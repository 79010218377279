import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ApplicationComponent } from './application/application.component';
import { HomeComponent } from './application/home/home.component';
import { CustomerComponent } from './application/customer/customer.component';
import { PracticeComponent } from './application/practice/practice.component';
import { NotesComponent } from './application/notes/notes.component';
import { AccountContactComponent } from './application/account-contact/account-contact.component';
import { OpportunitiesComponent } from './application/opportunities/opportunities.component';
import { ResponsewizardComponent } from './application/responsewizard/responsewizard.component';
import { OpportunitiesdashboardComponent } from './application/opportunities/opportunitiesdashboard/opportunitiesdashboard.component';
import { ContactsComponent } from './application/contacts/contacts.component';
import { LeadsComponent } from './application/leads/leads.component';
import { LeadsdashboardComponent } from './application/leads/leadsdashboard/leadsdashboard.component';
import { ContactsdashboardComponent } from './application/contacts/contactsdashboard/contactsdashboard.component';
import { ScheduletaskComponent } from './application/scheduletask/scheduletask.component';
import { EmailsComponent } from './application/emails/emails.component';
import { TasklistComponent } from './application/tasklist/tasklist.component';
import { TasklistdashboardComponent } from './application/tasklist/tasklistdashboard/tasklistdashboard.component';
import { ConfigurationdashboardComponent } from './application/configurationdashboard/configurationdashboard.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ReportsComponent } from './application/reports/reports.component';
import { AccountComponent } from './application/account-contact/account/account.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { CampaignsComponent } from './application/campaigns/campaigns.component';
import { DashboardComponent } from './application/dashboard/dashboard.component';
import { CalllistComponent } from './application/calllist/calllist.component';
import { ViewcalllistComponent } from './application/viewcalllist/viewcalllist.component';
import { TaskComponent } from './application/task/task.component';
import { ForecastingComponent } from './application/forecasting/forecasting.component';
import { QuickViewComponent } from './application/quick-view/quick-view.component';
import { MonthCalenderComponent } from './application/month-calender/month-calender.component';
import { SalestoolComponent } from './application/salestool/salestool.component';
import { TicketsComponent } from './application/tickets/tickets.component';
import { PageComponent } from './application/page/page.component';
import { AuditLogComponent } from './application/audit-log/audit-log.component';
import { ZendeskhomeComponent } from './application/zendeskhome/zendeskhome.component';
import { CustomercontactsComponent } from './application/customercontacts/customercontacts.component';
import { ImportedComponent } from './application/imported/imported.component';
import { RhxComponent } from './application/rhx/rhx.component';
import { PendedproposalsComponent } from './application/pendedproposals/pendedproposals.component';
import { SalesXcrmComponent } from './application/sales-xcrm/sales-xcrm.component';
import { UploadDocumentComponent } from './application/upload-document/upload-document.component';
import { AdvancedTableComponent } from './application/advanced-table/advanced-table.component';
import { AutomatedEmailsComponent } from './application/automated-emails/automated-emails.component';
import { MarketingComponent } from './application/marketing/marketing.component';
import { ProjectsComponent } from './application/projects/projects.component';
import { SystemRegistrationComponent } from './application/system-registration/system-registration.component';
import { ProposalsComponent } from './application/proposals/proposals.component';
import { UserMessagingComponent } from './application/user-messaging/user-messaging.component';
import { LeadsConfigurationComponent } from './application/leads-configuration/leads-configuration.component';
import { AdminReportsComponent } from './application/reports/admin-reports/admin-reports.component';
import { SearchResultComponent } from './application/search-result/search-result.component';
import { ProfileComponent } from './application/profile/profile.component';
import { QuickSearchComponent } from './application/quick-search/quick-search.component';
import { CampaignConfigurationComponent } from './application/campaign-configuration/campaign-configuration.component';
import { AboutsalesXCRMComponent } from './application/aboutsales-xcrm/aboutsales-xcrm.component';
import { MessagingComponent } from './application/messaging/messaging.component';
import { AddNewAccountComponent } from './application/add-new-account/add-new-account.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent }, 
  { path: 'resetpassword/:userid', component: ResetpasswordComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  {
    path: 'application', component: ApplicationComponent,
    children: [    
      { path: 'application', component: ApplicationComponent },
      { path: 'home/:term', component: HomeComponent },
      { path: 'customer/:id', component: CustomerComponent },
      { path: 'customer/:id/:term', component: CustomerComponent },
      { path: 'practice', component: PracticeComponent },
      { path: 'notes/:id/:type', component: NotesComponent },
      { path: 'account-contact/:practiceid/:accountid', component: AccountContactComponent },
      { path: 'opportunities/:contactid', component: OpportunitiesComponent },
      { path: 'responsewizard/:type/:SubMenuType', component: ResponsewizardComponent },
      { path: 'opportunities/:contactid/:type', component: OpportunitiesComponent },
      { path: 'opportunitiesdashboard/:contactid/:type/:term/:id', component: OpportunitiesdashboardComponent },
      { path: 'contacts/:subtype/:term', component: ContactsComponent },
      { path: 'leads/:contactid', component: LeadsComponent },
      { path: 'leads/:contactid/:id', component: LeadsComponent },
      { path: 'leadsdashboard/:contactid/:type/:term', component: LeadsdashboardComponent },
      { path: 'contactsdashboard/:contactid/:permission', component: ContactsdashboardComponent },
      { path: 'contactsdashboard/:contactid/:edit', component: ContactsdashboardComponent },
      { path: 'contactsdashboard/:contactid/:edit/:campaignid', component: ContactsdashboardComponent },
      { path: 'campaigns/:campaignid', component: CampaignsComponent },
      { path: 'scheduletask/:type/:id/:taskid', component: ScheduletaskComponent },
      { path: 'emails/:type/:ids', component: EmailsComponent },
      { path: 'tasklist', component: TasklistComponent },
      { path: 'tasklistdashboard/:type/:id/:taskid/:term', component: TasklistdashboardComponent },
      { path: 'configurationdashboard/:subMenuId', component: ConfigurationdashboardComponent },
      { path: 'reports/:type', component: ReportsComponent },
      { path: 'account/:type', component: AccountComponent },
      { path: 'account/:type/:map/:term', component: AccountComponent },
      { path: 'campaigns', component: CampaignsComponent },
      { path: 'dashboard/:type', component: DashboardComponent },
      { path: 'addAccount/:practiceid/:accountid', component: AccountContactComponent },
      { path: 'calllist/:contactids/:calllistid', component: CalllistComponent },
      { path: 'viewcalllist', component: ViewcalllistComponent },
      { path: 'task', component: TaskComponent },
      { path: 'forecasting', component: ForecastingComponent },
      { path: 'forecasting/:ContactId', component: ForecastingComponent },
      { path: 'quick-view/:ids/:term', component: QuickViewComponent },
      { path: 'month-calender/:subMenuId', component: MonthCalenderComponent },
      { path: 'month-calender/:subMenuId/:viewType', component: MonthCalenderComponent },
      { path: 'messaging/:messageid', component: MessagingComponent },
      { path: 'salestool/:subMenuId', component: SalestoolComponent },
      { path: 'system-registration', component: SystemRegistrationComponent },
      { path: 'tickets/:contactid', component: TicketsComponent },
      { path: 'tickets/:contactid/:ContOrAccType', component: TicketsComponent },
      { path: 'page', component: PageComponent },
      { path: 'configurationdashboard/:documentmanagement', component: ConfigurationdashboardComponent },
      { path: 'audit-log', component: AuditLogComponent },
      { path: 'zendeskhome/:accountorContactid', component: ZendeskhomeComponent },
      { path: 'zendeskhome/:accountorContactid/:type', component: ZendeskhomeComponent },
      { path: 'zendeskhome/:accountorContactid/:ticketId/:type', component: ZendeskhomeComponent },
      { path: 'customercontacts', component: CustomercontactsComponent },
      { path: 'customercontacts/:term/:contactid', component: CustomercontactsComponent },
      { path: 'imported/:sourcepage', component: ImportedComponent },
      { path: 'rhx/:subMenuId', component: RhxComponent },
      { path: 'pendedProposals', component: PendedproposalsComponent },
      { path: 'sales-xcrm.component', component: SalesXcrmComponent },
      { path: 'upload-document', component: UploadDocumentComponent },
      { path: 'advanced-table/:queryId/:searchtype', component: AdvancedTableComponent },
      { path: 'search-result/:queryId/:searchtype', component: SearchResultComponent},      
      { path: 'automated-emails', component: AutomatedEmailsComponent },
      { path: 'marketing', component: MarketingComponent },
     // { path: 'projects/:accId/:type', component: ProjectsComponent },
      { path: 'projects/:accId/:type/:ProjectID', component: ProjectsComponent },
      { path: 'projects', component: ProjectsComponent },
      { path: 'Proposals/:type', component: ProposalsComponent },
      { path: 'Proposals/:id/:type', component: ProposalsComponent },
      { path: 'Proposals/:id/:type/:ConOrAccType', component: ProposalsComponent },
      { path: 'Proposals/:id/:ProposalId/:type/:ConOrAccType', component: ProposalsComponent },
      { path: 'user-messaging', component: UserMessagingComponent },
      { path: 'Leads-Configuration', component: LeadsConfigurationComponent },
      { path: 'admin-reports/:type', component: AdminReportsComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'quicksearch', component: QuickSearchComponent },
      { path: 'CampaignConfiguration', component: CampaignConfigurationComponent },
      { path: 'aboutsales-xcrm/:type', component: AboutsalesXCRMComponent },
      { path: 'addnewaccount', component: AddNewAccountComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
