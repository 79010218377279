import { Component, OnInit, ViewContainerRef, ElementRef, Renderer2, ViewChild, Optional, Input } from '@angular/core';
import { LeadsService } from '../../../services/leads.service'
import { OpprtunitiesService } from '../../../services/opprtunities.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PageComponent } from '../../page/page.component';
import { CustomerService } from '../../../services/customer.service';
import { Contacts } from '../../../models/contacts';
import { SalesProcessHistory } from '../../../models/sales-process-history';
import { Accounts } from '../../../models/accounts';
import { Notes } from '../../../models/notes';
import { ToastrService } from 'ngx-toastr';;
import { ParentComp, UserlistComponent } from "../../userlist/userlist.component";
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { NotesrendererComponent } from '../../notesrenderer/notesrenderer.component';
import { Dashboardconfiguration } from '../../../models/dashboardconfiguration';
import { Column } from 'ag-grid';
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../../application.component';
import * as moment from 'moment'
import { LeadsComponent } from '../leads.component';
import { Dropdowntype, Dropdown } from '../../../models/dropdowntype';
import { NotesComponent } from '../../notes/notes.component';
import { AccountsService } from 'src/app/services/accounts.service';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { AuditLogModel } from '../../../models/auditlog';
declare var require: any;
declare var $: any;

@Component({
  selector: 'app-leadsdashboard',
  templateUrl: './leadsdashboard.component.html',
  styleUrls: ['./leadsdashboard.component.css']
})
export class LeadsdashboardComponent implements OnInit {
  items = [];
  datePipe: DatePipe = new DatePipe("en-US");
  itemCount = 0;
  itemResource: any;
  params: any;
  inputName: string = ''; value = "";
  columnSelections: string;
  Ids = [];
  dialogOpen: boolean = false;
  FeeAmountdiv: boolean = false;
  noData: boolean = true;
  notesitemResource: any;
  notesitems = [];
  notesitemCount = 0;
  notesparams: any;
  showDialog = false;
  currentuser: any;
  dropdownList = [];
  finalitems = "";
  selectedItems = [];
  dropdownSetting = {};
  isModal: boolean = false;
  contact: Contacts = new Contacts();
  account: Accounts = new Accounts();
  notes: Notes = new Notes();
  notedescription: string; NotesDec = false;
  leadid: number;
  typ: string;
  contactid: number = 0;
  @Input()
  leadtype: string;
  selectedleadtype: string = null;
  accessDenied: boolean = false;
  thisComp: ParentComp = ParentComp.LeadsDash;
  isViewInitialized: boolean = false;
  isPgClickRegistered: boolean = false;
  ForcastDiv: boolean = false;
  LeadInformation: boolean = true;
  OpenProppage: boolean = false;
  addLead: boolean = false;
  public sideBar;
  isDisplayCommissionEstimator: boolean = false;
  public gridOptions: GridOptions;
  public gridOptionsnotes: GridOptions;
  public rowData: any[];
  public columnDefs: any[];
  public columnDefsnotes: any[];
  public contactProps: string[];
  pageSize: string = "100";
  Leadstatus: string;
  public context;
  public frameworkComponents;
  allLeads: SalesProcessHistory[];
  allnotes: Notes[];
  aggridheight: string;
  conid: number;
  LeadSelectedVal = "Open & Stalled Leads";

  currentPage: number = 1;
  startrow: number = 1;
  noofRows: number = 1;
  totalnumberofrows: number = 1;
  totalnumberofPages: number = 1;
  LeadstatusList: Dropdowntype[] = [];
  @ViewChild(UserlistComponent) userListComp;
  @ViewChild(NotesComponent) notecomp;
  // @ViewChild(ForecastingComponent) forecastComp;
  @ViewChild(LeadsComponent) leadsComponent: LeadsComponent;
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");

  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  calanderonoff: boolean = false;
  DisplayrowsList: Dropdowntype[] = [];
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  term: string = "";
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  timezonevalue: string;
  userInfo: string;
  isShowDetailsChecked: boolean = false;
  private gridApi;
  @Input()
  PageFrom: string;
  addLeadExpand: boolean = false;
  EnableSearch: boolean = false;
  constructor(private paginationcop: PageComponent, public applicationComponent: ApplicationComponent, private elementRef: ElementRef, private renderer: Renderer2, private opprtunityservice: OpprtunitiesService, private http: HttpClient, private LeadsService: LeadsService, private route: ActivatedRoute, private router: Router, private customerService: CustomerService, private toastr: ToastrService, private viewContainerRef: ViewContainerRef, private accountService: AccountsService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.route.params.subscribe(params => {
      this.contactid = params['contactid'];
      this.term = params['term'];
      this.leadtype = params['type']
      //this.leadid = params['type'];
      this.PageFrom = params['PageFrom'];

      if (this.selectedleadtype != null) {
        this.Generate($("#userids").val());
      }
    });
    this.applicationComponent.selectedItems = (JSON.parse(localStorage.getItem("SelectedUserIds")));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {

      this.applicationComponent.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
      ];
      localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
      $("#userids").val(this.currentuser.id);
      this.applicationComponent.ExpandSelectedMenu('leads');
    }
    this.viewContainerRef = viewContainerRef;
    // this.toastr.setRootViewContainerRef(viewContainerRef);
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, 1, 0, 0).then((Owner) => {
      Owner.forEach(Owner => {
      });

      this.dropdownList = Owner;
    });
  }
  ngAfterViewInit(): void {
    var thiscomponent = this;
    setTimeout(function () { thiscomponent.ResizeTable(); }, 100);
  }
  LeadMsgReceived(msg) {
    this.OpenProppage = true;
  }
  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single', suppressRowClickSelection: true
    };
    this.gridOptionsnotes.getRowHeight = function (params) {
      if (params.node.floating) {
        return 50;
      } else {
        return 100;
      }
    }
    this.createColumnDefsnotes("");
    this.gridOptionsnotes.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  createColumnDefsnotes(val) {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellStyle: { 'text-align': 'left' }, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } },

    ];

  }
  onAgGridReadynotes(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllnotes();
      $(setTimeout(function () {
        selfObj.autoSizeAllnotes();
      }, 400));
    }, 400));
  }
  autoSizeAllnotes() {
    var allColumnIds = [];
    if (this.gridOptionsnotes && this.gridOptionsnotes.columnApi) {
      var allCols = this.gridOptionsnotes.columnApi.getAllColumns();
      if (allCols != null) {
        allCols.forEach(function (column) {
          allColumnIds.push(column.getColId());
        });
      }
      this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds);
      //this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds, "autosizeColumns");
    }

  }

  createColumnDefs(columnSelections: string) {
    var searcharray = [];
    var searcharray = [];
    if (this.gridOptions.quickFilterText != null && this.gridOptions.quickFilterText != undefined && this.gridOptions.quickFilterText != '') {
      searcharray.push(this.gridOptions.quickFilterText);
    }
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: this.PageFrom == 'dashboard' ? true : false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "salesstage") {
          this.columnDefs.push({
            field: 'salesstage', headerName: 'Sales Stage', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, getQuickFilterText: () => '', width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if (params.data.status == 'Open' || params.data.status == 'Pending') { return { color: 'red' }; }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "firstname") {
          this.columnDefs.push({
            field: 'firstname', headerName: 'First Name', getQuickFilterText: () => '', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "lastname") {
          this.columnDefs.push({
            field: 'lastname', headerName: 'Last Name', getQuickFilterText: () => '',hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "account") {
          this.columnDefs.push({
            field: 'account', headerName: 'Account Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "accPhone") {
          this.columnDefs.push({
            field: 'accPhone', headerName: 'Phone', hide: false, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          })
        }
        else if (fieldHeaderVisibleWidthArray[0] == "nexttask") {
          this.columnDefs.push({
            field: 'nexttask', headerName: 'Next Sales Stage', getQuickFilterText: () => '', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer(params) {
              if (params.data.salesstage === 'IPC') { return 'Analysis' }
              else if (params.data.salesstage === 'Analysis') { return 'Prepare Analysis Report' }
              else if (params.data.salesstage === 'Prepare Analysis Report') { return 'Review Analysis' }
              else if (params.data.salesstage === 'Review Analysis') { return 'Generate Proposal' }
              else if (params.data.salesstage === 'Generate Proposal') { return 'Justification Meeting' }
              else if (params.data.salesstage === 'Justification Meeting') { return 'Reference Calls' }
              else if (params.data.salesstage === 'Reference Calls') { return 'Demonstration' }
              else if (params.data.salesstage === 'Demonstration') { return 'Won-Lost-Pending' }
            }
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefs.push({
            field: 'status', headerName: 'Status', getQuickFilterText: () => '',hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle:
              function (params) {
                if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; }
              }, cellRenderer(params) {
                if (params.data.salesstage == 'MD Logic Demonstration' && params.data.status == 'Next Stage Pending') { return ' ' } else { return params.value; }
              }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "leadstatus") {
          this.columnDefs.push({
            field: 'leadstatus', headerName: 'Deal Won/Lost', getQuickFilterText: () => '', hide: false, cellRenderer(params) {
              if (params.data.Status == 'Won' || params.data.Status == 'Won') { return 'Closed' } else { return 'Open' }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', getQuickFilterText: () => '',hide: false, suppressSorting: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefs.push({ field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) { if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; } } });
        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, getQuickFilterText: () => '',
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      if (this.PageFrom == 'dashboard') {
        this.columnDefs = [
          {
            field: 'account', headerName: 'Account Name', hide: false, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },
          { field: 'datecreated', headerName: 'Date Created', hide: false },

          {
            field: 'lastname', headerName: 'Last Name', hide: false, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'firstname', headerName: 'First Name', hide: false, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },
          //{ field: 'middlename', headerName: 'Middle Name', hide: tr },

          { field: 'titleName', headerName: 'Title', hide: false },

          {
            field: 'salesstage', headerName: 'Sales Stage', hide: false, cellStyle: function (params) {
              if (params.data.status == 'Open' || params.data.status == 'Pending') { return { color: 'red' }; }
            }
          },
          {

            field: 'status', headerName: 'Status', hide: false, cellStyle:
              function (params) {
                if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; }
              }, cellRenderer(params) {
                if (params.data.salesstage == 'MD Logic Demonstration' && params.data.status == 'Next Stage Pending') { return ' ' } else { return params.value; }
              }

          },
          { field: 'datescheduled', headerName: 'Date Completed', hide: false },  //true starts here
          {
            field: 'nexttask', headerName: 'Next Sales Stage', hide: false, cellRenderer(params) {
              if (params.data.salesstage == 'IPC') { return 'Analysis' }
              else if (params.data.salesstage == 'Analysis') { return 'Prepare Analysis Report' }
              else if (params.data.salesstage == 'Prepare Analysis Report') { return 'Review Analysis' }
              else if (params.data.salesstage == 'Review Analysis') { return 'Generate Proposal' }
              else if (params.data.salesstage == 'Generate Proposal') { return 'Justification Meeting' }
              else if (params.data.salesstage == 'Justification Meeting') { return 'Reference Calls' }
              else if (params.data.salesstage == 'Reference Calls') { return 'Demonstration' }
              else if (params.data.salesstage == 'Demonstration') { return 'Won-Lost-Pending' }
            }
          },


          { field: 'owner', headerName: 'Owner', hide: false },


          {
            field: 'leadstatus', headerName: 'Deal Won/Lost', hide: false

          },
          //{ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: (this.PageFrom != 'dashboard') ? true : false , pinned: 'right' }

        ];
      } else {
        this.columnDefs = [
          {
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
          },
          { field: 'datecreated', headerName: 'Date Created', getQuickFilterText: () => '', hide: false },
          {
            field: 'account', headerName: 'Account Name',  hide: false, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'accPhone', headerName: 'Phone', hide: false, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'lastname', headerName: 'Last Name', getQuickFilterText: () => '',hide: false
          },
          {
            field: 'firstname', headerName: 'First Name', getQuickFilterText: () => '',hide: false
          },
          //{ field: 'middlename', headerName: 'Middle Name', hide: tr },

          { field: 'titleName', headerName: 'Title', getQuickFilterText: () => '',hide: false },

          {
            field: 'salesstage', headerName: 'Sales Stage', getQuickFilterText: () => '',hide: false, cellStyle: function (params) {
              if (params.data.status == 'Open' || params.data.status == 'Pending') { return { color: 'red' }; }
            }
          },
          {

            field: 'status', headerName: 'Status', getQuickFilterText: () => '', hide: false, cellStyle:
              function (params) {
                if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; }
              }, cellRenderer(params) {
                if (params.data.salesstage == 'MD Logic Demonstration' && params.data.status == 'Next Stage Pending') { return ' ' } else { return params.value; }
              }

          },
          { field: 'datescheduled', headerName: 'Date Completed', getQuickFilterText: () => '',hide: false },  //true starts here
          {
            field: 'nexttask', headerName: 'Next Sales Stage', getQuickFilterText: () => '',hide: false, cellRenderer(params) {
              if (params.data.salesstage == 'IPC') { return 'Analysis' }
              else if (params.data.salesstage == 'Analysis') { return 'Prepare Analysis Report' }
              else if (params.data.salesstage == 'Prepare Analysis Report') { return 'Review Analysis' }
              else if (params.data.salesstage == 'Review Analysis') { return 'Generate Proposal' }
              else if (params.data.salesstage == 'Generate Proposal') { return 'Justification Meeting' }
              else if (params.data.salesstage == 'Justification Meeting') { return 'Reference Calls' }
              else if (params.data.salesstage == 'Reference Calls') { return 'Demonstration' }
              else if (params.data.salesstage == 'Demonstration') { return 'Won-Lost-Pending' }
            }
          },


          { field: 'owner', headerName: 'Owner', getQuickFilterText: () => '',hide: false },

          { field: 'completeduser', headerName: 'Completed By', getQuickFilterText: () => '',hide: true },
          {
            field: 'leadstatus', headerName: 'Deal Won/Lost', getQuickFilterText: () => '',hide: false

          },
          //{ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: (this.PageFrom != 'dashboard') ? true : false , pinned: 'right' }

        ];
      }
      var tooltipRenderer = function (params) {
        return '<span title="' + params.value + '">' + params.value + '</span>';
      };
    }
  }
  //onAgGridReady(event) {
  //  let selfObj = this;
  //  $(setTimeout(function () {
  //    selfObj.autoSizeAll();
  //    $(setTimeout(function () {
  //      selfObj.autoSizeAll();
  //    }, 100));
  //  }, 100));
  //}
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  autoSizeAll() {

  }
  txtPageSizeChanged(pageSize) {

    this.currentPage = 1;
    this.pageSize = pageSize;
    this.ClosedLeads(this.selectedleadtype, $("#userids").val(), this.term, this.currentPage, this.pageSize);
    //this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    this.saveConfiguration();
  }
  initializeAgGrid() {
    var searchstring = this.applicationComponent.searchstring;
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let gridconfigval = this.PageFrom == 'dashboard' ? 62 : 6;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 6).then((config) => {
      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.pageSize = config.maxlimit.toString();
      }
      this.columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(this.columnSelections);
      this.startDateVal = (config != null && config.startdate != null) ? config.startdate : (new Date().getFullYear() - 1) + '-01-01';
      this.startDatePlaceHolder = (config != null && config.startdate != null) ? 'Start:' + moment(config.startdate).format("MM-DD-YYYY") : 'Start: 01-01-' + (new Date().getFullYear() - 1).toString();
      this.endDateVal = (config != null && config.enddate != null) ? config.enddate : new Date().getFullYear() + '-12-31';
      this.endDatePlaceHolder = (config != null && config.enddate != null) ? 'End:' + moment(config.enddate).format("MM-DD-YYYY") : 'End: 12-31-' + (new Date().getFullYear() - 1).toString();
      this.UpdateSearchColumns(this.columnSelections);
      this.applicationComponent.SearchColumnList = [];
      let allCols: string[] = this.columnSelections.split(',');

      let columnSelectionsArray: any[] = [];
      for (let i = 0; i < allCols.length; i++) {
        let fields: string[] = allCols[i].split(':');
        if (fields[2] == 'true' && fields[1] != '#') {
          columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
        }
      }
      this.applicationComponent.SearchColumnList = columnSelectionsArray;
    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.gridOptions.api.forEachNode((node) => {
        if (selfObj.leadid != null && selfObj.leadid != undefined && node.data.id == selfObj.leadid) {
          selfObj.gridOptions.api.selectNode(node, true);
          selfObj.gridOptions.rowSelection = 'single';
        }
      });
    }, 500));

  }
  OnselectionChanged(event) {
    if (this.PageFrom != 'dashboard') {
      var rowCount = event.api.getSelectedNodes().length;
      if (rowCount > 0) {
        if (event.api.getSelectedNodes()[0].data) {
          var data = event.api.getSelectedNodes()[0].data;
          let selfobj = this;
          $(setTimeout(function () {
            if (selfobj.leadsComponent) {
              selfobj.leadsComponent.GetBySalesProcessHistoryById(data.id);
            }
            selfobj.SalesDialogCss();

            selfobj.contactid = selfobj.conid = data.contactid;
            selfobj.leadid = data.id;
            if (selfobj.leadsComponent) {
              selfobj.leadsComponent.LoadSalesData(selfobj.contactid, selfobj.leadid);
            }
            if (selfobj.leadsComponent) {
              selfobj.leadsComponent.GetAllLeadNotes(data.contactid, 'Lead');
            }

          }, 500));



        }

      } else {
        this.leadsComponent.showsalesDialog = false;
      }
    }
  }

  SalesDialogCss() {
    $(setTimeout(function () {
      $('#showsalesDialog .dialog').attr('style', 'width: 35%;padding-bottom: 0px;border: 1px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 82.5%;padding: 0;top: 10%;left: 63%;');
    }, 100));
  }
  rowClickAgGrid(rowEvent) {
    if (this.PageFrom != 'dashboard') {
      if ((this.Ids).indexOf(rowEvent.data.contactid) > -1) {
        var index = (this.Ids).indexOf(rowEvent.data.contactid);
        (this.Ids).splice(index, 1);
        var string = (this.Ids).toString();
        $('#emailIds').val(string);
        $('#tasklistselectedtyle').val('');
      }
      else {
        this.Ids.push(rowEvent.data.contactid);
        var string = (this.Ids).toString();
        $('#emailIds').val(string);
        $('#tasklistselectedtyle').val('Contact');

        this.addLead = true;
        let str = localStorage.getItem('redirectfrom');
        if (str == 'dashboard') {
          this.contactid = parseInt(localStorage.getItem('leadContactId'));
          this.leadid = parseInt(localStorage.getItem('leadId'));
          localStorage.setItem('leadContactId', '');
          localStorage.setItem('redirectfrom', '');
        }
        else {
          this.contactid = rowEvent.data.contactid;
          this.leadid = rowEvent.data.id;
        }      
      }
    var obj: AuditLogModel = { id: 0, type: 'Leads', action: 'Leads Viewed', AccountOrContactId: rowEvent.data.contactid, AccountOrContact: "Contact", documentid: rowEvent.data.id, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Leads Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }
    }
  
  rowDoubleClickAgGrid(rowEvent) {
    if (this.PageFrom == 'dashboard') {
      this.conid = rowEvent.data.contactid;
      localStorage.setItem('leadContactId', rowEvent.data.contactid);
      localStorage.setItem('leadId', rowEvent.data.id);
      localStorage.setItem('redirectfrom', 'dashboard');
      this.router.navigateByUrl(`/application/leadsdashboard/0/0/null`);
      this.applicationComponent.ExpandSelectedMenu('leads');
      $(".subButtons").removeClass("activeButton");
      this.applicationComponent.selectedtabid = 'leads';
      //this.applicationComponent.activateMainButtons('leads');
      //if (rowEvent.api.getSelectedNodes()[0].data) {
      //  var data = rowEvent.api.getSelectedNodes()[0].data;
      //  let selfobj = this;
      //  $(setTimeout(function () {
      //    if (selfobj.leadsComponent) {
      //      selfobj.leadsComponent.GetBySalesProcessHistoryById(data.id);
      //    }
      //    selfobj.SalesDialogCss();

      //    selfobj.contactid = selfobj.conid = data.contactid;
      //    selfobj.leadid = data.id;
      //    if (selfobj.leadsComponent) {
      //      selfobj.leadsComponent.LoadSalesData(selfobj.contactid, selfobj.leadid);
      //    }
      //    if (selfobj.leadsComponent) {
      //      selfobj.leadsComponent.GetAllLeadNotes(data.contactid, 'Lead');
      //    }

      //  }, 500));



      //}
    }
    else {
     
      this.EnableSearch = false;
      if (rowEvent.data) {
        this.conid = rowEvent.data.contactid;
      }
      let str = localStorage.getItem('redirectfrom');
      if (str == 'dashboard') {
        this.contactid = parseInt(localStorage.getItem('leadContactId'));
        this.leadid = parseInt(localStorage.getItem('leadId'));
        localStorage.setItem('leadContactId', '');
        localStorage.setItem('leadId', '');
        localStorage.setItem('redirectfrom', '');
      }
      //else {
        this.contactid = rowEvent.data.contactid;
        this.leadid = rowEvent.data.id;
     // }
     

      //this.HideandShowstyles('Lead');
      // this.leadsComponent.HideAdnShow(rowEvent.data.contactid);
    }
    this.addLead = true;
  }
  //onmessageReceived(msg) {

  //  if ($.isNumeric(msg)) {
  //    this.leadsComponent.HideAdnShow(msg);
  //    this.HideandShowstyles('Lead');
  //  }else {
  //    this.LeadSelectedVal = msg;
  //  }
  //}
  HideandShowstyles(type) {
    this.ForcastDiv = false;
    this.leadtype = type;
    if (type == 'Lead') {
      this.LeadInformation = false;
      this.addLead = true;

    } else {

      this.addLead = false;
      this.LeadInformation = true;

    }
  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('#showDialog .dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 100));
  }
  openNotesDialogAg(rowdata) {

    let selfobj = this;
    $(setTimeout(function () {
      selfobj.isModal = true;
      selfobj.conid = rowdata.contactid;
      selfobj.showDialog = !selfobj.showDialog;
      selfobj.NotesDialogCss();

    }, 300));

  }

  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }

  UpdateSearchColumns(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[] = columnSelections.split(',');
    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');
      if (fields[2] == 'true' && (fields[1] != '#' && fields[0] != 'notes')) {
        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }
    //this.applicationComponent.ShowSearchTabs('True');
    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];

    if (retrievedObject != null) {

      let obj: any[] = JSON.parse(retrievedObject);
      obj.forEach(element => {

        if (element.subtab == 'Open Leads' && this.applicationComponent.closedLeadVal == 'Open') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
          //if (this.applicationComponent.SearchSelectedItemsLengthChanged == true) {
          //  this.applicationComponent.showSearchTabsSubTabs = false;
          //}
          //else {
          //  this.applicationComponent.showSearchTabsSubTabs = true;
          //}
        } else if (element.subtab == 'Closed Leads' && this.applicationComponent.closedLeadVal == 'Completed') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
          //if (this.applicationComponent.SearchSelectedItemsLengthChanged == true) {
          //  this.applicationComponent.showSearchTabsSubTabs = false;
          //}
          //else {
          //  this.applicationComponent.showSearchTabsSubTabs = true;
          //}
        }
        else if (element.subtab == 'All Leads' && this.applicationComponent.closedLeadVal == 'All') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
          //if (this.applicationComponent.SearchSelectedItemsLengthChanged == true) {
          //  this.applicationComponent.showSearchTabsSubTabs = false;
          //}
          //else {
          //  this.applicationComponent.showSearchTabsSubTabs = true;
          //}
        }
        else if (element.subtab == 'Forecasting' && this.applicationComponent.closedLeadVal == 'Forecasting') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
          //if (this.applicationComponent.SearchSelectedItemsLengthChanged == true) {
          //  this.applicationComponent.showSearchTabsSubTabs = false;
          //}
          //else {
          //  this.applicationComponent.showSearchTabsSubTabs = true;
          //}
        } else {
          this.applicationComponent.SearchSelectedItemsLengthChanged = false;
          //this.applicationComponent.showSearchTabsSubTabs = true;
        }


      });
      //tempTableColumns= JSON.parse(retrievedObject).columns;
      //tempTableSubtab = JSON.parse(retrievedObject).subtab;
    }
    if (tempTableColumns && tempTableColumns.length > 0) {
      this.applicationComponent.SearchSelectedItems = [];

      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })

    }
    else {

      this.accountService.GetDefaultColumns('OpenLeads', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.applicationComponent.SearchSelectedItems = [
            { "id": 'lastname', "itemName": 'Last Name' },
            { "id": 'firstname', "itemName": 'First Name' },
            { "id": 'account', "itemName": 'Account Name' }
          ];
        }
      });
      //this.applicationComponent.showSearchTabsSubTabs = false;
    }
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 25;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    this.UpdateSearchColumns(config.columnsselections);
    config.configureduserid = this.currentuser.id;
    config.resourceid = 6;
    config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    this.opprtunityservice.saveDashboardconfiguration(config);
  }

  ngOnInit() {
    this.applicationComponent.selectedbuttonText = "Leads";
    this.route.params.subscribe(params => {
      this.contactid = params['contactid'];    
    });

    if (this.PageFrom != 'dashboard') {
      var obj: AuditLogModel = { id: 0, type: 'Leads', action: 'Leads Viewed', AccountOrContactId: 0, AccountOrContact: "", documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Leads Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
      this.applicationComponent.InsertAuditHistory(obj);
    }
    if (this.applicationComponent.selectedusers != '' && this.applicationComponent.selectedusers != null && this.applicationComponent.selectedusers != undefined) {
      this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
        Owner.forEach(Owner => {
        });
        this.selectedItems = Owner;
      })
      this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
        this.DisplayrowsList = Rows;
      });
    }
    else {
      this.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username }
      ];
      this.finalitems += this.currentuser.id + ',';
      //$("#userids").val(this.currentuser.id);

    }

    this.initializeAgGrid();
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      this.ClosedLeads('Open Leads', $("#userids").val(), this.term, this.currentPage, this.pageSize);
    }

    this.customerService.GetSpecialityList("LeadStatus", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.LeadstatusList = accounttype;
    });

    this.isViewInitialized = true;

    if (document.getElementById('LeadsTabs') != undefined && document.getElementById('LeadsTabs') != null)
      document.getElementById('LeadsTabs').style.display = 'block';

    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 500));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 500));
    }
    let selfObj1 = this;
    $(setTimeout(function () {
      let str = localStorage.getItem('redirectfrom');
      if (str == 'dashboard') {
        selfObj1.rowDoubleClickAgGrid(selfObj1.allLeads[0]);
      }
    }, 400));

  }

  //AddSuspendInactiveDiv() {
  //  var mydiv = document.getElementsByClassName("pure-checkbox select-all ng-star-inserted");
  //  var div = document.createElement('div');
  //  div.id = "SuspendInactiveDiv";
  //  div.className = "col-xs-12 Banoos";
  //  mydiv[1].parentNode.insertBefore(div, mydiv[1].previousSibling);
  //  var banooDiv = document.getElementById("SuspendInactiveDiv");



  getinactiveUsers() {
    this.dropdownList = [];
    this.GetAllaccesusersListbyuser();

    //this.userservice.GetAllUsers(2).then((user) => {
    //  this.dropdownList = user;
    //});

  }
  getSuspendedUsers() {
    this.dropdownList = [];
    this.GetAllaccesusersListbyuser();
    //this.userservice.GetAllUsers(3).then((user) => {
    //  this.dropdownList = user;
    //});
  }
  GetAllaccesusersListbyuser() {

    var inactive = $('#Inactive').prop("checked") ? 1 : 0;
    var suspended = $('#suspended').prop("checked") ? 1 : 0;
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, 1, inactive, suspended).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.dropdownList = Owner;
    });

  }

  userListOutParamsBinding(outProps) {
    this.finalitems = outProps.finalitems;
    this.items = outProps.items;
    this.itemCount = outProps.itemsCount;
    this.selectedItems = outProps.selectedItems;
  }

  onItemSelect(item: any) {

    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    if (document.getElementById('ForecastingButton').className == 'activeButton') {
      //this.forecastComp.LeadChange(this.LeadSelectedVal, $("#userids").val(), this.startDateVal, this.endDateVal);
    } else {
      this.Generate(this.finalitems);
    }
  }
  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      if (document.getElementById('ForecastingButton').className == 'activeButton') {
        // this.forecastComp.LeadChange(this.LeadSelectedVal, $("#userids").val(), this.startDateVal, this.endDateVal);
      } else {
        this.Generate(this.finalitems);
      }
    } else {
      this.allLeads = [];
      $("#userids").val('');
    }
  }
  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    if (document.getElementById('ForecastingButton').className == 'activeButton') {
      // this.forecastComp.LeadChange(this.LeadSelectedVal, $("#userids").val(), this.startDateVal, this.endDateVal);
    } else {
      this.Generate(this.finalitems);
    }
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.allLeads = [];
    $("#userids").val('');
    this.applicationComponent.selectedusers = null
    this.Generate(this.finalitems);
  }

  Generate(userids) {
    this.applicationComponent.selectedusers = userids;
    let selobjj = this;

  }
  ClosedLeads(val, userids, searchval, startrow, endrow) {
    var subMenuId = '';
   
    if (this.currentuser.UserPrivilegeList != undefined) {
      var button = document.getElementById(subMenuId);
      var bname = (button == null) ? subMenuId : (button.title);
      var thisTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == bname || p.resource.toLowerCase() == bname)[0];
      if (thisTab != undefined && thisTab.canview == false) {
        this.toastr.warning("You Do Not have Access to " + bname + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
        return false;
      }
    }

    this.allLeads = [];
    var today = new Date();
    this.leadtype = val;
    userids = $("#userids").val();
    if (this.contactid != 0 && this.contactid != undefined) {
      this.HideandShowstyles('Lead');
    }
    else {
      this.HideandShowstyles('All')
    }
    this.applicationComponent.searchCriteriadata = [];
    this.LeadsService.GetAllLeadsList("All", $("#userids").val(), '2018-01-01', this.endDateVal, "null", 1, 1000, this.currentuser.id).then((customer) => {
      if (customer.length > 0) {
        if ((customer.length == 0 || customer == null) && searchval != null) {
          this.applicationComponent.ShowInvalidSearchDialog = !this.applicationComponent.ShowInvalidSearchDialog;
          this.applicationComponent.dialogsizeInvalidSearch();

        }
        customer.forEach(customer => {
          customer.datecreated = this.datePipe.transform(customer.datecreated, "MM/dd/yyyy");
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM/dd/yyyy");
          customer.datescheduled = this.datePipe.transform(customer.datescheduled, "MM/dd/yyyy");
        });
        let leads: any = customer;
        if (val == 'Open Leads') {
          subMenuId = 'OpenLeads';
          leads = leads.filter(p => p.leadstatus == "Open");
          
        } else if (val == 'Closed Leads') {
          subMenuId = 'ClosedLeads';
          leads = leads.filter(p => p.leadstatus == "Open");
        } else if (val == 'All') {
          subMenuId = 'AllLeads';
        }
        else {
          subMenuId = 'OpenLeads';
        }
        this.allLeads = leads;
        if (this.contactid > 0) {        
          let selfObj = this;
          $(setTimeout(function () {
            selfObj.gridOptions.api.forEachNode((node) => {
              if (node.data.contactid == selfObj.contactid) {
                selfObj.gridOptions.api.selectNode(node, true);
                selfObj.gridOptions.rowSelection = 'single';
              }
            });
          }, 300));  
        }
        this.leadid = Number(localStorage.getItem('leadId'));
        if (this.leadid > 0) {
         
          let selfObj = this;
          $(setTimeout(function () {
            selfObj.gridOptions.api.forEachNode((node) => {

              if (selfObj.leadid != null && selfObj.leadid != undefined && node.data.id == selfObj.leadid) {
                selfObj.gridOptions.api.selectNode(node, true);
                selfObj.gridOptions.rowSelection = 'single';
              } else {
                if (selfObj.addLead) {
                  if (node.data.id == selfObj.allLeads[0].id) {
                    selfObj.gridOptions.api.selectNode(node, true);
                    selfObj.gridOptions.rowSelection = 'single';
                  }
                }
              }
            });
          }, 500));          
        }
      } else {
        this.applicationComponent.searchstring = "No Match";
        if (this.addLead) {
          this.leadsComponent.HideAdnShow(0, 0);
        }
        if (this.applicationComponent.selectedsubbutton == 'Open') {
          var retrievedObject = localStorage.getItem('OpenLeadsSearchCriteria');
        } else if (this.applicationComponent.selectedsubbutton == 'Closed') {
          var retrievedObject = localStorage.getItem('ClosedLeadsSearchCriteria');
        } else if (this.applicationComponent.selectedsubbutton == 'All') {
          var retrievedObject = localStorage.getItem('AllLeadsSearchCriteria');
        }
        //var retrievedObject = localStorage.getItem('LeadsSearchCriteria');
        if (retrievedObject != null) {
          //this.applicationComponent.Searchdiv = true;
          var obj = retrievedObject.slice(0, -3);
          obj += '~';
          this.applicationComponent.searchCriteriadata = obj.split(' / ');
        }
      }
    });

    //this.initializeAgGrid();
  }

  reloadItems(params) {

    if (this.itemResource != undefined) {
      this.itemResource.query(params).then(items => this.items = items);
    }
  }

  GetAllLeadNotes(id, type) {
    this.customerService.GetAllNotesList(this.currentuser.id, id, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.allnotes = taskhistory;
      this.notesitemCount = taskhistory.length;
      let windowHeight = $(window).height() - 160;
    });
  }
  notesreloadItems(notesparams) {
    if (this.notesitemResource != undefined) {
      this.notesitemResource.query(notesparams).then(notesitems => this.notesitems = notesitems);
    }
  }

  enterNotes() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'block';
    document.getElementById('saveNotesButtons').style.display = 'block';
    document.getElementById('divTable').style.display = 'none';
  }
  SaveNote() {
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/img, "");
    this.notes.notedescription = this.notes.notedescription.replace(/<[^>]*>/g, '');
    this.notes.type = "Lead";
    this.notes.accountid = this.leadid;

    this.customerService.SaveNotes(this.notes).then(result => {
      this.GetAllLeadNotes(this.notes.accountid, 'Lead');
      this.CancelNote();
    });
  }
  CancelNote() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'none';
    document.getElementById('saveNotesButtons').style.display = 'none';
    document.getElementById('divTable').style.display = 'block';
  }
  ResizeTable() {   
    this.aggridheight = (((window.innerHeight) - (($('#appComponentContent').height()) + ($('#actiondivid').height())) - 85)) + 'px';
  }

  AssignStartAndEndDate(event) {

    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  //closedLeadEventListener() {
  //  if (this.applicationComponent.isClosedLeadEventRaised) {
  //    if (this.applicationComponent.closedLeadVal == "Forecasting") {
  //      this.HideandShowstyles('Forecasting');
  //    }
  //    else {

  //      this.ClosedLeads(this.applicationComponent.closedLeadVal, this.applicationComponent.closedLeadUserids, null, this.currentPage, this.pageSize);
  //    //  this.applicationComponent.ExpandSelectedMenu('leads');
  //    }
  //    this.applicationComponent.isClosedLeadEventRaised = false;
  //  }
  //  return false;
  //}

  openDisplaynotes(params) {
    this.NotesDec = !this.NotesDec;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    var notesOffset = $("#divNotesContainer").offset();
    let notesWidth = $('#divNotesContainer').width();
    let notesHeight = $('#divNotesContainer').height();
    let windowWidth = $(window).width();
    let showDialogWidth = 350;
    $(setTimeout(function () {
      $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

      if ($('input[name=Notes]').is(':checked')) {
        $('#txtLongNoteDesc').css('height', '81%');
        $('#txtLongNoteDesc').css('padding-top', '0%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
        $('#txtUserFullName').css('height', '14%');
      } else {
        $('#txtLongNoteDesc').css('height', '96%');
        $('#txtLongNoteDesc').css('padding-top', '6%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
        $('#txtLongNoteDesc').css('overflow', 'auto');
      }
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));
  }
  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();

  }
  CommissionEstimator(val) {
    this.isDisplayCommissionEstimator = val;
  }
  saveEditedNote() {
    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.closeDetailsDialog();
        selfObj.GetAllLeadNotes(selfObj.conid, 'Lead');
      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
  }
  first() {

    this.currentPage = 1;
    this.ClosedLeads(this.selectedleadtype, $("#userids").val(), this.term, this.currentPage, this.pageSize);
  }
  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.ClosedLeads(this.selectedleadtype, $("#userids").val(), this.term, this.currentPage, this.pageSize);
    }
  }
  next() {
    if (this.currentPage != this.totalnumberofPages) {
      this.currentPage++;
      this.ClosedLeads(this.selectedleadtype, $("#userids").val(), this.term, this.currentPage, this.pageSize);
    }

  }
  last() {
    this.currentPage = this.totalnumberofPages;
    if (this.currentPage <= this.totalnumberofPages) {
      this.ClosedLeads(this.selectedleadtype, $("#userids").val(), this.term, this.currentPage, this.pageSize);
    }
  }

  UserlistDataReceived(outProps: OutputPropsUserList) {
    if (outProps.enableSearch) { this.EnableSearch = true; }
    this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    //this.Contactvales = outProps.Contactvalues == undefined ? this.Contactvales : outProps.Contactvalues;
    if (outProps.finalitems != "") {
      $("#userids").val(outProps.finalitems);
      if (!outProps.pageSizeChanged) {
        this.ClosedLeads(outProps.secondDrpValue, $("#userids").val(), this.term, this.currentPage, this.pageSize);
      }
    } else {
      this.allLeads = [];
      $("#userids").val('');
    }
  }

  expandLeadsView(value) {
    if (value === 'Collapse') {
      this.addLeadExpand = false;
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.gridOptions.api.forEachNode((node) => {
          if (selfObj.leadid != null && selfObj.leadid != undefined && node.data.id == selfObj.leadid) {
            selfObj.gridOptions.api.selectNode(node, true);
            selfObj.gridOptions.rowSelection = 'single';
          }
        });
      }, 500));
    }
    else {
      this.addLeadExpand = true;
    }
  }
  CloseLeadsDiv() {
    this.leadtype = 'All';
    this.addLead = false;
    this.addLeadExpand = false;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
    this.gridOptions.quickFilterText = (document.getElementById('filter-text-box') as HTMLInputElement).value;
    this.createColumnDefs(this.columnSelections);
    this.gridApi.redrawRows();    
  }
  ClearSearch() {
    this.gridApi.setQuickFilter('');
    this.value = "";
    this.gridOptions.quickFilterText = "";
    this.createColumnDefs(this.columnSelections);
    this.gridApi.redrawRows();    
    this.EnableSearch = false;
  }
}
