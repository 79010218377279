<div class="container" style="height:100%;padding:0;">
  <input type="text" id="emailIds" style="display:none;" />
  <input type="text" id="tasklistselectedtyle" style="display:none;" />

  <div class="col-xs-12" style="height:100%;padding:0% 0% 0 0;">
    <div class="col-xs-12" style="height:100%;padding:0% 0% 0 0;">

      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 0.3% 0 0.3% 0; height: 7%" *ngIf="PageFrom!='dashboard'">
        <userdataheader [parentComp]="'Tickets'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
      </div>
   
      <div [ngClass]="showTicketInfo? 'col-xs-4' : 'col-xs-12'" style="padding:0;height:100%;">

        <div id="TicketsGrid" style="height:100%;padding:0;" *ngIf="!TicketDeatilsExpand">
          <div *ngIf="PageFrom!='dashboard' && showTicketInfo" class="col-xs-12" style="height:4%;padding:0;">

            <b style="margin-left: 10px;">
              <span (click)="CloseTicketDiv()">
                <img src="../../../assets/img/Black x.png" style="height: 21px; float: left; transform: rotate(180deg);" />
              </span> Tickets
            </b>
            <span>
              <img src="../../../assets/img/Open Quickview Grid Panel.png" style="height: 23px; float: right; transform: rotate(180deg);" (click)="ExpandTicketDeatils('Expand')" *ngIf="showTicketInfo" />
            </span>
          </div>
          <div class="col-xs-2" *ngIf="(PageFrom!='dashboard' && !showTicketInfo && shwTicketDetails)" style="height: 22%; padding: 0%">
            <div class="col-xs-12 daysFilterCss">
              <label class="container1">
                <input type="radio" id="0" checked="checked" name="FilterByDays" (click)="FilterByDays('0')" /><span class="checkmark"></span>
                <span>Display All</span>
                <Span style="float:right">{{TotalTicketCount}}</Span>
              </label>

            </div>
            <div class="col-xs-12 daysFilterCss">
              <label class="container1">
                <input type="radio" id="30" name="FilterByDays" (click)="FilterByDays('30')" /><span class="checkmark"></span>
                <span>30 Days</span>
                <Span style="float:right">{{Below30Days}}</Span>
              </label>

            </div>

            <div class="col-xs-12 daysFilterCss">
              <label class="container1">
                <input type="radio" id="3160" name="FilterByDays" (click)="FilterByDays('31-60')" /><span class="checkmark"></span>
                <span>31-60 Days</span>
                <Span style="float:right">{{Betwn31_60Days}}</Span>
              </label>
            </div>

            <div class="col-xs-12 daysFilterCss">
              <label class="container1">
                <input type="radio" id="6190" name="FilterByDays" (click)="FilterByDays('61-90')" /><span class="checkmark"></span>
                <span>61-90 Days</span>
                <Span style="float:right">{{Betwn61_90Days}}</Span>
              </label>
            </div>

            <div class="col-xs-12 daysFilterCss">
              <label class="container1">
                <input type="radio" id="91120" name="FilterByDays" (click)="FilterByDays('91-120')" /><span class="checkmark"></span>
                <span>91-120 Days</span>
                <Span style="float:right">{{Betwn91_120Days}}</Span>
              </label>
            </div>
            <div class="col-xs-12 daysFilterCss">
              <label class="container1">
                <input type="radio" id="120" name="FilterByDays" (click)="FilterByDays('120+')" /><span class="checkmark"></span>
                <span>120+ Days</span>
                <Span style="float:right">{{Above120Days}}</Span>
              </label>
            </div>

          </div>
         
          <div *ngIf="PageFrom!='dashboard'" class="col-xs-12 input-icons" style="padding: 7px; margin-bottom: 0.3%; border: 1px solid lightgray; width:100%">
            <i class="fa fa-search icon"></i>
            <input type="text" class="searchform-control" id="filter-text-box" placeholder="Search" (input)="onFilterTextBoxChanged()" [(ngModel)]="value" />
          </div>

          <div class="col-xs-12" style="height: 76%;padding: 0; margin-top: 4px;" [style.height.%]="shwTicketDetails ?'73':PageFrom!='dashboard'?'91':'100'">
            <ag-grid-angular style="width: 100%; padding-top: 10px;padding: 0;height:99%" id="gridheight" class="ag-theme-balham"
                             [style.border]="(applicationComponent.searchCriteriadata != '' && PageFrom!='dashboard')?'3px solid #00B0F0':'none'"
                             [style.height.%]="PageFrom!='dashboard'?'96.5':'100'"
                             [gridOptions]="ticketgridOptions"
                             [columnDefs]="columnDefs"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="Userlist"
                             [context]="context"
                             [sideBar]="sideBar"
                             (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                             (rowSelected)="rowClickAgGrid($event)"
                             (columnVisible)="columnVisible($event)"
                             (dragStopped)="dragStopped($event)"
                             (columnResized)="columnResized($event)"
                             [frameworkComponents]="frameworkComponents"
                             (gridReady)="onAgGridReady($event)">
            </ag-grid-angular>

          </div>
        </div>

        <div style="height:100%;padding:0;" *ngIf="TicketDeatilsExpand">
          <div class="col-xs-12" style="padding:0;height:4%">
            <span (click)="ExpandTicketDeatils('Collapse')"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
          </div>
          <div class="col-xs-12 scroll-c" style="padding:0;height:100%">

            <app-new-notes style="height:100%"
                           [selectedTabName]="'1'"
                           [conid]="ticketdata.contactid"
                           [typ]="'Contact'"
                           [ViewType]="'Block'"
                           [isModal]="false">
            </app-new-notes>
          </div>
        </div>


      </div>
      <div class="col-xs-8" style="height:90%" *ngIf="showTicketInfo">
        <div class="col-xs-8" style="padding:0 1.5%;height:100%;">
          <div style="height:30%;">
            <account-details *ngIf="Userlist.length>0" [viewaccount]="viewaccount" [account]="account" [tab]="''" [noteslist]="allnotes" (btnClickEvent)="btnClickReceived($event)" [pageFrom]="'Ticket'" [ViewData]="'Contact'" [contact]="contacts"></account-details>
          </div>

          <div class="col-xs-12" style="overflow: auto; border-radius:3px; padding:0;height:70%">
            <div class="col-xs-12" style="padding:0"><label>Ticket History</label></div>
            <ag-grid-angular class="ag-theme-balham"
                             style="height:93%;"
                             [gridOptions]="gridOptions"
                             [columnDefs]="columnDefs"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="ticketslist"
                             [context]="context"
                             [sideBar]="sideBar"
                             (selectionChanged)="onSelectionChanged($event)"
                             (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                             (columnVisible)="columnVisible($event)"
                             (dragStopped)="dragStopped($event)"
                             [suppressRowClickSelection]="true"
                             (columnResized)="columnResized($event)"
                             [frameworkComponents]="frameworkComponents"
                             (gridReady)="onAgGridReady($event)">
            </ag-grid-angular>
          </div>
        </div>
        <div class="col-xs-4" style="padding:0;height:100%;">
          <div class="col-xs-12" style=" height: 100%;padding:0" *ngIf="!PreviousNotesExpand">

            <div class="col-xs-12" style="height: 5%; margin-top: 1%; background-color: rgb(40,62,74); color: gray; padding: 1%; text-align: center; color: white ">
              <div class="col-xs-10"><label>TICKET  DETAILS </label> </div>
              <div class="col-xs-1">
                <button (click)="OpenTicket()" style="background: transparent; text-align: end; padding-right: 0;">
                  <img src="../../../assets/img/Edit Icon White.png" style="height:21px;" title="Edit Task" />
                </button>
              </div>
            </div>
            <div class="col-xs-12" style="padding: 0%; height: 60%; border: 1px solid lightgreen; padding-left: 2%; margin-top: 1%;">

              <div class="col-xs-12" style="padding: 0; height: 7%; padding-top: 5px;">
                <div class="col-xs-4" style="padding: 0px;">
                  <label style="font-size:11px">Contact :</label>
                </div>
                <div class="col-xs-8" style="font-size: 12px; padding: 0px; font-family: Calibri; color: #00b3f7d9; font-weight: normal">
                  <b><label>{{ticketdata.contactname| uppercase}}</label></b>
                </div>

              </div>

              <div class="col-xs-12" style="padding: 0; height: 7%">
                <div class="col-xs-4" style="padding: 0px;">
                  <label style="font-size:11px">Account :</label>
                </div>
                <div class="col-xs-8" style="padding: 0px;">
                  <label style="font-size:11px">{{ticketdata.accountName}}</label>
                </div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 7%">
                <div class="col-xs-4" style="padding: 0px;">
                  <label style="font-size:11px">Email :</label>
                </div>
                <div class="col-xs-8" style="padding: 0px;">
                  <label style="font-size:11px">{{ticketdata.email}}</label>
                </div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 7%">
                <div class="col-xs-4" style="padding: 0px;">
                  <label style="font-size:11px">Phone :</label>
                </div>
                <div class="col-xs-8" style="padding: 0px;">
                  <label style="font-size:11px">{{ticketdata.office}}</label>
                </div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 7%">
                <div class="col-xs-4" style="padding: 0px;">
                  <label style="font-size:11px">Cell :</label>
                </div>
                <div class="col-xs-8" style="padding: 0px;">
                  <label style="font-size:11px">{{ticketdata.cell}}</label>
                </div>
              </div>
              <div class="col-xs-12" style="padding: 0; height: 7%">
                <div class="col-xs-4" style="padding: 0px;">
                  <label style="font-size:11px">Created By :</label>
                </div>
                <div class="col-xs-8" style="padding: 0px;"> <label style="font-size:11px">{{ticketdata.UserName}}</label></div>

              </div>
              <div class="col-xs-12" style="padding: 0; height: 7%">
                <div class="col-xs-4" style="padding: 0px;">
                  <label style="font-size:11px">Date :</label>
                </div>
                <div class="col-xs-8" style="padding: 0px;">
                  <label style="font-size:11px"> {{ticketdata.createdDate}}</label>
                </div>
              </div>


              <div class="col-xs-12" style="padding: 0; height: 7%">
                <div class="col-xs-4" style="padding: 0px;">
                  <label style="font-size:11px">Ticket # :</label>
                </div>
                <div class="col-xs-8" style="padding: 0px;">
                  <label style="font-size:11px">{{ticketdata.ticketId}}</label>
                </div>

              </div>
              <div class="col-xs-12" style="padding: 0; height: 7%">
                <div class="col-xs-4" style="padding: 0px;">
                  <label style="font-size:11px">Subject :</label>
                </div>
                <div class="col-xs-8" style="padding: 0px;">  <label style="font-size:11px">{{ticketdata.subject}}</label> </div>

              </div>

              <div class="col-xs-12" style="padding: 0; height: 7%">
                <div class="col-xs-4" style="padding: 0px;"><label style="font-size:11px">Assign To :</label> </div>
                <div class="col-xs-8" style="padding: 0px;"><label style="font-size:11px">{{ticketdata.userList}}</label> </div>

              </div>
              <div class="col-xs-12" style="padding: 0; height: 7%">
                <div class="col-xs-4" style="padding: 0px;"> <label style="font-size:11px">CC :</label></div>
                <div class="col-xs-8" style="padding: 0px;"> <label style="font-size:11px">{{ticketdata.CCToName}}</label></div>

              </div>
              <div class="col-xs-12" style="padding: 0; height: 7%">
                <div class="col-xs-4" style="padding: 0px;"> <label style="font-size:11px">Priority :</label></div>
                <div class="col-xs-8" style="padding: 0px;">
                  <span *ngIf="ticketdata.priority==1"> <label style="font-size:11px">Yes</label></span>
                  <span *ngIf="ticketdata.priority==0"> <label style="font-size:11px">No</label></span>
                </div>

              </div>
              <div class="col-xs-12" style="padding: 0; height: 7%">
                <div class="col-xs-4" style="padding: 0px;">
                  <label style="font-size:11px">Status :</label>
                </div>
                <div class="col-xs-8" style="padding: 0px;">
                  <label style="font-size:11px">{{ticketdata.TicketStatus == 1 ? 'Open' : ticketdata.TicketStatus == 2 ? 'On-Hold' : ticketdata.TicketStatus == 3 ? 'Pended' : ticketdata.TicketStatus == 4 ? 'Complete' : ticketdata.TicketStatus == 5 ? 'Not-Completed' : ticketdata.TicketStatus == 6 ? 'Deleted' : ''}}</label>

                </div>

              </div>
              <div *ngIf="ticketdata.priority==1" class="col-xs-12" style="padding: 0; height: 7%; color: pink">
                <label style="font-size: 23px; font-weight: normal">
                  <b>Priority Ticket </b>

                </label>
              </div>

            </div>
            <div class="col-xs-12" style="padding: 0; height: 33%; border: 1px solid lightgreen; border-top: none; border-bottom: none;">
              <div class="col-xs-12    scroll-c" style="padding: 1% 1% 1% 2%; height: 100%; overflow: auto; border: 3px solid lightgray;display:flex">
                <div class="col-xs-10" style=" padding: 0;">
                  <div class="col-xs-12" style="padding:0;margin-top:1%;font-size:11px;margin-bottom:5%" *ngFor="let note of ticketNotesData">
                    <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; font-weight: bold;"><b>{{note.CreatedDate }}</b></div>

                    <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%;">{{note.Notes}}</div>
                  </div>
                </div>
                <div class="col-xs-2" style="padding:0">
                  <span style="float: right; width: 0; height: 0; border-top: 25px solid gray; border-left: 25px solid transparent;" (click)="PreviousNotesExpand = true"> <!--<img src="../../../assets/img/Black Expand View.png" style="height:15px" (click)="PreviousNotesExpand = true">--></span>
                </div>
              </div>
            </div>

          </div>



          <div class="col-xs-12" style="padding:0;height:100%" *ngIf="PreviousNotesExpand">
            <div class="col-xs-12" style="height: 100%; border: 1px solid lightgreen; padding: 0 0 0 0%">
              <div class="col-xs-12" style="height: 5%; margin-top: 0%; background-color: rgb(40,62,74); color: rgb(186,219,152); padding: 1%; text-align: center; ">
                <label>TICKET  NOTES</label>
                <span style="float:right;height:30px;padding:1%"> <img src="../../../assets/img/White x.png" style="height:20px;float:right;" (click)="PreviousNotesExpand = false"></span>
              </div>

              <div class="col-xs-12 scroll-c" style="padding:0;height:88%;overflow:auto">
                <div class="col-xs-12" style="padding: 0; margin-top: 1%; font-size: 11px; margin-bottom: 5%; padding-left: 2%;" *ngFor="let note of ticketNotesData">
                  <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; font-weight: bold;"><b>{{note.CreatedDate }}</b></div>

                  <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%;">{{note.Notes}}</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
    <input type="text" id="accotIdNotes" style="display:none;" />
    <input type="text" id="accotTypeNotes" style="display:none;" />
  </div>
  <app-dialog *ngIf="notesshowDialog && ticketdata.contactid>0" [(visible)]="notesshowDialog" style="height:100%" [CloseButtonColor]="'white'">
    <div class="col-xs-12" style="height: 6%; background-color: rgb(40,62,74); color: white; text-align: center; padding: 0%">

      <div class="col-xs-12" style="padding:1% 0 0 0;height:100%"><label>ACCOUNT DETAILS</label></div>
    </div>
    <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%">
      <app-new-notes style="height:100%"
                     [selectedTabName]="'Display All'"
                     [conid]="ticketdata.contactid"
                     [typ]="'Contact'"
                     [isModal]="false">
      </app-new-notes>
    </div>


  </app-dialog>

  <tickets *ngIf="isTicketsInfoDivVisible" [type]="'Contacts'">

  </tickets>

  <app-dialog [(visible)]="showAddEditAccount" *ngIf="showAddEditAccount" id="addEditAccount" [closable]="true" [OutSideClick]="(action!='View')? false:true">
    <app-add-edit-account *ngIf="action!='View'" [accountID]="this.account.id" [type]="'Account'" (sendMsgEvent)="ReceiveEvent($event)"></app-add-edit-account>
    <accountorcontactview *ngIf="action=='View'" [accountId]="this.account.id" [type]="'Account'"></accountorcontactview>
  </app-dialog>

  <app-dialog [(visible)]="showAddEditContact" *ngIf="showAddEditContact" id="addEditContact" [closable]="false" [OutSideClick]="false">
    <editcontact [contactid]="0" [accountId]="account.id" [type]="'Contact'" (sendMsgEvent)="ContactReceiveEvent($event)"></editcontact>
  </app-dialog>

  <app-dialog [(visible)]="showOpenTicketHistory" *ngIf="showOpenTicketHistory" id="OpenTicketHistoryDialog" [closable]="false">
    <div class="col-xs-12" style="height:100%;padding:0;">
      <div class="col-xs-12" style="padding:0.5% 0.5% 0 0;height:4%;border-radius:3px;width: 24px !important;float: right;">
        <img src="../../../assets/img/Black x.png" style="height:23px;float:right;margin-top:0%" (click)="CloseTicketHistory()" />
      </div>
      <div class="col-xs-12" style="height:95%;padding:0;">
        <app-history [type]="'Tickets'" [ContAccId]="ticketdata.contactid" [userType]="'Contact'"> </app-history>
      </div>
    </div>
  </app-dialog>
  <app-dialog [(visible)]="ShowDialog " *ngIf="ShowDialog " id="ShowDialog" [CloseButtonColor]="'black'">
    <div class="col-xs-12" style="height: 97%; padding:0%">
      <div class="col-xs-12" style="height: 2%;background-color:orangered">
      </div>
      <div class="col-xs-12" style="padding: 0.5%; height:5%;font-size: 14px">
       <label>EDIT TICKET</label>
      </div>
      <div *ngIf="!PreviousNotesExpand" class="col-xs-12" style="padding: 0 1% 1% 1%;">

        <div class="col-xs-12" style="height: 88%; border: 1px solid lightgray; padding: 1%; margin-top: 0.5%; ">

          <div class="col-xs-12" style="height:62%;padding: 0 1% 0 0;font-size: 12px;margin-top:2%">
            <div class="col-xs-12" style="height: 22%; padding: 0; margin-top: 2%;" *ngIf="ticketdata">
              <div class="col-xs-3" style="height:100%;font-weight:bold">
                Contact:
              </div>
              <div class="col-xs-9" style="padding: 0 2% 0 0; height: 100%; font-family: Calibri; color: #00b3f7d9; font-size: 13pt;" *ngIf="ticketdata">
                {{ticketdata.contactname}}
              </div>

            </div>
            <div class="col-xs-12" style="height: 22%; padding: 0; margin-top: 2%" *ngIf="ticketdata">
              <div class="col-xs-3" style="height:100%;font-weight:bold">
                Account:
              </div>
              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata">
                {{ticketdata.accountName}}
              </div>

            </div>

            <div class="col-xs-12" style="height: 22%; padding: 0; margin-top: 2%" *ngIf="ticketdata">
              <div class="col-xs-3" style="height:100%;font-weight:bold">
                Created By :
              </div>
              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata">
                {{ticketdata.UserName}}
              </div>

            </div>
            <div class="col-xs-12" style="height: 22%; padding: 0; margin-top: 2%" *ngIf="ticketdata">
              <div class="col-xs-3" style="height:100%;font-weight:bold">
                Ticket # :
              </div>
              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata">
                {{ticketdata.ticketId}}
              </div>

            </div>
            <div class="col-xs-12" style="height:22%;padding: 0;margin-top:2%">
              <div class="col-xs-3" style="height:100%;font-weight:bold">
                Status:
              </div>
              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata">
                <select name="status" class="form-control" [(ngModel)]="ticketdata.TicketStatus" (change)="onStausChnage($event.target.value)" [disabled]="ticketdata.TicketStatus == 4 || ticketdata.TicketStatus == 5 || ticketdata.TicketStatus == 6" style="height: 100%;padding: 2%;font-size: 12px;">
                  <option value="{{TicketStatus.id}}" *ngFor="let TicketStatus of ticketStatusListForDropDown">{{TicketStatus.itemName}}</option>
                </select>
              </div>
              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata == null ||ticketdata == undefined ">
                <select name="status" class="form-control" style="height: 100%;padding: 2%;font-size: 12px;">
                  <option selected>select  Status</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12" style="height:22%;padding: 0;margin-top:1%;">
              <div class="col-xs-3" style="height: 100%; font-weight: bold">
                Subject:
              </div>
              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata">
                <input class="form-control" name="Subject" type="text" [(ngModel)]="ticketdata.subject" placeholder="Subject" [disabled]="ticketdata.TicketStatus == 4 || ticketdata.TicketStatus == 5 || ticketdata.TicketStatus == 6" style="    height: 100%;padding: 2%;font-size: 12px;" />
              </div>

              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata == null ||ticketdata == undefined ">
                <input class="form-control" name="Subject" type="text" placeholder="Subject" disabled="disabled" style="height: 100%;padding: 2%;font-size: 12px;" />
              </div>
            </div>
            <div class="col-xs-12" style="height:22%;padding: 0;margin-top:1%">
              <div class="col-xs-3" style="height: 100%; font-weight: bold">
                Assigned To:
              </div>
              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata">
                <select name="status" class="form-control" id="AssignTo" (change)="AssignToChange($event.target.value)" [(ngModel)]="AssignTo" [disabled]="ticketdata.TicketStatus  == 4 || ticketdata.TicketStatus == 5 || ticketdata.TicketStatus == 6" style="    height: 100%;padding: 2%;font-size: 12px;">
                  <option value="{{task.category}}" *ngFor="let task of ticketUserAssignTo">{{task.itemName}}</option>
                </select>
              </div>

              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata == null ||ticketdata == undefined">
                <select name="status" class="form-control" id="AssignTo" (change)="AssignToChange($event.target.value)" style="    height: 100%;padding: 2%;font-size: 12px;">
                  <option selected>Select User </option>
                </select>
              </div>
            </div>
            <div class="col-xs-12" style="height:22%;padding: 0;margin-top:1%">
              <div class="col-xs-3" style="height: 100%; font-weight: bold">
                CC:
              </div>
              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata">
                <select name="status" class="form-control" (change)="CarbonCopy($event.target.value)" [(ngModel)]="CCTo" [disabled]="ticketdata.TicketStatus  == 4 || ticketdata.TicketStatus == 5 || ticketdata.TicketStatus == 6" style="height: 100%;padding: 2%;font-size: 12px;">
                  <option value="{{task.category}}" *ngFor="let task of ticketUserAssignTo">{{task.itemName}}</option>
                </select>
              </div>
              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata == null ||ticketdata == undefined">
                <select name="status" class="form-control" style="    height: 100%;padding: 2%;font-size: 12px;">
                  <option selected>Select Users</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12" style="height:22%;padding: 0;margin-top:1%">
              <div class="col-xs-3" style="height: 100%; font-weight: bold">
                Priority:
              </div>
              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata">
                <select name="ticketdata.priority" class="form-control" [(ngModel)]="ticketdata.priority" (change)="PriorityChnage($event)"
                        style="    height: 100%;padding: 2%;font-size: 12px;">
                  <option selected value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
              <div class="col-xs-9" style="padding: 0 2% 0 0;height:100%" *ngIf="ticketdata == null ||ticketdata == undefined">
                <select name="ticketdata.priority" class="form-control" [(ngModel)]="ticketdata.priority" (change)="PriorityChnage($event)"
                        style="    height: 100%;padding: 2%;font-size: 12px;">
                  <option selected value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
          </div>
          <div *ngIf="!PreviousNotesExpand" class="col-xs-12" style="height:40%;padding: 1% 1% 0 1%;">
            <div class="col-xs-12" style="height: 100%; border: 1px solid lightgray; padding: 1%;">
              <div class="col-xs-12" style="padding:0;height:100%">
                <textarea id="notesArea" rows="12" class="form-control scroll-c" [(ngModel)]="ticketNotes.Notes" type="text" name="notedescription" placeholder="Type here to add notes to this ticket" style="height:100%;overflow:auto;border: none;background: #f1f1f1;" (keyup)="NotesKeyUp()"></textarea>
              </div>
              <!--<div class="col-xs-12" style="height: 13%; text-align: right; margin-top: 2%;" *ngIf="showSaveNotes" [style.padding]="(TicketDeatilsExpand)? '0 0 0.5% 0': '0 0 2% 0'">
                <button style="background-color: white;padding: 0%;margin: 1% 4% 0 0;height:100%" (click)="clearNotes()">Cancel</button>
                <button style="background-color: rgb(162, 228, 119);border: 1px solid black !important;border-radius: 3px !important;height: 100%;margin-top:1%"
                        (click)="saveNotes()">
                  Save
                </button>
              </div>-->
            </div>
          </div>

          <div class="col-xs-12" style="height: 13%; text-align: right; margin-top: 2%;" *ngIf="statusChange">
            <button style="background-color: white;padding: 0%;margin: 1% 4% 0 0;" (click)="ClearOpenTicket()">Cancel</button>
            <button style="background-color: rgb(162, 228, 119);border: 1px solid black !important;border-radius: 3px !important;height: 24px;"
                    (click)="savetickets()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
</app-dialog>

  <app-dialog [(visible)]="SaveTicketDialog" id="SaveTicketDialog" *ngIf="SaveTicketDialog">
    <div class="col-xs-12" style="padding:5%">
      <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
      <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
        <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">TICKETS</div><br />
        <div class="col-xs-12" style="padding: 0; padding-top: 3%;">Save Changes to Ticket ?</div>
      </div>
      <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
        <div class="col-xs-7" style="padding: 0;">

        </div>
        <div class="col-xs-5" style="padding:0;">
          <div class="col-xs-6" style="padding:0">
            <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                    (click)="SaveTicketdata()">
              Save
            </button>
          </div>
          <div class="col-xs-6" style="padding:0">
            <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite;
             padding-left: 5%; color: #2ed1d1 " (click)="SaveTicketDialog = false">Cancel</button>
          </div>
          
        </div>
      </div>
    </div>
  </app-dialog>
