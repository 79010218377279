<div id="divNotesContainer" class="container" style="padding-left:0% !important;padding-right: 0;margin-top:0 !important;height:100%" >
  <div class="col-xs-12" style="text-align:center;margin-left: 0%;padding:0;height:100%" *ngIf="notesMainDiv && !openFileView">
    <div *ngIf="conid>0 && isModal" class="col-xs-4" style="height:100%;padding:0;">

      <account-contact-info [accountId]="conid" [Type]="typ" [isNotesDialog]="'true'">

      </account-contact-info>
    </div>
    <div [ngClass]="this.isModal ? 'col-xs-8' :'col-xs-12'" style="height:100%;padding:0;">
      <div class="col-xs-12 col-sm-12 col-md-12" style="background-color: #2A3740;padding-bottom: 0;height:5%;color:white;text-align:center;" *ngIf="this.isModal">
        <label style="margin-top:0.5%">NOTES HISTORY</label>
      </div>

      <div class="col-xs-12" style="padding:0;height:5%" *ngIf="!noteidDiv">
       
        <div class="col-xs-12" style="padding:0;height:100%">
          <div class="col-xs-6" style="text-align:left;height:100%" *ngIf="!this.isModal">
            <h3 style="margin:0;">Notes History</h3>
          </div>
          <div class="col-xs-6" style="padding:0px;height:95%">
            <div class="col-xs-1" style="padding:0;height:100%;width:13%">
              <button (click)="enterNotes()" style="background:none"> <img src="../../assets/img/Add Notes.jpg" style="height: 27px;" /></button>
            </div>
            <div class="col-xs-1" style="padding:0;height:100%;width:13%">
              <button (click)="View('List')" *ngIf="!NotesBlockView" style="background:none;"> <img src="../../assets/img/List View On.png" style="height: 20px;" /></button>
              <button (click)="View('List')" *ngIf="NotesBlockView" style="background:none;"> <img src="../../assets/img/List View Off.png" style="height: 20px;" /></button>
            </div>
            <div class="col-xs-1" style="padding:0;height:100%;width:13%">
              <button (click)="View('Block')" *ngIf="!NotesBlockView" style="background:none;"> <img src="../../assets/img/Expand View Off.png" style="height: 20px;" /></button>
              <button (click)="View('Off')" *ngIf="NotesBlockView" style="background:none;"> <img src="../../assets/img/Expand View ON.png" style="height: 20px;" /></button>
            </div>
            <div class="col-xs-7" style="padding:0;height:100%;width:61%">
              <select style="height:28px;width:100%;border-radius:5px;" (change)="Notes($event.target.value)" id="NotesTypeDropdown" >
                <option *ngFor="let note of NotesDropdownList" value={{note.value}}>
                  {{note.itemName}}
                </option>
              </select>
            </div>

          </div>

        </div>


      </div>

      <div class="col-xs-12" style="padding:0;" *ngIf="notesHistoryDiv && !noteidDiv" [style.height.%]="this.isModal ? '90' : '95'">

        <div class="tab-content" style="height:100%" *ngIf="!NotesBlockView">
          <div id="notesGrid" class="tab-pane fade in active" style="height:100%">
            <ag-grid-angular class="ag-theme-balham" *ngIf="notesTable" id="notsHGTCont" style="height:93%"
                             [gridOptions]="gridOptionsnotes"
                             [columnDefs]="columnDefsnotes"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="allnotes"
                             [context]="context"
                             [getRowHeight]="getRowHeight"
                             [pagination]="true"
                             [sideBar]="sideBar"
                             (columnResized)="onColumnResized($event)"
                             (selectionChanged)="notesChanged($event)"
                             (gridReady)="onAgGridReadynotes($event)">
            </ag-grid-angular>
          </div>
        </div>
        <div *ngIf="NotesBlockView" style="background-color:#e7eff7;overflow:auto;height:100%">
          <div class="col-xs-12" style="height:40%;margin-top:2%" *ngFor="let note of allnotes">
            <div class="col-xs-12" style="height:15%;text-align:left">
              <i class="fa fa-comment-o" style="color:#77C043;font-size:20px;"></i>
              <label style="margin-left:4%; font-size: 17px; color: #ada8a8;"> Notes</label>
              <label style="float:right"> {{note.created}} </label>
            </div>
            <div class="col-xs-12" style="height:85%;background-color:white;border-top:3px solid #77C043;padding:0;box-shadow: 1px 3px 12px 4px lightgrey;border-radius:0 0 5px 5px;">
              <div class="col-xs-12" style="height:25%;text-align:center;padding:0;">
                <div class="col-xs-12" style="padding:1%;">
                  <label style="color: rgb(49,189,240); border-radius: 5%; float: left">Contact:{{note.contactname}}</label>
                  <div class="col-xs-6" style="float:right;padding:0;">
                    <label>User: {{note.user}}</label>
                    <label style="float:right" (click)="openDisplaynotes(note)"><i class="fa fa-pencil"></i></label>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 scroll-c" style="height:50%;text-align:left;font-weight: 700;color: #00557d;overflow:auto">
                {{note.notedescription}}
              </div>
              <div class="col-xs-12" *ngIf="isDelink && note.id == selectedNoteId" style="text-align: left;padding: 0;background: #bfbebe;color: white; background: #3c3b3b;opacity: 0.9;height: 50%; margin-top: -16%;text-align: center;">
                <div class="col-xs-12" style="text-align:center;margin-top:5%;font-size:24px;color:white">
                  Select Document to Delink
                </div>
              </div>


              <div class="col-xs-12" style="height:25%;text-align:left;padding:0 0 0 0.5%">
                <div class="col-xs-12" style="height:100%;text-align:left;border-top: 2px solid #d3d3d3;padding:0;display:flex">
                  <div class="col-xs-2" style="padding:0;">
                    <span style="margin:0;" (click)="AddDocumentsNew('New',note)">
                      <img src="../../../assets/img/add document icon.png" style="height:28px;" />
                      <!--<input id="addFiles" name="AddFilesInput" type="file" (change)="addFiles($event)" multiple accept=".pdf" style=" opacity: 0; position: absolute; z-index: -1;display:none;" />-->

                    </span>
                    <span style="margin-left:6%">
                      <img src="../../../assets/img/Add Link.png" style="height:23px;" (click)="addlink(note)" />
                    </span>
                  </div>
                  <div class="col-xs-7" style="padding:0;">
                    <span style="font-size: 21px;margin: 2% 0 0 0;display:flex" *ngIf="note.isdocumentid">
                      <span *ngFor="let doc of note.docList" style="font-size: 21px;margin-left: 2%;margin-bottom: 0;">
                        <span>
                          <img src="../../../assets/img/PDF_file_icon.svg.png" style="height:23px;display:block" id="{{doc}}Off" (click)="!isDelink ? openfile(doc, note) : delink(doc, note)" />
                        </span>
                        <span>
                          <img src="../../../assets/img/PDF Document to De-Link.png" style="height:23px; display:none" id="{{doc}}ON" (click)="!isDelink ? openfile(doc, note) : delink(doc, note)" />
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="col-xs-3" style="padding:0;">
                    <span style="float:right">
                      <button class="btn" (click)="delinkDocs(note.id)" id="{{note.id}}Off" style="display:block">De-Link</button>
                      <button class="btn" (click)="delinkOff(note.id)" id="{{note.id}}ON" style="color:red;display:none;font-weight: 600;">De-Link"ON"</button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="noteidDiv" class="col-xs-12" style="padding:0;height:94%;border: 2px solid #00B0F0;">
        <div class="col-xs-12" style="padding:0;text-align:center;background-color:#badb98;color:black;height:6%">
          <label style="margin-top:0.5%">Add New Notes</label>
          <div *ngIf="!isModal" class="col-xs-1" style="text-align:right;color: gray;font-size:12px; font-weight: normal;float:right;padding:2px;" (click)="CancelNote()">
            <b>
              <img src="../../../assets/img/Collapse View .png" style="height:17px" />
            </b>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 0;height:85%;overflow:auto" id="noteidDiv">
          <div id="noteid" [froalaEditor]="editorOptions" [(froalaModel)]="notedescription" style="padding-right: 0%;height:99%"></div>

        </div>
        <div class="col-xs-4" id="saveNotesButtons" style="padding-left: 1%;padding:0; height:8%;float:right;margin-right:2%;" *ngIf="noteidDiv">
          <button class="btn cancel" (click)="CancelNote()" type="submit" style="padding: 0.75%;border: 1px solid;width:48%;font-weight:normal">Cancel</button>
          <button class="btn provider saveButton" (click)="SaveNote()" type="submit" style="margin-left: 2.2%;padding: 0.75%;width: 49%; font-weight:normal;border:1px solid black !important;">Save </button>
        </div>
      </div>
    </div>
  </div>
 
  <div style="padding:0;height:100%;" *ngIf="openFileView">
    <div class="col-xs-12" style="float:right;text-align:right;padding:0;">
      <div class="col-xs-12" style="padding:0;background-color:#2a3740;">
        <div class="col-xs-2">
          <i *ngIf="documentindex > 0" class="fa fa-arrow-left " aria-hidden="true" id="previous" style="color:white" (click)="previousdocument()"></i>
        </div>

        <div class="col-xs-5"> <label style="color:white"> Document {{documentindex+1}} of {{userdocumentcount}} </label></div>
        <div class="col-xs-2">
          <i *ngIf="documentindex < userdocumentcount-1" class="fa fa-arrow-right " aria-hidden="true" id="next" (click)="nextdocument()" style="color:white"></i>
        </div>
        <div class="col-xs-2"></div>
          <div class="col-xs-1" style="padding:0;float:right;margin-right:3%">
            <img src="../../../assets/img/Collapse View .png" style="height:17px" (click)="CloseopenFileView(null)" />
          </div>
        </div>

      </div>
    <div id="NotespdfDiv" class="col-xs-12">
      <!--<div id="pdfDiv" class="col-xs-12">

      </div>-->
    </div>


  </div>
  <div *ngIf="AttachDocument" style="height: 80%;">
    <div class="col-xs-11"><h3 style="text-align: center;"> Link Document to Note</h3></div>
    <div class="col-xs-1"><div class="col-xs-12" style="float:right;text-align:right"><img src="../../../assets/img/Collapse View .png" style="height:17px" (click)="CloseopenFileView('AttachDocument')" /></div></div>

    <documentmanagement [accountId]="this.conid" [selectedtype]="'Contact'" [isUploadDivVisible]="true" [documentview]="documentview" (sendMsgEvent)="onOpenUploadDocument($event)"></documentmanagement>
    <button class="btn saveButton" (click)="linkDocument()" style="width: 20%;border:1px solid black !important;float:right">Link Document</button>
  </div>
  <div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
    <div class="col-xs-12" style="padding:0;height:5%">
      <span class="col-xs-12" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;padding:2px;float:right;z-index:1">
        <b> <img src="../../../assets/img/Black x.png" style="height:20px" (click)="closeDetailsDialog()" /></b>

      </span>
    </div>
    <div class="col-xs-12" >
      <label>Date: &nbsp;</label><span>{{notesdata.created }}</span><br />
      <label>Time: &nbsp;</label><span>{{notesdata.created}}</span><br />
      <label>Contact: &nbsp;</label><span>{{notesdata.contactname}}</span><br />
      <label>User: &nbsp;</label><span>{{notesdata.user}}</span><br />
      <label>Notes: &nbsp;</label><span>{{notesdata.notedescription}}</span><br />
    </div>
    <ng-content></ng-content>

    <div class="col-xs-2 col-xs-offset-8" style="margin-left: 71%;">
      <span *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" class="editnote" style="margin-top: 20%;margin-right:-54%;">Edit</span>
      <span *ngIf="showEditNoteBtn" (click)="saveEditedNote()" class="save" style="margin-top: 20%;margin-right:-54%;">Save</span>
    </div>
  </div>
  <app-dialog [(visible)]="Upload" *ngIf="Upload" if="Upload" >
    <upload-document [accountId]="this.conid" [selectedtype]="'Contact'" [pageFrom]="'notes'" (sendMsgEvent)="SavedDocuments($event)"> </upload-document>
  </app-dialog>

  </div>

