export class Privilages {
  public assingeduserid: number;
  public resourceid: number;
  public canview: boolean;
  public canadd: boolean;
  public canedit: boolean;
  public candelete: boolean;
  public userid: number;

}
