import { Component, OnInit, ViewContainerRef} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, NG_VALIDATORS, Validator,  AbstractControl, ValidatorFn  } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Users } from '../models/users';
declare var $: any;
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  resetpwdform : FormGroup;
  user: Users = new Users();
  userdata: Users = new Users();
  showerrordiv: any; userid: number;
  resetpwd: boolean = false;
  isPasswordmatch: boolean = false;
  constructor(private router: Router,fb: FormBuilder, private route: ActivatedRoute, private users: UserService, private toastr: ToastrService, private viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
   // this.toastr.setRootViewContainerRef(viewContainerRef);
  }
 
  submitted = false;
  onSubmit() { this.submitted = true; }
  ngOnInit() {
    this.route.params.subscribe(params => {

      this.userid = params['userid'];
    });
    this.resetpwdform = new FormGroup({
      'password': new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(15)]),
      'confirmpassword': new FormControl(null, [Validators.required, Validators.minLength(8)]),
    });
  }
  Comparepwd() {
    let pas = $('#password').val();
    let con_pass = $('#confirmPassword').val();
    if (pas == con_pass) {
     // $('#errmsg').hide();
      //$('#errmsgdiv').css('display', 'none');
      $('#valid').show();

      $(setTimeout(function () {
        $('#userExist').css('display', 'none');
      }, 50));
    }
    else
      //this.isPasswordmatch = false;

      $('#valid').hide();
    $('#userExist').css('display', 'block');
    document.getElementById('userExist').innerHTML = 'Please provide New password';

    //$('#errmsgdiv').show();

  }

  showhide() {

    //$('#password').toggleClass("fa-eye fa-eye-slash");
    var input = $('#password');
    if (input.attr("type") == "password") {
      //$('#passwordEye').attr("class", "fa fa-fw fa-eye-slash field-icon toggle-password");
      $("#passwordEye").removeClass("fa fa-fw fa-eye field-icon toggle-password");
      $("#passwordEye").addClass("fa fa-fw fa-eye-slash field-icon toggle-password");

      input.attr("type", "text");
    } else {
      //$('#passwordEye').attr("class", "fa fa-fw fa-eye field-icon toggle-password--*/");

      $("#passwordEye").removeClass("fa fa-fw fa-eye-slash field-icon toggle-password");
      $("#passwordEye").addClass("fa fa-fw fa-eye field-icon toggle-password");

      input.attr("type", "password");
    }
  }
  showhide1() {

    //$('#password').toggleClass("fa-eye fa-eye-slash");
    var input = $('#confirmPassword');
    if (input.attr("type") == "password") {
      //$('#passwordEye').attr("class", "fa fa-fw fa-eye-slash field-icon toggle-password");
      $("#passwordEye1").removeClass("fa fa-fw fa-eye field-icon toggle-password1");
      $("#passwordEye1").addClass("fa fa-fw fa-eye-slash field-icon toggle-password1");

      input.attr("type", "text");
    } else {
      //$('#passwordEye').attr("class", "fa fa-fw fa-eye field-icon toggle-password--*/");

      $("#passwordEye1").removeClass("fa fa-fw fa-eye-slash field-icon toggle-password1");
      $("#passwordEye1").addClass("fa fa-fw fa-eye field-icon toggle-password1");

      input.attr("type", "password");
    }
  }

  CompareOldpassword() {
    this.users.GetUserByuserId(this.userid).then(result => {     
      this.userdata = result; 
    });
    let pas = $('#password').val();
    if (pas == this.userdata.password) {
      $('#userExist').show();
    } else {
      $('#userExist').hide();
    }
  }
  updatepassword() {
    let pas = $('#password').val().toString();
    let con_pass = $('#confirmPassword').val();
    if (pas == con_pass) {
      $('#errmsg').hide();
      this.users.ResetPassword(this.userid, pas).then(res => {
        if (res == "success") {
          this.toastr.success("Password Reset successfully.", "Reset", { timeOut: 600 });
          this.router.navigateByUrl(`/login`);
        }
        else {
          // this.toastr.setRootViewContainerRef(viewContainerRef);
          this.toastr.error("Something went wrong");
        }
      });
    }
    else
      $('#errmsg').show();
  }
}
