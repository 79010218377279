<div class="col-xs-12" style="padding:0;height:100%" *ngIf="!ShowConfigurationDialog">
  <ag-grid-angular style="width: 100%;height:100%" id="gridheight" class="ag-theme-balham" 
                   [gridOptions]="gridOptions"
                   [columnDefs]="columnDefs"
                   [rowData]="allCampaigns"
                   [sideBar]="sideBar"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   (selectionChanged)="onSelectionChanged($event)">
  </ag-grid-angular>


</div>
<div class="col-xs-12" *ngIf="ShowConfigurationDialog">

  <app-campaigns [CampaignId]="CampaignId" [ShowViewCampaign]="false" [Source]="'Dashboard'" (sendMsgEvent)="CloseDialog($event)" [type]=""> </app-campaigns>
</div>
