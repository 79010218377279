import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef, ViewEncapsulation, Input, Optional, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { Notes } from '../../models/notes';
import { Document } from '../../models/document';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Statistics } from '../../models/statistics';
import { Customers } from '../../models/customers';
import { Contacts } from '../../models/contacts';
import { Practice } from '../../models/practice';
import { ToastrService } from 'ngx-toastr';
import { Location, DatePipe } from '@angular/common';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { ApplicationComponent } from '../application.component';
import { UploadDocumentComponent } from '../../application/upload-document/upload-document.component';
import { DocumentmanagementComponent } from '../documentmanagement/documentmanagement.component';
import { Accounts } from 'src/app/models/accounts';
import { UserService } from 'src/app/services/user.service';
import { AccountsService } from 'src/app/services/accounts.service';
import { Dropdowntype } from 'src/app/models/dropdowntype';
declare var $: any;
declare var require: any;
@Component({
  selector: 'app-notes',
  host: {
    '(document:click)': 'handleClick($event)',
  },
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})

export class NotesComponent implements OnInit {
  minRowHeight = 100;
  currentRowHeight = this.minRowHeight;
  notesitemResource: any;
  notesitems = [];
  openFileView: boolean = false;
  DelinkOn: boolean = false;
  PDFselected: boolean = true;
  notesMainDiv: boolean = true;
  notesitemCount = 0;
  notesparams: any;
  private gridApi;
  private columnApi;
  @Input()
  allnotes: Notes[];
  @Input()
  currentuser: any;
  @Input()
  isOpenNewNotes: any;
  @Input()
  notedescription: string;
  @Input()
  noteslist: Array<Notes> = [];
  @Input()
  notes: Notes = new Notes();
  @Input()
  contact: Contacts = new Contacts();
  @Input()
  account: Accounts = new Accounts();
  @Input()
  customer: Customers = new Customers();
  _$element: any;
  @Input()
  statistics: Statistics = new Statistics();
  @Input()
  practice: Practice = new Practice();
  @Input()
  statisticlist: Array<Statistics> = [];
  @Input() getRowHeight;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  @Input() type: string = "";
  hidDiv: boolean = true;
  practiceid: number;
  //public getRowHeight;
  public staticsticsId: number = 1;
  //public type: string = "";
  public notesId: number = 0;
  public isdocumentid: string = "";
  CustomerDiv: boolean = true;
  Upload: boolean = false;
  public contactpermission: string;
  public Accountpermission: string;
  ContactDiv: boolean = true;
  AccountDiv: boolean = true;
  documentindex: number = 0;
  userdocumentcount: number = 0;
  userdocumentlist: any[] = [];
  imageTag: boolean = true;
  @Input()
  public columnDefsnotes: any[];
  @Input()
  public gridOptionsnotes: GridOptions;
  @Input()
  public elementRef;
  notesshowDialog = false;
  datePipe: DatePipe = new DatePipe("en-US");
  @Input()
  public context;
  @Input()
  source: string;
  @Input()
  permission: string;
  @Input() contactid: string;
  @Input() tabName: string;
  @Input() conid: number;
  @Input() typ: string;
  @Input() id: number;
  @Input() isModal: boolean = true;
  noteidDiv: boolean = false;
  notesHistoryDiv: boolean = true;
  NotesDec = false;
  isActiveIf: boolean = true;
  isNotesGridInit: boolean = false;
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  allContactsproviders: any[] = [];
  aggridheight: string;
  selectedNotes: Notes;
  isShowDetails: boolean = true;
  notesTable: boolean = true;
  ShownotesTable: boolean = false;
  isShowDetailsChecked: boolean = false;
  showEditNoteBtn: boolean = false;
  editNoteId: any;
  selectedTabName: string;
  public ShowdetailsgridOptionsnotes: GridOptions;
  public ShowdetailscolumnDefsnotes: any[];
  timezonevalue: string;
  userInfo: string;
  public statusIds: boolean = false;
  AccountIdArr: Dropdowntype[] = [];
  MyGridHeight: number = 330;
  documentlist: Document[] = [];
  NotesBlockView: boolean = false;
  public isDelink = false;
  public selectedNoteId: number;
  @ViewChild(DocumentmanagementComponent) docMgmtComp;
  @ViewChild(UploadDocumentComponent) uploadDoc;
  @ViewChild(UploadDocumentComponent) uploadDocComp: UploadDocumentComponent;
  AttachDocument: boolean = false;
  documentview: boolean = false;
  public sideBar;
  NotesDropdownList: Dropdowntype[] = [];
  public notesdata: Notes = new Notes();
  constructor(private http: HttpClient, private route: ActivatedRoute, private viewContainerRef: ViewContainerRef, private customerService: CustomerService, private userservice: UserService,
    private router: Router, private toastr: ToastrService, private location: Location, private opprtunityservice: OpprtunitiesService, private accountsService: AccountsService,
    public applicationComponent: ApplicationComponent, myElement: ElementRef) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.elementRef = myElement;


  }
  handleClick(event) {
    var clickedComponent = event.target;
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {
      $('#test').val('Notes');
    }
  }


  ngOnInit() {
     
    $('.fr-counter').attr('style', 'display:none;')
    var moment = require('moment-timezone');
    this.timezonevalue = moment.tz.guess();
    //$('#NotesTypeDropdown').val("AllContactsNotes");
    this.route.params.subscribe(params => {
      let providerid = Number($('#accotIdNotes').val());
      this.notes.accountid = providerid;
      this.type = params['type'];
      this.contactpermission = params['permission'];
      this.Accountpermission = params['practiceid'];
      this.statusIds = false;
       
      if (this.type == "account") {
        this.opprtunityservice.GetByAccountId(providerid).then(result => {
          this.account = result;
          this.contact = null;
          if (this.account.accnumber != null) {
            this.notes.type = "Customer";

          } else {
            this.notes.type = "Account";
          }
          this.CustomerDiv = true;
          this.ContactDiv = true;
          this.AccountDiv = false;
          this.imageTag = false;
           
          this.GetAllNotes(providerid, this.notes.type);

          this.notes.accountid = this.account.id;
        });

        document.getElementById('ownerlabel').innerHTML = this.currentuser.username;
        document.getElementById('labelHeader').className = "col-xs-12 noprovider";
        document.getElementById('labelHeader').innerHTML = "Account View";
        document.getElementById('statistics').className = "provider";
        document.getElementById('notesHeader').className = "col-xs-12 noprovider";
        document.getElementById('notesCount').className = "col-xs-12 noprovider";
      }
      else if (this.type == "contacts") {
        this.CustomerDiv = true;
        this.ContactDiv = false;
        this.AccountDiv = true;
        this.GetAllNotes(providerid, "Contact");
        this.customerService.GetContactById(providerid).then(result => {
          this.contact = result;
          this.notes.type = "Contact";
          this.conid = this.contact.id;
        });

        this.imageTag = true;
        document.getElementById('labelHeader').className = "col-xs-12 noprovider";
        document.getElementById('labelHeader').innerHTML = "Contact View";
        document.getElementById('statistics').className = "provider";
        document.getElementById('notesCount').className = "col-xs-12 noprovider";
      }
      else if (this.type == "Customer") {
        this.GetCustomerNotes(this.currentuser.id, providerid, this.type);
      }
      this.customerService.GetSpecialityList("NotesDrp", this.currentuser.id).then((notes) => {
        this.NotesDropdownList = notes;
      });
    });
    this.customerService.GetAllStatistics().then((statistics) => {
      statistics.forEach(statistics => {
        this.statisticlist.push(statistics)
      });

    });

    var url = window.location.href;
    if (url.indexOf('contacts/contacts') > -1) {
      this.MyGridHeight = 330;
    }
    else if (url.indexOf('account') > -1) {
      this.MyGridHeight = 325;
    }
    this.customerService.GetSpecialityList("AccountIds", this.currentuser.id).then((Acids) => {
      this.AccountIdArr = Acids;
    });
  }
  notesChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (rowEvent.api.getSelectedNodes()[0].data) {
        this.notesdata = rowEvent.api.getSelectedNodes()[0].data;
        this.NotesDec = true;
      }
    } else {
      this.notesdata = null;
      this.NotesDec = false;
    }

  }
  back() {
    this.location.back();
  }

  GetOpportunitiesnotes(id, type) {
     
    this.customerService.GetAllNotesList(this.currentuser.id, id, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = "Bhagya";
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      if (!this.isNotesGridInit)
        this.initializeAgGridNotes("");
      this.allnotes = taskhistory;
      for (let note of this.allnotes) {
        if (note.isdocumentid != null && note.isdocumentid != "") {
          note.docList = note.isdocumentid.split(",");
        }
      }
      this.notesitemResource.count().then(count => this.notesitemCount = count);
      this.notesparams = { offset: 0, limit: 10 }
    });
  }

  SaveNote() {
     
    if (this.tabName == 'Account' ) {
      this.conid = this.account.id;
    }
    this.noteidDiv = false;
    this.notesHistoryDiv = true;
    this.notes.accountid = (this.source == "Lead" || this.source == "Opportunity" || this.source == "TaskHistory" || this.source == "UserActivity(Detail)") ? Number(this.contactid) : this.conid;
    this.notes.type = this.typ;
    this.notes.isnewnote = 1;
    this.notes.userid = this.currentuser.id;
    this.notedescription = $('.fr-element').html();;
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/img, "");
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/gi, "");
    // this.isShowDetails = true;
    if (this.notes.notedescription != undefined && this.notes.notedescription != "") {
      this.customerService.SaveNotes(this.notes).then(result => {
        this.toastr.success("Notes saved successfully.", "Notes Saved", { timeOut: 600 });

        this.noteidDiv = false;
        $('.fr-element').empty();
        $('.fr-element').html("");
        //this.GetAllNotes(this.notes.accountid, "AllContactsNotes");
        this.Notes('AllContactsNotes');
        this.isOpenNewNotes = false;
      });
    }
  }

  UpdateNote(val) {
     
    this.type = this.tabName;
    this.noteidDiv = false;
    this.notesHistoryDiv = true;
    //this.notes.accountid = this.account.id;  Commented
    //this.notes.accountid = this.conid;
    this.notes.userfullName = this.contact.name;
    this.notes.type = this.selectedNotes.notestype;
    this.notes.isnewnote = 0
    this.notes.isdocumentid = this.isdocumentid;
    this.notes.id = this.selectedNotes.id;
    this.notes.userid = this.currentuser.id;
    this.notes.notedescription = this.selectedNotes.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/img, "");
    this.notes.notedescription = this.selectedNotes.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/gi, "");
    this.notes.isDelink = val;
    // this.isShowDetails = true;
    if (this.notes.notedescription != undefined && this.notes.notedescription != "") {
      this.customerService.UpdateNotes(this.notes).then(result => {
        //this.notedescription = "";
        this.toastr.success("Notes saved successfully.", "Notes Saved", { timeOut: 600 });
        $('.fr-element').empty();
        $('.fr-element').html("");
        if (this.type == "TaskHistory") {
          this.GetAllNotes(this.contactid, this.type);
        }
        else {
          if (this.typ == "Customer") {
            this.GetAllNotes(this.conid, "Account");
          } else {
            this.GetAllNotes(this.conid, this.type);
          }
          
        }
        
        this.isOpenNewNotes = false;
      });
    }
  }
  addlink(note) {

    this.openFileView = false;
    this.AttachDocument = true;
    this.selectedNotes = note;
    this.notesMainDiv = false;

  }
  public GetAllNotes(contactid, type) {
     
    this.allnotes = [];
    this.customerService.GetAllNotesList(this.currentuser.id, contactid, type).then((notes) => {
      notes.forEach(notes => {
        var moment = require('moment-timezone');
        var timeDisplay = notes.user + '<br />' + moment.tz(notes.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(notes.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        notes.userfullName = "Bhagyaa";
        notes.created = this.datePipe.transform(notes.created, "MM-dd-yyyy hh-mm-ss");
      });
      if (!this.isNotesGridInit)
        this.initializeAgGridNotes("");
      this.allnotes = notes;
      for (let note of this.allnotes) {
        if (note.isdocumentid != null && note.isdocumentid != "") {
          note.docList = note.isdocumentid.split(",");
        }
      }
      if (this.notesitemResource != undefined)
        this.notesitemResource.count().then(count => this.notesitemCount = count);
      this.notesparams = { offset: 0, limit: 10 }
    });
  }
  CancelNote() {
    this.noteidDiv = false;
    this.notesHistoryDiv = true;
    $('#checkDiv').css('height', '72%');
    this.isOpenNewNotes = false;
    //this.isShowDetails = true;
  }
  addnotes() {
    $('.fr-element').empty();
    this.notes = new Notes();
    document.getElementById('notesHeader').style.display = 'block';
    document.getElementById('noteid').style.display = 'block';
    document.getElementById('newNotesButton').style.display = 'none';
    //document.getElementById('saveNotesButtons').style.display = 'block';
    document.getElementById('displayNotes').style.display = 'none';
    document.getElementById('notesListTabsId').style.display = 'none';
    document.getElementById('notesGrid').style.display = 'none';
  }

  hidenotes() {
    document.getElementById('notesHeader').style.display = 'none';
    document.getElementById('noteid').style.display = 'none';
    document.getElementById('newNotesButton').style.display = 'block';
    // document.getElementById('saveNotesButtons').style.display = 'none';
    document.getElementById('displayNotes').style.display = 'block';
  }
  displayNotes(id, count) {
    this.notesId = count;
    var noteId = id + '_notes'
    var notes = document.getElementById(noteId).innerHTML;
    var dateId = id + '_date';
    var dateDetails = document.getElementById(dateId).innerHTML;
    document.getElementById('notesDate').innerHTML = dateDetails;
    document.getElementById('displayNotes').innerHTML = notes;
    $('.selectedNotes').removeClass('selectedNotes');
    $('#' + id + '_date').addClass('selectedNotes');
    $('#' + id + '_notes').addClass('selectedNotes');
    this.hidenotes();
    document.getElementById('nextNotes').style.display = 'block';
    document.getElementById('previousNotes').style.display = 'block';
    if (this.notesId == this.noteslist.length) {
      document.getElementById('nextNotes').style.display = 'none';
    }
    if (this.notesId == 1) {
      document.getElementById('previousNotes').style.display = 'none';
    }
  }

  practicedetails(id) {
    this.customerService.GetBypracticeId(id).then(result => {
      this.practice = result;
    });
  }

  nextStatistics() {
    var count = this.staticsticsId
    count++;
    if (count <= this.statisticlist.length) {
      document.getElementById('statisticsCount').innerHTML = '(' + count + ' of ' + this.statisticlist.length + ')';
      this.staticsticsId++;
      var id = this.staticsticsId;
      $('.statistic').attr('style', 'display:none');
      $('#' + id).attr('style', 'display:block');
      document.getElementById('previous').style.display = 'block';
      if (count == this.statisticlist.length) {
        document.getElementById('next').style.display = 'none';
      }
      //document.getElementById(id).style.display = 'block';
    }

  }
  previousStatistics() {
    var count = this.staticsticsId
    count--;
    if (count > 0) {
      document.getElementById('statisticsCount').innerHTML = '(' + count + ' of ' + this.statisticlist.length + ')';
      this.staticsticsId--;
      var id = this.staticsticsId;
      $('.statistic').attr('style', 'display:none');
      $('#' + id).attr('style', 'display:block');
      document.getElementById('next').style.display = 'block';
      if (count == 1) {
        document.getElementById('previous').style.display = 'none';
      }
    }
  }

  nextNotes() {
    var count = this.notesId
    count++
    if (count <= this.noteslist.length) {
      var idDetails = (this.noteslist)[this.notesId].id,
        notedescription = (this.noteslist)[this.notesId].notedescription,
        noteId = idDetails + '_notes',
        dateId = idDetails + '_date',
        dateDetails = document.getElementById(dateId).innerHTML;
      document.getElementById('notesDate').innerHTML = dateDetails;
      document.getElementById('displayNotes').innerHTML = notedescription;
      $('.selectedNotes').removeClass('selectedNotes');
      $('#' + noteId).addClass('selectedNotes');
      $('#' + dateId).addClass('selectedNotes');
      this.hidenotes();
      this.notesId++;
      document.getElementById('previousNotes').style.display = 'block';
      if (count == this.noteslist.length) {
        document.getElementById('nextNotes').style.display = 'none';
      }
    }
  }

  previousNotes() {
    var count = this.notesId
    count--
    if (count <= this.noteslist.length) {
      this.notesId--;
      var idDetails = (this.noteslist)[this.notesId].id,
        notedescription = (this.noteslist)[this.notesId].notedescription,
        noteId = idDetails + '_notes',
        dateId = idDetails + '_date',
        dateDetails = document.getElementById(dateId).innerHTML;
      document.getElementById('displayNotes').innerHTML = notedescription;
      document.getElementById('notesDate').innerHTML = dateDetails;
      $('.selectedNotes').removeClass('selectedNotes');
      $('#' + noteId).addClass('selectedNotes');
      $('#' + dateId).addClass('selectedNotes');
      this.hidenotes();
      document.getElementById('nextNotes').style.display = 'block';
      if (count == 0) {
        document.getElementById('previousNotes').style.display = 'none';
      }
    }
  }

  openNotesDialogAg(rowdata) {
    this.customerService.GetContactById(rowdata.id).then(result => {
      this.contact = result;
      this.customerService.GetByAccountId(this.contact.accountid).then(result => {
      });
    });
    this.GetCustomerNotes(this.currentuser.id, rowdata.id, 'Contact');
    this.notesshowDialog = !this.notesshowDialog;
    $('.dialog').width('1000px');

    //setTimeout(function () {
    //  $('#closeDetailsDialog').click();
    //}, 100);
  }

  GetCustomerNotes(currentuserid, contactid, type, accidofallcontacts: string = "0") {
     
    var moment = require('moment-timezone');
    this.customerService.GetAllNotesList(currentuserid, contactid, type, accidofallcontacts).then((taskhistory) => {
      taskhistory.forEach(items => {
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");

      });
      this.initializeAgGridNotes(type);
      this.allnotes = taskhistory;
      for (let note of this.allnotes) {
        if (note.isdocumentid != null && note.isdocumentid != "") {
          note.docList = note.isdocumentid.split(",");
        }
      }
    });

  }

  initializeAgGridNotes(val) {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000,toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'

    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',
      defaultToolPanel: 'none',
    };
    this.createColumnDefsnotes(val);

    this.context = this;
    this.isNotesGridInit = true;

  }
  createColumnDefsnotes(val, @Optional() isShowDetails: boolean = false) {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true},
      {
        field: 'type', headerName: '', hide: false, width: this.isShowDetailsChecked ? 100 : 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', hide: false, tooltipField: 'notedescription', width: this.isShowDetailsChecked ? 480 : 250, cellStyle: {'text-align':'left'}, cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.value && params.value.includes("Owner Changed")) {
            eDiv.innerHTML = "<label style='color:red'>" + params.value + "</label>";
          } else {
            eDiv.innerHTML = params.value;
          }
          
          
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: (val == "AllContactsNotes" && !this.isShowDetailsChecked) ? false : true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: this.isShowDetailsChecked ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return selfObj.isShowDetailsChecked == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: this.isShowDetailsChecked ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: this.isShowDetailsChecked ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: this.isShowDetailsChecked ? false : true, cellRenderer(params) { return params.value; } }
    ];

  }
  onColumnResized(event) {
    if (event.finished) {
    }
  }
  enterNotes() {
    if (this.contactpermission == 'view' || this.Accountpermission == 'view') {
      this.applicationComponent.isAccessDenied = true;
    }
    else {
      this.noteidDiv = true;
      this.isShowDetails = false;
      // this.notesHistoryDiv = false;
      //$('#checkDiv').css('height', '72%');

      $('.fr-element').empty();

    }
    this.isOpenNewNotes = true;
    setTimeout(function () {
      $('.fr-wrapper').attr('style', 'height:72%;overflow:auto;');
      $('.fr-element').html("");
    }, 100);

  }

  Notes(val) {
    $(".subButtons").removeClass("activeButton");
    $('#NotesTypeDropdown').val(val);
    
  
    this.initializeAgGridNotes("");
    this.selectedTabName = val;
    if (this.typ == undefined || this.typ == "Contacts")
      this.typ = "Contact";
    let type = this.typ ? this.typ == "Account" ? "Account" : this.typ == "Customer" ? "Customer" : "Contacts" : "Contacts";
    if (type == "Contact")
      type = "Contacts";
    // this.typ = "Contacts";
    this.contactid = this.contactid ? this.contactid : (($('#accotIdNotes').val() && $('#accotIdNotes').val() != "") ? $('#accotIdNotes').val() : "0");
    this.conid = (this.conid == undefined || this.conid == null) ? 0 : this.conid;
    if (this.tabName == val) {
      val = (val == "Contact" && type == "Customer") ? "Customer" : val;
      val = (val == "Contact" && type == "Account") ? "Customer" : val;
      if (this.source == 'reports') {
        this.GetCustomerNotes(this.currentuser.id, this.conid, (this.typ == 'Contact' || this.typ == 'Customer') ? this.typ : 'Contact');
      } else if (this.source == 'UserActivity(Detail)') {
        this.GetCustomerNotes(this.currentuser.id, this.conid != 0 ? this.conid : this.id, val == "Contact" ? this.typ : val);
      } else
        this.GetCustomerNotes(this.currentuser.id, this.contactid != "0" ? this.contactid : this.id, val == "Contact" ? this.typ : val);
    } else {
      if (val == 'AllContactsNotes') {
        if (type == "Contacts") {
          let allContactIds: string = "";
          this.customerService.GetContactById(this.conid).then(result => {
            this.contact = result;
            this.notes.type = "Contact";

            this.notes.accountid = this.contact.id;
            this.customerService.GetAllContactproviderByAccount(this.contact.accountid, this.currentuser.id).then((contacts) => {
              contacts.forEach(contacts => {
                this.allContactsproviders.push(contacts.id);
                allContactIds += contacts.id.toString() + ",";

              });
              if (allContactIds.length > 0)
                allContactIds = allContactIds.substring(0, allContactIds.length - 1);
              else
                allContactIds = "0";
              this.GetCustomerNotes(this.currentuser.id, allContactIds, val, this.contact.accountid.toString());
            });
          });
        } else {
          let allContactIds: string = "";
          this.customerService.GetAllContactproviderByAccount(this.conid, this.currentuser.id).then((contacts) => {
            this.allContactsproviders = [];
            contacts.forEach(contacts => {
              this.allContactsproviders.push(contacts.id);
              allContactIds += contacts.id.toString() + ",";
            });
            if (allContactIds.length > 0)
              allContactIds = allContactIds.substring(0, allContactIds.length - 1);
            else
              allContactIds = "0";
            this.GetCustomerNotes(this.currentuser.id, allContactIds, val, this.conid.toString());
          });
        }
      }
      else if (val == 'Contact' || val == 'Contacts') {
        this.customerService.GetAllContactproviderByAccount(this.account.id, this.currentuser.id).then((contacts) => {
          this.allContactsproviders = [];
          contacts.forEach(contacts => {
            this.allContactsproviders.push(contacts.id);
          });
          this.GetCustomerNotes(this.currentuser.id, this.allContactsproviders.join(','), val == "Contact" ? val : 'Contact', this.account.id.toString());
        });

      }
      else {
        this.customerService.getDesiredIdFromContactId(val, this.conid, type == "Contact" ? "Contacts" : type).then((ids) => {
          if (ids == null || ids == "null" || ids == "")
            ids = "0";
          this.GetCustomerNotes(this.currentuser.id, ids, val);
        });
      }
    }
    //let objSelf = this;
    //setTimeout(function () {
    //  objSelf.showdetail();
    //}, 500);
  }

  openDisplaynotes(params) {
    if (this.NotesBlockView) {
      this.notesdata = params;
      
    } else {
      this.notesdata = params.data;
      
    }
    this.type = params.type;
    this.NotesDec = true;
    if ($('input[name=Notes]').is(':checked')) {
      let usrFullName: string = params.data.userfullName;
      usrFullName = usrFullName.indexOf("<br />") >= 0 ? usrFullName.replace("<br />", "\n") : usrFullName;
      usrFullName = usrFullName.indexOf("<br />") >= 0 ? usrFullName.replace("<br />", "\n") : usrFullName;
      this.userInfo = usrFullName;
    }


    if (this.currentuser.id == params.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.id;
    } else
      this.showEditNoteBtn = false;

    var notesOffset = $("#divNotesContainer").offset();
    let notesWidth = $('#divNotesContainer').width();
    let notesHeight = $('#divNotesContainer').height();
    let windowWidth = $(window).width();
    //let showDialogWidth = 350;

    let selfObj = this;
    $(setTimeout(function () {
      if (selfObj.isModal) {
        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 25%;left: 76%;background-color:#ffffb4;border: 1px solid;overflow:auto;padding:0;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '81%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '14%');
        } else {
          $('#txtLongNoteDesc').css('height', '96%');
          $('#txtLongNoteDesc').css('padding', '6% 0 0 3%');

        }
      } else {
        var gridOffset = $("#notesGrid").offset();
        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 25%;left: 76%;background-color:#ffffb4;border: 1px solid;overflow:auto;padding:0;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '53%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '33%');
        } else {
          $('#txtLongNoteDesc').css('height', '89%');
          $('#txtLongNoteDesc').css('padding', '6% 0 0 3%');
        }
      }

      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);

    }, 50));
  }

  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();
  }
  saveEditedNote() {
    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.closeDetailsDialog();
        selfObj.GetAllNotes(selfObj.notes.accountid, this.type);
      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
  }

  onAgGridReadynotes(event) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    var thiscomp = this;
    var url = window.location.href;
    if (url.indexOf('opportunitiesdashboard') > -1) {
      $('.fr-wrapper').attr('style', 'height:68%;overflow:auto;')
      this.MyGridHeight = (screen.height * 0.3666);
    }

    if (url.indexOf('account-contact') > -1) {
      $('.fr-wrapper').attr('style', 'height:68%;overflow:auto;')
      this.MyGridHeight = (screen.height * 0.33666);
      $('#boxDiv').addClass('pull-right');

    }

    if (url.indexOf('leadsdashboard') > -1) {
      this.MyGridHeight = (screen.height * 0.355);
      $('.fr-wrapper').attr('style', 'height:68%;overflow:auto;')
    }
    if (url.indexOf('contacts/contacts') > -1) {
      this.MyGridHeight = (screen.height * 0.352222);
    }

    if (url.indexOf('tickets') > -1) {
      $(".mainCheckMark").removeClass("activeColor");
      $(".coustomButton").removeClass("activeButton");
      $(".coustomButton").removeClass("mainactiveButton");
      $("#tickets_span").addClass("activeColor");
      $("#tickets").addClass("mainactiveButton");
    }

    if (url.indexOf('calllist') > -1) {
      $('.fr-wrapper').attr('style', 'height:68%;overflow:auto;')
      this.MyGridHeight = (screen.height * 0.44888);
    }
    if (url.indexOf('tasklistdashboard') > -1) {
      $('.fr-wrapper').attr('style', 'height:68%;overflow:auto;')
      this.MyGridHeight = (screen.height * 0.355);//0.366
    }
    if (url.indexOf('contactsdashboard') > -1) {
      $('.fr-wrapper').attr('style', 'height:68%;overflow:auto;')
      this.MyGridHeight = screen.height * 0.3288;//0.344
    }




  }

  isActive(val) {
    if (this.tabName == val)
      return true;
    else
      return false;
  }
 
  View(val) {
    if (val == 'Block') {
      this.NotesBlockView = true;
    } else {
      this.NotesBlockView = false;
    }

  }
  onOpenUploadDocument(msg) {
    this.isdocumentid = msg;
  }
  linkDocument() {
    this.UpdateNote(false);
    this.AttachDocument = false;
    this.noteidDiv = false;
    this.openFileView = false;
    this.notesHistoryDiv = true;
    this.notesMainDiv = true;
  }

  AddDocumentsNew(val, Noteselected) {
    this.Upload = true;
    this.showDocumentAddDialogCss();
    this.selectedNotes = Noteselected
    $(setTimeout(function () {
      $("#addFiles").click();
    }, 500));
  }

  showDocumentAddDialogCss() {
    setTimeout(function () {
      $('#Upload .dialog').attr('style', 'width:44%;padding-bottom:0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height:63%;overflow:auto;height:77%;left:0;top: 13.5% !important;padding:0;right:32%');
    }, 4)
  }

  SavedDocuments(val) {
     
    if (val != "496" && val != "497" && val != "498" && val != "499" && val != "500" && val != "501" && val != "554") {
      this.Upload = false;
      this.isdocumentid = val;
      this.UpdateNote(false);
    }
  }

  openfile(documentid, note) {
    this.selectedNotes = note;
    this.userdocumentlist = this.selectedNotes.docList;
    this.userdocumentcount = this.selectedNotes.docList.length;
    this.getImageData(documentid);
  }
  getImageData(documentid) {
    var myBaseString = "";
    this.userservice.GetImageData(documentid).then(result => {
      myBaseString = result;
      let objSelf = this;
      this.openFileView = true
      $(setTimeout(function () {
        //objSelf.sendMsgEvent.emit(myBaseString);
        objSelf.onDocumentOpen(myBaseString);
      }, 100));

      $('.bodyContaint').css('height', '600px')
      $('#NotespdfDiv').css('');

    });
  }

  CloseopenFileView(val) {

    if (val == 'AttachDocument') {
      this.AttachDocument = false;
      this.notesMainDiv = true;
    } else {
      this.openFileView = false;
      this.documentindex = 0;
      this.userdocumentcount = 0;
    }

  }

  onDocumentOpen(myBaseString) {
    $('#NotespdfDiv').html('<embed src="' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
    $('#NotespdfDiv').attr('style', 'height: 93%;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');
  }
  delink(documentid, note) {
    $('#' + documentid + 'ON').attr('style', 'display:block;height:23px;');
    $('#' + documentid + 'Off').attr('style', 'display:none;height:23px;');
    let selfObj = this;
    setTimeout(function () {
      var isConfirmed = confirm("De-Link this document?");
      if (isConfirmed) {
        selfObj.isdocumentid = documentid;
        selfObj.selectedNotes = note;
        selfObj.UpdateNote(true);
        selfObj.isDelink = false;
      }
      else {
        $('#' + documentid + 'Off').attr('style', 'display:block;height:23px;');
        $('#' + documentid + 'ON').attr('style', 'display:none;height:23px;');
        selfObj.delinkOff(note.id)
      }
    }, 500);

  }
  delinkDocs(noteId) {
    this.isDelink = true;
    this.selectedNoteId = noteId;
    $('#' + noteId + 'Off').attr('style', 'display:none');
    $('#' + noteId + 'ON').attr('style', 'display:block;color:red;font-weight: 600;');
  }
  delinkOff(noteId) {
    this.isDelink = false;
    // this.selectedNoteId = noteId;
    $('#' + noteId + 'Off').attr('style', 'display:block');
    $('#' + noteId + 'ON').attr('style', 'display:none;color:red;font-weight: 600;');
  }

  closeNotes() {
    this.sendMsgEvent.emit("closeNotes");
  }

  previousdocument() {
    this.documentindex--;
    this.getImageData(this.userdocumentlist[this.documentindex]);
  }
  nextdocument() {
    //var viewtype = "crm";
    this.documentindex++;
    this.getImageData(this.userdocumentlist[this.documentindex]);
    //$('#pdfDiv').html('<embed src="' + this.userdocumentlist[this.documentindex].imagecontent + '" id = "pdfFileReader" style="width:100%;height:95%;" />')

  }

}
