export class ProposalItems {
  public ItemId: number;
  public ProposalItemName: string;
  public Categoryids: string;
  public ItemDescription: string;
  public SortOrder: number;
  public Price12: any;
  public Price24?: any;
  public Price36?: any;
  public Monthly12: any;
  public Monthly24?: any;
  public Monthly36?: any;
  public UserID: number;
  public deleteStatus: number;
  public CategoryName: string;

  public Units: number;
  public PriceTotal: number;
  public MonthlyTotal: number;
}
