export class Lastresponseview {
  public id: number;
  public userid: number;
  public questionid: number;
  public categoryid: number;
  public SubTaskGroup1id: number;
  public SubTaskGroup2id: number;
  public ResponseID: number;

}
