import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { GridOptions, RowDragEndEvent } from 'ag-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProposalCategory } from 'src/app/models/ProposalCategory';
import { ProposalItems } from 'src/app/models/ProposalItems';
import { UserService } from 'src/app/services/user.service';
declare var $: any;
import { SystemRowConfiguration } from 'src/app/models/system-row-configuration';
import { LeadsService } from 'src/app/services/leads.service';
import { ApplicationComponent } from '../application.component';
import { AuditLogModel } from '../../models/auditlog';
@Component({
  selector: 'app-proposal-configuration',
  templateUrl: './proposal-configuration.component.html',
  styleUrls: ['./proposal-configuration.component.css']
})
export class ProposalConfigurationComponent implements OnInit {
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  accessCategoriesDiv: boolean = true;
  accessItemsDiv: boolean = false;
  ProposalConfigDiv: boolean = true;
  createNewitem: boolean = false;
  createNewCategory: boolean = false;
  ExpandCategoryGrid: boolean = false;
  ExpandItemGrid: boolean = false;
  ProposalCategory: ProposalCategory = new ProposalCategory();
  allProposalCategory: ProposalCategory[];
  allProposalItems: ProposalItems[];
  public gridOptions: GridOptions;
  public gridProposalOptions: GridOptions;
  public columnDefs: any[];
  public columnDefsForItems: any[];
  currentUser: any;
  AddItemsForm: FormGroup;
  AddCategoryForm: FormGroup;
  proposalItems: ProposalItems = new ProposalItems();
  private gridApi;
  private gridColumnApi;
  public sideBar;
  public gridProposalApi;
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration();
  proposalType: string = "";
  @Input() SubMenuType: string = null;
  constructor(public applicationComponent: ApplicationComponent,private userService: UserService, private toastr: ToastrService, private leadsService: LeadsService) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));    
  }

  ngOnInit() {
    this.initializeAgGrid();
    //if (this.SubMenuType !== null && this.SubMenuType !== undefined && this.SubMenuType !== '') {
    this.AccessDiv("Categories");
    //} else {
      var obj: AuditLogModel = { id: 0, type: 'Proposal Configuration', action: 'Proposal Config', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.currentUser.id, dateofviewed: '', customertype: '', time: '', Title: 'Proposal Config', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
      this.applicationComponent.InsertAuditHistory(obj);
    //}
  }
  Expand(val) {
    if (val == 'Category') {
      this.ExpandCategoryGrid = !this.ExpandCategoryGrid;
    } else if (val == 'Item') {
      this.ExpandItemGrid = !this.ExpandItemGrid;
    }
  }
  getAddItemsForm(): FormGroup {
    return new FormGroup({
      'ProposalItemName': new FormControl(this.proposalItems.ProposalItemName, Validators.required),
      'Categoryids': new FormControl(this.proposalItems.Categoryids, Validators.required),
      'ItemSortOrder': new FormControl(this.proposalItems.SortOrder, Validators.required),
      'ItemPrice12': new FormControl(this.proposalItems.Price12, Validators.required),
      'ItemPrice24': new FormControl(this.proposalItems.Price24, Validators.required),
      'ItemPrice36': new FormControl(this.proposalItems.Price36, Validators.required),
      'MonthPrice12': new FormControl(this.proposalItems.Monthly12, Validators.required),
      'MonthPrice24': new FormControl(this.proposalItems.Monthly24, Validators.required),
      'MonthPrice36': new FormControl(this.proposalItems.Monthly36, Validators.required),
    });
  }
  getAddCategoryForm(): FormGroup {
    return new FormGroup({
      'ProposalItemName': new FormControl(this.ProposalCategory.CategoryName, Validators.required),
      'Categoryids': new FormControl(this.ProposalCategory.SortOrder, Validators.required),
    });
  }
  AccessDiv(value) {
    if (value == 'Categories') {
      this.AccessCategories();
    } else if (value == 'Items') {
      this.AccessItems();
    }

    var obj: AuditLogModel = { id: 0, type: 'Proposal Configuration', action: 'Proposal Config - ' + value, AccountOrContactId: 0, AccountOrContact: value, documentid: 0, userid: this.currentUser.id, dateofviewed: '', customertype: '', time: '', Title: 'Proposal Config - ' + value, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }
  AccessCategories() {
    this.ProposalConfigDiv = false;
    this.accessCategoriesDiv = true;
    this.GetAllProposalCategory();
    this.ProposalCategory = new ProposalCategory();
    this.proposalType = "Categories";
  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, rowSelection: 'single',
      toolPanelSuppressValues: true, enableCellChangeFlash: true, enableMultiRowDragging: true, animateRows: true, rowDragMultiRow: true, suppressMoveWhenRowDragging: true
      
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    
    this.createColumnDefs();
  
  }

  createColumnDefs() {
    let selfObj = this;
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 65,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, 
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, 
          rowDrag: true
        },
        { field: 'CategoryId', headerName: 'Id', hide: false, width:120},
        {
          field: 'CategoryName', headerName: 'Category', hide: false, width: 400, 
},
        { field: 'SortOrder', headerName: 'Sort Order', hide: false,width:120},
        {
          field: 'action', headerName: 'Action', hide: false,width:100, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:20%"/>';
            eDiv.addEventListener('click', function () {
              selfObj.deleteProposalCategory(params.data);
            });
            return eDiv;
          }
        }
    ];

    this.columnDefsForItems = [
      {
        field: '#',
        headerName: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        
      },
      { field: 'ItemId', headerName: 'Item Id', hide: false, width: 80 },
      {
        field: 'ProposalItemName', headerName: 'Item', hide: false, width: 350
},
      { field: 'ItemDescription', headerName: 'Item Description', hide: false, width: 150 },
      { field: 'Price12', headerName: 'Price12', hide: false, width: 85 },
      { field: 'Price24', headerName: 'Price24', hide: false, width: 85 },
      { field: 'Price36', headerName: 'Price36', hide: false, width: 85 },
      { field: 'Monthly12', headerName: 'Monthly12', hide: false, width: 90 },
      { field: 'Monthly24', headerName: 'Monthly24', hide: false, width: 90 },
      { field: 'Monthly36', headerName: 'Monthly36', hide: false, width: 90 },
      { field: 'SortOrder', headerName: 'Sort', hide: false, width: 70 },
      { field: 'Categoryids', headerName: 'Categoryids', hide: true, width: 120 },
      { field: 'CategoryName', headerName: 'Category', hide: false, width: 120 },
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:20%"/>';
          eDiv.addEventListener('click', function () {
            selfObj.deleteProposalItem(params.data);
          });
          return eDiv;
        }
      }
    ];

    
  }

  onRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("ProposalCategory");
  }
  //onRowProposalItemsDragEnd(event: RowDragEndEvent) {
  //  this.saverowconfiguration("ProposalItems");
  //}
  saverowconfiguration(type) {
    var sortoorder = [];
    if (type == "ProposalCategory") {
      this.gridApi.forEachNode((node) => {
        sortoorder.push(node.data.CategoryId);
      });
    } else {
      this.gridProposalApi.forEachNode((node) => {
        sortoorder.push(node.data.ItemId);
      });
    }

    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i == a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentUser.id;
    this.leadsService.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });
    });

  }
  onAgGridReady(event, type) {
    if (type == 'Item') {
      this.gridProposalApi = event.api;
    } else {
      this.gridApi = event.api;
    }
    
  }
  CreateNewCategory() {
    //this.ProposalCategory = new ProposalCategory();
    this.getAddCategoryForm();
    this.createNewCategory = true;
    this.ExpandCategoryGrid = false;
    this.ProposalCategory = new ProposalCategory();
    var NextsortOrder = this.allProposalCategory.sort((a, b) => b.SortOrder - a.SortOrder)[0].SortOrder;
    this.ProposalCategory.SortOrder = NextsortOrder + 1;
    
  }
  validateSortOrder() {
    if (this.ProposalCategory.SortOrder > 0) {
      $('#sortOrder').removeClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
      $('#sortOrder').addClass('ng-pristine ng-star-inserted ng-valid ng-touched');
      $('#sortOrder').prop('required', true);
    }
    
  }
  validateCategoryName() {
    if (this.ProposalCategory.CategoryName.length > 0) {
      $('#CategoryName').removeClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
      $('#CategoryName').addClass('ng-pristine ng-star-inserted ng-valid ng-touched');
      $('#CategoryName').prop('required', true);
    }
  }

  CloseaccessCategoriesDiv() {
    this.accessCategoriesDiv = false;
    this.ProposalConfigDiv = true;
    this.createNewCategory = false;
  /*this.sendMsgEvent.emit("");*/
    this.proposalType = "";
  }
  SaveProposalCategory() {
    this.ProposalCategory.UserID = this.currentUser.id;
    if (this.ProposalCategory.CategoryId == null || this.ProposalCategory.CategoryId == undefined || this.ProposalCategory.CategoryId == 0) {
      this.ProposalCategory.CategoryId = 0;
    }
    
    this.ProposalCategory.deleteStatus = 0;
    this.AddOrUpdateProposalCategory(this.ProposalCategory);
  }
  
  AddOrUpdateProposalCategory(Category) {
    this.userService.AddOrUpdateProposalCategory(Category).then(result => {
      this.GetAllProposalCategory();
      this.ProposalCategory = new ProposalCategory();
      this.createNewCategory = false;
      this.toastr.success("Proposal Category saved successfully.", "Proposal  Category Saved", { timeOut: 600 });
    });
  }
  GetAllProposalCategory() {
    this.userService.GetAllProposalCategory(this.currentUser.id).then(result => {
      this.allProposalCategory = result;
    });
  }
  deleteProposalCategory(Category) {
    var c = confirm("Are you sure you want to delete this Category?");
    if (c == true) {
      this.ProposalCategory = Category;
      this.ProposalCategory.deleteStatus = 1;
      this.AddOrUpdateProposalCategory(this.ProposalCategory);
    }
  }
  rowClickAgGrid(event) {
    if (event.node.selected && event.node.selected === true) {
      this.ProposalCategory = event.data;
      this.createNewCategory = true;
    }
   else { //For requirement Purpose Removed (Banu)
     this.ProposalCategory = null;
     this.ClearCategory();
     this.createNewCategory = false;
    }
      
  }
  ClearCategory() {
    this.ProposalCategory = new ProposalCategory();
  }

  AccessItems() {
    this.accessItemsDiv = true;
    this.ProposalConfigDiv = false;
    this.accessCategoriesDiv = false;
    this.GetAllProposalItems();
    this.GetAllProposalCategory();
    this.proposalItems = new ProposalItems();
    this.proposalItems.Categoryids = "0";
    //this.sendMsgEvent.emit("Items");
    this.proposalType = "Items";
  }

  CreateNewItem() {
    this.proposalItems = new ProposalItems();
    this.createNewitem = true;
    this.getAddItemsForm();
    $(setTimeout(function () {
      $('#ItemDescription').attr('style','border-left:5px solid #e05451;height:100%');
      $('#Monthly12').attr('style','border-left:5px solid #e05451');
      $('#Monthly24').attr('style','border-left:5px solid #e05451');
      $('#Monthly36').attr('style','border-left:5px solid #e05451');
    },100));
    this.ExpandItemGrid = false;
    var NextsortOrder = this.allProposalItems.sort((a, b) => b.SortOrder - a.SortOrder)[0].SortOrder;
    this.proposalItems.SortOrder = NextsortOrder + 1;
  }  

  CloseAccessItemsDiv() {
    this.accessItemsDiv = false;
    this.ProposalConfigDiv = true;
    this.createNewitem = false; 
    this.accessCategoriesDiv = false;
    this.allProposalCategory = null;
    this.proposalItems = new ProposalItems();
    //this.sendMsgEvent.emit("");
    this.proposalType = "";
  }
  SaveProposalItem() {
    this.proposalItems.UserID = this.currentUser.id;
    this.proposalItems.deleteStatus = 0;
    this.proposalItems.Price12 = this.proposalItems.Price12.replace('$','');
    this.proposalItems.Price24 = this.proposalItems.Price24.replace('$','');
    this.proposalItems.Price36 = this.proposalItems.Price36.replace('$','');
    this.proposalItems.Monthly12 = this.proposalItems.Monthly12.replace('$', '');
    this.proposalItems.Monthly24 = this.proposalItems.Monthly24.replace('$', '');
    this.proposalItems.Monthly36 = this.proposalItems.Monthly36.replace('$', '');
    if (this.proposalItems.ItemId == null || this.proposalItems.ItemId == undefined) {
      this.proposalItems.ItemId = 0;
    }
    this.InsertUpdateProposalItems(this.proposalItems)
  }

  InsertUpdateProposalItems(Items) {
    this.userService.InsertUpdateProposalItems(Items).then(result => {
      this.toastr.success("Proposal Item saved successfully.", "Proposal Item Saved", { timeOut: 600 });
      this.GetAllProposalItems();
      this.proposalItems = new ProposalItems();
      this.createNewitem = false;
      $('#ItemDescription').attr('style', 'border: 1px solid #ccc;height:100%');
      $('#Monthly12').attr('style', 'border: 1px solid #ccc');
      $('#Monthly24').attr('style', 'border: 1px solid #ccc');
      $('#Monthly36').attr('style', 'border: 1px solid #ccc');
      
    });
  }
  loadproposals(val) {
    this.GetAllProposalItems();
  }
  GetAllProposalItems() {
    var categoryid = $("#ProposalCategoryId").val() == undefined ? 0 : $("#ProposalCategoryId").val();
    this.userService.GetAllProposalItems(this.currentUser.id, categoryid).then(result => {
      this.allProposalItems = result;
    });
  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.proposalItems = event.api.getSelectedNodes()[0].data;
        this.proposalItems.Price12 = "$"+(this.proposalItems.Price12).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67;
        this.proposalItems.Price24 = "$"+(this.proposalItems.Price24).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67;
        this.proposalItems.Price36 = "$"+(this.proposalItems.Price36).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67;
        this.proposalItems.Monthly12 = "$" +(this.proposalItems.Monthly12).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67;
        this.proposalItems.Monthly24 = "$" +(this.proposalItems.Monthly24).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67;
        this.proposalItems.Monthly36 = "$" +(this.proposalItems.Monthly36).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67;
        this.createNewitem = true;

        if (this.proposalItems.ItemDescription.length > 0)
          $('#ItemDescription').attr('style', 'border-left:5px solid rgb(162, 228, 119);height:100%');

        if (this.proposalItems.Monthly12 != '' && this.proposalItems.Monthly12 != undefined)
          $('#Monthly12').attr('style', 'border-left:5px solid rgb(162, 228, 119)');

        if (this.proposalItems.Monthly24 != '' && this.proposalItems.Monthly24 != undefined)
          $('#Monthly24').attr('style', 'border-left:5px solid rgb(162, 228, 119)');

        if (this.proposalItems.Monthly36 != '' && this.proposalItems.Monthly36 != undefined)
          $('#Monthly36').attr('style', 'border-left:5px solid rgb(162, 228, 119)');
      }
    } else {
      this.proposalItems = new ProposalItems();
      this.clearItemFields();
      this.createNewitem = false;
    }
    
  }

  formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

  deleteProposalItem(Item) {
    var c = confirm("Are you sure you want to delete this Item?");
    if (c == true) {
      this.proposalItems = Item;
      this.proposalItems.deleteStatus = 1;
      this.InsertUpdateProposalItems(this.proposalItems);
    }
  }

  clearItemFields() {
    this.proposalItems = new ProposalItems();
    $('#ItemDescription').attr('style', 'border-left:5px solid #e05451;height:100%');
    $('#Monthly12').attr('style', 'border-left:5px solid #e05451');
    $('#Monthly24').attr('style', 'border-left:5px solid #e05451');
    $('#Monthly36').attr('style', 'border-left:5px solid #e05451');
  }
  selectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {      
        this.ProposalCategory = event.api.getSelectedNodes()[0].data;
        this.createNewCategory = true;
      }
    } else {
      this.ClearCategory();
    }
  }
  KeyUp(val) {
    if (val == 'Description') {
      if (this.proposalItems.ItemDescription.length > 0)
      $('#ItemDescription').attr('style', 'border-left:5px solid rgb(162, 228, 119);height:100%');
    } else if (val == 'Monthly12' || val == 'Monthly24' || val == 'Monthly36') {
      if (this.proposalItems.Monthly12 > 0)
        $('#Monthly12').attr('style', 'border-left:5px solid rgb(162, 228, 119)');

      if (this.proposalItems.Monthly24 > 0)
        $('#Monthly24').attr('style', 'border-left:5px solid rgb(162, 228, 119)');

      if (this.proposalItems.Monthly36 > 0)
        $('#Monthly36').attr('style', 'border-left:5px solid rgb(162, 228, 119)');
    }
  }


  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
  }
}
