import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { GridOptions, ColumnUtils } from "ag-grid/main";

import { ToastrService } from 'ngx-toastr';
import { AccountsHistory } from '../../../models/AccountHistory';
import { Accounts } from '../../../models/accounts';
import { Notes } from '../../../models/notes';
import { ProjectPickListModel } from '../../../models/PickListModel';
import { ProjectFields, ProjectForecastViewModel, Projects, ProjectStages, ProjectSteps, ProjectStepStatusModel } from '../../../models/ProjectConfiguration';
import { Viewaccount } from '../../../models/viewaccount';
import { CustomerService } from '../../../services/customer.service';
import { AccountDetailsComponent } from '../../account-details/account-details.component';
import { ApplicationComponent } from '../../application.component';
import { OwnershipsharingComponent } from '../../ownershipsharing/ownershipsharing.component';
import { NewNotesComponent } from '../../new-notes/new-notes.component';
import { Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-create-projects',
  templateUrl: './create-projects.component.html',
  styleUrls: ['./create-projects.component.css']
})
export class CreateProjectsComponent implements OnInit {
  viewaccount: Viewaccount = new Viewaccount();
  accountHistory: AccountsHistory = new AccountsHistory();
  @ViewChild(OwnershipsharingComponent) ownrshipComp: OwnershipsharingComponent;
  @ViewChild(AccountDetailsComponent) accdetailcmp;
  @ViewChild(NewNotesComponent) notecmp;
  account: Accounts = new Accounts();
  Project: Projects = new Projects();
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  showCreateProjectDialog: boolean = false;
  public practiceid: string;
  currentuser: any;
  isSharing: boolean = false;
  action: string;
  showAddEditAccount: boolean = false;
  rightMainDivHideShow: boolean = true;
  accountSharingid: number = 0;
  AddContacttableDiv: boolean = false;
  tab: string = 'Account';
  noteslist: Array<Notes> = [];
  isModal: boolean = false;
  conid: number;
  locId = 0;
  userprevilages: any;
  permission: string;
  typ: string;
  notesshowDialog = false;
  NotesBlockView: boolean = false;
  showAddEditContact: boolean = false;
  Project_OverView_Div: boolean = true;
  Project_Steps_Div: boolean = false;
  Project_Steps_List: ProjectSteps[] = [];
  @Input() accountContactId;
  @Input() accountContactType;
  @Input() ProjectId;
  @Input() ViewType;
  ProjectFieldsList: ProjectFields[] = [];
  NewForm = new FormGroup({});
  //NewStageFormSteps = new FormGroup({});
  @ViewChild('NewStageFormSteps') myForm: NgForm;
  Fields: any;
  FormDataModified: boolean = false;
  SaveProjectDialog: boolean = false;
  //public gridOptions: GridOptions;
  public columnDefs: any[];
  public sideBar;
  datePipe: DatePipe = new DatePipe("en-US");
  ProjectsList: Projects[] = [];
  public context;
  SelectedStage: number = 0;
  SelectedStageName: string = 'Project Details';
  SelectedStageIndex: number = -1;
  SelectedProjectType: number = 0;
  ProjectTypeList: ProjectPickListModel[] = [];
  ProjectStagesList: ProjectStages[] = [];
  OriginalProjectStagesList: ProjectStages[] = [];
  Proj_Stages_List_by_ProjType: ProjectStages[] = [];
  Proj_StagesCount: number;
  ShowAddNotesDiv: boolean = false;
  public gridApi;
  notes: Notes = new Notes();
  notedescription: string;
  public gridOptionsnotes: GridOptions;
  public columnDefsnotes: any[];
  allnotes: Notes[];
  NotesDec: boolean = false;
  projectTypeName: string = '';
  public notesdata: Notes = new Notes();
  ProjectStepsList: ProjectSteps[] = [];
  Project_Stage_Status: string = '';
  ProjStepStatus: ProjectStepStatusModel[] = [];
  DupProjStepStatus: ProjectStepStatusModel[] = [];
  public isDelink = false;
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  public gridOptionsView: GridOptions;
  public ForecastViewcolumnDefs;
  ForecastDataObj: ProjectForecastViewModel[] = [];

  selectedItems = [];
  dropdownSetting = {};
  dropdownList = [];
  StepStatusButton: boolean = true;
  ProjectStatusID: number;

  CloseCreateNewWarning: boolean = false;
  PreviousNotesExpand: boolean = false;

  public ShowPDF: boolean = false;
  public EditDocument: boolean = true;
  DocExpandView: boolean = false;
  constructor(private customerService: CustomerService, public applicationComponent: ApplicationComponent, private toastr: ToastrService,private router: Router) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }
  View(val) {
    if (val == 'Block') {
      this.NotesBlockView = true;
    } else {
      this.NotesBlockView = false;
    }

  }

  getPropertyName(id, name) {  
    if (name == 'UserAccess') {
      var current_element = (this.Fields).filter(function (obj) {
        return obj.FieldName == "UserAccess";
      });
      if (current_element.length > 0) {
        current_element = current_element[0];
        var dt = current_element.Data;
        if (id != "") {
         // var NameString = dt.filter(x => x.itemName);
          var ids = id.split(',');
          var NameString = [];
          this.selectedItems = [];
          //ids.forEach(ele => {
          //  NameString.push(dt.filter(x => x.id == ele)[0].itemName);
          //});
          ids.forEach(ele => {
            this.selectedItems.push({ "id": dt.filter(x => x.id == ele)[0].id, "itemName": dt.filter(x => x.id == ele)[0].itemName });
            NameString.push(dt.filter(x => x.id == ele)[0].itemName);
          });

          return NameString.join(",");
        } else {
          this.selectedItems = [];
        }
      }
    } else {
      var current_element = (this.Fields).filter(function (obj) {
        return obj.FieldName == name.trim();
      });
      if (current_element.length > 0) {
        current_element = current_element[0];
        var dt = current_element.Data;
        if (dt && dt.length > 0) {
          var dt_element = dt.filter(function (item) {
            if (item.hasOwnProperty('DataId')) { return item.DataId == id; }
            else { return item.id == id; }

          })
          if (dt_element.length > 0) {
            return dt_element[0].itemName;
          } else {
            return "";
          }
        }
        else {
          return "";
        }
      }
      else {
        return "";
      }
    }
    

    
  }
  ngOnInit(): void {
    
    this.LoadAccountInformation(this.accountContactId, this.accountContactType);

    if (this.ViewType == 'Project Step View') {
      this.initializeAgGrid();
    }
    else {
      this.initializeAgGridNotes("");
    }
  
    if (this.ProjectId == 0 || this.ProjectId == undefined || this.ProjectId == null) {
      this.showCreateProjectDialog = true;
      this.showCreateProjectDialogCss();
    }
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 10
    };
  }
  showCreateProjectDialogCss() {
    $(setTimeout(function () {
      $('#showCreateProjectDialog .dialog').attr('style', 'width: 35%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82.5%;padding: 0;top: 10%;left: 63%;');
    }, 4));
  }
  delink(documentid, note) {
    $('#' + documentid + 'ON').attr('style', 'display:block;height:23px;');
    $('#' + documentid + 'Off').attr('style', 'display:none;height:23px;');
    let selfObj = this;
    setTimeout(function () {
      var isConfirmed = confirm("De-Link this document?");
      if (isConfirmed) {
        //selfObj.isdocumentid = documentid;
        //selfObj.selectedNotes = note;
        //selfObj.UpdateNote(true);
        selfObj.isDelink = false;
      }
      else {
        $('#' + documentid + 'Off').attr('style', 'display:block;height:23px;');
        $('#' + documentid + 'ON').attr('style', 'display:none;height:23px;');
        selfObj.delinkOff(note.id)
      }
    }, 500);

  }
  openfile(documentid, note, index) {

    //this.documentindex = index
    //this.selectedNotes = note;
    //this.userdocumentlist = this.selectedNotes.docList;
    //this.userdocumentcount = this.selectedNotes.docList.length;

    //this.getImageData(documentid);


  }
  delinkDocs(noteId) {
    this.isDelink = true;
    //this.selectedNoteId = noteId;
    $('#' + noteId + 'Off').attr('style', 'display:none');
    $('#' + noteId + 'ON').attr('style', 'display:block;color:red;font-weight: 600;');
  }
  delinkOff(noteId) {
    this.isDelink = false;
    // this.selectedNoteId = noteId;
    $('#' + noteId + 'Off').attr('style', 'display:block');
    $('#' + noteId + 'ON').attr('style', 'display:none;color:red;font-weight: 600;');
  }
  LoadData(AccountOrContactId, AccountOrContactIType, ProjectId, ViewType, rowData) {
   
    this.accountContactId = AccountOrContactId;
    this.accountContactType = AccountOrContactIType;

    this.ProjectId = ProjectId;
    this.ViewType = ViewType;
    this.loadProjectByProjectId(this.ProjectId);
    this.Project_Steps_Div = false;
    this.Project_OverView_Div = true;
    this.SelectedStage = 0;
    this.LoadAccountInformation(AccountOrContactId, this.accountContactType);   
    this.Project = rowData;
    this.ProjectStatusID = this.Project.ProjectStatus;
    this.notecmp.Notes('Display All');
    this.projectTypeName = this.Project.ProjectTypeName;
    if (this.Project.UserAccess != undefined) {
      var userAccesIds = this.Project.UserAccess.split(",");

      this.selectedItems = this.dropdownList.filter(function (item) {
        return userAccesIds.indexOf(item.id) === -1;
      });
    }
    this.ProjectFormat();

  }
  ProjectFormat() {
    this.Project.TargetGoLiveDate = this.datePipe.transform(this.Project.TargetGoLiveDate, "yyyy-MM-dd");
    this.Project.OnsiteTrainingDate1From = this.datePipe.transform(this.Project.OnsiteTrainingDate1From, "yyyy-MM-dd");
    this.Project.OnsiteTrainingDate1To = this.datePipe.transform(this.Project.OnsiteTrainingDate1To, "yyyy-MM-dd");
    this.Project.OnsiteTrainingDate2From = this.datePipe.transform(this.Project.OnsiteTrainingDate2From, "yyyy-MM-dd");
    this.Project.OnsiteTrainingDate2To = this.datePipe.transform(this.Project.OnsiteTrainingDate2To, "yyyy-MM-dd");
    this.Project.RemoteTrainingDate1From = this.datePipe.transform(this.Project.RemoteTrainingDate1From, "yyyy-MM-dd");
    this.Project.RemoteTrainingDate1To = this.datePipe.transform(this.Project.RemoteTrainingDate1To, "yyyy-MM-dd");
    this.Project.RemoteTrainingDate2From = this.datePipe.transform(this.Project.RemoteTrainingDate2From, "yyyy-MM-dd");
    this.Project.RemoteTrainingDate2To = this.datePipe.transform(this.Project.RemoteTrainingDate2To, "yyyy-MM-dd");
  }
  Display(accId, accountname, addr1, addr2, city, state, zip, country, phone, fax, website, Accountiddata, Providence) {

    if (city != null) {
      var cityArray = city.split(' ');
      var newCity = '';
      for (var i = 0; i < cityArray.length; i++) {
        if (newCity == '') {
          newCity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
        else {
          newCity = newCity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
      }
    }
    if (website != null && website.indexOf('www.') >= 0) {
      website = ((website).split('www.')[1])
    } else if (website != null && website.indexOf('ww.') >= 0) {
      website = ((website).split('ww.')[1])
    }


    var properstate = (state != null) ? state.charAt(0).toUpperCase() + state.substr(1).toLowerCase() : "";
    this.viewaccount.accountId = accId;
    this.viewaccount.viewaccountname = accountname;
    this.viewaccount.viewaddress1 = addr1;
    this.viewaccount.viewaddress2 = addr2;
    this.viewaccount.viewcity = newCity == null ? "" : newCity;
    this.viewaccount.viewstate = properstate;
    this.viewaccount.viewzip = zip;
    this.viewaccount.viewCountry = country;
    this.viewaccount.viewphone = phone;
    this.viewaccount.viewfax = fax;
    this.viewaccount.viewwebsite = website;
    this.viewaccount.Accountiddata = Accountiddata;
    this.viewaccount.ProvidenceIsland = Providence;
    this.viewaccount.AccountHistoryNote = this.accountHistory.HistoryNotes;
    this.accdetailcmp.loadAccountId(accId);

  }

  btnClickReceived(event) {
    if (event == "ExpandSoftwareLicense" || event == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(event);
    } else {
      if (event.btnName == 'addContact') {
        this.addContact();
      } else if (event.btnName == 'AccountInfo') {
        this.AccountInfo();
      }
      else if (event.btnName == 'Accountsharing') {
        this.AccountsharingAg(event.val);
      }
      else if (event.btnName == 'openNotes') {
        this.openAccountNotes();
      }
    }

  }
  AccountInfo() {
    localStorage.setItem('RhxAccountVal', (this.account.id).toString());
    let SO = this;
    $(setTimeout(function () {
      SO.applicationComponent.activateSubButtons('empty');
      $(setTimeout(function () {
        SO.router.navigateByUrl(`/application/account/Accounts`);
        SO.applicationComponent.ExpandSelectedMenu('Accounts');
        $(".subButtons").removeClass("activeButton");

      }, 100));
    }, 50));

  }
  openAccountNotes() {
    this.isModal = true;
    this.conid = this.account.id;
    this.typ = 'Account';
    this.notesshowDialog = true;
    this.NotesDialogCss();

  }

  NotesDialogCss() {
    $(setTimeout(function () {
      $('#notesshowDialog .dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }

  addContact() {
    if (this.practiceid == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    } else {

      if (this.userprevilages != undefined) {
        for (var j = 0; j < this.userprevilages.length; j++) {
          if ("Contacts" == this.userprevilages[j].resource) {
            if (this.userprevilages[j].canadd == false) {
              this.toastr.clear();
              this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
              return;
            }
          }

        }
      }


      this.showAddEditContact = true;
      this.showAddEditContactCss();
      //this.contactId = 0;


    }

  }


  showAddEditContactCss() {
    setTimeout(function () {
      $('#addEditContact .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }


  ExpandAddEditAccountDialog(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 100%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 86%;padding: 0;top: 10%;left: 10%;max-width: 88%;');
      }, 4)
    } else {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
      }, 4)
    }
  }

  LoadAccountInformation(AccountContactId, accountconType) {
    
    var AccConId = 0;
    if (accountconType == 'Account') {
      AccConId = AccountContactId;
      this.customerService.GetByAccountId(AccConId).then(result => {
        this.account = result;
        this.customerService.GetAccountHistoryById(this.account.id, this.currentuser.id).then(result => {
          if (result != null) {
            this.accountHistory = result;
          }
          this.getProjectFields();
        });
        this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.ProvidenceIsland);
        //this.AccountsharingAg('Edit');
        if (this.Project && this.Project.ProjectID > 0) {
          $("#ProjectTypeId").prop("disabled", true);

        }
      });
    } else {
      this.customerService.LoadContactById(AccountContactId).then(result => {
        AccConId = result.accountid;
        this.customerService.GetByAccountId(AccConId).then(result => {
          this.account = result;
          this.customerService.GetAccountHistoryById(this.account.id, this.currentuser.id).then(result => {
            if (result != null) {
              this.accountHistory = result;
            }
            this.getProjectFields();
          });
          this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.ProvidenceIsland);
          //this.AccountsharingAg('Edit');
          if (this.Project && this.Project.ProjectID > 0) {
            $("#ProjectTypeId").prop("disabled", true);

          }
        });
      });
    }


  }
  dialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', ' width: 32%;padding - bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min - height: 23%;overflow: visible;height: 24%;');
    }, 4));
  }
  AccountsharingAg(selectedval) {
    if (this.practiceid == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    } else {

      if (selectedval == "EditAccount") {
        this.customerService.getAccountPermission(this.account.id, this.currentuser.id).then(perresult => {
          this.account.isAccountPermission = perresult;
          if (perresult == 'View') {
            this.showAddEditAccount = false;
            this.applicationComponent.isAccessDenied = true;
            this.dialogCss();
          } else {
            this.isSharing = false;
            this.action = selectedval;
            this.showAddEditAccount = true;
            this.showAddEditAccountCss();
          }

        });

      }
      else if (selectedval == "View") {
        this.isSharing = false;
        this.action = selectedval;
        this.showAddEditAccount = true;
        this.showAddEditAccountCss();
      }
      else {
        this.isSharing = true;
        this.isSharingCss();
        this.rightMainDivHideShow = false;
        this.accountSharingid = 1;
        this.AddContacttableDiv = false;
        //if (this.isSharing) {
        //  this.ownrshipComp.loadAccountInformation(this.account.id, 0);
        //}

      }
    }

  }

  showAddEditAccountCss() {
    setTimeout(function () {

      $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }

  isSharingCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width: 35%;padding-bottom: 0px;border: 1px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 82.5%;padding: 0;top: 10%;left: 63%;');
    }, 4)

  }

  getProjectFields() {
    this.customerService.GetProjectFields(this.currentuser.id).then(result => {
      this.ProjectFieldsList = result;
      result.forEach(ele => {
        if (ele.DataType == 'Pick List' || ele.DataType == 'System Lookup') {
          if (ele.FieldName == 'ProjectLeaderId' || ele.FieldName == 'Trainer1' || ele.FieldName == 'Trainer2' || ele.FieldName == 'UserAccess') {
            this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, 1, 0, 0).then((Owner) => {
              ele['Data'] = Owner;
              this.dropdownList = Owner;
            });
          }

          else if (ele.FieldName == "ProjectStatus") {
            this.customerService.GetProjectPickListData(ele.ProjectFieldId, ele.FieldName, this.currentuser.id).then((accounttype) => {
              ele['Data'] = accounttype;
              if (this.Project.ProjectID == undefined) {
                ele['Data'] = accounttype.filter(x => x.DataId !== 25);
              } else { ele['Data'] = accounttype; }
              if (this.Project.ProjectID != undefined) {
                this.customerService.getProjectStatus(this.Project.ProjectID).then(res => {
                  if (res) {
                   
                    Object.keys(res).forEach(function (key) {
                      if ((res[key] == null || res[key] == undefined) || ((res[key] && typeof (res[key]) !== "number") && (res[key].includes('Open') || res[key].includes('In-Progress')))) {
                        ele['Data'] = accounttype;
                      }
                    });
                  } else {
                    ele['Data'] = accounttype;
                  }


                });
              }
            });


          }
          else {
            this.customerService.GetProjectPickListData(ele.ProjectFieldId, ele.FieldName, this.currentuser.id).then((accounttype) => {
              ele['Data'] = accounttype;
            
            });
          }


        }


      });

      let So = this;
      setTimeout(function () {
        So.Fields = result;
        So.Fields.forEach(x => {
          So.NewForm.addControl(x.FieldName, new FormControl(''));

        });

        setTimeout(function () {
          if (So.Project && So.Project.ProjectID > 0) {
            $("#ProjectTypeId").prop("disabled", true);

          }
        }, 300)


      }, 400)



    });
  }

  PickListSortByType(value, data) {

    var DuplicateFiledDataList = [];
    if (value == 'SortOrder') {

      DuplicateFiledDataList = data.sort((a, b) => a.Sortorder - b.Sortorder);
      return DuplicateFiledDataList;
    } else if (value == 'FieldName') {
      DuplicateFiledDataList = data.sort((a, b) => a.itemName.localeCompare(b.itemName));
      return DuplicateFiledDataList;
    }
  }



  createColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      { field: 'CreatedDate', headerName: 'CreatedDate', hide: false },
      { field: 'ProjectTypeName', headerName: 'ProjectTypeId', hide: false },
      { field: 'ProjectStatusName', headerName: 'ProjectStatus', hide: false },
      { field: 'AccountOrContactId', headerName: 'AccountOrContactId', hide: false },
      { field: 'TargetGoLiveDate', headerName: 'TargetGoLiveDate', hide: false },
      { field: 'NumberofTrainers', headerName: '# Trainers', hide: false },
      { field: 'OnsiteDateStatus1Name', headerName: 'OnsiteDateStatus1', hide: false },
      { field: 'OnsiteTrainingDate1From', headerName: 'OnsiteTrainingDate1', hide: false },
      { field: 'OnsiteTrainingDate1To', headerName: 'OnsiteTrainingDate1', hide: false },
      { field: 'OnsiteTrainingDate2From', headerName: 'OnsiteTrainingDate2', hide: false },
      { field: 'OnsiteTrainingDate2To', headerName: 'OnsiteTrainingDate2', hide: false },
      { field: 'OnsiteTrainingHours', headerName: 'OnsiteTrainingHours', hide: false },
      { field: 'PickListEditable', headerName: 'Pick List Editable', hide: false },
      { field: 'ProjectLeaderName', headerName: 'ProjectLeaderId', hide: false },
      { field: 'OnsiteTrainingName', headerName: 'OnsiteTraining', hide: false },
      { field: 'RemoteTrainingName', headerName: 'RemoteTraining', hide: false },
      { field: 'RemoteTrainingDate1From', headerName: 'RemoteTrainingDate1', hide: false },
      { field: 'RemoteTrainingDate1To', headerName: 'RemoteTrainingDate1', hide: false },
      { field: 'RemoteDateStatus1Name', headerName: 'RemoteDateStatus1', hide: false },
      { field: 'RemoteTrainingDate2From', headerName: 'RemoteTrainingDate2', hide: false },
      { field: 'RemoteTrainingDate2To', headerName: 'RemoteTrainingDate2', hide: false },
      { field: 'RemoteDateStatus2Name', headerName: 'RemoteDateStatus2', hide: false },
      { field: 'RemoteTrainingHours', headerName: 'RemoteTrainingHours', hide: false },
      { field: 'Trainer1Name', headerName: 'Trainer1', hide: false },
      { field: 'Trainer2Name', headerName: 'Trainer2', hide: false },
      { field: 'UserAccessName', headerName: 'UserAccess', hide: false },
      { field: 'Notes', headerName: 'Notes', hide: false },
      //{
      //  field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right', cellRenderer(params) {
      //    var eDiv = document.createElement('div');
      //    if (params.data.isdeleted == 0 && params.data.TypeOfField == 'Custom') {
      //      eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
      //    }
      //    else if (params.data.isdeleted == 1 && params.data.TypeOfField == 'Custom') {
      //      eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size:14px"></i>';
      //    }

      //    eDiv.addEventListener('click', function () {
      //      params.data.isdeleted = 1;eted
      //      //selfObj.SaveProjField(params.data);

      //    });
      //    return eDiv;
      //  }
      //}
    ];
  }


  initializeAgGrid() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;

    this.createColumnDefs(columnSelections);

    /*   if (this.accountContactType == 'Account') {*/
    this.loadProjectsbyAccountId(this.accountContactId, this.accountContactType);
    this.loadProjectByProjectId(this.ProjectId);

    //} else {
    //  this.customerService.LoadContactById(this.accountContactId).then(result => {
    //    var AccId = result.accountid;
    //    this.loadProjectsbyAccountId(AccId);
    //  });
    //}
    this.gridOptionsnotes.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }


  }
  loadProjectByProjectId(ProjectId: any) {
    this.customerService.GetProjectByProjectId(ProjectId).then(result => {
      this.Project = JSON.parse(result);
      this.ProjectStatusID = this.Project.ProjectStatus;
      this.projectTypeName = this.Project.ProjectTypeName;
      this.ProjectFormat();
      this.customerService.getProjectStages(this.currentuser.id).then(result => {
       
        this.Proj_Stages_List_by_ProjType = result.filter(x => x.ProjectTypeID == this.Project.ProjectTypeId);
        this.Proj_StagesCount = this.Proj_Stages_List_by_ProjType.length;
      });
    });
  }

  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        let rowData = event.api.getSelectedNodes()[0].data;

        this.Project = rowData;
        this.ProjectStatusID = this.Project.ProjectStatus;
        var userAccesIds = this.Project.UserAccess.split(",");

        this.selectedItems = this.dropdownList.filter(function (item) {
          return userAccesIds.indexOf(item.id) === -1;
        });
        this.ProjectId = rowData.ProjectID;
        this.ProjectFormat();
      }
    } else {
      this.Project = new Projects()
    }
  }
  FieldChange(val, ColName) {

  }
  StatusChanged(val) {
    if (val == 25) {
      if (this.Proj_StagesCount == this.Project.StageAddedCount && this.Project.IncompleteStatusCount == 0 && val == 25) {
        this.Project.ProjectStatus = val;
      } else {
        alert("Please Complete all the stages Before Changing the status");
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.Project.ProjectStatus = selfObj.ProjectStatusID;
        }, 50));

      }}
    else {
      this.Project.ProjectStatus = this.ProjectStatusID;
    }
    
 
  }

  FormDataEntered(val) {
    this.FormDataModified = true;
  }
  columnVisible(event) {
    //this.saveConfiguration();
  }
  dragStopped(event) {
    //this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true) {

    }
    //this.saveConfiguration();
  }
  onAgGridReady(event) {
    this.gridApi = event.api;
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
    }, 100));
  }
  autoSizeAll() {
    //var allColumnIds = [];
    //if (this.gridOptions && this.gridOptions.columnApi) {
    //  this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
    //    allColumnIds.push(column.getColId());
    //  });
    //  this.gridOptions.columnApi.autoSizeColumns(allColumnIds, "autosizeColumns");
    //}    
  }
  SaveProject() {
    if (this.Project.ProjectID == 0 || this.Project.ProjectID == undefined) {
      this.NewForm.addControl('UserId', new FormControl(this.currentuser.id));
      this.NewForm.addControl('AccountOrContactId', new FormControl(this.accountContactId));
      this.NewForm.addControl('AccountOrContactType', new FormControl(this.accountContactType));
      this.NewForm.addControl('isdeleted', new FormControl(0));
      var date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
      this.NewForm.addControl('CreatedDate', new FormControl(date));
      this.NewForm.controls['CreatedDate'].setValue(date);
      this.Project.AccountOrContactId = this.accountContactId;
      this.Project.UserId = this.currentuser.id;
      this.Project.AccountOrContactType = this.accountContactType;
      this.Project.isdeleted = 0;
      this.Project.CreatedDate = date;
    } else {
      var date = this.datePipe.transform(this.Project.CreatedDate, 'yyyy-MM-dd HH:mm:ss');
      //var targetdate = this.datePipe.transform(this.Project.TargetGoLiveDate, 'yyyy-MM-dd HH:mm:ss');
      this.NewForm.addControl('CreatedDate', new FormControl(date));
      this.NewForm.controls['CreatedDate'].setValue(date);
      //this.NewForm.controls['TargetGoLiveDate'].setValue(targetdate);
      this.NewForm.addControl('AccountOrContactId', new FormControl(this.accountContactId));
      this.NewForm.controls['AccountOrContactId'].setValue(this.accountContactId);
      this.NewForm.addControl('AccountOrContactType', new FormControl(this.accountContactType));
      this.NewForm.addControl('isdeleted', new FormControl(this.Project.isdeleted));
      this.Project.AccountOrContactId = this.accountContactId;
      this.Project.UserId = this.currentuser.id;
      this.Project.AccountOrContactType = this.accountContactType;
      this.Project.isdeleted = 0;
    }
    this.SaveProjectDialog = true;
    this.applicationComponent.salesxcrmDialog('#SaveProjectDialog .dialog');
   
  }
  SaveProjectdata() { this.SaveForm();}
  SaveForm() {

    this.Project.fieldvalues = JSON.stringify(this.NewForm?.value);
  
    this.notedescription = this.Project.Notes;
    this.customerService.AddUpdateProject(this.Project).then(result => {
      this.loadProjectsbyAccountId(this.accountContactId, this.Project.AccountOrContactType);    
      this.Project = new Projects();
      this.sendMsgEvent.emit("ProjectSaved :" + result.ProjectID);
      this.toastr.success("Project saved successfully.", "ProjectSaved", { timeOut: 600 });
      this.applicationComponent.pageViewed = '';
      this.showCreateProjectDialog = false;
      this.SaveProjectDialog = false;
    });
  }

  loadProjectsbyAccountId(AccountId, AccorContactType) {

    this.customerService.GetAllProjectsByAccountId(AccountId).then(res => {
      this.ProjectsList = res;
      if (this.ProjectsList.length > 0 && this.ProjectId > 0) {

        this.Project = this.ProjectsList.filter(x => x.ProjectID == this.ProjectId)[0];
   
        this.ProjectStatusID = this.Project.ProjectStatus;
        this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, 1, 0, 0).then((Owner) => {
          this.dropdownList = Owner;
          var userAccesIds = this.Project.UserAccess.split(",");
          this.selectedItems = this.dropdownList.filter(function (item) {
            return userAccesIds.indexOf(item.id) === -1;
          });
        });

        this.SelectedProjectType = this.Project.ProjectTypeId;
        this.ProjectFormat();
        this.GetAllNotes(this.Project.ProjectID, 'Projects');
      } else {
        this.Project = new Projects();
      }


      if (this.Project && this.Project.ProjectID > 0) {
        $("#ProjectTypeId").prop("disabled", true);

      }

    });


  }
  GetDropDownData() {

    this.customerService.GetProjectPickListData(1, 'Project Type', this.currentuser).then((accounttype) => {
      this.ProjectTypeList = accounttype;

    });

    this.customerService.getProjectStages(this.currentuser.id).then(result => {
      this.OriginalProjectStagesList = result;
      this.ProjectStagesList = result.filter(x => x.ProjectTypeID == this.SelectedProjectType);
    });
  }

  SaveNote(ProjectId) {

    this.notes.accountid = ProjectId;
    this.notes.type = 'Projects';
    this.notes.isnewnote = 1;
    this.notes.userid = this.currentuser.id;
    if (this.notedescription == null) {
      this.notedescription = $('.fr-element').html();;

    }
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/img, "");
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/gi, "");
    if (this.notes.notedescription != undefined && this.notes.notedescription != "") {
      this.customerService.SaveNotes(this.notes).then(result => {
        this.toastr.success("Notes saved successfully.", "Notes Saved", { timeOut: 600 });
        $('.fr-element').empty();
        $('.fr-element').html("");
        this.ShowAddNotesDiv = false;
        this.GetAllNotes(this.ProjectId, 'Projects');
      });
    }
  }
  public GetAllNotes(projectId, type) {

    this.allnotes = [];
    this.customerService.GetAllNotesList(this.currentuser.id, projectId, type).then((notes) => {
      notes.forEach(note => {
        note.created = this.datePipe.transform(note.created, "MM-dd-yyyy");
      });
      this.allnotes = notes;
      for (let note of this.allnotes) {
        if (note.isdocumentid != null && note.isdocumentid != "") {
          note.docList = note.isdocumentid.split(",");
        }
      }

    });
  }
  initializeAgGridNotes(val) {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'

    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',
      defaultToolPanel: 'none',
    };
    this.createColumnDefsnotes(val);

    this.context = this;
  }
  createColumnDefsnotes(val, @Optional() isShowDetails: boolean = false) {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {
        field: 'type', headerName: '', hide: true, width: 100, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', hide: false, tooltipField: 'notedescription', width: 250, cellStyle: { 'text-align': 'left' }, cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.value && params.value.includes("Owner Changed")) {
            eDiv.innerHTML = "<label style='color:red'>" + params.value + "</label>";
          } else {
            eDiv.innerHTML = params.value;
          }


          eDiv.addEventListener('click', function () {
            //selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false },
      { field: 'type', headerName: 'Type', cellClass: "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false },

    ];

  }
  notesChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (rowEvent.api.getSelectedNodes()[0].data) {
        this.notesdata = rowEvent.api.getSelectedNodes()[0].data;
        this.NotesDec = true;
      }
    } else {
      this.notesdata = null;
      this.NotesDec = false;
    }

  }

  getProjectSteps() {
    this.customerService.getProjectSteps(this.currentuser.id).then(result => {
      this.ProjectStepsList = result;

    });
  }

  StageSelected(value, index) {
    this.SelectedStageIndex = index;

    this.customerService.getProjectStepsForStatus(this.ProjectId, value).then(result => {
      result.forEach(x => {
        x.ProjectId = this.ProjectId;
        if (x.ProjectStepCompleteDate !== "0001-01-01T00:00:00" && x.ProjectStepCompleteDate !== null && x.ProjectStepCompleteDate !== null) {
          x.ProjectStepCompleteDate = this.datePipe.transform(x.ProjectStepCompleteDate, "yyyy-MM-dd");
        } else {
          x.ProjectStepCompleteDate = null;
          x.ProjectStepStatus = "Open"; let that = this;
          
        }
      });

      this.ProjStepStatus = result;
      this.SelectedStageName = this.Proj_Stages_List_by_ProjType.filter(x => x.ProjectStageID == this.SelectedStage)[0].ProjectStageName;
    });

  }

  SaveStepsStatus() {
    this.customerService.AddUpdateProjectStepStatus(this.ProjStepStatus).then(result => {
      this.toastr.success("Project Step Status successfully.", "Project Step Status Saved", { timeOut: 600 });
      this.sendMsgEvent.emit("ProjectStepSaved");
      if (this.ProjStepStatus.length > 0) {
        this.Proj_Detail_Stage_Selected(this.ProjStepStatus[0].ProjectStageId.toString(), this.ProjStepStatus[0].ProjectStageId)
      }
      this.StepStatusButton = true;
      this.CancelStep();
    });
  }
  CancelStep() {
    this.showCreateProjectDialog = false;
    //this.SelectedStage = 0;
  }

  initilizeForecastGrid() {

    this.gridOptionsView = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.context = this;

    this.customerService.getAllForecastHeaders().then(res => {
      this.ForecastcreateColumnDefs(res);
      this.getForecastData();
      this.loadProjectByProjectId(this.ProjectId);
    });
  }


  ForecastcreateColumnDefs(columnSelections: string) {

    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.ForecastViewcolumnDefs = [];
      this.ForecastViewcolumnDefs = [
        {
          headerName: '',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, headerClass: 'ProjForecastViewHeaders1'
        },
        //{
        //  field: 'accountname', headerName: 'Account Name', hide: true, width: 150, rowGroup: true,
        //  headerClass: 'ProjForecastViewHeaders',
        //},
        { field: 'Project_Type', headerName: 'Project Type', hide: true, width: 150, headerClass: 'ProjForecastViewHeaders', rowGroup: true, }

      ];
      for (let i = 0; i < columnSelectionsArray.length; i++) {

        if (columnSelectionsArray[i]) {
          this.ForecastViewcolumnDefs.push({
            field: columnSelectionsArray[i].replace(' ', '_'), headerName: columnSelectionsArray[i], hide: false, width: 150, headerClass: 'ProjForecastViewHeaders', cellStyle: function (params) {
              if (params.value && params.value.includes('Open')) {
                var st = { 'background-color': 'transparent', 'text-align': 'center' };
                return st;
              } else if (params.value && params.value.includes('Complete')) {
                var st = { 'background-color': '#badb98', 'text-align': 'center' };
                return st;
              } else if (params.value == 'In-Progress') {
                var st = { 'background-color': '#fdfdc9', 'text-align': 'center' };
                return st;
              }
            }, cellRenderer: function (params) {
              if (params.value != null) {
                let x = params.value.replace(" ", "<br />");
                return x;

              } else {
                return params.value;
              }
            }
          });
        }
      }


    }
  }


  onSelectionChangedForeCast(event) {

    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      var rowdata = event.api.getSelectedNodes()[0];
      if (rowdata.data) {
        let rowData = event.api.getSelectedNodes()[0].data;
        this.accountContactId = rowData.accountorContactId;
        this.accountContactType = rowData.accountorContactType;
        this.ProjectId = rowData.ProjectId;
        /*  if (this.accountContactType == 'Account') {*/
        this.loadProjectsbyAccountId(this.accountContactId, this.accountContactType)
        //} else {
        //  // Need to work for Contacts
        //}


      } else {
        let accountId = event.api.getSelectedNodes()[0].allLeafChildren[0].data.accountorContactId;
        let accountOrContactType = event.api.getSelectedNodes()[0].allLeafChildren[0].data.accountorContactType;
        let ProjectId = event.api.getSelectedNodes()[0].allLeafChildren[0].data.ProjectId;
        this.accountContactId = accountId;
        this.accountContactType = accountOrContactType;
        this.ProjectId = ProjectId;
        /* if (this.accountContactType == 'Account') {*/
        this.loadProjectsbyAccountId(this.accountContactId, this.accountContactType)
        //} else {
        //  // Need to work for Contacts
        //}
      }
    }

  }

  getForecastData() {
    this.customerService.getAllForecastData(this.currentuser.id).then(res => {
      this.ForecastDataObj = res.filter(x => x.ProjectId == this.ProjectId);
     
    });
  }
 
  onItemSelect(event) {
    var userlist = [];
    this.selectedItems.forEach(x => {
      userlist.push(x.id);
    });
    this.Project.UserAccess = userlist.join(",");
  }

  OnItemDeSelect(event) {
    var userlist = [];
    this.selectedItems.forEach(x => {
      userlist.push(x.id);
    });
    this.Project.UserAccess = userlist.join(",");

  }

  onSelectAll(event) {
    var userlist = [];
    this.selectedItems.forEach(x => {
      userlist.push(x.id);
    });
    this.Project.UserAccess = userlist.join(",");

  }

  onDeSelectAll(event) {
    var userlist = [];
    this.selectedItems.forEach(x => {
      userlist.push(x.id);
    });
    this.Project.UserAccess = userlist.join(",");
  }

  StepStatusChange(Step) {

    if (Step.ProjectStepStatus == "Open") {
      this.myForm.form.get('Status_' + Step.ProjectStepId).setValidators([Validators.required])
      this.myForm.form.get('Status_' + Step.ProjectStepId).updateValueAndValidity();

      if (this.myForm.form.get('Status_' + Step.ProjectStepId).value != null && this.myForm.form.get('Date_' + Step.ProjectStepId).value != null) {
        this.StepStatusButton = false;
      } else {
        this.StepStatusButton = true;
      }
    } else {
      this.myForm.form.get('Date_' + Step.ProjectStepId).setValidators([Validators.required])
      this.myForm.form.get('Date_' + Step.ProjectStepId).updateValueAndValidity();
      $('#Status_' + Step.ProjectStepId).attr('style', 'padding:0');
      if (this.myForm.form.get('Date_' + Step.ProjectStepId).value != null && this.myForm.form.get('Status_' + Step.ProjectStepId).value != null) {
        this.StepStatusButton = false;
      } else {
        this.StepStatusButton = true;
      }
    }

  }
  StepStatusDateChange(Step) {

    if (this.myForm.form.get('Date_' + Step.ProjectStepId).value != null && this.myForm.form.get('Status_' + Step.ProjectStepId).value != null) {
      this.StepStatusButton = false;
    }
  }
  ProjectTypeListChange() {
    this.ProjectStagesList = this.OriginalProjectStagesList.filter(x => x.ProjectTypeID == this.SelectedProjectType);
    this.SelectedStage = 0;
    this.SelectedStageName = "Project Details";
  }

  CloseCreateProject() {
    if (this.Project.ProjectID == null || this.Project.ProjectID == 0 || this.Project.ProjectID == undefined) {
      this.CloseCreateNewWarning = true;
      setTimeout(function () {
        $('#CloseCreateNewWarning .dialog').attr('style', 'width: 29%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
      }, 4)
    } else {
      this.showCreateProjectDialog = false;
    }
    this.applicationComponent.pageViewed = '';
    //this.loadProjectsbyAccountId(this.accountContactId, this.Project.AccountOrContactType);
  }
  OpenEditProject() {
    debugger;
    var UserAccessIds = [];
    if (this.Project.UserAccess != "") {
      UserAccessIds = this.Project.UserAccess.split(",");
    }
    if (this.currentuser.id == this.Project.UserId || UserAccessIds.includes(this.currentuser.id.toString())) {
      this.showCreateProjectDialog = true;
      this.showCreateProjectDialogCss();
      this.customerService.GetProjectByProjectId(this.ProjectId).then(result => {
        this.Project = JSON.parse(result);
        this.ProjectStatusID = this.Project.ProjectStatus;
        //this.showCreateProjectDialog = true;
        //this.showCreateProjectDialogCss();
       // this.ProjectFormat();       
      });
    } else {
      alert("Dont Have Access to Edit")
    }

  }
  ContactReceiveEvent(msg) {
    this.showAddEditContact = false;

  }
  onCloseEventReceived(msg) {
    this.rightMainDivHideShow = true;

    if (msg == 'AccountEdit') {
      this.isSharing = false;
      //this.EditAccountFormDiv = true;
      //this.editAccount(this.account.id);
    }
    else if (msg == 'ContactEdit') {
      this.isSharing = false;
      //this.LoadContactEdit(this.contactSharingid);

    }
    else {
      this.isSharing = false;
      //this.EditAccountFormDiv = false;
      //this.AccountotherDetailsDiv = true;
      //this.GetAllProviderAndContactInfo(this.account.id);
      this.accdetailcmp.loadAccountId(this.account.id);
    }

  }
  ReceiveEvent(msg) {
    if (msg == "ExpandSoftwareLicense" || msg == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(msg);
    } else {
      //this.AccountData('show' + msg.split('~')[1]);
      this.showAddEditAccount = false;
      this.showAddEditContact = false;
      //this.AccountData(this.account.id);
    }
  }

  CloseProposalPage(msg) {
    this.ReceiveEvent("CloseAddAccountDialog~" + this.account.id);
  }
  onCloseDialogEventReceived(msg) {

    if (msg == 'false') {
      this.notesshowDialog = false;
      this.isModal = false;
      this.ShowPDF = false;
    }
  }

  ExpandPDF(val) {

    this.notesshowDialog = true;
    this.QuickViewDialogCss(val);
    this.DocExpandView = val;
  }
  QuickViewDialogCss(val) {
    if (val == true) { //expand
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    } else {
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    }

  }

  Proj_Detail_Stage_Selected(value, index) {
    if (value == 0) {
      this.Project_OverView_Div = true;
      this.Project_Steps_Div = false;
    } else {
      this.Project_Steps_Div = true;
      this.Project_OverView_Div = false;
      this.StageSelected(value, index);    
      this.customerService.getProjectStatus(this.ProjectId).then(result => {
        var ProjStage = this.Proj_Stages_List_by_ProjType.filter(x => x.ProjectStageID == value)[0].ProjectStageName;
        if (result != null) {
          this.Project_Stage_Status = result[ProjStage.replace(" ", '_')];
        } else { this.Project_Stage_Status ='Open' }
      });

    }
  }
}
