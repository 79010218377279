import { Component, OnInit, Input, Output,EventEmitter, } from '@angular/core';
import { CustomerService } from './../../services/customer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Contacts } from '../../models/contacts';
import { ApplicationComponent } from './../application.component';
declare var $: any;
@Component({
  selector: 'convertcontactto-lead',
  templateUrl: './convertcontactto-lead.component.html',
  styleUrls: ['./convertcontactto-lead.component.css']
})
export class ConvertcontacttoLeadComponent implements OnInit {
  @Input()
  id: number;
  @Input()
  type: number;
  @Input()
  selectorTag: boolean = false;

  @Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  contacts: Contacts = new Contacts();
  currentuser: any;

  constructor(private customerService: CustomerService, private router: Router, public applicationComponent: ApplicationComponent)
  {
   this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
   
  }
  
  convertLead(id)
  {
    if (this.type == 0) {
      this.customerService.UpdateLeadStatus(id, this.currentuser.id).then((contact) => {

      });     
    }
    this.applicationComponent.ExpandSelectedMenu('leads');
    this.router.navigateByUrl(`/application/leadsdashboard/${id}/0/null`);
  }

  cancel()
  {
    document.getElementById('checkDiv').style.display = 'none' 
    $('.overlay').remove()
    //document.getElementById('inactivelead').style.display = 'none'
     
  }
}
