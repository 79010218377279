export class ChatMessage {
  public fromuserid: number;
  public message: string;
  public createdate: string;
  public touserid: string;
  public type: string;
  public sentuserfirstName: string;
  public recuserfirstName: string;
  public sentuserlastName: string;
  public recuserlastName: string;
  public clientuniqueid: string;
  public datetime: string;

}


