import { Component, OnInit, Optional, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Calllist } from '../../models/calllist';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { EditdeletecvComponent } from '../editdeletecv/editdeletecv.component';
import { NotesrendererComponent } from '../notesrenderer/notesrenderer.component';
import { AccountsService } from '../../services/accounts.service';
import { Contacts } from '../../models/contacts';
import { ContactsdashboardComponent } from '../../application/contacts/contactsdashboard/contactsdashboard.component';
import { CustomerService } from '../../services/customer.service';
import { Column } from 'ag-grid';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { Viewcalllist } from '../../models/viewcalllist';
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../application.component';
import { DatePipe, Location } from '@angular/common';
import * as moment from 'moment';
import { Accounts } from 'src/app/models/accounts';
import { AccountContactComponent } from '../account-contact/account-contact.component';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { TaskHistory } from '../../models/task-history';
import { Tickets } from '../../models/tickets';
import { Emails } from '../../models/emails';
import { Project } from '../../models/project';
import { Notes } from '../../models/notes';
declare var $: any;
@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.css']
})
export class QuickViewComponent implements OnInit {
  //@Input()
  contactids: string = "";
 // @Input()
  Qtype: string = "";
  calllist: Calllist = new Calllist();
  public gridOptions: GridOptions;
  currentuser: any;
  public columnDefs: any[];
  public frameworkComponentsQuickView;
  allContacts: Contacts[];
  allAccounts: Accounts[];
  contactid: number = 0;
  Quickviewcontactid: number = 0
  customerTypes: string;
  gridHeight = screen.height * 0.58222;
  tab: string = 'QuickView';
  public context;
  @ViewChild(ContactsdashboardComponent) contactComp;
  @ViewChild(AccountContactComponent) accComp;
  contactshowDialog: boolean = false;
  showCancelDialog: boolean = false;
  showDialog = false; leadstatus = 0;
  contact: Contacts = new Contacts();
  type: string; itemCount = 0;
  selectedItems = []; itemResource: any; dropdownList = []; finalitems = "";
  pageSize: string = ""; dropdownSetting = {};
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear()-1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear()-1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");
  datePipe: DatePipe = new DatePipe("en-US");
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  viewcallist: Viewcalllist = new Viewcalllist();
  public sideBar;
  public AccountcolumnDefs: any[];
  public AccountgridApi: any;
  private gridApi;
  activitieslist: Array<TaskHistory> = [];
  ticketslist: Array<Tickets> = [];
  emailslist: Array<Emails> = [];
  projectlist: Array<Project> = [];
  userdocumentlist: any[] = [];
  noteslist: Array<Notes> = [];
  isQuickViewOpen: boolean = true;
  constructor(private _location: Location, public applicationComponent: ApplicationComponent,private route: ActivatedRoute, private opprtunityservice: OpprtunitiesService, private accountsService: AccountsService, private customerService: CustomerService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit()
  { 
  
    this.route.params.subscribe(params => {
      this.contactids = params['ids'];
      this.calllist.contactids = this.contactids;  
      this.Qtype = params['term'];
      $(".checkMark").removeClass("activeColor");
      $(".subButtons").removeClass("activeButton"); 
   
      this.initializeAgGrid();      
      if (this.applicationComponent.selectedusers != '' && this.applicationComponent.selectedusers != null && this.applicationComponent.selectedusers != undefined) {
        this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
          Owner.forEach(Owner => {
          });
          this.selectedItems = Owner;
        });

        if (this.Qtype == 'Contacts') {
          this.GetAllSelectedContactCalllist(this.calllist.contactids, '', 'Contacts', $("#userids").val(), this.startDateVal, this.endDateVal, this.customerTypes);
        } else {
          this.GetAllSelectedAccountCalllist(this.calllist.contactids, '', 'Accounts', $("#userids").val(), this.startDateVal, this.endDateVal, this.customerTypes);
        }
        
      } else {

        if (this.Qtype == 'Contacts') {
          this.GetAllSelectedContactCalllist(this.calllist.contactids, '', 'Contacts', this.currentuser.id, this.startDateVal, this.endDateVal, this.customerTypes);
        } else {
          this.GetAllSelectedContactCalllist(this.calllist.contactids, '', 'Accounts', this.currentuser.id, this.startDateVal, this.endDateVal, this.customerTypes);
        }

        this.selectedItems = [
          { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
        ];
      }
    //});
    let eleId = 'quickview';
    $(".fa-check").removeClass("activeColor");
    $("#" + eleId).addClass("activeColor");
    $(".mainCheckMark").removeClass("activeColor");
    $(".coustomButton").removeClass("mainactiveButton");
    $("#contact_span").addClass("activeColor");
    $("#contact").addClass("mainactiveButton");
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1,0,0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.dropdownList = Owner;
    });
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };

    var url = window.location.href;
    if (url.indexOf('quick-view') > -1) {
      $(setTimeout(function () {
        $('#ContactInformation1').attr('style', 'padding:0%;padding-top:0 !important;overflow:auto;width:100%;')
      }, 200));
    }
    this.applicationComponent.pageViewed = "QuickView";
    });
  }
  close() { this._location.back(); }
  CloseQuicKViewOK(val) {
    this.applicationComponent.pageViewed = '';
    this.applicationComponent.selectedmainbutton = '';
    this.showCancelDialog = false;
  }
  CloseQuickview() {
  
    this.showCancelDialog = true;
    this.applicationComponent.salesxcrmDialog('#cancelDialog .dialog');
    //$(setTimeout(function () {
    //  $('#cancelDialog .dialog').attr('style', 'border: 4px solid #a2e477;width: 30%;height:20%; padding:0;');
    //}, 4));
  }
  onItemSelect(item: any) {
    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.GetAllSelectedContactCalllist('0', '', 'Contacts', $("#userids").val(), this.startDateVal, this.endDateVal, this.customerTypes);
  }
  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.GetAllSelectedContactCalllist('0', '', 'Contacts', $("#userids").val(), this.startDateVal, this.endDateVal, this.customerTypes);
    } else {
      this.allContacts = [];
      $("#userids").val('');
    }
  }
  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.GetAllSelectedContactCalllist('0', '', 'Contacts', $("#userids").val(), this.startDateVal, this.endDateVal, this.customerTypes);
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.allContacts = [];
    this.gridOptions.api.hideOverlay();
    $("#userids").val('');
    this.applicationComponent.selectedusers = null;
  }
  public GetAllSelectedContactCalllist(contactsids, completedcontactids, type, currentuserid, startdate, enddate, customerTypes) {
    this.applicationComponent.selectedusers = currentuserid;
    this.viewcallist.term = null;
    this.viewcallist.contactids = (contactsids == "") ? null : contactsids;
    this.viewcallist.completedcontactids = (completedcontactids == "") ? null : completedcontactids;
    this.viewcallist.currentuserid = this.currentuser.id;
    this.viewcallist.startdate = startdate;
    this.viewcallist.enddate = enddate;
    this.viewcallist.isCustomertypelist = customerTypes;
    this.accountsService.GetAllSelectedContactCalllist(this.viewcallist).then((Contacts) => {
      Contacts.forEach(Contacts => {
        Contacts.created = this.datePipe.transform(Contacts.created, "MM-dd-yyyy");
      });
      this.allContacts = Contacts;
      if (this.allContacts[0].islimitedSearch == true) {
        //$('#searchContact').attr('style', 'height:30px;width:88%;border:none;border-radius:3px;')
        //$('#searchContact').val(this.allContacts[0].SearchVal);
        this.applicationComponent.searchstring = this.allContacts[0].SearchVal;
        this.applicationComponent.limitedSearch = true;
        $('#searchContact').val('');

      } else {
        //$('#searchContact').attr('style', 'height:30px;width:88%;border:none;border-radius:3px;')
        this.applicationComponent.term = "";
        $('#searchContact').val('');
        this.applicationComponent.searchstring = "";
        this.applicationComponent.limitedSearch = false;
         
      }
      this.itemCount = Contacts.length;
      this.calllist.contactids = Array.prototype.map.call(this.allContacts, s => s.id).toString();  
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.gridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.contactid) {
            selfObj.gridOptions.api.selectNode(node, true);
            selfObj.contactComp.GetContactInfo(selfObj.contactid);
            $('#emailIds').val(selfObj.contactid);
            $('#tasklistselectedtyle').val('Contact');  
          }
        });
      }, 1000));
    
      this.contactid = ((this.allContacts.length == 0) ? 0 : this.allContacts[0].id);
      $('#accotIdNotes').val(this.contactid.toString());
      $('#accotTypeNotes').val('Contact');
    });
  }

  public GetAllSelectedAccountCalllist(contactsids, completedcontactids, type, currentuserid, startdate, enddate, customerTypes) {
    this.applicationComponent.selectedusers = currentuserid;
    this.viewcallist.term = null;
    this.viewcallist.contactids = (contactsids == "") ? null : contactsids;
    this.viewcallist.completedcontactids = (completedcontactids == "") ? null : completedcontactids;
    this.viewcallist.currentuserid = this.currentuser.id;
    this.viewcallist.startdate = startdate;
    this.viewcallist.enddate = enddate;
    this.viewcallist.isCustomertypelist = customerTypes;
    this.accountsService.GetAllSelectedAccountCalllist(this.viewcallist).then((acc) => {
      acc.forEach(accoount => {
        accoount.created = this.datePipe.transform(accoount.created, "MM-dd-yyyy");
      });
      this.allAccounts = acc;
      this.itemCount = acc.length;
      this.calllist.contactids = Array.prototype.map.call(this.allAccounts, s => s.id).toString();
      this.contactid = ((this.allAccounts.length == 0) ? 0 : this.allAccounts[0].id);
      let selfObj = this;

      $(setTimeout(function () {
        if (selfObj.AccountgridApi != undefined) {
          selfObj.AccountgridApi.forEachNode((node) => {
            if (node.data.id == selfObj.contactid) {
              selfObj.AccountgridApi.selectNode(node, true);
              selfObj.accComp.AccountData('show' + selfObj.contactid);
              $('#emailIds').val(selfObj.contactid);
              $('#tasklistselectedtyle').val('Customer');  
            }
          });
        }
      }, 500));
    });
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    if (this.Qtype == 'Contacts') {

      this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 45).then((config) => {

        columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
        this.createColumnDefs(columnSelections);
        this.UpdateSearchColumns(columnSelections);
      });
    } else {
      this.createAccountColumnDefs('');
    }

    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponentsQuickView = {
      notesrenderer: NotesrendererComponent,
      editdeletecv: EditdeletecvComponent
    };
  
  }

  UpdateSearchColumns(columnSelections) {
     
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[] = columnSelections.split(',');

    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');
       
      if ((fields[2] == 'true' && fields[1] != '#') && (fields[2] == 'true' && fields[0] != 'notes') && (fields[2] == 'true' && fields[0] != 'actions')) {
         
        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }

    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];

    if (retrievedObject != null) {
      let obj: any[] = JSON.parse(retrievedObject);
      obj.forEach(element => {
        if (element.subtab == 'quickview') {
            tempTableColumns = element.columns;
            this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        }
        if (this.applicationComponent.SearchSelectedItemsLengthChanged == true) {
         // this.applicationComponent.showSearchTabsSubTabs = false;
        }
        else {
         // this.applicationComponent.showSearchTabsSubTabs = true;
        }
      });
    }
    if (tempTableColumns && tempTableColumns.length > 0) {
      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })

    }
    else {
      this.accountsService.GetDefaultColumns('quickview', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.applicationComponent.SearchSelectedItems = [
            { "id": 'lastname', "itemName": 'Last Name' },
            { "id": 'firstname', "itemName": 'First Name' },
            { "id": 'accountname', "itemName": 'Account Name' }
          ];
        }
      });
      //this.applicationComponent.showSearchTabsSubTabs = false;
    }
  }
  rowClickAgGrid(rowEvent) {
  
    if (rowEvent.node.selected) {
      this.contactid = rowEvent.data.id;
      this.contactComp.GetContactInfo(rowEvent.data.id);    
    }
    
  }

  rowClickAccountAgGrid(rowEvent) {

    if (rowEvent.node.selected) {
      this.contactid = rowEvent.data.id;
      this.accComp.AccountData('show' + rowEvent.data.id);
      $('#emailIds').val(rowEvent.data.id);
      $('#tasklistselectedtyle').val('Customer');  
    }

  }
  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let searchval = this.applicationComponent.searchstring;
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "actions") {
          this.columnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]), pinned: 'right', });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "accountname") {
          this.columnDefs.push({
            field: 'accountname', headerName: 'Account Name', hide: false, cellRenderer: function (params) {
              if (searchval != null) {
                let self = this;
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = searchval.replace(/["]/g, "")
                  if (x[i] == search) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            } });
        } else if (fieldHeaderVisibleWidthArray[0] == "lastname") {
          this.columnDefs.push({
            field: 'lastname', headerName: 'Last Name', hide: false, cellRenderer: function (params) {
              if (searchval != null) {
                let self = this;
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = searchval.replace(/["]/g, "")
                  if (x[i] == search) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }  });
        } else if (fieldHeaderVisibleWidthArray[0] == "firstname") {
          this.columnDefs.push({
            field: 'firstname', headerName: 'First Name', hide: false, cellRenderer: function (params) {
              if (searchval != null) {
                let self = this;
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = searchval.replace(/["]/g, "")
                  if (x[i] == search) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }  });
        } else if (fieldHeaderVisibleWidthArray[0] == "calllistcontactstatus") {
          this.columnDefs.push({ field: 'calllistcontactstatus', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) { if (params.value == 'Open') { return { color: 'red' }; } } });
        } else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        {
          field: 'lastname', headerName: 'Last Name', hide: false, cellRenderer: function (params) {
            if (searchval != null) {
              let self = this;
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchval.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          } },
        {
          field: 'firstname', headerName: 'First Name', hide: false, cellRenderer: function (params) {
            if (searchval != null) {
              let self = this;
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchval.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          } },
        { field: 'middlename', headerName: 'Middle Name', hide: false },
        { field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: false },
        { field: 'calllistcontactstatus', headerName: 'Status', hide: false, cellStyle: function (params) { if (params.value == 'Open') { return { color: 'red' }; } } },
        { field: 'titleName', headerName: 'Title', hide: false },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        { field: 'DOB', headerName: 'DOB', hide: false },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        { field: 'zip', headerName: 'ZIP', hide: false },
        {
          field: 'accountname', headerName: 'Account Name', hide: false, cellRenderer: function (params) {
            if (searchval != null) {
              let self = this;
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchval.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          } },
        { field: 'contacttypeName', headerName: 'Contact Type', hide: true },  //true starts here
        { field: 'suffix', headerName: 'Suffix', hide: true },
        { field: 'sex', headerName: 'Sex', hide: true },
        { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
        { field: 'email', headerName: 'Email', hide: true },
        { field: 'address1', headerName: 'Address1', hide: true },
        { field: 'address2', headerName: 'Address2', hide: true },
        { field: 'city', headerName: 'City', hide: true },
        { field: 'state', headerName: 'State', hide: true },
        { field: 'cell', headerName: 'Cell', hide: true },
        { field: 'office', headerName: 'Office', hide: true },
        { field: 'homeaddress', headerName: 'Home Address', hide: true },
        { field: 'homecity', headerName: 'Home City', hide: true },
        { field: 'homestate', headerName: 'Home State', hide: true },
        { field: 'homezip', headerName: 'Home Zip', hide: true },
        { field: 'home', headerName: 'Home', hide: true },
        { field: 'college', headerName: 'College', hide: true },
        { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
        { field: 'medschool', headerName: 'Medschool', hide: true },
        { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
        { field: 'residency', headerName: 'Residency', hide: true },
        { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
        { field: 'fellowship', headerName: 'Fellowship', hide: true },
        { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
        { field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true },
        { field: 'created', headerName: 'Created', hide: true }
      ];
    }
  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  onAgAccountGridReady(event) {
    this.AccountgridApi = event.api;
  }
  autoSizeAll() {

  }
  //onMessageReceived(msg) {
  //  var contidarr = this.calllist.contactids.split(",");
  //  if (contidarr.length > 0) {
  //    var index = contidarr.indexOf(this.contactid.toString());
  //    if (index >= 0 && index < contidarr.length) {
        
  //      if ((msg == "Next") && contidarr[index + 1] != undefined) {
  //        this.contactid = parseInt(contidarr[index + 1]);
  //      }
  //      else if ((msg == "Previous") && contidarr[index - 1] != undefined) {
  //        this.contactid = parseInt(contidarr[index - 1]);
  //      }


  //        let selfObj = this;
  //      $(setTimeout(function () {
           
  //          selfObj.gridOptions.api.forEachNode((node) => {
  //            if (node.data.id == selfObj.contactid) {
  //              selfObj.gridOptions.api.selectNode(node, true);
  //            }
  //          });
  //        }, 100));
        
  //    }
  //  }
  //}
  editRowAg(rowdata) {
    this.contactid = rowdata.id;
    this.contactComp.editcontact(this.contactid);    
  }
  deleteRowAg(rowdata) {
    var c = confirm("Are you sure you want to Delete this Contact??");
    if (c == true) {
      this.customerService.Deactivatecontact(rowdata.id, 1).then((user) => {
        this.GetAllSelectedContactCalllist(this.calllist.contactids, '', 'Contacts', $("#userids").val(), this.startDateVal, this.endDateVal, this.customerTypes);
      });
   
    }
  }
  convertContactAg(rowdata) {
    this.contactid = rowdata.id;
    this.showDialog = !this.showDialog
    this.customerService.GetContactById(rowdata.id).then(result => {
      this.contact = result;
      this.leadstatus = this.contact.converttolead;
    });
    setTimeout(function () {
      $('.dialog ').attr('style', 'height:180px;width:35%;padding-top: 3%;background: #f4f4f4;border: 1px solid #a2e477;')
    }, 4)

  }
  printMsg(msg) {
    this.contactshowDialog = !this.contactshowDialog;
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();
      config.maxlimit = 25;   
    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 45;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  openNotesDialogAg(rowdata) {
    this.contactComp.expand();
  }
  dateChange(event) {
    this.AssignStartAndEndDate(event);
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
    this.GetAllSelectedContactCalllist('0', '', 'Contacts', $("#userids").val(), this.startDateVal, this.endDateVal, this.customerTypes);
   
  }
  txtPageSizeChanged(pageSize) {
    this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    this.saveConfiguration();
  }
  AssignStartAndEndDate(event) {

    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }
  expandQuickView(value) {
    if (value === 'Collapse') {
      this.isQuickViewOpen = (this.isQuickViewOpen) ? false : true;
      if (this.Qtype == 'Contacts') {
        this.contactComp.SixBoxExpand = true
        this.contactComp.tab = 'Normal';
      } else {
        this.accComp.SixBoxExpand = true;
        this.accComp.tab = 'Normal';
      }
      
    }
    else {
      this.isQuickViewOpen = (this.isQuickViewOpen) ? false : true;
      if (this.Qtype == 'Contacts') {
        this.contactComp.SixBoxExpand = false
        this.contactComp.tab = 'QuickView';
      } else {
        this.accComp.SixBoxExpand = false;
        this.accComp.tab = 'QuickView';
      }
      
    }
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }

  onAccountMessageReceived(msg) {
    if (msg == "QuickView") { this.CloseQuickview(); }
    else if (msg == "Expand")
    { this.isQuickViewOpen = false; }
    else if (msg == "Collapse")
    { this.isQuickViewOpen = true; }
  }
  createAccountColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      this.AccountcolumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleArray[0] == "#") {
          this.AccountcolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleArray[0] == "customertypeid") {
          this.AccountcolumnDefs.push({
            field: 'customertypeid', headerName: 'Customer Type', hide: true,
            cellRenderer(params) { if (params.value == '0') { return "Non - Customer" } else if (params.value == '56') { return "Customer" } else if (params.value == '57') { return "Former Customer" } }, width: parseInt(fieldHeaderVisibleArray[3])
          });
        } else if (fieldHeaderVisibleArray[0] == "accountname") {
          this.AccountcolumnDefs.push({
            field: 'accountname', headerName: 'Account Name', hide: false,
          });
        }
        else if (fieldHeaderVisibleArray[0] == "accnumber") {
          this.AccountcolumnDefs.push({
            field: 'accnumber', headerName: 'Act Number', hide: true
          });
        }
        else if (fieldHeaderVisibleArray[0] == "actions") {
          this.AccountcolumnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecvcheck', hide: fieldHeaderVisibleArray[2] == "true" ? false : true, pinned: 'right', width: parseInt(fieldHeaderVisibleArray[3]) });
        }
        else if (fieldHeaderVisibleArray[0] == "notes") {
          this.AccountcolumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleArray[3]) });

        } else {
          this.AccountcolumnDefs.push({
            field: fieldHeaderVisibleArray[0],
            headerName: fieldHeaderVisibleArray[1],
            hide: fieldHeaderVisibleArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
      }
    }
    else {
      this.AccountcolumnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'id', headerName: 'Id', hide: true },
        {
          field: 'customertypeid', headerName: 'Customer Type', hide: true,
          cellRenderer(params) {
            if (params.value == '0') { return "Non - Customer" } else if (params.value == '56') { return "Customer" } else if (params.value == '57') { return "Former Customer" }
          }
        },
        { field: 'accnumber', headerName: 'Act Number', hide: true },
        { field: 'IsContact', headerName: ' Is Contact?', hide: true },
        // { field: 'userid', headerName: ' userid', hide: false },
        {
          field: 'accountname', headerName: 'Account Name', hide: false,
        },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        { field: 'address1', headerName: 'Address1', hide: false },
        { field: 'address2', headerName: 'Address2', hide: false },
        { field: 'city', headerName: 'City', hide: false },
        { field: 'state', headerName: 'State', hide: false },
        { field: 'zip', headerName: 'Zip', hide: false },
        { field: 'phone', headerName: 'Phone', hide: true },
        { field: 'ehrName', headerName: 'EHR', hide: false },
        { field: 'pmName', headerName: 'PM', hide: false },
        { field: 'rcmName', headerName: 'RCM', hide: false },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        { field: 'actions', headerName: 'Actions', hide: false, pinned: 'right', cellRenderer: 'editdeletecvcheck' },
      ];
    }

  }
  
}
