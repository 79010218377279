import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GridOptions, RowDragEndEvent } from 'ag-grid';
import { StatusIDCategory, StatusIDGroup } from 'src/app/models/status-idcategory';
import { UserService } from 'src/app/services/user.service';
import { SystemRowConfiguration } from 'src/app/models/system-row-configuration';
import { LeadsService } from 'src/app/services/leads.service';
import { ApplicationComponent } from '../application.component';

import { DatePipe } from '@angular/common';
import { AuditLogModel } from '../../models/auditlog';
declare var $: any;
@Component({
  selector: 'status-idconfiguration',
  templateUrl: './status-idconfiguration.component.html',
  styleUrls: ['./status-idconfiguration.component.css']
})
export class StatusIDconfigurationComponent implements OnInit {
  statusidCategory: StatusIDCategory = new StatusIDCategory();
  allStatusIds: StatusIDCategory[];
  allStatusIdGroups: StatusIDGroup[];
  dropdownList: any;
  createNewStatusId: boolean = false;
  deleteStatusId: boolean = false;
  currentUser: any;
  public gridOptions: GridOptions;
  public columnDefs: any[];
  public GroupsColumnDefs: any[];
  finalitems = [];
  selectedItems = [];
  updatestaatusId: number;
  dropdownSetting = {};
  ExpandCategoryGrid: boolean = false;
  ExpandGroupsGrid: boolean = false;
  sortOrder = "0";
  deletedStatusID = 0;
  public sideBar;
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration();
  StatusIdHomePage: boolean = true;
  StatusIdItemsPage: boolean = false;
  StatusIdGroupsPage: boolean = false;
  DeleteConfirmationPopup: boolean = false;
  DeleteGroupConfirmationPopup: boolean = false;
  datePipe: DatePipe = new DatePipe("en-US");
  StatusGroup: StatusIDGroup = new StatusIDGroup()
  createNewStatusIdGroup: boolean = false;
  @Input() SubMenuType: string = null;
  StatusIDGroup: number = 5000;
  constructor(public applicationComponent: ApplicationComponent,private userService: UserService, private toastr: ToastrService, private leadsService: LeadsService) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
    
    this.initializeAgGrid();
    //if (this.SubMenuType !== null && this.SubMenuType !== undefined && this.SubMenuType !== '') {
    this.OpenStatusID("Group");
    //} else {
    //  var obj: AuditLogModel = { id: 0, type: 'StatusID Configuration', action: 'Status ID Config', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.currentUser.id, dateofviewed: '', customertype: '', time: '', Title: 'Status ID Config', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    //  this.applicationComponent.InsertAuditHistory(obj);
    //}
  }
  OpenStatusID(type) {
    if (type == 'Group') {
      this.StatusIdHomePage = false;
      this.StatusIdItemsPage = false;
      this.StatusIdGroupsPage = true;
      this.GetAllStatusIDs('Groups');
    } else if (type == 'Items') {
      this.StatusIdHomePage = false;
      this.StatusIdItemsPage = true;
      this.StatusIdGroupsPage = false;
      this.GetAllStatusIDs('Groups');
      this.GetAllStatusIDs('Items')
    }

    var obj: AuditLogModel = { id: 0, type: 'StatusID Configuration', action: 'Status ID Config - ' + type, AccountOrContactId: 0, AccountOrContact: type, documentid: 0, userid: this.currentUser.id, dateofviewed: '', customertype: '', time: '', Title: 'Status ID Config - ' + type, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }

  onAgGridReady(event, type) {
    if (type == 'StatusIDGroups') {
      this.gridOptions.api = event.api;
    } else {
      this.gridOptions.api = event.api;
    }

  }

  onRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("StatusId");
  }
  onRowDragEndGroups(event: RowDragEndEvent) {
    this.saverowconfiguration("StatusIDGroups");
  }
  saverowconfiguration(type) {
    var sortoorder = [];
    if (type == 'StatusIDGroups') {
      this.gridOptions.api.forEachNode((node) => {
        sortoorder.push(node.data.StatusGroupId);
      });
    } else {
      this.gridOptions.api.forEachNode((node) => {
        sortoorder.push(node.data.Id);
      });
    }
      
   
    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i == a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentUser.id;
    this.leadsService.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });    
    });

  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true,
      toolPanelSuppressValues: true, enableCellChangeFlash: true, enableMultiRowDragging: true,
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };

    this.createColumnDefs();

  }

  createColumnDefs() {
    let selfObj = this;
    this.columnDefs = [
      {
        field: '#',
        headerName: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true
      },
      {
        field: 'StatusIDName', headerName: 'Status ID Description', hide: false, width: 460,
      },
      { field: 'SortOrder', headerName: 'Sort Order', hide: false, width: 110 },
      {
        field: 'StatusIDGroup', headerName: 'Group', hide: false, width: 110, cellRenderer(params) {
          if (params.data.StatusIDGroup == 5001) {
            return "Customer Profile";
          }
          else {
            return "Account Profile";
          }
        }
      },
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:20%"/>';
          eDiv.addEventListener('click', function () {
            selfObj.deleteStatusIds(params.data.Id,'Items');
          });
          return eDiv;
        }
      }
    ];

    this.GroupsColumnDefs = [
      {
        field: '#',
        headerName: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true
      },
      {
        field: 'StatusGroupName', headerName: 'Status ID Description', hide: false, width: 460,
      },
      { field: 'StatusSortOrder', headerName: 'Sort Order', hide: false, width: 110 },
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:20%"/>';
          eDiv.addEventListener('click', function () {
            selfObj.StatusGroup = params.data;
            selfObj.deleteStatusIds(params.data.Id,'Groups');
          });
          return eDiv;
        }
      }
    ];


  }
  onItemSelect(item: any) {
    var ids = item.id;
    this.finalitems.push(ids);
  }
  OnItemDeSelect(item: any) {
    var ids = item.id;
    const index: number = this.finalitems.indexOf(ids);
    if (index !== -1) {
      this.finalitems.splice(index, 1);
    }        
  }

  onDeSelectAll(items: any) {
    this.finalitems = [];
  }

  UpdateStatusId() {
    if (this.deletedStatusID > 0) {
      this.userService.UpdateAccountStatusId(this.updatestaatusId, this.deletedStatusID).then(result => {
        this.GetAllStatusIDs('Items');
        this.toastr.success("StatusId Deleted successfully.", "StatusId Deleted", { timeOut: 600 });
        this.deleteStatusId = false;
      });
    }
    else {
      alert("Please Select");
    }
    
  }
  CancelSelectedAccount() {
    this.finalitems = [];
    this.selectedItems = [];
    this.deleteStatusId = false;
  }
  ClearStatusID() {

    this.statusidCategory = new StatusIDCategory();
    this.StatusGroup = new StatusIDGroup();
  }

  deleteStatusIds(statusId, type) {
    if (type == 'Items') {
      this.updatestaatusId = statusId;
      this.userService.GetattattachedstatusIds(this.currentUser.id, statusId).then(result => {
        if (result > "0") {
          this.deleteStatusId = true;
          this.userService.GetAllAddedStatusIds(this.currentUser.id, statusId).then(result => {
            this.dropdownList = result;
          });
        } else {
          this.DeleteConfirmationPopup = true;
          this.applicationComponent.salesxcrmDialog('#DeleteConfirmationPopup .dialog');
          //setTimeout(function () {
          //  $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
          //}, 4);
        }
      })
    }
    else if (type == 'Groups') {
      this.DeleteGroupConfirmationPopup = true;
      this.applicationComponent.salesxcrmDialog('#DeleteGroupConfirmationPopup  .dialog');
      //setTimeout(function () {

      //  $('#DeleteGroupConfirmationPopup  .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
      //}, 4);
    }

  }

  ReceiveDeleteevent(msg) {
    if (msg == 'Ok') {
      this.deletedStatusID = this.updatestaatusId;
      this.UpdateStatusId();
      this.DeleteConfirmationPopup = false;
    } else {
      this.DeleteConfirmationPopup = false;
    }
  }

  ReceiveGroupDeleteevent(msg) {
    if (msg == 'Ok') {
      this.DeleteGroupConfirmationPopup = false;
      this.StatusGroup.DeleteStatus = 1;
      this.AddOrUpdateStatusIDGroups(this.StatusGroup);
    } else {
      this.DeleteGroupConfirmationPopup = false;
    }
  }
  SaveStatusID( val) {
    if (val == 'Items') {
      this.statusidCategory.UserID = this.currentUser.id;
      if (this.statusidCategory.Id == null || this.statusidCategory.Id == undefined || this.statusidCategory.Id == 0) {
        this.statusidCategory.Id = 0;
      }

      this.statusidCategory.deleteStatus = 0;
      this.AddOrUpdateStatusID(this.statusidCategory);
    } else if (val == 'Groups') {
      this.StatusGroup.UserID = this.currentUser.id;
      if (this.StatusGroup.StatusGroupId == null || this.StatusGroup.StatusGroupId == undefined || this.StatusGroup.StatusGroupId == 0) {
        this.StatusGroup.StatusGroupId = 0;
      }
      this.StatusGroup.DeleteStatus = 0;
      this.AddOrUpdateStatusIDGroups(this.StatusGroup)
    }
    
  }
  AddOrUpdateStatusIDGroups(Group) {
    this.userService.AddOrUpdateStatusIDGroups(Group).then(result => {
      this.GetAllStatusIDs('Groups');
      this.StatusGroup = new StatusIDGroup();
      this.createNewStatusIdGroup = false;
      this.toastr.success("Status ID Group saved successfully.", "Status ID Group Saved", { timeOut: 600 });
    });
  }
  AddOrUpdateStatusID(Category) {
    this.userService.AddOrUpdateStatusIds(Category).then(result => {
      this.GetAllStatusIDs('Items');
      this.statusidCategory = new StatusIDCategory();
      this.createNewStatusId = false;
      this.toastr.success("Status ID  saved successfully.", "Status ID Saved", { timeOut: 600 });
    });
  }
  ClearCategory() {
    this.statusidCategory = new StatusIDCategory();
  }
  CreateNewStatusID(Val) {
    if (Val == 'Items') {
      this.createNewStatusId = true;
      this.ExpandCategoryGrid = false;
      this.statusidCategory = new StatusIDCategory();
      var NextsortOrder = this.allStatusIds.sort((a, b) => b.SortOrder - a.SortOrder)[0].SortOrder;
      this.statusidCategory.SortOrder = NextsortOrder + 1
    } else if (Val == 'Groups') {
      this.createNewStatusIdGroup = true;
      this.ExpandGroupsGrid = false;
      this.StatusGroup = new StatusIDGroup();

      var NextsortOrder = this.allStatusIdGroups.sort((a, b) => b.StatusSortOrder - a.StatusSortOrder)[0].StatusSortOrder;
      this.StatusGroup.StatusSortOrder = NextsortOrder + 1
    }
    
  }

  DoubleSort(arr, prop1, prop2) {
    let mysort = [...arr].sort((a, b) => {
      if (a[prop1] == b[prop1]) {
        if (a[prop2] == b[prop2]) {
          return 0;
        } else {
          return (a[prop2] < b[prop2]) ? -1 : 1;
        }
      } else {
        return (a[prop1] > b[prop1]) ? -1 : 1;
      }

    });
    return mysort;
  }

  GetAllStatusIDs(val) {
    if (val == 'Items') {
      this.userService.GetAllStatusIds(this.currentUser.id, this.StatusIDGroup).then(result => {
        this.allStatusIds = result;
        if (this.sortOrder === "1") {
          this.allStatusIds = this.allStatusIds.sort(function (x, y) {
            let a = x.StatusIDName.toUpperCase(),
              b = y.StatusIDName.toUpperCase();
            return a == b ? 0 : a > b ? 1 : -1;
          });
        }

        //this.allStatusIds = this.DoubleSort(result, 'StatusIDGroupName', 'SortOrder');
      });
    } else if(val =='Groups'){
      this.userService.GetAllStatusIdGroups(this.currentUser.id).then(result => {
        this.allStatusIdGroups = result;
        if (this.sortOrder === "1") {
          this.allStatusIdGroups = this.allStatusIdGroups.sort(function (x, y) {
            let a = x.StatusGroupName.toUpperCase(),
              b = y.StatusGroupName.toUpperCase();
            return a == b ? 0 : a > b ? 1 : -1;
          });
        }
      });
    }
    
  }
  Expand(val) {
    this.ExpandCategoryGrid = !this.ExpandCategoryGrid;
  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.createNewStatusId = true;
        this.statusidCategory = event.api.getSelectedNodes()[0].data;
      }
    } else {
      this.ClearStatusID();
    }
   
  }
  onGroupSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.createNewStatusIdGroup = false;
        this.StatusGroup = event.api.getSelectedNodes()[0].data;
      }
    } else {
      this.ClearStatusID();
    }
   
  }
  onSelectAll(item: any) {

  }
  onDropdownClose() {

  }
  StatusIDOrder(val,type) {
    this.sortOrder = val;
    this.GetAllStatusIDs(type);
  }

  StatusIDGroupChange(val) {

  }
  StatusIDGroupChangeForGrid(val) { this.GetAllStatusIDs('Items') }
  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
    
  }
}
