export class OutputPropsUserList {  
  public finalitems: string;
  public calanderonoff: boolean = false;
  public pageSize: string;
  public secondDrpValue: string;
  public thirdDrpValue: string;
  public fourthDrpValue: string;
  public fifthDrpValue: string;
  public seventhDrpValue: string;
  public eightDrpValue: string;
  public custType: string;
  public pageSizeChanged: boolean = false;
  public enableSearch: boolean = false;
  public startDateVal: string;
  public endDateVal: string;
}
