<div class="col-xs-12" style="height: 2%; background-color: rgb(186,220,153);">
 
</div>
<div class="col-xs-12" style="height: 6%;padding:0%">
  <div class="col-xs-11" style="float:left;margin-top:1.5%"><b>CONTACT SUMMARY</b></div>
  <div class="col-xs-1" style="float: right; margin-top: 1.5%">
    <img src="../../../assets/img/Black x.png" style="height: 20px;" (click)="close()">
  </div>
</div>
<br/>
<div class="col-xs-12" style="margin-top:4%">
  <div class="col-xs-3">
    <img *ngIf="contact.photo!=null" [src]="contact.photo" style="width:100%;height:99%">
    <img *ngIf="contact.photo==null" src="../../../assets/img/default contact photo.jpg" style="width: 100%; height: 99%" />
  </div>
  <div class="col-xs-9" style="font-weight:700;padding:0%">
    <div>{{contact.accountname}}</div><br />
    <div class="col-xs-12" style="padding:0px">
      <div class="col-xs-6" style="padding:0px">
        <div>
          {{contact.firstname}} {{contact.middlename}} {{contact.lastname}}<span *ngIf="contact.title != null">
            ,&nbsp;
            {{contact.titleName}}
          </span>
        </div>
        <div>
          {{contact.contacttypeName}}
        </div>
        <div>{{contact.email}}</div>
        <div>{{contact.office}}</div>
        <div>{{contact.cell}}</div>
        <div>{{contact.address1}}</div>
        <div>{{contact.address2}}</div>
        <div>
          {{contact.city}}<b *ngIf="contact.state">,</b>&nbsp;{{contact.state | uppercase}}.&nbsp;{{contact.zip | slice:0:5 }}
          <span *ngIf="contact.zip && contact.zip.length > 5">-</span>{{contact.zip | slice:5:20 }},&nbsp;
        </div><div>{{contact.country}}</div>
        <br />
        <div> Last Completed Task:{{contact.LastCompletedTask | date:"MM-dd-yyyy"}}</div>
        <div>Last Closed Ticket:{{contact.lastTicketcompleteDate | date:"MM-dd-yyyy" }}</div>
        <div>Last Campaign:</div>
        <br />
        <div>SaaS Date:{{contact.SassDate | date:"MM-dd-yyyy"}}</div>
        <div>SaaS Renewal:{{contact.SaaSRenewal | date:"MM-dd-yyyy"}}</div>
        <div>SaaS Term: {{contact.SaaStermName}}</div>
        <div>SaaS RCM: {{contact.SaaSRCM | date:"MM-dd-yyyy"}}</div>
        <br />
        <div>College :{{contact.collegeName}}</div>
        <div>Medical School :{{contact.medschoolName}}</div>
        <div>Residency : {{contact.residencyName}}</div>
        <div>Fellowship : {{contact.fellowshipName}}</div>
      </div>

      <div class="col-xs-6" style="padding:0px">
        <textarea placeholder="Add Text here" id="notesTextArea" rows="22" class="form-control" type="text" name="notedescription" [(ngModel)]="contact.SummaryNotes"
                  style="height: 100%; background-color: #f5f5f5" (change)="valuechange($event)"></textarea>
      </div>
    </div>

  </div>
  <app-dialog [(visible)]="crmDialog" *ngIf="crmDialog" id="crmDialog" [closable]="false">
    <div class="col-xs-12" style="padding:5%">
      <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
      <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
        <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">Contact Summary Notes</div><br />
        <div class="col-xs-12" style="padding: 0; padding-top: 3%;">Save Contact Summary Notes?</div>
      </div>
      <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
        <div class="col-xs-7" style="padding: 0;">

        </div>
        <div class="col-xs-5" style="padding:0;">
          <div class="col-xs-6" style="padding:0">
            <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                    (click)="SaveNote()">
              SAVE
            </button>
          </div>
          <div class="col-xs-6" style="padding:0">
            <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite;
          padding-left: 5%; color: #2ed1d1 " (click)="crmDialog=false;">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </app-dialog>
</div>
