import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { GridOptions, ColumnUtils, ColumnApi, RowDragEndEvent } from "ag-grid/main";
import { CustomerService } from '../../../services/customer.service';
import { OpprtunitiesService } from '../../../services/opprtunities.service';
import { ApplicationComponent } from '../../application.component';
import { EditdeleteComponent } from '../../editdelete/editdelete.component';
import { Document } from '../../../models/document';
import { UserService } from '../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { SalesXCRMChartTabs } from '../../../models/SalesXCRMChartTabs';
import { SystemRowConfiguration } from '../../../models/system-row-configuration';
import { LeadsService } from '../../../services/leads.service';
declare var $: any;
@Component({
  selector: 'app-my-salesxcrm-configuration',
  templateUrl: './my-salesxcrm-configuration.component.html',
  styleUrls: ['./my-salesxcrm-configuration.component.css']
})
export class MySalesxcrmConfigurationComponent implements OnInit {
  SalesXCRMHomePage: boolean = true;
  ShowDocumentNameDiv: boolean = true;
  ChartTabDiv: boolean = false;
  public gridOptionsDM: GridOptions;
  public ChartTabsGridOptions: GridOptions;
  public sideBar;
  public context;
  public documentscolumnDefs: any[];
  public ChartTabcolumnDefs: any[];
  currentuser: any;
  colSelections: string;
  userdocumentlist: any[] = [];
  Originaluserdocumentlist: any[] = [];
  datePipe: DatePipe = new DatePipe("en-US");
  private gridApi;
  doc: Document = new Document();
  public frameworkComponents;
  ShowAddDocName: boolean = false;
  ShowAddChartTab: boolean = false;
  finaldocumentlist: Document[] = [];
  documentlist: Document[] = [];
  ChartTabs: SalesXCRMChartTabs[] = [];
  ChartTab = new SalesXCRMChartTabs();
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration();
  @Input() type: string = null;
  constructor(private opprtunityservice: OpprtunitiesService, public applicationComponent: ApplicationComponent, private customerService: CustomerService, private userservice: UserService, private toastr: ToastrService, private leadsService: LeadsService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.initializeAgGrid();
    this.LoadDocNamepermission();
  }

  initializeAgGrid() {
    this.gridOptionsDM = <GridOptions>{
      enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: false, paginationPageSize: 1000, toolPanelSuppressPivotMode: false, rowSelection: 'single',
      toolPanelSuppressValues: false, enableCellChangeFlash: true, enableMultiRowDragging: true,

    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 56).then((config) => {

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = columnSelections;
      this.createColumnDefs(this.colSelections);

    });
    this.gridOptionsDM.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeleteComponent,
    };

  }
  createColumnDefs(columnSelections: string) {

    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      this.documentscolumnDefs = [];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] === "#") {
          this.documentscolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true, rowDrag:true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] === "PermissionlevelName") {
          this.documentscolumnDefs.push({ field: 'PermissionlevelName', headerName: 'PermissionlevelName', hide: true});
        } else if (fieldHeaderVisibleWidthArray[0] === "actions") {
          this.documentscolumnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: fieldHeaderVisibleWidthArray[2] === "true" ? false : true, pinned: 'right', width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else {
          this.documentscolumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] === "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }


      }
    } else {
      let selfObj = this;
      this.documentscolumnDefs = [

        {
          field: '#',
          headerName: '#',
          width: 70,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true
        },
        { field: 'DateUploaded', headerName: 'Date Created', hide: false },
        { field: 'documentname', headerName: 'Document Name', hide: false},
        { field: 'UploadedBy', headerName: 'Saved By', hide: false },
        { field: 'documentdescription', headerName: 'Description' },
        { field: 'Size', headerName: 'Size' },
        { field: 'docsavedTo', headerName: 'Document Saved To' },
        {
          field: 'doctab', headerName: 'Document Tab', cellEditor: "agRichSelectCellEditor", editable: true, cellRenderer(params) {
            return params.data.doctab;
          },
          cellEditorParams: {
            cellHeight: 25,
            values: ["Expense Report", "Sales Report", "Company Documents", "Performance Reviews", "Miscellaneous"],
            formatValue: function (value) {
            }
          },

        },
        { field: 'documentdescription', headerName: 'Document Description'},
        { field: 'DeActivatedUser', headerName: 'DeActivated By'},
        { field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', pinned: 'right', width: 80 },
      
      ];
    }
  }

  LoadDocNamepermission() {
    this.customerService.GetAllDocNamePermissionListforConfig("MySalesXCRM", this.currentuser.id).then((document) => {
      document.forEach(document => {
        document.DateUploaded = this.datePipe.transform(document.DateUploaded, "MM-dd-yyyy");
      });
      this.userdocumentlist = document;
      this.Originaluserdocumentlist = document;
    });
  }

  onAgGridReady(event) {
    this.gridApi = event.api;
  }
  SalesXCRMLoad(val) {
    if (val == 'Document Name') {
      this.SalesXCRMHomePage = false;
      this.ShowDocumentNameDiv = true;
      this.ChartTabDiv = false;
      this.initializeAgGrid();
      this.LoadDocNamepermission();
    } else if (val == 'Chart Tab') {
      this.SalesXCRMHomePage = false;
      this.ShowDocumentNameDiv = false;
      this.ChartTabDiv = true;
      this.initializeChartTabGrid();
    }
  }
  CancelDocuments() {

  }
  SaveDocuments() {
    this.finaldocumentlist = [];
    this.documentlist.forEach(document => {
      

        let newdoc = new Document();
        newdoc.id = document.id;
        newdoc.documentname = document.documentname;
        newdoc.uploadedby = this.currentuser.id;
        newdoc.uploadedfilename = document.uploadedfilename;
        newdoc.Size = document.Size;
        newdoc.documenttype = document.documenttype;
        newdoc.documentdescription = document.documentdescription;
        newdoc.permissionlevel = null;
        newdoc.doctype = 'MySalesXCRM';
        newdoc.imagecontent = document.imagecontent;
        newdoc.extension = document.extension;
        newdoc.accountid = this.currentuser.id;
        newdoc.type = 'MySalesXCRM';;
        
        this.finaldocumentlist.push(newdoc);

    });
    if (this.finaldocumentlist.length > 0) {
      this.userservice.addDocumentNamePermission(this.finaldocumentlist).then(result => {
        this.toastr.success("Document names with permissions saved successfully.", "Document", { timeOut: 600 });
        this.LoadDocNamepermission();
        this.documentlist = [];
      });
    }
  }

  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
  }

  addFiles(event) {
      this.addCharge();
  }

  addCharge() {
    this.documentlist = [];
    let newdoc = new Document();
    newdoc.documentname = "";
    newdoc.permissionlevel = 0;
    newdoc.uploadedfilename = "";
    newdoc.Size = "";
    newdoc.documenttype = "";
    newdoc.imagecontent = "";
    newdoc.extension = "";
    newdoc.documentdescription = "";
    newdoc.accountid = 0;
    newdoc.type = "";
    this.documentlist.push(newdoc);
  }

  editRowAg(rowdata) {
    this.documentlist.push(rowdata);
    this.ShowAddDocName = true;
  }

  onSelectionChanged(event) {

    var rowCount = event.api.getSelectedNodes();
    this.documentlist = [];
    rowCount.forEach(ele => {
      let newdoc = new Document();
      newdoc.id = ele.data.id;
      newdoc.documentname = ele.data.documentname;
      newdoc.permissionlevel = ele.data.permissionlevel;
      newdoc.uploadedfilename = ele.data.uploadedfilename;
      newdoc.Size = ele.data.Size;
      newdoc.documenttype = ele.data.documenttype;
      newdoc.imagecontent = ele.data.imagecontent;
      newdoc.extension = ele.data.extension;
      newdoc.documentdescription = '';
      newdoc.accountid = 0;
      newdoc.type = ele.data.type;
      this.documentlist.push(newdoc);
      this.ShowAddDocName = true;
    });

  }

  deleteRowAg(rowdata) {
    rowdata.isdeleted = 1;
    this.documentlist.push(rowdata);
    this.SaveDocuments();
  }
  closeDiv(val) {
    if (val == 'ShowDocumentNameDiv') {
      this.SalesXCRMHomePage = true;
      this.ShowDocumentNameDiv = false;
      this.ChartTabDiv = false;
    } else if (val == 'ChartTabDiv') {
      this.SalesXCRMHomePage = true;
      this.ShowDocumentNameDiv = false;
      this.ChartTabDiv = false;
    }
  }

  LoadChartTabs() {
    this.customerService.GetMySalesXCRMChartTabs(this.currentuser.id).then((ChartTabs) => {
     
      this.ChartTabs = ChartTabs;
    });

  }

  initializeChartTabGrid() {
    this.ChartTabsGridOptions = <GridOptions>{
      //pagination: false, enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: false, toolPanelSuppressPivotMode: false, rowSelection: 'single',
      //toolPanelSuppressValues: false, enableCellChangeFlash: true, enableMultiRowDragging: true,
       enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true,
      toolPanelSuppressValues: true, enableCellChangeFlash: true, enableMultiRowDragging: true,
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createChartTabColumnDefs("");
    this.LoadChartTabs();
    this.ChartTabsGridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeleteComponent,
    };

  }
  createChartTabColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.ChartTabcolumnDefs = [

        {
          field: '#',
          headerName: '#',
          width: 70,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true
        },
      {
        field: 'ChartTabId', headerName: 'Id', hide: false, width:80
      },
      { field: 'ChartTabOrder', headerName: 'Sort Order', hide: false, width: 80 },
      { field: 'ChartTabDescription', headerName: 'ChartTab Description', hide: false },
      {
        field: 'Editable', headerName: 'Editable', hide: false, cellRenderer(params) {
          if (params.data.Editable == 1) {
            return "Yes";
          }
          else {
            return "No";
          }
        } },
      
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:20%"/>';
          eDiv.addEventListener('click', function () {
            selfObj.deleteChartTag(params.data);
          });
          return eDiv;
        }
      }
      ];
  }

  SaveChartTab() {

    this.ChartTab.isdeleted = 0;
    this.customerService.AddEditChartTab(this.ChartTab).then((ChartTabs) => {
      this.ChartTab = new SalesXCRMChartTabs();
      this.ShowAddChartTab = false;
      this.LoadChartTabs();
    });
  }
  onChargeTabSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.ChartTab = event.api.getSelectedNodes()[0].data;
        this.ShowAddChartTab = true;
      }
    }
  }

  onRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("ChartTabs");
  }
  saverowconfiguration(type) {
    var sortoorder = [];
    if (type == 'ChartTabs') {
      this.ChartTabsGridOptions.api.forEachNode((node) => {
        sortoorder.push(node.data.ChartTabId);
      });
    } else {
      this.gridOptionsDM.api.forEachNode((node) => {
        sortoorder.push(node.data.id);
      });
    }
   
    
    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i == a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentuser.id;
    
    this.leadsService.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });
    });

  }

  deleteChartTag(data) {
    this.ChartTab = data;
    this.ChartTab.isdeleted = 1;
    
    this.customerService.AddEditChartTab(this.ChartTab).then((ChartTabs) => {
      this.ChartTab = new SalesXCRMChartTabs();
      this.ShowAddChartTab = false;
      this.LoadChartTabs();
    });
  }

  printSortStateToConsole(event) {
    
    const sortState = this.gridApi.getSortModel();
    if (sortState.length == 0) {
    } else {
      
      for (var i = 0; i < sortState.length; i++) {
        const item = sortState[i];
        var sortoorder = [];
      this.ChartTabsGridOptions.api.forEachNode((node) => {
          sortoorder.push(node.data);
        });

        if (item.sort == 'asc') {
          sortoorder = sortoorder.sort(function (a, b) { return a.ChartTabOrder - b.ChartTabOrder });

        } else {
          sortoorder = sortoorder.sort(function (a, b) { return a.ChartTabOrder + b.ChartTabOrder });
        }
        let IDs= sortoorder.map(s => s.ChartTabId);

        this.rowconfig.SortOrder = IDs.filter((el, i, a) => i == a.indexOf(el)).join(",");
        this.rowconfig.Type = "ChartTabs";
        this.rowconfig.userid = this.currentuser.id;
        
        this.leadsService.SavestatusOrder(this.rowconfig).then(result => {
          this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });
        });
        
      }
    }
    
  }
  onRowDragEndDocumentName(event: RowDragEndEvent) {
    this.saverowconfiguration("DocumentName");
  }
}
