export class CampaignType {
  public CampaignTypeId: number;
  public CampaignTypeName: string;
  public SortOrder: number;
  public CreatedBy: number;
  public isdeleted: number;
}

export class CampaignExpense {
  public CampaignExpenceId: number;
  public CampaignExpenseName: string;
  public SortOrder: number;
  public CreatedBy: number;
  public isdeleted: number;
}


export class CampaignROI {
  public SalesRevenue: number = 0;
  public MonthlyRevenue: number= 0;
  public MonthlyPerYear: number =0;
  public salesPlusMonthlyPerYear: number = 0;
  public Expenses: number = 0;
  public ProfitLoss: number = 0;
}
