<div class="col-xs-12" style="padding:0;height:100%">
  <ag-grid-angular style="height:100%;" class="ag-theme-balham" id="Open" *ngIf="Type =='Open'"
                   [gridOptions]="gridOptionsView"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="OpenProjectsList"
                   [sideBar]="sideBar"
                   [context]="context"
                   (selectionChanged)="onSelectionChanged($event)"
                   (cellDoubleClicked)="cellDoubleClicked($event)">
  </ag-grid-angular>

  <ag-grid-angular style="height:100%;" class="ag-theme-balham" id="Pended"  *ngIf="Type =='Pended'"
                   [gridOptions]="PendedgridOptionsView"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="PendedProjectsList" 
                   [sideBar]="sideBar"
                   [context]="context"
                   (selectionChanged)="onSelectionChanged($event)"
                   (cellDoubleClicked)="cellDoubleClicked($event)">
  </ag-grid-angular>

  <ag-grid-angular style="height:100%;" class="ag-theme-balham"  id="Completed" *ngIf="Type =='Completed'"
                   [gridOptions]="CompletedgridOptionsView"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="CompletedProjectsList"
                   [sideBar]="sideBar"
                   [context]="context"
                   (selectionChanged)="onSelectionChanged($event)"
                   (cellDoubleClicked)="cellDoubleClicked($event)">
  </ag-grid-angular>

  <ag-grid-angular style="height:100%;" class="ag-theme-balham"  id="Cancelled" *ngIf="Type =='Cancelled'"
                   [gridOptions]="CancelledgridOptionsView"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="CancelProjectsList"
                   [sideBar]="sideBar"
                   [context]="context"
                   (selectionChanged)="onSelectionChanged($event)"
                   (cellDoubleClicked)="cellDoubleClicked($event)">
  </ag-grid-angular>


</div>
