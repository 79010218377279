import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
declare var $: any;
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class DialogComponent implements OnInit {
  @Input() closable = true;
  @Input() type: string;
  @Input() visible: boolean;
  @Input() CalendarDialog: boolean;
  @Input() CloseButtonColor: string = 'black';
  @Input() StayVisible: boolean = false;
  @Input() OutSideClick: boolean = true; 
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() Change: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {  
    var url = window.location.href;
    if (url.indexOf('month-calender') > -1) {
    
    }
    if (url.indexOf('addAccount') > -1) {
    }
   
  }

  close() {   
    if ($.trim($("#test").val()) != '') {
      if (this.StayVisible === false) {
       
        const yes = confirm("You have not completed adding the " + $("#test").val() + " information, click OK to exit (the Data will not be saved) or click Cancel and continue adding information in the " + $("#test").val() + ".");
        if (yes) {
          $("#test").val('');
          this.visible = false;
          this.visibleChange.emit(this.visible);
          $('#typeClicked').val('');
          $('#closeDetailsDialog').click();
        } else {

          return false;
        }
      } else {
        this.visibleChange.emit(true);
      }
      
    } else {
      if (this.StayVisible == false) {
        if (this.type == "Contactphoto") {
          this.Change.emit("Contactphoto");
        }
        else{
          this.visible = false;
          this.visibleChange.emit(this.visible);
        }
       
      } else if (this.StayVisible == true) {
        this.visibleChange.emit(true);
      }
      
      $('#typeClicked').val('');
      $('#closeDetailsDialog').click();
    }

  }
}
