
<div class="container" style="height:100%;padding:0;">
  <div class="col-xs-12" style="padding: 0; border: 1px solid white;" *ngIf="Type == 'Add'">
    <div class="col-xs-4" style="padding: 0;">
      <div class="col-xs-12" style="border: 1px solid black; height: 200px">
        <account-details [viewaccount]="viewaccount" [account]="account" [tab]="''"
                         [noteslist]="allnotes" [pageFrom]="'Proposal'" [ViewData]="'Account'" [contact]="''"
                         (btnClickEvent)="btnClickReceived($event)"></account-details>
      </div>
      <div class="col-xs-12" style="margin-top: 1%; padding: 0%;height: 800px" *ngIf="!ProposalTableExpand">
        <div class="col-xs-12" style="height: 0.5%; padding: 0%;background-color:orangered;">
        </div>
        <div class="col-xs-12" style="height:5%; padding: 0%;">
          <label style="padding: 1.2%; text-transform: uppercase;"> Proposal Details</label>
        </div>
        <div class="col-xs-12" style="padding: 0%; border: 1px solid gray; border-top: none; height: 600px;">
          <form [formGroup]="NewForm">
            <div class="col-xs-12 zeroPadding" style="height: 7%; margin-top: 1%; padding: 0% 0% 1% 0%;">
              <div class="col-xs-3 " style="padding: 1% 0 0 2%;width:31%;font-size:13px;">
                <label>Contact Name</label>
              </div>
              <div class="col-xs-9" style="width:69%;height:99%;padding-right:0">
                <select class="form-control decreasefieldSize" id="ContactList"
                        [(ngModel)]="Proposal.opportunities.accountorcontactid" name="ContactList"
                        (click)="ContactSelected($event);handleClick($event)" required
                        style="height: 92%;font-size: 13px">
                  <option value="null" disabled>Select Contact</option>
                  <option *ngFor="let conatct of  contactList" value={{conatct.id}}>
                    {{conatct.itemName}}
                  </option>
                </select>
              </div>
            </div>

            <div *ngFor="let field of Fields" class="col-xs-12" style="height:7%;padding:0;">
              <div class="col-xs-12 removeCompletePadding" style="padding:0;height:100%">
                <div class="col-xs-3" style="padding:0% 0 0 2%;font-size:13px;width:31%">
                  <label *ngIf="field.ColumnName != 'status' && field.ColumnName != 'actualclosedate' " style="margin:10% 0 0 0">{{field.DisplayAs}}</label>
                </div>
                <div class="col-xs-9" style="height:100%;width:69%;padding-right:0;">
                  <div class="col-xs-12" style="padding:0;height:100%">
                    <div class="{{field.ColumnName}}" style="padding:0;height:100%" *ngIf="field.ColumnName == 'item' || field.ColumnName == 'leadfromid' || field.ColumnName == 'drs' || field.ColumnName == 'midlevels' || field.ColumnName == 'PC'">
                      <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}"
                              [formControlName]="field.ColumnName" [(ngModel)]="Proposal.opportunities[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'"
                              [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)" style="height:92%;font-size:13px">
                        <option *ngFor="let customer of field.Data" value="{{customer.id}}" style="color:black;">
                          {{customer.opportunityitem}}
                        </option>
                      </select>
                    </div>
                    <div class="{{field.ColumnName}}" style="padding:0;height:100%;display:none" *ngIf="field.ColumnName == 'status'">
                      <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName"
                              [(ngModel)]="Proposal.opportunities[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'"
                              [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value);"
                              style="height: 92%;font-size: 13px;background-color:#eee;">
                        <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                          {{customer.opportunityitem}}
                        </option>
                      </select>
                    </div>
                    <div class="{{field.ColumnName}}" style="padding:0;height:100%" *ngIf="field.ColumnName == 'ownerid'">
                      <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="Proposal.opportunities[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)" style="height: 92%;font-size: 13px">

                        <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                          {{customer.itemName}}
                        </option>
                      </select>
                    </div>
                    <div class="{{field.ColumnName}}" style="padding:0;height:100%" *ngIf="field.ColumnName == 'RR'||field.ColumnName == 'dollers'">
                      <input name="{{field.ColumnName}}" value="{{Proposal.opportunities[field.ColumnName]}}" [(ngModel)]="Proposal.opportunities[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" [required]="(field.RequiredField == 'Yes')? 'true':'false'"
                             (keyup)="FormDataEntered($event.target)" style="height: 92%;display:none;font-size: 13px" readonly />

                    </div>


                    <div class="{{field.ColumnName}}" style="padding:0;height:100%">
                      <input name="{{field.ColumnName}}" value="{{Proposal.opportunities[field.ColumnName]}}" [(ngModel)]="Proposal.opportunities[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && field.ColumnName!='actualclosedate'" (keyup)="FormDataEntered($event.target)" style="height: 92%; font-size: 13px" required />

                      <input name="{{field.ColumnName}}" type="date" class="form-control decreasefieldSize" [formControlName]="field.ColumnName" [(ngModel)]="Proposal.opportunities[field.ColumnName]" *ngIf="(field.DataType == 'Date' ||  field.DataType == 'Date Range') && field.ColumnName!='actualclosedate'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" value="2018-01-01" (change)="FormDataEntered($event.target)" style="height: 92%; font-size: 13px" />
                    </div>


                  </div>

                </div>
              </div>


            </div>

            <input type="text" id="proposalvalid" value="{{NewForm.valid}}" style="display:none" />
          </form>
          </div>
        </div>
      </div>
    <div class="col-xs-8" style="padding: 0;">
      <div class="col-xs-4" [style.display]="(PreviewDocuments == true )? 'none': 'block'"
           style="border: 1px solid black; height: 200px; padding: 0.5%;">
        <div class="col-xs-12" style="padding: 0; font-weight: bold;">
          <div class="col-xs-7">
            <span>HISTORY</span>
          </div>
          <div class="col-xs-5"></div>
        </div>
        <div class="col-xs-12" style="padding: 0;margin-top:1%; font-weight: bold;">
          <div class="col-xs-7">
            <span>Proposals Pended</span>
          </div>
          <div class="col-xs-5">
            <span>{{PendedProposalCount}}</span>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 0; font-weight: bold;">
          <div class="col-xs-7">
            <span>Proposals Open</span>
          </div>
          <div class="col-xs-5">
            <span>{{OpenProposalCount}}</span>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 0; font-weight: bold;">
          <div class="col-xs-7">
            <span>Proposals Won</span>
          </div>
          <div class="col-xs-5">
            <span>{{WonProposalCount}}</span>
          </div>
        </div>
        <div class="col-xs-12" style="padding:0;font-weight: bold;">
          <div class="col-xs-7">
            <span>Proposals Lost</span>
          </div>
          <div class="col-xs-5">
            <span>{{LostProposalCount}}</span>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 8% 0 0 0; color: #d75b5b; font-size: 20px; text-align: center; height: 37%;" *ngIf="this.Proposal.ProposalID">
          <span>PENDED PROPOSAL</span>
        </div>

      </div>
      <div class="col-xs-4" style="border: 1px solid black; height: 200px; padding: 0.5%;" [style.display]="(PreviewDocuments == true )? 'none': 'block'">
        <div class="col-xs-12" style="padding:0;">
          <div class="col-xs-12" style="padding: 0; font-weight: bold;">
            <div class="col-xs-7" (click)="ShowPercentDiscount()">
              <span>INVESTMENT</span>
            </div>
            <div class="col-xs-5"></div>
          </div>
          <div class="col-xs-12" style="padding: 4px;font-weight: 600">
            <div class="col-xs-6">
              <div class="col-xs-12" style="padding:0%;text-align: left">Price</div>
              <div class="col-xs-12" style="padding:0;">
                <input class="form-control" id="term" type="text" name="dollers" disabled [(ngModel)]="TotalProposalPriceString" required style="padding: 0; font-size: 13px; background-color: #a2e477; border: 1px solid darkslategray; border-radius: 5px; " />
              </div>
            </div>
            <div class="col-xs-6">
              <div class="col-xs-12" style="padding:0%;text-align: left">Monthly</div>
              <div class="col-xs-12" style="padding:0;">
                <input class="form-control" id="term" type="text" name="RR" disabled [(ngModel)]="MonthlyTotalProposalPriceString" required style="padding: 0; font-size: 13px; background-color: #a2e477; border: 1px solid darkslategray; border-radius: 5px; " />
              </div>
            </div>

          </div>
          <div class="col-xs-12" *ngIf="ShowPercentDiscountBox" style="padding:0%">
            <div class="col-xs-6">
              <div class="col-xs-12" style="padding:0%;text-align: left;color:red">Discount %</div>
              <div class="col-xs-12" style="padding:0;">
                <input class="form-control" id="PercentPriceDiscount" [(ngModel)]="Proposal.TotalDiscountPercent" type="number" name="Percent" placeholder="%" style="padding: 0; font-size: 13px;  background-color: white; border: 1px solid red; border-radius: 5px;" (change)="PriceDiscountEnter()" (keyup)="PriceDiscountEnter()" onkeypress="return event.charCode >= 48" />
              </div>
            </div>
            <div class="col-xs-6" style="">
              <div class="col-xs-12" style="padding:0%;text-align: left;color:red">Discount %</div>
              <div class="col-xs-12" style="padding:0;">
                <input class="form-control" id="PercentMonthlyDiscount" [(ngModel)]="Proposal.MonthlyDiscountPercent" type="number" name="Percent" placeholder="%" style="border: 1px solid red; padding: 0; font-size: 13px;" (change)="MonthlyDiscountEnter()" (keyup)="MonthlyDiscountEnter()" onkeypress="return event.charCode >= 48" />
              </div>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="ShowPercentDiscountBox" style="padding:0%">
            <div class="col-xs-6">
              <div class="col-xs-12" style="padding:0%;text-align: left;color:red">Price/Discount </div>
              <div class="col-xs-12" style="padding:0;">
                <input class="form-control" id="PriceDiscount" type="text" name="Price Discount"
                       [(ngModel)]="Proposal.opportunities.dollers" disabled style="padding: 0; font-size: 13px; border: 1px solid red; background-color: white; border-radius: 5px;" />
              </div>
            </div>
            <div class="col-xs-6">
              <div class="col-xs-12" style="padding:0%;text-align: left;color:red">Monthly/Discount</div>
              <div class="col-xs-12" style="padding:0;">
                <input class="form-control" id="MonthlyDiscount" type="text" name="monthly Discount" [(ngModel)]="Proposal.opportunities.RR" disabled style="padding: 0; font-size: 13px; border: 1px solid red; border-radius: 5px; background-color: white;" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-4" style="border: 1px solid black; height: 200px; padding: 0.5%;" [style.display]="(PreviewDocuments == true )? 'none': 'block'">
        <div class="col-xs-12" style="padding: 0; font-weight: bold;">
          <div class="col-xs-7">
            <span>PREVIEW</span>
          </div>
          <div class="col-xs-5"></div>
        </div>

        <div class="col-xs-12" style="padding: 0; height: 50%; border-bottom: 1px solid black;">
          <div class="col-xs-6" style="padding:0;height:100%;">
            <img src="../../../assets/img/Proposal - Viewed.png" style="height: 75%;width: 75%;margin-top: 9%;margin-left: 35px;" *ngIf="ProposalDocumentGenerated" (click)="PDFNew('Proposal',true)" />
            <img src="../../../assets/img/Proposal - Not Viewed.png" style="height: 75%;width: 75%;margin-top: 9%;margin-left: 35px;" *ngIf="!ProposalDocumentGenerated" (click)="PDFNew('Proposal',true)" />

          </div>
          <div class="col-xs-6" style="padding:0;height:100%">
            <img src="../../../assets/img/Purchase Order Viewed.png" style="height: 75%;width: 75%;margin-top: 9%" *ngIf="PODocumentGenerated" (click)="PDFNew('PurchaseOrder',true)" />
            <img src="../../../assets/img/Purchase Order - Not Viewed.png" style="height:75%;width: 75%;margin-top: 9%" *ngIf="!PODocumentGenerated" (click)="PDFNew('PurchaseOrder',true)" />
          </div>
        </div>
        <div class="col-xs-12" style="padding:3% 0 0 0;height:64px;text-align:center;">
          <div *ngIf="ProposalID>0" class="col-xs-4" style="height: 80%; padding: 0 2% 0 3%;">
            <button (click)="CancelProposal = true;PendProposalCss('CancelProposal')" style="width: 100%; background: #2A3740; color: #dd9ab0; font-weight: 700; font-family: Calibri; border: 1px solid #000 !important; border-radius: 15px; margin-left: 2%; height: 100%">
              DISCARD
            </button>
          </div>
          <div *ngIf="ProposalID==undefined" class="col-xs-4" style="height: 80%; padding: 0 2% 0 3%;">
            <button (click)="Close()" style="width: 100%; background: #2A3740; color: #dd9ab0; font-weight: 700; font-family: Calibri; border: 1px solid #000 !important; border-radius: 15px; margin-left: 2%; height: 100%">
              DISCARD
            </button>
          </div>

          <div class="col-xs-4" style="height: 80%; padding: 0 2% 0 2%;">
            <button (click)="this.PreviewDocuments = false;SaveDialog(1);" style="width: 100%; background: #2A3740;color: yellow;font-weight: 700;font-family: Calibri;border: 1px solid #000 !important; border-radius: 15px;margin-left: 2%;height:100%">
              PEND
            </button>
          </div>
          <div class="col-xs-4" style="height: 80%; padding: 0 3% 0 2%;">
            <button (click)="SaveDialog(0);DiasbleSaveButton = true" style="width: 100%; background: #2A3740;color: rgb(146, 208, 80);font-weight: 700;font-family: Calibri;border: 1px solid #000 !important; border-radius: 15px;margin-left: 2%;height:100%" [disabled]="DiasbleSaveButton">
              SAVE
            </button>
          </div>
        </div>
      </div>
      <div [ngClass]="'col-xs-12'" style="padding: 0 0 0 0.8%; height: 800px; margin-top: 0.5%"
           [style.display]="(PreviewDocuments == true )? 'none': 'block'">
        <div class="col-xs-12" style="height: 0.5%; padding: 0%;background-color:orangered;">
        </div>
        <table style="width:100%;height:5%;color:black">
          <tr height="30" style="width:100%;">
            <td height="30" style="width:25%;padding-left:0.5%;color:black !important">
              <angular2-multiselect [data]="categories" [(ngModel)]="Displaysize"
                                    [settings]="dropdownSetting"
                                    (onSelect)="onItemSelect($event)"
                                    (onDeSelect)="OnItemDeSelect($event)"
                                    (onSelectAll)="onSelectAll($event)"
                                    (onDeSelectAll)="onDeSelectAll($event)">
              </angular2-multiselect>
            </td>
            <td height="30" style="width:19%;margin-left:0.5%">
              <div class="col-xs-12" style="padding:0;">
                <!--*ngIf="!ShowPercentDiscountBox"-->
                <div class="col-xs-1" style="padding:1.5%;width: 18.333333%;"><div class="DefaultDiscountCss ActiveDiscount" id="12" (click)="DiscountSelection('12')">12</div></div>
                <div class="col-xs-1" style="padding:1.5%;   width: 18.333333%;"><div class="DefaultDiscountCss" id="24" (click)="DiscountSelection('24')">24</div></div>
                <div class="col-xs-1" style="padding:1.5%;    width: 18.333333%;"><div class="DefaultDiscountCss" id="36" (click)="DiscountSelection('36')">36</div></div>
              </div>
              <!--<label *ngIf="ShowPercentDiscountBox">Item</label>-->
            </td>
            <td height="30" style="width:6%;padding-left:0.5%"><span>Units</span></td>
            <td height="30" style="width:6%;padding-left:0.5%"><span>Price</span></td>
            <td height="30" style="width:6%;padding-left:0.5%"><span>Total</span></td>
            <td height="30" style="width:1%;color:lightgrey;font-size:20px;">|</td>
            <td height="30" style="width:6%;padding-left:0.5%"><span>Monthly</span></td>
            <td height="30" style="width:6%"><span>&nbsp;&nbsp;Total</span></td>
            <td height="30" style="width:2%" (click)="ExpandTable()">
              <img src="../../../assets/img/Black Expand View.png" style="height:60%;width:70%" *ngIf="!ProposalTableExpand" />
              <img src="../../../assets/img/Black Collapse View.png" style="height:60%;width:70%" *ngIf="ProposalTableExpand" />
            </td>
          </tr>
        </table>
        <div class="col-xs-12 scroll-c" style="padding: 0; height: 600px; overflow-y: scroll; border: 1px solid black; border-top: none;">
         
          <table *ngFor="let ProposalItem of  allProposalItemsDuplicate" style="margin-top:0.3%">
            <tr style="width:100%">
              <td colspan="7" *ngIf="ProposalItem.CategoryName != null" style="padding:0.5%">
                <label style="font-size:16px;font-weight:800;margin-left:2%">{{ProposalItem.CategoryName}} </label>
              </td>
            </tr>
            <tr height="30" style="width:100%;font-size:13px;">
              <td style="width:0.5%;padding-left:0.5%"></td>
              <td style="width:54%;"><span>{{ProposalItem.ProposalItemName}}</span></td>
              <td style="width:8%;padding-left:0.5%">
                <input type="number" id="Units{{ProposalItem.ItemId}}" style="width:100%;text-align:center" (change)="UnitsEnter(ProposalItem);handleClick($event)" (keyup)="UnitsEnter(ProposalItem);handleClick($event)" onkeypress="return event.charCode >= 48" />
              </td>
              <td style="width:8%;padding-left:0.5%">
                <input *ngIf="NumberOfMonths==12" type="text" value="{{ProposalItem.Price12 | currency}}" disabled style="width:100%" />
                <input *ngIf="NumberOfMonths==24" type="text" value="{{ProposalItem.Price24 | currency}}" disabled style="width:100%" />
                <input *ngIf="NumberOfMonths==36" type="text" value="{{ProposalItem.Price36 | currency}}" disabled style="width:100%" />
              </td>

              <td style="width:8%;padding-left:0.5%">
                <input type="text" id="Total{{ProposalItem.ItemId}}" disabled style="width:100%" />
              </td>
              <td style="width:1%"></td>
              <td style="width:8%;padding-left:0.5%">
                <input type="text" *ngIf="NumberOfMonths==12" value="{{ProposalItem.Monthly12 | currency}}" disabled style="width:100%" />
                <input type="text" *ngIf="NumberOfMonths==24" value="{{ProposalItem.Monthly24 | currency}}" disabled style="width:100%" />
                <input type="text" *ngIf="NumberOfMonths==36" value="{{ProposalItem.Monthly36 | currency}}" disabled style="width:100%" />
              </td>

              <td style="width:8%;padding-left:0.5%"><input type="text" id="MonthlyTotal{{ProposalItem.ItemId}}" disabled style="width:100%" /></td>
              <td style="width:2%"></td>
            </tr>

          </table>
        </div>
      </div>
      <div [ngClass]="'col-xs-12'" style="height:100%;padding:0%;" *ngIf="PreviewDocuments">
        <div class="col-xs-12" style="padding:0;height:5%;background:#5e5e5e;color:white">
          <div class="col-xs-1" (click)="PreviewPDFExpand()" style="padding:0;height:100%;float:right">
            <img src="../../../assets/img/White Expand View .png" style="height:70%;width:25%;margin:5% 0 0 30%" *ngIf="!ExpandPreviewPDFDiv" />
            <img src="../../../assets/img/Collapse View .png" style="height:81%;width:18%;margin:2% 0 0 30%" *ngIf="ExpandPreviewPDFDiv" />
            <div class="col-xs-2" style="padding:0;height:100%;color:white;float:right;right:15%">
              <span style="font-size:18px;" (click)="ClosePreviewDocuments()">
                <img src="../../../assets/img/White x.png" style="height:25px" />
              </span>
            </div>
          </div>

        </div>
        <div id="pdfDiv1" class="col-xs-12" style="height:632px">
          <div *ngIf="!showpdf" style="padding:25%">
            <h1>Please Wait</h1>
            <h3 *ngIf="Proposal.isPended==1">The System is Pending the Proposal and Purchase Order.......</h3>
            <h3 *ngIf="Proposal.isPended==0">The System is Saving the Proposal and Purchase Order.......</h3>
          </div>

        </div>

      </div>
    </div>
</div>
  <app-dialog [(visible)]="SaveConfirmationDialog" *ngIf="SaveConfirmationDialog" id="SaveConfirmationDialog" [closable]="false" [OutSideClick]="false">
    <div class="col-xs-12" style="padding:5%">
      <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
      <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
        <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">{{this.Proposal.NumberOfMonths}} Months Proposal / PO</div><br />
        <div class="col-xs-12" style="padding: 0; padding-top: 3%;">Save Proposal and Purchase Order?</div>
      </div>
      <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
          <div class="col-xs-6" style="padding:0">
            <button class="btn" style="float:left;background-color: #0098f7 !important;color: white;margin-top:1%"
                    (click)="SaveProposal('Yes',pendedval)">
              Yes
            </button>
          </div>
          
          <div class="col-xs-6" style="padding:0">
            <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1 " (click)="CancelSaveConfirmationDialog();DiasbleSaveButton = false">Cancel--Return to Editing Proposal</button>
          </div>
       
      </div>
    </div>
  </app-dialog>


  <app-dialog [(visible)]="FormValidationDialog" *ngIf="FormValidationDialog" id="FormValidationDialog" [closable]="false" [OutSideClick]="false">
    <app-delete-popup [title]="'Saving Proposal'" [description]="'Fields in Red Must Be Completed?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="false" (sendMsgEvent)="FormValidation('No');DiasbleSaveButton = false;">
    </app-delete-popup>

  </app-dialog>


  <app-dialog [(visible)]="DocumentWarningDialog" *ngIf="DocumentWarningDialog" id="DocumentWarningDialog" [closable]="false" [OutSideClick]="false">
    <div class="col-xs-12" style="padding: 3% 2%;">
      <label style="font-size: 20px; font-weight: 800; padding-bottom: 2%;">SalesXCRM Message</label>
      <br />
      <span *ngIf="pendedval==1" style="font-size: 17px;font-weight: bold; "> SAVE PENDED PROPOSAL AND PO </span>
      <span *ngIf="pendedval==0" style="font-size: 17px; color: #cb4a48; font-weight: bold; "> PROPOSAL AND PO PREVIEW ALERT </span>
      <br />
      <div class="col-xs-12" style="padding:0;margin-top:2%">
        <span *ngIf="pendedval==1" style="font-size:17px;color:black">Select SAVE to Pend the Proposal and Purchase Order.</span>
        <span *ngIf="pendedval==0" style="font-size:17px;color:black">
          You have not Previewed both the Proposal and the Purchase Order. It is recommended to always preview
          both the proposal and the purchase order before saving. Once saved, the proposal and the purchase order can not be edited.
        </span>
        <br />
      </div>
      <div class="col-xs-12" style="height:30%;padding:0">
        <div class="col-xs-1"></div>
        <div class="col-xs-7" style="margin-top: 7%; padding: 0; text-align: right; font: initial; width: 64%;">
          <label style="text-decoration:underline;color:black;text-align:center;cursor:pointer" (click)="CloseandPreview();DiasbleSaveButton = false">RETURN TO CREATE PROPOSAL</label>
        </div>
        <div class="col-xs-3" id="taskSave" style="margin-top: 5%; padding: 0; text-align: right;">
          <button class="btn noprovider" (click)="PDFNew('SaveWithOutPreview',true)" style="width: 55%; background: #0098f7 !important; color: white; font-weight: 700; border-radius: 5px; margin-left: 2%; height: 42px; ">SAVE</button>
        </div>
      </div>
    </div>

  </app-dialog>


  <div class="col-xs-12" style="padding:0 0% 0 0;height:100%" *ngIf="Type !== 'Add'">
    <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 0.3% 0 0.3% 0; height: 7%" *ngIf="PageFrom!='Leads'">
      <userdataheader [parentComp]="'Proposal'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
    </div>
    <div class="col-xs-12" style="padding:0" [style.height.%]="PageFrom=='Leads' ? '100' : '89.5'">
      <div [ngClass]="(ShowPdfDiv == true)? 'col-xs-7':(ContactDetails == true)? 'col-xs-4' : 'col-xs-12'" style="padding:0;height:100%" *ngIf="!ExpandPDFDiv" [style.border]="(applicationComponent.searchCriteriadata != '')?'3px solid #00B0F0':'none'">

        <div style="height:4%" *ngIf="ContactDetails && (ShowPdfDiv == false)">
          <span (click)="ClosePropDiv()"><img src="../../../assets/img/Black x.png" style="height: 21px; float: left; transform: rotate(180deg);" /></span>
          <b style="margin-left:2%"> PROPOSALS ({{AllProposals.length}})</b>
          <span><img src="../../../assets/img/Open Quickview Grid Panel.png" style="height: 23px; float: right; transform: rotate(180deg);" /></span>
        </div>

        <div *ngIf="EnableSearch" class="col-xs-12" style="padding: 0; margin-bottom: 0.3%;">
          <div class="col-xs-11" style="padding:0;width:98%">
            <input type="text" class="form-control" id="filter-text-box" placeholder="Search by Account Name or Phone Number (Example 800 475 1234)" (input)="onFilterTextBoxChanged()" [(ngModel)]="value" />
          </div>
          <div class="col-xs-1" style="padding: 0; width:2%" (click)="ClearSearch()">
            <img src="../../../assets/img/Close Icon.png" style="height:23px;float:right;">
          </div>
        </div>

        <div class="col-xs-12" style="padding:0; height:94.5%">
          <ag-grid-angular style="width: 100%;height:100%" class="ag-theme-balham"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="AllProposals"
                           [sideBar]="sideBar"
                           (columnVisible)="columnVisible($event)"
                           (selectionChanged)="rowClickAgGrid($event)"
                           (dragStopped)="dragStopped($event)"
                           (columnResized)="columnResized($event)"
                           (gridReady)="onAgGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>

      <div class="col-xs-8" *ngIf="ContactDetails && (ShowPdfDiv == false)" style="padding-right:0;height:100%">
        <div class="col-xs-8" style="padding:0;height:100%;width:66.4%">
          <div class="col-xs-12 removePadding" style="height: 30%">
            <account-details *ngIf="AllProposals.length>0" [viewaccount]="viewaccount" [account]="'Accounts'" [tab]="''" [noteslist]="allnotes" (btnClickEvent)="btnClickReceived($event)" [pageFrom]="'ProposalAccount'" [ViewData]="'Contact'" [contact]="contact"></account-details>
          </div>
          <div [ngClass]="'col-xs-12'" style="padding: 0 0% 0 0%;margin-top:1%;height:68%">
            <div class="col-xs-10" style="padding:0 0 0% 0%;height:8%">
              <label><b style="font-weight: 700 !important; font-size: 15px;">Proposal History</b></label>
            </div>
            <div *ngIf="AllProposals.length>0" class="col-xs-2" style="padding:0 0 0% 2%;height:8%">

              <div class="col-xs-2" style="padding:0 0 0% 2%;height:8%">

              </div>
              <div class="col-xs-5" style="padding:0 0 0% 0%;height:8%">

                <img src="../../../assets/img/PDF_file_icon.svg.png" style="width: 55%;" (click)="PDFClick(Proposal.ProposalPDF, 'Proposal');" />
              </div>
              <div class="col-xs-5" style="padding:0 0 0% 0%;height:8%">

                <img src="../../../assets/img/PDF_file_icon.svg.png" style="width: 55%;" (click)="PDFClick(Proposal.ProposalPOPDF, 'Proposal');" />
              </div>


            </div>

            <div [ngClass]="(Proposal.isPended == 1)?'col-xs-12 pendedmark':'col-xs-12'" style="height:92%;overflow: auto;padding: 0 0 0 0%;">
              <ag-grid-angular class="ag-theme-balham"
                               style="height:100%;width:99%"
                               [gridOptions]="gridHistoryOptions"
                               [columnDefs]="columnDefs"
                               [defaultColDef]="applicationComponent.defaultGridColDef"
                               [rowData]="AllProposalsHistory"
                               [context]="context"
                               [sideBar]="sideBar">
              </ag-grid-angular>

            </div>
          </div>
        </div>
        <div class="col-xs-4" style="padding:0;height:100%;border: 1px solid rgb(199, 199, 199);width:33.6%">
          <div class="col-xs-12" style="padding: 0;height: 5%;text-align: center;">
            <label style="margin-top:1%">PROPOSAL AND PURCHASE ORDER DETAILS</label>
          </div>
          <div *ngIf="AllProposals.length>0" class="col-xs-12" style="padding: 1%; height: 70%;">

            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Contact:</b>
              </div>
              <b class="col-xs-8" style=" padding: 0px; color: #00b3f7d9">{{Proposal.ContactName | uppercase}}</b>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Date:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.CreatedDate}}</div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Account:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.AccountName}}</div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Pended:</b>
              </div>
              <div class="col-xs-8" *ngIf="Proposal.isPended==1" style=" padding: 0px;">Pended</div>
              <div class="col-xs-8" *ngIf="Proposal.isPended!=1" style=" padding: 0px;"></div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Date Closed:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.estimatedclosedate}}</div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Contact:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.ContactName}}</div>
            </div>

            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Type:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.itemName}}</div>

            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Description:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.ProposalDescription}}</div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Lead:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.leadfrom}}</div>

            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Description:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.LeadDescription}}</div>

            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Price:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.TotalPrice}}</div>

            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Monthly:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.MonthlyTotalPrice}}</div>

            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>ECD:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.estimatedclosedate | date: 'MM/dd/yyyy'}}</div>

            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Created by:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.owner}}</div>

            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;">
                <b>Status:</b>
              </div>
              <div class="col-xs-8" style=" padding: 0px;">{{Proposal.statusName}}</div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 5.8%; font-size: 11px">
              <div class="col-xs-4" style="padding: 0px;"></div>
              <div class="col-xs-8" style="padding: 0px; color: #00b3f7d9; font-size: 14px; text-decoration: underline;" (click)="openOpportunity();">Go To Opportunity</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <app-dialog [(visible)]="ShowPdfDiv" *ngIf="ShowPdfDiv" id="PDFDiv" [CloseButtonColor]="'white'">
    <div id="pdfDiv" class="col-xs-12">

    </div>
  </app-dialog>

  <app-dialog [(visible)]="notesshowDialog" *ngIf="notesshowDialog" (visibleChange)="onCloseDialogEventReceived($event)">
    <app-notes style="height:100%" [title]="'Contact Notes Info'"
               [source]="'contact'"
               [account]="account"
               [gridOptionsnotes]="gridOptionsnotes"
               [allnotes]="allnotes"
               [columnDefsnotes]="columnDefsnotes"
               [tabName]="'Account'"
               [contactid]="SelectedContactId"
               [isModal]="isModal"
               [conid]="SelectedContactId"
               (sendMsgEvent)="CloseNotesDiv($event)">

    </app-notes>

  </app-dialog>
  <app-dialog [(visible)]="showAddEditAccount" *ngIf="showAddEditAccount" id="addEditAccount" [closable]="(action!='View')? false:true" [OutSideClick]="(action!='View')? false:true">
    <app-add-edit-account *ngIf="action!='View'" [accountID]="this.account.id" [type]="'Account'" (sendMsgEvent)="ReceiveEvent($event)"></app-add-edit-account>
    <accountorcontactview *ngIf="action=='View'" [accountId]="this.account.id" [type]="'Account'"></accountorcontactview>
  </app-dialog>

  <app-dialog [(visible)]="showAddEditContact" *ngIf="showAddEditContact" id="addEditContact" [closable]="false" [OutSideClick]="false">
    <editcontact [contactid]="0" [accountId]="account.id" [type]="'Contact'" (sendMsgEvent)="ContactReceiveEvent($event)"></editcontact>
  </app-dialog>


  <app-dialog [(visible)]="MinmumOneItemAlert" *ngIf="MinmumOneItemAlert" id="MinmumOneItemAlert" [closable]="false" [OutSideClick]="false">
    <app-delete-popup [title]="'Preview Proposal | PO'" [description]="'Minimum of(1) item required'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="false" (sendMsgEvent)="MinmumOneItemAlert = false;">
    </app-delete-popup>

  </app-dialog>

  <app-dialog [(visible)]="MandatoryFieldAlert" *ngIf="MandatoryFieldAlert" id="MandatoryFieldAlert" [closable]="false">
    <app-delete-popup [title]="'Preview Proposal | PO'" [description]="'Please fill mandatory fields'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="false" (sendMsgEvent)="MandatoryFieldAlert = false;">
    </app-delete-popup>

  </app-dialog>

  <app-dialog [(visible)]="PendProposal" *ngIf="PendProposal" id="PendProposal" [closable]="false">
    <app-delete-popup [title]="'Pend Proposal'" [description]="'Pend Proposal to complete later?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="pendProposal()">
    </app-delete-popup>

  </app-dialog>
  <app-dialog [(visible)]="ClosePendProposalDialog" *ngIf="ClosePendProposalDialog" id="ClosePendProposalDialog" [closable]="false">
    <app-delete-popup [title]="'PENDED PROPOSAL'" [description]="'Save Changes to Proposal?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ClosependProposal()">
    </app-delete-popup>

  </app-dialog>

  <app-dialog [(visible)]="PendedProposalsDialog" id="PendedProposalsDialog" [closable]="false" *ngIf="PendedProposalsDialog">
    <div style="background-color:white;height:100%">
      <div class="col-xs-12" style="height: 8%;padding: 0px;border-bottom: 1px solid white; text-align: center; padding: 2%;">

        <label>Select Pended Proposals</label>
        <span> <img src="../../../assets/img/White x.png" style="float: right;height:25px" (click)="PendedProposalsDialog = false" /></span>
      </div>

      <div class="col-xs-12" style="padding:0;height:72%;">
        <div class="col-xs-12" style="padding: 0; height: 5%; font-size: 13px; font-weight: 800;">
          <div class="col-xs-1" style="width: 5%; "></div>
          <div class="col-xs-3" style="width: 20%;">Date</div>
          <div class="col-xs-4" style="width: 40%;padding:0;">Proposal Type</div>
          <div class="col-xs-4" style="width: 30%;padding:0;">Proposal Description</div>
          <div class="col-xs-4" style="width: 5%;padding:0;"></div>
        </div>
        <div class="col-xs-12" style="padding:0;height:5%;font-size:12px;" *ngFor="let item of PendedProposals">
          <div class="col-xs-1" style="width: 5%; padding: 0; text-align: center; ">
            <input type="checkbox" id="{{item.ProposalID}}" (change)="SelectedProposal(item)" />
          </div>
          <div class="col-xs-3" style="width: 20%;">{{item.CreatedDate | date: 'MM-dd-yyyy' }}</div>
          <div class="col-xs-4" style="width: 40%;padding:0;">{{item.opportunities.itemName}}</div>
          <div class="col-xs-4" style="width: 30%;padding:0;">{{item.opportunities.ProposalDescription}}</div>
          <div class="col-xs-4" style="width: 5%;padding:0;">
            <i class="fa fa-trash" aria-hidden="true" (click)="DeletePendProposal(item)"></i>
          </div>
        </div>
      </div>
    </div>
  </app-dialog>
  <app-dialog [(visible)]="CancelProposal" *ngIf="CancelProposal" id="CancelProposal" [closable]="false">
    <app-delete-popup [title]="'Proposal Alert'" [description]="'Delete this Proposal ?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="DeleteProposal()">
    </app-delete-popup>

  </app-dialog>
  <app-dialog [(visible)]="CloseAddProposal" id="CloseAddProposal" *ngIf="CloseAddProposal" [closable]="false">
    <app-delete-popup [title]="'CREATE PROPOSAL'" [description]="'Proposal will be Discarded'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="cancelAddProposal()">
    </app-delete-popup>

  </app-dialog>
</div>
