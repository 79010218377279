import { Component, OnInit, ViewContainerRef, ViewEncapsulation, ElementRef, Renderer2, ViewChild, Optional, Input } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { DatePipe } from '@angular/common';
import { CustomerService } from '../../services/customer.service';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import { Customers } from '../../models/customers';
import { Accounts } from '../../models/accounts';
import { Notes } from '../../models/notes';
import { Contacts } from '../../models/contacts';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { NotesrendererComponent } from '../notesrenderer/notesrenderer.component';
import { ActivitiesNotesInfoComponent } from '../activities-notes-info/activities-notes-info.component';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { SalesProcessHistory } from '../../models/sales-process-history';
import { Column } from 'ag-grid';
import { TaskHistory } from '../../models/task-history';
import { Opportunities } from '../../models/opportunities';
import { ToastrService } from 'ngx-toastr';;
import { IMyDpOptions } from 'mydatepicker';
import { CalllisthistoryComponent } from "../calllisthistory/calllisthistory.component";
enum selectedTab { UserActivityDetails, UserActivitySummary };
import * as moment from 'moment'
import { AuditLogComponent } from '../audit-log/audit-log.component';
import { Http } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { PageComponent } from '../page/page.component';
import { ApplicationComponent } from '../application.component';
import { NotesComponent } from '../notes/notes.component';
import { AccountsService } from 'src/app/services/accounts.service';
import { ContactsdashboardComponent } from '../../application/contacts/contactsdashboard/contactsdashboard.component';
import { AccountContactComponent } from '../account-contact/account-contact.component';
import { AccountSummaryCheckBox } from 'src/app/models/ActivitySummaryCheckbox';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { ScheduletaskComponent } from '../scheduletask/scheduletask.component';
import { LeadsComponent } from '../leads/leads.component';
import { OpportunitiesComponent } from '../opportunities/opportunities.component';
import { OtherStandardReportsComponent } from './other-standard-reports/other-standard-reports.component';
import { AuditLogModel } from '../../models/auditlog';
import { UserdataheaderComponent } from '../userdataheader/userdataheader.component';
declare var require: any;
declare var $: any;
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  resizeheight = (window.innerHeight / 1.542) + 'px';
  datePipe: DatePipe = new DatePipe("en-US");
  itemResource: any;
  items = [];
  itemCount = 0;
  params: any;
  notesitemResource: any;
  notesitems = [];
  notesitemCount = 0;
  notesparams: any;
  showDialog: boolean = false;
  usersummaryList: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSetting = {};
  finalitems = "";
  selectedtab = "";
  currentuser: any;
  type: string = "";
  selectedtype: string = "Access(Login/Logout)";
  notedescription: string; callhistorystatus: string;
  notes: Notes = new Notes();
  taskhistoryid: number;
  contact: Contacts = new Contacts();
  account: Accounts = new Accounts();
  customer: Customers = new Customers();
  accountdiv: boolean = true;
  customerdiv: boolean = true;
  contactorcustomerid: number = 0;
  alltaskhistory: TaskHistory[];
  alltaskhistoryOriginal: TaskHistory[];
  Selectedalltaskhistory: TaskHistory[];
  selectedreporttype: string = "Access(Login/Logout)";
  ReportType: string;
  Category: string;
  selectedFilterType: string = "Display All";
  isModal: boolean = true;
  isOpenNewNotes: boolean = false;
  contactinformationDiv: boolean = false;
  public gridOptions: GridOptions;
  public gridOptionsnotes: GridOptions;
  public gridselectedOptions: GridOptions;
  public NewAccountgridOptions: GridOptions;
  public OpportunitiesgridOptions: GridOptions;
  public NewContactgridOptions: GridOptions;
  public rowData: any[];
  public columnDefs: any[];
  public selectedcolumnDefs: any[];
  public columnDefsnotes: any[];
  public AccountcolumnDefs: any[];
  public OpportunitiescolumnDefs: any[];
  public NewContactcolumnDefs: any[];
  pageSize = "1000";
  DisplayrowsList: Dropdowntype[] = [];
  UserIds: string = "";
  columnSelections: string;
  @ViewChild(AuditLogComponent) Auditlog;
  public sideBar;
  public context;
  public frameworkComponents;
  public AccountframeworkComponents;
  selectedTab: selectedTab;
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  allnotes: Notes[];
  conid: number; contactid: number;
  showSelectedtaskHistory: boolean = false;
  showNewAccountTable: boolean = false;
  showNewContactAddedTable: boolean = false;
  showSaleProcessHistoryTable: boolean = false;
  showOpprtunitiesHistoryTable: boolean = false;
  taskDialog: boolean = false;
  isTaskHistoryGridInitiatized: boolean = false;
  //isMultipleContacts: boolean = false;
  gridCount: number = 0; selectedsummary: string = "";
  @ViewChild(ActivitiesNotesInfoComponent) actcomp;
  @ViewChild(UserdataheaderComponent) UDHComp;
  public month = ((new Date().getMonth() + 1) < 10) ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = (new Date().getFullYear() - 1) + '-' + this.month + '-01'; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = 'Start:' + this.month + '-01-' + (new Date().getFullYear() - 1).toString();
  public startDate: any = new Date();
  public EndDate: any = new Date();
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = new Date().getFullYear() + '-' + this.month + '-' + (new Date().getDate()); //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = 'End:' + this.month + '-' + (new Date().getDate()) + '-' + new Date().getFullYear().toString();
  ReportTypeList: Dropdowntype[] = [];
  MultipleContacts: Dropdowntype[] = [];
  activitieslist: Array<TaskHistory> = [];
  noteslist: Array<Notes> = [];
  allCustomers: Accounts[];
  allLeads: SalesProcessHistory[];
  Allopportunities: Opportunities[];

  allContacts: Contacts[];
  allNewContact: Contacts[];
  public LeadgridOptions: GridOptions;
  public LeadcolumnDefs: any[];
  typ: any; contactids = []; accountids = [];
  @ViewChild(CalllisthistoryComponent) callhistorycmp;
  @ViewChild(NotesComponent) notecomp;
  @ViewChild(OtherStandardReportsComponent) OtherReports;
  CallHistoryDialog = false; NotesDec = false;
  timezonevalue: string;
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  divHeight = screen.height * 0.63;
  Ids = [];
  summaryDivHeight1 = screen.height * 0.678888;
  ContentDiv = screen.height * 0.561111;
  gridhgt12 = screen.height * 0.271;
  gridhgt13 = screen.height * 0.271;
  gridhgt15 = screen.height * 0.271;
  gridhgt16 = screen.height * 0.271;

  currentPage: number = 1;
  startrow: number = 1;
  noofRows: number = 1;
  totalnumberofrows: number = 1;
  totalnumberofPages: number = 1;
  today = Date.now();
  contactheight = screen.height * 0.34;
  calanderonoff: boolean = true;
  //Newly Added becoz while Upgrading
  userInfo: string = "";
  isShowDetailsChecked: boolean = false;
  @ViewChild(ContactsdashboardComponent) contactComp;
  @ViewChild(AccountContactComponent) accComp;
  @ViewChild(ScheduletaskComponent) scheduleComp;
  @ViewChild(LeadsComponent) leadsComponent;
  @ViewChild(OpportunitiesComponent) optComp;
  public AccActChkBox = new AccountSummaryCheckBox();
  private gridApi;
  ShowUserActivityDetail: boolean = true;
  ShowUserActivitySummary: boolean = false;
  UserActivitySummaryCheckedDiv: boolean = false;
  OtherStandardReports: boolean = false;
  TaskListInfo: boolean = false;
  id: number; taskid: number;
  headerlabel = "";
  GridCount = "";
  leadid: number;
  opportunityids = [];
  ShowActivityDetailsTask: boolean = false;
  constructor(public applicationComponent: ApplicationComponent, private paginationcop: PageComponent, private leadservice: LeadsService, private http: Http, private customerService: CustomerService, private opprtunityservice: OpprtunitiesService, private viewContainerRef: ViewContainerRef, private toastr: ToastrService, private route: ActivatedRoute, private router: Router,  private accountsService: AccountsService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {
      this.applicationComponent.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
      ];
      localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
      $("#userids").val(this.currentuser.id);
      this.applicationComponent.ExpandSelectedMenu('reports');
    }
    // this.toastr.setRootViewContainerRef(viewContainerRef);
  }
  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let searchval = this.applicationComponent.searchstring;
    let selfobj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "" && columnSelections != undefined) {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({
            field: 'owner', headerName: 'User Name', hide: true, suppressSorting: true, cellStyle: function (params) {
              if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
            }, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefs.push({
            field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "DateCreated") {
          this.columnDefs.push({
            field: 'DateCreated', headerName: 'Date Created', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) , cellStyle: function (params) {
              if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "DateSched") {
          this.columnDefs.push({
            field: 'DateSched', headerName: 'Date Scheduled', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) , cellStyle: function (params) {
              if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "DateComp") {
          this.columnDefs.push({
            field: 'DateComp', headerName: 'Date Comp', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) ,cellStyle: function (params) {
              if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            }, cellRenderer: (params) => {
              if (params.data.status == 'Completed') {
                return moment(params.value).format('MM-DD-YYYY');
              }
            }
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "lastName") {
          this.columnDefs.push({
            field: 'lastName', headerName: 'Last Name', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) , cellStyle: function (params) {
              if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              //if (searchval != null) {
              if (params.data.SearchVal != null) {
                let self = this;
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  //var search = searchval.replace(/["]/g, "")
                  var search = params.data.SearchVal;
                  if (x[i] == search) {
                    st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "firstName") {
          this.columnDefs.push({
            field: 'firstName', headerName: 'First Name', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) , cellStyle: function (params) {
              if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              //if (searchval != null) {
              if (params.data.SearchVal != null) {
                let self = this;
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  //var search = searchval.replace(/["]/g, "")
                  var search = params.data.SearchVal
                  if (x[i] == search) {
                    st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            cellStyle: function (params) {
              if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            },
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        {
          field: 'DateCreated', headerName: 'Date Created', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'DateSched', headerName: 'Date Scheduled', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'DateComp', headerName: 'Date Comp', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }, cellRenderer: (params) => {
            if (params.data.status == 'Completed') {
              if (params.value == null || params.value == '') {
                return "";
              } else {
                return moment(params.value).format('MM-DD-YYYY');
              }

            }
          }
        },
        {
          field: 'TimeSched', headerName: 'Time Sched', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        { field: 'status', headerName: 'Status', hide: false, cellStyle: function (params) { if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; } } },
        {
          field: 'taskcategory', headerName: 'Task', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        {
          field: 'TypeofTask', headerName: 'Type Of Task', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        {
          field: 'firstName', headerName: 'First Name', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: function (params) {
            //if (searchval != null) {
            if (params.data.SearchVal != null) {
              let self = this;
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                //var search = searchval.replace(/["]/g, "")
                var search = params.data.SearchVal
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'lastName', headerName: 'Last Name', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: function (params) {
            //if (searchval != null) {
            if (params.data.SearchVal != null) {
              let self = this;
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                //var search = searchval.replace(/["]/g, "")
                var search = params.data.SearchVal;
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        //{
        //  field: 'ContactName', headerName: 'Contact Name', hide: false, cellStyle: function (params) {
        //    if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
        //      return { color: 'red' }
        //    }
        //  } },
        {
          field: 'contacttypeName', headerName: 'Contact Type', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        { field: 'AccountName', headerName: 'Account Name', hide: true },  //true starts here
        {
          field: 'time', headerName: 'Start Time', hide: true, cellStyle: function (params) {
            if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        {
          field: 'totime', headerName: 'End Time', hide: true, cellStyle: function (params) {
            if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        {
          field: 'owner', headerName: 'User Name', hide: true, cellStyle: function (params) {
            if ((params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        {
          field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, cellStyle: function (params) {
            if (params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) {
              return { color: '#FF0000' }
            }
          }
        },
      ];

    }
  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  autoSizeAll() {

  }
  
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 8;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;
    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  initializeAgGrid() {

    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    }; this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    //let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 8).then((config) => {
      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.gridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }

      this.columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(this.columnSelections);
      //this.startDateVal = (config != null && config.startdate != null) ? config.startdate : (new Date().getFullYear() - 1) + '-01-01';
      //this.startDatePlaceHolder = (config != null && config.startdate != null) ? 'Start:' + moment(config.startdate).format("MM-DD-YYYY") : 'Start: 01-01-' + (new Date().getFullYear() - 1).toString();
      //this.endDateVal = (config != null && config.enddate != null) ? config.enddate : new Date().getFullYear() + '-12-31';
      //this.endDatePlaceHolder = (config != null && config.enddate != null) ? 'End:' + moment(config.enddate).format("MM-DD-YYYY") : 'End: 12-31-' + (new Date().getFullYear() - 1).toString();
      this.UpdateSearchColumns(this.columnSelections);
    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };
  }

  UpdateSearchColumns(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[] = columnSelections.split(',');

    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');
      if (fields[2] == 'true' && (fields[1] != '#' && fields[0] != 'notes' && fields[0] != 'actions' && fields[0] != 'callliststatus')) {
        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }

    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];
    if (retrievedObject != null) {
      let obj: any[] = JSON.parse(retrievedObject);
      obj.forEach(element => {
        if (element.subtab == 'StandardReports') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        } else {
          this.applicationComponent.SearchSelectedItemsLengthChanged = false;
        }


      });

    }
    if (tempTableColumns && tempTableColumns.length > 0) {
      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })

    }
    else {
      this.accountsService.GetDefaultColumns('OpenTaskHistory', this.currentuser.id).then((config) => {

        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.applicationComponent.SearchSelectedItems = [
            { "id": 'firstName', "itemName": 'First Name' },
            { "id": 'lastName', "itemName": 'Last Name' }
          ];
        }
      });
      //this.applicationComponent.showSearchTabsSubTabs = false;
    }
  }
  rowClickTaAgGrid(rowEvent) {
    this.conid = rowEvent.data.contactid;
    this.typ = rowEvent.data.type;
    this.actcomp.GetContactNotesandActivities(rowEvent.data.contactid);
    if (rowEvent.node.selected) {
      if (rowEvent.data.type == 'Contacts') {
        this.actcomp.GetContactNotesandActivities(rowEvent.data.contactid);
      } else {
        //this.contact = null;
        this.actcomp.GetCustomerNotesandActivities(rowEvent.data.contactid);
      }
    } else {
      this.actcomp.GetContactNotesandActivities(0);
      this.contact = null;
      this.account = null;
    }
  }

  TaskhistoryrowClickAgGrid(event) {
     
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        var data = event.api.getSelectedNodes()[0].data;
        //this.accComp.showSccountInfo = true;
        //this.accComp.AccountData('show' + data.accountid);
        this.TaskListInfo = true;
        this.id = data.contactid;
        this.taskid = data.id;
        this.type = data.type;


        let objSelf = this;
        $(setTimeout(function () {
          objSelf.scheduleComp.initData(data.contactid, data.type, data.id);
        }, 500));
        
      } 
    } else {
      //this.accComp.showSccountInfo = false;
    }

  }

  TaskhistorySelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        var data = event.api.getSelectedNodes()[0].data;
        this.ShowActivityDetailsTask = true;
        this.id = data.contactid;
        this.taskid = data.id;
        this.type = data.type;


        let objSelf = this;
        $(setTimeout(function () {
          objSelf.scheduleComp.initData(data.contactid, data.type, data.id);
        }, 500));

      }
    } else {
      //this.accComp.showSccountInfo = false;
    }

  }

    //if (rowEvent.node.selected) {
    //  if (rowEvent.data.type == 'Contacts') {

    //    this.actcomp.GetContactNotesandActivities(rowEvent.data.contactid);
    //    this.contactids.push(rowEvent.data.contactid)
    //    this.contactids = this.RemoveDuplicateItemsFromArr(this.contactids);
    //    $('#emailIds').val((this.contactids).toString());
    //    $('#Ids').val($('#emailIds').val());
    //  }
    //  else {
    //    this.accountids.push(rowEvent.data.contactid)
    //    this.accountids = this.RemoveDuplicateItemsFromArr(this.accountids);
    //    $('#accids').val((this.accountids).toString());
    //    this.accountids.push(rowEvent.data.contactid);
    //    this.actcomp.GetCustomerNotesandActivities(rowEvent.data.contactid);
    //  }
    //}
    //else {

    //  var rowdata = rowEvent.data;
    //  if (rowEvent.data.type == 'Contacts') {
    //    if ((this.contactids).indexOf(rowdata.contactid) > -1) {
    //      this.contactids = this.RemoveDuplicateItemsFromArr(this.contactids);
    //      var index = (this.contactids).indexOf(rowdata.contactid);
    //      (this.contactids).splice(index, 1);
    //      var string = (this.contactids).toString();
    //      $('#emailIds').val(string);
    //    }
    //  }
    //  else {
    //    if ((this.accountids).indexOf(rowdata.contactid) > -1) {
    //      this.accountids = this.RemoveDuplicateItemsFromArr(this.accountids);
    //      var index = (this.accountids).indexOf(rowdata.contactid);
    //      (this.accountids).splice(index, 1);
    //      var string = (this.accountids).toString();
    //      $('#accids').val(string);
    //    }
    //  }
    //}
 // }

  RemoveDuplicateItemsFromArr(arr: any[]) {
    var uniqueIDs = [];
    $.each(arr, function (i, el) {
      if ($.inArray(el, uniqueIDs) === -1) uniqueIDs.push(el);
    });

    return uniqueIDs;
  }


  OpportunitiesrowClickAgGrid(event) {

    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (!this.contactinformationDiv) { this.contactinformationDiv = true; }
      if (event.api.getSelectedNodes()[0].data) {
         
        var data = event.api.getSelectedNodes()[0].data;
        this.TaskListInfo = true;
        this.opportunityids = data.id;
        this.contactids = data.accountorcontactid;
        this.typ = data.type;
        const selfobj = this;
        $(setTimeout(function () {

          selfobj.optComp.DisplayOpportunities(data);
        }, 200));




        //if (data.type == 'Contacts') {
        // // this.actcomp.GetContactNotesandActivities(event.data.accountorcontactid);
        //  this.contactids.push(data.accountorcontactid)
        //  this.contactids = this.RemoveDuplicateItemsFromArr(this.contactids);
        //  $('#emailIds').val((this.contactids).toString());
        //  //this.actcomp.GetContactNotesandActivities(event.data.accountorcontactid);
        //} else {
        //  this.accountids.push(data.accountorcontactid)
        //  this.accountids = this.RemoveDuplicateItemsFromArr(this.accountids);
        //  $('#accids').val((this.accountids).toString());
        //  this.accountids.push(data.accountorcontactid)
        //  //this.actcomp.GetCustomerNotesandActivities(event.data.accountorcontactid);
        //}
        //this.accComp.showSccountInfo = true;
        //this.accComp.AccountData('show' + data.accountid);
       
      }
    }
    else {
      this.accComp.showSccountInfo = false;
      var rowdata = event.data;

      if (event.data.type == 'Contacts') {
        if ((this.contactids).indexOf(rowdata.accountorcontactid) > -1) {
          this.contactids = this.RemoveDuplicateItemsFromArr(this.contactids);
          var index = (this.contactids).indexOf(rowdata.accountorcontactid);
          (this.contactids).splice(index, 1);
          var string = (this.contactids).toString();
          $('#emailIds').val(string);
        }
      }
      else {
        if ((this.accountids).indexOf(rowdata.accountorcontactid) > -1) {
          this.accountids = this.RemoveDuplicateItemsFromArr(this.accountids);
          var index = (this.accountids).indexOf(rowdata.accountorcontactid);
          (this.accountids).splice(index, 1);
          var string = (this.accountids).toString();
          $('#accids').val(string);
        }
        //let selectedRows = this.OpportunitiesgridOptions.api.getSelectedRows();
        //if (selectedRows.length == 0)
         // this.actcomp.GetContactNotesandActivities(0);
      }
     
    }
  }
  AccountrowClickAgGrid(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        
        var data = event.api.getSelectedNodes()[0].data;
        this.accountids=data.id
        //this.accountids = this.RemoveDuplicateItemsFromArr(this.accountids);
        $('#accids').val((this.accountids).toString());
        //this.accountids.push(data.id);
        this.TaskListInfo = true;
        const selfobj = this;
        $(setTimeout(function () {
          
          selfobj.accComp.showSccountInfo = true;
          selfobj.accComp.AccountData('show' + data.id);
        }, 200));

        
      }
    }
    else {
      this.accComp.showSccountInfo = false;
      let selectedRows = this.NewAccountgridOptions.api.getSelectedRows();     
      var data = event.api.getSelectedNodes()[0].data;
      if ((this.accountids).indexOf(data.id) > -1) {
        this.accountids = this.RemoveDuplicateItemsFromArr(this.accountids);
        var index = (this.accountids).indexOf(data.id);
        (this.accountids).splice(index, 1);
        var string = (this.accountids).toString();
        $('#accids').val(string);
      }
    }

  }
  NewContactrowClickAgGrid(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        var data = event.api.getSelectedNodes()[0].data;
        this.contactids = data.id;
        //this.contactids = this.RemoveDuplicateItemsFromArr(this.contactids);
        $('#emailIds').val((this.contactids).toString());
        this.TaskListInfo = true;
        const selfobj = this;
        $(setTimeout(function () {
          selfobj.contactComp.showContactInfo = true;
          selfobj.contactComp.GetContactInfo(data.id);
        }, 200));
      }
    } else {
      this.contactComp.showContactInfo = false;
    }
  }
  LeadrowClickAgGrid(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (!this.contactinformationDiv) { this.contactinformationDiv = true;}
      if (event.api.getSelectedNodes()[0].data) {        
        //this.contact = event.api.getSelectedNodes()[0].data;
        var data = event.api.getSelectedNodes()[0].data;
        this.leadid = data.id;
        this.contactids = data.contactid;
        //this.contactids = this.RemoveDuplicateItemsFromArr(this.contactids);
        $('#emailIds').val((this.contactids).toString());
        this.TaskListInfo = true;
        const selfobj = this;
        $(setTimeout(function () {
          selfobj.leadsComponent.HideAdnShow(data.contactid);
        }, 200));
      }
    }
    else {
      this.contact = null;
      this.account = null;
      this.contactComp.showContactInfo = false;
      if ((this.contactids).indexOf(event.data.id) > -1) {
        this.contactids = this.RemoveDuplicateItemsFromArr(this.contactids);
        var index = (this.contactids).indexOf(event.data.id);
        (this.contactids).splice(index, 1);
        var string = (this.contactids).toString();
        $('#emailIds').val(string);
      }
    }
  }
  openNotesDialogAg(rowdata) {
    
    this.conid = rowdata.contactid;
    this.showDialog = !this.showDialog;
      this.NotesDialogCss();
    
  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }
  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.createColumnDefsnotes("");
    this.gridOptionsnotes.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  createColumnDefsnotes(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }
        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellStyle: { 'text-align': 'left' }, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];
    //this.columnDefsnotes = [
    //  {
    //    field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
    //      if (params.value == 'Contact') {
    //        return '<i style="font-weight: 700;font-size: 18px;" class="fa fa-laptop" aria - hidden="true" > </i>';
    //      }
    //      else if (params.value != null && params.value.startsWith("Phone")) {
    //        return '<i style="font-weight: 700;font-size: 18px;" class="fa fa-phone" aria - hidden="true" > </i>';
    //      }
    //      else if (params.value != null && params.value.startsWith("To Do")) {
    //        return 'To-Do';
    //      }
    //      else if (params.value != null && params.value.startsWith("Meeting")) {
    //        return '<i style="font-weight: 700;font-size: 18px;" class="fa fa-calendar" aria - hidden="true" > </i>';
    //      } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
    //        return '<i style="font-weight: 700;font-size: 18px;" class="fa fa-server" aria - hidden="true" > </i>';
    //      }
    //      else {
    //        return '<h5 style="font-weight: 800;font-size: 15px;"> $$ </h5>'
    //      }
    //    }
    //  },
    //  {
    //    field: 'notedescription', headerName: 'Notes', hide: false, cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellRenderer(params) {
    //      var eDiv = document.createElement('div');
    //      eDiv.innerHTML = params.value;
    //      eDiv.addEventListener('click', function () {
    //        //selfObj.notes.notedescription = params.value;
    //        //selfObj.NotesDec = !selfObj.NotesDec;
    //        selfObj.openDisplaynotes(params);
    //      });
    //      return eDiv;
    //    } },
    //  { field: 'created', headerName: 'Date Added', hide: false },
    //  { field: 'status', headerName: 'Completed Y/N', hide: false, cellStyle: function (params) { if (params.value == 'Open') { return { color: 'red' }; } } },
    //  { field: 'user', headerName: 'User Added', hide: false },
    //  { field: 'type', headerName: 'Type', hide: false }

    //];
  }
  onAgGridReadynotes(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllnotes();
      $(setTimeout(function () {
        selfObj.autoSizeAllnotes();
      }, 400));
    }, 400));
  }
  autoSizeAllnotes() {
    var allColumnIds = [];
    if (this.gridOptionsnotes && this.gridOptionsnotes.columnApi) {
      var allCols = this.gridOptionsnotes.columnApi.getAllColumns();
      if (allCols != null) {
        allCols.forEach(function (column) {
          allColumnIds.push(column.getColId());
        });
      }
      this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds);
      // this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds, "autosizeColumns");
    }

  }

  ngOnInit() {
    
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All Users',
      unSelectAllText: 'UnSelect All Users',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
      this.applicationComponent.selectedbuttonText = "Reports";
    }
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1, 0, 0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.dropdownList = Owner;
    });
    this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
      this.DisplayrowsList = Rows;
    });
    this.customerService.GetSpecialityList("ReportType", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.ReportTypeList = accounttype;
    });
    this.customerService.GetSpecialityList("ContactOption", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      }); this.MultipleContacts = accounttype;
    });
    if (this.applicationComponent.selectedusers != '' && this.applicationComponent.selectedusers != null && this.applicationComponent.selectedusers != undefined) {
      this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
        Owner.forEach(Owner => {

        });
        this.selectedItems = Owner;
      });
      this.Generate($("#userids").val());
    }
    else {
      this.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username }
      ];
      this.finalitems += this.currentuser.id + ',';
      //$("#userids").val(this.currentuser.id);
      this.Generate(this.currentuser.id);
    }

    
    this.selectedreporttype == this.selectedtype;

    this.route.params.subscribe(params => {
      if (params['type'] != null && params['type'] != undefined) {
        this.selectedtype = params['type'];
        var obj: AuditLogModel = { id: 0, type: 'Standard Reports - ' + this.selectedreporttype, action: 'Standard Reports - ' + this.selectedreporttype, AccountOrContactId: 0, AccountOrContact: this.selectedreporttype, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Standard Reports', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
        this.applicationComponent.InsertAuditHistory(obj);
      }
      
      this.appendLabel(this.selectedtype);
      
        this.ReporttypeChange(this.selectedtype);
      this.selectedreporttype == this.selectedtype;
    });

  }
  myFunction() {
    document.getElementById("myDropdown").classList.toggle("show");
  }
  
  onItemSelect(item: any) {
    var ids = "";
    ids += item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());

  }
  OnItemDeSelect(item: any) {

    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.Generate($("#userids").val());
    } else {
      this.alltaskhistory = [];
      this.gridCount = 0;
      this.usersummaryList = [];
      $("#userids").val('');
      this.Auditlog.clearAudit();
    }
  }
  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.alltaskhistory = [];
    this.usersummaryList = [];
    $("#userids").val('');
    this.applicationComponent.selectedusers = null;
    this.gridCount = 0;
    this.Auditlog.clearAudit();
  }

  OpenNotes(rowEvent) {
    this.taskhistoryid = rowEvent.id;
    if (rowEvent.type == 'Contacts') {
      this.opprtunityservice.GetContactById(rowEvent.contactid).then(result => {
        this.opprtunityservice.GetByAccountId(result.accountid).then(result => {
          this.account = result;
        });
        var middleName = '';
        var title = '';
        if (result.middlename != null) {
          middleName = result.middlename;
        }
        if (result.title != null) {
          title = ',' + result.title;
        }
        this.contact.firstname = result.firstname + ' ' + middleName + ' ' + result.lastname + title;
        this.accountdiv = false;
        this.customerdiv = true;
      });
    }
    else if (rowEvent.type == 'Customer') {
      this.customerService.GetByproviderId(rowEvent.contactid).then(result => {
        this.customer = result;
        var middleName = '';
        var title = '';
        if (result.middle != null) {
          middleName = result.middle;
        }
        if (result.title != null) {
          title = ',' + result.title;
        }
        this.contact.firstname = result.first + ' ' + middleName + ' ' + result.last + title;
        this.accountdiv = true;
        this.customerdiv = false;
      });
    }
    //this.getTaskHistoryNotes(rowEvent.id, 'TaskHistory');
    this.showDialog = !this.showDialog;
    this.NotesDialogCss();
    $(setTimeout(function () {
      document.getElementById('notesListTabsId').style.display = 'none';
    }, 400));
    $('.dialog').width('1000px');
  }



  public loadGetAllUserActivitiesList(userlist, startdate, enddate, selectedType, startrow, endrow) {
    
    this.alltaskhistory = [];
    if (this.currentuser.UserPrivilegeList != undefined) {
      var button = document.getElementById('Reports');
      var buttonname = (button == null) ? 'Reports' : (button.title);
      var thisTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == buttonname || p.resource.toLowerCase() == buttonname)[0];
      if (thisTab != undefined && thisTab.canview == false) {
        this.toastr.warning("You Do Not have Access to " + buttonname + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
        return false;
      }
    }

    this.gridCount = 0;
    this.leadservice.GetAllUserActivities(this.currentuser.id, userlist, startdate, enddate, selectedType, startrow, endrow).then((taskhistory) => {
      taskhistory.forEach(taskhistory => {

        taskhistory.DateCreated = this.datePipe.transform(taskhistory.DateCreated, "yyyy-MM-dd");
        taskhistory.DateSched = this.datePipe.transform(taskhistory.DateSched, "yyyy-MM-dd");
        taskhistory.DateComp = (taskhistory.DateComp == null) ? "" : this.datePipe.transform(taskhistory.DateComp, "yyyy-MM-dd");
      });
      this.alltaskhistoryOriginal = taskhistory;
      this.alltaskhistory = taskhistory;
      if (this.alltaskhistory.length > 0) {


        if (this.alltaskhistory.length > 0 && this.alltaskhistory[0].islimitedSearch == true) {
          if (this.alltaskhistory[0].SearchVal) {
            this.applicationComponent.searchCriteria = this.alltaskhistory[0].SearchVal;
            this.applicationComponent.searchstring = this.alltaskhistory[0].SearchVal.slice(0, -3);
            this.applicationComponent.searchCriteriadata = this.applicationComponent.searchstring.split(' / ');
          }
          this.applicationComponent.term = "true";//passing dummy data toshow delete button in search box
          this.applicationComponent.limitedSearch = true;
          $('#searchContact').val('');
        }
        else {
          this.applicationComponent.term = "";
          $('#searchContact').val('');
          this.applicationComponent.searchstring = "";
          this.applicationComponent.limitedSearch = false;
          this.applicationComponent.searchCriteriadata = [];
        }

        if (this.selectedreporttype == "User Activity(Summary)" || this.selectedreporttype == "UserActivitySummary" ) {
          this.selectedcreateColumnDefs(this.columnSelections);
        }
        else if (this.selectedreporttype == "User Activity(Details)" || this.selectedreporttype == "UserActivityDetails") {
          this.createColumnDefs(this.columnSelections);
          
        }
        this.gridCount = this.alltaskhistory.length;

        this.startrow = this.alltaskhistory[0].CurrentPage == 0 ? 1 : this.alltaskhistory[0].CurrentPage;

        this.noofRows = endrow * this.currentPage;
        this.totalnumberofrows = this.alltaskhistory[0].Total;
        this.totalnumberofPages = Math.ceil(this.alltaskhistory[0].Total / endrow);
        let that = this;
        $(setTimeout(function () {
          that.paginationcop.startrow = that.alltaskhistory[0].CurrentPage == 0 ? 1 : that.alltaskhistory[0].CurrentPage;
          that.paginationcop.noofRows = endrow * that.currentPage;
          that.paginationcop.totalnumberofrows = that.alltaskhistory[0].Total;
          that.paginationcop.totalnumberofPages = that.alltaskhistory[0].Total / endrow;
        }, 200));
      } else {
        this.applicationComponent.searchstring = "No Match";
        //this.applicationComponent.searchCriteriadata.push("")
      }

    });

  }

  public loadallnewcontacts(userlist, startdate, enddate) {
    this.gridCount = 0;
    this.opprtunityservice.GetAllContactsBySearch('null', userlist, 'contacts', 0, startdate, enddate).then((contact) => {
      contact.forEach(contact => {
        contact.created = this.datePipe.transform(contact.created, "MM-dd-yyyy");
        contact.speciality = ((contact.speciality == 'null') ? "" : contact.speciality);
      });
      this.allNewContact = contact;
      this.contactorcustomerid = this.allNewContact[0].id;
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.NewContactgridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.allNewContact[0].id) {
            selfObj.NewContactgridOptions.api.selectNode(node, true);
            selfObj.contactorcustomerid = selfObj.allNewContact[0].id;


          }
        });
      }, 500));

      this.gridCount = this.allNewContact.length;
    });
  }
  public loadalluserSummary(userlist, startdate, enddate) {
    userlist = $("#userids").val();
    this.ShowUserActivityDetail = false;
    this.ShowUserActivitySummary = true;
    this.OtherStandardReports = false;
    this.leadservice.GetAllUserActivitiesList(userlist, startdate, enddate).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.usersummaryList = Owner;
    });
    $(".userlist").html('');
    this.showSelectedtaskHistory = false;
    // this.showNewAccountTable = false;
    this.showSaleProcessHistoryTable = false;
    this.showOpprtunitiesHistoryTable = false;
  }

  Generate(val) {
    val = $("#userids").val();
    this.applicationComponent.selectedusers = val;
    if (this.applicationComponent.selectedusers != '' || this.applicationComponent.selectedusers != null) {
      this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
        Owner.forEach(Owner => {
        });
        this.selectedItems = Owner;
      });

    }
    if (this.selectedreporttype == "UserActivitySummary" || this.selectedreporttype == "User Activity(Summary)") {
      this.loadalluserSummary(val, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);
    }
    else if (this.selectedreporttype == "UserActivityDetails" || this.selectedreporttype == "User Activity(Details)") {
      this.initializeAgGrid();
      this.loadGetAllUserActivitiesList(val, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'allactivity', this.currentPage, this.pageSize);
    }
    else if (this.selectedreporttype == "AuditLog") {
      this.UserIds = val;
      this.applicationComponent.selectedusers;
      this.Auditlog.LoadLoginAuditLog('Login', this.UserIds, '1', '200');
    }
    
    else if (this.selectedreporttype == "Access(Login/Logout)") {
      this.ShowUserActivityDetail = false;
      this.ShowUserActivitySummary = false;
      this.OtherStandardReports = true;
      this.selectedreporttype = 'Access(Login/Logout)';
      this.ReportType = 'Access(Login/Logout)';
      this.Category = "";
      this.startDate = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("YYYY-MM-DD");
      this.EndDate = moment(new Date()).format("YYYY-MM-DD");

      let SelfObj = this;
      $(setTimeout(function () {
        SelfObj.OtherReports.getOtherSelectedReportData(SelfObj.ReportType, SelfObj.Category, SelfObj.startDate, SelfObj.EndDate);
      }, 500));
    }

  }

  enterNotes() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'block';
    document.getElementById('saveNotesButtons').style.display = 'block';
    document.getElementById('divTable').style.display = 'none';
  }
  SaveNote() {
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/img, "");
    this.notes.notedescription = this.notes.notedescription.replace(/<[^>]*>/g, '');
    this.notes.type = "Lead";
    this.notes.accountid = this.taskhistoryid;

    this.customerService.SaveNotes(this.notes).then(result => {
      this.getTaskHistoryNotes(this.notes.accountid, 'TaskHistory');
      this.CancelNote();
    });
  }
  getTaskHistoryNotes(id, type) {
    this.customerService.GetAllNotesList(this.currentuser.id, id, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.initializeAgGridNotes();
      this.allnotes = taskhistory;
    });
  }
  CancelNote() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'none';
    document.getElementById('saveNotesButtons').style.display = 'none';
    document.getElementById('divTable').style.display = 'block';
  }
  dateChange(event) {
    this.AssignStartAndEndDate(event);
    let userids = $("#userids").val();
    if (this.selectedreporttype == "User Activity(Details)" || this.selectedreporttype == "UserActivityDetails") {
      this.ShowUserActivityDetail = true;
      this.ShowUserActivitySummary = false;
      this.OtherStandardReports = false;
      this.loadGetAllUserActivitiesList($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'allactivity', this.currentPage, this.pageSize);
    } else if (this.selectedreporttype == "User Activity(Summary)" || this.selectedreporttype == "UserActivitySummary" ) {  //UserActivitySummary
      this.ShowUserActivityDetail = false;
      this.ShowUserActivitySummary = true;
      this.OtherStandardReports = false;

      this.loadalluserSummary($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);
    }
    else {

      this.Auditlog.LoadLoginAuditLog('Login', this.UserIds, '1', '200', (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);

    }
    this.saveConfiguration();
  }
  AssignStartAndEndDate(event) {

    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  ReporttypeChange(reporttype) {
    this.selectedreporttype = reporttype;
    if (this.selectedreporttype == "UserActivityDetails" || this.selectedreporttype == "User Activity(Details)") {
      this.ShowUserActivityDetail = true;
      this.ShowUserActivitySummary = false;
      this.initializeAgGrid();
      this.loadGetAllUserActivitiesList($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'allactivity', this.currentPage, this.pageSize);
      this.customerService.GetSpecialityList("ReportType", this.currentuser.id).then((Rows) => {
        //this.fourthDropdownList = Rows.sort((a, b) => a.itemName > b.itemName ? 1 : -1);
        var value = Rows.filter(x => x.itemName == "User Activity(Details)")[0];
        this.UDHComp.FourthDrpModel = [{ "id": value.id, "itemName": value.itemName }];
      });

    } else if (this.selectedreporttype == "UserActivitySummary" || this.selectedreporttype == "User Activity(Summary)") {
      this.ShowUserActivityDetail = false;
      this.ShowUserActivitySummary = true;
      this.OtherStandardReports = false;
      this.loadalluserSummary($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);
      this.customerService.GetSpecialityList("ReportType", this.currentuser.id).then((Rows) => {
        //this.fourthDropdownList = Rows.sort((a, b) => a.itemName > b.itemName ? 1 : -1);
        var value = Rows.filter(x => x.itemName == "User Activity(Summary)")[0];
        this.UDHComp.FourthDrpModel = [{ "id": value.id, "itemName": value.itemName }];
      });
    } else {

      this.ShowUserActivityDetail = false;
      this.ShowUserActivitySummary = false;
      this.OtherStandardReports = true;
      if (reporttype != 'audit' && reporttype != 'Access(Login/Logout)') {
        this.LoadOtherMenu();
      }
      
      this.UserIds = $("#userids").val();
    }
    this.autoSizeAll();

  }

  CheckboxCheck(isPhoneSched: boolean, isPhoneComplete: boolean, isTodoSched: boolean, istodoComplete: boolean, MeetSched: boolean, MeetComp: boolean, AccAdded: boolean, ConAdded: boolean, LeadAdded: boolean, OpportunityAdded: boolean) {
    this.AccActChkBox.isPhoneScheduled = isPhoneSched;
    this.AccActChkBox.isPhoneCOmpleted = isPhoneComplete;
    this.AccActChkBox.isToDoScheduled = isTodoSched;
    this.AccActChkBox.isTodoCompleted = istodoComplete;
    this.AccActChkBox.isMeetingsScheduled = MeetSched;
    this.AccActChkBox.isMeetingsCompleted = MeetComp;
    this.AccActChkBox.isAccountsAdded = AccAdded;
    this.AccActChkBox.isContactsAdded = ConAdded;
    this.AccActChkBox.isLeadsConverted = LeadAdded;
    this.AccActChkBox.isOpportunitiesAdded = OpportunityAdded;
  }

  checkValue(event: any, id, val, type, text, userid, count) {
    if (!event.target.checked) {
      this.hidediv();
      this.contactinformationDiv = false;
      $('.closeButtonTr').removeClass('GreenBackGround');
     
    }
    else {
      $('.closeButtonTr').removeClass('GreenBackGround');
      $('.closeButtonTr:after').prop('checked', 'false');
      $('#' + id).addClass('GreenBackGround');
      this.SelectedActivity(val, type, text, userid, count);
      this.GridCount = count;
      switch (id) {
        case 'PhoneCallsScheduled': {
          this.headerlabel = "Phone Calls Scheduled";
          this.CheckboxCheck(true, false, false, false, false, false, false, false, false, false);
          break;
        }
        case 'PhoneCallsCompleted': {
          this.headerlabel = "Phone Calls Completed";
          this.CheckboxCheck(false, true, false, false, false, false, false, false, false, false);
          break;
        }
        case 'ToDoScheduled': {
          this.headerlabel = "To Do's Scheduled";
          this.CheckboxCheck(false, false, true, false, false, false, false, false, false, false);
          break;
        }
        case 'TodoCompleted': {
          this.headerlabel = "To Do's Completed";
          this.CheckboxCheck(false, false, false, true, false, false, false, false, false, false);
          break;
        }
        case 'MeetingsScheduled': {
          this.headerlabel = "Meetings Scheduled";
          this.CheckboxCheck(false, false, false, false, true, false, false, false, false, false);
          break;
        }
        case 'MeetingsCompleted': {
          this.headerlabel = "Meetings Completed";
          this.CheckboxCheck(false, false, false, false, false, true, false, false, false, false);
          break;
        }
        case 'AccountsAdded': {
          this.headerlabel = "Accounts Added";
          this.CheckboxCheck(false, false, false, false, false, false, true, false, false, false);
          break;
        }
        case 'ContactsAdded': {
          this.headerlabel = "Contacts Added";
          this.CheckboxCheck(false, false, false, false, false, false, false, true, false, false);
          break
        }
        case 'LeadsConverted': {
          this.headerlabel = "Leads Converted";
          this.CheckboxCheck(false, false, false, false, false, false, false, false, true, false);
          break;
        }
        case 'OpportunitiesAdded': {
          this.headerlabel = "Opportunities Added";
          this.CheckboxCheck(false, false, false, false, false, false, false, false, false, true);
          break;
        }
        default : {
          this.CheckboxCheck(false, false, false, false, false, false, false, false, false, false);
        }
      }
      
    }
  }
  SelectedActivity(val, id, text, userid, count) {
    $("#userids").val(id);
    $('.closeButton').attr('style', 'display:none');
    $('.closeButtonTr').removeAttr('style');
    this.selectedsummary = "";
    this.contactinformationDiv = true;
    this.UserActivitySummaryCheckedDiv = true;
    document.getElementById(id).style.display = 'block';
    if (count != 0) {
      if (val == 'Account') {

        this.showSelectedtaskHistory = false;
        this.showNewAccountTable = true;
        this.showSaleProcessHistoryTable = false;
        this.showOpprtunitiesHistoryTable = false;
        this.showNewContactAddedTable = false;

        this.type = "Account";
        this.selectedsummary = 'NewAccountgridOptions';
        this.SelectedAccount();
      }
      else if (val == 'Lead') {
        this.showSelectedtaskHistory = false;
        this.showNewAccountTable = false;
        this.showSaleProcessHistoryTable = true;
        this.showOpprtunitiesHistoryTable = false;
        this.showNewContactAddedTable = false;

        this.selectedsummary = 'LeadgridOptions';
        this.type = "Contact";
        this.SelectedLead(userid);
      }
      else if (val == 'Opportunity') {

        this.showSelectedtaskHistory = false;
        this.showNewAccountTable = false;
        this.showSaleProcessHistoryTable = false;
        this.showOpprtunitiesHistoryTable = true;
        this.showNewContactAddedTable = false;


        this.type = "Account";
        this.selectedsummary = 'OpportunitiesgridOptions';
        this.SelectedOpportunity();
      }
      else if (val == 'NewContact') {

        this.showSelectedtaskHistory = false;
        this.showNewAccountTable = false;
        this.showSaleProcessHistoryTable = false;
        this.showOpprtunitiesHistoryTable = false;
        this.showNewContactAddedTable = true;


        this.selectedsummary = 'NewContactgridOptions';
        this.type = "Contact";
        this.SelectedNewContact();
        this.showNewContactAddedTable = true;
      }
      else {
        this.selectedsummary = 'gridselectedOptions';
        this.type = "Account";
        if (!this.isTaskHistoryGridInitiatized) {
          this.SelectedtaskinitializeAgGrid();
        } else {
          //this.checkmultiplecontacts($("#chkmul :selected").val());
        }
        this.showSelectedtaskHistory = true;
        this.showNewAccountTable = false;
        this.showSaleProcessHistoryTable = false;
        this.showOpprtunitiesHistoryTable = false;
        this.showNewContactAddedTable = false;
        this.contactinformationDiv = true;

        this.loadGetAllUserActivitiesList(id, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, val, this.currentPage, this.pageSize);
        if (count == 0) {
          this.actcomp.zeroCountRefreshData();
        }

      }
    }
    else {
      //document.getElementById('showSelectedtaskHistory').style.display = 'none';
      //document.getElementById('showOpprtunitiesHistoryTable').style.display = 'none';
      //document.getElementById('showNewAccountTable').style.display = 'none';
      this.alltaskhistory = [];
      this.allNewContact = [];
      this.allCustomers = [];
      this.allLeads = [];
      this.Allopportunities = [];
    }
  }
  SelectedtaskinitializeAgGrid() {
    this.gridselectedOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: /*($("#chkmul :selected").val() == '436') ? 'multiple' : */'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    //let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 40).then((config) => {
      this.columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.selectedcreateColumnDefs(this.columnSelections);
      this.startDateVal = (config != null && config.startdate != null) ? config.startdate : (new Date().getFullYear() - 1) + '-01-01';
      this.startDatePlaceHolder = (config != null && config.startdate != null) ? 'Start:' + moment(config.startdate).format("MM-DD-YYYY") : 'Start: 01-01-' + (new Date().getFullYear() - 1).toString();
      this.endDateVal = (config != null && config.enddate != null) ? config.enddate : new Date().getFullYear() + '-12-31';
      this.endDatePlaceHolder = (config != null && config.enddate != null) ? 'End:' + moment(config.enddate).format("MM-DD-YYYY") : 'End: 12-31-' + (new Date().getFullYear() - 1).toString();
      this.UpdateSearchColumnsUserActSummary(this.columnSelections);
    });

    this.gridselectedOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }

    this.isTaskHistoryGridInitiatized = true;
  }
  UpdateSearchColumnsUserActSummary(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    let allCols: string[] = columnSelections.split(',');

    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');
      if (fields[2] == 'true' && (fields[1] != '#' && fields[0] != 'notes' && fields[0] != 'actions' && fields[0] != 'callliststatus')) {
        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }

    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    this.accountsService.GetDefaultColumns('reports', this.currentuser.id).then((config) => {
      if (config != null && config.defaultcolumns != null) {
        let tempTableColumns: any[];
        tempTableColumns = config.defaultcolumns.split(',');
        tempTableColumns.forEach(col => {
          this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

        })
      } else {
        this.applicationComponent.SearchSelectedItems = [
          { "id": 'firstName', "itemName": 'First Name' },
          { "id": 'lastName', "itemName": 'Last Name' }
        ];
      }
    });
    //this.applicationComponent.showSearchTabsSubTabs = false;
  }
  selectedcreateColumnDefs(columnSelections: string) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.selectedcolumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.selectedcolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "callliststatus") {
          this.selectedcolumnDefs.push({
            field: 'callliststatus', headerName: 'Call List Hx', cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:70px; background:white">' + params.value + '</button>';
              var eButton = eDiv.querySelectorAll('.btn-simple')[0];
              eButton.addEventListener('click', function () {
                selfObj.callhistorystatus = params.data.callliststatus;
                selfObj.contactid = params.data.contactid;
                selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
                selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
              });
              return eDiv;
            }, hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.selectedcolumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.selectedcolumnDefs.push({
            field: 'owner', headerName: 'User Name', cellStyle: function (params) {
              if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            }, hide: true, suppressSorting: true, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.selectedcolumnDefs.push({ field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) { if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; } } });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "DateCreated") {
          this.selectedcolumnDefs.push({
            field: 'DateCreated', headerName: 'Date Created', hide: false, cellStyle: function (params) {
              if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          }
          );
        }
        else if (fieldHeaderVisibleWidthArray[0] == "DateSched") {
          this.selectedcolumnDefs.push({
            field: 'DateSched', headerName: 'Date Scheduled', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) , cellStyle: function (params) {
              if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "DateComp") {
          this.selectedcolumnDefs.push({
            field: 'DateComp', headerName: 'Date Comp', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) , cellStyle: function (params) {
              if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            }, cellRenderer: (params) => {
              if (params.data.status == 'Open' || params.value == "") { return ""; } else {
                return moment(params.value).format('MM-DD-YYYY');
              }
            }
          });
        }
        else {
          this.selectedcolumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            cellStyle: function (params) {
              if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            },
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.selectedcolumnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false
        },
        {
          field: 'accountid', headerName: 'accountid', hide: true
        },
        {
          field: 'DateCreated', headerName: 'Date Created', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'DateSched', headerName: 'Date Scheduled', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'DateComp', headerName: 'Date Comp', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }, cellRenderer: (params) => {
            if (params.data.status == 'Open' || params.value == "") { return ""; } else {
              return moment(params.value).format('MM-DD-YYYY');
            }
          }
        },
        {
          field: 'TimeSched', headerName: 'Time Sched', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        { field: 'status', headerName: 'Status', hide: false, cellStyle: function (params) { if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; } } },
        {
          field: 'taskcategory', headerName: 'Task', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        {
          field: 'TypeofTask', headerName: 'Type Of Task', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        {
          field: 'firstName', headerName: 'First Name', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'lastName', headerName: 'Last Name', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'contacttypeName', headerName: 'Contact Type', hide: false, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        {
          field: 'AccountName', headerName: 'Account Name', hide: true, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },  //true starts here
        {
          field: 'time', headerName: 'Start Time', hide: true, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        {
          field: 'totime', headerName: 'End Time', hide: true, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        {
          field: 'callliststatus', headerName: 'Call List Hx', hide: false, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:50px; background:white">' + params.value + '</button>';
            var eButton = eDiv.querySelectorAll('.btn-simple')[0];
            eButton.addEventListener('click', function () {
              selfObj.callhistorystatus = params.data.callliststatus;
              selfObj.contactid = params.data.contactid;
              selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
              selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
            });
            return eDiv;
          }
        },
        {
          field: 'owner', headerName: 'User Name', hide: true, cellStyle: function (params) {
            if ((params.data.DateSched < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.DateSched, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false }
      ];
    }

  }
  TaskhistorycolumnVisible(event) {
    this.TaskhistorysaveConfiguration();
  }
  TaskhistorydragStopped(event) {
    this.TaskhistorysaveConfiguration();
  }
  TaskhistorycolumnResized(event) {
    if (event.finished == true)
      this.TaskhistorysaveConfiguration();
  }
  TaskhistorysaveConfiguration() {
    let allCols: Column[] = this.gridselectedOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 40;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }

  selectedonAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.selectedautoSizeAll();
      $(setTimeout(function () {
        selfObj.selectedautoSizeAll();
      }, 100));
    }, 100));
  }
  selectedautoSizeAll() {

  }
  GetCustomerNotes(contactid, type) {
    this.customerService.GetAllNotesList(this.currentuser.id, contactid, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.initializeAgGridNotes();
      this.allnotes = taskhistory;
    });

  }


  SelectedAccount() {

    this.showNewAccountTable = true;
    this.showSelectedtaskHistory = false;
    this.showSaleProcessHistoryTable = false;
    this.showOpprtunitiesHistoryTable = false;
    $("#contDis").hide();
    $("#disReportNo").hide();



    this.AccountIntializeAgGrid();
    this.loadallcustomers('All', 'All');
  }
  loadallcustomers(type, term) {

    this.gridCount = 0;
    var userids = $("#userids").val();
    this.opprtunityservice.GetAllAccountsBySearch(this.currentuser.id, term, userids, type, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 1, 100, '0,1,2', 0).then((account) => {
      account.forEach(account => {
      });
      this.allCustomers = account;
      this.contactorcustomerid = this.allCustomers[0].id;
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.NewAccountgridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.allCustomers[0].id) {
            selfObj.NewAccountgridOptions.api.selectNode(node, true);
          }
        });
      }, 500));
      this.gridCount = this.allCustomers.length;
      //this.GetCustomerNotesandActivities(this.allCustomers[0].id);
    });
  }
  createAccountColumnDefs(columnSelections: string) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.AccountcolumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.AccountcolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "Customer") {
          this.AccountcolumnDefs.push({
            field: 'Customer', headerName: 'Call List Hx', cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:60px; background:white">Account</button>';
              var eButton = eDiv.querySelectorAll('.btn-simple')[0];
              eButton.addEventListener('click', function () {
                selfObj.callhistorystatus = 'Customer';
                selfObj.contactid = params.data.id;
                selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, selfObj.callhistorystatus);
                selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
              });
              return eDiv;
            }, hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "accnumber") {
          this.AccountcolumnDefs.push({ field: 'accnumber', headerName: 'Act Number', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: { color: 'red' } });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.AccountcolumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else {
          this.AccountcolumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.AccountcolumnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'accnumber', headerName: 'Act Number', hide: false, cellStyle: { color: 'red' } },
        { field: 'accountname', headerName: 'Account Name', hide: false },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        { field: 'address1', headerName: 'Address1', hide: false },
        { field: 'address2', headerName: 'Address2', hide: false },
        { field: 'city', headerName: 'City', hide: false },
        { field: 'state', headerName: 'State', hide: false },
        { field: 'zip', headerName: 'Zip', hide: false },
        { field: 'phone', headerName: 'Phone', hide: true }, //true starts here
        {
          field: 'Customer', headerName: 'Call List Hx', hide: false, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:60px; background:white">Account</button>';
            var eButton = eDiv.querySelectorAll('.btn-simple')[0];
            eButton.addEventListener('click', function () {
              selfObj.callhistorystatus = 'Customer';
              selfObj.contactid = params.data.id;
              selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, selfObj.callhistorystatus);
              selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
            });
            return eDiv;
          }
        },

        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false }
      ];
    }
  }

  AccountonAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.AccountautoSizeAll();
      $(setTimeout(function () {
        selfObj.AccountautoSizeAll();
      }, 100));
    }, 100));
  }
  AccountautoSizeAll() {

  }

  AccountIntializeAgGrid() {

    this.NewAccountgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: /*($("#chkmul :selected").val() == '436') ? 'multiple' :*/ 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 41).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createAccountColumnDefs(columnSelections);

    });

    this.NewAccountgridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.AccountframeworkComponents = {
      notesrenderer: NotesrendererComponent
    };
  }
  AccountcolumnVisible(event) {
    this.AccountsaveConfiguration();
  }
  AccountdragStopped(event) {
    this.AccountsaveConfiguration();
  }
  AccountcolumnResized(event) {
    if (event.finished == true)
      this.AccountsaveConfiguration();
  }
  AccountsaveConfiguration() {
    let allCols: Column[] = this.NewAccountgridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 41;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }

  SelectedLead(userid) {
    this.showSaleProcessHistoryTable = true;
    this.showNewAccountTable = false;
    this.showSelectedtaskHistory = false;
    this.showOpprtunitiesHistoryTable = false;
    this.showNewContactAddedTable = false;
    $("#contDis").show();
    $("#disReportNo").show();
    this.LeadIntializeAgGrid();
    this.LoadallLeadConverted(userid);

  }
  LoadallLeadConverted(userid) {
    this.gridCount = 0;
    this.allContacts = [];
    var userids = userid;
    //this.opprtunityservice.GetAllContactsBySearch(null, userids, 'ContactLead', 0, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal).then((contact) => {
    //  contact.forEach(contact => {
    //    contact.speciality = ((contact.speciality == 'null') ? "" : contact.speciality);
    //  });
    //  this.allContacts = contact;
    //  let selfObj = this;
    //  $(setTimeout(function () {
    //    selfObj.LeadgridOptions.api.forEachNode((node) => {
    //      if (node.data.id == selfObj.allContacts[0].id) {
    //        selfObj.LeadgridOptions.api.selectNode(node, true);
    //        selfObj.contactorcustomerid = selfObj.allContacts[0].id;
    //      }
    //    });
    //  }, 500));
    //  this.gridCount = this.allContacts.length;
    //});


    this.leadservice.GetAllLeadsList('All Leads', userids, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'null', 1, 10, this.currentuser.id).then((customer) => {
      this.allLeads = customer;
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.LeadgridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.allLeads[0].id) {
            selfObj.LeadgridOptions.api.selectNode(node, true);
            selfObj.contactorcustomerid = selfObj.allLeads[0].id;
          }
        });
      }, 500));
    });


  }
  LeadonAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.LeadautoSizeAll();
      $(setTimeout(function () {
        selfObj.LeadautoSizeAll();
      }, 100));
    }, 100));
  }
  LeadautoSizeAll() {
  }
  LeadcolumnVisible(event) {
    this.LeadsaveConfiguration();
  }
  LeaddragStopped(event) {
    this.LeadsaveConfiguration();
  }
  LeadcolumnResized(event) {
    if (event.finished == true)
      this.LeadsaveConfiguration();
  }
  LeadsaveConfiguration() {
    let allCols: Column[] = this.LeadgridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 43;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }

  LeadIntializeAgGrid() {
    this.LeadgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: /*($("#chkmul :selected").val() == '436') ? 'multiple' :*/ 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 43).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createLeadColumnDefs(columnSelections);
    });
    this.LeadgridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };

  }

  createLeadColumnDefs(columnSelections: string) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.LeadcolumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.LeadcolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide:  false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "salesstage") {
          this.LeadcolumnDefs.push({
            field: 'salesstage', headerName: 'Sales Stage', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) , cellStyle: function (params) {
              if (params.data.status == 'Open' || params.data.status == 'Pending') { return { color: 'red' }; }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "firstname") {
          this.LeadcolumnDefs.push({
            field: 'firstname', headerName: 'First Name', hide: false});
        }
        else if (fieldHeaderVisibleWidthArray[0] == "lastname") {
          this.LeadcolumnDefs.push({
            field: 'lastname', headerName: 'Last Name', hide: false});
        }
        else if (fieldHeaderVisibleWidthArray[0] == "account") {
          this.LeadcolumnDefs.push({
            field: 'account', headerName: 'Account Name', hide: false});
        }
        else if (fieldHeaderVisibleWidthArray[0] == "nexttask") {
          this.LeadcolumnDefs.push({
            field: 'nexttask', headerName: 'Next Sales Step', hide: false, cellRenderer(params) {
              if (params.data.salesstage === 'IPC') { return 'Analysis' }
              else if (params.data.salesstage === 'Analysis') { return 'Prepare Analysis Report' }
              else if (params.data.salesstage === 'Prepare Analysis Report') { return 'Review Analysis' }
              else if (params.data.salesstage === 'Review Analysis') { return 'Generate Proposal' }
              else if (params.data.salesstage === 'Generate Proposal') { return 'Justification Meeting' }
              else if (params.data.salesstage === 'Justification Meeting') { return 'Reference Calls' }
              else if (params.data.salesstage === 'Reference Calls') { return 'Demonstration' }
              else if (params.data.salesstage === 'Demonstration') { return 'Won-Lost-Pending' }
            }
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.LeadcolumnDefs.push({
            field: 'status', headerName: 'Status', hide: false, cellStyle:
              function (params) {
                if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; }
              }, cellRenderer(params) {
                if (params.data.salesstage == 'MD Logic Demonstration' && params.data.status == 'Next Stage Pending') { return ' ' } else { return params.value; }
              }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.LeadcolumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide:  false , width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.LeadcolumnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.LeadcolumnDefs.push({ field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellStyle: function (params) { if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; } } });
        }
        else {
          this.LeadcolumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {

      this.LeadcolumnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        { field: 'datecreated', headerName: 'Date Created', hide: false },
        {
          field: 'lastname', headerName: 'Last Name', hide: false},
        { field: 'middlename', headerName: 'Middle Name', hide: false },
        {
          field: 'firstname', headerName: 'First Name', hide: false},
        { field: 'titleName', headerName: 'Title', hide: false },
        {
          field: 'account', headerName: 'Account Name', hide: false},
        {
          field: 'salesstage', headerName: 'Sales Stage', hide: false, cellStyle: function (params) {
            if (params.data.status == 'Open' || params.data.status == 'Pending') { return { color: 'red' }; }
          }
        },
        {

          field: 'status', headerName: 'Status', hide: false, cellStyle:
            function (params) {
              if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; }
            }, cellRenderer(params) {
              if (params.data.salesstage == 'MD Logic Demonstration' && params.data.status == 'Next Stage Pending') { return ' ' } else { return params.value; }
            }

        },
        {
          field: 'nexttask', headerName: 'Next Sales Step', hide: false, cellRenderer(params) {
            if (params.data.salesstage == 'IPC') { return 'Analysis' }
            else if (params.data.salesstage == 'Analysis') { return 'Prepare Analysis Report' }
            else if (params.data.salesstage == 'Prepare Analysis Report') { return 'Review Analysis' }
            else if (params.data.salesstage == 'Review Analysis') { return 'Generate Proposal' }
            else if (params.data.salesstage == 'Generate Proposal') { return 'Justification Meeting' }
            else if (params.data.salesstage == 'Justification Meeting') { return 'Reference Calls' }
            else if (params.data.salesstage == 'Reference Calls') { return 'Demonstration' }
            else if (params.data.salesstage == 'Demonstration') { return 'Won-Lost-Pending' }
          }
        },
        { field: 'datescheduled', headerName: 'Date Schedule', hide: true },  //true starts here
        { field: 'owner', headerName: 'Owner', hide: false },
        { field: 'completeduser', headerName: 'Completed By', hide: false },
        { field: 'dealwonOrlost', headerName: 'Deal Won/Lost', hide: true },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide:false, pinned: 'right' }

      ];

      var tooltipRenderer = function (params) {
        return '<span title="' + params.value + '">' + params.value + '</span>';
      };
    }
  }
  SelectedNewContact() {
    this.showNewAccountTable = false;
    this.showSelectedtaskHistory = false;
    this.showSaleProcessHistoryTable = false;
    this.showOpprtunitiesHistoryTable = false;
    this.showNewContactAddedTable = true;

    $("#contDis").show();
    $("#disReportNo").show();
    this.NewContactIntializeAgGrid();
    let self = this;
    $(setTimeout(function () {
      self.loadallnewcontacts($("#userids").val(), (self.calanderonoff) ? self.startDateVal : '2018-01-01', self.endDateVal)
    }, 500));


  }
  NewContactonAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.NewContactautoSizeAll();
      $(setTimeout(function () {
        selfObj.NewContactautoSizeAll();
      }, 100));
    }, 100));
  }
  NewContactcolumnVisible(event) {
    this.NewContactsaveConfiguration();
  }
  NewContactdragStopped(event) {
    this.NewContactsaveConfiguration();
  }
  NewContactcolumnResized(event) {
    if (event.finished == true)
      this.NewContactsaveConfiguration();
  }
  NewContactsaveConfiguration() {
    let allCols: Column[] = this.NewContactgridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 42;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }

  NewContactautoSizeAll() {

  }

  NewContactIntializeAgGrid() {

    this.NewContactgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: /*($("#chkmul :selected").val() == '436') ? 'multiple' :*/ 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 42).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createNewContactcolumnDefs(columnSelections);

    });

    this.NewContactgridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };

  }

  createNewContactcolumnDefs(columnSelections: string) {

    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.NewContactcolumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.NewContactcolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "callliststatus") {
          this.NewContactcolumnDefs.push({
            field: 'callliststatus', headerName: 'Call List Hx', cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:50px; background:white">' + params.value + '</button>';
              var eButton = eDiv.querySelectorAll('.btn-simple')[0];
              eButton.addEventListener('click', function () {
                selfObj.callhistorystatus = params.data.callliststatus;
                selfObj.contactid = params.data.id;
                selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
                selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
              });
              return eDiv;
            }, hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.NewContactcolumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else {
          this.NewContactcolumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.NewContactcolumnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        {
          field: 'lastname', headerName: 'Last Name', hide: false
        },
        { field: 'firstname', headerName: 'First Name', hide: false },
        { field: 'middlename', headerName: 'Middle Name', hide: false },
        { field: 'titleName', headerName: 'Title', hide: false },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        { field: 'DOB', headerName: 'DOB', hide: false },
        {
          field: 'callliststatus', headerName: 'Call List Hx', hide: false, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:50px; background:white">' + params.value + '</button>';
            var eButton = eDiv.querySelectorAll('.btn-simple')[0];
            eButton.addEventListener('click', function () {
              selfObj.callhistorystatus = params.data.callliststatus;
              selfObj.contactid = params.data.id;
              selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
              selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
            });
            return eDiv;
          }
        },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        { field: 'zip', headerName: 'ZIP', hide: false },
        { field: 'accountname', headerName: 'Account Name', hide: false },
        { field: 'contacttypeName', headerName: 'Contact Type', hide: true },  //true starts here
        { field: 'suffix', headerName: 'Suffix', hide: true },
        { field: 'sex', headerName: 'Sex', hide: true },
        { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
        { field: 'email', headerName: 'Email', hide: true },
        { field: 'address1', headerName: 'Address1', hide: true },
        { field: 'address2', headerName: 'Address2', hide: true },
        { field: 'city', headerName: 'City', hide: true },
        { field: 'state', headerName: 'State', hide: true },
        { field: 'cell', headerName: 'Cell', hide: true },
        { field: 'office', headerName: 'Office', hide: true },
        { field: 'homeaddress', headerName: 'Home Address', hide: true },
        { field: 'homecity', headerName: 'Home City', hide: true },
        { field: 'homestate', headerName: 'Home State', hide: true },
        { field: 'homezip', headerName: 'Home Zip', hide: true },
        { field: 'home', headerName: 'Home', hide: true },
        { field: 'college', headerName: 'College', hide: true },
        { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
        { field: 'medschool', headerName: 'Medschool', hide: true },
        { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
        { field: 'residency', headerName: 'Residency', hide: true },
        { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
        { field: 'fellowship', headerName: 'Fellowship', hide: true },
        { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
        { field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true },
        { field: 'created', headerName: 'Created', hide: true }
      ];
    }
  }
  SelectedOpportunity() {

    this.showNewAccountTable = false;
    this.showSelectedtaskHistory = false;
    this.showSaleProcessHistoryTable = false;
    this.showOpprtunitiesHistoryTable = true;
    this.showNewContactAddedTable = false;   
    this.OpprtunitiesIntializeAgGrid();
    this.ClosedOpportunity('All');

  }
  ClosedOpportunity(val) {
    this.gridCount = 0;
    this.opprtunityservice.GetAllOpportunitiesList(val, $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, null, this.currentuser.id,true).then((customer) => {
      customer.forEach(customer => {
        customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
        if (customer.estimatedclosedate != '0001-01-01T00:00:00') {
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
        } else {
          customer.estimatedclosedate = '';
        }
      });

      this.Allopportunities = customer;
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.OpportunitiesgridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.Allopportunities[0].id) {
            selfObj.OpportunitiesgridOptions.api.selectNode(node, true);
            selfObj.contactorcustomerid = selfObj.Allopportunities[0].accountid;
          }
        });
      }, 500));
      this.gridCount = this.Allopportunities.length;
    });
  };
  OpprtunitiesIntializeAgGrid() {
    this.OpportunitiesgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: /*($("#chkmul :selected").val() == '436') ? 'multiple' : */'single'
    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 44).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createOpportunitiesColumnDefs(columnSelections);

    });
    this.OpportunitiesgridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };

  }
  OpportunitiescolumnVisible(event) {
    this.OpportunitiessaveConfiguration();
  }
  OpportunitiesdragStopped(event) {
    this.OpportunitiessaveConfiguration();
  }
  OpportunitiescolumnResized(event) {
    if (event.finished == true)
      this.OpportunitiessaveConfiguration();
  }
  OpportunitiessaveConfiguration() {
    let allCols: Column[] = this.OpportunitiesgridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 44;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }

  OpportunitiesonAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.OpportunitiesautoSizeAll();
      $(setTimeout(function () {
        selfObj.OpportunitiesautoSizeAll();
      }, 100));
    }, 100));
  }
  OpportunitiesautoSizeAll() {

  }
  createOpportunitiesColumnDefs(columnSelections: string) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.OpportunitiescolumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.OpportunitiescolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.OpportunitiescolumnDefs.push({
            field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'MM-dd-yyyy') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "datecreated") {
          this.OpportunitiescolumnDefs.push({
            field: 'datecreated', headerName: 'Date Created', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) , cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "estimatedclosedate") {
          this.OpportunitiescolumnDefs.push({
            field: 'estimatedclosedate', headerName: 'Date Scheduled', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) ,cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "callliststatus") {
          this.OpportunitiescolumnDefs.push({
            field: 'callliststatus', headerName: 'Call List Hx', cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:60px; background:white">' + params.value + '</button>';
              var eButton = eDiv.querySelectorAll('.btn-simple')[0];
              eButton.addEventListener('click', function () {
                selfObj.callhistorystatus = params.data.callliststatus;
                selfObj.contactid = params.data.accountorcontactid;
                selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
                selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
              });
              return eDiv;
            }, hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.OpportunitiescolumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else {
          this.OpportunitiescolumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.OpportunitiescolumnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false
        },
        {
          field: 'accountid', headerName: 'accountid ', hide: true
        },
        {
          field: 'datecreated', headerName: 'Date Created ', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'estimatedclosedate', headerName: 'Date Scheduled', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'status', headerName: 'Status', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'practicename', headerName: 'Account Name', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'contactName', headerName: 'Contact Name', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'itemName', headerName: 'Item', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'dollers', headerName: '$$$$', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'RR', headerName: 'RR', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'PCname', headerName: 'PC', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'drsName', headerName: 'Drs', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },  //true starts here
        {
          field: 'midlevel', headerName: 'Mid Levels', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          }
        },
        {
          field: 'leadfrom', headerName: 'Lead From', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'owner', headerName: 'Owner', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'actualclosedate', headerName: 'Act.Close', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'callliststatus', headerName: 'Call List Hx', hide: false, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:60px; background:white">' + params.value + '</button>';
            var eButton = eDiv.querySelectorAll('.btn-simple')[0];
            eButton.addEventListener('click', function () {
              selfObj.callhistorystatus = params.data.callliststatus;
              selfObj.contactid = params.data.accountorcontactid;
              selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
              selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
            });
            return eDiv;
          }
        },

        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, pinned: 'right' },
      ];
    }
  }

  hidediv() {
    this.selectedsummary = '';
    this.contactinformationDiv = false;
    this.showSelectedtaskHistory = false;
    this.showNewAccountTable = false;
    this.showSaleProcessHistoryTable = false;
    this.showOpprtunitiesHistoryTable = false;
    this.showNewContactAddedTable = false;
   
  }
  OnChangeData(msg) {

    if (msg != 'Notes') {
      if (msg == 'OpenTask') {
        this.selectedreporttype = 'User Activity(Summary)';
      } else {
        this.isOpenNewNotes = false;
        this.loadalluserSummary($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);

        this.showSelectedtaskHistory = false;
        this.showSaleProcessHistoryTable = false;
        this.showOpprtunitiesHistoryTable = false;
        $('.overlay').remove();
      }
    }
    else {
      this.isOpenNewNotes = true;
      this.showSelectedtaskHistory = false
      if (msg == 'OpenTask') {
        this.selectedreporttype = 'User Activity(Summary)';
      }
    }
  }
  appendLabel(value) {

    if (this.currentuser.UserPrivilegeList != undefined) {
      var button = document.getElementById(value);
      var thisTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == value || p.resource.toLowerCase() == value)[0];
      if (thisTab != undefined && thisTab.canview == false) {
        this.toastr.warning("You Do Not have Access to " + value + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
        return false;
      }
    }

    let selfobj = this;
    $(setTimeout(function () {
      selfobj.ReporttypeChange(value);
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }, 100));
  }

  closeUserTable(id) {

    $('#' + id).remove();
    var valuesStored = $("#userids").val();
    var valuesStoredArray = (valuesStored).split(",");
    var newValues = '';
    for (var i = 0; i < valuesStoredArray.length; i++) {
      if (valuesStoredArray[i] != id) {
        if (newValues == '') {
          newValues = valuesStoredArray[i];
        } else {
          newValues = newValues + ',' + valuesStoredArray[i];
        }
      }
    }
    $("#userids").val(newValues);
    if (newValues == '') {
      this.selectedItems = [];
    } else {
      this.applicationComponent.selectedusers = newValues;
      if (this.applicationComponent.selectedusers != '' || this.applicationComponent.selectedusers != null) {
        this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
          Owner.forEach(Owner => {
          });
          this.selectedItems = Owner;
        });

      }
    }
    this.hidediv();
  }

  //checkmultiplecontacts(id) {
  //  let selfObj = this;
  //  if (id == '435') {
  //    this.isMultipleContacts = true;
  //    if (this.selectedsummary == 'NewAccountgridOptions') { this.NewAccountgridOptions.api.deselectAll(); this.NewAccountgridOptions.rowSelection = "multiple"; }
  //    else if (this.selectedsummary == 'NewContactgridOptions') { this.NewContactgridOptions.api.deselectAll(); this.NewContactgridOptions.rowSelection = "multiple"; }
  //    else if (this.selectedsummary == 'LeadgridOptions') { this.LeadgridOptions.api.deselectAll(); this.LeadgridOptions.rowSelection = "multiple"; }
  //    else if (this.selectedsummary == 'OpportunitiesgridOptions') { this.OpportunitiesgridOptions.api.deselectAll(); this.OpportunitiesgridOptions.rowSelection = "multiple"; }
  //    else { this.gridselectedOptions.api.deselectAll(); this.gridselectedOptions.rowSelection = "multiple"; }
  //  }
  //  else {
  //    this.isMultipleContacts = false;
  //    if (this.selectedsummary == 'NewAccountgridOptions') { this.NewAccountgridOptions.rowSelection = "single"; }
  //    else if (this.selectedsummary == 'NewContactgridOptions') { this.NewContactgridOptions.rowSelection = "single"; }
  //    else if (this.selectedsummary == 'LeadgridOptions') { this.LeadgridOptions.rowSelection = "single"; }
  //    else if (this.selectedsummary == 'OpportunitiesgridOptions') { this.OpportunitiesgridOptions.rowSelection = "single"; }
  //    else { this.gridselectedOptions.rowSelection = "single"; }
  //  }

  //}

  openDisplaynotes(params) {
    // this.notes.notedescription = params.value;
    //this.NotesDec = !this.NotesDec;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    var notesOffset = $("#divNotesContainer").offset();
    let notesWidth = $('#divNotesContainer').width();
    let notesHeight = $('#divNotesContainer').height();
    let windowWidth = $(window).width();
    let showDialogWidth = 350;
    let selfObj = this;
    $(setTimeout(function () {
      if (selfObj.isModal) {
        //$('#showDetailsDialog').width(showDialogWidth);
        //$('#showDetailsDialog').height(notesHeight - 100);
        //$('#showDetailsDialog').css('top', notesOffset.top + 18);
        ////$('#showDetailsDialog').css('left', notesWidth - showDialogWidth);
        //$('#showDetailsDialog').css('left', '26.2%');
        //$('#showDetailsDialog').css('border-radius', '4px');
        //$('#showDetailsDialog').css('border', '3px solid');

        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

        if ($('input[name=userInfo]').is(':checked')) {
          //$('#showDetailsDialog').css('left', '73.5%');
          $('#txtLongNoteDesc').css('height', '81%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '14%');
          //$('#showDetailsDialog').css('left', '73.5%');
        } else {
          $('#txtLongNoteDesc').css('height', '96%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtLongNoteDesc').css('overflow', 'auto');
        }
      }

      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);

    }, 1));
  }
  closeDetailsDialog() {
    this.NotesDec = false;
  }
  rowClickAgGrid(rowEvent) {
    if ((this.Ids).indexOf(rowEvent.data.contactid) > -1) {
      var index = (this.Ids).indexOf(rowEvent.data.contactid);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      $('#tasklistselectedtyle').val('');
    } else {
      this.Ids.push(rowEvent.data.contactid)
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      if (rowEvent.data.type == "Contacts") {
        $('#tasklistselectedtyle').val('Contact');
      }
      else {
        $('#tasklistselectedtyle').val('Customer');
      }
    }
  }
  editNote() {
    //if ($("#btnEditNote").text() == "Edit Note") {
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();
    //} else {
    //  $(this).hide();
    //  $('.box').removeClass('editable');
    //  $('.text').removeAttr('contenteditable');
    //  $('.edit').show();
    //  this.saveEditedNote();
    //}
  }
  saveEditedNote() {

    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.closeDetailsDialog();
        selfObj.getTaskHistoryNotes(selfObj.conid, 'Contact');
      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
    //this.Notes(this.selectedTabName);
  }

  //first() {

  //  this.currentPage = 1;
  //  this.loadGetAllUserActivitiesList($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'allactivity', this.currentPage, this.pageSize);
  //}
  //prev() {
  //  if (this.currentPage > 1) {
  //    this.currentPage--;
  //    this.loadGetAllUserActivitiesList($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'allactivity', this.currentPage, this.pageSize);
  //  }
  //}
  //next() {
  //  if (this.currentPage != this.totalnumberofPages) {
  //    this.currentPage++;
  //    this.loadGetAllUserActivitiesList($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'allactivity', this.currentPage, this.pageSize);
  //  }

  //}
  //last() {
  //  this.currentPage = this.totalnumberofPages;
  //  this.loadGetAllUserActivitiesList($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'allactivity', this.currentPage, this.pageSize);
  //}
  calanderclick(val) {

    this.calanderonoff = val;
    if (this.selectedreporttype == "User Activity(Details)" || this.selectedreporttype == "UserActivityDetails") {
      this.ShowUserActivityDetail = true;
      this.ShowUserActivitySummary = false;

      this.loadGetAllUserActivitiesList($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'allactivity', this.currentPage, this.pageSize);
    } else if (this.selectedreporttype == "User Activity(Summary)" || this.selectedreporttype == "UserActivitySummary" ) {  //UserActivitySummary
      this.ShowUserActivityDetail = false;
      this.ShowUserActivitySummary = true;

      this.loadalluserSummary($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);
    }
    else {

      this.Auditlog.LoadLoginAuditLog('Login', this.UserIds, '1', '200', (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);

    }
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }

  }
  UserlistDataReceived(outProps: OutputPropsUserList) {
    this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
    if (this.gridApi) {
      this.gridApi.paginationSetPageSize(Number(this.pageSize));
    }
    this.startDateVal = (this.calanderonoff) ? this.applicationComponent.SearchstartVal : '2018-01-01';
    this.endDateVal = (this.calanderonoff) ? this.applicationComponent.SearchendVal : this.endDateVal;
    
    if (outProps.finalitems != "") {
      $("#userids").val(outProps.finalitems);
      
      if (outProps.fourthDrpValue == "User Activity(Summary)" ) {  //UserActivitySummary
        if (!outProps.pageSizeChanged) {
          this.loadalluserSummary($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);
        }
        this.appendLabel(outProps.fourthDrpValue);
        if ($("#userids").val().includes(',')) {
          this.CheckboxCheck(false, false, false, false, false, false, false, false, false, false);
          this.hidediv();
          this.contactinformationDiv = false;
          $('.closeButtonTr').removeClass('GreenBackGround');
        }

        var obj: AuditLogModel = { id: 0, type: 'Standard Reports - ' + outProps.fourthDrpValue, action: 'Standard Reports - ' + outProps.fourthDrpValue, AccountOrContactId: 0, AccountOrContact: outProps.fourthDrpValue, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: outProps.fourthDrpValue, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
        this.applicationComponent.InsertAuditHistory(obj);


        this.ShowUserActivityDetail = false;
        this.OtherStandardReports = false;
        this.ShowUserActivitySummary = true;
      }
      else if (outProps.fourthDrpValue == "Standard Reports") {
        var url = window.location.href;
        if (url.indexOf('reports/audit') > -1) {
           
          this.selectedreporttype = 'Audit Log(Admin)';
          let SelfObj = this;
          $(setTimeout(function () {
            SelfObj.ShowUserActivityDetail = true;
          }, 500));

        }
        else {
          var obj: AuditLogModel = { id: 0, type: 'Standard Reports', action: 'Standard Reports', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Standard Reports', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
          this.applicationComponent.InsertAuditHistory(obj);

          this.applicationComponent.SubmenuChange('Reports', 'StandardReports', 'Sub');

        }
        
      }
      else if (outProps.fourthDrpValue == "Admin Reports") {

        var obj: AuditLogModel = { id: 0, type: 'Admin Reports', action: 'Admin Reports', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Standard Reports', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
        this.applicationComponent.InsertAuditHistory(obj);

          this.applicationComponent.SubmenuChange('Reports', 'AdminReports', 'Sub');

      }
      else if (outProps.fourthDrpValue == "Accounts Added" || outProps.fourthDrpValue == "Contacts Added" || outProps.fourthDrpValue == "Leads Added" || outProps.fourthDrpValue == "Leads Lost" || outProps.fourthDrpValue == "Leads Pended" || outProps.fourthDrpValue == "Leads Won" || outProps.fourthDrpValue == "Proposals Added" || outProps.fourthDrpValue == "Proposals Pended" || outProps.fourthDrpValue == "Opportunities Added" || outProps.fourthDrpValue == "Opportunities Completed" || outProps.fourthDrpValue == "Opportunities Lost" || outProps.fourthDrpValue == "Opportunities Pended" || outProps.fourthDrpValue == "Opportunities Won" || outProps.fourthDrpValue == "Tasks Added" || outProps.fourthDrpValue == "Tasks Completed" || outProps.fourthDrpValue == "Tickets Added" || outProps.fourthDrpValue == "Tickets Closed" || outProps.fourthDrpValue == "Tickets On-Hold" || outProps.fourthDrpValue == "Tickets Pended" || outProps.fourthDrpValue == "Tickets Open" || outProps.fourthDrpValue == 'Access(Login/Logout)') {
        this.ShowUserActivityDetail = false;
        this.ShowUserActivitySummary = false;
        this.OtherStandardReports = true;
        this.selectedreporttype = outProps.fourthDrpValue;
        /*this.ReportType = (outProps.fourthDrpValue == "Added Opportunities" || outProps.fourthDrpValue == "Completed Opportunities" ||outProps.fourthDrpValue == "Lost Opportunities" ||outProps.fourthDrpValue == "Pended Opportunities" || outProps.fourthDrpValue == "Won Opportunities") ? outProps.fourthDrpValue.split(" ")[1] :*/ this.ReportType = outProps.fourthDrpValue.split(" ")[0];
        this.Category = outProps.fourthDrpValue == "Opportunities Added" ? "Added Opportunities" :
          outProps.fourthDrpValue == "Opportunities Completed" ? "Completed Opportunities" :
            outProps.fourthDrpValue == "Opportunities Lost" ? "Lost Opportunities" :
              outProps.fourthDrpValue == "Opportunities Pended" ? "Pended Opportunities" :
                outProps.fourthDrpValue == "Opportunities Won" ? "Won Opportunities" : outProps.fourthDrpValue;      
        this.startDate = (this.calanderonoff) ? this.applicationComponent.SearchstartVal : moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("YYYY-MM-DD");
        this.EndDate = (this.calanderonoff) ? this.applicationComponent.SearchendVal : moment(new Date()).format("YYYY-MM-DD");
        let SelfObj = this;        
        $(setTimeout(function () {        
          SelfObj.OtherReports.getOtherSelectedReportData(SelfObj.ReportType, SelfObj.Category, SelfObj.startDate, SelfObj.EndDate);
        }, 300));

        var obj: AuditLogModel = { id: 0, type: 'Standard Reports - ' + outProps.fourthDrpValue , action: 'Standard Reports - ' + outProps.fourthDrpValue, AccountOrContactId: 0, AccountOrContact: outProps.fourthDrpValue, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: outProps.fourthDrpValue, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
        this.applicationComponent.InsertAuditHistory(obj);


        
      }
      else if (outProps.fourthDrpValue == "") {
        this.ShowUserActivityDetail = false
      }
      else {
        this.ShowUserActivityDetail = true;
        this.ShowUserActivitySummary = false;
        this.OtherStandardReports = false;
        this.ShowActivityDetailsTask = false;
        this.selectedreporttype = outProps.fourthDrpValue;
        if (!outProps.pageSizeChanged) {
          this.loadGetAllUserActivitiesList($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'allactivity', this.currentPage, this.pageSize);
          if (outProps.fifthDrpValue != null) {
            this.selectedFilterType = outProps.fifthDrpValue;
            let selfObj = this;
            $(setTimeout(function () {
              selfObj.filterTaskList(outProps.fifthDrpValue); 
            }, 400));
           
          }

          var obj: AuditLogModel = { id: 0, type: 'Standard Reports - ' + outProps.fourthDrpValue, action: 'Standard Reports - ' + outProps.fourthDrpValue, AccountOrContactId: 0, AccountOrContact: outProps.fourthDrpValue, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: outProps.fourthDrpValue, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
          this.applicationComponent.InsertAuditHistory(obj);
        }
        
      }

    } else {
      this.alltaskhistory = [];
      $("#userids").val('');
    }
  }

  LoadOtherMenu() {
    this.ShowUserActivityDetail = false;
    this.ShowUserActivitySummary = false;
    this.OtherStandardReports = true;
    this.customerService.GetSpecialityList("ReportType", this.currentuser.id).then((Rows) => {
      //this.fourthDropdownList = Rows.sort((a, b) => a.itemName > b.itemName ? 1 : -1);
      var value = Rows.filter(x => x.itemName.includes(this.selectedtype))[0];
      this.UDHComp.FourthDrpModel = [{ "id": value.id, "itemName": value.itemName }];

      this.selectedreporttype = this.selectedtype;
      this.ReportType = this.selectedtype.split(" ")[0];
      this.Category = this.selectedtype.split(" ")[1];
      this.startDate = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("YYYY-MM-DD");
      this.EndDate = moment(new Date()).format("YYYY-MM-DD");

      let SelfObj = this;
      $(setTimeout(function () {
        SelfObj.OtherReports.getOtherSelectedReportData(SelfObj.ReportType, SelfObj.Category, SelfObj.startDate, SelfObj.EndDate);
      }, 200));

    });

    //this.selectedItems = [{ "id": this.currentuser.id, "itemName": this.selectedtype }]
   
  }

  filterTaskList(val) {
    switch (val) {
      case "Display All": {
        this.alltaskhistory = this.alltaskhistoryOriginal;
        break;
      }
      case "Meeting Open": {
        this.alltaskhistory = this.alltaskhistoryOriginal.filter(x => x.taskcategory == "Meeting" && x.status == "Open");
        break;
      }
      case "Meeting Completed": {
        this.alltaskhistory = this.alltaskhistoryOriginal.filter(x => x.taskcategory == "Meeting" && x.status == "Completed");
        break;
      }
      case "Phone Open": {
        this.alltaskhistory = this.alltaskhistoryOriginal.filter(x => x.taskcategory == "Phone" && x.status == "Open");
        break;
      }
      case "Phone Completed": {
        this.alltaskhistory = this.alltaskhistoryOriginal.filter(x => x.taskcategory == "Phone" && x.status == "Completed");
        break;
      }
      case "To Do Open": {
        this.alltaskhistory = this.alltaskhistoryOriginal.filter(x => x.taskcategory == "To Do" && x.status == "Open");
        break;
      }
      case "To Do Completed": {
        this.alltaskhistory = this.alltaskhistoryOriginal.filter(x => x.taskcategory == "To Do" && x.status == "Completed");
        break;

      }
    }
  }
  

  CloseInfoDiv() {
    this.UserActivitySummaryCheckedDiv = false;
    this.TaskListInfo = false;
    this.contactinformationDiv = false;
    this.AccActChkBox = new AccountSummaryCheckBox();
  }

  rowDoubleClickAgGrid(rowEvent) {

    //localStorage.setItem('TaskListSelected', rowEvent.data.id);
    let SO = this;
    $(setTimeout(function () {
      SO.applicationComponent.activateSubButtons('empty');
      $(setTimeout(function () {
        SO.router.navigateByUrl(`/application/tasklistdashboard/Contacts/${rowEvent.data.contactid}/${rowEvent.data.id}/null`);
        SO.applicationComponent.ExpandSelectedMenu('tasklists');
        $(".subButtons").removeClass("activeButton");
        
      }, 100));
    }, 50));

      
  }

  onReceiveMessage(msg) {
    if (msg == "RefreshReportGrid") {
      this.ShowActivityDetailsTask = false;
      this.loadGetAllUserActivitiesList($("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'allactivity', this.currentPage, this.pageSize);
      let selfObj = this;
      $(setTimeout(function () {

        selfObj.filterTaskList(selfObj.selectedFilterType);
      }, 400));
    }
  }
}

