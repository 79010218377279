import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { StatusIdsComponent } from '../status-ids/status-ids.component';
import { CustomerService } from 'src/app/services/customer.service';
import { DatePipe } from '@angular/common';
import { Accounts } from '../../models/accounts';
import { Router } from '@angular/router';
import { ScheduletaskComponent } from '../scheduletask/scheduletask.component';
import { TaskHistory } from '../../models/task-history';
import { ApplicationComponent } from '../application.component';
import { LeadsService } from '../../services/leads.service';
import { AccountsService } from '../../services/accounts.service';
declare var $: any;
@Component({
  selector: 'account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css']
})
export class AccountDetailsComponent implements OnInit {
  @Input()
  viewaccount: any;
  @Input()
  account: Accounts;
  @Input()
  contact: any;
  @Input()
  tab: string;
  @Input()
  noteslist: any;
  @Input()
  pageFrom: any;
  @Input()
  ViewData: any;
  
  @Output()
  btnClickEvent = new EventEmitter<any>();
  @ViewChild(StatusIdsComponent) statuidscmp;
  @ViewChild(ScheduletaskComponent) shedTaskcmp;
  LastTicketDate;
  LastContactDate;
  datePipe: DatePipe = new DatePipe("en-US");
  currentuser: any;
  salesrepName: string;
  taskhistory: TaskHistory = new TaskHistory();
  futureTask: boolean = true;
  activitieslist: Array<TaskHistory> = [];
  today = Date.now();
  pageViewed = "";
  RWActive: boolean = false; statusIdExist: boolean = false;
  constructor(private accountsService: AccountsService,private customerService: CustomerService, private router: Router, public applicationComponent: ApplicationComponent,private leadservice: LeadsService) { this.currentuser = JSON.parse(localStorage.getItem("currentUser")); }

  ngOnInit(): void {
 
    if (this.viewaccount && this.viewaccount.accountId != undefined) {
      this.getAccountHistory(this.viewaccount.accountId); 
    }
  }
  RWActiveClick(val) { }
  
  loadAccountId(accountId) {
    this.getAccountHistory(accountId);  
    if (this.viewaccount.viewcity == 'Nassau') { this.viewaccount.viewCountry = "Bahamas"; } 
   
    this.accountsService.GetAccountStatusIds(accountId,'Account').then(result => {
      this.CheckStatusIDExist(result);
    });
  }
  CheckStatusIDExist(result) {
    if (result != undefined && (result.StatusID1 != null || result.StatusID2 != null || result.StatusID3 != null || result.StatusID4 != null || result.StatusID5 != null || result.StatusID6 != null ||
      result.StatusID7 != null || result.StatusID8 != null || result.StatusID9 != null || result.StatusID10 != null || result.StatusID11 != null || result.StatusID12 != null ||
      result.StatusID13 != null || result.StatusID14 != null || result.StatusID15 != null || result.StatusID16 != null || result.StatusID17 != null || result.StatusID18 != null ||
      result.StatusID19 != null || result.StatusID20 != null || result.StatusID21 != null || result.StatusID22 != null || result.StatusID23 != null || result.StatusID24 != null ||
      result.StatusID25 != null || result.StatusID26 != null || result.StatusID27 != null || result.StatusID28 != null || result.StatusID29 != null || result.StatusID30 != null ||
      result.StatusID31 != null || result.StatusID32 != null || result.StatusID33 != null || result.StatusID34 != null || result.StatusID35 != null || result.StatusID36 != null ||
      result.StatusID37 != null || result.StatusID38 != null || result.StatusID39 != null || result.StatusID40 != null)) {
      this.statusIdExist = true;
      return true;
    } else {
      this.statusIdExist = false;
      return false;
    }
  }
  getAccountHistory(accountId) {
   
    this.customerService.GetByAccountIdNew(accountId).then(result => {
      this.account = JSON.parse(result);
      
      if (this.statuidscmp != undefined) {
        this.statuidscmp.GetAccountInfo(accountId);
      }
    });

    this.customerService.LastTicketCreatedDateByAccID(this.viewaccount.accountId).then(result1 => {
      if (result1 != null && result1 != '0001-01-01T00:00:00') {
        this.LastTicketDate = this.datePipe.transform(result1, "dd-MM-yyyy")
      }
    });
    this.customerService.LastContactedDateByAccID(this.viewaccount.accountId).then(result2 => {
      if (result2 != null && result2 != '0001-01-01T00:00:00') {
        this.LastContactDate = this.datePipe.transform(result2, "dd-MM-yyyy");
      }
    });
    this.customerService.GetSalerepName(this.viewaccount.accountId).then(result3 => {      
      this.salesrepName = result3;   
    });
    this.leadservice.GetAllTaskHistoryByContact(accountId, 'Customer', this.currentuser.timezone).then((activities) => {
      this.activitieslist = activities;
      this.activitieslist = this.activitieslist.filter(x => x.estcompleteddate > this.datePipe.transform(this.today, "yyyy-MM-dd"));
    });

  }

  NavigateToCalender() {
    this.btnClickEvent.emit({ btnName: 'Calander', val: '' });  
    this.applicationComponent.activateMainButtons('Calendar'); 
  }
  ContactInfo(val) {
    if (val != undefined) {
      this.customerService.LoadContactById(val).then(result => {
        this.contact = result;
      });
    }
  }
  buttonClick(btnName, val) {   
    this.btnClickEvent.emit({ btnName: btnName, val: val });    
  }
}
