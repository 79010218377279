export class TaskHistory {
    public id: number;
    public contactid: number;
    public datecreated: string;
    public estcompleteddate: string;
    public status: string;
  public tasktype: string;
  public taskcategory: string;
  public tasktypeid: number;
  public time: string;
    public totime: string;
    public salesstageid: number;
    public type: string;
    public userid: number;
  public contactName: string;
  public accountname: string;
  public DateCreated: string;
  public DateSched: string;
  public datecompleted: string;
  public DateComp: string;
  public task: string;
  public owner: string;
  public timezone: string;
  public starttime: string;
  public endtime: string;
  public islimitedSearch: boolean;
  public SearchVal:string
  public CurrentPage: number;
  public PageSize: number;
  public TotalPages: number;
  public Total: number;
  public notedescription: string;
  public firstName: string;
  public lastName: string;
  public NoOfDays: number;
  public accountid: number;
  public ownerid: number;
  public title: string;
  public taskGroupID: number;
  public accountphone: string;
  public createdBy: string;
  public DAY: string;
}
