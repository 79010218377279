<div class="col-xs-12" style="padding:0;" [style.height.%]="(this.ContactDashBoardPage) ? 95:92">

  <ag-grid-angular style="width: 100%; height:100%;" id="emailsDivGrid"
                   class="ag-theme-balham" *ngIf="SentEmailTable"
                   [gridOptions]="gridemailOptions"
                   [columnDefs]="emailcolumnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="emailslist"
                   [sideBar]="sideBar"
                   [pagination]="true"
                   (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                   (gridReady)="onsentemailAgGridReady($event)">
  </ag-grid-angular>

  <div *ngIf="(!SentEmailTable)">
    
    <div class="col-xs-12" style="padding:0 1% 0 1%;margin-bottom: 1%;">
      <div class="col-xs-4" style="float:left"><i class="fa fa-arrow-left " *ngIf="(emialdivId>0)" aria-hidden="true" id="previous" (click)="previousEmail()"></i></div>
      <div class="col-xs-4" style="text-align:center"> <label>Emails</label> <span> ({{currentPage}} of {{emailslist.length}} )</span></div>
      <div class="col-xs-4" style="float:right;text-align:right"><i class="fa fa-arrow-right " aria-hidden="true" id="next" (click)="nextEmail()"></i></div>

    </div>
      <div *ngFor="let email of emailslist;let i = index">
        <div *ngIf="emialdivId == i" id="messageBox{{i}}">
          <label style="margin:0"><b> Sent : </b>{{email.sentemailDate}}</label> <br>
          <label style="margin:0"><b> From : </b></label> {{email.sender}}<br>
          <label style="margin:0"><b> To : </b></label> {{email.tousernames}}<br>
          <label style="margin:0"><b> Cc :    </b></label>  {{email.ccusernames}}<br>
          <label style="margin:0"><b> Bcc :  </b></label>  {{email.bccusernames}}<br>
          <label style="margin:0"><b> Subject :  </b></label>  {{email.subject}}<br>
          <!--<label style="margin:0"><b> Attachments :  </b></label>{{email.filenames}}<br>-->
          <label *ngIf="email.attachedfiles.length > 0" style="margin:0"><b> Attachments :  </b></label>
          <ul>
            <li *ngFor="let hero of email.attachedfiles">
           
              <a (click)="openAttachment(hero)"> {{ hero }}</a>
            </li>
          </ul>

          <!--<div *ngFor="let item of emails.attachedfiles">
          zzxzv
        </div>-->
          <hr style="border: 1px dashed;">
          <div style="padding-left: 0.5%;overflow:auto" [style.height.px]="divhght" [innerHTML]="email.messagebody"></div>
        </div>
      </div>
    </div>
  </div>

