import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { SalesProcessHistory } from './../models/sales-process-history';
import { TaskHistory } from './../models/task-history';
import { Dropdowntype, RevenueModel } from './../models/dropdowntype';
import { AppSettings } from '../app.settings';
import { Notes } from "./../models/notes";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QuestionResponses } from './../models/question-responses';
import { Lastresponseview } from '../models/lastresponseview';
import { SalesStages } from '../models/SalesStages';
import { SystemRowConfiguration } from '../models/system-row-configuration';
import { TaskType } from '../models/TaskType';
import { CampaignExpense, CampaignType } from '../models/CampaignConfiguration';
@Injectable()
export class LeadsService {
  options: RequestOptions;
  authToken: any;
  headers: any; reqHeader: HttpHeaders = new HttpHeaders();
  constructor(private httpclient: HttpClient, private http: Http, private settings: AppSettings) {
    this.reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('key')
    });
    //this.authToken = localStorage.getItem('key');
    //this.headers = new Headers();
    //this.headers.append('Authorization', `Bearer ${this.authToken}`);
    //this.options = new RequestOptions({ headers: this.headers });
  }

    GetAllSalesProcessHistoryByContact(contactid: number): Promise<SalesProcessHistory[]> {
      const promise = new Promise<SalesProcessHistory[]>((resolve, reject) => {
        this.httpclient.get<SalesProcessHistory[]>(this.settings.baseUrl + 'api/leads/GetAllSalesProcessHistoryByContact/' + contactid, { headers: this.reqHeader }).
              subscribe(res => {
                  resolve(res);
              });
      });
      return promise;
    }
    GetStatusChange(id: number, status: string): Promise<SalesProcessHistory> {
        const promise = new Promise<SalesProcessHistory>((resolve, reject) => {
          this.httpclient.get<SalesProcessHistory>(this.settings.baseUrl + 'api/leads/UpdateleadsStatus/' + id + "/" + status, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
    }
    GetAllTaskHistoryByContact(contactid: number, type:string,timezone: string): Promise<TaskHistory[]> {
      const promise = new Promise<TaskHistory[]>((resolve, reject) => {
        this.httpclient.get<TaskHistory[]>(this.settings.baseUrl + 'api/leads/GetAllTaskHistoryByContact/' + contactid + '/' + type + '/' + timezone, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
  }
  GetTasksBycategory(ContAccId: number, type: string, typeCategory: string): Promise<TaskHistory[]> {
      const promise = new Promise<TaskHistory[]>((resolve, reject) => {
        this.httpclient.get<TaskHistory[]>(this.settings.baseUrl + 'api/leads/GetAllTasksBycategory/' + ContAccId + '/' + type + '/' + typeCategory, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
    }

  GetAllTaskHistory(term: string, userid: string, startdate?: string, enddate?: string, searchval?: string,tab?:string,timezone?:string): Promise<TaskHistory[]> {
      const promise = new Promise<TaskHistory[]>((resolve, reject) => {
        if (searchval == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
          searchval = localStorage.getItem('SearchCriteria');
          localStorage.setItem('SearchCriteria', null);
        }
        this.httpclient.get<TaskHistory[]>(this.settings.baseUrl + 'api/leads/GetAllTaskHistory/' + searchval + '/' + userid + '/' + startdate + '/' + enddate + '/' + term + '/' + tab + '/' + timezone, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
  }
  GetAllCustomerActivities(userid: number): Promise<TaskHistory[]> {
    const promise = new Promise<TaskHistory[]>((resolve, reject) => {
    
      this.httpclient.get<TaskHistory[]>(this.settings.baseUrl + 'api/leads/GetAllCustomerActivities/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  
  GetAllTaskHistoryForCalendar(term: string, userid: string, startdate?: string, enddate?: string, searchval?: string, tab?: string, timezone?: string): Promise<TaskHistory[]> {
      const promise = new Promise<TaskHistory[]>((resolve, reject) => {
        if (searchval == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
          searchval = localStorage.getItem('SearchCriteria');
          localStorage.setItem('SearchCriteria', null);
        }
        this.httpclient.get<TaskHistory[]>(this.settings.baseUrl + 'api/leads/GetAllTaskHistoryForCalendar/' + searchval + '/' + userid + '/' + startdate + '/' + enddate + '/' + term + '/' + tab + '/' + timezone, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
    }

  GetTomorrowActivityDetails(userid: string, source: string, month: string, selectyear:number): Promise<TaskHistory[]> {
    const promise = new Promise<TaskHistory[]>((resolve, reject) => {
      this.httpclient.get<TaskHistory[]>(this.settings.baseUrl + 'api/leads/GetTomorrowActivity/' + userid + '/' + source + '/' + month + '/' + selectyear, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  GetAllUserActivities(currentuserid:number,userlist: string, startdate: string, enddate: string, selectedType: string, startrow?: number, endrow?: number): Promise<TaskHistory[]> {
       
        const promise = new Promise<TaskHistory[]>((resolve, reject) => {
          this.httpclient.get<TaskHistory[]>(this.settings.baseUrl + 'api/leads/GetAllUserActivityTaskHistory/' + currentuserid + '/' + userlist + '/' + startdate + '/' + enddate + '/' + selectedType + '/' + startrow + '/' + endrow, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
    }
  GetAllUserActivitiesList(users: string, startdate: string, enddate: string): Promise<any>
  {
        const promise = new Promise<any>((resolve, reject) => {
          this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetUserActivitiesSummary/' + users + '/' + startdate + '/' + enddate, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
  }
  GetAllDropdownsList(type: string): Promise<any>
  {
     
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetDropdownsForType/' + type, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  GetAllActivityQuotaList(users: string,quotatype:string,selectedyear:string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetAllActivityQuotaList/' + users + '/' + quotatype + '/' + selectedyear, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

 
    addSaleProcessHistory(saleprocesshistory: SalesProcessHistory): Promise<SalesProcessHistory> {
        const promise = new Promise<SalesProcessHistory>((resolve, reject) => {

          this.httpclient.post<SalesProcessHistory>(this.settings.baseUrl + "api/leads/SaveSaleProcessHistory", saleprocesshistory, { headers: this.reqHeader }
             ).subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
    }
  GetByTaskHistoryId(id: number, Audittype:string,timezone:string): Promise<TaskHistory> {
      const promise = new Promise<TaskHistory>((resolve, reject) => {
        this.httpclient.get<TaskHistory>(this.settings.baseUrl + 'api/leads/GetByTaskHistoryId/' + id + '/' + Audittype + '/' + timezone, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
    }
    GetBySalesProcessHistoryById(id: number): Promise<SalesProcessHistory> {
      const promise = new Promise<SalesProcessHistory>((resolve, reject) => {
        this.httpclient.get<SalesProcessHistory>(this.settings.baseUrl + 'api/leads/GetBySaleProcessHistoryById/' + id, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
    }

    public GetAllTaskHistoryById(accountid: number, type: string): Promise<any> {
      
      const promise = new Promise<any>((resolve, reject) => {
        this.httpclient.get<any>(this.settings.baseUrl + 'api/leads/GetAllNotesByTaskId/' + accountid + '/' + type, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
            reject((ress) => {
              alert(ress);
            });
          });
      });
      return promise;
    }

    public GetAllStagenotesById(accountid: number, type1: string): Promise<any> {
        const promise = new Promise<any>((resolve, reject) => {
          this.httpclient.get<any>(this.settings.baseUrl + 'api/leads/GetAllNotesByLead/' + accountid + '/' + type1, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                    //reject((ress) => {
                    //    alert(ress);
                    //});
                });
        });
        return promise;
    }
   
    
    addTaskHistory(taskhistory: TaskHistory): Promise<TaskHistory> {

        const promise = new Promise<TaskHistory>((resolve, reject) => {
          this.httpclient.post<TaskHistory>(this.settings.baseUrl + "api/leads/SaveTaskHistory", taskhistory, { headers: this.reqHeader }).subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
    }
    
    public GetSalesStagesList(): Promise<Dropdowntype[]> {
      const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
        this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/leads/GetAllSalesStageList/', { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
    }
    

  public GetAllTasksList(type: string, stage: string, taskgroupid: number, status: number, configureduserid: number): Promise<Dropdowntype[]> {
    
      const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
        this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/leads/GetAllTasksList/' + type + '/' + stage + '/' + taskgroupid + '/' + status + '/' + configureduserid, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
  }

  public GetAllTaskList(id:number): Promise<TaskType[]> {

    const promise = new Promise<TaskType[]>((resolve, reject) => {
      this.httpclient.get<TaskType[]>(this.settings.baseUrl + 'api/leads/GetAllTaskList/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllQuestionsList(configureduserid: number, taskcategoryval:number,status:number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/leads/GetAllwizardQuestionsList/' + configureduserid + '/' + taskcategoryval + '/' + status, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllSUbCategoryQuestionsList(taskcategory,subcategory,userid): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/leads/GetAllwizardSubcategoryList/' + taskcategory + '/' + subcategory + '/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllSUbCategoryQuestionsList2(taskcategory, subtaskgroup1id,userid): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/leads/GetAllwizardSubcategoryList2/' + taskcategory + '/' + subtaskgroup1id + '/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllResponseListByQuestionId(QuestionId: number, LoggedUID: number): Promise<QuestionResponses[]> {
    const promise = new Promise<QuestionResponses[]>((resolve, reject) => {
      this.httpclient.get<QuestionResponses[]>(this.settings.baseUrl + 'api/leads/GetAllResponsesByQuestionId/' + QuestionId + '/' + LoggedUID, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetResponseByQuestionId(QuestionId: number, LoggedUID: number): Promise<QuestionResponses> {
    const promise = new Promise<QuestionResponses>((resolve, reject) => {
      this.httpclient.get<QuestionResponses>(this.settings.baseUrl + 'api/leads/GetLastResponseSelectedByQuestionId/' + QuestionId + '/' + LoggedUID, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  
  
  public GetLastResponseViewed(loggedinUserId: number): Promise<Lastresponseview> {
    const promise = new Promise<Lastresponseview>((resolve, reject) => {
      this.httpclient.get<Lastresponseview>(this.settings.baseUrl + 'api/leads/GetLastResonseByUser/' + loggedinUserId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  

  public GetAllLeadsList(type: string, userid: string, startdate?: string, enddate?: string, term?: string, startrow?: number, endrow?: number,currentuserid?:number): Promise<any> {
      
    const promise = new Promise<any>((resolve, reject) => {
      if (term == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        term = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<any>(this.settings.baseUrl + 'api/leads/GetAllLeads/' + type + '/' + userid + '/' + startdate + '/' + enddate + '/' + term + '/' + startrow + '/' + endrow + '/' + currentuserid, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
            reject((ress) => {
              alert(ress);
            });
          });
      });
      return promise;
    }
  public GetForecastingLeads(userid: string, startdate?: string, enddate?: string): Promise<any> {

    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/leads/GetForecastingLeads/' + userid + '/' + startdate + '/' + enddate, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }
  public GetLeadDetails(contactids:string): Promise<any> {

    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/leads/GetForecastingLeadsDetails/' + contactids, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }
  
    public getcompletedstages(contactid: number): Promise<any> {
      
      const promise = new Promise<any>((resolve, reject) => {
        this.httpclient.get<any>(this.settings.baseUrl + 'api/leads/GetCompletedStagesByContact/' + contactid, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
            reject((ress) => {
              alert(ress);
            });
          });
      });
      return promise;
    }

    public GetAllTaskHistoryBySelectedDate(term: string, userid: string,selecteddate:string): Promise<TaskHistory[]> {
        const promise = new Promise<TaskHistory[]>((resolve, reject) => {
          this.httpclient.get<TaskHistory[]>(this.settings.baseUrl + 'api/leads/GetAllTaskHistoryByselecteddate/' + term + '/' + userid + '/' + selecteddate, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
    }

  UpdateResonceByUser(loggedinUserId: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/leads/UpdateResponseData/' + loggedinUserId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  AddUpdateSalesStage(SalesStages: SalesStages): Promise<SalesStages> {
    const promise = new Promise<SalesStages>((resolve, reject) => {
      this.httpclient.post<SalesStages>(this.settings.baseUrl + "api/leads/AddUpdateSalesStage", SalesStages, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  AddUpdateCampaignType(CampaignType: CampaignType): Promise<CampaignType> {
    const promise = new Promise<CampaignType>((resolve, reject) => {
      this.httpclient.post<CampaignType>(this.settings.baseUrl + "api/campaign/AddUpdateCampaignType", CampaignType, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  AddUpdateCampaignExpenses(CampaignType: CampaignExpense): Promise<CampaignExpense> {
    const promise = new Promise<CampaignExpense>((resolve, reject) => {
      this.httpclient.post<CampaignExpense>(this.settings.baseUrl + "api/campaign/AddUpdateCampaignExpenses", CampaignType, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  GetCampaignTypes(createdby: number): Promise<CampaignType[]> {
    const promise = new Promise<CampaignType[]>((resolve, reject) => {
      this.httpclient.get<CampaignType[]>(this.settings.baseUrl + "api/campaign/GetCampaignTypes/" + createdby, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  GetCampaignExpenses(createdby: number): Promise<CampaignExpense[]> {
    const promise = new Promise<CampaignExpense[]>((resolve, reject) => {
      this.httpclient.get<CampaignExpense[]>(this.settings.baseUrl + "api/campaign/GetCampaignExpenses/" + createdby, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  SavestatusOrder(statustype: SystemRowConfiguration): Promise<SystemRowConfiguration> {
    const promise = new Promise<SystemRowConfiguration>((resolve, reject) => {
      this.httpclient.post<SystemRowConfiguration>(this.settings.baseUrl + "api/leads/AddUpdateStatusType", statustype, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAllSalesStage(userid: number): Promise<SalesStages[]> {
    const promise = new Promise<SalesStages[]>((resolve, reject) => {
      this.httpclient.get<SalesStages[]>(this.settings.baseUrl + 'api/leads/GetAllSalesStage/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetLinkedOpportunities(currentuserId: number, contactIds: string): Promise<any> {

    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/leads/GetLinkedOpportunities/' + currentuserId + '/' + contactIds, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }

  public GetRevenuedashboardData(currentuserid: string, fromDate: string, toDate: string, type:string): Promise<RevenueModel> {

    const promise = new Promise<RevenueModel>((resolve, reject) => {

      this.httpclient.get<RevenueModel>(this.settings.baseUrl + 'api/leads/getRevenuedashboardData/' + currentuserid + '/' + fromDate + '/' + toDate + '/' + type, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }
  public GetRevenueData(currentuserid: string, fromDate: string, toDate: string): Promise<RevenueModel> {

    const promise = new Promise<RevenueModel>((resolve, reject) => {

      this.httpclient.get<RevenueModel>(this.settings.baseUrl + 'api/leads/getRevenueData/' + currentuserid + '/' + fromDate + '/' + toDate, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }
}
