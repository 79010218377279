import { Component, OnInit, DoCheck, Input, Output, EventEmitter, ElementRef, Renderer2, ViewEncapsulation, ViewContainerRef, IterableDiffers, IterableDiffer, ViewChild } from '@angular/core';
import { CustomerService } from './../../services/customer.service';
import { LeadsService } from './../../services/leads.service';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { Contacts } from '../../models/contacts';
import { Accounts } from '../../models/accounts';
import { Notes } from '../../models/notes';
import { Viewaccount } from '../../models/viewaccount';
import { TaskHistory } from '../../models/task-history';
import { SalesProcessHistory } from '../../models/sales-process-history';
import { ResponsewizardComponent } from '../responsewizard/responsewizard.component';
import { DatePipe } from '@angular/common';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';;
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GridOptions, ColumnUtils } from "ag-grid/main";
export enum ParentComp { Leads }
import { ApplicationComponent } from '../application.component';
import { Column } from 'ag-grid';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { OwnershipsharingComponent } from '../ownershipsharing/ownershipsharing.component';
import { AccountDetailsComponent } from '../account-details/account-details.component';
declare var $: any;
declare var require: any;
import * as moment from 'moment'
import { AccountComponent } from '../account-contact/account/account.component';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {
  @Input()
  id: number;
  @Input()
  type: string;
  @Input()
  taskid: number;
  @Input()
  taskDialog: boolean = false;
  @Input()
  SearchDialog: boolean = false;
  @Input()
  BTClick: boolean = false;
  @Input()
  selectedDate: any;
  FeeAmountdiv: boolean = true;
  divone: boolean = true;
  tableDiv: boolean = true;
  labelDiv: boolean = true;
  labelButton: boolean = true;
  searchDiv: boolean = false;
  CloseWarning: boolean = false;
  datePipe: DatePipe = new DatePipe("en-US");
  taskhistorystatusval;
  contact: Contacts = new Contacts();
  account: Accounts = new Accounts();
  itemResource: any;
  timezonevalue: string;
  selectedscheduleTasktype: string;
  notehgt = screen.height * 0.1777;//0.16
  opphistorygrdhgt = screen.height * 0.4422;//0.4422
  ContactDivhgt = screen.height * 0.1666;
  customerDivhgt = screen.height * 0.15;
  calendernotehgt = screen.height * 0.25;
  contactDivHGT234 = screen.height * 0.2177;
  Wizardselectedval: boolean = false;
  //AptDialogwWarning: boolean = false;
  CustomeritemResource: any;
  items = [];
  itemCount = 0;
  params: any;
  ContactitemCount = 0;
  CustomeritemCount = 0;
  Contactitems = [];
  Customeritems = [];
  Salesstageslist: Dropdowntype[] = [];
  taskhistory: TaskHistory = new TaskHistory();
  saleprocesshistory: SalesProcessHistory = new SalesProcessHistory();
  SalesitemCount = 0;
  itemList = [];
  Salesitems = [];
  selectedItems = [];
  taskhistorynotes: Array<Notes> = [];
  notes: Notes = new Notes();
  TaskId = 0;
  TaskStatuslist: Dropdowntype[] = [];
  TaskStatussettings = {};
  Tasktypelist: Dropdowntype[] = [];
  Tasklist: Dropdowntype[] = [];
  currentuser: any;
  userprevilages: any;
  scheduleTaskType: string;
  selectedTaskType: string;
  shcedulestatus: string;
  fromtimevalid: boolean = true;
  totimevalid: boolean = true;
  temp: string;
  public elementRef;
  taskhistoryform: FormGroup;
  Scheduletasktaskhistory: TaskHistory[];
  public gridOptions: GridOptions;
  public gridOptionsacconts: GridOptions;
  public rowData: any[];
  public columnDefs: any[];
  public gridOptionscontacts: GridOptions;
  public columnDefscontacts: any[];
  public columnDefsaccount: any[];
  allcontacts: Contacts[];
  allCustomers: Accounts[];
  taskHistoryGridInitialized: boolean = false;
  isresponseWizardIcon: boolean = false;
  mainDivHeight = screen.height * 0.788888;
  divHeight = screen.height * 0.544444;
  @Input()
  selectorTag: boolean = false;
  public context;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  @Output()
  Taskdialoghideshow = new EventEmitter<boolean>();
  today = Date.now();
  contactList: Dropdowntype[] = [];
  @ViewChild(ResponsewizardComponent) responsewzdcomp;
  @ViewChild('mycal') myDiv: ElementRef<HTMLElement>;
  @ViewChild(AccountDetailsComponent) accdetailcmp;
  public sideBar;
  viewaccount: Viewaccount = new Viewaccount();
  action: string;
  showAddEditAccount: boolean = false;
  showAddEditContact: boolean = false;
  isSharing: boolean = false;
  @ViewChild(OwnershipsharingComponent) ownrshipComp: OwnershipsharingComponent;
  rightMainDivHideShow: boolean = true;
  accountSharingid: number = 0;
  AddContacttableDiv: boolean = false;
  isModal: boolean = true;
  notesshowDialog = false;
  conid: number;
  allnotes: Array<Notes> = [];
  public gridOptionsnotes: GridOptions;
  public columnDefsnotes: any[];
  NotesDec = false;
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  TimeSlots: Dropdowntype[] = [];
  TimeSlotsOriginal: Dropdowntype[] = [];
  TimeLines: Dropdowntype[] = [];
  TimeIntervals = [{ WeekDay: '', Date: '', Time: '', AMPM: '', CurrentTime: new Date(), Booked: false, SlotDuration: 0, BookingId: 0, hidden: false,To:'',ToAMPM:''}];
  TimeIntervalsOriginal = [{ WeekDay: '', Date: '', Time: '', AMPM: '', CurrentTime: new Date(), Booked: false, BookingId: 0, SlotDuration: 0, hidden: false, To: '', ToAMPM: ''}];
  DaysFilter = { Monday: true, Tuesday: true, Wednesday: true, Thursday: true, Friday: true, Saturday: false, Sunday: false };
  @ViewChild(AccountDetailsComponent) accdetailscmp;
  @ViewChild(AccountComponent) acccmp;
  TimeSlot = '60 Minutes'; Meridian = 0; TimeLine = 'Next Available'; OpenBookedTimes = 'Open/Booked Times';
  TimeSlotSaveConfirm: boolean = false;
  MappedTimeSlot: boolean = false;
  ShowSlotsDiv: boolean = false;
  additionalDays: number = 4;
  CloseCreateNewWarning: boolean = false;
  calanderonoff: boolean = false;
  FieldsModified: boolean = false;
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018,   today = Date.now();}
   constructor(private applicationComponent: ApplicationComponent, private route: ActivatedRoute, private opprtunityservice: OpprtunitiesService, private customerService: CustomerService, private leadservice: LeadsService, private router: Router, private location: Location, private viewContainerRef: ViewContainerRef, private toastr: ToastrService, myElement: ElementRef)
  {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    var loggedInUserId = this.currentuser.id;
    this.customerService.GetPrivilagesByuser(loggedInUserId).then(result => {
      this.userprevilages = result;
    });
    this.isresponseWizardIcon = false;
    if (!this.selectorTag) {
      this.route.params.subscribe(params => {
        this.id = params['id'];
        this.taskid = params['taskid'];
        this.type = params['type'];
        this.contact.id = this.id;
       
        this.HideAdnShow(this.id, this.type);
      });
    }
    this.elementRef = myElement;
  }
  handleClick(event) {
    this.FieldsModified = true;
    var clickedComponent = event.target;
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {

      $('#test').val('TaskHistory');
    } else {

    }
  }
  onTaskChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    this.ShowSlotsDiv = false;
    if (rowCount > 0) {
      var data = rowEvent.api.getSelectedNodes()[0].data;
      this.LoadSelectedTaskdata(data.id, rowEvent.api.getSelectedNodes()[0].selected, "Load");
      this.TaskId = data.id;
      this.checkedBookedSlots();
    } else {
      this.taskhistory = new TaskHistory();
     
      this.TaskId = 0;
      this.taskhistorynotes = [];
      $('#fromtime').val('');
      $('#totime').val('');
      $('#fromtime').prop('required', '');
      $('#totime').prop('required', '');
      document.getElementById('sid').innerHTML = "SCHEDULE TASK";
      if (this.type != 'Account') {
        let objSelf = this;
        $(setTimeout(function () {
          $('#ContactList').val(objSelf.id);

          objSelf.taskhistory.contactid = objSelf.id;
          if (objSelf.currentuser.SelectedTaskGroupids.length > 0) {
            var index = objSelf.currentuser.SelectedTaskGroupids.length - 1;
            objSelf.taskhistory.taskGroupID = objSelf.currentuser.SelectedTaskGroupids[index].id;
          } else {
            objSelf.taskhistory.taskGroupID = null;
          }
        }, 100));
      }
      this.checkedBookedSlots();
    }
    $('#notesTextArea').val('');

    var url = window.location.href;
    if (url.indexOf('month-calender') > -1) {
      document.getElementById('notesTextArea').style.height = '50%';
    }
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();
    config.maxlimit = 25;

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 34;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  createColumnDefs(columnSelections: string) {
    let selfobj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "undefined#") {
          this.columnDefs.push({
            headerName: '#',
            field: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({
            field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            }, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefs.push({ field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) { if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; } } });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "status") {

        }
        else if (fieldHeaderVisibleWidthArray[0] == "time") {
          this.columnDefs.push({
            field: 'starttime', headerName: 'Start Time', hide: false, cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            cellRenderer: function (params) {
              if (params.value == '00:00:00' || params.value == null) { return "" } else {
                return params.value;
              }
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "totime") {
          this.columnDefs.push({
            field: 'endtime', headerName: 'End Time', hide: false, cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            cellRenderer: function (params) {
              if (params.value == '00:00:00' || params.value == null) { return "" } else {
                return params.value;
              }
            }
          });

        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            },
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },

        {
          field: 'datecreated', headerName: 'Date Created', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'estcompleteddate', headerName: 'Date Scheduled', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'taskcategory', headerName: 'Task', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
          }
        },
        { field: 'status', headerName: 'Status', hide: false, cellStyle: function (params) { if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; } } },
        {
          field: 'accountname', headerName: 'Account Name', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'tasktype', headerName: 'Type', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'contactName', headerName: 'Contact Name', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'starttime', headerName: 'Start Time1', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'endtime', headerName: 'End Time', hide: true, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
          }
        },  //true starts here
        {
          field: 'owner', headerName: 'Owner', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
          }
        }
      ];
    }
  }
  onAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
      $(setTimeout(function () {
        selfObj.autoSizeAll();
      }, 100));
    }, 100));
  }
  autoSizeAll() {
 
  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 34).then((config) => {

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(columnSelections);

    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.taskHistoryGridInitialized = true;
  }
  rowClickAgGrid(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      this.LoadSelectedTaskdata(rowEvent.data.id, rowEvent.node.selected, "Load");
      this.TaskId = rowEvent.data.id;
    }
    else {
      this.taskhistory = new TaskHistory();
      this.TaskId = 0;
      this.taskhistorynotes = [];
      $('#fromtime').val('');
      $('#totime').val('');
      $('#fromtime').prop('required', '');
      $('#totime').prop('required', '');
      document.getElementById('sid').innerHTML = "SCHEDULE TASK";
      if (this.type != 'Account') {
        let objSelf = this;
        $(setTimeout(function () {
          $('#ContactList').val(objSelf.id);

          objSelf.taskhistory.contactid = objSelf.id;
          if (objSelf.currentuser.SelectedTaskGroupids.length > 0) {
            var index = objSelf.currentuser.SelectedTaskGroupids.length - 1;
            objSelf.taskhistory.taskGroupID = objSelf.currentuser.SelectedTaskGroupids[index].id;
          } else {
            objSelf.taskhistory.taskGroupID = null;
          }
        }, 100));
      }
    }
    $('#notesTextArea').val('');

    var url = window.location.href;
    if (url.indexOf('month-calender') > -1) {
      document.getElementById('notesTextArea').style.height = '50%';
    }

  }
  Display(accId, accountname, addr1, addr2, city, state, zip, country, phone, fax, website, Accountiddata,ownerName) {

    if (city != null) {
      var cityArray = city.split(' ');
      var newCity = '';
      for (var i = 0; i < cityArray.length; i++) {
        if (newCity == '') {
          newCity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
        else {
          newCity = newCity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
      }
    }
    if (website != null && website.indexOf('www.') >= 0) {
      website = ((website).split('www.')[1])
    } else if (website != null && website.indexOf('ww.') >= 0) {
      website = ((website).split('ww.')[1])
    }


    var properstate = (state != null) ? state.charAt(0).toUpperCase() + state.substr(1).toLowerCase() : "";
    this.viewaccount.accountId = accId;
    this.viewaccount.viewaccountname = accountname;
    this.viewaccount.viewaddress1 = addr1;
    this.viewaccount.viewaddress2 = addr2;
    this.viewaccount.viewcity = newCity == null ? "" : newCity;
    this.viewaccount.viewstate = properstate;
    this.viewaccount.viewzip = zip;
    this.viewaccount.viewCountry = country;
    this.viewaccount.viewphone = phone;
    this.viewaccount.viewfax = fax;
    this.viewaccount.viewwebsite = website;
    this.viewaccount.Accountiddata = Accountiddata;
    this.viewaccount.ownerName = ownerName;
    this.accdetailcmp.loadAccountId(accId);
    localStorage.setItem('TaskAccountVal', null);

  }
  loadcoantactname(val, type) {
    if (type == "Contacts") {
      this.customerService.LoadContactById(val).then(result => {
        this.customerService.loadaccountdata(result.accountid).then(result1 => {
          this.account = result1;
          this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.owner);
          this.customerService.GetAccountContactList(result1.id).then((conatct) => {
            this.contactList = conatct;
            let objSelf = this;
            $(setTimeout(function () {
              $('#ContactList').val(val);
              objSelf.taskhistory.contactid = val;
              if (objSelf.currentuser.SelectedTaskGroupids.length > 0) {
                var index = objSelf.currentuser.SelectedTaskGroupids.length - 1;
                objSelf.taskhistory.taskGroupID = objSelf.currentuser.SelectedTaskGroupids[index].id;
              } else {
                objSelf.taskhistory.taskGroupID = null;
              }

            }, 100));
          });

        });
      });

    } else if (type == "Customer" || type == "Accounts" ) {
      this.customerService.GetAccountContactList(val).then((conatct) => {
        this.contactList = conatct;

        let objSelf = this;
        $(setTimeout(function () {
          $('#ContactList').val(objSelf.contactList[0].id);
          objSelf.taskhistory.contactid = objSelf.contactList[0].id;
          if (objSelf.currentuser.SelectedTaskGroupids.length > 0) {
            var index = objSelf.currentuser.SelectedTaskGroupids.length - 1;
            objSelf.taskhistory.taskGroupID = objSelf.currentuser.SelectedTaskGroupids[index].id;
          } else {
            objSelf.taskhistory.taskGroupID = null;
          }
        }, 50));
      });

    } else {
      this.customerService.GetAccountContactList(this.account.id).then((conatct) => {
        this.contactList = conatct;

      });
    }
  }

  getTimeSlots() {
    this.customerService.GetSpecialityList("TimeSlots", this.currentuser.id).then((Rows) => {
      this.TimeSlots = Rows;
      this.TimeSlotsOriginal = Rows;
      if (this.selectedDate !== null && this.selectedDate !== undefined) {
        var selectedDate = new Date(this.datePipe.transform(this.selectedDate, 'MM-dd-yyyy hh:mm a'));
        var minutestoAdd = Number(this.TimeSlot.split(" ")[0]);

        var endTime = new Date(selectedDate.getTime() + minutestoAdd * 60000);
        let SelfObj = this;
        $(setTimeout(function () {
          SelfObj.checknextTimeSlot(selectedDate, endTime);
        }, 100));
      }


      this.customerService.GetSpecialityList("Time Line", this.currentuser.id).then((Rowss) => {
        this.TimeLines = Rowss;
        this.TimeLine = 'Next Available';
        this.Meridian = 0;
        this.OpenBookedTimes = 'Open/Booked Times';
      });
    });
  }

  ngOnInit() {
    var url = window.location.href;
    if (url.indexOf("month-calender") > -1) {
      this.opphistorygrdhgt = screen.height * 0.45111;
    }
  
    this.applicationComponent.isTaskDataLeadEventRaised = false;
    let localTaskId = 0;
    if (!this.selectorTag) {
      $("#sid").removeClass("activeColor");
      $(".checkMark").removeClass("activeColor");
      $(".subButtons").removeClass("activeButton");
    }

    if (this.selectorTag) {
      this.initData(this.id, this.type, this.taskid);
    }
    else {
      this.route.params.subscribe(params => {
        if (this.id == null) {
          this.id = params['id'];
        }
        if (this.taskid == null) {
          localTaskId = this.taskid = params['taskid'];
        }
        if (this.type == null) {
          this.type = params['type'];

        }
        this.TaskId = 0;
        this.HideAdnShow(this.id, this.type);
        this.LoadSelectedTaskdata(this.taskid, true, "View");
        this.contact.id = this.id;
        this.loadcoantactname(this.id, this.type);

      });

    }
    if (this.applicationComponent.selectedsubbutton == 'schedTask') {
      this.applicationComponent.pageViewed = "ScheduleTask";
    }
   
    this.taskhistoryform = new FormGroup({
      'tasktype': new FormControl(this.taskhistory.tasktype, Validators.required),
      'estcompleteddate': new FormControl(this.taskhistory.estcompleteddate, Validators.required),
      'time': new FormControl(this.taskhistory.time, (this.taskhistory.time != null) ? Validators.required : Validators.nullValidator),
      'totime': new FormControl(this.taskhistory.totime, Validators.nullValidator),
      'notedescription': new FormControl(this.taskhistory.notedescription, Validators.required)
    });
    this.TaskId = 0;
    //this.opprtunityservice.GetTaskStatus("TaskStatus", this.currentuser.id).then((pc) => {
    //  pc.forEach(pc => {
    //    if (pc.itemName == 'Complete & Return Task')
    //    { pc.itemName ='Complete'; }
    //  });
    //  this.TaskStatuslist = pc;
    //  this.TaskStatuslist = this.TaskStatuslist.filter(x => x.itemName != 'Complete & Repeat Task' && x.itemName != 'Complete & Schedule New Task' )
    
    //  //this.TaskStatuslist.forEach(Owner => {
    //  //});
    //});
    this.initializeAgGrid();
    if (this.taskDialog == false) {
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.gridOptions.api.forEachNode((node) => {
          if (node.data.id == localTaskId) {
            selfObj.gridOptions.api.selectNode(node, true);
          }
        });
      }, 500));

    }

    if (this.type == 'Customer' || this.type == "Accounts") {
      this.customerService.loadaccountdata(this.id).then(result1 => {
        this.account = result1;
        this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.owner);
      });
    } else {
      this.customerService.GetContactById(this.id).then(result1 => {
        this.contact = result1;
      });
    }

    if (this.BTClick) {
   
      this.ActiveTask('BlockTime', 0, 0);
    }
  }

  initData(contactId, Type, taskId) {
    this.HideAdnShow(contactId, Type);

    this.contact.id = contactId;
    //this.accdetailscmp.ContactInfo(contactId);
    if (this.taskDialog == false) {
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.gridOptions.api.forEachNode((node) => {
          if (node.data.id == taskId) {
            selfObj.gridOptions.api.selectNode(node, true);
            selfObj.TaskId = node.data.id;
          }
          selfObj.accdetailscmp.ContactInfo(contactId);
        });
      }, 100));
    }

  }
  //Search() {
  //  this.labelButton = true
  //  this.tableDiv = false
  //  this.labelDiv = true
  //  this.searchDiv = false
  //  var term = $.trim($('#term').val());
  //  if (term == null || term == undefined || term == "")
  //    return false;

  //  if (document.getElementById('customer').className == 'mybtn mainactiveButton') {
  //    document.getElementById('initialTable').style.display = 'none';
  //    document.getElementById('customerTable').style.display = 'block';
  //    this.opprtunityservice.GetAllAccountsBySearch(this.currentuser.id, term, this.currentuser.id, 'customer').then((account) => {
  //      account.forEach(account => {
  //      });
  //      //this.initializeAgGridaccount();
  //      this.allCustomers = account;
  //    });
  //  } else {
  //    document.getElementById('customerTable').style.display = 'none';
  //    document.getElementById('initialTable').style.display = 'block';
  //    this.opprtunityservice.GetAllContactsBySearch(term, this.currentuser.id, 'contacts').then((contact) => {
  //      contact.forEach(contact => {
  //      });
  //      //  this.initializeAgGridcontact();
  //      this.allcontacts = contact;
  //    });
  //  }

  //  $('#term').val('');
  //}

  ContactreloadItems(params) {
    if (this.itemResource != undefined) {
      this.itemResource.query(params).then(Contactitems => this.Contactitems = Contactitems);
    }
  }
  loadTaskHistory(contactid, type) {

    if (type != undefined && contactid != undefined) {
      this.id = contactid;
      this.type = type;
      this.taskhistory = new TaskHistory();     
      this.items = [];
      this.leadservice.GetAllTaskHistoryForCalendar(type, this.currentuser.id, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'null', type, this.currentuser.timezone).then
        ((taskhistory) => {
        taskhistory.forEach(taskhistory => {
          taskhistory.datecreated = this.datePipe.transform(taskhistory.datecreated, "MM-dd-yyyy");
          taskhistory.estcompleteddate = ((taskhistory.estcompleteddate == "0001-01-01") ? "" : this.datePipe.transform(taskhistory.estcompleteddate, "MM-dd-yyyy"));
        
        });
        this.Scheduletasktaskhistory = taskhistory;

        if (this.taskDialog == false) {
          let selfObj = this;
          $(setTimeout(function () {
            selfObj.gridOptions.api.forEachNode((node) => {
              if (node.data.id == selfObj.TaskId) {
                selfObj.gridOptions.api.selectNode(node, true);
              }
            });
          }, 500));
        }


      });
    }
  }

  ContactSelected() {
    //this.loadTaskHistory(this.taskhistory.contactid, this.type)
    //this.leadservice.GetAllTaskHistoryByContact(this.taskhistory.contactid, 'Contacts', this.currentuser.timezone).then((taskhistory) => {
    this.leadservice.GetAllTaskHistoryForCalendar(this.type, this.currentuser.id, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'null', this.type, this.currentuser.timezone).then
      ((taskhistory) => {
      taskhistory.forEach(taskhistory => {
        taskhistory.datecreated = this.datePipe.transform(taskhistory.datecreated, "MM-dd-yyyy");
        taskhistory.estcompleteddate = ((taskhistory.estcompleteddate == "0001-01-01") ? "" : this.datePipe.transform(taskhistory.estcompleteddate, "MM-dd-yyyy"));
      });
      this.Scheduletasktaskhistory = taskhistory;
      //this.type = 'Contacts';
      //this.contact.id = this.taskhistory.contactid;
      //this.loadcoantactname(this.taskhistory.contactid, this.type);
      this.accdetailscmp.ContactInfo(this.taskhistory.contactid);
      //this.Filters("", "");
    });
  }

  HideAdnShow(id, type) {
    if (id == 0 || id == '' || id == null) {
      this.divone = false;
      this.tableDiv = false;
      this.labelDiv = true;
      this.labelButton = false;
      this.searchDiv = false;
      this.tableDiv = false;
      if (!this.taskHistoryGridInitialized)
        this.initializeAgGrid();
    } else {
      this.divone = true;
      this.tableDiv = true;
      this.labelDiv = false;
      this.labelButton = false;
      this.searchDiv = true;
      this.tableDiv = true;
      this.loadcoantactname(id, type);
    
    }
    this.leadservice.GetSalesStagesList().then((accounttype) => {
      accounttype.forEach(accounttype => {


      });
      this.Salesstageslist = accounttype;
    });
    this.type = type;

    this.loadTaskHistory(id, this.type);
    this.leadservice.GetSalesStagesList().then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.itemList = accounttype;
    });
    this.getTimeSlots();
  }
  public convertDate(selectedTimezone: string): void {
    //this.date = moment(this.date).tz(selectedTimezone).format("DD/MM/YYYY - HH:mm");
  }

  SaveTaskHistory() {
    if (this.taskhistory.id == undefined) {//save
      if (this.userprevilages != undefined) {
        for (var j = 0; j < this.userprevilages.length; j++) {
          if ("Sched Task" == this.userprevilages[j].resource) {
            if (this.userprevilages[j].canadd == false) {
              this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
              return;
            }

          }
        }
      }
      document.getElementById('notesTextArea').style.height = '50%';
    }
    else {//edit
      if (this.userprevilages != undefined) {
        for (var j = 0; j < this.userprevilages.length; j++) {
          if ("Sched Task" == this.userprevilages[j].resource) {
            if (this.userprevilages[j].canedit == false) {
              this.toastr.warning("You Do Not have Access to update – Please check with CRM Administrator if Access is Required.", "Access Denied");
              return;
            }

          }
        }
      }
    }
    //this.taskhistory.contactid = this.contact.id;
    if (this.taskhistory.id > 0) {
      this.taskhistory.type = this.taskhistory.type;
      if (!this.ShowSlotsDiv && this.TaskId) {
       // this.taskhistory.status = document.getElementById('selectStatus').innerHTML;
      }

    } else {
      this.taskhistory.type = 'Contacts';
      this.taskhistory.status = 'Open';
    }

    this.taskhistory.userid = this.currentuser.id;
    if (this.account && this.account != null && this.account.ownerid != undefined && this.account.ownerid != null) {
      this.taskhistory.ownerid = this.account.ownerid;
    } else {
      this.taskhistory.ownerid = this.currentuser.id;
    }
    

    this.shcedulestatus = this.taskhistory.status;
    this.taskhistory.timezone = this.currentuser.timezone;
    this.taskhistory.tasktype = $("#sel1 option:selected").text()
    this.taskhistory.tasktypeid = $("#sel1 option:selected").val();
    var moment = require('moment-timezone');
    this.timezonevalue = moment.tz.guess();

    if (this.taskhistory.time != null && this.taskhistory.time != undefined && this.taskhistory.time != "") {
      this.taskhistory.starttime = moment(this.taskhistory.estcompleteddate).format("MM/DD/YYYY") + ' ' + this.taskhistory.time;
    }
    if (this.taskhistory.totime != null && this.taskhistory.totime != undefined && this.taskhistory.totime != "") {
      this.taskhistory.endtime = moment(this.taskhistory.estcompleteddate).format("MM/DD/YYYY") + ' ' + this.taskhistory.totime;
    }
    this.notes.notedescription = this.taskhistory.notedescription;
    this.leadservice.addTaskHistory(this.taskhistory).then(result => {

      this.taskhistorynotes = [];
      if (this.taskhistory.status == "Open" || this.taskhistory.status == "Not Completed") {
        this.sendMsgEvent.emit("Task Added");
      }

      this.HideAdnShow(this.id, this.type);
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.loadTaskHistory(this.id, this.type);
        selfObj.selectedscheduleTasktype = undefined;
        $("#test").val('');
      }, 200));
      this.notes.accountid = result.id;
      this.notes.type = "TaskHistory";
      this.notes.userid = this.currentuser.id;
      this.notes.isnewnote = 1;

      this.opprtunityservice.addopprtunityNotes(this.notes).then(result => {
        this.notes = result;
       
        this.taskhistory.notedescription = '';
        this.TimeSlotSaveConfirm = false;
        $('#notesTextArea').val('');
        $('#notesTextArea').attr('style', 'color:black;width:100%;resize:none;height:50%');
        this.toastr.success("Task saved successfully.", "Task Saved", { timeOut: 600 });
      });

      $('#notesTextArea').val("");
      $('#fromtime').val('');
      $('#totime').val('');
      $('#fromtime').prop('required', '');
      $('#totime').prop('required', '');
     
      //if (this.shcedulestatus == "Complete &amp; Repeat" || this.shcedulestatus == "Complete & Repeat") {
      //  this.ActiveTask(this.selectedscheduleTasktype, result.tasktypeid, result.id);
      //  $(setTimeout(function () {
      //    $("#sel1").val(result.tasktypeid);
      //  }, 100));
      //}
      //else
        if (this.shcedulestatus == "Completed") {
        var url = window.location.href;
        if (url.indexOf('tasklistdashboard') > -1) {
          this.applicationComponent.TaskData('Open', this.currentuser.id);
          this.applicationComponent.ExpandSelectedMenu('tasklists')
        } else {
          this.sendMsgEvent.emit("Task Added");
        }

      }

      var url = window.location.href;
      if (url.indexOf('reports/audit') > -1) {
        this.sendMsgEvent.emit("RefreshReportGrid");
      }
      this.selectedscheduleTasktype = undefined;
    });
    $('.changeActive').removeClass('noprovider');

    //document.getElementById('taskSave').style.display = 'block';
    $('#taskCancel').attr('class', 'col-xs-6');

    this.taskhistorynotes = [];
    document.getElementById('sid').innerHTML = "SCHEDULE TASKS";
    $('.selectedType').removeClass('activeButton')
    $('.selectedType').addClass('noprovider')
    this.divone = false;
    this.tableDiv = true
    this.labelDiv = false;
    this.labelButton = false
    this.tableDiv = true
    //this.labelDiv = true
    this.searchDiv = false

    this.Tasktypelist = [];

    this.selectedTaskType = "";
    if (!this.ShowSlotsDiv && this.TaskId) {
     // document.getElementById('selectStatus').innerHTML = 'Open';
    }

    this.TaskId = 0;


  }

  public loadtaskHistoryByselecteddate(selecteddate) {
    this.leadservice.GetAllTaskHistoryBySelectedDate('All', $("#userids").val().toString(), selecteddate).then((taskhistory) => {
      taskhistory.forEach(taskhistory => {
        taskhistory.datecreated = this.datePipe.transform(taskhistory.datecreated, "MM-dd-yyyy");
        taskhistory.estcompleteddate = this.datePipe.transform(taskhistory.estcompleteddate, "MM-dd-yyyy");
        taskhistory.DateSched = this.datePipe.transform(taskhistory.estcompleteddate, "MM-dd-yyyy");
      });
      this.Scheduletasktaskhistory = taskhistory;
    });

  }
  ngAfterViewChecked() {  
    $("#taskGroupID").val(1); this.taskhistory.taskGroupID = 1;
  }
  LoadSelectedTaskdata(taskhistoryid, selected: boolean, Audittype): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.taskhistory.id = taskhistoryid;
      $('#notesTextArea').attr('style', 'color:black;width:100%;resize:none;');
      if (!selected) {
        this.notes.notedescription = '';
        this.taskhistory.notedescription = '';
        $('#notesTextArea').val('');
        this.taskhistorynotes = [];
        this.taskhistory = new TaskHistory();
        this.Tasktypelist = [];
        this.notes.notedescription = '';
        this.taskhistory.notedescription = '';
        $('#sel1').val(0);
        $('#date').val('');
        $('#time').val('');
        $('.changeActive').removeClass('noprovider')

        $('.labelStatus').removeClass('redColor');
        $('.labelStatus > .spanStatus').removeClass('activeColor');
        document.getElementById('sid').innerHTML = "SCHEDULE  A TASK";
        document.getElementById('notesTextArea').style.color = 'black';
        var idStatus = 167;
        var rstatus = 'Open'
        var spanId = idStatus + '_span';
        $('#' + idStatus).addClass('redColor');
        $('#' + spanId).addClass('activeColor');

        if (!this.ShowSlotsDiv && this.TaskId) {
         // document.getElementById('selectStatus').innerHTML = rstatus;
        }

        //document.getElementById('selectStatus').innerHTML = rstatus;
        //document.getElementById('taskSave').style.display = 'block';
        this.scheduleTaskType = "";
        this.selectedTaskType = "";
        this.TaskId = 0;
      }
      else {
        if (taskhistoryid != null && taskhistoryid != "null" && taskhistoryid != 0) {
          $('#notesTextArea').attr('style', 'width:100%;resize:none;');
          this.TaskId = this.taskhistory.id
          this.opprtunityservice.GetTaskStatus("TaskStatus", this.currentuser.id).then((pc) => {
            pc.forEach(pc => {
              if (pc.itemName == 'Complete & Return Task') { pc.itemName = 'Completed'; }
            });
            this.TaskStatuslist = pc;
            this.TaskStatuslist = this.TaskStatuslist.filter(x => x.itemName != 'Complete & Repeat Task' && x.itemName != 'Complete & Schedule New Task')
          });
          this.TaskStatuslist = this.TaskStatuslist.filter(x => x.id != 169 && x.id != 505);
          this.TaskStatussettings = { singleSelection: true, text: "Select Task Type" };
        
          this.leadservice.GetByTaskHistoryId(taskhistoryid, Audittype, this.currentuser.timezone).then(result => {
            var type = result.taskcategory + " - " + result.tasktype;
            this.taskhistory = result;
            if (this.taskhistory.id > 0) {
              this.ShowSlotsDiv = false;
            }
            this.taskhistory.time = this.datePipe.transform(this.taskhistory.time, 'hh: mm a')
            this.taskhistory.totime = this.datePipe.transform(this.taskhistory.totime, 'hh: mm a')
            
            $('#fromtime').val(this.taskhistory.time);
            $('#totime').val(this.taskhistory.totime);
            document.getElementById('sid').innerHTML = (result.taskcategory == "Blocked Times") ?"EDIT BLOCKED TIME": "EDIT TASK";
            var typeArray = type.split("-");
            if (typeArray[0].trim() == 'Phone') {
              this.scheduleTaskType = 'phonecall';

              this.ActiveTask('phonecall', 0, taskhistoryid);

            } else if (typeArray[0].trim() == 'To Do') {
              this.scheduleTaskType = 'todo';
              this.ActiveTask('todo', 0, taskhistoryid);
            } else if (typeArray[0].trim() == 'Meeting'){
              this.scheduleTaskType = 'meeting';
              this.ActiveTask('meeting', 0, taskhistoryid)
            } else if (typeArray[0].trim() == 'Blocked Times') {
              this.scheduleTaskType = 'BlockTime';
              this.ActiveTask('BlockTime', 0, taskhistoryid)
            }

            this.taskhistory.tasktype = type;

            this.taskhistory.tasktypeid = result.tasktypeid;
            this.divone = true;
            this.tableDiv = true;
            this.labelDiv = false;
            this.labelButton = false;
            this.searchDiv = true;
            this.tableDiv = true;
            this.selectedTaskType = result.tasktype;
            this.taskhistory.estcompleteddate = ((this.taskhistory.estcompleteddate == "0001-01-01") ? "" : this.datePipe.transform(this.taskhistory.estcompleteddate, "yyyy-MM-dd"));
            this.leadservice.GetAllTaskHistoryById(taskhistoryid, 'TaskHistory').then((items) => {
              items.forEach(items => {
              });
              this.taskhistorynotes = items;
            
              if ((this.taskhistorynotes).length < 1) {
                $('#notesTextArea').attr('rows', '9')
              } else {
                $('#notesTextArea').attr('rows', '9')
              }
              var id = 0;
              var status = '';
              if (this.taskhistory.status == 'Open') {
                id = 167;
                status = 'Open'
              } else if (this.taskhistory.status == 'Completed') {
                id = 168;
                status = 'Completed'
              }
              else if (this.taskhistory.status == 'Not Completed') {
                id = 169;
                status = 'Not Completed'
              } else {

              }
              $('.spanStatus').removeClass('activeColor');
              $('.labelStatus').removeClass('redColor');
              var spanId = id + '_span';
              $('#' + id).addClass('redColor');
              $('#' + spanId).addClass('activeColor');
              if (!this.ShowSlotsDiv && this.TaskId) {
                //document.getElementById('selectStatus').innerHTML = status;
              }

              this.taskhistory.status = status;

            });

            if (this.taskDialog == false) {
              let selfObj = this;
              $(setTimeout(function () {
                selfObj.gridOptions.api.forEachNode((node) => {
                  if (node.data.id == taskhistoryid) {
                    selfObj.gridOptions.api.selectNode(node, true);
                    selfObj.TaskId = node.data.id;
                  }
                });
              }, 100));
            }
          });
        }
      }

    });
    return promise;

  }
  rowColors(car) {
    if (car.id == this.taskhistory.id) return 'rgb(255,255,0)';
  }
  completeForm() {
    var validate = 1;
    var fromTime = $('#fromtime').val();
    var toTime = $('#totime').val();
    //if (fromTime != '' && toTime != '') {
    //  //var fromTimeArray = fromTime.split(':')
    //  //var toTimeArray = toTime.split(':')

    //  var fromTimeArray = fromTime;
    //  var toTimeArray = toTime;

    //  if (fromTimeArray[0] < toTimeArray[0]) {
    //    validate++;
    //  } else if (fromTimeArray[0] == toTimeArray[0]) {
    //    if (fromTimeArray[1] < toTimeArray[1]) {
    //      validate++
    //    } else if (fromTimeArray[1] == toTimeArray[1]) {
    //      validate++
    //    } else {
    //      validate = 0;
    //    }
    //  } else {
    //    validate = 0;
    //  }
    //} else if (fromTime != '' || toTime != '') {
    //  validate++;
    //} else {
    //  validate++;
    //}
    if (validate > 0) {
      if ((this.taskhistory.tasktype == "" || this.taskhistory.estcompleteddate == 'undefined' || this.taskhistory.tasktype == undefined) && (($('#sel1').val() == "") || $('#sel1').val() == null) || $('#date').val() == null || $('#date').val() == "") {
        //document.getElementById('taskSave').style.display = 'block';
        $('#taskSave').attr('style', 'display:block;margin-top:10px');
      } else if ((this.taskhistory.tasktype == undefined && ($('#sel1').val() == "") || ($('#date').val() == null || $('#date').val() == ""))) {
        //document.getElementById('taskSave').style.display = 'block';
        $('#taskSave').attr('style', 'display:block;margin-top:10px');

        $('#taskCancel').attr('class', 'col-xs-6');
      } else {
        $('#taskCancel').attr('class', 'col-xs-6');
        //document.getElementById('taskSave').style.display = 'block';
        $('#taskSave').attr('style', 'display:block;margin-top:10px');
      }
    } else {
      this.toastr.warning("Invalid From And To Time");
    }

  }

  valuechange(newValue) {
    if (newValue != "") {
      $('#totime').removeClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
      $('#totime').addClass('ng-pristine ng-star-inserted ng-valid ng-touched');
      $('#totime').prop('required', true);
      !this.taskhistoryform.valid;
      this.taskhistoryform.controls["totime"].setValidators(Validators.required);
      this.taskhistoryform.controls["totime"].updateValueAndValidity();
      this.taskhistory.totime = newValue;
      this.totimevalid = true;
      // 111this.fromtimevalid = true;

    }
  }
  valuechangeFrom(newValue) {
    if (newValue != "") {
      $('#fromtime').removeClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
      $('#fromtime').addClass('ng-pristine ng-star-inserted ng-valid ng-touched');
      $('#fromtime').prop('required', true);
      // if (!this.totimevalid) {
      if (this.taskhistory.totime == undefined) {
        $('#totime').removeClass('ng-pristine ng-star-inserted ng-valid ng-touched');
        $('#totime').addClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
        $('#totime').prop('required', false);
      }
      // }
      this.fromtimevalid = true;
      $('#totime').removeAttr('disabled');

      this.taskhistory.time = newValue;
    }
  }

  cleartime() {
    $('#fromtime').prop('required', '');
    $('#totime').prop('required', '');
    this.taskhistory.totime = undefined;
    this.taskhistory.time = undefined;
    this.taskhistory.starttime = undefined;
    this.taskhistory.endtime = undefined;
    $('#fromtime').val('');
    $('#totime').val('');
    this.fromtimevalid = true;
    this.totimevalid = true;
    $('#totime').removeClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
    $('#fromtime').removeClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
  }
  ActiveTask(id, tasktypeid, taskhistoryid) {
  
    this.Wizardselectedval = false;
    this.temp = id;
    $(".changeActive").removeClass("noprovider");

    if (id == '') {
      //document.getElementById('sid').innerHTML = "Schedule Task";
    }
    if (id == 'meeting') {

      $("#" + id).addClass("noprovider");
      $('#sel1 > option:first-child').text('Select Meeting List');
    } else if (id == 'phonecall') {

      $("#" + id).addClass("noprovider");
      $('#sel1 > option:first-child').text('Select Phone Call List');
    } else if (id == 'todo') {

      $("#" + id).addClass("noprovider");
      $('#sel1 > option:first-child').text('Select To Do List');

    } else if (id == 'BlockTime') {

      $("#" + id).addClass("noprovider");
      $('#sel1 > option:first-child').text('Select Blocked Time List');

    }
    $('.spanStatus').removeClass('activeColor');
    $('.labelStatus').removeClass('redColor');
    var idStatus = 167;
    var status = 'Open'
    if (this.TaskId == 0) {
      var spanId = idStatus + '_span';
      $('#' + idStatus).addClass('redColor');
      $('#' + spanId).addClass('activeColor');
      if (!this.ShowSlotsDiv && this.TaskId) {
        //document.getElementById('selectStatus').innerHTML = status;
      }

    }
    var taskGroupId = 0;
    if (this.taskhistory.taskGroupID) {
      taskGroupId = this.taskhistory.taskGroupID;
    } else {
      taskGroupId = this.currentuser.TaskGroupid.split(",")[0];
    }

    this.leadservice.GetAllTasksList(id, this.saleprocesshistory.salesstage, taskGroupId, 0, this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });

      this.Tasktypelist = accounttype;
      let selfObj = this;
      $(setTimeout(function () {
        if (tasktypeid != 0 && tasktypeid != undefined) {
          selfObj.taskhistory.tasktypeid = tasktypeid;
        }
        else {
          if (taskhistoryid == 0 || taskhistoryid == undefined) {
            selfObj.taskhistory.tasktype = accounttype[0].itemName;
            selfObj.taskhistory.tasktypeid = accounttype[0].id;
          }

        }
        selfObj.selectedscheduleTasktype = selfObj.temp;
        selfObj.ChooseTaskType(selfObj.taskhistory.tasktypeid);
      }, 500));

    });


  }
  selectedStatus(event) {
    if (event.target.value != "Open" && (!this.TaskId || this.TaskId == 0)) {
      return false;
    }

    if ($('#sel1').val() != null) {
     // var id = status.id;
      this.saleprocesshistory.salesstage = event.target.value;
      this.taskhistory.status = event.target.value;     
      //var spanId = status.id + '_span';
      //$('.spanStatus').removeClass('activeColor');
      //$('.labelStatus').removeClass('redColor');

      //setTimeout(function () {
      //  $('.labelStatus').removeAttr('style');
      //}, 3000);
      //$('.labelStatus').attr('style', 'cursor:pointer');

      //$('#' + id).addClass('redColor');
      //$('#' + spanId).addClass('activeColor');

      if (!this.ShowSlotsDiv && this.TaskId) {
        //document.getElementById('selectStatus').innerHTML = status.itemName;
      }

      this.completeForm();
    }
  }

  AccountContact(type) {

    this.type = type;
    document.getElementById('customerTable').style.display = 'none';
    document.getElementById('initialTable').style.display = 'none';
    if (type == 'customer' || type == "Accounts") {
      var url = window.location.href;
      if (url.indexOf('tasklist') < 0) {
      }
      else {
        this.router.navigateByUrl(`/application/tasklistdashboard/Customer/0/null`);
      }

      $("#contacts_span").removeClass("activeColor");
      $("#contacts").removeClass("mainactiveButton");
      $("#contacts").addClass("noprovider");
      $("#customer").removeClass("noprovider");
      $("#customer_span").addClass("activeColor");
      $("#customer").addClass("mainactiveButton");
    } else {
      var url = window.location.href;
      if (url.indexOf('tasklist') < 0) {
      }
      else {
        this.router.navigateByUrl(`/application/tasklistdashboard/Contacts/0/null`);
      }
      $("#customer_span").removeClass("activeColor");
      $("#customer").removeClass("mainactiveButton");
      $("#customer").addClass("noprovider");
      $("#contacts").removeClass("noprovider");
      //$("#contacts_span").addClass("activeColor");
      //$("#contacts").addClass("mainactiveButton");
    }
  }
  reloadPrevious() {
    this.location.back();
  }
  reloadSched() {
    var url = window.location.href;
    if (url.indexOf("month-calender") > -1) {
      this.sendMsgEvent.emit("Cancel");
    } else {
      this.applicationComponent.TaskData('Open', this.currentuser.id)
    }

  }

  rowDoubleClickAgGrid(rowEvent) { }
  onSearchstring(msg) {
    this.taskhistorynotes = [];
    this.sendMsgEvent.emit(msg);
  }
  onWizardAdded(msg) {
    $(setTimeout(function () {
      $("#responsewizard").trigger("click");
    }, 200));
  }

  //Wizardselected() {
  //   
  //  if (this.Wizardselectedval == true) {
  //     
  //    this.Wizardselectedval = false;
  //    //this.leadservice.UpdateResonceByUser(this.currentuser.id).then((response) => {
  //    //});
  //    $("#responsewizard").removeClass("GreenColor");

  //  }
  //  else {
  //    this.Wizardselectedval = true
  //    $("#responsewizard").addClass("GreenColor");
  //    $('.activeButton').removeClass('activeButton');
  //    $('#Opentask').addClass('activeButton');
  //    this.responsewzdcomp.GetLastResponse();
  //  }

  //}



  getStartTime(Event) {
    this.taskhistory.time = Event;
    this.validFrom();

    var Roundtime = this.convertTime12to24(Event);
    var NextTime = this.roundTime(Roundtime, 60);
    this.taskhistory.totime = this.convertFrom24To12Format(NextTime);
    this.validTo();
  }

  convertTime12to24(time12h) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  convertFrom24To12Format = (time24) => {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? 'AM' : 'PM';
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  }

  roundTime(time, minutesToRound) {
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    // Convert hours and minutes to time in minutes
    if (minutes == 0) {
      time = (hours * 60) + 60;
    } else {
      time = (hours * 60) + minutes;
    }


    let rounded = Math.ceil(time / minutesToRound) * minutesToRound;
    let rHr = '' + (rounded / 60)
    let rMin = '' + rounded % 60

    return rHr.padStart(2, '0') + ':' + rMin.padStart(2, '0')
  }


  getEndTime(Event) {
    this.taskhistory.totime = Event;
    this.validTo();
  }
  validFrom() {

    if (this.taskhistory.time == "Invalid DateTime") {
      $('#fromtime').addClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
      this.taskhistoryform.controls["time"].setValidators(Validators.required);
      this.taskhistoryform.controls["time"].updateValueAndValidity();
      if (this.taskhistory.totime == undefined || this.taskhistory.totime == "Invalid DateTime") {
        $('#totime').addClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
        this.taskhistoryform.controls["totime"].setValidators(Validators.required);
        this.taskhistoryform.controls["totime"].updateValueAndValidity();
        this.totimevalid = false;
      } else {
        this.totimevalid = true;
      }
    }
    else {
      this.valuechangeFrom(this.taskhistory.time);
      if (this.taskhistory.totime == undefined || this.taskhistory.totime == "Invalid DateTime") {
        $('#totime').addClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
        this.taskhistoryform.controls["totime"].setValidators(Validators.required);
        this.taskhistoryform.controls["totime"].updateValueAndValidity();
        this.totimevalid = false;
      } else {
        this.totimevalid = true;
      }
      //this.fromtimevalid = false;
    }
  }

  validTo() {

    if (this.taskhistory.totime == "Invalid DateTime") {
      $('#totime').addClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
      this.taskhistoryform.controls["totime"].setValidators(Validators.required);
      this.taskhistoryform.controls["totime"].updateValueAndValidity();
      if (this.taskhistory.time == undefined || this.taskhistory.time == "Invalid DateTime") {
        $('#fromtime').addClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
        this.taskhistoryform.controls["time"].setValidators(Validators.required);
        this.taskhistoryform.controls["time"].updateValueAndValidity();
        this.totimevalid = false;
      }
    }
    else {
      this.valuechange(this.taskhistory.totime);
      if (this.taskhistory.time == undefined || this.taskhistory.time == "Invalid DateTime") {
        $('#fromtime').addClass('ng-pristine ng-star-inserted ng-invalid ng-touched');
        this.taskhistoryform.controls["time"].setValidators(Validators.required);
        this.taskhistoryform.controls["time"].updateValueAndValidity();
        this.totimevalid = false;
      }
      //this.fromtimevalid = false;
    }
  }

  addContact() {
    //if (this.practiceid == "view") {
    //  this.applicationComponent.isAccessDenied = true;
    //} else {
    if (this.userprevilages != undefined) {
      for (var j = 0; j < this.userprevilages.length; j++) {
        if ("Contacts" == this.userprevilages[j].resource) {
          if (this.userprevilages[j].canadd == false) {
            this.toastr.clear();
            this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }
        }
      }
    }
    this.showAddEditContact = true;
    this.showAddEditContactCss();
    //}
  }
  AccountsharingAg(selectedval) {


    if (selectedval == "Edit") {
      this.isSharing = false;
      this.action = selectedval;
      this.showAddEditAccount = true;
      this.showAddEditAccountCss();
    }
    else if (selectedval == "View") {
      this.isSharing = false;
      this.action = selectedval;
      this.showAddEditAccount = true;
      this.showAddEditAccountCss();
    }
    else {
      this.isSharing = true;
      this.rightMainDivHideShow = false;
      this.accountSharingid = 1;
      this.AddContacttableDiv = false;
      this.ownrshipComp.loadAccountInformation(this.account.id, 0);
    }
    //}

  }
  showAddEditAccountCss() {
    setTimeout(function () {
      $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  showAddEditContactCss() {
    setTimeout(function () {
      $('#addEditContact .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  GetCustomerNotes(contactid, type) {
    this.customerService.GetAllNotesList(this.currentuser.id, contactid, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.initializeAgGridNotes();
      this.allnotes = taskhistory;
    });

  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }
  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.createColumnDefsnotes("");
    this.gridOptionsnotes.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  createColumnDefsnotes(columnSelections: string) {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellStyle: { 'text-align': 'left' }, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];
  }
  openDisplaynotes(params) {
    $('#closeDetailsDialog').click();

    // this.notes.notedescription = params.value;
    this.NotesDec = true;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    var notesOffset = $("#divNotesContainer").offset();
    let notesWidth = $('#divNotesContainer').width();
    let notesHeight = $('#divNotesContainer').height();
    let windowWidth = $(window).width();
    let showDialogWidth = 350;

    let selfObj = this;
    $(setTimeout(function () {
      if (selfObj.isModal) {

        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '81%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '14%');
        } else {
          $('#txtLongNoteDesc').css('height', '96%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtLongNoteDesc').css('overflow', 'auto');
        }
      } else {
        var gridOffset = $("#notesGrid").offset();

        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '53%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '33%');
        } else {
          $('#txtLongNoteDesc').css('height', '89%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      }
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));
  }
  onCloseDialogEventReceived(val) {
    this.saleprocesshistory.notesDescription = null;
  }
  openAccountNotes() {
    this.isModal = true;
    this.customerService.GetByAccountId(this.contact.accountid).then(result => {
      this.account = result;
    });
    $('#accotIdNotes').val(this.contact.accountid);
    $('#accotTypeNotes').val('Customer');
    this.GetCustomerNotes(this.contact.accountid, 'Customer');
    this.notesshowDialog = !this.notesshowDialog;
    this.NotesDialogCss();
    this.conid = this.contact.accountid;
  }
  ReceiveEvent(msg) {
    if (msg == "ExpandSoftwareLicense" || msg == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(msg);
    } else {
    }
  }
  ExpandAddEditAccountDialog(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 100%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 86%;padding: 0;top: 10%;left: 10%;max-width: 88%;');
      }, 4)
    } else {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
      }, 4)
    }
  }



  ContactReceiveEvent(msg) {
    this.showAddEditContact = false;
    //this.GetAllProviderAndContactInfo(this.account.id)
  }

  btnClickReceived(event) {
    if (event.btnName == 'Calander') { this.CloseCreateTicket(event.btnName); }
    else if (event.btnName == 'addContact') {
      this.addContact();
    }
    else if (event.btnName == 'Accountsharing') {
      this.AccountsharingAg(event.val);
    }
    else if (event.btnName == 'openNotes') {
      this.openAccountNotes();
    } else if (event.btnName == 'AccountInfo') {
      if (this.taskhistory.id == null || this.taskhistory.id == 0 || this.taskhistory.id == undefined || this.FieldsModified == true) {
        this.CloseCreateNewWarning = true;
        setTimeout(function () {
          $('#CloseCreateNewWarning .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
         // $('#CloseCreateNewWarning .dialog').attr('style', 'width: 29%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
        }, 4)
      } else {
        this.contactInfo();
      }
    }
  }


  contactInfo() {
    localStorage.setItem('RhxContactVal', (this.taskhistory.contactid).toString());

    let SO = this;
    $(setTimeout(function () {
      SO.applicationComponent.activateSubButtons('empty');
      $(setTimeout(function () {
        SO.router.navigateByUrl(`/application/contacts/contacts/null`);
        SO.applicationComponent.ExpandSelectedMenu('contact');
        SO.applicationComponent.selectedtabid = 'contact';
      }, 100));
    }, 50));
  }

  TaskGroupChanged(event) {
    this.taskhistory = new TaskHistory();
    this.TaskId = 0;
    this.taskhistorynotes = [];
    this.taskhistory.taskGroupID = event.target.value;
    this.taskhistory.contactid = this.contact.id;
    $(".changeActive").removeClass("noprovider");
  }

  getTimeIntervals(StartTime, EndDate, Duration) {
    var DateIntervals = [{ WeekDay: '', Date: '', Time: '', AMPM: '', CurrentTime: new Date(), Booked: false, SlotDuration: 0, BookingId: 0, hidden: false, To: '', ToAMPM:'' }];
    var Start;
    if (this.selectedDate) {
      Start = new Date(StartTime);
    } else {
      Start = new Date(StartTime.setHours(6, 0, 0, 0));
    }
    
    var End = new Date(EndDate.setHours(22, 0, 0, 0));

    if ((new Date < Start)) {
      DateIntervals = [{
        WeekDay: this.getweek(new Date(Start).getDay()),
        Date: this.datePipe.transform(Start, 'MM-dd-yyyy'),
        Time: this.datePipe.transform(Start, 'hh: mm'),
        AMPM: this.datePipe.transform(Start, 'a'),
        CurrentTime: Start,
        Booked: false, SlotDuration: Number(this.TimeSlot.split(" ")[0]),
        BookingId: 0, hidden: false,
        To: this.datePipe.transform(new Date(new Date(Start).getTime() + Duration * 60000), 'hh: mm'),
        ToAMPM: this.datePipe.transform(new Date(new Date(Start).getTime() + Duration * 60000), 'a')
      }];
    } else {
      DateIntervals = [];
    }

    while ((Start < End)) {
      Start = new Date(new Date(Start).getTime() + Duration * 60000);
      if (this.CheckFilterDays(Start) && (new Date(Start).getHours() >= 6 && new Date(Start).getHours() <= 22) && (new Date < Start)) {
        DateIntervals.push({
          WeekDay: this.getweek(new Date(Start).getDay()),
          Date: this.datePipe.transform(Start, 'MM-dd-yyyy'),
          Time: this.datePipe.transform(Start, 'hh: mm'),
          AMPM: this.datePipe.transform(Start, 'a'),
          CurrentTime: Start, hidden: false,
          Booked: false, SlotDuration: Number(this.TimeSlot.split(" ")[0]), BookingId: 0,
          To: this.datePipe.transform(new Date(new Date(Start).getTime() + Duration * 60000), 'hh: mm'),
          ToAMPM: this.datePipe.transform(new Date(new Date(Start).getTime() + Duration * 60000), 'a')
        });
      } 

    }
    return DateIntervals;

  }
  getweek(val) {
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

    return weekday[val];
  }
  CheckFilterDays(Start: Date) {

    if (new Date(Start).getDay() == 1 && this.DaysFilter.Monday == true) {
      return true;
    } else if (new Date(Start).getDay() == 2 && this.DaysFilter.Tuesday == true) {
      return true;
    } else if (new Date(Start).getDay() == 3 && this.DaysFilter.Wednesday == true) {
      return true;
    } else if (new Date(Start).getDay() == 4 && this.DaysFilter.Thursday == true) {
      return true;
    } else if (new Date(Start).getDay() == 5 && this.DaysFilter.Friday == true) {
      return true;
    } else if (new Date(Start).getDay() == 6 && this.DaysFilter.Saturday == true) {
      return true;
    } else if (new Date(Start).getDay() == 0 && this.DaysFilter.Sunday == true) {
      return true;
    } else {
      return false;
    }
  }
  Filters(event, value) {
    if (this.selectedDate == null || this.selectedDate == undefined) {
      this.getIntervels(new Date(), this.additionalDays);
    } else {
      this.getSelectedIntervels(this.selectedDate);
    }

  }

  LoadMore(selectedDate) {
    this.getIntervels(new Date(selectedDate) , this.additionalDays);
  }
  getIntervels(StartDate: Date, additionalDate: number) {
    switch (this.TimeLine) {
      case '1 Week': {
        StartDate.setDate(StartDate.getDate() + 7);
        break;
      }
      case '2 Weeks': {
        StartDate.setDate(StartDate.getDate() + 14);
        break;
      }
      case '3 Weeks': {
        StartDate.setDate(StartDate.getDate() + 21);
        break;
      }
      case '1 Month': {
        StartDate = new Date(StartDate.setMonth(StartDate.getMonth() + 1));
        break;
      }
      case '2 Months': {
        StartDate = new Date(StartDate.setMonth(StartDate.getMonth() + 2));
        break;
      }
      case '3 Months': {
        StartDate = new Date(StartDate.setMonth(StartDate.getMonth() + 3));
        break;
      }
      case '6 Months': {
        StartDate = new Date(StartDate.setMonth(StartDate.getMonth() + 6));
        break;
      } case '12 Months': {
        StartDate = new Date(StartDate.setFullYear(StartDate.getFullYear() + 1));
        break;
      }
      case 'Next Available': {
        StartDate.setDate(StartDate.getDate());
        break;
      }
      default: {
        StartDate.setDate(StartDate.getDate() + 7);
        break;
      }
    }

    if (StartDate.getDay() == 6) {

      StartDate.setDate(StartDate.getDate() + 2);
    } else if (StartDate.getDay() == 0) {
      StartDate.setDate(StartDate.getDate() + 1);
    }
    var count = 0;
    var EndDate = new Date(this.datePipe.transform(StartDate));
    var statDate = new Date(this.datePipe.transform(StartDate));


    //if (this.selectedDate !== null && this.selectedDate !== undefined) {
    //  EndDate = new Date(this.datePipe.transform(this.selectedDate, 'MM-dd-yyyy hh:mm a'));
    //} else {
      while (count < additionalDate) {
        EndDate = new Date(statDate.setDate(statDate.getDate() + 1));
        if (this.CheckFilterDays(EndDate)) {
          count++;
        }

      }
    //}
    this.TimeIntervalsOriginal = this.TimeIntervals = this.getTimeIntervals(StartDate, EndDate, this.TimeSlot.split(" ")[0]); //Tom Wants 15mins intervals by default
    this.checkedBookedSlots();

  }

  checkedBookedSlots() {
    var moment = require('moment-timezone');
    //if (this.TaskId > 0) {

    //  var res = this.Scheduletasktaskhistory.filter(x => x.id == this.TaskId)[0];
    //  var Mins = moment.duration(moment(res.endtime, "HH:mm:ss a").diff(moment(res.starttime, "HH:mm:ss a")));

    //  //var minutes = (parseInt(Mins.asHours()) * 60) + parseInt(Mins.asMinutes());
    //  var minutes = parseInt(Mins.asMinutes());
    //    this.assignTimeSlot(minutes);

    //  this.TimeIntervalsOriginal.forEach(elem => {
    //    if ((elem.Date == res.estcompleteddate) && (this.datePipe.transform(elem.CurrentTime, 'hh:mm:ss a') == res.starttime) && (res.id == this.TaskId) && (minutes == elem.SlotDuration)) {

    //      var currenttime = (this.datePipe.transform(elem.CurrentTime, 'hh:mm:ss a'));
    //      var CurrentEndTime = this.datePipe.transform(elem.CurrentTime.getTime() + elem.SlotDuration * 60000, 'hh:mm:ss a');
    //      if (currenttime == res.starttime || (res.starttime > currenttime && res.starttime < CurrentEndTime) || (res.endtime > currenttime && res.endtime < CurrentEndTime)) {
    //        elem.Booked = true;
    //        elem.BookingId = res.id;
    //      }
    //    } else {
    //      elem.Booked = false;
    //      elem.BookingId = 0;
    //    }
    //  });



    //} else {

    if (this.TaskId > 0) {
      var res = this.Scheduletasktaskhistory.filter(x => x.id == this.TaskId)[0];
      var Mins = moment.duration(moment(res.endtime, "HH:mm:ss a").diff(moment(res.starttime, "HH:mm:ss a")));

      //var minutes = (parseInt(Mins.asHours()) * 60) + parseInt(Mins.asMinutes());
      var minutes = parseInt(Mins.asMinutes());
      this.assignTimeSlot(minutes);

    }
    if (this.Scheduletasktaskhistory) {
      this.Scheduletasktaskhistory.forEach(ele => {
        var Mins = moment.duration(moment(ele.endtime, "HH:mm:ss a").diff(moment(ele.starttime, "HH:mm:ss a")));

        var minutes = parseInt(Mins.asMinutes());
        this.TimeIntervalsOriginal.filter(x => (x.Date == ele.estcompleteddate) && (this.datePipe.transform(x.CurrentTime, 'hh:mm:ss a') == ele.starttime));
        this.TimeIntervalsOriginal.forEach(elem => {
          if ((elem.Date == ele.estcompleteddate)) {
            var currenttime = (this.datePipe.transform(elem.CurrentTime, 'hh:mm:ss a'));
            var CurrentEndTime = this.datePipe.transform(elem.CurrentTime.getTime() + elem.SlotDuration * 60000, 'hh:mm:ss a');
            //if (currenttime == ele.starttime || (ele.starttime > currenttime && ele.starttime < CurrentEndTime) || (ele.endtime > currenttime && ele.endtime < CurrentEndTime)) {
            if (currenttime == ele.starttime || (CurrentEndTime > ele.starttime && CurrentEndTime < ele.endtime && CurrentEndTime < ele.starttime) || (currenttime > ele.starttime && currenttime < ele.endtime)) {
              elem.Booked = true;
              elem.BookingId = ele.id;
            }

          }
        });
      });
    }
    //else {
    //  this.TimeIntervalsOriginal.forEach(elem => {
    //    if ((elem.Date == res.estcompleteddate) && (this.datePipe.transform(elem.CurrentTime, 'hh:mm:ss a') == res.starttime) && (res.id == this.TaskId)) {

    //      var currenttime = (this.datePipe.transform(elem.CurrentTime, 'hh:mm:ss a'));
    //      var CurrentEndTime = this.datePipe.transform(elem.CurrentTime.getTime() + elem.SlotDuration * 60000, 'hh:mm:ss a');
    //      if (currenttime == res.starttime || (res.starttime > currenttime && res.starttime < CurrentEndTime) || (res.endtime > currenttime && res.endtime < CurrentEndTime)) {
    //        elem.Booked = true;
    //        elem.BookingId = res.id;
    //      }
    //    } else {
    //      elem.Booked = false;
    //      elem.BookingId = 0;
    //    }
    //  });
    //}

    //}


    if (this.OpenBookedTimes == 'Open Times') {
      this.TimeIntervals = this.TimeIntervalsOriginal.filter(x => x.Booked == false && ((this.Meridian == 1) ? x.AMPM == 'AM' : ((this.Meridian == 2) ? x.AMPM == 'PM' : x.AMPM != '')));
    } else if (this.OpenBookedTimes == 'Booked Times') {
      this.TimeIntervals = this.TimeIntervalsOriginal.filter(x => x.Booked == true && ((this.Meridian == 1) ? x.AMPM == 'AM' : ((this.Meridian == 2) ? x.AMPM == 'PM' : x.AMPM != '')));
    } else {
      this.TimeIntervals = this.TimeIntervalsOriginal.filter(x => ((this.Meridian == 1) ? x.AMPM == 'AM' : ((this.Meridian == 2) ? x.AMPM == 'PM' : x.AMPM != '')));
    }
    let that = this;
    this.TimeIntervals.forEach(ele => {
      var index = this.TimeIntervals.indexOf(ele);
      var bookedindes = this.TimeIntervals.findIndex(function (element) {
        return (element.Booked == true) && (element.BookingId != null) && (that.TimeIntervals.indexOf(element) > index);
      });
      var res = this.checkAvailableTime(ele, this.TimeIntervals[bookedindes]);
      ele.hidden = res;

    });

  }

  checkAvailableTime(starttime, nextslot) {
    var moment = require('moment-timezone');
    if (nextslot && nextslot.Booked == true) {
      var difference = moment.duration(moment(nextslot.CurrentTime).diff(moment(starttime.CurrentTime)));

      if (difference.asMinutes() < this.TimeSlot.split(" ")[0]) {
        return true
      } else {
        return false;
      }
    } else {
      return false;
    }


  }


  assignTimeSlot(value) {
    switch (value) {
      case 5: {
        this.TimeSlot = '05 Minutes';
        break;
      }
      case 15: {
        this.TimeSlot = '15 Minutes';
        break;
      }
      case 30: {
        this.TimeSlot = '30 Minutes';
        break;
      }
      case 45: {
        this.TimeSlot = '45 Minutes';
        break;
      }
      case 60: {
        this.TimeSlot = '60 Minutes';
        break;
      } case 90: {
        this.TimeSlot = '90 Minutes';
        break;
      }
      case 120: {
        this.TimeSlot = '120 Minutes';
        break;
      }
      case 180: {
        this.TimeSlot = '180 Minutes';
        break;
      }
      case 240: {
        this.TimeSlot = '240 Minutes';
        break;
      }
      case 300: {
        this.TimeSlot = '300 Minutes';
        break;
      }
      case 360: {
        this.TimeSlot = '360 Minutes';
        break;
      }
      case 420: {
        this.TimeSlot = '420 Minutes';
        break;
      }
      case 480: {
        this.TimeSlot = '480 Minutes';
        break;
      }
      default: {
        this.TimeSlot = this.TimeSlot;
        break;
      }
    }
  }
  checkDaysFilter() {
    var count = 0;
    if (this.DaysFilter.Monday == true) {
      count += 1;
    }
    if (this.DaysFilter.Tuesday == true) {
      count += 1;
    }
    if (this.DaysFilter.Wednesday == true) {
      count += 1;
    }
    if (this.DaysFilter.Thursday == true) {
      count += 1;
    }
    if (this.DaysFilter.Friday == true) {
      count += 1;
    }
    if (this.DaysFilter.Saturday == true) {
      count += 1;
    }
    if (this.DaysFilter.Sunday == true) {
      count += 1;
    }
    return count;
  }
  ViewScheduleTaskConfirm(val) {
   
    if (this.taskhistory.tasktypeid) {
      this.taskhistory.estcompleteddate = this.datePipe.transform(val.CurrentTime, 'yyyy-MM-dd');
      this.taskhistory.time = this.datePipe.transform(val.CurrentTime, 'hh:mm a');
      var Start = new Date(new Date(val.CurrentTime).getTime() + Number(this.TimeSlot.split(" ")[0]) * 60000);
      this.taskhistory.totime = this.datePipe.transform(Start, 'hh:mm a')
      this.taskhistory.tasktype = this.Tasktypelist.filter(x => x.id == this.taskhistory.tasktypeid)[0].itemName;
      if (this.contactList && !this.BTClick) {
        this.taskhistory.contactName = this.contactList.filter(x => x.id == this.taskhistory.contactid)[0].itemName;
      }
      this.TimeSlotSaveConfirmCss();
    } else {
      alert("Please Select Task Type");
    }

  }

  TimeSlotSaveConfirmCss() {
    this.TimeSlotSaveConfirm = true;
    setTimeout(function () {
      $('#TimeSlotSaveConfirm .dialog').attr('style', 'width: 45%;padding-bottom: 0px;min-height: 30%;padding: 0;top: 10%;left: 12%;');
    }, 4)
  }
  addMoreAppointments() {
    this.additionalDays += 5;
    this.getIntervels(new Date(), (this.additionalDays));
  }


  ShowSlots() {
    this.ShowSlotsDiv = true
    this.Filters("", "")
  }

  ChooseTaskType(value) {
    this.leadservice.GetAllTaskList(this.currentuser.id).then((accounttype) => {
      var res = accounttype.filter(x => x.id == value && x.taskgroupid == this.taskhistory.taskGroupID);
      this.TimeSlot = res[0].TimeRequired;
      let SelfObj = this;
      setTimeout(function () {
        SelfObj.Filters("", "")
      }, 100)

    });

  }
  BookedClick(event, id) {
    var notesli = "";
    this.leadservice.GetAllTaskHistoryById(Number(id), 'TaskHistory').then((items) => {
      this.taskhistorynotes = items;
      this.taskhistorynotes.forEach((res) => {
        notesli += res.notedescription;
      });

    });
 
    this.leadservice.GetByTaskHistoryId(Number(id), "View", this.currentuser.timezone).then(taskdata => {
      var taskhistory1 = taskdata;
      var moment = require('moment-timezone');
      let frm = (taskhistory1.time == null) ? '(NTS)' : moment.tz(taskhistory1.time.toLocaleString(), this.timezonevalue).format("h:mm A");
      let to = (taskhistory1.totime == null) ? '(NTS)' : moment.tz(taskhistory1.totime.toLocaleString(), this.timezonevalue).format("h:mm A");
      var contact = (this.taskhistory.contactid != 0 && this.taskhistory.contactid != null) ? "<b>Contact: </b>" + this.taskhistory.contactName + "<br/>" : "";
      var Account = (this.taskhistory.contactid != 0 && this.taskhistory.contactid != null) ? "<b>Account: </b>" + this.taskhistory.accountname + "<br/>" : "";
      let timelabel = frm + '-' + to;
      var tooltip = "<div class='tooltiptopicevent' style='width:auto;border:1px solid black;font-size:12px;height:auto;background:rgb(162, 228, 119);position:absolute;z-index:10001;padding:0.5% 0.5% 0.5% 0.5%;line-height:150%;top:"
        + (event.clientY) + "px;left:" + (event.clientX) + "px;'>"
        + "<b>Date: </b>" + moment(taskhistory1.time).format("MM/DD/YYYY") + "<br/>"
        + "<b>Time: </b>" + timelabel + "<br/>"
        + contact
        + Account
        + "<b>Type: </b>" + taskhistory1.tasktype.split("[")[0] + "<br/>"
        + "<b>Notes: </b>" + notesli + "<br/>"
        + "</div>";
      $(this).mouseover(function (e) {
        $('.tooltiptopicevent').css('top', e.pageY + 10);
        $('.tooltiptopicevent').css('left', e.pageX + 20);
      });
      $(this).mousemove(function (e) {
        $('.tooltiptopicevent').css('top', e.pageY + 10);
        $('.tooltiptopicevent').css('left', e.pageX + 20);
      });
      $("body").append(tooltip);
    });
  }

  MouseLeave() {
    $(setTimeout(function () {
      $(this).css('z-index', 8);
      $('.tooltiptopicevent').remove();
    }, 300));
  }

  DisplayOtherAppointments() {
    //this.selectedDate = null;
    //this.Filters("", "");
    this.LoadMore(this.selectedDate);
    this.MappedTimeSlot = false;
    this.TimeSlots = this.TimeSlotsOriginal;
  }

  getSelectedIntervels(startDatetime) {
   
    var DateIntervals = [{ WeekDay: '', Date: '', Time: '', AMPM: '', CurrentTime: new Date(), Booked: false, SlotDuration: 0, BookingId: 0, hidden: false, To: '', ToAMPM:'' }];
    DateIntervals = [{
      WeekDay: this.getweek(new Date(startDatetime).getDay()),
      Date: this.datePipe.transform(startDatetime, 'MM-dd-yyyy'),
      Time: this.datePipe.transform(startDatetime, 'hh: mm'),
      AMPM: this.datePipe.transform(startDatetime, 'a'),
     
      CurrentTime: startDatetime,
      Booked: false, SlotDuration: Number(this.TimeSlot.split(" ")[0]),
      BookingId: 0, hidden: false,
      To: this.datePipe.transform(new Date(new Date(startDatetime).getTime() + Number(this.TimeSlot.split(" ")[0]) * 60000), 'hh: mm'),
      ToAMPM: this.datePipe.transform(new Date(new Date(startDatetime).getTime() + Number(this.TimeSlot.split(" ")[0]) * 60000), 'a')

    }];
    this.TimeIntervals = DateIntervals;
    this.MappedTimeSlot = true;
  }
  checknextTimeSlot(startDatetime, endDateTime) {
    var moment = require('moment-timezone');
    var selectedDate = new Date(this.datePipe.transform(startDatetime, 'MM-dd-yyyy hh:mm a'));
    var selDate = this.datePipe.transform(startDatetime, 'MM-dd-yyyy');
    var enddate = new Date(this.datePipe.transform(endDateTime, 'MM-dd-yyyy hh:mm a'));
    if (this.Scheduletasktaskhistory) {
      this.Scheduletasktaskhistory.forEach(ele => {
        if ((selDate == ele.estcompleteddate)) {

          var startTime = new Date(moment(ele.estcompleteddate).format("MM/DD/YYYY") + ' ' + ele.starttime);
          if (selectedDate < startTime) {
            var difference = moment.duration(moment(startTime, "HH:mm a").diff(moment(selectedDate, "HH:mm a")));


            this.TimeSlots = this.TimeSlotsOriginal.filter(x => Number(x.itemName.split(" ")[0]) <= parseInt(difference.asMinutes()));
            this.MappedTimeSlot = true;
          }
        }
      });
    }
    

  }

  CloseCreateTicket(btnName) {
    
    if (btnName == 'AccontView') {
      this.sendMsgEvent.emit(btnName);     
    } else {
      this.CloseWarning = true;
      setTimeout(function () {
        $('#CloseWarning .dialog').attr('style', 'width: 29%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
        this.sendMsgEvent.emit(btnName);
      }, 4)
    }
  }
  CloseAddOpportunityDialog() { 
    this.sendMsgEvent.emit("CloseAddTask");
  }
  close() { this.sendMsgEvent.emit("Close"); }
  OpenAptwarning() {
    localStorage.setItem('TaskSelected', this.TaskId.toString());
    this.applicationComponent.loaddialog('EditTask', 'Edit Task', 'Go to the Task/Appt in the Calender', false, false, true, true, this.TaskId.toString());
    this.sendMsgEvent.emit("CloseAddTask");
  }
  //OpenCalender() {
    
  //  this.router.navigateByUrl(`/application/month-calender/null`);
  //  $(".subButtons").removeClass("activeButton");
  //  $("#TaskCalander_button").addClass("activeButton");
  //  localStorage.setItem('TaskSelected', this.TaskId.toString());
  //  this.sendMsgEvent.emit("CloseAddTask");
  //}
}
