import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { CustomerService } from './../../services/customer.service';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { IMyDpOptions } from 'mydatepicker';
import { Contacts } from '../../models/contacts';
import { Searchvalues } from '../../models/searchvalues';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { ContactsdashboardComponent } from '../../application/contacts/contactsdashboard/contactsdashboard.component';
import { Accounts } from '../../models/accounts';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationComponent } from './../application.component';
import { RhxNames } from '../../models/RhxNames';
declare var $: any;
@Component({
  selector: 'searchdata',
  templateUrl: './searchdata.component.html',
  styleUrls: ['./searchdata.component.css']
})
export class SearchdataComponent implements OnInit {
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  dropdownLists = [];
  currentuser: any;
  selectedItems = [];
  term: string = "";
  finalitems = "";
  tableData: Contacts[];
  hideGlobalSearchtable1: boolean = true;
  condashboardShow: boolean = false;
  isCustomer: boolean = true;
  isFormerCustomer: boolean = true;
  account: Accounts = new Accounts();
  searchvalues: Searchvalues = new Searchvalues();
  isDivVisible: boolean = false;
  taskDialog: boolean = false;
  allContactsproviders: Contacts[];
  type = "";
  @Input()
  divHeight3: number;
  @Input()
  pageFrom: string;

  ContactsprovidersitemCount = 0;
  searchvaluesList: Dropdowntype[] = [];
  divHeight = screen.height * 0.5444;
  SearchdataHeight = screen.height * 0.61666;
  ContactGridHeight = screen.height * 0.33333;
  ContactGrigwidth= 100;
  AccountViewDivwidth = 789;
  NoResult: boolean = false;
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = (new Date().getFullYear()-3) + '-01-01'; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = 'Start: 01-01-' + (new Date().getFullYear()-1).toString();
  RHxContactSelected: boolean = false;
  RHxAccountSelected: boolean = false;
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = new Date().getFullYear() + '-12-31';  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = 'End: 12-31-' + new Date().getFullYear().toString();
  public globalGridOptions: GridOptions;
  public globalColumnDefs: any[];
  public context;
  contactid: number = 0;
  @ViewChild(ContactsdashboardComponent) contactComp;
  dropdownSetting = {};
  public frameworkComponentsProvider;
  public gridOptionsProvider: GridOptions;
  public columnDefsProvider: any[];
  public sideBar; RHxNames: RhxNames[];
  constructor(public applicationComponent: ApplicationComponent,private customerService: CustomerService, private opprtunityservice: OpprtunitiesService, private router: Router)
  {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    }
  }
  ngOnInit() {
     
    this.selectedItems = [
      { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
    ];
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1,0,0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.dropdownLists = Owner;
    });
    this.RHxTab('Contacts');
    let selfObj2 = this;
    $(setTimeout(function () {
      selfObj2.AddSuspendInactiveDiv();
      selfObj2.GetAllaccesusersListbyuser();
    }, 500));
    //$("#userids").val(this.currentuser.id);
    this.customerService.GetSpecialityList("SearchType", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.searchvaluesList = accounttype;
    });
    this.initializeAgGrid();
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 2
    };
    
    var url = window.location.href;
    if (url.indexOf('campaigns') > -1) {
      this.SearchdataHeight = screen.height * 0.46777 ;
      this.divHeight = 350;
      $('#accountInformation').attr('style', 'padding-right: 0; height: 111px!important; padding-left: 0.5%; display: block;overflow: auto;');
      this.ContactGridHeight = 235;
      //document.getElementById('ContactGrigwidth').style.overflow = 'auto';
    }
    //this.applicationComponent.showContactsSubTabs = false;
    
  }

  RHxTab(val) {
    if (val == 'Contacts') {
      this.RHxAccountSelected = false;
      this.RHxContactSelected = true;
      this.GetRHxNames('Contact');
    } else if (val == 'Accounts') {
      this.RHxAccountSelected = true;
      this.RHxContactSelected = false;
      this.GetRHxNames('Account');
    }

    localStorage.setItem('RHxTabselected', val);
  }
 
  OpenAccountOrContact(Rhx) {
    Rhx.Type = Rhx.Type == 'Contact' ? 'Contacts' : 'Customer';
   // this.isDivVisible = false;
    this.sendMsgEvent.emit(Rhx.id + '|' + Rhx.Type);
    //if (Rhx.Type == "Account") {

    //} else if (Rhx.Type == "Contact") {

    //}
  }
  ShowRHxMenuDiv(val) {

   // this.recentHistoryOn = val;
    //this.RHxMenuDiv = val;
    this.RHxMenuDivCss();
    this.RHxNames = [];
    

    let selfobj = this;
    setTimeout(function () {
      var RHxTabselected = localStorage.getItem('RHxTabselected');
      if (RHxTabselected == 'Contacts') {
      //  selfobj.selectedtabid = 'contact';
       // selfobj.RHxContactSelected = true;
        selfobj.GetRHxNames('Contact');
      } else if (RHxTabselected == 'Accounts') {
       // selfobj.selectedtabid = 'customers';
        //selfobj.RHxAccountSelected = true;
        selfobj.GetRHxNames('Account');
      }
      
    }, 300)
  }
  RHxMenuDivCss() {
    setTimeout(function () {
      $('#RHxMenuDiv .dialog').attr('style', 'width: 11%;padding: 0;min-height: 63%;overflow: auto;height: 85%;left: 0!important;margin-left: 1%;top: 10%;');
    }, 4)
  }
  GetRHxNames(Type) {
    this.customerService.GetRecentHistoryNames(Type, $("#userids").val()).then((RHxNameslist) => {
      this.RHxNames = RHxNameslist;
    });
  }
  AddSuspendInactiveDiv() {
     
    var mydiv = document.getElementById("Searchh").getElementsByClassName("pure-checkbox select-all ng-star-inserted");
    //var mydiv  = $("#Searchh .pure-checkbox .select-all .ng-star-inserted");
    var div = document.createElement('div');
    div.id = "SuspendInactiveDivSearch";
    div.className = "col-xs-12 Banoos";
    mydiv[0].parentNode.insertBefore(div, mydiv[0].previousSibling);
    var banooDiv = document.getElementById("SuspendInactiveDivSearch");

    var div = document.createElement('div'); // creating checkbox element
    div.id = "inActiveDivSearch";
    div.className = "col-xs-6";
    $('#SuspendInactiveDivSearch').append(div);
    var inActiveDiv = document.getElementById("inActiveDivSearch");

    var div = document.createElement('div'); // creating checkbox element
    div.id = "suspendDivSearch";
    div.className = "col-xs-6";
    $('#SuspendInactiveDivSearch').append(div);
    var suspendDiv = document.getElementById("suspendDivSearch");


    var checkbox = document.createElement('input'); // creating checkbox element 
    checkbox.type = "checkbox"; // Assigning the attributes 
    checkbox.name = "Inactive";
    checkbox.value = "Inactive";
    checkbox.id = "Inactive1";
    //checkbox.onclick=this.inactiveclick();
    var label = document.createElement('label'); // creating label for checkbox 
    label.htmlFor = "Inactive1"; // assigning attributes for
    label.id = "InactiveLabelSearch";
    label.appendChild(document.createTextNode(' Inactive   '));  // appending the created text to  
    //inActiveDiv.append(checkbox); // appending the checkbox and label to div 
    //inActiveDiv.append(label);

    $('#inActiveDivSearch').append(checkbox);
    $('#inActiveDivSearch').append(label);


    var checkbox2 = document.createElement('input'); // creating checkbox element 
    checkbox2.type = "checkbox"; // Assigning the attributes 
    checkbox2.name = "suspended";
    checkbox2.value = "suspended";
    checkbox2.id = "suspended1";
    var label2 = document.createElement('label'); // creating label for checkbox 
    label2.htmlFor = "suspended1"; // assigning attributes for
    label2.id = 'suspendedLabelSearch';
    label2.appendChild(document.createTextNode(' Suspended'));  // appending the created text to  
    //suspendDiv.append(checkbox2); // appending the checkbox and label to div 
    //suspendDiv.append(label2);

    $('#suspendDivSearch').append(checkbox2);
    $('#suspendDivSearch').append(label2);

    $('#inActiveDivSearch').attr('style', 'padding:0;text-align:center');
    $('#suspendDivSearch').attr('style', 'padding:0;');

    $('#InactiveLabelSearch').attr('style', 'color: #9a9797;margin-left:4%');
    $('#suspendedLabelSearch').attr('style', 'color: #9a9797;margin-left:4%');

    let self = this;
    document.getElementById('Inactive1').onclick = function inactiveclick() {
      self.getinactiveUsers();
    }

    document.getElementById('suspended1').onclick = function inactiveclick() {
      self.getSuspendedUsers();
    }
  }
  getinactiveUsers() {
    this.dropdownLists = [];
    this.GetAllaccesusersListbyuser();

    //this.userservice.GetAllUsers(2).then((user) => {
    //  this.dropdownLists = user;
    //});

  }
  getSuspendedUsers() {
    this.dropdownLists = [];
    this.GetAllaccesusersListbyuser();
    //this.userservice.GetAllUsers(3).then((user) => {
    //  this.dropdownLists = user;
    //});
  }
  GetAllaccesusersListbyuser() {
     
    var inactive = $('#Inactive1').prop("checked") ? 1 : 0;
    var suspended = $('#suspended1').prop("checked") ? 1 : 0;
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1, inactive, suspended).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.dropdownLists = Owner;
    });
  }


  Search(val) {
    if (this.term != '' && this.term != undefined && this.term != null) {
      this.searchvalues.includecustomer = val;
      this.Getcontactorcustomerinfo($("#userids").val());
      $(setTimeout(function () {
        this.hideGlobalSearchtable1 = false;
      }, 100));
    } else {
      alert("Please enter text to search.");
    }
  }
  onItemSelect(item: any) {
     
    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    ///this.Generate($("#userids").val());
  }
  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
     // this.Generate($("#userids").val());
    } else {
     // this.allContacts = [];
      $("#userids").val('');
    }
  }
  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
  //  this.Generate($("#userids").val());
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    //this.allContacts = [];
    //this.gridOptions.api.hideOverlay();
    $("#userids").val('');
  }
  txtPageSizeChanged(pageSize) {
    //this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    //this.saveConfiguration();
  }
  initializeAgGrid() {
    this.globalGridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createColumnDefs("");
    this.globalGridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    
  }
  createColumnDefs(columnSelections) {

      this.globalColumnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          enableTooltip: true
        },
        { field: 'type', headerName: 'Type', hide: false },
        { field: 'accountname', headerName: 'Account Name', hide: false },
        { field: 'lastname', headerName: 'Last Name', hide: false },
        { field: 'firstname', headerName: 'First Name', hide: false },
        { field: 'middlename', headerName: 'Middle Name', hide: false },
        { field: 'titleName', headerName: 'Title', hide: false },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        { field: 'DOB', headerName: 'DOB', hide: false },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        { field: 'zip', headerName: 'ZIP', hide: false },
        { field: 'accnumber', headerName: 'Act Number', hide: false },     
        { field: 'contacttypeName', headerName: 'Contact Type', hide: true },  //true starts here
        { field: 'suffix', headerName: 'Suffix', hide: true },
        { field: 'sex', headerName: 'Sex', hide: true },
        { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
        { field: 'email', headerName: 'Email', hide: true },
        { field: 'address1', headerName: 'Address1', hide: true },
        { field: 'address2', headerName: 'Address2', hide: true },
        { field: 'city', headerName: 'City', hide: true },
        { field: 'state', headerName: 'State', hide: true },
        { field: 'cell', headerName: 'Cell', hide: true },
        { field: 'office', headerName: 'Office', hide: true },
        { field: 'homeaddress', headerName: 'Home Address', hide: true },
        { field: 'homecity', headerName: 'Home City', hide: true },
        { field: 'homestate', headerName: 'Home State', hide: true },
        { field: 'homezip', headerName: 'Home Zip', hide: true },
        { field: 'home', headerName: 'Home', hide: true },
        { field: 'college', headerName: 'College', hide: true },
        { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
        { field: 'medschool', headerName: 'Medschool', hide: true },
        { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
        { field: 'residency', headerName: 'Residency', hide: true },
        { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
        { field: 'fellowship', headerName: 'Fellowship', hide: true },
        { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
        { field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true },
        { field: 'created', headerName: 'Created', hide: true }

      ];
    
  }
  ChangeSearch(val) { }
  onAgGridReady() {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
      $(setTimeout(function () {
        selfObj.autoSizeAll();
      }, 400));
    }, 400));
  }
  autoSizeAll() {

  }
  rowClickAgGrid(rowEvent) {
    if (rowEvent.node.selected) {
      this.isDivVisible = true;
      if (rowEvent.data.type == 'Customer' || rowEvent.data.type == 'Account') {
        this.initializeaccountproviderAgGrid();
        this.type = 'Customer';
        $('#accountViewSearch').attr('style', 'width: 50%;margin-top:1%;display:block;padding-right:0;overflow:auto;')
        
       // $('#ContactViewSearch').attr('style', 'padding:0;width: 51%;margin-top:0.75%;display:none;height:98%;')
        this.customerService.GetByAccountId(rowEvent.data.id).then(result => {
          this.account = result;
          this.GetAllProviderAndContactInfo(rowEvent.data.id);
        });
      }
        else {
        this.condashboardShow = true;
        this.type = 'Contact';
        this.contactid = rowEvent.data.id;
      // this.contactComp.GetContactInfo(rowEvent.data.id);
        //var url = window.location.href;
        //if (url.indexOf('month-calender') > -1) {
        //  this.divHeight3 = 350;
        //}
      }
      $('.ag-paging-row-summary-panel span:first-child').html(rowEvent.rowIndex + 1)
      this.contactid = rowEvent.data.id;
    //  this.sendMsgEvent.emit(rowEvent.data.id + '|' + rowEvent.data.type);
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.contactComp.GetContactInfo(rowEvent.data.id);
      }, 100));
      //this.contactComp.GetContactInfo(rowEvent.data.id);
    }
    else {
      this.isDivVisible = false;
    }
  }
  rowDoubleClickAgGrid(rowEvent) {
    rowEvent.data.type = rowEvent.data.type == 'Contact' ? 'Contacts' : rowEvent.data.type;    
    this.isDivVisible = false;    
    this.sendMsgEvent.emit(rowEvent.data.id + '|' + rowEvent.data.type);
    if (this.pageFrom != "Campaigns") {
      this.hideGlobalSearchtable1 = true;
    }
    this.term = "";
    
  }

  BTClick() {
    this.sendMsgEvent.emit("BTClick");
  }
  GetAllProviderAndContactInfo(accountid) {
    this.customerService.GetAllContactproviderByAccount(accountid, this.currentuser.id).then((contacts) => {
      contacts.forEach(contacts => {
      });
      this.allContactsproviders = contacts;
      this.ContactsprovidersitemCount = contacts.length;
    });

  }
  NoResultCss() {
    setTimeout(function () {
      $('#NoResult .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 100);
  }
  Getcontactorcustomerinfo(userids) {
     
    this.hideGlobalSearchtable1 = false;
    //this.applicationComponent.showContactsSubTabs = false;
    if (this.term != null && this.term != undefined && this.term != "") {
   
      //this.searchvalues.includecustomer = $('input[id=chkIncludeCustomerOnly]').is(':checked') ? true : false;
      this.opprtunityservice.GetAllContactsAndCustomerBySearch(this.term, userids, this.startDateVal, this.endDateVal, $("#searchval option:selected").val(),this.searchvalues.includecustomer).then((data) => {

        this.tableData = data;
        if (this.tableData.length == 0) {
          this.NoResult = true;
          this.NoResultCss();
        }
      });
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.globalGridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.contactid) {
            selfObj.globalGridOptions.api.selectNode(node, true);
            selfObj.contactComp.GetContactInfo(selfObj.contactid);
          }
        });
      }, 200));
      
    }
    
  }

  initializeaccountproviderAgGrid() {
    this.globalGridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createColumnDefsProvider("");
    this.globalGridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;

  }
  createColumnDefsProvider(columnSelections: string) {
      this.columnDefsProvider = [
        {
          field: '#',
          headerName: 'Actions',
          width: 110,
          cellRenderer: 'editdeletecv',
          suppressSorting: true,
          suppressMenu: true,
          pinned: 'right',
          hide: false,
          outerWidth: 8,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'lastname', headerName: 'Last Name', hide: false },
        { field: 'firstname', headerName: 'First Name', hide: false },
        { field: 'middlename', headerName: 'Middle Name', hide: false },
        { field: 'titleName', headerName: 'Title', hide: false },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        { field: 'DOB', headerName: 'DOB', hide: false },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        { field: 'zip', headerName: 'ZIP', hide: false },
        { field: 'accountname', headerName: 'Account', hide: false },
        { field: 'contacttype', headerName: 'Contact Type', hide: true },  //true starts here
        { field: 'suffix', headerName: 'Suffix', hide: true },
        { field: 'sex', headerName: 'Sex', hide: true },
        { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
        { field: 'email', headerName: 'Email', hide: true },
        { field: 'address1', headerName: 'Address1', hide: true },
        { field: 'address2', headerName: 'Address2', hide: true },
        { field: 'city', headerName: 'City', hide: true },
        { field: 'state', headerName: 'State', hide: true },
        { field: 'cell', headerName: 'Cell', hide: true },
        { field: 'office', headerName: 'Office', hide: true },
        { field: 'homeaddress', headerName: 'Home Address', hide: true },
        { field: 'homecity', headerName: 'Home City', hide: true },
        { field: 'homestate', headerName: 'Home State', hide: true },
        { field: 'homezip', headerName: 'Home Zip', hide: true },
        { field: 'home', headerName: 'Home', hide: true },
        { field: 'college', headerName: 'College', hide: true },
        { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
        { field: 'medschool', headerName: 'Medschool', hide: true },
        { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
        { field: 'residency', headerName: 'Residency', hide: true },
        { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
        { field: 'fellowship', headerName: 'Fellowship', hide: true },
        { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
        { field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true },
        { field: 'created', headerName: 'Created', hide: true }
      ];  
  }
  onAgGridReadyProvider(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllProvider();
      $(setTimeout(function () {
        selfObj.autoSizeAllProvider();
      }, 400));
    }, 400));
  }
  autoSizeAllProvider() {

  }
  onMessageReceived(msg) { }

  //openScheduleTask() {
  //  this.taskDialog = !this.taskDialog;
  //  this.taskDialogCss();
  //}
  //taskDialogCss() {
  //  $(setTimeout(function () {
  //    $('#taskDialog .dialog').attr('style', 'width: 40%;padding: 0;border: 4px solid rgb(117, 218, 51);min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
  //  }, 4));
  //}
}
