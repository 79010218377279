import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { Accounts } from 'src/app/models/accounts';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../application.component';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { Contacts } from 'src/app/models/contacts';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
declare var $: any;
import { ContactsdashboardComponent } from '../../application/contacts/contactsdashboard/contactsdashboard.component';
import { AccountContactComponent } from '../account-contact/account-contact.component';
import { Location } from '@angular/common'
import { filter } from 'rxjs/operators';
import { CustomerService } from '../../services/customer.service';
import { QuickSearchModel } from 'src/app/models/QuickSearchmodel';
import { Subject } from "rxjs/Subject";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinctUntilChanged";
//import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/mergeMap";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';

import { debounceTime } from 'rxjs/operators';
import { Dropdowntype } from '../../models/dropdowntype';
import { UserService } from '../../services/user.service';
import { AccountComponent } from '../account-contact/account/account.component';
import { RhxNames } from '../../models/RhxNames';
@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.css']
})
export class QuickSearchComponent implements OnInit {
  public columnDefs: any[];
  public gridOptions: GridOptions;
  public sideBar;
  defaltAccountColumns: Accounts[];
  allCustomers: Accounts[];
  customertype: any = '0,1,2,3' ;
  currentuser: any;
  term: string='';
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  calanderonoff: boolean = false;
  AccountHistoryInfo: boolean = false;
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 3) + '-01-01' : this.applicationComponent.startVal; 
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");
  timezonevalue: string;
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");
  public context;
  search: string = null;
  searchCriteria: string = "";
  SearchType: string = "accountName";
  selectedval: string = '702';
  SearchResultTable: string = "";
  public ContactcolumnDefs: any[];
  allContacts: Contacts[];
  pageSize = "";
  private gridApi;  
  datePipe: DatePipe = new DatePipe("en-US");
  @ViewChild(AccountComponent) accComp;
  previousUrl: string;
  selectedItems = [];
  dropdownList = [];
  UserIds: string;
  dropdownSetting = {};
  SearcValueDrpSetting = {};
  SearcTypeDrpSetting = {};
  SearchSharedDrpSetting = {};
  finalitems: any; 
  QuickSearchModel: QuickSearchModel = new QuickSearchModel();
  account: Accounts = new Accounts();
  results: Object;
  subject: Subject<any> = new Subject();
  QuickSearchConfig = [];
  MultiselectDropDownChanged: boolean = false;
  SaveUserListConfirmation: boolean = false;
  SearcValueList: Dropdowntype[] = [];
  SearchTypeList: Dropdowntype[] = [];
  SearchInactiveTypeList: Dropdowntype[] = [];
  SearchSharedAccountList: Dropdowntype[] = [];
  SearcValueModel: number = 702;
  SearcValueText: string = "Account Name";
  LastContactDate;
  SearchSharedModel = 726;
  SearchTypeModel = 711;
  SearchTypeInactiveModel = 714;
  selectedUserIds = "";
  @Input()
  SearchTab: string;
  showSearchTabGrid: boolean = false;
  selecteddrpVal: string = "702";
  recentaccountNames = []; recentcontactNames = []; recentphonenumbers= []; recentcities= []; recentstates= []; recentzips= [];
  recentemails= [];
  RHxNames: RhxNames[]; RHxContactSelected: boolean = false; RHxAccountSelected: boolean = false;
  isactivecustomer: boolean = true; isinactivecustomer: boolean = true; issharedaccounts: boolean = true;
  constructor(private router: Router, private opprtunityservice: OpprtunitiesService, public applicationComponent: ApplicationComponent, private userservice: UserService,
    private location: Location, private customerService: CustomerService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    var val = this.currentuser.lastSearchby;
    $(setTimeout(function () {
      $("#" + val).trigger("click");
    }, 200));
    this.QuickSearchConfig = JSON.parse(localStorage.getItem('QuickSearchConfig'));
    this.applicationComponent.selectedItems = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
      this.selectedUserIds = $("#userids").val();
     
    }
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
  }

  ngOnInit(): void {
    this.applicationComponent.pageViewed = "Search";
   
    let SelfObj = this;
    setTimeout(() => {
      document.getElementById('SearchTextbox').focus();      
      SelfObj.clearSearch();
    }, 100);    
    this.dropdownList = this.applicationComponent.dropdownList;    
      this.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
    ];    
    this.finalitems += this.currentuser.id + ',';
    this.UserIds = this.currentuser.id;
    this.allCustomers = [];
    this.allContacts = [];
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 10
    };
   
    this.subject
      .pipe(debounceTime(1000))
      .subscribe(() => {
        if (this.term != "") {          
          this.GetContactSearchList(this.term, this.UserIds, this.isactivecustomer, this.isinactivecustomer, this.issharedaccounts);
        } else {
          this.allContacts = [];
          this.allCustomers = [];
        }
        
      }
      );
    this.initializeAgGrid();
    
  }
  onContactsSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        let rowData = event.api.getSelectedNodes()[0].data;
        this.AccountHistoryInfo = true;
        this.account.owner = rowData.owner;
        this.account.accounttypeName = rowData.accounttypeName;
        this.account.customertype = rowData.customertype == '1' || rowData.customertype == '2' ? "Yes" : "No";
        this.account.ownershipName = rowData.ownershipName;
        this.account.accountStatus = rowData.Status;
        this.customerService.LastContactedDateByAccID(rowData.accountid).then(result2 => {
          if (result2 != null && result2 != '0001-01-01T00:00:00') {
            this.LastContactDate = this.datePipe.transform(result2, "MM-dd-yyyy");
          }
        });
        $('#emailIds').val(rowData.accountid);
      }
    } else {
      this.AccountHistoryInfo = false;
    }

  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        let rowData = event.api.getSelectedNodes()[0].data;
        this.AccountHistoryInfo = false;
        this.account.owner = rowData.owner;
        this.account.accounttypeName = rowData.accounttypeName;
        this.account.customertype = rowData.customertype == "Customer" || rowData.customertype == "Former Customer" ?"Yes":"No";
        this.account.ownershipName = rowData.ownershipName;
        this.account.accountStatus = rowData.accountStatus;
        this.customerService.LastContactedDateByAccID(rowData.id).then(result2 => {
          if (result2 != null && result2 != '0001-01-01T00:00:00') {
            this.LastContactDate = this.datePipe.transform(result2, "MM-dd-yyyy");
          }
        });
        $('#emailIds').val(rowData.id);
      }
    } else {
    this.AccountHistoryInfo = false;
    }

  }
  loadSearchdropdowns() {  
    if (localStorage.getItem("LastSearchval") != '' && localStorage.getItem("LastSearchval")!='null') {
      this.SearcValueModel = parseInt(localStorage.getItem("LastSearchval"));
      this.loadgrids(this.SearcValueModel); 
    }   
  }
 
  MultiUserSaveclicked() {
    localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));    
    this.MultiselectDropDownChanged = false;
    this.SaveUserListConfirmation = false;  
  }

  GetAllaccesusersListbyuser() {
    this.dropdownList = [];
    var active = $('#Active').prop("checked") ? 1 : 0;
    var inactive = $('#Inactive').prop("checked") ? 1 : 0;
    var suspended = $('#suspended').prop("checked") ? 1 : 0;
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, active, inactive, suspended).then((Owner) => {
      this.applicationComponent.dropdownList = Owner;
    });
  }


  SaveConfirm(val) {
    if (val == 'Yes') {
      this.MultiUserSaveclicked();
    } else {
      this.MultiselectDropDownChanged = false;
      this.SaveUserListConfirmation = false;
    }
  }

  SaveUserListConfirmationCss() {
    setTimeout(function () {
      $('#SaveUserListConfirmation .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%;left:18%');
    }, 4)
  }


  onItemSelect(item) {
    var SelectedUsers = [];
    SelectedUsers = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    if (SelectedUsers && SelectedUsers.length > 0) {
      SelectedUsers.push(item)
    } else {
      SelectedUsers.push( item);
    }
    localStorage.setItem('SelectedUserIds', JSON.stringify(SelectedUsers));
    this.selectedUserIds = SelectedUsers.map(s => s.id).join(',');

    $('#cancelDiv').attr('style', 'display:block;');
    $('#SaveDiv').attr('style', 'display:block;');
    this.IncludeSharedAccounts();
  }
  SearcValueItemSelect(itemval) {  
    this.clearSearch();
    this.selectedval = itemval;
    this.selecteddrpVal = itemval;
    this.SearcValueText = itemval;
    this.loadgrids(itemval);    
    this.AccountHistoryInfo = false;
    
  }
  loadgrids(searchtypeval) {
    if (searchtypeval == 702 || searchtypeval == 723 || searchtypeval == 706 ||
      searchtypeval == 724 || searchtypeval == 704) {
      this.SearchType = "accountName";
      this.SearchResultTable = 'Account';
      this.createColumnDefs();
    }
    else {
      this.SearchType = "contactName";
      this.SearchResultTable = 'Contact';
      if (this.term == '') this.term = null;
      this.SearchResultTable = 'Contact';
      this.createContactsColumnDefs();
    }
    
  }
  
  ActiveItemSelect(event) {
    this.GetContactSearchList(this.term, this.UserIds, this.isactivecustomer, this.isinactivecustomer, this.issharedaccounts);   
  }
  InActiveItemSelect() {
    this.GetContactSearchList(this.term, this.UserIds, this.isactivecustomer, this.isinactivecustomer, this.issharedaccounts);
  }
  SharedAccountsSelect() {
    this.GetContactSearchList(this.term, this.UserIds, this.isactivecustomer, this.isinactivecustomer, this.issharedaccounts);
  }
  OnItemDeSelect(item) {
    var SelectedUsers = [];
    SelectedUsers = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    if (SelectedUsers && SelectedUsers.length > 0) {
      SelectedUsers = SelectedUsers.filter(x => x.id != item.id)
    } else {
      SelectedUsers.push( item);
    }
    localStorage.setItem('SelectedUserIds', JSON.stringify(SelectedUsers));
    this.selectedUserIds = SelectedUsers.map(s => s.id).join(',');
    $('#cancelDiv').attr('style', 'display:block;');
    $('#SaveDiv').attr('style', 'display:block;');
    this.IncludeSharedAccounts();
  }
  onSelectAll(items) {
    localStorage.setItem('SelectedUserIds', JSON.stringify(items));
    this.selectedUserIds = items.map(s => s.id).join(',');
    this.IncludeSharedAccounts();
    $('#cancelDiv').attr('style', 'display:block;');
    $('#SaveDiv').attr('style', 'display:block;');
  }
  onDeSelectAll(event) {
    localStorage.setItem('SelectedUserIds', null);
    $('#cancelDiv').attr('style', 'display:block;');
    $('#SaveDiv').attr('style', 'display:block;');
  }
  initializeAgGrid() {
  
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true,
      toolPanelSuppressPivotMode: true, rowSelection: 'single', enableCellChangeFlash: true,

    };
    this.context = this;
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',
    };
    if (this.SearchType == "accountName") {
      this.createColumnDefs();
    } else {
      this.createContactsColumnDefs();
    }
  }
  createColumnDefs() {    
    var searcharray = this.term;
    this.columnDefs = [];
    let selfobj = this;
    if (selfobj.selectedval === "704") {
      this.columnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        {
          field: 'phone', headerName: 'Phone', hide: false, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              let y = searcharray!="" ? searcharray.toString().split(' ') : searcharray;
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  selfobj.recentphonenumbers.push(params.data.id + ":" + params.value);
                }
                else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'accountname', headerName: 'Account Name', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.trim().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st;
            } else {
              return params.value;
            }
          }
        },
        {
          field: 'customertype', headerName: 'Customer', hide: false,
          cellRenderer(params) {
            if (params.value == '0') { return " " } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
          }
        },
        {
          field: 'address1', headerName: 'Address1', hide: false,
        },
        {
          field: 'address2', headerName: 'Address2', hide: false,
        },
        {
          field: 'city', headerName: 'City', hide: false,
          cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'state', headerName: 'State', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'zip', headerName: 'Zip', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        }, {
          field: 'owner', headerName: 'Owner', hide: false,
        },
        { field: 'id', headerName: 'Id', hide: true },
       
      ];
    }
    else if (selfobj.selectedval === "706") {
      this.columnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        {
          field: 'city', headerName: 'City', hide: false,
          cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  selfobj.recentcities.push(params.data.id + ":" + params.value);
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'accountname', headerName: 'Account Name', hide: false
         
        },
        {
          field: 'customertype', headerName: 'Customer', hide: false,
          cellRenderer(params) {
            if (params.value == '0') { return " " } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
          }
        },
        {
          field: 'address1', headerName: 'Address1', hide: false,
        },
        {
          field: 'address2', headerName: 'Address2', hide: false,
        },

        {
          field: 'state', headerName: 'State', hide: false
        },
        {
          field: 'zip', headerName: 'Zip', hide: false
        },

        {
          field: 'phone', headerName: 'Phone', hide: false
        },
         {
          field: 'owner', headerName: 'Owner', hide: false,
        },
        { field: 'id', headerName: 'Id', hide: true },

      ];
    }
    else if (selfobj.selectedval === "723") {
      this.columnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        {
          field: 'state', headerName: 'State', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  selfobj.recentstates.push(params.data.id + ":" + params.value);
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },

        {
          field: 'accountname', headerName: 'Account Name', hide: false
        },
        {
          field: 'customertype', headerName: 'Customer', hide: false,
          cellRenderer(params) {
            if (params.value == '0') { return " " } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
          }
        },
        {
          field: 'address1', headerName: 'Address1', hide: false,
        },
        {
          field: 'address2', headerName: 'Address2', hide: false,
        },
        {
          field: 'city', headerName: 'City', hide: false
        },
      
        {
          field: 'zip', headerName: 'Zip', hide: false
        },

        {
          field: 'phone', headerName: 'Phone', hide: false
        },
        {
          field: 'owner', headerName: 'Owner', hide: false,
        },
        { field: 'id', headerName: 'Id', hide: true },

      ];
    }
    else if (selfobj.selectedval === "724") {
      this.columnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        {
          field: 'zip', headerName: 'Zip', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split("");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "<label style='color:#00B0F0'>" + x[i] + "</label>";
                  selfobj.recentzips.push(params.data.id + ":" + params.value);
                }
                else {
                  st += x[i] + "";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },

        {
          field: 'accountname', headerName: 'Account Name', hide: false
        },
        {
          field: 'customertype', headerName: 'Customer', hide: false,
          cellRenderer(params) {
            if (params.value == '0') { return " " } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
          }
        },
        {
          field: 'address1', headerName: 'Address1', hide: false,
        },
        {
          field: 'address2', headerName: 'Address2', hide: false,
        },
        {
          field: 'city', headerName: 'City', hide: false
        },
        {
          field: 'state', headerName: 'State', hide: false
        },
    

        {
          field: 'phone', headerName: 'Phone', hide: false
        },
        {
          field: 'owner', headerName: 'Owner', hide: false,
        },
        { field: 'id', headerName: 'Id', hide: true },

      ];
    }
    else {
     
      selfobj.columnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'id', headerName: 'Id', hide: true },
   
        { field: 'accounttypeName', headerName: 'Account Type', hide: true },
        //{ field: 'customertype', headerName: 'Customer', hide: true },
        { field: 'ownershipName', headerName: 'Ownership', hide: true },
        { field: 'accountStatus', headerName: 'AccountStatus', hide: true },
        {
          field: 'accountname', headerName: 'Account Name', hide: false,
          cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(' ');
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (x[i].toString().startsWith(searcharray.trim())
                  || x[i].toString().toLowerCase().startsWith(searcharray.toLowerCase())
                  || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  selfobj.recentaccountNames.push(params.data.id + ":" + params.value + ":" + params.data.customertype);
                }
                else {
                  st += x[i] + " ";
                }
              }
             
              return st
           
            } else {
              return params.value;
            }
          },
        },
        {
          field: 'customertype', headerName: 'Customer', hide: false,
          cellRenderer(params) {
            if (params.value == '0') { return " " } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
          }
        },
        {
          field: 'accnumber', headerName: 'Act Number', hide: true, cellStyle: { color: '#00B0F0' },
        },
        { field: 'IsContact', headerName: ' Is Contact?', hide: true },

        {
          field: 'specialityName', headerName: 'Specialty', hide: true,
        },
        {
          field: 'address1', headerName: 'Address1', hide: false,
        },
        {
          field: 'address2', headerName: 'Address2', hide: false,
        },
        {
          field: 'city', headerName: 'City', hide: false,
          cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'state', headerName: 'State', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'zip', headerName: 'Zip', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'phone', headerName: 'Phone', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'mapit', headerName: 'Map It', hide: false, width: 80,
        },
        {
          field: 'owner', headerName: 'Owner', hide: false,
        }
      ];
    }
  }
  createContactsColumnDefs() {
    var searcharray = this.term;
   
    this.ContactcolumnDefs = [];
    var selfobj = this;
    if (selfobj.selectedval == "705") {
      selfobj.ContactcolumnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true,

        },
        {
          field: 'id', headerName: 'id', hide: true
        },
        {
          field: 'email', headerName: 'Email', hide: false,
          cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  selfobj.recentemails.push(params.data.id + ":" + params.value +":" +0);
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'lastname', headerName: 'Last Name', hide: false
          
        },
        {
          field: 'middlename', headerName: 'Middle Name', hide: false
        },
        {
          field: 'firstname', headerName: 'First Name', hide: false
        },
        {
          field: 'titleName', headerName: 'Title', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'accountname', headerName: 'Account', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'customertype', headerName: 'Customer', hide: false,
          cellRenderer(params) {
            if (params.value == '0') { return " " } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
          }
        },
        {
          field: 'specialityName', headerName: 'Specialty', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },

        {
          field: 'city', headerName: 'City', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }

        },
        {
          field: 'state', headerName: 'State', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }

        },
        {
          field: 'zip', headerName: 'ZIP', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },

       
        {
          field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true,

        },

        {
          field: 'DOB', headerName: 'DOB', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'cell', headerName: 'Cell', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }

        },
        {
          field: 'office', headerName: 'Office', hide: false,

        },
      ];
    } else {
      selfobj.ContactcolumnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true,

        },
        {
          field: 'id', headerName: 'id', hide: true
        },
        {
          field: 'lastname', headerName: 'Last Name', hide: false,
          cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
             
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'middlename', headerName: 'Middle Name', hide: false,
          cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {             
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
       
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'firstname', headerName: 'First Name', hide: false,
          cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  selfobj.recentcontactNames.push(params.data.id + ":" + params.data.lastname + " " + params.data.firstname + ":" + 0);
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'titleName', headerName: 'Title', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'accountname', headerName: 'Account', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'customertype', headerName: 'Customer', hide: false,
          cellRenderer(params) {
            if (params.value == '0') { return " " } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
          }
        },
        {
          field: 'specialityName', headerName: 'Specialty', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },

        {
          field: 'city', headerName: 'City', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }

        },
        {
          field: 'state', headerName: 'State', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }

        },
        {
          field: 'zip', headerName: 'ZIP', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },

        {
          field: 'accnumber', headerName: 'Acct #', hide: true
          , cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true,

        },

        {
          field: 'DOB', headerName: 'DOB', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'cell', headerName: 'Cell', hide: false, cellRenderer: function (params) {
            if (searcharray != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }

        },
        {
          field: 'office', headerName: 'Office', hide: false,

        },
        { field: 'callliststatus', headerName: 'Call List Hx', hide: true },
        {
          field: "notes", headerName: 'Notes', hide: true, cellRenderer: 'notesrenderer'
        },


        {
          field: 'contacttypeName', headerName: 'Contact Type', hide: true,

        },  //true starts here
        {
          field: 'suffix', headerName: 'Suffix', hide: true,

        },
        {
          field: 'sex', headerName: 'Sex', hide: true,

        },
        {
          field: 'subspeciality', headerName: 'Subspeciality', hide: true,

        },
        {
          field: 'email', headerName: 'Email', hide: true,

        },
        {
          field: 'address1', headerName: 'Address1', hide: true,

        },
        {
          field: 'address2', headerName: 'Address2', hide: true,

        },


        {
          field: 'conmapit', headerName: 'Map It', hide: false, width: 80, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<img src="../../../assets/img/Map it.png" style="height:20px;width:50%"/>';

            return eDiv;
          }
        },
        {
          field: 'homeaddress', headerName: 'Home Address', hide: true,

        },
        {

          field: 'homecity', headerName: 'Home City', hide: true,

        },
        {
          field: 'homestate', headerName: 'Home State', hide: true,

        },
        {
          field: 'homezip', headerName: 'Home Zip', hide: true,

        },
        {
          field: 'home', headerName: 'Home', hide: true,

        },
        {
          field: 'college', headerName: 'College', hide: true,

        },
        {
          field: 'collegegrandyear', headerName: 'C.G.year', hide: true,

        },
        {
          field: 'medschool', headerName: 'Medschool', hide: true,
        },
        {
          field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true
        },
        {
          field: 'residency', headerName: 'Residency', hide: true
        },
        {
          field: 'residencygrandyear', headerName: 'R.G.year', hide: true
        },
        {
          field: 'fellowship', headerName: 'Fellowship', hide: true
        },
        {
          field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true,

        },

        {
          field: 'created', headerName: 'Created', hide: true,

        },

      ];
    }
   
  }
  

  GetContactSearchList(term, userid, active?: boolean, inactive?: boolean, shared?: boolean) {
   
    var userIds = [];
    this.applicationComponent.selectedItems.forEach(ele => {
      userIds.push(ele.id);
    });
    this.QuickSearchModel.searchterm = this.term;
    this.QuickSearchModel.userids = userIds.join();
    this.QuickSearchModel.startDate = (this.calanderonoff) ? this.startDateVal : '2018-01-01';
    this.QuickSearchModel.endDate = this.endDateVal;
    this.QuickSearchModel.Includetype = '457';
    this.QuickSearchModel.customertype = this.customertype;
    this.QuickSearchModel.selectedval = (this.selectedval == "702" || this.selectedval == "703") ? 'Name' : (this.selectedval == "704") ? 'phone' : (this.selectedval == "705") ? 'Email' : (this.selectedval == "706") ? 'city' : (this.selectedval == "723") ? 'state' : (this.selectedval == "724") ? 'zip' :this.selectedval ;
    this.QuickSearchModel.SearchType = this.SearchType;
    this.opprtunityservice.GetAllContactsAndCustomerByQuickSearch(this.QuickSearchModel).then((contact) => {
      console.log("contact", contact);
        this.allContacts = [];      
        this.defaltAccountColumns = [];
        if (contact.length > 0) {
          contact.forEach(contact => {
            contact.created = this.datePipe.transform(contact.created, "MM-dd-yyyy");
            contact.speciality = ((contact.speciality == 'null') ? "" : contact.speciality);
          });
          if (this.SearchType == 'accountName') {
            let selfobj = this;
            contact.forEach(con => {
              var account: Accounts = new Accounts();
              account.id = con.id;
              account.accountname = con.accountname;
              account.address1 = con.address1;
              account.address2 = con.address2;
              account.city = con.city;
              account.phone = con.cell;
              account.owner = con.owner;
              account.state = con.state;
              account.zip = con.zip;
              account.isshared = con.isshared;
              account.customertype = con.customertype;
              account.accounttypeName = con.accounttypeName;
              account.ownershipName = con.ownershipName;
              account.accountStatus = con.Status;
              account.isCustomer = con.isCustomer;
              selfobj.defaltAccountColumns.push(account);              
            });
            if (!active) {
              selfobj.defaltAccountColumns = selfobj.defaltAccountColumns.filter(x => x.accountStatus != 14);
            }
            if (!inactive) {
              selfobj.defaltAccountColumns = selfobj.defaltAccountColumns.filter(x => x.accountStatus != 15);
            }        
            if (selfobj.term != "") {
              if (selfobj.selecteddrpVal == '702') {
                selfobj.allCustomers = selfobj.defaltAccountColumns.filter(x => x.accountname.toLowerCase().includes(selfobj.term.toLowerCase()) || x.accountname.includes(selfobj.term));
              } else if (selfobj.selecteddrpVal == '706') {
                selfobj.allCustomers = selfobj.defaltAccountColumns.filter(x => x.city != null && x.city.toLowerCase().includes(selfobj.term.toLowerCase()) || x.city.includes(selfobj.term));
              } else if (selfobj.selecteddrpVal == '723') {
                this.allCustomers = selfobj.defaltAccountColumns.filter(x => x.state != null && x.state.toLowerCase().includes(selfobj.term.toLowerCase()) || x.state.includes(selfobj.term));
              } else if (selfobj.selecteddrpVal == '724') {
                selfobj.allCustomers = selfobj.defaltAccountColumns.filter(x => x.zip != null && x.zip.includes(selfobj.term));
              } else if (selfobj.selecteddrpVal == '704') {
                selfobj.allCustomers = selfobj.defaltAccountColumns.filter(x => x.phone != null && x.phone.replace(/\D/g, "").includes(selfobj.term.replace(/\D/g, "")));
              }

              selfobj.SearchResultTable = 'Account';
              selfobj.createColumnDefs();
              selfobj.gridApi.redrawRows();
            }

          } else {
            if (this.SearchSharedModel == 725) {
              this.allContacts = contact;
              if (this.SearchTypeModel == 711 && this.SearchTypeInactiveModel == 714) {
                this.allContacts = this.allContacts.filter(x => (x.customertype == '1' || x.customertype == '0'));
              } else if (this.SearchTypeModel == 711 && this.SearchTypeInactiveModel == 713) {
                this.allContacts = this.allContacts.filter(x => (x.customertype == '1' || x.customertype == '0' || x.customertype == '2'));
              } else if (this.SearchTypeModel == 712 && this.SearchTypeInactiveModel == 713) {
                this.allContacts = this.allContacts.filter(x => (x.customertype == '0' || x.customertype == '2'));
              } else if (this.SearchTypeModel == 712 && this.SearchTypeInactiveModel == 714) {
                this.allContacts = this.allContacts.filter(x => (x.customertype == '0'));
              }
              if (this.selecteddrpVal == '705') {
                this.allContacts = this.allContacts.filter(x => x.email != null && (x.email.startsWith(this.term) || x.email.toLowerCase().startsWith(this.term)))
              } else if (this.selecteddrpVal == '703') {
                this.allContacts = this.allContacts.filter(x => (x.firstname != null && (x.firstname.startsWith(this.term) || x.firstname.toLowerCase().startsWith(this.term)) || (x.lastname != null && (x.lastname.startsWith(this.term) || x.lastname.toLowerCase().startsWith(this.term))) || (x.middlename != null && (x.middlename.startsWith(this.term) || x.middlename.toLowerCase().startsWith(this.term)))))
              }
            } else {
              this.allContacts = contact;
                
            }
            this.createContactsColumnDefs();
            this.gridApi.redrawRows();
          }
        } else {
          this.allContacts = [];
          this.allCustomers = [];
        }
      });
   
  }
  clearSearch() {
    $("#SearchTextbox").val(null);    
    this.initializeAgGrid();
    this.term = '';
    this.allCustomers = [];
    this.allContacts = [];
  }
 
  Search(val) {  
    this.SaveQuickSearchConfigToLocal(this.SearcValueText);
    this.term = (val != '') ? val : this.term;
    this.AccountHistoryInfo = false;
    let selfObj = this;
    setTimeout(() => {
      document.getElementById('SearchTextbox').focus();
      var userIds = [];
      selfObj.applicationComponent.selectedItems.forEach(ele => {
        userIds.push(ele.id);
      });
      if (selfObj.term == "" || selfObj.term == null) {
        selfObj.showSearchTabGrid = false;      
      }
      if (selfObj.selectedval == null) {
        if (selfObj.SearchType == 'accountName') {
          if (selfObj.term != "") {
            if (this.selecteddrpVal == '702') {
              this.allCustomers = this.defaltAccountColumns.filter(x => x.accountname.includes(this.term.toLowerCase()) || x.accountname.includes(this.term));
            } else if (this.selecteddrpVal == '706') {
              this.allCustomers = this.defaltAccountColumns.filter(x => x.accountname.includes(this.term.toLowerCase()) || x.city != null && x.city.includes(this.term));
            } else if (this.selecteddrpVal == '723') {
              this.allCustomers = this.defaltAccountColumns.filter(x => x.accountname.includes(this.term.toLowerCase()) || x.state != null && x.state.includes(this.term));
            } else if (this.selecteddrpVal == '724') {
              this.allCustomers = this.defaltAccountColumns.filter(x => x.zip != null && x.zip.includes(this.term));
            } else if (this.selecteddrpVal == '704') {
              this.allCustomers = this.defaltAccountColumns.filter(x => x.phone != null && x.phone.includes(this.term));
            }
            selfObj.SearchResultTable = 'Account';           
          } else {
            this.allCustomers = [];
            this.showSearchTabGrid = false;
          }
        }
        else if (selfObj.SearchType == 'contactName') {         
          if (selfObj.term == '') {
            selfObj.term = null; this.showSearchTabGrid = false;
          } else { }
          
          selfObj.SearchResultTable = 'Contact';
        }
      } else {
        selfObj.subject.next();
       
      }
    }, 200);

    
  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  OpenAccount(accId, customertype) {
    this.applicationComponent.hideSubButton = false;
    if (this.SearchType == "accountName") {
      if (customertype == 1 || customertype == 2) {
        var url = window.location.href;
        if (url.indexOf('account') > -1) {
          let selfobj = this;
          $(setTimeout(function () {

            selfobj.router.navigateByUrl(`/application/account/Customer`);
          }, 50));
          selfobj.router.navigateByUrl(`/application`);
        }
        else {
          this.router.navigateByUrl(`/application/account/Customer`);
        }

        localStorage.setItem('RhxAccountVal', accId);

        let selfobj = this;
        setTimeout(function () {
          selfobj.applicationComponent.ExpandSelectedMenu('customers');
          $(".subButtons").removeClass("activeButton");
          selfobj.applicationComponent.selectedtabid = 'Customer';
        }, 200)
      } else {
        var url = window.location.href;
        if (url.indexOf('account') > -1) {
          let selfobj = this;
          $(setTimeout(function () {

            selfobj.router.navigateByUrl(`/application/account/Accounts`);
          }, 50));
          selfobj.router.navigateByUrl(`/application`);
        }
        else {
          this.router.navigateByUrl(`/application/account/Accounts`);
        }

        localStorage.setItem('RhxAccountVal', accId);

        let selfobj = this;
        setTimeout(function () {
          selfobj.applicationComponent.ExpandSelectedMenu('Accounts');
          $(".subButtons").removeClass("activeButton");
        }, 200)
      }
    } else {
      var url = window.location.href;
      if (url.indexOf('contacts') > -1) {
        let selfobj = this;
        $(setTimeout(function () {

          selfobj.router.navigateByUrl(`/application/contacts/contacts/null`);
        }, 50));
        selfobj.router.navigateByUrl(`/application`);
      }
      else {
        this.router.navigateByUrl(`/application/contacts/contacts/null`);
      }
      localStorage.setItem('RhxContactVal', accId);
      $('#emailIds').val(accId);
      $('#tasklistselectedtyle').val('Contact');
     
      let selfobj = this;
      setTimeout(function () {
        selfobj.applicationComponent.ExpandSelectedMenu('contact');
        selfobj.applicationComponent.selectedtabid = 'contact';
      }, 200)
    }
  }
  AccountrowClickAgGrid(rowEvent) {
    this.OpenAccount(rowEvent.data.id, rowEvent.data.customertype);   
  }
  AccountcellDoubleClicked(rowEvent) {
    this.applicationComponent.QuickSearchDiv = false;
    this.applicationComponent.hideSubButton = false;   
    if (rowEvent.data.isCustomer == 874) {
      var url = window.location.href;
      if (url.indexOf('account') > -1) {
        let selfobj = this;
        $(setTimeout(function () {
          selfobj.router.navigateByUrl(`/application/account/Customer`);
        }, 50));
        selfobj.router.navigateByUrl(`/application`);
      }
      else {
        this.router.navigateByUrl(`/application/account/Customer`);
      }

      localStorage.setItem('RhxAccountVal', rowEvent.data.id);
      
      let selfobj = this;
      setTimeout(function () {
        selfobj.applicationComponent.ExpandSelectedMenu('customers');
        $(".subButtons").removeClass("activeButton");
        selfobj.applicationComponent.selectedtabid = 'Customer';
      }, 200)
    } else {
      var url = window.location.href;
      if (url.indexOf('account') > -1) {
        let selfobj = this;
        $(setTimeout(function () {

          selfobj.router.navigateByUrl(`/application/account/Accounts`);
        }, 50));
        selfobj.router.navigateByUrl(`/application`);
      }
      else {
        this.router.navigateByUrl(`/application/account/Accounts`);
      }

      localStorage.setItem('RhxAccountVal', rowEvent.data.id);
      
      let selfobj = this;
      setTimeout(function () {
        selfobj.applicationComponent.ExpandSelectedMenu('Accounts');
        $(".subButtons").removeClass("activeButton");
        //selfobj.selectedtabid = 'Customer';
      }, 200)
    }
  }
  ContactcellDoubleClicked(rowEvent) {
    this.applicationComponent.QuickSearchDiv = false;
    this.applicationComponent.hideSubButton = false;
    localStorage.setItem('RhxContactVal', rowEvent.data.id);
    let SO = this;
    var url = window.location.href;
    if (url.indexOf('contacts') > -1 || url.indexOf('customercontacts') > -1) {
  
      $(setTimeout(function () {
        SO.applicationComponent.activateSubButtons('empty');
      $(setTimeout(function () {
        if (rowEvent.data.customertype == 1 || rowEvent.data.customertype == 2) {

          SO.router.navigateByUrl(`/application/customercontacts`);
          SO.applicationComponent.ExpandSelectedMenu('customers');

        } else {
          SO.router.navigateByUrl(`/application/contacts/contacts/null`);
          SO.applicationComponent.ExpandSelectedMenu('contact');
        }
        SO.applicationComponent.selectedtabid = 'contact';
      }, 100));
       }, 50));
    } else {
      $(setTimeout(function () {
        if (rowEvent.data.customertype == 1 || rowEvent.data.customertype == 2) {

          SO.router.navigateByUrl(`/application/customercontacts`);
          SO.applicationComponent.ExpandSelectedMenu('customers');

        } else {
          SO.router.navigateByUrl(`/application/contacts/contacts/null`);
          SO.applicationComponent.ExpandSelectedMenu('contact');
        }
        SO.applicationComponent.selectedtabid = 'contact';
      }, 100));
    }
  }
  ContactrowClickAgGrid(rowEvent) {
    this.applicationComponent.QuickSearchDiv = false;
    this.applicationComponent.hideSubButton = false;
    localStorage.setItem('RhxContactVal', rowEvent.data.id);

    let SO = this;
    $(setTimeout(function () {
      SO.applicationComponent.activateSubButtons('empty');
      $(setTimeout(function () {
        SO.router.navigateByUrl(`/application/contacts/contacts/null`);
        SO.applicationComponent.ExpandSelectedMenu('contact');
        SO.applicationComponent.selectedtabid = 'contact';
      }, 100));
    }, 50));
  }
  closeQuickSearch() {   
    this.applicationComponent.QuickSearchDiv = false;
    this.applicationComponent.hideSubButton = false;    
  }
  
  changeSearchTypeRadio(val) {
   
    this.initializeAgGrid();
  }

  IncludeSharedAccounts() {
    var userIds = [];
    this.selectedItems.forEach(ele => {
      userIds.push(ele.id);

    });
   
      if (this.term != '' || this.term != null || this.term != undefined) {
        if (this.SearchType == 'accountName') {
          this.SearchResultTable = 'Account';
        } else if (this.SearchType == 'contactName') {
          this.SearchResultTable = 'Contact';
        }
        this.GetContactSearchList(this.term, userIds, this.isactivecustomer, this.isinactivecustomer, this.issharedaccounts);
    
    }

  }

  SaveQuickSearchConfigToLocal(searcvaiId) {
    this.userservice.saveLastQuickSearchVal(searcvaiId, this.currentuser.id).then(result => {
      this.currentuser.lastSearchby = searcvaiId;
      localStorage.setItem('currentUser', JSON.stringify(this.currentuser));
    });
    
  }
  IconClick(val) {
    this.applicationComponent.QuickSearchDiv = false;
    this.applicationComponent.hideSubButton = false;

    $('#tasklistselectedtyle').val('Customer');
    this.applicationComponent.activateMainButtons(val);
  }
  RHxTab(val) {
    if (val == 'Contacts') {
      this.RHxAccountSelected = false;
      this.RHxContactSelected = true;
      this.GetRHxNames('Contact');
    } else if (val == 'Accounts') {
      this.RHxAccountSelected = true;
      this.RHxContactSelected = false;
      this.GetRHxNames('Account');
    }
    localStorage.setItem('RHxTabselected', val);
  }
  GetRHxNames(Type) {
    this.customerService.GetRecentHistoryNames(Type, this.currentuser.id).then((RHxNameslist) => {
      this.RHxNames = RHxNameslist;
    });
  }
  OpenAccountOrContact(Rhx) {
    this.closeQuickSearch();
    this.applicationComponent.OpenAccountOrContact(Rhx); 
  }
  onFilterTextBoxChanged() {
    this.showSearchTabGrid = true;    
    if (this.selectedval == '702' || this.selectedval == '723' || this.selectedval == '706' ||
      this.selectedval == '724' || this.selectedval == '704') {
      this.SearchType = "accountName";
      this.SearchResultTable = 'Account';     
    }
    else {
      this.SearchType = "contactName";
      this.SearchResultTable = 'Contact';    
    }
    localStorage.setItem('lastSearchby', this.SearcValueModel.toString());  
    this.Search('');
  }
}
