<input type="text" id="userids" style="display:none;" />
<input type="text" id="test" style="display:none;" />
<input type="text" id="emailIds" style="display:none;" />
<input type="text" id="tasklistselectedtyle" style="display:none;" />
 
<div style="height: 11%;">
  <div class="col-xs-12" style="height: 49%;padding:0%;margin-bottom:0.2%">
    <div class="col-xs-1" style="width:10%;padding:0% 0% 0.5% 0.5%">
      <img src="../../assets/img/salesxcrm.png" *ngIf="hideLabel==true" style="height:25pt;margin-top:2.5%" (click)="hideShowCommonTabs(false);" />
      <img src="../../assets/img/salesxcrm.png" *ngIf="hideLabel==false" style="height:25pt; margin-top: 2.5%" (click)="hideShowCommonTabs(true)" />
    </div>
    <p class="col-xs-1" style="padding: 1% 0% 0% 1%; width: 14%;font-size:12px;">
      {{currentdatetime | date:'EEEE   MMMM d, yyyy h:mm a'}}
    </p>
    <div class="col-xs-7" style="padding: 0% 0% 0% 13%; width: 56% ">
      <div id="commontabs" class="col-md-12 col-sm-12 col-xs-12" style="text-align:right;float:right">
        <nav id="nav-menu-container">
          <ul class="footer-nav" style="width:125%;">
            <li class="subButtons" name="Customer" id="Customer_button" style="margin-left: 60px;border: none !important; height: 28px; width: 11%; display: none">
            </li>
            <li class="subButtons" name="Search" id="Search" title="Search" style="border:none !important;height: 25px;width: 5%;">
              <img *ngIf="pageViewed !== 'Search'" src="../../assets/img/Search Icon Green.png" style="height:35px;width:35px;" (click)="showSearchOption()" />
              <img *ngIf="pageViewed === 'Search'" src="../../assets/img/Search Icon Orange.png" style="height:35px;width:35px;" (click)="showSearchOption()" />

            </li>
            <li class="subButtons" name="Schedule Task" id="schedTask_button" title="Schedule Task" (click)="activateMainButtons('Create Task')"
                style="border: none !important; height: 25px; width: 5%;">
              <img *ngIf="pageViewed !== 'ScheduleTask'" src="../../assets/img/Create Task Icon V4.png" style="height:35px;width:35px;" />
              <img *ngIf="pageViewed === 'ScheduleTask'" src="../../assets/img/Create Task Icon V4 Orange.png" style="height:35px;width:35px;" />
            </li>
            <li class="subButtons" name="SMS" id="sms_button" title="Create SMS" (click)="activateMainButtons('Create Text')"
                style="border: none !important; height: 25px; width: 5%;">
              <img *ngIf="pageViewed !== 'CreateSMS'" src="../../assets/img/Create SMS Icon.png" style="height: 35px; width: 35px;" />
              <img *ngIf="pageViewed === 'CreateSMS'" src="../../assets/img/Create SMS Icon Orange.png" style="height: 35px; width: 35px;" />
            </li>
            <li class="subButtons" name="Email" id="email_button" title="Create Email" (click)="activateMainButtons('Create eMail')"
                style="border: none !important; height: 25px; width: 5%;">
              <img *ngIf="pageViewed !== 'CreateEmail'" src="../../assets/img/Create Email Icon.png" style="height:35px;width:35px;" />
              <img *ngIf="pageViewed === 'CreateEmail'" src="../../assets/img/Create Email Icon Orange.png" style="height:35px;width:35px;" />
            </li>
            <li class="subButtons" name="QuickView" id="quickview_button" title="Quick View" (click)="selectedbuttonText='Quick View';activateMainButtons('QuickView')"
                style="border: none !important; height: 25px; width: 5%;">
              <img *ngIf="pageViewed !== 'QuickView'" src="../../assets/img/Quickview Icon white and black on green.png" style="height: 35px; width: 35px; " />
              <img *ngIf="pageViewed === 'QuickView'" src="../../assets/img/Quickview Icon soft orange.png" style="height:35px;width:35px;" />
            </li>
            <li class="subButtons" name="Create Call List" id="calllist_button" title="Create Call List" (click)="selectedbuttonText='Create Call List ';activateMainButtons('Create Call List')"
                style="border: none !important; height: 25px; width: 5%;">
              <img *ngIf="pageViewed !== 'CreateCallList'" src="../../assets/img/Create Call List Icon.png" style="height:35px;width:35px;" />
              <img *ngIf="pageViewed == 'CreateCallList'" src="../../assets/img/Create Call List Icon Orange.png" style="height:35px;width:35px;" />

            </li>
            <li class="subButtons" name="Create Proposal" id="proposal_button" title="Create Proposal"
                style="border: none !important; height: 25px; width: 5%;" (click)="selectedbuttonText='Create Proposal';activateMainButtons('Create Proposal')">
              <img *ngIf="pageViewed !== 'CreateProposal'" src="../../assets/img/Create Proposal Icon.png" style="height:35px;width:35px;" />
              <img *ngIf="pageViewed === 'CreateProposal'" src="../../assets/img/Create Proposal Icon Orange.png" style="height:35px;width:35px;" />
            </li>

            <li class="subButtons" name="Opportunity" id="Opportunity_button" title="Create Opportunity" style="border: none !important; height: 25px; width: 5%;">
              <img (click)="activateMainButtons('Create Opportunity')" *ngIf="pageViewed !== 'CreateOpty'" src="../../assets/img/Create Opportunity Icon green.png" style="height:35px;width:35px;" />
              <img (click)="activateMainButtons('Create Opportunity')" *ngIf="pageViewed === 'CreateOpty'" src="../../assets/img/Create Opportunity Icon orange.png" style="height:35px;width:35px;" />
            </li>

            <li class="subButtons" name="Projects" id="projects_button" title="Create Project" style="border: none !important; height: 25px; width: 5%;">
              <img (click)="activateMainButtons('Create Project')" *ngIf="pageViewed !== 'CreateProject'" src="../../assets/img/Add PRoject.png" style="height:35px;width:35px;" />
              <img (click)="activateMainButtons('Create Project')" *ngIf="pageViewed === 'CreateProject'" src="../../assets/img/Add Project orange.png" style="height:35px;width:35px;" />

            </li>

            <li class="subButtons" name="Tickets" id="tickets_button" title="Create Ticket" style="border: none !important; height: 25px; width: 5%;">
              <img (click)="activateMainButtons('Create Tickets')" *ngIf="pageViewed !== 'CreateTicket'" src="../../assets/img/Create Support Ticket Icon.png" style="height:35px;width:35px;" />
              <img *ngIf="pageViewed === 'CreateTicket'" src="../../assets/img/Create Support Ticket Icon Orange.png" style="height:35px;width:35px;" />
            </li>

            <li class="subButtons" name="Responsewizard" id="Responsewizard_button" style="border: none !important; height: 25px; width: 5%;" title=" Response Wizard">
              <img src="../../assets/img/RW light green_1.png" *ngIf="!RWActive" style="height:35px;width:35px;" (click)="selectedbuttonText='Response Wizard';RWActiveClick(1)" />
              <img src="../../assets/img/RW orange_1.png" *ngIf="RWActive" style="height:35px;width:35px;" (click)="selectedbuttonText='Response Wizard';RWActiveClick(0)" />
            </li>

            <li id="MapIt_button" name="MapIt" (click)="selectedbuttonText='Map it';activateMainButtons('Map It')" class="subButtons" title="MapIt" style="border: none !important; height: 25px; width: 5%;">
              <img *ngIf="pageViewed !== 'MapIt'" src="../../assets/img/Map it.png" style="height:35px;width:35px;" />
              <img *ngIf="pageViewed === 'MapIt'" src="../../assets/img/Map it Orange.png" style="height:35px;width:35px;" />

            </li>

            <li class="subButtons" name="new" id="new_button" style="border: none !important; height: 25px; width: 5%;" title="Query Builder">
              <img src="../../assets/img/Advanced Search SQL Icon Green.png" *ngIf="!showAdvancedSearchDialog" style="height:35px;width:35px;" (click)="selectedbuttonText='Query Builder';advancedSearch($event)" />
              <img src="../../assets/img/Advanced Search SQL Icon Orange.png" *ngIf="showAdvancedSearchDialog" style="height:35px;width:35px;" (click)="selectedbuttonText='Query Builder';advancedSearch($event)" />
            </li>

            <li class="subButtons" (click)="selectedbuttonText='Create New Account';activateMainButtons('Add Account')" name="addaccount" id="addaccount_button" style="border: none !important; height: 25px; width: 5%;" title="Add Account">
              <img *ngIf="pageViewed !== 'AddAccount'" src="../../assets/img/Add Account Icon.png" style="height:35px;width:35px;" />
              <img *ngIf="pageViewed === 'AddAccount'" src="../../assets/img/Add Account Icon Orange.png" style="height:35px;width:35px;" />
            </li>
            <li class="subButtons" name="new" id="new_button" style="border: none !important; height: 25px; width: 5%;" title=" Export">
              <img (click)="activateMainButtons('imported')" src="../../assets/img/Export Icon.png" style="height:35px;width:35px;" />
            </li>
            <li class="subButtons" name="new" id="new_button" style="border: none !important; height: 25px; width: 5%;" title=" Print ">
              <img (click)="activateMainButtons('imported')" src="../../assets/img/Print Icon.png" style="height:35px;width:35px;" />
            </li>
          </ul>
        </nav>
      </div>

    </div>

    <div class="col-xs-1" style=" width: 12%; font-weight: 600; padding: 0.5% 0% 0% 6%;margin-left: 60px">
      Welcome
      <div style="font-size:12px" *ngIf="currentuser.username.length<=20">{{currentuser.username}}</div>
      <div *ngIf="currentuser.username.length>20" style="font-size:12px">{{currentuser.firstname.charAt(0)}} {{currentuser.lastname}}</div>
    </div>
    <div class="col-xs-1 pull-right" style="padding: 0.5% 0% 0% 0%;width: 3%; text-align: center; ">
      <img src="../../assets/img/REVISED 3 DOT ICON.png" (click)="showSearchFieldsDialogfun('True')" style="height: 20px; margin-top: 5%;" />
    </div>
  </div>
<div class="col-xs-12" style="height:5%;background:rgb(186,220,153)"></div>
  <div class="col-xs-12" style="margin-top: 0.1%; color: white; padding-right: 0; margin-top: 0%; height: 35%; border: 1px solid #A0AAB3; background: rgb(40,62,74) ">
    <p class="col-xs-10" style="padding: 0.5% 0% 0% 0%; height: 100%; width: 93%; font-size: 12px;">
      MD Logic, Inc.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{selectedbuttonText}}
    </p>
    <div class="col-xs-2" style=" width: 7%; font-weight: 600; padding:0.1% 0% 0.1% 0.1%;">
      <div class="col-xs-4" style="padding:0" (click)="selectedbuttonText='My Notes';openMyNotes()">
        <img *ngIf="(currentuser.followupNotes!=null || currentuser.goalNotes!=null || currentuser.miscNotes!=null || currentuser.personalNotes!=null)" src="../../assets/img/Notes Icon.png" style="height: 28px; width: 25px;" />
        <img *ngIf="(currentuser.followupNotes==null && currentuser.goalNotes==null && currentuser.miscNotes==null && currentuser.personalNotes==null)" src="../../assets/img/Notes Grey with Circle.png" style="height: 28px; width: 25px;" />
      </div>
      <div class="col-xs-4" style="padding:0" (click)="activateMainButtons('Internal Messages');selectedbuttonText='User Messaging';">
        <img *ngIf="inboxmessagecount > 0" src="../../assets/img/Messages Green with Circle.png" style="height:28px;width: 25px;" />
        <img src="../../assets/img/Messages Grey with Circle.png" *ngIf="inboxmessagecount == 0" style="height: 28px; width: 25px; " />
      </div>
      <div class="col-xs-4" style="padding:0" (click)="selectedbuttonText='Chat';LiveChat()">
        <img *ngIf="ChatMessageCount==0" src="../../assets/img/Live Chat Grey with Circle.png" style="height: 28px; width: 25px; " />
        <img *ngIf="ChatMessageCount>0" src="../../assets/img/Live Chat Green with Circle.png" style="height: 28px; width: 25px; " />
      </div>


      <!--<div class="col-xs-2" style="padding:0" (click)="selectedbuttonText='Tickets';activateMainButtons('tickets')">
    <img src="../../assets/img/Tickets Green with Circle.png" *ngIf="OpenTicketCountLoginUser > 0" style="height: 28px;width: 25px; " />
    <img src="../../assets/img/Tickets Grey with Circle.png" *ngIf="OpenTicketCountLoginUser == 0" style="height: 28px;width: 25px; " />
  </div>

  <div class="col-xs-2" style="padding:0" (click)="selectedbuttonText='Account Transfer';ShowAccountsTransfered()">
    <img *ngIf="AllTransferedAccounts && AllTransferedAccounts.length > 0" src="../../assets/img/Acct Transfer Green with Circle.png" style="height: 28px; width: 25px;" />
    <img *ngIf="AllTransferedAccounts == undefined || AllTransferedAccounts == null || AllTransferedAccounts.length == 0" src="../../assets/img/Acct Transfer Grey with Circle.png" style="height: 28px; width: 25px;" />
  </div>-->


    </div>

  </div>


  <div class="row" [hidden]="globalMultiSelect" style="flex:auto;padding:1%;margin:0;margin-top:4px; width:100%;background-color:white;overflow:auto" id="actiondivid">
    <div class="col-md-4 col-sm-6 col-xs-12" style="padding:  0;">
      <div class="col-md-2 col-sm-1 col-xs-3" style="padding:  0;">
        <label style="padding-top:5px">User List</label>
      </div>
      <div class="col-md-10 col-sm-11 col-xs-9" style="padding: 0%;padding-right: 0.5%;">
        <angular2-multiselect [data]="dropdownList" [(ngModel)]="globalSelectedItems"
                              (onSelect)="onItemSelect($event)"
                              (onDeSelect)="OnItemDeSelect($event)"
                              (onSelectAll)="onSelectAll($event)"
                              (onDeSelectAll)="onDeSelectAll($event)">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12 col-md-offset-4 col-sm-offset-6 col-xs-offset-0" style="padding: 0;">
      <div class="col-xs-12 col-md-3 col-sm-12 col-md-offset-3" style="padding: 0;">
        <my-date-picker id="startDate" name="startDate" [options]="startDatePickerOptions" [placeholder]="startDatePlaceHolder" (dateChanged)="dateChange($event)" style="border-left:none !important" required></my-date-picker>
      </div>
      <div class="col-xs-12 col-md-3 col-sm-12" style="padding: 0;    padding-left: 0.3%; padding-right: 0;">
        <my-date-picker name="endDate" [options]="endDatePickerOptions" [placeholder]="endDatePlaceHolder" (dateChanged)="dateChange($event)" style="border-left:none !important" required></my-date-picker>
      </div>

      <div class="col-md-3 col-sm-12 col-xs-12" style="padding: 0;padding-left: 3%;">
        <label class="col-sm-2 control-label" style="padding:0;padding-left:2%; margin-top: 5px;">Size</label>
        <div class="col-sm-10" style="padding-right:0">
          <input class="form-control" type="text" style="height: 29px;" name="Page Size" [(ngModel)]="pageSize" (change)="txtPageSizeChanged($event.target.value)">
        </div>
      </div>
    </div>
  </div>
  <input type="text" id="accotIdNotes" style="display:none;" />
  <input type="text" id="accotTypeNotes" style="display:none;" />

  <app-dialog [(visible)]="notesshowDialog" *ngIf="notesshowDialog">
    <app-notes [title]="'Notes Info'"
               [account]="account"
               [gridOptionsnotes]="gridOptionsnotes"
               [allnotes]="allnotes"
               [context]="context"
               [columnDefsnotes]="columnDefsnotes"
               [contact]="contact"
               [tabName]="'Contact'"
               [conid]="conid"
               [id]="conid"
               [typ]="typ">
    </app-notes>

  </app-dialog>
  <app-dialog [(visible)]="customernotesshowDialog">
    <div class="col-xs-12 provider" style="text-align:center;margin-top: 2%;margin-left: 1%;width: 98%;">
      <div class="col-xs-4" style="text-align:left">
        <div><b class="providerLabel" style="font-size:17px;">{{account.accountname}}</b></div>
        <div class="providerLabel">{{account.address1}}</div>
        <div>{{account.address2}}</div>
        <div>
          <span>{{account.city}} , {{account.state}}. {{account.zip}}, {{account.country}}</span>

        </div>
      </div>
      <div class="col-xs-4">
        <h3>Customer Notes Info</h3>
      </div>
      <div class="col-xs-4" style="text-align:right">
        <div><b>Customer:</b>{{account.accountname}}</div>
        <div style="margin-top: 6%;"><button class="pull-right noprovider" (click)="enterNotes()" style="width:50%">Add New Notes</button></div>
      </div>

    </div>
    <div class="col-xs-12" style="padding: 0 1.25% 0 1.25%;">
      <div id="noteid" [froalaEditor]="editorOptions" [(froalaModel)]="notedescription" style="padding-right: 0%;display:none;margin-top:0.5%"></div>

      <div class="col-xs-12" id="saveNotesButtons" style="display:none;margin-top:0.5%;padding:0;">
        <div class="col-xs-6" id="saveNotesButtons" style="margin-top:0.5%;padding: 0;">
          <button class="btn btn-default" (click)="CancelNote()" type="submit" style="padding: 0.75%;border: 1px solid;background-color: #dddddd;">Cancel Note</button>
        </div>
        <div class="col-xs-6" id="saveNotesButtons" style="margin-top:0.5%;padding: 0;">
          <button class="btn noprovider" (click)="SaveNote()" type="submit" style="margin-left: 1%;padding: 0.75%;width: 89.75%;">Save Note</button>
        </div>
      </div>

    </div>

    <div class="col-xs-12" id="divTable" style="margin-top: 1%;margin-bottom: 2%;width: 98%;margin-left: 1%;margin-right: 55%;">
      <ul class="nav nav-tabs" id="notesListTabsId" style="margin-top:10px">
        <li class="active" id="allNotesId"><a data-toggle="tab" (click)="Notes('Customer')">All</a></li>
        <li><a data-toggle="tab" (click)="Notes('CustomerOpportunity')">Opportunities</a></li>
        <li><a data-toggle="tab" (click)="Notes('CustomerTaskHistory')">Tasks</a></li>
        <li><a data-toggle="tab" (click)="Notes('CustomerLead')">Leads</a></li>
        <li><a data-toggle="tab" (click)="Notes('Customer')">All Contacts Notes</a></li>
      </ul>

      <div class="tab-content">
        <div id="home" class="tab-pane fade in active">
          <ag-grid-angular class="ag-theme-balham"
                           style="height:400px"
                           [gridOptions]="gridOptionsnotes"
                           [columnDefs]="columnDefsnotes"
                           [defaultColDef]="defaultGridColDef"
                           [rowData]="allnotes"
                           [sideBar]="sideBar"
                           [context]="context"
                           [suppressDragLeaveHidesColumns]="true"
                           (gridReady)="onAgGridReadynotes($event)">
          </ag-grid-angular>
        </div>

      </div>

    </div>

  </app-dialog>

  <!--</div>-->

</div>
  <div [hidden]="hideGlobalSearchtable">
    <div style="flex:auto;padding:0;margin:0; width:100%" id="actiondivid">

      <div class="col-md-4 col-sm-6 col-xs-12" style="padding:0;">
        <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding: 0;">
          <div class="col-xs-2">
            <label>Include:</label>
          </div>
          <div class="col-xs-3" style="padding: 0%">
            <input type="checkbox" id="Customers" (click)="loadselectedcontacts()" value="Customers" [(ngModel)]="isCustomer"> Customers<br>
          </div>
          <div class="col-xs-4" style="padding: 0%">
            <input type="checkbox" id="FormerCustomers" (click)="loadselectedcontacts()" value="FormerCustomers" [(ngModel)]="isFormerCustomer"> Former Customers<br>
          </div>
        </div>
      </div>

    </div>
    <ag-grid-angular [style.height]="aggridheight" [hidden]="hideGlobalSearchtable" style="width: 100%; min-height:400px; padding-right:1%;padding-left:1%;overflow:auto" id="globalGriddheight" (window:resize)="ResizeTable()" class="ag-theme-balham"
                     [gridOptions]="globalGridOptions"
                     [columnDefs]="globalColumnDefs"
                     [defaultColDef]="defaultGridColDef"
                     [rowData]="tableData"
                     [context]="context"
                     [sideBar]="sideBar"
                     [frameworkComponents]="frameworkComponents"
                     (rowSelected)="rowClickAgGrid($event)"
                     (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                     (columnVisible)="columnVisible($event)"
                     [suppressDragLeaveHidesColumns]="true"
                     (dragStopped)="dragStopped($event)"
                     (columnResized)="columnResized($event)"
                     (gridReady)="onAgGridReady()">
    </ag-grid-angular>
  </div>

  <div class="content-wrapper bodyContaint " [hidden]="(!hideGlobalSearchtable )" style="height:88%">
    <div *ngIf="showmenu" [ngClass]="(!hideLabel)? 'col-xs-1':'col-xs-2'"
     id="SideMenu" style="margin: 0.5% 0 0 1%;height:100%; padding: 0;
     border-right: 2px solid rgb(186,219,152); " [ngStyle]="{'width.%':(!hideLabel) ? 2.5 : 8.5}">
      <div class="col-xs-12 fancy-collapse-panel" style="padding:0;height:100%">
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
          <div class="Dashpanel panel-default" style="min-height: 6%;">
            <div class="panel-heading" role="tab" id="headingOne">
              <h5 class="panel-title">
                <a class="collapsed activeButton" title="Dashboards" data-toggle="collapse" data-parent="#accordion" aria-expanded="true" id="dashboardsMainMenu" aria-controls="dashboard" (click)="activateMainButtons('dashboards');selectedbuttonText='Dashboards';selectedsubbutton='';">
                  <img src="../../assets/img/Dashboard Light Black.png" style="height: 25px;" *ngIf="selectedtabid !== 'dashboards'">
                  <img src="../../assets/img/Dashboard Black.png" style="height: 25px" *ngIf="selectedtabid === 'dashboards'">
                  <label *ngIf="hideLabel">&nbsp;&nbsp;Dashboards</label>

                </a>
              </h5>
            </div>

          </div>

          <div class="Dashpanel panel-default" style="min-height: 6%;">
            <div class="panel-heading" role="tab" id="headingTwo">
              <h5 class="panel-title">
                <a class="collapsed" title="Recent" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="RecentMainMenu" aria-controls="Account" (click)="selectedbuttonText='Recent';activateMainButtons('Recent');">
                  <img src="../../assets/img/Recent Light Black.png" style="height: 25px;" *ngIf="selectedtabid !== 'RecentHistory'">
                  <img src="../../assets/img/Recent Black.png" style="height: 25px" *ngIf="selectedtabid === 'RecentHistory'">
                  <label *ngIf="hideLabel">&nbsp;&nbsp;Recent</label>
                  <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'RecentHistory' && limitedSearch">
                </a>

              </h5>
            </div>

          </div>

          <!--<div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingTwo">
            <h5 class="panel-title">
              <a class="collapsed" title="QuickSearch" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="QuickSearchMainMenu" aria-controls="QuickSearch" (click)="activateMainButtons('QuickSearch');">
                <img src="../../assets/img/Search Light Black.png" style="height: 25px;" *ngIf="selectedtabid !== 'QuickSearch'">
                <img src="../../assets/img/Search Black.png" style="height: 25px" *ngIf="selectedtabid === 'QuickSearch'">
                <label>&nbsp;&nbsp;Search</label>
                <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'QuickSearch' && limitedSearch">
              </a>

            </h5>
          </div>

        </div>-->

          <div class="Dashpanel panel-default" style="min-height: 6%; margin-bottom: 16px !important; ">
            <div class="panel-heading" role="tab" id="headingTwo">
              <h5 class="panel-title">
                <a class="collapsed" title="Accounts" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="AccountsMainMenu" aria-controls="Account" (dblclick)="Search(true)" (click)="selectedbuttonText='Accounts';activateMainButtons('Accounts');selectedsubbutton='';">
                  <img src="../../assets/img/Account Light Black.png" style="height: 25px;" *ngIf="selectedtabid !== 'Account'">
                  <img src="../../assets/img/Account Black.png" style="height: 25px" *ngIf="selectedtabid === 'Account'">
                  <label *ngIf="hideLabel">&nbsp;&nbsp;Accounts</label>
                  <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'Account' && limitedSearch">
                </a>

              </h5>
            </div>

          </div>

          <div class="Dashpanel panel-default" style="min-height: 6%; margin-bottom: 16px !important; ">
            <div class="panel-heading" role="tab" id="headingTwo">
              <h5 class="panel-title">
                <a class="collapsed" title="Contacts" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="contactMainMenu" aria-controls="contact" (dblclick)="Search(true)" (click)="selectedsubbutton='';selectedbuttonText='Contacts';activateMainButtons('contact');">
                  <img src="../../assets/img/Contact Light Black.png" style="height: 25px;" *ngIf="selectedtabid !== 'contact'">
                  <img src="../../assets/img/Contact Black.png" style="height: 25px" *ngIf="selectedtabid === 'contact'">
                  <label *ngIf="hideLabel">&nbsp;&nbsp;Contacts</label>
                  <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'contact' && limitedSearch">
                </a>
              </h5>
            </div>

          </div>

          <div class="Dashpanel panel-default" style="min-height: 6%;margin-bottom: 16px !important;">
            <div class="panel-heading" role="tab" id="headingThree">
              <h5 class="panel-title">

                <a class="collapsed" title="Leads" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="leadsMainMenu" aria-controls="leads" (dblclick)="Search(true)" (click)="selectedsubbutton='';selectedbuttonText='Leads';activateMainButtons('leads');">
                  <img src="../../assets/img/Leads Alternate Blue.png" style="height: 25px;" *ngIf="selectedtabid !== 'leads'">
                  <img src="../../assets/img/Leads Alternate Black.png" style="height: 25px" *ngIf="selectedtabid === 'leads'">
                  <label *ngIf="hideLabel">&nbsp;&nbsp;Leads</label>
                  <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'leads' && limitedSearch">
                </a>
              </h5>
            </div>

          </div>


          <div class="Dashpanel panel-default" style="margin-bottom: 16px !important; min-height: 6%">
            <div class="panel-heading" role="tab" id="headingThree">
              <h5 class="panel-title">

                <a class="collapsed" title="Opportunities" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="opportunitiesMainMenu" aria-controls="opportunities" (dblclick)="Search(true)" (click)="selectedsubbutton='';selectedbuttonText='Opportunities';activateMainButtons('opportunities');">
                  <img src="../../assets/img/Opportunities Light Black.png" style="height: 25px;" *ngIf="selectedtabid !== 'opportunities'">
                  <img src="../../assets/img/Opportunities Black.png" style="height: 25px" *ngIf="selectedtabid === 'opportunities'">
                  <label *ngIf="hideLabel">&nbsp;&nbsp;Opportunities</label>
                  <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'opportunities' && limitedSearch">
                </a>
              </h5>
            </div>

          </div>

          <div class="Dashpanel panel-default" style="margin-bottom: 16px !important; min-height: 6%">
            <div class="panel-heading" role="tab" id="headingThree">
              <h5 class="panel-title">

                <a class="collapsed" title="Sales ForeCast" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="SalesForecastMainMenu" aria-controls="SalesForeCast" (dblclick)="Search(true)"
                   (click)="selectedsubbutton='';selectedbuttonText='Sales Forecast';activateMainButtons('Sales Forecast');" style="padding-right:0 !important;">
                  <img src="../../assets/img/Sales Forecast Light Black.png" style="height: 25px;" *ngIf="selectedtabid !== 'Sales Forecast'">
                  <img src="../../assets/img/Sales Forecast Black.png" style="height: 25px" *ngIf="selectedtabid === 'Sales Forecast'">
                  <label *ngIf="hideLabel">&nbsp;&nbsp;Sales Forecast</label>
                  <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'Sales Forecast' && limitedSearch">
                </a>
              </h5>
            </div>

          </div>

          <div class="Dashpanel panel-default" style="margin-bottom: 16px !important; min-height: 6%">
            <div class="panel-heading" role="tab" id="headingThree">
              <h5 class="panel-title">

                <a class="collapsed" title="Task List" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="tasklistsMainMenu" aria-controls="tasklist" (dblclick)="Search(true)" (click)="selectedsubbutton='';selectedbuttonText='Task lists';activateMainButtons('task lists')">
                  <img src="../../assets/img/Task Lists Light Black.png" style="height: 25px;" *ngIf="selectedtabid !== 'task lists'">
                  <img src="../../assets/img/Task Lists Black.png" style="height: 25px" *ngIf="selectedtabid === 'task lists'">
                  <label *ngIf="hideLabel">&nbsp;&nbsp;Task List</label>
                  <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'task lists' && limitedSearch">
                </a>
              </h5>
            </div>

          </div>

          <div class="Dashpanel panel-default" style="margin-bottom: 16px !important; min-height: 6%">
            <div class="panel-heading" role="tab" id="headingThree" (click)="selectedsubbutton='';selectedbuttonText='Calendar';activateMainButtons('Calendar');">
              <h5 class="panel-title">

                <a class="collapsed" title="Calendar" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="CalendarMainMenu" aria-controls="Calender">
                  <img src="../../assets/img/Calendar Light Black.png" style="height: 25px;" *ngIf="selectedtabid !== 'TaskCalander'">
                  <img src="../../assets/img/Calendar Black.png" style="height: 25px" *ngIf="selectedtabid === 'TaskCalander'">
                  <label (dblclick)="Search(true)" style="width:75%">
                    <label *ngIf="hideLabel">&nbsp;&nbsp;Calendar</label>
                  </label>
                  <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'TaskCalander' && limitedSearch">
                </a>
              </h5>
            </div>

          </div>

          <div class="Dashpanel panel-default" style="margin-bottom: 16px !important; min-height: 6%">
            <div class="panel-heading" role="tab" id="headingThree">
              <h5 class="panel-title">

                <a class="collapsed" title="Reports" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="reportsMainMenu" aria-controls="reports" (dblclick)="Search(true)" (click)="selectedsubbutton='';selectedbuttonText='Reports';activateMainButtons('reports')">
                  <img src="../../assets/img/Reports Light Black.png" style="height: 25px;" *ngIf="selectedtabid !== 'reports'">
                  <img src="../../assets/img/Reports Black.png" style="height: 25px" *ngIf="selectedtabid === 'reports'">
                  <label *ngIf="hideLabel">&nbsp;&nbsp;Reports</label>
                  <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'reports' && limitedSearch">
                </a>
              </h5>
            </div>
            <!--<div id="reports" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
            <div class="panel-body">
              <ul class="nav">
                <li id="StandardReports_button" class="subButtons activeButton" title="Reports"><a class="layout" routerLinkActive="active" [routerLink]="['/dashboard']" (click)="SubmenuChange('Reports','StandardReports','Sub')">- Standard Reports</a></li>
                <li id="AdminReports_button" class="subButtons " title="dminReports"><a class="layout" routerLinkActive="active" (click)="SubmenuChange('Reports','AdminReports','Sub')">- Admin Reports</a></li>

              </ul>
            </div>
          </div>-->
          </div>

          <div class="Dashpanel panel-default" style="margin-bottom: 16px !important; min-height: 6%">
            <div class="panel-heading" role="tab" id="headingThree">
              <h5 class="panel-title">

                <a class="collapsed" title="Customers" data-toggle="collapse" data-parent="#accordion" id="customersMainMenu" aria-expanded="false" aria-controls="Customers" (dblclick)="Search(true)" (click)="selectedsubbutton='';selectedbuttonText='Customers';activateMainButtons('customers')">
                  <img src="../../assets/img/Customers Blue.png" style="height: 25px;" *ngIf="pageViewed !== 'Customer'">
                  <img src="../../assets/img/Customers Black.png" style="height: 25px" *ngIf="pageViewed === 'Customer'">
                  <label *ngIf="hideLabel">&nbsp;&nbsp;Customers</label>
                  <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'Marketing' && limitedSearch">
                </a>
              </h5>
            </div>
            <div id="Marketing" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
              <div class="panel-body">
                <ul class="nav"></ul>
              </div>
            </div>
          </div>

          <div class="Dashpanel panel-default" style="margin-bottom: 16px !important; min-height: 6%">
            <div class="panel-heading" role="tab" id="headingThree">
              <h5 class="panel-title">

                <a class="collapsed" title="Tickets" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="ticketsMainMenu" aria-controls="tickets" (dblclick)="Search(true)" (click)="selectedsubbutton='';selectedbuttonText='Tickets';activateMainButtons('tickets');">
                  <img src="../../assets/img/Tickets Black.png" style="height: 25px" *ngIf="OpenTicketCountLoginUser == 0">
                  <img src="../../assets/img/Red Ticket Nav Menu.png" style="height: 25px" *ngIf="OpenTicketCountLoginUser > 0">
                  <label *ngIf="hideLabel">&nbsp;&nbsp;Tickets</label>
                  <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'tickets' && limitedSearch">
                </a>
              </h5>
            </div>
          </div>

          <div class="Dashpanel panel-default" style="margin-bottom: 16px !important; min-height: 6%">
            <div class="panel-heading" role="tab" id="headingThree">
              <h5 class="panel-title">

                <a class="collapsed" title="Projects" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="ProjectsMainMenu" aria-controls="Projects" (dblclick)="Search(true)" (click)="selectedsubbutton='';selectedbuttonText='Projects';activateMainButtons('Projects')">
                  <img src="../../assets/img/Projects Light Black.png" style="height: 25px;" *ngIf="selectedtabid !== 'Projects'">
                  <img src="../../assets/img/Projects Light Black.png" style="height: 25px" *ngIf="selectedtabid === 'Projects'">
                  <label *ngIf="hideLabel">&nbsp;&nbsp;Projects</label>
                  <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'Projects' && limitedSearch">
                </a>
              </h5>
            </div>
            <div id="Projects" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
              <div class="panel-body">
                <ul class="nav"></ul>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div [ngClass]="(hideLabel)? 'col-xs-11':'col-xs-10'" class="col-xs-10" *ngIf="selectedsubbutton != 'ShowTransferAccounts' && selectedsubbutton != 'AdvancedSearchResult' && selectedsubbutton != 'Advanced Search' && selectedsubbutton != 'Proposals' && selectedsubbutton != '715' && selectedsubbutton != '426' && selectedsubbutton != '420' && selectedsubbutton != 'ReleaseNotes' && selectedsubbutton != 'Pop-Up Messaging' && selectedsubbutton != 'Recent History' && selectedsubbutton != 'PendedProposal' && selectedsubbutton != 'My Documents' && selectedsubbutton != 'Campaigns' && selectedsubbutton != 'viewcalllist' && selectedsubbutton != '612' && !ShowTransferAccounts " [ngStyle]="{'width.%':(!hideLabel) ? 96.5 : 90.5 , 'padding.%':hideShowCommonTab == true ? '0 1 0 1' : '0 0 0 1','height.%':Searchdiv == true ? '100' : '100'}">
      <router-outlet></router-outlet>
    </div>
    <div class="col-xs-11" *ngIf="selectedsubbutton == 'Proposals'" style="width:96.5%">
      <app-proposals [Type]="'Add'" [ContactOrAccountType]="ProposalContactOrAccountType" [SelectedContactId]="this.Email" [redirectFrom]="'A'" [ProposalID]="ProposalID" (sendMsgEvent)="EventEmit($event)"></app-proposals>
    </div>
    <div class="col-xs-11" *ngIf="(selectedsubbutton == '715')" style="padding: 0; height: 100%; width: 96.5%">
      <user-role-configuration [selectedvalue]="selectedsubbutton" (sendMsgEvent)="CloseAboutus($event)"></user-role-configuration>
    </div>
    <div class="col-xs-11 " *ngIf="(selectedsubbutton == '420')" style="padding: 0; height: 100%; width: 96.5%">
      <dropdown-configuration [type]="selectedsubbutton" [SubMenuType]="PopUpMessageType"></dropdown-configuration>
    </div>
    <div class="col-xs-11" *ngIf="(selectedsubbutton == '426')" style="padding: 0; height: 100%; width: 96.5%">
      <app-configurationdashboard [selectedvalue]="selectedsubbutton"></app-configurationdashboard>
    </div>
    <div class="col-xs-11" style="padding: 0; height: 100%; width: 96.5%" *ngIf="selectedsubbutton == 'ReleaseNotes' ">
      <app-aboutsales-xcrm (sendMsgEvent)="CloseAboutus($event)" [OpenPageType]="AboutUsPageType">

      </app-aboutsales-xcrm>
    </div>
    <div class="col-xs-11" *ngIf="selectedsubbutton == 'Pop-Up Messaging'" style="height: 100%; padding: 0; width: 96.5%">
      <app-user-messaging [OpenDivType]="PopUpMessageType"></app-user-messaging>
    </div>
    <div class="col-xs-11" style="padding: 0; height: 100%; width: 96.5%" *ngIf="selectedsubbutton == 'PendedProposal' ">
      <app-pendedproposals> </app-pendedproposals>
    </div>
    <div class="col-xs-11" style="padding: 0; height: 100%; width: 96.5%" *ngIf="selectedsubbutton == 'Recent History' ">
      <app-rhx [subMenuId]="'Today'"> </app-rhx>
    </div>
    <div class="col-xs-11" *ngIf="selectedsubbutton == '612'" style="height: 100%; padding: 0; width: 96.5%">
      <app-account-transfer-ownership [SubMenuType]="PopUpMessageType" (sendMsgEvent)="CloseAboutus($event)"></app-account-transfer-ownership>
    </div>
    <div class="col-xs-11" style="padding: 0; height: 100%; width: 96.5%" *ngIf="selectedsubbutton == 'viewcalllist' ">
      <app-viewcalllist> </app-viewcalllist>
    </div>

    <div class="col-xs-11" style="padding: 0; height: 100%; width: 96.5%"
         *ngIf="selectedsubbutton == 'ShowTransferAccounts' ">
      <div style="padding: 0; height: 100%;" [ngClass]="(googlemapshow)?'col-xs-5':'col-xs-12'">
     <div class="col-xs-12" style="padding: 0.5%; height: 5%;">
     <span style="float:right" (click)="selectedsubbutton = '';Confirmation(false);googlemapshow = false">
      <img src="../../../assets/img/Black x.png" style="height:20px" />
     </span>
     </div>
     <div class="col-xs-12" style="height:95%;">
       <ag-grid-angular style="height:95%;" class="ag-theme-balham"
                        [gridOptions]="gridOptions"
                        [columnDefs]="AccTransColumnDefs"
                        [defaultColDef]="defaultGridColDef"
                        [rowData]="AllTransferedAccounts"
                        (cellDoubleClicked)="AccountcellDoubleClicked($event)"
                        (rowSelected)="AccountTransferrowClickAgGrid($event)"
                        [sideBar]="sideBar">
       </ag-grid-angular>
     </div>
     <div class="col-xs-12" style="padding:0;height:10%">
    <div class="col-xs-8"></div>
    <div class="col-xs-4">
      <div class="col-xs-6"> <button class="btn btn-default" style="width: 100%;" (click)="Confirmation(false)">Cancel</button></div>
      <div class="col-xs-6">
        <button type="submit" class="btn saveButton" style="width: 100%; background: rgb(162, 228, 119) !important; background-color: rgb(162, 228, 119) !important; color: black; " (click)="Confirmation(true)">Save</button>
      </div>

    </div>
  </div>
      </div>
    </div>
     
        <div class="col-xs-11" style="padding: 0; height: 100%; width: 96.5%" *ngIf="selectedsubbutton == 'AdvancedSearchResult' ">
          <search-result></search-result>
        </div>
        <div class="col-xs-11" style="padding: 0; height: 100%; width: 96.5%" *ngIf="selectedsubbutton == 'Advanced Search' ">
          <advancedsearch [searchOnoff]="searchoffon" (sendMsgEvent)="ReceiveEvent($event)"></advancedsearch>
        </div>
        <div class="col-xs-11" style="padding: 0; height: 100%; width: 96.5%" *ngIf="selectedsubbutton == 'Campaigns' ">
          <app-campaigns [CampaignId]="CampaignId" [ShowViewCampaign]="false" [Source]="" [type]=""> </app-campaigns>
        </div>
        <div class="col-xs-11" style="padding: 0; height: 100%; width: 96.5%" *ngIf="selectedsubbutton == 'My Documents' ">
          <app-sales-xcrm (sendMsgEvent)="CloseAboutus($event)"> </app-sales-xcrm>
        </div>
      </div>
  <input type="text" id="loggedInUserId" style="display:none;" />
  <input type="text" id="loggedInUseremail" style="display:none;" />
  <app-dialog [(visible)]="Iscalllistdialogshow" *ngIf="Iscalllistdialogshow" id="Iscalllistdialogshow" [closable]="false">
    <div class="col-xs-12" style="padding:5%">
      <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
      <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
        <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">CALL LISTS</div><br />
        <div class="col-xs-12" style="padding: 0; padding-top: 3%;">Save Call List?</div>
      </div>
      <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
        <div class="col-xs-7" style="padding: 0;">

        </div>
        <div class="col-xs-5" style="padding:0;">         
          <div class="col-xs-6" style="padding:0">
            <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                    (click)="Calllistsave('Save')">
              SAVE
            </button>
          </div>
          <div class="col-xs-6" style="padding:0">
            <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1" (click)="Calllistsave('Discard')">Discard</button>
          </div>
         
        </div>
      </div>
    </div>

  </app-dialog>

  <app-dialog [(visible)]="IsCreateTicketshow" *ngIf="IsCreateTicketshow" id="IsCreateTicketshow" [closable]="false" [OutSideClick]="false">
    <div class="col-xs-12">
      <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br /><br />
      <div class="col-xs-8" style="padding: 0;">
        <div class="col-xs-12" style="padding: 0; ">Create New Ticket</div>
        <div class="col-xs-12" style="padding: 0; ">User Must Select Save or Cancel</div>
      </div>
      <div class="col-xs-4" style="padding:0;">
        <div class="col-xs-12" style="padding:0">
          <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                  (click)="IsCreateTicketshow = false ">
            OK
          </button>
        </div>

      </div>
    </div>
  </app-dialog>

  <app-dialog [(visible)]="isAccessDenied">
    <div style="text-align:center;font-size:22px;">
      <label style="color:red"> Access Denied</label><br />
      <span>You have "View Only" Access for this Account</span>
      <br />

      <label style="color:red"> Customer Type /Acct# Access Set To No</label><br />
      <span style="font-size:16px">please contact Administrator and Update Update "No" to "Yes"</span>
    </div>
  </app-dialog>
  <app-dialog [(visible)]="resetpwd">
    <form class="form-horizontal" action="/action_page.php">

      <div class="form-group">
        <div class="col-sm-12">
          <h4 style="margin-left:10px;">Update Password</h4>
          <input type="password" class="form-control" placeholder="Password" id="password" (click)="clearerrmsg()" style="width: 95% !important;margin-left:5px;margin-top: 15px;" required>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-12">
          <input type="password" class="form-control" placeholder="Confirm Password" id="confirm_password" (click)="clearerrmsg()" style="width: 95% !important;margin-left:5px; " required>
          <span id="errmsg" style="color:red; margin-left:8px; font-size:12px; display:none">Passwords didn't match</span>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-12">
          <button type="submit" (click)="updatepassword()" class="btn btn-success" style="width: 95% !important;margin-left:5px; ">Submit</button>
        </div>
      </div>
    </form>

  </app-dialog>

  <div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
    <textarea *ngIf="isShowDetailsChecked" id="txtUserFullName" readonly rows="3" name="userInfo" [(ngModel)]="userInfo" style="width:100%; border: none;"></textarea>
    <div class="col-xs-12" style="padding:0;">
      <button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog" style="float:left;position:unset; "><i class="fa fa-window-close-o"></i></button>
    </div>

    <div class="box" style="height:98%;border:none">
      <span *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" class="editnote">Edit</span>
      <span *ngIf="showEditNoteBtn" (click)="saveEditedNote()" class="save">Save</span>
      <div id="txtLongNoteDesc" class="text">
      </div>
    </div>

  </div>

  <app-dialog [(visible)]="ShowInvalidSearchDialog">
    <div style="text-align:center; font-size: 18px;">
      <label style="font-size:22px;"> Attention</label><br /><br /><br />
      <span>SalesXCRM did not find any data that matched your search requirements.</span>
      <br /><br /><br />
      <span>Please go back and modify your search requirements</span>

      <div class="col-xs-12" style="text-align:center">
        <button (click)="CloseInvalidSearchDialog()" style="margin-right: 3%;width: 35%;margin-top:11%;height:37px;    background-color: #92d050 !important;font-weight:600;font-size: 16px;border-radius: 5px;">Close Message</button>
      </div>
    </div>
  </app-dialog>

  <app-dialog [(visible)]="ShowSearchClosewarningDialog">
    <div style=" font-size: 19px;width:95%;margin-left:2%">
      <div style="text-align:center;font-size:21px;">    <b>Column Search Fields Alert</b></div>
      <div style="text-align:center;font-size:21px;"><span> Changes have been made to the default column search fields.</span></div><br />
      <span>
        Fields selected in the search field box determine what fields are searched when text is entered the search box.
        When the default fields are temporarily changed, the system will NOT allow the search field box to be closed
        (by clicking the search field close Icon)..
      </span><br /><br />
      <span>
        The system will allow navigation to another menu or sub-menu in the system. Upon return to this sub-menu, the default search field box will display.
        This alert confirms temporary changes have been made to the default search fields. To clear the changes,
        click on the Green Trash Can icon located next to the search field.
      </span>
      <br /><br />
      <span>
        To make the changes permanent, Click on “Set Default”..
      </span><br />
    </div>
  </app-dialog>


  <div *ngIf="Searchdiv" class="detailsDialog" id="Searchdiv" style="height:70%;padding:0;width:22%;border:3px solid #00B0F0;top:15% !important;background-color: white;">


    <div _ngcontent-vgp-c2="" class="col-xs-12" style="text-align: center; height: 6%;color: white;padding: 0 2% 0 2%;background-color: #2A3740 !important;width: 100%;border-bottom:1px solid white;font-size:18px;">
      <label _ngcontent-vgp-c2="" style="margin-top:1%"> Advanced Search</label><img src="../../assets/img/White x.png" style="height: 65%;float:right;margin-top:2%" (click)="CloseSearchBox(false)">

    </div>
    <div class="col-xs-12" style="padding:0;height:94%;font-size:12px;">
      <div class="col-xs-12" style="padding:0;height:11%;margin-top:2%">
        <span style="margin:0;">SEARCH FIELDS</span>
        <div class="col-xs-12" style="padding:1%;" id="HeaderMultiselect">
          <angular2-multiselect id="multipleDropdown" [data]="SearchColumnList" [(ngModel)]="SearchSelectedItems"
                                [settings]="dropdownSetting"
                                (onSelect)="SearchonItemSelect($event)"
                                (onDeSelect)="SearchOnItemDeSelect($event)"
                                (onSelectAll)="SearchonSelectAll($event)"
                                (onDeSelectAll)="SearchonDeSelectAll($event)">

            <c-badge *ngIf="SearchSelectedItems.length>=4">

              <label *ngIf="SearchSelectedItems.length>=4" style="margin: 0px;font-size:11px;">Multiple Columns</label>
            </c-badge>
          </angular2-multiselect>
        </div>
      </div>


      <div class="col-xs-12" style="padding:0.5%;height:15%">
        <div class="col-xs-12 pull-right" id="searchBox" style="padding: 0%;">
          <div class=" col-xs-12" style="float:left;padding:0;">
            <span>ENTER SEARCH WORD</span>
            <div class="col-xs-12" *ngIf="!isDateSelected" style="padding:0;background-color:white;border-radius:5px;">
              <input type="text" class="" placeholder="  Search..." (focus)="globalSearchOnfocus()" [textMask]="{mask: isSelectedDOB == true ? dobmask:isPhoneMask == true ? phonemask :''}" (keyup)="onKeyPress($event)" (keyup.enter)="onSearchChange($event.target.value)" id="searchContact" style="height: 30px !important;margin-left: 2%;width: 73%;    border-color: lightgrey; border: 1px solid lightgrey" (keyup.backspace)="onKeyPress($event)" />
              <button style="padding:0;height:40px;width:23%;">
                <img _ngcontent-hto-c2="" src="../../assets/img/Scheduler Search.png" style="height: 65%;" (click)="onSearchChange($event.target.value)">
              </button>
              <!--<button (click)="ClearSearchBox()" *ngIf="(term != '' && term!=undefined && term.length > 0 && limitedSearch ==false)" class="" style="padding:0;height:28px;width:10%;float:right">
                <img src="../../assets/img/X search clear.png" style="height:20px;" />
              </button>
              <button (click)="onSearchChange('delete')" *ngIf="limitedSearch==true" class="" style="padding:0;height:28px;width:10%;float:right">
                <img src="../../assets/img/X search clear.png" style="height:20px;" />
              </button>-->
            </div>

          </div>
        </div>
      </div>

      <div class="col-xs-12" style="padding:0.5%;height:5%">
        <span>SEARCH CRITERIA</span> <span style="float:right;margin-right:2%" (click)="ClearSearchCriteria()">CLEAR ALL</span>
      </div>
      <div class="col-xs-12" style="padding:0;height:67%">
        <div *ngFor="let item of searchCriteriadata; let i = index;" style=" height: 8%; border: 1px solid lightgrey; margin: 1% 2% 1% 2%; border-radius: 5px;font-size:16px;">
          <span [ngStyle]="{'color':item.includes('~') ? 'red':'black'}">
            &nbsp;&nbsp;{{
item.replace('~','')
            }}&nbsp;&nbsp;&nbsp;
          </span>
          <button *ngIf="searchCriteriadata.length>1" (click)="clearSearchConfirm=true;Removesearchfilter(item.replace('~',''))" class="" style="padding:0;height:18px;float:right">
            <img src="../../assets/img/Clear Search.png" style="height:20px;" />
          </button>
          <button *ngIf="searchCriteriadata.length==1" (click)="ClearSearchCriteria()" class="" style="padding:0;height:18px;float:right">
            <img src="../../assets/img/Clear Search.png" style="height:20px;" />
          </button>

        </div>
      </div>
    </div>
  </div>
  <app-dialog [(visible)]="NotSelected" *ngIf="NotSelected" [closable]="false" id="NotSelected">
    <div class="col-xs-12" style="padding:4%">
      <span style="font-size:19px;font-weight:800">SalesXCRM Message</span> <br />
      <div style="padding:0;margin-top:3%" class="col-xs-10">
        <span style="font-size:15px;">Action Icon</span>
      </div>
      <div style="padding:0;margin-top:3%" class="col-xs-10">
        <span *ngIf="selectedmainbutton!='Create Project'" style="font-size:15px;">An Account or Contact must be selected first</span>
        <span *ngIf="selectedmainbutton=='Create Project'">An Account must be selected first to create a project</span>
      </div>
      <div class="col-xs-2">
        <button class="btn" (click)="Ok('NotSelected')" style="float:right;background-color: #0098f7 !important;color: white;">OK</button>
      </div>
    </div>

  </app-dialog>


  <!--<app-notification (sendMsgEvent)="onWizzardClosed($event)"></app-notification>-->


  <app-dialog [(visible)]="showSearchTabsSubTabs" [closable]="false">
    <div class="col-xs-12" style="padding:0;height:100%">
      <div class="col-xs-12" style="height: 6%;background-color: #2A3740;color: white;text-align: center;font-size: 17px;"><label style="margin-top:2.5%">Fields to Search</label></div>
      <div class="col-xs-12" style="padding:0;height:90%;">
        <div class="col-xs-12" style="padding:1%;height:5%">
          <i class="fa fa-times-circle" aria-hidden="true" style="float:right" (click)="CloseSearchFieldsDialog('showSearchTabsSubTabs')"></i>
        </div>
        <div class="col-xs-12" *ngIf="isDateSelected" style="padding:0;">
          <div class="col-xs-6" style="padding:0;">
            <my-date-picker [options]="searchStartDatePickerOptions" [placeholder]="SearchStartDatePlaceHolder"
                            (dateChanged)="SearchStartdateChange($event)" style="border-left:none !important;height:30px !important" required></my-date-picker>
          </div>
          <div class="col-xs-6" style="padding:0;">
            <my-date-picker [options]="searchEndDatePickerOptions" [placeholder]="SearchEndDatePlaceHolder"
                            (dateChanged)="SearchEnddateChange($event)" style="border-left:none !important;height:30px !important" required></my-date-picker>
          </div>
        </div>

        <div class="col-xs-12" style="padding:0;height:94%;" id="SearchHeaderMultiselect">
          <angular2-multiselect id="multipleDropdown" [data]="SearchColumnList" [(ngModel)]="SearchSelectedItems"
                                [settings]="dropdownSetting"
                                (onSelect)="SearchonItemSelect($event)"
                                (onDeSelect)="SearchOnItemDeSelect($event)"
                                (onSelectAll)="SearchonSelectAll($event)"
                                (onDeSelectAll)="SearchonDeSelectAll($event)">

            <c-badge *ngIf="SearchSelectedItems.length>=4">

              <label *ngIf="SearchSelectedItems.length>=4" style="margin: 0px;font-size:11px;">Multiple Columns</label>
            </c-badge>
          </angular2-multiselect>
        </div>
        <div class="col-xs-1" style="padding:0;text-align:center;height:30px;background-color:white;border-radius:0 3px 3px 0;" *ngIf="SearchSelectedItemsLengthChanged == true">
          <button (click)="RefreshDefaultColumns()" class="btn btn-sm btn-default" style="padding:0;height:28px;">
            <img src="../../assets/img/Green Trash.png" style="height:20px;" />
          </button>
        </div>
      </div>
    </div>
  </app-dialog>
  <!--<app-dialog [(visible)]="showAddEditAccount" *ngIf="showAddEditAccount" id="addEditAccount" [closable]="false" [OutSideClick]="false" [CloseButtonColor]="'white'">
    <app-add-new-account (sendMsgEvent)="ReceiveAddEditAccEvent($event)"></app-add-new-account>
  </app-dialog>-->

  <app-dialog [(visible)]="OpenLogout" *ngIf="OpenLogout" id="addEditAccount" [closable]="false">
    <div class="col-xs-12" style="background-color:#F4F4F4;height:99%;padding:0;">
      <div class="col-xs-12" style="text-align: center; height: 13%;color: white;padding: 0px;background-color: #2A3740 !important;border-bottom:1px solid white;text-align:left;padding:0%;">
        <div class="col-xs-10" style="padding:0;height:100%">
          <div class="col-lg-12 col-sm-12 col-xs-12" style="padding:1% 1% 1% 4%;font-weight:400;font-size:12px;margin-top: 0%;color:white;height:100%">
            <div class="col-xs-12" style="padding:0;color:rgb(191,191,191);margin-top:1%">
              <div class="col-xs-4" style="padding:0;"><span>Last Login :</span></div>
              <div class="col-xs-8" style="padding:0;"><span> {{this.currentuser.loggedouttime | date: 'MM-dd-yyyy' }} ({{this.currentuser.loggedouttime | date: 'HH:mm' }})</span></div>
            </div>

            <div class="col-xs-12" style="padding:0;margin-top:1%;color:rgb(191,191,191)">
              <div class="col-xs-4" style="padding:0;"><span>Current Login :</span></div>
              <div class="col-xs-8" style="padding:0;"><span> {{this.currentuser.loggedInTime | date: 'MM-dd-yyyy' }} ({{this.currentuser.loggedInTime | date: 'HH:mm' }})</span></div>
            </div>

          </div>
        </div>
        <div class="col-xs-1" style="padding:3%;float:right">
          <i class="fa fa-times-circle" aria-hidden="true" style="color:white;" (click)="CloseLogOutBox()"></i>
        </div>
      </div>
      <div class="col-xs-12" style="text-align: left; height: 13%;color: white;padding: 1.5% 2% 0 3%;background-color: #2A3740 !important;width: 100%;border-bottom:1px solid white;font-size:18px;">
        <label style="margin-top:1%"> Select a Category</label>

      </div>
      <div class="col-xs-12" style="padding:0;height:75%">
        <div class="col-xs-12" style="height:78%;font-size:18px;padding:1% 0 0 3%">
          <div class="col-xs-12" style="padding:0;height:20%"><label (click)="ActionClick('AboutUs')">About SalesXCRM</label></div>
          <div class="col-xs-12" style="padding:0;height:20%"><label (click)="ActionClick('ReleaseNotes')">Release Notes</label></div>
          <div class="col-xs-12" style="padding:0;height:20%"><label (click)="ActionClick('MySubscription')">My Subscription</label></div>
          <div class="col-xs-12" style="padding:0;height:20%"><label (click)="ActionClick('MyProfile')">My Profile</label></div>
          <div class="col-xs-12" style="padding:0;height:20%"><label (click)="ActionClick('ContactUs')">Contact Us</label></div>

        </div>
        <div class="col-xs-12" style="height:20%;padding:1% 0 0 3%" (click)="logout();"><span style="font-size:18px;font-weight:600;color:deepskyblue;">Logout</span></div>
      </div>
    </div>


  </app-dialog>

  <app-dialog [(visible)]="CloseSearchDialog" [closable]="false" id="CloseSearchDialog">
    <div class="col-xs-12">
      <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
      <span style="font-size:15px;">To Close the Search Box</span><br />
      <div style="padding:0;margin-top:1%">
        <span style="font-size:15px;">User Must Clear All Search Words</span><br />
      </div>
      <button class="btn" (click)="CloseSearch()" style="float:right;background-color: #0098f7 !important;color: white;margin-top:-5%">OK</button>
    </div>

  </app-dialog>

  <!--<app-dialog [(visible)]="SearchNotAvailable" *ngIf="SearchNotAvailable" id="SearchNotAvailable" [closable]="false">

    <div class="col-xs-12" style="padding:4%">
      <span style="font-size:19px;font-weight:800">SalesXCRM Message</span> <br />
      <div style="padding:0;margin-top:3%" class="col-xs-10">
        <span style="font-size:15px;">Action Icon</span>
      </div>
      <div style="padding:0;margin-top:3%" class="col-xs-10">
        <span style="font-size:15px;">Feature Not Available</span><br />
      </div>
      <div class="col-xs-2">
        <button class="btn" (click)="SearchNotAvailable=false;" style="float:right;background-color: #0098f7 !important;color: white;">OK</button>
      </div>
    </div>

  </app-dialog>-->
  <app-dialog [(visible)]="ConfirmationSearchClear" *ngIf="ConfirmationSearchClear" id="ConfirmationSearchClear">
    <div class="col-xs-12">
      <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br /><br />
      <span style="font-size:15px;">Delete Search Criteria</span><br />
      <div class="col-xs-8" style="padding:0;margin-top:1%">
        <span style="font-size:15px;">Do you want to delete search criteria?</span><br />
      </div>
      <div class="col-xs-4" id="MyButtonsDiv" style="padding:0;">
        <button class="btn" (click)="SearchConfirm('Yes')" style="background-color: #0098f7 !important;color: white;">OK</button>
        <button class="btn" (click)="SearchConfirm('No')" style="margin-left: 4%;">Cancel</button>

      </div>
    </div>

  </app-dialog>

  <app-dialog [(visible)]="RHxMenuDiv" *ngIf="RHxMenuDiv" id="RHxMenuDiv" [OutSideClick]="false" [closable]="false">
    <div class="col-xs-12" style="padding: 0; height: 2%; background-color: rgb(186,220,153)"></div>
      <div class="col-xs-12" style="padding:0;height:4%">
        <div class="col-xs-10"><label>RECENT</label> </div>
        <div class="col-xs-2">
          <img src="../../assets/img/Black x.png" style="height: 20px;width: 20px;float:right" (click)="ShowRHxMenuDiv(false)">
        </div>
      </div>
      <div class="col-xs-12" style="height:7%;text-align:center;padding:0;">
        <div class="col-xs-4" style="padding:0;height:100%">
          <img src="../../assets/img/Contact Selected - Green.png" style="height: 35px;width: 35px;" *ngIf="RHxContactSelected" (click)="RHxTab('Contacts')">
          <img src="../../assets/img/Contact Not Selected - Grey.png" style="height: 35px;width: 35px;" *ngIf="!RHxContactSelected" (click)="RHxTab('Contacts')">

        </div>
        <div class="col-xs-4" style="padding:0;height:100%">
          <img src="../../assets/img/Accounts Not Selected - Grey.png" style="height: 35px;width: 35px;" *ngIf="!RHxAccountSelected" (click)="RHxTab('Accounts')">
          <img src="../../assets/img/Accounts Selected - Green.png" style="height: 35px;width: 35px;" *ngIf="RHxAccountSelected" (click)="RHxTab('Accounts')">
        </div>
        <div class="col-xs-4" style="padding:0;height:100%">
          <!--<img src="../../assets/img/Black x.png" style="height: 35px;width: 35px;" (click)="ShowRHxMenuDiv(false)">-->
          <img src="../../assets/img/Prev Visited White Icon.png" style="height: 35px;width: 35px;" *ngIf="!RHxRecentViewPages" (click)="RHxTab('RecentPages')">
          <img src="../../assets/img/Prev Visited Green Icon.png" style="height: 35px;width: 35px;" *ngIf="RHxRecentViewPages" (click)="RHxTab('RecentPages')">
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;height:87%">

        <div class="col-xs-12 scroll-c" style="height:100%;margin-top: 5%;padding:0;">
          <ul class="nav menu" style="padding:0;height:100%">
            <li *ngFor="let item of RHxNames" (click)="OpenAccountOrContact(item)" style="height:8%">
              <a routerLinkActive="active">
                <span>{{item.UserName}}</span>
              </a>
            </li>
          </ul>

        </div>
      </div>
  </app-dialog>


  <app-dialog [(visible)]="QuickSearchDiv" *ngIf="QuickSearchDiv" id="QuickSearchDiv" [closable]="false" [StayVisible]="true">

    <div class="col-xs-12" style="height:100%;padding:0;">
      <app-quick-search [SearchTab]="'Advance'"></app-quick-search>
    </div>
  </app-dialog>

  <app-dialog [(visible)]="ProposalOverLayWarning" *ngIf="ProposalOverLayWarning" id="ProposalOverLayWarning" [closable]="false">
    <div class="col-xs-12">
      <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br /><br />
      <span style="font-size:15px;"> CREATE PROPOSAL </span><br />
      <div class="col-xs-8" style="padding:0;margin-top:1%">
        <span style="font-size:15px;">Select SAVE,PEND or Cancel?</span><br />
      </div>
      <div class="col-xs-4" id="MyButtonsDiv" style="padding:0;">
        <button class="btn" style="background-color: #0098f7 !important;color: white;" (click)="CreateProposalPage = true;ProposalOverLayWarning = false">OK</button>
        <!--<button class="btn" style="margin-left: 4%;" (click)="ProposalOverLayWarning = false;CreateProposalPage = false;">Cancel</button>-->

      </div>
    </div>

  </app-dialog>



  <app-dialog [(visible)]="showSearchFieldsDialog" *ngIf="showSearchFieldsDialog" id="showSearchFieldsDialog" [closable]="false" [OutSideClick]="false" (visibleChange)="CloseSearchFieldsDialog('showSearchFieldsDialog')">

    <div style="background-color:white;height:100%">
      <div class="col-xs-12" style="background-color: rgb(186,219,152) !important; height: 2%">
      </div>
      <div class="col-xs-12" style="height:4%" (click)="showSearchFieldsDialog=false;">
        <img src="../../../assets/img/Black x.png" style="height: 20px;float:right;" />
      </div>
      <div class="col-xs-12" style="text-align: center; height: 23%; padding: 0px; border-bottom: 2px solid #CCC; text-align: left; padding: 0%;">
        <div class="col-xs-4" style="padding:0 0 0 0%;height:100%;font-size:14px;color:rgb(186,219,152);">
          <div class="col-xs-12" style="padding:3%;height:100%">

            <img [src]="ProfilePicture" style="width: 97%; height: 131px;" *ngIf="ProfilePicture">
            <img src="../../assets/img/Contact Image off.png" height="100" style="width:90%;padding:0 0 0 10%" *ngIf="!ProfilePicture">
          </div>
        </div>
        <div class="col-xs-8" style="padding:0 0 0 2%;height:100%;">
          <div class="col-xs-12" style="margin-top: 1%; padding: 0; font-size: 15px; font-weight: 800">
            <span>{{SubscriptionName}}</span>
          </div>
          <div class="col-xs-12" style="margin-top: 0%; padding: 0; font-size: 12px;">
            <span>{{this.currentuser.firstname}} {{this.currentuser.lastname}} </span>
          </div>
          <div class="col-xs-12" style="margin-top: 0%; padding: 0; font-size: 12px;">
            <span>{{this.currentuser.email}}</span>
          </div>
          <div class="col-xs-12" style="padding:0;margin-top:0%;font-size:12px">
            <div class="col-xs-12" style="padding:0;"><span>Software Version : {{'v' + appVersion}}</span></div>
          </div>
          <div class="col-xs-12" style="padding:0;margin-top:0%;font-size:12px">
            <div class="col-xs-12" style="padding:0;"><span> Release Date: {{today}}</span></div>
          </div>
          <div class="col-xs-12" style="padding:0;margin-top:0%;font-size:12px">
            <div class="col-xs-12" style="padding:0;"><span>Last Login :  {{this.currentuser.loggedouttime | date: 'MM-dd-yyyy' }} ({{this.currentuser.loggedouttime | date: 'HH:mm a' }})</span></div>
          </div>

          <div class="col-xs-12" style="padding:0;margin-top:0%;font-size:12px">
            <div class="col-xs-12" style="padding:0;"><span>Current Login :  {{this.currentuser.loggedouttime | date: 'MM-dd-yyyy' }} ({{this.currentuser.loggedouttime | date: 'HH:mm a' }})</span></div>

          </div>

          <!--<div class="col-xs-12" style="padding:3%;height:100%">
        <i class="fa fa-times-circle" aria-hidden="true" style="color:white;float:right" (click)="CloseSearchFieldsDialog('showSearchFieldsDialog')"></i><br />
        <img [src]="ProfilePicture" height="90" style="width:85%;padding:0 0 0 10%" *ngIf="ProfilePicture">
        <img src="../../assets/img/Contact Image off.png" height="100" style="width:85%;padding:0 0 0 10%" *ngIf="!ProfilePicture">
      </div>-->

        </div>
      </div>

      <div class="col-xs-12" style="padding:0;" *ngIf="!RWActive">
        <div class="fancy-collapse-panel scroll-c" style="overflow: auto;height: 100%;" id="3DotsIconPopUp">
          <div class="panel-group" id="accordion" role="tablist" style="margin-top: 10px;font-size:15px" aria-multiselectable="true">
            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="About SalesXCRM" data-toggle="collapse" data-parent="#accordion" id="AboutSalesXCRMMainMenu" aria-expanded="false" (click)="showSearchFieldsDialog=false;activateMainButtons('AboutSalesXCRM');
" aria-controls="AboutSalesXCRM">
                    <label>About SalesXCRM</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'AboutSalesXCRM' && limitedSearch">
                  </a>
                </h4>

              </div>

            </div>

            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Account Configuration" data-toggle="collapse" data-parent="#accordion" id="AccountTransferMainMenu" aria-expanded="false" (click)="showSearchFieldsDialog=false;selectedbuttonText='Account Transfer';activateMainButtons('AccountTransfer')" aria-controls="AccountTransfer">
                    <label>Account Transfer </label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'AccountTransfer' && limitedSearch">
                  </a>
                </h4>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="ContactUs" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="ContactUsMainMenu"
                     (click)="showSearchFieldsDialog=false;activateMainButtons('Contact Us')" aria-controls="ContactUs">
                    <label>Contact Us</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;"
                      (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'Contact Us' && limitedSearch">
                  </a>
                </h4>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Call Lists" data-toggle="collapse" data-parent="#accordion" id="CallListsMainMenu" aria-expanded="false" (click)="showSearchFieldsDialog=false;selectedbuttonText='My Call Lists';activateMainButtons('Call Lists');" aria-controls="CallList">
                    <label>My Call Lists</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'Call Lists' && limitedSearch">
                  </a>
                </h4>

              </div>

            </div>

            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Campaigns" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="CampaignsMainMenu"
                     (click)="showSearchFieldsDialog=false;selectedbuttonText='My Campaigns';activateMainButtons('Campaigns');" aria-controls="Campaigns">
                    <label>My Campaigns</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'Campaigns' && limitedSearch">
                  </a>
                </h4>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="My Documents" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="MyDocumentsMainMenu"
                     (click)="showSearchFieldsDialog=false;selectedbuttonText='My Documents';activateMainButtons('My Documents')" aria-controls="MyDocuments">
                    <label [ngStyle]="{ 'font-weight': NotificationMessages.length>0 ?'900': 'Normal' }">My Documents</label>
                    <label [ngStyle]="{ 'font-weight': NotificationMessages.length>0 ?'900': 'Normal' }" *ngIf="NotificationMessages.length>0" style="color: red; padding-left: 3%;">**</label>
                  </a>
                </h4>
              </div>

            </div>
            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="PendedProposal" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="PendedProposal" (click)="showSearchFieldsDialog=false;selectedbuttonText='My Pended Proposals';activateMainButtons('PendedProposal')" id="PendedProposalMainMenu">
                    <label>My Pended Proposals</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'PendedProposal' && limitedSearch">
                  </a>
                </h4>

              </div>


            </div>

            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px ">
                <h4 class="panel-title">
                  <a class="collapsed" title="MyProfile" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="MyProfileMainMenu"
                     (click)="showSearchFieldsDialog=false;selectedbuttonText='My Profile';activateMainButtons('My Profile')" aria-controls="MyProfile">
                    <label>My Profile</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'My Profile' && limitedSearch">
                  </a>
                </h4>

              </div>

            </div>


            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingTwo" style="height: 24px">
                <h3 class="panel-title">
                  <a class="collapsed" title="RHx" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" id="RecentHistoryMainMenu" aria-controls="Rhx" (click)="showSearchFieldsDialog=false;selectedbuttonText='My Recent History';SubmenuChange('Recent History','Recent History','main');">
                    <label>My Recent History</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'Today' && limitedSearch">
                  </a>
                </h3>

              </div>
            </div>

            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="false"
                     id="SubScriptionMainMenu" (click)="showSearchFieldsDialog=false;activateMainButtons('My Subscription')">
                    <label>My Subscription </label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'SubScription'">
                  </a>
                </h4>

              </div>

            </div>




            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="User Messaging | Pop-Up Communication" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="UserMessaging" (click)="showSearchFieldsDialog=false;selectedbuttonText='Pop-Up Messaging';SubmenuChange('Pop-Up Messaging','Pop-Up Messaging','main')" id="Pop-UpMessagingMainMenu">
                    <label>Pop-Up Messaging</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'Pop-Up Messaging' && limitedSearch">
                  </a>
                </h4>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Release Notes" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="ReleaseNotes" (click)="showSearchFieldsDialog=false;selectedbuttonText='Release Notes';activateMainButtons('ReleaseNotes')" id="ReleaseNotesMainMenu">
                    <label>Release Notes</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'ReleaseNotes' && limitedSearch">
                  </a>
                </h4>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Response Wizard Manager" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="ResponseWizard" (click)="showSearchFieldsDialog=false;selectedbuttonText='Response Wizard Configuration';RWActiveClick(2)" id="ResponseWizardMainMenu">
                    <label>Response Wizard Manager</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === 'ResponseWizard' && limitedSearch">
                  </a>
                </h4>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="System Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="SystemConfigurationMainMenu" (click)="showSearchFieldsDialog=false;selectedbuttonText='System Configuration';SubmenuChange('System Configuration','420','main')">
                    <label>System Configuration</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === '420' && limitedSearch">
                  </a>
                </h4>

              </div>

            </div>

            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="User Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="UserConfig"
                     id="UserConfigurationMainMenu" (click)="showSearchFieldsDialog=false;selectedbuttonText='User Configuration';SubmenuChange('User Configuration','426','main');">
                    <label>User Configuration</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === '426' && limitedSearch">
                  </a>
                </h4>

              </div>

            </div>
            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="User Roles" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="UserConfig"
                     id="UserRolesMainMenu" (click)="showSearchFieldsDialog=false;selectedbuttonText='User Roles';SubmenuChange('User Roles','715','main');">
                    <label>User Roles</label>
                    <img src="../../assets/img/Clear Search.png" style="height: 25px;float:right;" (click)="ClearSearchCriteria()" *ngIf="selectedtabid === '426' && limitedSearch">
                  </a>
                </h4>

              </div>

            </div>
            <div class="panel panel-default">
              <div class="dotpanel-heading" role="tab" id="headingOne" style="height: 24px">
                <h4 class="panel-title">
                  <a class="collapsed" title="LOGOUT" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="dil"
                     id="logout" (click)="logout();">
                    <label style="color: #0098f7; font-weight: 800;">LOGOUT</label>

                  </a>
                </h4>

              </div>

            </div>


          </div>
        </div>


      </div>
    </div>


  </app-dialog>


  <app-dialog [(visible)]="ShowConfigurationDialog" *ngIf="ShowConfigurationDialog" [type]="'Campaign'" id="ConfigurationDialog" [closable]="false">

    <div class="col-xs-12 " *ngIf="(selectedsubbutton == '451')" style="padding:0;height:100%">
      <dropdown-configuration [type]="selectedsubbutton" [SubMenuType]="PopUpMessageType"></dropdown-configuration>
    </div>
    <div class="col-xs-12 " *ngIf="(selectedsubbutton == '424')" id="TaskListConfiguration" style="padding:0;height:100%">
      <dropdown-configuration [type]="selectedsubbutton" [SubMenuType]="PopUpMessageType"></dropdown-configuration>

    </div>
    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="selectedsubbutton == 'AboutSalesXCRM' || selectedsubbutton == 'ContactUs' || selectedsubbutton == 'ReleaseNotes' ">
      <app-aboutsales-xcrm (sendMsgEvent)="CloseAboutus($event)" [OpenPageType]="AboutUsPageType">

      </app-aboutsales-xcrm>
    </div>
    <div class="col-xs-12 " *ngIf="(selectedsubbutton == '422')" style="padding:0;height:100%">
      <dropdown-configuration [type]="selectedsubbutton" [SubMenuType]="PopUpMessageType"></dropdown-configuration>
    </div>
    <div class="col-xs-12 " *ngIf="selectedsubbutton == '529'" style="height:100%;padding:0;">
      <dropdown-configuration [type]="selectedsubbutton" [SubMenuType]="PopUpMessageType"></dropdown-configuration>
    </div>
    <div class="col-xs-12" *ngIf="selectedsubbutton == '528'" style="height:100%;padding:0;">
      <dropdown-configuration [type]="selectedsubbutton" [SubMenuType]="PopUpMessageType"></dropdown-configuration>
    </div>
    <div class="col-xs-12" *ngIf="selectedsubbutton == '530'" style="height:100%;padding:0;">
      <dropdown-configuration [type]="selectedsubbutton" [SubMenuType]="PopUpMessageType"></dropdown-configuration>
    </div>
    <div class="col-xs-12" *ngIf="selectedsubbutton == '423'" style="height:100%;padding:0;">
      <dropdown-configuration [type]="selectedsubbutton"></dropdown-configuration>
    </div>

    <div class="col-xs-12" *ngIf="selectedsubbutton == '531'" style="height:100%;padding:0;">
      <dropdown-configuration [type]="selectedsubbutton" [SubMenuType]="PopUpMessageType"></dropdown-configuration>
    </div>
    <div class="col-xs-12" *ngIf="selectedsubbutton == '624'" style="height:100%;padding:0;">
      <dropdown-configuration [type]="selectedsubbutton" [SubMenuType]="PopUpMessageType"></dropdown-configuration>
    </div>
  
    <div class="col-xs-12" *ngIf="selectedsubbutton == 'ResponseWizard'" style="height:100%;padding:0;">
      <dropdown-configuration [type]="'SalesTool'" [SubMenuType]="PopUpMessageType"></dropdown-configuration>
    </div>

    <div class="col-xs-12" *ngIf="selectedsubbutton == '701'" style="height:100%;padding:0;">
      <dropdown-configuration [type]="selectedsubbutton" [SubMenuType]="PopUpMessageType"></dropdown-configuration>
    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="selectedsubbutton == 'My Subscription'">
      <app-subscription> </app-subscription>
    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="selectedsubbutton == 'My Profile' ">
      <app-profile> </app-profile>
    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="selectedsubbutton == '627' ">
      <dropdown-configuration [type]="selectedsubbutton" [SubMenuType]="PopUpMessageType"> </dropdown-configuration>
    </div>
    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="selectedsubbutton == '663' ">
      <dropdown-configuration [type]="selectedsubbutton" [SubMenuType]="PopUpMessageType"> </dropdown-configuration>
    </div>
    <div class="col-xs-12" *ngIf="selectedsubbutton == '697'" style="height:100%;padding:0;">
      <dropdown-configuration [type]="selectedsubbutton"></dropdown-configuration>
    </div>
  </app-dialog>
 
  <script type="text/javascript">
    window.onbeforeunload = function () {
      return 'You have unsaved work, it will be lost if you leave this page.';
    }
  </script>


  <!--<app-dialog [(visible)]="ClearTransferAccounts" id="ClearTransferAccounts" *ngIf="ClearTransferAccounts">
    <div class="col-xs-12">
      <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
      <div class="col-xs-8">
        <span style="font-size:15px;"> Account Transfer </span><br />
        <span style="font-size:15px;"> Clear Accounts Selected from Grid?</span>
      </div>
      <div class="col-xs-4" style="padding:0;">
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%" (click)="UpdateOwnership()">OK</button>
        </div>
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float:right;background-color: white !important;color: black;margin-top:1%" (click)="ClearTransferAccounts=false">Cancel</button>
        </div>
      </div>
    </div>
  </app-dialog>-->

  <app-dialog *ngIf="MessagingDialog" [(visible)]="MessagingDialog" id="MessagingDialog" [closable]="false" [StayVisible]="true">
    <div class="col-xs-12" style="height:100%;padding:0;">
      <app-messaging></app-messaging>
    </div>
  </app-dialog>

  <app-dialog *ngIf="ChatDialog" [(visible)]="ChatDialog" id="ChatDialog" [closable]="false" [StayVisible]="true">
    <chat (sendMsgEvent)="onChatMsg($event)"></chat>
  </app-dialog>

  <app-dialog *ngIf="ChatUserDialog || ChatEditUserDialog" [(visible)]="ChatUserDialog || ChatEditUserDialog" id="ChatUserDialog" [closable]="false" [StayVisible]="true">
    <div [ngClass]="(ChatUserDialog && ChatEditUserDialog) ? 'col-xs-6' : 'col-xs-12'" style="height: 100%;padding:0.5%" *ngIf="ChatUserDialog">
      <div class="col-xs-12" style="background-color: #2A3740;height: 6%;color:white;padding:0%">
        <div class="col-xs-11">
          SELECT CONTACTS TO LIVE CHAT
        </div>

        <div class="col-xs-1" style="padding: 1% 0 0 0">
          <img (click)="ChatUserDialog=false;loadChatdialog(true,true,true);" src="../../../assets/img/White x.png" style="height:26px" />
        </div>
      </div>
      <div class="col-xs-12" style="background-color: #2a3740a1; height: 84%; color: white; padding: 0%">
        <div class="col-xs-12" *ngFor="let chat of loggedinUserlist" style="padding: 0%">
          <div class="col-xs-1" *ngIf="groupId>0">
            <input type="checkbox" [(ngModel)]="chat.userselected" (change)="OpenChatbox(chat.username,chat.id,$event)">
          </div>
          <div class="col-xs-11">
            <span class="sublabel" id="{{chat.id}}_button" (click)="OpenChatbox(chat.username,chat.id,null)">
              {{chat.username}}
            </span>
          </div>

        </div>
      </div>
      <div class="col-xs-12" style="background-color: #2a3740a1; height: 5%; color: white; padding: 0%">
        <div *ngIf="selecteduserids.length>1" class="col-xs-12" style="padding:0%">
          <input class="form-control" type="text" name="groupName" [(ngModel)]="groupName" placeholder="Enter Group Name" style="border: 3px solid darkorange;" />
        </div>

      </div>
      <div class="col-xs-12" style="background-color: #2a3740a1; height: 5%; color: white; padding: 0%">
        <div class="col-xs-4 btn saveButton" *ngIf="groupId>0" style="padding:0%;float:right;">
          <button type="button" (click)="Updategroup()" class="btn" style="width: 100%;">Save</button>
        </div>
        <div class="col-xs-4" *ngIf="groupId==0" style="background-color: darkorange;padding:0%;float:right;">
          <button type="button" (click)="StartChat()" class="btn" style="width: 100%;">Start Live Chat </button>
        </div>
      </div>
    </div>
    <div [ngClass]="(ChatUserDialog && ChatEditUserDialog) ? 'col-xs-6' : 'col-xs-12'" style="height: 100%; padding: 0.5% " *ngIf="ChatEditUserDialog">
      <div class="col-xs-12" style="background-color: #2A3740;height: 6%;color:white;padding:0%">
        <div class="col-xs-11">
          EDIT OR DELETE LIVE CHAT SESSIONS
        </div>
        <div class="col-xs-1" style="padding: 1% 0 0 0">
          <img (click)="ChatEditUserDialog=false;loadChatdialog(true,true,true);" src="../../../assets/img/White x.png" style="height:26px" />
        </div>
      </div>
      <div class="col-xs-12" style="background-color: #2a3740a1; height: 94%; color: white; padding: 0%">
        <div class="col-xs-12" *ngFor="let chat of msgloggedinUserlist">
          <div class="col-xs-10">

            <span *ngIf="chat.groupName!=''" class="sublabel" id="{{chat.id}}_button" (click)="OpenChatbox(chat.username,chat.id,null)">
              {{chat.groupName}}   <span style="padding-left: 5px;" *ngIf="msgMsg=='EditUser' && chat.userid==currentuser.id">*</span>
            </span>

            <span *ngIf="chat.groupName==''" class="sublabel" id="{{chat.id}}_button" (click)="OpenChatbox(chat.username,chat.id,null)">
              {{chat.username}}   <span style="padding-left: 5px;" *ngIf="msgMsg=='EditUser' && chat.userid==currentuser.id">*</span>
            </span>
          </div>
          <div class="col-xs-1" *ngIf="msgMsg=='EditUser' && chat.userid==currentuser.id">
            <img (click)="EditChat(chat.touserid,chat.groupName,chat.groupId)" src="../../../assets/img/Edit White.png" style="height:20px" />
          </div>
          <div class="col-xs-1" *ngIf="msgMsg=='EditUser' && chat.userid==currentuser.id">
            <!--<img (click)="DeleteChat(chat.chatids,chat.userid)" src="../../../assets/img/Trash Can White.png" style="height:20px" />-->
          </div>
        </div>
      </div>

    </div>
  </app-dialog>


  <app-dialog [(visible)]="DeleteChatDialog" id="DeleteChatDialog" *ngIf="DeleteChatDialog" [closable]="false">
    <div class="col-xs-12" style="margin-top:5%">
      <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
      <div class="col-xs-12" style="padding: 0%; margin-top: 1%">
        <label style="font-size:14px;"> DELETE LIVE CHAT SESSION </label><br />
        <label style="font-size:12px;" *ngIf="fromchatusrId==currentuser.id"> Select OK to delete this chat session and chat history.</label>
        <label style="font-size:12px;" *ngIf="fromchatusrId!=currentuser.id">
          Select OK to delete this chat session,You are not the owner of this chat
          session and deleting this chat session will only delete the chat session
          name from your chat session box.
        </label>
      </div>
      <div class="col-xs-4" style="padding: 0; float: right; margin-top: 4%;">
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%" (click)="DeleteChatChatMessage(chatmsgId)">OK</button>
        </div>
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float:right;background-color: white !important;color: black;margin-top:1%" (click)="DeleteChatDialog=false">Cancel</button>
        </div>
      </div>
    </div>
  </app-dialog>

  <app-dialog *ngIf="hideSubButton" [(visible)]="hideSubButton" id="hideSubButton" [closable]="false" [StayVisible]="true">
    <div class="col-xs-12" style="height:100%;padding:0;">
      <app-quick-search [SearchTab]="'Simple'"></app-quick-search>
    </div>
  </app-dialog>




  <!--<app-dialog [(visible)]="CallListDialog" [CalendarDialog]="true" *ngIf="CallListDialog" [type]="'Calllist'" id="CallListDialog" (visibleChange)="SelectedDate = null;BTClick= false;pageViewed=''" [closable]="false" [OutSideClick]="false" [CloseButtonColor]="'white'">
    <app-calllist [contactids]="contactids" [calllistid]="calllistid"></app-calllist>
  </app-dialog>-->

  <!--<app-dialog [(visible)]="QuickViewDialog" [CalendarDialog]="true" [type]="'QuickView'" *ngIf="QuickViewDialog" id="QuickViewDialog" [closable]="false" [OutSideClick]="false" [CloseButtonColor]="'white'">
    <app-quick-view [contactids]="contactids" [Qtype]="Qtype"></app-quick-view>
  </app-dialog>-->

  <!--<app-dialog [(visible)]="RWActive" id="RWActive" [closable]="false" [OutSideClick]="false" [CloseButtonColor]="'white'">
    <div class="col-xs-11" style="height: 5%;">
    </div>
    <div class="col-xs-1" style="height: 5%">
      <img src="../../assets/img/Black x.png" style="height: 25px;float:right;margin-top:4px" (click)="CloseSearchFieldsDialog('RWActive')">
    </div>
    <div class="col-xs-12" style="padding:0;height:81%" *ngIf="RWActive">
      <responsewizard [type]="responsewizardType" [SubMenuType]="PopUpMessageType"></responsewizard>
    </div>

  </app-dialog>-->
  <app-dialog *ngIf="MyNotes" [(visible)]="MyNotes" id="MyNotes" [closable]="false" [StayVisible]="true">
    <div class="col-xs-12" style="background-color: rgb(186,219,152) !important; height: 2%"></div>
    <div style="text-align: center; height: 4%;padding: 0px;
      border-bottom:1px solid white;text-align:left;">
      <div class="col-xs-11">
        <label style="margin-top:1%;font-size:17px"> MY NOTES</label>
      </div>
      <div class="col-xs-1" style="padding-top:1%">
        <img src="../../assets/img/Black x.png" style="height: 23px;float:right;" (click)="CloseMyNotes()">
      </div>
    </div>

    <div class="col-xs-12" style="padding: 0 0 0 0.7%; margin-bottom: 3%">

      <div class="col-xs-9" style="padding: 0px;margin-top: 0.5%;width:75%">
        <textarea rows="35" id="MyNotes" cols="100" [(ngModel)]='currentuser.myNotes' placeholder="Enter Notes...."
          style="background-color: rgb(255, 255, 229); border: none; font-family: 'Dubai';
          font-size: 14px; font-weight: 600; padding: 9px">  
        </textarea>
      </div>
      <div class="col-xs-3 Notetabset" style="width: 25%;padding: 0 1% 0 8%; margin-top: 0.5%;">
        <input type="radio" name="Notetabset" id="FollowUp" value="FollowUp" [(ngModel)]="selectedNoteRadio" aria-controls="bano" />
        <label for="FollowUp" style="width: 100%; text-align: center; height: 40px" (click)="NotesChanged('FollowUp')">Follow Up</label>

        <input type="radio" name="Notetabset" id="Client" value="Client" [(ngModel)]="selectedNoteRadio" aria-controls="bano" />
        <label for="Client" style="width: 100%; text-align: center; height: 40px " (click)="NotesChanged('ClientRecommendations')">Suggestions</label>

        <input type="radio" name="Notetabset" id="Goals" value="Goals" [(ngModel)]="selectedNoteRadio" aria-controls="banoo" />
        <label for="Goals" style="width: 100%; text-align: center; height: 40px " (click)="NotesChanged('Goals')"> Personal Goals</label>

        <input type="radio" name="Notetabset" id="Personal" value="Personal" [(ngModel)]="selectedNoteRadio" aria-controls="banoo" />
        <label for="Personal" style="width: 100%; text-align: center; height: 40px " (click)="NotesChanged('Personal')">Personal Notes</label>

        <input type="radio" name="Notetabset" id="SoftwareBugs" value="SoftwareBugs" [(ngModel)]="selectedNoteRadio" aria-controls="banoo" />
        <label for="SoftwareBugs" style="width: 100%; text-align: center; height: 40px " (click)="NotesChanged('SoftwareBugs')">Software Bugs</label>

        <input type="radio" name="Notetabset" id="Misc" value="Misc" [(ngModel)]="selectedNoteRadio" aria-controls="tej" style="margin-left: 1px">
        <label for="Misc" style="width: 100%; text-align: center; height: 40px " (click)="NotesChanged('Misc')">Miscellaneous</label>

        <input type="radio" aria-controls="banoo" />
        <label for="PersonalGoals" style="width: 100%; text-align: center; height: 40px; color: white; "> Personal Goals</label>

        <input type="radio" aria-controls="tej" style="margin-left: 1px">
        <label for="Miscellaneous" style="width: 100%; text-align: center; height: 40px; color: white; ">Miscellaneous</label>

        <input type="radio" aria-controls="tej" style="margin-left: 1px">
        <label for="Miscellaneous" style="width: 100%; text-align: center; height: 40px; color: white; ">Miscellaneous</label>

        <input type="radio" aria-controls="tej" style="margin-left: 1px">
        <label for="Miscellaneous" style="width: 100%; text-align: center; height: 40px; color: white; ">Miscellaneous</label>

        <input type="radio" aria-controls="tej" style="margin-left: 1px">
        <label for="Miscellaneous" style="width: 100%; text-align: center; height: 40px; color: white; ">Miscellaneous</label>

        <input type="radio" aria-controls="tej" style="margin-left: 1px">
        <label for="Miscellaneous" style="width: 100%; text-align: center; height: 40px; color: white; ">Miscellaneous</label>

        <input type="radio" aria-controls="tej" style="margin-left: 1px">
        <label for="Miscellaneous" style="width: 100%; text-align: center; height: 40px; color: white; ">Miscellaneous</label>

        <input type="radio" aria-controls="tej" style="margin-left: 1px">
        <label for="Miscellaneous" style="width: 100%; text-align: center; height: 40px; color: white; ">Miscellaneous</label>

        <input type="radio" aria-controls="tej" style="margin-left: 1px">
        <label for="Miscellaneous" style="width: 100%; text-align: center; height: 40px; color: white; ">Miscellaneous</label>

        <div class="col-xs-12" style="border: 1px solid green; color: green; width: 100%; font-weight: 600;padding: 5%; text-align: center;" (click)="SaveMyNotes()">
          SAVE
        </div>
      </div>


    </div>

  </app-dialog>

  <app-dialog [(visible)]="TicketCreateDialog" [CalendarDialog]="true" *ngIf="TicketCreateDialog" id="TicketCreateDialog" (visibleChange)="SelectedDate = null;BTClick= false;pageViewed=''" [closable]="false" [OutSideClick]="false" [CloseButtonColor]="'white'">
    <create-ticket [ContOrAccType]="type" [contactid]="contactids" (sendMsgEvent)="onTicketMsg($event)"></create-ticket>
  </app-dialog>

  <app-dialog [(visible)]="taskDialog" [CalendarDialog]="true" *ngIf="taskDialog" [type]="'Task'" id="DashboardtaskDialog" (visibleChange)="SelectedDate = null;BTClick= false;pageViewed=''" [closable]="false" [OutSideClick]="false" [CloseButtonColor]="'black'">
    <app-task [id]="id" [taskid]="''" [type]="type" [BTClick]="false" [taskDialog]="true" [SearchDialog]="false" [selectorTag]="true" [selectedDate]="SelectedDate" (sendMsgEvent)="onTaskSaved($event)"></app-task>
  </app-dialog>

  <app-dialog [(visible)]="crmDialog" *ngIf="crmDialog" id="crmDialog" [closable]="false">
    <div class="col-xs-12" style="padding:5%">
      <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
      <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
        <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">{{dialogobj.title}}</div><br />
        <div class="col-xs-12" style="padding: 0; padding-top: 3%;">{{dialogobj.description}}</div>
      </div>
      <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
        <div class="col-xs-7" style="padding: 0;">

        </div>
        <div class="col-xs-5" style="padding:0;">
          <div class="col-xs-6" style="padding:0" *ngIf="dialogobj.isok">
            <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                    (click)="CRMOK(dialogobj.name)">
              OK
            </button>
          </div>
          <div class="col-xs-6" style="padding:0" *ngIf="dialogobj.issave">
            <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                    (click)="CRMSAVE(dialogobj.name)">
              SAVE
            </button>
          </div>
          <div class="col-xs-6" style="padding:0" *ngIf="dialogobj.isdiscard">
            <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1 " (click)="CRMDISCARD(dialogobj.name)">Discard</button>
          </div>
          <div class="col-xs-6" style="padding:0" *ngIf="dialogobj.iscancel">
            <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1 " (click)="CRMCANCEL(dialogobj.name)">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </app-dialog>
