<div class="col-xs-12" style="height:100%;padding:0 0 0 0%;">
  <div class="col-xs-12" style="background-color: rgb(186,219,152); height: 2% ">

  </div>
  <div class="col-xs-11" style="height: 4%;text-align: left">
    <label style="padding: 2% 0% 0% 0%; text-transform: uppercase;">My Subscription</label>
  </div>
  <div class="col-xs-1" (click)="close();" style="height: 4%;text-align: left">
    <label style="padding: 0.5%; text-transform: uppercase;"><img src="../../../assets/img/Black x.png" style="height: 20px; margin-top: 30%;" /></label>
  </div>

  <div class="col-xs-12" style="padding: 0 0 0 1%; height: 93%; margin-top: 1%">
    <div class="col-xs-3" style="padding: 0px; border-right: 1px solid black;font-size:13px; ">
      <div class="col-xs-12" style="padding: 0; height: 5%; color: black; line-height: 28px;" (click)="showContent(0)" [style.background]="showSubscription ? 'rgb(186,219,152)': 'white'">
        <input style="display:none" type="radio" name="tabset" id="tab1" value="tab1" aria-controls="bano" [(ngModel)]="selectedMenuRadio" checked />
        <label for="tab11" style="text-align:center;font-weight:normal" (click)="showContent(0)">My Subscription</label>
      </div>
      <div class="col-xs-12" style="padding: 0; height: 5%; color: black; line-height: 28px;" (click)="showContent(1)" [style.background]="showBilling ? 'rgb(186,219,152)': 'white'">
        <input style="display:none" type="radio" name="tabset" id="tab2" value="tab2" aria-controls="bano" [(ngModel)]="selectedMenuRadio" checked />
        <label for="tab12" style="text-align:center;font-weight:normal" (click)="showContent(1)">Billing Information</label>
      </div>
    </div>

    <div class="col-xs-9">
      <div class="col-xs-12" style="padding: 0% 0px 0 0;font-size:13px;height: 94%" *ngIf="showSubscription">
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Business Name:</label>
          </div>
          <div class="col-xs-8">
            <label> MD Logic, Inc</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Address:</label>
          </div>
          <div class="col-xs-8">
            <label>2180 Satellite Blvd</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Address:</label>
          </div>
          <div class="col-xs-8">
            <label>Suite 400</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>City:</label>
          </div>
          <div class="col-xs-8">
            <label>Duluth </label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>State:</label>
          </div>
          <div class="col-xs-8">
            <label>Georgia </label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Zip: </label>
          </div>
          <div class="col-xs-8">
            <label>30097</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Phone: </label>
          </div>
          <div class="col-xs-8">
            <label>800-273-7750</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Website:</label>
          </div>
          <div class="col-xs-8">
            <label>mdlogic.com</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Plan Type:</label>
          </div>
          <div class="col-xs-8">
            <label>Enterprise Plan</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Start Date:</label>
          </div>
          <div class="col-xs-8">
            <label>01-01-2019</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label># Users:</label>
          </div>
          <div class="col-xs-8">
            <label>30</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Renewal Date:</label>
          </div>
          <div class="col-xs-8">
            <label>01-01-2023</label>
          </div>
        </div>
        <div class="col-xs-12" style="padding-top:5%">
          <div class="col-xs-4" style="padding:0">
            <label>Primary Contact:</label>
          </div>
          <div class="col-xs-8">
            <label>Thomas Bierster</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Address:</label>
          </div>
          <div class="col-xs-8">
            <label>6021 Catamaran Court</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Address:</label>
          </div>
          <div class="col-xs-8">
            <label></label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>City:</label>
          </div>
          <div class="col-xs-8">
            <label>Flowery Branch</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>State:</label>
          </div>
          <div class="col-xs-8">
            <label>Georgia </label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Zip:</label>
          </div>
          <div class="col-xs-8">
            <label>30542</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Phone:</label>
          </div>
          <div class="col-xs-8">
            <label>678-123-4567</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Email:</label>
          </div>
          <div class="col-xs-8">
            <label>tbierster@mdlogic.com</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12" style="padding: 0% 0px 0 0; height: 94%; font-size: 13px;" *ngIf="showBilling">
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Card Type:</label>
          </div>
          <div class="col-xs-8">
            <label>Visa</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Card #:</label>
          </div>
          <div class="col-xs-8">
            <label>0213 2365 0236 2145</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>MM/YY:</label>
          </div>
          <div class="col-xs-8">
            <label>08/2024</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>CVV:</label>
          </div>
          <div class="col-xs-8">
            <label>598</label>
          </div>
        </div>
        <div class="col-xs-12" style="padding-top:5%">
          <div class="col-xs-4" style="padding:0">
            <label>Renewal Date:</label>
          </div>
          <div class="col-xs-8">
            <label>01/01/2023</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Renewal Amount:</label>
          </div>
          <div class="col-xs-8">
            <label>$1,995.00</label>
          </div>
        </div>
        <div class="col-xs-12" style="padding-top:5%">
          <div class="col-xs-4" style="padding:0">
            <label>Name on Card:</label>
          </div>
          <div class="col-xs-8">
            <label>Thomas Bierster</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Address:</label>
          </div>
          <div class="col-xs-8">
            <label>6021 Catamaran Court</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Address:</label>
          </div>
          <div class="col-xs-8">
            <label></label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>City:</label>
          </div>
          <div class="col-xs-8">
            <label>Flowery Branch</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>State:</label>
          </div>
          <div class="col-xs-8">
            <label>Georgia</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Zip:</label>
          </div>
          <div class="col-xs-8">
            <label>30542</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Phone:</label>
          </div>
          <div class="col-xs-8">
            <label>678-123-4567</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4" style="padding:0">
            <label>Email:</label>
          </div>
          <div class="col-xs-8">
            <label>tbierster@mdlogic.com</label>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>




