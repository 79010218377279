<div class="COL-XS-12" style="height:100%;font-size:12px" >
  <div class="col-xs-12" style="height:5%">
    <div class="col-xs-10" style="padding:0;height:100%" *ngIf="Type=='Add'">
      <img src="../../../assets/img/Create New Document Name.png" style="height:22px" (click)="AddNewLicense()" />&nbsp;&nbsp;<span style="margin-top:1%">ACCOUNT AND CONTACT LICENSES</span>
    </div>
    <div class="col-xs-10" style="padding:0;height:100%" *ngIf="Type=='Edit'">
      <label style="margin-top:1%">Total Logins (active): {{TotalLogins}} </label>
    </div>

    <div class="col-xs-1 pull-right" style="padding:0;height:100%">
      <img src="../../../../assets/img/Black Expand View.png" *ngIf="!ExpandView" style="height:22px;float:right" (click)="Expand(true)" /> 
      <img src="../../../../assets/img/Black Collapse View.png" *ngIf="ExpandView" style="height:22px;float:right" (click)="Expand(false)" />

    </div>
    
  </div>
  <div class="col-xs-12" style="padding:0;height:95%" >

    <div class="col-xs-12" style="padding:0;height:10%">
      <div class="col-xs-4" style="height:100%;font-size:12px">
        <select class="form-control" (change)="filter($event.target.value,'LicenseType')" style="font-size: 12px;padding:0.5%">
          <option value="0">Display All License Types</option>
          <option value="{{cat.Value}}" *ngFor="let cat of all_SL_TypesFilter">{{cat.Name}}</option>
        </select>
      </div>

      <div class="col-xs-4" style="height:100%;font-size:12px;padding-left:0">
        <select class="form-control" (change)="filter($event.target.value,'Account Or Contacts')" style="font-size: 12px;padding:0.5%">
          <option value="0">Display All Accounts / Contacts</option>
          <option value="{{con.id}}" *ngFor="let con of Contacts">{{con.Name}}</option>
        </select>
      </div>


      <div class="col-xs-4" style="height:100%;font-size:12px;padding-left:0;">
        <select class="form-control" (change)="filter($event.target.value,'Status')" style="font-size: 12px;padding:0.5%">
          <option value="1">Display Status (active)</option>
          <option value="0">Display Status (inactive)</option>
          <option value="2">Display Status (active & inactive)</option>
          
        </select>
      </div>

    </div>

    <div class="col-xs-12 scroll-c" style="height:80%" *ngIf="Type=='Add'">
      <table style="width:100%;font-size:12px">
        <tr>
          <th style="width:2%"></th>
          <th style="width:10%">License Type</th>
          <th style="width:10%">Account | Contact</th>
          <th style="width:10% !important">Lic Date</th>
          <th style="width:10%">Status</th>
          <th style="width:6%">Logins</th>
          <th style="width:6%">SaaS</th>
          <th style="width:10%">Term</th>
          <th style="width:10%">R-Date</th>
          <th style="width:6%">Notes</th>
          <th style="width:10%">License#</th>
          <th style="width:10%">Actions</th>
        </tr>
        <tr *ngFor="let license of SoftwareLicenses;let i=index" style="height:5%">
          <td style="width:2%;height:100%"><input type="checkbox" /></td>
          <td style="width:10%;height:100%">
            <select class="form-control" style="padding:0;height:100%;font-size:12px;" [(ngModel)]="license.LicenseTypeId" [disabled]="license.SoftwarelicenseId > 0" [ngStyle]="{'background-color':(license.SoftwarelicenseId <= 0 ) ? '#ffff91' : '#eee','color':(changeColor[i]!=null && changeColor[i].LicenseType && license.SoftwarelicenseId <= 0) ? 'black' : '#555'}" id="LicenseType{{license.SoftwarelicenseId}}" (change)="ChangeLogins(license);changeColor[i].LicenseType = true">
              <option value="0">License Type</option>
              <option value="{{cat.LicenseTypeId}}" *ngFor="let cat of all_SL_Types">{{cat.LicenseTypeName}}</option>
            </select>
          </td>
          <td style="width:10%;height:100%">

            <select class="form-control" style="padding:0;height:100%;font-size:12px" [(ngModel)]="license.Contacts_AccountId" [disabled]="license.SoftwarelicenseId > 0" id="AccOrCont{{license.SoftwarelicenseId}}" (change)="license.AccorConType = changeEvent($event,license);ChangeLogins(license);changeColor[i].AccountContact = true" [ngStyle]="{'background-color':(license.SoftwarelicenseId <= 0 ) ? '#ffff91' : '#eee','color':(changeColor[i] !=null && changeColor[i].AccountContact && license.SoftwarelicenseId <= 0) ? 'black' : '#555'}">
              <option value="0">Account / Contact</option>
              <option value="{{con.id}}" *ngFor="let con of Contacts">{{con.Name}}</option>
            </select>

          </td>
          <td style="width:10% !important;height:100%">
            <input type="text" class="form-control" [(ngModel)]="license.DateLicensed" value="MM-dd-yyyy" style="padding:0;font-size:11px;height:100%" [textMask]="{mask: dobmask}" placeholder="MM-dd-yyyy" id="LicenseDate{{license.SoftwarelicenseId}}" [disabled]="license.SoftwarelicenseId > 0" (keyup)="ChangeLogins(license);changeColor[i].Date = true" [ngStyle]="{'background-color':(license.SoftwarelicenseId <= 0 ) ? '#ffff91' : '#eee','color':(changeColor[i] !=null && changeColor[i].Date && license.SoftwarelicenseId <= 0) ? 'black' : '#555'}" />

          </td>
          <td style="width:10%;height:100%">

            <select class="form-control" style="padding:0;height:100%;font-size:12px" [(ngModel)]="license.Status" [disabled]="license.SoftwarelicenseId > 0" id="LicenseStatus{{license.SoftwarelicenseId}}"
                    (change)="ChangeLogins(license);changeColor[i].status = true" [ngStyle]="{'background-color':(license.SoftwarelicenseId <= 0 ) ? '#ffff91' : '#eee','color':(license.SoftwarelicenseId <= 0 ) ? 'black' : '#555'}">
              <option value="1" selected>Active</option>
              <option value="0">Inactive</option>
            </select>
          </td>

          <td style="width:6%;height:100%">
            <select class="form-control" style="padding:0;height:100%;font-size:12px" [(ngModel)]="license.Logins"
             [disabled]="license.SoftwarelicenseId > 0" id="LicenseLogins{{license.SoftwarelicenseId}}"
             (change)="changeColor[i].Logins = true"
             [ngStyle]="{'background-color':(license.SoftwarelicenseId <= 0 ) ? '#ffff91' : '#eee','color':(changeColor[i] !=null && changeColor[i].Logins && license.SoftwarelicenseId <= 0) ? 'black' : '#555'}">
             <option *ngFor="let item of LicenseLogins" value="{{item}}">{{item}}</option>
            </select>
          </td>
          <td style="height:100%;width:6%">
          <input type="text" class="form-control" [(ngModel)]="license.SaaS"  [disabled]="license.SoftwarelicenseId > 0" style="padding:0;height:100%;font-size:12px;" id="SaaS{{license.SoftwarelicenseId}}" [ngStyle]="{'background-color':(license.SoftwarelicenseId <= 0 ) ? '#ffff91' : '#eee','color':(changeColor[i] != null && changeColor[i].SaaS && license.SoftwarelicenseId <= 0) ? 'black' : '#555'}" (keyup)="changeColor[i].SaaS = true"/></td>
          <td>
            <select class="form-control" style="padding:0;height:100%;font-size:12px"
                    [(ngModel)]="license.term" [disabled]="license.SoftwarelicenseId > 0"
                    id="LicenseTerm{{license.SoftwarelicenseId}}"
                    (change)="changeColor[i].Logins = true"
                    [ngStyle]="{'background-color':(license.SoftwarelicenseId <= 0 ) ? '#ffff91' : '#eee','color':(changeColor[i] !=null && changeColor[i].Logins && license.SoftwarelicenseId <= 0) ? 'black' : '#555'}">
              <option *ngFor="let item of Saasterms" value="{{item.id}}">{{item.itemName}}</option>
            </select>
          </td>
          <td style="width:10% !important;height:100%">
            <input type="text" class="form-control" [(ngModel)]="license.RDate" value="MM-dd-yyyy" style="padding:0;font-size:11px;height:100%" [textMask]="{mask: dobmask}" placeholder="MM-dd-yyyy" id="RDate{{license.SoftwarelicenseId}}" [disabled]="license.SoftwarelicenseId > 0" (keyup)="ChangeLogins(license);changeColor[i].Date = true" [ngStyle]="{'background-color':(license.SoftwarelicenseId <= 0 ) ? '#ffff91' : '#eee','color':(changeColor[i] !=null && changeColor[i].Date && license.SoftwarelicenseId <= 0) ? 'black' : '#555'}" />

          </td>
          <td style="width:6%;height:100%;text-align:center">
            <img src="../../../../assets/img/Notes Icon.png" style="height:22px;" (click)="ViewNotesdialog(i,license,'Add/Edit')" *ngIf="(license.LicenseNote == null || license.LicenseNote == '') &&(license.LicenseNotes == null || license.LicenseNotes == undefined || license.LicenseNotes.length <= 0)" />
            <img src="../../../../assets/img/full-Yellow Note.png" style="height:22px;" *ngIf="(license.LicenseNote !== null && license.LicenseNote !== '') || (license.LicenseNotes && license.LicenseNotes.length > 0)" (click)="ViewNotesdialog(i,license,'Add/Edit')" />

            <input type="text" style="display:none" [(ngModel)]="license.LicenseNote" id="LicenseNote{{i}}" />
            <input type="text" style="display:none" [(ngModel)]="license.LicenseNotes" id="LicenseNotes{{i}}" />
          </td>
          <td style="width:10%;height:100%">
            <input type="text" class="form-control" [(ngModel)]="license.LicenseNumber" [disabled]="license.SoftwarelicenseId > 0" style="padding:0;height:100%;font-size:12px;" id="LicenseNo{{license.SoftwarelicenseId}}" [ngStyle]="{'background-color':(license.SoftwarelicenseId <= 0 ) ? '#ffff91' : '#eee','color':(changeColor[i] != null && changeColor[i].LicNumber && license.SoftwarelicenseId <= 0) ? 'black' : '#555'}" (keyup)="changeColor[i].LicNumber = true" />
          </td>

          <td style="width:10%;padding:1% 0 0 1%;height:100%">
            <div class="col-xs-6" style="padding:0;height:100%" *ngIf="license.SoftwarelicenseId > 0"><i class="fa fa-pencil" aria-hidden="true" (click)="EditLicense(license.SoftwarelicenseId)"></i></div>
            <div class="col-xs-6" style="padding:0;height:100%" *ngIf="license.SoftwarelicenseId > 0"><i class="fa fa-trash" aria-hidden="true" (click)="DeleteConfirmationPopupCss(license.SoftwarelicenseId)"></i></div>
          </td>
        </tr>
      </table>

    </div>

    <div class="col-xs-12" style="padding:0;height:10%" *ngIf="Type=='Add'">

      <div class="col-xs-3">
      </div>
      <div  [ngClass]="(ExpandView)? 'col-xs-5' : 'col-xs-9'"  style="margin-top:2%;float:right" >
        <div class="col-xs-6" style="padding: 0;">
          <button class="btn btn-default"  style="width: 100%; border:1px solid" (click)="GetAllSoftwareLicense()">Cancel</button>
        </div>
        <div class="col-xs-6" style="padding-right: 0;">
          <button type="submit" class="btn saveButton" style="width:100%;" (click)="SaveLicense()">Save</button>
        </div>
      </div>

    </div>

    <div class="col-xs-12" style="padding:0;height:90%" *ngIf="Type=='Edit'">   

      <div class="col-xs-12" style="height:100%">
        <ag-grid-angular class="ag-theme-balham" style="height:95%"
                         [gridOptions]="gridOptions"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="applicationComponent.defaultGridColDef"
                         [rowData]="SoftwareLicenses"
                         [context]="context"
                         [sideBar]="sideBar"
                         [suppressDragLeaveHidesColumns]="true"
                         [suppressRowClickSelection]="true"
                         (gridReady)="onAgGridReady($event)">
        </ag-grid-angular>

      </div>
    </div>
  </div>

</div>


  <app-dialog [(visible)]="ViewNotesDialog" *ngIf="ViewNotesDialog" id="ViewNotesDialog"  [closable]="false">
    <div class="col-xs-12" style="padding:0;height:40%">
      <div class="col-xs-12" style="padding:1% 0 0 0;height:20%;background:#2A3740;text-align:center;color:white">
        <label>License Notes</label>
        <img src="../../../../assets/img/White x.png" style="height:22px;float:right" (click)="SaveLicenseNotes('Cancel')"/>
      </div>
      <div class="col-xs-12" style="height:60%">
        <!--<div id="Licensenote{{NotesIndex}}" [froalaEditor]="editorOptions" [(froalaModel)]="LicenseNote" style="padding-right: 0%;height:99%"></div>-->

        <textarea id="notesArea" rows="6" class="form-control scroll-c" [(ngModel)]="LicenseNote" type="text" name="notedescription" placeholder="Type here to add notes to this License" style="height:98%;overflow:auto;border: none;background: #f1f1f1;" ></textarea>
      </div>
      <div class="col-xs-12" style="padding:0;height:20%">
        <div class="col-xs-3">
        </div>
        <div class="col-xs-9" style="float:right">
          <div class="col-xs-6" style="padding: 0;">
            <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="SaveLicenseNotes('Cancel')">Cancel</button>
          </div>
          <div class="col-xs-6" style="padding-right: 0;">
            <button type="submit" class="btn saveButton" style="width:100%;" (click)="SaveLicenseNotes('Save')" >Save</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12" style="height:59%">
      <div class="col-xs-12" style="padding:0;height:100%;border:1px solid black">
        <div class="col-xs-12" style="padding:0;height:15%;text-align:center;text-decoration:underline;font-size:18px">Previous Notes</div>
        <div class="col-xs-12 scroll-c" style="height:85%">
          <div class="col-xs-12 scroll-c" style="padding:0;height:36%;overflow:auto;margin-top:1%;border-bottom:1px solid lightgray;font-size:11px" *ngFor="let ticketNotes of LicenseNotesData">
            <div class="col-xs-12" style="padding:0%;margin-top:0.5%"><b>Date: {{ticketNotes.CreatedDate | date : "MM-dd-yyyy"}} ({{ticketNotes.CreatedDate | date:"hh:mm a"}})</b></div>
            <div class="col-xs-12" style="padding:0%"><b>User:</b>&nbsp;{{ticketNotes.username}}</div>
            <div class="col-xs-12" style="padding:0%;margin-top:0.5%"><b>Notes: </b>{{ticketNotes.Notes}}</div>
          </div>

        </div>


      </div>
    </div>
  </app-dialog>





  <div *ngIf="LicenseNotesDec" class="detailsDialog" id="showLicenseNotesDialog">
    <div class="col-xs-12" style="padding:0;height:5%">
      <span class="col-xs-12" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;padding:2px;float:right;z-index:1">
        <b> <img src="../../../assets/img/Black x.png" style="height:20px" (click)="LicenseNotesDec = false" /></b>

      </span>
    </div>
    <div class="col-xs-12 scroll-c" style="padding:0;height:94%">
      <div class="col-xs-12" style="height:50%;text-align:left;border-bottom:1px solid black;padding:2%" *ngFor="let item of SoftwareLicense.LicenseNotes">
        <div class="col-xs-12 scroll-c" style="padding:2%;height:95%">
          <label>Date:&nbsp;</label><span>{{item.CreatedDate | date : "MM-dd-yyyy"}}</span><br />
          <label>Time:&nbsp;</label><span>{{item.CreatedDate | date:"hh:mm a"}}</span><br />
          <label>Contact:&nbsp;</label><span>{{SoftwareLicense.Contacts_AccountName}}</span><br />
          <label>User:&nbsp;</label><span>  {{SoftwareLicense.createdByName}}</span><br />
          <label>Notes:&nbsp;</label><span>{{item.Notes}}  </span><br /> <br />
        </div>
      </div>
    </div>
  </div>

  <app-dialog [(visible)]="DeleteConfirmationPopup" *ngIf="DeleteConfirmationPopup" id="DeleteConfirmationPopup" [closable]="false">
    <!--<app-delete-popup [status] = "contactStatus"  [MessageType]="'License'"  (sendMsgEvent)="ReceiveDeleteevent($event)">
    </app-delete-popup>-->


    <div class="col-xs-12" style="height:99%">
      <div class="col-xs-12" style="padding:0;height:30%"><label style="font-size:19px;font-weight:800">SalesXCRM Message</label></div>
      <div class="col-xs-12" style="padding:0;height:20%">
        <b style="font-size:15px;" >LICENSE</b>
      </div>
      <div class="col-xs-12" style="padding:0;height:50%">
        <div style="padding:0;height:100%" class="col-xs-8">
          <b style="font-size:15px;" >Delete this License?</b><br />
        </div>
        <div class="col-xs-4" style="padding:0;height:100%">
          <button class="btn" style="background-color: #0098f7 !important;color: white;margin-top:-5%;margin-left:15%" (click)="DeleteLicense(selectedLicenseId);DeleteConfirmationPopup = false">OK</button>

          <button class="btn" (click)="DeleteConfirmationPopup = false" style="float:right;background-color: white !important;color: #0098f7;margin-top:-5%;border: 1px solid lightgrey !important;">Cancel</button>
        </div>
      </div>
    </div>
  </app-dialog>
