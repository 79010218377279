import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef,  Renderer2,  Input } from '@angular/core';
import { AccountsService } from '../../../services/accounts.service';
import { CustomerService } from '../../../services/customer.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { OpprtunitiesService } from '../../../services/opprtunities.service';
import { Accounts, AccountTasksCounts } from '../../../models/accounts';
import {  FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Accountlocations } from '../../../models/data-model';
import { ParentComp } from "../../userlist/userlist.component";
import { GridOptions } from "ag-grid/main";
import { NotesrendererComponent } from '../../notesrenderer/notesrenderer.component';
import { Dashboardconfiguration } from '../../../models/dashboardconfiguration';
import { PageComponent } from '../../page/page.component';
import { Column } from 'ag-grid';
import { ToastrService  } from 'ngx-toastr';
import { Dropdowntype } from '../../../models/dropdowntype';
import { ApplicationComponent } from '../../application.component';
import { DatePipe } from '@angular/common'; import { Notes } from '../../../models/notes';
import { EditdeletecvComponent } from '../../editdeletecv/editdeletecv.component';
import { OwnershipsharingComponent } from '../../ownershipsharing/ownershipsharing.component';
import { EditcontactComponent } from '../../editcontact/editcontact.component';
import * as moment from 'moment'
import { AddressmapComponent } from '../../addressmap/addressmap.component';
import { NotesComponent } from '../../notes/notes.component';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { AccountContactComponent } from '../../account-contact/account-contact.component';
import { UploadDocumentComponent } from '../../upload-document/upload-document.component';
import { UserService } from '../../../services/user.service';
declare var require: any;
declare var $: any;

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  search: string = null;
  itemResource: any;
  currentuser: any;
  items = [];
  itemCount = 0;
  params: any;
  dropdownList = [];
  finalitems = "";
  term: string = "";
  @Input()
  type: string = "";
  map: boolean = false;
  multiplelocationshowDialog = false; notes: Notes = new Notes();
  showDeleteDialog = false;
  Contactvalues: string = "0";
  active_deactiveStatus: number = 0;
  accountlocationlist: any;
  IsEditAccount: boolean = false;
  isColumnSelected: boolean = false;
  account: Accounts = new Accounts();
  @Input()
  accountCreatedDate: string;
  colSelections: string;
  ShowAddressmapping: boolean = false;
  IsshowFirst: boolean = false;
  isStatusidopen = false;
  accountlocations: Accountlocations = new Accountlocations();
  public zipmask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public dobmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  dropdownSetting = {};
  alllocations: boolean = true;
  addnewlocation: boolean = true;
  addresses: Accountlocations[];
  thisComp: ParentComp = ParentComp.Account;
  isViewInitialized: boolean = false;
  accessDenied: boolean = false;
  allCustomers: Accounts[];
  OriginalAllCustomers: Accounts[];
  public gridOptions: GridOptions; public gridEmptyOptions: GridOptions;
  public TableColumns: any;
  public LastActivitygridOptions: GridOptions;
  public rowData: any[];
  public columnDefs: any[];
  public defaultColDef;
  public lastActivitycolumnDefs: any[];
  pageSize ='1000';
  public context;
  public sideBar;
  public frameworkComponents;
  NotesDec = false;
  outProps: OutputPropsUserList = new OutputPropsUserList();
  accountcolumnlist: Dropdowntype[] = [];
  @ViewChild(OwnershipsharingComponent) ownrshipComp: OwnershipsharingComponent;
  @ViewChild(AddressmapComponent) addressMapComp: AddressmapComponent;
  @ViewChild(EditcontactComponent) editcontactComp: EditcontactComponent;
  @ViewChild(NotesComponent) notecomp;
  @ViewChild(AccountContactComponent) acccontactcomp;
  activeItems: boolean = true;
  heroForm: FormGroup; datePipe: DatePipe = new DatePipe("en-US");
  aggridheight: string; conid: number; notesshowDialog = false;
  public gridOptionsnotes: GridOptions;
  public columnDefsnotes: any[];
  allnotes1: Notes[];
  userdocumentlist: any[] = [];
  userdocumentcount: number = 0;
  openFileView: boolean = false;
  @ViewChild(UploadDocumentComponent) uploadDocComp: UploadDocumentComponent;
  DocViewAccessDenied: boolean = false;  
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear()-1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  timezonevalue :string;  
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } } 
  allnotes: Notes[];
  Ids = [];
  showEditNoteBtn: boolean = false;
  isopenopr: boolean = false;
  title: string = "";
  isdelete: boolean = false;
  subtitle:string = "";
  editNoteId: boolean = false;
  currentPage: number = 1;
  startrow: number = 1;
  noofRows: number = 1;
  accountid: number = 0;
  contactid: number = 0;
  totalnumberofrows: number = 1;
  accountSharingid: number = 0;
  selectedAccountid: number = 0;
  totalnumberofPages: number = 1;
  isCustomer: boolean = false;
  isSharing: boolean = false;
  isShowAccountInfo = false;
  isFormerCustomer: boolean = false;
  isShowDetailsChecked: boolean = false;
  isAccountViewOpen: boolean = true;
  isGooglemapExpandOpen: boolean = false;
  isContactViewOpen: boolean = false;
  AccConIds: string;
  userInfo: string;
  SelectedStatus: string = '0';
  calanderonoff: boolean = true;
  private gridApi;
  DocExpandView: boolean = false;
  AccountTasksCounts = new AccountTasksCounts();
  AllnotesOriginal: Notes[];
  public isDelink = false;
  public selectedNoteId: number;
  documentindex: number = 0;
  public isdocumentid: string = "";
  selectedNotes: Notes;
  noteidDiv: boolean = false;
  @Input() selectedTabName: string;
  @Input() typ: string;
  @Input()
  PageFrom: string = "";
  Upload: boolean = false;
  AttachDocument: boolean = false;
  notesMainDiv: boolean = true;
  isSharedAccts: boolean = true;
  selectedmyDashDefGridBox = [];
  selectedmyDashDefGridBoxid: number = 0;
  value: string = "";
  EnableSearch: boolean = false;
  currentMonth = (new Date().getMonth()) + 1;
  constructor(private paginationcop: PageComponent, public applicationComponent: ApplicationComponent,
    private elementRef: ElementRef, private renderer: Renderer2, private fb: FormBuilder, private http: HttpClient,
    private opprtunityservice: OpprtunitiesService, private route: ActivatedRoute, private accountService: AccountsService, private customerService: CustomerService,
    private router: Router, private toastr: ToastrService, private viewContainerRef: ViewContainerRef, private userservice: UserService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));    
    this.applicationComponent.selectedItems = (JSON.parse(localStorage.getItem("SelectedUserIds")));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {
      
      this.applicationComponent.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
      ];
      localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
      $("#userids").val(this.currentuser.id);
      this.applicationComponent.ExpandSelectedMenu(localStorage.getItem("Tab"));
    }
    this.createForm();
  }
  
  createForm()
  {
    this.heroForm = this.fb.group({
      name: '',
      secretLairs: this.fb.array([]),
      power: '',
      sidekick: ''
    });
  }

  getSelectedtmyDashDefGridBox() {
    this.userservice.GetSelectedUsermyDashDefGridBox(this.currentuser.id).then((result) => {
      this.selectedmyDashDefGridBox = result;
      this.selectedmyDashDefGridBoxid = result.selectedMyDashDefaultGridId;
    });
  }

  removeloction(val)
  {
    $("#" + (val + 2)).remove();
    this.secretLairs.removeAt(val);
  }

  delink(documentid, note) {
    $('#' + documentid + 'ON').attr('style', 'display:block;height:23px;');
    $('#' + documentid + 'Off').attr('style', 'display:none;height:23px;');
    let selfObj = this;
    setTimeout(function () {
      var isConfirmed = confirm("De-Link this document?");
      if (isConfirmed) {
        selfObj.isdocumentid = documentid;
        selfObj.selectedNotes = note;
        selfObj.UpdateNote(true);
        selfObj.isDelink = false;
      }
      else {
        $('#' + documentid + 'Off').attr('style', 'display:block;height:23px;');
        $('#' + documentid + 'ON').attr('style', 'display:none;height:23px;');
        selfObj.delinkOff(note.id)
      }
    }, 500);

  }
  delinkDocs(noteId) {
    this.isDelink = true;
    this.selectedNoteId = noteId;
    $('#' + noteId + 'Off').attr('style', 'display:none');
    $('#' + noteId + 'ON').attr('style', 'display:block;color:red;font-weight: 600;');
  }
  delinkOff(noteId) {
    this.isDelink = false;
    // this.selectedNoteId = noteId;
    $('#' + noteId + 'Off').attr('style', 'display:block');
    $('#' + noteId + 'ON').attr('style', 'display:none;color:red;font-weight: 600;');
  }

  AddDocumentsNew(val, Noteselected) {
    this.Upload = true;
    this.showDocumentAddDialogCss();
    this.selectedNotes = Noteselected;
    let selfobj = this;
    $(setTimeout(function () {
      selfobj.uploadDocComp.OpenFilemanual('New')
    }, 50));

  }
  addlink(note) {
    this.openFileView = false;
    this.AttachDocument = true;
    this.selectedNotes = note;
    this.notesMainDiv = false;
  }
  showDocumentAddDialogCss() {
    setTimeout(function () {
      $('#Upload .dialog').attr('style', 'width:44%;padding-bottom:0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height:63%;overflow:auto;height:77%;left:0;top: 13.5% !important;padding:0;right:32%');
    }, 4)
  }

  UpdateNote(val) {
    this.noteidDiv = false;
    this.notes.type = this.selectedNotes.notestype;
    this.notes.isnewnote = 0
    this.notes.isdocumentid = this.isdocumentid;
    this.notes.id = this.selectedNotes.id;
    this.notes.userid = this.currentuser.id;
    this.notes.notedescription = this.selectedNotes.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/img, "");
    this.notes.notedescription = this.selectedNotes.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/gi, "");
    this.notes.isDelink = val;
    if (this.notes.notedescription != undefined && this.notes.notedescription != "") {
      this.customerService.UpdateNotes(this.notes).then(result => {
        this.toastr.success("Notes saved successfully.", "Notes Saved", { timeOut: 600 });
        this.Notes("Display All");
        $('.fr-element').empty();
        $('.fr-element').html("");

      });
    }
  }

  Notes(val) {
    let allContactIds = [];
    this.customerService.GetAllContactproviderByAccount(this.accountid, this.currentuser.id).then((contacts) => {
        if (contacts.length > 0) {
          allContactIds = contacts.map(x => x.id);
          this.GetCustomerNotes(this.currentuser.id, allContactIds.join(","), 'AllContactsNotes', this.accountid.toString(), "null");
        } else {
          this.GetCustomerNotes(this.currentuser.id, "0", 'AllContactsNotes', this.accountid.toString(), "null");
        }

      });  
  }

  public GetAllNotes(contactid, type) {
    this.allnotes1 = [];
    this.customerService.GetAllNotesList(this.currentuser.id, contactid, type).then((notes) => {
      notes.forEach(notes => {
        var moment = require('moment-timezone');
        var timeDisplay = notes.user + '<br />' + moment.tz(notes.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(notes.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        notes.created = this.datePipe.transform(notes.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.AllnotesOriginal = this.allnotes1 = notes;
      for (let note of this.allnotes1) {
        if (note.isdocumentid != null && note.isdocumentid != "") {
          note.docList = note.isdocumentid.split(",");
        }
      }

    });
  }
  createColumnDefs(columnSelections: string, searchval: string, SearchSelectedItems) {
    let selfObj = this;
    var searcharray = [];
    if (selfObj.gridOptions.quickFilterText != null && selfObj.gridOptions.quickFilterText != undefined && selfObj.gridOptions.quickFilterText != '') {
      searcharray.push(selfObj.gridOptions.quickFilterText);
    }
  
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {   
      columnSelectionsArray = columnSelections.split(',');
      selfObj.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleArray[0] == "#") {
          selfObj.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleArray[0] == "customertypeid") {
          selfObj.columnDefs.push({
            field: 'customertypeid', headerName: 'Customer Type', getQuickFilterText: () => '', hide: fieldHeaderVisibleArray[2] == "true" ? false : true,
            cellRenderer(params) {
              if (params.value == '0') { return "Non - Customer" }
              else if (params.value == '56') { return "Customer" }
              else if (params.value == '57') { return "Former Customer" } else { return "Non - Customer" }
            }, width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
        else if (fieldHeaderVisibleArray[0] == "accountname") {
          selfObj.columnDefs.push({
            field: 'accountname', headerName: 'Account Name', hide: fieldHeaderVisibleArray[2] == "true" ? false : true,           
            cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(' ');
                var st = "";
                for (let i = 0; i < x.length; i++) {                  
                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";                    
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                } else {
                  return params.value;
                }
              }
            },           
          });
        }
        else if (fieldHeaderVisibleArray[0] == "accnumber") {
          selfObj.columnDefs.push({
            field: 'accnumber', headerName: 'Act Number', getQuickFilterText: () => '', hide: fieldHeaderVisibleArray[2] == "true" ? false : true,
            cellRenderer: function (params) {
              if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              } else {
                return params.value;
              }
            }
          });           
        }
        else if (fieldHeaderVisibleArray[0] == "mapit") {
          selfObj.columnDefs.push({
            field: 'mapit', headerName: 'Map It', hide: false, getQuickFilterText: () => '',width: 80, cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<img src="../../../assets/img/Map it_white.png" style="height:20px;width:50%"/>';
              //eDiv.addEventListener('click', function () {
              //});
              return eDiv;
            }   
          });
        }
        else if (fieldHeaderVisibleArray[0] == "actions") {
          selfObj.columnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: fieldHeaderVisibleArray[2] == "true" ? false : true, pinned: 'right', width: parseInt(fieldHeaderVisibleArray[3]) });
        }
        else if (fieldHeaderVisibleArray[0] == "accountStatus") {
          selfObj.columnDefs.push({
            field: 'accountStatus', headerName: 'Account Status', getQuickFilterText: () => '',cellRenderer(params) {
              if (params.value == 14) {
                return 'Active';
              } else {
                return 'On-Hold';
              }
            }, hide: fieldHeaderVisibleArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
        else if (fieldHeaderVisibleArray[0] == "notes") {
          selfObj.columnDefs.push({ field: 'notes', headerName: 'Notes', getQuickFilterText: () => '', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleArray[3]) });

        }
        else {

          var res = this.TableColumns.filter(ele => ele.ColumnName == fieldHeaderVisibleArray[0] && ele.DataType == "Pick List")
          if (res.length > 0) {
            selfObj.columnDefs.push({
              field: fieldHeaderVisibleArray[0] == "SalesRep" ? "salesrepusername" : fieldHeaderVisibleArray[0] == "leadfromid" ? "leadfrom": fieldHeaderVisibleArray[0],
              headerName: fieldHeaderVisibleArray[1],
              hide: fieldHeaderVisibleArray[2] == "true" ? false : true, getQuickFilterText: () => '',
              width: parseInt(fieldHeaderVisibleArray[3]),
              cellRenderer(params) {
                if (params.value == '881') {
                  return 'USA';
                } else if (params.value == '882') {
                  return 'Bahamas';
                } else {
                  if ((params.data.longtitude == "" || params.data.longtitude == null)
                    && selfObj.applicationComponent.googlemapshow) {
                    return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                  } else if (fieldHeaderVisibleArray[0] == "IsCustomer" && (params.data.isCustomer == "" || params.data.isCustomer == undefined || params.data.isCustomer == null || params.data.isCustomer == 875)) {
                    return "No"
                  } else if (fieldHeaderVisibleArray[0] == "IsCustomer" && (params.data.isCustomer == "" || params.data.isCustomer == undefined || params.data.isCustomer == null || params.data.isCustomer == 874)) {
                    return "Yes"
                  }
                  else if (fieldHeaderVisibleArray[0] == "soldBy") {
                    if (params.data.soldBy == 0) { return "Sales Team"; }
                    else if (params.data.soldBy == 1) { return "Reseller" }
                  }
                  else {
                    return params.value;
                  }
                }
               
              }
            });
          }
          else {
            if (fieldHeaderVisibleArray[0] == 'phone') {
              selfObj.columnDefs.push({
                field: fieldHeaderVisibleArray[0],
                headerName: fieldHeaderVisibleArray[1],
                hide: fieldHeaderVisibleArray[2] == "true" ? false : true,
                width: parseInt(fieldHeaderVisibleArray[3]),
                cellRenderer: function (params) {                 
                  if (searcharray.length > 0 && params.value != null) {
                    let x = params.value.split(" ");
                    let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
                    var st = "";
                    for (let i = 0; i < x.length; i++) {
                      if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                        st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                      }
                      else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                        st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                      }
                      else {
                        st += x[i] + " ";
                      }

                    }
                    return st

                  } else {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
                }

              });
            } else {
              selfObj.columnDefs.push({
                field: fieldHeaderVisibleArray[0],
                headerName: fieldHeaderVisibleArray[1] ,
                hide: fieldHeaderVisibleArray[2] == "true" ? false : true, getQuickFilterText: () => '',
                width: parseInt(fieldHeaderVisibleArray[3]),
                cellRenderer: function (params) {
                  if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                    return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                  } else {
                    return params.value;
                  }
                }

              });
            }
          }
          
        }
      }
    }
    else {

      if (selfObj.PageFrom == 'dashboard') {
        selfObj.columnDefs = [
          {
            field: 'accountname', headerName: 'Account Name', hide: false, cellRenderer: function (params) {

              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].toLowerCase().trim())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st
              } else {
                return params.value;
              }
            }
          },
          {
            field: 'OWNER', headerName: 'Owner', hide: false
          },
          {
            field: 'address1', headerName: 'Address1', hide: false,
          },
          {
            field: 'address2', headerName: 'Address2', hide: false
          },
          {
            field: 'city', headerName: 'City', hide: false
          },
          {
            field: 'state', headerName: 'State', hide: false
          },
          {
            field: 'zip', headerName: 'Zip', hide: false
          },
          { field: 'country', headerName: 'Country ', hide: false },
         
          { field: 'accounttypeName', headerName: 'Account Type', hide: false },
          {
            field: 'specialityName', headerName: 'Specialty', hide: false
          },
          {
            field: 'ehrName', headerName: 'EHR', hide: false
          },
          {
            field: 'pmName', headerName: 'PM', hide: false
          },
          {
            field: 'rcmName', headerName: 'RCM', hide: false
          }
        ];
      }
      else {
        selfObj.columnDefs = [
          {
            headerName: '#',
            field: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false, outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          }];

        selfObj.TableColumns.forEach(ele => {
          if (ele.OldOrNew == 'Old') {
            switch (ele.ColumnName) {
              case 'id': {
                selfObj.columnDefs.push({ field: 'id', headerName: 'Id', getQuickFilterText: () => '', hide: true });
                break;
              }
              case 'customertypeid': {
                selfObj.columnDefs.push({
                  field: 'customertypeid', headerName: 'Customer Type', getQuickFilterText: () => '', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer),
                  cellRenderer(params) {
                    if (params.value == '0') { return "Non - Customer" } else if (params.value == '56') { return "Customer" } else if (params.value == '57') { return "Former Customer" }
                  }
                });
                break;
              }
              case 'accnumber': {
                selfObj.columnDefs.push({
                  field: 'accnumber', headerName: 'Act Number', getQuickFilterText: () => '', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer), cellStyle: { color: 'red' }, cellRenderer: function (params) {
                    if (params.data.SearchVal != null && params.value != null) {
                      let x = params.value.split(" ");
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        var search = params.data.SearchVal;
                        if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                          st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'IsContact': {
                selfObj.columnDefs.push({ field: 'IsContact', getQuickFilterText: () => '', headerName: ' Is Contact?', hide: false });
                break;
              }
              case 'accountname': {
                selfObj.columnDefs.push({
                  field: 'accountname', headerName: 'Account Name', hide: false, suppressSorting: true, resizable: true,
                  cellRenderer: function (params) {
                    if (searcharray.length > 0 && params.value != null) {
                      let x = params.value.split(' ');
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                          st += "<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }
                      }
                      return st

                    } else {
                     
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;" 
                      } else {
                        return params.value;
                      }
                    }
                  },
                });
                break;
              }
              case 'speciality': {
                selfObj.columnDefs.push({
                  field: 'specialityName', headerName: 'Specialty', getQuickFilterText: () => '', hide: false, cellRenderer: function (params) {
                    if (params.data.SearchVal != null && params.value != null) {
                      let x = params.value.split(" ");
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        var search = params.data.SearchVal;
                        if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                          //if (x[i] == search || (x[i].toLowerCase() == params.data.SearchVal.toLowerCase())) {
                          st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'address1': {
                selfObj.columnDefs.push({
                  field: 'address1', headerName: 'Address1', getQuickFilterText: () => '', hide: false, cellRenderer: function (params) {
                    if (params.data.SearchVal != null && params.value != null) {
                      let x = params.value.split(" ");
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        var search = params.data.SearchVal;
                        if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                          st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'address2': {
                selfObj.columnDefs.push({
                  field: 'address2', headerName: 'Address2', hide: false, getQuickFilterText: () => '', cellRenderer: function (params) {
                    if (params.data.SearchVal != null && params.value != null) {
                      let x = params.value.split(" ");
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        var search = params.data.SearchVal;
                        if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                          st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'city': {
                selfObj.columnDefs.push({
                  field: 'city', headerName: 'City', hide: false, getQuickFilterText: () => '',cellRenderer: function (params) {
                    if (params.data.SearchVal != null && params.value != null) {
                      let x = params.value.split(" ");
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        var search = params.data.SearchVal;
                        if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                          st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'ownerid': {
                selfObj.columnDefs.push({
                  field: 'OWNER', headerName: 'Owner', hide: false, getQuickFilterText: () => '', cellRenderer: function (params) {
                    if (params.data.SearchVal != null && params.value != null) {
                      let x = params.value.split(" ");
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        var search = params.data.SearchVal;
                        if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                          st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break
              }
              case 'state': {
                selfObj.columnDefs.push({
                  field: 'state', headerName: 'State', hide: false, getQuickFilterText: () => '', cellRenderer: function (params) {
                    if (params.data.SearchVal != null && params.value != null) {
                      let x = params.value.split(" ");
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        var search = params.data.SearchVal;
                        if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                          st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'zip': {
                selfObj.columnDefs.push({
                  field: 'zip', headerName: 'Zip', hide: false, getQuickFilterText: () => '', cellRenderer: function (params) {
                    if (params.data.SearchVal != null && params.value != null) {
                      let x = params.value.split(" ");
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        var search = params.data.SearchVal;
                        if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                          st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'phone': {
                selfObj.columnDefs.push({
                  field: 'phone', headerName: 'Phone', hide: true, cellRenderer: function (params) {
                    if (searcharray.length > 0 && params.value != null) {
                      let x = params.value.split(" ");
                      let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                          st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                        }
                        else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                          st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'mapit': {
                selfObj.columnDefs.push({
                  field: 'mapit', headerName: 'Map It', hide: false, getQuickFilterText: () => '',width: 80, cellRenderer(params) {
                    var eDiv = document.createElement('div');
                    eDiv.innerHTML = '<img src="../../../assets/img/Map it_white.png" style="height:20px;width:50%"/>';
                    return eDiv;
                  }
                });
                break;
              }
              case 'ehr': {
                selfObj.columnDefs.push({
                  field: 'ehrName', headerName: 'EHR', hide: false, getQuickFilterText: () => '',cellRenderer: function (params) {
                    if (params.data.SearchVal != null && params.value != null) {
                      let x = params.value.split(" ");
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        var search = params.data.SearchVal;
                        if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                          st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'pm': {
                selfObj.columnDefs.push({
                  field: 'pmName', headerName: 'PM', hide: false, getQuickFilterText: () => '', cellRenderer: function (params) {
                    if (params.data.SearchVal != null && params.value != null) {
                      let x = params.value.split(" ");
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        var search = params.data.SearchVal;
                        if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                          st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'rcm': {
                selfObj.columnDefs.push({
                  field: 'rcmName', headerName: 'RCM', hide: false, getQuickFilterText: () => '', cellRenderer: function (params) {
                    if (params.data.SearchVal != null && params.value != null) {
                      let x = params.value.split(" ");
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        var search = params.data.SearchVal;
                        if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                          st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'ownerid': {
                selfObj.columnDefs.push({
                  field: 'OWNER', headerName: 'Owner', hide: false, getQuickFilterText: () => '', cellRenderer: function (params) {
                    if (params.data.SearchVal != null && params.value != null) {
                      let x = params.value.split(" ");
                      var st = "";
                      for (let i = 0; i < x.length; i++) {
                        var search = params.data.SearchVal;
                        if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                          st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                        }
                        else {
                          st += x[i] + " ";
                        }

                      }
                      return st

                    } else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'accounttype': {
                selfObj.columnDefs.push({
                  field: 'accounttypeName', getQuickFilterText: () => '', headerName: 'Account Type', hide: true,
                  cellRenderer: function (params) {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
                });
                break;
              }
              case 'ownership': {
                selfObj.columnDefs.push({
                  field: 'ownershipName', getQuickFilterText: () => '', headerName: 'Ownership', hide: true,
                  cellRenderer: function (params) {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
        });
                break;
              }
              case 'accountStatus': {
                selfObj.columnDefs.push({
                  field: 'accountStatus', headerName: 'AccountStatus', getQuickFilterText: () => '', hide: true, cellRenderer: function (params) {
                    if (params.value == 14) {
                      return 'Active';
                    } else {
                      return 'On-Hold';
                    }
                  }
                });
                break;
              }
              case 'country': {
                selfObj.columnDefs.push({
                  field: 'country', getQuickFilterText: () => '', headerName: 'Country', hide: true, cellRenderer: function (params) {
                    if (params.value == 881) {
                      return 'USA';
                    } else if (params.value == 882) {
                      return 'Bahamas';
                    }
                    else {
                      if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                        return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                      } else {
                        return params.value;
                      }
                    }
                  }
                });
                break;
              }
              case 'ProvidenceIsland': {
                selfObj.columnDefs.push({
                  field: 'ProvidenceIsland', getQuickFilterText: () => '', headerName: 'Providence Island', hide: true,
                  cellRenderer: function (params) {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
});
                break;
              }
              case 'fax': {
                selfObj.columnDefs.push({
                  field: 'fax', getQuickFilterText: () => '', headerName: 'Fax', hide: true,
                  cellRenderer: function (params) {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
});
                break;
              }
              case 'alterphone1': {
                selfObj.columnDefs.push({
                  field: 'alterphone1', getQuickFilterText: () => '', headerName: 'alterphone1', hide: true,
                  cellRenderer: function (params) {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
});
                break;
              }
              case 'alterphone2': {
                selfObj.columnDefs.push({
                  field: 'alterphone2', getQuickFilterText: () => '', headerName: 'alterphone2', hide: true,
                  cellRenderer: function (params) {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
});
                break;
              }
              case 'website': {
                selfObj.columnDefs.push({
                  field: 'website', getQuickFilterText: () => '', headerName: 'website', hide: true,
                  cellRenderer: function (params) {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
});
                break;
              }
              case 'pmdatepurchased': {
                selfObj.columnDefs.push({
                  field: 'pmdatepurchased', getQuickFilterText: () => '', headerName: 'PM Date Purchased', hide: true,
                  cellRenderer: function (params) {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
});
                break;
              }
              case 'ehrdatepurchased': {
                selfObj.columnDefs.push({
                  field: 'ehrdatepurchased', getQuickFilterText: () => '', headerName: 'EHR Date Purchased', hide: true,
                  cellRenderer: function (params) {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
});
                break;
              }
              case 'rcmdatepurchased': {
                selfObj.columnDefs.push({
                  field: 'rcmdatepurchased', getQuickFilterText: () => '', headerName: 'RCM Date Purchased', hide: true,
                  cellRenderer: function (params) {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
});
                break;
              }
              case 'created': {
                selfObj.columnDefs.push({
                  field: 'created', getQuickFilterText: () => '', headerName: 'Created Date', hide: true,
                  cellRenderer: function (params) {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
});
                break;
              }
              case 'leadfrom': {
                selfObj.columnDefs.push({
                  field: 'leadfrom', getQuickFilterText: () => '', headerName: 'Lead From', hide: true,
                  cellRenderer: function (params) {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }
});
                break;
              }

            }


          }
          else {
            if (ele.DataType == "Pick List") {
              selfObj.columnDefs.push({
                field: ele.ColumnName, headerName: ele.DisplayAs, hide: true, width: 200, cellRenderer(params) {

                  if (params.value != null && params.value != "") {
                    return params.value.split(":")[1];
                  } else {
                    if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                      return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    } else {
                      return params.value;
                    }
                  }


                }
              })
            }
            else {
              selfObj.columnDefs.push({
                field: ele.ColumnName, headerName: ele.DisplayAs, hide: true, width: 200, cellRenderer: function (params) {
                  if ((params.data.longtitude == "" || params.data.longtitude == null) && selfObj.applicationComponent.googlemapshow) {
                    return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                  } else {
                    return params.value;
                  }
                } })
            }
          }

        });        
        selfObj.columnDefs.push({ field: 'Geo', headerName: 'Geo ', hide: false })
        selfObj.columnDefs.push({ field: 'longtitude', headerName: 'longtitude',  hide: true })
        selfObj.columnDefs.push({ field: 'lattitude', headerName: 'lattitude', hide: true })
        selfObj.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false })
        selfObj.columnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: false, pinned: 'right' })

      }
    }
  }

  createLastActivityColumnDefs(columnSelections: string, searchval: string, SearchSelectedItems) {
    
    let selfObj = this;
    var searcharray = [];
    if (this.applicationComponent.searchCriteriadata != null) {
      var searchaarraystring = this.applicationComponent.searchCriteriadata;
      if (searchaarraystring.includes(' /')) {
        searcharray = searchaarraystring.split(' / ');
      } else {
        searcharray.push(searchaarraystring);
      }
    }
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      selfObj.lastActivitycolumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleArray[0] == "#") {
          selfObj.lastActivitycolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleArray[0] == "customertypeid") {
          selfObj.lastActivitycolumnDefs.push({
            field: 'customertypeid', headerName: 'Customer Type', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer),
            cellRenderer(params) { if (params.value == '0') { return "Non - Customer" } else if (params.value == '56') { return "Customer" } else if (params.value == '57') { return "Former Customer" } }, width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
        else if (fieldHeaderVisibleArray[0] == "accountname") {
          selfObj.lastActivitycolumnDefs.push({
            field: 'accountname', headerName: 'Account Name', hide: false, cellRenderer: function (params) {

              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].toLowerCase().trim())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st
              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleArray[0] == "accnumber") {
          selfObj.lastActivitycolumnDefs.push({
            field: 'accnumber', headerName: 'Act Number', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleArray[0] == "mapit") {
          selfObj.lastActivitycolumnDefs.push({
            field: 'mapit', headerName: 'Map It', hide: false, width: 80, cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<img src="../../../assets/img/Map it_white.png" style="height:20px;width:50%"/>';
              return eDiv;
            }
          });
        }
        else if (fieldHeaderVisibleArray[0] == "actions") {
          selfObj.lastActivitycolumnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: fieldHeaderVisibleArray[2] == "true" ? false : true, pinned: 'right', width: parseInt(fieldHeaderVisibleArray[3]) });
        }
        else if (fieldHeaderVisibleArray[0] == "notes") {
          selfObj.lastActivitycolumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleArray[3]) });

        }
        else {
          selfObj.lastActivitycolumnDefs.push({
            field: fieldHeaderVisibleArray[0],
            headerName: fieldHeaderVisibleArray[1],
            hide: fieldHeaderVisibleArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleArray[3]),
            cellRenderer: function (params) {

              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {

                  if (searcharray.toString().toLowerCase().includes(x[i].toLowerCase().trim())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }

          });
        }
      }
    }
    else {
      selfObj.lastActivitycolumnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'id', headerName: 'Id', hide: true },
        {
          field: 'customertypeid', headerName: 'Customer Type', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer),
          cellRenderer(params) {
            if (params.value == '0') { return "Non - Customer" } else if (params.value == '56') { return "Customer" } else if (params.value == '57') { return "Former Customer" }
          }
        },
        {
          field: 'accnumber', headerName: 'Act Number', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer), cellStyle: { color: 'red' }, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        { field: 'IsContact', headerName: ' Is Contact?', hide: false },
        {

          field: 'accountname', headerName: 'Account Name', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'speciality', headerName: 'Specialty', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'address1', headerName: 'Address1', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'address2', headerName: 'Address2', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'city', headerName: 'City', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'state', headerName: 'State', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'zip', headerName: 'Zip', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'phone', headerName: 'Phone', hide: true, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'mapit', headerName: 'Map It', hide: false, width: 80, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<img src="../../../assets/img/Map it_white.png" style="height:20px;width:50%"/>';

            return eDiv;
          }
        },
        {
          field: 'ehr', headerName: 'EHR', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'pm', headerName: 'PM', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'rcm', headerName: 'RCM', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false
        },
        { field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: false, pinned: 'right' },
        
      ];

    }
  }

  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  autoSizeAll() {
  
  }
  txtPageSizeChanged(pageSize) {
    this.currentPage = 1;
    this.pageSize = pageSize;
    this.loadallaccounts($("#userids").val(), false, this.currentPage, this.pageSize, this.active_deactiveStatus);
    this.saveConfiguration();
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
    
  }
  saveConfiguration()
  {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";
    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    } 
    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    this.UpdateSearchColumns(config.columnsselections);

    config.configureduserid = this.currentuser.id;
    config.resourceid = (this.PageFrom == "dashboard") ? 17 : 21;

    config.userid = "";
    for (let i = 0; i < this.applicationComponent.selectedItems.length; i++) {
      config.userid += this.applicationComponent.selectedItems[i].id + ",";
    }
    config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    this.opprtunityservice.saveDashboardconfiguration(config);
  }
  UpdateSearchColumns(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[] = columnSelections.split(',');
    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');
      
      if ((fields[2] == 'true' && fields[1] != '#') && (fields[2] == 'true' && fields[0] != 'notes') && (fields[2] == 'true' && fields[0] != 'actions')) {
      
        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }
    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];
    
    if (retrievedObject != null) {
      let obj: any[] = JSON.parse(retrievedObject);
      obj.forEach(element => {

        if (element.subtab == 'CustomerAccount') {
            tempTableColumns = element.columns;
            this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        } else if (element.subtab == 'ContactsAccount') {
            tempTableColumns = element.columns;
            this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
          } 

      
      });
    }
    if (tempTableColumns && tempTableColumns.length > 0 ) {
      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })

    }
    else {
      this.accountService.GetDefaultColumns('ContactsAccount', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.applicationComponent.SearchSelectedItems = [
            { "id": 'accountname', "itemName": 'Account Name' }
          ];
        }
      });
    }
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, paginationPageSize: 1000, enableSorting: false, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true,
      toolPanelSuppressPivotMode: true, rowSelection: this.isShowAccountInfo ? 'single': 'multiple', enableCellChangeFlash: true, suppressSorting: true, suppressMultiSort: true      
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };  
    let columnSelections: string;
    let configId = (this.PageFrom == "dashboard") ? 17:(this.PageFrom == "Reports") ? 51 : 21;
  
      this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, configId).then((config) => {
        if (config != null && config.maxlimit != null && config.maxlimit > 0) {
          this.pageSize = config.maxlimit.toString();
        }
        this.colSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
        this.colSelections = config != null && config.columnsselections != null ? config.columnsselections : "";

        this.startDateVal = (config != null && config.startdate != null) ? config.startdate : (new Date().getFullYear() - 1) + '-01-01';
        this.endDateVal = (config != null && config.enddate != null) ? config.enddate : new Date().getFullYear() + '-12-31';

        this.pageSize = (this.pageSize == "") ? '10' : this.pageSize;
        this.customerService.GetTableFields('Accounts', this.currentuser.id).then(result => {
          this.TableColumns = result;
          this.createColumnDefs(this.colSelections, this.applicationComponent.searchstring, this.applicationComponent.SearchSelectedItems);
        });

        if (localStorage.getItem('RhxAccountVal') != null && localStorage.getItem('RhxAccountVal') != undefined) {
          this.gridOptions.rowSelection = 'single';
          this.isShowAccountInfo = true;
          this.accountid = Number(localStorage.getItem('RhxAccountVal'));
          let selfObj = this;
          $(setTimeout(function () {
            selfObj.gridOptions.api.forEachNode((node) => {
              if (node.data.id == selfObj.accountid) {
                selfObj.gridOptions.api.selectNode(node, true);
              
              }
            });
          }, 300));
          this.customerService.GetByAccountId(this.accountid).then(AccResult => {
            this.Contactvalues = (AccResult.customertypeid != null) ? AccResult.customertypeid.toString() : '0';
            this.loadallaccounts($("#userids").val(), false, 1, this.pageSize, 0);

          });

        }
        else {
          this.loadallaccounts($("#userids").val(), false, 1, this.pageSize, 0);
        }
        this.UpdateSearchColumns(this.colSelections)

      });
   
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeletecvComponent,
      notesrenderer: NotesrendererComponent
    };

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
  }

  
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
          if (this.isShowAccountInfo) {
            this.acccontactcomp.AccountData('show' + event.api.getSelectedNodes()[0].data.id);
            this.acccontactcomp.showSccountInfo = true;
            this.acccontactcomp.ShowContactView = false;
            this.accountid = event.api.getSelectedNodes()[0].data.id;
          }
      }
    }
  }
  rowClickAgGrid(rowEvent) {

    if (this.gridApi.getSelectedRows()!=undefined && this.gridApi.getSelectedRows()?.length > 0 ) {
      $('#emailIds').val(this.gridApi.getSelectedRows().map(x => x.id).join(','));
      this.AccConIds = this.gridApi.getSelectedRows().map(x => x.id).join(',');
      $('#tasklistselectedtyle').val('Customer');
    } else {
      $('#emailIds').val("");
      this.AccConIds = "";
      $('#tasklistselectedtyle').val('');
    }
    if (this.applicationComponent.googlemapshow) {    
      if (this.addressMapComp != undefined) {
        this.addressMapComp.GetLocations(this.AccConIds, "Accounts");
      }
    }    
  }
  
  cellDoubleClicked(rowEvent) {
    if (this.PageFrom == "dashboard") {
      let SO = this;
      $(setTimeout(function () {
        SO.applicationComponent.activateSubButtons('empty');
        $(setTimeout(function () {
          SO.router.navigateByUrl(`/application/account/Customer`);
          SO.applicationComponent.ExpandSelectedMenu('customers');
          $(".subButtons").removeClass("activeButton");
          SO.applicationComponent.selectedtabid = 'customers';
        }, 50));
      }, 25));
    } else {
      this.applicationComponent.googlemapshow = false;
      this.applicationComponent.pageViewed = "";
      this.isSharing = false;
      this.isShowAccountInfo = true;
      this.accountid = rowEvent.data.id;
      localStorage.setItem('RhxAccountVal', rowEvent.data.id);
      this.gridOptions.rowSelection = 'single';
      if (this.EnableSearch) { this.EnableSearch = false; }
    }
  }
  AccountIdsReceived(msg) { 
    this.allCustomers = this.allCustomers.filter(x => this.AccConIds.split(',').includes(x.id.toString()));
   
  }
  MapItEventListner() {   
    if (this.applicationComponent.isMapItEventRaised) {
      this.IsEditAccount = false;
      if (!this.IsshowFirst) {
     this.createColumnDefs(this.colSelections, this.applicationComponent.searchstring, this.applicationComponent.SearchSelectedItems);
        this.IsshowFirst = true;     
      }
    } 
    else {
      this.applicationComponent.googlemapshow = false;

    }
  }
  ngOnInit() {
    this.applicationComponent.googlemapshow = false;
    localStorage.removeItem('CompaignselVal');
    this.route.params.subscribe(params => {
      this.term = params['term'];
      if (this.PageFrom !== 'dashboard') {
        this.type = params['type'];
        if (this.type == "Customer") { this.applicationComponent.selectedbuttonText = "Customers"; }
        else {
          this.applicationComponent.selectedbuttonText = "Accounts";
        }
      }      
      this.map = params['map'];
      $(".checkMark").removeClass("activeColor");
      $(".subButtons").removeClass("activeButton");
     
    });
 
    if ((this.type === "Customer") && this.PageFrom !='dashboard') {
      this.applicationComponent.pageViewed = "Customer";
      if (this.currentuser.defaultSearchPage == 1) {
        this.EnableSearch = true;
      } else { this.EnableSearch = false; }
    } else {
      this.applicationComponent.pageViewed = "";
      this.isShowAccountInfo = false;
    }
     
    this.search = this.applicationComponent.searchstring; 
  
    if (this.type == "Customer") {
       this.Contactvalues = '1';
      }
   
    if (this.term != "SearchCriteria") {    
      this.initializeAgGrid();
    }
      
    this.isViewInitialized = true;
  
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 50));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 50));
    }

    this.accountCreatedDate = this.account.created;
    this.getSelectedtmyDashDefGridBox();
  }

  dateChange(event) {
    this.AssignStartAndEndDate(event);
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
    this.saveConfiguration();
    this.loadallaccounts($("#userids").val(), false, this.currentPage, this.pageSize, this.active_deactiveStatus);
  }
  AssignStartAndEndDate(event) {
    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  userListOutParamsBinding(outProps) {
    this.finalitems = outProps.finalitems;
    this.items = outProps.items;
    this.itemCount = outProps.itemsCount;
    this.applicationComponent.selectedItems = outProps.selectedItems;
  }

  loadallaccounts(userids, isSearch: boolean, startrow, endrow, accstatus) {
    if (accstatus == undefined)
      accstatus = 0;
    var today = new Date();
    var startDate = today.getFullYear() + '-01-01';
    var endDate = today.getFullYear() + '-12-31';
    $('#startdate').val(startDate);
    $('#enddate').val(endDate);
    userids = $("#userids").val();
    this.applicationComponent.selectedusers = userids;
    this.applicationComponent.searchstring = "";
    this.applicationComponent.searchCriteriadata = [];
   
    if (this.type == "Customer" || this.type == "imported") {  
      this.opprtunityservice.GetAllAccountsBySearchNew(this.currentuser.id, this.term, userids, this.type, (this.calanderonoff) ? '2018-01-01' : '2018-01-01',
        this.endDateVal, startrow, endrow, this.Contactvalues, accstatus).then((account) => {       
        if (this.PageFrom == 'dashboard') {          
          if (this.selectedmyDashDefGridBoxid == 2) {
           var date = new Date();
           var firstDay = new Date(date.getFullYear(), date.getMonth(), 1); var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
           this.allCustomers = this.OriginalAllCustomers = account.filter(x => x.created >= moment(firstDay.toString()).format("YYYY-MM-DD") && x.created <= moment(lastDay.toString()).format("YYYY-MM-DD"));
          } else {
            this.allCustomers = this.OriginalAllCustomers = account.filter(x => x.customertypeid == 1);
          }
          this.isShowAccountInfo = false;
        } else {         
          if (this.currentuser.defaultSearchPage == 0) {
            this.OriginalAllCustomers = account;
            this.allCustomers = this.OriginalAllCustomers;
            if (this.currentuser.hidecustomer == 1 && !this.EnableSearch) {
              this.allCustomers = [];
            } else if (this.currentuser.hidecustomer == 1 && this.EnableSearch) {           
              if (this.value != null && this.value != "") {              
                this.allCustomers = this.OriginalAllCustomers;
              }
            }
            else {    
              this.allCustomers = this.OriginalAllCustomers;
            }
            } else {
              this.OriginalAllCustomers = account;
              this.allCustomers = [];
            }

            if (!this.isSharedAccts) {
              this.allCustomers = this.OriginalAllCustomers.filter(x => x.ownerid == this.currentuser.id);
              this.allCustomers = this.OriginalAllCustomers.filter(x => x.customertypeid == 1 && x.ownerid == this.currentuser.id);
          }
     
          }
       // }
          if (this.allCustomers.length > 0) {
          if (localStorage.getItem('RhxAccountVal') != null && localStorage.getItem('RhxAccountVal') != undefined) {
            this.gridOptions.rowSelection = 'single';
            this.accountid = Number(localStorage.getItem('RhxAccountVal'));
            let selfObj = this;
            $(setTimeout(function () {
              selfObj.gridOptions.api.forEachNode((node) => {
                if (node.data.id == selfObj.accountid) {
                  selfObj.gridOptions.api.selectNode(node, true);       
                }
              });
            }, 500));
          }
        } 
      });
    }
    else if (this.type == "Contact" || this.type == "imported" || this.type == "Accounts") {    
      this.opprtunityservice.GetAllAccountsBySearchNew(this.currentuser.id, this.term != undefined ? this.term : 'All', userids,
        this.type, (this.calanderonoff) ? '2018-01-01' : '2018-01-01', this.endDateVal, startrow, endrow, this.Contactvalues, accstatus).then((account) => {        
          this.allCustomers = this.OriginalAllCustomers = account.filter(x => x.isCustomer == null || x.isCustomer == 875);
          console.log("this.allCustomers", this.allCustomers);
          if (this.allCustomers.length > 0) {
            if (localStorage.getItem('RhxAccountVal') != null && localStorage.getItem('RhxAccountVal') != undefined) {
              this.gridOptions.rowSelection = 'single';
              this.accountid = Number(localStorage.getItem('RhxAccountVal'));
              let selfObj = this;
              $(setTimeout(function () {
                selfObj.gridOptions.api.forEachNode((node) => {
                  if (node.data.id == selfObj.accountid) {
                    selfObj.gridOptions.api.selectNode(node, true);
                  }
                });
              }, 500));
            }
          }
        }); 
    }
   
   
  }
  reloadItems(params) {
    if (this.itemResource != undefined) {
      this.itemResource.query(params).then(items => this.items = items);
    }
  }
  onItemSelectAccount(item: any) {
    var ids = item.id;
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.loadallaccounts($("#userids").val(), false, 1, this.pageSize, this.active_deactiveStatus);
  }
  OnItemDeSelect(item: any) {

    var ids = item.id;
    var result = "";
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    } else {
      this.allCustomers = [];
      $("#userids").val('');
    } 
    this.loadallaccounts($("#userids").val(), false, 1, this.pageSize, this.active_deactiveStatus);
  }
  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.loadallaccounts($("#userids").val(), false, 1, this.pageSize, this.active_deactiveStatus);
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.allCustomers = [];
    $("#userids").val('');
    this.applicationComponent.selectedusers = null;
   
  }
 
  Close(locationid) {
    var c = confirm("This Account has been Deleted.So you want to restore this account and all the contacts associated with this account to Active ?");
    if (c == true) {
      document.getElementById('location' + locationid).remove();
      this.customerService.updatemultiplelocation(locationid).then((user) => { });
    }
  }
  OpenMultiplelocation(rowEvent) {
    this.multiplelocationshowDialog = !this.multiplelocationshowDialog
    this.alllocations = false;
    this.addnewlocation = true;
    this.opprtunityservice.GetByAccountId(rowEvent.id).then(result => {
      this.account = result;
      this.GetAccountlocation(rowEvent.id); 
    });

  }
  GetAccountlocation(accid) {
    this.customerService.GetLocationByAccountId(accid).then(result => {
      result.forEach(Owner => {
      });
      this.accountlocationlist = result;
    });
  }
  rowDoubleClick(rowEvent) {
    if (rowEvent.row.item.permission == "view") {
      this.applicationComponent.isAccessDenied = true;
    }
    else {
      this.router.navigateByUrl(`/application/account-contact/${rowEvent.row.item.permission}/show${rowEvent.row.item.id}`)
    }
  }

  NewLocation() {
    this.addnewlocation = false;
    this.alllocations = true;
    document.getElementById('alllocations').style.display = 'none';
    document.getElementById('addnewlocation').style.display = 'block';
    this.customerService.GetLocationByAccountId(this.account.id).then(result => {
      this.addresses = result;
      this.setAddresses(this.addresses);
    });
  }
  SaveLocation() {
    this.addresses = this.prepareSaveHero();
    this.addresses[0].accountid = this.account.id;
    this.accountService.addaccountLocations(this.addresses).then(result => {
      this.GetAccountlocation(this.account.id);
      this.alllocations = true;
      this.addnewlocation = true;
     
    });

    document.getElementById('alllocations').style.display = 'block';
    document.getElementById('addnewlocation').style.display = 'none';

  }
  prepareSaveHero(): Accountlocations[] {
    const formModel = this.heroForm.value;
    const secretLairsDeepCopy: Accountlocations[] = formModel.secretLairs.map(
      (address: Accountlocations) => Object.assign({}, address),
    );
    return secretLairsDeepCopy;
  }
  Cancel() {
    this.alllocations = false;
    this.addnewlocation = true;
    document.getElementById('alllocations').style.display = 'block';
    document.getElementById('addnewlocation').style.display = 'none';
  }
  get secretLairs(): FormArray {
    return this.heroForm.get('secretLairs') as FormArray;
  };

  setAddresses(addresses: Accountlocations[]) {
    const addressFGs = addresses.map(address => this.fb.group(address));
    const addressFormArray = this.fb.array(addressFGs);
    this.heroForm.setControl('secretLairs', addressFormArray);
  }

  addLair() {
    this.secretLairs.push(this.fb.group(new Accountlocations()));
  }
 
  
  oneventReceived(msg) {
    this.IsEditAccount = false;
    this.type = 'Contact';
    this.loadallaccounts($("#userids").val(), false, 1, this.pageSize, this.active_deactiveStatus);
  }
  deletedialogclose() {
    this.showDeleteDialog = false;
    
  }
  deleteRowAg(rowdata) {
    if (rowdata.permission == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    }
    else {  
      this.selectedAccountid = rowdata.id;
      if (rowdata.isdeleted == 1) {
        var c = confirm("Are you sure you want to re-activate this account??");
        if (c == true) {
          this.customerService.DeactivateAccount(rowdata.id, 0, this.currentuser.id).then((user) => {
            this.loadallaccounts($("#userids").val(), false, 1, this.pageSize, this.active_deactiveStatus);
          });
        }
      } else {
        if (rowdata.ownerid == this.currentuser.id) { 
          this.title = "DELETE ACCOUNT";
          this.subtitle = "Delete this Account and all Contacts";
          this.isdelete = true;
          this.accountService.checkLeadorOpportunityOpen(rowdata.id).then(result => {
            this.AccountTasksCounts = result
            this.customerService.GetByAccountId(rowdata.id).then(account => {
              this.account = account;
              if (this.AccountTasksCounts.TotalLeads == 0 && this.AccountTasksCounts.TotalOpportunities == 0 && this.AccountTasksCounts.TotalPendProposals == 0 && this.AccountTasksCounts.TotalTasks == 0 && this.AccountTasksCounts.TotalTickets == 0) {
                this.isopenopr = false;
              }
              else {
                this.isopenopr = true;
              }
            });
         
          });
        } else {
          this.title = "DELETE ACCOUNT - ACCESS DENIED";
          this.subtitle = "You are not the owner of this account";
          this.isdelete = false;
        }
        this.showDeleteDialog = true;
        this.showDeleteDialogCss();
      }
    }

  }
  ActiateRowAg(rowdata) {
    
  }
  DeactivateAccountstatus(AccId) {
    this.customerService.DeactivateAccount(AccId, 1, this.currentuser.id).then((user) => {
      this.showDeleteDialog = false;
      this.loadallaccounts($("#userids").val(), false, 1, this.pageSize, this.active_deactiveStatus);
        });
  }

  openNotesDialogAg(rowdata) {
    if (rowdata.permission == "view" && this.currentuser.customertypeaccess == 0) {
      this.applicationComponent.isAccessDenied = true;
    }
    else {
      $('#accotIdNotes').val(rowdata.id);
      $('#accotTypeNotes').val('Customer');
      this.notesshowDialog = !this.notesshowDialog;
      this.NotesDialogCss();
      this.conid = rowdata.id;
    
    }
  }

  GetCustomerNotes(currentuserid, contactid, type, accidofallcontacts: string = "0", OppType: string) {
    var moment = require('moment-timezone');
    this.customerService.GetAllNotesListNew(currentuserid, contactid, type, accidofallcontacts, OppType).then((taskhistory) => {
      taskhistory.forEach(items => {
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");

      });
      this.AllnotesOriginal = this.allnotes1 = taskhistory;
      for (let note of this.allnotes1) {
        if (note.isdocumentid != null && note.isdocumentid != "") {
          note.docList = note.isdocumentid.split(",");
        }
      }
      if (this.selectedTabName == '1') {
        this.allnotes1 = this.AllnotesOriginal.filter(x => x.isnewnote !== null);
      } else {
        this.allnotes1 = this.AllnotesOriginal
      }
    });

  }

  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: true, enableColResize: true, paginationPageSize: 1000,enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.createColumnDefsnotes("");
    this.gridOptionsnotes.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  createColumnDefsnotes(columnSelections: string) {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          } 
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellStyle: { 'text-align': 'left' }, cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', cellStyle: { 'text-align': 'left' }, headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellStyle: { 'text-align': 'left' },cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];
  }
  onAgGridReadynotes(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllnotes();
      $(setTimeout(function () {
        selfObj.autoSizeAllnotes();
      }, 400));
    }, 400));
  }
  autoSizeAllnotes() {
    var allColumnIds = [];
    if (this.gridOptionsnotes && this.gridOptionsnotes.columnApi) {
      var allCols = this.gridOptionsnotes.columnApi.getAllColumns();
      if (allCols != null) {
        allCols.forEach(function (column) {
          allColumnIds.push(column.getColId());
        });
      }
      this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds);
    }

  }
  sharingAg(rowdata) {
    if (this.account.ownerid == this.currentuser.id || this.currentuser.systemadministrator==1) {
      this.accountid = rowdata.id;
      this.isSharing = true;
      this.isSharingCss();
      this.IsEditAccount = false;
    }
    
  }

  editRowAg(rowdata) {    
    if (rowdata.permission == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    }
    else {
      this.IsEditAccount = true;
      this.showAddEditAccountCss();    
      this.accountid = rowdata.id;     
    }
  }
  showAddEditAccountCss() {
    setTimeout(function () {

      $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }

  ExpandPDF(val) {
    this.notesshowDialog = true;
    this.QuickViewDialogCss(val);
    this.DocExpandView = val;
  }
  QuickViewDialogCss(val) {
    if (val == true) { //expand
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    } else {
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    }

  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 43%;padding: 0;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }

  dialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', ' width: 32%;padding - bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min - height: 23%;overflow: visible;height: 24%;');
    }, 4));
  }
  showDeleteDialogCss() {
    $(setTimeout(function () {
      $('#showDeleteDialog .dialog').attr('style', 'width: 50%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 4));
  }
  openDisplaynotes(params) {
    this.NotesDec = !this.NotesDec;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    $(setTimeout(function () {
      
      $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');
      if ($('input[name=Notes]').is(':checked')) {
        $('#txtLongNoteDesc').css('height', '81%');
        $('#txtLongNoteDesc').css('padding-top', '0%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
        $('#txtUserFullName').css('height', '14%');
      } else {
        $('#txtLongNoteDesc').css('height', '96%');
        $('#txtLongNoteDesc').css('padding-top', '6%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
      }
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));
  }
  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();
   
  }
  onFilterTextBoxChanged() {
    if (this.currentuser.defaultSearchPage == 1) {
      if ((document.getElementById('filter-text-box') as HTMLInputElement).value == "") {
        this.allCustomers = [];
      } else {
        this.allCustomers = this.OriginalAllCustomers;      
      }
    }
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
    this.gridOptions.quickFilterText = (document.getElementById('filter-text-box') as HTMLInputElement).value;
    if (this.currentuser.hidecustomer == 1 && this.EnableSearch) {
      this.allCustomers = this.OriginalAllCustomers.filter(x => x.accountname.indexOf(this.value));
    }
    this.createColumnDefs(this.colSelections, this.applicationComponent.searchstring, this.applicationComponent.SearchSelectedItems);
    this.gridApi.redrawRows();    
  }
  openfile(documentid, note, index) {
    this.documentindex = index
    this.selectedNotes = note;
    this.userdocumentlist = this.selectedNotes.docList;
    this.userdocumentcount = this.selectedNotes.docList.length;
    this.getImageData(documentid);
  }
  getImageData(documentid) {
    var myBaseString = "";
    this.customerService.GetDocumentById(documentid).then(result => {
      if (result.permissionlevel <= this.currentuser.permissionlevelid) {
        myBaseString = result.imagecontent;
        let objSelf = this;
        this.openFileView = true
        $(setTimeout(function () {
          objSelf.onDocumentOpen(myBaseString);
        }, 100));

        $('.bodyContaint').css('height', '600px')
        $('#NotespdfDiv').css('');
      }
      else {
        this.DocViewAccessDeniedCss();
      }

    });
  }

  DocViewAccessDeniedCss() {
    this.DocViewAccessDenied = true;
    setTimeout(function () {
      $('#DocViewAccessDenied .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4);
  }

  onDocumentOpen(myBaseString) {
    $('#NotespdfDiv').html('<embed src="' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
    $('#NotespdfDiv').attr('style', 'height: 93%;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');
  }

  saveEditedNote() {
   
  }
 
  loadselectedcontacts(str) {
    
    let selfObj = this;
    $(setTimeout(function () {   
      selfObj.initializeAgGrid();
      selfObj.loadallaccounts($("#userids").val(), false, selfObj.currentPage, selfObj.pageSize, selfObj.active_deactiveStatus);

    }, 400));
    }
 
  onCloseEventReceived(msg)
  {
    this.isSharing = false;
    if (msg == "Save") {
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.loadallaccounts($("#userids").val(), false, selfObj.currentPage, selfObj.pageSize, selfObj.active_deactiveStatus);
      }, 100));
      
    }
   else if (msg == "AccountEdit") {
      this.IsEditAccount = true;
      this.contactid = this.accountid;
      this.type = "Customer";
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.editcontactComp.editRowAg(selfObj.accountid, "Customer")      
      }, 100));
    }

  }
  UserlistDataReceived(outProps: OutputPropsUserList) {
    if (outProps.enableSearch) { this.EnableSearch = true; }
    if (outProps.custType == "Display contact") {
      this.SelectCustomerType("Display contact");
    } else {
      if (outProps.finalitems == "" || outProps.finalitems == undefined) {
        this.allCustomers = [];
      } else {
        this.calanderonoff = outProps.calanderonoff;
        this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;

        if (outProps.fifthDrpValue == "No Shared Accounts") {
          this.isSharedAccts = false;
        } else if (outProps.fifthDrpValue == "All Shared Accounts") { this.isSharedAccts = true; }

        if (outProps.fourthDrpValue != "0" && outProps.fourthDrpValue != undefined &&
          outProps.fourthDrpValue != "All Activity" && outProps.fourthDrpValue != "") {
          this.opprtunityservice.GetAlllastActivity($("#userids").val(), outProps.fourthDrpValue,
            (this.type == 'Customer') ? '1' : '0').then((accounts) => {
            this.allCustomers = accounts;

          });

        } else if (outProps.thirdDrpValue != "0" && outProps.thirdDrpValue != undefined && outProps.thirdDrpValue != "All Activity" && outProps.thirdDrpValue != "") {
          this.allCustomers = this.OriginalAllCustomers.filter(x => x.accounttypeName == outProps.thirdDrpValue);
        } else {
          if (this.type == 'Customer') {
            if (outProps.secondDrpValue == '1') {
              this.allCustomers = this.OriginalAllCustomers.filter(x => x.isCustomer == 874 && x.accountStatus == 14);
            } else if (outProps.secondDrpValue == '2') {
              this.allCustomers = this.OriginalAllCustomers.filter(x => x.isCustomer == 874 && x.accountStatus == 15);
            }
            else {
              if (this.currentuser.hidecustomer == 1 && !this.EnableSearch) {
                this.allCustomers = [];
              } else if (this.currentuser.hidecustomer == 1 && this.EnableSearch) {
                if (this.value != null && this.value != "") {
                  this.allCustomers = this.OriginalAllCustomers;
                }
              }
              else {
                this.loadallaccounts($("#userids").val(), false, 1, this.pageSize, 0);
              }
            }
          }
          else if (this.type == 'Accounts') {
            if (outProps.secondDrpValue == '1') {
              this.allCustomers = this.OriginalAllCustomers.filter(x => x.isCustomer != 874 && x.accountStatus == 14);
             }
            else if (outProps.secondDrpValue == '2') {
              this.allCustomers = this.OriginalAllCustomers.filter(x => x.isCustomer != 874 && x.accountStatus == 15);
}
            else {
             
              this.loadallaccounts($("#userids").val(), false, 1, this.pageSize, 0);
            }
            }
          else {
              this.allCustomers = this.OriginalAllCustomers.filter(x => x.isCustomer == null || x.isCustomer == 875);
            }
        }
      }
    }
  }
  onCellValueChanged($event) { 
   
  }
 
  SelectCustomerType(val) {
    if (val == 'Display contact') {
      this.router.navigateByUrl(`/application/customercontacts`);
    }
    this.customerService.UpdateCustomerType(val, this.currentuser.id).then((user) => {
      localStorage.setItem('CustomerType', user.CustomerTypeView);
     
    });     
  }
  SelectedRows(val) {
  
  }
  SelectCustomer(val) {
    this.loadselectedcontacts(val);
  }

  ReceiveEvent(msg) {
    if (msg == "ExpandSoftwareLicense" || msg == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(msg);
    } else {
      this.IsEditAccount = false;
      this.loadallaccounts($("#userids").val(), false, 1, this.pageSize, 0); 
    }
  }


  ExpandAddEditAccountDialog(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 100%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 86%;padding: 0;top: 10%;left: 10%;max-width: 88%;');
      }, 4)
    } else {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
      }, 4)
    }
  }


  expandaccountView(value) {
  
    if (value === 'Collapse') {
      this.isAccountViewOpen = false;
      if (this.applicationComponent.googlemapshow) {
        this.isGooglemapExpandOpen = true;    
      } else {
        this.acccontactcomp.SixBoxExpand = true;
        this.acccontactcomp.tab = 'QuickView';
      }
    }
    else {
      this.isAccountViewOpen = true;
      if (this.applicationComponent.googlemapshow) {
        this.isGooglemapExpandOpen = false;
      } else {
        this.acccontactcomp.SixBoxExpand = false;
        this.acccontactcomp.tab = 'QuickView';

        let selfObj = this;
        $(setTimeout(function () {
          selfObj.gridOptions.api.forEachNode((node) => {
            if (node.data.id == selfObj.accountid) {
              selfObj.gridOptions.api.selectNode(node, true);
      
              selfObj.gridOptions.rowSelection = 'single';
            }
          });
        }, 500));
      }
    }
  }
  EventReceived(msg) {
    if (msg == "StatusId") { this.isStatusidopen = true }
    else if (msg == "QuickView") { this.CloseAccountDiv(); }
  }
  CloseaccountIds() { this.isStatusidopen = false; }
  CloseAccountDiv() {
    this.isShowAccountInfo = false;
    this.isAccountViewOpen = true;
    if (this.type === "Customer") {
      this.applicationComponent.activateMainButtons('customers');
    } else {
      this.applicationComponent.activateMainButtons('Accounts');
    }    
  }
  isSharingCss() {
    setTimeout(function () {
      $('#isSharingDialog .dialog').attr('style', 'width: 35%;padding-bottom: 0px;border: 1px solid rgb(117, 218, 51);min-height: 63%;overflow: auto;height: 82.5%;padding: 0;top: 10%;left: 63%;');
    }, 4)
  }
  ClearSearch() {
    this.gridApi.setQuickFilter('');
    this.value = "";
    this.gridOptions.quickFilterText = "";
    if (this.currentuser.defaultSearchPage == 1) {
      this.EnableSearch = true;
      this.allCustomers = [];
    } else { this.EnableSearch = false; }
    if (this.type == "Customer" && this.currentuser.hidecustomer == 1) { this.allCustomers = []; }
    this.gridOptions.quickFilterText = "";
     this.createColumnDefs(this.colSelections, this.applicationComponent.searchstring, this.applicationComponent.SearchSelectedItems);
    this.gridApi.redrawRows();

  }
}
