import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import * as moment from 'moment';
import { Accounts } from '../../../models/accounts';
import { Tickets } from '../../../models/tickets';
import { CustomerService } from '../../../services/customer.service';
import { OpprtunitiesService } from '../../../services/opprtunities.service';
import { ApplicationComponent } from '../../application.component';
import { DatePipe } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-tickets-dashboard',
  templateUrl: './tickets-dashboard.component.html',
  styleUrls: ['./tickets-dashboard.component.css']
})
export class TicketsDashboardComponent implements OnInit {
  @Input()
  TaskGroupType: number;
  @Input()
  type: string;
  @Input()
  PageFrom: string;
  public ticketgridOptions: GridOptions;
  public gridOptions: GridOptions;
  public sideBar;
  public columnDefs: any[];
  Ticketslist: Array<Tickets> = [];
  public context;
  currentuser: any;
  allCustomers: Accounts[]; allmasterCustomers: Accounts[];
  allOnHoldCustomers: Accounts[];
  public ZenDeskTicketGridOptions: GridOptions;
  pageSize: string = "1000";
  colSelections: string;
  public ZendeskColumnDefs: any[];
  public ZendeskTrainerColumnDefs: any[];
  OpenTicketsUserlist: Array<Tickets> = [];
  ClosedTicketsUserlist: Array<Tickets> = [];
  DupClosedTicketsUserlist: Array<Tickets> = [];
  @Input()
  ticketType: string = "Open Tickets";
  displayCC = false;
  @Input()
  assignedTo: string = "null";
  dashticketType: string;
  AssignTo = "";
  CCTo = "";
  datePipe: DatePipe = new DatePipe("en-US");
  Filter: string = "My Tickets Only";
  sortOrder: string = "None";
  @Input()
  startDateVal;
  @Input()
  endDateVal;

  OpenTicketsGrid: boolean = false;
  ClosedTicketsGrid: boolean = false;
  public SelectedStatus: number = 1; public SelectedAct: number=732;
  constructor(private router: Router, private customerService: CustomerService, public applicationComponent: ApplicationComponent, private opprtunityservice: OpprtunitiesService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    if (this.type == 'Tickets') {
      this.initializeTicketsAgGrid();
    }
    else if (this.type == 'ZendeskTicketsOpen' || this.type == 'ZendeskTicketsClosed' || this.type == 'ZendeskTicketsTrainer') {
      this.initializeZendeskAgGrid();
    }
    else {
      this.initializeAgGrid();
    }

  }
  initializeTicketsAgGrid() {

    this.ticketgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000,toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.context = this;
    this.createColumnDefs('');
    this.GetAllTicketsByTaskGroup();
  }
  createColumnDefs(columnSelections: string) {
    var searcharray = [];

    let columnSelectionsArray: string[] = [];
    let selfobj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      selfobj.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          selfobj.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "contactname") {
          selfobj.columnDefs.push({
            field: 'contactname', headerName: 'Contact Name', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "accountName") {
          selfobj.columnDefs.push({
            field: 'accountName', headerName: 'Account', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else {
          selfobj.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {


      this.columnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'ticketId', headerName: 'Ticket', hide: true },
        {
          field: 'createdDate', headerName: 'Created Date', hide: false, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'accountName', headerName: 'Account', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'contactname', headerName: 'Contact name', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'email', headerName: 'Email', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'UserName', headerName: 'User Name', hide: true, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        { field: 'userList', headerName: 'Assign Ticket To', hide: false },
        { field: 'subject', headerName: 'Subject', hide: false },
        {
          field: 'priority', headerName: 'Priority', hide: false, cellRenderer: function (params) {
            if (params.data.priority == 1) {
              return 'Yes';

            } else {
              return 'No';
            }
          }
        },
        { field: 'descriptions', headerName: 'Description', hide: false },
        { field: 'CCToName', headerName: 'CC', hide: true },
        {
          field: 'TicketStatus', headerName: 'Status', hide: false
        },
        {
          field: 'action', headerName: 'Action', hide: false,

        }

      ];

    }

  }

  GetAllTicketsByTaskGroup() {
    this.customerService.GetTicketsByTaskGroup(this.currentuser.id, this.TaskGroupType).then((tickets) => {
      this.Ticketslist = tickets;
    });
  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: false, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true,
      toolPanelSuppressPivotMode: true, rowSelection: 'single', enableCellChangeFlash: true, suppressSorting: true, suppressMultiSort: true
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };

    this.createAccColumnDefs('', this.applicationComponent.searchstring, this.applicationComponent.SearchSelectedItems);
    var myDashactivity = localStorage.getItem('myDashactivity');
    if (myDashactivity != null) {
      this.SelectedAct = Number(myDashactivity);
    } else {
      this.SelectedAct = 732;
    }
   
    var myDashactivityStatus = localStorage.getItem('myDashactivityStatus');
    if (myDashactivityStatus != null) {
      this.SelectedStatus = Number(myDashactivityStatus);
    } else {
      this.SelectedStatus = 1;
    }
    this.getAccountsData(this.SelectedAct, this.SelectedStatus);
    this.getCustomerOnHoldData();
    //this.getCustomersOnholdData();
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;



  }
  createAccColumnDefs(columnSelections: string, searchval: string, SearchSelectedItems) {

    let selfObj = this;
    selfObj.columnDefs = [
      //{ 
      //  headerName: '#',
      //  field: '#',
      //  width: 52,
      //  checkboxSelection: true,
      //  suppressSorting: true,
      //  suppressMenu: true,
      //  pinned: true,
      //  hide: false, outerWidth: 8,
      //  headerCheckboxSelection: true,
      //  headerCheckboxSelectionFilteredOnly: true
      //},
      { field: 'id', headerName: 'Id', hide: true },
      //{ field: 'accnumber', headerName: 'Act Number', hide: true, cellStyle: { color: 'red' }},
      //{ field: 'TickerSymbol', headerName: ' Ticket Symbol', hide: true },
      //{ field: 'NoOfEmployees', headerName: 'NoOfEmployees', hide:true },
      //{ field: 'IsContact', headerName: ' Is Contact?', hide: true },
      {

        field: 'accountname', headerName: 'Account Name', hide: false,
      },
      {

        field: 'statusName', headerName: 'Status', hide: false,
      },
      {
        field: 'specialityName', headerName: 'Specialty', hide: false,
      },
      {
        field: 'address1', headerName: 'Address1', hide: false,
      },
      {
        field: 'address2', headerName: 'Address2', hide: false,
      },
      {
        field: 'city', headerName: 'City', hide: false,
      },
      {
        field: 'state', headerName: 'State', hide: false,
      },
      {
        field: 'zip', headerName: 'Zip', hide: false,
      },
      {
        field: 'countryname', headerName: 'Country', hide: false,
      },
      {
        field: 'phone', headerName: 'Phone', hide: true,
      },
      //{
      //  field: 'mapit', headerName: 'Map It', hide: false, width: 80, cellRenderer(params) {
      //    var eDiv = document.createElement('div');
      //    eDiv.innerHTML = '<img src="../../../assets/img/Map it_white.png" style="height:20px;width:50%"/>';
      //    //eDiv.addEventListener('click', function () {
      //    //});
      //    return eDiv;
      //  }   
      //},
      {
        field: 'ehrName', headerName: 'EHR', hide: false,
      },
      {
        field: 'pmName', headerName: 'PM', hide: false,
      },

      {
        field: 'rcmName', headerName: 'RCM', hide: false,
      },
      {
        field: 'owner', headerName: 'Owner', hide: false,
      },

      //{
      //  field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false},


    ];

  }
  TicketcellDoubleClicked(event, ticketType) {
    this.applicationComponent.ExpandSelectedMenu('tickets');
    localStorage.setItem('includecc', (event.data.CCTo == "" || event.data.CCTo == null) ? "false" : "true");
    localStorage.setItem('tickettype', event.data.TicketStatus);
    localStorage.setItem('openTicketId', event.data.ticketId);
    this.router.navigateByUrl(`/application/zendeskhome/${event.data.contactid}/${event.data.ticketId}/Open Tickets`);
  }
  onSelectionChanged(event) {   
      var rowCount = event.api.getSelectedNodes().length;
      if (rowCount > 0) {
        if (event.api.getSelectedNodes()[0].data) {
          localStorage.setItem('RhxAccountVal', event.api.getSelectedNodes()[0].data.id);
          if (this.PageFrom != 'dashboard') {
            let SO = this;
            $(setTimeout(function () {
              SO.applicationComponent.activateSubButtons('empty');
              $(setTimeout(function () {
                SO.router.navigateByUrl(`/application/account/Customer`);
                SO.applicationComponent.ExpandSelectedMenu('customers');
                $(".subButtons").removeClass("activeButton");
                SO.applicationComponent.selectedtabid = 'customers';
              }, 100));
            }, 50));
          }
        }
      }    
  }
  cellDoubleClicked(event) {
    localStorage.setItem('openCustActivityid', event.data.id);
    this.loadCutomergrid();

  }
  cellCustomerOnholdDoubleClicked(event) {
    localStorage.setItem('openCustomerholdid', event.data.id);
    this.loadCutomergrid();
  }

  loadCutomergrid() {
    let SO = this;
    $(setTimeout(function () {
      SO.applicationComponent.activateSubButtons('empty');
      $(setTimeout(function () {
        SO.router.navigateByUrl(`/application/account/Customer`);
        SO.applicationComponent.ExpandSelectedMenu('customers');
        $(".subButtons").removeClass("activeButton");
        SO.applicationComponent.selectedtabid = 'customers';
      }, 100));
    }, 50));
  }
  getAccountsData(val, custsatus) {
    this.opprtunityservice.GetAlllastActivity($("#userids").val(), (this.type == 'CustomerAccounts') ? 'CustomerAccounts' : 'No Activity (60+ days)', '1').then((accounts) => {
      this.allCustomers = accounts.filter(x => x.isCustomer == 874);
     
        this.allmasterCustomers = this.allCustomers;
        if (val == 731) {
          if (custsatus == 1 || custsatus == 2) {
            this.allCustomers = this.allmasterCustomers.filter(x => x.customertypeid == custsatus);
          }
          else {
            this.allCustomers = this.allmasterCustomers;
          }
        }
        else if (val == 732) {
          if (custsatus == 1 || custsatus == 2) {
            this.allCustomers = this.allmasterCustomers.filter(x => x.ownerid == this.currentuser.id && x.customertypeid == custsatus);
          }
          else {
            this.allCustomers = this.allmasterCustomers.filter(x => x.ownerid == this.currentuser.id);
          }
        }
        else if (val == 733) {
          if (custsatus == 1 || custsatus == 2) { this.allCustomers = this.allmasterCustomers.filter(x => x.Salesrep == this.currentuser.id && x.customertypeid == custsatus); }
          else {
            this.allCustomers = this.allmasterCustomers.filter(x => x.Salesrep == this.currentuser.id);
          }
        }
        else if (val == 734) {
          if (custsatus == 1 || custsatus == 2) { this.allCustomers = this.allmasterCustomers.filter(x => x.ownerid != this.currentuser.id && x.customertypeid == custsatus); }
          else {
            this.allCustomers = this.allmasterCustomers.filter(x => x.ownerid != this.currentuser.id);
          }
        } else {
          if (custsatus == 1 || custsatus == 2) {
            this.allCustomers = this.allmasterCustomers.filter(x => x.ownerid != this.currentuser.id && x.customertypeid == custsatus)
          }
          else {
            this.allCustomers = this.allmasterCustomers.filter(x => x.ownerid != this.currentuser.id);
          }
        }
        var openCustActivityid = Number(localStorage.getItem('openCustActivityid'));
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.gridOptions.api.forEachNode((node) => {
            if (node.data.id == openCustActivityid) {
              selfObj.gridOptions.api.selectNode(node, true);
              selfObj.gridOptions.rowSelection = 'single';
            }
          });
        }, 300));
      });
   
  }
  getCustomerOnHoldData() {
    this.allOnHoldCustomers = [];
    this.opprtunityservice.GetAlllastActivity($("#userids").val(), 'CustomeronHold', '1').then((holdaccounts) => {
      this.allOnHoldCustomers = holdaccounts;     
      var openCustomerholdid = Number(localStorage.getItem('openCustomerholdid'));
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.gridOptions.api.forEachNode((node) => {
          if (node.data.id == openCustomerholdid) {
            selfObj.gridOptions.api.selectNode(node, true);
            selfObj.gridOptions.rowSelection = 'single';
          }
        });
      }, 300));
    });
  }
  
  initializeZendeskAgGrid() {

    this.ZenDeskTicketGridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    //let gridconfigId = (this.type == 'ZendeskTicketsOpen') ? 53 : (this.type == 'ZendeskTicketsTrainer') ? 53 : 53;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 53).then((config) => {
      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        if (this.ZenDeskTicketGridOptions && this.ZenDeskTicketGridOptions.api)
          this.ZenDeskTicketGridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = columnSelections;
      //this.GetAllZendeskUers(this.Filter, this.sortOrder);
      this.createZendeskColumnDefs(columnSelections);
      if (this.ticketType == 'Open Tickets') {
        //if (this.type == 'ZendeskTicketsTrainer') {

        //}
        //else {
          this.GetAllZendeskUers(this.Filter, this.sortOrder);
       // }
      } else if (this.ticketType == 'Closed Tickets') {
        this.GetAllZendeskUersClosed(this.Filter, this.sortOrder);
      }
      //this.UpdateSearchColumns(columnSelections)
    });

    this.context = this;
  }

 
  createZendeskColumnDefs(columnSelections: string) {
    var searcharray = [];
    if (this.applicationComponent.searchCriteriadata) {
      var searchaarraystring = this.applicationComponent.searchCriteriadata;
      if (searchaarraystring.includes(' /')) {
        searcharray = searchaarraystring.split(' / ');
      } else {
        searcharray.push(searchaarraystring);
      }
    }
    let columnSelectionsArray: string[] = [];

    let selfobj = this;

    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      selfobj.ZendeskColumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          selfobj.ZendeskColumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "contactname") {
          selfobj.ZendeskColumnDefs.push({
            field: 'contactname', headerName: 'Contact Name', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "accountName") {
          selfobj.ZendeskColumnDefs.push({
            field: 'accountName', headerName: 'Account', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }

        else if (fieldHeaderVisibleWidthArray[0] == "TicketStatus") {
          selfobj.ZendeskColumnDefs.push({
            field: 'TicketStatus', headerName: 'Status', hide: false,

          });
        }

        else {
          selfobj.ZendeskColumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      if (selfobj.type == "ZendeskTicketsOpen") {
        selfobj.ZendeskColumnDefs = [
          {
            headerName: '#',
            field: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: true,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          },
          { field: 'ticketId', headerName: 'Ticket', hide: true },

          {
            field: 'accountName', headerName: 'Account', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'priority', headerName: 'Priority', hide: false, cellRenderer: function (params) {
              if (params.data.priority == 1) {
                return 'Yes';

              } else {
                return 'No';
              }
            }
          },

          {
            field: 'contactname', headerName: 'Contact name', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'createdDate', headerName: 'Created Date', hide: false, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          },
          { field: 'subject', headerName: 'Subject', hide: false },
          { field: 'descriptions', headerName: 'Description', hide: false },

          {
            field: 'email', headerName: 'Email', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },

          { field: 'userList', headerName: 'Assign Ticket To', hide: false },

          {
            field: 'TicketStatus', headerName: 'Status', hide: false,

          },

        ];
      }
      else if (selfobj.type == "ZendeskTicketsClosed") {
        selfobj.ZendeskColumnDefs = [
          {
            headerName: '#',
            field: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: true,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          },
          { field: 'ticketId', headerName: 'Ticket', hide: true },

          {
            field: 'accountName', headerName: 'Account', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },


          {
            field: 'contactname', headerName: 'Contact name', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'createdDate', headerName: 'Created Date', hide: false, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          },
          { field: 'userList', headerName: 'Assign Ticket To', hide: false },

          {
            field: 'priority', headerName: 'Priority', hide: false, cellRenderer: function (params) {
              if (params.data.priority == 1) {
                return 'Yes';

              } else {
                return 'No';
              }
            }
          },
          { field: 'subject', headerName: 'Subject', hide: false },
          { field: 'descriptions', headerName: 'Description', hide: false },

          {
            field: 'email', headerName: 'Email', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },


          {
            field: 'TicketStatus', headerName: 'Status', hide: false,

          },

        ];
      }
      else {
        selfobj.ZendeskColumnDefs = [
          {
            headerName: '#',
            field: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: true,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          },
          { field: 'ticketId', headerName: 'Ticket', hide: true },
          {
            field: 'createdDate', headerName: 'Created Date', hide: false, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          },
          {
            field: 'accountName', headerName: 'Account', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'contactname', headerName: 'Contact name', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'email', headerName: 'Email', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'UserName', headerName: 'User Name', hide: true, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },
          { field: 'userList', headerName: 'Assign Ticket To', hide: false },
          { field: 'subject', headerName: 'Subject', hide: false },
          {
            field: 'priority', headerName: 'Priority', hide: false, cellRenderer: function (params) {
              if (params.data.priority == 1) {
                return 'Yes';

              } else {
                return 'No';
              }
            }
          },
          { field: 'descriptions', headerName: 'Description', hide: false },
          { field: 'CCToName', headerName: 'CC', hide: true },
          {
            field: 'TicketStatus', headerName: 'Status', hide: false,

          },
          {
            field: 'action', headerName: 'Action', hide: false, cellRenderer(params) {
              if (params.data.TicketStatus != '6') {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:12%"/>';
                eDiv.addEventListener('click', function () {
                  //selfobj.deleteClosedTicket(params.data);
                });
                return eDiv;
              }
              else {
                return null;
              }


            }
          }

        ];
      }
    }
    selfobj.ZenDeskTicketGridOptions.getRowStyle = function (params) {
      if (params.data.assignTicketToUser != null && params.data.assignTicketToUser == selfobj.currentuser.id && params.data.readstatus == 0) {
        return { 'font-weight': 'bold' };
      }
    }
  }

  GetAllZendeskUers(filter, sortOrder) {
    if (this.ticketType == "") {
      this.OpenTicketsUserlist = [];
      this.ClosedTicketsUserlist = [];
    }
    else {
      if (this.ticketType == 'Open Tickets') {
        filter = 'My Tickets Only'
      }

      this.customerService.GetTicketsList(this.ticketType, filter, this.currentuser.id, sortOrder, this.displayCC, this.assignedTo).then((tickets) => {

        tickets.forEach(ele => {
          ele.createdDate = this.datePipe.transform(ele.createdDate, "MM-dd-yyyy")
          this.AssignTo = '';
          this.CCTo = '';
          if (ele.assignTicketToTask != null) {
            ele.assignTicketToTask = "TG-" + ele.assignTicketToTask;
            this.AssignTo = ele.assignTicketToTask;
          }
          if (ele.assignTicketToUser != null) {
            ele.assignTicketToUser = "USR-" + ele.assignTicketToUser;
            this.AssignTo = ele.assignTicketToUser
          }

          if (ele.CCToTask != null) {
            ele.CCToTask = "TG-" + ele.CCToTask;
            this.CCTo = ele.CCToTask;
          }
          if (ele.CCTo != null) {
            ele.CCTo = "USR-" + ele.CCTo;
            this.CCTo = ele.CCTo
          }

        });

        if (this.ticketType == "Open Tickets") {
          this.OpenTicketsUserlist = tickets;
          var openMyticketid = Number(localStorage.getItem('openTicketId'));
          let selfObj = this;
          $(setTimeout(function () {
            selfObj.ZenDeskTicketGridOptions.api.forEachNode((node) => {
              if (node.data.ticketId == openMyticketid) {
                selfObj.ZenDeskTicketGridOptions.api.selectNode(node, true);
                selfObj.ZenDeskTicketGridOptions.rowSelection = 'single';
              }
            });
          }, 300));
         
        }


      });
    }

  }
  GetAllZendeskUersClosed(filter, sortOrder) {
    if (this.ticketType == "") {
    }
    else {
      if (this.ticketType == 'Open Tickets') {
        filter = 'My Tickets Only'
      }

      this.customerService.GetTicketsList(this.ticketType, filter, this.currentuser.id, sortOrder, this.displayCC, this.assignedTo).then((tickets) => {
        tickets.forEach(ele => {

          ele.createdDate = this.datePipe.transform(ele.createdDate, "MM-dd-yyyy")
          this.AssignTo = '';
          this.CCTo = '';
          if (ele.assignTicketToTask != null) {
            ele.assignTicketToTask = "TG-" + ele.assignTicketToTask;
            this.AssignTo = ele.assignTicketToTask;
          }
          if (ele.assignTicketToUser != null) {
            ele.assignTicketToUser = "USR-" + ele.assignTicketToUser;
            this.AssignTo = ele.assignTicketToUser
          }

          if (ele.CCToTask != null) {
            ele.CCToTask = "TG-" + ele.CCToTask;
            this.CCTo = ele.CCToTask;
          }
          if (ele.CCTo != null) {
            ele.CCTo = "USR-" + ele.CCTo;
            this.CCTo = ele.CCTo
          }

        });

        this.ClosedTicketsUserlist = tickets;

        //  tickets.forEach(ele => {
        //    if (new Date(ele.createdDate) > new Date(this.startDateVal)) {
        //      alert(ele.createdDate);
        //      this.DupClosedTicketsUserlist.push(ele);
        //    } 

        //  });

        //this.ClosedTicketsUserlist = this.DupClosedTicketsUserlist;

      });
    }

  }

  LoadTickets(startdate, enddate, tickettype) {
    this.startDateVal = startdate;
    this.endDateVal = enddate;
    this.ticketType = tickettype;
    //if (this.ticketType == 'Open Tickets') {
    //  this.GetAllZendeskUers(this.Filter, this.sortOrder);
    //} else if (this.ticketType == 'Closed Tickets') {
    //  this.GetAllZendeskUersClosed(this.Filter, this.sortOrder);
    //}

    this.initializeZendeskAgGrid();
  }


  GetTicketsByGroup() {
    //this.customerService.GetTicketsList(this.ticketType, filter, this.currentuser.id, sortOrder, this.displayCC, this.assignedTo).then((tickets) => {

    //});
  }
}
