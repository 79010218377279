<div class="container">
  <h4>FORGOT YOUR PASSWORD?</h4> <br/>

  <div class="col-xs-12 removeCompletePadding"> 
  <label>Username/Email <span style="color:red">*</span></label>   
  </div><br />
  <div class="col-xs-12 removeCompletePadding">
       <input type="text" id="email" class="form-control" placeholder="Enter your Username/Email" />
    <span>Enter username or email address</span>
  </div>
  <div class="col-xs-12 removeCompletePadding">
    <re-captcha (resolved)="resolved($event)" siteKey="6Lc19FEUAAAAAAA8ahlt65Yez6ylBjNSp1FzF8OS"></re-captcha>
  </div>
  <div id="pwdsent"class="col-xs-12 removeCompletePadding" style="display:none">
  <input type="button" value="E-mail new password" (click)="passwordsent()"/>
  </div>
  </div>
