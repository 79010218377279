import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef, ViewEncapsulation, Pipe, Optional, PipeTransform,Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsService } from './../../services/accounts.service';
import { Calllist } from '../../models/calllist';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { CustomerService } from './../../services/customer.service';
import { IMyDpOptions } from 'mydatepicker';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { Column } from 'ag-grid';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { DatePipe } from '@angular/common';
import { ApplicationComponent } from './../application.component';
import { EditdeleteComponent } from '../editdelete/editdelete.component';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import * as moment from 'moment';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { AuditLogModel } from '../../models/auditlog';
import { Location } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-viewcalllist',
  templateUrl: './viewcalllist.component.html',
  styleUrls: ['./viewcalllist.component.css']
})
export class ViewcalllistComponent implements OnInit {
  @Input()
  rowData: Calllist[];
  @Input()
  userid: number;
  @Input()
  public gridOptions: GridOptions;
  @Input()
  public columnDefs: any[];
  @Input() currentuser: any;
  public context;
  aggridheight: string;
  dropdownList = [];
  finalitems = "";
  selectedItems = [];
  dropdownSetting = {};
  pageSize = "1000";
  typeFilterCallList: Calllist[];
  colSelections: string;
  datePipe: DatePipe = new DatePipe("en-US");
  deletecalllistDialog = false; calllistid: number;
  StatusTypeList: Dropdowntype[] = [];
  customertypeOfList: Dropdowntype[] = [];
  callListStatus = "Open";
  public frameworkComponents;
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear()-1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear()-1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");
  public sideBar;
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  calanderonoff: boolean = false;
  DeleteConfirmationPopup: boolean = false;
  private gridApi; 
  DisplayrowsList: Dropdowntype[] = [];
  CallListDialog: boolean = false;
  constructor(private _location: Location, public applicationComponent: ApplicationComponent, private userservice: UserService, private route: ActivatedRoute, private opprtunityservice: OpprtunitiesService, private router: Router, private viewContainerRef: ViewContainerRef, private accountsService: AccountsService, private customerService: CustomerService, private toastr: ToastrService)
  {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    }
  }
  ngAfterViewInit(): void {
    this.ResizeTable();
  
  }
  ngOnInit() {     
    this.initializeAgGrid();
    $(document).ready(function () {
      $("#BanooBttonNew").click(function () {
      });
    });
    var obj: AuditLogModel = { id: 0, type: 'Call List', action: 'Call List Viewed ', AccountOrContactId: 0, AccountOrContact: '', documentid:0 , userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Call List Viewed ', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);

    this.GetAllaccesusersListbyuser();
    if (this.applicationComponent.selectedusers != '' && this.applicationComponent.selectedusers != null && this.applicationComponent.selectedusers != undefined) {
      this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
        Owner.forEach(Owner => {
        });
        this.selectedItems = Owner;
      });
      
    }
    else { 

      this.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
      ];
      this.finalitems += this.currentuser.id + ',';
      //$("#userids").val(this.currentuser.id);
    }
    this.LoadAllCalllist($("#userids").val(), 'Open');
    if (document.getElementById('subTab') != undefined && document.getElementById('subTab') != null)
      document.getElementById('subTab').style.display = 'block';    
    //this.applicationComponent.showcampaignsTabs = false;
   
    this.customerService.GetSpecialityList("Status",this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.StatusTypeList = accounttype;
    });

    this.customerService.GetSpecialityList("typeOfList", this.currentuser.id).then((typeOfList) => {     
      this.customertypeOfList = typeOfList;
    });

    $(".subButtons").removeClass("activeButton");
    $("#viewcalllist_button").addClass("activeButton");
    $("#Cviewcalllist_button").addClass("activeButton");
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 500));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 500));
    }
  
  }

  GetAllaccesusersListbyuser() {
     
    this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
      this.DisplayrowsList = Rows;
    });
  }
  userListOutParamsBinding(outProps) {
    this.finalitems = outProps.finalitems;
    this.selectedItems = outProps.selectedItems;
  }
  onItemSelect(item: any) {
    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.LoadAllCalllist($("#userids").val(), $('#SelectedStatus').val());
  }
  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.LoadAllCalllist($("#userids").val(), $('#SelectedStatus').val());
    } else {
      this.rowData = [];
      $("#userids").val('');
    }
  }
  onSelectAll(items: any) {
    var ids = "";
    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.LoadAllCalllist($("#userids").val(), $('#SelectedStatus').val());
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.rowData = [];
    this.gridOptions.api.hideOverlay();
    $("#userids").val('');
    this.applicationComponent.selectedusers = null;
  }
  LoadAllCalllist(userids, selectedstatus) {
    this.applicationComponent.selectedusers = userids;
    this.accountsService.GetAllCalllist(userids, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, selectedstatus).then((calllist) => {

      if (calllist.length > 0) {
      calllist.forEach(calllist => {
        calllist.created = this.datePipe.transform(calllist.created, "MM-dd-yyyy HH:mm a");
        calllist.lastaccesed = this.datePipe.transform(calllist.lastaccesed, "MM-dd-yyyy HH:mm a");        
      });
      let filteredcalllist: any = calllist;
      
        if (selectedstatus == "Open" || selectedstatus == "Open Call Lists")
          filteredcalllist = calllist.filter((cust) => (cust.status == 'Open'));
        else if (selectedstatus == "Completed" || selectedstatus =="Completed Call Lists")
          filteredcalllist = calllist.filter((cust) => (cust.status == 'Completed'));
        else if (selectedstatus == "Deleted" || selectedstatus == "Deleted Call Lists")
          filteredcalllist = calllist.filter((cust) => (cust.status == 'Deleted'));
        else if (selectedstatus == "Show All" || selectedstatus == "All Call Lists")
          filteredcalllist = calllist.filter((cust) => ((cust.status == 'Open' || cust.status == 'Completed' || cust.status == 'Deleted')));
        this.rowData = filteredcalllist;
        this.typeFilterCallList = filteredcalllist;
        if (this.rowData.length > 0 && this.rowData[0].islimitedSearch == true) {
          if (this.rowData[0].SearchVal) {
            let sO = this;
            $(setTimeout(function () {
              sO.applicationComponent.searchCriteria = sO.rowData[0].SearchVal;
              sO.applicationComponent.Searchdiv = true;
              sO.applicationComponent.searchstring = sO.rowData[0].SearchVal.slice(0, -3);
              sO.applicationComponent.searchCriteriadata = sO.applicationComponent.searchstring.split(' / ');
            }, 1000));
          }
          this.applicationComponent.term = "true";//passing dummy data toshow delete button in search box
          this.applicationComponent.limitedSearch = true;
          $('#searchContact').val('');
        }
        else {
          this.applicationComponent.term = "";
          //$('#searchContact').val('');
          this.applicationComponent.limitedSearch = false;
          this.applicationComponent.searchstring = "";
          this.applicationComponent.searchCriteriadata = [];
        }
        this.createColumnDefs(this.colSelections);
      } else {
        this.applicationComponent.searchstring = "No Match";
        this.rowData = null;
        //this.applicationComponent.searchCriteriadata.push("");
      }
      
    });
   
  }
  calanderclick(val) {
     
    this.calanderonoff = val;
    this.LoadAllCalllist($("#userids").val(), $('#SelectedStatus').val());
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }

  }
  rowDoubleClickAgGrid(rowEvent) {
    this.applicationComponent.Calllistselectedtype = (rowEvent.data.typeOfList == "Contacts/Accounts" || rowEvent.data.typeOfList == "Customer" ? "Customer" : "Contact");
    var result = rowEvent.data;
    var obj: AuditLogModel = { id: 0, type: 'Call List', action: 'Call List Viewed - ' + result.calllistname, AccountOrContactId: 0, AccountOrContact: result.typeOfList, documentid: result.id, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Call List Viewed - ' + result.calllistname, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
    this.applicationComponent.calllistid = rowEvent.data.id;
    this.applicationComponent.selectedbuttonText = 'Create Call List';  
    this.applicationComponent.activateMainButtons('Create Call List');
    this.applicationComponent.crmDialog = false;
    this.router.navigateByUrl(`/application/calllist/` + 13146 + '/' + rowEvent.data.id);
    
  }


  //onSelectionChanged(event) {
  //      var rowCount = event.api.getSelectedNodes().length;
  //  if (rowCount > 0) {
  //    if (event.api.getSelectedNodes()[0].data) {
  //      var result = event.api.getSelectedNodes()[0].data;
  //      this.applicationComponent.Calllistselectedtype = (result.typeOfList == "Contacts/Accounts" || result.typeOfList == "Customer" ? "Customer" : "Contact");
  //      this.applicationComponent.ShowConfigurationDialog = false;
  //      this.applicationComponent.showSearchFieldsDialog = false;

  //      var obj: AuditLogModel = { id: 0, type: 'Call List', action: 'Call List Viewed - ' + result.calllistname, AccountOrContactId: 0, AccountOrContact: result.typeOfList, documentid: result.id, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Call List Viewed - ' + result.calllistname, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
  //      this.applicationComponent.InsertAuditHistory(obj);

  //      this.router.navigateByUrl(`/application/calllist/` + result.typeOfList + '/' + 0 + '/' + result.id);
  //    }
  //      }
  //}

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'multiple'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',
    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id,20).then((config) => {
      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.gridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = columnSelections;
      this.createColumnDefs(columnSelections);
      this.startDateVal = (config != null && config.startdate != null) ? config.startdate : (new Date().getFullYear() - 1) + '-01-01';
      this.startDatePlaceHolder = (config != null && config.startdate != null) ? 'Start:' + moment(config.startdate).format("MM-DD-YYYY") : 'Start: 01-01-' + (new Date().getFullYear() - 1).toString();
      this.endDateVal = (config != null && config.enddate != null) ? config.enddate : new Date().getFullYear() + '-12-31';
      this.endDatePlaceHolder = (config != null && config.enddate != null) ? 'End:' + moment(config.enddate).format("MM-DD-YYYY") : 'End: 12-31-' + (new Date().getFullYear() - 1).toString(); 
      this.UpdateSearchColumns(columnSelections)
    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      editdelete: EditdeleteComponent
    };


  }

  UpdateSearchColumns(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[] = columnSelections.split(',');

    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');
      if ((fields[2] == 'true' && fields[1] != '#') && (fields[2] == 'true' && fields[0] != 'notes') && (fields[2] == 'true' && fields[0] != 'actions')) {
        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }
    //this.applicationComponent.ShowSearchTabs('True');
    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];
    if (retrievedObject != null) {

      let obj: any[] = JSON.parse(retrievedObject);
      obj.forEach(element => {
        if (element.subtab == 'Calllist') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        }

        //if (this.applicationComponent.SearchSelectedItemsLengthChanged == true) {
        //  this.applicationComponent.showSearchTabsSubTabs = false;
        //}
        //else {
        //  this.applicationComponent.showSearchTabsSubTabs = true;
        //}
      });
    }
    if (tempTableColumns && tempTableColumns.length > 0 ) {
      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })

    }
    else {
      this.accountsService.GetDefaultColumns('Calllist', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.applicationComponent.SearchSelectedItems = [
            { "id": 'calllistname', "itemName": 'Call List Name' }
          ];
        }
      });
      //this.applicationComponent.showSearchTabsSubTabs = false;
    }
  }

  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    var searcharray = [];
    if (this.applicationComponent.searchCriteriadata) {
      var searchaarraystring = this.applicationComponent.searchCriteriadata;
      if (searchaarraystring.includes(' /')) {
        searcharray = searchaarraystring.split(' / ');
      } else {
        searcharray.push(searchaarraystring);
      }
    }
    let currentuserid = this.currentuser.id;
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } if (fieldHeaderVisibleWidthArray[0] == "actions") {
          this.columnDefs.push({
            field: 'actions', headerName: 'Actions', hide: this.callListStatus == 'Deleted Call Lists' ? true : false, cellRenderer(params) {
              if (params.data.userid == currentuserid) {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
                eDiv.addEventListener('click', function () {
                  selfObj.delete(params.data.id);
                });
                return eDiv;
              }
              //var eDiv = document.createElement('div');
              //eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
              //eDiv.addEventListener('click', function () {
              //  //selfobj.campaign = params.data;
              //  //selfobj.DeleteCampaignConfirmation = true;
              //  //selfobj.DeleteExpenseCss('DeleteCampaignConfirmation');
              //});
              //return eDiv;
            },
             width: parseInt(fieldHeaderVisibleWidthArray[3]),
            pinned: 'right'
          });
        }
        
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  //var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim() || x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            } 
          });
        }
      }
    }else {

      this.columnDefs = [

        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        {
          field: 'created', headerName: 'Date Created', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          } },
        {
          field: 'typeOfList', headerName: 'Type Of List', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          } },
        {
          field: 'calllistname', headerName: 'Call List Name', hide: false,
         cellRenderer: function (params) {
          if (params.data.SearchVal != null && params.value != null) {
            let x = params.value.split(" ");
            var st = "";
            for (let i = 0; i < x.length; i++) {
              var search = params.data.SearchVal;
              if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        }
        },
        {
          field: 'total', headerName: '# Contacts', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }},
        {
          field: 'completedcalltotal', headerName: '# Call Completed', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          } },
        {
          field: 'deletedcontactcalltotal', headerName: '# Deleted Contacts', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          } },
        { field: 'status', headerName: 'Status', hide: false, cellRenderer: function (params) {
          if (params.data.SearchVal != null && params.value != null) {
            let x = params.value.split(" ");
            var st = "";
            for (let i = 0; i < x.length; i++) {
              var search = params.data.SearchVal;
              if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        } ,cellStyle: function (params) { if (params.value == 'Open') { return { color: 'red' }; } } },
        {
          field: 'Owner', headerName: 'Created By', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          } },
        {
          field: 'lastaccesed', headerName: 'Date Last Accessed', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          } },
        {
          field: 'LastUserAccessed', headerName: 'User List Accessed', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          } },
        {
          field: 'Assignedto', headerName: 'Assigned To', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.includes(search) || searcharray.includes(x[i].trim() || x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          } },
        {
          field: 'actions', headerName: 'Actions', hide: this.callListStatus == 'Deleted Call Lists' ? true : false, cellRenderer(params) {
            if (params.data.userid == currentuserid) {
              if ($('#SelectedStatus').val() != 'Deleted') {
                var eDiv = document.createElement('div');
            eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
                eDiv.addEventListener('click', function () {
                  selfObj.delete(params.data.id);
                });
                return eDiv;
              }
              
            } 
          },
        pinned: 'right' }
      ];
    }

    $(".subButtons").removeClass("activeButton");
    $("#viewcalllist_button").addClass("activeButton"); 
    $("#Cviewcalllist_button").addClass("activeButton"); 
  }
  //onAgGridReady(event) {
  //  let selfObj = this;
  //  $(setTimeout(function () {
  //    selfObj.autoSizeAll();
  //    $(setTimeout(function () {
  //      selfObj.autoSizeAll();
  //    }, 400));
  //  }, 400));
  //}
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  autoSizeAll() {
    //var allColumnIds = [];
    //if (this.gridOptions && this.gridOptions.columnApi) {
    //  var allCols = this.gridOptions.columnApi.getAllColumns();
    //  if (allCols != null) {
    //    allCols.forEach(function (column) {
    //      allColumnIds.push(column.getColId());
    //    });
    //  }
    //  this.gridOptions.columnApi.autoSizeColumns(allColumnIds, "autosizeColumns");
    //}
  }
  dateChange(event) {
    this.AssignStartAndEndDate(event);
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
    this.LoadAllCalllist($("#userids").val(), $('#SelectedStatus').val());
    this.saveConfiguration();
  }
  /**
   *  Param event should contain 'event.formatted' to differentiate start and end date.
   *  and  event.date Should be in Json format {year:2, month:2,year:1990 }
   */
  AssignStartAndEndDate(event) {

    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  txtPageSizeChanged(pageSize) {
    this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    this.saveConfiguration();
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
    this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 20;
    config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;
    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);
  
  }

  delete(id) {
    this.calllistid = id;
    this.userservice.getprivilagebyuser(this.currentuser.id, 29).then((privilages) => {
      if (privilages == null) {

        this.toastr.warning("You Do Not have Access to View Call list – Please check with CRM Administrator if Access is Required.", "Access Denied");
      }
      else {
        this.applicationComponent.loaddialog('DeleteCallList', 'My Call List', 'Delete this My Call list Selected?', false, false, true, true, id);

      }
    });
  }

  //deletecalllist(calllistid) {
  //  this.customerService.Deletecalllist(calllistid).then((user) => {
  //    this.LoadAllCalllist($("#userids").val(), $('#SelectedStatus').val());
  //  });

  //  this.deletecalllistDialog = !this.deletecalllistDialog;
  //}
  
  StatusChange(val) {
    this.LoadAllCalllist($("#userids").val(), val);
  }
  customertypeChange(val)
  {
    let filterresult;
    if (val == 'Customers') {
      filterresult = this.typeFilterCallList.filter((cust) => (cust.typeOfList == 'Customer'));
    }
    else if (val == 'Contacts/Accounts') {
      filterresult = this.typeFilterCallList.filter((cust) => (cust.typeOfList == 'Contacts/Accounts'));
    }
    else {
      filterresult = this.typeFilterCallList;
    }
    this.rowData = filterresult;
  }
  ResizeTable() {
    this.aggridheight = ((((window.innerHeight) - (($('#appComponentContent').height()) + ($('#actiondivid').height()))) - 50)) + 'px';
  }

  UserlistDataReceived(outProps: OutputPropsUserList) {
    this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
    this.startDateVal = outProps.startDateVal;
    this.endDateVal = outProps.endDateVal;
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.callListStatus = outProps.secondDrpValue == undefined ? 'Open' : outProps.secondDrpValue;
    if (outProps.finalitems != "") {
      $("#userids").val(outProps.finalitems);
      if (!outProps.pageSizeChanged) {
        this.LoadAllCalllist($("#userids").val(), this.callListStatus);
      }
    } else {
      this.rowData = [];
      $("#userids").val('');
    }
  }
  close() {
    this._location.back();
  }
}
