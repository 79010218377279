export class Statistics {
    public id: number;
    public type: string;
    public scans: string;
    public eRx: string;
    public logins: string;
    public kiosk: string;
    public elab: string;
    public PM: string;
    public progressnotes: string;
    public referralletters: string;
    public midlevels: string;
    public users: string;
    public commandCTR: string;
    public phoneapp: string;
    public Drs: string;
}
