import { Privilages } from './privilages';
import { DateTime } from 'luxon';

export class Users {
  public id: number;
  public firstname: string;
  public lastname: string;
  public cellphone: string;
  public userrole: string;
  public email: string;
  public password: string;
  public confirmpassword: string;
  public selectedItems: any;
  public TaskGroupselectedItems: any;
  public AccountGroupselectedItems: any;
  public useraccessto: any;
  public userid: number;
  public systemadministrator: number;
  public customertypeaccess: number;
  public customerAccount: number;
  public customerStatus: number;
  public TaskGroupid?: string;
  public SelectedTaskGroupids: any;
  public Quotaid: number;
  public privilageslist: Privilages[];
  public permissionlevelid: number;
  public dealapprovalid: number;
  public userstatusid: number;
  public EmailSetUp: string;
  public isSendGrid: boolean;
  public isSMTP: boolean;
  public Zendseskpermissionid?: number;
  public ZendeskPermissionName: string;
  public userpermissionlevelid?: number;
  public salesxaccess: string;
  public calanderView: number;
  public QuotaName: string;
  public PermissionLevelName: string;
  public TaskGroupName: string;
  public ConvertCustomer: string;
  public SystemAdministratorame: string;
  public username: string;
  public RoleName: string;
  public dealApproval: string;
  public UserStatus: string;
  public userlist: string;
  public timezoneid: number;
  public releaseNoteAddEdit: number;
  public ticketTaskGroupAccessID: any;
  public AccountGroupAccessID: any;
  public mysalesxcrmuseraccess: any;
  public ticketTaskGroupAccess: string;
  public timezone: string;
  public CreatedDate: DateTime;
  public middleName: string;
  public title: string;
  public suffix: string;
  public taskGroupAccessForTickets: any;
  public SalesXCRMacesselectedItems: any;
  public titleName: string;
  public suffixName: string;
  public userAccessNames: string;
  public ProfilePicture: string;
  public Phototype: string;
  public SoftwareLicenseAdmin: number;
  public StatisticsAddEdit: number;
  public lastCustomerActivity: number;
  public lastSearchby: number;
  public defaultSearchPage: number;
  public isCustomer: number;
  public customerSince: number;
  public hidecustomer: number;
  public SystemType: number;
  public HL7interface: number;
  public CustomerRCM: number;
  public OnDemand: number;
  public ApptReminders: number;
  public eLab: number;
  public MIPS: number;
}


