import { Component, OnInit } from '@angular/core';
import { Users } from 'src/app/models/users';
import { UserService } from 'src/app/services/user.service';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { ApplicationComponent } from '../application.component';
import { CustomerService } from '../../services/customer.service';
import { AuditLogModel } from '../../models/auditlog';
import { Dropdowntype } from '../../models/dropdowntype';
declare var $: any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public currentuser: any;
  user: Users = new Users();
  public selectedItems: any;
  public TaskGroupAccess: string = "";
  urlImage = ''; documentAccessName = "";
  mySalesXAccessName = "";
  permissionlevellist: Dropdowntype[] = [];
  userpermissionlevellist: Dropdowntype[] = [];
  constructor(private userservice: UserService, private applicationComponent: ApplicationComponent, private customerService: CustomerService,) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.UserProfidata();
    this.GetDocumentAccess();
    var obj: AuditLogModel = { id: 0, type: 'Profile', action: 'Profile Viewed', AccountOrContactId: 0, AccountOrContact: "", documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Profile Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);

  }
  GetDocumentAccess() {

    let permissionlevel = this.permissionlevellist.filter(f => f.id == this.user.permissionlevelid);
    this.documentAccessName = permissionlevel[0].itemName;

    let userpermissionlevel = this.userpermissionlevellist.filter(f => f.id == this.user.userpermissionlevelid);
    this.mySalesXAccessName = userpermissionlevel[0].itemName;
  }
  UserProfidata() {
    this.userservice.GetUserByuserId(this.currentuser.id).then(result => {
      this.user = result;
      this.urlImage = this.user.ProfilePicture;

      if (this.user.EmailSetUp == "SendGrid") {
        this.user.isSendGrid = true;
        this.user.isSMTP = false;
      } else {
        this.user.isSendGrid = false;
        this.user.isSMTP = true;
      }

    });
  }
  UserlistDataReceived(outProps: OutputPropsUserList) { }
  close() {
    this.applicationComponent.activateMainButtons('dashboards');
    this.applicationComponent.ShowConfigurationDialog = false;
   // this.applicationComponent.showSearchFieldsDialog = false;
    
  }

  addFiles(event) {

    let files = event.srcElement.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      this.addChargeNew(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      this.urlImage = reader.result.toString();
    }
  }

  addChargeNew(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.user.ProfilePicture = reader.result.toString();
      selfObj.user.Phototype = file.type;
      selfObj.urlImage = reader.result.toString();
      selfObj.customerService.uploadUserProfilePhoto(selfObj.user).then((user) => {
        selfObj.currentuser.ProfilePicture = selfObj.user.ProfilePicture;
        localStorage.setItem('currentUser', JSON.stringify(selfObj.currentuser));
      }) ;
    }, 500));
  }
}
