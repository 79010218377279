<input type="text" id="emailIds" style="display:none;" />
<input type="text" id="tasklistselectedtyle" style="display:none;" />
<div class="container" style="margin-left: 0%;height:99%;padding:0">
  <div class="col-xs-12" style="padding:0">
    <div class="col-xs-11" style="padding:0;">
    </div>
    <div (click)="close();" class="col-xs-1" style="padding:0;float: right;">     
        <img src="../../../assets/img/Black x.png" style="padding:0;float: right;height: 24px " />   
    </div>
  </div>
  <div class="col-xs-12" style="padding: 0%;height:94%;">

    <div *ngIf="Qtype=='Contacts' && isQuickViewOpen" id="agCallListContacts" class="col-xs-4" style="height:100%;padding-left:0;width:33%">
      <div style="height:4%">       
        <b style=" font-size: 10pt; padding-left: 10px;"> QUICKVIEW | CONTACTS <!--( {{itemCount}} )--></b>
        <span (click)="expandQuickView('Expand')"><img src="../../../assets/img/Open Quickview Grid Panel.png" style="height: 23px; float: right; transform: rotate(180deg);" /></span>
      </div>
      <div class="col-xs-12" style="padding:0; height:100%">
        <ag-grid-angular style="width: 100%;height:95%" id="gridheight" class="ag-theme-balham"
                         [gridOptions]="gridOptions"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="applicationComponent.defaultGridColDef"
                         [rowData]="allContacts"
                         [context]="context"
                         [sideBar]="sideBar"
                         [frameworkComponents]="frameworkComponentsQuickView"
                         [suppressDragLeaveHidesColumns]="true"
                         (columnVisible)="columnVisible($event)"
                         (dragStopped)="dragStopped($event)"
                         [suppressRowClickSelection]="true"
                         (columnResized)="columnResized($event)"
                         (rowSelected)="rowClickAgGrid($event)"
                         (gridReady)="onAgGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
    <div [ngClass]="'col-xs-4'" [style.width.%]="'33'" style="height:100%;padding:0;" *ngIf="!isQuickViewOpen">
      <div class="col-xs-12" style="padding:0;height:4%">
        <span (click)="expandQuickView('Collapse')"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
      </div>
      <div class="col-xs-12 scroll-c" style="padding:0;height:100%" *ngIf="this.Qtype == 'Accounts'">
        <app-new-notes style="height:100%"
                       [selectedTabName]="'1'"
                       [conid]="contactid"
                       [typ]="'Account'"
                       [ViewType]="'Block'"
                       [isModal]="false">
        </app-new-notes>
      </div>
      <div class="col-xs-12 scroll-c" style="padding:0;height:100%" *ngIf="this.Qtype == 'Contacts'">
        <app-new-notes style="height:100%"
                       [selectedTabName]="'1'"
                       [conid]="contactid"
                       [typ]="'Contact'"
                       [ViewType]="'Block'"
                       [isModal]="false">
        </app-new-notes>
      </div>
    </div>

    <div *ngIf="Qtype =='Accounts' && this.contactid>0 && isQuickViewOpen" class="col-xs-4" style="height:100%;padding-left:0;width:33%">

      <div style="height:4%">
       
        <b style=" font-size: 10pt; padding-left: 10px;">QUICKVIEW | ACCOUNTS </b>
        <span (click)="expandQuickView('Collapse')"><img src="../../../assets/img/Open Quickview Grid Panel.png" style="height: 23px; float: right; transform: rotate(180deg);" /></span>

      </div>
      <div class="col-xs-12" style="padding:0; height:100%">
        <ag-grid-angular style="width: 100%;height:95%" id="gridheight" class="ag-theme-balham"
                         [gridOptions]="gridOptions"
                         [columnDefs]="AccountcolumnDefs"
                         [defaultColDef]="applicationComponent.defaultGridColDef"
                         [rowData]="allAccounts"
                         [context]="context"
                         [sideBar]="sideBar"
                         (rowSelected)="rowClickAccountAgGrid($event)"
                         (gridReady)="onAgAccountGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
    <div *ngIf="Qtype=='Contacts' && this.contactid>0" [ngClass]="'col-xs-8'" [style.width.%]="'67'" style="padding:0;padding-right: 0px;height:100%">
      <contactsdashboard [contactids]="this.calllist.contactids" [type]="this.type" [tab]="'QuickView'" (sendMsgEvent)="onAccountMessageReceived($event)"></contactsdashboard>
    </div>
    <div *ngIf="Qtype=='Accounts' && this.contactid>0" [ngClass]="'col-xs-8'" [style.width.%]="'67'" style="padding:0;padding-right: 0px;height:100%">
     
      <account-contact [type]="'Account'" [contactId]="'show'+this.contactid" [tab]="'QuickView'" (sendMsgEvent)="onAccountMessageReceived($event)"></account-contact>
    </div>
  </div>
  <app-dialog [(visible)]="showDialog">
    <convertcontactto-lead [id]="contactid" [type]="leadstatus" [selectorTag]="true"></convertcontactto-lead>
  </app-dialog>

  <app-dialog [(visible)]="showCancelDialog" id="cancelDialog">
    <app-delete-popup [status]="0" [title]="'QuickView'" [description]="'Exit QuickView?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="CloseQuicKViewOK($event)">
    </app-delete-popup>
  
  </app-dialog>
</div>
