
<div class="col-xs-12" style="padding: 0;height:99%;border:2px solid #d3d3d3;" >
  <div class="" style="padding: 1%; background-color:rgb(40,62,74); height: 5%">
    <div class="col-xs-12" style="padding:0;">
      <div class="col-xs-5" style="padding:0;"><b style="font-size:15px;color:#badb98">User:&nbsp;<span>{{emailAlertUsername}}</span>&nbsp;<span>{{emailAlertUsername2}}</span></b></div>
      <div class="col-xs-6" style="text-align:left;color:white">USER ALERTS - SEND EMAIL</div> 
    </div>

  </div>
  <ag-grid-angular style="height: 86%;" class="ag-theme-balham" 
                   [gridOptions]="gridOptions"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="allAccessUser"
                    [sideBar]="sideBar"
                   (gridReady)="onAgGridReady($event)">
  </ag-grid-angular>
  <div  class="col-xs-12" style="height:6%;padding:0"> 
    <div class="col-xs-4" style="padding: 0%;float:right;height:100%">
      <div class="col-xs-6" style="height:100%"><button style="background:#d3d3d3;margin-top:5px;height:100%" class="btn btn-long provider saveButtonCSS" (click)="AlertCancel()">Cancel </button></div>
      <div class="col-xs-6" style="height:100%;float:right">
      <button (click)="SaveAutomatedEmailsData()" style="background:#d3d3d3;margin-top:5px;height:100%" class="btn btn-long provider saveButtonCSS"  id="SaveUserAlerts">Save </button></div>
    </div>
  </div>
  <div *ngIf="closedAlertsEventListener()"></div>
</div>
