import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ContactUs } from 'src/app/models/contact-us';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ApplicationComponent } from '../application.component';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  @Input()
  From: string;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  contactus: ContactUs = new ContactUs();
  currentuser: any;
  contactPhone: any;
  contentBody: any;
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  constructor(public applicationComponent: ApplicationComponent,private userservice: UserService, private toastr: ToastrService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.contactus.username = this.currentuser.firstname + " " + this.currentuser.lastname;
    this.contactus.email = this.currentuser.email;
    this.contactPhone = this.currentuser.cellphone;
  }
  ReleaseNotesShow() {
    //this.releaseNotesDiv = true;
    //this.aboutus = false;
  }
  ShowContactus() {
    //this.ContactUs = true;
    //this.aboutus = false;

  }
  close() { }

  CancelContactUs() {
    var url = window.location.href;
    this.applicationComponent.displayheader(url);
    this.applicationComponent.ShowConfigurationDialog = false;
    /*this.sendMsgEvent.emit("ContactUs");*/
  }
  Send() {
    this.contactus.phone = this.contactPhone;
    this.contactus.body = this.contentBody;
    this.contactus.userId = this.currentuser.id;
    this.userservice.sendEmail(this.contactus).then(result => {
      if (this.contactus.phone == undefined || this.contactus.phone == '' || this.contactus.body == undefined || this.contactus.body == '') {
        return false;
      } else {
        this.toastr.success("Email sent successfully.", "Email Sent", { timeOut: 600 });
      }
      
    });
    this.contactPhone = '';
    this.contentBody = '';
  }
 
}
