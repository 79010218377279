import { Component, OnInit, NgZone, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { HubConnection } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { ReceiveMessage } from '../../models/ReceiveMessage';
import { MatDialog } from '@angular/material/dialog';
import { SignalrClientService } from '../../services/signalr-client.service';
import { ChatService } from '../../services/chat.service';
import { Message } from '../../models/message';
import { UserService } from '../../services/user.service';
import { Auditlog } from '../../models/auditlog';
import { CustomerService } from 'src/app/services/customer.service';
import { ChatMessage } from '../../models/chatMessage';
import { AppSettings } from '../../app.settings';
import { MessagingService } from '../../services/messaging.service';
import { ApplicationComponent } from '../application.component';
import { GroupChat } from '../../models/GroupChat';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
 
})
export class ChatComponent implements OnInit {
  message = new Message();
  chatContainer: ReceiveMessage[] = [];
  loggedinUserlist: Array<Auditlog> = [];
  globalloggedinUserlist: Array<Auditlog> = [];
  FirstloggedinUserlist: Array<Auditlog> = [];
  editloggedinUserlist: Array<Auditlog> = [];
  grpchat: GroupChat = new GroupChat();
  subscription: Subscription;
  msgloggedinUserlist: Array<Auditlog> = []; msgMsg = "";
  txtMessage: string = ''; public selectedusername: any = [];
  uniqueID: string = new Date().getTime().toString();
  messages = new Array<Message>();
  previousmessages = new Array<ChatMessage>();
  joined: boolean = false; selectedItems = [];
  public AddGroup = false; public groupName = ''; public userName = ''; public chatId = 0;public deletechatids = '';
 public Enableedit = false; public showlabel: false;
  public messageToSend = ''; public selecteduserids: any = [];
  public term = ''; fromchatusrId: number = 0;
  public box3header: string = "";
  public selectedchatusers = " "; public chatuserids = '';
  isopenchatMsg: boolean = false;
  isshowReply: boolean = true;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  toUser = ""; touserId: string = ""; grpuserids: string = ""; selecteduserName: string = ""; currentuser: any; messageDateString: string = "";
  public groupId = 0
  private hubConnection: HubConnection; dropdownList = []; dropdownSetting = {};
  private _pipelinerChatHubProxy: SignalR.Hub.Proxy; private _pipelinerChatHubConnection: SignalR.Hub.Connection;
  public box3: boolean = false; public box2: boolean = false; public box1: boolean = false;
  constructor(private toastr: ToastrService,private messagingservice: MessagingService, private settings: AppSettings, private chatService: SignalrClientService, private userservice: UserService, private _ngZone: NgZone, public dialog: MatDialog, public appComponent: ApplicationComponent,
    private customerService: CustomerService) {
    this.subscription = new Subscription();
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  GroupAdd() {
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.AddGroup = true;
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, 1, 0, 0).then((Owner) => {
      this.dropdownList = Owner;
    });
  }
  Close(val) {
    if (val == "box3") {
      this.box3 = false;
      this.appComponent.Standard3dotUitwo('#ChatDialog .dialog');     
    }
    else {
      this.sendMsgEvent.emit("Close" + "|" + this.box1 + "|" + this.box2 + "|" + this.box3);
    }
  }
  CloseMsg() {
    this.isopenchatMsg = false;
    this.sendMsgEvent.emit("Chatmsg" + "|" + this.box1 + "|" + this.box2 + "|" + this.box3);
  }
  OpenChatbox(touserId, chatids, grpId, chatuserName, grpuserids) {
    this.isshowReply = true;
    this.isopenchatMsg = true;  
    this.selecteduserName = chatuserName;
    this.touserId = touserId;
    this.grpuserids = grpuserids;
    this.message.groupId = grpId;
    this.box1 = true;
    if (grpId > 0) { this.message.groupName = chatuserName; this.groupName = chatuserName; this.groupId = grpId; }
    this.deletechatids = chatids;
    this.chatId = chatids;
  
    this.sendorReceivemessageUserList(this.currentuser.id, chatids, this.touserId, grpId,"");   
  }
 
  cancel() {  }
  sendorReceivemessageUserList(userId, chatids, touserId, grpId, source) {
    this.loggedinUserlist = [];
    this.customerService.GetmessageReceiveList(userId).then((userlist) => {    
      userlist.forEach(user => {
        user.groupuserids = user.touserid;
        user.touserid = user.groupName != null && user.groupName != "" ? user.groupId.toString() : user.userid.toString();
        user.groupName = user.groupName != null && user.groupName != "" ? user.groupName : user.username;
      });
      if (source == "Group") {
        userlist = userlist.sort((a, b) => (a.groupId > b.groupId ? -1 : 1));
      }
      if (chatids != "" && chatids != "0") {
        this.loggedinUserlist = userlist;       
      } else {
        this.globalloggedinUserlist = userlist;
        this.loggedinUserlist = this.globalloggedinUserlist;
        if (this.loggedinUserlist.length > 0) {
          if (source == "Group") {
            let selfobj = this;
            $(setTimeout(function () {
              $('#' + selfobj.loggedinUserlist[0].groupId + "_chatbutton").click();
            }, 30));
          }
        }
      }
      if (touserId > 0) {
        this.GetChatMessages(this.currentuser.id, chatids, grpId, "");
      }
    });
  }

  ngOnInit(): void {
    this.box2 = true; this.box1 = true;
    this.sendorReceivemessageUserList(this.currentuser.id,"",0,0,"");
  }
  EditChats() {
    this.box3 = true;
    this.selecteduserids = [];
    this.box3header = "EDIT CHAT SESSION";
    this.editloggedinUserlist = [];  
    this.userservice.GetAllUsers(0).then((userlist) => {
      this.editloggedinUserlist = userlist.filter(x => x.id != this.currentuser.id);
      this.editloggedinUserlist = this.editloggedinUserlist.filter(x => x.id != this.currentuser.id);
      this.editloggedinUserlist.forEach(user => {
        if (this.message.groupId) {
          user.userselected = this.grpuserids.split(',').includes(user.id.toString());
          if (user.userselected) {
            this.selecteduserids.push(user.id);
          }
        } else {
          user.userselected = this.touserId.split(',').includes(user.id.toString());         
        }       
      });
    });
    this.sendMsgEvent.emit("EditUser" + "|" + this.box1 + "|" + this.box2 + "|" + this.box3);
  }
  sendMessage(): void {
  
      var message = new ChatMessage();     
      message.clientuniqueid ="2";
      message.type = "sent";
      message.message = this.txtMessage;
    message.datetime = new Date().getTime().toString();
    message.createdate = new Date().getDate.toString();
      message.sentuserfirstName = this.currentuser.firstname;
      message.sentuserlastName = this.currentuser.lastname;
      this.previousmessages.push(message);
      this.message.clientuniqueid = "2";
      this.message.message = this.txtMessage;
      this.message.UserName = this.selectedchatusers;
      this.message.fromuserid = this.currentuser.id;
      this.message.touserid = this.message.groupId == 0 ? this.touserId : this.grpuserids;
      this.message.createdBy = this.currentuser.id;
      this.message.grpusers = this.grpuserids;
      if (this.message.groupName != "" && this.message.groupName != undefined) {
        this.chatService.sendGroupMessage(this.message);
        this.sendMsgEvent.emit("ClearGroup" + "|" + this.box1 + "|" + this.box2 + "|" + this.box3);
 
        if (this.message.groupId == 0) {
          let SO = this;
          $(setTimeout(function () {
            if (SO.message.groupId == 0) {
              SO.showlabel = false;
              SO.sendorReceivemessageUserList(SO.currentuser.id, "", SO.touserId, 0, "Group");

            }
            else {
              SO.customerService.GetAllChatMessages(SO.currentuser.id, SO.message.groupId, SO.chatId, SO.message.groupId).then((chatlist) => {
                SO.previousmessages = chatlist;
              });
            }
          }, 50));
        }
      }
      else {
        this.chatService.sendMessage(this.message);        
        this.sendMsgEvent.emit("MessageSend" + "|" + this.box1 + "|" + this.box2 + "|" + this.box3);
        this.showlabel = false;
        if (this.chatId == 0) {
          let SO = this;
          $(setTimeout(function () {
            SO.sendorReceivemessageUserList(SO.currentuser.id, "", SO.touserId, 0,"");
            $(setTimeout(function () {
              $('#' + SO.touserId + "_chatbutton").click();
            }, 50));
          }, 100));
        }
      }
      this.txtMessage = '';
    //}
  }
  
  public join() {
    this.chatService.join(this.groupName);
   this.joined = true;
  }
  OpenContacts() {
    this.box3 = true;
    this.isshowReply = false;
    $(".sublabel").removeClass("activeuserlbl");
    this.previousmessages = [];
    this.box3header = "ADD CHAT SESSION";
    this.groupName = null; this.groupId = 0;
    this.editloggedinUserlist = [];
    this.sendMsgEvent.emit("OpenUser" + "|" + this.box1 + "|" + this.box2 + "|" + this.box3);   
    this.userservice.GetAllUsers(0).then((userlist) => {
      this.editloggedinUserlist = userlist;
      this.editloggedinUserlist = this.editloggedinUserlist.filter(x => x.id != this.currentuser.id);
    });
  }
  leave() {
    this.chatService.leave(this.groupName);
    this.joined = false;
  }
  onItemSelect(item: any) { }
  OnItemDeSelect(item: any) { }
  onSelectAll(items: any) { }
  onDeSelectAll(items: any) { }
  loadSelecteduser(grpname, grpid, usernames, userids, showlabel) {
    this.showlabel = showlabel;
    this.message.groupName = grpname;
    this.message.groupId = grpid;
    this.touserId = userids;
    this.grpuserids = userids;
    this.selectedchatusers = usernames; this.chatId = 0;
    this.sendMessage();
  }
  GetChatMessages(userId, chatids, grpId, source) {
    this.previousmessages = [];
    if (this.touserId != "") {
      this.customerService.GetAllChatMessages(this.currentuser.id, this.touserId, chatids, grpId).then((chatlist) => {
        this.previousmessages = chatlist;
        this.sendMsgEvent.emit("ReadAllMsgs" +"|"+this.box1+"|"+this.box2+"|"+this.box3);
        if (this.touserId != undefined && !this.touserId.includes(",")) {
          $(".sublabel").removeClass("activeuserlbl");
          $('#' + this.touserId + "_chatbutton").addClass("activeuserlbl");
          if (source == "init") {
            $('#' + this.touserId + "_chatbutton").click();
          }
        }
      
      });
   
    }
  }
  Updategroup() {

    this.grpchat.groupId = this.groupId;
    this.grpchat.groupName = this.groupName;
    this.grpchat.createdBy = this.currentuser.id;
    this.grpchat.grpusers = this.selecteduserids.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []).join(',');
    this.customerService.updategroup(this.grpchat).then((user) => {
      this.toastr.success("Group Updated.", "Group");
      this.StartChat();
    });
  }
  StartChat() {
    this.isshowReply = true;
    if (this.selecteduserids.length > 1) {
      this.loadSelecteduser(this.groupName, this.groupId, this.selectedusername.join(','), this.selecteduserids.join(','), true);
      this.selectedusername = []; this.selecteduserids = [];
    }
    else {
      this.customerService.isDuplicateChatsession(this.currentuser.id, this.selecteduserids.join(',')).then((chatuser) => {
        if (chatuser) {          
          this.appComponent.salesxcrmDialog('#showduplicatechatsessionDiaog .dialog');
          this.appComponent.loaddialog('DplicateChat', 'Create New Chat Session', 'A chat session for the selected aleady exists.', false, false, false, true, 0);
          this.isshowReply = false;
      
        }
        else {
          this.loadSelecteduser(this.groupName, this.groupId, this.selectedusername.join(','), this.selecteduserids.join(','), true);
          this.selectedusername = []; this.selecteduserids = [];
        }
      })
    }

  }
  isDifferentDay(messageIndex: number): boolean {
    if (messageIndex === 0)    
      return true;
    const d1 = new Date(this.previousmessages[messageIndex - 1].createdate);
    const d2 = new Date(this.previousmessages[messageIndex].createdate);
    return (
      d1.getFullYear() !== d2.getFullYear() ||
      d1.getMonth() !== d2.getMonth() ||
      d1.getDate() !== d2.getDate()
    );
  }
  editOpenChatbox(touser, touserId, values) {
    if (values != null) {
      if (values.currentTarget.checked) {
        $('#' + touserId + "_button").addClass("activeSublabel");
        this.selectedusername.push(touser);
        this.selecteduserids.push(touserId);
      } else {
        $('#' + touserId + "_button").removeClass("activeSublabel");
        this.selecteduserids = this.selecteduserids;
        const index = this.selecteduserids.indexOf(touserId);
        if (index !== -1) {
          this.selecteduserids.splice(index, 1);      
        }
      }
    } else {
      $('#' + touserId + "_button").addClass("activeSublabel");
      const index = this.selecteduserids.indexOf(touserId);
      if (index == -1) {
        this.selectedusername.push(touser);
        this.selecteduserids.push(touserId);
      }
    }
  }
  EditChat(userids, grpName, grpId) {
    this.selecteduserids = []; this.selectedusername = []; var useridsarray = [];
    useridsarray = userids.split(',');
    useridsarray = useridsarray.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []);
    //if (!this.ChatUserDialog) {
     
     // this.loadChatdialog();
      this.userservice.GetAllUsers(0).then((userlist) => {
        this.loggedinUserlist = userlist;
        this.loggedinUserlist = this.loggedinUserlist.filter(x => x.id != this.currentuser.id);
        this.loggedinUserlist.forEach(user => {
          user.userselected = useridsarray.includes(user.id.toString());
        });
      });
   // }
    //else {
    //  let selfobj = this;
    //  setTimeout(function () {
    //    selfobj.loggedinUserlist.forEach(user => {
    //      $('#' + user.id + "_button").removeClass("activeSublabel");
    //    });
    //  }, 100);

    //}
    this.groupName = grpName;
    this.groupId = grpId;
    this.selecteduserids = userids.split(',');
    setTimeout(function () {
      useridsarray.forEach(id => {
        $('#' + id + "_button").addClass("activeSublabel");
      });
    }, 300);

  }
  DeleteChat() {
    if (this.deletechatids != '') {
      this.appComponent.loaddialog('DELETECHAT', 'DELETE LIVE CHAT SESSION', (this.fromchatusrId == this.currentuser.id) ? 'Select OK to delete this chat session and chat history.' : 'Select OK to delete this chat session,You are not the owner of this chat   session and deleting this chat session will only delete the chat session name from your chat session box.', false, false, true, true, this.deletechatids);
    } else {
      alert("Please Select Chat Session");
    }
 
  }
  getMessageDate(messageIndex: number): string {
    //let dateToday = new Date().toLocaleDateString();
    let longDateYesterday = new Date();
    longDateYesterday.setDate(new Date().getDate() - 1);
   // let dateYesterday = longDateYesterday.toLocaleDateString();
    //let today = dateToday.slice(0, dateToday.length - 5);
    //let yesterday = dateYesterday.slice(0, dateToday.length - 5);

    const wholeDate = new Date(
      this.previousmessages[messageIndex].createdate
    ).toLocaleDateString();
    this.messageDateString = (wholeDate == 'Invalid Date') ? "" : wholeDate;
    //this.messageDateString = wholeDate.slice(0, wholeDate.length - 5);

    if (new Date(this.previousmessages[messageIndex].createdate).getFullYear() === new Date().getFullYear()) {
      //if (this.messageDateString === today) {
      //  return "Today";
      //} else if (this.messageDateString === yesterday) {
      //  return "Yesterday";
      //} else {
        return this.messageDateString;
      //}
    } else {
      return this.messageDateString;
    }
  }
}
