export class Document{
  public id: number;
  public documentname: string;
  public datecreated: string;
  public uploadedby: number;
  public Size: string;
  public permissionlevel: number;
  public documenttype: string;
  public uploadedfilename: string;
  public imagecontent: string;
  public extension: string;  
  public accountid: number;
  public type: string;
  public documentnamepermissionid: number;
  public permissionlevelname: string;
  public doctype: string;
  public tabId: number;
  public pendingstatus: number;
  public lastediteddate: Date;
  public lastediteduserId: number;
  public documentdescription: string;

  public View: string;
  public DateUploaded: string;
  public file: any;
  public SavedBy: string;
  public CurrentPage: number;
  public PageSize: number;
  public TotalPages: number;
  public Total: number;
  public isdeleted: number;
  public DocSavedFrom: string;
  public Permissionlevel: string;
  public PermissionlevelName: string;
  public LateditedBy: string;
}

