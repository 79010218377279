import { Component, OnInit, Input,Output } from '@angular/core';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { Tickets } from '../../models/tickets';
import { CustomerService } from './../../services/customer.service';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment'
import { LeadsService } from './../../services/leads.service';
import { TaskHistory } from './../../models/task-history';
import { Dropdowntype } from '../../models/dropdowntype';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationComponent } from '../application.component';
import { Opportunities } from '../../models/opportunities';
import { Projects } from '../../models/ProjectConfiguration';
import { Notes } from '../../models/notes';
import { AccountSpecialNotes } from '../../models/AccountSpecialNotes';

import { AccountsService } from '../../services/accounts.service';
import { Campaign } from '../../models/Campaign';
import { UserService } from '../../services/user.service';

declare var $: any;
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  @Input()
  type: string;
  @Input()
  userType: string;
  @Input()
  ContAccId: number;
  @Input()
  AccountcontactIds: string;
  ticketslist: Array<Tickets> = [];
  duplicateticketslist: Array<Tickets> = [];
  public gridOptions: GridOptions;
  public OpportunityGridOptions: GridOptions;
  public ticketsgridOptions: GridOptions;
  public ProjectsgridOptions: GridOptions;
  public ticketscolumnDefs: any[];
  public columnDefs: any[];
  showhistorypdf1: boolean = false;
  public ProjectscolumnDefs: any[];
  public OpportunitycolumnDefs: any[];
  allCampaigns: Campaign[];
  OriginalAllCampaigns: Campaign[];
  today = Date.now();
  currentuser: any;
  source: string;
  public context;
  public AddScheduleTask: boolean = false;
  public frameworkComponents;
  datePipe: DatePipe = new DatePipe("en-US");
  tasktype: string;
  pageSize: string = "";
  columnSelections: string;
  alltaskhistory: TaskHistory[];
  userprevilages: any;
  activitieslist: Array<TaskHistory> = [];
  ticketsBlockView = false;
  isDelink: boolean = false;
  selectedNoteId;
  accountstatisticBox;
  pagefromActivitiesinfo;
  public sideBar;
  showActivityDetailsDialog: boolean = false;
  openTaskhistory: boolean = true;
  showTicketsDetailsDialog: boolean = false;
  ActivitiesDropdownList: Dropdowntype[] = [];
  ProjectsDropdownList: Dropdowntype[] = [];
  StatusDropdownList: Dropdowntype[] = [];
  taskhistory: TaskHistory = new TaskHistory();
  TicketDetails: Tickets = new Tickets();
  Allopportunities: Opportunities[];
  OriginalAllopportunities: Opportunities[];
  ProjectsList: Projects[] = [];
  MainProjectsList: Projects[] = [];
  taskhistorynotes: Array<Notes> = [];
  specialnotes: AccountSpecialNotes = new AccountSpecialNotes();
  constructor(private customerService: CustomerService, private opprtunityservice: OpprtunitiesService,
    private leadservice: LeadsService, public applicationComponent: ApplicationComponent,
    private router: Router, private accountsService: AccountsService,private userService: UserService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
   
  }

  ngOnInit() {
    this.customerService.GetPrivilagesByuser(this.currentuser.id).then(result => {
      this.userprevilages = result;
 
      if (this.type == 'Activities' || this.type == "FutureTask") {
        this.customerService.GetSpecialityList("Activities", this.currentuser.id).then((Documenttype) => {
          this.ActivitiesDropdownList = Documenttype;
        });
        this.customerService.GetSpecialityList("DisplayStatus", this.currentuser.id).then((Documenttype) => {
          this.StatusDropdownList = Documenttype;
        });
        this.getAllActivities(this.ContAccId, this.userType);
        this.initializeActivityAgGrid();
      }
      
      else if (this.type == 'Tickets') {
        localStorage.setItem('includecc', null);
        localStorage.setItem('tickettype', null);
        this.StatusDropdownList = [];
        this.StatusDropdownList.push({ "id": 0, "itemName": "Display All", "category": 'Ticket Types', "isdeleted": 0, "taskgroupid": 0 });
        this.StatusDropdownList.push({ "id": 4, "itemName": "Display Closed ", "category": 'Ticket Types', "isdeleted": 0, "taskgroupid": 0 });
        this.StatusDropdownList.push({ "id": 6, "itemName": "Display Deleted", "category": 'Ticket Types', "isdeleted": 0, "taskgroupid": 0 });
        this.StatusDropdownList.push({ "id": 2, "itemName": "Display On-Hold", "category": 'Ticket Types', "isdeleted": 0, "taskgroupid": 0 });
        this.StatusDropdownList.push({ "id": 1, "itemName": " Display Open", "category": 'Ticket Types', "isdeleted": 0, "taskgroupid": 0 });
        this.StatusDropdownList.push({ "id": 3, "itemName": "Display Pended ", "category": 'Ticket Types', "isdeleted": 0, "taskgroupid": 0 });   
        this.initializeAgGrid();
        if (this.userType == 'Contact') {
          this.loadtickets(this.ContAccId, 'All');
        }
        else if (this.userType == 'Customer') {
          this.customerService.GetAccountContactList(this.ContAccId).then(result => {
            result.forEach(ele => {
              this.loadtickets(ele.id, 'All');
            });
          });
        }

      }
      else if (this.type == 'Opportunities') {
        this.initializeOpportunityGrid();
        
      }
      else if (this.type == 'Projects') {
        this.customerService.GetSpecialityList("Project Status", this.currentuser.id).then((ProjectType) => {
          this.ProjectsDropdownList = ProjectType;
          this.ProjectsDropdownList = this.ProjectsDropdownList.filter(x =>x.itemName != 'Complete');
        });
        this.initializeProjectsAgGrid();

      }
    });
  }

  getAllActivities(contactid, usertype) {
    this.ContAccId = contactid;
    this.userType = usertype;
    this.leadservice.GetAllTaskHistoryByContact(contactid, usertype, this.currentuser.timezone).then((activities) => {       
      this.activitieslist = [];
  
      this.alltaskhistory = activities;
      if (this.type == "FutureTask") {
        this.alltaskhistory = this.alltaskhistory.filter(x => x.estcompleteddate > this.datePipe.transform(this.today, "yyyy-MM-dd"));
      }
    });
  }
  initializeActivityAgGrid() {   
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, defaultToolPanel: '', paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true,
      rowSelection: 'multiple', enableCellChangeFlash: true, rowDragManaged: true, animateRows: true
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 9).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        //this.gridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }
      this.columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";

      this.createColumnDefs(this.columnSelections);
    
    });
    //this.gridOptionsACT.defaultColDef = {
    //  headerComponentParams: {
    //    menuIcon: 'fa-bars'
    //  }
    //}
    this.context = this;
    
  }
  initializeAgGrid() {
    this.ticketsgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: false, toolPanelSuppressPivotMode: false, toolPanelSuppressValues: false, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.createticketscolumnDefs("");
    
  }
 
  createColumnDefs(columnSelections: string) {    
    let selfobj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
       
        else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefs.push({ field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: { 'text-align': 'left' }, function (params) { if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { textAlign: 'left',color: '#FF0000'}; } } });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "datecompleted") {
          this.columnDefs.push({
            field: 'datecompleted', headerName: 'Date Completed', hide: (this.tasktype == 'Open') ? true : false, cellStyle: { 'text-align': 'left' }, function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000', textAlign: 'left' }; }
            }, cellRenderer: (params) => {
              if (params.data.status == 'Open' || params.value == "") { return ""; } else {
                return moment(params.value).format('MM-DD-YYYY hh:mm a');
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "datecreated") {
          this.columnDefs.push({
            field: 'datecreated', headerName: 'Date Created', hide: false, cellStyle: { 'text-align': 'left' }, function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000', textAlign: 'left' }
              }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY hh:mm a');
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "estcompleteddate" || fieldHeaderVisibleWidthArray[0] == "DateSched") {
          this.columnDefs.push({
            field: 'estcompleteddate', headerName: 'Date Scheduled', hide: false, cellStyle: { 'text-align': 'left' }, function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000',textAlign: 'left' }
              }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY hh:mm a');
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "accountname") {
          this.columnDefs.push({
            field: 'accountname', headerName: 'Account Name', hide: false, cellStyle: { 'text-align': 'left' },function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000', textAlign: 'left' }; }
            },
            cellRenderer: function (params) {
              //if (searchval != null) {
              if (params.data.SearchVal != null) {
                let self = this;
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  //var search = searchval.replace(/["]/g, "")
                  var search = params.data.SearchVal
                  if (x[i] == search || (x[i].toLowerCase() == search.toLowerCase())) {
                    st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "firstName") {
          this.columnDefs.push({
            field: 'firstName', headerName: 'First Name', hide: false, cellStyle: { 'text-align': 'left' }, function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' ,textAlign: 'left'}; }
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "lastName") {
          this.columnDefs.push({
            field: 'lastName', headerName: 'Last Name', hide: false, cellStyle: { 'text-align': 'left' }, function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000', textAlign: 'left' }; }
            },
            cellRenderer: function (params) {
              //if (searchval != null) {
              if (params.data.SearchVal != null) {
                let self = this;
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  //var search = searchval.replace(/["]/g, "")
                  var search = params.data.SearchVal;
                  if (x[i] == search || (x[i].toLowerCase() == search.toLowerCase())) {
                    st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });

        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            cellStyle: { 'text-align': 'left' }, function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000', textAlign: 'left' }
              }
            },
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        {
          field: 'datecreated', headerName: 'Date Created', hide: false, cellStyle: { 'text-align': 'left' }, function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000', textAlign: 'left' }
            }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY hh:mm a');
          }
        },
        {
          field: 'estcompleteddate', headerName: 'Date Scheduled', hide: false, cellStyle: { 'text-align': 'left' }, function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000', textAlign: 'left' }
            }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY hh:mm a');
          }
        },
        {
          field: 'status', headerName: 'Status', hide: false, cellStyle: { 'text-align': 'left' }, function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000', textAlign: 'left' }; }
          }
        },
        {
          field: 'datecompleted', headerName: 'Date Completed', hide: (this.tasktype == 'Open') ? true : false, cellStyle: { 'text-align': 'left' }, function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000', textAlign: 'left' }; }
          }, cellRenderer: function (params) {
            if (params.data.status == 'Open' || params.value == "") { return ""; } else {
              return moment(params.value).format('MM-DD-YYYY hh:mm a');
            }
          }
        },
        {
          field: 'accountname', headerName: 'Account Name', hide: false, cellStyle: { 'text-align': 'left' },function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000', textAlign: 'left' }
            }
          },
          cellRenderer: function (params) {
            //if (searchval != null) {
            if (params.data.SearchVal != null) {
              let self = this;
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                //var search = searchval.replace(/["]/g, "")
                var search = params.data.SearchVal
                if (x[i] == search || (x[i].toLowerCase() == search.toLowerCase())) {
                  st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'taskcategory', headerName: 'Task', hide: false, cellStyle: { 'text-align': 'left' },function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000', textAlign: 'left' }
            }
          }
        },

        {
          field: 'tasktype', headerName: 'Type', hide: false, cellStyle: { 'text-align': 'left' },function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000', textAlign: 'left' }
            }
          }
        },
       
        {
          field: 'firstName', headerName: 'First Name', hide: false, cellStyle: { 'text-align': 'left' }, function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000', textAlign: 'left' }; }
          },
          cellRenderer: function (params) {
            //if (searchval != null) {
            if (params.data.SearchVal != null) {
              let self = this;
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                //var search = searchval.replace(/["]/g, "")
                var search = params.data.SearchVal;
                if (x[i] == search || (x[i].toLowerCase() == search.toLowerCase())) {
                  st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'lastName', headerName: 'Last Name', hide: false, cellStyle: { 'text-align': 'left' },function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000', textAlign: 'left' }; }
          },
          cellRenderer: function (params) {
            //if (searchval != null) {
            if (params.data.SearchVal != null) {
              let self = this;
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                //var search = searchval.replace(/["]/g, "")
                var search = params.data.SearchVal
                if (x[i] == search || (x[i].toLowerCase() == search.toLowerCase())) {
                  st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'starttime', headerName: 'Start Time', hide: false, cellStyle: { 'text-align': 'left' }, function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000', textAlign: 'left' }
            }
          }
        },
        {
          field: 'endtime', headerName: 'End Time', hide: false, cellStyle: { 'text-align': 'left' },function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000', textAlign: 'left' }
            }
          }
        }, //true starts here
        {
          field: 'owner', headerName: 'Owner', hide: true, cellStyle: { 'text-align': 'left' },function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000', textAlign: 'left' }
            }
          }
        },
        //{ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, pinned: 'right' }

      ];

    }
  }
  createticketscolumnDefs(columnSelections: string) {
    let selfObj = this;
    var delBtn = false;
    var thisTab = selfObj.userprevilages.filter(p => p.resource == "Delete Tickets")
    if (thisTab[0] != undefined && (thisTab[0].canview == true && thisTab[0].canadd == true)) {
      delBtn = true;
    }
  
    this.ticketscolumnDefs = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      { field: 'ticketId', headerName: 'Ticket', hide: true, cellStyle: { 'text-align': 'left' } },
      { field: 'ClosedDate', headerName: 'Closed Date', hide: false, cellStyle: { 'text-align': 'left' } },
      { field: 'userList', headerName: 'Assign Ticket To', hide: false, cellStyle: { 'text-align': 'left' } },
      { field: 'subject', headerName: 'Subject', hide: false, cellStyle: { 'text-align': 'left' } },
      { field: 'descriptions', headerName: 'Description', hide: false, cellStyle: { 'text-align': 'left' } },
      { field: 'email', headerName: 'Email', hide: false, cellStyle: { 'text-align': 'left' } },
      { field: 'UserName', headerName: 'User Name', hide: false, cellStyle: { 'text-align': 'left' } },
      { field: 'createdDate', headerName: 'Created Date', hide: false, cellStyle: { 'text-align': 'left' }},
      { field: 'contactname', headerName: 'Contact name', hide: false, cellStyle: { 'text-align': 'left' }},
      {
        field: 'TicketStatus', headerName: 'Status', hide: false, cellStyle: { 'text-align': 'left' },cellRenderer(params) {
          var eDiv = document.createElement('div');
          var text = '';
          if (params.value == 1) {
            text = 'Open';
          }
          else {
            text = 'Closed'
          }
          eDiv.innerHTML = text;
          return eDiv;
        }
      },
     
      {
        field: 'actions', width: 50, headerName: 'Actions', cellRenderer(params) {

          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<i style="font-weight: 400;font-size: 14px;" class="fa fa-trash" aria - hidden="true" > </i>';
          eDiv.addEventListener('click', function () {
            selfObj.deleteTicket(params.data.ticketId);
          });
          return eDiv;

        },
        hide: !delBtn, pinned: 'right'
      }
    ];
  }
  loadtickets(contOrAcc, type) {
    this.customerService.GetTicketsListByContact(contOrAcc, type).then((tickets) => {
      var dateTime;
      tickets.forEach(ele => {
        this.customerService.GetTicketNotesByTicketId(ele.ticketId,'Ticket').then(result => {           
          var dateTime;
          ele.ticketNotes = result;
          ele.ticketNotes.forEach(note => {
            dateTime = '';
            dateTime = this.datePipe.transform(note.CreatedDate, "MM-dd-yyyy");
            dateTime += " (" + this.datePipe.transform(note.CreatedDate, 'hh: mm a') + ")";
            note.CreatedDate = dateTime;
          });
        })
        this.duplicateticketslist.push(ele);
        dateTime = '';
        dateTime = this.datePipe.transform(ele.createdDate, "MM-dd-yyyy");
        dateTime += " (" + this.datePipe.transform(ele.createdDate, 'hh: mm a') + ")";
        ele.createdDate = dateTime;
      });
      let objSelf = this;
      $(setTimeout(function () {
        objSelf.ticketslist = objSelf.duplicateticketslist;
      }, 100));
      });
 
  }


  StatusDropdownChange(status) {  
    if (status == "Display Status = Open") {
      this.alltaskhistory = this.alltaskhistory.filter(x => x.status == 'Open');
    } else {
      this.getAllActivities(this.ContAccId, this.userType);
    }
    
  }
  TicketChange(ticketType) {
    if (ticketType > 0) {
      this.ticketslist = this.duplicateticketslist.filter(x => x.TicketStatus == ticketType);
    } else {
      this.ticketslist = this.duplicateticketslist;
    }
  
  }
  tasklisttypes(type) {
    var tasktype = "";
    if (type == 'AddTask') {
      this.AddScheduleTask = true;
    }
    else if (type == 'Display All Task Types') {
      this.getAllActivities(this.ContAccId, this.userType);
    } 
    else {
      if (type == "Display To-Do's") {
        tasktype = 'To Do';
      }
      else if (type == "Display Phone Calls") {
        tasktype = 'Phone';
      } else if (type == "Display Meetings") {
        tasktype = 'Meeting';
      }
      this.leadservice.GetTasksBycategory(this.ContAccId, this.userType, tasktype).then((activities) => {
        this.activitieslist = [];
        activities.forEach(activities => {
          activities.datecreated = this.datePipe.transform(activities.datecreated, "MM/dd/yyyy");
        });
        this.alltaskhistory = activities;

      });
    }
  }
  selectionactivitiesChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (rowEvent.api.getSelectedNodes()[0].data) {
        this.showActivityDetailsDialog = true;
        this.taskhistory = rowEvent.api.getSelectedNodes()[0].data;
        this.leadservice.GetAllTaskHistoryById(this.taskhistory.id, 'TaskHistory').then((items) => {
          items.forEach(items => {
          });
          this.taskhistorynotes = items;
        });
      }
    } else {
      this.showActivityDetailsDialog = false;
      this.taskhistory = null;
    }

  }
  selectionTicketChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (rowEvent.api.getSelectedNodes()[0].data) {
        this.showTicketsDetailsDialog = true;
        this.TicketDetails = rowEvent.api.getSelectedNodes()[0].data;
      }
    } else {
      this.showActivityDetailsDialog = false;
      this.taskhistory = null;
    }

  }
  TaskSaveReturn(msg) {
    
    if (msg == 'Cancel') {
      this.AddScheduleTask = false;
      this.loadtickets(this.ContAccId,'All');
      this.tasklisttypes('All');
      this.getAllActivities(this.ContAccId, this.userType);
    }
  }
  deleteTicket(TicketId) {
    this.customerService.DeleteTicket(TicketId).then((user) => {
      if (this.userType == 'Contact') {
        this.loadtickets(this.ContAccId, 'All');
      } else if (this.userType == 'Customer') {
        this.customerService.GetAccountContactList(this.ContAccId).then(result => {
          result.forEach(ele => {
            this.loadtickets(ele.id, 'All');
          });
        });
      }
    });
  }
  View(val) {
    if (val == 'Block') {
      this.ticketsBlockView = true;
    } else {
      this.ticketsBlockView = false;
    }

  }
  //delink(val, va1) {

  //}
  openfile(val,va1){

  }
  //delinkOff(val) {

  //}
  //delinkDocs(val) {

  //}
  rowClickAgGrid(event) {

  }
  openDisplaynotes(note) {

  }
  AddDocumentsNew(val1, Val2) { }
  addlink(note) { }
  ActivitiesrowClickAgGrid(event) {
    this.showActivityDetailsDialog = true;
  }
  closeDetailsDialog(val) {
    if (val == 'Activity') {
      this.showActivityDetailsDialog = false;
    } else {
      this.showTicketsDetailsDialog = false;
    }
    
  }
  OpenTask() {
    this.router.navigateByUrl(`/application/tasklistdashboard/` + this.userType + `/` + this.ContAccId + '/' + this.taskhistory.id + '/null');
    this.applicationComponent.ExpandSelectedMenu('tasklists');
  }
  getTicketStatus(val) {
    let status = ''; 
    switch (val) {
      case 1: {
        status = 'Open';
        break;
      }
      case 2: {
        status = 'On-Hold';
        break;
      }
      case 3: {
        status = 'Pended';
        break;
      }
      case 4: {
        status = 'Complete';
        break;
      }
      case 5: {
        status = 'Not-Completed';
        break;
      }
      case 6: {
        status = 'Deleted';
        break;
      }
    }

    return status ;
  }

  LoadOpportunities(accountid) {
    if (this.userType == 'Customer') {
      this.Allopportunities = [];
      this.OriginalAllopportunities = [];
      this.customerService.GetAllContactproviderByAccount(accountid, this.currentuser.id).then((contacts) => {
        contacts.forEach(ele => {
          this.LoadOppData(ele.id);
        });

        let selfObj = this;
        $(setTimeout(function () {
          selfObj.Allopportunities = selfObj.OriginalAllopportunities;
      
        }, 400));
        
        
      });
    } else {
      this.opprtunityservice.GetAllOpportunitiesListByAccountorContact(accountid, 'Opt').then((customer) => {
        customer.forEach((customer) => {
          customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
          customer.actualclosedate = this.datePipe.transform(customer.actualclosedate, "MM-dd-yyyy");
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
        });
        this.Allopportunities = this.OriginalAllopportunities = customer;
      
      });
    }



    
  }

  LoadOppData(conid) {
    this.opprtunityservice.GetAllOpportunitiesListByAccountorContact(conid, 'Opt').then((custm) => {
      custm.forEach((customer) => {
        customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
        customer.actualclosedate = this.datePipe.transform(customer.actualclosedate, "MM-dd-yyyy");
        customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");

        this.OriginalAllopportunities.push(customer);
      });
    });
  }
  
  LoadCampaigns(ContAccId,userid) {
    this.opprtunityservice.GetAllCampaigns(userid).then((viewCampaigns) => {
      this.OriginalAllCampaigns = viewCampaigns;
   
      if (this.userType == 'Contact') {
        this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.ContactsLinked == ContAccId)
      } else {     
        this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.ContactsLinked != null && this.AccountcontactIds.includes(x.ContactsLinked));
      }
     
    });
  }
  initializeOpportunityGrid() {
    this.OpportunityGridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;

    this.customerService.GetSpecialityList("OpportunitiesType", this.currentuser.id).then((Documenttype) => {
      this.ActivitiesDropdownList = Documenttype;
    });

    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 31).then((config) => {

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createOpportunityGridColumnDefs(columnSelections);

      let selfObj = this;
      $(setTimeout(function () {
        selfObj.LoadOpportunities(selfObj.ContAccId);
      }, 100));

      
    });
    this.OpportunityGridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }

  }
  createOpportunityGridColumnDefs(columnSelections: string) {
    let selfobj = this;
    
      this.OpportunitycolumnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        {
          field: 'datecreated', headerName: 'Date Created', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'estimatedclosedate', headerName: 'ECD', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        }, {
          field: 'ProposalPDF', headerName: 'Proposal', hide: false, width: 100, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
            eDiv.addEventListener('click', function () {
              selfobj.PDFClick(params.data.ProposalPDF, 'Proposal');
            });
            return eDiv;
          }

        },
        {
          field: 'ProposalPOPDF', headerName: 'PO', hide: false, width: 100, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
            eDiv.addEventListener('click', function () {
              selfobj.PDFClick(params.data.ProposalPOPDF, 'Proposal');
            });
            return eDiv;
          }

        }, 
        {
          field: 'status', headerName: 'Status', hide: false, cellRenderer: (params) => {
            if (params.data.status == '108') {
              return 'Open';
            }
            else if (params.data.status == '109') {
              return 'Closed-Won';
            } else if (params.data.status == '110') {
              return 'Closed-Lost';
            } else if (params.data.status == '111') {
              return 'Closed-Pending';
            }
          }, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'practicename', headerName: 'Account Name', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'firstName', headerName: 'First Name', hide: false,
          cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'lastName', headerName: 'Last Name', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },

        {
          field: 'itemName', headerName: 'Proposal Item', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'ProposalDescription', headerName: 'Proposal Description', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'LeadDescription', headerName: 'Lead Description', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'dollers', headerName: 'Price', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'RR', headerName: 'Monthly', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'PCname', headerName: 'Closing Percentage', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'drsName', headerName: '# Drs', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },  //true starts here
        {
          field: 'midlevel', headerName: '# Mid Levels', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'leadfrom', headerName: 'Lead From', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'owner', headerName: 'Owner', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'actualclosedate', headerName: 'Act.Close', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
      ];
  }
 
  PDFClick(val, type) {
    this.showhistorypdf1 = true;   
    this.PdfDialogCss(); let SO = this
    this.userService.GetProposalsDocumentContent(val).then(result => {
     
      $(setTimeout(function () {
        SO.onDocumentOpen(result.PdfContent);
      }, 500));
    });
  }
  PdfDialogCss() {
    setTimeout(function () {
      $('#showhistorypdf1 .dialog').attr('style', 'width: 44%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 85%;top: 10%;left: 54%;padding:0;4');
    }, 4);
  }
  onDocumentOpen(myBaseString) {
    $('#historypdfDiv').html('<embed src="data:application/pdf;base64,' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:100%;" />')
    $('#historypdfDiv').attr('style', 'width:100%;height: 100%;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;margin-left:0%');
  }
  OpportunitiesTypeChange(type) {
    if (type == "Closed Opportunities") {
      this.Allopportunities = this.OriginalAllopportunities.filter(x => (x.status.includes('Closed')));
    } else if (type == "Open Opportunities") {
      this.Allopportunities = this.OriginalAllopportunities.filter(x => x.status == 'Open');
    } else if (type == "Pended Opportunities") {
      this.Allopportunities = this.OriginalAllopportunities.filter(x => (x.status.includes('Pending')));
    } else {
      this.Allopportunities = this.OriginalAllopportunities;
    }
  }
  ProjectsTypeChange(type) {
    if (type == "All") {
    this.ProjectsList = this.MainProjectsList;
    } else {
      this.ProjectsList = this.MainProjectsList.filter(x => x.ProjectStatusName == type);
    }
  }
  initializeProjectsAgGrid() {
    this.ProjectsgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;

    this.createProjectsColumnDefs(columnSelections);
    this.loadProjectsbyAccountId(this.ContAccId);
    this.ProjectsgridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    

  }

  createProjectsColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.ProjectscolumnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      { field: 'CreatedDate', headerName: 'CreatedDate', hide: false },
      { field: 'ProjectTypeName', headerName: 'ProjectTypeId', hide: false },
      { field: 'ProjectStatusName', headerName: 'ProjectStatus', hide: false },
      { field: 'AccountOrContactId', headerName: 'AccountOrContactId', hide: true },
      { field: 'TargetGoLiveDate', headerName: 'TargetGoLiveDate', hide: true },
      { field: 'NumberofTrainers', headerName: '# Trainers', hide: true },
      { field: 'OnsiteDateStatus1Name', headerName: 'OnsiteDateStatus1', hide: true },
      { field: 'OnsiteTrainingDate1From', headerName: 'OnsiteTrainingDate1', hide: true },
      { field: 'OnsiteTrainingDate1To', headerName: 'OnsiteTrainingDate1', hide: true },
      { field: 'OnsiteTrainingDate2From', headerName: 'OnsiteTrainingDate2', hide: true },
      { field: 'OnsiteTrainingDate2To', headerName: 'OnsiteTrainingDate2', hide: true },
      { field: 'OnsiteTrainingHours', headerName: 'OnsiteTrainingHours', hide: true },
      { field: 'PickListEditable', headerName: 'Pick List Editable', hide: true },
      { field: 'ProjectLeaderName', headerName: 'ProjectLeaderName', hide: false },
      { field: 'OnsiteTrainingName', headerName: 'OnsiteTraining', hide: false },
      { field: 'RemoteTrainingName', headerName: 'RemoteTraining', hide: false },
      { field: 'RemoteTrainingDate1From', headerName: 'RemoteTrainingDate1', hide: true },
      { field: 'RemoteTrainingDate1To', headerName: 'RemoteTrainingDate1', hide: true },
      { field: 'RemoteDateStatus1Name', headerName: 'RemoteDateStatus1', hide: true },
      { field: 'RemoteTrainingDate2From', headerName: 'RemoteTrainingDate2', hide: true },
      { field: 'RemoteTrainingDate2To', headerName: 'RemoteTrainingDate2', hide: true },
      { field: 'RemoteDateStatus2Name', headerName: 'RemoteDateStatus2', hide: true },
      { field: 'RemoteTrainingHours', headerName: 'RemoteTrainingHours', hide: true },
      { field: 'Trainer1Name', headerName: 'Trainer1', hide: false },
      { field: 'Trainer2Name', headerName: 'Trainer2', hide: false },
      { field: 'UserAccessName', headerName: 'UserAccess', hide: false },
      { field: 'Notes', headerName: 'Notes', hide: true },
      
    ];
  }
  loadProjectsbyAccountId(AccountId) {
    this.customerService.GetAllProjectsByAccountId(AccountId).then(res => {
      res.forEach(SalesHistory => {
        SalesHistory.CreatedDate = this.datePipe.transform(SalesHistory.CreatedDate, "MM-dd-yyyy");
      });
      this.ProjectsList = res;
      this.MainProjectsList = res;
    });


  }

  ProjectsSelectionChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (rowEvent.api.getSelectedNodes()[0].data) {
        var data = rowEvent.api.getSelectedNodes()[0].data;
        //this.applicationComponent.ExpandSelectedMenu('Projects');
        //this.router.navigateByUrl(`/application/projects/" "/` + data.AccountOrContactId + '/' + data.ProjectID);
    
       
      }
    } else {
      this.showActivityDetailsDialog = false;
      this.taskhistory = null;
    }

  }
  OpenSpecailNote() {
    this.openTaskhistory = false;
  }
  cancelSpecial() { this.openTaskhistory = true; }
  SaveSpecialNote() {
    if (this.userType == 'Customer') {
      this.specialnotes.accountId = this.ContAccId;
    }
    this.accountsService.SavaccountSpecialNotes(this.specialnotes).then(result => {
      this.openTaskhistory = true;
    });
  }
  rowDoubleClickAgGrid(event) {
    this.applicationComponent.ExpandSelectedMenu('tickets');
    localStorage.setItem('includecc', (event.data.CCTo == "" || event.data.CCTo == null) ? "false" : "true");
    localStorage.setItem('tickettype', event.data.TicketStatus);
    this.router.navigateByUrl(`/application/zendeskhome/${event.data.contactid}/${event.data.ticketId}/Open Tickets`);
  }
  ProjectrowDoubleClickAgGrid(event) {
    this.applicationComponent.ExpandSelectedMenu('Projects');
    localStorage.setItem('projecttype', event.data.ProjectStatusName);
    this.router.navigateByUrl(`/application/projects/" "/` + event.data.AccountOrContactId + '/' + event.data.ProjectID);
  }
  OpportunitiesSelectionChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (rowEvent.api.getSelectedNodes()[0].data) {
        var OppData = rowEvent.api.getSelectedNodes()[0].data;
        this.applicationComponent.ExpandSelectedMenu('opportunities');
        if (OppData.type == 'Contacts') {
          this.router.navigateByUrl(`/application/opportunitiesdashboard/` + OppData.accountorcontactid + '/' + OppData.type+'/null/'+ OppData.id )
        } else {
          this.router.navigateByUrl(`/application/opportunitiesdashboard/` + OppData.accountorcontactid + '/' + OppData.type + '/null/' + OppData.id)
        }
      }
    }
  }


}
