import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NotificationService } from "./notification.service";
import { Notification, NotificationType } from "./notification";
import { Subscription } from "rxjs";
declare var $: any;
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  notifications: Notification[] = [];
  private _subscription: Subscription;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  @Input()
  NotificationMessages: any=[];
  constructor(private _notificationSvc: NotificationService) { }

  private _addNotification(notification: Notification) {
    this.notifications.push(notification);

    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);

    }
  }

  ngOnInit() {
    this._subscription = this._notificationSvc.getObservable().subscribe(notification => this._addNotification(notification));
  }


  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  close(notification: Notification) {
    this.notifications = this.notifications.filter(notif => notif.id !== notification.id);
    this.sendMsgEvent.emit(notification.Object);
  }


  className(notification: Notification): string {

    let style: string;

    switch (notification.type) {

      case NotificationType.success:
        style = 'success';
        break;

      case NotificationType.warning:
        style = 'warning';
        break;

      case NotificationType.error:
        style = 'error';
        break;


      case NotificationType.Message:
        style = 'Message';
        break;

      default:
        style = 'info';
        break;
    }

    return style;
  }
}
