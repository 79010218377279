export class Opportunities {
    public id: number;
    public item: string;
  public itemName: string;
    public dollers: string;
    public drs: number;
    public RR: string;
    public midlevels: number;
    public estimatedclosedate: string;
    public PC: number;
    public PCname: string;
    public ownerid: number;
    public datecreated: string;
    public actualclosedate: string;
    public status: string;
  public statusName: string;
    public practiceid: number;
  public accountorcontactid: number;
  public isPended: string;
    public type: string;
    public leadfrom: string;
    public leadfromid: number;
  public userid: number;
  public createdUser: string;
  public source: string;
  public salesCommissionOpportunity: string;
  public RRCommisssionOpportunityMonthly: string;
  public RRCommisssionOpportunityYearly: string;
  public islimitedSearch: boolean;
  public SearchVal: string;
  public SalesOpportunity: string;
  public RROpportunity: string;
  public notesDescription: string;
  public ProposalDescription: string;
  public LeadDescription: string;
  public OpportunityFor: string;
  public NoOfDays: number;
  public accountid: number;
  public contactName: string;
  public practicename: string;
  public owner: string;
  public isdeleted: number; 
  public ProposalPOPDF: string;
  public ProposalPDF: string;
  public drsName: string;
  public midlevel: string;
  public title: string;
  public fieldvalues: string;
}
