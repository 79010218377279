import { Component, OnInit, Input, ViewContainerRef, ElementRef, Output, EventEmitter } from '@angular/core';
import { Opportunities } from '../../models/opportunities';
import { Notes } from '../../models/notes';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { DatePipe } from '@angular/common';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { Contacts } from '../../models/contacts';
import { CustomerService } from './../../services/customer.service';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import { ToastrService } from 'ngx-toastr';
import { LeadsService } from '../../services/leads.service';
import { LinkDeleteComponent } from '../link-delete/link-delete.component';
import { ApplicationComponent } from '../application.component';
import { UserService } from '../../services/user.service';
declare var $: any;
@Component({
  selector: 'opportunityhistory',
  templateUrl: './opportunityhistory.component.html',
  styleUrls: ['./opportunityhistory.component.css']
})
export class OpportunityhistoryComponent implements OnInit {
  @Input()
  id: number;
  @Input()
  OppIds: string;
  @Input()
  PageFrom: string;
  @Input()
  type : string;
  currentuser: any;
  Allopportunities: Opportunities[];
  contactid = 0; datePipe: DatePipe = new DatePipe("en-US");
  public columnDefs: any[];
  public gridOptions: GridOptions;
  contact: Contacts = new Contacts();
  opportunities: Opportunities = new Opportunities();
  opportunitiesnotes: Array<Notes> = [];
  OwnerList: Dropdowntype[] = [];
  opprtunityitemlist: Dropdowntype[] = [];
  Tasklist: Dropdowntype[] = [];
  pclist: Dropdowntype[] = [];
  drlist: Dropdowntype[] = [];
  midlevelslist: Dropdowntype[] = [];
  leadfromlist: Dropdowntype[] = [];
  notes: Notes = new Notes();
  public elementRef;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  selectedtype: any;
  opportunityId: number = 0;
  today = Date.now();
  public sideBar;
  opportunitySelected: boolean = false;
  public frameworkComponents;
  public context;
  DeleteOpportunity: boolean = false; showpdf1: boolean = false;
  constructor(private opprtunityservice: OpprtunitiesService,
    public applicationComponent: ApplicationComponent, private customerService: CustomerService,
    private toastr: ToastrService, private userService: UserService,
     myElement: ElementRef)
  { 
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.elementRef = myElement;

  }
  ngOnInit() {  
    $(setTimeout(function () {
      $("#test").trigger("click");
    }, 100));
      this.initializeAgGrid();
  }

  GetOpportunitiesListByOppIds(oppids) {
    if (oppids) {
      this.opprtunityservice.GetOpportunitiesListByOppIds(oppids).then((customer) => {
        if (customer.length > 0) {
          customer.forEach((customer) => {
            customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
            customer.actualclosedate = this.datePipe.transform(customer.actualclosedate, "MM-dd-yyyy");
            customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
          });
          this.Allopportunities = customer;
       
        }
        else {
          this.Allopportunities = [];
        }
      });
    } else {
      this.Allopportunities = [];
    }
  
  }


  handleClick(event) {
    var clickedComponent = event.target;
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {
      $('#test').val('Opportunity');
    } else {
    }
  }

  createColumnDefs(columnSelections: string) {
    let selfobj = this;
      this.columnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false
        },
        { field: 'id', headerName: 'id', hide: true },
        {
          field: 'datecreated', headerName: 'Date Created', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          }},
        {
          field: 'estimatedclosedate', headerName: 'Date Scheduled', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          }
        },
        {
          field: 'ProposalPDF', headerName: 'Proposal', hide: true, width: 100, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
            eDiv.addEventListener('click', function () {
              selfobj.PDFClick(params.data.ProposalPDF, 'Proposal');
            });
            return eDiv;
          }

        },
        {
          field: 'ProposalPOPDF', headerName: 'PO', hide: true, width: 100, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
            eDiv.addEventListener('click', function () {
              selfobj.PDFClick(params.data.ProposalPOPDF, 'Proposal');
            });
            return eDiv;
          }

        },   
        {
          field: 'status', headerName: 'Status', hide: false, cellRenderer: (params) => {
            if (params.data.status == '108') {
              return 'Open';
            }
            else if (params.data.status == '109') {
              return 'Closed-Won';
            } else if (params.data.status == '110') {
              return 'Closed-Lost';
            } else if (params.data.status == '111') {
              return 'Closed-Pending';
            }
          }, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          } },
        {
          field: 'practicename', headerName: 'Account Name', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          }},
        {
          field: 'contactName', headerName: 'Contact Name', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          } },
        {
          field: 'itemName', headerName: 'Item', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          } },
        {
          field: 'dollers', headerName: '$$$$', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          } },
        {
          field: 'RR', headerName: 'RR', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          } },
        {
          field: 'PCname', headerName: 'PC', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          } },
        {
          field: 'drsName', headerName: 'Drs', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          } },  //true starts here
        {
          field: 'midlevel', headerName: 'Mid Levels', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          } },
        {
          field: 'leadfrom', headerName: 'Lead From', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          } },
        {
          field: 'owner', headerName: 'Owner', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          } },
        {
          field: 'actualclosedate', headerName: 'Act.Close', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          }
        },
        {
          field: 'actions', headerName: 'Actions', cellRenderer: 'linkdelete', cellRendererParams: { Delete: true, Link: false }, width: 100, hide: ((this.type == 'All Opportunities' )? false :true), pinned: 'right'
        }
      ];

  }
  PDFClick(val, type) {

    this.showpdf1 = true;
    this.PdfDialogCss();
    this.userService.GetProposalsDocumentContent(val).then(result => {
      let SO = this
      $(setTimeout(function () {
        SO.onDocumentOpen(result.PdfContent);
      }, 100));
    });
  }
  PdfDialogCss() {
    setTimeout(function () {
      $('#showpdf1 .dialog').attr('style', 'width: 44%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 85%;top: 10%;left: 54%;padding:0;4');
    }, 4);
  }
  onDocumentOpen(myBaseString) {
    $('#pdfDiv').html('<embed src="data:application/pdf;base64,' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:100%;" />')
    $('#pdfDiv').attr('style', 'width:100%;height: 100%;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;margin-left:0%');
  }
  onAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
     
    }, 100));
  }
  autoSizeAll() {
    if (this.gridOptions && this.gridOptions.columnApi && this.gridOptions.columnApi.getAllColumns() != undefined) {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
      //this.gridOptions.columnApi.autoSizeColumns(allColumnIds, "autosizeColumns");
    }
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, editable: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single', paginationPageSize: 1000,rowMultiSelectWithClick: true
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    //let selectedUserIds: string;
   
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 7).then((config) => {

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(columnSelections);

      if (this.type == 'All Opportunities' || this.type == 'Sales Won' || this.type == 'Sales Lost') {
        this.GetOpportunitiesListByOppIds(this.OppIds);
      }
      else {
        this.GetAllOpportunitiesbyselectedcontact(this.id);
      }
    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    
    this.context = this;
    this.frameworkComponents = {
      linkdelete: LinkDeleteComponent
    };
  }
  rowClickAgGrid(rowEvent) {
      if (rowEvent.node.selected) {
        this.loadDropDowns();
        this.opprtunityservice.GetByOpportunityId(rowEvent.data.id).then(result => {
          this.opportunities = result;
          this.opportunities.item = result.item;
          this.contactid = result.accountorcontactid;
          document.getElementById('saveOpp').style.display = 'none';
          if (this.opportunities.status == "Closed-Won" || this.opportunities.status == "Closed-Lost") {
            document.getElementById('statusdiv').style.display = 'none';
          }
          else {
            document.getElementById('statusdiv').style.display = 'block';
          }
        });
        this.opprtunityservice.GetAllOpprtunitiesNotesById(this.opportunities.id).then((items) => {
          items.forEach(items => {
          });
          this.opportunitiesnotes = items;
          document.getElementById('notesTextArea').style.color = 'red';
          document.getElementById('notesTextArea').style.border = 'none';
        });
        document.getElementById('Won').className = 'btn c-button';
        //document.getElementById('Lost_span').className = '';
        document.getElementById('Lost').className = 'btn c-button';
        document.getElementById('statusdiv').style.display = 'block';
        document.getElementById('Opptdetails').style.display = 'block';
        document.getElementById('OpportunityHistory').style.display = 'none';
      }

    
  }
  loadDropDowns() {
    this.opprtunityservice.GetAllItems().then((items) => {
      items.forEach(items => {
      });
      this.opprtunityitemlist = items;
    });

    this.opprtunityservice.GetSpecialityList("ProbabiltyClose", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.pclist = pc;
    });

    this.opprtunityservice.GetSpecialityList("Drs", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.drlist = pc;
    });

    this.opprtunityservice.GetSpecialityList("Midlevels", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.midlevelslist = pc;
    });

    this.opprtunityservice.GetSpecialityList("LeadFrom", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.leadfromlist = pc;
    });
    this.customerService.GetLoggedinUserList(this.currentuser.id, this.currentuser.systemadministrator).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.OwnerList = Owner;
    });
  }
  GetAllOpportunitiesbyselectedcontact(contactid) {
    if (this.PageFrom !== 'Campaign') {
      document.getElementById('OpportunityHistory').style.display = 'block';
    document.getElementById('Opptdetails').style.display = 'none';
    }
    
    this.customerService.GetContactById(contactid).then(result => {
      this.contact = result;
      this.opprtunityservice.GetAllOpportunitiesListByAccountorContact(this.id, 'Opt').then((customer) => {
        customer.forEach((customer) => {
          customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
          customer.actualclosedate = this.datePipe.transform(customer.actualclosedate, "MM-dd-yyyy");
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
        });
        this.Allopportunities = customer;
        
      });
    });
   
  }
  completeForm() {
    var number = 0;
    if (this.opportunities.item == "" || this.opportunities.item == undefined) {
      number = 1;
    }
    if (this.opportunities.PC == 0 || this.opportunities.PC == undefined) {
      number = 1;
    }
    if (this.opportunities.RR == undefined || this.opportunities.RR == "") {
      number = 1;
    }
    if (this.opportunities.dollers == undefined || this.opportunities.dollers == "") {
      number = 1;
    }
    if (this.opportunities.drs == 0 || this.opportunities.drs == undefined) {
      number = 1;
    }
    if (this.opportunities.estimatedclosedate == undefined || this.opportunities.estimatedclosedate == "") {
      number = 1;
    }
    if (this.opportunities.leadfromid == 0 || this.opportunities.leadfromid == undefined) {
      number = 1;
    }
    if (this.opportunities.midlevels == 0 || this.opportunities.midlevels == undefined) {
      number = 1;
    }
    if (this.opportunities.ownerid == 0 || this.opportunities.ownerid == undefined) {
      number = 1;
    }
    if (number == 0) {
      document.getElementById('saveOpp').style.display = 'block'
      document.getElementById('statusdiv').style.display = 'none';
    }
    if (this.opportunities.status == 'Closed-Won' || this.opportunities.status == 'Closed-Lost') {
      document.getElementById('statusdiv').style.display = 'none'
    }
  }
  addOpportunity() {
    this.opportunities.accountorcontactid = this.contactid;
    this.opportunities.type = 'Contacts';
    this.opportunities.userid = this.currentuser.id;
    this.opportunities.status = "Open";
    this.opportunities.source = "Opt";
    this.opportunities.OpportunityFor = "Opportunity";
    document.getElementById('notesTextArea').style.color = 'black';
    document.getElementById('notesTextArea').style.background = 'lightgray';
    this.opprtunityservice.addopprtunity(this.opportunities).then(result => {
      this.opportunities = result;
      this.notes.accountid = result.id;
      this.notes.type = "Opportunity";
      this.notes.notedescription = this.notes.stagenotedescription;
      this.notes.userid = this.currentuser.id;
      this.opprtunityservice.addopprtunityNotes(this.notes).then(result => {
        this.notes = result;
        this.notes.notedescription = '';
      });
      this.toastr.success("Opportunity saved successfully.", "Opportunity Saved", { timeOut: 600 });
      this.GetAllOpportunitiesbyselectedcontact(result.accountorcontactid);
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.gridOptions.api.forEachNode((node) => {
          if (node.data.id == result.id) {
            selfObj.gridOptions.api.selectNode(node, true);
          }
        });
      }, 100));
    });


  }
  Close() {
    this.sendMsgEvent.emit("closed");  
  }
  delete(val, status) {

    document.getElementById('Dialog').style.display = 'block';
    document.getElementById('OpportunityHistory').style.display = 'none';
    this.opportunities.status = status;
    $(".OpportunityactiveButton").addClass("c-button");
    $(".OpportunityactiveButton").removeClass("OpportunityactiveButton");
    if (status == "Closed-Won") {
      $("#Won").removeClass("c-button");
      $("#Won").addClass("OpportunityactiveButton");
    } else if (status == "Pending") {
      $("#Pending").removeClass("c-button");
      $("#Pending").addClass("OpportunityactiveButton");
    }
    else {
      $("#Lost").removeClass("c-button");
      $("#Lost").addClass("OpportunityactiveButton");
    }
  }
  handleEnterKeyPress(event) {
    const tagName = event.target.tagName.toLowerCase();
    if (tagName !== 'textarea') {
      return false;
    }
  }
  Cancel() {
   
  }
  rowDoubleClickAgGrid(rowEvent) {
    this.opportunityId = rowEvent.data.id;
    //if (this.active_deactiveStatus != 1)
    //  this.router.navigateByUrl(`/application/contactsdashboard/${rowEvent.data.id}`);
  }
  SaveOpportunity() {
    this.customerService.attachtoLead(this.opportunityId).then((user) => {
     // this.applicationComponent.ClosedLeads('Forecasting', this.currentuser.id, 'Forecasting');
      this.opportunityId = 0;
    });
  }
  ChangeStatus(val, status) {
    this.opprtunityservice.GetStatusChange(val, status).then(result => {
      this.opportunities = result;
      //this.loadOpportunities(result[0].accountorcontactid);
      //this.oppId = 0;
    });
    document.getElementById('Dialog').style.display = 'none';
    document.getElementById('OpportunityHistory').style.display = 'block';

  }

  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.opportunities = event.api.getSelectedNodes()[0].data;
        this.opportunitySelected = true;
      }
    }
  }
  LinkOpportunity() {
    this.sendMsgEvent.emit(this.opportunities.id.toString());  
  }
  columnVisible(event) {

  }

  dragStopped(event) {

  }

  columnResized(event) {

  }

  deleteRowAg(rowdata) {
    
    this.opportunityId = rowdata.id;
    this.DeleteOpportunity = true;
    this.DeleteOpportunityCss();
    
  }
  
  DeleteOpportunityCss() {
    setTimeout(function () {
      $('#DeleteOpportunity .dialog').attr('style', 'width: 33%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 20%;top:10%;');
    }, 4)
  }

  DeletOpportunity(opportunityid) {
    this.sendMsgEvent.emit(opportunityid.toString());
  }
}
