export class Systemconfiguration {
    public id: number;
    public configurationname: string;
    public atoztab: string;
    public practicetab: string;
    public recent: string;
    public maxresults: string;
    public defaultconfiguration: string;
    public userid: number;
    public addeduserid: number;
}
export class SystemconfigurationAccessModel {
  public AccountConfiguration: boolean=false;
  public AccountSharingGroups: boolean = false;
  public CampaignConfiguration: boolean = false;
  public ContactConfiguration: boolean = false;
  public DocumentMGMTConfiguration: boolean = false;
  public LeadsConfiguration: boolean = false;
  public MySalesXCRMConfiguration: boolean = false;
  public OpportunitiesConfiguration: boolean = false;
  public ProjectsConfiguration: boolean = false;
  public ProposalConfiguration: boolean = false;
  public ResponseWizard: boolean = false;
  public SoftwareLicenseConfiguration: boolean = false;
  public StatusIDConfiguration: boolean = false;
  public TaskListConfiguration: boolean = false;
  public TicketConfiguration: boolean = false;
}
