<div class="col-xs-12" style="padding:0;height:100%" *ngIf="ViewType == 'Forecast View'">
  <div class="col-xs-8" style="padding:0;height:100%;">
    <div class="col-xs-12" style="padding-left: 0px; padding-right: 0px; display: block;  border-bottom: 1px solid rgb(199, 199, 199); height: 35%; font-size: 12px; padding:0 0 0 1%">
      <account-details [viewaccount]="viewaccount" [account]="account" [tab]="''" [noteslist]="noteslist" (btnClickEvent)="btnClickReceived($event)" [pageFrom]="'Projects'" [ViewData]="'Account'" [contact]="''"></account-details>
    </div>
    <div class="col-xs-12" style="padding:1%;">
      <div class="col-xs-10" style=" text-align: center; text-transform: uppercase; font-weight: 700;
     background-color: rgb(40,62,74); color: white; width: 100%">PROJECT NOTES</div>
    </div>
    <div class="col-xs-12" style="padding:0;height:60%">    
        <app-new-notes style="height:100%"
                       [selectedTabName]="'Display All'"
                       [conid]="ProjectId"
                       [typ]="'Project'"
                       [Projtyp]="projectTypeName"
                       [isModal]="false">
        </app-new-notes>
  
    </div>
    
  </div>

  <div class="col-xs-4" style="padding:0;height:94%;border:1px solid black">

    <div class="col-xs-12" *ngIf="!PreviousNotesExpand" style="height: 5%; margin-top: 1%;
background-color: rgb(40,62,74); color: gray; padding: 1%; text-align: center; color: white ">
      <div class="col-xs-10"><label>PROJECT  DETAILS </label> </div>
      <div class="col-xs-1" *ngIf="SelectedStage>0">
        <button (click)="OpenEditProject()" style="background: transparent; text-align: end; padding-right: 0;">
           <img src="../../../assets/img/Edit Icon White.png" style="height:21px;" title="Edit Task" />
        </button>
      </div>
    </div>

    <div class="col-xs-12" style="padding:0 0 0 2%;height:94%" *ngIf="Project != null && Project != undefined && Project.ProjectID > 0 && !PreviousNotesExpand ">

      <div class="col-xs-12" style="padding: 2% 0 0 0; height: 9%; font-size: 12px;">
        <div class="col-xs-10" style="width: 82%; padding: 0;">
          <div class="col-xs-6" style="padding:4% 0 0 0;">
          <label><b>Stage : </b></label>
          </div>
          <div class="col-xs-6" style="padding:0;">
            <select class="form-control" name="Stages" style="padding: 0;font-size: 12px;" [(ngModel)]="SelectedStage" (change)="Proj_Detail_Stage_Selected($event.target.value,$event.target.selectedIndex)">
              <option selected value="0" style="color:black">Project Overview</option>
              <option *ngFor="let ProjectStages of Proj_Stages_List_by_ProjType"
                value={{ProjectStages.ProjectStageID}} style="color:black">
                 {{ProjectStages.ProjectStageName}}
              </option>
            </select>
          </div>
        </div>
        
      </div>

      <div class="col-xs-12 scroll-c" style="padding:0;height:90%;font-size:12px;" *ngIf="!Project_Steps_Div">       
        <div *ngFor="let field of Fields" class="col-xs-12" style="padding:0;">
          <div class="col-xs-12" style="padding:0;">
            <div class="col-xs-5" style="padding:0">
              <label> {{field.DisplayAs}} : </label>
            </div>
            <div class="col-xs-7">
              <b *ngIf="field.DataType=='Pick List'||field.DataType=='System Lookup'">{{getPropertyName(Project[field.FieldName], field.FieldName)}}</b>
              <b *ngIf="field.DataType=='Text Box'|| field.DataType=='Date Range' || field.DataType=='Date'">{{Project[field.FieldName]}}</b>
            </div>            
          </div>
        </div>
      </div>

      <div class="col-xs-12 scroll-c" style="padding:0;font-size:12px;" *ngIf="Project_Steps_Div">
        <div class="col-xs-7" style="padding:0">
          <label>Stage Status : </label>
        </div>
        <div class="col-xs-5">
          <b>{{Project_Stage_Status}}</b>
        </div>
          <br /><br />
                <div class="col-xs-12" style="padding:1% 0 1% 0;" *ngFor="let ProjStep of ProjStepStatus">
                  <div class="col-xs-7" style="padding:0">
                    <label>{{ProjStep.ProjectStepName}} : </label>
                  </div>
                  <div class="col-xs-5">
                    <b>{{ProjStep.ProjectStepStatus}}</b>
                  </div>
                </div>
        </div>

    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="PreviousNotesExpand">
      <div class="col-xs-12" style="height: 100%; border: 1px solid lightgreen; padding: 0 0 0 0%">
        <div class="col-xs-12" style="height: 5%; margin-top: 0%; background-color: rgb(40,62,74); color: gray; padding: 1%; text-align: center; ">
          <label>PROJECT  DETAILS</label>
          <span style="float:right;height:30px;padding:1%"> <img src="../../../assets/img/White x.png" style="height:20px;float:right;" (click)="PreviousNotesExpand = false"></span>
        </div>

        <div class="col-xs-12 scroll-c" style="padding:0;height:95%;overflow:auto">
          <div class="col-xs-12" style="padding: 0; margin-top: 1%; font-size: 11px; margin-bottom: 5%; padding-left: 2%;" *ngFor="let note of allnotes">
            <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; font-weight: bold; height: 20px;"><b>{{note.created | date:"dd-MM-yy"}}</b></div>

            <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; height: 20px;">{{note.notedescription}}</div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>


<div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
  <div class="col-xs-12" style="padding:0;height:5%">
    <span class="col-xs-12" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;padding:2px;float:right;z-index:1">
      <b> <img src="../../../assets/img/Black x.png" style="height:20px" (click)="NotesDec = false" /></b>

    </span>
  </div>
  <div class="col-xs-12">
    <label>Date: &nbsp;</label><span>{{notesdata.created }}</span><br />
    <label>Time: &nbsp;</label><span>{{notesdata.created}}</span><br />
    <label>User: &nbsp;</label><span>{{notesdata.user}}</span><br />
    <label>Notes: &nbsp;</label><span>{{notesdata.notedescription}}</span><br />
  </div>
  <ng-content></ng-content>


</div>


<app-dialog [(visible)]="showCreateProjectDialog" *ngIf="showCreateProjectDialog" id="showCreateProjectDialog" [OutSideClick]="false" [closable]="false">
  <div class="col-xs-12" style="background-color: orangered !important;padding:0%;color: orangered; height: 2%"></div>
  <div class="col-xs-12" style="padding:0;height:5%">
    <div class="col-xs-12" style="padding: 1%; height: 100%;font-weight: 600; text-transform: capitalize">

      <div class="col-xs-10" style="padding: 0%;" *ngIf="Project.ProjectID == undefined">CREATE NEW PROJECT </div>
      <div class="col-xs-10" style="padding: 0%;" *ngIf="Project != null && Project != undefined && Project.ProjectID > 0">EDIT PROJECT DETAILS</div>
      <img src="../../../../assets/img/Black x.png" style="height:20px;float:right" (click)="CloseCreateProject()" />
    </div>
  </div>

  <div class="col-xs-12"></div>
  <div class="col-xs-12 scroll-c" style="padding:0;height:92%" *ngIf="Project_OverView_Div">
    <div *ngIf="ProjectId>0">
      <div class="col-xs-12 removeCompletePadding">
        <div class="col-xs-3" style="padding:0%">
          Account :
        </div>
        <div class="col-xs-9">
          <b>{{account.accountname}}</b>
        </div>
      </div>

      <div class="col-xs-12 removeCompletePadding">
        <div class="col-xs-3" style="padding:0%">
          Project Type :
        </div>
        <div class="col-xs-9">
          <b>{{Project.ProjectTypeName}}</b>
        </div>       
      </div>

      <div class="col-xs-12 removeCompletePadding">
        <div class="col-xs-3" style="padding:0%">
          Stage :
        </div>
        <div class="col-xs-9">
          <b> Project Overview</b>
        </div>
       
      </div>
    </div>

    <form [formGroup]="NewForm">

      <div *ngFor="let field of Fields">
        <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%">
          <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
            <label *ngIf="field.ColumnName!='Notes'" style="margin:10% 0 0 0">{{field.DisplayAs}}</label>
            <label *ngIf="field.ColumnName=='Notes'" style="margin:10% 0 0 0">Additional Details</label>
          </div>
          <div class="col-xs-9">
            <div class="col-xs-12" style="padding:0;">
              <div class="{{field.ColumnName}}" style="padding:0;" *ngIf="field.FieldName == 'ProjectLeaderId' || field.FieldName == 'Trainer1' || field.FieldName == 'Trainer2'">
                <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.FieldName}}" [formControlName]="field.FieldName" [(ngModel)]="Project[field.FieldName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)">
                  <option value="0"> </option>
                  <option *ngFor="let customer of field.Data" value="{{customer.id}}" style="color:black">
                    {{customer.itemName}}
                  </option>
                </select>


              </div>
              <div class="{{field.ColumnName}}" style="padding:0;" *ngIf="field.FieldName == 'UserAccess'">
                <angular2-multiselect [data]="field.Data" id="{{field.FieldName}}" [required]="(field.RequiredField == 'Yes')? 'true':'false'" [formControlName]="field.FieldName"
                                      name="Project.UserAccess" value="Project.UserAccess"
                                      [(ngModel)]="selectedItems"
                                      [settings]="dropdownSetting"
                                      (onSelect)="onItemSelect($event)"
                                      (onDeSelect)="OnItemDeSelect($event)"
                                      (onSelectAll)="onSelectAll($event)"
                                      (onDeSelectAll)="onDeSelectAll($event)">
                </angular2-multiselect>

              </div>
              <div class="{{field.ColumnName}}" style="padding:0;" *ngIf="field.FieldName !== 'ProjectLeaderId' && field.FieldName !== 'Trainer1' && field.FieldName !== 'Trainer2' && field.FieldName !== 'UserAccess'">
                <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.FieldName}}" [formControlName]="field.FieldName" [(ngModel)]="Project[field.FieldName]" *ngIf="  field.ColumnName !== 'ProjectStatus'  && (field.DataType == 'Pick List' || field.DataType == 'System Lookup')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value);FieldChange($event.target.value,field.ColumnName)">

                  <option [disabled]="(field.ColumnName=='ProjectTypeId' && Project.ProjectID > 0)? true : false" *ngFor="let customer of field.Data" value={{customer.DataId}} style="color:black">
                    {{customer.itemName}}
                  </option>
                </select>

                <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.FieldName}}" [formControlName]="field.FieldName" [(ngModel)]="Project[field.FieldName]" *ngIf="field.FieldName === 'ProjectStatus' && (field.DataType == 'Pick List' || field.DataType == 'System Lookup')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="StatusChanged($event.target.value)">

                  <option [disabled]="((field.ColumnName=='ProjectTypeId' && Project.ProjectID > 0))? true : false" *ngFor="let customer of field.Data" value={{customer.DataId}} style="color:black">
                    {{customer.itemName}}
                  </option>
                </select>

              </div>


            </div>
            <div class="Test" style="padding:0;">
              <input value="{{Project[field.FieldName]}}" [(ngModel)]="Project[field.FieldName]" [formControlName]="field.FieldName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (keyup)="FormDataEntered($event.target)" />

              <input value="{{Project[field.FieldName]}}" [(ngModel)]="Project[field.FieldName]" [formControlName]="field.FieldName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'website')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" pattern="([Hh][Tt][Tt][Pp][Ss]?:\/\/)?(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" (keyup)="FormDataEntered($event.target)" />
            </div>
            <div class="{{field.FieldName}}" style="padding:0;">
              <input type="date" class="form-control decreasefieldSize" [formControlName]="field.FieldName" [(ngModel)]="Project[field.FieldName]" *ngIf="(field.DataType == 'Date' ||  field.DataType == 'Date Range' ||  field.DataType == 'Date/Time')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" value="{{Project[field.FieldName]}}" (change)="FormDataEntered($event.target)" />
            </div>
          </div>
        </div>


      </div>
      <div class="col-xs-12" style="padding: 0;margin-top: 0.7%; ">
        <div class="col-xs-3">
        </div>
        <div class="col-xs-9">
          <div class="col-xs-6" style="padding: 0;">
            <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="CloseCreateProject()">Cancel</button>
          </div>
          <div class="col-xs-6" style="padding-right: 0;">
            <button type="submit" class="btn saveButton" style="width:100%;" (click)="SaveProject()" [disabled]="!NewForm.valid">Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="col-xs-12" style="padding:0; height:92%" *ngIf="Project_Steps_Div">
    <div class="col-xs-12 removeCompletePadding">
      <span>Account : {{account.accountname}}</span>

    </div>

    <div class="col-xs-12 removeCompletePadding">
      <span> Project Type : {{Project.ProjectTypeName}}</span>

    </div>

    <div class="col-xs-12 removeCompletePadding">
      <span> Stage : {{SelectedStageName }}</span>

    </div>
    <form #NewStageFormSteps="ngForm">
      <div class="col-xs-12 scroll-c" style="padding:0;height:100%">
        <div class="col-xs-12" style="padding:0;height:100%">
          <div class="col-xs-12" style="padding:0;height:90%">
            <div class="col-xs-12" style="padding:0;margin-top:1%" *ngFor="let item of ProjStepStatus">
              <div class="col-xs-4" style="padding:0 1% 0 2%;font-size:0.8em">
                <label style="margin:8% 0 0 0">{{item.ProjectStepName}}</label>
                <label style="display:none">{{item.ProjectStepId}}</label>
              </div>

              <div class="col-xs-4" style="padding:0 1% 0 0;">
                <select class="form-control" name="Status_{{item.ProjectStepId}}" id="Status_{{item.ProjectStepId}}" value="{{item.ProjectStepName}}" style="padding:0;" required [(ngModel)]="item.ProjectStepStatus" (change)="StepStatusChange(item)">
                  <option value="Open" selected>Open</option>
                  <option value="Complete">Complete</option>
                  <option value="N/A">N/A</option>
                  <option value="Declined">Declined</option>
                </select>
              </div>
              <div class="col-xs-4" style="padding:0 0 0 1%;">
                <input type="date" class="form-control" name="Date_{{item.ProjectStepId}}" id="Date_{{item.ProjectStepId}}" [(ngModel)]="item.ProjectStepCompleteDate" style="padding:0;" #CompleteDate="ngModel" [required]="(item.ProjectStepStatus == 'Complete' || item.ProjectStepStatus == 'N/A')? true : false" (change)="StepStatusDateChange(item)" />
                <!--{{item.ProjectStepCompleteDate}}-->
              </div>
            </div>

          </div>
          <div class="col-xs-12" style="padding:0;height:10%">
            <div class="col-xs-12" style="padding: 0;margin-top: 0.7%; ">
              <div class="col-xs-4">
              </div>
              <div class="col-xs-8">
                <div class="col-xs-6" style="padding: 0;">
                  <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="CancelStep()">Cancel</button>
                </div>
                <div class="col-xs-6" style="padding-right: 0;" *ngIf="ProjStepStatus.length > 0">
                  <button type="submit" class="btn saveButton" style="width:100%;" (click)="SaveStepsStatus()" [disabled]="StepStatusButton">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </form>
  </div>

</app-dialog>


<app-dialog [(visible)]="SaveProjectDialog" id="SaveProjectDialog" *ngIf="SaveProjectDialog">
  <div class="col-xs-12" style="padding:5%">
    <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
    <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
      <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase">PROJECTS</div><br />
      <div class="col-xs-12" style="padding: 0; padding-top: 3%;">Save Changes to Projects ?</div>
    </div>
    <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
      <div class="col-xs-7" style="padding: 0;">
      </div>
      <div class="col-xs-5" style="padding:0;">       
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                  (click)="SaveProjectdata()">
            SAVE
          </button>
        </div>       
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float: right; background-color: white !important; color: black;
          margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1"
          (click)="SaveProjectDialog = false;">Cancel</button>
        </div>
      </div>
    </div>
  </div>
 
</app-dialog>
  <app-dialog [(visible)]="CloseCreateNewWarning" id="CloseCreateNewWarning" *ngIf="CloseCreateNewWarning">
    <div class="col-xs-12">
      <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br /><br />
      <div class="col-xs-8" style="padding: 0;">
        <div class="col-xs-12" style="padding: 0;font-weight:600">Create New Project</div>
        <div class="col-xs-12" style="padding: 0; font-weight: 600">Exit without Saving New Project?</div>
      </div>
      <div class="col-xs-4" style="padding:0;">
        <div class="col-xs-12" style="padding:0">
          <button class="btn" style="background-color: #0098f7 !important;color: white;margin-top:1%" (click)="showCreateProjectDialog = false;CloseCreateNewWarning = false ">
            OK
          </button>

          <button class="btn" style="float:right;background-color: white !important;color: black;margin :1%" (click)="CloseCreateNewWarning = false ">
            Cancel
          </button>
        </div>

      </div>
    </div>
  </app-dialog>

  <app-dialog [(visible)]="showAddEditContact" *ngIf="showAddEditContact" id="addEditContact" [closable]="false" [OutSideClick]="false">
    <editcontact [contactid]="0" [accountId]="account.id" [type]="'Contact'" (sendMsgEvent)="ContactReceiveEvent($event)"></editcontact>
  </app-dialog>

  <app-dialog [(visible)]="showAddEditAccount" *ngIf="showAddEditAccount" id="addEditAccount" [closable]="(action =='EditAccount')? false : true" (visibleChange)="CloseProposalPage($event)" [OutSideClick]="(action =='EditAccount')? false : true">
    <app-add-edit-account *ngIf="action =='EditAccount'" [accountID]="this.account.id" [type]="'Account'" (sendMsgEvent)="ReceiveEvent($event)"></app-add-edit-account>
    <accountorcontactview *ngIf="action=='View'" [accountId]="this.account.id" [type]="'Account'" (btnClickEvent)="btnClickReceived($event)"></accountorcontactview>
  </app-dialog>


  <app-dialog [(visible)]="isSharing" *ngIf="isSharing" id="isSharingDialog" [closable]="false">
    <ownershipsharing [AccId]="account.id" [PageType]="this.tab" (sendMsgEvent)="onCloseEventReceived($event)"></ownershipsharing>
  </app-dialog>


  <app-dialog [(visible)]="notesshowDialog" *ngIf="notesshowDialog" id="notesshowDialog" (visibleChange)="onCloseDialogEventReceived($event)" [CloseButtonColor]="'white'">
    <div class="col-xs-12" style="height: 6%; background-color: rgb(40,62,74); color: white; text-align: center; padding: 0%">
      <div class="col-xs-1" style="padding: 0; text-align: left; padding: 1% 0 0 1%;">
        <img src="../../../assets/img/White Expand View.png" style="height:21px" (click)="ExpandPDF(true)" *ngIf="!DocExpandView" />
        <img src="../../../assets/img/White Collapse View.png" style="height:21px" (click)="ExpandPDF(false)" *ngIf="DocExpandView" />
      </div>
      <div class="col-xs-11" style="padding:1% 0 0 0;height:100%"><label>ACCOUNT DETAILS</label></div>


    </div>
    <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%">

      <app-new-notes style="height:100%"
                     [selectedTabName]="'1'"
                     [conid]="conid"
                     [typ]="typ"
                     [isModal]="false">
      </app-new-notes>

    </div>

  </app-dialog>
