export class Messaging {
  public id: number;
  public fromuser: string;
  public subject: string;
  public date: string;
  public replied: string;
  public message: string;
  public messagefromid: string;
  public receivedid: string;
  public receivednames: string;
  public messagetype: number;
  public deletedmsguserids: string;
  public isreaduserids: string;
  public permanentlydeletedmsguserids: string;
  public readstatus: number;
  public touser: string;

  public CurrentPage: number;
  public PageSize: number;
  public TotalPages: number;
  public Total: number;
}
