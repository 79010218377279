export class QuickSearchModel {
  public searchterm: string;
  public userids: string;
  public startDate: string;
  public endDate: string;
  public Includetype: string;
  public customertype: string;
  public selectedval: string;
  public SearchType: string;
  public IncludeShared: string;
   
}
