import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CustomerService } from './../../services/customer.service';
import { Auditlog } from '../../models/auditlog';
import { DatePipe } from '@angular/common';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { PageComponent } from '../page/page.component';
import { ApplicationComponent } from '../application.component';
import { IMyDpOptions } from 'mydatepicker';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.css']
})
export class AuditLogComponent implements OnInit {
  loginauditloglist: Array<Auditlog> = [];
  datePipe: DatePipe = new DatePipe("en-US");
  audittype = "Login";
  aggridheight;
  public LogincolumnDefs: any[];
  public gridopportunityOptions: GridOptions;
  public accountcontactcolumnDefs: any[];
  public columnDefs: any[];
  showLoginauditTable: boolean = false;
  showAccountContactTable: boolean = false;
  auditLogGridHeight = screen.height * 0.51;

  @Input()
  selectedtype: string;  

  @Input()
  UserIds: string;  
  
  @Input()
  pageSize: string = "200";

  @Input()
  public startDateVal: any
  @Input()
  public endDateVal: any

  @Output()
  sendMsgEvent = new EventEmitter<string>();

  currentPage: number = 1;
  startrow: number = 1;
  noofRows: number = 1;
  totalnumberofrows: number = 1;
  totalnumberofPages: number = 1;
  //public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  //public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  //public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");

  //public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  //public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  //public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  public sideBar;
  private gridApi;
  constructor(private paginationcop: PageComponent, private customerService: CustomerService, public applicationComponent: ApplicationComponent) { }

  ngOnInit() {
    this.OpportunitieshistoryinitializeAgGrid();
    this.showLoginauditTable = true;   
    this.LoadLoginAuditLog('Login', this.UserIds, this.currentPage, this.pageSize, this.startDateVal, this.endDateVal, false);
  }

 

  OpportunitieshistoryinitializeAgGrid() {
    this.gridopportunityOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000,enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',

    };
    this.opportunitiesscreateColumnDefs("");
    this.gridopportunityOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
  }

  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  autoSizeAll() {

  }

  opportunitiesscreateColumnDefs(columnSelections: string) {
    this.columnDefs =this.LogincolumnDefs = [
      { field: 'documentname', headerName: 'Audit Type', hide: false ,width:100},
      { field: 'action', headerName: 'Action', hide: false, width: 300},      
      { field: 'username', headerName: ' User Name', hide: false, width: 200},
      { field: 'LoggedIn', headerName: 'Login-Time', hide: false, width: 250},
      { field: 'LoggedOut', headerName: 'Logout-Time', hide: false, width: 250},
      { field: 'TimeSpent', headerName: 'Time Used', hide: false, width: 150},
      { field: 'LogOutStatus', headerName: 'LogOut Status', hide: false, width: 200},
    ];
    this.accountcontactcolumnDefs = [
      { field: 'documentname', headerName: 'Audit Type', hide: false, width: 100 },
      { field: 'action', headerName: 'Action', hide: false, width: 300 },
      { field: 'username', headerName: ' User Name', hide: false, width: 200 },
      { field: 'dateofviewed', headerName: ' Created Date', hide: false, width: 250 },
    ];
  }
  Audit(val, startDateVal, endDateVal) {
     
    this.audittype = val;
    this.currentPage= 1;
    this.startrow= 1;
    this.noofRows= 1;
    this.totalnumberofrows= 1;
    this.totalnumberofPages = 1;

    if (val == "Account" || val == "Contact" || val == "Leads" || val == "Opportunity" || val == "TaskList" || val == "InternalMessaging" || val == "Campaigns" || val == "Proposals" || val == "Tickets" || val == "Customers") {
      this.columnDefs = this.accountcontactcolumnDefs;
    } else {
      this.columnDefs = this.LogincolumnDefs
    }

    this.LoadLoginAuditLog(val, this.UserIds, this.currentPage, this.pageSize, startDateVal, endDateVal, false);
    this.sendMsgEvent.emit(val)
  }
  LoadLoginAuditLog(type, UserIds, startrow, endrow, startdate, enddate, pageSizeChanged) {
    if (UserIds == "") {
      this.loginauditloglist = [];
    } else {
      if (!pageSizeChanged) {
        this.customerService.GetAllDocumentAuditlogList(0, type, UserIds, startrow, endrow, this.selectedtype, startdate, enddate).then((opportunityauditloglist) => {
          opportunityauditloglist.forEach(document => {
            document.dateofviewed = this.datePipe.transform(document.dateofviewed, "MM-dd-yyyy");
          });
          this.loginauditloglist = opportunityauditloglist;
        });
      }
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.gridApi.paginationSetPageSize(Number(endrow));
      }, 500));
    }
    
  }

  clearAudit() {
    this.loginauditloglist = [];
  }

  
}
