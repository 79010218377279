import { Component, OnInit, Output, Input,EventEmitter } from '@angular/core';
import { ReleaseNotes } from 'src/app/models/releaseNotes';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
declare var $: any;
import { ApplicationComponent } from '../application.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.css']
})
export class ReleaseNotesComponent implements OnInit {
  @Input()
  From: string;
  releaseNotesDiv: boolean = true;
  AddreleaseNotesDiv: boolean = false;
  ReadMoreDiv: boolean = false;
  releaseNotesListDiv: boolean = false;
  releaseNotesmodel: ReleaseNotes = new ReleaseNotes();
  selectedNote: ReleaseNotes;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  currentuser: any;
  releasenotesArray: Array<ReleaseNotes> = [];
  datePipe: DatePipe = new DatePipe("en-US");
  notesform: FormGroup;
  constructor(public applicationComponent: ApplicationComponent,private userservice: UserService, private toastr: ToastrService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.getAllReleaseNotes();
    this.notesform = this.getAccountForm();
  }

  getAccountForm(): FormGroup {

    return new FormGroup({

      'createdDate': new FormControl(this.releaseNotesmodel.createdDate, Validators.required),
      'releaseNotes': new FormControl(this.releaseNotesmodel.releaseNotes, Validators.required),
      'versionNumber': new FormControl(this.releaseNotesmodel.versionNumber, Validators.required),
      'Subject': new FormControl(this.releaseNotesmodel.Subject, Validators.required),
      
    });


  }

  ReleaseNotesShow() {
    //this.releaseNotesDiv = true;
    //this.aboutus = false;
  }
  ShowContactus() {
    //this.ContactUs = true;
    //this.aboutus = false;

  }
  close() { }
  SaveReleaseNotes() {
    this.userservice.SaveReleaseNotes(this.releaseNotesmodel).then(result => {
      this.getAllReleaseNotes();
      this.AddreleaseNotesDiv = false;
      this.releaseNotesListDiv = true;
    });
  } 
  getAllReleaseNotes() {
    this.userservice.getAllReleaseNotes().then(result => {
      result.forEach(element => {
        element.releaseNotes = element.releaseNotes.replaceAll('\n','<br />') 
      });
      this.releasenotesArray = result;
    });
  }

  delete(selectedNoteId) {
    this.userservice.deleteReleaseNotes(selectedNoteId).then(result => {
      this.getAllReleaseNotes();
      this.AddreleaseNotesDiv = false;
      this.releaseNotesListDiv = true;
    });
  }

  closeReleaseNotesDiv() {
    //this.releaseNotesDiv = false;
    this.applicationComponent.activateMainButtons('dashboards');
    this.applicationComponent.ShowConfigurationDialog = false;
    /*this.sendMsgEvent.emit("ReleaseNotes");*/
  }

  ReadMore(SelectedNote) {
    $(".releasebuttons").removeClass("activereleasenotes");
    $("#release" + SelectedNote.releaseNoteId).addClass("activereleasenotes");
    this.ReadMoreDiv = true;
    this.AddreleaseNotesDiv = false;
    this.releaseNotesListDiv = false;
    this.selectedNote = SelectedNote;
   
    setTimeout(function () {
      $('#ReadMoreDiv .dialog').attr('style', 'width: 50% !important;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 30%;overflow: visible;height: 84% !important;top: 10%;left: 48%;right: 0%;padding:0;');
    }, 100);
  }

  AddNewReleaseNotes() {
    this.AddreleaseNotesDiv = true;
    this.ReadMoreDiv=false;
    this.releaseNotesListDiv = false;
    this.releaseNotesmodel = new ReleaseNotes();
    
  }
  EditNotes(selectedNoteId) {
    this.AddreleaseNotesDiv = true;
    this.applicationComponent.Standard3dotUi('#AddreleaseNotesDiv .dialog');
    this.releaseNotesListDiv = false;
    this.releasenotesArray.forEach(element => {
      if (element.releaseNoteId == selectedNoteId) {
        element.createdDate = this.datePipe.transform(element.createdDate, "yyyy-MM-dd");
        this.releaseNotesmodel = element;
      }
    });
  }

  
  cancelReleaseNotes() {
    this.AddreleaseNotesDiv = false;
    this.releaseNotesListDiv = true;
  }
  Return(val) {
    this.ReadMoreDiv = false;
    this.AddreleaseNotesDiv = false;
    this.releaseNotesListDiv = true;
  }
}
