export class Customers {
    public id: number;
  public last: string;
  public accountname: string;
  public color: string;
    public first: string;
    public middle: string;
    public title: string;
    public speciality: string;
    public practice: string;
    public city: string;
    public state: string;
    public zip: string;
    public email: string;
    public phone: string;
    public address1: string;
    public address2: string;
    public type: string;
    public owner: string;
    public cell: string;
    public DOB: string;
    public college: string;
    public collegeyeargrad: number;
    public medschool: string;
    public medschoolyeargrad: number;
    public residency: string;
    public residencyyeargrad: number;  
    public fellowship: string;
    public fellowshipyeargrad: number;
    public salesoriginatedfrom: string;
    public multiplelocations: string;
    public practiceid: number;
    public office: string;
    public month: string;
    public day: string;
    public ext: string;
    public subspeciality: string;
}
