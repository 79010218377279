<div class="container" style="height:100%;padding:0;">
  <div class="col-xs-12" style="height:6%">
    <label style="margin-top: 0.5%;font-size: 15px;">System Registration | Licensing | Billing / Registration - Licensing - Billing </label>
  </div>
  <div class="col-xs-12" style="padding:0;height:94%">
    <div class="col-xs-12" style="padding:0;height:8%">
      <div  class="tabset" style="padding-left:1%">
        <input type="radio" name="tabset" id="tab1" value="tab1" aria-controls="bano" [(ngModel)]="selectedMenuRadio" checked />
        <label for="tab1" style="text-align:center;width:20%" >Company Registration</label>
        <input type="radio" name="tabset" id="tab2" value="tab2" [(ngModel)]="selectedMenuRadio" aria-controls="marzen">
        <label for="tab2" style="text-align:center;width:19.7%" >Licensing -Plan Type</label>
        <input type="radio" name="tabset" id="tab3" value="tab3" [(ngModel)]="selectedMenuRadio" aria-controls="rauchbier" style="margin-left: 1px">
        <label for="tab3" style="text-align:center;width:19.7%" >Billing Information</label>
        <input type="radio" name="tabset" id="tab4" value="tab4" [(ngModel)]="selectedMenuRadio" aria-controls="tej" style="margin-left: 1px">
        <label for="tab4" style="text-align:center;width:19.7%" ><label> </label></label> 
        <input type="radio" name="tabset" id="tab5" value="tab5" [(ngModel)]="selectedMenuRadio" aria-controls="banoo" />
        <label for="tab5" style="text-align:center;width:19.5%" > Zendesk Configuration</label>
      </div>
    </div>
    <div class="col-xs-12" style="padding:0;height:92%;background: #f4f4f4;"></div>
  </div>
</div>
