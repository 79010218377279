export class ProjectFields{
  public ProjectFieldId: number;
  public FieldName: string;
  public OldFname: string;
  public TypeOfField: string;
  public DisplayAs: string;
  public RequiredField: string;
  public DataType: string;
  public PickListEditable: string;
  public DataFormat: string;
  public MaxLimit: number;
  public Description: string;
  public SortOrder: string;
  public Userid: number;
  public CreatedDate: string;
  public isdeleted: number;
  public ColumnName: string;
  public FieldDataOrderBy;
}

export class ProjectStages {
  public ProjectStageID: number;
  public ProjectStageName: string;
  public ProjectTypeID: number;
  public ProjectTypeName: string;
  public SortOrder: string;
  public isdeleted: number;
  public UserId: number;
  public createdDate: string;
}


export class ProjectSteps{
  public ProjectStepID: number;
  public ProjectStepName: string;
  public ProjectTypeID: number;
  public ProjectTypeName: string;
  public ProjectStageId: number;
  public ProjectStageName: string;
  public Description: string;
  public SortOrder: number;
  public Status1: string;
  public Status2: string;
  public Status3: string;
  public Status4: string;
  public Status5: string;

  public isdeleted: number;
  public UserId: number;
  public createdDate: string;
}


export class Projects {

  public ProjectID: number;
  public ProjectTypeId: number;
  public ProjectTypeName: string;
  public ProjectLeaderId: number;
  public IncompleteStatusCount: number;
  public ProjectLeaderName: string;
  public TargetGoLiveDate: string;
  public OnsiteTraining: number;
  public OnsiteTrainingName: string;
  public OnsiteTrainingHours: number;
  public NumberofTrainers: number;
  public Trainer1: number;
  public Trainer1Name: string;
  public Trainer2: number;
  public Trainer2Name: string;
  public OnsiteTrainingDate1From: string;
  public OnsiteTrainingDate1To: string;
  public OnsiteDateStatus1: number;
  public OnsiteDateStatus1Name: string;
  public OnsiteTrainingDate2From: string;
  public OnsiteTrainingDate2To: string;
  public OnsiteDateStatus2: number;
  public OnsiteDateStatus2Name: string;
  public RemoteTraining: number;
  public RemoteTrainingName: string;
  public RemoteTrainingHours: number;
  public RemoteTrainingDate1From: string;
  public RemoteTrainingDate1To: string;
  public RemoteDateStatus1: number;
  public RemoteDateStatus1Name: string;
  public RemoteTrainingDate2From: string;
  public RemoteTrainingDate2To: string;
  public RemoteDateStatus2: number;
  public RemoteDateStatus2Name: string;
  public UserAccess: string;
  public UserAccessName: string;
  public Notes: string
  public AdditionalDetails: string;
  public ProjectStatus: number;
  public ProjectStatusName: string;
  public isdeleted: number;
  public UserId: number;
  public CreatedDate: string;
  public AccountOrContactId: number;
  public AccountName: string;
  public AccountOrContactType: string;
  public fieldvalues: string;
  public UserName: string;
  public TotalProjects: number;
  public StageAddedCount: number;
  public ProjectDescription: string;
}


export class ProjectStepStatusModel {
  public StepStatusId: number;
  public ProjectId: number;
  public ProjectStepId: number;
  public ProjectStepName: string;
  public ProjectStageId: number;
  public ProjectStageName: string;
  public ProjectTypeId: number;
  public ProjectStepStatus: string;
  public ProjectStepCompleteDate: string;
  public UserId: number;
  public CreatedDate: string;
  public isdeleted: number;


}

export class ProjectForecastViewModel {
  public ProjectId: number;
  public accountorContactId: number;
  public accountorContactType: string;
  public accountname: string;
  public Initial_Call: string;
  public IT_Call: string;
  public Hardware_Arrived: string;
  public Hardware_Setup: string;
  public Receive_Paperwork: string;
  public Knowledge_Base: string;
  public Schedule_Training: string;
  public Book_Travel: string;
  public Training: string;
  public Post_Training: string;
  public Project_Type: string;
}
