export class AccountFields {
  public id: number;
  public TableName: string;
  public FieldName: string;
  public TypeOfField : string;
  public DisplayAs: string;
  public RequiredField: string;
  public DataType: string;
  public PickListEditable: string;
  public DataFormat: string;
  public MaxLimit: string;
  public Description: string;
  public SortOrder: string;
  public ColumnName: string;
  public ModelData: any;
  public OldOrNew: string;

  public FieldDataOrderBy
}
