<div class="col-xs-12" style="padding: 0; height: 100%;">
    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ShowDocumentNameDiv">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Document Name</span>

      </h4>
      <div class="col-xs-6" style="padding:0;height:93%">
        <div class="col-xs-12" style="padding:0;height:6%">
          <img src="../../../assets/img/Create New Document Name.png" (click)="ShowAddDocName = true;addCharge()" style="height:30px" />
        </div>
        <div class="col-xs-12" style="padding:0;height:95%">
          <ag-grid-angular style="height:94%" class="ag-theme-balham"
                           [gridOptions]="gridOptionsDM "
                           [columnDefs]="documentscolumnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="userdocumentlist"
                           [context]="context"
                            [rowDragManaged]="true"
                           [suppressMoveWhenRowDragging]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           [frameworkComponents]="frameworkComponents"
                           (gridReady)="onAgGridReady($event)"
                           (rowDragEnd)="onRowDragEndDocumentName($event)">
          </ag-grid-angular>
        </div>
      </div>
      <div class="col-xs-6" style="padding:0;height:93%">
        <div class="col-xs-12" style="padding:0;height:6%"></div>
        <div class="col-xs-12" style="padding:0;height:93%" *ngIf="ShowAddDocName">

          <div class="col-xs-12" style="height:16%" *ngFor="let charge of documentlist; let myIndex=index">
            <div class="col-xs-12" style="padding:0.5% 0 0 0;">
              <div class="col-xs-3" style="padding:0;">Document Name</div>
              <div class="col-xs-8" style="padding:0;">
                <div class="col-xs-12" style="padding:0;" id="myid">
                  <input type="text" class="form-control" style="width: 100%;" [(ngModel)]="charge.documentname" />
                </div>

              </div>
            </div>


          </div>

          <div style="height: 10%;" class="col-xs-12">



            <div class="col-xs-12" style="padding:0;height:100%">
              <div class="col-xs-8"></div>
              <div class="col-xs-4">
                <button class="btn btn-long btn-default" style="width:45% !important;" (click)="CancelDocuments()">Cancel</button>
                <button type="submit" class="btn saveButton" (click)="SaveDocuments()" style="width: 49%;margin-right:4%">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ChartTabDiv">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Chart Tab Description</span>

      </h4>
      <div class="col-xs-6" style="padding:0;height:93%">
   
        <div class="col-xs-12" style="padding:0;height:99%">
          <ag-grid-angular style="height:94%" class="ag-theme-balham"
                           [gridOptions]="ChartTabsGridOptions "
                           [columnDefs]="ChartTabcolumnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="ChartTabs"
                           [context]="context"
                           [rowDragManaged]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (rowDragEnd)="onRowDragEnd($event)"
                           (sortChanged)="printSortStateToConsole($event)"
                           (selectionChanged)="onChargeTabSelectionChanged($event)"
                           (gridReady)="onAgGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
      <div class="col-xs-6" style="padding:0;height:93%">
        <div class="col-xs-12" style="padding:0;height:6%"></div>
        <div class="col-xs-12" style="padding:0;height:93%" *ngIf="ShowAddChartTab">

          <div class="col-xs-12" style="height:30%">
            <div class="col-xs-12" style="padding:0.5% 0 0 0;">
              <div class="col-xs-3" style="padding:0;">Chart Tab Description</div>
              <div class="col-xs-8" style="padding:0;">
                <div class="col-xs-12" style="padding:0;">
                  <input type="text" class="form-control" style="width: 100%;" [(ngModel)]="ChartTab.ChartTabDescription" />
                </div>

              </div>
            </div>


            <div class="col-xs-12" style="padding:0.5% 0 0 0;">
              <div class="col-xs-3" style="padding:0;">Chart Tab Order</div>
              <div class="col-xs-8" style="padding:0;">
                <div class="col-xs-12" style="padding:0;">
                  <input type="text" class="form-control" style="width: 100%;" [(ngModel)]="ChartTab.ChartTabOrder" />
                </div>

              </div>
            </div>

            <div class="col-xs-12" style="padding:0.5% 0 0 0;">
              <div class="col-xs-3" style="padding:0;">Chart Tab Editable</div>
              <div class="col-xs-8" style="padding:0;">
                <div class="col-xs-12" style="padding:0;">
                  <!--<input type="text" class="form-control" style="width: 100%;" [(ngModel)]="ChartTab.Editable" />-->

                  <select class="form-control" [(ngModel)]="ChartTab.Editable">
                    <option value="1">Yes</option>
                    <option value="0">No</option>

                  </select>
                </div>

              </div>
            </div>


          </div>

          <div style="height: 10%;" class="col-xs-12">



            <div class="col-xs-12" style="padding:0;height:100%">
              <div class="col-xs-8"></div>
              <div class="col-xs-4">
                <button class="btn btn-long btn-default" style="width:45% !important;" (click)="CancelDocuments()">Cancel</button>
                <button type="submit" class="btn saveButton" (click)="SaveChartTab()" style="width: 49%;margin-right:4%">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
 
</div>
