import { Component, OnInit, Optional, ViewChild, Output, EventEmitter, Input, ElementRef} from '@angular/core';
import { CustomerService } from './../../services/customer.service';
import { ApplicationComponent } from '../application.component';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import * as moment from 'moment'
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { ForecastDataModel, RevenueModel } from '../../models/dropdowntype';
import { Column, GridOptions } from 'ag-grid';
import { Opportunities } from '../../models/opportunities';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';

declare var require: any;
declare var $: any;
@Component({
  selector: 'forecasting',
  templateUrl: './forecasting.component.html',
  styleUrls: ['./forecasting.component.css']
})
export class ForecastingComponent implements OnInit {
  selectedItems = [];
  currentuser: any;
  dropdownList = [];
  calanderonoff: boolean = false;
  pageSize = "1000";
  private gridApi;
  CurrentDate: string;
  CurrentDatenew: string;
  SelectedBox = 'FSMonth';
  UserIds: string;
  StartDate: string = "";
  EndDate: string = "";
  public gridOptions: GridOptions;
  datePipe: DatePipe = new DatePipe("en-US");
  ForeCastBoxData: ForecastDataModel = new ForecastDataModel();
  public columnDefs: any[];
  Allopportunities: Opportunities[];
  SelectedBoxTitle: string = 'Monthly Sales Forecast';
  Ids = []; public yy: number;
  AccConIds: string;  opportunityids = [];
  public years: number[] = [];
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal;
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;
  public ForecastSalesECDYear = new Date().getFullYear();
  public ForecastRRECDYear = new Date().getFullYear();
  public SalesWonYear = new Date().getFullYear();
  public RRWonYear = new Date().getFullYear();
  public SalesLostYear = new Date().getFullYear();
  public RRLostYear = new Date().getFullYear();
  colSelections: string;
  public sideBar;
  public context;
  isPended: boolean = true;
  isexpand: boolean = false;
   RRCommission: number;
  isDisplayCommissionEstimator: boolean = false;
  SelectOpportunityWarning: boolean = false;
  SalesCommission: number;
  opportunities: Opportunities = new Opportunities();
  constructor(public applicationComponent: ApplicationComponent, private customerService: CustomerService, private opprtunityservice: OpprtunitiesService, private router: Router) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    this.currentDate();
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {
      this.applicationComponent.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
      ];
      localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
      $("#userids").val(this.currentuser.id);
      this.applicationComponent.ExpandSelectedMenu('SalesForecast');
    }

    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, 1, 0, 0).then((Owner) => {
      Owner.forEach(Owner => {
      });

      this.dropdownList = Owner;
    });
  }
  getYear() {
    var today = new Date();
    this.yy = today.getFullYear();
    for (var i = (this.yy-4); i <= this.yy; i++) {
      this.years.push(i);
    }
  }
  ngOnInit() {
    this.getBoxData();
    this.getYear();
    this.initializeAgGrid();
    this.applicationComponent.selectedbuttonText = "Sales Forecast";
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.context = this;
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 39).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        //  this.gridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }
      this.colSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(this.colSelections);
    });

    
    let selfObj = this;
    setTimeout(function () {
      selfObj.BoxClick('FSMonth', 'Monthly Sales Forecast', 'FSSubMonth');
    }, 400)
    
  }
  createColumnDefs(columnSelections) {
    let selfobj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({
            field: 'owner', headerName: 'Owner', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "datecompleted") {
          this.columnDefs.push({
            field: 'actualclosedate', headerName: 'Date Closed', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), 
            cellRenderer: (params) => {
              if (params.data.status == '108') {
                return "";
              } else {
                return moment(params.value).format('MM-DD-YYYY');
              }
            }
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "isPended") {
          this.columnDefs.push({
            field: 'isPended', headerName: 'Pended', hide: false, cellStyle: function (params) {
              { return { color: '#FF0000' }; }
            },  cellRenderer: function (params) {
              if (params.data.isPended == '1' || params.data.isPended == 1) { return 'Pended'; }
            }
          });
        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, 
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    }
    else {
      if (this.SelectedBoxTitle == 'Monthly Sales Forecast' || this.SelectedBoxTitle == 'Annual Sales Forecast' || this.SelectedBoxTitle == 'Monthly RR Forecast' || this.SelectedBoxTitle == 'Annual RR Forecast') {
        this.columnDefs = [
          {
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
          },
          {
            field: 'practicename', headerName: 'Account Name', hide: false
          },
          {
            field: 'estimatedclosedate', headerName: 'ECD', hide: false
          },
          {
            field: 'isPended', headerName: 'Pended', hide: false, cellStyle: function (params) {
              { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              if (params.data.isPended == '1' || params.data.isPended == 1) { return 'Pended'; }
            }
          },
          {
            field: 'dollers', headerName: 'Price', hide: false
          },
          {
            field: 'RR', headerName: 'Monthly', hide: false
          },
          {
            field: 'PCname', headerName: 'Closing Percentage', hide: false
          },
          {
            field: 'firstName', headerName: 'First Name', hide: false,
          },
          {
            field: 'lastName', headerName: 'Last Name', hide: false
          },
          {
            field: 'itemName', headerName: 'Proposal Item', hide: false
          },
          {
            field: 'ProposalDescription', headerName: 'Proposal Description', hide: false
          },
          {
            field: 'LeadDescription', headerName: 'Lead Description', hide: false
          },
          {
            field: 'statusName', headerName: 'Status', hide: false, cellRenderer: (params) => {
              if (params.data.status == '108') {
                return 'Open';
              }
              else if (params.data.status == '109') {
                return 'Closed-Won';
              } else if (params.data.status == '110') {
                return 'Closed-Lost';
              } else if (params.data.status == '111') {
                return 'Closed-Pending';
              }

            }
          },
          {
            field: 'owner', headerName: 'Owner', hide: false
          },

          {
            field: 'datecreated', headerName: 'Date Created', hide: false
          },        
          
     
          {
            field: 'actualclosedate', headerName: 'Date Closed', cellRenderer: (params) => {
              if (params.data.status == '108') {
                return "";
              } else {
                return params.data.datecreated;
              }
            }
          },
          {
            field: 'drsName', headerName: '# Drs', hide: true
          },  //true starts here
          {
            field: 'midlevel', headerName: '# Mid Levels', hide: true
          },
          {
            field: 'leadfrom', headerName: 'Lead From', hide: true
          },
         
          {
            field: 'actualclosedate', headerName: 'Act.Close', hide: true
          },
        ];
      } else {
        this.columnDefs = [
          {
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
          },
          {
            field: 'practicename', headerName: 'Account Name', hide: false
          },
          {
            field: 'actualclosedate', headerName: 'Date Closed', cellRenderer: (params) => {
              if (params.data.status == '108') {
                return "";
              } else {
                return params.data.datecreated;
              }
            }
          },
          {
            field: 'dollers', headerName: 'Price', hide: false
          },
          {
            field: 'RR', headerName: 'Monthly', hide: false
          },
          {
            field: 'itemName', headerName: 'Proposal Item', hide: false
          },
          {
            field: 'ProposalDescription', headerName: 'Proposal Description', hide: false
          },
          {
            field: 'firstName', headerName: 'First Name', hide: false,
          },
          {
            field: 'lastName', headerName: 'Last Name', hide: false
          },
          {
            field: 'estimatedclosedate', headerName: 'ECD', hide: false
          },
          {
            field: 'PCname', headerName: 'Closing Percentage', hide: false
          },
          {
            field: 'LeadDescription', headerName: 'Lead Description', hide: false
          },
          {
            field: 'statusName', headerName: 'Status', hide: false, cellRenderer: (params) => {
              if (params.data.status == '108') {
                return 'Open';
              }
              else if (params.data.status == '109') {
                return 'Closed-Won';
              } else if (params.data.status == '110') {
                return 'Closed-Lost';
              } else if (params.data.status == '111') {
                return 'Closed-Pending';
              }

            }
          },
          {
            field: 'owner', headerName: 'Owner', hide: false
          },
          {
            field: 'datecreated', headerName: 'Date Created', hide: false
          },
          {
            field: 'drsName', headerName: '# Drs', hide: true
          },  //true starts here
          {
            field: 'midlevel', headerName: '# Mid Levels', hide: true
          },
          {
            field: 'leadfrom', headerName: 'Lead From', hide: true
          },
         
          {
            field: 'actualclosedate', headerName: 'Act.Close', hide: true
          },
        ];
      }

    }
  }

  //ForecastSalesECDMonth() {
  //  this.opprtunityservice.GetAllOpportunitiesList("Open Opportunities", $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, null, this.currentuser.id,true).then
  //  ((allopportunities) => {
  //    allopportunities.forEach((customer) => {
  //      customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
  //      customer.actualclosedate = this.datePipe.transform(customer.actualclosedate, "MM-dd-yyyy");
  //      customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
  //    });


  //  });
  //}
  GetOpportunitiesForForecast(Status, StartDate, endDate) {
    this.Allopportunities = [];
    this.opprtunityservice.GetOpportunitiesForForecast($("#userids").val(), Status, StartDate, endDate, this.isPended).then
      ((allopportunities) => {        
        allopportunities.forEach((customer) => {
          customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
          customer.actualclosedate = this.datePipe.transform(customer.actualclosedate, "MM-dd-yyyy");
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
        });       
        this.Allopportunities = allopportunities;
      
        switch (this.SelectedBoxTitle) {
          case 'Monthly Sales Forecast': {
            if (Status == "Open") {

              this.ForeCastBoxData.ForeCastSalesMonth = this.SumOfArrayValues(allopportunities.map(s => s.dollers));
            }
            if (Status == "Closed-Lost") {
              this.ForeCastBoxData.SalesLostMonth = this.SumOfArrayValues(allopportunities.map(s => s.dollers))
            }
            if (Status == "Closed-Lost") {
              this.ForeCastBoxData.RRLostMonth = this.SumOfArrayValues(allopportunities.map(s => (s.RR)))
            }
              break;
            }
         
          case 'Monthly RR Forecast': {
            if (Status == "Open") {
              this.ForeCastBoxData.ForeCastRRMonth = this.SumOfArrayValues(allopportunities.map(s => (s.RR)))
            }
              break;
            }
          
          case 'Monthly Sales-Won': {
            if (Status == "Open" || Status =="Closed-Won") {
              this.ForeCastBoxData.SalesWonMonth = this.SumOfArrayValues(allopportunities.map(s => (s.dollers)))
            }
              break;
            }
          
          case 'Monthly RR-Won': {
            if (Status == "Open" || Status =="Closed-Won") {
              this.ForeCastBoxData.RRWonMonth = this.SumOfArrayValues(allopportunities.map(s => (s.RR)))
            }
              break;
            }
           
          case 'Monthly Sales-Lost': {
            if (Status == "Closed-Lost") {
              this.ForeCastBoxData.SalesLostMonth = this.SumOfArrayValues(allopportunities.map(s => (s.dollers)))
            }
              break;
            }           
          case 'Monthly RR-Lost': {
            if (Status == "Closed-Lost") {
              this.ForeCastBoxData.RRLostMonth = this.SumOfArrayValues(allopportunities.map(s => (s.RR)))
            }
              break;
          }

          case 'Annual Sales Forecast': {
            if (Status == "Open") {
              this.ForeCastBoxData.ForeCastSalesYear = this.SumOfArrayValues(allopportunities.map(s => (s.dollers)))
            }
            break;
          }

          case 'Annual RR Forecast': {
            if (Status == "Open") {
              this.ForeCastBoxData.ForecastRRYear = this.SumOfArrayValues(allopportunities.map(s => (s.RR)))
            }
            break;
          }
          case 'Annual Sales Won': {
            if (Status == "Open" || Status == "Closed-Lost" || Status == "Closed-Won") {
            
              this.ForeCastBoxData.SalesWonYear = this.SumOfArrayValues(allopportunities.map(s => (s.dollers)))
            }
            break;
          }
          case 'Annual RR Won': {
            if (Status == "Open" || Status == "Closed-Lost" || Status =="Closed-Won") {
              this.ForeCastBoxData.RRWonYear = this.SumOfArrayValues(allopportunities.map(s => (s.RR)))
            }
            break;
          }
            
            
        }

      });

   
  }

  SumOfArrayValues(Array) {
    var sum = 0;
    for (var i = 0; i < Array.length; i++) {
      if (Array[i] != "") {
        sum += parseFloat(Array[i].replace(",", ""));
      }
    }
    //var sum = Array.reduce((acc, cur) => acc + cur, 0);
    return sum;
  }
  BoxMonthChange(value, SelectedBox) {
    this.SelectedBoxTitle = SelectedBox;

    switch (this.SelectedBoxTitle) {
      case 'Monthly Sales Forecast': 
      case  'Monthly RR Forecast': {
        var date = new Date(value);
        var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
        var StartDate = moment(firstDay).format("YYYY-MM-DD");
        var EndDate = moment(lastDay).format("YYYY-MM-DD");
        this.GetOpportunitiesForForecast('Open', StartDate, EndDate);
        break;
      }
      case 'Annual Sales Forecast' : 
      case 'Monthly RR-Lost': {
        var date = new Date(value);
        var firstDay = new Date(value, 0, 1);
        var lastDay = new Date(value, 11, 31);
        var StartDate = moment(firstDay).format("YYYY-MM-DD");
        var EndDate = moment(lastDay).format("YYYY-MM-DD");
        this.GetOpportunitiesForForecast('Open', StartDate, EndDate);
        
        break;
      }
     
      case 'Monthly Sales-Won':
      case 'Monthly RR-Won': {
        var date = new Date(value);
        var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
        var StartDate = moment(firstDay).format("YYYY-MM-DD");
        var EndDate = moment(lastDay).format("YYYY-MM-DD");
        this.GetOpportunitiesForForecast('Closed-Won', StartDate, EndDate);
        break;
      }
      case 'Annual Sales Won':
      case 'Monthly RR-Lost': {
        var firstDay = new Date(value, 0, 1);
        var lastDay = new Date(value, 11, 31);
        var StartDate = moment(firstDay).format("YYYY-MM-DD");
        var EndDate = moment(lastDay).format("YYYY-MM-DD");
        this.GetOpportunitiesForForecast('Closed-Won', StartDate, EndDate);
        break;
      }
      case 'Monthly Sales-Lost':
      case 'Monthly RR-Lost': {
        var date = new Date(value);
        var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
        var StartDate = moment(firstDay).format("YYYY-MM-DD");
        var EndDate = moment(lastDay).format("YYYY-MM-DD");
        this.GetOpportunitiesForForecast('Closed-Lost', StartDate, EndDate);
        break;
      }

      case 'Annual Sales Lost':
      case 'Monthly RR-Lost': {
        var firstDay = new Date(value, 0, 1);
        var lastDay = new Date(value, 11, 31);
        var StartDate = moment(firstDay).format("YYYY-MM-DD");
        var EndDate = moment(lastDay).format("YYYY-MM-DD");
        this.GetOpportunitiesForForecast('Closed-Lost', StartDate, EndDate);
        break;
      }
      
    }
  }
  

  currentDate() {
    var now = new Date();
    var mm = now.getMonth() + 1;
    var mon = '';
    if (mm < 10) {
      mon = '0' + mm;
      this.CurrentDate = now.getFullYear() + '-' + mon;
    }
    else {
      this.CurrentDate = now.getFullYear() + '-' + mm;
     
    }

  }
  UserlistDataReceived(outProps: OutputPropsUserList) {
    this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
    if (outProps.thirdDrpValue != undefined) {
      if (outProps.thirdDrpValue == '718') { this.isPended = false; }
      else { this.isPended = true; }
    }
    if (outProps.finalitems != "") {
      $("#userids").val(outProps.finalitems);
      this.getBoxData();
      var now = new Date();
      var mm = now.getMonth() + 1;
      var dd = now.getDay();
      var mon = '';
      if (mm < 10) {
        mon = '0' + mm;
        this.CurrentDatenew = now.getFullYear() + '-' + mon + '-' + dd;
      }
      else {
        this.CurrentDatenew = now.getFullYear() + '-' + mm + '-' + dd;
      }
      switch (this.SelectedBoxTitle) {

        case 'Monthly Sales Forecast': {
          this.BoxClick('FSMonth', 'Monthly Sales Forecast', 'FSSubMonth');
          break;
        }
        case 'Monthly RR Forecast': {
          this.BoxClick('FRRMonth', 'Monthly RR Forecast', 'FSSubMonth');
          break;
        }
        case 'Annual Sales Forecast': {
          this.BoxClick('FSYear', 'Annual Sales Forecast', 'FSSubMonth');
          break;
        }
        case 'Monthly RR-Lost': {
          this.BoxClick('FRRYear', 'Monthly RR-Lost', 'FSSubMonth');
          break;
        }

        case 'Monthly Sales-Won': {
          this.BoxClick('SWMonth', 'Monthly Sales-Won', 'FSSubMonth');
          break;
        }
        case 'Monthly RR-Won': {
          this.BoxClick('RRWMonth', 'Monthly RR-Won', 'FSSubMonth');
          break;
        }
        case 'Annual Sales Won': {
          this.BoxClick('SWYear', 'Annual Sales Won', 'FSSubMonth');
          break;
        }
        case 'Monthly RR-Lost': {
          this.BoxClick('RRWYear', 'Monthly RR-Lost', 'FSSubMonth')
          break;
        }
        case 'Monthly Sales-Lost': {
          this.BoxClick('SLMonth', 'Monthly Sales-Lost', 'FSSubMonth')
          break;
        }
        case 'Monthly RR-Lost': {
          this.BoxClick('RRLMonth', 'Monthly RR-Lost', 'FSSubMonth')
          break;
        }

        case 'Annual Sales Lost': {
          this.BoxClick('SLYear', 'Annual Sales Lost', 'FSSubMonth');
          break;
        }
        case 'Monthly RR-Lost': {
          this.BoxClick('RRLYear', 'Monthly RR-Lost', 'FSSubMonth');
          break;
        }
      }
    } else {
      this.Allopportunities = [];
      this.ForeCastBoxData.ForeCastSalesMonth = 0;
      this.ForeCastBoxData.ForeCastSalesYear = 0;
      this.ForeCastBoxData.ForeCastRRMonth = 0;
      this.ForeCastBoxData.ForecastRRYear = 0;
      this.ForeCastBoxData.SalesWonMonth = 0;
      this.ForeCastBoxData.SalesWonYear = 0;
      this.ForeCastBoxData.RRWonMonth = 0;
      this.ForeCastBoxData.RRWonYear = 0;
      this.ForeCastBoxData.SalesLostMonth = 0;
      this.ForeCastBoxData.SalesLostYear = 0;
      this.ForeCastBoxData.RRLostMonth = 0;
      this.ForeCastBoxData.RRLostYear = 0;
      $("#userids").val('');
    }
  }

  BoxClick(id, BoxTitle,SubHeaderId) {
    $('.boxBorder').removeClass('boxBorderHighlight');
    $('#' + id).addClass('boxBorderHighlight');
    $('.subbackground').removeClass('FactiveButton');
    $('#' + SubHeaderId).addClass('FactiveButton');
    this.SelectedBoxTitle = BoxTitle;
    if (id == 'FSMonth' || id == 'FSYear' || id == 'FRRMonth' || id == 'FRRYear') {
      if (id == 'FSMonth' || id == 'FRRMonth') {

        var date = new Date($('#' + id +'Calendar').val());
        var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
        var StartDate = moment(firstDay).format("YYYY-MM-DD");
        var EndDate = moment(lastDay).format("YYYY-MM-DD");
        this.GetOpportunitiesForForecast('Open', StartDate, EndDate);
        
      } else if (id == 'FSYear' || id == 'FRRYear') {
       
        var firstDay = lastDay = new Date();
        if (id == 'FSYear') {
          firstDay = new Date(this.ForecastSalesECDYear, 0, 1);
          lastDay = new Date(this.ForecastSalesECDYear, 11, 31);
        } else {
          firstDay = new Date(this.ForecastRRECDYear, 0, 1);
          lastDay = new Date(this.ForecastRRECDYear, 11, 31);
        }
        var StartDate = moment(firstDay).format("YYYY-MM-DD");
        var EndDate = moment(lastDay).format("YYYY-MM-DD");
        this.GetOpportunitiesForForecast('Open', StartDate, EndDate);

      }
      
    }
    else if (id == 'SWMonth' || id == 'SWYear' || id == 'RRWMonth' || id == 'RRWYear') {

      if (id == 'SWMonth' || id == 'RRWMonth') {

        var date = new Date($('#' + id + 'Calendar').val());
        var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
        var StartDate = moment(firstDay).format("YYYY-MM-DD");
        var EndDate = moment(lastDay).format("YYYY-MM-DD");
        this.GetOpportunitiesForForecast('Closed-Won', StartDate, EndDate);
      } else if (id == 'SWYear' || id == 'RRWYear') {
        //this.Allopportunities = [];
        var firstDay = lastDay = new Date();
        if (id == 'SWYear') {
          firstDay = new Date(this.SalesWonYear, 0, 1);
          lastDay = new Date(this.SalesWonYear, 11, 31);
        } else {
          firstDay = new Date(this.RRWonYear, 0, 1);
          lastDay = new Date(this.RRWonYear, 11, 31);
        }
        var StartDate = moment(firstDay).format("YYYY-MM-DD");
        var EndDate = moment(lastDay).format("YYYY-MM-DD");
        this.GetOpportunitiesForForecast('Closed-Won', StartDate, EndDate);

      }
      
    } else if (id == 'SLMonth' || id == 'SLYear' || id == 'RRLMonth' || id == 'RRLYear' ) {

      if (id == 'SLMonth' || id == 'RRLMonth') {

        var date = new Date($('#' + id + 'Calendar').val());
        var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
        var StartDate = moment(firstDay).format("YYYY-MM-DD");
        var EndDate = moment(lastDay).format("YYYY-MM-DD");
        this.GetOpportunitiesForForecast('Closed-Lost', StartDate, EndDate);
      } else if (id == 'SLYear' || id == 'RRLYear') {
        var firstDay = lastDay = new Date();
        if (id == 'SLYear') {
          firstDay = new Date(this.SalesLostYear, 0, 1);
          lastDay = new Date(this.SalesLostYear, 11, 31);
        } else {
          firstDay = new Date(this.RRLostYear, 0, 1);
          lastDay = new Date(this.RRLostYear, 11, 31);
        }
        var StartDate = moment(firstDay).format("YYYY-MM-DD");
        var EndDate = moment(lastDay).format("YYYY-MM-DD");
        this.GetOpportunitiesForForecast('Closed-Lost', StartDate, EndDate);

      }
    }
  }
  LoadData() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
    this.StartDate = moment(firstDay).format("YYYY-MM-DD");
    this.EndDate = moment(lastDay).format("YYYY-MM-DD");
    
  }
  onCloseDialogEventReceived(event) {
    this.CommissionEstimator(false);
    this.opportunities.RRCommisssionOpportunityMonthly = null;
    this.opportunities.RRCommisssionOpportunityYearly = null;
    this.opportunities.salesCommissionOpportunity = null;
    this.gridOptions.api.deselectAll();
   
  }
  getBoxData() {
    this.ForeCastBoxData = new ForecastDataModel();

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
    var MonthStartDate = moment(firstDay).format("YYYY-MM-DD");
    var MonthEndDate = moment(lastDay).format("YYYY-MM-DD");

    this.opprtunityservice.ForecastData($("#userids").val(), MonthStartDate, MonthEndDate, this.isPended).then(newticketresult => {
      this.ForeCastBoxData.ForeCastSalesMonth = newticketresult.OpportunityOpenTotalPrice;
      this.ForeCastBoxData.ForeCastRRMonth = newticketresult.OpportunityOpenTotalRR;
      this.ForeCastBoxData.SalesWonMonth = newticketresult.OpportunityWonTotalPrice;
      this.ForeCastBoxData.SalesLostMonth = newticketresult.OpportunityLostTotalPrice;
      this.ForeCastBoxData.RRWonMonth = newticketresult.OpportunityWonTotalRR;
      this.ForeCastBoxData.RRLostMonth = newticketresult.OpportunityLostTotalRR;

      var Ydate = new Date();
      var YfirstDay = new Date(Ydate.getFullYear(), 0, 1);
      var YlastDay = new Date(YfirstDay.getFullYear(), 11, 31);
      var YearStartDate = moment(YfirstDay).format("YYYY-MM-DD");
      var YearEndDate = moment(YlastDay).format("YYYY-MM-DD");
      this.opprtunityservice.ForecastData($("#userids").val(), YearStartDate, YearEndDate, this.isPended).then(newticketresult2 => {
    
        this.ForeCastBoxData.ForeCastSalesYear = newticketresult2.OpportunityOpenTotalPrice;
        this.ForeCastBoxData.ForecastRRYear = newticketresult2.OpportunityOpenTotalRR;
        this.ForeCastBoxData.SalesWonYear = newticketresult2.OpportunityWonTotalPrice;
        this.ForeCastBoxData.SalesLostYear = newticketresult2.OpportunityLostTotalPrice;
        this.ForeCastBoxData.RRWonYear = newticketresult2.OpportunityWonTotalRR;
        this.ForeCastBoxData.RRLostYear = newticketresult2.OpportunityLostTotalRR;
      });
    });


  }
  SaleCommissionOpprtunity(val) {
    this.opportunities.salesCommissionOpportunity = this.opportunities == null?"":((parseFloat(this.SalesCommission.toString()) * val) / 100).toString().replace('NaN', '');
  }
  RRCommissionOpprtunity(val) {
    this.opportunities.RRCommisssionOpportunityMonthly = this.opportunities == null ? "" : (parseFloat(this.RRCommission.toString()) * (val / 100)).toString().replace('NaN', '');
    this.opportunities.RRCommisssionOpportunityYearly = this.opportunities == null ? "" : parseFloat((parseFloat(this.opportunities.RRCommisssionOpportunityMonthly)* 12).toString()).toFixed(2);
  }
  rowClickAgGrid(rowEvent) {
    if (this.opportunityids && (this.opportunityids).indexOf(rowEvent.data.id) > -1) {
      var index = (this.Ids).indexOf(rowEvent.data.id);
      (this.opportunityids).splice(index, 1);

    } else {
      this.opportunityids = [];
      this.opportunityids.push(rowEvent.data.id);
    }
    if ((this.Ids).indexOf(rowEvent.data.accountorcontactid) > -1) {
      var index = (this.Ids).indexOf(rowEvent.data.accountorcontactid);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      this.AccConIds = string;
      $('#tasklistselectedtyle').val('');
      if (rowEvent.data.type == "Contacts") {
        this.applicationComponent.taskselectedtype = 'Contact';
        $('#tasklistselectedtyle').val('Contact');
      } else {
        this.applicationComponent.taskselectedtype = 'Customer';
        $('#tasklistselectedtyle').val('Customer');
      }
      
    } else {
      this.Ids.push(rowEvent.data.accountorcontactid)
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      this.AccConIds = string;
     
      if (rowEvent.data.type == "Contacts") {
        this.applicationComponent.taskselectedtype = 'Contact';
        $('#tasklistselectedtyle').val('Contact');
      } else {
        this.applicationComponent.taskselectedtype = 'Customer';
        $('#tasklistselectedtyle').val('Customer'); 
      }
    }

  }
  Expand(val) {
    this.isexpand = val;
  }
  rowDoubleClickAgGrid(rowEvent) {
    this.opportunityids = rowEvent.data.id;
    let type = rowEvent.data.type;
    this.router.navigateByUrl(`/application/opportunitiesdashboard/${rowEvent.data.accountorcontactid}/${type}/null/${rowEvent.data.id}`);
    this.applicationComponent.ExpandSelectedMenu('opportunities');
  }

  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();

  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();     
    }, 400));
  }
  autoSizeAll() {
    if (this.gridOptions && this.gridOptions.columnApi) {
      this.gridOptions.columnApi.autoSizeAllColumns();
    }
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 25;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";
    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }
    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
   
    config.configureduserid = this.currentuser.id;
    config.resourceid = 39; // 39- for Forecasting

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }
    config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    this.opprtunityservice.saveDashboardconfiguration(config);
  }
  CommissionEstimator(val) {
   
    if (this.gridApi.getSelectedRows() != undefined && this.gridApi.getSelectedRows()?.length > 0) {
      this.SalesCommission = this.gridApi.getSelectedRows().reduce((sum, current) => sum +
        parseFloat(current.dollers.replace(',', '').replace('$', '')), 0);
      this.RRCommission = this.gridApi.getSelectedRows().reduce((sum, current) => sum +
        parseFloat(current.RR.replace(',', '').replace('$', '')), 0);
    } 
    if (this.opportunityids.length > 0) {
      this.isDisplayCommissionEstimator = val;
      this.isDisplayCommissionEstimatorCss();
    } else {
      this.SelectOpportunityWarning = true;
      this.SelectOpportunityWarningCss();
    }
   
  }
  SelectOpportunityWarningCss() {
    setTimeout(function () {
      $('#SelectOpportunityWarning .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;right:35%;overflow: auto;height: 4%;top:10%');
    }, 4);
  }

  isDisplayCommissionEstimatorCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width: 35%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);min-height: 63%;overflow: auto;height: 83%;right:35%;padding: 0;');
    }, 4)
  }
  Close() {
    this.applicationComponent.activateMainButtons('dashboards');
  }
}
