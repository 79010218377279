import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { IMyDpOptions } from 'mydatepicker';
import { AuditLogComponent } from '../audit-log/audit-log.component';
import { ApplicationComponent } from '../application.component';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { Auditlog, AuditLogModel } from '../../models/auditlog';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Column } from 'ag-grid';
import * as moment from 'moment';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { LeadsComponent } from '../leads/leads.component';
import { PageComponent } from '../page/page.component';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/services/accounts.service';
import { Dropdowntype } from 'src/app/models/dropdowntype';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
declare var $: any;
declare var require: any;
@Component({
  selector: 'app-rhx',
  templateUrl: './rhx.component.html',
  styleUrls: ['./rhx.component.css']
})
export class RhxComponent implements OnInit {
  @Input()
  subMenuId: string;
  currentuser: any;
  UserIds: string;
  selectedtype: string = "";
  dropdownSetting = {};
  rhxType: string;
  pageSize: string = "1000";
  dropdownList = [];
  selectedItems = [];
  timezonevalue: string;
  finalitems = "";
  selectedAudittype = "Login";
  datePipe: DatePipe = new DatePipe("en-US");
  rhxlist: Array<Auditlog> = [];
  public rhxcolumnDefs: any[];
  colSelections: string;
  public gridrhxOptions: GridOptions;
  public month = ((new Date().getMonth() + 1) < 10) ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = (new Date().getFullYear() - 1) + '-' + this.month + '-01'; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = 'Start:' + this.month + '-01-' + (new Date().getFullYear() - 1).toString();
  public sideBar;
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = new Date().getFullYear() + '-' + this.month + '-' + (new Date().getDate()); //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = 'End:' + this.month + '-' + (new Date().getDate()) + '-' + new Date().getFullYear().toString();
  @ViewChild(AuditLogComponent) Auditlog;
  @ViewChild(LeadsComponent) leadsComponent;
  aggridheight: string;
  currentPage: number = 1;
  DisplayrowsList: Dropdowntype[] = [];
  startrow: number = 1;
  noofRows: number = 1;
  totalnumberofrows: number = 1;
  totalnumberofPages: number = 1;
  calanderonoff: boolean = false;
  durationFilter = "";
  private gridApi; 
  constructor(private paginationcop: PageComponent, private toastr: ToastrService, private customerService: CustomerService, private opprtunityservice: OpprtunitiesService,
    public applicationComponent: ApplicationComponent, private router: Router, private route: ActivatedRoute, private accountService: AccountsService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    }
    this.UserIds = this.currentuser.id;
    this.selectedtype == "rhx"
  }
  ngAfterViewInit(): void {
    var thisComponent = this;
    setTimeout(function () { thisComponent.ResizeTable(); }, 10);
  }
  ResizeTable() {
    this.aggridheight = (((window.innerHeight) - (($('#appComponentContent').height()) + ($('#actiondivid').height()))) - 45) + 'px';
  }
  hours_am_pm(time) {
    if (time != null && time != '') {
      let hour = (time.split(':'))[0]
      let min = (time.split(':'))[1]
      let part = hour > 12 ? 'pm' : 'am';
      min = (min + '').length == 1 ? `0${min}` : min;
      hour = hour > 12 ? hour - 12 : hour;
      hour = (hour + '').length == 1 ? `0${hour}` : hour;
      return `${hour}:${min} ${part}`
    }

  }

  UpdateSearchColumns(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[] = columnSelections.split(',');

    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');

      if ((fields[2] == 'true' && fields[1] != '#') && (fields[2] == 'true' && fields[0] != 'notes') && (fields[2] == 'true' && fields[0] != 'actions')) {

        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }
    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];
    if (tempTableColumns && tempTableColumns.length > 0) {
      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })

    }
    else {
      this.accountService.GetDefaultColumns('ContactsAccount', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.applicationComponent.SearchSelectedItems = [
            { "id": 'accountname', "itemName": 'Account Name' }
          ];
        }
      });
    }
  }

  LoadRhx(startrow, endrow) {   
    var moment = require('moment-timezone');
    this.timezonevalue = moment.tz.guess();
    this.customerService.GetAllRhxList($("#userids").val(), startrow, endrow, this.startDateVal, this.endDateVal).then((opportunityauditloglist) => {
      opportunityauditloglist.forEach(document => {
        document.dateofviewed=document.dateofviewed.replace('T', ' ');
        let banTime1: Date = new Date(document.dateofviewed + ' UTC');
        document.time =this.datePipe.transform(banTime1.toLocaleString(), "hh-mm-ss"); 
        document.dateofviewed = this.datePipe.transform(document.dateofviewed, "MM-dd-yyyy");

      });

      this.rhxlist = opportunityauditloglist;
      this.startrow = this.rhxlist[0].CurrentPage == 0 ? 1 : this.rhxlist[0].CurrentPage;
      this.noofRows = endrow * this.currentPage;
      this.totalnumberofrows = this.rhxlist[0].Total;
      this.totalnumberofPages = Math.ceil(this.rhxlist[0].Total / endrow);
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.paginationcop.startrow = selfObj.rhxlist[0].CurrentPage == 0 ? 1 : selfObj.rhxlist[0].CurrentPage;

        selfObj.paginationcop.noofRows = endrow * selfObj.currentPage;

        selfObj.paginationcop.totalnumberofrows = selfObj.rhxlist[0].Total;
        selfObj.paginationcop.totalnumberofPages = selfObj.rhxlist[0].Total / endrow;
      }, 500));
    });
  }
  txtPageSizeChanged(pageSize) {
    this.currentPage = 1;
    this.pageSize = pageSize;
    this.gridrhxOptions.api.paginationSetPageSize(Number(pageSize));
    this.LoadRhx(this.currentPage, this.pageSize);

  }
  ngOnInit() {
     
    this.applicationComponent.SearchSelectedItems = [];
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }
    this.route.params.subscribe(params => {
      if (params['subMenuId'] != null || params['subMenuId'] != undefined) {
        this.subMenuId = params['subMenuId'];
      }
      if (this.subMenuId == "Today") {
        this.startDateVal = (new Date().getFullYear()) + "-" + this.month + "-" + new Date().getDate();
        this.endDateVal = this.startDateVal;
        this.startDatePlaceHolder = 'Start:' + this.month + '-' + new Date().getDate()+'-' + (new Date().getFullYear() - 1).toString();
        this.endDatePlaceHolder= 'End:' + this.month + '-' + (new Date().getDate()) + '-' + new Date().getFullYear().toString();
        this.rhxType = 'Today';
      }
      else if (this.subMenuId == "Yesterday") {
        this.startDateVal = (new Date().getFullYear()) + "-" + this.month + "-" + (new Date().getDate() - 1);
        this.endDateVal = this.startDateVal;
        this.startDatePlaceHolder = 'Start:' + this.month + '-' + (new Date().getDate()-1) + '-' + (new Date().getFullYear() - 1).toString();
        this.endDatePlaceHolder = 'End:' + this.month + '-' + (new Date().getDate()-1) + '-' + new Date().getFullYear().toString();
        this.rhxType = 'Yesterday';
      }
      else if (this.subMenuId == "Weekly") {
        var curr = new Date; 
        var first = curr.getDate() - curr.getDay(); 
        var last = first + 6;
        this.rhxType = 'Weekly';
        this.startDateVal = new Date(curr.setDate(first)).toISOString().slice(0, 10);
        this.endDateVal = new Date(curr.setDate(last)).toISOString().slice(0, 10);
        this.startDatePlaceHolder = 'Start:' + this.month + '-' + new Date().getDate() + '-' + (new Date().getFullYear() - 1).toString();
        this.endDatePlaceHolder = 'End:' + this.month + '-' + (new Date().getDate()) + '-' + new Date().getFullYear().toString();

      }
      else {
        this.startDateVal = (new Date().getFullYear()) + "-01-01";
        this.endDateVal = (new Date().getFullYear()) + "-12-31";;
        this.startDatePlaceHolder = 'Start:01-01-' + (new Date().getFullYear() - 1).toString();
        this.endDatePlaceHolder = 'End:12-31-' + new Date().getFullYear().toString();
        this.rhxType = 'year to date';
      }
      this.applicationComponent.startVal = this.startDateVal;
      this.applicationComponent.endVal = this.endDateVal;
      this.generateAuditlog();

      var obj: AuditLogModel = { id: 0, type: 'Recent History', action: 'Recent History Viewed', AccountOrContactId: 0, AccountOrContact: "", documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Recent History Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
      this.applicationComponent.InsertAuditHistory(obj);

    });
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All Users',
      unSelectAllText: 'UnSelect All Users',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    
    this.GetAllaccesusersListbyuser();
     
    if (this.applicationComponent.selectedusers != '' || this.applicationComponent.selectedusers != null) {
      this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
        Owner.forEach(Owner => {
        });
        this.selectedItems = Owner;
      });
      this.UserIds = $("#userids").val();
      this.generateAuditlog();
    } else {
      this.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username }
      ];
      this.finalitems += this.currentuser.id + ',';
      //$("#userids").val(this.currentuser.id);
      this.UserIds = $("#userids").val();
      this.generateAuditlog();
    }
    this.initializeAgGrid();
    $("#rhx").addClass("mybtn coustomButton mainactiveButton");
  }

  GetAllaccesusersListbyuser() {
     
    this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
      this.DisplayrowsList = Rows;
    });
  }


  initializeAgGrid() {
    this.gridrhxOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 47).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.gridrhxOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = columnSelections;
      this.startDateVal = (config != null && config.startdate != null) ? config.startdate : (new Date().getFullYear() - 1) + '-01-01';
      this.startDatePlaceHolder = (config != null && config.startdate != null) ? 'Start:' + moment(config.startdate).format("MM-DD-YYYY") : 'Start: 01-01-' + (new Date().getFullYear() - 1).toString();
      this.endDateVal = (config != null && config.enddate != null) ? config.enddate : new Date().getFullYear() + '-12-31';
      this.endDatePlaceHolder = (config != null && config.enddate != null) ? 'End:' + moment(config.enddate).format("MM-DD-YYYY") : 'End: 12-31-' + (new Date().getFullYear() - 1).toString();
      this.createColumnDefs(columnSelections);
      this.UpdateSearchColumns(this.colSelections)
    });
  }
 
  createColumnDefs(columnSelections: string) {
   
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      this.rhxcolumnDefs = [];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        this.rhxcolumnDefs.push({
          field: fieldHeaderVisibleWidthArray[0],
          headerName: fieldHeaderVisibleWidthArray[1],
          hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
          width: parseInt(fieldHeaderVisibleWidthArray[3])
        });

      }
    } else {   
      this.rhxcolumnDefs = [
        
        { field: 'AccountOrContactId', headerName: ' AccountOrContactId', hide: true, width: 10 },
        { field: 'AccountOrContact', headerName: ' typeofaccount', hide: true, width: 100 },
        { field: 'documentid', headerName: ' documentid', hide: true, width: 100 },
        { field: 'dateofviewed', headerName: ' Date', hide: false, width: 250 },
        { field: 'time', headerName: ' Time', hide: false, width: 250 },
        { field: 'AccountName', headerName: ' AccountName', hide: false, width: 250 },
        { field: 'contactname', headerName: 'Contact', hide: false, width: 300 },
        { field: 'Title', headerName: 'Title', hide: false, width: 300 },
        { field: 'action', headerName: 'Description', hide: false, width: 300 },
        { field: 'customertype', headerName: 'Type', hide: false, width: 300 },
        { field: 'documentname', headerName: 'Action', hide: false, width: 200 },
        { field: 'username', headerName: ' User Name', hide: false, width: 200 },
      ];
    }
  }
  dateChange(event) {
  //alert(this.getDateFormatYYYYHMMHDD(event.date));
    this.AssignStartAndEndDate(event);
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
   // alert(this.applicationComponent.startVal + this.applicationComponent.endVal)
    this.generateAuditlog();
    this.saveRHXConfiguration();
  }
  AssignStartAndEndDate(event) {

    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  onItemSelect(item: any) {
    var ids = "";
    ids += item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.UserIds = $("#userids").val();
    this.generateAuditlog();

  }
  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.UserIds = $("#userids").val();
      this.generateAuditlog();
    } else {
      this.rhxlist = [];
      $("#userids").val('');
    }
  }
  onSelectAll(items: any) {
    var ids = "";
    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.UserIds = $("#userids").val();
    this.generateAuditlog();

  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.rhxlist = [];
    $("#userids").val('');
  }
  generateAuditlog() {
    this.LoadRhx(this.startrow, this.pageSize);
  }
  OnMessageReceived(msg)
  {
    this.selectedAudittype = msg;
  }
  rowDoubleClickAgGrid(rowEvent) {
    this.applicationComponent.selectedsubbutton = "";
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.showSearchFieldsDialog = false;

    this.startDateVal = (new Date().getFullYear()) + "-01-01";
    this.endDateVal = (new Date().getFullYear()) + "-12-31";;
    this.startDatePlaceHolder = 'Start:01-01-' + (new Date().getFullYear() - 1).toString();
    this.endDatePlaceHolder = 'End:12-31-' + new Date().getFullYear().toString();
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
    
    if (rowEvent.data.documentname == "Account") {
      localStorage.setItem('RhxAccountVal', rowEvent.data.AccountOrContactId);
      let SO = this;
      $(setTimeout(function () {
        SO.applicationComponent.activateSubButtons('empty');
        $(setTimeout(function () {
          SO.router.navigateByUrl(`/application/account/Accounts`);
          SO.applicationComponent.ExpandSelectedMenu('Accounts');
          $(".subButtons").removeClass("activeButton");
          SO.applicationComponent.selectedtabid = 'Customer';
        }, 100));
      }, 50));

    }
    else if (rowEvent.data.documentname == "Leads") {
      this.router.navigateByUrl(`/application/leadsdashboard/${rowEvent.data.AccountOrContactId}/0/null`);
    }
    else if (rowEvent.data.documentname == "Opportunity") {
      let type = rowEvent.data.AccountOrContact == 'Contact' ? 'Contacts' : 'Customer';
      this.router.navigateByUrl(`/application/opportunitiesdashboard/${rowEvent.data.AccountOrContactId}/${type}/null/null`);
    }
    else if (rowEvent.data.documentname == "TaskList" && rowEvent.data.AccountOrContact=="Contact") {
      this.router.navigateByUrl(`/application/tasklistdashboard/Contacts/${rowEvent.data.AccountOrContactId}/${rowEvent.data.documentid}/null`);
    }
    else if (rowEvent.data.documentname == "TaskList" && rowEvent.data.AccountOrContact == "Account") {
      this.router.navigateByUrl(`/application/tasklistdashboard/Customer/${rowEvent.data.AccountOrContactId}/${rowEvent.data.documentid}/null`);
    }
    else if (rowEvent.data.documentname == "Contact") {
      //this.router.navigateByUrl(`/application/contactsdashboard/${rowEvent.data.AccountOrContactId}/null`);
      //this.router.navigateByUrl(`/application/contactsdashboard/${rowEvent.data.AccountOrContactId}`);

      localStorage.setItem('RhxContactVal', rowEvent.data.AccountOrContactId);

      let SO = this;
      $(setTimeout(function () {
        SO.applicationComponent.activateSubButtons('empty');
        $(setTimeout(function () {
          SO.router.navigateByUrl(`/application/contacts/contacts/null`);
          SO.applicationComponent.ExpandSelectedMenu('contact');
          SO.applicationComponent.selectedtabid = 'contact';
        }, 100));
      }, 50));
    }
  }

  columnVisible(event) {
    this.saveRHXConfiguration();
  }
  dragStopped(event) {
    this.saveRHXConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveRHXConfiguration();
  }

  saveRHXConfiguration() {
    let allCols: Column[] = this.gridrhxOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 47;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;
    this.opprtunityservice.saveDashboardconfiguration(config);

  }

  first(val) {
    this.currentPage = 1;
    this.LoadRhx(this.currentPage, this.pageSize);
  }
  prev(val) {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.LoadRhx(this.currentPage, this.pageSize);
    }
  }
  next(val) {
    if (this.currentPage != this.totalnumberofPages) {
      this.currentPage++;
      this.LoadRhx(this.currentPage, this.pageSize);
    }

  }
  last(val) {
    this.currentPage = this.totalnumberofPages;
    this.LoadRhx(this.currentPage, this.pageSize);
  }
  
  calanderclick(val) {
     
    this.calanderonoff = val;
    this.generateAuditlog();
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }

  }

  drpChange(val) {
    if (val == "Todays History") {
      this.startDateVal = (new Date().getFullYear()) + "-" + this.month + "-" + new Date().getDate();
      this.endDateVal = this.startDateVal;
      this.startDatePlaceHolder = 'Start:' + this.month + '-' + new Date().getDate() + '-' + (new Date().getFullYear() - 1).toString();
      this.endDatePlaceHolder = 'End:' + this.month + '-' + (new Date().getDate()) + '-' + new Date().getFullYear().toString();
      this.rhxType = 'Today';
    }
    else if (val == "Yesterdays History") {
      this.startDateVal = (new Date().getFullYear()) + "-" + this.month + "-" + (new Date().getDate() - 1);
      this.endDateVal = this.startDateVal;
      this.startDatePlaceHolder = 'Start:' + this.month + '-' + (new Date().getDate() - 1) + '-' + (new Date().getFullYear() - 1).toString();
      this.endDatePlaceHolder = 'End:' + this.month + '-' + (new Date().getDate() - 1) + '-' + new Date().getFullYear().toString();
      this.rhxType = 'Yesterday';
    }
    else if (val == "Weekly History") {
      var curr = new Date;
      var first = curr.getDate() - curr.getDay();
      var last = first + 6;
      this.rhxType = 'Weekly';
      this.startDateVal = new Date(curr.setDate(first)).toISOString().slice(0, 10);
      this.endDateVal = new Date(curr.setDate(last)).toISOString().slice(0, 10);
      this.startDatePlaceHolder = 'Start:' + this.month + '-' + new Date().getDate() + '-' + (new Date().getFullYear() - 1).toString();
      this.endDatePlaceHolder = 'End:' + this.month + '-' + (new Date().getDate()) + '-' + new Date().getFullYear().toString();

    }
    else if (val == "Monthly History") {
      var date = new Date();
      this.startDateVal = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10);
      this.endDateVal = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10);     
      this.rhxType = 'Monthly'; 
    }
    else {
      this.startDateVal = (new Date().getFullYear()) + "-01-01";
      this.endDateVal = (new Date().getFullYear()) + "-12-31";;
      this.startDatePlaceHolder = 'Start:01-01-' + (new Date().getFullYear() - 1).toString();
      this.endDatePlaceHolder = 'End:12-31-' + new Date().getFullYear().toString();
      this.rhxType = 'year to date';
    }
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  UserlistDataReceived(outProps: OutputPropsUserList) {
    this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.durationFilter = outProps.secondDrpValue == undefined ? 'Today' : outProps.secondDrpValue;
    if (outProps.finalitems != "") {
      $("#userids").val(outProps.finalitems);
      this.drpChange(this.durationFilter);
      //if (outProps.pageSizeChanged) {
        this.LoadRhx(this.currentPage, this.pageSize);
      //}
    } else {
      this.rhxlist = [];
      $("#userids").val('');
    }
  }
  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards')
    
  }
}
