<div class="col-xs-12" style="padding: 0; height: 100%;">
  
    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="FieldConfigDiv">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Opportunities Field Configuration</span>
      </h4>
      <div class="col-xs-12" style="height:7%;padding:0;">

        <div class="col-xs-7" style="padding:0;">
          <div class="col-xs-1" style="padding-left:0">
            <img src="../../../assets/img/Create New Document Name.png" (click)="ShowOppFieldForm = true" style="height:30px" />
          </div>

        </div>
      </div>
      <div class="col-xs-12" style="height:87%;padding:0;">

        <div class="col-xs-7" style="padding:0;height:100%">
          <ag-grid-angular style="height:100%;" class="ag-theme-balham"
                           [gridOptions]="gridOptionsView"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="DuplicateOpportunityFieldsList"
                           [rowDragManaged]="true"
                           [suppressMoveWhenRowDragging]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (selectionChanged)="onSelectionChanged($event)"
                           (gridReady)="onAgGridReady($event)"
                           (rowDragEnd)="onRowDragEnd($event)">
          </ag-grid-angular>
        </div>
        <div class="col-xs-5" style="padding:0;height:100%">
          <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="ShowOppFieldForm">
            <form #OpportunityFieldform="ngForm" style="height:100%;padding:0;">
              <div class="col-xs-12" style="height:90%">
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Field Name</label>
                  </div>
                  <div class="col-xs-8">
                    <div class="col-xs-12" style="padding:0;">
                      <input class="form-control" required id="Fname" name="FieldName" [(ngModel)]="OpportunityFields.FieldName" />

                    </div>
                  </div>
                </div>
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Display As</label>
                  </div>
                  <div class="col-xs-8">
                    <input class="form-control" id="DisplayAs" name="DisplayAs" [(ngModel)]="OpportunityFields.DisplayAs" required />

                  </div>
                </div>
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Required Field</label>
                  </div>
                  <div class="col-xs-8">
                    <select class="form-control" name="RequiredField" [(ngModel)]="OpportunityFields.RequiredField" required>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Data Type</label>
                  </div>
                  <div class="col-xs-8">
                    <select class="form-control" name="DataType" [(ngModel)]="OpportunityFields.DataType" required>

                      <option value="null" disabled="disabled">Select Data Type</option>
                      <option value="Pick List">Pick List</option>
                      <option value="Text Box">Text Box</option>
                      <option value="Numeric">Numeric</option>
                      <option value="Date/Time">Date/Time</option>
                      <option value="Date">Date</option>
                      <option value="Date/YearOnly">Date/Year Only</option>
                      <option value="System Lookup">System Lookup</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Editable</label>
                  </div>
                  <div class="col-xs-8">
                    <select class="form-control" name="PickListEditable" [(ngModel)]="OpportunityFields.Editable" required>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>
                </div>

                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Sort Order</label>
                  </div>
                  <div class="col-xs-8">
                    <input class="form-control" id="SortOrder" name="SortOrder" [(ngModel)]="OpportunityFields.SortOrder" required />

                  </div>
                </div>
              </div>
              <div class="col-xs-12" style="height:10%;float:right;padding-right:15px">
                <div class="col-xs-6" style="padding: 0;">
                  <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="CloseaccessDiv()">Cancel</button>
                </div>
                <div class="col-xs-6" style="padding-right: 0;">
                  <button type="submit" class="btn saveButton" style="width:100%;" [disabled]="(!OpportunityFieldform.form.valid)" (click)="SaveOppField()">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="FieldChoiceConfigDiv">

      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Field Choice Configuration</span>

      </h4>
      <div class="col-xs-12" style="height:7%;padding:0;">

        
        <div class="col-xs-12" style="padding:0;">
          <div class="col-xs-1" style="padding-left:0">
            <img src="../../../assets/img/Create New Document Name.png" style="height:30px" (click)="(SelectedOppField.Editable == 'Yes')?AddNewFieldData(0,'Add'):''" />
          </div>
          <div class="col-xs-5" style="float:right;padding:0;">
            <div class="col-xs-6" style="height:100%">
              <select class="form-control" (change)="PickListSortByType($event.target.value)">

                <option value="GOrderA-Z">Grid Order A-Z</option>
                <option value="GsortOrder">Grid By Sort Order</option>

              </select>
            </div>
            <div class="col-xs-6" style="height:100%"> 
              <select class="form-control" (change)="PickListSortByType($event.target.value)">

                <option value="A-Z">System Display A-Z</option>
                <option value="SSOrder">System Display Sort Order</option>

              </select>
            </div>
            
           
          </div>
        </div>
      </div>
      <div class="col-xs-12" style="height:87%;padding:0;">

        <div class="col-xs-7" style="padding:0;height:100%">
          <ag-grid-angular style="height:100%;" class="ag-theme-balham"
                           [gridOptions]="gridOptionsView"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="DuplicateOpportunityFieldsList"
                           [rowDragManaged]="true"
                           [suppressMoveWhenRowDragging]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (selectionChanged)="onSelectionChangedNew($event)"
                           (gridReady)="onAgGridReady($event)"
                           (rowDragEnd)="onRowDragEndFieldChoice($event)">
          </ag-grid-angular>
        </div>
        <div class="col-xs-5" style="padding:0;height:100%">
          <div class="col-xs-12" style="height:100%;padding-left:0;" *ngIf="ShowFieldItemTable">
            <div class="col-xs-12 scroll-c" style="height:90%;padding:0;">
              <!--<ag-grid-angular style="height:100%;" class="ag-theme-balham"
                               [gridOptions]="gridConfigFeildsView"
                               [columnDefs]="ConfigFeildscolumnDefs"
                               [defaultColDef]="applicationComponent.defaultGridColDef"
                               [rowData]="FiledDataList"
                               [rowDragManaged]="true"
                               [suppressMoveWhenRowDragging]="true"
                               [sideBar]="sideBar"
                               [animateRows]="true"
                               (gridReady)="onConfigFeildsAgGridReady($event)"
                               (rowDragEnd)="onConfigFeildsRowDragEnd($event)">
              </ag-grid-angular>-->
              <table style="width:100%">
    <tr style="background-color:#d6dfe2;height: 38px;text-align: center;">
      <th style="width:6%"></th>
      <th style="display:none"></th>
      <th style="text-align:center;width:15%">Sort Order</th>
      <th style="text-align:center;width:60%">Pick List Name</th>
      <th style="text-align:center;width:18%">Actions</th>
    </tr>
    <tbody [dragula]='"Category-bag"' id="{{SelectedOppField.ColumnName}}">
      <tr *ngFor="let fieldData of FiledDataList;let i = index" id="PickListRow{{fieldData.id}}" (click)="PickListRowClick(fieldData)">
        <td style="text-align:center;width:6%" id="PickList{{fieldData.id}}"><input type="checkbox" /></td>
        <td style="text-align:center;display:none" id="PickListID~{{fieldData.id}}">{{fieldData.id}}</td>
        <td style="text-align:center;width:15%" id="PickListSortOrder{{fieldData.id}}" (keyup)="keyupPickList(fieldData.id)">{{fieldData.SortOrder}}</td>
        <td style="text-align:left;padding-left:1%;width:60%;" id="PickListName{{fieldData.id}}" (keyup)="keyupPickList(fieldData.id)">{{fieldData.opportunityitem}}</td>
        <td style="text-align:center;width:18%"><i class="fa fa-trash" aria-hidden="true" *ngIf="fieldData.id !=0 && SelectedOppField.Editable == 'Yes'" (click)="DeletePickList(fieldData)"></i></td>
      </tr>
    </tbody>

  </table>
            </div>
            <div class="col-xs-12" style="padding:2% 0 0 0;height:8%">
              <div class="col-xs-6"></div>
              <div class="col-xs-6">
                <div class="col-xs-6" style="padding: 0;">
                  <button class="btn btn-default" style="width: 100%; border:1px solid">Cancel</button>
                </div>
                <div class="col-xs-6" style="padding-right: 0;">
                  <button type="submit" class="btn saveButton" style="width:100%;" (click)="SavePickListData()">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

</div>
