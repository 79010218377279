import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
declare var $: any;
@Component({
  selector: 'app-editactivatecv',
  templateUrl: './editactivatecv.component.html',
  styleUrls: ['./editactivatecv.component.css']
})
export class EditactivatecvComponent implements OnInit, ICellRendererAngularComp {

  constructor() { } 

  ngOnInit() {

  }

  public params: any;

  agInit(params: any): void {
    this.params = params;
   
  }
  needDeletebtn() {
    return true ;
  }
  needCVbtn() {
    return true;
  }
  public editRowAg() {
    this.params.context.editRowAg(this.params.node.data);
  }

  public deleteRowAg() {
    this.params.context.deleteRowAg(this.params.node.data);
  }
  public ActiateRowAg() {
    this.params.context.deleteRowAg(this.params.node.data);
  }

  public convertContactAg() {
    this.params.context.convertContactAg(this.params.node.data);
  }

  refresh(): boolean {
    return false;
  }
}
