import { Component, OnInit, ViewChild } from '@angular/core';
import { UserdataheaderComponent } from '../userdataheader/userdataheader.component';
import { ApplicationComponent } from '../application.component';
import * as moment from 'moment';
import { OutputPropsUserList } from '../../models/output-props-user-list';
import { ActivatedRoute } from '@angular/router';
import { GridOptions } from 'ag-grid';
import { ProjectFields, ProjectForecastViewModel, Projects, ProjectStepStatusModel, ProjectStages } from '../../models/ProjectConfiguration';
import { CustomerService } from '../../services/customer.service';
import { EditdeletecvComponent } from '../editdeletecv/editdeletecv.component';
import { CreateProjectsComponent } from './create-projects/create-projects.component';
import { DatePipe } from '@angular/common';
import { ProjectForecastData } from '../../models/ProjectForecastData';
declare var $: any;
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  selectedItems = [];
  currentuser: any;
  accountContactId: number;
  accountOrContactType: string;
  ProjectId: number; ProjectTypeId: number;
  public gridOptionsView: GridOptions;
  public gridProjectForeCast: GridOptions;
  public ForecastgridOptions: GridOptions;
  public columnDefs;
  public sideBar;
  ExpandCreateProject: boolean = false;
  ProjectsList: Projects[] = [];
  DuplicateProjectsList: Projects[] = [];
  ProjectForecaslist: ProjectForecastData[] = [];
  public frameworkComponents;
  public context;
  ForecastView: boolean = false;
  DeleteConfirmation: boolean = false; EnableSearch: boolean = false; value = "";
  public ForecastViewcolumnDefs;
  public ProjectForecastViewcolumnDefs;
  public ViewType = 'Forecast View';
  private rowData: [];
  public rowHeight = 50;
  ShowProjectsGrid: boolean = true;
  ForecastList: ProjectForecastViewModel[]= [];
  ForecastDataObj: ProjectForecastViewModel[] = [];
  @ViewChild(CreateProjectsComponent) createProj;
  @ViewChild(UserdataheaderComponent) usrdataheadcomp;
  public gridApi;
  datePipe: DatePipe = new DatePipe("en-US");
  projectType: string = "Projects";
  Proj_Stages_List_by_ProjType: ProjectStages[] = [];
  SelectedProjType: number =0;
  SelectedProjStatus: string = "Open";
  constructor(public applicationComponent: ApplicationComponent, private route: ActivatedRoute, private customerservice: CustomerService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    if (this.applicationComponent.selectedItems != null && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {
      this.getSelectedUserList(this.currentuser.id);
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) {
        this.accountContactId = (params['accId']) ? params['accId'] : this.accountContactId;
        this.ProjectId = (params['ProjectID']) ? params['ProjectID'] : this.ProjectId;
        this.accountOrContactType = (params['type']) ? params['type'] : this.accountOrContactType;
      }
    });
    this.applicationComponent.selectedbuttonText = "Projects";
    this.initializeAgGrid('');
    this.getAllProjects();
  }
  getSelectedUserList(val) {
    
    this.applicationComponent.selectedItems = [
      { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
    ];
    localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
    $("#userids").val(this.currentuser.id);
    this.applicationComponent.ExpandSelectedMenu('Projects');
  }


  initializeAgGrid(Type) {
    this.gridOptionsView = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeletecvComponent,
      
    };
      this.createColumnDefs();
  }
  ReceiveDeleteevent(msg) {
    if (msg == "Ok") {
      this.Confirmation('Yes')
    }
    this.DeleteConfirmation = false;
  }
  createColumnDefs() {
    let selfObj = this;
    var searcharray = [];
    if (selfObj.gridOptionsView.quickFilterText != null && selfObj.gridOptionsView.quickFilterText != undefined && selfObj.gridOptionsView.quickFilterText != '') {
      searcharray.push(selfObj.gridOptionsView.quickFilterText);
    }
    this.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      { field: 'ProjectID', headerName: 'ProjectID', getQuickFilterText: () => '', hide: true },
      { field: 'CreatedDate', headerName: 'Date Created', getQuickFilterText: () => '', hide: false },
      {
        field: 'AccountName', headerName: 'AccountName', hide: false, cellRenderer: function (params) {
          if (searcharray.length > 0 && params.value != null) {
            let x = params.value.split(' ');
            var st = "";
            for (let i = 0; i < x.length; i++) {
              if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                st += "<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }
            }
            return st

          } else {
            return params.value;
          }
        }
      },
      {
        field: 'accPhone', headerName: 'Phone', hide: false, cellRenderer: function (params) {
          if (searcharray.length > 0 && params.value != null) {
            let x = params.value.split(" ");
            let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
            var st = "";
            for (let i = 0; i < x.length; i++) {
              if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
              }
              else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        }
      },
      { field: 'ProjectTypeName', headerName: 'Project Type', getQuickFilterText: () => '', hide: false },      
      { field: 'ProjectStatusName', headerName: 'Project Status', getQuickFilterText: () => '', hide: false },
      { field: 'ProjectLeaderName', headerName: 'Project Leader', getQuickFilterText: () => '', hide: false },     
      { field: 'UserAccessName', headerName: 'User Access', getQuickFilterText: () => '', hide: false },
      {
        field: 'action', headerName: 'Action', hide: false, width: 200, pinned: 'right', cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true"></i>';
          eDiv.addEventListener('click', function () {
            selfObj.deleteProject(params.data);
          });
          return eDiv;
        }
      }
    ];

  
  }
  deleteProject(event) {
    this.ProjectId = event.date.ProjectID;
    this.DeleteConfirmation = true;
  }
  cellClicked(rowEvent) {
    if (rowEvent.colDef.field == 'action') {
      this.DeleteConfirmation = true;
      this.applicationComponent.salesxcrmDialog('#DeleteConfirmation .dialog');
      //this.DeleteProjectCss();
    }
 
  }
 
  Confirmation(val) {
    if (val == 'Yes') {
   
      this.customerservice.DeleteProject(this.ProjectId).then(result => {
        this.DeleteConfirmation = false;
        this.accountContactId = 0;
        this.getAllProjects();
      });
    } else {
      this.DeleteConfirmation = false;
    }
  }

  getProjectForecastData() {
    this.ProjectForecaslist = [];
    this.customerservice.getProjectForecastStageStatus(this.ProjectId).then(result => {
      result.forEach(result => {
        result.Last_updated = result.Last_updated==null?"N/A": this.datePipe.transform(result.Last_updated, "MM/dd/yyyy");
      });
      this.ProjectForecaslist = result.filter(x => x.ProjectTypeID == this.ProjectTypeId);
     
    });
   
  }
  ProjectForeCast() { this.initilizeProjectForecastGrid(); }
  UserlistDataReceived(outProps: OutputPropsUserList) {
    if (outProps.enableSearch) { this.EnableSearch = true; }
    if (outProps.secondDrpValue) {
      this.SelectedProjStatus = outProps.secondDrpValue;
    }
    if (outProps.thirdDrpValue) {
      this.SelectedProjType = parseInt(outProps.thirdDrpValue);
    } else if (parseInt(outProps.thirdDrpValue) == 0) { this.SelectedProjType =0   }
    if (this.SelectedProjStatus == 'All' && this.SelectedProjType == 0) {
      this.DuplicateProjectsList = this.ProjectsList;
      
    } else if (this.SelectedProjStatus == 'All' && this.SelectedProjType>0) {
      this.DuplicateProjectsList = this.ProjectsList.filter(x => x.ProjectTypeId == this.SelectedProjType);
    }
    else {
      if (this.SelectedProjType == 0) {
        this.DuplicateProjectsList = this.ProjectsList.filter(x => x.ProjectStatusName == this.SelectedProjStatus);
      } else {
        this.DuplicateProjectsList = this.ProjectsList.filter(x => x.ProjectStatusName == this.SelectedProjStatus && x.ProjectTypeId == this.SelectedProjType);
      }
      
    }
    this.ROwSelection();
    if (outProps.fourthDrpValue == "Forecast View") {
      this.ForecastView = true;
      this.initilizeForecastGrid();
    } else if (outProps.fourthDrpValue == "List View") {
      this.ForecastView = false;
    }
  }

  CloseContactDiv() {
    this.accountContactId = 0;
    if (this.ForecastView == true) {
      this.initilizeForecastGrid();
      this.ForecastView = false;
    } else {
      this.getAllProjects();
      this.initializeAgGrid('');
    }
  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        let rowData = event.api.getSelectedNodes()[0].data;
        this.accountContactId = rowData.AccountOrContactId;
        this.accountOrContactType = rowData.AccountOrContactType;
        this.ProjectId = rowData.ProjectID;
        this.ProjectTypeId = rowData.ProjectTypeId;
        this.ViewType = 'Forecast View';

        let selfObj = this;
        $(setTimeout(function () {
          selfObj.createProj.LoadData(selfObj.accountContactId, selfObj.accountOrContactType, selfObj.ProjectId, selfObj.ViewType, rowData);
          selfObj.projectType = "ViewProject";
        }, 300));
       
      }
    }

  }

  getAllProjects() {
    this.customerservice.getAllProjects(this.currentuser.id).then(res => {
      this.DuplicateProjectsList = this.ProjectsList = res;
      this.DuplicateProjectsList = this.DuplicateProjectsList.filter(x => x.ProjectStatusName == "Open");
      this.DuplicateProjectsList.forEach(proj => {
        proj.CreatedDate = this.datePipe.transform(proj.CreatedDate, "MM-dd-yyyy");
        proj.TargetGoLiveDate = this.datePipe.transform(proj.TargetGoLiveDate, "MM-dd-yyyy");
      });
      if (this.DuplicateProjectsList.length > 0) {
        this.ROwSelection();
      }
    });
  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  EventEmit(msg) {
     if (msg.startsWith('ProjectSaved')) {
       var projectId = msg.split(':')[1];
       this.getAllProjects();
    
       
 
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.gridApi.forEachNode((node) => {
          if (node.data.ProjectID == projectId) {
            selfObj.gridApi.selectNode(node, true);
            let rowData = node.data;
            selfObj.accountContactId = rowData.AccountOrContactId;
            selfObj.accountOrContactType = rowData.AccountOrContactType;
            selfObj.ProjectId = rowData.ProjectID;
            selfObj.ViewType = 'Forecast View';
            selfObj.createProj.LoadData(selfObj.accountContactId, selfObj.accountOrContactType, selfObj.ProjectId, selfObj.ViewType);
            
          }
        });
      }, 500));

    } else {
       this.getProjectForecastData();
    }
    
  }

  deleteRowAg(rowdata) {
   
    this.DeleteProjects(rowdata.ProjectID);
  }

  DeleteProjects(projId) {
    this.customerservice.DeleteProject(projId).then(res => {
      this.getAllProjects();
    });
  }

  getAllForeCastProjects() {
    this.customerservice.getAllForecastProjects(this.currentuser.id).then(res => {
      this.ForecastList = res;
    });
  }

 

  initilizeForecastGrid() {

    this.gridOptionsView = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeletecvComponent,

    };
    this.customerservice.getAllForecastHeaders().then(res => {
      this.ForecastcreateColumnDefs(res);
      this.getForecastData();
    });
  }

  initilizeProjectForecastGrid() {

    this.gridProjectForeCast = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeletecvComponent,

    };
    this.ProjectForecastcreateColumnDefs();
    this.getProjectForecastData();

  }

  ProjectForecastcreateColumnDefs() {
    this.ProjectForecastViewcolumnDefs = [      
      {
        field: 'StageName', headerName: 'Project Stage', hide: false, width: 150, cellStyle: function (params) {
          if (params.data.Status.startsWith('Complete')) {
            return { background: '#92d050' };
          }
          else if (params.data.Status.startsWith('In-Progress'))
          {
            return { background: '#FFFC33' };
          }
        }
      },
      {
        field: 'Status', headerName: 'Status', hide: false, width: 150, cellStyle: function (params) {
          if (params.data.Status.startsWith('Complete')) {
            return { background: '#92d050' };
          }
          else if (params.data.Status.startsWith('In-Progress')) {
            return { background: '#FFFC33' };
          }
        }
      },
      {
        field: 'Last_updated', headerName: 'Last Updated', hide: false, width: 150, cellStyle: function (params) {
          if (params.data.Status.startsWith('Complete')) {
            return { background: '#92d050' };
          }
          else if (params.data.Status.startsWith('In-Progress')) {
            return { background: '#FFFC33' };
          }
        }
      }

    ];
  }
  ForecastcreateColumnDefs(columnSelections: string) {

    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.ForecastViewcolumnDefs = [];
      this.ForecastViewcolumnDefs = [
        {
          headerName: '',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, headerClass: 'ProjForecastViewHeaders1'
        },
        {
          field: 'accountname', headerName: 'Account Name', hide: true, width: 150, rowGroup: true,
          headerClass: 'ProjForecastViewHeaders', 
        },
        { field: 'Project_Type', headerName: 'Project Type', hide: false, width: 150, headerClass: 'ProjForecastViewHeaders', cellStyle: {'text-align': 'center', 'padding': '0.5% 0 0 0' }}

      ];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        if (columnSelectionsArray[i]) {
          this.ForecastViewcolumnDefs.push({
            field: columnSelectionsArray[i].replace(' ', '_'), headerName: columnSelectionsArray[i], hide: false, width: 150, headerClass: 'ProjForecastViewHeaders', cellStyle: function (params) {
              if (params.value && params.value.trim() == 'Open') {
                var st3 = { 'background-color': 'transparent', 'text-align': 'center', 'padding': '0.5% 0 0 0' };
                return st3;
              } else if (params.value && params.value.trim() == 'Complete') {
                var st3 = { 'background-color': '#fdfdc9', 'text-align': 'center', 'padding': '0.5% 0 0 0' };
                return st3;
              } else if (params.value && params.value.includes('Open')) {
                var st = { 'background-color': 'transparent', 'text-align': 'center' };
                return st;
              } else if (params.value && params.value.includes('Complete')) {
                var st = { 'background-color': '#badb98','text-align':'center' };
                return st;
              } else if (params.value == 'In-Progress') {
                var st3 = { 'background-color': '#fdfdc9', 'text-align': 'center','padding':'0.5% 0 0 0' };
                return st3;
              }
            }, cellRenderer: function (params) {
              if ( params.value != null) {
                let x = params.value.replace(" ","<br />");
                return x;

              } else {
                return params.value;
              }
            }
          });
        }
      }

    

    }
  }

  
  onSelectionChangedForeCast(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      var rowdata = event.api.getSelectedNodes()[0];
      if (rowdata.data) {
        let rowData = event.api.getSelectedNodes()[0].data;
       
        this.accountContactId = rowData.accountorContactId;
        this.accountOrContactType = rowData.AccountOrContactType;
        this.ProjectId = rowData.ProjectId;
        this.ViewType = 'Forecast View';
        this.createProj.LoadData(this.accountContactId, this.accountOrContactType, this.ProjectId, this.ViewType);
      } else {
        let accountOrContactId = event.api.getSelectedNodes()[0].allLeafChildren[0].data.accountorContactId;
        let accountOrContacttype = event.api.getSelectedNodes()[0].allLeafChildren[0].data.accountorContactType;
        this.accountContactId = accountOrContactId;
        this.accountOrContactType = accountOrContacttype;
        this.ProjectId = 0;
        this.ViewType = 'Forecast View';
        this.createProj.LoadData(this.accountContactId, this.accountOrContactType, this.ProjectId, this.ViewType);
      }
    }

  }

  getForecastData() {
    this.customerservice.getAllForecastData(this.currentuser.id).then(res => {
      this.ForecastDataObj = res;
    });
  }
  ROwSelection() {
    if (localStorage.getItem('projecttype') == "Pended") {
      this.ProjectId = Number(localStorage.getItem("Pendedprojectid"));
    }
     if (localStorage.getItem('projecttype') == "Cancelled") {
      this.ProjectId = Number(localStorage.getItem("Cancelledprojectid"));
    }
    if (localStorage.getItem('projecttype') == "Complete") {
      this.ProjectId = Number(localStorage.getItem("Completedprojectid"));
    }
    if (localStorage.getItem('projecttype') == "Open"){
      this.ProjectId = Number(localStorage.getItem("projectid"));
    }
    let selfObj = this;
    $(setTimeout(function () {
      if (selfObj.gridOptionsView.api != null) {
        selfObj.gridOptionsView.api.forEachNode((node) => {
          if (node.data.ProjectID == selfObj.ProjectId) {
            selfObj.gridOptionsView.api.selectNode(node, true);
            selfObj.gridOptionsView.rowSelection = 'single';
          }
        });
      }
    }, 500));
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
    this.gridOptionsView.quickFilterText = (document.getElementById('filter-text-box') as HTMLInputElement).value;
    this.createColumnDefs();
    this.gridApi.redrawRows();   
  }
  ClearSearch() {
    this.gridApi.setQuickFilter('');
    this.value = "";
    this.EnableSearch = false;
    this.gridOptionsView.quickFilterText = "";
    this.createColumnDefs();
    this.gridApi.redrawRows();   
  }
}
