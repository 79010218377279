<div class="col-xs-12" style="padding:0 1% 0 0;height:100%">
  <div class="col-xs-12" style="padding:0.3% 0 0.3% 0;height:8%" id="actiondivid">
    <userdataheader [parentComp]="'AdminReports'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
  </div>
  <div class="col-xs-12" style="padding:0;height:89.5%" *ngIf="selectedreporttype == 'Customer Contacts (LK)'">
    <customercontacts [source]="'Reports'"> </customercontacts>
  </div>
  <div class="col-xs-12" style="padding:0;height:89.5%" *ngIf="selectedreporttype == 'Audit Log'">
    <audit-log [selectedtype]="selectedtype" [startDateVal]="startDateVal" [endDateVal]="endDateVal" [pageSize]="pageSize" [UserIds]="UserIds" (sendMsgEvent)="AudittypeReceived($event)"></audit-log>
  </div>
  <div class="col-xs-12" style="padding:0;height:82%" *ngIf=" selectedreporttype == 'Contacts(SaaS Renewals)'
       || selectedreporttype == 'Contacts(SaaS RCM)'">
    <sassdate-contacts [selectedreporttype]="selectedreporttype"> </sassdate-contacts>
  </div>
  <div class="col-xs-12" style="padding:0;height:82%" *ngIf="selectedreporttype == 'Proposal Items'">
    <proposal-items> </proposal-items>
  </div>
  <div class="col-xs-12" style="padding:0;height:89.5%" *ngIf="(selectedreporttype == 'Customer Accounts'||selectedreporttype == 'Customer Accounts Status')">
    <app-tickets-dashboard [type]="'CustomerAccounts'"></app-tickets-dashboard>
  </div>
  <div class="col-xs-12" style="padding:0;height:89.5%;background-color:#F4F4F4" *ngIf="selectedreporttype == 'User Access'">
    <div class="col-xs-6 CollapseCss" id="TodaysAccess" *ngIf="TodaysAccessDiv">
      <div class="col-xs-12" style="padding: 0;height: 12%;background-color: white;color: darkviolet;font-size: 18px;font-weight: 700;">
        <label>Today's Access</label>
        <div class="col-xs-1" style="padding:0;height:100%;float:right">
          <img src="../../../assets/img/Expand View.png" style="height:70%;width:50%;float:right" *ngIf="!ExpandTodaysAccess" (click)="Expand('TodaysAccess','Expand')" />
          <img src="../../../assets/img/Collapse View .png" style="height:40%;width:25%;float:right" *ngIf="ExpandTodaysAccess" (click)="Expand('TodaysAccess','Collapse')" />
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;height:88%;background-color:white">
        <div class="col-md-12" style="height:100%" *ngIf="barChartData">
          <canvas baseChart height="100"
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [options]="barChartOptions"
                  [legend]="barChartLegend"
                  [colors]="TodaysColors"
                  [chartType]="barChartType"></canvas>
        </div>
      </div>
    </div>

    <div class="col-xs-6 CollapseCss" id="WeeklyAccess" *ngIf="WeeklyAccessDiv">
      <div class="col-xs-12" style="padding: 0;height: 12%;background-color: white;color: #23B14D;font-size: 18px;font-weight: 700;">
        <label>Weekly Access</label>
        <div class="col-xs-1" style="padding:0;height:100%;float:right">
          <img src="../../../assets/img/Expand View.png" style="height:70%;width:50%;float:right" *ngIf="!ExpandWeeklyAccess" (click)="Expand('WeeklyAccess','Expand')" />
          <img src="../../../assets/img/Collapse View .png" style="height:40%;width:25%;float:right" *ngIf="ExpandWeeklyAccess" (click)="Expand('WeeklyAccess','Collapse')" />
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;height:88%;background-color:white">
        <div class="col-md-12" style="height:100%" *ngIf="barChartWeekData">
          <canvas baseChart height="100"
                  [datasets]="barChartWeekData"
                  [labels]="barChartWeekLabels"
                  [options]="barChartOptions"
                  [legend]="barChartLegend"
                  [colors]="WeeklyColors"
                  [chartType]="barChartType"></canvas>
        </div>
      </div>
    </div>

    <div class="col-xs-6 CollapseCss" id="MonthlyAccess" *ngIf="MonthlyAccessDiv">
      <div class="col-xs-12" style="padding: 0;height: 12%;background-color: white;color: orange;font-size: 18px;font-weight: 700;">
        <label>Monthly Access</label>
        <div class="col-xs-1" style="padding:0;height:100%;float:right">
          <img src="../../../assets/img/Expand View.png" style="height:70%;width:50%;float:right" *ngIf="!ExpandMonthlyAccess" (click)="Expand('MonthlyAccess','Expand')" />
          <img src="../../../assets/img/Collapse View .png" style="height:40%;width:25%;float:right" *ngIf="ExpandMonthlyAccess" (click)="Expand('MonthlyAccess','Collapse')" />
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;height:88%;background-color:white">
        <div class="col-md-12" style="height:100%" *ngIf="barChartMonthData">
          <canvas baseChart height="100"
                  [datasets]="barChartMonthData"
                  [labels]="barChartMonthLabels"
                  [options]="barChartOptions"
                  [legend]="barChartLegend"
                  [colors]="MonthlyColors"
                  [chartType]="barChartType"></canvas>
        </div>
      </div>
    </div>

    <div class="col-xs-6 CollapseCss" id="YearlyAccess" *ngIf="YearlyAccessDiv">
      <div class="col-xs-12" style="padding: 0;height: 12%;background-color: white;color: #4394db;font-size: 18px;font-weight: 700;">
        <label>Year to Date Access</label>
        <div class="col-xs-1" style="padding:0;height:100%;float:right">
          <img src="../../../assets/img/Expand View.png" style="height:70%;width:50%;float:right" *ngIf="!ExpandYearlyAccess" (click)="Expand('YearlyAccess','Expand')" />
          <img src="../../../assets/img/Collapse View .png" style="height:40%;width:25%;float:right" *ngIf="ExpandYearlyAccess" (click)="Expand('YearlyAccess','Collapse')" />
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;height:88%;background-color:white">
        <div class="col-md-12" style="height:100%" *ngIf="barChartYearData">
          <canvas baseChart height="100"
                  [datasets]="barChartYearData"
                  [labels]="barChartYearLabels"
                  [options]="barChartOptions"
                  [legend]="barChartLegend"
                  [colors]="YearlyColors"
                  [chartType]="barChartType"></canvas>
        </div>
      </div>
    </div>

    <div class="col-xs-12 CollapseCss" id="CustomDatesAccess" *ngIf="CustomDatesAccessDiv" style="height:100%">
      <div class="col-xs-12" style="padding: 0;height: 7%;background-color: white;color: darkviolet;font-size: 18px;font-weight: 700;">
        <label>CustomDates</label>
        <div class="col-xs-1" style="padding:0;height:100%;float:right;text-align:right">
          <label style="float:right;" (click)="Expand('CustomDateAccess','Collapse')">X</label>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;height:92%;background-color: white;">
        <div class="col-md-12" style="height:100%">
          <canvas baseChart height="100"
                  [datasets]="barChartCustomDateData"
                  [labels]="barChartCustomDateLabels"
                  [options]="barChartOptions"
                  [legend]="barChartLegend"
                  [colors]="CustomColor"
                  [chartType]="barChartType"></canvas>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12" style="padding: 0; height: 100%;" *ngIf="selectedreporttype == 'Users Logged In'">
    <div class="col-xs-12" style="padding: 1%; height:6%">
      <span style="font-size:18px">
        Users Logged In
      </span>

    </div>
    <div class="col-xs-12" style="height:94%;">
      <ag-grid-angular style="height:600px;" class="ag-theme-balham"
                       [gridOptions]="gridOptions"
                       [columnDefs]="columnDefs"
                       [rowData]="loggedinUserlist"
                       (gridReady)="onAgGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>


</div>
  <app-dialog [(visible)]="ShowCustomDatesDialog">
    <div class="col-xs-12" style="padding:0;height:100%">
      <div class="col-xs-12" style="text-align:center;height:40%;padding:5%"><span style="font-size:18px">Turn "On" Custom Dates for User Access?</span></div>


      <div class="col-xs-12">


        <div class="col-xs-4" style="margin:2% 0 0 15%;padding:0">
          <button class="btn noprovider" (click)="Expand('CustomDateAccess','Expand')" style="width: 80%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000 !important;border-radius: 5px;margin-left:2%">Yes</button>
        </div>
        <div class="col-xs-4" style="margin:2% 0 0 10%;padding:0;">
          <button class="btn noprovider" (click)="Expand('CustomDateAccess','Collapse')" style="width: 80%;background-color: lightgrey;color: black;font-weight: 700;border: 1px solid #000 !important;border-radius: 5px;margin-left:2%">No</button>
        </div>
      </div>
    </div>
  </app-dialog>
