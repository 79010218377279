export class AddressUpdate {
  public ContactIds: string;
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public zip: string;
  public AccountId: number;
  public longtitude: string;
  public lattitude: string;
  public mapit: number;
  public cell: string;
  public id: number;
}
