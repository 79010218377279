<div class="col-xs-12" style="padding:0;height:100%">
  <div class="col-xs-12" style="background-color: rgb(186,219,152); height: 2% ">

  </div>
  <div class="col-xs-11" style="height: 4%;text-align: left ">
    <label style="padding: 3% 0% 0% 0%; text-transform: uppercase;">Contact us</label>
  </div>
  <div class="col-xs-1" (click)="CancelContactUs();"
       style="padding: 0; height: 4%;text-align: left ">
    <img src="../../../assets/img/Black x.png" style="height: 20px; margin-top: 25%;" />
  </div>
  <div class="col-xs-12" style="padding:0;">
    <img src="../../assets/img/SalesXCRM Logo with New Slogan.png" style="height:95px;" />
  </div>
  <div class="col-xs-12" style="padding:0;">

    <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding:0;">
      <div class="col-xs-12">
        <label style="">User Name *</label>
      </div><br />
      <div class="col-xs-12">
        <input type="email" [(ngModel)]="contactus.username" class="form-control" disabled style="height: 40px;border-radius: 10px;border: 1px solid black;color: black;background-color:#EAEAEA">
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 2%;padding:0;">
      <div class="col-xs-12">
        <label style="">Email *</label>
      </div><br />
      <div class="col-xs-12">
        <input type="email" [(ngModel)]="contactus.email" class="form-control" disabled style="height: 40px;border-radius: 10px;border: 1px solid black;color: black;background-color:#EAEAEA">
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 2%;padding:0;">
      <div class="col-xs-12">
        <label style="">Phone *</label>
      </div><br />
      <div class="col-xs-12">
        <input type="text" [(ngModel)]="contactPhone" [textMask]="{mask: phonemask}" class="form-control" style="height: 40px;border-radius: 10px;border: 1px solid black;color: black;background-color:#EAEAEA">
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 2%;padding:0;">
      <div class="col-xs-12">
        <label style="">Comments or Questions *</label>
      </div><br />
      <div class="col-xs-12">
        <textarea [(ngModel)]="contentBody" style="width:100%;height:24vh;background-color:#EAEAEA">
                </textarea>
      </div>
    </div>
    <!--</div>-->

  </div>
  <div class="col-xs-12" style="height:5%;padding:0;">
    <div class="col-xs-6"></div>
    <div class="col-xs-6" style="height:100%">
      <div class="col-xs-6" style="padding:0;height:100%"><button class="btn noprovider" style="width:100%;height:100%; background:white" id="CancelOpp" (click)="CancelContactUs()"><span class="mainCheckMark"></span>Cancel</button></div>
      <div class="col-xs-6" style="padding:0;height:100%"><button class="btn noprovider" style="width:100%;height:100%; background:rgb(186,219,152)" (click)="Send();"><span class="mainCheckMark"></span>Send</button></div>
    </div>
  </div>

</div>
