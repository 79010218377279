
<input type="text" id="emailIds" style="display:none;" />
<input type="text" id="tasklistselectedtyle" style="display:none;" />
<div class="container" style="padding:0">
  <div [hidden]="FromSelector" style="padding:0;height:100%">
    <div class="col-xs-12" style="padding:0;margin:0.5% 0 0 0; width:100%;height:6%;" id="actiondivid" *ngIf="!showContactDashBoard && source!='Reports'">
      <userdataheader [parentComp]="'CustContacts'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
    </div>
    <div class="col-xs-12 input-icons" style="padding: 7px; margin-bottom: 0.3%; border: 1px solid lightgray; width:100%">
      <i class="fa fa-search icon"></i>
      <input type="text" class="searchform-control" id="filter-text-box" placeholder="Search" (input)="onFilterTextBoxChanged()" [(ngModel)]="value" />
    </div>

    <div [ngClass]="(applicationComponent.googlemapshow) ? 'col-xs-5':showContactDashBoard?'col-xs-4':'col-xs-12'" style="padding:0;height:91.5%;" *ngIf="isContactViewOpen">
      <div style="height: 4%; margin-top: 1%;" *ngIf="showContactDashBoard && isContactViewOpen">
        <span (click)="CloseContactDiv()"><img src="../../../assets/img/Black x.png" style="height: 21px; float: left; transform: rotate(180deg);" /></span>
        <b style="font-size: 10pt; margin-left: 10px;"> Customer Contacts</b>
        <span (click)="expandContactView('Collapse')"><img src="../../../assets/img/Open Quickview Grid Panel.png" style="height: 23px; float: right; transform: rotate(180deg);" /></span>
      </div>

      <ag-grid-angular style="width: 100%; height:96% !important;" class="ag-theme-balham"
                       [gridOptions]="gridOptions"
                       [columnDefs]="columnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="allContacts"
                       [context]="context"
                       [sideBar]="sideBar"
                       [frameworkComponents]="frameworkComponents"
                       (rowSelected)="rowClickAgGrid($event)"
                       (cellDoubleClicked)="cellDoubleClicked($event)"
                       (cellValueChanged)="onCellValueChanged($event)"
                       [suppressDragLeaveHidesColumns]="true"
                       (columnVisible)="columnVisible($event)"
                       (dragStopped)="dragStopped($event)"
                       [suppressRowClickSelection]="true"
                       (selectionChanged)="onSelectionChanged($event)"
                       (columnResized)="columnResized($event)"
                       (gridReady)="onAgGridReady($event)">
      </ag-grid-angular>

    </div>

    <div class="col-xs-4" style="padding:0;height:100%" *ngIf="!isContactViewOpen">
      <div class="col-xs-12" style="padding:0;height:4%">
        <span (click)="expandContactView('Expand')"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
      </div>
      <div class="col-xs-12 scroll-c" style="padding:0;height:100%">

        <app-new-notes style="height:100%"
                       [selectedTabName]="'1'"
                       [conid]="contactid"
                       [typ]="'Contact'"
                       [ViewType]="'Block'"
                       [isModal]="false">
        </app-new-notes>
      </div>
    </div>
    <div *ngIf="(applicationComponent.googlemapshow)" class="col-xs-7" style="padding:0px;height:100%">
      <addressmap [AccConIds]="ContactIds" [accesstype]="'Contacts'"></addressmap>
    </div>

    <div class="col-xs-8" *ngIf="showContactDashBoard" style="height:94%;padding-right:0;">
      <contactsdashboard [contactids]="contactid" [type]="'contacts'" [tab]="'QuickView'" *ngIf="!isShowAccountInfo" (sendMsgEvent)="onContactMessageReceived($event)">
      </contactsdashboard>

      <account-contact *ngIf="isShowAccountInfo" [SelectedContactId]="contactid" [type]="'Account'" [contactId]="'show'+ accountid" [tab]="'QuickView'"></account-contact>
    </div>

    <input type="text" id="accotIdNotes" style="display:none;" />
    <input type="text" id="accotTypeNotes" style="display:none;" />

    <app-dialog [(visible)]="notesshowDialog" *ngIf="notesshowDialog" [CloseButtonColor]="'white'">
      <div class="col-xs-12" style="height:6%;background-color:#2A3740;color:white;text-align:center;padding:0%">
        <div class="col-xs-1" style="padding: 0; text-align: left; padding: 1% 0 0 1%;">
          <img src="../../../assets/img/White Expand View.png" style="height:21px" (click)="ExpandPDF(true)" *ngIf="!DocExpandView" />
          <img src="../../../assets/img/White Collapse View.png" style="height:21px" (click)="ExpandPDF(false)" *ngIf="DocExpandView" />
        </div>
        <div class="col-xs-11" style="padding:1% 0 0 0;height:100%"><label>ACCOUNT DETAILS</label></div>
      </div>
      <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%">
        <app-new-notes style="height:100%"
                       [selectedTabName]="'Contact Only'"
                       [conid]="contactid"
                       [typ]="'Contact'"
                       [isModal]="false">
        </app-new-notes>

      </div>

    </app-dialog>
  </div>

</div>

<app-dialog [(visible)]="CallHistoryDialog">
  <calllisthistory [id]="contactid" [type]="callhistorystatus"></calllisthistory>
</app-dialog>
<div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
  <textarea *ngIf="isShowDetailsChecked" id="txtUserFullName" readonly rows="3" name="userInfo" [(ngModel)]="userInfo" style="width:100%; border: none;"></textarea>

  <div class="col-xs-12" style="padding:0;">
    <button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog" style="float:left;position:unset; "><i class="fa fa-window-close-o"></i></button>
  </div>

  <div class="box" style="height:98%;border:none">
    <span *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" class="editnote">Edit</span>
    <span *ngIf="showEditNoteBtn" (click)="saveEditedNote()" class="save">Save</span>
    <div id="txtLongNoteDesc" class="text">
    </div>
  </div>
</div>
<div *ngIf="MapItEventListner()"></div>
<app-dialog [(visible)]="DeleteConfirmationPopup" *ngIf="DeleteConfirmationPopup" id="DeleteConfirmationPopup" [closable]="false">
  <app-delete-popup [status]="0" [title]="'Contact'" [description]="(isdeletedstatustext==1) ?'Activate Contact Selected?' :'Delete Contact Selected?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteevent($event)">
  </app-delete-popup> 
</app-dialog>


<app-dialog [(visible)]="showAddEditContact" *ngIf="showAddEditContact" id="addEditContact" [closable]="false" [OutSideClick]="false">
  <editcontact [contactid]="contactid" [accountId]="accountid" [type]="'Contact'" (sendMsgEvent)="ContactReceiveEvent($event)"></editcontact>
</app-dialog>
