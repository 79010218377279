export class QuestionResponses {
  public id: number;
  public questionid: number;
  public response: string;
  public responseuserid: number;
  public isdeleted: number;
  public ResponseID: number;
  public isbolded: number;
}

