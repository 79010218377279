<div class="col-xs-12" style="padding:0;text-align: left;">
  <div class="col-xs-12 col-sm-12 col-md-12" style="padding: 1%;background-color: lightgray;padding-bottom: 0;">
    <span>ACCOUNT VIEW</span>
  </div>
  <div id="accountInformation" class="col-xs-" style="padding-left:0;padding-right:0; height:125px !important;margin-top:4%; display:block">
    <div>
      <div class="col-xs-12 col-sm-12 col-md-12" style="padding:0;border-radius:3px;">
        <div class="col-xs-12">
          <b class="providerLabel" style="font-size:14px;color:red;">{{account.accountname}}</b>
          <div class="col-xs-12 col-sm-12 col-md-4 pull-right" style="text-align: right;padding-right: 0;">
            Owner : <label id="ownerlabel">  {{this.currentuser.username}}</label>
          </div>
        </div>
        <div class="col-xs-6 scroll-c" style="height:100px;">
          <div class="providerLabel">{{account.address1}}&nbsp;</div>
          <div>{{account.address2}}</div>
          <div>
            <span>{{account.city}} , {{account.state}}.  {{account.zip | slice:0:5}}<span *ngIf="account.zip && account.zip.length > 5">-{{account.zip | slice:5:20}}</span>, {{account.country}}</span>
          </div>
          <div>
            <span *ngIf="account.phone!=null && account.phone!=''">Phone : {{account.phone}}</span>
            <span *ngIf="(account.phone!=null && account.phone!='') && (account.fax!=null && account.fax!='')">, </span>
            <span *ngIf="account.fax!=null && account.fax!=''">Fax: {{account.fax}}</span>
          </div>
          <div>
            <span *ngIf="account.website != null && account.website!=''">{{account.website}},&nbsp;&nbsp;</span>
            <span *ngIf="account.multiplelocations === 0">Multiple Locations: No</span>
            <span *ngIf="account.multiplelocations !== 0">Multiple Locations: Yes</span>
          </div>
        </div>


      </div>
    </div>

  </div>

  <div class="col-xs-12" id="providerDetails" style="display:block; margin-top:2%">
    <div class="otherCss col-xs-12" style="padding-left:0;padding-right:0;padding-top:0">
      <span class="col-xs-9" style="text-align:left; font-size:12px;padding: 0">Contacts ( {{ContactsprovidersitemCount}} )</span>
    </div>
    <div class="col-xs-12" style="padding:0">
      <ag-grid-angular class="ag-theme-balham"
                       style="height:230px;"
                       [gridOptions]="gridOptionsProvider"
                       [columnDefs]="columnDefsProvider"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="allContactsproviders"                      
                       (gridReady)="onAgGridReadyProvider($event)">
      </ag-grid-angular>
    </div>

  </div>

</div>
