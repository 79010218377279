<div class="container">
  <div class="row" style="display:flex;flex:auto;padding:0;margin:0.5% 0 0 0; width:100%;height:5%" id="actiondivid">
    <div class="col-md-4 col-sm-6 col-xs-12" style="padding:  0;">
      <!--<div class="col-md-2 col-sm-1 col-xs-3" style="padding:  0;">
        <label style="padding-top:5px">User List</label>
      </div>
      <div class="col-md-10 col-sm-11 col-xs-9" style="padding: 0%;padding-right: 0.5%;">
        <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                              [settings]="dropdownSetting"
                              (onSelect)="onItemSelect($event)"
                              (onDeSelect)="OnItemDeSelect($event)"
                              (onSelectAll)="onSelectAll($event)"
                              (onDropdownClose)="onDropdownClose($event)"
                              (onDeSelectAll)="onDeSelectAll($event)">
        </angular2-multiselect>

      </div>-->
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12" style="padding:  0;">
   
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12" style="padding: 0;">
      <div class="col-xs-3" style="text-align:right;padding:0;padding-right:2%">
        <button *ngIf="calanderonoff" (click)="calanderclick(false)" class="" style="padding:0;height:28px;background:white;">
          <img src="../../assets/img/range on.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
        </button>
        <button *ngIf="!calanderonoff" (click)="calanderclick(true)" class="" style="padding:0;height:28px;background:white;">
          <img src="../../assets/img/range off.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
        </button>
      </div>
      <div class="col-xs-12 col-md-3 col-sm-12 " style="padding: 0;" disabled>
        <my-date-picker id="startDate" name="startDate" [options]="startDatePickerOptions" [placeholder]="startDatePlaceHolder" [ngStyle]="{'pointer-events': calanderonoff ? 'auto' : 'none' }" (dateChanged)="dateChange($event)" style="border-left:none !important" required></my-date-picker>
      </div>
      <div class="col-xs-12 col-md-3 col-sm-12" style="padding: 0;    padding-left: 0.3%; padding-right: 0;">
        <my-date-picker name="endDate" [options]="endDatePickerOptions" [placeholder]="endDatePlaceHolder" [ngStyle]="{'pointer-events': calanderonoff ? 'auto' : 'none' }" (dateChanged)="dateChange($event)" style="border-left:none !important" required></my-date-picker>
      </div>

      <div class="col-md-3 col-sm-12 col-xs-12" style="padding: 0;padding-left: 3%;">
        <label class="col-sm-2 control-label" style="padding:0;padding-left:2%; margin-top: 5px;">Size</label>
        <div class="col-sm-10" style="padding-right:0">
          <input class="form-control" type="text" style="height: 29px;" id="pagesize" name="Page Size" [(ngModel)]="pageSize" (change)="txtPageSizeChanged($event.target.value)">
        </div>
      </div>
    </div>
  </div>
</div>
