<div class="col-xs-12" style="height:100%;padding:0;padding-right:2%" id="conatctUploadDivId">
  
  <div class="col-xs-12 scroll-c" style="overflow-y:auto;overflow-x:hidden;padding:0;max-height:80%;" id="addDetailsDiv" [style.height.%]="selectedtype =='MySalesXCRM' ? '50' : '80'">

    <ag-grid-angular style="width: 100%;height: 100%" class="ag-theme-balham" id="checkduplicateGrid"
                     [gridOptions]="gridOptions"
                     [columnDefs]="documentscolumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="documentlist"
                     [sideBar]="sideBar"
                     (gridReady)="onGridReady($event)"
                     (cellValueChanged)="onCellValueChanged($event)">

    </ag-grid-angular>

  </div>
  <div class="col-xs-12" style="height: 10%;padding:0;">
   
    <div class="col-xs-3 pull-right" style="padding:0;">
      <div class="col-xs-6" style="margin-top:10px;padding-left: 1%;padding-right: 0;" id="Cancel">
        <button class="btn displayButton" id="canbtn" (click)="Cancel()" style="width:99.5%;border:1px solid black">Cancel</button>
      </div>
      <div class="col-xs-6" id="Save" style="margin-top:10px;padding:0">
        <input *ngIf="!isDocumentManagement" id="addFiles" name="AddFilesInput" type="file" (change)="addFiles($event)" multiple accept=".pdf" style=" opacity: 0; position: absolute; z-index: -1;display:none;" />
        <button type="submit" class="btn saveButton" (click)="SaveDocuments(0)" [disabled]="DisableSaveButton" style="width: 78%;float: right;border:1px solid;">Save</button>

      </div>
     



    </div>

  </div>
</div>
