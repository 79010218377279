<div class="col-xs-12" style="padding:0;height:100%">
  <div class="col-md-12 col-sm-12 col-xs-12" style="padding:0 0 0 0;margin:0.5% 0 0 0;height:7%">
    <userdataheader [parentComp]="projectType" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
  </div>
  <div [ngClass]="(accountContactId <= 0 || accountContactId == null || accountContactId == undefined)? 'col-xs-12':'col-xs-4'" style="padding:0;"
       [style.height.%]="(accountContactId <= 0 || accountContactId == null || accountContactId == undefined)? '92':'92'" *ngIf="ShowProjectsGrid">

    <div style="height:4%" *ngIf="(accountContactId > 0 && accountContactId !== null && accountContactId !== undefined)  && ShowProjectsGrid">
      <span (click)="CloseContactDiv()"><img src="../../../assets/img/Black x.png" style="height: 21px; float: left; transform: rotate(180deg);" /></span>
      <b style="margin-left:2%"> PROJECTS ({{DuplicateProjectsList.length}})</b>
      <span (click)="ShowProjectsGrid = false;ProjectForeCast();"><img src="../../../assets/img/Open Quickview Grid Panel.png" style="height: 23px; float: right; transform: rotate(180deg);" /></span>
    </div>
    <div class="col-xs-12 input-icons" style="padding: 7px; margin-bottom: 0.3%; border: 1px solid lightgray; width:100%">
      <i class="fa fa-search icon"></i>
      <input type="text" class="searchform-control" id="filter-text-box" placeholder="Search" (input)="onFilterTextBoxChanged()" [(ngModel)]="value" />
    </div>
    <div class="col-xs-12" style="padding:0; height:94.5%">
      <ag-grid-angular style="height:96%;" class="ag-theme-balham" *ngIf="!ForecastView"
                       [gridOptions]="gridOptionsView"
                       [columnDefs]="columnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="DuplicateProjectsList"
                       [sideBar]="sideBar"
                       [context]="context"
                       (cellClicked)="cellClicked($event)"
                       (selectionChanged)="onSelectionChanged($event)"
                       (gridReady)="onAgGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>

  <div *ngIf="!ShowProjectsGrid" class="col-xs-4" style="padding:0;height:92%;">
    <div class="col-xs-12" style="padding:0;height:4%">
      <span (click)="ShowProjectsGrid = true;ROwSelection();"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
    </div>
    <div class="col-xs-12" style="height: 5%; margin-top: 0%; background-color: #2A3740; color: WHITE; padding: 0.8%; text-align: center; ">
      <label>PROJECT FORECAST</label>
    </div>
    <div class="ag-theme-balham" style="padding:0;height:100%">
      <ag-grid-angular style="height:95%;"
                       [gridOptions]="gridProjectForeCast"
                       [columnDefs]="ProjectForecastViewcolumnDefs"
                       [rowData]="ProjectForecaslist"
                       [animateRows]="true"
                       (selectionChanged)="onSelectionChangedForeCast($event)">

      </ag-grid-angular>
    </div>
  </div>


  <div class="col-xs-8" style="padding:0;" *ngIf="accountContactId > 0" [style.height.%]="(accountContactId <= 0 || accountContactId == null || accountContactId == undefined)? '92':'92'">
    <app-create-projects [accountContactId]="accountContactId" [accountContactType]="accountOrContactType" [ProjectId]="ProjectId" [ViewType]="ViewType" (sendMsgEvent)="EventEmit($event)"></app-create-projects>
  </div>

  <app-dialog [(visible)]="DeleteConfirmation" *ngIf="DeleteConfirmation" id="DeleteConfirmation" [closable]="false">
    <app-delete-popup [MessageType]="'DeleteProj'" [title]="'This will delete the selected Project'"
                      [description]="'Are You Sure?'" [isok]="true" [issave]="false"
                      [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteevent($event)">
    </app-delete-popup>
    <!--<div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br /><br />

    <div class="col-xs-8" style="padding:0;margin-top:1%">
      <div class="col-xs-12" style="padding: 0; ">This will delete the selected Project .</div>
      <div class="col-xs-12" style="padding: 0; ">Are You Sure?</div>
    </div>

    <div class="col-xs-4" id="MyButtonsDiv" style="padding:0;">
      <button class="btn" style="background-color: #0098f7 !important;color: white;" (click)="Confirmation('Yes')">OK</button>
      <button class="btn" style="margin-left: 4%;" (click)="Confirmation('No')">Cancel</button>

    </div>
  </div>-->

  </app-dialog>

</div>
