<div class="container" style="height:100%;padding:0">
  <input type="text" id="accotIdNotes" style="display:none;" />
  <input type="text" id="accotTypeNotes" style="display:none;" />
  <div class="col-xs-12" style="padding:0.3% 0 0.3% 0;" id="actiondivid">
    <userdataheader [parentComp]="'Opportunities'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
  </div>
 
  <div [ngClass]="(Showaddopportunity == true)? 'col-xs-4':'col-xs-12'" style="height:100%;padding:0;" *ngIf="!addopportunityExpand">
    <input type="text" id="tasklistselectedtyle" style="display:none;" />
    <div *ngIf="Showaddopportunity" class="col-xs-12" style="padding:0.3% 0 0.3% 0;height:4%;">
      <span (click)="CloseOppDiv()"><img src="../../../assets/img/Black x.png" style="height: 21px; float: left; transform: rotate(180deg);" /></span>
      <b style="font-size: 10pt; margin-left: 10px;">
        OPPORTUNITIES ({{Allopportunities? Allopportunities.length:'0'}})
      </b>
      <span (click)="expandOpportunityView('Expand')"><img src="../../../assets/img/Open Quickview Grid Panel.png" style="height: 23px; float: right; transform: rotate(180deg);" /></span>
    </div>
    <div class="col-xs-12" style="padding:0;margin:0.5% 0 0 0;height:89.5%">
      <div [ngClass]="(ShowPdfDiv == true)? 'col-xs-7':'col-xs-12'" style="padding: 0px;height:100%" *ngIf="!ExpandPDFDiv">
        <div class="col-xs-12" style="height:35%;padding:0;" *ngIf="!Showaddopportunity && !ExpandOppGrid && applicationComponent.closedOpportunityVal == 'Open'">
          <div class="col-xs-6" style="height:100%;padding:0;">
            <div class="col-xs-5" style="height:100%;padding:0;">
              <div class="col-xs-12" style="height: 99%;margin-top: 1%;padding:0;">
                <div class="col-xs-12" style="height: 20%;padding: 3% 0 0 0.5%;">
                  <label>Opportunity Pipeline</label>
                </div>

                <div class="col-xs-12 daysFilterCss" id="DisplayAll">
                  <label class="container1">
                    <input type="radio" checked="checked" name="FilterByDays" (click)="FilterByDays('DisplayAll')" /><span class="checkmark"></span>
                    <span>Display All</span>
                    <Span style="float:right">{{nonFilteredOpportunities != null? nonFilteredOpportunities.length : 0}}</Span>
                  </label>

                </div>
                <div class="col-xs-12 daysFilterCss" id="30">
                  <label class="container1">
                    <input type="radio" name="FilterByDays" (click)="FilterByDays('30')" /><span class="checkmark"></span>
                    <span>30 Days</span>
                    <Span style="float:right">{{Below30Days}}</Span>
                  </label>

                </div>

                <div class="col-xs-12 daysFilterCss" id="31-60">
                  <label class="container1">
                    <input type="radio" name="FilterByDays" (click)="FilterByDays('31-60')" /><span class="checkmark"></span>
                    <span>31-60 Days</span>
                    <Span style="float:right">{{Betwn31_60Days}}</Span>
                  </label>
                </div>

                <div class="col-xs-12 daysFilterCss" id="61-90">
                  <label class="container1">
                    <input type="radio" name="FilterByDays" (click)="FilterByDays('61-90')" /><span class="checkmark"></span>
                    <span>61-90 Days</span>
                    <Span style="float:right">{{Betwn61_90Days}}</Span>
                  </label>
                </div>

                <div class="col-xs-12 daysFilterCss" id="91-120">
                  <label class="container1">
                    <input type="radio" name="FilterByDays" (click)="FilterByDays('91-120')" /><span class="checkmark"></span>
                    <span>91-120 Days</span>
                    <Span style="float:right">{{Betwn91_120Days}}</Span>
                  </label>
                </div>
                <div class="col-xs-12 daysFilterCss" id="120+">
                  <label class="container1">
                    <input type="radio" name="FilterByDays" (click)="FilterByDays('120+')" /><span class="checkmark"></span>
                    <span>120+ Days</span>
                    <Span style="float:right">{{Above120Days}}</Span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-xs-6" style="height:100%;margin-left:3%">
              <div class="col-xs-12" style="height:98%;margin-top: 1%">
                <div class="col-xs-12" style="height:20%;padding: 3% 0 0 0.5%;"><label>Opportunity Statistics</label></div>
                <div class="col-xs-12" style="height:12.5%;padding:0 0 0 0.5%">
                  <div class="col-xs-8" style="padding:0;"><span>Number of Opps:</span></div>
                  <div class="col-xs-4" style="padding:0;"><Span>{{Allopportunities != null? Allopportunities.length : 0}}</Span></div>
                </div>
                <div class="col-xs-12" style="height:12.5%;padding: 0 0 0 0.5%">
                  <div class="col-xs-8" style="padding:0;"><span>Total Price:</span></div>
                  <div class="col-xs-4" style="padding:0;"><Span>{{totalPrice | currency}}</Span></div>
                </div>
                <div class="col-xs-12" style="height:12.5%;padding: 0 0 0 0.5%">
                  <div class="col-xs-8" style="padding:0;"><span>Avg. Price:</span></div>
                  <div class="col-xs-4" style="padding:0;"><Span>{{avgPrice | currency}}</Span></div>
                </div>
                <div class="col-xs-12" style="height:12.5%;padding:0 0 0 0.5%">
                  <div class="col-xs-8" style="padding:0;"><span>Total Monthly:</span></div>
                  <div class="col-xs-4" style="padding:0;"><Span>{{totalMonthlyPrice | currency}}</Span></div>
                </div>
                <div class="col-xs-12" style="height:12.5%;padding:0 0 0 0.5%">
                  <div class="col-xs-8" style="padding:0;"><span>Avg. Monthly:</span></div>
                  <div class="col-xs-4" style="padding:0;"><Span> $ {{avgMonthlyPrice | currency}}</Span></div>
                </div>
              </div>

            </div>

          </div>

          <div class="col-xs-6 box-body" style="height:100%;padding:0;">
            <div class="col-xs-12" style="height:2%;"></div>
            <div class="col-xs-12" style="height:98%;padding:0;">
              <div class="col-md-5 headerCSS" style="width:50%;padding:3%">               
                <canvas baseChart
                        [data]="customerpieChartData"
                        [chartType]="customerpieChartType"
                        [colors]="customerpieChartcolors"
                        [labels]="customerpieChartLabels"
                        [legend]="false"></canvas>
              </div>
              <div *ngIf="(userIds != null)" class="col-xs-1" style="padding:0px;margin-top:2%;font-size:16px !important;width:5%">
                <div *ngFor="let lbl of pieColors">
                  <div [ngStyle]="{'background-color': lbl , 'width':'20px' , height:'10px'}"></div><br />
                </div>
              </div>
              <div class="col-md-6" style="padding:0px;margin-top:0;width:45%;height:98%">
                <div class="col-xs-12" style="height:85%;padding:0;">
                  <div *ngFor="let lbl of customerpieChartLabels1" class="col-xs-12" style="padding:0;width:98%">
                    <div class="col-xs-9" style="padding:0;">
                      <h6>{{lbl.name}}</h6>
                    </div>
                    <div class="col-xs-3" style="padding:0;">
                      <h6>{{lbl.percentage}}</h6>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 input-icons" style="padding: 7px; margin-bottom: 0.3%; border: 1px solid lightgray; width:100%">
          <i class="fa fa-search icon"></i>
          <input type="text" class="searchform-control" id="filter-text-box" placeholder="Search" (input)="onFilterTextBoxChanged()" [(ngModel)]="value" />
        </div>
        <div class="col-xs-12" style="padding:0;" [style.height.%]="(Showaddopportunity == true)? '100':(ExpandOppGrid == true)? '100':(applicationComponent.closedOpportunityVal == 'Open')? '63':'100' ">
          <div class="col-xs-12" style="padding:0;" [style.height.%]="(ExpandOppGrid == true)? '95':'100'" [style.border]="(applicationComponent.searchCriteriadata != '')?'3px solid #00B0F0':'none'">
            <div *ngIf="!Showaddopportunity" class="col-xs-12" (click)="CommissionEstimator(true)" style="padding: 0px; margin-bottom: 4px;">
              <img src="../../../assets/img/Comm Calculator Icon.png" style="height:20px;width:2.0%;float:right" />
            </div>
            <ag-grid-angular style="width: 100%;height:95%" id="gridheight" (window:resize)="ResizeTable()"
                             class="ag-theme-balham" [gridOptions]="gridOptions"
                             [columnDefs]="columnDefs"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="Allopportunities"
                             [context]="context"
                             [sideBar]="sideBar"
                             [frameworkComponents]="frameworkComponents"
                             (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                             (columnVisible)="columnVisible($event)"
                             (dragStopped)="dragStopped($event)"
                             (columnResized)="columnResized($event)"
                             (selectionChanged)="onOppChanged($event)"
                             (gridReady)="onAgGridReady($event)">
            </ag-grid-angular>
          </div>
        </div>
      </div>

      <div [ngClass]="(ExpandPDFDiv == true)? 'col-xs-12':'col-xs-5'" style="padding:0;height:100%" *ngIf="ShowPdfDiv == true">
        <div class="col-xs-12" style="padding:0;height:5%;background-color:#2A3740">
          <div class="col-xs-2" style="padding:0;height:100%;color:white">
            <img src="../../../assets/img/White x.png" style="height:20px" (click)="closeShowPdfDiv()" />

          </div>
          <div class="col-xs-2" (click)="ExpandPDF1()" style="padding:0;height:100%;float:right">
            <img src="../../../assets/img/Expand View.png" style="height:85%;width:20%;float:right" *ngIf="!ExpandPDFDiv" />
            <img src="../../../assets/img/Collapse View .png" style="height:85%;width:10%;float:right" *ngIf="ExpandPDFDiv" />
          </div>
        </div>
        <div class="col-xs-12" style="padding:0;height:92%">
          <div id="pdfDiv" class="col-xs-12">

          </div>

        </div>
      </div>
    </div>

    <div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
      <textarea *ngIf="isShowDetailsChecked" id="txtUserFullName" readonly rows="3" name="userInfo" [(ngModel)]="userInfo" style="width:100%; border: none;"></textarea>

      <div class="col-xs-12" style="padding:0;">
        <button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog" style="float:left;position:unset; "><i class="fa fa-window-close-o"></i></button>
      </div>
      <div class="box" style="height:98%;border:none">
        <span *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" class="editnote">Edit</span>
        <span *ngIf="showEditNoteBtn" (click)="saveEditedNote()" class="save">Save</span>
        <div id="txtLongNoteDesc" class="text">
        </div>
      </div>

    </div>

  </div>

  <div class="col-xs-4" style="padding:0;height:100%" *ngIf="addopportunityExpand">
    <div class="col-xs-12" style="padding:0;height:4%">
      <span (click)="expandOpportunityView('Collapse')"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
    </div>
    <div class="col-xs-12 scroll-c" style="padding:0;height:100%">
      <app-new-notes style="height:100%"
                     [selectedTabName]="'1'"
                     [conid]="conid"
                     [typ]="'Contact'"
                     [ViewType]="'Block'"
                     [isModal]="false">
      </app-new-notes>
    </div>

  </div>
  <div class="col-xs-8" id="addopportunity" style="height:98%;padding:0% 0 0 0.5%;width:66.5%" *ngIf="Showaddopportunity">
    <opportunities [typeSelected]="typeSelected" [contactid]="contactid" [OpportunityID]="opportunityids" [type]="typ" (sendMsgEvent)="onReceiveMessage($event)">
    </opportunities>
  </div>


  <app-dialog [(visible)]="SelectOpportunityWarning" id="SelectOpportunityWarning">
    <div class="col-xs-12">
      <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br /><br />
      <span style="font-size:17px;font-weight:600"> No Row Selected </span><br />
      <div style="padding:0;margin-top:0%">
        <span style="font-size:17px;font-weight:600">Please select a row then try again</span><br />
      </div>
      <button class="btn" (click)="SelectOpportunityWarning = false" style="float:right;background-color: #0098f7 !important;color: white;margin-top:-7%">OK</button>
    </div>
  </app-dialog>

  <app-dialog [(visible)]="showpdf1" id="showpdf1" *ngIf="showpdf1" [CloseButtonColor]="'white'">
    <div id="pdfDiv" class="col-xs-12" style="padding:0;">
    </div>
  </app-dialog>

</div>
<app-dialog [(visible)]="isDisplayCommissionEstimator" (visibleChange)="onCloseDialogEventReceived($event)" [CloseButtonColor]="'white'">
  <div class="col-xs-12" style="padding:0;height:100%">
    <div class="col-xs-12 noprovider" style="height: 7%; text-align: center; background: rgb(40,62,74) !important; color: white !important; font-size: 20px; padding-top: 1%">
      Commission Estimator
    </div>
    <div class="col-xs-12" style="height:93%;border:1px solid gray;padding:0;font-size:16px;">
      <div class="col-xs-12" style="padding:3%;height:100%">
        <div class="col-xs-12" style="padding:0;height:7%;"><label>SALES</label></div>
        <div class="col-xs-12" style="padding:0;height:23%">
          <div class="col-xs-12" style="padding:0;height:30%">
            <div class="col-xs-5" style="padding:0;height:100%">Sales Total :</div>
            <div class="col-xs-5" style="padding:0 5% 0 5%;height:100%;">
            $ {{SalesCommission}}              
            </div>
          </div>

          <div class="col-xs-12" style="padding:0;height:30%">
            <div class="col-xs-5" style="padding:0;height:100%">Sales Commission (%) : </div>
            <div class="col-xs-5" style="padding:0 5% 0 0;height:100%;text-align:right">
              <div style=" width: 85%; border: 1px solid black !important; border: 1px solid #ddd !important; float: right; padding: 0; ">
                <input type="number" style="width: 69%; border: none" (blur)="SaleCommissionOpprtunity($event.target.value)" /> %
              </div>
            </div>
          </div>

          <div class="col-xs-12" style="padding:0;height:30%">
          <div class="col-xs-5" style="padding:0;height:100%">Total Sales Commission :</div>
          <div class="col-xs-5" style="padding:0 5% 0 5% ;height:100%;">{{opportunities.salesCommissionOpportunity | currency }}</div></div>
        </div>

        <div class="col-xs-12" style="padding:0;height:7%;"></div>
        <div class="col-xs-12" style="padding:0;height:7%;"><label>RECURRING REVENUE</label></div>
        <div class="col-xs-12" style="padding:0;height:28%">
          <div class="col-xs-12" style="padding:0;height:25%">
            <div class="col-xs-5" style="padding:0;height:100%">RR/Monthly Total :</div>
            <div class="col-xs-5" style="padding:0 5% 0 5%;height:100%;">
             $ {{RRCommission}}
              
            </div>
          </div>

          <div class="col-xs-12" style="padding:0;height:25%">
            <div class="col-xs-5" style="padding:0;height:100%">RR/Sales Commission % :</div>
            <div class="col-xs-5" style="padding:0 5% 0 0;height:100%;text-align:right">
              <div style=" width: 85%; border: 1px solid black !important; border: 1px solid #ddd !important; float: right; padding: 0; ">
                <input type="number" style="border:none;width: 69%" (blur)="RRCommissionOpprtunity($event.target.value)" /> %
              </div>
            </div>
          </div>

          <div class="col-xs-12" style="padding:0;height:25%">
          <div class="col-xs-5" style="padding:0;height:100%">RR/Commission (Monthly) :</div>
          <div class="col-xs-5" style="padding:0 5% 0 5%;height:100%;">{{opportunities.RRCommisssionOpportunityMonthly | currency }}</div></div>

          <div class="col-xs-12" style="padding:0;height:25%">
          <div class="col-xs-5" style="padding:0;height:100%">RR/Commission (Annually) :</div>
          <div class="col-xs-5" style="padding:0 5% 0 5%;height:100%;"> {{opportunities.RRCommisssionOpportunityYearly  }}</div></div>
        </div>

      </div>
    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="showDialog" *ngIf="showDialog" id="showDialog" style="height:100%" [CloseButtonColor]="'white'" (visibleChange)="onCloseDialogEventReceived($event)">
  <div class="col-xs-12" style="height:6%;background-color:#2A3740;color:white;text-align:center;padding:0%">
    <div class="col-xs-1" style="padding: 0; text-align: left; padding: 1% 0 0 1%;">
      <img src="../../../assets/img/White Expand View.png" style="height:21px" (click)="ExpandPDF(true)" *ngIf="!DocExpandView" />
      <img src="../../../assets/img/White Collapse View.png" style="height:21px" (click)="ExpandPDF(false)" *ngIf="DocExpandView" />
    </div>
    <div class="col-xs-11" style="padding:1% 0 0 0;height:100%"><label>ACCOUNT DETAILS</label></div>


  </div>
  <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%">
    <app-new-notes style="height:100%"
                   [selectedTabName]="'Opportunity Only'"
                   [conid]="conid"
                   [typ]="typ"
                   [isModal]="false">
    </app-new-notes>

  </div>

</app-dialog>
