import { Component, OnInit, ViewContainerRef, ElementRef, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Accounts } from '../../models/accounts';
import { Contacts } from '../../models/contacts';
import { Opportunities } from '../../models/opportunities';
import { Notes } from '../../models/notes';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from './../../services/customer.service'
import { TaskHistory } from '../../models/task-history';
import { LeadsService } from './../../services/leads.service';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { Column } from 'ag-grid';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { ApplicationComponent } from '../application.component';
import { AddOpportunityComponent } from './add-opportunity/add-opportunity.component';
import { Viewaccount } from '../../models/viewaccount';
import { OwnershipsharingComponent } from '../ownershipsharing/ownershipsharing.component';
import { AccountDetailsComponent } from '../account-details/account-details.component';
import { UserService } from '../../services/user.service';
declare var $: any;
declare var require: any;
@Component({
  selector: 'opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.css']
})
export class OpportunitiesComponent implements OnInit {
  public currentuser: any;
  @Input()
  type: string;
  @Input()
  contactid;
  @Input()
  typeSelected: string = "";
  @Input()
  OpportunityID;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  OppId: number = 0;
  editOpp: boolean = true;
  @ViewChild(AddOpportunityComponent) newComp;
  public gridOptions: GridOptions;
  public columnDefs: any[];
  datePipe: DatePipe = new DatePipe("en-US");
  today = Date.now();
  Allopportunities: Opportunities[];
  public context;
  opportunities: Opportunities = new Opportunities();
  opportunitiesHistory: Opportunities = new Opportunities();
  notes: Notes = new Notes();
  opportunitiesnotes: Array<Notes> = [];
  taskhistory: TaskHistory = new TaskHistory();
  TaskDiv: boolean = false;
  account: Accounts = new Accounts();
  contact: Contacts = new Contacts();
  selectedItems = [];
  status = true;
  notesshowDialog = false;
  ShowAddOppDialog = false;
  isModal: boolean = true;
  public sideBar;
  public gridOptionsnotes: GridOptions;
  public columnDefsnotes: any[];
  allnotes: Array<Notes> = [];
  timezonevalue: string;
  AccountIdsDialog: boolean = false;
  SearchPage: boolean = false;
  viewaccount: Viewaccount = new Viewaccount();
  action: string;
  showAddEditAccount: boolean = false;
  showAddEditContact: boolean = false;
  isSharing: boolean = false;
  @ViewChild(OwnershipsharingComponent) ownrshipComp: OwnershipsharingComponent;
  rightMainDivHideShow: boolean = true;
  accountSharingid: number = 0;
  AddContacttableDiv: boolean = false;
  userprevilages: any;
  ExpandPDF: boolean = false;
  @ViewChild(AccountDetailsComponent) accdetailcmp;
  showPDFDiv: boolean = false;
  PreviousNotesExpand: boolean = false;
  constructor(private userService: UserService,public applicationComponent: ApplicationComponent, private route: ActivatedRoute, private leadservice: LeadsService, private viewContainerRef: ViewContainerRef, private toastr: ToastrService, myElement: ElementRef, private opprtunityservice: OpprtunitiesService, private router: Router, private customerService: CustomerService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {   
    this.route.params.subscribe(params => {
       
      if (params['type'] != "0") {
        this.type = params['type'];
      }
    });
    if (this.typeSelected == 'opportunity') {
      this.editOpp = true;
    }
    else if (this.typeSelected == 'SearchPage') {
      this.SearchPage = true;
    }
    this.initializeAgGrid();
    this.loadOpportunities(this.contactid);
    this.info(this.contactid, this.type);
    this.GetOpportunityDetais(this.OpportunityID);

  }
  public OpenOpportunity(oppId) {
    this.editOpp = true;
    this.ShowAddOppDialog = true;
    this.oppAddEditDialogCss();
  }
  GetOpportunityDetais(oppId) {
    this.opprtunityservice.GetByOpportunityId(oppId).then(result => {
      this.opportunities = result;
      this.opprtunityservice.GetAllOpprtunitiesNotesById(oppId).then((items) => {
        items.forEach(items => {
        });
        this.opportunitiesnotes = items;
      });
    });
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    //let selectedUserIds: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 31).then((config) => {

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(columnSelections);

    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }

  }

  createColumnDefs(columnSelections: string) {
    let selfobj = this;
    let columnSelectionsArray: string[] = [];

    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({
            field: 'owner', headerName: 'Owner', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
            }, suppressSorting: true, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefs.push({
            field: 'statusName', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
            }
          });
        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
            },
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        {
          field: 'datecreated', headerName: 'Date Created', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'estimatedclosedate', headerName: 'ECD', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'statusName', headerName: 'Status', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'practicename', headerName: 'Account Name', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'firstName', headerName: 'First Name', hide: false,
          cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'lastName', headerName: 'Last Name', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },

        {
          field: 'itemName', headerName: 'Proposal Item', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'ProposalDescription', headerName: 'Proposal Description', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'LeadDescription', headerName: 'Lead Description', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'dollers', headerName: 'Price', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'RR', headerName: 'Monthly', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'PCname', headerName: 'Closing Percentage', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'drsName', headerName: '# Drs', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },  //true starts here
        {
          field: 'midlevel', headerName: '# Mid Levels', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'leadfrom', headerName: 'Lead From', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'owner', headerName: 'Owner', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'actualclosedate', headerName: 'Act.Close', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }
        },
      ];

    }
  }

  loadOpportunities(contactid) {
    this.contactid = contactid;
    if (contactid == 0) {
      this.Allopportunities = [];
      this.opportunitiesnotes = [];
      this.opportunitiesHistory = new Opportunities();
    } else {
      this.opprtunityservice.GetAllOpportunitiesListByAccountorContact(contactid, 'Opt').then((customer) => {
        customer.forEach((customer) => {
          customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
          customer.actualclosedate = this.datePipe.transform(customer.actualclosedate, "MM-dd-yyyy");
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
        });
        this.Allopportunities = customer;

        let selfObj = this;
        $(setTimeout(function () {
          selfObj.gridOptions.api.forEachNode((node) => {
            if (node.data.id == selfObj.OpportunityID) {
              selfObj.gridOptions.api.selectNode(node, true);
            }
          });
        }, 1000));
        this.opportunities = new Opportunities();
        this.opportunitiesnotes = [];
        this.Resetfields();
      });
    }
  }
  PDFClick(val, type) {
    if (val !== 0 && val !== '0' && val !== undefined) {
      this.showPDFDiv = true;
      this.PDFDIVCss();
      this.userService.GetProposalsDocumentContent(val).then(result => {
        let SO = this
        $(setTimeout(function () {
          SO.onDocumentOpen(result.PdfContent);
        }, 100));
      });
    }
    
  }

  PDFDIVCss() {
    setTimeout(function () {
      $('#PDFDiv .dialog').attr('style', 'width: 47%;padding: 0px;border: 4px solid rgb(49,189,240);min-height: 75%;overflow: auto;height: 99%;top: 0%;left: 53%;');
    }, 4)
  }

  onDocumentOpen(myBaseString) {
    $('#pdfDiv').html('<embed src="data:application/pdf;base64,' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:100%;" />')
    $('#pdfDiv').attr('style', 'width:100%;height: 100%;padding-left: 0;padding-right: 0%;background-color: white;margin-left:0%');
  }
  Resetfields() {
    this.opportunities.item = null;
    this.opportunities.PC = null;
    this.opportunities.drs = null;
    this.opportunities.midlevels = null;
    this.opportunities.leadfromid = null;
    this.taskhistory.type = null;
    this.opportunities.ownerid = this.currentuser.id;
  }

  rowClickAgGrid(rowEvent) {
    if (rowEvent.node.selected) {
      this.OpportunityID = rowEvent.data.id;
      this.editOpp = true;
      this.newComp.loadopp(this.OpportunityID);
    }
    else {
      this.OpportunityID = 0;
      this.editOpp = false;
      this.newComp.loadopp(this.OpportunityID);
    }
  }

  onSelectionChanged(event) {
     
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
       
      if (event.api.getSelectedNodes()[0].data) {
        this.OpportunityID = event.api.getSelectedNodes()[0].data.id;
        this.opportunitiesHistory = event.api.getSelectedNodes()[0].data;
        this.GetOpportunityDetais(this.OpportunityID);
      }
    } else {
      this.OpportunityID = 0;
     
    }
  }

  ShowTaskDiv() {
    this.TaskDiv = !this.TaskDiv;
    this.TaskDivCSS()
  }
  TaskDivCSS() {
    setTimeout(function () {
      $('#TaskDiv .dialog').attr('style', 'width: 40%;padding: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 95%;overflow: auto;height: 83%;top: 3%;left: 58%;');
    }, 4)
  }
  DisplayOpportunities(event) {
    this.info(event.accountorcontactid, event.type);
    this.loadOpportunities(event.accountorcontactid);
    this.editOpp = true;
   /* this.newComp.loadopp(event.id);*/
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.gridOptions.api.forEachNode((node) => {
        if (node.data.id == event.id) {
          selfObj.gridOptions.api.selectNode(node, true);
         
        }
      });
    }, 500));
   
  }

  oppAddEditDialogCss() {
    setTimeout(function () {
      $('#ShowAddOppDialog  .dialog').attr('style', 'width: 35%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82.5%;padding: 0;top: 10%;left: 63%;');
    }, 4)

  }
  info(id, type) {    
    this.oppAddEditDialogCss();
    if (type == "Account" || type == "Customer") {
      this.LoadAccountData(id,type);
    }
    else if (type == "Contacts") {
      this.contact = new Contacts();
      this.customerService.LoadContactById(id).then(result => {
        this.contact = result;      
        this.type = "Contacts";
        this.LoadAccountData(result.accountid, this.type);
      });
    }
  }
  LoadAccountData(id, type) {
    this.customerService.loadaccountdata(id).then(result => {
      this.account = result;
      this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.owner);
      this.type = type;
    });
  }

  Display(accId, accountname, addr1, addr2, city, state, zip, country, phone, fax, website, Accountiddata,ownerName) {

    //var properCity = city.charAt(0).toUpperCase() + city.substr(1).toLowerCase();
    if (city != null) {
      var cityArray = city.split(' ');
      var newCity = '';
      for (var i = 0; i < cityArray.length; i++) {
        if (newCity == '') {
          newCity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
        else {
          newCity = newCity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
      }
    }
    if (website != null && website.indexOf('www.') >= 0) {
      website = ((website).split('www.')[1])
    } else if (website != null && website.indexOf('ww.') >= 0) {
      website = ((website).split('ww.')[1])
    }


    var properstate = (state != null) ? state.charAt(0).toUpperCase() + state.substr(1).toLowerCase() : "";
    this.viewaccount.accountId = accId;
    this.viewaccount.viewaccountname = accountname;
    this.viewaccount.viewaddress1 = addr1;
    this.viewaccount.viewaddress2 = addr2;
    this.viewaccount.viewcity = newCity == null ? "" : newCity;
    this.viewaccount.viewstate = properstate;
    this.viewaccount.viewzip = zip;
    this.viewaccount.viewCountry = country;
    this.viewaccount.viewphone = phone;
    this.viewaccount.viewfax = fax;
    this.viewaccount.viewwebsite = website;
    this.viewaccount.Accountiddata = Accountiddata;
    this.viewaccount.ownerName = ownerName;
    this.accdetailcmp.loadAccountId(accId);
  }
  onAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
      $(setTimeout(function () {
        selfObj.autoSizeAll();
      }, 100));
    }, 100));
  }
  autoSizeAll() {

  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    config.maxlimit = 25;
    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 31;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }

  dragStopped(event) {
    this.saveConfiguration();
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  ResponseFromAddOpp(val) {
    if (val == 'OpportunitySaved') {
      this.loadOpportunities(this.contactid);
      this.status = true;
      this.ShowAddOppDialog  = false;
      this.sendMsgEvent.emit(val);//Added to refresh Parent Grid
    }
    else if (val == 'OpportunityStatusChanged') {
      this.TaskDiv = false;
      this.status = false;
    }
    else if (val == 'CloseDialog') {
      this.status = true;
    }
    else if (val == 'AddTask') {
      this.ShowTaskDiv();
    }
    else if (val == 'Expand') {
      this.ExpandPDF = true;
    }
    else if (val == "Collapse") {
      this.ExpandPDF = false;
    }else if (val == "CloseAddOpportunity") {
      this.ShowAddOppDialog = false;
    }
  }

  openAccountNotes() {
    this.isModal = true;
    this.customerService.GetByAccountId(this.contact.accountid).then(result => {
      this.account = result;
    });
   
    this.notesshowDialog = !this.notesshowDialog;
    this.NotesDialogCss();
  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }

  onCloseDialogEventReceived(val) { }
  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.createColumnDefsnotes("");
    this.gridOptionsnotes.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }

  createColumnDefsnotes(columnSelections: string) {
    let selfObj = this;
    this.columnDefsnotes = [{
      field: '#',
      headerName: '#',
      width: 52,
      checkboxSelection: true,
      suppressSorting: true,
      suppressMenu: true,
      pinned: true,
      hide: false,
      outerWidth: 8,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
    },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellStyle: { 'text-align': 'left' }, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'statusName', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];
  }
  AccountInfo() {  
    if (this.type == 'Customer') {
      localStorage.setItem('RhxAccountVal', (this.account.id).toString());
    } else if (this.type == "Contacts") {
      localStorage.setItem('RhxAccountVal', (this.contact.accountid).toString());
    }

    
    let SO = this;
    $(setTimeout(function () {
      SO.applicationComponent.activateSubButtons('empty');
      $(setTimeout(function () {
        SO.router.navigateByUrl(`/application/account/Accounts`);
        SO.applicationComponent.ExpandSelectedMenu('Accounts');
        $(".subButtons").removeClass("activeButton");
        SO.applicationComponent.selectedtabid = 'Customer';
      }, 100));
    }, 50));

  }
  openAccountIds() {
    //this.account.accountpopid1 = this.account.accountid1;
    //this.account.accountpopid2 = this.account.accountid2;
    //this.account.accountpopid3 = this.account.accountid3;
    this.AccountIdsDialog = true;
    this.AccountIdsDialogCss();
  }
  cancelaccountIds() {
    this.AccountIdsDialog = false;
  }

  AccountIdsDialogCss() {

      setTimeout(function () {
        $('.dialog').attr('style', 'width: 20%;padding-bottom:0px;border:2px solid rgb(49,189,240);border-radius:5%;min-height:15%;overflow: auto;height: 18%;left: 0%;right:56%;top: 41%;padding: 0;');
      }, 4)

  }
  onSearchstring(msg) {
     
    if (msg != undefined) {
      this.SearchPage = false;
      let selfobj = this;
    $(setTimeout(function () {
      var data = msg.split("|");
      selfobj.type = data[1];
      selfobj.loadOpportunities(selfobj.contactid);
      selfobj.info(selfobj.contactid, selfobj.type);
    }, 200));

    }
  }
  onTaskSaved(event) {
    //if (event == 'Cancel') {
      this.TaskDiv = false;
    //}
  }
  IsButtonAccess(con,type) {

  }
  addContact() {
  
    if (this.userprevilages != undefined) {
      for (var j = 0; j < this.userprevilages.length; j++) {
        if ("Contacts" == this.userprevilages[j].resource) {
          if (this.userprevilages[j].canadd == false) {
            this.toastr.clear();
            this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }
        }
      }
    }
    this.showAddEditContact = true;
    this.showAddEditContactCss();
  }
  AccountsharingAg(selectedval) {
    
    if (selectedval == "Edit") {
      this.isSharing = false;
      this.action = selectedval;
      this.showAddEditAccount = true;
      this.showAddEditAccountCss();
    }
    else if (selectedval == "View") {
      this.isSharing = false;
      this.action = selectedval;
      this.showAddEditAccount = true;
      this.showAddEditAccountCss();
    }
    else {
      this.isSharing = true;
      this.rightMainDivHideShow = false;
      this.accountSharingid = 1;
      this.AddContacttableDiv = false;
      this.ownrshipComp.loadAccountInformation(this.account.id, 0);
    }

  }
  showAddEditAccountCss() {
    setTimeout(function () {
      $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  showAddEditContactCss() {
    setTimeout(function () {
      $('#addEditContact .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  ReceiveEvent(msg) {
    if (msg == "ExpandSoftwareLicense" || msg == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(msg);
    } else {
      
    }
  }

  ExpandAddEditAccountDialog(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 100%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 86%;padding: 0;top: 10%;left: 10%;max-width: 88%;');
      }, 4)
    } else {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
      }, 4)
    }
  }

  ContactReceiveEvent(msg) {
    this.showAddEditContact = false;
    //this.GetAllProviderAndContactInfo(this.account.id)
  }
  btnClickReceived(event) {
    if (event.btnName == 'addContact') {
      this.addContact();
    }
    else if (event.btnName == 'Accountsharing') {
      this.AccountsharingAg(event.val);
    }
    else if (event.btnName == 'openNotes') {
      this.openAccountNotes();
    } else if (event.btnName == 'AccountInfo') {
      this.AccountInfo();
    }
  }
}
 
