import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GridOptions, RowDragEnterEvent, RowDragEndEvent, RowDragMoveEvent, RowDragLeaveEvent } from 'ag-grid';
import { ToastrService } from 'ngx-toastr';
import { SalesStages } from 'src/app/models/SalesStages';
import { SystemRowConfiguration } from 'src/app/models/system-row-configuration';
import { StatusItems } from 'src/app/models/StatusItems';
import { StatusTypes } from 'src/app/models/status-types';
import { TriggerTypes } from 'src/app/models/trigger-types';
import { UserService } from 'src/app/services/user.service';
import { LeadsService } from 'src/app/services/leads.service';
import { ApplicationComponent } from '../application.component';
import { AuditLogModel } from '../../models/auditlog';
declare var $: any;
@Component({
  selector: 'app-leads-configuration',
  templateUrl: './leads-configuration.component.html',
  styleUrls: ['./leads-configuration.component.css']
})
export class LeadsConfigurationComponent implements OnInit {
  LeadsConfigMainDiv: boolean = true;
  StatusTypeDiv: boolean = false;
  TriggerTypesDiv: boolean = false;
  SalesStagesDiv: boolean = false;
  ExpandStatusTypeGrid: boolean = false;
  createNewStatusType: boolean = false;
  createTriggerType: boolean = false;
  currentUser: any;
  public gridApi;
  public TriggergridApi;
  public gridstageApi;
  public rowSelection;
  public gridOptions: GridOptions;
  public triggergridOptions: GridOptions;
  public columnDefs: any[];
  public TriggerColumnDefs: any[];
  AddStatusTypeForm: FormGroup;
  AddTriggerTypeForm: FormGroup;
  SalesStagesForm: FormGroup;
  SalesStages: SalesStages = new SalesStages();
  statustype: StatusTypes = new StatusTypes();
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration();
  StatusItems: StatusItems[];
  statusTypes: StatusTypes[];
  triggerTypes: TriggerTypes[];
  triggertype: TriggerTypes = new TriggerTypes();
  ExpandSalesStageGrid: boolean = false;
  allSalesStages: SalesStages[];
  public SalesStageGridOptions: GridOptions;
  public SalesStageColumnDefs: any[];
  createSalesStage: boolean = false;
  public sideBar;
    deleteRowData: any;
    contactStatus: any;
    DeleteConfirmationPopup: boolean = false;
  //type: string;
  @Input() type: string = null;
  constructor(private userService: UserService, private toastr: ToastrService, private leadsService: LeadsService, public applicationComponent: ApplicationComponent) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
    if (this.type == "900") {
      this.AccessDiv("StatusType");
    }
    //} else {
      var obj: AuditLogModel = { id: 0, type: 'Leads Configuration', action: 'Leads Config', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.currentUser.id, dateofviewed: '', customertype: '', time: '', Title: 'Leads Config', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
      this.applicationComponent.InsertAuditHistory(obj);
    //}
  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.statustype = event.api.getSelectedNodes()[0].data;
        this.createNewStatusType = true;
        this.userService.GetStatusItemsByStatusType(this.statustype.Id).then(result => {
          this.StatusItems = result;
        });
      } else {
        this.statustype = new StatusTypes();
        this.createNewStatusType = false;
      }
    } else {
      this.statustype = new StatusTypes();
      this.StatusItems=[];
    }
  }
  onTriggerChanged(event) {
   
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.triggertype = event.api.getSelectedNodes()[0].data;
        this.createTriggerType = true;
      } else {
        this.triggertype = new TriggerTypes();
        this.createTriggerType = false;
      }
    } else { this.triggertype = new TriggerTypes();}
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true,
      toolPanelSuppressValues: true, enableCellChangeFlash: true, enableMultiRowDragging:true,
   
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createColumnDefs();

  }
  GetAllStatusTypes() {
    this.userService.GetAllStatusTypes(this.currentUser.id).then(result => {
      this.statusTypes = result;
    });
  }
  onRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("StatusType");   
  }
  onRowTriggerDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("TriggerType");
  }
  saverowconfiguration(type) {
    var sortoorder = [];
    if (type == "StatusType") {
      this.gridOptions.api.forEachNode((node) => {
        sortoorder.push(node.data.Id);
      });
    } else if (type == "TriggerType") {
      this.TriggergridApi.forEachNode((node) => {
        sortoorder.push(node.data.Id);
      });
    }else {
      this.gridstageApi.forEachNode((node) => {      
        sortoorder.push(node.data.SalesStageID);
      });
    }
    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i == a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentUser.id;
    this.leadsService.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });
     
    });
    
   }
  GetAllTriggerTypes() {
    this.userService.GetAllTriggerTypes(this.currentUser.id).then(result => {
      this.triggerTypes = result;
    });
  }
  createColumnDefs() {
    let selfObj = this;
    this.columnDefs = [
      {
        field: '#',
        headerName: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true,
      },
      { field: 'Id', headerName: 'Id', hide: false, width: 120 },
      {
        field: 'statustypeName', headerName: 'Sales Status Type', hide: false, width: 400,
      },
      { field: 'SortOrder', headerName: 'Sort Order', hide: false, width: 120 },
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:20%"/>';
          eDiv.addEventListener('click', function () {
           selfObj.deleteStatusType(params.data.Id);
          });
          return eDiv;
        }
      }
    ];
    this.rowSelection = 'multiple';

  }

  createTriggerColumnDefs() {
    let selfObj = this;
    this.TriggerColumnDefs = [
      {
        field: '#',
        headerName: '#',
        width: 62,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true
      },
      { field: 'Id', headerName: 'Id', hide: false, width: 120 },
      {
        field: 'TriggerType', headerName: 'Trigger Type', hide: false, width: 400,
      },
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:20%"/>';
          eDiv.addEventListener('click', function () {
            selfObj.deleteTriggerType(params.data.Id);
          });
          return eDiv;
        }
      }
    ];


  }
  deleteTriggerType(statusTypeId) {
    this.deleteRowData = statusTypeId;
    this.contactStatus = 0;
    this.DeleteConfirmationPopup = true;
    this.applicationComponent.salesxcrmDialog('#DeleteConfirmationPopup .dialog');
    //setTimeout(function () {
    //  $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    //}, 4);    
  }
  deleteStatusType(statusTypeId) {
    this.deleteRowData = statusTypeId;
    this.contactStatus = 0;
    this.DeleteConfirmationPopup = true;
    this.applicationComponent.salesxcrmDialog('#DeleteConfirmationPopup .dialog');
    //setTimeout(function () {
    //  $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    //}, 4);
            
  }

  initializeTriggerAgGrid() {
   
    this.triggergridOptions = <GridOptions>{
      enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true,
      toolPanelSuppressValues: true, enableCellChangeFlash: true, enableMultiRowDragging: true,

    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createTriggerColumnDefs();

  }
  AccessDiv(val) {
    if (val == 'StatusType') {
      this.StatusTypeDiv = true;
      this.initializeAgGrid();
      this.GetAllStatusTypes();
      this.LeadsConfigMainDiv = false;
    } else if (val == 'TriggerTypes') {
      this.TriggerTypesDiv = true;
      this.initializeTriggerAgGrid();
      this.GetAllTriggerTypes();
      this.LeadsConfigMainDiv = false;
    } else if (val == 'SalesStages') {
      this.SalesStagesDiv = true;
      this.LeadsConfigMainDiv = false;
      this.GetAllStatusTypes();
      this.GetAllTriggerTypes();
      this.initializeSalesStageGrid();
      let selfObj = this;
      $(setTimeout(function () {
        
        selfObj.GetAllSalesStage();
        selfObj.getSalesStageForm();
      }, 100));
      
    }

    var obj: AuditLogModel = { id: 0, type: 'Leads Configuration', action: 'Leads Config - ' + val, AccountOrContactId: 0, AccountOrContact: val, documentid: 0, userid: this.currentUser.id, dateofviewed: '', customertype: '', time: '', Title: 'Leads Config - ' + val, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }
  CloseDiv(val) {
    if (val == 'StatusType') {
      this.StatusTypeDiv = false;
      this.LeadsConfigMainDiv = true;
      this.statustype = new StatusTypes();
    } else if (val == 'TriggerType') {
      this.TriggerTypesDiv = false;
      this.LeadsConfigMainDiv = true;
      this.triggertype = new TriggerTypes();
    } else if (val == 'SalesStages') {
      this.SalesStagesDiv = false;
      this.LeadsConfigMainDiv = true;
      this.createSalesStage = false;
      this.ClearSalesStageForm();
    }
  }

  getSalesStageForm(): FormGroup {
    return new FormGroup({
      'SalesStage': new FormControl(this.SalesStages.SaleStage, Validators.required),
      'ForeCastDescription': new FormControl(this.SalesStages.ForeCastDescription, Validators.required),
      'SkipStage': new FormControl(this.SalesStages.SkipStage, Validators.required),
      'Sortorder': new FormControl(this.SalesStages.SortOrder, Validators.required)
    });
  }
  CreateSalesStage() {
    this.getSalesStageForm();
    this.createSalesStage = true;
    this.ExpandSalesStageGrid = false;
    this.SalesStages = new SalesStages();
    var NextsortOrder = this.allSalesStages.sort((a, b) => b.SortOrder - a.SortOrder)[0].SortOrder;
    this.SalesStages.SortOrder = NextsortOrder + 1;

  }
  initializeSalesStageGrid() {
    this.SalesStageGridOptions = <GridOptions>{
      enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
      , rowDragManaged: true, animateRows: true
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createSalesStageColumnDefs();

  }
  onSaleStageRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("SaleStage");
  }
  createSalesStageColumnDefs() {
    let selfObj = this;
    this.SalesStageColumnDefs = [
      {
        field: '#',
        headerName: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true,
      },
      { field: 'SalesStageID', headerName: 'Id', hide: false, width: 70 },
      {
        field: 'SaleStage', headerName: 'Sales Stage', hide: false, width: 150,
      },
      { field: 'ForeCastDescription', headerName: 'ForeCast Description', hide: false, width: 150 },
      {
        field: 'SkipStage', headerName: 'Skip Stage', hide: false, width: 90, cellRenderer(params) {
          if (params.data.SkipStage == 0) {
            return "No";
          }
          else {
            return "Yes";
          }
        }
      },
      {
        field: 'StatusType', headerName: 'Status Type', hide: false, width: 120, cellStyle: { 'padding': '0' }, cellRenderer: DropDowns
      },
      {
        field: 'TriggerType', headerName: 'Trigger Type', hide: false, width: 120, cellStyle: { 'padding': '0' }, cellRenderer: DropDowns
      },
      { field: 'SortOrder', headerName: 'Sort Order', hide: false, width: 120 },
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:20%"/>';
          eDiv.addEventListener('click', function () {
            selfObj.DeleteSalesStage(params.data);
          });
          return eDiv;
        }
      }
    ];

    function DropDowns(params) {
      var rowIndex = params.rowIndex;
      var Column = params.eGridCell.attributes.colId;
      var WeldGridData = selfObj.SalesStageGridOptions.rowData;
      var eSelect = document.createElement("select");
      eSelect.setAttribute('class', 'custom-select form-control');
      eSelect.setAttribute('style', 'padding:0px;font-size:11px;');
      eSelect.setAttribute('name', params.colDef.field);
      eSelect.setAttribute('id', params.colDef.field + "_" + rowIndex);
      
      //eSelect.disabled = !selfObj.createSalesStage;
      eSelect.onchange = function () {
        if (params.colDef.field == 'StatusType') {
          selfObj.SalesStages = params.data;
          selfObj.SalesStages.StatusType = $('#' + eSelect.id).val();
          params.data.StatusType = $('#' + eSelect.id).val();
          selfObj.SaveSalesStage();
        } else if (params.colDef.field == 'TriggerType') {
          selfObj.SalesStages = params.data;
          selfObj.SalesStages.TriggerType = $('#' + eSelect.id).val();
          params.data.TriggerType = $('#' + eSelect.id).val();
          selfObj.SaveSalesStage();
        }
      };
      
      var eOption = document.createElement("option");
      eOption.text = "Select";
      eOption.value = "";
      eOption.disabled = true;
      eOption.selected = true;
      eSelect.appendChild(eOption);
      if (params.colDef.field == "StatusType") {
        selfObj.statusTypes.forEach(ele => {
          var eOptionVal = document.createElement("option");
          eOptionVal.text = ele.statustypeName;
          eOptionVal.value = ele.Id.toString();
          eSelect.appendChild(eOptionVal);
        });
        
      }
      else if (params.colDef.field == "TriggerType") {
        selfObj.triggerTypes.forEach(ele => {
          var eOptionVal = document.createElement("option");
          eOptionVal.text = ele.TriggerType;
          eOptionVal.value = ele.Id.toString();
          eSelect.appendChild(eOptionVal);

        });

      }

      
      $(setTimeout(function () {
        if (params.colDef.field == 'StatusType') {
          $('#' + eSelect.id).val(params.data.StatusType);
        } else if (params.colDef.field == 'TriggerType') {
          $('#' + eSelect.id).val(params.data.TriggerType);
        }
      }, 200));
      return eSelect;
    }

    
  }

  
  Expand(val) {
    this.ExpandSalesStageGrid = !this.ExpandSalesStageGrid;
  }
  SaveSalesStage() {   
    this.SalesStages.CreatedBy = this.currentUser.id;
    this.leadsService.AddUpdateSalesStage(this.SalesStages).then(result => {
      this.toastr.success("Sales Stage saved successfully.", "Sales Stage Saved", { timeOut: 600 });
      this.ClearSalesStageForm();
      this.createSalesStage = false;
      this.GetAllSalesStage();
    });
  }
  GetAllSalesStage() {
    this.leadsService.GetAllSalesStage(this.currentUser.id).then(result => {
      this.allSalesStages = result;
    });
  }
  onSalesStageSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.SalesStages = event.api.getSelectedNodes()[0].data;
        this.createSalesStage = true;
      }
    } else {
      this.ClearSalesStageForm();
    }
  }
  ClearSalesStageForm() {
    this.SalesStages = new SalesStages();
  }
  DeleteSalesStage(ParamData) {
    this.deleteRowData = ParamData;
    this.contactStatus = 0;
    this.type = "SalesStage";
    this.DeleteConfirmationPopup = true;
    this.applicationComponent.salesxcrmDialog('#DeleteConfirmationPopup .dialog');
    //setTimeout(function () {
    //  $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 31%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    //}, 4);   
  }
  completeForm() { }
  handleClick($event) {

  }
  onAgGridReady(event) {
    this.gridApi = event.api;
  }
  onTriggerAgGridReady(event) {
    this.TriggergridApi = event.api;
  }
  onsalesstageAgGridReady(event) {
    this.gridstageApi = event.api;
  }

  ReceiveDeleteevent(msg) {
    if (msg == "Ok") {
      if (this.type == "SalesStage") {
        this.deleteRowData.isDeleted = 1;
        this.SalesStages = this.deleteRowData;
        this.SaveSalesStage();
      }
      else if (this.type == "TriggerType") {

        this.userService.UpdateStatusType(this.deleteRowData).then(result => {
          this.GetAllTriggerTypes();
          this.toastr.success("Trigger Type Deleted successfully.", "Trigger Deleted", { timeOut: 600 });
        });
        
      }
      else if (this.type == "StatusType") {
        this.userService.UpdateTriggerType(this.deleteRowData).then(result => {
          this.GetAllStatusTypes();
          this.toastr.success("Status Type Deleted successfully.", "Status Type Deleted", { timeOut: 600 });
        });
        
      }
    }
    this.DeleteConfirmationPopup = false;
    
  }

  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
    
  }

}

