<div class="col-xs-12" style="padding: 0px;">
  <div class="col-xs-12" *ngIf="openTicket" style="padding: 0; height: 620px;">
    <div class="col-xs-12" style="background-color: orangered !important; height: 2%"></div>
    <div class="col-xs-12 opportunity" style="padding:0.5%;height:5%;">
      <div class="col-xs-11" style="height: 7%;text-align: left">
        <label style="padding: 0.6%;">TICKETS/ACCOUNT DETAILS</label>
      </div>
      <div class="col-xs-1" (click)="ClearTicket()" style="height: 7%;text-align: right">
        <label style="padding: 3% 0% 0% 0%;"><img src="../../../assets/img/Black x.png" style="height:22px" /></label>
      </div>

    </div>

    <ag-grid-angular style="width: 100%;height:100%;" class="ag-theme-balham"
                     [gridOptions]="ticketsgridOptions"
                     [columnDefs]="ticketscolumnDefs"
                     [rowData]="ticketslist"
                     [sideBar]="sideBar"
                     [pagination]="true">
    </ag-grid-angular>
  </div>
 
  <div *ngIf="!openTicket" [ngClass]="'col-xs-12'" style="padding: 0;">
    <div class="col-xs-12" style="height:0.5%; background-color: orangered !important; color: orangered">
     ---
    </div>
    <div class="col-xs-12">     
      <div class="col-xs-11" style="text-align: left;padding:0%">
        <label style="padding: 0.6%;">CREATE TICKET</label>
      </div>
      <div class="col-xs-1" (click)="ClearTicket()" style="text-align: right">
        <label style="padding: 3% 0% 0% 0%;"><img src="../../../assets/img/Black x.png" style="height:22px" /></label>
      </div>
    </div>
    <form #ticketform="ngForm">
      <div class="col-xs-12" style="padding:0;height:100%" *ngIf="!showAssignToDiv && !showCCToDiv">
        <div class="col-xs-12" style="margin-top: 2%; padding-right: 2px; padding-left: 1%; text-align: left; height: 5%">
          <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
            <label style="margin:10% 0 0 0">Account</label>
          </div>
          <div class="col-xs-9" style="height:100%">
            <input class="form-control" type="text" name="accountName" [(ngModel)]="tickets.accountName" placeholder="Account Name" required />
          </div>

        </div>
        <div class="col-xs-12" style="margin-top:2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
          <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
            <label style="margin:10% 0 0 0">Contact</label>
          </div>
          <div class="col-xs-9" style="height:100%">
            <select class="form-control" id="ContactList" [(ngModel)]="tickets.contactid" name="ContactList" (change)="loadcontactdatatoticket($event.target.value)" required>
              <option value="null" disabled [selected]="true">Select Contact</option>
              <option *ngFor="let conatct of  contactList" value={{conatct.id}}>
                {{conatct.itemName}}
              </option>
            </select>
          </div>

        </div>

        <div class="col-xs-12" style="margin-top: 2%; padding-right: 2px; padding-left: 1%; text-align: left; height: 5%">
          <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
            <label style="margin:10% 0 0 0">Email</label>
          </div>
          <div class="col-xs-9" style="height:100%">
            <input class="form-control" type="text" name="email" [(ngModel)]="tickets.email" placeholder="Email Address" required />
          </div>
        </div>

        <div class="col-xs-12" style="margin-top: 2%; padding-right: 2px; padding-left: 1%; text-align: left; height: 5%">
          <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
            <label style="margin:10% 0 0 0">Office Phone</label>
          </div>
          <div class="col-xs-9" style="height:100%">
            <input class="form-control" type="text" name="office" [(ngModel)]="tickets.businessphone" placeholder="office" required />
          </div>
        </div>

        <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
          <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
            <label style="margin:10% 0 0 0">Cell Phone</label>
          </div>
          <div class="col-xs-9" style="height:100%">
            <input class="form-control" type="text" name="cell" [(ngModel)]="tickets.cellphone" placeholder="cell" />
          </div>
        </div>

        <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
          <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
            <label style="margin:10% 0 0 0">Created By</label>
          </div>
          <div class="col-xs-9" style="height:100%">
            <input class="form-control" name="CreatedBy" type="text" [(ngModel)]="tickets.UserName" placeholder="Created By" required />
          </div>
        </div>

        <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
          <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
            <label style="margin:10% 0 0 0">Subject</label>
          </div>
          <div class="col-xs-9" style="height:100%">
            <input class="form-control" name="Subject" type="text" [(ngModel)]="tickets.subject" placeholder="Subject" required />
          </div>
        </div>

        <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
          <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
            <label style="margin:10% 0 0 0">Priority</label>
          </div>
          <div class="col-xs-9" style="height:100%">
            <select name="tickets.priority" class="form-control" [(ngModel)]="tickets.priority"
                    style=" height: 100%;font-size: 12px;" required>
              <option selected value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </div>

        <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
          <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
            <label style="margin:10% 0 0 0">Assign Ticket To </label>
          </div>
          <div class="col-xs-9" style="height:100%" (click)="ShowAssignTo()">
            <input class="icon form-control" name="AssignTicketTo" type="text" [(ngModel)]="selectedNames" required readonly style="background-color: white !important;" />
          </div>
        </div>

        <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
          <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
            <label style="margin:10% 0 0 0">CC </label>
          </div>
          <div class="col-xs-9" style="height:100%" (click)="ShowCCTo()">
            <input class="icon form-control" name="CCTicketTo" type="text" [(ngModel)]="selectedCCNames" readonly style="background-color: white !important;" />
          </div>
        </div>

        <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;">
          <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
            <label style="margin:10% 0 0 0">Description </label>
          </div>
          <div class="col-xs-9" style="height:100%">
            <textarea name="Description" rows="8" [(ngModel)]="tickets.descriptions" class="form-control" placeholder="Description" style="height:100% !important" required></textarea>
          </div>
        </div>
        <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;">
          <div class="col-xs-3" (click)="OpenTicketDetails()">
            <label style="margin: 10% 0 0 0; text-decoration: underline;color: orangered;">TICKET HISTORY </label>
          </div>
          <div class="col-xs-9" style="height:100%">
            <div class="col-xs-6" style="padding:0;"><button class="btn displayButton" style="width: 100%;padding: 5px 7px;" (click)="ClearTicket()"><span class="mainCheckMark"></span>Cancel</button></div>
            <div class="col-xs-6" style="padding-right:0%;"><button class="btn noprovider" style="width: 100%; background: #a2e477; padding: 5px 7px;" [disabled]="!ticketform.form.valid" (click)="savetickets();" id="saveOpp"><span class="mainCheckMark"></span>Save</button></div>
          </div>
        </div>
      </div>
    </form>

    <app-dialog [(visible)]="showAssignToDiv" *ngIf="showAssignToDiv" id="showAssignToDiv" [closable]="false">

      <div class="col-xs-12" style="padding:0;height:6%;text-align:center">
        <label>ASSIGN TICKET TO</label>

      </div>
      <div class="col-xs-12" style="padding:0.5%;height:6%;border:1px solid lightgray;border-radius:3px;">
        <div class="col-xs-7" style="padding:0;">
          <input type="text" name="selectedAssignTo" [(ngModel)]="selectedNames" readonly style="width: 100%; height: 100%; padding-left: 2%; padding-right: 2%; border: none; background-color: white !important; padding-top: 2%;" />
        </div>
        <div class="col-xs-5" style="padding:0;">
          <div class="col-xs-4" style="display:block;padding:0">
            <button class="btn noprovider" (click)="ClearAssignTo('To')" style="width:100%;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;">Cancel</button>
          </div>
          <div class="col-xs-4 pull-right" style="display:block;padding:0">
            <button class="btn noprovider" (click)="CloseAssignTo()" style="width: 100%; background: #2196F3; color: white; font-weight: 700; border: 1px solid #000; border-radius: 3px;">Save</button>
          </div>
        </div>
      </div>
      <div class="col-xs-12 scroll-c" style="height: 82%;border: 2px solid lightgray;padding: 2% 0 2% 0;">
        <div class="col-xs-12" *ngFor="let task of ticketTaskAssignTo" style="padding:0;">
          <div class="col-xs-12" style="display:flex">
            <label class="container1" style="font-size: 17px;">
              &nbsp;&nbsp;&nbsp;
              {{task.itemName}}
              <input type="radio" name="Taskgroup" id="TG{{task.id}}" (click)="selectedTasks(task)">
              <span class="checkmark" id="TG{{task.id}}"></span>
            </label>

          </div>
        </div>
        <div class="col-xs-12" *ngFor="let user of ticketUserAssignTo" style="padding:0;">
          <div class="col-xs-12" style="display:flex">
            <label class="container1" style="font-size: 17px;">
              &nbsp;&nbsp;&nbsp;
              {{user.itemName}}
              <input type="radio" name="Taskgroup" id="UG{{user.id}}" (click)="selectedUser(user)">
              <span class="checkmark" id="UG{{user.id}}"></span>
            </label>

          </div>
        </div>
      </div>
    </app-dialog>

    <app-dialog [(visible)]="showCCToDiv" id="showCCToDiv" *ngIf="showCCToDiv" [closable]="false">
      <!--<div class="col-xs-12" style="padding:0;height:6%;text-align:center">
      <img src="../../../assets/img/Black x.png" style="height:25px;float:right" (click)="CloseAssignTo()" />
    </div>-->
      <div class="col-xs-12" style="padding:0;height:6%;text-align:center">
        <label>CC TICKET TO</label>
      </div>

      <div class="col-xs-12" style="padding:0;height:6%;border:1px solid lightgray;border-radius:3px;">
        <div class="col-xs-7" style="padding:0;">
          <!--<input type="text" name="selectedCCTo" [(ngModel)]="selectedCCNames" readonly style="width: 100%;height: 100%;padding-left: 2%;padding-right: 2%;border: none;background-color: white !important;" />-->
          <div class="col-xs-12" style="padding:0;">
            <span> {{selectedCCNamesArray[0]}} ({{selectedCCNamesArray.length}})</span>
          </div>
        </div>
        <div class="col-xs-5" style="padding:0;">
          <div class="col-xs-4" style="display:block;padding:0">
            <button class="btn noprovider" (click)="ClearAssignTo('CCTo')" style="width:100%;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;">Cancel</button>
          </div>
          <div class="col-xs-4 pull-right" style="display:block;padding:0">
            <button class="btn noprovider" (click)="CloseAssignTo()" style="width: 100%; background: #2196F3; color: white; font-weight: 700; border: 1px solid #000; border-radius: 3px;">Save</button>
          </div>
        </div>
      </div>
      <div class="col-xs-12 scroll-c" style="height: 82%;border: 2px solid lightgray;padding: 2% 0 2% 0;">
        <div class="col-xs-12" *ngFor="let task of ticketTaskAssignTo" style="padding:0;">
          <div class="col-xs-12" style="display:flex">
            <label class="container1" style="font-size: 17px;">
              &nbsp;&nbsp;&nbsp;
              {{task.itemName}}
              <input type="checkbox" name="Taskgroup" id="TG{{task.id}}" (click)="selectedCCTasks(task)">
              <span class="checkmark" id="TG{{task.id}}"></span>
            </label>

          </div>
        </div>
        <div class="col-xs-12" *ngFor="let user of ticketUserAssignTo" style="padding:0;">
          <div class="col-xs-12" style="display:flex">
            <label class="container1" style="font-size: 17px;">
              &nbsp;&nbsp;&nbsp;
              {{user.itemName}}
              <input type="checkbox" name="Taskgroup" id="UG{{user.id}}" (click)="selectedCCUser(user)">
              <span class="checkmark" id="UG{{user.id}}"></span>
            </label>

          </div>
        </div>
      </div>
    </app-dialog>
  </div>
</div>
