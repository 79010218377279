export class Notes {
  public id: number;
  public created: string;
  public createdtime: string;
  public notedescription: string;
  public notedescriptionhtml: string;
  public accountid: number;
  public accountname: string;
  public type: string;
  public stagenotedescription: string;
  public notestype: string;
  public userfullName: string;
  public user: string;
  public USER: string;
  public isnewnote: number;
  public userid?: number;
  public isdocumentid: string;
  public docList: string[];
  public isDelink: boolean;
  public contactname: string;
}
