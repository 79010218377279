
  <div *ngIf="notifications.length > 0">
    <div *ngFor="let notification of notifications | slice:0:2" id="{{notification.Object.userMessageID}}"
         [ngClass]="className(notification)">
      <ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
    </div>
  </div>
  <ng-template #notificationTpl let-notification="notification">
    <div class="col-xs-12" style="font-weight: 700;padding:0.5%; height:100%;border: 3px solid black; background-color: rgb(249, 84, 115); color: white ">
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-11" style="padding:0;">  {{notification.Object.DisplayDate}} :{{notification.Object.MessageName}}</div>
        <div class="col-xs-1" style="padding:0;">
          <label style="float:right"><img src="../../assets/img/White x.png" style="height: 25px; width: 25px;" (click)="close(notification)"></label>
        </div>
      </div>
        <div class="col-xs-12" [innerHTML]="notification.Object.MessageDescription"
        style="padding:0;white-space:pre-wrap;white-space:-moz-pre-wrap;white-space:-pre-wrap;white-space:-o-pre-wrap;
        word-wrap:break-word;">
        </div>

      </div>
    


  </ng-template>
