<div class="col-xs-12" style="padding:0;height:100%">
  <div class="coladd-op-xs-12" style="padding:0;height:100%;">
    <div class="col-xs-12" style="padding:0;height:100%">
      <div class="col-xs-12" style="padding:0;height:100%" *ngIf="!ExpandPDF">
        <div class="col-xs-8" style="padding:0 0 0 1%;height:100%">

          <div class="col-xs-12" id="detailsDiv1" *ngIf="type == 'Account' || type == 'Customer'" style="padding:0;height:34%">
            <account-details *ngIf="contactid>0" [viewaccount]="viewaccount" [account]="account" [tab]="''" [noteslist]="allnotes" (btnClickEvent)="btnClickReceived($event)" [pageFrom]="'Opportunities'" [ViewData]="'Account'" [contact]="''"></account-details>

          </div>
          <div class="col-xs-12" id="ContactdetailsDiv" *ngIf="type == 'Contacts'" style="padding: 0; height: 27%">
            <account-details *ngIf="contactid>0" [viewaccount]="viewaccount" [account]="account" [tab]="''" [noteslist]="allnotes" (btnClickEvent)="btnClickReceived($event)" [pageFrom]="'Opportunities'" [ViewData]="'Contact'" [contact]="contact"></account-details>

          </div>


          <div [ngClass]="(account!=null && account.accountStatus == 2)?'col-xs-12 watermark':'col-xs-12'" id="opprtunityid" style="padding: 0 0% 0 0%;margin-top:1%;height:66%">
            <div class="col-xs-10" style="padding:0 0 0% 0%;height:8%">
              <label><b style="font-weight: 700 !important; font-size: 15px;">Opportunity History</b></label>
            </div>
            <div *ngIf="Allopportunities.length>0" class="col-xs-2" style="padding:0 0 0% 2%;height:8%">
              <div class="col-xs-2" style="padding:0 0 0% 2%;height:8%">

              </div>
              <div class="col-xs-5" style="padding:0 0 0% 0%;height:8%">

                <img src="../../../assets/img/PDF_file_icon.svg.png" style="width: 55%;" (click)="PDFClick(opportunities.ProposalPDF,'Proposal')" />
              </div>
              <div class="col-xs-5" style="padding:0 0 0% 0%;height:8%">

                <img src="../../../assets/img/PDF_file_icon.svg.png" style="width: 55%;" (click)="PDFClick(opportunities.ProposalPOPDF,'Proposal')" />
              </div>
            </div>
            <div class="col-xs-12" id="opptListTable" style="height:100%;overflow: auto;padding: 0 0 0 0%;">
              <ag-grid-angular class="ag-theme-balham"
                               style="height:91%;width:99%"
                               [gridOptions]="gridOptions"
                               [columnDefs]="columnDefs"
                               [defaultColDef]="applicationComponent.defaultGridColDef"
                               [rowData]="Allopportunities"
                               [context]="context"
                               [sideBar]="sideBar"
                               (selectionChanged)="onSelectionChanged($event)">
              </ag-grid-angular>

            </div>
          </div>

        </div>
        <div class="col-xs-4" style="padding:0;height:100%">
          <div class="col-xs-12" *ngIf="!PreviousNotesExpand" style=" height: 96%;padding:0">

            <div class="col-xs-12" style="height: 5%; margin-top: 1%; background-color: rgb(40,62,74); color: gray; padding: 1%; text-align: center; color: white ">
              <div class="col-xs-10"><label>OPPORTUNITY DETAILS</label> </div>
              <div class="col-xs-1">
                <button (click)="OpenOpportunity(opportunities.id)" style="background: transparent; text-align: end; padding-right: 0;">
                  <img src="../../../assets/img/Edit Icon White.png" style="height:21px;" title="Edit Task" />
                </button>
              </div>
            </div>
            <div class="col-xs-12" style="padding: 0%; height: 58%; border: 1px solid lightgreen; padding-left: 2%; margin-top: 1%; ">
             
              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b>Contact:</b></div>
                <b class="col-xs-8" style="font-size: 13px; padding: 0px; color:#00b3f7d9">{{opportunitiesHistory.contactName}}</b>
              </div>
              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b>Proposal Type:</b></div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.itemName}}</div>
              </div>
              <div class="col-xs-12" style="padding: 0; height: 6.8%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b> Description:</b></div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.ProposalDescription}}</div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b>Lead From:</b></div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.leadfrom}}</div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b>Description:</b> </div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.LeadDescription}}</div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b>#Drs:</b> </div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.drsName}}</div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b># Mid Levels:</b> </div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.midlevel}}</div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b>ECD:</b></div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.estimatedclosedate}}</div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b>ECP:</b></div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.PCname}}</div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b>Price:</b></div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.dollers}}</div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b>Monthly:</b></div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.RR}}</div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b>Owner:</b></div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.owner}}</div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b>Status:</b></div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.statusName}}</div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 6.9%">
                <div class="col-xs-4" style="padding: 0px; font-size: 11px;"><b>Created By:</b></div>
                <div class="col-xs-8" style="font-size: 11px; padding: 0px;">{{opportunitiesHistory.createdUser}}</div>
              </div>

            </div>
            <div class="col-xs-12" style="padding: 0; height: 33%; border: 1px solid lightgreen; border-top: none; border-bottom: none;">
              <div class="col-xs-12    scroll-c" style="padding: 1% 1% 1% 2%; height: 100%; overflow: auto; border: 3px solid lightgray;display:flex">
                <div class="col-xs-10" style=" padding: 0;">
                  <div class="col-xs-12" style="padding:0;margin-top:1%;font-size:11px;margin-bottom:5%" *ngFor="let note of opportunitiesnotes">
                    <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; font-weight: bold; height: 20px;"><b>{{note.created | date:"dd-MM-yy"}} | {{note.created | date:"hh:mm:ss"}}</b></div>
                    <div class="col-xs-12" style="padding: 0%; height: 20px;">User:&nbsp;{{note.user}}</div>
                    <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; height: 20px;">{{note.notedescription}}</div>
                  </div>
                </div>
                <div class="col-xs-2" style="padding:0">
                  <span style="float: right; width: 0; height: 0; border-top: 25px solid gray; border-left: 25px solid transparent;" (click)="PreviousNotesExpand = true"> </span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12" style="padding:0;height:96%" *ngIf="PreviousNotesExpand">
            <div class="col-xs-12" style="height: 100%; border: 1px solid lightgreen; padding: 0 0 0 0%">
              <div class="col-xs-12" style="height: 5%; margin-top: 0%; background-color: #2A3740; color: gray;  padding: 1%; text-align: center; ">
                <label>OPPORTUNITY DETAILS</label>
                <span  style="float:right;height:30px;padding:1%"> <img src="../../../assets/img/White x.png" style="height:20px;float:right;" (click)="PreviousNotesExpand = false"></span>
              </div>
              
              <div class="col-xs-12 scroll-c" style="padding:0;height:88%;overflow:auto">
                <div class="col-xs-12" style="padding: 0; margin-top: 1%; font-size: 11px; margin-bottom: 5%; padding-left: 2%;" *ngFor="let note of opportunitiesnotes">
                  <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; font-weight: bold"><b>{{note.created | date:"dd-MM-yy"}} | {{note.created | date:"hh:mm:ss"}}</b></div>
                  <div class="col-xs-12" style="padding:0%">User:&nbsp;{{note.user}}</div>
                  <div class="col-xs-12" style="padding:0%;margin-top:0.5%">{{note.notedescription}}</div>
                </div>
              </div>

            </div>
          </div>
          <!--<div class="col-xs-12" *ngIf="showPDFDiv">
    <div id="pdfDiv" class="col-xs-12">

    </div>
  </div>-->
        </div>


        </div>
      <app-dialog [(visible)]="ShowAddOppDialog " *ngIf="ShowAddOppDialog " id="ShowAddOppDialog" [OutSideClick]="false" [closable]="false">
        <add-opportunity [OpportunityID]="OpportunityID" [editOpp]="editOpp" [type]="type" [contactid]="contactid" [Status]="status" (sendMsgEvent)="ResponseFromAddOpp($event)"></add-opportunity>
      </app-dialog>
    </div>
  </div>
</div>
<app-dialog [(visible)]="showPDFDiv" *ngIf="showPDFDiv" id="PDFDiv"  [CloseButtonColor]="'white'">
  <div id="pdfDiv" class="col-xs-12">

  </div>
</app-dialog>
<app-dialog [(visible)]="TaskDiv" *ngIf="TaskDiv" id="TaskDiv" (visibleChange)="onCloseDialogEventReceived($event)">
  <div *ngIf="TaskDiv && this.contactid>0" class="col-xs-12" style="padding-right:0;border: 1px solid #ddd;padding:0;height:100%;">
    <scheduletask [id]="contact.id" [taskid]="" [type]="'Contacts'" [taskDialog]="true" [SearchDialog]="false" [selectorTag]="true" (sendMsgEvent)="onTaskSaved($event)"></scheduletask>
  </div>
</app-dialog>
<app-dialog [(visible)]="showAddEditAccount" *ngIf="showAddEditAccount" id="addEditAccount" [closable]="(action!='View')? false:true" [OutSideClick]="(action!='View')? false:true">
  <app-add-edit-account *ngIf="action!='View'" [accountID]="this.account.id" [type]="'Account'" (sendMsgEvent)="ReceiveEvent($event)"></app-add-edit-account>
  <accountorcontactview *ngIf="action=='View'" [accountId]="this.account.id" [type]="'Account'"></accountorcontactview>
</app-dialog>
<app-dialog [(visible)]="showAddEditContact" *ngIf="showAddEditContact" id="addEditContact" [closable]="false" [OutSideClick]="false">
  <editcontact [contactid]="0" [accountId]="account.id" [type]="'Contact'" (sendMsgEvent)="ContactReceiveEvent($event)"></editcontact>
</app-dialog>
