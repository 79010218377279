import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Users } from '../../models/users';
import { DocumentmanagementComponent } from '../documentmanagement/documentmanagement.component';
import { UploadDocumentComponent } from '../upload-document/upload-document.component';
import { CustomerService } from './../../services/customer.service';
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../application.component';
import * as moment from 'moment'
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { Dropdowntype } from 'src/app/models/dropdowntype';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { GridOptions, RowDragEndEvent } from 'ag-grid';
import { UserMessages } from 'src/app/models/UserMessages';
import { DatePipe } from '@angular/common';
import { MessagingService } from 'src/app/services/messaging.service';
import { ToastrService } from 'ngx-toastr';
import { AuditLogModel } from '../../models/auditlog';
import { SalesXCRMChartTabs } from '../../models/SalesXCRMChartTabs';
declare var $: any;
@Component({
  selector: 'app-sales-xcrm',
  templateUrl: './sales-xcrm.component.html',
  styleUrls: ['./sales-xcrm.component.css']
})
export class SalesXcrmComponent implements OnInit {
  currentuser: any;
  user: Users = new Users();
  public years: number[] = [];
  public yy: number;
  uploadDocumentShow = false;
  calanderonoff: boolean = false;
  showDetailsDialog: boolean = false;
  selectedMenuRadio: string = "notab";
  selectedMenunumber: number;
  documentcount: number = 0;
  docstatus: string = "New";
  documentview: boolean = false;
  isdeletedDocuments: boolean = false;
  ExpandView: boolean = false;
  selectedReportType: number = 1000;
  finalitems = "";
  selectedItems = [];
  selectedItemslength: number;
  pageSize: string = "1000";
  dropdownList = [];
  documentindex: number = 0;
  userdocumentcount: number = 0;
  userdocumentlist: any[] = [];
  currentPage: number = 1;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  @ViewChild(DocumentmanagementComponent) docMgmtComp;
  @ViewChild(UploadDocumentComponent) uploadDocComp: UploadDocumentComponent;
  DisplayrowsList: Dropdowntype[] = [];
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");

  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  currentYear = new Date().getFullYear();
  public gridOptionsUserMessages: GridOptions;
  public sideBar;
  public columnDefsMessage: any[];
  contactStatus: any;
  DeleteMessagePopup: boolean = false;
  deleteRowDataMessage: any;
  rolesList: Dropdowntype[] = [];
  allMessages: UserMessages[];
  datePipe: DatePipe = new DatePipe("en-US");
  userMessages: UserMessages = new UserMessages();
  DocViewAccessDenied: boolean = false;
  ChartTabs: SalesXCRMChartTabs[] = [];
  private gridApi;
  constructor(private messageService: MessagingService, public applicationComponent: ApplicationComponent, private customerService: CustomerService, private userservice: UserService, private opprtunityservice: OpprtunitiesService, private toastr: ToastrService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    }
  }

  ngOnInit() {
    
    this.selectedMenuRadio = "CRMtab11000";
 
    this.loadUserdata();
    this.getYear(); this.applicationComponent.SearchSelectedItems = [];  
    this.LoadChartTabs();
    this.GetAllaccesusersListbyuser();
   
    if (this.applicationComponent.selectedusers != '' || this.applicationComponent.selectedusers != null) {
      this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
        Owner.forEach(Owner => {
        });
        this.selectedItems = Owner;
      })
    }
    else {
      this.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username }
      ];
      this.finalitems += this.currentuser.id + ',';
     
    }
   
    
  }

  ngAfterViewInit() {
 
    let self = this;
    setTimeout(function () {
      $('.TablabelClass').attr('style', 'text-align:center;color:black;width:50%;height:49px;background-color:white')
      $('#CRMtabLabel1000').attr('style', 'text-align:center;color:black;width:50%;height:49px;background-color:#badb98')
      $('#CRMtab1000').attr('style', 'border: none !important; color: transparent; background: transparent;')

      self.ReportChanged(1000);
    }, 300);
    
  }
  initializeAgGridUserMessages() {
    
    this.gridOptionsUserMessages = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };

    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 37).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefsMessages(columnSelections);
    });

  }
  createColumnDefsMessages(columnSelections: string) {
    let selfObj = this;
      this.columnDefsMessage = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        {
          field: 'userMessageID', headerName: 'Message ID', hide: true, width: 120        
        },
        {
          field: 'readuserCount', headerName: 'readuserCount', hide: true, width: 120
        },
        {
          field: 'DisplayDate', headerName: 'Date', hide: false, width: 120, cellStyle: function (params) {           
             if (params.data.readuserCount == '0') { return { color: '#FF0000'}; }
          }  },
        {
          field: 'DisplayDate', headerName: ' Date Viewed ', hide: false, cellStyle: function (params) {
            if (params.data.readuserCount == '0') { return { color: '#FF0000' }; }
          } },
        {
          field: 'MessageName', headerName: 'Subject', hide: false, width: 120, cellStyle: function (params) {
            if (params.data.readuserCount == '0') { return { color: '#FF0000' }; }
          }
         },
        {
          field: 'MessageType', headerName: 'Message Type', width: 300, cellStyle: function (params) {
            if (params.data.readuserCount == '0') { return { color: '#FF0000' }; }
          }
         },
        {
          field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right', cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true"></i>';
            eDiv.addEventListener('click', function () {
              selfObj.deleteUserMessage(params.data);
            });
            return eDiv;
          }
        }
      ];
    
  }
  deleteUserMessage(UserMessages) {
    this.deleteRowDataMessage = UserMessages;
    this.contactStatus = UserMessages.deletedStatus;
    this.DeleteMessagePopup = true;
    setTimeout(function () {
      $('#DeleteMessagePopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4);
  }

  ReceiveDeleteeventForMessage(msg) {
    if (msg == "Ok") {
      this.userMessages = this.deleteRowDataMessage;
      this.userMessages.deletedStatus = 1;
      this.AddorUpdateUserMessages(this.userMessages);
    }
    this.DeleteMessagePopup = false;

  }
  AddorUpdateUserMessages(userMessages) {
    
    this.messageService.AddorUpdateUserMessages(userMessages).then((res) => {
      this.toastr.success("Message Deleted successfully.", "Message Delete", { timeOut: 600 });
      this.GetAllUserMessages();      
    });
  }

  GetAllaccesusersListbyuser() {
     
    
    this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
      this.DisplayrowsList = Rows;
    });
  }

  loadPendingDocuments() {
    this.customerService.GetAllPendingDocuments(0, 'MySalesXCRM', 2019).then((document) => {
      this.documentcount = document.length;
    });

  }
  previousdocument() {

    this.documentindex--;
    if (this.userdocumentlist[this.documentindex].permissionlevel <= this.currentuser.permissionlevelid) {

      this.getImageData(this.userdocumentlist[this.documentindex].id);
    } else {
      this.documentindex++;
      this.DocViewAccessDeniedCss();
    }

    //this.documentindex--;
    //this.getImageData(this.userdocumentlist[this.documentindex].id);
  }
  nextdocument() {
    //this.documentindex++;
    //this.getImageData(this.userdocumentlist[this.documentindex].id);

    this.documentindex++;
    if (this.userdocumentlist[this.documentindex].permissionlevel <= this.currentuser.permissionlevelid) {

      this.getImageData(this.userdocumentlist[this.documentindex]);
    } else {
      this.documentindex--;
      this.DocViewAccessDeniedCss();
    }
  }
  DocViewAccessDeniedCss() {
    this.DocViewAccessDenied = true;
    setTimeout(function () {
      $('#DocViewAccessDenied .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4);
  }
  loadUserdata() {
    this.userservice.GetUserByuserId(this.currentuser.id).then(result => {
      this.user = result;
      this.user.selectedItems = this.sortByKey(this.user.selectedItems, 'category');
      this.selectedItemslength = this.user.selectedItems.length;
      if (this.user.EmailSetUp == "SendGrid") {
        this.user.isSendGrid = true;
        this.user.isSMTP = false;
      } else {
        this.user.isSendGrid = false;
        this.user.isSMTP = true;
      }
      
      if (this.user.customertypeaccess == 0)
        this.user.ConvertCustomer = "No";
      else
        this.user.ConvertCustomer = "Yes";
    });
  }

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  getYear() {
    var today = new Date();
    this.yy = today.getFullYear();
    for (var i = (this.yy - 100); i <= this.yy; i++) {
      this.years.push(i);
    }
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 58).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.pageSize = config.maxlimit.toString();
      }
    
      this.startDateVal = (config != null && config.startdate != null) ? config.startdate : (new Date().getFullYear() - 1) + '-01-01';
      this.startDatePlaceHolder = (config != null && config.startdate != null) ? 'Start:' + moment(config.startdate).format("MM-DD-YYYY") : 'Start: 01-01-' + (new Date().getFullYear() - 1).toString();
      this.endDateVal = (config != null && config.enddate != null) ? config.enddate : new Date().getFullYear() + '-12-31';
      this.endDatePlaceHolder = (config != null && config.enddate != null) ? 'End:' + moment(config.enddate).format("MM-DD-YYYY") : 'End: 12-31-' + (new Date().getFullYear() - 1).toString();
     
    });
  }
  SelectedYear(year) {
   // this.docMgmtComp.loaddocuments('SalesXUsers', year, this.selectedReportType);
  }
  GetAllUserMessages() {
    this.messageService.GetAllUserMessages(this.currentuser.id).then((res) => {
      this.allMessages = res;
      this.allMessages.forEach(ele => {
        ele.DisplayDate = this.datePipe.transform(ele.DisplayDate, "MM-dd-yyyy");
        if (ele.MessageToUsers != null) {
          ele.MessageToUserCount = ele.MessageToUsers.split(',').length;
        }
        if (ele.readusers != null) {
          ele.readuserCount = ele.readusers.split(',').length;
        }
        else {
          ele.readuserCount = 0;
        }
        ele.RemainingCount = ele.MessageToUserCount - ele.readuserCount;
      });
    });
  }
  ReportChanged(reporttype) {
    this.documentview = false;
    $(setTimeout(function () {
      $('.TablabelClass').attr('style', 'text-align:center;color:black;width:50%;height:49px;background-color:white')
      $('#CRMtabLabel' + reporttype).attr('style', 'text-align:center;color:black;width:50%;height:49px;background-color:#badb98')
      $('#CRMtab' + reporttype).attr('style', 'border: none !important; color: transparent; background: transparent;')
    }, 300))
    
    var obj: AuditLogModel = { id: 0, type: 'My SalesXCRM', action: 'My SalesXCRM Viewed', AccountOrContactId: 0, AccountOrContact: "", documentid: reporttype, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Proposal Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);

    if (this.uploadDocumentShow == true) {
      this.selectedMenunumber = reporttype;
      this.uploadDocumentShow = true
      if (reporttype) {
        this.selectedMenunumber = reporttype;
        this.uploadDocComp.TabChange(reporttype);
      }
      else {
        this.uploadDocComp.TabChange(1000);
        this.selectedMenunumber = 1000;
      }
    }
    else {
      this.uploadDocumentShow = false;
      this.selectedReportType = reporttype;
      let selfobj = this;
      if (reporttype != 1016) {
        $(setTimeout(function () {
          selfobj.docMgmtComp.loaddocuments('SalesXUsers', selfobj.currentYear, selfobj.selectedReportType, $("#userids").val(), (selfobj.calanderonoff) ? selfobj.startDateVal : '2018-01-01', selfobj.endDateVal);
          selfobj.customerService.GetAllDocumentList(selfobj.currentuser.id, "MySalesXCRM", 2020, selfobj.selectedReportType.toString(), selfobj.startDateVal, selfobj.endDateVal).then((userdocument) => {
            selfobj.userdocumentlist = userdocument;
          });

        }, 200));
      } else {
        selfobj.initializeAgGridUserMessages();
        selfobj.GetAllUserMessages();
      }
    }

    if (reporttype != 1016) {
      this.selectedMenunumber = reporttype;
      if (this.uploadDocumentShow == true) {
        this.uploadDocComp.TabChange(reporttype);
      }
      
    }
    else {
      if (this.uploadDocumentShow == true) { 
        this.uploadDocComp.TabChange(reporttype);
      }
      this.selectedMenunumber = 1000;
    }
    
  }
  dateChange(event) {
    this.AssignStartAndEndDate(event);
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
    this.docMgmtComp.loaddocuments('SalesXUsers', this.currentYear, this.selectedReportType, $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);
    this.docMgmtComp.saveConfiguration(this.pageSize,this.startDateVal, this.endDateVal) 
 
  }
  txtPageSizeChanged(pageSize) {
    this.currentPage = 1;
    this.pageSize = pageSize;
    this.docMgmtComp.saveConfiguration(this.pageSize, this.startDateVal, this.endDateVal);
  }
  AssignStartAndEndDate(event) {

    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  onOpenUploadDocument(msg) {
    debugger;
    var isnum = /^\d+$/.test(msg);
    if (isnum) {
      this.userdocumentcount = msg;
    }
    else if (msg == "UploadDocument")
    {
      this.uploadDocumentShow = true;
      let objSelf = this;
      $(setTimeout(function () {
        objSelf.uploadDocComp.OpenFilemanual(objSelf.docstatus);
        objSelf.documentcount = 0;
      }, 100));

    }
    else if (msg == "EditDocumentFalse") {

    }
    else if (msg.startsWith("TabChange")) {
      this.uploadDocumentShow = true;
    }
    else {
      this.documentview = true;
      this.onDocumentOpen(msg);

      this.customerService.GetAllDocumentList(this.currentuser.id, "MySalesXCRM", 2020, this.selectedReportType.toString(), this.startDateVal, this.endDateVal).then((userdocument) => {      
        this.userdocumentlist = userdocument;
      });
    }

  }

  onDocumentOpen(myBaseString) {
  
    var viewtype = "crm";
    $('#pdfDiv').html('<embed src="' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
    $('#pdfDiv').attr('style', 'height: 395%;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');
  }
  SavedDocuments(msg) {
    switch (msg) {
      case '1000':
        this.selectedMenuRadio = "CRMtab" + this.selectedReportType;
        this.uploadDocumentShow = true;
        break;
      case '1005':
        this.selectedMenuRadio = "CRMtab" + this.selectedReportType;
        this.uploadDocumentShow = true;
        break;
      case '1004':
        this.selectedMenuRadio = "CRMtab" + this.selectedReportType;
        this.uploadDocumentShow = true;
        break;
      case '1009':
        this.selectedMenuRadio = "CRMtab" + this.selectedReportType;
        this.uploadDocumentShow = true;
        break;
      case '1013':
        this.selectedMenuRadio = "CRMtab" + this.selectedReportType;
        this.uploadDocumentShow = true;
        break;
      case '1012':
        this.selectedMenuRadio = "CRMtab" + this.selectedReportType;
        this.uploadDocumentShow = true;
        break;
      case '1008':
        this.selectedMenuRadio = "CRMtab" + this.selectedReportType;
        this.uploadDocumentShow = true;
        break;
      case '1016':
        this.selectedMenuRadio = "CRMtab" + this.selectedReportType;
        this.uploadDocumentShow = true;
        break;
      default:
        this.selectedMenuRadio = "CRMtab" + this.selectedReportType;
        this.uploadDocumentShow = false;
        this.selectedMenunumber = 0;
        this.loadPendingDocuments();
        this.ReportChanged(this.selectedReportType);
    }
  }
  onItemSelect(item: any) {
    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.docMgmtComp.loaddocuments('SalesXUsers', this.currentYear, this.selectedReportType, $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);

  }
  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.docMgmtComp.loaddocuments('SalesXUsers', this.currentYear, this.selectedReportType, $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);
     // this.Generate($("#userids").val());
    } else {
      this.docMgmtComp.loaddocuments('SalesXUsers', this.currentYear, this.selectedReportType, $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);
      $("#userids").val('');
    }
  }
  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.docMgmtComp.loaddocuments('SalesXUsers', this.currentYear, this.selectedReportType, $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);
    //this.Generate($("#userids").val());
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.docMgmtComp.loaddocuments('SalesXUsers', this.currentYear, this.selectedReportType, $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);
    $("#userids").val('');
  }
  Expandimage() {
    if (document.getElementById('pdfDiv').className == 'col-xs-12') {
      document.getElementById('window').style.display = 'block';
      document.getElementById('pdfDiv').className = 'col-xs-6';
      $('#documentview').removeClass('col-xs-12');
      $('#documentview').addClass('col-xs-6');
      this.ExpandView = false;
    } else {
      document.getElementById('window').style.display = 'none';
      document.getElementById('pdfDiv').className = 'col-xs-12';
      $('#documentview').removeClass('col-xs-6');
      $('#documentview').addClass('col-xs-12');
      this.ExpandView = true;
    }     
  }
  onUserMessageSelection(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.userMessages = event.api.getSelectedRows()[0];
        this.userMessages.DisplayDate = (this.datePipe.transform(this.userMessages.DisplayDate, "yyyy-MM-dd"));
        if (this.userMessages.readuserCount == 0) {
          this.applicationComponent.onWizzardClosed(this.userMessages);
          this.GetAllUserMessages();
          this.applicationComponent.GetUserMessagesByUserId();
        }
        this.documentview = true;
      }
    }
    else {
      this.documentview =  false;
    }
  
  }
  //closeDetailsDialog() {
  //  this.showDetailsDialog = false;
  //}
  AddDocuments(val)
  {
     
    this.docstatus = val;
    if (this.uploadDocumentShow) {
      this.documentcount = 0;
      this.uploadDocComp.OpenFilemanual(this.docstatus);
    } else {
      this.docMgmtComp.uploadFiles();
    }
  }
  calanderclick(val) {
    this.calanderonoff = val;
    this.docMgmtComp.loaddocuments('SalesXUsers', this.currentYear, this.selectedReportType, $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }

  }
  closeEmbed() {
    this.documentview = false;
    this.uploadDocumentShow = false
    document.getElementById('window').style.display = 'block';
    //this.documentview = !this.documentview;
    document.getElementById('pdfDiv').style.display = 'none';

  }

  getImageData(documentid) {
    var myBaseString = "";
    this.userservice.GetImageData(documentid).then(result => {
      myBaseString = result;
      let objSelf = this;
      $(setTimeout(function () {        
        $('#buttonDiv').attr('style', 'display:block;height:100%');
      }, 100));

      $('.bodyContaint').css('height', '1000px')
      $('#pdfDiv1').css('display', 'block');
      this.onDocumentOpen(myBaseString);
    });
  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  UserlistDataReceived(outProps: OutputPropsUserList) {
      this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
      if (outProps.finalitems != "") {
        $("#userids").val(outProps.finalitems);
        if (!outProps.pageSizeChanged) {
          this.docMgmtComp.loaddocuments('SalesXUsers', this.currentYear, this.selectedReportType, $("#userids").val(), (this.calanderonoff) ? outProps.startDateVal : '2018-01-01', (this.calanderonoff) ? outProps.endDateVal : this.endDateVal, this.pageSize);
        } else {
          this.docMgmtComp.pageSizeChanged(this.pageSize);
        }
      } else {
        $("#userids").val('');
        this.docMgmtComp.loaddocuments('SalesXUsers', this.currentYear, this.selectedReportType, $("#userids").val(), (this.calanderonoff) ? outProps.startDateVal : '2018-01-01', (this.calanderonoff) ? outProps.endDateVal : this.endDateVal, this.pageSize);
      }
    
  }
  loadDeletedDocuments() {
    
    if (!this.isdeletedDocuments) {
      this.docMgmtComp.loadDeleteddocuments('SalesXUsers', this.currentYear, this.selectedReportType, $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, this.pageSize);
    } else {
      this.docMgmtComp.loaddocuments('SalesXUsers', this.currentYear, this.selectedReportType, $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, this.pageSize);
    }
  }

  close() {
    this.sendMsgEvent.emit("My Documents");
    
  }


  LoadChartTabs() {
    this.customerService.GetMySalesXCRMChartTabs(this.currentuser.id).then((ChartTabs) => {      
      this.ChartTabs = ChartTabs;
      
    });

  }
}
