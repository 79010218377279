export class Zendeskuser {
  public Name: string;
  public LocaleId: string;
  public PhotoUrl: string;
  public OpenidUrl: string;
  public CreatedAt: string;
  public LastLogin: string;
  public Details: string;
  public UpdatedAt: string;
  public Notes: string;
  public Email: string;
  public ExternalId: string;
  public RestrictionId: number;
  public Phone: string;
  public IsActive: string;
  public IsVerified: string;
  public TimeZone: string;     

}
