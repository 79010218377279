import { Pipe, PipeTransform } from '@angular/core';
import { ColumnNames } from '../models/column-names';

@Pipe({
  name: 'searchboxfilter'
})
export class SearchboxfilterPipe implements PipeTransform {
  transform(columnNames: ColumnNames[], searchFilter: string): ColumnNames[] {
    if (searchFilter.toLowerCase().trim() == "")
      return columnNames;
    else
      return columnNames.filter(columnName => columnName.displayName.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0);
  }
}
