import { Component, OnInit, ViewContainerRef, ElementRef, Renderer2, ViewChild, Optional, Input, EventEmitter, Output  } from '@angular/core';
import { Contacts } from '../../models/contacts';
import { Accounts } from '../../models/accounts';
import { TempData } from '../../models/temp-data';
import { Notes } from '../../models/notes';
import { Http } from '@angular/http';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from './../../services/customer.service';
import { DatePipe } from '@angular/common';
import { AppSettings } from '../../app.settings';
import { ToastrService } from 'ngx-toastr';
import { ParentComp, UserlistComponent } from "../userlist/userlist.component";
import { CalllisthistoryComponent } from "../calllisthistory/calllisthistory.component";
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { NotesrendererComponent } from '../notesrenderer/notesrenderer.component';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { PageComponent } from '../page/page.component';
import { Column } from 'ag-grid';
import { EditdeletecvComponent } from '../editdeletecv/editdeletecv.component';
import { EditactivatecvComponent } from '../editactivatecv/editactivatecv.component';
import { IMyDpOptions } from 'mydatepicker';
import { Dropdowntype } from '../../models/dropdowntype';
import { AccountsService } from '../../services/accounts.service';
import { ApplicationComponent } from '../application.component';
import { EditcontactComponent } from '../editcontact/editcontact.component';
import * as moment from 'moment'
declare var require: any;
import { NotesComponent } from '../notes/notes.component';
import { OwnershipsharingComponent } from '../ownershipsharing/ownershipsharing.component';
import { AddressmapComponent } from '../addressmap/addressmap.component';
import { DeletePopupComponent } from '../delete-popup/delete-popup.component';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { ContactsdashboardComponent } from '../contacts/contactsdashboard/contactsdashboard.component';
declare var $: any;
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  resizeheight = (window.innerHeight / 1.577) + 'px';
  public zipmask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public dobmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  currentuser: any;
  public sideBar;
  notedescription: string;
  itemResource: any;
  items = [];
  Contactitems = [];
  ContactitemCount = 0;
  itemCount = 0;
  params: any;
  term: string = "null";
  showDialog = false;
  ActiveConvertDialogg = false;
  SearchDiv123 = false;
  CallHistoryDialog = false;
  notesshowDialog = false;
  calanderonoff: boolean = true;
  dialogOpen: boolean = false;
  contactid: number; callhistorystatus: string;
  type: string;
  isselectionUserlist: boolean = false;
  showAddEditContact: boolean = false;
  EnableSearch: boolean = false;
  isContactViewOpen: boolean = true;
  leadstatus: number;
  notesitemResource: any;
  notesitems = [];
  notesitemCount = 0;
  ContactIds: string;
  notesparams: any;
  Ids = [];
  subtype: string = "contacts";
  ContacttableDiv: boolean = true;
  practicetableDiv: boolean = true;
  IsEditContact: boolean = false;
  isSharing: boolean = false;
  dropdownList = [];
  finalitems = "";
  /*selectedItems = [];*/
  dropdownSetting = {};
  datePipe: DatePipe = new DatePipe("en-US");
  contact: Contacts = new Contacts();
  account: Accounts = new Accounts();
  notes: Notes = new Notes();
  accessDenied: boolean = false;
  isMultiLine: boolean = false;
  showcustomer: boolean = false;
  isSharedConatcts: boolean = true;
  itemList = [];
  //selectedItems2 = [];
  settings2 = {};
  thisComp: ParentComp = ParentComp.Contacts;
  isViewInitialized: boolean = false;
  isPgClickRegistered: boolean = false;
  @ViewChild(UserlistComponent) userListComp;
  @ViewChild(CalllisthistoryComponent) callhistorycmp;
  @ViewChild(NotesComponent) notecomp;
  @ViewChild(EditcontactComponent) editcontactcomp;
  @ViewChild(AddressmapComponent) addressMapComp: AddressmapComponent;
  @ViewChild(OwnershipsharingComponent) ownrshipComp: OwnershipsharingComponent;
  @ViewChild(DeletePopupComponent) deletePopupComp: DeletePopupComponent;
  @ViewChild(ContactsdashboardComponent) condashcmp: ContactsdashboardComponent;
  isHeaderFixed: boolean = true;
  isStatusidopen: boolean = false;
  public gridOptions: GridOptions;
  public gridOptionsnotes: GridOptions;
  public columnDefs: any[];
  public TableColumns: any;
  public defaultColDef;
  public columnDefsnotes: any[];
  public contactProps: string[];
  allContacts: Contacts[];
  OriginalallContacts: Contacts[];
  pageSize = '100'; value = "";
  public selectedStatus: number = 309;
  public context;
  //googlemapshow: boolean = false;
  public frameworkComponents;
  public getRowHeight;
  activeItems: boolean = true;
  isGooglemapExpandOpen: boolean = false;
  colSelections: string;
  aggridheight: string;
  allnotes: Notes[];
  allContactsproviders: any[] = [];
  //public editorOptions: Object = { key: 'pe1G2wF1I1B2C3B5D6B4ogtzrkayiqC2A-7pmB-16D4E3D2F3C3I2D1B10C2B3==' };
  Collegelist: Dropdowntype[] = [];
  contacttypelist: Dropdowntype[] = [];
  SexList: Dropdowntype[] = [];
  TitleList: Dropdowntype[] = [];
  SuffixList: Dropdowntype[] = [];
  Specialitytypelist: Dropdowntype[] = [];
  MonthList: Dropdowntype[] = [];
  DayList: Dropdowntype[] = [];
  MedicalSchoolList: Dropdowntype[] = [];
  StatesList: Dropdowntype[] = [];
  CitiesList: Dropdowntype[] = [];
  OwnerList: Dropdowntype[] = [];
  Years: any[];
  MedSchoolYears: any[];
  ResidencyYears: any[];
  FellowShipYears: any[];
  @Input()
  FromSelector: boolean = false;
  tempdata: TempData[] = [];
  currentPage: number = 1;
  startrow: number = 1;
  noofRows: number = 1;
  totalnumberofrows: number = 1;
  totalnumberofPages: number = 1;
  userInfo: string;
  isModal: boolean = true;
  accountid;
  divHeight =(screen.height * 0.6);
  private gridApi;
  private gridColumnApi;
  isShowDetailsChecked: boolean = false;
  isShowAccountInfo: boolean = false;
  public stateInfo = []; 
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear()-1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };  
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }  
  active_deactiveStatus: number=0; NotesDec = false;
  timezonevalue: string;
  showEditNoteBtn: boolean = false;
  isShowContactInfo: boolean = false;
  editNoteId: boolean = false;
  Contactvales: string = "0";
  isCustomer: boolean = false;
  isFormerCustomer: boolean = false;
  contactIdArr: Dropdowntype[] = [];
  DeleteConfirmationPopup: boolean = false;
  deleteRowData: any;
  contactStatus: any;
  DisplayrowsList: Dropdowntype[] = [];
  SelectedContact: number = 0;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  DocExpandView: boolean = false;
  constructor(private paginationcop: PageComponent, public applicationComponent: ApplicationComponent, private accountsService: AccountsService, private elementRef: ElementRef,
    private renderer: Renderer2, private http: Http, private opprtunityservice: OpprtunitiesService, private route: ActivatedRoute, private customerService: CustomerService,
    private router: Router, private settings: AppSettings, private toastr: ToastrService, private viewContainerRef: ViewContainerRef) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  initializeAgGrid(typeval) {
    this.gridOptions = <GridOptions>{
      pagination: true, paginationPageSize: 1000,enableColResize: true, enableSorting: true, defaultToolPanel:'', enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true,
      rowSelection: 'multiple', enableCellChangeFlash: true, rowDragManaged: true, animateRows: true, suppressSorting: true, suppressMultiSort: true
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',
      defaultToolPanel: 'none',
    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 5).then((config) => {    
    columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = columnSelections;
      this.startDateVal = (config != null && config.startdate != null) ? config.startdate : (new Date().getFullYear() - 1) + '-01-01';    
      this.endDateVal = (config != null && config.enddate != null) ? config.enddate : new Date().getFullYear() + '-12-31';
      this.GetContactList(this.term, $("#userids").val(), 0);     
    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },      
      ],
      position: 'right',
      defaultToolPanel: 'none',
    };

    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent,
      editdeletecv: EditdeletecvComponent,
      editactivatecv: EditactivatecvComponent
    };
  
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3,
      closeDropDownOnSelection: false
    };
  }
  createColumnDefs(columnSelections: string, searchstring, @Optional() isDeActive: boolean = false) {
    var searcharray = [];
    if (this.gridOptions.quickFilterText != null && this.gridOptions.quickFilterText != undefined && this.gridOptions.quickFilterText != '') {
      searcharray.push(this.gridOptions.quickFilterText);
    }  
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
     
      columnSelectionsArray = columnSelections.split(',');
      this.columnDefs = [];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#" && !isDeActive) {

          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            
          });
        }       
        else if (fieldHeaderVisibleWidthArray[0] == "callliststatus") {
          this.columnDefs.push({
            field: 'callliststatus', headerName: 'Call List Hx', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:50px; background:white">' + params.value + '</button>';
              var eButton = eDiv.querySelectorAll('.btn-simple')[0];
              eButton.addEventListener('click', function () {
                selfObj.callhistorystatus = params.data.callliststatus;
                selfObj.contactid = params.data.id;
                selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
                selfObj.CallHistoryDialog = true;
              });
              return eDiv;
            }, width: parseInt(fieldHeaderVisibleWidthArray[3])
          })
        }
        else if (fieldHeaderVisibleWidthArray[0] == "customertype") {
          this.columnDefs.push({
            field: 'customertype', headerName: 'Customer Type', hide: (!this.isCustomer && !this.isFormerCustomer),
            cellRenderer: function (params) {
              if (params.value == '1') { return "Active" }
              else if (params.value == '2') { return "In Active" } else {
                return params.value;
              }
            },
            getQuickFilterText: () => '',
             width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "lastname") {
           
          this.columnDefs.push({
            field: 'lastname', headerName: 'Last Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
           
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                //if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                //} else {
                  return params.value;
                //}
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "firstname") {
          this.columnDefs.push({
            field: 'firstname', headerName: 'First Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                //if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                //} else {
                  return params.value;
                //}
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "zip") {
          this.columnDefs.push({
            field: 'zip', headerName: 'ZIP', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellRenderer: function (params) {
              //if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              //} else {
                return params.value;
             // }
            },
            getQuickFilterText: () => '',
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "accnumber") {
          this.columnDefs.push({
            field: 'accnumber', headerName: 'Acct #', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer), cellRenderer: function (params) {
              //if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              //} else {
                return params.value;
             // }
            }, getQuickFilterText: () => '', width: parseInt(fieldHeaderVisibleWidthArray[3])  });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "accountname") {
          this.columnDefs.push({
            field: 'accountname', headerName: 'Account', cellRenderer: function (params) {
              //if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              //} else {
                return params.value;
             // }
            }, getQuickFilterText: () => '',hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "speciality") {
          this.columnDefs.push({
            field: 'specialityName', headerName: 'Speciality', cellRenderer: function (params) {
              //if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              //} else {
                return params.value;
              //}
            }, getQuickFilterText: () => '', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "contacttype") {
          this.columnDefs.push({
            field: 'contacttypeName', headerName: 'Contact Type', cellRenderer: function (params) {
              //if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              //} else {
                return params.value;
             // }
            }, getQuickFilterText: () => '', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "actions") {
          this.columnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: isDeActive ? 'editactivatecv' : 'editdeletecv', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, pinned: 'right', width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({
            field: 'OWNER', headerName: 'Owner', cellRenderer: function (params) {
              //if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              //} else {
                return params.value;
             // }
            }, getQuickFilterText: () => '', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, suppressSorting: true,  width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "conmapit") {
          this.columnDefs.push({
            field: 'conmapit', headerName: 'Map It', hide: false, getQuickFilterText: () => '', suppressSorting: true, width:80, cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<img src="../../../assets/img/Map it.png" style="height:20px;width:50%"/>';
              //eDiv.addEventListener('click', function () {
              //});
              return eDiv;
            }       
          });
        }
        else {

        /*  var res = this.TableColumns.filter(ele => ele.ColumnName == fieldHeaderVisibleWidthArray[0] && ele.DataType == "Pick List")*/
          //if (res.length > 0) {
          //  selfObj.columnDefs.push({
          //    field: fieldHeaderVisibleWidthArray[0],
          //    headerName: fieldHeaderVisibleWidthArray[1],
          //    hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
          //    width: parseInt(fieldHeaderVisibleWidthArray[3]),
          //    cellRenderer(params) {
                 
          //      if (params.value != null && params.value != "") {
          //        return params.value.split(":")[1];
          //      } else {
          //        return params.value;
          //      }

          //    }
          //  });
          //}
          //else {
            if (fieldHeaderVisibleWidthArray[0] == 'office' || fieldHeaderVisibleWidthArray[0] == 'cell') {
              selfObj.columnDefs.push({
                field: fieldHeaderVisibleWidthArray[0],
                headerName: fieldHeaderVisibleWidthArray[1],
                hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, 
                width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
                  if (searcharray.length > 0 && params.value != null) {
                    let x = params.value.split(" ");
                    let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
                    var st = "";
                    for (let i = 0; i < x.length; i++) {
                      if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                        st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                      }
                      else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                        st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                      }
                      else {
                        st += x[i] + " ";
                      }

                    }
                    return st

                  } else {
                    //if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                    //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    //} else {
                      return params.value;
                    //}
                  }
                }
              });
            } else {
              selfObj.columnDefs.push({
                field: fieldHeaderVisibleWidthArray[0],
                headerName: fieldHeaderVisibleWidthArray[1],
                hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellRenderer: function (params) {
                  //if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                  //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                  //} else {
                    return params.value;
                  //}
                }, getQuickFilterText: () => '',
                width: parseInt(fieldHeaderVisibleWidthArray[3])
              });
            //}
          }

        }
      }
    } else {     
      selfObj.columnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        }
      ];
      //selfObj.TableColumns.forEach(ele => {
      //  if (ele.OldOrNew == 'Old') {
      //    switch (ele.ColumnName) {
      //      case 'customertype': {
      selfObj.columnDefs.push({
        field: 'contacttypeName', headerName: 'Customer Type ', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            if (params.value == '1') { return "Active" }
            else if (params.value == '2') { return "In Active" } else {
              return params.value;
            }
          //}
        },
        getQuickFilterText: () => '', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer)
      })
      //        break
      //      } 
      //      case 'accnumber': {
              selfObj.columnDefs.push({
          field: 'accnumber', headerName: 'Acct #', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer)
                , cellRenderer: function (params) {
                  //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
                  //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                  //} else {
                    return params.value;
                  //}
                }, getQuickFilterText: () => ''
        })
      //        break
      //      }
      //      case 'lastname': {
              selfObj.columnDefs.push({
          field: 'lastname', headerName: 'Last Name', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {           
              //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
              //    return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              //  } else {
                  return params.value;
               // }           
            }
          }
        })
      //        break
      //      }
          //  case 'firstname': {
              selfObj.columnDefs.push({
          field: 'firstname', headerName: 'First Name', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {            
              //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
              //    return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              //  } else {
                  return params.value;
                //}             
            }
          }
        })
      
              selfObj.columnDefs.push({
          field: 'middlename', headerName: 'Middle Name', hide: false,
          cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {            
              //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
              //    return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              //  } else {
                  return params.value;
                //}
            
            }
          }
        })
      //        break
      //      }
      //      case 'title': {
              selfObj.columnDefs.push({
                field: 'titleName', headerName: 'Title', hide: false, cellRenderer: function (params) {
                  //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
                  //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                  //} else {
                    return params.value;
                 // }
                }, getQuickFilterText: () => ''
        })
      //        break
      //      }
      //      case 'speciality': {
              selfObj.columnDefs.push({
                field: 'specialityName', headerName: 'Specialty', hide: false, cellRenderer: function (params) {
                  //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
                  //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                  //} else {
                    return params.value;
                 // }
                },getQuickFilterText: () => ''
        })
      //        break
      //      }
      //      case 'DOB': {
              selfObj.columnDefs.push({
                field: 'DOB', headerName: 'DOB', hide: false, cellRenderer: function (params) {
                  //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
                  //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                  //} else {
                    return params.value;
                 // }
                }, getQuickFilterText: () => ''
        
        })
      //        break
      //      }
      //      case 'zip': {
              selfObj.columnDefs.push({
                field: 'zip', headerName: 'ZIP', hide: false, cellRenderer: function (params) {
                  //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
                  //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                  //} else {
                    return params.value;
                 // }
                },getQuickFilterText: () => ''
        })
      //        break
      //      }
      //      case 'accountname': {
              selfObj.columnDefs.push({
                field: 'accountname', headerName: 'Account', hide: false, cellRenderer: function (params) {
                  //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
                  //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                  //} else {
                    return params.value;
                 // }
                },getQuickFilterText: () => ''
        })
      //        break
      //      }
      //      case 'suffix': {
      selfObj.columnDefs.push({
        field: 'suffixName', headerName: 'Suffix', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
          //}
        }, getQuickFilterText: () => '', hide: true })
      //        break
      //      }
      //      case 'sex': {
      selfObj.columnDefs.push({
        field: 'sexName', headerName: 'Sex', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '',hide: true, })
      //        break
      //      }
      //      case 'subspeciality': {
      selfObj.columnDefs.push({
        field: 'subspecialityName', headerName: 'Subspeciality', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
          //}
        }, getQuickFilterText: () => '',hide: true })
      //        break
      //      }
      //      case 'email': {
      selfObj.columnDefs.push({
        field: 'email', headerName: 'Email', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
          //}
        },getQuickFilterText: () => '',hide: true })
      //        break
      //      }
      //      case 'address1': {
      selfObj.columnDefs.push({
        field: 'address1', headerName: 'Address1', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '',hide: true,})
      //        break
      //      }
      //      case 'address2': {
      selfObj.columnDefs.push({
        field: 'address2', headerName: 'Address2', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        },getQuickFilterText: () => '',hide: true,})
      //        break
      //      }
      //      case 'city': {
      selfObj.columnDefs.push({
        field: 'city', headerName: 'City', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
          //}
        }, getQuickFilterText: () => '',hide: true})
      //        break
      //      }
      //      case 'state': {
      selfObj.columnDefs.push({
        field: 'state', headerName: 'State', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '',hide: true })
      //        break
      //      }
      //      case 'ownerid': {
      selfObj.columnDefs.push({
        field: 'OWNER', headerName: 'Owner', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '', hide: false})
      //        break
      //      }           
      //      case 'cell': {
              selfObj.columnDefs.push({
                field: 'cell', headerName: 'Cell', hide: true, cellRenderer: function (params) {
                  if (searcharray.length > 0 && params.value != null) {
                    let x = params.value.split(" ");
                    let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
                    var st = "";
                    for (let i = 0; i < x.length; i++) {
                      if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                        st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                      }
                      else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                        st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                      }
                      else {
                        st += x[i] + " ";
                      }

                    }
                    return st

                  } else {
                    //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
                    //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    //} else {
                      return params.value;
                    //}
                  }
                } })
      //        break
      //      }
      //      case 'office': {
              selfObj.columnDefs.push({
                field: 'office', headerName: 'Office', hide: true, cellRenderer: function (params) {
                  if (searcharray.length > 0 && params.value != null) {
                    let x = params.value.split(" ");
                    let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
                    var st = "";
                    for (let i = 0; i < x.length; i++) {
                      if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                        st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                      }
                      else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                        st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                      }
                      else {
                        st += x[i] + " ";
                      }

                    }
                    return st

                  } else {
                    //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
                    //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                    //} else {
                      return params.value;
                    //}
                  }
                } })
      //        break
      //      }
      //      case 'homeaddress': {
      selfObj.columnDefs.push({
        field: 'homeaddress', headerName: 'Home Address', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '', hide: true, })
      //        break
      //      }
      //      case 'homecity': {
      selfObj.columnDefs.push({
        field: 'homecity', headerName: 'Home City', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
          //}
        }, getQuickFilterText: () => '', hide: true})
      //        break
      //      }
      //      case 'homestate': {
      selfObj.columnDefs.push({
        field: 'homestate', headerName: 'Home State', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '', hide: true, })
      //        break
      //      }
      //      case 'homezip': {
      selfObj.columnDefs.push({
        field: 'homezip', headerName: 'Home Zip', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        },getQuickFilterText: () => '', hide: true,})
      //        break
      //      }
      //      case 'home': {
      selfObj.columnDefs.push({
        field: 'home', headerName: 'Home', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        },getQuickFilterText: () => '', hide: true,})
      //        break
      //      }
      //      case 'college': {
      selfObj.columnDefs.push({
        field: 'collegeName', headerName: 'College', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
          //}
        },getQuickFilterText: () => '', hide: true,})
      //        break
      //      }
      //      case 'collegegrandyear': {
      selfObj.columnDefs.push({
        field: 'collegegrandyear', headerName: 'C.G.year', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '', hide: true, })
      //        break
      //      }
      //      case 'medschool': {
      selfObj.columnDefs.push({
        field: 'medschoolName', headerName: 'Medschool', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '', hide: true, })
      //        break
      //      }
      //      case 'medschoolgrandyear': {
      selfObj.columnDefs.push({
        field: 'medschoolgrandyear', headerName: 'M.G.year', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '', hide: true, })
      //        break
      //      }
      //      case 'residency': {
      selfObj.columnDefs.push({
        field: 'residencyName', headerName: 'Residency', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '', hide: true, })
      //        break
      //      }
      //      case 'residencygrandyear': {
      selfObj.columnDefs.push({
        field: 'residencygrandyear', headerName: 'R.G.year', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        },getQuickFilterText: () => '', hide: true, })
      //        break
      //      }
      //      case 'fellowship': {
      selfObj.columnDefs.push({
        field: 'fellowshipName', headerName: 'Fellowship', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '', hide: true, })
      //        break
      //      }
      //      case 'fellowshipgrandyear': {
      selfObj.columnDefs.push({
        field: 'fellowshipgrandyear', headerName: 'F.G.year', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        },getQuickFilterText: () => '', hide: true,})
      //        break
      //      }
           
      //      case 'created': {
      //        selfObj.columnDefs.push({ field: 'created', headerName: 'Created123', getQuickFilterText: () => '', hide: true, })
      //        break
      //      }
      //      case 'Status': {
      //        selfObj.columnDefs.push({ field: 'Status', headerName: 'Created', getQuickFilterText: () => '',hide: true, })
      //        break
      //      } case 'contacttype': {
      selfObj.columnDefs.push({
        field: 'contacttypeName', headerName: 'contact Type', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '',hide: true, })
      //        break
      //      }
      //      case 'country': {
      selfObj.columnDefs.push({
        field: 'country', headerName: 'Created', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
         // }
        }, getQuickFilterText: () => '',hide: true, })
      //        break
      //      } case 'AlternateNo1': {
      selfObj.columnDefs.push({
        field: 'AlternateNo1', headerName: 'Alternate No1', cellRenderer: function (params) {
          //if ((params.data.lattitude == "" || params.data.lattitude == null)) {
          //  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
          //} else {
            return params.value;
          /*}*/
        },getQuickFilterText: () => '',hide: true, })
      //        break
      //      }

             
      //    }
      //  } else {
      //    selfObj.columnDefs.push({ field: ele.ColumnName, headerName: ele.DisplayAs, getQuickFilterText: () => '', hide: true, width: 200 })
      //  }
        
      //});
      selfObj.columnDefs.push({
        field: "Geo", headerName: 'Geo', hide: false,
      })
      selfObj.columnDefs.push({
          field: "notes", headerName: 'Notes', hide: false, cellRenderer: 'notesrenderer'})
      selfObj.columnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: isDeActive ? 'editactivatecv' : 'editdeletecv', hide: false, pinned: 'right' })
     
    }
  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  autoSizeAll() {
    var allColumnIds = [];
    this.gridOptions.api.sizeColumnsToFit();
  }

  txtPageSizeChanged(pageSize) {
    this.currentPage = 1;
    this.pageSize = pageSize;
    this.GetContactList(this.term, $("#userids").val(), this.active_deactiveStatus);
    this.saveConfiguration();
  }

  columnVisible(event) {   
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();
    if (this.pageSize == "")
      config.maxlimit = 100;
    else
      config.maxlimit = Number(this.pageSize);
    config.columnsselections = "";
    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }
    if (config.columnsselections.length > 0)
    config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    config.configureduserid = this.currentuser.id;
    config.resourceid = 5;
    config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;
    config.userid = "";
    for (let i = 0; i < this.applicationComponent.selectedItems.length; i++) {
      config.userid += this.applicationComponent.selectedItems[i].id + ",";
    }
    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.isShowAccountInfo = false;
        this.SelectedContact = event.api.getSelectedNodes()[0].data.id;
        if (this.condashcmp != undefined) {
          this.condashcmp.GetContactInfo(event.api.getSelectedNodes()[0].data.id);
          this.contactid = event.api.getSelectedNodes()[0].data.id;
        }
      }
    }
  }
  rowClickAgGrid(rowEvent) {


    if (this.gridApi.getSelectedRows() != undefined && this.gridApi.getSelectedRows()?.length > 0) {
      $('#emailIds').val(this.gridApi.getSelectedRows().map(x => x.id).join(','));
      this.ContactIds = this.gridApi.getSelectedRows().map(x => x.id).join(',');
      $('#tasklistselectedtyle').val('Contact');
    } else {
      $('#emailIds').val("");
      this.ContactIds = "";
      $('#tasklistselectedtyle').val('');
    }
    if (this.applicationComponent.googlemapshow) {
      if (this.addressMapComp != undefined) {
        this.addressMapComp.GetLocations(this.ContactIds, 'Contacts');
      }
    }


    //  if ((this.Ids).indexOf(rowEvent.data.id) > -1) {
    //    var index = (this.Ids).indexOf(rowEvent.data.id);
    //    (this.Ids).splice(index, 1);
    //    var string = (this.Ids).toString();
    //    $('#emailIds').val(string);
    //    if ($('#emailIds').val() != '') { $('#tasklistselectedtyle').val('Contact'); } else {
    //      $('#tasklistselectedtyle').val(' ');
    //    }
    //    this.ContactIds = string;
    //  } else {
    //    if (rowEvent.data.permission == "view") {
    //      this.applicationComponent.isAccessDenied = true;
    //    } else {
    //      this.applicationComponent.isAccessDenied = false;
    //    }
    //    this.Ids.push(rowEvent.data.id)
    //    var string = (this.Ids).toString();      
    //    $('#emailIds').val(string);
    //    $('#tasklistselectedtyle').val('Contact');       
    //    this.ContactIds = string;
    //    this.contactid = rowEvent.data.id;
    //    this.accountid = rowEvent.data.accountid;
        
    //  }
    //if (this.addressMapComp != undefined) {
    //  this.addressMapComp.GetLocations(this.ContactIds, 'Contacts');
    //}
    
  }

  MapItEventListner() {
    if (this.applicationComponent.isMapItEventRaised) {
      this.IsEditContact = false;
    }
    else {
      this.applicationComponent.googlemapshow = false;
    }
  }
  cellDoubleClicked(rowEvent) {
    this.isSharing = false;
    this.isShowContactInfo = true;
    this.contactid = rowEvent.data.id;
    this.SelectedContact = this.contactid;
    this.gridOptions.rowSelection = 'single';
    this.accountid = rowEvent.data.accountid;
    this.EnableSearch = false;
  }

  cellClicked(rowEvent) {
    
  }
  openNotesDialogAg(rowdata) {  
      if (rowdata.permission == "view") {
        this.applicationComponent.isAccessDenied = true;
      }
      else {      
        this.contactid = rowdata.id;
        this.notesshowDialog = !this.notesshowDialog;
        this.NotesDialogCss();
      }
  }
  oneventReceived(msg) {
  this.IsEditContact = false;
    this.toastr.success("Contact  saved successfully.", "Contact Saved", { timeOut: 1000 });
  }

  onCloseDialogEventReceived(msg) {
    this.notecomp.statusIds = false;
  }
  editRowAg(rowdata) {
    if (rowdata.permission == "view")
    {
      this.applicationComponent.isAccessDenied = true;
      this.dialogsize();
    }
    else {
      this.contactid = rowdata.id;
      this.accountid = rowdata.accountid;
      this.showAddEditContact = true;
      this.showAddEditContactCss();
     
    }
  }
  ReceiveDeleteevent(msg) {
    if (msg == "Ok") {
      if (this.deleteRowData.permission == "view") {
        this.applicationComponent.isAccessDenied = true;
        this.dialogsize();
      }
      else {
        this.contactid = this.deleteRowData.id;
        let isdeletedstatustext = (this.deleteRowData.isdeleted == 1) ? "Re-Activate" : "De-Activate";
        //var c = confirm("Are you sure you want to " + isdeletedstatustext + " this Contact??");
        //if (c == true) {
          let isdeletedstatus = (this.deleteRowData.isdeleted == 1) ? 0 : 1;
          this.customerService.Deactivatecontact(this.deleteRowData.id, isdeletedstatus).then((user) => {
            this.active_deactiveStatus = this.activeItems ? 0 : 1;
            this.GetContactList(this.term, $("#userids").val(),this.active_deactiveStatus);
            this.DeleteConfirmationPopup = false;
        });
        
        //}
      }
    } else {
      this.DeleteConfirmationPopup = false;
    }
  }
  deleteRowAg(rowdata) {

    if (this.currentuser.userrole == 1 && rowdata.ownerid == this.currentuser.id) {
      this.deleteRowData = rowdata;
      this.contactStatus = rowdata.isdeleted;
      this.DeleteConfirmationPopup = true;
      setTimeout(function () {
        $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
      }, 4);
    } else {
      alert("Please Contact System Admin / Owner of the Account to delete");
    }
  }
  ExpandPDF(val) {
    this.notesshowDialog = true;
    this.QuickViewDialogCss(val);
    this.DocExpandView = val;
  }
  QuickViewDialogCss(val) {
    if (val == true) { //expand
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    } else {
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    }
  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 43%;padding: 0;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }
  dialogsize() {
    setTimeout(function () {
      $('.dialog').attr('style', ' width: 32%;padding - bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min - height: 23%;overflow: visible;height: 24%;');

    }, 4)
  }
  convertContactAg(rowdata) {
    if (rowdata.permission == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogsize();
    }
    else {
      this.contactid = rowdata.id;
      this.leadstatus = 0;
      this.customerService.GetContactById(rowdata.id).then(result => {
        this.contact = result;
        this.leadstatus = this.contact.converttolead;
        if (this.leadstatus == 0) {
          this.applicationComponent.loaddialog('convertcontacttolead', 'Convert Lead', 'Convert this Contact to a Lead?', false, false, true, true, this.contactid);
        }
        else {
          this.applicationComponent.loaddialog('convertcontacttolead', 'Convert Lead', 'Contact is an Open Lead', false, false, true, true, this.contactid);
        } 
      });
           
    }
  }
  ngOnInit() {
    this.applicationComponent.googlemapshow = false;
    this.applicationComponent.pageViewed = "";
    this.applicationComponent.selectedbuttonText = "Contacts";
    $(".mainCheckMark").removeClass("activeColor");
    $(".coustomButton").removeClass("mainactiveButton");
    $("#contact").addClass("mainactiveButton");
    this.initializeAgGrid("Yes");   
    this.isViewInitialized = true;

  }

  sharingAg(rowdata) {
    this.isSharing = true;

    let selfObj = this;
    $(setTimeout(function () {
      selfObj.ownrshipComp.loadAccountInformation(rowdata.accountid, 0);
    }, 50));
  }
  
  onCloseEventReceived(msg) {
    if (msg == 'Close') {
      this.isSharing = false;
    } else if (msg == 'Save') {
      //this.getContactList();
      this.GetContactList(this.term, $("#userids").val(), this.active_deactiveStatus);
    }
    
  }
  GetAllaccesusersListbyuser() {
    
    this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
      this.DisplayrowsList = Rows;
    });
  }

  GetContactIds() {
    this.customerService.GetSpecialityList("ContactIds", this.currentuser.id).then((Acids) => {
      this.contactIdArr = Acids;
    });}
  userListOutParamsBinding(outProps) {
    this.finalitems = outProps.finalitems;
    this.Contactitems = outProps.items;
    this.ContactitemCount = outProps.itemsCount;
    this.applicationComponent.selectedItems = outProps.selectedItems;
  }
 
  AssignStartAndEndDate(event) {
    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  GetContactList(term, userid, active_deactiveStatus?: number) {   
    this.createColumnDefs(this.colSelections, this.applicationComponent.searchstring, !this.activeItems);   
    this.opprtunityservice.GetAllContactsbySearchPaginationNew(this.currentuser.id, term, userid, this.subtype, active_deactiveStatus,
      (this.calanderonoff) ? '2018-01-01' : '2018-01-01', this.endDateVal, this.Contactvales).then((contact) => {  
        this.allContacts = this.OriginalallContacts = contact;
      
        this.allContacts = this.OriginalallContacts.filter(x => x.STATUS == this.selectedStatus);

       if (localStorage.getItem('RhxContactVal') != null && localStorage.getItem('RhxContactVal') != undefined) {
        this.isShowContactInfo = true;
        this.contactid = Number(localStorage.getItem('RhxContactVal'));
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.gridOptions.api.forEachNode((node) => {
            if (node.data.id == selfObj.contactid) {
              selfObj.gridOptions.api.selectNode(node, true);
              selfObj.gridOptions.rowSelection = 'single';
            }
          });
        }, 300));       
          
      } else {   
          this.createColumnDefs(this.colSelections, this.applicationComponent.searchstring, !this.activeItems); 
       }
      
    });
  }
 
  onDropdownClose(items: any) {
  }
  rowDoubleClick(rowEvent) {
    if (rowEvent.row.item.permission == "view") {
      this.applicationComponent.isAccessDenied = true;
    }
    else {
      this.router.navigateByUrl(`/application/contactsdashboard/${rowEvent.row.item.id}/${rowEvent.row.item.permission}`);
    }
  }

  covertContact(rowEvent) {
    this.contactid = rowEvent.id;
    this.showDialog = !this.showDialog
  }
 
  Edit(event) {
    this.router.navigateByUrl(`/application/contactsdashboard/${event.id}/edit`);
  }

  Delete(rowEvent) {
    this.contactid = rowEvent.id;
    let isdeletedstatustext = (rowEvent.isdeleted == 1) ? "Re-Activate" : "De-Activate";
    var c = confirm("Are you sure you want to " + isdeletedstatustext + " this Contact??");
    if (c == true) {
      let isdeletedstatus = (rowEvent.isdeleted == 1) ? 0 : 1;
      this.customerService.Deactivatecontact(rowEvent.id, isdeletedstatus).then((user) => {
        this.active_deactiveStatus = this.activeItems ? 0 : 1;
        this.GetContactList(this.term, $("#userids").val(), this.active_deactiveStatus);
      });
    }
  }
  getContactList() {
    this.activeItems = this.activeItems ? false : true;
    this.active_deactiveStatus = this.activeItems ? 0 : 1;

    this.GetContactList(this.term, $("#userids").val(), this.active_deactiveStatus);
  }
  Resetcontacts() {
    this.contact.contacttype = null;
    this.contact.suffix = null;
    this.contact.sex = null;
    this.contact.title = null;
    this.contact.speciality = null;
    this.contact.subspeciality = null;
    this.contact.college = 0;
    this.contact.collegegrandyear = null;
    this.contact.medschool = 0;
    this.contact.medschoolgrandyear = null;
    this.contact.residency = 0;
    this.contact.residencygrandyear = null;
    this.contact.fellowship = 0;
    this.contact.fellowshipgrandyear = null;
    this.contact.ownerid = null;
    this.contact.practiceid = 0;
  }
  getyears() {
    var range = [];
    var year = new Date().getFullYear();
    for (var i = 0; i < 7; i++) {
      range.push({
        label: year + i,
        value: parseInt(String(year + i).slice(2, 4))
      });
    }
    this.Years = range;
  }
  SaveContact() {
    this.accountsService.addcontact(this.contact).then(result => {
      this.customerService.GetContactById(result.id).then(result => {
        this.contact = result;
        this.customerService.GetByAccountId(this.contact.accountid).then(result => {
          this.account = result;
        });

      });
      this.customerService.GetByAccountId(this.contact.accountid).then(result => {
        this.account = result;
        if (this.account.customertypeid == 1) {
          document.getElementById('logoid').style.display = 'block';
        } else {
          document.getElementById('logoid').style.display = 'none';
        }
      });
    });
  }

  openDisplaynotes(params) {
    $('#closeDetailsDialog').click();
    this.NotesDec = true;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;
    let selfObj = this;
    $(setTimeout(function () {     
      if (selfObj.isModal) {       
        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');
        $('.fr-wrapper').css('height', '68%');
        $('.fr-wrapper').css('overflow', 'auto');
        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '81%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '14%');
        } else {
          $('#txtLongNoteDesc').css('height', '96%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      } else {
        var gridOffset = $("#notesGrid").offset();       
        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');
        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '53%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '33%');
        } else {
          $('#txtLongNoteDesc').css('height', '89%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      } 
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));

  }
  editNotesDialogSize() {
    $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');
    $('#showDetailsDialog').show();
  }
  closeDetailsDialog() {
    this.NotesDec = false;
  }
  Search(val) {
    if (val == true) {
      this.applicationComponent.Searchdiv = true;
    }
    else {
      this.applicationComponent.Searchdiv = false;
    }
  }
  showAddEditContactCss() {
    setTimeout(function () {
      $('#addEditContact .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  ContactReceiveEvent(msg) {
    this.showAddEditContact = false;
    this.GetContactList(this.term, $("#userids").val(), this.active_deactiveStatus);
  }
  UserlistDataReceived(outProps: OutputPropsUserList) {
 
    if (outProps.enableSearch) { this.EnableSearch = true; }
    if (outProps.secondDrpValue == "") { this.allContacts = []; } else {
      this.calanderonoff = outProps.calanderonoff;
      this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
      this.Contactvales = outProps.secondDrpValue == undefined ? "0" : outProps.secondDrpValue;

      if (outProps.thirdDrpValue != "0" && outProps.thirdDrpValue != undefined && outProps.thirdDrpValue != "") {
      
        this.allContacts = this.OriginalallContacts.filter(x => x.contacttypeName == outProps.thirdDrpValue);
      } else if (outProps.fourthDrpValue != "0" && outProps.fourthDrpValue != undefined && outProps.fourthDrpValue != "Select Last Activity" && outProps.fourthDrpValue != "") {
        this.opprtunityservice.GetAlllastActivityContacts($("#userids").val(), outProps.fourthDrpValue).then((accounts) => {
          this.allContacts = accounts;
        });

      } else {
        if (outProps.finalitems != "") {
          $("#userids").val(outProps.finalitems);
          this.gridApi.paginationSetPageSize(Number(this.pageSize));
          if (!outProps.pageSizeChanged) {       
            if (outProps.secondDrpValue == '1') {
              this.selectedStatus = 309;
            } else if (outProps.secondDrpValue == '2') {
              this.selectedStatus = 310;
            }
            
            this.GetContactList(this.term, $("#userids").val(), this.active_deactiveStatus);
          }
        } else {
          this.allContacts = this.OriginalallContacts = [];
          $("#userids").val('');
        }
      }
    }
    if (outProps.fifthDrpValue == "No Shared Contacts") {
      this.isSharedConatcts = false;
    } else {
      this.isSharedConatcts = true;
    }
    }
  CloseaccountIds() { this.isStatusidopen = false; }
    
  onContactMessageReceived(msg) {
    if (msg == "StatusId") { this.isStatusidopen = true }
    else if (msg == "QuickView") { this.CloseContactDiv(); }
    else {
      this.isShowAccountInfo = true;
    }
  }
  expandContactView(value) {     
    if (value === 'Collapse') {
      this.isContactViewOpen = false;
      if (this.applicationComponent.googlemapshow) {
        this.isGooglemapExpandOpen = true;
      } else {
        this.condashcmp.SixBoxExpand = false;
        this.condashcmp.tab = 'QuickView';
      }      
    }
    else {
      this.isContactViewOpen = true;
      if (this.applicationComponent.googlemapshow) {
        this.isGooglemapExpandOpen = false;
      } else {
        this.condashcmp.SixBoxExpand = false;
        this.condashcmp.tab = 'QuickView';

        let selfObj = this;
        $(setTimeout(function () {
          selfObj.gridOptions.api.forEachNode((node) => {
            if (node.data.id == selfObj.contactid) {
              selfObj.gridOptions.api.selectNode(node, true);
              selfObj.gridOptions.rowSelection = 'single';
            }
          });
        }, 500));
      }
    }
  }
  ContactIdsReceived(contactids) {
    this.allContacts = this.allContacts.filter(x => this.ContactIds.split(',').includes(x.id.toString()));   
  }
  CloseContactDiv() {
    this.isShowContactInfo = false;
    this.isContactViewOpen = true;
    this.applicationComponent.activateMainButtons('contact'); 
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
    this.gridOptions.quickFilterText = (document.getElementById('filter-text-box') as HTMLInputElement).value;
    this.createColumnDefs(this.colSelections, this.applicationComponent.searchstring, !this.activeItems);
    this.gridApi.redrawRows();  
  }
  ClearSearch() {
    this.gridOptions.quickFilterText = "";
    this.gridApi.setQuickFilter('');
    this.value = "";
    this.EnableSearch = false;
    this.createColumnDefs(this.colSelections, this.applicationComponent.searchstring, !this.activeItems);
    this.gridApi.redrawRows(); 
  }
}
