
<div  style="width: 100%; height: 100%;margin-top:0%; padding-top: 0" class="scroll-c">
  <div *ngIf="!fieldsSelector"  style="background-color:white;height:100%;padding:0%" [ngClass]="valueSelector?'col-xs-4':'col-xs-12'">
    <div *ngIf="!valueSelector" style="text-align: left; height: 6%;color: white;border-bottom:1px solid white;font-size:18px;margin-top:1%" class="col-xs-12">
      <div class="col-xs-1" style="padding:0;width:3%">
        <img src="../../assets/img/gallery-Create Query.png" (click)="addNewQuery($event)" style="height: 32px;">
      </div>
      <div class="col-xs-1" style="padding:0 0 0 0%;">
        <span style="color:black;">My Queries</span>
      </div>
      <div class="col-xs-2" style=" padding: 0 0 0 0%;height:5%;">
        <span style="color: red;text-decoration:underline;background-color: white;"
              *ngIf="applicationComponent.searchoffon" (click)="openCloseQueries()">
          CLOSE ALL QUERIES
        </span>
      </div>

    </div>

    <div class="col-md-12 scroll-c" style="height: 80%; overflow: auto; border-bottom: 1px solid lightgray;">
      <ag-grid-angular style="width: 100%; height:100% !important;" id="gridheight" class="ag-theme-balham"
                       [gridOptions]="gridOptions"
                       [columnDefs]="columnDefs"
                       [rowData]="queryList"
                       [context]="context"
                       [sideBar]="sideBar"
                       [rowSelection]="'multiple'"
                       (selectionChanged)="onSelectionChanged($event)"
                       [frameworkComponents]="frameworkComponents"
                       (gridReady)="onAgGridReady($event)">
      </ag-grid-angular>

    </div>
    <div class="col-md-12" style="height:10%;padding:0 0 0 4%">
      <div class="col-md-12" style="margin-top:1%;height:100%">
        <div class="col-md-8" style="padding:0;float:right;">
          <div class="col-md-6" style="padding: 0">
            <button class="btn" style="" (click)="btnCancelFieldsSelectorClick($event)">Cancel</button>
          </div>
          <div class="col-md-6" style="padding:0 0 0 3%">
            <button class="btn saveButton" id="btnRunQry" (click)="btnAdvanvedRunQueryClick($event)" [disabled]="queryList.length <= 0"
                    style="padding-left: 2px; background-color: rgb(162, 228, 119); font-weight: 700; height: 36px;">
              Run Query
            </button>
          </div>
        </div>
      </div>

    </div>

  </div>
  <div *ngIf="fieldsSelector" style="width: 100%; height: 94%;margin-top:0%">
    <div style="padding: 0% 0% 0% 2%;height: 25px; color: white; width: 100%; border-bottom: 1px solid white; font-size: 18px; ">
      <label style="font-size: 14px;color: #5aa251;">Select Account or Contact to Select Fields For Query</label>
    </div>

    <div class="col-xs-12" style="height: 84%;border-bottom: 1px solid #ddd;background-color:#F4F4F4">
      <div class="col-xs-6" style=" overflow: scroll; height: 100%;">
        <div style="width: 100%; height: 2%; background-color: rgb(186,220,153)">

        </div>
        <div style="width: 100%;margin-top: 2%">
          <input id="rblAccount" (click)="lblAccountHideShow($event); rblTblSelected($event)" type="checkbox" name="tblSelect" value="account" />&nbsp;
          <label style="cursor: pointer">ACCOUNT</label>
        </div>
        <div *ngIf="accountShow" style="overflow: auto; height: auto; width: 100%;margin:0;background-color:white">
          <div class="col-xs-12" *ngFor="let col of accountColumns | searchboxfilter:fieldsSelectorSearchFilter; let i = index" [ngStyle]="{'display':(col.displayName ==='duplicateCol') ? 'none' : 'flex'}">
            <input type="checkbox" id="chkAccountCol{{i}}" [(ngModel)]="col.isSelected" (click)="selectedColList('Account',col.displayName,i)" />&nbsp;
            <label id="lblAccountCol{{i}}" style="white-space: nowrap">{{col.displayName}}</label>
          </div>
        </div>
      </div>
      <div class="col-xs-6" style=" overflow: scroll; height: 100%;">
        <div style="width: 100%; height: 2%; background-color: rgb(186,220,153)">

        </div>
        <div style="width: 100%;margin-top: 2%">
          <input (click)="lblContactHideShow($event);rblTblSelected($event)" id="rblContact" type="checkbox" name="tblSelect" value="contact" />&nbsp;
          <label style="cursor: pointer">CONTACT</label>
        </div>
        <div *ngIf="contactShow" style="overflow: auto; height: auto; width: 100%;margin:0;background-color:white;" class="row">
          <div class="col-xs-12" *ngFor="let col of contactColumns | searchboxfilter:fieldsSelectorSearchFilter; let i = index" [ngStyle]="{'display':(col.displayName ==='duplicateCol') ? 'none' : 'flex'}">
            <input type="checkbox" id="chkContactCol{{i}}" [(ngModel)]="col.isSelected" (click)="selectedColList('Contact',col.displayName,i)" />&nbsp;<label id="lblContactCol{{i}}" style="white-space: nowrap">{{col.displayName}}</label>
          </div>
        </div>
      </div>


    </div>
    <div class="col-md-12" style="margin-top:1%;height:6.5%;background-color:#F4F4F4">
      <div class="col-md-4 col-sm-4 col-xs-4" style="padding-right: 0">
        <button class="btn" style="background-color:#dddddd" (click)="btnCancelFieldsSelectorClick($event)">Cancel</button>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4" style="padding-left: 0"></div>
      <div class="col-md-4 col-sm-4 col-xs-4" style="padding-left: 0">
        <button class="btn saveButton" (click)="btnAddFieldsSelectorClick($event)" [disabled]="selectedColumnList.length == 0">Add</button>
      </div>
    </div>
  </div>

  <div *ngIf="valueSelector" class="col-xs-8" style="padding: 0%;height: 80%;">
    <div class="col-xs-5" style="padding: 0px 0 0 0; border: 1px solid gray;height:100%;">
      <div class="col-md-12" style="height: 1%; background-color: orangered; color: orangered">
        ..
      </div>
      <div class="col-md-12">
        <label style="padding-left:3%">{{'Edit Query'}}</label>
      </div>
     

      <div style="text-align: left; padding: 5%; width: 100%; border-bottom: 1px solid white;margin-top:3%">
        <div class="col-md-12" style="padding: 0px;">
          <input type="text" style="width: 100%;border-radius: 5px; resize: none; color: black; background-color: rgb(250, 253, 215); " id="txtQryName" placeholder="Enter Query Name" maxlength="40" [(ngModel)]="queryName" />
        </div>

      </div>
      <div style="text-align: left; padding: 5%; width: 100%; border-bottom: 1px solid white; margin-top: 1%">
        <label class="col-md-4" style="padding: 0px;">
          Created By:
        </label>
        <label class="col-md-6" style="padding: 0px;">
          {{this.currentuser.firstname}} {{this.currentuser.lastname}}
        </label>

      </div>
      <div style="text-align: left;padding: 5%;width: 100%; border-bottom: 1px solid white;">
        <label class="col-md-4" style="padding: 0px;">
          Date Created:
        </label>
        <label class="col-md-6" style="padding: 0px;">
          {{currentdatetime | date:'MMMM-dd-yyyy'}}
        </label>

      </div>
      <div style="text-align: left;padding: 5%;width: 100%; border-bottom: 1px solid white;">
        <label class="col-md-4" style="padding: 0px;">
          Last Edited:
        </label>
        <label class="col-md-6" style="padding: 0px;">
          {{currentdatetime | date:'MMMM-dd-yyyy'}}
        </label>

      </div>
    </div>
    <div class="col-xs-7" style="height: 100%;overflow: auto">
      <div class="col-md-1" (click)="btnCancelValueSelectorClick($event)">
        <img src="../../assets/img/new field icon.png" style="height:29px;" />
      </div>
      <div *ngFor="let col of accountColumns; let i = index" id="divValueSelectorAccount{{i}}">
        <div *ngIf="col.distinctValues.length > 0" id="{{i + 1+1}}">
          <div *ngIf="col.displayName != 'duplicateCol'" class="col-xs-12" style="padding: 0px 0 0 0;">
            <div class="col-xs-9" style="padding: 0px 0 0 0; width: 83%">
              <label>  Account - {{col.displayName}}  </label>
            </div>
            <div class="col-xs-2" style="padding: 0px 0 0 0;">
              <select (change)='SelectionChanged($event.target.value)' style="color: dodgerblue" class="form-control" [(ngModel)]="andactiveval">
                <option [value]='"AND"'>AND</option>
                <option [value]='"OR"'>OR</option>
              </select>
            </div>
          </div>
          <div class="form-control" style="display: flex; width: 100%;height: 46px; background-color: lavender">
            <input type="text" style="display:none" id="selRHSAccountOperator{{i}}" value="{{andactiveval}}" />
            <div style="width: 22%">
              <select class="form-control" style="border-radius: 3px;padding:0;" id="selLHSAccountSelector{{i}}" [(ngModel)]="col.operator">
                <option *ngFor="let val of lhsSelectors">{{val}}</option>
              </select>
            </div>
            <div style="width: 62%">
              <select class="form-control" style="width: 100%; border-radius: 3px;padding:0;" id="selRHSAccountSelector{{i}}" [(ngModel)]="col.selectedVal"
                      [disabled]="col.operator == 'Is Not Empty' || col.operator == 'Is Empty'" *ngIf="col.displayName !== 'Status ID' && col.columnName !=='accountid123'">
                <option *ngFor="let val of col.distinctValues" value="{{val.id}}" [disabled]="val.id == null">{{val.name}}</option>
              </select>
              <select class="form-control" style="width: 100%; border-radius: 3px;padding:0;" id="selRHSAccountSelector{{i}}" [(ngModel)]="col.selectedVal" [disabled]="col.operator == 'Is Not Empty' || col.operator == 'Is Empty'" *ngIf="(col.displayName == 'Status ID' || col.columnName=='accountid123') && (col.selectedVal !='' && col.selectedVal !=' ' ) ">
                <optgroup *ngFor='let Group of col.distinctValues' label="{{Group.GroupName}}">
                  <option *ngFor='let items of Group.GroupItems' [value]="items.Id">{{items.StatusIDName}}</option>
                </optgroup>
              </select>
            </div>
            <div style="width: 8%; text-align: center">
              <button class="glyphicon glyphicon-plus-sign" (click)="btnAddNew($event, col.columnName, i)" style="margin-top:22%"></button>
            </div>
            <div style="width: 8%; text-align: center">
              <button class="glyphicon glyphicon-minus-sign" (click)="btnAccRemove(i)" style="margin-top:22%"></button>
            </div>
            <div style="width: 8%; text-align: center">
              <button class="glyphicon glyphicon-trash" id="btnRemoveAccount{{i}}" (click)="btnRemoveValueSelectorClick($event)" style="margin-top:22%"></button>
            </div>
          </div>
        </div>
      </div>

      <div *ngFor="let col of contactColumns; let i = index" id="divValueSelectorContact{{i}}">
        <div *ngIf="col.distinctValues.length > 0" id="{{i + 1+1}}">
          <div style="width: 100%;padding:0;" class="col-xs-12">

            <div class="col-xs-9" style="padding: 0px 0 0 0;width:83%">
              <label *ngIf="col.displayName != 'duplicateCol'"> Contact - {{col.displayName}}</label>
            </div>
            <div *ngIf="col.displayName != 'duplicateCol'" class="col-xs-2" style="padding: 0px 0 0 0;">
              <select (change)='SelectionChanged($event.target.value)' [(ngModel)]="andactiveval" style="color: dodgerblue" class="form-control">
                <option [value]='"AND"'>AND</option>
                <option [value]='"OR"'>OR</option>
              </select>
            </div>
          </div>
          <div class="form-control" style="display: flex; width: 100%;height:46px;background-color: lavender">
            <input type="text" style="display:none" id="selRHSContactOperator{{i}}" value="{{andactiveval}}" />
            <div style="width: 22%">
              <select class="form-control" style="border-radius: 3px;padding:0;" id="selLHSContactSelector{{i}}" [(ngModel)]="col.operator">
                <option *ngFor="let val of lhsSelectors">{{val}}</option>
              </select>
            </div>
            <div style="width: 62%">
              <select class="form-control" style="width: 100%; border-radius: 3px;padding:0;" id="selRHSContactSelector{{i}}" [disabled]="col.operator == 'Is Not Empty' || col.operator == 'Is Empty'" [(ngModel)]="col.selectedVal">
                <option *ngFor="let val of col.distinctValues" value="{{val.id}}" [disabled]="val.id == null">{{val.name}}</option>
              </select>
            </div>
            <div style="width: 8%; text-align: center">
              <button class="glyphicon glyphicon-plus-sign" (click)="btnAddContactField($event, col.columnName, i)" style="margin-top:22%"></button>
            </div>
            <div style="width: 8%; text-align: center">
              <button class="glyphicon glyphicon-minus-sign" (click)="btnContactRemove(i)" style="margin-top:22%"></button>
            </div>
            <div style="width: 8%; text-align: center">
              <button class="glyphicon glyphicon-trash" id="btnRemoveContact{{i}}" (click)="btnRemoveValueSelectorClick($event)" style="margin-top:22%"></button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-xs-5" style="margin-top:1%;height:6.5%">
    </div>
    <div class="col-xs-7" style="margin-top: 1%; height: 6.5%; padding: 0%">
      <div class="col-md-4">
        <button class="btn" style="background-color:#dddddd" (click)="btnCancelFieldsSelectorClick($event)">Cancel</button>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4" style="padding-left: 0">
        <button class="btn saveButton" (click)="btnSaveValueSelectorClick($event)">Save</button>
      </div>
    </div>
  </div>

  <div *ngIf="quickSearch" style="width: 100%; height: 96%">
    <div class="col-md-12" style="padding: 0px 0 0 0;">

      <div style="text-align: left; height: 7%;color: white;padding: 2%;background-color: #2A3740 !important;width: 100%;border-bottom:1px solid white;font-size:18px;">
        <label style="font-size: 20px;color: #77C043;margin-left: 38%;">Quick Search</label>
      </div>
    </div>

    <div class="col-md-12" style="height: 78%; overflow: auto;border-bottom: 1px solid #ddd;background-color:#F4F4F4">
      <div style="width: 100%;margin-top:2%">
        <input id="rblAccount" (click)="lblAccountHideShow($event); rblTblSelected($event)" type="checkbox" name="tblSelect" value="account" />&nbsp;
        <label style="cursor: pointer">ACCOUNT</label>
      </div>
      <div *ngIf="accountShow" style="overflow: auto; height: auto; width: 100%;margin:0;background-color:white" class="row">
        <div class="col-xs-12" *ngFor="let col of accountColumns | searchboxfilter:fieldsSelectorSearchFilter; let i = index" style="display: flex">
          <div class="col-xs-5" style="padding:0;">
            <label id="lblAccountCol{{i}}" style="white-space: nowrap">{{col.displayName}}</label>
          </div>
          <div class="col-xs-7" style="text-align: left;padding:0;"><input type="text" id="AcctxtQuickSearchCol{{i}}" style="width:100%; height: 80%; border-radius: 3px;color:red" /></div>
        </div>
      </div>
      <div style="width: 100%">
        <input (click)="lblContactHideShow($event);rblTblSelected($event)" id="rblContact" type="checkbox" name="tblSelect" value="contact" />&nbsp;
        <label style="cursor: pointer">CONTACT</label>
      </div>
      <div *ngIf="contactShow" style="overflow: auto; height: auto; width: 100%;margin:0;background-color:white;" class="row">
        <div class="col-xs-12" *ngFor="let col of contactColumns | searchboxfilter:fieldsSelectorSearchFilter; let i = index" style="display: flex">
          <div *ngIf="col.displayName!='duplicateCol'" class="col-xs-5" style="padding:0;"><label id="lblAccountCol{{i}}" style="white-space: nowrap">{{col.displayName}}</label></div>
          <div *ngIf="col.displayName!='duplicateCol'" class="col-xs-7" style="text-align: left;padding:0;"><input type="text" id="ContxtQuickSearchCol{{i}}" style="width:100%; height: 80%; border-radius: 3px;color:red" /></div>
        </div>
      </div>
    </div>

    <div class="col-md-12" style="margin-top:1%;height:6.5%">
      <div class="col-md-4 col-sm-4 col-xs-4" style="padding-right: 0">
        <button class="btn" style="background-color:#dddddd" (click)="btnCancelFieldsSelectorClick($event)">Cancel</button>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4" style="padding-left: 0"></div>
      <div class="col-md-4 col-sm-4 col-xs-4" style="padding-left: 0">
        <button class="btn saveButton" (click)="btnQuickSearchClick($event)">Run Query</button>

      </div>
    </div>

  </div>
</div>


  <app-dialog [(visible)]="showWarningDialog" id="Ban" [closable]="false">
    <button aria-label="Close" class="dialog__close-btn" (click)="showWarningDialogClose()" style="background-color:transparent;float:right">X</button>
    <div style="padding:0;text-align:center;margin-top: 6%;font-size: 17px;margin-top:5%">
      <label style="color:red">Warning</label><br />
      <span>Changes have been made to this Query.</span><br />
      <span>select Save to keep changes</span><br />
      <span>Select Cancel to dischard Changes</span><br />
    </div>
  </app-dialog>


  <app-dialog [(visible)]="DeleteConfirmationDialog" [closable]="false" id="DeleteConfirmationDialog">
    <app-delete-popup [status]="0" [title]="'QUERY BUILDER'"
                      [description]="RunStatus=='Open'?'Close Open Query Before Deleting':'Delete the Selected query?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteevent($event)">
    </app-delete-popup>

  </app-dialog>

  <app-dialog [(visible)]="EditConfirmationDialog" [closable]="false" id="EditConfirmationDialog">
    <div class="col-xs-12">
      <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
      <span style="font-size:15px;">Query Builder</span><br />
      <div style="padding:0;margin-top:1%" class="col-xs-12">
        <div class="col-xs-8" style="padding:0">
          <span style="font-size:15px;"> Close Open Query Before Edit</span><br />
        </div>
        <div class="col-xs-4" style="padding:0;">
          <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%" (click)="EditConfirmation()">OK</button>

        </div>
      </div>

    </div>

  </app-dialog>
  <app-dialog [(visible)]="NoRowDialog" [closable]="false" id="NoRowDialog">
    <div class="col-xs-12">
      <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
      <span style="font-size: 15px; font-weight: 600">Query Builder</span><br />
      <div style="padding: 0; margin-top: 1%; font-weight: 600" class="col-xs-12">
        <div class="col-xs-10" style="padding:0">
          <span style="font-size:15px;">Select a Row to Run a Query</span><br />
        </div>
        <div class="col-xs-2" style="padding:0;">
          <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%" (click)="NoRowDialog=false;">OK</button>

        </div>
      </div>

    </div>

  </app-dialog>
  <app-dialog [(visible)]="closeOpenDialog" *ngIf="closeOpenDialog" id="closeOpenDialog" [closable]="false">
    <div class="col-xs-12" style="padding:5%">
      <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
      <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
        <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">OPEN QUERIES</div><br />
        <div class="col-xs-12" style="padding: 0; padding-top: 3%;">Close all Open Queries?</div>
      </div>
      <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
        <div class="col-xs-7" style="padding: 0;">

        </div>
        <div class="col-xs-5" style="padding:0;">
          <div class="col-xs-6" style="padding:0">
            <button class="btn" (click)="closeopenqueries();" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%">
              OK
            </button>
          </div>

          <div class="col-xs-6" style="padding:0">
            <button class="btn" (click)="closeOpenDialog=false;" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1 ">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </app-dialog>
