<input type="text" id="tasklistselectedtyle" style="display:none;" />
<input type="text" id="emailIds" style="display:none;" />
<div class="container" style="height:100%;padding:0;">
  <div [hidden]="FromSelector" style="height:100%">
    <div class="col-xs-12" *ngIf="!isShowContactInfo" style="padding:0.3% 0 0.3% 0;height:7.5%" id="actiondivid">
      <userdataheader [parentComp]="'Contact'" [selectedItems]="applicationComponent.selectedItems"
                      *ngIf="!applicationComponent.googlemapshow" (outPropsEvent)="UserlistDataReceived($event)">
      </userdataheader>
      <div class="col-xs-1" *ngIf="applicationComponent.googlemapshow" style="padding: 0%;float: right;">
        <span style="float:right;margin-top:7%" (click)="CloseContactDiv();"> <img src="../../../assets/img/Black x.png" style="height:25px" /> </span>
      </div>
    </div>
    
    <div [ngClass]="(isSharing) ? 'col-xs-7':(isShowContactInfo)?'col-xs-4':(applicationComponent.googlemapshow)?'col-xs-5':'col-xs-12'" style="height: 88%; margin-top: 0.5%; padding: 0px 0px 0px 0px; " [style.border]="(applicationComponent.searchCriteriadata != '')?'3px solid #00B0F0':'none'" *ngIf="isContactViewOpen">
      <div style="height:4%" *ngIf="(isShowContactInfo && isContactViewOpen)||(applicationComponent.googlemapshow)">
        <b style="font-size: 9pt; margin-left: 10px;"> <span (click)="CloseContactDiv()"><img src="../../../assets/img/Black x.png" style="height: 21px; float: left; transform: rotate(180deg);" /></span> CONTACTS</b>
        <span (click)="expandContactView('Collapse')"><img src="../../../assets/img/Open Quickview Grid Panel.png" style="height: 23px; float: right; transform: rotate(180deg);" /></span>
      </div>
      <div class="col-xs-12 input-icons" style="padding: 7px; margin-bottom: 0.3%; border: 1px solid lightgray; width:100%">
        <i class="fa fa-search icon"></i>
        <input type="text" class="searchform-control" id="filter-text-box" placeholder="Search" (input)="onFilterTextBoxChanged()" [(ngModel)]="value" />
      </div>
      <ag-grid-angular *ngIf="(isContactViewOpen && !isStatusidopen)" style="width: 100%; height:93% !important;" id="gridheight" class="ag-theme-balham"
                       [gridOptions]="gridOptions"
                       [columnDefs]="columnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="allContacts"
                       [context]="context"
                       [sideBar]="sideBar"
                       [cacheQuickFilter]="true"
                       [frameworkComponents]="frameworkComponents"
                       (rowSelected)="rowClickAgGrid($event)"
                       (rowDoubleClicked)="cellDoubleClicked($event)"
                       (columnVisible)="columnVisible($event)"
                       (dragStopped)="dragStopped($event)"
                       (columnResized)="columnResized($event)"
                       (selectionChanged)="onSelectionChanged($event)"
                       (gridReady)="onAgGridReady($event)">
      </ag-grid-angular>

      <div *ngIf="isStatusidopen">
        <div class="col-xs-12" style="height: 55px; background-color: rgb(186,219,152); padding: 0px ">
          <div class="col-xs-11" style="margin-top: 0.5%; font-size: 17px;border-right: 2px solid #fff;">
            <label>STATUS ID</label>
            <h4>Select all the Fields that describe this account</h4>
          </div>

          <div class="col-xs-1" (click)="CloseaccountIds()" style="padding: 2% 1% 1% 1%;">
            <img src="../../../assets/img/White x.png" style="float: right;height: 30px" />
          </div>
        </div>
        <status-ids [accOrContId]="accountid" [accesstype]="'Account'" title="Status ID"></status-ids>
      </div>
    </div>
    <div class="col-xs-4" style="padding:0;height:100%" *ngIf="!isContactViewOpen && !isGooglemapExpandOpen">
      <div class="col-xs-12" style="padding:0;height:4%">
        <span (click)="expandContactView('Expand')">
        <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
      </div>
      <div class="col-xs-12 scroll-c" style="padding:0;height:100%">

        <app-new-notes style="height:100%"
                       [selectedTabName]="'1'"
                       [conid]="contactid"
                       [typ]="'Contact'"
                       [ViewType]="'Block'"
                       [isModal]="false">
        </app-new-notes>
      </div>
    </div>
    <div *ngIf="(applicationComponent.googlemapshow)" [ngClass]="(!isGooglemapExpandOpen)? 'col-xs-7' : 'col-xs-12'" style="padding:0px;height:100%" [style.width.%]="isGooglemapExpandOpen?'100':isShowContactInfo?'66.5':'58'">
      <div class="col-xs-12" *ngIf="isGooglemapExpandOpen" style="padding:0;height:4%">
        <span (click)="expandContactView('Expand')"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
      </div>
      <addressmap [AccConIds]="ContactIds" [accesstype]="'Contacts'" (outPropsEvent)="ContactIdsReceived($event)"></addressmap>
    </div>
    <div *ngIf="(isSharing)" class="col-xs-5" style="height:95%;padding:0;">
      <ownershipsharing [AccId]="accountid" (sendMsgEvent)="onCloseEventReceived($event)"></ownershipsharing>
    </div>
    <div *ngIf="isShowContactInfo" class="col-xs-8" style="padding:0px 0px 0px 1%;height:100%">
      <contactsdashboard *ngIf="!isShowAccountInfo" [contactids]="contactid" [type]="this.type" [tab]="'QuickView'" (sendMsgEvent)="onContactMessageReceived($event)">
      </contactsdashboard>
      <account-contact *ngIf="isShowAccountInfo" [SelectedContactId]="SelectedContact" [type]="'Account'" [contactId]="'show'+accountid" [tab]="'QuickView'"></account-contact>
    </div>
    <!--<div *ngIf="IsEditContact" class="col-xs-5" style="height:95%;padding:0;">
    <editcontact [contactid]="contactid" [accountId]="accountid" [type]="type" (sendMsgEvent)="oneventReceived($event)"></editcontact>
  </div>-->

    <input type="text" id="accotIdNotes" style="display:none;" />
    <input type="text" id="accotTypeNotes" style="display:none;" />

    <app-dialog *ngIf="notesshowDialog" [(visible)]="notesshowDialog" style="height:100%" (visibleChange)="onCloseDialogEventReceived($event)" [CloseButtonColor]="'black'">
      <div class="col-xs-12" style="height: 2%; padding: 0%; background-color: rgb(186,220,153)">
      </div>
      <div class="col-xs-12" style="height:6%;border-bottom:1px solid gray;">
        <label>NOTES / ACCOUNT DETAILS</label>
      </div>
      <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%">

        <app-new-notes style="height:100%"
                       [selectedTabName]="'Contact Only'"
                       [conid]="contactid"
                       [typ]="'Contact'"
                       [isModal]="false">
        </app-new-notes>

      </div>
      <!--<app-notes style="height:100%" [title]="'Contact Notes Info'"
             [source]="'contact'"
             [account]="account"
             [gridOptionsnotes]="gridOptionsnotes"
             [allnotes]="allnotes"
             [context]="context"
             [columnDefsnotes]="columnDefsnotes"
             [getRowHeight]="getRowHeight"
             [contact]="contact"
             [tabName]="'Contact'"
             [contactid]="contactid"
             [isModal]="isModal"
             [conid]="contactid">
  </app-notes>-->



    </app-dialog>
  </div>

</div>

<!--<app-dialog [(visible)]="showDialog" *ngIf="showDialog" id="showDialog">
  <convertcontactto-lead [id]="contactid" [type]="leadstatus" [selectorTag]="true"></convertcontactto-lead>
</app-dialog>-->
<app-dialog [(visible)]="CallHistoryDialog">
  <calllisthistory [id]="contactid" [type]="callhistorystatus"></calllisthistory>
</app-dialog>
<div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
  <textarea *ngIf="isShowDetailsChecked" id="txtUserFullName" readonly rows="3" name="userInfo" [(ngModel)]="userInfo" style="width:100%; border: none;"></textarea>
  <div class="col-xs-12" style="padding:0;">
    <button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog" style="float:left;position:unset; "><i class="fa fa-window-close-o"></i></button>
  </div>
  <div class="box" style="height:98%;border:none">
    <!--<span *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" class="editnote">Edit</span>-->
    <!--<span *ngIf="showEditNoteBtn" (click)="saveEditedNote()" class="save">Save</span>-->
    <div id="txtLongNoteDesc" class="text">
    </div>
  </div>

</div>
<div *ngIf="MapItEventListner()"></div>

<app-dialog [(visible)]="showAddEditContact" *ngIf="showAddEditContact" id="addEditContact"  [closable]="false" [OutSideClick]="false" >
  <editcontact [contactid]="contactid" [accountId]="accountid" [type]="'Contact'" (sendMsgEvent)="ContactReceiveEvent($event)"></editcontact>
</app-dialog>
<app-dialog [(visible)]="DeleteConfirmationPopup" *ngIf="DeleteConfirmationPopup" id="DeleteConfirmationPopup" [closable]="false">
  <app-delete-popup [status] = "contactStatus" [MessageType]="'Contact'"  (sendMsgEvent)="ReceiveDeleteevent($event)">
    </app-delete-popup>

</app-dialog>

