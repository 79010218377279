<div class="container" style="padding: 0;margin-top:0;" *ngIf="PageFrom !== 'Campaign'">
  <div class="col-xs-12" style="padding: 0;font-size:12px;">
    <div class="col-xs-12" style="padding: 1%;border: 1px solid #ddd;height: 170px;margin-right: 0.25%;">
      <button id="test" style="display:none"></button>
      <div class="col-xs-12" id="contactinfo" style="display:inline-flex;padding:0;">
        <div class="col-xs-6" style="padding:0;text-align:left">
          <h5><b>Contact Selected</b></h5>
          <div><b class="providerLabel" style="color:red;">{{contact.accountname}}</b></div>
          <div class="providerLabel">{{contact.firstname}} {{contact.middlename}} {{contact.lastname}} , {{contact.title}}</div>
          <div>{{contact.speciality}}</div>
          <div>{{contact.address1}}</div>
          <div>{{contact.address2}}</div>
          <div>
            <span>
              <span *ngIf="contact.city">{{contact.city}}</span>
              <span *ngIf="contact.city">, {{contact.state}}.</span>
              <span *ngIf="contact.city">, {{contact.zip |slice:0:5}}<span *ngIf="contact.zip.length > 5">-{{contact.zip |slice:5:20}}</span></span>
            </span>

          </div>
          <div>Phone : {{contact.cell}}</div>
        </div>
        <div class="col-xs-6" style="text-align: right;padding-right: 0;">
          <b>Owner :{{contact.owner}}   </b>
          <button style="background-color: white;border: none;" (click)="Close()">X</button>
        </div>
      </div>
    </div>

  </div>
  <div class="col-xs-12" id="OpportunityHistory" style="padding: 0;">
    <div class="row">
      <div class="col-xs-12">
        <div class="col-xs-8">
          <h5>Opportunity History</h5>
        </div>
        <div *ngIf="opportunityId != 0" class="col-xs-1">
          <button class="btn" style="width:100%; border:none ;border-radius: 7px;"><span class="mainCheckMark"></span>Cancel</button>
        </div>
        <div *ngIf="opportunityId != 0" class="col-xs-2">
          <button class="btn noprovider" style="width:100%; border:none ;border-radius: 7px;" (click)="SaveOpportunity()"><span class="mainCheckMark"></span>Save Opportunity</button>
        </div>
        <div>

        </div>
      </div>
    </div>

    <ag-grid-angular class="ag-theme-balham"
                     style="height:335px"
                     [gridOptions]="gridOptions"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="Allopportunities"
                     [sideBar]="sideBar"
                     (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                     (rowSelected)="rowClickAgGrid($event)"
                     (gridReady)="onAgGridReady($event)">
    </ag-grid-angular>
  </div>
  <div class="col-xs-12" style="margin-bottom: 0.5%;padding: 0;padding-left: 0.5%;" id="mainDiv">
    <div class="col-xs-12 " id="Opptdetails" style="padding-left: 0% !important;padding-right:0;padding:0; display:none">
      <div [class]="(this.selectedtype=='account')?'col-xs-12':'col-xs-12'" style="padding:0;padding-bottom: 6px;" id="addNewOpportunity">
        <div class="col-xs-12" style="padding:0">
          <div class="col-xs-12" style="padding: 0px;" id="accountHeader">
            <div class="btn changeActive mainactiveButton" style="width:100%" id="Leadsale"><span id="Leadsale_span" class="mainCheckMark activeColor"></span><label>Add New Opportunity</label></div>
          </div>
        </div>
      </div>
      <form #opprtunityform="ngForm" (keydown.enter)="handleEnterKeyPress($event)">
        <div class="col-xs-12" style="padding: 0;padding-right:0%;background:#FFFFFF;width: 100%;box-shadow: -5px 15px 12px -2px lightgrey;border:none !important;" id="containtOpp">
          <div class="col-xs-12 zeroPadding">
            <div class="col-xs-1 ">
              <label>Item</label>
            </div>
            <div class="col-xs-11">
              <select class="form-control" name="item" [(ngModel)]="opportunities.item" (change)="completeForm();handleClick($event)" required>
                <option value="null" disabled [selected]="true">Select the Item</option>
                <option *ngFor="let oppartunity of opprtunityitemlist" value={{oppartunity.id}}>
                  {{oppartunity.itemName}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-xs-12 zeroPadding" style="margin-top:1%;">
            <div class="col-xs-1 ">
              <label>$$$</label>
            </div>
            <div class="col-xs-5">
              <input class="form-control" id="term" type="number" name="dollers" [(ngModel)]="opportunities.dollers" placeholder="Enter Amount" (keyup)="completeForm();handleClick($event)" required />
            </div>
            <div class="col-xs-1 ">
              <label>RR:</label>
            </div>


            <div class="col-xs-5">
              <input class="form-control" id="term" type="number" name="RR" [(ngModel)]="opportunities.RR" placeholder="Enter Amount" (keyup)="completeForm();handleClick($event)" required />
            </div>
          </div>
          <div class="col-xs-12 zeroPadding" style="margin-top:1%;">
            <div class="col-xs-1 ">
              <label>ECD</label>
            </div>
            <div class="col-xs-5">
              <input class="form-control" id="term" type="date" name="estimatedclosedate" [(ngModel)]="opportunities.estimatedclosedate" (change)="completeForm();handleClick($event)" required style="padding:0;" />
            </div>
            <div class="col-xs-1 ">
              <label>PC</label>
            </div>
            <div class="col-xs-5">
              <select class="form-control" name="PC" [(ngModel)]="opportunities.PC" (change)="completeForm();handleClick($event)" required style="padding:0;">
                <option value="null" disabled [selected]="true">Select %</option>
                <option *ngFor="let pc of pclist" value={{pc.id}}>
                  {{pc.itemName}}
                </option>
              </select>

            </div>
          </div>
          <div class="col-xs-12 zeroPadding" style="margin-top:1%">
            <div class="col-xs-1 ">
              <label>#Drs</label>
            </div>
            <div class="col-xs-5">
              <select class="form-control" name="drs" [(ngModel)]="opportunities.drs" (change)="completeForm();handleClick($event)" required>
                <option value="null" disabled [selected]="true">Select # Drs.</option>
                <option *ngFor="let dr of drlist" value={{dr.id}}>
                  {{dr.itemName}}
                </option>
              </select>
            </div>
            <div class="col-xs-1 ">
              <label>#Mid Level</label>
            </div>
            <div class="col-xs-5">
              <select class="form-control" name="midlevels" [(ngModel)]="opportunities.midlevels" (change)="completeForm();handleClick($event)" required>
                <option value="null" disabled [selected]="true">Select # Mid Levels</option>
                <option *ngFor="let midlevel of midlevelslist" value={{midlevel.id}}>
                  {{midlevel.itemName}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-xs-12 zeroPadding" style="margin-top:1%">
            <div class="col-xs-1 ">
              <label>Lead From</label>
            </div>
            <div class="col-xs-5">
              <select class="form-control" name="leadfromid" [(ngModel)]="opportunities.leadfromid" (change)="completeForm();handleClick($event)" required>
                <option value="null" disabled [selected]="true">Select Lead From</option>
                <option *ngFor="let lead of  leadfromlist" value={{lead.id}}>
                  {{lead.itemName}}
                </option>
              </select>
            </div>

            <div class="col-xs-1 ">
              <label>Owner</label>
            </div>
            <div class="col-xs-5">
              <select class="form-control" name="ownerid" [(ngModel)]="opportunities.ownerid" (change)="completeForm();handleClick($event)" required>
                <option value="null" disabled [selected]="true">Select Owner</option>
                <option *ngFor="let owner of  OwnerList" value={{owner.id}}>
                  {{owner.itemName}}
                </option>
              </select>
            </div>
            <div class="col-xs-12 zeroPadding" style="margin-top:1%;padding: 0;">
              <div class="col-xs-1 ">
                <label>Notes</label>
              </div>
              <div class="col-xs-5">
                <div class="col-xs-12" id="notesDiv" style="border: 1px solid #ddd;padding:0;">
                  <div style="max-height:40px;overflow:auto">
                    <div *ngFor="let notes of opportunitiesnotes">
                      {{notes.created | date: 'MM-dd-yyyy' }} :{{notes.notedescription}}
                    </div>
                  </div>
                  <textarea rows="3" [(ngModel)]="notes.stagenotedescription" name="notes" id="notesTextArea" style="resize:none;width: 100%;border:none;height: 80px;background:lightgray" placeholder="enter text here" (keyup)="completeForm();handleClick($event)"> </textarea>
                </div>

              </div>
              <div class="col-xs-1 ">

              </div>
              <div class="col-xs-5">
                <button class="btn noprovider" style="display:none;width:100%; margin-top:48px" (click)="addOpportunity();" id="saveOpp"><span class="mainCheckMark"></span>Save</button>
                <div id="statusdiv" class="" style="display:none;margin-top: -5%;">
                  <div class="col-xs-4" style="padding:0"><button class="btn c-button" id="Won" (click)="delete(this.opportunities.id,'Closed-Won')" style="width:92%"> Won</button></div>
                  <div class="col-xs-4" style="padding:0"><button class="btn c-button" id="Lost" (click)="delete(this.opportunities.id,'Closed-Lost')" style="width:92%">Lost</button></div>
                  <div class="col-xs-4" style="padding:0">
                    <button class="btn c-button" id="Pending" (click)="delete(this.opportunities.id,'Pending')" style="width:99%">Pending</button>
                  </div>
                  <div id="Dialog" style="display:none">
                    <div class="col-xs-12" style="padding:0;width: 100%;text-align:center;background:#80d4ff;font-size: 11px;height: 65px;margin-top: 2%;">
                      Do you want to close this Opportunity?
                      <div class="row" style="margin-top: 3%;">
                        <div class="col-xs-3 col-xs-offset-3" style="padding:0;">
                          <button class="btn " style="width:100%; padding:5%;" (click)="ChangeStatus(this.opportunities.id,this.opportunities.status)"><span id="saletool_span" class="mainCheckMark"></span>Yes</button>
                        </div>
                        <div class="col-xs-3" style="padding:0;">
                          <button class="btn " style="width:98%; padding:5%" (click)="Cancel()"><span id="saletool_span" class="mainCheckMark"></span>No</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="col-xs-12" style="padding:0;height:100%" *ngIf="PageFrom == 'Campaign'">
  <div class="col-xs-12" style="padding:0;height:100%;" *ngIf="type == 'Contact'">
    <div class="col-xs-12" style="padding:2% 2% 0 2%;height:50%">
      <ag-grid-angular class="ag-theme-balham"
                       style="height:100%"
                       [gridOptions]="gridOptions"
                       [columnDefs]="columnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="Allopportunities"
                       [sideBar]="sideBar"
                       (selectionChanged)="onSelectionChanged($event)"
                       (gridReady)="onAgGridReady($event)">
      </ag-grid-angular>
    </div>
    <div class="col-xs-12" style="padding:2%;height:50%">
      <div class="col-xs-12" style="padding:0;height:100%;border:1px solid black" *ngIf="opportunitySelected">
        <div class="col-xs-6" style="height:100%">
          <div><label>{{opportunities.contactName}}</label></div>
          <div><span>Created by: {{opportunities.owner}}</span></div>
          <div><span>Price: {{opportunities.dollers | currency}}</span></div>
          <div><span>Monthly: {{opportunities.RR | currency}}</span></div>
          <div><span>Date Created: {{opportunities.datecreated}}</span></div>
          <div><span>Status: {{opportunities.status}}</span></div>
        </div>
        <div class="col-xs-6" style="padding:0;height:100%">
          <div class="col-xs-5" style="padding:0;height:15%;margin:53% 0 0 45%">
            <button id="LinkOpportunity" class="btn saveButton" (click)="LinkOpportunity()" style="width: 100% !important;background:#00B0F0;color:white;border: 1px solid #2A3740 !important;height: 96%;">Link Opportunity</button>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="col-xs-12" style="padding:0;height:100%;" *ngIf="type == 'All Opportunities' || type == 'Sales Won' || type == 'Sales Lost'">
    <ag-grid-angular class="ag-theme-balham"
                     style="height:100%"
                     [gridOptions]="gridOptions"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="Allopportunities"
                     [sideBar]="sideBar"
                     [context]="context"
                     [frameworkComponents]="frameworkComponents"
                     (selectionChanged)="onSelectionChanged($event)"
                     (gridReady)="onAgGridReady($event)"
                     (columnVisible)="columnVisible($event)"
                     (dragStopped)="dragStopped($event)"
                     (columnResized)="columnResized($event)">
    </ag-grid-angular>
  </div>
</div>

<app-dialog [(visible)]="showpdf1" id="showpdf1" *ngIf="showpdf1" [CloseButtonColor]="'white'">
  <div id="pdfDiv" class="col-xs-12" style="padding:0;">
  </div>
</app-dialog>

<app-dialog [(visible)]="DeleteOpportunity" *ngIf="DeleteOpportunity" id="DeleteOpportunity" [closable]="false">
  <div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br /><br />
    <span style="font-size:15px;"> Are You Sure </span><br />
    <div class="col-xs-8" style="padding:0;margin-top:1%">
      <span style="font-size:15px;">De-link this Opportunity with the Campaign?</span><br />
    </div>
    <div class="col-xs-4" id="MyButtonsDiv" style="padding:0;">
      <button class="btn" style="background-color: #0098f7 !important;color: white;width:50% !important" (click)="DeletOpportunity(opportunityId)">OK</button>
      <button class="btn" style="margin-left: 4%;width:45% !important;" (click)="DeleteOpportunity = false">Cancel</button>

    </div>
  </div>

</app-dialog>
