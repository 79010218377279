
<input type="text" id="emailIds" style="display:none;" />
<div style="height:100%" [style.display]="(NewProposalPage == false)? 'block':'none'">
  <div class="col-xs-12" style="padding:0;background: white;height:100%">

    <div class="col-xs-12" style="background:white;padding:0;height:100%">
      <div class="col-xs-8" style="padding:0;height:100%">

        <div style="height:26%;">
          <account-details *ngIf="FeeAmountdiv" [viewaccount]="viewaccount" [account]="account" [tab]="''" [noteslist]="allnotes" (btnClickEvent)="btnClickReceived($event)"
                           [pageFrom]="'Leads'" [ViewData]="'Contact'" [contact]="contact"></account-details>
        </div>
         <div [ngClass]="(allSalesItems.length>0 &&(saleprocesshistory.status=='Won' ||saleprocesshistory.status=='Lost')) ?'col-xs-12 closedLeadwatermark':allSalesItems.length>0 ?'col-xs-12 OpenLeadwatermark':'col-xs-12 '" style="overflow: auto; border-radius:3px; padding:0;height:69%" *ngIf="!NewProposalPage">
         <ag-grid-angular class="ag-theme-balham"
                      style="height:100%;"
                      [gridOptions]="gridOptionsSalesItems"
                      [columnDefs]="columnDefsSalesItems"
                      [defaultColDef]="applicationComponent.defaultGridColDef"
                      [rowData]="allSalesItems"
                      [context]="context"
                      [sideBar]="sideBar"
                      [frameworkComponents]="frameworkComponents"
                      (rowSelected)="rowClickAgGridSalesItems($event)"
                      [suppressDragLeaveHidesColumns]="true"
                      (columnVisible)="columnVisible($event)"
                      (dragStopped)="dragStopped($event)"
                      (columnResized)="columnResized($event)"
                      (gridReady)="onAgGridReady($event)">
         </ag-grid-angular>
   </div>
      </div>
      <div class="col-xs-4" style="padding:0;height:100%">
        <div class="col-xs-12" style=" height: 96%;padding:0" *ngIf="!PreviousNotesExpand">

          <div class="col-xs-12" style="height: 5%; margin-top: 1%; background-color: rgb(40,62,74); color: gray; padding: 1%; text-align: center; color: white ">
            <div class="col-xs-10"><label>LEAD  DETAILS </label> </div>
            <div class="col-xs-1">
              <button (click)="OpenLeads()" style="background: transparent; text-align: end; padding-right: 0;">
                <img src="../../../assets/img/Edit Icon White.png" style="height:21px;" title="Edit Task" />
              </button>
            </div>
          </div>
          <div class="col-xs-12" style="padding: 0%; height: 57%; border: 1px solid lightgreen; padding-left: 2%; margin-top: 1%;font-size:11px ">
            <div class="col-xs-12" style="padding: 0; height: 7.6%;padding-top: 5px;">
              <div class="col-xs-4" style="padding:0px">Contact:</div>
              <b class="col-xs-8" style="color: #00b3f7d9; padding: 0px">{{saleprocesshistory.contactname |uppercase }}</b>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 7.6%">
              <div class="col-xs-4" style="padding:0px">Days:</div>
              <div class="col-xs-4" style="padding:0px">{{saleprocesshistory.NoOfDays}}</div>
              <div class="col-xs-4" style="padding:0px"></div>
            </div>

            <div class="col-xs-12" style="padding: 0; height: 7.6%">
              <div class="col-xs-4" style="padding:0px">IPC:</div>
              <div class="col-xs-4" style="padding:0px">{{saleprocesshistory.IPCStatus}}</div>
              <div class="col-xs-4">{{saleprocesshistory.IPCCompletedDate | date:"MM/dd/yyyy"}}</div>
            </div>

            <div class="col-xs-12" style="padding: 0; height: 7.6%">
              <div class="col-xs-4" style="padding:0px">Analysis:</div>
              <div class="col-xs-4" style="padding:0px">{{saleprocesshistory.AnalysisStatus}}</div>
              <div class="col-xs-4">{{saleprocesshistory.AnalysisCompletedDate | date:"MM/dd/yyyy"}}</div>
            </div>

            <div class="col-xs-12" style="padding: 0; height: 7.6%">
              <div class="col-xs-4" style="padding:0px">P-Analysis:</div>
              <div class="col-xs-4" style="padding:0px">{{saleprocesshistory.PrepareAnalysisStatus}}</div>
              <div class="col-xs-4">{{saleprocesshistory.PrepareAnalysisReportCompletedDate| date:"MM/dd/yyyy"}}</div>
            </div>

            <div class="col-xs-12" style="padding: 0; height: 7.6%">
              <div class="col-xs-4" style="padding:0px">R-Analysis:</div>
              <div class="col-xs-4" style="padding:0px">{{saleprocesshistory.ReviewAnalysisStatus}}</div>
              <div class="col-xs-4">{{saleprocesshistory.ReviewAnalysisCompletedDate| date:"MM/dd/yyyy"}}</div>

            </div>
            <div class="col-xs-12" style="padding: 0; height: 7.6%">
              <div class="col-xs-4" style="padding:0px">Proposal:</div>
              <div class="col-xs-4" style="padding:0px">{{saleprocesshistory.GenerateProposalStatus}}</div>
              <div class="col-xs-4">{{saleprocesshistory.GeneralProposalCompletedDate| date:"MM/dd/yyyy"}}</div>

            </div>

            <div class="col-xs-12" style="padding: 0; height: 7.6%">
              <div class="col-xs-4" style="padding:0px">Justification:</div>
              <div class="col-xs-4" style="padding:0px">{{saleprocesshistory.JustificationStatus}}</div>
              <div class="col-xs-4">{{saleprocesshistory.justificationmettingCompletedDate| date:"MM/dd/yyyy"}}</div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 7.6%">
              <div class="col-xs-4" style="padding:0px">Reference Call:</div>
              <div class="col-xs-4" style="padding:0px">{{saleprocesshistory.ReferenceCallsStatus}}</div>
              <div class="col-xs-4">{{saleprocesshistory.ReferencecallCompletedDate| date:"MM/dd/yyyy"}}</div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 7.6%">
              <div class="col-xs-4" style="padding:0px">Demo:</div>
              <div class="col-xs-4" style="padding:0px">{{saleprocesshistory.DemonstrationStatus}}</div>
              <div class="col-xs-4">{{saleprocesshistory.MdlogicdemonstrationCompletedDate| date:"MM/dd/yyyy"}}</div>
            </div>

            <div class="col-xs-12" style="padding: 0; height: 7.6%">
              <div class="col-xs-4" style="padding:0px">WLP:</div>
              <div class="col-xs-4" *ngIf="allSalesItems.length>0 &&(saleprocesshistory.status=='Won' ||saleprocesshistory.status=='Lost')" style="padding:0px">{{saleprocesshistory.status}}</div>
              <div class="col-xs-4" *ngIf="allSalesItems.length>0 &&(saleprocesshistory.status=='Won' ||saleprocesshistory.status=='Lost')">{{saleprocesshistory.completeddate| date:"MM/dd/yyyy"}}</div>

            </div>

          </div>
            <div class="col-xs-12" style="padding: 0; height: 33%; border: 1px solid lightgreen; border-top: none; border-bottom: none;">
              <div class="col-xs-12    scroll-c" style="padding: 1% 1% 1% 2%; height: 100%; overflow: auto; border: 3px solid lightgray;display:flex">
                <div class="col-xs-10" style="padding: 0;">
                  <div class="col-xs-12" style="padding:0;margin-top:1%;font-size:11px;margin-bottom:5%" *ngFor="let note of allnotes">
                    <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; font-weight: bold; height: 20px;"><b>{{note.created }}</b></div>

                    <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; height: 20px;">{{note.notedescription}}</div>
                  </div>
                </div>
                <div class="col-xs-2" style="padding:0">
                  <span style="float: right; width: 0; height: 0; border-top: 25px solid gray; border-left: 25px solid transparent;" (click)="PreviousNotesExpand = true"> <!--<img src="../../../assets/img/Black Expand View.png" style="height:15px" (click)="PreviousNotesExpand = true">--></span>
                </div>
              </div>
            </div>
          </div>

        <div class="col-xs-12" style="padding:0;height:96%" *ngIf="PreviousNotesExpand">
          <div class="col-xs-12" style="height: 100%; border: 1px solid lightgreen; padding: 0 0 0 0%">
            <div class="col-xs-12" style="height: 5%; margin-top: 0%; background-color: rgb(40,62,74); color: rgb(186,219,152); padding: 1%; text-align: center; ">
              <label>LEAD  NOTES</label>
              <span style="float:right;height:30px;padding:1%"> <img src="../../../assets/img/White x.png" style="height:20px;float:right;" (click)="PreviousNotesExpand = false; "></span>
            </div>

            <div class="col-xs-12 scroll-c" style="padding:0;height:88%;overflow:auto">
              <div class="col-xs-12" style="padding: 0; margin-top: 1%; font-size: 11px; margin-bottom: 5%; padding-left: 2%;" *ngFor="let note of allnotes">
                <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; font-weight: bold; height: 20px;"><b>{{note.created }}</b></div>

                <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; height: 20px;">{{note.notedescription}}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
      
      <app-dialog [(visible)]="showsalesDialog" id="showsalesDialog" *ngIf="showsalesDialog" [CloseButtonColor]="'black'">
        <div class="col-xs-12" style="padding: 0; height: 2%;background-color:orangered">         
        </div>
        <div class="col-xs-12" style="padding: 0; height: 5%;">         
            <label id="lblOpp" style="margin:1% 0 0 0%;font-size:13px;">EDIT LEADS</label>          
        </div>
        <ag-grid-angular style="width: 100%;height: 53%" class="ag-theme-balham" id="LeadStagesGrid"
                         [gridOptions]="AddLeadStagegridOptions"
                         [columnDefs]="documentscolumnDefs"
                         [defaultColDef]="applicationComponent.defaultGridColDef"
                         [rowData]="allSalesStages"
                         [sideBar]="sideBar">
        </ag-grid-angular>
        <div class="col-xs-12" style="padding-top: 54px;font-size:15px">
          <div *ngIf="allSalesItems.length>0" class="col-xs-3" style="padding:0%">
            <b> LEAD STATUS : </b>
          </div>
          <div *ngIf="allSalesItems.length>0" class="col-xs-3" style="padding:0%">
            <b *ngIf="allSalesItems.length>0 &&(saleprocesshistory.status=='Won' ||saleprocesshistory.status=='Lost')">CLOSED</b>
            <b *ngIf="allSalesItems.length>0 &&(saleprocesshistory.status!='Won' && saleprocesshistory.status!='Lost')">OPEN</b>

          </div>
          <div class="col-xs-6" *ngIf="(DateSelection && StatusSelection) == true || showSaveNotes" style="float: right; height: 9%; padding: 0px">

            <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width: 100%; background: white; padding: 5px 7px;" (click)="SaveHistory()"><span class="mainCheckMark"></span>Cancel</button></div>
            <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width: 100%; background: #a2e477; padding: 5px 7px;" (click)="SaveHistory()"><span class="mainCheckMark"></span>Save</button></div>
          </div>
        </div>
        <div *ngIf="!PreviousNotesExpand" class="col-xs-12" style="height:31%;padding: 8% 1% 0 1%;">
          <div class="col-xs-12" style="height: 100%; border: 3px solid gray; border-radius: 5px; padding: 0.5%;">
            <div class="col-xs-12" style="padding:0;height:100%">
              <textarea id="notesArea" rows="6" class="form-control scroll-c" [(ngModel)]="notes.notedescription" type="text" name="notedescription" placeholder="Enter Note" style="height:100%;overflow:auto;border: none;background: #f1f1f1;" (keyup)="NotesKeyUp()"></textarea>
            </div>

          </div>
        </div>
      </app-dialog>



    </div>

    </div>
</div>
<app-dialog [(visible)]="NewProposalPage" *ngIf="NewProposalPage" id="CreateProposalPage" [closable]="false" [StayVisible]="true" (visibleChange)="CloseProposalPage($event)">
  <div class="col-xs-12" style="height:93%" *ngIf="NewProposalPage">
    <app-proposals [Type]="'Add'" [ContactOrAccountType]="'Contact'" [SelectedContactId]="contact.id" [PageFrom]="'Leads'" [redirectFrom]="''" (sendMsgEvent)="EventEmit()"></app-proposals>
  </div>
</app-dialog>


<app-dialog [(visible)]="taskDialog" *ngIf="taskDialog" style="width:500px !important" [CloseButtonColor]="'white'">
 
  <div class="col-xs-12" style="height: 6%; padding: 0; background-color: rgb(40,62,74); color: white;">
    
    <div class="col-xs-12" style="height: 100%; background-color: rgb(40,62,74); color: white; text-align: center; padding: 1%">
      <label style="margin-top:1%">ACCOUNT DETAILS</label>
    </div>
  </div>
  <div class="col-xs-12" style="padding:0;height:94%">
    <app-history [type]="'Activities'" [ContAccId]="contact.id" [userType]="'Contacts'"> </app-history>
  </div>
</app-dialog>
<app-dialog [(visible)]="MdlogicDemoDialog" >
  <div class="col-xs-12" id="mdlogicDemonstration">
    <div class="col-xs-12">
      <label> Select Status of the Sale -Opportunity</label>
    </div>
    <div class="col-xs-12" style="margin-top:1%; ">
      <div class="col-xs-offset-1">
        <div id="statusdiv">
          <div class="col-xs-4" style="padding:0"><button class="btn" id="Won" (click)="delete(this.opportunities.id,'DEAL WON')" style="width:92%"> Won</button></div>
          <div class="col-xs-4" style="padding:0"><button class="btn" id="Lost" (click)="delete(this.opportunities.id,'This Deal Was Lost')" style="width:92%">Lost</button></div>
          <div class="col-xs-4" style="padding:0">
            <button class="btn" id="Pending" (click)="delete(this.opportunities.id,'Pending')" style="width:99%">Pending</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12" style="margin-top:1%;">
      <div class="col-xs-1" style="padding:0">
        <label style="margin-left: -15px;">Notes</label>
      </div>
      <div class="col-xs-11" style="padding: 0;">
        <div class="col-xs-12" id="notesDiv" style="border: 1px solid #ddd;padding:0;">
          <textarea rows="12" [(ngModel)]="notes.stagenotedescription" name="notes" id="stagenotesTextArea" style="resize:none;width: 100%;border:none;" placeholder="Type Notes here..." (keyup)="completeForm();"> </textarea>
        </div>
      </div>
    </div>
    <div class="col-xs-12" style="margin-top:1%;">
      <div class="col-xs-offset-1">
        <button class="btn noprovider" style="width:100%; border:none" (click)="SaleStatus();" id="saveOpp"><span class="mainCheckMark"></span>Save </button>
      </div>
    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="showDialog" *ngIf="showDialog" id="showDialog" style="height:100%" [CloseButtonColor]="'white'" (visibleChange)="onCloseDialogEventReceived($event)">
  <div class="col-xs-12" style="height: 6%; background-color: rgb(40,62,74); color: white; text-align: center; padding: 0%">
    <div class="col-xs-1" style="padding: 0; text-align: left; padding: 1% 0 0 1%;">
      <img src="../../../assets/img/White Expand View.png" style="height:21px" (click)="ExpandPDF(true)" *ngIf="!DocExpandView" />
      <img src="../../../assets/img/White Collapse View.png" style="height:21px" (click)="ExpandPDF(false)" *ngIf="DocExpandView" />
    </div>
    <div class="col-xs-11" style="padding:1% 0 0 0;height:100%"><label>ACCOUNT DETAILS</label></div>


  </div>
  <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%">
    <app-new-notes style="height:100%"
                   [selectedTabName]="'Contact Only'"
                   [conid]="conid"
                   [typ]="'Contact'"
                   [isModal]="false">
    </app-new-notes>

  </div>

</app-dialog>
<app-dialog [(visible)]="LeadStageNote" *ngIf="LeadStageNote" id="LeadStageNote" (visibleChange)="onCloseDialogEventReceived($event)">
  <div class="col-xs-12" style="padding:0;height:99%;">
    <div class="col-xs-12" style="padding:0;text-align:center;background-color:#badb98;color:black;height:6%">
      <label style="margin-top:0.5%">Add New Notes</label>
    
    </div>
    <div class="col-xs-12" style="padding: 0;height:85%;overflow:auto" id="noteidDiv">
      <div id="noteid" [froalaEditor]="editorOptions" [(froalaModel)]="saleprocesshistory.notesDescription" style="padding-right: 0%;height:99%"></div>

    </div>
    <div class="col-xs-4" id="saveNotesButtons" style="padding-left: 1%;padding:0; height:8%;float:right;margin-right:2%;">
      <button class="btn cancel" (click)="CancelNote()" type="submit" style="padding: 0.75%;border: 1px solid;width:48%;font-weight:normal">Cancel</button>
      <button class="btn provider saveButton" (click)="SaveNote(SelectedStage)" type="submit" style="margin-left: 2.2%;padding: 0.75%;width: 49%; font-weight:normal;border:1px solid black !important;">Save  </button>
    </div>
  </div>
</app-dialog>


<app-dialog [(visible)]="SaveLeadDialog" *ngIf="SaveLeadDialog" id="SaveLeadDialog" [closable]="false">
  <app-delete-popup [title]="'LEAD SALES PROCESS'" [description]="'Save changes to sales process?'" [isok]="true" [issave]="false"
    [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="SaveSaleProcessHistory($event)">
  </app-delete-popup>
  <!--<div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
    <span style="font-size:15px;">LEAD SALES PROCESS</span><br />
    <div class="col-xs-8" style="padding:0;">
      <div style="padding:0;margin-top:1%">
        <span style="font-size:15px;">Save changes to sales process?</span><br />
      </div>
    </div>

    <div class="col-xs-4" style="padding:0;">
      <div class="col-xs-6">
        <button class="btn" (click)="SaveSaleProcessHistory();SaveLeadDialog=false;showsalesDialog=false;" style="float:right;background-color: #0098f7 !important;color: white;margin-top:-5%">OK</button>
      </div>
      <div class="col-xs-6">
        <button class="btn" style="background: #f3f3f3;border: 0.5px solid #ddd !important;font-weight: 700;border-radius: 7px;" (click)="SaveLeadDialog=false;">
          Cancel
        </button>
      </div>
    </div>

  </div>-->

</app-dialog>
<app-dialog [(visible)]="showAddEditAccount" *ngIf="showAddEditAccount" id="addEditAccount" [closable]="(action!='View')?false:true" [OutSideClick]="(action!='View')?false:true">
  <app-add-edit-account *ngIf="action!='View'" [accountID]="this.account.id" [type]="'Account'" (sendMsgEvent)="ReceiveEvent($event)"></app-add-edit-account>
  <accountorcontactview *ngIf="action=='View'" [accountId]="this.account.id" [type]="'Account'"></accountorcontactview>
</app-dialog>

<app-dialog [(visible)]="showAddEditContact" *ngIf="showAddEditContact" id="addEditContact"  [closable]="false" [OutSideClick]="false" >
  <editcontact [contactid]="0" [accountId]="account.id" [type]="'Contact'" (sendMsgEvent)="ContactReceiveEvent($event)"></editcontact>
</app-dialog>
