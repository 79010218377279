<div class="col-xs-12" style="padding:0%;height:100%">  
 
  <div class="col-xs-12" style="padding:1%;height:99%">

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="AccountTransferPage">
      <div class="col-xs-3" style="padding:0px;">
        <angular2-multiselect [data]="FromDropdownList" 
                              name="selectedItems"
                              [(ngModel)]="FromSelectedItems"
                              [settings]="fromDropdownSettings"
                              (onSelect)="FromOnItemSelect($event)"
                              (onDeSelect)="FromOnItemDeSelect($event)">
        </angular2-multiselect>
      </div>
      <div class="col-xs-3">
        <angular2-multiselect [data]="ToDropdownList" 
                              name="selectedItems"
                              [(ngModel)]="ToSelectedItems"
                              [settings]="ToDropdownSettings"
                              (onSelect)="ToOnItemSelect($event)"
                              (onDeSelect)="ToOnItemDeSelect($event)">
        </angular2-multiselect>
      </div>
      <div class="col-xs-3">
        <angular2-multiselect [data]="SearcValueList" [(ngModel)]="SearcValueModel"
                              [settings]="SearcValueDrpSetting"
                              (onSelect)="SearcValueItemSelect($event)" >
        </angular2-multiselect>
      </div>
      <div class="col-xs-3">
        <div class="col-xs-7" style="padding: 0; height: 100%; width: 100%; display: flex; border: 1px solid gray;
      border-radius:5px;">
          <input type="text" (keyup.enter)="Search($event)" id="SearchTextbox" (keyup)=Search($event) class="form-control" [(ngModel)]="term" style="height: 100%;border:none !important"  *ngIf="selectedval  !== 'Phone'" placeholder="Enter Search" />

          <input type="text" (keyup.enter)="Search($event)" id="SearchTextbox" (keyup)=Search($event) class="form-control" [(ngModel)]="term" style="height: 100%; border: none !important"  [textMask]="{mask: phonemask}" *ngIf="selectedval  == 'Phone'" placeholder="Enter Search" />
          <button (click)="clearSearch()" style="background: transparent;">x</button>
        </div>
      </div>

   
      <div style="height:85%;padding:0;margin-top:1%" [ngClass]="'col-xs-12'" [style.width.%]="'100'">
        <b style="font-size:12px;"> SEARCH RESULTS</b>
        <div class="col-xs-12" style="padding:0;height:100%">
          <ag-grid-angular style="height:100%;" class="ag-theme-balham" id="AccountGrid"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="allCustomers"
                           [context]="context"
                           (rowSelected)="rowClickAgGrid($event)"
                           [sideBar]="sideBar">
          </ag-grid-angular>
        </div>
 
      </div>

      <div class="col-xs-12" style="height:7%;margin-top:3%" *ngIf="Ids && Ids.length>0">
        <div class="col-xs-8"></div>
        <div class="col-xs-4">
          <div class="col-xs-6"> <button class="btn btn-default" style="width: 100%;">Cancel</button></div>
          <div class="col-xs-6"> <button type="submit" class="btn saveButton" (click)="TransferConfirmationBox=true;TransferConfirmationBoxCss()" style="width: 100%;">Save</button></div>

        </div>
      </div>
    </div>
  </div>
</div>
<app-dialog [(visible)]="LeaveTransferAccounts" id="LeaveTransferAccounts" *ngIf="LeaveTransferAccounts">
  <div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
    <div class="col-xs-8">
      <span style="font-size:15px;"> Account Transfer </span><br />
      <span style="font-size:15px;"> Leave Account Transfer Page?</span>
    </div>
    <div class="col-xs-4" style="padding:0;">
      <div class="col-xs-6" style="padding:0">
        <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%" (click)="LeaveTransfer()">OK</button>
      </div>
      <div class="col-xs-6" style="padding:0">
        <button class="btn" style="float: right; background-color: white !important; color: #0098f7; margin-top: 1%; border: 1px solid #eee !important; margin-left: 1%;" (click)="LeaveTransferAccounts=false">Cancel</button>
      </div>
    </div>
  </div>
</app-dialog>
<app-dialog [(visible)]="TransferConfirmationBox" id="TransferConfirmationBox" *ngIf="TransferConfirmationBox">
  <div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
    <div class="col-xs-8">
      <span style="font-size:15px;"> Account Transfer </span><br />
      <span style="font-size:15px;"> Transfer Account Ownership</span>
    </div>
    <div class="col-xs-4" style="padding:0;">
      <div class="col-xs-6" style="padding:0">
        <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%" (click)="TransferAccounts()">OK</button>
      </div>
      <div class="col-xs-6" style="padding:0">
        <button class="btn" style="float:right;background-color: white !important;color: black;margin-top:1%" (click)="TransferConfirmationBox=false">Cancel</button>
      </div>
    </div>
  </div>
</app-dialog>
