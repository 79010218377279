import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, Optional, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Dropdowntype } from '../../models/dropdowntype';
import { Notes } from '../../models/notes';
import { AccountsService } from '../../services/accounts.service';
import { CustomerService } from '../../services/customer.service';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { UserService } from '../../services/user.service';
import { ApplicationComponent } from '../application.component';
import { Location, DatePipe } from '@angular/common';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { UploadDocumentComponent } from '../upload-document/upload-document.component';
import { Accounts } from '../../models/accounts';
import { AccountsHistory } from '../../models/AccountHistory';
import { Projects } from '../../models/ProjectConfiguration';
declare var $: any;

@Component({
  selector: 'app-new-notes',
  templateUrl: './new-notes.component.html',
  styleUrls: ['./new-notes.component.css']
})
export class NewNotesComponent implements OnInit {
  @Input() conid: number;
  @Input() typ: string;
  @Input() projconid: number;
  @Input() projtyp: string;
  @Input() Opptype: string;
  @Input() isModal: boolean = true;
  @Input() selectedTabName: string;
  @Input() OpportunityId: string;
  @Input() ViewType: string = 'List';
  @Input() Projtyp: string ;
  salesrepName: string;
  currentuser: any;
  customeracces: number = 0;
  public elementRef;
  NotesDropdownList: Dropdowntype[] = [];
  timezonevalue: string;
  notes: Notes = new Notes();
  NotesDec: boolean = false;
  public notesdata: Notes = new Notes();
  allnotes: Notes[];
  AllnotesOriginal: Notes[];
  type: string = "";
  datePipe: DatePipe = new DatePipe("en-US");
  noteidDiv: boolean = false; projectaddNote: boolean = false;
  NotesBlockView: boolean = false;
  public gridOptionsnotes: GridOptions;
  public sideBar;
  public columnDefsnotes: any[];
  showEditNoteBtn: boolean = false;
  editNoteId: any;
  Upload: boolean = false;
  selectedNotes: Notes;
  AttachDocument: boolean = false;
  openFileView: boolean = false;
  notesMainDiv: boolean = true;
  userdocumentlist: any[] = [];
  userdocumentcount: number = 0;
  public isdocumentid: string = "";
  public isDelink = false;
  public selectedNoteId: number;
  documentindex: number = 0;
  public notesDesc: string = '';
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  account: Accounts = new Accounts();
  notedescription: string;
  documentview: boolean = false;
  @ViewChild(UploadDocumentComponent) uploadDocComp: UploadDocumentComponent;
  DocViewAccessDenied: boolean = false;
  accountHistory: AccountsHistory = new AccountsHistory();
  LastTicketDate;
  LastContactDate;
  Project: Projects = new Projects();
  constructor(private http: HttpClient, private route: ActivatedRoute, private viewContainerRef: ViewContainerRef, private customerService: CustomerService, private userservice: UserService,
    private router: Router, private toastr: ToastrService, private location: Location, private opprtunityservice: OpprtunitiesService, private accountsService: AccountsService,
    public applicationComponent: ApplicationComponent, myElement: ElementRef) {

    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.elementRef = myElement;
  }

  ngOnInit(): void {

    $('.fr-counter').attr('style', 'display:none;')
    var moment = require('moment-timezone');
    this.timezonevalue = moment.tz.guess();

    this.route.params.subscribe(params => {
      let providerid = Number($('#accotIdNotes').val());
      this.notes.accountid = providerid;

      if (params['type'] != null && params['type'] != 0) {
        this.type = params['type'];
      }
      this.LoadAccountData();
      this.Notes(this.selectedTabName);

      if (this.ViewType == 'Block') {
        this.View('Block');
      } else {
        this.View('List');
      }

    });
  }

  notesChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (rowEvent.api.getSelectedNodes()[0].data) {
        this.notesdata = rowEvent.api.getSelectedNodes()[0].data;
        this.NotesDec = true;
      }
    } else {
      this.notesdata = null;
      this.NotesDec = false;
    }

  }
  back() {
    this.location.back();
  }

  public GetAllNotes(contactid, type) { 
    this.allnotes = [];
   
    this.customerService.GetAllNotesList(this.currentuser.id, contactid, type).then((notes) => {
      notes.forEach(notes => {
        var moment = require('moment-timezone');
        var timeDisplay = notes.user + '<br />' + moment.tz(notes.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(notes.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        notes.created = this.datePipe.transform(notes.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.AllnotesOriginal = this.allnotes = notes;
      this.initializeAgGridNotes("");
      for (let note of this.allnotes) {
        if (note.isdocumentid != null && note.isdocumentid != "") {
          note.docList = note.isdocumentid.split(",");
        }
      }
      
    });
  }

  GetCustomerNotes(currentuserid, contactid, type, accidofallcontacts: string = "0", OppType: string) {  
    var moment = require('moment-timezone');   
    this.customerService.GetAllNotesListNew(currentuserid, contactid, type, accidofallcontacts, OppType).then((taskhistory) => {
      taskhistory.forEach(items => {
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh:mm a");

      });
      this.initializeAgGridNotes(type);    
      this.allnotes = taskhistory.filter(x => x.isnewnote == 1);
      for (let note of this.allnotes) {
        if (note.notedescription) {
          note.notedescriptionhtml = note.notedescription;
          note.notedescription = note.notedescription.replace(/<[^>]*>|&nbsp;/g, '');
        }
        
        if (note.isdocumentid != null && note.isdocumentid != "") {
          note.docList = note.isdocumentid.split(",");
         
        }
        
      }
         
    });

  }

  CancelNote() {
    this.noteidDiv = false;
    
  }
  enterNotes() {
   
    if (this.typ == "Project") {
      this.projectaddNote = true;
      setTimeout(function () {
        $('#projectaddNote .dialog').attr('style', 'width: 42%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;top:14%;margin-left: 552px');
      }, 4);
    } else {
      this.noteidDiv = true;
      $('.fr-wrapper').attr('style', 'height:72%;overflow:auto;');

    }
    setTimeout(function () {
      $('.fr-element').empty();
      $('.fr-element').html("");
    }, 100);
  }
  ProjectCancelNote() {
    this.projectaddNote = false;
    setTimeout(function () {
      $('.fr-element').empty();
      $('.fr-element').html("");
    }, 100);
  }
  SaveProjectNote() { }
  View(val) {
    if (val == 'Block') {
      this.NotesBlockView = true;
    } else {
      this.NotesBlockView = false;
    }
  }

  initializeAgGridNotes(val) {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'

    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',
      defaultToolPanel: 'none',
    };
    this.createColumnDefsnotes(val);

  }
  createColumnDefsnotes(val, @Optional() isShowDetails: boolean = false) {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      
      {
        field: 'notedescription', hide: false, tooltipField: 'notedescription', width: 250, cellStyle: { 'text-align': 'left' }, cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.value && params.value.includes("Owner Changed")) {
            eDiv.innerHTML = "<label style='color:red'>" + params.value + "</label>";
          } else {
            eDiv.innerHTML = params.value;
          }


          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      }, {
        field: 'notedescriptionhtml', hide: true, tooltipField: 'notedescriptionhtml', width: 250
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false },
      { field: 'accountname', headerName: 'Account', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide:false },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>'  }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide:  false },
      //{ field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide:  true, cellRenderer(params) { return params.value; } }
    ];

  }

  openDisplaynotes(params) {
    if (this.NotesBlockView) {
      this.notesdata = params;
    } else {
      this.notesdata = params.data;
    }
    this.type = params.type;
    this.NotesDec = true;
    if (this.currentuser.id == params.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.id;
    } else
      this.showEditNoteBtn = false; 
  }
  LoadAccountData() {
    if (this.typ == "Contact") {
      this.customerService.GetContactById(this.conid).then(result => {   
        this.customerService.GetByAccountIdNew(result.accountid).then(result => {
          this.account = JSON.parse(result);
          this.getAccountHistory(result.accountid);
        });
      });} else {
      this.customerService.GetByAccountIdNew(this.conid).then(result => {
        this.account = JSON.parse(result);
        
        this.getAccountHistory(this.account.id);
      });
    }
   
    }

  getAccountHistory(accId) {
    this.customerService.LastTicketCreatedDateByAccID(accId).then(result1 => {
      if (result1 != null && result1 != '0001-01-01T00:00:00') {
        this.LastTicketDate = this.datePipe.transform(result1, "MM-dd-yyyy")
      }

    });
    this.customerService.LastContactedDateByAccID(accId).then(result2 => {
      if (result2 != null && result2 != '0001-01-01T00:00:00') {
        this.LastContactDate = this.datePipe.transform(result2, "MM-dd-yyyy");
      }
    });
    this.customerService.GetAccountHistoryById(accId, this.currentuser.id).then(result => {
      if (result != null) {
        this.accountHistory = result;
      }

    });
    this.customerService.GetSalerepName(accId).then(result3 => {

      this.salesrepName = result3;

    });
  }
  AddDocumentsNew(val, Noteselected) {
    this.Upload = true;
    this.showDocumentAddDialogCss();
    this.selectedNotes = Noteselected;
    let selfobj = this;
    $(setTimeout(function () {
      selfobj.uploadDocComp.OpenFilemanual('New')
    }, 50));
    
  }

  showDocumentAddDialogCss() {
    setTimeout(function () {
      $('#Upload .dialog').attr('style', 'width:44%;padding-bottom:0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height:63%;overflow:auto;height:77%;left:0;top: 13.5% !important;padding:0;right:32%');
    }, 4)
  }

  addlink(note) {
    this.openFileView = false;
    if (this.typ == "Project") {
      this.customerService.GetProjectByProjectId(this.conid.toString()).then(result => {
        this.Project = JSON.parse(result);
        this.projconid = this.Project.AccountOrContactId;
        this.projtyp = this.Project.AccountOrContactType;
       
        this.AttachDocument = true;
        this.selectedNotes = note;
        this.notesMainDiv = false;
      });
    } else {
      this.AttachDocument = true;
      this.selectedNotes = note;
      this.notesMainDiv = false;
    }

  }


  openfile(documentid, note,index) {
   
    this.documentindex = index
    this.selectedNotes = note;
    this.userdocumentlist = this.selectedNotes.docList;
    this.userdocumentcount = this.selectedNotes.docList.length;
    
      this.getImageData(documentid);
    
   
  }
  getImageData(documentid) {
    var myBaseString = "";
    //this.userservice.GetImageData(documentid).then(result => {
    this.customerService.GetDocumentById(documentid).then(result => {
      if (result != null) {
        if (result.permissionlevel <= this.currentuser.permissionlevelid) {
          myBaseString = result.imagecontent;
          let objSelf = this;
          this.openFileView = true
          $(setTimeout(function () {
            //objSelf.sendMsgEvent.emit(myBaseString);
            objSelf.onDocumentOpen(myBaseString);
          }, 100));

          $('.bodyContaint').css('height', '600px')
          $('#NotespdfDiv').css('');
        }
        else {
          this.DocViewAccessDeniedCss();
        }
      }
      else {

      }

    });
  }
  DocViewAccessDeniedCss() {
    this.DocViewAccessDenied = true;
    setTimeout(function () {
      $('#DocViewAccessDenied .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4);
  }

  onDocumentOpen(myBaseString) {
    $('#NotespdfDiv').html('<embed src="' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
    $('#NotespdfDiv').attr('style', 'height: 93%;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');
  }

  delink(documentid, note) {
    $('#' + documentid + 'ON').attr('style', 'display:block;height:23px;');
    $('#' + documentid + 'Off').attr('style', 'display:none;height:23px;');
    let selfObj = this;
    setTimeout(function () {
      var isConfirmed = confirm("De-Link this document?");
      if (isConfirmed) {
        selfObj.isdocumentid = documentid;
        selfObj.selectedNotes = note;
        selfObj.UpdateNote(true);
        selfObj.isDelink = false;
      }
      else {
        $('#' + documentid + 'Off').attr('style', 'display:block;height:23px;');
        $('#' + documentid + 'ON').attr('style', 'display:none;height:23px;');
        selfObj.delinkOff(note.id)
      }
    }, 500);

  }
  delinkDocs(noteId) {
    this.isDelink = true;
    this.selectedNoteId = noteId;
    $('#' + noteId + 'Off').attr('style', 'display:none');
    $('#' + noteId + 'ON').attr('style', 'display:block;color:red;font-weight: 600;');
  }
  delinkOff(noteId) {
    this.isDelink = false;
    // this.selectedNoteId = noteId;
    $('#' + noteId + 'Off').attr('style', 'display:block');
    $('#' + noteId + 'ON').attr('style', 'display:none;color:red;font-weight: 600;');
  }

  previousdocument() {
    //this.documentindex--;
    //this.getImageData(this.userdocumentlist[this.documentindex]);

    this.documentindex--;
    //if (this.userdocumentlist[this.documentindex].permissionlevel <= this.currentuser.permissionlevelid) {

      this.getImageData(this.userdocumentlist[this.documentindex]);
    //} else {
    //  this.documentindex++;
    //  this.DocViewAccessDeniedCss();
    //}
  }
  nextdocument() {   
    this.documentindex++;  
      this.getImageData(this.userdocumentlist[this.documentindex]);
    
  }

  CloseopenFileView(val) {

    if (val == 'AttachDocument') {
      this.AttachDocument = false;
      this.notesMainDiv = true;
    } else {
      this.openFileView = false;
      this.documentindex = 0;
      this.userdocumentcount = 0;
    }

  }
  onOpenUploadDocument(msg) {
  
    if (msg != "ShowPDF") {
      this.isdocumentid = msg;
    }
  }

  linkDocument() {

    this.UpdateNote(false);
    this.AttachDocument = false;
    this.noteidDiv = false;
    this.openFileView = false;
    this.notesMainDiv = true;
  }
  closeDetailsDialog() {
    this.NotesDec = false;
  }

  editNote() {
    $('.save').show();
  }

  SavedDocuments(val) {
    if (val != "496" && val != "497" && val != "498" && val != "499" && val != "500" && val != "501" && val != "554") {
      this.Upload = false;
      this.isdocumentid = val;
      this.UpdateNote(false);
    }
  }



  SaveNote() {
    this.noteidDiv = false;
    this.notes.accountid = this.conid;
    this.notes.type = this.typ;
    this.notes.isnewnote = 1;
    this.notes.userid = this.currentuser.id;
    this.notedescription = $('.fr-element').html();
  
    this.notes.notedescription = this.notedescription;


    if (this.notes.notedescription != undefined && this.notes.notedescription != "") {
      this.customerService.SaveNotes(this.notes).then(result => {
        this.toastr.success("Notes saved successfully.", "Notes Saved", { timeOut: 600 });
        this.Notes("Display All");
        this.noteidDiv = false;
        this.projectaddNote = false;
        $('.fr-element').empty();
        $('.fr-element').html("");
        
      });
    }
  }



  Notes(val) {
    let allContactIds = [];
    this.selectedTabName = val;
    if (this.typ == "Account") {
      this.customerService.GetAllContactproviderByAccount(this.conid, this.currentuser.id).then((contacts) => {
        if (contacts.length > 0) {
          allContactIds = contacts.map(x => x.id);
          this.GetCustomerNotes(this.currentuser.id, allContactIds.join(","), 'AllContactsNotes', this.conid.toString(), "null");
        } else {
          this.GetCustomerNotes(this.currentuser.id, "0", 'AllContactsNotes', this.conid.toString(), "null");
        }

      });

    } else if (this.typ == 'Contact') {
      this.GetCustomerNotes(this.currentuser.id, this.conid.toString(), 'AllContactsNotes', '0', "null");
    }
    else if (this.typ == 'Project') {
      this.allnotes = [];
      this.customerService.GetAllNotesList(this.currentuser.id, this.conid.toString(), this.typ).then((notes) => {
        notes.forEach(note => {
          note.createdtime = this.datePipe.transform(note.created, "h:mm a")
          note.created = this.datePipe.transform(note.created, "MM-dd-yyyy");
      
        });
        this.allnotes = notes;
        for (let note of this.allnotes) {
          if (note.notedescription) {
            note.notedescriptionhtml = note.notedescription;
            note.notedescription = note.notedescription.replace(/<[^>]*>|&nbsp;/g, '');
          }
          if (note.isdocumentid != null && note.isdocumentid != "") {
            note.docList = note.isdocumentid.split(",");
          }
        }
        this.initializeAgGridNotes("");
        this.NotesBlockView = false;

      });
    }

   
  }

  UpdateNote(val) {
    this.noteidDiv = false;
    this.notes.type = this.selectedNotes.notestype;
    this.notes.isnewnote = 0
    this.notes.isdocumentid = this.isdocumentid;
    this.notes.id = this.selectedNotes.id;
    this.notes.userid = this.currentuser.id;
    this.notes.notedescription = this.selectedNotes.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/img, "");
    this.notes.notedescription = this.selectedNotes.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/gi, "");
    this.notes.isDelink = val;
    if (this.notes.notedescription != undefined && this.notes.notedescription != "") {
      this.customerService.UpdateNotes(this.notes).then(result => {
        this.toastr.success("Notes saved successfully.", "Notes Saved", { timeOut: 600 });
        this.Notes("Display All");
        $('.fr-element').empty();
        $('.fr-element').html("");
        
      });
    }


  }
  saveEditedNote() {

  }
}

