<div class="col-xs-12" style="height: 100%; margin-top: 0%; text-align: left; width: 100%; padding: 0%; text-transform: uppercase;">
 
  <div [ngClass]="AddreleaseNotesDiv?'col-xs-12':'col-xs-6'" style="padding:0;height:100%;border-right:1px solid gray" *ngIf="releaseNotesDiv">
    <div class="col-xs-12" style="height: 100%; text-align: left; height: 6%;">
      <img src="../../../assets/img/Add Campaign.png" style="height:30px;float:left;margin-top:1%"
           (click)="applicationComponent.selectedbuttonText='Release Notes/Create New';AddNewReleaseNotes()" />
    </div>
    <div class="col-xs-12 scroll-c" *ngIf="!AddreleaseNotesDiv" style="height:90%;background-color:white;overflow:auto">
      <div class="releasebuttons col-xs-12" id="release{{releasenote.releaseNoteId}}" style="padding:0;height:15%;margin-top:1%" *ngFor="let releasenote of releasenotesArray">
          <div class="col-xs-12" style="padding: 0; line-height: 18px">
            <label>{{releasenote.createdDate| date: 'MM-dd-yyyy'}} | {{releasenote.versionNumber}}</label><span style="padding-left: 5%" (click)="EditNotes(releasenote.releaseNoteId)" *ngIf="currentuser.releaseNoteAddEdit > 0">
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </span>
          </div>
          <div class="col-xs-12" style="padding: 0; line-height: 18px">
            <label>{{releasenote.Subject  | slice:0:100}}</label>
          </div>
          <div class="col-xs-12" style="padding: 0; line-height: 18px">
            <label style="color:#337ab7; font-size: 12px" (click)="ReadMore(releasenote)"> READ MORE</label>
          </div>
      </div>
    </div>


    <div class="col-xs-12" id="AddreleaseNotesDiv" *ngIf="AddreleaseNotesDiv">
      <form #notesform="ngForm" style="height:100%">
        <div class="col-xs-12" style="padding:0;height:10%">
          <div class="col-xs-3" style="padding:1%;">

            <div class="col-xs-4" style="padding:2% 0 0 2%">
              <span>Select Date:</span>
            </div>
            <div class="col-xs-8" style="padding:0;">
              <input type="date" class="form-control" [(ngModel)]="releaseNotesmodel.createdDate" name="createdDate" />
            </div>
          </div>
          <div class="col-xs-3" style="padding:1%;">
            <div class="col-xs-6" style="padding:2% 0 0 2%">
              <span>Software Version:</span>
            </div>
            <div class="col-xs-6" style="padding:0;">
              <input type="text" class="form-control" name="firstname" [(ngModel)]="releaseNotesmodel.versionNumber" placeholder="Enter Version Number" required />
            </div>

          </div>

          <div class="col-xs-6" style="padding:1%">
            <div class="col-xs-2" style="padding:1%;width:15%"> Subject : </div>
            <div class="col-xs-9" style="padding:0;"> <input type="text" name="subject" [(ngModel)]="releaseNotesmodel.Subject" class="form-control" required /></div>
            <div class="col-xs-1" *ngIf="releaseNotesmodel.releaseNoteId" style="float:right">
              <span style="float:right" (click)="delete(releaseNotesmodel.releaseNoteId)">
                <i class="fa fa-trash" aria-hidden="true" style="font-size:20px;"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="col-xs-12" style="padding:0 1% 1% 1%;height:78%">
          <textarea id="releasenotes" rows="10" class="form-control" type="text" name="releaseNoteDescription" style="height:100%"
                    [(ngModel)]="releaseNotesmodel.releaseNotes" required></textarea>
        </div>
        <div class="col-xs-4 pull-right" style="padding:0 2% 0 0;height:10%">
          <div class="col-xs-6" style="margin-top:10px;padding-left: 1%;padding-right: 0;" id="Cancel">
            <button class="btn displayButton" id="canbtn" (click)="cancelReleaseNotes()" style="width:99.5%;border:1px solid black">Cancel</button>
          </div>
          <div class="col-xs-6" id="Save" style="margin-top:10px;padding:0">
            <button class="btn saveButton" style="width:100%;background: rgb(186,219,152);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="SaveReleaseNotes()" [disabled]="(!notesform.form.valid)">Save</button>

          </div>
        </div>
      </form>
    </div>
  
  </div>
  <div class="col-xs-6" style="padding:0;height:100%" *ngIf="ReadMoreDiv">
    <div class="col-xs-12" style="padding:0;height:100%;margin-top:1%" *ngIf="selectedNote != null">
      <div class="col-xs-12" style="height:6%;margin-top:0.5%;padding:0 1% 0 1%">
        <b style="font-size:18px !important;">{{selectedNote.createdDate| date: 'MM-dd-yyyy'}} </b>
      </div>
      <div class="col-xs-12" style="height:6%;margin-top:0.5%;padding:0 1% 0 1%">
        <b style="font-size:18px !important;"> {{selectedNote.versionNumber}}</b>
      </div>
      <div class="col-xs-12" style="height:6%;margin-top:0.5%;padding:0 1% 0 1%">
        <b style="font-size:18px !important;"> {{selectedNote.Subject}} </b>
      </div>
      <div>

      </div>
      <div class="col-xs-12 scroll-c" style="padding:0 1% 1% 1%;height:91%;overflow:auto;font-size:16px!important; margin-top:2% !important">
        <span [innerHTML]="selectedNote.releaseNotes"></span>
        <label style="font-size:16px;padding: 0 0 0 1%;" (click)="Return('releasenote')"> Return To Release Notes</label>
      </div>
    </div>
  </div>
</div>
