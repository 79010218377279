
<div *ngIf="this.id!=0 && !BTClick" class="col-xs-12" [style.height]="!selectorTag?'99%': '99%'" style="background: white;padding: 0;  ">

  <div style="padding:0%;height:100%" id="noSelectSecondDiv" [ngClass]="(taskDialog)? 'col-xs-12':'col-xs-12'">
    <div class="col-xs-12" id="Opptdetails" style="padding-left: 0% !important;padding-right:0;height:100%" [hidden]="labelButton">
      <form #taskhistoryform="ngForm" style="height:100%">

        <div class="col-xs-12" style="padding:0;height:100%;padding-top:0%" *ngIf="this.TaskId > 0">
               <div class="col-xs-12 mainactiveButton1" style="height: 2%;background-color:orangered" >

               </div>
              <div class="col-xs-12 mainactiveButton1" style="margin-bottom: 0%; padding: 0; text-align: left;
height: 4%;border: 1px solid rgb(186,219,152); width: 99.7%" [hidden]="labelButton">
                <label id="sid" style="padding-left: 14px;">EDIT TASK</label>
                <span (click)="close()" > <img src="../../../assets/img/Black x.png" style="height:22px;float:right" /></span>
              </div>
           
          <div class="col-xs-12" style="margin-top: 0%; padding: 0% 0% 0 0%; height: 22%">
            <div class="col-xs-1" style="padding-right:0;width:10%">
            </div>
            <div class="col-xs-11" style="padding-right:0;width:90%">
              <account-details [viewaccount]="viewaccount" [account]="account" [tab]="''" [noteslist]="''" [pageFrom]="'ScheduleTask'" [ViewData]="(this.type == 'Customer' || this.type == 'Accounts') ? 'Account' : 'Contact'" [contact]="contact" (btnClickEvent)="btnClickReceived($event)"></account-details>
            </div>
          </div>
          <div *ngIf="TaskId != 0" class="col-xs-1" style="padding-right: 0; width: 12%; font-size: 11px;">
            <label>Details </label>
          </div>
          <div *ngIf="TaskId != 0" class="col-xs-10" style="padding: 0; width:86%; border: 1px solid gray">
            <div class="col-xs-11">
              <div class="col-xs-12" style="padding: 0% 0% 0 0%" [ngStyle]="{'height.%':TaskId == 0 ? 5 : 2.5 }">
                <div class="col-xs-2" style="padding:0;width:15%;font-size:11px;">
                  <label> Group : </label>
                </div>
                <div *ngIf="TaskId != 0" class="col-xs-10" style="padding-right: 0; width: 85%; font-size: 11px">
                  <label>{{(taskhistory.taskGroupID == 2)? 'Trainer' :  'Sales' }}</label>
                </div>

              </div>

              <div class="col-xs-12" style="margin-top: 0.5%; padding: 0% 0% 0 0%" [ngStyle]="{'height.%':TaskId == 0 ? 5 : 2.5 }">
                <div class="col-xs-2" style="padding-left: 0;width:15%;font-size:11px;">
                  <label>Task : </label>
                </div>
                <div *ngIf="TaskId != 0" class="col-xs-10" style="padding-right: 0; width: 85%; font-size: 11px">
                  <label>{{taskhistory.taskcategory}}</label>
                </div>

              </div>

              <div class="col-xs-12" style="margin-top: 0.5%; padding: 0% 0% 0 0%" [ngStyle]="{'height.%':TaskId == 0 ? 5 : 2.5 }">
                <div class="col-xs-2" style="padding:0;width:15%;font-size:11px;">
                  <label>Type : </label>
                </div>
                <div *ngIf="TaskId != 0" class="col-xs-10" style="padding-right: 0; width: 85%; font-size: 11px;">
                  <label>{{taskhistory.tasktype}}</label>
                </div>

              </div>

              <div class="col-xs-12 " style="margin-top: 0.5%; padding: 0% 0% 0 0%" [ngStyle]="{'height.%':TaskId == 0 ? 5 : 2.5 }">
                <div class="col-xs-2" style="padding:0;width:15%;font-size:11px;">
                  <label>Contact : </label>
                </div>
                <div *ngIf="TaskId != 0" class="col-xs-10" style="padding-right: 0; width: 85%; font-size: 11px;">
                  <label>{{taskhistory.contactName}}</label>
                </div>

              </div>

              <div class="col-xs-12" style="padding: 0% 0% 0 0%; height: 66%" *ngIf="ShowSlotsDiv">
                <div class="col-xs-12" style="margin-top: 0.5%;padding: 0%;height:8%">
                  <div class="col-xs-1" style="padding:2% 0 0 0;width:10%;font-size:11px;">
                    <label>Search</label>
                  </div>
                  <div class="col-xs-11" style="padding-right:0;width:90%">
                    <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">

                      <select class="form-control" name="TimeSlot" required [(ngModel)]="TimeSlot" (change)="Filters($event,'TimeSlot');handleClick($event);">
                        <option value="null">Select Task</option>
                        <option value="{{item.value}}" *ngFor="let item of TimeSlots" [disabled]="(item.isdeleted == 1)? true : false">{{item.itemName}}</option>

                      </select>
                    </div>
                    <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                      <select class="form-control" name="Meridian" required [(ngModel)]="Meridian" (change)="Filters($event,'Meridian');handleClick($event);">
                        <option value="null">Select</option>
                        <option value="0">Morning/Afternoon</option>
                        <option value="1">Mornings</option>
                        <option value="2">Afternoons</option>

                      </select>
                    </div>
                  </div>

                </div>


                <div class="col-xs-12" style="margin-top: 0.5%;padding: 0%;height:8%">
                  <div class="col-xs-1" style="padding:0.5% 0 0 0;width:15%;font-size:12px;">

                  </div>
                  <div class="col-xs-11" style="padding-right:0;width:90%">
                    <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                      <select class="form-control" name="TimeLine" required [(ngModel)]="TimeLine" (change)="Filters($event,'TimeLine');handleClick($event);">
                        <option value="null">Select</option>
                        <option value="{{item.value}}" *ngFor="let item of TimeLines">{{item.itemName}}</option>

                      </select>
                    </div>
                    <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                      <select class="form-control" name="OpenBookedTimes" required [(ngModel)]="OpenBookedTimes" (change)="Filters($event,'TimeLine');handleClick($event);">
                        <option value="null">Select</option>
                        <option value="Open Times" selected>Open Times</option>
                        <option value="Booked Times">Booked Times</option>
                        <option value="Open/Booked Times">Open/Booked Times</option>

                      </select>
                    </div>


                  </div>

                </div>
                <div class="col-xs-12" style="padding:0;height:3%"></div>
                <div class="col-xs-12" style="padding:0;height:9%">
                  <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                    M <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Monday == true" (click)="DaysFilter.Monday = false;Filters('','')">
                    <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Monday == false" (click)="DaysFilter.Monday = true;Filters('','')">&nbsp;
                  </div>
                  <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                    T <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Tuesday == true" (click)="DaysFilter.Tuesday = false;Filters('','')">
                    <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Tuesday == false" (click)="DaysFilter.Tuesday = true;Filters('','')">&nbsp;
                  </div>
                  <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                    W <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Wednesday == true" (click)="DaysFilter.Wednesday = false;Filters('','')" />
                    <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Wednesday == false" (click)="DaysFilter.Wednesday = true;Filters('','')">&nbsp;
                  </div>
                  <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                    T <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Thursday == true" (click)="DaysFilter.Thursday = false;Filters('','')">
                    <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Thursday == false" (click)="DaysFilter.Thursday = true;Filters('','')">&nbsp;
                  </div>
                  <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                    F <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Friday == true" (click)="DaysFilter.Friday = false;Filters('','')">
                    <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Friday == false" (click)="DaysFilter.Friday = true;Filters('','')">&nbsp;
                  </div>
                  <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                    S <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Saturday == true" (click)="DaysFilter.Saturday = false;Filters('','')">
                    <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Saturday == false" (click)="DaysFilter.Saturday = true;Filters('','')">&nbsp;
                  </div>
                  <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                    S <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Sunday == true" (click)="DaysFilter.Sunday = false;Filters('','')">
                    <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Sunday == false" (click)="DaysFilter.Sunday = true;Filters('','')">&nbsp;
                  </div>

                </div>
                <div class="col-xs-12" style="padding:0;height:70%">
                  <div class="col-xs-12 scroll-c" style="padding:0;height:100%">

                    <div class="col-xs-12" style="padding:0;height:100%" id="timeSlotsBooking">
                      <div class="col-xs-12" style="padding:0;width:95%">
                        <div class="col-xs-2" style="width:25%">
                          <div class="col-xs-1" style="padding:0.5% 0 0 0;width:10%;font-size:12px;">
                            <img src="../../assets/img/Load More Results.png" style="height: 22px; margin-left: 7%;" (click)="addMoreAppointments()">
                          </div>
                        </div>
                        <div class="col-xs-2" style="width:20%; padding: 0 0 0 6%"> </div>
                        <div class="col-xs-2" style="width: 20%; padding: 0 0 0 7%">Day  </div>
                        <div class="col-xs-2" style="width:15%;padding:0;">Date</div>
                        <div class="col-xs-2" style="width: 15%; padding: 0;">Time</div>
                        <div class="col-xs-2" style="width: 15%; padding: 0;">To</div>
                      </div>
                      <div class="col-xs-12" style="padding:0;" *ngFor="let item of TimeIntervals">
                        <div class="col-xs-12" style="padding:0;">
                          <div class="col-xs-2" style="width: 20%; padding: 1%">
                            <button class="btn saveButton" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;padding:1%;height:100%;font-size:11px;" (click)="ViewScheduleTaskConfirm(item)" *ngIf="item.Booked == false" [disabled]="item.hidden"> Select Time</button>
                            <button class="btn saveButton" style="width:100%;background: white;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;padding:1%;height:100%;font-size:11px;" *ngIf="item.Booked == true" (mouseenter)="BookedClick($event,item.BookingId)" (mouseleave)="MouseLeave()">Booked</button>
                          </div>
                          <div class="col-xs-2" style="width: 20%; padding:1% 0 0 6% ">{{item.WeekDay}}</div>
                          <div class="col-xs-2" style="width: 15%; padding: 1% 0 0 0%; ">{{item.Date}}</div>
                          <div class="col-xs-2" style="width: 15%; padding: 1% 0 0 0%; ">{{item.Time}} {{item.AMPM | lowercase}}</div>
                          <div class="col-xs-2" style="width: 15%; padding: 1% 0 0 0%; ">{{item.To}} {{item.ToAMPM | lowercase}} </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="!ShowSlotsDiv && TaskId != 0" class="col-xs-12" style="margin-top: 0.5%; padding: 0% 0% 0 0%; height: 5%">
                <div class="col-xs-12" style="padding:0">
                  <div class="col-xs-2" style="padding: 0; width: 15%; font-size: 11px;">
                    <label>Date : </label>
                  </div>
                  <div class="col-xs-10" style="padding-right: 0; width: 85%; font-size: 11px">
                    {{taskhistory.estcompleteddate | date:"MM-dd-yyyy"}}
                  </div>
                </div>
                <div class="col-xs-12" style="padding:0">
                  <div class="col-xs-2" style="padding:0;width:15%;font-size:11px;">
                    <label>Time</label>
                  </div>
                  <div class="col-xs-10" style="padding-right: 0; width: 85%; font-size: 11px">
                    {{taskhistory.time}}-{{taskhistory.totime}}
                  </div>
                </div>

              </div>
            </div>
            <div class="col-xs-1" style="padding: 0px;">
              <img src="../../../assets/img/Go To Appt Icon.png" (click)="OpenAptwarning()" style="height:45px;" />
            </div>
          </div>

          <div *ngIf="TaskId == 0" class="col-xs-12" style="padding:0">
            <div class="col-xs-12" style="padding: 0% 0% 0 0%" [ngStyle]="{'height.%':TaskId == 0 ? 5 : 2.5 }">
              <div class="col-xs-2" style="padding:0;width:10%;font-size:11px;">
                <label>  Group : </label>
              </div>
              <div class="col-xs-10" style="padding-right:0;width:90%">
                <select class="form-control" name="taskhistory.taskGroupID" (change)="completeForm();handleClick($event);TaskGroupChanged($event)"
                        [(ngModel)]="taskhistory.taskGroupID" required>
                  <option value="null">Select Task Group</option>
                  <option *ngFor="let task of this.currentuser.SelectedTaskGroupids" value={{task.id}}>
                    {{task.itemName}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 0.5%; padding: 0% 0% 0 0%;" [ngStyle]="{'height.%':TaskId == 0 ? 5 : 2.5 }">
              <div class="col-xs-2" style="padding-left: 0;width:10%;font-size:11px;">
                <label>Task : </label>
              </div>

              <div class="col-xs-10" style="padding-right:0;width:90%">
                <div class="col-xs-3" style="padding:0;padding-right:1%">
                  <button class="btn changeActive" style="width: 100%; height: 33px; padding-top: 3px; border: 1px solid lightgrey !important;" id="todo" (click)="(this.TaskId == 0)?ActiveTask('todo',0 ,0):'';handleClick($event);"><span id="todo_span" class="mainCheckMark actionToDo"></span>To Do</button>
                </div>
                <div class="col-xs-3" style="padding:0; padding-right:1%">
                  <button class="btn changeActive" style="width: 100%; height: 33px; padding-top: 3px; border: 1px solid lightgrey !important; " id="phonecall" [style.padding-left]="selectorTag?'5px':'8px'" (click)="(this.TaskId ==0)?ActiveTask('phonecall',0,0):'';handleClick($event);"><span id="phonecall_span" class="mainCheckMark actionToDo"></span>Phone Call</button>
                </div>
                <div class="col-xs-3" style="padding:0;">
                  <button class="btn changeActive" style="width: 100%; height: 33px; padding-top: 3px; border: 1px solid lightgrey !important; " id="meeting" (click)="(this.TaskId == 0)?ActiveTask('meeting',0,0):'';handleClick($event);"><span id="meeting_span" class="mainCheckMark actionToDo"></span>Meeting</button>
                </div>
                <div class="col-xs-3" style="padding:0;">
                  <button class="btn changeActive" style="width: 100%; height: 33px; padding-top: 3px; border: 1px solid lightgrey !important; " id="BlockTime" (click)="(this.TaskId == 0)?ActiveTask('BlockTime',0,0):'';handleClick($event);"><span id="BlockTime_span" class="mainCheckMark actionToDo"></span>Block Time</button>
                </div>

              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 0.5%; padding: 0% 0% 0 0%" [ngStyle]="{'height.%':TaskId == 0 ? 5 : 2.5 }">
              <div class="col-xs-2" style="padding:0;width:10%;font-size:11px;">
                <label>Type : </label>
              </div>
              <div class="col-xs-10" style="padding-right:0;width:90%">
                <select class="form-control" id="sel1" name="tasktype" [(ngModel)]="taskhistory.tasktypeid" (change)="completeForm();handleClick($event);ChooseTaskType($event.target.value)" required>
                  <option *ngFor="let task of Tasktypelist" value={{task.id}}>
                    {{task.itemName}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-xs-12 " style="margin-top: 0.5%; padding: 0% 0% 0 0%;" [ngStyle]="{'height.%':TaskId == 0 ? 5 : 2.5 }">
              <div class="col-xs-2" style="padding:0;width:10%;font-size:11px;">
                <label>Contact : </label>
              </div>
              <div class="col-xs-10" style="padding-right:0;width:90%">
                <select class="form-control" id="ContactList" name="ContactList" [(ngModel)]="taskhistory.contactid" required (change)="ContactSelected();handleClick($event);">
                  <option value="null" disabled [selected]="true">Select Contact</option>
                  <option *ngFor="let conatct of  contactList" value={{conatct.id}} selected>
                    {{conatct.itemName}} <span *ngIf="conatct.category">- {{conatct.category}}</span>
                  </option>
                </select>
              </div>
            </div>

            <div class="col-xs-12" style="padding: 0% 0% 0 0%; height: 66%" *ngIf="ShowSlotsDiv">
              <div class="col-xs-12" style="margin-top: 0.5%;padding: 0%;height:8%">
                <div class="col-xs-2" style="padding:2% 0 0 0;width:10%;font-size:11px;">
                  <label>Search</label>
                </div>
                <div class="col-xs-10" style="padding-right:0;width:90%">
                  <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">

                    <select class="form-control" name="TimeSlot" required [(ngModel)]="TimeSlot" (change)="Filters($event,'TimeSlot');handleClick($event);">
                      <option value="null">Select Task</option>
                      <option value="{{item.value}}" *ngFor="let item of TimeSlots" [disabled]="(item.isdeleted == 1)? true : false">{{item.itemName}}</option>

                    </select>
                  </div>
                  <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                    <select class="form-control" name="Meridian" required [(ngModel)]="Meridian" (change)="Filters($event,'Meridian');handleClick($event);">
                      <option value="null">Select</option>
                      <option value="0">Morning/Afternoon</option>
                      <option value="1">Mornings</option>
                      <option value="2">Afternoons</option>

                    </select>
                  </div>
                </div>

              </div>


              <div class="col-xs-12" style="margin-top: 0.5%;padding: 0%;height:8%">
                <div class="col-xs-1" style="padding:0.5% 0 0 0;width:10%;font-size:12px;">

                </div>
                <div class="col-xs-11" style="padding-right:0;width:90%">
                  <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                    <select class="form-control" name="TimeLine" required [(ngModel)]="TimeLine" (change)="Filters($event,'TimeLine');handleClick($event);">
                      <option value="null">Select</option>
                      <option value="{{item.value}}" *ngFor="let item of TimeLines">{{item.itemName}}</option>

                    </select>
                  </div>
                  <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                    <select class="form-control" name="OpenBookedTimes" required [(ngModel)]="OpenBookedTimes" (change)="Filters($event,'TimeLine');handleClick($event);">
                      <option value="null">Select</option>
                      <option value="Open Times" selected>Open Times</option>
                      <option value="Booked Times">Booked Times</option>
                      <option value="Open/Booked Times">Open/Booked Times</option>

                    </select>
                  </div>


                </div>

              </div>
              <div class="col-xs-12" style="padding:0;height:3%"></div>
              <div class="col-xs-12" style="padding:0;height:9%">
                <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                  M <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Monday == true" (click)="DaysFilter.Monday = false;Filters('','')">
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Monday == false" (click)="DaysFilter.Monday = true;Filters('','')">&nbsp;
                </div>
                <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                  T <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Tuesday == true" (click)="DaysFilter.Tuesday = false;Filters('','')">
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Tuesday == false" (click)="DaysFilter.Tuesday = true;Filters('','')">&nbsp;
                </div>
                <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                  W <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Wednesday == true" (click)="DaysFilter.Wednesday = false;Filters('','')" />
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Wednesday == false" (click)="DaysFilter.Wednesday = true;Filters('','')">&nbsp;
                </div>
                <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                  T <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Thursday == true" (click)="DaysFilter.Thursday = false;Filters('','')">
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Thursday == false" (click)="DaysFilter.Thursday = true;Filters('','')">&nbsp;
                </div>
                <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                  F <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Friday == true" (click)="DaysFilter.Friday = false;Filters('','')">
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Friday == false" (click)="DaysFilter.Friday = true;Filters('','')">&nbsp;
                </div>
                <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                  S <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Saturday == true" (click)="DaysFilter.Saturday = false;Filters('','')">
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Saturday == false" (click)="DaysFilter.Saturday = true;Filters('','')">&nbsp;
                </div>
                <div class="col-xs-1" style="padding:0;width:14%;height:100%">
                  S <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Sunday == true" (click)="DaysFilter.Sunday = false;Filters('','')">
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Sunday == false" (click)="DaysFilter.Sunday = true;Filters('','')">&nbsp;
                </div>

              </div>
              <div class="col-xs-12" style="padding:0;height:70%">
                <div class="col-xs-12 scroll-c" style="padding:0;height:100%">

                  <div class="col-xs-12" style="padding:0;height:100%" id="timeSlotsBooking">
                    <div class="col-xs-12" style="padding:0;width:95%">
                      <div class="col-xs-2" style="width:25%">
                        <div class="col-xs-1" style="padding:0.5% 0 0 0;width:10%;font-size:12px;">
                          <img src="../../assets/img/Load More Results.png" style="height: 22px; margin-left: 7%;" (click)="addMoreAppointments()">
                        </div>
                      </div>
                      <div class="col-xs-2" style="width:20%; padding: 0 0 0 6%"> </div>
                      <div class="col-xs-2" style="width: 20%; padding: 0 0 0 7%">Day  </div>
                      <div class="col-xs-2" style="width:15%;padding:0;">Date</div>
                      <div class="col-xs-2" style="width: 15%; padding: 0;">Time</div>
                      <div class="col-xs-2" style="width: 15%; padding: 0;">To</div>
                    </div>
                    <div class="col-xs-12" style="padding:0;" *ngFor="let item of TimeIntervals">
                      <div class="col-xs-12" style="padding:0;">
                        <div class="col-xs-2" style="width: 20%; padding: 1%">
                          <button class="btn saveButton" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;padding:1%;height:100%;font-size:11px;" (click)="ViewScheduleTaskConfirm(item)" *ngIf="item.Booked == false" [disabled]="item.hidden"> Select Time</button>
                          <button class="btn saveButton" style="width:100%;background: white;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;padding:1%;height:100%;font-size:11px;" *ngIf="item.Booked == true" (mouseenter)="BookedClick($event,item.BookingId)" (mouseleave)="MouseLeave()">Booked</button>
                        </div>
                        <div class="col-xs-2" style="width: 20%; padding:1% 0 0 6% ">{{item.WeekDay}}</div>
                        <div class="col-xs-2" style="width: 15%; padding: 1% 0 0 0%; ">{{item.Date}}</div>
                        <div class="col-xs-2" style="width: 15%; padding: 1% 0 0 0%; ">{{item.Time}} {{item.AMPM | lowercase}}</div>
                        <div class="col-xs-2" style="width: 15%; padding: 1% 0 0 0%; ">{{item.To}} {{item.ToAMPM | lowercase}} </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!ShowSlotsDiv" class="col-xs-12" style="margin-top: 0.5%; padding: 0% 2% 0 2%; height: 5%">

              <div class="col-xs-2" style="padding:0;width:10%;font-size:11px;">
                <label>Date</label>
              </div>
              <div class="col-xs-3" style="padding-right:0;width:28%">
                {{taskhistory.estcompleteddate}}
              </div>

              <div class="col-xs-7" style="padding:0;width:56%">
                <div class="col-xs-7" style="padding:0;">
                  <div class="col-xs-4" style="padding:4% 0 0 2%;font-size:13px;text-align:center">
                    <label>Time </label>
                  </div>

                </div>
                <div class="col-xs-5" style="padding:0 0 0 3%;">

                  <div class="col-xs-12" style="padding:0;" *ngIf="TaskId == 0">
                    <popover-content #EndTime title="Select Time" [animation]="true" placement="bottom" size="small" [closeOnClickOutside]="true">
                      <ngx-timepicker-field [(ngModel)]="taskhistory.totime" name="totime" [controlOnly]="true" (ngModelChange)="getEndTime($event);handleClick($event);"></ngx-timepicker-field>
                    </popover-content>

                    <div class="col-xs-12 form-control" style="padding:0;border:1px solid #d2d6de; border-radius: 3px;">
                      <div class="col-xs-9" style="padding:0;">
                        <input class="form-control" [popover]="EndTime" [popoverCloseOnMouseOutside]="true" [popoverOnHover]="false" name="totime" [(ngModel)]="taskhistory.totime" style="height:32px !important;padding:0;font-size:11px" value="{{taskhistory.totime}}" id="totime" readonly />
                      </div>
                      <div class="col-xs-3" style="padding:0;"><i class="fa fa-clock-o" aria-hidden="true" [popover]="EndTime" [popoverCloseOnMouseOutside]="true" [popoverOnHover]="false" popoverPlacement="bottom-right" style="padding:20%;font-size:20px;"></i></div>

                    </div>
                  </div>
                </div>


              </div>
              <div class="col-xs-1" style="padding:1%;width:4%;text-align:center"> <label (click)="cleartime()" style="padding:0;text-align:center"><span> <img src="../../../assets/img/Black x.png" style="height:22px" /></span></label></div>
            </div>
          </div>

          <div class="col-xs-12" style="margin-top:1.5%; padding: 0% 2% 0 2%; height: 42%" *ngIf="!ShowSlotsDiv">
            <div *ngIf="!labelButton" class="col-xs-1" style="padding:0;width:10%;font-size:11px;">
              <label> Notes</label>
              <br /><br />
            </div>
            <div class="col-xs-11" style="padding:0;height:50%;width:90%">

              <input *ngIf="this.selectedscheduleTasktype==undefined" class="form-control" type="text" placeholder="Enter New Note" />
              <textarea placeholder="Enter New Note" *ngIf="this.selectedscheduleTasktype!=undefined" id="notesTextArea" rows="9" class="form-control" type="text" name="notedescription"
                        [(ngModel)]="taskhistory.notedescription" required style="height: 98%" (keyup)="handleClick($event);"></textarea>
            </div>
            <div *ngIf="!labelButton" class="col-xs-1" style="padding: 0; font-size: 11px; width: 10%; margin-bottom:0.5%">
              <label>Previous</label>
              <br /><br />
            </div>
            <div class="col-xs-11" style="padding: 0; height: 50%; width: 90%; background-color: #2A3740; color: white; overflow: auto">
              <div *ngFor="let note of taskhistorynotes">
                {{note.created |date:'MM-dd-yyyy'}}:  {{note.notedescription}}
              </div>
            </div>
          </div>



          <div class="col-xs-12" id="statusid" style="padding: 0% 2% 0 2%; margin-top: 1%; height: 4%" [hidden]="labelButton" *ngIf="!ShowSlotsDiv">
            <div class="col-xs-1" style="padding:0;width:10%;font-size:11px;">
              <label>Status</label>
            </div>
            <div class="col-xs-11" style="padding: 0%;width:90%">
              <div class="col-xs-12" style="padding:0;height:100%">
                <select class="form-control" name="taskGroup" (change)="selectedStatus($event);handleClick($event)" required [(ngModel)]="taskhistory.status">
                  <option value="null">Select Status</option>
                  <option *ngFor="let status of TaskStatuslist" value={{status.itemName}}>
                    {{status.itemName}}
                  </option>
                </select>
              </div>


            </div>
          </div>

          <div class="col-xs-12" style="padding: 0% 0% 0 0%; margin-top: 0.5%; height: 5%" [hidden]="labelButton" *ngIf="!ShowSlotsDiv">
            <div class="col-xs-1" style="padding:0"></div>
            <div [ngStyle]="{'margin-top.%':this.taskhistory.id == 0 ?  0 : 0 }" class="col-xs-11">
              <div class="col-xs-6" style="margin-top:10px;padding-left: 1%;padding-right: 0;" id="taskCancel">
                <button class="btn displayButton" id="canbtn" (click)="reloadSched()" style="width:99.5%">Cancel</button>
              </div>
              <div class="col-xs-6" id="taskSave" style="margin-top:10px;padding:0">

                <button *ngIf="(!fromtimevalid  || !totimevalid)" class="btn saveButton" disabled style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;">Save</button>
                <button *ngIf="(fromtimevalid && totimevalid)" class="btn saveButton" (click)="SaveTaskHistory()" [disabled]="(!taskhistoryform.form.valid || this.selectedscheduleTasktype==undefined) " style="width: 100%; background: rgb(162, 228, 119); color: black; font-weight: 700; border: 1px solid #000; border-radius: 3px;">Save</button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12" style="padding:0;height:100%;" *ngIf="this.TaskId <= 0 || this.TaskId == null ">
          <div class="col-xs-12" style="padding:0;height:100%">
            <div class="col-xs-12" style="background-color: orangered !important; height: 2%"></div>
            <div class="col-xs-12 mainactiveButton1" style="margin-bottom: 0%; padding: 0; text-align: left;height: 6%; padding-top: 2%; border: 1px solid rgb(186,219,152); width: 99.7%" [hidden]="labelButton">
              <label id="sid" style="padding-left: 14px;">SCHEDULE TASK</label>
              <span (click)="close()"> <img src="../../../assets/img/Black x.png" style="height:22px;float:right" /></span>
            </div>

            <div class="col-xs-12" style="margin-top:0%;padding:2%;height:27%">
              <account-details [viewaccount]="viewaccount" [account]="account" [tab]="''" [noteslist]="''" [pageFrom]="'ScheduleTask'" [ViewData]="(this.type == 'Customer' || this.type == 'Accounts')? 'Account' : 'Contact'" [contact]="contact" (btnClickEvent)="btnClickReceived($event)"></account-details>

            </div>
            <div class="col-xs-12" style="margin-top:0.5%;padding:0% 2% 0 2%;height:5%">
              <div class="col-xs-1" style="padding:2% 0 0 0;width:10%;font-size:11px;">
                <label> Group</label>
              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <span *ngIf="TaskId != 0">{{(taskhistory.taskGroupID == 2)? 'Trainer' :  'Sales' }} </span>
                <select *ngIf="TaskId == 0" class="form-control" name="taskGroup" (change)="completeForm();handleClick($event);TaskGroupChanged($event)" [(ngModel)]="taskhistory.taskGroupID" required>
                  <option value="null">Select Task Group</option>
                  <option *ngFor="let task of this.currentuser.SelectedTaskGroupids" value={{task.id}}>
                    {{task.itemName}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 0.5%; padding: 0% 2% 0 2%; height: 5%">
              <div class="col-xs-1" style="padding: 2% 0 0 0;width:10%;font-size:11px;">
                <label>Task</label>
              </div>
              <span *ngIf="TaskId != 0" class="col-xs-11" style="padding-right:0;width:90%">
                {{taskhistory.taskcategory}}
              </span>
              <div *ngIf="TaskId == 0" class="col-xs-11" style="padding-right:0;width:90%">
                <div class="col-xs-3" style="padding:0;padding-right:1%">
                  <button class="btn changeActive" style="width: 100%; height: 33px; padding-top: 3px; border: 1px solid lightgrey !important; " id="todo" (click)="(this.TaskId == 0)?ActiveTask('todo',0 ,0):''"><span id="todo_span" class="mainCheckMark actionToDo"></span>To Do</button>
                </div>
                <div class="col-xs-3" style="padding:0; padding-right:1%">
                  <button class="btn changeActive" style="width: 100%; height: 33px; padding-top: 3px; border: 1px solid lightgrey !important; " id="phonecall" [style.padding-left]="selectorTag?'5px':'8px'" (click)="(this.TaskId ==0)?ActiveTask('phonecall',0,0):''"><span id="phonecall_span" class="mainCheckMark actionToDo"></span>Phone Call</button>
                </div>
                <div class="col-xs-3" style="padding:0;padding-right:1%">
                  <button class="btn changeActive" style="width: 100%; height: 33px; padding-top: 3px; border: 1px solid lightgrey !important;" id="meeting" (click)="(this.TaskId == 0)?ActiveTask('meeting',0,0):''"><span id="meeting_span" class="mainCheckMark actionToDo"></span>Meeting</button>
                </div>
                <div class="col-xs-3" style="padding:0;">
                  <button class="btn changeActive" style="width: 100%; height: 33px; padding-top: 3px; border: 1px solid lightgrey !important; " id="BlockTime" (click)="(this.TaskId == 0)?ActiveTask('BlockTime',0,0):''"><span id="BlockTime_span" class="mainCheckMark actionToDo"></span></button>
                </div>
              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 0.5%; padding: 0% 2% 0 2%; height: 5%">
              <div class="col-xs-1" style="padding:2% 0 0 0;width:10%;font-size:11px;">
                <label>Type</label>
              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <span *ngIf="TaskId != 0">
                  {{taskhistory.tasktype}}
                </span>
                <select *ngIf="TaskId == 0" class="form-control" id="sel1" name="tasktype" [(ngModel)]="taskhistory.tasktypeid" (change)="completeForm();handleClick($event);ChooseTaskType($event.target.value)" required>
                  <option *ngFor="let task of Tasktypelist" value={{task.id}}>
                    {{task.itemName}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-xs-12 " style="margin-top: 0.5%; padding: 0% 2% 0 2%; height: 5%">
              <div class="col-xs-1" style="padding:2% 0 0 0;width:10%;font-size:11px;">
                <label>Contact </label>
              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <select *ngIf="TaskId == 0" class="form-control" id="ContactList" name="ContactList" [(ngModel)]="taskhistory.contactid" required (change)="ContactSelected()">
                  <option value="null" disabled [selected]="true">Select Contact</option>
                  <option *ngFor="let conatct of  contactList" value={{conatct.id}} selected>
                    {{conatct.itemName}} <span *ngIf="conatct.category">- {{conatct.category}}</span>
                  </option>
                </select>
              </div>
            </div>

            <div class="col-xs-12" style="padding: 0% 2% 0 2%; height: 5%" *ngIf="taskhistory.tasktypeid">
              <div class="col-xs-1" style="padding:2% 0 0 0;width:10%;font-size:11px;">
                <label> </label>
              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <div class="col-xs-1" style="padding:0;width:14%">
                  M <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Monday == true" (click)="DaysFilter.Monday = false;Filters('','')">
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Monday == false" (click)="DaysFilter.Monday = true;Filters('','')">&nbsp;
                </div>
                <div class="col-xs-1" style="padding:0;width:14%">
                  T <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Tuesday == true" (click)="DaysFilter.Tuesday = false;Filters('','')">
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Tuesday == false" (click)="DaysFilter.Tuesday = true;Filters('','')">&nbsp;
                </div>
                <div class="col-xs-1" style="padding:0;width:14%">
                  W <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Wednesday == true" (click)="DaysFilter.Wednesday = false;Filters('','')" />
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Wednesday == false" (click)="DaysFilter.Wednesday = true;Filters('','')">&nbsp;
                </div>
                <div class="col-xs-1" style="padding:0;width:14%">
                  T <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Thursday == true" (click)="DaysFilter.Thursday = false;Filters('','')">
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Thursday == false" (click)="DaysFilter.Thursday = true;Filters('','')">&nbsp;
                </div>
                <div class="col-xs-1" style="padding:0;width:14%">
                  F <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Friday == true" (click)="DaysFilter.Friday = false;Filters('','')">
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Friday == false" (click)="DaysFilter.Friday = true;Filters('','')">&nbsp;
                </div>
                <div class="col-xs-1" style="padding:0;width:14%">
                  S <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Saturday == true" (click)="DaysFilter.Saturday = false;Filters('','')">
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Saturday == false" (click)="DaysFilter.Saturday = true;Filters('','')">&nbsp;
                </div>
                <div class="col-xs-1" style="padding:0;width:14%">
                  S <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Sunday == true" (click)="DaysFilter.Sunday = false;Filters('','')">
                  <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Sunday == false" (click)="DaysFilter.Sunday = true;Filters('','')">&nbsp;
                </div>
              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 0.5%; padding: 0% 2% 0 2%; height: 5%" *ngIf="taskhistory.tasktypeid">
              <div class="col-xs-1" style="padding:2% 0 0 0;width:10%;font-size:11px;">
                <label>Search</label>
              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                  <select class="form-control" name="TimeSlot" required [(ngModel)]="TimeSlot" (change)="Filters($event,'TimeSlot')">
                    <option value="null">Select Task</option>
                    <option value="{{item.value}}" *ngFor="let item of TimeSlots" [disabled]="(item.isdeleted == 1)? true : false">{{item.itemName}}</option>

                  </select>
                </div>
                <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                  <select class="form-control" name="Meridian" required [(ngModel)]="Meridian" (change)="Filters($event,'Meridian')">
                    <option value="null">Select</option>
                    <option value="0">Morning/Afternoon</option>
                    <option value="1">Mornings</option>
                    <option value="2">Afternoons</option>

                  </select>
                </div>
              </div>

            </div>


            <div class="col-xs-12" style="margin-top: 0.5%; padding: 0% 2% 0 2%; height: 5%" *ngIf="taskhistory.tasktypeid">
              <div class="col-xs-1" style="padding:0.5% 0 0 0;width:10%;font-size:11px;">
                <img src="../../assets/img/Load More Results.png" style="height: 22px; margin-left: 7%;" (click)="addMoreAppointments()">
              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                  <select class="form-control" name="TimeLine" required [(ngModel)]="TimeLine" (change)="Filters($event,'TimeLine')">
                    <option value="null">Select</option>
                    <option value="{{item.value}}" *ngFor="let item of TimeLines">{{item.itemName}}</option>

                  </select>
                </div>
                <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                  <select class="form-control" name="OpenBookedTimes" required [(ngModel)]="OpenBookedTimes" (change)="Filters($event,'BookedFilter')">
                    <option value="null">Select</option>
                    <option value="Open Times" selected>Open Times</option>
                    <option value="Booked Times">Booked Times</option>
                    <option value="Open/Booked Times">Open/Booked Times</option>

                  </select>
                </div>


              </div>

            </div>
            <div class="col-xs-12" style="padding: 0% 2% 0 2%; height: 3%"></div>

            <div class="col-xs-12" style="padding: 0% 2% 0 2%; height: 35%" *ngIf="taskhistory.tasktypeid">
              <div class="col-xs-12 scroll-c" style="padding:0;" [style.height.%]="(MappedTimeSlot)? '90':'100'">
                <div class="col-xs-12" style="padding:0;height:100%" id="timeSlotsBooking">
                  <div class="col-xs-12" style="padding:0;width:95%;height:9%">
                    <div class="col-xs-2" style="width:20%">

                    </div>
                    <div class="col-xs-2" style="width: 30%; padding: 0 0 0 8%">Day</div>
                    <div class="col-xs-2" style="width: 20%; padding: 0 0 0 3%;">Date</div>
                    <div class="col-xs-2" style="width: 15%; padding: 0 0 0 3%;">Time</div>
                    <div class="col-xs-2" style="width: 15%; padding: 0 0 0 3%;">To</div>
                  </div>
                  <div class="col-xs-12" style="padding:0;" *ngFor="let item of TimeIntervals">
                    <div class="col-xs-12" style="padding:0;">
                      <div class="col-xs-2" style="width: 20%; padding: 1%">
                        <button class="btn saveButton" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;padding:1%;height:100%;font-size:11px;" (click)="ViewScheduleTaskConfirm(item)" *ngIf="item.Booked == false" [disabled]="item.hidden"> Select Time</button>
                        <button class="btn saveButton" style="width:100%;background: white;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;padding:1%;height:100%;font-size:11px;" *ngIf="item.Booked == true" (mouseenter)="BookedClick($event,item.BookingId)" (mouseleave)="MouseLeave()">Booked</button>
                      </div>
                      <div class="col-xs-2" style="width: 30%; padding:1% 0 0 6% ">{{item.WeekDay}}</div>
                      <div class="col-xs-2" style="width: 20%; padding: 1% 0 0 0%; ">{{item.Date}}</div>
                      <div class="col-xs-2" style="width: 15%; padding: 1% 0 0 0%; ">{{item.Time}} {{item.AMPM | lowercase}}</div>
                      <div class="col-xs-2" style="width: 15%; padding: 1% 0 0 0%; ">{{item.To}} {{item.ToAMPM | lowercase}} </div>

                    </div>

                  </div>
                </div>
              </div>

              <div class="col-xs-12" style="padding:0;height:10%" *ngIf="MappedTimeSlot">
                <div class="col-xs-12" style="height:100%">
                  <button class="btn saveButton" style="width:100%;background-color: Highlight;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;padding:1%;height:100%;font-size:11px;" (click)="DisplayOtherAppointments()">Display Other Appt Times Available</button>
                </div>
              </div>
            </div>

          </div>



        </div>

      </form>
    </div>

  </div>
</div>


<div *ngIf="this.id == 0 || BTClick" class="col-xs-12" [style.height]="!selectorTag?'99%': '99%'" style="background: #F4F4F4;padding: 0;  ">

  <div style="padding:0%;height:100%" id="noSelectSecondDiv" [ngClass]="(taskDialog)? 'col-xs-12':'col-xs-12'">


    <div class="col-xs-12" id="Opptdetails" style="padding-left: 0% !important;padding-right:0;height:100%" [hidden]="labelButton">
      <form #taskhistoryform="ngForm" style="height:100%">
        <div class="col-xs-12" style="padding:0;height:100%;" *ngIf="this.TaskId > 0">
          <div class="col-xs-12" style="background-color: orangered !important; height: 2%"></div>
          <div class="col-xs-12 mainactiveButton1" style="margin-bottom: 0%;height:6%; padding: 0; text-align: left; height: 6%; border: 1px solid rgb(186,219,152); width: 100%" [hidden]="labelButton">
            <label id="sid" style="padding-left: 14px;">SCHEDULE TASK</label>
            <span (click)="close()"> <img src="../../../assets/img/Black x.png" style="height:22px;float:right" /></span>
          </div>

          <div class="col-xs-12" style="margin-top:0.5%;padding:0% 2% 0 2%;" [ngStyle]="{'height.%':TaskId == 0 ? 6 : 3 }">
            <div class="col-xs-1" style="padding:0;width:12%;font-size:11px;">
              <label> Details</label>
            </div>
            <div class="col-xs-1" style="padding:0;width:8%;font-size:11px;">
              <label> Group :  </label>
            </div>
            <div *ngIf="TaskId != 0" class="col-xs-11" style="padding-right: 0; width: 80%; font-size: 11px;">
              {{(taskhistory.taskGroupID == 2)? 'Trainer' :  'Sales' }}
            </div>
            <div *ngIf="TaskId == 0" class="col-xs-11" style="padding-right:0;width:80%">
              <select id="taskGroupID" class="form-control" name="taskGroup" (change)="completeForm();handleClick($event);TaskGroupChanged($event)" [(ngModel)]="taskhistory.taskGroupID" required>
                <option value="null">Select Task Group</option>
                <option *ngFor="let task of this.currentuser.SelectedTaskGroupids" value={{task.id}}>
                  {{task.itemName}}
                </option>
              </select>
            </div>
          </div>

          <div *ngIf="taskhistory.taskcategory != 'Blocked Times'" class="col-xs-12" style="margin-top: 0.5%; padding: 0% 2% 0 2%;" [ngStyle]="{'height.%':TaskId == 0 ? 6 : 3 }">
            <div class="col-xs-1" style="padding:0;width:12%;font-size:11px;">

            </div>
            <div class="col-xs-1" style="padding-left: 0;width:8%;font-size:11px;">
              <label>Task :  </label>
            </div>
            <div class="col-xs-11" style="padding-right: 0; width: 80%; font-size: 11px;" *ngIf="TaskId != 0">
              {{taskhistory.taskcategory}}
            </div>
            <div class="col-xs-11" style="padding-right:0;width:80%" *ngIf="TaskId == 0">
              <div class="col-xs-3" style="padding:0;padding-right:1%">
                <button class="btn changeActive" style="width: 100%; height: 33px; padding-top: 3px; border: 1px solid lightgray !important;" id="todo" (click)="(this.TaskId == 0)?ActiveTask('todo',0 ,0):'';handleClick($event);"><span id="todo_span" class="mainCheckMark actionToDo"></span>To Do </button>
              </div>
              <div class="col-xs-3" style="padding:0; padding-right:1%">
                <button class="btn changeActive" style="width: 100%; height: 33px; padding-top: 3px; border: 1px solid lightgray !important;" id="phonecall" [style.padding-left]="selectorTag?'5px':'8px'" (click)="(this.TaskId ==0)?ActiveTask('phonecall',0,0):'';handleClick($event);" [disabled]="TaskId != 0"><span id="phonecall_span" class="mainCheckMark actionToDo"></span>Phone Call</button>
              </div>
              <div class="col-xs-3" style="padding:0;padding-right:1%">
                <button class="btn changeActive" style="width: 102%; height: 33px; padding-top: 3px; border: 1px solid lightgray !important;" id="meeting" (click)="(this.TaskId == 0)?ActiveTask('meeting',0,0):'';handleClick($event);" [disabled]="TaskId != 0"><span id="meeting_span" class="mainCheckMark actionToDo"></span>Meeting</button>
              </div>
              <div class="col-xs-3" style="padding:0;">
                <button class="btn changeActive" style="width: 100%; height: 33px; padding-top: 3px; border: 1px solid lightgray !important;" id="BlockTime" (click)="(this.TaskId == 0)?ActiveTask('BlockTime',0,0):'';handleClick($event);" [disabled]="TaskId != 0"><span id="BlockTime_span" class="mainCheckMark actionToDo"></span><!--Block Time--></button>
              </div>
            </div>
          </div>

          <div class="col-xs-12" style="margin-top: 0.5%; padding: 0% 2% 0 2%;" [ngStyle]="{'height.%':TaskId == 0 ? 6 : 3 }">
            <div class="col-xs-1" style="padding:0;width:12%;font-size:11px;">

            </div>
            <div class="col-xs-1" style="padding:0;width:8%;font-size:11px;">
              <label>Type : </label>
            </div>
            <div class="col-xs-11" style="padding-right: 0; width: 80%; font-size: 11px;" *ngIf="TaskId != 0">
              {{taskhistory.tasktype}}
            </div>
            <div class="col-xs-11" style="padding-right:0;width:80%" *ngIf="TaskId == 0">
              <select class="form-control" id="sel1" name="tasktype" [(ngModel)]="taskhistory.tasktypeid" (change)="completeForm();handleClick($event);ChooseTaskType($event.target.value)" required [disabled]="TaskId != 0">
                <option *ngFor="let task of Tasktypelist" value={{task.id}}>
                  {{task.itemName}}
                </option>
              </select>
            </div>
          </div>



          <div class="col-xs-12" style="padding: 0% 2% 0 2%; height: 66%" *ngIf="ShowSlotsDiv">
            <div class="col-xs-12" style="margin-top: 0.5%;padding: 0%;height:8%">
              <div class="col-xs-1" style="padding:2% 0 0 0;width:10%;font-size:11px;">

              </div>
              <div class="col-xs-1" style="padding:2% 0 0 0;width:10%;font-size:11px;">
                <label>Search</label>
              </div>
              <div class="col-xs-11" style="padding-right:0;width:80%">
                <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                  <select class="form-control" name="TimeSlot" required [(ngModel)]="TimeSlot" (change)="Filters($event,'TimeSlot');handleClick($event);">
                    <option value="null">Select Task</option>
                    <option value="{{item.value}}" *ngFor="let item of TimeSlots" [disabled]="(item.isdeleted == 1)? true : false">{{item.itemName}}</option>

                  </select>
                </div>
                <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                  <select class="form-control" name="Meridian" required [(ngModel)]="Meridian" (change)="Filters($event,'Meridian');handleClick($event);">
                    <option value="null">Select</option>
                    <option value="0">Morning/Afternoon</option>
                    <option value="1">Mornings</option>
                    <option value="2">Afternoons</option>

                  </select>
                </div>
              </div>

            </div>


            <div class="col-xs-12" style="margin-top: 0.5%;padding: 0%;height:8%">
              <div class="col-xs-1" style="padding:0.5% 0 0 0;width:10%;font-size:11px;" title="Display Addl Appt Times">
                <img src="../../assets/img/Load More Results.png" style="height: 22px; margin-left: 7%;" (click)="addMoreAppointments()">
              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                  <select class="form-control" name="TimeLine" required [(ngModel)]="TimeLine" (change)="Filters($event,'TimeLine');handleClick($event);">
                    <option value="null">Select</option>
                    <option value="{{item.value}}" *ngFor="let item of TimeLines">{{item.itemName}}</option>

                  </select>
                </div>
                <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                  <select class="form-control" name="OpenBookedTimes" required [(ngModel)]="OpenBookedTimes" (change)="Filters($event,'TimeLine');handleClick($event);">
                    <option value="null">Select</option>
                    <option value="Open Times" selected>Open Times</option>
                    <option value="Booked Times">Booked Times</option>
                    <option value="Open/Booked Times">Open/Booked Times</option>

                  </select>
                </div>


              </div>

            </div>
            <div class="col-xs-12" style="padding:0;height:3%"></div>
            <div class="col-xs-12" style="padding:0;height:8%">
              <div class="col-xs-2" style="padding:0;width:12%;height:100%"></div>
              <div class="col-xs-1" style="padding:0;width:12.6%;height:100%">
                M <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Monday == true" (click)="DaysFilter.Monday = false;Filters('','')">
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Monday == false" (click)="DaysFilter.Monday = true;Filters('','')">&nbsp;
              </div>
              <div class="col-xs-1" style="padding:0;width:12.6%;height:100%">
                T <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Tuesday == true" (click)="DaysFilter.Tuesday = false;Filters('','')">
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Tuesday == false" (click)="DaysFilter.Tuesday = true;Filters('','')">&nbsp;
              </div>
              <div class="col-xs-1" style="padding:0;width:12.6%;height:100%">
                W <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Wednesday == true" (click)="DaysFilter.Wednesday = false;Filters('','')" />
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Wednesday == false" (click)="DaysFilter.Wednesday = true;Filters('','')">&nbsp;
              </div>
              <div class="col-xs-1" style="padding:0;width:12.6%;height:100%">
                T <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Thursday == true" (click)="DaysFilter.Thursday = false;Filters('','')">
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Thursday == false" (click)="DaysFilter.Thursday = true;Filters('','')">&nbsp;
              </div>
              <div class="col-xs-1" style="padding:0;width:12.6%;height:100%">
                F <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Friday == true" (click)="DaysFilter.Friday = false;Filters('','')">
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Friday == false" (click)="DaysFilter.Friday = true;Filters('','')">&nbsp;
              </div>
              <div class="col-xs-1" style="padding:0;width:12.5%;height:100%">
                S <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Saturday == true" (click)="DaysFilter.Saturday = false;Filters('','')">
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Saturday == false" (click)="DaysFilter.Saturday = true;Filters('','')">&nbsp;
              </div>
              <div class="col-xs-1" style="padding:0;width:12.5%;height:100%">
                S <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Sunday == true" (click)="DaysFilter.Sunday = false;Filters('','')">
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Sunday == false" (click)="DaysFilter.Sunday = true;Filters('','')">&nbsp;
              </div>

            </div>
            <div class="col-xs-12" style="padding:0;height:72%">
              <div class="col-xs-12 scroll-c" style="padding:0;height:100%">

                <div class="col-xs-12" style="padding:0;height:100%" id="timeSlotsBooking">
                  <div class="col-xs-12" style="padding:0;width:95%;height:9%">
                    <div class="col-xs-2" style="width:25%">
                      <div class="col-xs-12" style="padding:0.5% 0 0 0;width:10%;font-size:11px;">

                      </div>
                    </div><div class="col-xs-2" style="width:20%;padding:0 0 0 6%">Day </div><div class="col-xs-2" style="width:20%">Date</div><div class="col-xs-2" style="width:15%">Time</div><div class="col-xs-2" style="width:15%">AM/PM</div>
                  </div>
                  <div class="col-xs-12" style="padding:0;" *ngFor="let item of TimeIntervals">
                    <div class="col-xs-12" style="padding:0;">
                      <div class="col-xs-2" style="width:20%;padding:1%">
                        <button class="btn saveButton" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;padding:1%;height:100%;font-size:11px;" (click)="ViewScheduleTaskConfirm(item)" *ngIf="item.Booked == false" [disabled]="item.hidden"> Select Time</button>
                        <button class="btn saveButton" style="width:100%;background: white;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;padding:1%;height:100%;font-size:11px;" *ngIf="item.Booked == true" (mouseenter)="BookedClick($event,item.BookingId)" (mouseleave)="MouseLeave()">Booked</button>
                      </div>
                      <div class="col-xs-2" style="width:20%;padding:0 0 0 3%">{{item.WeekDay}}</div>
                      <div class="col-xs-2" style="width:20%;padding:0;">{{item.Date}}</div>
                      <div class="col-xs-2" style="width:15%;padding:0;">{{item.Time}}</div>
                      <div class="col-xs-2" style="width:15%;">{{item.AMPM}}</div>

                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>

          <div *ngIf="!ShowSlotsDiv && TaskId != 0" class="col-xs-12" style="margin-top: 0.5%; padding: 0% 2% 0 2%; height: 5%">
            <div class="col-xs-12" style="padding:0">
              <div class="col-xs-1" style="padding:0;width:12%;font-size:11px;">

              </div>
              <div class="col-xs-1" style="padding:0;width:8%;font-size:11px;">
                <label>Date : </label>
              </div>
              <div class="col-xs-11" style="padding-right: 0; width: 80%; font-size: 11px">
                {{taskhistory.estcompleteddate |date:'MM/dd/yyyy'}}
              </div>
            </div>
            <div class="col-xs-12" style="padding:0">
              <div class="col-xs-1" style="padding:0;width:12%;font-size:11px;">
              </div>
              <div class="col-xs-1" style="padding:0;width:8%;font-size:11px;">
                <label>Time : </label>
              </div>
              <div class="col-xs-11" style="padding-right: 0; width: 80%; font-size: 11px">
                {{taskhistory.time}}-{{taskhistory.totime}}

              </div>
            </div>
            <div class="col-xs-12" style="padding:0">
              <div class="col-xs-1" style="padding:0;width:12%;font-size:11px;">

              </div>
              <div class="col-xs-11" style="padding-right: 0; width: 90%; font-size: 11px">

                <span *ngIf="taskhistory.taskcategory != 'Blocked Times'" style="font-family: Calibri; color: #00b3f7d9; font-size: 11pt; text-decoration: underline;" (click)="OpenAptwarning()"> Go To Appt</span>
              </div>
            </div>
          </div>
          <div class="col-xs-12" style="margin-top: 0.5%; padding: 0% 2% 0 2%; height: 6%" *ngIf="!ShowSlotsDiv && TaskId == 0">
            <div class="col-xs-1" style="padding:0;width:10%;font-size:11px;">
              <label> Date : </label>
            </div>
            <div class="col-xs-3" style="padding-right:0;width:28%" *ngIf="taskhistory.taskcategory != 'Blocked Times'">
              <input *ngIf="this.selectedscheduleTasktype==undefined" class="form-control" type="date" style="padding:0;font-size:11px" />
              <input *ngIf="this.selectedscheduleTasktype!=undefined" class="form-control" type="date" #mycal name="estcompleteddate" [(ngModel)]="taskhistory.estcompleteddate" placeholder="Select Date" (keyup)="completeForm();handleClick($event)" (blur)="completeForm()" (click)="ShowSlots()" id="date" required style="    padding: 0;   font-size: 11px" />

            </div>
            <div class="col-xs-7" style="padding:0;width:56%" *ngIf="taskhistory.taskcategory != 'Blocked Times'">
              <div class="col-xs-7" style="padding:0;">
                <div class="col-xs-4" style="padding:4% 0 0 2%;font-size:13px;text-align:center">
                  <label>Time :  </label>
                </div>

                <div class="col-xs-8" style="padding:1%">
                  <popover-content #StartTime title="Select Time" [animation]="true" placement="bottom" size="small" [closeOnClickOutside]="true">
                    <ngx-timepicker-field [(ngModel)]="taskhistory.time" name="time" [controlOnly]="true" (ngModelChange)="getStartTime($event);handleClick($event);"></ngx-timepicker-field>
                  </popover-content>

                  <div class="col-xs-12 form-control" style="padding:0;border:1px solid #d2d6de; border-radius: 3px;">
                    <div class="col-xs-9" style="padding:0;"><input class="form-control" [popover]="StartTime" [popoverCloseOnMouseOutside]="true" [popoverOnHover]="false" name="time" [(ngModel)]="taskhistory.time" style="height:32px !important;padding:0;font-size:11px" value="{{taskhistory.time}}" id="fromtime" readonly /></div>
                    <div class="col-xs-3" style="padding:0;"><i class="fa fa-clock-o" aria-hidden="true" [popover]="StartTime" [popoverCloseOnMouseOutside]="true" [popoverOnHover]="false" style="padding:20%;font-size:20px;"></i></div>

                  </div>

                </div>
              </div>
              <div class="col-xs-5" style="padding:0 0 0 3%;">

                <div class="col-xs-12" style="padding:0;">
                  <popover-content #EndTime title="Select Time" [animation]="true" placement="bottom" size="small" [closeOnClickOutside]="true">
                    <ngx-timepicker-field [(ngModel)]="taskhistory.totime" name="totime" [controlOnly]="true" (ngModelChange)="getEndTime($event);handleClick($event);"></ngx-timepicker-field>
                  </popover-content>

                  <div class="col-xs-12 form-control" style="padding:0;border:1px solid #d2d6de; border-radius: 3px;">
                    <div class="col-xs-9" style="padding:0;">
                      <input class="form-control" [popover]="EndTime" [popoverCloseOnMouseOutside]="true" [popoverOnHover]="false" name="totime" [(ngModel)]="taskhistory.totime" style="height:32px !important;padding:0;font-size:11px" value="{{taskhistory.totime}}" id="totime" readonly />
                    </div>
                    <div class="col-xs-3" style="padding:0;"><i class="fa fa-clock-o" aria-hidden="true" [popover]="EndTime" [popoverCloseOnMouseOutside]="true" [popoverOnHover]="false" popoverPlacement="bottom-right" style="padding:20%;font-size:20px;"></i></div>

                  </div>
                </div>
              </div>


            </div>

            <div class="col-xs-10" style="padding-right:0;" *ngIf="taskhistory.taskcategory == 'Blocked Times'">
              <div class="col-xs-3" style="padding:0%">Day</div><div class="col-xs-3">Date</div><div class="col-xs-3">Time</div><div class="col-xs-3">To</div>
              <div class="col-xs-3" style="margin-top: 1%; padding: 0%">{{taskhistory.estcompleteddate | date:'EEEE'}}</div><div class="col-xs-3" style="margin-top: 1%;">{{taskhistory.estcompleteddate | date: 'MM-dd-yyyy'}}</div><div class="col-xs-3" style="margin-top: 1%;">{{taskhistory.time | lowercase}}</div><div class="col-xs-3" style="margin-top: 1%;">{{taskhistory.totime | lowercase}}</div>
            </div>

          </div>

          <div class="col-xs-12" style="margin-top:1.5%; padding: 0% 2% 0 2%; height: 27%" *ngIf="!ShowSlotsDiv">
            <div *ngIf="!labelButton" class="col-xs-1" style="width:10%;font-size:11px;">
              <label> Notes </label>
              <br /><br />
            </div>
            <div class="col-xs-11" style="padding-right:0;height:100%;width:90%">
              <div class="" id="notesDiv" style="border: 1px solid #ddd;">

                <input *ngIf="this.selectedscheduleTasktype==undefined" class="form-control" type="text" />
                <textarea *ngIf="this.selectedscheduleTasktype!=undefined" id="notesTextArea" rows="9" class="form-control" type="text" name="notedescription" [(ngModel)]="taskhistory.notedescription" required></textarea>
              </div>
            </div>
          </div>
          <div class="col-xs-12" style="margin-top:1.0%;padding: 0% 2% 0 2%; height: 41%" *ngIf="!ShowSlotsDiv">
            <div *ngIf="!labelButton" class="col-xs-1" style="width:10%;font-size:11px;">
              <label> Previous</label>
            </div>
            <div class="col-xs-11" style="padding-right:0;height:100%;width:90%">
              <div style="height: 290px; padding-top: 5%; background-color: #2A3740; color: white; overflow: auto ">
                <div *ngFor="let note of taskhistorynotes">
                  {{note.created |date:'MM/dd/yyyy'}}:  {{note.notedescription}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12" id="statusid" style="padding: 0% 2% 0 2%; height: 5%" [hidden]="labelButton" *ngIf="!ShowSlotsDiv">
            <div class="col-xs-1" style="padding:0;width:10%;font-size:11px;">
              <label>Status : </label>
            </div>
            <div class="col-xs-11" style="padding-right: 0%;width:90%">

              <div class="col-xs-12" style="padding:0;height:100%">
                <select class="form-control" name="taskGroup" (change)="selectedStatus($event);handleClick($event)" required [(ngModel)]="taskhistory.status">
                  <option value="null">Select Status</option>
                  <option *ngFor="let status of TaskStatuslist" value={{status.itemName}}>
                    {{status.itemName}}
                  </option>
                </select>
              </div>


            </div>
          </div>

          <div class="col-xs-12" style="padding:0;margin-top:0.5%;height:6%" [hidden]="labelButton" *ngIf="!ShowSlotsDiv">
            <div class="col-xs-4" style="padding:0"></div>
            <div [ngStyle]="{'margin-top.%':this.taskhistory.id == 0 ?  0 : 0 }" class="col-xs-8" style="padding-right:0;">
              <div class="col-xs-6" style="margin-top:10px;padding-left: 1%;padding-right: 1%;" id="taskCancel">
                <button class="btn displayButton" id="canbtn" (click)="reloadSched()" style="width:99.5%">Cancel</button>
              </div>
              <div class="col-xs-6" id="taskSave" style="margin-top:10px;padding:0">

                <button *ngIf="(!fromtimevalid  || !totimevalid)" class="btn saveButton" disabled style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;">Save  </button>
                <button *ngIf="(fromtimevalid && totimevalid)" class="btn saveButton" (click)="SaveTaskHistory()" [disabled]="(!taskhistoryform.form.valid || this.selectedscheduleTasktype==undefined) " style="width:94%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;">Save </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12" style="padding:0;height:100%;" *ngIf="TaskId <= 0 || TaskId == null ">
          <div class="col-xs-12" style="padding:0;height:100%">
            <div class="col-xs-12" style="background-color: orangered !important; height: 2%"></div>
            <div class="col-xs-12 mainactiveButton1" style="margin-bottom: 0%; padding: 0; text-align: left; height: 7%; padding-top: 2%; border: 1px solid rgb(186,219,152); width: 100%" [hidden]="labelButton">
              <label *ngIf="!BTClick" id="sid" style="padding-left: 14px;">SCHEDULE TASK</label>
              <label *ngIf="BTClick" style="padding-left: 14px;">SCHEDULE BLOCKED TIME</label>
              <span (click)="close()"> <img src="../../../assets/img/Black x.png" style="height:22px;float:right" /></span>
            </div>

            <div class="col-xs-12" style="margin-top:1.5%;padding:0% 2% 0% 2%;height:6%">
              <div class="col-xs-1" style="padding:2% 0 0 0;width:10%;font-size:11px;">
                <label> Group : </label>
              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <select id="taskGroupID" class="form-control" name="taskGroup" (change)="completeForm();handleClick($event);TaskGroupChanged($event)" [(ngModel)]="taskhistory.taskGroupID" required [disabled]="TaskId != 0">
                  <option *ngFor="let task of this.currentuser.SelectedTaskGroupids" value={{task.id}}>
                    {{task.itemName}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-xs-12" *ngIf="!BTClick" style="margin-top: 0.5%; padding: 0% 2% 0% 2%; height: 6%">
              <div class="col-xs-1" style="padding: 2% 0 0 0;width:10%;font-size:11px;">
                <label>Task : </label>
              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <div class="col-xs-3" style="padding:0;padding-right:1%">
                  <button class="btn changeActive" style="width:100%;height: 33px; padding-top: 3px;" id="todo" (click)="(this.TaskId == 0)?ActiveTask('todo',0 ,0):'';handleClick($event);" [disabled]="TaskId != 0 || BTClick"><span id="todo_span" class="mainCheckMark actionToDo"></span>To Do </button>
                </div>
                <div class="col-xs-3" style="padding:0; padding-right:1%">
                  <button class="btn changeActive" style="width:100%;height: 33px; padding-top: 3px;" id="phonecall" [style.padding-left]="selectorTag?'5px':'8px'" (click)="(this.TaskId ==0)?ActiveTask('phonecall',0,0):'';handleClick($event);" [disabled]="TaskId != 0 || BTClick"><span id="phonecall_span" class="mainCheckMark actionToDo"></span>Phone Call</button>
                </div>
                <div class="col-xs-3" style="padding:0; padding-right:1%">
                  <button class="btn changeActive" style="width:102%;height: 33px; padding-top: 3px;" id="meeting" (click)="(this.TaskId == 0)?ActiveTask('meeting',0,0):'';handleClick($event);" [disabled]="TaskId != 0 || BTClick"><span id="meeting_span" class="mainCheckMark actionToDo"></span>Meeting</button>
                </div>
                <div class="col-xs-3" style="padding:0;">
                  <button class="btn changeActive" style="width:100%;height: 33px; padding-top: 3px;" id="BlockTime" (click)="(this.TaskId == 0)?ActiveTask('BlockTime',0,0):'';handleClick($event);" [disabled]="TaskId != 0"><span id="BlockTime_span" class="mainCheckMark actionToDo"></span><!--Block Time--></button>
                </div>

              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 0.5%;padding:0% 2% 0% 2%; height: 6%">
              <div class="col-xs-1" style="padding:2% 0 0 0;width:10%;font-size:11px;">
                <label>Type : </label>
              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <select class="form-control" id="sel1" name="tasktype" [(ngModel)]="taskhistory.tasktypeid" (change)="completeForm();handleClick($event);ChooseTaskType($event.target.value)" required [disabled]="TaskId != 0">
                  <option *ngFor="let task of Tasktypelist" value={{task.id}}>
                    {{task.itemName}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-xs-12 " style="margin-top: 0.5%; padding: 0% 2% 0% 2%; height: 6%" *ngIf="!BTClick">
              <div class="col-xs-1" style="padding:2% 0 0 0;width:10%;font-size:11px;">
                <label>Contact : </label>
              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <select class="form-control" id="ContactList" name="ContactList" [(ngModel)]="taskhistory.contactid" required [disabled]="TaskId != 0" (change)="ContactSelected();handleClick($event);">
                  <option value="null" disabled [selected]="true">Select Contact</option>
                  <option *ngFor="let conatct of  contactList" value={{conatct.id}} selected>
                    {{conatct.itemName}} <span *ngIf="conatct.category">- {{conatct.category}}</span>
                  </option>
                </select>
              </div>
            </div>
            <div class="col-xs-12" style="padding: 0% 2% 0% 2%; height: 6%" *ngIf="taskhistory.tasktypeid && !BTClick">
              <div class="col-xs-2" style="padding:0;width:12%;height:100%"></div>
              <div class="col-xs-1" style="padding:0;width:12.6%">
                M <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Monday == true" (click)="DaysFilter.Monday = false;Filters('','')">
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Monday == false" (click)="DaysFilter.Monday = true;Filters('','')">&nbsp;
              </div>
              <div class="col-xs-1" style="padding:0;width:12.6%">
                T <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Tuesday == true" (click)="DaysFilter.Tuesday = false;Filters('','')">
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Tuesday == false" (click)="DaysFilter.Tuesday = true;Filters('','')">&nbsp;
              </div>
              <div class="col-xs-1" style="padding:0;width:12.6%">
                W <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Wednesday == true" (click)="DaysFilter.Wednesday = false;Filters('','')" />
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Wednesday == false" (click)="DaysFilter.Wednesday = true;Filters('','')">&nbsp;
              </div>
              <div class="col-xs-1" style="padding:0;width:12.6%">
                T <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Thursday == true" (click)="DaysFilter.Thursday = false;Filters('','')">
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Thursday == false" (click)="DaysFilter.Thursday = true;Filters('','')">&nbsp;
              </div>
              <div class="col-xs-1" style="padding:0;width:12.6%">
                F <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Friday == true" (click)="DaysFilter.Friday = false;Filters('','')">
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Friday == false" (click)="DaysFilter.Friday = true;Filters('','')">&nbsp;
              </div>
              <div class="col-xs-1" style="padding:0;width:12.5%">
                S <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Saturday == true" (click)="DaysFilter.Saturday = false;Filters('','')">
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Saturday == false" (click)="DaysFilter.Saturday = true;Filters('','')">&nbsp;
              </div>
              <div class="col-xs-1" style="padding:0;width:12.5%">
                S <img src="../../assets/img/range on.png" style="height:37px;" *ngIf="DaysFilter.Sunday == true" (click)="DaysFilter.Sunday = false;Filters('','')">
                <img src="../../assets/img/range off.png" style="height:37px;" *ngIf="DaysFilter.Sunday == false" (click)="DaysFilter.Sunday = true;Filters('','')">&nbsp;
              </div>

            </div>
            <div class="col-xs-12" style="margin-top: 0.5%; padding: 0% 2% 0% 2%; height: 6%" *ngIf="taskhistory.tasktypeid">
              <div class="col-xs-1" style="padding:2% 0 0 0;width:10%;font-size:11px;">
                <label *ngIf="!BTClick">Search</label>
                <label *ngIf="BTClick">Duration:</label>
              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <div [ngClass]="(BTClick)? 'col-xs-12':'col-xs-6'" style="padding: 0 0.5% 0 0.5% ">
                  <select class="form-control" name="TimeSlot" required [(ngModel)]="TimeSlot" (change)="Filters($event,'TimeSlot');handleClick($event);">
                    <option value="null">Select Task</option>
                    <option value="{{item.value}}" *ngFor="let item of TimeSlots" [disabled]="(item.isdeleted == 1)? true : false">{{item.itemName}}</option>

                  </select>
                </div>
                <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% " *ngIf="!BTClick">
                  <select class="form-control" name="Meridian" required [(ngModel)]="Meridian" (change)="Filters($event,'Meridian');handleClick($event);">
                    <option value="null">Select</option>
                    <option value="0">Morning/Afternoon</option>
                    <option value="1">Mornings</option>
                    <option value="2">Afternoons</option>

                  </select>
                </div>
              </div>

            </div>


            <div class="col-xs-12" style="margin-top: 0.5%; padding: 0% 2% 0% 2%; height: 6%" *ngIf="taskhistory.tasktypeid && !BTClick">
              <div class="col-xs-1" style="padding:0.5% 0 0 0;width:10%;font-size:11px;">

              </div>
              <div class="col-xs-11" style="padding-right:0;width:90%">
                <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                  <select class="form-control" name="TimeLine" required [(ngModel)]="TimeLine" (change)="Filters($event,'TimeLine');handleClick($event);">
                    <option value="null">Select</option>
                    <option value="{{item.value}}" *ngFor="let item of TimeLines">{{item.itemName}}</option>

                  </select>
                </div>
                <div class="col-xs-6" style="padding: 0 0.5% 0 0.5% ">
                  <select class="form-control" name="OpenBookedTimes" required [(ngModel)]="OpenBookedTimes" (change)="Filters($event,'BookedFilter');handleClick($event);">
                    <option value="null">Select</option>
                    <option value="Open Times" selected>Open Times</option>
                    <option value="Booked Times">Booked Times</option>
                    <option value="Open/Booked Times">Open/Booked Times</option>

                  </select>
                </div>


              </div>

            </div>
            <div class="col-xs-12" style="padding: 0% 2% 0% 2%; height: 2%"></div>

            <div class="col-xs-12" style="padding: 0% 2% 0% 2%; height: 40%" *ngIf="taskhistory.tasktypeid">
              <div class="col-xs-12 scroll-c" style="padding:0;" [style.height.%]="(MappedTimeSlot)? '90':'100'">
                <table style="width:100%;" id="timeSlotsBooking">
                  <tr><th style="width:20%"></th><th style="width:20%;padding:0 0 0 6%">Day </th><th style="width:22%">Date</th><th style="width:15%">Time</th><th style="width:15%">To</th></tr>
                  <tr *ngFor="let item of TimeIntervals">
                    <td style="width:20%;padding:1%">
                      <button class="btn saveButton" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;padding:1%;height:100%;font-size:11px;" (click)="ViewScheduleTaskConfirm(item)" *ngIf="item.Booked == false"> Select Time</button>
                      <button class="btn saveButton" style="width:100%;background: white;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;padding:1%;height:100%;font-size:11px;" *ngIf="item.Booked == true" (mouseenter)="BookedClick($event,item.BookingId)" (mouseleave)="MouseLeave()">Booked</button>

                    </td>
                    <td style="width:20%;padding:0 0 0 5%">{{item.WeekDay}}</td>
                    <td style="width:20%">{{item.Date}}</td>
                    <td style="width:15%">{{item.Time}} {{item.AMPM |lowercase}}</td>
                    <td style="width:15%;">{{item.To}} {{item.ToAMPM | lowercase}}</td>
                  </tr>
                </table>
              </div>

              <div class="col-xs-12" style="padding:0;height:10%" *ngIf="MappedTimeSlot && !BTClick">
                <div class="col-xs-12" style="height:100%">
                  <button class="btn saveButton" style="width:100%;background-color: Highlight;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;padding:1%;height:100%;font-size:11px;" (click)="DisplayOtherAppointments()">Display Other Appt Times Available</button>
                </div>
              </div>
            </div>

          </div>



        </div>

      </form>
    </div>

  </div>
</div>


<app-dialog [(visible)]="notesshowDialog" *ngIf="notesshowDialog" (visibleChange)="onCloseDialogEventReceived($event)">
  <app-notes style="height:100%" [title]="'Contact Notes Info'"
             [source]="'contact'"
             [account]="account"
             [gridOptionsnotes]="gridOptionsnotes"
             [allnotes]="allnotes"
             [context]="context"
             [columnDefsnotes]="columnDefsnotes"
             [contact]="contact"
             [tabName]="'Account'"
             [contactid]="contact.id"
             [isModal]="isModal"
             [conid]="contact.id">
  </app-notes>

</app-dialog>
<app-dialog [(visible)]="showAddEditAccount" *ngIf="showAddEditAccount" id="addEditAccount" [closable]="(action!='View')? false:true" [OutSideClick]="(action!='View')? false:true">
  <app-add-edit-account *ngIf="action!='View'" [accountID]="this.account.id" [type]="'Account'" (sendMsgEvent)="ReceiveEvent($event)"></app-add-edit-account>
  <accountorcontactview *ngIf="action=='View'" [accountId]="this.account.id" [type]="'Account'"></accountorcontactview>
</app-dialog>

<app-dialog [(visible)]="showAddEditContact" *ngIf="showAddEditContact" id="addEditContact" [closable]="false" [OutSideClick]="false">
  <editcontact [contactid]="0" [accountId]="account.id" [type]="'Contact'" (sendMsgEvent)="ContactReceiveEvent($event)"></editcontact>
</app-dialog>


<app-dialog [(visible)]="TimeSlotSaveConfirm" *ngIf="TimeSlotSaveConfirm" id="TimeSlotSaveConfirm" [closable]="false" [OutSideClick]="false">
  <div class="col-xs-12" style="padding: 0; height: 4%; background-color: orangered; color: orangered">
    as
  </div>
  <div class="col-xs-12" style="height:95%;">

    <div class="col-xs-12" style="padding: 0; height: 8%;margin-top:2%">
      <b *ngIf="!BTClick" style="font-size:15px;text-transform:uppercase">Schedule Task / Appointments</b>
      <b *ngIf="BTClick" style="font-size:15px;text-transform:uppercase">SCHEDULE BLOCKED TIME</b>

    </div>
    <div class="col-xs-12" style="padding:0;height:50%">
      <div class="col-xs-12" style="padding:0;height:100%;font-size:14px">
        <div class="col-xs-12" style="padding:0;height:18%">
          <div class="col-xs-2" style="padding:0px">Date:</div>
          <div class="col-xs-10" style="padding:0px">{{taskhistory.estcompleteddate | date:"MM/dd/yyyy"}}</div>
        </div>
        <div class="col-xs-12" style="padding:0;height:18%">
          <div class="col-xs-2" style="padding:0px">Time:</div>
          <div class="col-xs-10" style="padding:0px">{{taskhistory.time | lowercase}} - {{taskhistory.totime | lowercase}}</div>
        </div>
        <div class="col-xs-12" style="padding:0;height:18%">
          <div class="col-xs-2" style="padding:0px">Task Type:</div>
          <div class="col-xs-10" style="padding:0px">{{taskhistory.tasktype}}</div>
        </div>
        <div class="col-xs-12" style="padding:0;height:18%" *ngIf="!BTClick">
          <div class="col-xs-2" style="padding:0px">Contact:</div>
          <div class="col-xs-10" style="padding:0px">{{taskhistory.contactName}}</div>
        </div>
        <div class="col-xs-12" style="padding:0;height:18%" *ngIf="!BTClick">
          <div class="col-xs-2" style="padding:0px">Account:</div>
          <div class="col-xs-10" style="padding:0px">{{account.accountname}}</div>
        </div>
      </div>
      <div class="col-xs-12" style="padding: 0; height: 100%; padding-top: 2%;">
        <textarea id="notesTextArea" rows="6" class="form-control" type="text" name="notedescription" [(ngModel)]="taskhistory.notedescription" style="height:100%;background-color:lightgray" required></textarea>
      </div>
      <div class="col-xs-12" style="padding: 0; height: 100%; padding-top: 1%;">
        <div class="col-xs-10"></div>
        <div class="col-xs-1" style="padding:0;"><button class="btn" style="background-color: #0098f7 !important;color: white;" (click)="SaveTaskHistory()">OK</button></div>
        <div class="col-xs-1" style="padding:0;"><button class="btn" style="float:right;background-color: white !important;color: #0098f7;border: 1px solid lightgrey !important;" (click)="TimeSlotSaveConfirm = false">Cancel</button></div>
      </div>
    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="CloseCreateNewWarning" id="CloseCreateNewWarning" *ngIf="CloseCreateNewWarning" [closable]="false">
  <div class="col-xs-12" style="padding:5%">
    <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
    <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
      <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">OPEN ACCOUNT</div><br />
       <div class="col-xs-12" style="padding: 0; padding-top: 3%;">Leave Create Task Page?</div>
    </div>
    <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
      <div class="col-xs-7" style="padding: 0;">

      </div>
      <div class="col-xs-5" style="padding:0;">
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                  (click)="CloseCreateNewWarning = false;CloseCreateTicket('AccontView')">
            OK
          </button>
        </div>
        
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1 " (click)="CloseCreateNewWarning = false">Cancel</button>
        </div>
      </div>
    </div>
  </div>

</app-dialog>

<app-dialog [(visible)]="CloseWarning" id="CloseWarning" *ngIf="CloseWarning" [closable]="false">
  <div class="col-xs-12">
    <label style="font-size:23px;font-weight:800">SalesXCRM Message</label> <br /><br />
    <div class="col-xs-8" style="padding: 0;">
      <div class="col-xs-12" style="padding: 0;text-transform:uppercase ">SCHEDULE TASK</div>
      <div class="col-xs-12" style="padding: 0; ">Leave Page and Do Not Save ?</div>
    </div>
    <div class="col-xs-4" style="padding:0;">
      <div class="col-xs-12" style="padding:0">
        <button class="btn" style="background-color: #0098f7 !important;color: white;margin-top:1%"
                (click)="CloseAddOpportunityDialog()">
          OK
        </button>
        <button class="btn" style="float:right;color: black;margin-top:1%"
                (click)="CloseWarning = false; ">
          Cancel
        </button>
      </div>

    </div>
  </div>
</app-dialog>

