<div>
  <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%;padding:0;display:none" *ngIf="locationType == 'loc1'">

      <div class="col-xs-3">
        <label>Address ID--{{Index}}</label>
      </div>
      <div class="col-xs-6" style="padding-right:0;width:62%">
        <input type="text" class="form-control decreasefieldSize" id="addressID{{Index}}" name="addressID" [(ngModel)]="accountlocations.id" (focusout)="addressChange()">
      </div>

    </div>
  <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%;padding:0" *ngIf="locationType == 'loc1'">

    <div class="col-xs-3">
      <label>Address Name</label>
    </div>
    <div class="col-xs-6" style="padding-right:0;width:60%">
      <input type="text" class="form-control decreasefieldSize" id="addressName{{Index}}" name="address1Name" [(ngModel)]="accountlocations.addressName" (focusout)="addressChange()">
      <input type="text" class="form-control decreasefieldSize" id="addressType{{Index}}" name="address1type" [(ngModel)]="accountlocations.addressType" style="display:none">
    </div>
  </div>

  <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%;padding:0">

    <div class="col-xs-3">
      <label>Address 1</label>
    </div>
    <div class="col-xs-6" style="padding-right:0;width:60%">

      <input type="text" class="form-control decreasefieldSize" id="address1{{Index}}" name="address1" (keydown.enter)="$event.preventDefault()" [(ngModel)]="accountlocations.address1" (focusout)="addressChange()" placeholder="Enter Address" autocorrect="off" autocapitalize="off"
                              spellcheck="off" #search *ngIf="locationType != 'loc1'" required>

       <input type="text" class="form-control decreasefieldSize" id="address1{{Index}}" name="address1" (keydown.enter)="$event.preventDefault()" [(ngModel)]="accountlocations.address1" (focusout)="addressChange()" placeholder="Enter Address" autocorrect="off" autocapitalize="off" spellcheck="off"
                               #search *ngIf="locationType == 'loc1'" required>

    </div>
    <div class="col-xs-2" style="padding:0;width:13%;height:32px">
      <img _ngcontent-c4="" src="../../assets/img/X search clear.png" style="height:26px;margin-left:5%;margin-top:4%" (click)="clear(Index)">
      <img src="../../../assets/img/Map Icon.jpg" (click)="Map()" style="float:right;height:26px;margin-top:4%" />
    </div>
  </div>
  <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%;padding:0">
 
    <div class="col-xs-3">
      <label>Address 2</label>
    </div>
    <div class="col-xs-9">
      <input type="text" class="form-control decreasefieldSize" id="address2{{Index}}" name="address2" [(ngModel)]="accountlocations.address2" (focusout)="addressChange()" placeholder="Enter Address" />
    </div>

  </div>
  <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%;padding:0">
    <div class="col-xs-3">
      <label><span id="citySateZipSpan">City</span> </label>
    </div>
    <div class="col-xs-9">
      <div class="col-xs-12" style="padding:0;">
        <input type="text" class="form-control decreasefieldSize" id="city{{Index}}" name="city" [(ngModel)]="accountlocations.city" (focusout)="addressChange()" placeholder="Enter city" required [disabled]="accountlocations.country == 'Bahamas'" />
      </div>

    </div>

  </div>

  <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%;padding:0">
    <div class="col-xs-3">
      <label><span id="citySateZipSpan">State</span> </label>
    </div>
    <div class="col-xs-9">

      <div class="col-xs-12" style="padding:0;">
        <input type="text" class="form-control decreasefieldSize" id="state{{Index}}" name="state" [(ngModel)]="accountlocations.state" (focusout)="addressChange()" placeholder="Enter state" required [disabled]="accountlocations.country == 'Bahamas'" />
      </div>

    </div>

  </div>

  <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%;padding:0"> 
    <div class="col-xs-3">
      <label><span id="citySateZipSpan">Zip</span> </label>
    </div>
    <div class="col-xs-9">
      <div class="col-xs-12" style="padding:0;">
        <input type="text" class="form-control decreasefieldSize" id="zip{{Index}}" name="zip" [(ngModel)]="accountlocations.zip" (focusout)="addressChange()" placeholder="Enter Zip" required [disabled]="accountlocations.country == 'Bahamas'" />
      </div>

    </div>

  </div>

  <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%;padding:0">
    <div class="col-xs-3">
      <label><span id="citySateZipSpan">Island</span> </label>
    </div>
    <div class="col-xs-9">
      <input type="text" class="form-control decreasefieldSize" id="ProvidenceIsland{{Index}}" name="ProvidenceIsland" [(ngModel)]="accountlocations.ProvidenceIsland" (focusout)="addressChange()" placeholder="" [disabled]="accountlocations.country != 'Bahamas'" />
    </div>
  </div>
  <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%;padding:0;" *ngIf="locationType == 'loc1'"> 
    <div class="col-xs-3 loctionText">
      <label>Phone</label>
    </div>
    <div class="col-xs-9 loctionInput">
      <input type="text" class="form-control decreasefieldSize" id="phone{{Index}}" [(ngModel)]="accountlocations.phone" placeholder="Enter Phone#" [textMask]="{mask: phonemask}" required (focusout)="addressChange()" />
    </div>
  </div>
  <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%;padding:0" *ngIf="locationType == 'loc1'">
    <div class="col-xs-3">
      <label>Fax </label>
    </div>
    <div class="col-xs-9">
      <input type="text" class="form-control decreasefieldSize" id="fax{{Index}}" [(ngModel)]="accountlocations.fax" placeholder="Enter Fax#" [textMask]="{mask: phonemask}" (focusout)="addressChange()" />
    </div>
  </div>
  <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%; padding: 0;" *ngIf="locationType == 'loc1'">
   
    <div class="col-xs-3">
      <label>Upload Image </label>
    </div>
    <div class="col-xs-8">
      <input type="file" class="form-control decreasefieldSize" (change)="addFiles($event,Index)"
             name="myImage" accept="image/x-png,image/gif,image/jpeg" />
      <input type="text" class="form-control decreasefieldSize" id="UploadImage{{Index}}" [(ngModel)]="accountlocations.LocationPicture" style="display:none" />

    </div>
    <div class="col-xs-1">
      <img src="../../../assets/img/Image Icon.png" (click)="ShowImage(Index)" id="UploadImage{{Index}}Icon" style="float:right;height:26px;margin-top:4%;" />
    </div>
   
  </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%;padding:0;display:none">
      <div class="col-xs-3">
        <label>Lattitude </label>
      </div>
      <div class="col-xs-9">
        <input type="text" class="form-control decreasefieldSize" id="lattitude{{Index}}" [(ngModel)]="accountlocations.lattitude" placeholder="Enter Fax#" (focusout)="addressChange()" />
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.7%; padding: 0; display: none">
      <div class="col-xs-3">
        <label>Longtitude </label>
      </div>
      <div class="col-xs-9">
        <input type="text" class="form-control decreasefieldSize" id="longtitude{{Index}}" [(ngModel)]="accountlocations.longtitude" placeholder="Enter Fax#" (focusout)="addressChange()" />
      </div>
    </div>
  
    <app-dialog [(visible)]="isOpenGoogleMap">
      <div class="box" style="height:100%;margin:0;">
        <div class="box-body Google" style="height:100%">
          <agm-map [latitude]="this.lattitude" [longitude]="this.longitude" style="height:100%">
            <agm-marker [latitude]="this.lattitude" [longitude]="this.longitude">

            </agm-marker>
          </agm-map>
        </div>
      </div>
    </app-dialog>

  </div>

<app-dialog [(visible)]="ShowImageDialog" *ngIf="ShowImageDialog" id="ShowImageDialog">
  <div class="col-xs-12" style="height:100%">
  
    <img [src]="ImageData" style="width:100%;height:99%">
  </div>
  
</app-dialog>

