
<div class="content" style="padding:0;height:100%">
  <div class="col-xs-12" style="padding:0;height:100%">  
      <div class="col-xs-3" style="padding: 0.5%; height: 100%; margin-top:2.5%;width:23%">
        <div class="col-xs-12 blank" style="border: 1px solid darkgrey; 
         padding: 0% 0% 0 0%; font-size: 0.7vw"
             id="QueryTab{{i}}" *ngFor="let qry of Newrunquerylist; let i = index" (click)="GetQueryInfo(qry.id,qry.queryOrder,'QueryTab'+i,qry.searchtype)">
          <div class="col-xs-11" style="padding:0px">
            <label style="margin-top: 4px;" title="{{qry.queryOrder}}">
              {{qry.queryOrder  | slice:0:50}} <span *ngIf="qry.queryOrder.length>50">...</span>
            </label>
          </div>
          <div class="col-xs-1" style="padding:0px">
            <img (click)="deleteQuery(qry.id,'QueryTab'+i)" src="../../assets/img/Black x.png" style="height:15px; margin-left:5px;">
          </div>
        </div>
      </div>

      <div *ngIf="!ShowGrid" class="col-xs-1" style=" width:3%;padding:0;">
        <div class="col-xs-12" style="padding: 0;width:100%;height:5%">
          <div class="col-xs-9 verticalText"><b>{{searchtype}}s ( {{tableData.length}} )</b></div>
          <div class="col-xs-3" style="padding:0;">

            <span (click)="expandContactView(true)"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: right;height:25px" /></span>
            <span (click)="CloseContactDiv()"> <img src="../../../assets/img/Black x.png" style="float: right;height:25px" /></span>
          </div>
        </div>
      </div>

      <div class="col-xs-9" style="padding: 0.5% 0 0 0.5%;height:100%;width:77%">
        <div class="col-xs-12" style="padding: 0%; margin-bottom: 1%;">
          <div class="col-xs-7" style="padding:0%"><label style="text-align:left;">{{QueryName}}</label></div>
          <div class="col-xs-1" style="float:right">        
            <img (click)="applicationComponent.selectedbuttonText='Query Builder';applicationComponent.advancedSearch($event)" src="../../../assets/img/Black x.png" style="height: 25px;float:right" />           
          </div>
        </div>
        <div style="padding:0;height:100%" [ngClass]="(showContactDashBoard) ? 'col-xs-4':'col-xs-12'" *ngIf="ShowGrid">
          <div class="col-xs-12" style="padding:0;height:100%" [ngClass]="(applicationComponent.googlemapshow)?'col-xs-5':'col-xs-12'">
            <ag-grid-angular style="width: 100%;height:100%;  margin-top:0" class="ag-theme-balham"
                             [gridOptions]="SearchResultGridOptions"
                             [columnDefs]="columnDefs"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="tableData"
                             [sideBar]="sideBar"
                             [suppressRowClickSelection]="true"
                             (rowSelected)="rowClickAgGrid($event)"
                             (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                             (selectionChanged)="onSelectionChanged($event)"
                             (gridReady)="onAgGridReady($event)">
            </ag-grid-angular>
          </div>

          <div *ngIf="(applicationComponent.googlemapshow)" class="col-xs-7" style="padding:0px;height:100%">
            <addressmap [AccConIds]="AccConIds" [accesstype]="'Accounts'"></addressmap>
          </div>
        </div>
        <div [ngClass]="(ShowGrid) ? 'col-xs-8':'col-xs-12'" *ngIf="showContactDashBoard" style="padding:0 0 0 0.8%;height:100%">
          <contactsdashboard [contactids]="contactid" [type]="'contacts'" [tab]="'QuickView'" *ngIf="!isShowAccountInfo" (sendMsgEvent)="onContactMessageReceived($event)">
          </contactsdashboard>

          <account-contact *ngIf="isShowAccountInfo" [type]="'Account'" [contactId]="'show'+ accountid" [tab]="'QuickView'"></account-contact>
        </div>

      </div>
    </div>

    <div>
      <div *ngIf="QuickSearchEventListener()"></div>

    </div>
    <app-dialog [(visible)]="CloseDialog" [closable]="false">
      <div style="height: 100%; width: 100%">
        <div class="col-xs-12" style="text-align:center; ">
          <div class="col-xs-12" style="font-size:14px;">
            <b>Advanced Search Result were Closed.</b>
          </div>
          <br /><br />
          <div class="col-xs-12" style="font-size:14px;margin-bottom: 5%;">
            <b>Select Advanced Option on Search Bar to Generate</b><br />
            Another  Advanced Search <br />
            -or-<br />
            Type Search Data in Search Bar and Select Enter
          </div>
        </div>

      </div>
    </app-dialog>
  </div>


<!--<div *ngIf="(applicationComponent.googlemapshow)" class="col-xs-7" style="padding:0px;height:100%" [style.width.%]="isShowAccountInfo?'66.5':'58.3'">
  <addressmap [AccConIds]="AccConIds" [accesstype]="'Accounts'"></addressmap>
</div>-->

