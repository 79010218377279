import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { CustomerService } from './../../services/customer.service';
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../application.component';
import * as moment from 'moment'
import { Opportunities } from '../../models/opportunities';
import { AccountsService } from './../../services/accounts.service';
import { AdvancedsearchService } from './../../services/advancedsearch.service';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { NotesrendererComponent } from '../notesrenderer/notesrenderer.component';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { Column } from 'ag-grid';
import { UserService } from '../../services/user.service';
import { DatePipe } from '@angular/common';
import { EditdeleteComponent } from '../editdelete/editdelete.component';
import { ToastrService } from 'ngx-toastr';
import { Notes } from '../../models/notes';
import { Contacts } from '../../models/contacts';
import { Customers } from '../../models/customers';
import { Accounts } from '../../models/accounts';
import { Router, ActivatedRoute } from '@angular/router';
import { Auditlog } from '../../models/auditlog';
import { Accountlocations, Hero } from '../../models/data-model';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import * as XLSX from 'ts-xlsx';
import { ColumnNames } from './../../models/column-names';
declare var require: any;
declare var $: any;
declare var ActiveXObject: (type: string) => void;
export enum Table { Contact, Account, Practice }
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-salestool',
  templateUrl: './salestool.component.html',
  styleUrls: ['./salestool.component.css']
})
export class SalestoolComponent implements OnInit {
  dropdownList = [];
  alphabets = [];
  currentuser: any;
  ExcelgridApi: any;
  dropdownSetting = {};
  FinalContacts: Contacts[] = [];
  FinalAccounts: Accounts[] = [];
  salestooltype: string;
  accountShow: boolean = false;
  contactShow: boolean = false;
  rowData = [];
  accountColumns: Array<ColumnNames> = new Array<ColumnNames>();
  contactColumns: Array<ColumnNames> = new Array<ColumnNames>();
  fieldsSelectorSearchFilter = "";
  TblDropDown =[];
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear()-1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear()-1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");
  public sideBar;
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  active_deactiveStatus: number; NotesDec = false;
  finalitems = "";
  arrayBuffer: any;
  selectedItems = [];
  colValues = [];
  Allopportunities: Opportunities[];
  public gridOptions: GridOptions;
  public columnDefs: any[];
  pageSize: string = "";
  public context;
  public frameworkComponents;
  datePipe: DatePipe = new DatePipe("en-US");
  aggridheight: string;
  Ids = [];
  isModal: boolean = true;
  public ExcelgridOptions: GridOptions;
  public columnDefsExcel: any[];
  editNoteId: boolean = false;
  timezonevalue: string;
  showEditNoteBtn: boolean = false;
  public gridOptionsnotes: GridOptions;
  public columnDefsnotes: any[];
  allnotes: Notes[];
  contactid: number;
  rowsFound: number;
  contact: Contacts = new Contacts();
  account: Accounts = new Accounts();
  customer: Customers = new Customers();
  showDialog = false;
  conid: number;
  file: File;
  addresses: Accountlocations[];
  typ: string;
  public components;
  expanded = false;
  
  Contact: string; ContactTaskHistory: string; ContactLead: string; AllContactsNotes: string; typeSelected: string;
  OpportunityHistoryDialog: boolean = false;
  public gridopportunityOptions: GridOptions;
  public opportunitycolumnDefs: any[];
  opportunityauditloglist: Array<Auditlog> = [];
  SalestoolList: Dropdowntype[] = [];
  OppAproved = false;
  constructor(public applicationComponent: ApplicationComponent, private customerService: CustomerService, private AdvancedsearchServices: AdvancedsearchService, private accountsService: AccountsService, private opprtunityservice: OpprtunitiesService, private toastr: ToastrService, private viewContainerRef: ViewContainerRef, private router: Router, private users: UserService, private route: ActivatedRoute)
  {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    }
  }

  //over() {
  //  //var doc = document.getElementById(userprevilage);

  //  Dashboardtype
  //}
  ngAfterViewInit(): void {
    var thisComponent = this;
    setTimeout(function () { thisComponent.ResizeTable(); }, 10);
    //$('#userprevilage').attr('style', 'background:blue');
  }
  ResizeTable() {
    this.aggridheight = (((window.innerHeight) - (($('#appComponentContent').height()) + ($('#actiondivid').height()))) - 110) + 'px';
  }
  ngOnInit() {
    $('.fr-counter').attr('style', 'display:none;')
    this.applicationComponent.SearchSelectedItems = [];
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1,0,0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.dropdownList = Owner;
      
    });
    this.route.params.subscribe(params => {
      let selfObj = this;
      $(setTimeout(function () {
         
        selfObj.SelectedItem(params['subMenuId']);
      }, 100));
    });
     
    if (this.applicationComponent.selectedusers != '' && this.applicationComponent.selectedusers != null && this.applicationComponent.selectedusers != undefined) {
      this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
        Owner.forEach(Owner => {
        });
        this.selectedItems = Owner;
      });    
    }
    else {
    
      this.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
      ];
      this.finalitems += this.currentuser.id + ',';
      //$("#userids").val(this.currentuser.id);
    }
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 2
    };
    $(".mainCheckMark").removeClass("activeColor");
    $(".coustomButton").removeClass("mainactiveButton");
    $("#saletool_span").addClass("activeColor");
    $("#saletool").addClass("mainactiveButton");
    this.initializeAgGrid();
    //this.customerService.GetSpecialityList("SalesTools", this.currentuser.id).then((accounttype) => {
    //    accounttype.forEach(accounttype => {
    //  });
    //    this.SalestoolList = accounttype;
    //});
    //if (this.currentuser.dealapprovalid == 453) { 
    //  this.ClosedOpportunity("Closed-Won", $("#userids").val());
    //} else {
    //  alert("This user Deal Approval is set to “No – Deal Approval Permission”, Please change the User set up to Yes – Deal Approval Permission if you want to add a Deal Approval for the User");
    //}
    //document.getElementById('importDataDiv').style.display = "none";
    document.getElementById('WizardBodyGrid').style.display = "none";
    this.SelectedItem('Import Data Wizard');
    document.getElementById("RadioButtons").style.display = "none";
    this.AlphabetsBinding();
    
    


  }

  AlphabetsBinding() {
    var count = 1;
    if (count < 100) {
      for (let i = 65; i <= 90; i++) {
        this.alphabets.push(String.fromCharCode(i));
        count++;
      }
      if (count > 26) {
        for (let j = 65; j <= 90; j++) {
          for (let k = 65; k <= 90; k++) {
            this.alphabets.push(String.fromCharCode(j) + String.fromCharCode(k));
          }
          count++;
        }
      }

    }
  }

  createColumnDefs(columnSelections: string) {
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefs.push({ field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) { if (params.value == 'Open') { return { color: 'red' }; } } });
        } else if (fieldHeaderVisibleWidthArray[0] == "approvedusername") {
          this.columnDefs.push({
            field: 'approvedusername', headerName: 'Approved by', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer(params) {
              if (params.value != null) { return params.value; } else {

                return "<input type='checkbox'" + (params.value ? "(checked='checked'" : "") + ">";
              }
            } });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "dateapproved") {
          this.columnDefs.push({
            field: 'dateapproved', headerName: 'Date Approved', hide: false, editable: true, cellEditor: DateEditor, cellRenderer: function (params) {
              if (params.value == null) {
                return '<i class="fa fa-calendar" aria-hidden="true"></i>';
              }
              else {
                if (params.data.approvedusername == null && params.value != null) {
                  selfObj.ApproveOpportunity(params.data.id, params.value, params.data.dollers, params.data.RR);
                }
                return params.value;
              }
            }
          }
          );
        } else if (fieldHeaderVisibleWidthArray[0] == "actions") {
          this.columnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: false, pinned: 'right', width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        { field: 'practicename', headerName: 'Account Name', hide: false },
        { field: 'status', headerName: 'Status', hide: false, cellStyle: function (params) { if (params.value == 'Open') { return { color: 'red' }; } } },  
        { field: 'dollers', headerName: '$$$$', hide: false ,editable:true},
        { field: 'RR', headerName: 'RR', hide: false, editable: true},
        { field: 'estimatedclosedate', headerName: 'Date Scheduled', hide: false },
        { field: 'PCname', headerName: 'PC', hide: false },
        { field: 'datecreated', headerName: 'Date Created', hide: false },
        { field: 'contactName', headerName: 'Contact Name', hide: false },
        { field: 'itemName', headerName: 'Item', hide: false }, 
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        {
          field: 'approvedusername', headerName: 'Approved by', hide: false, cellRenderer(params)
          {
            if (params.value != null) { return params.value;} else {
              
              return "<input type='checkbox'" + (params.value ? "(checked='checked'" : "") + ">";
            }
          }
        },
        {
          field: 'dateapproved', headerName: 'Date Approved', hide: false, editable: true, cellEditor: DateEditor, cellRenderer: function (params) {
            if (params.value == null) {
              return '<i class="fa fa-calendar" aria-hidden="true"></i>';
            }
            else {
              if (params.data.approvedusername == null && params.value != null) {
                selfObj.ApproveOpportunity(params.data.id, params.value, params.data.dollers, params.data.RR);               
              }
              return params.value;
            }
          },
          
        },
        { field: 'actions', headerName: 'Actions', cellRenderer:'editdeletecv', hide: false, pinned: 'right' },
      ];
      var tooltipRenderer = function (params) {
        return '<span title="' + params.value + '">' + params.value + '</span>';
      };
    }
    function DateEditor() { }

    // gets called once before the renderer is used
    DateEditor.prototype.init = function (params) {
      // create the cell
      this.eInput = document.createElement('input');
      this.eInput.value = params.value;

      // https://jqueryui.com/datepicker/
      $(this.eInput).datepicker({
        dateFormat: "mm-dd-yy",
        changeMonth: true,
        changeYear: true
      });
    };

    // gets called once when grid ready to insert the element
    DateEditor.prototype.getGui = function () {
      return this.eInput;
    };

    // focus and select can be done after the gui is attached
    DateEditor.prototype.afterGuiAttached = function () {
      this.eInput.focus();
      this.eInput.select();
    };

    // returns the new value after editing
    DateEditor.prototype.getValue = function () {
      return this.eInput.value;
    };

    // any cleanup we need to be done here
    DateEditor.prototype.destroy = function () {
      // but this example is simple, no cleanup, we could
      // even leave this method out as it's optional
    };

    // if true, then this editor will appear in a popup
    DateEditor.prototype.isPopup = function () {
      // and we could leave this method out also, false is the default
      return false;
    };
  }

  onAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
      $(setTimeout(function () {
        selfObj.autoSizeAll();
      }, 100));
    }, 100));
  }
  ApproveOpportunity(opportunityid, approveddate, dollers, RR) {
    
    if (!this.OppAproved) {
      var date = approveddate.replace('/', "-")
      var c = confirm("Are you sure to Approve this??");
      if (c == true) {
        this.OppAproved = true;
        this.opprtunityservice.ApproveselectedOpportunity(this.currentuser.id, opportunityid, date, dollers, RR).then((calllist) => {
          this.ClosedOpportunity("Closed-Won", $("#userids").val());
        });
      }
    }
  }
  autoSizeAll() {

  }
  txtPageSizeChanged(pageSize) {
    this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    this.saveConfiguration();
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
    
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 46;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  initializeAgGrid() {
    
    this.gridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 46).then((config) => {
      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.gridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(columnSelections);

    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent,
      editdeletecv: EditdeleteComponent,

    };

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
  }
  
  ClosedOpportunity(val, userids) {
    
    userids = $("#userids").val();
    this.opprtunityservice.GetAllOpportunitiesList(val, userids, this.startDateVal, this.endDateVal).then((customer) => {
      customer.forEach(customer => {
        customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
        customer.dateapproved = this.datePipe.transform(customer.dateapproved, "MM/dd/yyyy");
        if (customer.estimatedclosedate != '0001-01-01T00:00:00') {
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
        } else {
          customer.estimatedclosedate = '';
        }
      });

      this.Allopportunities = customer;
    });
    this.OppAproved = false;
  }

  openDisplaynotes(params) {
    
    //this.notes.notedescription = params.value;
    this.NotesDec = !this.NotesDec;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    var notesOffset = $("#divNotesContainer").offset();
    let notesWidth = $('#divNotesContainer').width();
    let notesHeight = $('#divNotesContainer').height();
    let windowWidth = $(window).width();
    let showDialogWidth = 350;
    let selfObj = this;
    $(setTimeout(function () {
       
      $('#showDetailsDialog').width(showDialogWidth);
      $('#showDetailsDialog').height(notesHeight - 140);
      $('#showDetailsDialog').css('top', notesOffset.top + 125);
      $('#showDetailsDialog').css('left', notesWidth - showDialogWidth);
      $('#showDetailsDialog').css('border-radius', '4px');
      $('#showDetailsDialog').css('border', '3px solid');

      if (selfObj.isModal) {
        $('#showDetailsDialog').width(showDialogWidth);
        $('#showDetailsDialog').css('height', '59%');
        $('#showDetailsDialog').css('top', notesOffset.top + 100);
        $('#showDetailsDialog').css('left', '612px');
        $('#showDetailsDialog').css('border-radius', '4px');
        $('#showDetailsDialog').css('border', '3px solid');
      }

      if ($('input[name=Notes]').is(':checked')) {
        $('#txtLongNoteDesc').css('height', '81%');
        $('#txtLongNoteDesc').css('padding-top', '0%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
        $('#txtUserFullName').css('height', '14%');
      } else {
        $('#txtLongNoteDesc').css('height', '96%');
        $('#txtLongNoteDesc').css('padding-top', '6%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
      }
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));
  }
  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
    
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();

  }
  saveEditedNote() {
    
    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.closeDetailsDialog();
        selfObj.GetOpportunitiesnotes(selfObj.contactid, 'Opportunity');
      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
  }
  GetOpportunitiesnotes(id, type) {
    
    this.customerService.GetAllNotesList(this.currentuser.id,id, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.initializeAgGridNotes();
      this.allnotes = taskhistory;
      //this.notesitemCount = taskhistory.length;
      let windowHeight = $(window).height() - 160;
    });
  }
  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.createColumnDefsnotes("");
    this.gridOptionsnotes.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  createColumnDefsnotes(columnSelections: string) {
    let selfObj = this;

    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellStyle: { 'text-align': 'left' }, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }
        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];

  }
  onAgGridReadynotes(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllnotes();
      $(setTimeout(function () {
        selfObj.autoSizeAllnotes();
      }, 400));
    }, 400));
  }
  autoSizeAllnotes() {

  }
  openNotesDialogAg(rowdata) {
    
     
    if (rowdata.type == 'Account' || rowdata.type == 'Customer') {
      this.opprtunityservice.GetByAccountId(rowdata.accountorcontactid).then(result => {
         
        this.account = result;
        this.contact.firstname = "No";
      });     
    }
    else if (rowdata.type == 'Contacts') {
      this.opprtunityservice.GetContactById(rowdata.accountorcontactid).then(result => {
        this.opprtunityservice.GetByAccountId(result.accountid).then(result => {
           
          this.account = result;
        });
         
        var middleName = '';
        var title = '';
        if (result != null && result.middlename != null) {
          middleName = result.middlename;
        }
        if (result != null && result.title != null) {
          title = ',' + result.title;
        }
        if (result != null)
          this.contact.firstname = result.firstname + ' ' + middleName + ' ' + result.lastname + title;
      });
    }

    this.contactid = rowdata.id;
    this.conid = rowdata.accountorcontactid;
    this.Contact = (rowdata.type == 'Contacts') ? 'Contact' : 'Customer';
    this.ContactTaskHistory = (rowdata.type == 'Contacts') ? 'ContactTaskHistory' : 'CustomerTaskHistory';
    this.ContactLead = (rowdata.type == 'Contacts') ? 'ContactLead' : 'CustomerLead';
    this.AllContactsNotes = (rowdata.type == 'Contacts') ? 'AllContactsNotes' : 'AllContactsNotes';
    this.typ = rowdata.type;
    $('#accotIdNotes').val(rowdata.id);
    $('#accotTypeNotes').val('Opportunity');
    this.GetOpportunitiesnotes(rowdata.id, 'Opportunity');

    this.showDialog = !this.showDialog;
    $(setTimeout(function () {
      if ($('#allNotesId').length > 0)
        $('#allNotesId').removeClass('active');

      if ($('#tskHistNotesId').length > 0)
        $('#tskHistNotesId').removeClass('active');

      if ($('#leadsNotesId').length > 0)
        $('#leadsNotesId').removeClass('active');

      if ($('#allContactNotesId').length > 0)
        $('#allContactNotesId').removeClass('active');

      if ($('#oppNotesId').length > 0) {
        $('#oppNotesId').removeClass('active');
        $('#oppNotesId').addClass('active');
      }
      var modalWidth = (screen.width) - ((screen.width) / 10);
      //$('.dialog').attr('style', 'padding:1%;border: 3px solid green;width:' + modalWidth + 'px');
      $('#chkShowDetailsNotes').prop('checked', false); $('#closeDetailsDialog').click();
    }, 100));

    $('.dialog').width('1000px');
  }

  editRowAg(rowdata) {
    this.router.navigateByUrl(`/application/opportunitiesdashboard/${rowdata.id}/opportunity/null/null`);

    //this.router.navigateByUrl(`/application/contactsdashboard/${rowdata.id}/edit`);
  }
  deleteRowAg(rowdata) {
    var c = confirm("Are you sure you want to delete this Opportunity ??");
    if (c == true) {
      this.opprtunityservice.deleteopportunity(rowdata.id).then((calllist) => {
        this.ClosedOpportunity("Closed-Won", $("#userids").val());
      });
    }
  }

  auditRowAg(rowdata) {
    this.OpportunityHistoryDialog = !this.OpportunityHistoryDialog;
    this.OpportunitieshistoryinitializeAgGrid();
    this.customerService.GetAllDocumentAuditlogList(rowdata.id,'Opportunity').then((opportunityauditloglist) => {
      opportunityauditloglist.forEach(document => {
        document.dateofviewed = this.datePipe.transform(document.dateofviewed, "MM-dd-yyyy");
      });
      this.opportunityauditloglist = opportunityauditloglist;
    });
  }

  onopportunityauditlogAgGridReady(event) {
    
    //let selfObj = this;
    //$(setTimeout(function () {
    //  selfObj.opportunitiesautoSizeAll();
    //}, 100));
  }

  OpportunitieshistoryinitializeAgGrid() {
    
    this.gridopportunityOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.opportunitiesscreateColumnDefs("");
    this.gridopportunityOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
  }
  rowClickAgGrid(rowEvent) {
    if ((this.Ids).indexOf(rowEvent.data.accountorcontactid) > -1) {
      var index = (this.Ids).indexOf(rowEvent.data.accountorcontactid);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      $('#tasklistselectedtyle').val('');
    } else {
      this.Ids.push(rowEvent.data.accountorcontactid)
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      if (rowEvent.data.type == "Contacts") {
        $('#tasklistselectedtyle').val('Contact');
      }
      else {
        $('#tasklistselectedtyle').val('Customer');
      }
    }
  }

  rowDoubleClickAgGrid(rowEvent) {
    this.router.navigateByUrl(`/application/opportunitiesdashboard/${rowEvent.data.id}/opportunity/null/null`);
  }
  opportunitiesscreateColumnDefs(columnSelections: string) {
    this.opportunitycolumnDefs = [
      { field: 'documentname', headerName: 'Opportunity Name', hide: false },
      { field: 'action', headerName: 'Action', hide: false },
      { field: 'dateofviewed', headerName: 'last acessed date', hide: false },
      { field: 'username', headerName: ' User Name', hide: false },
    ];
  }
  opportunitiesautoSizeAll() {
     
    if (this.gridopportunityOptions && this.gridopportunityOptions.columnApi && this.gridopportunityOptions.columnApi.getAllColumns() != undefined) {
      var allColumnIds = [];
      this.gridopportunityOptions.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });
      this.gridopportunityOptions.columnApi.autoSizeColumns(allColumnIds);
    }
  }
  onItemSelect(item: any) {
    
    var ids = "";
    ids += item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.ClosedOpportunity("Closed-Won", $("#userids").val());  
  }
  
  OnItemDeSelect(item: any) {
    
    var ids = "";
    ids = item.id;
    var result = '';
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.ClosedOpportunity("Closed-Won", $("#userids").val());  
    } else {
      this.Allopportunities = [];
      $("#userids").val('');
    }
  }
  onSelectAll(items: any) {
    
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.ClosedOpportunity("Closed-Won", $("#userids").val());  
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.Allopportunities = [];
    $("#userids").val('');
  }

  dateChange(event) {
    this.AssignStartAndEndDate(event);
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
    let userids = $("#userids").val();
    this.opprtunityservice.GetAllOpportunitiesList("Closed-Won", userids, this.startDateVal, this.endDateVal).then((customer) => {
      customer.forEach(customer => {
        customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
        if (customer.estimatedclosedate != '0001-01-01T00:00:00') {
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
        } else {
          customer.estimatedclosedate = '';
        }
      });
      this.Allopportunities = customer;
    });
  }
  AssignStartAndEndDate(event) {
    
    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }

  SelectedItem(value) {
    this.salestooltype = value;
    if (value == "ImportDataWizard") {
      
      $('#gridheight').css('display', 'none');
      document.getElementById('importDataDiv').style.display = "block";
    }
    else {
      
      
      $('#gridheight').css('display', 'block');
      //document.getElementById('gridheight').style.display = "block";
      document.getElementById('importDataDiv').style.display = "none";
    }
  }

  incomingfile(event) {
    this.file = event.target.files[0];
    
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.rowsFound = XLSX.utils.sheet_to_json(worksheet, { raw: false }).length;//Sheet length
      $('#importData').val(this.rowsFound);
       
      this.rowData = XLSX.utils.sheet_to_json(worksheet);
      var cells = Object.keys(worksheet);
      for (var i = 0; i < Object.keys(cells).length; i++) {
        if (cells[i].indexOf('1') > -1) {
          this.colValues.push(worksheet[cells[i]].v); //Contails all column names
        }
      }
      this.checkDuplicateInObject('Phone', XLSX.utils.sheet_to_json(worksheet, { raw: false }))
          
    }
    fileReader.readAsArrayBuffer(this.file);

    document.getElementById("RadioButtons").style.display = "block";
    this.AccountClicked();
    
  }

  onAgGridReadycontacts(event) {
    let selfObj = this;
    $(setTimeout(function () {
      
      $(setTimeout(function () {
      
      }, 100));
    }, 100));
  }

  RunFile() {
    document.getElementById('WizardBodyGrid').style.display = "block";
    document.getElementById('WizardBody').style.display = "none";
    //var newwindow = window.open("../../../assets/img/SalesXCRM.xlsx", "window2", "");   
  }

  checkDuplicateInObject(propertyName, inputArray) {
  var seenDuplicate = false,
    testObject = {};

  inputArray.map(function (item) {
    var itemPropertyName = item[propertyName];
    
    if (itemPropertyName in testObject) {
      testObject[itemPropertyName].duplicate = true;
      item.duplicate = true;
      seenDuplicate = true;
    }
    else {
      testObject[itemPropertyName] = item;
      delete item.duplicate;
    }
    });
    
  }


  initializeImportColumnDefs(val) {

    if (val == "Contacts") {
      this.columnDefsExcel = [
        { headerName: 'contacttype', field: 'contacttype' },
        { headerName: 'firstname', field: 'firstname' },
        { headerName: 'middlename', field: 'middlename' },
        { headerName: 'lastname', field: 'lastname' },
        { headerName: 'suffix', field: 'suffix' },
        { headerName: 'sex', field: 'sex' },
        { headerName: 'title', field: 'title' },
        { headerName: 'speciality', field: 'speciality' },
        { headerName: 'subspeciality', field: 'subspeciality' },
        { headerName: 'email', field: 'email' },
        { headerName: 'address1', field: 'address1' },
        { headerName: 'address2', field: 'address2' },
        { headerName: 'city', field: 'city' },
        { headerName: 'state', field: 'state' },
        { headerName: 'zip', field: 'zip' },
        { headerName: 'cell', field: 'cell' },
        { headerName: 'office', field: 'office' },
        { headerName: 'DOB', field: 'DOB' },
        { headerName: 'homeaddress', field: 'homeaddress' },
        { headerName: 'homecity', field: 'homecity' },
        { headerName: 'homestate', field: 'homestate' },
        { headerName: 'homezip', field: 'homezip' },
        { headerName: 'home', field: 'home' },
        { headerName: 'college', field: 'college' },
        { headerName: 'collegegrandyear', field: 'collegegrandyear' },
        { headerName: 'medschool', field: 'medschool' },
        { headerName: 'medschoolgrandyear', field: 'medschoolgrandyear' },
        { headerName: 'residency', field: 'residency' },
        { headerName: 'residencygrandyear', field: 'residencygrandyear' },
        { headerName: 'fellowship', field: 'fellowship' },
        { headerName: 'fellowshipgrandyear', field: 'fellowshipgrandyear' },
        { headerName: 'ownerid', field: 'ownerid' },
        { headerName: 'accountid', field: 'accountid' },
        { headerName: 'practiceid', field: 'practiceid' },
        { headerName: 'userid', field: 'userid' }

      ];
    }
    if (val == "Accounts") {
      this.columnDefsExcel = [
        { headerName: 'customertypeid', field: 'customertypeid' },
        { headerName: 'country', field: 'country' },
        { headerName: 'accounttype', field: 'accounttype' },
        { headerName: 'accountname', field: 'accountname' },
        { headerName: 'address1', field: 'address1' },
        { headerName: 'address2', field: 'address2' },
        { headerName: 'city', field: 'city' },
        { headerName: 'state', field: 'state' },
        { headerName: 'zip', field: 'zip' },
        { headerName: 'phone', field: 'phone' },
        { headerName: 'fax', field: 'fax' },
        { headerName: 'alterphone1', field: 'alterphone1' },
        { headerName: 'alterphone2', field: 'alterphone2' },
        { headerName: 'website', field: 'website' },
        { headerName: 'ownerid', field: 'ownerid' },
        { headerName: 'practiceid', field: 'practiceid' },
        { headerName: 'created', field: 'created' },
        { headerName: 'speciality', field: 'speciality' },
        { headerName: 'ownership', field: 'ownership' },
        { headerName: 'userid', field: 'userid' },
        { headerName: 'accnumber', field: 'accnumber' },
        { headerName: 'er', field: 'er' },
        { headerName: 'ehrdatepublished', field: 'ehrdatepublished' }
        

      ];
    }
  }

  CloseGrid() {
    document.getElementById('WizardBodyGrid').style.display = "none";
    document.getElementById('WizardBody').style.display = "block";
  }
  onExcelGridReady(params) { 
    this.ExcelgridApi = params.api;
    
  }
  getAllRows() {
    var that = this;
    var type=$('input[name="AccountContact"]:checked').val();
    if (type == 'Account') {
      let selfObj = this;      
      this.ExcelgridApi.forEachNode(function (rowNode, index) {
         
        selfObj.FinalAccounts.push(rowNode.data);
      }); 
      
      this.accountsService.addImportedAccount(this.FinalAccounts).then(result => {
        this.account = result;
        this.toastr.success("Account saved successfully.", "Account Saved", { timeOut: 600 });
              
      });
      
    }
    else {
      let selfObj = this;
      this.ExcelgridApi.forEachNode(function (rowNode, index) {
       
      let newcontact = new Contacts();
      newcontact.firstname = rowNode.data.firstname;
      newcontact.lastname = rowNode.data.lastname;
      selfObj.FinalContacts.push(rowNode.data);
      });

      selfObj.users.addExcelContacts(selfObj.FinalContacts).then(result => {
        selfObj.toastr.success("contact saved successfully.", "Contact Saved");

      });
    }

  }

  AccountClicked() {
    
    this.AdvancedsearchServices.GetAllColumnNames('tblaccounts').then((colNames) => {
      colNames.forEach(colName => {
      });
      this.accountColumns = colNames;
    });
    this.TblDropDown = this.accountColumns;
    this.initializeImportColumnDefs("Accounts");
    
  }
  ContactClicked() {
      this.AdvancedsearchServices.GetAllColumnNames('tblcontacts').then((colNames) => {
      colNames.forEach(colName => {
        });
        this.contactColumns = colNames;
    });
    
    this.TblDropDown = this.contactColumns;
    this.initializeImportColumnDefs("Contacts");

  }
  checkBoxChecked(val) {

    
    var city, state, zip, phone, fax;
    var isCityChecked, isStateChecked, isZipChecked, isPhoneChecked, isFaxChecked;
    //this.rowData.forEach(row => {
    //});

    if ($('#City').prop('checked') == true) {
      city = this.rowData[0].city;
      isCityChecked = true;
    }
    else {
      city = null;
      isCityChecked = false
    }
    if ($('#State').prop('checked') == true) {
      state = this.rowData[0].state;
      isStateChecked = true
    }
    else {
      state = null;
      isStateChecked = false
    }
    if ($('#Zip').prop('checked') == true) {
      zip = this.rowData[0].zip;
      isZipChecked = true;

    }
    else {
      zip = null;
      isZipChecked = false
    }
    if ($('#Phone').prop('checked') == true) {
      phone = this.rowData[0].phone;
      isPhoneChecked = true;
    }
    else {
      phone = null;
      isPhoneChecked = false
    }
    if ($('#Fax').prop('checked') == true) {
      fax = this.rowData[0].fax;
      isFaxChecked = true
    }
    else {
      fax = null;
      isFaxChecked = false
    }


      var radioValue = $("input[name='AccountContact']:checked").val();
      var array: string[];
      

    array = [city, isCityChecked, state, isStateChecked, zip, isZipChecked, phone, isPhoneChecked, fax, isFaxChecked]
      if (radioValue == "Accounts") {
        this.accountsService.CheckImportedAccountDuplicates(array).then(account => {
          this.account = account;
          if (this.account != null) {
            alert("Accounts Dulicates Found")
          }
          else {
            alert("No Dulicates Found")
          }
        });
        
      }
      else if (radioValue == "Contacts") {
        this.accountsService.CheckImportedContactsDuplicates(array).then(contact => {
          this.contact = contact;
          if (this.contact != null) {
            alert("Contact Dulicates Found")
          }
          else {
            alert("No Dulicates Found")
          }
        });
        
      }

  }

  DownLoadWxcel(res,filename) {
    this.accountsService.exportAsExcelFile(res, filename);
    
  }

  DuplicateCreateExcel() {
     
    var value = $('#CreateExcel').val();
    if (value == 1) {
      var radioValue = $("input[name='AccountContact']:checked").val();
      if (radioValue == "Accounts") {
        if (this.account.id != null) {
          this.DownLoadWxcel(this.account, "AccountDuplicates");
        }
      }
      else if (radioValue == "Contacts") {
        if (this.contact.id != null) {
          this.DownLoadWxcel(this.contact, "ContactDuplicates");
        }
      }
      
    }
  }
}




