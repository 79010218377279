export class Tickets {
  public ticketId: number;
  public contactid: number;
  public contactname: string;
  public createdDate: string;
  public email: string;
  public subject: string;
  public descriptions: string;
  public UserID: number;
  public UserName: string;
  public assignTicketToTask: string
  public userList: string
  public assignTicketToUser: string
  public TicketStatus: number;
  public accountName: string;
  public accID: number;
  public ZendeskID: number;
  public readstatus: number;
  public ticketNotes: any;
  public CCTo: string;
  public CCToName: string;
  public CCToTask: string;
  public CCTotaskName: string;
  public groupList: string;
  public priority: number;
  public businessphone: string;
  public cellphone: string;
  public islimitedSearch: boolean;
  public SearchVal: string;
  public office: string;
  public cell: string;
  public NoOfDays: number;
}
