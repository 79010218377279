
<div class="col-xs-12" style="padding: 0%;">
 
  <div class="col-xs-12" style="height:5%;width:100%">
    <userdataheader [parentComp]="'rhx'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
  </div>
  
  <div class="col-xs-12" style="margin: 0; height: 5%;">
    <div class="col-md-2 col-sm-6 col-xs-12" style="padding: 0; padding-top: 0.5%;">
      <label style="margin-top: 4%; font-size: 11px; font-weight: 800; margin-bottom: 0;">Recent History ({{rhxType}})</label>
    </div>
  </div>

  <div class="col-xs-12" style="padding: 0 0 0 1%;">
    <ag-grid-angular class="ag-theme-balham" style="height:600px"
                     [gridOptions]="gridrhxOptions"
                     [columnDefs]="rhxcolumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="rhxlist"
                     [sideBar]="sideBar"
                     (columnVisible)="columnVisible($event)"
                     (dragStopped)="dragStopped($event)"
                     [suppressRowClickSelection]="true"
                     (columnResized)="columnResized($event)"
                     (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                     (gridReady)="onAgGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
