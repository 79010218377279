<!--<div class="col-xs-12" style="padding: 0; height:5%">
  <span style="float:right;" (click)="DivClick($event);applicationComponent.selectedsubbutton='';">
  <img src="../../../assets/img/Black x.png" style="height:25px" />
  </span>
</div>-->

<div class="container" style="height:100%;padding:0 0 0 1%;">
  <div class="col-xs-12" style="padding: 0; height: 93%;">
    <form #userform="ngForm" style="height:100%;display:flex">

      <div class="col-xs-5" style="padding:0;height:100%;width:42%;padding-right:0.5%">
        <div class="col-xs-12" style="padding: 1%; background-color: rgb(40,62,74); height: 5%; color: white">
          <b>SELECT OR CREATE A NEW USER</b>
        </div>
        <div class="col-xs-12" style="padding: 0px !important; width: 100%; height: 6%; margin-top: 1%;">
          <div *ngIf="!userView" class="col-xs-12 noprovider" style="text-align:center;margin-top:0%;padding:0 !important">
            <div class="col-xs-1"><img src="../../../assets/img/Add Contact.png" style="height:25px;width:100%" (click)="ShowAddUserDiv()" /></div>
            <div class="col-xs-11" style="padding:0">
              <angular2-multiselect [data]="usersDropdownList" [(ngModel)]="userDrpModel"
                                    [settings]="SingleDrpSetting" name="users"
                                    (onSelect)="onUserConfigItemSelect($event)">
              </angular2-multiselect>



            </div>

          </div>
          <div *ngIf="userView" class="col-xs-12" style="padding: 0; background: rgb(40,62,74); color: white; text-align: center; height: 30px;">
            <label style="margin:0;margin-top:0.5%">User Registration & Privileges</label>
            <span class="pull-right" style="text-align:left;color: gray;font-size:12px; font-weight: normal;cursor: pointer;padding:2px;" (click)="closeuser()">
              <b>
                <img src="../../../assets/img/White x.png" style="height:24px" />
              </b>
            </span>
          </div>

        </div>

        <div class="col-xs-12" style="padding-right: 0;padding-left: 0;width:100%;height:92%;overflow:auto">

          <div *ngIf="(selectedUserType=='Users'|| selectedUserType=='Suspended' ||selectedUserType=='Role') && !userView" class="col-xs-12 noprovider" style="padding:0%;margin-top:0%;height:100%" (click)="DivClick($event)">
            <div class="col-xs-12" style="padding:0;height:100%" *ngIf="selectedUserType!='Role'">
              <ag-grid-angular class="ag-theme-balham" *ngIf="selectedUserType=='Users'|| selectedUserType=='Suspended'"
                               style="height:100%"
                               [gridOptions]="gridOptionsusers"
                               [columnDefs]="columnDefsusers"
                               [defaultColDef]="applicationComponent.defaultGridColDef"
                               [rowData]="allusers"
                               [context]="context"
                               [sideBar]="sideBar"
                               (selectionChanged)="onSelectionChanged($event)"
                               (cellValueChanged)="onCellValueChanged($event)"
                               (cellDoubleClicked)="cellDoubleClicked($event)"
                               [suppressDragLeaveHidesColumns]="true"
                               (columnVisible)="columnVisible($event)"
                               (dragStopped)="dragStopped($event)"
                               (columnResized)="columnResized($event)"
                               (gridReady)="onAgGridReadyusers($event)">
              </ag-grid-angular>
            </div>
            <div *ngIf="selectedUserType=='Role'" style="height:100%">
              <role (sendMsgEvent)="RoleeventReceived($event)">

              </role>

            </div>



          </div>
          <div *ngIf="userView" class="col-xs-12" style="text-align:center;padding:0.75%;margin-top:0%;height:100%;">
            <div class="col-xs-3" style="padding:0">
              <div style="height:100%;margin: 1%;" class="col-xs-12">
                <img [src]="urlImage" height="100" style="width:100%" *ngIf="urlImage">
                <input id="addProfileFiles" name="addProfileFiles" value="addFiles" type="file" (change)="addFiles($event)" accept=".jpg,.jpeg" style="display:contents" />

                <label for="addProfileFiles" style="color:#ddd;width:100%;height:100%;margin-bottom:0;" *ngIf="!urlImage">
                  <!--<i class="fa fa-camera" aria-hidden="true" *ngIf="!urlImage" for="addProfileFiles" style="margin:0;"></i>-->
                  <img src="../../../assets/img/default patient photo.png" style="height:62%;width:100%" *ngIf="!urlImage" />
                </label>
                <input id="AddFilesInput1" name="AddFilesInput1" value="AddFilesInput1" type="file" (change)="addFiles($event)" accept=".jpg,.jpeg" style="display:contents" />
                <label for="AddFilesInput1" style="border:0.75px solid #ddd;border-radius:5px;width:100%;text-align:center;height: 24px;padding: 0;margin-bottom: 0;background-color: #ddd;font-size:0.8vw" *ngIf="urlImage">Replace Picture </label>
              </div>
            </div>
            <div class="col-xs-9" style="border: 1px solid #ddd; border-radius:3px; padding:0;text-align:left;font-size:0.75em !important ">

              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Title:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.titleName}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>First Name:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.firstname}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Middle Name:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.middleName}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Last Name:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.lastname}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Suffix:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.suffixName}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>User Status:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.UserStatus}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>User Role:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.RoleName}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>System Admin:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.systemadministrator == 1 ? 'Yes': 'No'}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Create Task Group:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.TaskGroupName}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Sales Quota:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.Quotaid == 445 ? 'Yes': 'No'}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Deal Approval:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.dealApproval}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Document Access:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{documentAccessName}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>MySalesX Access:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{mySalesXAccessName}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>TimeZone:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.timezone}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Release Notes Admin:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.releaseNoteAddEdit == 1 ? 'Yes': 'No'}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Customer Type:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.customertypeaccess == 1 ? 'Yes': 'No'}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Customer Acct#:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.customerAccount == 1 ? 'Yes': 'No'}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Customer Status:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.customerStatus == 1 ? 'Yes': 'No'}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Tickets: Task Group Access:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.TaskGroupName}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Zendesk Permission:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.ZendeskPermissionName}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Email*:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.email}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Password:</label>
                </div>
                <div class="col-xs-7">
                  <span>********</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Email Configuration:</label>
                </div>
                <div class="col-xs-7">
                  <span>{{user.isSendGrid == true ? 'Send Grid' : ''}} {{user.isSMTP == true ? 'SMTP' : ''}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>User Access:</label>
                </div>
                <div class="col-xs-7">
                  <div *ngFor="let selecteduser of user.selectedItems">
                    <span>{{selecteduser.itemName}} {{selecteduser.category}}</span>
                  </div>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>Task Group Access:</label>
                </div>
                <div class="col-xs-7">
                  <div *ngFor="let selecteduser of user.TaskGroupselectedItems">
                    <span>{{selecteduser.itemName}}</span>
                  </div>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5">
                  <label>My SalesXCRM User Access:</label>
                </div>
                <div class="col-xs-7">
                  <div *ngFor="let selecteduser of user.SalesXCRMacesselectedItems">
                    <span>{{selecteduser.itemName}} {{selecteduser.category}}</span>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>


      </div>

      <div class="col-xs-2" style="padding: 0; height: 98%; border: 1px solid black; width: 11%" *ngIf="!isRoleTied">    
          <div class="col-xs-12" style="padding-top: 3%; background-color: rgb(40,62,74); height: 5%; color: white; height: 5%; color: white">
           <b>USER OPTIONS</b> 
          </div>       
        <div class="col-xs-12" style="padding: 0; margin-top: 1%; height: 100%; border-right: 5px solid rgb(186,219,152) ">
          <div class="col-xs-12" style="padding: 5px; height: 5%; color: black; line-height: 28px;" (click)="DashboardChanged('Registration')" [style.background]="selectedtab == 'Registration' ? 'rgb(186,219,152)': 'white'">
            <input style="display:none" type="radio" name="tabset" id="tab11" value="tab11" aria-controls="bano" [(ngModel)]="selectedMenuRadio" checked />
            <label for="tab11" style="text-align:center;font-weight:normal" (click)="DashboardChanged('Registration')">Registration</label>
          </div>
          <div class="col-xs-12" style="padding: 5px; height: 5%; color: black; line-height: 28px;" (click)="DashboardChanged('AdminAccess')" [style.background]="selectedtab == 'AdminAccess' ? 'rgb(186,219,152)': 'white'">
            <input style="display:none" type="radio" name="tabset" id="tab12" value="tab12" aria-controls="bano" [(ngModel)]="selectedMenuRadio" checked />
            <label for="tab12" style="text-align:center;font-weight:normal" (click)="DashboardChanged('AdminAccess')">Admin Access</label>
          </div>
          <div class="col-xs-12" style="padding: 5px; height: 5%; color: black; line-height: 28px;" (click)="DashboardChanged('Alerts')" [style.background]="selectedtab == 'Alerts' ? 'rgb(186,219,152)': 'white'">
            <input style="display:none" type="radio" name="tabset" id="tab13" value="tab13" [(ngModel)]="selectedMenuRadio" aria-controls="banoo" />
            <label for="tab13" style="text-align: center; font-weight: normal" (click)="DashboardChanged('Alerts')">Alerts</label>
          </div>

          <div class="col-xs-12" style="padding: 5px; height: 5%; color: black; line-height: 28px;" (click)="DashboardChanged('CustomerEditing')" [style.background]="selectedtab == 'CustomerEditing' ? 'rgb(186,219,152)': 'white'">
            <input style="display:none" type="radio" name="tabset" id="tab14" value="tab14" [(ngModel)]="selectedMenuRadio" aria-controls="banoo" />
            <label for="tab14" style="text-align: center; font-weight: normal" (click)="DashboardChanged('CustomerEditing')">Customer Editing</label>
          </div>

          <div class="col-xs-12" style="padding: 5px; height: 5%; color: black; line-height: 28px;" (click)="DashboardChanged('CustomerView')" [style.background]="selectedtab == 'CustomerView' ? 'rgb(186,219,152)': 'white'">
            <input style="display:none" type="radio" name="tabset" id="tab22" value="tab22" [(ngModel)]="selectedMenuRadio" aria-controls="banoo" />
            <label for="tab22" style="text-align: center; font-weight: normal" (click)="DashboardChanged('CustomerView')">Customer View</label>
          </div>

          <div class="col-xs-12" style="padding: 5px; height: 5%; color: black; line-height: 28px;" (click)="DashboardChanged('Dashboard')" [style.background]="selectedtab == 'Dashboard' ? 'rgb(186,219,152)': 'white'">
            <input type="radio" name="tabset" id="tab15" value="tab15" [(ngModel)]="selectedMenuRadio" aria-controls="rauchbier" style="display:none">
            <label for="tab15" style="text-align: center; font-weight: normal" (click)="DashboardChanged('Dashboard')">Dashboards</label>
          </div>
          <div class="col-xs-12" style="padding: 5px; height: 5%; color: black; line-height: 28px;" (click)="DashboardChanged('DocumentsGroups')" [style.background]="selectedtab == 'DocumentsGroups' ? 'rgb(186,219,152)': 'white'">
            <input type="radio" name="tabset" id="tab16" value="tab16" [(ngModel)]="selectedMenuRadio" aria-controls="rauchbier" style="display:none">
            <label for="tab16" style="text-align: center; font-weight: normal" (click)="DashboardChanged('DocumentsGroups')">Documents & Groups</label>
          </div>
          <div class="col-xs-12" style="padding: 5px; height: 5%; color: black; line-height: 28px;" (click)="DashboardChanged('EmailText')" [style.background]="selectedtab == 'EmailText' ? 'rgb(186,219,152)': 'white'">
            <input type="radio" name="tabset" id="tab17" value="tab17" [(ngModel)]="selectedMenuRadio" aria-controls="rauchbier" style="display:none">
            <label for="tab17" style="text-align: center; font-weight: normal" (click)="DashboardChanged('EmailText')">Email & Texting</label>
          </div>
          <div class="col-xs-12" style="padding: 5px; height: 5%; color: black; line-height: 28px;" (click)="DashboardChanged('Privileges')" [style.background]=" selectedtab == 'Privileges' ? 'rgb(186,219,152)': 'white'">
            <input style="display:none" type="radio" name="tabset" id="tab18" value="tab18" [(ngModel)]="selectedMenuRadio" aria-controls="marzen">
            <label for="tab18" style="text-align: center; font-weight: normal" (click)="DashboardChanged('Privileges')">Privileges</label>
          </div>
          <div class="col-xs-12" style="padding: 5px; height: 5%; color: black; line-height: 28px;" (click)="DashboardChanged('Sales')" [style.background]="selectedtab == 'Sales' ? 'rgb(186,219,152)': 'white'">
            <input type="radio" name="tabset" aria-controls="tej" id="tab20" value="tab20" [(ngModel)]="selectedMenuRadio" style="display:none">
            <label for="tab20" style="text-align: center; font-weight: normal" (click)="DashboardChanged('Sales')">Quota - Sales</label>
          </div>
          <div class="col-xs-12" style="padding: 5px; height: 5%; color: black; line-height: 28px;" (click)="DashboardChanged('UserQuota')" [style.background]="selectedtab == 'UserQuota' ? 'rgb(186,219,152)': 'white'">
            <input type="radio" name="tabset" aria-controls="tej" id="tab19" value="tab19" [(ngModel)]="selectedMenuRadio" style="display:none">
            <label for="tab19" style="text-align: center; font-weight: normal" (click)="DashboardChanged('UserQuota')">Quota - Tasks/Tickets</label>
          </div>

          <div class="col-xs-12" style="padding:5px; height: 5%; color: black; line-height: 28px;" (click)="DashboardChanged('UserAccess')" [style.background]="selectedtab == 'UserAccess' ? 'rgb(186,219,152)': 'white'">
            <input type="radio" name="tabset" aria-controls="tej" id="tab21" value="tab21" [(ngModel)]="selectedMenuRadio" style="display:none">
            <label for="tab21" style="text-align: center; font-weight: normal" (click)="DashboardChanged('UserAccess')">User Access</label>
          </div>

        </div>
      </div>


      <div class="col-xs-5" style="padding:0;height:100%;width:47%">

        <div *ngIf="(this.selectedMenuRadio == 'tab11') && showAddUserDiv" class="col-xs-12" style="padding: 0 0% 0 0.5%;width:100%;height:99%" id="UCForm">
          <div class="col-xs-12" style="padding-top: 1%; background-color: rgb(40,62,74); height: 5%; color: white; height: 5%; color: white">
            <b class="col-xs-5" style="text-transform: uppercase;">User:&nbsp;{{emailAlertUsername}} {{emailAlertUsername2}}</b>
            <b  style="text-align: left; color: white; text-transform: uppercase;" class="col-xs-6">USER REGISTRATION</b>
          </div>

          <!--<div class="" style="padding:1%;background-color:#2A3740;height:5%">
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-5" style="padding:0;"><b style="font-size:15px;color:#badb98">User:&nbsp;<span>{{emailAlertUsername}}</span>&nbsp;<span>{{emailAlertUsername2}}</span></b></div>
              <div class="col-xs-6" style="text-align:left;color:white">User Registration</div>
            </div>

          </div>-->
          <div class="col-xs-12" style="border: 1px solid #ddd; overflow:auto;  border-radius:3px; padding:0;font-size: 0.77vw;height:86% ">

            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>First Name*</label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <div class="col-xs-12" style="padding:0 0 0 0 "><input id="firstname" name="user.firstname" class="form-control" [(ngModel)]="user.firstname" placeholder="Enter First Name" (keyup)="handleClick($event)" required></div>


              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label> Middle Name </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <div class="col-xs-12" style="padding:0 0 0 0 "><input id="middlename" name="user.middlename" class="form-control" [(ngModel)]="user.middleName" placeholder="Enter Middle Name" (keyup)="handleClick($event)"></div>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label> Last Name*  </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <div class="col-xs-12" style="padding:0 0 0 0 "><input id="lastname" name="user.lastname" class="form-control" [(ngModel)]="user.lastname" placeholder="Enter Last Name" (keyup)="handleClick($event)" required></div>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Suffix </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.suffix" class="form-control" [(ngModel)]="user.suffix" (change)="handleClick($event)">
                  <option *ngFor="let suffix of  usersuffixlist" value={{suffix.id}}>
                    {{suffix.itemName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Cell Phone* </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <input id="cellphone" name="user.cellphone" class="form-control" [(ngModel)]="user.cellphone" placeholder="Enter Cell Phone" [textMask]="{mask: phonemask}" (keyup)="handleClick($event)" required>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label> User Status  </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.userstatusid" class="form-control" [(ngModel)]="user.userstatusid" (change)="handleClick($event)" required>
                  <option *ngFor="let stat of  userstatuslist" value={{stat.id}}>
                    {{stat.itemName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label> User Role  </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select id="userrole" name="user.userrole" class="form-control" [(ngModel)]="user.userrole" required (change)="loadroleacess($event.target.value);handleClick($event)">
                  <option value="null" disabled="disabled" [selected]="true">Select Role</option>Group
                  <option *ngFor="let role of  rolesList" value={{role.id}}>
                    {{role.itemName}}
                  </option>
                </select>
              </div>
            </div>

            <!--<div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label> System Admin  </label>
      </div>
      <div class="col-xs-8" style="padding:0 0.5% 0 0;" (click)="systemAdminClick()">
        <select id="systemAdmindrp" name="user.systemadministrator" class="form-control" [(ngModel)]="user.systemadministrator" (change)="handleClick($event)" required [disabled]="currentuser.systemadministrator == 0">
          <option value="0">No</option>
          <option value="1">Yes</option>
        </select>
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label> Create Task Group  </label>
      </div>
      <div class="col-xs-8" style="padding:0 0.5% 0 0;">

        <angular2-multiselect [data]="TasksGroups"
                              [(ngModel)]="user.SelectedTaskGroupids"
                              name="SelectedTaskGroupids"
                              [settings]="TaskGroupdropdownSettings">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label> Sales Quota </label>
      </div>
      <div class="col-xs-8" style="padding:0 0.5% 0 0;">
        <select name="user.Quotaid" class="form-control" [(ngModel)]="user.Quotaid" (change)="handleClick($event)" required>
          <option *ngFor="let quota of  QuotaList" value={{quota.id}}>
            {{quota.itemName}}
          </option>
        </select>
      </div>
    </div>

    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label> Document Access</label>
      </div>
      <div class="col-xs-8" style="padding:0 0.5% 0 0;">
        <select name="user.permissionlevelid" class="form-control" [(ngModel)]="user.permissionlevelid" (change)="handleClick($event)" required>
          <option *ngFor="let per of  permissionlevellist" value={{per.id}}>
            {{per.itemName}}
          </option>
        </select>
      </div>
    </div>-->

            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
             
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>
                  Time Zone
                </label>

              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.timezoneid" class="form-control" [(ngModel)]="user.timezone" (change)="handleClick($event)" required>
                  <option value="null" [selected]="true">Select Time Zone</option>
                  <option *ngFor="let zone of timezonelist" value={{zone.itemName}}>
                    {{zone.itemName}}
                  </option>
                </select>
              </div>
            </div>

            <!--<div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label> Release Notes Admin </label>
      </div>
      <div class="col-xs-8" style="padding:0 0.5% 0 0;">
        <select name="user.releaseNoteAddEdit" class="form-control" [(ngModel)]="user.releaseNoteAddEdit" (change)="handleClick($event)" required>
          <option value="0">No</option>
          <option value="1">Yes</option>
        </select>
      </div>
    </div>

    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label> Software License Admin </label>
      </div>
      <div class="col-xs-8" style="padding:0 0.5% 0 0;">
        <select name="user.SoftwareLicenseAdmin" class="form-control" [(ngModel)]="user.SoftwareLicenseAdmin" (change)="handleClick($event)" required>
          <option value="0" selected>No</option>
          <option value="1">Yes</option>
        </select>
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label> Statistics Add/Edit </label>
      </div>
      <div class="col-xs-8" style="padding:0 0.5% 0 0;">
        <select name="user.StatisticsAddEdit" class="form-control" [(ngModel)]="user.StatisticsAddEdit" (change)="handleClick($event)" required>
          <option value="0" selected>No</option>
          <option value="1">Yes</option>
        </select>
      </div>
    </div>

    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label> Customers Type </label>
      </div>
      <div class="col-xs-8" style="padding:0 0.5% 0 0;">
        <select name="user.customertypeaccess" class="form-control" [(ngModel)]="user.customertypeaccess" (change)="handleClick($event)" required>
          <option value="0">No</option>
          <option value="1">Yes</option>
        </select>
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label>Customer Account # </label>
      </div>
      <div class="col-xs-8" style="padding:0 0.5% 0 0;">
        <select name="user.customerAccount" class="form-control" [(ngModel)]="user.customerAccount" (change)="handleClick($event)" required>
          <option value="0">No</option>
          <option value="1">Yes</option>
        </select>
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label>Customer Status </label>
      </div>
      <div class="col-xs-8" style="padding:0 0.5% 0 0;">
        <select name="user.customerStatus" class="form-control" [(ngModel)]="user.customerStatus" (change)="handleClick($event)" required>
          <option value="0">No</option>
          <option value="1">Yes</option>
        </select>
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label>Tickets(Task Group Access)</label>
      </div>
      <div class="col-xs-8" style="padding:0 0.5% 0 0;">
        <select name="user.taskGroupAccessForTickets" class="form-control" [(ngModel)]="user.taskGroupAccessForTickets" (change)="handleClick($event)" required>
          <option value="null" disabled="disabled" [selected]="true">All Task Groups</option>
          <option *ngFor="let taskgroup of taskgrouplist" value={{taskgroup.id}}>
            {{taskgroup.itemName}}
          </option>
        </select>
        <select id="userrole" class="form-control">
          <option value="null" disabled="disabled" [selected]="true">All Task Groups</option>

        </select>
      </div>

    </div>

    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label>Zendesk Permission</label>
      </div>
      <div class="col-xs-8" style="padding:0 0.5% 0 0;">
        <select id="Zendseskpermissionid" name="user.Zendseskpermissionid" class="form-control" [(ngModel)]="user.Zendseskpermissionid" (change)="handleClick($event)">
          <option value="null" disabled="disabled" [selected]="true">Select Zendesk Permission</option>

          <option *ngFor="let zendesk of  ZendeskPermissionList" value={{zendesk.id}}>
            {{zendesk.itemName}}
          </option>
        </select>
      </div>
    </div>-->
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0 0.5% 0 0;height:7.8%">
             

              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Email* </label>
              </div>
              <div class="col-xs-8" style="padding:0 0 0 0;">
                <input type="email" [(ngModel)]="user.email" class="form-control" placeholder="Enter email" name="myEmail" #myEmail="ngModel" email pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required>
              </div>

            </div>

            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Password<label><span *ngIf="user.id==undefined || user.id==0 || user.id==null  || showPassword">*</span></label></label>

              </div>

              <div class="col-xs-8" style="padding:0 0.5% 0 0;">

                <div class="col-xs-12" style="padding:0;">
                  <div class="col-xs-12" style="padding:0">

                    <input type="password" class="form-control" id="password"
                           [(ngModel)]="user.password" name="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                           #password="ngModel" style="" placeholder="Password" required (keypress)="handleClick($event);" (focusout)="ValidatePassword($event)">
                    <span id="passwordEye" class="fa fa-fw fa-eye field-icon toggle-password pull-left" (click)="showhide()" style="float: right !important; margin-top: -22px"></span>
                  </div>
                </div>
              </div>
            </div>
            <!--<div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;">
        <label>Email Configuration</label>
      </div>
      <div class="col-xs-4" style="padding-right:0;padding-left:0">
        <input type="radio" name="email" value="SendGrid" [checked]="user.isSendGrid" (change)="handleClick($event)"> Send Grid<br>
      </div>
      <div class="col-xs-4" style="padding-right:0">
        <input type="radio" name="email" value="SMTP" [checked]="user.isSMTP" (change)="handleClick($event)"> SMTP<br>
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label>Users Access</label>
      </div>
      <div *ngIf="dropdownList?.length > 0" class="col-xs-8" style="padding:0 0.5% 0 0;">
        <angular2-multiselect [data]="dropdownList"
                              name="selectedItems"
                              [(ngModel)]="user.selectedItems"
                              [settings]="dropdownSettings"
                              (onSelect)="onItemSelect($event)"
                              (onDeSelect)="OnItemDeSelect($event)"
                              (onSelectAll)="onSelectAll($event)"
                              (onDeSelectAll)="onDeSelectAll($event)">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label>Task Groups Access</label>
      </div>
      <div *ngIf="TasksGroups?.length > 0" class="col-xs-8" style="padding:0 0.5% 0 0;">
        <angular2-multiselect [data]="TasksGroups" id="TaskGroupDropDown"
                              name="TaskGroupselectedItems"
                              [(ngModel)]="user.TaskGroupselectedItems"
                              [settings]="TaskGroupdropdownSettings"
                              (onSelect)="onTaskGroupAccessSelect($event)"
                              (onDeSelect)="OnTaskGroupItemDeSelect($event)"
                              (onSelectAll)="onTaskGroupSelectAll($event)"
                              (onDeSelectAll)="onTaskGroupDeSelectAll($event)">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label>Account Sharing Groups </label>
      </div>
      <div *ngIf="TasksGroups?.length > 0" class="col-xs-8" style="padding:0 0.5% 0 0;">
        <angular2-multiselect [data]="AccountGroupList" id="AccountGroupDropDown"
                              name="AccountGroupselectedItems"
                              [(ngModel)]="user.AccountGroupselectedItems"
                              [settings]="TaskGroupdropdownSettings">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
      <div class="col-xs-4" style="padding: 5px;text-align: left;">
        <label>My SalesXCRM Access</label>
      </div>
      <div *ngIf="dropdownList?.length > 0" class="col-xs-8" style="padding:0 0.5% 0 0;">
        <angular2-multiselect [data]="dropdownList"
                              name="SalesXCRMacesselectedItems"
                              [(ngModel)]="user.SalesXCRMacesselectedItems"
                              [settings]="dropdownSettings"
                              (onSelect)="onItemSelectAccess($event)"
                              (onDeSelect)="OnItemDeSelectAccess($event)"
                              (onSelectAll)="onSelectAllAccess($event)"
                              (onDeSelectAll)="onDeSelectAllAccess($event)">
        </angular2-multiselect>

      </div>
    </div>-->

          </div>
          <div class="col-xs-12" style="padding:0;height:7%">
            <div class="col-xs-4" style="float:right;padding:0;height:100%">
              <div class="col-xs-6" style="height: 100%">
                <button (click)="CancelUser()" id="CancelData" style="background:#d3d3d3;margin-top:5px;height:100%" class="btn btn-long provider saveButtonCSS">Cancel </button>
              </div>
              <div class="col-xs-6" style="height: 100%">
                <button (click)="RegisterUser('save')" id="SaveData" style="margin-top:5px;height:100%" [style.background]="(userform.form.valid)?'#93d250':'#d3d3d3'" class="btn btn-long provider saveButtonCSS" [disabled]="!userform.form.valid">
                  Save
                </button>
              </div>


            </div>
          </div>
        </div>

        <div *ngIf="this.selectedMenuRadio == 'tab12'" class="col-xs-12" style="padding: 0 0% 0 0.5%;width:100%;height:99%" id="AdminAccessForm">
          <div class="" style="padding: 1%; background-color: rgb(40,62,74); height: 5%">
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-5" style="padding:0;"><b style="font-size:15px;color:#badb98">User:&nbsp;<span>{{emailAlertUsername}}</span>&nbsp;<span>{{emailAlertUsername2}}</span></b></div>
              <div class="col-xs-6" style="text-align:left;color:white">ADMIN ACCESS</div>
            </div>

          </div>
          <div class="col-xs-12" style="border: 1px solid #ddd; overflow:auto;  border-radius:3px; padding:0;font-size: 0.77vw;height:86% ">

            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label> System Admin  </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;" (click)="systemAdminClick()">
                <select id="systemAdmindrp" name="user.systemadministrator" class="form-control" [(ngModel)]="user.systemadministrator" (change)="handleClick($event)" required [disabled]="currentuser.systemadministrator == 0">
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label> Create Task Group  </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">

                <angular2-multiselect [data]="TasksGroups"
                                      [(ngModel)]="user.SelectedTaskGroupids"
                                      name="SelectedTaskGroupids"
                                      [settings]="TaskGroupdropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
            <!-- <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
          <div class="col-xs-4" style="padding: 5px;text-align: left;">
            <label> Sales Quota </label>
          </div>
          <div class="col-xs-8" style="padding:0 0.5% 0 0;">
            <select name="user.Quotaid" class="form-control" [(ngModel)]="user.Quotaid" (change)="handleClick($event)" required>
              <option *ngFor="let quota of  QuotaList" value={{quota.id}}>
                {{quota.itemName}}
              </option>
            </select>
          </div>
        </div>

        <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
          <div class="col-xs-4" style="padding: 5px;text-align: left;">
            <label> Document Access</label>
          </div>
          <div class="col-xs-8" style="padding:0 0.5% 0 0;">
            <select name="user.permissionlevelid" class="form-control" [(ngModel)]="user.permissionlevelid" (change)="handleClick($event)" required>
              <option *ngFor="let per of  permissionlevellist" value={{per.id}}>
                {{per.itemName}}
              </option>
            </select>
          </div>
        </div>-->
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label> Release Notes Admin </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.releaseNoteAddEdit" class="form-control" [(ngModel)]="user.releaseNoteAddEdit" (change)="handleClick($event)" required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>

            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label> Software License Admin </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.SoftwareLicenseAdmin" class="form-control" [(ngModel)]="user.SoftwareLicenseAdmin" (change)="handleClick($event)" required>
                  <option value="0" selected>No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label> Statistics Add/Edit </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.StatisticsAddEdit" class="form-control" [(ngModel)]="user.StatisticsAddEdit" (change)="handleClick($event)" required>
                  <option value="0" selected>No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>

          </div>
          <div class="col-xs-12" style="padding:0;height:7%">
            <div class="col-xs-4" style="float:right;padding:0;height:100%">
              <div class="col-xs-6" style="height: 100%">
                <button (click)="CancelUser()" id="CancelData" style="background:#d3d3d3;margin-top:5px;height:100%" class="btn btn-long provider saveButtonCSS">Cancel </button>
              </div>
              <div class="col-xs-6" style="height: 100%">
                <button (click)="RegisterUser('save')" id="SaveData" style="margin-top:5px;height:100%" [style.background]="(userform.form.valid)?'#93d250':'#d3d3d3'" class="btn btn-long provider saveButtonCSS" [disabled]="!userform.form.valid">
                  Save
                </button>
              </div>


            </div>
          </div>
        </div>
        <div *ngIf="(selectedMenuRadio == 'tab19' ||selectedMenuRadio == 'tab20') && (this.user.id != undefined && this.user.id != null && this.user.id != 0)" class="col-xs-12" style="padding: 0;width:100%;height:82%">
          <div *ngIf="selectedMenuRadio == 'tab19'" class="Quotatabset" style="padding:0;">
            <div class="" style="padding: 1%; background-color: rgb(40,62,74); display: flex; margin-bottom: 0.5%;">
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5" style="padding:0;"><b style="font-size:15px;color:#badb98">User:&nbsp;<span>{{emailAlertUsername}}</span>&nbsp;<span>{{emailAlertUsername2}}</span></b></div>
                <div class="col-xs-6" style="text-align:left;color:white">User Quota</div>
              </div>

            </div>
            <input type="radio" name="Quotatabset" id="Quotatab1" value="Quotatab1" [(ngModel)]="selectedQuotaMenuRadio" aria-controls="tej" style="margin-left: 1px">
            <label for="Quotatab1" style="text-align:center;width:19.7%" (click)="UserQuota('TaskQuota')">User Task Quota</label>
            <input type="radio" name="Quotatabset" id="Quotatab2" value="Quotatab2" [(ngModel)]="selectedQuotaMenuRadio" aria-controls="tej" style="margin-left: 1px">
            <label for="Quotatab2" style="text-align:center;width:19.7%" (click)="UserQuota('TicketQuota')">User Ticket Quota</label>

            <input type="radio" name="Quotatabset" id="Quotatab3" aria-controls="tej" style="margin-left: 1px">
            <label for="Quotatab3" style="text-align: center; width: 19.7%; color: white">u</label>

            <input type="radio" name="Quotatabset" id="Quotatab4" aria-controls="banoo" />
            <label for="Quotatab4" style="text-align:center;color:white;width:19.5%">u</label>
            <input type="radio" name="Quotatabset" id="Quotatab5" aria-controls="marzen">
            <label for="Quotatab5" style="text-align:center;width:19.7%;color:white">u</label>
          </div>
          <div *ngIf="selectedMenuRadio == 'tab20'" class="Quotatabset" style="padding:0;">
            <div class="" style="padding: 1%; background-color: rgb(40,62,74); display: flex; margin-bottom: 0.5%;">
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-5" style="padding:0;"><b style="font-size:15px;color:#badb98">User:&nbsp;<span>{{emailAlertUsername}}</span>&nbsp;<span>{{emailAlertUsername2}}</span></b></div>
                <div class="col-xs-6" style="text-align:left;color:white">Sales Quota</div>
              </div>

            </div>

            <input type="radio" name="Quotatabset" id="Quotatab3" value="Quotatab3" [(ngModel)]="selectedQuotaMenuRadio" aria-controls="tej" style="margin-left: 1px">
            <label for="Quotatab3" style="text-align:center;width:19.7%" (click)="UserQuota('SalesQuota')">User Sales Quota</label>

            <input type="radio" name="Quotatabset" id="Quotatab1" aria-controls="tej" style="margin-left: 1px">
            <label for="Quotatab1" style="text-align: center; width: 19.7%; color: white">u</label>
            <input type="radio" name="Quotatabset" id="Quotatab2" aria-controls="tej" style="margin-left: 1px">
            <label for="Quotatab2" style="text-align: center; width: 19.7%; color: white">u</label>


            <input type="radio" name="Quotatabset" id="Quotatab4" aria-controls="banoo" />
            <label for="Quotatab4" style="text-align:center;color:white;width:19.5%">u</label>
            <input type="radio" name="Quotatabset" id="Quotatab5" aria-controls="marzen">
            <label for="Quotatab5" style="text-align:center;width:19.7%;color:white">U</label>
          </div>
          <userquota [userid]="this.user.id" [type]="selectedQuotaType" (sendMsgEvent)="onuserConfiguration($event)"> </userquota>
        </div>

        <div *ngIf="(this.selectedMenuRadio == 'tab13') && showAlertsDiv" class="col-xs-12" style="padding: 0;width:100%;height:98.5%;">
          <automated-emails [emailAlertUsername]="emailAlertUsername" [emailAlertUsername2]="emailAlertUsername2" [userid]="this.user.id" (sendMsgEvent)="onuserConfiguration($event)">:</automated-emails>
        </div>

        <div *ngIf="this.selectedMenuRadio == 'tab14'" class="col-xs-12" style="padding: 0 0% 0 0.5%;width:100%;height:99%" id="CustomerEditingForm">
          <div class="" style="padding: 1%; background-color: rgb(40,62,74); height: 5%">
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-5" style="padding:0;"><b style="font-size:15px;color:#badb98">User:&nbsp;<span>{{emailAlertUsername}}</span>&nbsp;<span>{{emailAlertUsername2}}</span></b></div>
              <div class="col-xs-6" style="text-align:left;color:white">CUSTOMER EDITING</div>
            </div>

          </div>
          <div class="col-xs-12" style="border: 1px solid #ddd; overflow:auto;  border-radius:3px; padding:0;font-size: 0.77vw;height:86% ">
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Is Customer</label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.isCustomer" class="form-control" [(ngModel)]="user.isCustomer" (change)="handleClick($event)" required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Customer Status </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.customerStatus" class="form-control" [(ngModel)]="user.customerStatus" (change)="handleClick($event)" required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Customer Since </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.customerSince" class="form-control" [(ngModel)]="user.customerSince" (change)="handleClick($event)" required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>

            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Customer Account # </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.customerAccount" class="form-control" [(ngModel)]="user.customerAccount" (change)="handleClick($event)" required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>System Type </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.SystemType" class="form-control" [(ngModel)]="user.SystemType"  required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>HL7 Interface</label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.HL7interface" class="form-control" [(ngModel)]="user.HL7interface" required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>RCM</label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.CustomerRCM" class="form-control" [(ngModel)]="user.CustomerRCM"  required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>On-Demand </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.OnDemand" class="form-control" [(ngModel)]="user.OnDemand" required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Appt Reminders </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.ApptReminders" class="form-control" [(ngModel)]="user.ApptReminders" required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>eLab </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.eLab" class="form-control" [(ngModel)]="user.eLab"  required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>MIPS </label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.MIPS" class="form-control" [(ngModel)]="user.MIPS"  required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>



          </div>
          <div class="col-xs-12" style="padding:0;height:7%">
            <div class="col-xs-4" style="float:right;padding:0;height:100%">
              <div class="col-xs-6" style="height: 100%">
                <button (click)="CancelUser()" id="CancelData" style="background:#d3d3d3;margin-top:5px;height:100%" class="btn btn-long provider saveButtonCSS">Cancel </button>
              </div>
              <div class="col-xs-6" style="height: 100%">
                <button (click)="RegisterUser('CustomerEdit')" id="SaveData" style="margin-top:5px;height:100%" [style.background]="(userform.form.valid)?'#93d250':'#d3d3d3'" class="btn btn-long provider saveButtonCSS" [disabled]="!userform.form.valid">
                  Save
                </button>
              </div>


            </div>
          </div>
        </div>

        <div *ngIf="this.selectedMenuRadio == 'tab22'" class="col-xs-12" style="padding: 0 0% 0 0.5%;width:100%;height:99%" id="CustomerViewForm">
          <div class="" style="padding: 1%; background-color: rgb(40,62,74); height: 5%">
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-5" style="padding:0;"><b style="font-size:15px;color:#badb98">User:&nbsp;<span>{{emailAlertUsername}}</span>&nbsp;<span>{{emailAlertUsername2}}</span></b></div>
              <div class="col-xs-6" style="text-align:left;color:white">CUSTOMER VIEW</div>
            </div>
          </div>
          <div class="col-xs-12" style="border: 1px solid #ddd; overflow:auto;  border-radius:3px; padding:0;font-size: 0.77vw;height:86%">
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Default Search Page Bar</label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.defaultSearchPage" class="form-control" [(ngModel)]="user.defaultSearchPage" (change)="handleClick($event)" required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Hide Customers - Must Search</label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.hidecustomer" class="form-control" [(ngModel)]="user.hidecustomer"  required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
          </div>
       
          <div class="col-xs-12" style="padding:0;height:7%">
            <div class="col-xs-4" style="float:right;padding:0;height:100%">
              <div class="col-xs-6" style="height: 100%">
                <button (click)="CancelUser()" id="CancelData" style="background:#d3d3d3;margin-top:5px;height:100%" class="btn btn-long provider saveButtonCSS">Cancel </button>
              </div>
              <div class="col-xs-6" style="height: 100%">
                <button (click)="RegisterUser('customerview')" id="SaveData" style="margin-top:5px;height:100%" [style.background]="(userform.form.valid)?'#93d250':'#d3d3d3'" class="btn btn-long provider saveButtonCSS" [disabled]="!userform.form.valid">
                  Save
                </button>
              </div>


            </div>
          </div>
        </div>

        <div *ngIf="(this.selectedMenuRadio == 'tab15') && showUserDashBoardDiv" class="col-xs-12" style="padding: 0.1%;  height: 98%; ">
          <div class="col-xs-12" style="padding: 1%; background-color: rgb(40,62,74); height: 5%; margin-bottom: 0.5%;">
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-5" style="padding:0;"><b style="font-size:15px;color:#badb98">User:&nbsp;<span>{{emailAlertUsername}}</span>&nbsp;<span>{{emailAlertUsername2}}</span></b></div>
              <div class="col-xs-6" style="text-align:left;color:white">User Dashboards</div>
            </div>

          </div>
          <div class="col-xs-6" style=" height: 94.5%; border: 1px solid #ccc;padding:0 ">
            <ag-grid-angular class="ag-theme-balham" style="height:89%;width:100%"
                             [gridOptions]="DashboardgridOptions" [sideBar]="sideBar"
                             [columnDefs]="DashboardcolumnDefs"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="DashBoardTypesList">
            </ag-grid-angular>
            <div class="col-xs-12" style="padding:0;height:8%">
              <div class="col-xs-6" style="float: right; height: 9%; padding-top: 3%;">

                <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width: 100%; background: white; padding: 5px 7px;" (click)="CancelUserDashBoard()"><span class="mainCheckMark"></span>Cancel</button></div>
                <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width: 100%; background: #a2e477; padding: 5px 7px;" id="saveDashboardConfig" disabled='disabled' (click)="SaveDashBoardData()"><span class="mainCheckMark"></span>Save</button></div>
              </div>
            </div>
          </div>
          <div class="col-xs-6" style=" height: 94.5%; border: 1px solid #ccc; padding: 0 ">
            <div class="col-xs-12" style="padding: 0.5% 2%; height: 90%">



              <div class="col-xs-12" style="padding:0;height:6%;text-align:center">
                <label style="font-size: 12px; font-weight: bolder; color: black;">My Dashboard User Grid View</label>

              </div>

              <div class="col-xs-12" style="padding:0;height:100%">
                <angular2-multiselect [data]="secondDropdownList" [(ngModel)]="SecondDrpModel" id="Dashsettings" name="selectedDashItems"
                                      [settings]="SingleSecondDrpSetting"
                                      (onSelect)="onGridSettingItemSelect($event)"
                                      (onDeSelect)="onGridSettingItemDeSelect($event)">
                </angular2-multiselect>
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;height:8%">
              <div class="col-xs-6" id="SaveCancel" style="float: right; height: 9%; padding-top: 3%;">

                <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width: 100%; background: white; padding: 5px 7px;" id="CancelOpp" (click)="cancel()"><span class="mainCheckMark"></span>Cancel</button></div>
                <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width: 100%; background: #a2e477; padding: 5px 7px;" (click)="Save();"><span class="mainCheckMark"></span>Save</button></div>
              </div>
            </div>
          </div>

        </div>

        <div *ngIf="(this.selectedMenuRadio == 'tab16')" class="col-xs-12" style="padding: 0 0% 0 0.5%;width:100%;height:99%" id="DocumentGroupForm">
          <div class="" style="padding: 1%; background-color: rgb(40,62,74); height: 5%">
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-5" style="padding:0;"><b style="font-size:15px;color:#badb98">User:&nbsp;<span>{{emailAlertUsername}}</span>&nbsp;<span>{{emailAlertUsername2}}</span></b></div>
              <div class="col-xs-6" style="text-align:left;color:white">DOCUMENTS & GROUPS </div>
            </div>

          </div>
          <div class="col-xs-12" style="border: 1px solid #ddd; overflow:auto;  border-radius:3px; padding:0;font-size: 0.77vw;height:86% ">
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Account Sharing Groups </label>
              </div>
              <div *ngIf="TasksGroups?.length > 0" class="col-xs-8" style="padding:0 0.5% 0 0;">
                <angular2-multiselect [data]="AccountGroupList" id="AccountGroupDropDown"
                                      name="AccountGroupselectedItems"
                                      [(ngModel)]="user.AccountGroupselectedItems"
                                      [settings]="TaskGroupdropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label> Document Access</label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.permissionlevelid" class="form-control" [(ngModel)]="user.permissionlevelid" (change)="handleClick($event)" required>
                  <option *ngFor="let per of  permissionlevellist" value={{per.id}}>
                    {{per.itemName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Task Groups Access</label>
              </div>
              <div *ngIf="TasksGroups?.length > 0" class="col-xs-8" style="padding:0 0.5% 0 0;">
                <angular2-multiselect [data]="TasksGroups" id="TaskGroupDropDown"
                                      name="TaskGroupselectedItems"
                                      [(ngModel)]="user.TaskGroupselectedItems"
                                      [settings]="TaskGroupdropdownSettings"
                                      (onSelect)="onTaskGroupAccessSelect($event)"
                                      (onDeSelect)="OnTaskGroupItemDeSelect($event)"
                                      (onSelectAll)="onTaskGroupSelectAll($event)"
                                      (onDeSelectAll)="onTaskGroupDeSelectAll($event)">
                </angular2-multiselect>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Tickets(Task Group Access)</label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select name="user.taskGroupAccessForTickets" class="form-control" [(ngModel)]="user.taskGroupAccessForTickets" (change)="handleClick($event)" required>
                  <option value="null" disabled="disabled" [selected]="true">All Task Groups</option>
                  <option *ngFor="let taskgroup of taskgrouplist" value={{taskgroup.id}}>
                    {{taskgroup.itemName}}
                  </option>
                </select>

              </div>

            </div>






          </div>
          <div class="col-xs-12" style="padding:0;height:7%">
            <div class="col-xs-4" style="float:right;padding:0;height:100%">
              <div class="col-xs-6" style="height: 100%">
                <button (click)="CancelUser()" id="CancelData" style="background:#d3d3d3;margin-top:5px;height:100%" class="btn btn-long provider saveButtonCSS">Cancel </button>
              </div>
              <div class="col-xs-6" style="height: 100%">
                <button (click)="RegisterUser('save')" id="SaveData" style="margin-top:5px;height:100%" [style.background]="(userform.form.valid)?'#93d250':'#d3d3d3'" class="btn btn-long provider saveButtonCSS" [disabled]="!userform.form.valid">
                  Save
                </button>
              </div>


            </div>
          </div>
        </div>

        <div *ngIf="(this.selectedMenuRadio == 'tab17')" class="col-xs-12" style="padding: 0 0% 0 0.5%;width:100%;height:99%" id="EmailTextForm">
          <div class="" style="padding: 1%; background-color: rgb(40,62,74); height: 5%">
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-5" style="padding:0;"><b style="font-size:15px;color:#badb98">User:&nbsp;<span>{{emailAlertUsername}}</span>&nbsp;<span>{{emailAlertUsername2}}</span></b></div>
              <div class="col-xs-6" style="text-align:left;color:white">Email &Aacute; texting</div>
            </div>

          </div>
          <div class="col-xs-12" style="border: 1px solid #ddd; overflow:auto;  border-radius:3px; padding:0;font-size: 0.77vw;height:86% ">
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;">
                <label>Email Configuration</label>
              </div>
              <div class="col-xs-4" style="padding-right:0;padding-left:0">
                <input type="radio" name="email" value="SendGrid" [checked]="user.isSendGrid" (change)="handleClick($event)"> Send Grid<br>
              </div>
              <div class="col-xs-4" style="padding-right:0">
                <input type="radio" name="email" value="SMTP" [checked]="user.isSMTP" (change)="handleClick($event)"> SMTP<br>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Zendesk Permission</label>
              </div>
              <div class="col-xs-8" style="padding:0 0.5% 0 0;">
                <select id="Zendseskpermissionid" name="user.Zendseskpermissionid" class="form-control" [(ngModel)]="user.Zendseskpermissionid" (change)="handleClick($event)">
                  <option value="null" disabled="disabled" [selected]="true">Select Zendesk Permission</option>

                  <option *ngFor="let zendesk of  ZendeskPermissionList" value={{zendesk.id}}>
                    {{zendesk.itemName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-xs-12" style="padding:0;height:7%">
            <div class="col-xs-4" style="float:right;padding:0;height:100%">
              <div class="col-xs-6" style="height: 100%">
                <button (click)="CancelUser()" id="CancelData" style="background:#d3d3d3;margin-top:5px;height:100%" class="btn btn-long provider saveButtonCSS">Cancel </button>
              </div>
              <div class="col-xs-6" style="height: 100%">
                <button (click)="RegisterUser('save')" id="SaveData" style="margin-top:5px;height:100%" [style.background]="(userform.form.valid)?'#93d250':'#d3d3d3'" class="btn btn-long provider saveButtonCSS" [disabled]="!userform.form.valid">
                  Save
                </button>
              </div>


            </div>
          </div>
        </div>
        <div class="tabset1" *ngIf="(this.selectedMenuRadio == 'tab18')"
             style="padding: 0 0% 0 0.5%;width:100%;height:99%" id="">
          <div style="padding: 1%; background-color: rgb(40,62,74); height: 5%">
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-5" style="padding:0;"><b style="font-size:15px;color:#badb98">User:&nbsp;<span>{{emailAlertUsername}}</span>&nbsp;<span>{{emailAlertUsername2}}</span></b></div>
              <div class="col-xs-6" style="text-align:left;color:white">PRIVILLAGES</div>
            </div>

          </div>
          <input type="radio" name="tabset1" id="prvtab1" value="prvtab1" aria-controls="bano" [(ngModel)]="selectedPrvMenuRadio" checked />
          <label *ngIf="!userView" for="prvtab1" style="text-align:center;width:20%" (click)="PrivilageTabChanged('Display All')">Display All</label>
          <input type="radio" name="tabset1" id="prvtab2" value="prvtab2" [(ngModel)]="selectedPrvMenuRadio" aria-controls="marzen">
          <label for="prvtab2" style="text-align:center;width:19.7%" (click)="PrivilageTabChanged('Navigation Menu')">Navigation Menu</label>
          <input type="radio" name="tabset1" id="prvtab3" value="prvtab3" [(ngModel)]="selectedPrvMenuRadio" aria-controls="rauchbier" style="margin-left: 1px">
          <label for="prvtab3" style="text-align:center;width:19.7%" (click)="PrivilageTabChanged('Action Icon')">Action Icons</label>
          <input type="radio" name="tabset1" id="prvtab4" value="prvtab4" [(ngModel)]="selectedPrvMenuRadio" aria-controls="tej" style="margin-left: 1px">
          <label for="prvtab4" style="text-align:center;width:19.7%" (click)="PrivilageTabChanged('3 Dot Icon')">3 Dot Icon Menu</label>

          <div class="col-xs-12" style="padding: 0;height:100%" id="boxDiv">

            <ag-grid-angular style="height: 91%; " id="gridheight"
                             [rowData]="resourcePrivilegesList"
                             class="ag-theme-balham"
                             [columnDefs]="columnDefs"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [suppressRowClickSelection]="true"
                             [suppressAggFuncInHeader]="true"
                             [groupHideOpenParents]="true"
                             [animateRows]="true"
                             [groupSelectsChildren]="false"
                             [autoGroupColumnDef]="autoGroupColumnDef"
                             [groupDefaultExpanded]="-1"
                             (gridReady)="onAgGridReady($event)">:</ag-grid-angular>

            <div class="col-xs-12" style="padding:0;height:6%">
              <div class="col-xs-4" style="float:right;padding:0;height:100%">
                <div class="col-xs-6" style="height: 100%">
                  <button (click)="CancelUserPrivilages()" style="background:#d3d3d3;margin-top:5px;height:100%" class="btn btn-long provider saveButtonCSS">Cancel </button>
                </div>
                <div class="col-xs-6" style="height: 100%">
                  <button (click)="RegisterUser('Previlages')" id="SaveUserPrivilages" style="background:#d3d3d3;margin-top:5px;height:100%" class="btn btn-long provider saveButtonCSS">Save </button>
                </div>


              </div>
            </div>



          </div>
        </div>

        <div *ngIf="(this.selectedMenuRadio == 'tab21') && showAddUserDiv" class="col-xs-12" style="padding: 0 0% 0 0.5%;width:100%;height:99%" id="UserAccessForm">
          <div class="" style="padding: 1%; background-color: rgb(40,62,74); height: 5%">
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-5" style="padding:0;"><b style="font-size:15px;color:#badb98">User:&nbsp;<span>{{emailAlertUsername}}</span>&nbsp;<span>{{emailAlertUsername2}}</span></b></div>
              <div class="col-xs-6" style="text-align:left;color:white">User Access</div>
            </div>

          </div>
          <div class="col-xs-12" style="border: 1px solid #ddd; overflow:auto;  border-radius:3px; padding:0;font-size: 0.77vw;height:86% ">
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>My SalesXCRM Access</label>
              </div>
              <div *ngIf="dropdownList?.length > 0" class="col-xs-8" style="padding:0 0.5% 0 0;">
                <angular2-multiselect [data]="dropdownList"
                                      name="SalesXCRMacesselectedItems"
                                      [(ngModel)]="user.SalesXCRMacesselectedItems"
                                      [settings]="dropdownSettings"
                                      (onSelect)="onItemSelectAccess($event)"
                                      (onDeSelect)="OnItemDeSelectAccess($event)"
                                      (onSelectAll)="onSelectAllAccess($event)"
                                      (onDeSelectAll)="onDeSelectAllAccess($event)">
                </angular2-multiselect>

              </div>
            </div>

            <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.8%;padding: 0;height:7.8%">
              <div class="col-xs-4" style="padding: 5px;text-align: left;">
                <label>Users Access</label>
              </div>
              <div *ngIf="dropdownList?.length > 0" class="col-xs-8" style="padding:0 0.5% 0 0;">
                <angular2-multiselect [data]="dropdownList"
                                      name="selectedItems"
                                      [(ngModel)]="user.selectedItems"
                                      [settings]="dropdownSettings"
                                      (onSelect)="onItemSelect($event)"
                                      (onDeSelect)="OnItemDeSelect($event)"
                                      (onSelectAll)="onSelectAll($event)"
                                      (onDeSelectAll)="onDeSelectAll($event)">
                </angular2-multiselect>
              </div>
            </div>
          </div>
          <div class="col-xs-12" style="padding:0;height:7%">
            <div class="col-xs-4" style="float:right;padding:0;height:100%">
              <div class="col-xs-6" style="height: 100%">
                <button (click)="CancelUser()" id="CancelData" style="background:#d3d3d3;margin-top:5px;height:100%" class="btn btn-long provider saveButtonCSS">Cancel </button>
              </div>
              <div class="col-xs-6" style="height: 100%">
                <button (click)="RegisterUser('save')" id="SaveData" style="margin-top:5px;height:100%" [style.background]="(userform.form.valid)?'#93d250':'#d3d3d3'" class="btn btn-long provider saveButtonCSS" [disabled]="!userform.form.valid">
                  Save
                </button>
              </div>


            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="closedConfigDashEventListener()">:</div>
</div>

<app-dialog [(visible)]="UserAccountchangeDialogs" [closable]="false">

  <div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message3455</label> <br />
    <div class="col-xs-8">
      <span style="font-size:15px;" *ngIf="this.applicationComponent.SelectedTab =='tab2'">User Privileges<br /></span>
      <span style="font-size:15px;" *ngIf="this.applicationComponent.SelectedTab =='tab3'">User DashBoard<br /></span>
      <span style="font-size:15px;" *ngIf="this.applicationComponent.SelectedTab =='tab4'">User Quota<br /></span>
      <span style="font-size:15px;" *ngIf="this.applicationComponent.SelectedTab =='tab5'">User ALerts<br /></span>
      <span style="font-size:15px;" *ngIf="this.applicationComponent.SelectedTab =='tab1'">User Registration <br /></span>

      <span style="font-size:15px;" *ngIf="this.applicationComponent.SelectedTab =='tab2'">Save Changes to User Privileges?<br /></span>
      <span style="font-size:15px;" *ngIf="this.applicationComponent.SelectedTab =='tab3'">Save Changes to User DashBoard?<br /></span>
      <span style="font-size:15px;" *ngIf="this.applicationComponent.SelectedTab =='tab4'">Save Changes to User Quota?<br /></span>
      <span style="font-size:15px;" *ngIf="this.applicationComponent.SelectedTab =='tab5'"> Save Changes to User ALerts?<br /></span>
      <span style="font-size:15px;" *ngIf="this.applicationComponent.SelectedTab =='tab1'">Save Changes to User Registration?<br /></span>
    </div>
    <div class="col-xs-4" style="padding:0;">
      <div class="col-xs-6" style="padding:0">
        <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%" (click)="CloseUserChangeDialog('savechanges')">OK</button>
      </div>
      <div class="col-xs-6" style="padding:0">
        <button class="btn" style="float:right;background-color: white !important;color: black;margin-top:1%" (click)="CloseUserChangeDialog('danotsavechanges')">Cancel</button>
      </div>
    </div>
  </div>


</app-dialog>

<app-dialog [(visible)]="AdminAccess" id="SystemAdminAccessBox" [closable]="false">
  <div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
    <span style="font-size:15px;">System Admin</span><br />
    <div style="padding:0;margin-top:1%">
      <span style="font-size:15px;">Must be System Admin to edit.</span><br />
    </div>
    <button class="btn" (click)="CloseAccessDlg()" style="float:right;background-color: #0098f7 !important;color: white;margin-top:-5%">OK</button>
  </div>

</app-dialog>

<app-dialog [(visible)]="passwordMissmatch" *ngIf="passwordMissmatch" id="passwordMissmatch">
  <div class="col-xs-12" style="height:100%;padding:0;">
    <div class="col-xs-12" style="padding:3% 0 0 7%;height:23%;font-size:30px;"><label>SalesXCRM Message</label></div>
    <div class="col-xs-12" style="height:76%;padding:0;font-size:18px;">
      <div class="col-xs-9" style="padding:1% 0 0 3%;height:100%">
        <label style="margin-left:6%;color:#7b7b7b">Password Requirements</label><br />

        <ul style="margin:2% 0 0 0">

          <li><span>Minimum of 8 characters</span><br /></li>
          <li><span>Maximum of 20 characters</span><br /></li>
          <li><span>No Spaces will be allowed</span><br /></li>
          <li><span>At least 1 Number</span><br /></li>
          <li><span>At least 1 Upper Case Character</span><br /></li>
          <li><span>At least 1 Lower Case Character</span><br /></li>
          <li><span>At least 1 special character (  !&nbsp;&nbsp; #&nbsp;&nbsp; $&nbsp;&nbsp; %&nbsp;&nbsp; ^&nbsp;&nbsp; &&nbsp;&nbsp; *) </span></li>

        </ul>
      </div>
      <div class="col-xs-3" style="padding:0;height:100%">
        <button (click)="ClosePasswordMissmatch()" style="background: #64aadc; margin-top: 5px; width: 50%; height: 16%; border-radius: 5px; color: white; margin: 5% 0 0 10%;" class="col-xs-12 noprovider">
          Ok
        </button>
      </div>
    </div>
  </div>
</app-dialog>
<app-dialog [(visible)]="showDeleteDialog" *ngIf="showDeleteDialog" id="showDeleteDialog">
  <div style="text-align:center;font-size:22px;">
    <div style="text-align:center;font-size:22px;">
      <label style="color:red;margin-bottom:2%"> Warning</label><br />
      <div><span>There are ({{Roleassociatedusers.length}}) Users with the Role &lt;{{selectedRole}}&gt; in the System </span>:</div>

      <div style="margin-bottom:2%">
        <div class="col-xs-12" *ngFor="let usr of Roleassociatedusers" style="padding: 0;font:Helvetica;font-size:10.5px;">
          <span style="font-size: 22px;"> &lt;{{usr.itemName}}&gt;</span><br />
        </div>
      </div>
      <br />
      <div style="margin-bottom:2%"><span style="font-size:22px;"> Please re-assign each user another role under the "Users" Tab.</span><br />:</div>
      <div style=""><span style="font-size:22px">Once re-assigned,you will be able to delete the User Role &lt;{{selectedRole}}&gt; </span>:</div>
    </div>
  </div>
</app-dialog>


<app-dialog [(visible)]="ShowTaskGrpDialog" *ngIf="ShowTaskGrpDialog" id="ShowTaskGrpDialog">
  <div *ngIf="!edittaskgroup" style="text-align:center;font-size:135%;font-weight:600;font-style:italic; ">
    <label>Message</label><br /><br />
    <span>You are adding a NewTask Group  <label style="color:red">{{this.taskname}}</label> </span>  to to-do Items<br />
    <span>This New Task Group will be Automatically Added to </span><br />
    <span><label style="color:white">phone items</label> and <label style="color:white">meeting Items </label>:</span>
  </div>

  <div *ngIf="edittaskgroup" style="text-align:center;font-size:135%;font-weight:600;font-style:italic; ">
    <label>Message</label><br /><br />
    <span>You are changing the Spelling of Task Group <label style="color:red">{{this.taskname}}</label> </span><br />
    <span>This spelling change will be Automatically Updated  </span><br />
    <span> to <label style="color:white">phone items</label> and <label style="color:white">meeting Items </label>:</span>
  </div>
  <div class="col-xs-12" style="margin-top:2%">
    <div class="col-xs-3 col-xs-offset-3" style="padding-right: 0;">
      <button class="btn noprovider" style="width:100%;height: 35px;" (click)="SaveGroup(this.tasktype)">Save</button>
    </div>
    <div class="col-xs-3" style="padding-right: 0;">
      <button (click)="ShowTaskGrpDialog = !ShowTaskGrpDialog" class="btn" style="width:100%;height: 35px;border: 1px solid #000;">No</button>
    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="TaskWarningDialog" *ngIf="TaskWarningDialog">
  <label style="color:red;text-align:center;font-size:154%;font-weight:600;padding:1%;font-style:italic;">Warning</label><br /><br />
  <div style="text-align:justify;font-size:154%;font-weight:600;padding:1%;font-style:italic;">
    <span>You have requested the TaskGroup Name  <label style="color:red">{{this.taskname}}</label>  to be Deactivated. The system has identified Users that are currently associated with that Task Group Name.</span><br /><br />
    <span>please SELECT another Task Group Name from the list below  and the system will automatically update the Task Group for these users</span>
  </div>
  <div class="col-xs-12 removeCompletePadding" style="margin-top: 3%;padding: 0;">
    <div class="col-xs-5" style="padding: 5px;text-align: left;font-size: 144%;font-weight: 600;">
      <label>Select Task Group </label>
    </div>
    <div class="col-xs-5" style="padding:0;">
      <select class="form-control heightlight-selected-value" [(ngModel)]="user.TaskGroupid">
        <option value="null" disabled="disabled" [selected]="true">Select Task Group</option>
        <option *ngFor="let taskgroup of  taskgrouplist" value={{taskgroup.id}}>
          {{taskgroup.itemName}}
        </option>
      </select>
    </div>

  </div>

  <div class="col-xs-12" style="margin-top: 11%;">
    <div class="col-xs-4 pull-left" style="padding-right: 0;">
      <button (click)="TaskWarningDialog = !TaskWarningDialog" style="width:100%;height: 35px;border: 1px solid #000;">Cancel</button>
    </div>
    <div class="col-xs-4 pull-right" style="padding-right: 0;">
      <button (click)="UpdateTaskName()" class="btn" style="width:100%;height: 35px;border: 1px solid #000;">Continue</button>
    </div>
  </div>

</app-dialog>


<app-dialog [(visible)]="DeleteUserAlert" *ngIf="DeleteUserAlert" id="DeleteUserAlert" [closable]="false">
  <div class="col-xs-12" style="height:100%">
    <label style="font-size:22px;font-weight:800">SalesXCRM Message</label> <br /><br />
    <span style="font-size:18px;color:red;"> DELETE USER </span><br />
    <div class="col-xs-12" style="padding:0;margin-top:1%;height:20%">
      <span style="font-size:15px;font-weight:600">Select user to Transfer Accounts/Contacts, Open Leads, Open Proposals, Open Opportunities, Open Tasks and Open Tickets to.</span>
    </div>
    <br />
    <div class="col-xs-12" style="padding:0;height:35%">
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-2" style=" height: 100%; width: 17%; font-size: 15px; padding: 0.5%;padding-left: 0;"><span style="font-weight:bold">User List</span></div>
        <div class="col-md-10 " style="padding: 0%;width:65%">
          <!--<angular2-multiselect [data]="applicationComponent.dropdownList" [(ngModel)]="applicationComponent.selectedItems"
                                [settings]="dropdownSettings"
                                (onSelect)="onItemSelect($event)"
                                (onDeSelect)="OnItemDeSelect($event)"
                                (onSelectAll)="onSelectAll($event)"
                                (onDeSelectAll)="onDeSelectAll($event)">
          </angular2-multiselect>-->

          <select class="form-control" [(ngModel)]="ReplaceUserId">
            <option value="0"> Select User</option>
            <option *ngFor="let item of allusers" value="{{item.id}}">{{item.username}}</option>
          </select>
        </div>
      </div>

    </div>
    <div class="col-xs-12" id="MyButtonsDiv" style="padding:0 12% 0 0;height:12%;text-align:right">
      <button class="btn" style="margin-left: 4%;" (click)="DeleteUserAlert = false;">Cancel</button>
      <button class="btn" style="background-color: #0098f7 !important;color: white;" (click)="DeleteUsers()">OK</button>
    </div>
  </div>
