import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApplicationComponent } from '../application.component';
import { IMyDpOptions } from 'mydatepicker';
import * as moment from 'moment'
import { ReleaseNotes } from '../../models/releaseNotes';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { ContactUs } from '../../models/contact-us';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../services/customer.service';
import { AuditLogModel } from '../../models/auditlog';
import { ActivatedRoute } from '@angular/router';
declare var $: any;
const { version: version } = require('package.json');
const { date: date } = require('package.json');
@Component({
  selector: 'app-aboutsales-xcrm',
  templateUrl: './aboutsales-xcrm.component.html',
  styleUrls: ['./aboutsales-xcrm.component.css']
})
export class AboutsalesXCRMComponent implements OnInit {
  SearchNotAvailable: boolean = false;
  releaseNotesDiv: boolean = false;
  AddreleaseNotesDiv: boolean = false;
  ReadMoreDiv: boolean = false;
  releaseNotesListDiv: boolean = false;
  ContactUs: boolean = false;
  aboutus: boolean = true;
  datePipe: DatePipe = new DatePipe("en-US");
  releaseNotesmodel: ReleaseNotes = new ReleaseNotes();
  selectedNote: ReleaseNotes;
  public pdfSrc : any;
  releasenotesArray: Array<ReleaseNotes> = [];
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  currentuser: any;
  appVersion: string;
  today: Date;
  @Input()
  OpenPageType: string;
  userprevilages: any;
  subscriptionDiv: boolean = false;
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");

  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  
  constructor(private route: ActivatedRoute,public applicationComponent: ApplicationComponent, private userservice: UserService, private toastr: ToastrService, private customerService: CustomerService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.appVersion = version;
    this.today = date;
    this.customerService.GetPrivilagesByuser(this.currentuser.id).then(result => {
      this.userprevilages = result;
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['type'] != undefined && params['type'] != null) {
        this.OpenPageType = params['type'];
      }
    });

    this.OpenAboutusPageType(this.OpenPageType);

  }
  OpenAboutusPageType(val) {
    if (val == 'AboutSalesXCRM') {
      this.aboutus = true;
      this.releaseNotesDiv = false;
      this.ContactUs = false;
      this.saveAuditlog('About SalesXCRM');
    } else if (val == 'ContactUs') {
      this.aboutus = false;
      this.releaseNotesDiv = false;
      this.ContactUs = true;
      this.saveAuditlog('Contact Us');
    } else if (val == 'ReleaseNotes') {
      this.aboutus = false;
      this.releaseNotesDiv = true;
      this.ContactUs = false;
      this.saveAuditlog('Release Notes');
    } else if (val == 'MySubScription') {
      this.aboutus = false;
      this.ContactUs = false;
      this.releaseNotesDiv = false;
      this.subscriptionDiv = true;
      this.saveAuditlog('My SubScription');
    }
    
  }

  ReleaseNotesShow() {
    this.OpenPageType = 'ReleaseNotes';
    this.OpenAboutusPageType(this.OpenPageType);
  }
  ShowContactus() {
    var thisTab = this.userprevilages.filter(p => p.resource == "Contact Us" || p.resource.toLowerCase() == "Contact Us")[0];
    if (thisTab == undefined || (thisTab !== undefined && thisTab.canview == false)) {
      this.toastr.warning("You1 Do Not have Access to " + "Contact Us" + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied", { timeOut: 1500 });

      return false;

    } else {
      this.OpenPageType = 'ContactUs';
      this.OpenAboutusPageType(this.OpenPageType);
    }
  }

  close() {
    this.sendMsgEvent.emit("AboutUs");
  }
  Return(val) {
    this.ReadMoreDiv = false;
    this.AddreleaseNotesDiv = false;
    this.releaseNotesListDiv = true;
  }

  closeReleaseNotesDiv(msg) {
    this.releaseNotesDiv = false;
    this.aboutus = true;
  }
  closesubscriptionDiv(msg) {
    this.subscriptionDiv = false;
    this.aboutus = true;
  }

  Click() {
    const downloadLink = document.createElement("a");
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.setAttribute("href", 'http://localhost:14164/Proposals/20200416PO3.pdf');
    downloadLink.setAttribute("target", '_blank'); 
    downloadLink.setAttribute("download", 'Me');
    
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
 
  CancelContactUs(msg) { this.ContactUs = false; this.aboutus = true; }

  SearchNot() { this.SearchNotAvailable = false; }
  MySubscribtion() {
    var thisTab = this.userprevilages.filter(p => p.resource == "My Subscription" || p.resource.toLowerCase() == "My Subscription")[0];
    if (thisTab == undefined || (thisTab !== undefined && thisTab.canview == false)) {
      this.toastr.warning("You Do Not have Access to " + "My SubScription" + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied", { timeOut: 1500 });

      return false;

    } else {
      this.OpenPageType = 'MySubScription';
      this.OpenAboutusPageType(this.OpenPageType);
    }

  }

  saveAuditlog(Action) {
    var obj: AuditLogModel = { id: 0, type: Action, action: Action, AccountOrContactId: 0, AccountOrContact: "", documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: Action, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }
}
