import { Component, OnInit, ElementRef, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Dropdowntype } from 'src/app/models/dropdowntype';
import { Notes } from 'src/app/models/notes';
import { OpprtunitiesService } from 'src/app/services/opprtunities.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Opportunities } from 'src/app/models/opportunities';
import { Router } from '@angular/router';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { DatePipe } from '@angular/common';
import { NotesComponent } from '../../notes/notes.component';
import { Accounts } from '../../../models/accounts';
import { Contacts } from '../../../models/contacts';
import { UserService } from '../../../services/user.service';
import { OpportunityFields } from '../../../models/OpportunityLeadTypes';

import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApplicationComponent } from '../../application.component';
declare var $: any;
@Component({
  selector: 'add-opportunity',
  templateUrl: './add-opportunity.component.html',
  styleUrls: ['./add-opportunity.component.css']
})
export class AddOpportunityComponent implements OnInit {
  notesshowDialog = false;
  opportunities: Opportunities = new Opportunities();
  currentuser: any;
  OwnerList: Dropdowntype[] = [];
  opprtunityitemlist: Dropdowntype[] = [];
  Tasklist: Dropdowntype[] = [];
  pclist: Dropdowntype[] = [];
  drlist: Dropdowntype[] = [];
  midlevelslist: Dropdowntype[] = [];
  leadfromlist: Dropdowntype[] = [];
  opportunitiesnotes: Array<Notes> = [];
  datePipe: DatePipe = new DatePipe("en-US");
  @Input()
  OpportunityID = 0;
  @Input()
  contactid: number;
  @Input()
  type: string;
  @Input()
  editOpp;
  notes: Notes = new Notes();
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  @Input()
  Status;
  SaveCancelOppDiv: boolean = true;
  userprevilages: any;
  Dialog: boolean = false;
  public gridOptionsnotes: GridOptions;
  public context;
  public columnDefsnotes: any[];
  allnotes: Notes[];
  timezonevalue: string;
  @ViewChild(NotesComponent) notecomp;
  account: Accounts = new Accounts();
  contact: Contacts = new Contacts();
  OpportunityNotesDialog: boolean = false;
  PreviousNotesExpand: boolean = false; showSaveNotes: boolean = false;
  notedescription: string;
  showPDFDiv: boolean = true;
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  SelectOpprotunityDialog: boolean = false;
  conid: number;
  typ: string;
  today= Date.now();
  ExpandPDFDiv: boolean= false;
  CloseCreateNewWarning: boolean = false;
  Fields: any;
  OpportunityFieldsList: OpportunityFields[] = [];
  dropdownList = [];
  NewForm = new FormGroup({});

  FormDataModified: boolean = false;
  constructor(public applicationComponent: ApplicationComponent, myElement: ElementRef, private opprtunityservice: OpprtunitiesService, private userService: UserService,private customerService: CustomerService, private toastr: ToastrService, private router: Router) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    var loggedInUserId = this.currentuser.id;
    this.customerService.GetPrivilagesByuser(loggedInUserId).then(result => {
      this.userprevilages = result;
    });
    
    
  }
  clearNotes() {
    this.notes.notedescription = "";
    this.showSaveNotes = false;
  }
  NotesKeyUp(event) {
    this.notes.stagenotedescription = event.target.value;
    if (this.notes.notedescription.length > 0) {
      this.showSaveNotes = true;
    }
    else {
      this.showSaveNotes = false;
    }
  }
  ngOnInit() {
    this.getProjectFields();
    this.loadDropDowns();
    this.loadopp(this.OpportunityID);
    this.SaveCancelOppDiv = this.Status;
    this.notes = new Notes();
  }
  loadDropDowns() {
    if (this.type == 'Contacts') {
      this.customerService.LoadContactById(this.contactid).then(result => {
        this.contact = result;
        this.opportunities.ownerid = this.contact.ownerid;
      });
    } else {
      this.customerService.loadaccountdata(this.contactid).then(result => {
        this.account = result;
        this.opportunities.ownerid = this.account.ownerid;
      });
    }

    this.opprtunityservice.GetAllItems().then((items) => {
      this.opprtunityitemlist = items;
    });

    this.opprtunityservice.GetSpecialityList("ProbabiltyClose", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.pclist = pc;
    });

    this.opprtunityservice.GetSpecialityList("Drs", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.drlist = pc;
    });

    this.opprtunityservice.GetSpecialityList("Midlevels", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.midlevelslist = pc;
    });

    this.opprtunityservice.GetSpecialityList("LeadFrom", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.leadfromlist = pc;
    });
    this.customerService.GetLoggedinUserList(this.currentuser.id, this.currentuser.systemadministrator).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.OwnerList = Owner;
    });
  }

  addOpportunity() {
    if (this.opprtunityitemlist) {
      this.opprtunityitemlist.forEach(oppitem => {
        if (oppitem.id == parseInt(this.opportunities.item)) {
          this.opportunities.itemName = oppitem.itemName;

        }
      });
    }
    this.OpportunityNotesDialog = false; 
    
    this.opportunities.source = "Opp Icon";
    if (this.opportunities.id == 0 || this.opportunities.id == undefined || this.opportunities.id == null) {
      this.opportunities.OpportunityFor = "Opportunity";
    }
    
    var previousnotes = "";
    if (this.opportunitiesnotes.length > 0) {
      this.opportunitiesnotes.forEach(oppitem => {
        previousnotes += oppitem.notedescription + "|";
      });

      this.opportunities.notesDescription = previousnotes + this.notes.stagenotedescription;
    } else {
      this.opportunities.notesDescription = this.notes.stagenotedescription;
    }

    this.opportunities.fieldvalues = JSON.stringify(this.NewForm?.value);
   
    this.opportunities.actualclosedate = this.opportunities.actualclosedate;

    this.opprtunityservice.addopprtunity(this.opportunities).then(result => {
      this.opportunities = result;
      this.notes.accountid = result.id;
      this.notes.type = "Opportunity";
      this.notes.notedescription = this.notes.stagenotedescription;
      this.notes.userid = this.currentuser.id;
      this.notes.isnewnote = 1;
      if (this.notes.notedescription) {
        this.notes.notedescription = this.notes.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/img, "");
        this.notes.notedescription = this.notes.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/gi, "");
      }
      

      this.opprtunityservice.addopprtunityNotes(this.notes).then(result => {
        this.notes = result;
        this.notes.notedescription = '';
        this.notes = new Notes();
      });
      this.CancelOpportunity("Save");
      this.sendMsgEvent.emit("OpportunitySaved"); 
      this.toastr.success("Opportunity saved successfully.", "Opportunity Saved", { timeOut: 600 });
      
    });


  }

  loadopp(oppId) {
    if (oppId != 0) {
      this.opprtunityservice.GetByOpportunityId(oppId).then(result => {
        this.opportunities = result;
        if (this.opportunities.actualclosedate == null) {
          this.opportunities.actualclosedate = this.today.toString();
        } else {       
        this.opportunities.actualclosedate = this.datePipe.transform(this.opportunities.actualclosedate, 'yyyy-MM-dd'); 
        }      
        if (this.opportunities.status == 'Closed-Won' || this.opportunities.status == 'Closed-Lost') {
          this.contactid = this.opportunities.accountorcontactid;
        }          
        this.type = this.opportunities.type;
        this.opprtunityservice.GetAllOpprtunitiesNotesById(this.opportunities.id).then((items) => {
          items.forEach(items => {
          });
          this.opportunitiesnotes = items;
          
        });
      });
    }
    else {
      this.CancelOpportunity("load"); 
    }
      

  }

  CancelOpportunity(val) {
    if (val != 'Cancel') {
      this.opportunities = new Opportunities();
      this.opportunitiesnotes = [];
      this.opportunities.ownerid = this.currentuser.id;
      this.editOpp = false;
    } else {
      this.CloseCreateNewWarning = true;
      this.applicationComponent.salesxcrmDialog('#CloseCreateNewWarning .dialog');     
    }
  }

  StatusChange(val) {
    if (this.opportunities.status != '108' && this.opportunities.status != '111') {
      this.sendMsgEvent.emit("OpportunityStatusChanged");
      let selfobj = this;
      setTimeout(function () {
         
        selfobj.SaveCancelOppDiv = false;
        selfobj.css();
        
      },4)
      
    }
    else {
      this.SaveCancelOppDiv = true;
      
    }
  }

  delete(val, status) {
    
    if (this.userprevilages != undefined) {
      for (var j = 0; j < this.userprevilages.length; j++) {
        if ("Opportunities" == this.userprevilages[j].resource) {
          if (this.userprevilages[j].canedit == false) {
            this.toastr.warning("You Do Not have Access to update – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }

        }
      }
    }
    this.Dialog = true
    this.opportunities.status = status;
    if (status == "Closed-Won") {
      $("#Won").removeClass("c-button");
      $("#Won").addClass("OpportunityactiveButton");
    } else if (status == "Pending") {
      $("#Pending").removeClass("c-button");
      $("#Pending").addClass("OpportunityactiveButton");
    }
    else {
      $("#Lost").removeClass("c-button");
      $("#Lost").addClass("OpportunityactiveButton");
    }
  }

  ChangeStatus(val, status) {
    if (val) {
      this.opprtunityservice.GetStatusChange(val, status).then(result => {
        this.addOpportunity();
        this.sendMsgEvent.emit("OpportunitySaved");
        this.Dialog = false;
        this.CancelOpportunity("Save");
        this.clearbuttonseelection();
        this.SaveCancelOppDiv = true;
        //});

      });
    } else {
      this.addOpportunity();
      this.SaveCancelOppDiv = true;
    }
    
  }
  css() {
    setTimeout(function () {
      $('#WonLostPendingDiv .dialog').attr('style', 'width: 40%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 20%;overflow: auto;height: 46%;left: 10%;top: 44%;');
    }, 4)
  }
  clearbuttonseelection() {
    
    $("#Won").removeClass("OpportunityactiveButton");
    $("#Pending").removeClass("OpportunityactiveButton");
    $("#Lost").removeClass("OpportunityactiveButton");

    $("#Won").addClass("c-button");
    $("#Pending").addClass("c-button");
    $("#Lost").addClass("c-button");
  }
  onCloseDialog(event) {
    this.sendMsgEvent.emit("CloseDialog");
    this.SaveCancelOppDiv = true;
    this.opportunities.status = "Open";
  }
  
  AddTask() {
    this.sendMsgEvent.emit("AddTask");
  }
  OpenOpp() {
    document.getElementById('addopportunity').style.display = 'none';
    document.getElementById('allOpportunities').style.display = 'block';
  }

  Save() {
   
    if (this.opportunities.id == 0 || this.opportunities.id == undefined) {
      this.NewForm.addControl('userid', new FormControl(this.currentuser.id));
      this.NewForm.addControl('accountorcontactid', new FormControl(this.contactid));
      this.NewForm.addControl('type', new FormControl(this.type));
      this.NewForm.addControl('isdeleted', new FormControl(0));
      
      this.NewForm.addControl('ownerid', new FormControl(this.opportunities.ownerid));
      this.NewForm.addControl('practiceid', new FormControl(this.contactid));
      this.NewForm.addControl('source', new FormControl("Opp Icon"));
      this.NewForm.addControl('OpportunityFor', new FormControl('Opportunity'));
      var date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
      this.NewForm.addControl('datecreated', new FormControl(date));
      this.NewForm.controls['datecreated'].setValue(date);
      this.opportunities.accountorcontactid = this.contactid;
      this.opportunities.userid = this.currentuser.id;
      this.opportunities.type = this.type;
      this.opportunities.isdeleted = 0;
      this.opportunities.datecreated = date;
    } else {
      var date = this.datePipe.transform(this.opportunities.datecreated, 'yyyy-MM-dd HH:mm:ss');
      this.NewForm.addControl('datecreated', new FormControl(date));
      this.NewForm.controls['datecreated'].setValue(date);
      this.NewForm.addControl('accountorcontactid', new FormControl(this.contactid));
      this.NewForm.addControl('type', new FormControl(this.type));
      this.NewForm.addControl('isdeleted', new FormControl(this.opportunities.isdeleted));
      this.NewForm.addControl('ownerid', new FormControl(this.opportunities.ownerid));
      this.NewForm.addControl('practiceid', new FormControl(this.opportunities.practiceid));
      this.NewForm.addControl('source', new FormControl(this.opportunities.source));
      this.NewForm.addControl('OpportunityFor', new FormControl(this.opportunities.OpportunityFor));
      this.opportunities.accountorcontactid = this.contactid;
      this.opportunities.userid = this.currentuser.id;
      this.opportunities.type = this.type;
      this.opportunities.isdeleted = 0;
    }

    this.addOpportunity();
  }

  OpportunityNotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }

  showNotesDialog() {
     
    if (this.OpportunityID > 0) {
      this.conid = this.contactid;
      
      if (this.opportunities.type == 'Customer') {
        this.typ = 'Account';
      } else if (this.opportunities.type == 'Contacts') {
        this.typ = 'Contact';
      } else {
        this.typ = this.opportunities.type;
      }
      this.notesshowDialog = true;
      this.NotesDialogCss();

      //$('#accotIdNotes').val(this.contactid);
      //$('#accotTypeNotes').val('Opportunity');
      //let selfobj = this;
      //$(setTimeout(function () {
      //  selfobj.notecomp.Notes('Opportunity');

      //}, 100));
    }
    else {
      //alert("Please Select an Opportunity");

      this.SelectOpprotunityDialog = true;
      setTimeout(function () {
        $('#SelectOpprotunityDialog .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
      }, 4);

    }
    
  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }
  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.createColumnDefsnotes("");
    this.gridOptionsnotes.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  createColumnDefsnotes(columnSelections: string) {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';  
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellStyle: { 'text-align': 'left' }, cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          //eDiv.addEventListener('click', function () {
          //  selfObj.openDisplaynotes(params);
          //});
          return eDiv;
        }
      },
      { field: 'contactname', cellStyle: { 'text-align': 'left' }, headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellStyle: { 'text-align': 'left' }, cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];
   
  }

  GetCustomerNotes(contactid, type) {
    var moment = require('moment-timezone');
    this.customerService.GetAllNotesList(this.currentuser.id, contactid, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.initializeAgGridNotes(); this.allnotes = taskhistory;
    });

  }




  Expand(val) {
    if (val == true) {
      this.ExpandPDFDiv = true;
      this.sendMsgEvent.emit("Expand"); 

    } else {
      this.ExpandPDFDiv = false;
      this.sendMsgEvent.emit("Collapse"); 
    }
  }
  CloseAddOpportunityDialog() {
    this.sendMsgEvent.emit("CloseAddOpportunity");
  }
  CloseCreateTicket() {
   
    //if (this.opportunities.id == null || this.opportunities.id == 0 || this.opportunities.id == undefined) {
    this.CloseCreateNewWarning = true;
    this.applicationComponent.salesxcrmDialog('#CloseCreateNewWarning .dialog');
    ////setTimeout(function () {
    ////  this.applicationComponent.
    ////    $('#CloseCreateNewWarning .dialog').attr('style', 'width: 29%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    ////  }, 4)
    //} else {

    //  this.sendMsgEvent.emit("CloseAddOpportunity");
    //}

  }

  ReceiveDeleteCampaignevent(msg) {
    if (msg == "Ok") {
      this.CloseAddOpportunityDialog();
    }
    this.CloseCreateNewWarning = false;
  }
  getProjectFields() {

    this.opprtunityservice.GetOpportunityFields(this.currentuser.id).then(result => {
      this.OpportunityFieldsList = result;
      result.forEach(ele => {
        if (ele.DataType == 'Pick List' || ele.DataType == 'System Lookup') {
          if (ele.ColumnName == 'ownerid') {
            this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, 1, 0, 0).then((Owner) => {
              ele['Data'] = Owner;
              this.dropdownList = Owner;
            });
          }
         
          else {
            this.opprtunityservice.GetFieldItems(ele.OppFieldId, ele.FieldName, this.currentuser.id).then((accounttype) => {
              ele['Data'] = accounttype;
              
            });
          }


        }


      });

      let So = this;
      setTimeout(function () {
        So.Fields = result;
        So.Fields.forEach(x => {
          So.NewForm.addControl(x.ColumnName, new FormControl(''));         
          So.showPDFDiv = false;
        });
        So.NewForm.addControl('notedescription', new FormControl(''));

      }, 400)



    });
  }

  FormDataEntered(val) {
    this.FormDataModified = true;
  }
}
