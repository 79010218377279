<input type="text" id="emailIds" style="display:none;" />
<div class="container">
  <div class="col-xs-12"   style="margin-top: 0.5%;margin-bottom: 0.5%;padding: 0;">
    <div class="col-xs-1 col-xs-offset-6" style="text-align: right;">
      <label>User List</label>
    </div>
    <div class="col-xs-4" style="padding: 0;">
      <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                            [settings]="dropdownSetting"
                            (onSelect)="onItemSelect($event)"
                            (onDeSelect)="OnItemDeSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                            (onDeSelectAll)="onDeSelectAll($event)">
      </angular2-multiselect>
    </div>

  </div>
  <div *ngIf="itemCount == 0" >
    <span class="search_no_results">
      No data found
    </span>
  </div>
  <div *ngIf="itemCount !=0">
    <div id="ContacttableDiv">
      <!--<data-table id="example" [items]="items"
                  [itemCount]="itemCount"
                  (reload)="reloadItems($event)"
                  [indexColumn]="false"
                  [multiSelect]="true"
                  [selectOnRowClick]="true"
                  [selectColumn]="true"
                  [substituteRows]="false"
                  [translations]="translations"
                  (rowClick)="rowClick($event)"
                  (rowDoubleClick)="rowDoubleClick($event)" style="overflow-x: auto; overflow-y: auto;">
        <data-table-column [property]="'accnumber'"
                           [header]="'Acct No#'"
                           [sortable]="true"
                           [resizable]="true">
          <template #dataTableCell let-item="item">
            <span class=""  *ngIf="item.accnumber === '0'"></span>
            <span class="" style="color:red" *ngIf="item.accnumber !== '0'">{{item.accnumber}}</span>
          </template>
        </data-table-column>
        <data-table-column [property]="'accountname'"
                           [header]="'Practice'"
                           [sortable]="true"
                           [resizable]="true">
        </data-table-column>
        <data-table-column [property]="'lastname'"
                           [header]="'Last Name'"
                           [sortable]="true"
                           [resizable]="true">
        </data-table-column>

        <data-table-column [property]="'firstname'"
                           [header]="'First name'"
                           [sortable]="true"
                           [resizable]="true">
        </data-table-column>
        <data-table-column [property]="'middlename'"
                           [header]="'Middle name'"
                           [sortable]="true"
                           [resizable]="true">
        </data-table-column>

        <data-table-column [property]="'title'"
                           [header]="'Title'"
                           [sortable]="true"
                           [resizable]="true">
        </data-table-column>
        <data-table-column [property]="'speciality'"
                           [header]="'Specialty'"
                           [sortable]="true"
                           [resizable]="true">
        </data-table-column>
        <data-table-column [property]="'DOB'"
                           [header]="'DOB'"
                           [sortable]="true"
                           [resizable]="true">
        </data-table-column>

        <data-table-column [property]="'zip'"
                           [header]="'ZIP'"
                           [sortable]="true"
                           [resizable]="true">
        </data-table-column>
      
        <data-table-column [property]="'contacttype'"
                           [header]="'Contact Type'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'suffix'"
                           [header]="'Suffix'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'sex'"
                           [header]="'Sex'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'subspeciality'"
                           [header]="'Subspeciality'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'email'"
                           [header]="'Email'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'address1'"
                           [header]="'Address1'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'address2'"
                           [header]="'Address2'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'city'"
                           [header]="'City'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'state'"
                           [header]="'State'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'cell'"
                           [header]="'Cell'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'office'"
                           [header]="'Office'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'homeaddress'"
                           [header]="'Homeaddress'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'homecity'"
                           [header]="'Homecity'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'homestate'"
                           [header]="'Homestate'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'homezip'"
                           [header]="'Homezip'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'home'"
                           [header]="'Home'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'college'"
                           [header]="'College'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'collegegrandyear'"
                           [header]="'C.G.year'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'medschool'"
                           [header]="'Medschool'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'medschoolgrandyear'"
                           [header]="'M.G.year'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'residency'"
                           [header]="'Residency'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'residencygrandyear'"
                           [header]="'R.G.year'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'fellowship'"
                           [header]="'fellowship'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'fellowshipgrandyear'"
                           [header]="'F.G.year'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column [property]="'owner'"
                           [header]="'Owner'"
                           [sortable]="true"
                           [resizable]="true"
                          >
        </data-table-column>
        <data-table-column [property]="'created'"
                           [header]="'created'"
                           [sortable]="true"
                           [resizable]="true"
                           [visible]="false">
        </data-table-column>
        <data-table-column header="Notes">
          <template #dataTableHeader let-item="item">
            Notes
          </template>
          <template #dataTableCell let-item="item">
            <button (click)="OpenContactNotes(item)" class="btn btn-sm btn-default"><b>Notes</b></button>
          </template>
        </data-table-column>
      </data-table>-->
    </div>


  </div>
  <app-dialog [(visible)]="notesshowDialog">
    <div class="col-xs-12 provider" style="text-align:center;margin-top: 2%;margin-left: 1%;width: 98%;">
      <div class="col-xs-4" style="text-align:left">
        <div><b class="providerLabel" style="font-size:17px;">{{account.accountname}}</b></div>
        <div class="providerLabel">{{account.address1}}&nbsp;</div>
        <div>{{account.address2}}</div>
        <div>
          <span>{{account.city}} , {{account.state}},  {{account.zip | slice:0:5}}<span *ngIf="account.zip && account.zip.length > 5">-{{account.zip | slice:5:20}}</span>,{{account.country}}</span>
        </div>
      </div>
      <div class="col-xs-4">
        <h3>Contact Notes Info</h3>
      </div>
      <div class="col-xs-4" style="text-align:right">
        <div><b>Contact:</b>{{contact.firstname}} {{contact.middlename}} {{contact.lastname}} , {{contact.title}}</div>
        <div style="margin-top: 6%;"><button (click)="enterNotes()">Add New Notes</button></div>
      </div>

    </div>
    <div class="col-xs-12" style="padding: 0 1.25% 0 1.25%;">
      <div id="noteid" [froalaEditor]="editorOptions" [(froalaModel)]="notedescription" style="padding-right: 0%;display:none;margin-top:0.5%"></div>

      <div class="row" id="saveNotesButtons" style="display:none;margin-top:0.5%;padding-left: 0.5%;padding-right: 1%;">
        <button class="btn cancel" (click)="CancelNote()" type="submit" style="padding: 0.75%;border: 1px solid;">Cancel Note</button>
        <button class="btn provider" (click)="SaveNote()" type="submit" style="margin-left: 1%;padding: 0.75%;width: 89.75%;">Save Note</button>
      </div>
    </div>

    <div class="col-xs-12" id="divTable" style="margin-top: 1%;margin-bottom: 2%;width: 98%;margin-left: 1%;margin-right: 55%;border:1px solid #000">
      <ul class="nav nav-tabs" id="notesListTabsId" style="margin-top:10px">
        <li class="active" id="allNotesId"><a data-toggle="tab" (click)="Notes('Contact')">All</a></li>
        <li><a data-toggle="tab" (click)="Notes('ContactOpportunity')">Opportunities</a></li>
        <li><a data-toggle="tab" (click)="Notes('ContactTaskHistory')">Tasks</a></li>
        <li><a data-toggle="tab" (click)="Notes('ContactLead')">Leads</a></li>
      </ul>

      <div class="tab-content">
        <div id="home" class="tab-pane fade in active">
          <!--<data-table [items]="notesitems"
                      [itemCount]="notesitemCount" [indexColumn]="false"
                      (reload)="notesreloadItems($event)"
                      [substituteRows]="false"
                      [translations]="translations">

            <data-table-column [property]="'notedescription'"
                               [header]="'Notes'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'created'"
                               [header]="'Date Added'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'user'"
                               [header]="'User Added'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'type'"
                               [header]="'Type'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'status'"
                               [header]="'Completed Y/N'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
          </data-table>-->
        </div>
        <!--<div id="oppocontent" class="tab-pane fade">
          <data-table [items]="opportunitieslistitems"
                      [itemCount]="opportunitieslistitems.length" [indexColumn]="false"
                      (reload)="notesreloadItems($event,'Opportunity')"
                      [substituteRows]="false"
                      [translations]="translations">

            <data-table-column [property]="'notedescription'"
                               [header]="'Notes'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'created'"
                               [header]="'Date Added'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'user'"
                               [header]="'User Added'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'type'"
                               [header]="'Type'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'status'"
                               [header]="'Completed Y/N'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
          </data-table>
        </div>
        <div id="taskscontent" class="tab-pane fade">
          <data-table [items]="tasklistitems"
                      [itemCount]="tasklistitems.length" [indexColumn]="false"
                      (reload)="notesreloadItems($event,'Lead')"
                      [substituteRows]="false"
                      [translations]="translations">

            <data-table-column [property]="'notedescription'"
                               [header]="'Notes'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'created'"
                               [header]="'Date Added'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'user'"
                               [header]="'User Added'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'type'"
                               [header]="'Type'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'status'"
                               [header]="'Completed Y/N'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
          </data-table>
        </div>
        <div id="leadscontent" class="tab-pane fade">
          <data-table [items]="leadslistitems"
                      [itemCount]="leadslistitems.length" [indexColumn]="false"
                      (reload)="notesreloadItems($event,'SaleStage')"
                      [substituteRows]="false"
                      [translations]="translations">

            <data-table-column [property]="'notedescription'"
                               [header]="'Notes'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'created'"
                               [header]="'Date Added'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'user'"
                               [header]="'User Added'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'type'"
                               [header]="'Type'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
            <data-table-column [property]="'status'"
                               [header]="'Completed Y/N'"
                               [sortable]="true"
                               [resizable]="true">
            </data-table-column>
          </data-table>
        </div>-->
      </div>

    </div>

  </app-dialog>

 
</div>
