import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Messaging } from '../../models/messaging';
import { Replymessages } from '../../models/replymessages';
import { MessagingService } from './../../services/messaging.service';
import { CustomerService } from './../../services/customer.service';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { PageComponent } from '../page/page.component';
import { Column } from 'ag-grid';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { ToastrService } from 'ngx-toastr';
import { ApplicationComponent } from '../application.component';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Dropdown, Dropdowntype } from '../../models/dropdowntype';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
declare var require: any;
declare var $: any;

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.css']
})
export class MessagingComponent implements OnInit {
  accountform: FormGroup;
  message: Messaging = new Messaging();

  Replymessages: Replymessages = new Replymessages();
  allMessages: Messaging[];
  toatlallMessages: Messaging[];
  totalsentlMessages: Messaging[];
  toatldeleteallMessages: Messaging[];
  allrepliedMessages: Messaging[];
  currentuser: any;
  allInboxMessage: Message[];
  pageSize: string = "100";
  messageId: number;
  aggridHeight: string = "";
  public gridOptions: GridOptions;
  public columnDefs: any[];
  public context;
  public frameworkComponents;
  aggridheight: string;
  isFirstTimeWidthChangeComplete: boolean = false;
  aggridheight1: string;
  dropdownList: Dropdowntype[] = [];
  dropdownListUsers = [];
  lastMessgeMain: string = "";
  timezonevalue: string;
  messagetype: string = "";
  msgTypId: number = 0;
  //finalitems = "";
  messagedescription: string;
  selectedItems: any;
  selectedUsers: any;
  replytype = '';
  isForward: boolean = true;
  public autoGroupColumnDef;
  gridApi: any;
  gridColumnApi: any;
  displayReplyMessage: string = "";
  composedReplyMsg: string;
  dropdownSetting: any;
  colSelections: string;
  selectedUserIds: string = "";
  divHeight = screen.height * 0.7044444;
  divHeight1 = screen.height * 0.561111;
  messageGridHeight = screen.height * 0.6088;
  currentPage: number = 1;
  startrow: number = 1;
  noofRows: number = 1;
  totalnumberofrows: number = 1;
  totalnumberofPages: number = 1;
  selectedmsgId: number = 0;
  isFirstNode: boolean = false;
  inboxmessagecount = 0;
  draftmessagecount = 0;
  sentmessagecount = 0;
  deletedmessagecount = 0;
  markunRead: boolean = false;
  ShowPermanentmessageDelete: boolean = false;
  Omsgid: number;
  Msgval: string;
  DeleteConfirmationPopup: boolean = false;
  CancelMessagePopup: boolean = false;
  DescriptionEntered: boolean = false;
  deleteRowDataId: any;
  deleteRowDataMsgval: any;
  contactStatus: any; selectedtab = "inbox";
  selectedMenuRadio: string = "tab1";
  public editorOptions: Object = {

    key: 'kRB4zB4E4D1F1H2E1qA-21nB-13takwgF-7B3D-17vwgC3E3G3B2B7A4D4F4D2F3==', placeholderText: 'Type Message Here',

  };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? new Date().getFullYear() + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public sideBar;
  constructor(private location: Location,private paginationcop: PageComponent, private route: ActivatedRoute, private router: Router, private opprtunityservice: OpprtunitiesService, public applicationComponent: ApplicationComponent, private messagingservice: MessagingService, private customerService: CustomerService, private viewContainerRef: ViewContainerRef, private toastr: ToastrService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    }
    this.viewContainerRef = viewContainerRef;
  }
  ngAfterViewInit(): void {
    this.ResizeTable();

  }
  ngOnInit() {
    $('.coustomButton').removeClass('mainactiveButton');
    $('#internalmessaging').addClass('mainactiveButton');
    this.applicationComponent.SearchSelectedItems = [];
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 7
    };

    let selfObj = this;
    this.customerService.GetAllOwnerList(0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      selfObj.dropdownList = Owner;
      selfObj.dropdownListUsers = Owner;
    });
    //$("#userids").val(this.currentuser.id);
    this.initializeAgGrid();
    this.route.params.subscribe(params => {
      this.message.id = params['messageid'];
      if (this.message.id != 0) {
        this.messagingservice.GetMessageById(this.message.id).then(result => {
          this.message = result;
          this.createMessagingObjectFromEventData(result);

          let selfObj1 = this;
          $(setTimeout(function () {

            selfObj1.gridApi.forEachNode((node) => {
              if (node.data != undefined || node.data != null) {
                if (node.data != undefined && node.data.id == selfObj1.message.id) {
                  node.setSelected(true);
                }
              }
              else if (node.aggData != undefined || node.aggData != null) {
                if (node.key == selfObj1.message.id) {
                  node.setSelected(true);
                }
              }
            });
          }, 500));
        });
      }
    });

    let selfObj2 = this;
    $(setTimeout(function () {

      selfObj2.GetAllmessagesByUsercount(selfObj2.currentuser.id, "Delete");
      selfObj2.messageButtonCSS('inbox', 0, '')
    }, 100));

    this.editorOptions = {

      key: 'kRB4zB4E4D1F1H2E1qA-21nB-13takwgF-7B3D-17vwgC3E3G3B2B7A4D4F4D2F3==', placeholderText: 'Type Message Here',
      events: {
        'charCounter.update': function () {

        },
        'keyup': function (keyupEvent) {
          $('.fr-wrapper').css('border-left', '2px solid #00B0F0');
          selfObj2.DescriptionEntered = true;

        }
      }
    };
  }

  GetAllmessagesByUsercount(userid, MessageType) {


    if (MessageType == "Inbox") {
      this.messagingservice.Getallmessages(userid, 0, userid, this.startDateVal, this.endDateVal, 1, 100).then((inboxmessages) => {
        this.inboxmessagecount = inboxmessages.length;
        this.applicationComponent.totalInboxmessages = inboxmessages.length;
      });
    }
    //Get Draft Count
    if (MessageType == "Draft") {
      this.messagingservice.Getallmessages(userid, 2, userid, this.startDateVal, this.endDateVal, 1, 100).then((draftmessages) => {
        this.draftmessagecount = draftmessages.length
      });
    }
    if (MessageType == "Sent") {
      ///get Sent count
      this.messagingservice.Getallmessages(this.currentuser.id, 3, this.currentuser.id, this.startDateVal, this.endDateVal, 1, 100).then((sentmessages) => {
        this.sentmessagecount = sentmessages.length;
        this.GetAllmessagesByUsercount(this.currentuser.id, "Inbox");
      });
    }
    if (MessageType == "Delete") {
      //Get Inbox Count
      this.messagingservice.Getallmessages(userid, 0, userid, this.startDateVal, this.endDateVal, 1, 100).then((inboxmessages) => {
        this.inboxmessagecount = inboxmessages.length;
        this.applicationComponent.totalInboxmessages = this.inboxmessagecount;
      });

      //Get Draft Count
      this.messagingservice.Getallmessages(userid, 2, userid, this.startDateVal, this.endDateVal, 1, 100).then((draftmessages) => {
        this.draftmessagecount = draftmessages.length
      });

      ///get Sent count
      this.messagingservice.Getallmessages(this.currentuser.id, 3, this.currentuser.id, this.startDateVal, this.endDateVal, 1, 100).then((sentmessages) => {

        this.sentmessagecount = sentmessages.length;
      });

      // get Delete count
      this.messagingservice.Getallmessages(this.currentuser.id, 4, this.currentuser.id, this.startDateVal, this.endDateVal, 1, 100).then((delmessages) => {
        this.toatlallMessages = [];
        //delmessages.forEach(message => {
        //});
        // this.toatldeleteallMessages = delmessages;
        this.deletedmessagecount = delmessages.length
      });
    }
    // this.deletedmessagecount = this.delmessages.length
    this.messagingservice.GetInboxCount(this.currentuser.id).then(result => {
      this.applicationComponent.inboxmessagecount = result;
    });
  }

  autoSizeAll() {

  }

  removeGroupValues(time: number) {
    $(setTimeout(function () {
      let val = $('.ag-group-value');
      val.each(function (index, value) {
        value.innerHTML = "";
      });
    }, time));
  }
  rowSelected(event) {

    if (this.markunRead == false) {
      let data: any;
      if (event.node.data != undefined && event.node.data != null)
        data = event.data;
      else if (event.node.allLeafChildren != undefined && event.node.allLeafChildren != null && event.node.allLeafChildren.length > 0 && event.node.allLeafChildren[0].data != undefined && event.node.allLeafChildren[0].data != null)
        data = event.node.allLeafChildren[0].data;

      if (event.node.selected) {
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.message = selfObj.createMessagingObjectFromEventData(data);

        }, 500));
      } else {
        this.message.id = 0;
        this.message = new Messaging();
      }
      //let selfObj = this;
      //$(setTimeout(function () {
      //  selfObj.Updateisreaddata(data.id);
      //}, 200));
    }
    else {
      this.markunRead = false;
    }

    // alert("fi");

    //window.location.reload();
    //this.initializeAgGrid();
    // this.router.(`/application/messaging/0`);
  }

  setReplyBtnDivHeight() {

  }
  public getallmessagesBymessageid(userid, MsgId) {
    this.messagingservice.GetallmessagesBymessage(this.currentuser.id, MsgId).then((replymessages) => {
      replymessages.forEach(replymessages => {    
        replymessages.message = replymessages.message.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/img, "");
        var moment = require('moment-timezone');
        replymessages.date = moment.tz(replymessages.date.toLocaleString(), this.timezonevalue).format("DD-MM-YYYY") + '  ( ' + moment.tz(replymessages.date.toLocaleString(), this.timezonevalue).format("h:mm ss") + ' )';
       
      });
      this.allrepliedMessages = replymessages;
    });

  };
  createMessagingObjectFromEventData(data) {
    //this.getallmessagesBymessageid(this.currentuser.id, data.id);
    this.selectedmsgId = data.Omsgid;
    let msg = new Messaging();
    msg.id = data.id;
    msg.fromuser = data.fromuser;
    msg.subject = data.subject;
    msg.date = data.date;
    msg.replied = data.replied;
    msg.message = data.message;
    msg.messagefromid = data.messagefromid;
    msg.receivedid = data.receivedid;
    msg.receivednames = data.receivednames;
    msg.messagetype = data.messagetype;
    this.getallmessagesBymessageid(this.currentuser.id, data.id);
    return msg;

  }

  Updateisreaddata(id) {
    // if (this.messagetype == "inbox") {   
    this.messagingservice.GetMessageById(id).then(result => {
      this.message = result;
      this.messageId = id;
      var isreaduseridsArray = (this.message.isreaduserids != null ? this.message.isreaduserids.split(',') : []);
      isreaduseridsArray.push(this.currentuser.id);
      this.message.isreaduserids = new Array(this.RemoveDuplicateItemsFromArr(isreaduseridsArray)).join(',');
      this.message.isreaduserids = this.RemoveDuplicateItemsFromArr(this.message.isreaduserids.split(',')).toString(); 
      this.messagingservice.Updateisreadstatus(id, this.message.isreaduserids).then((calllist) => {
        this.messagingservice.GetInboxCount(this.currentuser.id).then(result => {
          this.applicationComponent.inboxmessagecount = result;
         this.getallmessages(this.selectedUserIds, this.msgTypId, this.currentPage, this.pageSize);
          

        });
      });
    });
   
  }
  rowGroupOpened(event) {
    this.removeGroupValues(10);
    if (event.node.selected == true)
      event.node.allLeafChildren[0].setSelected(true);
    else {
      if (event.node.allLeafChildren[0].selected == true) {
        event.node.allLeafChildren[0].setSelected(false);
        event.node.setSelected(true);
      }
    }
  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    let columnSelections: string;
    this.createColumnDefs(columnSelections);
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.context = this;
    this.frameworkComponents = {
    };
  }

  txtPageSizeChanged(pageSize) {
    this.gridApi.paginationSetPageSize(Number(pageSize));
    //this.saveConfiguration();
    this.removeGroupValues(1000);
  }
  columnVisible(event) {
    //this.saveConfiguration();
  }
  dragStopped(event) {
    //this.saveConfiguration();
  }
  columnResized(event) {
    //if (event.finished == true)
    //  this.saveConfiguration();
  }

  saveConfiguration() {
    let allCols: Column[] = this.gridColumnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 25;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 14;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);
  }

  onUserSelect($event) {
    this.userListSelection();
  }

  OnUserDeSelect($event) {
    this.userListSelection();
  }

  onSelectAllUsers($event) {
    this.userListSelection();
  }

  onDeSelectAllUsers($event) {
    this.userListSelection();
  }

  userListSelection() {
    let selectedUserIds: string = "";
    this.selectedUsers.forEach(item => {
      selectedUserIds += item.id + "|";
    });

    if (selectedUserIds.length > 0)
      selectedUserIds = selectedUserIds.substr(0, selectedUserIds.length - 1);

    this.selectedUserIds = selectedUserIds;
    this.getallmessages(selectedUserIds, this.msgTypId, this.currentPage, this.pageSize);
    this.removeGroupValues(1000);
  }

  createColumnDefs(columnSelections: string) {
    let columnSelectionsArray: string[] = [];
    let selfObj = this;

    this.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      { field: 'id', headerName: 'Id', hide: true, rowGroup: true, headerClass: 'MessageHeaderclass', aggFunc: 'first' },
      {
        field: 'readstatus', headerName: 'hhh', hide: true, aggFunc: 'first'
      },

      { field: 'Msgval', headerName: 'Msg', hide: true, headerClass: 'MessageHeaderclass', aggFunc: 'first' },
      { field: 'Omsgid', headerName: 'Omsg id', hide: true, headerClass: 'MessageHeaderclass', aggFunc: 'first' },
      {
        field: ' ', headerName: 'Read Status', hide: false, headerClass: 'MessageHeaderclass', cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.data != null && params.data.readstatus == 0) {
            eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:90px; background:white">' + 'UnRead' + '</button>';
            var eButton = eDiv.querySelectorAll('.btn-simple')[0];
            eButton.addEventListener('click', function () {
              if (params.data != undefined && params.data != null) {
                selfObj.MarsAsUnRead(params.data.id, 'UnRead');
              }

            });
          }
          else if (params.node.aggData != null && params.node.aggData.readstatus == 0) {
            eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:90px; background:white">' + 'UnRead' + '</button>';
            var eButton = eDiv.querySelectorAll('.btn-simple')[0];
            eButton.addEventListener('click', function () {
              if (params.node.aggData != undefined && params.node.aggData != null) {
                selfObj.MarsAsUnRead(params.node.aggData.id, 'UnRead');
              }
            });
          } else {
            eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:90px; background:white">' + 'Read' + '</button>';
            var eButton = eDiv.querySelectorAll('.btn-simple')[0];
            eButton.addEventListener('click', function () {
              if (params.data != undefined && params.data != null) {
                selfObj.MarsAsUnRead(params.data.id, 'Read');
              }
              else if (params.node.aggData != undefined && params.node.aggData != null) {
                selfObj.MarsAsUnRead(params.node.aggData.id, 'Read');
              }
            });
          }

          return eDiv;
        }
      },
      {
        field: 'fromuser', headerName: 'From', hide: this.msgTypId == 0 ? false : true, headerClass: 'MessageHeaderclass', aggFunc: 'first'
        , cellStyle: function (params) {
          if (params.data != undefined && params.data != null) {
            if (params.data.readstatus == 0) {
              return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold', color: 'Red' };
            }
          }
          else if (params.node.aggData != undefined && params.node.aggData != null) {
            if (params.node.aggData.readstatus == 0) {
              return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold', color: 'Red' };
            }
          }
        }
      },
      {
        field: 'receivednames', headerName: 'To', hide: this.msgTypId == 0 ? true : false, headerClass: 'MessageHeaderclass', aggFunc: 'first'
        , cellStyle: function (params) {
          if (params.data != undefined && params.data != null) {
            if (params.data.readstatus == 0) {
              return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold', color: 'Red' };
            }
          }
          else if (params.node.aggData != undefined && params.node.aggData != null) {
            if (params.node.aggData.readstatus == 0) {
              return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold', color: 'Red' };
            }
          }
        }
      },
      {
        field: 'subject', headerName: 'Subject', hide: false, headerClass: 'MessageHeaderclass', aggFunc: 'first'
        , cellStyle: function (params) {
          if (params.data != undefined && params.data != null) {
            if (params.data.readstatus == 0) {
              return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold', color: 'Red' };
            }
          }
          else if (params.node.aggData != undefined && params.node.aggData != null) {
            if (params.node.aggData.readstatus == 0) {
              return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold', color: 'Red' };
            }
          }
        }
      },
      {
        field: 'date', headerName: 'Date', hide: false, headerClass: 'MessageHeaderclass', aggFunc: 'first'
        , cellStyle: function (params) {
          if (params.data != undefined && params.data != null) {
            if (params.data.readstatus == 0) {
              return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold', color: 'Red' };
            }
          }
          else if (params.node.aggData != undefined && params.node.aggData != null) {
            if (params.node.aggData.readstatus == 0) {
              return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold', color: 'Red' };
            }
          }
        }
      },
      {
        field: 'replied', headerName: 'Replied', hide: true, headerClass: 'MessageHeaderclass', aggFunc: 'first'
        , cellStyle: function (params) {
          if (params.data != undefined && params.data != null) {
            if (params.data.readstatus == 0) {
              return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold', color: 'Red' };
            }
          }
          else if (params.node.aggData != undefined && params.node.aggData != null) {
            if (params.node.aggData.readstatus == 0) {
              return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold', color: 'Red' };
            }
          }
        }
      },
      {
        field: 'message', headerName: 'Message', hide: true, headerClass: 'MessageHeaderclass', aggFunc: 'first'
        , cellStyle: function (params) {
          if (params.data != undefined && params.data != null) {
            if (params.data.readstatus == 0) {
              return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold', color: 'Red' };
            }
          }
          else if (params.node.aggData != undefined && params.node.aggData != null) {
            if (params.node.aggData.readstatus == 0) {
              return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold', color: 'Red' };
            }
          }
        }
      },
      { field: 'messagefromid', headerName: 'Message From Id', hide: true, headerClass: 'MessageHeaderclass', aggFunc: 'first' },
      { field: 'receivedid', headerName: 'Received Id', hide: true, headerClass: 'MessageHeaderclass', aggFunc: 'first' },
      { field: 'messagetype', headerName: 'Message Type', hide: true, headerClass: 'MessageHeaderclass', aggFunc: 'first' },
      {
        field: 'actions', headerName: '<i style="font-weight: 400;font-size: 14px;" class="fa fa-trash" aria - hidden="true" ></i>', cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<i style="font-weight: 400;font-size: 14px;" class="fa fa-trash" aria - hidden="true" ></i>';
          eDiv.addEventListener('click', function () {

            if (params.data != undefined)
              selfObj.delete(params.data.Omsgid, params.data.Msgval);
            else
              selfObj.delete(params.node.aggData.Omsgid, params.node.aggData.Msgval);
          });
          return eDiv;
        },
        width: 52, pinned: 'right', aggFunc: 'first', hide: false
      }
    ];
    this.autoGroupColumnDef = {
      filterValueGetter: function (params) {
        var colGettingGrouped = params.colDef.showRowGroup;
        var valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
        return valueForOtherCol;
      },
      headerName: "Count",
      headerClass: "MessageHeaderclass",
      width: 80
    };
  }
  canceldelete() { this.ShowPermanentmessageDelete = false; }
  ShowPermanentmessageDeleteCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 27% !important;border: 2px solid rgb(49,189,240);border-radius:5%;height:23%;top:45%;max-width:none;padding: 0;margin: 0;left:25%;    background-color: #f4f4f4;');
    }, 4));
  }
  ReceiveDeleteevent(msg) {
    if (msg == "Ok") {
      this.UpdateMessage(this.deleteRowDataId, this.deleteRowDataMsgval);
    }
    this.DeleteConfirmationPopup = false;

  }
  delete(id, Msgval) {

    if (this.msgTypId == 4) {
      this.Omsgid = id;
      this.Msgval = Msgval;
      this.ShowPermanentmessageDelete = true;
      this.ShowPermanentmessageDeleteCss();

    } else {
      this.deleteRowDataId = id;
      this.deleteRowDataMsgval = Msgval;
      this.contactStatus = 0;
      this.DeleteConfirmationPopup = true;
      setTimeout(function () {
        $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
      }, 4);

    }
  }

  UpdateMessage(id, Msgval) {

    if (Msgval == 'main') {
      this.messagingservice.GetMessageById(id).then(result => {
        this.message = result;
        var deletedmessageidArray = (this.message.deletedmsguserids != null ? this.message.deletedmsguserids.split(',') : []);
        deletedmessageidArray.push(this.currentuser.id);
        this.message.deletedmsguserids = new Array(this.RemoveDuplicateItemsFromArr(deletedmessageidArray)).join(',');
        this.message.deletedmsguserids = this.RemoveDuplicateItemsFromArr(this.message.deletedmsguserids.split(',')).toString();
        let selfObj = this;
        this.messagingservice.DeleteMessage(id, this.message.deletedmsguserids, this.messagetype, Msgval).then((user) => {
          this.toastr.error("Message Deleted successfully.", "Message Deleted");
          this.GetAllmessagesByUsercount(this.currentuser.id, "Delete");
          this.getallmessages(this.selectedUserIds, this.msgTypId, this.currentPage, this.pageSize);
          this.removeGroupValues(1000);
          $(setTimeout(function () {
            selfObj.message.id = 0;
          }, 500));
        });
      });
    } else {
      this.messagingservice.GetReplyMessageById(id).then(result => {
        this.Replymessages = result;
        var deletedmessageidArray = (this.Replymessages.deletedmsguserids != null ? this.Replymessages.deletedmsguserids.split(',') : []);
        deletedmessageidArray.push(this.currentuser.id);
        this.Replymessages.deletedmsguserids = new Array(this.RemoveDuplicateItemsFromArr(deletedmessageidArray)).join(',');
        this.Replymessages.deletedmsguserids = this.RemoveDuplicateItemsFromArr(this.Replymessages.deletedmsguserids.split(',')).toString();
        let selfObj = this;
        this.messagingservice.DeleteMessage(id, this.Replymessages.deletedmsguserids, this.messagetype, Msgval).then((user) => {
          this.toastr.error("Message Deleted successfully.", "Message Deleted");
          this.GetAllmessagesByUsercount(this.currentuser.id, "Delete");
          this.getallmessages(this.selectedUserIds, this.msgTypId, this.currentPage, this.pageSize);
          this.removeGroupValues(1000);
          $(setTimeout(function () {
            selfObj.message.id = 0;
          }, 500));
        });
      });
    }
    this.ShowPermanentmessageDelete = false;

    // this.messageButtonCSS('deleted', 4, "");
  }

  RemoveDuplicateItemsFromArr(arr: any[]) {
    var uniqueIDs = [];
    $.each(arr, function (i, el) {
      if ($.inArray(el, uniqueIDs) === -1) uniqueIDs.push(el);
    });

    return uniqueIDs;
  }
  arrayRemove(arr, value) {

    return arr.filter(function (ele) {
      return ele != value;
    });

  }
  MarsAsUnRead(id, status) {
    var c = confirm("“Do you want to change the status of this message to " + status);
    if (c == true) {
      this.markunRead = true;
      this.messagingservice.GetMessageById(id).then(result => {
        this.message = result;
        if (status == 'UnRead') {
          var isreaduseridsArray = (this.message.isreaduserids != null ? this.message.isreaduserids.split(',') : []);
          isreaduseridsArray.push(this.currentuser.id);
          this.message.isreaduserids = new Array(this.RemoveDuplicateItemsFromArr(isreaduseridsArray)).join(',');
          this.message.isreaduserids = this.RemoveDuplicateItemsFromArr(this.message.isreaduserids.split(',')).toString();
        } else {
          var isreaduseridsArray = (this.message.isreaduserids != null ? this.message.isreaduserids.split(',') : []);
          isreaduseridsArray = this.arrayRemove(isreaduseridsArray, 2);
          this.message.isreaduserids = new Array(this.RemoveDuplicateItemsFromArr(isreaduseridsArray)).join(',');
          this.message.isreaduserids = this.RemoveDuplicateItemsFromArr(this.message.isreaduserids.split(',')).toString();
        }
        this.messagingservice.Updateisreadstatus(id, this.message.isreaduserids).then((calllist) => {
          this.messagingservice.GetInboxCount(this.currentuser.id).then(result => {
            this.applicationComponent.inboxmessagecount = result;
            this.getallmessages(this.selectedUserIds, this.msgTypId, this.currentPage, this.pageSize);
            
          });
        });
      });
    }
  }
  savetodraft() {
    this.message.messagetype = 2;
    this.SaveMessage('Draft');
  }
  ReplyMessage() {
    let selfObj = this;
    var receivedids = [];
    this.message.messagefromid = this.currentuser.id;
    for (var i = 0; i < this.selectedItems.length; i++) {
      receivedids.push(this.selectedItems[i].id);
    }
    this.message.receivedid = receivedids.toString();

    if (this.isForward) {

      this.messagingservice.SaveMessages(this.message).then(result => {
        this.toastr.success("Message Sent successfully.", "Message Sent", { timeOut: 600 });
        $('.fr-element').empty();
        selfObj.messageButtonCSS(selfObj.messagetype, selfObj.msgTypId, 'Save');
        selfObj.removeGroupValues(1000);

      });
    } else {
      this.Replymessages.replymessage = this.composedReplyMsg;//.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/img, "");
      var mesageform = (this.message.messagefromid != null ? this.message.messagefromid.toString().split(',') : []);
      mesageform.push(this.Replymessages.replyfromuserid.toString());
      this.message.messagefromid = new Array(this.RemoveDuplicateItemsFromArr(mesageform)).join(',');

      var receivedid = (this.message.receivedid != null ? this.message.receivedid.toString().split(',') : []);
      receivedid.push(this.message.receivedid.toString());
      this.message.receivedid = new Array(this.RemoveDuplicateItemsFromArr(receivedid)).join(',');
      this.message.receivedid = this.RemoveDuplicateItemsFromArr(this.message.receivedid.toString().split(',')).toString();
      // this.messagingservice.SaveMessages(this.message).then(result => {

      this.Replymessages.replytouserids = receivedids.toString();
      this.messagingservice.replySaveMessages(this.Replymessages).then(result => {
        this.toastr.success("Message Sent successfully.", "Message Sent", { timeOut: 600 });

        $('.fr-element').empty();
        selfObj.messageButtonCSS(selfObj.messagetype, selfObj.msgTypId, 'Save');
        selfObj.removeGroupValues(1000);
      });
      // });

    }
  }
  CancelReply() {
    this.CancelMessagePopup = true;
    setTimeout(function () {
      $('#CancelMessagePopup .dialog').attr('style', 'width: 33%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4);

  }
  Ok(value) {
    if (value == "Cancel") {
      this.CancelMessagePopup = false;
    } else {
      this.replytype = '';
      this.message.id = 0;
      this.CancelMessage();
      this.CancelMessagePopup = false;
    }
  }
  SaveMessage(buttontype) {
    var receivedids = [];
    this.message.messagefromid = this.currentuser.id;
    for (var i = 0; i < this.selectedItems.length; i++) {
      receivedids.push(this.selectedItems[i].id);
    }
    this.message.receivedid = receivedids.toString();
    this.message.message = this.messagedescription;

    this.messagingservice.SaveMessages(this.message).then(result => {

      if (buttontype == 'Draft') {
        this.toastr.success("Message Saved to Draft successfully.", "Message Saved", { timeOut: 600 });
        this.getallmessages(this.selectedUserIds, '2', this.currentPage, this.pageSize);
        this.GetAllmessagesByUsercount(this.currentuser.id, "Draft");

      } else {
        this.toastr.success("Message Sent successfully.", "Message Sent", { timeOut: 600 });
        this.getallmessages(this.selectedUserIds, '0', this.currentPage, this.pageSize);
        this.GetAllmessagesByUsercount(this.currentuser.id, "Sent");

      }
      $('.fr-element').empty();
      let selfObj = this;
      if (selfObj.messagetype != "compose")

        selfObj.messageButtonCSS(selfObj.messagetype, selfObj.msgTypId, "");
      else
        selfObj.messageButtonCSS('inbox', 0, 'Save');
      selfObj.removeGroupValues(100);
    });
  }
  CancelMessage() {
    this.messageButtonCSS('inbox', 0, 'Cancel');

  }
  onAgGridReady(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;

    this.autoSizeAll();
    this.removeGroupValues(10);
  }

  ResizeTable() {
    var url = window.location.href;
    if (url.indexOf('messaging') > -1) {
      $('.fr-wrapper').css('height', (screen.height * 0.3555) + 'px');
      $('.fr-wrapper').css('overflow', 'auto');
      $('.fr-wrapper').css('border', '2px solid #00B0F0');
      $('.fr-wrapper').css('border-left', '5px solid #e05451');
      $('.c-btn').css('border-left', '5px solid #e05451');

    }
  }
  ResizeTable1() {

    var url = window.location.href;
    if (url.indexOf('messaging') > -1) {
      $('.fr-wrapper').css('height', (screen.height * 0.21444) + 'px');
      $('.fr-wrapper').css('overflow', 'auto');
      $('.fr-wrapper').css('border', '2px solid #00B0F0');
      $('.fr-wrapper').css('border', '2px solid rgb(162, 228, 119)');
      if (this.selectedItems.length > 0) {
        $('.c-btn').css('border-left', '2px solid rgb(162, 228, 119)');
      } else {
        $('.c-btn').css('border-left', '2px solid #e05451');
      }
    }
  }

  public getallmessages(userid, MsgType, startrow, endrow) {

    this.messagingservice.Getallmessages(this.currentuser.id, MsgType, this.currentuser.id, this.startDateVal, this.endDateVal, startrow, endrow).then((messages) => {
      messages.forEach(message => {
        this.createColumnDefs("");
        let dateArr: string[] = message.date.split("T");
        let dtArr = dateArr[0].split("-");
        let date: string = dtArr[2];
        let month: string = dtArr[1];
        let year: string = dtArr[0];
        message.date = month + "-" + date + "-" + year + " " + dateArr[1];
        message.receivednames = this.Getselectedusers(message.receivedid);
        this.allMessages = messages;
      });
      this.allMessages = messages;
  
      
      if (MsgType == 0) { this.inboxmessagecount = this.allMessages.length; }
      else if (MsgType == 2) { this.draftmessagecount = this.allMessages.length; }
      else if (MsgType == 3) { this.sentmessagecount = this.allMessages.length; }
      else if (MsgType == 4) { this.deletedmessagecount = this.allMessages.length; }      
    });
  }

  Getselectedusers(receivedid: string) {
    let receivedIdsArr: string[] = receivedid.split(',');
    let receivednames: string = "";

    receivedIdsArr.forEach(id => {
      let usrItem: Dropdowntype = this.dropdownList.find(x => x.id.toString() == id);
      if (usrItem != null && usrItem.itemName != null) {
        receivednames += usrItem.itemName + ";"
      }
    });
    if (receivednames.length > 0)
      receivednames = receivednames.substr(0, receivednames.length - 2);

    return receivednames;
  }

  rowClicked(rowEvent) {

    if (this.markunRead == false) {

      if (rowEvent.node.selected || rowEvent.node.allLeafChildren != undefined) {
        document.getElementById('replyBtnDiv').style.display = 'block';

        let data: any;
        if (rowEvent.data != undefined && rowEvent.data != null)
          data = rowEvent.data;
        else if (rowEvent.node.allLeafChildren != undefined && rowEvent.node.allLeafChildren != null && rowEvent.node.allLeafChildren.length > 0 && rowEvent.node.allLeafChildren[0].data != undefined && rowEvent.node.allLeafChildren[0].data != null)
          data = rowEvent.node.allLeafChildren[0].data;

        let id: number = data.id;
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.Updateisreaddata(data.id);
        }, 100));
        this.messagingservice.GetMessageById(data.id).then(result => {
          this.message = result;
        });

        if (rowEvent.node.allLeafChildren != undefined) {
          this.selectParentRow(id);
        }
      }
      else {
        this.message.id = 0;
        this.message = new Messaging();
      }

    } else {
      this.markunRead = false;
    }

  }
  ActiveTask(type) {

    this.replytype = type;
    let commaSeperatedToIds: string = "";

    if (type == 'Reply' || type == 'replytoall') {
      this.Replymessages.messageid = this.message.id;
      this.Replymessages.replyfromuserid = this.currentuser.id;
      if (this.message.receivedid != undefined && this.message.receivedid != null && this.message.receivedid != "") {
        let toUserIds: string[] = this.message.receivedid.split(",");
        if (type == 'Reply') {
          commaSeperatedToIds = this.message.messagefromid.toString();
          this.Replymessages.replytouserids = this.message.messagefromid.toString();
        } else {
          commaSeperatedToIds += this.message.messagefromid + ",";
          toUserIds.forEach(id => {
            if (id != this.currentuser.id)
              commaSeperatedToIds += id + ",";
          });
          if (commaSeperatedToIds.length > 0)
            commaSeperatedToIds = commaSeperatedToIds.substr(0, commaSeperatedToIds.length - 1);

          this.Replymessages.replytouserids = commaSeperatedToIds;
        }
      }
      this.isForward = false;
      this.composedReplyMsg = "";
      let selfobj = this;
      $(setTimeout(function () {
        selfobj.ResizeTable1();
      }, 200));
    } else {  //forward

      this.message.replied = "0";
      this.message.subject = "Fw: " + this.message.subject;

      this.isForward = true;

      $(setTimeout(function () {
        document.getElementById('MsgDiv').style.height = '495px';
      }, 500));

    }

    this.selectedItems = [];
    let selfObj = this;
    this.dropdownList.forEach(item => {
      if (selfObj.messagetype == "inbox") {
        if (selfObj.Replymessages.replytouserids.indexOf(item.id.toString()) >= 0)
          selfObj.selectedItems.push({ "id": item.id, "itemName": item.itemName });
      } else {
        if (selfObj.Replymessages.replytouserids.indexOf(item.id.toString()) >= 0)
          selfObj.selectedItems.push({ "id": item.id, "itemName": item.itemName });
      }

    });
  }

  //dateChange(event) {
  //  this.AssignStartAndEndDate(event);
  //  this.applicationComponent.startVal = this.startDateVal;
  //  this.applicationComponent.endVal = this.endDateVal;
  //  this.getallmessages(this.selectedUserIds, this.msgTypId);

  //  this.removeGroupValues(1000);
  //}

  //AssignStartAndEndDate(event) {

  //  let dateStr = event.formatted.split(":")[0];
  //  if (dateStr == "Start")
  //    this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  //  else
  //    this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  //}

  ///** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  //getDateFormatYYYYHMMHDD(date): string {
  //  let year = date.year;
  //  let month = date.month;
  //  let day = date.day;

  //  return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  //}
  messageButtonCSS(id, MsgType, Action) {
    this.messagetype = id;
    this.msgTypId = MsgType;
    this.replytype = '';
    this.selectedtab = id;
    let selfObj = this;
    this.message.id = 0;
    // if (Action != 'Cancel') {
    // document.getElementById('replyBtnDiv').style.display = 'none';
    //}
    $('.messageButton').removeClass('selectedButtonCSS');
    $('#' + id).addClass('selectedButtonCSS');
    $('.message').html('');
    $('#' + id + '_span').html('<i class="activeColor"></i>');
    // selfObj.GetAllmessagesByUsercount(this.currentuser.id);
    if (id == 'compose') {
      this.message = new Messaging();
      this.selectedItems = [];
      this.messagedescription = "";
      // $("#gridDiv").height(window.innerHeight - ($('#appComponentContent').height() + $('#actiondivid').height()) - 20);
    } else {
      if (Action != 'Save') {
        selfObj.getallmessages(selfObj.selectedUserIds, MsgType, this.currentPage, this.pageSize);
        selfObj.createColumnDefs(" ");
      }

    }

    $(setTimeout(function () {
      selfObj.ResizeTable();
    }, 100));

    //selfObj.GetAllmessagesByUsercount(this.currentuser.id);
    //this.removeGroupValues(1000);
  }

  selectParentRow(id) {
    let selfObj = this;
    $(setTimeout(function () {
      let isFirstNode: boolean = true;
      selfObj.gridApi.forEachNode((node) => {
        if (node.aggData != undefined || node.aggData != null) {
          if (isFirstNode && node.key == id) {
            isFirstNode = false;
            node.setSelected(true);
            //selfObj.gridApi.selectNode(node, true);
          }
        }
      });
    }, 500));
  }

  first() {

    this.currentPage = 1;
    this.getallmessages(this.selectedUserIds, this.messagetype, this.currentPage, this.pageSize);
  }
  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getallmessages(this.selectedUserIds, this.messagetype, this.currentPage, this.pageSize);
    }
  }
  next() {
    if (this.currentPage != this.totalnumberofPages) {
      this.currentPage++;
      this.getallmessages(this.selectedUserIds, this.messagetype, this.currentPage, this.pageSize);
    }

  }
  last() {
    this.currentPage = this.totalnumberofPages;
    if (this.currentPage <= this.totalnumberofPages) {
      this.getallmessages(this.selectedUserIds, this.messagetype, this.currentPage, this.pageSize);
    }

  }

  onItemSelect(item: any) {
    if (this.selectedItems.length > 0) {
      $('.c-btn').css('border-left', '1px solid #ccc');
    } else {
      $('.c-btn').css('border-left', '5px solid #e05451');
    }

  }
  OnItemDeSelect(item: any) {
    if (this.selectedItems.length > 0) {
      $('.c-btn').css('border-left', '1px solid #ccc');
    } else {
      $('.c-btn').css('border-left', '5px solid #e05451');
    }
  }
  onSelectAll(items: any) {
    if (this.selectedItems.length > 0) {
      $('.c-btn').css('border-left', '1px solid #ccc');
    } else {
      $('.c-btn').css('border-left', '5px solid #e05451');
    }
  }
  onDeSelectAll(items: any) {
    if (this.selectedItems.length > 0) {
      $('.c-btn').css('border-left', '1px solid #ccc');
    } else {
      $('.c-btn').css('border-left', '5px solid #e05451');
    }
  }
  closeMessage() {
    this.location.back()
  }
 
  SubjectKeyUp() {
    if (this.message.subject.length > 0) {
      $('#SubjectBox').css('border-left', '1px solid #ccc');
    }
  }
}
