import "froala-editor/js/froala_editor.pkgd.min.js";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AgGridModule } from 'ag-grid-angular';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { PopoverModule } from 'ngx-smart-popover';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HttpInterceptor } from '@angular/common/http';
import { AppSettings } from './app.settings';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AuthService } from './services/authentication.service';
import { AuthenticationInterceptor } from './authentication-interceptor';
import { AdvancedsearchService } from './services/advancedsearch.service';
import { UserService } from './services/user.service';
import { AccountsService } from './services/accounts.service';
import { CommonModule, DecimalPipe, HashLocationStrategy, LocationStrategy } from "@angular/common";
//import { CalendarModule } from "ap-angular-fullcalendar/angular2-fullcalendar";
import { ScheduletaskComponent } from './application/scheduletask/scheduletask.component';
import { TomorrowactivitiesComponent } from './application/tomorrowactivities/tomorrowactivities.component';
import { UnreadMessagesComponent } from './application/unread-messages/unread-messages.component';
import { CustomerService } from './services/customer.service';
import { HttpModule } from '@angular/http';
import { CookieService, CookieOptions } from 'angular2-cookie/core';
import { DashboardComponent } from './application/dashboard/dashboard.component';
import { ResponsewizardComponent } from './application/responsewizard/responsewizard.component';
import { ApplicationComponent } from './application/application.component';
import { DialogComponent } from './application/dialog/dialog.component';
import { EditdeletecvComponent } from './application/editdeletecv/editdeletecv.component';
import { NotesrendererComponent } from './application/notesrenderer/notesrenderer.component';
import { ViewcalllistComponent } from './application/viewcalllist/viewcalllist.component';
import { DocumentmanagementComponent } from './application/documentmanagement/documentmanagement.component';
import { PageComponent } from './application/page/page.component';
import { EditdeleteComponent } from './application/editdelete/editdelete.component';
import { ProgressBarModule } from "angular-progress-bar";
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SearchdataComponent } from './application/searchdata/searchdata.component';
import { LoadAccountcontactInfoComponent } from './application/load-accountcontact-info/load-accountcontact-info.component';
import { CampaignsComponent } from './application/campaigns/campaigns.component';
import { HomeComponent } from './application/home/home.component';
import { EditdeletecvcheckComponent } from './application/editdeletecvcheck/editdeletecvcheck.component';
import { CustomerComponent } from './application/customer/customer.component';
import { PracticeComponent } from './application/practice/practice.component';
import { NotesComponent } from './application/notes/notes.component';
import { AccountContactComponent } from './application/account-contact/account-contact.component';
import { ContactsComponent } from './application/contacts/contacts.component';
import { LeadsComponent } from './application/leads/leads.component';
import { LeadsdashboardComponent } from './application/leads/leadsdashboard/leadsdashboard.component';
import { ContactsdashboardComponent } from './application/contacts/contactsdashboard/contactsdashboard.component';
import { OpportunitiesComponent } from './application/opportunities/opportunities.component';
import { OpportunitiesdashboardComponent } from './application/opportunities/opportunitiesdashboard/opportunitiesdashboard.component';
import { EmailsComponent } from './application/emails/emails.component';
import { AddressmapComponent } from './application/addressmap/addressmap.component';
import { TasklistComponent } from './application/tasklist/tasklist.component';
import { StatusIdsComponent } from './application/status-ids/status-ids.component';
import { HistoryComponent } from './application/history/history.component';
import { TasklistdashboardComponent } from './application/tasklist/tasklistdashboard/tasklistdashboard.component';
import { AboutsalesXCRMComponent } from './application/aboutsales-xcrm/aboutsales-xcrm.component';
import { ConfigurationdashboardComponent } from './application/configurationdashboard/configurationdashboard.component';
import { AdvancedTableComponent } from './application/advanced-table/advanced-table.component';
import { ReportsComponent } from './application/reports/reports.component';
import { AddOpportunityComponent } from './application/opportunities/add-opportunity/add-opportunity.component';
import { AccountComponent } from './application/account-contact/account/account.component';
import { UserlistComponent } from './application/userlist/userlist.component';
import { EditactivatecvComponent } from './application/editactivatecv/editactivatecv.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { AdvancedsearchComponent } from './application/advancedsearch/advancedsearch.component';
import { SearchboxfilterPipe } from './pipes/searchboxfilter.pipe';
import { CalllistComponent } from './application/calllist/calllist.component';
import { TaskComponent } from './application/task/task.component';
import { EditcontactComponent } from './application/editcontact/editcontact.component';
import { ConvertcontacttoLeadComponent } from './application/convertcontactto-lead/convertcontactto-lead.component';
import { ActivitiesNotesInfoComponent } from './application/activities-notes-info/activities-notes-info.component';
import { CalllisthistoryComponent } from './application/calllisthistory/calllisthistory.component';
import { ForecastingComponent } from './application/forecasting/forecasting.component';
import { OpportunityhistoryComponent } from './application/opportunityhistory/opportunityhistory.component';
import { QuickViewComponent } from './application/quick-view/quick-view.component';
import { ContactsofCustomerComponent } from './application/contactsof-customer/contactsof-customer.component';
import { MonthCalenderComponent } from './application/month-calender/month-calender.component';
import { AddNewAccountComponent } from './application/add-new-account/add-new-account.component';
import { UserQuotaComponent } from './application/user-quota/user-quota.component';
import { ProjectsComponent } from './application/projects/projects.component';
import { MarketingComponent } from './application/marketing/marketing.component';
import { SalestoolComponent } from './application/salestool/salestool.component';
import { DocumentComponent } from './application/document/document.component';
import { TicketsComponent } from './application/tickets/tickets.component';
import { PaginationComponent } from './pagination/pagination.component';
import { AuditLogComponent } from './application/audit-log/audit-log.component';
import { EmailsdivComponent } from './application/emailsdiv/emailsdiv.component';
import { ZendeskhomeComponent } from './application/zendeskhome/zendeskhome.component';
import { CustomercontactsComponent } from './application/customercontacts/customercontacts.component';
import { ImportedComponent } from './application/imported/imported.component';
import { RhxComponent } from './application/rhx/rhx.component';
import { DropdownCOnfigurationComponent } from './application/dropdown-configuration/dropdown-configuration.component';
import { SalesXcrmComponent } from './application/sales-xcrm/sales-xcrm.component';
import { OwnershipsharingComponent } from './application/ownershipsharing/ownershipsharing.component';
import { UploadDocumentComponent } from './application/upload-document/upload-document.component';
import { AutomatedEmailsComponent } from './application/automated-emails/automated-emails.component';
import { RoleComponent } from './application/role/role.component';
import { AddressComponent } from './application/address/address.component';
import { TicketConfigurationComponent } from './application/ticket-configuration/ticket-configuration.component';
import { SystemRegistrationComponent } from './application/system-registration/system-registration.component';
import { ProposalConfigurationComponent } from './application/proposal-configuration/proposal-configuration.component';
import { OpportunitiesConfigurationComponent } from './application/opportunities-configuration/opportunities-configuration.component';
import { ProposalsComponent } from './application/proposals/proposals.component';
import { UserMessagingComponent } from './application/user-messaging/user-messaging.component';
import { NotificationComponent } from './application/notification/notification.component';
import { StatusIDconfigurationComponent } from './application/status-idconfiguration/status-idconfiguration.component';
import { LeadsConfigurationComponent } from './application/leads-configuration/leads-configuration.component';
import { AdminReportsComponent } from './application/reports/admin-reports/admin-reports.component';
import { ForbiddenValidatorDirectiveDirective } from './application/shared/forbidden-validator-directive.directive';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { MyDatePickerModule } from 'mydatepicker';
//import { Ng2FittextModule } from "ng2-fittext";
import { TextMaskModule } from 'angular2-text-mask';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { OpprtunitiesService } from './services/opprtunities.service';
import { MailserviceService } from './services/mailservice.service';
import { HeroService } from './services/hero.service';
import { LeadsService } from './services/leads.service';
import { MessagingService } from './services/messaging.service';
import { AgmCoreModule } from '@agm/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { QuickSearchComponent } from './application/quick-search/quick-search.component';
import { SearchResultComponent } from './application/search-result/search-result.component';
import { AccountContactInfoComponent } from './application/account-contact-info/account-contact-info.component';
import { AddEditAccountComponent } from './application/add-edit-account/add-edit-account.component';
import { ReleaseNotesComponent } from './application/release-notes/release-notes.component';
import { ContactUsComponent } from './application/contact-us/contact-us.component';
import { DeletePopupComponent } from './application/delete-popup/delete-popup.component';
import { AccountorcontactviewComponent } from './application/accountorcontactview/accountorcontactview.component';
import { SearchFieldComponent } from './application/search-field/search-field.component';
import { UserdataheaderComponent } from './application/userdataheader/userdataheader.component';
import { ProfileComponent } from './application/profile/profile.component';
import { AccountDetailsComponent } from './application/account-details/account-details.component';
import { AccountTransferOwnershipComponent } from './application/account-transfer-ownership/account-transfer-ownership.component';
import { TaskListConfigurationComponent } from './application/task-list-configuration/task-list-configuration.component';
import { PendedproposalsComponent } from './application/pendedproposals/pendedproposals.component';
import { NewNotesComponent } from './application/new-notes/new-notes.component';
import { CampaignConfigurationComponent } from './application/campaign-configuration/campaign-configuration.component';
import { LinkDeleteComponent } from './application/link-delete/link-delete.component';
import { DocumentManagementConfigurationComponent } from './application/document-management-configuration/document-management-configuration.component';
import { SoftwareLicenseConfigurationComponent } from './application/software-license-configuration/software-license-configuration.component';
import { SoftwareLicensingComponent } from './application/software-license-configuration/software-licensing/software-licensing.component';
import { OtherStandardReportsComponent } from './application/reports/other-standard-reports/other-standard-reports.component';
import { ProjectConfigurationComponent } from './application/projects/project-configuration/project-configuration.component';
import { CreateProjectsComponent } from './application/projects/create-projects/create-projects.component';
import { RevenueDashboardComponent } from './application/dashboard/revenue-dashboard/revenue-dashboard.component';
import { MySalesxcrmConfigurationComponent } from './application/sales-xcrm/my-salesxcrm-configuration/my-salesxcrm-configuration.component';
import { CampaignDashboardComponent } from './application/dashboard/campaign-dashboard/campaign-dashboard.component';
import { ProjectsDashboardComponent } from './application/dashboard/projects-dashboard/projects-dashboard.component';
import { SubscriptionComponent } from "./application/subscription/subscription.component";
import { UserRoleConfigurationComponent } from "./application/user-role-configuration/user-role-configuration.component";
import { MessagingComponent } from './application/messaging/messaging.component';
import { TicketsDashboardComponent } from './application/dashboard/tickets-dashboard/tickets-dashboard.component';
import { AccountConfigurationComponent } from './application/account-contact/account-configuration/account-configuration.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from "@angular/material/dialog";
import { HeadersComponent } from './application/headers/headers.component';
import { CreateTicketComponent } from './application/tickets/create-ticket/create-ticket.component';
import { ChatComponent } from './application/chat/chat.component';
import { MatIconModule } from '@angular/material/icon'
import { ChatService } from './services/chat.service';
import { CapsLockDirective } from './caps-lock.directive';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { SassdateContactsComponent } from './application/sassdate-contacts/sassdate-contacts.component';
import { ContactPhotoComponent } from './application/contacts/contact-photo/contact-photo.component';
import { ProposalItemsComponent } from './application/reports/proposal-items/proposal-items.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  listPlugin
]);

@NgModule({
  declarations: [CapsLockDirective,
    AppComponent, LoginComponent, UnreadMessagesComponent, DashboardComponent, TomorrowactivitiesComponent, ScheduletaskComponent, ResponsewizardComponent, AddressComponent ,
    ApplicationComponent, DialogComponent, EditdeletecvComponent, SassdateContactsComponent, ProposalItemsComponent, NotesrendererComponent, ViewcalllistComponent, DocumentmanagementComponent, PageComponent,
    EditdeleteComponent, SearchdataComponent, LoadAccountcontactInfoComponent, CampaignsComponent, HomeComponent, EditdeletecvcheckComponent, CustomerComponent,
    PracticeComponent, NotesComponent, AccountContactComponent, OpportunitiesComponent, OpportunitiesdashboardComponent, ContactsComponent, LeadsComponent,     LeadsdashboardComponent, ContactsdashboardComponent, EmailsComponent, AddressmapComponent, TasklistComponent, StatusIdsComponent, HistoryComponent,
    TasklistdashboardComponent, AboutsalesXCRMComponent, ConfigurationdashboardComponent, AdvancedTableComponent, ReportsComponent, AddOpportunityComponent, ChatComponent,
    AccountComponent, UserlistComponent, EditactivatecvComponent, ForgotpasswordComponent, ResetpasswordComponent, AdvancedsearchComponent, SearchboxfilterPipe,
    CalllistComponent, TaskComponent, EditcontactComponent, ConvertcontacttoLeadComponent, ActivitiesNotesInfoComponent, CalllisthistoryComponent, ForecastingComponent, UserRoleConfigurationComponent,
    OpportunityhistoryComponent, QuickViewComponent, ContactsofCustomerComponent, MonthCalenderComponent, MessagingComponent, UserQuotaComponent, ProjectsComponent,
    MarketingComponent, SalestoolComponent, DocumentComponent, TicketsComponent, PaginationComponent, AuditLogComponent, EmailsdivComponent, ZendeskhomeComponent,
    CustomercontactsComponent, ImportedComponent, RhxComponent, DropdownCOnfigurationComponent, SalesXcrmComponent, OwnershipsharingComponent, UploadDocumentComponent,
    AutomatedEmailsComponent, RoleComponent, AddressComponent, TicketConfigurationComponent, SystemRegistrationComponent, ProposalConfigurationComponent,
    OpportunitiesConfigurationComponent, ProposalsComponent, UserMessagingComponent, NotificationComponent, StatusIDconfigurationComponent, ContactPhotoComponent,
    LeadsConfigurationComponent, AdminReportsComponent, ForbiddenValidatorDirectiveDirective, SearchResultComponent, AccountContactInfoComponent, AddEditAccountComponent, ReleaseNotesComponent, ContactUsComponent, DeletePopupComponent, AccountorcontactviewComponent, SearchFieldComponent, UserdataheaderComponent, ProfileComponent, AccountDetailsComponent, QuickSearchComponent, AccountTransferOwnershipComponent, TaskListConfigurationComponent, PendedproposalsComponent, NewNotesComponent, CampaignConfigurationComponent, LinkDeleteComponent, DocumentManagementConfigurationComponent, SoftwareLicenseConfigurationComponent, SoftwareLicensingComponent, OtherStandardReportsComponent, ProjectConfigurationComponent, CreateProjectsComponent, RevenueDashboardComponent, MySalesxcrmConfigurationComponent, CampaignDashboardComponent, ProjectsDashboardComponent, SubscriptionComponent, TicketsDashboardComponent, AccountConfigurationComponent, AddNewAccountComponent, HeadersComponent, CreateTicketComponent
  ],
  imports: [
    BrowserModule, NgSelectModule,Ng4LoadingSpinnerModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB_-RI5MbnINvz5gsJOvLDyjr_o7fgkAFQ',
      libraries: ['places']
    }), MatProgressSpinnerModule,
    AppRoutingModule, FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(), FormsModule, ReactiveFormsModule, PopoverModule, BrowserAnimationsModule, ToastrModule.forRoot(), 
    HttpClientModule, NgIdleKeepaliveModule.forRoot(), HttpModule, ProgressBarModule, AngularMultiSelectModule, NgxMaterialTimepickerModule, CommonModule, MatDividerModule, MatIconModule, MatListModule,
    DragulaModule.forRoot(), MyDatePickerModule, TextMaskModule, RecaptchaModule.forRoot(), ChartsModule,  FullCalendarModule, MatFormFieldModule, MatInputModule, MatDialogModule,
    AgGridModule.withComponents([NotesrendererComponent, SassdateContactsComponent,ProposalItemsComponent,EditdeletecvComponent, EditactivatecvComponent, EditdeletecvcheckComponent, EditdeleteComponent, LinkDeleteComponent, CalllisthistoryComponent ])
    ],
  providers: [
    { provide: CookieOptions, useValue: {} }, { provide: LocationStrategy, useClass: HashLocationStrategy },
    AppSettings, CustomerService, MailserviceService, DecimalPipe, AccountsService, CookieService, HeroService, DragulaService, OpprtunitiesService, LeadsService, UserService, PageComponent, OpportunitiesdashboardComponent, 
    AdvancedsearchService, ChatService, MessagingService,ApplicationComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
