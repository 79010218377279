
<div class="col-xs-12 col-sm-12 col-md-12" id="AccountViewDiv" style="padding: 0; border-radius: 3px; font-family: Calibri; font-weight: 800" [style.margin-top.%]="(pageFrom=='ScheduleTask' || pageFrom=='Leads' ||pageFrom=='Ticket' ||pageFrom=='Opportunities' || pageFrom=='ProposalAccount' || pageFrom=='Projects' || pageFrom=='TaskList')? '0' : '1.5'">
  <div class="col-xs-12" style="padding: 0 0 0 0;height:14%">
    <div class="col-xs-7 removepadding" *ngIf="pageFrom!='ScheduleTask'">
      <b style="font-size: 9pt;" *ngIf="pageFrom != 'Contacts' && pageFrom != 'Proposal'"> ACCOUNT VIEW</b>
      <b style="font-size: 9pt;" *ngIf="pageFrom == 'Contacts'"> ACCOUNT </b>
    </div>


    <div class="col-xs-5 removepadding">
      <div class="col-xs-12" style="padding:0;height:6%" *ngIf="pageFrom !=='ScheduleTask'">
      
        <div class="col-xs-1" style="padding:0%;margin:0;height:100%;width:15%" *ngIf="pageFrom!='ScheduleTask' && pageFrom!='Proposal'">
          <span [ngClass]="(account.isAccountPermission=='View')? 'btn disabled' : 'btn'" id="AddNewContactid" [ngStyle]="{'backgroundColor':(account.isAccountPermission=='View') ? 'white' : 'white','color':(account.isAccountPermission=='View') ? 'lightgrey' : 'black'}" (click)="buttonClick('addContact','')" style="border:none;margin-left:8%;padding:0 0 1% 0">
            <img src="../../../assets/img/Add Contact.png" style="height:25px" title="Add Contact" />
          </span>

        </div>
        <div (click)="buttonClick('Accountsharing','View')" class="col-xs-1 " style="padding:0%;margin:0;height:100%;" [style.width.%]="pageFrom=='ScheduleTask'? '14' : '15'" *ngIf="pageFrom!='Proposal'">
          <img src="../../../assets/img/Account Hx.png" style="height:25px;" *ngIf="viewaccount.AccountHistoryNote == '' || viewaccount.AccountHistoryNote == null" title="Account Hx" />
          <img src="../../../assets/img/Account Hx Icon - Yellow.png" style="height:25px;" *ngIf="viewaccount.AccountHistoryNote !='' && viewaccount.AccountHistoryNote != null" title="Account Hx" />
        </div>
        <div class="col-xs-1" (click)="buttonClick('StatusId','View')" style="padding:0%; margin: 0; height: 100%; " *ngIf="viewaccount.accountId !== undefined && pageFrom!='Proposal'"
             [style.width.%]="pageFrom=='ScheduleTask'? '15' : '14'">
          <img *ngIf="statusIdExist == false" src="../../../../assets/img/Status ID Grey.png" style="height:25px;" />
          <img *ngIf="statusIdExist == true" src="../../../../assets/img/Status ID Green.png" style="height:25px;" />
        </div>
        <div class="col-xs-1" style="padding:0%; margin: 0; height: 100%; " *ngIf="viewaccount.accountId !== undefined && pageFrom!='Proposal'" [style.width.%]="pageFrom=='ScheduleTask'? '15' : '14'">
          <img src="../../../assets/img/Future Tasks None.png" *ngIf="activitieslist.length==0" style="height: 25px; " title="Future Task" />
          <img src="../../../assets/img/Future Tasks.png" *ngIf="activitieslist.length>0" (click)="buttonClick('FutureTask','')" style="height: 25px;" title="Future Task" />

        </div>
        <div class="col-xs-1" style="padding:0%;margin:0;height:100%;" [style.width.%]="pageFrom=='ScheduleTask'? '17' : '12'" *ngIf="pageFrom!='Proposal'">
          <img (click)="buttonClick('openNotes','')" src="../../../assets/img/No Notes.png" style="height:25px;" *ngIf="noteslist.length == 0" title="Add Notes" />
          <img (click)="buttonClick('openNotes','')" src="../../../../assets/img/Notes.png" style="height:25px;" *ngIf="noteslist.length > 0" title="Add Notes" />
        </div>
        <div class="col-xs-1" *ngIf="account.ownerid==currentuser.id && pageFrom!='Proposal'" (click)="buttonClick('Accountsharing','share')" style="padding: 0%; margin: 0; height: 100%; width: 14%;">
          <img src="../../../assets/img/Sharing Off.png" *ngIf="account.isshared == 0" style="height:25px" title="Shared" />
          <img src="../../../assets/img/Share Orange.png" *ngIf="account.isshared == 1" style="height:25px" title="Shared" />
        </div>

        <div class="col-xs-1" *ngIf="account.ownerid!=currentuser.id && currentuser.systemadministrator!=1 && pageFrom!='Proposal'" style="padding: 0%; margin: 0; height: 100%; width: 14%;">
          <img src="../../../assets/img/Sharing Off.png" *ngIf="account.isshared == 0" style="height:25px" title="Account Sharing" />
          <img src="../../../assets/img/Sharing Blue.png" *ngIf="account.isshared == 1" style="height:25px" title="Account Sharing" />
        </div>
        <div class="col-xs-1" *ngIf="account.ownerid!=currentuser.id && currentuser.systemadministrator==1 && pageFrom!='Proposal'" (click)="buttonClick('Accountsharing','share')"
             style="padding: 0%; margin: 0; height: 100%; width: 14%;">
          <img src="../../../assets/img/Sharing Off.png" *ngIf="account.isshared == 0" style="height:25px" title="Account Sharing" />
          <img src="../../../assets/img/Sharing Blue.png" *ngIf="account.isshared == 1" style="height:25px" title="Account Sharing" />
        </div>
        <div *ngIf="pageFrom!='ScheduleTask' && pageFrom!='Ticket' && pageFrom!='Proposal'" class="col-xs-1" style="padding: 0%; margin: 0; height: 100%; width: 14%;">
          <button (click)="buttonClick('Accountsharing','EditAccount')" [ngClass]="account.isAccountPermission!='View' ? 'btn-xs btn-default disabled' :'btn-xs btn-default'"
                  [ngStyle]="{'backgroundColor':(this.account.isAccountPermission == 'View') ? 'white' : 'white','color':(this.account.isAccountPermission == 'View') ? 'lightgrey' : 'black'}">
            <img src="../../../assets/img/Edit Account.png" style="height:25px;" title="Edit Account" />
          </button>

        </div>
      </div>

    </div>
  </div>

  <div *ngIf="ViewData == 'Account'" id="AccView" [ngClass]="(tab == 'QuickView')? 'col-xs-6':'col-xs-6' "
       style="padding:0;height:100%;" [style.font-size.pt]="tab === 'QuickView' ? '9.0' : '9.0'">
   
    <div class="col-xs-12 " style="padding:0%;height:45px;">
      <b (click)="buttonClick('AccountInfo',viewaccount.accountId)" class="providerLabel" style="font-family: Calibri; color: #00b3f7d9; font-size: 13pt; text-decoration: underline ">{{viewaccount.viewaccountname}}</b>
    </div>
    <div class="col-xs-12" style="padding: 0;">
      {{viewaccount.viewaddress1}}
    </div>
    <div class="col-xs-12" style="padding: 0;" *ngIf="viewaccount.viewaddress2!=null && viewaccount.viewaddress2!= ''">{{viewaccount.viewaddress2}}</div>
    <div *ngIf=" viewaccount.viewcity!='Nassau' " class="col-xs-12" style="padding: 0;">
     
      <span *ngIf="viewaccount.viewcity">{{viewaccount.viewcity}}</span>
      <span *ngIf="viewaccount.viewstate">,&nbsp;{{viewaccount.viewstate | uppercase}}.</span>
      <span *ngIf="viewaccount.ProvidenceIsland">{{viewaccount.ProvidenceIsland}}</span>
      <span *ngIf="viewaccount.viewzip">  {{viewaccount.viewzip | slice:0:5}}<span *ngIf="viewaccount.viewzip && viewaccount.viewzip.length > 5">-</span>{{viewaccount.viewzip | slice:5:20}}</span>
    </div>
    <div *ngIf="viewaccount.viewcity=='Nassau'" class="col-xs-12" style="padding:0;">
      
      <span *ngIf="viewaccount.viewstate">{{viewaccount.viewstate}}</span>
      <span *ngIf="viewaccount.ProvidenceIsland">{{viewaccount.ProvidenceIsland}}</span>
    </div>
    <div class="col-xs-12" style="padding: 0;" *ngIf="viewaccount.viewCountry!=null && viewaccount.viewCountry!= ''">{{viewaccount.viewCountry}}</div>
    <div class="col-xs-12" style="padding: 0;">
      <span *ngIf="viewaccount.viewphone!=null && viewaccount.viewphone!=''">Phone : {{viewaccount.viewphone}}</span>
      <span *ngIf="(viewaccount.viewphone!=null && viewaccount.viewphone!='') && (viewaccount.viewfax!=null && viewaccount.viewfax!='')">, </span>
      <span *ngIf="viewaccount.viewfax!=null && viewaccount.viewfax!=''">Fax: {{viewaccount.viewfax}}</span>
    </div>
    <div class="col-xs-12" style="padding: 0;">

      <div style="padding:0%" *ngIf="pageFrom!='ScheduleTask'">
        <span *ngIf="viewaccount.viewwebsite!=''">
          <a href="http:\\{{viewaccount.viewwebsite}}" target="_blank">{{viewaccount.viewwebsite}}</a>
        </span>
        <span *ngIf="viewaccount.viewwebsite==''||viewaccount.viewwebsite==null ||viewaccount.viewwebsite==undefined">
          Website Address Not Entered
        </span>
      </div>
    </div>
    <div class="col-xs-11" style="padding: 0;">
      <span> Owner :  </span><span style="margin-left: 5px;">{{viewaccount.ownerName}}</span>
    </div>
    <div class="col-xs-12" *ngIf="(applicationComponent.displayIcons && pageFrom!='ScheduleTask'&&
         pageFrom!='Proposal' && applicationComponent.selectedbuttonText!='Quick View' && applicationComponent.selectedbuttonText.trim()!='Create Call List' && applicationComponent.selectedbuttonText!='My Campaigns')" style="padding: 0;">
      {{applicationComponent.selectedbuttonText}}
      <nav id="nav-menu-container">
        <ul class="footer-nav" style="width:100%;padding:0px">
          <li class="subButtons" name="Schedule Task" id="schedTask_button" title="Schedule Task" (click)="applicationComponent.activateMainButtons('Create Task')" style="border: none !important; height: 28px; width: 12%;">
            <img *ngIf="applicationComponent.pageViewed !== 'ScheduleTask'" src="../../assets/img/Create Task Icon V4.png" style="height: 28px;width: 30px;" />
            <img *ngIf="applicationComponent.pageViewed === 'ScheduleTask'" src="../../assets/img/Create Task Icon V4 Orange.png" style="height: 28px;width: 30px;" />
          </li>

          <li class="subButtons" name="SMS" id="sms_button" title="Create SMS" (click)="applicationComponent.loaddialog('SearchNotAvailable', 'Action Icon','Feature Not Available',false,false,false,true,0)" style="border: none !important; height: 28px; width: 12%;">
            <img *ngIf="applicationComponent.pageViewed !== 'CreateSMS'" src="../../assets/img/Create SMS Icon.png" style="height: 28px;width: 30px;" />
            <img *ngIf="applicationComponent.pageViewed === 'CreateSMS'" src="../../assets/img/Create SMS Icon Orange.png" style="height: 28px;width: 30px;" />
          </li>

          <li class="subButtons" name="Email" id="email_button" title="Create Email" (click)="applicationComponent.loaddialog('SearchNotAvailable', 'Action Icon','Feature Not Available',false,false,false,true,0)" style="border: none !important; height: 28px; width: 12%;">
            <img *ngIf="applicationComponent.pageViewed !== 'CreateEmail'" src="../../assets/img/Create Email Icon.png" style="height: 28px;width: 30px;" />
            <img *ngIf="applicationComponent.pageViewed === 'CreateEmail'" src="../../assets/img/Create Email Icon Orange.png" style="height: 28px;width: 30px;" />
          </li>
          <li class="subButtons" name="Create Proposal" id="proposal_button" title="Create Proposal" (click)="applicationComponent.activateMainButtons('Create Proposal')" style="border: none !important; height: 28px; width: 12%;">
            <img (click)="applicationComponent.activateMainButtons('Create Proposal')" *ngIf="applicationComponent.pageViewed !== 'CreateProposal'" src="../../assets/img/Create Proposal Icon.png" style="height:28px;width:30px;" />
            <img (click)="applicationComponent.activateMainButtons('Create Proposal')" *ngIf="applicationComponent.pageViewed === 'CreateProposal'" src="../../assets/img/Create Proposal Icon Orange.png" style="height:28px;width:30px;" />

          </li>
          <li (click)="applicationComponent.activateMainButtons('Create Tickets')" class="subButtons" name="Tickets" id="tickets_button" title="Create Ticket" style="border: none !important; height: 28px; width: 12%;">
            <img *ngIf="applicationComponent.pageViewed !== 'CreateTicket'" src="../../assets/img/Create Support Ticket Icon.png" style="height: 28px; width: 30px;" />
            <img *ngIf="applicationComponent.pageViewed === 'CreateTicket'" src="../../assets/img/Create Support Ticket Icon Orange.png" style="height: 28px; width: 30px;" />
          </li>

          <li class="subButtons" name="Responsewizard" id="Responsewizard_button" style="border: none !important; height: 28px; width: 12%;" title=" Response Wizard">
            <img src="../../assets/img/RW light green_1.png" *ngIf="!applicationComponent.RWActive" style="height: 28px; width: 34px" (click)="applicationComponent.RWActiveClick(1)" />
            <img src="../../assets/img/RW orange_1.png" *ngIf="applicationComponent.RWActive" style="height: 28px; width: 34px" (click)="applicationComponent.RWActiveClick(0)" />
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div *ngIf="ViewData == 'Contact'" id="ContactView" [ngClass]="(tab == 'QuickView')? 'col-xs-6' : 'col-xs-6'" [style.padding]="pageFrom=='ScheduleTask'? '0 0 0 0' : '0% 0 0 0'" style="padding:0% 0 0 0;height:100%;" [style.font-size.pt]="tab === 'QuickView' ? '9.0' : '9.0'">
    
    <div class="col-xs-12" style="padding: 0;">
      <div class="providerLabel" *ngIf="pageFrom!='Accounts' && pageFrom!='ScheduleTask' && pageFrom!='Leads'&& pageFrom!='TaskList' " style="font-size:12px">{{contact.firstname}} {{contact.middlename}} {{contact.lastname}} <span *ngIf="contact.title!=null && contact.title!=''">, {{contact.titleName}}</span></div>
      <div class="col-xs-12 " style="padding: 0%;height:45px;">
        <b (click)="buttonClick('AccountInfo',viewaccount.accountId)" class="providerLabel" style="font-family: Calibri; color: #00b3f7d9; font-size: 13pt; text-decoration: underline ">{{viewaccount.viewaccountname}}</b>
      </div>
      <div class="col-xs-12" style="padding: 0;">{{viewaccount.viewaddress1}} </div>
      <div class="col-xs-12" style="padding: 0;" *ngIf="viewaccount.viewaddress2!=null && viewaccount.viewaddress2!= ''"> {{viewaccount.viewaddress2}} </div>

      <div class="col-xs-12" style="padding: 0;">
        <span *ngIf="viewaccount.viewcity">{{viewaccount.viewcity}}</span>
        <span *ngIf="viewaccount.viewstate">,&nbsp;{{viewaccount.viewstate | uppercase}}.</span>
        <span *ngIf="viewaccount.ProvidenceIsland">{{viewaccount.ProvidenceIsland}}</span>
        <span *ngIf="viewaccount.viewzip">&nbsp;{{viewaccount.viewzip | slice:0:5}}<span *ngIf="viewaccount.viewzip && viewaccount.viewzip.length > 5">-</span>{{viewaccount.viewzip | slice:5:20}}</span>
      </div>
      <div *ngIf="viewaccount.viewCountry" class="col-xs-12" style="padding: 0;">{{viewaccount.viewCountry}} </div>
      <div class="col-xs-12" style="padding: 0;">
        <span *ngIf="viewaccount.viewphone!=null && viewaccount.viewphone!=''">Phone : {{viewaccount.viewphone}}</span>
        <span *ngIf="(viewaccount.viewphone!=null && viewaccount.viewphone!='') && (viewaccount.viewfax!=null && viewaccount.viewfax!='')">, </span>
        <span *ngIf="viewaccount.viewfax!=null && viewaccount.viewfax!=''">Fax: {{viewaccount.viewfax}}</span>
      </div>
      <div class="col-xs-12" style="padding: 0;">

        <div style="padding:0%">
          <span>
            <a href="http:\\www.{{viewaccount.viewwebsite}}" target="_blank">{{viewaccount.viewwebsite}}</a>
          </span>
        </div>
        <div class="col-xs-11" style="padding: 0;">
          <span> Owner :  </span><span style="margin-left: 5px;">{{viewaccount.ownerName}}</span>
        </div>
      </div>

    </div>
  </div>

  <div class="col-xs-6" style="padding:0;height:100%;float:right;">

    <div class="col-xs-12" [ngStyle]="{'padding': pageFrom!='ScheduleTask' ? '1% 1% 1% 10%' : '0%'}" style="margin: 0; height: 79%; font-size: 9.0pt">

      <div class="col-xs-10" style="padding: 0;padding-left:10%; ">
        <div class="col-xs-12" style="padding:0;text-align:left"><label style="margin:0;font-weight:800;color:white;">EHR: </label></div>
        <div class="col-xs-12" style="padding:0;text-align:left">
          <label *ngIf="account.isCustomer == 874" style="font-family: Calibri; color: #00b3f7d9; font-size: 12pt ">
            CUSTOMER SINCE {{account.CustomerSince | date:"MM-dd-yyyy"}}
          </label>
        </div>
        <div class="col-xs-12" style="padding:0;text-align:left"><label style="margin:0;font-weight:800;">Speciality: </label>&nbsp;{{this.account.specialityName}}</div>
        <div class="col-xs-12" style="padding:0;text-align:left"><label style="margin:0;font-weight:800;">EHR: </label>&nbsp;{{this.account.ehrName | slice:0:20}}</div>
        <div class="col-xs-12" style="padding:0;text-align:left"><label style="margin: 0; font-weight: 800;">PM: </label>&nbsp;{{this.account.pmName | slice:0:20}}</div>
        <div class="col-xs-12" style="padding:0;text-align:left"><label style="margin: 0; font-weight: 800;">RCM: </label>&nbsp;{{this.account.rcmName | slice:0:18}}</div>
        <div class="col-xs-12" *ngIf="LastContactDate!=null" style="padding: 0; text-align: left; ">
          <label style="margin: 0; font-weight: 800;">L/Activity: </label>&nbsp;{{LastContactDate}}
          <!--{{LastContactDate | date:"MM-dd-yyyy"
          }}-->
        </div>
        <div class="col-xs-12" style="padding:0;text-align:left"><label style="margin: 0; font-weight: 800;">L/Ticket: </label>&nbsp;{{LastTicketDate}}</div>
        <div class="col-xs-12" style="padding:0;text-align:left"><label style="margin: 0; font-weight: 800;">Sales Rep: </label>&nbsp;{{salesrepName}}</div>
      </div>
     
    </div>

  </div>
</div>
