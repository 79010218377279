import { Component, OnInit, Input, EventEmitter, Output, Optional } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { Accounts } from 'src/app/models/accounts';
import { Accountlocations } from 'src/app/models/data-model';
import { AccountsHistory } from '../../models/AccountHistory';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { GridOptions } from 'ag-grid';
import { Notes } from '../../models/notes';
declare var $: any;

@Component({
  selector: 'accountorcontactview',
  templateUrl: './accountorcontactview.component.html',
  styleUrls: ['./accountorcontactview.component.css']
})
export class AccountorcontactviewComponent implements OnInit {
  @Input() accountId;
  @Input() type;
  account: Accounts = new Accounts();
  multiplelocaddresses: Accountlocations[] = [];
  datePipe: DatePipe = new DatePipe("en-US");
  selectedMenuRadio: string = "tab0";
  SelectedContactType;
  LastTicketDate;
  LastContactDate;
  customeracces: number = 0;
  currentuser: any;
  accountHistory: AccountsHistory = new AccountsHistory();
  @Output()
  btnClickEvent = new EventEmitter<any>();
  Fields: any;
  ExpandMode: boolean = false;
  userprevilages: any;
  urlImage = '';
  additionalLocation: Accountlocations = new Accountlocations();
  ShowImageDialog: boolean = false;
  ImageData: string = "";
  salesrepName: string;
  timezonevalue: string;
  public gridOptionsnotes: GridOptions;
  public sideBar;
  allnotes: Notes[];
  public columnDefsnotes: any[];
  constructor(private customerService: CustomerService, private toastr: ToastrService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.customeracces = this.currentuser.customertypeaccess;
    this.customerService.GetPrivilagesByuser(this.currentuser.id).then(result => {
      this.userprevilages = result;
    });
  }

  ngOnInit(): void {
    this.LoadAccountData(); 
    this.customerService.GetAccountHistoryById(this.accountId, this.currentuser.id).then(result => {
      if (result != null) {
        this.accountHistory = result;
      }
    });
  }
  tabChanged(val) {
    this.selectedMenuRadio = val;
    if (this.multiplelocaddresses && val == 'tab7') {
      this.multiplelocaddresses.forEach(ele => {
        if (ele.addressName == 'Main Location' && ele.LocationPicture) {
          let so = this;
          $(setTimeout(function () {
            var imgtag = document.getElementById('image');
            imgtag.setAttribute('src', ele.LocationPicture);
            imgtag.setAttribute('title', 'Click to Collapse');
            imgtag.setAttribute('style', 'width:100%;height:75%;padding:15% 0 0 0;float:right;display:block');
            $('#ChooseFileLabel').attr('style', 'display:none');
            $('#ReplaceFileLabel').attr('style', 'display:block');
          }, 500));
        } else if (ele.LocationPicture) {
          $(setTimeout(function () {
            var imgtag = document.getElementById('image' + ele.id);
            imgtag.setAttribute('src', ele.LocationPicture);
            imgtag.setAttribute('title', 'Click to Collapse');
            imgtag.setAttribute('style', 'width:100%;height:75%;padding:15% 0 0 0;float:right;display:block');
            $('#ChooseFileLabel' + + ele.id).attr('style', 'display:none');
            $('#ReplaceFileLabel' + + ele.id).attr('style', 'display:block');
          }, 500));
        }

      });
    }
    else if (val == 'tab5') {
      this.customerService.GetFormConfiguration(this.currentuser.id, 'Customer Details').then(res => {
        if (res != null && res.length > 0) {
          let obj: string[] = [res[0].OrderFields, 'Customer Details'];
          this.customerService.getSaveFormFields(obj).then(result => {
            this.Fields = result;
           
          });
        }
      });
      this.customerService.GetByAccountIdNew(this.accountId).then(result => {
        this.account = JSON.parse(result);
     
      });

    }
    else if (val == 'tab1') {
      this.customerService.GetFormConfiguration(this.currentuser.id, 'Account Specifics').then(res => {
        if (res != null && res.length > 0) {
          let obj: string[] = [res[0].OrderFields, 'Account Specifics'];
          this.customerService.getSaveFormFields(obj).then(result => {

            this.Fields = result;
          });
        }
      });
    }
    else if (val == 'tab4') {
      this.customerService.GetFormConfiguration(this.currentuser.id, 'Mailing Address').then(res => {
        if (res != null && res.length > 0) {
          let obj: string[] = [res[0].OrderFields, 'Mailing Address'];
          this.customerService.getSaveFormFields(obj).then(result => {

            this.Fields = result;
          });
        }
      });
    }
    else if (val == 'tab6') {
      this.DisplayNote();
    } else if (val == 'tab0' || val == 'tab10' || val == 'tab11') {    
      this.customerService.GetAccountHistoryById(this.account.id, this.currentuser.id).then(result => {
        if (result != null) {
          this.accountHistory = result;
        }
      });
    }
  }
  DisplayNote() {
    let allContactIds = [];
    this.customerService.GetAllContactproviderByAccount(this.accountId, this.currentuser.id).then((contacts) => {
      if (contacts.length > 0) {
        allContactIds = contacts.map(x => x.id);
        this.GetCustomerNotes(this.currentuser.id, allContactIds.join(","), 'AllContactsNotes', this.accountId.toString(), "null");
      } else {
        this.GetCustomerNotes(this.currentuser.id, "0", 'AllContactsNotes', this.accountId.toString(), "null");
      }
    });
  }

  GetCustomerNotes(currentuserid, contactid, type, accidofallcontacts: string = "0", OppType: string) {

    var moment = require('moment-timezone');
    this.customerService.GetAllNotesListNew(currentuserid, contactid, type, accidofallcontacts, OppType).then((taskhistory) => {
      taskhistory.forEach(items => {
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh:mm a");

      });
      this.initializeAgGridNotes(type);
      this.allnotes = taskhistory;
      this.allnotes = this.allnotes.filter(x => x.isnewnote == null);
      for (let note of this.allnotes) {
        if (note.notedescription) {
          note.notedescription = note.notedescription.replace(/<[^>]*>|&nbsp;/g, '');
        }
        if (note.isdocumentid != null && note.isdocumentid != "") {
          note.docList = note.isdocumentid.split(",");
        }
      }

    });

  }
  initializeAgGridNotes(val) {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, paginationPageSize: 1000,enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'

    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',
      defaultToolPanel: 'none',
    };
    this.createColumnDefsnotes(val);

  }
  createColumnDefsnotes(val, @Optional() isShowDetails: boolean = false) {
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false },
      {
        field: 'notedescription', hide: false, tooltipField: 'notedescription', width: 250, cellStyle: { 'text-align': 'left' }, cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.value && params.value.includes("Owner Changed")) {
            eDiv.innerHTML = "<label style='color:red'>" + params.value + "</label>";
          } else {
            eDiv.innerHTML = params.value;
          }

          return eDiv;
        }
      },
    ];

  }
  close() {
   
    this.btnClickEvent.emit('close');
  }
  LoadAccountData() {
    this.customerService.GetFormConfiguration(this.currentuser.id, 'Mailing Address').then(res => {
      if (res != null && res.length > 0) {
        let obj: string[] = [res[0].OrderFields, 'Mailing Address'];
        this.customerService.getSaveFormFields(obj).then(result => {         
          this.Fields = result;
        });
      }
    });
   
    this.customerService.GetByAccountIdNew(this.accountId).then(result => {    
      this.account = JSON.parse(result);
      this.account.ehrdatepurchased = (this.account.ehrdatepurchased)? moment(this.account.ehrdatepurchased).format("YYYY") : '';
      this.account.pmdatepurchased = (this.account.pmdatepurchased)? moment(this.account.pmdatepurchased).format("YYYY") : '';
      this.account.rcmdatepurchased = (this.account.rcmdatepurchased)? moment(this.account.rcmdatepurchased).format("YYYY"): '';
      let SO = this;
      this.customerService.GetTableFields('Accounts', this.currentuser.id).then(result => {
        result.forEach(ele => {
          if (ele.OldOrNew == "New" && ele.DataType == 'Pick List' && SO.account[ele.ColumnName] != null) {
            SO.account[ele.ColumnName] = SO.account[ele.ColumnName].split(':')[1];
          }
        });

      });
      var Addloc = new Accountlocations();
      Addloc.address1 = this.account.address1;
      Addloc.address2 = this.account.address2;
      Addloc.city = this.account.city;
      Addloc.state = this.account.state;
      Addloc.zip = this.account.zip;
      Addloc.fax = this.account.fax;
      Addloc.phone = this.account.phone;
      Addloc.lattitude = this.account.lattitude;
      Addloc.longtitude = this.account.longtitude.toString();
      Addloc.ProvidenceIsland = this.account.ProvidenceIsland;
      Addloc.country = this.account.country;
      Addloc.accountid = this.account.id;
      Addloc.LocationPicture = this.account.LocationPicture;
      Addloc.addressName = 'Main Location';
      
      this.multiplelocaddresses.push(Addloc);
      this.customerService.GetLocationByAccountId(this.accountId).then(result => {
        result.forEach(ele => {
          if (ele.address1 != null && ele.address1 != "") {
            
            this.multiplelocaddresses.push(ele);
          }
        })
        
        this.getAccountHistory();
      });

    });
  }
  getAccountHistory() {
    this.customerService.LastTicketCreatedDateByAccID(this.account.id).then(result1 => {
      if (result1 != null && result1 != '0001-01-01T00:00:00') {
        this.LastTicketDate = this.datePipe.transform(result1, "MM-dd-yyyy")
      }
      
    });
    this.customerService.LastContactedDateByAccID(this.account.id).then(result2 => {
      if (result2 != null && result2 != '0001-01-01T00:00:00') {
        this.LastContactDate = this.datePipe.transform(result2, "MM-dd-yyyy");
      }
    });
    this.customerService.GetAccountHistoryById(this.account.id, this.currentuser.id).then(result => {
      if (result != null) {
        this.accountHistory = result;
      }
    });
    this.customerService.GetSalerepName(this.account.id).then(result3 => {

      this.salesrepName = result3;

    });
  }

  buttonClick(btnName, val) {
    this.btnClickEvent.emit({ btnName: btnName, val: val });

  }
  Expand(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      this.ExpandMode = true;
    } else {
      this.ExpandMode = false;
    }
    this.btnClickEvent.emit(msg);
  }

  NOLicenseAccess() {
    alert("No access");
  }
  checkPrevilege() {
    if (this.userprevilages != undefined) {
      var thisTab = this.userprevilages.filter(p => p.resource == "License Tab")[0];
      if (thisTab == undefined || (thisTab != undefined && thisTab.canview == false)) {
        this.toastr.warning("You Do Not have Access to View License Tab – Please check with CRM Administrator if Access is Required.", "Access Denied", { timeOut: 1500 });

        return false;
      } else {
        return true;
      }
    }
  }

  addFiles(event,address) {
      let files = event.srcElement.files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        this.addChargeNew(file, address);
      }
    
  }

  addChargeNew(file, myaddress) {
    this.additionalLocation = myaddress;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    let selfObj = this;
    $(setTimeout(function () {
      if (myaddress.addressName != 'Main Location') {
        var imgtag = document.getElementById('image' + myaddress.id);
        imgtag.setAttribute('src', reader.result.toString());
        imgtag.setAttribute('title', 'Click to Collapse');
        imgtag.setAttribute('style', 'width:10%;height:75%;padding:15% 0 0 0 ;float:right;display:block');
        $('#ChooseFileLabel' + + myaddress.id).attr('style', 'display:none');
        $('#ReplaceFileLabel' + + myaddress.id).attr('style', 'display:block');
      } else {
        var imgtag = document.getElementById('image');
        imgtag.setAttribute('title', 'Click to Collapse');
        imgtag.setAttribute('src', reader.result.toString());
        imgtag.setAttribute('style', 'width:100%;height:75%;padding:15% 0 0 0;float:right;display:block');
        $('#ChooseFileLabel').attr('style', 'display:none');
        $('#ReplaceFileLabel').attr('style', 'display:block');
        
      }
      selfObj.additionalLocation.LocationPicture = reader.result.toString();
      selfObj.additionalLocation.PictureType = file.type;

          
      selfObj.customerService.uploadAdditionalLocationImage(selfObj.additionalLocation).then((user) => {
      });
    }, 500));
    

  }
  ShowImageFullScreen(locId) {
    var Id = (locId != undefined && locId != null) ? locId : "";

    var button = document.getElementById('image' + Id);
    if (button.title == 'Click to Expand') {
      $('#image' + Id).attr('style', 'width:100%;height:75%;padding:15% 0 0 0;float:right;display:block')
      $('#image' + Id).attr('title', 'Click to Collapse');
    } else {
      $('#image' + Id).attr('style', 'width:50%;height:75%;padding:15% 0 0 0;float:right;display:block')
      $('#image' + Id).attr('title', 'Click to Expand');
    }
  }
}
