<div class="col-xs-12" style="height:100%;padding:0  0 0 0%;">
 
  <div class="col-md-12 col-sm-12 col-xs-12" style="padding:  0;" *ngIf="!ShowViewCampaign && Source!=='AccountView' && Source!=='ContactView'">
    <div class="col-xs-11" style="height: 100%; padding: 0 0 0 1%; width: 96%">
      <userdataheader [parentComp]="'Campaigns'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
    </div>
  </div>

  <div class="col-xs-12" style="padding:0;height:94%" [style.height.%]="(ShowViewCampaign)? '99' : '93'">
    <div class="col-xs-12" style="padding: 0 0 0 1%; height: 100%" *ngIf="ShowCampaignGrid">
      <div class="col-xs-12" style="padding:1% 0 0 ;height:7%" *ngIf="!ShowViewCampaign && Source!=='AccountView' && Source!=='ContactView'">

        <div class="col-xs-1" style=" padding: 0; width: 2%;"> <img src="../../assets/img/Add Campaign.png" style="height: 20px;float: right;width: 100%" (click)="addNewCampaign(true);" /></div>
        <div class="col-xs-6"><label>My Campaigns</label></div>
      </div>

      <div class="col-xs-12" style="padding:0;height:93%">
       <ag-grid-angular style="width: 100%;height:100%" id="gridheight" class="ag-theme-balham"
                         [gridOptions]="gridOptions"
                         [columnDefs]="columnDefs"
                         [rowData]="allCampaigns"
                         [sideBar]="sideBar"
                         [defaultColDef]="applicationComponent.defaultGridColDef"
                         (selectionChanged)="onSelectionChanged($event)"
                         (columnVisible)="columnVisible($event)"
                         (dragStopped)="dragStopped($event)"
                         (columnResized)="columnResized($event)">
        </ag-grid-angular>
      </div>

    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ShowAddCampaign">
      <div class="col-xs-12" style="padding:0;height:4%">
        <div class="col-xs-6"><label>Add Campaigns</label></div>
        <div class="col-xs-1 pull-right" style=" padding: 0; width: 7%;">  <i class="fa fa-arrow-left" aria-hidden="true" (click)="back();"></i></div>

      </div>

      <div class="col-xs-12" style="padding:0;height:96%">
        <div class="col-xs-4" style="padding:0;height:100%">
          <div class="col-xs-12" style="height:8%">
            <select class="form-control" name="CampaignID" [(ngModel)]="campaign.CampaignTypeId" required>
              <option value="null" selected>Select Type of Campaign...</option>
              <option *ngFor="let campaign of campaigntypelist" value={{campaign.CampaignTypeId}}>
                {{campaign.CampaignTypeName}}
              </option>
            </select>
          </div>
          <div class="col-xs-12" style="height:8%">
            <input [(ngModel)]="campaign.CampaignName" type="text" class="form-control " placeholder="Type Name of the Campaign" (keyup)="ShowAddCampaignButtons = true" required>
          </div>
          <div class="col-xs-12" style="height:8%;padding:0;" *ngIf="ShowAddCampaignButtons">
            <div class="col-xs-6"></div>
            <div class="col-xs-3"><button class="btn displayButton" (click)="back()" id="cancelAddCampaign" style="width:99.5%">Cancel</button></div>
            <div class="col-xs-3"> <button id="SaveAddCampaign" (click)="saveCampaignCosts(true)" class="btn saveButton" style="width: 100% !important">Save</button></div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ShowViewCampaign">
      <div class="col-xs-4" style="height:100%;" *ngIf="ShowLeftSideDiv">
        <div class="col-xs-12" style="padding:0;height:6%">
          <div class="col-xs-10" style="padding:0;height:100%;font-size:15px;">
            <label (click)="EditCampaignName = true" *ngIf="!EditCampaignName" style="margin-top:1%">{{campaign.CampaignName}}</label>
            <div class="col-xs-9" style="height:8%;padding:0" *ngIf="EditCampaignName">
              <input [(ngModel)]="campaign.CampaignName" type="text" class="form-control " placeholder="Type Name of the Campaign" (keyup)="ShowAddButton = true" required>
            </div>
            <div class="col-xs-2" style="padding:0;height:100%;margin-left:2%" *ngIf="EditCampaignName && ShowAddButton">
              <button id="SaveAddCampaign" (click)="saveCampaignCosts(true);ShowAddButton = false;EditCampaignName = false" class="btn saveButton" style="width: 100% !important">Save</button>
            </div>
          </div>
          <div class="col-xs-1 pull-right" *ngIf="Source!='dashboard'" style="padding:0;height:100%"><img src="../../../assets/img/Display Tasks.png" style="height:22px;float:right" (click)="ShowLeftSideDiv = false;" /></div>
        </div>
        <div class="col-xs-12" style="padding:0;height:6%">
          <div class="col-xs-2" style="padding:1% 0 0 0;height:100%;width:15%">&nbsp;({{( LinkedContacts != null && LinkedContacts.length != undefined )? LinkedContacts.length : 0}})</div>
          <div class="col-xs-7" style="padding:0;height:100%">
            <select class="form-control" name="campaignid" [(ngModel)]="campaign.CampaignTypeId">
              <option value="null" disabled [selected]="true">Select Type of Campaign...</option>
              <option *ngFor="let campaign of campaigntypelist" value={{campaign.CampaignTypeId}}>
                {{campaign.CampaignTypeName}}
              </option>
            </select>
          </div>
          <div class="col-xs-1" style="padding:0;margin-left:10%"><img src="../../../assets/img/Question Mark Icon- Black.png" style="height:30px;" (click)="CampaignsInstructions = true;DialogCss('Instructions')" /> </div>
          <div class="col-xs-1 pull-right" style="padding:0;"><img src="../../../assets/img/Link Contact.png" style="height:30px;" (click)="ShowContactsDiv = true;showContactsdiv()" /> </div>
        </div>

        <div class="col-xs-12" style="padding:0;height:87%">
          <div class="col-xs-12" style="padding:0;height:84%">
          <ag-grid-angular style="width: 100%;height:100%" id="gridheight" class="ag-theme-balham"
                             [gridOptions]="gridOptionsSelectedContacts"
                             [columnDefs]="columnDefsSelectedContacts"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="LinkedContacts"
                             [sideBar]="sideBar"
                             [context]="context"
                             (selectionChanged)="onContactSelectionChanged($event)"
                             [frameworkComponents]="frameworkComponents"
                             (columnVisible)="columnVisible($event)"
                             (dragStopped)="dragStopped($event)"
                             (columnResized)="columnResized($event)"
                             (gridReady)="onAgGridReady($event)">
            </ag-grid-angular>
          </div>
          <div class="col-xs-12" style="padding:0;height:15%;margin-top:1%">
            <div class="col-xs-12" style="padding:0;height:50%">
              <div class="col-xs-3" style="padding:1%;height:100%;text-align:center"><label>Status</label></div>
              <div class="col-xs-9" style="padding:0;">
                <select class="form-control" name="campaignid" [(ngModel)]="campaign.CampaignStatus">
                  <option value="Open" [selected]="true">Open</option>
                  <option value="Closed" [selected]="true">Closed</option>
                </select>
              </div>

            </div>
            <div class="col-xs-12" style="padding:0;height:50%">
              <div class="col-xs-6"></div>
              <div class="col-xs-3"><button class="btn displayButton" style="width:99.5%">Cancel</button></div>
              <div class="col-xs-3"> <button (click)="saveCampaignCosts(true)" class="btn saveButton" style="width: 100% !important">Save</button></div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-4" *ngIf="!ShowLeftSideDiv" style="padding:0;height:98%">
        <div class="col-xs-12" style="padding:0;height:4%">
          <span (click)="ShowGrid()"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
        </div>
        <div class="col-xs-12 scroll-c" style="padding:0;height:100%">

          <app-new-notes style="height:100%"
                         [selectedTabName]="'1'"
                         [conid]="conid"
                         [typ]="'Contact'"
                         [ViewType]="'Block'"
                         [isModal]="false">
          </app-new-notes>
        </div>
      </div>

      <div style="padding:0;height:100%" *ngIf="!ShowContactsDiv" class="col-xs-8">
        <div [ngClass]="!toggleVal? 'col-xs-8' : 'col-xs-12'" style="padding:0;height:100%">
          <div class="col-xs-12" style="padding:0 0 0 1%;height:100%" *ngIf="conid > 0">
            <contactsdashboard [contactids]="conid" [type]="'Contact'" [tab]="'QuickView'" [Source]="'Campaigns'" [ShowRightInfoDiv]="toggleVal" (sendMsgEvent)="onContactMessageReceived($event)"> </contactsdashboard>

          </div>
          <div class="col-xs-12" style="padding:30% 20% 20% 30%;height:100%;font-size:15px;font-weight:600" *ngIf="LinkedContacts == undefined || LinkedContacts == null ||  LinkedContacts.length == 0">
            No Contacts Linked to Campaign
          </div>
        </div>
        <div *ngIf="!toggleVal" class="col-xs-4" style="padding:0;height:100%;border:1px solid #ddd">
          <div class="col-xs-12" style="padding: 0; height: 5%; background-color: rgb(40,62,74);color:white" *ngIf="Source!=='dashboard'">
            <label style="text-align: center;font-weight: normal;margin-top:1%">CAMPAIGN DETAILS</label>
          </div>
          <div class="col-xs-12" style="padding:0;height:95%">
            <div class="col-xs-12" style="height:33.3%;padding:0;">
              <div class="col-xs-6" style="padding:0;height:98%;border:2px solid rgb(186, 219, 152) !important;margin:0.5%;width:49%" (click)="OpenDialog('Expenses')" *ngIf="Source!='dashboard'">
                <div class="col-xs-12" style="padding:15% 10% 0% 10%;height:25%;text-align:center;"><label>Expenses</label></div>
                <div class="col-xs-12" style="padding:3% 10% 5% 10%;height:70%;text-align:center">
                  <img src="../../../assets/img/Expenses Grey.png" style="height:90px;" *ngIf="(campaign.Expenses == null ||campaign.Expenses == '' ||campaign.Expenses == undefined)" />
                  <img src="../../../assets/img/Expenses Green.png" style="height:90px;" *ngIf="(campaign.Expenses !== null && campaign.Expenses !== '' && campaign.Expenses !== undefined)" />
                </div>
              </div>

              <div class="col-xs-6" *ngIf="Source!='dashboard'" style="padding:0;height:98%;border:2px solid rgb(186, 219, 152) !important;margin:0.5%;width:49%" (click)="OpenDialog('ROI')">
                <div class="col-xs-12" style="padding:15% 10% 0% 10%;height:25%;text-align:center;"><label>ROI</label></div>
                <div class="col-xs-12" style="padding:3% 10% 5% 10%;height:70%;text-align:center">
                  <img src="../../../assets/img/ROI Grey.png" style="height:90px;" *ngIf="(campaign.Expenses == null ||campaign.Expenses == '' ||campaign.Expenses == undefined)" />
                  <img src="../../../assets/img/ROI Green.png" style="height:90px;" *ngIf="(campaign.Expenses !== null && campaign.Expenses !== '' && campaign.Expenses !== undefined)" />
                </div>
              </div>
            </div>

            <div class="col-xs-12" style="height:33.3%;padding:0;">
              <div class="col-xs-6" style="padding:0;height:98%;border:2px solid rgb(186, 219, 152) !important;margin:0.5%;width:49%" (click)="OpenDialog('Opportunities')">
                <div class="col-xs-12" style="padding:15% 0% 0% 0%;height:25%;text-align:center;"><label>Opportunities ({{( LinkedOpportunityIds != null && LinkedOpportunityIds.length != undefined )? LinkedOpportunityIds.length : 0}})</label></div>
                <div class="col-xs-12" style="padding:3% 10% 5% 10%;height:70%;text-align:center">
                  <img src="../../../assets/img/Opportunity Grey.png" style="height:90px;" *ngIf="(campaign.LinkedOpportunities == null ||campaign.LinkedOpportunities == '' ||campaign.LinkedOpportunities == undefined)" />
                  <img src="../../../assets/img/Opportunity Green.png" style="height:90px;" *ngIf="(campaign.LinkedOpportunities !== null && campaign.LinkedOpportunities !== '' && campaign.LinkedOpportunities !== undefined)" />
                </div>
              </div>
              <div class="col-xs-6" style="padding:0;height:98%;border:2px solid rgb(186, 219, 152) !important;margin:0.5%;width:49%" (click)="OpenDialog('Proposals')">

              </div>
            </div>

            <div class="col-xs-12" style="height:33.3%;padding:0;">
              <div class="col-xs-6" style="padding:0;height:98%;border:2px solid rgb(186, 219, 152) !important;margin:0.5%;width:49%" (click)="OpenDialog('Sales Won')">
                <div class="col-xs-12" style="padding:15% 10% 0% 10%;height:25%;text-align:center;"><label>Sales Won ({{( SalesWonopportunities != null && SalesWonopportunities.length != undefined )? SalesWonopportunities.length : 0}})</label></div>
                <div class="col-xs-12" style="padding:3% 10% 5% 10%;height:70%;text-align:center">
                  <img src="../../../assets/img/Sales Won Grey.png" style="height:90px;" *ngIf="SalesWonopportunities && SalesWonopportunities.length <= 0 " />
                  <img src="../../../assets/img/Sales Won Green.png" style="height:90px;" *ngIf="SalesWonopportunities && SalesWonopportunities.length > 0 " />
                </div>
              </div>
              <div class="col-xs-6" style="padding:0;height:98%;border:2px solid rgb(186, 219, 152) !important;margin:0.5%;width:49%" (click)="OpenDialog('Sales Lost')">
                <div class="col-xs-12" style="padding:15% 10% 0% 10%;height:25%;text-align:center;"><label>Sales Lost ({{( SalesLostopportunities != null && SalesLostopportunities.length != undefined )? SalesLostopportunities.length : 0}})</label></div>
                <div class="col-xs-12" style="padding:3% 10% 5% 10%;height:70%;text-align:center">
                  <img src="../../../assets/img/Sales Lost Grey.png" style="height:90px;" *ngIf="SalesLostopportunities && SalesLostopportunities.length <= 0 " />
                  <img src="../../../assets/img/Sales Lost Green.png" style="height:90px;" *ngIf="SalesLostopportunities && SalesLostopportunities.length > 0 " />
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div style="padding:0;height:100%;" *ngIf="ShowContactsDiv" [ngClass]="(ShowLeftSideDiv)? 'col-xs-8' : 'col-xs-12'" [style.width.%]="ShowLeftSideDiv?'66.5':'97'">
        <div class="col-xs-12" style="height:2%;background-color: rgb(186,220,153);">

        </div>
          <div class="col-xs-12" style="padding: 0; height: 4%; text-align: left; ">
            <div class="col-xs-11" style="padding:0;height:100%;font-size:17px;">
              <label>Select Contacts -- Link to Campaign</label>
            </div>
            <div class="col-xs-1" style="padding:0;height:100%">
            <img src="../../../assets/img/Black x.png" style="height:20px;float:right;"
                 (click)="ShowContactsDiv = false;this.Ids=[]" /></div>
          </div>

          <div class="col-xs-12" style="padding:0 0 0 1%;height:88%">         
            <div class="col-xs-12" style="padding:0% 0 0 0 ;height:9%;width:132%">
              <userdataheader [parentComp]="'Contact'" (outPropsEvent)="ContactsUserlistDataReceived($event)"> </userdataheader>
            </div>
            <div class="col-xs-12" style="padding:0;height:90%">
              <ag-grid-angular style="width: 100%; height:100% !important;" id="gridheight" class="ag-theme-balham"
                               [gridOptions]="ContactGridOptions"
                               [columnDefs]="ContactsColumnDefs"
                               [defaultColDef]="applicationComponent.defaultGridColDef"
                               [rowData]="allContacts"
                               [context]="context"
                               [sideBar]="sideBar"
                               [suppressRowClickSelection]="true"
                               (rowSelected)="ContactsrowClickAgGrid($event)"
                               (gridReady)="ContactsOnAgGridReady($event)">
              </ag-grid-angular>


            </div>

          </div>
          <div class="col-xs-12" style="padding:0.3%;height:6%">
            <div class="col-xs-2" style="height:98%;float:right">
              <button id="LinkContacts" class="btn saveButton" (click)="LinkContacts()" style="width: 100% !important; background: #00B0F0; color: white; border: 1px solid rgb(40,62,74) !important; height: 96%;">Link Contacts</button>
            </div>
          </div>
        </div>
      </div>
  </div>

</div>



<app-dialog [(visible)]="ShowExpensesDialog" *ngIf="ShowExpensesDialog" id="ExpensesDialog" [closable]="false">
  <div class="col-xs-12" style="padding:0;height:100%;">
    <div class="col-xs-12" style="padding: 0; height: 2%;background-color:orangered">
    </div>
    <div class="col-xs-12" style="padding: 0; height: 5%;">
      <div class="col-xs-11" style="padding:0;height:100%;font-size:14px;padding:0.5%">

        <label>CAMPAIGN EXPENSES</label>
      </div>
      <div class="col-xs-1" style="padding:0.5%;height:100%"><img src="../../../assets/img/Black x.png" style="height:22px;float:right;" (click)="ShowExpensesDialog = false;CostEntered = false;" /></div>
    </div>

    <div class="col-xs-12" style="padding:0%;height:95%">
      <div class="col-xs-7" style="padding:0;height:100%">
        <div class="col-xs-12" style="padding:0;height:7%">
          <div class="col-xs-1" style="padding:0;height:100%;"></div>
          <div class="col-xs-7" style="padding:2% 0 0 0;height:100%;text-align:left"><label>Type Of Expense</label></div>
          <div class="col-xs-4" style="padding:2% 0 0 2%;height:100%;text-align:left"><label>Cost</label></div>
        </div>
        <div class="col-xs-12" style="padding:0;height:91%">
          <div class="col-xs-12" style="padding:0;height:4.5%;margin-top:0.5%" *ngFor="let ind of ExpenseCountindex">
            <div class="col-xs-1" style="padding:0% 1% 1% 2%;height:100%;"><img src="../../../assets/img/Green Trash.png" style="height:20px;" (click)="DeleteExpenseConfirmation= true;selectedExpenseIndex = ind;DeleteExpenseCss('DeleteExpenseConfirmation');" /></div>
            <div class="col-xs-7" style="padding:0;height:100%;text-align:left">
              <select style="padding:0;height:100%;width:100%;border-radius:3px;font-size:12px;" id="ExpenseType{{ind}}">
                <option value="0" disabled [selected]="true">Select Type of Expense...</option>
                <option *ngFor="let expense of campaignExpenselist" value={{expense.CampaignExpenceId}}>
                  {{expense.CampaignExpenseName}}
                </option>
              </select>
            </div>
            <div class="col-xs-4" style="padding:0 1% 0 1%;height:100%;text-align:left">
              <input type="number" id="ExpenseCost{{ind}}" min="1" step="01" value="" placeholder="Enter Cost" style="height:100%;width:100%;text-align:center;font-size:12px;" name="ExpenseCost" (change)="CalculateExpense(ind);CostEntered = true;leadingZeros(ind)" (blur)="leadingZeros(ind)" />


            </div>
          </div>
        </div>



      </div>
      <div class="col-xs-5" style="padding:0;height:100%">
        <div class="col-xs-12" style="padding:2% 0 0 0;height:7%;text-align:center;font-size:18px;"><label>Total Cost &nbsp; {{TotalExpense | currency}}</label></div>
        <div class="col-xs-12" style="padding:0 10% 0 10%;height:93%">
          <div class="col-xs-12" style="padding:0;height:80%">
            <textarea id="notesTextArea" rows="6" class="form-control" type="text" name="notedescription" [(ngModel)]="campaign.ExpenseNotes" style="height: 100%; border: none; box-shadow: none;line-height: 1.028571;font-size:12px;background:#ffffb4;" (keyup)="CostEntered = true"></textarea>
          </div>
          <div class="col-xs-12" style="padding:0;height:20%">
            <div class="col-xs-12" style="height:50%"></div>
            <div class="col-xs-12" style="height:50%;padding:0;" *ngIf="CostEntered">
              <div class="col-xs-4"></div>
              <div class="col-xs-4" style="padding:0;"><button class="btn displayButton" style="width:99.5%" (click)="ShowExpensesDialog = false;CostEntered = false;">Cancel</button></div>
              <div class="col-xs-4" style="padding:0;"> <button class="btn saveButton" style="width: 100% !important" (click)="saveExpenses()">Save</button></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</app-dialog>


<app-dialog [(visible)]="ShowROIDialog" *ngIf="ShowROIDialog" id="ROIDialog" [closable]="false">
  <div class="col-xs-12" style="padding:0;height:100%;">
    <div class="col-xs-12" style="padding: 0; height: 2%;background-color:orangered">

    </div>
      <div class="col-xs-12" style="padding: 0; height: 5%;">
        <div class="col-xs-11" style="padding:0;height:100%;font-size:17px;padding:0.5%">

          <label>CAMPAIGN ROI</label>
        </div>
        <div class="col-xs-1" style="padding:0.5%;height:100%"><img src="../../../assets/img/Black x.png" style="height:22px;float:right;" (click)="ShowROIDialog = false;" /></div>
      </div>
      <div class="col-xs-12" style="height:95%;padding:2%">
        <div class="col-xs-12" style="padding:2%;height:100%;border:1px solid grey">
          <div class="col-xs-12" style="height:6%;padding:0;margin-top:1%">
            <div class="col-xs-8" style="padding:0;height:100%;font-size:18px"><label>SALES REVENUE</label></div>
            <div class="col-xs-4" style="padding:0;height:100%;text-align: left;">
              <div class="col-xs-8" style="padding:2%;height:100%;width:80%;border:1px solid grey;border-radius:3px;">{{ROI.SalesRevenue | currency}}</div>
            </div>
          </div>
          <div class="col-xs-12" style="height:6%;padding:0;margin-top:1%">
            <div class="col-xs-8" style="padding:0;height:100%;font-size:18px"><label>MONTHLY REVENUE</label></div>
            <div class="col-xs-4" style="padding:0;height:100%;text-align: left;">
              <div class="col-xs-8" style="padding:2%;height:100%;width:80%;border:1px solid grey;border-radius:3px;">{{ROI.MonthlyRevenue | currency}}</div>
            </div>
          </div>
          <div class="col-xs-12" style="height:6%;padding:0;margin-top:1%">
            <div class="col-xs-8" style="padding:0;height:100%;font-size:18px"><label>MONTHLY REVENUE X 12 MONTHS</label></div>
            <div class="col-xs-4" style="padding:0;height:100%;text-align: left;">
              <div class="col-xs-8" style="padding:2%;height:100%;width:80%;border:1px solid grey;border-radius:3px;">{{ROI.MonthlyPerYear | currency}}</div>
            </div>
          </div>
          <div class="col-xs-12" style="height:6%;padding:0;margin-top:1%">
            <div class="col-xs-8" style="padding:0;height:100%;font-size:18px"><label>SALES + MONTHLY REVENUE X 12 MONTHS</label></div>
            <div class="col-xs-4" style="padding:0;height:100%;text-align: left;">
              <div class="col-xs-8" style="padding:2%;height:100%;width:80%;border:1px solid grey;border-radius:3px;">{{ROI.salesPlusMonthlyPerYear | currency}}</div>
            </div>
          </div>
          <div class="col-xs-12" style="height:6%;padding:0;margin-top:1%">
            <div class="col-xs-8" style="padding:0;height:100%;font-size:18px"><label>EXPENSES</label></div>
            <div class="col-xs-4" style="padding:0;height:100%;text-align: left;">
              <div class="col-xs-8" style="padding:2%;height:100%;width:80%;border:1px solid grey;border-radius:3px;">{{ROI.Expenses | currency}}</div>
            </div>
          </div>

          <div class="col-xs-12" style="height:6%;padding:0;margin-top:1%">
            <div class="col-xs-8" style="padding:0;height:100%;font-size:18px"><label>PROFIT | LOSS</label></div>
            <div class="col-xs-4" style="padding:0;height:100%;text-align: left;">
              <div class="col-xs-8" style="padding:2%;height:100%;width:80%;border:1px solid grey;border-radius:3px;" [style.color]="(ROI.ProfitLoss < 0)? 'red': 'black'">{{ROI.ProfitLoss | currency}}</div>
            </div>
          </div>

        </div>
      </div>

    </div>
</app-dialog>


<app-dialog [(visible)]="LinkOpportunitiesDialog" *ngIf="LinkOpportunitiesDialog" id="LinkOpportunitiesDialog" [closable]="false">
  <div class="col-xs-12" style="padding:0;height:100%;">
    <div class="col-xs-12" style="padding: 0; height: 2%;background-color:orangered">

    </div>
    <div class="col-xs-12" style="padding: 0; height: 5%;">
      <div class="col-xs-11" style="padding:0;height:100%;font-size:17px;padding:0.5%">

        <label>Link Opportunities to Campaign</label>
      </div>
      <div class="col-xs-1" style="padding:0.5%;height:100%"><img src="../../../assets/img/Black x.png" style="height:22px;float:right;" (click)="LinkOpportunitiesDialog = false;" /></div>
    </div>
    <div class="col-xs-12" style="height:95%;padding:0%">
      <div class="col-xs-12" style="padding:0;height:100%">
        <opportunityhistory [id]="conid" [PageFrom]="'Campaign'" [type]="'Contact'" (sendMsgEvent)="ReceiveOpportunity($event)"></opportunityhistory>
      </div>
    </div>

  </div>
</app-dialog>


<app-dialog [(visible)]="DeleteExpenseConfirmation" *ngIf="DeleteExpenseConfirmation" id="DeleteExpenseConfirmation" [closable]="false">

  <app-delete-popup [status]="0" [MessageType]="'Expense'" (sendMsgEvent)="ReceiveExpenseDeleteevent($event)">
  </app-delete-popup>

</app-dialog>


<app-dialog [(visible)]="OpportunitiesDialog" *ngIf="OpportunitiesDialog" id="OpportunitiesDialog" [closable]="false">
  <div class="col-xs-12" style="padding:0;height:100%;">
    <div class="col-xs-12" style="padding: 0; height: 2%;background-color:orangered">

    </div>
    <div class="col-xs-12" style="padding: 0; height: 5%;"
         *ngIf="OpportunityViewType =='All Opportunities'">
      <div class="col-xs-11" style="padding:0;height:100%;font-size:17px;padding:0.5%">
        <label style="font-weight: normal">Opportunities</label>
      </div>
      <div class="col-xs-1" style="padding:0.5%;height:100%"><img src="../../../assets/img/Black x.png" style="height:22px;float:right;" (click)="OpportunitiesDialog = false;" /></div>
    </div>
    <div class="col-xs-12" style="padding:0;height:5%" *ngIf="OpportunityViewType =='Sales Won' || OpportunityViewType =='Sales Lost'">
 <div class="col-xs-12" style="padding:0;height:100%;">
          <div class="col-xs-11" style="padding:0;height:100%;font-size:14px;padding:0.5%">
            <label *ngIf="OpportunityViewType =='Sales Won'" style="font-weight: normal">SALES WON</label>
            <label *ngIf="OpportunityViewType =='Sales Lost'" style="font-weight: normal">SALES LOST</label>
          </div>
          <div class="col-xs-1" style="padding:0.5%;height:100%">
            <img src="../../../assets/img/Black x.png" style="height:20px;float:right;" (click)="OpportunitiesDialog = false;" />
          </div>
        </div>
    </div>


    <div class="col-xs-12" style="padding:0%" [style.height.%]="(OpportunityViewType =='Sales Won' || OpportunityViewType =='Sales Lost')? '90' : '95'">
      <div class="col-xs-12" style="padding:0;height:100%">
        <opportunityhistory [OppIds]="OpportunityIds" [PageFrom]="'Campaign'" [type]="OpportunityViewType" (sendMsgEvent)="DeleteOpportunity($event)"></opportunityhistory>
      </div>
    </div>

  </div>
</app-dialog>


<app-dialog [(visible)]="ProposalsDialog" *ngIf="ProposalsDialog" id="ProposalsDialog" [closable]="false">
  <div class="col-xs-12" style="padding:0;height:100%;">
    <div class="col-xs-12" style="padding:0;height:10%">
      <div class="col-xs-12" style="padding: 0; height: 50%;">
        <div class="col-xs-12" style="padding:0;height:100%;font-size:17px;">
          <div class="col-xs-11" style="padding:0;height:100%;padding:0.5%"><label>CAMPAIGN DETAILS</label></div>
          <div class="col-xs-1" style="padding:0.5%;height:100%"><img src="../../../assets/img/Black x.png" style="height:22px;float:right;" (click)="ProposalsDialog = false;pdfview = false" /></div>
        </div>

      </div>
      <div class="col-xs-12" style="padding: 0.5%; height: 50%; background: white; color: black; text-align: center; font-size: 16px;">
        <label>Proposals and Purchase Order</label>
        <img src="../../../assets/img/Black x.png" style="height:22px;float:right;" *ngIf="pdfview" (click)="pdfview = false" />
      </div>
    </div>
    <div class="col-xs-12" style="height:90%;padding:0;" *ngIf="!pdfview">
     <ag-grid-angular style="width: 100%;height:100%" id="gridheight" class="ag-theme-balham"
                       [gridOptions]="ProposalGridOptions"
                       [columnDefs]="ProposalColumnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="AllProposals"
                       [sideBar]="sideBar"
                       (selectionChanged)="onProposalSelectionChanged($event)">
      </ag-grid-angular>
    </div>

    <div class="col-xs-12" style="padding:0;height:90%" *ngIf="pdfview">
      <div id="pdfDiv" class="col-xs-12">

      </div>
    </div>
  </div>


</app-dialog>



<app-dialog [(visible)]="DeleteContactConfirmation" *ngIf="DeleteContactConfirmation" id="DeleteContactConfirmation" [closable]="false">
  <app-delete-popup [status]="0" [title]="'Contact'" [description]="'Delete this Contact Selected?'" [isok]="true"
   [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteevent($event)">
  </app-delete-popup>
</app-dialog>

<app-dialog [(visible)]="DeleteCampaignConfirmation" *ngIf="DeleteCampaignConfirmation" id="DeleteCampaignConfirmation" >
  <app-delete-popup [status]="0" [title]="'Campaign'" [description]="'Delete this Campaign Selected?'" [isok]="true"
   [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteCampaignevent($event)">
  </app-delete-popup>
</app-dialog>


<app-dialog [(visible)]="CampaignsInstructions" *ngIf="CampaignsInstructions" id="CampaignsInstructions">
  <div class="col-xs-12" style="height: 2%; font-size: 15px; background-color: rgb(186,220,153)"></div>
    <div class="col-xs-12" style="padding:0%; height: 8%; font-size: 15px;">
      <label style=" margin-left: 2.5%">HOW TO USE THE CAMPAIGN MANAGER</label>
    </div>
    <div class="col-xs-12" style="height:90%">
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Step 1.</b> Select the Expense Icon. </div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Step 2.</b> Enter the expenses for the Campaign and then select SAVE. </div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Step 3.</b> Select the Contact Link Icon. </div>
      <div class="col-xs-12" style="padding-left:0;height:9%;"><b>Step 4.</b> The system will display the Contacts in the system. Select the Contacts to link to the Campaign and then select SAVE.</div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Step 5.</b> Select a Contact that has been linked to the Campaign from the Grid. </div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Step 6.</b> Select the Opportunity Link Icon located under the Actions Column in the Grid. </div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Step 7.</b> The system will automatically display the Opportunities for the Contact selected. </div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Step 8.</b> Select the Opportunity to link to the Campaign and then select SAVE. </div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Step 9.</b> The system will automatically link the Proposal that generated the Opportunity. </div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Step 10.</b> The proposals can be viewed under the Proposal Icon. </div>

      <div class="col-xs-12" style="padding: 0%; height: 8%;font-size: 15px;">
        <label> CAMPAIGN ICONS</label>
      </div>

      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Expense Icon:</b> This icon will display the list of expenses for the Campaign. </div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>ROI Icon:</b> This  Icon will display the Profit / Loss of the Campaign</div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Opportunities Icon:</b> This icon will display a list of opportunities linked to the Campaign</div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Proposals Icon:</b> This icon will display a list of proposals linked to the Campaign</div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Sales Won Icon:</b> This Icon will display all Opportunities Won for this campaign</div>
      <div class="col-xs-12" style="padding-left:0;height:5%;"><b>Sales Lost Icon:</b> This Icon will display all Opportunities Lost for this campaign</div>


    </div>

</app-dialog>
