<div class="col-xs-12" style="padding:5%">
  <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
  <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
    <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">{{title}}</div><br />
    <div class="col-xs-12" style="padding: 0; padding-top: 3%;">{{description}}</div>
  </div>
  <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
    <div class="col-xs-7" style="padding: 0;">

    </div>
    <div class="col-xs-5" style="padding:0;">
      <div class="col-xs-6" style="padding:0" *ngIf="isok">
        <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                (click)="CRMOK()">
          OK
        </button>
      </div>
      <div class="col-xs-6" style="padding:0" *ngIf="issave">
        <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                (click)="CRMSAVE()">
          SAVE
        </button>
      </div>
      <div class="col-xs-6" style="padding:0" *ngIf="isdiscard">
        <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1 " (click)="CRMDISCARD()">Discard</button>
      </div>
      <div class="col-xs-6" style="padding:0" *ngIf="iscancel">
        <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1 " (click)="CRMCANCEL()">Cancel</button>
      </div>
    </div>
  </div>
</div>
