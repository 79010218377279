<div class="container" style="height:100%;">
 
  <div class="col-xs-1" style="padding:0px"> 
      <div class="col-xs-12" style="padding: 0; height: 100%; border-right: 5px solid rgb(186,219,152) ">
        <div class="col-xs-12" style="padding: 0; height: 5%; color: black; line-height: 28px;" [style.background]="selectedtab == 'inbox' ? 'rgb(186,219,152)': 'white'">
          <input style="display:none" type="radio" name="tabset" id="tab1" value="tab1" aria-controls="bano" [(ngModel)]="selectedMenuRadio" checked />
          <label for="tab1" style="text-align:center;font-weight:normal" (click)="messageButtonCSS('inbox',0,'')">Inbox<span style="">({{ applicationComponent.totalInboxmessages}})</span></label>
        </div>

        <div class="col-xs-12" style="padding: 0; height: 5%; color: black; line-height: 28px;" (click)="messageButtonCSS('draft',2,'')" [style.background]="selectedtab == 'draft' ? 'rgb(186,219,152)': 'white'">
          <input style="display:none" type="radio" name="tabset" id="tab2" value="tab2" aria-controls="bano" [(ngModel)]="selectedMenuRadio" checked />
          <label for="tab2" style="text-align:center;font-weight:normal" (click)="messageButtonCSS('draft',2,'')"> Draft<span style="">({{ draftmessagecount}})</span></label>
        </div>

        <div class="col-xs-12" style="padding: 0; height: 5%; color: black; line-height: 28px;" (click)="messageButtonCSS('sent',3,'')" [style.background]="selectedtab == 'sent' ? 'rgb(186,219,152)': 'white'">
          <input style="display:none" type="radio" name="tabset" id="tab3" value="tab3" [(ngModel)]="selectedMenuRadio" aria-controls="banoo" />
          <label for="tab3" style="text-align: center; font-weight: normal" (click)="messageButtonCSS('sent',3,'')">Sent<span style=""> ({{ sentmessagecount}})</span></label>
        </div>

        <div class="col-xs-12" style="padding: 0; height: 5%; color: black; line-height: 28px;" (click)="messageButtonCSS('deleted',4,'')" [style.background]="selectedtab == 'deleted' ? 'rgb(186,219,152)': 'white'">
          <input style="display:none" type="radio" name="tabset" id="tab4" value="ta14" [(ngModel)]="selectedMenuRadio" aria-controls="banoo" />
          <label for="tab4" style="text-align: center; font-weight: normal" (click)="messageButtonCSS('deleted',4,'')">Deleted <span style=""> ({{ deletedmessagecount}})</span></label>
        </div>

       

      </div> 
  </div>
  <div class="col-xs-11" style="height:100%">

    <div class="col-xs-12" style="padding:0% 0 0 0;width:100%;height:5%" *ngIf="(this.messagetype!=='compose' && this.replytype =='')">
      <div class="col-xs-1" style="padding:0;">
        <img src="../../../assets/img/Create New Document Name.png" style="height:23px" *ngIf="(this.messagetype!=='compose')" (click)="messageButtonCSS('compose',1,'')" />
      </div>

    </div>
    <div id="gridDiv" [class.col-xs-6]="this.messagetype!='compose' && this.replytype==''" [class.padding-right-zero]="this.messagetype=='compose' || this.replytype!=''" [class.col-xs-11]="this.messagetype=='compose' || this.replytype!=''" style="padding:0;height:100%" [style.width.%]="this.messagetype!='compose' && this.replytype=='' ? '50': 100">

      <div class="col-xs-12" style="padding:0;height:7%" *ngIf="messagetype == 'compose' || replytype === 'Reply' || replytype === 'replytoall' || replytype === 'forward'">
        <div class="col-xs-10" style="padding:0px">
          <label style="margin-top:0.5%;" *ngIf="this.messagetype == 'compose'">Create New Message</label>
          <label style="margin-top:0.5%;" *ngIf="replytype === 'Reply'">Replying To a Message</label>
          <label style="margin-top:0.5%;" *ngIf="replytype === 'replytoall'">Replying To a Message</label>
          <label style="margin-top:0.5%;" *ngIf="replytype === 'forward'">Farwarding a Message</label>
        </div>
        <div class="col-xs-1" style="padding:0px;">
          <button class="btn btn-default" (click)="CancelReply()" style="width:100%;">Cancel</button>
        </div>
        <div class="col-xs-1" style="padding:0px;">
          <button class="btn saveButton" (click)="ReplyMessage()" style="width:100%;">Send</button>
        </div>

      </div>


      <div *ngIf="(this.messagetype=='compose')" class="col-xs-12" style="border:1px solid #ddd;padding:0;height:93%">
        <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
          <div class="col-xs-1" style="padding:0">
            <label>To </label>
          </div>
          <div class="col-xs-11" style="padding-right:0;">
            <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems" id="MessageCompose"
                                  [settings]="dropdownSetting"
                                  (onSelect)="onItemSelect($event)"
                                  (onDeSelect)="OnItemDeSelect($event)"
                                  (onSelectAll)="onSelectAll($event)"
                                  (onDeSelectAll)="onDeSelectAll($event)">
            </angular2-multiselect>
          </div>
        </div>
        <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
          <div class="col-xs-1" style="padding:0;">
            <label>Subject</label>
          </div>
          <div class="col-xs-11" style="padding-right:0">
            <input type="text" class="form-control" id="SubjectBox" [(ngModel)]="message.subject" placeholder="Enter Subject" required (keyup)="SubjectKeyUp()" />
          </div>
        </div>

        <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
          <textarea id="froala-editor" class="form-control" [froalaEditor]="editorOptions" [(froalaModel)]="messagedescription">
          </textarea>
        </div>
        <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
          <div class="col-xs-6" style="padding: 0">
            <button class="btn btn-default" (click)="CancelMessage();DescriptionEntered == false" style="width:30%;margin-left:0.5%;height: 30px; padding-top: 3px;">Cancel</button>

          </div>
          <div class="col-xs-6" style="padding:0; text-align: right">
            <button *ngIf="this.selectedItems.length>0 && message.subject!=undefined && message.subject!='' && DescriptionEntered" class="btn saveButton" (click)="savetodraft()" style="width:25%;height: 30px; padding-top: 3px;">Save to Draft</button>
            <button *ngIf="this.selectedItems.length==0 || message.subject==undefined || messagedescription==undefined || (message.subject!=undefined && message.subject=='') || (DescriptionEntered == false)" disabled="disabled" class="btn saveButton" (click)="savetodraft()" style="width:25%;height: 30px; padding-top: 3px;">Save to Draft</button>
            <button *ngIf="this.selectedItems.length>0 && message.subject!=undefined && message.subject!='' && DescriptionEntered" class="btn saveButton" (click)="SaveMessage('Compose')" style="width:25%;height: 30px; margin-left:1px; padding-top: 3px;">Send</button>
            <button *ngIf="this.selectedItems.length==0 || message.subject==undefined || messagedescription==undefined || (message.subject!=undefined && message.subject=='') || (DescriptionEntered == false)" disabled="disabled" class="btn saveButton" (click)="SaveMessage('Save')" style="width:25%;height: 30px; margin-left:1px; padding-top: 3px;">Send</button>
          </div>
        </div>

      </div>

      <div *ngIf="this.replytype!=''" class="col-xs-12" style="border:1px solid #ddd;padding:0">

        <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                              [settings]="dropdownSetting">
        </angular2-multiselect>

        <div class="col-xs-12" *ngIf="(this.replytype!='forward')" style="margin-top: 2%;">
          <div id="replyMessage" [froalaEditor]="editorOptions" [(froalaModel)]="composedReplyMsg" style="overflow:auto"></div>
        </div>

      </div>

      <div *ngIf="(this.messagetype!='compose' && (this.replytype==''))" class="col-xs-12" style="border:1px solid #ddd;padding:1px 0px 0px 0px;height:93%">
        <ag-grid-angular style="width: 100%; height:95%" id="gridheight" class="ag-theme-balham"
                         [gridOptions]="gridOptions"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="applicationComponent.defaultGridColDef"
                         [rowData]="allMessages"
                         [context]="context"
                         [suppressAggFuncInHeader]="true"
                         [enableRangeSelection]="true"
                         [groupHideOpenParents]="true"
                         [animateRows]="true"
                         [sideBar]="sideBar"
                         [groupSelectsChildren]="false"
                         [autoGroupColumnDef]="autoGroupColumnDef"
                         (rowSelected)="rowSelected($event)"
                         (rowClicked)="rowClicked($event)"
                         (columnVisible)="columnVisible($event)"
                         (dragStopped)="dragStopped($event)"
                         (columnResized)="columnResized($event)"
                         (rowGroupOpened)="rowGroupOpened($event)"
                         (gridReady)="onAgGridReady($event)">

        </ag-grid-angular>


        <app-dialog [(visible)]="ShowPermanentmessageDelete" [closable]="false">
          <div class="col-xs-12" style="text-align:center; font-size: 18px;margin-top:2%;">

            <label> Permanently Delete All Deleted Messages?</label><br /><br /><br />
            <div class="row" style="margin-top: 2%;margin-bottom: 3%;padding-left: 13%;padding-right: 13%;">
              <div class="col-xs-6" style="text-align: right;padding: 0 5% 0 0;">
                <button (click)="canceldelete()" class="Button" style="width:50%;height: 25px;border: 1px solid #ddd !important;border-radius: 5px;background-color:white">No</button>
              </div>
              <div class="col-xs-6" style="padding-right: 0;text-align:left">
                <button class="Button" style="width:50%;height: 25px;border: 1px solid #ddd !important;border-radius: 5px;background-color:rgb(162, 228, 119)" (click)="UpdateMessage(this.Omsgid, this.Msgval)">Yes</button>
              </div>
            </div>
          </div>
        </app-dialog>

      </div>

      <div *ngIf="!isFirstNode && this.messagetype!='compose' && this.replytype!=''" class="col-xs-12" style="height: 200px; font-size: 12px; overflow: auto; border: 1px solid #ddd; padding: 0%; margin-top: 5px; font-size: 0.95em !important;" id="MsgDiv">
        <div *ngFor="let message of allrepliedMessages">
          <div class="col-xs-12" style="padding: 0px;">
            <div class="col-xs-1">
              <span style="margin:0">From :</span>
            </div>
            <div class="col-xs-9">
              <span style="margin:0">{{message.fromuser}}</span>
            </div>
          </div>

          <div class="col-xs-12" style="padding: 0px;">
            <div class="col-xs-1">
              <span style="margin:0">To :</span>
            </div>
            <div class="col-xs-9">
              <span style="margin:0">{{message.touser}}</span>
            </div>
          </div>

          <div class="col-xs-12" style="padding: 0px;">
            <div class="col-xs-1">
              <span style="margin:0">Received :</span>
            </div>
            <div class="col-xs-9">
              <span style="margin:0">{{message.date | date: 'MM-dd-yyyy hh:mm a'}}</span>
            </div>
          </div>

          <div class="col-xs-12" style="padding: 0px;">
            <div class="col-xs-1">
              <span style="margin:0">Subject :</span>
            </div>
            <div class="col-xs-9">
              <span style="margin:0"><span>  {{message.subject}}</span></span>
            </div>
          </div>

          <div class="col-xs-12" style="padding: 0px;">
            <div class="col-xs-1">
              <span style="margin:0">Message :</span>
            </div>
            <div class="col-xs-9">
              <span [innerHTML]="message.message"></span>

            </div>
          </div>
          <div class="col-xs-12" style="padding: 0px;">
            <hr style="border: 1px dashed black;">
          </div>

        </div>
      </div>
    </div>

    <div *ngIf="!this.isFirstNode && this.messagetype !='compose' && this.replytype ==''" class="col-xs-6" [style.height]="aggridheight" id="replyBtnDiv" style="padding:0% 0 0 10px" [style.width.%]="this.messagetype!='compose'? '50': 89">

      <div *ngIf="(this.messagetype!='deleted')" class="col-xs-12" style="padding: 0px;border:1px solid gray;">
        <div class="col-xs-2" style="padding: 0; height: 100%; width: 20%;">

        </div>
        <div class="col-xs-1" style="padding: 0; height: 100%;">
          <img src="../../../assets/img/Reply.png" style="height:23px" *ngIf="(this.messagetype!=='compose')" (click)="ActiveTask('Reply')" />
        </div>
        <div class="col-xs-1" style="padding: 0; height: 100%;">
          <img src="../../../assets/img/Reply All.png" style="height:23px" *ngIf="(this.messagetype!=='compose')" (click)="ActiveTask('replytoall')" />
        </div>
        <div class="col-xs-5" style="padding: 0; height: 100%;">

        </div>
        <div class="col-xs-1" style="padding: 0; height: 100%;">
          <img src="../../../assets/img/Forward.png" style="height:23px" *ngIf="(this.messagetype!=='compose')" (click)="ActiveTask('forward')" />
        </div>
        <div class="col-xs-2" style="padding: 0; height: 100%;">

        </div>
      </div>
      <div *ngIf="(this.messagetype!='deleted')" class="col-xs-12" style="padding:3px 0px;">
        <div class="col-xs-4" style="padding: 0; height: 100%;">

        </div>
        <div class="col-xs-4" style="padding: 0; height: 100%; text-align: center; font-size: 12px;">
          <span><b>MESSAGE HISTORY</b></span>
        </div>
        <div class="col-xs-4" style="padding: 0; height: 100%;">

        </div>

      </div>
      <div *ngIf="this.message.id!=0" class="col-xs-12" style="margin: 0; padding: 0px; font-size: 0.75em !important;">
        <div class="col-xs-12" style="margin:0;padding: 0px;" *ngFor="let message of allrepliedMessages;let i = index">
          <div class="col-xs-12">
            <div class="col-xs-2" padding-zero>
              <span style="margin:0">From </span>
            </div>
            <div class="col-xs-9">
              <span style="margin:0">{{message.fromuser}}</span>
            </div>
          </div>

          <div class="col-xs-12">
            <div class="col-xs-2" padding-zero>
              <span style="margin:0">To </span>
            </div>
            <div class="col-xs-9">
              <span style="margin:0">{{message.touser}}</span>
            </div>
          </div>

          <div class="col-xs-12">
            <div class="col-xs-2" padding-zero>
              <span style="margin:0">Received </span>
            </div>
            <div class="col-xs-9">
              <span style="margin:0">{{message.date}}</span>
            </div>
          </div>

          <div class="col-xs-12">
            <div class="col-xs-2" padding-zero>
              <span style="margin:0">Subject </span>
            </div>
            <div class="col-xs-9">
              <span style="margin:0"><span>  {{message.subject}}</span></span>
            </div>
          </div>

          <div class="col-xs-12">
            <div class="col-xs-2" padding-zero>
              <span style="margin:0">Message </span>
            </div>
            <div class="col-xs-9">
              <span [innerHTML]="message.message"></span>
            </div>
          </div>
          <div class="col-xs-12">
            <hr style="border: 1px dashed black;">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-dialog [(visible)]="CancelMessagePopup" *ngIf="CancelMessagePopup" id="CancelMessagePopup" [closable]="false">
  <div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
    <span style="font-size: 15px; font-weight: 600">User Messaging</span><br />
    <div style="padding: 0; margin-top: 3%; font-weight: 600" class="col-xs-8">
      <span>Message will not be sent?</span>
    </div>
    <div class="col-xs-4">
      <button class="btn" (click)="Ok('Ok')" style="background-color: #0098f7 !important;color: white;">OK</button>
      <button class="btn" (click)="Ok('Cancel')" style="float:right;background-color: white !important;color: #0098f7;border: 1px solid lightgrey !important;">Cancel</button>
    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="DeleteConfirmationPopup" *ngIf="DeleteConfirmationPopup" id="DeleteConfirmationPopup" [closable]="false">
  <app-delete-popup [status]="contactStatus" [MessageType]="'Message'" (sendMsgEvent)="ReceiveDeleteevent($event)">
  </app-delete-popup>
</app-dialog>
