import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { TaskHistory } from '../../models/task-history';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { MessagingService } from '../../services/messaging.service';
import { IMyDpOptions } from 'mydatepicker';
import { DatePipe } from '@angular/common';
import { Salesranking } from '../../models/salesranking';
import { Messaging } from '../../models/messaging';
import { ViewSalesinfo } from '../../models/view-salesinfo';
import { Accounts } from '../../models/accounts';
import { Router, ActivatedRoute } from '@angular/router';
import { Dropdowntype, Dropdown, RevenueModel } from '../../models/dropdowntype';
import { CustomerService } from './../../services/customer.service';
import { GridOptions, ColumnUtils } from "ag-grid/main";
//import { CalendarComponent } from "ap-angular-fullcalendar";
import { ApplicationComponent } from '../application.component';
import { Userquota } from '../../models/userquota';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { Column } from 'ag-grid';
import { ScheduletaskComponent } from "../scheduletask/scheduletask.component";
import { ProposalsComponent } from '../proposals/proposals.component';
import { LeadsdashboardComponent } from '../leads/leadsdashboard/leadsdashboard.component';
import { UnreadMessagesComponent } from '../unread-messages/unread-messages.component';
import timeGridPlugin from '@fullcalendar/timegrid';
declare var require: any;
import * as moment from 'moment'
import { UserService } from 'src/app/services/user.service';
import { AdvancedsearchService } from 'src/app/services/advancedsearch.service';
declare var $: any;
import { HttpClient } from '@angular/common/http';
import { CalendarOptions, EventApi } from '@fullcalendar/angular';
//import { SignalRAdapter } from 'src/adapters/signalr-adapter';
//import { DemoAdapter } from 'src/adapters/demo-adapter';
import { SignalRGroupAdapter } from 'src/adapters/signalr-group-adapter';
import { ChatAdapter } from 'ng-chat';
import { TaskComponent } from '../task/task.component';
import { ZendeskhomeComponent } from '../zendeskhome/zendeskhome.component';
import { AccountTransferModel } from '../../models/AccountTransferModel';
import { ToastrService } from 'ngx-toastr';
import { Notes } from '../../models/notes';
import { RevenueDashboardComponent } from './revenue-dashboard/revenue-dashboard.component';
import { SalesProcessHistory } from '../../models/sales-process-history';
import { Campaigninfo } from '../../models/Campaigninfo';
import { Campaign } from '../../models/Campaign';
import { ProjectsDashboardComponent } from './projects-dashboard/projects-dashboard.component';
import { Projects } from '../../models/ProjectConfiguration';
import { ProjectsInfo } from '../../models/ProjectsInfo';
import { CampaignDashboardComponent } from './campaign-dashboard/campaign-dashboard.component';
import { TicketsDashboardComponent } from './tickets-dashboard/tickets-dashboard.component';

import { UserMessages } from '../../models/UserMessages';
import { NotificationService } from '../notification/notification.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  calendarOptions: CalendarOptions = {
  };
  calendarOptions1: CalendarOptions = {

  };
  calendarOptions2: CalendarOptions = {

  };
  //calendarOptions3: CalendarOptions = {

  //};

  allCampaigns: Campaign[];
  OriginalAllCampaigns: Campaign[];
  public sideBar;
  pageSize: string = "1000";
  aggridHeight: string = "";
  currentuser: any;
  messageOpen = false;
  hdrHtml0: boolean = false;
  CalendarTomorrowActDiv: boolean = true;
  MsgCalendarDiv: boolean = true;
  calendarpastDiv: boolean = true;
  internalmessagediv: boolean = true;
  msgSalesDiv: boolean = true;//for tab2 & 3
  fullcalendar: boolean = true;//for tab2 & 3
  saleRankingDiv: boolean = true;//for tab2 & 3
  intmsgdiv: boolean = true;//for tab2 & 3

  calanderonoff: boolean = true;
  Dashboardtypelist: Dropdowntype[] = [];
  message: Messaging = new Messaging();
  alltaskhistory: TaskHistory[];
  tomorrowtaskhistory: TaskHistory[];
  datePipe: DatePipe = new DatePipe("en-US");
  Allsalesranking: Salesranking[];

  AllActivityData: any;
  closedRankingData: any;
  AllAccountData: any;
  DashBoardConfigData: any;
  AllyeartoSalesData: any;
  AllyeartoSalesDataTotal: any;
  YearToDateTotal: any;
  AllyeartodataActivity: Userquota[] = [];
  allMessages: Messaging[];
  allCustomers: Accounts[];
  selectedActType = 732;
  OpenOppTotal: string = "$ 0";
  colSelections: string;
  public SalesRankinggridOptions: GridOptions;
  public AccountgridOptions: GridOptions;
  public TomorrowActivitygridOptions: GridOptions;
  public useractivitygridOptions: GridOptions;
  public columnDefs: any[];
  public taskcolumnDefs: any[];
  public useractivitycolumnDefs: any[];
  public closedRankingcolumnDefs: any[];
  public AccontcolumnDefs: any[];
  public TomorrowActivitycolumnDefs: any[];
  salesinfo: ViewSalesinfo = new ViewSalesinfo();
  campaigninfo: Campaigninfo = new Campaigninfo();
  finaldata = [];
  finaldataincludediscription = [];
  newEvents = [];
  newEventsincludediscription = [];
  MonthlycallsPercentageColor = "#e7e8ea";
  MonthlyMeetingCompletedPercentageColor = "#e7e8ea";
  MonthlycallsScheduledPercentageColor = "#e7e8ea";
  MonthlySalesWonPercentageColor = "#e7e8ea";
  MonthlySalesRRPercentageColor = "#e7e8ea";
  MonthlyAccountsaddedPercentageColor = "#e7e8ea";
  leadconvertedPercentageColor = "#e7e8ea";
  MonthlyToDoPercentageColor = "#e7e8ea";
  NewTicketsPercentageColor = "#e7e8ea";
  ClosedTicketsPercentageColor = "#e7e8ea";
  MonthlylastDueActivityPercentageColor = "#e7e8ea";
  UserIds: string;
  UserIdsCount: number = 1;
  selectedTab: string;
  //calendarOptions: any;
  timezonevalue: string;
  check: boolean = true;
  dropdownList = [];
  selectedItems = [];
  finalitems: any;
  CurrentDate: string;
  CallsCompletedLabel: string;
  NewTicketsLabel: string;
  ClosedTicketsLabel: string;
  MonthlySalesRRLabel: string;
  MonthlyAccountsLabel: string;
  MonthlyToDoLabel: string;
  MonthlyMeetingLabel: string;
  MeetingsCompletedLabel: string;
  ToDoCompletedLabel: string;
  TotalOppWonPriceLabel: string;
  TotalOppWonRRLabel: string;
  TotalLostOppPriceLabel: string;
  TotalOpenOppPriceLabel: string;
  SalesWonLabel: string;
  RRWonLabel: string;
  LeadsConvertedLabel: string;
  today = Date.now();
  public accountGridColumnApi: any;
  public salesRankingGridColumnApi: any;
  public userActivityGridColumnApi: any;
  YDAHight = screen.height * 0.24111;
  divHeight = (screen.height * 0.21);
  divHeight1 = (screen.height * 0.2444);
  divHeight3 = 124;
  divHeight4 = (screen.height * 0.2422222);
  divHeight5 = (screen.height * 0.203333);
  canvasHGT = screen.height * 0.241111;
  hdHGT = screen.height * 0.02666667;
  public yy: number;
  pastDueactivitytaskhistory: TaskHistory[];
  public PastDueActivitygridOptions: GridOptions;
  public pastDueActivitycolumnDefs: any[];
  currentYear = new Date().getFullYear();
  currentMonth = (new Date().getMonth()) + 1;
  public years: number[] = [];
  selectedMenuRadio: string = "notab";
  tasklistshowDialog = false;
  showExpandGrid = false;
  EXpandCalender: boolean = false;
  displayCalendar: boolean = false;
  emptyGridData = [];
  assignedToval: number;
  contactId: number = 0;
  showcustomerOnhold: boolean = true;
  showtickets: boolean = true;
  showclosedtickets: boolean = true;
  showclosedticketsrating: boolean = true;
  showsalesranking: boolean = true;
  ShowcustomerActivity: boolean = true;
  showPastdue: boolean = true;
  showcompletedprojects: boolean = true;
  showcancelledprojects: boolean = true;
  showopenleads: boolean = true;
  showactivitytopusers: boolean = true;
  expand: boolean = false;

  expandmydashboardsecrow: boolean = false;
  expandmydashboardthirdrow: boolean = false;

  expandsupportdashboardsecrow: boolean = false;
  expandsupportdashboardthirdrow: boolean = false;

  expandprojectdashboardsecrow: boolean = false;
  expandprojectdashboardthirdrow: boolean = false;

  expandnbddashboardsecrow: boolean = false;
  CurrentUserMessages: UserMessages[];
  expandcustomeronhold: boolean = false;
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal;
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");
  taskDialog: boolean = false;
  showtaskDialog: boolean = false;
  taskhistory: TaskHistory = new TaskHistory()
  notes: Notes = new Notes();
  @ViewChild(ScheduletaskComponent) scheduleComp: ScheduletaskComponent;
  @ViewChild(UnreadMessagesComponent) unReadMsgcomponent: UnreadMessagesComponent;
  @ViewChild(TaskComponent) taskcomp: TaskComponent;
  @ViewChild(ProposalsComponent) Proposalcomp: ProposalsComponent;
  @ViewChild(LeadsdashboardComponent) LeadsDashBoardComp: LeadsdashboardComponent;
  @ViewChild(ZendeskhomeComponent) ZendeskComp: ZendeskhomeComponent;
  @ViewChild(TicketsDashboardComponent) TicketsDashComp: TicketsDashboardComponent;
  @ViewChild(RevenueDashboardComponent) revenueDashComp: RevenueDashboardComponent;
  @ViewChild(ProjectsDashboardComponent) ProjDashComp: ProjectsDashboardComponent;
  @ViewChild(CampaignDashboardComponent) campDashComp: CampaignDashboardComponent;
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public ECDstartDate: any;
  public ECDendDate: any;
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  public customerpieChartLabels = [];
  public customerpieChartLabels1 = [];
  public customerpieChartType: string = 'pie';
  public customerpieChartData = [{ horizontalAlign: "left" }];
  public customerpieChartcolors = [{ backgroundColor: ["#826AA4", "	#5F97C7", "#4C7FC0", "#CA5A4E", "#9DB15A"] }]
  public pieColors = ['#826AA4', '#5F97C7', '#4C7FC0', '#CA5A4E', '#9DB15A'];
  public SalesbarChartLabels = [];
  public NBDSpecialistSalesbarChartLabels = [];
  selectedmonth = new Date();
  public color = '#23B14D';
  public displayDiv: boolean = false;
  dropdownSetting = {};
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public elementRef;
  public barChartLabels: string[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;
  public barChartData: any[] = [{ label: 'Series A', backgroundColor: "#ccc" }];
  public NBDSpecialistbarChartData: any[] = [
    {/*data: [65, 59, 80, 81, 56, 55, 40],*/ label: 'Series A', backgroundColor: "#ccc" },
    {/*data: [28, 48, 40, 19, 86, 27, 90],*/ label: 'Series B', backgroundColor: "rgb(202, 90, 78)" }
  ];
  //@ViewChild(CalendarComponent) myCalendar: CalendarComponent;

  public messagegridOptions: GridOptions;
  public messagecolumnDefs: any[];
  public colorStoke: any[];
  public colors = [
    { // 1st Year.
      backgroundColor: '#4394db'
    },
    { // 2nd Year.
      backgroundColor: 'rgba(30, 169, 224, 0.8)'
    }
  ]
  public chartColors: Array<any> = [{
    fillColor: 'rgba(47, 132, 71, 0.8)',
    strokeColor: 'rgba(47, 132, 71, 0.8)',
    highlightFill: 'rgba(47, 132, 71, 0.8)',
    highlightStroke: 'rgba(47, 132, 71, 0.8)'
  }];
  public data = [300, 500, 100];
  public labels = ["Download Sales", "In-Store Sales", "Mail-Order Sales"];
  public NBDSpecialistbarChartOptions: any = {
    responsive: true,
    legend: { fontSize: 5 }
  };
  public chartClicked(e: any): void { }
  public UnreadMessageCount = this.applicationComponent.inboxmessagecount;
  public myDashboard: boolean = false;
  public salesDashBoard: boolean = false;
  public nbdDashBoard: boolean = false;
  public trainerDashboard: boolean = false;
  public supportDashboard: boolean = false;
  public ProjectsDashboard: boolean = false;
  public RevenueDashboard: boolean = false;
  public TransferedAccountsDashboard: boolean = false;
  globaleventid: number;
  MultiselectDropDownChanged: boolean = false;
  SaveUserListConfirmation: boolean = false;
  id: number;
  taskid: number;
  type: string;
  month = "";
  ActivityDropdowns: Dropdowntype[] = [];
  CustActivityDropdowns: Dropdowntype[] = [];
  CustStatusDropdowns: Dropdowntype[] = [];
  title = 'app';
  currentTheme = 'dark-theme';
  triggeredEvents = [];
  //fileUploadUrl: string = `${SignalRAdapter.serverBaseUrl}UploadFile`;
  SelectedAct: number; SelectedStatus: number;
  userId: string = "offline-demo";
  username: string;
  NotificationMessages = [];
  //adapter: ChatAdapter = new DemoAdapter();
  Revenue: RevenueModel = new RevenueModel();
  SingleRevenue: RevenueModel = new RevenueModel();
  signalRAdapter: SignalRGroupAdapter;
  TodayTimeSpent = 0;
  WeekkTimeSpent = 0;
  MonthTimeSpent = 0;
  YearTimeSpent = 0;

  Ids = [];
  ClearTransferAccounts: boolean = false;
  DropDownListBox: boolean = false;
  SelectedDate: any;
  SearchDialog: boolean = false;
  BTClick: boolean = false;
  RevenueStartDate: string = "";
  RevenueEndDate: string = "";
  TicketStartDate: string = "";
  TicketEndDate: string = "";
  public LeadsgridOptions: GridOptions;
  public LeadscolumnDefs: any[];
  allLeads: SalesProcessHistory[];
  public context;
  projInfo: ProjectsInfo = new ProjectsInfo();
  selectedmyDashDefGridBox = [];
  selectedmyDashDefGridBoxid: number = 0;
 
  constructor(private route: ActivatedRoute, public applicationComponent: ApplicationComponent, private userservice: UserService, private messagingservice: MessagingService,
    private leadservice: LeadsService, private opprtunityservice: OpprtunitiesService, private customerService: CustomerService, private toastr: ToastrService, private router: Router,protected _notificationSvc: NotificationService,
    private advsearch: AdvancedsearchService, private http: HttpClient) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedbuttonText = "DashBoard";
    this.loadCustomerActivityDrp();

    this.applicationComponent.selectedItems = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {
      this.getSelectedUserList(this.currentuser.id);
    }

  }
  loadCustomerActivityDrp() {

    forkJoin({
      res1: this.customerService.GetSpecialityList("CustomerActivity", this.currentuser.id),
      res2: this.customerService.GetSpecialityList("Account Active or Inactive", this.currentuser.id)
    })
      .subscribe(({ res1, res2 }) => {
        this.CustActivityDropdowns = res1;
  
        var myDashactivity = localStorage.getItem('myDashactivity');
        if (myDashactivity != null) {
          this.ActivityChange(Number(myDashactivity));

        } else {
          this.SelectedAct = 732;
        }
        this.CustStatusDropdowns = res2;
     
        var myDashactivityStatus = localStorage.getItem('myDashactivityStatus');
        if (myDashactivityStatus != null) {
          this.CustomerStatusChange(Number(myDashactivityStatus));
        } else {
          this.SelectedStatus = 1;
        }
        
      });
   
  }
  getSelectedtmyDashDefGridBox() {

    this.userservice.GetSelectedUsermyDashDefGridBox(this.currentuser.id).then((result) => {
      this.selectedmyDashDefGridBox = result;
      this.selectedmyDashDefGridBoxid = 3;// result.selectedMyDashDefaultGridId;
      if (this.selectedmyDashDefGridBoxid == 1) {
        this.assignedToval = 13
        this.currentMonth = (new Date().getMonth()) + 1;
      }
      else if (this.selectedmyDashDefGridBoxid == 2) { this.assignedToval = 3 }
      else if (this.selectedmyDashDefGridBoxid == 4) { this.assignedToval = 2 }
      else if (this.selectedmyDashDefGridBoxid == 5) { this.assignedToval = 8 }
      else if (this.selectedmyDashDefGridBoxid == 3) {
        var date = new Date();
        var ECDstartDate = new Date(date.getFullYear(), date.getMonth(), 1);
        var ECDendDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.ECDstartDate = moment(ECDstartDate.toString()).format("YYYY-MM-DD");
        this.ECDendDate = moment(ECDendDate.toString()).format("YYYY-MM-DD");

      }

    });
  }
  refresh() {
    var that = this;
    that.alltaskhistory = [];
    var calEventStatus = [];
    var tomorrow = new Date();
    var isEventOverDiv = function (x, y) {
      var external_events = $('#external-events');
      var offset = external_events.offset();
      offset.right = external_events.width() + offset.left;
      offset.bottom = external_events.height() + offset.top;
      if (x >= offset.left
        && y >= offset.top
        && x <= offset.right
        && y <= offset.bottom) { return true; }
      return false;

    };
    that.calendarOptions1 = {
      plugins: [timeGridPlugin],
      initialView: 'timeGridWeek',
      views: {

        TwoWeek: {
          text: '2 Weeks',
          type: 'timeGridWeek',
          duration: { weeks: 2 },
          dayHeaderContent: ({ date }) => {
            return moment(date).format('ddd MM/DD')
          },

        }
      },
      allDaySlot: true,
      slotDuration: '00:15:01',
      slotMinTime: '6:00',
      slotMaxTime: '24:00',
      nowIndicator: true,
      selectable: true,
      editable: true,
      droppable: true,
      slotLabelFormat:
      {
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false,
      },
      buttonText: {
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        list: 'List',
        TwoWeek: '2 Weeks',
      },
      headerToolbar:
      {
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridDay,timeGridWeek,TwoWeek,dayGridMonth'   
      },
      dateClick: this.handleDateClick.bind(this),
      eventDidMount: function (event) {
      },
      eventClick: function (event) {
        $(setTimeout(function () {
          that.leadservice.GetByTaskHistoryId(Number(event.event.id), "View", that.currentuser.timezone).then(result => {
            that.taskhistory = result;
            that.id = that.taskhistory.contactid;
            that.taskid = that.taskhistory.id;
            that.type = that.taskhistory.type;
            if (that.taskhistory.contactid == null || that.taskhistory.contactid == 0 || that.taskhistory.contactid == undefined) {
              that.BTClick = true;
            }

            var url = window.location.href;
            if (url.indexOf('month-calender') > -1) {
              $('#checkDiv').css('height', '83%');
            }
            if (that.taskid != undefined) {
              that.taskDialog = !that.taskDialog;
              that.taskDialogCss();
              $(setTimeout(function () {
               
                that.taskcomp.loadcoantactname(that.id, that.type);
                that.taskcomp.LoadSelectedTaskdata(that.taskid, true, "View");
                that.taskcomp.loadtaskHistoryByselecteddate(that.taskhistory.estcompleteddate);
              }, 300));
            }
          });
        }, 400));

      },
      eventMouseEnter: function (event) {
        var notesli = "";
        that.leadservice.GetAllTaskHistoryById(Number(event.event.id), 'TaskHistory').then((items) => {
          this.taskhistorynotes = items;
          this.taskhistorynotes.forEach((res) => {
            notesli += "<span> -" + res.notedescription + "</span><br />";
          });

        });


        that.leadservice.GetByTaskHistoryId(Number(event.event.id), "View", that.currentuser.timezone).then(taskdata => {
          that.taskhistory = taskdata;

          var moment = require('moment-timezone');
          let frm = (event.event.start == null) ? '(NTS)' : moment.tz(event.event.start.toLocaleString(), this.timezonevalue).format("h:mm A");
          let to = (event.event.end == null) ? '(NTS)' : moment.tz(event.event.end.toLocaleString(), this.timezonevalue).format("h:mm A");
          var contact = (that.taskhistory.contactid != 0 && that.taskhistory.contactid != null) ? "<b>Contact: </b>" + that.taskhistory.contactName + "<br/>" : "";
          var Account = (that.taskhistory.contactid != 0 && that.taskhistory.contactid != null) ? "<b>Account: </b>" + that.taskhistory.accountname + "<br/>" : "";
          let timelabel = frm + '-' + to;
         
        });

      },
      eventMouseLeave: function (data) {
        $(setTimeout(function () {
          $(this).css('z-index', 8);
          $('.tooltiptopicevent').remove();
        }, 300));
      },
      eventDrop: function (event) {
        that.makeEventsDraggable('eventDrop', event.event.id, event.event.start);
      },
      eventResize: function (event, ) {
        that.makeEventsDraggable('eventResize', event.event.id, event.event.start);

      },
      eventReceive: function (event) {
        that.makeEventsDraggable('eventReceive', that.globaleventid, event.event.start);
      },

      eventDragStart: function (event) {
        that.globaleventid = 0;
        calEventStatus['calendar'] = '#calendar1';
        calEventStatus['event_id'] = event.event.id;
        that.globaleventid = Number(event.event.id);
        //$('#calendar1').fullCalendar('removeEvents', event.event.id);
      },

      eventDragStop: function (event) {
        if (isEventOverDiv(event.jsEvent.clientX, event.jsEvent.clientY)) {
          //$('#calendar1').fullCalendar('removeEvents', event.event.id);
          var el = $("<div class='fc-event'>").appendTo('#external-events-listing').text(event.event.title);
          el.draggable({
            zIndex: 999,
            revert: true,
            revertDuration: 0
          });
          el.data('event', { title: event.event.title, id: event.event.id, stick: true });

        }

        //calEventStatus = [];

      },


    };
    that.calendarOptions2 = {
      plugins: [timeGridPlugin],
      initialView: 'listDay',
      allDaySlot: true,
      slotDuration: '00:15:01',
      slotMinTime: '6:00',
      slotMaxTime: '23:45',
      nowIndicator: true,
      selectable: true,
      editable: true,
      droppable: true,
      slotLabelFormat:
      {
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false,
      },     
      headerToolbar:
      {
        left: '',
        center: 'title',
        right: 'today,prev,next'
      },
      dateClick: this.handleDateClick.bind(this),
      eventDidMount: function (event) {
      },
      datesSet: this.handleEvents.bind(this),
      eventClick: function (event) {

        $(setTimeout(function () {
          that.leadservice.GetByTaskHistoryId(Number(event.event.id), "View", that.currentuser.timezone).then(result => {
            that.taskhistory = result;
            that.id = that.taskhistory.contactid;
            that.taskid = that.taskhistory.id;
            that.type = that.taskhistory.type;
            if (that.taskhistory.contactid == null || that.taskhistory.contactid == 0 || that.taskhistory.contactid == undefined) {
              that.BTClick = true;
            }

            var url = window.location.href;
            if (url.indexOf('month-calender') > -1) {
              $('#checkDiv').css('height', '83%');
            }
            if (that.taskid != undefined) {
              that.taskDialog = !that.taskDialog;
              that.taskDialogCss();
              $(setTimeout(function () {

                that.taskcomp.loadcoantactname(that.id, that.type);
                that.taskcomp.LoadSelectedTaskdata(that.taskid, true, "View");
                that.taskcomp.loadtaskHistoryByselecteddate(that.taskhistory.estcompleteddate);
              }, 300));
            }
          });
        }, 400));

      },
      eventMouseEnter: function (event) {
        var notesli = "";
        that.leadservice.GetAllTaskHistoryById(Number(event.event.id), 'TaskHistory').then((items) => {
          this.taskhistorynotes = items;
          this.taskhistorynotes.forEach((res) => {
            notesli += "<span> -" + res.notedescription + "</span><br />";
          });

        });


        that.leadservice.GetByTaskHistoryId(Number(event.event.id), "View", that.currentuser.timezone).then(taskdata => {
          that.taskhistory = taskdata;

          var moment = require('moment-timezone');
          let frm = (event.event.start == null) ? '(NTS)' : moment.tz(event.event.start.toLocaleString(), this.timezonevalue).format("h:mm A");
          let to = (event.event.end == null) ? '(NTS)' : moment.tz(event.event.end.toLocaleString(), this.timezonevalue).format("h:mm A");
          var contact = (that.taskhistory.contactid != 0 && that.taskhistory.contactid != null) ? "<b>Contact: </b>" + that.taskhistory.contactName + "<br/>" : "";
          var Account = (that.taskhistory.contactid != 0 && that.taskhistory.contactid != null) ? "<b>Account: </b>" + that.taskhistory.accountname + "<br/>" : "";
          let timelabel = frm + '-' + to;

        });

        $(this).mouseover(function (e) {
          $(this).css('z-index', 8);

          $('.tooltiptopicevent').fadeIn('500');
          $('.tooltiptopicevent').fadeTo('10', 1.9);
        }).mousemove(function (e) {
          $('.tooltiptopicevent').css('top', e.pageY + 10);
          $('.tooltiptopicevent').css('left', e.pageX + 20);
        });

      },
      eventMouseLeave: function (data) {
        $(setTimeout(function () {
          $(this).css('z-index', 8);
          $('.tooltiptopicevent').remove();
        }, 300));
      },
      eventDrop: function (event) {
        that.makeEventsDraggable('eventDrop', event.event.id, event.event.start);
      },
      eventResize: function (event, ) {
        that.makeEventsDraggable('eventResize', event.event.id, event.event.start);

      },
      eventReceive: function (event) {
        that.makeEventsDraggable('eventReceive', that.globaleventid, event.event.start);
      },

      eventDragStart: function (event) {
        that.globaleventid = 0;
        calEventStatus['calendar'] = '#calendar1';
        calEventStatus['event_id'] = event.event.id;
        that.globaleventid = Number(event.event.id);
      },

      eventDragStop: function (event) {
        if (isEventOverDiv(event.jsEvent.clientX, event.jsEvent.clientY)) {
          var el = $("<div class='fc-event'>").appendTo('#external-events-listing').text(event.event.title);
          el.draggable({
            zIndex: 999,
            revert: true,
            revertDuration: 0
          });
          el.data('event', { title: event.event.title, id: event.event.id, stick: true, description: 'Lecture' });

        }
      },


    };
    //that.calendarOptions3 = {

    //  initialView: 'listDay',
    //  initialDate: new Date().setDate(tomorrow.getDate() + 1),
    //  allDaySlot: true,
    //  slotDuration: '00:15:01',
    //  slotMinTime: '6:00',
    //  slotMaxTime: '24:00',
    //  nowIndicator: true,
    //  selectable: true,
    //  editable: true,
    //  droppable: true,
    //  slotLabelFormat:
    //  {
    //    hour: 'numeric',
    //    minute: '2-digit',
    //    omitZeroMinute: false,
    //  },
    //  headerToolbar:
    //  {
    //    left: '',
    //    center: 'title',
    //    right: ''
    //  },
    //  dateClick: this.handleDateClick.bind(this),
    //  eventDidMount: function (event) {
    //  },
    //  eventClick: function (event) {
    //    $(setTimeout(function () {
    //      that.leadservice.GetByTaskHistoryId(Number(event.event.id), "View", that.currentuser.timezone).then(result => {
    //        that.taskhistory = result;
    //        that.id = that.taskhistory.contactid;
    //        that.taskid = that.taskhistory.id;
    //        that.type = that.taskhistory.type;
    //        if (that.taskhistory.contactid == null || that.taskhistory.contactid == 0 || that.taskhistory.contactid == undefined) {
    //          that.BTClick = true;
    //        }

    //        var url = window.location.href;
    //        if (url.indexOf('month-calender') > -1) {
    //          $('#checkDiv').css('height', '83%');
    //        }
    //        if (that.taskid != undefined) {
    //          that.taskDialog = !that.taskDialog;
    //          that.taskDialogCss();
    //          $(setTimeout(function () {
    //            //that.scheduleComp.loadcoantactname(that.id, that.type);
    //            //that.scheduleComp.LoadSelectedTaskdata(that.taskid, true, "View");
    //            //that.scheduleComp.loadtaskHistoryByselecteddate(that.taskhistory.estcompleteddate);

    //            that.taskcomp.loadcoantactname(that.id, that.type);
    //            that.taskcomp.LoadSelectedTaskdata(that.taskid, true, "View");
    //            that.taskcomp.loadtaskHistoryByselecteddate(that.taskhistory.estcompleteddate);
    //          }, 300));
    //        }
    //      });
    //    }, 400));

    //  },
    //  eventMouseEnter: function (event) {
    //    var notesli = "";
    //    that.leadservice.GetAllTaskHistoryById(Number(event.event.id), 'TaskHistory').then((items) => {
    //      this.taskhistorynotes = items;
    //      this.taskhistorynotes.forEach((res) => {
    //        notesli += "<span> -" + res.notedescription + "</span><br />";
    //      });

    //    });


    //    that.leadservice.GetByTaskHistoryId(Number(event.event.id), "View", that.currentuser.timezone).then(taskdata => {
    //      that.taskhistory = taskdata;

    //      var moment = require('moment-timezone');
    //      let frm = (event.event.start == null) ? '(NTS)' : moment.tz(event.event.start.toLocaleString(), this.timezonevalue).format("h:mm A");
    //      let to = (event.event.end == null) ? '(NTS)' : moment.tz(event.event.end.toLocaleString(), this.timezonevalue).format("h:mm A");
    //      var contact = (that.taskhistory.contactid != 0 && that.taskhistory.contactid != null) ? "<b>Contact: </b>" + that.taskhistory.contactName + "<br/>" : "";
    //      var Account = (that.taskhistory.contactid != 0 && that.taskhistory.contactid != null) ? "<b>Account: </b>" + that.taskhistory.accountname + "<br/>" : "";
    //      let timelabel = frm + '-' + to;
    //      var tooltip = "<div class='tooltiptopicevent' style='width:auto;border:1px solid black;font-size:12px;height:auto;background:rgb(162, 228, 119);position:absolute;z-index:10001;padding:0.5% 0.5% 0.5% 0.5%;line-height:150%;top:"
    //        + (event.jsEvent.pageY) + "px;left:" + (event.jsEvent.pageX) + "px;'>"
    //        + "<b>Date: </b>" + moment(event.event.start).format("MM/DD/YYYY") + "<br/>"
    //        + "<b>Time: </b>" + timelabel + "<br/>"
    //        + contact
    //        + Account
    //        + "<b>Type: </b>" + event.event.title.split("[")[0] + "<br/>"
    //        + "<b>Notes: </b>" + notesli + "<br/>"
    //        + "</div>";
    //      $(this).mouseover(function (e) {
    //        $('.tooltiptopicevent').css('top', e.pageY + 10);
    //        $('.tooltiptopicevent').css('left', e.pageX + 20);
    //      });
    //      $(this).mousemove(function (e) {
    //        $('.tooltiptopicevent').css('top', e.pageY + 10);
    //        $('.tooltiptopicevent').css('left', e.pageX + 20);
    //      });
    //      $("body").append(tooltip);
    //    });

    //    $(this).mouseover(function (e) {
    //      $(this).css('z-index', 8);

    //      $('.tooltiptopicevent').fadeIn('500');
    //      $('.tooltiptopicevent').fadeTo('10', 1.9);
    //    }).mousemove(function (e) {
    //      $('.tooltiptopicevent').css('top', e.pageY + 10);
    //      $('.tooltiptopicevent').css('left', e.pageX + 20);
    //    });

    //  },
    //  eventMouseLeave: function (data) {
    //    $(setTimeout(function () {
    //      $(this).css('z-index', 8);
    //      $('.tooltiptopicevent').remove();
    //    }, 300));
    //  },
    //  eventDrop: function (event) {
    //    that.makeEventsDraggable('eventDrop', event.event.id, event.event.start);
    //  },
    //  eventResize: function (event,) {
    //    that.makeEventsDraggable('eventResize', event.event.id, event.event.start);

    //  },
    //  eventReceive: function (event) {
    //    that.makeEventsDraggable('eventReceive', that.globaleventid, event.event.start);
    //  },

    //  eventDragStart: function (event) {
    //    that.globaleventid = 0;
    //    calEventStatus['calendar'] = '#calendar1';
    //    calEventStatus['event_id'] = event.event.id;
    //    that.globaleventid = Number(event.event.id);
    //    //$('#calendar1').fullCalendar('removeEvents', event.event.id);
    //  },
    //  eventDragStop: function (event) {
    //    if (isEventOverDiv(event.jsEvent.clientX, event.jsEvent.clientY)) {
    //      //$('#calendar1').fullCalendar('removeEvents', event.event.id);
    //      var el = $("<div class='fc-event'>").appendTo('#external-events-listing').text(event.event.title);
    //      el.draggable({
    //        zIndex: 999,
    //        revert: true,
    //        revertDuration: 0
    //      });
    //      el.data('event', { title: event.event.title, id: event.event.id, stick: true });

    //    }
    //  },


    //};
  }


  makeEventsDraggable(eventtype, eventid, date) {
    let that = this;
    $('.fc-draggable').each(function () {
      $(this).data('event', {
        title: $.trim($(this).text()), // use the element's text as the event title
        stick: true // maintain when user navigates (see docs on the renderEvent method)
      });
      $(this).draggable({
        zIndex: 999,
        revert: true,      // will cause the event to go back to its
        revertDuration: 0  //  original position after the drag
      });
      $("td").removeClass("fc-highlight");
    });
    $('.fc-rigid').attr('style', 'height:80px');
    $('.fc-day-grid-container').attr('style', 'height:auto');
    if (eventid != 0) {
      that.leadservice.GetByTaskHistoryId(eventid, "View", that.currentuser.timezone).then(result => {
        that.taskhistory = result;
        that.taskhistory.estcompleteddate = date;
        that.taskhistory.contactid = that.taskhistory.contactid;
        that.taskhistory.type = that.taskhistory.type;
        that.taskhistory.userid = that.currentuser.id;
        that.taskhistory.timezone = that.currentuser.timezone;
        that.taskhistory.tasktype = $("#sel1 option:selected").text();
        if (that.taskhistory.time != undefined || that.taskhistory.time != null) {
          //that.taskhistory.starttime = that.taskhistory.time;

          that.taskhistory.starttime = moment(date).format("MM/DD/YYYY hh:mm a");
        }
        if (that.taskhistory.totime != null) {
          //that.taskhistory.endtime = that.taskhistory.totime;
          var difference = moment.duration(moment(that.taskhistory.totime).diff(moment(that.taskhistory.time)));
          var endTime = new Date(date.getTime() + difference.asMinutes() * 60000);
          that.taskhistory.endtime = moment(endTime).format("MM/DD/YYYY hh:mm a");
        }
        that.leadservice.addTaskHistory(that.taskhistory).then(result => {


          that.notes.accountid = result.id;
          that.notes.type = "TaskHistory";
          if (eventtype == "eventDrop" || eventtype == "eventReceive") {
            $(setTimeout(function () {
              //that.TaskData(that.statustype, $("#userids").val())
              that.checkvalues('All Tasks', $("#userids").val());
            }, 100));
          }


          that.notes.userid = that.currentuser.id;
          that.notes.notedescription = "Task Moved to " + moment(date).format("MM/DD/YYYY hh:mm a");

          that.opprtunityservice.addopprtunityNotes(that.notes).then(result => {
            that.notes = result;

            that.taskhistory.notedescription = '';
            this.toastr.success("Task saved successfully.", "Task Saved", { timeOut: 600 });
          });
        });

      });
    }

  }


  hours_am_pm(time) {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part = hour > 12 ? 'pm' : 'am';
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`;
  }
  checkvalues(val, userids) {
    this.newEvents = [];
    this.finaldataincludediscription = [];
    if (userids && userids != null) {
      this.leadservice.GetAllTaskHistoryForCalendar('All Tasks', userids, this.startDateVal, this.endDateVal, 'null', 'All Tasks', this.currentuser.timezone).then((taskhistory) => {
        taskhistory.forEach(taskhistory => {
          if (taskhistory.status == 'Open') {
            if ((taskhistory.starttime != null && taskhistory.starttime != '0:00')) {
             
              (this.finaldata).push({
                id: taskhistory.id,
                title: taskhistory.tasktype + ((taskhistory.contactid == 0 || taskhistory.contactid == null) ? '' : "    - " + taskhistory.firstName + "," + taskhistory.lastName),
                start: (taskhistory.starttime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.starttime).toISOString() : '',
                end: (taskhistory.endtime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.endtime).toISOString() : '',
                color: (taskhistory.contactid == 0) ? '#0c3d6d' : '#3788d8',
                backgroundColor: (taskhistory.taskcategory == "Phone") ? '#00aa55' : taskhistory.taskcategory == "To Do" ? '#f56042' : taskhistory.taskcategory == "Blocked Times" ? '#aa54aa' : '#3788d8'
              });
              (this.finaldataincludediscription).push({
                id: taskhistory.id,
                title: "<div class='col-xs-12'><div class='col-xs-1' style='padding:0%;width: 7.5%;'><i class='fa fa-circle' style='font-size: 13px;color: rgb(55, 136, 216);'></i> </div><div class='col-xs-1' style='padding:0%;width: 7.5%;'><i class='fa fa-check' style='font-size: 18px; color:white'></i> </div> <div class='col-xs-7' style='float: left;padding:0%'><div style='width:340px; float: left;'>" + taskhistory.tasktype + "</div><div>" + ((taskhistory.contactid == 0 || taskhistory.contactid == null) ? '' : "" + taskhistory.firstName + "," + taskhistory.lastName + "," + (taskhistory.title == null ? "" : taskhistory.title)) + " " + "</div><div>" + taskhistory.accountname + "</div><div>" +
                  ((taskhistory.accountphone == undefined) ? '' : taskhistory.accountphone) + "</div></div><div class='col-xs-3' style='padding:0%'>" + taskhistory.owner +  "</div> </div>",
                start: (taskhistory.starttime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.starttime).toISOString() : '',
                end: (taskhistory.endtime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.endtime).toISOString() : '',
                color: (taskhistory.contactid == 0) ? '#0c3d6d' : '#3788d8',
                backgroundColor: (taskhistory.taskcategory == "Phone") ? '#00aa55' : taskhistory.taskcategory == "To Do" ? '#f56042' : taskhistory.taskcategory == "Blocked Times" ? '#aa54aa' : '#3788d8'
              });

            }
            else {
              (this.finaldata).push({
                id: taskhistory.id,
                title: taskhistory.tasktype + ((taskhistory.contactid == 0 || taskhistory.contactid == null) ? '' : "    - " + taskhistory.firstName + "," + taskhistory.lastName),
                start: taskhistory.estcompleteddate,
                color: (taskhistory.contactid == 0) ? '#0c3d6d' : '#3788d8',
                backgroundColor: (taskhistory.taskcategory == "Phone") ? '#00aa55' : taskhistory.taskcategory == "To Do" ? '#f56042' : taskhistory.taskcategory == "Blocked Times" ? '#aa54aa' : '#3788d8'
              });
            }
          }
          else if (taskhistory.status == 'Completed' || taskhistory.status == 'Complete & Repeat' || taskhistory.status == 'Complete & Schedule New' || taskhistory.status == 'Complete & Return' || taskhistory.status == 'Not Completed') {
            if ((taskhistory.starttime != null && taskhistory.starttime != '0:00')) {
              (this.finaldata).push({
                id: taskhistory.id,
                title: taskhistory.tasktype + ((taskhistory.contactid == 0 || taskhistory.contactid == null) ? '' : "    - " + taskhistory.firstName + "," + taskhistory.lastName + "," + taskhistory.title),
                start: (taskhistory.starttime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.starttime).toISOString() : '',
                end: (taskhistory.endtime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.endtime).toISOString() : '',
                color: (taskhistory.contactid == 0) ? 'lightgrey' : 'lightgrey',
                textColor: 'black', backgroundColor: 'lightgrey'
              });
              (this.finaldataincludediscription).push({
                id: taskhistory.id,
                title: "<div class='col-xs-12'><div class='col-xs-1' style='padding:0%;width: 7.5%;'><i class='fa fa-circle' style='font-size: 13px;color: rgb(55, 136, 216);'></i> </div><div class='col-xs-1' style='padding:0%;width: 7.5%;'><i class='fa fa-check' style='font-size: 18px;color: rgb(55, 136, 216)'></i> </div> <div class='col-xs-7' style='float: left;padding:0%'><div style='width:340px; float: left;'>" + taskhistory.tasktype + "</div><div>" + ((taskhistory.contactid == 0 || taskhistory.contactid == null) ? '' : "" + taskhistory.firstName + "," + taskhistory.lastName + "," + (taskhistory.title == null ? "" : taskhistory.title)) + " " + "</div><div>" + taskhistory.accountname + "</div><div>" +
                  ((taskhistory.accountphone == undefined) ? '' : taskhistory.accountphone) + "" + "</div></div><div class='col-xs-3' style='padding:0%'>" + taskhistory.owner + "</div> </div>",
                start: (taskhistory.starttime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.starttime).toISOString() : '',
                end: (taskhistory.endtime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.endtime).toISOString() : '',
                color: (taskhistory.contactid == 0) ? 'lightgrey' : 'lightgrey',
                textColor: 'black', backgroundColor: 'lightgrey'
              });

            } else {
              (this.finaldata).push({
                id: taskhistory.id,
                color: (taskhistory.contactid == 0) ? 'lightgrey' : 'lightgrey',
                Contact: taskhistory.contactName,
                title: taskhistory.tasktype + "[" + taskhistory.owner,
                textColor: 'black', backgroundColor: 'lightgrey'

              });
            }
          }
          else {
            if ((taskhistory.starttime != null && taskhistory.starttime != '0:00')) {
              (this.finaldata).push({
                id: taskhistory.id,
                title: taskhistory.tasktype + ((taskhistory.contactid == 0 || taskhistory.contactid == null) ? '' : "    - " + taskhistory.firstName + "," + taskhistory.lastName + "," + taskhistory.title),
                start: (taskhistory.starttime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.starttime).toISOString() : '',
                end: (taskhistory.endtime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.endtime).toISOString() : '',
                color: (taskhistory.contactid == 0) ? '#0c3d6d' : '#3788d8',
                backgroundColor: (taskhistory.taskcategory == "Phone") ? '#00aa55' : taskhistory.taskcategory == "To Do" ? '#f56042' : taskhistory.taskcategory == "Blocked Times" ? '#aa54aa' : '#3788d8'

              });
              (this.finaldataincludediscription).push({
                id: taskhistory.id,
                title: "<div class='col-xs-12'><div class='col-xs-1' style='padding:0%;width: 7.5%;'><i class='fa fa-circle' style='font-size: 13px;color: rgb(55, 136, 216);'></i> </div><div class='col-xs-1' style='padding:0%;width: 7.5%;'><i class='fa fa-check' style='font-size: 18px; color: white'></i> </div> <div class='col-xs-7' style='float: left;padding:0%'><div style='width:340px; float: left;'>" + taskhistory.tasktype + "</div><div>" + ((taskhistory.contactid == 0 || taskhistory.contactid == null) ? '' : "" + taskhistory.firstName + "," + taskhistory.lastName + "," + (taskhistory.title == null ? "" : taskhistory.title)) + " " + "</div><div>" + taskhistory.accountname + "</div><div>" +
                  taskhistory.accountphone + "" + "</div></div><div class='col-xs-3' style='padding:0%'>" + taskhistory.owner + "</div> </div>",
                start: (taskhistory.starttime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.starttime).toISOString() : '',
                end: (taskhistory.endtime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.endtime).toISOString() : '',
                color: (taskhistory.contactid == 0) ? '#0c3d6d' : '#3788d8',
                backgroundColor: (taskhistory.taskcategory == "Phone") ? '#00aa55' : taskhistory.taskcategory == "To Do" ? '#f56042' : taskhistory.taskcategory == "Blocked Times" ? '#aa54aa' : '#3788d8'
              });

            } else {
              (this.finaldata).push({ id: taskhistory.id, color: (taskhistory.contactid == 0) ? '#0c3d6d' : '#3788d8', Contact: taskhistory.contactName, title: taskhistory.tasktype + "    &nbsp;  " + taskhistory.firstName + "," + taskhistory.lastName + "," + taskhistory.title, start: taskhistory.estcompleteddate, From: taskhistory.starttime, To: taskhistory.endtime, backgroundColor: (taskhistory.taskcategory == "Phone") ? '#00aa55' : taskhistory.taskcategory == "To Do" ? '#f56042' : taskhistory.taskcategory == "Blocked Times" ? '#aa54aa' : '#3788d8' });
            }
          }

        });
        if (this.check == true) {
          this.newEvents = this.finaldata;
          this.newEventsincludediscription = this.finaldataincludediscription;
          this.calendarOptions1.events = [];
          this.calendarOptions2.events = [];
          // this.calendarOptions3.events = [];

          this.calendarOptions1.events = this.newEvents;
          this.calendarOptions2.events = this.finaldataincludediscription;
          $('#myCalendar1 .fc-next-button').click();         
            setTimeout(function () {             
              $('#myCalendar1 .fc-today-button').click();
            }, 100);        

          this.finaldata = [];
        }

        this.alltaskhistory = taskhistory;
      });
    } else {
      this.calendarOptions1.events = [];
      this.calendarOptions2.events = [];

      this.newEvents = [];
      this.calendarOptions1.events = this.newEvents;
      this.calendarOptions2.events = this.newEvents;

    }
  }
  getYear() {
    var today = new Date();
    this.yy = today.getFullYear();
    for (var i = (this.yy - 100); i <= this.yy; i++) {
      this.years.push(i);
    }
  }

  checkTabAccess() {
    if (this.selectedMenuRadio == "tab1")
      return this.myDashboard;
    else if (this.selectedMenuRadio == "tab2")
      return this.salesDashBoard;
    else if (this.selectedMenuRadio == "tab3")
      return this.nbdDashBoard;
    else if (this.selectedMenuRadio == "tab4")
      return this.trainerDashboard;
    else if (this.selectedMenuRadio == "tab5")
      return this.supportDashboard;
    else if (this.selectedMenuRadio == "tab6")
      return this.ProjectsDashboard;
    else if (this.selectedMenuRadio == "tab7")
      return this.RevenueDashboard;
    else if (this.selectedMenuRadio == "tab8")
      return this.TransferedAccountsDashboard;
    else if (this.selectedMenuRadio == "notab")
      return false;
  }

  ngOnInit() {
    this.refresh();
  
    $(".mainCheckMark").removeClass("activeColor");
    $(".coustomButton").removeClass("mainactiveButton");
    $('#dashboard').addClass('mainactiveButton');
    this.applicationComponent.searchstring = "";
    this.currentDate();
    var calEventStatus = [];
    this.route.params.subscribe(params => {
      this.selectedMenuRadio = params['type'];
      let selfObj = this;
      $(setTimeout(function () {
        if (params['type'] == 'tab1') { $("#tab1").trigger("click"); }
        else if (params['type'] == 'tab2') { $("#tab2").trigger("click"); }
        else if (params['type'] == 'tab3') { $("#tab3").trigger("click"); }
        else if (params['type'] == 'tab4') { $("#tab4").trigger("click"); }
        else if (params['type'] == 'tab5') { $("#tab5").trigger("click"); }
        else if (params['type'] == 'tab6') { $("#tab6").trigger("click"); }
        else if (params['type'] == 'tab7') { $("#tab7").trigger("click"); }
        else if (params['type'] == 'tab8') { $("#tab8").trigger("click"); }
        selfObj.DashboardChanged(params['type'])
      }, 500));
    });


    let selfObj = this;
    $(setTimeout(function () {
      selfObj.opprtunityservice.GetDashBoardAccess(selfObj.currentuser.id).then((config) => {
        config = config.sort(x => x.dashboardtypeid);
        config = config.reverse();
        config.forEach(config => {
          if (config.dashboardtypeid == 475) {
            selfObj.myDashboard = config.isaccess == 1 ? true : false;
          } else if (config.dashboardtypeid == 476) {
            selfObj.salesDashBoard = config.isaccess == 1 ? true : false;
          } else if (config.dashboardtypeid == 477) {
            selfObj.nbdDashBoard = config.isaccess == 1 ? true : false;
          } else if (config.dashboardtypeid == 478) {
            selfObj.trainerDashboard = config.isaccess == 1 ? true : false;
          } else if (config.dashboardtypeid == 479) {
            selfObj.supportDashboard = config.isaccess == 1 ? true : false;
          } else if (config.dashboardtypeid == 689) {
            selfObj.ProjectsDashboard = config.isaccess == 1 ? true : false;
          } else if (config.dashboardtypeid == 690) {
            selfObj.RevenueDashboard = config.isaccess == 1 ? true : false;
          }
        });

        if (config.length == 0) {
          selfObj.myDashboard = true;
          selfObj.salesDashBoard = false;
          selfObj.nbdDashBoard = false;
          selfObj.trainerDashboard = false;
          selfObj.supportDashboard = false;
          selfObj.ProjectsDashboard = false;
          selfObj.RevenueDashboard = false;
          selfObj.TransferedAccountsDashboard = false;
          selfObj.selectedMenuRadio = "tab1";
        }

        if (selfObj.myDashboard || selfObj.salesDashBoard || selfObj.nbdDashBoard || selfObj.trainerDashboard || selfObj.supportDashboard || selfObj.ProjectsDashboard || selfObj.RevenueDashboard || selfObj.TransferedAccountsDashboard) {
          //do nothing
        } else {
          selfObj.selectedMenuRadio = "notab";
        }

      });
    }, 200));
    $('.ag-tool-panel').attr('style', 'display:none');
    this.customerService.GetSpecialityList("Dashboard", this.currentuser.id).then((ehr) => {
      ehr.forEach(accounttype => {
      });
      this.Dashboardtypelist = ehr;
    });
    this.dropdownList = this.applicationComponent.dropdownList;

    this.finalitems += this.currentuser.id + ',';
    if ($("#userids").val() && $("#userids").val() != null && $("#userids").val() != undefined && $("#userids").val() != '') {
      this.UserIds = $("#userids").val();
    } else {
      this.UserIds = this.currentuser.id;
    }  

    this.advsearch.GetAllRunQueries(this.currentuser.id).then((queries) => {
      if (queries && queries.length > 0) {
        if (queries[0].queryOrder == null) {
          this.applicationComponent.searchoffon = false;
        } else {
          var list = queries[0].queryOrder.split(",");
          list.forEach(element => {
            if (element != "") {
              this.applicationComponent.searchoffon = true;
            }
          });
        }
      }
    });

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 2
    };
    let selfObj1 = this;
    //$(setTimeout(function () {
    //  selfObj1.AddSuspendInactiveDiv();
    //}, 400));

    $("#UserList .c-btn").click(function () {

      selfObj1.DropDownListBox = !selfObj1.DropDownListBox;
      if (selfObj1.DropDownListBox == true) {
        $("#UserList .dropdown-list").attr("hidden", false);
      } else {
        $("#UserList .dropdown-list").attr("hidden", true);
      }
    });

    window.onclick = function (event) {
      var overlay = document.getElementsByClassName('col-md-12');
      if (event.target == overlay[0]) {
        selfObj1.DropDownListBox = false;
      }

      var cbtn = document.getElementsByClassName('c-btn');
      var clist = document.getElementsByClassName('c-list');
      var cinput = document.getElementsByClassName('c-input ng-pristine ng-valid ng-star-inserted ng-touched');//done
      var selectall = document.getElementsByClassName('pure-checkbox select-all ng-star-inserted');// done
      var purecheckbox = document.getElementsByClassName('pure-checkbox ng-star-inserted selected-item');// done
      var lazyContainer = document.getElementsByClassName('dropdown-list animated fadeIn');
      var lazy = document.getElementsByClassName('lazyContainer');

      var SuspendInactiveDiv = document.getElementById('SuspendInactiveDiv');
      var UserListHeader = document.getElementById('UserListHeader');
      var ButtonsDiv = document.getElementById('ButtonsDiv');
      var Active = document.getElementById('Active');
      var Inactive = document.getElementById('Inactive');
      var suspended = document.getElementById('suspended');
      var MultiUserSave = document.getElementById('MultiUserSave');
      var MultiUserCancel = document.getElementById('MultiUserCancel');
      if (event.target == clist || event.target == Inactive || event.target == Active || event.target == suspended || event.target == cbtn || event.srcElement == cinput[0] || event.srcElement == selectall[1] || event.srcElement == purecheckbox[1] || (event.target.parentNode && lazy[1] != undefined && (event.target.parentNode.className == lazy[1].className)) || event.target == MultiUserSave || event.target == MultiUserCancel || event.target == SuspendInactiveDiv || event.target == UserListHeader || event.target == ButtonsDiv || event.target == lazyContainer) {
      } else {
        if (selfObj1.MultiselectDropDownChanged == true) {
          selfObj1.SaveUserListConfirmation = true;
          selfObj1.SaveUserListConfirmationCss();
          $("#UserList .dropdown-list").attr("hidden", false);
        }

      }

    }

    //this.AccTranInitializeAgGrid();
    //this.getTransferedAccounts();
    this.applicationComponent.SearchSelectedItemsLengthChanged = false;
    this.initializeAgGrid();
    this.initializePastDueActivityAgGrid();
    this.GenerateUserAccess(this.UserIds);
    this.Generate(this.UserIds);
    this.getSelectedtmyDashDefGridBox();

    let selfObja = this;
    $(setTimeout(function () {
      selfObja.GetUserMessagesByUserId();
    }, 400));

  }

  SaveConfirm(val) {
    if (val == 'Yes') {
      this.MultiUserSaveclicked();
      this.TicketsDashComp.getCustomerOnHoldData();
    } else {
      this.MultiselectDropDownChanged = false;
      this.SaveUserListConfirmation = false;
      $("#UserList .dropdown-list").attr("hidden", true);
    }
    $('#cancelDiv').attr('style', 'display:none;');
    $('#SaveDiv').attr('style', 'display:none;');
    if (this.UserIds == null) {
      this.newEvents = [];
      this.calendarOptions1.events = this.newEvents;
      this.calendarOptions2.events = this.newEvents;
    }
  }

  SaveUserListConfirmationCss() {
    setTimeout(function () {
      $('#SaveUserListConfirmation .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 4)
  }
  getSelectedUserList(val) {
    //this.customerService.getSelectedUserList(val).then(res => {
    //  if (res != null && res.length > 0) {
    //    this.applicationComponent.selectedItems = res;
    //    localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
    //    var ids = [];
    //    this.applicationComponent.selectedItems.forEach(ele => {
    //      ids.push(ele.id);
    //    });

    //    this.UserIds = ids.join(',');
    //    $("#userids").val(ids.join(','));
    //    //this.Proposalcomp.GetAllProposal(this.UserIds);
    //  } else {
    //this.applicationComponent.selectedItems = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    this.applicationComponent.selectedItems = [
      { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
    ];
    localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
    $("#userids").val(this.currentuser.id);
    //  }
    //});
  }

  AddSuspendInactiveDiv() {

    var mydiv = document.getElementsByClassName("pure-checkbox select-all ng-star-inserted");
    var Headerdiv = document.createElement('div');
    Headerdiv.id = "Header";
    Headerdiv.className = "col-xs-12 UserListHeader";
    mydiv[1].parentNode.insertBefore(Headerdiv, mydiv[1].previousSibling);

    var UserListlabel = document.createElement('label'); // creating label for checkbox 
    UserListlabel.htmlFor = "UserList"; // assigning attributes for
    UserListlabel.id = "UserList";
    UserListlabel.appendChild(document.createTextNode(' User List   '));
    $('#Header').append(UserListlabel);
    ////////////////////////////////////////////////////////////////////////////////////////////////

    var ButtonsDiv = document.createElement('div');
    ButtonsDiv.id = "ButtonsDiv";
    ButtonsDiv.className = "col-xs-12 UserListButtons";
    mydiv[1].parentNode.insertBefore(ButtonsDiv, mydiv[1].previousSibling);

    var cancelDiv = document.createElement('div');
    cancelDiv.id = "cancelDiv";
    cancelDiv.className = "col-xs-6";
    $('#ButtonsDiv').append(cancelDiv);

    var SaveDiv = document.createElement('div');
    SaveDiv.id = "SaveDiv";
    SaveDiv.className = "col-xs-6";
    $('#ButtonsDiv').append(SaveDiv);

    var CancelButton = document.createElement('button');
    CancelButton.textContent = 'Cancel'; CancelButton.className = 'MultiselectCancelButton'; CancelButton.value = "Cancel"; CancelButton.title = 'Cancel'; CancelButton.id = "MultiUserCancel"
    $('#cancelDiv').append(CancelButton);

    var SaveChanges = document.createElement('button');
    SaveChanges.textContent = 'Save'; SaveChanges.className = 'MultiselectSaveButton'; SaveChanges.value = "Save"; SaveChanges.title = 'Save'; SaveChanges.id = "MultiUserSave"
    $('#SaveDiv').append(SaveChanges);
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    var SuspendInactiveDiv = document.createElement('div');
    SuspendInactiveDiv.id = "SuspendInactiveDiv";
    SuspendInactiveDiv.className = "col-xs-12 Banoos";
    mydiv[1].parentNode.insertBefore(SuspendInactiveDiv, mydiv[1].previousSibling);


    var ActiveDiv = document.createElement('div');
    ActiveDiv.id = "ActiveDiv";
    ActiveDiv.className = "col-xs-3";
    $('#SuspendInactiveDiv').append(ActiveDiv);

    var inActiveDiv = document.createElement('div'); // creating checkbox element
    inActiveDiv.id = "inActiveDiv";
    inActiveDiv.className = "col-xs-4";
    $('#SuspendInactiveDiv').append(inActiveDiv);

    var suspendDiv = document.createElement('div'); // creating checkbox element
    suspendDiv.id = "suspendDiv";
    suspendDiv.className = "col-xs-5";
    $('#SuspendInactiveDiv').append(suspendDiv);


    var ActiveCheckBox = document.createElement('input'); // creating checkbox element 
    ActiveCheckBox.type = "checkbox"; // Assigning the attributes 
    ActiveCheckBox.name = "Active";
    ActiveCheckBox.value = "Active";
    ActiveCheckBox.checked = true;
    ActiveCheckBox.id = "Active";
    var ActiveLabel = document.createElement('label'); // creating label for checkbox 
    ActiveLabel.htmlFor = "Active"; // assigning attributes for
    ActiveLabel.id = "ActiveLabel";
    ActiveLabel.appendChild(document.createTextNode(' Active   '));  // appending the created text to  

    $('#ActiveDiv').append(ActiveCheckBox);
    $('#ActiveDiv').append(ActiveLabel);


    var InactiveCheckbox = document.createElement('input'); // creating checkbox element 
    InactiveCheckbox.type = "checkbox"; // Assigning the attributes 
    InactiveCheckbox.name = "Inactive";
    InactiveCheckbox.value = "Inactive";
    InactiveCheckbox.id = "Inactive";
    var InactiveLabel = document.createElement('label'); // creating label for checkbox 
    InactiveLabel.htmlFor = "Inactive"; // assigning attributes for
    InactiveLabel.id = "InactiveLabel";
    InactiveLabel.appendChild(document.createTextNode(' Inactive   '));  // appending the created text to  

    $('#inActiveDiv').append(InactiveCheckbox);
    $('#inActiveDiv').append(InactiveLabel);


    var SuspendedCheckbox = document.createElement('input'); // creating checkbox element 
    SuspendedCheckbox.type = "checkbox"; // Assigning the attributes 
    SuspendedCheckbox.name = "suspended";
    SuspendedCheckbox.value = "suspended";
    SuspendedCheckbox.id = "suspended";
    var SuspendedLabel = document.createElement('label'); // creating label for checkbox 
    SuspendedLabel.htmlFor = "suspended"; // assigning attributes for
    SuspendedLabel.id = 'suspendedLabel';
    SuspendedLabel.appendChild(document.createTextNode(' Suspended'));  // appending the created text to  

    $('#suspendDiv').append(SuspendedCheckbox);
    $('#suspendDiv').append(SuspendedLabel);

    $('#cancelDiv').attr('style', 'display:none;');
    $('#SaveDiv').attr('style', 'display:none;');
    $('#ActiveDiv').attr('style', 'display:flex;width:30%');
    $('#inActiveDiv').attr('style', 'padding:0;display:flex;width:30%');
    $('#suspendDiv').attr('style', 'padding:0;;display:flex;width:40%');

    $('#ActiveLabel').attr('style', 'margin-left:4%');
    $('#InactiveLabel').attr('style', 'margin-left:4%');
    $('#suspendedLabel').attr('style', 'margin-left:4%');

    let self = this;
    document.getElementById('Active').onclick = function inactiveclick() {
      self.GetAllaccesusersListbyuser();
    }

    document.getElementById('Inactive').onclick = function inactiveclick() {
      self.GetAllaccesusersListbyuser();
    }

    document.getElementById('suspended').onclick = function inactiveclick() {
      self.GetAllaccesusersListbyuser();
    }

    document.getElementById('MultiUserSave').onclick = function MultiUserSaveclick() {
      self.MultiUserSaveclicked();

    }
    document.getElementById('MultiUserCancel').onclick = function MultiUserSaveclick() {
      self.MultiUserCancelClicked();

    }
  }
  MultiUserCancelClicked() {
    $("#UserList .dropdown-list").attr("hidden", true);

    this.MultiselectDropDownChanged = false;
    this.SaveUserListConfirmation = false;
    this.DropDownListBox = false;
    this.applicationComponent.selectedItems = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.UserIdsCount = this.applicationComponent.selectedItems.length;
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));

    }
    this.SaveConfirm('No');
  }
  MultiUserSaveclicked() {

    localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
    this.MultiselectDropDownChanged = false;
    this.SaveUserListConfirmation = false;
    this.DropDownListBox = false;
    $("#UserList .dropdown-list").attr("hidden", true);
  }

  GetAllaccesusersListbyuser() {
    this.dropdownList = [];
    var active = $('#Active').prop("checked") ? 1 : 0;
    var inactive = $('#Inactive').prop("checked") ? 1 : 0;
    var suspended = $('#suspended').prop("checked") ? 1 : 0;
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, active, inactive, suspended).then((Owner) => {
      this.applicationComponent.dropdownList = Owner;
    });
  }

  public chartHovered(e: any): void { }

  currentDate() {
    var now = new Date();
    var mm = now.getMonth() + 1;
    var mon = '';
    if (mm < 10) {
      mon = '0' + mm;
      this.CurrentDate = now.getFullYear() + '-' + mon;
      this.CallsCompletedLabel = this.NewTicketsLabel = this.ClosedTicketsLabel = this.MonthlySalesRRLabel = this.MonthlyAccountsLabel = this.MonthlyToDoLabel = this.MonthlyMeetingLabel = this.MeetingsCompletedLabel =
        this.ToDoCompletedLabel = this.SalesWonLabel = this.RRWonLabel = this.LeadsConvertedLabel = this.TotalOppWonPriceLabel = this.TotalOppWonRRLabel = this.TotalLostOppPriceLabel = this.TotalOpenOppPriceLabel = moment(this.CurrentDate).format('MMM, YYYY');

    }
    else {
      this.CurrentDate = now.getFullYear() + '-' + mm;
      this.CallsCompletedLabel = this.NewTicketsLabel = this.ClosedTicketsLabel = this.MonthlySalesRRLabel = this.MonthlyAccountsLabel = this.MonthlyToDoLabel = this.MonthlyMeetingLabel = this.MeetingsCompletedLabel = this.ToDoCompletedLabel = this.SalesWonLabel = this.RRWonLabel = this.LeadsConvertedLabel = this.TotalOppWonPriceLabel = this.TotalOpenOppPriceLabel = this.TotalOppWonRRLabel = this.TotalLostOppPriceLabel = moment(this.CurrentDate).format('MMM, YYYY');
    }

  }
  ViewMonthlyNewTickets(loggedinuserid, monthName, year) {
    this.opprtunityservice.GetMonthlyNewTicketsAdded(this.UserIds, monthName, year).then(newticketresult => {

      this.salesinfo.NewTicketsgoal = (newticketresult.Goal != null) ? newticketresult.Goal : 0;
      this.salesinfo.NewTicketCount = (newticketresult.Monthcount != null) ? newticketresult.Monthcount : 0;
      this.salesinfo.NewTicketPercentage = (newticketresult.Percentage != null) ? newticketresult.Percentage.replace('%', '') : "0";
      this.salesinfo.NewTicketPercentage = Math.round(parseFloat(this.salesinfo.NewTicketPercentage)).toString();
      if (parseInt(this.salesinfo.NewTicketPercentage) >= 0 && parseInt(this.salesinfo.NewTicketPercentage) <= 25) {

        this.NewTicketsPercentageColor = "#FF0000";
      }
      else if (parseInt(this.salesinfo.NewTicketPercentage) >= 26 && parseInt(this.salesinfo.NewTicketPercentage) <= 75) {


        this.NewTicketsPercentageColor = "yellow";
      }
      else {

        this.NewTicketsPercentageColor = "#77C043";
      }


    });
  }
  ViewMonthlyClosedTickets(loggedinuserid, monthName, year) {
    this.opprtunityservice.GetMonthlyClosedTickets(this.UserIds, monthName, year).then(newticketresult => {

      this.salesinfo.ClosedTicketsgoal = (newticketresult.Goal != null) ? newticketresult.Goal : 0;
      this.salesinfo.ClosedTicketCount = (newticketresult.Monthcount != null) ? newticketresult.Monthcount : 0;
      this.salesinfo.ClosedTicketPercentage = (newticketresult.Percentage != null) ? newticketresult.Percentage.replace('%', '') : "0";
      this.salesinfo.ClosedTicketPercentage = (this.salesinfo.ClosedTicketPercentage != '0') ? Math.round(parseFloat(this.salesinfo.ClosedTicketPercentage)).toString() : "0";
      if (parseInt(this.salesinfo.ClosedTicketPercentage) >= 0 && parseInt(this.salesinfo.ClosedTicketPercentage) <= 25) {

        this.ClosedTicketsPercentageColor = "#FF0000";
      }
      else if (parseInt(this.salesinfo.ClosedTicketPercentage) >= 26 && parseInt(this.salesinfo.ClosedTicketPercentage) <= 75) {


        this.ClosedTicketsPercentageColor = "yellow";
      }
      else {

        this.ClosedTicketsPercentageColor = "#77C043";
      }


    });
  }


  ViewMonthlyToDoCompleted(loggedinuserid, monthName, year) {
    this.opprtunityservice.GetMonthlyToDoCompleted(this.UserIds, monthName, year).then(result => {
      if (result) {
        this.salesinfo.MonthlyToDogoal = (result.Goal != null) ? result.Goal : 0;
        this.salesinfo.MonthlyToDoCount = (result.Monthcount != null) ? result.Monthcount : 0;
        this.salesinfo.MonthlyToDoPercentage = (result.Percentage != null) ? result.Percentage.replace('%', '') : "0";
        this.salesinfo.MonthlyToDoPercentage = (this.salesinfo.MonthlyToDoPercentage != null) ? this.salesinfo.MonthlyToDoPercentage.split(".", 2)[0].toString() : "0";
        if (parseInt(this.salesinfo.MonthlyToDoPercentage) >= 0 && parseInt(this.salesinfo.MonthlyToDoPercentage) <= 25) {

          this.MonthlyToDoPercentageColor = "#FF0000";
        }
        else if (parseInt(this.salesinfo.MonthlyToDoPercentage) >= 26 && parseInt(this.salesinfo.MonthlyToDoPercentage) <= 75) {

          this.MonthlyToDoPercentageColor = "yellow";
        }
        else {

          this.MonthlyToDoPercentageColor = "#77C043";
        }

      }


    });
  }

  ViewMonthlyMeetingCompleted(loggedinuserid, monthName, year) {
    this.opprtunityservice.GetMonthlyMeetingCOmpleted(this.UserIds, monthName, year).then(result => {
      if (result && result != null) {
        this.salesinfo.MonthlyMeetingCompletedgoal = (result.Goal != null) ? result.Goal : 0;
        this.salesinfo.MonthlyMeetingCompletedCount = (result.Monthcount != null) ? result.Monthcount : 0;
        this.salesinfo.MonthlyMeetingCompletedPercentage = (result.Percentage != null) ? result.Percentage.replace('%', '') : "0";
        this.salesinfo.MonthlyMeetingCompletedPercentage = (this.salesinfo.MonthlyMeetingCompletedPercentage != null) ? this.salesinfo.MonthlyMeetingCompletedPercentage.split(".", 2)[0].toString() : "0";
        if (parseInt(this.salesinfo.MonthlyMeetingCompletedPercentage) >= 0 && parseInt(this.salesinfo.MonthlyMeetingCompletedPercentage) <= 25) {

          this.MonthlyMeetingCompletedPercentageColor = "#FF0000";
        }
        else if (parseInt(this.salesinfo.MonthlyMeetingCompletedPercentage) >= 26 && parseInt(this.salesinfo.MonthlyMeetingCompletedPercentage) <= 75) {

          this.MonthlyMeetingCompletedPercentageColor = "yellow";
        }
        else {

          this.MonthlyMeetingCompletedPercentageColor = "#77C043";
        }

      }
    });
  }
  ViewSalesInformation(loggedinuserid, monthName, year) {  
    this.opprtunityservice.GetAllViewSalesInfo(this.UserIds, monthName, year).then(result => {
      if (result) {
        this.salesinfo.Monthlycallscompleted = (result.Goal != null) ? result.Goal : 0;
        this.salesinfo.Monthlycallscount = (result.Monthcount != null) ? result.Monthcount : 0;
        this.salesinfo.MonthlycallsPercentage = (result.Percentage != null) ? result.Percentage.replace('%', '') : "0";
        this.salesinfo.MonthlycallsPercentage = (this.salesinfo.MonthlycallsPercentage != null) ? this.salesinfo.MonthlycallsPercentage.split(".", 2)[0].toString() : '0';

        if (parseInt(this.salesinfo.MonthlycallsPercentage) >= 0 && parseInt(this.salesinfo.MonthlycallsPercentage) <= 25) {

          this.MonthlycallsPercentageColor = "#FF0000";
        }
        else if (parseInt(this.salesinfo.MonthlycallsPercentage) >= 26 && parseInt(this.salesinfo.MonthlycallsPercentage) <= 75) {

          this.MonthlycallsPercentageColor = "yellow";
        }
        else {

          this.MonthlycallsPercentageColor = "#77C043";
        }
      }
    });

  }
  SalesWonopp(msg) {
    var salewon = msg.split(":")[0];
    var rrwon = msg.split(":")[1];
    var locale = "en-us";
    this.ViewSalesWOn(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.salesinfo.MonthlySalesWonCount = (salewon != null) ? '$' + salewon : '0';
    var obtained = parseInt(this.salesinfo.MonthlySalesWonCount.replace('$', ''));
    var total = parseInt(this.salesinfo.MonthlySaleswongoal.replace('$', ''), 10);
  
      this.salesinfo.MonthlySalesWonPercentage = obtained==0?'0':(obtained * 100 / total).toString();
    
    if (parseInt(this.salesinfo.MonthlySalesWonPercentage) >= 0 && parseInt(this.salesinfo.MonthlySalesWonPercentage) <= 25) {

      this.MonthlySalesWonPercentageColor = "#FF0000";
    }
    else if (parseInt(this.salesinfo.MonthlySalesWonPercentage) >= 26 && parseInt(this.salesinfo.MonthlySalesWonPercentage) <= 74) {

      this.MonthlySalesWonPercentageColor = "yellow";
    }
    else {
      this.MonthlySalesWonPercentageColor = "#77C043";
    }

    this.ViewSalesRR(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.salesinfo.MonthlySalesRRCount = (rrwon == null || rrwon != "NAN") ? "0" : '$' + rrwon.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1") + "";
    var obtainedRR = this.salesinfo.MonthlySalesRRCount == "0" ? 0 : parseInt(rrwon.replace('$', ''));
    var totalRR = parseInt(this.salesinfo.MonthlySalesRRgoal.replace('$', ''), 10);
    if (totalRR == 0) { this.salesinfo.MonthlySalesRRPercentage = '0'; } else {
      this.salesinfo.MonthlySalesRRPercentage = (obtainedRR * 100 / totalRR).toString();
    }
   
      if (parseInt(this.salesinfo.MonthlySalesRRPercentage.replace('$', '')) >= 0 && parseInt(this.salesinfo.MonthlySalesRRPercentage.replace('$', '')) <= 25) {
    
        this.MonthlySalesRRPercentageColor = "#FF0000";
      }
      else if (parseInt(this.salesinfo.MonthlySalesRRPercentage.replace('$', '')) >= 26 && parseInt(this.salesinfo.MonthlySalesRRPercentage.replace('$', '')) <= 74) {
     
        this.MonthlySalesRRPercentageColor = "yellow";
      }
      else {     
        this.MonthlySalesRRPercentageColor = "#77C043";
      }
  
  }
  ViewSalesWOn(loggedinuserid, monthName, year) {
    this.opprtunityservice.GetSalesWOn(this.UserIds, monthName, year).then(result => {
      if (result) {
        this.salesinfo.MonthlySaleswongoal = (result.Goal != null) ? '$' + result.Goal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1") + "" : '0';

      }

    });
  }
  ViewSalesRR(loggedinuserid, monthName, year) {
    if (this.UserIds != null) {
      this.opprtunityservice.GetSalesRR(this.UserIds, monthName, year).then(result => {
        if (result) {
          this.salesinfo.MonthlySalesRRgoal = (result.Goal != null) ? '$' + result.Goal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1") + "" : '0';

        }

      });
    }
  }
  ViewLeadsConvertedInformation(loggedinuserid, monthName, year) {
    if (this.UserIds != null) {
      this.opprtunityservice.GetAllViewLeadsConvertedInfo(this.UserIds, monthName, year).then(result => {
        if (result) {
          this.salesinfo.monthlyleadconvertedcount = (result.Goal != null) ? result.Goal : 0;
          this.salesinfo.leadconvertedcount = (result.Monthcount != null) ? result.Monthcount : 0;
          this.salesinfo.leadconvertedPercentage = (result.Percentage != null) ? result.Percentage.replace('%', '') : "0";
          this.salesinfo.leadconvertedPercentage = (this.salesinfo.leadconvertedPercentage != '0') ? this.salesinfo.leadconvertedPercentage.split(".", 2)[0].toString() : '0';
          if (parseInt(this.salesinfo.leadconvertedPercentage) >= 0 && parseInt(this.salesinfo.leadconvertedPercentage) <= 25) {

            this.leadconvertedPercentageColor = "#FF0000";
          }
          else if (parseInt(this.salesinfo.leadconvertedPercentage) >= 26 && parseInt(this.salesinfo.leadconvertedPercentage) <= 74) {

            this.leadconvertedPercentageColor = "yellow";
          }
          else {

            this.leadconvertedPercentageColor = "#77C043";
          }

        }

      });
    }
  }

  viewmonthlysalesRanking(loggedinuserid, month, year) {
    if (this.UserIds != null) {
      this.opprtunityservice.GetviewmonthlysalesRanking(this.currentuser.id, month, year).then((salesranking) => {
        this.Allsalesranking = salesranking;
      });
    } else {
      this.Allsalesranking = [];
    }
  }
  ViewMonthlyCallScheduledByMonth(loggedinuserid, month, year) {

    this.opprtunityservice.GetViewMonthlyCallScheduledByMonth(loggedinuserid, month, year).then((result) => {
      if (result) {
        this.salesinfo.MonthlycallsScheduled = (result.Goal != null) ? result.Goal : 0;
        this.salesinfo.MonthlycallsScheduledcount = (result.Monthcount != null) ? result.Monthcount : 0;
        this.salesinfo.MonthlycallsScheduledPercentage = (result.Percentage != null) ? result.Percentage.replace('%', '') : "0";
        this.salesinfo.MonthlycallsScheduledPercentage = (this.salesinfo.MonthlycallsScheduledPercentage != "0") ? this.salesinfo.MonthlycallsScheduledPercentage.split(".", 2)[0].toString() : "0";
        if (parseInt(this.salesinfo.MonthlycallsScheduledPercentage) >= 0 && parseInt(this.salesinfo.MonthlycallsScheduledPercentage) <= 25) {
          this.MonthlycallsScheduledPercentageColor = "#FF0000";
        }
        else if (parseInt(this.salesinfo.MonthlycallsScheduledPercentage) >= 26 && parseInt(this.salesinfo.MonthlycallsScheduledPercentage) <= 74) {
          this.MonthlycallsScheduledPercentageColor = "yellow";
        }
        else {
          this.MonthlycallsScheduledPercentageColor = "#77C043";
        }
      }

    });
  }

  ViewMonthlyPatDueActivityByMonth(loggedinuserid, month, year) {

    this.opprtunityservice.GetViewPastDueActivityByMonth(this.UserIds, month, year).then((result) => {

      if (result) {
        this.salesinfo.MonthlylastDueActivitygoal = (result.Goal != null) ? result.Goal : 0;
        this.salesinfo.MonthlylastDueActivityCount = (result.Monthcount != null) ? result.Monthcount : 0;
        this.salesinfo.MonthlylastDueActivityPercentage = (result.Percentage != null) ? result.Percentage.replace('%', '') : "0";
        this.salesinfo.MonthlylastDueActivityPercentage = (this.salesinfo.MonthlylastDueActivityPercentage != "0") ? this.salesinfo.MonthlylastDueActivityPercentage.split(".", 2)[0].toString() : '0';
        if (parseInt(this.salesinfo.MonthlylastDueActivityPercentage) >= 0 && parseInt(this.salesinfo.MonthlylastDueActivityPercentage) <= 25) {

          this.MonthlylastDueActivityPercentageColor = "#FF0000";
        }
        else if (parseInt(this.salesinfo.MonthlylastDueActivityPercentage) >= 26 && parseInt(this.salesinfo.MonthlylastDueActivityPercentage) <= 74) {

          this.MonthlylastDueActivityPercentageColor = "yellow";
        }
        else {

          this.MonthlylastDueActivityPercentageColor = "#77C043";
        }
      }

    });
    this.month = month;

  }

  ViewMonthlyAccountAddedByMonth(loggedinuserid, month, year) {
    this.opprtunityservice.GetViewMonthlyAccountAddedByMonth(this.UserIds, month, year).then((result) => {
      if (result) {
        this.salesinfo.MonthlyAccountAdded = (result.Goal != null) ? result.Goal : 0;
        this.salesinfo.MonthlyAccountsaddedcount = (result.Monthcount != null) ? result.Monthcount : 0;
        this.salesinfo.MonthlyAccountsaddedPercentage = (result.Percentage != null) ? result.Percentage.replace('%', '') : "0";
        this.salesinfo.MonthlyAccountsaddedPercentage = (this.salesinfo.MonthlyAccountsaddedPercentage != "0") ? this.salesinfo.MonthlyAccountsaddedPercentage.split(".", 2)[0].toString() : '0';
        if (parseInt(this.salesinfo.MonthlyAccountsaddedPercentage) >= 0 && parseInt(this.salesinfo.MonthlyAccountsaddedPercentage) <= 25) {

          this.MonthlyAccountsaddedPercentageColor = "#FF0000";
        }
        else if (parseInt(this.salesinfo.MonthlyAccountsaddedPercentage) >= 25 && parseInt(this.salesinfo.MonthlyAccountsaddedPercentage) <= 74) {

          this.MonthlyAccountsaddedPercentageColor = "yellow";
        }
        else {

          this.MonthlyAccountsaddedPercentageColor = "#77C043";
        }
      }

    });
  }
  LoadActivityData(startdate, enddate) {
    this.opprtunityservice.GetActivitysummaryData(this.UserIds, startdate, enddate).then((salesranking) => {
      this.AllActivityData = salesranking;
    });
  }
  LoadClosedTicketsRanking(startdate, enddate) {
    this.opprtunityservice.GetClosedTicketsRanking(this.UserIds, this.selectedTab == 'tab4' ? 2 : 8, startdate, enddate).then((rankingdata) => {
      this.closedRankingData = rankingdata;
    });
  }
  loadallcustomers(loggedinuserid) {
    var labelArr = [];
    var labelArr1 = [];
    var data = [];
    this.opprtunityservice.GetAllCustomersSales(this.UserIds, this.startDateVal, this.endDateVal).then((account) => {
      account.forEach(account => {
        //var newArrayIs = { name: account.accountname, percentage: '$ ' + account.percentage.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + ".00" }
        var newArrayIs = { name: account.accountname, percentage: '$' + parseInt(account.percentage).toFixed(2) }
        labelArr1.push(newArrayIs);
        labelArr.push(account.accountname);
        //data.push(55);
        data.push(parseInt(account.percentage));
      });
      this.customerpieChartLabels = labelArr;     
      this.customerpieChartLabels1 = labelArr1;
      let selfobj = this;
      $(setTimeout(function () {
        selfobj.customerpieChartData = data;
      }, 200));

    });

    this.opprtunityservice.GetOpenOpportunitiestotal(loggedinuserid, 0).then((account) => {
      account.forEach(account => {
        //this.OpenOppTotal = '$' + account.OpenOppTotal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        this.OpenOppTotal = "$" + parseInt(account.OpenOppTotal).toFixed(2);
      });
    });
  }


  LoadyeartodateSalesBymonth(loggedinuserid, selectedyear) {
    var bardata = [];
    this.opprtunityservice.GetYearToSalesDataByMonth(this.UserIds, selectedyear).then((salesranking) => {
      this.AllyeartoSalesData = salesranking;
      bardata.push(this.AllyeartoSalesData[0].JanPercenatge, this.AllyeartoSalesData[0].FebPercenatge, this.AllyeartoSalesData[0].MarPercenatge, this.AllyeartoSalesData[0].AprilPercenatge, this.AllyeartoSalesData[0].MayPercenatge, this.AllyeartoSalesData[0].JunPercenatge, this.AllyeartoSalesData[0].JulPercenatge, this.AllyeartoSalesData[0].AugPercenatge, this.AllyeartoSalesData[0].SepPercenatge, this.AllyeartoSalesData[0].OctPercenatge, this.AllyeartoSalesData[0].NovPercenatge, this.AllyeartoSalesData[0].DecPercenatge)
      this.barChartData = [{ data: bardata, label: 'Sales BY Month', backgroundColor: "#ccc", fontSize: '2px' }];
      this.AllyeartoSalesDataTotal = salesranking;
      //this.YearToDateTotal = '$' + this.AllyeartoSalesDataTotal[0].YeearToDateTotal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
      this.YearToDateTotal = '$' + parseInt(this.AllyeartoSalesDataTotal[0].YeearToDateTotal).toFixed(2)
    });

    //this.opprtunityservice.GetYearToSalesDataByMonth(loggedinuserid, this.startDateVal.split('-')[0]).then((Salesranking) => {
    //  this.AllyeartoSalesDataTotal = Salesranking;
    //  this.YearToDateTotal = '$' + this.AllyeartoSalesDataTotal[0].YeearToDateTotal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    //});



  }

  NBDSpecilaistSalesBymonth(loggedinuserid, selectedyear) {
    var NBDdata = [], NBDdata1 = [];
    this.opprtunityservice.GetYearToDateActivity(this.UserIds, selectedyear).then((AllyeartodataActivity) => {
      AllyeartodataActivity.forEach(salesranking => {
      });
      this.AllyeartodataActivity = AllyeartodataActivity;
      for (var i = 0; i < AllyeartodataActivity.length; i++) {
        if (i == 0) {
          NBDdata.push(AllyeartodataActivity[i].JanPercenatge, AllyeartodataActivity[i].FebPercenatge, AllyeartodataActivity[i].MarPercenatge, AllyeartodataActivity[i].AprilPercenatge, AllyeartodataActivity[i].MayPercenatge, AllyeartodataActivity[i].JunPercenatge, AllyeartodataActivity[i].JulPercenatge, AllyeartodataActivity[i].AugPercenatge, AllyeartodataActivity[i].SepPercenatge, AllyeartodataActivity[i].OctPercenatge, AllyeartodataActivity[i].NovPercenatge, AllyeartodataActivity[i].DecPercenatge)
        } else {
          NBDdata1.push(AllyeartodataActivity[i].JanPercenatge, AllyeartodataActivity[i].FebPercenatge, AllyeartodataActivity[i].MarPercenatge, AllyeartodataActivity[i].AprilPercenatge, AllyeartodataActivity[i].MayPercenatge, AllyeartodataActivity[i].JunPercenatge, AllyeartodataActivity[i].JulPercenatge, AllyeartodataActivity[i].AugPercenatge, AllyeartodataActivity[i].SepPercenatge, AllyeartodataActivity[i].OctPercenatge, AllyeartodataActivity[i].NovPercenatge, AllyeartodataActivity[i].DecPercenatge)
        }
      }
      this.NBDSpecialistbarChartData = [
        { data: NBDdata, label: 'Leads Converted', backgroundColor: "#ccc" },
        { data: NBDdata1, label: 'Phone Calls Completed	', backgroundColor: "rgb(202, 90, 78)" }
      ];
    });
  }

  initializeAgGrid() {
    $('.ag-tool-panel').attr('style', 'display:none');
    this.SalesRankinggridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.useractivitygridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000,enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };

    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 50).then((config) => {

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(columnSelections);

    });

    let useractivitycolumnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 51).then((config) => {

      useractivitycolumnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.useractivitycreateColumndef(useractivitycolumnSelections);

    });
    this.CreateclosedRankingColumndef();
  }
  CreateclosedRankingColumndef() {
    this.closedRankingcolumnDefs = [
      { field: 'username', headerName: 'User Name', hide: false },
      { field: 'TicketClosed', headerName: 'TicketsClosed', hide: false }
    ];
  }
  useractivitycreateColumndef(columnSelections: string) {
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      this.useractivitycolumnDefs = [];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        this.useractivitycolumnDefs.push({
          field: fieldHeaderVisibleWidthArray[0],
          headerName: fieldHeaderVisibleWidthArray[1],
          hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
          width: parseInt(fieldHeaderVisibleWidthArray[3])
        });

      }
    } else {
      this.useractivitycolumnDefs = [
        { field: 'username', headerName: 'User Name', hide: false },
        { field: 'CallCompleted', headerName: 'CallCompleted', hide: false },
        { field: 'LeadConverted', headerName: 'LeadConverted', hide: false },
      ];
    }
  }


  createColumnDefs(columnSelections: string) {
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      this.columnDefs = [];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        this.columnDefs.push({
          field: fieldHeaderVisibleWidthArray[0],
          headerName: fieldHeaderVisibleWidthArray[1],
          hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
          width: parseInt(fieldHeaderVisibleWidthArray[3])
        });

      }
    } else {

      this.columnDefs = [
        { field: 'SalesRep', headerName: 'Sales Rep', hide: false, width: 120 },
        { field: 'Software', headerName: 'Price', hide: false, width: 120 },
        { field: 'RR', headerName: 'Monthly', hide: false },
      ];
    }
  }

  onAgGridReady(event) {
    //// $('.ag-tool-panel').attr('style', 'display:none');
    // let selfObj = this;
    // $(setTimeout(function () {
    //   selfObj.autoSizeAll();
    //   $(setTimeout(function () {
    //     selfObj.autoSizeAll();
    //   }, 100));
    // }, 100));
  }
  onTaskAgGridReady(event) {
    $('.ag-tool-panel').attr('style', 'display:none');
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizetaskhistoryAll();
    }, 100));
  }
  autoSizetaskhistoryAll() {
    if (this.TomorrowActivitygridOptions && this.TomorrowActivitygridOptions.columnApi && this.TomorrowActivitygridOptions.columnApi.getAllColumns() != undefined) {
      var allColumnIds = [];
      this.TomorrowActivitygridOptions.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });
      this.TomorrowActivitygridOptions.columnApi.autoSizeColumns(allColumnIds);
    }
  }
  //autoSizeAll() {
  //  if (this.gridOptions && this.gridOptions.columnApi && this.gridOptions.columnApi.getAllColumns() != undefined) {
  //    var allColumnIds = [];
  //    this.gridOptions.columnApi.getAllColumns().forEach((column) => {
  //      allColumnIds.push(column.getColId());
  //    });
  //    this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
  //  }
  //}
  ChangeDashboard(val) {
  }
  currenttab(tab) {
    if (tab == 'tab2' || tab == 'tab3') {
      this.displayDiv = true
    } else {
      this.displayDiv = false;
    }
    return $('input[id=' + tab + ']').is(':checked');

  }
  onItemSelect(item: any) {
    this.MultiselectDropDownChanged = true;
    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    var s = $("#userids").val();
    while (s.charAt(0) === ',') {
      s = s.substr(1);
    }
    $("#userids").val(s);
    var SelectedUsers = [];
    SelectedUsers = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    if (SelectedUsers && SelectedUsers.length > 0) {
      SelectedUsers.push(item)
    } else {
      SelectedUsers.push(item);
    }
    //localStorage.setItem('SelectedUserIds', JSON.stringify(SelectedUsers));
    this.UserIds = $("#userids").val();
    this.GenerateUserAccess(this.UserIds);

    //this.applicationComponent.selectedItems = this.selectedItems;

    if (this.selectedMenuRadio == "tab4" || this.selectedMenuRadio == "tab5") {
      this.DashboardChanged(this.selectedMenuRadio)
      this.ZendeskComp.LoadTickets("", "", "");
    } else if (this.selectedMenuRadio == "tab7") {
      this.DashboardChanged(this.selectedMenuRadio)
      this.loadRevenue();
      this.SalesOpportunities('2021-11', 'Won Opportunities')
    }
    else if (this.selectedMenuRadio == "tab8") {
      this.DashboardChanged(this.selectedMenuRadio)
      this.LoadCampaigns(this.UserIds, this.monthNumToName(this.currentMonth), this.currentYear);
      this.campDashComp.initializeAgGrid(this.UserIds);
    }
    else {
      this.DashboardChanged(this.selectedMenuRadio);
      if (this.Proposalcomp) {
        this.Proposalcomp.GetAllProposal(this.UserIds);
      }
      if (this.LeadsDashBoardComp) {
        this.LeadsDashBoardComp.ClosedLeads('Open', $("#userids").val(), null, 1, 10);
      }
    }
    $('#cancelDiv').attr('style', 'display:block;');
    $('#SaveDiv').attr('style', 'display:block;');
    this.Generate(this.UserIds);

  }
  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.MultiselectDropDownChanged = true;
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.GenerateUserAccess($("#userids").val());

      var SelectedUsers = [];
      SelectedUsers = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
      if (SelectedUsers && SelectedUsers.length > 0) {
        SelectedUsers = SelectedUsers.filter(x => x.id != item.id)
      } else {
        SelectedUsers.push(item);
      }
      this.UserIds = $("#userids").val();
      if (this.selectedMenuRadio == "tab4" || this.selectedMenuRadio == "tab5") {
        this.ZendeskComp.LoadTickets("", "", "");
        this.DashboardChanged(this.selectedMenuRadio)
      }
      else if (this.selectedMenuRadio == "tab8") {
        this.DashboardChanged(this.selectedMenuRadio)
        this.LoadCampaigns(this.UserIds, this.monthNumToName(this.currentMonth), this.currentYear);
        this.campDashComp.initializeAgGrid(this.UserIds);
      }
      else {
        this.DashboardChanged(this.selectedMenuRadio);
        if (this.Proposalcomp) {
          this.Proposalcomp.GetAllProposal($("#userids").val());
        }
        if (this.LeadsDashBoardComp) {
          this.LeadsDashBoardComp.ClosedLeads('Open', $("#userids").val(), null, 1, 10);
        }
      }
      $('#cancelDiv').attr('style', 'display:block;');
      $('#SaveDiv').attr('style', 'display:block;');

      var res = $("#UserList .dropdown-list").prop("hidden");
      if (res == true) {

        this.SaveUserListConfirmation = true;
        this.SaveUserListConfirmationCss();
        //$("#UserList .dropdown-list").attr("hidden", false);

      }
      this.Generate($("#userids").val());

    }
    else {
      $("#userids").val('');
      //localStorage.setItem('SelectedUserIds', null); 
      this.finalitems = "";
      this.UserIds = null;
      this.UserIdsCount = 0;
      this.applicationComponent.selectedItems = null;
      if (this.selectedMenuRadio == "tab4" || this.selectedMenuRadio == "tab5") {
        this.ZendeskComp.onTicketTypeChange('Closed Tickets');
        this.ZendeskComp.LoadTickets("", "", "");
        this.ZendeskComp.Userlist = [];
        this.ZendeskComp.assignedTo = null;
        this.ZendeskComp.dashticketType = null;
      } else {
        if (this.Proposalcomp) {
          this.Proposalcomp.GetAllProposal(0);
        }
        if (this.LeadsDashBoardComp) {
          this.LeadsDashBoardComp.ClosedLeads('Open', 0, null, 1, 10);
        }


      }
      $('#cancelDiv').attr('style', 'display:block;');
      $('#SaveDiv').attr('style', 'display:block;');
      var res = $("#UserList .dropdown-list").prop("hidden");
      if (res == true) {
        this.SaveUserListConfirmation = true;
        this.SaveUserListConfirmationCss();
        //$("#UserList .dropdown-list").attr("hidden", false);

      }
      this.Generate(null);
      this.unReadMsgcomponent.getallmessages(this.UserIds);

    }
    this.DashboardChanged(this.selectedMenuRadio);
  }
  onSelectAll(items) {
    var ids = "";
    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.MultiselectDropDownChanged = true;

    //localStorage.setItem('SelectedUserIds', JSON.stringify(items));
    this.UserIds = $("#userids").val();
    this.GenerateUserAccess($("#userids").val());


    if (this.selectedMenuRadio == "tab8") {
      this.DashboardChanged(this.selectedMenuRadio)
      this.LoadCampaigns(this.UserIds, this.monthNumToName(this.currentMonth), this.currentYear);
      this.campDashComp.initializeAgGrid(this.UserIds);
    }

    //this.applicationComponent.selectedItems = this.selectedItems;

    //if (this.selectedMenuRadio == "tab4" || this.selectedMenuRadio == "tab5") {
    //  this.ZendeskComp.LoadTickets();
    //} else {
    //  this.Proposalcomp.GetAllProposal($("#userids").val());
    //  this.LeadsDashBoardComp.ClosedLeads('Open', $("#userids").val(), null, 1, 10);
    //}
    $('#cancelDiv').attr('style', 'display:block;');
    $('#SaveDiv').attr('style', 'display:block;');
    this.Generate($("#userids").val());
    this.DashboardChanged(this.selectedMenuRadio);
  }
  onDeSelectAll(items: any) {

    $("#userids").val("");
    localStorage.setItem('SelectedUserIds', null);
    this.applicationComponent.selectedusers = null;
    this.finalitems = "";
    this.UserIds = null;
    this.UserIdsCount = 0;
    this.MultiselectDropDownChanged = true;
    this.applicationComponent.selectedItems = null;
    //if (this.selectedMenuRadio == "tab4" || this.selectedMenuRadio == "tab5") {
    //  this.ZendeskComp.LoadTickets();
    //  this.ZendeskComp.Userlist = [];

    //} else {
    //  this.Proposalcomp.GetAllProposal(0);
    //  this.LeadsDashBoardComp.ClosedLeads('Open', 0, null, 1, 10);
    //}
    $('#cancelDiv').attr('style', 'display:block;');
    $('#SaveDiv').attr('style', 'display:block;');
    var res = $("#UserList .dropdown-list").prop("hidden");
    if (res == true) {

      this.SaveUserListConfirmation = true;
      this.SaveUserListConfirmationCss();
      //$("#UserList .dropdown-list").attr("hidden", false);

    }
    this.UserIds = $("#userids").val();
    if (this.selectedMenuRadio == "tab8") {
      this.DashboardChanged(this.selectedMenuRadio)
      this.LoadCampaigns(this.UserIds, this.monthNumToName(this.currentMonth), this.currentYear);
      this.campDashComp.initializeAgGrid(this.UserIds);
    }


    this.DashboardChanged(this.selectedMenuRadio);
    this.Generate(null);
    this.unReadMsgcomponent.getallmessages(this.UserIds);

  }
  Generate(userids) {
    if (userids != null) {
      var format = /,/;
      if (format.test(userids)) {

        this.UserIdsCount = userids.split(',').length;
      } else { this.UserIdsCount = 1; }
      this.UserIds = userids;

      this.UserIds = userids;
      var locale = "en-us";
    }
    this.applicationComponent.selectedusers = userids;
    var doc = document.getElementById("Inactive");
    this.checkvalues('Open Tasks', userids);
    this.refresh();
    var doc1 = document.getElementById("Inactive");
    this.YeartoDateActivity(userids);
    this.ViewMonthlyNewTickets(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.ViewMonthlyClosedTickets(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.ViewMonthlyPatDueActivityByMonth(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.ViewMonthlyMeetingCompleted(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.ViewMonthlyToDoCompleted(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.ViewSalesInformation(userids, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.viewmonthlysalesRanking(this.UserIds, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.ViewLeadsConvertedInformation(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.loadallcustomers(userids);

    this.loadpastdueActivitiesdata(this.UserIds, 'PastDue', this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.ViewMonthlyAccountAddedByMonth(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.ViewMonthlyCallScheduledByMonth(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    var moment = require('moment-timezone');
    this.SalesbarChartLabels = moment.localeData().monthsShort();
    this.NBDSpecialistSalesbarChartLabels = moment.localeData().monthsShort();
    this.ViewSalesWOn(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.ViewSalesRR(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());

    this.LoadyeartodateSalesBymonth(this.UserIds, (new Date()).getFullYear());
    this.NBDSpecilaistSalesBymonth(this.UserIds, (new Date()).getFullYear());
    var startOfWeek = moment().startOf('week').toDate();
    var endOfWeek = moment().endOf('week').toDate();

    var startDateVal = this.datePipe.transform(startOfWeek, 'yyyy-MM-dd');
    var endDateVal = this.datePipe.transform(endOfWeek, 'yyyy-MM-dd');
    this.LoadAccountAddedData(startDateVal, endDateVal);

    let selfObj = this;
    $(setTimeout(function () {
      //selfObj.tomoactcomponent.loadtomorrowActivitiesdata(selfObj.UserIds, "Tomorrow", selfObj.month, selfObj.currentYear);
      if (selfObj.unReadMsgcomponent != undefined) {
        selfObj.unReadMsgcomponent.getallmessages(selfObj.UserIds);
      }

    }, 100));
    $(setTimeout(function () {
      selfObj.loadpastdueActivitiesdata(selfObj.UserIds, "PastDue", selfObj.month, selfObj.currentYear);
    }, 100));
    //document.getElementById("bchart").style.height = '105px !important';
  }
  dateChange(event) {
    this.AssignStartAndEndDate(event);
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
    this.GenerateUserAccess($("#userids").val());
    this.Generate($("#userids").val());

  }
  messageClose() {
    //document.getElementById('selectedmessagediv').style.display = 'none';

  }
  onReceiveMessage(msg) {
    if (msg == '0') {
      this.messageOpen = false;

    } else if (msg.indexOf("Load") >= 0) {
      this.UnreadMessageCount = msg.replace("Load", "");
    }
    else if (msg == 'Expand') {
      this.CalendarTomorrowActDiv = false;
      this.calendarpastDiv = false
      this.fullcalendar = false;//for tab2 & 3
      this.intmsgdiv = true;//for tab2 & 3
      this.saleRankingDiv = false;//for tab2 & 3
     
      $('#intmsgdiv').removeClass("col-md-6 col-sm-12 col-xs-12");//for tab2 & 3
      $('#intmsgdiv').addClass("col-md-12 col-sm-12 col-xs-12");//for tab2 & 3
      $('#intmsgdiv').attr("style", "padding:0;height:100%;");//for tab2 & 3

    } else if (msg == 'collapse') {
      this.CalendarTomorrowActDiv = true;
      this.calendarpastDiv = true;
      this.internalmessagediv = true;
      this.fullcalendar = true;//for tab2 & 3
      this.intmsgdiv = true;//for tab2 & 3
      this.saleRankingDiv = true;//for tab2 & 3
      //$('#MsgCalendarDiv').attr("style", "height:50%;padding: 0;margin-top: 1%");
      $('#internalmessagediv').removeClass("col-md-12 col-sm-12 col-xs-12");
      $('#internalmessagediv').addClass("col-md-6 col-sm-12 col-xs-12");
      $('#intmsgdiv').removeClass("col-md-12 col-sm-12 col-xs-12");//for tab2 & 3
      $('#intmsgdiv').addClass("col-md-6 col-sm-12 col-xs-12");//for tab2 & 3
    //  $('#msgSalesDiv').attr("style", "height:100%;padding: 0;");//for tab2 & 3
      $('#intmsgdiv').attr("style", "padding:0;padding-right:1.8%;height:100%;");//for tab2 & 3

      //this.OpenCompletedButtons();
    }
    else {
      this.messageOpen = true;
      this.messagingservice.GetMessageById(msg).then(result => {
        this.message = result;
      });
    }
  }

  expandcalendarpast(val) {
    if (val == 'Collapase') {
      this.showExpandGrid = false;
    } else {
      this.showExpandGrid = true;
      this.GridCss();
    }

  }
  GridCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 45%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px!important;margin-right: 1%;');
    }, 4));
  }
  AssignStartAndEndDate(event) {
    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  previousYear(id) {
    var idArray = id.split('_');
    var year = Number($('#' + idArray[0]).html());
    year--;
    $('#' + idArray[0]).html(year.toString())
  }

  nextYear(id) {
    var idArray = id.split('_');
    var year = Number($('#' + idArray[0]).html());
    year++;
    $('#' + idArray[0]).html(year.toString())
  }
  selectedMonth(monthNo, monthName, yearId, claenderId, monthDisplay) {
    $('#' + monthDisplay).html(monthName);
    document.getElementById(claenderId).style.display = 'none';
    $('.' + claenderId).removeClass('monthSelectedView');
    $('.' + claenderId).addClass('monthView');
    $('#' + monthNo + '_' + claenderId).removeClass('monthView');
    $('#' + monthNo + '_' + claenderId).addClass('monthSelectedView');
    if (claenderId == 'calernderCall') {
      this.ViewSalesInformation(this.currentuser.id, monthName, (new Date()).getFullYear())
    } else {
      this.ViewLeadsConvertedInformation(this.currentuser.id, monthName, (new Date()).getFullYear())
    }
  }
  callCalenderOpen(claenderId) {
    document.getElementById(claenderId).style.display = 'block';
  }
  CallCompletedByMonth(val) {
    this.CallsCompletedLabel = moment(val).format('MMM, YYYY');
    this.ViewSalesInformation(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0])
  }
  NewTicketAddedByMonth(val) {
    this.NewTicketsLabel = moment(val).format('MMM, YYYY');
    this.ViewMonthlyNewTickets(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0])
  }
  ClosedTicketsByMonth(val) {
    this.ClosedTicketsLabel = moment(val).format('MMM, YYYY');
    this.ViewMonthlyClosedTickets(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0])
  }
  LeadsConvertedByMonth(val) {
    this.LeadsConvertedLabel = moment(val).format('MMM, YYYY');
    this.ViewLeadsConvertedInformation(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0])
  }
  moonthranking(val) {
    this.viewmonthlysalesRanking(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0]);
  }
  MonthlyCallsScheduledByMonth(val) {
    this.ViewMonthlyCallScheduledByMonth(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0]);
  }
  MonthlyMeetingCompletedByMonth(val) {
    this.MonthlyMeetingLabel = moment(val).format('MMM, YYYY');
    this.ViewMonthlyMeetingCompleted(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0]);
  }
  MonthlyToDoCompletedByMonth(val) {
    this.MonthlyToDoLabel = moment(val).format('MMM, YYYY');
    this.ViewMonthlyToDoCompleted(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0]);
  }
  MonthlyAccountAddedByMonth(val) {
    this.MonthlyAccountsLabel = moment(val).format('MMM, YYYY');
    this.ViewMonthlyAccountAddedByMonth(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0]);
  }
  PatDueActivityByMonth(val) {
    this.loadpastdueActivitiesdata(this.UserIds, 'PastDue', this.monthNumToName(val.split('-')[1]), val.split('-')[0])
    this.ViewMonthlyPatDueActivityByMonth(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0]);
  }
  MonthlySalesRR(val) {
    this.MonthlySalesRRLabel = moment(val).format('MMM, YYYY');
    this.ViewSalesRR(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0]);
  }
  MonthlySalesWon(val) {
    this.MonthlyMeetingLabel = moment(val).format('MMM, YYYY');
    this.SalesOpportunities(val, 'Won Opportunities');
    this.ViewSalesWOn(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0]);

  }
  YeartoDateActivity(val) {
    this.NBDSpecilaistSalesBymonth(this.UserIds, val);
  }
  loadpastdueActivitiesdata(userids, source, month, selectedyear) {
    if (userids != null) {
      this.leadservice.GetTomorrowActivityDetails(userids, source, month, selectedyear).then((result) => {
        result.forEach(pastDueactivitytaskhistory => {
          pastDueactivitytaskhistory.datecreated = this.datePipe.transform(pastDueactivitytaskhistory.datecreated, "MM-dd-yyyy");
          pastDueactivitytaskhistory.estcompleteddate = this.datePipe.transform(pastDueactivitytaskhistory.estcompleteddate, "MM-dd-yyyy");
        });
        this.pastDueactivitytaskhistory = result;  
        var paskdueId = Number(localStorage.getItem('openPastDueId'));
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.PastDueActivitygridOptions.api.forEachNode((node) => {
            if (node.data.id == paskdueId) {
              selfObj.PastDueActivitygridOptions.api.selectNode(node, true);
              selfObj.PastDueActivitygridOptions.rowSelection = 'single';
            }
          });
        }, 300));
        this.salesinfo.MonthlylastDueActivityCount = this.pastDueactivitytaskhistory.length;
      });
    }
    else {
      this.pastDueactivitytaskhistory = [];
    }
  }
  public monthNumToName(monthnum) {
    var months = [
      'January', 'February', 'March', 'April', 'May',
      'June', 'July', 'August', 'September',
      'October', 'November', 'December'
    ];
    return months[monthnum - 1] || '';
  }

  OpenOpportunities() {
    this.applicationComponent.activateMainButtons('opportunities');
  }
  ClosedWonOpportunities() {
    this.router.navigateByUrl(`/application/opportunitiesdashboard/0/0/null/null`);
    this.applicationComponent.ClosedOpportunity("Closed", this.currentuser.id);
  }

  SelectedYear(yearval) {
    this.LoadyeartodateSalesBymonth(this.currentuser.id, yearval);
  }

  DashboardChanged(selectedtab) {
  
  this.expand = false;
    localStorage.setItem('dashboardTab', selectedtab);
    var locale = "en-us";
    this.getYear();
    this.initializeAgGrid();
    this.selectedTab = selectedtab;
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
    if (selectedtab == 'tab2') {
      var salesDashboardval = localStorage.getItem('salesDashboardGrid');
      if (salesDashboardval != null) {
        this.expandgrid(salesDashboardval, 'Expand', true)
      }
      this.viewmonthlysalesRanking(this.UserIds, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
      this.LoadyeartodateSalesBymonth(this.currentuser.id, (new Date()).getFullYear());
      this.loadRevenue();
      this.loadallcustomers(this.UserIds);
    }
    else if (selectedtab == 'tab3') {
      var nbdDashboardval = localStorage.getItem('nbdDashboardGrid');
      if (nbdDashboardval != null) {
        this.expandgrid(nbdDashboardval, 'Expand', true)
      }
      this.NBDSpecilaistSalesBymonth(this.UserIds, (new Date()).getFullYear());
      this.loadRevenue();
      this.LoadActivityData(moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD"));
      this.IntializeaccountGrid();
      this.customerService.GetSpecialityList("AccountReport", this.currentuser.id).then((accdropdown) => {
        this.ActivityDropdowns = accdropdown;
      });
    }
    else if (selectedtab == 'tab4' || selectedtab == 'tab5') {
      if (selectedtab == 'tab5') {
        var supportDashboardval = localStorage.getItem('supportDashboardGrid');
        if (supportDashboardval != null) {
          this.expandgrid(supportDashboardval, 'Expand', true)
        }
      } else {
        var trainerDashboardGridval = localStorage.getItem('trainerDashboardGrid');
        if (trainerDashboardGridval != null) {
          this.expandgrid(trainerDashboardGridval, 'Expand', true)
        }
      }
      this.TicketStartDate = moment(firstDay).format("MM-DD-YYYY");
      this.TicketEndDate = moment(lastDay).format("MM-DD-YYYY");
      this.applicationComponent.searchCriteriadata = null;
      this.LoadClosedTicketsRanking(moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD"));
      this.ZendeskComp.GetAllZendeskUers('All Tasks Groups and Users', 'none');  
    }
    else if (selectedtab == 'tab6') {
      var projectDashboardval = localStorage.getItem('projectDashboardGrid');
      if (projectDashboardval != null) {
        this.expandgrid(projectDashboardval, 'Expand', true)
      }
      this.LoadProjectInfo(this.UserIds, this.monthNumToName(this.currentMonth), this.currentYear);

    } else if (selectedtab == 'tab7') {
      this.loadRevenue();
      this.initializeOpenLeadsAgGrid();

    } else if(selectedtab == 'tab8') {
      this.LoadCampaigns(this.UserIds, this.monthNumToName(this.currentMonth), this.currentYear);

    }
    else if (selectedtab == 'tab1') {
      var mydashboardval = localStorage.getItem('myDashExpandGrid');
      if (mydashboardval != null) {
        this.expandgrid(mydashboardval, 'Expand', true)
      }
      this.loadCustomerActivityDrp();
      setTimeout(function () {
        $(".fc-list-event-title > a").each(function (index) {
          if ($(this).text().indexOf("</div>") > -1) {
            $(this).html($(this).text());
          }
        });

      }, 500);
      
    }

    this.onReceiveMessage('collapse');
    if (this.applicationComponent.selectedItems.length == 0) {
      if (this.Proposalcomp) {
        this.Proposalcomp.GetAllProposal(0);
      }
      if (this.LeadsDashBoardComp) {
        this.LeadsDashBoardComp.ClosedLeads(null, 0, null, 1, 10);
      }


    }

    this.EXpandCalender = false;
  }
  LoadProjectInfo(userid, mon, year) {
    this.opprtunityservice.GetProjectDashboardInfo(userid, mon, year).then((result) => {
      this.projInfo = result;

    });
  }
  LoadCampaigns(userid, mon, year) {
    this.opprtunityservice.GetexpensetotalprofitInfo(userid, mon, year).then((result) => {
      this.campaigninfo = result;
      this.campaigninfo.OpenCampaignProfitLoss =  (Number(this.campaigninfo.OpenCampaignProfitLoss) -
        Number(this.campaigninfo.OpenCampaignTotalExpenses)).toString();
    });
  }


  IntializeaccountGrid() {
    this.AccountgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 46).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.AccountgridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = columnSelections;
      this.createAccountsColumnDefs(columnSelections);
    });
  }
  createAccountsColumnDefs(columnSelections: string) {
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      this.AccontcolumnDefs = [];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        this.AccontcolumnDefs.push({
          field: fieldHeaderVisibleWidthArray[0],
          headerName: fieldHeaderVisibleWidthArray[1],
          hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
          width: parseInt(fieldHeaderVisibleWidthArray[3])
        });

      }
    } else {
      let so = this;
      this.AccontcolumnDefs = [
        { field: 'id', headerName: 'id', hide: true, width: 120, },
        { field: 'accountname', headerName: 'Account Name', hide: false, width: 120, cellStyle: function (params) { if (params.data.isshared == 1) { return { color: '#77C043' }; } } },
        {
          field: 'dateadded', headerName: 'Date Added', hide: false, width: 120, cellStyle: function (params) {
            if (params.data.isshared == 1 && params.data.ownerid == selfObj.currentuser.id) { return { color: '#77C043' }; } else if (params.data.isshared == 1 && params.data.ownerid != selfObj.currentuser.id) { return { color: '#003366' }; }
          }, cellRenderer: (params) => {
            return moment(params.value, ['DD-MM-YYYY', 'MM-DD-YYYY']).format('MM-DD-YYYY');
          }
        },
        { field: 'time', headerName: 'Time Added', hide: false, cellStyle: function (params) { if (params.data.isshared == 1) { return { color: '#77C043' }; } } },
        
        { field: 'owner', headerName: 'User', hide: false, width: 120, cellStyle: function (params) { if (params.data.isshared == 1) { return { color: '#77C043' }; } } },
      ];
    }

  }

  LoadAccountAddedData(startDateVal, endDateVal) {
    this.opprtunityservice.GetAccountsData(this.UserIds, startDateVal, endDateVal).then((accountdata) => {
      this.AllAccountData = accountdata;
      if (localStorage.getItem('RhxAccountVal') != null && localStorage.getItem('RhxAccountVal') != undefined) {
        var accountid = Number(localStorage.getItem('RhxAccountVal'));
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.AccountgridOptions.api.forEachNode((node) => {
            if (node.data.id == accountid) {
              selfObj.AccountgridOptions.api.selectNode(node, true);
              selfObj.AccountgridOptions.rowSelection = 'single';
            }
          });
        }, 200));
      }
    });

  }
  columnVisible(event) {
    this.saveNBDAccountConfiguration(46);
  }
  dragStopped(event) {
    this.saveNBDAccountConfiguration(46);
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveNBDAccountConfiguration(46);
  }

  pastDueActivitycolumnVisible(event) {
    this.savePastDueActivityConfiguration(52);
  }
  pastDueActivitydragStopped(event) {
    this.savePastDueActivityConfiguration(52);
  }
  pastDueActivitycolumnResized(event) {
    if (event.finished == true)
      this.savePastDueActivityConfiguration(52);
  }

  SalesRankingcolumnVisible(event) {
    this.saveNBDAccountConfiguration(50);
  }
  SalesRankingdragStopped(event) {
    this.saveNBDAccountConfiguration(50);
  }
  SalesRankingcolumnResized(event) {
    this.saveNBDAccountConfiguration(50);
  }
  AllActivitycolumnVisible(event) {
    this.saveNBDAccountConfiguration(51);
  }
  AllActivitydragStopped(event) {
    this.saveNBDAccountConfiguration(51);
  }
  AllActivitycolumnResized(event) {
    this.saveNBDAccountConfiguration(51);
  }
  savePastDueActivityConfiguration(resourceid) {

    let allCols: Column[] = this.PastDueActivitygridOptions.columnApi.getAllGridColumns();;
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 25;
    else
      config.maxlimit = Number(this.pageSize);
    config.columnsselections = "";
    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = resourceid;

    config.userid = "";
    for (let i = 0; i < this.applicationComponent.selectedItems.length; i++) {
      config.userid += this.applicationComponent.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);
  }
  saveNBDAccountConfiguration(resourceid) {
    let allCols: Column[] = (resourceid == 46) ? this.accountGridColumnApi.getAllGridColumns() : (resourceid == 50) ? this.salesRankingGridColumnApi.getAllGridColumns() : this.userActivityGridColumnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 25;
    else
      config.maxlimit = Number(this.pageSize);
    config.columnsselections = "";
    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = resourceid;

    config.userid = "";
    for (let i = 0; i < this.applicationComponent.selectedItems.length; i++) {
      config.userid += this.applicationComponent.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  PastDueActivityrowDoubleClickAgGrid(rowEvent) {
    localStorage.setItem('openPastDueId', rowEvent.data.id);
    var message = rowEvent.data.contactid + '|' + rowEvent.data.id + '|' + rowEvent.data.type;
    this.onTaskOpen(message);
  }
  onAccountAgGridReady(event) {
    this.accountGridColumnApi = event.columnApi;
  }
  onSalesRankingAgGridReady(event) {
    this.salesRankingGridColumnApi = event.columnApi;
  }
  onUserActivityAgGridReady(event) {
    this.userActivityGridColumnApi = event.columnApi;
  }
  onTaskOpen(msg) {
    var data = msg.split("|");
    this.contactId = data[0];
    this.taskid = data[1];
    this.type = data[2];
    this.leadservice.GetByTaskHistoryId(this.taskid, "View", this.currentuser.timezone).then(result => {
      this.taskhistory = result;

      this.contactId = this.taskhistory.contactid;
      this.taskid = this.taskhistory.id;
      this.type = this.taskhistory.type;
      $("#dashboardsMainMenu").removeClass("activeButton");
      $('#tasklistsMainMenu').addClass("activeButton");
      this.router.navigateByUrl('/application/tasklistdashboard/' + this.type + '/' + this.contactId + '/' + this.taskid + '/null');
      //this.taskDialog = !this.taskDialog;
      //this.taskDialogCss();
      //this.applicationComponent.showDashboardSubTabs = true;

    });
    var that = this;
    $(setTimeout(function () {
      that.scheduleComp.loadcoantactname(that.contactId, that.type);
      that.scheduleComp.LoadSelectedTaskdata(that.taskid, true, "View");
      that.scheduleComp.loadtaskHistoryByselecteddate(that.taskhistory.estcompleteddate);
    }, 300));



  }
  Taskdialoghideshow(tdialog: boolean) {
    this.taskDialog = tdialog;
    this.taskDialogCss();
  }
  taskDialogCss() {
    $(setTimeout(function () {
      $('#dashtaskDialog .dialog').attr('style', 'width: 45%;padding: 0;min-height: 63%;height:99%;top: 0;left: 3%;max-width: 1393px!important;margin-right: 1%');
    }, 4));
  }

  onTaskSaved(msg) {
   
    this.taskDialog = false;
    this.SelectedDate = null;
   
    this.checkvalues('Open Tasks', this.UserIds);
    this.refresh();

    var locale = "en-us";

    this.ViewMonthlyMeetingCompleted(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.ViewMonthlyToDoCompleted(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.ViewMonthlyCallScheduledByMonth(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    this.ViewSalesInformation(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.loadpastdueActivitiesdata(selfObj.UserIds, "PastDue", selfObj.month, selfObj.currentYear);
    }, 100));
    this.ViewMonthlyPatDueActivityByMonth(this.currentuser.id, this.selectedmonth.toLocaleString(locale, { month: "long" }), (new Date()).getFullYear());
  }
  initializePastDueActivityAgGrid() {

    this.PastDueActivitygridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };

    this.PastDueActivitygridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 52).then((config) => {

      //if (config != null && config.maxlimit != null && config.maxlimit > 0) {
      //  this.PastDueActivitygridOptions.api.paginationSetPageSize(config.maxlimit);
      this.pageSize = '1000';
      //}

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = columnSelections;
      this.createpastDueActivitycolumnDefs(columnSelections);
    });

  }
  createpastDueActivitycolumnDefs(columnSelections: string) {

    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      selfObj.pastDueActivitycolumnDefs = [];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        selfObj.pastDueActivitycolumnDefs.push({
          field: fieldHeaderVisibleWidthArray[0],
          headerName: fieldHeaderVisibleWidthArray[1],
          hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellStyle: { color: '#FF0000' },
          width: parseInt(fieldHeaderVisibleWidthArray[3])
        });

      }
    } else {
      this.pastDueActivitycolumnDefs = [
        { field: 'contactName', headerName: 'Contact Name', hide: false, cellStyle: { color: '#FF0000' } },
        { field: 'estcompleteddate', headerName: 'Date Scheduled', hide: false, cellStyle: { color: '#FF0000' } },
        { field: 'accountname', headerName: 'Account Name', hide: false, cellStyle: { color: '#FF0000' } },
        { field: 'taskcategory', headerName: 'Task', hide: false, cellStyle: { color: '#FF0000' } },
        { field: 'tasktype', headerName: 'Type', hide: false, cellStyle: { color: '#FF0000' } },
        { field: 'status', headerName: 'Status', hide: false, cellStyle: function (params) { if (params.value == 'Open') { return { color: '#FF0000' }; } } },
        { field: 'datecreated', headerName: 'Date Created', hide: false, cellStyle: { color: '#FF0000' } },  
        { field: 'type', headerName: 'Account Or Contact', hide: false, cellStyle: { color: '#FF0000' } },
        { field: 'starttime', headerName: 'From', hide: false, cellStyle: { color: '#FF0000' } },
        { field: 'endtime', headerName: 'To', hide: false, cellStyle: { color: '#FF0000' } },  //true starts here
        { field: 'owner', headerName: 'User Name', hide: true, cellStyle: { color: '#FF0000' } }

      ];
    }
  }

  AccountrowDoubleClickAgGrid(rowEvent) {
   
    localStorage.setItem('RhxAccountVal', rowEvent.data.id);
    let SO = this;
    $(setTimeout(function () {
      SO.applicationComponent.activateSubButtons('empty');
      $(setTimeout(function () {
        SO.router.navigateByUrl(`/application/account/Accounts`);
        SO.applicationComponent.ExpandSelectedMenu('Accounts');
        $(".subButtons").removeClass("activeButton");
        SO.applicationComponent.selectedtabid = 'Customer';
      }, 100));
    }, 50));


  }
  ActivityReportChange(selectedValue) {
    if (selectedValue == '508') {
      this.startDateVal = this.datePipe.transform(this.today, 'yyyy-MM-dd');
      this.endDateVal = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    }
    else if (selectedValue == '507') {
      var startOfWeek = moment().startOf('week').toDate();
      var endOfWeek = moment().endOf('week').toDate();

      this.startDateVal = this.datePipe.transform(startOfWeek, 'yyyy-MM-dd');
      this.endDateVal = this.datePipe.transform(endOfWeek, 'yyyy-MM-dd');

    }
    else if (selectedValue == '509') {
      var date = new Date();
      this.startDateVal = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
      this.endDateVal = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
    }
    else if (selectedValue == '510') {
      var date = new Date();
      this.startDateVal = date.getFullYear() + "-01-01";
      this.endDateVal = date.getFullYear() + "-12-31";

    }
    this.LoadAccountAddedData(this.startDateVal, this.endDateVal);
  }
  InactiveSuspend(val) {
    //alert(val)
  }
  InternalMessageClicked() {

  }

  //onTaskclose(event) {
  //  this.showtaskDialog = !this.showtaskDialog;
  //}


  switchTheme(theme: string): void {
    this.currentTheme = theme;
  }

  onEventTriggered(event: string): void {
    this.triggeredEvents.push(event);
  }

  joinSignalRChatRoom(): void {
    const userName = prompt('Please enter a user name:');

    this.signalRAdapter = new SignalRGroupAdapter(userName, this.http);
  }

  GenerateUserAccess(val) {
    val = $("#userids").val();
    this.TodayTimeSpent = 0;
    this.WeekkTimeSpent = 0;
    this.MonthTimeSpent = 0;
    this.YearTimeSpent = 0;
    this.applicationComponent.selectedusers = val;
    if (this.applicationComponent.selectedusers != '' || this.applicationComponent.selectedusers != null) {
      this.customerService.GetUserAccessTime(this.applicationComponent.selectedusers, 'Today', this.startDateVal, this.endDateVal).then((Time) => {
        var Timedata = Time;
        Timedata.forEach(ele => {
          if (ele != null) {
            this.TodayTimeSpent += ele.TimeSpent;
          }
        });

      });

      this.customerService.GetUserAccessTime(this.applicationComponent.selectedusers, 'Week', this.startDateVal, this.endDateVal).then((weekTime) => {
        weekTime.forEach(ele => {
          if (ele != null) {
            this.WeekkTimeSpent += ele.TimeSpent;
          }
        });
      });

      this.customerService.GetUserAccessTime(this.applicationComponent.selectedusers, 'Month', this.startDateVal, this.endDateVal).then((Month) => {
        Month.forEach(ele => {
          if (ele != null) {
            this.MonthTimeSpent += ele.TimeSpent;
          }
        });
      });
      this.customerService.GetUserAccessTime(this.applicationComponent.selectedusers, 'Year', this.startDateVal, this.endDateVal).then((Year) => {
        Year.forEach(ele => {
          if (ele != null) {
            this.YearTimeSpent += ele.TimeSpent;
          }
        });

      });

    }


  }
  //getAccountTransferData() {
  //  this.customerService.GetAccountsTransferByUser(this.currentuser.id,'New').then(res => {
  //    this.TransferedAccounts = res;
  //  });
  //}

  rowClickAgGrid(rowEvent) {
    if ((this.Ids).indexOf(rowEvent.data.id) > -1) {
      var index = (this.Ids).indexOf(rowEvent.data.id);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      $('#tasklistselectedtyle').val('');
    } else {
      this.Ids.push(rowEvent.data.id)
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      $('#tasklistselectedtyle').val('Customer');
    }
  }




  handleDateClick(arg) {
    this.SelectedDate = arg.dateStr;
    //$('#checkDiv').css('height', '89%');
    this.SearchDialog = !this.SearchDialog;
    this.SearchDialogCss();
  }
  handleEvents(event: EventApi | any): void {
    setTimeout(function () {
      $(".fc-list-event-title > a").each(function (index) {
        $(this).html($(this).text());
      });

    }, 100);
  }
  SearchDialogCss() {
    $(setTimeout(function () {
      $('#SearchDialog .dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }

  onSearchstring(msg) {
    if (msg == 'BTClick') {
      this.taskDialog = true;
      this.SearchDialog = true;
      this.taskDialogCss();
      this.SearchDialog = !this.SearchDialog;
      this.BTClick = true;
      this.id = 0;
      this.taskid = 0;
    } else {
      var data = msg.split("|");
      this.SearchDialog = !this.SearchDialog;
      this.SearchDialogCss();
      this.taskDialog = !this.taskDialog;
      this.taskDialogCss();
      this.id = data[0];
      this.taskid = 0;
      this.type = data[1];
      this.BTClick = false;
      let selfObj = this;
      $(setTimeout(function () {
        //selfObj.scheduleComp.loadcoantactname(selfObj.id, selfObj.type);
        //selfObj.scheduleComp.LoadSelectedTaskdata(selfObj.taskid, true, "View");
        //selfObj.scheduleComp.loadtaskHistoryByselecteddate(selfObj.taskhistory.estcompleteddate);

        selfObj.taskcomp.loadcoantactname(selfObj.id, selfObj.type);
        selfObj.taskcomp.LoadSelectedTaskdata(selfObj.taskid, true, "View");
        selfObj.taskcomp.loadtaskHistoryByselecteddate(selfObj.taskhistory.estcompleteddate);
      }, 300));
    }
  }

  SalesOpportunities(value, type) {
    var date = new Date(value);

    var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
    var startDaY = moment(firstDay).format("YYYY-MM-DD");
    var endDay = moment(lastDay).format("YYYY-MM-DD");
    this.revenueDashComp.LoadRevenueData(type, startDaY, endDay);
  }
  TicketsChange(value, type) {
    var date = new Date(value);
    var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
    var startDaY = moment(firstDay).format("MM-DD-YYYY");
    var endDay = moment(lastDay).format("MM-DD-YYYY");
    this.TicketsDashComp.LoadTickets(startDaY, endDay, "Closed Tickets");
  }
  ClosedChange(value) {
    var date = new Date(value);
    var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
    var startDaY = moment(firstDay).format("YYYY-MM-DD");
    var endDay = moment(lastDay).format("YYYY-MM-DD");
    this.LoadClosedTicketsRanking(startDaY, endDay);
  }
  UserActivityChange(value) {
    var date = new Date(value);
    var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
    var startDaY = moment(firstDay).format("YYYY-MM-DD");
    var endDay = moment(lastDay).format("YYYY-MM-DD");
    this.LoadActivityData(startDaY, endDay);
  }
  loadRevenue() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
    this.RevenueStartDate = moment(firstDay).format("YYYY-MM-DD");
    this.RevenueEndDate = moment(lastDay).format("YYYY-MM-DD");
    this.getRevenueData();
  }

  initializeOpenLeadsAgGrid() {
    var searchstring = this.applicationComponent.searchstring;
    this.LeadsgridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    //this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 6).then((config) => {

    //  if (config != null && config.maxlimit != null && config.maxlimit > 0) {
    //    //this.gridOptions.api.paginationSetPageSize(config.maxlimit);
    //    this.pageSize = config.maxlimit.toString();
    //  }

    //  let columnSelectionsArray: any[] = [];
    //  for (let i = 0; i < allCols.length; i++) {
    //    let fields: string[] = allCols[i].split(':');
    //    if (fields[2] == 'true' && fields[1] != '#') {
    //      columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
    //    }
    //  }

    //  this.applicationComponent.SearchColumnList = columnSelectionsArray;
    //});

    this.createLeadsColumnDefs();
    this.ClosedLeads('Open Leads', $("#userids").val(), null, 1, this.pageSize);
    this.LeadsgridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    //this.frameworkComponents = {
    //  notesrenderer: NotesrendererComponent
    //};

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };

    //let selfObj = this;
    //$(setTimeout(function () {
    //  selfObj.gridOptions.api.forEachNode((node) => {
    //    if (node.data.id == selfObj.leadid) {
    //      selfObj.gridOptions.api.selectNode(node, true);
    //      selfObj.gridOptions.rowSelection = 'single';
    //    }
    //  });
    //}, 500));

  }

  createLeadsColumnDefs() {
    var searcharray = [];
    if (this.applicationComponent.searchCriteriadata) {
      var searchaarraystring = this.applicationComponent.searchCriteriadata;
      if (searchaarraystring.includes(' /')) {
        searcharray = searchaarraystring.split(' / ');
      } else {
        searcharray.push(searchaarraystring);
      }
    }
    let columnSelectionsArray: string[] = [];


    this.LeadscolumnDefs = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      { field: 'datecreated', headerName: 'Date Created', hide: false },
      {
        field: 'lastname', headerName: 'Last Name', hide: false, cellRenderer: function (params) {
          if (searcharray.length > 0 && params.value != null) {
            let x = params.value.split(" ");
            var st = "";
            for (let i = 0; i < x.length; i++) {
              if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        }
      },
      {
        field: 'firstname', headerName: 'First Name', hide: false, cellRenderer: function (params) {
          if (searcharray.length > 0 && params.value != null) {
            let x = params.value.split(" ");
            var st = "";
            for (let i = 0; i < x.length; i++) {
              if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        }
      },
      { field: 'titleName', headerName: 'Title', hide: false },
      {
        field: 'account', headerName: 'Account Name', hide: false, cellRenderer: function (params) {
          if (searcharray.length > 0 && params.value != null) {
            let x = params.value.split(" ");
            var st = "";
            for (let i = 0; i < x.length; i++) {
              if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        }
      },
      {
        field: 'salesstage', headerName: 'Sales Stage', hide: false, cellStyle: function (params) {
          if (params.data.status == 'Open' || params.data.status == 'Pending') { return { color: 'red' }; }
        }
      },
      {

        field: 'status', headerName: 'Status', hide: false, cellStyle:
          function (params) {
            if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; }
          }, cellRenderer(params) {
            if (params.data.salesstage == 'MD Logic Demonstration' && params.data.status == 'Next Stage Pending') { return ' ' } else { return params.value; }
          }

      },
      {
        field: 'nexttask', headerName: 'Next Sales Step', hide: false, cellRenderer(params) {
          if (params.data.salesstage == 'IPC') { return 'Analysis' }
          else if (params.data.salesstage == 'Analysis') { return 'Prepare Analysis Report' }
          else if (params.data.salesstage == 'Prepare Analysis Report') { return 'Review Analysis' }
          else if (params.data.salesstage == 'Review Analysis') { return 'Generate Proposal' }
          else if (params.data.salesstage == 'Generate Proposal') { return 'Justification Meeting' }
          else if (params.data.salesstage == 'Justification Meeting') { return 'Reference Calls' }
          else if (params.data.salesstage == 'Reference Calls') { return 'Demonstration' }
          else if (params.data.salesstage == 'Demonstration') { return 'Won-Lost-Pending' }
        }
      },
      { field: 'datescheduled', headerName: 'Date Schedule', hide: true },  //true starts here
      { field: 'owner', headerName: 'Owner', hide: false },
      { field: 'completeduser', headerName: 'Completed By', hide: false },
      { field: 'dealwonOrlost', headerName: 'Deal Won/Lost', hide: true },


    ];

    var tooltipRenderer = function (params) {
      return '<span title="' + params.value + '">' + params.value + '</span>';
    };

  }

  ClosedLeads(val, userids, searchval, startrow, endrow) {
    var subMenuId = '';
    if (val == 'Open Leads') {
      subMenuId = 'OpenLeads';
    } else if (val == 'Completed') {
      subMenuId = 'ClosedLeads';
    } else if (val == 'All') {
      subMenuId = 'AllLeads';
    }
    else {
      subMenuId = 'OpenLeads';
    }
    if (this.currentuser.UserPrivilegeList != undefined) {
      var button = document.getElementById(subMenuId);
      var bname = (button == null) ? subMenuId : (button.title);
      var thisTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == bname || p.resource.toLowerCase() == bname)[0];
      if (thisTab != undefined && thisTab.canview == false) {
        this.toastr.warning("You Do Not have Access to " + bname + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
        return false;
      }
    }

    this.allLeads = [];
    var today = new Date();
    userids = $("#userids").val();

    this.applicationComponent.searchCriteriadata = [];
    this.leadservice.GetAllLeadsList(val, userids, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, searchval, startrow, endrow, this.currentuser.id).then((customer) => {
      if (customer.length > 0) {
        if ((customer.length == 0 || customer == null) && searchval != null) {
          this.applicationComponent.ShowInvalidSearchDialog = !this.applicationComponent.ShowInvalidSearchDialog;
          this.applicationComponent.dialogsizeInvalidSearch();

        }
        customer.forEach(customer => {
          customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
        });
        let leads: any = customer;
        if (val == "Open") {
          leads = leads.filter((cust) => cust.status != 'Won' && cust.status != 'Lost');
        }
        this.allLeads = leads;
        if (this.allLeads.length > 0 && this.allLeads[0].islimitedSearch == true) {
          if (this.allLeads[0].SearchVal) {
            this.applicationComponent.searchCriteria = this.allLeads[0].SearchVal;
            this.applicationComponent.searchstring = this.allLeads[0].SearchVal.slice(0, -3);
            //this.applicationComponent.Searchdiv = true;
            this.applicationComponent.searchCriteriadata = this.applicationComponent.searchstring.split(' / ');
          }
          this.applicationComponent.term = "true"; //passing dummy data toshow delete button in search box
          this.applicationComponent.searchstring = this.allLeads[0].SearchVal;
          this.applicationComponent.limitedSearch = true;
          $('#searchContact').val('');

        }
        else {
          this.applicationComponent.term = "";
          $('#searchContact').val('');
          this.applicationComponent.searchstring = "";
          this.applicationComponent.limitedSearch = false;
          this.applicationComponent.searchCriteriadata = [];
        }

      }
    });

    //this.initializeAgGrid();
  }
  getRevenueData() {
    this.leadservice.GetRevenueData($("#userids").val(), this.RevenueStartDate, this.RevenueEndDate).then((customer) => {
      this.Revenue = customer;
    });
  }
  ChangeclosedtotalCampaign(val) {

    this.TotalLostOppPriceLabel = moment(val).format('MMM, YYYY');
    this.LoadCampaigns(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0]);

  }
  ChangeprofitlossCampaign(val) {

    this.TotalLostOppPriceLabel = moment(val).format('MMM, YYYY');
    this.LoadCampaigns(this.currentuser.id, this.monthNumToName(val.split('-')[1]), val.split('-')[0]);

  }
  ChangeTotalOpp(val, type) {
    var date = new Date(val);

    var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
    var startDay = moment(firstDay).format("YYYY-MM-DD");
    var endDay = moment(lastDay).format("YYYY-MM-DD");

    if (type == 'WonPrice') {
      this.TotalOppWonPriceLabel = moment(val).format('MMM, YYYY');
    } else if (type == 'WonRR') {
      this.TotalOppWonRRLabel = moment(val).format('MMM, YYYY');
    } else if (type == 'LostPrice') {
      this.TotalLostOppPriceLabel = moment(val).format('MMM, YYYY');
    } else {
      this.TotalOpenOppPriceLabel = moment(val).format('MMM, YYYY');
    }
    this.leadservice.GetRevenuedashboardData($("#userids").val(), startDay, endDay, type).then((customer) => {
      if (type == 'WonPrice') {
        this.Revenue.OpportunityWonTotalPrice = customer.OpportunityWonTotalPrice;
      } else if (type == 'WonRR') {
        this.Revenue.OpportunityWonTotalRR = customer.OpportunityWonTotalRR;
      } else if (type == 'LostPrice') {
        this.Revenue.OpportunityLostTotalPrice = customer.OpportunityLostTotalPrice;
      } else {
        this.Revenue.OpportunityOpenTotalPrice = customer.OpportunityOpenTotalPrice;
      }
    });
    this.CallsCompletedLabel = moment(val).format('MMM, YYYY');
  }
  GetUserMessagesByUserId() {
    this.NotificationMessages = [];
    this.messagingservice.GetUserMessagesByUser(this.currentuser.id).then((res) => {
      res.forEach(x => {
        var DisplayDate = this.datePipe.transform(x.DisplayDate, "MM-dd-yyyy");
        var currentdate = this.datePipe.transform(new Date(), "MM-dd-yyyy");
        var readusers;
        if (x.readusers != null) {
          readusers = x.readusers.split(',').filter(x => x == this.currentuser.id)[0];
        }
        if (DisplayDate <= currentdate && !readusers) {
          x.DisplayDate = DisplayDate;
          this.NotificationMessage(x);
          this.NotificationMessages.push(x);
          this.applicationComponent.NotificationMessages = this.NotificationMessages;
          //this.NotificationMessageDialog = true;
        }
        else {
          //this.NotificationMessageDialog = false;

        }

      });
      this.CurrentUserMessages = res;
    });
  }

  NotificationMessage(Data) {
    this._notificationSvc.Message(Data);
    this.NotificationMessage
  }

  NotificationMessageDialogCss() {
    setTimeout(function () {
      $(' #NotificationMessageDialog .dialog').attr('style', 'width: 50%;padding - bottom: 0px;min-height: 63%;overflow: auto;height: 60%;left: 6%;top: 18.5%;padding: 0;');
    }, 4)
  }

  onWizzardClosed(val) {
    if (val.readusers == null) {
      val.readusers = "";
      val.readusers += this.currentuser.id;
    } else {
      val.readusers += "," + this.currentuser.id;
    }

    this.AddorUpdateUserMessages(val)
  }

  AddorUpdateUserMessages(userMessages) {

    this.messagingservice.AddorUpdateUserMessages(userMessages).then((res) => {
      this.GetUserMessagesByUserId()

    });
  }
  onWizardAdded() {

  }
  LeadsrowClickAgGrid(rowEvent) {
    this.applicationComponent.CollapseSelectedMenu('leads');
    this.applicationComponent.ExpandSelectedMenu('leads');
    this.router.navigateByUrl(`/application/leadsdashboard/${rowEvent.data.contactid}/${rowEvent.data.id}/null`);
  }

  CalenderClick() {

    let className = $(this).parent()
      .parent().parent().attr('class');
    let removeSpace = className.replace(' ', '.');

    $("." + removeSpace).datepicker({
      format: "dd/mm/yyyy",
      orientation: "bottom auto",
      autoclose: true,
      showOnFocus: "false"
    });


  }
  ActivityChange(value) {
 
    localStorage.setItem('myDashactivity', value);
    this.SelectedAct = value;
    this.TicketsDashComp.getAccountsData(this.SelectedAct, this.SelectedStatus);   
  }

  CustomerStatusChange(value) { 
 
    localStorage.setItem('myDashactivityStatus', value);
    this.SelectedStatus = value;
    this.TicketsDashComp.getAccountsData(this.SelectedAct, this.SelectedStatus);
  }

  CompletedProjects(value, type) {
    var date = new Date(value);
    var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
    var startDaY = moment(firstDay).format("YYYY-MM-DD");
    var endDay = moment(lastDay).format("YYYY-MM-DD");
    this.ProjDashComp.LoadCompletedProjects(type, startDaY, endDay);
  }
  expandgrid(gridname, val, expandval) {
    this.expand = expandval;
    if (gridname == "opentickets" || gridname == "CustomerOnHold" || gridname == "taskpastdue" || gridname == "custactivity") {
      localStorage.setItem('myDashExpandGrid', gridname);
    }
     if (gridname == "assignedtosupportgroup" || gridname == "closedtickets" || gridname == "closedticketsrating") {
      localStorage.setItem('supportDashboardGrid', gridname);
    }
     if (gridname == "assignedtotrainergroup" || gridname == "closedtickets" || gridname =="closedticketsrating")
    {
      localStorage.setItem('trainerDashboardGrid', gridname);
    }
     if (gridname == "cancelledprojects" || gridname == "completedprojects" || gridname == "pendedprojects") {
      localStorage.setItem('projectDashboardGrid', gridname);
    }
     if (gridname == "opportunitiesopen" || gridname == "openleads" || gridname == "useractivitytopuser") {
      localStorage.setItem('nbdDashboardGrid', gridname);
    }
    if (gridname == "opportunitiesopen" || gridname == "openleads" || gridname == "salesranking") {
      localStorage.setItem('salesDashboardGrid', gridname);
    }
    if (gridname == "opentickets") {
      this.showcustomerOnhold = false;
      this.expandmydashboardthirdrow = true;
    }
    else if (gridname == "CustomerOnHold") {
      this.showtickets = false;
      this.expandmydashboardthirdrow = true;
    }
    else if (gridname == "taskpastdue") {
      this.expandmydashboardsecrow = true;
      this.ShowcustomerActivity = false;
    }
    else if (gridname == "custactivity") {
      this.expandmydashboardsecrow = true;
      this.showPastdue = false;
      this.ShowcustomerActivity = true;
    }
    else if (gridname == "assignedtosupportgroup" || gridname == "assignedtotrainergroup") {
      this.expandsupportdashboardthirdrow = true;
    }
    else if (gridname == "closedtickets" || gridname == "closedticketsrating") {
      this.expandsupportdashboardsecrow = true;
      if (gridname == "closedticketsrating") {
        this.showclosedtickets = false;
      }
      else if (gridname == "closedtickets") {
        this.showclosedticketsrating = false;
      }
    }
    else if (gridname == "opportunitiesopen") { this.expandsupportdashboardthirdrow = true; }
    else if (gridname == "pendedprojects") {
      this.expandprojectdashboardthirdrow = true;
    } else if (gridname == "completedprojects" || gridname == "cancelledprojects") {
      this.expandprojectdashboardsecrow = true;
      if (gridname == "completedprojects") { this.showcancelledprojects = false; }
      else if (gridname == "cancelledprojects") { this.showcompletedprojects = false; }
    } else if (gridname == "openleads" || gridname == "useractivitytopuser") {
      this.expandnbddashboardsecrow = true;
      if (gridname == "useractivitytopuser") { this.showopenleads = false; }
      else if (gridname == "openleads") {
        this.showactivitytopusers = false;
        this.showsalesranking = false;
      }
    } else if (gridname == "useractivitytopuser") { }
    else if (gridname == "salesranking") {
      this.expandnbddashboardsecrow = true;
      this.showopenleads = false;
    }
   
  }
  expandclose(gridname, expandval) {
    this.expand = expandval;
    this.expandmydashboardsecrow = expandval;
    this.expandmydashboardthirdrow = expandval;
    if (gridname == "opentickets" || gridname == "CustomerOnHold" || gridname == "taskpastdue" || gridname == "custactivity") {
      localStorage.removeItem("myDashExpandGrid");
    }
    if (gridname == "assignedtosupportgroup" || gridname == "closedtickets" || gridname =="closedticketsrating") {
      localStorage.removeItem('supportDashboardGrid');
    }
     if (gridname == "assignedtotrainergroup" || gridname == "closedtickets" || gridname == "closedticketsrating") {
      localStorage.removeItem('trainerDashboardGrid');
    }
     if (gridname == "cancelledprojects" || gridname == "completedprojects" || gridname == "pendedprojects") {
      localStorage.removeItem('projectDashboardGrid');
    }
     if (gridname == "opportunitiesopen" || gridname == "openleads" || gridname == "useractivitytopuser") {
      localStorage.removeItem('nbdDashboardGrid');
    }
    if (gridname == "opportunitiesopen" || gridname == "openleads" || gridname == "salesranking") {
      localStorage.removeItem('salesDashboardGrid');
    }
    if (gridname == "opentickets") {
      this.showcustomerOnhold = true;
    }
    else if (gridname == "CustomerOnHold") {
      this.showtickets = true;

    }
    else if (gridname == "taskpastdue") {
      this.showPastdue = true;
      this.ShowcustomerActivity = true;
    }
    else if (gridname == "custactivity") {
      this.ShowcustomerActivity = true;
      this.showPastdue = true;
    }
    else if (gridname == "assignedtosupportgroup" || gridname == "assignedtotrainergroup") {
      this.expandsupportdashboardthirdrow = false;
    }
    else if (gridname == "closedtickets" || gridname == "closedticketsrating") {
      this.expandsupportdashboardsecrow = false;
      if (gridname == "closedticketsrating") { this.showclosedtickets = true; }
      else if (gridname == "closedtickets") {
        this.showclosedticketsrating = true;
      }
    }
    else if (gridname == "pendedprojects") {
      this.expandprojectdashboardthirdrow = false;
    }
    else if (gridname == "completedprojects" || gridname == "cancelledprojects") {
      this.expandprojectdashboardsecrow = false;
      if (gridname == "completedprojects") { this.showcancelledprojects = true; }
      else if (gridname == "cancelledprojects") { this.showcompletedprojects = true; }
    }
    else if (gridname == "openleads" || gridname == "useractivitytopuser") {
      this.expandnbddashboardsecrow = false;
      if (gridname == "useractivitytopuser") { this.showopenleads = true; }
      else if (gridname == "openleads") {
      this.showactivitytopusers = true;
        this.showsalesranking = true;
      }
    }
    else if (gridname == "opportunitiesopen") { this.expandsupportdashboardthirdrow = false; }
    
    else if (gridname == "salesranking") {
      this.expandnbddashboardsecrow = false;
      this.showopenleads = true;
    }
  }
}
