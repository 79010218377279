<div class="col-xs-12" style="height:100%;padding:0;">

  <div *ngIf="SelectedReportType ==''" style="padding: 11% 20% 20% 35%; font-size: 213%;">
  <b>NO REPORT IS SELECTED</b>
  </div>
  <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="this.SelectedReportType == 'Accounts'">
   <ag-grid-angular style="width: 100%;height:100%" class="ag-theme-balham"
                     [gridOptions]="AccountGridOptions"
                     [columnDefs]="AccountcolumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="allCustomers"
                     [context]="context"
                     [sideBar]="sideBar"
                     [pagination]="true"
                     [frameworkComponents]="AccountframeworkComponents"
                     (rowDoubleClicked)="AccountDoubleClick($event)">     
    </ag-grid-angular>
  </div>

  <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="this.SelectedReportType == 'Contacts'">
    <ag-grid-angular style="width: 100%;height:100%" class="ag-theme-balham"
                     [gridOptions]="ContactGridOptions"
                     [columnDefs]="ContactColumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="allNewContact"
                     [context]="context"
                     [sideBar]="sideBar"
                     [pagination]="true"
                     [frameworkComponents]="frameworkComponents"
                     (rowDoubleClicked)="ContactDoubleClick($event)">     
    </ag-grid-angular>
  </div>

  <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="this.SelectedReportType == 'Leads'">
    <ag-grid-angular style="width: 100%;height:100%" class="ag-theme-balham"
                     [gridOptions]="LeadgridOptions"
                     [columnDefs]="LeadcolumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="allLeads"
                     [context]="context"
                     [sideBar]="sideBar"
                     [pagination]="true"
                     [frameworkComponents]="frameworkComponents"
                     (rowDoubleClicked)="LeadsDoubleClick($event)">    
    </ag-grid-angular>

  </div>

  <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="this.SelectedReportType == 'Opportunities'">
    <ag-grid-angular style="width: 100%; height:100%" class="ag-theme-balham"
                     [gridOptions]="OpportunitiesgridOptions"
                     [columnDefs]="OpportunitiescolumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="Allopportunities"
                     [context]="context"
                     [sideBar]="sideBar"
                     [pagination]="true"
                     [frameworkComponents]="frameworkComponents"
                     (rowDoubleClicked)="OpportunitiesDoubleClick($event)">
      <!--(selectionChanged)="OpportunitiesrowClickAgGrid($event)"
      (columnVisible)="OpportunitiescolumnVisible($event)"
      (dragStopped)="OpportunitiesdragStopped($event)"
      (columnResized)="OpportunitiescolumnResized($event)"
      (gridReady)="OpportunitiesonAgGridReady($event)">-->
    </ag-grid-angular>
  </div>
  <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="this.SelectedReportType == 'Tasks'">
    <ag-grid-angular style="width: 100%;height:95% " class="ag-theme-balham"
                     [gridOptions]="TaskGridOptions"
                     [columnDefs]="TaskcolumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [context]="context"
                     [rowData]="alltaskhistory"
                     [sideBar]="sideBar"
                     [pagination]="true"
                     [frameworkComponents]="frameworkComponents"
                     (rowDoubleClicked)="TasksDoubleClick($event)">
      <!--(selectionChanged)="TaskhistoryrowClickAgGrid($event)"
      (columnVisible)="TaskhistorycolumnVisible($event)"
      (dragStopped)="TaskhistorydragStopped($event)"
      (columnResized)="TaskhistorycolumnResized($event)"
      (gridReady)="selectedonAgGridReady($event)">-->
    </ag-grid-angular>

  </div>

  <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="this.SelectedReportType == 'Tickets'">
    <ag-grid-angular style="width: 100%; padding-top: 10px;padding: 0;height:99%" id="gridheight" class="ag-theme-balham"
                     [gridOptions]="TicketGridOptions"
                     [columnDefs]="TicketColumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="Userlist"
                     [context]="context"
                     [sideBar]="sideBar"
                     [pagination]="true"
                     [frameworkComponents]="frameworkComponents"
                     (rowDoubleClicked)="TicketsDoubleClick($event)">
      <!--(rowDoubleClicked)="rowDoubleClickAgGrid($event)"
      (rowSelected)="rowClickAgGrid($event)"
      (columnVisible)="columnVisible($event)"
      (dragStopped)="dragStopped($event)"
      [suppressRowClickSelection]="true"
      (columnResized)="columnResized($event)"
      [frameworkComponents]="frameworkComponents"
      (gridReady)="onAgGridReady($event)">-->
    </ag-grid-angular>
  </div>
  <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="this.SelectedReportType == 'Proposals'">
    <ag-grid-angular style="width: 100%;height:100%" id="gridheight" class="ag-theme-balham"
                     [gridOptions]="ProposalGridOptions"
                     [columnDefs]="ProposalColumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="AllProposals"
                     [sideBar]="sideBar"
                     [pagination]="true"
                     (rowDoubleClicked)="ProposalDoubleClick($event)">
      <!--(columnVisible)="columnVisible($event)"
      (selectionChanged)="rowClickAgGrid($event)"
      (dragStopped)="dragStopped($event)"
      (columnResized)="columnResized($event)"
      (gridReady)="onAgGridReady($event)">-->
    </ag-grid-angular>
  </div>

  <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="this.SelectedReportType == 'Access(Login/Logout)'">
  <ag-grid-angular style="width: 100%;height:100%"
                     class="ag-theme-balham"
                     [gridOptions]="AccessGridOptions"
                     [columnDefs]="AccessColumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="loginauditloglist"
                     [pagination]="true"
                     [sideBar]="sideBar">
    </ag-grid-angular>
  </div>

</div>
