<input type="text" id="emailIds" style="display:none;" />
<input type="text" id="tasklistselectedtyle" style="display:none;" />
<input type="text" id="typeClicked" style="display:none;" />
<input type="text" id="loctionCount" value="0" style="display:none;" />
<input type="text" id="setDocumentDetails" value="0" style="display:none;" />
<div  id="Accountinformation" class="container" style="padding:0;height:100%">

  <div *ngIf="showSccountInfo" class="col-xs-12" id="window" style="padding-left: 0;padding-right: 0%;background-color: white;height:100%;">
    <div [ngClass]="(tab === 'QuickView')?(SixBoxExpand)?'col-xs-6' : 'col-xs-8':'col-xs-8'" [style.width.%]="(tab === 'QuickView')?'67' : '69'" *ngIf="leftMainDivHideShow" style="padding:0;height:100%" id="LeftMainDiv">
      <div id="accountInformation" [ngStyle]="{'font-size.px':tab === 'QuickView' ? 12 : 14,'width.%':(documentview) ? 198 : 100 }" class="col-xs-12" style="padding-left:0;padding-right:0; display:block;overflow:auto;border-bottom:1px solid #c7c7c7;height:34%">
        <account-details [viewaccount]="viewaccount" [account]="account" [tab]="tab" [noteslist]="noteslist" (btnClickEvent)="btnClickReceived($event)" [pageFrom]="'Accounts'" [ViewData]="'Account'" [contact]="''"></account-details>
      </div>

      <div [ngClass]="(account.accountStatus == 15)?'col-xs-12 watermark':'col-xs-12'" id="providerDetails" style="padding:0;margin-top: 0px; height:66%">

        <div class="" id="leftGridDiv" *ngIf="leftGridDetailsDiv" style="height:99%">
          <div class="otherCss" style="margin-bottom:0.2em;padding:0;height:6%" *ngIf="!ShowContactstoSelect">

            <div class="col-xs-4" style="padding:0;height:100%">

              <select class="form-control" name="contacttype" (change)="ContactTypeFilter($event.target.value)" [(ngModel)]="SelectedContactType" style="height: 85%;margin-top: 2%;padding: 0;font-size: 11px;">
                <option value="All" [selected]="true">All Contacts ({{this.allContacts?.length}})</option>
                <option *ngFor="let contacttype of SortedContactType" value={{contacttype.Value}}>
                  {{contacttype.Name}}
                </option>
              </select>
            </div>

            <div class="col-xs-4" style="padding:0;height:100%">
              <select class="form-control" name="contactStatus" (change)="ContactStatusFilter($event.target.value)" [(ngModel)]="SelectedStatus" style="height: 85%;margin-top: 2%;padding: 0;font-size: 11px;">
                <option *ngFor="let Status of SortedStatus" value={{Status.id}}>
                  {{Status.Value}}
                </option>
              </select>
            </div>
            <div class="col-xs-4" style="padding:0;height:100%">
              <select class="form-control" (change)="ContactsSortOrder($event.target.value)" [(ngModel)]="ContactsOrder.OrderByValue" style="height: 85%;margin-top: 2%;padding: 0;font-size: 11px;">
                <option value="{{item.value}}" *ngFor="let item of ContactOrder">{{item.itemName}}</option>
              </select>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="ShowContactstoSelect" style="padding:0;text-align:center;height:6%">
            <label style="color:red">Select the Contacts to Update with the New Address then Click Continue</label>
          </div>
          <div class="col-xs-12" style="padding:0;height:94%">
            <ag-grid-angular class="ag-theme-balham" style="height:100%"
                             [gridOptions]="gridOptions"
                             [columnDefs]="columnDefs"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="allContactsproviders"
                             [context]="context"
                             [sideBar]="sideBar"
                             [frameworkComponents]="frameworkComponents"
                             (rowSelected)="rowClickAgGrid($event)"
                             (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                             [suppressDragLeaveHidesColumns]="true"
                             (columnVisible)="columnVisible($event)"
                             (dragStopped)="dragStopped($event)"
                             [suppressRowClickSelection]="true"
                             (columnResized)="columnResized($event)"
                             (gridReady)="onAgGridReady($event)"
                             (selectionChanged)="onSelectionChanged($event)">
            </ag-grid-angular>
          </div>
        </div>
        <app-dialog id="resetpassword" [(visible)]="showDialog">
          <convertcontactto-lead [id]="contactid" [type]="leadstatus" [selectorTag]="true"></convertcontactto-lead>

        </app-dialog>
        <input type="text" id="accotIdNotes" style="display:none;" />
        <input type="text" id="accotTypeNotes" style="display:none;" />
        <app-dialog [(visible)]="notesshowDialog" *ngIf="notesshowDialog" (visibleChange)="onCloseDialogEventReceived($event)" [CloseButtonColor]="'black'">
          <div class="col-xs-12" style="height: 2.5%; padding: 0%; color: rgb(186,219,152);background-color: rgb(186,219,152) ">
            d
          </div>
          <div class="col-xs-12" style="height: 5%;padding: 0%;border-bottom:1px solid gray">         
          <div class="col-xs-11" style="padding: 6px; height: 100% ">
            <label style="font-weight: normal !important;">NOTES / ACCOUNT DETAILS</label></div>
          </div>
          <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%">
            <app-new-notes style="height:100%"
                           [selectedTabName]="'1'"
                           [conid]="conid"
                           [typ]="typ"
                           [isModal]="false">
            </app-new-notes>
          </div>

        </app-dialog>

        <app-dialog [(visible)]="futureTaskDialog" *ngIf="futureTaskDialog" (visibleChange)="onCloseDialogEventReceived($event)" [type]="'FutureTask'" [CloseButtonColor]="'black'">
          <app-history [type]="'FutureTask'" [ContAccId]="this.contactid" [userType]="'Customer'"> </app-history>
        </app-dialog>
      </div>

    </div>


    <div class="col-xs-6" *ngIf="uploadDocumentShow && !QuickViewDocumentsinfo &&  !QuickViewProposalsinfo" style="padding:0; height:100%;">
      <upload-document [accountId]="this.contactid" [selectedtype]="'Account'" (sendMsgEvent)="SavedDocuments($event)"> </upload-document>
    </div>

    <div [ngClass]="(tab === 'QuickView')?(SixBoxExpand)?'col-xs-6' : 'col-xs-4' : 'col-xs-4'" [style.width.%]="(tab === 'QuickView')?'33' : '31'" id="rightMainDiv"
         *ngIf="rightMainDivHideShow && !uploadDocumentShow" [ngStyle]="{'margin-top.px':(documentview) ? 132 : 0,'height.%':(documentview) ? 95 : 100 }" style="padding:0; ">
      <div *ngIf="showAccountHistoryHx" style="border: 1px solid #ddd; overflow:hidden;height:99%;margin-top:1%;">       
        <div class="col-xs-12" style="background-color: rgb(39,57,67); color: white; text-align: center; padding: 0%">
          <div class="col-xs-11" style="text-align: center; padding: 6px; height: 100%">          
          <label style="font-weight: normal !important;"> ACCOUNT HISTORY OVERVIEW</label>
          </div>
          <div class="col-xs-1" style="padding:0;height:100%">
            <label class="col-xs-12" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;padding:2px;float:right;z-index:1;height:100%;padding:9%;" *ngIf="tab == 'QuickView'">
              <img src="../../../assets/img/White x.png" style="height:25px" (click)="CloseHistory()" />
            </label>
          </div>
        </div>
        <div class="col-xs-12" style="padding:1.5% ;height:100%" *ngIf="accountHistory && accountHistory.HistoryNotes">
          <textarea rows="6" type="text" name="notedescription" disabled style="height: 98%; width: 100%; padding: 2%; background: rgb(221,217,217); font-weight: 600;">{{accountHistory.HistoryNotes}}</textarea>
        </div>
      </div>
      <div id="AccountotherDetails" *ngIf="AccountotherDetailsDiv" style="border: 1px solid #ddd; overflow:hidden;height:99%;margin-top:1%;">
        <div class="col-xs-12" style="height:5%;padding:0;">
          <div class="col-xs-12" style="height: 95%; background-color: rgb(40,62,74); color: white; text-align: center; padding: 0%">

            <div class="col-xs-11" style="text-align: center; padding: 6px; height: 100%">
              <label *ngIf="viewaccount.AccountHistoryNote !='' && viewaccount.AccountHistoryNote != null" class="fa fa-star" aria-hidden="true"
                     style="color: rgb(186,219,152) !important;float:left;font-size: 20px;" (click)="openAcchistoryNotes()"></label>
              <label style="font-weight: normal !important;">ACCOUNT DETAILS</label>
            </div>

            <div class="col-xs-1" style="padding:0;height:100%">
              <label class="col-xs-12" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;padding:2px;float:right;z-index:1;height:100%;padding:9%;" *ngIf="tab == 'QuickView'">
                <img src="../../../assets/img/White x.png" style="height:25px" (click)="CloseTab(tab)" />
              </label>


            </div>

          </div>
        </div>
        <div class="col-xs-12" id="accountotherDetailsTable" *ngIf="accountotherDetailsTable" style="height:95.5%;padding:0;">

          <div class="col-xs-12" style="padding:0;overflow:hidden;height:20%" id="activityDiv">
            <div class="col-xs-6" style="border: 2px solid rgb(186,219,152) !important; height: 97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
              <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">
                <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView'  || tab == 'Normal')? '25':'10'">
                  <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView'  || tab == 'Normal')? '100':'91'" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'"> ACTIVITIES ({{activitieslist.length}})</span>
                </div>

                <div *ngIf="tab === 'QuickView'  || tab == 'Normal'" (click)="expandaccountactivities('ACTIVITIES')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%;">
                  <img *ngIf="activitieslist.length>0" src="../../../assets/img/Activities - QuickView Page Icon - Green.png" style="height:70%" />
                  <img *ngIf="activitieslist.length==0" src="../../../assets/img/Activities - QuickView Page Icon - Grey.png" style="height:70%" />
                </div>

              </div>
            </div>
            <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
              <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">
                <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView'  || tab == 'Normal')? '25':'10'">
                  <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView'  || tab == 'Normal')? '100':'91'" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'"> NOTES ({{noteslist.length}})</span>
                </div>

                <div *ngIf="tab === 'QuickView'  || tab == 'Normal'" (click)="expandaccountnotes('NOTES')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%">

                  <img *ngIf="noteslist.length>0" src="../../../assets/img/Notes - QuickView Page Icon - Green.png" style="height:70%" />
                  <img *ngIf="noteslist.length==0" src="../../../assets/img/Notes - QuickView Page Icon - Grey.png" style="height:70%" />
                </div>

              </div>
            </div>
          </div>

          <div class="col-xs-12" style="padding:0;height:20%" id="emailDiv">
            <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
              <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">
                <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView'  || tab == 'Normal')? '25':'10'">
                  <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView'  || tab == 'Normal')? '100':'91'" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'"> EMAILS ({{emailslist.length}})</span>
                </div>

                <div *ngIf="tab === 'QuickView'  || tab == 'Normal'" (click)="expandaccountemails('EMAILS')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%;">
                  <img *ngIf="emailslist.length>0" src="../../../assets/img/Emails Green Icon - Account Details Box.png" style="height:70%" />
                  <img *ngIf="emailslist.length==0" src="../../../assets/img/Emails Grey Icon - Account Details Box.png" style="height:70%" />
                </div>

              </div>
            </div>
            <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0%' :'0 0.8% 0 0.8%'">
              <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">
                <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView'  || tab == 'Normal')? '25':'10'">
                  <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView'  || tab == 'Normal')? '100':'91'" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'"> SMS  (0)</span>
                </div>

                <div *ngIf="tab === 'QuickView'  || tab == 'Normal'" (click)="expandsms('SMS')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%">
                  <img  src="../../../assets/img/SMS Grey Icon - Account Details Box.png" style="height:70%" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12" style="padding:0;height:20%">
            <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
              <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">
                <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView'  || tab == 'Normal')? '25':'10'">
                  <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView'  || tab == 'Normal')? '100':'91'" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'"> DOCUMENTS ({{userdocumentlist.length}})</span>
                </div>

                <div *ngIf="tab === 'QuickView'  || tab == 'Normal'" (click)="expanddocuments('WithOut Proposal','DOCUMENTS')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%;">
                  <img *ngIf="userdocumentlist.length>0" src="../../../assets/img/Documents - QuickView Page Icon - Green.png" style="height:70%" />
                  <img *ngIf="userdocumentlist.length==0" src="../../../assets/img/Documents - QuickView Page Icon - Grey.png" style="height:70%" />
                </div>

              </div>
            </div>
            <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
              <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">
                <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView'  || tab == 'Normal')? '25':'10'">
                  <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView'  || tab == 'Normal')? '100':'91'" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">CAMPAIGNS ({{Campignslist.length}})</span>
                </div>
                <div *ngIf="tab === 'QuickView'  || tab == 'Normal'" (click)="expandcampaigns('CAMPAIGNS')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%">
                  <img *ngIf="Campignslist.length>0" src="../../../assets/img/Campaigns Green Icon - Account Details Box.png" style="height:70%" />
                  <img *ngIf="Campignslist.length==0" src="../../../assets/img/Campaigns Grey Icon - Account Details Box.png" style="height:70%" />
                </div>
              </div>
            </div>

          </div>

          <div class="col-xs-12" style="padding:0;height:20%">
            <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
              <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">
                <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView'  || tab == 'Normal')? '25':'10'">
                  <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView'  || tab == 'Normal')? '100':'91'" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">  OPPORTUNITIES ({{Opportunitylist.length}})</span>
                </div>

                <div *ngIf="tab === 'QuickView'  || tab == 'Normal'" (click)="expandOpportunities('OPPORTUNITIES')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%;">
                  <img *ngIf="Opportunitylist.length>0" src="../../../assets/img/Account Details Opportunities Green.png" style="height:70%" />
                  <img *ngIf="Opportunitylist.length==0" src="../../../assets/img/Account Details Opportunities Grey.png" style="height:70%" />
                </div>

              </div>
            </div>
            <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
              <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">
                <!--<div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView'  || tab == 'Normal')? '25':'10'">
                  <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView'  || tab == 'Normal')? '100':'91'" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'"> PROPOSALS & PO ({{POdocumentlist.length}})</span>
                </div>-->

                <!--<div *ngIf="tab === 'QuickView'  || tab == 'Normal'" (click)="expanddocuments('With Proposal','
                PROPOSALS AND PURCHASE ORDERS')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%">

                  <img *ngIf="POdocumentlist.length>0" src="../../../assets/img/Account Details Proposals Green.png" style="height:70%" />
                  <img *ngIf="POdocumentlist.length==0" src="../../../assets/img/Account Details Proposals Grey.png" style="height:70%" />
                </div>-->

              </div>
            </div>
          </div>

          <div class="col-xs-12" style="padding:0;height:20%">
            <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
              <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">
                <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView'  || tab == 'Normal')? '25':'10'">
                  <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView'  || tab == 'Normal')? '100':'91'" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">  TICKETS ({{ticketslist.length}})</span>
                </div>

                <div *ngIf="tab === 'QuickView'  || tab == 'Normal'" (click)="expandtickets('TICKETS')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%;">
                  <img *ngIf="ticketslist.length>0" src="../../../assets/img/Tickets - QuickView Page Icon - Green.png" style="height:70%" />
                  <img *ngIf="ticketslist.length==0" src="../../../assets/img/Tickets - QuickView Page Icon - Grey.png" style="height:70%" />
                </div>

              </div>
            </div>
            <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
              <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">
                <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView'  || tab == 'Normal')? '25':'10'">
                  <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView'  || tab == 'Normal')? '100':'91'" [style.text-align]="(tab === 'QuickView'  || tab == 'Normal')? 'center':'left'">PROJECTS ({{ProjectsList.length}})</span>
                </div>

                <div *ngIf="tab === 'QuickView'  || tab == 'Normal'" (click)="expandProjects('PROJECTS')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%">

                  <img *ngIf="ProjectsList.length>0" src="../../../assets/img/Projects - QuickView Page Icon - Green.png" style="height:70%" />
                  <img *ngIf="ProjectsList.length==0" src="../../../assets/img/Projects - QuickView Page Icon - Grey.png" style="height:70%" />
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12" id="ticketsinfo" style="padding:0;height: 100%" *ngIf="showticketsinfo">
          <div class="col-xs-12" style="padding:0;height:98%;">
            <app-history [type]="'Tickets'" [ContAccId]="this.contactid" [userType]="'Customer'"> </app-history>
          </div>
        </div>
        <div id="documentsinfo" *ngIf="documentsinfo" class="col-xs-12" style="height: 100%;padding:0;">
          <div class="col-xs-12" style="padding:0;height:5%" *ngIf="EditDocument && !ShowPDF">
            <div class="col-xs-12" style="padding:0;height:100%">
              <div *ngIf="!ShowPDF" class="col-xs-1" style="padding:0;height:95%;">
                <div class="col-xs-12" style="padding:0;height:100%">
                  <span style="text-align:right; color: gray;font-size:12px ;font-weight: normal;padding-left: 3% !important;" (click)="AddDocumentsNewAccount('New')">
                    <img src="../../../../assets/img/Create New Document Name.png" style="height:29px" />
                  </span>
                </div>

              </div>

              <div class="col-xs-6" style="text-align:left;height:100%" [ngStyle]="{'margin-bottom.%':(documentview) ? -1.5 : 0 }">

                <div class="col-xs-12" style="height:100%">
                  <h3 style="margin:0 13% 0 0;"> DOCUMENTS</h3>
                </div>

              </div>
              <div class="col-xs-5" style="padding:0;height:100%">
                <div class="col-xs-12" style="height:100%;padding-right:0;" *ngIf="!ShowPDF">
                  <select style="height:93%;width:100%;border-radius:5px" (change)="DocumentsFilterBy($event.target.value)">
                    <option> Display All</option>
                    <option value="{{item.Value}}" *ngFor="let item of DocumentsFilter"> {{item.Value}}</option>
                  </select>
                </div>
              </div>

            </div>

          </div>
          <div class="col-xs-12" style="padding:0;height:95%">
            <documentmanagement *ngIf="isDocumentsInfoDivVisible" [accountId]="this.contactid" [selectedtype]="'Account'" [accountstatisticBox]="accountstatisticBox" [isUploadDivVisible]="true" (sendMsgEvent)="onOpenUploadDocument($event)" [FilterType]="'WithOut Proposal'"></documentmanagement>
         </div>
        </div>

        <div class="col-xs-12" id="Projectsinfo" style="padding:0;height: 95%" *ngIf="Projectsinfo">
          <div class="col-xs-12" style="padding:0;height:98%;">
            <app-history [type]="'Projects'" [ContAccId]="contactid" [userType]="'Customer'"> </app-history>
          </div>
        </div>

        <div id="Proposalinfo" *ngIf="Proposalinfo" class="col-xs-12" style="height: 100%;padding:0;">
          <div class="col-xs-12" style="padding:0;height:5%" *ngIf="EditDocument && !ShowPDF">
            <div class="col-xs-12" style="padding:0;height:100%">
              <div *ngIf="!ShowPDF" class="col-xs-1" style="padding:0;height:95%;">
                <div class="col-xs-12" style="padding:0;height:100%">
                  <span style="text-align:right; color: gray;font-size:12px ;font-weight: normal;padding-left: 3% !important;" (click)="AddDocumentsNewAccount('New')">
                    <img src="../../../../assets/img/Create New Document Name.png" style="height:29px" />
                  </span>
                </div>

              </div>

              <div class="col-xs-6" style="text-align:left;height:100%" [ngStyle]="{'margin-bottom.%':(documentview) ? -1.5 : 0 }">

                <div class="col-xs-12" style="height:100%">
                  <h3 style="margin:0 13% 0 0;">Proposal Documents</h3>
                </div>

              </div>
              <div class="col-xs-5" style="padding:0;height:100%">
                <div class="col-xs-12" style="height:100%;padding-right:0;" *ngIf="!ShowPDF">
                  <select style="height:93%;width:100%;border-radius:5px" (change)="DocumentsFilterBy($event.target.value)">
                    <option> Display All</option>
                    <option value="{{item.Value}}" *ngFor="let item of DocumentsFilter"> {{item.Value}}</option>
                  </select>
                </div>
              </div>

            </div>

          </div>



          <div class="col-xs-12" style="padding:0;height:95%">
            <documentmanagement *ngIf="isDocumentsInfoDivVisible" [accountId]="this.contactid" [selectedtype]="'Account'" [accountstatisticBox]="accountstatisticBox" [isUploadDivVisible]="true" (sendMsgEvent)="onOpenUploadDocument($event)" [FilterType]="'With Proposal'"></documentmanagement>


          </div>
        </div>

        <div class="col-xs-12" id="accountemailinfo" *ngIf="accountemailinfo" style="height: 100%;padding:0;">
          <div class="col-xs-12 provider" style="text-align:left;padding:0;height:5%">
            EMAILS ({{emailslist.length}})
          </div>
          <span class="col-xs-6" style="text-align:right;color: gray;font-size:12px;height:5%; font-weight: normal;cursor: pointer;padding:2px;" (click)="close()">
            <b>
              <img src="../../../assets/img/Black x.png" style="height:17px" />
            </b>
          </span>
          <div class="col-xs-12" style="padding:0;height:95%">
            <emailsdiv [id]="this.contactid" [type]="'Customer'" [AccountContactPage]="true"></emailsdiv>
          </div>
        </div>
        <div class="col-xs-12" id="accountnotesinfo" *ngIf="accountnotesinfo" style="height:94%;padding:0;">

          <div class="col-xs-12" style="padding:0;height:100%;margin-top:0%">

            <app-new-notes style="height:100%"
                           [selectedTabName]="'1'"
                           [conid]="conid"
                           [typ]="typ"
                           [isModal]="false">
            </app-new-notes>

          </div>

        </div>
        <div id="accountactivitiesinfo" *ngIf="accountactivitiesinfo && !showAccountHistoryHx" class="col-xs-12" style="height:94%;padding:0;">
          <div class="col-xs-12" style="padding:0;height:100%;">
            <app-history [type]="'Activities'" [ContAccId]="contactid" [userType]="'Customer'"> </app-history>
          </div>

        </div>

      </div>



      <div [class]="(tab=='QuickView')?'col-xs-5':'col-xs-12'" *ngIf="AddContacttableDiv" style="padding-left: 0px;padding-right: 0%; overflow:visible;" id="tableDiv" [style.height.%]="this.accountstatisticBox == 'Close' ? 96 : 81">

      </div>


    </div>


  <div *ngIf="documentview" id="documentview" class="col-xs-6">
    <div class="col-xs-6"> Account :  <b style="font-size:14px;color:red;">{{viewaccount.viewaccountname}}</b></div>

    <div class="col-xs-1">
      <i *ngIf="documentindex > 0" class="fa fa-arrow-left " aria-hidden="true" id="previous" (click)="previousdocument()"></i>
    </div>
    <div class="col-xs-3"> <label style="color:black">{{documentindex+1}} of {{userdocumentcount}} (Documents)</label></div>
    <div class="col-xs-1">
      <i *ngIf="userdocumentcount >= documentindex" class="fa fa-arrow-right " aria-hidden="true" id="next" (click)="nextdocument()"></i>
    </div>
    <div class="col-xs-1" style="padding: 0px;float:right;">
      <button class="closeEmbed" style="float: right;" (click)="Expandimage()"><i class="fa fa-arrows-alt" aria-hidden="true"></i></button>
      <button class="closeEmbed" (click)="closeEmbed()" style="float: right;background: white;border: none;"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
    </div>
  </div>
  <div id="pdfDiv" class="col-xs-6">

  </div>
</div>

<div *ngIf="ShowContactView && contactid>0" style="height:100%;padding:0;" class="col-xs-12"> 
  <contactsdashboard [contactids]="contactid" [type]="'Contact'" [tab]="'QuickView'" (sendMsgEvent)="onAccountMessageReceived($event)"></contactsdashboard>
</div>

<div *ngIf="isTaskShow" style="height:100%">
  <scheduletask [id]="this.id" [taskid]="this.taskid" [type]="this.type" [selectorTag]="true" (sendMsgEvent)="ReceiveTaskUpdate($event)" (Taskdialoghideshow)="Taskdialoghideshow($event)"></scheduletask>
</div>
  </div>
<input type="text" value="" id="typeClicked" style="display:none;" />
<div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
  <textarea *ngIf="isShowDetailsChecked" id="txtUserFullName" readonly rows="3" name="userInfo" [(ngModel)]="userInfo" style="width:100%; border: none;"></textarea>
  <div class="col-xs-12" style="padding:0;">
    <button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog" style="float:left;position:unset; "><i class="fa fa-close"></i></button>
  </div>

  <div class="box" style="height:98%;border:none">
    <span *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" class="editnote">Edit</span>
    <span *ngIf="showEditNoteBtn" (click)="saveEditedNote()" class="save">Save</span>
    <div id="txtLongNoteDesc" class="text">
    </div>
  </div>

</div>

  <app-dialog [(visible)]="ViewPhotoDialog" *ngIf="ViewPhotoDialog" id="ViewPhotoDialog" [closable]="false" [type]="'Contactphoto'" (Change)="onaccChange($event)">
    <contact-photo [contact]="contacts" (Change)="OnReceived($event)"></contact-photo>
  </app-dialog>
<app-dialog [(visible)]="DuplicateAccountDialog" style="width:500px !important">
  <div class="col-xs-12" style="background-color: rgb(244,244,244); height: 100%; width: 100%;">
    <div class="col-xs-12" style="text-align:left;margin:1% ;height:94%;width:99%;background-color:white;margin-top:2%">
      <label style="text-align: left;font-size: 110%;margin-left:1%;height:65%">
        <label>The Account you are adding that has been identified in the System as being Deleted by <span style="color:red">{{deleteduserName}}</span></label><br /><br />

        <label> To Continue Adding the Account please Select Continue Or Select Cancel to dischard the Account Data you just Entered for this Account. </label><br /><br />

        <label>
          By Selecting CANCEL,you have the option of requesting the user of the deleted account to
          Change the Status of Account from Deleted to Active.An Administrator of the system can also
          re-activate the account and assign it to you.
        </label><br /><br />
        <label> When an account is re-activated ,all preious notes, leads, opportunites, tasks and emails are available for viewing depending on your configuration and access.<br /></label>

      </label>
      <br /><br /><br />
      <div class="col-xs-12" style="margin-top: 0%;margin-bottom: 0%;height:25%">
        <div class="col-xs-6 pull-left" style="padding-right:0;height:100%">
          <button (click)="cancelDialog()" class="btn noprovider" style="width: 60%;height: 70%;border: 1px solid black !important;">Cancel</button>
        </div>

        <div class="col-xs-6 pull-right" style="height:100%">
          <button class="btn noprovider pull-right" style="width: 60%;height: 70%;border: 1px solid black !important;background: #93d250;" (click)="Continue()">Continue</button>

        </div>
      </div>

    </div>
  </div>




</app-dialog>
<app-dialog [(visible)]="SaveAccDialog" style="width:500px !important">
  <div class="col-md-12" *ngIf="SaveAccDialog" style="padding:0;margin-top:0%;">
    <div class="col-xs-12" style="text-align:center;font-size:24px;">

      <label>Warning-Duplicate Information has been found </label>
      <div>{{account.accountname}}</div>
      <div>{{account.address1}},&nbsp;{{account.address2}}</div>
      <div>{{account.city}},{{account.state| uppercase}},{{account.zip | slice:0:5}}<span *ngIf="account.zip && account.zip.length > 5">-{{account.zip | slice:5:20}}</span></div>
      <div>  Fax: {{account.fax}}</div>
      <div *ngIf="(websiteduplicate)"><label id="lblwebsite" style="color:red">{{account.website}}</label></div>

      <div *ngIf="(!websiteduplicate)"><label>{{account.website}}</label></div>
      <div>Owner:{{currentuser.username}}</div>
      <br /><br />

      <div class="col-xs-12">
        <div class="col-xs-6">
          <button class="btn" style="width:100%;background-color:#92d050;height: 35px" (click)="SaveAccountInfo('null')">Yes-Proceed with Saving Duplicate Information</button>

        </div>
        <div class="col-xs-6">
          <button class="btn" style="width:100%;background-color:#92d050;height: 35px" (click)="closeduplicatediv()"> No-I want to change the duplicate information</button>

        </div>
      </div>

    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="showmapWarning">
  <div style=" font-size: 19px;width:100%;">
    <div style="text-align:center;font-size:21px;">    <b>Adding Account Address</b></div>
    <div style="text-align:justify;padding: 0 3% 0 2%;">
      <br />
      <span>
        The Address for this account was not selected from
        google Address list.Please select one of the two
        options below: Select <b>"  Go to Google Address "</b> and
        select google address for the account -or- select
        <b>"Save"</b> and system will save the address you have
        added to account.
      </span>
      <br /><br />
    </div>
    <div class="col-xs-12" style="margin-top: 2%;">
      <div class="col-xs-6" style="padding: 0;">
        <button class="btn noprovider" (click)="showmapWarning= !showmapWarning" style="width:85%;height: 31px;background-color:dodgerblue;border-radius:5px;">Go to Google Address</button>

      </div>
      <div class="col-xs-6" style="padding-right:0;text-align:right;">
        <button class="btn noprovider" (click)="SaveCustomeAddress()" style="width:85%;height: 31px;background-color:#93d250;border-radius:5px;">Save</button>
      </div>
    </div>
  </div>

</app-dialog>


<app-dialog [(visible)]="showAddEditAccount" *ngIf="showAddEditAccount" id="addEditAccount"
            [closable]="(action =='EditAccount')? false : false" (visibleChange)="CloseProposalPage($event)" [OutSideClick]="(action =='EditAccount')? false : true" [CloseButtonColor]="'white'">
  <app-add-edit-account *ngIf="action =='EditAccount'" [accountID]="this.account.id" [type]="'Account'" (sendMsgEvent)="ReceiveEvent($event)"></app-add-edit-account>

  <accountorcontactview *ngIf="action=='View'" [accountId]="this.account.id" [type]="'Account'" (btnClickEvent)="btnClickReceived($event)"></accountorcontactview>
</app-dialog>

<app-dialog [(visible)]="showAddEditContact" *ngIf="showAddEditContact" id="addEditContact" [closable]="false" [OutSideClick]="false">
  <editcontact [contactid]="contactId" [accountId]="account.id" [type]="'Contact'" (sendMsgEvent)="ContactReceiveEvent($event)"></editcontact>
</app-dialog>
<app-dialog [(visible)]="DeleteConfirmationPopup" *ngIf="DeleteConfirmationPopup" id="DeleteConfirmationPopup" [closable]="false">
  <app-delete-popup [status]="contactStatus" [MessageType]="'Contact'" (sendMsgEvent)="ReceiveDeleteevent($event)">

  </app-delete-popup>
</app-dialog>
<app-dialog [(visible)]="quickViewHistoryDialog" *ngIf="quickViewHistoryDialog" id="quickViewHistoryDialog" (visibleChange)="onCloseDialogEventReceived($event)" [closable]="false">
  <div class="col-xs-12" style="height: 2%; padding: 0%; background-color: rgb(186,220,153)"></div>
  <div class="col-xs-12" style="height: 5%;padding: 0%;border-bottom:1px solid gray">
  
    <div class="col-xs-11" style=" padding: 1%; height: 100%">
      <label>{{historyName}} / ACCOUNT DETAILS</label>
    </div>
    <div class="col-xs-1" style="padding:0;height:100%">
      <label class="col-xs-12" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;padding:2px;float:right;z-index:1;height:100%;padding:9%;">
        <img src="../../../assets/img/Black x.png" style="height:20px" (click)="closeDoc()" />
      </label>
    </div>

  </div>
  <hr>
  <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%" *ngIf="quickViewnotesDialog">

    <app-new-notes style="height:100%"
                   [selectedTabName]="'1'"
                   [conid]="conid"
                   [typ]="typ"
                   [isModal]="false">
    </app-new-notes>

  </div>
  <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%" *ngIf="quickViewActivitiesDialog">
    <app-history [type]="'Activities'" [ContAccId]="contactid" [userType]="'Customer'"> </app-history>
  </div>
  <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%" *ngIf="quickViewCampaignsDialog">
    <div class="col-xs-12" style="padding:0;height:5%">
      <div class="col-xs-9" style="padding:0;height:100%;float:right">

        <div class="col-xs-4" style="padding:0 0 0 0;height:100%">

        </div>
        <div class="col-xs-4" style="height: 100%;">

        </div>
        <div class="col-xs-4" style="height: 100%;">
          <select style="height:90%;width:100%;border-radius:5px;" (change)="CampaignsDropdownChange($event.target.value)">
            <option *ngFor="let stat of StatusDropdownList" value={{stat.value}}>
              {{stat.itemName}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <app-campaigns [CampaignId]="0" [ShowViewCampaign]="false" [Source]="'AccountView'" [AccountcontactIds]="AccountcontactIds" [type]="CampaignType" (sendMsgEvent)="CampaignReceived($event)"> </app-campaigns>
  </div>
  <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%" *ngIf="quickViewSMSDialog">
    <app-history [type]="'sms'" [AccountcontactIds]="AccountcontactIds" [userType]="'Customer'"> </app-history>
  </div>
  <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%" *ngIf="QuickViewOpportunityInfo">
    <app-history [type]="'Opportunities'" [ContAccId]="contactid" [userType]="'Customer'"> </app-history>
  </div>
  <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%" *ngIf="QuickViewProjectInfo">
    <app-history [type]="'Projects'" [ContAccId]="contactid" [userType]="'Customer'"> </app-history>
  </div>
  <div *ngIf="QuickViewDocumentsinfo" class="col-xs-12" style="height: 92%;padding:0;margin-top:0.5%">
    <div class="col-xs-12" style="padding:0;height:5%" *ngIf="EditDocument && !ShowPDF">
      <div class="col-xs-12" style="padding:0;height:100%">
        <div *ngIf="!ShowPDF" class="col-xs-1" style="padding:0;height:95%;">
          <div class="col-xs-12" style="padding:0;height:100%">
            <span style="text-align:right; color: gray;font-size:12px ;font-weight: normal;padding-left: 3% !important;" (click)="AddDocumentsNewAccount('New')">
              <img src="../../../../assets/img/Create New Document Name.png" style="height:25px" />
            </span>
          </div>
        </div>
        <div class="col-xs-6" style="text-align:left;height:100%" [ngStyle]="{'margin-bottom.%':(documentview) ? -1.5 : 0 }">
          <div class="col-xs-12" style="height:100%;padding:2%" *ngIf="!ShowPDF">
          </div>

        </div>
        <div class="col-xs-5" style="padding:0;height:100%">
          <div class="col-xs-12" style="height: 100%; width: 341px" *ngIf="!ShowPDF && !uploadDocumentShow">
            <select style="height:93%;width:100%;border-radius:5px" (change)="DocumentsFilterBy($event.target.value)">
              <option> Display All</option>
              <option value="{{item.Value}}" *ngFor="let item of DocumentsFilter"> {{item.Value}}</option>
            </select>
          </div>
        </div>

      </div>
    </div>
    <div class="col-xs-12" style="padding:0;height:95%;margin-top:0.5%">
      <div class="col-xs-12" *ngIf="!uploadDocumentShow" style="padding:0;height:100%">
        <documentmanagement *ngIf="isDocumentsInfoDivVisible" [accountId]="this.contactid" [selectedtype]="'Account'" [accountstatisticBox]="accountstatisticBox" [isUploadDivVisible]="true" (sendMsgEvent)="onOpenUploadDocument($event)" [FilterType]="'WithOut Proposal'"></documentmanagement>

      </div>
      <div class="col-xs-12" *ngIf="uploadDocumentShow" style="padding:0; height:100%;">
        <upload-document [accountId]="this.contactid" [selectedtype]="'Account'" (sendMsgEvent)="SavedDocuments($event)"> </upload-document>
      </div>
    </div>

  </div>
  <div *ngIf="QuickViewProposalsinfo" class="col-xs-12" style="height: 92%;padding:0;margin-top:0.5%">
    <div class="col-xs-12" style="padding:0;height:5%" *ngIf="EditDocument && !ShowPDF">
      <div class="col-xs-12" style="padding:0;height:100%">
        <div class="col-xs-6" style="text-align:left;height:100%;padding:0px" [ngStyle]="{'margin-bottom.%':(documentview) ? -1.5 : 0 }">

        </div>
        <div class="col-xs-3" style="padding:0;height:100%">
          <div class="col-xs-12" style="height:100%;" *ngIf="!ShowPDF && !uploadDocumentShow">
            <select style="height:93%;width:100%;border-radius:5px" (change)="DocumentsFilterBy($event.target.value)">
              <option> Display Proposal & PO</option>
              <option value="{{item.Value}}" *ngFor="let item of DocumentsFilter"> {{item.Value}}</option>

            </select>
          </div>
        </div>
        <div class="col-xs-3" style="padding:0;height:100%">
          <div class="col-xs-12" style="height:100%;width:200px" *ngIf="!ShowPDF && !uploadDocumentShow">
            <select style="height:93%;width:100%;border-radius:5px" (change)="DocumentsStatusBy($event.target.value)">
              <option *ngFor="let stat of postatuslist" value={{stat.id}}>
                {{stat.itemName}}
              </option>
            </select>
          </div>
        </div>

      </div>


    </div>



    <div class="col-xs-12" style="padding:0;height:95%;margin-top:0.5%">

      <div class="col-xs-12" *ngIf="!uploadDocumentShow" style="padding:0;height:100%">
        <documentmanagement *ngIf="isDocumentsInfoDivVisible" [accountId]="this.contactid" [selectedtype]="'Account'" [accountstatisticBox]="accountstatisticBox" [isUploadDivVisible]="true" (sendMsgEvent)="onOpenUploadDocument($event)" [FilterType]="'With Proposal'"></documentmanagement>

      </div>
      <div class="col-xs-12" *ngIf="uploadDocumentShow" style="padding:0; height:100%;">
        <upload-document [accountId]="this.contactid" [selectedtype]="'Account'" (sendMsgEvent)="SavedDocuments($event)"> </upload-document>
      </div>
    </div>

  </div>
  <div class="col-xs-12" style="padding:0;height:95%;margin-top:0.5%" *ngIf="QuickViewEmailinfo">
    <div class="col-xs-12 provider" style="text-align:left;padding:0;height:5%">
      <div class="col-xs-8" style="height:100%;padding-right:0;">

      </div>
      <div class="col-xs-4" style="height: 100%; width: 270px;">
        <select style="height:93%;width:100%;border-radius:5px">
          <option> Display All</option>
        </select>
      </div>
    </div>
    <div class="col-xs-12" style="height:95%;padding:0;">
      <emailsdiv [id]="this.contactid" [type]="'Customer'" [AccountContactPage]="true"></emailsdiv>
    </div>
  </div>
  <div class="col-xs-12" style="padding:0;height:94%;margin-top:0.5%" *ngIf="QuickViewTicketsDialog">
    <app-history [type]="'Tickets'" [ContAccId]="this.contactid" [userType]="'Customer'"> </app-history>
  </div>
</app-dialog>

<app-dialog [(visible)]="isSharing" *ngIf="isSharing" id="isSharingDialog" [closable]="false">
  <ownershipsharing [AccId]="account.id" [PageType]="this.tab" (sendMsgEvent)="onCloseEventReceived($event)"></ownershipsharing>
</app-dialog>



<app-dialog [(visible)]="LicenseExistWarningDialog" *ngIf="LicenseExistWarningDialog" id="LicenseExistWarningDialog" [closable]="false">
  <div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
    <span style="font-size:15px;">License Alert</span><br />
    <div style="padding:0;margin-top:1%">
      <span style="font-size:15px;">The Contact has a license for this Account. You must delete the license assigned to this Contact before deleting the Contact</span><br />
    </div>
    <button class="btn" (click)="LicenseExistWarningDialog = false" style="float:right;background-color: #0098f7 !important;color: white;margin-top:0%">OK</button>
  </div>

</app-dialog>
