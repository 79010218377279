<div class="col-xs-12 " id="Opptdetails" style="padding-left: 0.5% !important;padding-right:0;height:100%">
  <div class="col-xs-12" style="padding:0;height:2%;background-color:orangered"></div>
    <div class="col-xs-12" style="padding:0;height:4%;">
      <div class="col-xs-12" style="padding:1%;height:100%;">
        <b id="lblOpp" style="margin:0;font-size:13px;">{{ this.opportunities.id > 0 ? 'EDIT OPPORTUNITY' : 'ADD NEW OPPORTUNITY' }}</b>
        <img src="../../../assets/img/Black x.png" style="height:20px;float:right" (click)="CloseCreateTicket()" />
        <div class="col-xs-2" style="padding:0;height:100%;float:right" *ngIf="showPDFDiv">
          <img src="../../../assets/img/Expand View.png" style="height:85%;width:20%;float:right" *ngIf="!ExpandPDFDiv" (click)="Expand(true)" />
          <img src="../../../assets/img/Collapse View .png" style="height:85%;width:10%;float:right" *ngIf="ExpandPDFDiv" (click)="Expand(false)" />
        </div>
      </div>
    </div>
    <div class="col-xs-12" style="padding:0;height:94%;overflow:auto" *ngIf="!showPDFDiv">
      <form [formGroup]="NewForm" style="height:100%;padding:1% 0 0 0" *ngIf="Fields && Fields.length > 0">
        <div *ngFor="let field of Fields" class="col-xs-12" style="height:5%;padding:0;">
          <div class="col-xs-12 removeCompletePadding" style="padding:0;height:100%">
            <div class="col-xs-3" style="padding: 0 0 0 2%; font-size: 0.8em;">
              {{field.DisplayAs}}
            </div>
            <div class="col-xs-9" style="height:100%">
              <div class="col-xs-12" style="padding:0;height:100%">
                <div class="{{field.ColumnName}}" style="padding:0;height:100%" *ngIf="field.ColumnName == 'item' || field.ColumnName == 'leadfromid' || field.ColumnName == 'drs' || field.ColumnName == 'midlevels' || field.ColumnName == 'PC'">
                  <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}"
                          [formControlName]="field.ColumnName" [(ngModel)]="opportunities[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)" style="height:92%;padding:0 0 0 1%;font-size:13px;"
                          [attr.disabled]="opportunities.source=='Proposal'?true:null">
                    <option *ngFor="let customer of field.Data" value="{{customer.id}}" style="color:black">
                      {{customer.opportunityitem}}
                    </option>
                  </select>
                </div>
                <div class="{{field.ColumnName}}" style="padding:0;height:100%" *ngIf="field.ColumnName == 'status'">

                  <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}"
                          [formControlName]="field.ColumnName" [(ngModel)]="opportunities[field.ColumnName]" *ngIf="((opportunities.isPended=='1') &&(field.DataType == 'Pick List' || field.DataType == 'System Lookup'))" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value);StatusChange($event)" style="height: 92%; padding: 0 0 0 1%; font-size: 13px" disabled="disabled">

                    <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                      {{customer.opportunityitem}}
                    </option>
                  </select>
                  <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}"
                          [formControlName]="field.ColumnName" [(ngModel)]="opportunities[field.ColumnName]" *ngIf="((opportunities.isPended==null ||opportunities.isPended=='0') &&(field.DataType == 'Pick List' || field.DataType == 'System Lookup'))" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value);StatusChange($event)" style="height: 92%; padding: 0 0 0 1%; font-size: 13px">

                    <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                      {{customer.opportunityitem}}
                    </option>
                  </select>

                </div>
                <div class="{{field.ColumnName}}" style="padding:0;height:100%" *ngIf="field.ColumnName == 'ownerid'">

                  <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="opportunities[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)" style="height: 92%; padding: 0 0 0 1%; font-size: 13px" [attr.disabled]="opportunities.source=='Proposal'?true:null">
                    <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                      {{customer.itemName}}
                    </option>
                  </select>
                </div>

                <div class="{{field.ColumnName}}" style="padding:0;height:100%">
                  <input value="{{opportunities[field.ColumnName]}}" [(ngModel)]="opportunities[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (keyup)="FormDataEntered($event.target)" style="height: 92%; padding: 0 0 0 1%; font-size: 13px" [attr.disabled]="opportunities.source=='Proposal'?true:null" />

                  <input type="date" class="form-control decreasefieldSize" [formControlName]="field.ColumnName" [(ngModel)]="opportunities[field.ColumnName]" *ngIf="(field.DataType == 'Date' ||  field.DataType == 'Date Range') &&
                       field.ColumnName=='estimatedclosedate'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" value="2018-01-01" (change)="FormDataEntered($event.target)" style="height:92%; padding: 0 0 0 1%; font-size: 13px" [attr.disabled]="opportunities.status!='108'?true:null" />

                  <input type="date" class="form-control decreasefieldSize" [formControlName]="field.ColumnName" [(ngModel)]="opportunities[field.ColumnName]" *ngIf="(field.DataType == 'Date' ||  field.DataType == 'Date Range') &&
                       field.ColumnName=='actualclosedate'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" value="2018-01-01" (change)="FormDataEntered($event.target)" style="height: 92%; padding: 0 0 0 1%; font-size: 13px" [attr.disabled]="opportunities.status=='108'?true:null" />
                </div>


              </div>

            </div>
          </div>


        </div>

        <div class="col-xs-12 zeroPadding" style="height:30%;padding:0;">
          <div class="col-xs-3" style="padding: 1% 0 0 2%;height:90%;font-size:13px;">

          </div>
          <div class="col-xs-9" style="height:90%">
            <div class="col-xs-12" style="height:100%;border: 1px solid black;padding: 0%;">
              <div class="col-xs-12" style="padding:0;height:100%">
                <textarea id="notesArea" rows="4" class="form-control scroll-c" value="notes.stagenotedescription" [(ngModel)]="notes.stagenotedescription" type="text" name="notedescription" placeholder="Enter New Note" style="height: 100%; overflow: auto; border: none; background: #f5f5f5; padding: 2%;" (keyup)="NotesKeyUp($event)" [formControlName]="'notedescription'"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-4" id="SaveCancelOpp" *ngIf="SaveCancelOppDiv" style="float: right; height: 9%;">

          <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width: 100%; background: white; padding: 5px 7px;" id="CancelOpp" (click)="CancelOpportunity('Cancel')"><span class="mainCheckMark"></span>Cancel</button></div>
          <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width: 100%; background: #a2e477; padding: 5px 7px;" [disabled]="!NewForm.valid" (click)="Save();" id="saveOpp"><span class="mainCheckMark"></span>Save</button></div>
        </div>
      </form>
    </div>


  </div>



  <app-dialog [(visible)]="!SaveCancelOppDiv" *ngIf="!SaveCancelOppDiv" id="WonLostPendingDiv" (visibleChange)="onCloseDialog($event)" [closable]="false">
    <!--<div class="col-xs-12" id="statusdiv" style="padding:0px">-->
    <div class="col-xs-12" style="border-right: 1px solid lightgray; height: 25%; margin-top: 2%;">
      <div class="col-xs-11" style="padding: 0%;">
        <div class="col-xs-12" style="padding:0;font-size:18px">
          <b>CLOSE OPPORTUNITY</b>
        </div>
        <div class="col-xs-12" style="padding: 0; font-size: 15px; height: 25px; padding: 2% 0 0 0;">
          <div class="col-xs-3" style="padding:0;">
            Status :
          </div>
          <div class="col-xs-8">
            <span *ngIf="opportunities.status == '109'">Won</span>
            <span *ngIf="opportunities.status == '110'">Lost</span>
            <span *ngIf="opportunities.status == '111'">Pending</span>
          </div>

        </div>
        <div class="col-xs-12" style="padding: 0; height: 100%; font-size: 15px; height: 25px; padding: 2% 0 0 0;">
          <div class="col-xs-3" style="padding:0;">
            Date :
          </div>
          <div class="col-xs-4">
            <div style="color:red">{{opportunities.actualclosedate | date:"MM/dd/yyyy"}}</div>
          </div>
          <div class="col-xs-4">
            <input type="date" class="form-control decreasefieldSize" [(ngModel)]="opportunities.actualclosedate" />
          </div>
        </div>

      </div>
      <div class="col-xs-1" style="padding: 0%; float: right;">
        <img src="../../../assets/img/Black x.png" style="height: 20px" (click)="SaveCancelOppDiv=true;" />
      </div>
    </div>

    <div class="col-xs-12" style="height: 65%">
      <div class="col-xs-12" style="height:83%;padding:3% 0 0 0;">
        <textarea id="notesArea1" rows="18" class="form-control scroll-c" [(ngModel)]="notes.stagenotedescription" type="text" name="notedescription" placeholder="Type here to add notes to this Opportunity" style="height:98%;overflow:auto;border: none;background: #f1f1f1;" (keyup)="NotesKeyUp($event)"></textarea>
      </div>
      <div class="col-xs-12" style="height: 20%;padding:0%;" *ngIf="notes.stagenotedescription && notes.stagenotedescription != null && notes.stagenotedescription != '' && notes.stagenotedescription!= undefined">
        <div class="col-xs-8" id="saveNotesButtons" style="padding-left: 1%;padding:0; float:right;margin-top:2%;">
          <button class="btn cancel" type="submit" style="border: 1px solid;width:48%;font-weight:normal" (click)="notes.stagenotedescription=''">Cancel</button>
          <button class="btn provider saveButton" type="submit" style="margin-left: 2.2%; width: 49%; height: 27px; font-weight: normal; border: 1px solid black !important; " (click)="ChangeStatus(this.opportunities.id,this.opportunities.status)">Save</button>
        </div>
      </div>
    </div>
    <!--</div>-->

  </app-dialog>

  <app-dialog *ngIf="notesshowDialog && contactid>0" [(visible)]="notesshowDialog" style="height:100%" [CloseButtonColor]="'white'">
    <app-new-notes style="height:100%"
                   [selectedTabName]="'Opportunity Only'"
                   [conid]="conid"
                   [OpportunityId]="OpportunityID"
                   [typ]="typ"
                   [Opptype]="'Opportunity'"
                   [isModal]="true">
    </app-new-notes>


  </app-dialog>

  <app-dialog [(visible)]="OpportunityNotesDialog" id="OpportunityNotesDialog" style="height:100%" [CloseButtonColor]="'black'">
    <div class="col-xs-12" style="padding:0;height:100%">
      <div class="col-xs-12" style="padding:0;text-align:center;background-color:#badb98;color:black;height:6%">
        <label style="margin-top:0.5%">Add New Notes</label>
        <div class="col-xs-1" style="text-align:right;color: gray;font-size:12px; font-weight: normal;float:right;padding:2px;" (click)="OpportunityNotesDialog = false">
          <b>
            <img src="../../../assets/img/Collapse View .png" style="height:17px" />
          </b>
        </div>
      </div>
      <div class="col-xs-12" style="padding: 0;height:85%;overflow:auto" id="noteidDiv">
        <div id="noteid" [froalaEditor]="editorOptions" [(froalaModel)]="notes.stagenotedescription" style="padding-right: 0%;height:99%"></div>

      </div>
      <div class="col-xs-4" id="saveNotesButtons" style="padding-left: 1%;padding:0; height:8%;float:right;margin-right:2%;">
        <button class="btn cancel" (click)="OpportunityNotesDialog = false" type="submit" style="padding: 0.75%;border: 1px solid;width:48%;font-weight:normal">Cancel</button>
        <button class="btn provider saveButton" (click)="addOpportunity()" type="submit" style="margin-left: 2.2%;padding: 0.75%;width: 49%; font-weight:normal;border:1px solid black !important;">Save </button>
      </div>
    </div>

  </app-dialog>

  <app-dialog [(visible)]="SelectOpprotunityDialog" *ngIf="SelectOpprotunityDialog" id="SelectOpprotunityDialog">

    <div class="col-xs-12" style="height:99%">
      <div class="col-xs-12" style="padding:0;height:30%"><label style="font-size:19px;font-weight:800">SalesXCRM Message</label></div>
      <div class="col-xs-12" style="padding:0;height:20%">
        <b style="font-size:15px;text-transform:uppercase">Opportunity</b>
      </div>
      <div class="col-xs-12" style="padding:0;height:50%">
        <div style="padding:0;height:100%" class="col-xs-9">
          <b style="font-size:15px;">Please Select an Opportunity</b><br />

        </div>
        <div class="col-xs-3" style="padding:0;height:100%">
          <button class="btn" style="background-color: #0098f7 !important;color: white;margin-top:-5%;margin-left:15%" (click)="SelectOpprotunityDialog = false">OK</button>

        </div>
      </div>
    </div>
  </app-dialog>
  <app-dialog [(visible)]="CloseCreateNewWarning" id="CloseCreateNewWarning" *ngIf="CloseCreateNewWarning">
    <app-delete-popup *ngIf="opportunities.id == null ||opportunities.id == 0 || opportunities.id == undefined" [status]="0" [MessageType]="'CloseOpp'" [title]="'Create Opportunity'"
                      [description]="'New Opportunity will NOT be saved?'" [isok]="true" [issave]="false"
                      [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteCampaignevent($event)">
    </app-delete-popup>
    <app-delete-popup *ngIf="opportunities.id>0" [status]="0" [MessageType]="'CloseOpp'" [title]="'Edit Opportunity'"
                      [description]="'Changes will not be saved?'" [isok]="true" [issave]="false"
                      [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteCampaignevent($event)">
    </app-delete-popup>
    <!--<div class="col-xs-12" style="margin-top: 5%;">
      <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br /><br />
      <div *ngIf="opportunities.id == null ||opportunities.id == 0 || opportunities.id == undefined"  class="col-xs-8" style="padding: 0;">
      <div class="col-xs-12" style="padding: 0;text-transform:uppercase ">Create Opportunity</div>
      <div class="col-xs-12" style="padding: 0; ">New Opportunity will NOT be saved?</div>
    </div>
      <div *ngIf="opportunities.id>0" class="col-xs-8" style="padding: 0; font-size: 17px; font-weight: 700;margin-top:2%">
        <div *ngIf="opportunities.id == null ||opportunities.id == 0 || opportunities.id == undefined" class="col-xs-12" style="padding: 0;text-transform:uppercase ">Edit Opportunity</div>
        <div class="col-xs-12" style="padding: 0; ">Changes will not be saved?</div>
      </div>
      <div class="col-xs-4" style="padding:0;">
        <div class="col-xs-12" style="padding:0">
          <button class="btn" style="background-color: #0098f7 !important;color: white;margin-top:1%"
                  (click)="CloseCreateNewWarning = false;CloseAddOpportunityDialog()">
            OK
          </button>
          <button class="btn" style="float:right;color: black;margin-top:1%"
                  (click)="CloseCreateNewWarning = false; ">
            Cancel
          </button>
        </div>

      </div>
    </div>-->
  </app-dialog>
