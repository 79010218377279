import { Component, OnInit, Input } from '@angular/core';
import { Emails, Attachements } from '../../models/emails';
import { CustomerService } from '../../services/customer.service';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { ApplicationComponent } from '../application.component';
declare var $: any;
@Component({
  selector: 'emailsdiv',
  templateUrl: './emailsdiv.component.html',
  styleUrls: ['./emailsdiv.component.css']
})
export class EmailsdivComponent implements OnInit {
  @Input()
  id: number;
  @Input()
  AccountContactPage: boolean = false;
  @Input()
  ContactDashBoardPage: boolean = false; 
  @Input()
  type: string;
  divhght = (screen.height) * 0.21;
  currentPage: number = 1;
  emailslist: Array<Emails> = [];
  emailAttachments: Array<Attachements> = [];
  datePipe: DatePipe = new DatePipe("en-US");
  public emailcolumnDefs: any[];
  public gridemailOptions: GridOptions;
  SentEmailTable: boolean = true;
  public emialdivId: number = 0;
  public sideBar;
  constructor(private customerService: CustomerService, public applicationComponent: ApplicationComponent) { }

  ngOnInit() {
     
    this.loadEmailHistory(this.id, this.type);
 //  document.getElementById('messageBox0').style.display = 'block';

    
  }
  rowDoubleClickAgGrid(rowEvent) {
    this.SentEmailTable = false;
  }
 
  loadEmailHistory(contactid, type) {
     
    this.customerService.GetAllEmailsList(contactid, type).then((emails) => {
      emails.forEach(emails => {
         
        emails.sentemailDate = this.datePipe.transform(emails.sentemailDate, "MM/dd/yyyy hh-mm-ss");
        emails.messagebody = emails.messagebody;
      
          if (emails.filenames != null && emails.filenames.includes(",")) {
            emails.attachedfiles = (emails.filenames != null && emails.filenames.includes(",")) ? emails.filenames.split(",") : [];
          } else { emails.attachedfiles = [emails.filenames] }

    });
      this.emailslist = emails;
      // 
      //this.loademailAttachment(this.emailslist[0].id)
    });
  //this.loademailAttachment(this.emailslist[0].id)
  }

  openAttachment(filename) {
    //var data = filename.split("|");
    this.customerService.GetByAttachmentId(filename.split("|")[1]).then(result => {
      this.openfile(result.imagecontent,result.filename, result.filetype);
    });
  }
  openfile(myBaseString, fileName, documenttype) {
    
    var data = myBaseString.split(",")
    var binary_string = window.atob(data[1]);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    var arrayBufferView = new Uint8Array(bytes.buffer);
    if (documenttype == "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      var blob = new Blob([arrayBufferView], { type: documenttype });
    else
      var blob = new Blob([arrayBufferView], { type: 'image/png/html' });

    var url = window.URL.createObjectURL(blob);

    var a = $("<a>").attr("href", url).attr("target", "_blank").attr("download", fileName).appendTo("body");

    a[0].click();

    a.remove();
    //this.sendMsgEvent.emit(myBaseString);
    ////$('#window').attr('style', 'padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-left: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');
    ////$('#pdfDiv').html('<button class="closeEmbed" onclick="closeEmbed()" style="float: right;background: white;border: none; display:block" ><i class="fa fa-times-circle-o" aria-hidden="true"></i></button><embed src="' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
    ////$('#pdfDiv').attr('style', 'height: 513px;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');
    //// this.isPdfOpen = true;
  }

  loademailAttachment(emailid) {
   
    this.customerService.GetAllEmailsAttachmentList(emailid).then((emails) => {     
      this.emailAttachments = emails;
    });
  }
  onsentemailAgGridReady(val) {
    this.gridemailOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };

    this.emailscreateColumnDefs("");
    this.gridemailOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }

    //var url = window.location.href;
    //if (url.indexOf('quick-view') > -1) {
    //  $('#emailsDivGrid').css('height', (screen.height * 0.425) + 'px');
    //}
  }
  emailscreateColumnDefs(columnSelections: string) {
    this.emailcolumnDefs = [
      { field: 'sentemailDate', headerName: 'Email Sent', hide: false },
      { field: 'sender', headerName: 'From User', hide: false },
      { field: 'tousernames', headerName: ' To', hide: false },
      { field: 'subject', headerName: 'Subject', hide: false },
      { field: 'bccusernames', headerName: 'BCC User', hide: false },
      { field: 'ccusernames', headerName: 'CC User', hide: false },
    ];

  }

  previousEmail() {
    var count = this.emialdivId;
      
    count--;
    if (count <= this.emailslist.length-1) {
      (this.currentPage)--;
      this.emialdivId = count;
      document.getElementById('messageBox' + count).style.display = 'block';

    }
  }
  nextEmail() {
     
    var count = this.emialdivId;
    
    count++;
    if (count <= this.emailslist.length-1) {
      (this.currentPage)++;
      this.emialdivId = count;
      document.getElementById('messageBox' + count).style.display = 'block';

    }
  }

 
}
