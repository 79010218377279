import { Component, OnInit, ViewContainerRef, ViewEncapsulation, ElementRef, Renderer2, ViewChild, Optional, Input } from '@angular/core';
/*import { UserService } from '../../services/user.service';*/
import { UserService } from 'src/app/services/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Users } from '../../models/users';
import { Systemconfiguration } from '../../models/systemconfiguration';
import { Dropdowntype, Dropdown, ResourcePrivileges, SaveResourcePrivileges } from '../../models/dropdowntype';
import { Tasklistconfiguration } from '../../models/tasklistconfiguration';
import { ToastrService } from 'ngx-toastr';
import { Taskgroup } from "../../models/taskgroup";
import { CustomerService } from '../../services/customer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LeadsService } from './../../services/leads.service';
import { ParentComp, UserlistComponent } from "../userlist/userlist.component";
import { UserQuotaComponent } from "../user-quota/user-quota.component";
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { Column } from 'ag-grid';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApplicationComponent } from '../application.component';
import { DocumentmanagementComponent } from '../documentmanagement/documentmanagement.component';
import { DropdownCOnfigurationComponent } from '../dropdown-configuration/dropdown-configuration.component';
import { AutomatedEmailsComponent } from '../automated-emails/automated-emails.component';
import { RoleComponent } from '../role/role.component';
import { Privilages } from 'src/app/models/privilages';
import { Dashboardconfig } from 'src/app/models/Dashboardconfig';
import { AuditLogModel } from '../../models/auditlog';
import { MyDashDefGridConfig } from '../../models/MyDashDefGridConfig';
import { userModel } from '../../models/userModel';
declare var $: any;
//import 'ag-grid-enterprise';
@Component({
  selector: 'app-configurationdashboard',
  templateUrl: './configurationdashboard.component.html',
  styleUrls: ['./configurationdashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigurationdashboardComponent implements OnInit {
  @Input()
  selectedvalue: number;
  SingleSecondDrpSetting = {};
  public sideBar;
  emailAlertUsername: any;
  emailAlertUsername2: any;
  nUId: number;
  useraccess: any;
  currentuser: any;
  userregt = screen.height * 0.50778;
  userListHgt = screen.height * 0.54;
  HeadHgt1 = screen.height * 0.0355555556;
  HeadHgt = screen.height * 0.04;
  taskgroupId: number = 0;
  userid: any;
  TaskWarningDialog: boolean = false;
  privilageslist: Privilages[] = [];
  privilages: Privilages = new Privilages();
  tasklistconfiguration: Tasklistconfiguration = new Tasklistconfiguration();
  taskgroup: Taskgroup = new Taskgroup();
  public DashboardgridOptions: GridOptions;
  tasktype: string;
  test: any;
  res: Users;
  itemCount = 0;
  itemResource: any;
  params: any;
  items = [];
  userform: FormGroup;
  user: Users = new Users();
  userModel: userModel = new userModel();
  dash: Dashboardconfig = new Dashboardconfig();
  mydash: MyDashDefGridConfig = new MyDashDefGridConfig();
  systemconfiguration: Systemconfiguration = new Systemconfiguration();
  rolesList: Dropdowntype[] = [];
  dealapprovallist: Dropdowntype[] = [];
  taskgrouplist: Dropdowntype[] = [];
  mainresourceList: Dropdowntype[] = [];
  subresourceList: Dropdowntype[] = [];
  resourcePrivilegesList: ResourcePrivileges[];
  DashBoardConfigList: Dashboardconfig[];
  emptyResourcePrivilegesList: ResourcePrivileges[];
  customertypeList: Dropdowntype[] = [];
  dropdownList = [];
  selectedItems = [];
  TaskGroupselectedItems = [];
  selectedItemsAccess = [];
  TasksGroups = [];
  selectedTasksGroups = [];
  finalitems = "";
  dropdownSettings = {};
  SingleDrpSetting = {};
  TaskGroupdropdownSettings = {};
  AccountGroupdropdownSettings = {};
  tktTaskAccessSettings = {};
  isDocumentManagementRoute: boolean = false;
  isRoleTied: boolean = false;
  showDeleteDialog: boolean = false;
  ischangesforuser: boolean = false;
  ShowTaskGrpDialog: boolean = false;
  istodoDeactivate: boolean = false;
  taskname: string = "";
  selectedtab = "";
  documentAccessName = "";
  mySalesXAccessName = "";
  showResetBtn = true;
  showCancelBtn = false;
  showPassword = false;
  public elementRef;
  toTasktypelist = [];
  phonecallTasktypelist = [];
  meetingTasktypelist = [];
  newmeetingid = 0;
  newtodoid = 0;
  newphonecallid = 0;
  tododropdowns: Dropdowntype[] = [];
  meetingdropdowns: Dropdowntype[] = [];
  phonecalldropdowns: Dropdowntype[] = [];
  totaskids = [];
  meetingtaskids = [];
  phonecalltaskids = [];
  toDoNew: boolean = true;
  phonecallNew: boolean = true;
  meetingNew: boolean = true;
  meetingbuttons: boolean = true;
  toDobuttons: boolean = true;
  phoneCallbuttons: boolean = true;
  LeadType: boolean = false;
  proposalType: string = '';
  thisComp: ParentComp = ParentComp.ConfigurationDashBoard;
  isViewInitialized: boolean = false;
  isPgClickRegistered: boolean = false;
  todoNewTextSave: boolean = true;
  todoNewButtonSave: boolean = true;
  phoneNewTextSave: boolean = true;
  phoneButtonSave: boolean = true;
  meetingNewTextSave: boolean = true;
  meetingButtonSave: boolean = true;
  todoAddnewItem: boolean = false;
  phoneAddnewItem: boolean = false;
  meetingAddnewItem: boolean = false;
  QuotaList: Dropdowntype[] = [];
  ZendeskPermissionList: Dropdowntype[] = [];
  Roleassociatedusers: Dropdowntype[] = [];
  permissionlevellist: Dropdowntype[] = [];
  userpermissionlevellist: Dropdowntype[] = [];
  userstatuslist: Dropdowntype[] = [];
  usertitlelist: Dropdowntype[] = [];
  usersuffixlist: Dropdowntype[] = [];
  timezonelist: Dropdowntype[] = [];
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  viewSuspendedUsers: boolean = false;
  selectedMenuRadio: string = "tab11";
  selectedPrvMenuRadio: string = "prvtab1";
  selectedQuotaMenuRadio: string = "Quotatab1";
  selectedUserType = "Users";
  selectedDocType: string;
  currentYear = new Date().getFullYear();
  public context;
  @ViewChild(UserlistComponent) userListComp;
  @ViewChild(DocumentmanagementComponent) docMgmtComp;
  @ViewChild(UserQuotaComponent) userquotaComp;
  @ViewChild(RoleComponent) roleComp;
  
  allusers: any;
  public gridOptionsusers: GridOptions;
  public MaingridOptions: GridOptions;
  public SubgridOptions: GridOptions;
  public columnDefsusers: any[];
  public MaincolumnDefs: any[];
  public SubcolumnDefs: any[];
  //selectedColumnIds = [];
  configurationtypeList: Dropdowntype[] = [];
  public gridApi;
  public gridColumnApi;
  public userListgridApi;
  public rowData: any[];
  public rowSelection;
  public autoGroupColumnDef;
  public columnDefs;
  private columnTypes;
  public gridOptionsView: GridOptions;
  public DashboardcolumnDefs: any[];
  DashBoardTypesList: any[];
  finaldashboardconfiglist: Dashboardconfig[] = [];
  @ViewChild(DropdownCOnfigurationComponent) Dropdownconfig;
  @ViewChild(AutomatedEmailsComponent) AutomatedEmails;
  DocumenttypeList: Dropdowntype[] = [];
  AccountGroupList: Dropdowntype[] = [];
  isDisplayCommissionEstimator: boolean = false;
  edittaskgroup: boolean = false;
  fromtaskgroupid: number = 0;
  selectedRole: string = " ";
  userView: boolean = false;
  useraccountChange: boolean = false;
  UserAccountchangeDialogs: boolean = false;
  showAddUserDiv: boolean = false;
  showUserPrivilegesDiv: boolean = false;
  showUserDashBoardDiv: boolean = false;
  showUserQuotaDiv: boolean = false;
  showAlertsDiv: boolean = false;
  //Newly Added becoz while Upgrading
  isMeetingDeactivate: boolean = false;
  isphoneDeactivate: boolean = false;
  hide: boolean = false;
  AdminAccess: boolean = false;
  usersDropdownList: Dropdowntype[] = [];
  userDrpModel = [];
  selectedQuotaType = "";
  DeleteUserAlert: boolean = false;
  passwordMissmatch: boolean = false;
  DeleteUserId: number;
  ReplaceUserId: number = 0;
  urlImage = '';
  myDashDefGridBoxlist: any;
  SecondDrpModel = [];
  secondDropdownList: Dropdowntype[] = [];
  constructor(public applicationComponent: ApplicationComponent, private http: HttpClient, private userservice: UserService, private opprtunityservice: OpprtunitiesService, private toastr: ToastrService, private customerService: CustomerService, private viewContainerRef: ViewContainerRef, private router: Router, myElement: ElementRef, private leadservice: LeadsService, private route: ActivatedRoute) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.user.userrole = null;
    this.elementRef = myElement;
  }
  initializeAgGrid() {
    this.gridOptionsView = <GridOptions>{
      //pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'multiple'
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true//, rowSelection: 'single'
    };
    this.createColumnDefs("");
  }
  createColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.columnDefs = [
      {
        headerName: "Main Resource Id",
        field: "MainResourceId",
        hide: true,
        aggFunc: 'first',

      },
      {
        headerName: "Resource",
        field: "MainResourceName",
        hide: true,
        rowGroup: true,
        aggFunc: 'first'
      },
      
      {
        headerName: "Sub Resource Id",
        field: "SubResourceId",
        hide: true,
        aggFunc: 'first'
      },
      {
        headerName: "Resource Type",
        field: "ResourceType",
        hide: false,
      },
      {
        headerName: "Features",
        field: "SubResourceName",
        hide: false,
        aggFunc: 'first'
      },
      {
        headerName: "Is Main",
        field: "IsMain",
        hide: true,
        aggFunc: 'first'
      },
      {
        headerName: "Is Checked",
        field: "IsChecked",
        hide: true,
        aggFunc: 'first'
      },
      {
        headerName: "User Privilege Id",
        field: "UserPrivilegeId",
        hide: true,
        aggFunc: 'first'
      },
      {
        headerName: "Access", width: 90, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
           
          eDiv.innerHTML = "<input type='checkbox'" + ((params.data != undefined && params.data.CanView) || (params.node.allLeafChildren != undefined && params.node.allLeafChildren[0].data != undefined && params.node.allLeafChildren[0].data.CanView) ? "checked='checked'" : "") + ">";
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function (event) {
             
            //selfObj.Change(params);
            if (params.data != undefined)
              params.data.CanView = !params.data.CanView;
            else if (params.node.allLeafChildren != undefined && params.node.allLeafChildren[0].data != undefined)
              params.node.allLeafChildren[0].data.CanView = !params.node.allLeafChildren[0].data.CanView;
            selfObj.useraccountChange = true;
            $('#SaveUserPrivilages').css("background", "#93d250");
            $('#SaveUserPrivilages').prop('disabled', false);
          });
          return eDiv;
        },
        aggFunc: 'first'
      },
      {
        headerName: "Add", width: 90, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
           
          eDiv.innerHTML = "<input type='checkbox'" + ((params.data != undefined && params.data.CanAdd) || (params.node.allLeafChildren != undefined && params.node.allLeafChildren[0].data != undefined && params.node.allLeafChildren[0].data.CanAdd) ? "checked='checked'" : "") + ">";
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
             
            //selfObj.Change(params);
            if (params.data != undefined)
              params.data.CanAdd = !params.data.CanAdd;
            else if (params.node.allLeafChildren != undefined && params.node.allLeafChildren[0].data != undefined)
              params.node.allLeafChildren[0].data.CanAdd = !params.node.allLeafChildren[0].data.CanAdd;
            selfObj.useraccountChange = true;
            $('#SaveUserPrivilages').css("background", "#93d250");
            $('#SaveUserPrivilages').prop('disabled', false);
          });
          return eDiv;
        },
        aggFunc: 'first'
      },
      {
        headerName: "Edit", width: 90, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');

          eDiv.innerHTML = "<input type='checkbox'" + ((params.data != undefined && params.data.CanEdit) || (params.node.allLeafChildren != undefined && params.node.allLeafChildren[0].data != undefined && params.node.allLeafChildren[0].data.CanEdit) ? "checked='checked'" : "") + ">";
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
             
            //selfObj.Change(params);
            if (params.data != undefined)
              params.data.CanEdit = !params.data.CanEdit;
            else if (params.node.allLeafChildren != undefined && params.node.allLeafChildren[0].data != undefined)
              params.node.allLeafChildren[0].data.CanEdit = !params.node.allLeafChildren[0].data.CanEdit;
            selfObj.useraccountChange = true;
            $('#SaveUserPrivilages').css("background", "#93d250");
            $('#SaveUserPrivilages').prop('disabled', false);
          });
          return eDiv;
        },
        aggFunc: 'first'
      },
      {
        headerName: "Delete", width: 90, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');

          eDiv.innerHTML = "<input type='checkbox'" + ((params.data != undefined && params.data.CanDelete) || (params.node.allLeafChildren != undefined && params.node.allLeafChildren[0].data != undefined && params.node.allLeafChildren[0].data.CanDelete) ? "checked='checked'" : "") + ">";
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
             
            //selfObj.Change(params);
            if (params.data != undefined)
              params.data.CanDelete = !params.data.CanDelete;
            else if (params.node.allLeafChildren != undefined && params.node.allLeafChildren[0].data != undefined)
              params.node.allLeafChildren[0].data.CanDelete = !params.node.allLeafChildren[0].data.CanDelete;
            $('#SaveUserPrivilages').css("background", "#93d250");
            $('#SaveUserPrivilages').prop('disabled', false);
          });
          return eDiv;
        },
        aggFunc: 'first'
      },
    ];

    $(setTimeout(function () {
      selfObj.autoGroupColumnDef = {
        filterValueGetter: function (params) {
          var colGettingGrouped = params.colDef.showRowGroup;
          var valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
          return valueForOtherCol;
        },
        headerName: "Resource",
        width: 200,
        

        cellRenderer(params) {
           
          if (params.node.allChildrenCount != null) {
            //Uparraow(paramsparams.node.aggData.MainResourceId)
            var eDiv = document.createElement('div');
            //var checkboxId = params.node.aggData.MainResourceId;
            if (params.node.aggData.IsChecked) {
              eDiv.innerHTML = "<label class='glyphicon glyphicon-menu-down'></label> <input id='" + params.node.aggData.MainResourceId + "' type='checkbox' checked='checked' > " + params.value + " (" + params.node.allChildrenCount + ")";
            } else {
              eDiv.innerHTML = "<label class='glyphicon glyphicon-menu-down'></label> <input id='" + params.node.aggData.MainResourceId + "' type='checkbox'> " + params.value + " (" + params.node.allChildrenCount + ")";
            }

            eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
              //(selfObj.selectedColumnIds).push(params.node.aggData.MainResourceId);
              params.node.aggData.IsChecked = !params.node.aggData.IsChecked;
               
              //let test = params;
              selfObj.CheckUnCheckAll(params);
              //Call Checkbox Func
            });
            eDiv.getElementsByTagName("label")[0].addEventListener('click', function () {
               
              let test = params;
              params.node.expanded = true;
              selfObj.gridApi.onGroupExpandedOrCollapsed(params.node.aggData.MainResourceId);
            });
            return eDiv;
          }

          else {
             
            //let test = params;
            if (params.data.IsMain == 1 || params.node.parent.IsMain == 1) {
              var eDiv = document.createElement('div');
              //var checkboxId = params.data.MainResourceId;
              //if (params.data.MainResourceName == params.data.SubResourceName) {
              if (params.node.parent.aggData.IsChecked) {
                 
                eDiv.innerHTML = "<label class='glyphicon glyphicon-menu-up'></label> <input id='" + params.data.MainResourceId + "' type='checkbox' checked='checked'> " + params.data.MainResourceName + " (" + params.node.parent.allChildrenCount + ")";
              } else {
                eDiv.innerHTML = "<label class='glyphicon glyphicon-menu-up'></label> <input id='" + params.data.MainResourceId + "' type='checkbox'> " + params.data.MainResourceName + " (" + params.node.parent.allChildrenCount + ")";
              }
              //}
              eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
                //var checkboxId = params.data.MainResourceId;
                //var index = (selfObj.selectedColumnIds).indexOf(checkboxId);
                //(selfObj.selectedColumnIds).splice(index, 1);
                //let test = params;
                 
                params.node.parent.aggData.IsChecked = !params.node.parent.aggData.IsChecked;
                selfObj.CheckUnCheckAll(params);
                //Call Checkbox Func
              });
              eDiv.getElementsByTagName("label")[0].addEventListener('click', function () {
                 
                let test = params;
                //params.node.expanded = false;
                params.node.parent.expanded = false;
                selfObj.gridApi.onGroupExpandedOrCollapsed(params.data.MainResourceId);
              });
              return eDiv;
            }
            //else if (params.data.IsMain == 0) {

            //  var eDiv = document.createElement('div');
            //  //eDiv.innerHTML = "<label class='glyphicon glyphicon-menu-up'></label> <input id='" + params.data.MainResourceId + "' type='checkbox'> " + params.data.MainResourceName ;

            //  return eDiv;
            //}
          }
        }
      };
      //selfObj.gridOptions
    }, 500));
  }

  getmyDashDefGridBoxlist() {
    this.myDashDefGridBoxlist = [];
  
    this.userservice.GetAllmyDashDefGridBoxlist().then((result) => {
      this.myDashDefGridBoxlist = result;
      this.secondDropdownList = [];
      this.myDashDefGridBoxlist.forEach(ele => {
        this.secondDropdownList.push({ "id": ele.id, "itemName": ele.name, "category": ele.name, "isdeleted": ele.isdeleted, "taskgroupid": 0 });
      });
    });
  }
  getSelectedtmyDashDefGridBox() {
    this.userservice.GetSelectedUsermyDashDefGridBox(this.user.id).then((result) => {
      this.SecondDrpModel = this.secondDropdownList.filter(x => x.id == result.selectedMyDashDefaultGridId);     
    });
  }

  Save() {
    this.mydash.userid = this.user.id;
    this.userservice.savemyDashDefGridBox(this.mydash).then(result => {
      this.toastr.success("My Dashboard Configuration saved successfully.", "Dashboard Configuration", { timeOut: 600 });
    });
  }
  
  viewSuspended(val) {
    this.userservice.GetAllUsers(val).then((user) => {
      this.allusers = user;
    });
  }

  CheckUnCheckAll(params) {
    var ids = params.value;
     

    let mainResourceId: any = 0;
    let isChecked: boolean = false;
    if (params.node.aggData != null) {
      mainResourceId = params.node.aggData.MainResourceId;
      isChecked = params.node.aggData.IsChecked;
    }
    else if (params.node.parent != null && params.node.parent.aggData != null) {
      mainResourceId = params.node.parent.aggData.MainResourceId;
      isChecked = params.node.parent.aggData.IsChecked;
    }

    let selfObj = this;
    this.useraccountChange = true;

    if (isChecked) {
      selfObj.resourcePrivilegesList.filter(x => x.MainResourceId == mainResourceId).forEach(res => {
        res.CanView = true;
        res.CanAdd = true;
        res.CanEdit = true;
        res.CanDelete = true;
        res.IsChecked = isChecked;
      });
      selfObj.gridApi.onGroupExpandedOrCollapsed(mainResourceId);
    } else {
      selfObj.resourcePrivilegesList.filter(x => x.MainResourceId == mainResourceId).forEach(res => {
        res.CanView = false;
        res.CanAdd = false;
        res.CanEdit = false;
        res.CanDelete = false;
        res.IsChecked = isChecked;
      });
      selfObj.gridApi.onGroupExpandedOrCollapsed(mainResourceId);
    }
    $('#SaveUserPrivilages').css("background", "#93d250");
    $('#SaveUserPrivilages').prop('disabled', false);
  }

  cellClicked(event) {
     
    let selfObj = this;
    if (event.node.data != undefined || event.node.data != null) {
       
      //this.message = this.createMessagingObjectFromEventData(event.node.data);
      event.node.data.CanView = true;
      event.node.data.CanAdd = true;
      event.node.data.CanEdit = true;
      event.node.data.CanDelete = true;
      //selfObj.createColumnDefs("");
    } else {
       
      $(setTimeout(function () {
         
        //selfObj.message = selfObj.createMessagingObjectFromEventData(event.node.allLeafChildren[0].data);
        event.node.allLeafChildren[0].data.CanView = true;
        event.node.allLeafChildren[0].data.CanAdd = true;
        event.node.allLeafChildren[0].data.CanEdit = true;
        event.node.allLeafChildren[0].data.CanDelete = true;
        //selfObj.createColumnDefs("");
      }, 200));
      event.node.setSelected(true);
    }
  }

  rowSelected(event) {
     
    let selfObj = this;
    if (event.node.data != undefined || event.node.data != null) {
      if (event.node.selected) {
        $(setTimeout(function () {
           
          //selfObj.message = selfObj.createMessagingObjectFromEventData(event.node.data);
          event.node.data.CanView = true;
          event.node.data.CanAdd = true;
          event.node.data.CanEdit = true;
          event.node.data.CanDelete = true;
          //selfObj.createColumnDefs("");
        }, 200));
      } else {
         
        //this.message.id = 0;
        //this.message = new Messaging();        
        event.node.data.CanView = false;
        event.node.data.CanAdd = false;
        event.node.data.CanEdit = false;
        event.node.data.CanDelete = false;
        //selfObj.createColumnDefs("");
      }
    } else {
       
      if (event.node.selected) {
        $(setTimeout(function () {
          event.node.allLeafChildren[0].data.CanView = true;
          event.node.allLeafChildren[0].data.CanAdd = true;
          event.node.allLeafChildren[0].data.CanEdit = true;
          event.node.allLeafChildren[0].data.CanDelete = true;
        }, 200));
      } else {
      
        event.node.allLeafChildren[0].data.CanView = false;
        event.node.allLeafChildren[0].data.CanAdd = false;
        event.node.allLeafChildren[0].data.CanEdit = false;
        event.node.allLeafChildren[0].data.CanDelete = false;

      }
    }
  }

  rowGroupOpened(event) {
    if (event.node.selected == true)
      event.node.allLeafChildren[0].setSelected(true);
    else {
      if (event.node.allLeafChildren[0].selected == true) {
        event.node.allLeafChildren[0].setSelected(false);
        event.node.setSelected(true);
      }
    }
  }

  loadResourcePrivilegeList(userId: number, groupName: string) {
    this.userservice.GetResourcePrivilegesList(this.user.id, groupName).then((resourcePrivileges) => {
      this.resourcePrivilegesList = resourcePrivileges;
      if (userId == 0)
        this.emptyResourcePrivilegesList = resourcePrivileges;
    });
  }

  loadDashBoardconfigByuser(userId: number) {
    this.userservice.GetDashBoardConfigByuser(this.user.id).then((config) => {
      if (config.length == 0) {
        this.customerService.GetSpecialityList("DashBoardTypes", this.currentuser.id).then((CustomerType) => {
          this.DashBoardTypesList = CustomerType;
          CustomerType.forEach(config => {
            $("#" + config.id + "Yes").prop('checked', false);
            $("#" + config.id + "No").prop('checked', false);
          });
        });
      } else {
        config.forEach(config => {
          $("#" + config.dashboardtypeid + "Yes").prop('checked', config.isaccess == 1 ? true : false);
          $("#" + config.dashboardtypeid + "No").prop('checked', config.isaccess == 1 ? false : true);
        });

        this.DashBoardConfigList = config;
      }

    });

    //if (this.DashBoardConfigList == null) {
    //  $(setTimeout(function () {
    //    $("#475Yes").prop('checked', true);
    //    $("#476Yes").prop('checked', true);
    //    $("#477Yes").prop('checked', true);
    //    $("#478No").prop('checked', true);
    //    $("#479No").prop('checked', true);
    //  }, 100));
    //}
  }
  Change(chkId) {
    if (chkId.indexOf("Yes") >= 0) {
      let rplcId = chkId.replace("Yes", "No");
      $("#" + rplcId).prop('checked', false);
    }
    else if (chkId.indexOf("No") >= 0) {
      let rplcId = chkId.replace("No", "Yes");
      $("#" + rplcId).prop('checked', false);
    }
    this.applicationComponent.SelectedTab = 'tab13';
    this.useraccountChange = true;
  }
  rowClickAgGrid(rowEvent) {
  }
  showhide() {

    //$('#password').toggleClass("fa-eye fa-eye-slash");
    var input = $('#password');
    if (input.attr("type") == "password") {
      //$('#passwordEye').attr("class", "fa fa-fw fa-eye-slash field-icon toggle-password");
      $("#passwordEye").removeClass("fa fa-fw fa-eye field-icon toggle-password");
      $("#passwordEye").addClass("fa fa-fw fa-eye-slash field-icon toggle-password");

      input.attr("type", "text");
    } else {
      //$('#passwordEye').attr("class", "fa fa-fw fa-eye field-icon toggle-password--*/");

      $("#passwordEye").removeClass("fa fa-fw fa-eye-slash field-icon toggle-password");
      $("#passwordEye").addClass("fa fa-fw fa-eye field-icon toggle-password");

      input.attr("type", "password");
    }
  }
  onAgGridReady(event) {

    
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;

    this.userservice.GetAllMainResourceList('Sub', 1).then((roles) => {
      this.subresourceList = roles;
    });

    //this.loadResourcePrivilegeList(0,null);
    this.loadResourcePrivilegeList(this.user.id, null);
  }
  autoSizeAll() {
  }

  initializeAgGridusers() {
    this.gridOptionsusers = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000,enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 42).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefsusers(columnSelections);
    });
    this.gridOptionsusers.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptionsusers.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();
    config.maxlimit = 10;
    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 42;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config).then(result => {
      this.useraccountChange = false;
    });

  }
  createColumnDefsusers(columnSelections: string) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefsusers = [];
       
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefsusers.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "userrole") {
          this.columnDefsusers.push({
            field: 'userrole', headerName: 'User Role', hide: false,
            editable: true, cellEditor: "agSelectCellEditor", cellEditorParams: function (params) {
              return {
                values: Array.prototype.map.call(selfObj.rolesList, s => s.itemName),
                formatValue: function (value) {
                }
              };
            }
          })
        }
        else {
          this.columnDefsusers.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefsusers = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'firstname', headerName: 'First Name', hide: false },
        { field: 'lastname', headerName: 'Last Name', hide: false },
        {
          field: 'userrole', headerName: 'User Role', hide: false,
          editable: true, cellEditor: "agSelectCellEditor", cellEditorParams: function (params) {
            return {
              values: Array.prototype.map.call(selfObj.rolesList, s => s.itemName),
              formatValue: function (value) {
              }
            };
          }
        },
        { field: 'email', headerName: 'Email', hide: false },
        { field: 'username', headerName: 'UserName', hide: false },
        {
          field: 'action', headerName: 'Action', hide: false, width: 90, pinned: 'right', cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<img src="../../../assets/img/trash.png" style="height:15px;width:30%"/>';
            eDiv.addEventListener('click', function () {
              selfObj.DeleteUserAlert = true;
              selfObj.DeleteUserId = params.data.id;
              selfObj.DeleteUserAlertCss();
            });
            return eDiv;
          }
        }
      ];
    }
  }

  DeleteUsers() {
    if (this.ReplaceUserId != null && this.ReplaceUserId != 0) {
      this.userservice.DeleteUser(this.DeleteUserId, this.ReplaceUserId).then((user) => {
        this.DeleteUserAlert = false
        this.userservice.GetAllUsers(0).then((user) => {
          this.allusers = user;
          this.DeleteUserId = 0;
          this.ReplaceUserId = 0;
          this.toastr.success("User Deleted successfully.", "User Deleted", { timeOut: 600 });
        });
      });
    } else {
      alert("Please select User");
    }
   
  }
  DeleteUserAlertCss() {
    $(setTimeout(function () {
      $('#DeleteUserAlert .dialog').attr('style', 'width: 40%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 40%;top:10%');
    }, 4));
  }
  onAgGridReadyusers(event) {
    this.userListgridApi = event.api;
  }
  
  onSelectionChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      this.showAddUserDiv = true;
      this.user.id = rowEvent.api.getSelectedNodes()[0].data.id;
      this.emailAlertUsername = rowEvent.api.getSelectedNodes()[0].data.firstname;
      this.emailAlertUsername2 = rowEvent.api.getSelectedNodes()[0].data.lastname;
      this.userservice.GetUserByuserId(this.user.id).then(result => {
        this.user = result;
     
        this.urlImage = this.user.ProfilePicture;
        
        if (this.user.EmailSetUp == "SendGrid") {
          this.user.isSendGrid = true;
          this.user.isSMTP = false;
        } else {
          this.user.isSendGrid = false;
          this.user.isSMTP = true;
        }
       
      });
      if (this.selectedMenuRadio == "tab11") {
        //this.loadResourcePrivilegeList(this.user.id);
        //this.initializeAgGrid();        
        this.showResetBtn = true;
        this.showCancelBtn = false;
        this.showPassword = false;
      }
      else if (this.selectedMenuRadio == "tab12") {
        this.loadResourcePrivilegeList(this.user.id, null);
        this.initializeAgGrid();
        this.userservice.GetUserByuserId(this.user.id).then(result => {
          this.loadroleacess(this.currentuser.userrole).then(res => {
          });
          this.user = result;      
          this.urlImage = this.user.ProfilePicture;
          this.selectedPrvMenuRadio = 'prvtab1';
          this.PrivilageTabChanged('Display All');
        });
        this.showResetBtn = true;
        this.showCancelBtn = false;
        this.showPassword = false;
      }
      else if (this.selectedMenuRadio == "tab13") {
         
        this.loadDashBoardconfigByuser(this.user.id);
      }
      else if (this.selectedMenuRadio == "tab15") {
         
        this.AutomatedEmails.loadUserAlertEmails(rowEvent.api.getSelectedNodes()[0].data.id);
       
      }

      //$("#SaveData").css("background","#90d24e")
    }
    else {
      this.user.id = 0;
      this.showAddUserDiv = false;
      if (this.userquotaComp != undefined || this.userquotaComp != null)
        this.userquotaComp.showdetail();
      this.resetUserForm();
    
    }
       
  }

  selectallMain(val) {

    if ($('#' + val + '_chkbox').is(":checked")) {
      $('input:checkbox[id^=' + val + '_' + ']').prop('checked', true);
    } else {
      $('input:checkbox[id^=' + val + '_' + ']').prop('checked', false);
    }
  }
  loadTaskGrouplist(status) {
     
    if ($("#isTodoSelected").is(':checked') || $("#isPhoneSelected").is(':checked') || $("#isMeetingSelected").is(':checked')) {
      this.userservice.GetAllTaskgrouplist("TaskList", 1).then((taskgroups) => {
        this.taskgrouplist = taskgroups;
        this.TasksGroups = taskgroups;
        this.taskgrouplist.sort((a, b) => a.itemName.localeCompare(b.itemName));
      });
    } else {
      this.userservice.GetAllTaskgrouplist("TaskList", 0).then((taskgroups) => {
        this.taskgrouplist = taskgroups;
        this.TasksGroups = taskgroups;
        this.taskgrouplist.sort((a, b) => a.itemName.localeCompare(b.itemName));
      });
    }
    
    this.toTasktypelist = [];
    this.phonecallTasktypelist = [];
    this.meetingTasktypelist = [];
  }

  initializeAgGridSubConfiguration() {
    this.SubgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.SubcreateColumnDefsusers("");
    this.SubgridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  SubcreateColumnDefsusers(columnSelections: string) {

    this.SubcolumnDefs = [
      { field: 'itemName', headerName: 'ACCESS TO', hide: false },
      { headerName: "View/Access", width: 120, checkboxSelection: true, hide: false, outerWidth: 8 },
      { headerName: "Add", width: 120, checkboxSelection: true, hide: false, outerWidth: 8 },
      { headerName: "Edit", width: 120, checkboxSelection: true, hide: false, outerWidth: 8 },
      { headerName: "Delete", width: 120, checkboxSelection: true, hide: false, outerWidth: 8 }

    ];
  }


  initializeAgGridMainConfiguration() {
    this.MaingridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'multiple'
    };
    this.MaincreateColumnDefsusers("");
    this.MaingridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  MaincreateColumnDefsusers(columnSelections: string) {
    let selfObj = this;
    this.MaincolumnDefs = [
      {
        field: 'itemName', headerName: 'ACCESS TO', hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.MaingridOptions.rowSelection = "multiple";
            selfObj.initializeAgGridSubConfiguration();
            selfObj.userservice.GetAllMainResourceList('Sub', params.data.id).then((roles) => {
              selfObj.subresourceList = roles;
            });
          });
          return eDiv;
        }
      },
      {
        headerName: "View/Access",
        width: 120, checkboxSelection: true, hide: false,
        outerWidth: 8,
      }
    ];
  }
  MainonAgGridReadyusers(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.MainautoSizeAllusers();
      $(setTimeout(function () {
        selfObj.MainautoSizeAllusers();
      }, 1000));
    }, 1000));
  }
  MainautoSizeAllusers() {

  }

  loadstatusdropdowns() {
    this.customerService.GetSpecialityList("UserConfigStatus", this.currentuser.id).then((Rows) => {
      this.usersDropdownList = Rows;
      this.userDrpModel = [{ "id": this.usersDropdownList[0].id, "itemName": this.usersDropdownList[0].itemName, "category": this.usersDropdownList[0].category }];
    });
  }
  ngOnInit() {
     
    this.applicationComponent.SearchSelectedItems = [];
    this.route.params.subscribe(params => {
      this.isDocumentManagementRoute = params['documentmanagement'];
      this.selectedvalue = params['subMenuId'];

      let selfObj = this;
      $(setTimeout(function () {
         
        selfObj.appendLabel(selfObj.selectedvalue);
      }, 100));
      //this.activateSubButtons(params['subMenuId']);
    });
    $(".subButtons").hide();
    $(".checkMark").removeClass("activeColor");
    $(".subButtons").removeClass("activeButton");
    $("#systemConfig_span").addClass("activeColor");
    $("#systemConfig").addClass("mainactiveButton");


    this.user = new Users();
    this.user.userrole = null;
    this.user.Zendseskpermissionid = null;
    this.user.TaskGroupid = null;
    this.loadTaskGrouplist(0);
    
    this.user.ticketTaskGroupAccessID = [
      { "id": 1, "itemName": 'Sales', "category": 'none'}
    ];
    
    
    this.userservice.GetAllRolesList(this.currentuser.id).then((roles) => {
     
      this.rolesList = roles;
    });
    
    this.customerService.GetSpecialityList("Quota", this.currentuser.id).then((CustomerType) => {
      CustomerType.forEach(CustomerType => {
      });
      this.QuotaList = CustomerType;
    });
    this.customerService.GetSpecialityList("Zendesk", this.currentuser.id).then((CustomerType) => {
      CustomerType.forEach(CustomerType => {
      });
      this.ZendeskPermissionList = CustomerType;
    });

    this.customerService.GetSpecialityList("PermissionLevel", this.currentuser.id).then((PermissionLevel) => {
      PermissionLevel.forEach(PermissionLevel => {
      });
      this.permissionlevellist = PermissionLevel;
    });
    this.customerService.GetSpecialityList("UserPermissionLevel", this.currentuser.id).then((UserPermissionLevel) => {
      UserPermissionLevel.forEach(UserPermissionLevel => {
      });
      this.userpermissionlevellist = UserPermissionLevel;
    });
    this.customerService.GetSpecialityList("UserStatus", this.currentuser.id).then((userstatus) => {
      userstatus.forEach(userstatus => {
      });
      this.userstatuslist = userstatus;
    });
    this.customerService.GetSpecialityList("UserTitle", this.currentuser.id).then((userstatus) => {
      userstatus.forEach(userstatus => {
      });
      this.usertitlelist = userstatus;
    });
    this.customerService.GetSpecialityList("Suffix", this.currentuser.id).then((userstatus) => {
      userstatus.forEach(userstatus => {
      });
      this.usersuffixlist = userstatus;
    });
    this.customerService.GetSpecialityList("TimeZone", this.currentuser.id).then((timezone) => {
      timezone.forEach(timezone => {
      });
      this.timezonelist = timezone;
    });
    
    this.customerService.GetSpecialityList("DealApproval", this.currentuser.id).then((dealapprovallist) => {
      dealapprovallist.forEach(dealapprovallist => {
      });
      this.dealapprovallist = dealapprovallist;
    });

    this.customerService.GetSpecialityList("DocumnentType", this.currentuser.id).then((Documenttype) => {
      Documenttype.forEach(Documenttype => {
      });
      this.DocumenttypeList = Documenttype;
    });

    //this.customerService.GetSpecialityList("AccountGroup", this.currentuser.id).then((AccGropu) => {
    //  this.AccountGroupList = AccGropu;
    //});

    this.customerService.GetAllAccountSharingGroups(this.currentuser.id).then((ShareGroups) => {
      var Groups = [];
      ShareGroups.forEach(ele => {
        Groups.push({ "id": ele.SharingGroupId, "itemName": ele.SharingGroupName, "category": 'SharingGroups' })
      });
      this.AccountGroupList = Groups;
    });

    this.SingleDrpSetting = {
      singleSelection: true,
      text:'Users'

    };

    this.loadstatusdropdowns();

    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
     
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.TaskGroupdropdownSettings = {
      singleSelection: false,
      text: "Select TaskGroup",
      selectAllText: 'Select All',

      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };

    this.TaskGroupdropdownSettings = {
      singleSelection: false,
      text: "Select Account Group",
      selectAllText: 'Select All',

      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.initializeAgGridusers();
     
    this.initializeAgGrid();
    this.IntializeDashboardGrid();

    this.selectedtab = 'Registration';
    localStorage.setItem('UserConfigSubTab', this.selectedtab);

    var obj: AuditLogModel = { id: 0, type: 'User Configuration', action: 'User ConfigViewed', AccountOrContactId: 0, AccountOrContact: "", documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'User Config Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
    this.viewSuspended(0);
    //this.userservice.GetAllUsers(0).then((user) => {
    //  this.allusers = user;
    //});

    

    this.userform = new FormGroup({
      'firstname': new FormControl(this.user.firstname, Validators.required),
      'lastname': new FormControl(this.user.lastname, Validators.required),
      'userrole': new FormControl(this.user.userrole, Validators.required),
      'cellphone': new FormControl(this.user.cellphone, Validators.required),
      'email': new FormControl(this.user.email, Validators.email),
      'password': new FormControl(this.user.password, [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')]),
      'Zendseskpermissionid': new FormControl(this.user.Zendseskpermissionid, Validators.nullValidator),
      'timezoneid': new FormControl(this.user.timezoneid, Validators.nullValidator),
      'todotaskgroup': new FormControl(this.user.SelectedTaskGroupids, Validators.nullValidator),
      'userstatusid': new FormControl(this.user.userstatusid, Validators.required),
      'systemadministrator': new FormControl(this.user.systemadministrator, Validators.required),
      'permissionlevelid': new FormControl(this.user.permissionlevelid, Validators.required),
      //'userpermissionlevelid': new FormControl(this.user.userpermissionlevelid, Validators.required),
      'releaseNoteAddEdit': new FormControl(this.user.releaseNoteAddEdit, Validators.required),
      'customertypeaccess': new FormControl(this.user.customertypeaccess, Validators.required),
      'customerAccount': new FormControl(this.user.customerAccount, Validators.required),
      'customerStatus': new FormControl(this.user.customerStatus, Validators.required),
      'TaskGroupselectedItems': new FormControl(this.user.TaskGroupselectedItems, Validators.required),
      'taskGroupAccessForTickets': new FormControl(this.user.taskGroupAccessForTickets, Validators.required),
    });
    //this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, 1, 1).then((Owner) => {
    this.customerService.GetAllaccesusersListbyuser(0,1, 1, 1).then((Owner) => { // passing 0 to get all Users
      Owner.forEach(Owner => {
      });

      this.dropdownList = Owner;
      this.dropdownList.push({ id: 0, category: "None", itemName: "None", isdeleted: 0, name: null, Value: null });
    })
    this.isViewInitialized = true;
    //  this.configurationtypeList = accounttype;
    //});

    if (this.isDocumentManagementRoute) {
      this.appendLabel(451);
    }

    //$('#SaveData').prop('disabled', true);
    $('#SaveUserPrivilages').prop('disabled', true);
    $('#saveDashboardConfig').prop('disabled', true);
    $('#searchBox').attr('style', 'display:none');
    //this.applicationComponent.showDashboardSubTabs = false;
    //this.applicationComponent.showSystemConfigSubTabs = true;

    //let self = this;
    //$(document).mousedown(function (e) {
    //   
    //  var container = $("#UCForm");
    //  if (!container.is(e.target) && container.has(e.target).length === 0) {
    //    if (self.useraccountChange) {
    //     // self.UserAccountchangeDialogs = true
    //      self.UserAccountchangeDialogCss();
    //      e.stopPropagation();
    //    }
    //  }
    //});

   

  }

  UserAccountchangeDialogCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width: 29%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4)
  }

  onItemSelect(event) {
    this.useraccountChange = true;
    
  }

  OnItemDeSelect(event) {
    this.useraccountChange = true;
    //$("#SaveData").css("background", "#93d250");
    //$('#SaveData').prop('disabled', false);
  }

  onSelectAll(event) {
    this.useraccountChange = true;
  }

  onDeSelectAll(event) {
    this.useraccountChange = true;
  }
  onItemSelectAccess(event) {
    this.useraccountChange = true;
    //$("#SaveData").css("background", "#93d250");
    //$('#SaveData').prop('disabled', false);
  }

  OnItemDeSelectAccess(event) {
    this.useraccountChange = true;
    //$("#SaveData").css("background", "#93d250");
    //$('#SaveData').prop('disabled', false);
  }

  onSelectAllAccess(event) {
    this.useraccountChange = true;
  }

  onDeSelectAllAccess(event) {
    this.useraccountChange = true;
  }

  onTaskGroupAccessSelect(event) {
   // this.useraccountChange = true;
    //$("#SaveData").css("background", "#93d250");
    //$('#SaveData').prop('disabled', false);
  }

  OnTaskGroupItemDeSelect(event) {
   // this.useraccountChange = true;
    //$("#SaveData").css("background", "#93d250");
    //$('#SaveData').prop('disabled', false);

  }

  onTaskGroupSelectAll(event) {
    //this.useraccountChange = true;
    //$("#SaveData").css("background", "#93d250");
    //$('#SaveData').prop('disabled', false);
  }

  onTaskGroupDeSelectAll(event) {
   // this.useraccountChange = true;
    //$("#SaveData").css("background", "#93d250");
    //$('#SaveData').prop('disabled', false);
  }


  reloadItems(params) {
    if (this.itemResource != undefined) {
      this.itemResource.query(params).then(items => this.items = items);
    }
  }
  Configure() {
    if (document.getElementById('contactconfiguration_button').className == 'btn activeButton') {
      this.systemconfiguration.configurationname = 'Contacts';
    } else {

    }

    this.systemconfiguration.addeduserid = this.currentuser.id;
    this.userservice.savesystemconfiguration(this.systemconfiguration).then(result => {
      this.toastr.success("Configuration saved successfully.", "Configuration Saved", { timeOut: 600 });
    });
  }

  TodoedittaskGroup() { 
    this.ShowTaskGrpDialog = true;
    this.edittaskgroup = true;
   
  }
  activateSubButtons(id) {
    $(".subButtons").removeClass("activeButton");
    $('#' + id + "_button").addClass("activeButton");
    this.selectedItems = [];
    //this.selectedtab = id;
    
  }
  GetAllTasksList(tasktype, taskgroup, status) {
    if (tasktype == 'todo') this.toTasktypelist = [];
    else if (tasktype == 'phonecall') this.phonecallTasktypelist = [];
    else if (tasktype == 'meeting') this.meetingTasktypelist = [];

    this.leadservice.GetAllTasksList(tasktype, 'undefined', taskgroup, status, this.currentuser.id).then((accounttype) => {
      if (tasktype == 'todo') {
        this.toTasktypelist = accounttype;
        
      }
      else if (tasktype == 'phonecall') {
        this.phonecallTasktypelist = accounttype;
      }
      else if (tasktype == 'meeting') {
        this.meetingTasktypelist = accounttype;
      }
    });

  }
  
  Activate(item) {
    var AuditLogId = Number(localStorage.getItem("AuditLogId"));
    this.customerService.updatelogin(item.id, 0, " ", AuditLogId).then((user) => { });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('temptoken');
    //this.router.navigateByUrl(`/login`);
  }
  DivClick(val) {
    var url = window.location.href;
    this.applicationComponent.displayheader(url);
    //if (this.useraccountChange) {
    //  this.UserAccountchangeDialogs = true;
    //  this.UserAccountchangeDialogCss();
    //}
  }
  handleClick(event) {    
    var clickedComponent = event.target;
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
        this.useraccountChange = true;
        this.applicationComponent.SelectedTab = this.selectedMenuRadio;
      }
      clickedComponent = clickedComponent.parentNode;
    }
    while (clickedComponent);
    
    if (this.user.firstname != "" && this.user.firstname != undefined && this.user.lastname != "" && this.user.lastname != undefined && this.user.userrole != "" && this.user.userrole != undefined && this.user.email != "" && this.user.email != undefined && this.user.password != "" && this.user.password != undefined) {
      //$("#SaveData").css("background", "#93d250");
      //$('#SaveData').prop('disabled', false);
    }
    else {
      //$("#SaveData").css("background", "#d3d3d3");
      //$('#SaveData').prop('disabled', true);
    }
    

  }
  public clearcheckboxs() {
    $('.mainview').each(function () {
      this.checked = false;
    })
    $('.mainadd').each(function () {
      this.checked = false;
    })
    $('.mainedit').each(function () {
      this.checked = false;
    })
    $('.view').each(function () {
      this.checked = false;
    })
    $('.add').each(function () {
      this.checked = false;
    })
    $('.edit').each(function () {
      this.checked = false;
    })
  }

  public clearCheckBoxesNew() {
    $('input:checkbox').prop('checked', false);
    this.resourcePrivilegesList = this.emptyResourcePrivilegesList;
  }

  public resetUserForm() {
    this.user = new Users();
    this.user.userrole = null;
    this.user.Zendseskpermissionid = null;
    this.user.TaskGroupid = null;
    this.user.ticketTaskGroupAccessID = 0;
    this.user.systemadministrator = 0;
    this.user.timezoneid = null;
    this.user.customertypeaccess = 0;
    this.clearCustomerEditing();
    this.user.Quotaid = 444;
    this.user.userstatusid = 460;
    this.user.permissionlevelid = null;
    this.user.Zendseskpermissionid = 465;
    this.user.dealapprovalid = 452;//Not in Use
  }

  loadroleacess(role): Promise<any> {
    this.dropdownList = [];
    const promise = new Promise<any>((resolve, reject) => {
      this.customerService.GetAllOwnerList(role).then((Owner) => {
        Owner.forEach(Owner => {
          if (this.currentuser.id != Owner.id) {
            this.dropdownList.push(Owner)
            resolve();
          }
        });
      })
    });
    return promise;
  }
  RegisterUser(btn) {   
    this.user.ticketTaskGroupAccessID == this.user.TaskGroupselectedItems;
    this.userservice.GetEmail(this.user).then(result => {
      this.res = result;
      if (this.res != null) {
        this.toastr.error("The email address you have entered is already registered", "Email");
        return;
      }
      if (btn == "save") {
        if (this.currentuser.id == this.user.id) {
          this.currentuser.systemadministrator = this.user.systemadministrator;
          localStorage.setItem('currentUser', JSON.stringify(this.currentuser));
        }
        this.user.userid = this.currentuser.id;
        this.user.EmailSetUp = $("input[name='email']:checked").val();
        this.user.ticketTaskGroupAccessID = '';
        this.user.AccountGroupAccessID = '';
        this.user.mysalesxcrmuseraccess = '';
        if (this.user.TaskGroupselectedItems != undefined && this.user.TaskGroupselectedItems.length > 0 ) {
          this.user.TaskGroupselectedItems.forEach(ele => {
            this.user.ticketTaskGroupAccessID += ele.id + ','
          });
        }
        if (this.user.AccountGroupselectedItems != undefined && this.user.AccountGroupselectedItems.length > 0) {
          this.user.AccountGroupAccessID = this.user.AccountGroupselectedItems.map(x => x.id).join(",");
        }
        if (this.user.SalesXCRMacesselectedItems != undefined && this.user.SalesXCRMacesselectedItems.length > 0) {
          this.user.SalesXCRMacesselectedItems.forEach(ele => {
            this.user.mysalesxcrmuseraccess += ele.id + ','
          });
        }

        if (this.user.SelectedTaskGroupids != undefined && this.user.SelectedTaskGroupids.length > 0) {
          this.user.TaskGroupid = this.user.SelectedTaskGroupids.map(x => x.id).join(",");
        }
      
        this.userservice.registeruser(this.user).then(result => {
          this.nUId = result.id;
          this.useraccess = [];
          this.useraccess.push(this.nUId);
          if (this.user.selectedItems != undefined) {
            for (var i = 0; i < this.user.selectedItems.length; i++) {
              this.useraccess.push(this.user.selectedItems[i].id);
            }
            this.userservice.updateuser(this.useraccess);
          }
         
          this.SaveDashBoardData(result.id);
          this.resetUserForm();
          this.toastr.success("User Registered successfully.", "User Saved", { timeOut: 600 });
          if (this.userDrpModel[0].id == 609) { this.viewSuspended(0); }
          else { this.viewSuspended(1); }
        
          this.gridOptionsusers.api.deselectAll();
          this.userservice.GetAllRolesList(this.currentuser.id).then((roles) => {
          
            this.rolesList = roles;
          });

          this.clearCustomerEditing();
          this.user.customerStatus = 0;
          this.clearCheckBoxesNew();
        });

      }
      if (btn == "Previlages") {
        this.user.userid = this.currentuser.id;
        this.user.EmailSetUp = $("input[name='email']:checked").val();
        let saveResourcePrivileges: SaveResourcePrivileges = new SaveResourcePrivileges();
        saveResourcePrivileges.ResourcePrivileges = this.resourcePrivilegesList;
        saveResourcePrivileges.AssignedUserId = this.user.id;
        saveResourcePrivileges.UserId = this.currentuser.id;     
        this.userservice.SaveUserPrivilagesNew(saveResourcePrivileges).then(result => {
          this.user.selectedItems;
          this.toastr.success("User Previllages successfully.", "Previllages Saved", { timeOut: 600 });
         
          this.userservice.GetAllRolesList(this.currentuser.id).then((roles) => {
           
            this.rolesList = roles;
          });
          this.customerService.GetPrivilagesByuser(this.currentuser.id).then(result => {
            this.currentuser.UserPrivilegeList = result;
            this.applicationComponent.currentuser.UserPrivilegeList = result;
          });
        
          this.useraccountChange = false;
          this.useraccess = [];
          this.useraccess.push(this.nUId);
          if (this.user.selectedItems != undefined) {
            for (var i = 0; i < this.user.selectedItems.length; i++) {
              this.useraccess.push(this.user.selectedItems[i].id);
            }
            this.userservice.updateuser(this.useraccess);
          }

          this.selectedPrvMenuRadio = 'prvtab1';
          this.loadResourcePrivilegeList(this.user.id, 'Display All');
        });
        $("#SaveUserPrivilages").css("background", "#d3d3d3");
        $('#SaveUserPrivilages').prop('disabled', true);
      }
      if (btn == "customerview") {      
        this.customerService.updateCustomerView(this.user.id, this.user.defaultSearchPage,this.user.hidecustomer).then((user) => {
          this.toastr.success("Customer View Updated Successfully.", "User Updated", { timeOut: 200 });
          this.currentuser.defaultSearchPage = this.user.defaultSearchPage;
          localStorage.setItem('currentUser', JSON.stringify(this.currentuser));         
        });
      }
      if (btn == "CustomerEdit") {
        this.userModel.userId = this.user.id;
        this.userModel.isCustomer = this.user.isCustomer;
        this.userModel.customerSince = this.user.customerSince;
        this.userModel.HL7interface = this.user.HL7interface;
        this.userModel.CustomerRCM = this.user.CustomerRCM;
        this.userModel.OnDemand = this.user.OnDemand;
        this.userModel.ApptReminders = this.user.ApptReminders;
        this.userModel.eLab = this.user.eLab;
        this.userModel.MIPS = this.user.MIPS;
        this.userModel.SystemType = this.user.SystemType;
        this.userModel.customerStatus = this.user.customerStatus;
        this.userModel.customerAccount = parseInt(this.user.customerAccount.toString());
        this.userservice.updateCustomerEdit(this.userModel).then(result => {
          this.toastr.success("Customer Edit Updated Successfully.", "User Updated", { timeOut: 200 });
          this.currentuser.isCustomer = this.userModel.isCustomer;
          this.currentuser.customerSince = this.userModel.customerSince;
          this.currentuser.HL7interface = this.userModel.HL7interface;
          this.currentuser.CustomerRCM = this.userModel.CustomerRCM;
          this.currentuser.OnDemand = this.userModel.OnDemand;
          this.currentuser.ApptReminders = this.userModel.ApptReminders;
          this.currentuser.eLab = this.userModel.eLab;
          this.currentuser.MIPS = this.userModel.MIPS;
          this.currentuser.SystemType = this.userModel.SystemType;
          this.currentuser.customerStatus = this.userModel.customerStatus;
          this.currentuser.customerAccount = this.userModel.customerAccount;
          this.currentuser.accountStatus = this.userModel.customerStatus;
          
          localStorage.setItem('currentUser', JSON.stringify(this.currentuser));
        });
      }
      //update
      else {
        var pass = $('#password').val();
        if (pass != undefined && $.trim(pass) == "")
          return false;
        else if (pass == undefined)
          this.user.password = null;
        else
          this.user.password = pass;
 
        if (this.user.id > 0) {
          this.user.userid = this.currentuser.id;         
          this.userservice.updateRegisteredUser(this.user).then(result => {
            let saveResourcePrivileges: SaveResourcePrivileges = new SaveResourcePrivileges();
            saveResourcePrivileges.ResourcePrivileges = this.resourcePrivilegesList;
            saveResourcePrivileges.AssignedUserId = result.id;
            saveResourcePrivileges.UserId = this.currentuser.id;

            this.userservice.SaveUserPrivilagesNew(saveResourcePrivileges).then(result => {
              this.useraccess = [];
              this.useraccess.push(this.user.id);
              for (var i = 0; i < this.user.selectedItems.length; i++) {
                this.useraccess.push(this.user.selectedItems[i].id);
              }
              this.userservice.updateuser(this.useraccess);

              this.userservice.GetAllRolesList(this.currentuser.id).then((roles) => {
            
                this.rolesList = roles;
              });
              this.user = new Users();
              this.showCancelBtn = false;
              this.user.email = '';
              this.user.password = '';
              this.user.userrole = 'null';
              this.user.systemadministrator = 0;
              this.user.timezoneid = null;
              this.clearCustomerEditing();
              this.toastr.success("Privileges updated successfully.", "User Updated", { timeOut: 600 });
              this.useraccountChange = false;
              //this.customerService.GetPrivilagesByuser(this.currentuser.id).then(result => {
              //  this.userprevilages = result;
              //});
              this.gridOptionsusers.api.deselectAll();
              this.viewSuspended(0);

              this.clearCheckBoxesNew();
            });
          });
        }
        else {
          //this.toastr.error("User not selected correctly", "User");
        }
      } // Update Ends here
    });//this.userservice.GetEmail ends here
    this.applicationComponent.PrevUserConfigTab = "";
   
  }

  clearCustomerEditing() {
   
    this.user.customertypeaccess = 0;
    this.user.customerAccount = 0;
    this.user.customerStatus = 0;
    this.user.SystemType = 0;
    this.user.HL7interface = 0;
    this.user.CustomerRCM = 0;
    this.user.OnDemand = 0;
    this.user.ApptReminders = 0;
    this.user.eLab = 0;
    this.user.MIPS = 0;
  }
  selectall(name) {
    if (name == "view") {
      if ($('#view_chkbox').is(":checked")) {
        $('.view').each(function () {
          this.checked = true;
        })
      } else {
        $('.view').each(function () {
          this.checked = false;
        })
      }
    }
    if (name == "add") {
      if ($('#Add_chkbox').is(":checked")) {
        $('.add').each(function () {
          this.checked = true;
        })
      } else {
        $('.add').each(function () {
          this.checked = false;
        })
      }
    }
    if (name == "edit") {
      if ($('#Edit_chkbox').is(":checked")) {
        $('.edit').each(function () {
          this.checked = true;
        })
      } else {
        $('.edit').each(function () {
          this.checked = false;
        })
      }

    }

  }
  checkAccess(id, type) {
    var idCheck = '', classname = '', mainId = '';
    if (type == 'add') {
      idCheck = 'mainadd_' + id
      classname = "mainadd";
      mainId = 'mainadd_chkbox';
    } else if (type == 'edit') {
      idCheck = 'mainedit_' + id
      classname = "mainedit";
      mainId = 'mainedit_chkbox';
    } else {
      idCheck = 'mainview_' + id
      classname = "mainview";
      mainId = 'mainview_chkbox';
    }
    var checkIs = 0;
    $('.' + classname).each(function () {
      if (!this.checked) {
        checkIs++;
      }
    })
    if (checkIs > 0) {
      $('#' + mainId).prop('checked', false);
    } else {
      $('#' + mainId).prop('checked', true);
    }
  }
  checkAccessSub(id, type) {
    var idCheck = '', classname = '', mainId = '';
    if (type == 'add') {
      idCheck = 'add_' + id
      classname = "add";
      mainId = 'Add_chkbox';
    } else if (type == 'edit') {
      idCheck = 'edit_' + id
      classname = "edit";
      mainId = 'Edit_chkbox';
    } else {
      idCheck = 'view_' + id
      classname = "view";
      mainId = 'view_chkbox';
    }

    var checkIs = 0;
    $('.' + classname).each(function () {
      if (!this.checked) {
        checkIs++;
      }
    })
    if (checkIs > 0) {
      $('#' + mainId).prop('checked', false);
    } else {
      $('#' + mainId).prop('checked', true);
    }
  }
  UserrowClick(rowEvent) {
    this.systemconfiguration.userid = rowEvent.row.item.id;
  }
  rowClick(rowEvent) {

  }
  ShowPassword() {
    this.showResetBtn = false;
    this.showCancelBtn = true;
    this.showPassword = true;

  }

  HidePassword() {
    this.showResetBtn = true;
    this.showCancelBtn = false;
    this.showPassword = false;
  }
  keyupTask(taskid, kval, tasktype) {
     
    if (taskid > 0) {
      if ((this.totaskids.filter(f => f.Index == taskid && f.Type == "todo")).length == 0)
        this.totaskids.push({ Index: taskid, Type: tasktype });
      if ((this.meetingtaskids.filter(f => f.Index == taskid && f.Type == "meeting")).length == 0)
        this.meetingtaskids.push({ Index: taskid, Type: tasktype });
      if ((this.phonecalltaskids.filter(f => f.Index == taskid && f.Type == "phonecall")).length == 0)
        this.phonecalltaskids.push({ Index: taskid, Type: tasktype });
    }
    else {
      if (tasktype == "todo") { this.toDoNew = false; this.newtodoid = taskid; }
      else if (tasktype == "meeting") { this.meetingNew = false; this.newmeetingid = taskid; }
      else if (tasktype == "phonecall") { this.phonecallNew = false; this.newphonecallid = taskid; }
    }

    if (tasktype == "todo") {
      $("#" + taskid + "todoTasktype").css("color", "red");
      this.toDobuttons = false
    }
    else if (tasktype == "meeting") {
      $("#" + taskid + "meetingTasktype").css("color", "red");
      this.meetingbuttons = false
    }
    else if (tasktype == "phonecall") {
      $("#" + taskid + "phoneTasktype").css("color", "red");
      this.phoneCallbuttons = false
    }

  }

  //SaveTasktype(val) {
  //  if (val == 'todo') {
       
  //    this.totaskids.forEach(id => {
         
  //      if (id.Type == 'todo') {
  //        var drpDwn = new Dropdowntype();
  //        drpDwn.category = "To Do";

  //        drpDwn.taskgroupid = $('#todotaskgroup').val();
  //        drpDwn.itemName = $('#' + id.Index + 'todoTasktype').html().replace('To Do - ', '');
  //        drpDwn.id = parseInt($('#' + id.Index + 'todoTaskID').html());
  //        if (drpDwn.itemName.toString().replace('&nbsp;', '').trim() != '') {
  //          this.tododropdowns.push(drpDwn);
  //        }
  //        //this.tododropdowns.push(drpDwn);
  //      }
  //    });
  //    if (this.totaskids.length == 0) {
  //      if ((this.tododropdowns.filter(f => { f.id == 0 && f.category == "To Do" && f.itemName == $('#' + this.newtodoid + 'todoTasktype').html() }).length == 0)) {
  //        var drpDwn = new Dropdowntype();
  //        drpDwn.category = "To Do";
          
  //        drpDwn.taskgroupid = $('#todotaskgroup').val();
          
  //        drpDwn.itemName = $('#' + this.newtodoid + 'todoTasktype').html().replace('To Do - ', '');
  //        drpDwn.id = 0;
  //        if (drpDwn.itemName.toString().replace('&nbsp;', '').trim() != '') {
  //          this.tododropdowns.push(drpDwn);
  //        }
  //      }
  //    }
  //    this.customerService.checkduplicateTaskName(this.tododropdowns[0].id, this.tododropdowns[0].itemName, $('#todotaskgroup').val(), this.tododropdowns[0].category).then(taskgopdata => {
        
  //      if (taskgopdata == null) {
  //        this.customerService.saveDropdownValue(this.tododropdowns).then(result => {
  //          this.toastr.success(" To Do Task List configured Successfully.", "Task List", { timeOut: 600 });
  //          this.newtodoid = 0;
  //          this.totaskids = [];
  //          this.tododropdowns = [];
            
  //          this.GetAllTasksList(val, $('#todotaskgroup').val(), 0);
  //          this.toDobuttons = true; this.toDoNew = true;
             
  //        });
           
  //        $('#todoTasktype').attr('style', "background-color:#808080;color:white");
  //      } else {
  //        alert("Task Name Already Exist");
  //        this.newtodoid = 0;
  //        this.totaskids = [];
  //        this.tododropdowns = [];
  //        return false;
  //      }
  //      });    
     
  //  }
  //  else if (val == 'meeting') {
  //    this.meetingtaskids.forEach(id => {
  //      if (id.Type == 'meeting') {
  //        var drpDwn = new Dropdowntype();
  //        drpDwn.category = "Meeting";
  //        drpDwn.taskgroupid = $('#meetingtaskgroup').val();
  //        drpDwn.itemName = $('#' + id.Index + 'meetingTasktype').html().replace('Meeting - ', '');
  //        drpDwn.id = parseInt($('#' + id.Index + 'meetingTaskID').html());
  //        if (drpDwn.itemName.toString().replace('&nbsp;', '').trim() != '') {
  //          this.meetingdropdowns.push(drpDwn);
  //        }
       
  //      }
  //    });
  //    if (this.meetingtaskids.length == 0) {
  //      if ((this.meetingdropdowns.filter(f => { f.id == 0 && f.category == "Meeting" && f.itemName == $('#' + this.newmeetingid + 'meetingTasktype').html() }).length == 0)) {
  //        var drpDwn = new Dropdowntype();
  //        drpDwn.category = "Meeting";
  //        drpDwn.taskgroupid = $('#meetingtaskgroup').val();
  //        drpDwn.itemName = $('#' + this.newmeetingid + 'meetingTasktype').html().replace('Meeting - ', '');;
  //        drpDwn.id = 0;
  //        if (drpDwn.itemName.toString().replace('\n', '').trim() != '') {
  //          this.meetingdropdowns.push(drpDwn);
  //        }
  //      }
  //    }
  //    this.customerService.checkduplicateTaskName(this.meetingdropdowns[0].id, this.meetingdropdowns[0].itemName, $('#meetingtaskgroup').val(), this.meetingdropdowns[0].category).then(taskgopdata => {
         
  //      if (taskgopdata == null) {
  //        this.customerService.saveDropdownValue(this.meetingdropdowns).then(result => {
  //          this.toastr.success(" Meeting Task List configured Successfully.", "Task List", { timeOut: 600 });
  //          this.newmeetingid = 0;
  //          this.meetingtaskids = [];
  //          this.meetingdropdowns = [];
  //          this.GetAllTasksList(val, $('#meetingtaskgroup').val(), 0);
  //          this.meetingbuttons = true; this.meetingNew = true;
            
  //        });
  //        $('#meetingTasktype').attr('style', "background-color:#808080;color:white");
  //      } else {
  //        alert("Task Name Already Exist");
  //        this.newmeetingid = 0;
  //        this.meetingtaskids = [];
  //        this.meetingdropdowns = [];
  //        return false;
  //      }
  //    });   
   
  //  }
  //  else if (val == 'phonecall') {
       
  //    var drpDwn = new Dropdowntype();
  //    this.phonecalltaskids.forEach(id => {
         
  //      if (id.Type == 'phonecall') {
       
  //        drpDwn.category = "Phone";

  //        drpDwn.taskgroupid = $('#phonetaskgroup').val();
  //        drpDwn.itemName = $('#' + id.Index + 'phoneTasktype').html().replace('Phone - ', '');
  //        drpDwn.id = parseInt($('#' + id.Index + 'phoneTaskID').html());
  //        if (drpDwn.itemName.toString().replace('&nbsp;', '').trim() != '') {
  //          this.phonecalldropdowns.push(drpDwn);
  //        }
  //        //this.tododropdowns.push(drpDwn);
  //      }
  //    });
      
  //    if (this.phonecalltaskids.length == 0) {
  //      if ((this.phonecalldropdowns.filter(f => { f.id == 0 && f.category == "Phone" && f.itemName == $('#' + this.newphonecallid + 'phoneTasktype').html() }).length == 0)) {
  //        var drpDwn = new Dropdowntype();
  //        drpDwn.category = "Phone";
  //        drpDwn.taskgroupid = $('#phonetaskgroup').val();
  //        drpDwn.itemName = $('#' + this.newphonecallid + 'phoneTasktype').html().replace('Phone - ', '');;
  //        drpDwn.id = 0;
  //        if (drpDwn.itemName.toString().replace('\n', '').trim() != '') {
  //          this.phonecalldropdowns.push(drpDwn);
  //        }
  //      }
  //    }
  //      this.customerService.checkduplicateTaskName(drpDwn.id, drpDwn.itemName, $('#phonetaskgroup').val(), drpDwn.category).then(taskgopdata => {
  //      if (taskgopdata == null) {
  //        this.customerService.saveDropdownValue(this.phonecalldropdowns).then(result => {
  //          this.toastr.success("Phone Call Task List configured Successfully.", "Task List", { timeOut: 600 });
  //          this.newphonecallid = 0;
  //          this.phonecalltaskids = [];
  //          this.phonecalldropdowns = [];
  //          this.GetAllTasksList(val, $('#phonetaskgroup').val(), 0);
  //          this.phoneCallbuttons = true; this.phonecallNew = true;
             
  //        });
          
  //        $('#phoneTasktype').attr('style', "background-color:#808080;color:white");
  //      } else {
  //        alert("Task Name Already Exist");
  //        this.newphonecallid = 0;
  //        this.phonecalltaskids = [];
  //        this.phonecalldropdowns = [];
  //        return false;
  //      }
  //    });

    
  //  }
  //}
  canceltodotasktype(val) {
     
    this.GetAllTasksList(val, this.currentuser.TaskGroupid, 0);
    if (val == "todo") { this.toDobuttons = true; this.toDoNew = true; $('#todoTasktype').attr('style', "background-color:#808080;color:white"); }
    else if (val == "meeting") { this.meetingbuttons = true; this.meetingNew = true; $('#meetingTasktype').attr('style', "background-color:#808080;color:white"); }
    else if (val == "phonecall") { this.phoneCallbuttons = true; this.phonecallNew = true; $('#phoneTasktype').attr('style', "background-color:#808080;color:white"); }
    
  }

  ToDoDelete(id, status) {
    if (status == '1') {
      $('#' + id + 'todoTasktype').attr('style', "background-color:#b7e4ff;")
      $('#' + id + 'todoTasktypeorder').attr('style', "background-color:#b7e4ff;")
      var that =this;
      $(setTimeout(function () {
        var c = confirm("Select Yes To Confirm You Want to Delete This Task item");
        if (c == true) {
          that.customerService.deletetaskitem(id, status).then((user) => {
            that.toastr.success(" To Do item deleted Successfully.", "Task List");
            that.GetAllTasksList('todo', $('#todotaskgroup').val(), 0);
          });
         // $('#todotaskgroup').val(1);
        }
        else {
          $('#' + id + 'todoTasktype').attr('style', "background-color:none;border:1px solid #A6A4A4")
          $('#' + id + 'todoTasktypeorder').attr('style', "background-color:none;border:1px solid #A6A4A4")
        }
      }, 200));    
    }
    else {

      $('#' + id + 'todoTasktype').attr('style', "background-color:#b7e4ff;")
      $('#' + id + 'todoTasktypeorder').attr('style', "background-color:#b7e4ff;")
      var that = this;
      $(setTimeout(function () {
      var c = confirm("Are you sure you want to do Activate to do item??");
      if (c == true) {
        that.customerService.deletetaskitem(id, status).then((user) => {
          that.toastr.success(" To Do item Activated Successfully.", "Task List");
          that.GetAllTasksList('todo', $('#todotaskgroup').val(), 1);
        });
        }
        else {
          $('#' + id + 'todoTasktype').attr('style', "background-color:none;border:1px solid #A6A4A4")
          $('#' + id + 'todoTasktypeorder').attr('style', "background-color:none;border:1px solid #A6A4A4")
        }
      }, 200));   
    }
  }
  PhoneCallDelete(id, status) {
    if (status == '1') {

      $('#' + id + 'phoneTasktype').attr('style', "background-color:#b7e4ff;")
      $('#' + id + 'phoneTasktypeorder').attr('style', "background-color:#b7e4ff;")
      var that = this;
      $(setTimeout(function () {
        var c = confirm("Select Yes To Confirm You Want to Delete This Task item");
        if (c == true) {
          that.customerService.deletetaskitem(id, 1).then((user) => {
            that.toastr.success("Phone call item deleted Successfully.", "Task List");
            that.GetAllTasksList('phonecall', $('#phonetaskgroup').val(), 0);
          });
        //  $('#phonetaskgroup').val(1);
        }
        else {
          $('#' + id + 'phoneTasktype').attr('style', "background-color:none;border:1px solid #A6A4A4")
          $('#' + id + 'phoneTasktypeorder').attr('style', "background-color:none;border:1px solid #A6A4A4")
        }
      }, 200));    

    }
    else {
      $('#' + id + 'phoneTasktype').attr('style', "background-color:#b7e4ff;")
      $('#' + id + 'phoneTasktypeorder').attr('style', "background-color:#b7e4ff;")
      var that = this;
      $(setTimeout(function () {
      var c = confirm("Are you sure you want to do Activate phone item??");
      if (c == true) {
        that.customerService.deletetaskitem(id, status).then((user) => {
          that.toastr.success(" Phone call item Activated Successfully.", "Task List");
          that.GetAllTasksList('phonecall', $('#phonetaskgroup').val(), 1);
        });
        }
        else {
          $('#' + id + 'phoneTasktype').attr('style', "background-color:none;border:1px solid #A6A4A4")
          $('#' + id + 'phoneTasktypeorder').attr('style', "background-color:none;border:1px solid #A6A4A4")
        }
      }, 200));  
    }
  }
  MeetingDelete(id, status) {
    if (status == '1') {
      $('#' + id + 'meetingTasktype').attr('style', "background-color:#b7e4ff;")
      $('#' + id + 'meetingTasktypeorder').attr('style', "background-color:#b7e4ff;")
      var that = this;
      $(setTimeout(function () {
        var c = confirm("Select Yes To Confirm You Want to Delete This Task item");
      if (c == true) {
        that.customerService.deletetaskitem(id, 1).then((user) => {
          that.toastr.success(" Meeting item deleted Successfully.", "Task List");
          that.GetAllTasksList('meeting', $('#meetingtaskgroup').val(), 0);
        });

       // $('#meetingtaskgroup').val(1);
      }
      else {
        $('#' + id + 'meetingTasktype').attr('style', "background-color:none;border:1px solid #A6A4A4")
        $('#' + id + 'meetingTasktypeorder').attr('style', "background-color:none;border:1px solid #A6A4A4")
        }
      }, 200)); 
    }
    else {
      $('#' + id + 'meetingTasktype').attr('style', "background-color:#b7e4ff;")
      $('#' + id + 'meetingTasktypeorder').attr('style', "background-color:#b7e4ff;")
      var that = this;
      $(setTimeout(function () {
      var c = confirm("Are you sure you want to do Activate meeting item??");
      if (c == true) {
        that.customerService.deletetaskitem(id, status).then((user) => {
          that.toastr.success("Meeting item Activated Successfully.", "Task List");
          that.GetAllTasksList('meeting', $('#meetingtaskgroup').val(), 1);
        });
        }
        else {
          $('#' + id + 'meetingTasktype').attr('style', "background-color:none;border:1px solid #A6A4A4")
          $('#' + id + 'meetingTasktypeorder').attr('style', "background-color:none;border:1px solid #A6A4A4")
        }
      }, 200)); 
    }
  }

  edit(jval, tasktype) {
     
    
    if (tasktype =="todoTasktype" && $('#todotaskgroup').val() == "0") {
      alert("Please Select Category Before Adding Item")
    }
   else if (tasktype == "phoneTasktype" && $('#phonetaskgroup').val() == "0") {
      alert("Please Select Category Before Adding Item")
    }
   else if (tasktype == "meetingTasktype" && $('#meetingtaskgroup').val() == "0") {
      alert("Please Select Category Before Adding Item")
    }
    else {

          if (tasktype == "todoTasktype") {
          //  
            if (jval == 0) {
              var toDoEmpty = ({ id: 0, itemName: '', category: null })
              this.toTasktypelist.push(toDoEmpty);
            }
            //var toDoEmpty = ({ id: jval, itemName: '', category: null })
           // this.toTasktypelist.push(toDoEmpty);
          }
          else if (tasktype == "phoneTasktype") {
            //var phoneCallEmpty = ({ id: 0, itemName: '', category: null })
            if (jval == 0) {
              var phoneCallEmpty = ({ id: jval, itemName: '', category: null })
              this.phonecallTasktypelist.push(phoneCallEmpty);
            }
          }
          else if (tasktype == "meetingTasktype") {
            if (jval == 0) {
              var meetingEmpty = ({ id: jval, itemName: '', category: null })
              this.meetingTasktypelist.push(meetingEmpty);
            }
          }

           $(setTimeout(function () {
             
            $('.disableEditable').attr('contenteditable', "false")
             $('.disableEditable').attr('style', "border: 1px solid #A6A4A4;background-color:#f2f2f3;")
             $('.disableEditableButton').attr('style', "border: 1px solid #A6A4A4;background-color:#f2f2f3;")
            
            $('#' + jval + tasktype).attr('contenteditable', "true")
            $('#' + tasktype).removeClass('btn-default');
            if (jval == 0) {
              $('#' + tasktype).attr('style', "background-color:#b7e4ff;")
            }

            $('.Tr').removeAttr('style');
            // $('.Tr').attr('style', "background-color:yellow;")
            $('#' + jval + tasktype).attr('style', "background-color:#b7e4ff;")
            $('#' + jval + tasktype + 'order').attr('style', "background-color:#b7e4ff;")

            if (($('#' + jval + tasktype).html()).trim() == 'add item here') {
            $('#' + jval + tasktype).html('')
            }
          }, 200));     


      
        }

    
  }
  addItemHereDblClk(jval, tasktype) {

    if (tasktype == "phoneTasktype") {
      $("#phoneItemId").hide();
      $("#0phoneTasktype").show();
    }
    this.edit(jval, tasktype);
  }

  ToDoReordertaskItems(tasktype) {
    var c = confirm("Are you sure you want to Reorder items in this Order??");
    if (c == true) {
      var TaskIdArr = [];
      if (tasktype == 'To Do') {
         
        $.each($("#todoTable tbody tr td[id$='todoTaskID']"), function (k, v) {
          var TaskIds = $.trim($(this).html());
          TaskIdArr.push(TaskIds);
        })
        this.tasklistconfiguration.taskgroupid = $('#todotaskgroup').val();

      }
      else if (tasktype == 'Phone') {
        $.each($("#phoneTable tbody tr td[id$='phoneTaskID']"), function (k, v) {
          var TaskIds = $.trim($(this).html());
          TaskIdArr.push(TaskIds);
        })
        this.tasklistconfiguration.taskgroupid = $('#phonetaskgroup').val();
      }
      else if (tasktype == 'Meeting') {
        $.each($("#meetingTable tbody tr td[id$='meetingTaskID']"), function (k, v) {
          var TaskIds = $.trim($(this).html());
          TaskIdArr.push(TaskIds);
        })
        this.tasklistconfiguration.taskgroupid = $('#meetingtaskgroup').val();
      }
    //  TaskIdArr.pop();
      this.tasklistconfiguration.configureduserid = this.currentuser.id;
      this.tasklistconfiguration.tasklistconfigurtaionorder = TaskIdArr.toString();
      this.tasklistconfiguration.tasktype = tasktype;
      this.customerService.SaveTaskListConfiguration(this.tasklistconfiguration).then(result => {
        this.toastr.success(tasktype + " Task List Re-Ordered Successfully.", tasktype + " Task List", { timeOut: 600 });

      });
    }
  }

  userListOutParamsBinding(outProps) {
    this.finalitems = outProps.finalitems;
    this.selectedItems = outProps.selectedItems;
    this.items = outProps.items;
    this.itemCount = outProps.itemsCount;
  }
  SaveGroup(val) {
    this.taskgroup.tasktype = val;
    if (val == 'todo')
      this.taskgroup.TaskGroupName = $('#ToGrouptaskname').val();
    else if (val == 'meeting') this.taskgroup.TaskGroupName = $('#MeetingTaskgroupName').val();
    else if (val == 'phone') this.taskgroup.TaskGroupName = $('#PhoneTaskgroupName').val();
   
    if (this.taskgroup.TaskGroupName.trim() == "")
      return false;
    if (this.taskgroup.id == 0) {
      this.customerService.checkduplicateTaskGroupName(this.taskgroup).then(result => {
        if (result == null) {          
          this.customerService.addtaskgroup(this.taskgroup).then(result => {

            this.toastr.success("New Task Group Saved Successfully.", "Task Group", { timeOut: 600 });
            this.ShowTaskGrpDialog = false;
            this.loadTaskGrouplist(0);
            this.userservice.GetAllTaskgrouplist("TaskList",0).then((taskgroups) => {
              this.CancelTaskGroup(val);
              $('#ToGrouptaskname').val('');
              $('#MeetingTaskgroupName').val('');
              $('#PhoneTaskgroupName').val('');
            });
          });
        }
        else {
          alert(this.taskgroup.TaskGroupName + "  Already Exist")
        }
      });
    } else {
      this.customerService.addtaskgroup(this.taskgroup).then(result => {

        this.toastr.success("New Task Group Saved Successfully.", "Task Group", { timeOut: 600 });
        this.ShowTaskGrpDialog = false;
        this.loadTaskGrouplist(0);
        this.userservice.GetAllTaskgrouplist("TaskList",0).then((taskgroups) => {
          this.CancelTaskGroup(val);
          $('#ToGrouptaskname').val('');
          $('#MeetingTaskgroupName').val('');
          $('#PhoneTaskgroupName').val('');
        });
      });
    }
  }
  SaveTaskGroup(value) {
    this.taskname = (value == 'To Do') ? $('#ToGrouptaskname').val() : (value == 'Phone') ? $('#PhoneTaskgroupName').val() : (value == 'Meeting') ? $('#MeetingTaskgroupName').val() : "";
    this.ShowTaskGrpDialog = true;
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width:35% !important;border: 1px solid black; background:#93d14f; height:35%; top:16%;max-width:none;padding:1%;');
    }, 200));
    if (this.taskgroup.id != 0) {
      this.edittaskgroup = true;
    } else { this.edittaskgroup = false; }

  }


  AddTaskGroup(type) {
    this.tasktype = type;
    $('.taskGroupType').removeClass('selectedTaskGroupType')
    if (type == 'todo') {
      $('#todoAddTaskGroup').removeClass('btn-default');
      $('#todoAddTaskGroup').addClass('selectedTaskGroupType');
      this.taskgroup.id = $('#todotaskgroup').val();   
      $('#ToGrouptaskname').val($("#todotaskgroup option:selected").text());
      this.todoNewTextSave = false;
      this.todoNewButtonSave = true;
      this.phoneNewTextSave = true;
      this.phoneButtonSave = true;
      this.meetingNewTextSave = true;
      this.meetingButtonSave = true;
    } else if (type == 'phone') {
      $('#phoneAddTaskGroup').removeClass('btn-default');
      $('#phoneAddTaskGroup').addClass('selectedTaskGroupType');
      this.taskgroup.id = $('#phonetaskgroup').val();
      $('#PhoneTaskgroupName').val($("#phonetaskgroup option:selected").text());
      this.todoNewTextSave = true;
      this.todoNewButtonSave = true;
      this.phoneNewTextSave = false;
      this.phoneButtonSave = true;
      this.meetingNewTextSave = true;
      this.meetingButtonSave = true;
    } else {
      $('#meetingAddTaskGroup').removeClass('btn-default');
      $('#meetingAddTaskGroup').addClass('selectedTaskGroupType');
      this.taskgroup.id = $('#meetingtaskgroup').val();
      $('#MeetingTaskgroupName').val($("#meetingtaskgroup option:selected").text());
      this.todoNewTextSave = true;
      this.todoNewButtonSave = true;
      this.phoneNewTextSave = true;
      this.phoneButtonSave = true;
      this.meetingNewTextSave = false;
      this.meetingButtonSave = true;
    }
  }
  enableButtons(type) {
    if (type == 'todo') {

      if ($('#ToGrouptaskname').val().trim() == "")
        this.todoNewButtonSave = true;
      else
        this.todoNewButtonSave = false;

      this.todoNewTextSave = false;
      this.phoneNewTextSave = true;
      this.phoneButtonSave = true;
      this.meetingNewTextSave = true;
      this.meetingButtonSave = true;
    } else if (type == 'phone') {

      if ($('#PhoneTaskgroupName').val().trim() == "")
        this.phoneButtonSave = true;
      else
        this.phoneButtonSave = false;

      this.todoNewTextSave = true;
      this.todoNewButtonSave = true;
      this.phoneNewTextSave = false;
      this.meetingNewTextSave = true;
      this.meetingButtonSave = true;
    } else {

      if ($('#MeetingTaskgroupName').val().trim() == "")
        this.meetingButtonSave = true;
      else
        this.meetingButtonSave = false;

      this.todoNewTextSave = true;
      this.todoNewButtonSave = true;
      this.phoneNewTextSave = true;
      this.phoneButtonSave = true;
      this.meetingNewTextSave = false;
    }
  }
  CancelTaskGroup(type) {
    $('.taskGroupType').removeClass('btn-default')
    $('.taskGroupType').removeClass('selectedTaskGroupType')
    $('.taskGroupType').addClass('btn-default')

    $('#ToGrouptaskname').val('');
    $('#MeetingTaskgroupName').val('');
    $('#PhoneTaskgroupName').val('');
    this.todoNewTextSave = true;
    this.todoNewButtonSave = true;
    this.todoNewTextSave = true;
    this.todoNewButtonSave = true;
    this.phoneNewTextSave = true;
    this.phoneButtonSave = true;
    this.meetingNewTextSave = true;
    this.meetingButtonSave = true;
  }

  loadtaskitems(taskgroup, type, status) {
    if (type == "todo") {
      //if ($("#isTodoSelected").is(':checked')) {
        taskgroup = $('#todotaskgroup').val();
        this.GetAllTasksList(type, taskgroup, status);
      //}
      //else {
      //  taskgroup = $('#todotaskgroup').val();
      //  this.GetAllTasksList(type, taskgroup, 0);
      //}
    }
    else if (type == "phonecall") {
      if ($("#isPhoneSelected").is(':checked')) {
        taskgroup = $('#phonetaskgroup').val();
        this.GetAllTasksList(type, taskgroup, status);
      }
      else {
        taskgroup = $('#phonetaskgroup').val();
        this.GetAllTasksList(type, taskgroup, 0);
      }
    }
    else if (type == "meeting") {
      if ($("#isMeetingSelected").is(':checked')) {
        taskgroup = $('#meetingtaskgroup').val();
        this.GetAllTasksList(type, taskgroup, status);
      }
      else {
        taskgroup = $('#meetingtaskgroup').val();
        this.GetAllTasksList(type, taskgroup, 0);
      }
    }
  }
 

  appendLabel(value) {
     
    this.selectedvalue = value
    this.activateSubButtons(value);
   
    //$('#addDetailsDiv').attr('style', 'height:250px ');
    //this.Dropdownconfig.LoadDropDownValues(this.selectedvalue);
  }
  MainrowClickAgGrid(rowEvent) {
    this.initializeAgGridSubConfiguration();

  }
  UserSetUp(val) {
    if (val == "Users")
    {
      this.viewSuspendedUsers = false;
      this.viewSuspended(0);
    }
    else if (val == "Suspended") {
      this.viewSuspendedUsers = true;
      this.viewSuspended(1);
    }
    else {
      this.viewSuspendedUsers = false;
    }
    this.selectedUserType = val;  
  }

  UserQuota(val) {
    this.applicationComponent.SelectedTab = "tab14";
    let previousTab = this.selectedQuotaMenuRadio;
    this.selectedQuotaType = val;
    if (this.user.id == undefined || this.user.id == null || this.user.id == 0) {
      let selfObj = this;
      alert("Please select a user.");

      $(setTimeout(function () {
        selfObj.selectedQuotaMenuRadio = previousTab;
      }, 1));
    } else {


      if (val == "TaskQuota") {
        this.selectedQuotaMenuRadio = "Quotatab1";
        let Obj = this;
        $(setTimeout(function () {
          Obj.userquotaComp.LoadActivityQuotaData(Obj.user.id, Obj.currentYear);
        }, 300));
      }
      else if (val == 'TicketQuota') {
        this.selectedQuotaMenuRadio = "Quotatab2";
        let Obj = this;
        $(setTimeout(function () {
          Obj.userquotaComp.LoadSalesQuotaData(Obj.user.id, Obj.currentYear);
        }, 300));
      } else if (val == 'SalesQuota') {
        this.selectedQuotaMenuRadio = "Quotatab3";
        let Obj = this;
        $(setTimeout(function () {
          Obj.userquotaComp.LoadSalesQuotaData(Obj.user.id, Obj.currentYear);
        }, 300));
      }
    }

  }
  DashboardChanged(val) {
    
    let previousTab = this.selectedMenuRadio;
    localStorage.setItem('PrevUserConfigTab', previousTab);
    this.applicationComponent.PrevUserConfigTab = previousTab;
    if (this.useraccountChange) {
      this.UserAccountchangeDialogs = true;
      this.UserAccountchangeDialogCss();
    }
    this.selectedtab = val;
    if (val == "Dashboard") {
      this.SingleSecondDrpSetting = {
        singleSelection: true,
        text: 'Select Dashboard Type',
        selectAllText: 'Select All',

        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        badgeShowLimit: 3
      };
      this.getmyDashDefGridBoxlist();
      this.selectedMenuRadio = "tab12";
      if (this.user.id == undefined || this.user.id == null || this.user.id == 0) {
        let selfObj = this;
        alert("Please select a user.");
        $(setTimeout(function () {
          selfObj.selectedMenuRadio = previousTab;
        }, 1));
      } else
        this.customerService.GetSpecialityList("DashBoardTypes", this.currentuser.id).then((CustomerType) => {
          CustomerType.forEach(CustomerType => {
          });
          this.DashBoardTypesList = CustomerType;
          this.showUserDashBoardDiv = true;
        });
      if (this.user.id != undefined && this.user.id != null && this.user.id != 0) {
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.loadDashBoardconfigByuser(selfObj.user.id);
          selfObj.getSelectedtmyDashDefGridBox();
        }, 500));
      }
    }
    else if (val == "Privileges") {

      this.applicationComponent.SelectedTab = "tab18";
      if (this.user.id == undefined || this.user.id == null || this.user.id == 0) {
        let selfObj = this;
        alert("Please select a user.");
        $(setTimeout(function () {
          selfObj.selectedMenuRadio = previousTab;
        }, 1));
      } else
        this.showUserPrivilegesDiv = true;
      this.loadResourcePrivilegeList(this.user.id, null);
      this.selectedMenuRadio = "tab18";
    }
    else if (val == "Alerts") {

      if (this.user.id == undefined || this.user.id == null || this.user.id == 0) {
        let selfObj = this;
        alert("Please select a user.");
        $(setTimeout(function () {
          selfObj.selectedMenuRadio = previousTab;
        }, 10));
      } else {
        this.selectedMenuRadio = 'tab13';
        this.showAlertsDiv = true;
        let selfObj = this;
        this.user.id = this.user.id;
        $(setTimeout(function () {
          selfObj.AutomatedEmails.loadUserAlertEmails(this.user.id);

        }, 300));

      }
    }
    else if (val == 'UserQuota') {
      this.UserQuota('TaskQuota');
    }
    else if (val == 'Sales') {
      this.UserQuota('SalesQuota');
    }
    //else if (val == 'CustomerView') {
    //  this.user.hidecustomer = this.currentuser.hidecustomer;
    //}
    else if (val == "Registration") {
      this.showAddUserDiv = true;
      this.applicationComponent.SelectedTab = "tab11";
      this.selectedMenuRadio = "tab11";
    }
  
  }
  IntializeDashboardGrid() {
    this.DashboardgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.createDashBoardTypesColumnDefs("");
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };

  }
  createDashBoardTypesColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.DashboardcolumnDefs = [
      { field: 'id', headerName: 'Id', hide: true, width: 10 },
      { field: 'itemName', headerName: 'DashBoard Types', hide: false, width: 150 },
      {
        headerName: "Display (Yes)", width: 120, hide: false, outerWidth: 8, cellRenderer(params) {
           
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<input id="' + params.data.id + 'Yes"' + ' type="checkbox">';
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
            selfObj.Change(params.data.id + "Yes");
            $('#saveDashboardConfig').css("background", "#93d250");
            $('#saveDashboardConfig').prop('disabled', false);
            
          });
          return eDiv;
        }
      },
      {
        headerName: "Display (No)", width: 120, hide: false, outerWidth: 8, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<input id="' + params.data.id + 'No"' + ' type="checkbox">';
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
            selfObj.Change(params.data.id + "No");
            $('#saveDashboardConfig').css("background", "#93d250");
            $('#saveDashboardConfig').prop('disabled', false);
          });
          return eDiv;
        },
      },

    ];

  }

  SaveDashBoardData(id: number = 0) {
    this.finaldashboardconfiglist = [];
    //let rowData = [];
    //this.DashboardgridOptions.rowData.forEachNode(node => rowData.push(node.data));
    //return rowData;
     
    if (this.DashboardgridOptions.api == undefined) {
      this.customerService.GetSpecialityList("DashBoardTypes", this.currentuser.id).then((CustomerType) => {
        this.DashBoardTypesList = CustomerType;

        let count = this.DashBoardTypesList.length;
        for (var i = 0; i < count; i++) {

          let newdash = new Dashboardconfig();
          newdash.dashboardtypeid = this.DashBoardTypesList[i].id; //rowNode.data.id;


          if (i == 0)
            newdash.isaccess = 1;
          else
            newdash.isaccess = 0;

          newdash.userid = this.currentuser.id;
          newdash.selecteduserid = this.user.id;
          this.finaldashboardconfiglist.push(newdash);
        }
        if (this.finaldashboardconfiglist.length > 0) {
          this.userservice.SaveDashBoardConfig(this.finaldashboardconfiglist).then(result => {
            this.toastr.success("DashBoard Configured successfully.", "DashBoard Config", { timeOut: 600 });
            this.useraccountChange = false;
          });
        }
        
      });
    } else {
      let count = this.DashboardgridOptions.api.getDisplayedRowCount();
      for (var i = 0; i < count; i++) {
         
        var rowNode = this.DashboardgridOptions.api.getDisplayedRowAtIndex(i);
        let newdash = new Dashboardconfig();
        newdash.dashboardtypeid = rowNode.data.id;
        newdash.isaccess = $('#' + rowNode.data.id + 'Yes').is(":checked") == true ? 1 : 0;
        newdash.userid = this.currentuser.id;
        newdash.selecteduserid = this.user.id;
        this.finaldashboardconfiglist.push(newdash);
      }
      this.userservice.SaveDashBoardConfig(this.finaldashboardconfiglist).then(result => {
        this.toastr.success("DashBoard Configured successfully.", "DashBoard Config", { timeOut: 600 });
        this.useraccountChange = false;
      });
    }
    $("#saveDashboardConfig").css("background", "#d3d3d3");
    $('#saveDashboardConfig').prop('disabled', true);
    this.applicationComponent.PrevUserConfigTab = "";
  }
  UpdateTaskName() {
    this.userservice.modifyTaskGroupidtoUsers(this.fromtaskgroupid, this.user.TaskGroupid).then(result => {
      this.toastr.success("Task Group Updated successfully.", "Group Updated", { timeOut: 600 });
      this.TaskWarningDialog = false;
    });

  }
  //ChangeDocumentType() {
     
  //  this.selectedDocType = $("#DocumenttypeId option:selected").text();
  //  this.docMgmtComp.loaddocuments(this.selectedDocType.trim(), this.currentYear);
  //}
  RoleeventReceived(msg) {
    var idstatus = msg.split("|")
    this.userservice.checkRoleTiedtoUser(idstatus[0]).then(resultdata => {
      this.Roleassociatedusers = resultdata;
      if (resultdata.length == 0) {
        var c = confirm("Select Yes To Confirm You Want to Delete This Role");
        if (c == true) {
          this.userservice.deleteRole(idstatus[0], idstatus[1]).then((user) => {
            this.toastr.success(" Role deleted Successfully.", "Role", { timeOut: 600 });
            this.roleComp.GetAllRoles();
          });
        } 
      }
      else {
        this.selectedRole = idstatus[2];
        this.showDeleteDialog = true;
        this.dialogsizeDeleteRole();
        // this.selectedMenuRadio = "";
      }
    });

  }
  DeActivateTaskGroup(value,status) {
     
    this.taskname = (value == 'todo') ? $("#todotaskgroup option:selected").text() : (value == 'phone') ? $("#phonetaskgroup option:selected").text() : (value == 'meeting') ? $("#meetingtaskgroup option:selected").text() : "";
    this.fromtaskgroupid = (value == 'todo') ? $('#todotaskgroup').val() : (value == 'phone') ? $('#phonetaskgroup').val() : (value == 'meeting') ? $('#meetingtaskgroup').val():"";
    this.userservice.checkAssociatedGroup(this.fromtaskgroupid).then(result => {
      if (result > 0) {
        this.TaskWarningDialog = true;
        $(setTimeout(function () {
          $('.dialog').attr('style', 'width:40% !important;border: 1px solid black; background:#93d14f; height:75%; top:16%;max-width:none;padding:1%;text-align:center;');
        }, 200));
      } else {
        var c =(status == 1)? confirm("Are you sure you want to Delete this Task Group Name?") : confirm("Are you sure you want to Activate this Task Group Name?");
        if (c == true) {
          this.userservice.DeactivateTaskGroup(this.fromtaskgroupid,status).then((user) => {
            this.loadTaskGrouplist(status);
          });

        }
        }

    });
  }
  AccountIdChange(val) {  }
  onCellValueChanged(params) {
    
        this.rolesList.forEach(rolelist => {
      if (params.newValue == rolelist.itemName) {
        params.data.userrole = rolelist.id;
      }
    });

    this.userservice.updateRole(params.data.userrole,params.data.id).then((user) => {
     // this.loadTaskGrouplist(status);
    });
   
  }
  dialogsizeDeleteRole() {
    setTimeout(function () {
      $('#showDeleteDialog .dialog').attr('style', 'width: 53%;padding - bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min - height: 50%;overflow: visible;height: 82%;top: 17%;left:43%');
    }, 4);
  }
  cellDoubleClicked(rowEvent) {
    this.userView = true;
    $("#tab12").trigger("click");
    this.DashboardChanged('Privileges');
    this.selectedMenuRadio = 'tab12';
    this.selectedPrvMenuRadio = 'prvtab2';
    this.PrivilageTabChanged('Navigation Menu');
    this.GetDocumentAccess();
  }
  closeuser() {
    this.userView = false;
    $("#tab11").trigger("click");
    this.DashboardChanged('Registration');
    this.selectedMenuRadio = 'tab11';

  }
  closedConfigDashEventListener() {
    var tab = localStorage.getItem('PrevUserConfigTab');
    if (this.applicationComponent.isClosedConfigDashEventListener) {
      if (tab == "tab11")
        this.RegisterUser('save');
      else if (tab == "tab12")
        this.RegisterUser('Previlages');
      else if (tab == "tab13")
        this.SaveDashBoardData();
      else if (this.applicationComponent.SelectedTab == "tab22")
        this.RegisterUser('customerview');

      this.applicationComponent.isClosedConfigDashEventListener = false;
      this.applicationComponent.isUserConfig = false;
      this.applicationComponent.isUserPrivilege = false;
      this.applicationComponent.isUserDashboard = false;
    }
    return false;
  }
  onuserConfiguration(val) {
    if (val == "Changed") {
      this.useraccountChange = true;
    }
    else if (val == 'Alert Cancel') {
      this.CancelAlert();
    }
    else {

      this.useraccountChange = false;
      
    }
  }
  CloseUserChangeDialog(val) {
    if (val == 'danotsavechanges') {
      this.UserAccountchangeDialogs = false;
    
      this.useraccountChange = false;
     
    }
    else if (val == 'savechanges') {
      if (this.applicationComponent.SelectedTab == 'tab12') {
        this.RegisterUser('Previlages')
      } else if (this.applicationComponent.SelectedTab == 'tab11') {
        this.RegisterUser('save');
      } else if (this.applicationComponent.SelectedTab == 'tab13') {
        this.SaveDashBoardData();
      } 
      
      this.UserAccountchangeDialogs = false;
    }
  }

  GetDocumentAccess() {
    //this.totaskids.filter(f => f.Index == taskid && f.Type == "todo")
    let permissionlevel = this.permissionlevellist.filter(f => f.id == this.user.permissionlevelid);
    this.documentAccessName = permissionlevel[0].itemName;

    let userpermissionlevel = this.userpermissionlevellist.filter(f => f.id == this.user.userpermissionlevelid);
    this.mySalesXAccessName = userpermissionlevel[0].itemName;
  }
  ShowAddUserDiv() {
    this.DashboardChanged('Registration');
    this.selectedMenuRadio = 'tab11';
    this.userListgridApi.deselectAll();
   
    let objSelf = this;
    $(setTimeout(function () {
      objSelf.showAddUserDiv = true;
      objSelf.user = new Users();      
    }, 100));

  }

  CancelUser() {
    this.showAddUserDiv = false;
    this.user = new Users();
    this.gridApi.deselectAll();
  }
  CancelUserPrivilages() {
    this.showUserPrivilegesDiv = false;
    var tab = localStorage.getItem('PrevUserConfigTab');
    this.selectedMenuRadio = tab;
  }
  CancelUserDashBoard() {
    this.showUserDashBoardDiv = false;
    var tab = localStorage.getItem('PrevUserConfigTab');
    this.selectedMenuRadio = tab;
  }

  CancelAlert() {
    this.showAlertsDiv = false
    var tab = localStorage.getItem('PrevUserConfigTab');
    this.selectedMenuRadio = tab;
  }

  //LeadTypeReceiveEvent(msg) {
  //  if (msg == 'Lead Type') {
  //    this.LeadType = true;
  //  }
  //  else if ("Close Lead Type") {
  //    this.LeadType = false;
  //  } else {
  //    this.LeadType = false;
  //  }
  //}

  ProposakAccessType(msg) {
    if (msg != null && msg != undefined) {
      this.proposalType = msg;
      
    }
  }

  systemAdminClick() {
    if (this.currentuser.systemadministrator == 0) {
      this.AdminAccess = true;
      setTimeout(function () {
        $('#SystemAdminAccessBox .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
      }, 4);
    }
  }
  CloseAccessDlg() { this.AdminAccess = false; }

  PrivilageTabChanged(grpName) {
    if (grpName == 'Navigation Menu') {
      this.selectedPrvMenuRadio = 'prvtab2';
    } else if (grpName == 'Action Icon') {
      this.selectedPrvMenuRadio = 'prvtab3';
    } else if (grpName == '3 Dot Icon') {
      this.selectedPrvMenuRadio = 'prvtab4';
    } else {
      this.selectedPrvMenuRadio = 'prvtab1';
    }
    
    this.loadResourcePrivilegeList(this.user.id, grpName);
  }
  onUserConfigItemSelect(item: any) {
    if (item.value == 'Users (Active)') { this.UserSetUp('Users'); }
    else if (item.value == 'Users (Inactive/Suspended)') { this.UserSetUp('Suspended'); }
    else if (item.value == 'Users (Role Configuration)') { this.UserSetUp('Role');}
 
  }
 
  close() {

    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
    
  }

  ValidatePassword(event) {
    if (event.target.value != null) {
      var pwd = event.target.value;

      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      if (!strongRegex.test(pwd)) {
        this.passwordMissmatch = true;
        this.passwordMissmatchCss();
        !this.userform.valid;
      } 
    }
  }
  passwordMissmatchCss() {
    setTimeout(function () {
      $('#passwordMissmatch .dialog').attr('style', 'width: 40%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 42%;overflow: auto;height: 42%;top:10%;padding:0;');
    }, 4);
  }
  ClosePasswordMissmatch() {
    this.passwordMissmatch = false;
    //setTimeout(() => {
    //  document.getElementById('password').focus();
    //}, 100);
  }

  addFiles(event) {

    let files = event.srcElement.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      this.addChargeNew(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      this.urlImage = reader.result.toString();
    }
  }

  addChargeNew(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.user.ProfilePicture = reader.result.toString();
      selfObj.user.Phototype = file.type;
      selfObj.urlImage = reader.result.toString();
      selfObj.customerService.uploadUserProfilePhoto(selfObj.user).then((user) => {
        selfObj.currentuser.ProfilePicture = selfObj.user.ProfilePicture;
        localStorage.setItem('currentUser', JSON.stringify(selfObj.currentuser));
      });
    }, 500));
  }

  onGridSettingItemSelect(item: any) {
    this.mydash.selectedMyDashDefaultGridId = item.id;
  }
  onGridSettingItemDeSelect(item: any) {
    this.mydash.selectedMyDashDefaultGridId = item.id;
  }
  cancel() {

  }
}
