import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid';
import { AuditLogModel } from '../../models/auditlog';
import { Proposals } from '../../models/Praposals';
import { ProposalsModel } from '../../models/Proposalsmodel';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { UserService } from '../../services/user.service';
import { ApplicationComponent } from '../application.component';
import { EditdeletecvComponent } from '../editdeletecv/editdeletecv.component';
import { ProposalsComponent } from '../proposals/proposals.component';
import { DatePipe } from '@angular/common';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare var $: any;

@Component({
  selector: 'app-pendedproposals',
  templateUrl: './pendedproposals.component.html',
  styleUrls: ['./pendedproposals.component.css']
})
export class PendedproposalsComponent implements OnInit {
  public selectedItems: any;
  public gridOptions: GridOptions;
  public sideBar;
  public frameworkComponents;
  public context;
  public columnDefs: any[];
  currentuser: any;
  AllPendedProposals: ProposalsModel[];
  Proposal: Proposals = new Proposals();
  DeleteConfirmation: boolean = false;
  @ViewChild(ProposalsComponent) proposal;
  datePipe: DatePipe = new DatePipe("en-US");
  constructor(public applicationComponent: ApplicationComponent, public userService: UserService, private router: Router, private opprtunityservice: OpprtunitiesService
    ) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));  
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    }
  }

  ngOnInit(): void {
    this.getPendedProposals($("#userids").val());
    this.initializeAgGrid();
    var obj: AuditLogModel = { id: 0, type: 'Pended Proposal', action: 'Pended Proposal Viewed', AccountOrContactId: 0, AccountOrContact: "", documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Pended Proposal Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }
  getPendedProposals(userid) {
    this.userService.GetAllPendedProposals(userid, 1).then(result => {
      this.AllPendedProposals = result;     
      this.AllPendedProposals.forEach(prop => {
        prop.estimatedstrclosedate = prop.estimatedclosedate==null?"": this.datePipe.transform(prop.estimatedclosedate, "MM/dd/yyyy").toString();
      });
   
    });
  }
  close() {

    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
  }
  UserlistDataReceived(event) {
    if (event.finalitems == "") { this.AllPendedProposals = []; } else {
      this.getPendedProposals(event.finalitems);
    }
  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true,
      toolPanelSuppressPivotMode: true, rowSelection: 'multiple', enableCellChangeFlash: true
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createColumnDefs();

    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeletecvComponent,
    };


  }

  createColumnDefs() {
    let selfObj = this;
    selfObj.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
   
      {
        field: 'estimatedstrclosedate', headerName: 'ECD', hide: false, cellStyle: function (params) {
          { return { color: '#FF0000' }; }
        }
      },
      { field: 'AccountName', headerName: 'Account', hide: false },
      { field: 'ContactName', headerName: 'Contact', hide: false },
      {
        field: 'isPended', headerName: 'Pended', hide: false, cellRenderer: function (params) {
        { return 'Pended'; }}, cellStyle: function (params) {
          { return { color: '#FF0000' }; }
        }
      },
      { field: 'dollers', headerName: 'Dollers', hide: false, width: 100 },
      {
        field: 'RR', headerName: 'RR', hide: false, width: 100
      },

      { field: 'itemName', headerName: 'Proposal Type', hide: false },
      { field: 'ProposalDescription', headerName: 'Proposal Description', hide: false },
     
      {
        field: 'datecreated', headerName: 'Date Created', hide: false
      },
      {
        field: 'createdUser', headerName: 'Created By', hide: false, width: 100
      },
      {
        field: 'owner', headerName: 'User', hide: false
      },
      { field: 'ProposalID', headerName: 'ProposalID', hide: true },
      { field: 'OpportunityID', headerName: 'OpportunityID', hide: true },
      { field: 'CreatedDate', headerName: 'Date', hide: true },
      {
        field: 'action', headerName: 'Action', hide: false, width: 90, pinned: 'right', cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true"></i>';
          eDiv.addEventListener('click', function () {
            selfObj.deletePendProposal(params.data);
          });
          return eDiv;
        }
      }

    ];
  }

  deletePendProposal(proposal) {
    this.Proposal = proposal;
    this.DeleteConfirmation = true;
    this.DeletePendProposalCss();
  }
  DeletePendProposalCss() {
    setTimeout(function () {
      $('#DeleteConfirmation .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 4)
  }
  ProposalCss() {
    setTimeout(function () {
      $('#isloader .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 4)
  }
  Confirmation(val) {
    if (val == 'Ok') {     
      this.opprtunityservice.deleteopportunity(this.Proposal.OpportunityID).then(result => {
        this.DeleteConfirmation = false;
        this.getPendedProposals(this.currentuser.id);
      });
     
    } else {
      this.DeleteConfirmation = false;
    }
  }

  cellDoubleClicked(rowEvent) {
    this.applicationComponent.selectedsubbutton = "";
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.showSearchFieldsDialog = false;
    this.applicationComponent.ProposalID = rowEvent.data.ProposalID;
    $('#emailIds').val(rowEvent.data.accountorcontactid);
    $('#tasklistselectedtyle').val('Contact')
       this.applicationComponent.activateMainButtons('Create Proposal');   
  }
}

