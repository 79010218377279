export class Hero {
  id = 0;
  name = '';
  addresses: Accountlocations[];
}

export class Accountlocations {
  public addressType: string;
  id;
  address1 = '';
  address2 = '';
  city = '';
  state = '';
  public country: string= '';
  zip = ''; 
  alterphone1 = '';
  alterphone2 = '';
  accountid = 0;
  public lattitude: string = '';
  public longtitude: string = '';

  public ProvidenceIsland: string = '';
  public phone: string = '';
  public fax: string = '';
  public addressName = "";
  public isdelete = 0;

  public LocationPicture: string;
  public PictureType: string;
}

