export class Emails {
    public id: number;
  public fromuserid: string;
  public from: string;
  public to: string;
  public toids: string;
    public subject: string;
    public messagebody: string;
  public sentemailDate: string;
  public sentemailTime: string;
  public receiveemailDate: string;
  public bccids: string;
  public ccids: string;
  public filenames: string;
  attachements: Attachements[];
  attachedfiles = [];
}
export class Attachements {
  public filename: string;
  public filetype: string;
  public filepath: string;
  public imagecontent: string;
}
