import { Component, OnInit, ViewContainerRef, ElementRef,  Input, Output, EventEmitter, ViewChild, NgZone } from '@angular/core';
import { Contacts } from '../../models/contacts';
import { Customers } from '../../models/customers';
import { Notes } from '../../models/notes';
import { HttpClient } from '@angular/common/http';
import { AccountsService } from './../../services/accounts.service';
import { CustomerService } from './../../services/customer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Dropdowntype } from '../../models/dropdowntype';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Statistics } from '../../models/statistics';
import { Location } from '@angular/common';
import { Tickets } from '../../models/tickets';
import { Emails } from '../../models/emails';
import { Document } from '../../models/document';
import { Project } from '../../models/project';
import { Accounts } from '../../models/accounts';
import { HeroService } from './../../services/hero.service';
import { Accountlocations } from '../../models/data-model';
import { LeadsService } from './../../services/leads.service';
import { TaskHistory } from '../../models/task-history';
import { GridOptions} from "ag-grid/main";
import { AddressComponent } from '../address/address.component';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import * as moment from 'moment';
import { ApplicationComponent } from '../application.component';
declare var $: any;
@Component({
  selector: 'editcontact',
  templateUrl: './editcontact.component.html',
  styleUrls: ['./editcontact.component.css']
})
export class EditcontactComponent implements OnInit {
  currentuser: any;
  notedescription: string;
  userprevilages: any;
  accid: number;
  customercontacttypelist: Dropdowntype[];
  Years: any[];
  MedSchoolYears: any[];
  ResidencyYears: any[];
  FellowShipYears: any[];
  accountform: FormGroup;
  contactform: FormGroup;
  notes: Notes = new Notes();
  datePipe: DatePipe = new DatePipe("en-US");
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  public getRowHeight;
  public zipmask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public dobmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  account: Accounts = new Accounts();
  contacts: Contacts = new Contacts();
  AddressSelection: string = '';
  customer: Customers = new Customers();
  accountlocations: Accountlocations = new Accountlocations();
  accountslist: Array<Accounts> = [];
  contactslist: Array<Contacts> = [];
  noteslist: Array<Notes> = [];
  public practiceid: number = 0;
  @Input()
  accountId: number;
  @Input()
  contactid: number;
  @Input()
  type: string;
  OwnerList: Dropdowntype[] = [];
  statisticlist: Array<Statistics> = [];
  SuffixList: Dropdowntype[] = [];
  SexList: Dropdowntype[] = [];
  TitleList: Dropdowntype[] = [];
  contacttypelist: Dropdowntype[] = [];
  Specialitytypelist: Dropdowntype[] = [];
  Collegelist: Dropdowntype[] = [];
  MedicalSchoolList: Dropdowntype[] = [];
  StatesList: Dropdowntype[] = [];
  CitiesList: Dropdowntype[] = [];
  PractiseList: Dropdowntype[] = [];
  Statuslist: Dropdowntype[] = [];
  itemResource: any;
  ContactStaffitemResource: any;
  items = [];
  itemCount = 0;
  staffitems = [];
  staffitemCount = 0;
  locationId = 0;
  locId = 0;
  params: any;
  ContactStaffparams: any;
  showmapWarning: boolean = false;
  showDialog = false;
  locationCount: number = 2;
  MonthList: Dropdowntype[] = [];
  DayList: Dropdowntype[] = [];
  public providerId: number = 0;
  activitieslist: Array<TaskHistory> = [];
  documentlist: Array<Document> = [];
  ticketslist: Array<Tickets> = [];
  emailslist: Array<Emails> = [];
  projectlist: Array<Project> = [];
  FeeAmountdiv: boolean = false;
  hidDiv: boolean = true;
  notesshowDialog = false;
  allnotes: Notes[];
  public staticsticsId: number = 1;
  public stateInfo = [];
  public rows: Array<Notes> = [];
  public columns: Array<any> = [
    { title: 'Date', name: 'created' },
    { title: 'Type', name: 'type' },
    { title: 'Note Description', name: 'notedescription' }
  ];
  Ids = [];
  public page: number = 1;
  public itemsPerPage: number = 10;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;
  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered']
  };
  stateTextBox: boolean = true;
  stateDropDown: boolean = false;
  displayZipCode: boolean = true;
  displayCountryName: boolean = false;
  seletedcountry: any;
  countryId: number = 1;
  public elementRef;
  textareaLength: number = 15;
  addresses: Accountlocations[];
  multiplelocaddresses: any;
  AddressList = [];
  public gridOptions: GridOptions;
  public columnDefs: any[];
  heroForm: FormGroup;
  nameChangeLog: string[] = [];
  allContactsproviders: Contacts[];
  allContactstaffs: Contacts[];
  public context;
  public frameworkComponents; public columnDefsnotes: any[];
  public gridOptionsnotes: GridOptions;
  today = Date.now();
  public AccountorCustomertype;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  titleCasePipe: TitleCasePipe = new TitleCasePipe();
  public searchControl: FormControl;
  public lattitude: number;
  public longitude: number;
  public address1: string;
  public zoom: number;
  @ViewChild("search")
  @ViewChild(AddressComponent) adddata;
  AccountIdArr: Dropdowntype[] = [];
  contactIdArr: Dropdowntype[] = [];
  public searchElementRef: ElementRef;
  NewForm = new FormGroup({});
  Fields: any;
  FormDataModified: boolean = false;
  DiscardFormDataConfirmation: boolean = false;

  constructor(private appComponent: ApplicationComponent,private ngZone: NgZone, private fb: FormBuilder, private http: HttpClient, private heroService: HeroService,
    private accountsService: AccountsService, private route: ActivatedRoute, private customerService: CustomerService,
    private toastr: ToastrService, private viewContainerRef: ViewContainerRef, private location: Location, private router: Router,
    myElement: ElementRef, private leadservice: LeadsService, private opprtunityservice: OpprtunitiesService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.account.customertypeid = 0;
    this.account.country = "1";
    this.account.accounttype = null;
    this.contacts.isaddressadded = null;
    this.account.ownerid = null;
    this.account.state = null;
    this.account.city = null;
    this.account.multiplelocations = 0;
    this.account.speciality = null;
    this.account.ownership = null;
    this.account.rcm = null;
    this.account.ehr = null;
    this.account.pm = null;
    this.account.ownerid = this.currentuser.id;
    this.ResetContact();
    this.elementRef = myElement; 

  }
  ngOnInit() {
    this.contacts.STATUS = 309;
    this.dropdownvalues();
    this.getContactForm();
    
    if (this.contactid > 0) {
      this.Loadcontactdata(this.contactid);
      var index = 1;
      this.customerService.GetByAccountId(this.accountId).then(result => {
        this.account = result;
        this.AddressList.push({ "id": result.id + "~AccountAdd", "itemName": "Location (" + index + ") :- " + ((result.address1 != null) ? result.address1 : '') + ", " + ((result.address2 != null) ? result.address2 : '') + ", " + ((result.city != null) ? result.city : '') + ", " + ((result.state !=null)?result.state:''), "Category": 'Account Address' });
        this.customerService.GetLocationByAccountId(this.accountId).then(result => {
          this.multiplelocaddresses = result;
          
          result.forEach(ele => {
            if (ele.address1 !== null && ele.address1 !== '') {
              index++;
              this.AddressList.push({ "id": ele.id + "~AdditionalLoc", "itemName": "Location (" + index + ") :- " + ((ele.address1 != null) ? ele.address1 : '') + ", " + ((ele.address2 != null) ? ele.address2 : '') + ", " + ((ele.city != null) ? ele.city : '') + ", " + ((ele.state != null) ? ele.state : ''), "Category": 'Additional Loaction' });
              
            }

          });

        });
      });
    }
    else {
      this.customerService.GetByAccountId(this.accountId).then(result => {
        this.account = result;
        this.contacts.ownerid = this.account.ownerid;
        this.contacts.address1 = result.address1;
        this.contacts.address2 = result.address2;
        if (result.country !== 'Bahamas') {
          this.contacts.city = result.city;
        }
        this.contacts.state = result.state;
        this.contacts.zip = result.zip;
        this.customerService.GetPickListData("Account (Primary) Country", 12, this.currentuser.id).then((accounttype) => {
          this.contacts.country = accounttype.filter(x => x.id == parseInt(result.country))[0].itemName;
        });
        this.contacts.ProvidenceIsland = result.ProvidenceIsland;
        this.contacts.isaddressadded = true;
        if (result.phone != null) {
          this.contacts.office = result.phone;
        }
        
        var index = 1;
        this.AddressList.push({ "id": result.id + "~AccountAdd", "itemName": "Location (" + index + ") :- " + ((result.address1 != null) ? result.address1 : '') + ", " + ((result.address2 != null) ? result.address2 : '') + ", " + ((result.city != null) ? result.city : '') + ", " + ((result.state != null) ? result.state : ''), "Category": 'Account Address' });
        this.customerService.GetLocationByAccountId(this.accountId).then(result1 => {
          this.multiplelocaddresses = result1;
          result1.forEach(ele => {
            if (ele.address1 !== null && ele.address1 !== '') {
              index++;
              this.AddressList.push({ "id": ele.id + "~AdditionalLoc", "itemName": "Location (" + index + ") :- " + ((ele.address1 != null) ? ele.address1 : '') + ", " + ((ele.address2 != null) ? ele.address2 : '') + ", " + ((ele.city != null) ? ele.city : '') + ", " + ((ele.state != null) ? ele.state : ''), "Category": 'Additional Loaction' });
            }

          });
          this.AddressSelection = result.id + "~AccountAdd";
        });
      });
    }

    this.customerService.GetFormConfiguration(this.currentuser.id, 'Contacts').then(res => {
      if (res != null && res.length > 0) {
        let obj: string[] = [res[0].OrderFields, 'Contacts'];
        this.customerService.getSaveFormFields(obj).then(result => {
          result.forEach(ele => {
             if (ele['ColumnName'] == 'address1') {
              ele['Data'] = this.AddressList;
              
            }  else if (ele['ColumnName'] == 'ownerid') {
              ele['Data'] = this.OwnerList;
            } else {
               if (ele.DataType == "Pick List") {
                 var FieldId = ele['id'];
                 var FieldName = ele['FieldName'];
                 if (ele['ColumnName'] == 'speciality' || ele['ColumnName'] == 'subspeciality') {
                   FieldId = 21;
                   FieldName = 'Account Specialty';
                 } else if (ele['ColumnName'] == 'fellowship' || ele['ColumnName'] == 'residency') {
                   FieldId = 101;
                   FieldName = 'Contact Medical School';
                 } else if (ele['ColumnName'] == 'SaasTerm') {
                   FieldId = 119;
                   FieldName = 'SaasTerm';
                 } else {
                   FieldId = ele['id'];
                   FieldName = ele['FieldName'];
                 }
                //this.customerService.GetSpecialityList(ele['FieldName'], this.currentuser.id).then((CustomerType) => {
                 this.customerService.GetPickListData(FieldName, FieldId, this.currentuser.id).then((accounttype) => {                  
                     var data = this.PickListSortByType(ele['FieldDataOrderBy'], accounttype)
                     ele['Data'] = data;                
                });
              }
              
            }

          });
          this.Fields = result;
          this.Fields.forEach(x => {
            this.NewForm.addControl(x.ColumnName, new FormControl(''));          
            if (this.contacts.country == 'Bahamas' && (x.ColumnName == 'state' || x.ColumnName =='zip')) {
              x.RequiredField = 'No';
            }
          });
          this.NewForm.addControl('AddLocationId', new FormControl(this.contacts.AddLocationId));
          let selfObj = this;
          setTimeout(function () {           
            if (selfObj.contacts.country == 'Bahamas') {
              $("#zip").attr("disabled", "disabled");
              $("#state").attr("disabled", "disabled");
              $("#city").attr("disabled", "disabled");
            }            
          }, 100);
        });
      }      
    });   
  }

  public onAccStatusClick() {
    if (this.currentuser.customerStatus == 0) {
      alert("You don't have access to Change Account Status. If you want to Access, please change it from User Configuration ")

    }
  }
  Loadcontactdata(contactid) {   
    this.customerService.GetContactByIdNew(contactid).then(result => {
      this.contacts = JSON.parse(result);
      this.contacts.collegegrandyear = moment(this.contacts.collegegrandyear).format("YYYY");
      this.contacts.medschoolgrandyear = moment(this.contacts.medschoolgrandyear).format("YYYY");
      this.contacts.fellowshipgrandyear = moment(this.contacts.fellowshipgrandyear).format("YYYY");
      this.contacts.residencygrandyear = moment(this.contacts.residencygrandyear).format("YYYY");
      this.contacts.SassDate = this.contacts.SassDate == "0001-01-01T00:00:00" ? null : this.datePipe.transform(this.contacts.SassDate, 'yyyy-MM-dd');
      this.contacts.SaaSRenewal = this.contacts.SaaSRenewal =="0001-01-01T00:00:00"?null: this.datePipe.transform(this.contacts.SaaSRenewal, 'yyyy-MM-dd');
      this.contacts.SaaSRCM = this.contacts.SaaSRCM == "0001-01-01T00:00:00" ? null : this.datePipe.transform(this.contacts.SaaSRCM, 'yyyy-MM-dd'); 
      this.accountId = this.contacts.accountid;
      this.loadcontacttitles(this.contacts.contacttype);
      this.contacts.isaddressadded = true;
      this.contacts.ownerid = this.account.ownerid;
      this.NewForm.addControl('AddLocationId', new FormControl(this.contacts.AddLocationId));

      if (this.contacts.AddLocationId == 0) {
        this.AddressSelection = this.contacts.accountid + "~AccountAdd";
        let SO = this;
        setTimeout(function () {
          SO.AddressSelected(SO.AddressSelection);
        }, 100);
      } else {
        this.AddressSelection = this.contacts.AddLocationId + "~AdditionalLoc";
        let SO = this;
        setTimeout(function () {
          SO.AddressSelected(SO.AddressSelection);
        }, 100);
      }
    });
  }
  handleClick(event) {
    var clickedComponent = event.target;
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {

      $('#test').val('Account');
    } else {

    }
  }

  dropdownvalues() {
    $('#zip').attr('disabled', true);
    this.customerService.GetLoggedinUserList(this.currentuser.id, this.currentuser.systemadministrator).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.OwnerList = Owner;
    });
    this.getyears()
    {
      var range = [];
      var y = 1949;
      var year = new Date().getFullYear();
      for (var i = 0; y <= year; i++) {
        y = y + 1;
        range.push({
          label: y,
          value: y
        });
      }
      this.Years = range;
      this.MedSchoolYears= range;
      this.ResidencyYears= range;
      this.FellowShipYears= range;
    }

  }
  countryState(id, state, type) {
    this.stateTextBox = true;
    this.stateDropDown = true;
    var $stateDropdownDiv = $('#stateDropDown').parent().parent();

    if (id == 1) {

      $('#citySateZipSpan').html('City/State/Zip');
      $('#citySateZipforContacts').html('City/State/Zip');
      $('#contactCityDiv').css('display', 'block');
      this.getSelectedState('all');
      this.stateTextBox = true;
      this.stateDropDown = false;
      this.displayZipCode = true;
      this.displayCountryName = false;
      $stateDropdownDiv.removeClass('col-xs-6');
      $stateDropdownDiv.addClass('col-xs-3');
      $stateDropdownDiv.prop("style", "padding-right:0px");
      $('#stateDropDown').prop("placeholder", "Select State");   
      if (this.seletedcountry !== null && this.seletedcountry != undefined && this.seletedcountry == 1) {
        if (state != undefined || state != null)
          $('#stateDropDown').val(state);
      }
      else
        $('#stateDropDown').val('');
      this.countryId == 1;
    }
    else if (id == 2) {
      $('#citySateZipSpan').html('City/State/Zip');

      $('#citySateZipforContacts').html('City/State/Zip');

      this.getSelectedState('bahamas');
      this.stateTextBox = true;
      this.stateDropDown = false;
      this.displayZipCode = false;
      this.displayCountryName = true;
      $stateDropdownDiv.removeClass('col-xs-3');
      $stateDropdownDiv.addClass('col-xs-6');
      $stateDropdownDiv.prop("style", "padding:0px");
      $('#stateDropDown').prop("placeholder", "Select Island");
      if (this.seletedcountry !== null && this.seletedcountry != undefined && this.seletedcountry == 2) {
        if (state != undefined || state != null)
          $('#stateDropDown').val(state);
      }
      else
        $('#stateDropDown').val('');
      $('#displayCountry').val('Bahamas');
      this.countryId == 2;
    }
    else {
      
      $('#stateTextBox').prop("placeholder", "Select State");
      this.stateTextBox = false;
      this.stateDropDown = true;
    }
  }

  editRowAg(id, type): boolean {
    var response = false;
    if (type == 'Contacts' || type == 'Contact') {
    
      this.customerService.Getcontacttypes(2).then((accounttype) => {
        this.contacttypelist = [];
        accounttype.forEach(accounttype => {
        });
        this.contacttypelist = accounttype;
      });

      this.ResetContact();
     
      this.customerService.GetContactByIdNew(id).then(result => {
        this.contacts = JSON.parse(result);
        this.contacts.collegegrandyear = moment(this.contacts.collegegrandyear).format("YYYY");
        this.contacts.medschoolgrandyear = moment(this.contacts.medschoolgrandyear).format("YYYY");
        this.contacts.fellowshipgrandyear = moment(this.contacts.fellowshipgrandyear).format("YYYY");
        this.contacts.residencygrandyear = moment(this.contacts.residencygrandyear).format("YYYY");
        this.adddata.loadaddress(this.contacts);
        this.customerService.GetByAccountId(this.contacts.accountid).then(result => {
          this.account = result;
          if (result.country == '1') {
            this.countryState(1, this.account.state, null);
          }
          else {
            this.countryState(2, this.account.state, null);

          }
        });
       
        this.loadcontacttitles(this.contacts.contacttype).then(() => {
          if (this.contacts.contacttype == "" || this.contacts.contacttype == 'PT' || this.contacts.contacttype == 'PA') {
            $('#title').removeClass('required');
            $('#title').removeClass('not-required');
            $('#titlespan').attr('style','display:block;')
            $('#title').addClass('required');
          } else {
            $('#title').removeClass('required');
            $('#title').removeClass('not-required');
            $('#titlespan').attr('style', 'display:none;')
          }
          this.changeCityStateZip(this.contacts.country, this.contacts.state);
          response = true;
        });

      });
    } else {
      this.opprtunityservice.GetByAccountId(id).then(result => {
        this.account = result;
        this.address1 = result.address1;
        this.adddata.loadaddress(this.account);
        if (result.country == '1') {
          this.countryState(1, this.account.state, null);
        }
        else {
          this.countryState(2, this.account.state, null);

        }
        this.contacts.speciality = this.account.speciality;
      });
    }
    return response;
  }
  ResetContact() {
    this.contacts = new Contacts();
    this.contacts.contacttype = null;
    this.contacts.suffix = null;
    this.contacts.sex = null;
    this.contacts.title = null;
    this.contacts.speciality = null;
    this.contacts.subspeciality = null;
    this.contacts.college = null;
    this.contacts.collegegrandyear = null;
    this.contacts.medschool = null;
    this.contacts.medschoolgrandyear = null;
    this.contacts.residency = null;
    this.contacts.residencygrandyear = null;
    this.contacts.fellowship = null;
    this.contacts.fellowshipgrandyear = null;
    this.contacts.ownerid = null;
    this.contacts.firstname = null;
    this.contacts.lastname = null;
    this.contacts.practiceid = 0;
    $('#title').removeClass('not-required')
    $('#test').val('');
  }  
  getContactForm(): FormGroup {
    return new FormGroup({
      'contacttype': new FormControl(this.contacts.contacttype, Validators.required),
      'firstname': new FormControl(this.contacts.firstname, Validators.required),
      'lastname': new FormControl(this.contacts.lastname, Validators.required),
      'title': new FormControl(this.contacts.title, Validators.required),
      'state': new FormControl(this.contacts.state, Validators.required),
      'city': new FormControl(this.contacts.city, Validators.nullValidator), 
      'zip': new FormControl(this.contacts.zip, Validators.required),
      'ownerid': new FormControl(this.contacts.ownerid, Validators.required),
      'middlename': new FormControl(this.contacts.middlename, Validators.nullValidator),
      'suffix': new FormControl(this.contacts.suffix, Validators.nullValidator),
      'sex': new FormControl(this.contacts.sex, Validators.nullValidator),
      'speciality': new FormControl(this.contacts.speciality, Validators.nullValidator),
      'subspeciality': new FormControl(this.contacts.subspeciality, Validators.nullValidator),
      //'email': new FormControl(this.contacts.email, Validators.email),
      'address1': new FormControl(this.contacts.address1, Validators.nullValidator),
      'address2': new FormControl(this.contacts.address2, Validators.nullValidator),
      'cell': new FormControl(this.contacts.cell, Validators.nullValidator),
      'office': new FormControl(this.contacts.office, Validators.nullValidator),
      'isaddressadded': new FormControl(this.contacts.isaddressadded, Validators.requiredTrue),
      'homeaddress': new FormControl(this.contacts.homeaddress, Validators.nullValidator),
      'homestate': new FormControl(this.contacts.homestate, Validators.nullValidator),
      'homecity': new FormControl(this.contacts.homecity, Validators.nullValidator),
      'homezip': new FormControl(this.contacts.homezip, Validators.nullValidator),
      'home': new FormControl(this.contacts.home, Validators.nullValidator),
      'college': new FormControl(this.contacts.college, Validators.nullValidator),
      'medschool': new FormControl(this.contacts.medschool, Validators.nullValidator),
      'residency': new FormControl(this.contacts.residency, Validators.nullValidator),
      'fellowship': new FormControl(this.contacts.fellowship, Validators.nullValidator),
      'collegegrandyear': new FormControl(this.contacts.collegegrandyear, Validators.nullValidator),
      'medschoolgrandyear': new FormControl(this.contacts.medschoolgrandyear, Validators.nullValidator),
      'residencygrandyear': new FormControl(this.contacts.residencygrandyear, Validators.nullValidator),
      'fellowshipgrandyear': new FormControl(this.contacts.fellowshipgrandyear, Validators.nullValidator),
      'facebook': new FormControl(this.contacts.facebook, Validators.nullValidator),
      'instagram': new FormControl(this.contacts.instagram, Validators.nullValidator),
      'linkedin': new FormControl(this.contacts.linkedin, Validators.nullValidator),
      'twitter': new FormControl(this.contacts.twitter, Validators.nullValidator),
    });
  }
  collegeyear(val) {
    var range = [];
    var y = Number(val);
    var year = new Date().getFullYear();
    for (var i = 0; y <= year; i++) {
      y = y + 1;
      range.push({
        label: y,
        value: y
      });
    }
    this.MedSchoolYears = range;
    this.ResidencyYears = range;
    this.FellowShipYears = range;
  }
 
  onChange(stateid) {
    this.customerService.GetAllCitiesList(stateid).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.CitiesList = accounttype;
    });
  } 
  SaveContact() {
    if (this.contacts.isaddressadded) {
      var titleContain = 1;
      if ($("#title").hasClass("required") || $('#title').hasClass('not-required')) {
        if (this.contacts.title == null) {
          titleContain = 0;
        }
      } else {
        titleContain = 1;
      }
      if (titleContain == 1) {
        this.contacts.practiceid = this.practiceid;
        this.contacts.userid = this.currentuser.id;
        this.contacts.isshared = this.account.isshared;
        this.contacts.accountid = this.accountId;
        if (this.contacts.contacttype == "Physician (MD)") {
          this.contacts.title = 'MD';
        } else if (this.contacts.contacttype == "Physician (DO)") {
          this.contacts.title = 'DO';
        } else if (this.contacts.contacttype == "Podiatrist (DPM)") {
          this.contacts.title = 'DPM';
        }

        if (this.contacts.id == 0 || this.contacts.id == undefined) {
          this.NewForm.addControl('userid', new FormControl(this.currentuser.id));
          this.NewForm.addControl('created', new FormControl(this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')));
        }
        this.NewForm.addControl('accountid', new FormControl(this.contacts.accountid));
        this.NewForm.controls['address1'].setValue(this.contacts.address1);
        this.NewForm.controls['AddLocationId'].setValue(this.contacts.AddLocationId);
        
        this.contacts.fieldvalues = JSON.stringify(this.NewForm?.value);
       
        this.contacts.ownerid = this.account.ownerid;
        this.contacts.isshared = this.account.isshared;
        this.accountsService.addcontactNew(this.contacts).then(result => {
          this.contacts.id = result.id;
          $('#test').val('');
          this.contacts.userid = this.currentuser.id;
          this.contacts = new Contacts();
          this.contacts.STATUS = 309;
          this.sendMsgEvent.emit("contact Added");
        });

      }
    } else
    {
      this.showmapWarning = true;
      this.showmapWarningDialogCss();
    }

  }

  showmapWarningDialogCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width:30%;padding-bottom: 0px;border:4px solid rgb(49,189,240);border-radius:5%;min-height: 30%;overflow: visible;height: 43%;top: 32%;left: 56%;');
    }, 100);
  }
  changeCityStateZip(id, state) {

    var $stateDropdownDiv = $('#contactstateTextBox').parent();
    if (id == 1) {
      $('#citySateZipforContacts').html('City/State/Zip');
      this.getSelectedState('all');
      this.stateTextBox = true;
      this.stateDropDown = false;
      this.displayZipCode = true;
      this.displayCountryName = false;
      $stateDropdownDiv.removeClass('col-xs-6');
      $stateDropdownDiv.addClass('col-xs-3');
      $stateDropdownDiv.prop("style", "padding-right:0px");
      $('#contactstateTextBox').prop("placeholder", "Select State");
      $('#contactstateTextBox').val(state);
   

      this.countryId == 1;
    }
    else if (id == 2) {
      $('#citySateZipforContacts').html('City/State/Zip');
      this.getSelectedState('bahamas');
      this.stateTextBox = true;
      this.stateDropDown = false;
      this.displayZipCode = false;
      this.displayCountryName = true; 
      $('#contactstateTextBox').prop("placeholder", "Select Island");
      $('#contactstateTextBox').val(state); 
      $('#contactsDisplayCountry').val('Bahamas');
      this.countryId == 2;
    }
  }
  getSelectedState(country) {
    this.customerService.GetAllStateList(country).then((state) => {
      state.forEach(accounttype => {
      });
      this.StatesList = state;
      this.stateInfo = state;

    });
  }
  statePopulate(zipid, cityid, stateid, stateDDN) {
    var that = this;
    var zipCode = $('#' + zipid).val();
    var newStr = zipCode.replace(/_/g, "");
    var newStrArray = newStr.split("-");
    if (newStrArray[0].length > 4) {
      var url = "http://ziptasticapi.com/" + newStrArray[0];
      $.ajax({
        url: url,
        dataType: "json",
        type: 'GET',
        success: function (data) {
          $('#' + cityid).val(data.city);
          that.loadstate(data.state, stateid, stateDDN);
          if (zipid == 'contactzip') {
            that.contacts.city = data.city;

          }
          if (zipid == 'contacthomezip') {
            that.contacts.homecity = data.city;

          }
          else {
            that.account.city = data.city;

          }
        }

      });
    }
  } 
  
  loadstate(state, stateid, stateDDN) {
    $('.selectedState').removeClass('selectedState');
    var countryCode = ($('#country').val());
    if (countryCode == 1 && stateDDN == 'stateDropDown') {
      for (var i = 0; i < 50; i++) {
        if (this.StatesList[i].itemName.trim().toString() == state) {
          $('#' + stateDDN).val(state)
          $('#' + this.StatesList[i].id).addClass('selectedState');
          this.account.state = state;
        }
      }
    } else {
      $('#' + stateid).val(state);

      if (stateid == 'contactstateTextBox') {
        this.contacts.state = state;

      }
      if (stateid == 'contacthomestate') {
        this.contacts.homestate = state;
      }
      else {
        this.account.state = state;

      }

    }
  } 
  GetStateByID(stateId) {
    if (/^\d+$/.test(stateId)) {
      for (var i = 0; i < 50; i++) {
        if (this.StatesList[i].id.toString() == stateId) {
          this.contacts.state = this.StatesList[i].itemName;
        }
      }
    }
    else {
      this.contacts.state = stateId;
    }


  }  
  getyears() {
    var range = [];
    var year = new Date().getFullYear();
    for (var i = 0; i < 7; i++) {
      range.push({
        label: year + i,
        value: parseInt(String(year + i).slice(2, 4))
      });
    }

    this.Years = range;
    this.MedSchoolYears = range;
    this.ResidencyYears = range;
    this.FellowShipYears = range;
  }
  showStateList() {
    document.getElementById('stateList').style.display = 'block';

  }
  hideStateList() {
    document.getElementById('stateList').style.display = 'none';
  }  
  contactsShowStateList() {

    document.getElementById('contactsStateList').style.display = 'block';
  }
  contactsHideStateList() {

    document.getElementById('contactsStateList').style.display = 'none';
  }
  closeeditaccount() {
    this.sendMsgEvent.emit("Close");
  }
  selectState(id, name) {
    $('.selectedState').removeClass('selectedState');
    $('#' + id).addClass('selectedState');
    $('#stateDropDown').val(name);
    this.account.state = name;
  } 
  contactsSelectState(id, name) {
    $('.selectedState').removeClass('selectedState');
    $('#cont_' + id).addClass('selectedState');
    $('#contactstateTextBox').val(name);
    this.contacts.state = name;
  } 
  IsButtonAccess(btn, type) {
    this.toastr.clear();
    if (this.userprevilages != undefined) {
      for (var j = 0; j < this.userprevilages.length; j++) {
        if (btn == this.userprevilages[j].resource) {
          if (type == "add" && this.userprevilages[j].canadd == false) {
            this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }
          if (type == "edit" && this.userprevilages[j].canedit == false) {
            this.toastr.warning("You Do Not have Access to edit – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }
        }

      }
    }

  } 
  loadcontacttitles(val): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      this.customerService.GetSpecialityList("Contact Title", this.currentuser.id).then((title) => {
       
        this.TitleList = title;
      });
     
    });
    return promise;
  }
  special_char(event) {

    var k = event.charCode;
    return (!(k === 124));
  } 
  titleSelected() {
    if ($('#title').hasClass('not-required')) {
      $('#title').removeClass('not-required')
      $('#title').addClass('required')
    }
  }
  collegeYear1() {
    $('#collegegrandyear').removeAttr('disabled');
  }
  medschoolYear1() {
    $('#medschoolgrandyear').removeAttr('disabled');
  }
  residencyYear1() {
    $('#residencygrandyear').removeAttr('disabled');
  }
  fellowshipYear1() {
    $('#fellowshipgrandyear').removeAttr('disabled');
  }
  getWindowHeight() { 
    return ((window.innerHeight * 72) / 100) + "px";
  }
  SaveAccount() {

    if (this.account.isaddressadded) {
      this.route.params.subscribe(params => {
        this.account.userid = this.currentuser.id;

        this.accountsService.addaccount(this.account).then(result => {
          this.account = result;
          this.sendMsgEvent.emit("Added");
        });
      });
    } else {
      this.showmapWarning = true;
      this.showmapWarningDialogCss();
    }

  }
  SaveCustomeAddress() {
    this.account.isaddressadded = true;
    this.contacts.isaddressadded = true;
    this.showmapWarning = false;
  }
  resetAccountNumber() {
    if (this.account.id != 0) {

      if ($('#customertypeid').val() != 0) {
        if (this.account.accnumber == '' || this.account.accnumber == '0') {
          $('#accnumber').val('');
          this.account.accnumber = null;
        }
      } else {
        if (this.account.accnumber == '' || this.account.accnumber == '0' || this.account.accnumber == null) {
          $('#accnumber').val('0');
          this.account.accnumber = '0';
        }

      }
    }
  }
  Island_Countryval(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  accountNumber() {
    if ($('accnumber').val() == '') {
      if ($('#customertypeid').val() == 0) {
        this.account.accnumber = '0';
      } else {
        this.account.accnumber = null;
      }
    }
  }
  public transform(input: string): string {
   
    if (!input) {
      return '';
    }
    else {
      return input[0].toUpperCase() + input.substr(1);
    }
  }

  AddressReceived(msg: Accounts) {
    if (this.type == "Customer") {
      this.account.address1 = msg.address1;
      this.account.address2 = msg.address2;
      this.account.city = msg.city;
      this.account.state = msg.state;
      this.account.ProvidenceIsland = msg.ProvidenceIsland;
      this.account.zip = msg.zip;
      this.account.lattitude = msg.lattitude;
      this.account.longtitude = msg.longtitude;
      this.account.isaddressadded = msg.isaddressadded;
    } else
    {
      this.contacts.address1 = msg.address1;
      this.contacts.address2 = msg.address2;
      this.contacts.city = msg.city;
      this.contacts.state = msg.state;
      this.contacts.zip = msg.zip;
      this.contacts.ProvidenceIsland = msg.ProvidenceIsland;
      this.contacts.lattitude = msg.lattitude;
      this.contacts.longtitude = msg.longtitude.toString();
      this.contacts.isaddressadded = msg.isaddressadded;
    }

  }

  cancelContact() {
    this.sendMsgEvent.emit("contact Added");
  }
  AddressSelected(event) {
    if (event.split("~")[1] == 'AccountAdd') {
      this.contacts.address1 = this.account.address1;
      this.contacts.address2 = this.account.address2;
      if (this.account.country !== 'Bahamas') {
        this.contacts.city = this.account.city;
      } else {
        this.contacts.city = "";
       
        
      }
      
      this.contacts.state = this.account.state;
      this.contacts.zip = this.account.zip;
       this.customerService.GetPickListData("Account (Primary) Country", 12, this.currentuser.id).then((accounttype) => {
        this.contacts.country = accounttype.filter(x => x.id == parseInt(this.account.country))[0].itemName;
      });
      this.contacts.lattitude = this.account.lattitude;
      this.contacts.longtitude = this.account.longtitude.toString();
      if (this.account.phone != null) {
        this.contacts.office = this.account.phone;
      }
      this.contacts.ProvidenceIsland = this.account.ProvidenceIsland;
      this.contacts.isaddressadded = true;
      this.contacts.AddLocationId = 0;
      this.NewForm.addControl('AddLocationId', new FormControl(this.contacts.AddLocationId));
    } else {
      var msg = this.multiplelocaddresses.filter(p => p.id == event.split("~")[0])[0];
      this.contacts.address1 = msg.address1;
      this.contacts.address2 = msg.address2;
      if (msg.country !== 'Bahamas') {
        this.contacts.city = msg.city;
      } else {
        this.contacts.city = "";
      }
      this.contacts.state = msg.state;
      this.contacts.zip = msg.zip;
      this.contacts.country = msg.country;
      this.contacts.ProvidenceIsland = msg.ProvidenceIsland;
      this.contacts.lattitude = msg.lattitude;
      this.contacts.longtitude = msg.longtitude;
      this.contacts.office = msg.phone;
      this.contacts.isaddressadded = true;
      this.contacts.AddLocationId = msg.id;
      this.NewForm.addControl('AddLocationId', new FormControl(this.contacts.AddLocationId));
    }
    
    
  }

  PickListSortByType(value, data) {
    var DuplicateFiledDataList = [];
    if (value == 'SortOrder') {
      DuplicateFiledDataList = data.sort((a, b) => a.Sortorder - b.Sortorder);
      return DuplicateFiledDataList;
    } else if (value == 'FieldName') {
      DuplicateFiledDataList = data.sort((a, b) => a.itemName.localeCompare(b.itemName));
      return DuplicateFiledDataList;
    }
  }

  FormDataEntered(val) {
    this.FormDataModified = true;
  }

  DiscardFormDataConfirmationCss() {
    this.DiscardFormDataConfirmation = true;
    this.appComponent.salesxcrmDialog('#DiscardFormDataConfirmation .dialog');
 
  }

  addFiles(event) {
  
      let files = event.srcElement.files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        this.addChargeNew(file);
      }
  }

  addChargeNew(file) {
    let contact = new Contacts();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    
    let selfObj = this;
    $(setTimeout(function () {
      contact.id = selfObj.contactid;
      contact.phototype = file.type;     
      contact.photo = reader.result.toString();
      selfObj.contacts.fileName = file.name;
      selfObj.contacts.photo = reader.result.toString();
      selfObj.NewForm.controls['photo'].setValue(selfObj.contacts.photo);
    }, 500));
    

  }
}
