<input type="text" id="emailIds" style="display:none;" />
<div *ngIf="showContactInfo" id="Contactinformation" class="container" style=" padding:0;" [style.height.%]="tab=='QuickView'? 100: 100">
  <div class="col-xs-12" style="padding-bottom:0; height:100%;padding:0" id="ContactInformation1">

    <div *ngIf="!isTaskShow" class="col-xs-12" style="padding: 0%;background:#fff; overflow:hidden;height:100%" [style.width.%]="100">
      <div id="window" [ngClass]="(ShowRightInfoDiv)?((tab === 'QuickView')?((SixBoxExpand)?'col-xs-6' : 'col-xs-8') : 'col-xs-6') : 'col-xs-12'" [ngStyle]="{'font-size.px':tab === 'QuickView' ? 11 : 14 }" style="padding-left: 0;padding-right:0;height:98%">
        <div *ngIf="viewaccount!=null" class="col-xs-12 scroll-c" id="informationblock" style="padding-left: 0px; padding-right: 0px; display: block; overflow: auto; border-bottom: 1px solid rgb(199, 199, 199); height: 34.4%; font-size: 14px; width: 100%;">
          <account-details [viewaccount]="viewaccount" [account]="account" [tab]="tab" [noteslist]="noteslist" (btnClickEvent)="btnClickReceived($event)" [pageFrom]="'Contacts'" [ViewData]="'Account'" [contact]="'contact'"></account-details>
        </div>
        <div class="col-xs-12" id="mainDivleft" style="padding:0; height:64%;">         
          <div [ngClass]="(account.accountStatus == 15)?'col-xs-12  watermark':'col-xs-12 '" [ngStyle]="{'font-size.px':tab === 'QuickView' ? 12 : 13.5 }" id="detailedView" style="padding:0%;">
            <div style="padding:0 !important;" class="col-xs-12">
              <div class="col-xs-10" style="height:10px;padding:0px"><b><label id="quickviewcontactid" style="font-size: 9pt;">CONTACT VIEW ({{displaycontact.TotalContactsCount}})</label></b></div>

              <div class="col-xs-12" style="padding:0 !important;font-weight:800" id="contactDiv">
                <div class="col-xs-12 removeCompletePadding" [ngStyle]="{'font-size.pt':tab === 'QuickView' ? 9 : 10 }" style="height: 100%">
                  <div class="col-xs-12 removeCompletePadding" style="height: 5%">

                    <div class="col-xs-8 " style="padding:0 3%;text-align:center;" *ngIf="addEditInsideContact">
                      <span [ngClass]="(this.contactpermission == 'view')? 'btn-xs btn-default pull-right disabled' : 'btn-xs btn-default pull-right'" [ngStyle]="{'backgroundColor':(this.contactpermission == 'view') ? 'white' : 'white','color':(this.contactpermission == 'view') ? 'lightgrey' : 'black'}" *ngIf="this.allowAccess === true" (click)="editcontact(0)" style="padding:0 0 2% 0"><i class="fa fa-user" aria-hidden="true"></i></span>

                      <span [ngClass]="(this.contactpermission == 'view')? 'btn-xs btn-default pull-right disabled' : 'btn-xs btn-default pull-right'" [ngStyle]="{'backgroundColor':(this.contactpermission == 'view') ? 'white' : 'white','color':(this.contactpermission == 'view') ? 'lightgrey' : 'black'}" *ngIf="this.allowAccess === true" (click)="editcontact(displaycontact.id)"><i class="fa fa-pencil" aria-hidden="true"></i></span>

                    </div>

                  </div>
                  <div class="col-xs-12 removeCompletePadding" style="height: 15%">
                    <div [ngClass]="tab == 'QuickView' ? 'col-xs-4' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'40'" style="padding:0;">
                      <div style="margin: 4% 0 0 0;"  (click)="openfile(urlImage)">
                        <img [src]="urlImage" style="width: 40%; height: 40%" *ngIf="urlImage">
                        <label for="addProfileFiles" style="color: #ddd; width:60%; height: 50%; margin-bottom: 0; " *ngIf="!urlImage">
                          <img src="../../../assets/img/default contact photo.jpg" style="height:40%;width:40%" *ngIf="!urlImage" />
                        </label>
                      </div>
                    </div>
                    <div [ngClass]="tab == 'QuickView' ? 'col-xs-6' :'col-xs-4'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '50' :'40'">
                      <b style=" color: #00b3f7d9; font-size: 13pt">{{displaycontact.firstname}} {{displaycontact.middlename}} {{displaycontact.lastname}}<span *ngIf="displaycontact.title != null">,&nbsp;{{displaycontact.titleName}}</span></b>
                      <div>{{displaycontact.email}}</div>
                      <div>Office:{{displaycontact.office}}</div>
                      <div>Cell:{{displaycontact.cell}}</div>
                    </div>

                    <div style="padding: 0px;" class="col-xs-2">
                      <div class="col-xs-1" style="padding:0;width:25%">
                        <img *ngIf="(displaycontact.instagram!=null && displaycontact.instagram!='')" src="../../../assets/img/Instagram Color.png" title="Instagram" style="height:20px" (click)="OpenSocialMedia('https://www.instagram.com/',displaycontact.firstname+displaycontact.lastname)" />

                        <img *ngIf="(displaycontact.instagram==null || displaycontact.instagram=='')" src="../../../assets/img/Instagram Black.png" title="Instagram" style="height:20px" />
                      </div>


                      <div class="col-xs-1" style="padding: 0; width: 25%">
                        <img *ngIf="(displaycontact.facebook!=null && displaycontact.facebook!='')" src="../../../assets/img/Facebook Color.png" title="Facebook" style="height:20px" (click)="OpenSocialMedia('https://www.facebook.com/',displaycontact.firstname+displaycontact.lastname)" />
                        <img *ngIf="(displaycontact.facebook==null ||displaycontact.facebook=='')" title="Facebook" src="../../../assets/img/Facebook Black.png" style="height:20px" />
                      </div>
                      <div class="col-xs-1" style="padding: 0; width: 25%">
                        <img *ngIf="(displaycontact.twitter!=null && displaycontact.twitter!='')" src="../../../assets/img/Twitter Color.png" title="Twitter" style="height:20px" (click)="OpenSocialMedia('https://twitter.com/',displaycontact.firstname+displaycontact.lastname)" />
                        <img *ngIf="(displaycontact.twitter==null || displaycontact.twitter=='')" src="../../../assets/img/Twitter Black.png" title="Twitter" style="height:20px" />
                      </div>
                      <div class="col-xs-1" style="padding: 0; width: 25%">
                        <img *ngIf="(displaycontact.linkedin!=null && displaycontact.linkedin!='')" src="../../../assets/img/Linkedin Color.png" style="height:20px" (click)="OpenSocialMedia('https://www.linkedin.com/',displaycontact.firstname+displaycontact.lastname)" title="Linkedin" />
                        <img *ngIf="(displaycontact.linkedin==null || displaycontact.linkedin=='')" src="../../../assets/img/Linkedin Black.png" style="height:20px" title="Linkedin" />
                      </div>

                    </div>
                  </div>
                  <div class="col-xs-12 scroll-c" style="padding: 0%; height: 340px; overflow-y: scroll; ">
                    <div class="col-xs-2 removeCompletePadding" *ngIf="!photoForConatctView" style="float: right;">
                      <div class="col-xs-10 pull-right" style="padding:0 3%;text-align:right;">
                        <span [ngClass]="(this.contactpermission == 'view')? 'btn-xs btn-default disabled' : 'btn-xs btn-default '" [ngStyle]="{'backgroundColor':(this.contactpermission == 'view') ? 'white' : 'white','color':(this.contactpermission == 'view') ? 'lightgrey' : 'black'}" *ngIf="this.allowAccess === true" (click)="editcontact(displaycontact.id)">
                          <img title="Edit Contact" src="../../../../assets/img/Edit Contact.png" style="height:21px;float:right" />
                        </span>
                      </div>

                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-4' :'col-xs-4'" style="padding:0;font-weight:700" [style.width.%]="tab == 'QuickView' ? '30' :'37'">
                        <b style="font-weight:800">Contact Type:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.contacttypeName}}
                      </div>

                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800">Address1:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.address1}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800">Address2:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.address2}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <span><b style="font-weight:800">City/State/Zip/Country:</b></span>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        <span>{{displaycontact.city}}<b *ngIf="displaycontact.state">,</b>&nbsp;{{displaycontact.state | uppercase}}.&nbsp;{{displaycontact.zip | slice:0:5 }}<span *ngIf="displaycontact.zip && displaycontact.zip.length > 5">-</span>{{displaycontact.zip | slice:5:20 }},&nbsp;{{displaycontact.country}}</span>

                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <span><b style="font-weight:800">Island:</b></span>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        <span>{{displaycontact.ProvidenceIsland}}</span>

                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800">Specialty:</b>
                      </div>
                      <div *ngIf="displaycontact.speciality != 'null'" class="col-xs-7" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.specialityName}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800">Sub Specialty:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.subspecialityName}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800">Sex:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{(displaycontact.sex == 315)?'Male':(displaycontact.sex == 316)? 'Female' : ''}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800">DOB:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.DOB}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800"> College/Year:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.collegeName}}
                        <span *ngIf="displaycontact.collegegrandyear !== null  && displaycontact.collegegrandyear !== 'Invalid date'">
                          /{{displaycontact.collegegrandyear }}
                        </span>
                      </div>

                    </div>
                    <div *ngIf="displaycontact.medschool !== null" class="col-xs-12 removeCompletePadding ">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800">Med School/Year:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0 ;padding-left:0%" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.medschoolName}}
                        <span *ngIf="displaycontact.medschoolgrandyear !== null  && displaycontact.medschoolgrandyear !== 'Invalid date'">
                          / {{displaycontact.medschoolgrandyear }}
                        </span>
                      </div>

                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800">Residency/Year:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.residencyName}}
                        <span *ngIf="(displaycontact.residencygrandyear!=undefined && displaycontact.residencygrandyear !== 'Invalid date')"> /{{displaycontact.residencygrandyear}} </span>
                      </div>

                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800">Fellowship/Year:</b>
                      </div>
                      <div *ngIf="displaycontact.fellowship !== null" [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.fellowshipName}}<span *ngIf="displaycontact.fellowshipgrandyear!=null  && displaycontact.fellowshipgrandyear !== 'Invalid date'">
                          /{{displaycontact.fellowshipgrandyear}}
                        </span>
                      </div>

                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800">Home address:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.homeaddress}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" style="padding:0" [style.width.%]="tab == 'QuickView' ? '30' :'37'">
                        <b style="font-weight:800">City/State/Zip:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.homecity}}
                        <span *ngIf="displaycontact.homestate !== null">/{{displaycontact.homestate | uppercase}}</span>
                        <span *ngIf="displaycontact.homezip !== null">/{{displaycontact.homezip | slice:0:5}}<span *ngIf="displaycontact.homezip && displaycontact.homezip.length > 5">-{{displaycontact.homezip | slice:5:20}}</span></span>
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800"> Home #:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.home}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800"> FaceBook:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.facebook}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800">Instagram:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.instagram}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800"> Linkedin:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.linkedin}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800"> Twitter:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.twitter}}
                      </div>
                    </div>

                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800"> SaaS Date:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.SassDate | date:"MM-dd-yyyy"}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800"> SaaS RCM:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.SaaSRCM | date:"MM-dd-yyyy"}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800"> SasS Renewal:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.SaaSRenewal | date:"MM-dd-yyyy"}}
                      </div>
                    </div>
                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800"> SaaS Term:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.SaaStermName}}
                      </div>
                    </div>

                    <div class="col-xs-12 removeCompletePadding">
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '30' :'37'" style="padding:0;">
                        <b style="font-weight:800">Owner:</b>
                      </div>
                      <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                        {{displaycontact.owner}}
                      </div>
                    </div>
                  </div>
                </div>

              </div>


              <div *ngIf="documentsinfoBesideContactInfo" style="height:100%;overflow:hidden" class="col-xs-3 removeCompletePadding" id="documentDiv">
                <div class="col-xs-12 pull-right" style="padding:0 3%;text-align:right;" id="documentInsideContactView" [style.height.px]="documentInsideContactViewHeight">
                  <div class="col-xs-6 provider" style="text-align:left"> DOCUMENTS ({{userdocumentlist.length}})</div>

                  <span *ngIf="!ShowPDF" class="col-xs-6" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;padding:2px;" (click)="closeDocumentInsideContact()">
                    <b>
                      <img src="../../../assets/img/Black x.png" style="height:17px" />
                    </b>
                  </span>
                  <documentmanagement *ngIf="isDocumentsInfoDivVisible" [accountId]="this.contactid" [selectedtype]="'Contact'" [isUploadDivVisible]="true" [documentview]="documentview" (sendMsgEvent)="onDocumentSavedSaved($event)"></documentmanagement>
                </div>



              </div>


            </div>

            <!--<button class="btn saveButton" type="button" (click)="editcontact()" style="width:100%;margin-left:0;margin-top: 2%;">Edit</button>-->
          </div>


        </div>
      </div>


      <div *ngIf="uploadDocumentShow && !QuickViewDocumentsDialog" class="col-xs-6" style="padding:0px;height:100%">
        <upload-document [accountId]="this.contactid" [selectedtype]="'Contact'" (sendMsgEvent)="SavedDocuments($event)"> </upload-document>
      </div>

      <div id="rightInfoDiv" *ngIf="ShowRightInfoDiv" [ngClass]="(tab === 'QuickView')?'col-xs-4':(SixBoxExpand)?'col-xs-6' : 'col-xs-4'" style=" margin-top: 0%;padding: 0;padding-right: 0; margin-bottom:0; height: 100%" [style.display]="(tab !== 'Search') ? 'block' : 'none'">

        <div [style.visibility]="(tab !== 'Search') ? 'visible' : 'hidden'" class="col-xs-12 col-sm-12 col-md-12" id="otherDetails" style="padding-left: 0;padding-right: 1.75%;padding:0;border: 2px solid #ddd;margin-top: -2px; overflow:hidden;border-top:none;border-left:1px solid #ddd;height:100%">         
          <div class="col-xs-12" style="height: 6%; padding: 0; margin-top: 1%; background-color: rgb(40,62,74); color: white;">
          
            <div class="col-xs-2" style="height:100%;padding:1% 0 0 0;">
              <label *ngIf="Source=='Campaigns'" class="col-xs-12" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;padding:2px;float:right;z-index:1;height:100%;padding:9%;">
                <img src="../../../assets/img/Toggle.png" style="height:22px;float:left" (click)="ToggleClick()" />
              </label>
            </div>
            <div class="col-xs-8" style="height: 100%; background-color: rgb(40,62,74); color: white; text-align: center; padding: 3%">
              <label style="font-weight: normal !important;">ACCOUNT DETAILS</label>
            </div>
            <div class="col-xs-2" style="height:100%;padding:1% 0 0 0;" *ngIf="tab == 'QuickView'">
              <label class="col-xs-12" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;padding:2px;float:right;z-index:1;height:100%;padding:9%;">
                <img src="../../../assets/img/White x.png" style="height:25px" (click)="CloseTab(tab)" />
              </label>
            </div>
            <div class="col-xs-1" style="height:100%;padding:1% 0 0 0;" *ngIf="notesinfo || activitiesinfo || documentsinfo || emailsinfo || showticketsinfo || Proposalinfo|| Projectsinfo ">
              <span style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;float:right;z-index:1;padding:0 1% 0 0">
                <b> <img src="../../../assets/img/White x.png" style="height:25px" (click)="close()" /></b>
              </span>
            </div>
          </div>


          <div class="col-xs-12" id="otherDetailsTable" *ngIf="otherDetailsTable" style="height:93%;padding:0;">
            <div class="col-xs-12" id="mergeHGT" style="padding:0;overflow:hidden;height:20%">
              <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" (click)="expandactivities('ACTIVITIES')" [style.padding]="(tab === 'QuickView')? '7% 0 0 0 ' :'0 0.8% 0 0.8%'">
                <div class="col-xs-12" style="padding: 0 0 0 0.5%;height:100%" id="ticketDiv">
                  <div class="col-xs-12 provider" style="padding:0;" id="documentHeader" [style.height.%]="(tab === 'QuickView')? '25':'10'">
                    <div class="col-xs-11" style="padding:0" [style.text-align]="(tab === 'QuickView')? 'center':'left'" [style.width.%]="(tab === 'QuickView')? '100':'91'">
                      <span style="font-size:12px;padding: 0;" [style.text-align]="(tab === 'QuickView')? 'center':'left'"> ACTIVITIES ({{activitieslist.length}})</span>
                    </div>

                  </div>
                  <div *ngIf="tab === 'QuickView'" (click)="expandactivities('ACTIVITIES')" class="col-xs-12" style="  border-radius:3px;overflow:auto; margin-top:3px;padding:0 0 0 30%;height:80%;font-size:12px;">
                    <img *ngIf="activitieslist.length>0" src="../../../assets/img/Activities - QuickView Page Icon - Green.png" style="height:70%" />
                    <img *ngIf="activitieslist.length==0" src="../../../assets/img/Activities - QuickView Page Icon - Grey.png" style="height:70%" />
                  </div>

                </div>
              </div>
              <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView')? '7% 0 0 0' :'0 0.8% 0 0.8%'" (click)="expand('NOTES')">
                <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView')? 'center':'left'">
                  <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView')? '25':'10'">
                    <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView')? '100':'91'" [style.text-align]="(tab === 'QuickView')? 'center':'left'"> NOTES ({{noteslist.length}})</span>

                  </div>

                  <div *ngIf="tab === 'QuickView'" (click)="expand('NOTES')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%">
                    <img *ngIf="noteslist.length > 0" src="../../../assets/img/Notes - QuickView Page Icon - Green.png" style="height:70%" />
                    <img *ngIf="noteslist.length==0" src="../../../assets/img/Notes - QuickView Page Icon - Grey.png" style="height:70%" />
                  </div>

                </div>
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;overflow:hidden; background-color: #f5f5f54d !important;height:20%">
              <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
                <div class="col-xs-12" style="padding: 0 0 0 0.5%;height:100%" id="ticketDiv">
                  <div class="col-xs-12 provider" style="padding:0;" id="documentHeader" [style.height.%]="(tab === 'QuickView')? '25':'10'">
                    <div class="col-xs-11" style="padding:0" [style.text-align]="(tab === 'QuickView')? 'center':'left'" [style.width.%]="(tab === 'QuickView')? '100':'91'">
                      <span style="font-size:12px;padding: 0;" [style.text-align]="(tab === 'QuickView')? 'center':'left'"> EMAILS ({{emailslist.length}})</span>
                    </div>

                  </div>
                  <div *ngIf="tab === 'QuickView'" (click)="expandemails('EMAILS')" class="col-xs-12" style="  border-radius:3px;overflow:auto; margin-top:3px;padding:0 0 0 30%;height:80%;font-size:12px;">
                    <img *ngIf="emailslist.length>0" src="../../../assets/img/Emails Green Icon - Account Details Box.png" style="height:70%" />
                    <img *ngIf="emailslist.length==0" src="../../../assets/img/Emails Grey Icon - Account Details Box.png" style="height:70%" />
                  </div>

                </div>
              </div>
              <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
                <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView')? 'center':'left'">
                  <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView')? '25':'10'">
                    <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView')? '100':'91'" [style.text-align]="(tab === 'QuickView')? 'center':'left'"> SMS(0)</span>

                  </div>

                  <div *ngIf="tab === 'QuickView'" (click)="expandsms('SMS')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%">
                    <!--<img *ngIf="userdocumentlist.length>0" src="../../../assets/img/SMS Green Icon - Account Details Box.png" style="height:70%" />-->
                    <img src="../../../assets/img/SMS Grey Icon - Account Details Box.png" style="height:70%" />
                  </div>

                </div>
              </div>
            </div>

            <div class="col-xs-12" style="padding:0;overflow:hidden; background-color: #f5f5f54d !important;height:20%">

              <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
                <div class="col-xs-12" style="padding: 0 0 0 0.5%;height:100%" id="ticketDiv">
                  <div class="col-xs-12 provider" style="padding:0;" id="documentHeader" [style.height.%]="(tab === 'QuickView')? '25':'10'">
                    <div class="col-xs-11" style="padding:0" [style.text-align]="(tab === 'QuickView')? 'center':'left'" [style.width.%]="(tab === 'QuickView')? '100':'91'">
                      <span style="font-size:12px;padding: 0;" [style.text-align]="(tab === 'QuickView')? 'center':'left'"> DOCUMENTS ({{userdocumentlist.length}})</span>
                    </div>

                  </div>
                  <div *ngIf="tab === 'QuickView'" (click)="expanddocuments('WithOut Proposal','DOCUMENTS')" class="col-xs-12" style="  border-radius:3px;overflow:auto; margin-top:3px;padding:0 0 0 30%;height:80%;font-size:12px;">
                    <img *ngIf="userdocumentlist.length>0" src="../../../assets/img/Documents - QuickView Page Icon - Green.png" style="height:70%" />
                    <img *ngIf="userdocumentlist.length==0" src="../../../assets/img/Documents - QuickView Page Icon - Grey.png" style="height:70%" />
                  </div>

                </div>
              </div>

              <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
                <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView')? 'center':'left'">
                  <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView')? '25':'10'">
                    <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView')? '100':'91'" [style.text-align]="(tab === 'QuickView')? 'center':'left'"> CAMPAIGNS ({{Campignslist.length}})</span>
                  </div>
                  <div *ngIf="tab === 'QuickView'" (click)="expandcampaigns('CAMPAIGNS')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%">
                    <img *ngIf="Campignslist.length>0" src="../../../assets/img/Campaigns Green Icon - Account Details Box.png" style="height:70%" />
                    <img *ngIf="Campignslist.length==0" src="../../../assets/img/Campaigns Grey Icon - Account Details Box.png" style="height:70%" />
                  </div>

                </div>

              </div>
            </div>

            <div class="col-xs-12" style="padding:0;overflow:hidden; background-color: #f5f5f54d !important;height:20%">
              <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
                <div class="col-xs-12" style="padding: 0 0 0 0.5%;height:100%" id="ticketDiv">
                  <div class="col-xs-12 provider" style="padding:0;" id="documentHeader" [style.height.%]="(tab === 'QuickView')? '25':'10'">
                    <div class="col-xs-11" style="padding:0" [style.text-align]="(tab === 'QuickView')? 'center':'left'" [style.width.%]="(tab === 'QuickView')? '100':'91'">
                      <span style="font-size:12px;padding: 0;" [style.text-align]="(tab === 'QuickView')? 'center':'left'"> OPPORTUNITIES ({{Opportunitylist.length}})</span>
                    </div>

                  </div>
                  <div *ngIf="tab === 'QuickView'" (click)="expandOpportunities('OPPORTUNITIES')" class="col-xs-12" style="  border-radius:3px;overflow:auto; margin-top:3px;padding:0 0 0 30%;height:80%;font-size:12px;">
                    <img *ngIf="Opportunitylist.length>0" src="../../../assets/img/Account Details Opportunities Green.png" style="height:70%" />
                    <img *ngIf="Opportunitylist.length==0" src="../../../assets/img/Account Details Opportunities Grey.png" style="height:70%" />
                  </div>

                </div>
              </div>
              <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
                <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView')? 'center':'left'">
                  <!--<div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView')? '25':'10'">
                    <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView')? '100':'91'" [style.text-align]="(tab === 'QuickView')? 'center':'left'"> PROPOSALS & PO ({{POdocumentlist.length}})</span>

                  </div>

                  <div *ngIf="tab === 'QuickView'" (click)="expanddocuments('With Proposal','PROPOSALS & PO')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%">

                    <img *ngIf="POdocumentlist.length>0" src="../../../assets/img/Account Details Proposals Green.png" style="height:70%" />
                    <img *ngIf="POdocumentlist.length==0" src="../../../assets/img/Account Details Proposals Grey.png" style="height:70%" />
                  </div>-->

                </div>
              </div>

            </div>

            <div class="col-xs-12" style="padding:0; overflow:hidden; background-color: #f5f5f54d !important;height:20%">
              <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
                <div class="col-xs-12" style="padding: 0 0 0 0.5%;height:100%" id="ticketDiv">
                  <div class="col-xs-12 provider" style="padding:0;" id="documentHeader" [style.height.%]="(tab === 'QuickView')? '25':'10'">
                    <div class="col-xs-11" style="padding:0" [style.text-align]="(tab === 'QuickView')? 'center':'left'" [style.width.%]="(tab === 'QuickView')? '100':'91'">
                      <span style="font-size:12px;padding: 0;" [style.text-align]="(tab === 'QuickView')? 'center':'left'"> TICKETS ({{ticketslist.length}})</span>
                    </div>

                  </div>
                  <div *ngIf="tab === 'QuickView'" (click)="expandtickets('TICKETS')" class="col-xs-12" style="  border-radius:3px;overflow:auto; margin-top:3px;padding:0 0 0 30%;height:80%;font-size:12px;">
                    <img *ngIf="ticketslist.length>0" src="../../../assets/img/Tickets - QuickView Page Icon - Green.png" style="height:70%" />
                    <img *ngIf="ticketslist.length==0" src="../../../assets/img/Tickets - QuickView Page Icon - Grey.png" style="height:70%" />
                  </div>

                </div>
              </div>
              <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
                <div class="col-xs-12" style="padding: 0;margin-top:0;height:100%" id="ProposalDiv" [style.text-align]="(tab === 'QuickView')? 'center':'left'">
                  <div class="col-xs-12 provider" style="margin-top: 0; padding: 0; margin-top: 1%;" id="ProposalHeader" [style.height.%]="(tab === 'QuickView')? '25':'10'">
                    <span class="col-xs-11" style="text-align:left; font-size:11px;padding: 0;" [style.width.%]="(tab === 'QuickView')? '100':'91'" [style.text-align]="(tab === 'QuickView')? 'center':'left'"> PROJECTS ({{ProjectsList.length}})</span>

                  </div>

                  <div *ngIf="tab === 'QuickView'" (click)="expandProjects('PROJECTS')" class="col-xs-12 scroll-c" style="border-radius:3px;height: 100px;overflow: auto;font-size: 12px;height:70%">
                    <img *ngIf="ProjectsList.length>0" src="../../../assets/img/Projects - QuickView Page Icon - Green.png" style="height:70%" />
                    <img *ngIf="ProjectsList.length==0" src="../../../assets/img/Projects - QuickView Page Icon - Grey.png" style="height:70%" />
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div id="notesinfo" *ngIf="notesinfo" class="col-xs-12" style=" overflow:hidden; border:0px solid #ddd;overflow:hidden;height:100%;padding:0;">
            <div class="col-xs-6" style="padding:0;margin-top:0.5%;z-index:100;float:right">

            </div>
            <div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
              <div class="col-xs-12" style="padding:0;height:5%">
                <span class="col-xs-12" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;padding:2px;float:right;z-index:1">
                  <b> <img src="../../../assets/img/Black x.png" style="height:20px" (click)="closeDetailsDialog()" /></b>

                </span>
              </div>


            </div>
            <div class="col-xs-12" style="padding:0;height:100%;margin-top:0.5%">
              <app-new-notes style="height:100%"
                             [selectedTabName]="'1'"
                             [conid]="contactid"
                             [typ]="'Contact'"
                             [isModal]="false">
              </app-new-notes>
            </div>



          </div>
          <div id="activitiesinfo" *ngIf="activitiesinfo" class="col-xs-12" style="height:97%;padding:0;margin-top:1%">
            <div class="col-xs-12" style="padding:0;height:95%;">
              <app-history [type]="'Activities'" [ContAccId]="this.contactid" [userType]="'Contacts'"> </app-history>
            </div>

          </div>
          <div id="ticketsinfo" style="height: 94%;padding:0;margin-top:3%" class="col-xs-12" *ngIf="showticketsinfo">
            <div class="col-xs-12" style="padding:0;height:100%;margin-top:-3%">
              <app-history [type]="'Tickets'" [ContAccId]="this.contactid" [userType]="'Contact'"> </app-history>
            </div>
          </div>

          <div id="Projectsinfo" style="height: 94%;padding:0;margin-top:3%" class="col-xs-12" *ngIf="Projectsinfo">
            <div class="col-xs-12" style="padding:0;height:100%;margin-top:-3%">
              <app-history [type]="'Projects'" [ContAccId]="displaycontact.accountid" [userType]="'Customer'"> </app-history>
            </div>
          </div>

          <div id="documentsinfo" *ngIf="documentsinfo" class="col-xs-12" style="height:94%;overflow:hidden;margin-top:1%;padding:0;">


            <div class="col-xs-12" style="padding:0;height:5%" *ngIf="EditDocument && !ShowPDF">
              <div class="col-xs-12" style="padding:0;height:100%">
                <div *ngIf="!ShowPDF" class="col-xs-1" style="padding:0;height:95%;">
                  <div class="col-xs-12" style="padding:0;height:100%">
                    <span style="text-align:right; color: gray;font-size:12px ;font-weight: normal;padding-left: 3% !important;" (click)="AddDocumentsNew('New')">
                      <img src="../../../../assets/img/Create New Document Name.png" style="height:29px" />
                    </span>
                  </div>

                </div>

                <div class="col-xs-6" style="text-align:left;height:100%;padding:0px" [ngStyle]="{'margin-bottom.%':(documentview) ? -1.5 : 0 }">

                  <div class="col-xs-12" style="height:100%">
                    <h3 style="margin: 0 13% 0 0; font-size: 13px; font-weight: bold"> DOCUMENTS</h3>
                  </div>

                </div>
                <div class="col-xs-5" style="padding:0;height:100%">
                  <div class="col-xs-12" style="height:100%;padding-right:0;" *ngIf="!ShowPDF">
                    <select style="height:93%;width:100%;border-radius:5px" (change)="DocumentsFilterBy($event.target.value)">
                      <option> Display All</option>
                      <option value="{{item.Value}}" *ngFor="let item of DocumentsFilter"> {{item.Value}}</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-xs-12" style="padding:0;height:95%">
              <documentmanagement *ngIf="isDocumentsInfoDivVisible" [accountId]="this.contactid" [selectedtype]="'Contact'" [isUploadDivVisible]="true" [documentview]="documentview" (sendMsgEvent)="onOpenUploadDocument($event)" [FilterType]="'WithOut Proposal'"></documentmanagement>
            </div>

          </div>


          <div id="Proposalinfo" *ngIf="Proposalinfo" class="col-xs-12" style="height:94%;overflow:hidden;margin-top:1%;padding:0;">


            <div class="col-xs-12" style="padding:0;height:5%" *ngIf="EditDocument && !ShowPDF">
              <div class="col-xs-12" style="padding:0;height:100%">
                <div *ngIf="!ShowPDF" class="col-xs-1" style="padding:0;height:95%;">
                  <div class="col-xs-12" style="padding:0;height:100%">
                    <span style="text-align:right; color: gray;font-size:12px ;font-weight: normal;padding-left: 3% !important;" (click)="AddDocumentsNew('New')">
                      <img src="../../../../assets/img/Create New Document Name.png" style="height:29px" />
                    </span>
                  </div>

                </div>

                <div class="col-xs-6" style="text-align:left;height:100%" [ngStyle]="{'margin-bottom.%':(documentview) ? -1.5 : 0 }">

                  <div class="col-xs-12" style="height:100%">
                    <h3 style="margin:0 13% 0 0;">Proposal Documents</h3>
                  </div>

                </div>
                <div class="col-xs-5" style="padding:0;height:100%">
                  <div class="col-xs-12" style="height:100%;padding-right:0;" *ngIf="!ShowPDF">
                    <select style="height:93%;width:100%;border-radius:5px" (change)="DocumentsFilterBy($event.target.value)">
                      <option> Display All</option>
                      <option value="{{item.Value}}" *ngFor="let item of DocumentsFilter"> {{item.Value}}</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-xs-12" style="padding:0;height:95%">
              <documentmanagement *ngIf="isDocumentsInfoDivVisible" [accountId]="this.contactid" [selectedtype]="'Contact'" [isUploadDivVisible]="true" [documentview]="documentview" (sendMsgEvent)="onOpenUploadDocument($event)" [FilterType]="'With Proposal'"></documentmanagement>
            </div>

          </div>

          <div id="emailsinfo" *ngIf="emailsinfo" style="height:100%">

            <div class="col-xs-12 provider" style="text-align:left;padding:0;height:5%"> EMAILS ({{emailslist.length}})</div>

            <div class="col-xs-12" style="padding:0;height:95%">
              <emailsdiv [id]="this.contactid" [type]="'Contacts'" [ContactDashBoardPage]="true"></emailsdiv>
            </div>

          </div>

        </div>
       
      </div>
      <div *ngIf="documentview" class="col-xs-6" id="documentViewId">

        <div class="col-xs-6">
          Contact Name:  <b style="color:#FF0000">{{displaycontact.firstname}} {{displaycontact.middlename}} {{displaycontact.lastname}}<b *ngIf="displaycontact.title">,</b>&nbsp;{{displaycontact.title}}</b>
        </div>
        <div class="col-xs-1">
          <i *ngIf="documentindex > 0" class="fa fa-arrow-left " aria-hidden="true" id="previous" (click)="previousdocument()"></i>
        </div>
        <div class="col-xs-3"> <label style="color:black">{{documentindex+1}} of {{userdocumentcount}} (Documents)</label></div>
        <div class="col-xs-1">
          <i *ngIf="userdocumentcount >= documentindex" class="fa fa-arrow-right " aria-hidden="true" id="next" (click)="nextdocument()"></i>
        </div>
        <div class="col-xs-1" style="padding: 0px;float:right;">
          <button class="closeEmbed" style="float: right;" (click)="Expandimage()"><i class="fa fa-arrows-alt" aria-hidden="true"></i></button>
          <button class="closeEmbed" (click)="closeEmbed()" style="float: right;background: white;border: none;"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
        </div>


      </div>

    </div>
    <div class="col-xs-12" *ngIf="isTaskShow" style="padding:0;height:100%">
      <scheduletask [id]="this.id" [taskid]="this.taskid" [type]="this.type" [selectorTag]="true" (sendMsgEvent)="ReceiveTaskUpdate($event)" (Taskdialoghideshow)="Taskdialoghideshow($event)"></scheduletask>
    </div>
    <input type="text" id="accotIdNotes" style="display:none;" />
    <input type="text" id="accotTypeNotes" style="display:none;" />
    <app-dialog [(visible)]="notesshowDialog" *ngIf="notesshowDialog" (visibleChange)="onCloseDialogEventReceived($event)" [CloseButtonColor]="'white'">
      <div class="col-xs-12" style="height: 6%; background-color: rgb(40,62,74); color: white; text-align: center; padding: 0%">
        <div class="col-xs-1" style="padding: 0; text-align: left; padding: 1% 0 0 1%;">
          <img src="../../../assets/img/White Expand View.png" style="height:21px" (click)="ExpandPDF(true)" *ngIf="!DocExpandView" />
          <img src="../../../assets/img/White Collapse View.png" style="height:21px" (click)="ExpandPDF(false)" *ngIf="DocExpandView" />
        </div>
        <div class="col-xs-11" style="padding:1% 0 0 0;height:100%">
        <label style="font-weight: normal !important;">ACCOUNT DETAILS</label></div>


      </div>
      <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%">

        <app-new-notes style="height:100%"
                       [selectedTabName]="'1'"
                       [conid]="contactid"
                       [typ]="'Contact'"
                       [isModal]="false">
        </app-new-notes>

      </div>

    </app-dialog>
    <app-dialog [(visible)]="futureTaskDialog" *ngIf="futureTaskDialog" (visibleChange)="onCloseDialogEventReceived($event)" [type]="'FutureTask'" [CloseButtonColor]="'black'">
      <app-history [type]="'FutureTask'" [ContAccId]="account.id" [userType]="'Customer'"> </app-history>
    </app-dialog>
    <app-dialog [(visible)]="showmapWarning">
      <div style=" font-size: 19px;font-family: initial;width:100%;">
        <div style="text-align:center;font-size:21px;">    <b>Adding Account Address</b></div>
        <div style="text-align:justify;padding: 0 3% 0 2%;">
          <br />
          <span>
            The Address for this account was not selected from
            google Address list.Please select one of the two
            options below: Select <b>"  Go to Google Address "</b> and
            select google address for the account -or- select
            <b>"Save"</b> and system will save the address you have
            added to account.
          </span>
          <br /><br />
        </div>
        <div class="col-xs-12" style="margin-top: 2%;">
          <div class="col-xs-6" style="padding: 0;">
            <button class="btn noprovider" (click)="showmapWarning= !showmapWarning" style="width:85%;height: 31px;background-color:dodgerblue;border-radius:5px;">Go to Google Address</button>

          </div>
          <div class="col-xs-6" style="padding-right:0;text-align:right;">
            <button class="btn noprovider" (click)="SaveCustomeAddress()" style="width:85%;height: 31px;background-color:#93d250;border-radius:5px;">Save</button>
          </div>
        </div>
      </div>


    </app-dialog>

  </div>

</div>
<div *ngIf="showAccountInfo" id="AccountInformation">
  <account-contact [type]="this.type" [contactId]="contactid" [tab]="'QuickView'" (sendMsgEvent)="LoadContact($event)"></account-contact>
</div>

<app-dialog [(visible)]="showAddEditContact" *ngIf="showAddEditContact" id="addEditContact" [closable]="(action == 'EditAccount' || action == 'EditContact')? false : true" [OutSideClick]="(action == 'EditAccount' || action == 'EditContact')? false : true">
  <editcontact *ngIf="action == 'EditContact'" [contactid]="contactid" [accountId]="this.displaycontact.accountid" [type]="'Contact'" (sendMsgEvent)="ContactReceiveEvent($event)"></editcontact>
  <app-add-edit-account *ngIf="action == 'EditAccount'" [accountID]="this.account.id" [type]="'Account'" (sendMsgEvent)="ReceiveEvent($event)"></app-add-edit-account>
  <accountorcontactview *ngIf="action == 'View'" [accountId]="this.account.id" [type]="'Account'" (btnClickEvent)="btnClickReceived($event)"></accountorcontactview>
</app-dialog>

<app-dialog [(visible)]="quickViewHistoryDialog" *ngIf="quickViewHistoryDialog" id="quickViewHistoryDialog" [closable]="false">
  <div class="col-xs-12" style="height: 2%; padding: 0; background-color: rgb(186,220,153)"></div>
  <div class="col-xs-12" style="height: 5%; padding: 0; border-bottom: 1px solid gray;">
    <!--<div class="col-xs-1" style="padding: 0; text-align: left; padding: 1% 0 0 1%;">
     <img src="../../../assets/img/White Expand View.png" style="height:21px" (click)="ExpandPDF(true)" *ngIf="!DocExpandView" />
      <img src="../../../assets/img/White Collapse View.png" style="height:21px" (click)="ExpandPDF(false)" *ngIf="DocExpandView" />
    </div>-->
    <div class="col-xs-10" style="height: 100%; padding: 1%">
      <label>{{historyName}} / ACCOUNT DETAILS</label>
    </div>
    <div style="height:100%;padding:1% 0 0 0;">
      <span class="col-xs-1" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;float:right;z-index:1;padding:0 1% 0 0" (click)="GetContactInfo(contactid);quickViewHistoryDialog = false;ShowPDF = false">
        <b> <img src="../../../assets/img/Black x.png" style="height:20px" /></b>
      </span>
    </div>
  </div>
  <div class="col-xs-12" style="padding:0;height:94%;" *ngIf="QuickViewActivitiesDialog">
    <app-history [type]="'Activities'" [ContAccId]="this.contactid" [userType]="'Contacts'"> </app-history>
  </div>

  <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%" *ngIf="QuickViewOpportunityDialog">
    <app-history [type]="'Opportunities'" [ContAccId]="contactid" [userType]="'Contacts'"> </app-history>
  </div>
  <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%" *ngIf="QuickViewProjectDialog">
    <app-history [type]="'Projects'" [ContAccId]="displaycontact.accountid" [userType]="'Customer'"> </app-history>
  </div>

  <div class="col-xs-12" style="padding:0;height:94%;margin-top:0.5%" *ngIf="QuickViewNotesDialog">

    <app-new-notes style="height:100%"
                   [selectedTabName]="'1'"
                   [conid]="contactid"
                   [typ]="'Contact'"
                   [isModal]="false">
    </app-new-notes>
  </div>

  <div class="col-xs-12" style="padding:0;height:94%;" *ngIf="QuickViewDocumentsDialog">
    <div class="col-xs-12" style="padding:0;height:5%" *ngIf="EditDocument && !ShowPDF">
      <div class="col-xs-12" style="padding:0;height:100%">
        <div class="col-xs-1" style="padding:0;height:95%;">
          <div class="col-xs-12" style="padding:0;height:100%">
            <span style="text-align:right; color: gray;font-size:12px ;font-weight: normal;padding-left: 3% !important;" (click)="AddDocumentsNew('New')">
              <img src="../../../../assets/img/Create New Document Name.png" style="height:25px" />
            </span>
          </div>

        </div>

        <div class="col-xs-6" style="text-align:left;height:100%;padding:0px" [ngStyle]="{'margin-bottom.%':(documentview) ? -1.5 : 0 }">

          <div class="col-xs-12" style="height:100%;padding:2%">
            <h4 style="margin: 0 13% 0 0; font-size: 13px; font-weight: bold;"> DOCUMENTS</h4>
          </div>

        </div>
        <div class="col-xs-5" style="padding:0;height:100%">
          <div class="col-xs-12" style="height:100%;padding-right:0;" *ngIf="!uploadDocumentShow">
            <select style="height:93%;width:100%;border-radius:5px" (change)="DocumentsFilterBy($event.target.value)">
              <option> Display All</option>
              <option value="{{item.Value}}" *ngFor="let item of DocumentsFilter"> {{item.Value}}</option>
            </select>
          </div>
        </div>

      </div>
    </div>
    <div class="col-xs-12" style="padding:0;height:95%" *ngIf="QuickViewDocumentsDialog">
      <div class="col-xs-12" style="padding:0;height:100%" *ngIf="!uploadDocumentShow">
        <documentmanagement *ngIf="isDocumentsInfoDivVisible" [accountId]="this.contactid" [selectedtype]="'Contact'" [isUploadDivVisible]="true" [documentview]="documentview" (sendMsgEvent)="onOpenUploadDocument($event)" [FilterType]="'WithOut Proposal'"></documentmanagement>
      </div>
      <div *ngIf="uploadDocumentShow" class="col-xs-12" style="padding:0px;height:100%">
        <upload-document [accountId]="this.contactid" [selectedtype]="'Contact'" (sendMsgEvent)="SavedDocuments($event)"> </upload-document>
      </div>

    </div>


  </div>


  <div class="col-xs-12" style="padding:0;height:94%;" *ngIf="QuickViewProposalsDialog">
    <div class="col-xs-12" style="padding:0;height:5%" *ngIf="EditDocument && !ShowPDF">
      <div class="col-xs-12" style="padding:0;height:100%">
        <div class="col-xs-1" style="padding:0;height:95%;">
          <div class="col-xs-12" style="padding:0;height:100%">
            <span style="text-align:right; color: gray;font-size:12px ;font-weight: normal;padding-left: 3% !important;" (click)="AddDocumentsNew('New')">
              <img src="../../../../assets/img/Create New Document Name.png" style="height:25px" />
            </span>
          </div>

        </div>

        <div class="col-xs-7" style="text-align:left;height:100%;padding:0px" [ngStyle]="{'margin-bottom.%':(documentview) ? -1.5 : 0 }">

          <div class="col-xs-12" style="height:100%;padding:2%">
            <span style="margin: 0 13% 0 0; font-size: 14px; font-weight: bold;">PROPOSALS AND PURCHASE ORDERS</span>
          </div>

        </div>
        <div class="col-xs-4" style="padding:0;height:100%">
          <div class="col-xs-12" style="height:100%;padding-right:0;" *ngIf="!uploadDocumentShow">
            <select style="height:93%;width:100%;border-radius:5px" (change)="DocumentsFilterBy($event.target.value)">
              <option> Display All</option>
              <option value="{{item.Value}}" *ngFor="let item of DocumentsFilter"> {{item.Value}}</option>
            </select>
          </div>
        </div>

      </div>
    </div>
    <div class="col-xs-12" style="padding:0;height:95%" *ngIf="QuickViewProposalsDialog">
      <div class="col-xs-12" style="padding:0;height:100%" *ngIf="!uploadDocumentShow">
        <documentmanagement *ngIf="isDocumentsInfoDivVisible" [accountId]="this.contactid" [selectedtype]="'Contact'" [isUploadDivVisible]="true" [documentview]="documentview" (sendMsgEvent)="onOpenUploadDocument($event)" [FilterType]="'With Proposal'"></documentmanagement>
      </div>
      <div *ngIf="uploadDocumentShow" class="col-xs-12" style="padding:0px;height:100%">
        <upload-document [accountId]="this.contactid" [selectedtype]="'Contact'" (sendMsgEvent)="SavedDocuments($event)"> </upload-document>
      </div>

    </div>


  </div>
  <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%" *ngIf="quickViewSMSDialog">
    <app-history [type]="'sms'" [AccountcontactIds]="''" [userType]="'Customer'"> </app-history>
  </div>
  <div class="col-xs-12" style="padding:0;height:94%;" *ngIf="QuickViewTicketsDialog">
    <app-history [type]="'Tickets'" [ContAccId]="this.contactid" [userType]="'Contact'"> </app-history>
  </div>
  <div class="col-xs-12" style="padding:0;height:94%;" *ngIf="quickViewCampaignsDialog">
    <div class="col-xs-12" style="padding:0;height:5%">
      <div class="col-xs-9" style="padding:0;height:100%;float:right">

        <div class="col-xs-4" style="padding:0 0 0 0;height:100%">

        </div>
        <div class="col-xs-4" style="height: 100%;">

        </div>
        <div class="col-xs-4" style="height: 100%;">
          <select style="height:90%;width:100%;border-radius:5px;" (change)="CampaignsDropdownChange($event.target.value)">
            <option *ngFor="let stat of StatusDropdownList" value={{stat.value}}>
              {{stat.itemName}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <app-campaigns [CampaignId]="0" [ShowViewCampaign]="false" [AccountcontactIds]="this.contactid" [Source]="'ContactView'" [type]=""> </app-campaigns>
  </div>
  <div class="col-xs-12" style="padding:0;height:94%;" *ngIf="QuickViewEmailDialog">
    <div class="col-xs-12 provider" style="text-align:left;padding:0;height:5%"> EMAILS ({{emailslist.length}})</div>

    <!--<span class="col-xs-6" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;padding:2px;" (click)="close()"><b> <img src="../../../assets/img/Black x.png" style="height:20px" /></b></span>-->
    <div class="col-xs-12" style="padding:0;height:95%">
      <emailsdiv [id]="this.contactid" [type]="'Contacts'" [ContactDashBoardPage]="true"></emailsdiv>
    </div>

  </div>
</app-dialog>

<app-dialog [(visible)]="isSharing " *ngIf="isSharing " id="OwnerShipSharing" [closable]="false">
  <ownershipsharing [AccId]="account.id" [PageType]="this.tab" (sendMsgEvent)="onCloseEventReceived($event)"></ownershipsharing>
</app-dialog>
<app-dialog [(visible)]="ViewPhotoDialog" *ngIf="ViewPhotoDialog" id="ViewPhotoDialog" [closable]="false" [type]="'Contactphoto'" (Change)="onChange($event)">
  <contact-photo [contact]="displaycontact" (Change)="OnReceived($event)"></contact-photo>
</app-dialog>
