<div class="col-xs-12" style="padding:0;border:1px solid beige" [style.height.%]="selectedtype=='SalesXUsers' ? 400 : 100" *ngIf="!ShowPDF">

  <div *ngIf="!isDocumentManagement && !isUploadDivVisible" class="col-md-12 col-sm-12 col-xs-12" style="padding: 0;" [style.height.%]="selectedtype=='MySalesXCRM' ? 53 : selectedtype=='SalesXUsers' ? 93 : 88">
    <ag-grid-angular style="width: 100%;height: 600px" class="ag-theme-balham"
                     [gridOptions]="gridOptions"
                     [columnDefs]="documentscolumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="userdocumentlist"
                     [context]="context"
                     [sideBar]="sideBar"
                     (selectionChanged)="SalesXCRMSelectionChanged($event)"
                     [frameworkComponents]="frameworkComponents"
                     [suppressDragLeaveHidesColumns]="true"
                     (columnVisible)="columnVisible($event)"
                     (dragStopped)="dragStopped($event)"
                     [suppressRowClickSelection]="true"
                     (gridReady)="onAgGridReady($event)">
    </ag-grid-angular>


  </div>

  <div *ngIf="!isDocumentManagement && (isUploadDivVisible && documentlist != null && documentlist.length > 0)" class="col-md-12 col-sm-12 col-xs-12" style="padding: 0;" [style.height.%]="documentDivHeight">

  </div>

  <div *ngIf="!isDocumentManagement && (isUploadDivVisible && documentlist != null && documentlist.length == 0)" class="col-md-12 col-sm-12 col-xs-12" style="padding: 0; height:83%" [style.height.%]="this.accountstatisticBox == 'Close' ? 86 : documentview ? 78 : 97"
       id="documentManagementDiv">
    <ag-grid-angular style="width: 100%;height:100%" class="ag-theme-balham" *ngIf="!EditDiv"
                     [gridOptions]="gridOptions"
                     [columnDefs]="documentscolumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="userdocumentlist"
                     (selectionChanged)="DoconSelectionChanged($event)"
                     [context]="context"
                     [sideBar]="sideBar"
                     (columnVisible)="columnVisible($event)"
                     (dragStopped)="dragStopped($event)"
                     [frameworkComponents]="frameworkComponents">
    </ag-grid-angular>
    <div class="col-xs-12" style="height:100%" *ngIf="EditDiv">

      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding:0;height:100%">
        <div style="text-align:center;font-weight:900;height:7%;font-size:18px;"><label>Edit Document</label></div>
        <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.5%;padding:0;height:10%">
          <div class="col-xs-4" style="padding: 1% 0 0 0;height:100%">
            <span>Document Name :</span>
          </div>
          <div class="col-xs-8">
            <select class="form-control" [(ngModel)]="doc.documentnamepermissionid" (change)="fieldEdited('Document Name')">
              <option selected="selected" value="0">Select</option>
              <option *ngFor="let doc of documentNamesList" value="{{doc.id}}">{{doc.documentname}}</option>
            </select>
          </div>
        </div>
        <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.5%;padding:0;height:14%">
          <div class="col-xs-4" style="padding:0;">
            <span>Documnent Description :</span>
          </div>
          <div class="col-xs-8">
            <textarea rows="2" class="form-control" type="text" [(ngModel)]="doc.documentdescription" (focusout)="fieldEdited('Description')"></textarea>
          </div>
        </div>
        <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.5%;padding:0;height:5%">
          <div class="col-xs-4" style="padding:0;">
            <span>Document Size :</span>
          </div>
          <div class="col-xs-8">
            {{doc.Size}}
            <!--<input class="form-control" [(ngModel)]="doc.Size">-->
          </div>
        </div>


        <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.5%;padding:0;height:5%">
          <div class="col-xs-4" style="padding:0;">
            <span>User Saved</span>
          </div>
          <div class="col-xs-8">
            {{doc.SavedBy}}
            <!--<input class="form-control" [(ngModel)]="doc.SavedBy">-->
          </div>
        </div>
        <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.5%;padding:0;height:5%">
          <div class="col-xs-4" style="padding:0;">
            <span>Date Saved :</span>
          </div>
          <div class="col-xs-8">
            {{doc.datecreated | date : "MM-dd-yyyy"}}
          </div>
        </div>
        <div class="col-xs-12 removeCompletePadding" style="margin-top: 0.5%;padding:0;height:5%">
          <div class="col-xs-4" style="padding:0;">
            <span>Time Saved :</span>
          </div>
          <div class="col-xs-8">
            {{doc.datecreated | date:"hh:mm a"}}
          </div>
        </div>

        <div class="col-xs-12" style="padding: 1%; height: 45%; background-color: lightgray;">
          <div class="col-xs-12" style="padding:0;height:16%;font-size:15px;"><label>Edit Document History</label></div>
          <div class="col-xs-12" style="padding:0;height:80%;font-size:12px;">
            <table style="width:100%">
              <tr>
                <th style="width:17%">Date</th>
                <th style="width:17%">Time</th>
                <th style="width:20%">User</th>
                <th style="width:30%">Field Edited</th>
              </tr>
              <tr *ngFor="let item of docHistory">
                <td style="width:17%">{{item.EditedDate}}</td>
                <td style="width:17%">{{item.EditedTime}}</td>
                <td style="width:20%">{{item.EditedByName}}</td>
                <td style="width:30%">{{item.FieldEdited}}</td>
              </tr>
            </table>
          </div>

        </div>

        <div class="col-xs-12" style="margin-top: 1%; height:10%">
          <div class="col-xs-4">
          </div>
          <div class="col-xs-8">
            <div class="col-xs-6" style="padding: 0;">
              <button class="btn btn-default" (click)="cancel()" style="width: 100%; border:1px solid">Cancel</button>
            </div>
            <div class="col-xs-6" style="padding-right: 0;">
              <button type="submit" class="btn saveButton" style="width:100%;" (click)="UpdateDoc()">Save</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="(isDocumentTransfer && selectedtype!= 'Contact') || (isDocumentTransfer && selectedtype != 'Account')" class="col-md-6 col-sm-6 col-xs-12 col-sm-12 ng-star-inserted">
    <div style="background-color:#66ff33 !important">DOCUMENT TRANSFER</div>
    <div *ngIf="(isselectedtransfered)">
      <p>
        <b>Congratulation</b><br /><br />
        there were (18) documents transfered from <br />
        <b> {{this.selecteddocumentName}} to {{this.selectedtodocumentname}}</b><br /><br />
        <label style="color:red">
          {{this.selecteddocumentName}} Document Description was deleted
        </label><br /><br />
        <label (click)="documenttransfered()">Please select Continue</label>
      </p>
    </div>
    <div *ngIf="(!isselectedtransfered)" class="col-md-6 col-sm-6 col-xs-6 col-sm-6 ng-star-inserted">
      <p>
        There are (12) documants associated with the document description:<br /><br />
        <b>{{this.selecteddocumentName}}</b><br /><br />
        Before the system can delete this document description,all documents associates with this document description must be transfered to
        another document descrption.<br /><br />
        Please select document decription from the table on right to transfer these documents too.
      </p>
    </div>
    <div *ngIf="(!isselectedtransfered)" class="col-md-6 col-sm-6 col-xs-6 col-sm-6 ng-star-inserted">

      <table class='table table-striped '>
        <tbody *ngFor="let doc of userdocumentlist; let i = index">
          <tr id="{{doc.id}}" class="closeButtonTr" (click)="SelectedDocumentType(doc.id,doc.documentname)">
            <td><input id="Check" style="color:red; display:block;" type="button" value="X"></td>
            <td>{{doc.documentname}}</td>
          </tr>
        </tbody>
      </table>
      <br /><br />
      <div *ngIf="todocumentid!=0">
        <button class="btn btn-default" (click)="canceltransfered()">Cancel</button>
        <button class="btn saveButton" (click)="transfered()">Save</button>
      </div>
    </div>

  </div>

  <div *ngIf="isDocumentManagement" class="col-xs-12" style="padding: 0; height: 100%">  
      <div class="col-xs-12" style="padding:0;">
        <h4 class="fontStyleCss">
          <span style="text-transform: uppercase; margin-left: 1%">DOCUMENT NAMES / MY DOCUMENTS PERMISSION</span>
        </h4>
      </div>
    <div class="col-xs-12" style="padding:0;height:7%">
      <div class="col-xs-12" style="padding:0">
        <div class="col-xs-1" style="padding:0">
          <img src="../../../assets/img/Create New Document Name.png" style="height:25px" (click)="addFiles($event)" />
        </div>

      </div>

    </div>
    <div class="col-xs-12" style="padding:0;height:90%">
      <ag-grid-angular style="height:94%" class="ag-theme-balham" id="DM"
                       [gridOptions]="gridOptionsDM "
                       [columnDefs]="documentscolumnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="userdocumentlist"
                       [context]="context"
                       [rowDragManaged]="true"
                       [sideBar]="sideBar"
                       [animateRows]="true"
                       (rowDragEnd)="onRowDragEnd($event)"
                       (columnVisible)="columnVisibleDM($event)"
                       (dragStopped)="dragStoppedDM($event)"
                       [frameworkComponents]="frameworkComponents"
                       (selectionChanged)="onSelectionChanged($event)"
                       (gridReady)="onAgGridReady($event)">
      </ag-grid-angular>
    </div>


  </div>

  <div [class.col-md-6]="isDocumentManagement" [class.col-sm-6]="isDocumentManagement" [class.col-xs-12]="isDocumentManagement || !isDocumentManagement" [class.col-md-12]="!isDocumentManagement" [class.col-sm-12]="isDocumentManagement" [ngClass]="parentPage =='docManConfig'? 'heightclass':''" [style.height.px]="uploadDivHeight" style="padding: 0;" *ngIf="!isDocumentTransfer" id="buttonDiv">

    <div style="overflow: auto;height:90%" id="addDetailsDiv" *ngIf="isDocumentManagement || (!isDocumentManagement && documentlist != null && documentlist.length > 0)">

      <div class="col-xs-12 removeCompletePadding" style="padding: 0;height:100%">
        <div class="col-xs-12" style="padding:0;height:6%" *ngIf="isDocumentManagement">
        </div>
        <div *ngIf="isEditDocument" style="padding:0;">
          <div class="col-xs-12" style="text-align:center;padding:0;">
            <label>EDIT DOCUMENT NAME/PERMISSION</label><br /><br />
          </div>

          <div class="col-xs-12" style="padding:0;">
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding:0;">
              <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding-left:0;">
                <div class="col-xs-4">
                  <label>Date Created :</label>
                </div>
                <div class="col-xs-8">
                  <input class="form-control" [(ngModel)]="doc.id" style="display:none">
                  <input class="form-control" [(ngModel)]="doc.datecreated" readonly>
                  <input class="form-control" [(ngModel)]="doc.documentnamepermissionid" style="display:none">
                </div>
              </div>
              <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding-left:0;">
                <div class="col-xs-4">
                  <label>Document Name :</label>
                </div>
                <div class="col-xs-8">
                  <input class="form-control" [(ngModel)]="doc.documentname">
                </div>
              </div>
              <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding-left:0;">
                <div class="col-xs-4">
                  <label>Saved By :</label>
                </div>
                <div class="col-xs-8">
                  <input class="form-control" [(ngModel)]="doc.SavedBy" readonly>
                </div>
              </div>


              <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding-left:0;">
                <div class="col-xs-4">
                  <label>Permission level :</label>
                </div>
                <div class="col-xs-8">
                  <input class="form-control" [(ngModel)]="doc.permissionlevelname" readonly>
                </div>
              </div>
              <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding-left:0;">
                <div class="col-xs-4">
                  <label>Date Last Edited : </label>
                </div>
                <div class="col-xs-8">
                  <input class="form-control" [(ngModel)]="doc.lastediteddate" readonly>
                </div>
              </div>

              <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding-left:0;">
                <div class="col-xs-4">
                  <label>User Last Edited : </label>
                </div>
                <div class="col-xs-8">
                  <input class="form-control" [(ngModel)]="doc.LateditedBy" readonly>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12" style="height:16%" *ngFor="let charge of documentlist; let myIndex=index">
          <div class="col-xs-12" style="padding:0.5% 0 0 0;">
            <div class="col-xs-3" style="padding:0;">Document Name</div>
            <div class="col-xs-8" style="padding:0;">
              <div class="col-xs-12" style="padding:0;" *ngIf="isDocumentManagement" id="myid">
                <input type="text" class="form-control" style="width: 100%;" [(ngModel)]="charge.documentname" />
              </div>

            </div>
          </div>
          <div class="col-xs-12" style="padding:0;margin-top:1%">
            <div class="col-xs-3" style="padding:0;">Permission Level</div>
            <div class="col-xs-8" style="padding:0;">
              <div class="col-xs-12" style="padding:0;" *ngIf="isDocumentManagement">
                <select class="form-control" [(ngModel)]="charge.permissionlevel">
                  <option selected="selected" value="0">Select</option>
                  <option *ngFor="let per of permissionlevellist" value="{{per.id}}">{{per.itemName}}</option>
                </select>
              </div>
              <div class="col-xs-12" style="padding:0;" *ngIf="!isDocumentManagement">
                <select class="form-control" [(ngModel)]="charge.documentnamepermissionid">
                  <option selected="selected" value="0">Select</option>
                  <option *ngFor="let doc of documentNamesList" value="{{doc.id}}">{{doc.documentname}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-xs-12" style="padding:0;" *ngIf="!isDocumentManagement">
            <div class="col-xs-4" style="padding:0;">Uploaded File</div>
            <div class="col-xs-8" style="padding:0;">
              <label for="addFile{{myIndex}}" style="cursor: pointer;" title="Change Document">{{charge.uploadedfilename}}</label><span title="View Document" style="padding-left: 5px; cursor: pointer" [class.fa]="true" [class.fa-file-pdf-o]="charge.extension.toLowerCase()=='pdf'" [class.fa-file-text-o]="charge.extension.toLowerCase()=='txt'" [class.fa-file-word-o]="charge.extension.toLowerCase()=='doc'||charge.extension.toLowerCase()=='docx'" [class.fa-file-image-o]="charge.extension.toLowerCase()=='jpg'||charge.extension.toLowerCase()=='jpeg'||charge.extension.toLowerCase()=='png'" (click)="openfilecharge(charge)"></span>
              <input id="addFile{{myIndex}}" name="AddFileInput{{myIndex}}" type="file" (change)="addFile($event,charge)" accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.txt" style="width:106%;margin-top:2%; opacity: 0; position: absolute; z-index: -1" />
            </div>
          </div>

          <div class="col-xs-12" style="padding:0;" *ngIf="!isDocumentManagement">
            <div class="col-xs-4" style="padding:0;">File Size</div>
            <div class="col-xs-8" style="padding:0;"> <label name="lblFileSize">{{charge.size}}</label></div>
          </div>

        </div>


      </div>
    </div>
    <div style="height: 10%;" class="col-xs-12" [hidden]="EditDiv" *ngIf="(documentlist != null && documentlist.length > 0)">



      <div *ngIf="isDocumentManagement" class="col-xs-12" style="padding:0;height:100%">
        <div class="col-xs-8"></div>
        <div class="col-xs-4">
          <button class="btn btn-long btn-default" style="width:45% !important;" (click)="CancelDocuments()">Cancel</button>


          <button *ngIf="(documentlist != null && documentlist.length == 0) || checkEnable()" type="submit" class="btn saveButton" (click)="SaveDocuments()" style="width: 49%;margin-right:4%">Save</button>
        </div>
      </div>
    </div>
  </div>


</div>
<div class="col-xs-12" *ngIf="ShowPDF" style="height:100%;padding:0;margin-top:0%">
  <div class="col-xs-12" style="padding:0;height:8%">

    <div class="col-xs-11" style="padding:0;height:100%">
      <div class="col-xs-12" style="text-align: center;">
        <label style="color:black">
          <i *ngIf="documentindex > 0" class="fa fa-arrow-left " aria-hidden="true" id="previous" style="color:black" (click)="previousdocument()"></i>
          {{documentindex + 1}} of {{userdocumentcount}}
          <i *ngIf="documentindex < userdocumentcount-1" class="fa fa-arrow-right " aria-hidden="true" id="next" (click)="nextdocument()" style="color:black"></i>
        </label>
       
      </div>
      <div class="col-xs-12" style="text-align: center;">
        <label>{{PreviewDocument.DateUploaded}}  {{PreviewDocument.documentname}}</label>
      </div>
    </div>

    <div class="col-xs-1" style="padding:0;background-color:transparent;float:right;text-align:right;margin-top:0%">
      <b (click)="close()">
        <img src="../../../assets/img/Black x.png" style="height:23px" />
      </b>
    </div>
  </div>

  <div id="pdfDiv1" class="col-xs-12" *ngIf="pdfDiv1">

  </div>
</div>
<app-dialog [(visible)]="DeleteConfirmationPopup" id="DeleteConfirmationPopup" [closable]="false">
  <app-delete-popup  [title]="'Document'" [description]="(contactStatus==1)?'Activate this Document Selected?':'Delete this Document Selected?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteevent($event)">
  </app-delete-popup>
 
</app-dialog>
<app-dialog [(visible)]="documentDialog" *ngIf="documentDialog" id="documentDialog" style="height:100%">
  <div class="col-xs-12">
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
        <div class="col-xs-4">
          <label>Date Created :</label>
        </div>
        <div class="col-xs-8">
          <input class="form-control" [(ngModel)]="doc.id" style="display:none">
          <input class="form-control" [(ngModel)]="doc.datecreated" disabled>
          <input class="form-control" [(ngModel)]="doc.documentnamepermissionid" style="display:none">
        </div>
      </div>
      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
        <div class="col-xs-4">
          <label>Document Name:</label>
        </div>
        <div class="col-xs-8">
          <select class="form-control" id="documentName" [(ngModel)]="doc.permissionlevel">
            <option selected="selected" value="0">Select</option>
            <option *ngFor="let doc of documentNamesList" value="{{doc.id}}">{{doc.documentname}}</option>
          </select>
        </div>
      </div>
      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
        <div class="col-xs-4">
          <label>Saved By :</label>
        </div>
        <div class="col-xs-8">
          <input class="form-control" [(ngModel)]="doc.SavedBy" disabled>
        </div>
      </div>

      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
        <div class="col-xs-4">
          <label>Size :</label>
        </div>
        <div class="col-xs-8">
          <input class="form-control" [(ngModel)]="doc.Size" disabled>
        </div>
      </div>
      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
        <div class="col-xs-4">
          <label>Permission to View :</label>
        </div>
        <div class="col-xs-8">
          <input class="form-control" [(ngModel)]="doc.PermissionlevelName" disabled>
        </div>
      </div>
      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
        <div class="col-xs-4">
          <label>Document Type : </label>
        </div>
        <div class="col-xs-8">
          <input class="form-control" [(ngModel)]="doc.extension" disabled>
        </div>
      </div>

      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
        <div class="col-xs-4">
          <label>Document Tab :</label>
        </div>
        <div class="col-xs-8">
          <select class="form-control" [(ngModel)]="doc.tabId">
            <option *ngFor="let doc of  docTabList" value={{doc.id}}>
              {{doc.itemName}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
        <div class="col-xs-4">
          <label>Document Description :</label>
        </div>
        <div class="col-xs-8">
          <input class="form-control" [(ngModel)]="doc.documentdescription">
        </div>
      </div>

      <div class="col-xs-12" style="margin-top: 1%; ">
        <div class="col-xs-4">
        </div>
        <div class="col-xs-8">
          <div class="col-xs-6" style="padding: 0;">
            <button class="btn btn-default" (click)="cancel()" style="width: 100%; border:1px solid">Cancel</button>
          </div>
          <div class="col-xs-6" style="padding-right: 0;">
            <button type="submit" class="btn saveButton" style="width:100%;" (click)="UpdateDoc()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="ShowDeleteDocNamePermission" *ngIf="ShowDeleteDocNamePermission" id="ShowDeleteDocNamePermission" [closable]="false">
  <app-delete-popup [title]="(deleteRowData.isdeleted == 1)?'Activate Confirmation':'Delete Confirmation'" [description]="(deleteRowData.isdeleted == 1)?'Activate this Document Name?':'Delete this Document Name?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="Confirmation($event)">
  </app-delete-popup>
</app-dialog>

<app-dialog [(visible)]="ShowDeleteDocNameMatch" *ngIf="ShowDeleteDocNameMatch" id="ShowDeleteDocNameMatch">
  <div class="col-xs-12" style="padding:0;height:100%">
    <div class="col-xs-12" style="height:10%">
      <label style="font-size: 27px;font-weight: 900;padding: 0 0 0 3%;">SalesXCRM Message</label>
    </div>
    <div class="col-xs-10" style="padding: 0 0 0 8%; height: 10%; margin-top: 1%;">
      <span style="font-size:18px;">You are Deleting the following Document Name :&nbsp;</span><span style="font-size:18px;color:red;text-decoration:underline">{{DeletedDoc.documentname}}:</span><br />
    </div>

    <div class="col-xs-12" style="padding: 0 0 0 8%; height: 20%; font-size: 18px;">
      <span>The system has found the Document Name being used in the system. </span><br />
      <span>Before deleting you must select another document name </span><br />
      <span>to replace the document name you are Deleting.</span><br />
    </div>
    <div class="col-xs-12" style="padding:0 0 0 8%;height:58%">
      <div class="col-xs-12" style="padding:0;height:10%">
        <span style="font-size:18px;">Please select a Document name to replace this Document </span>
      </div>
      <div class="col-xs-12" style="padding:0;height:88%">
        <div class="col-xs-7 scroll-c" style="padding:0;height:100%;overflow:auto;width:55%">

          <angular2-multiselect [data]="documenttypeList" [(ngModel)]="ReplaceItem"
                                [settings]="dropdownSetting">
          </angular2-multiselect>

        </div>
        <div class="col-xs-5" style="padding:0;height:100%">
          <div class="col-xs-12" style="height:80%"></div>
          <div class="col-xs-12" style="height:20%">
            <div class="col-xs-6"><button (click)="Confirmation(false)" class="btn noprovider" style="width: 100%; height: 36px; color: #008CBA; background-color: white; border: 1px solid lightgrey !important;">Cancel</button></div>

            <div class="col-xs-6"><button class="btn saveButton" style="width:100%;height: 36px;" (click)="ReplaceDocumentName()">Save</button></div>

          </div>
        </div>
      </div>
    </div>
  </div>
</app-dialog>


<app-dialog [(visible)]="DocViewAccessDenied" *ngIf="DocViewAccessDenied" id="DocViewAccessDenied">
  <div class="col-xs-12" style="padding:5%">
    <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
    <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
      <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">Access Denied</div><br />
        <div class="col-xs-12" style="padding: 0; padding-top: 3%;">Higher Level of Permission Required</div>
    </div>
    <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
      <div class="col-xs-7" style="padding: 0;">

      </div>
      <div class="col-xs-5" style="padding:0;">
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                  (click)="DocViewAccessDenied = false">
            OK
          </button>
        </div>
        
      </div>
    </div>
  </div>
</app-dialog>
