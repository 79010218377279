<ag-grid-angular style="width: 100%; height:105% !important;"  class="ag-theme-balham"
                 [gridOptions]="gridOptions"
                 [columnDefs]="columnDefs"
                 [rowData]="allContacts"
                 [defaultColDef]="applicationComponent.defaultGridColDef"
                 [context]="context"
                 [sideBar]="sideBar"                
                 [frameworkComponents]="frameworkComponents"                
                 (cellDoubleClicked)="cellDoubleClicked($event)"
                 (columnVisible)="columnVisible($event)"
                 (dragStopped)="dragStopped($event)"
                 (columnResized)="columnResized($event)"
                 (gridReady)="onAgGridReady($event)">
</ag-grid-angular>
