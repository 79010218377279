import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GridOptions, RowDragEndEvent } from 'ag-grid';
import { AuditLogModel } from '../../models/auditlog';
import { Dropdowntype } from '../../models/dropdowntype';
import { Taskgroup } from '../../models/taskgroup';
import { TaskType } from '../../models/TaskType';
import { CustomerService } from '../../services/customer.service';
import { LeadsService } from '../../services/leads.service';
import { UserService } from '../../services/user.service';
import { ApplicationComponent } from '../application.component';
import { EditdeletecvComponent } from '../editdeletecv/editdeletecv.component';
import { SystemRowConfiguration } from '../../models/system-row-configuration';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-task-list-configuration',
  templateUrl: './task-list-configuration.component.html',
  styleUrls: ['./task-list-configuration.component.css']
})
export class TaskListConfigurationComponent implements OnInit {
  public gridOptions: GridOptions;
  public sideBar;
  public columnDefs: any[];
  public TaskGroupcolumnDefs: any[];
  TaskListItems: TaskType[];
  OriginalTaskListItems: TaskType[];
  TaskTypeFieldform: FormGroup;
  TaskGroupFieldform: FormGroup;
  taskTypeObj: TaskType = new TaskType();
  taskGroupObj: Taskgroup = new Taskgroup();
  taskgrouplist: Taskgroup[] = [];
  OriginalTaskGroupList: Taskgroup[] = [];
  taskname: string = "";
  ShowTaskGrpDialog: boolean = false;
  edittaskgroup: boolean = false;
  ShowTaskTypeFieldform: boolean = false;
  ShowTaskGroupFieldform: boolean = false;
  public frameworkComponents;
  public context;
  public DeleteMessagePopup = false;
  public DeleteTaskGroup = false;
  currentuser: any;
  public taskGroupConmfigHomePage = true;
  public ShowTaskGroupPage = true;
  public ShowTaskGroupItemsPage = false;
  TimeSlots: Dropdowntype[] = [];
  SelectedCategory: string = null;
  selectedGroup: number = null;
  @Input() SubMenuType: string = null;
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration();
  constructor(private leadservice: LeadsService, private userservice: UserService, private customerService: CustomerService, private toastr: ToastrService, public applicationComponent: ApplicationComponent) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.customerService.GetSpecialityList("TimeSlots", this.currentuser.id).then((Rows) => {
      this.TimeSlots = Rows;
    });    
    this.ShowTaskGroupPages("TaskGroups");   
  }

  getTaskGroupItems() {
    this.initializeAgGrid();
    this.getAllTaskListItems();
    this.TaskTypeFieldform = this.getTaskTypeFieldsForm();
    this.taskTypeObj.category = 'To Do';
    this.userservice.GetTaskGroupList("TaskList", 0, this.currentuser.id).then((taskgroups) => {
      this.taskgrouplist = taskgroups;
    });
    
  }
  getTaskTypeFieldsForm(): FormGroup {
    return new FormGroup({
      'tasktype': new FormControl(this.taskTypeObj.tasktype, Validators.required),
      'category': new FormControl(this.taskTypeObj.category, Validators.required),
      'taskgroupid': new FormControl(this.taskTypeObj.taskgroupid, Validators.required),
      'SortOrder': new FormControl(this.taskTypeObj.SortOrder, Validators.required),    

    });


  }

  getTaskGroupFieldsForm(): FormGroup {
    return new FormGroup({

      'tasktype': new FormControl(this.taskTypeObj.tasktype, Validators.required),
      'category': new FormControl(this.taskTypeObj.category, Validators.required),
      'taskgroupid': new FormControl(this.taskTypeObj.taskgroupid, Validators.required),
      'SortOrder': new FormControl(this.taskTypeObj.SortOrder, Validators.required),


    });


  }
  getAllTaskListItems() {
    this.TaskListItems = [];
    this.leadservice.GetAllTaskList(this.currentuser.id).then((accounttype) => {
      this.TaskListItems = accounttype;
      this.OriginalTaskListItems = accounttype;

    });
  }

  initializeAgGrid() {
    //this.gridOptions = <GridOptions>{
    //  pagination: true, enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true,
    //  toolPanelSuppressValues: true, enableCellChangeFlash: true, enableMultiRowDragging: true, rowDragManaged: true, animateRows: true
    //};

    this.gridOptions = <GridOptions>{
      enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000, toolPanelSuppressPivotMode: true,
      toolPanelSuppressValues: true, enableCellChangeFlash: true, enableMultiRowDragging: true,

    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeletecvComponent,
      //notesrenderer: NotesrendererComponent
    };
    if (this.ShowTaskGroupPage == true) {
      this.createTaskGroupColumnDefs();
    } else {
      this.createColumnDefs();
    }
    

  }

  createColumnDefs() {
    let selfObj = this;
    this.columnDefs = [
      {
        field: '#',
        headerName: '#',
        width: 62,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true
      },
      { field: 'SortOrder', headerName: 'Sort Order', hide: false, width: 90,},
      { field: 'tasktype', headerName: 'Description', hide: false},
      { field: 'category', headerName: 'Category', hide: false},
      { field: 'taskgroupName', headerName: 'Task Group', hide: false },
      { field: 'TimeRequired', headerName: 'Time', hide: false },
      {
        field: 'action', headerName: 'Action', hide: false, width: 90, pinned: 'right', cellRenderer: 'editdeletecv'}
     
    ];


  }

  createTaskGroupColumnDefs() {
    let selfObj = this;
    this.TaskGroupcolumnDefs = [
      {
        field: '#',
        headerName: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true,
      },
      { field: 'Sortorder', headerName: 'Sort Order', hide: false, width: 120},
      { field: 'TaskGroupName', headerName: 'Task Group', hide: false },
      { field: 'tasktype', headerName: 'Description', hide: true },
      {
        field: 'action', headerName: 'Action', hide: false, width: 90, pinned: 'right', cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:20%"/>';
          eDiv.addEventListener('click', function () {
            selfObj.deleteTaskGroup(params.data);
          });
          return eDiv;
        }}
      
    ];


  }

 
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      this.taskTypeObj = event.api.getSelectedNodes()[0].data;
      this.ShowTaskTypeFieldform = true;
    }
  }
  onTGSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      this.taskGroupObj = event.api.getSelectedNodes()[0].data;
      this.ShowTaskGroupFieldform = true;
    }
  }

  SaveTaskType() {
   
    this.customerService.saveDropdownValue(this.taskTypeObj).then(result => {
      this.getAllTaskListItems();
      this.taskTypeObj = new TaskType();
    });
   
  }

  DeleteTaskType(taskType) {
    this.taskTypeObj = taskType;
    this.taskTypeObj.isdeleted = 1;
    this.SaveTaskType();
  }
  editRowAg(rowdata) {
    this.taskTypeObj = rowdata;
    this.ShowTaskTypeFieldform = true;
  }
  deleteRowAg(taskType) {
    this.taskTypeObj = taskType;

    this.DeleteMessagePopup = true;
    this.applicationComponent.salesxcrmDialog('#DeleteMessagePopup .dialog');
    //setTimeout(function () {
    //  $('#DeleteMessagePopup .dialog').attr('style', 'width: 29%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    //}, 4);
   
  }
  ReceiveDeleteeventForMessage(msg) {
    if (msg == "Ok") {
      //this.userMessages = this.deleteRowDataMessage;
      this.taskTypeObj.isdeleted = 1;
      this.SaveTaskType();
    }
    this.DeleteMessagePopup = false;

  }
  ReceiveDeleteTaskGroup(msg) {
    if (msg == "Ok") {
      
      this.taskGroupObj.isdeleted = 1;

      this.customerService.addtaskgroup(this.taskGroupObj).then(result => {
        this.getTaskGroupItems();
        this.taskGroupObj = new Taskgroup();
        this.ShowTaskGroupFieldform = false;
      });
    }
    this.DeleteTaskGroup = false;

  }
  canceltaskList() {
    this.taskTypeObj = new TaskType();
  }
  ShowTaskGroupPages(val) {
    if (val == "TaskGroups") {
      this.taskGroupConmfigHomePage = false;
      this.ShowTaskGroupPage = true;
      this.ShowTaskGroupItemsPage = false;
      this.TaskTypeFieldform = this.getTaskTypeFieldsForm();
      this.getAlltaskGroups();
    } else if (val =='TaskGroupsItems') {
      this.taskGroupConmfigHomePage = false;
      this.ShowTaskGroupPage = false;
      this.ShowTaskGroupItemsPage = true;
      this.getTaskGroupItems();
    }

    var obj: AuditLogModel = { id: 0, type: 'Task List Configuration', action: 'Task List Config - ' + val, AccountOrContactId: 0, AccountOrContact: val, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Task List Config - ' + val, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }
  closePage(val) {
    if (val == "TaskGroups") {
      this.taskGroupConmfigHomePage = true;
      this.ShowTaskGroupPage = false;
      this.ShowTaskGroupItemsPage = false;
      this.ShowTaskGroupFieldform = false;
    } else if (val == 'TaskGroupsItems') {
      this.taskGroupConmfigHomePage = true;
      this.ShowTaskGroupPage = false;
      this.ShowTaskGroupItemsPage = false;
      this.ShowTaskTypeFieldform = false;
    }
  }

  SaveGroup() {
    
    this.taskGroupObj.addeduserid = this.currentuser.id;
    this.taskGroupObj.isdeleted = 0;
    this.taskGroupObj.tasktype = 'TaskList';
   
    this.customerService.addtaskgroup(this.taskGroupObj).then(result => {
      this.getTaskGroupItems();
      this.taskGroupObj = new Taskgroup();
      this.ShowTaskGroupFieldform = false;
    });
   
  }

  getAlltaskGroups() {
    this.initializeAgGrid();
    this.userservice.GetTaskGroupList("TaskList", 0, this.currentuser.id).then((taskgroups) => {
      this.taskgrouplist = taskgroups;
      
    });
  }

  deleteTaskGroup(val) {
    this.DeleteTaskGroup = true;
    this.taskGroupObj = val;
    this.applicationComponent.salesxcrmDialog('#DeleteTaskGroup .dialog');
    //setTimeout(function () {
    //  $('#DeleteTaskGroup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    //}, 4);
  }

  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
    
  }
  DisplayByTaskGroups(event) {
    this.TaskListItems = [];
    if (event.target.value != 'null') {
      this.TaskListItems = this.OriginalTaskListItems.filter(x => x.taskgroupid == event.target.value);
      if (this.SelectedCategory!=null) {
        this.TaskListItems = this.OriginalTaskListItems.filter(x => x.taskgroupid == event.target.value && x.category == this.SelectedCategory );
      }
    } else {
      this.TaskListItems = this.OriginalTaskListItems;
    }
    this.initializeAgGrid();
  }
  DisplayByCategory(event) {
    if (event.target.value != 'null') {
      this.TaskListItems = this.OriginalTaskListItems.filter(x => x.category == event.target.value);
      if (this.selectedGroup != null) {
        this.TaskListItems = this.OriginalTaskListItems.filter(x => x.category == event.target.value && x.taskgroupid == this.selectedGroup );
      }
    } else {
      this.TaskListItems = this.OriginalTaskListItems;
    }
  }
  dragStopped(event) {
   
  }

  onRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("TaskGroupItems");
  }

  onRowDragEndGroups(event: RowDragEndEvent) {
    this.saverowconfiguration("TaskGroups");
  }

  onAgGridReady(event, type) {
    if (type == 'TaskGroupItems') {
      this.gridOptions.api = event.api;
    } else {
      this.gridOptions.api = event.api;
    }

  }

  saverowconfiguration(type) {
    var sortoorder = [];
    if (type == 'TaskGroupItems') {
      this.gridOptions.api.forEachNode((node) => {
        sortoorder.push(node.data.id);
      });
    } else {
      this.gridOptions.api.forEachNode((node) => {
        sortoorder.push(node.data.id);
      });
    }


    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i == a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentuser.id;
    this.leadservice.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });
    });

  }

}
