import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { LeadsService } from 'src/app/services/leads.service';
import { Dropdowntype, Dropdown } from 'src/app/models/dropdowntype';
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../application.component';
declare var $: any;
import * as moment from 'moment';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { Projects } from '../../models/ProjectConfiguration';
import { Router } from '@angular/router';
import { TaskHistory } from '../../models/task-history';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { Opportunities } from '../../models/opportunities';
import { UserService } from '../../services/user.service';
import { ProposalsModel } from '../../models/Proposalsmodel';
import { SalesProcessHistory } from '../../models/sales-process-history';

@Component({
  selector: 'userdataheader',
  templateUrl: './userdataheader.component.html',
  styleUrls: ['./userdataheader.component.css']
})

export class UserdataheaderComponent implements OnInit {
  @Input() parentComp: string;
  @Input() selectedItems = [];
  dropdownSetting = {};
  SingledropdownSetting = {};
  SingleSecondDrpSetting = {};
  SingleThirdDrpSetting = {};
  SingleFourthDrpSetting = {};
  SingleFifthDrpSetting = {};
  SingleSixthDrpSetting = {};
  SingleSeventhDrpSetting = {};
  eightDrpSetting = {};
  dropdownList = [];
  currentuser: any;
  DisplayrowsList: Dropdowntype[] = [];
  pageSize = "1000";
  type: string = this.applicationComponent.pageViewed;
  calanderonoff: boolean = true;
  outProps: OutputPropsUserList = new OutputPropsUserList();
  secondDropdownList: Dropdowntype[] = [];
  thirdDropdownList: Dropdowntype[] = [];
  fourthDropdownList: Dropdowntype[] = [];
  fifthDropdownList: Dropdowntype[] = [];
  sixthDropdownList: Dropdowntype[] = [];
  SeventhDropdownList: Dropdowntype[] = [];
  eightDropdownList: Dropdowntype[] = [];
  MultiselectDropDownChanged: boolean = false;
  SaveUserListConfirmation: boolean = false;
  fourthDrpValue = 'Access(Login/Logout)'; Duplicatealltaskhistory: TaskHistory[];
  DupAllopportunities: Opportunities[];
  public startDatePickerOptions: IMyDpOptions = {
    dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false, dayLabels: { su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S' }
  };//dayLabels
  public startDateVal: any = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("MM-DD-YYYY")
  public startDatePlaceHolder: string = 'Start: ' + moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("MM-DD-YYYY");
  timezonevalue: string;
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false, dayLabels: { su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S' } };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;finalitems: any;
  public endDatePlaceHolder: string = 'End: ' + moment(new Date()).format("MM-DD-YYYY");
  Displaysize = [{ "id": 545, "itemName": 'Display All Rows', "category": 'Rows' }];
  SecondDrpModel = [];
  secondDrpText = "";
  ThirdDrpModel = [];
  thirdDrpText = "";
  FourthDrpModel = [];
  fourthDrpText = "";
  FifthDrpModel = [];
  fifthDrpText = "";
  SixthDrpModel = [];
  SeventhDrpModel = [];
  eightDrpModel = [];
  sixthDrpText = "";
  CheckBoxAppended: boolean = false
  @Output()
  outPropsEvent = new EventEmitter<OutputPropsUserList>();
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  DuplicateProjectsList: Projects[] = [];
  DupallLeads: SalesProcessHistory[];
  sendOutProps(outProps) {
    this.outPropsEvent.emit(outProps);
  }
  DropDownListBox: boolean = false;
  CalenderListView: boolean = false;
  isDisplayAccount: boolean = false;
  DupAllProposals: ProposalsModel[];
  constructor(private router: Router, private customerService: CustomerService, private LeadsService: LeadsService, public applicationComponent: ApplicationComponent, private opprtunityservice: OpprtunitiesService, private userService: UserService)
  {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {
      //this.getSelectedUserList(this.currentuser.id);
    }

  }
  txtPageSizeChanged(pageSize) {
    switch (pageSize) {
      case 'Display in 25 Rows': {
        this.outProps.pageSize = '25';
        break
      }
      case 'Display in 50 Rows': {
        this.outProps.pageSize = '50';
        break
      }
      case 'Display in 100 Rows': {
        this.outProps.pageSize = '100';
        break
      }
      case 'Display in 250 Rows': {
        this.outProps.pageSize = '250';
        break
      }
      case 'Display in 500 Rows': {
        this.outProps.pageSize = '500';
        break
      }
      case 'Display in 1000 Rows': {
        this.outProps.pageSize = '1000';
        break
      }case 'Display All Rows': {
        this.outProps.pageSize = '20000';
        break
      }
      default :{
        this.outProps.pageSize = pageSize;
        break;
      }
    }

    //this.outProps.pageSize = pageSize;
    this.outProps.pageSizeChanged = true;
    this.assignOutPropsAndEmitEvent();
  }
  dateChange(event) {
    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.outProps.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.outProps.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);

    this.applicationComponent.startVal = this.outProps.startDateVal;
    this.applicationComponent.endVal = this.outProps.endDateVal;
    this.applicationComponent.SearchstartVal = this.outProps.startDateVal;
    this.applicationComponent.SearchendVal = this.outProps.endDateVal;
    this.assignOutPropsAndEmitEvent();
  }
  AssignStartAndEndDate(event) {
    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.outProps.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.outProps.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);

  }
  calanderclick(val) {
    this.calanderonoff = val;
    if (this.calanderonoff == true) {
     
      this.startDateVal = this.applicationComponent.startVal == undefined ? (new Date().getFullYear()) + '-01-01' : this.applicationComponent.startVal;
      this.outProps.startDateVal = this.startDateVal;      
      this.startDatePlaceHolder= 'Start: 01-01-' + (new Date().getFullYear()).toString();
      this.endDateVal = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;
      this.outProps.endDateVal = this.endDateVal;
      this.endDatePlaceHolder = 'End: 12-31-' + new Date().getFullYear().toString() ;
      this.applicationComponent.SearchstartVal = moment(this.startDateVal).format("YYYY-MM-DD")
      this.applicationComponent.SearchendVal = moment(this.endDateVal).format("YYYY-MM-DD");
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    } else {
   
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
      var date = new Date();
      this.startDateVal = moment(new Date(date.getFullYear(), 1, 1)).format("YYYY-MM-DD");
      this.outProps.startDateVal = this.startDateVal;
      this.startDatePlaceHolder = 'Start: ' + moment(new Date(date.getFullYear(), date.getMonth(), 1)).format("MM-DD-YYYY");
      this.endDateVal = moment(new Date()).format("YYYY-MM-DD");
      this.outProps.endDateVal = this.endDateVal;
      this.endDatePlaceHolder = 'End: ' + moment(new Date()).format("MM-DD-YYYY");
      this.applicationComponent.SearchstartVal = moment(this.startDateVal).format("YYYY-MM-DD")
      this.applicationComponent.SearchendVal = moment(this.endDateVal).format("YYYY-MM-DD");
    }
    this.outProps.calanderonoff = val;
    this.outProps.pageSizeChanged = false;
    if (this.parentComp == "Reports" && this.outProps.fourthDrpValue == undefined) {
      this.outProps.fourthDrpValue = "Access(Login/Logout)";
    }

    this.assignOutPropsAndEmitEvent();

    
  }
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  Displaydata(SelectedVal) {
    if (SelectedVal == 'Accounts') {
      this.isDisplayAccount = false;
      this.router.navigateByUrl(`/application/account/Customer`);    
    }
    else {      
      this.router.navigateByUrl(`/application/customercontacts`);
      this.isDisplayAccount = true;
    }
  }
  SelectActivity(val) {
    if ( val != "0") {
      this.SingleSecondDrpSetting = {
        singleSelection: (this.parentComp == 'Projects') ? true : false,
        text: this.secondDrpText,
        disabled: (this.parentComp =='Projects')?false: true
      };
    } else {
      this.SingleSecondDrpSetting = {
        singleSelection: true,
        text: this.secondDrpText,
        disabled: false
      };
    }
    this.outProps.thirdDrpValue = val;
    this.outProps.pageSizeChanged = false;
    this.assignOutPropsAndEmitEvent();
  }
  SelectCustomerType(val) {
    this.outProps.custType = val;
    this.outProps.pageSizeChanged = false;
    this.assignOutPropsAndEmitEvent();
  }
  SelectedRows(val) {

  }
  SelectCustomer(str) {
    this.outProps.secondDrpValue = str;
    this.outProps.pageSizeChanged = false;
    this.assignOutPropsAndEmitEvent();
  }
  SelectReportType(str) {   
    this.fourthDrpValue = str;
    this.outProps.fourthDrpValue = str;
    this.outProps.pageSizeChanged = false;   
    this.fifthDropdownList = [];   
    if (str == "Proposal Items" || str == "Contacts(SaaS Renewals)" || str == "Customer Contacts (LK)" || str == "Contacts(SaaS RCM)") {    
        this.customerService.GetSpecialityList("SaasContactType", this.currentuser.id).then((Rows) => {
          this.fifthDropdownList = Rows;
          this.fifthDrpText = "Active Contacts";
          this.FifthDrpModel = [{ "id": 743, "itemName": "Active Contacts" }];
        });
      } else if (str == "Customer Accounts") {
        this.customerService.GetSpecialityList("Account Active or Inactive", this.currentuser.id).then((Rows) => {
          this.fifthDropdownList = Rows;
          this.fifthDrpText = "Active";
          this.FifthDrpModel = [{ "id": 1, "itemName": "Active" }];
        });
      } else if (str == "Customer Accounts Status") {
        this.customerService.GetSpecialityList("Account Status", this.currentuser.id).then((Rows) => {
          this.fifthDropdownList = Rows;
          this.fifthDrpText = "On Hold";
          this.FifthDrpModel = [{ "id": 15, "itemName": "On Hold" }];
        });
      }
    this.assignOutPropsAndEmitEvent();
  }

  SelectTaskListFilter(str) {
    this.outProps.fifthDrpValue = str;
    this.outProps.pageSizeChanged = false;
    this.assignOutPropsAndEmitEvent();
  }

  ngOnInit(): void {
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };

    if (this.parentComp === 'Accounts') {
      this.secondDrpText = "Do Not Include Customers";
      this.SecondDrpModel = [{ "id": 0, "itemName": 'Do Not Include Customers', "category": 'Non Customer', "isdeleted": 0, "taskgroupid": 0 }];
    }
    
    if (this.parentComp === 'CustContacts') {
      this.secondDrpText = "Active";
      this.SecondDrpModel = [{ "id": 1, "itemName": 'Active', "category": 'Customer', "isdeleted": 0, "taskgroupid": 0 }];
    }
    else if (this.parentComp === 'Tickets') {
      this.SeventhDropdownList = [];
      this.SeventhDropdownList.push({ "id": 0, "itemName": "Include CC", "category": 'cc', "isdeleted": 0, "taskgroupid": 0 });
      this.SeventhDropdownList.push({ "id": 1, "itemName": "Do No Include CC", "category": 'cc', "isdeleted": 0, "taskgroupid": 0 });     
      this.SeventhDrpModel = [{ "id": 1, "itemName": "Do No Include CC" }];

      this.eightDropdownList = [];
      this.eightDropdownList.push({ "id": 0, "itemName": "NO - Display Aging", "category": 'cc', "isdeleted": 0, "taskgroupid": 0 });
      this.eightDropdownList.push({ "id": 1, "itemName": "YES - Display Aging", "category": 'cc', "isdeleted": 0, "taskgroupid": 0 });
      this.eightDrpModel = [{ "id": 0, "itemName": "NO - Display Aging" }];
    }   
    else if (this.parentComp === 'Calendar') {
      this.secondDrpText = "All Tasks";
      this.SecondDrpModel = [{ "id": 566, "itemName": "All Tasks" }];
    } else if (this.parentComp === 'Ticket') {
      this.secondDrpText = "Display My Tickets First";
      this.SecondDrpModel = [{ "id": 571, "itemName": "Display My Tickets First" }];
    } else if (this.parentComp === 'CallList') {
      this.secondDrpText = "Open Call Lists";
      this.SecondDrpModel = [{ "id": 579, "itemName": "Open Call Lists" }];
    } else if (this.parentComp === 'rhx' || this.parentComp === 'crm') {
      this.calanderonoff = false;
      this.secondDrpText = "Todays History";
      this.SecondDrpModel = [{ "id": 575, "itemName": "Todays History" }];
    } else if (this.parentComp === 'Profile') {
      this.secondDrpText = "My Profile";
      this.SecondDrpModel = [{ "id": 583, "itemName": "My Profile" }];
    } else if (this.parentComp === 'Projects') {
      this.customerService.getAllProjects(this.currentuser.id).then(res => {
        this.DuplicateProjectsList = res;        
        this.applicationComponent.OpenProjects = this.DuplicateProjectsList.filter(x => x.ProjectStatusName == "Open").length;
       
        this.secondDrpText = "Open (" + this.applicationComponent.OpenProjects +")";
        this.SecondDrpModel = [{ "id": 666, "itemName": "Open (" + this.applicationComponent.OpenProjects +")" }];
      });
     
    } else if (this.parentComp === 'Campaigns') {
      this.secondDrpText = "Open Campaign";
      this.SecondDrpModel = [{ "id": 694, "itemName": "Open Campaign" }];
    }
   
    if (this.parentComp === 'Accounts' || this.parentComp === 'Customer') {
      this.fourthDrpText = "All Activity";
   
      this.FourthDrpModel = [{ "id": 567, "itemName": "All Activity" }];
      this.ThirdDrpModel = [{ "id": 0, "itemName": 'All Account Types', "category": 'All Account Types', "isdeleted": 0, "taskgroupid": 0 }];
  
      if (this.type !== 'Customer') {
        this.secondDrpText = "Active";
        this.SecondDrpModel = [{ "id": 1, "itemName": 'Active', "category": 'Non Customer', "isdeleted": 0, "taskgroupid": 0 }];
      } else {
        this.secondDrpText = "Active";
        this.SecondDrpModel = [{ "id": 1, "itemName": 'Active', "category": 'Customer', "isdeleted": 0, "taskgroupid": 0 }];
      }
    } else if (this.parentComp === 'Calendar') {
      this.thirdDrpText = "Current Month";
      this.ThirdDrpModel = [{ "id": 437, "itemName": "Current Month" }];
    } else if (this.parentComp === 'Ticket') {
      this.thirdDrpText = "Display My CC Tickets";
      this.ThirdDrpModel = [{ "id": 573, "itemName": "Display My CC Tickets" }];
    } else if (this.parentComp === 'Reports') {
      this.thirdDrpText = "Standard Reports";
      this.ThirdDrpModel = [{ "id": 600, "itemName": "Standard Reports" }];
    } else if (this.parentComp === 'AdminReports') {
      this.thirdDrpText = "Standard Reports";
      this.ThirdDrpModel = [{ "id": 601, "itemName": "Admin Reports" }];
    } else if (this.parentComp === 'Projects') {
      this.thirdDrpText = "All Project Types";
      this.ThirdDrpModel = [{ "id": 0, "itemName": "All Project Types" }];
    } else if (this.parentComp === 'Contact' || this.parentComp == 'CustContacts') {
      this.FourthDrpModel = [{ "id": 567, "itemName": "All Activity" }];
      this.thirdDrpText = "All Contact Types";
      this.ThirdDrpModel = [{ "id": 0, "itemName": 'All Contact Types', "category": 'All Contact Types', "isdeleted": 0, "taskgroupid": 0 }];
    } else if (this.parentComp === 'Proposal')
    {
      this.thirdDrpText = "Include Pended Proposals";
      this.ThirdDrpModel = [{ "id": 719, "itemName": "Include Pended Proposals" }];
      this.calanderonoff = false;
    }
    else if (this.parentComp === 'Forecasting' || this.parentComp === 'Opportunities') {
      this.thirdDrpText = "Include Pended Opportunities";
      this.ThirdDrpModel = [{ "id": 717, "itemName": "Include Pended Opportunities" }];
      this.calanderonoff = false;
    }
    if (this.parentComp === 'Reports') {
      this.fourthDrpText = "Select Report";
      this.FourthDrpModel = [{ "id": 0, "itemName": "Select Report" }];
    } else if (this.parentComp === 'AdminReports') {
      this.fourthDrpText = "Select Admin Reports";
      this.FourthDrpModel = [{ "id": 0, "itemName": "Select Admin Reports" }];
    } else if (this.parentComp === 'Projects') {
      this.fourthDrpText = "List View";
      this.FourthDrpModel = [{ "id": 664, "itemName": "List View" }];
    }

    if (this.parentComp === 'Accounts' || this.parentComp === 'Customer') {
      this.fifthDrpText = "All Shared Accounts";
      this.FifthDrpModel = [{ "id": 727, "itemName": "All Shared Accounts" }];
    } else if (this.parentComp === 'Contact' || this.parentComp === 'CustContacts') {
      this.fifthDrpText = "All Shared Contacts";
      this.FifthDrpModel = [{ "id": 729, "itemName": "All Shared Contacts" }];
    } else if (this.parentComp === 'AdminReports') {
      this.fifthDrpText = "Active Contacts";
      this.FifthDrpModel = [{ "id": 743, "itemName": "Active Contacts" }];
    }

    if (this.type === 'Customer' && this.parentComp !== 'CustContacts') {
      this.sixthDrpText = "Display Accounts";
      this.SixthDrpModel = [{ "id": 0, "itemName": "Display Accounts" }];
    } else if (this.parentComp === 'CustContacts') {
      this.sixthDrpText = "Display Contacts";
      this.SixthDrpModel = [{ "id": 0, "itemName": "Display Contacts" }];
    }
    
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, 1, 0, 0).then((Owner) => {
      this.dropdownList = Owner;
      this.applicationComponent.dropdownList = Owner;
      
    });
    //if (this.parentComp == 'Contact') {
    //  this.SingleSecondDrpSetting = {
    //    singleSelection: false,
    //    text: this.secondDrpText,
    //  };
    //} else {
      this.SingleSecondDrpSetting = {
        singleSelection: true,
        text: this.secondDrpText,
      };
    //}
    this.SingleThirdDrpSetting = {
      singleSelection: true,
      text: this.thirdDrpText
    };

    this.SingleFourthDrpSetting = {
      singleSelection: true,
      text: this.fourthDrpText
    };
    this.SingleFifthDrpSetting = {
      singleSelection: true,
      text: this.fifthDrpText
    };
    this.SingleSixthDrpSetting = {
      singleSelection: true,
      text: this.sixthDrpText
    };
    this.SingleSeventhDrpSetting = {
      singleSelection: true,
      text: this.sixthDrpText
    };
    this.eightDrpSetting = {
      singleSelection: true
    };
    this.LoadData();
    this.finalitems += $("#userids").val() + ',';
    let selfObj1 = this;

    $("#UserList .c-btn").click(function () {
      selfObj1.AddSuspendInactiveDiv();
      selfObj1.DropDownListBox = !selfObj1.DropDownListBox;
      if (selfObj1.DropDownListBox == true) {
        $("#UserList .dropdown-list").attr("hidden", false);
      } else {
        $("#UserList .dropdown-list").attr("hidden", true);
      }
    });
    
    $(document).ready(function () {
      $("#checkDiv #UserList .c-btn").click(function () {
        selfObj1.AddSuspendInactiveDiv();
        selfObj1.DropDownListBox = !selfObj1.DropDownListBox;
        if (selfObj1.DropDownListBox == true) {
          $("#UserList .dropdown-list").attr("hidden", false);
        } else {
          $("#UserList .dropdown-list").attr("hidden", true);
        }
      });
    });

    window.onclick = function (event) {
      var overlay = document.getElementsByClassName('col-md-12 divoverlay');
      if (event.target == overlay[0]) {
        selfObj1.DropDownListBox = false;
      } 

      var cbtn = document.getElementsByClassName('c-btn');
      var clist = document.getElementsByClassName('c-list');
      var cinput = document.getElementsByClassName('c-input ng-pristine ng-valid ng-star-inserted ng-touched');//done
      var selectall = document.getElementsByClassName('pure-checkbox select-all ng-star-inserted');// done
      var purecheckbox = document.getElementsByClassName('pure-checkbox ng-star-inserted selected-item');// done
      var lazyContainer = document.getElementsByClassName('dropdown-list animated fadeIn');
      var lazy = document.getElementsByClassName('lazyContainer');

      var SuspendInactiveDiv = document.getElementById('SuspendInactiveDiv');
      var Inactive = document.getElementById('Inactive');
      var suspended = document.getElementById('suspended');
      var MultiUserSave = document.getElementById('MultiUserSave');
      if (event.target == clist || event.target == Inactive || event.target == suspended || event.target == cbtn || event.srcElement == cinput[0] || event.srcElement == selectall[1] || event.srcElement == purecheckbox[1] || (event.target.parentNode && lazy[1] != undefined && (event.target.parentNode.className == lazy[1].className)) || event.target == MultiUserSave || event.target == SuspendInactiveDiv || event.target == lazyContainer) {
        
      } else {
        if (selfObj1.MultiselectDropDownChanged == true) {
          selfObj1.SaveUserListConfirmation = true;
          selfObj1.SaveUserListConfirmationCss();
          $("#UserList .dropdown-list").attr("hidden", false);
        }
      }

    }

  }
  AddSuspendInactiveDiv() {
    if (this.CheckBoxAppended != true) {
      var mydiv = document.getElementsByClassName("pure-checkbox select-all ng-star-inserted");
      var Headerdiv = document.createElement('div');
      Headerdiv.id = "Header";
      Headerdiv.className = "col-xs-12 UserListHeader";
      mydiv[1].parentNode.insertBefore(Headerdiv, mydiv[1].previousSibling);

      var UserListlabel = document.createElement('label'); // creating label for checkbox 
      UserListlabel.htmlFor = "UserList"; // assigning attributes for
      UserListlabel.id = "UserList";
      
      UserListlabel.appendChild(document.createTextNode(' User List   '));
      $('#Header').append(UserListlabel);
      ////////////////////////////////////////////////////////////////////////////////////////////////

      var ButtonsDiv = document.createElement('div');
      ButtonsDiv.id = "ButtonsDiv";
      ButtonsDiv.className = "col-xs-12 UserListButtons";
      mydiv[1].parentNode.insertBefore(ButtonsDiv, mydiv[1].previousSibling);

      var cancelDiv = document.createElement('div');
      cancelDiv.id = "cancelDiv";
      cancelDiv.className = "col-xs-6";
      $('#ButtonsDiv').append(cancelDiv);

      var SaveDiv = document.createElement('div');
      SaveDiv.id = "SaveDiv";
      SaveDiv.className = "col-xs-6";
      $('#ButtonsDiv').append(SaveDiv);

      var CancelButton = document.createElement('button');
      CancelButton.textContent = 'Cancel'; CancelButton.className = 'MultiselectCancelButton'; CancelButton.value = "Cancel"; CancelButton.title = 'Cancel'; CancelButton.id = "MultiUserCancel"
      $('#cancelDiv').append(CancelButton);

      var SaveChanges = document.createElement('button');
      SaveChanges.textContent = 'Save'; SaveChanges.className = 'MultiselectSaveButton'; SaveChanges.value = "Save"; SaveChanges.title = 'Save'; SaveChanges.id = "MultiUserSave"
      $('#SaveDiv').append(SaveChanges);
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


      var SuspendInactiveDiv = document.createElement('div');
      SuspendInactiveDiv.id = "SuspendInactiveDiv";
      SuspendInactiveDiv.className = "col-xs-12 Banoos";
      mydiv[1].parentNode.insertBefore(SuspendInactiveDiv, mydiv[1].previousSibling);


      var ActiveDiv = document.createElement('div');
      ActiveDiv.id = "ActiveDiv";
      ActiveDiv.className = "col-xs-3";
      $('#SuspendInactiveDiv').append(ActiveDiv);

      var inActiveDiv = document.createElement('div'); // creating checkbox element
      inActiveDiv.id = "inActiveDiv";
      inActiveDiv.className = "col-xs-4";
      $('#SuspendInactiveDiv').append(inActiveDiv);

      var suspendDiv = document.createElement('div'); // creating checkbox element
      suspendDiv.id = "suspendDiv";
      suspendDiv.className = "col-xs-5";
      $('#SuspendInactiveDiv').append(suspendDiv);


      var ActiveCheckBox = document.createElement('input'); // creating checkbox element 
      ActiveCheckBox.type = "checkbox"; // Assigning the attributes 
      ActiveCheckBox.name = "Active";
      ActiveCheckBox.value = "Active";
      ActiveCheckBox.checked = true;
      ActiveCheckBox.id = "Active";
      var ActiveLabel = document.createElement('label'); // creating label for checkbox 
      ActiveLabel.htmlFor = "Active"; // assigning attributes for
      ActiveLabel.id = "ActiveLabel";
      ActiveLabel.appendChild(document.createTextNode(' Active   '));  // appending the created text to  

      $('#ActiveDiv').append(ActiveCheckBox);
      $('#ActiveDiv').append(ActiveLabel);


      var InactiveCheckbox = document.createElement('input'); // creating checkbox element 
      InactiveCheckbox.type = "checkbox"; // Assigning the attributes 
      InactiveCheckbox.name = "Inactive";
      InactiveCheckbox.value = "Inactive";
      InactiveCheckbox.id = "Inactive";
      var InactiveLabel = document.createElement('label'); // creating label for checkbox 
      InactiveLabel.htmlFor = "Inactive"; // assigning attributes for
      InactiveLabel.id = "InactiveLabel";
      InactiveLabel.appendChild(document.createTextNode(' Inactive   '));  // appending the created text to  

      $('#inActiveDiv').append(InactiveCheckbox);
      $('#inActiveDiv').append(InactiveLabel);


      var SuspendedCheckbox = document.createElement('input'); // creating checkbox element 
      SuspendedCheckbox.type = "checkbox"; // Assigning the attributes 
      SuspendedCheckbox.name = "suspended";
      SuspendedCheckbox.value = "suspended";
      SuspendedCheckbox.id = "suspended";
      var SuspendedLabel = document.createElement('label'); // creating label for checkbox 
      SuspendedLabel.htmlFor = "suspended"; // assigning attributes for
      SuspendedLabel.id = 'suspendedLabel';
      SuspendedLabel.appendChild(document.createTextNode(' Suspended'));  // appending the created text to  

      $('#suspendDiv').append(SuspendedCheckbox);
      $('#suspendDiv').append(SuspendedLabel);
      this.CheckBoxAppended = true;
      $('#cancelDiv').attr('style', 'display:none;');
      $('#SaveDiv').attr('style', 'display:none;');
      $('#ActiveDiv').attr('style', 'display:flex;width:30%');
      $('#inActiveDiv').attr('style', 'padding:0;display:flex;width:30%');
      $('#suspendDiv').attr('style', 'padding:0;;display:flex;width:40%');

      $('#ActiveLabel').attr('style', 'margin-left:4%');
      $('#InactiveLabel').attr('style', 'margin-left:4%');
      $('#suspendedLabel').attr('style', 'margin-left:4%');
    }
      let self = this;
      document.getElementById('Active').onclick = function inactiveclick() {
        self.GetAllaccesusersListbyuser();
      }

      document.getElementById('Inactive').onclick = function inactiveclick() {
        self.GetAllaccesusersListbyuser();
      }

      document.getElementById('suspended').onclick = function inactiveclick() {
        self.GetAllaccesusersListbyuser();
      }

      document.getElementById('MultiUserSave').onclick = function MultiUserSaveclick() {
        self.MultiUserSaveclicked();

    }
    document.getElementById('MultiUserCancel').onclick = function MultiUserSaveclick() {
      self.MultiUserCancelClicked();

    }
  }




  MultiUserCancelClicked() {
    $("#UserList .dropdown-list").attr("hidden", true);

    this.MultiselectDropDownChanged = false;
    this.SaveUserListConfirmation = false;
    this.DropDownListBox = false;
    this.applicationComponent.selectedItems = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));

    }
    this.SaveConfirm('No');
  }
  MultiUserSaveclicked() {
    localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
   
      this.MultiselectDropDownChanged = false;
    this.SaveUserListConfirmation = false;
    this.DropDownListBox = false;
      $("#UserList .dropdown-list").attr("hidden", true);
    //});
  }

  getSelectedUserList(val) {
    //this.customerService.getSelectedUserList(val).then(res => {
    //  if (res.length > 0) {
    //    this.applicationComponent.selectedItems = res
    //  } else {
        this.applicationComponent.selectedItems = [
          { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
        ];
        $("#userids").val(this.currentuser.id);
    //  }
      
    //});
  }
  

  
  GetAllaccesusersListbyuser() {
    var active = $('#Active').prop("checked") ? 1 : 0;
    var inactive = $('#Inactive').prop("checked") ? 1 : 0;
    var suspended = $('#suspended').prop("checked") ? 1 : 0;
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, active, inactive, suspended).then((Owner) => {
      this.dropdownList = Owner;
      this.applicationComponent.dropdownList = Owner;
      
    });
  }
  LoadData() {

    if (this.parentComp === 'Accounts' || this.parentComp === 'Customer' || this.parentComp === 'Contact' || this.parentComp === 'CustContacts') {
      this.customerService.GetSpecialityList("Account Active or Inactive", this.currentuser.id).then((Rows) => {
        this.secondDropdownList = [];
        Rows.forEach(ele => {
          this.secondDropdownList.push(ele);
        });
          if (this.parentComp === 'Contact') {
            this.secondDrpText = this.secondDropdownList[0].itemName;
            this.SecondDrpModel = [{ "id": this.secondDropdownList[0].id, "itemName": this.secondDropdownList[0].itemName, "category": this.secondDropdownList[0].category, "isdeleted": 0, "taskgroupid": 0 }];
          }
      });
    } else if (this.parentComp === 'Lead') {    
        this.customerService.GetSpecialityList("LeadType", this.currentuser.id).then((Rows) => {       
          
       
          this.LeadsService.GetAllLeadsList("All", $("#userids").val(), '2018-01-01', this.endDateVal, "null", 1, 1000, this.currentuser.id).then((res) => {
            this.DupallLeads = res;
          
            this.applicationComponent.AllLeadCount = this.DupallLeads.length;
            this.applicationComponent.OpenLeadCount = this.DupallLeads.filter(x => x.leadstatus == 'Open').length;
            this.applicationComponent.ClosedLeadCount = this.DupallLeads.filter(x => (x.leadstatus == 'Closed' || x.leadstatus == 'Completed')).length;
            this.secondDropdownList = [];
            Rows.forEach(row => {
              this.secondDropdownList.push({
                "id": row.id,
                "itemName": row.itemName + "(" + (row.itemName.startsWith("Open")? this.applicationComponent.OpenLeadCount : row.itemName.startsWith("Closed") ? this.applicationComponent.ClosedLeadCount : this.applicationComponent.AllLeadCount)+ ")",
                "category": row.category
                , "isdeleted": 0, "taskgroupid": row.taskgroupid
              });
            });
            this.secondDrpText = " Open Leads(" + this.applicationComponent.OpenLeadCount.toString() + ")";
            this.SecondDrpModel = [{ "id": 558, "itemName": " Open Leads(" + this.applicationComponent.OpenLeadCount.toString() + ")" }];
          });
          
        });
    } else if (this.parentComp === 'Proposal') {
      this.customerService.GetSpecialityList("ProposalType", this.currentuser.id).then((Rows) => {        
        
        this.userService.GetAllProposals($("#userids").val(), "All Proposals", 1, (this.calanderonoff) ? this.applicationComponent.startVal : '2018-01-01', this.applicationComponent.endVal).then(res => {
          this.DupAllProposals = res;        
          this.applicationComponent.OpenPropCount = this.DupAllProposals.filter(x => x.status == '108').length;
          this.applicationComponent.ClosedPropCount = this.DupAllProposals.filter(x => x.status == '110' || x.status == '109').length;
          this.applicationComponent.AllPropCount = this.DupAllProposals.length;
          this.secondDropdownList = [];
          Rows.forEach(row => {
            this.secondDropdownList.push({
              "id": row.id,
              "itemName": row.itemName + "(" + ((row.itemName.startsWith("Open")) ? this.applicationComponent.OpenPropCount : (row.itemName.startsWith("Closed")) ? this.applicationComponent.ClosedPropCount : (row.itemName.startsWith("All")) ? this.applicationComponent.AllPropCount : this.applicationComponent.PendedPropCount) + ")",
              "category": row.category
              , "isdeleted": 0, "taskgroupid": row.taskgroupid
            });
          });
          this.secondDrpText = " Open Proposals(" + this.applicationComponent.OpenPropCount.toString() + ")";
          this.SecondDrpModel = [{ "id": 558, "itemName": " Open Proposals(" + this.applicationComponent.OpenPropCount.toString() + ")" }];
        });
      });
      this.customerService.GetSpecialityList("Proposal", this.currentuser.id).then((Rows) => {
        this.thirdDropdownList = Rows;
      });
    } else if (this.parentComp === 'Opportunities') {
      this.customerService.GetSpecialityList("OpportunitiesType", this.currentuser.id).then((Rows) => {
      
        this.opprtunityservice.GetAllOpportunitiesList("All Opportunities", $("#userids").val(), '2018-01-01', this.endDateVal, 'null', this.currentuser.id, true).then((res) => {
          this.DupAllopportunities = res;
          this.applicationComponent.OpenOpporCount = this.DupAllopportunities.filter(x => x.status == '108').length;
          this.applicationComponent.LostOpporCount = this.DupAllopportunities.filter(x => x.status == '110').length;
          this.applicationComponent.PendedOpporCount = this.DupAllopportunities.filter(x => x.isPended=='1').length;
          this.applicationComponent.WonOpporCount = this.DupAllopportunities.filter(x => x.status == '109').length;
          this.applicationComponent.AllOpporCount = this.DupAllopportunities.length;
          this.secondDropdownList = [];
          Rows.forEach(row => {
            this.secondDropdownList.push({
              "id": row.id,
              "itemName": row.itemName + "(" + ((row.itemName.startsWith("Open")) ? this.applicationComponent.OpenOpporCount : (row.itemName.startsWith("Lost")) ? this.applicationComponent.LostOpporCount : (row.itemName.startsWith("All")) ? this.applicationComponent.AllOpporCount : (row.itemName.startsWith("Pended")) ? this.applicationComponent.PendedOpporCount : this.applicationComponent.WonOpporCount) + ")",
              "category": row.category
              , "isdeleted": 0, "taskgroupid": row.taskgroupid
            });
          });
          this.secondDrpText = " Open Opportunities(" + this.applicationComponent.OpenOpporCount.toString() + ")";
          this.SecondDrpModel = [{ "id": 561, "itemName": " Open Opportunities(" + this.applicationComponent.OpenOpporCount.toString() + ")" }];
        });
      });
      this.customerService.GetSpecialityList("Forecast", this.currentuser.id).then((Rows) => {
        this.thirdDropdownList = Rows;
      });
    } else if (this.parentComp === 'Forecasting') {
      this.customerService.GetSpecialityList("Forecast", this.currentuser.id).then((Rows) => {
        this.thirdDropdownList = Rows;
      });
    } else if (this.parentComp === 'TaskList' || this.parentComp === 'crm' || this.parentComp === 'Calendar') {
      this.calanderonoff = false;
      this.customerService.GetSpecialityList("TaskListType", this.currentuser.id).then((Rows) => {     
        
        this.LeadsService.GetAllTaskHistory("All Tasks", $("#userids").val(), '2018-01-01', this.endDateVal, 'null', 'tasklist', this.currentuser.timezone).then((res) => {
          this.Duplicatealltaskhistory = res;
          this.applicationComponent.OpenTaskCount = this.Duplicatealltaskhistory.filter(x => x.status.startsWith("Open")).length;
          this.applicationComponent.NotCompletedTaskCount = this.Duplicatealltaskhistory.filter(x => x.status.startsWith("Not")).length;
          this.applicationComponent.CompletedTaskcount = this.Duplicatealltaskhistory.filter(x => x.status.startsWith("Complete")).length;
          this.applicationComponent.Alltaskcount = this.Duplicatealltaskhistory.length;
          this.secondDropdownList = [];
          Rows.forEach(row => {
            this.secondDropdownList.push({
              "id": row.id,
              "itemName": row.itemName + "(" + ((row.itemName.startsWith("Open")) ? this.applicationComponent.OpenTaskCount : (row.itemName.startsWith("Completed")) ? this.applicationComponent.CompletedTaskcount : (row.itemName.startsWith("Not")) ? this.applicationComponent.NotCompletedTaskCount : (row.itemName.startsWith("All")) ? this.applicationComponent.Alltaskcount : this.applicationComponent.Alltaskcount) + ")",
              "category": row.category
              , "isdeleted": 0, "taskgroupid": row.taskgroupid
            });
          });
          this.secondDrpText = "Open Tasks(" + this.applicationComponent.OpenTaskCount.toString() + ")";
          this.SecondDrpModel = [{ "id": 564, "itemName": "Open Tasks (" + this.applicationComponent.OpenTaskCount.toString() + ")" }];
        });
       
      });
    } else if (this.parentComp === 'Ticket') {
      this.customerService.GetSpecialityList("TicketFilter", this.currentuser.id).then((Rows) => {
        this.secondDropdownList = Rows;
      });
    } else if (this.parentComp === 'CallList') {
      this.customerService.GetSpecialityList("CallListStatus", this.currentuser.id).then((Rows) => {
        this.secondDropdownList = Rows;
      });      
    } else if (this.parentComp === 'rhx') {
      this.customerService.GetSpecialityList("RecentHistoryFilter", this.currentuser.id).then((Rows) => {
        this.secondDropdownList = Rows;
      });      
    } else if (this.parentComp === 'Profile') {
      this.customerService.GetSpecialityList("ProfileViewTypes", this.currentuser.id).then((Rows) => {
        this.secondDropdownList = Rows;
      });      
    } else if (this.parentComp === 'Projects' || this.parentComp === 'ViewProject') {
      this.customerService.GetSpecialityList("Project Status", this.currentuser.id).then((Rows) => {
        
        Rows.forEach(row => {
          this.customerService.getAllProjects(this.currentuser.id).then(res => {
            this.DuplicateProjectsList = res;
            this.applicationComponent.OpenProjects = this.DuplicateProjectsList.filter(x => x.ProjectStatusName == "Open").length;
            this.applicationComponent.PendedProjects = this.DuplicateProjectsList.filter(x => x.ProjectStatusName == "Pended").length;
            this.applicationComponent.CancelledProjects = this.DuplicateProjectsList.filter(x => x.ProjectStatusName == "Cancelled").length;
            this.applicationComponent.CompleteProjects = this.DuplicateProjectsList.filter(x => x.ProjectStatusName == "Complete").length;
            this.applicationComponent.AllProjects = this.DuplicateProjectsList.length;
            this.secondDropdownList = [];
            this.secondDropdownList.push({
              "id": row.id,
              "itemName": row.itemName + "(" + ((row.itemName.trim() == "Open") ? this.applicationComponent.OpenProjects : (row.itemName.trim() == "Pended") ? this.applicationComponent.PendedProjects : (row.itemName.trim() == "Cancelled") ? this.applicationComponent.CancelledProjects : (row.itemName.trim() == "Complete") ? this.applicationComponent.CompleteProjects : this.applicationComponent.AllProjects) + ")",
              "category": row.category
              , "isdeleted": 0, "taskgroupid": row.taskgroupid
            });
            if (localStorage.getItem('projecttype') != null) {

              if (localStorage.getItem('projecttype') == "Pended") {
                this.secondDrpText = "Pended(" + this.applicationComponent.PendedProjects.toString() + ")";
                this.SecondDrpModel = [{ "id": 3, "itemName": "Pended(" + this.applicationComponent.PendedProjects.toString() + ")" }];
                this.outProps.secondDrpValue = "Pended";
              }
              else if (localStorage.getItem('projecttype') == "Cancelled") {
                this.secondDrpText = "Cancelled(" + this.applicationComponent.CancelledProjects.toString() + ")";
                this.SecondDrpModel = [{ "id": 3, "itemName": "Cancelled(" + this.applicationComponent.CancelledProjects.toString() + ")" }];
                this.outProps.secondDrpValue = "Cancelled";
              }
              else if (localStorage.getItem('projecttype') == "Complete") {
                this.secondDrpText = "Complete(" + this.applicationComponent.CompleteProjects.toString() + ")";
                this.SecondDrpModel = [{ "id": 3, "itemName": "Complete(" + this.applicationComponent.CompleteProjects.toString() + ")" }];
                this.outProps.secondDrpValue = "Complete";
              }

            }
            this.outProps.pageSizeChanged = false;
            this.assignOutPropsAndEmitEvent();
          });

        });
      });
       
    } else if (this.parentComp === 'Campaigns') {
      this.customerService.GetSpecialityList("CampaignStatus", this.currentuser.id).then((Rows) => {
        this.secondDropdownList = Rows;
      });      
    }
    else if (this.parentComp === 'Tickets') {
      var cc = (this.SeventhDrpModel.map(x => x.id)[0] == 1) ? false : true;
      this.customerService.GetTicketsList('All Tickets', "My Tickets Only", this.currentuser.id, "None", cc, "null").then((ticketscount) => {
        this.applicationComponent.OpenTicketCount = ticketscount.filter(p => p.TicketStatus == 1).length;

        this.applicationComponent.OnHoldTicketCount = ticketscount.filter(p => p.TicketStatus == 2).length;
        this.applicationComponent.PendedTicketCount = ticketscount.filter(p => p.TicketStatus == 3).length;
        this.applicationComponent.ClosedTicketCount = ticketscount.filter(p => p.TicketStatus == 4).length + ticketscount.filter(p => p.TicketStatus == 5).length;

        this.applicationComponent.AllTicketCount = ticketscount.length;

       
        this.secondDropdownList = [];
      this.secondDropdownList.push({ "id": 0, "itemName": "--Select--", "category": 'All Account Types', "isdeleted": 0, "taskgroupid": 0 });
        this.secondDropdownList.push({ "id": 1, "itemName": " Open (" + this.applicationComponent.OpenTicketCount.toString() + ")", "category": 'All Account Types', "isdeleted": 0, "taskgroupid": 0 });

        this.secondDropdownList.push({ "id": 2, "itemName": "Pended (" + this.applicationComponent.PendedTicketCount.toString() + ")", "category": 'All Account Types', "isdeleted": 0, "taskgroupid": 0 });

        this.secondDropdownList.push({ "id": 3, "itemName": "Closed (" + this.applicationComponent.ClosedTicketCount.toString() + ")", "category": 'All Account Types', "isdeleted": 0, "taskgroupid": 0 });

        this.secondDropdownList.push({ "id": 4, "itemName": "On - Hold (" + this.applicationComponent.OnHoldTicketCount.toString() + ")", "category": 'All Account Types', "isdeleted": 0, "taskgroupid": 0 });

        this.secondDropdownList.push({ "id": 5, "itemName": "Deleted (" + this.applicationComponent.DeleteTicketCount.toString() + ")", "category": 'All Account Types', "isdeleted": 0, "taskgroupid": 0 });

        this.secondDrpText = "Open (" + this.applicationComponent.OpenTicketCount.toString() + ")";
        this.SecondDrpModel = [{ "id": 1, "itemName": "Open (" + this.applicationComponent.OpenTicketCount.toString() + ")" }];
          
      });
     
    }
    if (this.parentComp === 'Accounts' || this.parentComp === 'Customer') {
   
      this.customerService.GetSpecialityList("Account Type", this.currentuser.id).then((Rows) => {     
        this.thirdDropdownList = Rows;      
      });

    } else if (this.parentComp === 'Calendar') {
      this.customerService.GetSpecialityList("calenderView", this.currentuser.id).then((Rows) => {
        this.thirdDropdownList = Rows;
      });     
    } else if (this.parentComp === 'Ticket') {
      this.customerService.GetSpecialityList("TicketCCFilter", this.currentuser.id).then((Rows) => {
        this.thirdDropdownList = Rows;
      });     
    } else if (this.parentComp === 'Reports' || this.parentComp === 'AdminReports') {
      this.customerService.GetSpecialityList("Reports", this.currentuser.id).then((Rows) => {
        this.thirdDropdownList = Rows;
      });
    } else if (this.parentComp === 'Projects') {
      this.customerService.GetProjectFields(this.currentuser.id).then(result => {       
        result.forEach(ele => {
          if (ele.DataType == 'Pick List' || ele.DataType == 'System Lookup') {

            if (ele.ColumnName == "ProjectType") {
              this.thirdDropdownList.push({ "id": 0, "itemName":'All Project Types', "category": 'Project Type', "isdeleted": 0, "taskgroupid": 0 })
              this.customerService.GetProjectPickListData(ele.ProjectFieldId, ele.FieldName, this.currentuser.id).then((accounttype) => {
                accounttype.forEach(data => {
                  this.thirdDropdownList.push({ "id": data.DataId, "itemName": data.itemName, "category": 'Project Type', "isdeleted": 0, "taskgroupid": 0 })
                });
              });
            }
          }
        })
      });
     
    } else if (this.parentComp === 'Contact' || (this.parentComp == 'CustContacts')) {
      this.customerService.GetSpecialityList("Contact Type", this.currentuser.id).then((Rows) => {
        this.thirdDropdownList = Rows;
        //this.thirdDropdownList.push({ "id": 0, "itemName": 'All Contact Types', "category": 'All Contact Types', "isdeleted": 0, "taskgroupid": 0 })
        //Rows.forEach(ele => {
        //  this.thirdDropdownList.push(ele);
        //});
      });
    }

    if (this.parentComp === 'Reports') {
      this.customerService.GetSpecialityList("ReportType", this.currentuser.id).then((Rows) => {
        this.fourthDropdownList = Rows.sort((a, b) => a.itemName > b.itemName ? 1 : -1);
        this.fourthDropdownList = this.fourthDropdownList.filter(x => x.itemName!="Audit Log" && !x.itemName.includes("User Access")  && !x.itemName.includes("Users Logged In")
          && !x.itemName.includes("Proposal Items") && !x.itemName.includes("Contacts(SaaS Renewals)") && !x.itemName.includes("Contacts(SaaS RCM)")
          && !x.itemName.includes("Customer Accounts") && !x.itemName.includes("Customer Accounts Status") && !x.itemName.includes("Customer Contacts (LK)"));
      });

      this.customerService.GetSpecialityList("Task List Sort By", this.currentuser.id).then((Rows) => {
        this.fifthDropdownList = Rows;
      });

    } else if (this.parentComp === 'AdminReports') {
     
      this.customerService.GetSpecialityList("ReportType", this.currentuser.id).then((Rows) => {
        this.fourthDropdownList = Rows;
        this.fourthDropdownList = Rows.sort((a, b) => a.itemName > b.itemName ? 1 : -1);
        this.fourthDropdownList = this.fourthDropdownList.filter(x => x.itemName == "Audit Log" || x.itemName == "User Access" || x.itemName == "Users Logged In"
          || x.itemName == "Proposal Items" || x.itemName == "Contacts(SaaS Renewals)" || x.itemName == "Contacts(SaaS RCM)"
          || x.itemName == "Customer Accounts" || x.itemName == "Customer Accounts Status" || x.itemName == "Customer Contacts (LK)");
    
      });   
        this.customerService.GetSpecialityList("SaasContactType", this.currentuser.id).then((Rows) => {
          this.fifthDropdownList = Rows;
        });
     
    }
   
    else if (this.parentComp === 'Accounts' || this.parentComp === 'Customer' || this.parentComp == 'Contact' || (this.parentComp == 'CustContacts')) {
      
      this.customerService.GetSpecialityList("ActivityRange", this.currentuser.id).then((Rows) => {
        this.fourthDropdownList = Rows;
      });
    }

    if (this.parentComp === 'Accounts' || this.parentComp === 'Customer') {
      this.customerService.GetSpecialityList("AccountShared", this.currentuser.id).then((Rows) => {
        this.fifthDropdownList = Rows;
      });
    } else if (this.parentComp === 'Contact' || this.parentComp === 'CustContacts') {
      this.customerService.GetSpecialityList("ContactShared", this.currentuser.id).then((Rows) => {
        this.fifthDropdownList = Rows;
     
      });
    }

    if (this.type === 'Customer' && this.parentComp !== 'CustContacts') {
      this.sixthDropdownList.push({ id: 0, itemName: 'Display Accounts', category: 'Display account', isdeleted: 0, taskgroupid: 0});
      this.sixthDropdownList.push({ id: 1, itemName: 'Display Contacts', category: 'Display contact', isdeleted: 0, taskgroupid: 0 });
    } else if (this.parentComp === 'CustContacts') {
      this.sixthDropdownList.push({ id: 0, itemName: 'Display Contacts', category: 'Display contact', isdeleted: 0, taskgroupid: 0 });
      this.sixthDropdownList.push({ id: 1, itemName: 'Display Accounts', category: 'Display account', isdeleted: 0, taskgroupid: 0 });
    }

      this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {

      this.DisplayrowsList = Rows;
    });
  }

  onItemDisplayrowSelect(item: any) {
    this.txtPageSizeChanged(item.value);
  }
  OnItemDisplayrowDeSelect(item: any) {
    this.txtPageSizeChanged(item.value);
  }

  setDropdownText(val, first_one) {
    
   // $('#SecondDrp .c-btn').css('display', 'inline-block');
    return val;
    ////var css = "margin-bottom:1px;width: 125px;width: 125px;list - style: none;padding: 2px 22px 2px 8px;border - radius: 2px;margin - right: 4px;float: left;position: relative;";
    ////if (first_one == 0) {
    //var css = "margin-top: -6px;";
    ////}

    //return '<div class="c-token ng-star-inserted"><span class="c-label ng-star-inserted"  style="padding:2px;font-size:0.90em !important;"> ' + val + ' </span></div><br>';

  }
  onSecondDrpItemSelect(item: any) {
    if ((this.parentComp == 'Accounts' || this.parentComp == 'Customer' || this.parentComp == 'Contact' || this.parentComp == 'CustContacts' || this.parentComp == 'Calendar')
      && (this.SecondDrpModel && this.SecondDrpModel.length > 0)) {
      if (this.parentComp == 'Accounts' || this.parentComp == 'Customer' || this.parentComp == 'Contact' || this.parentComp == 'CustContacts' || this.parentComp == 'Calendar') {
        var values = this.SecondDrpModel.map(({ id }) => id).join(',').split(',');
        if (values.includes("0") && values.includes("1") && values.includes("2")) {
          $('#SecondDrp .c-btn').html('Display All');
          this.SelectCustomer('0,1,2');
        } else {
          var names = this.SecondDrpModel.map(({ itemName }) => itemName);
          var Ids = this.SecondDrpModel.map(({ id }) => id);
          //var content = "";
          //for (var i = 0; i < names.length; i++) {
          //  var index = i;
          //  if (names.length == 1) {
          //    index = 5;
          //  }
          //  content += this.setDropdownText(names[i], index);
          //}
          //$('#SecondDrp .c-btn').html(content);
          if (this.parentComp == 'Calendar') { this.SelectCustomer(names.toString().split('(')[0]); } else {
            this.SelectCustomer(Ids.join(','));
          }
        }

      
      }
      else {
        this.SelectCustomer(this.SecondDrpModel.map(({ id }) => id).join(','));
      }
    } else if (this.parentComp == 'Tickets') {
      this.outProps.secondDrpValue = item.id;
      this.outProps.pageSizeChanged = false;
      this.assignOutPropsAndEmitEvent();
    } 
    else {
      if (this.parentComp == 'Lead' || this.parentComp == 'Proposal' ||this.parentComp == 'Projects' || this.parentComp == 'ViewProject' || this.parentComp == 'TaskList' || this.parentComp == 'Opportunities' ) {
        this.SelectCustomer(item.itemName.split('(')[0]);
      } else {
        this.SelectCustomer(item.value);
      }
    }

    
  }
  OnSecondDrpItemDeSelect(item: any) {
    if ((this.parentComp == 'Customer' || this.parentComp == 'Accounts' || this.parentComp == 'Contact' || this.parentComp == 'CustContacts') && (this.SecondDrpModel && this.SecondDrpModel.length > 0)) {
      if (this.parentComp == 'Customer' ||this.parentComp == 'Accounts' || this.parentComp == 'Contact' || this.parentComp == 'CustContacts') {
        var values = this.SecondDrpModel.map(({ id }) => id).join(',').split(',');
        if (values.includes("0") && values.includes("1") && values.includes("2")) {
          this.secondDrpText = this.setDropdownText('Display All', 'no');
          this.SelectCustomer('0,1,2');
        } else {
          var names = this.SecondDrpModel.map(({ itemName }) => itemName);
          var content = "";
          for (var i = 0; i < names.length; i++) {
            var index = i;
            if (names.length == 1) {
              index = 5;
            }
            content += this.setDropdownText(names[i], index);
          }
          $('#SecondDrp .c-btn').html(content);

          this.SelectCustomer(this.SecondDrpModel.map(({ id }) => id).join(','));
        }
      } else { this.SelectCustomer(this.SecondDrpModel.map(({ id }) => id).join(','));}
    } else {
      $('#SecondDrp .c-btn').html('');
      this.SelectCustomer("");
      
    }
  }
  onSecondDrpItemSelectAll(items: any) {
    if ((this.parentComp == 'Customer' || this.parentComp == 'Accounts' || this.parentComp == 'Contact' || this.parentComp == 'CustContacts') && (this.SecondDrpModel && this.SecondDrpModel.length > 0)) {
      if (this.parentComp == 'Customer' || this.parentComp == 'Accounts' || this.parentComp == 'Contact' || this.parentComp == 'CustContacts') {
        var values = this.SecondDrpModel.map(({ id }) => id).join(',').split(',');
        if (values.includes("0") && values.includes("1") && values.includes("2")) {
          $('#SecondDrp .c-btn').html('<span class="c-label ng-star-inserted">Display All</span>');
          this.secondDrpText = "Disply All";
          this.SelectCustomer('0,1,2');
        } else {

          $('#SecondDrp .c-btn').html('<span class="c-label ng-star-inserted">Display All</span>');
          this.secondDrpText = "Disply All";

          this.SelectCustomer(this.SecondDrpModel.map(({ id }) => id).join(','));
        }
      } else { this.SelectCustomer(this.SecondDrpModel.map(({ id }) => id).join(',')); }
     
    } else {
      this.SelectCustomer("");
    }
  }
  OnSecondDrpItemDeSelectAll(items: any) {
    this.SelectCustomer("");

    if (this.parentComp === 'Lead') {
      this.secondDrpText = "Open Leads";
      this.SecondDrpModel = [{ "id": 555, "itemName": "Open Leads" }];
    } else if (this.parentComp === 'Proposal') {
      this.secondDrpText = "Open Proposals";
      this.SecondDrpModel = [{ "id": 558, "itemName": "Open Proposals" }];
    }
    
    else if (this.parentComp === 'Calendar') {
      this.secondDrpText = "All Tasks";
      this.SecondDrpModel = [{ "id": 566, "itemName": "All Tasks" }];
    } else if (this.parentComp === 'Ticket') {
      this.secondDrpText = "Display My Tickets First";
      this.SecondDrpModel = [{ "id": 571, "itemName": "Display My Tickets First" }];
    } else if (this.parentComp === 'Tickets') {
      this.secondDrpText = "--Select--";
      this.SecondDrpModel = [{ "id": 0, "itemName": "--Select--" }];
    }
    else if (this.parentComp === 'CallList') {
      this.secondDrpText = "Open Call Lists";
      this.SecondDrpModel = [{ "id": 579, "itemName": "Open Call Lists" }];
    } else if (this.parentComp === 'rhx') {
      this.secondDrpText = "Todays History";
      this.SecondDrpModel = [{ "id": 575, "itemName": "Todays History" }];
    } else if (this.parentComp === 'Profile') {
      this.secondDrpText = "My Profile";
      this.SecondDrpModel = [{ "id": 583, "itemName": "My Profile" }];
    } else if (this.parentComp === 'Projects') {      
      this.secondDrpText = "Open (" + this.applicationComponent.OpenProjects + ")";
      this.SecondDrpModel = [{ "id": 666, "itemName": "Open (" + this.applicationComponent.OpenProjects + ")" }];
    } else if (this.parentComp == 'Customer' || this.parentComp == 'Accounts' || this.parentComp == 'Contact' || this.parentComp == 'CustContacts') {
      this.secondDrpText = "";
      $('#SecondDrp .c-btn').html('');
    }
  }
  onThirdDrpItemSelect(item: any) {
    if (this.parentComp == 'Opportunities' || this.parentComp == 'Forecasting' || this.parentComp == 'Proposal' || this.parentComp =='Projects') {
      this.outProps.thirdDrpValue = item.id;
      this.outProps.pageSizeChanged = false;
      this.assignOutPropsAndEmitEvent();
    } else {
     // if (this.parentComp != 'Projects') {
        this.SelectActivity(item.value);
      //}
    }
  }
  onThirdDrpItemSelectReport(item: any) {    
    this.SelectReportType(item.value);
  }
  OnThirdDrpItemDeSelect(item: any) {
    this.SelectActivity("");

  }
  OnThirdDrpDeSelectAll(item: any) {
    this.SelectActivity("");

    if (this.parentComp == 'Customer' ||this.parentComp === 'Accounts') {
      this.ThirdDrpModel = [{ "id": 0, "itemName": 'All Account Types', "category": 'All Account Types', "isdeleted": 0, "taskgroupid": 0 }];
    } else if (this.parentComp === 'Calendar') {
      this.thirdDrpText = "Current Month";
      this.ThirdDrpModel = [{ "id": 437, "itemName": "Current Month" }];
    } else if (this.parentComp === 'Ticket') {
      this.thirdDrpText = "Display My CC Tickets";
      this.ThirdDrpModel = [{ "id": 573, "itemName": "Display My CC Tickets" }];
    } else if (this.parentComp === 'Reports') {
      this.thirdDrpText = "Standard Reports";
      this.ThirdDrpModel = [{ "id": 600, "itemName": "Standard Reports" }];
    } else if (this.parentComp === 'AdminReports') {
      this.thirdDrpText = "Standard Reports";
      this.ThirdDrpModel = [{ "id": 601, "itemName": "Admin Reports" }];
    } else if (this.parentComp === 'Projects') {
      this.thirdDrpText = "All Project Types";
      this.ThirdDrpModel = [{ "id": 0, "itemName": "All Project Types" }];
    } else if (this.parentComp === 'Contact' || this.parentComp == 'CustContacts') {
      this.FourthDrpModel = [{ "id": 567, "itemName": "All Activity" }];
      this.thirdDrpText = "All Contact Types";
      this.ThirdDrpModel = [{ "id": 0, "itemName": 'All Contact Types', "category": 'All Contact Types', "isdeleted": 0, "taskgroupid": 0 }];
    } else if (this.parentComp === 'Forecasting' || this.parentComp === 'Opportunities') {
      this.thirdDrpText = "Include Pended Opportunities";
      this.ThirdDrpModel = [{ "id": 717, "itemName": "Include Pended Opportunities" }];
    }

  }
  OnThirdDrpItemDeSelectReport(item: any) {
    this.SelectReportType('');
  }
  onFourthDrpItemSelect(item: any) {
    
      this.SelectReportType(item.value);
  }
  OnFourthDrpItemDeSelect(item: any) {
    this.SelectReportType(item.value);
  }
  OnFourthDrpDeSelectAll(item: any) {
    this.SelectReportType(item.value);
    if (this.parentComp == 'Customer' ||this.parentComp === 'Accounts' || this.parentComp === 'Contact' || this.parentComp == 'CustContacts') {
      this.FourthDrpModel = [{ "id": 567, "itemName": "All Activity" }];
    } else if (this.parentComp === 'Reports') {
      this.fourthDrpText = "Access(Login/Logout)";
      this.FourthDrpModel = [{ "id": 652, "itemName": "Access(Login/Logout)" }];      
    } else if (this.parentComp === 'AdminReports') {
      this.fourthDrpText = "Select Admin Reports";
      this.FourthDrpModel = [{ "id": 0, "itemName": "Select Admin Reports" }];      
    } else if (this.parentComp === 'Projects') {
      this.fourthDrpText = "List View";
      this.FourthDrpModel = [{ "id": 664, "itemName": "List View" }];
    }
    
  }

  onFifthDrpItemSelect(item: any) {
    this.SelectTaskListFilter(item.value)
  }
  OnFifthDrpItemDeSelect(item: any) {
    //this.SelectActivity(item.value);
    this.SelectTaskListFilter(item.value)

  }

  OnFifthDrpItemDeSelectAll(item: any) {
    this.SelectTaskListFilter(item.value)
    if (this.type !== 'Customer' && (this.parentComp === 'Accounts' || this.parentComp == 'Customer')) {
      this.fifthDrpText = "Recently Imported";
      this.FifthDrpModel = [{ "id": 0, "itemName": "Recently Imported" }];
    } else if (this.parentComp === 'Reports') {
      this.FifthDrpModel = [{ "id": 617, "itemName": "Display All" }];
    }
  }

  onSixthDrpItemSelect(item: any) {
    if (item.itemName == 'Display Contacts') {
      this.customerService.GetSpecialityList("ContactShared", this.currentuser.id).then((Rows) => {
        this.fifthDropdownList = Rows;
      });
      this.fifthDrpText = "All Shared Contacts";
      this.FifthDrpModel = [{ "id": 730, "itemName": "All Shared Contacts" }];
    } else {
      this.customerService.GetSpecialityList("AccountShared", this.currentuser.id).then((Rows) => {
        this.fifthDropdownList = Rows;
      });
      this.fifthDrpText = "All Shared Accounts";
      this.FifthDrpModel = [{ "id": 727, "itemName": "All Shared Accounts" }];
    }
    this.SelectCustomerType(item.category);
  }
  OnSixthDrpItemDeSelect(item: any) {
    this.SelectCustomerType(item.category);
  }
  onItemSelect(item: any) {
    this.MultiselectDropDownChanged = true;
    var ids = item.id;
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    var SelectedUsers = [];
    SelectedUsers = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    if (SelectedUsers && SelectedUsers.length > 0) {
      SelectedUsers.push(item)
    } else {
      SelectedUsers.push( item);
    }
    $('#cancelDiv').attr('style', 'display:block;');
    $('#SaveDiv').attr('style', 'display:block;');
    this.outProps.pageSizeChanged = false;
    this.assignOutPropsAndEmitEvent();
  }
  OnItemDeSelect(item: any) {
    this.MultiselectDropDownChanged = true;
    var ids = item.id;
    var result = "";
    this.finalitems = this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', "");

    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      var SelectedUsers = [];
      SelectedUsers = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
      if (SelectedUsers && SelectedUsers.length > 0) {
        SelectedUsers = SelectedUsers.filter(x => x.id != item.id)
      } else {
        SelectedUsers.push(item);
      }
      //localStorage.setItem('SelectedUserIds', JSON.stringify(SelectedUsers));

      //this.finalitems = this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', "");
    } else {
      this.finalitems = '';
      //localStorage.setItem('SelectedUserIds', null);
    }
    $('#cancelDiv').attr('style', 'display:block;');
    $('#SaveDiv').attr('style', 'display:block;');
    var res = $("#UserList .dropdown-list").prop("hidden");
    if (res == true) {

      this.SaveUserListConfirmation = true;
      this.SaveUserListConfirmationCss();
      //$("#UserList .dropdown-list").attr("hidden", false);

    }
    //this.finalitems = result;
    this.outProps.pageSizeChanged = false;
    this.assignOutPropsAndEmitEvent();
  }
  onSelectAll(items: any) {
    this.MultiselectDropDownChanged = true;
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    $('#cancelDiv').attr('style', 'display:block;');
    $('#SaveDiv').attr('style', 'display:block;');
    this.outProps.pageSizeChanged = false;
    this.assignOutPropsAndEmitEvent();
  }
  onDeSelectAll(items: any) {
    this.MultiselectDropDownChanged = true;
    this.finalitems = "";
    $("#userids").val("");
    //localStorage.setItem('SelectedUserIds', null);
    $('#cancelDiv').attr('style', 'display:block;');
    $('#SaveDiv').attr('style', 'display:block;');
    var res = $("#UserList .dropdown-list").prop("hidden");
    if (res == true) {

      this.SaveUserListConfirmation = true;
      this.SaveUserListConfirmationCss();
      //$("#UserList .dropdown-list").attr("hidden", false);

    }
    this.outProps.pageSizeChanged = false;
    this.assignOutPropsAndEmitEvent();
  }
  assignOutPropsAndEmitEvent() {
    this.outProps.finalitems = this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""); 
    this.sendOutProps(this.outProps);
  }
  Imported() {
    this.applicationComponent.SearchNotAvailable = true;
    this.notSelectedCss();
  }
  notSelectedCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4)
  }

  SaveConfirm(val) {
    if (val == 'Yes') {
      this.MultiUserSaveclicked();
      //this.assignOutPropsAndEmitEvent();
    } else {
      this.MultiselectDropDownChanged = false;
      this.SaveUserListConfirmation = false;
      $("#UserList .dropdown-list").attr("hidden", true);
    }
    $('#cancelDiv').attr('style', 'display:none;');
    $('#SaveDiv').attr('style', 'display:none;');
    
  }


  SaveUserListConfirmationCss() {
    setTimeout(function () {
      $('#SaveUserListConfirmation .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 4)
  }
  ShowCalenderListView(val) {
    this.CalenderListView = !this.CalenderListView;
    this.sendMsgEvent.emit(val)
  }
  onSeventhDrpItemSelect(item: any) {    
    this.outProps.seventhDrpValue = item.id;
    this.outProps.pageSizeChanged = false;
    this.assignOutPropsAndEmitEvent();
    this.LoadData();
  }
  oneightDrpItemSelect(item: any) {
    this.outProps.eightDrpValue = item.id;
    this.assignOutPropsAndEmitEvent();
  }
  OnSeventhDrpItemDeSelect(item: any) {
    
  }
  onSeventhDrpItemSelectAll(items: any) {
   
  }
  OnSeventhDrpItemDeSelectAll(items: any) {
    
  }
  public Search(val) {
    this.outProps.enableSearch = true;
    this.assignOutPropsAndEmitEvent();
  }
}
