<div class="col-xs-12" style="padding:0;height:100%">
  <div class="col-xs-12" style="padding:0;height:10%">
    <div class="tabset" style="background:#f4f4f4">
      <input type="radio" name="tabset" id="tab1" aria-controls="marzen" checked>
      <label for="tab1" (click)="Audit('Login',this.startDateVal,this.endDateVal)">Login/Logout</label>

      <input type="radio" name="tabset" id="tab9" aria-controls="rauchbier" style="margin-left: 1px">
      <label for="tab9" (click)="Audit('All',this.startDateVal,this.endDateVal)">All</label>

      <input type="radio" name="tabset" id="tab2" aria-controls="rauchbier" style="margin-left: 1px">
      <label for="tab2" (click)="Audit('Account',this.startDateVal,this.endDateVal)">Account</label>

      <input type="radio" name="tabset" id="tab3" aria-controls="rauchbier" style="margin-left: 1px">
      <label for="tab3" (click)="Audit('Contact',this.startDateVal,this.endDateVal)">Contacts</label>

      <input type="radio" name="tabset" id="tab4" aria-controls="rauchbier" style="margin-left: 1px">
      <label for="tab4" (click)="Audit('Leads',this.startDateVal,this.endDateVal)">Leads</label>

      <input type="radio" name="tabset" id="tab10" aria-controls="rauchbier" style="margin-left: 1px">
      <label for="tab10" (click)="Audit('Proposals',this.startDateVal,this.endDateVal)">Proposals</label>

      <input type="radio" name="tabset" id="tab5" aria-controls="rauchbier" style="margin-left: 1px">
      <label for="tab5" (click)="Audit('Opportunity',this.startDateVal,this.endDateVal)">Opportunities</label>

      <input type="radio" name="tabset" id="tab6" aria-controls="rauchbier" style="margin-left: 1px">
      <label for="tab6" (click)="Audit('TaskList',this.startDateVal,this.endDateVal)">Task List</label>

      <input type="radio" name="tabset" id="tab11" aria-controls="rauchbier" style="margin-left: 1px">
      <label for="tab11" (click)="Audit('Customers',this.startDateVal,this.endDateVal)">Customers</label>


      <input type="radio" name="tabset" id="tab11" aria-controls="rauchbier" style="margin-left: 1px">
      <label for="tab11" (click)="Audit('Tickets',this.startDateVal,this.endDateVal)">Tickets</label>

      <input type="radio" name="tabset" id="tab8" aria-controls="rauchbier" style="margin-left: 1px">
      <label for="tab8" (click)="Audit('Campaigns',this.startDateVal,this.endDateVal)">Campaigns</label>

      <input type="radio" name="tabset" id="tab7" aria-controls="rauchbier" style="margin-left: 1px">
      <label for="tab7" (click)="Audit('InternalMessaging',this.startDateVal,this.endDateVal)">Internal Messaging</label>

    </div>
  </div>
  <div class="col-xs-12" style="padding:0;height:90%">
    <ag-grid-angular style="width: 100%;height:100%"
                     class="ag-theme-balham" 
                     [gridOptions]="gridopportunityOptions"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="loginauditloglist"
                     [sideBar]="sideBar"
                     (gridReady)="onAgGridReady($event)">
    </ag-grid-angular>

  </div>
 
</div>


