import { Component, OnInit, ViewContainerRef, ViewEncapsulation, ElementRef, Renderer2, ViewChild, Optional, Input } from '@angular/core';
import { Contacts } from '../../models/contacts';
import { Accounts } from '../../models/accounts';
import { Notes } from '../../models/notes';
import { Http } from '@angular/http';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from './../../services/customer.service';
import { DatePipe } from '@angular/common';
import { AppSettings } from '../../app.settings';
import { ToastrService } from 'ngx-toastr';;
import { ParentComp, UserlistComponent } from "../userlist/userlist.component";
import { CalllisthistoryComponent } from "../calllisthistory/calllisthistory.component";
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { NotesrendererComponent } from '../notesrenderer/notesrenderer.component';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { PageComponent } from '../page/page.component';
import { Column } from 'ag-grid';
import { EditactivatecvComponent } from '../editactivatecv/editactivatecv.component';
import { IMyDpOptions } from 'mydatepicker';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import { AccountsService } from '../../services/accounts.service';
import { ApplicationComponent } from '../application.component';
import * as moment from 'moment'
declare var require: any;
import { NotesComponent } from '../notes/notes.component';
import { AddressmapComponent } from '../addressmap/addressmap.component';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { ContactsdashboardComponent } from '../contacts/contactsdashboard/contactsdashboard.component';
import { AuditLogModel } from '../../models/auditlog';
import { AccountDetailsComponent } from '../account-details/account-details.component';
declare var $: any;
import { UserdataheaderComponent } from '../userdataheader/userdataheader.component';
@Component({
  selector: 'customercontacts',
  templateUrl: './customercontacts.component.html',
  styleUrls: ['./customercontacts.component.css']
})
export class CustomercontactsComponent implements OnInit {
  @Input() source;
  resizeheight = (window.innerHeight / 1.577) + 'px';
  public zipmask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public dobmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  currentuser: any;
  notedescription: string;
  itemResource: any;
  items = [];
  Contactitems = [];
  ContactitemCount = 0;
  itemCount = 0;
  params: any;
  term: string = "";
  showDialog = false;
  ActiveConvertDialogg = false;
  CallHistoryDialog = false;
  ContactIds: string;
  notesshowDialog = false;
  public defaultColDef;
  contactid: number;
  callhistorystatus: string;
  public sideBar;
  leadstatus: number;
  notesitemResource: any;
  notesitems = [];
  notesitemCount = 0;
  notesparams: any;
  Ids = [];
  subtype: string = "";
  ContacttableDiv: boolean = true;
  practicetableDiv: boolean = true;
  dropdownList = [];
  finalitems = "";
  selectedItems = [];
  dropdownSetting = {};
  datePipe: DatePipe = new DatePipe("en-US");
  contact: Contacts = new Contacts();
  account: Accounts = new Accounts();
  notes: Notes = new Notes();
  accessDenied: boolean = false;
  isMultiLine: boolean = false;
  calanderonoff: boolean = true;
  itemList = [];
  selectedItems2 = [];
  settings2 = {};
  contactIdArr: Dropdowntype[] = [];
  thisComp: ParentComp = ParentComp.Contacts;
  isViewInitialized: boolean = false;
  isPgClickRegistered: boolean = false;
  @ViewChild(UserlistComponent) userListComp;
  @ViewChild(CalllisthistoryComponent) callhistorycmp;
  @ViewChild(NotesComponent) notecomp;
  @ViewChild(AddressmapComponent) addressMapComp: AddressmapComponent;
  @ViewChild(AccountDetailsComponent) acccontDetailComp;
  isHeaderFixed: boolean = true;
  public gridOptions: GridOptions;
  public gridOptionsnotes: GridOptions;
  public columnDefs: any[];
  public columnDefsnotes: any[];
  public contactProps: string[];
  allContacts: Contacts[];
  OriginalallContacts: Contacts[];
  pageSize: string = "1000";
  public context;
  isCustomer: boolean = false;
  isFormerCustomer: boolean = false;
  public frameworkComponents;
  public getRowHeight;
  activeItems: boolean = true;
  colSelections: string;
  aggridheight: string;
  allnotes: Notes[];
  allContactsproviders: any[] = [];
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  Collegelist: Dropdowntype[] = [];
  contacttypelist: Dropdowntype[] = [];
  SexList: Dropdowntype[] = [];
  TitleList: Dropdowntype[] = [];
  SuffixList: Dropdowntype[] = [];
  Specialitytypelist: Dropdowntype[] = [];
  MonthList: Dropdowntype[] = [];
  DayList: Dropdowntype[] = [];
  MedicalSchoolList: Dropdowntype[] = [];
  StatesList: Dropdowntype[] = [];
  CitiesList: Dropdowntype[] = [];
  OwnerList: Dropdowntype[] = [];
  Years: any[];
  MedSchoolYears: any[];
  ResidencyYears: any[];
  FellowShipYears: any[];
  @Input()
  FromSelector: boolean = false;
  DisplayrowsList: Dropdowntype[] = [];
  currentPage: number = 1;
  startrow: number = 1;
  noofRows: number = 1;
  totalnumberofrows: number = 1;
  totalnumberofPages: number = 1;
  showContactDashBoard = false;
  isModal: boolean = true;
  isSharedAccts: boolean = true;
  divHeight = (screen.height * 0.6);
  userInfo;
  isShowDetailsChecked: boolean = false;
  public stateInfo = [];
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear()-1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear()-1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");

  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  active_deactiveStatus: number; NotesDec = false;
  timezonevalue: string;
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  Contactvales: string = "1";
  isCustomercontacts: boolean = false;
  isFormerCustomercontacts: boolean = false;
  DeleteConfirmationPopup: boolean = false;
  deleteRowData: any;
  isdeletedstatustext: number;
  private gridApi;
  isContactViewOpen: boolean = true;
  @ViewChild(ContactsdashboardComponent) condashcmp: ContactsdashboardComponent;
  @ViewChild(UserdataheaderComponent) userdatacmp: UserdataheaderComponent;
  EnableSearch: boolean = false; value = "";
  isShowAccountInfo: boolean = false;
  accountid;
  showAddEditContact: boolean = false;
  DocExpandView: boolean = false;
  public selectedStatus: number = 309;
  constructor(private paginationcop: PageComponent, public applicationComponent: ApplicationComponent, private accountsService: AccountsService, private elementRef: ElementRef, private renderer: Renderer2, private http: Http, private opprtunityservice: OpprtunitiesService, private route: ActivatedRoute, private customerService: CustomerService, private router: Router, private settings: AppSettings, private toastr: ToastrService, private viewContainerRef: ViewContainerRef) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    if (this.currentuser.defaultSearchPage == 1) { this.EnableSearch = true; } else { this.EnableSearch = false; }
    this.viewContainerRef = viewContainerRef;
  }
  ngAfterViewInit(): void {
    //this.ResizeTable();
  }
  UpdateSearchColumns(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[] = columnSelections.split(',');

    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');
      if (fields[2] == 'true' && (fields[1] != '#' && fields[0] != 'notes' && fields[0] != 'actions' && fields[0] != 'callliststatus')) { 
        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }

    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];

    if (retrievedObject != null) {
      let obj: any[] = JSON.parse(retrievedObject);
      obj.forEach(element => {
        if (element.subtab == 'customercontacts') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        }
        //if (this.applicationComponent.SearchSelectedItemsLengthChanged == true) {
        //  this.applicationComponent.showSearchTabsSubTabs = false;
        //}
        //else {
        //  this.applicationComponent.showSearchTabsSubTabs = true;
        //}
      });
    }
    if (tempTableColumns && tempTableColumns.length > 0) {
      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })

    }
    else {
      this.accountsService.GetDefaultColumns('customercontacts', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.applicationComponent.SearchSelectedItems = [
            { "id": 'lastname', "itemName": 'Last Name' },
            { "id": 'firstname', "itemName": 'First Name' }
          ];
        }
      });
      //this.applicationComponent.showSearchTabsSubTabs = false;
    }
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, paginationPageSize: 1000, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'multiple'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 5).then((config) => {
    
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = columnSelections;
      this.startDateVal = (config != null && config.startdate != null) ? config.startdate : (new Date().getFullYear() - 1) + '-01-01';
      this.startDatePlaceHolder = (config != null && config.startdate != null) ? 'Start:' + moment(config.startdate).format("MM-DD-YYYY") : 'Start: 01-01-' + (new Date().getFullYear() - 1).toString();
      this.endDateVal = (config != null && config.enddate != null) ? config.enddate : new Date().getFullYear() + '-12-31';
      this.endDatePlaceHolder = (config != null && config.enddate != null) ? 'End:' + moment(config.enddate).format("MM-DD-YYYY") : 'End: 12-31-' + (new Date().getFullYear() - 1).toString();  
      this.createColumnDefs(columnSelections,true);
      this.UpdateSearchColumns(columnSelections);
      this.Generate($("#userids").val());
      
    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent,
      //editdeletecv: EditdeletecvComponent,
      editactivatecv: EditactivatecvComponent
    };

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3

    };
  }
  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    var searcharray = [];
    if (this.gridOptions.quickFilterText != null && this.gridOptions.quickFilterText != undefined && this.gridOptions.quickFilterText != '') {
      searcharray.push(this.gridOptions.quickFilterText);
    }  

    var searchstring= this.applicationComponent.searchstring
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {

          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "lastname") {
          this.columnDefs.push({
            field: 'lastname', headerName: 'Last Name', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {

                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                } else {
                  return params.value;
                }
              }
            }
          })
          //this.columnDefs.push({ field: 'lastname', headerName: 'Last Name', hide: false, sort: "asc" })
        }
        else if (fieldHeaderVisibleWidthArray[0] == "firstname") {
          this.columnDefs.push({
            field: 'firstname', headerName: 'First Name', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {

                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                } else {
                  return params.value;
                }
              }
            }
          })
          //this.columnDefs.push({ field: 'lastname', headerName: 'Last Name', hide: false, sort: "asc" })
        }
        else if (fieldHeaderVisibleWidthArray[0] == "accountname") {
          this.columnDefs.push({
            field: 'accountname', headerName: 'Account Name', getQuickFilterText: () => '', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellRenderer: function (params) {             
                if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                } else {
                  return params.value;
                }            
            }
          })
          //this.columnDefs.push({ field: 'lastname', headerName: 'Last Name', hide: false, sort: "asc" })
        }
        else if (fieldHeaderVisibleWidthArray[0] == "callliststatus") {
          this.columnDefs.push({
            field: 'callliststatus', headerName: 'Call List Hx', getQuickFilterText: () => '',hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:50px; background:white">' + params.value + '</button>';
              var eButton = eDiv.querySelectorAll('.btn-simple')[0];
              eButton.addEventListener('click', function () {
                selfObj.callhistorystatus = params.data.callliststatus;
                selfObj.contactid = params.data.id;
                selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
                selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
              });
              return eDiv;
            }, width: parseInt(fieldHeaderVisibleWidthArray[3])
          })
        }
        else if (fieldHeaderVisibleWidthArray[0] == "customertype") {
          this.columnDefs.push({
            field: 'customertype', headerName: 'Customer Type', getQuickFilterText: () => '',hide: (!selfObj.isCustomercontacts && !selfObj.isFormerCustomercontacts),
            cellRenderer(params) {
              if (params.value == '0') { return "Non - Customer" } else if (params.value == '1') { return "Customer" }
              else if (params.value == '2') { return "Former Customer" }
            }, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "accnumber") {
          this.columnDefs.push({
            field: 'accnumber', headerName: 'Acct #', cellRenderer(params) {
              if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              } else {
                return params.value;
              }
},
            getQuickFilterText: () => '', hide: (!selfObj.isCustomercontacts && !selfObj.isFormerCustomercontacts), width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "SaaS") {
          this.columnDefs.push({
            field: 'SaaS', headerName: 'SaaS', getQuickFilterText: () => '', hide: false, cellRenderer(params) {
              if (params.value != null) {
                return "$" + parseInt(params.value).toFixed(2);
              } else {
                return "$0.00";
              }
            }
        });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "actions") {
          this.columnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: isDeActive ? 'editactivatecv' : 'editdeletecv', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, pinned: 'right', width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, suppressSorting: true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellRenderer: function (params) {             
                if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                  return "<label style='color:red'>" + params.value + "</label>&nbsp;"
                } else {
                  return params.value;
                }            
            }
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        {
          field: 'customertype', headerName: 'Customer Type', getQuickFilterText: () => '', hide: (!selfObj.isCustomercontacts && !selfObj.isFormerCustomercontacts),
          cellRenderer(params) {
            if (params.value == '0') { return "Non - Customer" } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
          }
        },
        {
          field: 'accnumber', headerName: 'Acct #', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '', hide: (!selfObj.isCustomercontacts && !selfObj.isFormerCustomercontacts)
        },
        //{ field: 'lastname', headerName: 'Last Name', hide: false, sort: "asc"},
        {
          field: 'lastname', headerName: 'Last Name', hide: false,
          cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {

              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              } else {
                return params.value;
              }
            }
          }
        },
        {
          field: 'firstname', headerName: 'First Name', hide: false,
          cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {

              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              } else {
                return params.value;
              }
            }
          }
        },
        {
          field: 'middlename', headerName: 'Middle Name', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '', hide: false
        },
        {
          field: 'titleName', headerName: 'Title', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '', hide: false
        },
        {
          field: 'specialityName', headerName: 'Specialty', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '', hide: false
        },
        {
          field: 'DOB', headerName: 'DOB', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '', hide: false
        },
        {
          field: 'callliststatus', headerName: 'Call List Hx', getQuickFilterText: () => '', hide: false, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:50px; background:white">' + params.value + '</button>';
            var eButton = eDiv.querySelectorAll('.btn-simple')[0];
            eButton.addEventListener('click', function () {
              selfObj.callhistorystatus = params.data.callliststatus;
              selfObj.contactid = params.data.id;
              selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
              selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
            });
            return eDiv;
          }
        },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        {
          field: 'zip', headerName: 'ZIP', getQuickFilterText: () => '', hide: false, cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }
        },
        {
          field: 'accountname', headerName: 'Account Name', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '', hide: false
        },
        { field: 'actions', headerName: 'Actions', cellRenderer: isDeActive ? 'editactivatecv' : 'editdeletecv', hide: false, pinned: 'right' },
        
        {
          field: 'contacttypeName', headerName: 'Contact Type', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '', hide: true
        },
        {
          field: 'SaaS', headerName: 'SaaS', getQuickFilterText: () => '', hide: false, cellRenderer(params) {
            if (params.value != null) {
              return "$" + parseInt(params.value).toFixed(2);
            } else {
              return "$0.00";
            }
          }
        },
        {
          field: 'RDate', headerName: 'RDate', getQuickFilterText: () => '', hide: false
        },
        {
          field: 'sex', headerName: 'Sex', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'subspeciality', headerName: 'Subspeciality', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '', hide: true },
        {
          field: 'email', headerName: 'Email', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '', hide: true },
        {
          field: 'address1', headerName: 'Address1', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'address2', headerName: 'Address2', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '', hide: true },
        {
          field: 'city', headerName: 'City', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'state', headerName: 'State', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'cell', headerName: 'Cell',
          cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {

              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              } else {
                return params.value;
              }
            }
          }, hide: true },
        {
          field: 'office', headerName: 'Office',
          cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {

              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
                return "<label style='color:red'>" + params.value + "</label>&nbsp;"
              } else {
                return params.value;
              }
            }
          }, hide: true },
        {
          field: 'homeaddress', headerName: 'Home Address', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '', hide: true },
        {
          field: 'homecity', headerName: 'Home City', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'homestate', headerName: 'Home State', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'homezip', headerName: 'Home Zip', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'home', headerName: 'Home', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'college', headerName: 'College', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'collegegrandyear', headerName: 'C.G.year', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'medschool', headerName: 'Medschool', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'medschoolgrandyear', headerName: 'M.G.year', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'residency', headerName: 'Residency', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'residencygrandyear', headerName: 'R.G.year', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'fellowship', headerName: 'Fellowship', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'fellowshipgrandyear', headerName: 'F.G.year', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
        {
          field: 'owner', headerName: 'Owner', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, hide: false, getQuickFilterText: () => '',suppressSorting: true },
        {
          field: 'created', headerName: 'Created', cellRenderer(params) {
            if ((params.data.lattitude == "" || params.data.lattitude == null) && selfObj.applicationComponent.googlemapshow) {
              return "<label style='color:red'>" + params.value + "</label>&nbsp;"
            } else {
              return params.value;
            }
          }, getQuickFilterText: () => '',hide: true },
       
      ];

      this.defaultColDef = { sortable: true };
    }
  }

   currencyFormatter(currency, sign) {
  var sansDec = currency.toFixed(0);
  var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return sign + `${formatted}`;
}
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  autoSizeAll() {

  }
  GetContactIds() {
    this.customerService.GetSpecialityList("ContactIds", this.currentuser.id).then((Acids) => {
      this.contactIdArr = Acids;
    });
  }
  txtPageSizeChanged(pageSize) {
    //this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    this.currentPage = 1;
    this.pageSize = pageSize;
    this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
    this.saveConfiguration();
  }

  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    this.UpdateSearchColumns(config.columnsselections);
    config.configureduserid = this.currentuser.id;
    config.resourceid = 5;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;
    config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        if (this.isShowAccountInfo) {
          this.acccontDetailComp.ContactInfo('show' + event.api.getSelectedNodes()[0].data.id);
          //this.acccontactcomp.ShowContactView = false;
        }

      }
    }
  }
  rowClickAgGrid(rowEvent) {
    if (this.active_deactiveStatus != 1)
      if ((this.Ids).indexOf(rowEvent.data.id) > -1) {
        var index = (this.Ids).indexOf(rowEvent.data.id);
        (this.Ids).splice(index, 1);
        var string = (this.Ids).toString();
        $('#emailIds').val(string);
        this.ContactIds = string;
      } else {
        this.Ids.push(rowEvent.data.id)
        var string = (this.Ids).toString();
        $('#emailIds').val(string);
        this.ContactIds = string;
      }
    $('#tasklistselectedtyle').val('Contact');
    if (this.applicationComponent.googlemapshow) {
      this.addressMapComp.GetLocations(this.ContactIds, "Contacts");
    }
  }
  cellDoubleClicked(rowEvent) {
    
    this.contactid = rowEvent.data.id;
    this.accountid = rowEvent.data.accountid;
     
    this.showContactDashBoard = true;
    this.EnableSearch = false;
    var obj: AuditLogModel = { id: 0, type: 'Customer', action: 'Customer Viewed', AccountOrContactId: rowEvent.data.id, AccountOrContact: rowEvent.data.permission, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Task List Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
       this.applicationComponent.ExpandSelectedMenu('customers');

  }
  onCellValueChanged($event) {
   
  }
  ExpandPDF(val) {
    this.notesshowDialog = true;
    this.QuickViewDialogCss(val);
    this.DocExpandView = val;
  }
  QuickViewDialogCss(val) {
    if (val == true) { //expand
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 100%;padding: 0;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    } else {
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 43%;padding: 0;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    }

  }

  NotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 43%;padding: 0;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }
  openNotesDialogAg(rowdata) {
   
    this.contactid = rowdata.id;
    this.notesshowDialog = !this.notesshowDialog;
    this.NotesDialogCss();

  }

  editRowAg(rowdata) {
    this.contactid = rowdata.id;
    this.accountid = rowdata.accountid;
    this.showAddEditContact = true;
    this.showAddEditContactCss();

  }
  showAddEditContactCss() {
    setTimeout(function () {
      $('#addEditContact .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  ContactReceiveEvent(msg) {
    this.showAddEditContact = false;
    //this.GetContactList(this.term, $("#userids").val(), this.active_deactiveStatus);
    this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
  }

  deleteRowAg(rowdata) {
    this.DeleteConfirmationPopup = true;
    this.deleteRowData = rowdata
    this.contactid = rowdata.id;
    this.isdeletedstatustext = rowdata.isdeleted;
    this.applicationComponent.salesxcrmDialog('#DeleteConfirmationPopup .dialog');
    //setTimeout(function () {
    //  $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    //}, 4);
     
  }
  ReceiveDeleteevent(msg) {
    if (msg == "Ok") {
      let isdeletedstatus = (this.deleteRowData.isdeleted == 1) ? 0 : 1;
      this.customerService.Deactivatecontact(this.deleteRowData.id, isdeletedstatus).then((user) => {
        this.active_deactiveStatus = this.activeItems ? 0 : 1;
        this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
      });
      }
      this.DeleteConfirmationPopup = false;
  }
  convertContactAg(rowdata) {
    this.contactid = rowdata.id;
    this.leadstatus = 0;
    this.showDialog = !this.showDialog
    this.customerService.GetContactById(rowdata.id).then(result => {
      this.contact = result;
      this.leadstatus = this.contact.converttolead;
      if (this.leadstatus == 0) {
        this.applicationComponent.loaddialog('convertcontacttolead', 'Convert Lead', 'Convert this Contact to a Lead?', false, false, true, true, this.contactid);
      }
      else {
        this.applicationComponent.loaddialog('convertcontacttolead', 'Convert Lead', 'Contact is an Open Lead', false, false, true, true, this.contactid);
      }
    });

  }

  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single', autoHeight: true
    };
    this.createColumnDefsnotes();
    this.context = this;
  }
  createColumnDefsnotes() {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular",
        tooltipField: 'notedescription', cellStyle: { 'text-align': 'left' },
        //tooltip: function (params) {
        //  return (params.valueFormatted);
        //},
        hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];
    //this.getRowHeight = function () {
    //  return 100;
    //};
    //this.getRowHeight = function (params) {
    // // return 18 * (Math.floor(params.data.notedescription.length / 45) + 1);
    // // return 1000;
    //};
  }

  ngOnInit() {
    this.applicationComponent.googlemapshow = false;
    const custPage = localStorage.getItem("CustPage");
    if (custPage === "Customer") {
      this.applicationComponent.pageViewed = "Customer";
    } else {
      this.applicationComponent.pageViewed = "";
    }
    this.route.params.subscribe(params => {
      this.term = params['term'];
      this.contactid = params['contactid'];
      this.subtype = 'Customercontacts';
    });

    var thisTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == "Contacts")[0];
    if (thisTab.canview == false) {
      this.toastr.warning("You Do Not have Access to " + thisTab.resource + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
      this.accessDenied = true;
      return false;
    }

    let selfObj = this;
    $(setTimeout(function () {
      var appendHtml = '<div style="padding-top: 45px"><label id="lblDeActive" style="transform: rotate(90deg)">DeActive</label></div>';

      var dyn = document.createElement('dyn');
      dyn.innerHTML = appendHtml;
      $('.ag-side-buttons').append(dyn);
      dyn.onclick = function () {
        if ($('#lblDeActive').text() == 'DeActive')
          $('#lblDeActive').text('Active');
        else
          $('#lblDeActive').text('DeActive');
        selfObj.activeItems = selfObj.activeItems ? false : true;
        selfObj.active_deactiveStatus = selfObj.activeItems ? 0 : 1;
        
          selfObj.GetContactList(selfObj.term, $("#userids").val(), selfObj.currentPage, selfObj.pageSize, selfObj.active_deactiveStatus);
          
      };
    }, 500));
    $(".mainCheckMark").removeClass("activeColor");
    $(".coustomButton").removeClass("mainactiveButton");
    //$("#a-z_button").addClass("activeButton");
    $("#customers").addClass("mainactiveButton");

    var today = new Date();

    this.initializeAgGrid();

    this.route.params.subscribe(params => {     
      this.subtype = 'Customercontacts';
      let eleId = this.subtype;
      this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1, 0, 0).then((Owner) => {
        Owner.forEach(Owner => {
        });
        this.dropdownList = Owner;
      });
      this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
        this.DisplayrowsList = Rows;
      });
    
      if (this.applicationComponent.selectedusers != '' && this.applicationComponent.selectedusers != null && this.applicationComponent.selectedusers != undefined) {
        this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
          Owner.forEach(Owner => {
          });
          this.selectedItems = Owner;
        });
        

        $(".coustomButton").removeClass("mainactiveButton");
        $("#customers_span").addClass("activeColor");
        $("#customers").addClass("mainactiveButton");
        
      
        this.Generate($("#userids").val());
      }
      
    });
    
    this.isViewInitialized = true;

    this.loaddropdowns();
  
    this.GetContactIds(); 
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }
  
  }

  userListOutParamsBinding(outProps) {
    this.finalitems = outProps.finalitems;
    this.Contactitems = outProps.items;
    this.ContactitemCount = outProps.itemsCount;
    this.selectedItems = outProps.selectedItems;
  }
 

  AssignStartAndEndDate(event) {

    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  GetCUSContactListLK(userid, filtstatusval) {   
    this.opprtunityservice.GetAllCustomerContactsLK(userid).then((contactLK) => {
      this.allContacts = contactLK;
    });
  }

  GetContactList(term, userid, startrow, endrow, active_deactiveStatus?: number) {  
    if (this.source == "Reports") {     
      this.GetCUSContactListLK($("#userids").val(), 'active');
    } else {
      if (this.currentuser.UserPrivilegeList != undefined) {
        var thisTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'CustomerContacts' || p.resource.toLowerCase() == 'CustomerContacts')[0];
        if (thisTab != undefined && thisTab.canview == false) {
          this.toastr.warning("You Do Not have Access to Customer Contacts Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
          return false;
        }
      }
      if (this.Contactvales != "") {
   
        this.opprtunityservice.GetAllContactsCustomersBySearch(this.currentuser.id, this.term, $("#userids").val().toString(), '2018-01-01', this.endDateVal, startrow, endrow, this.Contactvales).then((contact) => {
          contact.forEach(contact => {
            contact.created = this.datePipe.transform(contact.created, "MM-dd-yyyy");
            contact.speciality = ((contact.speciality == 'null') ? "" : contact.speciality);
          });
          if (this.currentuser.defaultSearchPage == 1) {
            this.OriginalallContacts = contact;         
          } else {
            this.OriginalallContacts = contact;         
          }
          
          if (!this.isSharedAccts) {
            this.allContacts = this.OriginalallContacts.filter(x => x.ownerid == this.currentuser.id);
          }
          if (this.currentuser.hidecustomer == 0) {
            this.allContacts = this.OriginalallContacts.filter(x => x.Status == this.selectedStatus);
          }
          if (this.currentuser.hidecustomer == 1 && !this.EnableSearch) {
            this.allContacts = [];
          } else if (this.currentuser.hidecustomer == 1 && this.EnableSearch) {
            
            if (this.value != null && this.value != "") {
              this.allContacts = this.OriginalallContacts;
            }
          }
          else {
            this.allContacts = this.OriginalallContacts;
          }       
          this.allContacts = this.OriginalallContacts.filter(x => x.Status == this.selectedStatus);
          let selfObj = this;
          $(setTimeout(function () {
            selfObj.userdatacmp.isDisplayAccount = true;
          }, 200));
          if (localStorage.getItem('RhxContactVal') != null && localStorage.getItem('RhxContactVal') != undefined) {

            this.showContactDashBoard = true;
            this.contactid = Number(localStorage.getItem('RhxContactVal'));
            let selfObj = this;
            $(setTimeout(function () {
              selfObj.gridOptions.api.forEachNode((node) => {
                if (node.data.id == selfObj.contactid) {
                  selfObj.gridOptions.api.selectNode(node, true);
                  selfObj.gridOptions.rowSelection = 'single';
                }
              });
            }, 500));
          }
        });
      }
      else {
        this.allContacts = [];
      }

      var obj: AuditLogModel = { id: 0, type: 'Customer', action: 'Customer Viewed', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Task List Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
      this.applicationComponent.InsertAuditHistory(obj);
    }
   
  }
  onItemSelect(item: any) {
    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val().toString();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.Generate($("#userids").val());
    } else {
      this.allContacts = [];
      $("#userids").val('');
    }
  }
  dateChange(event) {
    this.AssignStartAndEndDate(event);
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
    this.saveConfiguration();
    this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
  }
 

  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.allContacts = [];
    this.gridOptions.api.hideOverlay();
    $("#userids").val('');
    this.applicationComponent.selectedusers = null;
  }
  Generate(userids) {

    this.applicationComponent.selectedusers = userids;
    if (this.pageSize == '')
      this.pageSize = '1000';
    if (this.contactid != null && this.contactid != undefined) {
      this.showContactDashBoard = true;
      
      this.customerService.GetContactById(this.contactid).then(ConResult => {
        this.customerService.GetByAccountId(ConResult.accountid).then(AccResult => {
          this.Contactvales = AccResult.customertypeid.toString();
          this.userListComp
          this.GetContactList(this.term, userids, this.currentPage, this.pageSize, 0);
          let selfObj = this;
          $(setTimeout(function () {
            selfObj.gridOptions.api.forEachNode((node) => {
              if (node.data.id == selfObj.contactid) {
                selfObj.gridOptions.api.selectNode(node, true);
                selfObj.gridOptions.rowSelection = 'single';
              }
            });
          }, 500));
        });
      });

    }

    else {
      this.GetContactList(this.term, userids, this.currentPage, this.pageSize, 0);
    }
  }
  rowClick(rowEvent) {
    if ((this.Ids).indexOf(rowEvent.row.item.id) > -1) {
      var index = (this.Ids).indexOf(rowEvent.row.item.id);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
    } else {
      this.Ids.push(rowEvent.row.item.id)
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
    }
  }
  rowDoubleClick(rowEvent) {
    

    var obj: AuditLogModel = { id: 0, type: 'Customer', action: 'Customer Viewed', AccountOrContactId: rowEvent.row.item.id, AccountOrContact: rowEvent.data.permission, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Task List Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);

    this.router.navigateByUrl(`/application/contactsdashboard/${rowEvent.row.item.id}/${rowEvent.data.permission}`);
  }

  covertContact(rowEvent) {
    this.contactid = rowEvent.id;
    this.showDialog = !this.showDialog
  }
  convertLead(id) {
    this.customerService.UpdateLeadStatus(id, this.currentuser.id).then((contact) => {
      this.showDialog = !this.showDialog
      this.router.navigateByUrl(`/application/leads/${id}`);

    });
  }
  Edit(event) {
    this.router.navigateByUrl(`/application/contactsdashboard/${event.id}/edit`);
  }

  Delete(rowEvent) {
    this.contactid = rowEvent.id;
    let isdeletedstatustext = (rowEvent.isdeleted == 1) ? "Re-Activate" : "De-Activate";
    var c = confirm("Are you sure you want to " + isdeletedstatustext + " this Contact??");
    if (c == true) {
      let isdeletedstatus = (rowEvent.isdeleted == 1) ? 0 : 1;
      this.customerService.Deactivatecontact(rowEvent.id, isdeletedstatus).then((user) => {
        this.active_deactiveStatus = this.activeItems ? 0 : 1;
        this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
      });
    }
  }
  getContactList() {
    this.activeItems = this.activeItems ? false : true;
    this.active_deactiveStatus = this.activeItems ? 0 : 1;

    this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
  }
  loaddeactivatedcontcats() {
    this.activeItems = this.activeItems ? false : true;
    this.active_deactiveStatus = this.activeItems ? 0 : 1;
    this.createColumnDefs(this.colSelections, !this.activeItems);

    this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
      $(setTimeout(function () {
        selfObj.autoSizeAll();
      }, 100));
    }, 100));
  }

  stripHTML(str) {
    var strippedText = $("<div/>").html(str).text();
    return strippedText;
  }
  public GetAllNotes(contactid, type) {
    var moment = require('moment-timezone');
    this.allnotes = [];
    this.customerService.GetAllNotesList(this.currentuser.id,contactid, type).then((notes) => {
      notes.forEach(items => {
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.initializeAgGridNotes();
      this.allnotes = notes;
      this.notesitemCount = notes.length;
      let windowHeight = $(window).height() - 160;

    });
  }

  notesreloadItems(notesparams) {
    if (this.notesitemResource != undefined) {
      this.notesitemResource.query(notesparams).then(notesitems => this.notesitems = notesitems);
    }
  }

  loaddropdowns() {
    this.customerService.GetAllOwnerList(0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.OwnerList = Owner;
    });
   
    this.customerService.GetSpecialityList("Account Specialty", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Specialitytypelist = accounttype;
    });

    this.customerService.GetCollegeList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Collegelist = accounttype;
    });

    this.customerService.GetMedicalSchoolList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.MedicalSchoolList = accounttype;
    });

    this.customerService.GetAllStateList('all').then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.StatesList = accounttype;
      this.stateInfo = accounttype;
    });
    this.customerService.GetSpecialityList("Month", this.currentuser.id).then((appointment) => {
      appointment.forEach(appointment => {
      });
      this.MonthList = appointment;

    });

    this.customerService.GetSpecialityList("Day", this.currentuser.id).then((appointment) => {
      appointment.forEach(appointment => {
      });
      this.DayList = appointment;
    });
    this.customerService.Getcontacttypes(2).then((accounttype) => {
      this.contacttypelist = [];
      accounttype.forEach(accounttype => {
      });
      this.contacttypelist = accounttype;
    });
    this.customerService.GetCollegeList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Collegelist = accounttype;
    });
    this.customerService.GetSpecialityList("Suffix", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.SuffixList = accounttype;
    });
    this.customerService.GetSpecialityList("Sex", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.SexList = accounttype;
    });

    this.getyears()
    {
      var range = [];
      var y = 1949;
      var year = new Date().getFullYear();
      for (var i = 0; y <= year; i++) {
        y = y + 1;
        range.push({
          label: y,
          value: y
        });
      }
      this.Years = range;
    }
    this.Resetcontacts();
  }
  Resetcontacts() {
    this.contact.contacttype = null;
    this.contact.suffix = null;
    this.contact.sex = null;
    this.contact.title = null;
    this.contact.speciality = null;
    this.contact.subspeciality = null;
    this.contact.college = 0;
    this.contact.collegegrandyear = null;
    this.contact.medschool = 0;
    this.contact.medschoolgrandyear = null;
    this.contact.residency = 0;
    this.contact.residencygrandyear = null;
    this.contact.fellowship = 0;
    this.contact.fellowshipgrandyear = null;
    this.contact.ownerid = null;
    this.contact.practiceid = 0;
  }
  getyears() {
    var range = [];
    var year = new Date().getFullYear();
    for (var i = 0; i < 7; i++) {
      range.push({
        label: year + i,
        value: parseInt(String(year + i).slice(2, 4))
      });
    }
    this.Years = range;
  }
  SaveContact() {
    this.accountsService.addcontact(this.contact).then(result => {
      this.customerService.GetContactById(result.id).then(result => {
        this.contact = result;
        this.customerService.GetByAccountId(this.contact.accountid).then(result => {
          this.account = result;
        });

      });
      this.customerService.GetByAccountId(this.contact.accountid).then(result => {
        this.account = result;
        this.accountid = this.account.id
        if (this.account.customertypeid == 1) {
          document.getElementById('logoid').style.display = 'block';
        } else {
          document.getElementById('logoid').style.display = 'none';
        }
      });
    });
  }

  openDisplaynotes(params) {
    $('#closeDetailsDialog').click();
    //var contentDiv = params.value;

    //this.notes.notedescription = params.value;

    this.NotesDec = true;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    var notesOffset = $("#divNotesContainer").offset();
    let notesWidth = $('#divNotesContainer').width();
    let notesHeight = $('#divNotesContainer').height();
    let windowWidth = $(window).width();
    let showDialogWidth = 350;

    let selfObj = this;
    $(setTimeout(function () {
      //$('#showDetailsDialog').width(showDialogWidth);
      //$('#showDetailsDialog').height(notesHeight - 140);

      if (selfObj.isModal) {
        //$('#showDetailsDialog').css('top', '23%');
        //$('#showDetailsDialog').css('height', '47%');
        //$('#showDetailsDialog').css('left', notesWidth - showDialogWidth);
        //$('#showDetailsDialog').css('top', '20.5%');
        //$('#showDetailsDialog').css('left', notesWidth - showDialogWidth);

        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');
        $('.fr-wrapper').attr('style','height:68%;overflow:auto;')
        //$('.fr-wrapper').css('height', '147px !important');
        //$('.fr-wrapper').css('overflow', 'auto');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '81%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '14%');
        } else {
          $('#txtLongNoteDesc').css('height', '96%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      } else {

        var gridOffset = $("#notesGrid").offset();
        //$('#showDetailsDialog').css('top', gridOffset.top);
        //$('#showDetailsDialog').css('height', '30%');
        //$('#showDetailsDialog').css('left', '69%');

        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '53%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '33%');
        } else {
          $('#txtLongNoteDesc').css('height', '89%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      }
      //$('#showDetailsDialog').css('border-radius', '4px');
      //$('#showDetailsDialog').css('border', '3px solid');
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));

  }

  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
     
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();

  }
  saveEditedNote() {

    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.GetAllNotes(selfObj.contactid, 'Contact');
        selfObj.closeDetailsDialog();

      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
  }


  first() {

    this.currentPage = 1;
    this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
  }
  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
    }
  }
  next() {
    if (this.currentPage != this.totalnumberofPages) {
      this.currentPage++;
      this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
    }

  }
  last() {
    this.currentPage = this.totalnumberofPages;
    this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
  }
  calanderclick(val) {
    this.calanderonoff = val;
    this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
    //this.loadallaccounts($("#userids").val(), true, 1, this.pageSize, this.active_deactiveStatus);
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }
  }
  loadselectedcontacts(str, val) {
     
    if (str == "formerCustomer")
      this.isFormerCustomercontacts = val;
    let items = [];
    if (this.isCustomercontacts) {
      items.push('1');
    }
    else {
      items = $.grep(items, function (value) {
        return value != '1';
      });
    }
    if (this.isFormerCustomercontacts) {
      items.push('2');
    }
    else {
      items = $.grep(items, function (value) {
        return value != '2';
      });
    }
    this.Contactvales = items.toString();
    this.initializeAgGrid();
    this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);
  }

  MapItEventListner() {
    if (this.applicationComponent.isMapItEventRaised) {
      
    } else { this.applicationComponent.googlemapshow = false; }
  }

  SelectCustomerType(val) {
    if (val == 'Display account') {
      this.router.navigateByUrl(`/application/account/Customer`);
    }
    this.customerService.UpdateCustomerType(val, this.currentuser.id).then((user) => {
      localStorage.setItem('CustomerType', user.CustomerTypeView);
    });
  }
  UserlistDataReceived(outProps: OutputPropsUserList) {
  
    if (outProps.enableSearch) { this.EnableSearch = true; }
    this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
    if (outProps.fifthDrpValue == "No Shared Contacts") {
      this.isSharedAccts = false;
    } else if (outProps.fifthDrpValue == "All Shared Contacts") { this.isSharedAccts = true; }
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.Contactvales = (outProps.secondDrpValue == undefined || outProps.secondDrpValue == '0') ? "1" :
      outProps.secondDrpValue;
    if (outProps.thirdDrpValue != "0" && outProps.thirdDrpValue != undefined && outProps.thirdDrpValue != "") {   
      this.allContacts = this.OriginalallContacts.filter(x => x.contacttypeName == outProps.thirdDrpValue)
    } else if (outProps.fourthDrpValue != "0" && outProps.fourthDrpValue != undefined && outProps.fourthDrpValue != "All Activity" && outProps.fourthDrpValue != "") {
      this.opprtunityservice.GetAlllastActivityContacts($("#userids").val(), outProps.fourthDrpValue).then((result) => {     
        this.allContacts = result.filter(x => x.customertype == '1');
      });

    } else {
      if (outProps.finalitems != "") {
        $("#userids").val(outProps.finalitems);
        if (!outProps.pageSizeChanged) {
          if (outProps.custType !== undefined) {
            this.SelectCustomerType(outProps.custType);
          }    
          if (outProps.secondDrpValue == '1') {
            this.selectedStatus = 309;
          } else if (outProps.secondDrpValue == '2') {
            this.selectedStatus = 310;
          }         
          this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, 0);
        }
      } else {
        this.allContacts = [];
        $("#userids").val('');
      }
    }
  }

  expandContactView(value) {
    if (value === 'Collapse') {
      this.isContactViewOpen = false;
      this.condashcmp.SixBoxExpand = false;
      this.condashcmp.tab = 'QuickView';
    }
    else {
      this.isContactViewOpen = true;
      this.condashcmp.SixBoxExpand = false;
      this.condashcmp.tab = 'QuickView';
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.gridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.contactid) {
            selfObj.gridOptions.api.selectNode(node, true);
            selfObj.gridOptions.rowSelection = 'single';
          }
        });
      }, 500));
    }
  }
  CloseContactDiv() {
    this.showContactDashBoard = false;
    this.isContactViewOpen = true;
    if (this.currentuser.defaultSearchPage == 1) { this.EnableSearch = true; } else { this.EnableSearch = false; }
  }

  onContactMessageReceived(msg) {
    this.isShowAccountInfo = true;
  }
  onFilterTextBoxChanged() {
    if (this.currentuser.hidecustomer == 1 && this.EnableSearch) {
      this.allContacts = this.OriginalallContacts.filter(x => x.firstname.indexOf(this.value) || x.lastname.indexOf(this.value));
    }
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
    this.gridOptions.quickFilterText = (document.getElementById('filter-text-box') as HTMLInputElement).value;
    this.createColumnDefs(this.colSelections, true);
    this.gridApi.redrawRows(); 
     
  }
  ClearSearch() {
    this.gridApi.setQuickFilter('');
    this.value = "";
    if (this.currentuser.defaultSearchPage == 1) {
      this.EnableSearch = true;
      this.allContacts = [];
    } else {
      if (this.currentuser.hidecustomer == 1) { this.allContacts = []; }
    this.EnableSearch = false;
    }
  }
}
