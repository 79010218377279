import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions, RowDragEndEvent } from 'ag-grid';
import { ToastrService } from 'ngx-toastr';
import { AuditLogModel } from '../../models/auditlog';
import { CampaignExpense, CampaignType } from '../../models/CampaignConfiguration';
import { LeadsService } from '../../services/leads.service';
import { ApplicationComponent } from '../application.component';
import { SystemRowConfiguration } from '../../models/system-row-configuration';

@Component({
  selector: 'app-campaign-configuration',
  templateUrl: './campaign-configuration.component.html',
  styleUrls: ['./campaign-configuration.component.css']
})
export class CampaignConfigurationComponent implements OnInit {
  CampaignConfigMainDiv: boolean = true;
  CampaignTypesDiv: boolean = false;
  CampaignExpensesDiv: boolean = false;
  ExpandCampaignTypesGrid: boolean = false;
  CampaignTypeForm: FormGroup;
  CampaignType: CampaignType = new CampaignType();
  AllCampaignTypes: CampaignType[];
  AllCampaignExpsnses: CampaignExpense[];
  currentUser: any;
  public gridOptions: GridOptions;
  public sideBar;
  public columnDefs: any[];
  CampaignTypeFormDisable: boolean = true;

  ExpandCampaignExpenseGrid: boolean = false;
  CampaignExpenseForm: FormGroup;
  CampaignExpense: CampaignExpense = new CampaignExpense();
  CampaignExpenseFormDisable: boolean = true;
  @Input() type: string = '600';
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration();
  constructor(public applicationComponent: ApplicationComponent, private leadsService: LeadsService, private toastr: ToastrService) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }
  
  ngOnInit(): void {
   
    this.AccessDiv(this.type);
      var obj: AuditLogModel = { id: 0, type: 'Campaign Configuration', action: 'Campaign Config', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.currentUser.id, dateofviewed: '', customertype: '', time: '', Title: 'Campaign Config ', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
      this.applicationComponent.InsertAuditHistory(obj);
    //}
  }


  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
    
  }
  CloseDiv(val) {
    if (val == '600') {
      this.CampaignConfigMainDiv = true;
      this.CampaignTypesDiv = false;
      this.CampaignExpensesDiv = false;
      this.CampaignTypeFormDisable = true;
    } else if (val == "601") {
      this.CampaignConfigMainDiv = true;
      this.CampaignTypesDiv = false;
      this.CampaignExpensesDiv = false;
      this.CampaignExpenseFormDisable = true;
    }
  }
  AccessDiv(val) {
    if (val == '600') {
      this.CampaignConfigMainDiv = false;
      this.CampaignTypesDiv = true;
      this.CampaignExpensesDiv = false;
      this.initializeAgGrid(val);

    } else if (val == '601') {
      this.CampaignConfigMainDiv = false;
      this.CampaignTypesDiv = false;
      this.CampaignExpensesDiv = true;
      this.initializeAgGrid(val);
    }
    var obj: AuditLogModel = { id: 0, type: 'Campaign Configuration', action: 'Campaign Config - ' + val, AccountOrContactId: 0, AccountOrContact: val, documentid: 0, userid: this.currentUser.id, dateofviewed: '', customertype: '', time: '', Title: 'Campaign Config - ' + val, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }

  ClearForm(val) {
    if (val == '600') {
      this.CampaignType = new CampaignType();
      this.CampaignTypeFormDisable = true;
    } else if (val == 'CampaignExpenses') {
      this.CampaignExpense = new CampaignExpense();
      this.CampaignExpenseFormDisable = true;
    }
  }
  SaveCampaignType() {
    if (this.CampaignType.CampaignTypeId == null || this.CampaignType.CampaignTypeId == undefined || this.CampaignType.CampaignTypeId == 0) {
      this.CampaignType.CreatedBy = this.currentUser.id;
    }
    this.leadsService.AddUpdateCampaignType(this.CampaignType).then(result => {
      this.toastr.success("Campaign Type saved successfully.", "Campaign Type Saved", { timeOut: 600 });
      this.ClearForm('600');
      this.GetCampaignTypes();
    });
  }
  SaveCampaignExpense() {
    this.CampaignExpense.CreatedBy = this.currentUser.id;
    this.leadsService.AddUpdateCampaignExpenses(this.CampaignExpense).then(result => {
      this.toastr.success("Campaign Expense saved successfully.", "Campaign Expense Saved", { timeOut: 600 });
      this.ClearForm('CampaignExpenses');
      this.GetCampaignExpenses();
    });
  }
  GetCampaignTypes() {
    this.leadsService.GetCampaignTypes(this.currentUser.id).then(result => {
      this.AllCampaignTypes = result;
    });
  }

  GetCampaignExpenses() {
    this.leadsService.GetCampaignExpenses(this.currentUser.id).then(result => {
      this.AllCampaignExpsnses = result;
    });
  }
  initializeAgGrid(val) {
    this.gridOptions = <GridOptions>{
      enableColResize: true, enableSorting: true, paginationPageSize: 1000, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true,
      toolPanelSuppressValues: true, enableCellChangeFlash: true, enableMultiRowDragging: true,

    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createColumnDefs(val);
    if (val == '600') {
      this.GetCampaignTypes();
    } else if (val == '601') {
      this.GetCampaignExpenses();
    }
    
  }
  createColumnDefs(val) {
   
    let selfObj = this;
    if (val == '600') {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 65,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true,
        },
        { field: 'CampaignTypeId', headerName: 'Id', hide: true, width: 120 },
        { field: 'SortOrder', headerName: 'Sort Order', hide: false, width: 120 },
        {
          field: 'CampaignTypeName', headerName: 'Campaign Type Description', hide: false, width: 450,
        },
        
        {
          field: 'action', headerName: 'Action', hide: false, width: 100, cellRenderer(params) {
            var eDiv = document.createElement('div');
            if (params.data.isdeleted == 1) {
              eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size: 18px;"></i>';
            } else {
              eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size: 18px;"></i>';
            }
            
            eDiv.addEventListener('click', function () {
              selfObj.deleteCampaignType(params.data);
            });
            return eDiv;
          }
        }
      ];
    } else if (val == "CampaignExpenses"|| val=='601') {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 65,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true,
        },
        { field: 'CampaignExpenceId', headerName: 'Id', hide: true, width: 120 },
        { field: 'SortOrder', headerName: 'Sort Order', hide: false, width: 120 },
        {
          field: 'CampaignExpenseName', headerName: 'Campaign Type Description', hide: false, width: 450,
        },
        {
          field: 'action', headerName: 'Action', hide: false, width: 100, cellRenderer(params) {
            var eDiv = document.createElement('div');
            if (params.data.isdeleted == 1) {
              eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size: 18px;"></i>';
            } else {
              eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size: 18px;"></i>';
            }

            eDiv.addEventListener('click', function () {
              selfObj.deleteCampaignExpense(params.data);
            });
            return eDiv;
          }
        }
      ];
    }
    

  }
  onCampaignTypeSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.CampaignType = event.api.getSelectedNodes()[0].data;
        this.CampaignTypeFormDisable = false;
      }
    } else {
      this.ClearForm('600');
    }
  }
  onCampaignExpenseSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.CampaignExpense = event.api.getSelectedNodes()[0].data;
        this.CampaignExpenseFormDisable = false;
      }
    } else {
      this.ClearForm('CampaignExpenses');
    }
  }

  ShowForm(val) {
    if (val == 'CampaignTypes') {
      this.CampaignTypeFormDisable = false;
    } else if (val == 'CampaignExpenses') {
      this.CampaignExpenseFormDisable = false;
    }
  }

  deleteCampaignType(campaign) {
    this.CampaignType = campaign;
    if (this.CampaignType.isdeleted == 1) {
      this.CampaignType.isdeleted = 0;
    } else {
      this.CampaignType.isdeleted = 1;
    }
    
    this.SaveCampaignType();
    

  }


  deleteCampaignExpense(campaign) {
    this.CampaignExpense = campaign;
    if (this.CampaignExpense.isdeleted == 1) {
      this.CampaignExpense.isdeleted = 0;
    } else {
      this.CampaignExpense.isdeleted = 1;
    }

    this.SaveCampaignExpense();


  }

  onRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("600");
  }
  onRowDragEndExpense(event: RowDragEndEvent) {
    this.saverowconfiguration("601");
  }
  onAgGridReady(event) {
    this.gridOptions.api = event.api;
  }
  onAgGridReadyExpense(event) {
    this.gridOptions.api = event.api;
  }
  saverowconfiguration(type) {
    var sortoorder = [];
    if (type == "600") {
      this.gridOptions.api.forEachNode((node) => {
        sortoorder.push(node.data.CampaignTypeId);
      });
    } else if (type == "601") {
      this.gridOptions.api.forEachNode((node) => {
        sortoorder.push(node.data.CampaignExpenceId);
      });
    } 
    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i == a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentUser.id;
    this.leadsService.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });

    });

  }
}
