import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef, ViewEncapsulation, Pipe, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsService } from './../../services/accounts.service';
import { MailserviceService } from './../../services/mailservice.service';
import { MessagingService } from './../../services/messaging.service';
import { Customers } from '../../models/customers';
import { Emails, Attachements } from '../../models/emails';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { DatePipe } from '@angular/common';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import 'rxjs/add/operator/map'
import { ApplicationComponent } from '../application.component';
declare var $: any;
import 'rxjs/Rx';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.css']
})
export class EmailsComponent implements OnInit {
  datePipe: DatePipe = new DatePipe("en-US");
  sentemaillist: Array<Emails> = [];
  attachemnts: Array<Attachements> = [];
  //attachemnts: Attachements[] = [];
  contactList = [];
  finalitems = "";
  bccitems = "";
  public emailcolumnDefs: any[];
  selectedItems = [];
  BccselectedItems = [];
  public gridemailOptions: GridOptions;
  contactslist: Array<Customers> = [];
  currentuser: any;
  selectedType = "";
  typeSelected = '';
  emails: Emails = new Emails(); contactsids: string = "";
  messagedescription: string;
  accountName: string;
  SentEmailTable: boolean = false;
  itemList: any = [];
  bccitemList: any = [];
  settings = {};
  errorMessage: string;
  filesToUpload: Array<File>;
  selectedFileNames: string[] = [];
  emailAlert: boolean = false;
  public isLoadingData: Boolean = false;
  @ViewChild('fileUpload') fileUploadVar: any;
  uploadResult: any;
  res: Array<string>;
  totalContacts: number = 0;
  grayedContacts: number = 0;
  selectedLabel: string = "Contacts Selected";
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  public sideBar;
  constructor(public applicationComponent: ApplicationComponent,private toastr: ToastrService, private http: HttpClient, private route: ActivatedRoute, private opprtunityservice: OpprtunitiesService, private viewContainerRef: ViewContainerRef, private accountsService: AccountsService, private mailservice: MailserviceService, private messagingservice: MessagingService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentuser.email = this.currentuser.email;
  }
  onSearchstring(msg) {

  }
  ngOnInit() {
    $(".checkMark").removeClass("activeColor");
    $(".subButtons").removeClass("activeButton");
    $(".fa-check").removeClass("activeColor");
    var selected = '';
    if (document.getElementById('subTab') != undefined && document.getElementById('subTab') != null)
      document.getElementById('subTab').style.display = 'block';
    this.route.params.subscribe(params => {
      this.contactsids = params['ids'];
      let type = params['type'];
      this.selectedType = type;
      this.typeSelected = type
      // this.GetMajorAccountId(this.contactsids)
      this.GetAllContactsnames(this.contactsids, type);
      if (type == 'Customer') {
        //$("#coustomers_span").addClass("activeColor");
        //$("#coustomers").addClass("mainactiveButton");
        //document.getElementById('selectedLabel').innerHTML = 'Customers Selected';
        this.selectedLabel = "Customers Selected";
      } else {
        //$("#contact_span").addClass("activeColor");
        //$("#contact").addClass("mainactiveButton");
        //document.getElementById('selectedLabel').innerHTML = 'Contacts Selected';
        this.selectedLabel = "Contacts Selected";
      }
      //}
      $("#email").addClass("activeColor");
      $("#email_button").addClass("activeButton");

    });
    this.settings = {
      text: "Select Contacts",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      classes: "myclass custom-class",
      primaryKey: "id",
      labelKey: "name",
      noDataLabel: "Search Contacts...",
      enableSearchFilter: true,
      //searchBy: ['name', 'name']
    };
    //this.opprtunityservice.GetAllContactsbySearchPagination(null, this.currentuser.id, this.subtype, active_deactiveStatus, this.startDateVal, this.endDateVal, startrow, endrow).then((contact) => {
    //  contact.forEach(contact => {
    //    contact.created = this.datePipe.transform(contact.created, "MM-dd-yyyy");
    //    contact.speciality = ((contact.speciality == 'null') ? "" : contact.speciality);
    //  });

    //  this.contactList = contact;
    this.applicationComponent.pageViewed = "CreateEmail";
  }
  onSearch(evt: any) {
    this.itemList = [];

    this.opprtunityservice.GetContactsForEmail(evt.target.value, this.currentuser.id).then((con) => {
       
      //con.forEach(contact => {
      //   
      //  let drpDwn: Dropdown = new Dropdown();
      //  drpDwn.id = contact.id.toString();
      //  drpDwn.itemName = contact.itemName;
      //  drpDwn.Text = contact.itemName;
      //  drpDwn.Value = contact.id.toString();
      //  this.itemList.push(drpDwn);
      //});

      this.itemList = con;
    });
  }

  bcconSearch(evt: any) {
    this.itemList = [];

    this.opprtunityservice.GetContactsForEmail(evt.target.value, this.currentuser.id).then((con) => {
       
      //con.forEach(contact => {
      //   
      //  let drpDwn: Dropdown = new Dropdown();
      //  drpDwn.id = contact.id.toString();
      //  drpDwn.itemName = contact.itemName;
      //  drpDwn.Text = contact.itemName;
      //  drpDwn.Value = contact.id.toString();
      //  this.bccitemList.push(drpDwn);
      //});

      this.bccitemList = con;
    });
  }
  onsentemailAgGridReady() {
    this.gridemailOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    }; this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.emailscreateColumnDefs("");
    this.gridemailOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
  }
  emailscreateColumnDefs(columnSelections: string) {
    this.emailcolumnDefs = [
      { field: 'tousernames', headerName: 'To', hide: false },
      { field: 'subject', headerName: 'Subject', hide: false },
      { field: 'sentemailDate', headerName: 'Sent Date', hide: false },
      { field: 'messagebody', headerName: ' Message', tooltipField: 'messagebody', hide: false },

    ];

  }
  Email(val) {
    if (val == "SentMessage") {
      this.SentEmailTable = true;
      this.onsentemailAgGridReady();
      this.messagingservice.GetAllSentMessagesList(this.currentuser.id).then((sentmessageslist) => {
        sentmessageslist.forEach(document => {
          document.sentemailDate = this.datePipe.transform(document.sentemailDate, "MM-dd-yyyy");
          document.messagebody = document.messagebody.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/img, "");

        });
        this.sentemaillist = sentmessageslist;
      });
    }
    else {
      this.SentEmailTable = false;
    }
  }
  public GetAllContactsnames(contactsids, type) {
     
    this.accountsService.GetAllSelectedContacts(contactsids, type).then((Contacts) => {
      Contacts.forEach(Contacts => {
      });
       
      this.contactslist = Contacts;
      this.totalContacts = this.contactslist.length;

      this.grayedContacts = 0;
      this.contactslist.forEach(con => {
         
        if (con.color == "lightgray")
          this.grayedContacts += 1;
      });

      var nameLast = "";
      var Emailids = "";
      var selected = '';
      var selectedLastName = '';
      var selectedId = '';
      for (var i = 0; i < Contacts.length; i++) {
        if (i == 0) {
          nameLast = Contacts[i].last
        } else {
          var t = Contacts.length;
          t--;
          if (i == t) {
            nameLast = nameLast + ' and ' + Contacts[i].last
          } else {
            nameLast = nameLast + ' , ' + Contacts[i].last
          }

        }
         
        if (i == 0) {
          if (Contacts[i].email != null) {
            Emailids = Contacts[i].email
          }
        } else {
          if (Contacts[i].email != null) {
            Emailids = Emailids + ' , ' + Contacts[i].email
          } else { Emailids = Emailids; }
        }

        if (i == 0) {
          selectedLastName = Contacts[i].last
          selectedId = Contacts[i].id.toString();
          if (Contacts.length == 1) {
            selectedLastName = selectedLastName + ','
            selectedId = selectedId + ','
          }
        } else {
          selectedLastName = selectedLastName + ',' + Contacts[i].last
          selectedId = selectedId + ',' + Contacts[i].id
        }

         
        let rmvId: string = "rmvSelCon" + i;
        let conId = Contacts[i].id;

        selected = selected + '<div class="col-xs-4" style="text-align:center;margin-top: 0.3em;padding:0" id= "' + Contacts[i].id + '">';
        if (this.selectedType == 'Customer') {
          //selected = selected + '<button class="btn provider" style="width:100%;padding-top: 2%;padding - bottom: 2%;background-color: #33CC33 !important;" id="' + Contacts[i].id + '" >' + Contacts[i].first + '<a style="float:right;color: black" onclick="removeSelected(' + Contacts[i].id + ')">X</a></button>';
        } else {
          if (Contacts[i].color != 'lightgray') { this.accountName = Contacts[i].accountname }
          let contactName: string = Contacts[i].first + ' ' + Contacts[i].last;
          if (contactName.length > 20) {
            contactName = contactName.substr(0, 20);
            contactName += "..";
          }
           
          selected = selected + '<button class="btn emailContactButton" style="width:97%;border:solid 1px !important;height:30px;cursor:default;padding-top: 2%;padding-bottom: 2%;background-color:' + Contacts[i].color + '!important;" id="' + Contacts[i].id + '" >' + contactName + '<div id="' + rmvId + '" style="float:right; color:black; padding-right:5px; cursor:pointer; font-size:9px; font-weight:600">X</div></button>';
        }
        selected = selected + '</div>'

        let selfObj = this;
        $(setTimeout(function () {
           
          $("#" + rmvId).click(function () {
             
            selfObj.removeSelected(conId);
          });
        }, 1000));
      }
      //alert(selectedId);      

      $('#selectedId').val(selectedId);
      $('#selectedLastName').val(selectedLastName);
      document.getElementById('selectedContacts').innerHTML = selected;
      $("#To").val(Emailids);
      document.getElementById('nameLast').innerHTML = 'Dear Doctors ' + nameLast + ',';
    });
  }

  removeSelected(id) {
     

    let indRmv: number = -1;
    let cnt = 0;
    this.contactslist.forEach(con => {
      if (con.id == id)
        indRmv = cnt;

      cnt++;
    });

    this.contactslist.splice(indRmv, 1);

    this.totalContacts = this.contactslist.length;

    this.grayedContacts = 0;
    this.contactslist.forEach(con => {
       
      if (con.color == "lightgray")
        this.grayedContacts += 1;
    });

    document.getElementById(id).remove();
    var selectedIds = $('#selectedId').val();
    var selectedEmals = $('#To').val();
    var selectedIdsArray = selectedIds.split(',');
    var selectedEmalsArray = selectedEmals.split(',');
    var selectedLastName = $('#selectedLastName').val();
    var selectedLastNameArray = selectedLastName.split(',');
    var finalselectedIds = '';
    var finalselectedemails = '';
    var finalselectedLastNameArray = '';
    for (var i = 0; i < selectedIdsArray.length; i++) {
      if (selectedIdsArray[i] != id) {
        if (selectedIdsArray[i] != '') {
          if (finalselectedIds == '') {
            finalselectedIds = selectedIdsArray[i];
            finalselectedemails = selectedEmalsArray[i];
            finalselectedLastNameArray = selectedLastNameArray[i];
          } else {
            finalselectedIds = finalselectedIds + ',' + selectedIdsArray[i];
            finalselectedemails = finalselectedemails + ',' + selectedEmalsArray[i];
            finalselectedLastNameArray = finalselectedLastNameArray + ',' + selectedLastNameArray[i];
          }
        }
      }
    }
    $('#selectedId').val(finalselectedIds);
    $('#To').val(finalselectedemails);
    $('#selectedLastName').val(finalselectedLastNameArray);
    document.getElementById('nameLast').innerHTML = 'Dear Doctors ' + finalselectedLastNameArray;
  }

  onItemSelect(item: any) {
     
    ////var ids = item.id;
    //if ($("#ccids").val() != "")
    //  this.finalitems = $("#ccids").val() + ",";
    //else
    //  this.finalitems = "";
    //this.finalitems += item.email + ',';
    //$("#ccids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));

    if (this.emails.ccids == null || this.emails.ccids == undefined)
      this.emails.ccids = "";

    if (item.email != null && item.email.trim() != "")
      this.emails.ccids += item.email + ",";
  }
  OnItemDeSelect(item: any) {
     
    let ids: string[] = [];

    if (this.emails.ccids != null && this.emails.ccids != undefined)
      ids = this.emails.ccids.split(',');

    let cnt = 0;
    let rmvIndx = -1;
    ids.forEach(id => {
      if (id == item.email)
        rmvIndx = cnt;
      cnt++;
    });

    if (rmvIndx >= 0)
      ids.splice(rmvIndx, 1);

    this.emails.ccids = "";

    ids.forEach(id => {
      if (id.trim() != "")
        this.emails.ccids += id + ",";
    });

    //if (this.emails.ccids.length > 0)
    //  this.emails.ccids.substr(0, this.emails.ccids.length - 1);
  }
  onSelectAll(items: any) {
     
    //var ids = "";

    //for (var i = 0; i < items.length; i++) {
    //  ids += items[i].id + ',';
    //}
    //this.finalitems = "";
    //this.finalitems = ids;
    //$("#ccids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));

    this.emails.ccids = "";
    items.forEach(item => {
      if (item.email != null && item.email.trim() != "")
        this.emails.ccids += item.email + ",";
    });
  }
  onDeSelectAll(items: any) {
     
    //this.finalitems = "";
    //$("#ccids").val('');

    this.emails.ccids = "";
  }

  BcconItemSelect(item: any) {
     
    //var ids = item.id;
    //this.bccitems = $("#Bccids").val() + ",";
    //this.bccitems += ids + ',';
    //$("#Bccids").val(this.bccitems.substring(0, this.bccitems.length - 1).replace('undefined', ""));

    if (this.emails.bccids == null || this.emails.bccids == undefined)
      this.emails.bccids = "";

    if (item.email != null && item.email.trim() != "")
      this.emails.bccids += item.email + ",";
  }
  BccOnItemDeSelect(item: any) {
     
    //var ids = item.id;
    //var result = "";
    //this.bccitems = $("#Bccids").val();
    //var dfi = this.bccitems.split(',');
    //for (var i = 0; i < dfi.length; i++) {
    //  if (dfi[i] != ids && dfi[i] != "") {
    //    result += dfi[i] + ',';
    //  }
    //}
    //this.bccitems = result;
    //if (this.bccitems != "") {
    //  $("#Bccids").val(this.bccitems.substring(0, this.bccitems.length - 1).replace('undefined', ""));
    //} else {
    //  $("#Bccids").val('');
    //}

    let ids: string[] = [];

    if (this.emails.bccids != null && this.emails.bccids != undefined)
      ids = this.emails.bccids.split(',');

    let cnt = 0;
    let rmvIndx = -1;
    ids.forEach(id => {
      if (id == item.email)
        rmvIndx = cnt;
      cnt++;
    });

    if (rmvIndx >= 0)
      ids.splice(rmvIndx, 1);

    this.emails.bccids = "";

    ids.forEach(id => {
      if (id.trim() != "")
        this.emails.bccids += id + ",";
    });
  }
  BcconSelectAll(items: any) {
     
    //var ids = "";

    //for (var i = 0; i < items.length; i++) {
    //  ids += items[i].id + ',';
    //}
    //this.bccitems = "";
    //this.bccitems = ids;
    //$("#Bccids").val(this.bccitems.substring(0, this.bccitems.length - 1).replace('undefined', ""));

    this.emails.bccids = "";
    items.forEach(item => {
      this.emails.bccids += item.email + ",";
    });
  }
  BcconDeSelectAll(items: any) {
     
    //this.bccitems = "";
    //$("#Bccids").val('');

    this.emails.bccids = "";
  }

  emailAlertResponse(isSend: boolean) {
    if (isSend) {   //Send Email after BCCing all To Email Ids
       
      if (this.emails.ccids == null || this.emails.ccids == undefined)
        this.emails.ccids = "";
      if (this.emails.ccids.length > 0 && this.emails.ccids[this.emails.ccids.length - 1] == ",")
        this.emails.ccids = this.emails.ccids.substr(0, this.emails.ccids.length - 1);

      if (this.emails.bccids == null || this.emails.bccids == undefined)
        this.emails.bccids = "";
      if (this.emails.bccids.length > 0 && this.emails.bccids[this.emails.bccids.length - 1] == ",")
        this.emails.bccids = this.emails.bccids.substr(0, this.emails.bccids.length - 1);

      this.emails.to = $('#To').val();

      if (this.emails.to != null && this.emails.to.length > 0) {
        let toIds: string[] = this.emails.to.split(',');

        if (toIds.length > 0) {
          this.emails.to = toIds[0];

          if (toIds.length > 1 && this.emails.bccids.length > 0 && this.emails.bccids[this.emails.bccids.length - 1] != ",") {
            this.emails.bccids += ",";
          }

          let cnt = 0;
          toIds.forEach(id => {
            if (cnt > 0) {
              if (this.emails.bccids.indexOf(id) < 0)
                this.emails.bccids += id + ",";
            }

            cnt++;
          });
        }

        this.SendEmail();
      }

      this.emailAlert = false;
    } else {
      this.emailAlert = false;
    }
  }

  SendEmail() {
     

    //this.emails.bccids = $("#Bccids").val();
    //this.emails.ccids = $("#ccids").val();

    if (this.emails.ccids == null || this.emails.ccids == undefined)
      this.emails.ccids = "";
    if (this.emails.ccids.length > 0 && this.emails.ccids[this.emails.ccids.length - 1] == ",")
      this.emails.ccids = this.emails.ccids.substr(0, this.emails.ccids.length - 1);

    if (this.emails.bccids == null || this.emails.bccids == undefined)
      this.emails.bccids = "";
    if (this.emails.bccids.length > 0 && this.emails.bccids[this.emails.bccids.length - 1] == ",")
      this.emails.bccids = this.emails.bccids.substr(0, this.emails.bccids.length - 1);

    this.emails.fromuserid = this.currentuser.id;
    this.emails.toids = $('#selectedId').val();
    this.emails.subject = $('#Subject').val();
    this.emails.messagebody = $('.fr-view').html();
    this.emails.from = this.currentuser.email;
    if (!this.emailAlert)
      this.emails.to = $('#To').val();

    if (this.emails.to.trim() == "") {
      alert("Please enter To Email Ids.");
    } else if (this.emails.subject.trim() == "") {
      alert("Subject is Required to Send Emails. Please Select OK and then Enter a Subject Name.");
    } else if (this.grayedContacts != 0 && !this.emailAlert) {
      this.emailAlert = true;
    } else {
      this.emails.attachements = this.attachemnts;
      this.mailservice.SendEmails(this.emails).then(result => {
        this.toastr.success("Email Sent successfully.", "Email Sent", { timeOut: 600 });
        this.clearfields();
      });
    }
  }
  clearfields() {
    this.bccitems = "";
    this.finalitems = "";
    this.selectedItems = [];
    this.BccselectedItems = [];
    $('#Subject').val("");
    $("#Bccids").val('');
    $("#ccids").val('');
    this.cancelUpload();
  }
  fileChangeEvent(fileInput: any) {
    this.uploadResult = "";
    this.attachemnts = [];
    this.filesToUpload = <Array<File>>fileInput.target.files;

    for (let i = 0; i < this.filesToUpload.length; i++) {
      const reader = new FileReader();
      const [file] = this.filesToUpload;
      reader.readAsDataURL(file);
      //let oatt = new Attachements();
      this.selectedFileNames.push(this.filesToUpload[i].name);
      //oatt.filename = this.filesToUpload[i].name;
      //oatt.filetype = this.filesToUpload[i].type;
      //oatt.imagecontent = reader.result;
      //this.attachemnts.push(oatt);
      let objSelf = this;
      $(setTimeout(function () {
        objSelf.GetImagedata(reader, objSelf.filesToUpload[i].name, objSelf.filesToUpload[i].size, objSelf.filesToUpload[i].type);
        //objSelf.selectedFileNames.push(file.name);
      }, 500));
    }
    this.upload();

  }
  //fileChangeEvent(event) {
  //  this.uploadResult = "";
  //  this.attachemnts = [];
  //  //this.filesToUpload = event.target.files.length;
  //  for (let i = 0; i < event.target.files.length; i++) {

  //    const reader = new FileReader();
  //    const [file] = event.target.files;
  //    reader.readAsDataURL(file);
  //   // this.selectedFileNames.push(file.name);
  //    let objSelf = this;
  //    $(setTimeout(function () {
  //      objSelf.GetImagedata(reader, file.name, file.size, file.type);
  //      objSelf.selectedFileNames.push(file.name);
  //    }, 500));
  //  }
  //  //if (event.target.files && event.target.files.length) {
  //  //   
  //  //  const reader = new FileReader();
  //  //  const [file] = event.target.files;
  //  //  reader.readAsDataURL(file);
  //  //  let objSelf = this;
  //  //  $(setTimeout(function () {
  //  //    objSelf.GetImagedata(reader, file.name, file.size, file.type);
  //  //  }, 500));
  //  //}


  //  this.upload();
  //}
  GetImagedata(reader, filename, filesize, filetype) {
    let oatt = new Attachements();
    oatt.filename = filename;
    oatt.filetype = filetype;
    oatt.imagecontent = reader.result;
    this.attachemnts.push(oatt);
  }
  cancelUpload() {
    this.attachemnts = [];
    this.filesToUpload = [];
    this.fileUploadVar.nativeElement.value = "";
    this.selectedFileNames = [];
    this.uploadResult = "";
    this.errorMessage = "";
  }
  upload() {
    this.uploadResult = "";

    if (this.filesToUpload.length > 0) {
      this.isLoadingData = true;

      let formData: FormData = new FormData();

      for (var i = 0; i < this.filesToUpload.length; i++) {
        formData.append('uploadedFiles', this.filesToUpload[i], this.filesToUpload[i].name);
      }

      //let apiUrl = "http://localhost:14164/api/messaging/UploadFiles";
      let apiUrl = "http://mdlogiccrm.com:8080/api/messaging/UploadFiles";
      this.http.post(apiUrl, formData).pipe(map((res: Response) => res.json()))
        .subscribe
        (
        data => {
          this.uploadResult = data;
          this.errorMessage = "";
        },
        err => {
          console.error(err);
          this.errorMessage = err;
          this.isLoadingData = false;
        },
        () => {
          this.isLoadingData = false,
            this.fileUploadVar.nativeElement.value = "";
          this.selectedFileNames = [];
          this.filesToUpload = [];
        }
        );
    }
  }
}
