import { Injectable } from '@angular/core';

@Injectable()
export class AppSettings {
    get baseUrl(): string { return localStorage.getItem("baseUrl"); }
    set baseUrl(val: string) { localStorage.setItem("baseUrl", val); }
  

    constructor() {

    }
}