export class Practice {
    public id: number;
    public practice: string;
    public city: string;
    public state: string;
    public zip: string;
    public email: string;
    public phone: string;
    public address1: string;
    public address2: string;
    public owner: string;
    public cell: string;
    public created: string;
    public website: string;
}
