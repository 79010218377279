<div class="col-xs-12" id="AddAccountDiv" style="height:100%;padding:0;">
  <form #accountform="ngForm" (keydown.enter)="$event.preventDefault()" style="height:100%">
    <div class="col-xs-12" style="padding-left:0;padding-right: 0;height:100%" id="boxDiv">
      <div id="addAccountDetais" style="height:100%">
        <div class="col-xs-12" style="height: 2%;background-color:orangered">
          
        </div>
        <div class="col-xs-12" style="height: 4%;">
          <h4>
            <label *ngIf="(account.id==0)">ADD NEW ACCOUNT</label>
            <label *ngIf="(account.id!=0)">EDIT ACCOUNT</label>
            <label style="float:right"> <img src="../../../assets/img/Black x.png" (click)="(FormDataModified)? (DiscardFormDataConfirmationCss()):cancelAccount()" style="height:22px" /></label>
          </h4>
        </div>
        <div class="col-xs-12" id="accountHeader" style="text-align:center;color:black; border-radius:3px;margin-top: 0;height:14%;padding:0;" *ngIf="!ExpandMode">
          <div class="col-xs-12" style="padding:0;height:100%;">
            <div class="AddAcctabset" style="padding:0">

              <input type="radio" name="AddAcctabset" id="AddAcctab4" value="AddAcctab4" aria-controls="bano" [(ngModel)]="AddAccselectedMenuRadio" checked />
              <label for="AddAcctab4" style="text-align:center;width:24%" (click)="AddAcctabChanged('AddAcctab4')"> PRIMARY ADDRESS </label>
              <input type="radio" name="AddAcctabset" id="AddAcctab1" value="AddAcctab1" aria-controls="bano" [(ngModel)]="AddAccselectedMenuRadio" />
              <label for="AddAcctab1" style="text-align:center;width:24%" (click)="AddAcctabChanged('AddAcctab1')"> ACCOUNT SPECIFICS </label>
              <input type="radio" name="AddAcctabset" id="AddAcctab5" value="AddAcctab5" disabled aria-controls="bano" [(ngModel)]="AddAccselectedMenuRadio">
              <label for="AddAcctab5" style="text-align: center; width: 24%;" (click)="AddAcctabChanged('AddAcctab5')">CUSTOMER DETAILS</label>
              <input type="radio" name="AddAcctabset" id="AddAcctab6" value="AddAcctab6" aria-controls="bano"
                     style="margin-left: 1px" [(ngModel)]="AddAccselectedMenuRadio">
              <label for="AddAcctab6" style="text-align: center; width: 24%;" (click)="AddAcctabChanged('AddAcctab6')">SYSTEM NOTES </label>

              <input type="radio" name="AddAcctabset" id="AddAcctab7" value="AddAcctab7" [(ngModel)]="AddAccselectedMenuRadio" aria-controls="tej" style="margin-left: 1px" [disabled]="this.account.multiplelocations == 0">
              <label for="AddAcctab7" style="text-align:center;width:24%" (click)="AddAcctabChanged('AddAcctab7')"> ADDITIONAL LOCATIONS</label>
              <input type="radio" name="AddAcctabset" id="AddAcctab0" value="AddAcctab0" aria-controls="bano" [(ngModel)]="AddAccselectedMenuRadio" />
              <label for="AddAcctab0" style="text-align:center;width:24%" (click)="AddAcctabChanged('AddAcctab0')"> ACCOUNT HISTORY </label>
              <input type="radio" name="AddAcctabset" id="AddAcctab2" value="AddAcctab2" aria-controls="bano" [(ngModel)]="AddAccselectedMenuRadio" />
              <label for="AddAcctab2" style="text-align:center;width:24%" (click)="(currentuser.SoftwareLicenseAdmin == 1)? AddAcctabChanged('AddAcctab2'): NOLicenseAccess()">CUSTOMER LICENSES</label>
              <input type="radio" name="AddAcctabset" id="AddAcctab4" value="AddAcctab4" aria-controls="bano" />
              <label for="AddAcctab4" style="text-align: center; width: 24%; color:lightgrey">EMPTY </label>

              <input type="radio" name="AddAcctabset" id="AddAcctab9" value="AddAcctab9" aria-controls="bano" />
              <label for="AddAcctab9" style="text-align: center; width: 24%; color:lightgrey">EMPTY </label>

              <input type="radio" name="AddAcctabset" id="AddAcctab10" value="AddAcctab10" aria-controls="bano" />
              <label for="AddAcctab10" style="text-align:center;width:24%" (click)=" AddAcctabChanged('AddAcctab10')"> ACCOUNT STATISTICS</label>

              <input type="radio" name="AddAcctabset" id="AddAcctab11" value="AddAcctab11" aria-controls="bano" />
              <label for="AddAcctab11" style="text-align:center;width:24%" (click)=" AddAcctabChanged('AddAcctab11')"> CUSTOMER STATISTICS </label>

              <input type="radio" name="AddAcctabset" id="AddAcctab12" value="AddAcctab11" disabled aria-controls="bano" />
              <label for="AddAcctab11" style="text-align: center; width: 24%;color:lightgrey">EMPTY</label>

            </div>
          </div>
        </div>
        <div class="col-xs-12 scroll-c" style="padding:0;height:76.5%;display:flex" *ngIf="AddAccselectedMenuRadio == 'AddAcctab0'">
          <div class="col-xs-6" style=" height: 100%; padding: 0% 0.5% 0 2%;">
            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Is Customer</div>
              <div class="col-xs-8" style="height:100%;padding:0;">
                <input *ngIf="(account.customertypeid == 1 || account.customertypeid == 2)" id="customertypeid" name="account.customertypeid" class="form-control" disabled value="Yes" style="height:100%;padding: 0 2% 0 2%;font-size:12px" />

                <input *ngIf="(account.customertypeid == 0)" id="customertypeid" name="account.customertypeid" class="form-control" disabled value="No" style="height:100%;padding: 0 2% 0 2%;font-size:12px" />
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Customer Since</div>
              <div class="col-xs-8" style="height:100%;padding:0;font-size:12px"><input id="CustomerSince" type="text" class="form-control" name="CustomerSince" [(ngModel)]="account.CustomerSince" (keypress)="handleClick($event)" style="height:100%;padding: 0 2% 0 2%;" readonly /></div>
            </div>
            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Lead From</div>
              <div class="col-xs-8" style="height:100%;padding:0;">
                <select name="LeadFrom" class="form-control" (change)="handleClick($event)" style="height:100%;padding: 0 2% 0 2%;font-size:12px" [(ngModel)]="accountHistory.LeadFromId">
                  <option value="null" disabled="disabled" [selected]="true">Select Lead From</option>
                  <option *ngFor="let lead of  leadfromlist" value={{lead.id}}>
                    {{lead.itemName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Account Status</div>
              <div class="col-xs-8" style="height:100%;padding:0;font-size:12px">
                <input id="AccountStatus" name="account.accountStatus" class="form-control" disabled [value]="(account.accountStatus == 14)?'Active' : (account.accountStatus == 15)?'On Hold':''" placeholder="Enter Account Status" style="height:100%;padding: 0 2% 0 2%;font-size:12px" />
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Sold By</div>
              <div class="col-xs-8" style="height:100%;padding:0;">
                <select name="SoldBy" class="form-control" (change)="handleClick($event)" required style="height:100%;padding: 0 2% 0 2%;font-size:12px" [(ngModel)]="accountHistory.SoldBy" *ngIf="(account.customertypeid == 1 || account.customertypeid == 2)">
                  <option value="null" disabled="disabled" [selected]="true">Select Sold By</option>
                  <option value="0">Sales Team</option>
                  <option value="1">Reseller</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Account Owner</div>
              <div class="col-xs-8" style="height:100%;padding:0;font-size:12px">
                <input name="account.OWNER" [(ngModel)]="account.OWNER" class="form-control" disabled style="height:100%;padding: 0 2% 0 2%;font-size:12px" />
              </div>
            </div>
            <br />
            <div class="col-xs-12" style="padding:0;height:6.8%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Account Type</div>
              <div class="col-xs-8" style="height:100%;padding:0;font-size:12px">
                <input name="account.accounttypeName" [(ngModel)]="account.accounttypeName" class="form-control" disabled style="height:100%;padding: 0 2% 0 2%;font-size:12px" />
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Ownership</div>
              <div class="col-xs-8" style="height:100%;padding:0;font-size:12px">
                <input name="account.ownershipName" [(ngModel)]="account.ownershipName" class="form-control" disabled style="height:100%;padding: 0 2% 0 2%;font-size:12px" />
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;" *ngIf="(this.customeracces==1)">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Active Or Inactive</div>
              <div class="col-xs-8" style="height:100%;padding:0;font-size:12px">
                <input id="CustomerType" name="account.customertypeid" class="form-control" disabled [(ngModel)]="account.customertype" [value]="(account.customertypeid == 1)?'Customer' : (account.customertypeid == 2)?'Former Customer':'Non Customer'" style="height:100%;padding: 0 2% 0 2%;font-size:12px" *ngIf="(account.customertypeid == 1 || account.customertypeid == 2)" />
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">EHR</div>
              <div class="col-xs-8" style="height:100%;padding:0;">
                <input type="text" id="accEHR" name="EHR" class="form-control" [(ngModel)]="account.ehrName" disabled style="height:100%;padding: 0 2% 0 2%;font-size:12px" />

              </div>
            </div>

            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">PM</div>
              <div class="col-xs-8" style="height:100%;padding:0;">
                <input type="text" id="PM" name="PM" class="form-control" [(ngModel)]="account.pmName" disabled style="height:100%;padding: 0 2% 0 2%;font-size:12px" />

              </div>
            </div>

            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">RCM</div>
              <div class="col-xs-8" style="height:100%;padding:0;">
                <input type="text" id="RCM" name="RCM" class="form-control" [(ngModel)]="account.rcmName" disabled style="height:100%;padding: 0 2% 0 2%;font-size:12px" />

              </div>
            </div>




            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Key Contact (#1)</div>
              <div class="col-xs-8" style="height:100%;padding:0;">
                <select name="KeyContact1" class="form-control" (change)="handleClick($event)" style="height:100%;padding: 0 2% 0 2%;font-size:12px" [(ngModel)]="accountHistory.KeyContact1">
                  <option value="null" disabled="disabled" [selected]="true">Select Key Contact (#1)</option>
                  <option *ngFor="let conatct of  contactList" value={{conatct.id}}>
                    {{conatct.itemName}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Key Contact (#2)</div>
              <div class="col-xs-8" style="height:100%;padding:0;">
                <select name="KeyContact2" class="form-control" (change)="handleClick($event)" style="height:100%;padding: 0 2% 0 2%;font-size:12px" [(ngModel)]="accountHistory.KeyContact2">
                  <option value="null" disabled="disabled" [selected]="true">Select Key Contact (#2)</option>
                  <option *ngFor="let conatct of  contactList" value={{conatct.id}}>
                    {{conatct.itemName}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Key Contact (#3)</div>
              <div class="col-xs-8" style="height:100%;padding:0;">
                <select name="KeyContact3" class="form-control" (change)="handleClick($event)" style="height:100%;padding: 0 2% 0 2%;font-size:12px" [(ngModel)]="accountHistory.KeyContact3">
                  <option value="null" disabled="disabled" [selected]="true">Select Key Contact (#3)</option>
                  <option *ngFor="let conatct of  contactList" value={{conatct.id}}>
                    {{conatct.itemName}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-xs-12" style="padding:0;height:6.8%;margin-top:0.5%;">
              <div class="col-xs-4" style="height:100%;padding:1% 0 0 0;font-size:11px">Sales Rep.</div>
              <div class="col-xs-8" style="height:100%;padding:0;">

                <select name="account.salesRep" class="form-control" [(ngModel)]="account.salesRep" [title]="((account.salesRep==null)? 'Select Sales Rep. ':account.salesRep)" (change)="handleClick($event)">
                  <option value="null" disabled="disabled" [selected]="true">Select Sales Rep.</option>
                  <option *ngFor="let sale of  salereplist" value={{sale.id}}>
                    {{sale.username}}
                  </option>
                </select>
              </div>
            </div>


          </div>
          <div style="    padding: 0 0.5% 0 0.5%" class="col-xs-6">
            <div class="col-xs-12" style="padding:1% 1% 0 0;height:100%;border:1px solid #ccc ">
              <div style="height: 8%; text-align: center; vertical-align: middle; padding-top: 2%;"> <label style="text-align:center">Account History Notes</label></div>
              <div style="height:92%">
                <textarea id="notesTextArea" rows="6" class="form-control" type="text" name="notedescription" (keyup)="handleClick($event)"
                          [(ngModel)]="accountHistory.HistoryNotes" style="height: 100%; border: none; box-shadow: none;line-height: 1.028571;font-size:14px"></textarea>
              </div>
            </div>
          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:6%" *ngIf="AddAccselectedMenuRadio == 'AddAcctab0'">
          <div class="col-xs-12" style="padding: 0;margin-top: 0.7%; ">
            <div class="col-xs-5">
            </div>
            <div class="col-xs-7" style="padding-right:1%">
              <div class="col-xs-6" style="padding: 0;">
                <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="cancelAccount()">Cancel</button>
              </div>
              <div class="col-xs-6" style="padding-right: 0;">
                <button type="submit" class="btn saveButton" style="width:100%;" (click)="SaveAccountHistory()">Save </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12" style="padding:0;height:82%" *ngIf="AddAccselectedMenuRadio == 'AddAcctab4'">
          <div class="col-xs-12 removeCompletePadding provider scroll-c" id="accountBody" style="padding:1% 0 2% 0;overflow:auto; margin-top:0px; border-radius:3px;height:100%" [style.display]="(EditAddressDiv == false)?  'block' : 'none'">
            <form [formGroup]="NewForm">
              <div *ngFor="let field of Fields">
                <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%" *ngIf="field.ColumnName != 'address1' && field.ColumnName != 'address2' && field.ColumnName != 'city' && field.ColumnName != 'state' && field.ColumnName != 'zip' && field.ColumnName != 'Country' && field.ColumnName != 'ProvidenceIsland'">
                  <div class="col-xs-3" style="padding-right:0;">
                    <label *ngIf="field.ColumnName == 'customertypeid'">Active or Inactive</label>

                    <label *ngIf="field.ColumnName != 'customertypeid'">{{field.DisplayAs}}</label>
                  </div>
                  <div class="col-xs-9">
                    <div class="col-xs-12" style="padding:0;">
                      <div class="col-xs-12" style="padding:0;" *ngIf="field.OldOrNew == 'Old'">

                        <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName"
                                [(ngModel)]="account[field.ColumnName]" *ngIf="(field.DataType == 'Pick List' || field.DataType == 'System Lookup')
                                && field.ColumnName=='ownerid' && currentuser.id!=account[field.ColumnName]" [required]="(field.RequiredField == 'Yes')? 'true':'false'"
                                (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)" disabled>
                          <option value="0"> </option>
                          <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                            {{customer.itemName}}
                          </option>
                        </select>

                        <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName"
                                [(ngModel)]="account[field.ColumnName]" *ngIf="(field.DataType == 'Pick List' || field.DataType == 'System Lookup')
                                && field.ColumnName=='ownerid' && currentuser.id==account[field.ColumnName]" [required]="(field.RequiredField == 'Yes')? 'true':'false'"
                                (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)">
                          <option value="0"> </option>
                          <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                            {{customer.itemName}}
                          </option>
                        </select>

                        <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName"
                                [(ngModel)]="account[field.ColumnName]" *ngIf="(field.DataType == 'Pick List' || field.DataType == 'System Lookup')
                                && field.ColumnName!='ownerid'" [required]="(field.RequiredField == 'Yes')? 'true':'false'"
                                (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)">
                          <option value="0"> </option>
                          <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                            {{customer.itemName}}
                          </option>
                        </select>

                      </div>

                      <div class="col-xs-12" style="padding:0;" *ngIf="field.OldOrNew == 'New'">
                        <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)">
                          <option *ngFor="let customer of field.Data" value="{{customer.id}}" style="color:black">
                            {{customer.itemName}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-xs-12" style="padding:0;" *ngIf="(field.ColumnName != 'phone' && field.ColumnName != 'fax' && field.ColumnName != 'alterphone2' && field.ColumnName != 'alterphone1' && field.ColumnName != 'website')">
                      <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName != 'address1' || field.ColumnName != 'address2' || field.ColumnName != 'city' || field.ColumnName != 'state' || field.ColumnName != 'zip' || field.ColumnName != 'country' || field.ColumnName != 'ProvidenceIsland')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (keyup)="FormDataEntered($event.target)" />
                    </div>
                    <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'phone' || field.ColumnName == 'fax' || field.ColumnName == 'alterphone2' || field.ColumnName == 'alterphone1')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" [textMask]="{mask: phonemask}" (keyup)="FormDataEntered($event.target)" />

                    <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'website')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" pattern="([Hh][Tt][Tt][Pp][Ss]?:\/\/)?(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" (keyup)="FormDataEntered($event.target)" />


                    <input type="date" class="form-control decreasefieldSize" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]"
                           *ngIf="((field.DataType == 'Date' ||  field.DataType == 'Date/Time'))" [required]="(field.RequiredField == 'Yes')? 'true':'false'"
                           (change)="FormDataEntered($event.target)" />

                    <select class="form-control decreasefieldSize" name="{{field.ColumnName
                    }}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Date/YearOnly'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)">
                      <option *ngFor="let customer of Years" value="{{customer.value}}" style="color:black">
                        {{customer.label}}
                      </option>
                    </select>

                  </div>
                </div>
                <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%" *ngIf="field.ColumnName == 'address1'">
                  <app-address [locationType]="'loc'" [Index]="1" (sendMsgEvent)="AddressReceived($event)"></app-address>
                </div>

              </div>
              <div class="col-xs-12" style="padding: 0;margin-top: 0.7%; ">
                <div class="col-xs-3">
                </div>
                <div class="col-xs-9">
                  <div class="col-xs-6" style="padding: 0;">
                    <button class="btn btn-default" (click)="(FormDataModified)? (DiscardFormDataConfirmationCss()):cancelAccount()" style="width: 100%; border:1px solid">Cancel</button>
                  </div>
                  <div class="col-xs-6" style="padding-right: 0;">
                    <button type="submit" class="btn saveButton" style="width:100%;" (click)="showEditAddressDiv()" [disabled]="!NewForm.valid">Save </button>
                  </div>
                </div>
              </div>

            </form>


          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:82%" *ngIf="AddAccselectedMenuRadio == 'AddAcctab1'">
          <div class="col-xs-12 removeCompletePadding provider scroll-c" id="accountBody" style="padding:1% 0 2% 0;overflow:auto; margin-top:0px; border-radius:3px;height:100%" [style.display]="(EditAddressDiv == false)?  'block' : 'none'">

            <form [formGroup]="NewForm">

              <div *ngFor="let field of Fields">

                <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%" *ngIf="field.ColumnName != 'address1' && field.ColumnName != 'address2' && field.ColumnName != 'city' && field.ColumnName != 'state' && field.ColumnName != 'zip' && field.ColumnName != 'Country' && field.ColumnName != 'ProvidenceIsland'">
                  <div class="col-xs-3" style="padding-right:0;">
                    <label *ngIf="field.ColumnName == 'customertypeid'">Active or Inactive</label>

                    <label *ngIf="field.ColumnName != 'customertypeid'">{{field.DisplayAs}}</label>
                  </div>
                  <div class="col-xs-9">
                    <div class="col-xs-12" style="padding:0;">
                      <div class="col-xs-12" style="padding:0;" *ngIf="field.OldOrNew == 'Old'">

                        <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName"
                                [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'"
                                [required]="(field.RequiredField == 'Yes')? 'true':'false'"
                                (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)">
                          <option value="0"> </option>
                          <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                            {{customer.itemName}}
                          </option>
                        </select>
                      </div>

                      <div class="col-xs-12" style="padding:0;" *ngIf="field.OldOrNew == 'New'">
                        <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)">
                          <option *ngFor="let customer of field.Data" value="{{customer.id}}" style="color:black">
                            {{customer.itemName}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-xs-12" style="padding:0;" *ngIf="(field.ColumnName != 'phone' && field.ColumnName != 'fax' && field.ColumnName != 'alterphone2' && field.ColumnName != 'alterphone1' && field.ColumnName != 'website')">
                      <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName != 'address1' || field.ColumnName != 'address2' || field.ColumnName != 'city' || field.ColumnName != 'state' || field.ColumnName != 'zip' || field.ColumnName != 'country' || field.ColumnName != 'ProvidenceIsland')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (keyup)="FormDataEntered($event.target)" />
                    </div>
                    <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'phone' || field.ColumnName == 'fax' || field.ColumnName == 'alterphone2' || field.ColumnName == 'alterphone1')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" [textMask]="{mask: phonemask}" (keyup)="FormDataEntered($event.target)" />

                    <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'website')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" pattern="([Hh][Tt][Tt][Pp][Ss]?:\/\/)?(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" (keyup)="FormDataEntered($event.target)" />

                    <input type="date" class="form-control decreasefieldSize" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="((field.DataType == 'Date' ||  field.DataType == 'Date/Time'))" [required]="(field.RequiredField == 'Yes')? 'true':'false'" value="{{account[field.ColumnName]}}" (change)="FormDataEntered($event.target)" />


                    <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Date/YearOnly'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)">
                      <option *ngFor="let customer of Years" value="{{customer.value}}" style="color:black">
                        {{customer.label}}
                      </option>
                    </select>

                  </div>
                </div>
                <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%" *ngIf="field.ColumnName == 'address1'">
                  <app-address [locationType]="'loc'" [Index]="1" (sendMsgEvent)="AddressReceived($event)"></app-address>
                </div>

              </div>
              <div class="col-xs-12" style="padding: 0;margin-top: 0.7%; ">
                <div class="col-xs-3">
                </div>
                <div class="col-xs-9">
                  <div class="col-xs-6" style="padding: 0;">
                    <button class="btn btn-default" (click)="(FormDataModified)? (DiscardFormDataConfirmationCss()):cancelAccount()" style="width: 100%; border:1px solid">Cancel</button>
                  </div>
                  <div class="col-xs-6" style="padding-right: 0;">
                    <button type="submit" class="btn saveButton" style="width:100%;" (click)="showEditAddressDiv()" [disabled]="!NewForm.valid">Save</button>
                  </div>
                </div>
              </div>

            </form>

          </div>

        </div>

        <div class="col-xs-12" style="padding:0;height:82%" *ngIf="AddAccselectedMenuRadio == 'AddAcctab5'">
          <div class="col-xs-12 removeCompletePadding provider scroll-c" id="accountBody" style="padding:1% 0 2% 0;overflow:auto; margin-top:0px; border-radius:3px;height:100%" [style.display]="(EditAddressDiv == false)?  'block' : 'none'">

            <form [formGroup]="NewForm">
              <div *ngFor="let field of Fields">
                <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%" *ngIf="(field.ColumnName != 'address1' && field.ColumnName != 'address2'
                     && field.ColumnName != 'city' && field.ColumnName != 'state' && field.ColumnName != 'zip' && field.ColumnName != 'Country'
                     && field.ColumnName != 'ProvidenceIsland')">
                  <div class="col-xs-3" style="padding-right:0;">
                    <label *ngIf="field.ColumnName == 'customertypeid'">Active or Inactive</label>
                    <label *ngIf="field.ColumnName != 'customertypeid'">{{field.DisplayAs}}</label>
                  </div>
                  <div class="col-xs-9">
                    <div class="col-xs-12" style="padding:0;">
                      <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName"
                              [(ngModel)]="account[field.ColumnName]"
                              *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'"
                              [required]="(field.RequiredField == 'Yes')? 'true':'false'"
                              (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)">
                        <option *ngIf="field.ColumnName=='SystemType'" value=""> </option>
                        <option *ngFor="let customer of field.Data" value="{{customer.id}}" style="color:black">
                          {{customer.itemName}}
                        </option>
                      </select>
                    </div>
                    <div class="col-xs-12" style="padding:0;" *ngIf="(field.ColumnName != 'phone' && field.ColumnName != 'fax' && field.ColumnName != 'alterphone2' && field.ColumnName != 'alterphone1' && field.ColumnName != 'website')">
                      <input id="{{field.ColumnName}}" value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName != 'address1' || field.ColumnName != 'address2' || field.ColumnName != 'city' || field.ColumnName != 'state' || field.ColumnName != 'zip' || field.ColumnName != 'country' || field.ColumnName != 'ProvidenceIsland')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (keyup)="FormDataEntered($event.target)" />
                    </div>

                    <input type="date" class="form-control decreasefieldSize" value="{{account[field.ColumnName]}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName"
                           [(ngModel)]="account[field.ColumnName]" *ngIf="(field.DataType == 'Date' ||  field.DataType == 'Date/Time')"
                           [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target)" />
                  </div>
                </div>

              </div>
              <div class="col-xs-12" style="padding: 0;margin-top: 0.7%; ">
                <div class="col-xs-3">
                </div>
                <div class="col-xs-9">
                  <div class="col-xs-6" style="padding: 0;">
                    <button class="btn btn-default" (click)="(FormDataModified)? (DiscardFormDataConfirmationCss()):cancelAccount()" style="width: 100%; border:1px solid">Cancel</button>
                  </div>
                  <div class="col-xs-6" style="padding-right: 0;">
                    <button type="submit" class="btn saveButton" style="width:100%;" (click)="showEditAddressDiv()" [disabled]="!NewForm.valid">Save</button>
                  </div>
                </div>
              </div>

            </form>
          </div>

        </div>

        <div class="col-xs-12" style="padding:0;height:82%" *ngIf="AddAccselectedMenuRadio == 'AddAcctab7'">
          <div class="col-xs-12" style="padding:0;height:100%" [style.display]="(LocationMappedContactsDiv == false)? 'block':'none'">
            <div class="col-xs-12" style="padding:0;height:8%">
              <div class="col-xs-1"></div>
              <div class="col-xs-1" style="padding:0;height:100%" *ngIf="account && account.id > 0">
                <img src="../../../assets/img/Add additional location.png" style="height:90%;width:90%;margin-top:2%" (click)="multipleLocation(1,true);" />
              </div>
              <div class="col-xs-11" style="padding:0;height:100%"></div>
            </div>
            <div class="col-xs-12 scroll-c" style="padding:0;height:92%;overflow:auto">
              <form [formGroup]="heroForm" style="height:90%">
                <div formArrayName="secretLairs">
                  <div *ngFor="let address of secretLairs.controls; let i=index" [formGroupName]="i">
                    <div id="{{i + 1}}" class="col-xs-12" style="padding:0">

                      <div class="col-xs-4">

                      </div>
                      <div class="col-xs-8" style="padding:1%">
                        <h4 style="color: #92d050">
                          Location #{{i + 1}}
                          <button *ngIf="this.secretLairs.value.length>1" class="pull-right" (click)="removeloction(i+1+1)" type="button" style="margin-right: 4%;width:8%;background-color:white">
                            <img src="../../../assets/img/Green Trash.png" style="width:100%;height:100%" />
                          </button>
                        </h4>
                      </div>
                    </div>
                    <div class="col-xs-12">
                      <div class="col-xs-1"></div>
                      <div class="col-xs-11" style="padding:0px">
                        <app-address [locationType]="'loc1'" [Index]="i+1" (sendMsgEvent)="AddressReceived($event)"></app-address>
                      </div>

                      <br>
                    </div>

                  </div>
                </div>

              </form>
              <div class="col-xs-12" style="height:10%;padding:0;" *ngIf="showBtn">
                <div class="col-xs-6"></div>
                <div class="col-xs-6">
                  <div class="col-xs-6" style="padding: 0;">
                    <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="cancelAccount()">Cancel</button>
                  </div>
                  <div class="col-xs-6">
                    <button type="submit" class="btn saveButton" style="width:100%;" (click)="SaveAdditionalAddress()">Save </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12" style="padding:0 2% 0 2%;height:100%;" *ngIf="LocationMappedContactsDiv">
            <div style="height: 10%; width: 100%;  text-align: center; padding-top: 1%; font-size: 18px;">
              <label style="text-transform:uppercase">Select Address</label>
            </div>

            <div style="height:16%;width:100%">
              <p style="font-weight:normal">
                You are deleting an address for this account. The system has identified Contacts linked to the address being deleted. These contacts must be linked to another address for this account. Select the address that will be updated for these contacts and then select <span style="font-weight:bolder"> "Continue"</span>
              </p>
            </div>

            <div style="height: 64%; width: 90%; border: 1px solid lightgray;  margin: 0 5% 0 5%;">
              <div style="padding:2%;">
                <div *ngFor="let address of AddressList">
                  <p style="font-weight:normal"><input type="radio" id="address.id" name="selectedAddres" (click)="UpdatedAddressChecked(address.id)"> &nbsp; {{address.itemName}}</p>
                </div>
              </div>
            </div>
            <div style="height: 10%; width: 100%; padding: 2% 2% 0 2%;">
              <div class="col-xs-6" style="padding: 0;">
                <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="LocationMappedContactsDiv = false">Cancel</button>
              </div>
              <div class="col-xs-6" style="padding-right: 0;">
                <button type="submit" class="btn saveButton" style="width:100%;" (click)="updatecontactaddress()" [disabled]="ReplaceAddressSelected == false">Save  </button>

              </div>

            </div>

          </div>
        </div>

        <div class="col-xs-12" style="padding:0" [style.height.%]="(ExpandMode)? '90' : '82'" *ngIf="AddAccselectedMenuRadio == 'AddAcctab2' && currentuser.SoftwareLicenseAdmin == 1">
          <app-software-licensing [accountid]="account.id" [Type]="'Add'" (ExpandBox)="Expand($event)"></app-software-licensing>
        </div>

        <div class="col-xs-12" style="padding:0;height:82%" *ngIf="AddAccselectedMenuRadio == 'AddAcctab6'">
          <ag-grid-angular class="ag-theme-balham" id="notsHGTCont" style="height:99%"
                           [gridOptions]="gridOptionsnotes"
                           [columnDefs]="columnDefsnotes"
                           [rowData]="allnotes"
                           [pagination]="true"
                           [sideBar]="sideBar">
          </ag-grid-angular>
        </div>
        <div class="col-xs-12" style="padding:0;height:82%" *ngIf="(AddAccselectedMenuRadio == 'AddAcctab10'||AddAccselectedMenuRadio == 'AddAcctab11') && currentuser.StatisticsAddEdit == 1">
          <div class="col-xs-12" style="height:100%">
            <div class="col-xs-12" style="padding:0;height:80%">
              <textarea *ngIf="AddAccselectedMenuRadio == 'AddAcctab11'" id="StatisticsTextArea" rows="6" class="form-control" type="text" name="customerStatisticnotes" (keyup)="handleClick($event)" [(ngModel)]="accountHistory.customerStatisticnotes" style="height: 100%;box-shadow: none;line-height: 2;font-size:12px" placeholder="Enter Notes / Customer Statistics"></textarea>

              <textarea *ngIf="AddAccselectedMenuRadio == 'AddAcctab10'" id="StatisticsTextArea" rows="6" class="form-control" type="text" name="StatisticsNotes" (keyup)="handleClick($event)" [(ngModel)]="accountHistory.StatisticsNotes" style="height: 100%;box-shadow: none;line-height: 2;font-size:12px" placeholder="Enter Notes / Statistics"></textarea>
            </div>
            <div class="col-xs-12" style="padding:0;height:20%">
              <div class="col-xs-12" style="padding: 0;margin-top: 0.7%; ">
                <div class="col-xs-5">
                </div>
                <div class="col-xs-7" style="padding-right:1%">
                  <div class="col-xs-6" style="padding: 0;">
                    <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="cancelAccount()">Cancel</button>
                  </div>
                  <div class="col-xs-6">
                    <button type="submit" class="btn saveButton" style="width:100%;" (click)="SaveAccountHistory()">Save </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </form>

</div>

<app-dialog [(visible)]="SaveAccDialog" style="width:500px !important">
  <div class="col-md-12" *ngIf="SaveAccDialog" style="padding:0;margin-top:0%;">
    <div class="col-xs-12" style="text-align:center;font-size:24px;">

      <label>Warning-Duplicate Information has been found </label>
      <div>{{account.accountname}}</div>
      <div>{{account.address1}},&nbsp;{{account.address2}}</div>
      <div>{{account.city}},{{account.state| uppercase}},{{account.zip | slice:0:5}}<span *ngIf="account.zip && account.zip.length > 5">-{{account.zip | slice:5:20}}</span></div>
      <div>  Fax: {{account.fax}}</div>
      <div *ngIf="(websiteduplicate)"><label id="lblwebsite" style="color:red">{{account.website}}</label></div>

      <div *ngIf="(!websiteduplicate)"><label>{{account.website}}</label></div>
      <div>Owner:{{currentuser.username}}</div>
      <br /><br />

      <div class="col-xs-12">
        <div class="col-xs-6">
          <button class="btn" style="width:100%;background-color:#92d050;height: 35px" (click)="SaveAccountInfo('null')">Yes-Proceed with Saving Duplicate Information</button>

        </div>
        <div class="col-xs-6">
          <button class="btn" style="width:100%;background-color:#92d050;height: 35px" (click)="closeduplicatediv()"> No-I want to change the duplicate information</button>

        </div>
      </div>

    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="showmapWarning" id="Banoo">
  <div style=" font-size: 19px;width:100%;">
    <div style="text-align:center;font-size:21px;">    <b>Adding Account Address</b></div>
    <div style="text-align:justify;padding: 0 3% 0 2%;">
      <br />
      <span>
        The Address for this account was not selected from
        google Address list.Please select one of the two
        options below: Select <b>"  Go to Google Address "</b> and
        select google address for the account -or- select
        <b>"Save"</b> and system will save the address you have
        added to account.
      </span>
      <br /><br />
    </div>
    <div class="col-xs-12" style="margin-top: 2%;">
      <div class="col-xs-6" style="padding: 0;">
        <button class="btn noprovider" (click)="showmapWarning= !showmapWarning" style="width:85%;height: 31px;background-color:dodgerblue;border-radius:5px;">Go to Google Address</button>

      </div>
      <div class="col-xs-6" style="padding-right:0;text-align:right;">
        <button class="btn noprovider" (click)="SaveCustomeAddress()" style="width:85%;height: 31px;background-color:#badb98;border-radius:5px;">Save </button>
      </div>
    </div>
  </div>

</app-dialog>

<app-dialog [(visible)]="DuplicateAccountDialog" style="width:500px !important">
  <div class="col-xs-12" style="background-color: rgb(244,244,244); height: 100%; width: 100%;">
    <div class="col-xs-12" style="text-align:left;margin:1% ;height:94%;width:99%;background-color:white;margin-top:2%">
      <label style="text-align: left;font-size: 110%;margin-left:1%;height:65%">
        <label>The Account you are adding that has been identified in the System as being Deleted by <span style="color:red">{{deleteduserName}}</span></label><br /><br />

        <label> To Continue Adding the Account please Select Continue Or Select Cancel to dischard the Account Data you just Entered for this Account. </label><br /><br />

        <label>
          By Selecting CANCEL,you have the option of requesting the user of the deleted account to
          Change the Status of Account from Deleted to Active.An Administrator of the system can also
          re-activate the account and assign it to you.
        </label><br /><br />
        <label> When an account is re-activated ,all preious notes, leads, opportunites, tasks and emails are available for viewing depending on your configuration and access.<br /></label>

      </label>
      <br /><br /><br />
      <div class="col-xs-12" style="margin-top: 0%;margin-bottom: 0%;height:25%">
        <div class="col-xs-6 pull-left" style="padding-right:0;height:100%">
          <button (click)="cancelDialog()" class="btn noprovider" style="width: 60%;height: 70%;border: 1px solid black !important;">Cancel</button>
        </div>

        <div class="col-xs-6 pull-right" style="height:100%">
          <button class="btn noprovider pull-right" style="width: 60%;height: 70%;border: 1px solid black !important;background: #badb98;" (click)="Continue()">Continue</button>

        </div>
      </div>

    </div>
  </div>




</app-dialog>


<app-dialog [(visible)]="DiscardFormDataConfirmation" *ngIf="DiscardFormDataConfirmation" id="DiscardFormDataConfirmation" [closable]="false">
  <div class="col-xs-12" style="padding:5%">
    <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
    <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
      <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">Account</div><br />
      <div class="col-xs-12" *ngIf="(account.id==0)" style="padding: 0; padding-top: 3%;">Discard Saving Account</div>
      <div class="col-xs-12" *ngIf="(account.id!=0)" style="padding: 0; padding-top: 3%;">Changes will Not be Saved</div>
    </div>
    <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
      <div class="col-xs-7" style="padding: 0;">

      </div>
      <div class="col-xs-5" style="padding:0;">
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                  (click)="cancelAccount()">
            OK
          </button>
        </div>
        
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%;
box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1 " (click)="DiscardFormDataConfirmation = false">Cancel</button>
        </div>
      </div>
    </div>
  </div>
 
</app-dialog>

<app-dialog [(visible)]="NoAccess" *ngIf="NoAccess" id="NoAccess">

  <div class="col-xs-12" style="height:99%">
    <div class="col-xs-12" style="padding:0;height:30%"><label style="font-size:19px;font-weight:800">SalesXCRM Message</label></div>
    <div class="col-xs-12" style="padding:0;height:20%">
      <b style="font-size:15px;text-transform:uppercase">Access Denied</b>
    </div>
    <div class="col-xs-12" style="padding:0;height:50%">
      <div style="padding:0;height:100%" class="col-xs-9">
        <b style="font-size:15px;">Please check privileges in  User Configuration</b><br />
      </div>
      <div class="col-xs-3" style="padding:0;height:100%">
        <button class="btn" style="background-color: #0098f7 !important;color: white;margin-top:-5%;margin-left:15%" (click)="NoAccess = false">OK</button>


      </div>
    </div>
  </div>
</app-dialog>
