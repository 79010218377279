import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import { CustomerService } from './../../services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { Tasklistconfiguration } from '../../models/tasklistconfiguration';
import { ApplicationComponent } from '../application.component';
import { SystemconfigurationAccessModel } from '../../models/systemconfiguration';
import { AccountConfigurationComponent } from '../account-contact/account-configuration/account-configuration.component';
import { LeadsConfigurationComponent } from '../leads-configuration/leads-configuration.component';
import { MySalesxcrmConfigurationComponent } from '../sales-xcrm/my-salesxcrm-configuration/my-salesxcrm-configuration.component';
import { OpportunitiesConfigurationComponent } from '../opportunities-configuration/opportunities-configuration.component';
import { ProjectConfigurationComponent } from '../projects/project-configuration/project-configuration.component';
import { ProposalConfigurationComponent } from '../proposal-configuration/proposal-configuration.component';
import { SoftwareLicenseConfigurationComponent } from '../software-license-configuration/software-license-configuration.component';
import { StatusIDconfigurationComponent } from '../status-idconfiguration/status-idconfiguration.component';
import { TaskListConfigurationComponent } from '../task-list-configuration/task-list-configuration.component';
import { TicketConfigurationComponent } from '../ticket-configuration/ticket-configuration.component';
import { DocumentmanagementComponent } from '../documentmanagement/documentmanagement.component';
import { DocumentManagementConfigurationComponent } from '../document-management-configuration/document-management-configuration.component';
import { CampaignConfigurationComponent } from '../campaign-configuration/campaign-configuration.component';
declare var $: any;

@Component({
  selector: 'dropdown-configuration',
  templateUrl: './dropdown-configuration.component.html',
  styleUrls: ['./dropdown-configuration.component.css']
})
export class DropdownCOnfigurationComponent implements OnInit {
  @Input()
  type: string="420";
  @ViewChild(AccountConfigurationComponent) accconfigcmp;
  @ViewChild(LeadsConfigurationComponent) leadconfigcmp;
  @ViewChild(MySalesxcrmConfigurationComponent) crmconfigcmp;
  @ViewChild(OpportunitiesConfigurationComponent) oppconfigcmp;
  @ViewChild(ProjectConfigurationComponent) projcmp;
  @ViewChild(ProposalConfigurationComponent) proposalcmp;
  @ViewChild(SoftwareLicenseConfigurationComponent) licensecmp;
  @ViewChild(StatusIDconfigurationComponent) statusidcmp;
  @ViewChild(TaskListConfigurationComponent) tasklistcmp;
  @ViewChild(TicketConfigurationComponent) ticcmp;
  @ViewChild(DocumentManagementConfigurationComponent) doccmp;
  @ViewChild(CampaignConfigurationComponent) camcmp;
  dropdownTypes: any;
  Specialitytypelist: Dropdowntype[] = [];
  Collegelist: Dropdowntype[] = [];
  MedicalSchoolList: Dropdowntype[] = [];
  accounttypeList: Dropdowntype[] = [];
  ownershipList: Dropdowntype[] = [];
  pmpurchasedList: Dropdowntype[] =[];
  rcmlist: Dropdowntype[] = [];
  AccountIdlist: Dropdowntype[] = [];
  ContactIdslist: Dropdowntype[] = [];
  ehrpurchasedList: Dropdowntype[] =[];
  dropdown: Dropdown = new Dropdown();
  tasklistconfiguration: Tasklistconfiguration = new Tasklistconfiguration();
  systemconfig :SystemconfigurationAccessModel = new SystemconfigurationAccessModel();
  selectedtabid = "dashboards";
  
  specialityids = [];
  currentuser: any;
  
  
  dropdownid: number;
  dropItemName: string; dropdownCategory: string; selectedNewVal: string;
  AccountIdArr: Dropdowntype[] = [];
  contactIdArr: Dropdowntype[] = [];
  thisTab: any;
  public columnDefs;
  public sideBar;  
  @Input() SubMenuType: string = null;
  private gridApi;
  public clickedVal: string = '';
  
  public showCampaignConfiguration: boolean = false;
  public show: boolean = true;
  constructor(private leadservice: LeadsService, private customerService: CustomerService, private toastr: ToastrService, public applicationComponent: ApplicationComponent)
  {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    
 }

  ngOnInit() {   
    this.LoadAccounts();
    this.LoadSpecialities();
    this.LoadCollegeList();
    this.LoadMedSchool();
    this.clickedVal = this.type;
    
    if (this.clickedVal == '420') {
      if (this.currentuser.UserPrivilegeList != undefined) {
       
        this.systemconfig.AccountConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Account Configuration' || p.resource.toLowerCase() == 'Account Configuration')[0].canview;
        this.systemconfig.AccountSharingGroups = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Account Sharing Group' || p.resource.toLowerCase() == 'Account Sharing Group')[0].canview;
        this.systemconfig.CampaignConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Campaign Configuration' || p.resource.toLowerCase() == 'Campaign Configuration')[0].canview;
        this.systemconfig.ContactConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Contact Configuration' || p.resource.toLowerCase() == 'Contact Configuration')[0].canview;
        this.systemconfig.DocumentMGMTConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Document MGMT Configuration' || p.resource.toLowerCase() ==
          'Document MGMT Configuration')[0].canview;
        this.systemconfig.LeadsConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Leads Configuration' || p.resource.toLowerCase() ==
          'Leads Configuration')[0].canview;
        this.systemconfig.MySalesXCRMConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'MySalesXCRM Configuration' || p.resource.toLowerCase() ==
          'MySalesXCRM Configuration')[0].canview;
        this.systemconfig.OpportunitiesConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Opportunities Configuration' || p.resource.toLowerCase() ==
          'Opportunities Configuration')[0].canview;
        this.systemconfig.ProjectsConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Projects Configuration' || p.resource.toLowerCase() ==
          'Projects Configuration')[0].canview;
        this.systemconfig.ProposalConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Proposal Configuration' || p.resource.toLowerCase() ==
          'Proposal Configuration')[0].canview;

        this.systemconfig.ResponseWizard = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Response Wizard' || p.resource.toLowerCase() ==
          'Response Wizard')[0].canview;

        this.systemconfig.SoftwareLicenseConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Software License Configuration' || p.resource.toLowerCase() ==
          'Software License Configuration')[0].canview;

        this.systemconfig.StatusIDConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Status ID Configuration' || p.resource.toLowerCase() ==
          'Status ID Configuration')[0].canview;

        this.systemconfig.TaskListConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Task List Configuration' || p.resource.toLowerCase() ==
          'Task List Configuration')[0].canview;

        this.systemconfig.TicketConfiguration = this.currentuser.UserPrivilegeList.filter(p => p.resource == 'Ticket Configuration' || p.resource.toLowerCase() ==
          'Ticket Configuration')[0].canview;

        //this.SubmenuChange('Account Configuration', '420', 'main');
      }
    }
  }


  onsideMenuClick(val) {
    this.clickedVal = val;
   
        this.show = true;
        switch (this.clickedVal) {
          case '420': {
            this.type = '420';
            break;
          }
          case '701': {
            this.type = '701';
            break;
          }
          case '624': {
            this.type = '624';
            break;
          }
          case '422': {
            this.type = '422';
            break;
          }
          case '451': {
            this.type = '451';
            break;
          }
          case '531': {
            this.type = '531';
            break;
          }
          case '697': {
            this.type = '697';
            break;
          }
          case '423': {
            this.type = '423';
            break;
          }
          case '663': {
            this.type = '663';
            break;
          }
          case '528': {
            this.type = '528';
            break;
          }
          case '627': {
            this.type = '627';
            break;
          }
          case '530': {
            this.type = '530';
            break;
          }
          case '424': {
            this.type = '424';
            break;
          }
          case '529': {
            this.type = '529';
            break;
          }
          case 'SalesTool': {
            this.type = 'SalesTool';
            break;
          }
        }
   
  }

  LoadSpecialities()
  {
    
    this.customerService.GetSpecialityList("Account Specialty", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Specialitytypelist = accounttype;
    });

  }

  LoadCollegeList() {
    this.customerService.GetCollegeList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Collegelist = accounttype;
    });
  }
  LoadMedSchool() {
    this.customerService.GetMedicalSchoolList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.MedicalSchoolList = accounttype;
    });
  }
  LoadAccounts() {
    this.customerService.GetSpecialityList("Account Type", this.currentuser.id).then((accounttype) => {     
      this.accounttypeList = accounttype;
    });

    this.customerService.GetSpecialityList("Account Ownership", this.currentuser.id).then((ownerShip) => {
      this.ownershipList = ownerShip;
    });

    this.customerService.GetSpecialityList("Account PM", this.currentuser.id).then((pm) => {
      pm.forEach(pm => {
      });
      this.pmpurchasedList = pm;
    });
    this.customerService.GetSpecialityList("Account RCM", this.currentuser.id).then((rcm) => {
      rcm.forEach(rcm => {
      });
      this.rcmlist = rcm;
    });
    this.customerService.GetSpecialityList("Account EHR", this.currentuser.id).then((ehr) => {
      ehr.forEach(accounttype => {
      });
      this.ehrpurchasedList = ehr;
    });
    this.customerService.GetSpecialityList("AccountIds", this.currentuser.id).then((accountids) => {
      accountids.forEach(rcm => {
      });
      this.AccountIdlist = accountids;
    });
    this.ContactIdslist = [];
    this.customerService.GetSpecialityList("ContactIds", this.currentuser.id).then((contactids) => {
     
      this.ContactIdslist = contactids;
    });
  }

  SaveDropdownName(DropdownType)
  {

    this.specialityids.forEach(id => {
      if (id.Type == 'Speciality' || id.Type == 'SubSpeciality' || id.Type == 'EHR' || id.Type == 'PM' || id.Type == 'RCM' || id.Type == 'AccountType' || id.Type == 'OwnerShip' || id.Type == 'College' || id.Type == 'MedSchool' || id.Type == 'Residency' || id.Type == 'FellowShip' || id.Type == 'AccountIds' || id.Type == 'ContactIds') {
        var drpDwn = new Dropdown();
        if (DropdownType == id.Type) {
          this.dropdown.category = id.Type;
          this.dropdown.itemName = $('#' + id.Type + id.Index + "itemName").html();
          this.dropdown.id = $('#' + id.Index + id.Type).html();    
        }
      }
      
    });
    
    if (this.specialityids.length == 0) {
      this.dropdown.category = DropdownType;
      this.dropdown.itemName = $('#' + DropdownType + 'Value').val();
      if (this.dropdown.itemName.trim() == "")
        return false;
    }


    this.customerService.checkduplicateDropDownName(this.dropdown).then(result => {
        if (result == null) {
          this.customerService.adddropdown(this.dropdown).then(result => {
             
            this.dropdown = new Dropdown();
            this.toastr.success("New DropDown Saved Successfully.", "Task Group", { timeOut: 600 });
            this.LoadSpecialities();
            this.LoadCollegeList();
            this.LoadMedSchool();
            this.LoadAccounts();
            $('#' + DropdownType + 'Value').val('');
            this.specialityids = [];
          });
        }
        else {
          alert(this.dropdown.itemName + "  Already Exist")
        }

        $('#' + DropdownType + 'Value').css('display', 'none')
        $('#' + DropdownType + 'Div').css('display', 'none')
        $('#' + DropdownType + 'Table').css('height', '79%')
      });

    
    
  }
  addDropDownName(clickedButton) {
    $('#' + clickedButton +'Value').css('display','block')
    $('#' + clickedButton + 'Div').css('display', 'block')
    $('#' + clickedButton + 'Table').css('height', '49%')
    $('#' + clickedButton + 'Value').val('');
    this.dropdown = new Dropdown();
  }
 

  
  accountIdChange(val) {
    this.selectedNewVal = val;
  }
  contactIdChange(val) {
    this.selectedNewVal = val;
  }
  SubmenuChange(Tab, val, menu) {
    this.type = val;
    if (menu == 'main') {
      this.selectedtabid = Tab;
      var classOfId = $('#' + Tab.replace(" ", "") + "MainMenu").attr('class');
      if (classOfId == '') {
        this.CollapseSelectedMenu(Tab.replace(" ", ""));
      }
      else {
        this.ExpandSelectedMenu(Tab.replace(" ", ""), val);
        
      }

    } else {

      $(".subButtons").removeClass("activeSubButton");
      $('#' + val + "_button").addClass("activeSubButton");
    }
    if (val == '100') {
      this.crmconfigcmp.SalesXCRMLoad("Document Name");
    }
   else if (val == '101') {
      this.crmconfigcmp.SalesXCRMLoad("Chart Tab");
    }
    else if (val == '200') {
      this.oppconfigcmp.ShowDiv("Fields Configuration");
    }
    else if (val == '201') {
      this.oppconfigcmp.ShowDiv("Fields Choice Configuration");
    }
    else if (val == '300') {
      this.projcmp.AccessDiv("Fields");
    }
    else if (val == '301') {
      this.projcmp.AccessDiv("Config");
    }
    else if (val == '302') {
      this.projcmp.AccessDiv("Stages");
    }
    else if (val == '303') {
      this.projcmp.AccessDiv("Steps");
    }
    else if (val == '420') {
      this.accconfigcmp.AccountField("Add/Edit");
    } else if (val == '422') {
      this.accconfigcmp.AccountField("Configuration");
    }
    else if (val == '423') {
      this.accconfigcmp.AccountField("AccountMailingPageConfiguration");
    }
    else if (val == '424') {
      this.accconfigcmp.AccountField("AccountSpecificsPageConfiguration");
    }
    else if (val == '500') {
      this.accconfigcmp.AccountSharingGroup('Add/Edit');
    }
    else if (val == '600' || val == '601') {
      if (this.camcmp!= undefined) {
        this.camcmp.AccessDiv(val);
      }
    }
    else if (val == '700') {
      this.accconfigcmp.ContactsField('Add/Edit');
    }
   else if (val == '701') {
      this.accconfigcmp.ContactsField('Configuration');
    }
    else if (val == '702') {
      this.accconfigcmp.ContactsField('ContactPageConfiguration');
    }
    else if (val == '800' || val == '801') {
      this.doccmp.ChangeVal(val);
    }
    else if (val == '901') {
      this.leadconfigcmp.AccessDiv("TriggerTypes");
    }
    else if (val == '902') {
      this.leadconfigcmp.AccessDiv("SalesStages");
    }
    else if (val == '1000') {
      this.proposalcmp.AccessDiv("Categories");
    }
    else if (val == '1001') {
      this.proposalcmp.AccessDiv("Items");
    } else if (val == '1100') {
      this.licensecmp.ShowSLC_Pages("Categories");
    }
    else if (val == '1101') {
      this.licensecmp.ShowSLC_Pages("LicenseTypes");
    }
    else if (val == '1200') {
      this.statusidcmp.OpenStatusID("Group");
    }
    else if (val == '1201') {
      this.statusidcmp.OpenStatusID("Items");
    }
    else if (val == '1300') {
      this.tasklistcmp.ShowTaskGroupPages("TaskGroups");
    }
    else if (val == '1301') {
      this.tasklistcmp.ShowTaskGroupPages("TaskGroupsItems");
    }
    else if (val == '1400') {
      this.ticcmp.AccessDiv("SalesXCRM Ticket");
    }
    else if (val == '1401') {
      this.ticcmp.AccessDiv("Zendesk Integration");
    }

  }
  ExpandSelectedMenu(val, Idval) {
    $('#AccountConfigurationMainMenu').attr('aria-expanded', 'false');
    $('#AccountConfigurationMainMenu').addClass('collapsed');
    $('#AccountConfigurationMainMenu').removeClass('activeButton');
    $('#AccountConfiguration').removeClass('in');

    $('#AccountSharingMainMenu').attr('aria-expanded', 'false');
    $('#AccountSharingMainMenu').addClass('collapsed');
    $('#AccountSharingMainMenu').removeClass('activeButton');
    $('#AccountSharing').removeClass('in');

    $('#CampaignMainMenu').attr('aria-expanded', 'false');
    $('#CampaignMainMenu').addClass('collapsed');
    $('#CampaignMainMenu').removeClass('activeButton');
    $('#Campaign').removeClass('in');

    $('#ContactMainMenu').attr('aria-expanded', 'false');
    $('#ContactMainMenu').addClass('collapsed');
    $('#ContactMainMenu').removeClass('activeButton');
    $('#Contact').removeClass('in');

    $('#DocumentMGMTMainMenu').attr('aria-expanded', 'false');
    $('#DocumentMGMTMainMenu').addClass('collapsed');
    $('#DocumentMGMTMainMenu').removeClass('activeButton');
    $('#DocumentMGMT').removeClass('in');

    $('#LeadsMainMenu').attr('aria-expanded', 'false');
    $('#LeadsMainMenu').addClass('collapsed');
    $('#LeadsMainMenu').removeClass('activeButton');
    $('#Leads').removeClass('in');

    $('#CRMMainMenu').attr('aria-expanded', 'false');
    $('#CRMMainMenu').addClass('collapsed');
    $('#CRMMainMenu').removeClass('activeButton');
    $('#CRM').removeClass('in');

    $('#OpportunitiesMainMenu').attr('aria-expanded', 'false');
    $('#OpportunitiesMainMenu').addClass('collapsed');
    $('#OpportunitiesMainMenu').removeClass('activeButton');
    $('#Opportunities').removeClass('in');

    $('#ProjectMainMenu').attr('aria-expanded', 'false');
    $('#ProjectMainMenu').addClass('collapsed');
    $('#ProjectMainMenu').removeClass('activeButton');
    $('#Project').removeClass('in');

    $('#ProposalMainMenu').attr('aria-expanded', 'false');
    $('#ProposalMainMenu').addClass('collapsed');
    $('#ProposalMainMenu').removeClass('activeButton');
    $('#Proposal').removeClass('in');

    $('#SoftwareLicenseMainMenu').attr('aria-expanded', 'false');
    $('#SoftwareLicenseMainMenu').addClass('collapsed');
    $('#SoftwareLicenseMainMenu').removeClass('activeButton');
    $('#SoftwareLicense').removeClass('in');

    $('#StatusIDMainMenu').attr('aria-expanded', 'false');
    $('#StatusIDMainMenu').addClass('collapsed');
    $('#StatusIDMainMenu').removeClass('activeButton');
    $('#StatusID').removeClass('in');

    $('#TaskListMainMenu').attr('aria-expanded', 'false');
    $('#TaskListMainMenu').addClass('collapsed');
    $('#TaskListMainMenu').removeClass('activeButton');
    $('#TaskList').removeClass('in');

    $('#TicketMainMenu').attr('aria-expanded', 'false');
    $('#TicketMainMenu').addClass('collapsed');
    $('#TicketMainMenu').removeClass('activeButton');
    $('#Ticket').removeClass('in');

    $('#' + val+'MainMenu').attr('aria-expanded', 'true');
    $('#' + val + 'MainMenu').removeClass('collapsed');
    $('#' + val + 'MainMenu').addClass('activeButton');
    $('#' + val).addClass('in');
    $(".subButtons").removeClass("activeButton");
    $(".subButtons").removeClass("activeSubButton");
    $('#' + Idval + "_button").addClass("activeSubButton");
  }
  CollapseSelectedMenu(val) {
    //$('#dashboardsMainMenu').attr('aria-expanded', 'false');
    //$('#dashboardsMainMenu').addClass('collapsed');
    ////$('#dashboard').removeClass('in');

    //$('#contactMainMenu').attr('aria-expanded', 'false');
    //$('#contactMainMenu').addClass('collapsed');
    //$('#contact').removeClass('in');

    //$('#AccountsMainMenu').attr('aria-expanded', 'false');
    //$('#AccountsMainMenu').addClass('collapsed');
    //$('#Accounts').removeClass('in');

    //$('#leadsMainMenu').attr('aria-expanded', 'false');
    //$('#leadsMainMenu').addClass('collapsed');
    //$('#leads').removeClass('in');

    //$('#opportunitiesMainMenu').attr('aria-expanded', 'false');
    //$('#opportunitiesMainMenu').addClass('collapsed');
    //$('#opportunities').removeClass('in');

    //$('#tasklistsMainMenu').attr('aria-expanded', 'false');
    //$('#tasklistsMainMenu').addClass('collapsed');
    //// $('#tasklist').removeClass('in');

    //$('#CalendarMainMenu').attr('aria-expanded', 'false');
    //$('#CalendarMainMenu').addClass('collapsed');

    //$('#reportsMainMenu').attr('aria-expanded', 'false');
    //$('#reportsMainMenu').addClass('collapsed');
    //$('#reports').removeClass('in');

    //$('#MarketingMainMenu').attr('aria-expanded', 'false');
    //$('#MarketingMainMenu').addClass('collapsed');
    //$('#Marketing').removeClass('in');

    //$('#ProjectsMainMenu').attr('aria-expanded', 'false');
    //$('#ProjectsMainMenu').addClass('collapsed');
    //$('#Projects').removeClass('in');

    //$('#ticketsMainMenu').attr('aria-expanded', 'false');
    //$('#ticketsMainMenu').addClass('collapsed');
    //$('#tickets').removeClass('in');

    //$('#customersMainMenu').attr('aria-expanded', 'false');
    //$('#customersMainMenu').addClass('collapsed');
    //$('#customers').removeClass('in');

    //$('#MySalesXCRMMainMenu').attr('aria-expanded', 'false');
    //$('#MySalesXCRMMainMenu').addClass('collapsed');
    //// $('#MySalesXCRM').removeClass('in');

    //$('#RecentHistoryMainMenu').attr('aria-expanded', 'false');
    //$('#RecentHistoryMainMenu').addClass('collapsed');

    //$('#SalesToolsMainMenu').attr('aria-expanded', 'false');
    //$('#SalesToolsMainMenu').addClass('collapsed');
    //$('#SalesTools').removeClass('in');

    //$('#MyProfileMainMenu').attr('aria-expanded', 'false');
    //$('#MyProfileMainMenu').addClass('collapsed');
    //$('#MyProfile').removeClass('in');

    //$('#internalmessagingMainMenu').attr('aria-expanded', 'false');
    //$('#internalmessagingMainMenu').addClass('collapsed');
    //$('#internalmessaging').removeClass('in');

    //$('#CallListsMainMenu').attr('aria-expanded', 'false');
    //$('#CallListsMainMenu').addClass('collapsed');

    //$('#CampaignsMainMenu').attr('aria-expanded', 'false');
    //$('#CampaignsMainMenu').addClass('collapsed');
    //$('#Campaigns').removeClass('in');

    //$('#SystemConfigurationMainMenu').attr('aria-expanded', 'false');
    //$('#SystemConfigurationMainMenu').addClass('collapsed');

    //$('#UserConfigurationMainMenu').attr('aria-expanded', 'false');
    //$('#UserConfigurationMainMenu').addClass('collapsed');

    //$('#SysregMainMenu').attr('aria-expanded', 'false');
    //$('#SysregMainMenu').addClass('collapsed');
    //$('#Sysreg').removeClass('in');

    //$('#Pop-UpMessagingMainMenu').attr('aria-expanded', 'false');
    //$('#Pop-UpMessaginggMainMenu').addClass('collapsed');

    //$('#SalesForecastMainMenu').attr('aria-expanded', 'false');
    //$('#SalesForecastMainMenu').addClass('collapsed');
    //// $('#SalesForeCast').removeClass('in');

    //$('#AboutSalesXCRMMainMenu').attr('aria-expanded', 'false');
    //$('#AboutSalesXCRMMainMenu').addClass('collapsed');
    //$('#AboutSalesXCRM').removeClass('in');

    //$('#ContactUsMainMenu').attr('aria-expanded', 'false');
    //$('#ContactUsMainMenu').addClass('collapsed');
    //$('#ContactUs').removeClass('in');

    //$('#ReleaseNotesMainMenu').attr('aria-expanded', 'false');
    //$('#ReleaseNotesMainMenu').addClass('collapsed');
    //$('#ReleaseNotes').removeClass('in');

    //$('#PendedProposalMainMenu').attr('aria-expanded', 'false');
    //$('#PendedProposalMainMenu').addClass('collapsed');
    //$('#PendedProposal').removeClass('in');

  }
  Close() {
    this.applicationComponent.ShowConfigurationDialog = false;
  }
  SortDropdwns(dropdowntype) {
     
    var c = confirm("Are you sure you want to Reorder items in this Order??");
    if (c == true) {
      var dropdownArr = [];
      //if (dropdowntype == 'Speciality') {
      $.each($("#" + dropdowntype + "Table table tbody tr td[id$=" + dropdowntype + "]"), function (k, v) {
           
          var splIds = $.trim($(this).html());
          dropdownArr.push(splIds);
        })
         this.tasklistconfiguration.taskgroupid = 1;
      //}
      this.tasklistconfiguration.configureduserid = this.currentuser.id;
      this.tasklistconfiguration.tasklistconfigurtaionorder = dropdownArr.toString();
      this.tasklistconfiguration.tasktype = dropdowntype;
      this.customerService.SaveTaskListConfiguration(this.tasklistconfiguration).then(result => {
        this.toastr.success(dropdowntype + " Drop-Down Re-Ordered Successfully.", dropdowntype + " Drop Down", { timeOut: 600 });

      });
    }
  }

 

 

}


