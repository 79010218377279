import { Injectable } from '@angular/core';
//import { Http, httHeaders, RequestOptions } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions, ResponseContentType} from '@angular/http';
import { AppSettings } from '../app.settings';
import { Dropdowntype, Dropdown,ResourcePrivileges, SaveResourcePrivileges } from './../models/dropdowntype';

import { Userquota } from "./../models/userquota";
import { Document } from "./../models/document";
import { Auditlog, AuditLogModel } from "./../models/auditlog";
import { AccountDocuments } from "./../models/account-documents";
import { Privilages } from "./../models/privilages";
import { Systemconfiguration } from './../models/systemconfiguration';
//import { HttpClient } from '@angular/common/http';
//import { debug } from 'util';
import { Contacts } from '../models/contacts';
import { Dashboardconfig } from "./../models/Dashboardconfig";
import { AutomatedEmails } from "./../models/automated-emails";
import { Role } from './../models/Role';
import { ReleaseNotes } from './../models/releaseNotes';
import { TicketConfiguration } from './../models/ticketConfiguration';
import { ProposalCategory } from './../models/ProposalCategory';
import { ProposalItems } from './../models/ProposalItems';
import { OpportunityLeadTypes } from './../models/OpportunityLeadTypes';
import { Proposals } from './../models/Praposals';
import { ProposalsModel } from './../models/Proposalsmodel';
import { window } from 'rxjs/operators';
import { Users } from '../models/users';
import { StatusItems } from '../models/StatusItems';
import { StatusIDCategory, StatusIDGroup } from '../models/status-idcategory';
import { StatusTypes } from '../models/status-types';
import { TriggerTypes } from '../models/trigger-types';
import { ContactUs } from '../models/contact-us';
import { DocumentHistory } from '../models/DocumentHistory';
import { Taskgroup } from '../models/taskgroup';
import { SLCategories, SLTypes, SoftwareLicense } from '../models/SoftwareLicenseConfiguration';
import { Accounts } from '../models/accounts';
import { SubScription } from '../models/Subscriptionmodel';
import { promise } from 'protractor';
import { MyDashDefGridConfig } from '../models/MyDashDefGridConfig';
import { userModel } from '../models/userModel';

@Injectable()
export class UserService {
  options: RequestOptions;
  authToken: any;
  reqHeader: HttpHeaders = new HttpHeaders();
  public userList: any;
  constructor(private http: HttpClient, private settings: AppSettings, private httpclient: HttpClient) {
    this.reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('key')
    });
  }
  public GetAllUsers(viewSuspendedUsers : number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetAllUsers/' + viewSuspendedUsers, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });

    return promise;
  }

  public GetAllloggedInUsers(viewSuspendedUsers: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetAllloggedInUsers/' + viewSuspendedUsers, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });

    return promise;
  }
  

  public GetAllmyDashDefGridBoxlist(): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/users/GetAllmyDashDefGridBoxlist', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  saveLastQuickSearchVal(searchVal: number, userId: number): Promise<any> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/users/saveQuickSearch/' + searchVal + '/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
    //const promise = new Promise<string>((resolve, reject) => {
    //  this.httpclient.post<string>(this.settings.baseUrl + 'api/users/saveLastQuickSearch/', searchVal
    //  ).subscribe(res => {
    //    resolve(res);
    //  });
    //});
    //return promise;
  }

  savemyDashDefGridBox(configuration: MyDashDefGridConfig): Promise<Dashboardconfig> {

    const promise = new Promise<Dashboardconfig>((resolve, reject) => {
      this.httpclient.post<Dashboardconfig>(this.settings.baseUrl + 'api/users/savemyDashDefGridBox/', configuration, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAllRoles(): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetAllRoles', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }

  public GetAllChatParticipant(viewSuspendedUsers: number): Promise<any> {
    //let viewSuspended: number = viewSuspendedUsers ? 1 : 0;
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetAllChatParticipant/' + viewSuspendedUsers, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }

  public GetUsers(userStatusID: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetUsers/' + userStatusID, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }
  Getsytemconfigurationbyuser(userid: number): Promise<Systemconfiguration> {
    const promise = new Promise<Systemconfiguration>((resolve, reject) => {
      this.httpclient.get<Systemconfiguration>(this.settings.baseUrl + 'api/users/GetbySystemConfigurationByUser/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  savesystemconfiguration(configuration: Systemconfiguration): Promise<Systemconfiguration> {

    const promise = new Promise<Systemconfiguration>((resolve, reject) => {
      this.httpclient.post<Systemconfiguration>(this.settings.baseUrl + "api/users/Savesystemconfiguration", configuration, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  
  public GetAllRolesList(loggedInuserId:number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/users/GetAllRolesList/' + loggedInuserId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public checkduplicateRoleName(rolename: string): Promise<string> {

    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.get<string>(this.settings.baseUrl + 'api/users/CheckRoleName/' + rolename, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  checkRoleTiedtoUser(RoleId:number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/users/GetAssociatedUserRole/' + RoleId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public deleteRole(id: number, status: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/deleteRoleItem/' + id + "/" + status, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllTaskgrouplist(tasktype:string,Status:number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/users/GetAllTaskGroupList/' + tasktype + '/' + Status, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetTaskGroupList(tasktype: string, Status: number, userid: number): Promise<Taskgroup[]> {
    const promise = new Promise<Taskgroup[]>((resolve, reject) => {
      this.httpclient.get<Taskgroup[]>(this.settings.baseUrl + 'api/users/GetTaskGroupList/' + tasktype + '/' + Status + '/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllWizardTaskGroupList(tasktype: string, Status: number, userid): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/users/GetAllWizardTaskGroupList/' + tasktype + '/' + Status + '/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetTaskgrouplist(Status: string): Promise<Dropdowntype[]> {
     
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/users/GetTaskGroupList/' + Status, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetSelectedTaskgroups(Status: string): Promise<Dropdowntype[]> {
     
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/users/GetSelectedTaskgroups/' + Status, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetTicketConfigSettings(userid: number): Promise<TicketConfiguration[]> {
     
    const promise = new Promise<TicketConfiguration[]>((resolve, reject) => {
      this.httpclient.get<TicketConfiguration[]>(this.settings.baseUrl + 'api/users/GetTicketConfigSettings/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }


  public GetTicketTaskGroups(userid: number): Promise<TicketConfiguration[]> {
     
    const promise = new Promise<TicketConfiguration[]>((resolve, reject) => {
      this.httpclient.get<TicketConfiguration[]>(this.settings.baseUrl + 'api/users/GetTicketTaskGroups/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetTicketUserLists(userid: number): Promise<TicketConfiguration[]> {
     
    const promise = new Promise<TicketConfiguration[]>((resolve, reject) => {
      this.httpclient.get<TicketConfiguration[]>(this.settings.baseUrl + 'api/users/GetTicketUserLists/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public SaveTicketConfigSettings(tickConfig: TicketConfiguration): Promise<TicketConfiguration> {
     
    const promise = new Promise<TicketConfiguration>((resolve, reject) => {
      this.httpclient.post<TicketConfiguration>(this.settings.baseUrl + 'api/users/SaveTicketConfigSettings', tickConfig, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;

  }
  public SaveTicketTaskGroups(tickConfig: TicketConfiguration): Promise<TicketConfiguration> {
     
    const promise = new Promise<TicketConfiguration>((resolve, reject) => {
      this.httpclient.post<TicketConfiguration>(this.settings.baseUrl + 'api/users/SaveTicketTaskGroups', tickConfig, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;

  }

  public SaveTicketUserList(tickConfig: TicketConfiguration): Promise<TicketConfiguration> {
     
    const promise = new Promise<TicketConfiguration>((resolve, reject) => {
      this.httpclient.post<TicketConfiguration>(this.settings.baseUrl + 'api/users/SaveTicketUserList', tickConfig, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;

  }
  public GetAllMainResourceList(type: string, mainid: number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/users/GetAllMainResourcesList/' + type + '/' + mainid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetResourcePrivilegesList(userId: number, groupName: string): Promise<ResourcePrivileges[]> {
    const promise = new Promise<ResourcePrivileges[]>((resolve, reject) => {
      this.httpclient.get<ResourcePrivileges[]>(this.settings.baseUrl + 'api/users/GetResourcePrivilegesList/' + userId + '/' + groupName, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetDashBoardConfigByuser(userId: number): Promise<Dashboardconfig[]> {
    const promise = new Promise<Dashboardconfig[]>((resolve, reject) => {
      this.httpclient.get<Dashboardconfig[]>(this.settings.baseUrl + 'api/users/GetDashBoardConfigList/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAutomatedEmailsConfigByuser(userId: number): Promise<AutomatedEmails[]> {
    const promise = new Promise<AutomatedEmails[]>((resolve, reject) => {
      this.httpclient.get<AutomatedEmails[]>(this.settings.baseUrl + 'api/users/GetAutomatedEmail/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  
  public GetAllSubResourceList(mainResourceid: number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/users/GetAllSubResourcesList/' + mainResourceid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public registeruser(user: Users): Promise<Users> {
    const promise = new Promise<Users>((resolve, reject) => {
      this.httpclient.post<Users>(this.settings.baseUrl + 'api/users/RegisterUser', user, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public updateCustomerEdit(user: userModel): Promise<userModel> {
    const promise = new Promise<userModel>((resolve, reject) => {
      this.httpclient.post<userModel>(this.settings.baseUrl + 'api/users/CustomerEdit', user, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public saveRole(orole: Role): Promise<Role> {

    const promise = new Promise<Role>((resolve, reject) => {

      this.httpclient.post<Role>(this.settings.baseUrl + 'api/users/SaveRole', orole, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  
  public SaveReleaseNotes(releasenotesdata: ReleaseNotes): Promise<ReleaseNotes> {

    const promise = new Promise<ReleaseNotes>((resolve, reject) => {

      this.httpclient.post<ReleaseNotes>(this.settings.baseUrl + 'api/users/SaveReleaseNotes', releasenotesdata, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public SaveSubscription(subscription: SubScription): Promise<SubScription> {

    const promise = new Promise<SubScription>((resolve, reject) => {

      this.httpclient.post<SubScription>(this.settings.baseUrl + 'api/users/SaveSubscription', subscription, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetSubscriptionName(): Promise<SubScription> {
    const promise = new Promise<SubScription>((resolve, reject) => {
      this.httpclient.get<SubScription>(this.settings.baseUrl + 'api/users/GetSubscription', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetSelectedUsermyDashDefGridBox(userid:number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetSelectedUsermyDashDefGridBox/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public getAllReleaseNotes(): Promise<any> {

    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/getAllReleaseNotes/', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });

    return promise;
  }

  deleteReleaseNotes(ReleaseNoteId: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/DeleteReleaseNotes/' + ReleaseNoteId, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  DeleteUser(DeleteUserId: number, ReplaceUserId: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/DeleteUser/' + DeleteUserId + '/' + ReplaceUserId, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public sendEmail(emailData: ContactUs): Promise<ContactUs> {
    const promise = new Promise<ContactUs>((resolve, reject) => {

      this.httpclient.post<ContactUs>(this.settings.baseUrl + 'api/users/SendEmmail', emailData, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public updateRegisteredUser(user: Users): Promise<Users> {

    const promise = new Promise<Users>((resolve, reject) => {

      this.httpclient.post<Users>(this.settings.baseUrl + 'api/users/UpdateRegisteredUser', user, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public ResetPassword(userid: number, password: string): Promise<any> {
     
    //const promise = new Promise((resolve, reject) => {

    //  this.httpclient.get(this.settings.baseUrl + 'api/users/ResetPassword/' + userid + "/" + password).subscribe((res) => {

    //    resolve(res);

    //  });
    //});
    //return promise;



    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/ResetPassword/' + userid + '/' + password, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetEmail(user: Users): Promise<Users> {

    const promise = new Promise<Users>((resolve, reject) => {
      this.httpclient.post<Users>(this.settings.baseUrl + 'api/users/IsEmailExists', user, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public saveUserPrivilages(privilages: Privilages[]): Promise<Privilages[]> {

    const promise = new Promise<Privilages[]>((resolve, reject) => {
      this.httpclient.post<Privilages[]>(this.settings.baseUrl + 'api/users/SaveUserPrivilages', privilages, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public SaveRoleName(RoleObj: Role): Promise<Role> {
     
    const promise = new Promise<Role>((resolve, reject) => {
      this.httpclient.post<Role>(this.settings.baseUrl + 'api/users/AddOrUpdateRole', RoleObj, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  //SaveUserPrivilagesNew
  public SaveUserPrivilagesNew(privilages: SaveResourcePrivileges): Promise<SaveResourcePrivileges> {
    const promise = new Promise<SaveResourcePrivileges>((resolve, reject) => {
      this.httpclient.post<SaveResourcePrivileges>(this.settings.baseUrl + 'api/users/SaveUserPrivilagesNew', privilages, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetPrivilagesByuserId(userid: number): Promise<any> {

    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetPrivilagesByuserId/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });

    return promise;
  }
  public GetUserByuserId(userid: number): Promise<any> {

    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetUserByuserId/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });

    return promise;
  }

  public updateuser(ids: string[]): Promise<string[]> {
    const promise = new Promise<string[]>((resolve, reject) => {
      this.httpclient.post<string[]>(this.settings.baseUrl + 'api/users/UpdateUser', ids, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  getprivilagebyuser(assigneduserid: number, privilageid: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/privilages/' + assigneduserid + "/" + privilageid, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  addquota(quotas: Userquota[]): Promise<Userquota[]> {
    const promise = new Promise<Userquota[]>((resolve, reject) => {
      this.httpclient.post<Userquota[]>(this.settings.baseUrl + 'api/users/SaveQuota', quotas, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  addDocuments(documents: Document[]): Promise<Document[]> {
     
    const promise = new Promise<Document[]>((resolve, reject) => {
      this.httpclient.post<Document[]>(this.settings.baseUrl + 'api/documents/SaveDocuments', documents, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  DocumentsHistory(documents: DocumentHistory[]): Promise<DocumentHistory[]> {

    const promise = new Promise<DocumentHistory[]>((resolve, reject) => {
      this.httpclient.post<DocumentHistory[]>(this.settings.baseUrl + 'api/documents/SaveDocumentsHistory', documents, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  addDocumentNamePermission(documents: Document[]): Promise<Document[]> {
    const promise = new Promise<Document[]>((resolve, reject) => {
      this.httpclient.post<Document[]>(this.settings.baseUrl + 'api/documents/SaveDocumentNamePermission', documents,{ headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  addExcelContacts(documents: Contacts[]): Promise<Contacts[]> {
     
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      this.httpclient.post<Contacts[]>(this.settings.baseUrl + 'api/Accounts/SaveImportedContactsData', documents, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  addContacts(contacts: Contacts[]): Promise<Contacts[]> {
     
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      this.httpclient.post<Contacts[]>(this.settings.baseUrl + 'api/accounts/SaveContactsData', contacts, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  addCustomersdata(customers: Accounts[]): Promise<Accounts[]> {
    const promise = new Promise<Accounts[]>((resolve, reject) => {
      this.httpclient.post<Accounts[]>(this.settings.baseUrl + 'api/accounts/SaveAccountData', customers, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  addtransfered(fromdocumentid:number,todocumentid:number): Promise<Document[]> {
    const promise = new Promise<Document[]>((resolve, reject) => {
      this.httpclient.get<Document[]>(this.settings.baseUrl + 'api/documents/tansferedtodocument/' + fromdocumentid + '/' + todocumentid, { headers: this.reqHeader })
      .subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  
  UpdateDocumentName(document: Document): Promise<Document> {
    const promise = new Promise<Document>((resolve, reject) => {
      this.httpclient.post<Document>(this.settings.baseUrl + 'api/documents/UpdateDocumentName', document, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  GetImageData(documentId: number): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.get<string>(this.settings.baseUrl + 'api/documents/GetImageData/' + documentId, { headers: this.reqHeader })
        .subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  UpdatePermissionLevel(document: Document): Promise<Document> {
    const promise = new Promise<Document>((resolve, reject) => {
      this.httpclient.post<Document>(this.settings.baseUrl + 'api/documents/UpdatePermissionLevel', document, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  DeleteDocument(documentId: string): Promise<Document[]> {
     
    let obj: string[] = [documentId];
    const promise = new Promise<Document[]>((resolve, reject) => {
      this.httpclient.post<Document[]>(this.settings.baseUrl + 'api/documents/DeleteDocument', obj, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  
  DeleteDocumentNamePermission(documentId: string): Promise<Document[]> {
     
    let obj: string[] = [documentId];
    const promise = new Promise<Document[]>((resolve, reject) => {
      this.httpclient.post<Document[]>(this.settings.baseUrl + 'api/documents/DeleteDocumentNamePermission', obj, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  addDocumentsauditlog(auditlog: Auditlog): Promise<Auditlog> {
    const promise = new Promise<Auditlog>((resolve, reject) => {
      this.httpclient.post<Auditlog>(this.settings.baseUrl + 'api/documents/SaveAuditlog', auditlog, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  attachdocumentstoaccount(auditlog: AccountDocuments): Promise<AccountDocuments> {
    const promise = new Promise<AccountDocuments>((resolve, reject) => {
      this.httpclient.post<AccountDocuments>(this.settings.baseUrl + 'api/documents/SaveAccountDocuments', auditlog, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  SaveDashBoardConfig(dashboardconfig: Dashboardconfig[]): Promise<Dashboardconfig[]> {
    const promise = new Promise<Dashboardconfig[]>((resolve, reject) => {
      this.httpclient.post<Dashboardconfig[]>(this.settings.baseUrl + 'api/users/SaveDashBoardConfig', dashboardconfig, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  SaveAutomatedEmailConfig(automatedconfig: AutomatedEmails[]): Promise<AutomatedEmails[]> {
    const promise = new Promise<AutomatedEmails[]>((resolve, reject) => {
      this.httpclient.post<AutomatedEmails[]>(this.settings.baseUrl + 'api/users/SaveAutomatedEmail', automatedconfig, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  checkAssociatedGroup(taskgroupid: number): Promise<number> {
    const promise = new Promise<number>((resolve, reject) => {
      this.httpclient.get<number>(this.settings.baseUrl + 'api/users/checkAssociatedGroup/' + taskgroupid, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  updateRole(roleid: string,userid:number): Promise<number> {
    const promise = new Promise<number>((resolve, reject) => {
      this.httpclient.get<number>(this.settings.baseUrl + 'api/users/UpdateRoleByname/' + roleid + "/" + userid, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  DeactivateTaskGroup(taskgroupid: number, status:number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/DeAcivateTaskGroupByName/' + taskgroupid + '/' + status, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  modifyTaskGroupidtoUsers(fromgroupid: number, togroupid:string): Promise<number> {
    const promise = new Promise<number>((resolve, reject) => {
      this.httpclient.get<number>(this.settings.baseUrl + 'api/users/UpdateGroup/' + fromgroupid + '/' + togroupid, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAccessUserdata(userid: number): Promise<AutomatedEmails[]> {

    const promise = new Promise<AutomatedEmails[]>((resolve, reject) => {
      this.httpclient.get<AutomatedEmails[]>(this.settings.baseUrl + 'api/customers/GetAllAutomatedEmails/' + userid, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public AddOrUpdateProposalCategory(ProposalCategory: ProposalCategory): Promise<ProposalCategory> {

    const promise = new Promise<ProposalCategory>((resolve, reject) => {
      this.httpclient.post<ProposalCategory>(this.settings.baseUrl + "api/configuration/AddOrUpdateProposalCategory", ProposalCategory, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }


  public GetAllProposalCategory(userId: number): Promise<ProposalCategory[]> {
    const promise = new Promise<ProposalCategory[]>((resolve, reject) => {
      this.httpclient.get<ProposalCategory[]>(this.settings.baseUrl + "api/configuration/GetAllProposalCategory/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetAllProposalItems(userId: number,categoryId:number): Promise<ProposalItems[]> {
    const promise = new Promise<ProposalItems[]>((resolve, reject) => {
      this.httpclient.get<ProposalItems[]>(this.settings.baseUrl + "api/configuration/GetAllProposalItems/" + userId + "/" + categoryId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetAllProposalItemsWithSortOrder(userId: number, categoryId: number): Promise<ProposalItems[]> {
    const promise = new Promise<ProposalItems[]>((resolve, reject) => {
      this.httpclient.get<ProposalItems[]>(this.settings.baseUrl + "api/configuration/GetAllProposalItemsWithSortOrder/" + userId + "/"+categoryId
        , { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAllProposalsByAccount(userId: number): Promise<Proposals[]> {
    const promise = new Promise<Proposals[]>((resolve, reject) => {
      this.httpclient.get<Proposals[]>(this.settings.baseUrl + "api/configuration/GetAllProposalsByAccount/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public LastCreatedProposalsByAccount(userId: number): Promise<Proposals[]> {
    const promise = new Promise<Proposals[]>((resolve, reject) => {
      this.httpclient.get<Proposals[]>(this.settings.baseUrl + "api/configuration/LastCreatedProposalsByAccount/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetPendedProposalsByAccount(userId: number): Promise<ProposalsModel[]> {
    const promise = new Promise<ProposalsModel[]>((resolve, reject) => {
      this.httpclient.get<ProposalsModel[]>(this.settings.baseUrl + "api/configuration/GetPendedProposalsByAccount/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetPendedProposalByContact(conId: number): Promise<ProposalsModel[]> {
    const promise = new Promise<ProposalsModel[]>((resolve, reject) => {
      this.httpclient.get<ProposalsModel[]>(this.settings.baseUrl + "api/configuration/GetPendedProposalsByConatct" + conId, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAllProposals(userId: string, prstatus: string, isPended: number,startDate:string,endDate:string): Promise<ProposalsModel[]> {
    const promise = new Promise<ProposalsModel[]>((resolve, reject) => {
      this.httpclient.get<ProposalsModel[]>(this.settings.baseUrl + "api/configuration/GetAllProposals/" + userId + "/" + prstatus + "/" + isPended + "/" + startDate + "/" + endDate, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAllPendedProposals(userId: string, Pended: number): Promise<ProposalsModel[]> {
    const promise = new Promise<ProposalsModel[]>((resolve, reject) => {
      this.httpclient.get<ProposalsModel[]>(this.settings.baseUrl + "api/configuration/GetAllPendedProposals/" + userId + "/" + Pended, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public InsertUpdateProposalItems(ProposalCategory: ProposalItems): Promise<ProposalItems> {

    const promise = new Promise<ProposalItems>((resolve, reject) => {
      this.httpclient.post<ProposalItems>(this.settings.baseUrl + "api/configuration/InsertUpdateProposalItems", ProposalCategory, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public InsertProposal(Proposal: Proposals): Promise<Proposals> {

    const promise = new Promise<Proposals>((resolve, reject) => {
      this.httpclient.post<Proposals>(this.settings.baseUrl + "api/configuration/InsertProposal", Proposal, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }


  public AddOrUpdateOpportunityLeadtype(OpportynityLeadType: OpportunityLeadTypes): Promise<OpportunityLeadTypes> {
    const promise = new Promise<OpportunityLeadTypes>((resolve, reject) => {
      this.httpclient.post<OpportunityLeadTypes>(this.settings.baseUrl + "api/configuration/AddOrUpdateOpportunityLeadtype", OpportynityLeadType, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAllOpportunityLeadTypes(userId: number): Promise<OpportunityLeadTypes[]> {
    const promise = new Promise<OpportunityLeadTypes[]>((resolve, reject) => {
      this.httpclient.get<OpportunityLeadTypes[]>(this.settings.baseUrl + "api/configuration/GetAllOpportunityLeadTypes/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }


  public GetProposalsByDate(Date: string): Promise<Proposals[]> {
    const promise = new Promise<Proposals[]>((resolve, reject) => {
      this.httpclient.get<Proposals[]>(this.settings.baseUrl + "api/configuration/GetProposalsByDate/" + Date, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      }); 
    });
    return promise;
  }

  //public GenerateProposalDocument(Proposal: Proposals): Promise<any> {
  //  const promise = new Promise<any>((resolve, reject) => {
  //    this.httpclient.post<any>(this.settings.baseUrl + "api/configuration/GenerateProposalDocument", Proposal).subscribe(res => {
  //      resolve(res);
  //    });
  //  });
  //  return promise;
  //}

  public GenerateProposalDocument(Proposal: Proposals) {
    return this.httpclient.post(this.settings.baseUrl + `api/configuration/GenerateProposalDocument`, Proposal, { headers: this.reqHeader, responseType: 'arraybuffer' });
  }


  public GeneratePODocument(Proposal: Proposals) {
    return this.httpclient.post(this.settings.baseUrl + `api/configuration/GeneratePODocument`, Proposal, { headers: this.reqHeader,responseType: 'arraybuffer' });
  }


  public GetProposalsDocumentContent(DocID: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + "api/configuration/GetProposalsDocumentContent/" + DocID, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetStatusItemsByStatusType(userId: number): Promise<StatusItems[]> {
    const promise = new Promise<StatusItems[]>((resolve, reject) => {
      this.httpclient.get<StatusItems[]>(this.settings.baseUrl + "api/configuration/GetStatusItemsByStatusType/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAllStatusIds(userId: number,groupId:number): Promise<StatusIDCategory[]> {
    const promise = new Promise<StatusIDCategory[]>((resolve, reject) => {
      this.httpclient.get<StatusIDCategory[]>(this.settings.baseUrl + "api/configuration/GetAllStatusIds/" + userId + "/" + groupId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetAllStatusIdGroups(userId: number): Promise<StatusIDGroup[]> {
    const promise = new Promise<StatusIDGroup[]>((resolve, reject) => {
      this.httpclient.get<StatusIDGroup[]>(this.settings.baseUrl + "api/configuration/GetAllStatusIdGroups/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAllStatusTypes(userId: number): Promise<StatusTypes[]> {
    const promise = new Promise<StatusTypes[]>((resolve, reject) => {
      this.httpclient.get<StatusTypes[]>(this.settings.baseUrl + "api/configuration/GetAllStatusTypes/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetAllTriggerTypes(userId: number): Promise<TriggerTypes[]> {
    const promise = new Promise<TriggerTypes[]>((resolve, reject) => {
      this.httpclient.get<TriggerTypes[]>(this.settings.baseUrl + "api/configuration/GetAllTriggerTypes/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public UpdateStatusType(statustypeId: number): Promise<StatusIDCategory[]> {
    const promise = new Promise<StatusIDCategory[]>((resolve, reject) => {
      this.httpclient.get<StatusIDCategory[]>(this.settings.baseUrl + "api/configuration/UpdatestatustypeId/" + statustypeId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public UpdateTriggerType(TriggertypeId: number): Promise<TriggerTypes[]> {
    const promise = new Promise<TriggerTypes[]>((resolve, reject) => {
      this.httpclient.get<TriggerTypes[]>(this.settings.baseUrl + "api/configuration/UpdateTriggertypeId/" + TriggertypeId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetAllAddedStatusIds(userId: number, statusId: number): Promise<StatusIDCategory[]> {
    const promise = new Promise<StatusIDCategory[]>((resolve, reject) => {
      this.httpclient.get<StatusIDCategory[]>(this.settings.baseUrl + "api/configuration/GetAllStatusIdsList/" + userId + "/" + statusId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetAlldropdownStatusIds(userId: number, statusId: number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + "api/configuration/GetAllStatusIdsDropdown/" + userId + "/" + statusId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetUserMessages(viewSuspendedUsers: number, messageID: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetAllUsersForUserMessage/' + viewSuspendedUsers + '/' + messageID, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }

  public UpdateAccountStatusId(oldstatusId: number, NewStatusId: number): Promise<StatusIDCategory[]> {
    const promise = new Promise<StatusIDCategory[]>((resolve, reject) => {
      this.httpclient.get<StatusIDCategory[]>(this.settings.baseUrl + "api/configuration/UpdateaccountStatusids/" + oldstatusId + "/" + NewStatusId
        , { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetattattachedstatusIds(userId: number, statusId: number): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.get<string>(this.settings.baseUrl + 'api/configuration/CheckStatusIdLinked/' + userId + '/' + statusId, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public AddOrUpdateStatusIds(statusids: StatusIDCategory): Promise<StatusIDCategory> {

    const promise = new Promise<StatusIDCategory>((resolve, reject) => {
      this.httpclient.post<StatusIDCategory>(this.settings.baseUrl + "api/configuration/AddOrUpdateStatusIDCategory", statusids, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public AddOrUpdateStatusIDGroups(statusids: StatusIDGroup): Promise<StatusIDGroup> {

    const promise = new Promise<StatusIDGroup>((resolve, reject) => {
      this.httpclient.post<StatusIDGroup>(this.settings.baseUrl + "api/configuration/AddOrUpdateStatusIDGroups", statusids, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public SaveCategoriesOrder(Order: string, type: string, userId: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/SaveCategoriesOrder/' + Order + '/' + type + '/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public SaveConfigFieldDataSortOrder(Order: string, FieldName: string, OppFieldId:number, userId: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/SaveConfigFieldDataSortOrder/' + Order + '/' + FieldName + '/' + OppFieldId + '/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public AddOrUpdateSoftwareLicenseCategory(SL_Category: SLCategories): Promise<SLCategories> {

    const promise = new Promise<SLCategories>((resolve, reject) => {
      this.httpclient.post<SLCategories>(this.settings.baseUrl + "api/configuration/AddOrUpdateSoftwareLicenseCategory", SL_Category, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public AddOrUpdateSoftwareLicenseTypes(SL_Types: SLTypes): Promise<SLTypes> {

    const promise = new Promise<SLTypes>((resolve, reject) => {
      this.httpclient.post<SLTypes>(this.settings.baseUrl + "api/configuration/AddOrUpdateSoftwareLicenseTypes", SL_Types, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetAll_SL_Categories(userId: number): Promise<SLCategories[]> {
    const promise = new Promise<SLCategories[]>((resolve, reject) => {
      this.httpclient.get<SLCategories[]>(this.settings.baseUrl + "api/configuration/GetAllSoftwareLicenseCategories/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAll_SL_Types(userId: number): Promise<SLTypes[]> {
    const promise = new Promise<SLTypes[]>((resolve, reject) => {
      this.httpclient.get<SLTypes[]>(this.settings.baseUrl + "api/configuration/GetAllSoftwareLicenseTypes/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetAllSoftwareLicense(userId: number, accConIds: string): Promise<SoftwareLicense[]> {
    const promise = new Promise<SoftwareLicense[]>((resolve, reject) => {
      this.httpclient.get<SoftwareLicense[]>(this.settings.baseUrl + "api/configuration/GetAllSoftwareLicense/" + userId + '/' + accConIds, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }


  public AddOrUpdateSoftwareLicense(Softwarelicense: SoftwareLicense[]): Promise<SoftwareLicense> {

    const promise = new Promise<SoftwareLicense>((resolve, reject) => {
      this.httpclient.post<SoftwareLicense>(this.settings.baseUrl + "api/configuration/AddOrUpdateSoftwareLicense", Softwarelicense, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  InsertAuditLog(AuditLog: AuditLogModel): Promise<AuditLogModel> {
    const promise = new Promise<AuditLogModel>((resolve, reject) => {
      this.httpclient.post<AuditLogModel>(this.settings.baseUrl + "api/users/InsertAuditLog", AuditLog, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  //weeklyReport(userId): Promise<any> {

  //  const promise = new Promise<any>((resolve, reject) => {
  //    this.httpclient.post<any>(this.settings.baseUrl + "api/accounts/WeeklyReport", userId).subscribe(res => {
  //      resolve(res);
  //    });
  //  });
  //  return promise;
  //}

  weeklyReport(accountName: string): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.get<string>(this.settings.baseUrl + 'api/accounts/WeeklyReport/' + accountName, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
}
