<div id="map" class="col-xs-12" style="padding:0;border:1px solid black;height:100%"> 
    <div class="" style="height:100%;margin:0;">
      <div class="box-body Google" style="height:100%">

        <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
        <agm-map #map [latitude]="lat" [longitude]="lng" [zoom]="3" [mapTypeControl]="true" style="height:93%">

          <agm-marker *ngFor="let marker of markers"
                      [latitude]="marker.lat"
                      [longitude]="marker.lng"
                      [opacity]="marker.alpha"
                      (mouseOver)="onMouseOver(infoWindow, $event,marker.lat,marker.lng)"
                      (mouseOut)="onMouseOut(infoWindow, $event)"
                      [markerDraggable]="true"
                      [openInfoWindow]="true"
                      [agmFitBounds]="true"
                      (markerClick)="selectMarker($event)">

            <agm-info-window [disableAutoPan]="false" #infoWindow>

              <div>
                <b>{{this.Name}}</b>
                <div>

                </div>
              </div>

            </agm-info-window>
          </agm-marker>

        </agm-map>
      </div>
    </div>
</div>


