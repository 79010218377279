export class Calllist {
  public id: number;
  public userid: number;
  public calllistname: string;
  public contactids: string;
  public accountids: string;
  public lastaccesseduserid: number;
  public Calllistselectedtype: string;
  public typeOfList: string;
  public status: string;
  public created: string;
  public lastaccesed: string;
  public completedcontactids: string;
  public Assignedto: number;
  public assignedusername: string;
  public createdusername: string;
  public deletedcontactids: string;
  public islimitedSearch: boolean;
  public SearchVal: string;
  public currentAction: string;
}
