<div class="col-xs-12" style="padding: 0; height: 100%; ">
    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="TicketConfigurationPage">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Ticket Configuration</span>
      </h4>
      <div class="col-xs-4" style="height:93%;background-color:white;padding:0;">
        <div class="col-xs-12" style="margin-top:0%;border:1px solid lightgrey;height:99%">
          <div class="col-xs-12" style="padding:0;height:6%;margin-top:3%">
            <button class="" style="padding:0;height:28px;background:white;" *ngIf="salesxcrmbtn == 1" (click)="BtnClick('DisableSalesXcrm')">
              <img src="../../assets/img/range on.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
            </button>
            <button class="" style="padding:0;height:28px;background:white;" *ngIf="salesxcrmbtn == 0" (click)="BtnClick('EnableSalesXcrm')">
              <img src="../../assets/img/range off.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
            </button>
            <span>&nbsp;&nbsp;Enable SalesXcrm Ticket System </span>
          </div>
          <div class="col-xs-12" style="padding:0;height:6%">
            <button class="" style="padding:0;height:28px;background:white;" *ngIf="taskGroupBtn == 0" (click)="BtnClick('ActiveTaskGroup')">
              <img src="../../assets/img/range off.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
            </button>
            <button class="" style="padding:0;height:28px;background:white;" *ngIf="taskGroupBtn == 1" (click)="BtnClick('AllTaskGroup')">
              <img src="../../assets/img/range on.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
            </button>
            <span>&nbsp;&nbsp;Include Active Task Groups Only </span>
          </div>
          <div class="col-xs-12" style="padding:0;height:6%">
            <button class="" style="padding:0;height:28px;background:white;" *ngIf="userBtn == 0" (click)="BtnClick('ActiveUser')">
              <img src="../../assets/img/range off.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
            </button>

            <button class="" style="padding:0;height:28px;background:white;" *ngIf="userBtn == 1" (click)="BtnClick('AllUser')">
              <img src="../../assets/img/range on.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
            </button>
            <span>&nbsp;&nbsp;Include Active Users Only </span>
          </div>
          <!--<div class="col-xs-12" style="padding:0;height:6%">
            <button class="" style="padding:0;height:28px;background:white;">
              <img src="../../assets/img/range off.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
            </button>
            <span>&nbsp;&nbsp;Activate Zendesk Integration </span>
          </div>-->
          <div class="col-xs-12" style="padding:0;height:66%">

          </div>
          <div class="col-xs-12" style="padding:0;height:6%;text-align:right" *ngIf="ticketConfigurationChange">
            <button class="btn saveButtonCSS" style="background:#f7f7f7;margin-top:5px" (click)="Cancelticket('TicketSettings')">Cancel </button>
            <button class="btn saveButtonCSS" style="margin-top:5px;margin-left: 2%;width: 19%;background-color: rgb(162, 228, 119);border: 1px solid black !important;"
                    (click)="SaveTicket('TicketSettings')">
              Save
            </button>

          </div>

        </div>
      </div>
      <div class="col-xs-8" style="padding:0;height:93%" *ngIf="salesxcrmbtn == 1">

        <div class="col-xs-12" style="padding:0;height:6%;">
          <div class="col-xs-12" style="padding:0.5%;height:98%;background:#2A3740;color:white;text-align:center;font-size:17px;"><label>Select the Task Groups and Users for Tickets</label></div>
        </div>

        <div class="col-xs-12" style="padding:0;height:94%">
          <div class="col-xs-6" style="padding:0.5%;height:100%;border:1px solid lightgrey">
            <div class="col-xs-12" style="height:10%;text-align:center">
              <label style="font-size:18px;text-decoration:underline"> Task Groups</label>
            </div>
            <div class="col-xs-12 scroll-c" style="height:82%;overflow:auto">
              <div class="col-xs-12" style="" *ngFor="let taskgroup of taskgrouplist">
                <div class="col-xs-12" style="display:flex">
                  <label class="container" style="font-size: 17px;">
                    &nbsp;&nbsp;&nbsp;
                    {{taskgroup.itemName}}
                    <input type="checkbox" id="TG{{taskgroup.id}}" (click)="selectedTasks(taskgroup.id)">
                    <span class="checkmark" id="TG{{taskgroup.id}}"></span>
                  </label>


                </div>
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;height:6%;text-align:right" *ngIf="ticketTaskGroupChange">
              <button class="btn saveButtonCSS" style="background:#f7f7f7;margin-top:5px" (click)="Cancelticket('TaskGroupsCancel')">Cancel </button>

              <button class="btn saveButtonCSS" style="margin-top:5px;margin-left: 2%;width: 19%;background-color: rgb(162, 228, 119);border: 1px solid black !important;" (click)="SaveTicket('TaskGroupsSave')">Save </button>
            </div>
          </div>
          <div class="col-xs-6" style="padding:0.5%;height:100%;border:1px solid lightgrey">
            <div class="col-xs-12" style="height:10%;text-align:center">
              <label style="font-size:18px;text-decoration:underline"> Users List</label>
            </div>
            <div class="col-xs-12 scroll-c" style="height:82%;overflow:auto">
              <div class="col-xs-12" style="" *ngFor="let user of userList">
                <div class="col-xs-12" style="display:flex">
                  <label class="container" style="font-size: 17px;">
                    &nbsp;&nbsp;&nbsp;
                    {{user.username}}
                    <input type="checkbox" id="User{{user.id}}" (click)="selectedUsers(user.id)">
                    <span class="checkmark" id="User{{user.id}}"></span>
                  </label>


                </div>
              </div>
            </div>

            <div class="col-xs-12" style="padding:0;height:6%;text-align:right" *ngIf="ticketUserListChange">
              <button class="btn saveButtonCSS" style="background:#f7f7f7;margin-top:5px" (click)="Cancelticket('UserCancel')">Cancel </button>

              <button class="btn saveButtonCSS" style="margin-top:5px;margin-left: 2%;width: 19%;background-color: rgb(162, 228, 119);border: 1px solid black !important;" (click)="SaveTicket('UserSave')">Save </button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ZendeskActivationPage">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Zendesk Integration Configuration</span>
      </h4>
      <div class="col-xs-12" style="padding:0;height:6%">
        <button class="" style="padding:0;height:28px;background:white;">
          <img src="../../assets/img/range off.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
        </button>
        <span>&nbsp;&nbsp;Activate Zendesk Integration </span>
      </div>
    </div>

  </div>
