import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions } from '@angular/http';
import { AppSettings } from '../app.settings';
import { CookieService } from 'angular2-cookie/core';
import { ToastrService } from 'ngx-toastr';
declare var require: any;
declare var $: any;
import { CustomerService } from '../services/customer.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  LoggedInUser: any;
  userprevilages: any;
  public Formdata: any = [];
  email: string;
  password: string;
  remeberMe: boolean;
  capsOn: boolean = false;
  currentuser: any;
  tab: string = 'Login';
  currentYear = new Date().getFullYear();
  ShowPopUpDiv = false;

  constructor(private toastr: ToastrService, private router: Router, private http: Http, private appSettings: AppSettings, private customerService: CustomerService,
    private _cookieService: CookieService,private spinnerService: Ng4LoadingSpinnerService) {
    if (_cookieService.get('remember')) {
      this.Formdata.username = this._cookieService.get('username');
    }
  }

  ngOnInit(): void {
    document.getElementById('reauth-email').innerHTML = '';
    $('#submit').focus();
    $('#Passwords').keypress(function (e) {
      var s = String.fromCharCode(e.which);
      if (s.toUpperCase() === s && s.toLowerCase() !== s && !e.shiftKey) {
       // alert('caps is on');
      }
    });
  }

  getCookie(key: string) {
    return this._cookieService.get(key);
  }

  CaplockCheck(event) {
    let that = this;
    var input = document.getElementById("Passwords");
    document.addEventListener("keyup", function (event) {
      if ((event.keyCode >= 65 && event.keyCode <= 90)) {
        var res = that.isCapslock(event);
        that.ShowPopUpDiv = res;
      }
    });
  }

  isCapslock(e) {
    const charCode = e.keyCode;
    const shiftKey = e.shiftKey;
    const capslockstatus = e.getModifierState("CapsLock");

    let capsLock;
    if ((charCode >= 65 && charCode <= 90) && (shiftKey || capslockstatus)) {
      capsLock = true;
      return capsLock;
    } else {
      return false;
    }



  }


  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {

  }

  capsleave(event) {

  }

  submitData() {
    this.spinnerService.show();
    if (this.Formdata.rememberme == true) {
      this._cookieService.put('username', this.Formdata.username.trim());
      this._cookieService.put('remember', this.Formdata.rememberme);
    } else {
      this._cookieService.put('username', '');
      this._cookieService.put('remember', '');
    }

    if (this.Formdata.username == null && this.Formdata.password == null) {
      document.getElementById('reauth-email').innerHTML = 'Please provide username and password !!';

    }
    else {
     
      this.customerService.Login(this.Formdata.username.trim(), this.Formdata.password).then((user) => {  
       this.spinnerService.hide();
        var token = user.token;    
        if (user.username == null && user.password == null) {
          document.getElementById('reauth-email').innerHTML = 'Invalid Username or Password';
          this.Formdata.password = '';
        }
        else if (user.loggedinaccess == 0 || user.loggedinaccess == 1) {
          user.username = user.firstname + " " + user.lastname;
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('temptoken', user.temptoken);
          this.router.navigateByUrl('/application/dashboard/tab1');
          this.customerService.updatelogin(user.id, 1, null, 0).then((user) => {           
          });
        }
        else if (user.systemadministrator == 1 && user.userrole == 8) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('temptoken', user.temptoken);
          localStorage.setItem('calanderView', user.calanderView);         
          this.router.navigateByUrl('/application/dashboard/tab1');
          this.customerService.updatelogin(user.id, 1, null, 0).then((user) => {
            localStorage.setItem('AuditLogId', JSON.stringify(user.AuditLogId));
          });
        }
        else if (user.loggedinaccess == 1) {
          this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
          document.getElementById('reauth-email').innerHTML = 'Access Denied - You are Currently Logged in another session – please logout of that session before trying to login again';
        }
      });
    }
  }
  CheckUserExist() {
    if (this.Formdata.username == "") {
      document.getElementById('reauth-email').innerHTML = 'Please provide email address !!';

    }
    else {
      this.customerService.CheckUsernameExistOrNot(this.Formdata.username, this.Formdata.password).then((user) => {

        if (user.username != null) {
          this.tab = "Sent";
        } else {
          document.getElementById('reauth-email').innerHTML = 'Your email was not found <br/> Please re-enter your email to try again';
        }
      });

    }
  }
  Forgotpassword() {
    this.router.navigateByUrl('/forgotpassword');
  }
  ForgotOrResetpassword(val) {
    if (val == "Forgot") {
      this.Formdata.username = '';
      this.Formdata.password = null;
      document.getElementById('reauth-email').innerHTML = '';
    } else if (val == "Login") {
      document.getElementById('reauth-email').innerHTML = '';
    }

    this.tab = val;

  }
  showhide(event) {
    var input = $('#Passwords');
    if (input.attr("type") == "password") {
      $("#passwordEye").removeClass("fa fa-fw fa-eye field-icon toggle-password");
      $("#passwordEye").addClass("fa fa-fw fa-eye-slash field-icon toggle-password");
      input.attr("type", "text");
    } else {   
      $("#passwordEye").removeClass("fa fa-fw fa-eye-slash field-icon toggle-password");
      $("#passwordEye").addClass("fa fa-fw fa-eye field-icon toggle-password");

      input.attr("type", "password");
    }
    this.CaplockCheck(event);
  }

}
