export class Viewcalllist {
  public term: string;
  public contactids: string;
  public completedcontactids: string;
  public currentuserid: string;
  public startdate: string;
  public enddate: string;
  public deletedcontacts: string;
  public isCustomertypelist: string;
}
