import { Injectable, EventEmitter } from '@angular/core';
import { Message } from '../models/message';


@Injectable({
  providedIn: 'root'
})
@Injectable()
export class ChatService {
  messageReceived = new EventEmitter<Message>();
  connectionEstablished = new EventEmitter<Boolean>();

}  
