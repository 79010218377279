<div class="col-xs-12" style="padding-left:0;padding-right: 0;height:100%">
  <div class="col-xs-11" style="height: 6%;text-align: left">
   
  </div>
  <div class="col-xs-1" style="height: 6%;text-align: right">
    <span style="float:right;margin-top:7%" (click)="cancelAccount();"> <img src="../../../assets/img/Black x.png" style="height:25px" /> </span>
  </div>
  <form [formGroup]="NewForm" style="height:100%">
    <div class="col-xs-4" style="padding:0;">
      <div *ngFor="let field of Fields">

        <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%" *ngIf="field.ColumnName != 'address1' && field.ColumnName != 'address2' && field.ColumnName != 'city' && field.ColumnName != 'state' && field.ColumnName != 'zip' && field.ColumnName != 'Country' && field.ColumnName != 'ProvidenceIsland'">
          <div class="col-xs-3" style="padding-right:0;">
            <label *ngIf="field.ColumnName == 'customertypeid'">Active Or Inactive</label>

            <label *ngIf="field.ColumnName != 'customertypeid'">{{field.DisplayAs}}</label>
          </div>
          <div class="col-xs-9">
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-12" style="padding:0;" *ngIf="field.OldOrNew == 'Old'">
                <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)">
                  <option value="0"> </option>
                  <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                    {{customer.itemName}}
                  </option>
                </select>
              </div>

              <div class="col-xs-12" style="padding:0;" *ngIf="field.OldOrNew == 'New'">
                <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)">
                  <option *ngFor="let customer of field.Data" value="{{customer.id}}" style="color:black">
                    {{customer.itemName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;" *ngIf="(field.ColumnName != 'phone' && field.ColumnName != 'fax' && field.ColumnName != 'alterphone2' && field.ColumnName != 'alterphone1' && field.ColumnName != 'website')">
              <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName != 'address1' || field.ColumnName != 'address2' || field.ColumnName != 'city' || field.ColumnName != 'state' || field.ColumnName != 'zip' || field.ColumnName != 'country' || field.ColumnName != 'ProvidenceIsland')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (keyup)="FormDataEntered($event.target)" />
            </div>
            <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'phone' || field.ColumnName == 'fax' || field.ColumnName == 'alterphone2' || field.ColumnName == 'alterphone1')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" [textMask]="{mask: phonemask}" (keyup)="FormDataEntered($event.target)" />

            <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'website')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" pattern="([Hh][Tt][Tt][Pp][Ss]?:\/\/)?(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" (keyup)="FormDataEntered($event.target)" />

            <input type="date" class="form-control decreasefieldSize" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="((field.DataType == 'Date' ||  field.DataType == 'Date/Time'))" [required]="(field.RequiredField == 'Yes')? 'true':'false'" value="{{account[field.ColumnName]}}" (change)="FormDataEntered($event.target)" />


            <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Date/YearOnly'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)">
              <option *ngFor="let customer of Years" value="{{customer.value}}" style="color:black">
                {{customer.label}}
              </option>
            </select>

          </div>
        </div>
        <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%" *ngIf="field.ColumnName == 'address1'">
          <app-address [locationType]="'loc'" [Index]="1" (sendMsgEvent)="AddressReceived($event)"></app-address>
        </div>

      </div>
    </div>
    <div class="col-xs-4" style="padding:0;">
      <div *ngFor="let field of SpecificsFields">

        <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%" *ngIf="field.ColumnName != 'address1' && field.ColumnName != 'address2' && field.ColumnName != 'city' && field.ColumnName != 'state' && field.ColumnName != 'zip' && field.ColumnName != 'Country' && field.ColumnName != 'ProvidenceIsland'">
          <div class="col-xs-4" style="padding-right:0;">
            <label *ngIf="field.ColumnName == 'customertypeid'">Active Or Inactive</label>

            <label *ngIf="field.ColumnName != 'customertypeid'">{{field.DisplayAs}}</label>
          </div>
          <div class="col-xs-8">
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-12" style="padding:0;" *ngIf="field.OldOrNew == 'Old'">
                <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)">
                  <option value="0"> </option>
                  <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                    {{customer.itemName}}
                  </option>
                </select>
              </div>

              <div class="col-xs-12" style="padding:0;" *ngIf="field.OldOrNew == 'New'">
                <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'"
                        (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)" [required]="(field.RequiredField == 'Yes')? 'true':'false'">
                  <option *ngFor="let customer of field.Data" value="{{customer.id}}" style="color:black">
                    {{customer.itemName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;" *ngIf="(field.ColumnName != 'phone' && field.ColumnName != 'fax' && field.ColumnName != 'alterphone2' && field.ColumnName != 'alterphone1' && field.ColumnName != 'website')">
              <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName != 'address1' || field.ColumnName != 'address2' || field.ColumnName != 'city' || field.ColumnName != 'state' || field.ColumnName != 'zip' || field.ColumnName != 'country' || field.ColumnName != 'ProvidenceIsland')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (keyup)="FormDataEntered($event.target)" />
            </div>
            <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'phone' || field.ColumnName == 'fax' || field.ColumnName == 'alterphone2' || field.ColumnName == 'alterphone1')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" [textMask]="{mask: phonemask}" (keyup)="FormDataEntered($event.target)" />

            <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'website')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" pattern="([Hh][Tt][Tt][Pp][Ss]?:\/\/)?(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" (keyup)="FormDataEntered($event.target)" />

            <input type="date" class="form-control decreasefieldSize" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="((field.DataType == 'Date' ||  field.DataType == 'Date/Time'))" [required]="(field.RequiredField == 'Yes')? 'true':'false'" value="{{account[field.ColumnName]}}" (change)="FormDataEntered($event.target)" />


            <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Date/YearOnly'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)">
              <option *ngFor="let customer of Years" value="{{customer.value}}" style="color:black">
                {{customer.label}}
              </option>
            </select>

          </div>
        </div>
        <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%" *ngIf="field.ColumnName == 'address1'">
          <app-address [locationType]="'loc'" [Index]="1" (sendMsgEvent)="AddressReceived($event)"></app-address>
        </div>

      </div>
    </div>
    <div class="col-xs-4" style="padding:0;">    
      <div *ngFor="let field of ShippingFields">
        <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%" *ngIf="field.ColumnName != 'address1' && field.ColumnName != 'address2' && field.ColumnName != 'city' && field.ColumnName != 'state' && field.ColumnName != 'zip' && field.ColumnName != 'Country' && field.ColumnName != 'ProvidenceIsland'">
          <div class="col-xs-3" style="padding-right:0;">
            <label *ngIf="field.ColumnName == 'customertypeid'">Active Or Inactive</label>

            <label *ngIf="field.ColumnName != 'customertypeid'">{{field.DisplayAs}}</label>
          </div>
          <div class="col-xs-9">
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-12" style="padding:0;" *ngIf="field.OldOrNew == 'Old'">

                <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)">
                  <option value="0"> </option>
                  <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                    {{customer.itemName}}
                  </option>
                </select>
              </div>

              <div class="col-xs-12" style="padding:0;" *ngIf="field.OldOrNew == 'New'">
                <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Pick List' || field.DataType == 'System Lookup'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value);IsCustomerTypeChange($event.target.value,field.ColumnName)">
                  <option *ngFor="let customer of field.Data" value="{{customer.id}}" style="color:black">
                    {{customer.itemName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;" *ngIf="(field.ColumnName != 'phone' && field.ColumnName != 'fax' && field.ColumnName != 'alterphone2' && field.ColumnName != 'alterphone1' && field.ColumnName != 'website')">
              <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName != 'address1' || field.ColumnName != 'address2' || field.ColumnName != 'city' || field.ColumnName != 'state' || field.ColumnName != 'zip' || field.ColumnName != 'country' || field.ColumnName != 'ProvidenceIsland')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (keyup)="FormDataEntered($event.target)" />
            </div>
            <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'phone' || field.ColumnName == 'fax' || field.ColumnName == 'alterphone2' || field.ColumnName == 'alterphone1')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" [textMask]="{mask: phonemask}" (keyup)="FormDataEntered($event.target)" />

            <input value="{{account[field.ColumnName]}}" [(ngModel)]="account[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'website')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" pattern="([Hh][Tt][Tt][Pp][Ss]?:\/\/)?(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" (keyup)="FormDataEntered($event.target)" />

            <input type="date" class="form-control decreasefieldSize" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="((field.DataType == 'Date' ||  field.DataType == 'Date/Time'))" [required]="(field.RequiredField == 'Yes')? 'true':'false'" value="{{account[field.ColumnName]}}" (change)="FormDataEntered($event.target)" />


            <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="account[field.ColumnName]" *ngIf="field.DataType == 'Date/YearOnly'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)">
              <option *ngFor="let customer of Years" value="{{customer.value}}" style="color:black">
                {{customer.label}}
              </option>
            </select>

          </div>
        </div>
        <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%" *ngIf="field.ColumnName == 'address1'">
          <app-address [locationType]="'loc'" [Index]="1" (sendMsgEvent)="AddressReceived($event)"></app-address>
        </div>

      </div>
      <div class="col-xs-12" style="padding:0;height:6%">
        <div class="col-xs-12" style="padding: 0;margin-top: 31%; ">
          <div class="col-xs-3">
          </div>
          <div class="col-xs-9">
            <div class="col-xs-6" style="padding: 0;">
              <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="cancelAccount()">Cancel</button>
            </div>
            <div class="col-xs-6" style="padding-right: 0;">
              <button type="submit" class="btn saveButton" style="width:100%;" (click)="showEditAddressDiv()" [disabled]="!NewForm.valid">Save  </button>
            </div>
          </div>
        </div>
      </div>

    </div>   
  </form>

</div>
