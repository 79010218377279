<span><button (click)="editRowAg()" class="btn-xs btn-default" [ngStyle]="{'backgroundColor':(params.data.permission == 'view') ? 'white' : 'white','color':(params.data.permission == 'view') ? 'lightgrey' : 'black'}"><i class="fa fa-pencil" aria-hidden="true"></i></button></span>



<span>
  <button id="cv" *ngIf="needCVbtn()" (click)="convertContactAg()" [ngStyle]="{'backgroundColor':(params.data.permission == 'view') ? 'white' : 'white','color':(params.data.permission == 'view') ? 'lightgrey' : (params.data.converttolead == '1') ? 'red':'black'}" class="btn-xs btn-default"><b>CV</b></button>
</span>

<span style="padding:0;"><button id="Sharing" *ngIf="needSharing() && params.data.ownerid==currentuser.id" (click)="sharingAg()" [ngStyle]="{'backgroundColor':(params.data.permission == 'view' ||params.data.isshared==1) ? 'white' : (params.data.permission == 'edit' || params.data.isshared==1 ? 'white' : 'white' ) ,'color':params.data.isshared==0?'black':(params.data.permission == 'view' ||params.data.isshared==1) ? 'rgb(61,184,50)' : (params.data.permission == 'edit' ? 'white' : 'black' ) }" class="btn-xs btn-default"><i style="height:13px;width:13px;" class="fa fa-share-alt" aria-hidden="true"></i></button></span>

<span style="padding:0;"><button id="Sharing" *ngIf="needSharing() && params.data.ownerid!=currentuser.id" (click)="sharingAg()" [ngStyle]="{'backgroundColor':(params.data.permission == 'view' ||params.data.isshared==1) ? 'white' : (params.data.permission == 'edit' || params.data.isshared==1 ? 'white' : 'white' ) ,'color':params.data.isshared==0?'black': (params.data.permission == 'view' ||params.data.isshared==1) ? '#4286f4' : (params.data.permission == 'edit' ? '#4286f4' : 'black' ) }" class="btn-xs btn-default"><i style="height:13px;width:13px;" class="fa fa-share-alt" aria-hidden="true"></i></button></span>

<span><button *ngIf="params.data.isdeleted==0" (click)="deleteRowAg()" [ngStyle]="{'backgroundColor':(params.data.permission == 'view') ? 'white' : 'white','color':(params.data.permission == 'view') ? 'lightgrey' : (params.data.permission == 'edit' ? 'lightgrey' : 'black') }" class="btn-xs btn-default"><i class="fa fa-trash" aria-hidden="true"></i></button></span>
<span><button *ngIf="params.data.isdeleted==1" (click)="ActiateRowAg()" [ngStyle]="{'backgroundColor':(params.data.permission == 'view') ? 'white' : 'white','color':(params.data.permission == 'view') ? 'lightgrey' : 'black'}" class="btn-xs btn-default"><i class="fa fa-undo" aria-hidden="true"></i></button></span>
