import { Component, OnInit, DoCheck, Input, Output, EventEmitter, ElementRef, Renderer2, ViewEncapsulation, IterableDiffers, IterableDiffer } from '@angular/core';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
export enum ParentComp { Contacts, LeadsDash, OpportunitiesDash, ConfigurationDashBoard, TaskListDash, Customer, Account }
declare var $: any;
//export class OutputPropsUserList {
//  @Output() finalitems = "";
//  @Output() items = [];
//  @Output() itemsCount = 0;
//  @Output() selectedItems = [];
//}

@Component({
  selector: 'userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserlistComponent implements OnInit, DoCheck {
  isMultiLine: boolean = false;
  isOpportunitiesDash: boolean = false;

  @Input() parentComp: ParentComp;

  @Input() accessDenied: boolean = false;
  @Input() dropdownList = [];
  @Input() selectedItems = [];
  //@Input()
  dropdownSetting = {};

  //Common
  @Input() finalitems = "";
  @Input() items = [];
  @Input() itemsCount = 0;

  @Input() elementRef: ElementRef;
  @Input() renderer: Renderer2;

  iterableDiffer: IterableDiffer<any>;

  constructor(private _iterableDiffers: IterableDiffers) {
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
  }

  ngOnInit() {
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
  }

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.items);
    if (changes) {
      this.clonePgRng();

      let selfObj: UserlistComponent = this;
      $(setTimeout(function () {
        selfObj.multiLineCheckUncheck(true);
      }, 300));
    }
  }

  clonePgRng() {
    $('#dupPgRng').empty();
    var $target = $('.container').find('.pagination-range');
    $target.clone().first().appendTo('#dupPgRng');
  }

  @Output()
  generateEvent = new EventEmitter();
  Generate(userIds) {
    this.generateEvent.emit(userIds);
  }

  @Output()
  outPropsEvent: EventEmitter<OutputPropsUserList> = new EventEmitter<OutputPropsUserList>();
  sendOutProps(outProps) {
    this.outPropsEvent.emit(outProps);
  }

  onItemSelect(item: any) {
    
    if (this.parentComp == ParentComp.Contacts)
      this.onItemSelectContacts(item);
    else if (this.parentComp == ParentComp.LeadsDash)
      this.onItemSelectLeadsDash(item);
    else if (this.parentComp == ParentComp.ConfigurationDashBoard)
      this.onItemSelectConfiguredDashBoard(item);
    else if (this.parentComp == ParentComp.TaskListDash)
      this.onItemSelectTaskListDashBoard(item);
    else if (this.parentComp == ParentComp.Customer)
      this.onItemSelectCustomer(item);
    else if (this.parentComp == ParentComp.Account)
      this.onItemSelectAccount(item);
    else
      this.onItemSelectOpportunitiesDash(item);


    this.assignOutPropsAndEmitEvent();
  }
  OnItemDeSelect(item: any) {
    if (this.parentComp == ParentComp.Contacts)
      this.OnItemDeSelectContacts(item);
    else if (this.parentComp == ParentComp.LeadsDash)
      this.OnItemDeSelectLeadsDash(item);
    else if (this.parentComp == ParentComp.ConfigurationDashBoard)
      this.OnItemDeSelectLeadsDash(item);
    else if (this.parentComp == ParentComp.TaskListDash)
      this.OnItemDeSelectTaskListDashBoard(item);
    else if (this.parentComp == ParentComp.Customer)
      this.OnItemDeSelectCustomer(item);
    else if (this.parentComp == ParentComp.Account)
      this.OnItemDeSelectAccount(item);
    else
      this.OnItemDeSelectOpportunitiesDash(item);

    this.assignOutPropsAndEmitEvent();
  }
  onSelectAll(items: any) {
    if (this.parentComp == ParentComp.Contacts)
      this.onSelectAllContacts(items);
    else if (this.parentComp == ParentComp.LeadsDash)
      this.onSelectAllLeadsDash(items);
    else if (this.parentComp == ParentComp.ConfigurationDashBoard)
      this.onSelectAllLeadsDash(items);
    else if (this.parentComp == ParentComp.TaskListDash)
      this.onSelectAllTaskListDashBoard(items);
    else if (this.parentComp == ParentComp.Customer)
      this.onSelectAllCustomer(items);
    else if (this.parentComp == ParentComp.Account)
      this.onSelectAllAccount(items);
    else
      this.onSelectAllOpportunitiesDash(items);

    this.assignOutPropsAndEmitEvent();
  }
  onDeSelectAll(items: any) {
    if (this.parentComp == ParentComp.Contacts)
      this.onDeSelectAllContacts(items);
    else if (this.parentComp == ParentComp.LeadsDash)
      this.onDeSelectAllLeadsDash(items);
    else if (this.parentComp == ParentComp.ConfigurationDashBoard)
      this.onDeSelectAllLeadsDash(items);
    else if (this.parentComp == ParentComp.TaskListDash)
      this.onDeSelectAllTaskListDashBoard(items);
    else if (this.parentComp == ParentComp.Customer)
      this.onDeSelectAllCustomer(items);
    else if (this.parentComp == ParentComp.Account)
      this.onDeSelectAllAccount(items);
    else
      this.onDeSelectAllOpportunitiesDash(items);

    this.assignOutPropsAndEmitEvent();
  }

  assignOutPropsAndEmitEvent() {
    let outProps: OutputPropsUserList = new OutputPropsUserList();

    outProps.finalitems = this.finalitems;
    //outProps.items = this.items;
    //outProps.itemsCount = this.itemsCount;
    //outProps.selectedItems = this.selectedItems;

    //let selfObj: UserlistComponent = this;
    //$(setTimeout(function () {
    //  selfObj.multiLineCheckUncheckOnUserListChange();
    //}, 300));

    this.sendOutProps(outProps);
  }
  multiLineCheckUncheck(isPaginationChanged: boolean) {
    
    let hdr = this.elementRef.nativeElement.querySelectorAll('.column-header');
    for (var n = 0; n < hdr.length; n++) {
      this.renderer.addClass(hdr[n], 'tmpMultiLineHdr');
      this.renderer.removeClass(hdr[n], 'column-header');
    }

    if (this.isMultiLine) {
      if (isPaginationChanged) {
        this.isMultiLine = !this.isMultiLine;
        this.multiLineCheckUncheck(false);
        this.isMultiLine = !this.isMultiLine;
      }
      else {    //make the view single lined
        let tbl = this.elementRef.nativeElement.querySelector('.tmpMultiLineTbl');
        this.renderer.addClass(tbl, 'table');
        this.renderer.removeClass(tbl, 'tmpMultiLineTbl');
        let clmn = this.elementRef.nativeElement.querySelectorAll('.tmpMultiLineClmn');
        for (var n = 0; n < clmn.length; n++) {
          this.renderer.addClass(clmn[n], 'data-column');
          this.renderer.removeClass(clmn[n], 'tmpMultiLineClmn');
        }
      }
    } else {
      if (!isPaginationChanged) {   //make the view multi lined
        let tbl = this.elementRef.nativeElement.querySelector('table');
        this.renderer.addClass(tbl, 'tmpMultiLineTbl');
        this.renderer.removeClass(tbl, 'table');

        let clmn = this.elementRef.nativeElement.querySelectorAll('.data-column');
        for (var n = 0; n < clmn.length; n++) {
          this.renderer.addClass(clmn[n], 'tmpMultiLineClmn');
          this.renderer.removeClass(clmn[n], 'data-column');
        }

        //let dtBox = this.elementRef.nativeElement.querySelector('.data-table-box');
        //this.renderer.addClass(dtBox, 'tmpMultiLinedtBox');
        //this.renderer.removeClass(dtBox, 'data-table-box');
      }
    }
  }
  //multiLineCheckUncheckOnUserListChange() {
  //  
  //  let hdr = this.elementRef.nativeElement.querySelectorAll('.column-header');
  //  for (var n = 0; n < hdr.length; n++) {
  //    this.renderer.addClass(hdr[n], 'tmpMultiLineHdr');
  //    this.renderer.removeClass(hdr[n], 'column-header');
  //  }

  //  if (this.isMultiLine) {   //make the grid multi line
  //    let tbl = this.elementRef.nativeElement.querySelector('table');
  //    this.renderer.addClass(tbl, 'tmpMultiLineTbl');
  //    this.renderer.removeClass(tbl, 'table');

  //    let clmn = this.elementRef.nativeElement.querySelectorAll('.data-column');
  //    for (var n = 0; n < clmn.length; n++) {
  //      this.renderer.addClass(clmn[n], 'tmpMultiLineClmn');
  //      this.renderer.removeClass(clmn[n], 'data-column');
  //    }
  //  }
  //  //else {    //make the grid multi line
  //  //  let tbl = this.elementRef.nativeElement.querySelector('.tmpMultiLineTbl');
  //  //  if (tbl == undefined) {
  //  //    this.isMultiLine = !this.isMultiLine;
  //  //    this.multiLineCheckUncheckOnUserListChange();
  //  //    this.isMultiLine = !this.isMultiLine;
  //  //  } else {
  //  //    this.renderer.addClass(tbl, 'table');
  //  //    this.renderer.removeClass(tbl, 'tmpMultiLineTbl');

  //  //    let clmn = this.elementRef.nativeElement.querySelectorAll('.tmpMultiLineClmn');
  //  //    for (var n = 0; n < clmn.length; n++) {
  //  //      this.renderer.addClass(clmn[n], 'data-column');
  //  //      this.renderer.removeClass(clmn[n], 'tmpMultiLineClmn');
  //  //    }
  //  //  }
  //  //}
  //}


  //Contacts

  onItemSelectContacts(item: any) {
    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  OnItemDeSelectContacts(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.Generate($("#userids").val());
    } else {
      this.items = [];
      this.itemsCount = 0;
      $("#userids").val('');
    }
  }
  onSelectAllContacts(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  onDeSelectAllContacts(items: any) {
    this.finalitems = "";
    this.items = [];
    this.itemsCount = 0;
    $("#userids").val('');
  }


  //LeadsDash
  onItemSelectLeadsDash(item: any) {
    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  OnItemDeSelectLeadsDash(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.Generate($("#userids").val());
    } else {
      this.items = [];
      this.itemsCount = 0;
      $("#userids").val('');
    }
  }
  onSelectAllLeadsDash(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  onDeSelectAllLeadsDash(items: any) {
    this.finalitems = "";
    this.items = [];
    this.itemsCount = 0;
    $("#userids").val('');
  }


  //OpportunitiesDash
  onItemSelectOpportunitiesDash(item: any) {
    var ids = "";
    ids += item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  OnItemDeSelectOpportunitiesDash(item: any) {
    var ids = "";
    ids = item.id;
    var result = '';
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.Generate($("#userids").val());
    } else {
      this.items = [];
      this.itemsCount = 0;
    }
  }
  onSelectAllOpportunitiesDash(items: any) {
    var ids = "";
    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
  }
  onDeSelectAllOpportunitiesDash(items: any) {
    this.finalitems = "";
    this.items = [];
    this.itemsCount = 0;
    $("#userids").val('');
  }


  //ConfiguredDashBoard
  onItemSelectConfiguredDashBoard(item: any) {
    var ids = "";
    ids += item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  OnItemDeSelectConfiguredDashBoard(item: any) {
    var ids = "";
    ids = item.id;
    var result = '';
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.Generate($("#userids").val());
    } else {
      this.items = [];
      this.itemsCount = 0;
    }
  }
  onSelectAllConfiguredDashBoard(items: any) {
    var ids = "";
    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
  }
  onDeSelectAllConfiguredDashBoard(items: any) {
    this.finalitems = "";
    this.items = [];
    this.itemsCount = 0;
    $("#userids").val('');
  }

  //TaskListDashBoard
  onItemSelectTaskListDashBoard(item: any) {
    var ids = "";
    ids += item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  OnItemDeSelectTaskListDashBoard(item: any) {
    var ids = "";
    ids = item.id;
    var result = '';
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.Generate($("#userids").val());
    } else {
      this.items = [];
      this.itemsCount = 0;
    }
  }
  onSelectAllTaskListDashBoard(items: any) {
    var ids = "";
    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
  }
  onDeSelectAllTaskListDashBoard(items: any) {
    this.finalitems = "";
    this.items = [];
    this.itemsCount = 0;
    $("#userids").val('');
  }


  //Customer
  onItemSelectCustomer(item: any) {
    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($('#userids').val());
    //this.loadallcustomers(this.type, this.term);
  }
  OnItemDeSelectCustomer(item: any) {
    
    var ids = item.id;
    var result = "";
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    } else {
      this.items = [];
      this.itemsCount = 0;
      $("#userids").val('');
    }
    //this.loadallcustomers(this.type, this.term);
    this.Generate($('#userids').val());
  }
  onSelectAllCustomer(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    //this.loadallcustomers(this.type, this.term);
    this.Generate($('#userids').val());
  }
  onDeSelectAllCustomer(items: any) {
    this.finalitems = "";
    this.items = [];
    this.itemsCount = 0;
    $("#userids").val('');
    //this.loadallcustomers(this.type, this.term);
    this.Generate($('#userids').val());
  }

  //Account
  onItemSelectAccount(item: any) {
    var ids = item.id;
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  OnItemDeSelectAccount(item: any) {
    
    var ids = item.id;
    var result = "";
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    } else {
      this.items = [];
      this.itemsCount = 0;
      $("#userids").val('');
    }
    this.Generate($("#userids").val());
  }
  onSelectAllAccount(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  onDeSelectAllAccount(items: any) {
    this.finalitems = "";
    this.items = [];
    this.itemsCount = 0;
    $("#userids").val('');
    this.Generate($("#userids").val());
  }
}
