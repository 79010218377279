import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef, ViewEncapsulation, Pipe, Optional, PipeTransform, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsService } from './../../services/accounts.service';
import { Contacts } from '../../models/contacts';
import { Accounts } from '../../models/accounts';
import { Calllist } from '../../models/calllist';
import { Viewcalllist } from '../../models/viewcalllist';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { EditdeletecvComponent } from '../editdeletecv/editdeletecv.component';
import { EditdeletecvcheckComponent } from '../editdeletecvcheck/editdeletecvcheck.component';
import { NotesrendererComponent } from '../notesrenderer/notesrenderer.component';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { CustomerService } from '../../services/customer.service';
import { EditcontactComponent } from '../editcontact/editcontact.component';
import { UserService } from '../../services/user.service';
import { Notes } from '../../models/notes';
import { DatePipe, TitleCasePipe, Location } from '@angular/common';
import { Dropdowntype, Dropdown } from './../../models/dropdowntype';
import { ToastrService } from 'ngx-toastr';
import { Column } from 'ag-grid';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { ApplicationComponent } from '../application.component';
import { OwnershipsharingComponent } from '../ownershipsharing/ownershipsharing.component';
import { StatusIdsComponent } from '../status-ids/status-ids.component';
import { NotesComponent } from '../notes/notes.component';
import { Viewaccount } from '../../models/viewaccount';
import { ContactsdashboardComponent } from '../contacts/contactsdashboard/contactsdashboard.component';
import { AccountContactComponent } from '../account-contact/account-contact.component';
declare var require: any;
declare var $: any;
@Component({
  selector: 'app-calllist',
  templateUrl: './calllist.component.html',
  styleUrls: ['./calllist.component.css']
})
export class CalllistComponent implements OnInit {
  currentuser: any;
  allContacts: Contacts[];
  public gridOptions: GridOptions;
  public gridOptionsViewcalllist: GridOptions;
  public gridOptionsnotes: GridOptions;
  public gridOptionsProvider: GridOptions;
  public context;
  isShowDetailsChecked: boolean = true;
  //@Input()
  contactids: string;
  //@Input()
  calllistid: number;
 // @Input()
  tab: string;
  userInfo: string;
  action: string;
  Years: any[];
  MedSchoolYears: any[];
  ResidencyYears: any[];
  FellowShipYears: any[];
  AccountIdsDialog: boolean = false;
  showAddEditAccount: boolean = false;
  showAddEditContact: boolean = false;
  isSharing: boolean = false;
  Collegelist: Dropdowntype[] = [];
  contacttypelist: Dropdowntype[] = [];
  SexList: Dropdowntype[] = [];
  TitleList: Dropdowntype[] = [];
  SuffixList: Dropdowntype[] = [];
  Specialitytypelist: Dropdowntype[] = [];
  MonthList: Dropdowntype[] = [];
  DayList: Dropdowntype[] = [];
  public stateInfo = [];
  MedicalSchoolList: Dropdowntype[] = [];
  StatesList: Dropdowntype[] = [];
  CitiesList: Dropdowntype[] = [];
  OwnerList: Dropdowntype[] = [];
  allCustomers: Accounts[];
  public frameworkComponents;
  public frameworkComponentsAccounts;
  public frameworkComponentsProvider;
  public columnDefs: any[];
  public AccountcolumnDefs: any[];
  public columnDefsProvider: any[];
  public columnDefscalllist: any[];
  public columnDefsnotes: any[];
  accViewHGT = screen.height * 0.57;
  itemResource: any;
  contactsids: any;
  itemCount = 0;
  stateTextBox: boolean = true;
  stateDropDown: boolean = false;
  displayZipCode: boolean = true;
  displayCountryName: boolean = false;
  showcancelButtonDiv: boolean = true;
  id = 0;
  public zipmask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public dobmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  editContHGT = (screen.height * 0.39444); //0.3944
  ContactsprovidersitemCount = 0;
  showDialog = false;
  showCancelDialog = false;
  showContactDialog = false;
  showContactOpenDialog = false;
  ConverttoCVshowDialog = false;
  assigncalllistDialog = false;
  notesshowDialog=false;
  dropdownList = [];
  callListGridInitialized: boolean = false;
  account: Accounts = new Accounts();
  contacts: Contacts = new Contacts();
  viewcallist: Viewcalllist = new Viewcalllist();
  viewaccount: Viewaccount = new Viewaccount();
  calllist: Calllist = new Calllist();
  allContactsproviders: Contacts[];
  notedescription: string;
  taskDialog: boolean = false;
  aggridheight: string;
  aggridheightp: string;
  selectedcontactid = 0;
  selectedstatus = "Open";
  contactid = 0; leadstatus = 0;
  contactsExpanded: boolean = false;
  statusIds: boolean = false;
  deleteId: number = 0; type = '';
  showDeleteDialog: boolean = false;
  contactshowDialog: boolean = false;
  @ViewChild(EditcontactComponent) editcontactchild;
  @ViewChild(OwnershipsharingComponent) ownrshipComp: OwnershipsharingComponent;
  @ViewChild(StatusIdsComponent) statusidcmp: StatusIdsComponent;
  @ViewChild(ContactsdashboardComponent) accountContactInfo: ContactsdashboardComponent;
  
  @ViewChild(AccountContactComponent) acccontactcomp;
  allnotes: Notes[];
  isModal: boolean;  
  public getRowHeight;
  datePipe: DatePipe = new DatePipe("en-US"); titleCasePipe: TitleCasePipe = new TitleCasePipe();
  notes: Notes = new Notes();
  msg = '';
  dropdownSetting = {};
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  assignuserid: number; conid: number; NotesDec = false;
  accountid: number = 0;
  typ: string; selectedItems = [];
  divHeight = screen.height * 0.3444;
  gridHeight = screen.height * 0.57444;
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  timezonevalue: string;
  public elementRef;
  AccountIdArr: Dropdowntype[] = [];
  contactIdArr: Dropdowntype[] = [];
  public sideBar;
  @ViewChild(NotesComponent) notecomp;
  @ViewChild(AccountContactComponent) accComp;
  DeleteConfirmationPopup: boolean = false;
  deleteRowData: any;
  contactstatus: number;
  noteslist: Array<Notes> = [];
  userprevilages: any;
  isCalllistOpen: boolean = true;
  ShowButtons: boolean = false;
  @ViewChild('CallListcontainer') menu: ElementRef;
  constructor(private _location: Location,private route: ActivatedRoute, public applicationComponent: ApplicationComponent, private opprtunityservice: OpprtunitiesService, private userservice: UserService, private router: Router, private viewContainerRef: ViewContainerRef, private accountsService: AccountsService, private customerService: CustomerService, private toastr: ToastrService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }
  ngAfterViewInit(): void {
    this.ResizeTable();
    this.ResizeTableP();
  }
  close() { this._location.back(); }
  ngOnInit() { 
    this.route.params.subscribe(params => {
      this.calllist.contactids = params['contactids'];
      this.calllist.id = params['calllistid'];
      this.initializeAgGrid();
    if (this.calllist.id != 0) {
      
        this.customerService.GetByCalllistById(this.calllist.id).then(result => {
          this.calllist = result;
          this.calllist.contactids.concat(this.calllist.completedcontactids);
          this.applicationComponent.Calllistselectedtype = this.calllist.typeOfList;
          if (this.calllist.Assignedto != null) {
            this.selectedItems = [
              { "id": this.calllist.Assignedto, "itemName": this.calllist.assignedusername }
            ];
          } else {
            this.selectedItems = [
              { "id": this.calllist.userid, "itemName": this.calllist.createdusername }
            ];
          }
          if (this.calllist.userid == this.currentuser.id) {
            //document.getElementById('saveButtonDiv').style.display = 'block';
          } else {
            //document.getElementById('saveButtonDiv').style.display = 'none';
          }
          this.showcancelButtonDiv = false;
        
          if (this.applicationComponent.Calllistselectedtype == 'Contact' || this.applicationComponent.Calllistselectedtype =='CustomerContact') {
            this.GetAllSelectedContactCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, 'Contacts');
          } else if (this.applicationComponent.Calllistselectedtype == 'Customer' || this.applicationComponent.Calllistselectedtype == 'Customers' || this.applicationComponent.Calllistselectedtype == 'Contacts/Accounts') {
            this.GetAllSelectedAccountCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, this.applicationComponent.Calllistselectedtype);
          }
        });
      } else {
        $('#saveButtonDiv').attr('style', 'display:block');
        this.showcancelButtonDiv = true;
        if (this.calllist.contactids != '0') {
                
            if (this.applicationComponent.Calllistselectedtype == 'Contact') {
            
              this.GetAllSelectedContactCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, 'Contacts');
            } else if (this.applicationComponent.Calllistselectedtype == 'Customer' || this.applicationComponent.Calllistselectedtype =='Customers') {
              this.GetAllSelectedAccountCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, this.applicationComponent.Calllistselectedtype);
            }
        }
        else {
          this.GetAllSelectedContactCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, 'Contacts');
        }
      }
    });
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1, 0, 0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.dropdownList = Owner;
    });
    this.dropdownSetting = {
      singleSelection: true,
      text: "Select User",
      enableSearchFilter: true
    
    };   
    this.customerService.GetSpecialityList("AccountIds", this.currentuser.id).then((Acids) => {
      this.AccountIdArr = Acids;
    });
    this.customerService.GetSpecialityList("ContactIds", this.currentuser.id).then((Acids) => {
      this.contactIdArr = Acids;
    });
    this.customerService.GetPrivilagesByuser(this.currentuser.id).then(result => {
      this.userprevilages = result;
    });
  
    this.applicationComponent.pageViewed = "CreateCallList";
  
  }
  OpenCancelDialog() {
    this.showCancelDialog = true;
    this.applicationComponent.salesxcrmDialog('#cancelDialog .dialog');   
  }
  CloseCalllist() {
    this.applicationComponent.CallListDialog = false;
    this.applicationComponent.pageViewed = '';
  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }
  Notes(val) {
    this.GetCustomerNotes(this.contactid, val);
  }  
  openNotesDialogAg(rowdata) {
     
    this.conid = rowdata.id;
    this.typ = rowdata.type;
    $('#accotIdNotes').val(rowdata.id);
    $('#accotTypeNotes').val('Contact');
    this.contactid = rowdata.id;
    this.customerService.GetContactById(rowdata.id).then(result => {
      this.contacts = result;
      this.customerService.GetByAccountId(this.contacts.accountid).then(result => {
        this.account = result;

      });
    });

     
    if ((document.getElementById('agCallListContacts').className == 'col-xs-6') || ((document.getElementById('agCallListContacts').className == 'removePadding col-xs-6'))) {
      //$(setTimeout(function () {
      //  $("#divNotesContainer").css('height', '400px');
      //}, 100));
      this.isModal = false;      
      document.getElementById('notesinfo').style.display = 'block'
       $('#chkShowDetailsNotes').prop('checked', false);
      document.getElementById('providerDetails').style.display = 'none'
      document.getElementById('accountInformation').style.display = 'none'
    }
    if (document.getElementById('agCallListContacts').className == 'col-xs-12') {
      
      this.isModal = true;
      this.contactid = rowdata.id;
      $('#accotIdNotes').val(rowdata.id);
      $('#accotTypeNotes').val('Contact');

      this.contactid = this.contactid;
      this.notesshowDialog = !this.notesshowDialog;
      this.NotesDialogCss();
      $(setTimeout(function () {
        if ($('#oppNotesId').length > 0)
          $('#oppNotesId').removeClass('active');

        if ($('#tskHistNotesId').length > 0)
          $('#tskHistNotesId').removeClass('active');

        if ($('#leadsNotesId').length > 0)
          $('#leadsNotesId').removeClass('active');

        if ($('#allContactNotesId').length > 0)
          $('#allContactNotesId').removeClass('active');

        if ($('#allNotesId').length > 0) {
          $('#allNotesId').removeClass('active');
          $('#allNotesId').addClass('active');
        }
        $('#chkShowDetailsNotes').prop('checked', false);
      }, 600));
     var modalWidth = (screen.width) - ((screen.width) / 10);
     //$('.dialog').attr('style', 'padding:1%;border: 3px solid green;width:' + modalWidth + 'px');

    }
    this.GetCustomerNotes(rowdata.id.toString(), "Contact");
  }
  ContactId1Change(val) {
    if (val === "null") {
      this.contacts.contactid2 = "null";
      this.contacts.contactid3 = "null";
    }
    else if ((this.contacts.contactid1 == this.contacts.contactid2) || (this.contacts.contactid1 == this.contacts.contactid3)) {
      alert("Please select Other Option already Selected Value Added .")
      this.contacts.contactid1 = "null";
    }
  }
  ContactId2Change(val) {
    if (val === "null") {
      this.contacts.contactid3 = "null";
    }
    else if ((this.contacts.contactid2 == this.contacts.contactid1) || (this.contacts.contactid2 == this.contacts.contactid3)) {
      alert("Please select Other Option already Selected Value Added .")
      this.contacts.contactid2 = "null";
    }

  }
  ContactId3Change(val) {
    if ((this.contacts.contactid3 == this.contacts.contactid2) || (this.contacts.contactid3 == this.contacts.contactid1)) {
      alert("Please select Other Option already Selected Value Added .")
      this.contacts.contactid3 = "null";
    }
  }
  GetCustomerNotes(contactid, type) {
    this.customerService.GetAllNotesList(this.currentuser.id,contactid, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.initializeAgGridNotes();
      this.allnotes = taskhistory;
      this.noteslist = taskhistory;
    });

  }

  titleSelected() {
    if ($('#title').hasClass('not-required')) {
      $('#title').removeClass('not-required')
      $('#title').addClass('required')

    }
  }

  collegeyear(val) {
    var range = [];
    var y = Number(val);
    var year = new Date().getFullYear();
    for (var i = 0; y <= year; i++) {
      y = y + 1;
      range.push({
        label: y,
        value: y
      });
    }
    this.MedSchoolYears = range;
  }
  medschoolyear(val) {
    var range = [];
    var y = Number(val);
    var year = new Date().getFullYear();
    for (var i = 0; y <= year; i++) {
      y = y + 1;
      range.push({
        label: y,
        value: y
      });
    }
    this.ResidencyYears = range;
  }
  residencyyear(val) {
    var range = [];
    var y = Number(val);
    var year = new Date().getFullYear();
    for (var i = 0; y <= year; i++) {
      y = y + 1;
      range.push({
        label: y,
        value: y
      });
    }
    this.FellowShipYears = range;
  }
  loadcontacttitles(val) {
    this.customerService.GetTitleListbycontacttype(val).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.TitleList = accounttype;
      this.contacts.title = null;
      if (val == 'Physician' || val == 'DPM' || val == 'NP' || val == 'PT' || val == 'PA') {
        $('#title').removeClass('required');
        $('#title').addClass('not-required');
        document.getElementById('titlespan').style.display = 'block';

      } else {
        $("#title").prop("required", false)
        $('#title').removeClass('not-required');
        $('#title').removeClass('required');
        document.getElementById('titlespan').style.display = 'none';

      }
    });

  }

  //close() {
     
  //  document.getElementById('notesinfo').style.display = 'none'
  //  document.getElementById('providerDetails').style.display = 'block'
  //  document.getElementById('accountInformation').style.display = 'block'
  //}
  initializeAgGridNotes() {
     
    this.gridOptionsnotes = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.createColumnDefsnotes();

    this.context = this;
  }
  createColumnDefsnotes() {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },tooltipField: 'notedescription', hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: false, cellStyle: { 'text-align': 'left' },
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];
   
    this.getRowHeight = function (params) {
      return 18 * (Math.floor(params.data.notedescription.length / 45) + 1);
    };
  }

  onAgGridReadynotes(event) {
     
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllnotes();
      $(setTimeout(function () {
        selfObj.autoSizeAllnotes();
      }, 1000));
    }, 1000));
  }
  autoSizeAllnotes() {
     
    var allColumnIds = [];
    if (this.gridOptionsnotes && this.gridOptionsnotes.columnApi) {
      var allCols = this.gridOptionsnotes.columnApi.getAllColumns();
      if (allCols != null) {
        allCols.forEach(function (column) {
          allColumnIds.push(column.getColId());
        });
      }
      this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds);
    }

  }
  GetAllProviderAndContactInfo(accountid) {
     
    this.customerService.GetAllContactproviderByAccount(accountid, this.currentuser.id).then((contacts) => {
      contacts.forEach(contacts => {
        contacts.created = this.datePipe.transform(contacts.created, "MM-dd-yyyy");
      });
      this.allContactsproviders = contacts;
      this.ContactsprovidersitemCount = contacts.length;     
    });

  }

  public GetAllSelectedContactCalllist(contactsids, completedcontactids, deletedcontactids, type) {     
    this.viewcallist.term = null;
    this.viewcallist.contactids = (contactsids == "") ? null : contactsids;
    this.viewcallist.completedcontactids = (completedcontactids == "") ? null : completedcontactids;
    this.viewcallist.deletedcontacts = (deletedcontactids == "") ? null : deletedcontactids;
    this.viewcallist.currentuserid = this.currentuser.id;
    this.accountsService.GetAllSelectedContactCalllist(this.viewcallist).then((Contacts) => {
      Contacts.forEach(Contacts => {
        Contacts.created = this.datePipe.transform(Contacts.created, "MM-dd-yyyy");
      });
      this.allContacts = Contacts;
      this.calllist.contactids = contactsids;
      if (completedcontactids != null && completedcontactids != '') {
        this.calllist.contactids.concat(completedcontactids);
      }
      this.contactid = ((this.allContacts.length == 0) ? 0 : this.allContacts[0].id);
      this.itemCount = Contacts.length;
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.gridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.contactid) {
            selfObj.gridOptions.api.selectNode(node, true);
            selfObj.contactid = selfObj.contactid;
            selfObj.applicationComponent.taskselectedtype == 'Contact';
          }
        });
      }, 500));
      this.customerService.GetByAccountId(((this.allContacts.length == 0) ? 0 : this.allContacts[0].accountid)).then(result => {
        this.account = result;
        //this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.country, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata);
        this.GetCustomerNotes(this.account.id,'Customer');
       this.GetAllProviderAndContactInfo((this.account ? this.account.id : 0));
      });

    });
    if (this.calllist.contactids == "") {       
      $(setTimeout(function () {
        $("#callstatus").val("Completed");   
      }, 200));
    }
  }
  public GetAllSelectedAccountCalllist(contactsids, completedcontactids, deletedcontactids, type) {
    this.viewcallist.term = null;
    this.viewcallist.contactids = (contactsids == "") ? null : contactsids;
    this.viewcallist.completedcontactids = (completedcontactids == "") ? null : completedcontactids;
    this.viewcallist.deletedcontacts = (deletedcontactids == "") ? null : deletedcontactids;
    this.viewcallist.currentuserid = this.currentuser.id;
    this.viewcallist.isCustomertypelist = type;
    this.accountsService.GetAllSelectedAccountCalllist(this.viewcallist).then((Contacts) => {
     
      this.allCustomers = Contacts;
      this.calllist.contactids = contactsids;
      if (completedcontactids != null && completedcontactids != '') {
        this.calllist.contactids.concat(completedcontactids);
      }
      this.contactid = ((this.allCustomers.length == 0) ? 0 : this.allCustomers[0].id);
      this.itemCount = Contacts.length;
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.gridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.contactid) {
            selfObj.gridOptions.api.selectNode(node, true);
            selfObj.applicationComponent.taskselectedtype == 'Customer';
          
          }
        });
      }, 500));
      this.customerService.GetByAccountId(((this.allCustomers.length == 0) ? 0 : this.allCustomers[0].id)).then(result => {
        this.account = result;     
        this.GetCustomerNotes(this.account.id, 'Customer');
       this.GetAllProviderAndContactInfo((this.account ? this.account.id : 0));
      });

    });




    if (this.calllist.contactids == "") {
       
      $(setTimeout(function () {
        $("#callstatus").val("Completed");   
      }, 200));

      
     
    }
  }

  initializeAgGrid() {
     
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.gridOptionsProvider = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    if (this.applicationComponent.Calllistselectedtype == "Customer") {
      this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 25).then((accconfig) => {
      
          columnSelections = accconfig != null && accconfig.columnsselections != null ? accconfig.columnsselections : "";
          this.createAccountColumnDefs(columnSelections);
       
      });
     
    } else {
      this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 31).then((config) => {
       
        columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
        this.createColumnDefs(columnSelections);
      });

    }
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 36).then((config) => {

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefsProvider(columnSelections);
    });
    this.gridOptionsProvider.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponentsAccounts = {
      notesrenderer: NotesrendererComponent,
      //editdeletecvcheck: EditdeletecvComponent
      editdeletecvcheck: EditdeletecvcheckComponent
    };
    
      this.frameworkComponents = {
        notesrenderer: NotesrendererComponent,
        editdeletecvcheck: EditdeletecvcheckComponent
      };
      this.frameworkComponentsProvider = {
        notesrenderer: NotesrendererComponent,
        editdeletecv: EditdeletecvComponent
      };

  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }

  AccountcolumnVisible(event) {
    this.AccountsaveConfiguration();
  }
  AccountdragStopped(event) {
    this.AccountsaveConfiguration();
  }
  AccountcolumnResized(event) {
    if (event.finished == true)
      this.AccountsaveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration(); 
    config.maxlimit = 25;    
    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 31;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }

  AccountsaveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();
    config.maxlimit = 25;
    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 25;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "actions") {
          this.columnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecvcheck', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) , pinned: 'right', });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "calllistcontactstatus") {
          this.columnDefs.push({
            field: 'calllistcontactstatus', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellStyle: function (params) {
              if (params.value == 'Open' || params.value == 'Deleted') { return { color: 'red' }; }
             // else if (params.value == 'Deleted') { return { backgroundColor: 'red' } }
            }
          });
        } else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        {
          width: 100, hide: false, outerWidth: 8, headerName: 'CC', cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = "<input type='checkbox'" + (params.data.calllistcontactstatus == 'Completed' ? "checked='checked'" : "") + ">";
            eDiv.getElementsByTagName("input")[0].addEventListener('click', function (event) {
              selfObj.callcheck(params.data);
            });
            return eDiv;
          }
        },
        { field: 'lastname', headerName: 'Last Name', hide: false },
        { field: 'firstname', headerName: 'First Name', hide: false },
        { field: 'middlename', headerName: 'Middle Name', hide: false },
        //{ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecvcheck', hide: false, pinned: 'right' },
        { field: 'actions', headerName: 'Actions', hide: false, pinned: 'right', cellRenderer: 'editdeletecvcheck' },
        {
          field: 'calllistcontactstatus', headerName: 'Status', hide: false,
          cellStyle: function (params) {
            if (params.value == 'Open' || params.value == 'Deleted') { return { color: 'red' }; }
           }
        },
        { field: 'titleName', headerName: 'Title', hide: false },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        { field: 'DOB', headerName: 'DOB', hide: false },
        { field: 'accountid', headerName: 'accountid', hide: false},
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        { field: 'zip', headerName: 'ZIP', hide: false },
        { field: 'accountname', headerName: 'Account Name', hide: false },
        { field: 'contacttype', headerName: 'Contact Type', hide: true },  //true starts here
        { field: 'suffix', headerName: 'Suffix', hide: true },
        { field: 'sex', headerName: 'Sex', hide: true },
        { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
        { field: 'email', headerName: 'Email', hide: true },
        { field: 'address1', headerName: 'Address1', hide: true },
        { field: 'address2', headerName: 'Address2', hide: true },
        { field: 'city', headerName: 'City', hide: true },
        { field: 'state', headerName: 'State', hide: true },
        { field: 'cell', headerName: 'Cell', hide: true },
        { field: 'office', headerName: 'Office', hide: true },
        { field: 'homeaddress', headerName: 'Home Address', hide: true },
        { field: 'homecity', headerName: 'Home City', hide: true },
        { field: 'homestate', headerName: 'Home State', hide: true },
        { field: 'homezip', headerName: 'Home Zip', hide: true },
        { field: 'home', headerName: 'Home', hide: true },
        { field: 'college', headerName: 'College', hide: true },
        { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
        { field: 'medschool', headerName: 'Medschool', hide: true },
        { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
        { field: 'residency', headerName: 'Residency', hide: true },
        { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
        { field: 'fellowship', headerName: 'Fellowship', hide: true },
        { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
        { field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true },
        { field: 'created', headerName: 'Created', hide: true }
      ];
    }
  }
  createAccountColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      this.AccountcolumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleArray[0] == "#") {
          this.AccountcolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleArray[0] == "0") {
          this.AccountcolumnDefs.push({
            width: 50, hide: false, outerWidth: 8, headerName: 'CC', cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = "<input type='checkbox'" + (params.data.calllistcontactstatus == 'Completed' ? "checked='checked'" : "") + ">";
              eDiv.getElementsByTagName("input")[0].addEventListener('click', function (event) {
                selfObj.callcheck(params.data);
              });
              return eDiv;
            }
          });
        }
        else if (fieldHeaderVisibleArray[0] == "customertypeid") {
          this.AccountcolumnDefs.push({
            field: 'customertypeid', headerName: 'Customer Type', hide: true,
            cellRenderer(params) { if (params.value == '0') { return "Non - Customer" } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" } }, width: parseInt(fieldHeaderVisibleArray[3])
          });
        } else if (fieldHeaderVisibleArray[0] == "accountname") {
          this.AccountcolumnDefs.push({
            field: 'accountname', headerName: 'Account Name', hide: false, });
        }
        else if (fieldHeaderVisibleArray[0] == "accnumber") {
          this.AccountcolumnDefs.push({
            field: 'accnumber', headerName: 'Act Number', hide: true
          });
        }
        else if (fieldHeaderVisibleArray[0] == "actions") {
          this.AccountcolumnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecvcheck', hide: fieldHeaderVisibleArray[2] == "true" ? false : true, pinned: 'right', width: parseInt(fieldHeaderVisibleArray[3]) });
        }
        else if (fieldHeaderVisibleArray[0] == "notes") {
          this.AccountcolumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleArray[3]) });

        } else {
          this.AccountcolumnDefs.push({
            field: fieldHeaderVisibleArray[0],
            headerName: fieldHeaderVisibleArray[1],
            hide: fieldHeaderVisibleArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
      }
    }
    else {
      this.AccountcolumnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'id', headerName: 'Id', hide: true },
        {
          field: 'customertypeid', headerName: 'Customer Type', hide: true,
          cellRenderer(params) {
            if (params.value == '0') { return "Non - Customer" } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
          }
        },
        {
          width: 100, hide: false, outerWidth: 8, headerName: 'CC', cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = "<input name='" + params.data.id+"' type='checkbox'" + (params.data.calllistcontactstatus == 'Completed' ? "checked='checked'" : "") + ">";
            eDiv.getElementsByTagName("input")[0].addEventListener('click', function (event) {
              selfObj.callcheck(params.data);              
            });
            return eDiv;
          }},
        { field: 'IsContact', headerName: ' Is Contact?', hide: true },
        {
          field: 'accountname', headerName: 'Account Name', hide: false,
        },
        {
          field: 'accountname', headerName: 'Account Name', hide: false, 
        },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        { field: 'address1', headerName: 'Address1', hide: false },
        { field: 'address2', headerName: 'Address2', hide: false },
        { field: 'city', headerName: 'City', hide: false },
        { field: 'state', headerName: 'State', hide: false },
        { field: 'zip', headerName: 'Zip', hide: false },
        { field: 'phone', headerName: 'Phone', hide: true },
        { field: 'ehrName', headerName: 'EHR', hide: false },
        { field: 'pmName', headerName: 'PM', hide: false },
        { field: 'rcmName', headerName: 'RCM', hide: false },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        { field: 'actions', headerName: 'Actions', hide: false, pinned: 'right', cellRenderer: 'editdeletecvcheck' },
      ];
    }

  }
  Complete(id) {
  }

  providercolumnVisible(event) {
    this.providersaveConfiguration();
  }
  providerdragStopped(event) {
    this.providersaveConfiguration();
  }
  providercolumnResized(event) {
    if (event.finished == true)
      this.providersaveConfiguration();
  }
  providersaveConfiguration() {
    let allCols: Column[] = this.gridOptionsProvider.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();
    config.maxlimit = 25;
    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 36;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  createColumnDefsProvider(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefsProvider = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefsProvider.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.columnDefsProvider.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "actions") {
          this.columnDefsProvider.push({ field: 'actions', headerName: 'Actions', cellRenderer: isDeActive ? 'editactivatecv' : 'editdeletecv', hide: false, pinned: 'right', width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else {
          this.columnDefsProvider.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefsProvider = [
        {
          field: '#',
          headerName: 'Actions',
          width: 110,
          cellRenderer: 'editdeletecv',
          suppressSorting: true,
          suppressMenu: true,
          pinned: 'right',
          hide: false,
          outerWidth: 8,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'lastname', headerName: 'Last Name', hide: false },
        { field: 'firstname', headerName: 'First Name', hide: false },
        { field: 'middlename', headerName: 'Middle Name', hide: false },
        { field: 'title', headerName: 'Title', hide: false },
        { field: 'speciality', headerName: 'Specialty', hide: false },
        { field: 'DOB', headerName: 'DOB', hide: false },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        { field: 'zip', headerName: 'ZIP', hide: false },
        { field: 'accountname', headerName: 'Account Name', hide: false },
        { field: 'contacttype', headerName: 'Contact Type', hide: true },  //true starts here
        { field: 'suffix', headerName: 'Suffix', hide: true },
        { field: 'sex', headerName: 'Sex', hide: true },
        { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
        { field: 'email', headerName: 'Email', hide: true },
        { field: 'address1', headerName: 'Address1', hide: true },
        { field: 'address2', headerName: 'Address2', hide: true },
        { field: 'city', headerName: 'City', hide: true },
        { field: 'state', headerName: 'State', hide: true },
        { field: 'cell', headerName: 'Cell', hide: true },
        { field: 'office', headerName: 'Office', hide: true },
        { field: 'homeaddress', headerName: 'Home Address', hide: true },
        { field: 'homecity', headerName: 'Home City', hide: true },
        { field: 'homestate', headerName: 'Home State', hide: true },
        { field: 'homezip', headerName: 'Home Zip', hide: true },
        { field: 'home', headerName: 'Home', hide: true },
        { field: 'college', headerName: 'College', hide: true },
        { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
        { field: 'medschool', headerName: 'Medschool', hide: true },
        { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
        { field: 'residency', headerName: 'Residency', hide: true },
        { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
        { field: 'fellowship', headerName: 'Fellowship', hide: true },
        { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
        { field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true },
        { field: 'created', headerName: 'Created', hide: true }
      ];
    }

  }
  onAgGridReady(event) {
     
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
      $(setTimeout(function () {
        selfObj.autoSizeAll();
      }, 400));
    }, 400));
  }
  onAgGridReadyProvider(event) {
     
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllProvider();
      $(setTimeout(function () {
        selfObj.autoSizeAllProvider();
      }, 400));
    }, 400));
  }
  autoSizeAll() { 
  }
  autoSizeAllProvider() {
  
  }

  saveCalllists() {     
    this.ConverttoCVshowDialog = !this.ConverttoCVshowDialog;   
    $(setTimeout(function () {
      $('#SaveDialog .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 4));
  }
  cancelCallList() {
     
    if (this.calllist.calllistname == undefined || this.calllist.calllistname == null || this.calllist.calllistname.trim() == "") {
      this.showCancelDialog = !this.showCancelDialog;
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
      }, 4));
    } else {
      this.router.navigateByUrl(`/application/contacts/contacts/null`);
    }
  }
  closeCancelDialog() {
    this.showCancelDialog = !this.showCancelDialog;
    this.applicationComponent.pageViewed = "";
  }
  closeCancelDialogGoToContacts() {   
    this.showCancelDialog = false;
    this.applicationComponent.pageViewed = "";
  }

  expandCollapseContactsGrid() {
     
    if (!this.contactsExpanded) {
      $('#agCallListContacts').removeClass('col-xs-6');
      //$('#accView').css('display', 'none');
      $('#agCallListContacts').addClass('col-xs-12');
      document.getElementById('expandid').innerHTML = "X";
    } else {
      $('#agCallListContacts').removeClass('col-xs-12');
      //$('#accView').css('display', 'block');
      $('#agCallListContacts').addClass('col-xs-6');
      document.getElementById('expandid').innerHTML = "< >";
    }
    this.contactsExpanded = !this.contactsExpanded;
    this.close()
  }

  SaveCalllist() {     
    this.calllist.userid = this.currentuser.id;
    this.calllist.contactids = this.calllist.contactids;   
    this.calllist.lastaccesseduserid = this.currentuser.id;
    this.calllist.Calllistselectedtype = this.applicationComponent.Calllistselectedtype;

    this.calllist.typeOfList = this.applicationComponent.Calllistselectedtype;
    this.accountsService.addCalllist(this.calllist).then(result => {
      this.calllist.calllistname = null;
      this.ShowButtons = false;
      this.applicationComponent.Iscalllisterrorshow = false;
      this.ConverttoCVshowDialog = false;
      this.applicationComponent.CallListDialog = false;
      this.applicationComponent.pageViewed = "";
      
    });
    
  }

  AddTask() {   
     
    this.taskDialog = !this.taskDialog;
    var searchBoxOffset = $("#searchBox").offset();
    let width = window.innerWidth;
    let searchBoxHeight = $('#searchBox').height();
    var leftmargin = ($(window).width()) / 1.7742;
    let windowHeight = $(window).height() / 1.175;
    $(setTimeout(function () {     
      $('#closeDialog').attr('style', 'color:white;')

      $('.dialog').attr('style', 'width:32% !important;border: 4px solid rgb(49,189,240);border-radius:5%; height:83%;top:16%;left:67%;max-width:none;padding-top: 0;');
    }, 1));
   
  }

  rowClickAgGrid(rowEvent) {  
    if (rowEvent.node.selected) {
      this.contactid = rowEvent.data.id;
      this.customerService.GetByAccountId(rowEvent.data.accountid).then(result => {
        this.account = result;      
        this.customerService.getAccountPermission(this.account.id, this.currentuser.id).then(perresult => {
          this.account.isAccountPermission = perresult;
        });

        if (this.account.website!=null && this.account.website.indexOf('www.') >= 0) {
          this.account.website = ((this.account.website).split('www.')[1])
        }       
       
        this.GetAllProviderAndContactInfo(this.account.id);
        $('#emailIds').val(rowEvent.data.id);
        $('#tasklistselectedtyle').val('Contact');   
      });
    } else {
      this.contactid = 0;
      //$('#emailIds').val('');
      $('#tasklistselectedtyle').val('')
    }
  }
  selectionAccountChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (rowEvent.api.getSelectedNodes()[0].data) {
        this.account = rowEvent.api.getSelectedNodes()[0].data;
        this.acccontactcomp.AccountData('show' + rowEvent.api.getSelectedNodes()[0].data.id);
        this.acccontactcomp.ShowContactView = false;
        $('#emailIds').val(rowEvent.api.getSelectedNodes()[0].data.id);
      }
    }

  } 
  selectionChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (rowEvent.api.getSelectedNodes()[0].data) {
        this.contactid = rowEvent.api.getSelectedNodes()[0].data.id;
        this.customerService.GetByAccountId(rowEvent.api.getSelectedNodes()[0].data.accountid).then(result => {
          this.account = result;          
          this.customerService.getAccountPermission(this.account.id, this.currentuser.id).then(perresult => {
            this.account.isAccountPermission = perresult;
          });

          if (this.account.website != null && this.account.website.indexOf('www.') >= 0) {
            this.account.website = ((this.account.website).split('www.')[1])
          }
          if (this.accountContactInfo != undefined) {
            this.accountContactInfo.GetContactInfo(this.contactid);
          }
          this.GetAllProviderAndContactInfo(this.account.id);
          $('#emailIds').val(rowEvent.api.getSelectedNodes()[0].data.id);
          $('#tasklistselectedtyle').val('Contact');
        });
      }
    } else {
      this.contactid = 0;
      //$('#emailIds').val('');
      $('#tasklistselectedtyle').val('')
    }

  }

  editcontact(val) {
    //document.getElementById('editAccount').style.display = 'block';
    //document.getElementById('accView').style.display = 'none';
    //$('#saveContact').attr('style', 'display: block');
    //this.loaddropdowns();
    this.accountid = this.account.id;
    this.contactid = val;
    this.contactshowDialog = !this.contactshowDialog;
    this.showAddEditContactCss();
  }

  cancelContact() {
    document.getElementById('editAccount').style.display = 'none';
    //document.getElementById('accView').style.display = 'block';
    $('#saveContact').attr('style', 'display: none');
    
  }

  loaddropdowns() {
     
    this.customerService.GetAllOwnerList(0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.OwnerList = Owner;

    });

     
    //this.customerService.GetSpecialitytypes(this.currentuser.id).then((accounttype) => {
    //  accounttype.forEach(accounttype => {
    //  });
    //  this.Specialitytypelist = accounttype;
    //});
    this.customerService.GetSpecialityList("Account Specialty", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Specialitytypelist = accounttype;
    });

    this.customerService.GetCollegeList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Collegelist = accounttype;
    });

    this.customerService.GetMedicalSchoolList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.MedicalSchoolList = accounttype;
    });

    this.customerService.GetAllStateList('all').then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.StatesList = accounttype;
      this.stateInfo = accounttype;
    });
    this.customerService.GetSpecialityList("Month", this.currentuser.id).then((appointment) => {
      appointment.forEach(appointment => {
      });
      this.MonthList = appointment;

    });

    this.customerService.GetSpecialityList("Day", this.currentuser.id).then((appointment) => {
      appointment.forEach(appointment => {
      });
      this.DayList = appointment;
    });
    this.customerService.Getcontacttypes(2).then((accounttype) => {
      this.contacttypelist = [];
      accounttype.forEach(accounttype => {
      });
      this.contacttypelist = accounttype;
    });
    this.customerService.GetCollegeList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Collegelist = accounttype;
    });
    this.customerService.GetSpecialityList("Suffix", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.SuffixList = accounttype;
    });
    this.customerService.GetSpecialityList("Sex", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.SexList = accounttype;
    });

    this.customerService.GetTitleListbycontacttype(this.contacts.contacttype).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.TitleList = accounttype;
    });

    this.getyears()
    {
      var range = [];
      var y = 1949;
      var year = new Date().getFullYear();
      for (var i = 0; y <= year; i++) {
        y = y + 1;
        range.push({
          label: y,
          value: y
        });
      }
      this.Years = range;
      this.MedSchoolYears = range;
      this.ResidencyYears = range;
      this.FellowShipYears = range;
    }
  }
  getyears() {
    var range = [];
    var year = new Date().getFullYear();
    for (var i = 0; i < 7; i++) {
      range.push({
        label: year + i,
        value: parseInt(String(year + i).slice(2, 4))
      });
    }
    this.Years = range;
  }
  SaveContact() {
     
    this.contacts.accountid = this.account.id;
    this.contacts.isshared = this.account.isshared;
    this.accountsService.addcontact(this.contacts).then(result => {
      this.GetAllProviderAndContactInfo(result.accountid)
      this.toastr.success("Contact Updated successfully.", "Contact Saved", { timeOut: 600 });

    });
    this.cancelContact();
    this.contacts = new Contacts();
    this.Resetcontacts();
  }

  Resetcontacts() {
    this.contacts.contacttype = null;
    this.contacts.suffix = null;
    this.contacts.sex = null;
    this.contacts.title = null;
    this.contacts.speciality = null;
    this.contacts.subspeciality = null;
    this.contacts.college = 0;
    this.contacts.collegegrandyear = null;
    this.contacts.medschool = 0;
    this.contacts.medschoolgrandyear = null;
    this.contacts.residency = 0;
    this.contacts.residencygrandyear = null;
    this.contacts.fellowship = 0;
    this.contacts.fellowshipgrandyear = null;
    this.contacts.ownerid = null;
    this.contacts.practiceid = 0;
  }

  contactsSelectState(id, name) {
    $('.selectedState').removeClass('selectedState');
    $('#cont_' + id).addClass('selectedState');
    $('#contactstateTextBox').val(name);
    this.contacts.state = name;
  }
  contactsShowStateList() {

    document.getElementById('contactsStateList').style.display = 'block';
  }
  contactsHideStateList() {

    document.getElementById('contactsStateList').style.display = 'none';
  }

  handleClick(event) {
     
    var clickedComponent = event.target;
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {

      $('#test').val('Contact');
    } else {
    }
  }
  statePopulate(zipid, cityid, stateid, stateDDN) {
    var that = this;
    var zipCode = $('#' + zipid).val();
    var newStr = zipCode.replace(/_/g, "");
    var newStrArray = newStr.split("-");
    if (newStrArray[0].length > 0) {
      var url = "http://ziptasticapi.com/" + newStrArray[0];
      $.ajax({
        url: url,
        dataType: "json",
        type: 'GET',
        success: function (data) {

          $('#' + cityid).val(data.city);
          that.loadstate(data.state, stateid, stateDDN);
          if (zipid == 'contactzip') {
            that.contacts.city = data.city;
          }
          if (zipid == 'contacthomeZip') {
            that.contacts.homecity = data.city;
          }
          if (zipid == 'zipCode') {
            that.account.city = data.city;

          }
        }

      });
    }
  }

  loadstate(state, stateid, stateDDN) {

    var countryCode = ($('#country').val());
    if (countryCode == 1) {
      for (var i = 0; i < 50; i++) {
        if (this.StatesList[i].itemName.trim().toString() == state) {
          $('#' + stateDDN).val(this.StatesList[i].id)
          this.account.state = state;
        }
      }
    } else {

      $('#' + stateid).val(state);

      if (stateid == 'contactstateTextBox') {
        this.contacts.state = state;

      }
      if (stateid == 'contacthomestate') {
        this.contacts.homestate = state;

      }
      if (stateid == 'stateTextBox') {
        this.account.state = state;
      }
    }
  }

  changeCityStateZip(id) {

    var $stateDropdownDiv = $('#contactstateTextBox').parent();
    if (id == 1) {
      $('#citySateZipforContacts').html('City/State/Zip');
      this.getSelectedState('all');
      ///this.stateTextBox = true;
      //this.stateDropDown = false;
      //this.displayZipCode = true;
     // this.displayCountryName = false;
      $stateDropdownDiv.removeClass();
      $stateDropdownDiv.addClass('col-xs-3 removeCompletePadding');
      $stateDropdownDiv.prop("style", "padding:0px");
      $('#contactstateTextBox').prop("placeholder", "Select State");
      $('#contactCityDiv').removeClass();
      $('#contactCityDiv').addClass('col-xs-6');

     // this.countryId == 1;
    }
    else if (id == 2) {
      $('#citySateZipforContacts').html('City/State/Zip');
      this.getSelectedState('bahamas');
      //this.stateTextBox = true;
      //this.stateDropDown = false;
      //this.displayZipCode = false;
      //this.displayCountryName = true;
      $stateDropdownDiv.removeClass();
      $stateDropdownDiv.addClass('col-xs-6 removeCompletePadding');
      $stateDropdownDiv.prop("style", "padding:0px");
      $('#contactstateTextBox').prop("placeholder", "Select Island");
      $('#contactCityDiv').removeClass();
      $('#displayCountry').val('Bahamas');
      //this.countryId == 2;
    }
  }
  getSelectedState(country) {
    this.customerService.GetAllStateList(country).then((state) => {
      state.forEach(accounttype => {
      });
      this.StatesList = state;
      this.stateInfo = state;

    });
  }
  editRowAg(rowdata) {
    this.contactid = rowdata.id;
    if (rowdata.type == 'Contact') {
      this.contactid = rowdata.id;
      this.accountid = rowdata.accountid;
      this.contactshowDialog = !this.contactshowDialog;
      this.showAddEditContactCss();
    } else {
      this.accountid = rowdata.id;
      this.showAddEditAccount = !this.showAddEditAccount;
      this.showAddEditAccountCss();
    }

    
  }
 
  rowClickAgGridprovider(rowEvent) {
     
    this.contactid = rowEvent.data.id;
  }
  enterNotes() {
     
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'block';
    document.getElementById('saveNotesButtons').style.display = 'block';
    document.getElementById('divTable').style.display = 'none';
  }
  SaveNote() {
     
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/img, "");
    this.notes.notedescription = this.notes.notedescription.replace(/<[^>]*>/g, '');
    this.notes.type = "Contact";
    this.notes.accountid = this.contactid;

    this.customerService.SaveNotes(this.notes).then(result => {
      this.GetCustomerNotes(this.notes.accountid, 'Contact');
      $('#notesListTabsId .active').removeClass('active');
      $('#notesListTabsId #allNotesId').addClass('active');
      this.CancelNote();
    });
  }
  CancelNote() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'none';
    document.getElementById('saveNotesButtons').style.display = 'none';
    document.getElementById('divTable').style.display = 'block';
  }
  deletetoOpenRowAg(rowdata) {

     
    this.selectedcontactid = rowdata.id;
    this.selectedstatus = rowdata.calllistcontactstatus;
    this.showContactOpenDialog = !this.showContactOpenDialog;
    setTimeout(function () {
      //$('.dialog').attr('style', 'height:180px;width:35%;padding-top: 3%;background: #f4f4f4;border: 1px solid #a2e477;')
      $('.dialog').attr('style', 'width: 35%;padding - bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min - height: 23 %;overflow: visible;height: 23 %;')
    }, 4)
  }
  deleteRowAg(rowdata) {
    this.selectedcontactid = rowdata.id;
    if (rowdata.callisttype && rowdata.callisttype == 'callistcontact') {      
      this.deleteId = rowdata.id;
      if (rowdata.calllistcontactstatus == 'Open') {
        this.showDeleteDialog = !this.showDeleteDialog;
      } else {
        alert("Already You have Contacted this Contact so you Can't Delete !!!!")
      }
      this.showDeleteDialogCss();
    }
    else {      
      this.deleteRowData = rowdata;
      this.contactstatus = this.deleteRowData.isdeleted;
      this.DeleteConfirmationPopup = true;
      setTimeout(function () {
        $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
      }, 4);
      
    }
  }
  ReceiveDeleteevent(msg) {
    if (msg == "Ok") {
      this.contactstatus = (this.contactstatus==1)?0:1;
      this.customerService.Deactivatecontact(this.deleteRowData.id, this.contactstatus).then((user) => {
        this.GetAllProviderAndContactInfo(this.deleteRowData.accountid);
      });
      }
   
      this.DeleteConfirmationPopup = false;
   
  }
  assignuser(assigneduserid) {
     
    this.customerService.Assigntocalllist(assigneduserid, this.calllist.id).then((calllist) => {
      this.assigncalllistDialog = !this.assigncalllistDialog
      this.router.navigateByUrl(`/application/viewcalllist`);
    });
  }
  RemoveContact(contactid) {
    if (contactid != 0) {
      var contactidsArray = (this.calllist.contactids != null ? this.calllist.contactids.split(',') : []);
      contactidsArray.splice($.inArray(contactid + "", contactidsArray), 1);
      this.calllist.contactids = new Array(contactidsArray).join(',');
      var deletedcontactidArray = (this.calllist.deletedcontactids != null ? this.calllist.deletedcontactids.split(',') : []);
      deletedcontactidArray.push(contactid);
      this.calllist.deletedcontactids = new Array(this.RemoveDuplicateItemsFromArr(deletedcontactidArray)).join(',');
      this.calllist.deletedcontactids = this.RemoveDuplicateItemsFromArr(this.calllist.deletedcontactids.split(',')).toString();
      if (this.calllist.id > 0) {
        this.accountsService.addCalllist(this.calllist).then(result => {
          //this.GetAllSelectedContactCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, 'Status');

          if (this.applicationComponent.Calllistselectedtype == 'Contact' || this.applicationComponent.Calllistselectedtype == 'CustomerContact') {
            this.GetAllSelectedContactCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, 'Contacts');
          } else if (this.applicationComponent.Calllistselectedtype == 'Customer' || this.applicationComponent.Calllistselectedtype == 'Customers' || this.applicationComponent.Calllistselectedtype == 'Contacts/Accounts') {
            this.GetAllSelectedAccountCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, this.applicationComponent.Calllistselectedtype);
          }
        });
      } else {
        // this.GetAllSelectedContactCalllist(this.calllist.contactids, '', this.calllist.deletedcontactids, 'Contacts');
        if (this.applicationComponent.Calllistselectedtype == 'Contact' || this.applicationComponent.Calllistselectedtype == 'CustomerContact') {
          this.GetAllSelectedContactCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, 'Contacts');
        } else if (this.applicationComponent.Calllistselectedtype == 'Customer' || this.applicationComponent.Calllistselectedtype == 'Customers' || this.applicationComponent.Calllistselectedtype == 'Contacts/Accounts') {
          this.GetAllSelectedAccountCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, this.applicationComponent.Calllistselectedtype);
        }
      }
      

      this.showDeleteDialog = !this.showDeleteDialog;
      this.showDeleteDialogCss();
    }
  }
  closeDeleteDialog() {
    this.deleteId = 0;
    this.showDeleteDialog = !this.showDeleteDialog;
    this.showDeleteDialogCss();
  }
  sharingAg(rowdata) {
    this.accountid = rowdata.accountid;
    this.isSharing = true;
    this.isSharingCss();
    this.ownrshipComp.loadAccountInformation(rowdata.accountid, 0);
  }
  onCloseEventReceived(msg) {
    this.accountid = 0;
    this.isSharing = false;
    if (this.applicationComponent.Calllistselectedtype == 'Contact' || this.applicationComponent.Calllistselectedtype == 'CustomerContact') {
      this.GetAllSelectedContactCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, 'Contacts');
    } else if (this.applicationComponent.Calllistselectedtype == 'Customer' || this.applicationComponent.Calllistselectedtype == 'Customers' || this.applicationComponent.Calllistselectedtype == 'Contacts/Accounts') {
      this.GetAllSelectedAccountCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, this.applicationComponent.Calllistselectedtype);
    }
  }
 
  onItemSelect(item: any) {
    this.ShowButtons = true;
    var ids = item.id;
    this.assignuserid = item.id;
    $('.dropdown-list').prop('hidden', true)
    this.userservice.getprivilagebyuser(this.assignuserid, 22).then((privilages) => {
      if (privilages == null) {
        this.toastr.warning("You Do Not have Access to View Call list – Please check with CRM Administrator if Access is Required.", "Access Denied");
      }
      else {
        this.assigncalllistDialog = !this.assigncalllistDialog
        $(setTimeout(function () {
          $('.dialog').attr('style', 'border: 4px solid #a2e477;width: 467px;overflow:hidden;');
        }, 4));
      }
    });
   
  }
 
  
  OnItemDeSelect(item: any) {
    this.ShowButtons = true;
    var ids = item.id;
  }
  convertContactAg(rowdata) {
    this.contactid = rowdata.id;    
    this.customerService.GetContactById(rowdata.id).then(result => {
      this.contacts = result;
      //this.leadstatus = this.contacts.converttolead;
      if (this.contacts.converttolead == 0) {
        this.applicationComponent.loaddialog('convertcontacttolead', 'Convert Lead', 'Convert this Contact to a Lead?', false, false, true, true, this.contactid);
      }
      else {
        this.applicationComponent.loaddialog('convertcontacttolead', 'Convert Lead', 'Contact is an Open Lead', false, false, true, true, this.contactid);
      } 
      //this.applicationComponent.loaddialog();
      //this.showDialog = !this.showDialog;
      //this.showDialogCss();
    });
    this.applicationComponent.pageViewed = "";
  }
  //showDialogCss() {
  //  $(setTimeout(function () {
  //    $('#resetpassword .dialog ').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%')

  //  }, 4));
  //}
  showDeleteDialogCss() {
    $(setTimeout(function () {
      $('#deleteDialog .dialog ').attr('style', 'height:180px;width:35%;padding-top: 0%;background: rgb(186,219,152) !important;border:4px solid rgb(49,189,240);border-radius:5%;overflow:visible;')

    }, 4));
  }
  AccountsharingAg(selectedval) {
    if (selectedval == "Edit") {
      this.isSharing = false;
      this.action = selectedval;
      this.showAddEditAccount = true;
      this.showAddEditAccountCss();
    }
    else if (selectedval == "View") {
      this.isSharing = false;
      this.action = selectedval;
      this.showAddEditAccount = true;
      this.showAddEditAccountCss();
    }
    else {
      this.isSharing = true;      
      this.ownrshipComp.loadAccountInformation(this.account.id, 0);
    }
    
  }
  onCloseEventSharing(event)
  { this.isSharing = false; }

  callcheck(rowdata) {
   
    this.contactid = rowdata.id;
    this.selectedcontactid = rowdata.id;
    this.selectedstatus = rowdata.calllistcontactstatus;
    if (rowdata.calllistcontactstatus != 'Completed' || rowdata.calllistcontactstatus == 'Completed') {
      this.showContactDialog = !this.showContactDialog;
      setTimeout(function () {
        $('#ContactDialog .dialog').attr('style', 'height:180px!;width:30%;border: 5px solid #a2e477;margin-top: 38px;margin-left: 619px')
      }, 4)
    } 
    else {
      this.calllistcontactstatus(rowdata.id, 'Open');    
    }
  }

  closecalllistdialog() { this.applicationComponent.CallListDialog = false; this.applicationComponent.pageViewed = ''; }

  calllistdialogclose(status) {
    if (status =='Open') {
      this.showContactOpenDialog = !this.showContactOpenDialog;
 } else {
      this.showContactDialog = !this.showContactDialog;
    }
    document.getElementById('checkDiv').style.display = 'none';
  }
  RemoveDuplicateItemsFromArr(arr: any[]) {
    var uniqueIDs = [];
    $.each(arr, function (i, el) {
      if ($.inArray(el, uniqueIDs) === -1) uniqueIDs.push(el);
    });

    return uniqueIDs;
  }

  calllistcontactstatus(contactid, status) {
    if (status == "Deleted") {
      this.calllist.contactids = this.RemoveDuplicateItemsFromArr(this.calllist.contactids.split(',')).toString();  
      var DeletedidsArray = (this.calllist.deletedcontactids != null ? this.calllist.deletedcontactids.split(',') : []);
      DeletedidsArray.splice($.inArray(contactid + "", DeletedidsArray), 1);
      this.calllist.deletedcontactids = new Array(DeletedidsArray).join(',');
      var contactidsArray = (this.calllist.contactids != null ? this.calllist.contactids.split(',') : []);
      contactidsArray.push(contactid);
      this.calllist.contactids = new Array(this.RemoveDuplicateItemsFromArr(contactidsArray)).join(',');

    }
    else if (status == "Completed")
    {
      this.calllist.contactids = this.RemoveDuplicateItemsFromArr(this.calllist.contactids.split(',')).toString(); 
      var CompletedidsArray = (this.calllist.completedcontactids != null ? this.calllist.completedcontactids.split(',') : []);
      CompletedidsArray.splice($.inArray(contactid + "", CompletedidsArray), 1);
      this.calllist.completedcontactids = new Array(CompletedidsArray).join(',');
      var contactidsArray = (this.calllist.contactids != null ? this.calllist.contactids.split(',') : []);
      contactidsArray.push(contactid);
      this.calllist.contactids = new Array(this.RemoveDuplicateItemsFromArr(contactidsArray)).join(',');

    }
    else if (status == "Open") {
      this.calllist.completedcontactids = this.RemoveDuplicateItemsFromArr(this.calllist.completedcontactids.split(',')).toString();
      var CompletedidsArray = (this.calllist.completedcontactids != null ? this.calllist.completedcontactids.split(',') : []);
      CompletedidsArray.splice($.inArray(contactid + "", CompletedidsArray), 1);
      this.calllist.completedcontactids = new Array(CompletedidsArray).join(',');
      var contactidsArray = (this.calllist.contactids != null ? this.calllist.contactids.split(',') : []);
      contactidsArray.push(contactid);
      this.calllist.contactids = new Array(this.RemoveDuplicateItemsFromArr(contactidsArray)).join(',');

    }
    else {
    
      this.calllist.completedcontactids = this.RemoveDuplicateItemsFromArr(this.calllist.completedcontactids != null ? this.calllist.completedcontactids.split(',') : []).toString();
      var contactidsArray = (this.calllist.contactids != null ? this.calllist.contactids.split(',') : []);
      contactidsArray.splice($.inArray(contactid + "", contactidsArray), 1);
      this.calllist.contactids = new Array(contactidsArray).join(',');
      var completedcontactidArray = (this.calllist.completedcontactids != null ? this.calllist.completedcontactids.split(',') : []);
      completedcontactidArray.push(contactid);
      this.calllist.completedcontactids = new Array(this.RemoveDuplicateItemsFromArr(completedcontactidArray)).join(',');
      if (this.calllist.contactids == "") {
        $("#callstatus").val("Completed");
        this.calllist.status = 'Completed';
        this.router.navigateByUrl(`/application/viewcalllist`);
      }
    }
    this.calllist.currentAction = status;
    this.calllist.lastaccesseduserid = this.currentuser.id;
    this.calllist.userid = this.currentuser.id;
   
    this.accountsService.addCalllist(this.calllist).then(result => {
      //this.GetAllSelectedContactCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, 'Status');
      if (this.applicationComponent.Calllistselectedtype == 'Contact' || this.applicationComponent.Calllistselectedtype == 'CustomerContact') {
        this.GetAllSelectedContactCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, 'Contacts');
      } else if (this.applicationComponent.Calllistselectedtype == 'Customer' || this.applicationComponent.Calllistselectedtype == 'Customers' || this.applicationComponent.Calllistselectedtype == 'Contacts/Accounts') {
        this.GetAllSelectedAccountCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, this.applicationComponent.Calllistselectedtype);
      }
      });
    if (status == "Deleted" || status == "Completed") {
      this.showContactOpenDialog = !this.showContactOpenDialog;

    } else {
      this.showContactDialog = false;

    }
  }
  //printMsg(msg) {
     
  //  this.contactshowDialog = !this.contactshowDialog;
  //  this.GetAllSelectedContactCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids,'Status');
  //}
  onTaskSaved(msg) {
     
  this.taskDialog = !this.taskDialog;
  }
  public transform(input: string): string { 
     
    if (!input) {
      return '';
    }
    else {
      return input[0].toUpperCase() + input.substr(1);
    }
  }

  openDisplaynotes(params) {
     
    $('#closeDetailsDialog').click();
    
    this.notes.notedescription = params.value;
    this.NotesDec = true;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;   

    let selfObj = this;
    $(setTimeout(function () {
      var notesOffset = $("#divNotesContainer").offset();
      let notesWidth = $('#divNotesContainer').width();
      let notesHeight = $('#divNotesContainer').height();
      let windowWidth = $(window).width();
      let showDialogWidth = 350;

       
      //$('#showDetailsDialog').width(showDialogWidth);
      //$('#showDetailsDialog').height(notesHeight - 140);
      //if ($('#typeClicked').val() == 'modal') {
      if (selfObj.isModal) {
        //$('#showDetailsDialog').css('top', '23%');
        //$('#showDetailsDialog').css('height', '61%');
        //$('#showDetailsDialog').css('left', notesWidth - showDialogWidth);
        //$('#showDetailsDialog').css('top', notesOffset.top + 125);
        //$('#showDetailsDialog').css('left', notesWidth - showDialogWidth);

        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '81%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '14%');
        } else {
          $('#txtLongNoteDesc').css('height', '96%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      } else {
        //var url = window.location.href;
        //if ((url).indexOf('account-contact') > -1) {
        //  $('#showDetailsDialog').css('top', '62%');
        //} else {
        //  $('#showDetailsDialog').css('top', '72%');
        //}
        var gridOffset = $("#notesGrid").offset();
        //$('#showDetailsDialog').css('top', gridOffset.top);
        //$('#showDetailsDialog').css('height', '30%');
        //$('#showDetailsDialog').css('left', '69%');

        $('#showDetailsDialog').attr('style', 'width: 22%;height: 62%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '53%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '33%');
        } else {
          $('#txtLongNoteDesc').css('height', '89%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      }
      //$('#showDetailsDialog').css('border-radius', '4px');
      //$('#showDetailsDialog').css('border', '3px solid');
      $('#showDetailsDialog').show();
    }, 1));
  }

  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
     
    if ($("#btnEditNote").text() == "Edit Note") {
      $("#txtLongNoteDesc").removeAttr("readonly");
      $("#txtLongNoteDesc").css("background-color", "white");
      $("#btnEditNote").css("background-color", "#A2E477");
      $("#btnEditNote").text("Save Note");
    } else {
      this.saveEditedNote();
    }
  }
  openAccountIds() {
    this.statusIds = true;
    this.opprtunityservice.GetByAccountId(this.account.id).then(result => {
      this.AccountIdsDialog = true;
      this.AccountIdsDialogCss();
    });

  }

  AccountIdsDialogCss() {

    //if (this.account.accountid1 == null && this.account.accountid2 == null && this.account.accountid3 == null) {
    //  setTimeout(function () {
    //    $('.dialog').attr('style', 'width: 20%;padding-bottom:0px;border:2px solid black;min-height:15%;overflow: auto;height: 18%;left: 56%;top: 18%;padding: 0;');
    //  }, 4)
    //}
    //else {
      setTimeout(function () {
        $('.dialog').attr('style', 'width: 20%;padding-bottom:0px;border:2px solid rgb(49,189,240);border-radius:5%;min-height:15%;overflow: auto;height: 18%;left: 56%;top: 28%;padding: 0;');
      }, 4)
    //}
  }
  Updatesaccounts() {
    this.account.id = this.account.id;
    this.accountsService.updateaccountids(this.account).then(result => {
      this.statusIds = false;
    });
  }
  cancelaccountIds() {
    this.AccountIdsDialog = false;
  }

  AccountId1PopupChange(val) {
    if (val == "null") { this.account.accountpopid2 = "null"; this.account.accountpopid3 = "null"; }
    else if ((this.account.accountpopid1 == this.account.accountpopid2) || (this.account.accountpopid1 == this.account.accountpopid3)) {
      alert("Please select Other Option already Selected Value Added .")
      this.account.accountpopid1 = "null";
    }
  }
  AccountId2PopupChange(val) {
    if (val == "null") { this.account.accountpopid2 = "null"; this.account.accountpopid3 = "null"; }
    else if ((this.account.accountpopid2 == this.account.accountpopid1) || (this.account.accountpopid2 == this.account.accountpopid3)) {
      alert("Please select Other Option already Selected Value Added .")
      this.account.accountpopid2 = "null";
    }
  }
  AccountId3PopupChange(val) {
    if (val == "null") { this.account.accountpopid2 = "null"; this.account.accountpopid3 = "null"; }
    else if ((this.account.accountpopid3 == this.account.accountpopid1) || (this.account.accountpopid3 == this.account.accountpopid2)) {
      alert("Please select Other Option already Selected Value Added .")
      this.account.accountpopid3 = "null";
    }
  }
  saveEditedNote() {
     
    let desc: string = $("#txtLongNoteDesc").val();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.closeDetailsDialog();
        selfObj.GetCustomerNotes(selfObj.conid, 'Contact');
      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
  }
  ResizeTable() {
    this.aggridheight = ((((window.innerHeight) - (($('#appComponentContent').height()) + ($('#actiondivid').height()))) - 55)) + 'px';
  }
  ResizeTableP() {
    this.aggridheightp = ((((window.innerHeight) - (($('#appComponentContent').height()) + ($('#actiondivid').height()) + ($('#accountInformation').height()))) - 120)) + 'px';
  }

  openAccountNotes() {
    this.isModal = true;
    this.conid = this.account.id;
    $('#accotIdNotes').val(this.account.id);
    $('#accotTypeNotes').val('Customer');
    this.notesshowDialog = !this.notesshowDialog;
    this.NotesDialogCss();
    let selfobj = this;
    $(setTimeout(function () {
      selfobj.notecomp.Notes('AllContactsNotes');
      $('#chkShowDetailsNotes').prop('checked', false); $('#closeDetailsDialog').click();
    }, 50));
  }
  showAddEditContactCss() {
    setTimeout(function () {

      $('#addEditContact .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  showAddEditAccountCss() {
    setTimeout(function () {

      $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  ContactReceiveEvent(msg) { 
    this.contactshowDialog = false;
    this.GetAllSelectedContactCalllist(this.calllist.contactids, this.calllist.completedcontactids, this.calllist.deletedcontactids, 'Status');
    this.toastr.success("Contact Updated successfully.", "Contact Saved", { timeOut: 600 });
  }
  closecalllist() {
    this.applicationComponent.activateMainButtons('Call Lists');
  }
 ReceiveEvent(msg) {
   if (msg == "ExpandSoftwareLicense" || msg == "CollapseSoftwareLicense") {
     this.ExpandAddEditAccountDialog(msg);
   } else {
     this.showAddEditAccount = false;
   } 
  }
  
  addContact() {      
      if (this.userprevilages != undefined) {
        for (var j = 0; j < this.userprevilages.length; j++) {
          if ("Contacts" == this.userprevilages[j].resource) {
            if (this.userprevilages[j].canadd == false) {
              this.toastr.clear();
              this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
              return;
            }
          }

        }
      }
      this.showAddEditContact = true;
      this.showAddEditContactCss();
      this.contactid = 0;
    
  }
  Receivevent(msg) {
    this.AddTask();
  }
  expandcalllist(value) {
    this.isCalllistOpen = (this.isCalllistOpen) ? false : true;
    if (value === 'Collapse') {
      if (this.applicationComponent.Calllistselectedtype == 'Contact' || this.applicationComponent.Calllistselectedtype == 'CustomerContact') {
        this.accountContactInfo.SixBoxExpand = false;
        this.accountContactInfo.tab = 'QuickView';
      } else {
        this.accComp.SixBoxExpand = false;
        this.accComp.tab = 'QuickView';
      }
      
    }
    else {
      if (this.applicationComponent.Calllistselectedtype == 'Contact' || this.applicationComponent.Calllistselectedtype == 'CustomerContact') {
        this.accountContactInfo.SixBoxExpand = false;
        this.accountContactInfo.tab = 'QuickView'; //dummy data
      }
      else {
        this.accComp.SixBoxExpand = false;
        this.accComp.tab = 'QuickView';
      }
      
    }
  }
  onAccountMessageReceived(msg) {
    if (msg == "QuickView") {
      this.OpenCancelDialog();
    }
    else if (msg == "Expand") {
      this.isCalllistOpen = false;
    } else if (msg == "Collapse")
    { this.isCalllistOpen = true; }
  }
  btnClickReceived(event) {
    if (event.btnName == 'addContact') {
      this.addContact();
    }
    else if (event.btnName == 'Accountsharing') {
      this.AccountsharingAg(event.val);
    }
    else if (event.btnName == 'openNotes') {
      this.openAccountNotes();
    }
  }

  isSharingCss() {
    setTimeout(function () {
      $('#isSharingDialog .dialog').attr('style', 'width: 35%;padding-bottom: 0px;border: 1px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 82.5%;padding: 0;top: 10%;left: 63%;');
    }, 4)

  }



  ExpandAddEditAccountDialog(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 100%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 86%;padding: 0;top: 10%;left: 10%;max-width: 88%;');
      }, 4)
    } else {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
      }, 4)
    }
  }

}
