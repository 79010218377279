<div class="container" style="height: 100%; padding: 0;">
  <div class="col-xs-12" id="subTab" style="padding:0;height:100%">
  
    <div class="col-xs-12" style="padding:0px !important;width:100%;height:6%">
      <div class="col-xs-1"><img src="../../../assets/img/Add Campaign.png" (click)="ShowRolesForm = true" style="height:25px;" /></div>
    </div>
    <div class="col-xs-12" style="padding: 0; height: 97%; padding: 0 0 0 1%;">
      <div class="col-xs-6" style="padding: 0; height: 93%">
        <ag-grid-angular style="height:100%;" class="ag-theme-balham"
                         [gridOptions]="gridOptions"
                         [columnDefs]="columnDefs"
                         [rowData]="allRoles"
                         [context]="context"
                         (selectionChanged)="onRoleSelectionChanged($event)"
                         [rowDragManaged]="true"
                         (gridReady)="onAgGridReady($event)">
        </ag-grid-angular>
      </div>

      <div class="col-xs-6" style="padding:0;height: 93%">
        <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ShowRolesForm">
          <form #RoleForm="ngForm">

            <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%">
              <div class="col-xs-3" style="padding-right:0;">
                <label>User Role</label>
              </div>
              <div class="col-xs-9">
                <input id="rolename" name="orole.rolename" class="form-control" [(ngModel)]="orole.rolename" placeholder="Role Name">
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%">
              <div class="col-xs-3" style="padding-right:0;">
                <label>Sales Rep</label>
              </div>
              <div class="col-xs-9">
                <select id="Salesrep" name="orole.Salesrep" class="form-control" [(ngModel)]="orole.Salesrep">
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%">
              <div class="col-xs-3" style="padding-right:0;">
                <label>Sort Order</label>
              </div>
              <div class="col-xs-9">
                <input id="SortOrder" name="orole.SortOrder" class="form-control" [(ngModel)]="orole.SortOrder" placeholder="SortOrder">
              </div>
            </div>

            <div class="col-xs-12" style="padding:0;height:7%;margin-top:50%">


              <div class="col-xs-7"></div>
              <div class="col-xs-2" style="display:block;padding:0">
                <button class="btn noprovider" style="width:100%;background: lightgray;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="CancelRole()">Cancel</button>
              </div>
              <div class="col-xs-2 pull-right" style="display:block;padding:0;margin-right:1%">
                <button class="btn noprovider" (click)="SaveRole()" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" [disabled]="(!RoleForm.form.valid)">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>

  <!--</div>-->
</div>
