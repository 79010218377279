export class TaskType {

  public id: number;
  public tasktype: string;
  public category: string;
  public isdeleted: number;
  public taskgroupid: number;
  public taskgroupName: string;
  
  public SortOrder: string;
  public TimeRequired: string;

}
