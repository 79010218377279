import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SLCategories, SLTypes } from '../../models/SoftwareLicenseConfiguration';
import { ApplicationComponent } from '../application.component';
import { GridOptions, RowDragEndEvent } from 'ag-grid';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { AuditLogModel } from '../../models/auditlog';
import { SystemRowConfiguration } from '../../models/system-row-configuration';
import { LeadsService } from '../../services/leads.service';

@Component({
  selector: 'app-software-license-configuration',
  templateUrl: './software-license-configuration.component.html',
  styleUrls: ['./software-license-configuration.component.css']
})
export class SoftwareLicenseConfigurationComponent implements OnInit {
  public SLC_CategoriesPage: boolean = true;
  public SLC_TypesPage: boolean = false;

  currentuser: any;
  SLCategory: SLCategories = new SLCategories();
  SLType: SLTypes = new SLTypes();
  createNewCategory: boolean = false;
  createNewLicenseType: boolean = false;
  public gridOptions: GridOptions;
  public sideBar;
  public columnDefs: any[];
  all_SL_Category: SLCategories[];
  AddSLCategoryForm: FormGroup;
  AddSLTypeForm: FormGroup;

  all_SL_Types: SLTypes[];
  all_SL_TypesOriginal: SLTypes[];
  AddSLTypesForm: FormGroup;
  public gridSoftwareLicenseTypeApi;
  public gridSoftwareLicenseCategoryApi;
  @Input() SubMenuType: string = null;
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration();
  constructor(public applicationComponent: ApplicationComponent, private userService: UserService, private toastr: ToastrService, private leadsService: LeadsService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    //if (this.SubMenuType !== null && this.SubMenuType !== undefined && this.SubMenuType !== '') {
    this.ShowSLC_Pages("Categories");
    //} else {
      //var obj: AuditLogModel = { id: 0, type: 'Software License Configuration', action: 'Software License', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Software License', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
      //this.applicationComponent.InsertAuditHistory(obj);
    //}
  }

  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');

  }
  CloseDivs() {
    this.SLC_CategoriesPage = false;
    this.SLC_TypesPage = false;
    this.SLType = new SLTypes();
    this.SLCategory = new SLCategories();
    this.createNewLicenseType = false;
    this.createNewCategory = false;
  }

  ShowSLC_Pages(value) {
    if (value == 'Categories') {
      this.SLC_CategoriesPage = true;
      this.SLC_TypesPage = false;
      this.initializeAgGrid(value);
      this.SLCategory = new SLCategories();
    } else if (value == 'LicenseTypes') {
      this.SLC_CategoriesPage = false;
      this.SLC_TypesPage = true;
      this.initializeAgGrid(value);
      this.SLType = new SLTypes();
    }

    var obj: AuditLogModel = { id: 0, type: 'Software License Configuration', action: 'Software License - ' + value, AccountOrContactId: 0, AccountOrContact: value, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Software License - ' + value, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }

  CreateNewCategory(Value) {
    if (Value == 'Categories') {
      this.getAddCategoryForm(Value);
      this.createNewCategory = true;
      this.SLCategory = new SLCategories();
    } else if (Value == 'LicenseTypes') {
      this.getAddCategoryForm(Value);
      this.createNewLicenseType = true;
      this.SLType = new SLTypes();
    }
   

  }

  getAddCategoryForm(Value): FormGroup {
    if (Value == 'Categories') {
      return new FormGroup({
        'LicenseCategoryName': new FormControl(this.SLCategory.LicenseCategoryName, Validators.required),
        'SortOrder': new FormControl(this.SLCategory.SortOrder, Validators.required),
      });
    } else {
      return new FormGroup({
        'LicenseTypeName': new FormControl(this.SLType.LicenseTypeName, Validators.required),
        'SortOrder': new FormControl(this.SLType.SortOrder, Validators.required),
        'LicenseCategoryId': new FormControl(this.SLType.LicenseCategoryId, Validators.required),
      });
    }
    
  }

  initializeAgGrid(value) {
    this.gridOptions = <GridOptions>{
      enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000, toolPanelSuppressPivotMode: true, rowSelection: 'single',
      toolPanelSuppressValues: true, enableCellChangeFlash: true, enableMultiRowDragging: true,
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };

    
    this.GetAll_SL_Category(value);
    
    this.createColumnDefs(value);

  }

  createColumnDefs(value) {
    let selfObj = this;
    if (value == 'Categories') {

      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 65,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true
        },
        { field: 'LicenseCategoryId', headerName: 'Id', hide: true, width: 120 },
        {
          field: 'LicenseCategoryName', headerName: 'Category', hide: false, width: 400,
        },
        { field: 'SortOrder', headerName: 'Sort Order', hide: false, width: 120 },
        {
          field: 'action', headerName: 'Action', hide: false, width: 100, cellRenderer(params) {
            var eDiv = document.createElement('div');

            if (params.data.isdeleted == 1) {
              eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size: 18px;"></i>';
            } else {
              eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size: 18px;"></i>';
            }

            
            eDiv.addEventListener('click', function () {
              selfObj.deleteProposalCategory(params.data, value);
            });
            return eDiv;
          }
        }
      ];

    } else if (value == 'LicenseTypes') {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 65,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true
        },
        { field: 'LicenseTypeId', headerName: 'Id', hide: true, width: 120 },
        {
          field: 'LicenseTypeName', headerName: 'License Type', hide: false, width: 400,
        },{
          field: 'LicenseCategoryName', headerName: 'Category', hide: false, width: 400,
        },
        { field: 'SortOrder', headerName: 'Sort Order', hide: false, width: 120 },
        {
          field: 'action', headerName: 'Action', hide: false, width: 100, cellRenderer(params) {
            var eDiv = document.createElement('div');

            if (params.data.isdeleted == 1) {
              eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size: 18px;"></i>';
            } else {
              eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size: 18px;"></i>';
            }

            eDiv.addEventListener('click', function () {
              selfObj.deleteProposalCategory(params.data, value);
            });
            return eDiv;
          }
        }
      ];
    }
    
  }

  onRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("SLCategories");
  }
  onRowDragEndLT(event: RowDragEndEvent) {
    this.saverowconfiguration("SLTypes");
  }

  saverowconfiguration(type) {
    var sortoorder = [];
    if (type == "SLCategories") {
      this.gridSoftwareLicenseCategoryApi.forEachNode((node) => {
        sortoorder.push(node.data.LicenseCategoryId);
      });
    } else {
      this.gridSoftwareLicenseTypeApi.forEachNode((node) => {
        sortoorder.push(node.data.LicenseTypeId);
      });
    }

    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i == a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentuser.id;
    this.leadsService.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });
    });

  }

  selectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        if (this.SLC_TypesPage) {
          this.SLType = event.api.getSelectedNodes()[0].data;
          this.createNewLicenseType = true;
        } else {
          this.SLCategory = event.api.getSelectedNodes()[0].data;
          this.createNewCategory = true;
        }
        
      }
    } else {
      if (this.SLC_TypesPage) {
        this.ClearForm('LicenseTypes');
      } else {
        this.ClearForm('Categories');
      }
      
    }
  }
  ClearForm(value) {
    if (value == 'Categories') {
      this.SLCategory = new SLCategories();
    } else {
      this.SLType = new SLTypes();
    }
    
  }

  SaveSLCategory() {
    this.SLCategory.CreatedBy = this.currentuser.id;
    if (this.SLCategory.LicenseCategoryId == null || this.SLCategory.LicenseCategoryId == undefined || this.SLCategory.LicenseCategoryId == 0) {
      this.SLCategory.LicenseCategoryId = 0;
    }

    this.SLCategory.isdeleted = 0;
    this.AddOrUpdateProposalCategory(this.SLCategory,'Categories');
  }

  AddOrUpdateProposalCategory(Category, type) {
    if (type == 'Categories') {
      this.userService.AddOrUpdateSoftwareLicenseCategory(Category).then(result => {
        this.initializeAgGrid("Categories");
          this.ClearForm(type);
        this.SLCategory = new SLCategories();
        this.createNewCategory = false;
        this.toastr.success("Software License Category saved successfully.", "Software License  Category Saved", { timeOut: 600 });
      });
    }

    else if (type == 'LicenseTypes') {
      this.userService.AddOrUpdateSoftwareLicenseTypes(Category).then(result => {
        this.initializeAgGrid("LicenseTypes");
        this.SLCategory = new SLCategories();
        this.createNewCategory = false;
        this.ClearForm(type);
        this.toastr.success("Software License Type saved successfully.", "Software License Type Saved", { timeOut: 600 });
      });
    }
      
    
  }
  SaveSLType() {
    this.SLType.CreatedBy = this.currentuser.id;
    if (this.SLType.LicenseTypeId == null || this.SLType.LicenseTypeId == undefined || this.SLType.LicenseTypeId == 0) {
      this.SLType.LicenseTypeId = 0;
    }

    this.SLType.isdeleted = 0;
    this.AddOrUpdateProposalCategory(this.SLType,'LicenseTypes');
  }
  loadSLTypes(event) {
    if (event == 0) {
      this.all_SL_Types = this.all_SL_TypesOriginal;
    } else {
      this.all_SL_Types = this.all_SL_TypesOriginal.filter(x => x.LicenseCategoryId == event);
    }
    
  }
  onAgGridReady(event, val) {
    if (val == 'Category') {
      this.gridSoftwareLicenseCategoryApi = event.api;
    } else {
      this.gridSoftwareLicenseTypeApi = event.api;
    }
  }

  GetAll_SL_Category(Type) {
    if (Type == 'Categories') {
      this.userService.GetAll_SL_Categories(this.currentuser.id).then(result => {
        this.all_SL_Category = result;
      });
    }
    else {

      this.userService.GetAll_SL_Categories(this.currentuser.id).then(result => {
        this.all_SL_Category = result.filter(x => x.isdeleted == 0);

        this.userService.GetAll_SL_Types(this.currentuser.id).then(result => {
          this.all_SL_Types = result;
          this.all_SL_TypesOriginal = result;

        });
      });

      
    }

  }

  deleteProposalCategory(Category, value) {
    if (value == 'Categories') {
      if (Category.isdeleted == 1) {
        var c = confirm("Are you sure you want to undo this Category?");
        if (c == true) {
          this.SLCategory = Category;
          this.SLCategory.isdeleted = 0;
          this.AddOrUpdateProposalCategory(this.SLCategory, "Categories");
        }
      } else {
        var c = confirm("Are you sure you want to delete this Category?");
        if (c == true) {
          this.SLCategory = Category;
          this.SLCategory.isdeleted = 1;
          this.AddOrUpdateProposalCategory(this.SLCategory, "Categories");
        }
      }
    }
    else if (value == 'LicenseTypes') {
      if (Category.isdeleted == 1) {
        var c = confirm("Are you sure you want to undo this License Type?");
        if (c == true) {
          this.SLType = Category;
          this.SLType.isdeleted = 0;
          this.AddOrUpdateProposalCategory(this.SLType, "LicenseTypes");
        }
      } else {
        var c = confirm("Are you sure you want to delete this License Type?");
        if (c == true) {
          this.SLType = Category;
          this.SLType.isdeleted = 1;
          this.AddOrUpdateProposalCategory(this.SLType, "LicenseTypes");
        }
      }
    }
    
  }
}
