import { Component, OnInit ,Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import { Dropdowntype} from '../../models/dropdowntype';
import { Tasklistconfiguration } from 'src/app/models/tasklistconfiguration';
import { Role } from 'src/app/models/Role';
import { CustomerService } from 'src/app/services/customer.service';
@Component({
  selector: 'role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  rolesList: Dropdowntype[] = [];
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  roleids = [];
  currentuser: any;
  newroleid = 0;
  RoleTextSave: boolean = true;
  AddRoleButtons: boolean = true;
  Editing: boolean = false;
  tasklistconfiguration: Tasklistconfiguration = new Tasklistconfiguration();
  EditRoleid: number;
  role: Role = new Role();
  constructor(private userservice: UserService, private toastr: ToastrService, private customerService: CustomerService,) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.GetAllRoles();
  }
  GetAllRoles() {
    this.userservice.GetAllRolesList(this.currentuser.id).then((roles) => {
      this.rolesList = roles;
    });
  }
  edit(jval, tasktype) {

      $(setTimeout(function () {
         
        $('.disableEditable').attr('contenteditable', "false")
        $('.disableEditable').attr('style', "border: 1px solid #A6A4A4;background-color:#f2f2f3;")
        $('.disableEditableButton').attr('style', "border: 1px solid #A6A4A4;background-color:#f2f2f3;")

        $('#' + jval + 'RoleType').attr('contenteditable', "true")
        //if (jval == 0) {
        //  $('#' + tasktype).attr('style', "background-color:#b7e4ff;")
        //}

        //$('.Tr').removeAttr('style');
        // $('.Tr').attr('style', "background-color:yellow;")
        $('#' + jval + 'RoleType').attr('style', "background-color:#b7e4ff;")
        $('#' + jval + 'Roleorder').attr('style', "background-color:#b7e4ff;")

        if (($('#' + jval + tasktype).html()).trim() == 'add item here') {
          $('#' + jval + tasktype).html('')
        }
      }, 200));
      this.Editing = true;
      this.EditRoleid = jval;
    
  }

  AddNewRole() {
    this.RoleTextSave = false;
  }
  SaveRole() {
    if (this.Editing === true) {
      this.role.rolename = $('#' + this.EditRoleid + 'RoleType').html().trim();
      this.role.id = this.EditRoleid;
    } else {
      this.role.rolename = $('#RoleTextBox').val().trim();
    }


    this.userservice.checkduplicateRoleName(this.role.rolename).then(roledata => {
       
      if (roledata == null) {
       this.userservice.SaveRoleName(this.role).then((roles) => {
      this.CancelTaskGroup();
      this.cancelRole();
    });
       
      } else {
        alert("Task Name Already Exist");
        this.newroleid = 0;
        return false;
      }
    });  
    this.Editing = false;
  }
  CancelTaskGroup() {
    this.AddRoleButtons = true;
    this.RoleTextSave = true;
  }
  KeyUp() {
    this.AddRoleButtons = false;
  }
  EditRole() {
    
    this.roleids.forEach(id => {
      if (id.Type == 'todo') {
        var drpDwn = new Dropdowntype();
        drpDwn.category = "To Do";

        drpDwn.taskgroupid = $('#todotaskgroup').val();
        drpDwn.itemName = $('#' + id.Index + 'todoTasktype').html().replace('To Do - ', '');
        drpDwn.id = parseInt($('#' + id.Index + 'todoTaskID').html());
        if (drpDwn.itemName.toString().replace('&nbsp;', '').trim() != '') {
          this.rolesList.push(drpDwn);
        }

      }

    });
    if (this.roleids.length == 0) {
      if ((this.rolesList.filter(f => { f.id == 0 && f.itemName == $('#' + this.newroleid + 'todoTasktype').html() }).length == 0)) {
        var drpDwn = new Dropdowntype();
        drpDwn.category = "To Do";

        drpDwn.taskgroupid = $('#todotaskgroup').val();

        drpDwn.itemName = $('#' + this.newroleid + 'todoTasktype').html().replace('To Do - ', '');
        drpDwn.id = 0;
        if (drpDwn.itemName.toString().replace('&nbsp;', '').trim() != '') {
          this.rolesList.push(drpDwn);
        }
      }
    }
    this.userservice.checkduplicateRoleName(this.rolesList[0].itemName).then(roledata => {

      if (roledata == null) {
        //this.customerService.saveDropdownValue(this.tododropdowns).then(result => {
        //  this.toastr.success(" To Do Task List configured Successfully.", "Task List");
        //  this.newtodoid = 0;
        //  this.totaskids = [];
        //  this.tododropdowns = [];

        //  this.GetAllTasksList(val, $('#todotaskgroup').val(), 0);
        //  this.toDobuttons = true; this.toDoNew = true;
        //   
        //});
         
        $('#todoTasktype').attr('style', "background-color:#808080;color:white");
      } else {
        alert("Task Name Already Exist");
        this.newroleid = 0;
        //this.totaskids = [];
        //this.rol = [];
        return false;
      }
    });  
   }
  cancelRole() {
    $(setTimeout(function () { 
       
      $('.disableEditable').attr('contenteditable', "false")
      $('.disableEditable').attr('style', "border: 1px solid #A6A4A4;background-color:#f2f2f3;")
      $('.disableEditableButton').attr('style', "border: 1px solid #A6A4A4;background-color:#f2f2f3;")

      $('#' + this.EditRoleid + 'RoleType').attr('contenteditable', "true")
    }, 200));
    this.GetAllRoles();
  }
  RoleDelete(roleName,id, status) {
    if (status === '1') {
      $('#' + id + 'todoTasktype').attr('style', "background-color:#b7e4ff;")
      $('#' + id + 'todoTasktypeorder').attr('style', "background-color:#b7e4ff;")
     
      this.sendMsgEvent.emit(id + "|" + status + "|" + roleName);
       
    }
    else {

      $('#' + id + 'todoTasktype').attr('style', "background-color:#b7e4ff;")
      $('#' + id + 'todoTasktypeorder').attr('style', "background-color:#b7e4ff;")
      //var that = this;
      //$(setTimeout(function () {
        let c = confirm("Are you sure you want to do Activate Role?");
        if (c === true) {
          this.userservice.deleteRole(id, status).then((user) => {
            this.toastr.success("Role Activated Successfully.", "Role", { timeOut: 600 });
            this.GetAllRoles();
          });
        }
        else {
          $('#' + id + 'todoTasktype').attr('style', "background-color:none;border:1px solid #A6A4A4")
          $('#' + id + 'todoTasktypeorder').attr('style', "background-color:none;border:1px solid #A6A4A4")
        }
      //}, 200));
    }
  }

  SortOrder() {
    var c = confirm("Are you sure you want to Reorder items in this Order??");
    if (c == true) {
      var RoleIdArr = [];
      $.each($("#RolesTable tbody tr td[id$='RoleID']"), function (k, v) {
        var TaskIds = $.trim($(this).html());
        RoleIdArr.push(TaskIds);
      })
      this.tasklistconfiguration.taskgroupid = 0;
    }

    this.tasklistconfiguration.configureduserid = this.currentuser.id;
    this.tasklistconfiguration.tasklistconfigurtaionorder = RoleIdArr.toString();
    this.tasklistconfiguration.tasktype = 'Roles';
    this.customerService.SaveTaskListConfiguration(this.tasklistconfiguration).then(result => {
      this.toastr.success('Roles' + " Task List Re-Ordered Successfully.", 'Roles' + " Task List", { timeOut: 600 });

    });
  }
  keyupTask(Roleid,index,val) {

  }
}

