import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef, ViewEncapsulation, Renderer2,Optional } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { AccountsService } from '../../services/accounts.service';
import { Accounts } from '../../models/accounts';
import { Accountlocations } from '../../models/data-model';
import { Http } from '@angular/http';
import { ParentComp, UserlistComponent } from "../userlist/userlist.component";
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { NotesrendererComponent } from '../notesrenderer/notesrenderer.component';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { Column } from 'ag-grid';
import { Notes } from '../../models/notes';
import { EditdeleteComponent } from '../editdelete/editdelete.component';
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../application.component';
import * as moment from 'moment'
declare var require: any;
declare var $: any;
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class CustomerComponent {
  resizeheight = (window.innerHeight / 1.577) + 'px';
  notedescription: string;
  itemResource: any;
  currentuser: any;
  items = [];
  itemCount = 0;
  params: any;
  dropdownList = [];
  finalitems = "";
  selectedItems = [];
  term: string = "";
  multiplelocationshowDialog = false;
  accountlocationlist: any;
  account: Accounts = new Accounts();
  notes: Notes = new Notes();
  accountlocations: Accountlocations = new Accountlocations();
  public zipmask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public dobmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  dropdownSetting = {};
  alllocations: boolean = true;
  addnewlocation: boolean = true;
  practiceDiv: boolean = false;
  otherDiv: boolean = true;
  type: string = ''; Ids = [];
  thisComp: ParentComp = ParentComp.Customer;
  isViewInitialized: boolean = false;
  accessDenied: boolean = false;
  notesshowDialog = false;
  notesitems = [];
  notesitemResource: any;
  notesitemCount = 0;
  notesparams: any;
  datePipe: DatePipe = new DatePipe("en-US");
  allCustomers: Accounts[];
  public gridOptions: GridOptions;
  public gridOptionsnotes: GridOptions;
  public rowData: any[];
  public columnDefs: any[];
  public columnDefsnotes: any[];
  pageSize: string = "1000";
  public context;
  public frameworkComponents;
  aggridheight: number = screen.height * 0.6333;
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  allnotes: Notes[];
  allContactsproviders: any[] = [];
  conid: number;
  typ: string; NotesDec = false;
  userInfo: string;
  isShowDetailsChecked: boolean = false;
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false, dayLabels: { su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S' } };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear()-1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear()-1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");

  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false, dayLabels: { su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S' } };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;

  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  timezonevalue: string;
  constructor(public applicationComponent: ApplicationComponent, private elementRef: ElementRef, private renderer: Renderer2, private http: Http, private opprtunityservice: OpprtunitiesService, private route: ActivatedRoute, private accountService: AccountsService, private customerService: CustomerService, private router: Router, private toastr: ToastrService, private viewContainerRef: ViewContainerRef) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    }
    this.viewContainerRef = viewContainerRef;
   // this.toastr.setRootViewContainerRef(viewContainerRef);
  }
  createColumnDefs(columnSelections: string) {
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleArray[3])});
        } else if (fieldHeaderVisibleArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, width: parseInt(fieldHeaderVisibleArray[3])});
        } else if (fieldHeaderVisibleArray[0] == "accnumber") {
          this.columnDefs.push({ field: 'accnumber', headerName: 'Act Number', hide: fieldHeaderVisibleArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleArray[3]) });
          //this.columnDefs.push({ field: 'accnumber', headerName: 'Act Number', hide: fieldHeaderVisibleArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleArray[3]), cellStyle:{ color: 'red'  } });
        }  else if (fieldHeaderVisibleArray[0] == "actions") {
          this.columnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: false, width: parseInt(fieldHeaderVisibleArray[3]), pinned: 'right' });
        } else {
          this.columnDefs.push({
            field: fieldHeaderVisibleArray[0],
            headerName: fieldHeaderVisibleArray[1],
            hide: fieldHeaderVisibleArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
      }
    }
    else {
      this.columnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'id', headerName: 'Id', hide: true },
        { field: 'accnumber', headerName: 'Act Number', hide: false},
        //{ field: 'accnumber', headerName: 'Act Number', hide: false, cellStyle: { color: 'red' } },
        { field: 'accountname', headerName: 'Account Name', hide: false },
        { field: 'speciality', headerName: 'Specialty', hide: false },
        { field: 'address1', headerName: 'Address1', hide: false },
        { field: 'address2', headerName: 'Address2', hide: false },
        { field: 'city', headerName: 'City', hide: false },
        { field: 'state', headerName: 'State', hide: false },
        { field: 'zip', headerName: 'Zip', hide: false },
        { field: 'phone', headerName: 'Phone', hide: true },  //true starts here
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        { field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: false, pinned: 'right' },
      ];
    }
  
  }
  ngAfterViewInit(): void {
   

  }

  onAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
      $(setTimeout(function () {
        selfObj.autoSizeAll();
      }, 100));
    }, 100));
  }
  autoSizeAll() { 
  }
  txtPageSizeChanged(pageSize) {
    this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    this.saveConfiguration();
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
    this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 25;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";
    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }
    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 12;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    this.opprtunityservice.saveDashboardconfiguration(config);
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, suppressMovableColumns: true, rowSelection: 'multiple'
    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 12).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.gridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(columnSelections);
    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent,
      editdeletecv: EditdeleteComponent
    };

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
  }
  rowClickAgGrid(rowEvent) {
    if ((this.Ids).indexOf(rowEvent.data.id) > -1) {
      var index = (this.Ids).indexOf(rowEvent.data.id);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
    } else {
      this.Ids.push(rowEvent.data.id)
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
    }
  }
  rowDoubleClickAgGrid(rowEvent) {
    this.router.navigateByUrl(`/application/account-contact/0/show${rowEvent.data.id}`);
  }
  openNotesDialogAg(rowdata) {
    this.customerService.GetByAccountId(rowdata.id).then(result => {
      this.account = result;
    });
    $('#accotIdNotes').val(rowdata.id);
    $('#accotTypeNotes').val('Customer');
    this.GetCustomerNotes(rowdata.id, 'Customer');
    this.notesshowDialog = !this.notesshowDialog;
     
    this.conid = rowdata.id;
 
    $(setTimeout(function () {
      if ($('#oppNotesId').length > 0)
        $('#oppNotesId').removeClass('active');

      if ($('#leadsNotesId').length > 0)
        $('#leadsNotesId').removeClass('active');

      if ($('#allContactNotesId').length > 0)
        $('#allContactNotesId').removeClass('active');

      if ($('#tskHistNotesId').length > 0)
        $('#tskHistNotesId').removeClass('active');

      if ($('#allNotesId').length > 0) {        
        $('#allNotesId').removeClass('active');
        $('#allNotesId').addClass('active');
      }
      var modalWidth = (screen.width) - ((screen.width) / 10);
      //$('.dialog').attr('style', 'padding:1%;border: 3px solid green;width:' + modalWidth + 'px');
      $('#chkShowDetailsNotes').prop('checked', false); $('#closeDetailsDialog').click();
    }, 100));

    
  }
  openMultiplelocationDialogAg(rowdata) {
    this.multiplelocationshowDialog = !this.multiplelocationshowDialog
    this.alllocations = false;
    this.addnewlocation = true;
    this.opprtunityservice.GetByAccountId(rowdata.id).then(result => {
      this.account = result;
      this.GetAccountlocation(rowdata.id);
    });

  }
  editRowAg(rowdata) {
    this.router.navigateByUrl(`/application/account-contact/0/edit${rowdata.id}`);
  }

  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.createColumnDefsnotes("");
    this.gridOptionsnotes.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  createColumnDefsnotes(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },tooltipField: 'notedescription', hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellStyle: { 'text-align': 'left' }, cellRenderer(params) { return params.value; } }

    ];

    //this.columnDefsnotes = [
    //  {
    //    field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
    //      if (params.value == 'Contact') {
    //        return '<i style="font-weight: 700;font-size: 18px;" class="fa fa-laptop" aria - hidden="true" > </i>';
    //      }
    //      else if (params.value != null && params.value.startsWith("Phone")) {
    //        return '<i style="font-weight: 700;font-size: 18px;" class="fa fa-phone" aria - hidden="true" > </i>';
    //      }
    //      else if (params.value != null && params.value.startsWith("To Do")) {
    //        return 'To-Do';
    //      }
    //      else if (params.value != null && params.value.startsWith("Meeting")) {
    //        return '<i style="font-weight: 700;font-size: 18px;" class="fa fa-calendar" aria - hidden="true" > </i>';
    //      } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
    //        return '<i style="font-weight: 700;font-size: 18px;" class="fa fa-server" aria - hidden="true" > </i>';
    //      }
    //      else {
    //        return '<h5 style="font-weight: 800;font-size: 15px;"> $$ </h5>'
    //      }
    //    }
    //  },
    //  {
    //    field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: false, tooltipField: 'notedescription', cellRenderer(params) {
    //      var eDiv = document.createElement('div');
    //      eDiv.innerHTML = params.value;
    //      eDiv.addEventListener('click', function () {
    //        //selfObj.notes.notedescription = params.value;
    //        //selfObj.NotesDec = !selfObj.NotesDec;
    //        selfObj.openDisplaynotes(params);
    //      });
    //      return eDiv;
    //    }
    //   },
    //  { field: 'created', headerName: 'Date Added', hide: false },
    //  { field: 'status', headerName: 'Completed Y/N', hide: false, cellStyle: function (params) { if (params.value == 'Open') { return { color: 'red' }; } } },
    //  { field: 'user', headerName: 'User Added', hide: false },
    //  { field: 'type', headerName: 'Type', hide: false }

    //];
  }
  onAgGridReadynotes(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllnotes();
      $(setTimeout(function () {
        selfObj.autoSizeAllnotes();
      }, 400));
    }, 400));
  }
  autoSizeAllnotes() {
    var allColumnIds = [];
    if (this.gridOptionsnotes && this.gridOptionsnotes.columnApi) {
      var allCols = this.gridOptionsnotes.columnApi.getAllColumns();
      if (allCols != null) {
        allCols.forEach(function (column) {
          allColumnIds.push(column.getColId());
        });
      }
      this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds);
      //this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds, "autosizeColumns");
    }

  }
  public ngOnInit(): void {
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1,0,0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.dropdownList = Owner;
    })

    if (this.applicationComponent.selectedusers != '' && this.applicationComponent.selectedusers != null && this.applicationComponent.selectedusers != undefined) {
      this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
        Owner.forEach(Owner => {
        }); this.selectedItems = Owner;
      })
    }
    else {
      this.selectedItems = [{ "id": this.currentuser.id, "itemName": this.currentuser.username }];
      this.finalitems += this.currentuser.id + ',';
      //$("#userids").val(this.currentuser.id);
    }
     
    this.route.params.subscribe(params => {
      this.type = params['id'];
      this.term = params['term'];

      //let eleId = this.type;
      if (this.type == 'practice') {
        this.practiceDiv = false;
        this.otherDiv = true;
        if (this.term == undefined) {
           
          this.loadallcustomers(this.type, this.term);
        } else {
           
          this.loadallcustomers(this.type, this.term);
        }
        $(".checkMark").removeClass("activeColor");
        //$(".subButtons").removeClass("activeButton");
        if (document.getElementById('subTab') != undefined && document.getElementById('subTab') != null)
          document.getElementById('subTab').style.display = 'block';
        //$("#practice_button").removeClass("activeButton");
        $("#customers_span").addClass("activeColor");
        $("#customers").addClass("mainactiveButton");
        //$("#practice_button").addClass("activeButton");


        if (this.gridOptions && this.gridOptions.columnApi)
          this.gridOptions.columnApi.moveColumn('accountname', 1);
        else {
          let selfObj: CustomerComponent = this;
          $(setTimeout(function () {

            if (selfObj.gridOptions && selfObj.gridOptions.columnApi)
              selfObj.gridOptions.columnApi.moveColumn('accountname', 1);
          }, 200));
        }
      }
      //else if (this.type == 'recent') {
      //  var thisSubTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == "Recent")[0];
      //  if (thisSubTab.canview == false) {
      //    // this.toastr.setRootViewContainerRef(viewContainerRef);
      //    this.toastr.warning("You Do Not have Access to " + "Recent" + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
      //    return false;
      //  }

      //  this.practiceDiv = true;
      //  this.otherDiv = false;
      //  if ($('.activeButton').length == 0) {
      //    $("#practice_button").removeClass("activeButton");
      //    $("#coustomers_span").addClass("activeColor");
      //    $("#coustomers").addClass("mainactiveButton");
      //    $("#recent_button").addClass("activeButton");
      //  }
      //  }
      //else if (this.type == 'specialty') {
      //  var thisSubTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == "Recent")[0];
      //  if (thisSubTab.canview == false) {
      //    // this.toastr.setRootViewContainerRef(viewContainerRef);
      //    this.toastr.warning("You Do Not have Access to " + "Recent" + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
      //    return false;
      //  }

      //  this.practiceDiv = true;
      //  this.otherDiv = false;
      //  if ($('.activeButton').length == 0) {
      //    $("#practice_button").removeClass("activeButton");
      //    $("#coustomers_span").addClass("activeColor");
      //    $("#coustomers").addClass("mainactiveButton");
      //    $("#specialty_button").addClass("activeButton");
      //  }
      //}
        else {

        this.practiceDiv = true;
        this.otherDiv = false;
        if (this.term == undefined) {
           
          this.loadallcustomers(this.type, this.term);
        } else {
           
          this.loadallcustomers(this.type, this.term);
        }

        //this.applicationComponent.showContactsCustomersSubTabs = false;
        if (document.getElementById('subTab') != undefined && document.getElementById('subTab') != null)
          document.getElementById('subTab').style.display = 'block';
       
        //if ($('.activeButton').length == 0) {
        //  $(".fa-check").removeClass("activeColor");
        //  $("#practice_button").removeClass("activeButton");
        //  $("#a-z").addClass("activeColor");
        //  $("#coustomers_span").addClass("activeColor");
        //  $("#coustomers").addClass("mainactiveButton");
        //  $("#a-z_button").addClass("activeButton");
        //}

        if (this.columnDefs && this.columnDefs.length > 0 && this.allCustomers && this.allCustomers.length > 0) {
          if (this.gridOptions && this.gridOptions.columnApi)
            this.gridOptions.columnApi.moveColumn('accountname', 3);
            //this.gridOptions.columnApi.moveColumn('accountname', 3, "autosizeColumns");
          else {
            let selfObj: CustomerComponent = this;
            $(setTimeout(function () {
              if (selfObj.gridOptions && selfObj.gridOptions.columnApi)
                selfObj.gridOptions.columnApi.moveColumn('accountname', 3);
                //selfObj.gridOptions.columnApi.moveColumn('accountname', 3, "autosizeColumns");
            }, 200));
          }
        }
      }
    });
    //$(".fa-check").removeClass("activeColor");
    //$("#" + this.type).addClass("activeColor");
    //$("#" + this.type + "_button").addClass("activeButton");

    $("#customers_span").addClass("activeColor");
    $("#customers").addClass("mainactiveButton");

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All Users',
      unSelectAllText: 'UnSelect All Users',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.initializeAgGrid();
    this.isViewInitialized = true;
  }
  
  userListOutParamsBinding(outProps) {
    this.finalitems = outProps.finalitems;
    this.items = outProps.items;
    this.itemCount = outProps.itemsCount;
    this.selectedItems = outProps.selectedItems;
  }

  loadallcustomers(type, term) {
     
    var userids = $("#userids").val();
    this.applicationComponent.selectedusers = userids;
    this.opprtunityservice.GetAllAccountsBySearch(this.currentuser.id,term, userids, type, this.startDateVal, this.endDateVal, 1, 100).then((account) => {
      account.forEach(account => {
      });
    //this.opprtunityservice.GetAllAccountsBySearch(this.term != undefined ? this.term : 'All', userids, this.type, this.startDateVal, this.endDateVal, 1, 100).then((account) => {
    //  account.forEach(account => {
    //  });
      this.allCustomers = account;

    });
  }

  GetContactList(term, userids) {
    //var userids = $("#userids").val();
    this.opprtunityservice.GetAllAccountsBySearch(this.currentuser.id,term, userids, this.type).then((account) => {
      account.forEach(account => {
      });
      this.itemCount = account.length;
      //this.itemResource = new DataTableResource(account);
      //this.itemResource.count().then(count => this.itemCount = count);
      //this.params = { offset: 0, limit: 10 }
      //this.reloadItems(this.params);
    });
  }
  Generate(userids) {
    this.GetContactList(this.term, userids);
  }

  //reloadItems(params) {
  //  if (this.itemResource != undefined) {
  //    this.itemResource.query(params).then(items => this.items = items);
  //  }
  //}
  onItemSelect(item: any)
  {
    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
     
    this.loadallcustomers(this.type, this.term);
  }
  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    } else {
      this.allCustomers = [];
      $("#userids").val('');
    }
     
    this.loadallcustomers(this.type, this.term);
  }
  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
     
    this.loadallcustomers(this.type, this.term);
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.allCustomers = [];
    $("#userids").val('');
    this.applicationComponent.selectedusers = null;
    this.loadallcustomers(this.type, this.term);
  }
  
  Close(locationid) {
    document.getElementById('location' + locationid).remove();
    this.customerService.updatemultiplelocation(locationid).then((user) => { });
    //this.GetAccountlocation(this.account.id);
  }
  //OpenMultiplelocation(rowEvent) {
  //  this.multiplelocationshowDialog = !this.multiplelocationshowDialog
  //  this.alllocations = false;
  //  this.addnewlocation = true;
  //  this.opprtunityservice.GetByAccountId(rowEvent.id).then(result => {
  //    this.account = result;
  //    this.GetAccountlocation(rowEvent.id);
  //  });

  //}
  GetAccountlocation(accid) {
    //this.accountlocationlist = null;
    this.customerService.GetLocationByAccountId(accid).then(result => {
      result.forEach(Owner => {
      });
      this.accountlocationlist = result;
    });
  }

  NewLocation() {
     
    this.alllocations = true;
    this.addnewlocation = false;
    document.getElementById('alllocations').style.display = 'none';
    document.getElementById('addnewlocation').style.display = 'block';
  }
  SaveLocation() {
    document.getElementById('alllocations').style.display = 'block';
    document.getElementById('addnewlocation').style.display = 'none';
  }
  Cancel() {
    this.alllocations = false;
    this.addnewlocation = true;
    document.getElementById('alllocations').style.display = 'block';
    document.getElementById('addnewlocation').style.display = 'none';
  }

  GetCustomerNotes(contactid, type) {

    this.customerService.GetAllNotesList(this.currentuser.id,contactid, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.initializeAgGridNotes(); this.allnotes = taskhistory;    
    });

  }

  //Notes(val)
  //{
  //  alert(val)
  //  if (val == 'AllContactsNotes') {
  //    this.customerService.GetAllContactproviderByAccount(this.account.id).then((contacts) => {
  //      contacts.forEach(contacts => {
  //        this.allContactsproviders.push(contacts.id)
  //      });
  //      this.GetCustomerNotes(this.allContactsproviders, val);
  //    });
  //  } else {
  //    this.GetCustomerNotes(this.account.id, val);
  //  }
  //}
  enterNotes() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'block';
    document.getElementById('saveNotesButtons').style.display = 'block';
    document.getElementById('divTable').style.display = 'none';
  }
  SaveNote() {
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/img, "");
    this.notes.notedescription = this.notes.notedescription.replace(/<[^>]*>/g, '');
    this.notes.type = "Customer";
    this.notes.accountid = this.account.id;

    this.customerService.SaveNotes(this.notes).then(result => {
      this.GetCustomerNotes(this.notes.accountid, 'Contact');
      $('#notesListTabsId .active').removeClass('active');
      $('#notesListTabsId #allNotesId').addClass('active');
      this.CancelNote();
    });
  }
  CancelNote() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'none';
    document.getElementById('saveNotesButtons').style.display = 'none';
    document.getElementById('divTable').style.display = 'block';
  }
 

  dateChange(event) {
    this.AssignStartAndEndDate(event);
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
     
    this.loadallcustomers(this.type, this.term);
  }
  AssignStartAndEndDate(event) {

    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }

  openDisplaynotes(params) {
   // this.notes.notedescription = params.value;
    this.NotesDec = !this.NotesDec;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    var notesOffset = $("#divNotesContainer").offset();
    let notesWidth = $('#divNotesContainer').width();
    let notesHeight = $('#divNotesContainer').height();
    let windowWidth = $(window).width();
    let showDialogWidth = 350;
    $(setTimeout(function () {
       
      $('#showDetailsDialog').width(showDialogWidth);
      $('#showDetailsDialog').height(notesHeight - 101);
      $('#showDetailsDialog').css('top', notesOffset.top + 94);
      $('#showDetailsDialog').css('left', notesWidth - showDialogWidth);
      $('#showDetailsDialog').css('border-radius', '4px');
      $('#showDetailsDialog').css('border', '3px solid');
      $('#showDetailsDialog').css('overflow', 'auto');
      if ($('input[name=Notes]').is(':checked')) {
        $('#txtLongNoteDesc').css('height', '81%');
        $('#txtLongNoteDesc').css('padding-top', '0%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
        $('#txtUserFullName').css('height', '14%');
      } else {
        $('#txtLongNoteDesc').css('height', '96%');
        $('#txtLongNoteDesc').css('padding-top', '6%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
        $('#txtLongNoteDesc').css('overflow', 'auto');
      }
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));
  }
  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
    //if ($("#btnEditNote").text() == "Edit Note") {
    //  $("#txtLongNoteDesc").removeAttr("readonly");
    //  $("#txtLongNoteDesc").css("background-color", "white");
    //  $("#btnEditNote").css("background-color", "#A2E477");
    //  $("#btnEditNote").text("Save Note");
    //} else {
    //  this.saveEditedNote();
    //}

     
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();



  }
  saveEditedNote() {


    // 
    //let desc: string = $("#txtLongNoteDesc").val();
    //let selfObj = this;
    //this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
    //  if (success) {
    //    selfObj.toastr.success("Note updated successfully.", "Note Updated");
    //    selfObj.closeDetailsDialog();
    //    selfObj.GetCustomerNotes(selfObj.conid, 'Customer');
    //  } else {
    //    selfObj.toastr.success("There was some error saving note.", "Updated Failed");
    //  }
    //});
    ////this.Notes(this.selectedTabName);

    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.GetCustomerNotes(selfObj.conid, 'Customer');
        selfObj.closeDetailsDialog();

      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
  }
}


