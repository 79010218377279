import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import * as XLSX from 'ts-xlsx';
declare var $: any;
@Component({
  selector: 'page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {
  @Input()
  total: number;
  @Input()
  totalpagesize: number;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  selectedItems = [];
  arrayBuffer: any;
  file: File;

 public currentPage: number = 1;
public startrow: number = 1;
  public noofRows: number = 1;
  public totalnumberofrows: number = 1;
  public totalnumberofPages: number = 21;
  constructor() { }

  ngOnInit() {
   // alert(this.totalpagesize "k"""+ this.total);
    this.totalnumberofrows = this.total;
  }

  ngOnChanges() {
     
  }
  incomingfile(event) {
    this.file = event.target.files[0];   
  }

  Upload() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
    }
    fileReader.readAsArrayBuffer(this.file);
  }
  Paginationfirst() {
    this.sendMsgEvent.emit("first");
  }
  Paginationprev() {
    this.sendMsgEvent.emit("prev");
  }
  Paginationnext() {
    this.sendMsgEvent.emit("next");
  }
  Paginationlast() {
    this.sendMsgEvent.emit("last");
  }
}
