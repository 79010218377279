<div class="col-xs-12" style="background-color:orangered;height:2%">

</div>

<div class="col-xs-2" style="padding: 0.5%; height: 98%; border: 1px solid lightgray; width: 13%" *ngIf="pageFrom != 'Campaigns'">

  <div class="col-xs-12" style="height:7%;padding:0;">
    <div class="col-xs-6" style="padding:0;height:100%">

      <img src="../../assets/img/Contact Selected - Green.png" style="height: 35px;width: 35px;" *ngIf="RHxContactSelected" (click)="RHxTab('Contacts')">
      <img src="../../assets/img/Contact Not Selected - Grey.png" style="height: 35px;width: 35px;" *ngIf="!RHxContactSelected" (click)="RHxTab('Contacts')">

    </div>
    <div class="col-xs-6" style="padding:0;height:100%">

      <img src="../../assets/img/Accounts Not Selected - Grey.png" style="height: 35px;width: 35px;" *ngIf="!RHxAccountSelected" (click)="RHxTab('Accounts')">
      <img src="../../assets/img/Accounts Selected - Green.png" style="height: 35px;width: 35px;" *ngIf="RHxAccountSelected" (click)="RHxTab('Accounts')">
    </div>

  </div>
  <div class="col-xs-12" style="padding:0;height:87%">

    <div class="col-xs-12 scroll-c" style="height:100%;margin-top: 5%;padding:0;">
      <ul class="nav menu" style="padding:0;height:100%">
        <li *ngFor="let item of RHxNames" (click)="OpenAccountOrContact(item)" style="height:8%">
          <!--(click)="OpenAccountOrContact(item)"-->
          <a routerLinkActive="active">
            <span>{{item.UserName}}</span>
          </a>
        </li>
      </ul>

    </div>
  </div>
</div>

<div class="col-xs-9" style="padding:0;height:98%;width:86%">
  <div class="col-xs-12" id="rdtAddTask" style="flex:auto;padding:0;margin:0;width:100%;background:white;padding-bottom:0.5%;height:7%">

    <div class="col-xs-12" style="padding:  0;margin-top:0.5%" *ngIf="pageFrom != 'Campaigns'">
      <div class="col-xs-4">
        <div class="col-xs-3" style="padding-left:  0;">
          <label style="padding-top:5px">User List</label>
        </div>
        <div class="col-xs-9" style="padding: 0%;padding-right:0;">
          <angular2-multiselect [data]="dropdownLists" [(ngModel)]="selectedItems" id="Searchh"
                                [settings]="dropdownSetting"
                                (onSelect)="onItemSelect($event)"
                                (onDeSelect)="OnItemDeSelect($event)"
                                (onSelectAll)="onSelectAll($event)"
                                (onDeSelectAll)="onDeSelectAll($event)">
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-xs-5">
        <div class="col-xs-8" style="padding:0;">
          <input class="form-control " placeholder="Enter Contact Name or Account Name" style="width: 100%; border: 1px solid; border-radius: 4px;" id="term" type="text" [(ngModel)]="term" (keyup.enter)="Search(this.searchvalues.includecustomer ? true : false)" />
        </div>
        <div class="col-xs-4" style="padding-right:2%">

          <img src="../../../assets/img/Scheduler Search.png" (click)="Search(this.searchvalues.includecustomer ? true : false)" style=" height: 35px;margin-top:-4%" />
        </div>

      </div>
      <div class="col-xs-2" style="padding: 0% 0 1% 0;">
        <img src="../../../assets/img/BlockTimeIcon.png" style=" height: 30px;" (click)="BTClick()" />
      </div>


    </div>
    <div class="col-xs-12" style="padding:  0;margin-top:0.5%" *ngIf="pageFrom == 'Campaigns'">
      <div class="col-xs-4" style="padding:0;">
        <div class="col-xs-3" style="padding-left:  0;">
          <label style="padding-top:5px">User List</label>
        </div>
        <div class="col-xs-9" style="padding: 0%;padding-right:0;">
          <angular2-multiselect [data]="dropdownLists" [(ngModel)]="selectedItems"
                                [settings]="dropdownSetting"
                                (onSelect)="onItemSelect($event)"
                                (onDeSelect)="OnItemDeSelect($event)"
                                (onSelectAll)="onSelectAll($event)"
                                (onDeSelectAll)="onDeSelectAll($event)">
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-xs-4">
        <div class="col-xs-7" style="padding:0;">
          <input class="form-control " placeholder="Enter Contact Name or Account Name" style="width: 100%; border: 1px solid; border-radius: 4px;" id="term" type="text" [(ngModel)]="term" (keyup.enter)="Search(this.searchvalues.includecustomer ? true : false)" />

        </div>
        <div class="col-xs-4" style="padding-right:2%">
          <img src="../../../assets/img/Scheduler Search.png" (click)="Search(this.searchvalues.includecustomer ? true : false)" style=" height: 30px;margin-top:-4%" />
        </div>

      </div>


    </div>

  </div>

  <div class="col-xs-12" id="seaechdata12" style="padding:0;overflow:hidden;height:93% ">

    <div class="col-xs-12" style="padding:0;height:100%">
      <div class="col-xs-5" style="padding:0; margin-top:1%;width: 49%;height:98%" *ngIf="!hideGlobalSearchtable1">
        <ag-grid-angular style="width: 100%;height:100%" class="ag-theme-balham"
                         [gridOptions]="globalGridOptions"
                         [columnDefs]="globalColumnDefs"
                         [defaultColDef]="applicationComponent.defaultGridColDef"
                         [rowData]="tableData"
                         [sideBar]="sideBar"
                         [context]="context"
                         (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                         (rowSelected)="rowClickAgGrid($event)"
                         (gridReady)="onAgGridReady()">
        </ag-grid-angular>
      </div>

      <div *ngIf="(condashboardShow)" class="col-xs-6" style="padding:0;margin-top:0.75%;height:100%;">
        <account-contact-info [accountId]="this.contactid" [Type]="this.type" [tab]="'Search'" [PageFrom]="'Tickets'" (sendMsgEvent)="onMessageReceived($event)"></account-contact-info>
        <div *ngIf="(!hideGlobalSearchtable1)" class="col-xs-6" style="padding-right:0; display:none;margin-top:1%" id="accountViewSearch">
          <div class="col-xs-12" style="text-align: left; padding-left:0.5%;padding-right:0; ">
            <div class="col-xs-12 col-sm-12 col-md-12" style="padding: 1%;background-color: lightgray;padding-bottom: 0;">
              <span>ACCOUNT VIEW</span>
            </div>
            <div id="accountInformation" class="col-xs-12" style="padding-right:0; height:151px !important;padding-left:0.5%; display:block">
              <div>
                <div class="col-xs-12 col-sm-12 col-md-12" style="padding:0;border-radius:3px;">
                  <div class="col-xs-12" style="padding:0">
                    <b class="providerLabel" style="font-size:14px;color:red;">{{account.accountname}}</b>
                    <div class="col-xs-12 col-sm-12 col-md-4 pull-right" style="text-align: right;padding-right: 0;">
                      Owner : <label id="ownerlabel">  {{this.currentuser.username}}</label>
                    </div>
                  </div>
                  <div class="col-xs-6 scroll-c" style="height:100px;padding:0">
                    <div class="providerLabel">{{account.address1}}</div>
                    <div>{{account.address2}}</div>
                    <div>
                      <span>{{account.city}} , {{account.state}}.  {{account.zip | slice:0:5}}<span *ngIf="account.zip && account.zip.length > 5">-{{account.zip | slice:5:20}}</span> ,{{account.country}}</span>

                    </div>
                    <div>
                      <span *ngIf="account.phone!=null && account.phone!=''">Phone : {{account.phone}}</span>
                      <span *ngIf="(account.phone!=null && account.phone!='') && (account.fax!=null && account.fax!='')">, </span>
                      <span *ngIf="account.fax!=null && account.fax!=''">Fax: {{account.fax}}</span>
                    </div>
                    <div>
                      <span *ngIf="account.website != null && account.website!=''">{{account.website}},&nbsp;&nbsp;</span>
                      <span *ngIf="account.multiplelocations === 0">Multiple Locations: No</span>
                      <span *ngIf="account.multiplelocations !== 0">Multiple Locations: Yes</span>
                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div class="col-xs-12" id="providerDetails" style="display:block; margin-top:0%;padding:0">
              <div class="otherCss col-xs-12" style="padding-left:0;padding-right:0;padding-top:0">
                <span class="col-xs-9" style="text-align:left; font-size:12px;padding: 0">Contacts ( {{ContactsprovidersitemCount}} )</span>
              </div>
              <div class="col-xs-12" style="padding:0;overflow:auto">
                <ag-grid-angular style="width: 100%; padding-top: 0px;" class="ag-theme-balham" [style.height.px]="ContactGridHeight" [style.width.%]="ContactGrigwidth"
                                 [gridOptions]="gridOptionsProvider"
                                 [columnDefs]="columnDefsProvider"
                                 [defaultColDef]="applicationComponent.defaultGridColDef"
                                 [rowData]="allContactsproviders"
                                 [context]="context"
                                 [sideBar]="sideBar"
                                 (gridReady)="onAgGridReadyProvider($event)">
                </ag-grid-angular>
              </div>

            </div>

            <div id="notesinfo" style="display:none">
              <div class="col-xs-12 pull-right" style="text-align:right">
                <span style="color: gray;font-size:12px; font-weight: normal;">X</span>
              </div>
              <div class="col-xs-12 " style="text-align:left;padding:0;">

                <!--<app-notes *ngIf="!isModal" style="height:100%" [title]="'Contact Notes Info'"
                           [source]="'contact'"
                           [account]="account"
                           [gridOptionsnotes]="gridOptionsnotes"
                           [allnotes]="allnotes"
                           [context]="context"
                           [columnDefsnotes]="columnDefsnotes"
                           [contact]="contacts"
                           [tabName]="'Contact'"
                           [contactid]="contactid"
                           [conid]="conid"
                           [typ]="typ"
                           [isModal]="isModal"
                           [type]="'Contact'">
                </app-notes>-->
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>





<app-dialog [(visible)]="NoResult" *ngIf="NoResult" [closable]="false" id="NoResult">
  <div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
    <span style="font-size:15px;">Search</span><br />
    <div style="padding:0;margin-top:1%">
      <span style="font-size:15px;">No Results Found</span><br />
    </div>
    <button class="btn" (click)="NoResult=!NoResult" style="float:right;background-color: #0098f7 !important;color: white;margin-top:-5%">OK</button>
  </div>

</app-dialog>
