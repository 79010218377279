import { Component, OnInit, Input, Output, ViewChild, EventEmitter, HostListener, ElementRef, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../application.component';
import * as moment from 'moment'
import { GridOptions, Column } from 'ag-grid';
import { DatePipe, Location } from '@angular/common';
declare var $: any;
import { NotesComponent } from '../notes/notes.component';
import { Dropdowntype, Dropdown } from 'src/app/models/dropdowntype';
import { Accounts } from 'src/app/models/accounts';
import { ProposalItems } from 'src/app/models/ProposalItems';
import { Proposals } from 'src/app/models/Praposals';
import { ProposalsModel } from 'src/app/models/Proposalsmodel';
import { Notes } from 'src/app/models/notes';
import { OpprtunitiesService } from 'src/app/services/opprtunities.service';
import { CustomerService } from 'src/app/services/customer.service';
import { UserService } from 'src/app/services/user.service';
import { Dashboardconfiguration } from 'src/app/models/dashboardconfiguration';
import { AccountsService } from 'src/app/services/accounts.service';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { Viewaccount } from '../../models/viewaccount';
import { OwnershipsharingComponent } from '../ownershipsharing/ownershipsharing.component';
import { ContactsdashboardComponent } from '../contacts/contactsdashboard/contactsdashboard.component';
import { AuditLogModel } from '../../models/auditlog';
import { AccountDetailsComponent } from '../account-details/account-details.component';
import { OpportunityFields, OpportunityItems } from '../../models/OpportunityLeadTypes';
import { Contacts } from '../../models/contacts';
declare var require: any;
@Component({
  selector: 'app-proposals',
  templateUrl: './proposals.component.html',
  styleUrls: ['./proposals.component.css']
})

export class ProposalsComponent implements OnInit {
  name = 'Angular';
  dropdownList = [];
  currentuser: any;
  @Input()
  Type: string;
  @Input()
  PageFrom: string;
  @Input()
  redirectFrom: string;
  @Input()
  ContactOrAccountType: string;
  @Input()
  ProposalID: number;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  datePipe: DatePipe = new DatePipe("en-US");
  TodaysDate = new Date();
  opprtunityform: FormGroup;
  contactList: Dropdowntype[] = [];
  categories: Dropdowntype[] = [{ id: null, itemName: 'Display All Rows', category: '', isdeleted: 0, taskgroupid: 0 }];
  opprtunityitemlist: Dropdowntype[] = [];
  pclist: Dropdowntype[] = [];
  drlist: Dropdowntype[] = [];
  midlevelslist: Dropdowntype[] = [];
  leadfromlist: OpportunityItems[] = [];
  OwnerList: Dropdowntype[] = [];
  allProposalItems: ProposalItems[];
  allProposalItemsDuplicate: ProposalItems[];
  finalitems = "";
  public columnDefs: any[];
  public account: Accounts = new Accounts();
  @Input()
  SelectedContactId: number;
  enableUnits: boolean = true;
  TotalProposalPrice: number = 0;
  MonthlyTotalProposalPrice: number = 0;
  TotalProposalPriceString: string = '0';
  MonthlyTotalProposalPriceString: string = '0';
  ProposalTableExpand: boolean = false;
  SaveConfirmationDialog: boolean = false;
  FormValidationDialog: boolean = false;
  ClosePendProposal: boolean = false;
  isPended: number = 1;
  PropId: number;
  Proposal: Proposals = new Proposals();
  showpdf: boolean = false;
  LastProposalDate: Date;
  OpportunitiesCount: number = 0;
  public gridOptions: GridOptions;
  public gridHistoryOptions: GridOptions;
  AllProposals: ProposalsModel[];
  AllProposalsHistory: ProposalsModel[];
  AllAccountProposals: Proposals[];
  PendedProposals: any[];
  Pended: ProposalsModel[];
  PendedConPro: ProposalsModel[];
  ShowPdfDiv: boolean = false;
  ExpandPDFDiv: boolean = false;
  ExpandPreviewPDFDiv: boolean = false;
  SelectedProposalItems = [];
  selectedItems = [];
  selectedCategoryIDs = [];
  selectedLeadFrom = '';
  ProposalDocumentGenerated: boolean = false;
  ClosePendProposalDialog: boolean = false;
  PODocumentGenerated: boolean = false;
  DocumentWarningDialog: boolean = false;
  pdfAddress: string;
  @ViewChild(NotesComponent) noteComp: NotesComponent;
  @ViewChild(ContactsdashboardComponent) contDashComp;
  public gridOptionsnotes: GridOptions;
  public columnDefsnotes: any[];
  PreviewDocuments: boolean = false;
  allnotes: Array<Notes> = [];
  isModal: boolean = false;
  notesshowDialog = false;
  conid: number;
  calanderonoff: boolean = false;
  timezonevalue: string;
  public context;
  ShowPercentDiscountBox = false;
  pageSize: string = "";
  colSelections: string;
  AccountIdsDialog;
  AccountIdArr: Dropdown[];
  tab;
  expanded: boolean = false;
  public sideBar;
  DisplayrowsList: Dropdowntype[] = [];
  NumberOfMonths: number=12;
  public pendedProposalsCount: number = 0;
  ProposalPageChanges: boolean = false;
  DiasbleSaveButton: boolean = false;
  //ShowLoader: boolean = false;
  public elementRef;
  public CloseAddProposal: boolean = false;
  today = Date.now();
  OpenProposalCount: number = 0; PendedProposalCount: number = 0; WonProposalCount: number = 0; LostProposalCount: number = 0;
  @ViewChild('mycal') myDiv: ElementRef<HTMLElement>;
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear()) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");

  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");
  private gridApi;
  viewaccount: Viewaccount = new Viewaccount();
  action: string;
  showAddEditAccount: boolean = false;
  showAddEditContact: boolean = false;
  isSharing: boolean = false;
  @ViewChild(AccountDetailsComponent) accdetailcmp;
  @ViewChild(OwnershipsharingComponent) ownrshipComp: OwnershipsharingComponent;
  rightMainDivHideShow: boolean = true;
  accountSharingid: number = 0;
  pendedval: number = 0;
  AddContacttableDiv: boolean = false;
  userprevilages: any;
  dropdownSetting = {};
  Displaysize = [{ id: null, itemName: 'Display All Rows', category: '', isdeleted: 0, taskgroupid: 0 }];
  MinmumOneItemAlert: boolean = false;
  MandatoryFieldAlert: boolean = false;
  PendProposal: boolean = false;
  PendedProposalsDialog: boolean = false;
  DeleteConfirmation: boolean = false;
  CancelProposal: boolean = false;
  ProposalType = "";
  ContactDetails: boolean = false;
  @HostListener('document:click', ['$event'])
  clickout(event) {

    if (this.eRef.nativeElement.contains(event.target)) {
    } else {
      var cbtn = document.getElementById('appComponentContent');
      var accordion = document.getElementById('accordion');
      var SideMenu = document.getElementById('SideMenu');
      var collapsed = document.getElementsByClassName('collapsed');
      //if ((event.path.includes(cbtn) || event.path.includes(accordion) || event.path.includes(collapsed)) && this.ProposalPageChanges == true) {
      //  this.PendProposal = true;
      //  this.PendProposalCss('PendProposal');
      //  event.preventdefault();
      //  event.stoppropagation();
      //}
    }
  }
  contact: Contacts = new Contacts();
  Fields: any;
  OpportunityFieldsList: OpportunityFields[] = [];
  NewForm = new FormGroup({});
  FormDataModified: boolean = false; EnableSearch: boolean = false; value = "";
  constructor(private location: Location, private route: ActivatedRoute, private router: Router, private customerService: CustomerService, public applicationComponent: ApplicationComponent, private eRef: ElementRef, myElement: ElementRef,
    private opprtunityservice: OpprtunitiesService, private userService: UserService, private toastr: ToastrService, private accountService: AccountsService) {
    //defaultOptions.workerSrc = './assets/pdf.worker-es5.js';
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    
    this.applicationComponent.selectedItems = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    if (this.applicationComponent.selectedItems != null && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {
      this.getSelectedUserList(this.currentuser.id);
    }

    this.elementRef = myElement;
  }


  PendProposalCss(val) {
    if (val === 'PendProposal') {
      this.applicationComponent.salesxcrmDialog('#PendProposal .dialog');
      //setTimeout(function () {
      //  $('#PendProposal .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
      //}, 4)
    } else if (val === 'CancelProposal') {
      this.applicationComponent.salesxcrmDialog('#CancelProposal .dialog');
      //setTimeout(function () {
      //  $('#CancelProposal .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
      //}, 4)
    }

  }

  getSelectedUserList(val) {

    this.applicationComponent.selectedItems = [
      { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
    ];
    localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
    $("#userids").val(this.currentuser.id);
    this.applicationComponent.ExpandSelectedMenu('Proposals');
  }
  handler(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  ClosePropDiv() {
    this.ContactDetails = false;
  }
  ngOnInit() {
    this.getProjectFields();
    this.dropdownSetting = {
      singleSelection: true,
      text: "Display All Rows",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 1
    };
    this.initializeAgGrid();
    if (this.PageFrom != 'Leads') {
      this.route.params.subscribe(params => {
        if (params) {
          this.SelectedContactId = (params['id']) ? params['id'] : this.SelectedContactId;
          this.Type = (params['type']) ? params['type'] : this.Type;
          this.ContactOrAccountType = (params['ConOrAccType']) ? params['ConOrAccType'] : this.ContactOrAccountType;
          this.ProposalID = (params['ProposalId']) ? params['ProposalId'] : this.ProposalID;
        }
        if (this.ContactOrAccountType == "Contact") {
          this.Proposal.opportunities.type = 'Contacts';
          this.customerService.LoadContactById(this.SelectedContactId).then(result => {
            this.contact = result;
            this.Proposal.opportunities.ownerid = result.ownerid;
            this.Proposal.ownerid = result.ownerid;
          });

        } else {
          this.Proposal.opportunities.type = this.ContactOrAccountType;
          this.customerService.loadaccountdata(this.SelectedContactId).then(result => {
            this.account = result;
            this.Proposal.opportunities.ownerid = this.account.ownerid;
            this.Proposal.ownerid = this.account.ownerid;
          });
        }


        if ((this.ProposalID != null || this.ProposalID != 0) && this.account.id != null) {
          this.GetAllProposalByAccount(this.account.id);
          this.GetPendedProposalByContact(this.SelectedContactId);
        }
        if (this.Type != 'Add') {
          if (this.calanderonoff == false) {
            $(setTimeout(function () {
              $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
              $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
            }, 500));
          } else {
            $(setTimeout(function () {
              $('.mydp .selection').attr('style', 'background-color:white !important;');
              $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
            }, 500));
          }
          this.selectedItems = [
            { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
          ];
          this.finalitems += this.currentuser.id + ',';
          if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
            this.GetAllProposal($("#userids").val());
         
            this.DiscountSelection('12');
          } else {
            this.GetAllProposal(0);
           
            this.DiscountSelection('12');
          }

        }


      });
    }


    this.GetAllaccesusersListbyuser();
    //this.Type = 'Add';
    //this.ContactOrAccountType = 'Contact';
    //this.SelectedContactId = 471;
    if (this.Type == 'Add') {
      this.loadDropDowns();
      if (this.ContactOrAccountType == 'Contact') {
        this.LoadAccountandContacts(this.SelectedContactId);
      }
      else {
        this.LoadAccountandContactsByAccountID(this.SelectedContactId);
      }
 
      this.GetAllProposalItems();
      this.Proposal.opportunities.status = "108";
      this.Proposal.opportunities.accountorcontactid = this.SelectedContactId;

      this.Resetfields();
      //this.opprtunityform = new FormGroup({
      //  'item': new FormControl(this.Proposal.opportunities.item, Validators.required),
      //  'ProposalDescription': new FormControl(this.Proposal.opportunities.ProposalDescription, Validators.required),
      //  'LeadDescription': new FormControl(this.Proposal.opportunities.LeadDescription, Validators.required),
      //  'estimatedclosedate': new FormControl(this.Proposal.opportunities.estimatedclosedate, Validators.required),
      //  'PC': new FormControl(this.Proposal.opportunities.PC, Validators.required),
      //  'drs': new FormControl(this.Proposal.opportunities.drs, Validators.required),
      //  'midlevels': new FormControl(this.Proposal.opportunities.midlevels, Validators.required),
      //  'leadfromid': new FormControl(this.Proposal.opportunities.leadfromid, Validators.required),
      //  'ownerid': new FormControl(this.Proposal.opportunities.ownerid, Validators.required),
      //  'status': new FormControl(this.Proposal.opportunities.status, Validators.required)
      //});
    }
    else {
      if (this.calanderonoff == false) {
        $(setTimeout(function () {
          $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
          $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
        }, 500));
      } else {
        $(setTimeout(function () {
          $('.mydp .selection').attr('style', 'background-color:white !important;');
          $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
        }, 500));
      }
      this.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
      ];
      this.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
      ];
      this.finalitems += this.currentuser.id + ',';
      //$("#userids").val(this.currentuser.id);
      if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
        this.GetAllProposal($("#userids").val());       
        this.DiscountSelection('12');
      } else {
        this.GetAllProposal(0);      
        this.DiscountSelection('12');
      }


    }
    let selfObj = this;
    $(setTimeout(function () {
      let proposalPdfId = localStorage.getItem('proposalPdfId');
      let str = localStorage.getItem('redirectfrom');
      if (str == 'dashboard') {
        localStorage.setItem('proposalPdfId', '');
        localStorage.setItem('redirectfrom', '');
        selfObj.PDFClick(proposalPdfId, 'Proposal');

        let selfo = selfObj;
        $(setTimeout(function () {
          selfo.gridOptions.api.forEachNode((node) => {
            if (node.data.ProposalPDF == proposalPdfId || node.data.ProposalPOPDF == proposalPdfId) {
              selfo.gridOptions.api.selectNode(node, true);
            }
          });
        }, 200));

      }
    }, 400));

  }

  ngAfterViewInit(): void {
    if (this.currentuser.proposalSelection != null && this.currentuser.proposalSelection > 0) {
      this.DiscountSelection(this.currentuser.proposalSelection.toString());
    }
  }
  GetPendedProposalByContact(conId) {
    this.userService.GetPendedProposalsByAccount(conId).then(res => {
      this.PendedConPro = res;
    });
  }
  UpdateSearchColumns(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[] = columnSelections.split(',');
    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');

      if ((fields[2] == 'true' && fields[1] != '#') && (fields[2] == 'true' && fields[0] != 'notes') && (fields[2] == 'true' && fields[0] != 'actions')) {

        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }
    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];
    if (tempTableColumns && tempTableColumns.length > 0) {
      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })

    }
    else {
      this.accountService.GetDefaultColumns('ContactsAccount', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.applicationComponent.SearchSelectedItems = [
            { "id": 'AccountName', "itemName": 'Account' }
          ];
        }
      });
    }
  }

  Resetfields() {
    this.Proposal.ProposalIds = '';
    if (this.ContactOrAccountType == "Contact") {
      this.Proposal.opportunities.accountorcontactid = this.SelectedContactId;
      this.customerService.LoadContactById(this.SelectedContactId).then(result => {
        this.contact = result;
        this.Proposal.opportunities.ownerid = result.ownerid;
        this.Proposal.ownerid = result.ownerid;
      });
    } else {
      this.Proposal.opportunities.accountorcontactid = null;
      this.customerService.loadaccountdata(this.SelectedContactId).then(result => {
        this.account = result;
        this.Proposal.opportunities.ownerid = this.account.ownerid;
        this.Proposal.ownerid = this.account.ownerid;
      });
    }

    this.Proposal.opportunities.item = null;
    this.Proposal.opportunities.PC = null;
    this.Proposal.opportunities.drs = null;
    this.Proposal.opportunities.midlevels = null;
    this.Proposal.opportunities.leadfromid = null;
    //this.Proposal.opportunities.ownerid = this.currentuser.id;
  }

  getallNotes() {
    this.customerService.GetAllNotesList(this.currentuser.id, this.SelectedContactId.toString(), "Contact").then((notes) => {
      notes.forEach(notes => {
        var moment = require('moment-timezone');
        var timeDisplay = notes.user + '<br />' + moment.tz(notes.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(notes.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        notes.userfullName = timeDisplay;
        notes.created = this.datePipe.transform(notes.created, "MM/dd/yyyy");
      });
      this.initializeAgGridNotes();
      this.allnotes = notes;
    });

  }




  PreviewProposal() {
    $('#PreviewProposal').attr('style', 'display:block;padding:0;background:white;')

    $(setTimeout(function () {
      $('#PreviewProposal').attr('style', 'height:100%;display:none;')
    }, 300));
  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.gridHistoryOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 54).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        //this.gridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();

      }

      this.colSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = config != null && config.columnsselections != null ? config.columnsselections : "";

      //this.startDateVal = (config != null && config.startdate != null) ? config.startdate : (new Date().getFullYear() - 1) + '-01-01';
      //this.startDatePlaceHolder = (config != null && config.startdate != null) ? 'Start:' + moment(config.startdate).format("MM-DD-YYYY") : 'Start: 01-01-' + (new Date().getFullYear() - 1).toString();
      //this.endDateVal = (config != null && config.enddate != null) ? config.enddate : new Date().getFullYear() + '-12-31';
      //this.endDatePlaceHolder = (config != null && config.enddate != null) ? 'End:' + moment(config.enddate).format("MM-DD-YYYY") : 'End: 12-31-' + (new Date().getFullYear() - 1).toString();

      this.pageSize = (this.pageSize == "") ? '100' : this.pageSize;

      this.createColumnDefs(this.colSelections);
      this.UpdateSearchColumns(this.colSelections)


    });



  }

  createColumnDefs(columnSelections: string) {
    var searcharray = [];
    var searcharray = [];
    if (this.gridOptions.quickFilterText != null && this.gridOptions.quickFilterText != undefined && this.gridOptions.quickFilterText != '') {
      searcharray.push(this.gridOptions.quickFilterText);
    }
    let columnSelectionsArray: string[] = [];
    let selfobj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      selfobj.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          selfobj.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "ContactName") {
          selfobj.columnDefs.push({
            field: 'ContactName', headerName: 'Contact Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "AccountName") {
          selfobj.columnDefs.push({
            field: 'AccountName', headerName: 'Account', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "accPhone") {
          selfobj.columnDefs.push({
            field: 'accPhone', headerName: 'Phone', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "isPended") {
          this.columnDefs.push({
            field: 'isPended', headerName: 'Pended', hide: false, cellRenderer: function (params) {
              if (params.data.isPended == '1' || params.data.isPended == 1) { return 'Pended'; }
            }, cellStyle: function (params) { return { color: '#FF0000' }; }
            
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "action") {
          this.columnDefs.push({
            field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right'
            , cellRenderer(params) {
              if (params.data.isPended != '1' && params.data.isPended != 1) {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
                eDiv.addEventListener('click', function () {
                  selfobj.DeletePendProposal(params.data);
                });
                return eDiv;
              }
            }
          });
        }
        else {
          selfobj.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {


      selfobj.columnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false
        },
       
        {
          field: 'CreatedDate', headerName: 'Date', hide: false, width: 110, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'AccountName', headerName: 'Account', hide: false, width: 200, cellRenderer: function (params) {

            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'accPhone', headerName: 'Phone', hide: false, width: 200, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'ContactName', headerName: 'Contact Name', hide: false, width: 180
        },
        {
          field: 'statusName', headerName: 'Status', hide: false, width: 100
        },
        {
          field: 'isPended', headerName: 'Pended', hide: false, cellRenderer: function (params) {
            if (params.data.isPended == '1' || params.data.isPended == 1) { return 'Pended'; }
          }, cellStyle: function (params) { return { color: '#FF0000' }; }
        },
        {
          field: 'TotalPrice', headerName: 'Total Price', hide: false, width: 100
        },
        {
          field: 'MonthlyTotalPrice', headerName: 'Monthly Total Price', hide: false, width: 100
        },
        {
          field: 'itemName', headerName: 'Proposal Type', hide: false, width: 170
        },
        {
          field: 'ProposalDescription', headerName: 'Proposal Description', hide: false, width: 170
        },
        {
          field: 'LeadDescription', headerName: 'Lead Description', hide: false, width: 170
        },
        {
          field: 'leadfrom', headerName: 'Lead From', hide: false, width: 170
        },
        {
          field: 'actualclosedate', headerName: 'Date Closed', hide: false,
        },
        {
          field: 'createdUser', headerName: 'Created By', hide: false, width: 100
        },
        {
          field: 'ProposalPOPDF', headerName: 'PO', hide: true, width: 100, cellRenderer(params) {
            if (params.data.ProposalPOPDF != null && params.data.ProposalPOPDF != 0) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
              eDiv.addEventListener('click', function () {
                selfobj.ContactDetails = false;
                selfobj.PDFClick(params.data.ProposalPOPDF, 'Proposal');
              });
              return eDiv;
            }
          }

        },
        {
          field: 'ProposalPDF', headerName: 'Proposal', hide: true, width: 100, cellRenderer(params) {

            if (params.data.ProposalPDF != null && params.data.ProposalPDF != 0) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
              eDiv.addEventListener('click', function () {
                selfobj.ContactDetails = false;
                selfobj.PDFClick(params.data.ProposalPDF, 'Proposal');
              });
              return eDiv;
            }

          }
        },
        {
          field: 'ProposalID', headerName: 'ProposalID', hide: true, width: 110,
        },        
        {
          field: 'AccountId', headerName: 'AccountId', hide: true, width: 200
        },
        {
          field: 'OpportunityID', headerName: 'OpportunityID', hide: true, width: 110,
        },
        {
          field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right'

          , cellRenderer(params) {
            if (params.data.isPended != '1' && params.data.isPended != 1) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
              eDiv.addEventListener('click', function () {
                selfobj.DeletePendProposal(params.data);
              });
              return eDiv;
            }
          }
        }
      ];

    }
  }

  PDFClick(val, type) {
    if (this.redirectFrom == 'dashboard') {
      localStorage.setItem('proposalPdfId', val);
      localStorage.setItem('redirectfrom', 'dashboard');
      this.applicationComponent.activateMainButtons('Proposals');
    }
    else {
      this.ShowPdfDiv = true;
      this.PDFDIVCss();
      this.userService.GetProposalsDocumentContent(val).then(result => {
        if (result != null) {
          let SO = this
          $(setTimeout(function () {
            SO.onDocumentOpen(result.PdfContent);
          }, 300));
        }
      });
    }
  }
  PDFDIVCss() {
    setTimeout(function () {
      $('#PDFDiv .dialog').attr('style', 'width: 47%;padding: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 75%;overflow: auto;height: 99%;top: 0%;left: 53%;');
    }, 4)
  }
  ExpandPDF() {
    this.ExpandPDFDiv = !this.ExpandPDFDiv;
  }
  DeleteProposal() {
    this.Proposal.ProposalID = this.ProposalID;
    this.Proposal.isdeleted = 1;
    this.CancelProposal=false;
    this.userService.InsertProposal(this.Proposal).then(result => {
     
    
    });
  }

  closeShowPdfDiv() {
    this.ShowPdfDiv = false;
    this.ExpandPDFDiv = false;

    let so = this;
    $(setTimeout(function () {
      so.contDashComp.GetContactInfo(so.SelectedContactId);
      so.contDashComp.ShowRightInfoDiv = false;
    }, 100));
  }
  calanderclick(val) {
    this.calanderonoff = val;
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }

  }
  GetAllaccesusersListbyuser() {

    this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
      this.DisplayrowsList = Rows;
    });
  }


  LoadAccountandContacts(ContactId) {
    this.customerService.GetContactById(ContactId).then(result => {
      this.contact = result;
      if (result) {
        this.customerService.loadaccountdata(result.accountid).then(result => {
          this.account = result;
          if (this.AllProposals != null) {
            this.AllProposalsHistory = this.AllProposals.filter(x => x.AccountId == this.account.id);
            let so = this;
            $(setTimeout(function () {
              so.gridHistoryOptions.api.forEachNode((node) => {
                if (node.data.ProposalID == so.PropId) {
                  so.gridHistoryOptions.api.selectNode(node, true);
                }
              });
            }, 200));
          }
          this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.country, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.owner);
          this.customerService.GetAccountContactList(this.account.id).then((conatct) => {
            this.contactList = conatct;

            let selfObj = this;
            $(setTimeout(function () {
              //selfObj.Proposal.opportunities.accountorcontactid = selfObj.SelectedContactId;
              $("#ContactList").val(selfObj.SelectedContactId);
            }, 500));

            this.contactList.forEach(p => {
              this.getAllOpportunitiesbyContactid(p.id)
            });
            this.GetAllProposalByAccount(this.account.id);
          });
        });
      }

    });
  }

  LoadAccountandContactsByAccountID(accountid) {
    this.customerService.loadaccountdata(accountid).then(result => {
      this.account = result;
      this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.country, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.owner);
      this.customerService.GetAccountContactList(this.account.id).then((conatct) => {
        this.contactList = conatct;

        let selfObj = this;
        $(setTimeout(function () {
          selfObj.Proposal.opportunities.accountorcontactid = selfObj.SelectedContactId;
          $("#ContactList").val(selfObj.SelectedContactId);
        }, 500));

        this.contactList.forEach(p => {
          this.getAllOpportunitiesbyContactid(p.id)
        });
        this.GetAllProposalByAccount(this.account.id);
      });
    });
  }

  getAllOpportunitiesbyContactid(contactids) {
    this.opprtunityservice.GetAllOpportunitiesListByAccountorContact(contactids, 'Opt').then((customer) => {
      this.OpportunitiesCount += customer.length;

    });
  }
  loadDropDowns() {
    this.opprtunityservice.GetAllItems().then((items) => {
      items.forEach(items => {
      });
      this.opprtunityitemlist = items;
    });

    this.opprtunityservice.GetSpecialityList("ProbabiltyClose", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.pclist = pc;
    });

    this.opprtunityservice.GetSpecialityList("Drs", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.drlist = pc;
    });

    this.opprtunityservice.GetSpecialityList("Midlevels", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.midlevelslist = pc;
    });

    //this.opprtunityservice.GetSpecialityList("LeadFrom", this.currentuser.id).then((pc) => {

    this.opprtunityservice.GetFieldItems(3, 'Lead From', this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.leadfromlist = pc;
    });
    this.customerService.GetLoggedinUserList(this.currentuser.id, this.currentuser.systemadministrator).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.OwnerList = Owner;
    });
  }

  GetAllProposalItems() {
    this.allProposalItemsDuplicate=[];
    this.userService.GetAllProposalItemsWithSortOrder(this.currentuser.id, 0).then(result => {
      this.allProposalItems = result;
      this.allProposalItemsDuplicate = result;
     
      var PreviousCategory = '0';
      //this.categories.push({ id: null, itemName: 'Display All Rows', category: '', isdeleted: 0, taskgroupid: 0 });
      result.forEach(p => {
        if (p.Categoryids == PreviousCategory) {
          p.CategoryName = null;
        } else {
          this.categories.push({ id: Number(p.Categoryids), itemName: p.CategoryName, category: '', isdeleted: 0, taskgroupid: 0 })

        }
        PreviousCategory = p.Categoryids;
      });

    });
  }

  GetAllProposalByAccount(accid) {
    this.PendedProposals = [];
    this.userService.GetAllProposalsByAccount(accid).then(AccountProposals => {
      this.OpenProposalCount = AccountProposals.filter(x => x.status == "108").length;
      this.PendedProposalCount = AccountProposals.filter(x => x.status == "111").length;
      this.WonProposalCount = AccountProposals.filter(x => x.status == "109").length;
      this.LostProposalCount = AccountProposals.filter(x => x.status == "110").length;

    });

    this.userService.LastCreatedProposalsByAccount(accid).then(result => {
   
      if (result.length > 0) {
        this.LastProposalDate = result[0].CreatedDate;
        this.userService.GetPendedProposalsByAccount(accid).then(res => {
          this.Pended = res;
          res.forEach(ele => {
            var Proposal = new Proposals();
            this.opprtunityservice.GetByOpportunityId(ele.OpportunityID).then(result => {
              Object.assign(Proposal, ele);
              Proposal.opportunities = result;
            
              this.PendedProposals.push(Proposal);

              if (this.ProposalID != undefined) {
                const res = this.PendedProposals.filter(x => x.ProposalID === Number(this.ProposalID));
                if (res && res[0] !== undefined) {
                  this.SelectedProposal(res[0]);
                }

                $("#test").val('');
              }
            });


          });

        });
      }

    });
  }


  GetAllProposal(userid) {  
      this.userService.GetAllProposals(userid, this.Type, this.isPended, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal).then(result => {
     
        result.forEach(result => {
          result.CreatedDate = this.datePipe.transform(result.CreatedDate, "MM/dd/yyyy");
        });
        this.AllProposals = result;

        if (this.AllProposals.length > 0) {

          if (this.ProposalID != null && this.ProposalID != undefined) {

            let selfObj = this;
            $(setTimeout(function () {
              selfObj.gridOptions.api.forEachNode((node) => {
                if (node.data.ProposalID == selfObj.ProposalID) {
                  selfObj.gridOptions.api.selectNode(node, true);
                  selfObj.gridOptions.rowSelection = 'single';
                }
              });
            }, 500));
          }
          else if (this.redirectFrom && this.redirectFrom !== 'dashboard') {

            let selfObj = this;
            $(setTimeout(function () {
              selfObj.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));

            }, 500));
          }
          else {
            if (this.ContactDetails) {
              let selfObj = this;
              $(setTimeout(function () {
                selfObj.gridOptions.api.forEachNode((node) => {
                  if (node.data.ProposalID == selfObj.AllProposals[0].ProposalID) {
                    selfObj.gridOptions.api.selectNode(node, true);
                    selfObj.gridOptions.rowSelection = 'single';
                  }
                });
              }, 500));
            }
          }
        }
        else {
          this.AllProposalsHistory = [];

        }

      });  
  }

  ProposalItemChecked(ProposalItem) {

    var res = $('#CheckBox' + ProposalItem.ItemId).prop("checked")
    if (res == true) {
      var total = parseInt($('#Total' + ProposalItem.ItemId).val());
      var Monthly = parseInt($('#MonthlyTotal' + ProposalItem.ItemId).val());
      //this.TotalProposalPrice += total
      //this.MonthlyTotalProposalPrice += Monthly


      this.Proposal.ProposalIds += ProposalItem.ItemId + ':' + $('#Units' + ProposalItem.ItemId).val() + ",";

      ProposalItem.Units = $('#Units' + ProposalItem.ItemId).val();
      ProposalItem.PriceTotal = total;
      ProposalItem.MonthlyTotal = Monthly;
      this.SelectedProposalItems.push(ProposalItem);
    }
    else {
      var total = parseInt($('#Total' + ProposalItem.ItemId).val());
      var Monthly = parseInt($('#MonthlyTotal' + ProposalItem.ItemId).val());
      //this.TotalProposalPrice -= total;
      //this.MonthlyTotalProposalPrice -= Monthly;
      var i = ProposalItem.ItemId + ':' + $('#Units' + ProposalItem.ItemId).val();
      var ProposalIds = this.Proposal.ProposalIds.split(',');
      ProposalIds = ProposalIds.filter(p => p != i)
      this.Proposal.ProposalIds = ProposalIds.join(',');
      $('#Units' + ProposalItem.ItemId).val('');
      $('#Total' + ProposalItem.ItemId).val('');
      $('#MonthlyTotal' + ProposalItem.ItemId).val('');
      $('#CheckBox' + ProposalItem.ItemId).attr('DISABLED', 'disabled');
      ProposalItem.PriceTotal = '';
      ProposalItem.MonthlyTotal = '';
      this.SelectedProposalItems = this.SelectedProposalItems.filter(p => p != ProposalItem);
    }
  }

  UnitsEnter(ProposalItem) {
    var Units = $('#Units' + ProposalItem.ItemId).val();
    this.ProposalDocumentGenerated = false;
    this.PODocumentGenerated = false;
    var Total;
    var Monthly;
    if (Units != '' && Units != '0') {
      if (this.NumberOfMonths == 12) {
        Total = ProposalItem.Price12 * Units;
        Monthly = ProposalItem.Monthly12 * Units;
      } else if (this.NumberOfMonths == 24) {
        Total = ProposalItem.Price24 * Units;
        Monthly = ProposalItem.Monthly24 * Units;
      } else if (this.NumberOfMonths == 36) {
        Total = ProposalItem.Price36 * Units;
        Monthly = ProposalItem.Monthly36 * Units;
      }

      $('#Total' + ProposalItem.ItemId).val("$" + (Total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
      $('#MonthlyTotal' + ProposalItem.ItemId).val("$" + (Monthly).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));

      this.ColourTextBoxes(ProposalItem.ItemId);
      ProposalItem.Units = $('#Units' + ProposalItem.ItemId).val();
      ProposalItem.PriceTotal = Total;
      ProposalItem.MonthlyTotal = Monthly;
      var P = this.SelectedProposalItems.filter(p => p.ItemId != ProposalItem.ItemId);
      this.SelectedProposalItems = P;
      this.SelectedProposalItems.push(ProposalItem);
    } else {
      $('#Total' + ProposalItem.ItemId).val("");
      $('#MonthlyTotal' + ProposalItem.ItemId).val("");
      var P = this.SelectedProposalItems.filter(p => p.ItemId != ProposalItem.ItemId);
      this.SelectedProposalItems = P;
      Total = 0;
      Monthly = 0;
      this.RemoveColourTextBoxes(ProposalItem.ItemId);
    }
    this.MonthlyTotalProposalPrice = 0;
    this.TotalProposalPrice = 0;
    this.SelectedProposalItems.forEach(p => {
      this.MonthlyTotalProposalPrice += p.MonthlyTotal;
      this.TotalProposalPrice += p.PriceTotal;
      if (this.ShowPercentDiscountBox == false) {       
          this.DiscountSelection($(".ActiveDiscount").attr('id'));      
      } else {
      
        if (this.Proposal.TotalDiscountPercent != null) {
          this.PriceDiscountEnter();
        }
        if (this.Proposal.MonthlyDiscountPercent != null) {
          this.MonthlyDiscountEnter();
        }
      }


    });
    this.TotalProposalPriceString = "$" + (this.TotalProposalPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    this.MonthlyTotalProposalPriceString = "$" + (this.MonthlyTotalProposalPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    this.Proposal.opportunities.dollers = (this.TotalProposalPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    this.Proposal.opportunities.RR = (this.MonthlyTotalProposalPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    this.Proposal.ProposalIds = '';
    this.SelectedProposalItems.forEach(ele => {
      this.Proposal.ProposalIds += ele.ItemId + ':' + ele.Units + ",";
    });
    //if (this.Proposal.ProposalIds != '' || this.Proposal.ProposalIds != null || this.Proposal.ProposalIds != undefined) {
    //  var i = ProposalItem.ItemId;
    //  var ProposalIds = this.Proposal.ProposalIds.split(',');
    //  ProposalIds = ProposalIds.filter(p => p.split(':')[0] != i);
    //  this.Proposal.ProposalIds = ProposalIds.join(',');
    //  this.Proposal.ProposalIds += i + ':' + $('#Units' + ProposalItem.ItemId).val() + ',';
    //} else {
    //  this.Proposal.ProposalIds += ProposalItem.ItemId + ':' + $('#Units' + ProposalItem.ItemId).val() + ",";
    //}
  }

  ColourTextBoxes(ItemId) {
    $('#Units' + ItemId).attr('style', 'width:100%;background-color:#a2e477;text-align:center');
    $('#Total' + ItemId).attr('style', 'width:100%;background-color:#a2e477;');
    $('#MonthlyTotal' + ItemId).attr('style', 'width:100%;background-color:#a2e477;');
  }

  RemoveColourTextBoxes(ItemId) {
    $('#Units' + ItemId).attr('style', 'width:100%;');
    $('#Total' + ItemId).attr('style', 'width:100%;');
    $('#MonthlyTotal' + ItemId).attr('style', 'width:100%;');
  }
  PriceDiscountEnter() {
    var Percent = Number($('#PercentPriceDiscount').val());
    if (Percent > 0) {
      var PriceDiscount = this.TotalProposalPrice - (this.TotalProposalPrice / 100 * Percent);
      this.Proposal.opportunities.dollers = PriceDiscount.toString();      
      $('#PriceDiscount').val("$" + (PriceDiscount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
    // $('#PercentPriceDiscount').val(Percent + "%");
    }
    else {
      $('#PriceDiscount').val("");
    }

  }
  MonthlyDiscountEnter() {
    var Percent = Number($('#PercentMonthlyDiscount').val());
    if (Percent > 0) {
      var MonthlyDiscount = this.MonthlyTotalProposalPrice - (this.MonthlyTotalProposalPrice / 100 * Percent);
      this.Proposal.opportunities.RR = MonthlyDiscount.toString()
        ;
     
      $('#MonthlyDiscount').val("$" + (MonthlyDiscount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
     // $('#PercentMonthlyDiscount').val(Percent + "%")
    }
    else {
      $('#MonthlyDiscount').val("");
    }
  }

  ExpandTable() {
    this.ProposalTableExpand = !this.ProposalTableExpand;
  }
  FormValidation(val) {

    if (val == "Yes") {

      if (this.ProposalDocumentGenerated == true && this.PODocumentGenerated == true) {
        this.SaveConfirmationDialog = true;
        this.SaveConfirmationDialogCss();
      }
      else {
        this.DocumentWarningDialog = true;
        this.DocumentWarningDialogCss();
      }
      this.FormValidationDialog = false;
    } else {
      this.FormValidationDialog = false;
    }
  }
  SaveDialog(val) {
    this.pendedval = val;
    if ($('#proposalvalid').val() == "true") {
      if (this.ProposalDocumentGenerated == true && this.PODocumentGenerated == true) {
        this.SaveConfirmationDialog = true;
        this.SaveConfirmationDialogCss();
      }
      else {
        this.DocumentWarningDialog = true;
        this.DocumentWarningDialogCss();
      }
    }
    else {
      this.FormValidationDialog = true;
      this.FormValidationDialogCss();
    }

  }
  CancelSaveConfirmationDialog() {
    this.SaveConfirmationDialog = !this.SaveConfirmationDialog;
  }
  CancelFormValidationDialog() {
    this.FormValidationDialog = !this.FormValidationDialog;
  }
  SaveConfirmationDialogCss() {
    setTimeout(function () {
      $('#SaveConfirmationDialog .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 4)
  }
  FormValidationDialogCss() {
    this.applicationComponent.salesxcrmDialog('#FormValidationDialog .dialog');    
  }
  ShowLoaderCss() {
    setTimeout(function () {
      $('#ShowLoader .dialog').attr('style', 'width: 35%;padding: 2.5% 0 0 3%;border: 4px solid rgb(156 251 93);min-height: 15%;background: transparent;overflow: auto;height: 11%;top: 43%;max-width: 88%;left: 10%;color: white;');
    }, 4);
  }
  SaveProposal(val, ispended) {
    this.Proposal.isPended = ispended;
    if (this.Proposal.opportunities.id == 0 || this.Proposal.opportunities.id == undefined) {
      this.NewForm.addControl('userid', new FormControl(this.currentuser.id));
      this.NewForm.addControl('accountorcontactid', new FormControl(this.Proposal.opportunities.accountorcontactid));
      this.NewForm.addControl('type', new FormControl(this.Proposal.opportunities.type));
      this.NewForm.addControl('isdeleted', new FormControl(0));
      this.NewForm.addControl('ownerid', new FormControl(this.Proposal.opportunities.ownerid));
      this.NewForm.addControl('practiceid', new FormControl(this.Proposal.opportunities.accountorcontactid));
      this.NewForm.addControl('source', new FormControl("Proposal"));
      this.NewForm.addControl('OpportunityFor', new FormControl('Opportunity'));
      var date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
      this.NewForm.addControl('datecreated', new FormControl(date));
      this.NewForm.controls['datecreated'].setValue(date);
      this.NewForm.addControl('actualclosedate', new FormControl(date));
      this.NewForm.controls['actualclosedate'].setValue(null);
      this.Proposal.opportunities.userid = this.currentuser.id;
      this.Proposal.opportunities.isdeleted = 0;
      this.Proposal.opportunities.datecreated = date;
    } else {
      var date = this.datePipe.transform(this.Proposal.opportunities.datecreated, 'yyyy-MM-dd HH:mm:ss');
      this.NewForm.addControl('datecreated', new FormControl(date));
      this.NewForm.controls['datecreated'].setValue(date);
      this.NewForm.addControl('accountorcontactid', new FormControl(this.Proposal.opportunities.accountorcontactid));
      this.NewForm.addControl('type', new FormControl(this.Proposal.opportunities.type));
      this.NewForm.addControl('isdeleted', new FormControl(this.Proposal.opportunities.isdeleted));
      this.NewForm.addControl('ownerid', new FormControl(this.Proposal.opportunities.ownerid));
      this.NewForm.addControl('practiceid', new FormControl(this.Proposal.opportunities.practiceid));
      this.NewForm.addControl('source', new FormControl(this.Proposal.opportunities.source));
      this.NewForm.addControl('OpportunityFor', new FormControl(this.Proposal.opportunities.OpportunityFor));
      this.Proposal.opportunities.userid = this.currentuser.id;
      this.Proposal.opportunities.isdeleted = 0;
    }
    let self = this;
    setTimeout(function () {     
      self.addOpportunity(val);
      self.SaveConfirmationDialog = false;
    }, 4);

  }


  addOpportunity(val) {
    if (val == "Yes") {
      this.Proposal.opportunities.OpportunityFor = "Proposal";
      this.Proposal.opportunities.status = "108";
    } else {
      this.Proposal.opportunities.OpportunityFor = "Proposal";
      this.Proposal.opportunities.status = "109";
    }
    if (this.Proposal.MonthlyDiscountPercent == undefined || this.Proposal.MonthlyDiscountPercent == 0) {
      this.Proposal.opportunities.RR = this.MonthlyTotalProposalPrice.toString();
    }
    if (this.Proposal.TotalDiscountPercent == undefined || this.Proposal.MonthlyDiscountPercent == 0) {
      this.Proposal.opportunities.dollers = this.TotalProposalPrice.toString();
    }
    var ids = this.Proposal.ProposalIds.split(',');
    this.Proposal.ProposalIds = ids.filter(p => p != "").join(",");
    this.Proposal.opportunities.userid = this.currentuser.id;
    this.Proposal.opportunities.isdeleted = 0;
    this.Proposal.opportunities.drs = this.Proposal.opportunities.drs;
    this.Proposal.opportunities.midlevels = this.Proposal.opportunities.midlevels;
    this.Proposal.opportunities.fieldvalues = JSON.stringify(this.NewForm?.value);
    //if (this.Proposal.isPended == 0) {
      this.PDFNew('Proposal', false);
      this.PDFNew('PurchaseOrder', false);
    //}
   
    this.opprtunityservice.addopprtunity(this.Proposal.opportunities).then(result => {
      this.Proposal.OpportunityID = result.id;
      var notes = new Notes();
      this.PendProposal = false;
      notes.accountid = this.Proposal.OpportunityID;
      notes.type = "Opportunity";
      notes.notedescription = 'Opportunity Generated From Proposal';
      notes.userid = this.currentuser.id;
      this.opprtunityservice.addopprtunityNotes(notes).then(result => {
        this.userService.InsertProposal(this.Proposal).then(result => {      
          if (this.Proposal.isPended == 1) {
            this.applicationComponent.activateMainButtons('PendedProposal');
          } 
          else {
            this.GetAllProposalByAccount(this.account.id);
            this.toastr.success("Proposal saved successfully.", "Proposal Saved", { timeOut: 600 });
            this.ClosePreviewDocuments();
            this.TotalProposalPriceString = '';
            this.MonthlyTotalProposalPriceString = '';
          }
          this.contactList.forEach(p => {
            this.getAllOpportunitiesbyContactid(p.id)
          })
          if (this.PageFrom === 'Leads') {
            //this.sendMsgEvent.emit("Proposal Saved");
          }
          else if (this.ProposalType === "Pend Proposal") {

            const SelfObj = this;
            setTimeout(function () {
             // SelfObj.sendMsgEvent.emit("Pend Proposal");
              SelfObj.PendProposal = false;
              SelfObj.ProposalPageChanges = false;
            }, 100)
          }
          else {

            if (this.Proposal.opportunities.status === "108") {
              this.applicationComponent.ProposalSubMenu('Open Proposals');
            }
           // this.sendMsgEvent.emit("Proposal Saved");
            //this.ShowLoader = false;

            //else {
            //  this.applicationComponent.ProposalSubMenu('Closed Proposals');
            //}
          }
          this.cancleProposal();
          this.Resetfields();
          this.DiasbleSaveButton = false;
        });

      });

    });


  }

  LeadFromUpdate(val) {

    this.selectedLeadFrom = this.leadfromlist.filter(p => p.id == val.target.value)[0].opportunityitem

  }

  cancleProposal() {
    this.SaveConfirmationDialog = false;
    this.Proposal = new Proposals();

    this.GetAllProposalItems();
    this.TotalProposalPrice = 0;
    this.MonthlyTotalProposalPrice = 0;
    $('#PriceDiscount').val("");
    $('#MonthlyDiscount').val("");
  }

  onDocumentOpen(myBaseString) {
    $('#pdfDiv').html('<embed src="data:application/pdf;base64,' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:100%;" />')
    $('#pdfDiv').attr('style', 'width:100%;height: 100%;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;margin-left:0%');
  }

  PreviewDocument(myBaseString) {
 
    var url = URL.createObjectURL(new Blob([myBaseString], {
      type: "application/pdf"
    }));
   
    $('#pdfDiv1').html('<embed src="' + url + '" id = "pdfFileReader" style="width:100%;height:632px;" />')
    $('#pdfDiv1').attr('style', 'width:100%;height: 632px;padding-left: 0;padding-right: 0%;border-top: 0px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;margin-left:0%');
  }

  PDFNew(val, showpdfval) {
    this.showpdf = showpdfval;
    if (this.Proposal.ProposalIds != "") {
      this.Proposal.TotalPrice = this.TotalProposalPrice;
      this.Proposal.MonthlyTotalPrice = this.MonthlyTotalProposalPrice;
      this.Proposal.CreatedBy = this.currentuser.id;
      this.Proposal.DefaultDiscount = !this.ShowPercentDiscountBox;
      this.Proposal.ContactId = this.Proposal.opportunities.accountorcontactid;
      this.Proposal.AccountId = this.account.id;
      this.Proposal.NumberOfMonths = this.NumberOfMonths;

      if (this.Proposal.opportunities.item != null) {
        this.Proposal.opportunities.itemName = this.opprtunityitemlist.filter(p => p.id == parseInt(this.Proposal.opportunities.item))[0].itemName;
      }
      if (this.Proposal.opportunities.leadfromid != null) {
        this.Proposal.opportunities.leadfrom = this.leadfromlist.filter(p => p.id == this.Proposal.opportunities.leadfromid)[0].opportunityitem;
      }
      this.Proposal.opportunities.userid = this.currentuser.id;

      this.Proposal.opportunities.source = "Opt";
      this.Proposal.opportunities.type = 'Contacts';
      var ids = this.Proposal.ProposalIds.split(',');
      this.Proposal.ProposalIds = ids.filter(p => p != "").join(",");
      this.Proposal.CreateduserName = this.currentuser.username;

      var timestamp;
      var now = new Date();
      timestamp = now.getFullYear().toString();
      timestamp += (((now.getMonth() + 1) < 9) ? '0' : '') + (now.getMonth() + 1).toString();
      timestamp += ((now.getDate() < 10) ? '0' : '') + now.getDate().toString();
      var ProposalCountOftheday;

      this.userService.GetProposalsByDate(this.datePipe.transform(new Date(), 'yyyy-MM-dd')).then((Owner) => {

        ProposalCountOftheday = Owner.filter(p => p.ProposalPDF != null);
        if (val == 'Proposal') {
          this.PreviewDocuments = true;
          this.Proposal.ProposalPDFID = timestamp + 'P' + (ProposalCountOftheday.length + 1);
          this.userService.GenerateProposalDocument(this.Proposal).subscribe(result => {
            this.PreviewDocuments = true;
            this.ProposalDocumentGenerated = true;
            if (this.showpdf) {
              this.PreviewDocument(result);
            }
          });
        }
        else if (val == 'PurchaseOrder') {
          this.PreviewDocuments = true;
          this.Proposal.ProposalPOID = timestamp + 'PO' + (ProposalCountOftheday.length + 1);
          this.userService.GeneratePODocument(this.Proposal).subscribe(result => {
            this.PreviewDocuments = true;
            this.PODocumentGenerated = true;
            if (this.showpdf) {
              this.PreviewDocument(result);
            }
          });
        }
        else if (val == 'SaveWithOutPreview') {
          this.DocumentWarningDialog = false;
          this.Proposal.ProposalPOID = timestamp + 'PO' + (ProposalCountOftheday.length + 1);
          this.Proposal.ProposalPDFID = timestamp + 'P' + (ProposalCountOftheday.length + 1);
          this.userService.GenerateProposalDocument(this.Proposal).subscribe(result => { });
          this.userService.GeneratePODocument(this.Proposal).subscribe(result => { });
          this.SaveConfirmationDialog = true;
          this.SaveConfirmationDialogCss();
        }
      });
    } else {
      this.MinmumOneItemAlert = true;
      this.applicationComponent.salesxcrmDialog('#MinmumOneItemAlert .dialog');
     
    }

  }
 
  CloseandPreview() {
    this.DocumentWarningDialog = false;
  }
  DocumentWarningDialogCss() {
    setTimeout(function () {
      $('#DocumentWarningDialog .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
}, 4)
  }
  onItemSelect(item: any) {

    if (item.id !== null) {

      this.selectedCategoryIDs = [];
      this.selectedCategoryIDs.push(item);
    
      this.filterProposals(this.selectedCategoryIDs)
    }
    else {
      this.allProposalItemsDuplicate = this.allProposalItems;
    }
  }
  OnItemDeSelect(item: any) {
    

    this.selectedCategoryIDs = this.selectedCategoryIDs.filter(x => x.id !== item.id);
    this.filterProposals(this.selectedCategoryIDs)
  }
  onSelectAll(items: any) {
   
    this.selectedCategoryIDs = items;
    this.filterProposals(this.selectedCategoryIDs)
  }

  onDeSelectAll(items: any) {
   
    this.selectedCategoryIDs = [];
    this.filterProposals(this.selectedCategoryIDs)
  }
  filterProposals(selectedCategoryIDs) {
    var PreviousCategory = '0';
    this.allProposalItemsDuplicate = [];
    selectedCategoryIDs.forEach(x => {
      var result = this.allProposalItems.filter(ele => Number(ele.Categoryids) === x.id)
      result.forEach(p => {
        if (p.Categoryids == PreviousCategory) {
          p.CategoryName = null;
        }
        PreviousCategory = p.Categoryids;
        this.allProposalItemsDuplicate.push(p);
      });
    });

  }
  openAccountNotes() {
    this.isModal = true;
    this.customerService.GetByAccountId(this.account.id).then(result => {
      this.account = result;
    });
    $('#accotIdNotes').val(this.account.id);
    $('#accotTypeNotes').val('Customer');
    this.GetCustomerNotes(this.account.id, 'Customer');
    this.notesshowDialog = !this.notesshowDialog;
    this.NotesDialogCss();
    this.conid = this.account.id;

  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }

  GetCustomerNotes(contactid, type) {
    this.noteComp.GetCustomerNotes(this.currentuser.id, contactid, type);
  }

  CloseNotesDiv(val) {

    this.notesshowDialog = false;
    this.noteComp.openFileView = false;
  }

  ClosePreviewDocuments() {
    this.PreviewDocuments = false;
    this.ExpandPreviewPDFDiv = false;
  }

  PreviewPDFExpand() {
    this.ExpandPreviewPDFDiv = !this.ExpandPreviewPDFDiv;
  }

  initializeAgGridNotes() {

    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.createColumnDefsnotes();

  }
  createColumnDefsnotes() {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellStyle: { 'text-align': 'left' }, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {

          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];

  }
  onCloseDialogEventReceived(msg) {
    if (msg == 'false') {
      this.notesshowDialog = false;
      this.isModal = false;
    }
  }
  txtPageSizeChanged(pageSize) {

    this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  saveConfiguration() {

    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 25;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    this.UpdateSearchColumns(config.columnsselections);
    config.configureduserid = this.currentuser.id;
    config.resourceid = 54;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;
    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  ContactSelected(event) {
    this.Proposal.opportunities.type = 'Contacts';
    this.Proposal.opportunities.accountorcontactid = event.target.value;
  }
  DiscountSelection(DId) {
    this.NumberOfMonths = DId;

    if (DId == '12') {

      this.MonthlyTotalProposalPrice = 0;
      this.TotalProposalPrice = 0;
      this.SelectedProposalItems.forEach(ele => {
        var Units = $('#Units' + ele.ItemId).val();
        if (Units != '') {
          if (this.NumberOfMonths == 12) {
            var Total = Units * ele.Price12;
            var Monthly = Units * ele.Monthly12;
          } else if (this.NumberOfMonths == 24) {
            var Total = Units * ele.Price24;
            var Monthly = Units * ele.Monthly24;
          } else if (this.NumberOfMonths == 36) {
            var Total = Units * ele.Price36;
            var Monthly = Units * ele.Monthly36;
          }

          $('#Total' + ele.ItemId).val("$" + (Total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
          $('#MonthlyTotal' + ele.ItemId).val("$" + (Monthly).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));


          ele.PriceTotal = Total;
          ele.MonthlyTotal = Monthly;

          this.MonthlyTotalProposalPrice += ele.MonthlyTotal;
          this.TotalProposalPrice += ele.PriceTotal;
        }


      });
    } else if (DId == '24') {
      this.MonthlyTotalProposalPrice = 0;
      this.TotalProposalPrice = 0;
    
      this.SelectedProposalItems.forEach(ele => {
        var Units = $('#Units' + ele.ItemId).val();
        if (Units != '') {
          var Total = Units * ele.Price24;
          var Monthly = Units * ele.Monthly24;
          $('#Total' + ele.ItemId).val("$" + (Total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
          $('#MonthlyTotal' + ele.ItemId).val("$" + (Monthly).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
          ele.PriceTotal = Total;
          ele.MonthlyTotal = Monthly;

          this.MonthlyTotalProposalPrice += ele.MonthlyTotal;
          this.TotalProposalPrice += ele.PriceTotal;
        }


      });
    } else if (DId == '36') {
      this.MonthlyTotalProposalPrice = 0;
      this.TotalProposalPrice = 0;
      this.SelectedProposalItems.forEach(ele => {
        var Units = $('#Units' + ele.ItemId).val();
        if (Units != '') {
          var Total = Units * ele.Price36;
          var Monthly = Units * ele.Monthly36;
          $('#Total' + ele.ItemId).val("$" + (Total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
          $('#MonthlyTotal' + ele.ItemId).val("$" + (Monthly).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
          ele.PriceTotal = Total;
          ele.MonthlyTotal = Monthly;

          this.MonthlyTotalProposalPrice += ele.MonthlyTotal;
          this.TotalProposalPrice += ele.PriceTotal;
        }


      });
    }

    this.currentuser.proposalSelection = DId;
    localStorage.setItem('currentUser', JSON.stringify(this.currentuser));

    this.customerService.UpdateProposal(this.currentuser.id, DId).then((user) => {
  
    });

    this.TotalProposalPriceString = "$" + (this.TotalProposalPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    this.MonthlyTotalProposalPriceString = "$" + (this.MonthlyTotalProposalPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    $('.DefaultDiscountCss').removeClass('ActiveDiscount');
    $('#' + DId).addClass('ActiveDiscount');
    this.PriceDiscountEnter();
    this.MonthlyDiscountEnter();
   
    this.ProposalDocumentGenerated = false;
    this.PODocumentGenerated = false;
  }
  ShowPercentDiscount() {
    this.ShowPercentDiscountBox = !this.ShowPercentDiscountBox;
    if (this.ShowPercentDiscountBox == true) {
      this.Proposal.TotalDiscountPercent = null;
      this.Proposal.MonthlyDiscountPercent = null;
      $('#PriceDiscount').val("");
      $('#MonthlyDiscount').val("");
      this.Proposal.opportunities.RR = "";
      this.Proposal.opportunities.dollers = "";
    }
    else {
      let selfObj = this;
      $(setTimeout(function () {
   
        selfObj.DiscountSelection($(".ActiveDiscount").attr('id'));
      }, 200));

    }

  } dateChange(event) { }
  IsButtonAccess(type, addedit) {

  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  UserlistDataReceived(outProps: OutputPropsUserList) {
    if (outProps.enableSearch) { this.EnableSearch = true; }
    this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    if (outProps.finalitems != "") {
      $("#userids").val(outProps.finalitems);
      this.Type = outProps.secondDrpValue !== undefined ? outProps.secondDrpValue : this.Type;

      if (this.Type == 'Pended Proposals') {
        this.getPendedProposals($("#userids").val());
      } else {
        if (!outProps.pageSizeChanged) {
          if (outProps.startDateVal != "" && outProps.startDateVal != null) {
            this.startDateVal = outProps.startDateVal;
          }
          if (outProps.endDateVal != "" && outProps.endDateVal != null) {
            this.endDateVal = outProps.endDateVal;
          }
          if (outProps.thirdDrpValue == '720') {
            this.isPended = 0;
          }
          else {
            this.isPended = 1;
          }
          this.GetAllProposal($("#userids").val());
        }
      }

    } else {
      this.AllProposals = [];
      $("#userids").val('');
    }
  }
  getPendedProposals(userid) {
    this.userService.GetAllPendedProposals(userid, 1).then(result => {
      this.AllProposals = result;
    });
  }
  Display(accId, accountname, addr1, addr2, city, state, zip, country, phone, fax, website, Accountiddata,ownerName) {

    //var properCity = city.charAt(0).toUpperCase() + city.substr(1).toLowerCase();
    if (city != null) {
      var cityArray = city.split(' ');
      var newCity = '';
      for (var i = 0; i < cityArray.length; i++) {
        if (newCity == '') {
          newCity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
        else {
          newCity = newCity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
      }
    }
    if (website != null && website.indexOf('www.') >= 0) {
      website = ((website).split('www.')[1])
    } else if (website != null && website.indexOf('ww.') >= 0) {
      website = ((website).split('ww.')[1])
    }


    var properstate = (state != null) ? state.charAt(0).toUpperCase() + state.substr(1).toLowerCase() : "";
    this.viewaccount.accountId = accId;
    this.viewaccount.viewaccountname = accountname;
    this.viewaccount.viewaddress1 = addr1;
    this.viewaccount.viewaddress2 = addr2;
    this.viewaccount.viewcity = newCity == null ? "" : newCity;
    this.viewaccount.viewstate = properstate;
    this.viewaccount.viewzip = zip;
    this.viewaccount.viewCountry = country==882?"Bahamas":"USA";
    this.viewaccount.viewphone = phone;
    this.viewaccount.viewfax = fax;
    this.viewaccount.viewwebsite = website;
    this.viewaccount.Accountiddata = Accountiddata;
    this.viewaccount.ownerName = ownerName;
    this.accdetailcmp.loadAccountId(accId);
  }
  addContact() {
    //if (this.practiceid == "view") {
    //  this.applicationComponent.isAccessDenied = true;
    //} else {
    if (this.userprevilages != undefined) {
      for (var j = 0; j < this.userprevilages.length; j++) {
        if ("Contacts" == this.userprevilages[j].resource) {
          if (this.userprevilages[j].canadd == false) {
            this.toastr.clear();
            this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }
        }
      }
    }
    this.showAddEditContact = true;
    this.showAddEditContactCss();
    //}
  }
  AccountsharingAg(selectedval) {
    //if (this.practiceid == "view") {
    //  this.applicationComponent.isAccessDenied = true;
    //} else {

    if (selectedval == "Edit") {
      this.isSharing = false;
      this.action = selectedval;
      this.showAddEditAccount = true;
      this.showAddEditAccountCss();
    }
    else if (selectedval == "View") {
      this.isSharing = false;
      this.action = selectedval;
      this.showAddEditAccount = true;
      this.showAddEditAccountCss();
    }
    else {
      this.isSharing = true;
      this.rightMainDivHideShow = false;
      this.accountSharingid = 1;
      this.AddContacttableDiv = false;
      this.ownrshipComp.loadAccountInformation(this.account.id, 0);
    }
    //}

  }
  showAddEditAccountCss() {
    setTimeout(function () {
      $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  showAddEditContactCss() {
    setTimeout(function () {
      $('#addEditContact .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  ReceiveEvent(msg) {
    if (msg == "ExpandSoftwareLicense" || msg == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(msg);
    } else {

    }
  }

  ExpandAddEditAccountDialog(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 100%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 86%;padding: 0;top: 10%;left: 10%;max-width: 88%;');
      }, 4)
    } else {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
      }, 4)
    }
  }



  ContactReceiveEvent(msg) {
    this.showAddEditContact = false;
    //this.GetAllProviderAndContactInfo(this.account.id)
  }
  btnClickReceived(event) {
    if (event.btnName == 'addContact') {
      this.addContact();
    }
    else if (event.btnName == 'Accountsharing') {
      this.AccountsharingAg(event.val);
    }
    else if (event.btnName == 'openNotes') {
      this.openAccountNotes();
    }
    else if (event.btnName == 'AccountInfo')
    {
      this.router.navigateByUrl(`/application/account/Accounts`);
      this.applicationComponent.ExpandSelectedMenu('Accounts');
      $(".subButtons").removeClass("activeButton");
      localStorage.setItem('RhxAccountVal', event.val);
    }
  }
  handleClick(event) {

    this.ProposalPageChanges = true;
    var clickedComponent = event.target;
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {

      //$('#test').val('Proposals');
    } else {

    }
  }

  pendProposal() {
    this.ProposalType = "Pend Proposal";
    this.Proposal.ContactId = this.Proposal.opportunities.accountorcontactid;
    this.Proposal.AccountId = this.account.id;
    this.Proposal.CreatedBy = this.currentuser.id;   
    this.SaveProposal('Yes', 1);


  }
  ShowPendedproposals() {
    this.PendedProposalsDialog = true;
    setTimeout(function () {
      $('#PendedProposalsDialog .dialog').attr('style', 'width:35%;padding-bottom:0px;border:3px solid #00B0F0;min-height:75%;overflow:auto;height:84%;left:63%;top:10%;padding:0;');
    }, 4)
  }

  SelectedProposal(val) {

    this.Proposal = val;

    this.PendedProposalsDialog = false;
   
    this.GetAllProposalItems();
    var ProposalIds = []
    this.SelectedProposalItems = [];
    let SelfObj = this;
    setTimeout(function () {

      if (SelfObj.Proposal.ProposalIds != "") {
        ProposalIds = SelfObj.Proposal.ProposalIds.split(',');
        ProposalIds.forEach(ele => {
          if (ele != "") {
            var res = SelfObj.allProposalItemsDuplicate.filter(x => x.ItemId == Number(ele.split(':')[0]));
            $('#Units' + ele.split(':')[0]).val(ele.split(':')[1]);
            SelfObj.UnitsEnter(res[0]);
          }
        });
      }
      else {
        SelfObj.MonthlyTotalProposalPriceString = '0';
        SelfObj.TotalProposalPriceString = '0';

      }


      if (SelfObj.Proposal.ProposalPDFID != null) {
        SelfObj.ProposalDocumentGenerated = true;
      } else {
        SelfObj.ProposalDocumentGenerated = false;
      }
      if (SelfObj.Proposal.ProposalPOID != null) {
        SelfObj.PODocumentGenerated = true;
      } else {
        SelfObj.PODocumentGenerated = false;
      }

      if (SelfObj.Proposal.MonthlyDiscountPercent || SelfObj.Proposal.TotalDiscountPercent) {
        SelfObj.ShowPercentDiscountBox = true;
      } else {
        SelfObj.ShowPercentDiscountBox = false;
      }
    }, 300);


  }

  DeletePendProposal(item) {
    this.Proposal = item;
    if (this.Proposal.isPended != 1) {
      this.applicationComponent.loaddialog('ProposalDelete', 'Delete Proposal', 'Linked Opportunity will be Deleted', false, false, true, true, this.Proposal.ProposalID)
    } else {
      this.applicationComponent.loaddialog('ProposalDelete', 'Delete Pended Proposal', 'Delete Proposal and Opportunity?', false, false, true, true, this.Proposal.ProposalID)
    }
  }

  //Confirmation(val) {
  //  if (val == 'Yes') {
  //    //  this.Proposal.ProposalID = this.ProposalID;
  //    this.Proposal.isdeleted = 1;
  //    this.userService.InsertProposal(this.Proposal).then(result => {
  //      this.DeleteConfirmation = false;
  //      this.GetAllProposal($("#userids").val());
  //      this.ContactDetails = false;
  //    });
  //  } else {
  //    this.DeleteConfirmation = false;
  //  }
  //}


  Close() {
    this.applicationComponent.selectedsubbutton = "";
    this.location.back();
  }
  cancelProposal() {
    this.applicationComponent.CreateProposalPage = false;
    this.applicationComponent.pageViewed = "";
  }
  openOpportunity() {
    this.applicationComponent.CollapseSelectedMenu('opportunities');
    this.applicationComponent.ExpandSelectedMenu('opportunities');
    let AccountOrContactId = this.Proposal.ContactId;
    let opportunityId = this.Proposal.OpportunityID;
    let type = 'Contacts';
    this.router.navigateByUrl(`/application/opportunitiesdashboard/${AccountOrContactId}/${type}/null/${opportunityId}`);


  }
  CloseCss() {
    this.applicationComponent.salesxcrmDialog('#CloseAddProposal .dialog');
   
  }
  cancelAddProposal() {

    if (this.PageFrom == 'Leads') {
     // this.sendMsgEvent.emit("Discard");
    } else {
      this.applicationComponent.ProposalOverLayWarning = false;
      this.applicationComponent.CreateProposalPage = false;
      this.applicationComponent.pageViewed = "";
    }
    // this.applicationComponent.activateMainButtons(this.applicationComponent.selectedtabid);
  }

  rowClickAgGrid(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        var data = event.api.getSelectedNodes()[0].data;

        if (this.PageFrom == 'Leads') {
          this.applicationComponent.activateMainButtons('Proposals');
          this.PDFClick(data.ProposalPDF, 'Proposal');
        } else {
          this.ContactDetails = true;
          this.SelectedContactId = data.ContactId;
          this.Proposal = data;
          this.PropId = this.Proposal.ProposalID;
          this.LoadAccountandContacts(this.SelectedContactId);
          var obj: AuditLogModel = { id: 0, type: 'Proposal', action: 'Proposal Viewed', AccountOrContactId: this.Proposal.ContactId, AccountOrContact: "Contact", documentid: this.Proposal.ProposalID, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Proposal Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
          this.applicationComponent.InsertAuditHistory(obj);

        }
      }
    } else {
      this.ContactDetails = false;
      this.ShowPdfDiv = false
      this.SelectedContactId = 0;
      this.Proposal = new Proposals();
    }



  }


  onAccountMessageReceived(msg) {
    if (msg == 'AccountView') {
      localStorage.setItem('RhxContactVal', (this.Proposal.ContactId).toString());

      let SO = this;
      $(setTimeout(function () {
        SO.applicationComponent.activateSubButtons('empty');
        $(setTimeout(function () {
          SO.router.navigateByUrl(`/application/contacts/contacts/null`);
          SO.applicationComponent.ExpandSelectedMenu('contact');
          SO.applicationComponent.selectedtabid = 'contact';
        }, 100));
      }, 50));
    }
  }

  getProjectFields() {

    this.opprtunityservice.GetOpportunityFields(this.currentuser.id).then(result => {
      this.OpportunityFieldsList = result;
      result.forEach(ele => {
        if (ele.DataType == 'Pick List' || ele.DataType == 'System Lookup') {
          if (ele.ColumnName == 'ownerid') {
            this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, 1, 0, 0).then((Owner) => {
              ele['Data'] = Owner;
              this.dropdownList = Owner;
            });
          } else if (ele.ColumnName == 'status') {
            this.opprtunityservice.GetFieldItems(ele.OppFieldId, ele.FieldName, this.currentuser.id).then((accounttype) => {

              if (this.ProposalID == undefined) {
                ele['Data'] = accounttype.filter(x => x.id == 108);
              } else { ele['Data'] = accounttype; }
            });
          }

          else {
            this.opprtunityservice.GetFieldItems(ele.OppFieldId, ele.FieldName, this.currentuser.id).then((accounttype) => {
              ele['Data'] = accounttype;
            });
          }


        }


      });

      let So = this;
      setTimeout(function () {
        So.Fields = result;
      
        So.Fields.forEach(x => {
          So.NewForm.addControl(x.ColumnName, new FormControl(''));
        });


      }, 400)



    });
  }

  FormDataEntered(val) {
    this.FormDataModified = true;
   
  }
  CloseProposal() {
    this.ClosePendProposalDialog = true;
    this.applicationComponent.salesxcrmDialog('#ClosePendProposalDialog .dialog');
    //setTimeout(function () {
    //  $('#ClosePendProposalDialog .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    //}, 4);
     }
  ClosependProposal() {
    this.applicationComponent.CreateProposalPage = false;
    this.applicationComponent.pageViewed = "";
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
    this.gridOptions.quickFilterText = (document.getElementById('filter-text-box') as HTMLInputElement).value;
    this.createColumnDefs(this.colSelections);
    this.gridApi.redrawRows();    
  }
  ClearSearch() {
    this.gridApi.setQuickFilter('');
    this.value = "";
    this.gridOptions.quickFilterText = "";
    this.createColumnDefs(this.colSelections);
    this.gridApi.redrawRows();    
    this.EnableSearch = false;
  }
}

