import { Component, OnInit,  ElementRef, Optional, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Contacts } from '../../models/contacts';
import { Customers } from '../../models/customers';
import { Notes } from '../../models/notes';
import { AccountsService } from './../../services/accounts.service';
import { CustomerService } from './../../services/customer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Dropdowntype } from '../../models/dropdowntype';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Statistics } from '../../models/statistics';
import { Location } from '@angular/common';
import { Tickets } from '../../models/tickets';
import { Emails } from '../../models/emails';
import { Accounts } from '../../models/accounts';
import { Viewaccount } from '../../models/viewaccount';
import { Accountlocations } from '../../models/data-model';
import { LeadsService } from './../../services/leads.service';
import { TaskHistory } from '../../models/task-history';
import { AccountDocuments } from '../../models/account-documents';
import { GridOptions } from "ag-grid/main";
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { Column } from 'ag-grid';
import { NotesrendererComponent } from '../notesrenderer/notesrenderer.component';
import { EditactivatecvComponent } from '../editactivatecv/editactivatecv.component';
import { EditdeletecvComponent } from '../editdeletecv/editdeletecv.component';
import { ApplicationComponent } from '../application.component';
import { OwnershipsharingComponent } from '../ownershipsharing/ownershipsharing.component';
import { UploadDocumentComponent } from '../../application/upload-document/upload-document.component';
import { AddressComponent } from '../address/address.component';
import { DocumentmanagementComponent } from '../documentmanagement/documentmanagement.component';
import { HistoryComponent } from '../history/history.component';
import { forbiddenNameValidator } from '../shared/forbidden-validator-directive.directive';
import { AddressUpdate } from 'src/app/models/AddressUpdate';
import { NotesComponent } from '../notes/notes.component';
import { AccountDetailsComponent } from '../account-details/account-details.component';
import { AccountsHistory } from '../../models/AccountHistory';
import { ContactsSortOrder } from '../../models/ContactsSortOrder';
import { UserService } from '../../services/user.service';
import { Opportunities } from '../../models/opportunities';
import { Projects } from '../../models/ProjectConfiguration';
import { Campaign } from '../../models/Campaign';
import { CampaignsComponent } from '../campaigns/campaigns.component';
import { ContactPhotoComponent } from '../contacts/contact-photo/contact-photo.component';
declare var require: any;
declare var $: any;
@Component({
  selector: 'account-contact',
  templateUrl: './account-contact.component.html',
  styleUrls: ['./account-contact.component.css']
})
export class AccountContactComponent implements OnInit {
  @Input()
  contactId;
  currentuser: any;
  notedescription: string;
  userprevilages: any;
  dialogOpen: boolean = false;
  showmapWarning: boolean = false;
  AccountIdsDialog: boolean = false;
  showticketsinfo: boolean = false;
  expanded: boolean = false;
  tableDivHGT = screen.height * 0.48666;//0.51444
  addContactBodyHGT1 = screen.height * 0.4333;//0.46
  SaveAccDialog: boolean = false;
  documentview: boolean = false;
  isOpenGoogleMap: boolean = false;
  public searchControl: FormControl;
  accid: number;
  customercontacttypelist: Dropdowntype[];
  documentlist: Array<AccountDocuments> = [];
  AccountIdArr: Dropdowntype[] = [];
  contactIdArr: Dropdowntype[] = [];
  Years: any[];
  MedSchoolYears: any[];
  ResidencyYears: any[];
  FellowShipYears: any[];
  accountform: FormGroup;
  contactform: FormGroup;
  notes: Notes = new Notes();
  StatusDropdownList: Dropdowntype[] = [];
  datePipe: DatePipe = new DatePipe("en-US");
  titleCasePipe: TitleCasePipe = new TitleCasePipe();
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  public getRowHeight;
  public zipmask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public dobmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  account: Accounts = new Accounts();
  accountHistory: AccountsHistory = new AccountsHistory();
  Myaccount: Accounts = new Accounts();
  viewaccount: Viewaccount = new Viewaccount();
  contacts: Contacts = new Contacts();
  customer: Customers = new Customers();
  accountlocations: Accountlocations = new Accountlocations();
  @ViewChild(HistoryComponent) historyComp;
  @ViewChild(OwnershipsharingComponent) ownrshipComp: OwnershipsharingComponent;
  @ViewChild(UploadDocumentComponent) uploadDoc;
  @ViewChild(AddressComponent) adddata;
  @ViewChild(CampaignsComponent) campaincmp;
  @ViewChild(ContactPhotoComponent) contactphotocmp;
  accountslist: Array<Accounts> = [];
  contactslist: Array<Contacts> = [];
  noteslist: Array<Notes> = [];
  public accountid: number = 0;
  public practiceid: string;
  public historyName: string;
  public statisticbox: string;
  contactid: number;
  leadstatus: number;
  public sideBar;
  public NotesideBar;
  public contactpermission: string;
  uploadDocumentShow = false;
  customertypeList: Dropdowntype[] = [];
  OwnerList: Dropdowntype[] = [];
  accounttypeList: Dropdowntype[] = [];
  ownershiplist: Dropdowntype[] = [];
  Accountstatisticlist: Array<Statistics> = [];
  SuffixList: Dropdowntype[] = [];
  SexList: Dropdowntype[] = [];
  TitleList: Dropdowntype[] = [];
  contacttypelist: Dropdowntype[] = [];
  Specialitytypelist: Dropdowntype[] = [];
  Collegelist: Dropdowntype[] = [];
  MedicalSchoolList: Dropdowntype[] = [];
  StatesList: Dropdowntype[] = [];
  CitiesList: Dropdowntype[] = [];
  PractiseList: Dropdowntype[] = [];
  ehrpurchasedList: Dropdowntype[] = [];
  pmpurchasedList: Dropdowntype[] = [];
  rcmlist: Dropdowntype[] = [];
  itemResource: any;
  ContactStaffitemResource: any;
  items = [];
  itemCount = 0;
  staffitems = []; selectedItems = [];
  staffitemCount = 0;
  locationId = 0;
  locId = 0;
  conid: number;
  permission: string;
  typ: string;
  ismatchduplicate: string;
  PhoneDuplicate = false;
  websiteduplicate = false;
  params: any;
  ContactStaffparams: any;
  showDialog = false;
  taskDialog = false;
  pdfDialog = false;
  DeleteConfirmationPopup = false;
  DuplicateAccountDialog: boolean = false;
  leftGridDetailsDiv: boolean = true;
  AddContacttableDiv: boolean = false;
  isShowDetailsChecked: boolean = false;
  showAddEditAccount: boolean = false;
  showAccountHistoryHx: boolean = false;
  showAddEditContact: boolean = false;
  AccountotherDetailsDiv: boolean = true;
  EditAccountFormDiv: boolean = false;
  AddAccountFormDiv: boolean = false;
  showSccountInfo: boolean = true;
  leftMainDivHideShow: boolean = true;
  rightMainDivHideShow: boolean = true;
  ShowContactView: boolean = false;
  isTaskShow: boolean = false;
  contactSharingid: number = 0;
  AccountstatisticsBoxDuplicate: boolean = true;
  leftGridDetailsDivDuplicate: boolean = false;
  accountSharingid: number = 0;
  ViewPhotoDialog = false;
  locationCount: number = 2;
  MonthList: Dropdowntype[] = [];
  DayList: Dropdowntype[] = [];
  ContactOrder: Dropdowntype[] = [];
  public providerId: number = 0;
  activitieslist: Array<TaskHistory> = [];
  ticketslist: Array<Tickets> = [];
  ProjectsList: Array<Projects> = [];
  emailslist: Array<Emails> = [];
  Campignslist: Array<Campaign> = [];
  Opportunitylist: Array<Opportunities> = [];
  FeeAmountdiv: boolean = false;
  hidDiv: boolean = true;
  notesshowDialog = false;
  futureTaskDialog = false;
  quickViewHistoryDialog = false;
  quickViewnotesDialog = false;
  quickViewActivitiesDialog = false;
  quickViewCampaignsDialog = false;
  quickViewSMSDialog = false;
  QuickViewTicketsDialog: boolean = false;
  QuickViewDocumentsinfo: boolean = false;
  QuickViewProposalsinfo: boolean = false;
  QuickViewEmailinfo: boolean = false;
  QuickViewOpportunityInfo: boolean = false;
  QuickViewProjectInfo: boolean = false;
  allnotes: Notes[];
  accounttaskDialoghgt = screen.height * 0.43222;
  url = '';
  userdocumentlist: any[] = [];
  Originaluserdocumentlist: any[] = [];
  POdocumentlist: any[] = [];
  public accountstaticsticsId: number = 1;
  public stateInfo = [];
  private data: Array<Notes> = [];
  public rows: Array<Notes> = [];
  public columns: Array<any> = [
    { title: 'Date', name: 'created' },
    { title: 'Type', name: 'type' },
    { title: 'Note Description', name: 'notedescription' }
  ];
  Ids = [];
  public page: number = 1;
  currentYear = new Date().getFullYear();
  pdfopen = false;
  public itemsPerPage: number = 10;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;
  public zendtype: string = 'Expand';
  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered']
  };
  stateTextBox: boolean = true;
  stateDropDown: boolean = false;
  displayZipCode: boolean = true;
  AccountContactPage: boolean = true;
  displayCountryName: boolean = false;
  seletedcountry: any;
  countryId: number = 1;
  documentindex: number = 0;
  accDocCount: number = 0;
  conDocCount: number = 0;
  accConDocCount: number = 0;
  public elementRef;
  textareaLength: number = 15;
  addresses: Accountlocations[];
  public gridOptions: GridOptions;
  public columnDefs: any[];
  heroForm: FormGroup;
  nameChangeLog: string[] = [];
  allContactsproviders: Contacts[];
  Contacttotal: Contacts[];
  allContacts: Contacts[];
  public context;
  id: number;
  SelectedCountryId: number;
  taskid: number;
  collegeyearDrop: boolean = true;
  MedSchoolyearDrop: boolean = true;
  ResidencyyearDrop: boolean = true;
  FellowshipyearDrop: boolean = true;
  selectedcontact: string[] = [];
  addressupdate: AddressUpdate = new AddressUpdate();
  selectedAddressOption: string = '';
  enableUpdateAll: boolean = false;
  enableSelectContacts: boolean = false;
  enableDoNotUpdate: boolean = false;
  public frameworkComponents; public columnDefsnotes: any[];
  public gridOptionsnotes: GridOptions;
  today = new Date();
  public AccountorCustomertype;
  @Input()
  type: string;
  @Input()
  tab: string = 'Account';
  @Input()
  SelectedContactId: number;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  NotesDec = false;
  isDocumentsInfoDivVisible: boolean = false;
  isTicketsInfoDivVisible: boolean = false;
  accountstatisticBox: string = 'Close';
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  timezonevalue: string;
  isModal: boolean = false;
  SixBoxExpand: boolean = false;
  setDocumentDetails = 0;
  fixHeight = 101;
  divHeight = (screen.height * 0.1611);
  divHeight1 = (screen.height * 0.53);//0.44
  divHeight2 = (screen.height * 0.13888);
  accounttaskDialog: boolean = false;
  accountactivitiesinfo: boolean = false;
  ShowAccountHx: boolean = false;
  accountnotesinfo: boolean = false;
  accountemailinfo: boolean = false;
  campaignsinfo: boolean = false;
  documentsinfo: boolean = false;
  Proposalinfo: boolean = false;
  Projectsinfo: boolean = false;
  AccountcontactIds: string = "";
  accountotherDetailsTable: boolean = true;
  customeracces: number = 0;
  deleteduserName: string = "";
  IsEditAccount: boolean = false;
  divHeight3 = (screen.height * 0.514444);//0.42555
  userdocumentcount: number = 0;
  isSharing: boolean = false;
  action: string;
  isSharingShow: boolean = false;
  public lattitude: number;
  public longitude: number;
  public address1: string;
  public zoom: number;
  @ViewChild("search")
  public searchElementRef: ElementRef;
  docstatus: string = "New";
  documentcount: number = 0;
  SortedContactType;
  DocumentsFilter;
  postatuslist;
  SortedStatus;
  SelectedStatus;
  SelectedContactType;
  ContactsSortOrderBy = "LA-Z";
  @ViewChild(DocumentmanagementComponent) docMgmtComp;
  @ViewChild(UploadDocumentComponent) uploadDocComp: UploadDocumentComponent;
  @ViewChild(NotesComponent) notecomp;
  @ViewChild(AccountDetailsComponent) accdetailcmp;
  userInfo: string;
  locstateDropDown;
  EditAddressDiv: boolean = false;
  showContinueButton: boolean = false;
  ShowContactstoSelect: boolean = false;
  deleteRowData: any;
  contactStatus: any;
  public ShowPDF: boolean = false;
  public EditDocument: boolean = true;
  DocExpandView: boolean = false;
  CampaignType: string = 'All';

  ContactsOrder: ContactsSortOrder = new ContactsSortOrder();
  LicenseExistWarningDialog: boolean = false;
  showviewcampaign = false;
  constructor(private fb: FormBuilder,private accountsService: AccountsService,
    public applicationComponent: ApplicationComponent,private route: ActivatedRoute,
    private customerService: CustomerService, private toastr: ToastrService,
    private location: Location, private router: Router, myElement: ElementRef, private leadservice: LeadsService,
    private opprtunityservice: OpprtunitiesService, private userService: UserService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.customeracces = this.currentuser.customertypeaccess;
    this.account.customertypeid = 0;
    this.account.isaddressadded = null;
    this.contacts.isaddressadded = null;
    this.account.accounttype = null;
    this.account.ownerid = null;
    this.account.state = null;
    this.account.city = null;
    this.account.multiplelocations = 0;
    this.account.speciality = null;
    this.account.accountStatus = 1;
    this.account.ownership = null;
    this.account.rcm = null;
    this.account.ehr = null;
    this.account.pm = null;
    this.account.ownerid = this.currentuser.id;
    this.account.pm = null;
    this.account.pm = null;
    this.ResetContact();
    this.elementRef = myElement;
    this.createForm();
  }

  AddDocumentsNewAccount(val) {     
    this.docstatus = val;
    if (this.uploadDocumentShow) {
      this.documentcount = 0;
      this.uploadDocComp.OpenFilemanual(this.docstatus);
    } else {
      this.docMgmtComp.uploadFiles();
    }
  }

  onOpenUploadDocument(msg) {
  
    var isnum = /^\d+$/.test(msg);
    if (isnum) {
      this.userdocumentcount = msg;
    }
    else if (msg == "UploadDocument") {
      this.uploadDocumentShow = true;
      let objSelf = this;
      $(setTimeout(function () {
        objSelf.uploadDocComp.OpenFilemanual(objSelf.docstatus);
        objSelf.documentcount = 0;
      }, 100));

    } else if (msg == "ShowPDF") {
      this.ShowPDF = true;
    } else if (msg == "Hide") {
      this.ShowPDF = false;
      this.DocumentsFilterBy('Display All',)
    } else if (msg == 'EditDocumentTrue') {
      this.EditDocument = false;
    } else if (msg == 'EditDocumentFalse') {
      this.EditDocument = true;
    }
  }

  CloseNotesDiv(val) {
    this.notesshowDialog = false;
    this.close();
  }

  Nameval(event) {
    var regex = new RegExp("^[-a-zA-Z0-9!'_.&()-{}\|;:?/\" ]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  Island_Countryval(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }


  handleClick(event) {
    var clickedComponent = event.target;
    var nodeData = clickedComponent[0].text;
    if (nodeData == 'Select College') {
      this.collegeyearDrop = false;
    } else if (nodeData == 'Select Med School') {
      this.MedSchoolyearDrop = false;
    } else if (nodeData == 'Select Residency') {
      this.ResidencyyearDrop = false;
    } else if (nodeData == 'Select Fellowship') {
      this.FellowshipyearDrop = false;
    }
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {
      $('#test').val('Account');


    } else {

    }
  }
  
  ResetContact() {
    this.contacts = new Contacts();
    this.contacts.contacttype = null;
    this.contacts.suffix = null;
    this.contacts.sex = null;
    this.contacts.title = null;
    this.contacts.speciality = null;
    this.contacts.accountStatus = 0;
    this.contacts.subspeciality = null;
    this.contacts.college = null;
    this.contacts.collegegrandyear = null;
    this.contacts.medschool = null;
    this.contacts.medschoolgrandyear = null;
    this.contacts.residency = null;
    this.contacts.residencygrandyear = null;
    this.contacts.fellowship = null;
    this.contacts.fellowshipgrandyear = null;
    this.contacts.ownerid = null;  
    this.contacts.firstname = null;
    this.contacts.lastname = null;
    this.contacts.practiceid = 0;
    $('#title').removeClass('not-required')
    $('#test').val('');
  }
  cancelAccount() {
    this.EditAccountFormDiv = false;
    this.AccountotherDetailsDiv = true;
    this.AddAccountFormDiv = false;
    this.rightMainDivHideShow = true;

    if (this.account.id == 0) {
      this.location.back();
    } else {
      this.GetAllProviderAndContactInfo(this.account.id);
    }
  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, paginationPageSize: 1000, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: this.tab == "QuickView" ?'multiple': 'multiple'

    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }        
      ],
      position: 'left',
    };
  
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent,
      editdeletecv: EditdeletecvComponent,
     editactivatecv: EditactivatecvComponent
    };

    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 23).then((config) => {
      
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(columnSelections);
    });

    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
  }
  deleteRowAg(rowdata) {
    this.deleteRowData = rowdata;
    if (this.deleteRowData.isLicenseKey == false) {
      if (this.currentuser.userrole == 1 && rowdata.ownerid == this.currentuser.id) {
        this.contactStatus = this.deleteRowData.isdeleted
        this.DeleteConfirmationPopupCss();
      } else {
        alert("Please Contact System Admin / Owner of the Account to delete");
      }
      
    } else {
      this.LicenseExistWarningDialog = true;
      setTimeout(function () {
        $('#LicenseExistWarningDialog .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 24%;top:10%');
      }, 4)
    }
    
  }
  DeleteConfirmationPopupCss() {
    this.DeleteConfirmationPopup = true;
    setTimeout(function () {
      $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4);
  }
  ReceiveDeleteevent(msg) {
    if (msg == "Ok") {
      if (this.practiceid == "view") {
        this.applicationComponent.isAccessDenied = true;
        this.dialogCss();
      } else {        
        this.DeleteConfirmationPopupCss();
        
        let isdeletedstatus = (this.deleteRowData.isdeleted == 1) ? 0 : 1;
        this.customerService.Deactivatecontact(this.deleteRowData.id, isdeletedstatus).then((user) => {
          this.ticketslist = [];
          this.GetAllProviderAndContactInfo(this.account.id);
          let selfObj = this;
          $(setTimeout(function () {
            selfObj.autoSizeAll();
          }, 400));
          this.DeleteConfirmationPopup = false;
        });
      }
    } else {
      this.DeleteConfirmationPopup = false;
    }
  }

  convertLead(id) {
    this.showDialog = !this.showDialog;
    this.router.navigateByUrl(`/application/leads/${id}`);
  }

  convertContactAg(rowdata) {
    this.contactid = rowdata.id;
    this.leadstatus = 0;
    this.showDialog = !this.showDialog;
    this.customerService.GetContactById(rowdata.id).then(result => {
      this.contacts = result;
      this.leadstatus = this.contacts.converttolead;

    });
    $(setTimeout(function () {
      $('.dialog ').attr('style', 'height:180px;width:35%;padding-top: 3%;background: rgb(186,219,152) !important;border:4px solid rgb(49,189,240);border-radius:5%;overflow:visible;')
    }, 4));
  }
  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      this.columnDefs = [];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleArray[0] == "#") {

          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
          });
        }
        else if (fieldHeaderVisibleArray[0] == "notes") {
          this.columnDefs.push({
            field: 'notes', headerName: 'Notes', cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.style.textAlign = 'center';
              eDiv.style.alignContent = 'center';
              eDiv.innerHTML = '<img src="../../../assets/img/Notes Icon.png" style="height:25px;width:50%;margin-top:-2%"/>';
              eDiv.addEventListener('click', function () {
                selfObj.openNotesDialogAg(params.data.id);
              });
              return eDiv;
            }, hide: false, width: 25 });
        } else if (fieldHeaderVisibleArray[0] == "isLicenseKey") {
          this.columnDefs.push({
            field: 'isLicenseKey', headerName: 'LK', hide: false, width: parseInt(fieldHeaderVisibleArray[3]), cellRenderer(params) {
              var eDiv = document.createElement('div');
              if (params.data.isLicenseKey == true ) {
                eDiv.innerHTML = '<i class="fa fa-key" aria-hidden="true" style="font-size:15px"></i>';
              }
              else if (params.data.isdeleted == false) {
                eDiv.innerHTML = '';
              }
              return eDiv;
            }
              });
              
            
        }
        else if (fieldHeaderVisibleArray[0] == "actions") {
          this.columnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: isDeActive ? 'editactivatecv' : 'editdeletecv', hide: false, pinned: 'right', width: 120 });
        }
        else if (fieldHeaderVisibleArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, width: parseInt(fieldHeaderVisibleArray[3]) });
        }
        else if (fieldHeaderVisibleArray[0] == "Status") {
          this.columnDefs.push({
            field: 'Status', headerName: 'Contact Status', hide: false, suppressSorting: true, width: parseInt(fieldHeaderVisibleArray[3]), cellRenderer(params) {
              if (params.data.Status === 309) {
                return "Active";
              } else {
                return "InActive";
              }
            } });
        }
        else if (fieldHeaderVisibleArray[0] == "image") {
          this.columnDefs.push({
            field: 'image', headerName: 'Image', hide: false, width: parseInt(fieldHeaderVisibleArray[3]), cellRenderer(params) {
              var eDiv = document.createElement('div');
                
              if (params.data.photo != null) {
                eDiv.innerHTML = '<img class="btn-simple" style="width:40px;height:30px" src="' + params.data.photo + '"/>';

                var eButton = eDiv.querySelectorAll('.btn-simple')[0];
                eButton.addEventListener('click', function () {
                  selfObj.openfile(params.data);
                });
              } else {
                eDiv.innerHTML = '<img class="btn-simple" style="width:40px;height:30px" src="../../../assets/img/default contact photo.jpg"/>';
                var eButton = eDiv.querySelectorAll('.btn-simple')[0];
                eButton.addEventListener('click', function () {
                  selfObj.openfile(params.data);
                });
              }


              return eDiv;
            }
          });
        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleArray[0],
            headerName: fieldHeaderVisibleArray[1],
            hide: fieldHeaderVisibleArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 32,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        { field: 'lastname', headerName: 'Last Name', hide: false, width: 120 },
        { field: 'firstname', headerName: 'First Name', hide: false, width: 120 },
        { field: 'titleName', headerName: 'Title', hide: false, width: 80 },
        
        {
          field: 'image', headerName: 'Image', hide: false, width: 100, cellRenderer(params) {
            var eDiv = document.createElement('div');
            if (params.data.photo != null) {
              eDiv.innerHTML = '<img class="btn-simple" style="width:40px;height:30px" src="' + params.data.photo + '"/>';

              var eButton = eDiv.querySelectorAll('.btn-simple')[0];
              eButton.addEventListener('click', function () {
                selfObj.openfile(params.data);
              });
            } else {
              eDiv.innerHTML = '<img class="btn-simple" style="width:40px;height:30px" src="../../../assets/img/default contact photo.jpg"/>';
              var eButton = eDiv.querySelectorAll('.btn-simple')[0];
              eButton.addEventListener('click', function () {
                selfObj.openfile(params.data);
              });
            }
            return eDiv;
          }
        },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        {
          field: 'Status', headerName: 'Contact Status', hide: false, cellRenderer(params) {
            if (params.data.Status === 309) {
              return "Active";
            } else {
              return "InActive";
            }
          }
        },{
          field: 'isLicenseKey', headerName: 'LK', hide: false, width: 100 , cellRenderer(params) {
            var eDiv = document.createElement('div');
            if (params.data.isLicenseKey == true) {
              eDiv.innerHTML = '<i class="fa fa-key" aria-hidden="true" style="font-size:15px"></i>';
            }
            else if (params.data.isdeleted == false) {
              eDiv.innerHTML = '';
            }

            return eDiv;
          }
        },
        { field: 'DOB', headerName: 'DOB', hide: false, width: 80 },
        {
          field: 'notes', headerName: 'Notes', cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.style.textAlign = 'center';
            eDiv.style.alignContent = 'center';
            eDiv.innerHTML = '<img src="../../../assets/img/Notes Icon.png" style="height:25px;width:50%;margin-top:-2%"/>';
            eDiv.addEventListener('click', function () {
              selfObj.openNotesDialogAg(params.data.id);
            });
            return eDiv;
          }, hide: false, width: 25
        },
        { field: 'zip', headerName: 'ZIP', hide: false, width: 80 },
        { field: 'accountname', headerName: 'Account Name', hide: false },
        { field: 'actions', headerName: 'Actions', cellRenderer: isDeActive ? 'editactivatecv' : 'editdeletecv', hide: false, pinned: 'right', width: 120 },
        { field: 'contacttypeName', headerName: 'Contact Type', hide: true },  //true starts here
        { field: 'suffixName', headerName: 'Suffix', hide: true },
        { field: 'sexName', headerName: 'Sex', hide: true },
        { field: 'subspecialityName', headerName: 'Subspeciality', hide: true },
        { field: 'email', headerName: 'Email', hide: true },
        { field: 'address1', headerName: 'Address1', hide: true },
        { field: 'address2', headerName: 'Address2', hide: true },
        { field: 'city', headerName: 'City', hide: true },
        { field: 'state', headerName: 'State', hide: true },
        { field: 'cell', headerName: 'Cell', hide: true },
        { field: 'office', headerName: 'Office', hide: true },
        { field: 'homeaddress', headerName: 'Home Address', hide: true },
        { field: 'homecity', headerName: 'Home City', hide: true },
        { field: 'homestate', headerName: 'Home State', hide: true },
        { field: 'homezip', headerName: 'Home Zip', hide: true },
        { field: 'home', headerName: 'Home', hide: true },
        { field: 'collegeName', headerName: 'College', hide: true },
        { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
        { field: 'medschoolName', headerName: 'Medschool', hide: true },
        { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
        { field: 'residencyName', headerName: 'Residency', hide: true },
        { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
        { field: 'fellowshipName', headerName: 'Fellowship', hide: true },
        { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
        { field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true },
        { field: 'created', headerName: 'Created', hide: true },
        
      ];
    }
  }
  openfile(contact) { 
    this.ViewPhotoDialog = true;
    this.ViewPhotoDialogCss();
    this.contacts = contact;
 
  }
  ViewPhotoDialogCss() {
    setTimeout(function () {
      $('#ViewPhotoDialog .dialog').attr('style', 'width: 50%;padding-bottom: 0px;min-height: 25%;overflow: auto;height: 70%;padding: 0;left: 23%;top: 20%;');
    }, 4);
  }


  onAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
     
    }, 400));
  }
  autoSizeAll() {
    if (this.gridOptions && this.gridOptions.columnApi) {      
      this.gridOptions.columnApi.autoSizeAllColumns();
    }
  }
  editRowAg(rowdata) {
     
    if (this.practiceid == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    } else {
   
      this.contactId = rowdata.id;
      this.showAddEditContact = true;
      this.showAddEditContactCss();
      
    }
  }

  LoadContactEdit(contactId) {
     
    this.AccountotherDetailsDiv = false;
    this.AddContacttableDiv = true;
    this.type = "Contact";
    $('#test').val('Contact');
    this.contacts = new Contacts();
    this.customerService.Getcontacttypes(2).then((accounttype) => {
      this.contacttypelist = [];
      accounttype.forEach(accounttype => {
      });
      this.contacttypelist = accounttype;
    });

    this.ResetContact();
    $('#ticketHeader').addClass('col-xs-12 otherCss');
    $('#emailHeader').addClass('col-xs-12 otherCss');
    $('#notesHeader').addClass('col-xs-12 otherCss');
    $('#activityHeader').addClass('col-xs-12 otherCss');
    $('#documentHeader').addClass('col-xs-12 otherCss');
    $('#projectHeader').addClass('col-xs-12 otherCss');

    this.customerService.GetContactById(contactId).then(result => {       
      this.getyears();   
      this.loadcontacttitles(result.contacttype).then(() => {
        this.contacts = result;
        this.adddata.loadaddress(this.contacts);
        this.changeCityStateZip(this.contacts.country, this.contacts.state);
        this.titleSelected();
      });
    });
    this.AccountstatisticsBoxDuplicate = false;

    $('#AccountViewDiv').removeClass('col-xs-6 col-sm-6 col-md-6');
    $('#AccountViewDiv').addClass('col-xs-12 col-sm-12 col-md-12');
  }
  rowDoubleClickAgGrid(rowEvent) {
    if (this.tab == 'QuickView') {
      this.showSccountInfo = false;
      this.ShowContactView = true;
      this.contactid = rowEvent.data.id;
    } else {
      this.router.navigateByUrl(`/application/contactsdashboard/${rowEvent.data.id}/${this.practiceid}`);
    }
  }

  onAccountMessageReceived(msg) {
    this.showSccountInfo = true;  
    this.ShowContactView = false;
    this.contactid = this.account.id;
    this.GetAllProviderAndContactInfo(this.account.id);
  }
  Getall6boxesinfo(accid) {
    this.customerService.GetAllEmailsList(accid, 'Customer').then((emails) => {
       this.emailslist = emails;
    });
    this.leadservice.GetAllTaskHistoryByContact(accid, 'Customer', this.currentuser.timezone).then((activities) => {
      this.activitieslist = activities;     
    });
    this.customerService.GetAllDocumentList(accid, 'Account', this.currentYear, 'Account').then((document) => {
      this.Originaluserdocumentlist = document;
      this.userdocumentlist = document.filter(x => x.DocSavedFrom !== "Proposal");
      this.POdocumentlist = document.filter(x => x.DocSavedFrom === "Proposal");
      
    });
    this.customerService.GetAllProjectsByAccountId(accid).then(res => {
      this.ProjectsList = res;
    });

   
  }
  AccountData(Accountid) {
    this.loadAccountInformation(Accountid);    
    this.customerService.GetDocumentsCount(Accountid.toString().replace('show', ''), 'Account').then(result => {
      this.userdocumentcount = result;

    });


  }
  loadAccountInformation(Accountid) {
    if (this.tab == 'QuickView') {
      $('#Accountinformation').removeClass("container")
      $('#Accountinformation').removeAttr('style')
      $('#Accountinformation').attr('style', 'padding-left:0.5%;height:100%')
    }
    
    $('#title').removeClass('not-required');
    if (Accountid != undefined && Accountid != 0 && Accountid.toString().indexOf('edit') > -1) {
      this.contactid = parseInt(Accountid.toString().replace('edit', ''));
      this.GetAllProviderAndContactInfo(Accountid.toString().replace('edit', ''));
      this.customerService.GetByAccountId(Accountid.toString().replace('edit', '')).then(result => {
        this.account = result;
        this.customerService.GetAccountHistoryById(this.account.id, this.currentuser.id).then(result => {
          if (result != null) {
            this.accountHistory = result;
          }

        });
        
        this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.ProvidenceIsland, this.account.owner);
        this.AccountsharingAg('Edit');
      });
    }
    else if (Accountid != undefined && Accountid != 0 && Accountid.toString().indexOf('show') > -1) {
      this.contactid = parseInt(Accountid.toString().replace('show', ''));
      this.GetAllProviderAndContactInfo(Accountid.toString().replace('show', ''));

      this.AccountotherDetailsDiv = true;
      this.EditAccountFormDiv = false;

      this.rightMainDivHideShow = true;

      this.customerService.GetByAccountId(Accountid.toString().replace('show', '')).then(result => {
        this.account = result;
       
        this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.ProvidenceIsland, this.account.owner);
      });

    }
    else if (Accountid != 0) {
      this.contactid = Accountid;  
    }
    this.customerService.GetAllStatistics().then((statistics) => {
      this.Accountstatisticlist = [];
      statistics.forEach(statistics => {
        this.Accountstatisticlist.push(statistics)
      });

    });
    this.Getall6boxesinfo(this.contactid);
    this.accountform = this.getAccountForm();
    
    var url = window.location.href;
    if (url.indexOf('account-contact') > -1 && url.indexOf('edit') > -1) {
    }
    else if (url.indexOf('account-contact') > -1) {
    }
    else if (url.indexOf('addAccount') > -1) {
      this.leftMainDivHideShow = false;
      this.rightMainDivHideShow = false;
      this.applicationComponent.pageViewed = "AddAccount";
    }
   

    this.customerService.GetSpecialityList("ContactOrderBy", this.currentuser.id).then((Acids) => {
      this.ContactOrder = Acids;
    });
  }
  ngAfterViewInit(): void {

  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.accountid = (params['accountid'] == undefined) ? this.contactId : params['accountid'];
      this.account.id = (params['accountid'] == undefined) ? this.contactId : params['accountid'];
      this.practiceid = params['practiceid'];     
      this.AccountData(this.account.id);
    });
    this.applicationComponent.totalglobalsearchcount = 0;
    this.AccountstatisticsBoxDuplicate = false;
    this.accountstatisticBox = "Close";
    this.leftGridDetailsDiv = true;
    this.AddContacttableDiv = false;
    this.EditAccountFormDiv = false;
    $('#accotIdNotes').val(this.accountid);
    this.account.accnumber = '0';
    var loggedInUserId = this.currentuser.id;
    if (this.currentuser.customertypeaccess == 1) {
      $('#Customertype').css('display', 'block');
    } else {
      $('#Customertype').css('display', 'none');
    }
    this.customerService.GetPrivilagesByuser(loggedInUserId).then(result => {
      this.userprevilages = result;
    });

    $('#1').attr('style', 'display:block');
    $('#_accountsub').attr('style', 'display:block');

    var url = window.location.href;
    if (url.indexOf('account-contact') > -1) {

      this.gridOptions = <GridOptions>{
        pagination: true, paginationPageSize: 1000, rowSelection: 'multiple'
      };
      this.divHeight1 = (screen.height * 0.4722);
      this.divHeight3 = (screen.height * 0.46222);
      this.divHeight2 = (screen.height * 0.1540);
      this.fixHeight = 115;
     

    }
    else if (url.indexOf('quick-view') > -1) {
      this.divHeight1 = (screen.height * 0.46222);
      this.divHeight3 = (screen.height * 0.4355);
      this.divHeight2 = (screen.height * 0.145666);

    }
   

    $('#tableDiv').css('display', 'none');
    if (this.applicationComponent.selectedsubbutton == 'addaccount') {
    }
    this.searchControl = new FormControl();
  }
  invokeEvent(place: Object) {
  }


  public onAccStatusClick() {
    if (this.currentuser.customerStatus == 0) {
      alert("You don't have access to Change Account Status. If you want to Access, please change it from User Configuration ")

    }
  }


  getAccountForm(): FormGroup {

    return new FormGroup({

      'accountname': new FormControl(this.account.accountname, [
        Validators.required,
        forbiddenNameValidator(/bob/i) // <-- Here's how you pass in the custom validator.
      ]),

      'country': new FormControl(this.account.country, Validators.required),
      'address1': new FormControl(this.account.address1, Validators.required),
      'accounttype': new FormControl(this.account.accounttype, Validators.required),
      'ownership': new FormControl(this.account.ownership, Validators.required),
      'state': new FormControl(this.account.state, Validators.required),
      'otherstate': new FormControl(this.account.state, Validators.required),
      'city': new FormControl(this.account.city, Validators.required),
      'zip': new FormControl(this.account.zip, Validators.required),
      'ownerid': new FormControl(this.account.ownerid, Validators.required),
      'customertypeid': new FormControl(this.account.customertypeid, Validators.nullValidator),
      'Practice': new FormControl(this.account.practiceid, Validators.nullValidator),
      'address2': new FormControl(this.account.address2, Validators.nullValidator),
      'phone': new FormControl(this.account.phone, Validators.nullValidator),
      'fax': new FormControl(this.account.fax, Validators.nullValidator),
      'alterphone1': new FormControl(this.account.alterphone1, Validators.nullValidator),
      'alterphone2': new FormControl(this.account.alterphone2, Validators.nullValidator),
      'website': new FormControl(this.account.website, Validators.nullValidator),
      'speciality': new FormControl(this.account.speciality, Validators.nullValidator),
      'accountStatus': new FormControl(this.account.accountStatus, Validators.nullValidator),
    });


  }

 
  get accountname() { return this.accountform.get('accountname'); }

  onChange(stateid) {
    this.customerService.GetAllCitiesList(stateid).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.CitiesList = accounttype;
    });
  }

  
  cancelContact() {
     
    this.AccountotherDetailsDiv = true;
    this.AddContacttableDiv = false;
    this.collegeyearDrop = true;
    this.MedSchoolyearDrop = true;
    this.ResidencyyearDrop = true;
    this.FellowshipyearDrop = true;
    if (this.tab != "QuickView") {
      let numb = this.accountid.toString().match(/\d/g);
      this.accid = parseInt(numb.join(""));
      if (this.account.id == 0) {
        this.customerService.GetByAccountId(this.accid).then((account) => {
          this.account = account;
          this.customerService.GetAccountHistoryById(this.accid, this.currentuser.id).then(result => {
            if (result != null) {
              this.accountHistory = result;
            }

          });
        });

        if (this.account.website != null && this.account.website.indexOf('www.') >= 0) {
          this.account.website = ((this.account.website).split('www.')[1])
        }

      } else {
        this.customerService.GetByAccountId(this.account.id).then((account) => {
          this.account = account;
          this.customerService.GetAccountHistoryById(this.account.id, this.currentuser.id).then(result => {
            if (result != null) {
              this.accountHistory = result;
            }

          });
        });

        if (this.account.website != null && this.account.website.indexOf('www.') >= 0) {
          this.account.website = ((this.account.website).split('www.')[1])
        }
      }

    }
    this.GetAllProviderAndContactInfo(this.account.id);


  }
  CloseHistory() {
    this.showAccountHistoryHx = false;
    this.AccountotherDetailsDiv = true;
  }
  LoadAcchistory(accountId) {
    this.accdetailcmp.loadAccountId(accountId);
    this.customerService.GetAccountHistoryById(accountId, this.currentuser.id).then(result => {
      if (result != null) {
        this.accountHistory = result;       
        if (this.accountHistory.HistoryNotes != "") {
          var currentUserids = []; var dateViewed = [];
          var Accountviwed = this.accountHistory.HistoryViewed != null ? this.accountHistory.HistoryViewed.split('|') : [];
          
          Accountviwed.forEach(eledata => {
            if (eledata != "") {
              currentUserids.push(eledata != null ? eledata.split(':')[0] : null);
              dateViewed.push(eledata != null ? eledata.split(':')[1] : null);
            }
          });
         
          var currentdate = this.datePipe.transform(this.today, "yyyy-MM-dd");
         
          if (currentUserids.length > 0 && dateViewed.length > 0 && (currentUserids.includes(this.currentuser.id.toString()) && dateViewed.includes(currentdate))) {         
            this.showAccountHistoryHx = false;
            this.AccountotherDetailsDiv = true;
          } else {
            this.showAccountHistoryHx = true;
            this.AccountotherDetailsDiv = false;
          }
          this.showAccountHistoryHxCss();
        }

        this.viewaccount.AccountHistoryNote = this.accountHistory.HistoryNotes;
 
       
      } else {
       
        this.viewaccount.AccountHistoryNote = null;
        this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.ProvidenceIsland, this.account.owner);
    
        
      }

    });
  }
    showAccountHistoryHxCss() {
      setTimeout(function () {
        $('#ShowAccountHx .dialog').attr('style', 'width: 25%;padding-bottom:0px;min-height:63%;overflow: auto;height:80%;padding:0;top:10%;border:1px solid black;left:72%; background-color: lightyellow;');
      }, 4)
    }
  Display(accId, accountname, addr1, addr2, city, state, zip, country, phone, fax, website, Accountiddata,Providence,owner) {
   
    if (city != null) {
      var cityArray = city.split(' ');
      var newCity = '';
      for (var i = 0; i < cityArray.length; i++) {
        if (newCity == '') {
          newCity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
        else {
          newCity = newCity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
      }
    }
    if (website != null && website.indexOf('www.') >= 0) {
      website = ((website).split('www.')[1])
    } else if (website != null && website.indexOf('ww.') >= 0) {
      website = ((website).split('ww.')[1])
    }


    var properstate = (state != null) ? state.charAt(0).toUpperCase() + state.substr(1).toLowerCase() : "";
    this.viewaccount.accountId = accId;
    this.viewaccount.viewaccountname = accountname;
    this.viewaccount.viewaddress1 = addr1;
    this.viewaccount.viewaddress2 = addr2;
    this.viewaccount.viewcity = newCity == null ? "" : newCity;
    this.viewaccount.viewstate = properstate;
    this.viewaccount.viewzip = zip;
    this.viewaccount.viewCountry = country;
    this.viewaccount.viewphone = phone;
    this.viewaccount.viewfax = fax;
    this.viewaccount.viewwebsite = website;
    this.viewaccount.Accountiddata = Accountiddata;
    this.viewaccount.ProvidenceIsland = Providence;
    this.viewaccount.ownerName = owner;
    this.LoadAcchistory(accId);
   
  }
  cancelDialog() { this.DuplicateAccountDialog = false; }

  Continue() { this.SaveAccountInfo(this.selectedAddressOption); }
  SaveCustomeAddress() {
    this.account.isaddressadded = true;
    this.contacts.isaddressadded = true;
    this.showmapWarning = false;
  }

  showEditAddressDiv() {
    if (this.account.isaddressadded && this.account.id != 0) {
      this.customerService.GetAccountContactList(this.account.id).then((contacts) => {
        if (contacts.length != 0) {
          this.EditAddressDiv = true;
          this.showContinueButton = false;
          let so = this;
          setTimeout(function () {
            so.checkBoxChecked('UpdateAll');
          }, 400);
        } else {
          this.SaveAccount('DoNotUpdate');
        }
      });

    } else {
      this.SaveAccount('DoNotUpdate');
    }
  }
  cancelEditAddressDiv() {
    this.EditAddressDiv = false;
  }

  SaveAccount(val) {
    if (this.account.isaddressadded) {
      if (this.account.id == 0) {
        this.accountsService.checkduplicateAccountName(this.account.accountname).then(result => {
           
          if (result == "No") {
            this.accountsService.checkduplicates(this.account).then(resultdata => {
               
              if (resultdata == "No") {
                this.SaveAccountInfo(val);
              }
              else {
                this.ismatchduplicate = result;
                if (resultdata == 'WebsiteDuplicate') { this.websiteduplicate = true; }
                this.SaveAccDialog = true;
              }
            });
          }
          else {
            this.DuplicateAccountDialog = true
            this.deleteduserName = result;
            $(setTimeout(function () {
              $('.dialog').attr('style', 'width:45% !important;border: 4px solid rgb(49,189,240);border-radius:5%; height:70%;top:16%;max-width:none;padding:0;');
            }, 200));

          }
        });


      } else {

        this.SaveAccountInfo(val);
      }

      this.EditAccountFormDiv = false;
      this.AccountotherDetailsDiv = true;
    }
    else {
      this.showmapWarning = true;
      this.showmapWarningDialogCss();
    }
  }
  showmapWarningDialogCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width:30%;padding-bottom: 0px;border:4px solid rgb(49,189,240);border-radius:5%;min-height: 40%;overflow: visible;height: 42%;top: 32%;left: 56%;');
    }, 4);
  }
  openAcchistoryNotes() {
    this.showAccountHistoryHx = true;
  }

  closeduplicatediv() {
    this.SaveAccDialog = false;
  }
  SaveAccountInfo(val) {
    this.account.userid = this.currentuser.id;
    this.accountsService.addaccount(this.account).then(result => {
      this.account = result;
      this.SaveAccDialog = false;
      this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.ProvidenceIsland, this.account.owner);
      this.addresses = this.prepareSaveHero();
      this.accountsService.addaccountLocations(this.addresses).then(result => {
      });
      this.addressupdate.address1 = this.account.address1;
      this.addressupdate.address2 = this.account.address1;
      this.addressupdate.city = this.account.city;
      this.addressupdate.state = this.account.state;
      this.addressupdate.zip = this.account.zip;
      switch (val) {
        case 'UpdateAll': {
          this.customerService.GetAccountContactList(this.account.id).then((contacts) => {
            let contactids = [];
            contacts.forEach(contacts => {
              contactids.push(contacts.id);
            });
            this.addressupdate.ContactIds = contactids.join(',');
            this.updatecontactaddress(this.addressupdate);
          });
          break;
        }
        case 'SelectContacts': {
          this.addressupdate.ContactIds = this.selectedcontact.join(',');
          this.updatecontactaddress(this.addressupdate);
          break;
        }
        case 'DoNotUpdate': {
          break;
        }
      }
      this.AccountData(this.account.id);
      this.toastr.success("Account saved successfully.", "Account Saved", { timeOut: 600 });
      this.cancelContact();
      this.EditAddressDiv = false;
      $('#test').val('');
      if (this.account.zip != undefined || this.account.zip != null) {
        if (this.account.zip.toString().length >= 6) {
          this.account.zip = this.account.zip.toString().slice(0, 5) + "-" + this.account.zip.toString().slice(5);
        } else if (this.account.zip.toString().length === 5) {
          this.account.zip = this.account.zip.toString();
        } else {
          this.account.zip = this.account.zip;
        }
      }
      this.GetCustomerNotes(this.account.id.toString(), 'Customer');
      this.router.navigateByUrl(`/application/account-contact/0/show` + this.account.id);
    });

    this.GetAllProviderAndContactInfo(this.account.id);
    $('#statisticHeader').attr('style', 'text-align: left;');
    this.AddAccountFormDiv = false;
    this.rightMainDivHideShow = true;
    this.addresses = null;
  }

  updatecontactaddress(addressupdate) {
    this.accountsService.updatecontactaddress(addressupdate).then(result => {
      this.addressupdate = new AddressUpdate();
      
    });
  }

  multipleLocation(id) {
     
    if (id == 1) {
      this.secretLairs.push(this.fb.group(new Accountlocations()));
      $('#multipleLocation').val('0');
    } else {

    }

  }

  addContact() {
    if (this.practiceid == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    } else {
      
      if (this.userprevilages != undefined) {
        for (var j = 0; j < this.userprevilages.length; j++) {
          if ("Contacts" == this.userprevilages[j].resource) {
            if (this.userprevilages[j].canadd == false) {
              this.toastr.clear();
              this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
              return;
            }
          }

        }
      }
     

      this.showAddEditContact = true;
      this.showAddEditContactCss();
      this.contactId = 0;


    }

  }
  
  GetAllProviderAndContactInfo(accountid) {
    
    this.initializeAgGrid();
    
    this.customerService.GetAllContactproviderByAccount(accountid, this.currentuser.id).then((contacts) => {
      contacts.forEach(contacts => {
        this.getTicketsData(contacts.id);
        this.getCampaignData(this.currentuser.id);
        this.getOpportunityData(contacts.id);
        this.userService.GetAllSoftwareLicense(this.currentuser.id, contacts.id.toString()).then(result => {
          if (result && result.length > 0) {
            contacts.isLicenseKey = true;
          } else {
            contacts.isLicenseKey = false;
          }
        });
        
      });
      this.allContactsproviders = contacts;
      this.AccountcontactIds = this.allContactsproviders.map(s => s.id).join(",");
  
      this.allContacts = contacts;
      var rew = Array.from(new Set(this.allContactsproviders.map(s => s.contacttype)))
        .map(contacttype => {
          return {
            Name: ((this.allContactsproviders.filter(x => x.contacttype == contacttype)[0].contacttypeName != null) ? this.allContactsproviders.filter(x => x.contacttype == contacttype)[0].contacttypeName: '' ) + " (" + this.allContactsproviders.filter(x => x.contacttype == contacttype).length + ")",
            Value: contacttype
          }
        });
      this.SelectedContactType = "All";
      this.SortedStatus = [
        { id: 0, Value: 'Active & Inactive' + " (" + this.allContactsproviders.length + ")" },
        { id: 309, Value: 'Active' + " (" + this.allContactsproviders.filter(x => x.Status == 309).length + ")" },
        { id: 310, Value: 'Inactive' + " (" + this.allContactsproviders.filter(x => x.Status == 310).length + ")" }
        
      ];
      this.SelectedStatus = 309;

      this.SortedContactType = rew;
      this.getContactorder();
      

      this.itemCount = contacts.length;
      if (this.SelectedContactId != 0) {
        
      }
    });
    if (this.account.id == 0) {
      this.GetCustomerNotes(accountid, 'Customer')
    } else {
      this.GetCustomerNotes(accountid, 'Customer')
    }

  }

  getContactorder() {
    this.customerService.GetSaveContactOrder(this.currentuser.id).then((result) => {
      if (result) {
        this.ContactsOrder = result;
        this.ContactsSortOrderBy = result.OrderByValue;
      } else {
        this.ContactsOrder.OrderByValue = "LA-Z";
      }
      this.ContactsSortOrder(this.ContactsOrder.OrderByValue);
      
    });
  }

  getTicketsData(Conid) {
    this.ticketslist = [];
    this.customerService.GetTicketsListByContact(Conid, 'All').then((tickets) => {
      tickets.forEach(ticket => {
        ticket.createdDate = this.datePipe.transform(ticket.createdDate, "MM/dd/yyyy");
        this.ticketslist.push(ticket)
      });
    });
  }
  getCampaignData(userid) {
  
    this.opprtunityservice.GetAllCampaigns(userid).then((Campaigns) => {    
      this.Campignslist = Campaigns;
      console.log("this.Campignslist", this.Campignslist);
     // this.Campignslist = this.Campignslist.filter(x => x.ContactsLinked != null && this.AccountcontactIds.includes(x.ContactsLinked));
      
    });
    }
  getOpportunityData(Conid) {

    this.Opportunitylist = [];
    this.opprtunityservice.GetAllOpportunitiesListByAccountorContact(Conid, 'Opt').then((custm) => {
      custm.forEach((customer) => {
        customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
        customer.actualclosedate = this.datePipe.transform(customer.actualclosedate, "MM-dd-yyyy");
        customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
        this.Opportunitylist.push(customer);
      });

    });
    
  }

  saveaccountContact() {
     
    if (this.contacts.isaddressadded) {
      this.adddata.sendBackAddress();
      var titleContain = 1;
      if ($("#title").hasClass("required") || $('#title').hasClass('not-required')) {
        if (this.contacts.title == null) {
          titleContain = 0;

        }
      } else {
        titleContain = 1;
      }
      if (titleContain == 1) {
        this.adddata.sendBackAddress();
        this.contacts.accountid = this.account.id;
        this.contacts.userid = this.currentuser.id;
        this.contacts.isshared = this.account.isshared;

        this.accountsService.addcontact(this.contacts).then(result => {
          this.contacts.id = result.id;
          $('#test').val('');
          this.contacts.userid = this.currentuser.id;
          this.toastr.success("Contact saved successfully.", "Contact Saved", { timeOut: 600 });

          this.AccountotherDetailsDiv = true;
          this.AddContacttableDiv = false;

          this.length = this.data.length;
          this.GetAllProviderAndContactInfo(this.account.id);
          this.customerService.loadaccountdata(this.contacts.accountid).then(result => {
            this.account = result;
            this.customerService.GetAccountHistoryById(this.account.id, this.currentuser.id).then(result => {
              if (result != null) {
                this.accountHistory = result;
              }

            });
            if (this.account.city != null) {

              var city = this.account.city;
              var cityArray = city.split(' ');
              var newcity = '';
              for (var i = 0; i < cityArray.length; i++) {
                if (newcity == '') {
                  newcity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
                } else {
                  newcity = newcity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
                }
              }
              this.account.city = newcity;
            }

            var state = this.account.state;
            var properstate = state.charAt(0).toUpperCase() + state.substr(1).toLowerCase();
            this.account.state = properstate;

          });
          this.FellowshipyearDrop = true;
          this.MedSchoolyearDrop = true;
          this.ResidencyyearDrop = true;
          this.collegeyearDrop = true;
        });


      }
    } else {
      this.showmapWarning = true;
      this.showmapWarningDialogCss1();
    }
  }
  showmapWarningDialogCss1() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width:30%;padding-bottom: 0px;border:4px solid rgb(49,189,240);border-radius:5%;min-height: 40%;overflow: visible;height: 42%;top: 50%;left: 56%;');
    }, 4);
  }
  rowColors(car) {
    if (car.id == this.contacts.id) return 'rgb(216, 208, 33)';
  }

  editAccount(val) {
    $('#emailIds').val(val);

    this.customerService.GetDocumentsCount(val, 'Account').then(result => {
      this.userdocumentcount = result;

    });

    if (val != 0) {
      $('#Accountlabel').html('Edit Account');
      this.AddAccountFormDiv = true;
      //this.applicationComponent.pageViewed = "AddAccount";
      this.rightMainDivHideShow = false;
      // rightMainDiv
      this.AccountotherDetailsDiv = false;
      this.locId = 0;
      this.account = new Accounts();
      this.customerService.GetByAccountId(val).then(result => {
        this.account = result;
        this.customerService.GetAccountHistoryById(this.account.id, this.currentuser.id).then(result => {
          if (result != null) {
            this.accountHistory = result;
          }

        });
        this.adddata.loadaddress(this.account);
        if (this.account.customertypeid == 0) { this.accountstatisticBox = 'Close'; }
        if (this.account != null) {
          this.customerService.getAccountPermission(this.account.id, this.currentuser.id).then(perresult => {
            this.account.isAccountPermission = perresult;
          });
        }
        $('.coustomButton').removeClass('mainactiveButton');
        if (this.account.customertypeid == 0) {
          $("#contact").addClass("mainactiveButton");
        } else {
          $("#customers").addClass("mainactiveButton");
        }
        this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.ProvidenceIsland, this.account.owner);
        this.Getall6boxesinfo(this.account.id);
        //this.multipleLocation(this.account.multiplelocations);
        this.customerService.GetLocationByAccountId(this.account.id).then(result => {
          this.addresses = result;
          this.GetAllProviderAndContactInfo(this.account.id);
        });

        this.seletedcountry = this.account.country;
        if (result.country == '1') {
          this.countryState(1, this.account.state, null);
        }
        else {
          this.countryState(2, this.account.state, null);

        }
        if (this.account.customertypeid == 0 || this.account.customertypeid == undefined || this.account.customertypeid == null) {

          if (this.account.accnumber == '' || this.account.accnumber == '0' || this.account.accnumber == null || this.account.accnumber == undefined) {
            $('#accnumber').val('0');
            this.account.accnumber = '0';
          }
        }
        //document.getElementById('accountid').style.display = 'block';


      });


    }
    else {

    }

    var url = window.location.href;
    if (url.indexOf('account-contact/0/edit') > -1) {

      this.tableDivHGT = 300;
    }
    if (url.indexOf('account-contact') > -1 || url.indexOf('addAccount') > -1) {

      this.tableDivHGT = 300;
    }




  }

  AccountStisticboxclose() {
    this.accountstatisticBox = 'Close';
    this.AccountotherDetailsDiv = true;
  }
  AccountStisticboxOpen() {
    this.accountstatisticBox = 'Open';
    this.AccountotherDetailsDiv = false;

  }
  close() {
    if (this.tab === 'QuickView') {
      this.SixBoxExpand = false;
      this.sendMsgEvent.emit("Collapse");
    }
    $('#setDocumentDetails').val(0);   
    if (this.account.accnumber == '0') {
      this.GetCustomerNotes(this.account.id.toString(), 'Customer');
    }
    else {
      this.GetCustomerNotes(this.account.id.toString(), 'Customer');
    }
    this.Getall6boxesinfo(this.account.id.toString());
    this.accountotherDetailsTable = true;
    this.accountnotesinfo = false;
    this.accountemailinfo = false;
    this.showticketsinfo = false;
    this.documentsinfo = false;
    this.Proposalinfo = false;
    this.Projectsinfo = false;
    this.AccountStisticboxOpen();
    this.isDocumentsInfoDivVisible = false;
    this.AccountStisticboxclose();
 
  }
  expandaccountnotes(val) {
    this.historyName = val;
    this.typ = 'Account';
    this.conid = this.account.id;

    if (this.practiceid == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    } else {
      if (this.tab === 'QuickView') {
        this.isModal = true;   
        this.quickViewHistoryDialog = true;
        this.applicationComponent.quickViewDialogcss('#quickViewHistoryDialog .dialog');
        this.quickViewnotesDialog = true;
        this.quickViewActivitiesDialog = false;
        this.QuickViewDocumentsinfo = false;
        this.QuickViewProposalsinfo = false;
        this.QuickViewTicketsDialog = false;
        this.QuickViewEmailinfo = false;
        this.QuickViewOpportunityInfo = false;
        this.QuickViewProjectInfo = false;
        this.isModal = false;
      } else {
        this.isModal = false;
        this.accountnotesinfo = true;
        this.AccountStisticboxclose();
        this.accountotherDetailsTable = false;
        this.accountactivitiesinfo = false;
        this.accountemailinfo = false;
        this.showticketsinfo = false;
        this.documentsinfo = false;
        this.Projectsinfo = false;
        $('#accotIdNotes').val(this.account.id);
        $('#accotTypeNotes').val('Customer');
      }
    }
  }

  expandaccountemails(val) {
    this.historyName = val;
    if (this.practiceid == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    } else {
      if (this.tab === 'QuickView') {
        this.quickViewHistoryDialog = true;
 
        this.applicationComponent.quickViewDialogcss('#quickViewHistoryDialog .dialog');
        this.QuickViewEmailinfo = true;
        this.quickViewnotesDialog = false;
        this.quickViewActivitiesDialog = false;
        this.QuickViewDocumentsinfo = false;
        this.QuickViewProposalsinfo = false;
        this.QuickViewTicketsDialog = false;
        this.QuickViewOpportunityInfo = false;
        this.QuickViewProjectInfo = false;
      } else {
        this.isModal = false;
        this.accountotherDetailsTable = false;
        this.accountemailinfo = true;
        this.documentsinfo = false;
        this.Projectsinfo = false;
        this.accountactivitiesinfo = false;
        this.accountnotesinfo = false;
        this.showticketsinfo = false;
      }
      
    }
  }

  expandaccountactivities(name) {
    this.historyName = name;
    if (this.practiceid == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    } else {
      if (this.tab === 'QuickView') {
        this.quickViewHistoryDialog = true;  
        this.applicationComponent.quickViewDialogcss('#quickViewHistoryDialog .dialog');
        this.quickViewActivitiesDialog = true;
        this.quickViewnotesDialog = false;
        this.QuickViewDocumentsinfo = false;
        this.QuickViewProposalsinfo = false;
        this.QuickViewTicketsDialog = false;
        this.QuickViewEmailinfo = false;
        this.QuickViewOpportunityInfo = false;
        this.QuickViewProjectInfo = false;
      } else {
        this.isModal = false;
        this.accountotherDetailsTable = false;
        this.accountnotesinfo = false;
        this.accountemailinfo = false;
        this.showticketsinfo = false;
        this.documentsinfo = false;
        this.Projectsinfo = false;
        this.accountactivitiesinfo = true;
      }
      
      this.historyComp.getAllActivities(this.contactid, 'Customer');
    }
  }
  expandcampaigns(val) {  
    this.historyName = val;
    this.quickViewHistoryDialog = true;
    //if (this.showviewcampaign) {
    //  $('#quickViewHistoryDialog .dialog').attr('style', 'width:100%;padding:0;min-height:63%;overflow:auto;height:92%;top:8%;margin-right:0%;');
    //} else {
      this.applicationComponent.quickViewDialogcss('#quickViewHistoryDialog .dialog');
   // }
    this.quickViewCampaignsDialog = true;
    this.quickViewnotesDialog = false;
    this.QuickViewDocumentsinfo = false;
    this.QuickViewProposalsinfo = false;
    this.QuickViewTicketsDialog = false;
    this.QuickViewEmailinfo = false;
    this.QuickViewOpportunityInfo = false;
    this.QuickViewProjectInfo = false;
    this.quickViewActivitiesDialog = false;
    this.customerService.GetSpecialityList("CampaignStatus", this.currentuser.id).then((Rows) => {
      this.StatusDropdownList = Rows;
    });
  }
  expandsms(val) {
    this.historyName = val;
    this.quickViewHistoryDialog = true;
   
    this.applicationComponent.quickViewDialogcss('#quickViewHistoryDialog .dialog');
    this.quickViewCampaignsDialog = false;
    this.quickViewSMSDialog = true;
    this.quickViewnotesDialog = false;
    this.QuickViewDocumentsinfo = false;
    this.QuickViewProposalsinfo = false;
    this.QuickViewTicketsDialog = false;
    this.QuickViewEmailinfo = false;
    this.QuickViewOpportunityInfo = false;
    this.QuickViewProjectInfo = false;
    this.quickViewActivitiesDialog = false;
  }
  expandOpportunities(val) {
    this.historyName = val;
    if (this.practiceid == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    } else {
      if (this.tab === 'QuickView') {
    
        this.quickViewHistoryDialog = true;
        this.applicationComponent.quickViewDialogcss('#quickViewHistoryDialog .dialog');
     
        this.quickViewActivitiesDialog = false;
        this.quickViewnotesDialog = false;
        this.QuickViewDocumentsinfo = false;
        this.QuickViewProposalsinfo = false;
        this.QuickViewTicketsDialog = false;
        this.QuickViewEmailinfo = false;
        this.quickViewCampaignsDialog = false;
        this.QuickViewOpportunityInfo = true;
        this.QuickViewProjectInfo = false;
      } else {
        this.isModal = false;
        this.accountotherDetailsTable = false;
        this.accountnotesinfo = false;
        this.accountemailinfo = false;
        this.showticketsinfo = false;
        this.documentsinfo = false;
        this.Projectsinfo = false;
        this.accountactivitiesinfo = true;
      }

    }
  }
  expanddocuments(val,name) {
    this.historyName = name;
    if (this.practiceid == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    } else {

      if (this.tab === 'QuickView') {
        this.quickViewHistoryDialog = true;
      
        this.applicationComponent.quickViewDialogcss('#quickViewHistoryDialog .dialog');     
        this.quickViewActivitiesDialog = false;
        this.quickViewnotesDialog = false;
        this.QuickViewTicketsDialog = false;
        this.quickViewSMSDialog = false;
        this.isDocumentsInfoDivVisible = true;
        this.QuickViewEmailinfo = false;
        this.QuickViewOpportunityInfo = false;
        this.QuickViewProjectInfo = false;
        if (val == 'With Proposal') {
          this.QuickViewDocumentsinfo = false;
          this.customerService.GetSpecialityList("ProposalPo", this.currentuser.id).then((status) => {           
            this.postatuslist = status;
          });
          this.QuickViewProposalsinfo = true;
         
        } else {
          this.QuickViewDocumentsinfo = true;
          this.QuickViewProposalsinfo = false;
        }

      } else {
        $('#setDocumentDetails').val(1);
        this.accountotherDetailsTable = false;
        this.documentsinfo = true;
        this.accountnotesinfo = false;
        this.accountemailinfo = false;
        this.showticketsinfo = false;
        this.accountactivitiesinfo = false;
        this.Projectsinfo = false;
        this.isDocumentsInfoDivVisible = true;

        if (val == 'With Proposal') {
          this.documentsinfo = false;
          this.Proposalinfo = true;
        } else {
          this.documentsinfo = true;
          this.Proposalinfo = false;
        }

      }
      this.loadDocFilterDropDown(val);
    }
  }

  loadDocFilterDropDown(val) {

    if (val == 'With Proposal') {
      this.DocumentsFilter = Array.from(new Set(this.POdocumentlist.map(s => s.documentname)))
        .map(contacttype => {
          return {
            Name: ((this.POdocumentlist.filter(x => x.documentname == contacttype)[0].documentname != null) ? this.POdocumentlist.filter(x => x.documentname == contacttype)[0].documentname : '') + " (" + this.POdocumentlist.filter(x => x.documentname == contacttype).length + ")",
            Value: contacttype
          }
        });
    } else {
      this.DocumentsFilter = Array.from(new Set(this.userdocumentlist.map(s => s.documentname)))
        .map(contacttype => {
          return {
            Name: ((this.userdocumentlist.filter(x => x.documentname == contacttype)[0].documentname != null) ? this.userdocumentlist.filter(x => x.documentname == contacttype)[0].documentname : '') + " (" + this.userdocumentlist.filter(x => x.documentname == contacttype).length + ")",
            Value: contacttype
          }
        });
    }

  }
  DocumentsFilterBy(event) {
    this.docMgmtComp.DocumentsFilterBy(event)
  }
  DocumentsStatusBy(event) {
    this.docMgmtComp.DocumentsStatusBy(event)
  }
  expandtickets(val) {
    this.historyName = val;
    if (this.tab === 'QuickView') {
   
      this.quickViewHistoryDialog = true;
      this.applicationComponent.quickViewDialogcss('#quickViewHistoryDialog .dialog');
      this.QuickViewDocumentsinfo = false;
      this.QuickViewProposalsinfo = false;
      this.quickViewActivitiesDialog = false;
      this.quickViewnotesDialog = false;
      this.QuickViewEmailinfo = false;
      this.QuickViewTicketsDialog = true;
      this.QuickViewOpportunityInfo = false;
      this.QuickViewProjectInfo = false;
    } else {
      this.accountotherDetailsTable = false;
      this.Projectsinfo = false;
      this.showticketsinfo = true;
      this.isTicketsInfoDivVisible = true;
    }

  }



  expandProjects(val) {
    this.historyName = val;
    if (this.tab === 'QuickView') {  
      this.quickViewHistoryDialog = true;
      this.applicationComponent.quickViewDialogcss('#quickViewHistoryDialog .dialog');
    
      this.QuickViewDocumentsinfo = false;
      this.QuickViewProposalsinfo = false;
      this.quickViewActivitiesDialog = false;
      this.quickViewnotesDialog = false;
      this.QuickViewEmailinfo = false;
      this.QuickViewTicketsDialog = false;
      this.QuickViewOpportunityInfo = false;
      this.QuickViewProjectInfo = true;
    } else {
      this.accountotherDetailsTable = false;
      this.showticketsinfo = false;
      this.isTicketsInfoDivVisible = false;
      this.accountnotesinfo = false;
      this.accountemailinfo = false;
      this.documentsinfo = false;
      this.Projectsinfo = false;
      this.accountactivitiesinfo = false;

      this.Projectsinfo = true;
    }

  }

  refresh() {
    this.account.city = null;
    this.account.state = null;
    this.account.zip = null;

  }
  countryState(id, state, type) {
     
    this.SelectedCountryId = id;
    this.stateTextBox = true;
    this.stateDropDown = true;
    var $stateDropdownDiv = $('#stateDropDown').parent().parent();

    if (id == 1) {

      $('#citySateZipSpan').html('City/State/Zip');
      $('#citySateZipforContacts').html('City/State/Zip');
      $('#contactCityDiv').css('display', 'block');
      this.getSelectedState('all');
      this.stateTextBox = true;
      this.stateDropDown = false;
      this.displayZipCode = true;
      this.displayCountryName = false;
      $stateDropdownDiv.removeClass('col-xs-6');
      $stateDropdownDiv.addClass('col-xs-3');
      $stateDropdownDiv.prop("style", "padding-right:0px");
      $('#stateDropDown').prop("placeholder", "Select State");
      if (type == 'Refresh') { this.refresh(); }
      if (this.seletedcountry !== null && this.seletedcountry != undefined && this.seletedcountry == 1) {
        if (state != undefined || state != null)
          $('#stateDropDown').val(state);
      }
      else
        $('#stateDropDown').val('');
      this.countryId == 1;
    }
    else if (id == 2) {
      $('#citySateZipSpan').html('City/State/Zip');

      $('#citySateZipforContacts').html('City/State/Zip');

      this.getSelectedState('bahamas');
      //  document.getElementById('contactCityDiv').style.display = 'none';
      this.stateTextBox = true;
      this.stateDropDown = false;
      this.displayZipCode = false;
      this.displayCountryName = true;
      $stateDropdownDiv.removeClass('col-xs-3');
      $stateDropdownDiv.addClass('col-xs-6');
      $stateDropdownDiv.prop("style", "padding:0px");
      $('#stateDropDown').prop("placeholder", "Select Island");
      if (this.seletedcountry !== null && this.seletedcountry != undefined && this.seletedcountry == 2) {
        if (state != undefined || state != null)
          $('#stateDropDown').val(state);
      }
      else
        $('#stateDropDown').val('');
      $('#displayCountry').val('Bahamas');
      this.countryId == 2;
    }
    else {
      //this.account.city = null;
      //this.account.state = null;
      //this.account.zip = null;
      if (type == 'Refresh') { this.refresh(); }
      // $('#stateTextBox').val('');

      $('#stateTextBox').prop("placeholder", "Select State");
      this.stateTextBox = false;
      this.stateDropDown = true;
    }
  }

  changeCityStateZip(id, state) {



    var $stateDropdownDiv = $('#contactstateTextBox').parent();
    if (id == 1) {
      $('#citySateZipforContacts').html('City/State/Zip');
      this.getSelectedState('all');
      this.stateTextBox = true;
      this.stateDropDown = false;
      this.displayZipCode = true;
      this.displayCountryName = false;
      $stateDropdownDiv.removeClass('col-xs-6');
      $stateDropdownDiv.addClass('col-xs-3');
      $stateDropdownDiv.prop("style", "padding-right:0px");
      $('#contactstateTextBox').prop("placeholder", "Select State");
      $('#contactstateTextBox').val(state);
      $('#contactCityDiv').removeClass('col-xs-6');
      $('#contactCityDiv').addClass('col-xs-6');
      this.countryId == 1;
    }
    else if (id == 2) {
      $('#citySateZipforContacts').html('City/State/Zip');
      this.getSelectedState('bahamas');
      this.stateTextBox = true;
      this.stateDropDown = false;
      this.displayZipCode = false;
      this.displayCountryName = true;
      $stateDropdownDiv.removeClass('col-xs-3');
      $stateDropdownDiv.addClass('col-xs-6');
      $stateDropdownDiv.prop("style", "padding:0px");
      $('#contactstateTextBox').prop("placeholder", "Select Island");
      $('#contactstateTextBox').val(state);

      $('#contactCityDiv').removeClass('col-xs-6');
      $('#contactsDisplayCountry').val('Bahamas');
      this.countryId == 2;
    }
  }
  getSelectedState(country) {
    this.customerService.GetAllStateList(country).then((state) => {
      state.forEach(accounttype => {
      });
      this.StatesList = state;
      this.stateInfo = state;

    });
  }
  statePopulate(zipid, cityid, stateid, stateDDN) {
    var that = this;
    var zipCode = $('#' + zipid).val();
    var newStr = zipCode.replace(/_/g, "");
    var newStrArray = newStr.split("-");
    if (newStrArray[0].length > 4) {
      var url = "http://ziptasticapi.com/" + newStrArray[0];
       
      $.ajax({
        url: url,
        dataType: "json",
        type: 'GET',
        success: function (data) {
          $('#' + cityid).val(data.city);
          that.loadstate(data.state, stateid, stateDDN);
          if (zipid == 'contactzip') {
            that.contacts.city = data.city;

          }
          if (zipid == 'contacthomezip') {
            that.contacts.homecity = data.city;

          }
          else {
            that.account.city = data.city;

          }
        }

      });
    }
  }

  locstatePopulate(ival, zipid, cityid, stateid, stateDDN) {
     
    var that = this;
    var zipCode = $('#' + ival + zipid).val();
    var newStr = zipCode.replace(/_/g, "");
    var newStrArray = newStr.split("-");
    if (newStrArray[0].length > 4) {
      var url = "http://ziptasticapi.com/" + newStrArray[0];
      $.ajax({
        url: url,
        dataType: "json",
        type: 'GET',
        success: function (data) {

          $('#' + ival + cityid).val(data.city);
          that.locloadstate(ival, data.state, stateid, stateDDN, data.city);

        }

      });
    }
  }
  statePopulateAddLoction(id) {

    var zipId = 'zip_' + id;
    var stateId = 'state_' + id;
    var cityId = 'city_' + id;
    var zipCode = $('#' + zipId).val();
    var newStr = zipCode.replace(/_/g, "");
    var newStrArray = newStr.split("-");
    if (newStrArray[0] > 4) {
      var url = "http://ziptasticapi.com/" + newStrArray[0];
      $.ajax({
        url: url,
        dataType: "json",
        type: 'GET',
        success: function (data) {
          $('#' + cityId).val(data.city);
          $('#' + stateId).val(data.state);
        }

      });
    }
  }
  locloadstate(ival, state, stateid, stateDDN, city) {

    $('.selectedState').removeClass('selectedState');
    var countryCode = ($('#country').val());
    if (countryCode == 1 && stateDDN == 'locstateDropDown') {
      for (var i = 0; i < 50; i++) {
        if (this.StatesList[i].itemName.trim().toString() == state) {
          $('#' + ival + stateDDN).val(state)
          $('#' + this.StatesList[i].id).addClass('selectedState');
          this.heroForm.value.secretLairs[ival].state = state;
          this.heroForm.value.secretLairs[ival].city = city;

        }
      }
    } else {
      $('#' + ival + stateid).val(state);
      this.heroForm.value.secretLairs[ival].state = state;


    }
  }
  loadstate(state, stateid, stateDDN) {
    $('.selectedState').removeClass('selectedState');
    var countryCode = ($('#country').val());
    if (countryCode == 1 && stateDDN == 'stateDropDown') {
      for (var i = 0; i < 50; i++) {
        if (this.StatesList[i].itemName.trim().toString() == state) {
          $('#' + stateDDN).val(state)
          $('#' + this.StatesList[i].id).addClass('selectedState');
          this.account.state = state;
        }
      }
    } else {
      $('#' + stateid).val(state);

      if (stateid == 'contactstateTextBox') {
        this.contacts.state = state;

      }
      if (stateid == 'contacthomestate') {
        this.contacts.homestate = state;

      }
      else {
        this.account.state = state;

      }

    }
  }

  GetStateByID(stateId) {

    if (/^\d+$/.test(stateId)) {
      for (var i = 0; i < 50; i++) {
        if (this.StatesList[i].id.toString() == stateId) {
          this.contacts.state = this.StatesList[i].itemName;
        }
      }
    }
    else {
      this.contacts.state = stateId;
    }


  }

  ResetAccount() {

    this.account.id = 0;
    this.account.customertypeid = 0;
    this.account.country = "1";
    this.account.accounttype = null;
    this.account.accountname = null;
    this.account.ownerid = null;
    this.account.state = null;
    this.account.city = null;
    this.account.practiceid = 0;
    this.account.address1 = null;
    this.account.address2 = null;
    this.account.zip = null;
    this.account.phone = null;
    this.account.fax = null;
    this.account.alterphone1 = null;
    this.account.ownership = null;
    this.account.alterphone2 = null;
    this.account.website = null;
    this.account.ownerid = null;
    this.account.multiplelocations = 0;
    this.locationId = 0;
    this.locationCount = 1;
    this.account.rcm = null;
    this.account.ehr = null;
    this.account.pm = null;
    this.account.ownerid = this.currentuser.id;
    this.accountlocations = new Accountlocations();
    this.account.speciality = null;
    this.account.accountStatus = 0;
    this.countryState(1, null, null);
    $('#test').val('');
  }

  getyears() {
    var range = [];
    var year = new Date().getFullYear();
    for (var i = 0; i < 7; i++) {
      range.push({
        label: year + i,
        value: parseInt(String(year + i).slice(2, 4))
      });
    }


    this.Years = range;
  }
  showStateList() {
    document.getElementById('stateList').style.display = 'block';

  }
  hideStateList() {
    document.getElementById('stateList').style.display = 'none';
  }
  locshowStateList(i) {
    document.getElementById(i + 'locstateList').style.display = 'block';

  }
  lochideStateList(i) {
    document.getElementById(i + 'locstateList').style.display = 'none';
  }
  contactsShowStateList() {

    document.getElementById('contactsStateList').style.display = 'block';
  }
  contactsHideStateList() {

    document.getElementById('contactsStateList').style.display = 'none';
  }
  selectState(id, name) {
    $('.selectedState').removeClass('selectedState');
    $('#' + id).addClass('selectedState');
    $('#stateDropDown').val(name);
    this.account.state = name;
  }
  locselectState(ival, id, name) {

    $('.selectedState').removeClass('selectedState');
    $('#' + id).addClass('selectedState');
    $('#' + ival + 'locstateDropDown').val(name);
    this.heroForm.value.secretLairs[ival].state = name;

  }
  contactsSelectState(id, name) {
    $('.selectedState').removeClass('selectedState');
    $('#cont_' + id).addClass('selectedState');
    $('#contactstateTextBox').val(name);
    this.contacts.state = name;
  }
  nextStatistics() {
    var count = this.accountstaticsticsId
    count++;
    if (count <= this.Accountstatisticlist.length) {
      document.getElementById('accountstatisticsCount').innerHTML = '(' + count + ' of ' + this.Accountstatisticlist.length + ')';
      this.accountstaticsticsId++;
      var id = this.accountstaticsticsId;
      $('.statistic').attr('style', 'display:none');
      $('#' + id).attr('style', 'display:block;background:white;');
      $('#' + id + '_accountsub').attr('style', 'display:block;background:white;height:102px;overflow:auto;');
      document.getElementById('previous').style.display = 'block';
      if (count == this.Accountstatisticlist.length) {
        document.getElementById('next').style.display = 'none';
      }
    }

  }
  previousStatistics() {
    var count = this.accountstaticsticsId
    count--;
    if (count > 0) {
      document.getElementById('accountstatisticsCount').innerHTML = '(' + count + ' of ' + this.Accountstatisticlist.length + ')';
      this.accountstaticsticsId--;
      var id = this.accountstaticsticsId;
      $('.statistic').attr('style', 'display:none');
      $('#' + id).attr('style', 'display:block;background:white;');
      $('#' + id + '_accountsub').attr('style', 'display:block;background:white;height:102px;overflow:auto;');
      document.getElementById('next').style.display = 'block';
      if (count == 1) {
        document.getElementById('previous').style.display = 'none';
      } else {
        document.getElementById('previous').style.display = 'block';
      }
    }
  }
  CampaignReceived(msg) { 
    $('#quickViewHistoryDialog .dialog').attr('style', 'width:100%;padding:0;min-height:63%;overflow:auto;height:92%;top:8%;margin-right:0%;');
    //this.showviewcampaign = true;
  }
  SavedDocuments(msg) {
    if (msg != "496" && msg != "497" && msg != "498" && msg != "499" && msg != "500" && msg != "501" && msg != "554") {
      this.uploadDocumentShow = false;
      this.customerService.GetAllDocumentList(this.account.id.toString(), 'Account', this.currentYear, 'Account').then((document) => {
        document.forEach(document => {
          document.DateUploaded = this.datePipe.transform(document.DateUploaded, "MM-dd-yyyy");
        });
        this.Originaluserdocumentlist = document;
        this.Getall6boxesinfo(this.account.id.toString());
      });
    }
    
  }
  expandDiv() {
    var ExpandVal = $('#expandid').html();
    //AccountViewDiv
    if (ExpandVal == "X") {
      this.expanded = false;
      $('#expandid').html('<img _ngcontent-c34="" src="../../../assets/img/Expand View.png" style="height:20px">')
      $('#LeftMainDiv').removeClass('col-xs-12')
      $('#LeftMainDiv').addClass('col-xs-6')

      this.AccountstatisticsBoxDuplicate = false;
      //$('#AccountViewDiv').removeClass('col-xs-12 col-sm-12 col-md-12');
      //$('#AccountViewDiv').add('col-xs-6 col-sm-6 col-md-6');
      this.rightMainDivHideShow = true;
    } else {
      this.AccountstatisticsBoxDuplicate = true;
      this.expanded = true;
      $('#expandid').html('X');
      $('#LeftMainDiv').removeClass('col-xs-6')
      $('#LeftMainDiv').addClass('col-xs-12')      
      this.rightMainDivHideShow = false;
    }

  }
  Expandzendesk() {
    if (this.zendtype = 'Expand') {
      document.getElementById('zendesktype').innerHTML = "Close";
      this.zendtype = "Close";
    } else {
      document.getElementById('zendesktype').innerHTML = "Expand";
      this.zendtype = "Expand";
    }
  }
  IsButtonAccess(btn, type) {
    this.toastr.clear();
    if (this.userprevilages != undefined) {
      for (var j = 0; j < this.userprevilages.length; j++) {
        if (btn == this.userprevilages[j].resource) {
          if (type == "add" && this.userprevilages[j].canadd == false) {
            this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }
          if (type == "edit" && this.userprevilages[j].canedit == false) {
            this.toastr.warning("You Do Not have Access to edit – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }
        }

      }
    }

  }

  resetAccountNumber() {
    if (this.account.id != 0) {

      if ($('#customertypeid').val() != 0) {
        if (this.account.accnumber == '' || this.account.accnumber == '0') {
          $('#accnumber').val('');
          this.account.accnumber = null;
        }
      } else {
        if (this.account.accnumber == '' || this.account.accnumber == '0' || this.account.accnumber == null) {
          $('#accnumber').val('0');
          this.account.accnumber = '0';
        }
      }
    }
  }
  loadcontacttitles(val): Promise<any> {
     
    const promise = new Promise((resolve, reject) => {

      this.customerService.GetTitleListbycontacttype(val).then((accounttype) => {
        this.TitleList = accounttype;
        this.contacts.title = null;
        if (val == 'Physician' || val == 'DPM' || val == 'NP' || val == 'PT' || val == 'PA') {
          $('#title').removeClass('required');
          $('#title').addClass('not-required');
          document.getElementById('titlespan').style.display = 'block';

        } else {
          $("#title").prop("required", false)
          $('#title').removeClass('not-required');
          $('#title').removeClass('required');
          document.getElementById('titlespan').style.display = 'none';

        }
        //resolve();
      });

    });
    return promise;
  }
  special_char(event) {

    var k = event.charCode;
    return (!(k === 124));
  }
  accountNumber() {
    if ($('accnumber').val() == '') {
      if ($('#customertypeid').val() == 0) {
        this.account.accnumber = '0';
      } else {
        this.account.accnumber = null;
      }
    }
  }
  titleSelected() {
    if ($('#title').hasClass('not-required')) {
      $('#title').removeClass('not-required')
      $('#title').addClass('required')

    }
  }
  collegeYear1() {
    $('#collegegrandyear').removeAttr('disabled');
  }
  medschoolYear1() {
    $('#medschoolgrandyear').removeAttr('disabled');
  }
  residencyYear1() {
    $('#residencygrandyear').removeAttr('disabled');
  }
  fellowshipYear1() {
    $('#fellowshipgrandyear').removeAttr('disabled');
  }
  removeloction(val) {
    if (val == 0) {
      this.account.multiplelocations = 0;
    }
    $("#" + (val + 2)).remove();
    this.secretLairs.removeAt(val);
  }
  createForm() {
    this.heroForm = this.fb.group({
      name: '',
      secretLairs: this.fb.array([]),
      power: '',
      sidekick: ''
    });
  }


  get secretLairs(): FormArray {
    return this.heroForm.get('secretLairs') as FormArray;
  };

  setAddresses(addresses: Accountlocations[]) {

    const addressFGs = addresses.map(address => this.fb.group(address));
    const addressFormArray = this.fb.array(addressFGs);
    this.heroForm.setControl('secretLairs', addressFormArray);
  }
  prepareSaveHero(): Accountlocations[] {

    const formModel = this.heroForm.value;
    const secretLairsDeepCopy: Accountlocations[] = formModel.secretLairs.map(

      (address: Accountlocations) => Object.assign({}, address, address.accountid = this.account.id),
    );
    return secretLairsDeepCopy;
  }
  rowClickAgGrid(rowEvent) {

    if ((this.Ids).indexOf(rowEvent.data.id) > -1) {
      var index = (this.Ids).indexOf(rowEvent.data.id);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      if ($('#emailIds').val() != '') {
        $('#tasklistselectedtyle').val('Contact');
      }
     
    } else {
      this.Ids.push(rowEvent.data.id)
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      $('#tasklistselectedtyle').val('Contact');
    }
    
  }
  statisticId(id) {
  }

  AddressReceived(msg: Accounts) {
    if (this.type == "Contact") {
      this.contacts.address1 = msg.address1;
      this.contacts.address2 = msg.address2;
      this.contacts.city = msg.city;
      this.contacts.state = msg.state;
      this.contacts.zip = msg.zip;
      this.contacts.ProvidenceIsland = msg.ProvidenceIsland;
      this.contacts.lattitude = msg.lattitude.toString();
      this.contacts.longtitude = msg.longtitude.toString();
      this.contacts.country = msg.country;
      this.contacts.isaddressadded = msg.isaddressadded;
    } else {
      this.getAccountForm();
      this.account.address1 = msg.address1;
      this.account.address2 = msg.address2;
      this.account.city = msg.city;
      this.account.state = msg.state;
      this.account.zip = msg.zip;
      this.account.ProvidenceIsland =  msg.ProvidenceIsland;
      this.account.lattitude = msg.lattitude;
      this.account.longtitude = msg.longtitude;
      this.account.country = msg.country;
      this.account.isaddressadded = msg.isaddressadded;
    }
  
  }
  openNotesDialogAg(Contactid) {
  
    this.conid = Contactid;
    this.typ = 'Contact';
    this.notesshowDialog = !this.notesshowDialog;
    this.NotesDialogCss();
  }
  
  QuickViewDialogCss(val) {
    if (val == true) { //expand
      this.applicationComponent.StandardGreenpopupUi('.dialog');
     
    }
    
    else {
      this.applicationComponent.quickViewDialogcss('.dialog');
    
    }

  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 43%;padding: 0;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }
  openAccountNotes() {
    this.isModal = true;
    this.conid = this.account.id;
    this.typ = 'Account';
    this.notesshowDialog = true;
    this.NotesDialogCss();   
  }
  openFutureTask() {
    this.futureTaskDialog=true;
  }
  GetCustomerNotes(contactid, type) {
    this.customerService.GetAllContactproviderByAccount(contactid, this.currentuser.id).then((contacts) => {
      let allContactIds: string = "";
      if (contacts) {
        contacts.forEach(contacts => {
          allContactIds += contacts.id.toString() + ",";
        });
      }
      if (allContactIds.length > 0)
        allContactIds = allContactIds.substring(0, allContactIds.length - 1);
      else
        allContactIds = "0";
      //this.customerService.GetAllNotesList(this.currentuser.id, allContactIds, 'AllContactsNotes', contactid).then((taskhistory) => {
      this.customerService.GetAllNotesListNew(this.currentuser.id, allContactIds, 'AllContactsNotes', contactid, "null").then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy");
      });
     
        this.allnotes = taskhistory;
        this.noteslist = this.allnotes.filter(x => x.isnewnote == 1); 
           for (let note of this.allnotes) {
        if (note.isdocumentid != null && note.isdocumentid != "") {
          note.docList = note.isdocumentid.split(",");
        }
      }
    });
    });
    this.conid = contactid;

  }

  GetNotes(contactid, type) {
    this.customerService.GetAllNotesList(this.currentuser.id, contactid, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy");
        //this.data.push(taskhistory.)
      });
      this.allnotes = taskhistory;
    });

  }
  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: true, enableColResize: true, paginationPageSize: 1000, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.NotesideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ], position: 'right',
      defaultToolPanel: 'none',
    };  
    this.createColumnDefsnotes();

    this.context = this;
  }
  createColumnDefsnotes() {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', hide: false, cellStyle: { 'text-align': 'left' }, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];

  }

  onAgGridReadynotes(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllnotes();
      $(setTimeout(function () {
        selfObj.autoSizeAllnotes();
      }, 1000));
    }, 1000));
  }
  autoSizeAllnotes() {
    var allColumnIds = [];
    if (this.gridOptionsnotes && this.gridOptionsnotes.columnApi) {
      var allCols = this.gridOptionsnotes.columnApi.getAllColumns();
      if (allCols != null) {
        allCols.forEach(function (column) {
          allColumnIds.push(column.getColId());
        });
      }
      this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds);
    }

  }
  Notes(val) {
    this.GetCustomerNotes(this.contacts.id, val);
  }
  enterNotes() {
    $('.fr-element').empty();
    document.getElementById('home').style.display = 'none';
    document.getElementById('noteid').style.display = 'block';
    document.getElementById('saveNotesButtons').style.display = 'block';
    document.getElementById('divTable').style.display = 'none';
  }
  SaveNote(notestype) {
     
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/img, "");
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/gi, "");
    this.notes.notedescription = this.notes.notedescription.replace(/<[^>]*>/g, '');
    if (notestype == "Customer") {
      this.notes.accountid = this.account.id;
    } else {
      this.notes.accountid = this.contacts.id;
    }
    this.notes.type = notestype;
    this.customerService.SaveNotes(this.notes).then(result => {
      this.GetCustomerNotes(this.notes.accountid, notestype);
      $('#notesListTabsId .active').removeClass('active');
      $('#notesListTabsId #allNotesId').addClass('active');
      this.CancelNote();
    });
  }
  CancelNote() {
    $('.fr-element').empty();
    document.getElementById('home').style.display = 'block';
    document.getElementById('noteid').style.display = 'none';
    document.getElementById('saveNotesButtons').style.display = 'none';
    document.getElementById('divTable').style.display = 'block';
  }

  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
     
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();
    config.maxlimit = 25;
    config.columnsselections = "";
    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 23;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }

  AddAccountTask() {
     
    if (this.practiceid == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    } else {
      this.accounttaskDialog = true;
      this.accountactivitiesinfo = false;
    }
  }
  onTaskSaved(msg) {
    this.Getall6boxesinfo(this.account.id);
    if (msg = "Cancel") {
      this.accounttaskDialog = false;
      this.accountactivitiesinfo = true;
    }
  }
  Refresh(msg) {
  }
  PreviousAccountContact() {
    this.sendMsgEvent.emit("Previous");
  }
  NextAccountContact() {
    this.sendMsgEvent.emit("Next");
  }
  public transform(input: string): string {

    if (!input) {
      return '';
    }
    else {

      return input[0].toUpperCase() + input.substr(1);
    }

  }

  openDisplaynotes(params) {
    $('#closeDetailsDialog').click();
    this.NotesDec = true;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    var notesOffset = $("#divNotesContainer").offset();
    let notesWidth = $('#divNotesContainer').width();
    let notesHeight = $('#divNotesContainer').height();
    let windowWidth = $(window).width();
    let showDialogWidth = 350;

    let selfObj = this;
    $(setTimeout(function () {
       
      //$('#showDetailsDialog').width(showDialogWidth);
      //$('#showDetailsDialog').height(notesHeight - 140);
      //if ($('#typeClicked').val() == 'modal') {
      if (selfObj.isModal) {
        //$('#showDetailsDialog').css('top', '23%');
        //$('#showDetailsDialog').css('height', '61%');
        //$('#showDetailsDialog').css('left', notesWidth - showDialogWidth);
        //$('#showDetailsDialog').css('top', notesOffset.top + 125);
        //$('#showDetailsDialog').css('left', notesWidth - showDialogWidth);
        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '81%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '14%');
        } else {
          $('#txtLongNoteDesc').css('height', '96%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      } else {
       
        var gridOffset = $("#notesGrid").offset();
             $('#showDetailsDialog').attr('style', 'width: 16%;height: 71%;top: 24.5%;left: 82%;border-radius: 4px;border: 1px solid;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '53%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '33%');
        } else {
          $('#txtLongNoteDesc').css('height', '89%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtLongNoteDesc').css('overflow', 'auto');
        }
      }
     
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));
  }

  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", 'white');
    $('.save').show();
   
  }
  onDocumentOpen(myBaseString) {
    var viewtype = "AccountView";
    document.getElementById('pdfDiv').style.display = 'block';
    document.getElementById('window').className = 'col-xs-6';
    document.getElementById('accountInformation').className = 'col-xs-12';
   
    $('#pdfDiv').html('<embed src="' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
    $('#pdfDiv').attr('style', 'height: 562px;padding-left: 1%;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');

  }
  OpenTask(contactId, taskid, type) {
    this.id = contactId;
    this.taskid = taskid;
    this.type = type;
    this.isTaskShow = true;
  }
  Expandimage() {
    if (document.getElementById('pdfDiv').className == 'col-xs-12') {
      document.getElementById('window').style.display = 'block';
      document.getElementById('pdfDiv').className = 'col-xs-6';
      $('#documentview').removeClass('col-xs-12');
      $('#documentview').addClass('col-xs-6');
    } else {
      document.getElementById('window').style.display = 'none';
      document.getElementById('pdfDiv').className = 'col-xs-12';
      $('#documentview').removeClass('col-xs-6');
      $('#documentview').addClass('col-xs-12');
    }
  }
  onDocumentSavedSaved(msg) {
    var isnum = /^\d+$/.test(msg);

    if (msg == 'UploadDocument') {
      this.uploadDocumentShow = true;
      let objSelf = this;
      $(setTimeout(function () {
        objSelf.uploadDoc.OpenFilemanual();
      }, 100));
    }
    else if (isnum) {
      this.customerService.GetDocumentsCount(msg, 'Account').then(result => {
        this.userdocumentcount = result;

      });
    } else if (msg == 'close') {
      this.close()
    }
    else {
      this.documentview = true;
      this.onDocumentOpen(msg);
    }

  }
  saveEditedNote() {
     
    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.closeDetailsDialog();
        selfObj.GetNotes(selfObj.contactid, 'Contact');
      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
    //this.Notes(this.selectedTabName);
  }
  previousdocument() {
    this.documentindex--;
    $('#pdfDiv').html('<embed src="' + this.Originaluserdocumentlist[this.documentindex].imagecontent + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
  }
  nextdocument() {
    this.documentindex++;
    $('#pdfDiv').html('<embed src="' + this.Originaluserdocumentlist[this.documentindex].imagecontent + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
  }
  AccountsharingAg(selectedval) {
    if (this.practiceid == "view") {
      this.applicationComponent.isAccessDenied = true;
      this.dialogCss();
    } else {

      if (selectedval == "EditAccount") {
        this.customerService.getAccountPermission(this.account.id, this.currentuser.id).then(perresult => {
          this.account.isAccountPermission = perresult;
          if (perresult == 'View') {
            this.showAddEditAccount = false;
            this.applicationComponent.isAccessDenied = true;
            this.dialogCss();
          } else {
            this.isSharing = false;
            this.action = selectedval;
            this.showAddEditAccount = true;
            this.showAddEditAccountCss();
          }
          
        });
        
      }
      else if (selectedval == "View") {
        this.isSharing = false;
        this.action = selectedval;
        this.showAddEditAccount = true;
        this.showAddEditAccountCss();
      }
      else {
        this.isSharing = true;
          this.isSharingCss();
        this.rightMainDivHideShow = false;
        this.accountSharingid = 1;
        this.AddContacttableDiv = false;
        if (this.isSharing) {
          this.ownrshipComp.loadAccountInformation(this.account.id, 0);
        }
        
      }
    }

  }

  dialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', ' width: 32%;padding - bottom: 0px;border-radius:5%;min - height: 23%;overflow: visible;height: 24%;');
    }, 4));
  }

  isSharingCss() {
    setTimeout(function () {
      $('#isSharingDialog .dialog').attr('style', 'width: 35%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82.5%;padding: 0;top: 10%;left: 63%;');
    }, 4)

  }
  sharingAg(rowdata) {
    if($('#expandid').html() == "X") {
      $('#expandid').html('<img _ngcontent-c34="" src="../../../assets/img/Expand View.png" style="height:20px">')
      $('#LeftMainDiv').removeClass('col-xs-12')
      $('#LeftMainDiv').addClass('col-xs-6')
    }
    if (this.account.ownerid == this.currentuser.id || this.currentuser.systemadministrator == 1) {
      this.isSharing = true;
      this.isSharingCss();
      //this.rightMainDivHideShow = false;
      this.accountSharingid = 1;
      this.contactSharingid = rowdata.id;
      let selfObj = this;
      $(setTimeout(function () {


        selfObj.gridOptions.api.forEachNode((node) => {

          if (node.data.id == rowdata.id) {
            selfObj.gridOptions.api.selectNode(node, true);

          }
        });
      }, 100));
      selfObj.ownrshipComp.loadAccountInformation(selfObj.account.id, rowdata.id);

    }


  }
  onCloseEventReceived(msg) {
    this.rightMainDivHideShow = true;

    if (msg == 'AccountEdit') {
      this.isSharing = false;
      this.EditAccountFormDiv = true;
      this.editAccount(this.account.id);
    }
    else if (msg == 'ContactEdit') {
      this.isSharing = false;
      this.LoadContactEdit(this.contactSharingid);

    }
    else {
      this.isSharing = false;
      this.EditAccountFormDiv = false;
      this.AccountotherDetailsDiv = true;
      this.GetAllProviderAndContactInfo(this.account.id);
  
      this.accdetailcmp.loadAccountId(this.account.id, this.viewaccount.AccountHistoryNote);
    }

  }
  Taskdialoghideshow(tdialog: boolean) {
    this.isTaskShow = tdialog;
  }

  

  Map() {
    this.isOpenGoogleMap = true;
  }

  closeEmbed() {
    this.documentview = false;
    $('#window').removeClass('col-xs-6');
    $('#window').addClass('col-xs-12');
    $('#pdfDiv').attr('style', 'display: none;');
    this.close();
    document.getElementById('window').style.display = 'block';
    document.getElementById('pdfDiv').className = 'col-xs-6';

  }
  onCloseDialogEventReceived(msg) {
   
    if (msg == 'false') {
      this.notesshowDialog = false;
      this.isModal = false;
      this.ShowPDF = false;
    }
  }

  ContactTypeFilter(val) {
     
    if (val == 'All') {
      this.Contacttotal = this.allContacts;
      if (this.SelectedStatus != 0) {
        this.allContactsproviders = this.allContacts.filter(x => x.Status == this.SelectedStatus);
      } else {
        this.allContactsproviders = this.allContacts;}
    } else {
      this.Contacttotal = this.allContacts.filter(x => x.contacttype == val);
      if (this.SelectedStatus != 0) {
        this.allContactsproviders = this.allContacts.filter(x => x.contacttype == val && x.Status == this.SelectedStatus);
      }
      else {
        this.allContactsproviders = this.allContacts.filter(x => x.contacttype == val);
      }
      
    }
    this.SortedStatus = [
      { id: 0, Value: 'Active & Inactive' + " (" + this.Contacttotal.length + ")" },
      { id: 309, Value: 'Active' + " (" + this.Contacttotal.filter(x => x.Status == 309).length + ")" },
      { id: 310, Value: 'Inactive' + " (" + this.Contacttotal.filter(x => x.Status == 310).length + ")" }

    ];

  }
  ContactStatusFilter(val) {
    if (val == 0) {
      if (this.SelectedContactType == 'All')
        this.allContactsproviders = this.allContacts;
      else {
        this.allContactsproviders = this.allContacts.filter(x => x.contacttype == this.SelectedContactType);
      }
    } else {
      if (this.SelectedContactType != 'All') {
        this.allContactsproviders = this.allContacts.filter(x => x.Status == val && x.contacttype == this.SelectedContactType);
      }
      else {
        this.allContactsproviders = this.allContacts.filter(x => x.Status == val);
      }
      
    }
    //this.SelectedContactType = "All";
  }
  ReceiveTaskUpdate(event) {

  }

  checkBoxChecked(val) {
    this.selectedAddressOption = val;
    if ($('#' + val).is(":checked")) {
      if (val == 'SelectContacts') {
        this.EnableDisableCheckbox(false, true, false);
        if (this.selectedcontact == null || this.selectedcontact == undefined) {
          alert("Please select Contact");
        } else {
          this.showContinueButton = false;
        }
        this.ShowContactstoSelect = true;

      } else if (val == 'UpdateAll') {
        this.showContinueButton = true;
        this.EnableDisableCheckbox(true, false, false);
      }
      else if (val == 'DoNotUpdate') {
        this.showContinueButton = true;
        this.EnableDisableCheckbox(false, false, true);
      }


    }
  }
  EnableDisableCheckbox(UpdateAll: boolean, SelectContacts: boolean, DoNotUpdate: boolean) {
    this.enableUpdateAll = UpdateAll;
    this.enableSelectContacts = SelectContacts;
    this.enableDoNotUpdate = DoNotUpdate;
  }

  ContinueEditing() {
    this.SaveAccount(this.selectedAddressOption)
    this.ShowContactstoSelect = false;
  }
  onSelectionChanged(event) {
    this.showContinueButton = true;
    var data = event.api.getSelectedNodes();
    data.forEach(element => {
      this.selectedcontact.push(element.data.id);
      
    });
  }
  ReceiveEvent(msg) {
    if (msg == "ExpandSoftwareLicense" || msg == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(msg);
    } else {
      this.AccountData('show' + msg.split('~')[1]);
      this.showAddEditAccount = false;
      this.showAddEditContact = false;
      //this.AccountData(this.account.id);
    } 
  }
  showAddEditAccountCss() {
    setTimeout(function () {
      $('#addEditAccount .dialog').attr('style', 'width: 45%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 91%;padding: 0;top: 8%;left: 53%;');
    }, 4)
  }
  showAddEditContactCss() {
    setTimeout(function () {
      $('#addEditContact .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 91%;padding: 0;top: 8%;left: 56%;');
    }, 4)
  }
  ContactReceiveEvent(msg) {
    this.showAddEditContact = false;
    this.GetAllProviderAndContactInfo(this.account.id)
  }

  btnClickReceived(event) {
    debugger;
    if (event == 'close') { this.showAddEditAccount = false; }
    else if (event == "ExpandSoftwareLicense" || event == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(event);
    } else {
      if (event.btnName == 'addContact') {
        this.addContact();
      }
      else if (event.btnName == 'Accountsharing') {
        this.AccountsharingAg(event.val);
      }
      else if (event.btnName == 'StatusId') {
        this.sendMsgEvent.emit("StatusId");
      }
      else if (event.btnName == 'openNotes') {
        this.openAccountNotes();
      } else if (event.btnName == 'FutureTask') {
        this.openFutureTask();
      }
    }
    
  }
  ExpandPDF(val) {    
    this.notesshowDialog = true;
    this.QuickViewDialogCss(val);
    this.DocExpandView = val;
  }

  closeDoc() {
    this.quickViewHistoryDialog = false;
    this.AccountotherDetailsDiv = true;
    this.rightMainDivHideShow = true;
    this.uploadDocumentShow = false;
    this.GetCustomerNotes(this.account.id.toString(), 'Customer');
    this.ShowPDF = false;
    this.EditDocument = true;
  }

  SaveContactOrder() {
     
    if (this.ContactsOrder.ContactOrderid) {

    } else {
      this.ContactsOrder.CategoryType = "ContactOrderBy";
      this.ContactsOrder.UserId = this.currentuser.id;
    }
    
    this.customerService.SaveContactOrder(this.ContactsOrder).then(perresult => {
      this.ContactsOrder = perresult;
    });
  }

  ContactsSortOrder(event) {
    this.allContactsproviders = [];
    this.SaveContactOrder();
    let Selfobj = this;
    setTimeout(function () {
      switch (Selfobj.ContactsOrder.OrderByValue) {
        case "LA-Z": {
          if (Selfobj.SelectedContactType != 'All') {
            Selfobj.allContactsproviders = Selfobj.allContacts.filter(x => x.contacttype == Selfobj.SelectedContactType)
          } else {
            Selfobj.allContactsproviders = Selfobj.allContacts;
          }
          Selfobj.allContactsproviders = Selfobj.allContactsproviders.filter(x => x.Status == Selfobj.SelectedStatus)
          Selfobj.allContactsproviders = Selfobj.allContactsproviders.sort((a, b) => a.lastname.toLowerCase() > b.lastname.toLowerCase() ? 1 : -1);
          break;
        }
        case "FA-Z": {
          if (Selfobj.SelectedContactType != 'All') {
            Selfobj.allContactsproviders = Selfobj.allContacts.filter(x => x.contacttype == Selfobj.SelectedContactType)
          } else {
            Selfobj.allContactsproviders = Selfobj.allContacts;
          }
          Selfobj.allContactsproviders = Selfobj.allContactsproviders.filter(x => x.Status == Selfobj.SelectedStatus)
          Selfobj.allContactsproviders = Selfobj.allContactsproviders.sort((a, b) => a.firstname.toLowerCase() > b.firstname.toLowerCase() ? 1 : -1);
          break;
        }
        case "OrderByCreated": {
          if (Selfobj.SelectedContactType != 'All') {
            Selfobj.allContactsproviders = Selfobj.allContacts.filter(x => x.contacttype == Selfobj.SelectedContactType)
          } else {
            Selfobj.allContactsproviders = Selfobj.allContacts;
          }
          Selfobj.allContactsproviders = Selfobj.allContactsproviders.filter(x => x.Status == Selfobj.SelectedStatus)
          Selfobj.allContactsproviders = Selfobj.allContactsproviders.sort((a, b) => a.created > b.created ? 1 : -1);
          break;
        }
        case "OrderByTitle": {
          if (Selfobj.SelectedContactType != 'All') {
            Selfobj.allContactsproviders = Selfobj.allContacts.filter(x => x.contacttype == Selfobj.SelectedContactType)
          } else {
            Selfobj.allContactsproviders = Selfobj.allContacts;
          }
          Selfobj.allContactsproviders = Selfobj.allContactsproviders.filter(x => x.Status == Selfobj.SelectedStatus)
          Selfobj.allContactsproviders = Selfobj.allContactsproviders.sort(Selfobj.CustomeSort(true,'title'));

        }
        default: {
       
        }
      }
      
    }, 200)

   
  }

  CustomeSort(ascending,field) {
  return function (a, b) {
    if (a[field] === b[field]) {
      return 0;
    }
    else if (a[field] === null || a[field] === '(NULL)' || a[field] === '') {
      return 1;
    }
    else if (b[field] === null || b[field] === '(NULL)' || b[field] === '') {
      return -1;
    }
    else if (ascending) {
      return a[field] < b[field] ? -1 : 1;
    }
    else {
      return a[field] < b[field] ? 1 : -1;
    }

  };

  }
  ExpandAddEditAccountDialog(msg) {
      if (msg == 'ExpandSoftwareLicense') {
        setTimeout(function () {
          $('#addEditAccount .dialog').attr('style', 'width: 100%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 86%;padding: 0;top: 10%;left: 10%;max-width: 88%;');
        }, 4)
    } else {
        setTimeout(function () {
          $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
        }, 4)
    }
  }

  CloseProposalPage(msg) {
    this.ReceiveEvent("CloseAddAccountDialog~" + this.account.id);
  }
  CloseTab(tabval) {
    this.sendMsgEvent.emit(tabval);
  }
  CampaignsDropdownChange(status) {
    this.campaincmp.LoadCampaigns(this.currentuser.id, status)
  }
  crmDialogCss() {
    $(setTimeout(function () {
      $('#crmDialog .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 42%;left: 24%;max-width: 1393px !important;');
    }, 4));
  }
  onaccChange(msg) {
    this.contactphotocmp.checkNotes();
  }
  OnReceived(msg) {
    if (msg == "Open") {
      this.contactphotocmp.crmDialog = true;
      this.crmDialogCss();
    }
    else {
      this.ViewPhotoDialog = false;
    }
  }

}
