import { Component, OnInit, ViewContainerRef, Optional, ViewChild, Input, VERSION } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from "../services/customer.service";
import { ToastrService } from 'ngx-toastr';
import { UserService } from './../services/user.service';
import { MessagingService } from './../services/messaging.service';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { Contacts } from '../models/contacts';
import { Accounts } from '../models/accounts';
import { TempData } from '../models/temp-data';
import { Notes } from '../models/notes';
import { Dropdowntype, Dropdown } from '../models/dropdowntype';
import { OpprtunitiesService } from '../services/opprtunities.service';
declare var require: any;
import { Column } from 'ag-grid';
const { version: version } = require('../../../package.json');
const { date: date } = require('../../../package.json');
import { NotesrendererComponent } from './notesrenderer/notesrenderer.component';
import { EditdeletecvComponent } from './editdeletecv/editdeletecv.component';
import { DatePipe } from '@angular/common';
import { IMyDpOptions } from 'mydatepicker';
import { Dashboardconfiguration } from '../models/dashboardconfiguration';
import { AccountsService } from './../services/accounts.service';
declare var require: any;
import { DialogComponent } from './dialog/dialog.component';
import { RhxNames } from '../models/RhxNames';
import { UserMessages } from '../models/UserMessages';
declare var $: any;
import { NotificationService } from "./notification/notification.service";
import { NotificationComponent } from "./notification/notification.component";
//import { ChatAdapter } from 'ng-chat';
import { DemoAdapter } from './../../adapters/demo-adapter';
import { AboutsalesXCRMComponent } from './aboutsales-xcrm/aboutsales-xcrm.component';

import { AuditLogModel, Auditlog } from '../models/auditlog';
import { HostListener } from '@angular/core';
import { AccountTransferModel } from '../models/AccountTransferModel';
import { SearchResultComponent } from './search-result/search-result.component';
import { TicketsComponent } from './tickets/tickets.component';
import { Mynotes } from '../models/mynotes';
import { AccountComponent } from './account-contact/account/account.component';
import { ChatComponent } from './chat/chat.component';
import { GroupChat } from '../models/GroupChat';
import { distinct } from 'rxjs/operators';
import { AppSettings } from '../app.settings';
import { HubConnection } from '@aspnet/signalr';
import { Message } from '../models/message';
import { crmModel } from '../models/CrmModel';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {
  version = '2.0';
  public currentdatetime = new Date();
  hideLabel: boolean = false;
  buttonList: NodeListOf<HTMLButtonElement>;
  public dobmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  @Input()
  pageViewed: string = "";
  selectedsubbutton: string = "";
  selectedmainbutton: string = "";
  selectedbuttonText: string = "Dashboard";
  displayIcons: boolean = false;
  userprevilages: any;
  currentuser: any;
  showAddEditAccount: boolean = false;
  showduplicatechatsessionDiaog: boolean = false;
  resetpwd: boolean = false;
  isSelectedDOB: boolean = false;
  isDateSelected: boolean = false;
  showSearchFields: boolean = false;
  recentHistoryOn: boolean = false;
  RHxMenuDiv: boolean = false;
  RHxContactSelected: boolean = false;
  RHxAccountSelected: boolean = false;
  RHxRecentViewPages: boolean = false;
  public searchoffon: boolean = false;
  AboutUsPage: boolean = false;
  AboutUsPageDiv: boolean = false;
  MyNotes: boolean = false;
  ReleaseNotesPage: boolean = false;
  ContactUsPage: boolean = false;
  showSearchFieldsDialog: boolean = false;
  QuickSearchEvenraised: boolean = false;
  AdvancedSearchEventraised: boolean = false;
  NotSelected: boolean = false;
  TicketCreateDialog: boolean = false;
  showmenuDialog: boolean = false;
  isPhoneMask: boolean = false;
  hideShowCommonTab: boolean = true;
  googlemapshow: boolean = false;
  isSearchresult: boolean = false;
  Searchdiv: boolean = false;
  ConfirmationSearchClear: boolean = false;
  clearSearchConfirm: boolean = false;
  EnableSearch: boolean = false;
  selectedtabid = "dashboards";
  Qtype: string = "";
  public elementRef;
  Searchtabname: string;
  tabnameforAccountSearch: string = "";
  selectcolums = [];
  allusers: any;
  appVersion: string;
  today: Date;
  day: string = "";
  currentdate: string;
  dropdownSetting = {};
  public limitedSearch: boolean = false;
  fieldlist: string[] = [];
  advancedSearchRBLChecked: string = ""
  public globalGridOptions: GridOptions;
  public globalColumnDefs: any[];
  allContacts: Contacts[];
  Menuslist: Dropdowntype[] = [];
  aggridheight: string;
  selectedcolumncount: number = 0;
  public context;
  termCount: boolean = false;
  public term: string = "";
  SubMenuId: string;
  contactids: string;
  public searchstring: string = null;
  searchCriteria: string = "";
  searchCriteriadata: any;
  tableData: Contacts[];
  tempdata: TempData[] = [];
  changeCount: number = 0;
  hideGlobalSearchtable: boolean = true;
  RWActive: boolean = false;
  responsewizardType: string = "TaskList";
  pageSize: string = "1000";
  ShowInvalidSearchDialog: boolean = false;
  SearchSelectedItemsLengthChanged: boolean = false;
  showAdvancedSearchDialog: boolean = false;
  CallListDialog: boolean = false;
  QuickViewDialog: boolean = false;
  ShowSearchClosewarningDialog: boolean = false;
  CommonTabs: boolean = true;
  globalMultiSelect: boolean = true;
  LeaveTransferAccounts: boolean = false;
  maincontent: boolean = true;
  isAccessDenied: boolean = false;
  calandarType: string = 'All Tasks';
  public frameworkComponents;
  datePipe: DatePipe = new DatePipe("en-US");
  contact: Contacts = new Contacts();
  account: Accounts = new Accounts();
  mynotes: Mynotes = new Mynotes();
  grpchat: GroupChat = new GroupChat();
  notes: Notes = new Notes();
  notesshowDialog = false;
  customernotesshowDialog = false;
  allnotes: Notes[];
  responseWizardType: string = 'TaskList';
  public columnDefsnotes: any[];
  public gridOptionsnotes: GridOptions;
  public getRowHeight; notedescription: string;
  public selectedusername: any = []; public selecteduserids: any = [];
  colSelections: string;
  dropdownList = [];
  globalSelectedItems = [];
  SearchSelectedItems = [];
  showSearchTabsSubTabs: boolean = false;
  UnReadOpenTicketCount = 0;
  OpenTicketCountLoginUser = 0;
  OpenTicketCount = 0;
  PendedTicketCount = 0;
  OnHoldTicketCount = 0;
  ClosedTicketCount = 0;
  DeleteTicketCount = 0;
  ChatMessageCount = 0;
  AllTicketCount = 0;
  QuickSearchDiv: boolean = false;
  //showResponseWizardSubTabs: boolean = false;
  isClosedOpportunityEventRaised: boolean = false;
  closedOpportunityVal: any = 'Open';
  closedOpportunityUserids: any;
  isAddOpportunityEventRaised: boolean = false;
  selectedItems = [];
  isClosedLeadEventRaised: boolean = false;
  closedLeadVal: any = null;
  closedLeadUserids: any;
  arrayBuffer: any;
  Ids = [];
  CampaignId: any;
  AccConIds: string;
  isAddcampaignEventRaised: boolean = false;
  isCustomer: boolean = false;
  isFormerCustomer: boolean = false;
  Email;
  isTaskDataLeadEventRaised: boolean = false;
  TaskDataVal: any = 'Open Tasks';
  TaskDataUserids: any;
  isAddTaskEventRaised: boolean = false;
  ProposalType: string = "";
  isTicketDataEventRaised: boolean = false;
  TicketDataVal: any = null;
  TicketDataUserids: any;
  isAddTicketEventRaised: boolean = false;
  public sideBar;
  isDashBoardEventRaised: boolean = false;
  DashBoardVal: any = null;
  Banu = false;
  isMapItEventRaised: boolean = false;
  timezonevalue: string;
  inboxmessagecount: number = 0;
  totalInboxmessages: number = 0;
  isMultipleContactSelected: boolean = false;
  SearchNotAvailable: boolean = false;
  public selectedusers: string;
  public startVal: any;
  public endVal: any;
  userInfo: string;
  isShowDetailsChecked: boolean = false;
  ChatDialog: boolean = false;
  public SelectedTab: string;
  private gridApi;
  UserIds: string;
  SearchResultTable: string = "";
  calllistid: number = 0;
  chatmsgId: number = 0; fromchatusrId: number = 0;
  public showmenu = true;
  public editorOptions: Object = { key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false, dayLabels: { su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S' } };
  public startDateVal: any = (new Date().getFullYear() - 2) + '-01-01'; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public searchStartDatePickerOptions: IMyDpOptions = { dateFormat: 'mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false, dayLabels: { su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S' } };
  public searchEndDatePickerOptions: IMyDpOptions = { dateFormat: 'mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false, dayLabels: { su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S' } };

  public startDatePlaceHolder: string = 'Start: 01-01-' + (new Date().getFullYear() - 1).toString();

  public SearchStartDatePlaceHolder: string = '01-01-' + (new Date().getFullYear() - 1).toString();
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false, dayLabels: { su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S' } };
  public endDateVal: any = new Date().getFullYear() + '-12-31';  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = 'End: 12-31-' + new Date().getFullYear().toString();
  public SearchEndDatePlaceHolder: string = '12-31-' + new Date().getFullYear().toString();
  finalitems = ""; public groupName = ''; public groupId = 0;
  finalitems1 = "";
  conid: number;
  typ: string;
  NotesDec = false;
  taskselectedtype = "";
  Calllistselectedtype = "";
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  showsaleTool: boolean = false;
  OpenLogout: boolean = false;
  viewpermission: boolean = true;
  totalglobalsearchcount: number = 0;
  file: File;
  RHxNames: RhxNames[];
  SearchColumnList: any;
  public SearchstartVal: string = (new Date().getFullYear() - 1).toString() + '-01-01';
  public SearchendVal: string = new Date().getFullYear().toString() + '-12-31';
  isClosedConfigDashEventListener: boolean = false;
  isClosedUserQuotaEventListener: boolean = false;
  isClosedAlertsEventListener: boolean = false;
  isUserConfig: boolean = false;
  isUserPrivilege: boolean = false;
  isUserDashboard: boolean = false;
  isUserQuota: boolean = false;
  emailFontSize;
  isUserAlerts: boolean = false;
  PrevUserConfigTab: string = "";
  Filter: string = "All Tasks Groups and Users";
  sortOrder: string = "None";
  aboutUsType = "AboutUs";
  ShowMessageDialog = false;
  CurrentUserMessages: UserMessages[];
  @ViewChild(DialogComponent) dlgComp;
  @ViewChild(AboutsalesXCRMComponent) aboutusComponent;
  @ViewChild(NotificationComponent) NotificationComponent;
  @ViewChild(SearchResultComponent) SearchresultComponent;
  @ViewChild(TicketsComponent) tickCom;
  @ViewChild(AccountComponent) accComp;
  @ViewChild(ChatComponent) chatComp;
  userId = 999;
  //public adapter: ChatAdapter = new DemoAdapter();
  currentTheme = 'dark-theme';
  CloseSearchDialog: boolean = false;
  AboutUsPageType: string = 'AboutSalesXCRM';
  ShowLivechatBox: boolean = false;
  CreateProposalPage: boolean = false;
  ProposalContactOrAccountType = "";
  ProposalOverLayWarning: boolean = false;
  AdvancedSearchResult: boolean = false;
  ShowConfigurationDialog: boolean = false;
  ProposalID: number;
  ProfilePicture: string = "";
  NotificationMessageDialog: boolean = false;
  NotificationMessages = [];
  PopUpMessageType: string = null;
  defaultGridColDef = { resizable: true, suppressSorting: true, enableColResize: true, enableSorting: false, enableFilter: true, sortable: true };
  AllTransferedAccounts: Accounts[];
  public gridOptions: GridOptions;
  public AccTransColumnDefs: any[];
  TransferedAccounts: AccountTransferModel[];
  //ClearTransferAccounts: boolean = false;
  ChatUserDialog: boolean = false; ChatEditUserDialog: boolean = false;
  ShowTransferAccounts: boolean = false;
  SubscriptionName: string = "";
  selectedval: string = 'Name';
  SearcValueList: Dropdowntype[] = [];
  SearchTypeList: Dropdowntype[] = [];
  SearcValueDrpSetting = {};
  hideSubButton: boolean = false;
  MessagingDialog: boolean = false;
  SearchType: string = "accountName";
  SearcValueModel = [];
  SearchTypeModel = [];
  customertype: any = '0,1,2';
  allCustomers: Accounts[];
  calanderonoff: boolean = true;
  IncludeShared: string = "Shared";
  QuickSearchConfig = [];
  calllisturl = "";
  ticketcreateurl = "";
  Iscalllistdialogshow: boolean = false;
  Iscalllisterrorshow: boolean = false;
  IsCreateTicketshow: boolean = false;
  taskDialog: boolean = false;
  BTClick: boolean = false;
  id: number;
  SearchDialog: boolean = false;
  SelectedDate: any;
  type: any;
  isprojectOpen: boolean = false;
  OpenProjects: number; PendedProjects: number; CancelledProjects: number; CompleteProjects: number; AllProjects: number;
  Alltaskcount: number; CompletedTaskcount: number; NotCompletedTaskCount: number; OpenTaskCount: number;
  AllOpporCount = 0; OpenOpporCount = 0; LostOpporCount = 0; PendedOpporCount = 0; WonOpporCount = 0;
  AllPropCount = 0; OpenPropCount = 0; ClosedPropCount = 0; PendedPropCount = 0;
  AllLeadCount = 0; OpenLeadCount = 0; ClosedLeadCount = 0;
  selectedNoteRadio: string = "FollowUp";
  loggedinUserlist: Array<Auditlog> = []; msgloggedinUserlist: Array<Auditlog> = [];
  isLiveChat: boolean = false; DeleteChatDialog: boolean = false;
  msgMsg = "";
  private hubConnection: HubConnection;
  private _pipelinerChatHubProxy: SignalR.Hub.Proxy; private _pipelinerChatHubConnection: SignalR.Hub.Connection;
  public crmDialog: boolean = false;
  public dialogobj: crmModel = new crmModel(); 
  constructor(private advancedSearchService: AccountsService, private http: HttpClient, private userservice: UserService, private route: ActivatedRoute,
    private opprtunityservice: OpprtunitiesService, private settings: AppSettings,
    /*private chatService: SignalrClientService,*/
    private messagingservice: MessagingService, private router: Router, private users: UserService, private customerService: CustomerService,
    private toastr: ToastrService, private viewContainerRef: ViewContainerRef, protected _notificationSvc: NotificationService) {
    this.viewContainerRef = viewContainerRef;
    this.appVersion = version;
    this.today = date;
    window.onbeforeunload = () => {
      localStorage.removeItem('SelectedUserIds');
    }
   // this.createConnection();
  }
   private createConnection() {
    let self = this;
    self._pipelinerChatHubConnection = jQuery.hubConnection(self.settings.baseUrl);
    self._pipelinerChatHubConnection.qs = { 'bearer': localStorage.getItem('key') };

    self.initPipelinerChatHubProxy();
    self._pipelinerChatHubConnection.start()
      .then(function () {   
        let messageItem = new Message();
        messageItem.clientuniqueid = "test18Apr2023";
        messageItem.type = "Info";
        messageItem.message = "Hello Hi";
        //self.chatService.sendMessage(messageItem);

      })
      .fail(function (result) {
 
      }); 
  }
  private initPipelinerChatHubProxy() {
    let self = this;
    self._pipelinerChatHubProxy = self._pipelinerChatHubConnection.createHubProxy('pipelinerchathub');
    self._pipelinerChatHubProxy.on('addNewMessageToPage', (name, message) => {
      self.loadchatCount();
      //self.chatComp.GetChatMessages(self.currentuser.id, "0", "0", "init");
    });

  }
  ngOnInit() {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const d = new Date();
    this.day = weekday[d.getDay()];
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));   
    this.ProfilePicture = this.currentuser.ProfilePicture;
    var loggedInUserId = this.currentuser.id;
    this.customerService.GetPrivilagesByuser(loggedInUserId).then(result => {
      this.userprevilages = result;
      var thisTab = this.userprevilages.filter(p => p.resource == 'Live Chat')[0];
      if (thisTab != undefined && thisTab.canview == true) {
        this.isLiveChat = true;

      }
    });
    $("#test").addClass("disabledbutton");
    this.GetAllaccesusersListbyuser();
    this.getTransferedAccounts();
    this.GetAllZendeskUers(this.Filter, this.sortOrder);
 
    this.messagingservice.Getallmessages(this.currentuser.id, 0, this.currentuser.id, this.startDateVal, this.endDateVal, 1, 100).then((inboxmessages) => {
      this.totalInboxmessages = inboxmessages.length;
    });
    this.loadchatCount();
    this.initializeAgGrid();

    this.dropdownSetting = {
      singleSelection: false,
      enableSearchFilter: true,
      badgeShowLimit: 3,
      text: "Select Fields to Search"
    };

    //let selfObj = this;
    //$(setTimeout(function () {
    //  selfObj.GetUserMessagesByUserId();
    //}, 400));
    //}, 400));
 
    this.loadSearchdropdowns();
  }

  loaddialog(name, title, description, issaveval, isdiscardval, iscancelval, isokval, contactId) {
    this.dialogobj.name = name;
    this.dialogobj.title = title;
    this.dialogobj.description = description;
    this.dialogobj.issave = issaveval;
    this.dialogobj.isdiscard = isdiscardval;
    this.dialogobj.iscancel = iscancelval;
    this.dialogobj.isok = isokval;
    this.dialogobj.idvalues = contactId;
    this.dialogobj.Idval = contactId;
    this.crmDialog = true;
    this.crmDialogCss();
  }

  loadchatCount() {
    this.messagingservice.GetallChats(this.currentuser.id).then((chatsCount) => {
      this.ChatMessageCount = chatsCount;
    }); }
  AddSuspendInactiveDiv() {
    var mydiv = document.getElementsByClassName("pure-checkbox select-all ng-star-inserted");
    var div = document.createElement('div');
    div.id = "SuspendInactiveDiv";
    div.className = "col-xs-12 Banoos";
    mydiv[1].parentNode.insertBefore(div, mydiv[1].previousSibling);
    var banooDiv = document.getElementById("SuspendInactiveDiv");

    var div = document.createElement('div');
    div.id = "emptyDiv";
    div.className = "col-xs-6";
    $('#SuspendInactiveDiv').append(div);

    var div = document.createElement('div');
    div.id = "inActiveDiv";
    div.className = "col-xs-3";
    $('#SuspendInactiveDiv').append(div);
    var inActiveDiv = document.getElementById("inActiveDiv");

    var div = document.createElement('div');
    div.id = "suspendDiv";
    div.className = "col-xs-3";
    $('#SuspendInactiveDiv').append(div);
    var suspendDiv = document.getElementById("suspendDiv");


    var checkbox = document.createElement('input'); // creating checkbox element 
    checkbox.type = "checkbox"; // Assigning the attributes 
    checkbox.name = "Inactive";
    checkbox.value = "Inactive";
    checkbox.id = "Inactive";

    var label = document.createElement('label'); // creating label for checkbox 
    label.htmlFor = "Inactive"; // assigning attributes for
    label.id = "InactiveLabel";
    label.appendChild(document.createTextNode(' Inactive   '));  // appending the created text to  
    //inActiveDiv.append(checkbox); // appending the checkbox and label to div 
    //inActiveDiv.append(label);

    $('#inActiveDiv').append(checkbox);
    $('#inActiveDiv').append(label);

    var checkbox2 = document.createElement('input'); // creating checkbox element 
    checkbox2.type = "checkbox"; // Assigning the attributes 
    checkbox2.name = "suspended";
    checkbox2.value = "suspended";
    checkbox2.id = "suspended";
    var label2 = document.createElement('label'); // creating label for checkbox 
    label2.htmlFor = "suspended"; // assigning attributes for
    label2.id = 'suspendedLabel';
    label2.appendChild(document.createTextNode(' Suspended'));  // appending the created text to  
    //suspendDiv.append(checkbox2); // appending the checkbox and label to div 
    //suspendDiv.append(label2);

    $('#suspendDiv').append(checkbox2);
    $('#suspendDiv').append(label2);

    $('#InactiveLabel').attr('style', 'color: #9a9797;margin-left:4%');
    $('#suspendedLabel').attr('style', 'color: #9a9797;margin-left:4%');

    let self = this;
    document.getElementById('Inactive').onclick = function inactiveclick() {
      self.getinactiveUsers();
    }

    document.getElementById('suspended').onclick = function inactiveclick() {
      self.getSuspendedUsers();
    }
  }
  getinactiveUsers() {
    this.dropdownList = [];
    this.GetAllaccesusersListbyuser();
  }
  getSuspendedUsers() {
    this.dropdownList = [];
    this.GetAllaccesusersListbyuser();
  }
  GetAllaccesusersListbyuser() {
    var inactive = $('#Inactive').prop("checked") ? 1 : 0;
    var suspended = $('#suspended').prop("checked") ? 1 : 0;
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1, inactive, suspended).then((Owner) => {
      this.dropdownList = Owner;
      this.finalitems += this.currentuser.id + ',';
      this.globalSelectedItems = [{ "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }];
    });
  }


  txtPageSizeChanged(pageSize) {
    this.globalGridOptions.api.paginationSetPageSize(Number(pageSize));
    this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.globalGridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 26;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  ngAfterViewInit(): void {
    this.ResizeTable();
    $('#multipleDropdown .cuppa-dropdown .dropdown-list .lst-area .ng-star-inserted .ng-star-inserted  .label').after('<input type="checkbox" id="FormerCustomers" (change)="loadselectedcontacts()" title="Set as Default">').click();
  }
  UpdateSearchColumns(columnSelections) {
     
    this.SearchColumnList = [];
    let allCols: string[] = columnSelections.split(',');

    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');
      if (fields[2] == 'true' && (fields[1] != '#' && fields[0] != 'notes' && fields[0] != 'actions' && fields[0] != 'callliststatus')) {
        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }

    this.SearchColumnList = columnSelectionsArray;

    this.SearchSelectedItems = [
      { "id": 'lastname', "itemName": 'LastName' },
      { "id": 'firstname', "itemName": 'FirstName' },
      { "id": 'accountname', "itemName": 'Account Name' }
    ];


  }
  initializeAgGrid() {
    this.globalGridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'multiple'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 26).then((config) => {
      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.pageSize = config.maxlimit.toString();
      }

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = columnSelections;
      this.createColumnDefs(columnSelections, this.searchstring);
      this.UpdateSearchColumns(columnSelections);
    });
    this.globalGridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent,
      editdeletecv: EditdeletecvComponent
    };

  }
  createColumnDefs(columnSelections, searchstring) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.globalColumnDefs = [];



      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleArray[0] == "#") {
          this.globalColumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleArray[0] == "notes") {
          this.globalColumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleArray[3]) });
        }
        else if (fieldHeaderVisibleArray[0] == "lastname") {
          this.globalColumnDefs.push({
            field: 'lastname', headerName: 'Last Name', hide: false, cellRenderer: function (params) {
              if (searchstring) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = searchstring.replace(/["]/g, "")
                  if (x[i] == search) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleArray[0] == "customertype") {
          this.globalColumnDefs.push({
            field: 'customertype', headerName: 'Customer Type', hide: (!this.isCustomer && !this.isFormerCustomer),
            cellRenderer(params) {
              if (params.value == '0') { return "Non - Customer" } else if (params.value == '56') { return "Customer" }
              else if (params.value == '57') { return "Former Customer" }
              if (searchstring) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = searchstring.replace(/["]/g, "")
                  if (x[i] == search) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }, width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
        else if (fieldHeaderVisibleArray[0] == "accnumber") {
          this.globalColumnDefs.push({
            field: 'accnumber', headerName: 'Acct #', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer), cellRenderer: function (params) {
              if (searchstring) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = searchstring.replace(/["]/g, "")
                  if (x[i] == search) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }, width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
        else if (fieldHeaderVisibleArray[0] == "owner") {
          this.globalColumnDefs.push({
            field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, cellRenderer: function (params) {
              if (searchstring) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = searchstring.replace(/["]/g, "")
                  if (x[i] == search) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }, width: parseInt(fieldHeaderVisibleArray[3])
          });
        } else {
          this.globalColumnDefs.push({
            field: fieldHeaderVisibleArray[0],
            headerName: fieldHeaderVisibleArray[1], cellRenderer: function (params) {
              if (searchstring) {
                if (params.value != null) {
                  let x = params.value.split(" ");
                  var st = "";
                  for (let i = 0; i < x.length; i++) {
                    var search = searchstring.replace(/["]/g, "")
                    if (x[i] == search) {
                      st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                    }
                    else {
                      st += x[i] + " ";
                    }

                  }
                  return st
                }
              } else {
                return params.value;
              }
            },
            hide: fieldHeaderVisibleArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
      }
    } else {
      this.globalColumnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          enableTooltip: true
        },
        { field: 'type', headerName: 'Type', hide: false },
        {
          field: 'customertype', headerName: 'Customer Type', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer),
          cellRenderer(params) {
            if (params.value == '0') { return "Non - Customer" } else if (params.value == '56') { return "Customer" } else if (params.value == '57') { return "Former Customer" }
          }
        },
        {
          field: 'accnumber', headerName: 'Act Number', cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }, hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer)
        },
        {
          field: 'lastname', headerName: 'Last Name', cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }, hide: false
        },
        {
          field: 'firstname', headerName: 'First Name', hide: false, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'middlename', headerName: 'Middle Name', hide: false, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'title', headerName: 'Title', hide: false, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'speciality', headerName: 'Specialty', hide: false, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'DOB', headerName: 'DOB', hide: false, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        {
          field: 'zip', headerName: 'ZIP', hide: false, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'accountname', headerName: 'Account Name', hide: false, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'contacttype', headerName: 'Contact Type', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },  //true starts here
        {
          field: 'suffix', headerName: 'Suffix', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'sex', headerName: 'Sex', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'subspeciality', headerName: 'Subspeciality', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'email', headerName: 'Email', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'address1', headerName: 'Address1', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'address2', headerName: 'Address2', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'city', headerName: 'City', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'state', headerName: 'State', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'cell', headerName: 'Cell', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'office', headerName: 'Office', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'homeaddress', headerName: 'Home Address', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'homecity', headerName: 'Home City', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'homestate', headerName: 'Home State', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'homezip', headerName: 'Home Zip', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'home', headerName: 'Home', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'college', headerName: 'College', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'collegegrandyear', headerName: 'C.G.year', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'medschool', headerName: 'Medschool', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'residency', headerName: 'Residency', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'residencygrandyear', headerName: 'R.G.year', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'fellowship', headerName: 'Fellowship', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'created', headerName: 'Created', hide: true, cellRenderer: function (params) {
            if (searchstring) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = searchstring.replace(/["]/g, "")
                if (x[i] == search) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        }

      ];
    }

  }
  onAgGridReady() {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
      $(setTimeout(function () {
        selfObj.autoSizeAll();
      }, 400));
    }, 400));
  }
  autoSizeAll() {

  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  getRecords(id: number) {

  }
  openNotesDialogAg(rowdata) {
    this.notes.type = rowdata.type;
    this.notes.accountid = rowdata.id;
    this.conid = rowdata.id;
    $('#accotIdNotes').val(rowdata.id);
    this.typ = rowdata.type == 'Contact' ? 'Contacts' : rowdata.type;
    if (rowdata.type == 'Contact') {
      $('#accotTypeNotes').val('Contact');
      this.customerService.GetContactById(rowdata.id).then(result => {
        this.contact = result;
        this.customerService.GetByAccountId(this.contact.accountid).then(result => {
          this.account = result;
        });
      });
      this.GetContactNotes(rowdata.id, 'Contact');
      this.notesshowDialog = !this.notesshowDialog;
    } else {
      $('#accotTypeNotes').val('Customer');
      this.customerService.GetByAccountId(rowdata.id).then(result => {
        this.account = result;
      });
      this.GetContactNotes(rowdata.id, 'Customer');
      this.customernotesshowDialog = !this.customernotesshowDialog;
    }
    $(setTimeout(function () {
      if ($('#oppNotesId').length > 0)
        $('#oppNotesId').removeClass('active');

      if ($('#leadsNotesId').length > 0)
        $('#leadsNotesId').removeClass('active');

      if ($('#allContactNotesId').length > 0)
        $('#allContactNotesId').removeClass('active');

      if ($('#tskHistNotesId').length > 0)
        $('#tskHistNotesId').removeClass('active');

      if ($('#allNotesId').length > 0) {
        $('#allNotesId').removeClass('active');
        $('#allNotesId').addClass('active');
      }
      $('#chkShowDetailsNotes').prop('checked', false); $('#closeDetailsDialog').click();
    }, 100));

    let windowHeight = $(window).height() - 160;
    $(setTimeout(function () {
      $('.dialog').height(windowHeight);
    }, 100));

    $('.dialog').width('1000px');
  }
  Notes(val) {
    this.GetContactNotes(this.contact.id, val);
  }
  enterNotes() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'block';
    document.getElementById('saveNotesButtons').style.display = 'block';
    document.getElementById('divTable').style.display = 'none';

  }
  SaveNote() {
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/img, "");
    this.notes.notedescription = this.notes.notedescription.replace(/<[^>]*>/g, '');
    this.customerService.SaveNotes(this.notes).then(result => {
      this.GetContactNotes(this.notes.accountid, this.notes.type);
      $('#notesListTabsId .active').removeClass('active');
      $('#notesListTabsId #allNotesId').addClass('active');
      this.CancelNote();
    });
  }
  CancelNote() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'none';
    document.getElementById('saveNotesButtons').style.display = 'none';
    document.getElementById('divTable').style.display = 'block';
  }
  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, paginationPageSize: 1000,enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createColumnDefsnotes();
    this.context = this;
  }
  createColumnDefsnotes() {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellStyle: { 'text-align': 'left' }, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];
    this.getRowHeight = function (params) {
      return 18 * (Math.floor(params.data.notedescription.length / 45) + 1);
    };
  }

  onAgGridReadynotes(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllnotes();
    }, 1000));
  }
  autoSizeAllnotes() {
    var allColumnIds = [];
    if (this.gridOptionsnotes && this.gridOptionsnotes.columnApi) {
      var allCols = this.gridOptionsnotes.columnApi.getAllColumns();
      if (allCols != null) {
        allCols.forEach(function (column) {
          allColumnIds.push(column.getColId());
        });
      }
      this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds);
    }

  }
  GetContactNotes(contactid, type) {
    this.customerService.GetAllNotesList(this.currentuser.id, contactid, type).then((contactnotes) => {
      contactnotes.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.initializeAgGridNotes(); this.allnotes = contactnotes;
    });
  }
  logout() {
 
    this.tempdata = [];
    localStorage.setItem('LastSearchval', null);
    localStorage.removeItem('projectid');
    localStorage.removeItem('openCustomerholdid');
    localStorage.removeItem('openTicketId');
    localStorage.removeItem('openopportunityId');    
    localStorage.removeItem('OpenMyticketId');
    localStorage.removeItem('openPastDueId');
    localStorage.removeItem('openCustActivityid');
    localStorage.removeItem('TempTable');
    localStorage.removeItem('dashboardTab');
    localStorage.removeItem('selectedQueryCount');
    localStorage.removeItem('advSearchTabName');
    localStorage.removeItem('advSearchQueryId');
    localStorage.removeItem('myDashExpandGrid');
    localStorage.removeItem('supportDashboardGrid');
    localStorage.removeItem('trainerDashboardGrid');
    localStorage.removeItem('projectDashboardGrid');
    localStorage.removeItem('nbdDashboardGrid');
    localStorage.removeItem('salesDashboardGrid');
    localStorage.removeItem('Pendedprojectid');
    localStorage.removeItem('Completedprojectid');
    localStorage.removeItem('Cancelledprojectid');
    localStorage.removeItem('myDashactivity');
    localStorage.removeItem('myDashactivityStatus');
    localStorage.removeItem('CompaignselVal');
    var AuditLogId = Number(localStorage.getItem("AuditLogId"));
    
    this.customerService.updatelogin(this.currentuser.id, 0, "User Logged Out", AuditLogId ).then((user) => { });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('temptoken');
    localStorage.removeItem('SelectedSubMenu');
    localStorage.removeItem('SelectedUserIds');
    localStorage.removeItem('QuickSearchConfig');
    localStorage.removeItem('RhxAccountVal');
    localStorage.removeItem('RhxContactVal');
    //localStorage.removeItem('TaskListSelected');
    this.router.navigateByUrl(`/login`);
  }
  Calllistsave(msg) {
    if (msg == 'Save')
    {
      this.Iscalllistdialogshow = false;
      return false;
    }
    else {
      this.Iscalllistdialogshow = false;
      this.calllisturl = "";
      this.activateMainButtons(this.selectedmainbutton);
    }
  }
  activateSubButtons(id, event = null) {
   
    if (id != 'schedTask' && id != 'Tickets') {
      this.selectedsubbutton = id;
    }
    var url = window.location.href;
    this.isMultipleContactSelected = false;
    this.googlemapshow = false;
    this.hideGlobalSearchtable = true;
    this.SearchSelectedItemsLengthChanged = false;
    this.isDateSelected = false;
    this.totalglobalsearchcount = 0;
    this.searchCriteria = "";
    this.taskselectedtype = $('#tasklistselectedtyle').val();
    if (id !== 'MapIt') {
      this.sideMenuDisable('Enable');
    }
    var email = $('#emailIds').val() == '' ? 0 : $('#emailIds').val();
    var selectedType = $('#tasklistselectedtyle').val();
    var accids = $('#accids').val() == '' ? 0 : $('#accids').val();
    if (id == "SMS" || id == "email") {
      this.loaddialog('SearchNotAvailable', 'Action Icon','Feature Not Available',false,false,false,true,0);
      //this.SearchNotAvailable = true;
      //this.notSelectedCss("SearchNotAvailable");
    }
    else if (id == "schedTask") {    
      if (email != undefined && email != '') {
        var array = email.split(',');
        if (array.length == 1) {
          if (this.selectedtabid === 'contact' || this.selectedtabid === 'customers' || this.selectedtabid === 'Customer') {
            if (this.taskselectedtype == 'Customer') {
              this.router.navigateByUrl(`/application/tasklistdashboard/Customer/` + email + '/null/null');
            } else {
              this.router.navigateByUrl(`/application/tasklistdashboard/Contacts/` + email + '/null/null');
            }

          }        
          else if (url.indexOf('leads') > -1) {

            this.router.navigateByUrl(`/application/tasklistdashboard/Contacts/` + email + '/null/null');
          }
          else {
            if (this.taskselectedtype == 'Contact') {
              
              //this.router.navigateByUrl(`/application/tasklistdashboard/Contacts/` + email + '/null/null');
              this.id = email;
              this.type = 'Contacts';
            
              this.taskDialog = true;
              this.taskDialogCss();
            } else {
              //this.router.navigateByUrl(`/application/tasklistdashboard/Customer/` + email + '/null/null');
              this.id = email;
              this.type = 'Accounts';
            
              this.taskDialog = true;
              this.taskDialogCss();
            }

          }
        } else if (array.length > 1) {
          this.loaddialog('MultiSelect', 'The Action Icon you Selected','Limited to (1) Account or (1) Contact',false,false,false,true,0);
          //this.isMultipleContactSelected = true;
          //this.notSelectedCss("isMultipleContactSelected");
        }
        else {
          this.router.navigateByUrl(`/application/tasklistdashboard//null/0/null/null`);
        }

      }
      else {
        this.loaddialog('NotSelected','Action Icon', 'An Account or Contact must be selected first', false, false, true, true,0);      
      }

    }
    else if (id == "email") {
      if (this.selectedtabid == 'Contacts' || document.getElementById('opportunities').className == 'mybtn coustomButton mainactiveButton') {
        if (email == '') {
          alert("Please select the customers or contacts")
        } else {
          this.router.navigateByUrl(`/application/emails/Contacts/` + email);
          //$('.fr-wrapper').css('height', '282px');
          //$('.fr-wrapper').css('overflow', 'auto');
          $('.fr-wrapper').attr('style', 'height:68%;overflow:auto;')
        }
      }
      else {
        if (this.taskselectedtype == 'Contact') {
          this.router.navigateByUrl(`/application/emails/Contacts/` + email);
        } else {
          alert("Sorry you Can't send Email to Account");
        }
      }

    }
    else if (id == "calllists") {
      this.showAdvancedSearchDialog = false;
      this.AdvancedSearchResult = false;
      if ((!$('#emailIds').val() || $('#emailIds').val() == "" || $('#emailIds').val() == 0) && (!$('#accids').val() || $('#accids').val() == "")) {
        this.loaddialog('NotSelected', 'Action Icon', 'An Account or Contact must be selected first', false, false, true, true,0); 
        return false;
      } else {
        if (selectedType == 'Contact') {
          this.Calllistselectedtype = 'Contact';
        } else if (selectedType == 'Customer') {
          this.Calllistselectedtype = 'Customer';
          this.Ids = [];
        }
        this.contactids = $('#emailIds').val();     
        this.router.navigateByUrl(`/application/calllist/` + this.contactids + '/0')
       
      }

    }
    else if (id == "Opportunity") {
      this.Searchtabname = 'OpenOpportunity';
      this.closedOpportunityVal = 'Open';
     
      var url = window.location.href;
      var ids = ($('#emailIds').val());
      localStorage.setItem('SubTabOpportunity', this.Searchtabname);
      localStorage.removeItem('SelectedSubMenu');
      var array = ids.split(',');

      if (array.length == 1 && ids != undefined && ids != '') {
        if (url.indexOf('contacts') > -1 || url.indexOf('leads') > -1) {
          this.router.navigateByUrl(`/application/opportunitiesdashboard/` + ids + '/Contacts/null/null')
        } else {
          if (this.taskselectedtype == 'Contact') {
            this.router.navigateByUrl(`/application/opportunitiesdashboard/` + ids + '/Contacts/null/null')
          } else {
            this.router.navigateByUrl(`/application/opportunitiesdashboard/` + ids + '/Customer/null/null');
          }

        }
        this.ExpandSelectedMenu('opportunities');
      } else if (array.length > 1) {
        this.isMultipleContactSelected = true;
        this.notSelectedCss("isMultipleContactSelected");
        this.ExpandSelectedMenu('opportunities');
      } else {
        this.loaddialog('NotSelected', 'Action Icon', 'An Account or Contact must be selected first', false, false, true, true,0); 
      }

    }
    else if (id == "viewcalllist") {
      this.Searchtabname = "Calllists";
      this.router.navigateByUrl(`/application/viewcalllist`);
      this.selectedsubbutton = id;

      $(".subButtons").removeClass("activeButton");
      $('#viewcalllist_button').addClass("activeButton");
    }
    else if (id == "Cviewcalllist") {
      this.Searchtabname = "Calllists";
      this.router.navigateByUrl(`/application/viewcalllist`);
      $(".subButtons").removeClass("activeButton");
      $('#Cviewcalllist_button').addClass("activeButton");
    }
    else if (id == "imported") {
      this.SearchNotAvailable = true;
      this.notSelectedCss("SearchNotAvailable");     
    }
    else if (id == 'Add Account') {
      this.router.navigateByUrl(`/application/addnewaccount`);
      //this.showAddEditAccount = true;
      //this.showAddEditAccountCss();
      this.pageViewed = "AddAccount";   

    }
    else if (id == 'My Documents') {
      this.selectedtabid = id;
      this.selectedsubbutton = id;
      this.CommonTabs = true;     
    
      $(".subButtons").removeClass("activeButton");
      $("#MySalesXcrmSubTab").addClass("activeButton");
    }
    else if (id == 'My Subscription') {
      this.selectedtabid = id;
      this.selectedsubbutton = id;
      this.CommonTabs = true;
      this.ShowConfigurationDialog = true;
      this.ShowConfigurationDialogCss(id);
      $(".subButtons").removeClass("activeButton");
      $("#MySalesXcrmSubTab").addClass("activeButton");
    }
    else if (id == "quickview") {
      if (email) {
        if (url.indexOf('contacts') > -1) {
          this.Qtype = "Contacts";
        }
        else {
          this.Qtype = "Accounts";
        }
        this.router.navigateByUrl(`/application/quick-view/` + $('#emailIds').val() + '/'+ this.Qtype)
      
      } else {
        this.loaddialog('NotSelected', 'Action Icon', 'An Account or Contact must be selected first', false, false, true, true,0); 
      }

    }
    else if (id == 'Tickets') {
      this.pageViewed = "CreateTicket";
      if (email == '') {
        this.loaddialog('NotSelected', 'Action Icon', 'An Account or Contact must be selected first', false, false, true, true,0); 
      }
      else {
        var array = email.split(',');
        if (array.length == 1) {
          let self = this;
          self.userservice.GetTicketConfigSettings(self.currentuser.id).then((taskgroup) => {
            if (taskgroup != null && taskgroup[0].SalesXcrm == 1) {              
              if (self.selectedtabid != 'search') {  
                self.type = self.taskselectedtype;                 
              } else {
                self.type = selectedType;
              }
              self.contactids = email;
              self.TicketCreateDialog = true;
              self.notSelectedCss("Tickets");        
            }
            else {
              alert("The ticket feature in the system is not enabled. Please check with your system administrator to enable the ticket system.");
            }
          });

        } else if (array.length > 1) {
          this.isMultipleContactSelected = true;
          this.notSelectedCss("isMultipleContactSelected");
        }
        else {
        }

      }

    }
    else if (id == 'MapIt') {
      if ((!$('#emailIds').val() || $('#emailIds').val() == "" || $('#emailIds').val() == 0) && (!$('#accids').val() || $('#accids').val() == "")) {
        this.loaddialog('NotSelected', 'Action Icon', 'An Account or Contact must be selected first', false, false, true, true,0); 
      }
      else if (($('#emailIds').val()) || $('#emailIds').val() != "" || $('#emailIds').val() != 0) {
        this.isMapItEventRaised = true;      
        this.pageViewed = "MapIt";
        this.googlemapshow = true;
       
      }
    }
    else if (id == 'UnMapit') {
      this.isMapItEventRaised = false;
      this.pageViewed = "";
      this.googlemapshow = false;
    }
    else if (id == 'Proposals') {
      if (email) {
        this.AdvancedSearchResult = false;
        let array = email.split(',');
        if (array.length === 1 && email !== undefined && email !== '') {
          this.pageViewed = "CreateProposal";
          this.Email = email;
          this.ExpandSelectedMenu('Proposals');
          this.ProposalSubMenu('AddProposal')
        } else if (array.length > 1) {
          this.isMultipleContactSelected = true;
          this.notSelectedCss("isMultipleContactSelected");
        }
      }
      else {
        this.loaddialog('NotSelected', 'Action Icon', 'An Account or Contact must be selected first', false, false, true, true,0); 
      }
    }
    else if (id == 'ResponseWizard') {
      this.responseWizardType = 'TaskList';
      $(".subButtons").removeClass("activeButton");
      $('#' + id + "_button").addClass("activeButton");
    }
    else if (id == 'EditResponseWizard') {
      this.responseWizardType = 'SalesTool';
      $(".subButtons").removeClass("activeButton");
      $('#' + id + "_button").addClass("activeButton");
    }
    else if (id == 'LastActivity') {
      this.Searchtabname = "ContactsAccount";
      this.router.navigateByUrl(`/application/account/LastActivity`);
    }
    else {
      if (id == 'a-z_button') {
      }
      else if (id == 'practice') {
        this.router.navigateByUrl(`/application/customer/practice`);
      }
      else if (id == 'specialty') {
        this.router.navigateByUrl(`/application/customer/specialty`);
      }
      else if (id == 'allaccount') {
        this.Searchtabname = "CustomerAccount";
        this.router.navigateByUrl(`/application/account/Customer`);
        //Callaccount_button


        $(".subButtons").removeClass("activeButton");
        $('#Callaccount_button').addClass("activeButton");

      }
      else if (id == 'recent') {
        this.router.navigateByUrl(`/application/customer/recent`);
      }

      else if (id == 'Contacts') {
        this.Searchtabname = "contacts";
        let selfobj = this;
        $(setTimeout(function () {
          selfobj.activateSubButtons('');
          $(setTimeout(function () {
            selfobj.router.navigateByUrl(`/application/contacts/contacts/null`);
          }, 50))
        }, 50));
        
      }
      else if (id == 'allContactAccounts') {
        this.Searchtabname = "ContactsAccount";
        let selfobj = this;
        $(setTimeout(function () {
          selfobj.activateSubButtons('');
          $(setTimeout(function () {
            selfobj.router.navigateByUrl(`/application/account/Accounts`);
          }, 50))
        }, 50));
        
      }
      else if (id == 'specialty') {
        this.router.navigateByUrl(`/application/contacts/specialty/null`);
      }
      else if (id == 'a-z') {
        this.router.navigateByUrl(`/application/customercontacts`);
        this.Searchtabname = "customercontacts";
        $(".subButtons").removeClass("activeButton");
        $('#Ca-z_button').addClass("activeButton");
      } else {
        //this.router.navigateByUrl(`/application/projects`);
      }

    }

  }
  deletSearchQuery(tabname) {
    this.Searchtabname = tabname;
    $('#searchContact').val('');
    this.term = "";
    let selfobj = this;
    selfobj.advancedSearchService.deleteSearchQuery(tabname, selfobj.currentuser.id).then((out) => {

      if (tabname == "contacts") {
        $(setTimeout(function () {
          selfobj.activateSubButtons('');
          $(setTimeout(function () {
            selfobj.activateSubButtons('Contacts');
          }, 50))
        }, 50));

      }
      else if (tabname == "Accounts") {
        $(setTimeout(function () {
          selfobj.activateSubButtons('empty');
          $(setTimeout(function () {
            selfobj.activateSubButtons('allContactAccounts');
          }, 500))
        }, 100));

      }
      else if (tabname == "ContactsAccount") {
        $(setTimeout(function () {
          selfobj.activateSubButtons('Contacts');
          $(setTimeout(function () {
            selfobj.activateSubButtons('allContactAccounts');
          }, 50))
        }, 100));
      }
      else if (tabname == "CustomerAccount") {
        let selfobj = this;
        $(setTimeout(function () {
          selfobj.activateSubButtons('empty');
          $(setTimeout(function () {
            selfobj.activateSubButtons('allaccount');
          }, 500));
        }, 100));
      }
      else if (tabname == "customercontacts") {
        let selfobj = this;
        $(setTimeout(function () {
          selfobj.activateSubButtons('empty');
          $(setTimeout(function () {
            selfobj.activateSubButtons('a-z');
          }, 500));
        }, 100));
      }
      else if (tabname == "OpenTaskHistory") {
        $(setTimeout(function () {
          selfobj.TaskData('Open', selfobj.currentuser.id)
        }, 100));
      }
      else if (tabname == "CompletedTaskHistory") {
        $(setTimeout(function () {
          selfobj.TaskData('Completed', selfobj.currentuser.id)
        }, 100));
      }
      else if (tabname == "AllTaskHistory") {
        $(setTimeout(function () {
          selfobj.TaskData('All', selfobj.currentuser.id)
        }, 100));
      }
      else if (tabname == "OpenLeads") {
        $(setTimeout(function () {
          selfobj.activateSubButtons('');
          $(setTimeout(function () {
            selfobj.activateMainButtons('leads')
            selfobj.ClosedLeads('Open', selfobj.currentuser.id, 'Open Leads')
          }, 200))
        }, 50));

      }
      else if (tabname == "CompletedLeads") {
        selfobj.ClosedLeads('Completed', selfobj.currentuser.id, 'Closed Leads')
      }
      else if (tabname == "AllLeads") {
        selfobj.ClosedLeads('All', selfobj.currentuser.id, 'All Leads');
      }
      else if (tabname == "Calllists") {
        $(setTimeout(function () {
          selfobj.activateSubButtons('');
          $(setTimeout(function () {
            selfobj.activateSubButtons('Cviewcalllist');
          }, 400));
        }, 50));
      }
      else if (tabname == "Quickview") {

        let selfobj = this;
        $(setTimeout(function () {
          selfobj.activateSubButtons('quickview')
        }, 100));
      }
      else if (tabname == "Global") {
        let selfobj = this;
        $(setTimeout(function () {
          selfobj.hideGlobalSearchtable = true;
          selfobj.UpdateSearchColumns(selfobj.colSelections);
          this.SearchSelectedItemsLengthChanged = false;
        }, 200))
      }
      else if (tabname == "OpenCalander" || tabname == "CompletedCalander" || tabname == "AllCalander") {
        let selfobj = this;
        $(setTimeout(function () {
          selfobj.activateSubButtons('');
          $(setTimeout(function () {
            selfobj.activateMainButtons('Calendar');
            selfobj.SubmenuChange('Calendar', 'TaskCalander', 'Sub');
          }, 100));

        }, 200));
      }
      else if (tabname == "OpenOpportunity" || tabname == 'ClosedOpportunity' || tabname == 'AllOpportunity') {
        let selfobj = this;
        $(setTimeout(function () {
          //selfobj.activateSubButtons('');
          selfobj.activateMainButtons('task lists');
          $(setTimeout(function () {
            selfobj.activateMainButtons('opportunities');
            selfobj.ClosedOpportunity((tabname == "OpenOpportunity") ? 'Open' : (tabname == "ClosedOpportunity") ? 'Closed' : 'All', selfobj.currentuser.id)
          }, 100))
        }, 10));
      }
      else if (tabname == "Proposals") {
        let selfobj = this;
        $(setTimeout(function () {
          selfobj.ProposalSubMenu('Closed Proposals');
          $(setTimeout(function () {
            selfobj.ProposalSubMenu('Open Proposals');
          }, 200))
        }, 200));

      }
      else if (tabname == "tickets") {
        let selfobj = this;
        $(setTimeout(function () {
          selfobj.activateSubButtons('empty');
          $(setTimeout(function () {          
            selfobj.activateMainButtons('tickets');
            switch (this.TicketDataVal) {
              case 'Open': {
                selfobj.TicketData('Open', this.currentuser.id, 'Open Tickets');
                break;
              }
              case 'Pended': {
                selfobj.TicketData('Pended', this.currentuser.id, 'Pended Tickets')
                break;
              }
              case 'On-Hold': {
                selfobj.TicketData('On-Hold', this.currentuser.id, 'On-Hold Tickets')
                break;
              }
              case 'Completed': {
                selfobj.TicketData('Completed', this.currentuser.id, 'Closed Tickets')
                break;
              }
              case 'Deleted': {
                selfobj.TicketData('Deleted', this.currentuser.id, 'Deleted Tickets')
                break;
              }
            }

          }, 200))
        }, 50));

      }
    });

    this.tempdata = [];
    localStorage.removeItem('TempTable');
    this.SearchSelectedItems = [];
  }
  saveSearchQuery(tabname, selectedClumns, PreviousSelectedColumns) {
    this.Searchtabname = tabname;
    let qryName: string = "TabSearch";
    qryName = "'" + qryName + "'";
    var tablename = "";
    var alias = "";
    var contablejoin = "";
    let searchQry: string;
    let whereQry = "";
    let Joins = "";
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (tabname == 'ContactsAccount' || tabname == 'CustomerAccount' || tabname == 'Accounts') {
      tablename = 'tblaccounts';
      alias = 'acc';
    }
    else if (tabname == 'contacts' || tabname == 'customercontacts' || tabname == 'Global') {
      tablename = 'tblcontacts';
      alias = 'con';
    }
    else if (tabname == 'OpenLeads' || tabname == 'CompletedLeads' || tabname == 'AllLeads') {
      tablename = 'tblsaleprocesshistory';
      alias = 'sale';

    }
    else if (tabname == 'OpenOpportunity' || tabname == 'ClosedOpportunity' || tabname == 'AllOpportunity') {
      tablename = 'tblopportunities'
      alias = 'opp';
      contablejoin = " join tblcontacts as con on opp.accountorcontactid=con.id  join tblaccounts as acc on con.accountid=acc.id"
    }
    else if (tabname == 'OpenTaskHistory' || tabname == 'CompletedTaskHistory' || tabname == 'AllTaskHistory' || tabname == 'OpenCalander' || tabname == 'Reports' || tabname == 'CompletedCalander' || tabname == 'AllCalander' || tabname == 'Open Tasks' ) {
      tablename = 'tbltaskhistory'
      alias = 'tas';
    }
    else if (tabname == 'Calllists') {
      tablename = 'tblcalllist'
      alias = 'cal';
    }
    else if (tabname == 'Quickview') {
      tablename = 'tblcontacts'
      alias = 'con';
    }
    else if (tabname == 'Proposals') {
      tablename = 'tblproposals'
      alias = 'prop';
      contablejoin = " join tblaccounts as acc on prop.AccountID =acc.id join tblcontacts as con on prop.ContactId = con.id "
    }
    else if (tabname == 'tickets') {
      tablename = 'tblinternalticket'
      alias = 'tkts';
      contablejoin = " join tblcontacts as con on tkts.ContactId = con.id join tblaccounts as acc on con.accountid = acc.id join tbluser as usr on tkts.assignTicketToUser = usr.id"
    }
    else if (tabname == 'Rhx') {
      tablename = 'tblauditlog'
      alias = 'audi';
      contablejoin = " join tblaccounts as acc on audi.AccountOrContactId =acc.id join tblcontacts as con on audi.AccountOrContactId = con.id "
    }

    if ((tabname == 'contacts') || tabname == 'customercontacts' || tabname == 'QuickView') {

      searchQry = " 'select " + alias + ".*,true as islimitedSearch,''" + this.searchCriteria + "'' as SearchVal, acc.accountname AS accountname,usr.username AS OWNER,acc.accnumber,acc.customertypeid AS customertype from " + tablename + " as " + alias;
    }
    else {
      searchQry = " 'select " + alias + ".*,true as islimitedSearch,''" + this.searchCriteria + "'' as SearchVal,acc.accountname,acc.accnumber,acc.customertypeid AS customertype,IF(acc.customertypeid=0,''Contact'',''Account'') AS TYPE from " + tablename + " as " + alias;
    }
    let Where = "";
    if (tablename == "tblcontacts") {
      Where = " where (" + alias + ".userid = " + currentUser.id + " or " + alias + ".ownerid=" + currentUser.id + ")";
    }
    else if (tablename == "tblproposals") {
      Where = " where " + alias + ".CreatedBy = " + currentUser.id;
    }
    else {
      Where = " where " + alias + ".userid = " + currentUser.id;
    }
    if (tablename == "tblsaleprocesshistory") {
      searchQry = " 'select sale.id, sale.userid, sale.contactid,true as islimitedSearch,''" + this.searchCriteria + "'' as SearchVal, sale.datecreated, con.lastname, con.middlename, con.firstname, con.title, acc.accountname AS ACCOUNT,(SELECT salesstage FROM tblsaleprocesshistory WHERE contactid=sale.contactid ORDER BY datecreated DESC LIMIT 1) AS salesstage,con.firstname,con.middlename,PK.itemName as titleName,con.lastname,true as islimitedSearch ,''" + $('#searchContact').val() + "'' as SearchVal,acc.accountname AS account,UpdateLeadStage(sale.contactid,(SELECT salesstage FROM tblsaleprocesshistory WHERE contactid=sale.contactid ORDER BY datecreated DESC LIMIT 1), (SELECT STATUS FROM tblsaleprocesshistory WHERE contactid = sale.contactid ORDER BY datecreated DESC LIMIT 1)) AS STATUS ,tsktype.tasktype AS nexttask, sale.completeddate AS datescheduled,user.username AS OWNER ,comuser.username AS completeduser,sale.dealwonOrlost  from " + tablename + " as " + alias;
      Joins += " join tblcontacts as con on " + alias + ".contactid=con.id join tblaccounts as acc on con.accountid = acc.id LEFT JOIN tbltaskhistory AS task ON task.salesstageid =sale.id left join tbltasktypes as tsktype on task.tasktypeid = tsktype.id JOIN tbluser AS USER ON sale.userid =  user.id LEFT JOIN tbluser AS comuser ON sale.completeduserid = comuser.id left join picklistfielddata as PK on con.title = PK.id ";
      let Where = " where " + alias + ".userid = " + currentUser.id;
      for (let i = 0; i < selectedClumns.length; i++) {
        if (selectedClumns[i] == 'account') {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            whereQry += " and acc.accountname REGEXP '(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)' )";
          }
          else {
            whereQry += " and acc.accountname" + " like ''%" + $('#searchContact').val() + "%''";
          }
        }
        else if (selectedClumns[i] == 'firstname' || selectedClumns[i] == 'middlename' || selectedClumns[i] == 'lastname' ) {
          whereQry += " and con." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
        }
        else if (selectedClumns[i] == 'titleName') {
          Joins += " Left Join picklistfielddata as " + selectedClumns[i] + " on con .title = " + selectedClumns[i] + ".id";
          whereQry += " and " + selectedClumns[i] + ".itemName like ''%" + $('#searchContact').val() + "%''";
        }
        else if (selectedClumns[i] == 'datecreated' || selectedClumns[i] == "completeddate") {
          whereQry += " AND DATE(" + alias + "." + selectedClumns[i] + ") >= ''" + this.SearchstartVal + "'' AND DATE(" + alias + "." + selectedClumns[i] + ") <=''" + this.SearchendVal + "''";
        }
        else {
          whereQry += " and con." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
        }
      }
      whereQry += " GROUP BY contactid";
    }
    else if (tablename == "tbltaskhistory") {
      searchQry = "'select tas.id,tas.datecreated,tas.estcompleteddate,true as islimitedSearch,''" + this.searchCriteria + "'' as SearchVal,acc.accountname AS accountname,tas.status,con.contacttype,tas.dateCompleted AS datecompleted,tsktype.tasktype,tsktype.tasktype as TypeofTask,tsktype.category as taskcategory, tas.type, tas.contactid, tas.time AS starttime, tas.totime AS endtime, con.firstname AS firstName, con.lastname AS lastName,true as islimitedSearch ,''" + $('#searchContact').val() + "'' as SearchVal, usr.username from " + tablename + " as " + alias;
      Joins += " join tblcontacts as con on " + alias + ".contactid=con.id ";
      Joins += "join tblaccounts as acc on con.accountid = acc.id JOIN tbluser AS usr ON tas.userid = usr.id join tbltasktypes as tsktype on tas.tasktypeid=tsktype.id";

      for (let j = 0; j < PreviousSelectedColumns.length; j++) {

        var prandcondition = false;
        if (PreviousSelectedColumns[j].split(':')[0] == 'accountname' || PreviousSelectedColumns[j].split(':')[0] == 'practicename') {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            if (!prandcondition) {
              whereQry += " and " + " acc." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)'' ";
              prandcondition = true;
            } else {
              whereQry += " or " + " acc." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)'' ";
            }
          }
          else {
            if (!prandcondition) {
              whereQry += " and " + " acc.accountname like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
              prandcondition = true;
            } else {
              whereQry += " or " + " acc.accountname like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
            }
          }
        }
        else {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            if (!prandcondition) {
              whereQry += " and " + alias + "." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
              prandcondition = true;
            } else {
              whereQry += " or " + alias + "." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
            }
          }
          else {
            if (!prandcondition) {
              whereQry += " and CONCAT_WS('' ''," + alias + "." + PreviousSelectedColumns[j].split(':')[0].replace(",", ",con.") + ") LIKE ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
              prandcondition = true;
            } else {
              whereQry += " or CONCAT_WS('' ''," + alias + "." + PreviousSelectedColumns[j].split(':')[0].replace(",", ",con.") + ") LIKE ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
            }
          }
        }

      }


      for (let i = 0; i < selectedClumns.length; i++) {
        if (selectedClumns[i] == 'accountname' || selectedClumns[i] == 'practicename') {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            whereQry += " and acc.accountname REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
          }
          else {
            whereQry += " and acc.accountname" + " like ''%" + $('#searchContact').val() + "%''";
          }
        }
        else if (selectedClumns[i] == 'firstName') {
          whereQry += " and con.firstName like ''%" + $('#searchContact').val() + "%''";
        }
        else if (selectedClumns[i] == 'lastName') {
          whereQry += " and con.lastName like ''%" + $('#searchContact').val() + "%''";
        }
        else if (selectedClumns[i] == "datecreated" || selectedClumns[i] == "estcompleteddate") {
          whereQry += " AND DATE(" + alias + "." + selectedClumns[i] + ") >= ''" + this.SearchstartVal + "'' AND DATE(" + alias + "." + selectedClumns[i] + ") <=''" + this.SearchendVal + "''";
        }
        else if (selectedClumns[i] == "taskcategory") {
          whereQry += " and tsktype.category like ''%" + $('#searchContact').val() + "%''";
        }
        else if (selectedClumns[i] == "tasktype" || selectedClumns[i] == "TypeofTask") {
          whereQry += " and tsktype.tasktype like ''%" + $('#searchContact').val() + "%''";
        }
        else if (selectedClumns[i] == "contacttype") {
          whereQry += " and con.contacttype like ''%" + $('#searchContact').val() + "%''";
        }
        else {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            whereQry += " and " + alias + "." + selectedClumns[i] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
          }
          else {
            whereQry += " and " + alias + "." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
          }
        }

      }
      whereQry += (tabname == "OpenTaskHistory" || tabname == "OpenCalander") ? " and " + alias + ".status = ''Open''" : (tabname == "CompletedTaskHistory" || tabname == "CompletedCalander") ? " and " + alias + ".status = ''Completed''" : " and " + alias + ".status IS NOT NULL";
    }

    else if (tablename == "tblcontacts") {
       
      var customertypeid = (tabname == "ContactsAccount") ? '0' : (tabname == "CustomerAccount") ? '1' : tablename == "tblcontacts" ? '0' : '1,2';
      searchQry = " 'select " + alias + ".*,PK1.ItemName as specialityName,PK2.itemName as titleName ,true as islimitedSearch,''" + this.searchCriteria + "'' as SearchVal, acc.accountname AS accountname,usr.username AS OWNER,acc.accnumber,acc.customertypeid AS customertype from " + tablename + " as " + alias;
      searchQry += " Left join picklistfielddata as PK1 on " + alias + ".speciality = PK1.id Left Join picklistfielddata as PK2 on " + alias + ".title = PK2.id";
      Joins += " join tbluser as usr on " + alias + ".userid = usr.id join tblaccounts as acc on con.accountid=acc.id";
      
      for (let j = 0; j < PreviousSelectedColumns.length; j++) {

        var prandcondition = false;
        if (PreviousSelectedColumns[j].split(':')[0] == 'accountname') {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            if (!prandcondition) {
              whereQry += "and acc.customertypeid in (" + customertypeid + ")  and " + " acc." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)'' ";
              prandcondition = true;
            } else {
              whereQry += "and acc.customertypeid in (" + customertypeid + ")  or " + " acc." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)'' ";
            }
          }
          else {
            if (!prandcondition) {
              whereQry += "and acc.customertypeid in (" + customertypeid + ")  and " + " acc." + PreviousSelectedColumns[j].split(':')[0] + " like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
              prandcondition = true;
            } else {
              whereQry += "and acc.customertypeid in (" + customertypeid + ")  or " + " acc." + PreviousSelectedColumns[j].split(':')[0] + " like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
            }
          }
        }
        else if (PreviousSelectedColumns[j].split(',')[0] == 'owner') {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            if (!prandcondition) {
              whereQry += "and acc.customertypeid in (" + customertypeid + ")  and " + " usr.username REGEXP ''(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)'' ";
              prandcondition = true;
            } else {
              whereQry += "and acc.customertypeid in (" + customertypeid + ")  or " + " usr.username REGEXP ''(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)'' ";
            }
          }
          else {
            if (!prandcondition) {
              whereQry += "and acc.customertypeid in (" + customertypeid + ")  and " + " usr.username like =''" + PreviousSelectedColumns[j].split(':')[1] + "''";
              prandcondition = true;
            } else {
              whereQry += "and acc.customertypeid in (" + customertypeid + ")  or " + " usr.username like =''" + PreviousSelectedColumns[j].split(':')[1] + "''";
            }
          }
        }
        else if (PreviousSelectedColumns[j].split(',')[0] == 'type') {
          if ($('#searchContact').val() == 'Contact') {
            whereQry += " and acc.customertypeid=0";
          }
          else {
            whereQry += " and acc.customertypeid!=0";
          }
        }
        else if (PreviousSelectedColumns[j].split(':')[0] == 'cell' || PreviousSelectedColumns[j].split(',')[0] == 'office') {
          if (!prandcondition) {
            //if (/,/.test(columnarray[k]) == true) {
            //  PreviousSelectedColumns = columnarray[k].split(',');
            //}
            //else {
            whereQry += "and acc.customertypeid in (" + customertypeid + ")  and " + alias + "." + PreviousSelectedColumns[j].split(',')[0] + " =''" + PreviousSelectedColumns[j].split(':')[1] + "''";
            //}
            prandcondition = true;
          } else {
            whereQry += "and acc.customertypeid in (" + customertypeid + ")  or " + alias + "." + PreviousSelectedColumns[j].split(',')[0] + " =''" + PreviousSelectedColumns[j].split(':')[1] + "''";
          }
        }
        else {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            if (!prandcondition) {
              whereQry += "and acc.customertypeid in (" + customertypeid + ")  and " + alias + "." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
              prandcondition = true;
            } else {
              whereQry += "and acc.customertypeid in (" + customertypeid + ")  or " + alias + "." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
            }
          }
          else {
            if (!prandcondition) {
              whereQry += "and acc.customertypeid in (" + customertypeid + ")  and CONCAT_WS('' ''," + alias + "." + PreviousSelectedColumns[j].split(':')[0].replace(",", ",con.") + ") LIKE ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
              prandcondition = true;
            } else {
              whereQry += "and acc.customertypeid in (" + customertypeid + ")  or CONCAT_WS('' ''," + alias + "." + PreviousSelectedColumns[j].split(':')[0].replace(",", ",con.") + ") LIKE ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
            }
          }
        }

      }

      var andcondition = false;
      for (let i = 0; i < selectedClumns.length; i++) {
        if ($('#searchContact').val().includes('/')) {
          var searchvaluesarray = $('#searchContact').val().split(' / ');
          searchvaluesarray.forEach(element => {
            if (selectedClumns[i] == 'accountname') {
              if (element.indexOf('"') >= 0) {
                if (!andcondition) {
                  whereQry += " and " + " acc." + selectedClumns[i] + " REGEXP ''(^|[[:space:]])" + element + "([[:space:]]|$)'' ";
                  andcondition = true;
                } else {
                  whereQry += " or " + " acc." + selectedClumns[i] + " REGEXP ''(^|[[:space:]])" + element + "([[:space:]]|$)'' ";
                }
              }
              else {
                if (!andcondition) {
                  whereQry += " and " + " acc." + selectedClumns[i] + " like ''%" + element + "%''";;
                  andcondition = true;
                } else {
                  whereQry += " or " + " acc." + selectedClumns[i] + " like ''%" + element + "%''";
                }
              }
            }
            else if (selectedClumns[i] == 'titleName' || selectedClumns[i] == 'specialityName') {
              var type = "";
              switch (selectedClumns[i]) {
                case 'titleName': {
                  type = 'title';
                  break;
                }
                case 'specialityName': {
                  type = 'speciality';
                  break;
                }
              }
              if (element.indexOf('"') >= 0) {
                if (!andcondition) {
                  Joins += " left join picklistfielddata as " + selectedClumns[i] + " on " + alias + "." + type +" = " + selectedClumns[i]+".id"
                  whereQry += " and " + selectedClumns[i] + ".itemName like ''%" + element + "%''";
                  andcondition = true;
                } else {
                  Joins += " left join picklistfielddata as " + selectedClumns[i] + " on " + alias + "." + type + " = " + selectedClumns[i] + ".id"
                  whereQry += " and " + selectedClumns[i] + ".itemName like ''%" + element + "%''";
                }
              }
              else {
                if (!andcondition) {
                  Joins += " left join picklistfielddata as " + selectedClumns[i] + " on " + alias + "." + type + " = " + selectedClumns[i] + ".id"
                  whereQry += " and " + selectedClumns[i] + ".itemName like ''%" + element + "%''";
                  andcondition = true;
                } else {
                  Joins += " left join picklistfielddata as " + selectedClumns[i] + " on " + alias + "." + type + " = " + selectedClumns[i] + ".id"
                  whereQry += " and " + selectedClumns[i] + ".itemName like ''%" + element + "%''";
                }
              }
            }
            else if (selectedClumns[i] == 'owner') {
              if (element.indexOf('"') >= 0) {
                if (!andcondition) {
                  whereQry += " and " + " usr.username REGEXP ''(^|[[:space:]])" + element + "([[:space:]]|$)'' ";
                  andcondition = true;
                } else {
                  whereQry += " or " + " usr.username REGEXP ''(^|[[:space:]])" + element + "([[:space:]]|$)'' ";
                }
              }
              else {
                if (!andcondition) {
                  whereQry += " and " + " usr.username =''" + element + "''";
                  andcondition = true;
                } else {
                  whereQry += " or " + " usr.username =''" + element + "''";
                }
              }
            }
            else if (selectedClumns[i] == 'type') {
              if (element == 'Contact') {
                whereQry += " and acc.customertypeid=0";
              }
              else {
                whereQry += " and acc.customertypeid!=0";
              }
            }
            else {
              if (element.indexOf('"') >= 0) {
                if (!andcondition) {
                  whereQry += " and " + alias + "." + selectedClumns[i] + " REGEXP ''(^|[[:space:]])" + element + "([[:space:]]|$)'' ";
                  andcondition = true;
                } else {
                  whereQry += " or " + alias + "." + selectedClumns[i] + " REGEXP ''(^|[[:space:]])" + element + "([[:space:]]|$)'' ";
                }
              }
              else {
                if (!andcondition) {
                  whereQry += " and " + alias + "." + selectedClumns[i] + " like ''%" + element + "%''";
                  andcondition = true;
                } else {
                  whereQry += " or " + alias + "." + selectedClumns[i] + " like ''%" + element + "%''";
                }
              }
            }
          });
        } else {
          if (selectedClumns[i] == 'accountname') {
            if ($('#searchContact').val().indexOf('"') >= 0) {
              if (!andcondition) {
                whereQry += " and " + " acc." + selectedClumns[i] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
                andcondition = true;
              } else {
                whereQry += " or " + " acc." + selectedClumns[i] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
              }
            }
            else {
              if (!andcondition) {
                whereQry += " and " + " acc." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";;
                andcondition = true;
              } else {
                whereQry += " or " + " acc." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
              }
            }
          }
          else if (selectedClumns[i] == 'titleName' || selectedClumns[i] == 'specialityName') {
            var type = "";
            switch (selectedClumns[i]) {
              case 'titleName': {
                type = 'title';
                break;
              }
              case 'specialityName': {
                type = 'speciality';
                break;
              }
            }
            if ($('#searchContact').val().indexOf('"') >= 0) {
              if (!andcondition) {
                Joins += " left join picklistfielddata as " + selectedClumns[i] + " on " + alias + "." + type + " = " + selectedClumns[i] + ".id"
                whereQry += " and " + selectedClumns[i] + ".itemName like ''%" + $('#searchContact').val() + "%''";
                andcondition = true;
              } else {
                Joins += " left join picklistfielddata as " + selectedClumns[i] + " on " + alias + "." + type + " = " + selectedClumns[i] + ".id"
                whereQry += " and " + selectedClumns[i] + ".itemName like ''%" + $('#searchContact').val() + "%''";
              }
            }
            else {
              if (!andcondition) {
                Joins += " left join picklistfielddata as " + selectedClumns[i] + " on " + alias + "." + type + " = " + selectedClumns[i] + ".id"
                whereQry += " and " + selectedClumns[i] + ".itemName like ''%" + $('#searchContact').val() + "%''";
                andcondition = true;
              } else {
                Joins += " left join picklistfielddata as " + selectedClumns[i] + " on " + alias + "." + type + " = " + selectedClumns[i] + ".id"
                whereQry += " and " + selectedClumns[i] + ".itemName like ''%" + $('#searchContact').val() + "%''";
              }
            }
          }
          else if (selectedClumns[i] == 'owner') {
            if ($('#searchContact').val().indexOf('"') >= 0) {
              if (!andcondition) {
                whereQry += " and " + " usr.username REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
                andcondition = true;
              } else {
                whereQry += " or " + " usr.username REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
              }
            }
            else {
              if (!andcondition) {
                whereQry += " and " + " usr.username =''" + $('#searchContact').val() + "''";
                andcondition = true;
              } else {
                whereQry += " or " + " usr.username =''" + $('#searchContact').val() + "''";
              }
            }
          }
          else if (selectedClumns[i] == 'type') {
            if ($('#searchContact').val() == 'Contact') {
              whereQry += " and acc.customertypeid=0";
            }
            else {
              whereQry += " and acc.customertypeid!=0";
            }
          }
          else {
            if ($('#searchContact').val().indexOf('"') >= 0) {
              if (!andcondition) {
                whereQry += " and " + alias + "." + selectedClumns[i] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
                andcondition = true;
              } else {
                whereQry += " or " + alias + "." + selectedClumns[i] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
              }
            }
            else {
              if (!andcondition) {
                whereQry += " and " + alias + "." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
                andcondition = true;
              } else {
                whereQry += " or " + alias + "." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
              }
            }
          }
        }



      }
      whereQry += selectedClumns.length == 0 ? "" : " ";

    }

    else if (tablename == "tblaccounts") {
       
      var customertypeid = (tabname == "ContactsAccount") ? '0' : (tabname == "CustomerAccount") ? '1' : tablename == "tblaccounts" ? '0' : '1,2';
      searchQry = " 'select " + alias + ".*,PK1.itemName as specialityName,Pk2.itemName as ehrName,PK3.itemName as pmName,PK4.itemName as rcmName,true as islimitedSearch,''" + this.searchCriteria + "'' as SearchVal,fnCheckAccountContact(acc.id) AS IsContact from " + tablename + " as " + alias;
      searchQry += " Left join picklistfielddata as PK1 on " + alias + ".speciality = PK1.id Left Join picklistfielddata as PK2 on " + alias + ".ehr = PK2.id Left Join picklistfielddata as PK3 on " + alias +".pm = PK3.id Left Join picklistfielddata as PK4 on "+alias+".rcm = Pk4.id"
      for (let j = 0; j < PreviousSelectedColumns.length; j++) {
        if (PreviousSelectedColumns[j].split(':')[0] == 'phone') {
          if (PreviousSelectedColumns[j].split(':')[1].indexOf('"') >= 0) {
            whereQry += " and acc." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)'' ";
          }
          else {
            whereQry += " and acc." + PreviousSelectedColumns[j].split(':')[0] + " like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
          }


        }
        else if (PreviousSelectedColumns[j].split(':')[0] == "specialityName" || PreviousSelectedColumns[j].split(':')[0] == 'ehrName' || PreviousSelectedColumns[j].split(':')[0] == 'pmName' || PreviousSelectedColumns[j].split(':')[0] == 'rcmName') {
          var type = "";
          switch (PreviousSelectedColumns[j].split(':')[0]) {
            case "specialityName": {
              type = "speciality";
              break;
            }
            case "ehrName": {
              type = "ehr";
              break;
            }
            case "pmName": {
              type = "pm";
              break;
            }
            case "rcmName": {
              type = "rcm";
              break;
            }

          }
          Joins += " Left join picklistfielddata as prv" + PreviousSelectedColumns[j].split(':')[0] + " on " + alias + "." + type + " = prv" + PreviousSelectedColumns[j].split(':')[0]+".id   ";
          whereQry += " and acc.customertypeid in (" + customertypeid + ")  and prv" + PreviousSelectedColumns[j].split(':')[0] + ".itemName like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
        }

        //else if (PreviousSelectedColumns[j] == "specialityName") {
        //  Joins += " Left join picklistfielddata as pk on " + alias + ".speciality=pk.id   ";
        //  whereQry += " and  acc.customertypeid in (" + customertypeid + ")  and pk.itemName like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
        //}
        else {
          whereQry += " and  acc.customertypeid in (" + customertypeid + ")  and " + alias + "." + PreviousSelectedColumns[j].split(':')[0] + " like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
        }
      }
      for (let i = 0; i < selectedClumns.length; i++) {
        if ($('#searchContact').val().includes('/')) {
          var searchvaluesarray = $('#searchContact').val().split(' / ');
          searchvaluesarray.forEach(element => {
            if (selectedClumns[i] == 'phone') {

              whereQry += " and " + alias + "." + selectedClumns[i] + " =''" + element + "''";

            }
            else if (selectedClumns[i] == "specialityName" || selectedClumns[i] == 'ehrName' || selectedClumns[i] == 'pmName' || selectedClumns[i] == 'rcmName') {
              var type = "";
              switch (selectedClumns[i]) {
                case "specialityName": {
                  type = "speciality";
                  break;
                }
                case "ehrName": {
                  type = "ehr";
                  break;
                }
                case "pmName": {
                  type = "pm";
                  break;
                }
                case "rcmName": {
                  type = "rcm";
                  break;
                }

              }
              Joins += " Left join picklistfielddata as " + selectedClumns[i] + " on " + alias + "." + type + " = " + selectedClumns[i]+".id   ";
              whereQry += " and  acc.customertypeid in (" + customertypeid + ")  and " + selectedClumns[i] + ".itemName like ''%" + element + "%''";
            }
            else if ($('#searchContact').val().indexOf('"') >= 0) {
              whereQry += " and  acc.customertypeid in (" + customertypeid + ") and " + alias + "." + selectedClumns[i] + " REGEXP ''(^|[[:space:]])" + element + "([[:space:]]|$)'' ";
            }
            else {
              whereQry += " and  acc.customertypeid in (" + customertypeid + ")  and " + alias + "." + selectedClumns[i] + " like ''%" + element + "%''";
            }
          });
        } else {
          if (selectedClumns[i] == 'phone') {

            whereQry += " and " + alias + "." + selectedClumns[i] + " =''" + $('#searchContact').val() + "''";

          }
          else if (selectedClumns[i] == "specialityName" || selectedClumns[i] == 'ehrName'|| selectedClumns[i] == 'pmName' || selectedClumns[i] == 'rcmName') {
            var type = "";
            switch (selectedClumns[i]) {
              case "specialityName": {
                type = "speciality";
                break;
              }
              case "ehrName": {
                type = "ehr";
                break;
              }
              case "pmName": {
                type = "pm";
                break;
              }
              case "rcmName": {
                type = "rcm";
                break;
              }
              
            }
            Joins += " Left join picklistfielddata as " + selectedClumns[i] + " on " + alias + "." + type + " = " + selectedClumns[i]+".id   ";
            whereQry += " and  acc.customertypeid in (" + customertypeid + ")  and " + selectedClumns[i]+".itemName like ''%" + $('#searchContact').val() + "%''";
          }
          else if ($('#searchContact').val().indexOf('"') >= 0) {
            whereQry += " and  acc.customertypeid in (" + customertypeid + ") and " + alias + "." + selectedClumns[i] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
          }
          else {
            whereQry += " and  acc.customertypeid in (" + customertypeid + ")  and " + alias + "." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
          }
        }
      }
    }

    else if (tablename == "tblopportunities") {
      searchQry = " 'select " + alias + ".*,true as islimitedSearch,''" + this.searchCriteria + "'' as SearchVal,acc.accountname AS practicename, con.firstname as firstName,con.lastname as lastName,item.opportunityitem as itemName,pcdrp.itemName as PCname,drdrp.itemName as drsName,mddrp.itemName as midlevel,leaddrp.itemName as leadfrom, " +
        "usr.username as owner from " + tablename + " as " + alias;
      Joins += contablejoin;
      Joins += " Join tbluser as usr on opp.userid =usr.id ";
      Joins += " Join tblopportunityitems as item on opp.item =item.id ";
      Joins += " LEFT Join tbldropdown as pcdrp on opp.PC =pcdrp.id ";
      Joins += " LEFT Join tbldropdown as drdrp on opp.drs =drdrp.id ";
      Joins += " LEFT Join tbldropdown as mddrp on opp.midlevels =mddrp.id ";
      Joins += " LEFT Join tbldropdown as leaddrp on opp.leadfromid =leaddrp.id ";

      for (let j = 0; j < PreviousSelectedColumns.length; j++) {

        var prandcondition = false;
        if (PreviousSelectedColumns[j].split(':')[0] == 'practicename') {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            if (!prandcondition) {
              whereQry += " and " + " acc." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)'' ";
              prandcondition = true;
            } else {
              whereQry += " or " + " acc." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)'' ";
            }
          }
          else {
            if (!prandcondition) {
              whereQry += " and " + " acc.accountname like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
              prandcondition = true;
            } else {
              whereQry += " or " + " acc.accountname like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
            }
          }
        }
        else {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            if (!prandcondition) {
              whereQry += " and " + alias + "." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
              prandcondition = true;
            } else {
              whereQry += " or " + alias + "." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
            }
          }
          else {
            if (!prandcondition) {
              whereQry += " and CONCAT_WS('' ''," + alias + "." + PreviousSelectedColumns[j].split(':')[0].replace(",", ",con.") + ") LIKE ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
              prandcondition = true;
            } else {
              whereQry += " or CONCAT_WS('' ''," + alias + "." + PreviousSelectedColumns[j].split(':')[0].replace(",", ",con.") + ") LIKE ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
            }
          }
        }

      }


      for (let i = 0; i < selectedClumns.length; i++) {

        if (selectedClumns[i] == 'itemName')
          whereQry += " and item.opportunityitem like ''%" + $('#searchContact').val() + "%''";

        else if (selectedClumns[i] == 'PCname') {
          whereQry += " and pcdrp.itemName like ''%" + $('#searchContact').val() + "%''";
        }
        else if (selectedClumns[i] == 'drsName') {
          whereQry += " and drdrp.itemName like ''%" + $('#searchContact').val() + "%''";
        }
        else if (selectedClumns[i] == 'midlevel') {
          whereQry += " and mddrp.itemName like ''%" + $('#searchContact').val() + "%''";
        }
        else if (selectedClumns[i] == 'leadfrom') {
          whereQry += " and leaddrp.itemName like ''%" + $('#searchContact').val() + "%''";
        }
        else if (selectedClumns[i] == 'firstName') {
          whereQry += " and (con.firstName like ''%" + $('#searchContact').val() + "%'')";
        }
        else if (selectedClumns[i] == 'lastName') {
          whereQry += " and (con.lastName like ''%" + $('#searchContact').val() + "%'')";
        }
        else if (selectedClumns[i] == 'practicename') {
          whereQry += " and  acc.accountname like ''%" + $('#searchContact').val() + "%''";
        }
        else if (selectedClumns[i] == "datecreated" || selectedClumns[i] == "estimatedclosedate" || selectedClumns[i] == "datecompleted") {
          // whereQry += " and " + alias + "." + selectedClumns[i] + " = ''" + $('#searchContact').val() + "''";
          whereQry += " AND DATE(" + alias + "." + selectedClumns[i] + ") >= ''" + this.SearchstartVal + "'' AND DATE(" + alias + "." + selectedClumns[i] + ") <=''" + this.SearchendVal + "''";
        }
        else {
          whereQry += " and opp." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
        }

      }
      whereQry += (tabname == "OpenOpportunity") ? " and " + alias + ".status = ''Open''" : (tabname == "ClosedOpportunity") ? " and " + alias + ".status = ''Closed-Won''" : " and " + alias + ".status IS NOT NULL";
    }

    else if (tablename == "tblproposals") {
      searchQry = " 'select " + alias + ".*,true as islimitedSearch,''" + this.searchCriteria + "'' as SearchVal,acc.accountname AS AccountName,Concat(con.firstname,'','',con.lastname) as ContactName,item.opportunityitem as itemName,leaddrp.itemName as leadfrom, " +
        "opp.LeadDescription ,opp.status from " + tablename + " as " + alias;
      Joins += contablejoin;
      Joins += " Join tblopportunities as opp on " + alias + ".OpportunityId = opp.id ";
      Joins += " Join tblopportunityitems as item on opp.item =item.id ";
      Joins += " LEFT Join tbldropdown as leaddrp on opp.leadfromid =leaddrp.id ";

      for (let j = 0; j < PreviousSelectedColumns.length; j++) {

        var prandcondition = false;
        if (PreviousSelectedColumns[j].split(':')[0] == 'accountname' || PreviousSelectedColumns[j].split(':')[0] == 'account' || PreviousSelectedColumns[j].split(':')[0] == 'AccountName') {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            if (!prandcondition) {
              whereQry += " and " + " acc." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)'' ";
              prandcondition = true;
            } else {
              whereQry += " or " + " acc." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)'' ";
            }
          }
          else {
            if (!prandcondition) {
              whereQry += " and " + " acc." + PreviousSelectedColumns[j].split(':')[0] + " like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
              prandcondition = true;
            } else {
              whereQry += " or " + " acc." + PreviousSelectedColumns[j].split(':')[0] + " like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
            }
          }
        }
        else {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            if (!prandcondition) {
              whereQry += " and " + alias + "." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
              prandcondition = true;
            } else {
              whereQry += " or " + alias + "." + PreviousSelectedColumns[j].split(':')[0] + " REGEXP ''(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)'' ";
            }
          }
          else {
            if (!prandcondition) {
              whereQry += " and CONCAT_WS('' ''," + alias + "." + PreviousSelectedColumns[j].split(':')[0].replace(",", ",con.") + ") LIKE ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
              prandcondition = true;
            } else {
              whereQry += " or CONCAT_WS('' ''," + alias + "." + PreviousSelectedColumns[j].split(':')[0].replace(",", ",con.") + ") LIKE ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
            }
          }
        }

      }

      for (let i = 0; i < selectedClumns.length; i++) {
        if (selectedClumns[i] == 'accountname' || selectedClumns[i] == 'account' || selectedClumns[i] == 'AccountName') {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            whereQry += " and acc.accountname REGEXP '(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)' )";
          }
          else {
            whereQry += " and acc.accountname" + " like ''%" + $('#searchContact').val() + "%''";
          }
        } else if (selectedClumns[i] == 'ContactName') {
          whereQry += " and (con.firstname like ''%" + $('#searchContact').val() + "%'' || con.lastname like ''%" + $('#searchContact').val() + "%'')";
        }
        else if (selectedClumns[i] == 'itemName') {
          whereQry += " and item.opportunityitem" + " like ''%" + $('#searchContact').val() + "%''";
        }
        else {
          whereQry += " and " + alias + "." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
        }
      }
      whereQry += (this.ProposalType == "Open Proposals") ? " and opp.status =''Open''" : (this.ProposalType == "Closed Proposals") ? " and (opp.status = ''Closed-Won'' || opp.status = ''Closed-Lost'')" : " and opp.status is NOT Null";

    }
    else if (tablename == "tblinternalticket") {
      searchQry = " 'select " + alias + ".*,true as islimitedSearch,''" + this.searchCriteria + "'' as SearchVal,acc.accountname AS accountname,Concat(con.firstname,'','',con.lastname) as contactname,(SELECT GROUP_CONCAT(username) FROM tbluser WHERE FIND_IN_SET(id,tkts.assignTicketToUser)) AS userList " +
        "from " + tablename + " as " + alias;
      Joins += contablejoin;
      for (let i = 0; i < selectedClumns.length; i++) {
        if (selectedClumns[i] == 'accountName' || selectedClumns[i] == 'account' || selectedClumns[i] == 'AccountName') {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            whereQry += " and acc.accountname REGEXP '(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)' )";
          }
          else {
            whereQry += " and acc.accountname" + " like ''%" + $('#searchContact').val() + "%''";
          }
        }
        else if (selectedClumns[i] == 'contactname') {
          whereQry += " and (con.firstname like ''%" + $('#searchContact').val() + "%'' || con.lastname like ''%" + $('#searchContact').val() + "%'')";
        }
        else if (selectedClumns[i] == 'userList') {
          whereQry += " and usr.username" + " like ''%" + $('#searchContact').val() + "%''";
        }
        else {
          whereQry += " and " + alias + "." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
        }
      }
      var status = 0;
      switch (this.TicketDataVal) {
        case 'Open': {
          status = 1;
          break;
        }
        case 'Pended': {
          status = 3;
          break;
        }
        case 'On-Hold': {
          status = 2;
          break;
        }
        case 'Completed': {
          status = 4;
          break;
        }
        case 'Deleted': {
          status = 6;
          break;
        }
      }

      whereQry += " and tkts.TicketStatus =''" + status + "''";

    }

    else if (tablename == "tblauditlog") {
      searchQry = " 'select " + alias + ".*,true as islimitedSearch,''" + this.searchCriteria + "'' as SearchVal,acc.accountname from " + tablename + " as " + alias;
      Joins += contablejoin;
      //Joins += " Join tblopportunities as opp on " + alias + ".OpportunityId = opp.id ";
      //Joins += " Join tblopportunityitems as item on opp.item =item.id ";
      //Joins += " LEFT Join tbldropdown as leaddrp on opp.leadfromid =leaddrp.id ";

      for (let i = 0; i < selectedClumns.length; i++) {
        if (selectedClumns[i] == 'accountname' || selectedClumns[i] == 'account' || selectedClumns[i] == 'AccountName') {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            whereQry += " and acc.accountname REGEXP '(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)' )";
          }
          else {
            whereQry += " and acc.accountname" + " like ''%" + $('#searchContact').val() + "%''";
          }
        } else if (selectedClumns[i] == 'ContactName') {
          whereQry += " and (con.firstname like ''%" + $('#searchContact').val() + "%'' || con.lastname like ''%" + $('#searchContact').val() + "%'')";
        }
        //else if (selectedClumns[i] == 'itemName') {
        //  whereQry += " and item.opportunityitem" + " like ''%" + $('#searchContact').val() + "%''";
        //}
        else {
          whereQry += " and " + alias + "." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
        }
      }
      //whereQry += (this.ProposalType == "Open Proposals") ? " and opp.status =''Open''" : (this.ProposalType == "Closed Proposals") ? " and (opp.status = ''Closed-Won'' || opp.status = ''Closed-Lost'')" : " and opp.status is NOT Null";

    }

    else if (tablename == "tblcalllist") {
      searchQry = " 'select cal.id,cal.created,cal.calllistname,''" + this.searchCriteria + "'' as SearchVal, cal.typeOfList,cal.status,cal.lastaccesed,cal.userid AS userid,user.username AS OWNER,lastaccessuser.username AS LastUserAccessed, assigneduser.username AS Assignedto,(IF(ISNULL(contactids), 0,IF(contactids='''',0,(CHAR_LENGTH(contactids) - CHAR_LENGTH(REPLACE(contactids, '','', '''')) + 1)))+ IF(ISNULL(completedcontactids), 0, IF(completedcontactids = '''', 0, (CHAR_LENGTH(completedcontactids) - CHAR_LENGTH(REPLACE(completedcontactids, '','', '''')) + 1)))" +
        "+ IF(ISNULL(deletedcontactids), 0, IF(deletedcontactids = '''', 0, (CHAR_LENGTH(deletedcontactids) -" +
        "CHAR_LENGTH(REPLACE(deletedcontactids, '', '', '''')) + 1)))) AS total, IF(ISNULL(completedcontactids), 0, IF(completedcontactids = '''', 0, (CHAR_LENGTH(completedcontactids) - CHAR_LENGTH(REPLACE(completedcontactids, '', '', '''')) + 1)))  AS completedcalltotal, IF(ISNULL(deletedcontactids), 0, IF(deletedcontactids = '''', 0, (CHAR_LENGTH(deletedcontactids) - CHAR_LENGTH(REPLACE(deletedcontactids, '', '', '''')) + 1))) AS deletedcontactcalltotal,true as islimitedSearch from " + tablename +
        "  as cal JOIN tbluser AS USER ON cal.userid = user.id LEFT JOIN tbluser AS lastaccessuser ON cal.lastaccesseduserid = lastaccessuser.id LEFT JOIN tbluser AS assigneduser ON cal.assignedto = assigneduser.id";
      var andcondition = false;
      for (let j = 0; j < PreviousSelectedColumns.length; j++) {
        if (PreviousSelectedColumns[j].split(',')[0] == 'calllistname') {
          if (PreviousSelectedColumns[j].split(':')[1].indexOf('"') >= 0) {
            if (!prandcondition) {
              whereQry += " and cal.calllistname REGEXP '(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)' ";
              prandcondition = true;
            } else {
              whereQry += " or cal.calllistname REGEXP '(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)' ";
            }
          }
          else {
            if (!prandcondition) {
              whereQry += " and cal.calllistname like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
              prandcondition = true;
            } else {
              whereQry += " or cal.calllistname like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
            }
          }
        }
        else if (PreviousSelectedColumns[j].split(',')[0] == 'Assignedto') {
          if (!prandcondition) {
            whereQry += " and assigneduser.username like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
            prandcondition = true;
          } else {
            whereQry += " or assigneduser.username like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
          }
        }
        else {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            if (!prandcondition) {
              whereQry += " and cal." + PreviousSelectedColumns[j].split(',')[0] + " REGEXP '(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)' ";
              prandcondition = true;
            } else {
              whereQry += " or cal." + PreviousSelectedColumns[j].split(',')[0] + " REGEXP '(^|[[:space:]])" + PreviousSelectedColumns[j].split(':')[1] + "([[:space:]]|$)' ";
            }
          }
          else {
            if (!prandcondition) {
              //whereQry += " and cal." + PreviousSelectedColumns[j].split(',')[0] + " like ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
              whereQry += " and CONCAT_WS('' '',cal." + PreviousSelectedColumns[j].split(':')[0].replace(",", ",cal.") + ") LIKE ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
              prandcondition = true;
            } else {
              whereQry += " or CONCAT_WS('' '',cal." + PreviousSelectedColumns[j].split(':')[0].replace(",", ",cal.") + ") LIKE ''%" + PreviousSelectedColumns[j].split(':')[1] + "%''";
            }
          }
        }
      }
      for (let i = 0; i < selectedClumns.length; i++) {
        if (selectedClumns[i] == 'calllistname') {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            if (!andcondition) {
              whereQry += " and cal.calllistname REGEXP '(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)' ";
              andcondition = true;
            } else {
              whereQry += " or cal.calllistname REGEXP '(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)' ";
            }
          }
          else {
            if (!andcondition) {
              whereQry += " and cal.calllistname like ''%" + $('#searchContact').val() + "%''";
              andcondition = true;
            } else {
              whereQry += " or cal.calllistname like ''%" + $('#searchContact').val() + "%''";
            }
          }
        }
        else if (selectedClumns[i] == 'Assignedto') {
          if (!andcondition) {
            whereQry += " and assigneduser.username like ''%" + $('#searchContact').val() + "%''";
            andcondition = true;
          } else {
            whereQry += " or assigneduser.username like ''%" + $('#searchContact').val() + "%''";
          }
        }
        else {
          if ($('#searchContact').val().indexOf('"') >= 0) {
            if (!andcondition) {
              whereQry += " and cal." + selectedClumns[i] + " REGEXP '(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)' ";
            } else {
              whereQry += " or cal." + selectedClumns[i] + " REGEXP '(^|[[:space:]])" + $('#searchContact').val() + "([[:space:]]|$)' ";
            }
          }
          else {
            if (!andcondition) {
              whereQry += " and cal." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
            } else {
              whereQry += " or cal." + selectedClumns[i] + " like ''%" + $('#searchContact').val() + "%''";
            }
          }
        }
      }
    }

    searchQry += Joins + Where + whereQry + "'";
    var filterdata = "";


    if (PreviousSelectedColumns.length > 0 && selectedClumns.length == 1) {
      filterdata += PreviousSelectedColumns.join("|") + "|" + selectedClumns + ":" + $('#searchContact').val();
    }
    else if (PreviousSelectedColumns.length > 0 && selectedClumns.length == 0) {
      filterdata += PreviousSelectedColumns.join("|") + "";
    }
    else if (PreviousSelectedColumns.length > 0 && selectedClumns.length > 1) {
      filterdata += PreviousSelectedColumns.join("|") + "|" + selectedClumns + ":" + $('#searchContact').val();

    }
    else if (PreviousSelectedColumns.length == 0 && selectedClumns.length > 1) {
      if ($('#searchContact').val().includes(' / ')) {
        var searchvaluesarray = $('#searchContact').val().split(' / ');
        for (let i = 0; i < searchvaluesarray.length; i++) {
          filterdata += selectedClumns + ":" + searchvaluesarray[i];
          if ((i + 1) < searchvaluesarray.length) {
            filterdata += "|";
          }
        }
      }
      else {
        filterdata = selectedClumns + ":" + $('#searchContact').val();
      }


    }
    else {
      if (selectedClumns.length > 0 && selectedClumns.length == 1) {
        if ($('#searchContact').val().includes(' / ')) {
          var searchvaluesarray = $('#searchContact').val().split(' / ');
          //searchvaluesarray.forEach(element => {
          for (let i = 0; i < searchvaluesarray.length; i++) {
            filterdata += selectedClumns + ":" + searchvaluesarray[i];
            if ((i + 1) < searchvaluesarray.length) {
              filterdata += "|";
            }
          }

          //});
        } else {
          filterdata += selectedClumns + ":" + $('#searchContact').val();
        }

      }
    }
    
  }

  onSearchChange(searchValue: string) {
    if (searchValue == null) { searchValue = $('#searchContact').val(); }
    this.searchCriteria += $('#searchContact').val() + " / ";
    var selectcolums = [];
    var PreviousSelectedColumns = [];
    this.SearchSelectedItems.forEach(selitem => {
      selectcolums.push(selitem.id)
    })
    this.term = searchValue;
    var url = window.location.href;
    this.maincontent == false;
    if (this.selectedtabid === 'contact') {
      this.globalMultiSelect = true;
      if (searchValue != "") {
        if (document.getElementById('contactMainMenu').className == 'activeButton') {
          if (searchValue == "delete") {

            if (this.clearSearchConfirm) {
              this.searchstring = "";
              this.searchCriteria = "";
              this.deletSearchQuery('contacts');
            } else {
              return false;
            }
            this.searchstring = "";
            this.searchCriteria = "";
            this.deletSearchQuery('contacts');
          }
          else {
            localStorage.setItem('contactSearchCriteria', this.searchCriteria);
            this.advancedSearchService.searchdata('contacts', this.currentuser.id).then(result => { 

              if (result != null && result != undefined) {
                var str = $('#Search').val();
                if (/|/.test(result.filtercolumnsdata) == true) {
                  var columnarray = result.filtercolumnsdata.split('|');

                  for (let k = 0; k < columnarray.length; k++) {
                    PreviousSelectedColumns.push(columnarray[k]);
                  }
                }
                else {
                  PreviousSelectedColumns.push(result.filtercolumnsdata);
                }
              }
              this.saveSearchQuery('contacts', selectcolums, PreviousSelectedColumns);

            });
          }
        }
        else if (document.getElementById('AccountsMainMenu').className == 'activeButton') {
          if (searchValue == "delete") {

            var isConfirmed = confirm("Account Search Fields was cleared, do you want to continue?? click 'Ok'");
            if (isConfirmed) {
              this.searchstring = "";
              this.searchCriteria = "";
              this.deletSearchQuery('ContactsAccount');
            } else {
              return false;
            }
          }
          else {
            this.advancedSearchService.searchdata('ContactsAccount', this.currentuser.id).then(result => {


              if (result != null) {
                var str = $('#Search').val();
                if (/|/.test(result.filtercolumnsdata) == true) {
                  var columnarray = result.filtercolumnsdata.split('|');

                  for (let k = 0; k < columnarray.length; k++) {
                    if (/,/.test(columnarray[k]) == true) {
                      PreviousSelectedColumns = columnarray[k].split(',');
                    }
                    else {
                      PreviousSelectedColumns.push(columnarray[k]);
                    }
                  }
                }
                else {
                  if (/,/.test(result.filtercolumnsdata) == true) {
                    PreviousSelectedColumns = result.filtercolumnsdata.split(',');
                  }
                  else {
                    PreviousSelectedColumns.push(result.filtercolumnsdata);
                  }
                }
              }

              //this.router.navigateByUrl(`/application/contacts/contacts/${searchValue}`);
              this.saveSearchQuery('ContactsAccount', selectcolums, PreviousSelectedColumns);

            });
            //this.saveSearchQuery('ContactsAccount', selectcolums, PreviousSelectedColumns);

          }

        }
       
        else if (document.getElementById('quickview_button').className == 'subButtons activeButton') {
          if (searchValue == "delete") {
            var isConfirmed = confirm("QuickView Search Fields was cleared, do you want to continue?? click 'Ok'");
            if (isConfirmed) {
              this.searchstring = "";
              this.searchCriteria = "";
              this.deletSearchQuery('QuickView');
            } else {
              return false;
            }

          }
          else {
            this.saveSearchQuery('QuickView', selectcolums, PreviousSelectedColumns);
          }
        }

      }
    }
    else if (this.selectedtabid === 'Accounts') {
      this.globalMultiSelect = true;
      if (searchValue != "") {
        if (document.getElementById('AccountsMainMenu').className == 'activeButton') {
          if (searchValue == "delete") {
            if (this.clearSearchConfirm) {
              this.searchstring = "";
              this.searchCriteria = "";
              this.deletSearchQuery('Accounts');
            } else {
              return false;
            }
            this.searchstring = "";
            this.searchCriteria = "";
            this.deletSearchQuery('Accounts');
          }
          else {
            localStorage.setItem('AccountSearchCriteria', this.searchCriteria);
            this.advancedSearchService.searchdata('Accounts', this.currentuser.id).then(result => {

              if (result != null && result != undefined) {
                var str = $('#Search').val();
                if (/|/.test(result.filtercolumnsdata) == true) {
                  var columnarray = result.filtercolumnsdata.split('|');

                  for (let k = 0; k < columnarray.length; k++) {
                    PreviousSelectedColumns.push(columnarray[k]);
                  }
                }
                else {
                  PreviousSelectedColumns.push(result.filtercolumnsdata);
                }
              }
              //this.router.navigateByUrl(`/application/contacts/contacts/${searchValue}`);
              this.saveSearchQuery('Accounts', selectcolums, PreviousSelectedColumns);

            });
          }
        }

      }
    }
    else if (this.selectedtabid === 'customers') {
      
       
      this.globalMultiSelect = true;
      if (searchValue != "") {
        if (document.getElementById('customersMainMenu').className == 'activeButton') {
          if (searchValue == "delete") {
            if (this.clearSearchConfirm) {
              this.searchstring = "";
              this.searchCriteria = "";
              this.deletSearchQuery('Customers');
            } else {
              return false;
            }
            this.searchstring = "";
            this.searchCriteria = "";
            this.deletSearchQuery('Customers');
          }
          else {
            localStorage.setItem('customersSearchCriteria', this.searchCriteria);
            this.advancedSearchService.searchdata('Customers', this.currentuser.id).then(result => {

              if (result != null && result != undefined) {
                var str = $('#Search').val();
                if (/|/.test(result.filtercolumnsdata) == true) {
                  var columnarray = result.filtercolumnsdata.split('|');

                  for (let k = 0; k < columnarray.length; k++) {
                    PreviousSelectedColumns.push(columnarray[k]);
                  }
                }
                else {
                  PreviousSelectedColumns.push(result.filtercolumnsdata);
                }
              }
               
              //this.router.navigateByUrl(`/application/contacts/contacts/${searchValue}`);
              if (localStorage.getItem("CustomerType") == 'account') {
                this.saveSearchQuery('CustomerAccount', selectcolums, PreviousSelectedColumns);
              } else {
                this.saveSearchQuery('customercontacts', selectcolums, PreviousSelectedColumns);
              }
              

            });
          }
        }

      }


    }
    else if (this.selectedtabid === 'leads') {
      this.closedLeadVal == null ? this.closedLeadVal = 'Open' : this.closedLeadVal
      if (searchValue == "delete") {
        if (this.clearSearchConfirm) {
          this.searchstring = "";
          this.searchCriteria = "";
          this.deletSearchQuery(this.closedLeadVal + 'Leads');
        } else {
          return false;
        }
        let selfobj = this;
        $(setTimeout(function () {
          selfobj.router.navigateByUrl(`/application/leadsdashboard/0/0/null`);
        }, 200));
      }
      else {
        if (this.selectedsubbutton == 'Open') {
          localStorage.setItem('OpenLeadsSearchCriteria', this.searchCriteria);
        } else if (this.selectedsubbutton == 'Closed') {
          localStorage.setItem('ClosedLeadsSearchCriteria', this.searchCriteria);
        } else if (this.selectedsubbutton == 'All') {
          localStorage.setItem('AllLeadsSearchCriteria', this.searchCriteria);
        }

        this.advancedSearchService.searchdata(this.closedLeadVal, this.currentuser.id).then(result => {

          if (result != null && result != undefined) {
            var str = $('#Search').val();
            if (/|/.test(result.filtercolumnsdata) == true) {
              var columnarray = result.filtercolumnsdata.split('|');

              for (let k = 0; k < columnarray.length; k++) {
                PreviousSelectedColumns.push(columnarray[k]);
              }
            }
            else {
              PreviousSelectedColumns.push(result.filtercolumnsdata);
            }
          }
          this.saveSearchQuery(this.closedLeadVal + 'Leads', selectcolums, PreviousSelectedColumns);
        });


      }
    }
    else if (this.selectedtabid === 'opportunities') {

      (this.closedOpportunityVal == null) ? this.closedOpportunityVal = 'Open' : this.closedOpportunityVal
      if (searchValue == "delete") {
        if (this.clearSearchConfirm) {
          this.searchstring = "";
          this.searchCriteria = "";
          this.deletSearchQuery(this.closedOpportunityVal + 'Opportunity');
        } else {
          return false;
        }

        let selfobj = this;
        $(setTimeout(function () {
          selfobj.router.navigateByUrl(`/application/opportunitiesdashboard/0/0/null/null`);
        }, 200));
      }
      else {
        if (this.closedOpportunityVal == 'Open') {
          localStorage.setItem('OpenOppSearchCriteria', this.searchCriteria);
        } else if (this.closedOpportunityVal == 'Closed') {
          localStorage.setItem('ClosedOppSearchCriteria', this.searchCriteria);
        } else if (this.closedOpportunityVal == 'All') {
          localStorage.setItem('AllOppSearchCriteria', this.searchCriteria);
        }

        this.advancedSearchService.searchdata(this.closedOpportunityVal + 'Opportunity', this.currentuser.id).then(result => {

          if (result != null && result != undefined) {
            var str = $('#Search').val();
            if (/|/.test(result.filtercolumnsdata) == true) {
              var columnarray = result.filtercolumnsdata.split('|');

              for (let k = 0; k < columnarray.length; k++) {
                PreviousSelectedColumns.push(columnarray[k]);
              }
            }
            else {
              PreviousSelectedColumns.push(result.filtercolumnsdata);
            }
          }
          this.saveSearchQuery(this.closedOpportunityVal + 'Opportunity', selectcolums, PreviousSelectedColumns);
        });
      }
    }
    else if (this.selectedtabid === 'task lists') {
      (this.TaskDataVal == null) ? this.TaskDataVal = 'Open Tasks' : this.TaskDataVal
      if (searchValue == "delete") {
        if (this.clearSearchConfirm) {
          this.searchstring = "";
          this.searchCriteria = "";
          this.deletSearchQuery(this.TaskDataVal );
        } else {
          return false;
        }

      }
      else {
        if (this.Searchtabname == 'OpenTaskHistory') {
          localStorage.setItem('OpenTaskSearchCriteria', this.searchCriteria);
        } else if (this.Searchtabname == 'ClosedTaskHistory') {
          localStorage.setItem('ClosedTasksSearchCriteria', this.searchCriteria);
        } else if (this.Searchtabname == 'AllTaskHistory') {
          localStorage.setItem('AllTasksSearchCriteria', this.searchCriteria);
        }
        this.advancedSearchService.searchdata(this.TaskDataVal, this.currentuser.id).then(result => {

          if (result != null && result != undefined) {
            var str = $('#Search').val();
            if (/|/.test(result.filtercolumnsdata) == true) {
              var columnarray = result.filtercolumnsdata.split('|');

              for (let k = 0; k < columnarray.length; k++) {
                PreviousSelectedColumns.push(columnarray[k]);
              }
            }
            else {
              PreviousSelectedColumns.push(result.filtercolumnsdata);
            }
          }
          this.saveSearchQuery(this.TaskDataVal , selectcolums, PreviousSelectedColumns);
        });
      }
    }
    else if (this.selectedtabid === 'TaskCalander' || this.selectedtabid === 'Calendar') {
      if (searchValue == "delete") {
        if (this.clearSearchConfirm) {
          this.searchstring = "";
          this.searchCriteria = "";
          this.deletSearchQuery(this.calandarType );
        } else {
          return false;
        }
      }
      else {
        this.saveSearchQuery(this.calandarType , selectcolums, PreviousSelectedColumns);
      }
    }
    else if (this.selectedtabid === 'reports') {
      if (searchValue == "delete") {
        if (this.clearSearchConfirm) {
          this.searchstring = "";
          this.searchCriteria = "";
          this.deletSearchQuery('Reports');
        } else {
          return false;
        }

        let selfobj = this;
        $(setTimeout(function () {
          selfobj.router.navigateByUrl(`/application/reports/delete`);
        }, 100));
      }
      else {
        this.saveSearchQuery('Reports', selectcolums, PreviousSelectedColumns);
        let selfobj = this;
        $(setTimeout(function () {
          selfobj.router.navigateByUrl(`/application/reports/${searchValue}`);
        }, 100));

      }
    }
    else if (this.selectedtabid === 'Proposals') {
      if (searchValue == "delete") {
        if (this.clearSearchConfirm) {
          this.searchstring = "";
          this.searchCriteria = "";
          this.deletSearchQuery('Proposals');
        } else {
          return false;
        }

      }
      else {
        localStorage.setItem('ProposalSearchCriteria', this.searchCriteria);
        this.advancedSearchService.searchdata('Proposals', this.currentuser.id).then(result => {

          if (result != null && result != undefined) {
            var str = $('#Search').val();
            if (/|/.test(result.filtercolumnsdata) == true) {
              var columnarray = result.filtercolumnsdata.split('|');

              for (let k = 0; k < columnarray.length; k++) {
                PreviousSelectedColumns.push(columnarray[k]);
              }
            }
            else {
              PreviousSelectedColumns.push(result.filtercolumnsdata);
            }
          }
          this.saveSearchQuery('Proposals', selectcolums, PreviousSelectedColumns);
        });

      }
    }
    else if (this.selectedtabid === 'tickets') {
      if (searchValue == "delete") {
        if (this.clearSearchConfirm) {
          this.searchstring = "";
          this.searchCriteria = "";
          this.deletSearchQuery('tickets');
        } else {
          return false;
        }
      }
      else {
        //this.saveSearchQuery('tickets', selectcolums, PreviousSelectedColumns);
        localStorage.setItem('tickets', this.searchCriteria);
        this.advancedSearchService.searchdata('tickets', this.currentuser.id).then(result => {

          if (result != null && result != undefined) {
            var str = $('#Search').val();
            if (/|/.test(result.filtercolumnsdata) == true) {
              var columnarray = result.filtercolumnsdata.split('|');

              for (let k = 0; k < columnarray.length; k++) {
                PreviousSelectedColumns.push(columnarray[k]);
              }
            }
            else {
              PreviousSelectedColumns.push(result.filtercolumnsdata);
            }
          }
          this.saveSearchQuery('tickets', selectcolums, PreviousSelectedColumns);
        });
      }
    }
    else if (this.selectedtabid === 'Rhx') {
      if (searchValue == "delete") {
        var isConfirmed = confirm("Reports Search Fields was cleared, do you want to continue?? click 'Ok'");
        if (isConfirmed) {
          this.searchstring = "";
          this.searchCriteria = "";
          this.deletSearchQuery('Rhx');
        } else {
          return false;
        }

        //let selfobj = this;
        //$(setTimeout(function () {
        //  selfobj.router.navigateByUrl(`/application/reports/delete`);
        //}, 100));
      }
      else {
        this.saveSearchQuery('Rhx', selectcolums, PreviousSelectedColumns);
        //let selfobj = this;
        //$(setTimeout(function () {
        //  selfobj.router.navigateByUrl(`/application/reports/${searchValue}`);
        //}, 100));

      }
    }
    else if (this.selectedtabid === 'Call Lists') {
      if (searchValue == "delete") {
        if (this.clearSearchConfirm) {
              this.searchstring = "";
              this.searchCriteria = "";
              this.deletSearchQuery('Calllists');
            } else {
              return false;
            }

          }
          else {
            this.advancedSearchService.searchdata('Calllists', this.currentuser.id).then(result => {
              if (result != null) {
                var str = $('#Search').val();
                if (/|/.test(result.filtercolumnsdata) == true) {
                  var columnarray = result.filtercolumnsdata.split('|');

                  for (let k = 0; k < columnarray.length; k++) {
                    PreviousSelectedColumns.push(columnarray[k]);
                  }
                }
                else {
                  PreviousSelectedColumns.push(result.filtercolumnsdata);
                }
              }
              this.saveSearchQuery('Calllists', selectcolums, PreviousSelectedColumns);

            });
            // this.saveSearchQuery('Calllist', selectcolums, PreviousSelectedColumns);
          }
    }
    else {
      this.initializeAgGrid();
      this.hideGlobalSearchtable = false;
      if (searchValue == "delete") {
        var isConfirmed = confirm("Global Search Fields was cleared, do you want to continue?? click 'Ok'");
        if (isConfirmed) {
          this.searchCriteria = "";
          this.searchCriteriadata = [];
          this.searchstring = "";
          this.deletSearchQuery('Global');
        } else {
          return false;
        }

      }
      else {
        this.term = searchValue;
        this.saveSearchQuery('Global', selectcolums, PreviousSelectedColumns);
      }

    }

  }

  globalSearch() {
    var url = window.location.href;
    if (url.indexOf('contacts') < 0) {
      $(setTimeout(function () {
        var appendHtml = '<div style="padding-top: 45px"><label id="lblDeActive" style="transform: rotate(90deg)"></label></div>';

        $('#lblDeActive').remove();

      }, 500));
    }
    var userids = $("#userids").val();
    userids = (userids != undefined && userids != null && userids != "") ? userids : this.currentuser.id;
    this.Getcontactorcustomerinfo(userids);
  }

  Getcontactorcustomerinfo(userids) {
    if (this.term != null && this.term != undefined && this.term != "") {
      this.opprtunityservice.GetAllContactsAndCustomerBySearch(this.term, userids, this.startDateVal, this.endDateVal, '457', true).then((data) => {
        this.tableData = data;
        this.totalglobalsearchcount = this.tableData.length;
        if (this.totalglobalsearchcount > 0) { this.isCustomer = true, this.isFormerCustomer = true } else { this.isCustomer = false, this.isFormerCustomer = false }

      });
      if (this.tableData[0].islimitedSearch == true) {
        
        this.isSearchresult == true;
        $('#searchContact').val(this.tableData[0].SearchVal);
        this.searchstring = this.tableData[0].SearchVal;
        this.limitedSearch = true;
      } else {
        
        this.isSearchresult == true;
        $('#searchContact').val("");
        this.searchstring = "";
        this.limitedSearch = false;
      }
      this.createColumnDefs(this.colSelections, this.searchstring);

      //this.term = '';
    }
  }
 

  onKeyPress(event: any) {
    this.term = event.target.value;
  };
  callListSavePopUpCss() {
      setTimeout(function () {
        $('#Iscalllistdialogshow .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');      }, 4)
    
  }

  ticketCreatePopUpCss() {
    setTimeout(function () {
      $('#IsCreateTicketshow .dialog').attr('style', 'width: 29%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4)

  }
  activateMainButtons(id) {  
    if (id != 'Create Task' && id != 'Create Tickets' && id != 'Create Text' && id != 'Create eMail')
    this.displayIcons = false;
    if (id == 'QuickView' || id == 'Create Call List' || id == 'Call Lists' || id == 'Campaigns') {
     
      this.displayIcons = true;
    }
    this.selectedmainbutton = id;
    var url = window.location.href;
    if (id == "Create Call List") {
      this.calllisturl = url;

    } else {
      if (this.calllisturl != "" && this.calllisturl != url) {
        if (this.Iscalllisterrorshow) {
          this.Iscalllistdialogshow = true;
          this.callListSavePopUpCss();
          return false;
        }
      }
    }

    if (id == "Create Tickets") {
      this.pageViewed = 'CreateTicket';
      this.ticketcreateurl = url;
    } else {
      if (this.ticketcreateurl != "" && this.ticketcreateurl != url) {
        this.ticketCreatePopUpCss();
        return false;
      }
    }
    this.AboutUsPage = false;
    this.startDateVal = new Date().getFullYear() + "-01-01";
    this.endDateVal = new Date().getFullYear() + "-12-31";;
    this.startDatePlaceHolder = 'Start:01-01-' + (new Date().getFullYear() - 1).toString();
    this.endDatePlaceHolder = 'End:12-31-' + new Date().getFullYear().toString();
    this.startVal = this.startDateVal;
    this.endVal = this.endDateVal;
    this.searchCriteriadata = [];
    this.googlemapshow = false;
    this.isDateSelected = false;
    if (id !== 'Map It') {
      this.ShowTransferAccounts = false;
      this.sideMenuDisable('Enable');
    }
    
    this.totalglobalsearchcount = 0;
    this.SearchNotAvailable = false;
    this.Search(false);
    //this.QuickSearchDiv = false;
    localStorage.removeItem('RhxContactVal');
    localStorage.removeItem('RhxAccountVal');
    localStorage.removeItem('TaskSelected');
    this.searchCriteria = "";
    if (!this.viewpermission) {
      this.isAccessDenied = true;
      this.dialogsize();
    }
    else {
      var classOfId = $('#' + id + "MainMenu").attr('class');
      if (classOfId == '') {
        this.CollapseSelectedMenu(id.replace(" ", ""));
      }
      else {
        let str = id;
                if (this.userprevilages != undefined) {
          var button = document.getElementById(id + 'MainMenu');
          var buttonname = (button == null) ? id : (button.title);
          var thisTab = this.userprevilages.filter(p => p.resource == buttonname || p.resource.toLowerCase() == buttonname)[0];
          if (thisTab == undefined || (thisTab !== undefined && thisTab.canview == false)) {
            this.toastr.warning("You1 Do Not have Access to " + buttonname + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied", { timeOut: 1500 });

            return false;

          } else {
            if (id == 'Advanced Search') {
              if (str == 'imported') {
                this.activateSubButtons('imported');
              } else {
                $(".mainCheckMark").removeClass("activeColor");
                $(".coustomButton").removeClass("activeButton");
                $(".coustomButton").removeClass("mainactiveButton");
                this.router.navigateByUrl(`/application`);
              }
            }
            else if (id == 'saletool') {
              this.showsaleTool = true;
            }
            else {
              this.showsaleTool = false;
            }
            var email = $('#emailIds').val() == '' ? 0 : $('#emailIds').val();
            this.Email = email;
            if (id != 'QuickSearch' && id != "Create Proposal") {
              this.selectedtabid = id;
            }
            
            this.taskselectedtype = $('#tasklistselectedtyle').val();
            $(".subButtons").show();
            this.CommonTabs = true;
            
            if ($.trim($("#test").val()) != '') {
              if ($("#test").val() == "Proposals") {
                $("#test").val('');
                return false;
              } else {
             
                var yes = confirm("You have not completed adding the" + $("#test").val() + "information, click OK to exit (the note will not be saved) or click Cancel and continue adding information in the" + $("#test").val() + ".");
                if (yes) {
                  $("#test").val('');
                } else {
                  document.getElementById('LeadsTabs').style.display = 'block';
                  return false;
                }
              }
             

            }
            this.hideGlobalSearchtable = true;
            this.globalMultiSelect = true;
            if (id != 'Marketing' && id != 'Create Project'&& id != 'Create Task' && id != 'Map It' && id != 'Recent' && id !== 'Create Text' && id !== 'Create eMail') {
              let thats = this;
              $(setTimeout(function () {
                thats.ExpandSelectedMenu(id.replace(" ", ""));
              }, 100))
            }
            $(".checkMark").removeClass("activeColor");
            $(".subButtons").removeClass("activeButton");

            if (id == "leads" || id == "task lists" || id == "Task Lists") {
              $("#a-z").addClass("activeColor");
              $("#a-z_button").addClass("activeButton");
              this.pageViewed = '';
            }
            $(setTimeout(function () {
              $("#" + id + "_span").addClass("activeColor");
            }, 100));
            if (id == 'leads') {
               var url = window.location.href;    
              if (this.calllisturl != "" && this.calllisturl != url) {
                if (this.Iscalllisterrorshow) {
                  this.Iscalllistdialogshow = true;
                  this.callListSavePopUpCss();
                  return false;
                }
              }
              this.Searchtabname = 'Open Leads';
              this.closedLeadVal = "Open";
              var url = window.location.href;

              if (url.indexOf('leadsdashboard') > -1) {
                let selfobj = this;
                $(setTimeout(function () {
                  selfobj.ClosedLeads('Open', selfobj.currentuser.id, 'Open Leads'); 
                  selfobj.router.navigateByUrl(`/application/leadsdashboard/0/0/null`);
                }, 100));
                selfobj.router.navigateByUrl(`/application`);
              }
              else {
                this.ClosedLeads('Open', this.currentuser.id, 'Open Leads'); 
                this.router.navigateByUrl(`/application/leadsdashboard/0/0/null`);
              }
            
            }
            else if (id == 'contact') {
              var url = window.location.href;
              if (url.indexOf('contacts') > -1) {
                let selfobj = this;
                $(setTimeout(function () {

                  selfobj.router.navigateByUrl(`/application/contacts/contacts/null`);
                }, 50));
                selfobj.router.navigateByUrl(`/application`);
              }
              else {
                this.router.navigateByUrl(`/application/contacts/contacts/null`);
              }
              
            }
            else if (id == 'Accounts') {
              var url = window.location.href;
              if (url.indexOf('account') > -1) {
                let selfobj = this;
                $(setTimeout(function () {
                  selfobj.router.navigateByUrl(`/application/account/Accounts`);
                }, 50));
                selfobj.router.navigateByUrl(`/application`);
              }
              else {
                this.router.navigateByUrl(`/application/account/Accounts`);
              }             
            }
            else if (id == 'Add Account') {
              this.activateSubButtons(id);
            }
            else if (id == 'customers') {
              localStorage.removeItem('SelectedSubMenu');
              this.Searchtabname = "customercontacts";

              if (localStorage.getItem("CustomerType") == 'contact') {
                 this.router.navigateByUrl(`/application/customercontacts`);
              } else {
                var url = window.location.href;
                if (url.indexOf('account') > -1) {
                  let selfobj = this;
                  $(setTimeout(function () {

                    selfobj.router.navigateByUrl(`/application/account/Customer`);
                  }, 50));
                  selfobj.router.navigateByUrl(`/application`);
                }
                else {
                  this.router.navigateByUrl(`/application/account/Customer`);
                }
              }
              if (this.pageViewed === 'Customer') {
                localStorage.setItem("CustPage", "");
                this.pageViewed = "";
              } else {
                localStorage.setItem("CustPage", "Customer");
                this.pageViewed = "Customer";
              }
            }
            else if (id == 'opportunities') {
              this.Searchtabname = 'OpenOpportunity';
              this.closedOpportunityVal = 'Open';
              localStorage.setItem('SubTabOpportunity', this.Searchtabname);
              localStorage.removeItem('SelectedSubMenu');
              var url = window.location.href;
              if (url.indexOf('opportunitiesdashboard') > -1) {
                let selfobj = this;
                $(setTimeout(function () {
                  selfobj.ClosedOpportunity('Open', selfobj.currentuser.id);
                  selfobj.router.navigateByUrl(`/application/opportunitiesdashboard/0/0/null/null`);
                }, 50));
                selfobj.router.navigateByUrl(`/application`);
              }
              else {
                this.ClosedOpportunity('Open', this.currentuser.id);
                this.router.navigateByUrl(`/application/opportunitiesdashboard/0/0/null/null`);
              }             
            }
            else if (id == 'task lists') {
              this.Searchtabname = 'OpenTaskHistory';
              this.TaskDataVal = 'Open Tasks';
              var url = window.location.href;
              localStorage.removeItem('SelectedSubMenu');
              this.isTaskDataLeadEventRaised = true;
              this.TaskDataUserids = this.currentuser.id;
              var url = window.location.href;
              if (url.indexOf('tasklistdashboard') > -1) {
                let selfobj = this;
                $(setTimeout(function () {
               
                  selfobj.router.navigateByUrl(`/application/tasklistdashboard/null/0/null/null`);
                }, 50));
                selfobj.router.navigateByUrl(`/application`);
              }
              else {
                this.router.navigateByUrl(`/application/tasklistdashboard/null/0/null/null`);
              }
              var ids = ($('#emailIds').val());
              $('#emailIds').val("");
              $('#tasklistselectedtyle').val("");
            }
            else if (id == 'tickets') {
              var ids = $('#emailIds').val() == '' ? 0 : $('#emailIds').val();
              var email = $('#emailIds').val() == '' ? 0 : $('#emailIds').val();
              localStorage.removeItem('SelectedSubMenu');
              this.Searchtabname = "Open Tickets";

              this.TicketData('Open', this.currentuser.id, 'Open Tickets');
              $(".subButtons").removeClass("activeButton");
              $("#OpenTickets_button").addClass("activeButton");
              this.pageViewed = '';
            }
            else if (id == 'Calendar') {
              this.Searchtabname = "TaskCalander";
              localStorage.removeItem('SelectedSubMenu');
              this.SubmenuChange('Calendar', 'TaskCalander', 'Sub');
              this.router.navigateByUrl(`/application/month-calender/null`);
              $(".subButtons").removeClass("activeButton");
              $("#TaskCalander_button").addClass("activeButton");

            }
            else if (id == 'Internal Messages') {
              this.router.navigateByUrl(`/application/messaging/0`);           
              //this.MessagingDialog = true;
              //this.fullPopupcss('#MessagingDialog .dialog');             
            }
            else if (id == 'zendesk') {
              var ids = ($('#emailIds').val());
              this.Searchtabname = "OpenTickets";
              $(".subButtons").removeClass("activeButton");
              $("#OpenTickets_button").addClass("activeButton");
              this.router.navigateByUrl(`/application/zendeskhome/` + ids);

            }
            
            else if (id == 'Sales Forecast') {
              this.ClosedLeads('Forecasting', this.currentuser.id, 'Forecasting');
              this.router.navigateByUrl(`/application/forecasting`);
              this.pageViewed = '';
            }
            else if (id == 'systemConfig') {
              //document.getElementById('commontabs').style.display = 'none';
              this.CommonTabs = false;
             
              this.router.navigateByUrl(`/application/configurationdashboard/420`);
            }
            else if (id == 'dashboards') {            
              let str = localStorage.getItem('dashboardTab');
              if (str == null) {
                str = 'tab1';
              }
              this.router.navigateByUrl(`/application/dashboard/` + str);
              this.SearchSelectedItems = [];
              localStorage.removeItem('SelectedSubMenu');
              $(".subButtons").removeClass("activeButton");
              $("#dashboardsSubMenu").addClass("activeButton");
              this.DashBoarddata(str);
              this.pageViewed = '';             
              this.PopUpMessageType = null;
            }
            else if (id == 'My Documents') {
              this.activateSubButtons('My Documents');
            }
            else if (id == 'My Subscription') {
              this.activateSubButtons('My Subscription');
            }
            else if (id == 'My Profile') {
              //this.router.navigateByUrl(`/application/profile`);
              this.SearchSelectedItems = [];
              this.selectedsubbutton = id;
              this.ShowConfigurationDialog = true;
              this.ShowConfigurationDialogCss(id);
              

            }
            else if (id == 'AccountTransfer') {
              
              this.SearchSelectedItems = [];
              this.selectedsubbutton = '612';
            
            }
            else if (id == 'reports') {
              this.Searchtabname = "StandardReports";

              localStorage.removeItem('SelectedSubMenu');
              this.router.navigateByUrl(`/application/reports/audit`);
              $(".subButtons").removeClass("activeButton");
              $("#StandardReports_button").addClass("activeButton");
              this.pageViewed = '';
            }
            else if (id == 'Campaigns') {          
              this.selectedsubbutton = id;
              this.viewCampaigns('view', this.currentuser.id);
            }
            else if (id == 'AboutSalesXCRM') {
              this.AboutUsPageType = id;
               this.ShowConfigurationDialog = true;
              this.ShowConfigurationDialogCss(id);
              this.ActionClick('AboutSalesXCRM');
              this.activateSubButtons('AboutSalesXCRM');
            }
            else if (id == 'PendedProposal') {
           
              this.selectedsubbutton = id;
            }

            else if (id == 'Contact Us') {
              this.ShowConfigurationDialog = true;
              this.ShowConfigurationDialogCss(id);
              this.AboutUsPageType = 'ContactUs';
              this.ActionClick('ContactUs');
            }
            else if (id == 'ReleaseNotes') {
              this.AboutUsPageType = id;
              this.ActionClick('ReleaseNotes');
            }
            else if (id == 'saletool') {
              //this.showSalestoolSubTabs = true;
              this.router.navigateByUrl(`/application/salestool/ResponseWizard`);
            }
            else if (id == 'Marketing') {
              this.SearchNotAvailable = true;
              this.notSelectedCss("SearchNotAvailable");
             // this.pageViewed = '';

            }
            else if (id == 'Proposals') {
              this.pageViewed = '';
              let selfobj = this;
              $(setTimeout(function () {
                selfobj.activateSubButtons('');
                $(setTimeout(function () {
                  selfobj.ProposalSubMenu('Open Proposals');
                }, 50))
              }, 10));
          
            }
            else if (id == 'Call Lists') {
              this.activateSubButtons('viewcalllist');
            }
            else if (id == 'Create Task') {
              this.pageViewed = "ScheduleTask";
              this.activateSubButtons('schedTask')
            }
            else if (id == 'Create eMail') {
              this.activateSubButtons('email');
            }
            else if (id == 'Create Text') {
              this.activateSubButtons('SMS');
            }
            else if (id == 'Create Opportunity') {
              this.activateSubButtons('Opportunity');
            }
            else if (id == 'Create Tickets') {
              this.activateSubButtons('Tickets');
            }
            else if (id == 'Create Proposal') {
              this.activateSubButtons('Proposals');
              this.ShowConfigurationDialog = false;
            }
            else if (id == 'Create Call List') {
              this.activateSubButtons('calllists');
            }
            else if (id == 'QuickView') {
              this.activateSubButtons('quickview');
            }
            else if (id == 'Map It') {
               
              if (this.pageViewed !== 'MapIt') {
                this.activateSubButtons('MapIt');
                
              } else {
                this.activateSubButtons('UnMapit');
              }
            }
            else if (id == 'QuickSearch') {
               
              this.QuickSearch()
            }
            else if (id == 'Recent') {
              this.ShowRHxMenuDiv(true)
            }
            else if (id == 'SocialMedia') {
              this.SearchNotAvailable = true;
              this.notSelectedCss("SearchNotAvailable");
            }
            else if (id == 'Projects' || id == 'Create Project') {
              if (id == 'Projects') {
               // this.pageViewed = 'CreateProject';
                this.ExpandSelectedMenu('Projects');
                //var type = (this.taskselectedtype == 'Contact') ? this.taskselectedtype : 'Account';
                
                //  this.router.navigateByUrl(`/application/projects/` + this.Email + '/' + type);
                localStorage.removeItem("projecttype");
                this.router.navigateByUrl(`/application/projects/0/Account/0`); // When we click on Projects(Leftside Menu) it should redirect to Projects but not Project View Page
               
              } else {
                var type = (this.taskselectedtype == 'Contact') ? this.taskselectedtype : 'Account';
                if (this.Email && type =="Account") {
                  let array = this.Email.split(',');
                  if (array.length === 1 && email !== undefined && email !== '') {                  
                  
                      this.pageViewed = 'CreateProject';
                      this.ExpandSelectedMenu('Projects');
                      this.router.navigateByUrl(`/application/projects/` + this.Email + '/' + type+'/0');
                    
                  } else if (array.length > 1) {
                    this.isMultipleContactSelected = true;
                    this.notSelectedCss("isMultipleContactSelected");
                  }
                }
                else {
                  this.loaddialog('NotSelected', 'Action Icon', 'An Account or Contact must be selected first', false, false, true, true,0); 
                }
              }

            }
            else {
              this.router.navigateByUrl(`/application`);
              this.SearchSelectedItems = [];
              this.SearchColumnList = [];
            }


            if (id !== 'Recent') {
              localStorage.setItem('Tab', this.selectedtabid);
            }
          }
        }
      }
    }
     
    $('#searchBox').attr('style', 'display:block;padding: 0 ; margin-bottom: 0.3%;');
    if (id !== 'Create Text' && id !== 'Create eMail' && id !== 'Add Account') {
      if (!this.displayIcons) {
        $('#emailIds').val("");
        $('#tasklistselectedtyle').val("");
      }
    }
    
    //this.pageViewed = "";
  
  }
  fullPopupcss(id) {
    $(setTimeout(function () {
      $(id).attr('style', 'width: 100%;padding-bottom:0px;border: none;min-height:67%;overflow: auto;height: 84%;top:11%;left:0.2%;')     
    }, 4));
  }

  resetpwddialog() {
    this.resetpwd = !this.resetpwd;
    $('.dialog').width('50%');
  }

  ExpandSelectedMenu(val) {
  
    $('#dashboardsMainMenu').attr('aria-expanded', 'false');
    $('#dashboardsMainMenu').addClass('collapsed');
    $('#dashboardsMainMenu').removeClass('activeButton');

    $('#QuickSearchMainMenu').attr('aria-expanded', 'false');
    $('#QuickSearchMainMenu').addClass('collapsed');
    $('#QuickSearchMainMenu').removeClass('activeButton');

    $('#RecentMainMenu').attr('aria-expanded', 'false');
    $('#RecentMainMenu').addClass('collapsed');
    $('#RecentMainMenu').removeClass('activeButton');

    $('#contactMainMenu').attr('aria-expanded', 'false');
    $('#contactMainMenu').addClass('collapsed');
    $('#contactMainMenu').removeClass('activeButton');
    $('#contact').removeClass('in');

    $('#AccountsMainMenu').attr('aria-expanded', 'false');
    $('#AccountsMainMenu').addClass('collapsed');
    $('#AccountsMainMenu').removeClass('activeButton');
    $('#Accounts').removeClass('in');

    $('#leadsMainMenu').attr('aria-expanded', 'false');
    $('#leadsMainMenu').addClass('collapsed');
    $('#leadsMainMenu').removeClass('activeButton');
    $('#leads').removeClass('in');

    $('#opportunitiesMainMenu').attr('aria-expanded', 'false');
    $('#opportunitiesMainMenu').addClass('collapsed');
    $('#opportunitiesMainMenu').removeClass('activeButton');
    $('#opportunities').removeClass('in');

    $('#tasklistsMainMenu').attr('aria-expanded', 'false');
    $('#tasklistsMainMenu').addClass('collapsed');
    $('#tasklistsMainMenu').removeClass('activeButton');
    //$('#tasklist').removeClass('in');

    $('#CalendarMainMenu').attr('aria-expanded', 'false');
    $('#CalendarMainMenu').addClass('collapsed');
    $('#CalendarMainMenu').removeClass('activeButton');

    $('#reportsMainMenu').attr('aria-expanded', 'false');
    $('#reportsMainMenu').addClass('collapsed');
    $('#reportsMainMenu').removeClass('activeButton');
    $('#reports').removeClass('in');

    $('#MarketingMainMenu').attr('aria-expanded', 'false');
    $('#MarketingMainMenu').addClass('collapsed');
    $('#contactMainMenu').removeClass('activeButton');
    $('#Marketing').removeClass('in');

    $('#ProjectsMainMenu').attr('aria-expanded', 'false');
    $('#ProjectsMainMenu').addClass('collapsed');
    $('#ProjectsMainMenu').removeClass('activeButton');
    $('#Projects').removeClass('in');

    $('#ticketsMainMenu').attr('aria-expanded', 'false');
    $('#ticketsMainMenu').addClass('collapsed');
    $('#ticketsMainMenu').removeClass('activeButton');
    $('#tickets').removeClass('in');

    $('#customersMainMenu').attr('aria-expanded', 'false');
    $('#customersMainMenu').addClass('collapsed');
    $('#customersMainMenu').removeClass('activeButton');
    $('#customers').removeClass('in');

    $('#MySalesXCRMMainMenu').attr('aria-expanded', 'false');
    $('#MySalesXCRMMainMenu').addClass('collapsed');
    $('#MySalesXCRMMainMenu').removeClass('activeButton');

    $('#RecentHistoryMainMenu').attr('aria-expanded', 'false');
    $('#RecentHistoryMainMenu').addClass('collapsed');
    $('#RecentHistoryMainMenu').removeClass('activeButton');

    $('#MyProfileMainMenu').attr('aria-expanded', 'false');
    $('#MyProfileMainMenu').addClass('collapsed');
    $('#MyProfileMainMenu').removeClass('activeButton');


    $('#SalesToolsMainMenu').attr('aria-expanded', 'false');
    $('#SalesToolsMainMenu').addClass('collapsed');
    $('#SalesToolsMainMenu').removeClass('activeButton');

    $('#internalmessagingMainMenu').attr('aria-expanded', 'false');
    $('#internalmessagingMainMenu').addClass('collapsed');
    $('#internalmessagingMainMenu').removeClass('activeButton');

    $('#CallListsMainMenu').attr('aria-expanded', 'false');
    $('#CallListsMainMenu').addClass('collapsed');
    $('#CallListsMainMenu').removeClass('activeButton');

    $('#CampaignsMainMenu').attr('aria-expanded', 'false');
    $('#CampaignsMainMenu').addClass('collapsed');
    $('#CampaignsMainMenu').removeClass('activeButton');
    //$('#Campaigns').removeClass('in');

    $('#SystemConfigurationMainMenu').attr('aria-expanded', 'false');
    $('#SystemConfigurationMainMenu').addClass('collapsed');
    $('#SystemConfigurationMainMenu').removeClass('activeButton');
    $('#SystemConfiguration').removeClass('in');

    $('#UserConfigurationMainMenu').attr('aria-expanded', 'false');
    $('#UserConfigurationMainMenu').addClass('collapsed');
    $('#UserConfigurationMainMenu').removeClass('activeButton');

    $('#SysregMainMenu').attr('aria-expanded', 'false');
    $('#SysregMainMenu').addClass('collapsed');
    $('#SysregMainMenu').removeClass('activeButton');
    $('#Sysreg').removeClass('in');

    $('#ProposalsMainMenu').attr('aria-expanded', 'false');
    $('#ProposalsMainMenu').addClass('collapsed');
    $('#ProposalsMainMenu').removeClass('activeButton');
    //$('#Proposal').removeClass('in');

    $('#AboutSalesXCRMMainMenu').attr('aria-expanded', 'false');
    $('#AboutSalesXCRMMainMenu').addClass('collapsed');
    $('#AboutSalesXCRMMainMenu').removeClass('activeButton');
    $('#AboutSalesXCRM').removeClass('in');

    $('#Pop-UpMessagingMainMenu').attr('aria-expanded', 'false');
    $('#Pop-UpMessagingMainMenu').addClass('collapsed');
    $('#Pop-UpMessagingMainMenu').removeClass('activeButton');

    $('#SalesForecastMainMenu').attr('aria-expanded', 'false');
    $('#SalesForecastMainMenu').addClass('collapsed');
    $('#SalesForecastMainMenu').removeClass('activeButton');
    //  $('#SalesForeCast').removeClass('in');

    $('#ContactUsMainMenu').attr('aria-expanded', 'false');
    $('#ContactUsMainMenu').addClass('collapsed');
    $('#ContactUsMainMenu').removeClass('activeButton');
    $('#ContactUs').removeClass('in');

    $('#AccountTransferMainMenu').attr('aria-expanded', 'false');
    $('#AccountTransferMainMenu').addClass('collapsed');
    $('#AccountTransferMainMenu').removeClass('activeButton');
    $('#AccountTransfer').removeClass('in');

    $('#ReleaseNotesMainMenu').attr('aria-expanded', 'false');
    $('#ReleaseNotesMainMenu').addClass('collapsed');
    $('#ReleaseNotesMainMenu').removeClass('activeButton');
    $('#ReleaseNotes').removeClass('in');

    $('#PendedProposalMainMenu').attr('aria-expanded', 'false');
    $('#PendedProposalMainMenu').addClass('collapsed');
    $('#PendedProposalMainMenu').removeClass('activeButton');
    $('#PendedProposal').removeClass('in');

    $('#SocialMediaMainMenu').attr('aria-expanded', 'false');
    $('#SocialMediaMainMenu').addClass('collapsed');
    $('#SocialMediaMainMenu').removeClass('activeButton');
    $('#SocialMedia').removeClass('in');


    // $('#' + val+'MainMenu').attr('aria-expanded', 'true');
    $('#' + val + 'MainMenu').removeClass('collapsed');
    $('#' + val + 'MainMenu').addClass('activeButton');
    $('#' + val).addClass('in');
  }

  CollapseSelectedMenu(val) {
    $('#dashboardsMainMenu').attr('aria-expanded', 'false');
    $('#dashboardsMainMenu').addClass('collapsed');
    //$('#dashboard').removeClass('in');

    $('#contactMainMenu').attr('aria-expanded', 'false');
    $('#contactMainMenu').addClass('collapsed');
    $('#contact').removeClass('in');

    $('#AccountsMainMenu').attr('aria-expanded', 'false');
    $('#AccountsMainMenu').addClass('collapsed');
    $('#Accounts').removeClass('in');

    $('#leadsMainMenu').attr('aria-expanded', 'false');
    $('#leadsMainMenu').addClass('collapsed');
    $('#leads').removeClass('in');

    $('#opportunitiesMainMenu').attr('aria-expanded', 'false');
    $('#opportunitiesMainMenu').addClass('collapsed');
    $('#opportunities').removeClass('in');

    $('#tasklistsMainMenu').attr('aria-expanded', 'false');
    $('#tasklistsMainMenu').addClass('collapsed');
    // $('#tasklist').removeClass('in');

    $('#CalendarMainMenu').attr('aria-expanded', 'false');
    $('#CalendarMainMenu').addClass('collapsed');

    $('#reportsMainMenu').attr('aria-expanded', 'false');
    $('#reportsMainMenu').addClass('collapsed');
    $('#reports').removeClass('in');

    $('#MarketingMainMenu').attr('aria-expanded', 'false');
    $('#MarketingMainMenu').addClass('collapsed');
    $('#Marketing').removeClass('in');

    $('#ProjectsMainMenu').attr('aria-expanded', 'false');
    $('#ProjectsMainMenu').addClass('collapsed');
    $('#Projects').removeClass('in');

    $('#ticketsMainMenu').attr('aria-expanded', 'false');
    $('#ticketsMainMenu').addClass('collapsed');
    $('#tickets').removeClass('in');

    $('#customersMainMenu').attr('aria-expanded', 'false');
    $('#customersMainMenu').addClass('collapsed');
    $('#customers').removeClass('in');

    $('#MySalesXCRMMainMenu').attr('aria-expanded', 'false');
    $('#MySalesXCRMMainMenu').addClass('collapsed');
    // $('#MySalesXCRM').removeClass('in');

    $('#RecentHistoryMainMenu').attr('aria-expanded', 'false');
    $('#RecentHistoryMainMenu').addClass('collapsed');

    $('#SalesToolsMainMenu').attr('aria-expanded', 'false');
    $('#SalesToolsMainMenu').addClass('collapsed');
    $('#SalesTools').removeClass('in');

    $('#MyProfileMainMenu').attr('aria-expanded', 'false');
    $('#MyProfileMainMenu').addClass('collapsed');
    $('#MyProfile').removeClass('in');

    $('#internalmessagingMainMenu').attr('aria-expanded', 'false');
    $('#internalmessagingMainMenu').addClass('collapsed');
    $('#internalmessaging').removeClass('in');

    $('#CallListsMainMenu').attr('aria-expanded', 'false');
    $('#CallListsMainMenu').addClass('collapsed');

    $('#CampaignsMainMenu').attr('aria-expanded', 'false');
    $('#CampaignsMainMenu').addClass('collapsed');
    $('#Campaigns').removeClass('in');

    $('#SystemConfigurationMainMenu').attr('aria-expanded', 'false');
    $('#SystemConfigurationMainMenu').addClass('collapsed');

    $('#UserConfigurationMainMenu').attr('aria-expanded', 'false');
    $('#UserConfigurationMainMenu').addClass('collapsed');

    $('#SysregMainMenu').attr('aria-expanded', 'false');
    $('#SysregMainMenu').addClass('collapsed');
    $('#Sysreg').removeClass('in');

    $('#Pop-UpMessagingMainMenu').attr('aria-expanded', 'false');
    $('#Pop-UpMessaginggMainMenu').addClass('collapsed');

    $('#SalesForecastMainMenu').attr('aria-expanded', 'false');
    $('#SalesForecastMainMenu').addClass('collapsed');
    // $('#SalesForeCast').removeClass('in');

    $('#AboutSalesXCRMMainMenu').attr('aria-expanded', 'false');
    $('#AboutSalesXCRMMainMenu').addClass('collapsed');
    $('#AboutSalesXCRM').removeClass('in');

    $('#ContactUsMainMenu').attr('aria-expanded', 'false');
    $('#ContactUsMainMenu').addClass('collapsed');
    $('#ContactUs').removeClass('in');

    $('#ReleaseNotesMainMenu').attr('aria-expanded', 'false');
    $('#ReleaseNotesMainMenu').addClass('collapsed');
    $('#ReleaseNotes').removeClass('in');

    $('#PendedProposalMainMenu').attr('aria-expanded', 'false');
    $('#PendedProposalMainMenu').addClass('collapsed');
    $('#PendedProposal').removeClass('in');

  }

  //DeselectAlltabs() {
  //  this.showContactsSubTabs = false;
  //  this.showResponseWizardSubTabs = false;
  //  this.showOpportunitiesSubTabs = false;
  //  this.showReportSubTabs = false;
  //  this.showTaskSubTabs = false;
  //  this.showZendeskSubTabs = false;
  //  this.showcampaignsTabs = false;
  //  this.showRecentHistorySubTabs = false;
  //  this.showDashboardSubTabs = false;
  //  this.showSalestoolSubTabs = false;
  //  this.showMessagesSubTabs = false;
  //  this.showMySalesXCRMSubTabs = false;
  //  this.showLeadsTabsSubTabs = false;
  //  this.showCalanderSubTabs = false;
  //  this.showSystemConfigSubTabs = false;
  //}
  ResponseWizard(val) {

    this.selectedtabid = "ResponseWizard";
    var button = document.getElementById('ResponseWizard');
    if (this.userprevilages != undefined) {
      var buttonname = (button == null) ? this.selectedtabid : (button.innerText || button.title);
      var thisTab = this.userprevilages.filter(p => p.resource == buttonname)[0];
      if (thisTab === undefined || (thisTab != undefined && thisTab.canview == false)) {
        this.toastr.warning("You Do Not have Access to " + buttonname + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied", { timeOut: 600 });
        return false;
      }
    }
    if (val == true) {
      this.RWdialog();
    }
    else {
      //this.showResponseWizardSubTabs = false;
    }
  }

  clearerrmsg() {
    $('#errmsg').hide();
  }
  loaddfields(selectedtable) {
    this.customerService.getAllfieldsbyselectedtable(selectedtable).then(res => {
      this.fieldlist = res;
    });
  }
  updatepassword() {
    let pas = $('#password').val();
    let con_pass = $('#confirm_password').val();
    if (pas == con_pass) {
      this.users.ResetPassword(this.currentuser.id, pas).then(res => {
        if (res == "success") {
          // this.toastr.setRootViewContainerRef(viewContainerRef);
          this.toastr.success("Successfully Updated Password!", "Password", { timeOut: 600 });
          this.resetpwd = !this.resetpwd;
          $('#password').val('');
          $('#confirm_password').val('');
        }
        else {
          // this.toastr.setRootViewContainerRef(viewContainerRef);
          this.toastr.error("Something went wrong");

        }
      });
    }
    else
      $('#errmsg').show();

  }
  incomingfile(event) {
    this.file = event.target.files[0];
  }
  advancedSearch(event) {
    if (this.userprevilages != undefined) {
      var thisTab = this.userprevilages.filter(p => p.resource == 'Advanced Search')[0];
      if (thisTab == undefined || (thisTab != undefined && thisTab.canview == false)) {
        this.toastr.warning("You Do Not have Access to Advanced Search Tab – Please check with CRM Administrator if Access is Required.", "Access Denied", { timeOut: 1500 });

        return false;

      } else {
        this.showSearchFieldsDialog = false;
        this.selectedsubbutton = 'Advanced Search';
        this.AdvancedSearchResult = false;
        localStorage.setItem('Tab', this.selectedtabid);
        localStorage.setItem('SubTab', this.selectedsubbutton);
        this.activateMainButtons("Advanced Search");
      }
    }
  }


  ResizeTable() {
    this.aggridheight = ($("#globalGriddheight").height(((window.innerHeight) - ($('#appComponentContent').height())) - 100)) + 'px';
  }
  rowDoubleClickAgGrid(rowEvent) {
   
    this.hideGlobalSearchtable = true;
    if (rowEvent.data.type == "Contact")
      this.router.navigateByUrl(`/application/contactsdashboard/${rowEvent.data.id}/${rowEvent.data.permission}`);
    else if (rowEvent.data.type == "Customer" || rowEvent.data.type == "Account")
      this.router.navigateByUrl(`/application/account-contact/${rowEvent.data.permission}/show${rowEvent.data.accountid}`);

    let url: string = window.location.href;
    if (url.indexOf('month-calender') >= 0) {
      
    }

  }
  valuechange(newValue) {

    //mymodel = newValue;
  }
  dateChange(event) {
    this.AssignStartAndEndDate(event);
    this.Getcontactorcustomerinfo($("#userids").val());
  }
  SearchStartdateChange(event) {
    this.SearchstartVal = this.getDateFormatYYYYHMMHDD(event.date);
    this.onSearchChange("");
  }
  SearchEnddateChange(event) {
    this.SearchendVal = this.getDateFormatYYYYHMMHDD(event.date);
    this.onSearchChange("");
  }
  SearchonItemSelect(item: any) {
    //this.tempdata = [];
    var ids = item.id;
    this.finalitems1 += ids + ',';
    this.selectedcolumncount++;
    if (this.SearchSelectedItems.length > 0) {
      this.SearchSelectedItems.forEach(selitem => {
        if ((selitem.id == "datecreated" || selitem.id == "estimatedclosedate" || selitem.id == "datecompleted" || selitem.id == "estcompleteddate" || selitem.id == "DateSched" || selitem.id == "DateComp" || selitem.id == "DateCreated" || selitem.id == "created" || selitem.id == "lastaccesed") && this.SearchSelectedItems.length == 1) {
          this.isDateSelected = true;
        }
        else if (selitem.id == "cell" || selitem.id == "office" || selitem.id == "phone") {
          this.isPhoneMask = true;
        }
        else
          this.isDateSelected = false;

      })
    }

    this.SearchSelectedItemsLengthChanged = true;
    this.tempStoreColumns();
  }
  tempStoreColumns() {
    //localStorage.removeItem('TempTable');
    let newtemp = new TempData();
    newtemp.subtab = this.Searchtabname;
    newtemp.SearchSelectedItemsLengthChanged = this.SearchSelectedItemsLengthChanged;
    newtemp.columns = Array.prototype.map.call(this.SearchSelectedItems, s => s.id + ("-" + s.itemName));
    if (this.tempdata.length != 0) {
      //var length = this.tempdata.length;
      var index
      for (var i = 0; i < this.tempdata.length; i++) {
        if ((this.tempdata)[i].subtab == this.Searchtabname) {
          index = i;
          this.tempdata.splice(index, 1);
        }

      }

    }
    this.tempdata.push(newtemp);
    localStorage.setItem('TempTable', JSON.stringify(this.tempdata));
  }
  SearchOnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    var dfi = this.finalitems1.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems1 = result;
    if (this.SearchSelectedItems.length > 0) {
      this.SearchSelectedItems.forEach(selitem => {
        if ((selitem.id == "datecreated" || selitem.id == "estimatedclosedate" || selitem.id == "datecompleted" || selitem.id == "estcompleteddate" || selitem.id == "DateSched" || selitem.id == "DateComp" || selitem.id == "DateCreated" || selitem.id == "created" || selitem.id == "lastaccesed") && this.SearchSelectedItems.length == 1) {
          this.isDateSelected = true;
        }
        else if (selitem.id == "cell" || selitem.id == "office" || selitem.id == "phone") {
          this.isPhoneMask = true;
        }
        else
          this.isDateSelected = false;
      })
    }
    this.SearchSelectedItemsLengthChanged = true;
    this.tempStoreColumns();
  }
  SearchonSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems1 = "";
    this.finalitems1 = ids;
    this.SearchSelectedItemsLengthChanged = true;
    this.tempStoreColumns();
  }
  SearchonDeSelectAll(items: any) {
    this.finalitems1 = "";
    $("#userids").val('');
    this.SearchSelectedItemsLengthChanged = true;
    this.tempStoreColumns();
  }
  onItemSelect(item: any) {
    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Getcontactorcustomerinfo($("#userids").val());
  }
  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.Getcontactorcustomerinfo($("#userids").val());
    } else {
      this.allContacts = [];
      $("#userids").val('');
    }
  }
  onSelectAll(items) {

    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Getcontactorcustomerinfo($("#userids").val());
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.allContacts = [];
    this.globalGridOptions.api.hideOverlay();
    $("#userids").val('');
  }
  AssignStartAndEndDate(event) {

    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  globalSearchOnfocus() {
    this.globalMultiSelect = true;
    this.inputmask();
  }

  inputmask() {
    this.SearchSelectedItems.forEach(selitem => {
      if (selitem.id == "DOB" && this.SearchSelectedItems.length == 1) {
        this.isSelectedDOB = true;
      }
      else
        this.isSelectedDOB = false;
    })
  }


  ClosedOpportunity(val, userids) {
    this.selectedsubbutton = val;
    var subMenuId = "";
    if (val == 'Open') {
      this.Searchtabname = 'OpenOpportunity';
      subMenuId = 'OpenOpportunity';
    }
    else if (val == 'Closed') {
      this.Searchtabname = 'ClosedOpportunity';
      subMenuId = 'ClosedOpportunity';
    }
    else if (val == 'All') {
      this.Searchtabname = 'AllOpportunity';
      subMenuId = 'AllOpportunity';
    }
    else if (val == 'New') {
      this.Searchtabname = 'NewOpportunity';
      subMenuId = 'AddOpprtunity';
    } else {
      this.Searchtabname = 'OpenOpportunity';
      subMenuId = 'OpenOpportunity';
    }

    localStorage.setItem('SubTabOpportunity', this.Searchtabname);
    var url = window.location.href;
    if (url.indexOf('opportunitiesdashboard') > -1) {    
      this.router.navigateByUrl(`/application/opportunitiesdashboard/0/0/null/null`);
      
    }

    this.isClosedOpportunityEventRaised = true;
    this.closedOpportunityVal = val;
    this.closedOpportunityUserids = userids;
    if (val == 'New') {
    
    }
    else {
      $('#searchBox').attr('style', 'display:block;padding: 0 ; margin-bottom: 0.3%;');
    }
    this.pageViewed = "";
  }
  ClosedLeads(val, userids, buttonname) {
    this.selectedsubbutton = val;
    this.Searchtabname = buttonname;
    $(".subButtons").removeClass("activeButton");
    $(".subButtons").removeClass("limainactiveButton");
    $(".subButtons").removeClass("mybtn");


    if (this.userprevilages != undefined) {
      var submenuID = '';
      if (buttonname == 'Forecasting') {
        submenuID = 'ForecastingButton';

      }
      else {
        submenuID = buttonname
      }

    }
    if (buttonname == 'Forecasting') {
      this.router.navigateByUrl(`/application/forecasting`);
    }
    else {
      this.isClosedLeadEventRaised = true;
      this.closedLeadVal = val;
      this.closedLeadUserids = userids;
    }

    //}

  }
  TaskData(val, userids) {
    this.selectedsubbutton = val;
    var submenuID = '';
    if (val == 'Open') {
      this.Searchtabname = 'OpenTaskHistory';
      submenuID = "Open Tasks";
    }
    else if (val == 'Completed') {
      this.Searchtabname = 'ClosedTaskHistory';
      submenuID = "Completed Tasks";
    }
    else if (val == 'All') {
      this.Searchtabname = 'AllTaskHistory';
      submenuID = "All Tasks";
    }
    else if (val == 'New') {
      this.Searchtabname = 'NewTaskHistory';
      submenuID = "Add Tasks";
    } else {
      this.Searchtabname = 'OpenTaskHistory';
      submenuID = "Open Tasks";
    }
   
    this.isTaskDataLeadEventRaised = true;
    this.TaskDataVal = val;
    this.TaskDataUserids = userids;
    this.router.navigateByUrl(`/application/tasklistdashboard/Customer/0/null/null`);

    if (val == 'New') {
     // $('#searchBox').attr('style', 'display:none');
      // this.ShowSearchTabs('False')
    }
    else {
      $('#searchBox').attr('style', 'display:block;padding: 0 ; margin-bottom: 0.3%;');
      //this.ShowSearchTabs('True')
    }
  }
  DashBoarddata(val) {
    this.router.navigateByUrl('/application/dashboard/' + val);
    $('#' + val + "_button").addClass("activeButton");
  }


  TicketData(val, userids, Button) {

    this.selectedsubbutton = val;
    this.Searchtabname = Button;
    this.isTicketDataEventRaised = true;
    this.TicketDataVal = val;
    this.TicketDataUserids = userids;

    if (val == 'New') {
     
      this.router.navigateByUrl(`/application/tickets/` + this.TicketDataUserids);
    }
    else {
      var url = window.location.href;
      if (url.indexOf('zendeskhome') > -1) {
        let selfobj = this;
        $(setTimeout(function () {

          selfobj.router.navigateByUrl(`/application/zendeskhome/0/` + selfobj.Searchtabname);
        }, 50));
        selfobj.router.navigateByUrl(`/application`);
      }
      else {
        this.router.navigateByUrl(`/application/zendeskhome/0/` + this.Searchtabname);
      }
      
      $('#searchBox').attr('style', 'display:block;padding: 0; margin-bottom: 0.3%;');
    }
    var btn = "";
    switch (Button) {
      case 'Open Tickets': {
        btn = 'OpenTickets'; break;
      }
      case 'Pended Tickets': {
        btn = 'PendedTickets'; break;
      }
      case 'On-Hold Tickets': {
        btn = 'On-HoldTickets'; break;
      }
      case 'Closed Tickets': {
        btn = 'ClosedTickets'; break;
      }
      case 'Deleted Tickets': {
        btn = 'DeletedTickets'; break;
      }
      case 'All Tickets': {
        btn = 'AllTickets'; break;
      }
      case 'Add Tickets': {
        btn = 'AddTickets'; break;
      }
    }

    setTimeout(function () {
      $(".subButtons").removeClass("activeButton");
      $('#' + btn + "_button").addClass("activeButton");
    }, 200);
  }

  ProposalSubMenu(val) {
    this.ProposalType = val;
    //let selfobj = this;
    //setTimeout(function () {
      var buttonID = "";
      if (val == 'All Proposals') {
        buttonID = "AllProposals";
      }
      else if (val == 'Open Proposals') {
        buttonID = "OpenProposals";
      }
      else if (val == 'Closed Proposals') {
        buttonID = "ClosedProposal";
      }
      else if (val == 'AddProposal') {
        buttonID = "AddProposal";
      }
      $(".subButtons").removeClass("activeButton");
      $('#' + buttonID + "_button").addClass("activeButton");

    if (val == 'All Proposals' || val == 'Open Proposals' || val == 'Closed Proposals') {
      var url = window.location.href;
      if (url.indexOf('Proposals') > -1) {
        let selfobj = this;
        $(setTimeout(function () {

          selfobj.router.navigateByUrl(`/application/Proposals/` + val);
        }, 50));
        selfobj.router.navigateByUrl(`/application`);
      }
      else {
        this.router.navigateByUrl(`/application/Proposals/` + val);
      }
      } else {
        
        if (this.taskselectedtype === 'Contact') {
          this.ProposalContactOrAccountType = this.taskselectedtype;
        } else {
          this.ProposalContactOrAccountType = 'Account';
      }
      this.CreateProposalPage = true;     
      }

    //}, 200);
  }
  //CreateProposalPageCss(val) {
  //  if (val == "CreateProposalPage") {
  //    setTimeout(function () {
  //      $('#CreateProposalPage .dialog').attr('style', 'width: 96%;padding: 0px;border: 3px solid #FF5F15;min-height: 63%;overflow: auto;height: 92%;top: 8%;right: 0;left:3%;max-width: 100% !important;');
  //    }, 4)

  //  }

  //  else if (val == "QuickSearchDiv") {
  //    setTimeout(function () {
  //      $('#QuickSearchDiv .dialog').attr('style', 'width: 88%;padding: 0px;min-height: 63%;overflow: auto;height: 86%;top: 10%;right: 0;left: 10%;max-width: 100% !important;border: 4px solid rgb(117, 218, 51);');
  //    }, 4)
  //  }
  //}

  Removesearchfilter(value) {
    if (this.selectedtabid === 'contact') {
      this.globalMultiSelect = true;
      //this.showContactsSubTabs = true;
      if (value != "") {
        if (this.selectedsubbutton === 'Contacts') {
          this.removefilterval('contacts', value);
        }
        else if (this.selectedsubbutton == 'allContactAccounts') {
          this.removefilterval('ContactsAccount', value);

        }
        else if (this.selectedsubbutton == 'imported') {
          this.removefilterval('Calllists', value);

        }
        else if (this.selectedsubbutton == 'viewcalllist') {
          this.removefilterval('QuickView', value);
        }

      }
    }
    else if (this.selectedtabid === 'customers') {
      if (value != "") {
        if (this.selectedsubbutton == 'a-z') {
          this.removefilterval('customercontacts', value);
        } else if (this.selectedsubbutton == 'allaccount') {
          this.removefilterval('CustomerAccount', value);
        }

      }
    }
    else if (this.selectedtabid == "Account") {
      this.removefilterval('Accounts', value);
    }
    else if (this.selectedtabid == "leads") {
      if (this.selectedsubbutton == 'Open') {
        this.removefilterval('OpenLeads', value);
      }

    }
    else if (this.selectedtabid == "task lists") {
      this.removefilterval(this.Searchtabname, value);
    }
    else if (this.selectedtabid == "Proposals") {
      this.removefilterval('Proposals', value);
    }
    else if (this.selectedtabid == "opportunities") {
      if (this.closedOpportunityVal == 'Open') {
        this.removefilterval(this.closedOpportunityVal + 'Opportunity', value);
      } else if (this.closedOpportunityVal == 'Closed') {
        this.removefilterval(this.closedOpportunityVal + 'Opportunity', value);
      } else if (this.closedOpportunityVal == 'All') {
        this.removefilterval(this.closedOpportunityVal + 'Opportunity', value);
      }
    }
  }
  removefilterval(tabName, filterval) {
    var PreviousSelectedColumns = [];
    var SelectedColumns = [];
    this.advancedSearchService.searchdata(tabName, this.currentuser.id).then(result => {

      if (result != null) {
        var columnarray = result.filtercolumnsdata.split('|');
        this.searchCriteriadata.splice(this.searchCriteriadata.indexOf(filterval), 1);
        for (let k = 0; k < columnarray.length; k++) {
          if (filterval != columnarray[k].split(':')[1]) {
            PreviousSelectedColumns.push(columnarray[k]);
          }
        }
      }
      this.searchCriteria = "";
      for (let i = 0; i < this.searchCriteriadata.length; i++) {
        this.searchCriteria += this.searchCriteriadata[i] + " / ";
      }
      this.saveSearchQuery(tabName, SelectedColumns, PreviousSelectedColumns);

    });
  }
  SubmenuChange(Tab, val, menu) {
    this.AboutUsPage = false;
    this.SearchNotAvailable = false;
    if (menu == 'main') {
      this.selectedtabid = Tab;
      var classOfId = $('#' + Tab.replace(" ", "") + "MainMenu").attr('class');
      if (classOfId == '') {
        this.CollapseSelectedMenu(Tab.replace(" ", ""));
      }
      else {
        this.ExpandSelectedMenu(Tab.replace(" ", ""));
      }
    }
    if (this.userprevilages != undefined) {
      var button: any;
      var buttonname: any;
      if (menu == 'main') {
        button = document.getElementById(Tab + 'MainMenu');
        buttonname = (button == null) ? Tab : (button.title);
      } else {
        buttonname = Tab;
      }
      var thisTab = this.userprevilages.filter(p => p.resource == buttonname || p.resource.toLowerCase() == buttonname)[0];
      if (thisTab == undefined || (thisTab != undefined && thisTab.canview == false)) {
        this.toastr.warning("You Do Not have Access to " + buttonname + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied", { timeOut: 1500 });

        return false;

      } else {
        this.selectedsubbutton = val;
        this.Searchtabname = val;
        if (Tab == "Calendar") {
          this.router.navigateByUrl(`/application/month-calender/` + val);
        }
      
        else if (Tab == "Reports") {
          if (val == 'StandardReports') {
            var type = "";
            if (this.PopUpMessageType == 'User Activity(Details)') {
              type = "UserActivityDetails";
            } else if (this.PopUpMessageType == 'User Activity(Summary)') {
              type = "UserActivitySummary";
            } else if (this.PopUpMessageType == "Access(Login/Logout)") {
              type = "admin";
            }  else {
              type = this.PopUpMessageType;
            }
            this.router.navigateByUrl(`/application/reports/` + type);
            $(".subButtons").removeClass("activeButton");
            $("#StandardReports_button").addClass("activeButton");
          }

          else if (val == 'AdminReports') {

            if (this.PopUpMessageType == 'Audit Log(Admin)') {
              type = "AuditLogAdmin";
            } else if (this.PopUpMessageType == 'User Access(Admin)') {
              type = "UserAccessAdmin";
            } else {
              type = this.PopUpMessageType;
            }

            this.router.navigateByUrl(`/application/admin-reports/` + type);
            $(".subButtons").removeClass("activeButton");
            $("#StandardReports_button").addClass("activeButton");
          }
        }
        else if (Tab == 'Sysreg') {
          
          this.router.navigateByUrl(`/application/system-registration`);
        }
        else {
          if (menu == 'Sub') {
            this.ExpandSelectedMenu('SystemConfiguration');
          }
          
        }
        this.pageViewed = '';
        $(setTimeout(function () {
          $(".subButtons").removeClass("activeButton");
          $('#' + val + "_button").addClass("activeButton");
        }, 200));

      }
    }

  }
  //standard3dotMenuUI(val) {
  //}
  standard3dotpartialPage(val) {
    setTimeout(function () {
      $(val).attr('style', 'width:32%;padding-bottom:0px;min-height:75%;overflow:auto;height:92%;left:68%;top:8%;padding:0;');
    }, 4)
  }
  ShowConfigurationDialogCss(Type) {
   
    if (Type == 'AboutSalesXCRM' || Type == 'Contact Us' || Type == 'ReleaseNotes' ) {
      this.standard3dotpartialPage('#ConfigurationDialog .dialog');      
    }  
    else if (Type == 'My Profile' || Type == 'My Subscription') {
      setTimeout(function () {
        $('#ConfigurationDialog .dialog').attr('style', 'width:37%;padding-bottom:0px;min-height:75 %;overflow: auto;height: 84%;left: 62%;top: 10%;padding:0');
      }, 4)
    }
    else {
      this.fullPopupcss('#ConfigurationDialog .dialog');
    }

  }
  AddOpportunity() {
    this.isAddOpportunityEventRaised = true;
  }
  AddTask() {
    this.isAddTaskEventRaised = true;
  }

  AddTicket() {
    this.isAddTicketEventRaised = true;
  }
  viewCampaigns(val, userids) {
    $(".subButtons").removeClass("activeButton");
    $('#' + val + "_button").addClass("activeButton");
    if (val == 'add') {
      $('#searchBox').attr('style', 'display:none');
      //this.ShowSearchTabs('False')
    }
    else {
      $('#searchBox').attr('style', 'display:block;padding: 0; margin-bottom: 0.3%;');
      //this.ShowSearchTabs('True')
    }
    
    this.CampaignId = 0;
  }
  addNewCampaign(val) {
    this.isAddcampaignEventRaised = true;
  }

  openDisplaynotes(params) {
    //  this.notes.notedescription = params.value;
    this.NotesDec = !this.NotesDec;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;
;
    $(setTimeout(function () {
      $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

      if ($('input[name=Notes]').is(':checked')) {
        $('#txtLongNoteDesc').css('height', '81%');
        $('#txtLongNoteDesc').css('padding-top', '0%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
        $('#txtUserFullName').css('height', '14%');
      } else {
        $('#txtLongNoteDesc').css('height', '96%');
        $('#txtLongNoteDesc').css('padding-top', '6%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
      }
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));
  }
  closeDetailsDialog() {
    this.NotesDec = false;
    this.showAdvancedSearchDialog = false;
  }
  ReceiveAddEditAccEvent(msg) {
    if (msg == "ExpandSoftwareLicense" || msg == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(msg);
    }
    else if (msg && msg.split('~')[1] > 0) {
      if (msg.split('~')[2] ==874) {
        var url = window.location.href;
        if (url.indexOf('account') > -1) {
          let selfobj = this;
          $(setTimeout(function () {

            selfobj.router.navigateByUrl(`/application/account/Customer`);
          }, 50));
          selfobj.router.navigateByUrl(`/application`);
        }
        else {
          this.router.navigateByUrl(`/application/account/Customer`);
        }

        this.QuickSearchDiv = false;
        localStorage.setItem('RhxAccountVal', msg.split('~')[1]);
        let selfobj = this;
        setTimeout(function () {
          selfobj.ExpandSelectedMenu('customers');
          $(".subButtons").removeClass("activeButton");
          selfobj.selectedtabid = 'Customer';
        }, 200)
      } else {
        var url = window.location.href;
        if (url.indexOf('account') > -1) {
          let selfobj = this;
          $(setTimeout(function () {

            selfobj.router.navigateByUrl(`/application/account/Accounts`);
          }, 50));
          selfobj.router.navigateByUrl(`/application`);
        }
        else {
          this.router.navigateByUrl(`/application/account/Accounts`);
        }

        this.QuickSearchDiv = false;
        localStorage.setItem('RhxAccountVal', msg.split('~')[1]);
        let selfobj = this;
        setTimeout(function () {
          selfobj.ExpandSelectedMenu('Accounts');
          $(".subButtons").removeClass("activeButton");
          //selfobj.selectedtabid = 'Customer';
        }, 200)
      }
      this.loaddialog('NewAccount', 'ADDING ACCOUNT', 'Account will be Saved', false, false, true, true, 0)
    } else {
      this.loaddialog('NewAccount', 'ADDING ACCOUNT', 'Account will Not be Saved', false, false, true, true, 0)
    }
  
 
  }
  ReceiveEvent(msg) {
    if (msg == "changed") {
      this.QuickSearchEvenraised = true;
    }
    else if (msg == "Advacnedchanged") {
      this.AdvancedSearchEventraised = true;
    }
    else {
      this.showAdvancedSearchDialog = false;
      this.AdvancedSearchResult = false;
      this.activateMainButtons('dashboards');
    }

  }
  CloseInvalidSearchDialog() {
    this.ShowInvalidSearchDialog = !this.ShowInvalidSearchDialog;
  }
  editNote() {
    //if ($("#btnEditNote").text() == "Edit Note") {
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();
  }
  saveEditedNote() {

    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.closeDetailsDialog();
        if (selfObj.notes.type == 'Contact')
          selfObj.GetContactNotes(selfObj.conid, 'Contact');
        else
          selfObj.GetContactNotes(selfObj.conid, 'Customer');
      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
  }

  rowClickAgGrid(rowEvent) {
    if ((this.Ids).indexOf(rowEvent.data.id) > -1) {
      var index = (this.Ids).indexOf(rowEvent.data.id);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      $('#tasklistselectedtyle').val('');
    } else {
      this.Ids.push(rowEvent.data.id)
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      if (rowEvent.data.type == "Contact") {
        $('#tasklistselectedtyle').val('Contact');
      }
      else {
        $('#tasklistselectedtyle').val('Customer');
      }
    }
  }

  loadselectedcontacts() {
    let items = ['0'];
    if ($('#Customers').is(":checked")) {
      items.push('56');
    }
    else {
      items = $.grep(items, function (value) {
        return value != '56';
      });
    }
    if ($('#FormerCustomers').is(":checked")) {
      items.push('57');
    }
    else {
      items = $.grep(items, function (value) {
        return value != '57';
      });
    }

    //this.Contactvales = items.toString();
    //this.initializeAgGrid();
    // this.GetContactList(this.term, $("#userids").val(), this.currentPage, this.pageSize, this.active_deactiveStatus);

  }

  dialogsize() {
    setTimeout(function () {
      $('.dialog').attr('style', ' width: 32%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 23%;overflow: visible;height: 24%;');

    }, 4)
  }
  MultipleContactSelecteddialogsize() {
    setTimeout(function () {
      $('.dialog').attr('style', ' width: 32%;padding-bottom: 0px;border: 2px solid black;min-height: 23%;overflow: visible;height: 24%;padding:0;');

    }, 4)
  }
  dialogsizeInvalidSearch() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width: 50%;padding - bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min - height: 50%;overflow: visible;height: 50%;top: 24%;');

    }, 4);
  }
  dialog() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width: 24%;padding-bottom: 0px;border: 4px solid #00B0F0;min-height: 45%;overflow: auto;height: 46%;padding: 0;left: 0px;margin-left: 13%;top: 10%;');

    }, 4);
  }
  RWdialog() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width:100%; padding:0; border:none; min-height:63%; overflow:auto; height:78%; top:21%; left:12%; max-width:87%;margin:0;');

    }, 100);
  }
  columnsearchfieldDialog() {
    setTimeout(function () {
      $('.dialog').attr('style', ' width: 40%;padding - bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min - height:50%;overflow: visible;height:57%;top:25%');
    }, 4);
  }
  OpenLogoutBox() {
    this.ShowTransferAccounts = false;
    this.OpenLogout = true;
    this.dialog();
  }
  CloseLogOutBox() {
    this.OpenLogout = !this.OpenLogout;
  }

  RefreshDefaultColumns() {
    var selectcolums = [];
    this.SearchSelectedItems = [];
    this.SearchSelectedItems.forEach(selitem => {
      selectcolums.push(selitem.id + "-" + selitem.itemName)
    })
    if (document.getElementById('contact_span').className == 'mainCheckMark activeColor') {
      if (document.getElementById('a-z_button').className == 'subButtons activeButton') {
        this.advancedSearchService.GetDefaultColumns('contacts', this.currentuser.id).then((config) => {
          if (config != null && config.defaultcolumns != null) {
            let tempTableColumns: any[];
            tempTableColumns = config.defaultcolumns.split(',');
            tempTableColumns.forEach(col => {
              this.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

            })
          } else {
            this.SearchSelectedItems = [
              { "id": 'lastname', "itemName": 'LastName' },
              { "id": 'firstname', "itemName": 'FirstName' }
            ];
          }
        });
      }
      else if (document.getElementById('AccountsMainMenu').className == 'activeButton') {
        this.advancedSearchService.GetDefaultColumns('contacts', this.currentuser.id).then((config) => {
          if (config != null && config.defaultcolumns != null) {
            let tempTableColumns: any[];
            tempTableColumns = config.defaultcolumns.split(',');
            tempTableColumns.forEach(col => {
              this.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

            })
          } else {
            this.SearchSelectedItems = [
              { "id": 'accountname', "itemName": 'Account Name' }
            ];
          }
        });

      }
      else if (document.getElementById('viewcalllist_button').className == 'subButtons activeButton') {
        this.advancedSearchService.GetDefaultColumns('Calllists', this.currentuser.id).then((config) => {
          if (config != null && config.defaultcolumns != null) {
            let tempTableColumns: any[];
            tempTableColumns = config.defaultcolumns.split(',');
            tempTableColumns.forEach(col => {
              this.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

            })
          } else {
            this.SearchSelectedItems = [
              { "id": 'calllistname', "itemName": 'Call List Name' }
            ];
          }
        });

      }
    }
    else if (document.getElementById('customers_span').className == 'mainCheckMark activeColor') {
      this.advancedSearchService.GetDefaultColumns('customercontacts', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.SearchSelectedItems = [
            { "id": 'lastname', "itemName": 'Last Name' },
            { "id": 'firstname', "itemName": 'First Name' }
          ];
        }
      });
    }
    else if (document.getElementById('leads_span').className == 'mainCheckMark activeColor') {
      this.advancedSearchService.GetDefaultColumns('OpenLeads', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })
          })
        } else {
          this.SearchSelectedItems = [
            { "id": 'lastname', "itemName": 'Last Name' },
            { "id": 'firstname', "itemName": 'First Name' },
            { "id": 'account', "itemName": 'Account Name' }
          ];
        }
      });
    }
    else if (document.getElementById('opportunities_span').className == 'mainCheckMark activeColor') {
      this.advancedSearchService.GetDefaultColumns('OpenOpportunity', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.SearchSelectedItems = [
            { "id": 'firstName', "itemName": 'First Name' },
            { "id": 'lastName', "itemName": 'Last Name' },
            { "id": 'practicename', "itemName": 'Account Name' }
          ];
        }
      });
    }
    else if (document.getElementById('tasklist_span').className == 'mainCheckMark activeColor') {
      this.advancedSearchService.GetDefaultColumns('OpenTaskHistory', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.SearchSelectedItems = [
            { "id": 'accountname', "itemName": 'Account Name' },
            { "id": 'firstName', "itemName": 'First Name' },
            { "id": 'lastName', "itemName": 'Last Name' },
          ];
        }
      });
    }
    else if (document.getElementById('Calender_span').className == 'mainCheckMark activeColor') {
      this.advancedSearchService.GetDefaultColumns('TaskCalander', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.SearchSelectedItems = [
            { "id": 'accountname', "itemName": 'Account Name' },
            { "id": 'firstName', "itemName": 'First Name' },
            { "id": 'lastName', "itemName": 'Last Name' },
          ];
        }
      });

    }
    else if (document.getElementById('reports_span').className == 'mainCheckMark activeColor') {
      this.advancedSearchService.GetDefaultColumns('reports', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.SearchSelectedItems = [
            { "id": 'firstName', "itemName": 'First Name' },
            { "id": 'lastName', "itemName": 'Last Name' }
          ];
        }
      });
    }
    localStorage.removeItem('TempTable');
    this.SearchSelectedItemsLengthChanged = false;

  }

  removeTempData(subtab) {

    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];
    this.tempdata = [];
    if (retrievedObject != null) {
      let obj: any[] = JSON.parse(retrievedObject);
      obj.forEach(element => {

        if (element.subtab != subtab) {
          this.tempdata.push(element);
        }
      });
    }
    localStorage.setItem('TempTable', JSON.stringify(this.tempdata));
  }

  displayDefaultColumns() {

    var selectcolums = [];
    this.SearchSelectedItems.forEach(selitem => {
      selectcolums.push(selitem.id + "-" + selitem.itemName)
    })
    if (document.getElementById('contact_span').className == 'mainCheckMark activeColor') {
      if (document.getElementById('a-z_button').className == 'subButtons activeButton') {
        this.advancedSearchService.SaveDefaultColumns("contacts", selectcolums.toString(), this.currentuser.id).then(result => {
          this.removeTempData("contacts");
        });
      }
      else if (document.getElementById('AccountsMainMenu').className == 'activeButton') {
        this.advancedSearchService.SaveDefaultColumns("ContactsAccount", selectcolums.toString(), this.currentuser.id).then(result => {
          this.removeTempData("ContactsAccount");
        });

      }
      else if (document.getElementById('viewcalllist_button').className == 'subButtons activeButton') {
        this.advancedSearchService.SaveDefaultColumns("Calllists", selectcolums.toString(), this.currentuser.id).then(result => {
          this.removeTempData("Calllists");
        });

      }
    }
    else if (document.getElementById('customers_span').className == 'mainCheckMark activeColor') {
      this.advancedSearchService.SaveDefaultColumns("customercontacts", selectcolums.toString(), this.currentuser.id).then(result => {
        this.removeTempData("customercontacts");
      });
      //this.SearchSelectedItems = [
      //  { "id": 'lastname', "itemName": 'Last Name' },
      //  { "id": 'firstname', "itemName": 'First Name' }
      //];
    }
    else if (document.getElementById('leads_span').className == 'mainCheckMark activeColor') {
      this.advancedSearchService.SaveDefaultColumns("OpenLeads", selectcolums.toString(), this.currentuser.id).then(result => {
        this.removeTempData('Open Leads')
      });
      //this.SearchSelectedItems = [
      //  { "id": 'lastname', "itemName": 'Last Name' },
      //  { "id": 'firstname', "itemName": 'First Name' },
      //  { "id": 'account', "itemName": 'Account Name' }
      //];
    }
    else if (document.getElementById('opportunities_span').className == 'mainCheckMark activeColor') {
      this.advancedSearchService.SaveDefaultColumns("OpenOpportunity", selectcolums.toString(), this.currentuser.id).then(result => {
        this.removeTempData('OpenOpportunity');
      });

      //this.SearchSelectedItems = [
      //  { "id": 'firstName', "itemName": 'First Name' },
      //  { "id": 'lastName', "itemName": 'Last Name' },
      //  { "id": 'practicename', "itemName": 'Account Name' }
      //];
    }
    else if (document.getElementById('tasklist_span').className == 'mainCheckMark activeColor') {
      this.advancedSearchService.SaveDefaultColumns("OpenTaskHistory", selectcolums.toString(), this.currentuser.id).then(result => {
        this.removeTempData('OpenTaskHistory');
      });
      //this.SearchSelectedItems = [
      //  { "id": 'accountname', "itemName": 'Account Name' },
      //  { "id": 'firstName', "itemName": 'First Name' },
      //  { "id": 'lastName', "itemName": 'Last Name' },
      //];
    }
    else if (document.getElementById('Calender_span').className == 'mainCheckMark activeColor') {
      this.advancedSearchService.SaveDefaultColumns("OpenCalander", selectcolums.toString(), this.currentuser.id).then(result => {
        this.removeTempData("TaskCalander");
      });
      //this.SearchSelectedItems = [
      //  { "id": 'accountname', "itemName": 'Account Name' },
      //  { "id": 'firstName', "itemName": 'First Name' },
      //  { "id": 'lastName', "itemName": 'Last Name' },
      //];
    }
    else if (document.getElementById('reports_span').className == 'mainCheckMark activeColor') {
      this.advancedSearchService.SaveDefaultColumns("Reports", selectcolums.toString(), this.currentuser.id).then(result => {
        this.removeTempData("StandardReports");
      });
      //this.SearchSelectedItems = [
      //  { "id": 'firstName', "itemName": 'First Name' },
      //  { "id": 'lastName', "itemName": 'Last Name' }
      //];
    }

    this.SearchSelectedItemsLengthChanged = false;

  }

  showSearchFieldsDialogfun(val) {
    var url = window.location.href;
    if (this.calllisturl != "" && this.calllisturl != url) {
      if (this.Iscalllisterrorshow) {
        this.Iscalllistdialogshow = true;
        this.callListSavePopUpCss();
        return false;
      }
    }
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.ProfilePicture = this.currentuser.ProfilePicture;
    this.userservice.GetSubscriptionName().then(result => {
      this.SubscriptionName = result.BusinessName;
    });
    this.showSearchFieldsDialog = true;
    this.showSearchTabsSubTabs = false;
    this.showSearchFieldsDialogfunCss();
    this.sideMenuDisable('Enable');

    this.customerService.GetSpecialityList("Menus", this.currentuser.id).then((menus) => {
      menus.forEach(appointment => {
      });
      this.Menuslist = menus;
      var retrievedObject = localStorage.getItem('SelectedSubMenu');
      
      if (retrievedObject != null) {
        setTimeout(function () {
          $('#SubMenu' + retrievedObject).addClass("activeButton");
        }, 200);
      }

    });

  }

  showSearchFieldsDialogfunCss() {
    setTimeout(function () {
      $('#showSearchFieldsDialog .dialog').attr('style', 'width:30%;padding-bottom:0px;min-height:75%;overflow:auto;height:84%;left:68%;top:10%;padding:0');
    }, 4)
  }

  showAdvancedSearchDialogCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width:44.5%;padding-bottom:0px;border:3px solid #FF5F15;min-height:63%;overflow:hidden;height:92%;left:53.5%;padding:0;');
    }, 4)
  }
  notSelectedCss(val) {
    switch (val) {
      case "SearchNotAvailable": {
        setTimeout(function () {
          $('#SearchNotAvailable .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 3px solid #FF5F15;min-height: 18%;overflow: auto;height: 4%;top:10%');
        }, 4)
        break;
      }
      case "isMultipleContactSelected": {
        setTimeout(function () {
          $('#isMultipleContactSelected .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 3px solid #FF5F15;min-height: 18%;overflow: auto;height: 4%;top:10%');
        }, 4);
        break;
      }
      case "NotSelected": {
      this.pageViewed = "";
        setTimeout(function () {
          $('#NotSelected .dialog').attr('style', 'width: 33%;padding-bottom: 0px;border: 3px solid #FF5F15;min-height: 18%;overflow: auto;height: 4%;top:10%');
        }, 4);
        break;
      }
      case "CloseSearchDialog": {
        setTimeout(function () {
          $('#CloseSearchDialog .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 3px solid #FF5F15;min-height: 18%;overflow: auto;height: 4%;top:10%');
        }, 4);
        break;
      }
      case "quickview": {
        this.StandardpopupUi('#QuickViewDialog .dialog');
        //setTimeout(function () {
        //  $('#QuickViewDialog .dialog').attr('style', 'width:96%;border:3px solid #FF5F15;height:88%;z-index: 999;');
        //}, 4);
        break;
      }
      case "Tickets": {
        setTimeout(function () {
          $('#TicketCreateDialog .dialog').attr('style', 'width:35%;padding:0; height:63%;overflow:auto;height:92%;top:8%;margin-right:0%;');
        }, 4);
        break;
      }
      case "CallList": {
        this.Standard3dotUi('#CallListDialog .dialog');        
        break;
      }
      default: {
        setTimeout(function () {
          $('.dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 3px solid #FF5F15;min-height: 18%;overflow: auto;height: 4%;top:10%;');
        }, 4);
        break;
      }
    }

  }
  showmenuDialogCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width:32.5%;padding-bottom:0px;border:3px solid #00B0F0;min-height:63%;overflow:auto;height:85%;left:65.5%;top:14.5%;padding:0;');
    }, 4)
  }

  openSearchFields() {
    this.showSearchFields = true;
  }
  ClearSearchBox() {
    $('#searchContact').val('');
    //this.onSearchChange('delete');
  }

  CloseSearchFieldsDialog(val) {
    if (val == 'showSearchFieldsDialog') {
      this.showSearchFieldsDialog = false;
      this.CollapseSelectedMenu('');
    } else if (val == 'RWActive') {
      this.RWActive = false;
    }
    else if (val == 'showSearchTabsSubTabs') {
      this.showSearchTabsSubTabs = false;
    }
    //else if (val == 'showmenuDialog') {
    //  this.showmenuDialog = false;
    //}
  }
  QuickSearch()
  {
     //this.router.navigateByUrl(`/application/quicksearch`);
    this.QuickSearchDiv = true;
   // this.CreateProposalPageCss("QuickSearchDiv");
  }

  GetRHxNames(Type) {
    this.customerService.GetRecentHistoryNames(Type, $("#userids").val()).then((RHxNameslist) => {
      this.RHxNames = RHxNameslist;
    });
  }

  SelectedMenu(item) {
    localStorage.setItem('SelectedSubMenu', item);
    switch (item) {
      case 521:
        this.activateMainButtons('My Documents')
        break;
      case 522:
        this.activateMainButtons('rhx');
        break;
      case 523:
        this.ResponseWizard(true);
        break;
      case 524:
        this.activateMainButtons('Internal Messages');
        break;
      case 525:
        this.activateMainButtons('Campaigns');
        break;
      case 526:
        this.activateMainButtons('saletool');
        break;
      case 527:
        this.activateMainButtons('systemConfig');
        break;

    }
    this.CloseSearchFieldsDialog('showSearchFieldsDialog');

  }

  hideShowCommonTabs(val) {
    this.hideLabel = val;
    //this.crmDialog = true;
    //this.crmDialogCss();
  }
  quickViewDialogcss(id) {
   
    $(setTimeout(function () {
      $(id).attr('style', 'width: 52%;padding:0;min-height:63%;overflow:auto;height:92%;top:8%;margin-right:0%;');
    }, 4));
  }
  salesxcrmDialog(id) {
    $(setTimeout(function () {
      $(id).attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 4));
  }

  crmDialogCss() {
    $(setTimeout(function () {
      $('#crmDialog .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 4));
  }
  Closelogoutpopup() { this.OpenLogout = false; }
  Search(val) {
    if (val && (this.selectedtabid == "dashboards" || this.selectedtabid == "Sales Forecast" || this.selectedtabid == "AdminReports" || this.selectedtabid == "Projects"
      || this.selectedtabid == "Marketing")) {
      this.SearchNotAvailable = true;
      this.notSelectedCss("SearchNotAvailable");
    } else {
      if (val == true) {
        this.Searchdiv = false;
        setTimeout(() => {
        document.getElementById('searchContact').focus();
        }, 500);
      }
      else {
        if (this.searchCriteriadata.length == 0) {
          this.Searchdiv = false;
        }
        else {
          this.CloseSearchDialog = true;
          this.notSelectedCss("CloseSearchDialog");
        }

      }
    }
  }

  SearchNot() { this.SearchNotAvailable = false; }
  ShowRHxMenuDiv(val) {
    if (!val) {
      var url = window.location.href;
      this.displayheader(url);
      }   
      this.recentHistoryOn = val;
      this.RHxMenuDiv = val;
      this.RHxMenuDivCss();
      this.RHxNames = [];
      let selfobj = this;
      setTimeout(function () {
        var RHxTabselected = localStorage.getItem('RHxTabselected');
        if (RHxTabselected == 'Contacts') {
          selfobj.selectedtabid = 'contact';
          selfobj.RHxContactSelected = true;
          selfobj.GetRHxNames('Contact');
        } else if (RHxTabselected == 'Accounts') {
          selfobj.selectedtabid = 'customers';
          selfobj.RHxAccountSelected = true;
          selfobj.GetRHxNames('Account');
        }
        else {
          selfobj.GetRHxNames('All');
          selfobj.RHxRecentViewPages = true;
        }
      }, 300)
   
  }

  RHxTab(val) {
    
    if (val == 'Contacts') {
      this.RHxAccountSelected = false;
      this.RHxContactSelected = true;
      this.RHxRecentViewPages = false;
      this.GetRHxNames('Contact');
    } else if (val == 'Accounts') {
      this.RHxAccountSelected = true;
      this.RHxContactSelected = false;
      this.RHxRecentViewPages = false;
      this.GetRHxNames('Account');
    } else if (val == 'RecentPages') {
      this.GetRHxNames('All');
      this.RHxRecentViewPages = true;
      this.RHxAccountSelected = false;
      this.RHxContactSelected = false;
    }

    localStorage.setItem('RHxTabselected', val);
  }
  OpenAccountOrContact(Rhx) {
    if (Rhx.Type == "Account") {
      this.customerService.GetByAccountId(Rhx.id).then(result => {
        this.account = result;
        if (this.account.isCustomer == 874) {
          var url = window.location.href;
          if (url.indexOf('account') > -1) {
            let selfobj = this;
            $(setTimeout(function () {

              selfobj.router.navigateByUrl(`/application/account/Customer`);
            }, 50));
            selfobj.router.navigateByUrl(`/application`);
          }
          else {
            this.router.navigateByUrl(`/application/account/Customer`);
          }

          localStorage.setItem('RhxAccountVal', Rhx.id);
          this.recentHistoryOn = false;
          this.RHxMenuDiv = false;
          this.RHxNames = [];
          let selfobj = this;
          setTimeout(function () {
            selfobj.ExpandSelectedMenu('customers');
            $(".subButtons").removeClass("activeButton");
           selfobj.selectedtabid = 'Customer';
          }, 200)
        } else {
          var url = window.location.href;
          if (url.indexOf('account') > -1) {
            let selfobj = this;
            $(setTimeout(function () {

              selfobj.router.navigateByUrl(`/application/account/Accounts`);
            }, 50));
            selfobj.router.navigateByUrl(`/application`);
          }
          else {
            this.router.navigateByUrl(`/application/account/Accounts`);
          }
         
          localStorage.setItem('RhxAccountVal', Rhx.id);
          this.recentHistoryOn = false;
          this.RHxMenuDiv = false;
          this.RHxNames = [];
          let selfobj = this;
          setTimeout(function () {
            selfobj.ExpandSelectedMenu('Accounts');
            $(".subButtons").removeClass("activeButton");
            //selfobj.selectedtabid = 'Customer';
          }, 200)
        }

        
      });
    }
    else if (Rhx.Type == "Contact") {
    
      var url = window.location.href;
      if (url.indexOf('contacts') > -1) {
        let selfobj = this;
        $(setTimeout(function () {

          selfobj.router.navigateByUrl(`/application/contacts/contacts/null`);
        }, 50));
        selfobj.router.navigateByUrl(`/application`);
      }
      else {
        this.router.navigateByUrl(`/application/contacts/contacts/null`);
      }
      localStorage.setItem('RhxContactVal', Rhx.id);
      $('#emailIds').val(Rhx.id);
      $('#tasklistselectedtyle').val('Contact');
      this.recentHistoryOn = false;
      this.RHxMenuDiv = false;
      this.RHxNames = [];
      let selfobj = this;
      setTimeout(function () {
        selfobj.ExpandSelectedMenu('contact');
        selfobj.selectedtabid = 'contact';
      }, 200)

    }
    else {
      localStorage.setItem('RecentPagesViewed', Rhx.id);
      this.recentHistoryOn = false;
      this.RHxMenuDiv = false;
      this.RHxNames = [];


      this.customerService.GetAuditDataByID(Rhx.id).then((RHxNameslist) => {
        
        if (RHxNameslist.type == "Leads") {
          this.CollapseSelectedMenu('leads');
          this.ExpandSelectedMenu('leads');
          this.router.navigateByUrl(`/application/leadsdashboard/${RHxNameslist.AccountOrContactId}/0/null`);
        } else if (RHxNameslist.type == "Opportunity") {
          this.CollapseSelectedMenu('opportunities');
          this.ExpandSelectedMenu('opportunities');
          let type = RHxNameslist.AccountOrContact == 'Contact' ? 'Contacts' : 'Customer';
          this.router.navigateByUrl(`/application/opportunitiesdashboard/${RHxNameslist.AccountOrContactId}/${type}/null/${RHxNameslist.documentid}`);
        }
        else if (RHxNameslist.type == "TaskList" ) {
          if (RHxNameslist.AccountOrContact == "Contacts") {
            this.CollapseSelectedMenu('tasklists');
            this.ExpandSelectedMenu('tasklists');
            this.router.navigateByUrl(`/application/tasklistdashboard/Contacts/${RHxNameslist.AccountOrContactId}/${RHxNameslist.documentid}/null`);
          } else if (RHxNameslist.AccountOrContact == "Account") {
            this.CollapseSelectedMenu('tasklists');
            this.ExpandSelectedMenu('tasklists');
            this.router.navigateByUrl(`/application/tasklistdashboard/Customer/${RHxNameslist.AccountOrContactId}/${RHxNameslist.documentid}/null`);
          }
          else {
            this.activateMainButtons('task lists');
          }
          
        } else if (RHxNameslist.type == "Proposal") {
          this.CollapseSelectedMenu('Proposals');
          this.ExpandSelectedMenu('Proposals');

          this.router.navigateByUrl(`/application/Proposals/` + RHxNameslist.AccountOrContactId + `/` + RHxNameslist.documentid + `/Open Proposal/Contact`);
        } else if (RHxNameslist.type == "Forecasting") {
          this.CollapseSelectedMenu('Forecasting');
          this.ExpandSelectedMenu('Forecasting');

          this.ClosedLeads('Forecasting', this.currentuser.id, 'Forecasting');
          this.router.navigateByUrl(`/application/forecasting/${RHxNameslist.AccountOrContactId}`);
        } else if (RHxNameslist.type == "Calendar") {
          this.CollapseSelectedMenu('Calendar');
          this.ExpandSelectedMenu('Calendar');

          this.router.navigateByUrl(`/application/month-calender/null`);
        } else if (RHxNameslist.type == "Customer") {
          this.CollapseSelectedMenu('customers');
          this.ExpandSelectedMenu('customers');
          if (RHxNameslist.AccountOrContactId > 0) {
            this.router.navigateByUrl(`/application/customercontacts/undefined/${RHxNameslist.AccountOrContactId}`);
          } else {
            this.router.navigateByUrl(`/application/customercontacts`);
          }
        } else if (RHxNameslist.type == "Tickets") {
          this.CollapseSelectedMenu('tickets');
          this.ExpandSelectedMenu('tickets');
         
          this.router.navigateByUrl(`/application/zendeskhome/${RHxNameslist.AccountOrContactId}/${RHxNameslist.documentid}/Open Tickets`);
        }
        else if (RHxNameslist.type == "About SalesXCRM" || RHxNameslist.type == "Contact Us" || RHxNameslist.type == "Release Notes") {

          this.AboutUsPageType = this.selectedsubbutton = RHxNameslist.type.replace(" ", '');
          this.ActionClick(this.AboutUsPageType);
          //this.router.navigateByUrl(`/application/aboutsales-xcrm/${RHxNameslist.type}`);
        } else if (RHxNameslist.type == "Call List") {

          if (RHxNameslist.documentid != 0) {
            this.router.navigateByUrl(`/application/calllist/` + RHxNameslist.AccountOrContact + '/' + 0 + '/' + RHxNameslist.documentid);
          } else {
            this.activateMainButtons('Call Lists');
          }

          
        } else if (RHxNameslist.type == 'Campaigns') {
        
          this.selectedsubbutton = 'Campaigns';
          this.CampaignId = RHxNameslist.documentid;
        
        } else if (RHxNameslist.type == 'Profile') {
          this.activateMainButtons('My Profile');
        }else if (RHxNameslist.type == 'Recent History') {
          this.SubmenuChange('Recent History', 'Recent History', 'main');
        }else if (RHxNameslist.type == 'Pended Proposal') {
          this.activateMainButtons('PendedProposal');
        } else if (RHxNameslist.type == 'My Documents') {
          this.activateMainButtons('My Documents');
        } else if (RHxNameslist.type == 'User Configuration') {
          this.SubmenuChange('User Configuration', '426', 'main');
        } else if (RHxNameslist.type == 'PopUp Message') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Pop-Up Messaging', 'Pop-Up Messaging', 'main');
        } else if (RHxNameslist.type == 'Ticket Configuration') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Ticket Configuration', '529', 'Sub');
        } else if (RHxNameslist.type == 'Task List Configuration') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Task List Configuration', '424', 'Sub');
        } else if (RHxNameslist.type == 'StatusID Configuration') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Status ID Configuration', '530', 'Sub');
        } else if (RHxNameslist.type == 'Software License Configuration') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Software License Configuration', '627', 'Sub');
        } else if (RHxNameslist.type == 'Response Wizard') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Sales tools', 'ResponseWizard', 'sub');
        } else if (RHxNameslist.type == 'Proposal Configuration') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Proposal Configuration', '528', 'Sub');
        } else if (RHxNameslist.type == 'Opportunities Configuration') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Opportunities Configuration', '423', 'Sub');
        } else if (RHxNameslist.type == 'Leads Configuration') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Leads Configuration', '531', 'Sub');
        } else if (RHxNameslist.type == 'Document MGMT Configuration') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Document MGMT Configuration', '451', 'Sub');
        } else if (RHxNameslist.type == 'Campaign Configuration') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Campaign Configuration', '624', 'Sub');
        } else if (RHxNameslist.type == 'Account Transfer Configuration') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Account - Transfer Ownership', '612', 'Sub');
        } else if (RHxNameslist.type == 'Account Configuration') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Account Configuration', '420', 'Sub')
        } else if (RHxNameslist.type == 'Contact Configuration') {
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Contact Configuration', '422', 'Sub')
        } else if (RHxNameslist.type.includes('Standard Reports')) {
          this.CollapseSelectedMenu('reports');
          this.ExpandSelectedMenu('reports');
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Reports', 'StandardReports', 'Sub');
        } else if (RHxNameslist.type.includes('Admin Reports')) {
          this.CollapseSelectedMenu('reports');
          this.ExpandSelectedMenu('reports');
          this.PopUpMessageType = RHxNameslist.AccountOrContact;
          this.SubmenuChange('Reports', 'AdminReports', 'Sub');
        }

      });

      

    }
    this.pageViewed = "";

  }
  RWActiveClick(val) {
    var url = window.location.href;
    if (this.calllisturl != "" && this.calllisturl != url) {
      if (this.Iscalllisterrorshow) {
        this.Iscalllistdialogshow = true;
        this.callListSavePopUpCss();
        return false;
      }
    }
    if (this.userprevilages != undefined) {
      var thisTab = this.userprevilages.filter(p => p.resource == 'Response Wizard')[0];
      if (thisTab == undefined || (thisTab != undefined && thisTab.canview == false)) {
        this.toastr.warning("You Do Not have Access to Response Wizard Tab – Please check with CRM Administrator if Access is Required.", "Access Denied", { timeOut: 1500 });

        return false;
      }     
      if (val == 1 || val == 2) {
        this.responsewizardType = (val == 2) ? "SalesTool" : "TaskList";
        this.router.navigateByUrl(`/application/responsewizard/` + this.responsewizardType + `/` + this.PopUpMessageType);
         } 
      else {
        this.RWActive = false;
      }
    }
  }

 
  RHxMenuDivCss() {
    setTimeout(function () {
      $('#RHxMenuDiv .dialog').attr('style', 'width: 15%;padding: 0;min-height: 63%;overflow: auto;height: 85%;left: 0!important;margin-left: 1%;top: 10%;');
    }, 4)
  }
  Ok(msg) {
    if (msg == 'NotSelected') {
      this.NotSelected = false;
    }
    else {
      this.isMultipleContactSelected = false;
    }
  }

  GetAllZendeskUers(filter, sortOrder) { 
    this.ClosedTicketCount = 0;
    this.customerService.GetTicketsList('All Tickets', filter, this.currentuser.id, sortOrder, false, null).then((tickets) => {
  
      this.OpenTicketCount = tickets.filter(p => p.TicketStatus == 1).length;     
      this.OnHoldTicketCount = tickets.filter(p => p.TicketStatus == 2).length;
      this.PendedTicketCount = tickets.filter(p => p.TicketStatus == 3).length;
      this.ClosedTicketCount = tickets.filter(p => p.TicketStatus == 4).length + tickets.filter(p => p.TicketStatus == 5).length;
      this.DeleteTicketCount = tickets.filter(p => p.TicketStatus == 6).length;
      this.AllTicketCount = tickets.length;
      this.UnReadOpenTicketCount = tickets.filter(p => p.readstatus == 0 && (p.assignTicketToUser != null && p.assignTicketToUser == this.currentuser.id)).length;
    });

  }

  GetUserMessagesByUserId() {
    this.NotificationMessages = [];
    this.messagingservice.GetUserMessagesByUser(this.currentuser.id).then((res) => {
      this.NotificationMessages = res.filter(x => x.readusers == null);     
    });
  }

  NotificationMessage(Data) {
    this._notificationSvc.Message(Data);
    this.NotificationMessage
  }

  NotificationMessageDialogCss() {
    setTimeout(function () {
      $(' #NotificationMessageDialog .dialog').attr('style', 'width: 50%;padding - bottom: 0px;min-height: 63%;overflow: auto;height: 60%;left: 6%;top: 18.5%;padding: 0;');
    }, 4)
  }

  onWizzardClosed(val) {
    if (val.readusers == null) {
      val.readusers = "";
      val.readusers += this.currentuser.id;
    } else {
      val.readusers += "," + this.currentuser.id;
    }

    this.AddorUpdateUserMessages(val)
  }

  AddorUpdateUserMessages(userMessages) {
    this.messagingservice.AddorUpdateUserMessages(userMessages).then((res) => {
     
    });
  }
  onWizardAdded() {

  }

  

  CloseSearch() {
    this.CloseSearchDialog = false;
  }
  ShowEditSearchDiv(val) {
    if (val === true) {
      this.showSearchTabsSubTabs = true;
      this.showSearchTabsSubTabsCss();
      $(setTimeout(function () {
        //$('#multipleDropdown .cuppa-dropdown .dropdown-list .list-area .list-filter').after('<input type="checkbox" style="margin-left:3%;margin-top:3%;" onclick="SetDefaultColumn()"><label style="margin-left:2%;">Set Default</label>');
        $('#multipleDropdown .c-list').click()
      }, 500));

    } else {
      this.showSearchTabsSubTabs = false;
    }
  }
  showSearchTabsSubTabsCss() {
    setTimeout(function () {

      $('.dialog').attr('style', 'width: 22%;padding - bottom: 0px;border: 3px solid #00B0F0;min-height: 63%;overflow: auto;height: 73.8%;left: 76%;top: 18.5%;padding: 0;');
    }, 4)
  }
  showAddEditAccountCss() {
    setTimeout(function () {
      $('#addEditAccount .dialog').attr('style', 'width: 96%;padding-bottom: 0px;min-height: 92%;overflow: auto;height: 92%;padding: 0;top:8%;border: 3px solid #FF5F15;left:3.2%');
    }, 4)
  }
  ActionClick(val) {
    this.selectedsubbutton = val;
    this.AboutUsPage = true;
    if (val == 'AboutSalesXCRM') {
      this.selectedsubbutton = val;
      this.AboutUsPageDiv = true;
      
    } else if (val == 'ReleaseNotes') {
      this.AboutUsPageDiv = true;
     
    } else if (val == 'ContactUs') {
      this.AboutUsPageDiv = true;
      
    }
 
    this.aboutusComponent.OpenAboutusPageType(val)
    $(".subButtons").removeClass("activeButton");
    $('#' + val + "_button").addClass("activeButton");

  }

  CloseAboutus(msg) {
    this.AboutUsPage = false;
    if (msg == 'AboutUs') {
      this.AboutUsPageDiv = false;
      this.activateMainButtons('dashboards');
      
    } else if (msg == 'AccountTransfer') {
      this.ShowConfigurationDialog = false;
      this.activateMainButtons('dashboards');
    } else if (msg == 'My Documents') {
     // this.ShowConfigurationDialog = false;
      $('#MyDocumentsMainMenu').removeClass("activeButton");
      $('#MyDocumentsMainMenu').addClass("collapsed");
    } else if (msg == 'userRole') {
      this.ShowConfigurationDialog = false;
      $('#UserRolesMainMenu').removeClass("activeButton");
     
    }
    else if (msg == 'ReleaseNotes') {
      this.ReleaseNotesPage = false;
    } else if (msg == 'ContactUs') {
      this.ContactUsPage = false;
    }
    this.ShowConfigurationDialog = false;
    this.selectedsubbutton = "";
  }
  CloseSearchBox(val) {
    this.Searchdiv = false;
  }

  ClearSearchCriteria() {
    this.ConfirmationSearchClear = true;
    this.ConfirmationSearchClearCss();
  }

  SearchConfirm(val) {
    if (val === 'Yes') {
      this.clearSearchConfirm = true;
      this.onSearchChange('delete');
      this.ConfirmationSearchClear = false;
      this.Searchdiv = false;
      
    } else {
      this.ConfirmationSearchClear = false;
      this.clearSearchConfirm = false;
    }
    
  }

  ConfirmationSearchClearCss() {
    setTimeout(function () {
      $('#ConfirmationSearchClear .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4)
  }
  ProposalOverLayWarningCss() {
    setTimeout(function () {
      $('#ProposalOverLayWarning .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4)
  }

  ShowLiveChatBox(val) {
    this.ShowLivechatBox = true;
  }
  EventEmit(msg) {  
    if (msg === 'Proposal Cancelled') {
      this.CreateProposalPage = false;
      this.activateMainButtons('dashboards');
    } else if (msg === "Pend Proposal") {
      this.activateMainButtons('PendedProposal');
      this.CreateProposalPage = false;
    } else if (msg === "Proposal Saved") {
      this.CreateProposalPage = false;
      this.activateMainButtons('Proposals');
    }
  }
  CloseProposalPage(msg) {
    if (msg === true) {
      this.CreateProposalPage = true;
      this.ProposalOverLayWarning = true;
      this.ProposalOverLayWarningCss();
    }
  }

  sideMenuDisable(Value) {
    if (Value == "Disable") {
      $("#SideMenu").addClass('customDisable');
      $('.panel-default').attr('style', 'border-color: transparent !important;')
      $('.panel-default .panel-heading').attr('style', 'border-color: transparent !important')
    } else {
      $("#SideMenu").removeClass('customDisable');
      $('.panel-default').attr('style', 'border-color: white !important;')
      $('.panel-default .panel-heading').attr('style', 'border-color: white !important;')
    }
    
  }

  ExpandAddEditAccountDialog(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 100%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 86%;padding: 0;top: 10%;left: 10%;max-width: 88%;');
      }, 4)
    } else {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
      }, 4)
    }
  }

  closedialog(val) {
    $('#NotificationMessageDialog' + val).attr('style', 'display:none');
  }

  InsertAuditHistory(RecentPagesViewed: AuditLogModel) {

    this.userservice.InsertAuditLog(RecentPagesViewed).then((result) => {

    });
  }

  WeekLyReport() {
    this.userservice.weeklyReport(this.currentuser.userName).then((result) => {

    });
  }

  AccTranInitializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: false, enableColResize: true, paginationPageSize: 1000, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true,
      toolPanelSuppressPivotMode: true, rowSelection: 'multiple', enableCellChangeFlash: true
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.AccTranCreateColumnDefs();
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    //this.context = this;

  }
  AccTranCreateColumnDefs() {
    this.AccTransColumnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      { field: 'id', headerName: 'Id', hide: true },
      {
        field: 'customertypeid', headerName: 'Customer Type', hide: true,
        cellRenderer(params) {
          if (params.value == '0') { return "Non - Customer" } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
        }
      },
      { field: 'accnumber', headerName: 'Act Number', hide: true, cellStyle: { color: 'red' }, },
      { field: 'IsContact', headerName: ' Is Contact?', hide: true },
      { field: 'accountname', headerName: 'Account Name', hide: false },
      { field: 'speciality', headerName: 'Specialty', hide: true, },
      {
        field: 'address1', headerName: 'Address1', hide: false,
      },
      {
        field: 'address2', headerName: 'Address2', hide: false,
      },
      {
        field: 'city', headerName: 'City', hide: false,
      },
      {
        field: 'state', headerName: 'State', hide: false,
      },
      {
        field: 'zip', headerName: 'Zip', hide: false
      },
      {
        field: 'phone', headerName: 'Phone', hide: false,
      },
      {
        field: 'mapit', headerName: 'Map It', hide: false, width: 80,
      },
      {
        field: 'ehr', headerName: 'EHR', hide: false,
      },
      {
        field: 'pm', headerName: 'PM', hide: false,
      },

      {
        field: 'rcm', headerName: 'RCM', hide: false,
      },
      {
        field: 'username', headerName: 'User Name', hide: false,
      }
    ];
  }
  openMyNotes() {
    this.MyNotes = true;
    let selfObj = this;
    $(setTimeout(function () {
      $("#FollowUp").trigger("click");     
      selfObj.NotesChanged("#FollowUp");
      selfObj.currentuser.myNotes = selfObj.currentuser.followupNotes;
    }, 200));
    selfObj.MyNotesDialogCss();
  }

  MyNotesDialogCss() {
    $(setTimeout(function () {
      $('#MyNotes .dialog').attr('style', 'width:52%;padding:0;min-height: 63%;overflow: auto;height:92%;top:8%;margin-right:0%;');
    }, 4));
  }
  CloseMyNotes() {
  
    if (this.selectedNoteRadio == "FollowUp") {
      if (this.currentuser.myNotes != this.currentuser.followupNotes) {
        this.loaddialog('MyNotes', 'My Notes', 'Save Changes to My Notes? ', false, false, true, true, 0)
      } else {
        this.MyNotes = false;
      }
    }
    else if (this.selectedNoteRadio == "Client") {
      if (this.currentuser.myNotes != this.currentuser.followupNotes) {
        this.loaddialog('MyNotes', 'My Notes', 'Save Changes to My Notes? ', false, false, true, true, 0)
      } else {
        this.MyNotes = false;
      } }
    else if (this.selectedNoteRadio == "Goals") {
      if (this.currentuser.myNotes != this.currentuser.goalNotes) {
        this.loaddialog('MyNotes', 'My Notes', 'Save Changes to My Notes? ', false, false, true, true, 0)
      } else {
        this.MyNotes = false;
      }
    }
    else if (this.selectedNoteRadio == "Personal") {
      if (this.currentuser.myNotes != this.currentuser.personalNotes) {
        this.loaddialog('MyNotes', 'My Notes', 'Save Changes to My Notes? ', false, false, true, true, 0)
      } else {
        this.MyNotes = false;
      }
    }
    else if (this.selectedNoteRadio == "SoftwareBugs") {
      if (this.currentuser.myNotes != this.currentuser.SoftwareBugsNotes) {
        this.loaddialog('MyNotes', 'My Notes', 'Save Changes to My Notes? ', false, false, true, true, 0)
      } else {
        this.MyNotes = false;
      }
    }
    else if (this.selectedNoteRadio == "Misc") {
      if (this.currentuser.myNotes != this.currentuser.miscNotes) {
        this.loaddialog('MyNotes', 'My Notes', 'Save Changes to My Notes? ', false, false, true, true, 0)
      } else {
        this.MyNotes = false;
      }
    }
    //else {
    //  this.MyNotes = false;
    //  var url = window.location.href;
    //  this.displayheader(url);
    //}
   
  }
  SaveMyNotes() {   
    if (this.selectedNoteRadio == "FollowUp") {
      this.mynotes.followupNotes = $('textarea#MyNotes').val();
    }
    else if (this.selectedNoteRadio == "Goals") {
      this.mynotes.goalNotes = $('textarea#MyNotes').val();
    } else if (this.selectedNoteRadio == "Misc") {
      this.mynotes.miscNotes = $('textarea#MyNotes').val();
    } else if (this.selectedNoteRadio == "Personal") {
      this.mynotes.personalNotes = $('textarea#MyNotes').val();
    } else if (this.selectedNoteRadio == "SoftwareBugs") {
      this.mynotes.SoftwareBugsNotes = $('textarea#MyNotes').val();
    }
    this.customerService.SaveuserNotes(this.mynotes).then(result => {
      if (this.selectedNoteRadio == "FollowUp") {
        this.currentuser.followupNotes = this.mynotes.followupNotes;
      } else if (this.selectedNoteRadio == "Goals") {
        this.currentuser.goalNotes = this.mynotes.goalNotes;
      } else if (this.selectedNoteRadio == "Misc") {
        this.currentuser.miscNotes = this.mynotes.miscNotes;
      } else if (this.selectedNoteRadio == "Personal") {
        this.currentuser.personalNotes = this.mynotes.personalNotes;
      } else if (this.selectedNoteRadio == "SoftwareBugs") {
        this.currentuser.SoftwareBugsNotes = this.mynotes.SoftwareBugsNotes;
      }
      this.toastr.success("My Note Added successfully.", "Note Added");
    });
  }
  NotesChanged(val) {

    if (val == "FollowUp") {
      this.currentuser.myNotes = this.currentuser.followupNotes;
    } else if (val == "Goals") {
      this.currentuser.myNotes = this.currentuser.goalNotes;
    } else if (val == "Misc") {
      this.currentuser.myNotes = this.currentuser.miscNotes;
    } else if (val == "Personal") {
      this.currentuser.myNotes = this.currentuser.personalNotes;
    } else if (val == "SoftwareBugs") {
      this.currentuser.myNotes = this.currentuser.SoftwareBugsNotes;
    } else {
      this.currentuser.myNotes = " ";
    }
  }
  getTransferedAccounts() {
    this.AllTransferedAccounts = [];
    this.customerService.GetAccountsTransferByUser(this.currentuser.id, 'New').then(res => {
      if (res != null) {
        this.TransferedAccounts = res;
        var ids = [];
        let so = this;
        $(setTimeout(function () {
          so.TransferedAccounts.forEach(ele => {
            ids.push(ele.AccountIds);
          });
          so.opprtunityservice.GetAllAccountsByListOfIds(ids.join(',')).then(accounts => {

            if (accounts != null) {
              so.AllTransferedAccounts = accounts;
            } else {
              so.AllTransferedAccounts = [];
            }

            so.AccTranInitializeAgGrid();
          });
        }, 100));
      }
      else {
        this.AllTransferedAccounts = [];
      }
    });


  }
  AccountTransferrowClickAgGrid(rowEvent) {
    if ((this.Ids).indexOf(rowEvent.data.id) > -1) {
      var index = (this.Ids).indexOf(rowEvent.data.id);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      this.AccConIds = string;
      $('#tasklistselectedtyle').val('');
    } else {
      this.Ids.push(rowEvent.data.id)
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      this.AccConIds = string;
      $('#tasklistselectedtyle').val('Customer');

    }
  }
  AccountcellDoubleClicked(rowEvent) {
    this.QuickSearchDiv = false;
    localStorage.setItem('RhxAccountVal', rowEvent.data.id);
    let SO = this;
    $(setTimeout(function () {
      SO.activateSubButtons('empty');
      $(setTimeout(function () {
        SO.router.navigateByUrl(`/application/account/Accounts`);
        SO.ExpandSelectedMenu('Accounts');
        $(".subButtons").removeClass("activeButton");
        SO.selectedtabid = 'Customer';
      }, 100));
    }, 50));

  }
  OpenAccountTransferDialog() {
      this.AccTranInitializeAgGrid();
    this.getTransferedAccounts();
    
  }


  Confirmation(val) {
    if (val == true) {
      this.loaddialog('AccountTransfer', 'Account Transfer', 'Clear Accounts Selected from Grid?', false, false, true, true, 0);
    }
    else {
      this.ShowTransferAccounts = false;
      var url = window.location.href;
      this.displayheader(url);
      this.sideMenuDisable('Enable');
    }
  }
 
  UpdateOwnership() {

    this.customerService.TransferAccountsToNewOwner(this.currentuser.id, this.Ids.join(',')).then(val => {
      this.getTransferedAccounts();
      //this.ClearTransferAccounts = false;
      this.Ids = [];
      this.toastr.success("Account Transfer successfully.", "Account Saved", { timeOut: 600 });
    });
  }
  ShowAccountsTransfered() {
    this.showmenu = true;
    this.selectedsubbutton = 'ShowTransferAccounts';   
    this.OpenAccountTransferDialog();
    this.Ids = [];
    $('#emailIds').val('');
  }

  showSearchOption() {
    var url = window.location.href;
    if (this.calllisturl != "" && this.calllisturl != url) {
      if (this.Iscalllisterrorshow) {
        this.Iscalllistdialogshow = true;
        this.callListSavePopUpCss();
        return false;
      }
    }
    this.hideSubButton = true;
    setTimeout(function () {
      $('#hideSubButton .dialog').attr('style', 'width:50%;padding-bottom:0px;left:50%;top:6%;padding:0;');
    }, 4)
  }
  Standard3dotUi(idval) {
    setTimeout(function () {
      $(idval).attr('style', 'width: 96%;padding-bottom:0px;min-height:92%;overflow:auto;height:92%;padding:0;top:8%;border:3px solid #00B0F0;left:3.2%;');
    }, 4)
  }
  Standard3dotUione(idval) {
    setTimeout(function () {
      $(idval).attr('style', 'width: 33%;padding-bottom:0px;min-height:92%;overflow:auto;height:92%;padding:0;top:8%;border:2px solid #00B0F0;left:66.2%;');
    }, 4)
  }
  Standard3dotUitwo(idval) {
    setTimeout(function () {
      $(idval).attr('style', 'width: 66%;padding-bottom:0px;min-height:92%;overflow:auto;height:92%;padding:0;top:8%;border:2px solid #00B0F0;left:33.2%;');
    }, 4)
  }
  StandardpopupUi(idval) {
    setTimeout(function () {
      $(idval).attr('style', 'width: 96%;padding-bottom:0px;min-height:92%;overflow:auto;height:92%;padding:0;top:8%;border:3px solid #FF5F15;left:3.2%;');
    }, 4)
  }
  StandardGreenpopupUi(idval) {
    setTimeout(function () {
      $(idval).attr('style', 'width: 96%;padding-bottom:0px;min-height:92%;overflow:auto;height:92%;padding:0;top:8%;border:4px solid #badb98;left:3.2%;');
    }, 4)
  }
  hideSearchOption() {
    this.hideSubButton = false;
  }

  loadSearchdropdowns() {
    this.customerService.GetSpecialityList("Searchval", this.currentuser.id).then((Rows) => {
      this.SearcValueList = Rows;

      if (this.SearchType == "contactName") {
        this.SearcValueModel = this.SearcValueList.filter(x => x.itemName == 'Contact ' + this.selectedval);
      } else {
        this.SearcValueModel = this.SearcValueList.filter(x => x.itemName == 'Account ' + this.selectedval);
      }

    });
    this.customerService.GetSpecialityList("SearchTypeval", this.currentuser.id).then((Rows) => {
      this.SearchTypeList = Rows;

      var CustType = (this.customertype == '1') ? 'Customer' : (this.customertype == '2') ? 'Former Customer' : (this.customertype == '0') ? "Non-Customer" : 'Display All'
      this.SearchTypeModel = this.SearchTypeList.filter(x => x.itemName == CustType);

    });

    this.SearcValueModel = [{ "id": 702, "itemName": "Account Name" }];
    this.SearchTypeModel = [{ "id": 712, "itemName": "Display All" }];
   // this.loadallaccounts(this.UserIds, false, 1, 10000, 0);
  }
 
  loadallaccounts(userids, isSearch: boolean, startrow, endrow, accstatus) {
    this.selectedusers = userids;
    //if (this.selectedusers != '' && this.selectedusers != null && this.selectedusers != undefined) {
    //  this.customerService.Getselectedusers(this.selectedusers).then((Owner) => {
    //    Owner.forEach(Owner => {
    //    });
    //    this.selectedItems = Owner;
    //  })
    //}
    //else {
    //  this.selectedItems = [{ "id": this.currentuser.id, "itemName": this.currentuser.username }];
    //  this.finalitems += this.currentuser.id + ',';
    //}
    this.searchstring = "";
    this.searchCriteriadata = [];
    if (this.term == "") {
      this.term = null;
    }
    this.allCustomers = [];
    this.opprtunityservice.GetAllAccountsBySearch(this.currentuser.id, this.term, this.selectedItems.toString(), 'Accounts', (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, startrow, endrow, this.customertype, accstatus).then((account) => {
      if (account.length > 0) {
        account.forEach(account => {
        });
        
          this.allCustomers = account;
       
        this.gridApi.paginationSetPageSize(Number(this.allCustomers.length));
        //this.initializeAgGrid();

      } else {
        this.allCustomers = [];
      }

    });

  }

  SearcValueItemSelect(item) {
    this.selectedval = item.itemName.split(' ')[1];
    if (item.value.startsWith('Account')) {
      this.SearchType = "accountName";
      this.SearchResultTable = 'Account';
      if (this.term != "") {
        this.loadallaccounts(this.UserIds, false, 1, 10000, 0);

      } else {
        this.allCustomers = [];
      }
    }
    else {
      this.SearchType = "contactName";
      this.SearchResultTable = 'Contact';
      if (this.term == '') this.term = null;
      this.GetContactList(this.term, this.UserIds, 0);
     this.SearchResultTable = 'Contact';
    }
    this.SaveQuickSearchConfigToLocal();
  }
  SearchTypeItemSelect(item) {
    this.customertype = item.value == "Display All" ? '0,1,2' : item.value == "Customer" ? '1' : item.value == "Former Customer" ? '2' : '0';

    this.SaveQuickSearchConfigToLocal()
  }

  GetContactList(term, userid, active_deactiveStatus?: number) {
    this.opprtunityservice.GetAllContactsbySearchPagination(this.currentuser.id, term, userid, 'Contacts', active_deactiveStatus, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, this.customertype).then((contact) => {
      if (contact.length > 0) {
        contact.forEach(contact => {
          contact.created = this.datePipe.transform(contact.created, "MM-dd-yyyy");
          contact.speciality = ((contact.speciality == 'null') ? "" : contact.speciality);
        });

        if (this.IncludeShared == 'NotShared') {
          var filterCon = [];
          contact.forEach(ele => {
            if (ele.userid == this.currentuser.id) {
              filterCon.push(ele)
            } else {
              if (ele.isshared == 0) {
                filterCon.push(ele)
              }
            }

          });

          //this.allContacts = contact.filter(con => (con.userid == this.currentuser.id)? '': con.isshared == 0);
          this.allContacts = filterCon;
        } else {
          this.allContacts = contact;
        }
        //this.gridApi.paginationSetPageSize(Number(this.allCustomers.length));
        this.initializeAgGrid();
        this.gridApi.paginationSetPageSize(Number(this.allContacts.length));
      } else {
        this.allContacts = [];
      }

    });
  }

  SaveQuickSearchConfigToLocal() {
    this.QuickSearchConfig = [{ 'selectedval': this.selectedval, 'SearchType': this.SearchType, 'customertype': this.customertype, 'IncludeShared': this.IncludeShared }];
    localStorage.setItem('QuickSearchConfig', JSON.stringify(this.QuickSearchConfig[0]));
  }
  LiveChat() {
    this.ChatDialog = true;
    this.Standard3dotUitwo('#ChatDialog .dialog');
   
  }
  onChatUserMsg(msg) { }
  onTaskSaved(msg) {
    if (msg == 'Task Added') {
      this.taskDialog = false;
    } else {
      this.taskDialog = !this.taskDialog;
      this.taskDialogCss();
      this.SelectedDate = null;
    
      var url = window.location.href;
      if (url.indexOf('account') > -1 && msg == 'CloseAddTask') {
        this.router.navigateByUrl(`/application/account/Accounts`);
        localStorage.setItem('RhxAccountVal', this.id.toString());
      }
      else if (url.indexOf('contacts') > -1 && msg == 'CloseAddTask') {
        let SO = this;
        $(setTimeout(function () {
          SO.activateSubButtons('empty');
          $(setTimeout(function () {
            SO.router.navigateByUrl(`/application/contacts/contacts/null`);
          }, 100));
        }, 50));

        localStorage.setItem('RhxContactVal', this.id.toString());

      }
      else {
        if (url.indexOf('account') > -1) {

          let selfobj = this;
          $(setTimeout(function () {
            selfobj.router.navigateByUrl(`/application/account/Accounts`);
          }, 50));
          selfobj.router.navigateByUrl(`/application`);

          localStorage.setItem('RhxAccountVal', this.id.toString());
        }
        else {
          if (msg == 'Task Added') { } else { }
          this.router.navigateByUrl(`/application/account/Accounts`);
          localStorage.setItem('RhxAccountVal', this.id.toString());
          let selfobj = this;
          setTimeout(function () {
            selfobj.ExpandSelectedMenu('Accounts');
            $(".subButtons").removeClass("activeButton");
            selfobj.selectedtabid = 'Customer';
          }, 200)
          if (this.type == "Contacts") {
            this.customerService.LoadContactById(this.id).then(conres => {
              localStorage.setItem('RhxAccountVal', conres.accountid.toString());
            });

          }

        }

      }
    }
    this.pageViewed = "";
  }

  taskDialogCss() {
    $(setTimeout(function () {
      $('#DashboardtaskDialog .dialog').attr('style', 'width: 40%;padding: 0;min-height: 63%;height: 100%;top: 0;left: 3%;max-width: 1393px !important;margin-right:0%;');
    }, 4));
  }
  onChatMsg(msg) {
    var values = msg.split('|');
    this.msgMsg = values[0];
    if (values[0] == "OpenUser" || values[0] =="EditUser") {      
      this.loadChatdialog(values[1], values[2], values[3]);
    } 
    else if (values[0] == "ClearGroup") {
      this.groupId = 0; this.groupName = null;
   
    }
    else if (values[0] == "MessageSend") { this.ChatUserDialog = false; }
    else if (values[0] == "ReadAllMsgs") {
      this.loadChatdialog(values[1], values[2], values[3]);
      this.loadchatCount();
    }
    else {
      this.ChatDialog = false;
      this.ChatUserDialog = false;
      var url = window.location.href;
      this.displayheader(url);
    }
  }
  OpenChatbox(touser, touserId, values) {
    if (values != null) {
      if (values.currentTarget.checked) {
        $('#' + touserId + "_button").addClass("activeSublabel");
        this.selectedusername.push(touser);
        this.selecteduserids.push(touserId);
      } else {
        $('#' + touserId + "_button").removeClass("activeSublabel");
        this.selecteduserids = this.selecteduserids;
        const index = this.selecteduserids.indexOf(touserId.toString());
        if (index !== -1) {
          this.selecteduserids.splice(index, 1);
        }
      }
    } else {
      $('#' + touserId + "_button").addClass("activeSublabel");
      const index = this.selecteduserids.indexOf(touserId);
      if (index == -1) {
        this.selectedusername.push(touser);
        this.selecteduserids.push(touserId);
      }
    }
  }
  StartChat() {
    if (this.selecteduserids.length > 1)
    {
      this.chatComp.loadSelecteduser(this.groupName, this.groupId, this.selectedusername.join(','), this.selecteduserids.join(','), true);
        this.selectedusername = []; this.selecteduserids = [];
        this.ChatUserDialog = false;
    }
    else
    {
      this.customerService.isDuplicateChatsession(this.currentuser.id, this.selecteduserids.join(',')).then((chatuser) => {
      if (chatuser) {
        this.showduplicatechatsessionDiaog = true;
         setTimeout(function () {
      $('#showduplicatechatsessionDiaog .dialog').
        attr('style', 'width: 29%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height:22%;overflow: auto;height: 4%;top:10%;left:7%');
    }, 4);
      }
      else {
        this.chatComp.loadSelecteduser(this.groupName, this.groupId, this.selectedusername.join(','), this.selecteduserids.join(','), true);
        this.selectedusername = []; this.selecteduserids = [];
        this.ChatUserDialog = false;
      }
    })
    }
   
  }
  
  DeleteChatChatMessage(chatid) {
    this.customerService.deleteChat(chatid).then((delmsg) => {
      this.DeleteChatDialog = false;
      this.chatComp.FirstloggedinUserlist = [];
      this.chatComp.sendorReceivemessageUserList(this.currentuser.id, chatid, "", 0);
    });
  }

  EditChat(userids, grpName, grpId) {
    this.selecteduserids = []; this.selectedusername = []; var useridsarray = [];
    useridsarray = userids.split(',');
    useridsarray = useridsarray.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []);
    if (!this.ChatUserDialog) {
      this.ChatUserDialog = true;
      this.loadChatdialog(true,true,true);
      this.userservice.GetAllUsers(0).then((userlist) => {
        this.loggedinUserlist = userlist;       
        this.loggedinUserlist = this.loggedinUserlist.filter(x => x.id != this.currentuser.id);
        this.loggedinUserlist.forEach(user => {
          user.userselected = useridsarray.includes(user.id.toString());
        });
      });
    } else {
      let selfobj = this;
      setTimeout(function () {
        selfobj.loggedinUserlist.forEach(user => {
          $('#' + user.id + "_button").removeClass("activeSublabel");
        });
      }, 100);
     
    }
    this.groupName = grpName;
    this.groupId = grpId;
    this.selecteduserids = userids.split(',');
    setTimeout(function () {
      useridsarray.forEach(id => {
        $('#' + id + "_button").addClass("activeSublabel");
      });
    }, 300);
   
  }

  loadChatdialog(val1, val2, val3) {
    if (val1 == "true" && val2 == "true" && val3 == "true"){
      this.Standard3dotUi('#ChatDialog .dialog');
    } else {
      this.Standard3dotUitwo('#ChatDialog .dialog');
    }
  }
  sendorReceivemessageUserList(userId) {
    this.customerService.GetmessageReceiveList(this.currentuser.id).then((userlist) => {
      userlist.forEach(user => {
        if (user.unreadcount == '0') {
          user.groupName = user.groupName != null && user.groupName != "" ? user.groupName : user.username;
        } else {
          user.groupName = user.groupName != null && user.groupName != "" ? user.groupName : user.username + "(" + user.unreadcount + ")";
        }
      });
      this.loggedinUserlist = userlist;
    });
  }
  Updategroup() {
    this.grpchat.groupId = this.groupId;
    this.grpchat.groupName = this.groupName;
    this.grpchat.createdBy = this.currentuser.id;
    this.grpchat.grpusers = this.selecteduserids.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []).join(',');
    this.customerService.updategroup(this.grpchat).then((user) => {
      this.toastr.success("Group Updated.", "Group");
      this.ChatUserDialog = false; this.ChatEditUserDialog = false;
      this.StartChat();
    });
  }
  onTicketMsg(msg) {
    if (msg == "OpenTicket") {
      setTimeout(function () {
        $('#TicketCreateDialog .dialog').attr('style', 'width: 45%;padding-bottom: 0px;min-height: 92%;overflow: auto;height: 92%;padding: 0;top:8%;left:55%;');
      }, 4);
    } else if (msg == "HideTicket") {
      this.notSelectedCss('Tickets');     
    } else {
      this.TicketCreateDialog = false;
    }
  }

  CRMOK(val) {
    if (val == 'ProposalDelete') {
      this.customerService.DeleteProposalById(this.dialogobj.Idval).then(result => {
      });
      this.activateMainButtons('Proposals');
      this.router.navigateByUrl(`/application/Proposals/Open Proposals`);
    } else if (val == 'Opportunitydelete') {
      this.opprtunityservice.deleteopportunity(this.dialogobj.Idval).then(result => {
      });
      this.activateMainButtons('opportunities');
    } else if (val == 'DELETECHAT') {
      this.DeleteChatChatMessage(this.dialogobj.idvalues);
    }
    else if (val == 'DeleteCallList') {
      this.ShowConfigurationDialog = false;
      this.customerService.Deletecalllist(this.dialogobj.Idval).then((user) => {
        this.activateMainButtons('Call Lists');
    });     
    }
    else if (val == 'AccountTransfer') {
      this.UpdateOwnership();
    }
    else if (val == 'NewAccount') {
      this.showAddEditAccount = false;
      this.pageViewed = "";
    }
    else if (val == 'EditTask') {
      this.router.navigateByUrl(`/application/month-calender/null`);
      $(".subButtons").removeClass("activeButton");
      $("#TaskCalander_button").addClass("activeButton");
      this.crmDialog = false;
    }
    else if (val == 'MyNotes') {
      this.SaveMyNotes();
    }
    else if (val == 'convertcontacttolead') {
      this.customerService.UpdateLeadStatus(this.dialogobj.Idval, this.currentuser.id).then((contact) => {
      });    
      this.ExpandSelectedMenu('leads');
      this.router.navigateByUrl(`/application/leadsdashboard/${this.dialogobj.Idval}/0/null`);
    }
    this.crmDialog = false;
    this.pageViewed = "";
  }
  CRMSAVE(val) {  }
  CRMDISCARD(val) {  }
  CRMCANCEL(val) {
    this.crmDialog = false;
  }
  displayheader(url) {
    if (window.location.href.indexOf("leadsdashboard") != -1) {
      this.selectedbuttonText = "Leads";
    }
    else if (window.location.href.indexOf("dashboard") != -1) {
      this.selectedbuttonText = "DashBoard";
    }
  }

}
