import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { CustomerService } from './../../services/customer.service';
//import { DataTableResource, DataTableTranslations } from 'angular-2-data-table';
import { Router, ActivatedRoute } from '@angular/router';
import { Contacts } from '../../models/contacts';
import { Accounts } from '../../models/accounts';
import { Notes } from '../../models/notes';
import { DatePipe } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  itemResource: any;
  items = [];
  itemCount = 0;
  params: any;
  Ids = [];
  term: string = "";
  currentuser: any;
  subtype: string = "";
  dropdownList = [];
  finalitems = "";
  selectedItems = [];
  dropdownSetting = {};
  contact: Contacts = new Contacts();
  account: Accounts = new Accounts();
  notes: Notes = new Notes();
  notedescription: string;
  notesshowDialog = false;
  contactid: number;
  notesitemResource: any;
  notesitems = [];
  notesitemCount = 0;
  notesparams: any;
  datePipe: DatePipe = new DatePipe("en-US");
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  private gridApi;
  private gridColumnApi;

  private columnDefs;
  private autoGroupColumnDef;
  private rowSelection;
  private rowGroupPanelShow;
  private pivotPanelShow;
  private defaultColDef;

  constructor(private http: HttpClient, private router: Router, private opprtunityservice: OpprtunitiesService, private route: ActivatedRoute, private customerService: CustomerService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.route.params.subscribe(params => {
      this.term = params['term'];
      this.GetContactList(this.term, this.currentuser.id);

    });

  }


  ngOnInit() {
    $('#example').data({
      "scrollY": 200,
      "scrollX": true
    });
    if (document.getElementById('subTab') != undefined && document.getElementById('subTab') != null)
      document.getElementById('subTab').style.display = 'block';
    this.route.params.subscribe(params => {
      this.term = params['term'];
      this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1,0,0).then((Owner) => {
        Owner.forEach(Owner => {
        });

        this.dropdownList = Owner;
      })
      if ($("#userids").val() != '') {
        this.customerService.Getselectedusers($("#userids").val()).then((Owner) => {
          Owner.forEach(Owner => {
          });
          this.selectedItems = Owner;
        })
        this.GetContactList(this.term, $("#userids").val());
      }
      else {
        this.GetContactList(this.term, this.currentuser.id);
        this.selectedItems = [
          { "id": this.currentuser.id, "itemName": this.currentuser.username }
        ];
        this.finalitems += this.currentuser.id + ',';
        $("#userids").val(this.currentuser.id);
        this.GetContactList(this.term, this.currentuser.id);
      }



    });
  }

  OpenContactNotes(rowEvent) {
    this.customerService.GetContactById(rowEvent.id).then(result => {
      this.contact = result;
      this.customerService.GetByAccountId(this.contact.accountid).then(result => {
        this.account = result;
      });

    });

    this.GetCustomerNotes(rowEvent.id, 'Contact');
    this.notesshowDialog = !this.notesshowDialog
    $('.dialog').width('1000px');
  }

  Notes(val) {
    this.GetCustomerNotes(this.contact.id, val);
  }
  GetCustomerNotes(contactid, type) {

    this.customerService.GetAllNotesList(this.currentuser.id,contactid, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.notesitemCount = taskhistory.length;
      //this.notesitemResource = new DataTableResource(taskhistory);
      //this.notesitemResource.count().then(count => this.notesitemCount = count);
      //this.notesparams = { offset: 0, limit: 10 }
      //this.notesreloadItems(this.notesparams);
    });

  }

  //notesreloadItems(notesparams) {
  //  if (this.notesitemResource != undefined) {
  //    this.notesitemResource.query(notesparams).then(notesitems => this.notesitems = notesitems);
  //  }
  //}

  enterNotes() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'block';
    document.getElementById('saveNotesButtons').style.display = 'block';
    document.getElementById('divTable').style.display = 'none';
  }
  SaveNote() {
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/img, "");
    this.notes.type = "Contact";
    this.notes.accountid = this.contact.id;

    this.customerService.SaveNotes(this.notes).then(result => {
      this.GetCustomerNotes(this.notes.accountid, 'Contact');
      $('#notesListTabsId .active').removeClass('active');
      $('#notesListTabsId #allNotesId').addClass('active');
      this.CancelNote();
    });
  }
  CancelNote() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'none';
    document.getElementById('saveNotesButtons').style.display = 'none';
    document.getElementById('divTable').style.display = 'block';
  }
  GetContactList(term, userid) {
    this.opprtunityservice.GetAllContactsBySearch(term, userid, 'contacts').then((contact) => {
      contact.forEach(contact => {
      });
      this.itemCount = contact.length;
      //this.itemResource = new DataTableResource(contact);
      //this.itemResource.count().then(count => this.itemCount = count);
      //this.params = { offset: 0, limit: 10 }
      //this.reloadItems(this.params);
    });
  }
  onItemSelect(item: any) {
    var ids = item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.GetContactList(this.term, $("#userids").val());


  }
  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.GetContactList(this.term, $("#userids").val());
    } else {
      this.items = [];
      this.itemCount = 0;
      $("#userids").val('');
    }
  }
  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.GetContactList(this.term, $("#userids").val());
  }
  onDeSelectAll(items: any) {

    this.finalitems = "";
    this.items = [];
    this.itemCount = 0;
    $("#userids").val('');
  }
 
  rowClick(rowEvent) {
    if ((this.Ids).indexOf(rowEvent.row.item.id) > -1) {
      var index = (this.Ids).indexOf(rowEvent.row.item.id);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
    } else {
      this.Ids.push(rowEvent.row.item.id)
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
    }
  }
  rowDoubleClick(rowEvent) {
    this.router.navigateByUrl(`/application/contactsdashboard/${rowEvent.row.item.id}`);
  }
  reloadItems(params) {
    if (this.itemResource != undefined) {
      this.itemResource.query(params).then(items => this.items = items);
    }
  }
}
