import { Component, OnInit, ViewContainerRef, ViewEncapsulation, ElementRef, Renderer2, ViewChild, Optional, Input, EventEmitter, Output } from '@angular/core';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { LeadsService } from './../../services/leads.service';
import { Users } from '../../models/users';
import { Userquota } from '../../models/userquota';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ApplicationComponent } from '../application.component';
declare var $: any;
@Component({
  selector: 'userquota',
  templateUrl: './user-quota.component.html',
  styleUrls: ['./user-quota.component.css']
})
export class UserQuotaComponent implements OnInit {
  public ActivityQuotagridOptions: GridOptions;
  public SalesQuotagridOptions: GridOptions;
  public context;
  //SalesQuotahgt = screen.height * 0.27444;
    //actQuotahgt= screen.height*0.27778
  currentuser: any;
  aggridheight: string;
  user: Users = new Users();
  userquota: Array<Userquota> = [];;
  public elementRef;
  public ActivityQuotacolumnDefs: any[];
  ActivityQuotadata: Array<Userquota> =[];
  SalesQuotadata: any[];
  public SalesQuotacolumnDefs: any[];
  showActivityQuota: boolean = false;
  showSalesQuota: boolean = false;
  activityGridAPI: any;
  salesGridAPI: any;
  isActivityQuota = false;
  isSalesQuota = false;
  public years: number[] = [];
  public yy: number;
  public gridactivityColumnApi;
  currentMonth = Number(new Date().getMonth()+1);
  @Input()
  userid: number;
  @Input()
  type: string;
  Dashboardtypeval: number = 0;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  public sideBar;
  constructor(private leadservice: LeadsService, private viewContainerRef: ViewContainerRef, private router: Router, myElement: ElementRef, private http: HttpClient, private userservice: UserService, private toastr: ToastrService, public applicationComponent: ApplicationComponent) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.user.userrole = null;
    this.elementRef = myElement;
  }

  ngOnInit() {  
    this.getYear();
    //if (this.type == "TaskQuota") {
    //  this.LoadActivityQuotaData(this.currentuser.id, (new Date()).getFullYear());
    //} else {
    //  this.LoadSalesQuotaData(this.currentuser.id, (new Date()).getFullYear());
    //}

    $('#saveSales').prop('disabled', true);
    $('#saveActivity').prop('disabled', true);

  }
  TaskQuota() { this.ActivityQuotacreateColumnDefsusers(); }
  SaleQuota() { this.SalesQuotacreateColumnDefsusers("");}
  getYear() {
    var today = new Date();
    this.yy = today.getFullYear();
    for (var i = (this.yy - 100); i <= this.yy; i++) {
      this.years.push(i);
    }
  }
  onCellValueChanged(event) {
    this.sendMsgEvent.emit("Changed");
  }
  SaveSalesQuotaData() {
    
    var rowdata = [];
    if (this.salesGridAPI != undefined) {
      
      this.salesGridAPI.forEachNode(function (node) {
        rowdata.push(node.data);
      });
    }
    $('#saveSales').prop('disabled', true);
    $('#saveSales').css("background", "#d3d3d3");
    this.saveQuotadata(rowdata, $("#salesyearselected").val());
    //this.applicationComponent.useraccountChange = false;
  }
  SaveActivityQuotaData() {
    var rowdata = [];
    if (this.activityGridAPI != undefined) {
      this.activityGridAPI.forEachNode(function (node) {
        rowdata.push(node.data);
      });
      //this.saveQuotadata(rowdata);
    }
    if (this.salesGridAPI != undefined) {     
      this.salesGridAPI.forEachNode(function (node) {
        rowdata.push(node.data);
      });      
    }
    $('#saveActivity').prop('disabled', true);
    $('#saveActivity').css("background", "#d3d3d3 !important");
    this.saveQuotadata(rowdata, $("#activityyearselected").val());
    //$('#saveActivity').prop('disabled', true);
    //$('#saveActivity').css("background", "#d3d3d3 !important");
    //this.applicationComponent.useraccountChange = false;
  }
  saveQuotadata(rowdata, year) {
    this.userquota = [];
    for (let user of rowdata) {
      user.userid = this.userid;
      this.userquota.push(user);
    }
    this.userquota[0].year = year;
    this.userservice.addquota(this.userquota).then(result => {
      this.toastr.success("User Quota saved successfully.", "User Quota Saved", { timeOut: 600 });
      this.applicationComponent.SelectedTab = 'tab4';
      this.sendMsgEvent.emit("Close");
      //this.applicationComponent.useraccountChange = false;
    });
  }
  initializeActivityQuotaAgGridConfiguration() {
    this.ActivityQuotagridOptions = <GridOptions>{
      pagination: false, enableColResize: true, headerHeight: 40, enableSorting: true, enableFilter: false, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single', showToolPanel: false, toolPanelSuppressColumnFilter: false, floatingFilter: false

    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.ActivityQuotacreateColumnDefsusers();
    this.ActivityQuotagridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  ActivityQuotacreateColumnDefsusers() {

    //const MonthNames = ["Jan", "Feb", "Mar", "apr", "may", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    //const d = new Date();


    this.ActivityQuotacolumnDefs = [
      { field: 'Activity', headerName: 'Activity', hide: false, filter: false, suppressFilter: true, suppressSorting: true, suppressMenu: true  },
      {
        field: 'goalmonthly', headerName: 'Goal (Monthly)', hide: false, headerClass: 'GoalHeaderclass', cellStyle: { color: 'red', cursor: 'text' }, editable: true,
        valueSetter: ActivitynameValueSetter, width: 100, filter: false, suppressFilter: true, suppressSorting: true, suppressMenu: true
      },
      { field: 'Jan', headerName: 'Jan', hide: false, headerClass: (this.currentMonth == 1) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, filter: false, suppressFilter: true, suppressSorting: true, suppressMenu: true },
      { field: 'JanPercenatge', headerName: '%', hide: !this.isActivityQuota, width: 65, cellStyle: { color: 'blue' }, filter: false, suppressFilter: true, suppressSorting: true, suppressMenu: true  },
      { field: 'Feb', headerName: 'Feb', hide: false, headerClass: (this.currentMonth == 2) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressFilter: true, suppressSorting: true, suppressMenu: true },
      { field: 'FebPercenatge', headerName: '%', hide: !this.isActivityQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Mar', headerName: 'Mar', hide: false, headerClass: (this.currentMonth == 3) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true },
      { field: 'MarPercenatge', headerName: '%', hide: !this.isActivityQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Apr', headerName: 'Apri', hide: false, headerClass: (this.currentMonth == 4) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true },
      { field: 'AprilPercenatge', headerName: '%', hide: !this.isActivityQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'May', headerName: 'May', hide: false, headerClass: (this.currentMonth == 5) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true  },
      { field: 'MayPercenatge', headerName: '%', hide: !this.isActivityQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Jun', headerName: 'June', hide: false, headerClass: (this.currentMonth == 6) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true  },
      { field: 'JunPercenatge', headerName: '%', hide: !this.isActivityQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Jul', headerName: 'July', hide: false, headerClass: (this.currentMonth == 7) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true   },
      { field: 'JulPercenatge', headerName: '%', hide: !this.isActivityQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Aug', headerName: 'Aug', hide: false, headerClass: (this.currentMonth == 8) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true  },
      { field: 'AugPercenatge', headerName: '%', hide: !this.isActivityQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Sep', headerName: 'Sep', hide: false, headerClass: (this.currentMonth == 9) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true  },
      { field: 'SepPercenatge', headerName: '%', hide: !this.isActivityQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Oct', headerName: 'Oct', hide: false, headerClass: (this.currentMonth == 10) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true  },
      { field: 'OctPercenatge', headerName: '%', hide: !this.isActivityQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Nov', headerName: 'Nov', hide: false, headerClass: (this.currentMonth == 11) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true  },
      { field: 'NovPercenatge', headerName: '%', hide: !this.isActivityQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Dece', headerName: 'Dec', hide: false, headerClass: (this.currentMonth == 12) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true  },
      { field: 'DecPercenatge', headerName: '%', hide: !this.isActivityQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },

    ];
    function ActivitynameValueSetter(params) {
      //this.applicationComponent.useraccountChange = true;
      params.data.goalmonthly = Number(params.newValue);
      params.data.JanPercenatge = ((params.data.Jan / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.FebPercenatge = ((params.data.Feb / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.MarPercenatge = ((params.data.Mar / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.AprilPercenatge = ((params.data.Apr / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.MayPercenatge = ((params.data.May / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.JunPercenatge = ((params.data.Jun / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.JulPercenatge = ((params.data.Jul / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.AugPercenatge = ((params.data.Aug / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.SepPercenatge = ((params.data.Sep / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.OctPercenatge = ((params.data.Oct / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.NovPercenatge = ((params.data.Nov / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.DecPercenatge = ((params.data.Dece / Number(params.newValue)) * 100).toFixed(2) + '%';
    }
 
    function formatNumber(number) {
      return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

  }
  LoadActivityQuotaData(selecteduserid, selectedyear) {    
    this.ActivityQuotadata = [];
    if (selecteduserid = 'undefined' ? this.currentuser.id : this.userid)
      this.leadservice.GetAllActivityQuotaList(this.userid.toString(), 'Activity', selectedyear).then((Owner) => {
      Owner.forEach(Owner => {
      });
        this.ActivityQuotadata = Owner;
    });

    this.initializeActivityQuotaAgGridConfiguration();
    
  }
  LoadSalesQuotaData(selecteduserid, selectedyear) {
    this.initializeSalesQuotaAgGridConfiguration();
    this.SalesQuotadata = [];
    if (selecteduserid = 'undefined' ? this.currentuser.id : selecteduserid)
      this.leadservice.GetAllActivityQuotaList(this.userid.toString(), this.type, selectedyear).then((SalesQuotadata) => {
        SalesQuotadata.forEach(SalesQuotadata => {
        });
        this.SalesQuotadata = SalesQuotadata;
      });

  }
  onActivityQuotaGridReady(event) {
    this.activityGridAPI = event.api;
    this.gridactivityColumnApi = event.columnApi;
    $(setTimeout(function () {
    }, 1000));
   
  }
  autoSizeAllActivityQuota() {
    var allColumnIds = [];
    this.gridactivityColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridactivityColumnApi.autoSizeColumns(allColumnIds);
    
  }
  onSalesQuotaGridReady(event) {
    this.salesGridAPI = event.api;
  }
 
  initializeSalesQuotaAgGridConfiguration() {
    this.SalesQuotagridOptions = <GridOptions>{
      pagination: false, enableColResize: true, headerHeight: 40, enableSorting: true, enableFilter: false, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single', toolPanelSuppressColumnFilter: false, floatingFilter: false

    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.SalesQuotacreateColumnDefsusers("");
    this.SalesQuotagridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  SalesQuotacreateColumnDefsusers(columnSelections: string) {

    this.SalesQuotacolumnDefs = [
      {
        field: 'Activity', headerName: 'Activity', hide: false, filter: false, width: 150, suppressSorting: true, suppressMenu: true, },
      {
        field: 'goalmonthly', headerName: 'Goal (Monthly)', hide: false, headerClass: 'GoalHeaderclass', cellStyle: { color: 'red', cursor: 'text' }, editable: true,
        valueSetter: nameValueSetter, width: 120, filter: false, suppressFilter: true, suppressSorting: true, suppressMenu: true, ColId: 'goalMonthly', type: 'valueColumn'
        ,
      },
      {
        field: 'Jan', headerName: 'Jan', hide: false, headerClass: (this.currentMonth == 1) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, filter: false, suppressFilter: true, suppressSorting: true,suppressMenu: true, },
      { field: 'JanPercenatge', headerName: '%', hide: !this.isSalesQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      {
        field: 'Feb', headerName: 'Feb', hide: false, headerClass: (this.currentMonth == 2) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, filter: false, suppressFilter: true, suppressSorting: true,suppressMenu: true,},
      { field: 'FebPercenatge', headerName: '%', hide: !this.isSalesQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true},
      {
        field: 'Mar', headerName: 'Mar', hide: false, headerClass: (this.currentMonth == 3) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, filter: false, suppressFilter: true, suppressSorting: true,suppressMenu: true, },
      { field: 'MarPercenatge', headerName: '%', hide: !this.isSalesQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Apr', headerName: 'Apri', hide: false, headerClass: (this.currentMonth == 4) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, filter: false, suppressFilter: false},
      { field: 'AprilPercenatge', headerName: '%', hide: !this.isSalesQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true  },
      { field: 'May', headerName: 'May', hide: false, headerClass: (this.currentMonth == 5) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true},
      { field: 'MayPercenatge', headerName: '%', hide: !this.isSalesQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Jun', headerName: 'June', hide: false, headerClass: (this.currentMonth == 6) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true},
      { field: 'JunPercenatge', headerName: '%', hide: !this.isSalesQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Jul', headerName: 'July', hide: false, headerClass: (this.currentMonth == 7) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65,suppressSorting: true, suppressMenu: true},
      { field: 'JulPercenatge', headerName: '%', hide: !this.isSalesQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Aug', headerName: 'Aug', hide: false, headerClass: (this.currentMonth == 8) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true},
      { field: 'AugPercenatge', headerName: '%', hide: !this.isSalesQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Sep', headerName: 'Sep', hide: false, headerClass: (this.currentMonth == 9) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true},
      { field: 'SepPercenatge', headerName: '%', hide: !this.isSalesQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Oct', headerName: 'Oct', hide: false, headerClass: (this.currentMonth == 10) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true},
      { field: 'OctPercenatge', headerName: '%', hide: !this.isSalesQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true},
      { field: 'Nov', headerName: 'Nov', hide: false, headerClass: (this.currentMonth == 11) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true},
      { field: 'NovPercenatge', headerName: '%', hide: !this.isSalesQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },
      { field: 'Dece', headerName: 'Dec', hide: false, headerClass: (this.currentMonth == 12) ? 'ActivityQuotaHeaderclassCurrentMonth' : 'ActivityQuotaHeaderclass', width: 65, suppressSorting: true, suppressMenu: true},
      { field: 'DecPercenatge', headerName: '%', hide: !this.isSalesQuota, width: 65, cellStyle: { color: 'blue' }, suppressSorting: true, suppressMenu: true },

    ];
    function numberValueParser(params) {
      return Number(params.newValue);
     
    }
    function nameValueSetter(params) {     
      params.data.goalmonthly = Number(params.newValue);
      params.data.JanPercenatge = ((params.data.Jan / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.FebPercenatge = ((params.data.Feb / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.MarPercenatge = ((params.data.Mar / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.AprilPercenatge = ((params.data.Apr / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.MayPercenatge = ((params.data.May / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.JunPercenatge = ((params.data.Jun / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.JulPercenatge = ((params.data.Jul / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.AugPercenatge = ((params.data.Aug / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.SepPercenatge = ((params.data.Sep / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.OctPercenatge = ((params.data.Oct / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.NovPercenatge = ((params.data.Nov / Number(params.newValue)) * 100).toFixed(2) + '%';
      params.data.DecPercenatge = ((params.data.Dece / Number(params.newValue)) * 100).toFixed(2) + '%';
     // this.sendMsgEvent.emit("Change");
    }
    function formatNumber(number) {
      return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  }
  
  Dashboardtype(type) {
    this.Dashboardtypeval = type;
    if (type == "userquota") {
      if (this.user.Quotaid == 445) {
      } else {
        alert("This user Quota is set to “No – Quota”, Please change the User set up to Yes – Quota if you want to add a Quota for the User");
      }      
    }
    else {
      //this.initializeAgGrid();
    }
    if (type == 2) {
      document.getElementById('saveSales').style.display = 'none';
      document.getElementById('saveActivity').style.display = 'none';
      return false;
    }
   // $('.dashboardConfigButtonsSelected').attr('class', 'dashboardConfigButtons');
   // $('#' + type).attr('class', 'dashboardConfigButtonsSelected');
  }

  SelectedYear(year) {
    this.LoadSalesQuotaData(this.userid, year);
  }
  ActivitySelectedYear(year) {
    this.LoadActivityQuotaData(this.userid, year);
  }

  agGridRowSelected(event) {
    $('#saveSales').css("background", "#93d250");
    $('#saveSales').prop('disabled', false);
  }
  agGridActivityRowSelected(event) {
    $('#saveActivity').css("background", "#93d250");
    $('#saveActivity').prop('disabled', false);
  }
  closedUserQuotaEventListener() {
    var tab = localStorage.getItem('PrevUserConfigTab');
    if (this.applicationComponent.isClosedUserQuotaEventListener) {
      if (tab == "tab4") {
        this.SaveSalesQuotaData();
        this.SaveActivityQuotaData();
      }
      this.applicationComponent.isClosedUserQuotaEventListener = false;
      this.applicationComponent.isUserQuota = false;

    }
    return false;
  }
  
}
