import { StatusItems } from "./StatusItems";

export class SalesStages {
  public SalesStageID: number;
  public SaleStage: string;
  public ForeCastDescription: number;
  public SkipStage: number;
  public SortOrder: number;
  public CreatedBy: number;
  public CreatedDate: Date;
  public isDeleted: number;
  public StatusType: any;
  public TriggerType: any;

  public StatusItems: StatusItems[];
  public completeddate: string;
  public status: string;
  public leadstatus: string;
  public notesDescription: string;
  public id: number;
}
