import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { BrowserModule } from '@angular/platform-browser';

import * as $ from 'jquery'; window["$"] = $; window["jquery"] = $;
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-061347}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Salesxcrm}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{SalesXcrm}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{SalesXcrm}_need_to_be_licensed___{SalesXcrm}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{8_August_2025}____[v3]_[01]_MTc1NDYwNzYwMDAwMA==569bd60630486a01d527dedb5636c5a5");
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
