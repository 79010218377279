
        <div class="col-xs-12" style="height: 100%;margin-top: 0%;text-align:left;padding:0;">
          <div style="height:100%;padding:0;" class="col-xs-12">
            <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="aboutus">
              <div class="col-xs-12" style="background-color: rgb(186,219,152); height: 2% ">
              
              </div>
              <div class="col-xs-11" style="height: 4%;text-align: left">
                <label style="padding: 3% 0% 0% 0%; text-transform: uppercase; ">About us</label>
              </div>
              <div class="col-xs-1" (click)="close();" style="height: 4%;text-align: right">
                <label style="padding: 3% 0% 0% 0%; text-transform: uppercase;">
                <img src="../../../assets/img/Black x.png" style="height: 20px;margin-top: 50%;" /></label>

              </div>
            
              <div class="col-xs-12" style="padding:0;height:19%">
                <img src="../../assets/img/SalesXCRM Logo with New Slogan.png" style="height:95px;margin-top:1.5%" />

              </div>
              <div class="col-xs-12" style="padding:0;height:64%">
                <div class="col-xs-12" style="height:100%;">
                  <label style="font-weight: normal; word-spacing: 2px; font-size: 16px; padding-top: 4%;">
                    The SalesXCRM Software is engineered to automate the core functions of today's businesses.
                  </label>
                  <br />
                  <label style="font-weight: normal; word-spacing: 2px; font-size: 16px; padding-top: 4%;">
                    Our mission is to remove the complexity found in other CRM systems and design solutions that drive productivity at an affordable cost.
                  </label>
                  <br />
                  <label style="font-weight: normal; word-spacing: 2px; font-size: 16px; padding-top: 4%;">
                    Our founders have vast experience in operating successful businesses and understand the importance of implementing productive technology. We strive to create software that our users look forward to utilizing each day.
                  </label>
                  <br />
                  <label style="font-weight: normal; word-spacing: 2px; font-size: 16px; padding-top: 4%;">
                    Whether your team is prospecting for new business or providing service and support for current customers, the SalesXCRM Software provides innovative CRM solutions designed to automate and grow your business.
                  </label><br />
                  <label style="font-weight: normal; word-spacing: 2px; font-size: 16px; padding-top: 4%;">
                    Thank you for being a valued client.
                  </label><br />
                  <label style="font-family: 'Bradley Hand ITC'; font-size: 20px; padding-top: 7%; ">
                    The SalesXCRM Team
                  </label>
                </div>

              </div>
              <!--<div class="col-xs-12" style="padding: 0; height: 10%; display: flex; width: 64%;">
    <div class="col-xs-12"><img src="../../assets/img/Release Notes.png" (click)="ReleaseNotesShow()" style="height:60px;" /></div>
    <div class="col-xs-12"><img src="../../assets/img/My Subscription.png" (click)="MySubscribtion()" style="height: 60px;" /></div>
    <div class="col-xs-12"><img src="../../assets/img/Contact Us.png" (click)="ShowContactus()" style="height: 60px;" /></div>
  </div>-->
            </div>

              <div class="col-xs-12" *ngIf="releaseNotesDiv" style="padding:0;height:97%;">

                <app-release-notes (sendMsgEvent)="closeReleaseNotesDiv($event)"></app-release-notes>
              </div>

              <div class="col-xs-12" *ngIf="subscriptionDiv" style="padding:0;height:97%;">

                <app-subscription (sendMsgEvent)="closesubscriptionDiv($event)"></app-subscription>
              </div>

              <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ContactUs">
                <app-contact-us (sendMsgEvent)="CancelContactUs($event)"></app-contact-us>

              </div>

              <div *ngIf="!ContactUs" class="col-xs-3" style="padding:0;height:100%;border-left:1px solid #e4dcdc;width:20%;display:none">
                <div class="col-xs-12" style="padding:0;height:25%">
                  <span style="float:right;margin:1%" (click)="close()" *ngIf="aboutus"> <img src="../../../assets/img/Black x.png" style="height:22px" /></span>
                  <div class="col-xs-12" style="text-align: left;padding:1%;height:85%">
                    <div style="padding:4% 0 0 22%;color:black;font-size:11pt;text-align:left">
                      <div>Current Version: {{'v' + appVersion}}</div>  <div>Release Date: {{today}}</div>
                    </div>
                  </div>
                </div>

                <div class="col-xs-12" style="height:75%;padding:0;">

                  <div class="col-xs-12" style="height: 100%; margin-top: 0%;text-align:center;padding:0;">
                    <div class="col-xs-12" style="margin-top:1%"><img src="../../assets/img/Release Notes.png" (click)="ReleaseNotesShow()" style="height:86px;width:33%;" /></div>
                    <div class="col-xs-12" style="margin-top:10%"><img src="../../assets/img/My Subscription.png" (click)="MySubscribtion()" style="height:86px;width:33%;" /></div>
                    <div class="col-xs-12" style="margin-top:10%"><img src="../../assets/img/Contact Us.png" (click)="ShowContactus()" style="height:86px;width:33%;" /></div>

                    <div class="col-xs-12" style="padding:0;height:10%;margin-top:36%;font-size:11.5px">
                      <div class="col-xs-12" style="text-align: center;padding:1%;height:100%">

                        2017-2021 SalesXCRM all Rights Reserved<br />
                        SalesXCRM is a Registered Trademark<br />

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
         
          </div>
        <app-dialog [(visible)]="SearchNotAvailable" id="SearchNotAvailable" [closable]="false">
          <div class="col-xs-12">
            <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
            <span style="font-size:15px;">Feature</span><br />
            <div style="padding:0;margin-top:1%">
              <span style="font-size:15px;">Not Available at this time</span><br />
            </div>
            <button class="btn" (click)="SearchNot()" style="float:right;background-color: #0098f7 !important;color: white;margin-top:-5%">OK</button>
          </div>

        </app-dialog>
