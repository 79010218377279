import { Component, OnInit, ViewContainerRef, ViewEncapsulation, ElementRef, Renderer2, ViewChild, Optional, Input } from '@angular/core';
import { LeadsService } from '../../../services/leads.service';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../../services/customer.service';
import { Notes } from '../../../models/notes';
import { Contacts } from '../../../models/contacts';
import { Customers } from '../../../models/customers';
import { Accounts } from '../../../models/accounts';
import { OpprtunitiesService } from '../../../services/opprtunities.service';
import { ParentComp, UserlistComponent } from "../../userlist/userlist.component";
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { NotesrendererComponent } from '../../notesrenderer/notesrenderer.component';
import { ScheduletaskComponent } from "../../scheduletask/scheduletask.component";
import { Dashboardconfiguration } from '../../../models/dashboardconfiguration';
import { Column } from 'ag-grid';
import { TaskHistory } from '../../../models/task-history';
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../../application.component';
import * as moment from 'moment'
import { NotesComponent } from '../../notes/notes.component';
import { AccountsService } from 'src/app/services/accounts.service';
import { Dropdowntype } from 'src/app/models/dropdowntype';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { Viewaccount } from 'src/app/models/viewaccount';
import { AuditLogModel } from '../../../models/auditlog';
import { UserdataheaderComponent } from '../../userdataheader/userdataheader.component';
declare var require: any;
declare var $: any;
@Component({
  selector: 'app-tasklistdashboard',
  templateUrl: './tasklistdashboard.component.html',
  styleUrls: ['./tasklistdashboard.component.css']
  //encapsulation: ViewEncapsulation.None
})
export class TasklistdashboardComponent implements OnInit {
  resizeheight = (window.innerHeight / 1.542) + 'px';
  datePipe: DatePipe = new DatePipe("en-US");
  itemResource: any;
  items = [];
  itemCount = 0; term: string = "";
  params: any;
  notesitemResource: any;
  notesitems = [];
  notesitemCount = 0;
  notesparams: any;
  showNotesDialog = false;
  dialogOpen: boolean = false;
  contactid: number;
  currentuser: any;
  dropdownSetting = {};
  userprevilages: any;
  dropdownList = []; finalitems = ""; selectedItems = [];
  notedescription: string;
  tasktype: string;
  notes: Notes = new Notes();
  taskhistoryid: number;
  contact: Contacts = new Contacts();
  account: Accounts = new Accounts();
  customer: Customers = new Customers();
  accountdiv: boolean = true;
  customerdiv: boolean = true;
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  thisComp: ParentComp = ParentComp.TaskListDash;
  isViewInitialized: boolean = false;
  isPgClickRegistered: boolean = false;
  alltaskhistory: TaskHistory[];
  taskhistory: TaskHistory = new TaskHistory();
  public gridOptions: GridOptions;
  public gridOptionsnotes: GridOptions;
  public rowData: any[];
  public columnDefs: any[];
  public columnDefsnotes: any[];
  pageSize = "1000";
  public context;
  public frameworkComponents;
  aggridheight: string;
  allnotes: Notes[];
  typ: string; id: number;
  type: string; taskid: number;
  conid: number; Contact: string; ContactOpportunity: string; ContactLead: string; AllContactsNotes: string;
  @ViewChild(UserlistComponent) userListComp;
  @ViewChild(ScheduletaskComponent) scheduleComp;
  @ViewChild(NotesComponent) notecomp;

  DisplayrowsList: Dropdowntype[] = [];
  public elementRef;
  NotesDec = false;
  columnSelections: string;
  ShowScheduletask: boolean = false;
  taskhistoryinfo: boolean = true;

  Below30Days: number = 0;
  Betwn31_60Days: number = 0;
  Betwn61_90Days: number = 0;
  Betwn91_120Days: number = 0;
  Above120Days: number = 0;
  Above151Days: number = 0;
  totalPhoneCalls: number = 0;
  totalMeetings: number = 0;
  totalToDos: number = 0;
  ExpandTaskGrid: boolean = false;
  public sideBar;
  daysFilter: boolean = false;
  TaskExpand: boolean = false;
  public pieChartData = [];
  public customerpieChartType: string = 'pie';
  public customerpieChartcolors = [{ backgroundColor: ["#4C7FC0", "	#CA5A4E", "#9DB15A"] }]
  public pieColors = ["#4C7FC0", "	#CA5A4E", "#9DB15A"];
  public customerpieChartLabels = [];
  //public pieChartLabels: string[] = [];
  pieChartLabels = ["Phone Calls", "Meeting", "To Do"];
  //pieChartData = [2, 1, 1];
  OpenOppTotal: string = "$ 0";
  scheduleTaskLoaded: boolean = false;
  notesshowDialog = false;
  isModal: boolean = true;
  DocExpandView = false;
  @Input()
  PageFrom: string = "";
  @Input()
  typeTask: string = this.taskhistory.tasktype;
  taskhistorynotes: Array<Notes> = [];
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");

  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  calanderonoff: boolean = false;
  timezonevalue: string;
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  Ids = [];
  today = Date.now();
  private gridColumnApi;
  userInfo: string;
  isShowDetailsChecked: boolean = false;
  private gridApi;
  viewaccount: Viewaccount = new Viewaccount(); EnableSearch: boolean = false; value = "";
  constructor(private route: ActivatedRoute, public applicationComponent: ApplicationComponent, private renderer: Renderer2, private leadservice: LeadsService, private customerService: CustomerService, private router: Router, private viewContainerRef: ViewContainerRef, private toastr: ToastrService, private opprtunityservice: OpprtunitiesService, myElement: ElementRef, private accountService: AccountsService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {
      this.applicationComponent.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
      ];
      localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
      $("#userids").val(this.currentuser.id);
      this.applicationComponent.ExpandSelectedMenu('tasklists');
    }
    // this.toastr.setRootViewContainerRef(viewContainerRef);
    var loggedInUserId = this.currentuser.id;
    this.customerService.GetPrivilagesByuser(loggedInUserId).then(result => {
      this.userprevilages = result;
    });
    this.elementRef = myElement;
  }
  ngAfterViewInit(): void {
    var thiscomponent = this;

    setTimeout(function () { thiscomponent.ResizeTable(); }, 10);
  }
  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let searcharray = [];
    if (this.gridOptions.quickFilterText != null && this.gridOptions.quickFilterText != undefined && this.gridOptions.quickFilterText != '') {
      searcharray.push(this.gridOptions.quickFilterText);
    }
    let selfobj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections && columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefs.push({ field: 'status', headerName: 'Status', getQuickFilterText: () => '', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) { if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') { return { color: '#FF0000' }; } } });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "datecompleted") {
          this.columnDefs.push({
            field: 'datecompleted', headerName: 'Date Completed', getQuickFilterText: () => '',hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              if (params.data.status == 'Open' || params.value == "") { return ""; } else {
                return moment(params.value).format('MM-DD-YYYY');
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "datecreated") {
          this.columnDefs.push({
            field: 'datecreated', headerName: 'Date Created', getQuickFilterText: () => '',hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') {
                return { color: '#FF0000' }
              }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "estcompleteddate" || fieldHeaderVisibleWidthArray[0] == "DateSched") {
          this.columnDefs.push({
            field: 'estcompleteddate', headerName: 'Date Scheduled', getQuickFilterText: () => '', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') {
                return { color: '#FF0000' }
              }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "accountname") {
          this.columnDefs.push({
            field: 'accountname', headerName: 'Account Name', getQuickFilterText: () => '',hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "firstName") {
          this.columnDefs.push({
            field: 'firstName', headerName: 'First Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(' ');
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "lastName") {
          this.columnDefs.push({
            field: 'lastName', headerName: 'Last Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(' ');
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "time") {
          this.columnDefs.push({
            field: 'starttime', headerName: 'Start Time', getQuickFilterText: () => '', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            cellRenderer: function (params) {
              if (params.value == '00:00:00' || params.value == null) { return "" } else {
                return params.value;
              }
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "totime") {
          this.columnDefs.push({
            field: 'endtime', headerName: 'End Time', getQuickFilterText: () => '', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            cellRenderer: function (params) {
              if (params.value == '00:00:00' || params.value == null) { return "" } else {
                return params.value;
              }
            }
          });

        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1], getQuickFilterText: () => '',
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') {
                return { color: '#FF0000' }
              }
            },
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        {
          field: 'estcompleteddate', headerName: 'Date Scheduled', getQuickFilterText: () => '', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') {
              return { color: '#FF0000' }
            }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'accountname', headerName: 'Account Name', getQuickFilterText: () => '', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') {
              return { color: '#FF0000' }
            }
          }, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(' ');
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          },
        },

        {
          field: 'status', headerName: 'Status', getQuickFilterText: () => '', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'tasktype', headerName: 'Type', getQuickFilterText: () => '', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') {
              return { color: '#FF0000' }
            }
          }
        },
        {
          field: 'taskcategory', headerName: 'Task', getQuickFilterText: () => '', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') {
              return { color: '#FF0000' }
            }
          }
        },
        {
          field: 'firstName', headerName: 'First Name', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          },
          cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(' ');
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'lastName', headerName: 'Last Name', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          },
          cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(' ');
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'starttime', headerName: 'Start Time', getQuickFilterText: () => '', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') {
              return { color: '#FF0000' }
            }
          }
          //}, cellRenderer: function (params) {
          //  return moment(params.value).format('MM-DD-YYYY');

          //}
        },
        {
          field: 'endtime', headerName: 'End Time', getQuickFilterText: () => '',hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') {
              return { color: '#FF0000' }
            }
          }
        }, //true starts here
        {
          field: 'datecreated', headerName: 'Date Created', getQuickFilterText: () => '', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') {
              return { color: '#FF0000' }
            }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'datecompleted', headerName: 'Date Completed', getQuickFilterText: () => '', hide: (this.tasktype == 'Open') ? true : false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') { return { color: '#FF0000' }; }
          }, cellRenderer: function (params) {
            if (params.data.status == 'Open' || params.value == "") { return ""; } else {
              return moment(params.value).format('MM-DD-YYYY');
            }
          }
        },
        {
          field: 'owner', headerName: 'Created By', getQuickFilterText: () => '', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status == 'Open') {
              return { color: '#FF0000' }
            }
          }
        },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, pinned: 'right' },
       

      ];

    }
  }
 
  //onAgGridReady(event) {
  //  let selfObj = this;
  //  $(setTimeout(function () {
  //    selfObj.autoSizeAll();
  //    $(setTimeout(function () {
  //      selfObj.autoSizeAll();
  //      selfObj.gridColumnApi = selfObj.gridOptions.columnApi;
  //    }, 100));
  //  }, 100));

  //  //this.columnApi = that.gridOptions.columnApi;
  //}
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  autoSizeAll() {

  }
  txtPageSizeChanged(pageSize) {

    this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    this.saveConfiguration();
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";
    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    this.UpdateSearchColumns(config.columnsselections);
    config.configureduserid = this.currentuser.id;
    config.resourceid = 9;
    config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;
    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    this.opprtunityservice.saveDashboardconfiguration(config);
  }

  initializeAgGrid() {

    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: false, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true,
      toolPanelSuppressPivotMode: true, rowSelection: 'single', enableCellChangeFlash: true, suppressSorting: true, suppressMultiSort: true
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 9).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.gridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }
      this.columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(this.columnSelections);
      this.startDateVal = (config != null && config.startdate != null) ? config.startdate : (new Date().getFullYear() - 1) + '-01-01';
      this.startDatePlaceHolder = (config != null && config.startdate != null) ? 'Start:' + moment(config.startdate).format("MM-DD-YYYY") : 'Start: 01-01-' + (new Date().getFullYear() - 1).toString();
      this.endDateVal = (config != null && config.enddate != null) ? config.enddate : new Date().getFullYear() + '-12-31';
      this.endDatePlaceHolder = (config != null && config.enddate != null) ? 'End:' + moment(config.enddate).format("MM-DD-YYYY") : 'End: 12-31-' + (new Date().getFullYear() - 1).toString();
      this.applicationComponent.SearchColumnList = [];
      this.UpdateSearchColumns(this.columnSelections);
    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
  }
  UpdateSearchColumns(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[];
    if (columnSelections != null)
      allCols = columnSelections.split(',');

    let columnSelectionsArray: any[] = [];
    if (allCols != null) {
      for (let i = 0; i < allCols.length; i++) {
        let fields: string[] = allCols[i].split(':');
        if (fields[2] == 'true' && (fields[1] != '#' && fields[0] != 'notes')) {
          columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
        }
      }
    }

    //this.applicationComponent.ShowSearchTabs('True');
    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];
    if (retrievedObject != null) {
      let obj: any[] = JSON.parse(retrievedObject);
      obj.forEach(element => {
        if (element.subtab == 'OpenTaskHistory' && this.applicationComponent.TaskDataVal == 'Open') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        } else if (element.subtab == 'ClosedTaskHistory' && this.applicationComponent.TaskDataVal == 'Completed') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;

        }
        else if (element.subtab == 'AllTaskHistory' && this.applicationComponent.TaskDataVal == 'All') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        }
        else if (element.subtab == 'NewTaskHistory' && this.applicationComponent.TaskDataVal == 'New') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        } else {
          this.applicationComponent.SearchSelectedItemsLengthChanged = false;

        }


      });

    }
    if (tempTableColumns && tempTableColumns.length > 0) {
      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })

    }
    else {
      this.accountService.GetDefaultColumns('OpenTaskHistory', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.applicationComponent.SearchSelectedItems = [
            { "id": 'accountname', "itemName": 'Account Name' },
            { "id": 'firstName', "itemName": 'First Name' },
            { "id": 'lastName', "itemName": 'Last Name' },
          ];
        }
      });
    }
  }
  onTaskListChanged(rowEvent) {
    if (this.ShowScheduletask) {
      var rowCount = rowEvent.api.getSelectedNodes().length;
      if (rowCount > 0) {
        if (rowEvent.api.getSelectedNodes()[0].data) {
          this.taskhistoryid = rowEvent.api.getSelectedNodes()[0].data.id;
          this.taskhistory = rowEvent.api.getSelectedNodes()[0].data;
          this.leadservice.GetAllTaskHistoryById(Number(this.taskhistoryid), 'TaskHistory').then((items) => {
            this.taskhistorynotes = items;
          });
          this.conid = rowEvent.api.getSelectedNodes()[0].data.contactid;
          let objSelf = this;
          $(setTimeout(function () {
           
            objSelf.scheduleComp.initData(rowEvent.api.getSelectedNodes()[0].data.contactid, rowEvent.api.getSelectedNodes()[0].data.type, rowEvent.api.getSelectedNodes()[0].data.id);
          }, 500));

          
        }
      }
    }
  }
  Display(accId, accountname, addr1, addr2, city, state, zip, country, phone, fax, website, Accountiddata) {

    if (city != null) {
      var cityArray = city.split(' ');
      var newCity = '';
      for (var i = 0; i < cityArray.length; i++) {
        if (newCity == '') {
          newCity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
        else {
          newCity = newCity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
      }
    }
    if (website != null && website.indexOf('www.') >= 0) {
      website = ((website).split('www.')[1])
    } else if (website != null && website.indexOf('ww.') >= 0) {
      website = ((website).split('ww.')[1])
    }


    var properstate = (state != null) ? state.charAt(0).toUpperCase() + state.substr(1).toLowerCase() : "";
    this.viewaccount.accountId = accId;
    this.viewaccount.viewaccountname = accountname;
    this.viewaccount.viewaddress1 = addr1;
    this.viewaccount.viewaddress2 = addr2;
    this.viewaccount.viewcity = newCity == null ? "" : newCity;
    this.viewaccount.viewstate = properstate;
    this.viewaccount.viewzip = zip;
    this.viewaccount.viewCountry = country;
    this.viewaccount.viewphone = phone;
    this.viewaccount.viewfax = fax;
    this.viewaccount.viewwebsite = website;
    this.viewaccount.Accountiddata = Accountiddata;

  }
  
  rowDoubleClickAgGrid(rowEvent) {
    this.id = this.conid = rowEvent.data.contactid;
    this.taskid = rowEvent.data.id;
    this.type = rowEvent.data.type;
    this.taskhistoryinfo = false;
    this.ShowScheduletask = true;
    let objSelf = this;
    $(setTimeout(function () {
      objSelf.scheduleComp.initData(rowEvent.data.contactid, rowEvent.data.type, rowEvent.data.id); 
    }, 500));
       
  }
  cellDoubleClicked(rowEvent) {
    this.EnableSearch = false;
    this.id = this.conid = rowEvent.data.contactid;
    this.taskid = rowEvent.data.id;
    this.type = rowEvent.data.type;
    this.taskhistoryinfo = false;
    this.ShowScheduletask = true;
    let objSelf = this;
    $(setTimeout(function () {
      objSelf.scheduleComp.initData(rowEvent.data.contactid, rowEvent.data.type, rowEvent.data.id);
    }, 500));
    var obj: AuditLogModel = { id: 0, type: 'TaskList', action: 'Task List Viewed', AccountOrContactId: rowEvent.data.contactid, AccountOrContact: rowEvent.data.type, documentid: rowEvent.data.id, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Task List Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }
  openNotesDialogAg(rowdata) {
    
    this.conid = rowdata.contactid;
    this.showNotesDialog = !this.showNotesDialog;
    if (this.showNotesDialog == true) {
      this.NotesDialogCss();
    }
    
  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('#showNotesDialog .dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }
  ngOnInit() {
    if (document.getElementById('subTab') != undefined && document.getElementById('subTab') != null)
      document.getElementById('subTab').style.display = 'none';
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }
    var obj: AuditLogModel = { id: 0, type: 'TaskList', action: 'Task List Viewed', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Task List Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
    this.applicationComponent.selectedbuttonText = "Task lists";
    this.initializeAgGrid();
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.taskid = params['taskid'];
      this.type = params['type'];
      this.term = params['term'];
      this.TaskData('Open Tasks', $("#userids").val(), this.term);
      this.initializeAgGrid();
    });
    if (this.id == 0 && (this.type != null && this.type != "null")) {
      this.taskhistoryinfo = true;
      this.ShowScheduletask = false;      
    } else
      if (this.type != null && this.type != "null") {
        this.taskhistoryinfo = false;
        this.ShowScheduletask = true;
      
      }
      else {
        this.GetAllaccesusersListbyuser();
        
        if (this.applicationComponent.selectedusers != '' || this.applicationComponent.selectedusers != null) {
          this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
            Owner.forEach(Owner => {
            });
            this.selectedItems = Owner;
          })
        }
        else {
          this.selectedItems = [
            { "id": this.currentuser.id, "itemName": this.currentuser.username }
          ];
          this.finalitems += this.currentuser.id + ',';

        }
        this.dropdownSetting = {
          singleSelection: false,
          text: "Select Fields",
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          searchPlaceholderText: 'Search Fields',
          enableSearchFilter: true,
          badgeShowLimit: 3
        };
        this.TaskData('Open Tasks', $("#userids").val(), this.term);
      }
    
    this.isViewInitialized = true;
    if (document.getElementById('TaskListTabs') != undefined && document.getElementById('TaskListTabs') != null)
      document.getElementById('TaskListTabs').style.display = 'block';

 
  }

  GetAllaccesusersListbyuser() {

    this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
      this.DisplayrowsList = Rows;
    });
  }

  userListOutParamsBinding(outProps) {
    this.finalitems = outProps.finalitems;
    this.items = outProps.items;
    this.itemCount = outProps.itemsCount;
    this.selectedItems = outProps.selectedItems;
  }

  selectall(name) {
    if (name == "view") {
      if ($('#view_chkbox').is(":checked")) {
        $('.view').each(function () {
          this.checked = true;
        })
      } else {
        $('.view').each(function () {
          this.checked = false;
        })
      }
    }
    if (name == "add") {
      if ($('#Add_chkbox').is(":checked")) {
        $('.add').each(function () {
          this.checked = true;
        })
      } else {
        $('.add').each(function () {
          this.checked = false;
        })
      }
    }
    if (name == "edit") {
      if ($('#Edit_chkbox').is(":checked")) {
        $('.edit').each(function () {
          this.checked = true;
        })
      } else {
        $('.edit').each(function () {
          this.checked = false;
        })
      }

    }

  }
  checkAccess(id, type) {
    var idCheck = '';
    if (type == 'add') {
      idCheck = 'mainadd_' + id
    } else if (type == 'edit') {
      idCheck = 'mainedit_' + id
    } else {
      idCheck = 'mainview_' + id
    }

    if ($('#' + idCheck).is(":checked")) {
      $('.' + id).each(function () {
        this.checked = true;
      })
    } else {
      $('.' + id).each(function () {
        this.checked = false;
      })
    }
  }
  public clearcheckboxs() {
    $('.mainview').each(function () {
      this.checked = false;
    })
    $('.mainadd').each(function () {
      this.checked = false;
    })
    $('.mainedit').each(function () {
      this.checked = false;
    })
    $('.view').each(function () {
      this.checked = false;
    })
    $('.add').each(function () {
      this.checked = false;
    })
    $('.edit').each(function () {
      this.checked = false;
    })
  }
  checkAccessSub(id, type) {
    var idCheck = '';
    if (type == 'add') {
      idCheck = 'add_' + id
    } else if (type == 'edit') {
      idCheck = 'edit_' + id
    } else {
      idCheck = 'view_' + id
    }

    if ($('#' + idCheck).is(":checked")) {
      $('.' + id).each(function () {
        this.checked = true;
      })
    } else {
      $('.' + id).each(function () {
        this.checked = false;
      })
    }
  }
  onItemSelect(item: any) {
    var ids = "";
    ids += item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  OnItemDeSelect(item: any) {
    var ids = "";
    ids = item.id;
    var result = '';
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.Generate($("#userids").val());
    } else {
      this.alltaskhistory = [];
      $("#userids").val('');

    }
  }
  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.alltaskhistory = [];
    $("#userids").val('');
  }

  Generate(val) {
    this.TaskData('All', val, this.term);    
  } 

  TaskData(val, userids, searchval) {
    if (this.currentuser.UserPrivilegeList != undefined) {
      var button = document.getElementById(val);
      var bname = (button == null) ? val : (button.title);
      var thisTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == bname || p.resource.toLowerCase() == bname)[0];
      if (thisTab != undefined && thisTab.canview == false) {
        this.toastr.warning("You Do Not have Access to " + bname + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
        return false;
      }
    }
    userids = $("#userids").val();
    this.tasktype = val;
    if (searchval == undefined) { searchval = null; }
    if (this.PageFrom == "dashboard") {
      this.leadservice.GetAllCustomerActivities(this.currentuser.id).then((customerActivities) => {
        this.alltaskhistory = customerActivities;
      });
    } else {
      this.leadservice.GetAllTaskHistory(val, userids, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, searchval, 'tasklist', this.currentuser.timezone).then((taskhistory) => {
        if (taskhistory.length > 0) {         
          taskhistory.forEach(taskhistory => {
            taskhistory.datecreated = this.datePipe.transform(taskhistory.datecreated, "yyyy-MM-dd");
            taskhistory.datecompleted = taskhistory.datecompleted == null ? "" : this.datePipe.transform(taskhistory.datecompleted, "yyyy-MM-dd");
            taskhistory.estcompleteddate = ((taskhistory.estcompleteddate == "0001-01-01") ? "" : this.datePipe.transform(taskhistory.estcompleteddate, "yyyy-MM-dd"));
          });         
          this.alltaskhistory = taskhistory;
          if (!this.ShowScheduletask) {
            this.gridApi.paginationSetPageSize(Number(this.alltaskhistory.length));
            var data = [];
            var labelArr = [];
            this.Below30Days = taskhistory.filter((cust) => cust.NoOfDays <= 30).length;
            this.Betwn31_60Days = taskhistory.filter((cust) => cust.NoOfDays > 30 && cust.NoOfDays <= 60).length;
            this.Betwn61_90Days = taskhistory.filter((cust) => cust.NoOfDays > 60 && cust.NoOfDays <= 90).length;
            this.Betwn91_120Days = taskhistory.filter((cust) => cust.NoOfDays > 90 && cust.NoOfDays <= 120).length;
            this.Above120Days = taskhistory.filter((cust) => cust.NoOfDays > 120).length;
            this.Above151Days = taskhistory.filter((cust) => cust.NoOfDays > 150).length;

            this.totalPhoneCalls = taskhistory.filter((cust) => cust.taskcategory == "Phone").length;
            this.totalMeetings = taskhistory.filter((cust) => cust.taskcategory == "Meeting").length;
            this.totalToDos = taskhistory.filter((cust) => cust.taskcategory == "To Do").length;
            labelArr.push({ name: "Phone Calls", Total: this.totalPhoneCalls });
            labelArr.push({ name: "Meeting", Total: this.totalMeetings });
            labelArr.push({ name: "To Do", Total: this.totalToDos });
            this.customerpieChartLabels = labelArr;
            data.push(this.totalPhoneCalls);
            data.push(this.totalMeetings);
            data.push(this.totalToDos);
            this.pieChartData = [this.totalPhoneCalls, this.totalMeetings, this.totalToDos];

            if (this.alltaskhistory.length > 0 && this.alltaskhistory[0].islimitedSearch == true) {
              if (this.alltaskhistory[0].SearchVal) {
                this.applicationComponent.searchCriteria = this.alltaskhistory[0].SearchVal;
                //this.applicationComponent.Searchdiv = true;
                this.applicationComponent.searchstring = this.alltaskhistory[0].SearchVal.slice(0, -3);
                this.applicationComponent.searchCriteriadata = this.applicationComponent.searchstring.split(' / ');
              }
              this.applicationComponent.term = "True";//passing dummy data toshow delete button in search box
              this.applicationComponent.limitedSearch = true;
              $('#searchContact').val('');
            }
            else {

              //$('#searchContact').attr('style', 'height:30px;width:88%;border:none;border-radius:3px;')
              this.applicationComponent.term = "";
              $('#searchContact').val('');
              this.applicationComponent.searchstring = "";
              this.applicationComponent.limitedSearch = false;
              this.applicationComponent.searchCriteriadata = [];
            }
            if (this.gridOptions.columnApi != undefined) {
              this.gridOptions.columnApi.setColumnVisible('datecompleted', (val == 'Open') ? false : true);
              this.createColumnDefs(this.columnSelections);
            }
          } else {
            var taskid = Number(localStorage.getItem('openPastDueId')) > 0 ? Number(localStorage.getItem('openPastDueId')) : this.alltaskhistory[0].id;
            let selfObj = this;
            $(setTimeout(function () {
              selfObj.gridOptions.api.forEachNode((node) => {
                if (node.data.id == taskid) {
                  selfObj.gridOptions.api.selectNode(node, true);
                }
              });
            }, 300));
          }

        } else {
          this.alltaskhistory = taskhistory;
          if (this.alltaskhistory.length==0) {
            this.scheduleComp.HideAdnShow(0, "");
          }
         
        }
      });
    }
   
    this.UpdateSearchColumns(this.columnSelections);
    this.taskhistoryinfo = true;
   
  }

  FilterByDays(val) {

    $('.daysFilterCss').removeClass('daysFilterGreenCss');
    $('#' + val).addClass('daysFilterGreenCss');
    this.leadservice.GetAllTaskHistory('Open Tasks', $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, null, 'tasklist', this.currentuser.timezone).then((customers) => {
      switch (val) {
        case '30':
          this.daysFilter = true;
          customers = customers.filter((cust) => cust.NoOfDays <= 30);
          break;
        case '31-60':
          this.daysFilter = true;
          customers = customers.filter((cust) => cust.NoOfDays > 30 && cust.NoOfDays <= 60);
          break;
        case '61-90':
          this.daysFilter = true;
          customers = customers.filter((cust) => cust.NoOfDays > 60 && cust.NoOfDays <= 90);
          break;
        case '91-120':
          this.daysFilter = true;
          customers = customers.filter((cust) => cust.NoOfDays > 90 && cust.NoOfDays <= 120);
          break;
        case '120+':
          this.daysFilter = true;
          customers = customers.filter((cust) => cust.NoOfDays > 120 && cust.NoOfDays <= 150);
          break;
        case '151+':
          this.daysFilter = true;
          customers = customers.filter((cust) => cust.NoOfDays > 150);
          break;
        case 'DisplayAll':
          this.daysFilter = false;

          break;

      }

      customers.forEach(taskhistory => {
        taskhistory.datecreated = this.datePipe.transform(taskhistory.datecreated, "yyyy-MM-dd");
        taskhistory.datecompleted = taskhistory.datecompleted == null ? "" : this.datePipe.transform(taskhistory.datecompleted, "yyyy-MM-dd");
        taskhistory.estcompleteddate = ((taskhistory.estcompleteddate == "0001-01-01") ? "" : this.datePipe.transform(taskhistory.estcompleteddate, "yyyy-MM-dd"));
      });
     
      this.alltaskhistory = customers;
      this.gridApi.paginationSetPageSize(Number(this.alltaskhistory.length));
      var data = [];
      var labelArr = [];

      this.totalPhoneCalls = customers.filter((cust) => cust.taskcategory == "Phone").length;
      this.totalMeetings = customers.filter((cust) => cust.taskcategory == "Meeting").length;
      this.totalToDos = customers.filter((cust) => cust.taskcategory == "To Do").length;
      

    });
  }

  OpenNotes(rowEvent) {
    this.taskhistoryid = rowEvent.id;
    if (rowEvent.type == 'Contacts') {
      this.opprtunityservice.GetContactById(rowEvent.contactid).then(result => {
        this.opprtunityservice.GetByAccountId(result.accountid).then(result => {
          this.account = result;
          //if (this.account.city != null) {
          //  var city = this.account.city;
          //  var cityArray = city.split(' ');
          //  var newcity = '';
          //  for (var i = 0; i < cityArray.length; i++) {
          //    if (newcity == '') {
          //      newcity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
          //    } else {
          //      newcity = newcity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
          //    }
          //  }
          //  this.account.city = newcity;
          //}
          //this.account.state = this.account.state.charAt(0).toUpperCase() + this.account.state.substr(1).toLowerCase();

        });
        var middleName = '';
        var title = '';
        if (result.middlename != null) {
          middleName = result.middlename;
        }
        if (result.title != null) {
          title = ',' + result.title;
        }
        this.contact.firstname = result.firstname + ' ' + middleName + ' ' + result.lastname + title;
        this.accountdiv = false;
        this.customerdiv = true;
      });
    }
    else if (rowEvent.type == 'Customer') {
      this.opprtunityservice.GetByAccountId(rowEvent.contactid).then(result => {
        this.account = result;
        //if (this.account.city != null) {

        //  var city = this.account.city;
        //  var cityArray = city.split(' ');
        //  var newcity = '';
        //  for (var i = 0; i < cityArray.length; i++) {
        //    if (newcity == '') {
        //      newcity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        //    } else {
        //      newcity = newcity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        //    }
        //  }
        //  this.account.city = newcity;
        //}
        //this.account.state = this.account.state.charAt(0).toUpperCase() + this.account.state.substr(1).toLowerCase();

        this.contact.firstname = this.account.accountname;
        this.accountdiv = true;
        this.customerdiv = false;
      });
    }

    this.getTaskHistoryNotes(rowEvent.id, 'TaskHistory');
    this.showNotesDialog = !this.showNotesDialog;
    this.NotesDialogCss();
    $('.dialog').width('1000px');
  }
  getTaskHistoryNotes(id, type) {
    this.customerService.GetAllNotesList(this.currentuser.id, id, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      //this.initializeAgGridNotes();
      this.allnotes = taskhistory;
      this.notesitemCount = taskhistory.length;

      let windowHeight = $(window).height() - 160;
      //$(setTimeout(function () {
      //  $('.dialog').height(windowHeight);
      //}, 100));
    });
  }

  notesreloadItems(notesparams) {
    if (this.notesitemResource != undefined) {
      this.notesitemResource.query(notesparams).then(notesitems => this.notesitems = notesitems);
    }
  }

  AddTask() {
    if (this.userprevilages != undefined) {
      for (var j = 0; j < this.userprevilages.length; j++) {
        if ("Task List" == this.userprevilages[j].resource) {
          if (this.userprevilages[j].canadd == false) {
            this.toastr.warning("You Do Not have Access to this Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }
          
        }
      }
    }
    //this.router.navigateByUrl(`/application/scheduletask/Customer/0/null`);
  }
  enterNotes() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'block';
    document.getElementById('saveNotesButtons').style.display = 'block';
    document.getElementById('divTable').style.display = 'none';
  }
  SaveNote() {
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/img, "");
    this.notes.notedescription = this.notes.notedescription.replace(/<[^>]*>/g, '');
    this.notes.type = "TaskHistory";
    this.notes.accountid = this.taskhistoryid;

    this.customerService.SaveNotes(this.notes).then(result => {
      this.getTaskHistoryNotes(this.notes.accountid, 'TaskHistory');
      this.CancelNote();
    });
  }
  CancelNote() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'none';
    document.getElementById('saveNotesButtons').style.display = 'none';
    document.getElementById('divTable').style.display = 'block';
  }
  ResizeTable() {
    //this.aggridheight = ($("#gridheight").height(((window.innerHeight) - (($('#appComponentContent').height()) + ($('#actiondivid').height()))) - 85)) + 'px';
    this.aggridheight = (((window.innerHeight) - (($('#appComponentContent').height()) + ($('#actiondivid').height())) - 45)) + 'px';
  }

  dateChange(event) {
    this.AssignStartAndEndDate(event);
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
    let userids = $("#userids").val();
    this.leadservice.GetAllTaskHistory(this.tasktype, userids, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'tasklist', this.currentuser.timezone).then((taskhistory) => {
      taskhistory.forEach(taskhistory => {
        taskhistory.datecreated = this.datePipe.transform(taskhistory.datecreated, "MM-dd-yyyy");
        taskhistory.estcompleteddate = ((taskhistory.estcompleteddate == "0001-01-01") ? "" : this.datePipe.transform(taskhistory.estcompleteddate, "MM-dd-yyyy"));
      });
      this.alltaskhistory = taskhistory;
      this.gridApi.paginationSetPageSize(Number(this.alltaskhistory.length));
      if (this.alltaskhistory[0].islimitedSearch == true) {
        //$('#searchContact').attr('style', 'height:30px;width:88%;border:none;border-radius:3px;')
        if (this.alltaskhistory[0].SearchVal) {
          this.applicationComponent.searchCriteria = this.alltaskhistory[0].SearchVal;
          //this.applicationComponent.Searchdiv = true;
          this.applicationComponent.searchstring = this.alltaskhistory[0].SearchVal.slice(0, -3);
          this.applicationComponent.searchCriteriadata = this.applicationComponent.searchstring.split(' / ');

        }
        this.applicationComponent.term = "True";//passing dummy data toshow delete button in search box
        this.applicationComponent.limitedSearch = true;
        $('#searchContact').val('');
      }
      else {
        //$('#searchContact').attr('style', 'height:30px;width:88%;border:none;border-radius:3px;')
        this.applicationComponent.term = "";
        $('#searchContact').val('');
        this.applicationComponent.searchstring = "";
        this.applicationComponent.limitedSearch = false;
        this.applicationComponent.searchCriteriadata = [];
      }
    });
    this.saveConfiguration();
  }
  AssignStartAndEndDate(event) {

    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  TaskDataEventListener() {

    if (this.applicationComponent.isTaskDataLeadEventRaised) {
      this.taskid = null;
      if (this.applicationComponent.TaskDataVal == 'New') {
        this.taskhistoryinfo = false;
        this.ShowScheduletask = true;
        this.id = 0;
        //this.scheduleComp.reloadSched();
        this.AddTask();
      } else {

        this.TaskData(this.applicationComponent.TaskDataVal, this.applicationComponent.TaskDataUserids, null);
        this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1, 0, 0).then((Owner) => {
          Owner.forEach(Owner => {
          });

          this.dropdownList = Owner;
        })

        if (this.applicationComponent.selectedusers != '' || this.applicationComponent.selectedusers != null) {
          this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
            Owner.forEach(Owner => {
            });
            this.selectedItems = Owner;
          })
        }
        else {
          this.selectedItems = [
            { "id": this.currentuser.id, "itemName": this.currentuser.username }
          ];
          this.finalitems += this.currentuser.id + ',';
          //$("#userids").val(this.currentuser.id);

        }
      }

      this.applicationComponent.isTaskDataLeadEventRaised = false;
    }
    return false;
  }

  GridRefresh(msg) {
    if (msg == "Cancel") {
      this.ShowScheduletask = false;
    } else {
    
      this.leadservice.GetAllTaskHistory(this.tasktype, $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, null, 'tasklist', this.currentuser.timezone).then((taskhistory) => {
        taskhistory.forEach(taskhistory => {
          taskhistory.datecreated = this.datePipe.transform(taskhistory.datecreated, "MM-dd-yyyy");
          taskhistory.estcompleteddate = ((taskhistory.estcompleteddate == "0001-01-01") ? "" : this.datePipe.transform(taskhistory.estcompleteddate, "MM-dd-yyyy"));
        });
        this.alltaskhistory = taskhistory;
        this.gridApi.paginationSetPageSize(Number(this.alltaskhistory.length));
        if (this.alltaskhistory[0].islimitedSearch == true) {
          //$('#searchContact').attr('style', 'height:30px;width:88%;border:none;border-radius:3px;')
          if (this.alltaskhistory[0].SearchVal) {
            this.applicationComponent.searchCriteria = this.alltaskhistory[0].SearchVal;
            //this.applicationComponent.Searchdiv = true;
            this.applicationComponent.searchstring = this.alltaskhistory[0].SearchVal.slice(0, -3);
            this.applicationComponent.searchCriteriadata = this.applicationComponent.searchstring.split(' / ');

          }
          this.applicationComponent.term = "True";//passing dummy data toshow delete button in search box
          this.applicationComponent.limitedSearch = true;
          $('#searchContact').val('');
        }
        else {
          //$('#searchContact').attr('style', 'height:30px;width:88%;border:none;border-radius:3px;')
          this.applicationComponent.term = "";
          $('#searchContact').val('');
          this.applicationComponent.searchstring = "";
          this.applicationComponent.limitedSearch = false;
          this.applicationComponent.searchCriteriadata = [];
        }
      });
    }
  }
  onReceiveMessage(msg) {
    if (msg != "Task Added") {
      this.applicationComponent.isTaskDataLeadEventRaised = true;
      var data = msg.split("|");
      if (data[1] == 'Customer' || data[1] == 'Account') {
        this.router.navigateByUrl(`/application/tasklistdashboard/Customer/` + data[0] + '/null/null');
      }
      else {
        this.router.navigateByUrl(`/application/tasklistdashboard/Contacts/` + data[0] + '/null/null');
      }
      let selfObj1 = this;
      $(setTimeout(function () {
        //$('#addtask').attr('style', 'display: block; height: 100%;');
        //document.getElementById('taskhistoryinfo').style.display = 'none';
        selfObj1.taskid = data[0];
        selfObj1.type = data[1];
       
      }, 100));

    }
    else if (msg == "Task Added") {
      this.CloseTaskDiv();
      this.TaskData('Open Tasks', $("#userids").val(), this.term);    
    }
    else {     
      var data = msg.split("|");
      if (data[1] == 'Customer' || data[1] == 'Account') {
        this.router.navigateByUrl(`/application/tasklistdashboard/Customer/` + data[0] + '/null/null');
      }
      else {
        this.router.navigateByUrl(`/application/tasklistdashboard/Contacts/` + data[0] + '/null/null');
      }
      $(setTimeout(function () {
        //$('#addtask').attr('style', 'display: block; height: 100%;');
        //document.getElementById('taskhistoryinfo').style.display = 'none';
        this.taskid = data[0];
        this.type = data[1];
      }, 200));
    }

  }

  openDisplaynotes(params) {
    this.NotesDec = !this.NotesDec;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    var notesOffset = $("#divNotesContainer").offset();
    let notesWidth = $('#divNotesContainer').width();
    let notesHeight = $('#divNotesContainer').height();
    let windowWidth = $(window).width();
    let showDialogWidth = 350;
    $(setTimeout(function () {
  
      $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

      if ($('input[name=Notes]').is(':checked')) {
        $('#txtLongNoteDesc').css('height', '81%');
        $('#txtLongNoteDesc').css('padding-top', '0%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
        $('#txtUserFullName').css('height', '14%');
      } else {
        $('#txtLongNoteDesc').css('height', '96%');
        $('#txtLongNoteDesc').css('padding-top', '6%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
        $('#txtLongNoteDesc').css('overflow', 'auto');
      }
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));
  }
  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();

  }
  expandTaskView(value) {
    if (value === 'Collapse') {
      this.TaskExpand = false;    
    }
    else {
      this.TaskExpand = true;
    }
  }
  CloseTaskDiv() {
    this.ShowScheduletask = false;
    this.TaskExpand = false;
  }
  saveEditedNote() {

    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.closeDetailsDialog();
        selfObj.getTaskHistoryNotes(selfObj.contactid, 'TaskHistory');
      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
  }
 
  ExpandGrid(val) {

    this.ExpandTaskGrid = val;
  }

  UserlistDataReceived(outProps: OutputPropsUserList) {
    if (outProps.enableSearch) { this.EnableSearch = true; }
    this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
    this.gridApi.paginationSetPageSize(Number(this.pageSize)); 
    this.tasktype = outProps.secondDrpValue == undefined ? this.tasktype : outProps.secondDrpValue;

    if (outProps.calanderonoff == true) {
      this.startDateVal = (outProps.startDateVal != undefined) ? outProps.startDateVal : this.startDateVal;
      this.endDateVal = (outProps.endDateVal != undefined) ? outProps.endDateVal : this.endDateVal;
    }
    if (outProps.finalitems != "") {
      $("#userids").val(outProps.finalitems);
      if (!outProps.pageSizeChanged) {
        this.TaskData(this.tasktype, $("#userids").val(), null);
      }
    } else {
      this.alltaskhistory = [];
      $("#userids").val('');
    }
  }
 
  onCloseDialogEventReceived(val) {
    if (val == 'false') {
      this.notesshowDialog = false;
      this.isModal = false;
    }
  }

  ExpandPDF(val) {
    this.notesshowDialog = true;
    this.QuickViewDialogCss(val);
    this.DocExpandView = val;
  }
  QuickViewDialogCss(val) {
    if (val == true) { //expand
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    } else {
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    }

  }

  onCellValueChanged($event) {
  
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
    this.gridOptions.quickFilterText = (document.getElementById('filter-text-box') as HTMLInputElement).value;
    this.createColumnDefs(this.columnSelections);
    this.gridApi.redrawRows();
  }
  ClearSearch() {
    this.gridApi.setQuickFilter('');
    this.value = "";
    this.EnableSearch = false;
    this.gridOptions.quickFilterText = "";
    this.createColumnDefs(this.columnSelections);
    this.gridApi.redrawRows();
  }
}

