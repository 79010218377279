import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { Messaging } from '../../models/messaging';
import { MessagingService } from '../../services/messaging.service';
import { ApplicationComponent } from '../application.component';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Column } from 'ag-grid';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { OpprtunitiesService } from '../../services/opprtunities.service';
declare var $: any;
@Component({
  selector: 'unread-messages',
  templateUrl: './unread-messages.component.html',
  styleUrls: ['./unread-messages.component.css']
})
export class UnreadMessagesComponent implements OnInit {
  public messagegridOptions: GridOptions;
  divHeight = (screen.height) * 0.19888889;
  public messagecolumnDefs: any[];
  allMessages: Messaging[];
  currentuser: any;
  datePipe: DatePipe = new DatePipe("en-US");
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  selectedItems = [];
  colSelections: string;
  constructor(private router: Router, public applicationComponent: ApplicationComponent, private messagingservice: MessagingService, private opprtunityservice: OpprtunitiesService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.initializeAgGrid();
    this.getallmessages(this.currentuser.id)


  }
  initializeAgGrid() {   
    this.messagegridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 49).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.messagegridOptions.api.paginationSetPageSize(config.maxlimit);
      }

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = columnSelections;
      this.createMessageColumnDefs(columnSelections);
    });
   
  }
  expandDiv() {
    

    if ($('#expandid').html() =='<img src="../../../assets/img/Collapse View .png" style="height:20px">') {
      $('#expandid').html('<img src="../../../assets/img/Expand View.png" style="height:20px" />');
      this.sendMsgEvent.emit("collapse");
    } else {
  $('#expandid').html('<img src="../../../assets/img/Collapse View .png" style="height:20px" />');
      this.sendMsgEvent.emit("Expand");
    }
  }
  createMessageColumnDefs(columnSelections: string) {
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      this.messagecolumnDefs = [];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        this.messagecolumnDefs.push({
          field: fieldHeaderVisibleWidthArray[0],
          headerName: fieldHeaderVisibleWidthArray[1],
          hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
          width: parseInt(fieldHeaderVisibleWidthArray[3])
        });

      }
    } else {

      this.messagecolumnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false
        },
        { field: 'date', headerName: 'Date', hide: false, aggFunc: 'first', width: 90 },
        { field: 'fromuser', headerName: 'From', hide: false, aggFunc: 'first', width: 120 },
        { field: 'subject', headerName: 'Subject', hide: false, aggFunc: 'first' }

      ];
    }

  }
  rowClickAgGrid(rowEvent) {
    if (rowEvent.node.selected) {
      this.sendMsgEvent.emit(rowEvent.data.id);
    }
    else {
      this.sendMsgEvent.emit('0');
    }
  }
  rowDoubleClickAgGrid(rowEvent) {
    this.router.navigateByUrl(`/application/messaging/` + rowEvent.data.id);
  }
  public getallmessages(userid) {
    if (userid != null) {  
      this.messagingservice.Getallmessages(this.currentuser.id, 0, this.currentuser.id, this.applicationComponent.startDateVal, this.applicationComponent.endDateVal, 1, 100).then((msg) => {
        //this.messagingservice.Getallmessages(userid, 0, this.currentuser.id, "undefined", "undefined", 1, 100).then((msg) => {
        if (msg != null) {
          msg.forEach(msg => {
            msg.message = this.htmlToPlaintext(msg.message)
            msg.date = this.datePipe.transform(msg.date, "MM-dd-yyyy");
          });
          this.initializeAgGrid();
          let unreadmsgs: any = msg;
          unreadmsgs = msg.filter((cust) => cust.readstatus == 0);
          this.allMessages = unreadmsgs;

          this.applicationComponent.inboxmessagecount = this.allMessages.length;
          if (this.allMessages.length > 0) {
            this.sendMsgEvent.emit("Load" + this.allMessages.length.toString());
          }
        }
        
      });
    } else
    {
      this.allMessages = [];
      this.applicationComponent.inboxmessagecount = 0;
    }
  }
  public htmlToPlaintext(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  columnVisible(event) {
    this.saveRHXConfiguration();
  }
  dragStopped(event) {
    this.saveRHXConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveRHXConfiguration();
  }
  InternalMessageClicked() {
    this.router.navigateByUrl(`/application/messaging/`);
  }

  saveRHXConfiguration() {
    let allCols: Column[] = this.messagegridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    //if (this.pageSize == "")
    config.maxlimit = 100;
    //else
    //  config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 49;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }
}
