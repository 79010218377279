import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { Dropdowntype } from '../../models/dropdowntype';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Accounts, Accountlocations } from '../../models/accounts';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { DatePipe, Location } from '@angular/common';
import { AccountsService } from '../../services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { Users } from '../../models/users';
import { ApplicationComponent } from '../application.component';

@Component({
  selector: 'add-new-account',
  templateUrl: './add-new-account.component.html',
  styleUrls: ['./add-new-account.component.css']
})
export class AddNewAccountComponent implements OnInit {
  Fields: any; SpecificsFields: any; ShippingFields: any;
  AccSpecificFields:any;
  currentuser: any;
  customeracces: number = 0;
  OwnerList: Dropdowntype[] = [];
  NewForm = new FormGroup({});
  account: Accounts = new Accounts();
  public stateInfo = [];
  MonthList: Dropdowntype[] = [];
  DayList: Dropdowntype[] = [];
  Years: any[];
  FormDataModified: boolean = false;
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  addresses: Accountlocations[] = [];
  DiscardFormDataConfirmation: boolean = false;
  datePipe: DatePipe = new DatePipe("en-US");
  enableDoNotUpdate: boolean = false;
  ismatchduplicate: string;
  SaveAccDialog: boolean = false; websiteduplicate = false;
  DuplicateAccountDialog: boolean = false;
  deleteduserName: string = "";
  showmapWarning: boolean = false; EditAddressDiv: boolean = false;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  customertypeList: Dropdowntype[] = [];
  contactList: Dropdowntype[] = [];
  salereplist: Users[] = [];
  accounttypeList: Dropdowntype[] = [];
  ownershiplist: Dropdowntype[] = [];
  Statuslist: Dropdowntype[] = [];
  customercontacttypelist: Dropdowntype[];
  SuffixList: Dropdowntype[] = [];
  SexList: Dropdowntype[] = [];
  leadfromlist: Dropdowntype[] = [];
  TitleList: Dropdowntype[] = [];
  contacttypelist: Dropdowntype[] = [];
  Specialitytypelist: Dropdowntype[] = [];
  Collegelist: Dropdowntype[] = [];
  MedicalSchoolList: Dropdowntype[] = [];
  StatesList: Dropdowntype[] = [];
  CitiesList: Dropdowntype[] = [];
  PractiseList: Dropdowntype[] = [];
  ehrpurchasedList: Dropdowntype[] = [];
  pmpurchasedList: Dropdowntype[] = [];
  rcmlist: Dropdowntype[] = [];
  constructor(public applicationComponent: ApplicationComponent, private router: Router, private _location: Location, private toastr: ToastrService, private customerService: CustomerService, private accountsService: AccountsService,
    private opprtunityservice: OpprtunitiesService) { this.currentuser = JSON.parse(localStorage.getItem("currentUser")); }

  ngOnInit(): void {
    this.dropdownvalues();
   
    this.customerService.GetFormConfiguration(this.currentuser.id, 'Mailing Address').then(res => {
      if (res != null && res.length > 0) {
        let obj: string[] = [res[0].OrderFields, 'Mailing Address'];
        this.customerService.getSaveFormFields(obj).then(result => {
          if (this.customeracces == 0) {
            result = result.filter(x => x.ColumnName != 'customertypeid');
          }

          result.forEach(ele => {
            if (ele['ColumnName'] == 'ownerid') {
              ele['Data'] = this.OwnerList;
              this.account.ownerid = this.currentuser.id;
            } else {
              if (ele['DataType'] == 'Pick List') {
                this.customerService.GetPickListData(ele['FieldName'], ele['id'], this.currentuser.id).then((accounttype) => {
                  var data = this.PickListSortByType(ele['FieldDataOrderBy'], accounttype)
                  ele['Data'] = data;
                });
              }
            }
          });
          this.Fields = result.filter(x => !x.ColumnName.includes('Shipping'));        
          this.ShippingFields = result.filter(x => x.ColumnName.includes('Shipping'));         
          this.Fields.forEach(x => {
            this.NewForm.addControl(x.ColumnName, new FormControl(''));           
          });

        });
      }
    });

    this.customerService.GetFormConfiguration(this.currentuser.id, 'Account Specifics').then(Specificsres => {
      if (Specificsres != null && Specificsres.length > 0) {
        let obj: string[] = [Specificsres[0].OrderFields, 'Account Specifics'];
        this.customerService.getSaveFormFields(obj).then(result => {        
          result.forEach(ele => {
            if (ele['DataType'] == 'Pick List') {             
              this.customerService.GetPickListData(ele['FieldName'], ele['id'], this.currentuser.id).then((accounttype) => { 
                  var data = this.PickListSortByType(ele['FieldDataOrderBy'], accounttype)
                  ele['Data'] = data;
                });
              }
          });
          this.SpecificsFields = result;
        
          this.SpecificsFields.forEach(x => {
            this.NewForm.addControl(x.ColumnName, new FormControl(''));
          });

        });
      }
    });
    
  }

  cancelAccount() {
    this._location.back();   
  }
   dropdownvalues() {
    
     this.customerService.GetSpecialityList("Account Active or Inactive", this.currentuser.id).then((CustomerType) => {     
       this.customertypeList = CustomerType;
       this.account.customertypeid = 1;
     
    });
    this.customerService.GetAllOwnerList(0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.OwnerList = Owner;
    });

    this.customerService.GetSpecialityList("Account Type", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.accounttypeList = accounttype;
      
    });

    this.customerService.GetSpecialityList("Account Ownership", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.ownershiplist = accounttype;
    });
    this.customerService.GetSpecialityList("Account Status", this.currentuser.id).then((accounttype) => {
 
      this.Statuslist = accounttype;
      
    });

    this.customerService.GetSpecialityList("Suffix", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.SuffixList = accounttype;
    });
    this.customerService.GetSpecialityList("Sex", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.SexList = accounttype;
    });
    this.customerService.GetAccountContactList(this.account.id).then((conatct) => {

      conatct.forEach(ele => {
        if (ele.category != null) {
          ele.itemName = ele.itemName + ' - ' + ele.category;
        }
      });

      this.contactList = conatct;
    });


    this.customerService.GetSpecialityList("Account PM", this.currentuser.id).then((pm) => {
      pm.forEach(pm => {
      });
      this.pmpurchasedList = pm;
    });
    this.customerService.GetSpecialityList("Account RCM", this.currentuser.id).then((rcm) => {
      rcm.forEach(rcm => {
      });
      this.rcmlist = rcm;
    });
    
    this.customerService.GetRoleSalesRep(this.currentuser.userrole).then((salerep) => {
      this.salereplist = salerep;
    });
    this.customerService.GetSpecialityList("Account EHR", this.currentuser.id).then((ehr) => {
      ehr.forEach(accounttype => {
      });
      this.ehrpurchasedList = ehr;
    });
    this.customerService.Getcontacttypes(1).then((accounttype) => {

      accounttype.forEach(accounttype => {
      });
      this.customercontacttypelist = accounttype;
    });
    this.customerService.GetSpecialityList("Account Specialty", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Specialitytypelist = accounttype;
    });

    this.customerService.GetCollegeList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Collegelist = accounttype;
    });

    this.customerService.GetMedicalSchoolList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.MedicalSchoolList = accounttype;
    });

    this.customerService.GetAllStateList('all').then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.StatesList = accounttype;
      this.stateInfo = accounttype
    });
    this.customerService.GetSpecialityList("Month", this.currentuser.id).then((appointment) => {
      appointment.forEach(appointment => {
      });
      this.MonthList = appointment;

    });

    this.customerService.GetSpecialityList("Day", this.currentuser.id).then((appointment) => {
      appointment.forEach(appointment => {
      });
      this.DayList = appointment;
    });

    this.customerService.GetPractices().then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.PractiseList = accounttype;
    });

    this.getyears()
    {
      var range = [];
      var y = 1949;
      var year = new Date().getFullYear();
      for (var i = 0; y <= year; i++) {
        y = y + 1;
        range.push({
          label: y,
          value: y
        });
      }
      this.Years = range;
    }

  }
  getyears() {
    var range = [];
    var year = new Date().getFullYear();
    for (var i = 0; i < 7; i++) {
      range.push({
        label: year + i,
        value: parseInt(String(year + i).slice(2, 4))
      });
    }


    this.Years = range;
  }
  showEditAddressDiv() {
    this.account.zip = $('#zip1').val();
    this.account.address1 = $('#address11').val();
    this.account.address2 = $('#address21').val();
    this.account.city = $('#city1').val();
    this.account.state = $('#state1').val();
    this.account.lattitude = $('#lattitude1').val();
    this.account.longtitude = $('#longtitude1').val();
    this.account.country = $('#country').val();
    this.account.ProvidenceIsland = $('#ProvidenceIsland1').val();
    this.NewForm.controls['ProvidenceIsland'].setValue(this.account.ProvidenceIsland);
    this.NewForm.controls['address1'].setValue(this.account.address1);
    this.NewForm.controls['address2'].setValue(this.account.address2);
    this.NewForm.controls['city'].setValue(this.account.city);
    this.NewForm.controls['state'].setValue(this.account.state);
    this.NewForm.controls['country'].setValue(this.account.country);
    this.NewForm.controls['zip'].setValue(this.account.zip);
  
    if (this.account.id == 0) {
      this.NewForm.addControl('userid', new FormControl(this.currentuser.id));
      var date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
      this.NewForm.addControl('created', new FormControl(date));
      this.NewForm.controls['created'].setValue(date);
    } else {
      var date = this.datePipe.transform(this.account.created, 'yyyy-MM-dd HH:mm:ss');
      this.NewForm.addControl('created', new FormControl(date));
      this.NewForm.controls['created'].setValue(date);
    }
    var date = this.datePipe.transform(this.account.created, 'yyyy-MM-dd HH:mm:ss');
    this.NewForm.addControl('created', new FormControl(date));
    this.NewForm.controls['created'].setValue(date);
    this.SaveAccount('DoNotUpdate');
  }
  SaveAccount(val) {
    if (this.account.isaddressadded) {
      if (this.account.id == 0) {
        this.accountsService.checkduplicateAccountName(this.account.accountname).then(result => {

          if (result == "No") {
            this.accountsService.checkduplicates(this.account).then(resultdata => {

              if (resultdata == "No" || resultdata == null) {
                this.SaveAccountInfo(val);
              }
              else {
                this.ismatchduplicate = result;
                if (resultdata == 'WebsiteDuplicate') { this.websiteduplicate = true; }
                this.SaveAccDialog = true;
              }
            });
          }
          else {
            this.DuplicateAccountDialog = true
            this.deleteduserName = result;
            $(setTimeout(function () {
              $('.dialog').attr('style', 'width:45% !important;border: 4px solid rgb(49,189,240);border-radius:5%; height:70%;top:16%;max-width:none;padding:0;');
            }, 200));

          }
        });


      } else {

        this.SaveAccountInfo(val);
      }

    }
    else {
      this.showmapWarning = true;
      this.showmapWarningDialogCss();
    }
  }
  showmapWarningDialogCss() {
    setTimeout(function () {
      $('#Banoo .dialog').attr('style', 'width:30%;padding-bottom: 0px;border:4px solid rgb(49,189,240);border-radius:5%;min-height: 40%;overflow: visible;height: 42%;top: 32%;left: 56%;');
    }, 4);
  }
  SaveAccountInfo(val) {
    this.account.userid = this.currentuser.id;
    var date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');   
    this.NewForm.controls['created'].setValue(date);
    this.account.fieldvalues = JSON.stringify(this.NewForm?.value);
    this.account.lattitude = this.account.lattitude;
    this.account.longtitude = this.account.longtitude;
    
    this.accountsService.addaccountNew(this.account).then(result => {
      this.account = result;      
      this.SaveAccDialog = false;
      this.toastr.success("Account saved successfully.", "Account Saved", { timeOut: 600 });
  
      if (this.account.isCustomer == 874) {
        var url = window.location.href;
        if (url.indexOf('account') > -1) {
          let selfobj = this;
          $(setTimeout(function () {

            selfobj.router.navigateByUrl(`/application/account/Customer`);
          }, 50));
          selfobj.router.navigateByUrl(`/application`);
        }
        else {
          this.router.navigateByUrl(`/application/account/Customer`);
        }
        localStorage.setItem('RhxAccountVal', this.account.id.toString());

        let selfobj = this;
        setTimeout(function () {
          selfobj.applicationComponent.ExpandSelectedMenu('customers');
          $(".subButtons").removeClass("activeButton");
          selfobj.applicationComponent.selectedtabid = 'Customer';
        }, 200)
      } else {
        var url = window.location.href;
        if (url.indexOf('account') > -1) {
          let selfobj = this;
          $(setTimeout(function () {

            selfobj.router.navigateByUrl(`/application/account/Accounts`);
          }, 50));
          selfobj.router.navigateByUrl(`/application`);
        }
        else {
          this.router.navigateByUrl(`/application/account/Accounts`);
        }
        let selfobj = this;
        setTimeout(function () {
          selfobj.applicationComponent.ExpandSelectedMenu('Accounts');
          $(".subButtons").removeClass("activeButton");
          localStorage.setItem('RhxAccountVal', selfobj.account.id.toString());
        });
        

      }
      this.EditAddressDiv = false;
      this.FormDataModified = false;
      $('#test').val('');
      if (this.account.zip != undefined || this.account.zip != null) {
        if (this.account.zip.toString().length >= 6) {
          this.account.zip = this.account.zip.toString().slice(0, 5) + "-" + this.account.zip.toString().slice(5);
        } else if (this.account.zip.toString().length === 5) {
          this.account.zip = this.account.zip.toString();
        } else {
          this.account.zip = this.account.zip;
        }
      }
      this.sendMsgEvent.emit("CloseAddAccountDialog~" + this.account.id + "~" + this.account.isCustomer);
    });

    $('#statisticHeader').attr('style', 'text-align: left;');
    this.addresses = null;
  }
  FormDataEntered(val) {
    this.FormDataModified = true;
  }
  DiscardFormDataConfirmationCss() {
    this.DiscardFormDataConfirmation = true;
    setTimeout(function () {
      $('#DiscardFormDataConfirmation .dialog').attr('style', 'width: 31%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4);
  }
  AddressReceived(msg: Accounts) { // Reverted Changes
  
    if (msg.locationType == "loc1") {
      let multipleAdd = new Accountlocations();
      multipleAdd.address1 = msg.address1;
      multipleAdd.address2 = msg.address2;
      multipleAdd.city = msg.city;
      multipleAdd.state = msg.state;
      multipleAdd.zip = msg.zip;
      multipleAdd.id = msg.id;   
      multipleAdd.phone = msg.phone;
      multipleAdd.fax = msg.fax;
      multipleAdd.accountid = this.account.id;
      this.addresses.push(multipleAdd);

    } else {
        this.account.address1 = msg.address1;
        this.account.address2 = msg.address2;
        this.account.city = msg.city;
        this.account.state = msg.state;
        this.account.zip = msg.zip;
        this.account.ProvidenceIsland = msg.ProvidenceIsland;
      this.account.lattitude = msg.lattitude;
      this.account.longtitude = msg.longtitude;
        this.account.country = msg.country;
        this.account.isaddressadded = msg.isaddressadded;
        this.NewForm.controls['address1'].setValue(this.account.address1);
        this.NewForm.controls['address2'].setValue(this.account.address2);
        this.NewForm.controls['city'].setValue(this.account.city);
        this.NewForm.controls['state'].setValue(this.account.state);
      this.NewForm.controls['zip'].setValue(this.account.zip);
      this.NewForm.controls['country'].setValue(this.account.country);
      this.NewForm.controls['ProvidenceIsland'].setValue(this.account.ProvidenceIsland);
      if (this.account.country == "882") {
        setTimeout(function () {
          $('#city1').addClass('form-control decreasefieldSize ng-pristine');
          $('#city1').removeClass('ng-invalid');
          $('#city1').removeClass('required');         
          new FormControl('city', Validators.nullValidator);

          $('#state1').addClass('form-control decreasefieldSize ng-pristine');
          $('#state1').removeClass('ng-invalid');
          $('#state1').removeClass('required');
          new FormControl('state', Validators.nullValidator);

          $('#zip1').addClass('form-control decreasefieldSize ng-pristine');
          $('#zip1').removeClass('ng-invalid');
          $('#zip1').removeClass('required');
          new FormControl('zip', Validators.nullValidator);
        }, 300);
        

        
      } else {
        $('#city1').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
        new FormControl('city', Validators.required);

        $('#state1').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
        new FormControl('state', Validators.required);

        $('#zip1').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
        new FormControl('zip', Validators.required);
      }

    }

  }
  IsCustomerTypeChange(value, fieldVal) {
 
     if (fieldVal == 'country') {
      if (value == 882) {

        $('#city1').addClass('form-control decreasefieldSize ng-pristine');
        $('#city1').removeClass('ng-invalid');
        $('#city1').removeClass('required');
        new FormControl('city', Validators.nullValidator);

        $('#state1').addClass('form-control decreasefieldSize ng-pristine');
        $('#state1').removeClass('ng-invalid');
        $('#state1').removeClass('required');
        new FormControl('state', Validators.nullValidator);

        $('#zip1').addClass('form-control decreasefieldSize ng-pristine');
        $('#zip1').removeClass('ng-invalid');
        $('#zip1').removeClass('required');
        new FormControl('zip', Validators.nullValidator);

      } else {
        $('#city1').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
        new FormControl('city', Validators.required);

        $('#zip1').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
        new FormControl('zip', Validators.required);

        $('#state1').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
        new FormControl('state', Validators.required);
      }
    }
    else if (fieldVal == 'ownerid') {
      if (value == 0) {
        $('#ownerid').removeClass("ng-pristine ng-valid");
        $('#ownerid').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
        this.NewForm.controls['ownerid'].setValue('');
        new FormControl('ownerid', Validators.required);
      }
      else {
        $('#ownerid').removeClass("ng-pristine ng-invalid");
        $('#ownerid').addClass('form-control decreasefieldSize ng-pristine ng-valid ng-star-inserted ng-touched');
        new FormControl('ownerid', Validators.nullValidator);
      }

    }
  }

  PickListSortByType(value, data) {
    var DuplicateFiledDataList = [];
    if (value == 'SortOrder') {
      DuplicateFiledDataList = data.sort((a, b) => a.Sortorder - b.Sortorder);
      return DuplicateFiledDataList;
    } else if (value == 'FieldName') {
      DuplicateFiledDataList = data.sort((a, b) => a.itemName.localeCompare(b.itemName));
      return DuplicateFiledDataList;
    } else {
      DuplicateFiledDataList = data.sort((a, b) => a.Sortorder - b.Sortorder);
      return DuplicateFiledDataList;
    }
  }
}
