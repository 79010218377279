
<div [@dialog] *ngIf="visible" class="dialog scroll-c"
     [style.border]="(this.type == 'FutureTask')? '4px solid #00beff':'4px solid rgb(49,189,240);border-radius:5%;'"
     [style.padding.px]="(this.type == 'FutureTask')? '0':''"
     style="width:100%;min-height:63%; overflow:auto;"
     [style.height.%]="this.CalendarDialog==true? 85:72">
  <!--769-->
  <ng-content></ng-content>
  <button *ngIf="closable" (click)="close()" aria-label="Close"  id="closeDialog">
  <img src="../../../assets/img/White x.png" style="height:23px" class="whitedialog__close-btn" *ngIf="CloseButtonColor === 'white'" />
  <img src="../../../assets/img/Black x.png" style="height:20px" class="dialog__close-btn" *ngIf="CloseButtonColor !== 'white'" />
  </button>
</div>


<div *ngIf="visible" [ngClass]="(type=='Calllist' ||type=='QuickView') ? '':'overlay'"  (click)="(OutSideClick)? close():''"></div>


