<div class="container" style="padding:0;height:100%">
  <div class="col-xs-12" style="padding:0;height:100%">

    <div class="col-xs-4" style="padding:0;height:100%">
      <div class="col-xs-12" style="padding:0;height:40%">
        <div class="col-xs-12" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:98%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
          <div style="text-transform:uppercase;font-size:14px;">Special Notes</div>
          <div>
            {{specialNotes}}
          </div>
        </div>

      </div>

      <div class="col-xs-12" style="padding:0;height:30%">
        <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
          <div style="text-transform: uppercase; font-size: 14px; height: 18%"> Ticket Counts</div>
          <div class="col-xs-12" style="padding: 0; height: 17% ">
            <div class="col-xs-9" style="padding:0;">
              Open Tickets
            </div>
            <div class="col-xs-2">
              ({{this.applicationComponent.OpenTicketCount}})
            </div>
          </div>
          <div class="col-xs-12" style="padding: 0; height: 17% ">
            <div class="col-xs-9" style="padding:0;">
              Pended Tickets
            </div>
            <div class="col-xs-2">
              ({{this.applicationComponent.PendedTicketCount}})
            </div>
          </div>
          <div class="col-xs-12" style="padding: 0; height: 17% ">
            <div class="col-xs-9" style="padding:0;">
              Closed Tickets
            </div>
            <div class="col-xs-2">
              ({{this.applicationComponent.ClosedTicketCount}})
            </div>
          </div>
          <div class="col-xs-12" style="padding: 0; height: 17% ">
            <div class="col-xs-9" style="padding:0;">
              On-Hold Tickets
            </div>
            <div class="col-xs-2">
              ({{this.applicationComponent.OnHoldTicketCount}})
            </div>
          </div>
          <div class="col-xs-12" style="padding: 0; height: 17% ">
            <div class="col-xs-9" style="padding:0;">
              Deleted Tickets
            </div>
            <div class="col-xs-2">
              ({{this.applicationComponent.DeleteTicketCount}})
            </div>
          </div>
        </div>
        <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
          <div style="text-transform: uppercase; font-size: 14px; text-transform: uppercase; font-size: 14px; height: 18%">Open Ticket Dates</div>
          <div class="col-xs-12" *ngFor="let ticket of Openticketslist  | slice:0:4" style="padding: 0; height: 17%">
            <div class="col-xs-9" style="padding:0;">
              {{ticket.createdDate | date: 'MM-dd-yyyy (HH:mm a)'}} 
            </div>
          </div>
         
        </div>
      </div>

      <div class="col-xs-12" style="padding:0;height:30%">
        <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
          <div style="text-transform: uppercase; font-size: 14px; text-transform: uppercase; font-size: 14px; height: 18%">On Hold Ticket Dates</div>
          <div class="col-xs-12" *ngFor="let ticket of OnHoldticketslist  | slice:0:4" style="padding: 0; height: 17%">
            <div class="col-xs-9" style="padding:0;">
              {{ticket.createdDate | date: 'MM-dd-yyyy (HH:mm a)'}}
            </div>
          </div>
          
        </div>
        <div class="col-xs-6" style="border:2px solid rgb(186,219,152) !important;height:97%;margin:0.5%;width:49%" [style.padding]="(tab === 'QuickView'  || tab == 'Normal')? '7% 0 0 0' :'0 0.8% 0 0.8%'">
          <div style="text-transform: uppercase; font-size: 14px; text-transform: uppercase; font-size: 14px; height: 18%">Pended Ticket Dates</div>
          <div class="col-xs-12" *ngFor="let ticket of OnHoldticketslist  | slice:0:4" style="padding: 0; height: 17%">
            <div class="col-xs-9" style="padding:0;">
              {{ticket.createdDate | date: 'MM-dd-yyyy (HH:mm a)'}}
            </div>
          </div>
          
        </div>
      </div>


    </div>
    <div class="col-xs-8" style="padding:0;height:100%" *ngIf="ContOrAccType == 'Contact'">
      <contactsdashboard [contactids]="contactid" [type]="'Contact'" [tab]="'QuickView'" (sendMsgEvent)="onEventReceived($event)"></contactsdashboard>
    </div>


  </div>
</div>
<div class="detailsDialog" id="showDetailsDialog">
  <ng-content></ng-content>
  <div class="col-xs-2 col-xs-offset-8" style="margin-left: 71%;">
  </div>
  <div class="col-xs-1">
    <button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog" style="margin-top:-37%"><i class="fa fa-window-close-o"></i></button>
  </div>
  <div class="box" style="border: none;box-shadow: none;box-shadow: none;">
    <div id="txtLongNoteDesc" class="text">
    </div>
  </div>

</div>

<app-dialog [(visible)]="showAddEditAccountDialog" *ngIf="showAddEditAccountDialog" id="addEditAccount" [closable]="false" [OutSideClick]="false">
  <app-add-edit-account [accountID]="this.account.id" [type]="'Account'" (sendMsgEvent)="ReceiveEvent($event)"></app-add-edit-account>
</app-dialog>
<app-dialog [(visible)]="DeleteConfirmationPopup" *ngIf="DeleteConfirmationPopup" id="DeleteConfirmationPopup" [closable]="false">
  <app-delete-popup [status]="contactStatus" [MessageType]="'Ticket'" (sendMsgEvent)="ReceiveDeleteevent($event)">
  </app-delete-popup>
</app-dialog>

<div *ngIf="showTicketsDetailsDialog" class="detailsDialog" id="showTicketsDetailsDialog" style="height:70%;padding:0;width:22%;border:1px solid black;top:15% !important">
  <div class="col-xs-12" style="padding:0;height:100%;background-color:#a8f1f1">
    <div class="col-xs-12" style="padding:0;height:5%">
      <span class="col-xs-12" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;padding:2px;float:right;z-index:1">
        <b> <img src="../../../assets/img/Black x.png" style="height:25px" (click)="closeTicketDialog('Ticket')" /></b>

      </span>
    </div>
    <div class="col-xs-12" style="height:94%;">
      <div class="col-xs-12" style="padding:0;height:92%;text-align:left">
        <label>Date Created:&nbsp;</label><span>{{TicketDetails.createdDate}}</span><br />
        <label>Contact:&nbsp;</label><span>{{TicketDetails.contactname}}</span><br />
        <label>Created By:&nbsp;</label><span>  {{TicketDetails.UserName}}</span><br />
        <label>Ticket Number:&nbsp;</label><span>{{TicketDetails.ticketId}}</span><br />
        <label>Status:&nbsp;</label><span>{{getTicketStatus(TicketDetails.TicketStatus)}}</span><br />
        <label>Subject:&nbsp;</label><span>{{TicketDetails.subject}}</span><br />
        <label>Assigned To:&nbsp;</label><span>{{TicketDetails.userList}}</span><br />
        <label>CC:&nbsp;</label><span>{{TicketDetails.CCToName}}{{TicketDetails.CCTotaskName}}</span><br />
        <label>Previous Notes:&nbsp;</label><br />
        <span *ngFor="let Note of TicketDetails.ticketNotes">{{Note.CreatedDate}}<br />{{Note.username}}<br />{{Note.Notes}}<br /><br /></span>
      </div>
      <!--<div class="col-xs-12" style="padding:0;height:7%;text-align:center">
        <a *ngIf="taskhistory.status==='Open'" (click)="OpenTask()" style="color:black;text-decoration: underline;cursor: pointer;font-weight:600">OPEN THIS TASK</a>
      </div>-->
    </div>
  </div>

</div>

<app-dialog [(visible)]="CreateTicketDialog" *ngIf="CreateTicketDialog" id="CreateTicketDialog" [OutSideClick]="false" [closable]="false">
  <!--<div class="col-xs-12">-->
    <div class="col-xs-8" *ngIf="openTicket" style="padding: 0; height: 500px;">
      <div class="col-xs-12 opportunity" style="height: 6%; text-align: center">
        <img src="../../../assets/img/Expand View.png" style="height:20px;float:left" (click)="HideTicket()" />
        <b id="lblOpp" style="margin:0;font-size:18px;">TICKETS/ACCOUNT DETAILS</b>
        <img src="../../../assets/img/White x.png" style="height:25px;float:right" (click)="HideTicket()" />

      </div>
      <ag-grid-angular style="width: 100%;height:100%;" class="ag-theme-balham"
                       [gridOptions]="ticketsgridOptions"
                       [columnDefs]="ticketscolumnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="ticketslist"
                       [sideBar]="sideBar"
                       [pagination]="true"
                       (rowSelected)="rowClickAgGrid($event)">
      </ag-grid-angular>
    </div>
    <div [ngClass]="!openTicket ? 'col-xs-12' :'col-xs-4'"  style="padding: 0">
      <div *ngIf="this.source!='Contacts'" class="col-xs-12" style="height:100%;padding:0">
        <div class="col-xs-12 opportunity" style="padding:1%;height:5%;text-align:center">
        <i class="fa fa-arrow-left" aria-hidden="true" (click)="OpenTicketDetails()" style="color: white; float: left; "></i>
        <b id="lblOpp" style="margin:0;font-size:18px;">Create Ticket</b>
        <img src="../../../assets/img/White x.png" style="height:25px;float:right" (click)="CloseCreateTicket()" />
        </div>
        <form style="height:86%" #ticketform="ngForm">
          <div class="col-xs-12" style="padding:0;height:100%" *ngIf="!showAssignToDiv && !showCCToDiv">
            <div class="col-xs-12" style="margin-top: 2%; padding-right: 2px; padding-left: 1%; text-align: left; height: 5%">
              <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
                <label style="margin:10% 0 0 0">Account</label>
              </div>
              <div class="col-xs-9" style="height:100%">
                <input class="form-control" type="text" name="accountName" [(ngModel)]="tickets.accountName" placeholder="Account Name" required />
              </div>

            </div>
            <div class="col-xs-12" style="margin-top:2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
              <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
                <label style="margin:10% 0 0 0">Contact</label>
              </div>
              <div class="col-xs-9" style="height:100%">
                <select class="form-control" id="ContactList" [(ngModel)]="tickets.contactid" name="ContactList" (change)="loadcontactdatatoticket($event.target.value)" required>
                  <option value="null" disabled [selected]="true">Select Contact</option>
                  <option *ngFor="let conatct of  contactList" value={{conatct.id}}>
                    {{conatct.itemName}}
                  </option>
                </select>
              </div>

            </div>

            <div class="col-xs-12" style="margin-top: 2%; padding-right: 2px; padding-left: 1%; text-align: left; height: 5%">
              <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
                <label style="margin:10% 0 0 0">Email</label>
              </div>
              <div class="col-xs-9" style="height:100%">
                <input class="form-control" type="text" name="email" [(ngModel)]="tickets.email" placeholder="Email Address" required />
              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 2%; padding-right: 2px; padding-left: 1%; text-align: left; height: 5%">
              <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
                <label style="margin:10% 0 0 0">Office Phone</label>
              </div>
              <div class="col-xs-9" style="height:100%">
                <input class="form-control" type="text" name="office" [(ngModel)]="tickets.businessphone" placeholder="office" required />
              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
              <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
                <label style="margin:10% 0 0 0">Cell Phone</label>
              </div>
              <div class="col-xs-9" style="height:100%">
                <input class="form-control" type="text" name="cell" [(ngModel)]="tickets.cellphone" placeholder="cell" />
              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
              <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
                <label style="margin:10% 0 0 0">Created By</label>
              </div>
              <div class="col-xs-9" style="height:100%">
                <input class="form-control" name="CreatedBy" type="text" [(ngModel)]="tickets.UserName" placeholder="Created By" required />
              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
              <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
                <label style="margin:10% 0 0 0">Subject</label>
              </div>
              <div class="col-xs-9" style="height:100%">
                <input class="form-control" name="Subject" type="text" [(ngModel)]="tickets.subject" placeholder="Subject" required />
              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
              <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
                <label style="margin:10% 0 0 0">Priority</label>
              </div>
              <div class="col-xs-9" style="height:100%">
                <select name="tickets.priority" class="form-control" [(ngModel)]="tickets.priority"
                        style=" height: 100%;font-size: 12px;" required>
                  <option selected value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
              <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
                <label style="margin:10% 0 0 0">Assign Ticket To </label>
              </div>
              <div class="col-xs-9" style="height:100%" (click)="ShowAssignTo()">
                <input class="icon form-control" name="AssignTicketTo" type="text" [(ngModel)]="selectedNames" required readonly style="background-color: white !important;" />
              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;height:5%">
              <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
                <label style="margin:10% 0 0 0">CC </label>
              </div>
              <div class="col-xs-9" style="height:100%" (click)="ShowCCTo()">
                <input class="icon form-control" name="CCTicketTo" type="text" [(ngModel)]="selectedCCNames" readonly style="background-color: white !important;" />
              </div>
            </div>

            <div class="col-xs-12" style="margin-top: 2%;padding-right:2px;padding-left: 1%;text-align:left;">
              <div class="col-xs-3" style="padding:0 0 0 2%;font-size:0.8em">
                <label style="margin:10% 0 0 0">Description </label>
              </div>
              <div class="col-xs-9" style="height:100%">
                <textarea name="Description" rows="8" [(ngModel)]="tickets.descriptions" class="form-control" placeholder="Description" style="height:100% !important" required></textarea>
              </div>
            </div>
            <div class="col-xs-9" style="float: right; height: 9%; padding-top: 3%;">

              <div class="col-xs-6" style="padding:0;"><button class="btn displayButton" style="width: 100%;padding: 5px 7px;" (click)="ClearTicket()"><span class="mainCheckMark"></span>Cancel</button></div>
              <div class="col-xs-6" style="padding-right:0%;"><button class="btn noprovider" style="width: 100%; background: #a2e477; padding: 5px 7px;" [disabled]="!ticketform.form.valid" (click)="savetickets();" id="saveOpp"><span class="mainCheckMark"></span>Save</button></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  <!--</div>-->
 
</app-dialog>
<app-dialog [(visible)]="showAssignToDiv" *ngIf="showAssignToDiv" id="showAssignToDiv" [closable]="false">
 
  <div class="col-xs-12" style="padding:0;height:6%;text-align:center">
    <label>ASSIGN TICKET TO</label>

  </div>
  <div class="col-xs-12" style="padding:0.5%;height:6%;border:1px solid lightgray;border-radius:3px;">
    <div class="col-xs-7" style="padding:0;">
      <input type="text" name="selectedAssignTo" [(ngModel)]="selectedNames" readonly style="width: 100%; height: 100%; padding-left: 2%; padding-right: 2%; border: none; background-color: white !important; padding-top: 2%;" />
    </div>
    <div class="col-xs-5" style="padding:0;">
      <div class="col-xs-4" style="display:block;padding:0">
        <button class="btn noprovider" (click)="ClearAssignTo('To')" style="width:100%;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" >Cancel</button>
      </div>
      <div class="col-xs-4 pull-right"  style="display:block;padding:0">
        <button class="btn noprovider" (click)="CloseAssignTo()" style="width: 100%; background: #2196F3; color: white; font-weight: 700; border: 1px solid #000; border-radius: 3px;" >Save</button>
      </div>
    </div>
  </div>
  <div class="col-xs-12 scroll-c" style="height: 82%;border: 2px solid lightgray;padding: 2% 0 2% 0;">
    <div class="col-xs-12" *ngFor="let task of ticketTaskAssignTo" style="padding:0;">
      <div class="col-xs-12" style="display:flex">
        <label class="container1" style="font-size: 17px;">
          &nbsp;&nbsp;&nbsp;
          {{task.itemName}}
          <input type="radio" name="Taskgroup" id="TG{{task.id}}" (click)="selectedTasks(task)">
          <span class="checkmark" id="TG{{task.id}}"></span>
        </label>

      </div>
    </div>
    <div class="col-xs-12" *ngFor="let user of ticketUserAssignTo" style="padding:0;">
      <div class="col-xs-12" style="display:flex">
        <label class="container1" style="font-size: 17px;">
          &nbsp;&nbsp;&nbsp;
          {{user.itemName}}
          <input type="radio" name="Taskgroup" id="UG{{user.id}}" (click)="selectedUser(user)">
          <span class="checkmark" id="UG{{user.id}}"></span>
        </label>

      </div>
    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="showCCToDiv" id="showCCToDiv" *ngIf="showCCToDiv" [closable]="false">
 
  <div class="col-xs-12" style="padding:0;height:6%;text-align:center">
    <label>CC TICKET TO</label>
  </div>
  
  <div class="col-xs-12" style="padding:0;height:6%;border:1px solid lightgray;border-radius:3px;">
    <div class="col-xs-7" style="padding:0;">

      <div class="col-xs-12" style="padding:0;">
        <span> {{selectedCCNamesArray[0]}} ({{selectedCCNamesArray.length}})</span>
      </div>
    </div>
    <div class="col-xs-5" style="padding:0;">
      <div class="col-xs-4" style="display:block;padding:0">
        <button class="btn noprovider" (click)="ClearAssignTo('CCTo')" style="width:100%;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;">Cancel</button>
      </div>
      <div class="col-xs-4 pull-right" style="display:block;padding:0">
        <button class="btn noprovider" (click)="CloseAssignTo()" style="width: 100%; background: #2196F3; color: white; font-weight: 700; border: 1px solid #000; border-radius: 3px;">Save</button>
      </div>
    </div>
  </div>
  <div class="col-xs-12 scroll-c" style="height: 82%;border: 2px solid lightgray;padding: 2% 0 2% 0;">
    <div class="col-xs-12" *ngFor="let task of ticketTaskAssignTo" style="padding:0;">
      <div class="col-xs-12" style="display:flex">
        <label class="container1" style="font-size: 17px;">
          &nbsp;&nbsp;&nbsp;
          {{task.itemName}}
          <input type="checkbox" name="Taskgroup" id="TG{{task.id}}" (click)="selectedCCTasks(task)">
          <span class="checkmark" id="TG{{task.id}}"></span>
        </label>

      </div>
    </div>
    <div class="col-xs-12" *ngFor="let user of ticketUserAssignTo" style="padding:0;">
      <div class="col-xs-12" style="display:flex">
        <label class="container1" style="font-size: 17px;">
          &nbsp;&nbsp;&nbsp;
          {{user.itemName}}
          <input type="checkbox" name="Taskgroup" id="UG{{user.id}}" (click)="selectedCCUser(user)">
          <span class="checkmark" id="UG{{user.id}}"></span>
        </label>

      </div>
    </div>
  </div>
</app-dialog>



<app-dialog [(visible)]="CloseCreateNewWarning" id="CloseCreateNewWarning" *ngIf="CloseCreateNewWarning">
  <div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br /><br />
    <div class="col-xs-8" style="padding: 0;">
      <div class="col-xs-12" style="padding: 0; ">Create New Ticket</div>
      <div class="col-xs-12" style="padding: 0; ">User Must Select Save or Cancel</div>
    </div>
    <div class="col-xs-4" style="padding:0;">
      <div class="col-xs-12" style="padding:0">
        <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                (click)="CloseCreateNewWarning = false ">
          OK
        </button>
      </div>

    </div>
  </div>
</app-dialog>
