<div class="col-xs-12" style="padding: 0; height: 100%; ">
    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ProjectFieldsDiv">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Projects Fields</span>

      </h4>
      <div class="col-xs-12" style="height:7%;display:flex;padding:0;">

        <div class="col-xs-7" style="padding:0;">
          <div class="col-xs-1" style="padding-left:0">
           <img src="../../../assets/img/Create New Document Name.png" (click)="ShowFieldForm('Fields')" style="height:30px" />
          </div>
          <div class="col-xs-5">
            <select class="form-control" (change)="FilterByTypeOfField($event)">

              <option value="1"> Display All Types of Fields </option>
              <option value="2"> Display Standard Fields </option>
              <option value="3"> Display Custom Fields </option>
            </select>


          </div>
          <div class="col-xs-4">
            <select class="form-control" (change)="SortByType($event)">

              <option value="1">Display by Sort Order</option>
              <option value="2">Display by Field Name</option>

            </select>
          </div>

        </div>
      </div>
      <div class="col-xs-12" style="height:87%;display:flex;padding:0;">

        <div class="col-xs-7" style="padding:0">
         <ag-grid-angular style="height:100%;" class="ag-theme-balham"
                           [gridOptions]="gridOptionsView"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="DuplicateProjectFieldsList"
                           [rowDragManaged]="true"
                           [suppressMoveWhenRowDragging]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (selectionChanged)="onSelectionChanged($event)"
                           (gridReady)="onAgGridReady($event)"
                           (rowDragEnd)="onRowDragEnd($event)">
          </ag-grid-angular>
        </div>
        <div class="col-xs-5" style="padding:0;height:100%">
          <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="ShowProjectFieldForm">
            <form #ProjectFieldform="ngForm" style="height:100%;padding:0;">
              <div class="col-xs-12" style="height:90%">
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Field Name</label>
                  </div>
                  <div class="col-xs-8">
                    <div class="col-xs-12" style="padding:0;">
                      <input class="form-control" required id="Fname" name="FieldName" [(ngModel)]="ProjectFields.FieldName" [disabled]="DisableFields" />

                    </div>
                  </div>
                </div>
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Type of Field</label>
                  </div>
                  <div class="col-xs-8">
                    <input class="form-control" id="TypeOfField" name="TypeOfField" [(ngModel)]="ProjectFields.TypeOfField" [disabled]="DisableFields" />
                  </div>
                </div>
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Display As</label>
                  </div>
                  <div class="col-xs-8">
                    <input class="form-control" id="DisplayAs" name="DisplayAs" [(ngModel)]="ProjectFields.DisplayAs" required />

                  </div>
                </div>
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Required Field</label>
                  </div>
                  <div class="col-xs-8">
                    <select class="form-control" name="RequiredField" [(ngModel)]="ProjectFields.RequiredField" required [disabled]="DisableFields">
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Data Type</label>
                  </div>
                  <div class="col-xs-8">
                    <select class="form-control" name="DataType" [(ngModel)]="ProjectFields.DataType" required [disabled]="DisableFields">

                      <option value="null" disabled="disabled">Select Data Type</option>
                      <option value="Pick List">Pick List</option>
                      <option value="Text Box">Text Box</option>
                      <option value="Numeric">Numeric</option>
                      <option value="Date/Time">Date/Time</option>
                      <option value="Date">Date</option>
                      <option value="Date/YearOnly">Date/Year Only</option>
                      <option value="System Lookup">System Lookup</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Pick List Editable</label>
                  </div>
                  <div class="col-xs-8">
                    <select class="form-control" name="PickListEditable" [(ngModel)]="ProjectFields.PickListEditable" required [disabled]="DisableFields">
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Data Format</label>
                  </div>
                  <div class="col-xs-8">
                    <select class="form-control" name="DataFormat" [(ngModel)]="ProjectFields.DataFormat" required [disabled]="DisableFields">
                      <option value="0" disabled="disabled"> Select Data Format</option>
                      <option value="Alphanumeric"> Alphanumeric</option>
                      <option value="Alpha"> Alpha</option>
                      <option value="Numeric"> Numeric</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Max limit</label>
                  </div>
                  <div class="col-xs-8">
                    <input class="form-control" id="MaxLimit" name="MaxLimit" [(ngModel)]="ProjectFields.MaxLimit" required [disabled]="DisableFields" />

                  </div>
                </div>
                <div class="col-xs-12" style="height:21%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Description</label>
                  </div>
                  <div class="col-xs-8">
                    <textarea class="form-control" rows="4" type="text" name="notedescription" style="height:100%" [(ngModel)]="ProjectFields.Description" required></textarea>
                  </div>
                </div>
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Sort Order</label>
                  </div>
                  <div class="col-xs-8">
                    <input class="form-control" id="SortOrder" name="SortOrder" [(ngModel)]="ProjectFields.SortOrder" required [disabled]="DisableFields" />

                  </div>
                </div>
              </div>
              <div class="col-xs-12" style="height:10%;float:right;padding-right:15px">
                <div class="col-xs-6" style="padding: 0;">
                  <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="AccessDiv('Main')">Cancel</button>
                </div>
                <div class="col-xs-6" style="padding-right: 0;">
                  <button type="submit" class="btn saveButton" style="width:100%;" [disabled]="(!ProjectFieldform.form.valid)" (click)="SaveProjectField('Fields')">Save </button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>


    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ProjectFieldsConfigDiv">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Projects Fields Configuration</span>

      </h4>
      <div class="col-xs-12" style="padding:0;height:94%">
        <div class="col-xs-6" style="padding:0;height:100%;overflow:auto">
          <div class="col-xs-12" style="padding:0;height:7%">
            <div class="col-xs-12" style="height:100%;width:100%;">
              <div class="multiselect">
                <div class="selectBox" (click)="showCheckboxes()">
                  <select style="height:100%;border-radius:5px;">
                    <option>Select Field</option>
                  </select>
                  <div class="overSelect"></div>
                </div>
                <div id="checkboxes">

                  <table style="width:100%">
                    <tr style="background-color:#d6dfe2;">
                      <th style="width:6%"></th>
                      <th style="padding-left:1%">Field Name</th>
                      <th style="text-align:center">Type Of Field</th>
                      <th style="text-align:center">Data Type</th>
                      <th style="text-align:center">Pick List<br /> Editable</th>
                    </tr>
                  
                      <tr *ngFor="let field of PickListColumns">
                        <td style="text-align:center"><input type="checkbox" class="FieldCheckBox" id="chkBox{{field.ProjectFieldId}}" (change)="onCheckboxChange(field,$event)" /></td>
                        <td style="padding-left:1%">{{field.FieldName}}</td>
                        <td style="text-align:center">{{field.TypeOfField}}</td>
                        <td style="text-align:center">{{field.DataType}}</td>
                        <td style="text-align:center">{{field.PickListEditable}}</td>
                      </tr>

                    
                    

                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-6" style="padding:0;height:100%">
          <div class="col-xs-12" style="padding:0;height:7%">
            <div class="col-xs-12" style="padding:0" *ngIf="ShowFieldItemTable">
              <div class="col-xs-1" style="padding-left:0">
               <img src="../../../assets/img/Create New Document Name.png" (click)="(SelectedProjectFields.PickListEditable == 'Yes')?AddNewFieldData(0,'Add'):''" style="height:30px" />
              </div>

              <div class="col-xs-6" style="float:right">
                <select class="form-control" (change)="PickListSortByType($event.target.value)" [(ngModel)]="SelectedProjectFields.FieldDataOrderBy">
                  <option value="SortOrder">System Display Sort Order</option>
                  <option value="FieldName">System Display A-Z</option>
                </select>
              </div>
              <div class="col-xs-5" style="float:right">
                <select class="form-control" (change)="PickListSortByType($event.target.value)" [(ngModel)]="SelectedProjectFields.FieldDataOrderBy">
                  <option value="SortOrder">Grid By Sort Order</option>
                  <option value="FieldName">Grid Order A-Z</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-xs-12" style="padding:0;height:92%">
            <div class="col-xs-12" style="height:100%;padding-left:0;" *ngIf="ShowFieldItemTable">
              <div class="col-xs-12 scroll-c" style="height:90%;padding:0;">
                <!--<ag-grid-angular style="height:100%;" class="ag-theme-balham"
                                 [gridOptions]="gridConfigFeildsView"
                                 [columnDefs]="ConfigFeildscolumnDefs"
                                 [defaultColDef]="applicationComponent.defaultGridColDef"
                                 [rowData]="FieldDataList"
                                 [rowDragManaged]="true"
                                 [suppressMoveWhenRowDragging]="true"
                                 [sideBar]="sideBar"
                                 [animateRows]="true"
                                 (gridReady)="onConfigFeildsAgGridReady($event)"
                                 (rowDragEnd)="onConfigFeildsRowDragEnd($event)">
                </ag-grid-angular>-->
                <table style="width:100%">
    <tr style="background-color:#d6dfe2;height: 38px;text-align: center;">
      <th style="width:6%"></th>
      <th style="display:none"></th>
      <th style="text-align:center;width:15%">Sort Order</th>
      <th style="text-align:center;width:60%">Pick List Name</th>
      <th style="text-align:center;width:18%">Actions</th>
    </tr>
    <tbody [dragula]='"ProposalFields-bag"' id="{{SelectedProjectFields.ColumnName}}">
      <tr *ngFor="let fieldData of FieldDataList;let i = index" id="PickListRow{{fieldData.DataId}}" (click)="PickListRowClick(fieldData,'Accounts')">
        <td style="text-align:center;width:6%" id="PickList{{fieldData.DataId}}"><input type="checkbox" /></td>
        <td style="text-align:center;display:none" id="PickListID~{{fieldData.DataId}}">{{fieldData.DataId}}</td>
        <td style="text-align:center;width:15%" id="PickListSortOrder{{fieldData.DataId}}" (keyup)="keyupPickList(fieldData.DataId)">{{fieldData.SortOrder}}</td>
        <td style="text-align:left;padding-left:1%;width:60%;" id="PickListName{{fieldData.DataId}}" (keyup)="keyupPickList(fieldData.DataId)">{{fieldData.itemName}}</td>
        <td style="text-align:center;width:18%"><i class="fa fa-trash" aria-hidden="true" *ngIf="fieldData.DataId !=0 && SelectedProjectFields.PickListEditable == 'Yes'" (click)="DeletePickList(fieldData,'Accounts')"></i></td>
      </tr>

    </tbody>
  </table>
              </div>
              <div class="col-xs-12" style="padding:2% 0 0 0;height:8%">
                <div class="col-xs-6"></div>
                <div class="col-xs-6">
                  <div class="col-xs-6" style="padding: 0;">
                    <button class="btn btn-default" style="width: 100%; border:1px solid">Cancel</button>
                  </div>
                  <div class="col-xs-6" style="padding-right: 0;">
                    <button type="submit" class="btn saveButton" style="width:100%;" (click)="SavePickListData()">Save </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12" *ngIf="ProjectStageDiv" style="padding:0;height:100%">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase;margin-left:1%">Projects Stages</span>

      </h4>
      <div class="col-xs-12" style="padding:0%;height:7%">
        <div class="col-xs-7" style="padding:0;height:100%">
          <div class="col-xs-1" style="padding-left:0">
            <img src="../../../assets/img/Create New Document Name.png" style="height:30px" (click)="ShowProjectStageForm = true" />
          </div>
          <div class="col-xs-7">
          </div>
          <div class="col-xs-4">
            <select class="form-control" (change)="ProjectTyperSelect($event.target.value)" [(ngModel)]="selectedProjectType1">

              <option value="0">Select Project Type</option>
              <option *ngFor="let projectType of ProjectTypeList" value={{projectType.DataId}}>{{projectType.itemName}}</option>

            </select>
          </div>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0%;height:87%">

        <div class="col-xs-7" style="padding:0;height:100%">
         <ag-grid-angular style="height:100%;" class="ag-theme-balham"
                           [gridOptions]="gridOptionsView"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="DuplicateProjectStagesList"                           
                            [rowDragManaged]="true"
                           [suppressMoveWhenRowDragging]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (selectionChanged)="onStageSelectionChanged($event)"
                           (gridReady)="onAgGridReadyStages($event)"
                           (rowDragEnd)="onRowDragEndStages($event)">
          </ag-grid-angular>
        </div>

        <div class="col-xs-5" style="padding:0;height:100%">

          <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="ShowProjectStageForm">
            <form #ProjectStageform="ngForm" style="height:100%;padding:0;">
              <div class="col-xs-12" style="height:90%">
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Stage</label>
                  </div>
                  <div class="col-xs-8">
                    <div class="col-xs-12" style="padding:0;">
                      <input class="form-control" required id="Fname" name="ProjectStageName" [(ngModel)]="ProjectStage.ProjectStageName" [disabled]="DisableStageFields" />

                    </div>
                  </div>
                </div>

                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Project Type</label>
                  </div>
                  <div class="col-xs-8">
                    <input class="form-control" id="TypeOfField" name="ProjectTypeName" [(ngModel)]="ProjectStage.ProjectTypeName" disabled />
                  </div>
                </div>

                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Sort Order</label>
                  </div>
                  <div class="col-xs-8">
                    <input class="form-control" id="SortOrder" name="SortOrder" [(ngModel)]="ProjectStage.SortOrder" required [disabled]="DisableStageFields" />

                  </div>
                </div>
              </div>
              <div class="col-xs-12" style="height:10%;float:right;padding-right:15px">
                <div class="col-xs-6" style="padding: 0;">
                  <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="AccessDiv('Main')">Cancel</button>
                </div>
                <div class="col-xs-6" style="padding-right: 0;">
                  <button type="submit" class="btn saveButton" style="width:100%;" [disabled]="(!ProjectStageform.form.valid)" (click)="SaveProjectField('Stages')">Save </button>
                </div>
              </div>
            </form>
          </div>

        </div>

      </div>

    </div>


    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ProjectStepsDiv">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Projects Steps</span>

      </h4>
      <div class="col-xs-12" style="padding:0%;height:7%">
        <div class="col-xs-7" style="padding:0;height:100%">
          <div class="col-xs-1" style="padding-left:0">
           <img src="../../../assets/img/Create New Document Name.png" style="height:30px" (click)="AddNewProjSteps();" />
          </div>
          <div class="col-xs-3">
          </div>
          <div class="col-xs-4">
            <select class="form-control" (change)="ProjectTypeSelect2($event)" [(ngModel)]="selectedProjectType">

              <option value="0">Select Project Type</option>
              <option *ngFor="let projectType of ProjectTypeList" value={{projectType.DataId}}>{{projectType.itemName}}</option>

            </select>
          </div>
          <div class="col-xs-4">
            <select class="form-control" (change)="ProjectStageSelected($event)" [(ngModel)]="selectedProjectStage">

              <option value="0">Select Project Stages</option>
              <option *ngFor="let projectType of StagesList" value={{projectType.ProjectStageID}}>{{projectType.ProjectStageName}}</option>

            </select>
          </div>

        </div>
      </div>

      <div class="col-xs-12" style="padding:0;height:87%">
        <div class="col-xs-7" style="padding:0;height:100%">
<ag-grid-angular style="height:100%;" class="ag-theme-balham"
                           [gridOptions]="gridOptionsView"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="ProjectStepsList"
                            [rowDragManaged]="true"
                           [suppressMoveWhenRowDragging]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (selectionChanged)="onStepsSelectionChanged($event)"
                           (gridReady)="onAgGridReadySteps($event)"
                           (rowDragEnd)="onRowDragEndSteps($event)">
          </ag-grid-angular>
        </div>
        <div class="col-xs-5" style="padding:0;height:100%">
          <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="ShowProjectStepsForm">
            <form #ProjectStepsform="ngForm" style="height:100%;padding:0;">
              <div class="col-xs-12" style="height:90%">
                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Steps</label>
                  </div>
                  <div class="col-xs-8">
                    <div class="col-xs-12" style="padding:0;">
                      <input class="form-control" required id="Fname" name="ProjectStepName" [(ngModel)]="ProjectSteps.ProjectStepName" [disabled]="DisableStageFields" />

                    </div>
                  </div>
                </div>

                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Project Type</label>
                  </div>
                  <div class="col-xs-8">
                    <input class="form-control" id="ProjectTypeName" name="ProjectTypeName" [(ngModel)]="ProjectSteps.ProjectTypeName" disabled />
                  </div>
                </div>

                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Stage</label>
                  </div>
                  <div class="col-xs-8">
                    <input class="form-control" id="projectStageName" name="projectStageName" [(ngModel)]="ProjectSteps.ProjectStageName" disabled />
                  </div>
                </div>

                <div class="col-xs-12" style="height:20%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Description</label>
                  </div>
                  <div class="col-xs-8">
                    <textarea class="form-control" rows="4" type="text" name="Description" style="height:100%" [(ngModel)]="ProjectSteps.Description" required></textarea>

                  </div>
                </div>

                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Sort Order</label>
                  </div>
                  <div class="col-xs-8">
                    <input class="form-control" id="SortOrder" name="SortOrder" [(ngModel)]="ProjectSteps.SortOrder" required [disabled]="DisableStageFields" />

                  </div>
                </div>

                <!--<div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Status 1</label>
                  </div>
                  <div class="col-xs-8" style="padding:0;height:100%">
                    <div class="col-xs-6" style="height:100%">-->
                      <!--<select class="form-control" [(ngModel)]="ProjectSteps.Status1" name="Status1" disabled>
                        <option value="0" disabled>Select Status</option>
                        <option value="Open" selected>Open</option>
                        <option value="Completed">Completed</option>
                        <option value="N.A">N/A</option>
                        <option value="Declined">Declined</option>
                      </select>-->
                      <!--<input class="form-control" id="Status1" name="Status1" [(ngModel)]="ProjectSteps.Status1" disabled />

                    </div>
                    <div class="col-xs-6" style="height:100%;padding-left:0">
                      <select class="form-control" disabled>
                        <option value="0">Treat as Open/Complete</option>
                        <option value="1" selected>Treated Open</option>
                        <option value="2">Treated Completed</option>
                      </select>
                    </div>


                  </div>
                </div>

                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Status 2</label>
                  </div>
                  <div class="col-xs-8" style="padding:0;height:100%">
                    <div class="col-xs-6" style="height:100%">-->
                      <!--<select class="form-control" [(ngModel)]="ProjectSteps.Status2" name="Status2" disabled>
                        <option value="0" disabled>Select Status</option>
                        <option value="Open">Open</option>
                        <option value="Completed" selected>Completed</option>
                        <option value="N.A">N/A</option>
                        <option value="Declined">Declined</option>
                      </select>-->
                      <!--<input class="form-control" id="Status2" name="Status2" [(ngModel)]="ProjectSteps.Status2" disabled />

                    </div>
                    <div class="col-xs-6" style="height:100%;padding-left:0">
                      <select class="form-control" disabled>
                        <option value="0">Treat as Open/Complete</option>
                        <option value="1">Treated Open</option>
                        <option value="2" selected>Treated Completed</option>
                      </select>
                    </div>


                  </div>
                </div>

                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Status 3</label>
                  </div>
                  <div class="col-xs-8" style="padding:0;height:100%">
                    <div class="col-xs-6" style="height:100%">-->
                      <!--<select class="form-control" [(ngModel)]="ProjectSteps.Status3" name="Status3">
                        <option value="0" disabled>Select Status</option>
                        <option value="Open">Open</option>
                        <option value="Completed">Completed</option>
                        <option value="N.A">N/A</option>
                        <option value="Declined">Declined</option>
                      </select>-->

                      <!--<input class="form-control" id="Status3" name="Status3" [(ngModel)]="ProjectSteps.Status3" />

                    </div>
                    <div class="col-xs-6" style="height:100%;padding-left:0">
                      <select class="form-control">
                        <option value="0">Treat as Open/Complete</option>
                        <option value="1">Treated Open</option>
                        <option value="2">Treated Completed</option>
                      </select>
                    </div>


                  </div>
                </div>

                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Status 4</label>
                  </div>
                  <div class="col-xs-8" style="padding:0;height:100%">
                    <div class="col-xs-6" style="height:100%">-->
                      <!--<select class="form-control" [(ngModel)]="ProjectSteps.Status4" name="Status4">
                        <option value="0" disabled>Select Status</option>
                        <option value="Open">Open</option>
                        <option value="Completed">Completed</option>
                        <option value="N.A">N/A</option>
                        <option value="Declined">Declined</option>
                      </select>-->
                      <!--<input class="form-control" id="Status4" name="Status4" [(ngModel)]="ProjectSteps.Status4" />

                    </div>
                    <div class="col-xs-6" style="height:100%;padding-left:0">
                      <select class="form-control">
                        <option value="0">Treat as Open/Complete</option>
                        <option value="1">Treated Open</option>
                        <option value="2">Treated Completed</option>
                      </select>
                    </div>


                  </div>
                </div>

                <div class="col-xs-12" style="height:8%;padding:0">
                  <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                    <label>Status 5</label>
                  </div>
                  <div class="col-xs-8" style="padding:0;height:100%">
                    <div class="col-xs-6" style="height:100%">
                     
                      <input class="form-control" id="Status5" name="Status5" [(ngModel)]="ProjectSteps.Status5" />

                    </div>
                    <div class="col-xs-6" style="height:100%;padding-left:0">
                      <select class="form-control">
                        <option value="0">Treat as Open/Complete</option>
                        <option value="1">Treated Open</option>
                        <option value="2">Treated Completed</option>
                      </select>
                    </div>


                  </div>
                </div>-->

              </div>
              <div class="col-xs-12" style="height:10%;float:right;padding-right:15px">
                <div class="col-xs-6" style="padding: 0;">
                  <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="AccessDiv('Main')">Cancel</button>
                </div>
                <div class="col-xs-6" style="padding-right: 0;">
                  <button type="submit" class="btn saveButton" style="width:100%;" [disabled]="(!ProjectStepsform.form.valid)" (click)="SaveProjectField('Steps')">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>

    </div>

  </div>
