export class Contacts {
  public id: number;
  public contacttype: string;
  public contacttypeName: string;
  public firstname: string;
  public middlename: string;
  public lastname: string;
  public suffix?: number;
  public sex?: number;
  public sexName: number;
  public title: string;
  public titleName: string;
  public speciality: string;
  public specialityName: string;
  public accountStatus: number;
  public subspeciality?: number;
  public subspecialityName?: string;
  public email: string;
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public zip: string;
  public cell: string;
  public office: string;
  public DOB: string;
  public homeaddress: string;
  public homecity: string;
  public homestate: string;
  public homezip: string;
  public home: string;
  public college?: number;
  public collegeName: string;
  public collegegrandyear: string;
  public medschool?: number;
  public medschoolName: string;
  public medschoolgrandyear: string;
  public residency?: number;
  public residencyName: string;
  public residencygrandyear: string;
  public fellowship?: number;
  public fellowshipName: string;
  public fellowshipgrandyear: string;
  public ownerid?: number;
  public owner: string;
  public providerid?: number;
  public accountid?: number;
  public practiceid?: number;
  public practice: string;
  public accnumber: string;
  public accountname: string;
  public contactName: string;
  public userid?: number;
  public country: string;
  public isdeleted?: number;
  public created: string;
  public type: string;
  public accounttypeName: string;
  public ownershipName: string;
  public callisttype: string;
  public converttolead?: number;
  public photo: string;
  public fileName: string;
  public phototype: string;
  public name: string;
  public isshared: number;
  public islimitedSearch: boolean;
  public lattitude: string;
  public longtitude: string;
  public customertype: string;
  public customertypeName: string;
  public CurrentPage: number;
  public PageSize: number;
  public TotalPages: number;
  public Total: number;
  public TotalContactsCount: number;
  public isaddressadded: boolean;
  public SearchVal: string;
  public contactid1: string;
  public contactid2: string;
  public contactid3: string;
  public contactid123: string;
  public mapit: number;
  public Status: number;
  public STATUS: number;
  public ProvidenceIsland: string;
  public AddLocationId: number;
  public fieldvalues: string;
  public isLicenseKey: boolean;
  public facebook: string;
  public instagram: string;
  public linkedin: string;
  public twitter: string;
  public SassDate: string;
  public SaasTerm: number;
  public SaaSRenewal: string;
  public SaaSRCM: string;
  public SaaStermName: string;
  public Geo: string;
  public LastCompletedTask: string;
  public lastTicketcompleteDate: string;
  public SummaryNotes: string; public SaaS: string;
  public isCustomer: number;
}
