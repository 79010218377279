import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Tickets } from '../../../models/tickets';
import { Dropdowntype } from '../../../models/dropdowntype';
import { CustomerService } from '../../../services/customer.service';
import { TicketNotes } from '../../../models/TicketNotes';
import { UserService } from '../../../services/user.service';
import { Accounts } from '../../../models/accounts';
import { GridOptions } from 'ag-grid';
import { ApplicationComponent } from '../../application.component';
declare var $: any;
@Component({
  selector: 'create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.css']
})
export class CreateTicketComponent implements OnInit {
  @Input() ContOrAccType;
  @Input() contactid; 
  tickets: Tickets = new Tickets();
  contactList: Dropdowntype[] = [];
  selectedNames = '';
  selectedCCNames = '';
  currentuser: any;
  public context;
  public frameworkComponents;
  ticketNotes: TicketNotes = new TicketNotes();
  showCCToDiv = false; ticketTaskAssignTo: any; ticketUserAssignTo: any;
  showAssignToDiv = false;
  public account: Accounts = new Accounts();
  selectedCCNamesArray = []; selectedCCTaskListArray = []; selectedCCUserListArray = [];
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  openTicket: boolean = false;
  public ticketsgridOptions: GridOptions;
  public ticketscolumnDefs: any[];
  ticketslist: Array<Tickets> = [];
  public sideBar;
  constructor(private customerService: CustomerService, public applicationComponent: ApplicationComponent, private userservice: UserService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }
  ngOnInit(): void {
    if (this.ContOrAccType == 'Contact') {
      this.LoadAccountandContacts(this.contactid);
    } else if (this.ContOrAccType == 'Account' || this.ContOrAccType == 'Customer') {
      this.tickets.contactid = null;
      this.LoadAccountandContactsByAccountID(this.contactid)
    }
    this.tickets.UserName = this.currentuser.username;
  }
  OpenTicketDetails() {
    this.loadtickets();
    this.initializeAgGrid();
    this.sendMsgEvent.emit("OpenTicket");
    this.openTicket = true;   
  }

  loadtickets() {
    this.ticketslist = [];  
    if (this.ContOrAccType == 'Contact') {
      this.customerService.GetTicketsListByContact(this.contactid, 'All').then((tickets) => {
        this.ticketslist = tickets;
      });
    } else {
      this.customerService.GetTicketsListByAccount(this.contactid, 'All').then((tickets) => {
        this.ticketslist = tickets;
       

      });
    }
  }
  HideTicket() { this.openTicket = false; this.sendMsgEvent.emit("HideTicket"); }
  initializeAgGrid() {
    this.ticketsgridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: false, paginationPageSize: 1000,toolPanelSuppressPivotMode: false, toolPanelSuppressValues: false, rowSelection: 'single'
    };
    let columnSelections: string;
   
    this.createticketscolumnDefs("");
    this.ticketsgridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
     // editdelete: EditdeleteComponent
    };

  }
  createticketscolumnDefs(columnSelections: string) {
    let selfObj = this;  
    this.ticketscolumnDefs = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      { field: 'ticketId', headerName: 'Ticket', hide: true },
      { field: 'createdDate', headerName: 'Created Date', hide: false },
      { field: 'contactname', headerName: 'Contact name', hide: false },
      { field: 'email', headerName: 'Email', hide: false },
      { field: 'UserName', headerName: 'User Name', hide: false },
      { field: 'userList', headerName: 'Assign Ticket To', hide: false },
      { field: 'subject', headerName: 'Subject', hide: false },
      { field: 'descriptions', headerName: 'Description', hide: false },
      {
        field: 'TicketStatus', headerName: 'Status', hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          var text = '';
          if (params.value == 1) {
            text = 'Open';
          }
          else {
            text = 'Closed'
          }
          eDiv.innerHTML = text;
          return eDiv;
        }
      },
      
    ];
  }

  LoadAccountandContactsByAccountID(accountid) {
   
    this.customerService.loadaccountdata(accountid).then(result => {
      this.account = result;    
      this.tickets.accountName = this.account.accountname;     
      this.customerService.GetAccountContactList(this.account.id).then((conatct) => {
        this.contactList = conatct;
       
      });
    });
  }
  loadcontactdatatoticket(contactid) {
    this.customerService.GetContactById(contactid).then(result => {
      if (result && result.title == null) {
        result.title = "";
      }
      if (result && result.firstname == null) {
        result.firstname = "";
      }
      if (result && result.middlename == null) {
        result.middlename = "";
      }
      if (result && result.lastname == null) {
        result.lastname = "";
      }

      this.contactid = result.id;
      this.tickets.contactid = result.id;
      this.tickets.accountName = result.accountname;
      this.tickets.businessphone = result.office;
      this.tickets.cellphone = result.cell;
      this.tickets.contactname = result.firstname + ' ' + result.middlename + ' ' + result.lastname + ' ' + result.title;
      this.tickets.email = result.email;
      this.tickets.UserID = result.ownerid;
      this.tickets.UserName = this.currentuser.username;
      
    });

  }
  LoadAccountandContacts(ContactId) {
    this.tickets.contactid = ContactId;
    this.customerService.GetContactById(ContactId).then(result => {
      if (result) {
        this.tickets.email = result.email;
        this.tickets.businessphone = result.office;
        this.tickets.cellphone = result.cell;
        this.customerService.loadaccountdata(result.accountid).then(result => {
          this.account = result;        
          this.tickets.accountName = this.account.accountname;
          this.customerService.GetAccountContactList(this.account.id).then((conatct) => {
            this.contactList = conatct;
          });
        });
      }
    });
  }
  selectedCCUser(Value) {

    if ($('#UG' + Value.id).is(":checked")) {
      this.selectedCCUserListArray.push(Value);
      this.selectedCCNamesArray.push(Value.itemName);
    }
    else {
      this.selectedCCUserListArray = this.selectedCCUserListArray.filter(x => x.id !== Value.id);
      this.selectedCCNamesArray = this.selectedCCNamesArray.reduce(Value.itemName);
    }

    this.selectedCCNames = this.selectedCCNamesArray.join(",").toString();
    this.tickets.CCTo = this.selectedCCUserListArray.map(x => x.id).join(",").toString();
  }

  ShowAssignTo() {
  this.showAssignToDiv = true;
    this.showAssignToDivCss('AssignTo');
    this.GetTicketUserLists();
    this.getSelectedTaskGroups('0,1');
  }
  ClearAssignTo(val) {
    if (val == "To") {
      this.selectedNames = "";
      this.tickets.assignTicketToTask = null;
      this.tickets.assignTicketToUser = null;
      this.showAssignToDiv = false;
    } else {
      this.selectedCCNames = '';
      this.tickets.CCTo = null;
      this.tickets.CCToTask = null;
      this.showCCToDiv = false;
      this.selectedCCNamesArray = [];
    }

  }
  selectedTasks(Value) {
    this.selectedNames = '';
    this.tickets.assignTicketToTask = null;
    this.tickets.assignTicketToUser = null;
    if ($('#TG' + Value.id).is(":checked")) {
      this.selectedNames = Value.itemName;
      this.tickets.assignTicketToTask = Value.id;
    }
    else {
      this.selectedNames = '';
      this.tickets.assignTicketToTask = null;
    }
  }
  savetickets() {
 
    this.tickets.UserID = this.currentuser.id;
    this.tickets.TicketStatus = 1;
    this.customerService.addticket(this.tickets).then(result => {
      if (result != null) {
        this.tickets = result;       
        this.saveNotes(this.tickets);
        this.sendMsgEvent.emit("Ticket Saved");
        this.applicationComponent.pageViewed = '';
      }
    });
  }
  ClearTicket() { 
    this.sendMsgEvent.emit("Clear");
    this.applicationComponent.pageViewed = '';
  }
  ShowCCTo() {
    this.showCCToDiv = true;
    this.showAssignToDivCss('CCTo');
    this.GetTicketUserLists();
    this.getSelectedTaskGroups('0,1');
  }

  CloseAssignTo() {
    this.showAssignToDiv = false;
    this.showCCToDiv = false;
  }
  selectedCCTasks(Value) {
    if ($('#TG' + Value.id).is(":checked")) {
      this.selectedCCTaskListArray.push(Value);
      this.selectedCCNamesArray.push(Value.itemName);

    }
    else {
      this.selectedCCTaskListArray = this.selectedCCTaskListArray.filter(x => x.id !== Value.id)
      this.selectedCCNamesArray = this.selectedCCNamesArray.reduce(Value.itemName);

    }
    this.selectedCCNames = this.selectedCCNamesArray.join(",").toString();
    this.tickets.CCToTask = this.selectedCCTaskListArray.map(x => x.id).join(",").toString();
  }
  selectedUser(Value) {
    this.selectedNames = '';
    this.tickets.assignTicketToUser = null;
    this.tickets.assignTicketToTask = null;
    if ($('#UG' + Value.id).is(":checked")) {
      this.selectedNames = Value.itemName;
      this.tickets.assignTicketToUser = Value.id;
    }
    else {
      this.selectedNames = '';
      this.tickets.assignTicketToUser = null;
    }
  }
  showAssignToDivCss(val) {
    setTimeout(function () {
      $('#showAssignToDiv .dialog').attr('style', 'width: 30%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: hidden;height: 85%;left: 68%;top: 10%;padding: 0;');
    }, 4)

    setTimeout(function () {
      $('#showCCToDiv .dialog').attr('style', 'width: 30%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: hidden;height: 85%;left: 68%;top: 10%;padding: 0;');
    }, 4)
  }
  GetTicketUserLists() {
    this.userservice.GetTicketUserLists(this.currentuser.id).then((taskgroup) => {
      this.getSelectedUserList(taskgroup[0].SelectedUserID);
    });
  }
  getSelectedTaskGroups(status) {
    this.ticketTaskAssignTo = [];
  
    this.userservice.GetTaskgrouplist(status).then((taskgroups) => {
      this.ticketTaskAssignTo = taskgroups;
    });
  }
  getSelectedUserList(status) {
    this.ticketUserAssignTo = [];
    this.customerService.Getselectedusers(status).then((users) => {
      this.ticketUserAssignTo = users;
    });
  }
  saveNotes(tickets) {
    this.ticketNotes.NoteId = 0;
    this.ticketNotes.UserID = this.currentuser.id;
    this.ticketNotes.TicketId = tickets.ticketId;
    this.ticketNotes.Notes = this.tickets.descriptions;
    this.ticketNotes.NotesType = "Ticket";
    this.customerService.addticketNotes(this.ticketNotes).then(result => {
     
    })
  }
}
