
<div class="col-xs-12" style="padding:0 0 0 0%;height:99%">
 
  <div class="col-xs-12" style="width:90%;height:6%" id="actiondivid">
    <userdataheader [parentComp]="'CallList'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
  </div>
 
  <div class="col-xs-12  removePadding" style="padding:1%; height: 94%" [style.border]="(applicationComponent.searchCriteriadata != '')?'3px solid #00B0F0':'none'">
    <ag-grid-angular style="width: 100%;height:100%" id="gridheight" class="ag-theme-balham"
                     [gridOptions]="gridOptions"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="rowData"
                     [context]="context"
                     [sideBar]="sideBar"
                     [frameworkComponents]="frameworkComponents"
                     (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                     [suppressDragLeaveHidesColumns]="true"
                     (columnVisible)="columnVisible($event)"
                     (dragStopped)="dragStopped($event)"
                     (columnResized)="columnResized($event)"
                     (gridReady)="onAgGridReady($event)">
    </ag-grid-angular>
  </div>

</div>

