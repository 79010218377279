<div class="container" style="padding:0%;height:100%">
  <input type="text" id="tasklistselectedtyle" style="display:none;" />
  <input type="text" id="accotIdNotes" style="display:none;" />
  <input type="text" id="accotTypeNotes" style="display:none;" />

  <app-dialog [(visible)]="showNotesDialog" *ngIf="showNotesDialog" id="showNotesDialog" style="height:100%" [CloseButtonColor]="'white'" (visibleChange)="onCloseDialogEventReceived($event)">
    <div class="col-xs-12" style="height:6%;background-color:#2A3740;color:white;text-align:center;padding:0%">
      <div class="col-xs-1" style="padding: 0; text-align: left; padding: 1% 0 0 1%;">
        <img src="../../../assets/img/White Expand View.png" style="height:21px" (click)="ExpandPDF(true)" *ngIf="!DocExpandView" />
        <img src="../../../assets/img/White Collapse View.png" style="height:21px" (click)="ExpandPDF(false)" *ngIf="DocExpandView" />
      </div>
      <div class="col-xs-11" style="padding:1% 0 0 0;height:100%"><label>ACCOUNT DETAILS</label></div>
    </div>
    <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%">
      <app-new-notes style="height:100%"
                     [selectedTabName]="'Display All'"
                     [conid]="conid"
                     [typ]="'Contact'"
                     [isModal]="false">
      </app-new-notes>

    </div>

  </app-dialog>
  <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 0.3% 0 0.3% 0; height: 7.5%" id="actiondivid">
    <userdataheader [parentComp]="'TaskList'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
  </div>

  
  <div [ngClass]="(ShowScheduletask == true && PageFrom!='dashboard')? 'col-xs-4':'col-xs-12'" id="taskhistoryinfo" style="height:100%;padding:0;" *ngIf="!TaskExpand">

    <div *ngIf="ShowScheduletask && !TaskExpand && PageFrom!='dashboard'" class="col-xs-12" style="padding:0 0 0 1%;margin:0.5% 0 0 0;height:3%">
      <b style="font-size: 10pt; margin-left: 10px;">
        <span (click)="CloseTaskDiv()"><img src="../../../assets/img/Black x.png" style="height: 21px; float: left; transform: rotate(180deg);" /></span>
        Task List<!--( {{alltaskhistory ? alltaskhistory.length: '0'}} )-->
      </b>
      <span (click)="expandTaskView('Expand')"><img src="../../../assets/img/Open Quickview Grid Panel.png" style="height: 23px; float: right; transform: rotate(180deg);" /></span>

    </div>

    <div class="col-xs-12" style="padding:0;" [style.height.%]="(ShowScheduletask)?'100':'89.5'">
      <div class="col-xs-12" style="height:35%;padding:0;" *ngIf="!ShowScheduletask && PageFrom!='dashboard' && !ExpandTaskGrid && applicationComponent.TaskDataVal == 'Open Tasks'">
        <div class="col-xs-6" style="height:100%;padding:0;">
          <div class="col-xs-5" style="height:100%;padding:0;">
            <div class="col-xs-12" style="height: 99%;margin-top: 1%;padding:0;">
              <div class="col-xs-12" style="height: 20%;padding: 3% 0 0 0.5%;"><label>Tasks Pipeline</label></div>
              <div class="col-xs-12 daysFilterCss" id="DisplayAll">
                <label class="container1">
                  <input type="radio" checked="checked" name="FilterByDays" (click)="FilterByDays('DisplayAll')" /><span class="checkmark"></span>
                  <span>Display All</span>
                  <span style="float:right">{{Below30Days + Betwn31_60Days + Betwn61_90Days + Betwn91_120Days + Above120Days}}</span>
                </label>

              </div>
              <div class="col-xs-12 daysFilterCss" id="30">
                <label class="container1">
                  <input type="radio" name="FilterByDays" (click)="FilterByDays('30')" /><span class="checkmark"></span>
                  <span>30 Days</span>
                  <Span style="float:right">{{Below30Days}}</Span>
                </label>
              </div>

              <div class="col-xs-12 daysFilterCss" id="31-60">
                <label class="container1">
                  <input type="radio" name="FilterByDays" (click)="FilterByDays('31-60')" /><span class="checkmark"></span>
                  <span>31-60 Days</span>
                  <Span style="float:right">{{Betwn31_60Days}}</Span>
                </label>
              </div>
              <div class="col-xs-12 daysFilterCss" id="61-90">
                <label class="container1">
                  <input type="radio" name="FilterByDays" (click)="FilterByDays('61-90')" /><span class="checkmark"></span>
                  <span>61-90 Days</span>
                  <Span style="float:right">{{Betwn61_90Days}}</Span>
                </label>
              </div>
              <div class="col-xs-12 daysFilterCss" id="91-120">
                <label class="container1">
                  <input type="radio" name="FilterByDays" (click)="FilterByDays('91-120')" /><span class="checkmark"></span>
                  <span>91-120 Days</span>
                  <Span style="float:right">{{Betwn91_120Days}}</Span>
                </label>
              </div>
              <div class="col-xs-12 daysFilterCss" id="120+">
                <label class="container1">
                  <input type="radio" name="FilterByDays" (click)="FilterByDays('120+')" /><span class="checkmark"></span>
                  <span> 120+ Days</span>
                  <Span style="float:right">{{Above120Days}}</Span>
                </label>
              </div>

            </div>
          </div>
          <div class="col-xs-6" style="height:100%;margin-left:3%">
            <div class="col-xs-12" style="height:98%;margin-top: 1%">
              <div class="col-xs-12" style="height:20%;padding: 3% 0 0 0.5%;"><label>Tasks Statistics</label></div>
              <div class="col-xs-12" style="height:12.5%;padding:0 0 0 0.5%">
                <div class="col-xs-8" style="padding:0;"><span>Number of Tasks:</span></div>
                <div class="col-xs-4" style="padding:0;text-align:center"><Span>{{alltaskhistory != null? alltaskhistory.length : 0}}</Span></div>
              </div>
              <div class="col-xs-12" style="height:12.5%;padding: 0 0 0 0.5%">
                <div class="col-xs-8" style="padding:0;"><span>Total Phone Calls:</span></div>
                <div class="col-xs-4" style="padding:0;text-align:center"><Span>{{totalPhoneCalls}}</Span></div>
              </div>
              <div class="col-xs-12" style="height:12.5%;padding: 0 0 0 0.5%">
                <div class="col-xs-8" style="padding:0;"><span>Total Meetings:</span></div>
                <div class="col-xs-4" style="padding:0;text-align:center"><Span>{{totalMeetings}}</Span></div>
              </div>
              <div class="col-xs-12" style="height:12.5%;padding:0 0 0 0.5%">
                <div class="col-xs-8" style="padding:0;"><span>Total To Do's:</span></div>
                <div class="col-xs-4" style="padding:0;text-align:center"><Span>{{totalToDos}}</Span></div>
              </div>

            </div>

          </div>

        </div>
        <div class="col-xs-6 box-body" style="height:100%;padding:0;">
          <div class="col-xs-12" style="height:2%;"></div>
          <div class="col-xs-12" style="height:98%;padding:0;">
            <div class="col-md-5 headerCSS" style="width:50%;padding:3%">
              <canvas baseChart
                      [data]="pieChartData"
                      [chartType]="customerpieChartType"
                      [colors]="customerpieChartcolors"
                      [labels]="pieChartLabels"
                      [legend]="false"></canvas>
            </div>
            <div class="col-xs-1" style="padding:0px;margin-top:2%;font-size:16px !important;width:5%">
              <div *ngFor="let lbl of pieColors">
                <div [ngStyle]="{'background-color': lbl , 'width':'20px' , height:'10px'}"></div><br />
              </div>
            </div>
            <div class="col-md-6" style="padding:0px;margin-top:0;width:45%;height:98%">
              <div class="col-xs-12" style="height:85%;padding:0;">
                <div *ngFor="let lbl of customerpieChartLabels" class="col-xs-12" style="padding:0;width:98%">
                  <div class="col-xs-6" style="padding:0;">
                    <h6>{{lbl.name}}</h6>
                  </div>
                  <div class="col-xs-3" style="padding:0;">
                    <h6>{{lbl.Total}}</h6>
                  </div>
                </div>
              </div>
              <div class="col-xs-12" style="height:15%;padding:0;">
                <div class="col-xs-2" style="padding:0;height:100%;float:right;text-align:right">
                  <img src="../../../assets/img/Collapse View .png" style="height:81%;width:18%;margin:0" *ngIf="ExpandTaskGrid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12 input-icons" style="padding: 7px; margin-bottom: 0.3%; border: 1px solid lightgray; width:100%">
        <i class="fa fa-search icon"></i>
        <input type="text" class="searchform-control" id="filter-text-box" placeholder="Search" (input)="onFilterTextBoxChanged()" [(ngModel)]="value" />
      </div>
      <div class="col-xs-12" style="padding:0;margin-top: 0;" [style.height.%]="(ShowScheduletask == true)? '100':(ExpandTaskGrid == true)? '100': (applicationComponent.TaskDataVal == 'Open Tasks')? '65' :'100' ">
        <div class="col-xs-12" style="height:5%;padding:0;" *ngIf="ExpandTaskGrid && !ShowScheduletask">
          <div class="col-xs-1" style="padding:0;height:100%;float:right;text-align:right" *ngIf="ExpandTaskGrid">

            <img src="../../../assets/img/Collapse View .png" style="height:75%;width:18%;margin:0" (click)="ExpandGrid(false)" />
          </div>
        </div>
        <div class="col-xs-12" style="padding:0;" [style.height.%]="ShowScheduletask && id>0? '91':'105'" [style.border]="(applicationComponent.searchCriteriadata != '')?'3px solid #00B0F0':'none'">
          <ag-grid-angular style="width: 100%; padding-top: 10px;height:98%" id="gridheight" (window:resize)="ResizeTable()" class="ag-theme-balham"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="alltaskhistory"
                           [context]="context"
                           [sideBar]="sideBar"
                           [frameworkComponents]="frameworkComponents"
                           (cellValueChanged)="onCellValueChanged($event)"
                           (cellDoubleClicked)="cellDoubleClicked($event)"
                           (columnVisible)="columnVisible($event)"
                           (dragStopped)="dragStopped($event)"
                           (columnResized)="columnResized($event)"
                           (selectionChanged)="onTaskListChanged($event)"
                           (gridReady)="onAgGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="TaskExpand" class="col-xs-4" style="padding:0;height:100%;">
    <div class="col-xs-12" style="padding:0;height:4%">
      <span (click)="expandTaskView('Collapse')"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
    </div>
    <div class="col-xs-12 scroll-c" style="padding:0;height:100%">
      <app-new-notes style="height:100%"
                     [selectedTabName]="'1'"
                     [conid]="conid"
                     [typ]="'Contact'"
                     [ViewType]="'Block'"
                     [isModal]="false">
      </app-new-notes>

    </div>
  </div>

  <div id="addtask" class="col-xs-8" *ngIf="ShowScheduletask && id>0" style="height:100%;padding:0% 0 0 1%">
    <scheduletask [id]="id" [taskid]="taskid" [type]="type" (sendMsgEvent)="onReceiveMessage($event)" (sendGridRefreshEvent)="GridRefresh($event)"></scheduletask>
  </div>



  <div *ngIf="TaskDataEventListener()"></div>
  <div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
    <textarea *ngIf="isShowDetailsChecked" id="txtUserFullName" readonly rows="3" name="userInfo" [(ngModel)]="userInfo" style="width:100%; border: none;"></textarea>
    <div class="col-xs-12" style="padding:0;">
      <button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog" style="float:left;position:unset; "><i class="fa fa-window-close-o"></i></button>
    </div>
    <div class="box" style="height:98%;border:none">
      <span *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" class="editnote">Edit</span>
      <span *ngIf="showEditNoteBtn" (click)="saveEditedNote()" class="save">Save</span>
      <div id="txtLongNoteDesc" class="text">
      </div>
    </div>
  </div>
</div>

