<div class="CallListcontainer" style="height:100%;padding: 0;"> 
  <input type="text" id="emailIds" style="display:none;height:100%" />
  <input type="text" id="tasklistselectedtyle" style="display:none;height:100%" />
  <div class="col-xs-12" style="padding: 0 0 0 0.4%;height:100%">
    <div class="col-xs-12" style="padding: 0%;background:#fff;height:100%">
      <div class="col-xs-12 removePadding" style="padding:0;height:100%;">
        <div id="agCallListContacts" *ngIf="isCalllistOpen" class="col-xs-4"
             style="margin-top:0.5%;height:99%;padding:0 1% 0 0;width:32%">
          <div class="col-xs-12" style="padding:0;" [style.height.%]="ShowButtons ? '14' : '22'">
            <div *ngIf="isCalllistOpen" class="col-xs-12" style="padding:0">
              <div class="col-xs-11" style="padding-left:0;padding:0;">
                <b style="padding-left: 10px; " *ngIf="calllist.id==0">CREATE</b> <b> CALL LIST</b>
              </div>
              <div class="col-xs-1" style="padding-left:0;padding:0;">
                <span (click)="expandcalllist('Collapse')"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="    float: right;height: 23px;transform: rotate(180deg);" /></span>
              </div>
            </div>
            <div class="col-xs-12" style="padding:0;">
             <input *ngIf="calllist.id==0" type="text" class="form-control"
                     [readonly]="((calllist.id!=0 && calllist.userid!=this.currentuser.id )?true:false)"
                    [(ngModel)]="calllist.calllistname"
                    (keyup)="calllist.calllistname=(transform($event.target.value));applicationComponent.Iscalllisterrorshow=true;ShowButtons= true"
                     placeholder="Enter Call List Name" />
              <div *ngIf="calllist.id>0" class="col-xs-12" style="margin-top: 0.5%; padding:0; font-size: 13px;">

                <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding:0">
                  <div class="col-xs-3" style="padding-left:0;padding:0;">
                    Assigned To
                  </div>
                  <div class="col-xs-9" style="padding:0 0 0 0;">
                    <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                                          [settings]="dropdownSetting"
                                          (onSelect)="onItemSelect($event)"
                                          (onDeSelect)="OnItemDeSelect($event)">
                    </angular2-multiselect>
                  </div>
                </div>
                <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding:0">
                  <div *ngIf="calllist.id!=0" class="col-xs-3" style="padding:0;">
                    Status
                  </div>
                  <div *ngIf="calllist.id!=0" class="col-xs-9" style="padding: 0 0 0 0;">
                    <select id="callstatus" class="form-control" [(ngModel)]="calllist.status" style="height: 30px; font-size: 0.85em !important;" (change)="ShowButtons= true">
                      <option value="Open">Open</option>
                      <option value="Completed">Completed</option>

                    </select>
                  </div>
                </div>
                <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;padding:0">
                  <div *ngIf="calllist.id!=0" class="col-xs-3" style="padding:0;">
                    List Name:
                  </div>
                  <div *ngIf="calllist.id!=0" class="col-xs-9" style="padding: 0 0 0 0;">
                   <input type="text" style=" font-size: 0.85em !important;" class="form-control" [(ngModel)]="calllist.calllistname"
                     (keyup)="calllist.calllistname=(transform($event.target.value));this.applicationComponent.Iscalllisterrorshow=true;ShowButtons= true"
                            placeholder="Enter Call List Name" />
                  </div>
                </div>

              </div>

              <div class="col-xs-4" style="padding:0;float:right" *ngIf="ShowButtons">
                <div class="col-xs-6" style="padding: 0;margin-left: 2%;margin-top: 2%">
                  <button class="btn btn-default" style="width:100%;" (click)="cancelCallList();">Cancel</button>
                </div>
                <div class="col-xs-5" id="saveButtonDiv" style="padding: 0;margin-left:2%;margin-top:2%;border: 1px solid black;">
                  <button class="btn noprovider" [disabled]="((calllist.calllistname!=null && calllist.calllistname!='')?false:true)" style="display:block;width:100%;" (click)="saveCalllists();">Save</button>
                </div>
              </div>
            </div>

          </div>
          <ag-grid-angular style="width: 100%; height:86%" id="gridheight" class="ag-theme-balham" *ngIf="applicationComponent.Calllistselectedtype == 'Contact'|| applicationComponent.Calllistselectedtype == 'CustomerContact'"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="allContacts"
                           [context]="context"
                           [sideBar]="sideBar"
                           [frameworkComponents]="frameworkComponents"
                           [suppressDragLeaveHidesColumns]="true"
                           (selectionChanged)="selectionChanged($event)"
                           (columnVisible)="columnVisible($event)"
                           (dragStopped)="dragStopped($event)"
                           (columnResized)="columnResized($event)"
                           (gridReady)="onAgGridReady($event)">
          </ag-grid-angular>


          <ag-grid-angular style="" [style.height.%]="ShowButtons ? '71' : '76'" class="ag-theme-balham" *ngIf="applicationComponent.Calllistselectedtype == 'Customer' ||applicationComponent.Calllistselectedtype == 'Contacts/Accounts'"
                           [gridOptions]="gridOptions"
                           [columnDefs]="AccountcolumnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="allCustomers"
                           [context]="context"
                           [sideBar]="sideBar"
                           [frameworkComponents]="frameworkComponentsAccounts"
                           (columnVisible)="AccountcolumnVisible($event)"
                           (dragStopped)="AccountdragStopped($event)"
                           (columnResized)="AccountcolumnResized($event)"
                           (selectionChanged)="selectionAccountChanged($event)"
                           (gridReady)="onAgGridReady($event)">
          </ag-grid-angular>
        </div>

        <div *ngIf="!isCalllistOpen" class="col-xs-4" style="margin-top:0.5%;height:99%;padding:0 1% 0 0;width:32%">
          <div class="col-xs-12" style="padding:0;height:4%">
            <span (click)="expandcalllist('Expand')"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
          </div>
          <div class="col-xs-12 scroll-c" style="padding:0;height:100%">

            <app-new-notes style="height:100%"
                           [selectedTabName]="'1'"
                           [conid]="account.id"
                           [typ]="'Account'"
                           [ViewType]="'Block'"
                           [isModal]="false">
            </app-new-notes>

          </div>
        </div>

        <div *ngIf="applicationComponent.Calllistselectedtype != 'Contact'&& account.id>0 " id="accView" [ngClass]="'col-xs-8'" [style.width.%]="'68'" style="padding:0;height:99%">

          <account-contact [type]="'Account'" [contactId]="'show'+account.id" [tab]="'QuickView'" (sendMsgEvent)="onAccountMessageReceived($event)"></account-contact>
        </div>

        <div *ngIf="applicationComponent.Calllistselectedtype == 'Contact' && contactid>0" [ngClass]="'col-xs-8'" [style.width.%]="'68'" style="padding: 0; height: 100%">
          <contactsdashboard [contactids]="contactid" [type]="'Contact'" [tab]="'QuickView'" (sendMsgEvent)="onAccountMessageReceived($event)"></contactsdashboard>
        </div>

        <app-dialog id="SaveDialog" *ngIf="ConverttoCVshowDialog" [(visible)]="ConverttoCVshowDialog">
          <div class="col-xs-12" style="padding:5%">
            <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
            <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
              <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">CALL LIST</div><br />
              <div class="col-xs-12" style="padding: 0; padding-top: 3%;">Save Call List?</div>
            </div>
            <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
              <div class="col-xs-7" style="padding: 0;">

              </div>
              <div class="col-xs-5" style="padding:0;">
                <div class="col-xs-6" style="padding:0">
                  <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                          (click)="SaveCalllist()">
                    SAVE
                  </button>
                </div>
                <div class="col-xs-6" style="padding:0">
                  <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1" (click)="CloseCalllist();">Discard</button>
                </div>

              </div>
            </div>
          </div>
         
        </app-dialog>

        <app-dialog [(visible)]="showCancelDialog" id="cancelDialog">
          <app-delete-popup [status]="0" [title]="'CALL LIST'" [description]="'Do Not save this Call List?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="closeCancelDialogGoToContacts()">
          </app-delete-popup>

        </app-dialog>

        <app-dialog id="deleteDialog" [(visible)]="showDeleteDialog">

          <div class="col-xs-12" style="text-align:center; padding-left: 13%;padding-right: 10%;">
            <h3>Contact will be removed from Call List</h3>
            <h3>Please Confirm</h3>
          </div>
          <div class="col-xs-12" style="margin-top: 2%;margin-bottom: 2%;padding-left: 13%;padding-right: 13%;">
            <div class="col-xs-6" style="padding-right: 0;">
              <button class="btn" style="width:100%;height: 35px;" (click)="RemoveContact(selectedcontactid)">Yes</button>
            </div>
            <div class="col-xs-6">
              <button (click)="closeDeleteDialog()" class="btn" style="width:100%;height: 35px;">No</button>
            </div>
          </div>

        </app-dialog>
        <app-dialog id="ContactDialog" [(visible)]="showContactDialog" *ngIf="showContactDialog">
          <div class="col-xs-12" style="padding:0px">
            <div class="col-xs-12" style="font-size:27px;">
              <label style="font-size:17px;font-weight:800">SalesXCRM Message</label> <br />
            </div>
            <div *ngIf="selectedstatus != 'Completed'" class="col-xs-8" style="margin-top: 4%;">
              <label style="font-size:14px;">CALL COMPLETED</label><br />
              <label style="font-size:14px;">Select OK to Confirm Call Completed ?</label><br />
            </div>
            <div *ngIf="selectedstatus == 'Completed'" class="col-xs-8" style="margin-top: 4%;">
              <label style="font-size:14px;">CALL LIST</label><br />
              <label style="font-size:14px;">Remove Check Box?</label><br />
            </div>
            <div class="col-xs-4" style="margin-top: 4%;">
              <div *ngIf="selectedstatus != 'Completed'" class="col-xs-6" style="padding: 0; margin-top: 12px;">
                <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%" (click)="calllistcontactstatus(selectedcontactid,'Contracted')">OK</button>
              </div>
              <div *ngIf="selectedstatus == 'Completed'" class="col-xs-6" style="padding: 0; margin-top: 12px;">
                <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%" (click)="calllistcontactstatus(selectedcontactid,'Open')">SAVE</button>
              </div>
              <div class="col-xs-6" style="padding: 0; margin-top: 12px;">
                <button class="btn" style="float: right; background-color: white !important; color: #00b3f7d9; margin-top: 1%" (click)="calllistdialogclose('Contracted')">Cancel</button>
              </div>

            </div>
          </div>

        </app-dialog>
        <app-dialog [(visible)]="showContactOpenDialog">
          <div style="background-color: rgb(244,244,244); height: 100%; width: 100%" class="col-xs-12">
            <div class="row" style="text-align:center; padding-left: 13%;padding-right: 10%;">
              <div class="row" style="font-size:18px;padding-top: 2%">
                "Do you want to change the status<br /> of this contact from Deleted to Open "
              </div>

            </div>
            <div class="row" style="margin-top: 2%;margin-bottom: 2%;padding-left: 13%;padding-right: 13%;">
              <div class="col-xs-6" style="padding-right: 0;">
                <button class="btn noprovider" style="width:100%;height: 40px;" (click)="calllistcontactstatus(selectedcontactid,selectedstatus)">Yes</button>

              </div>
              <div class="col-xs-6">
                <button (click)="calllistdialogclose('Open')" class="btn noprovider" style="width:100%;height: 40px;">No</button>
              </div>
            </div>
          </div>
        </app-dialog>
        <app-dialog [(visible)]="contactshowDialog" *ngIf="contactshowDialog" id="addEditContact" [closable]="false" [OutSideClick]="false">
          <editcontact [contactid]="contactid" [accountId]="accountid" [type]="'Contact'" (sendMsgEvent)="ContactReceiveEvent($event)"></editcontact>
        </app-dialog>
        <app-dialog [(visible)]="assigncalllistDialog">
          <div style="height: 100%; width: 100%;">
            <div class="row" style="text-align:center; padding-left: 13%;padding-right: 10%;">
              <div class="row" style="font-size:18px;padding-top: 2%">
                Do you want to assign this call list to selected User?
              </div>

            </div>
            <div class="row" style="margin-top: 2%;margin-bottom: 2%;padding-left: 13%;padding-right: 13%;">
              <div class="col-xs-6" style="padding-right: 0;">
                <button class="btn noprovider" (click)="assignuser(assignuserid)" style="width:100%;height: 40px;">Yes</button>

              </div>
              <div class="col-xs-6">
                <button class="btn noprovider" (click)="assigncalllistDialog= !assigncalllistDialog" style="width:100%;height: 40px;">No</button>
              </div>
            </div>
          </div>
        </app-dialog>

        <input type="text" id="accotIdNotes" style="display:none;" />
        <input type="text" id="accotTypeNotes" style="display:none;" />

        <app-dialog *ngIf="isModal" [(visible)]="notesshowDialog">

          <app-notes [title]="'Customer Notes Info'" style="height:100%"
                     [source]="'Customer'"
                     [account]="account"
                     [gridOptionsnotes]="gridOptionsnotes"
                     [allnotes]="allnotes"
                     [context]="context"
                     [columnDefsnotes]="columnDefsnotes"
                     [contact]="account"
                     [tabName]="'Contact'"
                     [conid]="conid"
                     [typ]="'Customer'">
          </app-notes>
        </app-dialog>
      </div>
    </div>
  </div>
  <div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
    <textarea *ngIf="isShowDetailsChecked" id="txtUserFullName" readonly rows="3" name="userInfo" [(ngModel)]="userInfo" style="width:100%; border: none;"></textarea>
    <div class="col-xs-12" style="padding:0;">
      <button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog" style="float:left;position:unset; "><i class="fa fa-window-close-o"></i></button>
    </div>
    <div class="box" style="height:98%;border:none">
      <span *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" class="editnote">Edit</span>
      <span *ngIf="showEditNoteBtn" (click)="saveEditedNote()" class="save">Save</span>
      <div id="txtLongNoteDesc" class="text">
      </div>
    </div>
    <!--<button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog">X</button>-->
  </div>
</div>
<app-dialog [(visible)]="DeleteConfirmationPopup" *ngIf="DeleteConfirmationPopup" id="DeleteConfirmationPopup" [closable]="false">
  <app-delete-popup [status]="contactstatus" [MessageType]="'Call List'" (sendMsgEvent)="ReceiveDeleteevent($event)">

  </app-delete-popup>
</app-dialog>
<app-dialog [(visible)]="showAddEditContact" *ngIf="showAddEditContact" id="addEditContact" [closable]="false" [OutSideClick]="false">
  <editcontact [contactid]="contactid" [accountId]="account.id" [type]="'Contact'" (sendMsgEvent)="ContactReceiveEvent($event)"></editcontact>
</app-dialog>

<app-dialog [(visible)]="showAddEditAccount" *ngIf="showAddEditAccount" id="addEditAccount" [closable]="(action!='View')? false:true" [OutSideClick]="(action!='View')? false:true">
  <app-add-edit-account *ngIf="action!='View'" [accountID]="this.accountid" [type]="'Account'" (sendMsgEvent)="ReceiveEvent($event)"></app-add-edit-account>
  <accountorcontactview *ngIf="action=='View'" [accountId]="this.account.id" [type]="'Account'"></accountorcontactview>
</app-dialog>
<app-dialog *ngIf="isSharing" [(visible)]="isSharing" id="isSharingDialog" [closable]="false">
  <ownershipsharing [AccId]="account.id" (sendMsgEvent)="onCloseEventReceived($event)"></ownershipsharing>
</app-dialog>

<script>
  $(document).mouseup(function (e) {
    var container = $(".container");
    if (!container.is(e.target) && container.has(e.target).length === 0) {

      container.hide();
    }
  });
</script>
