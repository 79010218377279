<div class="col-xs-12" id="AddAccountDiv" style="height:100%;padding:0;">
  <form #accountform="ngForm" (keydown.enter)="$event.preventDefault()" style="height:100%">
    <div class="col-xs-12" style="padding-left:0;padding-right: 0;height:100%" id="boxDiv">

      <div id="addAccountDetais" style="height: 100%; font-weight: 600;">
        <div  class="col-xs-12" style="padding: 1%; height: 2%; background-color: rgb(186,220,153)">       
        </div>
        <div *ngIf="(account.id!=0)" class="col-xs-11" style="padding: 1%; height: 5%;text-align: left;font-size:15px;">
          ACCOUNT INFORMATION
        </div>
        <div class="col-xs-1" style="padding-top:1%">
          <img src="../../../assets/img/Black x.png" (click)="close()" style="height:20px;">
        </div>
        <div class="col-xs-12" id="accountHeader" style="text-align:center;color:black;border-radius:3px;margin-top: 0;height:16%;padding:0;" *ngIf="!ExpandMode">
          <div class="col-xs-12" style="padding:0;height:100%;margin-top:0.5%">
            <div class="tabset" style="padding:0">

              <input type="radio" name="tabset" id="tab4" value="tab4" aria-controls="bano" [(ngModel)]="selectedMenuRadio" />
              <label for="tab4" style="text-align:center;width:24%" (click)="tabChanged('tab4')">PRIMARY ADDRESS </label>
              <input type="radio" name="tabset" id="tab1" value="tab1" aria-controls="bano" [(ngModel)]="selectedMenuRadio" />
              <label for="tab1" style="text-align:center;width:24%" (click)="tabChanged('tab1')">ACCOUNT SPECIFICS</label>
              <input type="radio" name="tabset" id="tab5" value="tab5" disabled aria-controls="bano" [(ngModel)]="selectedMenuRadio">
              <label for="tab5" style="text-align: center; width: 24%;" (click)="tabChanged('tab5')">CUSTOMER DETAILS</label>
              <input type="radio" name="tabset" id="tab6" value="tab6" disabled aria-controls="bano" style="margin-left: 1px" [(ngModel)]="selectedMenuRadio">
              <label for="tab6" style="text-align: center; width: 24%;" (click)="tabChanged('tab6')">SYSTEM NOTES </label>

              <input type="radio" name="tabset" id="tab7" value="tab7" [(ngModel)]="selectedMenuRadio" aria-controls="bano" style="margin-left: 1px">
              <label for="tab7" style="text-align:center;width:24%" (click)="tabChanged('tab7')">ADDITIONAL LOCATIONS</label>
              <input type="radio" name="tabset" id="tab0" value="tab0" aria-controls="bano" [(ngModel)]="selectedMenuRadio" checked />
              <label for="tab0" style="text-align:center;width:24%" (click)="tabChanged('tab0')">ACCOUNT HISTORY</label>

              <input type="radio" name="tabset" id="tab2" value="tab2" aria-controls="bano" [(ngModel)]="selectedMenuRadio" />
              <label for="tab2" style="text-align:center;width:24%" (click)="(checkPrevilege() == true)? tabChanged('tab2'): ''">CUSTOMER LICENSES</label>

              <input type="radio" name="tabset" id="tab3" value="tab3" aria-controls="bano" />
              <label for="tab3" style="text-align: center; width: 24%; color: lightgrey ">EMPTY </label>

              <input type="radio" name="tabset" id="tab7" value="tab7" aria-controls="bano" style="margin-left: 1px">
              <label for="tab7" style="text-align: center; width: 24%; color: lightgrey">EMPTY</label>

              <input type="radio" name="tabset" id="tab10" value="tab10" aria-controls="bano" [(ngModel)]="selectedMenuRadio" />
              <label for="tab1" style="text-align:center;width:24%" (click)="tabChanged('tab10')">ACCOUNT STATISTICS</label>

              <input type="radio" name="tabset" id="tab11" value="tab11" aria-controls="bano" [(ngModel)]="selectedMenuRadio" />
              <label for="tab11" style="text-align:center;width:24%" (click)="tabChanged('tab11')">CUSTOMER STATISTICS</label>

              <input type="radio" name="tabset" id="tab6" value="tab6" disabled aria-controls="bano" style="margin-left: 1px">
              <label for="tab6" style="text-align: center; width: 24%; color: lightgrey">EMPTY </label>

            </div>
          </div>
        </div>
        <div class="col-xs-12" style="padding:0;height:83%;font-size: 12px;" *ngIf="selectedMenuRadio == 'tab0'">
          <div class="col-xs-12" style="height:100%;padding:1%;display:flex">
            <div class="col-xs-6" style="height:100%;padding-left:0.5%">
              <div class="col-xs-12" style="padding:0;height:4%;">
                <div class="col-xs-5" style="height:100%;padding:0;"><span>Account Type</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">{{account.accounttypeName}}</div>
              </div>
              <div class="col-xs-12" style="padding:0;height:4%;">
                <div class="col-xs-5" style="height:100%;padding:0;"><span>Ownership</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">{{account.ownershipName}}</div>
              </div>
              <div class="col-xs-12" style="padding:0;height:4%">
                <div class="col-xs-5" style="height:100%;padding:0;"><span>Account Status</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">{{(account.accountStatus == 14)? 'Active' :'On Hold' }}</div>
              </div>
              <div class="col-xs-12" style="padding:0;height:4%">
                <div class="col-xs-5" style="height:100%;padding:0;"><span>Account Owner</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">{{account.OWNER}}</div>
              </div>

              <div class="col-xs-12" style="padding:0;height:4%">
                <div class="col-xs-5" style="height:100%;padding:0;"><span>Lead From</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">
                  <span *ngIf="accountHistory.LeadFrom">{{accountHistory.LeadFrom}}</span>
                </div>
              </div>

              <div class="col-xs-12" style="padding:0;height:4%">
                <div class="col-xs-5" style="height:100%;padding:0"><span>Last Contact Date</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">{{this.LastContactDate}}</div>
              </div>
              <div class="col-xs-12" style="padding:0;height:4%">
                <div class="col-xs-5" style="height:100%;padding:0;"><span>Key Contact (#1)</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">{{accountHistory.KeyContact1Name}}</div>
              </div>

              <div class="col-xs-12" style="padding:0;height:4%">
                <div class="col-xs-5" style="height:100%;padding:0;"><span>Key Contact (#2)</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">{{accountHistory.KeyContact2Name}}</div>
              </div>

              <div class="col-xs-12" style="padding:0;height:4%">
                <div class="col-xs-5" style="height:100%;padding:0"><span>Key Contact (#3)</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">{{accountHistory.KeyContact3Name}}</div>
              </div>

              <div class="col-xs-12" style="padding:0;height:4%">
                <div class="col-xs-5" style="height:100%;padding:0"><span>EHR</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">{{account.ehrName}}</div>
              </div>

              <div class="col-xs-12" style="padding:0;height:4%">
                <div class="col-xs-5" style="height:100%;padding:0;"><span>PM</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">{{account.pmName}}</div>
              </div>

              <div class="col-xs-12" style="padding:0;height:4%">
                <div class="col-xs-5" style="height:100%;padding:0;"><span>RCM</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">{{account.rcmName}}</div>
              </div>

              <div class="col-xs-12" style="padding:0;height:4%">
                <div class="col-xs-5" style="height:100%;padding:0;"><span>Sales Rep</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;">{{salesrepName}}</div>
              </div>
              <div class="col-xs-12" style="padding:0;height:4%;font-weight:800">
                <div class="col-xs-5" style="height:100%;padding:0;"><span [ngStyle]="{'color':account.customertypeid==0 ? 'gray' : 'black' }">Customer</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;" [ngStyle]="{'color':account.customertypeid==0 ? 'gray' : 'black' }">
                  <span *ngIf="account.isCustomer==874">Yes</span>
                  <span *ngIf="account.isCustomer!=874">No</span>
                </div>
              </div>

              <div class="col-xs-12" style="padding: 0; height: 4%; font-weight: 800;">
                <div class="col-xs-5" style="height:100%;padding:0;"><span [ngStyle]="{'color':account.customertypeid==0 ? 'gray' :'black' }">Customer Since</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;"><span>{{account.CustomerSince | date: 'MM-dd-yyyy'}}</span></div>
              </div>
              <div class="col-xs-12" style="padding: 0; height: 4%; font-weight: 800" *ngIf="(this.customeracces==1)">
                <div class="col-xs-5" style="height:100%;padding:0;"><span [ngStyle]="{'color':account.customertypeid==0 ? 'gray' : 'black' }">Customer type</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;" [ngStyle]="{'color':account.customertypeid==0 ? 'gray' :'black' }">
                  <span *ngIf="(account.customertypeid == 1 || account.customertypeid == 2)">{{(account.customertypeid == 2)? 'Former Customer' : (account.customertypeid == 1)? 'Customer' : 'Non Customer' }}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding: 0; height: 4%; font-weight: 800">
                <div class="col-xs-5" style="height:100%;padding:0;"><span [ngStyle]="{'color':account.customertypeid==0 ? 'gray' :'black' }">Sold By</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;" [ngStyle]="{'color':account.customertypeid==0 ? 'gray' :'black' }">
                  <span *ngIf="(account.customertypeid == 1 || account.customertypeid == 2)">{{(accountHistory.SoldBy == 0)?'Sales Team':'Reseller'}}</span>
                </div>
              </div>
              <div class="col-xs-12" style="padding: 0; height: 4%; font-weight: 800">
                <div class="col-xs-5" style="height:100%;padding:0;"><span [ngStyle]="{'color':account.customertypeid==0 ? 'gray' : 'black' }">Last Ticket Date</span></div>
                <div class="col-xs-7" style="height:100%;padding:0;" [ngStyle]="{'color':account.customertypeid==0 ? 'gray' :'black' }">{{this.LastTicketDate}}</div>
              </div>
            </div>
            <div class="col-xs-6" style="height:100%;padding-right:0.5%">
              <div class="col-xs-12" style="padding:1.5% ;height:100%" *ngIf="accountHistory && accountHistory.HistoryNotes">
                <textarea id="notesTextArea" rows="6" type="text" name="notedescription" disabled style="height: 98%; width: 100%; padding: 2%;border: 1px solid black">{{accountHistory.HistoryNotes}}</textarea>
              </div>
            </div>



          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:83%;font-size:12px;" *ngIf="selectedMenuRadio == 'tab1'">

          <div class="col-xs-12 removeCompletePadding scroll-c" id="accountBody" style="padding:1%;overflow:auto; margin-top:0px; border-radius:3px;height:100%">
            <div class="col-xs-12 removeCompletePadding" *ngFor="let field of Fields" style="padding:0;height:4%">

              <div class="col-xs-3" style="padding-right:0;">
                <span>{{field.DisplayAs}} : </span>
              </div>
              <div class="col-xs-9">
                <span *ngIf="field.ColumnName !='accounttype' && field.ColumnName !='customertypeid' && field.ColumnName !='ehr'  && field.ColumnName !='ownerid' && field.ColumnName !='ownership' && field.ColumnName !='pm' && field.ColumnName !='rcm' && field.ColumnName !='speciality' && field.ColumnName !='accountStatus' && field.ColumnName !='CustomerSince'">{{account[field.ColumnName]}}</span>
                <span *ngIf="field.ColumnName =='CustomerSince'">{{account[field.ColumnName] | date: 'MM-dd-yyyy'}}</span>
                <span *ngIf="field.ColumnName =='accounttype'">{{account.accounttypeName}}</span>
                <span *ngIf="field.ColumnName =='customertypeid'">{{account.customertype}}</span>
                <span *ngIf="field.ColumnName =='ehr'">{{account.ehrName}}</span>
                <span *ngIf="field.ColumnName =='ownerid'">{{account.OWNER}}</span>
                <span *ngIf="field.ColumnName =='ownership'">{{account.ownershipName}}</span>
                <span *ngIf="field.ColumnName =='pm'">{{account.pmName}}</span>
                <span *ngIf="field.ColumnName =='rcm'">{{account.rcmName}}</span>
                <span *ngIf="field.ColumnName =='speciality'">{{account.specialityName}}</span>
                <span *ngIf="field.ColumnName =='accountStatus'">{{(account.accountStatus == 14)? 'Active' :'On Hold' }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12" style="padding:0;height:83%;font-size:12px;" *ngIf="selectedMenuRadio == 'tab4'">

          <div class="col-xs-12 removeCompletePadding scroll-c" id="accountBody" style="padding:1%;overflow:auto; margin-top:0px; border-radius:3px;height:100%">
            <div class="col-xs-12 removeCompletePadding" *ngFor="let field of Fields" style="padding:0;height:4%">

              <div class="col-xs-3" style="padding-right:0;">
                <span>{{field.DisplayAs}} : </span>
              </div>
              <div class="col-xs-9">
                <span *ngIf="field.ColumnName !='accounttype' && field.ColumnName !='customertypeid' && field.ColumnName !='ehr'  && field.ColumnName !='ownerid' && field.ColumnName !='ownership' && field.ColumnName !='pm' && field.ColumnName !='rcm' && field.ColumnName !='speciality' && field.ColumnName !='accountStatus' && field.ColumnName !='CustomerSince'">{{account[field.ColumnName]}}</span>
                <span *ngIf="field.ColumnName =='CustomerSince'">{{account[field.ColumnName] | date: 'MM-dd-yyyy'}}</span>
                <span *ngIf="field.ColumnName =='IsCustomer'">{{account.accounttypeName}}</span>
                <span *ngIf="field.ColumnName =='accounttype'">{{account.accounttypeName}}</span>
                <span *ngIf="field.ColumnName =='customertypeid'">{{account.customertype}}</span>
                <span *ngIf="field.ColumnName =='ehr'">{{account.ehrName}}</span>
                <span *ngIf="field.ColumnName =='ownerid'">{{account.OWNER}}</span>
                <span *ngIf="field.ColumnName =='ownership'">{{account.ownershipName}}</span>
                <span *ngIf="field.ColumnName =='pm'">{{account.pmName}}</span>
                <span *ngIf="field.ColumnName =='rcm'">{{account.rcmName}}</span>
                <span *ngIf="field.ColumnName =='speciality'">{{account.specialityName}}</span>
                <span *ngIf="field.ColumnName =='accountStatus'">{{(account.accountStatus == 14)? 'Active' :'On Hold' }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12" style="padding:0;height:83%;font-size:12px;" *ngIf="selectedMenuRadio == 'tab5'">

          <div class="col-xs-12 removeCompletePadding scroll-c" id="accountBody" style="padding:1%;overflow:auto; margin-top:0px; border-radius:3px;height:100%">
            <div class="col-xs-12 removeCompletePadding" *ngFor="let field of Fields" style="padding:0;height:4%">

              <div class="col-xs-3" style="padding-right:0;">
                <span>{{field.DisplayAs}} : </span>
              </div>
              <div class="col-xs-9">

                <span *ngIf="field.ColumnName =='IsCustomer'">{{(account.isCustomer==874)?'Yes':'No'}}</span>
                <span *ngIf="field.ColumnName =='accountStatus'">{{(account.accountStatus == 14)? 'Active' :'On Hold' }}</span>
                <span *ngIf="field.ColumnName =='CustomerSince'">{{account[field.ColumnName] | date: 'MM-dd-yyyy'}}</span>
                <span *ngIf="field.ColumnName =='accnumber'">{{account[field.ColumnName]}}</span>
                <span *ngIf="field.ColumnName =='SystemType'">{{account.SystemTypeName}}</span>
                <span *ngIf="field.ColumnName =='HL7Interface'">{{account.HL7interfaceName}}</span>
                <span *ngIf="field.ColumnName =='CustomerRCM'">{{account.CustomerRCMName}}</span>
                <span *ngIf="field.ColumnName =='OnDemand'">{{account.onDemandName}}</span>
                <span *ngIf="field.ColumnName =='ApptReminders'">{{account.RemainderName}}</span>
                <span *ngIf="field.ColumnName =='eLab'">{{account.eLabName}}</span>
                <span *ngIf="field.ColumnName =='MIPS'">{{account.MIPSName}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12" style="padding:0;height:83%;font-size: 12px;" *ngIf="selectedMenuRadio == 'tab7'">

          <div class="col-xs-12 scroll-c" style="padding:1.5%;height:100%;overflow:auto">
            <div *ngFor="let loc of multiplelocaddresses; let i = index" style="height:40%">
              <div style="padding:1%;height:100%" class="col-xs-7">
                <div class="col-xs-12 removeCompletePadding" style="padding:0;height:10%">

                  <div class="col-xs-4" style="padding:0;">
                    <label style="font-weight:900">Location #{{i + 1}} </label>
                  </div>
                  <div class="col-xs-8">
                    <label>{{loc.addressName}}</label>

                  </div>
                </div>
                <div class="col-xs-12 removeCompletePadding" id="Customertype" style="padding:0;height:9%">
                  <div class="col-xs-4" style="padding:0;">
                    <span>Address1 </span>
                  </div>
                  <div class="col-xs-8">
                    <span>{{loc.address1}}</span>

                  </div>
                </div>
                <div class="col-xs-12 removeCompletePadding" id="Customertype" style="padding:0;height:9%">
                  <div class="col-xs-4" style="padding:0;">
                    <span>Address 2</span>
                  </div>
                  <div class="col-xs-8">
                    <span>{{loc.address2}}</span>

                  </div>

                </div>
                <div class="col-xs-12 removeCompletePadding" id="Customertype" style="padding:0;height:9%">
                  <div class="col-xs-4" style="padding:0;">
                    <span>City</span>
                  </div>
                  <div class="col-xs-8">
                    <span>{{loc.city}}</span>

                  </div>

                </div>
                <div class="col-xs-12 removeCompletePadding" id="Customertype" style="padding:0;height:9%">
                  <div class="col-xs-4" style="padding:0;">
                    <span>State</span>
                  </div>
                  <div class="col-xs-8">
                    <span>{{loc.state}}</span>

                  </div>

                </div>
                <div class="col-xs-12 removeCompletePadding" id="Customertype" style="padding:0;height:9%">
                  <div class="col-xs-4" style="padding:0;">
                    <span>Zip</span>
                  </div>
                  <div class="col-xs-8">
                    <span>{{loc.zip}}</span>

                  </div>

                </div>
                <div class="col-xs-12 removeCompletePadding" id="Customertype" style="padding:0;height:9%">
                  <div class="col-xs-4" style="padding:0;">
                    <span>Country</span>
                  </div>
                  <div class="col-xs-8">
                    <span>{{loc.country}}</span>
                  </div>

                </div>
                <div class="col-xs-12 removeCompletePadding" id="Customertype" style="padding:0;height:9%">
                  <div class="col-xs-4" style="padding:0;">
                    <span>Island</span>
                  </div>
                  <div class="col-xs-8">
                    <span>{{loc.ProvidenceIsland}}</span>
                  </div>

                </div>
                <div class="col-xs-12 removeCompletePadding" id="Customertype" style="padding:0;height:9%">
                  <div class="col-xs-4" style="padding:0;">
                    <span>Phone #</span>
                  </div>
                  <div class="col-xs-8">
                    <span>{{loc.phone}}</span>
                  </div>

                </div>
                <div class="col-xs-12 removeCompletePadding" id="Customertype" style="padding:0;height:9%">
                  <div class="col-xs-4" style="padding:0;">
                    <span>Fax #</span>
                  </div>
                  <div class="col-xs-8">
                    <span>{{loc.fax}}</span>
                  </div>

                </div>
              </div>

              <div class="col-xs-5" style="padding:0;height:100%;width:40%">
                <div class="col-xs-12" style="height:100%;margin: 0 0 0 0;">
                  <img id="image{{loc.id}}" height="80" style="width:100%;margin-top:10%;padding:15% 0 0 0 ;display:none" (click)="ShowImageFullScreen(loc.id)">

                  <input id="ChooseFile{{loc.id}}" name="ChooseFile{{loc.id}}" value="addFiles" type="file" (change)="addFiles($event,loc)" accept=".jpg,.jpeg" style="display:contents" />
                  <label for="ChooseFile{{loc.id}}" style="font-size:100px;color:#ddd;width:100%;height:100%;margin-bottom:0;" id="ChooseFileLabel{{loc.id}}">
                    <i class="fa fa-camera" aria-hidden="true" style="margin:0;float:right"></i>
                  </label>

                  <!--<input id="ReplaceFile{{loc.id}}" name="ReplaceFile{{loc.id}}" value="ReplaceFile" type="file" (change)="addFiles($event,loc)" accept=".jpg,.jpeg" style="display:contents" />
            <label for="ReplaceFile{{loc.id}}" class="replacePictureButtonCss" style="display:none" id="ReplaceFileLabel{{loc.id}}" >Replace Picture </label>-->
                </div>

              </div>
            </div>
            di
          </div>
        </div>

        <div class="col-xs-12" style="padding:0;margin-top:1%" [style.height.%]="(ExpandMode)? '90' : '82'" *ngIf="selectedMenuRadio == 'tab2' && currentuser.SoftwareLicenseAdmin == 1">
          <app-software-licensing [accountid]="accountId" [Type]="'Edit'" (ExpandBox)="Expand($event)"></app-software-licensing>
        </div>
        <div class="col-xs-12" style="padding:0;margin-top:1%;height:83%" *ngIf="(selectedMenuRadio == 'tab10'|| selectedMenuRadio == 'tab11')">
          <div class="col-xs-12" style="height:100%">
            <div class="col-xs-12" style="padding:0;height:80%">
              <textarea *ngIf="selectedMenuRadio == 'tab10'" id="StatisticsTextArea" rows="6" class="form-control" type="text" r name="StatisticsNotes" [(ngModel)]="accountHistory.StatisticsNotes" style="height: 100%;box-shadow: none;line-height: 1.028571;font-size:12px;background:white" contenteditable="false" readonly></textarea>
              <textarea *ngIf="selectedMenuRadio == 'tab11'" id="StatisticsTextArea" rows="6" class="form-control" type="text" r name="customerStatisticnotes" [(ngModel)]="accountHistory.customerStatisticnotes" style="height: 100%;box-shadow: none;line-height: 1.028571;font-size:12px;background:white" contenteditable="false" readonly></textarea>
            </div>
          </div>
        </div>
        <div class="col-xs-12" style="margin-top:1%;height:83%" *ngIf="selectedMenuRadio == 'tab6'">
          <ag-grid-angular class="ag-theme-balham" style="height:90%"
                           [gridOptions]="gridOptionsnotes"
                           [columnDefs]="columnDefsnotes"
                           [rowData]="allnotes"
                           [pagination]="true"
                           [sideBar]="sideBar">
          </ag-grid-angular>
        </div>
      </div>



      </div>
  </form>

</div>


<app-dialog [(visible)]="ShowImageDialog" *ngIf="ShowImageDialog" id="ShowImageDialog">
  <div class="col-xs-12" style="height:100%">
    <!--<img src="../../../assets/img/maxresdefault.jpg" style="width:100%;height:95%;" />-->

    <img [src]="ImageData" style="width:100%;height:99%">
  </div>

</app-dialog>

