import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Customers } from './../models/customers';
import { Accounts } from './../models/accounts';
import { Accountsharing } from './../models/AccountsharingModel';
import { Campaign } from './../models/Campaign';
import { Auditlog, AuditLogModel } from './../models/auditlog';
import { Openitems } from './../models/openitems';
import { Contacts } from './../models/contacts';
import { Dropdowntype, Dropdown } from './../models/dropdowntype';
import { Statistics } from './../models/statistics';
import { Tickets } from './../models/tickets'; 
import { Notes } from './../models/notes';
import { Emails, Attachements } from './../models/emails';
import { Practice } from './../models/practice';
import { Document } from './../models/document';
import { AccountDocuments } from './../models/account-documents';
import { Project } from './../models/project';
import { AppSettings } from '../app.settings';
import { Tasklistconfiguration } from './../models/tasklistconfiguration';
import { Taskgroup } from './../models/taskgroup';
import { WizardSubGroup } from './../models/wizard-sub-group';
//import { Privilages } from "app/models/privilages";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Accountlocations, Hero } from './../models/data-model';
import { Calllist } from '../models/calllist';
import { Zendeskuser } from '../models/zendeskuser';
import { WizardQuestions } from '../models/wizard-questions';
import { QuestionResponses } from '../models/question-responses';
import { Lastresponseview } from '../models/lastresponseview';
import { Privilages } from '../models/privilages';
import { TicketNotes } from '../models/TicketNotes';
import { RhxNames } from '../models/RhxNames';
import { WizardSubGroup2 } from '../models/WizardSubGroup2';
import { UserAccessTimeModel } from '../models/UserAccessTimeModel';
import { AccountsHistory } from '../models/AccountHistory';
import { AccountFields } from '../models/AccountFields';
import { TableColumns } from '../models/TableColumns';
import { PickListModel, ProjectPickListModel } from '../models/PickListModel';
import { AccountTransferModel } from '../models/AccountTransferModel';
import { TaskType } from '../models/TaskType';
import { DocumentHistory } from '../models/DocumentHistory';
import { FormConfiguration } from '../models/FormConfiguration';
import { ContactsSortOrder } from '../models/ContactsSortOrder';
import { Users } from '../models/users';
import { ProjectFields, ProjectForecastViewModel, Projects, ProjectStages, ProjectSteps, ProjectStepStatusModel } from '../models/ProjectConfiguration';
import { SalesXCRMChartTabs } from '../models/SalesXCRMChartTabs';
import { SharingGroups } from '../models/Accountsharing';
import { User } from 'ng-chat';
import { ProjectForecastData } from '../models/ProjectForecastData';
import { Mynotes } from '../models/mynotes';
import { ChatMessage } from '../models/chatMessage';
import { GroupChat } from '../models/GroupChat';
import { userModel } from '../models/userModel';
type NewType = FormConfiguration;

@Injectable()
export class CustomerService {
  options: RequestOptions;
  authToken: any; 
  headers: any;
  reqHeader: HttpHeaders = new HttpHeaders();
  lead: Customers = new Customers();
  //taskgroup: Taskgroup = new Taskgroup();
  statistics: Statistics = new Statistics();
  tickets: Tickets = new Tickets();
  notes: Notes = new Notes();
  emails: Emails = new Emails();
  practice: Practice = new Practice(); 
  document: Document = new Document(); 
  project: Project = new Project();
  constructor(private httpclient: HttpClient,private http: Http, private settings: AppSettings) { 
  }

  public GetAllCustomerList(type: string, term: string): Promise<Customers[]> {
    let self = this;
    const promise = new Promise<Customers[]>((resolve, reject) => {
      self.httpclient.get<Customers[]>(this.settings.baseUrl + 'api/customers/GetAllCustomersList/' + type + '/' + term, { headers: self.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetDocumentsCount(accountorcontactid: number,type:string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/customers/GetDocumentsCountbyuser/' + accountorcontactid + '/' + type, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetBypracticeId(practiceId: number): Promise<Practice> {

    
    const promise = new Promise<Practice>((resolve, reject) => {
      this.httpclient.get<Practice>(this.settings.baseUrl + 'api/customers/GetByPracticeId/' + practiceId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetByproviderId(id: number): Promise<Customers> {
    const promise = new Promise<Customers>((resolve, reject) => {
      this.httpclient.get<Customers>(this.settings.baseUrl + 'api/customers/GetByproviderId/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  GetByAccountId(id: number): Promise<Accounts> { 
    const promise = new Promise<Accounts>((resolve, reject) => {
      if (id != undefined && id != null) {
        this.httpclient.get<Accounts>(this.settings.baseUrl + 'api/accounts/GetByAccountId/' + id, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });      }
    });
    return promise;
  }

  GetByAccountIdNew(id: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      if (id != undefined && id != null) {
        this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/GetByAccountIdNew/' + id, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }

  GetAccountHistoryById(id: number,loggeduserId:number): Promise<AccountsHistory> {
    const promise = new Promise<AccountsHistory>((resolve, reject) => {
      if (id != undefined && id != null) {
        this.httpclient.get<AccountsHistory>(this.settings.baseUrl + 'api/accounts/GetAccountHistoryById/' + id + '/' + loggeduserId, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }

  LastTicketCreatedDateByAccID(id: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      if (id != undefined && id != null) {
        this.httpclient.get<Date>(this.settings.baseUrl + 'api/accounts/LastTicketCreatedDateByAccID/' + id, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }

  LastContactedDateByAccID(id: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      if (id != undefined && id != null) {
        this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/LastContactedDateByAccID/' + id, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }
  GetSalerepName(id: number): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      if (id != undefined && id != null) {
        this.httpclient.get<string>(this.settings.baseUrl + 'api/accounts/GetSalesrepName/' + id, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }
  
  GetDocumentById(docid: number): Promise<Document> {
    const promise = new Promise<Document>((resolve, reject) => {
      if (docid != undefined && docid != null) {
        this.httpclient.get<Document>(this.settings.baseUrl + 'api/documents/GetDocId/' + docid, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }
  GetDocumentHistoryById(docid: number): Promise<DocumentHistory[]> {
    const promise = new Promise<DocumentHistory[]>((resolve, reject) => {
      if (docid != undefined && docid != null) {
        this.httpclient.get<DocumentHistory[]>(this.settings.baseUrl + 'api/documents/GetDocHistoryById/' + docid, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }
  GetDocumentPermissionById(docid: number): Promise<Document> {
    const promise = new Promise<Document>((resolve, reject) => {
      if (docid != undefined && docid != null) {
        this.httpclient.get<Document>(this.settings.baseUrl + 'api/documents/GetPerDocId/' + docid, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }
  GetAccountSharingByAccountId(id: number): Promise<Accountsharing> {
    const promise = new Promise<Accountsharing>((resolve, reject) => {
      if (id != undefined && id != null) {
        this.httpclient.get<Accountsharing>(this.settings.baseUrl + 'api/accounts/GetAccSharingByAccountId/' + id, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }
  GetpopenItemsForUser(userids: string, accId:number): Promise<Openitems> {
    const promise = new Promise<Openitems>((resolve, reject) => {
      if (userids != undefined && userids != null) {
        this.httpclient.get<Openitems>(this.settings.baseUrl + 'api/accounts/GetOpenItemsForEditUsers/' + userids + '/' + accId, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }
  GetpermissionGroupByAccount(id: number): Promise<Accountsharing[]> {
    const promise = new Promise<Accountsharing[]>((resolve, reject) => {
      if (id != undefined && id != null) {
        this.httpclient.get<Accountsharing[]>(this.settings.baseUrl + 'api/accounts/GetPermissionByAccount/' + id, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }

  getAccountPermission(accid: number,loggedinUserId:number): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
    
      this.httpclient.get<string>(this.settings.baseUrl + 'api/accounts/GetUserAccountPermission/' + accid + '/' + loggedinUserId, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
    
    });
    return promise;
  }

  public GetSaveContactOrder(userid: number): Promise<ContactsSortOrder> {
    const promise = new Promise<ContactsSortOrder>((resolve, reject) => {
      this.httpclient.get<ContactsSortOrder>(this.settings.baseUrl + 'api/customers/GetSaveContactOrder/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public SaveContactOrder(ContactsOrder: ContactsSortOrder) {
    const promise = new Promise<ContactsSortOrder>((resolve, reject) => {
      this.httpclient.post<ContactsSortOrder>(this.settings.baseUrl + 'api/customers/SaveContactOrder', ContactsOrder, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  loadaccountdata(id: number): Promise<Accounts> {
    const promise = new Promise<Accounts>((resolve, reject) => {
      if (id != undefined && id != null) {
        this.httpclient.get<Accounts>(this.settings.baseUrl + 'api/accounts/LoadAccountDataByAccountId/' + id, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }
  GetByAttachmentId(id: number): Promise<Attachements> {
    const promise = new Promise<Attachements>((resolve, reject) => {
      if (id != undefined && id != null) {
        this.httpclient.get<Attachements>(this.settings.baseUrl + 'api/accounts/GetMesageAttachments/' + id, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }
  GetByCalllistById(id: number): Promise<Calllist> {
    const promise = new Promise<Calllist>((resolve, reject) => {
      this.httpclient.get<Calllist>(this.settings.baseUrl + 'api/accounts/GetByCalllistId/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetallContactlistbyselectedaccounts(accountids: string): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.get<string>(this.settings.baseUrl + 'api/accounts/GetAllContactsbySelectedids/' + accountids, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetLocationByAccountId(id: number): Promise<Accountlocations[]> {
    const promise = new Promise<Accountlocations[]>((resolve, reject) => {
      this.httpclient.get<Accountlocations[]>(this.settings.baseUrl + 'api/accounts/GetLocationByAccountId/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
 LoadContactById(id: number): Promise<Contacts> {
    const promise = new Promise<Contacts>((resolve, reject) => {
      this.httpclient.get<Contacts>(this.settings.baseUrl + 'api/accounts/GetContactDataById/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetContactById(id: number): Promise<Contacts> {    
    const promise = new Promise<Contacts>((resolve, reject) => {
      this.httpclient.get<Contacts>(this.settings.baseUrl + 'api/accounts/GetContactById/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetContactByIdNew(id: number): Promise<any> {    
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/GetContactByIdNew/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  ResponseById(id: number): Promise<QuestionResponses> {
    const promise = new Promise<QuestionResponses>((resolve, reject) => {
      this.httpclient.get<QuestionResponses>(this.settings.baseUrl + 'api/accounts/GetResponById/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  deleteCategory(id: number): Promise<Taskgroup> {
    const promise = new Promise<Taskgroup>((resolve, reject) => {
      this.httpclient.get<Taskgroup>(this.settings.baseUrl + 'api/accounts/DeleteTaskGroupById/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  Getcontactimage(id: number): Promise<Contacts> {

    const promise = new Promise<Contacts>((resolve, reject) => {
      this.httpclient.get<Contacts>(this.settings.baseUrl + 'api/accounts/GetImage/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllUserListBypractice(practiceId: number): Promise<Customers[]> {
    const promise = new Promise<Customers[]>((resolve, reject) => {
      this.httpclient.get<Customers[]>(this.settings.baseUrl + 'api/customers/GetAllUserListByPractice/' + practiceId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }


  public GetAllStaffListBypractice(practiceId: number): Promise<Customers[]> {
    const promise = new Promise<Customers[]>((resolve, reject) => {
      this.httpclient.get<Customers[]>(this.settings.baseUrl + 'api/customers/GetAllStaffListByPractice/' + practiceId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllContactUserListBypractice(practiceId: number): Promise<Customers[]> {
    const promise = new Promise<Customers[]>((resolve, reject) => {
      this.httpclient.get<Customers[]>(this.settings.baseUrl + 'api/customers/GetAllUserListByPractice/' + practiceId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllContactproviderByAccount(accountid: number,userId: number): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/accounts/GetAllContactproviderByAccount/' + accountid + '/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllContactproviderByAccountQV(accountids: string, userId: number): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/accounts/GetAllContactproviderByAccountQV/' + accountids + '/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public getDesiredIdFromContactId(desired: string, contactid: number, type: string): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.get<string>(this.settings.baseUrl + 'api/customers/GetDesiredIdFromContactId/' + desired + '/' + contactid.toString() + '/' + type, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllContactstaffByAccount(accountid: number): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/accounts/GetAllContactStaffByAccount/' + accountid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllNotesListByAccount(accountid: number): Promise<Notes[]> {
    const promise = new Promise<Notes[]>((resolve, reject) => {
      this.httpclient.get<Notes[]>(this.settings.baseUrl + 'api/accounts/GetAllAccountNotes/' + accountid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetSpecialityList(category: string,loggedinUserId:number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/customers/GetAlldropdownsByCategory/' + category + '/' + loggedinUserId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  
  public GetRoleSalesRep(roleId:number): Promise<Users[]> {
    const promise = new Promise<Users[]>((resolve, reject) => {
      this.httpclient.get<Users[]>(this.settings.baseUrl + 'api/customers/GetRoleSalerep/' + roleId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetSearchColumnList(tblName: string): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/configuration/GetAllTableColumnList/' + tblName, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  GetAccountContactList(accId: number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/customers/GetAllContactsByAccount/' + accId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetTitleListbycontacttype(cattype: string): Promise<Dropdowntype[]> {
      const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
        this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/customers/GetAlltitlesBycategorytype/' + cattype, { headers: this.reqHeader }).
              subscribe(res => {
                  resolve(res);
              });
      });
      return promise;
  }
  public GetAllaccesusersListbyuser(userid: number, ActiveUser: number, inActiveUser: number, suspendedUser: number): Promise<Dropdowntype[]> {
     
      const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
        this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/customers/GetAllOwnerList/' + userid + '/0/' + ActiveUser + '/' + inActiveUser + '/' + suspendedUser, { headers: this.reqHeader }).
              subscribe(res => {
                  resolve(res);
              });
      });
      return promise;
  }
  public Getselectedusers(userids: string): Promise<Dropdowntype[]> {
    let self = this;
      const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
        this.httpclient.get<Dropdowntype[]>(self.settings.baseUrl + 'api/customers/GetAllSelectedUserList/' + userids, { headers: self.reqHeader }).
              subscribe(res => {
                  resolve(res);
              });
      });
      return promise;
  }

  public GetDropdownNames(dropdownids?: string): Promise<Dropdowntype[]> {
    let self = this;
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(self.settings.baseUrl + 'api/customers/GetSelecteddropdownList/' + dropdownids, { headers: self.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllaccesUserList(currentuserId: number): Promise<Dropdowntype[]> {     
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/customers/GetAllaccesUserList/' + currentuserId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllOwnerList(Roleid: number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/customers/GetAllOwnerList/0/' + Roleid + '/1/0/0', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetLoggedinUserList(userid: number,systemadministrator:number): Promise<Dropdowntype[]> {
      const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
        this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/customers/GetLoggedinUserList/' + userid + '/' + systemadministrator, { headers: this.reqHeader }).
              subscribe(res => {
                  resolve(res);
              });
      });
      return promise;
  }
  public Getcontacttypes(typeid: number): Promise<Dropdowntype[]> {
    
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/accounts/GetAllContacttypesList/' + typeid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public Getaccountaddress(id: number): Promise<Accounts> {
    const promise = new Promise<Accounts>((resolve, reject) => {
      this.httpclient.get<Accounts>(this.settings.baseUrl + 'api/accounts/GetAccountAddress/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetSpecialitytypes(loggedinUserId:number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/accounts/GetAllSpecialitiesList/' + loggedinUserId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetCollegeList(loggedinUserId: number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/accounts/GetAllCollegeList/' + loggedinUserId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetMedicalSchoolList(loggedinUserId: number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/accounts/GetAllMedicalSchoolList/' + loggedinUserId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllStateList(country): Promise<Dropdowntype[]> {
      const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
        this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/accounts/GetAllStatesList/' + country, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllCitiesList(stateid: number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/accounts/GetAllCitiesByState/' + stateid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllStatistics(): Promise<Statistics[]> {
    const promise = new Promise<Statistics[]>((resolve, reject) => {
      this.httpclient.get<Statistics[]>(this.settings.baseUrl + 'api/customers/GetAllStatisticsList/', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  addticket(tickets: Tickets): Promise<Tickets> {
    const promise = new Promise<Tickets>((resolve, reject) => {
      this.httpclient.post<Tickets>(this.settings.baseUrl + 'api/zendesk/SaveTickets', tickets, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  addticketNotes(tickets: TicketNotes): Promise<TicketNotes> {
    const promise = new Promise<TicketNotes>((resolve, reject) => {
      this.httpclient.post<TicketNotes>(this.settings.baseUrl + 'api/zendesk/SaveTicketsNotes', tickets, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetTicketsListByContact(type: number, Status: string): Promise<Tickets[]> {
    const promise = new Promise<Tickets[]>((resolve, reject) => {
      this.httpclient.get<Tickets[]>(this.settings.baseUrl + 'api/zendesk/GetTicketsListByContact/' + type + '/' + Status, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetTicketsListByAccount(type: number, Status: string): Promise<Tickets[]> {
    const promise = new Promise<Tickets[]>((resolve, reject) => {
      this.httpclient.get<Tickets[]>(this.settings.baseUrl + 'api/zendesk/GetTicketsByAccount/' + type + '/' + Status, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetTicketNotesByTicketId(ticketId: number,type:string): Promise<TicketNotes[]> {
    const promise = new Promise<TicketNotes[]>((resolve, reject) => {
      this.httpclient.get<TicketNotes[]>(this.settings.baseUrl + 'api/zendesk/GetTicketNotesByTicketId/' + ticketId + '/' + type, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetTicketsList(Status: string, Filter: string, UserId: number, SortOrder: string, CCval: boolean, assignedTo: string): Promise<Tickets[]> {
    const promise = new Promise<Tickets[]>((resolve, reject) => {
      this.httpclient.get<Tickets[]>(this.settings.baseUrl + 'api/zendesk/GetTicketsList/' + Status + '/' + Filter + '/' + UserId + '/' + SortOrder + '/' + CCval + '/' + assignedTo, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllTicketStatus(): Promise<Dropdown[]> {
    const promise = new Promise<Dropdown[]>((resolve, reject) => {
      this.httpclient.get<Dropdown[]>(this.settings.baseUrl + 'api/zendesk/GetAllTicketStatus', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllzendeskuserlist(): Promise<Zendeskuser[]> {
    const promise = new Promise<Zendeskuser[]>((resolve, reject) => {
      this.httpclient.get<Zendeskuser[]>(this.settings.baseUrl + 'api/zendesk/GetAllZendeskUserList/', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllNotesList(userid:number,contactid: string, type: string, accidofallcontacts: string = "0"): Promise<Notes[]> {
    const promise = new Promise<Notes[]>((resolve, reject) => {
      this.httpclient.get<Notes[]>(this.settings.baseUrl + 'api/customers/GetAllNotesList/' + userid + '/' + contactid + '/' + type + '/' + accidofallcontacts, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllNotesListNew(userid: number, contactid: string, type: string, accidofallcontacts: string = "0", Opptype: string): Promise<Notes[]> {
    const promise = new Promise<Notes[]>((resolve, reject) => {
      this.httpclient.get<Notes[]>(this.settings.baseUrl + 'api/customers/GetAllNotesListNew/' + userid + '/' + contactid + '/' + type + '/' + accidofallcontacts + '/' + Opptype, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllEmailsList(contactid: number, type: string): Promise<Emails[]> {
    const promise = new Promise<Emails[]>((resolve, reject) => {
      this.httpclient.get<Emails[]>(this.settings.baseUrl + 'api/customers/GetAllEmailList/' + contactid + '/' + type, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllEmailsAttachmentList(emailid: number): Promise<Attachements[]> {
    const promise = new Promise<Attachements[]>((resolve, reject) => {
      this.httpclient.get<Attachements[]>(this.settings.baseUrl + 'api/customers/GetAllattachmentsByemailId/' + emailid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  savezendeskUser(zendesk: Zendeskuser): Promise<Zendeskuser> {
    const promise = new Promise<Zendeskuser>((resolve, reject) => {
      this.httpclient.post<Zendeskuser>(this.settings.baseUrl + 'api/zendesk/SaveZendeskUser', zendesk, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public GetAllDeletedDocumentList(accountId: string, selectedType: string, selYear: number, SelReportType: string, startrow?: number, endrow?: number, start?: string, end?: string): Promise<Document[]> {
    const promise = new Promise<Document[]>((resolve, reject) => {
      this.httpclient.get<Document[]>(this.settings.baseUrl + 'api/customers/GetAllDeletedDocumentList/' + accountId + '/' + selectedType + '/' + selYear + '/' + SelReportType + '/' + startrow + '/' + endrow + '/' + start + '/' + end, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllDocumentList(accountId: string, selectedType: string, selYear: number, SelReportType: string,start?:string,end?:string ): Promise<Document[]> {
    const promise = new Promise<Document[]>((resolve, reject) => {
      this.httpclient.get<Document[]>(this.settings.baseUrl + 'api/customers/GetAllDocumentList/' + accountId + '/' + selectedType + '/' + selYear + '/' + SelReportType + '/' + start + '/' + end, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllDocumentByPermissionLevel(userids: string, permissionLevel: number): Promise<Document[]> {
    const promise = new Promise<Document[]>((resolve, reject) => {
      this.httpclient.get<Document[]>(this.settings.baseUrl + 'api/customers/GetAllDocumentByPermissionLevel/' + userids + '/' + permissionLevel, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllPendingDocuments(accountId: number, selectedType: string, selYear: number): Promise<Document[]> {
    const promise = new Promise<Document[]>((resolve, reject) => {
      this.httpclient.get<Document[]>(this.settings.baseUrl + 'api/customers/GetAllPendingDocumentList/' + accountId + '/' + selectedType + '/' + selYear, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllDocumentNamePermissionList(selectedType: string, userId?: number): Promise<Document[]> {
    const promise = new Promise<Document[]>((resolve, reject) => {
      this.httpclient.get<Document[]>(this.settings.baseUrl + 'api/customers/GetAllDocumentNamePermissionList/' + selectedType + '/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllDocNamePermissionListforConfig(selectedType: string, userId?: number): Promise<Document[]> {
    const promise = new Promise<Document[]>((resolve, reject) => {
      this.httpclient.get<Document[]>(this.settings.baseUrl + 'api/customers/GetAllDocNamePermissionListforConfig/' + selectedType + '/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllActiveDocumentNamePermissionList(selectedType: string, startrow?: number, endrow?: number): Promise<Document[]> {
    const promise = new Promise<Document[]>((resolve, reject) => {
      this.httpclient.get<Document[]>(this.settings.baseUrl + 'api/customers/GetAllActiveDocumentNamePermissionList/' + selectedType + '/' + startrow + '/' + endrow, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllAccountDocumentList(loggedinuserid: number, accountid: number): Promise<AccountDocuments[]> {
    const promise = new Promise<AccountDocuments[]>((resolve, reject) => {
      this.httpclient.get<AccountDocuments[]>(this.settings.baseUrl + 'api/customers/GetAllAccountDocumentList/' + loggedinuserid + '/' + accountid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllDocumentAuditlogList(documentid: number, type: string, UserIds?: string, startrow?: number, endrow?: number, selectedtype?: string, startdate?: string, enddate?: string): Promise<Auditlog[]> {
     
    const promise = new Promise<Auditlog[]>((resolve, reject) => {
      this.httpclient.get<Auditlog[]>(this.settings.baseUrl + 'api/customers/GetAllAuditLog/' + documentid + '/' + type + '/' + UserIds + '/' + startrow + '/' + endrow + '/' + selectedtype + '/' + startdate + '/' + enddate, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllUersloggedInList(): Promise<Auditlog[]> {
    const promise = new Promise<Auditlog[]>((resolve, reject) => {
      this.httpclient.get<Auditlog[]>(this.settings.baseUrl + 'api/customers/GetAllUserloggedInList', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetmessageReceiveList(userId): Promise<Auditlog[]> {
    const promise = new Promise<Auditlog[]>((resolve, reject) => {
      this.httpclient.get<Auditlog[]>(this.settings.baseUrl + 'api/customers/GetAllSendReceiveUserList/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public isDuplicateChatsession(fromuserId, touserId): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      this.httpclient.get<boolean>(this.settings.baseUrl + 'api/customers/GetDuplicateChatSession/' + fromuserId + '/' + touserId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllChatMessages(userId, touserId, chatids, grpId): Promise<ChatMessage[]> {
    const promise = new Promise<ChatMessage[]>((resolve, reject) => {
      this.httpclient.get<ChatMessage[]>(this.settings.baseUrl + 'api/customers/GetAllChatMessagesList/' + userId + '/' + touserId + '/' + chatids + '/' + grpId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  

  public GetAllRhxList(UserIds?: string, startrow?: number, endrow?: number, startdate?: string, enddate?: string): Promise<Auditlog[]> {
    const promise = new Promise<Auditlog[]>((resolve, reject) => {
      this.httpclient.get<Auditlog[]>(this.settings.baseUrl + 'api/customers/GetAllRhxAuditLog/' + UserIds + '/' + startrow + '/' + endrow + '/' + startdate + '/' + enddate, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }


  public GetRecentHistoryNames(Type: string,userdids:string): Promise<RhxNames[]> {
    const promise = new Promise<RhxNames[]>((resolve, reject) => {
      this.httpclient.get<RhxNames[]>(this.settings.baseUrl + 'api/customers/GetRecentHistoryNames/' + Type + '/' + userdids, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAuditDataByID(userdids: string): Promise<AuditLogModel> {
    const promise = new Promise<AuditLogModel>((resolve, reject) => {
      this.httpclient.get<AuditLogModel>(this.settings.baseUrl + 'api/users/GetAuditDataByID/' + userdids, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllProjectList(): Promise<Project[]> {
    const promise = new Promise<Project[]>((resolve, reject) => {
      this.httpclient.get<Project[]>(this.settings.baseUrl + 'api/customers/GetAllProjectList/', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetPractices(): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/accounts/GetAllPracticeList/', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public UpdateProvider(customer: Customers): Promise<Customers> {
    
    const promise = new Promise<Customers>((resolve, reject) => {
      this.httpclient.post<Customers>(this.settings.baseUrl + 'api/customers/UpdateProvider/', customer, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }


  public SaveNotes(notes: Notes): Promise<Notes> {
    var currentuser = JSON.parse(localStorage.getItem("currentUser"));
    notes.userid = currentuser.id;
    const promise = new Promise<Notes>((resolve, reject) => {
      this.httpclient.post<Notes>(this.settings.baseUrl + "api/customers/SaveNotes", notes, { headers: this.reqHeader }
       ).subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public SaveuserNotes(notes: Mynotes): Promise<Mynotes> {
    var currentuser = JSON.parse(localStorage.getItem("currentUser"));
    notes.userId = currentuser.id;
    const promise = new Promise<Mynotes>((resolve, reject) => {
      this.httpclient.post<Mynotes>(this.settings.baseUrl + "api/customers/SaveMyNotes", notes, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public UpdateNotes(notes: Notes): Promise<Notes> {
     
    var currentuser = JSON.parse(localStorage.getItem("currentUser"));
    notes.userid = currentuser.id;
    const promise = new Promise<Notes>((resolve, reject) => {
      this.httpclient.post<Notes>(this.settings.baseUrl + "api/customers/UpdateNotes", notes, { headers: this.reqHeader }
       ).subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public SaveResponcesForQuestion(responce: QuestionResponses): Promise<QuestionResponses> {
    const promise = new Promise<QuestionResponses>((resolve, reject) => {
      this.httpclient.post<QuestionResponses>(this.settings.baseUrl + "api/customers/SaveQuestionRenponses", responce, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public SaveEditedNote(id: string, desc: string) {     
    let obj: string[] = [id, desc];
    const promise = new Promise<boolean>((resolve, reject) => {       
      this.httpclient.post<boolean>(this.settings.baseUrl + "api/customers/SaveEditedNote", obj, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public SaveEmail(email: string): Promise<any> {   
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + "api/SendEmail?email=" + email, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
 
 
  updategroup(groupdata: GroupChat): Promise<any> {
    const promise = new Promise<GroupChat>((resolve, reject) => {
      this.httpclient.post<GroupChat>(this.settings.baseUrl + "api/chats/UpdateChatGroup", groupdata, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;   
  }

  deleteChat(chatid: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/chats/deleteMessage/' + chatid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  updateCustomerView(id: number, cusviewStatus: number,hidecustomer): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/UpdateCustomerViewStatus/' + id + "/" + cusviewStatus + "/" + hidecustomer, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  UpdateProposal(id: number, proposalSelection: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/UpdateProposalSelection/' + id + "/" + proposalSelection, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  deletetaskitem(id: number, status: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/deletetaskitemStatus/' + id + "/" + status, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  activatedeactivateQuestion(id: number, status: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/deleteQuestionStatus/' + id + "/" + status, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  DeactivateResponse(responseid: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/deactivateresponse/' + responseid, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  DeactivateCategories(id: number, type: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/customers/DeactivateCategories/' + id + '/' + type, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  updatemultiplelocation(id: number): Promise<any> {

      const promise = new Promise<any>((resolve, reject) => {
        this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/Updatemultiplelocation/' + id, { headers: this.reqHeader }).
              subscribe(res => {
                  resolve(res);
              });
      });
      return promise;
  }
  public Token(username: string, password: string): Promise<any> {
    let self = this;
    let headers = new Headers();
    headers.set("Content-Type", "application/x-www-form-urlencoded");
    const promise = new Promise<any>((resolve, reject) => {
      self.http.post(self.settings.baseUrl + "Token", "grant_type=password&username=" + username + "&password=" + password, { headers: headers }).
        subscribe(res => {
          resolve(res.json());
        });
    });
    return promise;
  }

  updatelogin(id: number, status1: number, LogOutStatus: string, AuditLogId: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/UpdateLoginStatus/' + id + "/" + status1 + "/" + LogOutStatus + "/" + AuditLogId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  //public updateuserSuspend(userdata): Promise<userModel[]> {
  //  const promise = new Promise<userModel[]>((resolve, reject) => {
  //    this.httpclient.post<userModel[]>(this.settings.baseUrl + 'api/UpdateSuspend/', userdata, { headers: this.reqHeader }).
  //      subscribe(res => {
  //        resolve(res);
  //      });
  //  });
  //  return promise;
  //}

  

  public Login(username: string, password: string): Promise<any> {
    let self = this;
    localStorage.removeItem("key");
    const promise = new Promise<any>((resolve, reject) => {
      self.Token(username, password)
        .then(tokenData => {
          let headers = new Headers();
          headers.set("Authorization", `Bearer ${tokenData.access_token}`);
          self.http.get(self.settings.baseUrl + "api/Login/" + encodeURIComponent(password) , { headers: headers }).
            subscribe(res => {
              let user = res.json();
              user.token = tokenData.access_token;
              self.reqHeader = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
              });
              localStorage.setItem('key', user.token);
              resolve(user);
            });
        });
    });
    return promise;   
  }
  public CheckUsernameExistOrNot(username: string, password: string): Promise<any> {
    this.headers = new Headers();

    localStorage.removeItem("key");
    var temptoken = localStorage.getItem("temptoken");
    const promise = new Promise<any>((resolve, reject) => {
      this.http.get(this.settings.baseUrl + "api/CheckUsernameExistOrNot/" + username + "/" + password + "/" + temptoken).
        subscribe(res => {
          let user = res.json()
          this.authToken = user.token
          this.headers.append('Authorization', `Bearer ${this.authToken}`);
          localStorage.setItem('key', this.authToken);         
          this.options = new RequestOptions({ headers: this.headers });
          resolve(res.json());
        });
    });
    return promise;
  }

  
  public getAllfieldsbyselectedtable(tablename: string): Promise<string[]> {
    const promise = new Promise<string[]>((resolve, reject) => {
      this.httpclient.get<string[]>(this.settings.baseUrl + 'api/customers/GetTablefields/' + tablename, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetPrivilagesByuser(id: number): Promise<Privilages[]> {
    let self = this;
    const promise = new Promise<Privilages[]>((resolve, reject) => {
      self.httpclient.get<Privilages[]>(self.settings.baseUrl + 'api/users/GetPrivilagesByuser/' + id, { headers: self.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetDisplayCommision(id: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/users/GetCommissionCalculate/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  saveDropdownValue(dropdowns: TaskType): Promise<TaskType> {
    const promise = new Promise<TaskType>((resolve, reject) => {
      this.httpclient.post<TaskType>(this.settings.baseUrl + 'api/customers/saveDropdownValue', dropdowns, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  SavelastViewedresponsewizard(lastresponseview: Lastresponseview): Promise<Lastresponseview> {
    const promise = new Promise<Lastresponseview>((resolve, reject) => {
      this.httpclient.post<Lastresponseview>(this.settings.baseUrl + 'api/customers/SaveLastResonseViewed', lastresponseview, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  saveWizardQuestion(questions: WizardQuestions[]): Promise<WizardQuestions[]> {
    const promise = new Promise<WizardQuestions[]>((resolve, reject) => {
      this.httpclient.post<WizardQuestions[]>(this.settings.baseUrl + 'api/customers/saveWizardQuestions', questions, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  addtaskgroup(taskgroup: Taskgroup): Promise<Taskgroup> {
    const promise = new Promise<Taskgroup>((resolve, reject) => {
      this.httpclient.post<Taskgroup>(this.settings.baseUrl + 'api/configuration/SaveTaskGroupData', taskgroup, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }
  addSubtaskgroup(taskgroup: WizardSubGroup): Promise<WizardSubGroup> {
    const promise = new Promise<WizardSubGroup>((resolve, reject) => {
      this.httpclient.post<WizardSubGroup>(this.settings.baseUrl + 'api/configuration/SaveSubTaskGroupData', taskgroup, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }
  addSubtaskgroup2(taskgroup: WizardSubGroup2): Promise<WizardSubGroup2> {
    const promise = new Promise<WizardSubGroup2>((resolve, reject) => {
      this.httpclient.post<WizardSubGroup2>(this.settings.baseUrl + 'api/configuration/SaveSubTaskGroup2Data', taskgroup, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }
  adddropdown(dropdown: Dropdown): Promise<Dropdown> {
    const promise = new Promise<Dropdown>((resolve, reject) => {
      this.httpclient.post<Dropdown>(this.settings.baseUrl + 'api/configuration/SaveDropDown', dropdown, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }

  SaveAccountField(AccountFields: AccountFields): Promise<Dropdown> {
    const promise = new Promise<Dropdown>((resolve, reject) => {
      this.httpclient.post<Dropdown>(this.settings.baseUrl + 'api/configuration/SaveAccountField', AccountFields, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }
  SavePickList(PickList: PickListModel): Promise<PickListModel> {
    const promise = new Promise<PickListModel>((resolve, reject) => {
      this.httpclient.post<PickListModel>(this.settings.baseUrl + 'api/configuration/SavePickList', PickList, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }

  deleteDropdown(id: number, type: string): Promise<Dropdown> {
     
    const promise = new Promise<Dropdown>((resolve, reject) => {
      this.httpclient.get<Dropdown>(this.settings.baseUrl + 'api/configuration/DeleteDropDown/' + id + '/' + type, { headers: this.reqHeader }).
        subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }

  ReplaceandDelete(drpId: number, drpName: string, replaceid: number): Promise<string> {

    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.get<string>(this.settings.baseUrl + 'api/configuration/ReplaceandDeleteIDS/' + drpId + '/' + replaceid + '/' + drpName, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  checkduplicateTaskGroupName(taskgroup: Taskgroup): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.post<string>(this.settings.baseUrl + 'api/configuration/Checkduplicatetaskgroup', taskgroup, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  checkduplicateDropDownName(dropdown: Dropdown): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.post<string>(this.settings.baseUrl + 'api/configuration/Checkduplicatedropdown', dropdown, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  checkIdaleadymapped(dropdown: Dropdown): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.post<string>(this.settings.baseUrl + 'api/configuration/CheckIdMappedtoAccorContact', dropdown, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public checkduplicateTaskName(taskTyp: TaskType): Promise<TaskType[]> {

    const promise = new Promise<TaskType[]>((resolve, reject) => {
      this.httpclient.post<TaskType[]>(this.settings.baseUrl + 'api/configuration/ChecktaskName', taskTyp, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  addAccuntPermissionEdit(accountSharing: Accountsharing): Promise<Accountsharing> {
    const promise = new Promise<Accountsharing>((resolve, reject) => {
      this.httpclient.post<Accountsharing>(this.settings.baseUrl + 'api/accounts/SaveAccountsharing', accountSharing, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  removesharingByAccid(AccountId:number,UserId:number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/RemoveSharing/' + AccountId + '/' + UserId, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  SaveTaskListConfiguration(tasklistcongiguration: Tasklistconfiguration): Promise<Tasklistconfiguration> {
    
    const promise = new Promise<Tasklistconfiguration>((resolve, reject) => {
      this.httpclient.post<Tasklistconfiguration>(this.settings.baseUrl + 'api/configuration/SaveTaskListcongigurationOrder', tasklistcongiguration, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  ConfigureCalanderView(loggedinUserid: number, calanderView: number,type:string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/ConfigureCalander/' + loggedinUserid + "/" + calanderView + '/' + type, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  Deactivatecontact(contactid: number, status: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/deactivate/' + contactid + "/" + status, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
 
  DeactivateAccount(accountId: number, status: number,deletedUserId:number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/deactivateAccountid/' + accountId + "/" + status + '/' + deletedUserId, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  DeactivateDocument(documnentId: number, status: number, DocTab: number, deletedBy:number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/documents/deactivatedoccumentByid/' + documnentId + "/" + status + "/" + DocTab + '/' + deletedBy, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  DeleteZendeskUser(id: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/zendesk/deactivate/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  DeleteTicket(id: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/zendesk/deleteTicket/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  uploadPhoto(contacts: Contacts): Promise<Contacts> { 
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.post<any>(this.settings.baseUrl + 'api/accounts/UpdateContactPhoto', contacts, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  uploadAdditionalLocationImage(addLoc: Accountlocations): Promise<Accountlocations> { 
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.post<any>(this.settings.baseUrl + 'api/accounts/uploadAdditionalLocationImage', addLoc, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  uploadUserProfilePhoto(user: Users): Promise<Users> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.post<any>(this.settings.baseUrl + 'api/users/UpdateUserProfilePhoto', user, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  Deletecalllist(calllistid: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/Deletecalllist/' + calllistid, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  
  Assigntocalllist(assigneduserid: number,calllistid:number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/Assigntocalllist/' + assigneduserid + "/" + calllistid, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }


  Updateownerid(newownerid: number, accountid: number,loggedInuserId:number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/UpdateOwneridByaccount/' + newownerid + "/" + accountid + '/' + loggedInuserId, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  TransferAccountsToNewOwner(newownerid: number, accountids: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/TransferAccountsToNewOwner/' + newownerid + "/" + accountids, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  UpdatePermissiontoViewUserByaccId(permissiontoviewusers: string, accountid: number,viewtaskgropvalues:string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/UpdatePermissionUser/' + permissiontoviewusers + "/" + accountid + '/' + viewtaskgropvalues, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  UpdateLeadStatus(contactid: number,userid:number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/UpdateLeadStatus/' + contactid + '/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  DeleteProposalById(proposalid: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/DeleteByproposalId/' + proposalid , { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  UpdateCustomerType(CustomerType: string, userid: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/UpdateCustomerType/' + CustomerType + '/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  UpdateSelectedCustomerType(val: number, userid: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/UpdateCustomerActivity/' + val + '/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  completecontact(calllistid:number,contactid: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/completecontact/' + calllistid + "/" + contactid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  DeleteCampaigncontact(id: number, isdeletedstatus:number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/deletecampaigncontact/' + id + '/' + isdeletedstatus, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  attachtoLead(oppId: number): Promise<any> {

    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/leads/leadattach/' + oppId, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetUserAccessTime(userids: string, type: string, startdate: string, enddate: string): Promise<UserAccessTimeModel[]> {
    const promise = new Promise<UserAccessTimeModel[]>((resolve, reject) => {
      this.httpclient.get<UserAccessTimeModel[]>(this.settings.baseUrl + 'api/customers/GetUserAccessTime/' + userids + '/' + type + '/' + startdate + '/' + enddate, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetTableFields(tableName: string,userId:number): Promise<AccountFields[]> {
    const promise = new Promise<AccountFields[]>((resolve, reject) => {
      this.httpclient.get<AccountFields[]>(this.settings.baseUrl + 'api/configuration/GetTableFields/' + tableName + '/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }


  public GetPickListData(FieldName: string, FieldNameId: number, userId: number): Promise<PickListModel[]> {
    const promise = new Promise<PickListModel[]>((resolve, reject) => {
      this.httpclient.get<PickListModel[]>(this.settings.baseUrl + 'api/configuration/GetPickListData/' + FieldName + '/' + FieldNameId + '/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetSalesRep(userId: number): Promise<PickListModel[]> {
    const promise = new Promise<PickListModel[]>((resolve, reject) => {
      this.httpclient.get<PickListModel[]>(this.settings.baseUrl + 'api/configuration/GetSalesRep/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  
  public SaveFieldDataOrderBy(FieldID: number,OrderBy: string): Promise<PickListModel[]> {
    const promise = new Promise<PickListModel[]>((resolve, reject) => {
      this.httpclient.get<PickListModel[]>(this.settings.baseUrl + 'api/configuration/SaveFieldDataOrderBy/' + FieldID + '/' + OrderBy, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAccountsByFieldData(FieldName:string ,DataId:number): Promise<Accounts[]> {
    const promise = new Promise<Accounts[]>((resolve, reject) => {
      this.httpclient.get<Accounts[]>(this.settings.baseUrl + 'api/accounts/GetAccountsByFieldData/' + FieldName + "/" + DataId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetContactsByFieldData(FieldName: string, DataId: number): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/accounts/GetContactsByFieldData/' + FieldName + "/" + DataId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public UpdateAccountByColumn(ColumnName: string, ReplaceId: number, accountIds: string) {
    let obj: any[] = [ColumnName, ReplaceId, accountIds]; 
    const promise = new Promise<boolean>((resolve, reject) => {
      this.httpclient.post<boolean>(this.settings.baseUrl + 'api/customers/UpdateAccountByColumn', obj, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;

    
  }
  public transferAccounts(AccTransfr: AccountTransferModel) {
    
    const promise = new Promise<boolean>((resolve, reject) => {
      this.httpclient.post<boolean>(this.settings.baseUrl + 'api/accounts/transferAccounts', AccTransfr, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAccountsTransferByUser(LoggedInUserid: number,type:string): Promise<AccountTransferModel[]> {
    const promise = new Promise<AccountTransferModel[]>((resolve, reject) => {
      this.httpclient.get<AccountTransferModel[]>(this.settings.baseUrl + 'api/accounts/GetAccountsTransferByUser/' + LoggedInUserid + '/' + type, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public SaveSelectedUserList(userids: string, LoggedInUser: number): Promise<Dropdowntype[]> {

    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/customers/SaveSelectedUserList/' + userids + '/' + LoggedInUser, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public getSelectedUserList(LoggedInUser: number): Promise<Dropdowntype[]> {

    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/customers/getSelectedUserList/' + LoggedInUser, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  SaveFormConfiguration(FormConfig: FormConfiguration): Promise<FormConfiguration> {
    const promise = new Promise<FormConfiguration>((resolve, reject) => {
      this.httpclient.post<FormConfiguration>(this.settings.baseUrl + 'api/configuration/SaveFormConfiguration', FormConfig, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }
  GetFormConfiguration(id: number, typ: string): Promise<FormConfiguration[]> {
    const promise = new Promise<FormConfiguration[]>((resolve, reject) => {
      this.httpclient.get<FormConfiguration[]>(this.settings.baseUrl + 'api/configuration/getFormConfiguration/' + id + "/" + typ, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }
  getSaveFormFields(Obj: object): Promise<AccountFields[]> {
    const promise = new Promise<AccountFields[]>((resolve, reject) => {
      this.httpclient.post<AccountFields[]>(this.settings.baseUrl + 'api/configuration/getSaveFormFields', Obj, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }

  public GetProjectFields(uId: number): Promise<ProjectFields[]> {
    const promise = new Promise<ProjectFields[]>((resolve, reject) => {
      this.httpclient.get<ProjectFields[]>(this.settings.baseUrl + 'api/configuration/GetProjectFields/' + uId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  SaveProjectField(ProjectField: ProjectFields): Promise<ProjectFields> {
    const promise = new Promise<ProjectFields>((resolve, reject) => {
      this.httpclient.post<ProjectFields>(this.settings.baseUrl + 'api/configuration/SaveProjectField', ProjectField, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }

  public GetProjectPickListData(FieldId: number, FieldName: string, currentUser: number): Promise<ProjectPickListModel[]> {
    const promise = new Promise<ProjectPickListModel[]>((resolve, reject) => {
      this.httpclient.get<ProjectPickListModel[]>(this.settings.baseUrl + 'api/configuration/GetProjectPickListData/' + FieldId + '/' + FieldName + '/' + currentUser, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  SaveProjectPickList(PickList: ProjectPickListModel): Promise<ProjectPickListModel> {
    const promise = new Promise<ProjectPickListModel>((resolve, reject) => {
      this.httpclient.post<ProjectPickListModel>(this.settings.baseUrl + 'api/configuration/SaveProjectPickList', PickList, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }

  public SaveProjectFieldDataOrderBy(FieldID: number, OrderBy: string): Promise<ProjectPickListModel[]> {
    const promise = new Promise<ProjectPickListModel[]>((resolve, reject) => {
      this.httpclient.get<ProjectPickListModel[]>(this.settings.baseUrl + 'api/configuration/SaveProjectFieldDataOrderBy/' + FieldID + '/' + OrderBy, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  SaveProjectStage(ProjectStage: ProjectStages): Promise<ProjectStages> {
    const promise = new Promise<ProjectStages>((resolve, reject) => {
      this.httpclient.post<ProjectStages>(this.settings.baseUrl + 'api/configuration/SaveProjectStage', ProjectStage, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }


  public getProjectStages(uId:number): Promise<ProjectStages[]> {
    const promise = new Promise<ProjectStages[]>((resolve, reject) => {
      this.httpclient.get<ProjectStages[]>(this.settings.baseUrl + 'api/configuration/GetProjectStages/' + uId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public getProjStagesByTypeID(typeID: number): Promise<ProjectStages[]> {
    const promise = new Promise<ProjectStages[]>((resolve, reject) => {
      this.httpclient.get<ProjectStages[]>(this.settings.baseUrl + 'api/configuration/GetProjStagesbyTypeID/' + typeID, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  SaveProjectSteps(ProjectField: ProjectSteps): Promise<ProjectSteps> {
    const promise = new Promise<ProjectSteps>((resolve, reject) => {
      this.httpclient.post<ProjectSteps>(this.settings.baseUrl + 'api/configuration/SaveProjectSteps', ProjectField, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }


  public getProjectSteps(UId:number): Promise<ProjectSteps[]> {
    const promise = new Promise<ProjectSteps[]>((resolve, reject) => {
      this.httpclient.get<ProjectSteps[]>(this.settings.baseUrl + 'api/configuration/GetProjectSteps/' + UId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  AddUpdateProject(Project: Projects): Promise<Projects> {
    const promise = new Promise<Projects>((resolve, reject) => {
      this.httpclient.post<Projects>(this.settings.baseUrl + 'api/configuration/AddUpdateProject', Project, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public getAllProjects(userid: string): Promise<Projects[]> {
    const promise = new Promise<Projects[]>((resolve, reject) => {
      this.httpclient.get<Projects[]>(this.settings.baseUrl + 'api/configuration/GetAllProjects/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllProjectsByAccountId(accid: string): Promise<Projects[]> {
    const promise = new Promise<Projects[]>((resolve, reject) => {
      this.httpclient.get<Projects[]>(this.settings.baseUrl + 'api/configuration/GetAllProjectsByAccountId/' + accid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetProjectByProjectId(ProjId: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/configuration/GetProjectByProjectId/' + ProjId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public DeleteProject(ProjectId: number): Promise<Projects[]> {
    const promise = new Promise<Projects[]>((resolve, reject) => {
      this.httpclient.get<Projects[]>(this.settings.baseUrl + 'api/configuration/DeleteProject/' + ProjectId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public getProjectStepsForStatus(projectId: number, StageName: string): Promise<ProjectStepStatusModel[]> {
    const promise = new Promise<ProjectStepStatusModel[]>((resolve, reject) => {
      this.httpclient.get<ProjectStepStatusModel[]>(this.settings.baseUrl + 'api/configuration/GetProjectStepsForStatus/' + projectId + '/' + StageName, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  AddUpdateProjectStepStatus(Project: ProjectStepStatusModel[]): Promise<ProjectStepStatusModel> {
    const promise = new Promise<ProjectStepStatusModel>((resolve, reject) => {
      this.httpclient.post<ProjectStepStatusModel>(this.settings.baseUrl + 'api/configuration/AddUpdateProjectStepStatus', Project, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  public getAllForecastProjects(userid: string): Promise<ProjectForecastViewModel[]> {
    const promise = new Promise<ProjectForecastViewModel[]>((resolve, reject) => {
      this.httpclient.get<ProjectForecastViewModel[]>(this.settings.baseUrl + 'api/configuration/GetProjectForecastView/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public getAllForecastHeaders( ): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.get<string>(this.settings.baseUrl + 'api/configuration/ForecastHeaders',{ headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public getAllForecastData(UID:number): Promise<ProjectForecastViewModel[]> {
    const promise = new Promise<ProjectForecastViewModel[]>((resolve, reject) => {
      this.httpclient.get<ProjectForecastViewModel[]>(this.settings.baseUrl + 'api/configuration/ForecastData/' + UID, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public getProjectStatus(PID: number): Promise<ProjectForecastViewModel> {
    const promise = new Promise<ProjectForecastViewModel>((resolve, reject) => {
      this.httpclient.get<ProjectForecastViewModel>(this.settings.baseUrl + 'api/configuration/ProjectStatus/' + PID, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public getProjectForecastStageStatus(PID: number): Promise<ProjectForecastData[]> {
    const promise = new Promise<ProjectForecastData[]>((resolve, reject) => {
      this.httpclient.get<ProjectForecastData[]>(this.settings.baseUrl + 'api/configuration/ProjectForecastData/' + PID, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
 
  public GetMySalesXCRMChartTabs(UID: number): Promise<SalesXCRMChartTabs[]> {
    const promise = new Promise<SalesXCRMChartTabs[]>((resolve, reject) => {
      this.httpclient.get<SalesXCRMChartTabs[]>(this.settings.baseUrl + 'api/documents/GetMySalesXCRMChartTabs/' + UID, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  AddEditChartTab(tickets: SalesXCRMChartTabs): Promise<SalesXCRMChartTabs> {
    const promise = new Promise<SalesXCRMChartTabs>((resolve, reject) => {
      this.httpclient.post<SalesXCRMChartTabs>(this.settings.baseUrl + 'api/documents/AddEditChartTab', tickets, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAllAccountSharingGroups(userid: number): Promise<SharingGroups[]> {

    const promise = new Promise<SharingGroups[]>((resolve, reject) => {
      this.httpclient.get<SharingGroups[]>(this.settings.baseUrl + 'api/configuration/GetAllAccountSharingGroups/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public SaveAccountShareGroupData(SharingGroup: SharingGroups) {
    const promise = new Promise<SharingGroups>((resolve, reject) => {
      this.httpclient.post<SharingGroups>(this.settings.baseUrl + 'api/configuration/SaveAccountShareGroupData', SharingGroup, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetTicketsByTaskGroup(userid: number, TaskGroupId: number): Promise<Tickets[]> {
    const promise = new Promise<Tickets[]>((resolve, reject) => {
      this.httpclient.get<Tickets[]>(this.settings.baseUrl + 'api/zendesk/GetTicketsByTaskGroupId/' + userid + '/' + TaskGroupId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
}
