import {  Component, ElementRef} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { AppSettings } from './app.settings';
import { environment } from '../environments/environment';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { Compiler } from '@angular/core';
import { EventTargetInterruptSource } from '@ng-idle/core';
import { CustomerService } from './services/customer.service';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  currentDate: any;
  currentuser: any;
  TimeOutDialog: boolean = false;
  count: string = '2:00';
  addressFormGroup: FormGroup;

  constructor(private element: ElementRef, private compiler: Compiler, private http: HttpClient, private settings: AppSettings,
    private idle: Idle, private router: Router, private customerService: CustomerService, private keepalive: Keepalive) {

    this.compiler.clearCache();
    settings.baseUrl = environment.apiBaseUrl;
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    idle.setIdle(1800);
    idle.setTimeout(120);
    //idle.setIdle(900);
    //idle.setTimeout(120);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)
    idle.setInterrupts([
      new EventTargetInterruptSource(
        this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll mousemove')]
    );

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
    });

    idle.onTimeoutWarning.subscribe((countdown: number) => {

      var sec_num = countdown;
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      this.count = '0' + hours + ":" + '0' + minutes + ':' + seconds;

      this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
      if (this.currentuser != null) {

        this.TimeOutDialog = true;
        this.TimeOutDialogCss();
        $(setTimeout(function () {
          $(this).css('z-index', 8);
          $('.tooltiptopicevent').remove();
        }, 300));
      }

    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE_START';
    });
    idle.onTimeout.subscribe(() => {
     
      this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
      if (this.currentuser != null) {
        var AuditLogId = Number(localStorage.getItem("AuditLogId"));
        this.customerService.updatelogin(this.currentuser.id, 0, "System Timed Out", AuditLogId).then((user) => { });
      }
      localStorage.removeItem('currentUser');
      localStorage.removeItem('temptoken');
      localStorage.removeItem('QuickSearchConfig');
      localStorage.removeItem('TempTable');
      localStorage.removeItem('dashboardTab');
      localStorage.removeItem('selectedQueryCount');
      localStorage.removeItem('advSearchTabName');
      localStorage.removeItem('advSearchQueryId');
      localStorage.removeItem('SelectedSubMenu');
      localStorage.removeItem('SelectedUserIds');
      localStorage.removeItem('QuickSearchConfig');
      localStorage.removeItem('RhxAccountVal');
      localStorage.removeItem('RhxContactVal');
      localStorage.removeItem('CompaignVal');
      localStorage.removeItem('CompaignselVal');
      this.reset();
      this.router.navigateByUrl(`/login`);
      this.TimeOutDialog = false;
    });

    idle.watch();
  }
  StaySignedIn() {
    this.reset();
    this.TimeOutDialog = false;

  }

  TimeOutDialogCss() {
    setTimeout(function () {
      $('#TimeOutDialog .dialog').attr('style', 'width:32%;padding-bottom:0px;min-height:40%;overflow:auto;left:0%;top:15%;padding:0;border-radius:10px;');
    }, 4)
  }

  AgreeSignout() {


    this.router.navigateByUrl(`/login`);

    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    if (this.currentuser != null) {

      var AuditLogId = Number(localStorage.getItem("AuditLogId"));
      this.customerService.updatelogin(this.currentuser.id, 0, "User Logged Out", AuditLogId).then((user) => { });
    }

    localStorage.removeItem('currentUser');
    localStorage.removeItem('temptoken');
    localStorage.removeItem('QuickSearchConfig');
    localStorage.removeItem('TempTable');
    localStorage.removeItem('dashboardTab');
    localStorage.removeItem('selectedQueryCount');
    localStorage.removeItem('advSearchTabName');
    localStorage.removeItem('advSearchQueryId');
    localStorage.removeItem('SelectedSubMenu');
    localStorage.removeItem('SelectedUserIds');
    localStorage.removeItem('QuickSearchConfig');
    localStorage.removeItem('RhxAccountVal');
    localStorage.removeItem('RhxContactVal');
    localStorage.removeItem('CompaignVal');
    localStorage.removeItem('CompaignselVal');
    this.reset();
    this.router.navigateByUrl(`/login`);
    this.TimeOutDialog = false;
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  ngOnInit() {

  }



}


