import { Component, OnInit } from '@angular/core';
import { GridOptions, Column } from 'ag-grid';
import { ProposalItems } from '../../../models/ProposalItems';
import { UserService } from '../../../services/user.service';
import { OpprtunitiesService } from '../../../services/opprtunities.service';
import { Dashboardconfiguration } from '../../../models/dashboardconfiguration';
import { ApplicationComponent } from '../../application.component';

@Component({
  selector: 'proposal-items',
  templateUrl: './proposal-items.component.html',
  styleUrls: ['./proposal-items.component.css']
})
export class ProposalItemsComponent implements OnInit {
  public gridProposalOptions: GridOptions;
  public columnDefsForItems: any[];
  allProposalItems: ProposalItems[];
  public sideBar;
  currentUser: any;
  colSelections: string; private gridApi;
  public context;
  public frameworkComponents;
  constructor(private userService: UserService, private opprtunityservice: OpprtunitiesService,public applicationComponent: ApplicationComponent) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.initializeAgGrid();
    this.GetAllProposalItems();
  }

  initializeAgGrid() {
    this.gridProposalOptions = <GridOptions>{
      pagination: true, enableColResize: true, paginationPageSize: 1000, enableSorting: false, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true,
      toolPanelSuppressPivotMode: true, rowSelection: 'single', enableCellChangeFlash: true, suppressSorting: true, suppressMultiSort: true     
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    let configId = 52;

    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentUser.id, configId).then((config) => {     
      this.colSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      if (this.colSelections != null) {
        this.createColumnDefs(this.colSelections);
      } else {
        this.createColumnDefs("");
      }
    });

  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();

  }
  saveConfiguration() {
    let allCols: Column[] = this.gridProposalOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();   
      config.maxlimit = 1000;   
    config.columnsselections = "";
    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }
    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    this.UpdateSearchColumns(config.columnsselections);

    config.configureduserid = this.currentUser.id;
    config.resourceid =  52;

    config.userid = "";
    for (let i = 0; i < this.applicationComponent.selectedItems.length; i++) {
      config.userid += this.applicationComponent.selectedItems[i].id + ",";
    }
  
    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    this.opprtunityservice.saveDashboardconfiguration(config);
  }

  UpdateSearchColumns(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[] = columnSelections.split(',');
    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');

      if ((fields[2] == 'true' && fields[1] != '#') && (fields[2] == 'true' && fields[0] != 'notes') && (fields[2] == 'true' && fields[0] != 'actions')) {

        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }
    //this.applicationComponent.ShowSearchTabs('True');
    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];

    if (retrievedObject != null) {
      let obj: any[] = JSON.parse(retrievedObject);
      obj.forEach(element => {

        if (element.subtab == 'CustomerAccount') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        } else if (element.subtab == 'ContactsAccount') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        }


      });
    }
    if (tempTableColumns && tempTableColumns.length > 0) {
      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })

    }
  
  }
  GetAllProposalItems() {
    this.userService.GetAllProposalItems(this.currentUser.id, 0).then(result => {
      this.allProposalItems = result;
    });
  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }

  createColumnDefs(columnSelections: string) {
    let selfobj = this;
    let columnSelectionsArray: string[] = [];

    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefsForItems = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefsForItems.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else {
          this.columnDefsForItems.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefsForItems = [
        {
          field: '#',
          headerName: '#',
          width: 65,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true,
          rowDrag: true
        },
        { field: 'CategoryName', headerName: 'Category', hide: false, width: 220 },
        {
          field: 'ProposalItemName', headerName: 'Items', hide: false, width: 450
        },
        { field: 'SortOrder', headerName: 'Sort Order', hide: false, width: 150 },
        { field: 'Price12', headerName: 'Price12 Months', hide: false, width: 150 },
        { field: 'Price24', headerName: 'Price24 Months', hide: false, width: 150 },
        { field: 'Price36', headerName: 'Price36 Months', hide: false, width: 150 },
        { field: 'Monthly12', headerName: 'Monthly12 Months', hide: false, width: 150 },
        { field: 'Monthly24', headerName: 'Monthly24 Months', hide: false, width: 150 },
        { field: 'Monthly36', headerName: 'Monthly36 Months', hide: false, width: 150 },


      ];
    }
  }
}
