export class Message {
    clientuniqueid: string;
    type: string;
    message: string;
  date: Date; UserName: string;
  groupName: string;
  groupId: number;
  fromuserid: number;
  touserid: string;
  createdBy: number;
  grpusers: string;
  Id: number;
  }
