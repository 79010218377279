<div class="container" style="padding: 0%;margin-top:0;height:100%">
  <div class="container" style="margin-top:3px;background-color:#F4F4F4;border-radius:3px; padding:0;height:100%">
    <div class="col-xs-12" style="padding: 0.3% 0 0 0;height: 8%;background: white;">
      <div class="col-xs-4" style="padding:0px">
        <button id="test" style="display:none"></button>
        <div [ngClass]="(DropDownListBox)? 'col-md-12' : 'col-md-12' " style="padding:  0;width:100%;margin-top:1%;">
          <div class="col-md-10 " [ngStyle]="{'margin-top.%':0,'width.%': 79.5 }" style="padding:0; ">
            <angular2-multiselect [data]="applicationComponent.dropdownList"
                                  [(ngModel)]="applicationComponent.selectedItems" id="UserList" [settings]="dropdownSetting"
                                  (onSelect)="onItemSelect($event)"
                                  (onDeSelect)="OnItemDeSelect($event)"
                                  (onSelectAll)="onSelectAll($event)"
                                  (onDeSelectAll)="onDeSelectAll($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>
      <div class="col-xs-8 tabset" style="padding: 0px;width:66.5%">   
        <input type="radio" name="tabset" id="tab1" value="tab1" [(ngModel)]="selectedMenuRadio" aria-controls="bano" />
        <label for="tab1" [ngStyle]="{color:(!this.myDashboard) ? 'gray' : '#2A3740' }" style="text-align:center;height:45px" (click)="DashboardChanged('tab1')">My <br />Dashboard</label>

        <input type="radio" name="tabset" id="tab5" value="tab5" [(ngModel)]="selectedMenuRadio" aria-controls="banoo" />
        <label for="tab5" [ngStyle]="{color:(!this.supportDashboard) ? 'gray' : '#2A3740' }" style="text-align: center; height: 45px" (click)="DashboardChanged('tab5')">Support<br /> Dashboard</label>

        <input type="radio" name="tabset" id="tab4" value="tab4" [(ngModel)]="selectedMenuRadio" aria-controls="tej" style="margin-left: 1px">
        <label for="tab4" [ngStyle]="{color:(!this.trainerDashboard) ? 'gray' : '#2A3740' }" style="text-align: center; height: 45px" (click)="DashboardChanged('tab4')">Trainer<br /> Dashboard</label>

        <input type="radio" name="tabset" id="tab6" value="tab6" [(ngModel)]="selectedMenuRadio" aria-controls="banoo" />
        <label for="tab6" [ngStyle]="{color:(!this.ProjectsDashboard) ? 'gray' : '#2A3740' }" style="text-align: center; height: 45px" (click)="DashboardChanged('tab6')">Projects<br /> Dashboard</label>


        <input type="radio" name="tabset" id="tab3" value="tab3" [(ngModel)]="selectedMenuRadio" aria-controls="rauchbier" style="margin-left: 1px">
        <label for="tab3" [ngStyle]="{color:(!this.nbdDashBoard) ? 'gray' : '#2A3740' }" style="text-align: center; height: 45px" (click)="DashboardChanged('tab3')">NBD<br /> Dashboard</label>

        <input type="radio" name="tabset" id="tab2" value="tab2" [(ngModel)]="selectedMenuRadio" aria-controls="marzen">
        <label for="tab2" [ngStyle]="{color:(!this.salesDashBoard) ? 'gray' : '#2A3740' }" style="text-align: center; height: 45px" (click)="DashboardChanged('tab2')">Sales<br /> Dashboard</label>

        <input type="radio" name="tabset" id="tab7" value="tab7" [(ngModel)]="selectedMenuRadio" aria-controls="banoo" />
        <label for="tab7" [ngStyle]="{color:(!this.RevenueDashboard) ? 'gray' : '#2A3740' }" style="text-align: center; height: 45px" (click)="DashboardChanged('tab7')">Revenue<br /> Dashboard</label>

        <input type="radio" name="tabset" id="tab8" value="tab8" aria-controls="banoo" [(ngModel)]="selectedMenuRadio" />
        <label for="tab8" style="text-align: center; height: 45px;" (click)="DashboardChanged('tab8')"> Campaigns <br /> Dashboard </label>

        <input type="radio" name="tabset" id="tab9" value="tab9" aria-controls="banoo" [(ngModel)]="selectedMenuRadio" />
        <label for="tab9" style="text-align: center; height: 45px;" (click)="DashboardChanged('tab9')"> Marketing <br /> Dashboard </label>
      </div>

    </div>
    <app-notification (sendMsgEvent)="onWizzardClosed($event)" [NotificationMessages]="NotificationMessages"></app-notification>
    <div [ngStyle]="{'overflow':NotificationMessages.length>0 ? 'auto' : '' }" style="height:90%;padding:0;" class="col-xs-12">
  <div class="tab-panels" *ngIf="((selectedMenuRadio == 'tab1' && myDashboard) || (selectedMenuRadio == 'tab2' && salesDashBoard) || (selectedMenuRadio == 'tab3' && nbdDashBoard) || (selectedMenuRadio == 'tab4' && trainerDashboard) || (selectedMenuRadio == 'tab5' && supportDashboard) || (selectedMenuRadio == 'tab9' && nbdDashBoard))" style="height:99%">
    <div class="col-xs-12" style="height:100%;padding:0.7%" [ngStyle]="{'border':(UserIdsCount==1 || UserIdsCount==0) ? '1px solid #ddd' : '3px solid #90d24e' }">
      <div class="col-xs-12" style="padding:0;height:100%;">
        <div *ngIf="currenttab('tab1') ||  currenttab('tab2') || currenttab('tab3') ||currenttab('tab4') ||currenttab('tab5')||currenttab('tab8')||currenttab('tab9')" class="col-xs-12" style="padding:0px;height:14%">
          <div class="col-lg-3 col-md-6 col-sm-6" style="padding-left: 0;">
            <div class="card card-stats" style="cursor:pointer;">
              <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
                <br />
                <b>No Data</b>
              </div>
              <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
                <!--style="padding:0;border: 3px solid #90d24e">-->
                <div *ngIf="currenttab('tab1') || currenttab('tab2') || currenttab('tab3') " class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                  <div class="col-xs-8" style="padding:0;">
                    <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right:0;color:#2A3740;font-size: 0.95em">Calls Completed</h4>
                  </div>
                  <div class="col-xs-4" style="padding:0;text-align:center">
                    <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:12% 0 0 0;text-align:center">
                      <b class="countCss" style="margin-left: 0;">
                        {{this.salesinfo.Monthlycallscount}} <span *ngIf="UserIdsCount==1" style="color: darkgray;">/{{this.salesinfo.Monthlycallscompleted}}</span>
                      </b>
                    </h4>
                  </div>
                </div>
                <div *ngIf="currenttab('tab4') || currenttab('tab5')|| currenttab('tab9') " class="col-xs-12" style="padding:0 0 0 3%;margin-right:0;">
                  <div class="col-xs-8" style="padding:0;">
                    <h4 *ngIf="currenttab('tab4') || currenttab('tab5')" class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.95em">New Tickets</h4>
                  </div>
                  <div class="col-xs-4" style="padding:0;text-align:center">
                    <h4 class="card-category headerTextCss" style="margin:12% 0 0 0;text-align:center">
                      <b *ngIf="currenttab('tab4') || currenttab('tab5')" class="countCss" style="margin-left: 0;">
                        {{this.salesinfo.NewTicketCount}} <span *ngIf="UserIdsCount==1" style="color: darkgray;">/{{this.salesinfo.NewTicketsgoal}}</span>
                      </b>
                      <b *ngIf="currenttab('tab9')" class="countCss" style="margin-left: 0;">
                        <span *ngIf="UserIdsCount==1" style="color: white;">/</span>
                      </b>
                    </h4>
                  </div>
                </div>
                <div *ngIf="currenttab('tab1') || currenttab('tab2') || currenttab('tab3')" class="col-xs-12" style="padding:0% 0% 0 3%">

                  <div class="col-xs-7" style="padding:0">
                   <progress-bar [progress]="this.salesinfo.MonthlycallsPercentage" [color]="MonthlycallsPercentageColor">
                    </progress-bar>
                  </div>

                  <div class="col-xs-5" style="padding:0;text-align:center">
                    <div class="col-xs-8" style="padding:0;">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.95em;padding:0 0 0 3%">{{CallsCompletedLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="CallCompletedByMonth($event.target.value)" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />
                   </div>
                  </div>
                </div>
                <div *ngIf="currenttab('tab4') || currenttab('tab5') || currenttab('tab9')" class="col-xs-12" style="padding:0% 0% 0 3%">

                  <div class="col-xs-7" style="padding:0">
                   <progress-bar *ngIf="currenttab('tab4') || currenttab('tab5') " [progress]="this.salesinfo.NewTicketPercentage" [color]="NewTicketsPercentageColor">
                    </progress-bar>
                   <progress-bar *ngIf="currenttab('tab9') " [progress]="0" [color]="NewTicketsPercentageColor">
                    </progress-bar>
                  </div>

                  <div class="col-xs-5" style="padding:0;text-align:center">

                    <div class="col-xs-8" style="padding:0;">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.95em;padding:0 0 0 3%">{{NewTicketsLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="NewTicketAddedByMonth($event.target.value)" style="font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />

                    </div>

                  </div>
                </div>

                <!--<div class="col-xs-1" style="padding:0;">

                  <i class="fa fa-user iconCSS" aria-hidden="true" style="margin-left:34%"></i>

                </div>-->

              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6" style="padding-left: 0;">
            <div class="card card-stats" style="cursor:pointer;">
              <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
                <br />
                <b>No Data</b>
              </div>
              <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">


                <div class="col-xs-12" style="padding:0 0 0 3%;margin-right:0;">
                  <div class="col-xs-8" style="padding:0;" *ngIf="currenttab('tab1') || currenttab('tab3') ||currenttab('tab4')||currenttab('tab5') ||currenttab('tab9')">
                    <h4 *ngIf="currenttab('tab1')" class="card-category headerTextCss" style="font-size: 0.95em;margin:4% 0 3% 0; color:#2A3740">Meetings Completed</h4>
                    <h4 *ngIf="currenttab('tab3')" class="card-category headerTextCss" style="font-size: 0.95em;margin:4% 0 3% 0; color:#2A3740">Monthly Calls Completed</h4>
                    <h4 *ngIf="currenttab('tab4') ||currenttab('tab5')" class="card-category headerTextCss" style="font-size: 0.95em;margin:4% 0 3% 0; color:#2A3740">Closed Tickets</h4>
                  </div>
                  <div class="col-xs-6" style="padding:0;" *ngIf="currenttab('tab2')">
                    <h4 class="card-category headerTextCss" style="font-size: 0.95em;color:#2A3740"> Sales Won</h4>
                  </div>

                  <div *ngIf="currenttab('tab1')" class="col-xs-4" style="padding:0;text-align:center">
                    <h4 class="card-category headerTextCss" style="font-size:0.90em;margin:14% 0 0 0;text-align:center">
                      <b class="countCss" style="margin-left: 0;">
                        {{this.salesinfo.MonthlyMeetingCompletedCount}}
                        <span *ngIf="UserIdsCount==1" style="color: darkgray;">/{{this.salesinfo.MonthlyMeetingCompletedgoal}}</span>
                      </b>

                    </h4>

                  </div>
                  <div *ngIf="currenttab('tab2')" class="col-xs-6" style="padding:0;text-align:right">
                    <h4 class="card-category headerTextCss" style="font-size:0.90em;margin:7% 7% 0 0;text-align:right">
                      <b class="countCss" style="margin-left: 0;font-size:14px;">
                        {{this.salesinfo.MonthlySalesWonCount}}
                        <span *ngIf="UserIdsCount==1" style="color: darkgray;">/{{this.salesinfo.MonthlySaleswongoal}}</span>
                      </b>

                    </h4>

                  </div>
                  <div *ngIf="currenttab('tab3')" class="col-xs-4" style="padding:0;">
                    <h4 class="card-category headerTextCss" style="font-size:0.90em;margin:13% 0 0 0;text-align:center">

                      <b class="countCss" style="margin-left: 0;">
                        {{this.salesinfo.Monthlycallscount}}
                        <span *ngIf="UserIdsCount==1" style="color: darkgray">/{{this.salesinfo.Monthlycallscompleted}}</span>
                      </b>

                    </h4>
                  </div>
                  <div *ngIf="currenttab('tab4')|| currenttab('tab5')|| currenttab('tab9')" class="col-xs-4" style="padding:0;">
                    <h4 *ngIf="currenttab('tab4')|| currenttab('tab5')" class="card-category headerTextCss" style="font-size:0.90em;margin:13% 0 0 0;text-align:center">

                      <b class="countCss" style="margin-left: 0;">
                        {{this.salesinfo.ClosedTicketCount}}
                        <span *ngIf="UserIdsCount==1" style="color: darkgray">/{{this.salesinfo.ClosedTicketsgoal}}</span>
                      </b>

                    </h4>
                    <h4 *ngIf="currenttab('tab9')" class="card-category headerTextCss" style="font-size:0.90em;margin:13% 0 0 0;text-align:center">

                      <b class="countCss" style="margin-left: 0;">
                        <!--{{this.salesinfo.ClosedTicketCount}}-->
                        <span *ngIf="UserIdsCount==1" style="color: white">/{{this.salesinfo.ClosedTicketsgoal}}</span>
                      </b>

                    </h4>
                  </div>


                </div>

                <div *ngIf="currenttab('tab1')" class="col-xs-12" style="padding:0% 0% 0 3%">

                  <div class="col-xs-7" style="padding:0;">
                    
                   <progress-bar *ngIf="UserIdsCount==1" [progress]="salesinfo.MonthlyMeetingCompletedPercentage" [color]="MonthlyMeetingCompletedPercentageColor">
                    </progress-bar>
                  </div>
                  <div class="col-xs-5" style="padding:0;text-align:center">

                    <div class="col-xs-8" style="padding:0;">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.95em;padding:0 0 0 3%">{{MonthlyMeetingLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="MonthlyMeetingCompletedByMonth($event.target.value)" style="font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />

                    </div>



                  </div>

                </div>
                <div *ngIf="currenttab('tab2')" class="col-xs-12" style="padding:0% 0% 0 3%">
                  <div class="col-xs-7" style="padding:0;">
                    <progress-bar *ngIf="UserIdsCount==1" [progress]="salesinfo.MonthlySalesWonPercentage" [color]="MonthlySalesWonPercentageColor">
                    </progress-bar>
                  </div>
                  <div class="col-xs-5" style="padding:0;text-align:center">
                    <div class="col-xs-8" style="padding:0;">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{MonthlyMeetingLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="MonthlySalesWon($event.target.value)" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />

                    </div>

                  </div>
                </div>
                <div *ngIf="currenttab('tab3')" class="col-xs-12" style="padding:0% 0% 0 3%">
                  <div class="col-xs-7" style="padding:0;">
                    <progress-bar *ngIf="UserIdsCount==1" [progress]="salesinfo.MonthlycallsPercentage" [color]="MonthlycallsPercentageColor">
                    </progress-bar>
                  </div>
                  <div class="col-xs-5" style="padding:0;text-align:center">
                    <div class="col-xs-8" style="padding:0;">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{MonthlyMeetingLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="MonthlyMeetingCompletedByMonth($event.target.value)" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />

                    </div>
                  </div>

                </div>
                <div *ngIf="currenttab('tab4') || currenttab('tab5')|| currenttab('tab9')" class="col-xs-12" style="padding:0% 0% 0 3%">
                  <div class="col-xs-7" style="padding:0;">
                  <progress-bar *ngIf="UserIdsCount==1" [progress]="salesinfo.ClosedTicketPercentage" [color]="ClosedTicketsPercentageColor">
                    </progress-bar>
                  </div>
                  <div class="col-xs-5" style="padding:0;text-align:center">

                    <div class="col-xs-8" style="padding:0;">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{ClosedTicketsLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="ClosedTicketsByMonth($event.target.value)" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />

                    </div>

                    <!--<input type="month" (change)="ClosedTicketsByMonth($event.target.value)" value="{{CurrentDate}}" style="padding:0;width:100%;font-size:12px;border:none !important;float:right;text-align:right;margin:3% 0 0 0;font-family:none;" />-->
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6" style="padding-left: 0;">
            <div class="card card-stats" style="cursor:pointer;">
              <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
                <br />
                <b>No Data</b>
              </div>
              <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">


                <div class="col-xs-12" style="padding:0 0 0 3%;">
                  <div class="col-xs-8" style="padding:0;" *ngIf="currenttab('tab1') || currenttab('tab3')">
                    <h4 *ngIf="currenttab('tab1')" class="card-category headerTextCss" style="margin:5% 0 0 0;font-size: 0.95em;color:#2A3740">To-Do's  Completed</h4>
                    <h4 *ngIf="currenttab('tab3')" class="card-category headerTextCss" style="margin:4% 0 3% 0;font-size: 0.95em;color:#2A3740">Monthly Accounts Added</h4>
                  </div>
                  <div class="col-xs-6" style="padding:0;" *ngIf="currenttab('tab2')">
                    <h4 class="card-category headerTextCss" style="margin-right:0;font-size: 0.95em;color:#2A3740">Monthly Won</h4>
                  </div>
                  <div *ngIf="currenttab('tab1')" class="col-xs-4" style="padding: 0%;text-align:center">
                    <h4 class="card-category headerTextCss" style="margin:13% 0 0 0;font-size:0.90em;text-align:center">

                      <b class="countCss" style="margin-left: 0;">
                        {{salesinfo.MonthlyToDoCount}}
                        <span *ngIf="UserIdsCount==1" style="color:darkgray;">/{{salesinfo.MonthlyToDogoal}}</span>
                      </b>

                    </h4>
                  </div>
                  <div *ngIf="currenttab('tab2')" class="col-xs-6" style="padding: 0%;text-align:right">
                    <h4 class="card-category headerTextCss" style="margin:7% 7% 0 0;font-size:0.90em;text-align:right">
                      <b class="countCss" style="margin-left: 0;font-size:14px;">
                        {{salesinfo.MonthlySalesRRCount}}
                        <span *ngIf="UserIdsCount==1" style="color:darkgray;">/{{salesinfo.MonthlySalesRRgoal}}</span>
                      </b>

                    </h4>
                  </div>
                  <div *ngIf="currenttab('tab3')" class="col-xs-4" style="padding: 0%;">
                    <h4 class="card-category headerTextCss" style="margin:12% 0 0 0;font-size:0.90em;text-align:center">
                      <b class="countCss" style="margin-left: 0;">
                        {{salesinfo.MonthlyAccountsaddedcount}}
                        <span *ngIf="UserIdsCount==1" style="color:darkgray;">/{{salesinfo.MonthlyAccountAdded}}</span>
                      </b>

                    </h4>
                  </div>
                  <div *ngIf="currenttab('tab4') || currenttab('tab9')" class="col-xs-12" style="padding:0 0 0 3%;margin-right:0;">
                    <div class="col-xs-8" style="padding:0;">
                      <h4 *ngIf="currenttab('tab4')" class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right:0;color:#2A3740;font-size: 0.95em">Calls Completed</h4>
                    </div>
                    <div class="col-xs-4" style="padding:0;text-align:center">
                      <h4 *ngIf="currenttab('tab4')" class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:12% 0 0 0;text-align:center">
                        <b class="countCss" style="margin-left: 0;">
                          {{this.salesinfo.Monthlycallscount}} <span *ngIf="UserIdsCount==1" style="color: darkgray;">/{{this.salesinfo.Monthlycallscompleted}}</span>
                        </b>
                      </h4>
                      <h4 *ngIf="currenttab('tab9')" class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:12% 0 0 0;text-align:center">
                        <b class="countCss" style="margin-left: 0;">
                         <span *ngIf="UserIdsCount==1" style="color: white;">/{{this.salesinfo.Monthlycallscompleted}}</span>
                        </b>
                      </h4>
                    </div>
                    <div class="col-xs-12" style="padding:0% 0% 0 3%">

                      <div class="col-xs-7" style="padding:0">
                       <progress-bar [progress]="this.salesinfo.MonthlycallsPercentage" [color]="MonthlycallsPercentageColor">
                        </progress-bar>
                      </div>

                      <div class="col-xs-5" style="padding:0;text-align:center">

                        <div class="col-xs-8" style="padding:0;">
                          <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{CallsCompletedLabel}}</span>
                        </div>
                        <div class="col-xs-4" style="padding:0;">
                          <input type="month" (change)="CallCompletedByMonth($event.target.value)" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 22px;" value="{{CurrentDate}}" />

                        </div>

                        <!--<input type="month" (change)="CallCompletedByMonth($event.target.value)" style="font-size:12.2px;font-family:none; border:none !important;margin-left:0%;width:100%;text-align:right" value="{{CurrentDate}}" />-->
                      </div>
                    
                    </div>
                  </div>
                </div>

                <div *ngIf="currenttab('tab1')" class="col-xs-12" style="padding:0% 0% 0 3%">

                  <div class="col-xs-7" style="padding:0;">
                   <progress-bar *ngIf="UserIdsCount==1" [progress]="this.salesinfo.MonthlyToDoPercentage" [color]="MonthlyToDoPercentageColor">
                    </progress-bar>
                  </div>
                  <div class="col-xs-5" style="padding:0;text-align:center">


                    <div class="col-xs-8" style="padding:0;">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{MonthlyToDoLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="MonthlyToDoCompletedByMonth($event.target.value)" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />

                    </div>


                    <!--<input type="month" (change)="MonthlyToDoCompletedByMonth($event.target.value)" style="width:100%;padding:0;margin:2% 0 0 0;font-size:12px;border:none !important;float:right;text-align:right;font-family:none;" value="{{CurrentDate}}" />-->
                  </div>

                </div>
                <div *ngIf="currenttab('tab2')" class="col-xs-12" style="padding:0% 0% 0 3%">

                  <div class="col-xs-7" style="padding:0;">
                    <progress-bar [progress]="this.salesinfo.MonthlySalesRRPercentage" [color]="MonthlySalesRRPercentageColor">
                    </progress-bar>
                  </div>
                  <div class="col-xs-5" style="padding:0;text-align:center">

                    <div class="col-xs-8" style="padding:0;">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{MonthlySalesRRLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="MonthlySalesRR($event.target.value)" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />

                    </div>

                    <!--<input type="month" (change)="MonthlySalesRR($event.target.value)" style="width:100%;padding:0;margin:3% 0 0 0;font-size:12px;border:none !important;float:right;text-align:right;font-family:none;" value="{{CurrentDate}}" />-->
                  </div>
                   
                </div>
               
                <div *ngIf="currenttab('tab3')" class="col-xs-12" style="padding:0% 3% 0 3%">

                  <div class="col-xs-7" style="padding:0;">
                   <progress-bar [progress]="this.salesinfo.MonthlyAccountsaddedPercentage" [color]="MonthlyAccountsaddedPercentageColor">
                    </progress-bar>
                  </div>
                  <div class="col-xs-5" style="padding:0;text-align:center">

                    <div class="col-xs-8" style="padding:0;">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{MonthlyAccountsLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="MonthlyAccountAddedByMonth($event.target.value)" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />

                    </div>

                    <!--<input type="month" (change)="MonthlyAccountAddedByMonth($event.target.value)" style="width:100%;padding:0;margin:3% 0 0 0;font-size:12px;border:none !important; text-align:right;float:right;font-family:none;" value="{{CurrentDate}}" />-->
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6" style="padding: 0;">
            <div class="card card-stats" style="cursor:pointer;">
              <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
                <br />
                <b>No Data</b>
              </div>
              <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">

                <div class="col-xs-12" style="padding:0 0 0 3%">
                  <div class="col-xs-8" style="padding:0;" *ngIf="currenttab('tab2') || currenttab('tab3')">
                    <h4 *ngIf="currenttab('tab2')" class="card-category headerTextCss" style="font-size:0.85em;color:#2A3740;">Leads Converted</h4>
                    <h4 *ngIf="currenttab('tab3')" class="card-category headerTextCss" style="font-size:0.85em;color:#2A3740;margin:4% 0 3% 0">Monthly Leads Converted</h4>
                  </div>

                  <div *ngIf="currenttab('tab2') || currenttab('tab3')" class="col-xs-4" style="padding:0;">
                    <h4 class="card-category headerTextCss" style="font-size:0.90em;margin:13% 0 0 0;text-align:center">

                      <b class="countCss" style="margin-left: 0;">{{salesinfo.leadconvertedcount}}<span *ngIf="UserIdsCount==1" style="color: darkgray;">/{{salesinfo.monthlyleadconvertedcount}}</span></b>

                    </h4>
                  </div>
                  <div class="col-xs-12" *ngIf="currenttab('tab1')" style="padding: 1% 0 0 0; font-size: 0.90em;">
                    <div class="col-xs-12" style="padding: 3% 0 0 0; width: 100%; font-weight: bold;">
                      SalesXCRM User Usage Stats
                    </div>
                    <div class="col-xs-12" style="padding:3% 0 0 0;width:100%">
                      <div class="col-xs-3" style="padding: 0px;"><span style="font-weight: bold;">Today</span>  {{TodayTimeSpent |number : '1.1-1' }}</div>
                      <div class="col-xs-3" style="padding: 0px;"><span style="font-weight: bold;">Week</span>  {{WeekkTimeSpent | number: '1.1-1'}}</div>
                      <div class="col-xs-3" style="padding: 0px;"><span style="font-weight: bold;">Month</span>  {{MonthTimeSpent |number : '1.1-1' }}</div>
                      <div class="col-xs-3" style="padding: 0px;"><span style="font-weight: bold;">YTD</span>  {{YearTimeSpent | number: '1.1-1'}}</div>

                    </div>
                  </div>
                </div>
               
                <div *ngIf="currenttab('tab2')" class="col-xs-12" style="padding:0% 0% 0 3%">

                  <div class="col-xs-7" style="padding:0;">
                   <progress-bar [progress]="this.salesinfo.leadconvertedPercentage" [color]="MonthlylastDueActivityPercentageColor">
                    </progress-bar>
                  </div>
                  <div class="col-xs-5" style="padding:0;text-align:center">


                    <div class="col-xs-8" style="padding:0;">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{LeadsConvertedLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="LeadsConvertedByMonth($event.target.value)" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />

                    </div>


                    <!--<input type="month" (change)="LeadsConvertedByMonth($event.target.value)" style="width:100%;padding:0;margin:3% 0 0 0;font-size:12px;font-size:12.2px;border:none !important;text-align:right;float:right;font-family:none;" value="{{CurrentDate}}" />-->
                  </div>

                </div>
                <div *ngIf="currenttab('tab3')" class="col-xs-12" style="padding:0% 3% 0 3%">

                  <div class="col-xs-7" style="padding:0;">
                    <progress-bar [progress]="this.salesinfo.leadconvertedPercentage" [color]="leadconvertedPercentageColor">
                    </progress-bar>
                  </div>
                  <div class="col-xs-5" style="padding:0;text-align:center">

                    <div class="col-xs-8" style="padding:0;">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{LeadsConvertedLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="LeadsConvertedByMonth($event.target.value)" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />

                    </div>
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12" style="margin:0;padding:0;height:86%">
          <div *ngIf="!EXpandCalender" style="height:99%" [style.padding]="'0 1% 0 0'" id="dashProposalLeadActivityDiv" [ngClass]="'col-md-6 col-sm-12 col-xs-12' ">
            <div class="box" *ngIf="currenttab('tab1')" [ngStyle]="{'margin-top':'1.7% !important' ,'height':'99%'}" id="CalendarTomorrowActDiv">
              <div class="box-header" style="border-bottom: 1px solid #ddd; padding: 0.5% 0 0 0; height: 6%; margin: 0; width: 100%; background-color: rgb(214,225,201);">
                <div class="col-xs-12" style="padding: 0;">
                  <div class="col-xs-12 haderCss" style="margin-left: 0;padding-top: 0;padding-bottom: 0;padding-left:0">
                    <span style="font-size: 0.95em;">My Calendar</span>
                    <span style="font-size: 0.95em;float: right" (click)="displayCalendar=!displayCalendar">{{displayCalendar ? 'Close Calendar':'Display Calendar'}}</span>

                  </div>

                </div>
              </div>

              <div class="box-body" style="padding:0;overflow:auto;padding-top:0%;height:88%">
                <full-calendar #myCalendar1 [options]="calendarOptions2" id="myCalendar1"></full-calendar>
              </div>

            </div>

            <div *ngIf="currenttab('tab2') || currenttab('tab3')" class="box" [ngStyle]="{'margin-top':'2%','height':'48%' }">
              <div class="box-header" style="border-bottom: 1px solid #ddd; padding: 0; cursor: pointer; height: 12%; width: 100%; background-color: rgb(214,225,201);">

                <div class="col-xs-12" style="padding: 0.5%;">
                  <div class="col-xs-10 haderCss" style="padding:0.5%;">
                    <span style="font-size: 0.95em;" *ngIf="currenttab('tab2')">
                      Sales Opportunities - Open &nbsp;&nbsp;&nbsp;

                    </span>
                    <span style="font-size: 0.95em;" *ngIf="currenttab('tab3')">Activity Report</span>
                  </div>

                  <div class="col-xs-2 haderCss" style="margin-left: 0;padding: 0;">
                    <label *ngIf="(UserIds != null) && currenttab('tab2')">{{OpenOppTotal}}</label>
                    <span style="font-size: 15px;cursor:pointer;float: right;margin:0 1% 0 0" *ngIf="currenttab('tab3' )">
                      <select id="ActivityReportId" (change)="ActivityReportChange($event.target.value);" style="font-size: 13px !important;background-color: rgb(214,225,201); border: none">
                        <option *ngFor="let act of ActivityDropdowns" value={{act.id}}>
                          {{act.itemName}}
                        </option>
                      </select>
                    </span>
                  </div>
                </div>
              </div>

              <div *ngIf="currenttab('tab2')" class="box-body" style="height:87%;" (click)="OpenOpportunities()">
                <div class="col-md-5 headerCSS" style="display: block;width:50%;padding:3%">
                  <canvas baseChart
                          [data]="customerpieChartData"
                          [chartType]="customerpieChartType"
                          [colors]="customerpieChartcolors"
                          [labels]="customerpieChartLabels"
                          [legend]="false"
                          (chartClick)="chartClicked($event)"></canvas>
                </div>
                <div *ngIf="(UserIds != null)" class="col-xs-1" style="padding:0px;margin-top:2%;font-size:16px !important">
                  <div *ngFor="let lbl of pieColors">
                    <div [ngStyle]="{'background-color': lbl , 'width':'20px' , height:'10px'}"></div><br />
                  </div>
                </div>
                <div class="col-md-5" style="padding:0px;">
                  <div *ngFor="let lbl of customerpieChartLabels1" class="col-xs-12" style="padding:0;">
                    <div class="col-xs-6" style="padding:0;">
                      <h6 style="margin:6%;margin-bottom:2.5%">{{lbl.name}}</h6>
                    </div>
                    <div class="col-xs-6" style="padding:0;">
                      <h6 style="margin:6%; margin-bottom:2.5%;color:#2A3740">{{lbl.percentage}}</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="currenttab('tab3')" class="box-body" style="overflow:hidden;height:87%;padding:0;">

                <ag-grid-angular class="ag-theme-balham" style="height:99%;width:100%"
                                 [gridOptions]="AccountgridOptions"
                                 [columnDefs]="AccontcolumnDefs"
                                 [defaultColDef]="applicationComponent.defaultGridColDef"
                                 [rowData]="AllAccountData"
                                 [sideBar]="false"
                                 (rowDoubleClicked)="AccountrowDoubleClickAgGrid($event)"
                                 (columnVisible)="columnVisible($event)"
                                 (dragStopped)="dragStopped($event)"
                                 [suppressRowClickSelection]="true"
                                 (columnResized)="columnResized($event)"
                                 (gridReady)="onAccountAgGridReady($event)">
                </ag-grid-angular>

              </div>
            </div>
            <div *ngIf="currenttab('tab4') || currenttab('tab5')|| currenttab('tab9')" class="col-md-12" [ngStyle]="{'margin-top': '2%','padding': '0%','background': 'white','height': '97%' }">

              <div class="col-xs-12" style="padding: 0; height: 5%;background-color: rgb(214,225,201);">
                <span *ngIf="currenttab('tab4') || currenttab('tab5')"
                      class="card-category headerTextCss" style="margin-right: 0;font-size: 0.95em; padding:0"> My Open Tickets</span>
                <span *ngIf="currenttab('tab9')" class="card-category headerTextCss" style="margin-right: 0;
                      color: black; font-size: 0.90em; padding: 0 0 0 1.5%"> </span>
              </div>
              <div *ngIf="currenttab('tab4') || currenttab('tab5')" class="col-xs-12" style="padding:0;height:99% ">
                <app-tickets-dashboard  [PageFrom]="'dashboard'" [ticketType]="'Open Tickets'" [assignedTo]="'null'" [type]="'ZendeskTicketsOpen'"></app-tickets-dashboard>
              </div>
              <div *ngIf="currenttab('tab9')" class="col-xs-12" style="padding:0;height:99% ">
                
              </div>
            </div>


            <div *ngIf="currenttab('tab2')" class="col-md-12 col-sm-12 col-xs-12" style="padding-left:0;padding-right:0;height:48%;">
              <div class="box calendarcss" [ngStyle]="{'margin-top':'15px','height':'95%'}">
                <div class="col-xs-12" style="padding: 0; height: 12%; background-color: rgb(214,225,201);">
                  <span class="card-category headerTextCss" style="margin-right: 0;background-color: rgb(214,225,201); font-size: 0.95em; padding: 0 0 0 1.5%">
                    Sales Opportunities - Won
                    <input type="month" style="font-size: 14px !important; border: none;background-color: rgb(214,225,201); font-family: none; float: right; margin: 0.5% 1% 0 0; text-align: right; height: 15px" (change)="SalesOpportunities($event.target.value,'Won Opportunities')" value="{{CurrentDate}}" class="MonthPickerDark" />
                  </span>
                </div>
                <div class="col-xs-12" style="padding:0;height:87% ">

                  <app-revenue-dashboard [opportunitytype]="'Won Opportunities'" [type]="'Won'" [startDateVal]="RevenueStartDate" [endDateVal]="RevenueEndDate" (outPropsEvent)="SalesWonopp($event)"></app-revenue-dashboard>
                </div>
              </div>
            </div>

            <div *ngIf="currenttab('tab3')" class="col-md-12 col-sm-12 col-xs-12" style="padding-left:0;padding-right:0;height:52%">
              <div class="box" [ngStyle]="{'margin-top':'16px' ,'overflow':'auto','height':'90%'}">
                <div class="box-header" style="border-bottom: 1px solid #ddd; padding: 0.5% 0 0 0; cursor: pointer; height: 12%; width: 100%;background-color: rgb(214,225,201);">

                  <div class="col-xs-12" style="padding: 0;height:100%">
                    <div class="col-xs-6 haderCss" style="margin-left: 0;padding-top: 0;padding-bottom: 0;padding-left:0;height:100%">
                      <span style="font-size: 0.95em;">Year to Date Activity</span>
                    </div>
                    <div class="col-xs-2 haderCss" style="margin-left: 0;padding-top: 0;padding-bottom: 0;height:92%;float:right;margin:0">
                      <select id="yearselected" class="form-control" (change)="YeartoDateActivity($event.target.value)" required style="height: 100%; padding: 0; font-size: 0.90em; background-color: rgb(214,225,201); border: none">
                        <option *ngFor="let y of years" [selected]="yy === y ">{{y}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="box-body" style="padding-bottom:0;overflow:auto;" [style.height.px]="divHeight3">
                  <div class="col-md-12 headerCSS" style="margin-top:0 !important">
                    <canvas baseChart
                            height="120"
                            width="620"
                            [datasets]="NBDSpecialistbarChartData"
                            [labels]="NBDSpecialistSalesbarChartLabels"
                            [options]="NBDSpecialistbarChartOptions"
                            [chartType]="barChartType"></canvas>
                  </div>

                </div>
                <table class="table" height="50" style="margin-bottom:0;font-size:14px;">
                  <tbody>
                    <tr *ngFor="let item of AllyeartodataActivity">
                      <td style="width:10%;font-size:10px;">{{item.activityname}}</td>
                      <td style="width:7%">{{item.JanPercenatge}}</td>
                      <td style="width:7%">{{item.FebPercenatge}}</td>
                      <td style="width:7%">{{item.MarPercenatge}}</td>
                      <td style="width:7%">{{item.AprilPercenatge}}</td>
                      <td style="width:7%">{{item.MayPercenatge}}</td>
                      <td style="width:7%">{{item.JunPercenatge}}</td>
                      <td style="width:7%">{{item.JulPercenatge}}</td>
                      <td style="width:7%">{{item.AugPercenatge}}</td>
                      <td style="width:7%">{{item.SepPercenatge}}</td>
                      <td style="width:7%">{{item.OctPercenatge}}</td>
                      <td style="width:7%">{{item.NovPercenatge}}</td>
                      <td>{{item.DecPercenatge}}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>

          </div>

          <div [style.padding]="(EXpandCalender)? '0 0 0 0':'0 0% 0 0'" id="DashboardCalenderDiv" [ngClass]="(EXpandCalender)? 'col-md-12 col-sm-12 col-xs-12' :'col-md-6 col-sm-12 col-xs-12'" style="padding:0; height:98%;">
            <div [ngClass]="(expand)? 'expandbox' :'box'" *ngIf=" !expandnbddashboardsecrow && (currenttab('tab2') ||currenttab('tab3'))" [ngStyle]="{'margin-top':'1.7% !important','border':expand? '4px solid #00B0F0' : '0px solid'}" id="CalendarTomorrowActDiv">
              <div [ngClass]="(expand)? 'expandbox' :'box'" class="box-header" [ngStyle]="{'height.%': expand ? '5.5' :'12' }" style="border-bottom: 1px solid #ddd; padding: 0.5% 0 0 0; margin: 0; width: 100%;background-color: rgb(214,225,201);">
                <div class="col-xs-12" style="padding: 0;">
                  <div class="col-xs-12 haderCss" style="margin-left: 0;padding-top: 0;padding-bottom: 0;padding-left:0">
                    <span class="col-xs-11" style="font-size: 0.95em;padding:0" (click)="expandgrid('opportunitiesopen','Expand',true)">Opportunities - Open</span>
                    <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 30px"><img src="../../../assets/img/Black x.png" style="height:22px" (click)="expandclose('opportunitiesopen',false)"></span>
                  </div>

                </div>
              </div>

              <div class="box-body" style="padding:0;overflow:auto;padding-top:0%;height:88%">
                <app-revenue-dashboard [opportunitytype]="'Open Opportunities'" [type]="'Open_ECD'" [startDateVal]="RevenueStartDate" [endDateVal]="RevenueEndDate"></app-revenue-dashboard>
                <!--<app-proposals [Type]="'Open Proposals'" [PageFrom]="'Leads'" [redirectFrom]="'dashboard'"></app-proposals>-->
              </div>

            </div>
            <div *ngIf="currenttab('tab1') && displayCalendar" class="col-md-12" [ngStyle]="{'margin-top.%':(EXpandCalender) ? 1 : 2,'padding': '0%','background': 'white','height': '97%' }">

              <div class="box-header" style="border-bottom:1px solid #ddd;padding: 0.5% 0 0 0;height:6%;margin: 0;width: 100%;background-color: #2A3740;">
                <div class="col-xs-12" style="padding: 0;">
                  <div class="col-xs-12 haderCss" style="margin-left: 0; padding-top: 0; padding-bottom: 0; padding-left: 0.5%; padding-right: 0.5%;">

                    <span style="font-size: 0.90em;">Calendar</span>
                    <span><img src="../../../assets/img/White Expand View.png" *ngIf="!EXpandCalender" style="height:17px;float:right" (click)="EXpandCalender = true"> </span>
                    <span><img src="../../../assets/img/White Collapse View.png" *ngIf="EXpandCalender" style="height:17px;float:right" (click)="EXpandCalender = false"> </span>


                  </div>
                  <div class="col-xs-8">

                  </div>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;height:93% !important;">
                <full-calendar #myCalendar1 [options]="calendarOptions1" id="myCalendar1"></full-calendar>

              </div>
            </div>
            <div class="col-xs-12" [ngStyle]="{'border': expand ? '4px solid #00B0F0' :'0px solid white',
                     'padding':expand ? '0px 0% 0px 0px' :'0px 1% 0px 0px' }" style="margin-top: 2%;background: white; height: 100%;" *ngIf="currenttab('tab1') && !displayCalendar && selectedmyDashDefGridBoxid">
              <div class="col-xs-12" [ngStyle]="{'height.%': expand ? '100' :'49' }" style="padding: 0%" *ngIf="!expandmydashboardsecrow">
                <div [ngClass]="expand ? 'col-xs-12' : 'col-xs-6'" [ngStyle]="{'padding': expand ? '0%' :'0 1% 0 0' }" style="height:100%" *ngIf="selectedmyDashDefGridBoxid==3 && showtickets">
                  <div class="col-xs-12" [ngStyle]="{'height.%': expand ? '5.5' :'11' }" style="padding: 0;background-color: rgb(214,225,201);">
                    <span class="col-xs-11 card-category headerTextunderlineCss" style="margin-right: 0;background-color: rgb(214,225,201); font-size: 0.95em;" (click)="expandgrid('opentickets','Expand',true)">
                      Open Tickets
                    </span>
                    <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 30px"><img src="../../../assets/img/Black x.png" style="height:22px" (click)="expandclose('opentickets',false)"></span>
                  </div>
                  <div class="col-xs-12" style="padding:0;height:88% ">
                    <app-zendeskhome [PageFrom]="'dashboard'" [dashticketType]="'Open Tickets'" [assignedTo]="'null'"></app-zendeskhome>
                  </div>
                </div>
                <div [ngClass]="expand ? 'col-xs-12' : 'col-xs-6'" [ngStyle]="{'padding': expand ? '0%' :'0 0% 0 0' }" style="height: 100%" *ngIf="showcustomerOnhold">

                  <div class="col-xs-12" [ngStyle]="{'height.%': expand ? '5.5' :'11' }" style="padding: 0; background-color: rgb(214,225,201); ">
                    <span class="col-xs-11 card-category headerTextunderlineCss" style="margin-right: 0;background-color: rgb(214,225,201); font-size: 0.95em;" (click)="expandgrid('CustomerOnHold','Expand',true)">
                      Customer On-Hold
                    </span>
                    <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 30px"><img src="../../../assets/img/Black x.png" style="height:22px" (click)="expandclose('CustomerOnHold',false)"></span>
                  </div>
                  <div class="col-xs-12" style="padding:0;height:88% ">
                    <app-tickets-dashboard [PageFrom]="'dashboard'" [type]="'CustomerOnHold'"></app-tickets-dashboard>
                  </div>
                </div>
                <div class="col-xs-6" style="padding:0%;height:100%" *ngIf="selectedmyDashDefGridBoxid==1 ||selectedmyDashDefGridBoxid==2 ||selectedmyDashDefGridBoxid==4 ||selectedmyDashDefGridBoxid==5">

                  <div class="col-xs-12" style="padding:0;height:11%;background-color:#2A3740;">
                    <span *ngIf="selectedmyDashDefGridBoxid==1" class="card-category headerTextCss" style="margin-right:0;background-color:#2A3740;font-size: 0.95em;padding:0%">
                      Open Tickets - Assigned to Corporate Group
                    </span>
                    <span *ngIf="selectedmyDashDefGridBoxid==2" class="card-category headerTextCss" style="margin-right:0;background-color:#2A3740;font-size: 0.95em;padding:0%">
                      Open Tickets - Assigned to NBD Group
                    </span>
                    <span *ngIf="selectedmyDashDefGridBoxid==4" class="card-category headerTextCss" style="margin-right:0;background-color:#2A3740;font-size: 0.95em;padding:0%">
                      Open Tickets - Assigned to Trainers Group
                    </span>
                    <span *ngIf="selectedmyDashDefGridBoxid==5" class="card-category headerTextCss" style="margin-right:0;background-color:#2A3740;font-size: 0.95em;padding:0%">
                      Open Tickets - Assigned to Support Group
                    </span>

                  </div>
                  <div class="col-xs-12" style="padding:0;height:88% ">
                    <app-tickets-dashboard [TaskGroupType]="assignedToval" [type]="'Tickets'"></app-tickets-dashboard>
                  </div>
                </div>
              </div>
              <div class="col-xs-12" [ngStyle]="{'height.%': expand ? '100' :'49' }" style="padding: 0%;" *ngIf="!expandmydashboardthirdrow">
                <div [ngClass]="expand ? 'col-xs-12' : 'col-xs-6'" style="height:100%;padding:0%" *ngIf="(selectedmyDashDefGridBoxid ==1 || selectedmyDashDefGridBoxid ==3) && showPastdue">

                  <div class="col-xs-12" [ngStyle]="{'height.%': expand ? '5.5' :'11' }" style="padding: 0; background-color: rgb(214,225,201);">
                    <span *ngIf="selectedmyDashDefGridBoxid ==1" class="card-category headerTextCss" style="margin-right: 0;background-color: rgb(214,225,201); font-size: 0.95em; padding: 0 0 0 1.5% ">
                      Customer On-Hold
                    </span>
                    <span *ngIf="selectedmyDashDefGridBoxid ==3" class="col-xs-11 card-category headerTextunderlineCss" style="margin-right: 0; background-color: rgb(214,225,201); font-size: 0.95em; " (click)="expandgrid('taskpastdue','Expand',true)">
                      Tasks | Past Due
                    </span>
                    <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 30px"><img src="../../../assets/img/Black x.png" style="height:22px" (click)="expandclose('taskpastdue',false)"></span>

                  </div>
                  <div class="col-xs-12" style="padding:0;height:88% " *ngIf="selectedmyDashDefGridBoxid ==1">
                    <app-tickets-dashboard [PageFrom]="'dashboard'" [type]="'CustomerOnHold'"></app-tickets-dashboard>
                  </div>
                  <div class="col-xs-12" style="padding:0;height:88% " *ngIf="selectedmyDashDefGridBoxid ==3">
                    <ag-grid-angular style="width: 100%;overflow:auto;height:100%" class="ag-theme-balham"
                                     [gridOptions]="PastDueActivitygridOptions"
                                     [columnDefs]="pastDueActivitycolumnDefs"
                                     [defaultColDef]="applicationComponent.defaultGridColDef"
                                     [rowData]="pastDueactivitytaskhistory"
                                     [sideBar]="false"
                                     (columnVisible)="pastDueActivitycolumnVisible($event)"
                                     (dragStopped)="pastDueActivitydragStopped($event)"
                                     (columnResized)="pastDueActivitycolumnResized($event)"
                                     (rowDoubleClicked)="PastDueActivityrowDoubleClickAgGrid($event)">
                    </ag-grid-angular>
                  </div>
                </div>

                <div class="col-xs-6" style="padding:0 1% 0 0 ;height:100%" *ngIf="selectedmyDashDefGridBoxid == 2">

                  <div class="col-xs-12" style="padding:0;height:11%;background-color:#2A3740;">
                    <span class="card-category headerTextCss" style="margin-right: 0; background-color: #2A3740; color: white; font-size: 0.95em;">
                      Accounts Added this Month

                    </span>
                  </div>
                  <div class="col-xs-12" style="padding:0;height:88% ">
                    <app-account [PageFrom]="'dashboard'" [type]="'Customer'"></app-account>
                  </div>

                </div>
                <div class="col-xs-6" style="padding:0 1% 0 0 ;height:100%" *ngIf="selectedmyDashDefGridBoxid == 4 || selectedmyDashDefGridBoxid == 5">
                  <div class="col-xs-12" style="padding: 0; height: 11%;background-color: rgb(214,225,201);">
                    <span class="card-category headerTextCss" style="margin-right: 0; background-color: rgb(214,225,201); font-size: 0.95em;">
                      Open Projects
                    </span>
                  </div>
                  <div class="col-xs-12" style="padding:0;height:88% ">
                    <app-projects-dashboard [ProjectsType]="'Open Projects'" [Type]="'Open'"></app-projects-dashboard>
                  </div>

                </div>
                <div [ngClass]="expand ? 'col-xs-12' : 'col-xs-6'" [ngStyle]="{'padding': expand ? '0 0 0 0%' :'0 0 0 1%' }" style="height:100%" *ngIf="ShowcustomerActivity">

                  <div class="col-xs-12" [ngStyle]="{'height.%': expand ? '5.5' :'11' }" style="padding: 0;background-color: rgb(214,225,201); ">
                    <span class="col-xs-11 card-category headerTextunderlineCss" style="margin-right: 0; background-color: rgb(214,225,201); font-size: 0.95em;" (click)="expandgrid('custactivity','Expand',true)">
                      <label class="col-xs-6" style="padding:0">Customers / 60+ Days</label>
                      <span class="col-xs-3" style="font-size: 15px;float:left;cursor:pointer;" *ngIf="selectedmyDashDefGridBoxid ==3">                      
                        <select [(ngModel)]="SelectedStatus" (change)="CustomerStatusChange($event.target.value)"
                                style="font-size: 13px !important;background-color: rgb(214,225,201); border: none">
                          <option *ngFor="let cus of CustStatusDropdowns" value={{cus.id}}>
                            {{cus.itemName}}
                          </option>
                        </select>
                      </span>
                      <span class="col-xs-3" style="font-size: 15px;float:right;cursor:pointer;" *ngIf="selectedmyDashDefGridBoxid ==3">
                        <select [(ngModel)]="SelectedAct" (change)="ActivityChange($event.target.value);"
                                style="font-size: 13px !important;background-color: rgb(214,225,201); border: none ">
                          <option *ngFor="let cus of CustActivityDropdowns" value={{cus.id}}>
                            {{cus.itemName}}
                          </option>
                        </select>
                      </span>
                    </span>

                    <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 30px"><img src="../../../assets/img/Black x.png" style="height:22px" (click)="expandclose('custactivity',false)"></span>
                  </div>
                  <div class="col-xs-12" style="padding:0;height:88% ">
                    <app-tickets-dashboard [PageFrom]="'dashboard'" [TaskGroupType]="selectedActType" [type]="'Customer'"></app-tickets-dashboard>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12" [ngStyle]="{'margin-top': '2%','padding': '0 1% 0 0','background': 'white','height': '100%','overflow': 'auto' }" *ngIf="currenttab('tab1') && !displayCalendar && this.selectedmyDashDefGridBox ==null">
              <div class="col-xs-12" style="height:49%">
                <div class="col-xs-6" style="padding:0 1% 0 0 ;height:100%">

                  <div class="col-xs-12" style="padding:0;height:11%;background-color:#2A3740;">
                    <span class="card-category headerTextCss" style="margin-right:0;background-color:#2A3740;font-size: 0.90em;padding:0 0 0 1.5%">


                    </span>
                  </div>
                  <div class="col-xs-12" style="padding:0;height:88% ">

                    <ag-grid-angular style="width: 100%;overflow:auto;height:100%" class="ag-theme-balham"
                                     [rowData]="emptyGridData">
                    </ag-grid-angular>
                  </div>

                </div>
                <div class="col-xs-6" style="padding:0 0 0 1%;height:100%">

                  <div class="col-xs-12" style="padding:0;height:11%;background-color:#2A3740;">
                    <span class="card-category headerTextCss" style="margin-right:0;background-color:#2A3740;font-size: 0.90em;padding:0 0 0 1.5%">


                    </span>
                  </div>
                  <ag-grid-angular style="width: 100%;overflow:auto;height:100%" class="ag-theme-balham"
                                   [rowData]="emptyGridData">
                  </ag-grid-angular>
                </div>
              </div>
              <div class="col-xs-12" style="height:49%;margin-top:1%">
                <div class="col-xs-6" style="padding:0 1% 0 0 ;height:100%">

                  <div class="col-xs-12" style="padding:0;height:11%;background-color:#2A3740;">
                    <span class="card-category headerTextCss" style="margin-right:0;background-color:#2A3740;font-size: 0.90em;padding:0 0 0 1.5%">


                    </span>
                  </div>
                  <div class="col-xs-12" style="padding:0;height:88% ">
                    <ag-grid-angular style="width: 100%;overflow:auto;height:100%" class="ag-theme-balham"
                                     [rowData]="emptyGridData">
                    </ag-grid-angular>
                  </div>

                </div>
                <div class="col-xs-6" style="padding:0 0 0 1%;height:100%">

                  <div class="col-xs-12" style="padding:0;height:11%;background-color:#2A3740;">
                    <span class="card-category headerTextCss" style="margin-right:0;background-color:#2A3740;font-size: 0.90em;padding:0 0 0 1.5%">


                    </span>
                  </div>
                  <div class="col-xs-12" style="padding:0;height:88% ">
                    <ag-grid-angular style="width: 100%;overflow:auto;height:100%" class="ag-theme-balham"
                                     [rowData]="emptyGridData">
                    </ag-grid-angular>
                  </div>
                </div>
              </div>
            </div>

            <div [ngClass]="(expand)? 'expandbox' :'box'" *ngIf="!expandsupportdashboardsecrow && (currenttab('tab4') ||currenttab('tab5')||currenttab('tab9'))" [ngStyle]="{'border':expand ? '4px solid #00B0F0' : '0px solid white'}">
              <div class="box-header" style="border-bottom: 1px solid #ddd; padding: 0.5% 0 0 0; cursor: pointer; width: 100%; background-color: rgb(214,225,201);">
                <div class="col-xs-12" [ngStyle]="{'height.%': expand ? '5.5' :'11' }" style="padding: 0%;background-color: rgb(214,225,201);">
                  <span class="col-xs-11" *ngIf="currenttab('tab4')" style="font-size: 0.95em;padding:0" (click)="expandgrid('assignedtotrainergroup','Expand',true)">
                    Open Tickets - (Assigned To Trainers Group)
                  </span>
                  <span class="col-xs-11" *ngIf="currenttab('tab5')" style="font-size: 0.95em;padding:0" (click)="expandgrid('assignedtosupportgroup','Expand',true)">
                    Open Tickets - (Assigned To Support Group)
                  </span>
                  <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 30px"><img src="../../../assets/img/Black x.png"
                    style="height:22px" (click)="expandclose('assignedtosupportgroup',false)"></span>
                  <span *ngIf="currenttab('tab9')" style="font-size: 0.90em; color: rgb(214,225,201)">c</span>
                </div>
              </div>

              <div *ngIf="currenttab('tab4')" class="box-body" style="padding:0;padding-top:0%;height:88%">
                <app-tickets-dashboard [ticketType]="'Open Tickets'" [assignedTo]="'Trainer'" [type]="'ZendeskTicketsOpen'"></app-tickets-dashboard>

              </div>
              <div *ngIf="currenttab('tab5')" class="box-body" style="padding:0;padding-top:0%;height:88%">
                <app-zendeskhome [PageFrom]="'dashboard'" [dashticketType]="'Open Tickets'" [assignedTo]="'Support'"></app-zendeskhome>
              </div>
              <div *ngIf="currenttab('tab9')" class="box-body" style="padding:0;padding-top:0%;height:88%">
               
              </div>
            </div>
            <div *ngIf="!expandsupportdashboardthirdrow && (currenttab('tab1') || currenttab('tab2') ||currenttab('tab3')||currenttab('tab4')||currenttab('tab5')||currenttab('tab9'))" class="col-md-12 col-sm-12 col-xs-12" [ngStyle]="{'height.%':expand ? '100' : '48'}" style="padding:0;" id="MsgCalendarDiv">

              <div *ngIf="(currenttab('tab2') ||currenttab('tab3')) && showopenleads" [ngClass]="(expand)? 'col-md-12 col-sm-12 col-xs-12' :'col-md-6 col-sm-12 col-xs-12'" style="padding: 2% 1% 0 0; height: 100%">
                <div class="box calendarcss" [ngStyle]="{'border':expand? '4px solid #00B0F0' : '0px solid'}">
                  <div class="box-header" [ngStyle]="{'height.%':expand ? '6' : '12' }" style="border-bottom: 1px solid #ddd; padding: 0.5% 0 0 0; cursor: pointer; width: 100%;background-color: rgb(214,225,201);">
                    <div class="col-xs-12 " style="padding: 0.5%;">
                      <span class="col-xs-11" style="font-size: 0.95em;padding:0%;" (click)="expandgrid('openleads','Expand',true)">Open Leads </span>
                      <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 30px"><img src="../../../assets/img/Black x.png" style="height:22px" (click)="expandclose('openleads',false)"></span>
                    </div>
                  </div>
                  <div class="box-body" style="overflow:auto; padding: 0px;height:88%">
                    <app-leadsdashboard [PageFrom]="'dashboard'" [leadtype]="'Open Leads'"></app-leadsdashboard>
                  </div>
                </div>
              </div>
              <div *ngIf="(currenttab('tab4') ||currenttab('tab5')||currenttab('tab9')) && showclosedtickets"
                   [ngClass]="(expand)? 'col-md-12 col-sm-12 col-xs-12' :'col-md-6 col-sm-12 col-xs-12'"
                   style="padding:2% 1% 0 0; height:100%" id="internalmessagediv">
                <div class="box calendarcss " style="margin-top:0%" [ngStyle]="{'border':expand? '4px solid #00B0F0' : '0px solid'}">
                  <div class="box-header" [ngStyle]="{'height.%':expand ? '5.5' : '12' }"
                       style="border-bottom: 1px solid #ddd; padding: 0.5% 0 0 0; cursor: pointer; height: 12%; width: 100%; background-color: rgb(214,225,201);">
                    <div class="col-xs-12" style="padding: 0;" *ngIf="currenttab('tab4') ||currenttab('tab5')">
                      <span class="col-xs-8" style="font-size: 0.95em;padding: 1% 0 0 1.5%;
                       " (click)="expandgrid('closedtickets','Expand',true)">
                        My Closed Tickets
                      </span>
                      <span class="col-xs-3" style=" padding:0%;font-size:17px; font-weight: normal;color:#A0AAB3;cursor:pointer;margin-top:0.5%">
                        <input type="month" style="font-size: 12.2px; font-family: none; border: none !important; margin: 0 3% 0 0; width: 100%; text-align: right; float: right;background-color: rgb(214,225,201); height: 15px " (change)="TicketsChange($event.target.value,'Closed')" value="{{CurrentDate}}" class="MonthPickerDark" />
                      </span>
                      <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 0px"><img src="../../../assets/img/Black x.png" style="height:22px;float:right;" (click)="expandclose('closedtickets',false)"></span>
                    </div>
                    <div class="col-xs-12" style="padding: 0;" *ngIf="currenttab('tab9')">
                      <span style="font-size: 0.90em;padding: 0 0 0 4%;"></span>

                    </div>
                  </div>
                  <div class="box-body" style=" padding: 0px;height:88%">

                    <app-tickets-dashboard *ngIf="currenttab('tab4') ||currenttab('tab5')" [ticketType]="'Closed Tickets'" [assignedTo]="'null'" [startDateVal]="TicketStartDate" [endDateVal]="TicketEndDate" [type]="'ZendeskTicketsClosed'"></app-tickets-dashboard>
                  </div>
                </div>
              </div>

              <div *ngIf="(currenttab('tab2') || currenttab('tab3') ||currenttab('tab4') ||currenttab('tab5')||currenttab('tab9')) && msgSalesDiv" [ngClass]="(expand)? 'col-md-12 col-sm-12 col-xs-12' :'col-md-6 col-sm-6 col-xs-6'"
                   style="padding:2% 0 0 0;height:100%;" id="msgSalesDiv">


                <div *ngIf="currenttab('tab2') && showsalesranking" class="col-md-12 col-sm-12 col-xs-12" style="padding:0;height:98%">
                  <div class="box" [ngStyle]="{'height':'98%','margin-top':'2%','border':expand? '4px solid #00B0F0' : '0px solid'}">
                    <div class="box-header" [ngStyle]="{'height.%':expand ? '6' : '12' }" style="border-bottom: 1px solid #ddd; padding: 0.5% 0 0 0; cursor: pointer; width: 100%; background-color: rgb(214,225,201);">
                      <div class="col-xs-12" style="padding: 0;">
                        <div class="col-xs-12 haderCss" style="margin-left:0;padding-left:0;padding-top: 0;padding-bottom: 0;padding-right:0;">
                          <span [ngClass]="expand ? 'col-xs-11' :'col-xs-12'" style="font-size: 0.95em;padding:0" (click)="expandgrid('salesranking','Expand',true)">
                            Sales Ranking
                            <span>
                              <input type="month" style="font-size: 14px !important; border: none;background-color: rgb(214,225,201); font-family: none; margin: 0 2% 0 0; float: right; text-align: right; height: 15px" (change)="moonthranking($event.target.value)" value="{{CurrentDate}}" class="MonthPickerDark" />
                            </span>
                          </span>
                          <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 30px"><img src="../../../assets/img/Black x.png" style="height:22px" (click)="expandclose('salesranking',false)"></span>

                          <span class="pull-right" style="text-align:right;font-size:17px; font-weight: normal;color:#A0AAB3;cursor:pointer;"><b id="expandSale" (click)="expandcalendarpast('Expand')"><!--<img src="../../../assets/img/Expand View.png" style="height:17px" />--></b></span>
                        </div>
                        <div class="col-xs-8">
                        </div>
                      </div>
                    </div>

                    <div class="box-body" style="padding:0;height:90%">
                      <ag-grid-angular class="ag-theme-balham" id="SalesRanking" style="height:99%;width:100%"
                                       [gridOptions]="SalesRankinggridOptions"
                                       [columnDefs]="columnDefs"
                                       [defaultColDef]="applicationComponent.defaultGridColDef"
                                       [rowData]="Allsalesranking"
                                       [sideBar]="false"
                                       (columnVisible)="SalesRankingcolumnVisible($event)"
                                       (dragStopped)="SalesRankingdragStopped($event)"
                                       [suppressRowClickSelection]="true"
                                       (columnResized)="SalesRankingcolumnResized($event)"
                                       (gridReady)="onSalesRankingAgGridReady($event)">
                      </ag-grid-angular>

                    </div>
                  </div>

                </div>

                <div *ngIf="currenttab('tab3') && showactivitytopusers" class="col-md-12 col-sm-12 col-xs-12" style="padding:0;height:99%">
                  <div class="box" [ngStyle]="{'height':'96%','margin-top':'2%','border':expand? '4px solid #00B0F0' : '0px solid' }">
                    <div class="box-header" style="border-bottom: 1px solid #ddd; padding: 0.5% 0 0 0; cursor: pointer; height: 12%; width: 100%; background-color: rgb(214,225,201);">
                      <div class="col-xs-12" style="padding:0;">
                        <div class="col-xs-12 " style="padding:0">
                          <span [ngClass]=" expand ? 'col-xs-11' :'col-xs-12'" style="font-size: 0.95em;padding:0" (click)="expandgrid('useractivitytopuser','Expand',true)">
                            User Activity - Top Users
                            <span class="pull-right" style="text-align:right;font-size:17px; font-weight: normal;cursor:pointer;margin-top:0.5%">
                              <input type="month" style="font-size: 12.2px; font-family: none; border: none !important; margin: 0 3% 0 1%; width: 100%; text-align: right; float: right;background-color: rgb(214,225,201); height: 15px;" (change)="UserActivityChange($event.target.value)" value="{{CurrentDate}}" class="MonthPickerDark" />
                            </span>
                          </span>
                          <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 30px"><img src="../../../assets/img/Black x.png" style="height:22px" (click)="expandclose('useractivitytopuser',false)"></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12" style="padding:0;height:91%">
                      <ag-grid-angular class="ag-theme-balham" id="SalesRanking" style="height:99%"
                                       [gridOptions]="useractivitygridOptions"
                                       [columnDefs]="useractivitycolumnDefs"
                                       [defaultColDef]="applicationComponent.defaultGridColDef"
                                       [rowData]="AllActivityData"
                                       [sideBar]="false"
                                       (columnVisible)="AllActivitycolumnVisible($event)"
                                       (dragStopped)="AllActivitydragStopped($event)"
                                       [suppressRowClickSelection]="true"
                                       (columnResized)="AllActivitycolumnResized($event)"
                                       (gridReady)="onUserActivityAgGridReady($event)">
                      </ag-grid-angular>
                    </div>


                  </div>

                </div>

                <div *ngIf="(currenttab('tab4') ||currenttab('tab5')||currenttab('tab9')) && showclosedticketsrating " class="col-md-12 col-sm-12 col-xs-12" style="padding:0;height:94%;margin-top:0%">
                  <div class="box calendarcss " style="margin-top:0%" [ngStyle]="{'border':expand? '4px solid #00B0F0' : '0px solid'}">
                    <div class="box-header" [ngStyle]="{'height.%':expand ? '5.5' : '12' }" style="border-bottom: 1px solid #ddd; padding: 0.5% 0 0 0; cursor: pointer; height: 12%; width: 100%; background-color: rgb(214,225,201);">
                      <div class="col-xs-12" style="padding: 0;" *ngIf="currenttab('tab4') ||currenttab('tab5')">
                        <span  [ngClass]="expand ? 'col-xs-9' :'col-xs-8'" *ngIf="currenttab('tab4') ||currenttab('tab5')" style="font-size: 0.95em;padding: 1% 0 0 1.5%;" (click)="expandgrid('closedticketsrating','Expand',true)">
                          Closed Tickets Ratings
                        </span>
                        <span class="col-xs-3" style=" padding: 0%; font-size: 17px; font-weight: normal; color: black; cursor: pointer; margin-top: 0.5%">
                          <input type="month" style="font-size: 12.2px; font-family: none; border: none !important; margin: 0 3% 0 0%; width: 100%; color: black;background-color: rgb(214,225,201); height: 15px; " (change)="ClosedChange($event.target.value)" value="{{CurrentDate}}" class="MonthPickerDark" />
                        </span>
                        <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 0px"><img src="../../../assets/img/Black x.png" style="height:22px;float:right;" (click)="expandclose('closedticketsrating',false)"></span>
                      </div>
                      <div class="col-xs-12" style="padding: 0;" *ngIf="currenttab('tab9')">
                        <span style="font-size: 0.90em; padding: 0 0 0 4%;"></span>
                      </div>
                    </div>
                    <div *ngIf="currenttab('tab4') ||currenttab('tab5')" class="col-xs-12" style="padding:0;height:96%">
                      <ag-grid-angular class="ag-theme-balham" id="SalesRanking" style="height:99%"
                                       [gridOptions]="useractivitygridOptions"
                                       [columnDefs]="closedRankingcolumnDefs"
                                       [defaultColDef]="applicationComponent.defaultGridColDef"
                                       [rowData]="closedRankingData"
                                       [sideBar]="false"
                                       (columnVisible)="AllActivitycolumnVisible($event)"
                                       (dragStopped)="AllActivitydragStopped($event)"
                                       [suppressRowClickSelection]="true"
                                       (columnResized)="AllActivitycolumnResized($event)"
                                       (gridReady)="onUserActivityAgGridReady($event)">
                      </ag-grid-angular>
                    </div>
                    <div *ngIf="currenttab('tab9')" class="col-xs-12" style="padding:0;height:100%">
                     
                    </div>
                  </div>

                </div>


              </div>



            </div>

          </div>
          <div class="row" style="margin:0">
            <div class="col-md-3 col-sm-12 col-xs-12" style="padding-right:0;padding:0;">
              <div *ngIf="(messageOpen)" class="box" style="margin-top:16px;height: 185px;background-color: #33CC33">
                From : {{message.fromuser}}<br /><br />
                To : {{message.touser}}<br /><br />
                Received : {{message.date}}<br /><br />
                Subject :  {{message.subject}}<br /><br />
                Message : {{message.message}}

              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-panels" *ngIf="(selectedMenuRadio == 'tab7' && RevenueDashboard)" style="height:100%">

    <div class="col-xs-12" style="height:99%;padding:0.7%" [ngStyle]="{'border':(UserIdsCount==1 || UserIdsCount==0) ? '1px solid #ddd' : '3px solid #90d24e' }">
      <div class="col-xs-12" style="padding:0px;height:14%">
        <div class="col-lg-3 col-md-6 col-sm-6" style="padding-left: 0;">
          <div class="card card-stats" style="cursor:pointer;">
            <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
              <br />
              <b>No Data</b>
            </div>
            <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
              <div class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                <div class="col-xs-12" style="padding:0;">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right: 0; color: #2A3740; font-size: 0.95em">Total Opportunities (Sales Won)</h4>
                </div>
                <div class="col-xs-6" style="padding:0;text-align:center">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:0 0 0 0;text-align:left">
                    <b class="countCss" style="margin-left: 0;">
                      {{Revenue.OpportunityWonTotalPrice | currency}}
                    </b>
                  </h4>
                </div>
                <div class="col-xs-5" style="padding:0;text-align:right;float:right">
                  <div class="col-xs-8" style="padding:0;text-align:center">
                    <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{TotalOppWonPriceLabel}}</span>
                  </div>
                  <div class="col-xs-4" style="padding:0;">
                    <input type="month" (change)="ChangeTotalOpp($event.target.value,'WonPrice')" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" class="" />


                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6" style="padding-left: 0;">
          <div class="card card-stats" style="cursor:pointer;">
            <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
              <br />
              <b>No Data</b>
            </div>
            <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
              <div class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                <div class="col-xs-12" style="padding:0;">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right: 0; color: #2A3740; font-size: 0.95em">Total Opportunities (Monthly Won)</h4>
                </div>
                <div class="col-xs-6" style="padding:0;text-align:center">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:0 0 0 0;text-align:left">
                    <b class="countCss" style="margin-left: 0;">
                      {{Revenue.OpportunityWonTotalRR | currency}}
                    </b>
                  </h4>
                </div>
                <div class="col-xs-5" style="padding:0;text-align:right;float:right">
                  <div class="col-xs-8" style="padding:0;text-align:center">
                    <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{TotalOppWonRRLabel}}</span>
                  </div>
                  <div class="col-xs-4" style="padding:0;">
                    <input type="month" (change)="ChangeTotalOpp($event.target.value,'WonRR')" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />


                  </div>


                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6" style="padding-left: 0;">
          <div class="card card-stats" style="cursor:pointer;">
            <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
              <br />
              <b>No Data</b>
            </div>
            <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
              <div class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                <div class="col-xs-12" style="padding:0;">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right: 0; color: #2A3740; font-size: 0.95em">Total Opportunities (Sales Lost)</h4>
                </div>
                <div class="col-xs-6" style="padding:0;text-align:center">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:0 0 0 0;text-align:left">
                    <b class="countCss" style="margin-left: 0;">
                      {{Revenue.OpportunityLostTotalPrice | currency}}
                    </b>
                  </h4>
                </div>
                <div class="col-xs-5" style="padding:0;text-align:right;float:right">
                  <div class="col-xs-8" style="padding:0;text-align:center">
                    <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{TotalLostOppPriceLabel}}</span>
                  </div>
                  <div class="col-xs-4" style="padding:0;">
                    <input type="month" (change)="ChangeTotalOpp($event.target.value,'LostPrice')" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6" style="padding: 0;">
          <div class="card card-stats" style="cursor:pointer;">
            <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
              <br />
              <b>No Data</b>
            </div>
            <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
              <div class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                <div class="col-xs-12" style="padding:0;">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right: 0; color: #2A3740; font-size: 0.95em">Total Opportunities (Open Sales)</h4>
                </div>
                <div class="col-xs-6" style="padding:0;text-align:center">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:0 0 0 0;text-align:left">
                    <b class="countCss" style="margin-left: 0;">
                      {{Revenue.OpportunityOpenTotalPrice | currency}}
                    </b>
                  </h4>
                </div>
                <div class="col-xs-5" style="padding:0;text-align:right;float:right">
                  <div class="col-xs-8" style="padding:0;text-align:center">
                    <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{TotalOpenOppPriceLabel}}</span>
                  </div>
                  <div class="col-xs-4" style="padding:0;">
                    <input type="month" (change)="ChangeTotalOpp($event.target.value,'OpenPrice')" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12" style="padding:0;height:86%">
        <div class="col-md-6" style="height: 100%; display: grid; padding-left: 0; padding-right: 0.5%;">
          <div class="col-md-6" [ngStyle]="{'margin-top': '2%','padding': '0 1% 0 0','background': 'white','width': '100%','overflow': 'auto' }">

            <div class="col-xs-12" style="padding: 0.5%; height: 12%;background-color: rgb(214,225,201);">
              <span class="card-category headerTextCss" style="margin-right: 0;background-color: rgb(214,225,201); font-size: 0.95em;">
                Sales Opportunities - Won
                <input type="month" style="font-size: 14px !important; border: none;background-color: rgb(214,225,201); font-family: none; float: right; margin: 0.5% 1% 0 0; text-align: right; height: 15px" (change)="SalesOpportunities($event.target.value,'Won Opportunities')" value="{{CurrentDate}}" class="MonthPickerDark" />
              </span>
            </div>
            <div class="col-xs-12" style="padding:0;height:87% ">

              <app-revenue-dashboard [opportunitytype]="'Won Opportunities'" [type]="'Won'" [startDateVal]="RevenueStartDate" [endDateVal]="RevenueEndDate"></app-revenue-dashboard>
            </div>
          </div>
          <div class="col-md-6" [ngStyle]="{'margin-top': '1%','padding': '0 1% 0 0','background': 'white','width': '100%','overflow': 'auto' }">

            <div class="col-xs-12" style="padding: 0.5%; height: 12%;background-color: rgb(214,225,201);">
              <span class="card-category headerTextCss" style="margin-right: 0;background-color: rgb(214,225,201); font-size: 0.95em;">
                Sales Opportunities - Lost
                <input type="month" style="font-size: 12px !important; border: none; background-color: rgb(214,225,201); font-family: none; float: right; margin: 0.5% 1% 0 0; text-align: right; height: 15px" (change)="SalesOpportunities($event.target.value,'Lost Opportunities')" value="{{CurrentDate}}" class="MonthPickerDark" />
              </span>
            </div>
            <div class="col-xs-12" style="padding:0;height:87% ">
              <app-revenue-dashboard [opportunitytype]="'Lost Opportunities'" [type]="'Lost'" [startDateVal]="RevenueStartDate" [endDateVal]="RevenueEndDate"></app-revenue-dashboard>
            </div>
          </div>
        </div>
        <div class="col-md-6" style="height: 100%; padding: 0;">
          <div class="col-md-12" [ngStyle]="{'margin-top': '2%','padding': '0 1% 0 0','background': 'white','height': '97%','overflow': 'auto' }">

            <div class="col-xs-12" style="padding: 0.5%; height: 5.5%; background-color: rgb(214,225,201);">
              <span class="card-category headerTextCss" style="margin-right: 0;background-color: rgb(214,225,201); font-size: 0.95em;">
                Sales Opportunities - Open

              </span>
            </div>
            <div class="col-xs-12" style="padding:0;height:92% ">
              <app-revenue-dashboard [opportunitytype]="'Open Opportunities'" [type]="'Open'" [startDateVal]="startDateVal" [endDateVal]="RevenueEndDate"></app-revenue-dashboard>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


  <div class="tab-panels" *ngIf="(selectedMenuRadio == 'tab6' && ProjectsDashboard)" style="height:100%">
    <div class="col-xs-12" style="height:99%;padding:0.7%" [ngStyle]="{'border':(UserIdsCount==1 || UserIdsCount==0) ? '1px solid #ddd' : '3px solid #90d24e' }">
      <div class="col-xs-12" style="padding:0px;height:14%">
        <div class="col-lg-3 col-md-6 col-sm-6" style="padding-left: 0;">
          <div class="card card-stats" style="cursor:pointer;">
            <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
              <br />
              <b>No Data</b>
            </div>
            <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
              <div class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                <div class="col-xs-12" style="padding:0;">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right: 0; color: black; font-size: 0.95em">Open Projects</h4>
                </div>
                <div class="col-xs-6" style="padding:0;text-align:center">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:0 0 0 0;text-align:left">
                    <b class="countCss" style="margin-left: 0;">
                      {{projInfo.OpenProjectsCount}}
                    </b>
                  </h4>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6" style="padding-left: 0;">
          <div class="card card-stats" style="cursor:pointer;">
            <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
              <br />
              <b>No Data</b>
            </div>
            <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
              <div class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                <div class="col-xs-12" style="padding:0;">
                  <h4 class="card-category headerTextunderlineCss" (click)="callCalenderOpen('calernderCall')" style="margin-right: 0; color: rgb(215,213,213); font-size: 0.95em">Pended Projects</h4>
                </div>
                <div class="col-xs-6" style="padding:0;text-align:center">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:0 0 0 0;text-align:left">
                    <b class="countCss" style="margin-left: 0;">
                      {{projInfo.PendedProjectsCount}}
                    </b>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6" style="padding-left: 0;">
          <div class="card card-stats" style="cursor:pointer;">
            <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
              <br />
              <b>No Data</b>
            </div>
            <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
              <div class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                <div class="col-xs-12" style="padding:0;">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right: 0; color: #2A3740; font-size: 0.95em">Completed Projects</h4>
                </div>
                <div class="col-xs-6" style="padding:0;text-align:center">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:0 0 0 0;text-align:left">
                    <b class="countCss" style="margin-left: 0;">
                      {{projInfo.CompletedProjectsCount}}
                    </b>
                  </h4>
                </div>
                <div class="col-xs-5" style="padding:0;text-align:right;float:right">
                  <div class="col-xs-8" style="padding:0;text-align:center">
                    <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{TotalLostOppPriceLabel}}</span>
                  </div>
                  <div class="col-xs-4" style="padding:0;">
                    <input type="month" (change)="ChangeTotalOpp($event.target.value,'LostPrice')" style="font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6" style="padding: 0;">
          <div class="card card-stats" style="cursor:pointer;">
            <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
              <br />
              <b>No Data</b>
            </div>
            <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
              <div class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                <div class="col-xs-12" style="padding:0;">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right: 0; color: #2A3740; font-size: 0.95em">Cancelled Projects</h4>
                </div>
                <div class="col-xs-6" style="padding:0;text-align:center">
                  <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:0 0 0 0;text-align:left">
                    <b class="countCss" style="margin-left: 0;">
                      {{projInfo.CancelledProjectsCount}}
                    </b>
                  </h4>
                </div>
                <div class="col-xs-5" style="padding:0;text-align:right;float:right">
                  <div class="col-xs-8" style="padding:0;text-align:center">
                    <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{TotalOpenOppPriceLabel}}</span>
                  </div>
                  <div class="col-xs-4" style="padding:0;">
                    <input type="month" (change)="ChangeTotalOpp($event.target.value,'OpenPrice')" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12" style="padding:0;height:86%">
        <div class="col-xs-6" style="height: 100%; padding-left: 0; padding-right: 0.5%;">
          <div class="col-xs-12" [ngStyle]="{'margin-top': '2%','padding': '0 1% 0 0','background': 'white','width': '100%','overflow': 'auto','height': '98%' }">

            <div class="col-xs-12" style="padding: 0; height: 5%;background-color: rgb(214,225,201); ">
              <span class="card-category headerTextCss" style="margin-right: 0;background-color: rgb(214,225,201); font-size: 0.95em;">
                Open Projects

              </span>
            </div>
            <div class="col-xs-12" style="padding:0;height:95% ">
              <app-projects-dashboard [ProjectsType]="'Open Projects'" [Type]="'Open'"></app-projects-dashboard>

            </div>
          </div>

        </div>
        <div class="col-xs-6" style="height: 100%; padding: 0;">
          <div *ngIf="!expandprojectdashboardsecrow" class="col-xs-12" [ngStyle]="{'height.%':expand? '100' : '50','border':expand? '4px solid #00B0F0' : '0px solid'}" style="margin-top: 2%;padding:0%">

            <div [ngStyle]="{'height.%':expand? '6' : '10' }" class="col-xs-12" style="padding: 0.5%;background-color: rgb(214,225,201);">
              <span class="col-xs-11" style="margin-right: 0;background-color: rgb(214,225,201); font-size: 0.95em;padding:0" (click)="expandgrid('pendedprojects','Expand',true)">
                Pended Projects
              </span>
              <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 30px"><img src="../../../assets/img/Black x.png" style="height:22px" (click)="expandclose('pendedprojects',false)"></span>
            </div>
            <div class="col-xs-12" style="padding:0;height:88% ">
              <app-projects-dashboard [ProjectsType]="'Pended Projects'" [Type]="'Pended'"></app-projects-dashboard>
            </div>
          </div>

          <div *ngIf="!expandprojectdashboardthirdrow" class="col-xs-12" [ngStyle]="{'height.%':expand? '100' : '47','border':expand? '4px solid #00B0F0' : '0px solid' }" style="margin-top:2%;padding:0%">
            <div [ngClass]="(expand)? 'col-xs-12' :'col-xs-6'" style="padding:0 1% 0 0 ;height:100%" *ngIf="showcompletedprojects">

              <div class="col-xs-12" [ngStyle]="{'height.%':expand? '6' : '11' }" style="padding: 0.5%;background-color: rgb(214,225,201);">
                <span class="col-xs-11" style="margin-right: 0;background-color: rgb(214,225,201); font-size: 0.95em;padding:0" (click)="expandgrid('completedprojects','Expand',true)">
                  Completed Projects
                  <input type="month" style="font-size: 12px !important; border: none;background-color: rgb(214,225,201); font-family: none; float: right; margin: 0.5% 1% 0 0; text-align: right; height: 15px" (change)="CompletedProjects($event.target.value,'Completed Projects')" value="{{CurrentDate}}" class="MonthPickerDark" />
                </span>
                <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 30px"><img src="../../../assets/img/Black x.png" style="height:22px" (click)="expandclose('completedprojects',false)"></span>
              </div>
              <div class="col-xs-12" style="padding:0;height:88% ">
                <app-projects-dashboard [ProjectsType]="'Completed Projects'" [Type]="'Completed'"></app-projects-dashboard>
              </div>

            </div>
            <div [ngClass]="(expand)? 'col-xs-12' :'col-xs-6'" style="padding:0 0 0 0%;height:100%" *ngIf="showcancelledprojects">

              <div class="col-xs-12" [ngStyle]="{'height.%':expand? '6' : '11' }" style="padding: 0.5%;background-color: rgb(214,225,201);">
                <span class="col-xs-11" style="margin-right: 0;background-color: rgb(214,225,201); font-size: 0.95em;padding:0" (click)="expandgrid('cancelledprojects','Expand',true)">
                  Cancelled Projects
                  <input type="month" style="font-size: 14px !important; border: none;background-color: rgb(214,225,201); font-family: none; float: right; margin: 0.5% 1% 0 0; text-align: right; height: 15px" (change)="CompletedProjects($event.target.value,'Cancelled Projects')" value="{{CurrentDate}}" class="MonthPickerDark" />
                </span>
                <span *ngIf="expand" class="col-xs-1" style="padding: 0px 0px 0px 30px">
                  <img src="../../../assets/img/Black x.png" style="height:22px" (click)="expandclose('cancelledprojects',false)">
                </span>
              </div>
              <div class="col-xs-12" style="padding:0;height:88% ">
                <app-projects-dashboard [ProjectsType]="'Cancelled Projects'" [Type]="'Cancelled'"></app-projects-dashboard>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="tab-panels" *ngIf="(selectedMenuRadio == 'tab8')" style="height:100%">
    <div class="col-xs-12" style="height:100%;padding:0.7%" [ngStyle]="{'border':(UserIdsCount==1 || UserIdsCount==0) ? '1px solid #ddd' : '3px solid #90d24e' }">
      <div class="col-xs-12" style="padding:0;height:100%;">
        <div class="col-xs-12" style="padding:0px;height:14%">
          <div class="col-lg-3 col-md-6 col-sm-6" style="padding-left: 0;">
            <div class="card card-stats" style="cursor:pointer;">
              <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
                <br />
                <b>No Data</b>
              </div>
              <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
                <div class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                  <div class="col-xs-12" style="padding:0;">
                    <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right: 0;font-size: 0.95em">Open Campaigns   ( Total Expenses ) </h4>
                  </div>
                  <div class="col-xs-11" style="padding:0;text-align:center">
                    <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:0 0 0 0;text-align:left">
                      <b class="countCss" style="margin-left: 0;">
                        {{this.campaigninfo.OpenCampaignTotalExpenses | currency}}
                      </b>
                    </h4>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6" style="padding-left: 0;">
            <div class="card card-stats" style="cursor:pointer;">
              <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
                <br />
                <b>No Data</b>
              </div>
              <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
                <div class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                  <div class="col-xs-12" style="padding:0;">
                    <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right: 0;font-size: 0.95em">Open Campaigns   ( Profit | Loss ) </h4>
                  </div>
                  <div class="col-xs-11" style="padding:0;text-align:center">
                    <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:0 0 0 0;text-align:left">
                      <b class="countCss" style="margin-left: 0;">
                        {{this.campaigninfo.OpenCampaignProfitLoss| currency}}
                      </b>
                    </h4>
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6" style="padding-left: 0;">
            <div class="card card-stats" style="cursor:pointer;">
              <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
                <br />
                <b>No Data</b>
              </div>
              <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
                <div class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                  <div class="col-xs-12" style="padding:0;">
                    <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right: 0;font-size: 0.95em">Closed Campaigns ( Total Expenses ) </h4>
                  </div>
                  <div class="col-xs-6" style="padding:0;text-align:center">
                    <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:0 0 0 0;text-align:left">
                      <b class="countCss" style="margin-left: 0;">
                        {{this.campaigninfo.ClosedCampaignTotalExpenses| currency}}
                      </b>
                    </h4>
                  </div>
                  <div class="col-xs-5" style="padding:0;text-align:right;float:right">
                    <div class="col-xs-8" style="padding:0;text-align:center">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{TotalLostOppPriceLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="ChangeclosedtotalCampaign($event.target.value)" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6" style="padding: 0;">
            <div class="card card-stats" style="cursor:pointer;">
              <div *ngIf="(UserIds == null)" class="card-header card-header-warning card-header-icon col-xs-12" style="text-align:center;padding-top:3%">
                <br />
                <b>No Data</b>
              </div>
              <div *ngIf="(UserIds != null)" class="card-header card-header-warning card-header-icon col-xs-12" style="padding:0;">
                <div class="col-xs-12" sw tyle="padding:0 0 0 3%;margin-right:0;">
                  <div class="col-xs-12" style="padding:0;">
                    <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin-right: 0;font-size: 0.95em">Closed Campaigns ( Profit | Loss ) </h4>
                  </div>
                  <div class="col-xs-6" style="padding:0;text-align:center">
                    <h4 class="card-category headerTextCss" (click)="callCalenderOpen('calernderCall')" style="margin:0 0 0 0;text-align:left">
                      <b class="countCss" style="margin-left: 0;">
                        {{this.campaigninfo.ClosedCampaignProfitLoss| currency}}
                      </b>
                    </h4>
                  </div>
                  <div class="col-xs-5" style="padding:0;text-align:right;float:right">
                    <div class="col-xs-8" style="padding:0;text-align:center">
                      <span class="card-category headerTextCss" style="margin-right:0;color:#2A3740;font-size: 0.90em;padding:0 0 0 3%">{{TotalLostOppPriceLabel}}</span>
                    </div>
                    <div class="col-xs-4" style="padding:0;">
                      <input type="month" (change)="ChangeprofitlossCampaign($event.target.value)" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" value="{{CurrentDate}}" />
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12" style="margin:0;padding:0;height:86%">
          <div style="height: 100%; padding-left: 0%;" id="DashboardCalenderDiv" [ngClass]="(EXpandCalender)? 'col-md-12 col-sm-12 col-xs-12' :'col-md-6 col-sm-12 col-xs-12' ">
            <div *ngIf="currenttab('tab8')" class="col-md-12" [ngStyle]="{'margin-top.%':(EXpandCalender) ? 1 : 2,'padding': '0%','background': 'white','height': '97%' }">

              <div class="box-header" style="border-bottom: 1px solid #ddd; padding: 0.5% 0 0 0; height: 6%; margin: 0; width: 100%; background-color: rgb(214,225,201) ">
                <div class="col-xs-12" style="padding: 0;">
                  <div class="col-xs-12 haderCss" style="margin-left: 0; padding-top: 0; padding-bottom: 0; padding-left: 0.5%; padding-right: 0.5%;">
                  <span style="font-size: 0.95em;">Open Campaigns</span>
                  </div>
                  <div class="col-xs-8">
                  </div>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;height:93% !important;">

                <app-campaign-dashboard [type]="'Open'"></app-campaign-dashboard>
              </div>
            </div>

          </div>

          <div class="col-md-6 col-sm-12 col-xs-12" style="padding:0; height:99%" id="dashProposalLeadActivityDiv">
            <div *ngIf="currenttab('tab8')" class="col-md-12" [ngStyle]="{'margin-top.%':(EXpandCalender) ? 1 : 2,'padding': '0%','background': 'white','height': '97%' }">

              <div class="box-header" style="border-bottom: 1px solid #ddd; padding: 0.5% 0 0 0; height: 6%; margin: 0; width: 100%; background-color: rgb(214,225,201)">
                <div class="col-xs-12" style="padding: 0;">
                  <div class="col-xs-12 haderCss" style="margin-left: 0; padding-top: 0; padding-bottom: 0; padding-left: 0.5%; padding-right: 0.5%;">
                    <span style="font-size: 0.95em;">Closed Campaigns</span>
                  </div>
                  <div class="col-xs-8">

                  </div>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0;height:93% !important;">
                <!--<app-campaigns [CampaignId]="0" [ShowViewCampaign]="true" [Source]="'dashboard'" [type]="'Closed'"> </app-campaigns>-->
                <app-campaign-dashboard [type]="'Closed'"></app-campaign-dashboard>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>

  <app-dialog [(visible)]="showExpandGrid" style="width:500px !important" [closable]="false">
    <div *ngIf="currenttab('tab1') && calendarpastDiv" class="col-md-12 col-sm-12 col-xs-12" style="padding:0;height:100%" id="calendarpastDiv">
      <div class="col-xs-12" style="padding:0;height:100%">
        <div class="col-xs-12" style="border-bottom:1px solid #ddd;padding: 0;cursor:pointer;height:6%;width: 100%;background-color: #2A3740;">
          <div class="col-xs-12" style="padding: 0;height:100%">

            <div class="col-xs-11" style="padding:1%;height:100%">
              <span style="font-size: 0.95em; color: white; margin-left: 2%;">Calendar (Past Due Activity) </span><span style="color:#A0AAB3">({{(pastDueactivitytaskhistory)?   pastDueactivitytaskhistory.length : 0}})  </span>
            </div>
            <div class="col-xs-1" style="padding:1%;height:100%">
              <span class="pull-right" style="text-align:right;font-size:17px; font-weight: normal;color:#A0AAB3;cursor:pointer;"><b id="expand" (click)="expandcalendarpast('Collapase')"><!--<img src="../../../assets/img/Expand View.png" style="height:17px" />--></b></span>
            </div>
          </div>
        </div>

        <div class="col-xs-12" style="padding:0;padding-top:0;height:93%">

          <!--<ag-grid-angular style="width: 100%;overflow:auto;height:100%" class="ag-theme-balham"
                           [gridOptions]="PastDueActivitygridOptions"
                           [columnDefs]="pastDueActivitycolumnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="pastDueactivitytaskhistory"
                           [sideBar]="false"
                           (columnVisible)="pastDueActivitycolumnVisible($event)"
                           (dragStopped)="pastDueActivitydragStopped($event)"
                           (columnResized)="pastDueActivitycolumnResized($event)"
                           (rowDoubleClicked)="PastDueActivityrowDoubleClickAgGrid($event)">
          </ag-grid-angular>-->

        </div>
      </div>
    </div>
    <div *ngIf="currenttab('tab2') && msgSalesDiv && saleRankingDiv" class="col-md-12 col-sm-12 col-xs-12" style="padding:0;height:100%" id="saleRankingDiv">
      <div class="col-xs-12" style="padding:0;height:100%">
        <div class="col-xs-12" style="border-bottom:1px solid #ddd;padding: 0;cursor:pointer;height:6%;width: 100%;background-color: #2A3740;">
          <div class="col-xs-12" style="padding: 0;height:100%">
            <div class="col-xs-11" style="padding:1%;height:100%">

              <span style="font-size: 0.95em; color: white">Sales Ranking- <input type="month" style=" font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 90%; text-align: center; float: left; color: white; height: 25px;" (change)="moonthranking($event.target.value)" value="{{CurrentDate}}" /></span>
            </div>
            <div class="col-xs-1" style="padding:1%;height:100%">

              <span class="pull-right" style="text-align:right;font-size:17px; font-weight: normal;color:#A0AAB3;cursor:pointer;"><b id="expandSale" (click)="expandcalendarpast('Collapase')"><!--<img src="../../../assets/img/Expand View.png" style="height:17px" />--></b></span>
            </div>
            <div class="col-xs-8">
            </div>
          </div>
        </div>

        <div class="col-xs-12" style="padding:0;height:93%">
          <ag-grid-angular class="ag-theme-balham" id="SalesRanking" style="height:99%;width:100%"
                           [gridOptions]="SalesRankinggridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="Allsalesranking"
                           [sideBar]="false"
                           (columnVisible)="SalesRankingcolumnVisible($event)"
                           (dragStopped)="SalesRankingdragStopped($event)"
                           [suppressRowClickSelection]="true"
                           (columnResized)="SalesRankingcolumnResized($event)"
                           (gridReady)="onSalesRankingAgGridReady($event)">
          </ag-grid-angular>

        </div>
      </div>

    </div>

    <div *ngIf="currenttab('tab3') && msgSalesDiv" class="col-md-12 col-sm-12 col-xs-12" style="padding:0;height:99%">
      <div class="col-xs-12" style="padding:0;height:100%">
        <div class="col-xs-12" style="border-bottom:1px solid #ddd;padding: 0;cursor:pointer;height:6%;width: 100%;background-color: #2A3740;">
          <div class="col-xs-12" style="padding:0;height:100%">
            <div class="col-xs-7 " style="padding:1%;height:100%">
              <span style="font-size: 0.95em; color: white">User Activity - Top Users</span>
            </div>
            <div class="col-xs-5" style="padding:1%;height:100%">

              <span class="pull-right" style="text-align:right;font-size:17px; font-weight: normal;color:#A0AAB3;cursor:pointer;">
                <input type="month" style="font-size: 12.2px; font-family: none; border: none !important; margin-left: 0%; width: 100%; text-align: right; float: right; color: white; background-color: #2A3740; height: 15px;" (change)="UserActivityChange($event.target.value)" value="{{CurrentDate}}" />
              </span>
            </div>
          </div>
        </div>
        <div class="col-xs-12" style="padding:0;height:93%">
          <ag-grid-angular class="ag-theme-balham" id="SalesRanking" style="height:99%"
                           [gridOptions]="useractivitygridOptions"
                           [columnDefs]="useractivitycolumnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="AllActivityData"
                           [sideBar]="false"
                           (columnVisible)="AllActivitycolumnVisible($event)"
                           (dragStopped)="AllActivitydragStopped($event)"
                           [suppressRowClickSelection]="true"
                           (columnResized)="AllActivitycolumnResized($event)"
                           (gridReady)="onUserActivityAgGridReady($event)">
          </ag-grid-angular>
        </div>


      </div>

    </div>
    <div *ngIf="currenttab('tab4') ||currenttab('tab5')||currenttab('tab9') " class="col-md-12 col-sm-12 col-xs-12" style="padding:0;height:100%;">
      <div class="col-xs-12" style="padding:0;height:100%">
        <div class="col-xs-12" style="border-bottom:1px solid #ddd;padding: 0;cursor:pointer;height:6%;width: 100%;background-color: #2A3740;">
          <div class="col-xs-12" style="padding:0;height:100%">
            <div class="col-xs-11" style="padding:1%;height:100%">
              <span *ngIf="currenttab('tab4')||currenttab('tab9')" style="font-size: 0.95em; color: White">Closed Tickets Rankings - Training Group</span>
              <span *ngIf="currenttab('tab5')" style="font-size: 0.95em; color: White">Closed Tickets Rankings - Support Group</span>
            </div>
            <div class="col-xs-1" style="padding:1%;height:100%">
              <span class="pull-right" style="text-align:right;font-size:17px; font-weight: normal;color:#A0AAB3;cursor:pointer;"><b id="expandUserAct" (click)="expandcalendarpast('Collapase')"><!--<img src="../../../assets/img/Expand View.png" style="height:17px" />--></b></span>
            </div>
          </div>
        </div>
        <div class="col-xs-12" style="padding:0;height:93%">
          <ag-grid-angular class="ag-theme-balham" id="SalesRanking" style="height:99%"
                           [gridOptions]="useractivitygridOptions"
                           [columnDefs]="closedRankingcolumnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="closedRankingData"
                           [sideBar]="false"
                           (columnVisible)="AllActivitycolumnVisible($event)"
                           (dragStopped)="AllActivitydragStopped($event)"
                           [suppressRowClickSelection]="true"
                           (columnResized)="AllActivitycolumnResized($event)"
                           (gridReady)="onUserActivityAgGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </app-dialog>



  <app-dialog [(visible)]="SaveUserListConfirmation" *ngIf="SaveUserListConfirmation" id="SaveUserListConfirmation">
    <div class="col-xs-12" style="padding:5%">
      <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
      <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
        <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">
          User List
        </div><br />
              <div class="col-xs-12" style="padding: 0; padding-top: 3%;">Save Changes to User List?</div>
      </div>
      <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
        <div class="col-xs-7" style="padding: 0;">

        </div>
        <div class="col-xs-5" style="padding:0;">
          <div class="col-xs-6" style="padding:0">
            <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                    (click)="SaveConfirm('Yes')">
              OK
            </button>
          </div>
         
          <div class="col-xs-6" style="padding:0" >
            <button class="btn" style="float: right; background-color: white !important; color: black;
margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1 "
                    (click)="SaveConfirm('No')">Cancel</button>
          </div>
        </div>
      </div>
    </div>

  </app-dialog>

  <app-dialog [(visible)]="taskDialog" *ngIf="taskDialog" id="dashtaskDialog" [closable]="false" [CalendarDialog]="true" (visibleChange)="SelectedDate = null" [CloseButtonColor]="'white'">
 
      <app-task [id]="id" [taskid]="taskid" [type]="type" [taskDialog]="true" [BTClick]="BTClick" [SearchDialog]="false" [selectorTag]="true" [selectedDate]="SelectedDate" (sendMsgEvent)="onTaskSaved($event)" (Taskdialoghideshow)="Taskdialoghideshow($event)"></app-task>

  </app-dialog>

  <app-dialog [(visible)]="SearchDialog" id="SearchDialog" [CalendarDialog]="true" style="width:542px" *ngIf="SearchDialog">
    <searchdata (sendMsgEvent)="onSearchstring($event)">
    </searchdata>
  </app-dialog>
  <div id='external-events' style="display:none">
    <div id='external-events-listing'>
      <h4>Draggable Events</h4>
      <div class='fc-event'>My Event 1</div>
      <div class='fc-event'>My Event 2</div>
      <div class='fc-event'>My Event 3</div>
      <div class='fc-event'>My Event 4</div>
      <div class='fc-event'>My Event 5</div>
    </div>
    <p>
      <input type='checkbox' id='drop-remove' checked='checked' />
      <label for='drop-remove'>remove after drop</label>
    </p>
  </div>

</div>
  </div>
</div>
