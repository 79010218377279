import { Component, OnInit, Input } from '@angular/core';
import { LeadsService } from '../../../services/leads.service';
import { CustomerService } from '../../../services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ApplicationComponent } from '../../application.component';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { AccountFields } from '../../../models/AccountFields';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SystemRowConfiguration } from '../../../models/system-row-configuration';
import { GridOptions, RowDragEndEvent } from "ag-grid/main";
import { PickListModel } from '../../../models/PickListModel';
import { AuditLogModel } from '../../../models/auditlog';
import { FormConfiguration } from '../../../models/FormConfiguration';
import { SharingGroups } from '../../../models/Accountsharing';
import { Dropdowntype, Dropdown } from '../../../models/dropdowntype';
import { UserService } from '../../../services/user.service';
declare var $: any;

@Component({
  selector: 'app-account-configuration',
  templateUrl: './account-configuration.component.html',
  styleUrls: ['./account-configuration.component.css']
})
export class AccountConfigurationComponent implements OnInit {
  @Input()
  type: string;
  @Input() SubMenuType: string = null;
  currentuser: any;
  subs = new Subscription();
  AccountFields: AccountFields = new AccountFields();
  ContactFields: AccountFields = new AccountFields();
  ShowAccountFieldForm: boolean = false;
  AccountFieldConfiguration: boolean = false;
  AccountPageConfiguration: boolean = false;
  ContactPageConfiguration: boolean = false;
  DisableFields: boolean = false;
  ContactConfigInfo: boolean = true;
  AddEditContactFields: boolean = false;
  ContactFieldsConfiguration: boolean = false;
  ShowContactFieldForm: boolean = false;
  contactFieldform: FormGroup;
  ShowShareGroupFieldForm: boolean = false;
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration();
  AccountOptionPage: boolean = true;
  SelectedConfigurationType = "";
  AddEditAccountField: boolean = false;
  accountFieldform: FormGroup;
  public ShowFieldItemTable: boolean = false;
  public DeleteMatchPopUp: boolean = false;
  public TableColumns: any;
  public SelectedAccountPage: string = "";
  public DuplicateTableColumns: any;
  public PickListColumns: any;
  public gridOptionsView: GridOptions;
  public gridConfigFeildsView: GridOptions;
  public expanded: boolean = false;
  PreviewAccountForm: boolean = false;
  SelectedField: AccountFields;
  FiledDataList : PickListModel[];
  DuplicateFiledDataList = [];
  PickListIds = [];
  MappedAccounts = [];
  PickListArray: PickListModel = new PickListModel();
  DeletePickListObj: PickListModel;
  ReplaceItem = []; dropdownSetting = {}; documenttypeList = [];
  AccountFieldsSelected = [];
  public columnDefs;
  public ConfigFeildscolumnDefs;
  public sideBar;
  FormConfiguration: FormConfiguration = new FormConfiguration();
  AccountSharingHomePage: boolean = true;
  AccGroupNames: boolean = false;
  SharingGroupsList: SharingGroups[] = [];
  SharingGroup: SharingGroups = new SharingGroups();
  ShareGroupFieldform: FormGroup;
  public ShareGroupsgridOptions: GridOptions;
  dropdownTypes: any;
  private gridApi; Specialitytypelist: Dropdowntype[] = [];
  Collegelist: Dropdowntype[] = [];
  MedicalSchoolList: Dropdowntype[] = [];
  accounttypeList: Dropdowntype[] = [];
  ownershipList: Dropdowntype[] = [];
  pmpurchasedList: Dropdowntype[] = [];
  rcmlist: Dropdowntype[] = [];
  AccountIdlist: Dropdowntype[] = [];
  ContactIdslist: Dropdowntype[] = [];
  ehrpurchasedList: Dropdowntype[] = [];
  dropdown: Dropdown = new Dropdown();
  rowedit: boolean = false;
  specialityids = [];
  dropdownid: number;
  dropItemName: string; dropdownCategory: string; selectedNewVal: string;
  AccountIdArr: Dropdowntype[] = [];
  contactIdArr: Dropdowntype[] = [];
  showAccountContactDialog: boolean = false;
  replaceId: number;
  public show: boolean = true;

  constructor(private leadservice: LeadsService, private customerService: CustomerService, private toastr: ToastrService, public applicationComponent: ApplicationComponent, private dragulaService: DragulaService, private userService: UserService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.subs.add(this.dragulaService.drop("Questions-bag")
      .subscribe(({ name, el, target, source, sibling }) => {
        this.Order();
      })
    );
    this.subs.add(this.dragulaService.drop("AccountFieldData-bag")
      .subscribe(({ name, el, target, source, sibling }) => {
        this.CategoryOrder();
      })
    );
    this.subs.add(this.dragulaService.drop("ContactFieldData-bag")
      .subscribe(({ name, el, target, source, sibling }) => {
        this.CategoryOrder();
      })
    );
  }

  ngOnInit(): void {
    this.accountFieldform = this.getAccountFieldsForm();
    this.LoadDropDownValues(this.type);
    this.dropdownSetting = {
      singleSelection: true,
      text: "Select Document Name",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 2
    };


    if (this.type == '422') {
      if (this.SubMenuType !== null && this.SubMenuType !== undefined && this.SubMenuType !== '') {
        this.ContactsField(this.SubMenuType);

      } else {
        var obj: AuditLogModel = { id: 0, type: 'Contact Configuration', action: 'Contact Config', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Contact Config', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
        this.applicationComponent.InsertAuditHistory(obj);
      }
    }
    else {
      this.AccountField("Add/Edit");
    
    }
  }
  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');

  }

  AccountField(val) {
    if (val == 'Add/Edit') {
      this.AccountOptionPage = false;
      this.AddEditAccountField = true;
      this.AccountFieldConfiguration = false;
      this.AccountPageConfiguration = false;
      this.accountFieldform = this.getAccountFieldsForm();
      this.AccountFields = new AccountFields();
      this.ShowAccountFieldForm = false;
      this.getAccountFields('Accounts');

    } else if (val == 'Configuration') {
      this.AccountOptionPage = false;
      this.AddEditAccountField = false;
      this.AccountFieldConfiguration = true;   
      this.AccountPageConfiguration = false;
      this.getAccountFields('Accounts');
      
    } else if (val == 'AccountMailingPageConfiguration' || val == 'AccountSpecificsPageConfiguration' || val == 'AccountShippingPageConfiguration') {
     
      if (val == "AccountMailingPageConfiguration") {
        this.SelectedConfigurationType = "Account Mailing Page Configuration";
        this.getAccountFields('Mailing Address');
        this.getSavedOder('Mailing Address');
      }
      else if (val == "AccountSpecificsPageConfiguration")
      {
        this.SelectedConfigurationType = "Account Specifics Page Configuration";
        this.getAccountFields('Account Specifics');
        this.getSavedOder('Account Specifics');
      }
      else {
        this.SelectedConfigurationType = "Account Shipping Page Configuration";
        this.getAccountFields('Shipping Address');
        this.getSavedOder('Shipping Address');
      }
      this.AccountOptionPage = false;
      this.AddEditAccountField = false;
      this.AccountFieldConfiguration = false;
      this.AccountPageConfiguration = true;
      
    }


    var obj: AuditLogModel = { id: 0, type: 'Account Configuration', action: 'Account Config - ' + val, AccountOrContactId: 0, AccountOrContact: val, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Account Config - ' + val, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);

  }
  DeleteField(data) {
    if (data.isdeleted == 0) {
      data.isdeleted = 1;
    } else {
      data.isdeleted = 0;
    }

    if (data.TableName == "Accounts") {
      this.AccountFields = data;
      this.SaveAccountField('Accounts');
    }
    else {

      this.ContactFields = data;
      this.SaveAccountField('Contacts');
    }
  }

  ShowFieldForm(val) {
    if (val == 'Accounts') {
      this.ShowAccountFieldForm = true;
      this.AccountFields.TypeOfField = 'Custom';
    }
    else if (val == 'Contacts') {
      this.ShowContactFieldForm = true;
      this.ContactFields.TypeOfField = 'Custom';
    }
    else if (val == 'AccountSharingGroup') {
      this.ShowShareGroupFieldForm = true;

    }
  }

  CloseAccountField(val) {
    if (val == 'Add/Edit') {
      this.AddEditAccountField = false;
      this.AccountFieldConfiguration = false;
      this.AccountPageConfiguration = false;
    } else if (val == 'Configuration') {
      this.AddEditAccountField = false;
      this.AccountFieldConfiguration = false;
      this.AccountPageConfiguration = false;
      this.ShowFieldItemTable = false;
    } else if (val == 'AccountPageConfiguration') {
      this.AddEditAccountField = false;
      this.AccountFieldConfiguration = false;
      this.AccountPageConfiguration = false;
    }

    this.AccountOptionPage = true;
  }
  SaveAccountField(val) {
    if (val == 'Accounts') {
      this.AccountFields.TableName = 'Accounts';
      this.customerService.SaveAccountField(this.AccountFields).then(result => {
        this.AccountFields = new AccountFields();
        this.toastr.success("Account Fields Saved Successfully.", "Account Fields", { timeOut: 600 });
        this.getAccountFields('Accounts');
        this.ShowAccountFieldForm = false;
      });
    } else if ('Contacts') {
      this.ContactFields.TableName = 'Contacts';
      this.customerService.SaveAccountField(this.ContactFields).then(result => {
        this.ContactFields = new AccountFields();
        this.toastr.success("Contact Fields Saved Successfully.", "Contact Fields", { timeOut: 600 });
        this.getAccountFields('Contacts');
        this.ShowContactFieldForm = false;
      });
    }

  }


  getAccountFields(val) {
    this.initializeAgGrid();
    this.SelectedAccountPage = val;
    this.customerService.GetTableFields(val, this.currentuser.id).then(result => {
      this.TableColumns = result;
      if (this.ContactPageConfiguration == true || this.AccountPageConfiguration == true) {
        this.DuplicateTableColumns = this.TableColumns.filter(x => x.isdeleted == 0);
      } else {
        this.DuplicateTableColumns = result;
      }
      this.PickListColumns = this.TableColumns.filter(x => x.DataType == 'Pick List' && x.isdeleted == 0);
    });
  }
  initializeAgGridConfigFeild() {
    this.gridConfigFeildsView = <GridOptions>{
      enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true,
      toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createdConfigFeildsColumnDefs("");
  }
  createdConfigFeildsColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.ConfigFeildscolumnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, rowDrag: true
      },
      { field: 'Sortorder', headerName: 'Sort Order', hide: false },

      { field: 'itemName', headerName: 'Pick List Name', hide: false },
     
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right', cellRenderer(params) {
          var eDiv = document.createElement('div');
          
            eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
         
          eDiv.addEventListener('click', function () {
            selfObj.DeletePickList(params.data,'Accounts');
          });
          return eDiv;
        }
      }
    ];
  }
  initializeAgGrid() {
    this.gridOptionsView = <GridOptions>{  
      enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createColumnDefs("");
  }
  createColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, rowDrag:true
      },
      { field: 'FieldName', headerName: 'Field Name', hide: false },
      { field: 'TableName', headerName: 'Table Name', hide: true },
      { field: 'TypeOfField', headerName: 'Type Of Field', hide: false },
      {
        field: 'TableName', headerName: 'Tab Type', hide: false, cellStyle: function (params) {
          if (params.value == "Mailing Address") {
            return { color: 'red' };
          } else if (params.value == "Account Specifics") {
            return { color: 'blue' };
          } else {
            return { color: 'green' };
          }
        }
      },
      { field: 'DisplayAs', headerName: 'Display As', hide: false },
      { field: 'RequiredField', headerName: 'Required Field', hide: false },
      { field: 'DataType', headerName: 'Data Type', hide: false },
      { field: 'PickListEditable', headerName: 'Pick List Editable', hide: false },
      { field: 'DataFormat', headerName: 'Data Format', hide: false },
      { field: 'MaxLimit', headerName: 'Max Limit', hide: false },
      { field: 'Description', headerName: 'Description', hide: false },
      { field: 'SortOrder', headerName: 'Sort Order', hide: false },
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right', cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.data.isdeleted == 0 && params.data.TypeOfField == 'Custom') {
            eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
          }
          else if (params.data.isdeleted == 1 && params.data.TypeOfField == 'Custom') {
            eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size:14px"></i>';
          }

          eDiv.addEventListener('click', function () {
            selfObj.DeleteField(params.data);
          });
          return eDiv;
        }
      }
    ];
  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {

      if (event.api.getSelectedNodes()[0].data && event.api.getSelectedNodes()[0].data.TableName == 'Accounts') {
        this.AccountFields = event.api.getSelectedNodes()[0].data;

        this.ShowAccountFieldForm = true;
        if (this.AccountFields.TypeOfField == 'Standard') {
          this.DisableFields = true;
        } else {
          this.DisableFields = false;
        }
      } else if (event.api.getSelectedNodes()[0].data && event.api.getSelectedNodes()[0].data.TableName == 'Contacts') {
        this.ContactFields = event.api.getSelectedNodes()[0].data;

        this.ShowContactFieldForm = true;
        if (this.ContactFields.TypeOfField == 'Standard') {
          this.DisableFields = true;
        } else {
          this.DisableFields = false;
        }
      }


    } else {

    }

  }

  FilterByTypeOfField(event) {
    this.DuplicateTableColumns = [];
    if (event.target.value == 2) {
      this.DuplicateTableColumns = this.TableColumns.filter(x => x.TypeOfField == 'Standard');
    } else if (event.target.value == 3) {
      this.DuplicateTableColumns = this.TableColumns.filter(x => x.TypeOfField == 'Custom');
    } else {
      this.DuplicateTableColumns = this.TableColumns;
    }
  }

  SortByType(event) {
    this.DuplicateTableColumns = [];
    let selfObj = this;
    $(setTimeout(function () {
      if (event.target.value == 1) {
        selfObj.DuplicateTableColumns = selfObj.TableColumns.sort((a, b) => a.SortOrder - b.SortOrder);
      } else if (event.target.value == 2) {
        var data = selfObj.TableColumns;
        data = data.sort((a, b) => a.FieldName.localeCompare(b.FieldName));
        selfObj.DuplicateTableColumns = data;
      }
    }, 200));


  }

  showCheckboxes(val) {
    this.PickListColumns = [];
    if (val == 'Accounts') {    
        this.expanded = true;
        this.PickListColumns = this.TableColumns.filter(x => x.DataType == 'Pick List' && x.isdeleted == 0);     
    } else if (val == 'Contacts') {     
        this.expanded = true;
        this.PickListColumns = this.TableColumns.filter(x => x.DataType == 'Pick List' && x.isdeleted == 0);     
    }
  }


  onCheckboxChange(field, event) {
    if (event.target.checked == true) {
      $('.FieldCheckBox').prop('checked', false);
      $('#chkBox' + field.id).prop('checked', true);
      this.SelectedField = field;
    
      this.customerService.GetPickListData(this.SelectedField.FieldName, this.SelectedField.id, this.currentuser.id).then((accounttype) => {
        this.FiledDataList = accounttype;
        this.DuplicateFiledDataList = accounttype;
        if (this.SelectedField.id != 0) {
          this.ShowFieldItemTable = true;
          this.initializeAgGridConfigFeild();
        } else {
          this.ShowFieldItemTable = false;
        }
      });


    }
  }

  AddNewFieldData(id, val, type) {
    if (type == 'Accounts') {
      if (val == 'Add') {
        var toDoEmpty = ({ id: 0, FieldID: 0, isdeleted: 0, Sortorder: 0, itemName: '', Category: '' })
        this.FiledDataList.push(toDoEmpty);
        $(setTimeout(function () {
          $('#PickListSortOrder' + id).attr('contenteditable', "true")
          $('#PickListName' + id).attr('contenteditable', "true")
          $('#PickListRow' + id).attr('style', "background-color:#b7e4ff");
        }, 200));
      }
    } else if (type == 'Contacts') {
      if (val == 'Add') {
        var toDoEmpty = ({ id: 0, FieldID: 0, isdeleted: 0, Sortorder: 0, itemName: '', Category: '' })
        this.FiledDataList.push(toDoEmpty);
        $(setTimeout(function () {
          $('#ConPickListSortOrder' + id).attr('contenteditable', "true")
          $('#ConPickListName' + id).attr('contenteditable', "true")
          $('#ConPickListRow' + id).attr('style', "background-color:#b7e4ff");
        }, 200));
      }
    }

  }
  keyupPickList(val) {
    if ((this.PickListIds.filter(f => f.Index == val)).length == 0) {
      this.PickListIds.push({ Index: val });
    }

  }
  SavePickListData(val) {
    if (this.PickListIds.length > 0) {
      this.PickListArray = new PickListModel();
      if (val == 'Accounts') {
        this.PickListIds.forEach(id => {
          this.PickListArray.id = parseInt($('#PickListID' + id.Index).html());
          this.PickListArray.Sortorder = parseInt($('#PickListSortOrder' + id.Index).html());
          this.PickListArray.itemName = $('#PickListName' + id.Index).html();
          this.PickListArray.isdeleted = 0;
          this.PickListArray.FieldID = this.SelectedField.id;
          this.PickListArray.Category = this.PickListColumns.filter(x => x.id == this.SelectedField.id)[0].FieldName;
        });
      } else if (val == 'Contacts') {
        this.PickListIds.forEach(id => {

          this.PickListArray.id = parseInt($('#ConPickListID' + id.Index).html());
          this.PickListArray.Sortorder = parseInt($('#ConPickListSortOrder' + id.Index).html());
          this.PickListArray.itemName = $('#ConPickListName' + id.Index).html();
          this.PickListArray.isdeleted = 0;
          this.PickListArray.FieldID = this.SelectedField.id;
          this.PickListArray.Category = this.PickListColumns.filter(x => x.id == this.SelectedField.id)[0].FieldName;
        });
      }

      this.SaveData(this.PickListArray);

    }

  }

  SaveData(PickListArray) {
    this.customerService.SavePickList(PickListArray).then(result => {
      this.toastr.success("Saved Successfully.", "Pick List", { timeOut: 600 });
      this.customerService.GetPickListData(this.SelectedField.FieldName, this.SelectedField.id, this.currentuser.id).then((accounttype) => {
        this.FiledDataList = accounttype;
        this.DuplicateFiledDataList = accounttype;
        this.PickListIds = [];
      });
    });
  }

  getPickListFields() {
    this.initializeAgGrid();
    this.customerService.GetTableFields('tblaccounts', this.currentuser.id).then(result => {
      this.TableColumns = result;
    });
  }

  PickListRowClick(row, type) {
    if (type == 'Accounts') {

      if (this.PickListColumns.filter(x => x.id == row.FieldID)[0].PickListEditable == 'Yes') {
        $(setTimeout(function () {
          $('#PickListSortOrder' + row.id).attr('contenteditable', "true")
          $('#PickListName' + row.id).attr('contenteditable', "true")
          $('#PickListRow' + row.id).attr('style', "background-color:#b7e4ff");
        }, 200));
      }
    } else if (type == 'Contacts' && this.PickListColumns.filter(x => x.id == row.FieldID)[0].PickListEditable == 'Yes') {
      $(setTimeout(function () {
        $('#ConPickListSortOrder' + row.id).attr('contenteditable', "true")
        $('#ConPickListName' + row.id).attr('contenteditable', "true")
        $('#ConPickListRow' + row.id).attr('style', "background-color:#b7e4ff");
      }, 200));
    }

  }

  PickListSortByType(value) {
    this.DuplicateFiledDataList = [];
    let selfObj = this;
    $(setTimeout(function () {
      if (value == 'SortOrder') {
        selfObj.DuplicateFiledDataList = selfObj.FiledDataList.sort((a, b) => a.Sortorder - b.Sortorder);
      } else if (value == 'FieldName') {
        var data = selfObj.FiledDataList;
        data = data.sort((a, b) => a.itemName.localeCompare(b.itemName));
        selfObj.DuplicateFiledDataList = data;
      }
    }, 200));
   
  }

  SaveFieldDataOrderBy(value) {
    this.customerService.SaveFieldDataOrderBy(this.SelectedField.id, value).then(mappedAccounts => {


    });
  }

  DeletePickList(val, Type) {
    this.DeletePickListObj = val;
    this.documenttypeList = [];
    if (Type == 'Accounts' && this.PickListColumns.filter(x => x.id == val.FieldID)[0].PickListEditable == 'Yes') {

      this.customerService.GetAccountsByFieldData(val.Category, val.id).then(mappedAccounts => {
        this.MappedAccounts = mappedAccounts;
        if (mappedAccounts && mappedAccounts.length > 0) {

          this.customerService.GetSpecialityList(val.Category, this.currentuser.id).then((accounttype) => {
            accounttype.forEach(acc => {
              this.documenttypeList.push({ "id": acc.id, "itemName": acc.itemName, "category": acc.category });
            });
          });
          this.DeleteMatchPopUp = true;
          this.DeleteMatchPopUpCss();
        }
        else {
          this.DeletePickListObj.isdeleted = 1;
          this.SaveData(this.DeletePickListObj);
        }
      });

    } else if (Type == 'Contacts' && this.PickListColumns.filter(x => x.id == val.FieldID)[0].PickListEditable == 'Yes') {
      this.customerService.GetContactsByFieldData(val.Category, val.id).then(mappedAccounts => {
        this.MappedAccounts = mappedAccounts;
        if (mappedAccounts && mappedAccounts.length > 0) {

          this.customerService.GetSpecialityList(val.Category, this.currentuser.id).then((accounttype) => {
            accounttype.forEach(acc => {
              this.documenttypeList.push({ "id": acc.id, "itemName": acc.itemName, "category": acc.category });
            });
          });
          this.DeleteMatchPopUp = true;
          this.DeleteMatchPopUpCss();
        }
        else {
          this.DeletePickListObj.isdeleted = 1;
          this.SaveData(this.DeletePickListObj);
        }
      });
    }
  }
  DeleteMatchPopUpCss() {
    setTimeout(function () {
      $('#DeleteMatchPopUp .dialog').attr('style', 'width: 45%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 81%;overflow: auto;height: 81%;top: 11%;left: 53%;');
    }, 4);
  }
  Confirmation(val) {
    this.DeleteMatchPopUp = false;
  }
  ReplaceAccountType() {
    var ids = this.MappedAccounts.map(a => a.id);
    this.customerService.UpdateAccountByColumn(this.ReplaceItem[0].category, this.ReplaceItem[0].id, ids.join(',').toString()).then((accounttype) => {
      this.DeletePickListObj.isdeleted = 1;
      this.SaveData(this.DeletePickListObj);
      this.DeleteMatchPopUp = false;
      this.ReplaceItem = [];
    });
  }
  ContactsField(val) {
    if (val == 'Add/Edit') {
      this.ContactConfigInfo = false;
      this.AddEditContactFields = true;
      this.ContactFieldsConfiguration = false;

      this.contactFieldform = this.getContactFieldsForm();
      this.ContactFields = new AccountFields();
      this.getAccountFields('Contacts');
      this.ContactPageConfiguration = false;

      var obj: AuditLogModel = { id: 0, type: 'Contact Configuration', action: 'Contact Config - ' + val, AccountOrContactId: 0, AccountOrContact: val, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Contact Config - ' + val, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
      this.applicationComponent.InsertAuditHistory(obj);

    } else if (val == 'Configuration') {
      this.ContactConfigInfo = false;
      this.AddEditContactFields = false;
      this.ContactFieldsConfiguration = true;
      this.ContactPageConfiguration = false;
      this.getAccountFields('Contacts');

      var obj: AuditLogModel = { id: 0, type: 'Contact Configuration', action: 'Contact Config - ' + val, AccountOrContactId: 0, AccountOrContact: val, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Contact Config - ' + val, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
      this.applicationComponent.InsertAuditHistory(obj);

    } else if (val == 'Close_Add/Edit') {
      this.ContactConfigInfo = true;
      this.AddEditContactFields = false;
      this.ContactFieldsConfiguration = false;
      this.ContactPageConfiguration = false;
      this.ShowContactFieldForm = false;
    } else if (val == 'Close_Configuration') {
      this.ContactConfigInfo = true;
      this.AddEditContactFields = false;
      this.ContactFieldsConfiguration = false;
      this.ContactPageConfiguration = false;
      this.ShowFieldItemTable = false;
    } else if (val == "ContactPageConfiguration") {
      this.ContactConfigInfo = false;
      this.AddEditContactFields = false;
      this.ContactFieldsConfiguration = false;
      this.ContactPageConfiguration = true;
      this.getAccountFields('Contacts');

      var obj: AuditLogModel = { id: 0, type: 'Contact Configuration', action: 'Contact Config - ' + val, AccountOrContactId: 0, AccountOrContact: val, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Contact Config - ' + val, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
      this.applicationComponent.InsertAuditHistory(obj);

      this.getSavedOder('Contacts');
    } else if (val == 'Close_ContactPageConfiguration') {
      this.ContactConfigInfo = true;
      this.AddEditContactFields = false;
      this.ContactFieldsConfiguration = false;
      this.ContactPageConfiguration = false;
    }

  }

  getContactFieldsForm(): FormGroup {

    return new FormGroup({

      'FieldName': new FormControl(this.ContactFields.FieldName, Validators.required),
      'TypeofField': new FormControl(this.ContactFields.TypeOfField, Validators.required),
      'DisplayAs': new FormControl(this.ContactFields.DisplayAs, Validators.required),
      'address1': new FormControl(this.ContactFields.RequiredField, Validators.required),
      'RequiredField': new FormControl(this.ContactFields.DataType, Validators.required),
      'PickListEditable': new FormControl(this.ContactFields.PickListEditable, Validators.required),
      'DataFormat': new FormControl(this.ContactFields.DataFormat, Validators.required),
      'MaxLimit': new FormControl(this.ContactFields.MaxLimit, Validators.required),
      'Description': new FormControl(this.ContactFields.Description, Validators.required),
      'SortOrder': new FormControl(this.ContactFields.SortOrder, Validators.required),

    });


  }
  PreviewForm(val) {
    if (val == 'Open') {
      this.PreviewAccountForm = true;
      this.PreviewAccountFormCss();
    } else {
      this.PreviewAccountForm = false;
    }

  }
  PreviewAccountFormCss() {
    setTimeout(function () {
      //$('#PreviewAccountForm .dialog').attr('style', 'width: 45%;padding-bottom: 0px;border: 4px solid rgb(117, 218, 51);min-height: 81%;overflow: auto;height: 81%;top: 11%;left: 53%;');
      $('#PreviewAccountForm .dialog').attr('style', 'width: 44%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 81%;overflow: auto;height: 81%;top: 11%;left: 54%;');
    }, 4);
  }

  SaveFields() {
    this.FormConfiguration.FormName = this.SelectedAccountPage;
    this.customerService.SaveFormConfiguration(this.FormConfiguration).then(res => {
      this.getSavedOder(this.FormConfiguration.FormName);
      this.toastr.success(this.FormConfiguration.FormName + "Fields Saved Successfully.", this.FormConfiguration.FormName + " Fields", { timeOut: 600 });
    });
  }
  Order() {
    var TaskIdArr = [];
    $.each($("#FieldTable tbody tr td[id$='FieldID']"), function (k, v) {
      var TaskIds = $.trim($(this).text());
      TaskIdArr.push(TaskIds + "~" + k);

    });
    this.FormConfiguration.OrderFields = TaskIdArr.join(',');
  }
  getSavedOder(typ) {
    this.customerService.GetFormConfiguration(this.currentuser.id, typ).then(res => {
      if (res != null && res.length > 0) {
        this.FormConfiguration = res[0];
        let obj: string[] = [res[0].OrderFields, typ];
        this.customerService.getSaveFormFields(obj).then(x => {
          this.AccountFieldsSelected = x;
          this.AccountFieldsSelected.forEach(ele => {
            $('#FieldSelectionCheck' + ele.indexvalue + 'Id' + ele.id).prop('checked', true);
            $('#SelectOption' + ele.indexvalue).text(ele.FieldName);
            $('#SelectOption' + ele.indexvalue).val(ele.id);

          });
        });
      }

    });
  }

  AccountSharingGroup(val) {
    if (val == 'Add/Edit') {
      this.AccGroupNames = true;
      this.AccountSharingHomePage = false;
      this.initializeAccShareGroupAgGrid();
      this.GetAllAccountSharingGroups();
    }
  }
  initializeAccShareGroupAgGrid() {
    this.ShareGroupsgridOptions = <GridOptions>{
      enableColResize: true, enableSorting: true, paginationPageSize: 1000,defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, enableCellChangeFlash: true, enableMultiRowDragging: true,
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createAccShareGroupColumnDefs("");
  }
  createAccShareGroupColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 60,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        enableTooltip: true, rowDrag: true
      },
      { field: 'SharingGroupId', headerName: 'SharingGroupId', hide: true },
      { field: 'SharingGroupName', headerName: 'SharingGroupName', hide: false },
      { field: 'SortOrder', headerName: 'SortOrder', hide: false },
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right', cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.data.isdeleted == 0) {
            eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
          }
          else if (params.data.isdeleted == 1) {
            eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size:14px"></i>';
          }

          eDiv.addEventListener('click', function () {
            selfObj.DeleteShareGroup(params.data);
          });
          return eDiv;
        }
      }
    ];
  }

  GetAllAccountSharingGroups() {
    this.customerService.GetAllAccountSharingGroups(this.currentuser.id).then((ShareGroups) => {
      this.SharingGroupsList = ShareGroups;
    });
  }


  myFunction(val) {
    document.getElementById("myDropdownAcc" + val).classList.toggle("show");
  }

  SelectedFieldChkChange(i, item, event) {
    if (event.target.checked == true) {
      var res = this.AccountFieldsSelected.filter(e => e.id == item.id);
      if (res.length <= 0) {
        $('.FieldSelectionCheckBox').prop('checked', false);
        $('#FieldSelectionCheck' + i + 'Id' + item.id).prop('checked', true);
        $('#SelectOption' + i).text(item.FieldName);
        $('#SelectOption' + i).val(item.id);
        $('.dropdownAcc-content').removeClass("show");
        item.indexvalue = i;
        this.AccountFieldsSelected.push(item);
        if (this.type == '422') {
          this.FormConfiguration.FormName = "Contacts";
        } else {
          this.FormConfiguration.FormName = "Accounts";
        }

        this.FormConfiguration.DisplayFields = this.AccountFieldsSelected.map(x => x.id).join(",");
        this.FormConfiguration.OrderFields = this.AccountFieldsSelected.map(x => x.id + "~" + x.indexvalue).join(",");
        this.FormConfiguration.userid = this.currentuser.id;
      }
      else {
        alert("Field already Exist");
        $('#FieldSelectionCheck' + i + 'Id' + item.id).prop('checked', false);
      }
    }
    else {
      $('.FieldSelectionCheckBox').prop('checked', false);
      $('#SelectOption' + i).text("Select Field");
      $('#SelectOption' + i).val(null);
      this.AccountFieldsSelected = this.AccountFieldsSelected.filter(e => e.id != item.id);
    }
  }

  DeletePageField(i, item, event) {
    $('.FieldSelectionCheckBox').prop('checked', false);
    $('#SelectOption' + i).text("Select Field");
    $('#SelectOption' + i).val(null);
    this.AccountFieldsSelected = this.AccountFieldsSelected.filter(e => e.id != item.id);
  }


  CloseAccGroupNames(val) {
    if (val == 'Close_Add/Edit') {
      this.AccountSharingHomePage = true;
      this.AccGroupNames = false;
      this.ShowShareGroupFieldForm = false;
      this.SharingGroup = new SharingGroups();
    }

  }
  SaveAccountShareGroup() {
    this.SharingGroup.isdeleted = 0;
    this.SharingGroup.Createdby = this.currentuser.id;

    this.SaveSharingGroup();
  }
  SaveSharingGroup() {
    this.customerService.SaveAccountShareGroupData(this.SharingGroup).then(res => {
      this.GetAllAccountSharingGroups();
      this.SharingGroup = new SharingGroups();
      this.ShowShareGroupFieldForm = false;
      this.toastr.success("Account Sharing Group Saved Successfully.", " Account Sharing Groups", { timeOut: 600 });
    });
  }

  onSharingGroupSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {

      if (event.api.getSelectedNodes()[0].data) {
        this.SharingGroup = event.api.getSelectedNodes()[0].data;
        this.ShowShareGroupFieldForm = true;
      }
    }
  }

  DeleteShareGroup(ParamData) {
    this.SharingGroup = ParamData;
    this.SharingGroup.isdeleted = 1;
    this.SaveSharingGroup();

  }
  onRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("SharingGroups");
  }
  onRowDragEndStages(event: RowDragEndEvent) {
    this.saverowconfiguration("SharingGroups");
  }
 

  onRowDragEndSteps(event: RowDragEndEvent) {
    this.saverowconfiguration("SharingGroups");
  }
  saverowconfiguration(type) {
    var sortoorder = [];
    if (type == "AccountFields") {
      this.gridOptionsView.api.forEachNode((node) => {
        sortoorder.push(node.data.id);
      });
    } else if (type == "ContactFields") {
      this.gridOptionsView.api.forEachNode((node) => {
        sortoorder.push(node.data.id);
      });
    }
    else if (type == "SharingGroups") {
      this.ShareGroupsgridOptions.api.forEachNode((node) => {
        sortoorder.push(node.data.SharingGroupId);
      });
    } else {
      this.gridConfigFeildsView.api.forEachNode((node) => {
        sortoorder.push(node.data.id);
      });
    }
    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i == a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentuser.id;

    this.leadservice.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });
    });
  }


  getAccountFieldsForm(): FormGroup {

    return new FormGroup({

      'FieldName': new FormControl(this.AccountFields.FieldName, Validators.required),

      'TypeofField': new FormControl(this.AccountFields.TypeOfField, Validators.required),
      'DisplayAs': new FormControl(this.AccountFields.DisplayAs, Validators.required),
      'address1': new FormControl(this.AccountFields.RequiredField, Validators.required),
      'RequiredField': new FormControl(this.AccountFields.DataType, Validators.required),
      'PickListEditable': new FormControl(this.AccountFields.PickListEditable, Validators.required),
      'DataFormat': new FormControl(this.AccountFields.DataFormat, Validators.required),
      'MaxLimit': new FormControl(this.AccountFields.MaxLimit, Validators.required),
      'Description': new FormControl(this.AccountFields.Description, Validators.required),
      'SortOrder': new FormControl(this.AccountFields.SortOrder, Validators.required),

    });


  }
  LoadDropDownValues(type) {
    this.leadservice.GetAllDropdownsList(type).then((dropdowns) => {
      this.dropdownTypes = dropdowns;
      this.dropdownTypes.forEach(drop => {
        $('#' + drop.dropdownName + 'Value').css('display', 'none')
        $('#' + drop.dropdownName + 'Div').css('display', 'none')
      });
    });
  }
  showPageCheckbeoxs() {
  
    if (!this.expanded) {
      this.expanded = true;
    }
    else {
      this.expanded = false;
    }
 

  }


  printSortStateToConsole(event) {

    const sortState = this.gridApi.getSortModel();
    if (sortState.length == 0) {
    } else {

      for (var i = 0; i < sortState.length; i++) {
        const item = sortState[i];
        var sortoorder = [];
        this.ShareGroupsgridOptions.api.forEachNode((node) => {
          sortoorder.push(node.data);
        });

        if (item.sort == 'asc') {
          sortoorder = sortoorder.sort(function (a, b) { return a.Sortorder - b.Sortorder });

        } else {
          sortoorder = sortoorder.sort(function (a, b) { return a.Sortorder + b.Sortorder });
        }
        let IDs = sortoorder.map(s => s.SharingGroupId);

        this.rowconfig.SortOrder = IDs.filter((el, i, a) => i == a.indexOf(el)).join(",");
        this.rowconfig.Type = "SharingGroups";
        this.rowconfig.userid = this.currentuser.id;

        this.leadservice.SavestatusOrder(this.rowconfig).then(result => {
          this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });
        });

      }
    }

  }
  CancelDropdown(clickedButton) {
    this.LoadDropDownValues(this.type);
    $('#' + clickedButton + 'Value').css('display', 'none')
    $('#' + clickedButton + 'Div').css('display', 'none')
    $('#' + clickedButton + 'Table').css('height', '79%')

  }
  edit(specid, dropName) {
    $('#' + dropName + specid + 'itemName').attr('style', "background-color:#b7e4ff;")
    $('#' + dropName + specid + 'Buttons').attr('style', "background-color:#b7e4ff;")
    $('.disableEditable').attr('contenteditable', "false");
    $('#' + dropName + specid + 'itemName').attr('contenteditable', "true")
    this.rowedit = true;
  }
  keyupTask(dropName, specid) {
    this.specialityids = [];
    if (specid > 0) {
      if ((this.specialityids.filter(f => f.Index == specid && f.Type == dropName)).length == 0)
        this.specialityids.push({ Index: specid, Type: dropName });

    }
    $('#' + dropName + specid + 'itemName').css("color", "red");
    $('#' + dropName + 'Div').css('display', 'block');
    $('#' + dropName + 'Table').css('height', '68%')
  }
  onAccountAgGridReady(event) {
    this.gridOptionsView.api = event.api;
  }

  onAccountRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("AccountFields");
  }
  onConfigFeildsRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration(this.SelectedField.id);
  }
  delete(specid, itemName, dropName, dropdownvalues) {
    this.dropdown.id = specid;
    this.dropdown.itemName = itemName;
    this.dropdown.category = dropName;
    this.customerService.checkIdaleadymapped(this.dropdown).then(result => {

      if (result == null) {
        var c = confirm("Select Yes To Confirm You Want to Delete ??");
        $('#' + dropName + specid + 'itemName').attr('style', "background-color:#b7e4ff;")
        $('#' + dropName + specid + 'Buttons').attr('style', "background-color:#b7e4ff;")
        if (c == true) {
          this.customerService.deleteDropdown(specid, itemName).then(result => {
            this.toastr.success("DropDown Deleted Successfully.", "Drop Down List", { timeOut: 600 });
            this.LoadDropDownValues(this.type);
           
            this.dropdown = new Dropdown();
            $('#' + itemName + 'Value').val('');
          });
        }
        else {
          $('#' + dropName + specid + 'itemName').attr('style', "background-color:none;border:1px solid #A6A4A4")
          $('#' + dropName + specid + 'Buttons').attr('style', "background-color:none;border:1px solid #A6A4A4")
          this.LoadDropDownValues(this.dropdown.itemName)
        }
      }
      else {
        this.dropdownid = specid;
        this.dropItemName = itemName;
        this.dropdownCategory = dropName;
        this.AccountIdArr = dropdownvalues;
        this.contactIdArr = dropdownvalues;
        this.showAccountContactDialog = true;
        this.showAdvancedSearchDialogCss();

      }
    });
  }
  Replaceanddelete(drpId, drpName, replaceid) {
    this.customerService.ReplaceandDelete(drpId, drpName, replaceid).then(result => {
      this.showAccountContactDialog = false;
      this.dropdown = new Dropdown();
      $('#' + drpName + 'Value').val('');
      this.toastr.success("Dropdown deleted successfully.", "Dropdown", { timeOut: 600 });
    });
  }
  showAdvancedSearchDialogCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width:30%;padding-bottom:0px;border:4px solid rgb(49,189,240);border-radius:5%;min-height:20%;overflow:auto;height:31%;left:0%;top:37%;padding:0;');
    }, 4)
  }

  onAgGridReady(event) {
    this.gridApi = event.api;
  }

  onContactAgGridReady(event) {
    this.gridOptionsView.api = event.api;
  }
  onContactRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("ContactFields");
  }
  onConfigFeildsAgGridReady(event) {
    this.gridApi = event.api;
  }
  CategoryOrder() {
    let TaskIdArr = [];
    $.each($("#" + this.SelectedField.ColumnName + " tr td:nth-child(2)"), function (k, v) {
      var TaskIds = $.trim($(this).attr('id'));
      TaskIdArr.push(TaskIds.split('~')[1]);

    });

    this.userService.SaveConfigFieldDataSortOrder(TaskIdArr.join(','), this.SelectedField.FieldName, this.SelectedField.id, this.currentuser.id).then(result => {
      this.toastr.success("Questions Re-Ordered Successfully.", " Questions", { timeOut: 600 });
    });
  }
}
