import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.css']
})
export class DeletePopupComponent implements OnInit {
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  @Input() status;
  @Input() MessageType;

  @Input() title;
  @Input() description;
  @Input() isok;
  @Input() issave;
  @Input() iscancel;
  @Input() isdiscard;
  constructor() { }

  ngOnInit(): void {
  }
  CRMOK() { this.sendMsgEvent.emit("Ok"); }
  CRMSAVE() { }
  CRMDISCARD() { }
  CRMCANCEL() { this.sendMsgEvent.emit("Cancel"); }
  //Confirmation(val) {
  //  if (val) {
  //    this.sendMsgEvent.emit("Ok");
  //  } else {
  //    this.sendMsgEvent.emit("Cancel");
  //  }
    
  //}
}
