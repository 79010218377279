<div class="col-xs-12" style="height:100%;padding:0;">
 
  <!--<div class="col-xs-11" style="height: 5%;">
  </div>
  <div class="col-xs-1" style="height: 5%;">
    <span style="float:right;margin-top:7%" (click)="applicationComponent.selectedsubbutton='';"> <img src="../../../assets/img/Black x.png" style="height:25px" /> </span>
  </div>-->
  <div class="col-xs-2" style="height: 100%; width: 16%; padding-right: 0%;">

    <div class="col-xs-12" style="padding:0;height:90%;border-right:1px solid black">
      <div class="col-xs-12" style="padding: 0; height: 100%; border-right: 5px solid rgb(186,219,152) ">
        <div class="fancy-collapse-panel scroll-c" style="overflow: auto;height: 100%;" id="3DotsIconPopUp">
          <div class="panel-group" id="accordion" role="tablist" style="margin-top: 10px" aria-multiselectable="true">

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Account Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="AccountConfigurationMainMenu" (click)="SubmenuChange('Account Configuration','420','main')" [style.background]="type == '420' ? 'rgb(186,219,152)': 'white'">
                    <label>Account Configuration</label>

                  </a>
                </h4>

              </div>
              <div id="AccountConfiguration" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="420_button" class="subButtons " (click)="SubmenuChange('Account Fields','420','Sub')" title="Account Fields"><label style="width:5%"><b>-</b></label> <label style="width:95%">Account Fields</label></li>

                    <li id="422_button" (click)="SubmenuChange('Account Fields Configuration','422','Sub')" class="subButtons" title="Account Fields Configuration">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Account Fields Configuration</label>

                    </li>

                    <li id="423_button" class="subButtons" (click)="SubmenuChange('Account Page Configuration - Mailing Address','423','Sub')" title="Account Page Configuration - Mailing Address">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Account Page Configuration - Mailing Address</label>

                    </li>

                    <li id="424_button" class="subButtons" (click)="SubmenuChange('Account Page Configuration - Account Specifics','424','Sub')" title="Account Page Configuration - Account Specifics">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Account Page Configuration - Account Specifics</label>

                    </li>


                  </ul>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Account Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="AccountSharingMainMenu" (click)="SubmenuChange('AccountSharing','500','main')" [style.background]="type == '500' ? 'rgb(186,219,152)': 'white'">
                    <label>Account Sharing Groups</label>

                  </a>
                </h4>

              </div>
              <div id="AccountSharing" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px;">
                    <li id="500_button" class="subButtons activeSubButton" (click)="SubmenuChange('AccountSharing','500','Sub')" title="Account Fields">

                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Group Names</label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Account Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="CampaignMainMenu" (click)="SubmenuChange('Campaign','600','main')" [style.background]="type == '600' ? 'rgb(186,219,152)': 'white'">
                    <label>Campaign Configuration</label>

                  </a>
                </h4>

              </div>
              <div id="Campaign" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="600_button" class="subButtons activeSubButton" (click)="SubmenuChange('CampaignTypes','600','Sub')" title="Campaign Types">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Campaign Types</label>

                    </li>
                    <li id="601_button" class="subButtons" (click)="SubmenuChange('CampaignExpenses','601','Sub')" title="Campaign Expenses">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Campaign Expenses</label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Contact Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="ContactMainMenu" (click)="SubmenuChange('Contact','700','main')" [style.background]="type == '700' ? 'rgb(186,219,152)': 'white'">
                    <label> Contact Configuration </label>

                  </a>
                </h4>

              </div>
              <div id="Contact" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="700_button" class="subButtons activeSubButton" (click)="SubmenuChange('Contact Fields','700','Sub')" title="Contact Fields">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Contact Fields</label>

                    </li>
                    <li id="701_button" class="subButtons" (click)="SubmenuChange('Contact Fields Configuration','701','Sub')" title="Contact Fields Configuration">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Contact Fields Configuration</label>

                    </li>
                    <li id="702_button" class="subButtons" (click)="SubmenuChange('Contact Page Configuration','702','Sub')" title="Contact Page Configuration">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Contact Page Configuration</label>

                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Contact Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="DocumentMGMTMainMenu" (click)="SubmenuChange('DocumentMGMT','800','main')" [style.background]="type == '800' ? 'rgb(186,219,152)': 'white'">
                    <label> Document MGMT Configuration  </label>

                  </a>
                </h4>

              </div>
              <div id="DocumentMGMT" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="800_button" class="subButtons activeSubButton" (click)="SubmenuChange('DocumentMGMT','800','Sub')" title="Contact Fields">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Document Names / Accounts</label>

                    </li>
                    <li id="801_button" class="subButtons" (click)="SubmenuChange('DocumentMGMT','801','Sub')" title="Contact Fields">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Document Names / My Documents</label>

                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Contact Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="LeadsMainMenu" (click)="SubmenuChange('Leads','900','main')" [style.background]="type == '900' ? 'rgb(186,219,152)': 'white'">
                    <label> Leads Configuration  </label>

                  </a>
                </h4>

              </div>
              <div id="Leads" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="900_button" (click)="SubmenuChange('Leads','900','Sub')" class="subButtons activeSubButton" title="Status Type">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Status Type</label>

                    </li>
                    <!--<li id="901_button" class="subButtons" title="Status Type" (click)="SubmenuChange('Leads','901','Sub')">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Trigger Type</label>
                    </li>-->
                    <li id="902_button" class="subButtons" title="Status Type" (click)="SubmenuChange('Leads','902','Sub')">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Sales Stages</label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="MySalesXCRM Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="CRMMainMenu" (click)="SubmenuChange('CRM','100','main')" [style.background]="type == '100' ? 'rgb(186,219,152)': 'white'">
                    <label> My Documents Configuration  </label>

                  </a>
                </h4>

              </div>
              <div id="CRM" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="100_button" class="subButtons activeSubButton" (click)="SubmenuChange('CRM','100','Sub')" title="Status Type">  <label style="width:5%"><b>-</b></label> <label style="width:95%">Document Name</label></li>
                    <li id="101_button" class="subButtons" title="Status Type" (click)="SubmenuChange('CRM','101','Sub')">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Chart Tab Description</label>
                    </li>

                  </ul>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Opportunities Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="OpportunitiesMainMenu" (click)="SubmenuChange('Opportunities','200','main')" [style.background]="type == '200' ? 'rgb(186,219,152)': 'white'">
                    <label>Opportunities Configuration</label>

                  </a>
                </h4>

              </div>
              <div id="Opportunities" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="200_button" class="subButtons activeSubButton" (click)="SubmenuChange('Fields','200','Sub')" title="Fields Configuration">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Fields Configuration</label>

                    </li>
                    <li id="201_button" class="subButtons" (click)="SubmenuChange('FieldChoices','201','Sub')" title="Field Choices Configuration"><label style="width:5%"><b>-</b></label> <label style="width:95%">Field Choices Configuration</label></li>

                  </ul>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Projects Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="ProjectMainMenu" (click)="SubmenuChange('Project','300','main')" [style.background]="type == '300' ? '#c1e9c1': 'white'">
                    <label>Projects Configuration</label>

                  </a>
                </h4>

              </div>
              <div id="Project" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="300_button" class="subButtons activeSubButton" title="Projects Fields" (click)="SubmenuChange('ProjectsFields','300','Sub')">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Projects Fields</label>

                    </li>

                    <li id="301_button" class="subButtons" (click)="SubmenuChange('ProjectsFieldsConfiguration','301','Sub')" title="Projects Fields Configuration">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Projects Fields Configuration</label>

                    </li>

                    <li id="302_button" class="subButtons" title="Project Stages" (click)="SubmenuChange('ProjectStages','302','Sub')">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Project Stages</label>

                    </li>

                    <li id="303_button" class="subButtons" title="Project Steps" (click)="SubmenuChange('ProjectSteps','303','Sub')">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Project Steps</label>

                    </li>

                  </ul>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Proposal Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="ProposalMainMenu" (click)="SubmenuChange('Proposal','1000','main')" [style.background]="type == '1000' ? 'rgb(186,219,152)': 'white'">
                    <label>Proposal Configuration</label>

                  </a>
                </h4>

              </div>
              <div id="Proposal" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="1000_button" class="subButtons activeSubButton" (click)="SubmenuChange('Fields','1000','Sub')" title="Fields Configuration">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Categories</label>

                    </li>
                    <li id="1001_button" class="subButtons" (click)="SubmenuChange('Items','1001','Sub')" title="Field Choices Configuration">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Items</label>

                    </li>

                  </ul>
                </div>
              </div>
            </div>



            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Software License Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="SoftwareLicenseMainMenu" (click)="SubmenuChange('SoftwareLicense','1100','main')" [style.background]="type == '1100' ? 'rgb(186,219,152)': 'white'">
                    <label>Software License Configuration</label>

                  </a>
                </h4>

              </div>
              <div id="SoftwareLicense" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="1100_button" class="subButtons activeSubButton" (click)="SubmenuChange('Categories','1100','Sub')" title="Categories">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Categories</label>

                    </li>
                    <li id="1101_button" class="subButtons" (click)="SubmenuChange('LicenseTypes','1101','Sub')" title="Field Choices Configuration">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">License Types</label>

                    </li>

                  </ul>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Status ID Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="StatusIDMainMenu" (click)="SubmenuChange('StatusID','1200','main')" [style.background]="type == '1200' ? 'rgb(186,219,152)': 'white'">
                    <label>Status ID Configuration</label>

                  </a>
                </h4>

              </div>
              <div id="StatusID" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="1200_button" class="subButtons activeSubButton" (click)="SubmenuChange('StatusIDGroups','1200','Sub')" title="Categories">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Status ID Groups</label>
                    </li>
                    <li id="1201_button" class="subButtons" (click)="SubmenuChange('StatusIDItems','1201','Sub')" title="Field Choices Configuration">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Status ID Items</label>

                    </li>

                  </ul>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Task List Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="TaskListMainMenu" (click)="SubmenuChange('TaskList','1300','main')" [style.background]="type == '1300' ? 'rgb(186,219,152)': 'white'">
                    <label> Task List Configuration </label>

                  </a>
                </h4>

              </div>
              <div id="TaskList" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="1300_button" class="subButtons activeSubButton" (click)="SubmenuChange('TaskGroups','1300','Sub')" title="Categories">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Task Groups</label>

                    </li>
                    <li id="1301_button" class="subButtons" (click)="SubmenuChange('TaskGroupItems','1301','Sub')" title="Field Choices Configuration">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Task Group Items</label>

                    </li>

                  </ul>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne" style="height:30px">
                <h4 class="panel-title">
                  <a class="collapsed" title="Ticket Configuration" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="Config"
                     id="TicketMainMenu" (click)="SubmenuChange('Ticket','1400','main')" [style.background]="type == '1400' ? 'rgb(186,219,152)': 'white'">
                    <label> Ticket Configuration </label>

                  </a>
                </h4>

              </div>
              <div id="Ticket" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="nav menu" style="padding-left: 6%; font-size: 11px; ">
                    <li id="1400_button" class="subButtons activeSubButton" (click)="SubmenuChange('SalesXCRM','1400','Sub')" title="Categories">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">SalesXCRM Ticket Configuration</label>
                    </li>
                    <li id="1401_button" class="subButtons" (click)="SubmenuChange('Zendesk','1401','Sub')" title="Field Choices Configuration">
                      <label style="width:5%"><b>-</b></label> <label style="width:95%">Zendesk Integration Configuration</label>

                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="col-xs-10" style="padding: 0; height: 100%; width: 84%;">


    <div class="col-xs-12" style="padding:0 ;height:100%" *ngIf="(type=='420' ||type=='421' || type=='422' ||type=='423' ||type=='424' || type=='500' || type=='700'||type=='701'||type=='702') && show">
      <app-account-configuration [type]="type" [SubMenuType]="SubMenuType"></app-account-configuration>
    </div>
    <div class="col-xs-12" style="padding:0 ;height:100%" *ngIf="(type=='600'|| type=='601') && show">
      <app-campaign-configuration [type]="type"></app-campaign-configuration>
    </div>
    <div class="col-xs-12" style="padding:0 ;height:100%;" *ngIf="(type=='800'||type=='801') && show">
      <app-document-management-configuration></app-document-management-configuration>
    </div>
    <div class="col-xs-12" style="padding:0 ;height:100%" *ngIf="(type=='900' || type=='901'|| type=='902') && show">
      <app-leads-configuration [type]="type"></app-leads-configuration>
    </div>
    <div class="col-xs-12" style="padding:0 ;height:100%" *ngIf="(type=='100' || type=='101') && show">
      <app-my-salesxcrm-configuration [type]="type"></app-my-salesxcrm-configuration>
    </div>
    <div class="col-xs-12" style="padding:0 ;height:100%" *ngIf="(type=='200' || type=='201') && show">
      <app-opportunities-configuration></app-opportunities-configuration>
    </div>
    <div class="col-xs-12" style="padding:0 ;height:100%" *ngIf="(type=='300' || type=='301' ||type=='302' || type=='303') && show">
      <app-project-configuration></app-project-configuration>
    </div>
    <div class="col-xs-12" style="padding:0 ;height:100%" *ngIf="(type=='1000' || type=='1001') && show">
      <app-proposal-configuration></app-proposal-configuration>
    </div>
    <div class="col-xs-12" style="padding:0 ;height:100%" *ngIf="(type=='1100' || type=='1101') && show">
      <app-software-license-configuration></app-software-license-configuration>
    </div>
    <div class="col-xs-12" style="padding:0 ;height:100%" *ngIf="(type=='1200' || type=='1201') && show">
      <status-idconfiguration></status-idconfiguration>
    </div>
    <div class="col-xs-12" style="padding:0 ;height:100%" *ngIf="(type=='1300' || type=='1301') && show">
      <app-task-list-configuration></app-task-list-configuration>
    </div>
    <div class="col-xs-12" style="padding:0 ;height:100%" *ngIf="(type=='1400' ||type=='1401') && show">
      <app-ticket-configuration></app-ticket-configuration>
    </div>

  </div>












</div>




