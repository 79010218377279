<div class="col-xs-12" style="height:100%;padding:0 0 0 0%;">
  <div class="col-xs-12" style="background-color: rgb(186,219,152); height: 2% ">

  </div>
  <div class="col-xs-11" style="height: 4%;text-align: left">
    <label style="padding: 2% 0% 0% 0%; text-transform: uppercase;">My Profile</label>
  </div>
  <div class="col-xs-1" style="height: 4%; text-align: left">
    <span style="float:right;margin-top:7%" (click)="close();"> <img src="../../../assets/img/Black x.png" style="height: 20px; margin-top: 30%;"/> </span>
  </div>

  <div class="col-xs-12" style="text-align: center; padding: 0.75%; margin-top: 0%; height: 95%; overflow: auto;">
    <div class="col-xs-3" style="padding:0">
      <div style="height:100%;margin: 1%;" class="col-xs-12">
        <img [src]="urlImage" height="100" style="width:100%" *ngIf="urlImage">
        <input id="addProfileFiles" name="addProfileFiles" value="addFiles" type="file" (change)="addFiles($event)" accept=".jpg,.jpeg" style="display:contents" />

        <label for="addProfileFiles" style="color:#ddd;width:100%;height:100%;margin-bottom:0;" *ngIf="!urlImage">
          <!--<i class="fa fa-camera" aria-hidden="true" *ngIf="!urlImage" for="addProfileFiles" style="margin:0;"></i>-->
          <img src="../../../assets/img/default patient photo.png" style="height:62%;width:100%" *ngIf="!urlImage" />
        </label>
        <input id="AddFilesInput1" name="AddFilesInput1" value="AddFilesInput1" type="file" (change)="addFiles($event)" accept=".jpg,.jpeg" style="display:contents" />
        <label for="AddFilesInput1" style="border:0.75px solid #ddd;border-radius:5px;width:100%;text-align:center;height: 24px;padding: 0;margin-bottom: 0;background-color: #ddd;font-size:0.8vw" *ngIf="urlImage">Replace Picture </label>
      </div>
    </div>
    <div class="col-xs-9" style="border: 1px solid #ddd; border-radius:3px; padding:0;text-align:left;font-size:0.75em !important ">

      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Title:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.titleName}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>First Name:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.firstname}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Middle Name:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.middleName}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Last Name:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.lastname}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Suffix:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.suffixName}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>User Status:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.UserStatus}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>User Role:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.RoleName}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>System Admin:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.systemadministrator == 1 ? 'Yes': 'No'}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Create Task Group:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.TaskGroupName}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Sales Quota:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.Quotaid == 445 ? 'Yes': 'No'}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Deal Approval:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.dealApproval}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Document Access:</label>
        </div>
        <div class="col-xs-7">
          <span>{{documentAccessName}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>MySalesX Access:</label>
        </div>
        <div class="col-xs-7">
          <span>{{mySalesXAccessName}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>TimeZone:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.timezone}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Release Notes Admin:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.releaseNoteAddEdit == 1 ? 'Yes': 'No'}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Customer Type:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.customertypeaccess == 1 ? 'Yes': 'No'}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Customer Acct#:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.customerAccount == 1 ? 'Yes': 'No'}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Customer Status:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.customerStatus == 1 ? 'Yes': 'No'}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Tickets: Task Group Access:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.TaskGroupName}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Zendesk Permission:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.ZendeskPermissionName}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Email*:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.email}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Password:</label>
        </div>
        <div class="col-xs-7">
          <span>********</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Email Configuration:</label>
        </div>
        <div class="col-xs-7">
          <span>{{user.isSendGrid == true ? 'Send Grid' : ''}} {{user.isSMTP == true ? 'SMTP' : ''}}</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>User Access:</label>
        </div>
        <div class="col-xs-7">
          <div *ngFor="let selecteduser of user.selectedItems">
            <span>{{selecteduser.itemName}} {{selecteduser.category}}</span>
          </div>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>Task Group Access:</label>
        </div>
        <div class="col-xs-7">
          <div *ngFor="let selecteduser of user.TaskGroupselectedItems">
            <span>{{selecteduser.itemName}}</span>
          </div>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5">
          <label>My SalesXCRM User Access:</label>
        </div>
        <div class="col-xs-7">
          <div *ngFor="let selecteduser of user.SalesXCRMacesselectedItems">
            <span>{{selecteduser.itemName}} {{selecteduser.category}}</span>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>
