import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import { AppSettings } from '../app.settings';
import { Emails } from './../models/emails';
@Injectable()
export class MailserviceService {
  emails: Emails = new Emails();
  options: RequestOptions;
  authToken: any;
  headers: any; reqHeader: HttpHeaders = new HttpHeaders();
  constructor(private httpclient: HttpClient, private http: Http, private settings: AppSettings)
  {
  this.reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('key')
  });
  //this.authToken = localStorage.getItem('key');
  //  this.headers = new Headers();
  //  this.headers.append('Authorization', `Bearer ${this.authToken}`);
  //  this.options = new RequestOptions({ headers: this.headers });
  }
  SendEmails(emails: Emails): Promise<Emails> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.post<any>(this.settings.baseUrl + "api/messaging/SendEmails", emails,{ headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
}
