import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridOptions, Optional } from 'ag-grid';
import { Campaign } from '../../../models/Campaign';
import { AccountsService } from '../../../services/accounts.service';
import { CustomerService } from '../../../services/customer.service';
import { LeadsService } from '../../../services/leads.service';
import { OpprtunitiesService } from '../../../services/opprtunities.service';
import { UserService } from '../../../services/user.service';
import { ApplicationComponent } from '../../application.component';
import { CampaignsComponent } from '../../campaigns/campaigns.component';
declare var $: any;
@Component({
  selector: 'app-campaign-dashboard',
  templateUrl: './campaign-dashboard.component.html',
  styleUrls: ['./campaign-dashboard.component.css']
})
export class CampaignDashboardComponent implements OnInit {
  public gridOptions: GridOptions;
  public columnDefs: any[];
  allCampaigns: Campaign[];
  OriginalAllCampaigns: Campaign[];
  public context;
  private gridApi;
  public sideBar;
  currentuser: any;
  pageSize: string = "1000";
  colSelections: string;
  public frameworkComponents;
  @Input() type: string;
  ShowConfigurationDialog: boolean = false;
  CampaignId;
  userIDs: string;
  @ViewChild(CampaignsComponent) CampaignComp: CampaignsComponent;
  constructor(public applicationComponent: ApplicationComponent, private route: ActivatedRoute, public decimalpipe: DecimalPipe, private customerService: CustomerService, private leadsService: LeadsService,     private opprtunityservice: OpprtunitiesService, private userService: UserService, private viewContainerRef: ViewContainerRef, private accountsService: AccountsService) {

    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));}

  ngOnInit(): void {
    this.initializeAgGrid(this.currentuser.id);
  }


  initializeAgGrid(userids) {
    this.userIDs = userids;
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 32).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.pageSize = config.maxlimit.toString();
      }

      this.colSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.pageSize = (this.pageSize == "") ? '1000' : this.pageSize;
      this.createColumnDefs(this.colSelections);
      this.LoadCampaigns(this.userIDs);

    });

    this.context = this;
    //this.frameworkComponents = {
    //  linkdelete: LinkDeleteComponent
    //};

  }

  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    var searcharray = [];
    if (this.applicationComponent.searchCriteriadata) {
      var searchaarraystring = this.applicationComponent.searchCriteriadata;
      if (searchaarraystring.includes(' /')) {
        searcharray = searchaarraystring.split(' / ');
      } else {
        searcharray.push(searchaarraystring);
      }
    }
    let selfobj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
       
          if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true });
        }
         else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3]),
          });
        }
      }
    }
    else {
      this.columnDefs = [
        //{
        //  field: '#',
        //  headerName: '#',
        //  width: 52,
        //  checkboxSelection: true,
        //  suppressSorting: true,
        //  suppressMenu: true,
        //  pinned: true,
        //  hide: false,
        //  outerWidth: 8,
        //  headerCheckboxSelection: true,
        //  headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        //},
        { field: 'CampaignID', headerName: 'Campaign Id', hide: true },
        { field: 'CreatedDate', headerName: 'Date Created', hide: false },
        { field: 'CampaignName', headerName: 'Campaign Name', hide: false },
        { field: 'CampaignTypeName', headerName: 'Campaign Type', hide: false },
        { field: 'TotalExpense', headerName: 'Total Expense', hide: false },
        { field: 'ProfitLoss', headerName: 'Profit | Loss', hide: false },
        { field: 'salesPlusMonthlyPerYear', headerName: 'Total SMR', hide: false },
        { field: 'CampaignStatus', headerName: 'Status', hide: false },
        { field: 'createdByName', headerName: 'Owner', hide: false },
        //{
        //  field: 'actions', headerName: 'Actions', width: 80, hide: (this.Source == "dashboard") ? true : false, pinned: 'right', cellRenderer(params) {
        //    var eDiv = document.createElement('div');
        //    eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
        //    eDiv.addEventListener('click', function () {
        //      selfobj.campaign = params.data;
        //      selfobj.DeleteCampaignConfirmation = true;
        //      selfobj.DeleteExpenseCss('DeleteCampaignConfirmation');
        //    });
        //    return eDiv;
        //  }
        //}


      ];

      var tooltipRenderer = function (params) {
        return '<span title="' + params.value + '">' + params.value + '</span>';
      };
    }
  }


  LoadCampaigns(userid) {
    this.opprtunityservice.GetAllCampaigns(userid).then((viewCampaigns) => {

      viewCampaigns.forEach(ele => {
        var salesPlusMonthlyPerYear = Number(ele.SalesRevenue) + (Number(ele.MonthlyRevenue) * 12);
        var ProfitLoss = salesPlusMonthlyPerYear - ele.TotalExpense;

        ele.salesPlusMonthlyPerYear = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(salesPlusMonthlyPerYear);
        ele.ProfitLoss = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(ProfitLoss);

      });

      this.OriginalAllCampaigns = viewCampaigns;
      if (this.type == 'Open') {
        this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.CampaignStatus == 'Open');
      } else if (this.type == 'Closed') {
        this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.CampaignStatus == 'Closed');
      } else {
        this.allCampaigns = this.OriginalAllCampaigns;
      }
      
    });
  }

  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
     // this.CampaignId = event.api.getSelectedNodes()[0].data.CampaignID;
    
      this.applicationComponent.selectedbuttonText = 'My Campaigns';
      this.applicationComponent.activateMainButtons('Campaigns');
      localStorage.setItem('CompaignVal', event.api.getSelectedNodes()[0].data.CampaignID + "|" + this.type);
    }
  }
  CloseDialog(msg) {
    if (msg == 'CloseDialog') {
      this.ShowConfigurationDialog = false;
    }
  }
  DialogCss() {
    setTimeout(function () {
      $('#ConfigurationDialog .dialog').attr('style', 'width: 100%;padding-bottom: 0px;border: none;min-height: 63%;overflow: auto;height: 84%;padding: 0.5%;top: 10%;max-width: 88%;left: 10%;');
    }, 4)
  }
}
