import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Column, GridOptions, RowDragEndEvent } from 'ag-grid';
import * as moment from 'moment';
import { IMyDpOptions } from 'mydatepicker';
import { ToastrService } from 'ngx-toastr';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { Dropdowntype } from '../../models/dropdowntype';
import { SystemRowConfiguration } from '../../models/system-row-configuration';
import { CustomerService } from '../../services/customer.service';
import { LeadsService } from '../../services/leads.service';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { ApplicationComponent } from '../application.component';
import { EditdeleteComponent } from '../editdelete/editdelete.component';
import { Document } from '../../models/document';
import { UserService } from '../../services/user.service';
import { DocumentmanagementComponent } from '../documentmanagement/documentmanagement.component';
import { AuditLogModel } from '../../models/auditlog';
declare var $: any;
@Component({
  selector: 'app-document-management-configuration',
  templateUrl: './document-management-configuration.component.html',
  styleUrls: ['./document-management-configuration.component.css']
})
export class DocumentManagementConfigurationComponent implements OnInit {
  DocMgmtHomePage: boolean = true;
  DocMgmtPage: boolean = false;
  selectedDocType: string;
  currentuser: any;
  DocumenttypeList: Dropdowntype[] = [];
  public gridOptionsDM: GridOptions;
  public sideBar;
  pageSize: string = "1000";
  colSelections: string;
  public context;
  public frameworkComponents;
  public documentscolumnDefs: any[];
  permissionlevellist: Dropdowntype[] = [];
  selectedtype: string = "Accounts & Contacts";
  documenttypeList = [];
  deleteRowData: any;
  finaldocumentlist: Document[] = [];
  DeletedDoc: any;
  documentNamesList: any[] = [];
  ShowDeleteDocNameMatch: boolean = false;
  ShowDeleteDocNamePermission: boolean = false;
  userdocumentlist: any[] = [];
  datePipe: DatePipe = new DatePipe("en-US");
  userdocumentcount: number = 0;
  currentYear = new Date().getFullYear();
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal === undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal === undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");
  finalitems = ""; dropdownSetting = {};
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal === undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal === undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");
  selectedItems = [];
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration();
  documentlist: Document[] = [];
  private gridApi;
  SelReportType: string = '496';
  calanderonoff: boolean = true;
  doc: Document = new Document();
  @ViewChild(DocumentmanagementComponent) docMgmtComp;
  @Input() SubMenuType: string = null;
  constructor(private leadsService: LeadsService, private customerService: CustomerService, private opprtunityservice: OpprtunitiesService, public applicationComponent: ApplicationComponent, private toastr: ToastrService, private userservice: UserService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.customerService.GetSpecialityList("DocumnentType", this.currentuser.id).then((Documenttype) => {
      Documenttype.forEach(Documenttype => {
      });
      this.DocumenttypeList = Documenttype;
      //this.ChangeDocumentType();
      this.initializeAgGrid();
    });
    if (this.SubMenuType !== null && this.SubMenuType !== undefined && this.SubMenuType !== '') {
      this.AccessDiv(this.SubMenuType);
    } else {
      var obj: AuditLogModel = { id: 0, type: 'Document MGMT Configuration', action: 'Doc Mgmt Config', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Doc Mgmt Config', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
      this.applicationComponent.InsertAuditHistory(obj);
    }
  }
  ChangeVal(val) {
    this.docMgmtComp.ChangeVal(val);
  }
  AccessDiv(val) {
    this.DocMgmtPage = true;
    this.DocMgmtHomePage = false;
    

    var obj: AuditLogModel = { id: 0, type: 'Document MGMT Configuration', action: 'Doc Mgmt Config - ' + val, AccountOrContactId: 0, AccountOrContact: val, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Doc Mgmt Config - ' + val, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
    this.ChangeDocumentType();
  }

  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
  }

  ChangeDocumentType() {
    this.selectedDocType = $("#DocumenttypeId option:selected").text();
    //this.loadPermissionLevel(this.selectedDocType);
    this.docMgmtComp.loaddocuments(this.selectedDocType, this.currentYear);
  }
  loadPermissionLevel(type) {
    var dropdowntype = "";
    if (type == "Accounts & Contacts") {
      dropdowntype = "PermissionLevel";
    }
    else {
      dropdowntype = "UserPermissionLevel";
    }
    this.customerService.GetSpecialityList(dropdowntype, this.currentuser.id).then((PermissionLevel) => {
      PermissionLevel.forEach(PermissionLevel => {
      });
      this.permissionlevellist = PermissionLevel;
    });
  }

  initializeAgGrid() {
    this.gridOptionsDM = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, paginationPageSize: 1000, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: false, toolPanelSuppressPivotMode: false, rowSelection: 'single',
      toolPanelSuppressValues: false, enableCellChangeFlash: true, enableMultiRowDragging: true,

    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    
      this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 56).then((config) => {

        if (config != null && config.maxlimit != null && config.maxlimit > 0) {
          this.pageSize = config.maxlimit.toString();
        }
        columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
        this.colSelections = columnSelections;
        this.createColumnDefs(this.colSelections);
        this.getUserDocumentList(this.currentYear, 1, this.pageSize, this.currentuser.id, this.startDateVal, this.endDateVal)
      });
    

    
    this.gridOptionsDM.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeleteComponent,
    };
  }
  createColumnDefs(columnSelections: string) {

    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      this.documentscolumnDefs = [];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');

        if (fieldHeaderVisibleWidthArray[0] === "#") {
          this.documentscolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] === "actions") {
          this.documentscolumnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: fieldHeaderVisibleWidthArray[2] === "true" ? false : true, pinned: 'right', width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else {
          this.documentscolumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] === "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }


      }
    } else {
      let selfObj = this;
      this.documentscolumnDefs = [

        {
          field: '#',
          headerName: '#',
          width: 70,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag:true,
        },
        { field: 'DateUploaded', headerName: 'Date Created', hide: false },
        { field: 'documentname', headerName: 'Document Name', hide: false, editable: true },
        { field: 'UploadedBy', headerName: 'Saved By', hide: false },
        { field: 'documentdescription', headerName: 'Description', hide: true },
        { field: 'Size', headerName: 'Size', hide: true },
        {
          field: 'PermissionlevelName', headerName: 'Permission to View', hide: false, editable: true,
          cellEditor: "agRichSelectCellEditor",
          cellEditorParams: function (params) {
            return {
              values: Array.prototype.map.call(selfObj.permissionlevellist, s => s.itemName),
              formatValue: function (value) {
              }
            };
          }, cellRenderer(params) {
            return params.data.PermissionlevelName;
          }
        },
        {
          field: 'doctab', headerName: 'Document Tab', cellEditor: "agRichSelectCellEditor", editable: true,
          hide: (selfObj.selectedtype === "MySalesXCRM" || selfObj.selectedtype === "SalesXUsers") ? false : true, cellRenderer(params) {
            return params.data.doctab;
          },
          cellEditorParams: {
            cellHeight: 25,
            values: ["Expense Report", "Sales Report", "Company Documents", "Performance Reviews", "Miscellaneous"],
            formatValue: function (value) {
            }
            
          },

        },
        { field: 'documentdescription', headerName: 'Document Description', hide: (selfObj.selectedtype === "MySalesXCRM" || selfObj.selectedtype === "SalesXUsers") ? false : true },
        { field: 'DeActivatedUser', headerName: 'DeActivated By', hide: (selfObj.selectedtype === "Account" || selfObj.selectedtype === "Contact") ? true : false },
        {
          field: 'actions', headerName: 'Actions', hide: false, pinned: 'right', width: 80, cellRenderer(params) {
            var eDiv = document.createElement('div');
            if (params.data.isdeleted === 0) {
              eDiv.innerHTML = '<img src="../../../assets/img/trash.png" style="height:15px;width:30%"/>';
            } else {
              eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size: 14px;margin-left: 4%;color: grey;"></i>';
            }

            eDiv.addEventListener('click', function () {
              //selfObj.checkDocPermissionLinked(params.data);
              if (params.data.isdeleted === 0) {
                selfObj.checkDocPermissionLinked(params.data);
              } else {
                selfObj.UndoDocname(params.data);
              }
            });
            return eDiv;

          }
        },
      ];
    }

    
  }

  checkDocPermissionLinked(Data) {
    this.documenttypeList = [];
    this.deleteRowData = Data;
    this.customerService.GetAllDocumentByPermissionLevel(this.currentuser.id, Data.id).then((userdocument) => {
      //this.finaldocumentlist = userdocument;
      if (userdocument.length > 0) {
        this.DeletedDoc = Data;
        this.customerService.GetAllDocumentNamePermissionList(this.selectedtype, this.currentuser.id).then((document) => {
          document.forEach(doc => {
            this.documenttypeList.push({ "id": doc.id, "itemName": doc.documentname, "category": doc.permissionlevel });
          });
          this.documentNamesList = document;
        });
        this.ShowDeleteDocNameMatch = true;
        this.ShowDeleteDocNameMatchCss();
      } else {

        this.ShowDeleteDocNamePermission = true;
        this.ShowDeleteDocNamePermissionCss();
      }
    });

  }

  UndoDocname(Data) {
    this.deleteRowData = Data;
    this.ShowDeleteDocNamePermission = true;
    this.ShowDeleteDocNamePermissionCss();

  }

  ShowDeleteDocNameMatchCss() {
    setTimeout(function () {
      $('#ShowDeleteDocNameMatch .dialog').attr('style', 'width: 45%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 81%;overflow: auto;height: 81%;top: 11%;left: 53%;');
    }, 4);
  }

  ShowDeleteDocNamePermissionCss() {
    setTimeout(function () {
      $('#ShowDeleteDocNamePermission .dialog').attr('style', 'width: 30%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 21%;top:10%');
    }, 4);
  }


  getUserDocumentList(selYear, startrow, endrow, userids, startval, endval) {

    if (this.selectedtype === undefined) { this.selectedtype = 'Accounts & Contacts'; }
    else if (this.selectedtype === "MySalesXCRM") {
      this.selectedtype = this.selectedtype;
    }


    if (this.selectedtype === 'Accounts & Contacts' || this.selectedtype === "MySalesXCRM") {
      this.customerService.GetAllDocumentNamePermissionList(this.selectedtype, this.currentuser.id).then((document) => {
        document.forEach(document => {
          document.DateUploaded = this.datePipe.transform(document.DateUploaded, "MM-dd-yyyy");
        });

        this.userdocumentlist = document;
        this.userdocumentcount = this.userdocumentlist.length;
        if (this.userdocumentlist.length === 0) {
          $('.ag-overlay-no-rows-center').html('No Documents Found');
        }

      });
    }
   
  }

  onRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration(this.selectedtype.trim() + "DocManagement");
    this.saveConfigurationDM(this.pageSize, this.startDateVal, this.endDateVal);
  }
  columnVisibleDM(event) {
    this.saveConfigurationDM(this.pageSize, this.startDateVal, this.endDateVal);
  }
  dragStoppedDM(event) {
    this.saveConfigurationDM(this.pageSize, this.startDateVal, this.endDateVal);
  }
  columnResizedDM(event) {
    if (event.finished === true)
      this.saveConfigurationDM(this.pageSize, this.startDateVal, this.endDateVal);
  }
  saveConfigurationDM(pagesize, start, End) {
    let allCols: Column[] = this.gridOptionsDM.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize === "")
      config.maxlimit =1000;
    else
      config.maxlimit = Number(pagesize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    config.configureduserid = this.currentuser.id;
    config.resourceid = 56;
    config.startdate = start;
    config.enddate = End;
    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }

  saverowconfiguration(type) {
    var sortoorder = [];
    this.gridOptionsDM.api.forEachNode((node) => {
      sortoorder.push(node.data.id);
    });

    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i === a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentuser.id;
    this.leadsService.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });

    });

  }

  onSelectionChanged(event) {

    var rowCount = event.api.getSelectedNodes();
    this.documentlist = [];
    rowCount.forEach(ele => {
      let newdoc = new Document();
      newdoc.id = ele.data.id;
      newdoc.documentname = ele.data.documentname;
      newdoc.permissionlevel = ele.data.permissionlevel;
      newdoc.uploadedfilename = ele.data.uploadedfilename;
      newdoc.Size = ele.data.Size;
      newdoc.documenttype = ele.data.documenttype;
      newdoc.imagecontent = ele.data.imagecontent;
      newdoc.extension = ele.data.extension;
      newdoc.documentdescription = '';
      newdoc.accountid = 0;
      newdoc.type = ele.data.type;
      this.documentlist.push(newdoc);

    });

  }

  onAgGridReady(event) {
    
    var url = window.location.href;
    if (url.indexOf('contactsdashboard') > 1) {
      $('#buttonDiv').attr('style', 'margin-top: 0%;');
    }
    this.gridApi = event.api;
  }

  addCharge() {
    this.documentlist = [];
    let newdoc = new Document();
    newdoc.documentname = "";
    newdoc.permissionlevel = 0;
    newdoc.uploadedfilename = "";
    newdoc.Size = "";
    newdoc.documenttype = "";
    newdoc.imagecontent = "";
    newdoc.extension = "";
    newdoc.documentdescription = "";
    newdoc.accountid = 0;
    newdoc.type = "";
    this.documentlist.push(newdoc);
  }

  CancelDocuments() {
    this.documentlist = [];
  }
  SaveDocuments() {

    //let test: number = this.accountId;
    this.finaldocumentlist = [];
    let deleteDocuments: Document[] = [];

    this.documentlist.forEach(document => {
      let isValidate: boolean = false;
        if (document.documentname != null && document.documentname.trim() != "" && document.permissionlevel != null && document.permissionlevel != 0)
          isValidate = true;

      if (isValidate) {

        let newdoc = new Document();
        newdoc.id = document.id;
        newdoc.documentname = document.documentname;
        newdoc.uploadedby = this.currentuser.id;
        newdoc.uploadedfilename = document.uploadedfilename;
        newdoc.Size = document.Size;
        newdoc.documenttype = document.documenttype;
        newdoc.documentdescription = document.documentdescription;
        newdoc.permissionlevel = (this.selectedtype === "MySalesXCRM" || this.selectedtype === " MySalesXCRM " || this.selectedtype === "Accounts & Contacts") ? document.permissionlevel : document.documentnamepermissionid;
        newdoc.doctype = this.selectedtype;
        newdoc.imagecontent = document.imagecontent;
        newdoc.extension = document.extension;
        newdoc.accountid = 0;
        newdoc.type = (this.selectedtype === null) ? 'Account Or Contact' : (this.selectedtype === "SalesXUsers") ? 'MySalesXCRM' : this.selectedtype;
        
        this.finaldocumentlist.push(newdoc);

        deleteDocuments.push(document);
      }
    });

    if (this.finaldocumentlist.length > 0) {

        this.userservice.addDocumentNamePermission(this.finaldocumentlist).then(result => {
          this.toastr.success("Document names with permissions saved successfully.", "Document", { timeOut: 600 });
          //this.loaddocuments(this.selectedtype.trim(), this.currentYear, this.SelReportType, this.currentuser.id, this.startDateVal, this.endDateVal, this.pageSize);
          this.getUserDocumentList(this.currentYear, 1, this.pageSize, this.currentuser.id, this.startDateVal, this.endDateVal)
          if (deleteDocuments.length > 0) {
            deleteDocuments.forEach(document => {
              let deleteIndex = this.documentlist.indexOf(document);
              if (deleteIndex >= 0)
                this.documentlist.splice(deleteIndex, 1);
            });
          }
        });
    }
    else {
  //this.doc.uploadedby = this.currentuser.id;
        this.doc.lastediteduserId = this.currentuser.id;
        this.userservice.UpdatePermissionLevel(this.doc).then(result => {

          this.toastr.success("Document names with permissions Updated successfully.", "Document", { timeOut: 600 });
          //this.loaddocuments(this.selectedtype.trim(), this.currentYear, this.SelReportType, this.currentuser.id, this.startDateVal, this.endDateVal, this.pageSize);
          this.getUserDocumentList(this.currentYear, 1, this.pageSize, this.currentuser.id, this.startDateVal, this.endDateVal)
          if (deleteDocuments.length > 0) {
            deleteDocuments.forEach(document => {
              let deleteIndex = this.documentlist.indexOf(document);
              if (deleteIndex >= 0)
                this.documentlist.splice(deleteIndex, 1);
            });
          }
        });

    }
    var url = window.location.href;
    if (url.indexOf('contactsdashboard') > -1) {

      //document.getElementById('documentsinfo').style.marginTop = "9% !important";
    }

    
  }
  
}
