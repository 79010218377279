import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@aspnet/signalr';
import { Message } from '../models/message';
import { User } from 'ng-chat';
import { environment } from '../../environments/environment';
import { ReceiveMessage } from '../models/ReceiveMessage';
import { Subject } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ChatMessage } from '../models/chatMessage';
import { AppSettings } from '../app.settings';

@Injectable({
  providedIn: 'root'
})
export class SignalrClientService {
  userName: string;
  messenger = new Subject<ReceiveMessage>();
  onlineUsers = new Subject<User[]>();
  messageReceived = new EventEmitter<Message>();
  connectionEstablished = new EventEmitter<Boolean>();

  private connectionIsEstablished = false;
  private _hubConnection: HubConnection;
  private _pipelinerChatHubConnection: SignalR.Hub.Connection;
  private _pipelinerChatHubProxy: SignalR.Hub.Proxy;
  reqHeader: HttpHeaders = new HttpHeaders();
  constructor(private settings: AppSettings, private httpclient: HttpClient) {
    this.reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('key')
    });
    this.createConnection();
    this.registerOnServerEvents();

   // this.startConnection();
  }

  sendMessage(message: Message) {

    this._pipelinerChatHubProxy.invoke('NewPipelinerHubChatMessage', message);
    this.SendChatMessages(message);

  }
  SendChatMessages(message: Message): Promise<Message> {
    const promise = new Promise<Message>((resolve, reject) => {
      this.httpclient.post<Message>(this.settings.baseUrl + 'api/chats/SendChat/', message, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  private createConnection() {
    let self = this;
    self._pipelinerChatHubConnection = jQuery.hubConnection(self.settings.baseUrl);
    self._pipelinerChatHubConnection.qs = { 'bearer': localStorage.getItem('key') };
  
    self.initPipelinerChatHubProxy();
    self._pipelinerChatHubConnection.start()
      .then(function () {
      
        let messageItem = new Message();
        messageItem.clientuniqueid = "test18Apr2023";
        messageItem.type = "Info";
        messageItem.message = "Hello Hi";
        self.sendMessage(messageItem);
       
      })
      .fail(function (result) {
        console.log('Failed to connect to the Signalr hub. Error message is: ' + result);
      });
 
  }
  registerOnServerEvents() {
    this._pipelinerChatHubProxy.on("ReceiveMessage", (user, message) => {
      this.messenger.next({
        userName: user, message: message, isSender: false
      });
    });
  
  }
  private initPipelinerChatHubProxy() {   
    let self = this;    
    self._pipelinerChatHubProxy = self._pipelinerChatHubConnection.createHubProxy('pipelinerchathub');
    self._pipelinerChatHubProxy.on('addNewMessageToPage', (name, message) => {

    });
   
  }
 
  public join(groupName) {
    this._pipelinerChatHubProxy.invoke('JoinGroup', groupName)
      .then(_ => {
        alert("joined")
      });
  }
  public sendGroupMessage(newMessage: Message) {
    this._pipelinerChatHubProxy.invoke('SendMessage', newMessage)
      .then(_ =>
        this.SendChatMessages(newMessage)
    );
  }
  public leave(groupName) {
    this._pipelinerChatHubProxy.invoke('LeaveGroup', groupName)
      .then(_ => {
        alert("left")
      });
  }


  //private startConnection() {
  //  //let me = this;
  //  //await me._hubConnection
  //  //  .start()
  //  //  .then(() => {
  //  //    this.connectionIsEstablished = true;
  //  //    console.log('Hub connection started');
  //  //    this.connectionEstablished.emit(true);
  //  //  })
  //  //  .catch(err => {
  //  //    console.log('Error while establishing connection, retrying...');
  //  //    //setTimeout(function () { me.startConnection(); }, 5000);
  //  //  });
  //}

  //private registerOnServerEvents(): void {
  //  this._hubConnection.on('MessageReceived', (data: any) => {
  //    this.messageReceived.emit(data);
  //    console.log("this.messageReceived", this.messageReceived);
  //  });
  //}
  //userName: string;
  //connection: signalR.HubConnection;
  //messenger = new Subject<ReceiveMessage>();
  //onlineUsers = new Subject<User[]>();
  //constructor() { }
  //public sendMessage(message: string) {
  //  return this.connection.invoke("SendMessage","bhagya gowda" /*this.userName*/, message)
  //}
  //openConnection() {
  //    //this.connection = new signalR.HubConnectionBuilder()
  //    //.withUrl(`${environment.chatURL}?username=${this.userName}`)
  //    //.build();
  //    this.connection.start().then(res => {
  //    console.log("connected");
  //  });
  //  // add handler
  //   this.connection.on("OnlineUsers", (user: User[]) => {
  //    this.onlineUsers.next(user);
  //  });
  //  this.chatMessageHandler();
  //}
  //chatMessageHandler() {
  //  this.connection.on("ReceiveMessage", (user, message) => {
  //    this.messenger.next({
  //      userName: user, message: message, isSender: false
  //    });
  //  });
  //  this.connection.on("OnlineUsers", (user: User[]) => {
  //    //console.log("user", user);
  //    this.onlineUsers.next(user);
  //  });
  //}
}
