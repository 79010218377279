import { Injectable, ViewChild } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Accounts } from './../models/accounts';
import { ViewSalesinfo } from './../models/view-salesinfo';
import { Contacts } from './../models/contacts';
import { Opportunities } from './../models/opportunities';
import { Notes } from './../models/notes';
import { Dropdowntype, Dropdown, RevenueModel } from './../models/dropdowntype';
import { AppSettings } from '../app.settings';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Dashboardconfiguration } from './../models/dashboardconfiguration';
import { Salesranking } from '../models/salesranking';
import { Userquota } from '../models/userquota';
import { Dashboardconfig } from '../models/Dashboardconfig';
import { AdvancedsearchTable } from '../models/advancedsearch-table';
import { AdvancedSearch } from '../models/advancedsearch';
import { Accountlocations } from '../models/data-model';
import { QuickSearchModel } from '../models/QuickSearchmodel';
import { Campaign } from '../models/Campaign';
import { ProposalsModel } from '../models/Proposalsmodel';
import { Campaigninfo } from '../models/Campaigninfo';
import { ProjectsInfo } from '../models/ProjectsInfo';
import { OpportunityFields, OpportunityItems } from '../models/OpportunityLeadTypes';

@Injectable()
export class OpprtunitiesService {
  options: RequestOptions;
  authToken: any;
  headers: any;
  reqHeader: HttpHeaders = new HttpHeaders();
  constructor(private http: HttpClient, private settings: AppSettings, private httpclient: HttpClient) {
    this.reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('key')
    });
  
    //this.authToken = localStorage.getItem('key');
    //this.headers = new Headers();
    //this.headers.append('Authorization', `Bearer ${this.authToken}`);
    //this.options = new RequestOptions({ headers: this.headers });
  }

  addopprtunity(opprtunity: Opportunities): Promise<Opportunities> {

    const promise = new Promise<Opportunities>((resolve, reject) => {
      this.httpclient.post<Opportunities>(this.settings.baseUrl + "api/opportunities/SaveOpportunity", opprtunity, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  addopprtunityNotes(notes: Notes): Promise<Notes> {
    const promise = new Promise<Notes>((resolve, reject) => {


      this.httpclient.post<Notes>(this.settings.baseUrl + "api/opportunities/SaveOpportunityNotes", notes, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  GetStatusChange(id: number, status: string): Promise<Opportunities> {
    const promise = new Promise<Opportunities>((resolve, reject) => {
      this.httpclient.get<Opportunities>(this.settings.baseUrl + 'api/opportunities/UpdateOpportunityStatus/' + id + "/" + status, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetAllContactsCount(userdid: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/GetContcatsCount/' + userdid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetAllCampaignsdollerestimated(userdid: number, type: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/GetCampaignsdollerestimatedCount/' + userdid + '/' + type, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  GetAllCampaignRRestimated(userdid: number, type: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/GetCampaignsRRestimatedCount/' + userdid + '/' + type, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  GetAllCampaignRRestimatedAnnual(userdid: number, type: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/GetCampaignsRRAnualestimatedCount/' + userdid + '/' + type, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  GetAllinterestlevelnonecount(userdid: number, interestlevel: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/accounts/GetAllInterestLevelCount/' + userdid + "/" + interestlevel, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllAccountsBySearch(currentuserid: number, term: string, userid: string, selectedtype: string, startdate?: string, enddate?: string, startrow?: number, endrow?: number, contacttypes?: string, status?: number): Promise<Accounts[]> {
   const promise = new Promise<Accounts[]>((resolve, reject) => { 
      if (term == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        term = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
     this.httpclient.get<Accounts[]>(this.settings.baseUrl + 'api/opportunities/GetAllAccountbySearch/' + term + '/' + userid + '/' + selectedtype + '/' + startdate + '/' + enddate + '/' + startrow + '/' + endrow + '/' + contacttypes + '/' + status + '/' + currentuserid, { headers: this.reqHeader }).

        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllAccountsBySearchNew(currentuserid: number, term: string, userid: number, selectedtype: string, startdate?: string, enddate?: string, startrow?: number, endrow?: number, contacttypes?: string, status?: number): Promise<Accounts[]> {
    const promise = new Promise<Accounts[]>((resolve, reject) => {
      if (term == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        term = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<Accounts[]>(this.settings.baseUrl + 'api/opportunities/GetAllAccountbySearchNew/' + term + '/' + userid + '/' + selectedtype + '/' + startdate + '/' + enddate + '/' + startrow + '/' + endrow + '/' + contacttypes + '/' + status + '/' + currentuserid, { headers: this.reqHeader }).
      subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }


  public GetAllAccountByUserID(userid: number): Promise<Accounts[]> {
    const promise = new Promise<Accounts[]>((resolve, reject) => {
      this.httpclient.get<Accounts[]>(this.settings.baseUrl + 'api/opportunities/GetAllAccountByUserID/' + userid, { headers: this.reqHeader }).

        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }


  public GetAllAccountsByListOfIds(accountids:string): Promise<Accounts[]> {
    const promise = new Promise<Accounts[]>((resolve, reject) => {
      this.httpclient.get<Accounts[]>(this.settings.baseUrl + 'api/opportunities/GetAllAccountsByListOfIds/' + accountids, { headers: this.reqHeader } ).

        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  
  public GetAlllastActivity(currentuserid: string, term: string,type:string): Promise<Accounts[]> {
    const promise = new Promise<Accounts[]>((resolve, reject) => {
      if (term == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        term = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<Accounts[]>(this.settings.baseUrl + 'api/opportunities/GetAlllastActivityAccounts/' + term + '/' + currentuserid + '/' + type, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAlllastActivityContacts(currentuserid: string, term: string): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      if (term == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        term = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/opportunities/GetAlllastActivityContacts/' + term + '/' + currentuserid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllCustomersSales(loggeinuserid: string,startdate:string,enddate:string): Promise<Accounts[]> {
    const promise = new Promise<Accounts[]>((resolve, reject) => {
      this.httpclient.get<Accounts[]>(this.settings.baseUrl + 'api/opportunities/GetAllCustomerOpenOpportunities/' + loggeinuserid + '/' + startdate + '/' + enddate, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetOpenOpportunitiestotal(loggeinuserid: number,contactorAccountId:number): Promise<Accounts[]> {
    const promise = new Promise<Accounts[]>((resolve, reject) => {
      this.httpclient.get<Accounts[]>(this.settings.baseUrl + 'api/opportunities/GetOpenOpportunitiesTotal/' + loggeinuserid + '/' + contactorAccountId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllViewSalesInfo(loggeinuserid: string, monthname: string,year:string): Promise<ViewSalesinfo> {
    const promise = new Promise<ViewSalesinfo>((resolve, reject) => {
      this.httpclient.get<ViewSalesinfo>(this.settings.baseUrl + 'api/opportunities/Getviewsalesinfo/' + loggeinuserid + '/' + monthname + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetexpensetotalprofitInfo(loggeinuserid: string, mon: string, year: number): Promise<Campaigninfo> {
    
    const promise = new Promise<Campaigninfo>((resolve, reject) => {
      this.httpclient.get<Campaigninfo>(this.settings.baseUrl + 'api/opportunities/GetCampaingnexpensetotalprofitInfo/' + loggeinuserid + '/' + mon + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetProjectDashboardInfo(loggeinuserid: string, mon: string, year: number): Promise<ProjectsInfo> {
    const promise = new Promise<ProjectsInfo>((resolve, reject) => {
      this.httpclient.get<ProjectsInfo>(this.settings.baseUrl + 'api/opportunities/GetProjectsInfo/' + loggeinuserid + '/' + mon + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetMonthlyMeetingCOmpleted(loggeinuserid: string, monthname: string, year: string): Promise<ViewSalesinfo> {
    const promise = new Promise<ViewSalesinfo>((resolve, reject) => {
      this.httpclient.get<ViewSalesinfo>(this.settings.baseUrl + 'api/opportunities/GetviewMeetingCompleted/' + loggeinuserid + '/' + monthname + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetMonthlyToDoCompleted(loggeinuserid: string, monthname: string, year: string): Promise<ViewSalesinfo> {
    const promise = new Promise<ViewSalesinfo>((resolve, reject) => {
      this.httpclient.get<ViewSalesinfo>(this.settings.baseUrl + 'api/opportunities/GetviewToDoCompleted/' + loggeinuserid + '/' + monthname + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetMonthlyNewTicketsAdded(loggeinuserid: string, monthname: string, year: string): Promise<ViewSalesinfo> {
    const promise = new Promise<ViewSalesinfo>((resolve, reject) => {
      this.httpclient.get<ViewSalesinfo>(this.settings.baseUrl + 'api/opportunities/GetviewNewTickets/' + loggeinuserid + '/' + monthname + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetMonthlyClosedTickets(loggeinuserid: string, monthname: string, year: string): Promise<ViewSalesinfo> {
    const promise = new Promise<ViewSalesinfo>((resolve, reject) => {
      this.httpclient.get<ViewSalesinfo>(this.settings.baseUrl + 'api/opportunities/GetviewClosedTickets/' + loggeinuserid + '/' + monthname + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  
  
  public GetSalesWOn(loggeinuserid: string, monthname: string, year: string): Promise<ViewSalesinfo> {
    const promise = new Promise<ViewSalesinfo>((resolve, reject) => {
      this.httpclient.get<ViewSalesinfo>(this.settings.baseUrl + 'api/opportunities/GetviewSalesWOn/' + loggeinuserid + '/' + monthname + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetSalesRR(loggeinuserid: string, monthname: string,year:string): Promise<ViewSalesinfo> {
    const promise = new Promise<ViewSalesinfo>((resolve, reject) => {
      this.httpclient.get<ViewSalesinfo>(this.settings.baseUrl + 'api/opportunities/GetviewSalesRR/' + loggeinuserid + '/' + monthname + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllViewLeadsConvertedInfo(loggeinuserid: string, monthname: string,year:string): Promise<ViewSalesinfo> {
    const promise = new Promise<ViewSalesinfo>((resolve, reject) => {
      this.httpclient.get<ViewSalesinfo>(this.settings.baseUrl + 'api/opportunities/GetLeadsinfo/' + loggeinuserid + '/' + monthname + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetviewmonthlysalesRanking(loggeinuserid: string, monthname: string, year:string): Promise<Salesranking[]> {
    const promise = new Promise<Salesranking[]>((resolve, reject) => {
      this.httpclient.get<Salesranking[]>(this.settings.baseUrl + 'api/opportunities/GetSalesRankingData/' + loggeinuserid + '/' + monthname + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetViewMonthlyCallScheduledByMonth(loggeinuserid: string, monthname: string,year:string): Promise<ViewSalesinfo> {
    const promise = new Promise<ViewSalesinfo>((resolve, reject) => {
      this.httpclient.get<ViewSalesinfo>(this.settings.baseUrl + 'api/opportunities/GetMonthlyCallScheduled/' + loggeinuserid + '/' + monthname + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetViewMonthlyAccountAddedByMonth(loggeinuserid: string, monthname: string, year:string): Promise<ViewSalesinfo> {
    const promise = new Promise<ViewSalesinfo>((resolve, reject) => {
      this.httpclient.get<ViewSalesinfo>(this.settings.baseUrl + 'api/opportunities/GetMonthlyAccountAdded/' + loggeinuserid + '/' + monthname + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetViewPastDueActivityByMonth(loggeinuserid: string, monthname: string, year: string): Promise<ViewSalesinfo> {
    const promise = new Promise<ViewSalesinfo>((resolve, reject) => {
      this.httpclient.get<ViewSalesinfo>(this.settings.baseUrl + 'api/opportunities/GetMonthlyPastDueActivity/' + loggeinuserid + '/' + monthname + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetActivitysummaryData(loggeinuserid: string, startdate: string, enddate: string): Promise<Salesranking[]> {
    const promise = new Promise<Salesranking[]>((resolve, reject) => {
      this.httpclient.get<Salesranking[]>(this.settings.baseUrl + 'api/opportunities/GetNBDActivityData/' + loggeinuserid + '/' + startdate + '/' + enddate, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetClosedTicketsRanking(loggeinuserid: string, value: number, startdate: string, enddate: string): Promise<Salesranking[]> {
    const promise = new Promise<Salesranking[]>((resolve, reject) => {
      this.httpclient.get<Salesranking[]>(this.settings.baseUrl + 'api/opportunities/GetClosedTicketrankingData/' + loggeinuserid + '/' + value + '/' + startdate + '/' + enddate, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAccountsData(loggeinuserid: string, startdate: string, enddate: string): Promise<Accounts[]> {
    const promise = new Promise<Accounts[]>((resolve, reject) => {
      this.httpclient.get<Accounts[]>(this.settings.baseUrl + 'api/opportunities/GetDashboardAccountData/' + loggeinuserid + '/' + startdate + '/' + enddate, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetDashBoardAccess(loggeinuserid: string): Promise<Dashboardconfig[]> {
    const promise = new Promise<Dashboardconfig[]>((resolve, reject) => {
      this.httpclient.get<Dashboardconfig[]>(this.settings.baseUrl + 'api/opportunities/GetDashboardAccessData/' + loggeinuserid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetYearToSalesDataByMonth(userid: string,year:string): Promise<Salesranking[]> {
     
    const promise = new Promise<Salesranking[]>((resolve, reject) => {
      this.httpclient.get<Salesranking[]>(this.settings.baseUrl + 'api/opportunities/GetYeartoSalesDataByMonth/' + userid + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetYearToDateActivity(userid: string,year:number): Promise<Userquota[]> {
    const promise = new Promise<Userquota[]>((resolve, reject) => {
      this.httpclient.get<Userquota[]>(this.settings.baseUrl + 'api/opportunities/GetYeartoDateActivity/' + userid + '/' + year, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  

  public GetAllContactsCustomersBySearch(currentuserid:number,term: string, userid: string, startdate?: string, enddate?: string, startrow?: number, endrow?: string, Contactvales?:string): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      if (term == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        term = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/opportunities/GetAllContactCustomeragination/' + term + '/' + userid + '/' + startdate + '/' + enddate + '/' + startrow + '/' + endrow + '/' + Contactvales + '/' + currentuserid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllCustomerContactsLK(currentuserid: number): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {     
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/opportunities/GetAllCustomersLK/' + currentuserid , { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllContactsBySearch(term: string, userid: string, subtype: string, disabledstatus?: number, startdate?: string, enddate?: string): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {

       
      if (term == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        term = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/opportunities/GetAllContactsbySearch/' + term + '/' + userid + '/' + subtype + '/' + disabledstatus + '/' + startdate + '/' + enddate, { headers: this.reqHeader }).
        subscribe(res => {
           
          resolve(res);
        });
    });
    return promise;
  }

  public GetContacts(searchterm: string, userid: string): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      if (searchterm == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        searchterm = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/customers/GetAllContactList/' + searchterm + '/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetContactsForEmail(searchterm: string, userid: string): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      if (searchterm == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        searchterm = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/customers/GetAllContactListForEmail/' + searchterm + '/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllContactsbySearchPagination(loggedinuserId:number,term: string, userid: string, subtype: string, disabledstatus?: number, startdate?: string, enddate?: string,  contactselectedval?: string): Promise<Contacts[]> {
     
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      if (term == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        term = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/opportunities/GetAllContactsbySearchPagination/' + loggedinuserId + '/' + term + '/' + userid + '/' + subtype + '/' + disabledstatus + '/' + startdate + '/' + enddate + '/' + contactselectedval, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllsaasContacts(loggedinuserId: number,selType:string): Promise<any[]> {

    const promise = new Promise<any[]>((resolve, reject) => {
     
      this.httpclient.get<any[]>(this.settings.baseUrl + 'api/opportunities/GetAllStaffDateContacts/' + loggedinuserId + '/' + selType, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllContactsbySearchPaginationNew(loggedinuserId: number, term: string, userid: string, subtype: string, disabledstatus?: number, startdate?: string, enddate?: string, contactselectedval?: string): Promise<any[]> {
     
    const promise = new Promise<any[]>((resolve, reject) => {
      if (term == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        term = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<any[]>(this.settings.baseUrl + 'api/opportunities/GetAllContactsbySearchPaginationNew/' + loggedinuserId + '/' + term + '/' + userid + '/' + subtype + '/' + disabledstatus + '/' + startdate + '/' + enddate + '/' + contactselectedval, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  

  public GetAllContactsAndCustomerBySearch(term: string, userid: string, startdate?: string, enddate?: string, searchtype?: string, includecustomer?: boolean): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      if (term == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        term = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/opportunities/GetAllContactsAndCustomerBySearch/' + term + '/' + userid + '/' + startdate + '/' + enddate + '/' + searchtype + '/' + includecustomer, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllContactsAndCustomerByQuickSearch(quickSearchModel: QuickSearchModel): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      if (quickSearchModel.searchterm == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        quickSearchModel.searchterm = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.post<Contacts[]>(this.settings.baseUrl + 'api/opportunities/GetAllContactsAndCustomerByQuickSearch/', quickSearchModel, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public runQuicksearchQuery(query): Promise<AdvancedsearchTable[]> {
     
    const promise = new Promise<AdvancedsearchTable[]>((resolve, reject) => {
      this.httpclient.post<AdvancedsearchTable[]>(this.settings.baseUrl + 'api/advancedsearch/FireQuery/', query, { headers: this.reqHeader }).
      subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAdvancesSearchQueryByData(queryId: number): Promise<AdvancedsearchTable[]> {
    const promise = new Promise<AdvancedsearchTable[]>((resolve, reject) => {
      this.httpclient.get<AdvancedsearchTable[]>(this.settings.baseUrl + 'api/opportunities/GetAdvancedSearchById/' + queryId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
 
  GetQueryById(queryid: number): Promise<AdvancedSearch> {
    const promise = new Promise<AdvancedSearch>((resolve, reject) => {
      this.httpclient.get<AdvancedSearch>(this.settings.baseUrl + 'api/accounts/GetAdvancedQueryById/' + queryid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  
  public GetContactsColumnDefs() {
    const promise = new Promise<string[]>((resolve, reject) => {
      this.httpclient.get<string[]>(this.settings.baseUrl + 'api/opportunities/GetContactsColumnDefs', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllContacts(): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/opportunities/GetAllContactbySearch/', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  GetByAccountId(id: number): Promise<Accounts> {
     
    const promise = new Promise<Accounts>((resolve, reject) => {
      this.httpclient.get<Accounts>(this.settings.baseUrl + 'api/accounts/GetByAccountId/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  CheckPhoneWebsite(Phone: string, Website: string): Promise<Accounts> {
     
    const promise = new Promise<Accounts>((resolve, reject) => {
      this.httpclient.get<Accounts>(this.settings.baseUrl + 'api/accounts/CheckPhoneWebsite/' + Phone + '/' + Website, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetlocationsByAccountId(id: number): Promise<Accountlocations> {
    const promise = new Promise<Accountlocations>((resolve, reject) => {
      this.httpclient.get<Accountlocations>(this.settings.baseUrl + 'api/accounts/GetlocationsByAccountId/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetContactById(id: number): Promise<Contacts> {
    const promise = new Promise<Contacts>((resolve, reject) => {
      this.httpclient.get<Contacts>(this.settings.baseUrl + 'api/accounts/GetContactById/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  GetByOpportunityId(id: number): Promise<Opportunities> {
    const promise = new Promise<Opportunities>((resolve, reject) => {
      this.httpclient.get<Opportunities>(this.settings.baseUrl + 'api/opportunities/GetByOpportunityId/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetDollerRRvales(opportunityids: string): Promise<Opportunities> {
    const promise = new Promise<Opportunities>((resolve, reject) => {
      this.httpclient.get<Opportunities>(this.settings.baseUrl + 'api/opportunities/GetSelectedRRDollers/' + opportunityids, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllOpportunitiesList(term: string, userid: string, startdate?: string, enddate?: string, searchval?: string,currentuserid?:number,ispended?:boolean): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      if (searchval == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        searchval = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<any>(this.settings.baseUrl + 'api/opportunities/GetAllOpportunities/' + searchval + '/' + userid + '/' + startdate + '/' + enddate + '/' + term + '/' + currentuserid + '/' + ispended, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }
  public GetAllOpportunitiesListByAccountorContact(accountorcontactid: number, source: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/opportunities/GetAllOpportunitiesByAccount/' + accountorcontactid + '/' + source, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }
  public GetOpportunitiesListByOppIds(oppIds: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/campaign/GetOpportunitiesListByOppIds/' + oppIds, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }

  public GetAllOpprtunitiesNotesById(OpId: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/opportunities/GetAllNotesByOpprtunitiesId/' + OpId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }

  public GetSpecialityList(category: string,loggedinuserid:number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/customers/GetAlldropdownsByCategory/' + category + '/' + loggedinuserid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllItems(): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/opportunities/GetAllOpportunityItems/', { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetTaskStatus(category: string,loggedinuserId:number): Promise<Dropdowntype[]> {
    const promise = new Promise<Dropdowntype[]>((resolve, reject) => {
      this.httpclient.get<Dropdowntype[]>(this.settings.baseUrl + 'api/customers/GetAlldropdownsByCategory/' + category + '/' + loggedinuserId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public saveDashboardconfiguration(dashboardConfiguration: Dashboardconfiguration): Promise<Dashboardconfiguration> {
    const promise = new Promise<Dashboardconfiguration>((resolve, reject) => {
      this.httpclient.post<Dashboardconfiguration>(this.settings.baseUrl + 'api/opportunities/SaveDashboardconfiguration', dashboardConfiguration, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public getDashboardConfigurationByUserId(userId: number, resourceid: number): Promise<Dashboardconfiguration> {
    const promise = new Promise<Dashboardconfiguration>((resolve, reject) => {
      this.httpclient.get<Dashboardconfiguration>(this.settings.baseUrl + 'api/opportunities/GetDashboardConfigurationByUserId/' + userId + '/' + resourceid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });

    return promise;
  }

 

  public saveCampaign(campaign: Campaign): Promise<Campaign> {

    const promise = new Promise<Campaign>((resolve, reject) => {
      this.httpclient.post<Campaign>(this.settings.baseUrl + 'api/campaign/SaveCampaign', campaign, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }


  public GetAllProposalsByOppID(OppIDs: string): Promise<ProposalsModel[]> {
    const promise = new Promise<ProposalsModel[]>((resolve, reject) => {
      this.httpclient.get<ProposalsModel[]>(this.settings.baseUrl + "api/campaign/GetAllProposalsByOppID/" + OppIDs, { headers: this.reqHeader } 
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAllCampaigns(loggedinuserid: string): Promise<Campaign[]> {
    const promise = new Promise<Campaign[]>((resolve, reject) => {
      this.httpclient.get<Campaign[]>(this.settings.baseUrl + 'api/campaign/GetAllCampaigns/' + loggedinuserid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetContactsByContactIds(ContactIds: string): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/campaign/GetContactsByContactIds/' + ContactIds, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }



  //public updateCampaignCosts(campaignCosts: CampaignCosts): Promise<number> {
  //  const promise = new Promise<number>((resolve, reject) => {
  //    this.httpclient.post<number>(this.settings.baseUrl + 'api/campaign/UpdateCampaignCosts', campaignCosts
  //    ).subscribe(res => {
  //      resolve(res);
  //    });
  //  });
  //  return promise;
  //}

  

  ApproveselectedOpportunity(approveduserid: number, opportunityid: number, approveddate: string, dollers: string, RR: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/opportunities/apporveopportunitybyuser/' + approveduserid + "/" + opportunityid + '/' + approveddate + '/' + dollers + '/' + RR, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  deleteopportunity(opportunityid: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/opportunities/deleteopportunitybyuser/' + opportunityid, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public ForecastData(loggeinuserid: string, StartDate: string, EndDate: string, isPended: boolean): Promise<RevenueModel> {
    const promise = new Promise<RevenueModel>((resolve, reject) => {
      this.httpclient.get<RevenueModel>(this.settings.baseUrl + 'api/opportunities/ForecastData/' + loggeinuserid + '/' + StartDate + '/' + EndDate + '/' + isPended, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetOpportunitiesForForecast(userids: string, type: string, startDate: string, enddate: string, isPended: boolean): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/opportunities/GetOpportunitiesForForecast/' + userids + '/' + type + '/' + startDate + '/' + enddate + '/' + isPended, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }

  public GetAllOpportunitiesByECD(term: string, userid: string, startdate?: string, enddate?: string, searchval?: string, currentuserid?: number): Promise<any> {

    const promise = new Promise<any>((resolve, reject) => {
      if (searchval == "SearchCriteria" && localStorage.getItem('SearchCriteria') != null) {
        searchval = localStorage.getItem('SearchCriteria');
        localStorage.setItem('SearchCriteria', null);
      }
      this.httpclient.get<any>(this.settings.baseUrl + 'api/opportunities/GetAllOpportunitiesByECD/' + searchval + '/' + userid + '/' + startdate + '/' + enddate + '/' + term + '/' + currentuserid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
          reject((ress) => {
            alert(ress);
          });
        });
    });
    return promise;
  }

  public GetOpportunityFields(id: number): Promise<OpportunityFields[]> {
    const promise = new Promise<OpportunityFields[]>((resolve, reject) => {
      this.httpclient.get<OpportunityFields[]>(this.settings.baseUrl + 'api/opportunities/GetOpportunityFields/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }


  public SaveOpportunityFields(opprtunity: OpportunityFields): Promise<OpportunityFields> {

    const promise = new Promise<OpportunityFields>((resolve, reject) => {
      this.httpclient.post<OpportunityFields>(this.settings.baseUrl + "api/opportunities/SaveOpportunityField", opprtunity, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetFieldItems(fieldId: number,FieldName:string, userId: number): Promise<OpportunityItems[]> {
    const promise = new Promise<OpportunityItems[]>((resolve, reject) => {
      this.httpclient.get<OpportunityItems[]>(this.settings.baseUrl + 'api/opportunities/GetOppPickListData/' + fieldId + '/' + FieldName + '/' + userId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  SaveOppPickList(PickList: OpportunityItems): Promise<OpportunityItems> {
    const promise = new Promise<OpportunityItems>((resolve, reject) => {
      this.httpclient.post<OpportunityItems>(this.settings.baseUrl + 'api/opportunities/SaveOppPickList', PickList, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);

      });
    });
    return promise;
  }
}
