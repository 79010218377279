export class PickListModel {
  public id: number;
  public FieldID: number;
  public isdeleted: number;
  public Sortorder: number;
  public itemName: string;
  public Category: string;
}
export class ProjectPickListModel {
  public DataId: number;
  public ProjectFieldId: number;
  public isdeleted: number;
  public SortOrder: number;
  public itemName: string;
  public Category: string;
}
