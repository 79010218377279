import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  RequestOptions } from '@angular/http';
import { ColumnNames } from './../models/column-names';
import { RunQuery } from './../models/run-query';
import { Dropdowntype } from './../models/dropdowntype';
import { AppSettings } from '../app.settings';
import { Notes } from "./../models/notes";
//import { HttpClient } from '@angular/common/http';

@Injectable()
export class AdvancedsearchService {
  options: RequestOptions;
  authToken: any;
  headers: any;
  reqHeader: HttpHeaders = new HttpHeaders();
  constructor(private httpclient: HttpClient, private http: HttpClient, private settings: AppSettings) {
    this.reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('key')
    });
 
  }

  GetAllColumnNames(tblName: string): Promise<ColumnNames[]> {
    const promise = new Promise<ColumnNames[]>((resolve, reject) => {
      this.httpclient.get<ColumnNames[]>(this.settings.baseUrl + 'api/accounts/GetAllColumns/' + tblName, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  closeRunQuery(runquery: RunQuery): Promise<RunQuery> {   
    const promise = new Promise<RunQuery>((resolve, reject) => {
      this.httpclient.post<RunQuery>(this.settings.baseUrl + 'api/advancedsearch/CloseOpenQueries', runquery, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  saveRunQuery(runquery: RunQuery): Promise<RunQuery> {
    const promise = new Promise<RunQuery>((resolve, reject) => {
      this.httpclient.post<RunQuery>(this.settings.baseUrl + 'api/advancedsearch/SaveRunQueries', runquery, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  GetAllRunQueries(currentuserId: number): Promise<RunQuery[]> {
    const promise = new Promise<RunQuery[]>((resolve, reject) => {
      this.httpclient.get<RunQuery[]>(this.settings.baseUrl + 'api/advancedsearch/GetAllQueries/' + currentuserId, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
}
