import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Document } from '../../models/document';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import { CustomerService } from '../../services/customer.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { GridOptions, ColumnUtils, ColumnApi, RowDragEndEvent } from "ag-grid/main";
import { DatePipe } from '@angular/common';
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../application.component';
import * as moment from 'moment'
import { PageComponent } from '../page/page.component';
import { Router, ActivatedRoute } from '@angular/router';
import { EditdeleteComponent } from '../editdelete/editdelete.component';
import { Column } from 'ag-grid';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { AppSettings } from 'src/app/app.settings';
import { SystemRowConfiguration } from 'src/app/models/system-row-configuration';
import { LeadsService } from 'src/app/services/leads.service';
import { DocumentHistory } from '../../models/DocumentHistory';
declare var $: any;
@Component({
  selector: 'documentmanagement',
  templateUrl: './documentmanagement.component.html',
  styleUrls: ['./documentmanagement.component.css']
})
export class DocumentmanagementComponent implements OnInit {
  @Input()
  accountId: number = 0;
  @Input()
  isUploadDivVisible: boolean = false;
  @Input()
  documentview: boolean = false;
  @Input()
  isDocumentManagement: boolean = false;
  @Input() selectedtype: string;
  @Input() FilterType: string;
  documentDivHeight: number = 100;
  fromdocumentid: number;
  todocumentid: number;
  calanderonoff: boolean = false; pendingDocumentCount = 0;

  EditDiv: boolean = false;
  selectedtodocumentname: string;
  uploadDivHeight: number = screen.height * 0.6111;
  gridhgtforAccView: number = screen.height * 0.35555;
  documentlist: Document[] = [];
  finaldocumentlist: Document[] = [];
  doc: Document = new Document();
  docHistory: DocumentHistory[] = [];
  permissionlevellist: Dropdowntype[] = [];
  pageSize: string = "1000";
  currentuser: any;
  tablehgt = 145;
  @Input()
  accountstatisticBox: string = 'Open';
  @Input()
  parentPage;
  selecteddocumentName: string;
  userdocumentlist: any[] = [];
  Originaluserdocumentlist: any[] = [];
  userpendingdocumentlist: any[] = [];
  datePipe: DatePipe = new DatePipe("en-US");
  currentYear = new Date().getFullYear();
  public gridOptions: GridOptions;
  public gridOptionsDM: GridOptions;
  public documentscolumnDefs: any[];
  public DocumentNamecolumnDefs: any[];
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal === undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal === undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");
  finalitems = ""; dropdownSetting = {};
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal === undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal === undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  selectedItems = [];
  dropdownList = [];
  isPdfOpen = false;
  isselectedtransfered = false;
  documentDialog = false;
  GridLoad = false;
  ShowPDF = false;
  pdfDiv1 = false;
  isDocumentTransfer = false;
  documentNamesList: any[] = [];
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  documenttypeList = [];
  SelReportType: string = '496';
  isEditDocument = false;
  currentPage: number = 1;
  startrow: number = 1;
  noofRows: number = 1;
  totalnumberofrows: number = 1;
  totalnumberofPages: number = 1;
  public context;
  public frameworkComponents;
  docTabList: Dropdowntype[] = [];
  conList: Dropdowntype[] = [];
  colSelections: string;
  Ids = [];
  FieldsEdited = [];
  DocumenttypeList: Dropdowntype[] = [];
  public sideBar;
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration();
  DeleteConfirmationPopup = false;
  deleteRowData: any;
  contactStatus: any;
  selectedUserIds: any;
  private gridApi;
  documentindex: number = 0;
  PreviewDocument: Document;
  userdocumentcount: number = 0;
  ShowDeleteDocNamePermission: boolean = false;
  ShowDeleteDocNameMatch: boolean = false;
  DeletedDoc: any; ReplaceItem = [];
  DocViewAccessDenied: boolean = false;
  selectedDocType: string;
  constructor(private leadsService: LeadsService, private router: Router, public applicationComponent: ApplicationComponent, private settings: AppSettings, private customerService: CustomerService, private userservice: UserService, private toastr: ToastrService, private paginationcop: PageComponent, private opprtunityservice: OpprtunitiesService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  checkEnable() {
    let isEnable: boolean = false;

    if (!this.isDocumentManagement) {
      isEnable = this.documentlist.find(x => (x.documentnamepermissionid != undefined && x.documentnamepermissionid != null && x.documentnamepermissionid != 0)) != null;
    } else {
      isEnable = this.documentlist.find(x => ((x.documentname != null && x.documentname.trim() != '') && (x.permissionlevel != null && x.permissionlevel != 0))) != null;
    }
    $('table td#myid').find('input:first').focus()
    return isEnable;
  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: false, toolPanelSuppressPivotMode: false, toolPanelSuppressValues: false, rowSelection: 'Single'
    };

    this.gridOptionsDM = <GridOptions>{
      enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: false, toolPanelSuppressPivotMode: false, rowSelection: 'single',
      toolPanelSuppressValues: false, enableCellChangeFlash: true, enableMultiRowDragging: true, rowDrag: true

    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    if (this.isDocumentManagement) {
      this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 56).then((config) => {

        if (config != null && config.maxlimit != null && config.maxlimit > 0) {
          this.pageSize = config.maxlimit.toString();
        }
        columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
        this.colSelections = columnSelections;
        this.createColumnDefs(this.colSelections);

      });
    }
    else {
      this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 59).then((config) => {

        if (config != null && config.maxlimit != null && config.maxlimit > 0) {
          this.pageSize = config.maxlimit.toString();
        }
        columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
        this.colSelections = columnSelections;
        this.createColumnDefs(this.colSelections);

      });
    }

    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.gridOptionsDM.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeleteComponent,
    };

    var url = window.location.href;
    if (url.indexOf('contactsdashboard') > 1) {
      $('#buttonDiv').attr('style', 'margin-top: 0%;');
      $('#documentManagementDiv').attr('style', 'height:83%');
    }
    if (url.indexOf('quick-view') > 1) {
      $('#documentManagementDiv').attr('style', 'height:88%');
    }
    if (url.indexOf('configurationdashboard') > 1) {

      this.tablehgt = 517;
    }

  }
  createColumnDefs(columnSelections: string) {

    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');
      this.documentscolumnDefs = [];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');

        if (fieldHeaderVisibleWidthArray[0] === "#") {
          this.documentscolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] === "actions") {
          this.documentscolumnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: fieldHeaderVisibleWidthArray[2] === "true" ? false : true, pinned: 'right', width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else {
          this.documentscolumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] === "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }


      }
    } else {
      let selfObj = this;
      this.documentscolumnDefs = [

        {
          field: '#',
          headerName: '#',
          width: 70,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: (this.isDocumentManagement) ? true : false,
        },
        { field: 'DateUploaded', headerName: 'Date Created', hide: false },
        { field: 'documentname', headerName: 'Document Name', hide: false, editable: this.isDocumentManagement ? true : false },
        { field: 'documentdescription', headerName: 'Document Description', hide: (selfObj.selectedtype === "MySalesXCRM" || selfObj.selectedtype === "SalesXUsers") ? false : true },
        {
          field: 'doctab', headerName: 'Document Tab', cellEditor: "agRichSelectCellEditor", editable: true,
          hide: (selfObj.selectedtype === "MySalesXCRM" || selfObj.selectedtype === "SalesXUsers") ? false : true, cellRenderer(params) {
            return params.data.doctab;
          },
          cellEditorParams: {
            cellHeight: 25,
            values: ["Expense Report", "Sales Report", "Company Documents", "Performance Reviews", "Miscellaneous"],
            formatValue: function (value) {
            }
          },

        },
        { field: 'UploadedBy', headerName: 'Saved By', hide: false },
        {
          field: 'PermissionlevelName', headerName: 'Permission to View', hide: false, editable: true,
          cellEditor: "agRichSelectCellEditor",
          cellEditorParams: function (params) {
            return {
              values: Array.prototype.map.call(selfObj.permissionlevellist, s => s.itemName),
              formatValue: function (value) {
              }
            };
          }, cellRenderer(params) {
            return params.data.PermissionlevelName;
          }
        },

        { field: 'stat', headerName: 'Status', cellRenderer: (params) => {
          if (params.data.stat == '108') {
            return 'Open';
          }
          else if (params.data.stat == '109') {
            return 'Display Won';
          } else if (params.data.stat == '110') {
            return 'Display Lost';
          } else if (params.data.stat == '111') {
            return 'Display Pending';
          }
        }, hide: this.isDocumentManagement ? true : false },
        { field: 'Size', headerName: 'Size', hide: this.isDocumentManagement ? true : false },
        
        {
          field: 'documenttype', width: 120, headerName: 'Document Type', hide: this.isDocumentManagement ? true : false, cellRenderer(params) {
            let usrPermissionLevel = selfObj.currentuser.permissionlevelid;
            let docPermissionLevel = params.data.permissionlevel;
            var eDiv = document.createElement('div');
            if (params.data.extension.toLowerCase() === "txt") {
              eDiv.innerHTML = '<button class="btn-simple btn-sm"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>';
            } else if (params.data.extension.toLowerCase() === "pdf") {
              eDiv.innerHTML = '<button class="btn-simple btn-sm"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></button>';
            } else if (params.data.extension.toLowerCase() === "doc" || params.data.extension.toLowerCase() === "docx") {
              eDiv.innerHTML = '<button class="btn-simple btn-sm"><i class="fa fa-file-word-o" aria-hidden="true"></i></button>';
            } else if (params.data.extension.toLowerCase() === "jpg" || params.data.extension.toLowerCase() === "jpeg" || params.data.extension.toLowerCase() === "png") {
              eDiv.innerHTML = '<button class="btn-simple btn-sm"><i class="fa fa-file-image-o" aria-hidden="true"></i></button>';
            } else {
              eDiv.innerHTML = '<button class="btn-simple btn-sm"><i class="fa fa-file-o" aria-hidden="true"></i></button>';
            }

            var eButton = eDiv.querySelectorAll('.btn-simple')[0];
            eButton.addEventListener('click', function () {
              selfObj.PreviewDocument = params.data;
              selfObj.documentindex = params.rowIndex;
              if (selfObj.selectedtype === "Account" || selfObj.selectedtype === "Contact") {
                if (Number(params.data.permissionlevel) <= selfObj.currentuser.permissionlevelid) {
                  selfObj.openfile(params.data.id, params.data.fileName, params.data.documenttype);

                } else {
                  selfObj.DocViewAccessDeniedCss();
                }

              }
              else if (selfObj.selectedtype === "MySalesXCRM" || selfObj.selectedtype === "SalesXUsers") {
                if (params.data.DocSavedFrom === 'Proposal') {
                  var val = selfObj.settings.baseUrl + "Proposals/" + params.data.fileName + ".pdf";
                  selfObj.onDocumentOpen(val);
                } else {
                  if (Number(params.data.permissionlevel) <= selfObj.currentuser.userpermissionlevelid) {

                    selfObj.openfile(params.data.id, params.data.fileName, params.data.documenttype);
                  } else {
                    selfObj.DocViewAccessDeniedCss();
                  }
                }
              }
              else {
                //alert("You don't have permission to view this document.");
                selfObj.DocViewAccessDeniedCss();
              }
            });
            return eDiv;
          }
        },
        { field: 'DeActivatedUser', headerName: 'DeActivated By', hide: (selfObj.selectedtype === "Account" || selfObj.selectedtype === "Contact") ? true : false },

        { field: 'docsavedTo', headerName: 'Document Saved To', hide: (selfObj.selectedtype === "Accounts & Contacts" || selfObj.selectedtype === "MySalesXCRM" || selfObj.selectedtype === "SalesXUsers") ? true : false },
        
       
       
        { field: 'actions', headerName: 'Actions', cellRenderer: 'editdeletecv', hide: !this.isDocumentManagement ? false : true, pinned: 'right', width: 80 },
        {
          field: 'actions', headerName: 'Actions', hide: this.isDocumentManagement ? false : true, pinned: 'right', width: 80, cellRenderer(params) {
            var eDiv = document.createElement('div');
            if (params.data.isdeleted === 0) {
              eDiv.innerHTML = '<img src="../../../assets/img/trash.png" style="height:15px;width:30%"/>';
            } else {
              eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size: 14px;margin-left: 4%;color: grey;"></i>';
            }

            eDiv.addEventListener('click', function () {
              if (params.data.isdeleted === 0) {
                selfObj.checkDocPermissionLinked(params.data);
              } else {
                selfObj.UndoDocname(params.data);
              }
            });
            return eDiv;

          }
        },
      ];
    }

    this.DocumentNamecolumnDefs = [
      { field: 'documentdescription', headerName: 'Document Name', hide: false, editable: this.isDocumentManagement ? false : false },

    ];
  }

  checkDocPermissionLinked(Data) {
    this.documenttypeList = [];
    this.deleteRowData = Data;
    this.customerService.GetAllDocumentByPermissionLevel(this.currentuser.id, Data.id).then((userdocument) => {
      this.finaldocumentlist = userdocument;
      if (userdocument.length > 0) {
        this.DeletedDoc = Data;
        this.customerService.GetAllDocumentNamePermissionList(this.selectedtype, this.currentuser.id).then((document) => {
          document.forEach(doc => {
            this.documenttypeList.push({ "id": doc.id, "itemName": doc.documentname, "category": doc.permissionlevel });
          });
          this.documentNamesList = document;
        });
        this.ShowDeleteDocNameMatch = true;
        this.ShowDeleteDocNameMatchCss();
      } else {
        this.ShowDeleteDocNamePermission = true;
        this.applicationComponent.salesxcrmDialog('#ShowDeleteDocNamePermission .dialog');       
      }
    });
  }

  UndoDocname(Data) {
    this.deleteRowData = Data;
    this.ShowDeleteDocNamePermission = true;
    this.applicationComponent.salesxcrmDialog('#ShowDeleteDocNamePermission .dialog');
  }

  //onCellValueChanged(params) {

  //  this.customerService.DeactivateDocument(params.data.id, 0, params.data.doctab, this.currentuser.id).then((user) => {
  //    this.getUserDocumentList(this.currentYear, this.currentPage, this.pageSize, this.currentuser.id, this.startDateVal, this.endDateVal)
  //  });
  //}
  onRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration(this.selectedtype.trim() + "DocManagement");
    this.saveConfigurationDM(this.pageSize, this.startDateVal, this.endDateVal);
  }
  saverowconfiguration(type) {
    var sortoorder = [];
    this.gridOptionsDM.api.forEachNode((node) => {
      sortoorder.push(node.data.id);
    });

    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i === a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentuser.id;
    this.leadsService.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });

    });

  }
  cellEditingStopped(event) {

    let document: Document = new Document();
    document.id = event.node.data.id;
    document.documentname = event.node.data.documentname;
    let permissionlevelid: number = event.node.data.Permissionlevel;
    let permissionObj = this.permissionlevellist.find(x => x.itemName === event.node.data.permissionlevelname);
    if (permissionObj != null)
      permissionlevelid = permissionObj.id;

    document.permissionlevel = permissionlevelid;


    if (this.isDocumentManagement) {
      if (event.colDef.field === "permissionlevelname") {
        this.userservice.UpdatePermissionLevel(document).then(result => {

          this.toastr.success("Permission Level updated successfully.", "Permission Level Updated", { timeOut: 600 });
        });
      } else {
        this.userservice.UpdateDocumentName(document).then(result => {

          this.toastr.success("Document Name updated successfully.", "Document Name Updated", { timeOut: 600 });
        });
      }
    }
  }

  openfile(documentid, fileName, documenttype) {
    var myBaseString = "";

    this.customerService.GetDocumentById(documentid).then(result => {
      myBaseString = result.imagecontent;

      if (this.selectedtype === 'SalesXUsers') {
        this.sendMsgEvent.emit(myBaseString);
      } else {
        var res = this.niceBytes(result.Size);
        if (res <= 2097152) {
          this.onDocumentOpen(myBaseString);


        }
        else {
          this.openfilecharge1(result);
        }

      }


    });

  }

  niceBytes(x) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseFloat(x) || 0;
    var type = x.split(" ")[1];
    var bytes = 0;
    if (type == "MB") {
      bytes = n * 1048576;
    }
    else if (type == "KB") {
      bytes = n * 1024;
    } else {
      bytes = n;
    }

    return parseInt(bytes.toString());
  }

  onDocumentOpen(myBaseString) {
    this.ShowPDF = true;
    this.pdfDiv1 = true;
    this.sendMsgEvent.emit("ShowPDF");
    $(setTimeout(function () {
      $('#pdfDiv1').css('display', 'block');
      $('#documentManagementDiv').css('display', 'none');

      $('#pdfDiv1').html('<embed src="' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
      $('#pdfDiv1').attr('style', 'height: 93%;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');
    }, 200));



  }

  base64ToBlob(base64, type = "application/octet-stream") {
    const binStr = window.atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }

  PDFDocumentOpen(myBaseString) {
    this.ShowPDF = true;
    $(setTimeout(function () {
      $('#documentManagementDiv').css('display', 'none');
      $('#pdfDiv').html('<embed src="data:application/pdf;base64,' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:100%;" />')
      $('#pdfDiv').attr('style', 'width:100%;height: 100%;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;margin-left:0%');
    }, 100));
  }
  close() {
    this.ShowPDF = false;
    this.pdfDiv1 = false;
    this.getUserDocumentList(this.currentYear, this.currentPage, this.pageSize, this.currentuser.id, this.startDateVal, this.endDateVal);
    this.sendMsgEvent.emit("Hide");
  }
  ChangeVal(val) {
    if (val == 800) { this.selectedtype = "Accounts & Contacts"; }
    else { this.selectedtype = "MySalesXCRM";  }
    this.getUserDocumentList(this.currentYear, this.currentPage, this.pageSize, this.currentuser.id, this.startDateVal, this.endDateVal);
  }
  openfilecharge(charge) {
    let myBaseString = charge.imagecontent;
    let fileName = charge.uploadedfilename;

    var data = myBaseString.split(",")
    var binary_string = window.atob(data[1]);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    var arrayBufferView = new Uint8Array(bytes.buffer);

    if (charge.documenttype === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      var blob = new Blob([arrayBufferView], { type: charge.documenttype });
    else
      var blob = new Blob([arrayBufferView], { type: 'image/png/html' });

    var url = window.URL.createObjectURL(blob);

    var a = $("<a>").attr("href", url).attr("download", fileName).appendTo("body");

    a[0].click();

    a.remove();
    this.sendMsgEvent.emit(myBaseString);
    //$('#window').attr('style', 'padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-left: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');
    $('#pdfDiv1').html('<button class="closeEmbed" onclick="closeEmbed()" style="float: right;background: white;border: none; display:block" ><i class="fa fa-times-circle-o" aria-hidden="true"></i></button><embed src="' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
    $('#pdfDiv1').attr('style', 'height: 513px;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');
    this.isPdfOpen = true;
  }


  openfilecharge1(charge) {
    let myBaseString = charge.imagecontent;
    let fileName = charge.fileName;

    var data = myBaseString.split(",")
    var binary_string = window.atob(data[1]);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    var arrayBufferView = new Uint8Array(bytes.buffer);

    if (charge.documenttype === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      var blob = new Blob([arrayBufferView], { type: charge.documenttype });
    else
      var blob = new Blob([arrayBufferView], { type: 'image/png/html' });

    var url = window.URL.createObjectURL(blob);

    var a = $("<a>").attr("href", url).attr("download", fileName).appendTo("body");

    a[0].click();

    a.remove();
    this.sendMsgEvent.emit(myBaseString);
    //$('#window').attr('style', 'padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-left: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');
    $('#pdfDiv1').html('<button class="closeEmbed" onclick="closeEmbed()" style="float: right;background: white;border: none; display:block" ><i class="fa fa-times-circle-o" aria-hidden="true"></i></button><embed src="' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
    $('#pdfDiv1').attr('style', 'height: 513px;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');
    this.isPdfOpen = true;
  }

  deleteCharge(charge) {
    let deleteIndex = this.documentlist.indexOf(charge);
    if (deleteIndex >= 0) {
      this.documentlist.splice(deleteIndex, 1);
    }
  }

  pageSizeChanged(pageSize) {
    this.gridApi.paginationSetPageSize(Number(pageSize));
  }
  loaddocuments(selType, selYear, SelReportType, userids, startval, endval, pageSize) {

    this.selectedUserIds = userids;
    this.startDateVal = startval,
      this.endDateVal = endval;
    this.selectedtype = selType;
    this.SelReportType = SelReportType
    if (selType === undefined || selType === 'Accounts & Contacts') {
      this.selectedtype = "Accounts & Contacts";
      this.accountId = 0;
      this.customerService.GetSpecialityList("PermissionLevel", this.currentuser.id).then((PermissionLevel) => {
        PermissionLevel.forEach(PermissionLevel => {
        });
        this.permissionlevellist = PermissionLevel;
      });
    }
    else
      if (selType === "MySalesXCRM") {
        this.selectedtype = selType;
        this.customerService.GetSpecialityList("UserPermissionLevel", this.currentuser.id).then((PermissionLevel) => {
          PermissionLevel.forEach(PermissionLevel => {
          });
          this.permissionlevellist = PermissionLevel;
        });
        this.accountId = this.currentuser.id;
      }

    if (userids != "") {
      this.getUserDocumentList(selYear, this.currentPage, this.pageSize, userids, startval, endval)
    } else {
      this.userdocumentlist = [];
    }
  }

  loadDeleteddocuments(selType, selYear, SelReportType, userids, startval, endval, pageSize) {

    this.getUserDeletedDocumentList(selYear, this.currentPage, this.pageSize === "" ? 1000 : this.pageSize, userids, startval, endval)

  }
  getUserDeletedDocumentList(selYear, startrow, endrow, userids, startval, endval) {

    this.customerService.GetAllDeletedDocumentList(userids, "MySalesXCRM", selYear, this.SelReportType, startrow, endrow, startval, endval).then((userdocument) => {
      this.userdocumentlist = userdocument;
      this.Originaluserdocumentlist = userdocument;
      this.userdocumentcount = this.userdocumentlist.length;
    });
  }

  getUserDocumentList(selYear, startrow, endrow, userids, startval, endval) {
    if (this.selectedtype === undefined) { this.selectedtype = 'Accounts & Contacts'; }
    else if (this.selectedtype === "MySalesXCRM") {
      this.selectedtype = this.selectedtype;
    }
    if (this.selectedtype === 'Accounts & Contacts' || this.selectedtype === "MySalesXCRM") {
      this.customerService.GetAllDocumentNamePermissionList(this.selectedtype, this.currentuser.id).then((document) => {
        document.forEach(document => {
          document.DateUploaded = this.datePipe.transform(document.DateUploaded, "MM-dd-yyyy");
        });
        this.userdocumentlist = document;
        this.Originaluserdocumentlist = document;
        this.userdocumentcount = this.userdocumentlist.length;
        if (this.userdocumentlist.length === 0) {
          $('.ag-overlay-no-rows-center').html('No Documents Found');
        }
       
      });
    }
    else if (this.selectedtype === "SalesXUsers") {
      this.customerService.GetAllDocumentList(userids, "MySalesXCRM", selYear, this.SelReportType, startval, endval).then((userdocument) => {
        userdocument.forEach(userdocument => {
          userdocument.DateUploaded = this.datePipe.transform(userdocument.DateUploaded, "MM-dd-yyyy");
        });
        this.userdocumentlist = userdocument;
        this.Originaluserdocumentlist = userdocument;
        this.userdocumentcount = this.userdocumentlist.length;
        this.sendMsgEvent.emit(this.userdocumentlist.length.toString());

      });
     
      this.customerService.GetAllDocumentNamePermissionList("MySalesXCRM", this.currentuser.id).then((document) => {
        document.forEach(document => {
          document.DateUploaded = this.datePipe.transform(document.DateUploaded, "MM-dd-yyyy");
        });
        this.documentNamesList = document;
      });

    }
    else {

      this.customerService.GetAllDocumentNamePermissionList(this.selectedtype, this.currentuser.id).then((document) => {
        document.forEach(document => {
          document.DateUploaded = this.datePipe.transform(document.DateUploaded, "MM-dd-yyyy");
        });
        this.documentNamesList = document;
      });
      this.customerService.GetAllDocumentList(this.accountId.toString(), this.selectedtype, selYear, this.SelReportType, startval, endval).then((document) => {
        document.forEach(document => {
          document.DateUploaded = this.datePipe.transform(document.DateUploaded, "MM-dd-yyyy");
        });
        if (this.FilterType == "With Proposal") {
          this.userdocumentlist = document.filter(x => x.DocSavedFrom == 'Proposal');
          this.Originaluserdocumentlist = document.filter(x => x.DocSavedFrom == 'Proposal');
        } else if (this.FilterType == 'WithOut Proposal') {
          this.userdocumentlist = document.filter(x => x.DocSavedFrom !== 'Proposal');
          this.Originaluserdocumentlist = document.filter(x => x.DocSavedFrom !== 'Proposal');
        } else {
          this.userdocumentlist = document;
          this.Originaluserdocumentlist = document;
          this.userdocumentcount = this.userdocumentlist.length;
        }

        if (this.userdocumentlist.length === 0) {
          $('.ag-overlay-no-rows-center').html('<strong>No Documents Available</strong>');
        }
      });
     
    }

  }

  DocumentsFilterBy(value) {
    if (value == "Display Proposal & PO") {
      this.userdocumentlist = this.Originaluserdocumentlist;
    } else {
      this.userdocumentlist = this.Originaluserdocumentlist.filter(x => x.documentname == value)
    }

    this.userdocumentcount = this.userdocumentlist.length;

  }
  DocumentsStatusBy(val) {
    if (val == 736) { this.userdocumentlist = this.Originaluserdocumentlist.filter(x=>x.stat==110); }
    else if (val == 737) { this.userdocumentlist = this.Originaluserdocumentlist.filter(x => x.stat == 111); }
    else if (val == 738) { this.userdocumentlist = this.Originaluserdocumentlist.filter(x => x.stat == 109);}
    else {
      this.userdocumentlist = this.Originaluserdocumentlist;
    }
  }
  ngOnInit() {

    if (this.isUploadDivVisible)
      this.documentDivHeight = 50;

    if (!this.isDocumentManagement) {
      this.uploadDivHeight = 0;
    }

    if (!this.GridLoad) {
      this.initializeAgGrid();
      this.GridLoad = true;
    }

    this.loaddocuments(this.selectedtype, this.currentYear, this.SelReportType, this.currentuser.id, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, this.pageSize);


    var url = window.location.href;
    if (url.indexOf('contactsdashboard') > -1) {
      if (document.getElementById('documentManagementDiv') != undefined || document.getElementById('documentManagementDiv') != null)
        document.getElementById('documentManagementDiv').style.height = "83%";

      $('#documentManagementDiv').attr('style', 'height:426px');

    }

    this.customerService.GetSpecialityList("DocumnentType", this.currentuser.id).then((Documenttype) => {
      Documenttype.forEach(Documenttype => {
      });
      this.DocumenttypeList = Documenttype;
    
      this.initializeAgGrid();
    });

    this.dropdownSetting = {
      singleSelection: true,
      text: "Select Document Name",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 2
    };

  }
  GetImagedata(reader, index, filename, filesize, filetype) {
    $('#' + index + 'imagecontent').val(reader.result);
    $('#' + index + 'uploadedfilename').val(filename);
    $('#' + index + 'size').val(filesize);
    $('#' + index + 'documenttype').val(filetype);
  }
  columnVisible(event) {
    this.saveConfiguration(this.pageSize, this.startDateVal, this.endDateVal);
  }
  dragStopped(event) {
    this.saveConfiguration(this.pageSize, this.startDateVal, this.endDateVal);
  }
  columnResized(event) {
    if (event.finished === true)
      this.saveConfiguration(this.pageSize, this.startDateVal, this.endDateVal);
  }
  saveConfiguration(pagesize, start, End) {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize === "")
      config.maxlimit = 25;
    else
      config.maxlimit = Number(pagesize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    config.configureduserid = this.currentuser.id;
    config.resourceid = 59;
    config.startdate = start;
    config.enddate = End;
    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  columnVisibleDM(event) {
    this.saveConfigurationDM(this.pageSize, this.startDateVal, this.endDateVal);
  }
  dragStoppedDM(event) {
    this.saveConfigurationDM(this.pageSize, this.startDateVal, this.endDateVal);
  }
  columnResizedDM(event) {
    if (event.finished === true)
      this.saveConfigurationDM(this.pageSize, this.startDateVal, this.endDateVal);
  }
  saveConfigurationDM(pagesize, start, End) {
    let allCols: Column[] = this.gridOptionsDM.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize === "")
      config.maxlimit = 25;
    else
      config.maxlimit = Number(pagesize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    config.configureduserid = this.currentuser.id;
    config.resourceid = 56;
    config.startdate = start;
    config.enddate = End;
    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  onFileChanged(event, index) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      let objSelf = this;
      $(setTimeout(function () {
        objSelf.GetImagedata(reader, index, file.name, file.size, file.type);
      }, 500));
    }
  }
  updateDropdown(value, index) {
    document.getElementById(index + 'selectedPermissionName').innerHTML = value;
  }
  addCharge() {
    this.documentlist = [];
    let newdoc = new Document();
    newdoc.documentname = "";
    newdoc.permissionlevel = 0;
    newdoc.uploadedfilename = "";
    newdoc.Size = "";
    newdoc.documenttype = "";
    newdoc.imagecontent = "";
    newdoc.extension = "";
    newdoc.documentdescription = "";
    newdoc.accountid = 0;
    newdoc.type = "";
    this.documentlist.push(newdoc);

    this.loaddocuments(this.selectedtype, this.currentYear, this.SelReportType, this.currentuser.id, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, this.pageSize);
  }
  //rowDoubleClickAgGrid(rowEvent) {
  //  let selfObj = this;
  //  $(setTimeout(function () {
  //    selfObj.gridOptions.api.forEachNode((node) => {
  //      if (node.data.id === rowEvent.data.id) {
  //        selfObj.gridOptions.api.selectNode(node, true);
  //        var myBaseString = "";
  //        selfObj.userservice.GetImageData(rowEvent.data.id).then(result => {
  //          myBaseString = result;
  //          $(setTimeout(function () {
  //            selfObj.sendMsgEvent.emit(myBaseString);
  //          }, 100));
  //        });
  //        this.sendMsgEvent.emit(rowEvent.data.imagecontent);
  //      }
  //    });
  //  }, 500));

  //}

  SalesXCRMSelectionChanged(event) {
    let selfObj = this;
    $(setTimeout(function () {
      var rowCount = event.api.getSelectedNodes().length;
      if (rowCount > 0) {
        if (event.api.getSelectedNodes()[0].data) {
          var rowdata = event.api.getSelectedNodes()[0];
          if (rowdata.data.permissionlevel <= selfObj.currentuser.userpermissionlevelid) {
            selfObj.documentindex = rowdata.rowIndex;
            selfObj.PreviewDocument = rowdata.data;

            var myBaseString = "";
            selfObj.userservice.GetImageData(rowdata.data.id).then(result => {
              myBaseString = result;
              selfObj.sendMsgEvent.emit(myBaseString);
            });
            //this.sendMsgEvent.emit(rowdata.data.imagecontent);
          } else {
            selfObj.DocViewAccessDeniedCss();

          }
        } else {

        }
      } else {

      }
    }, 200));
  }

  rowClickAgGrid(rowEvent) {

    //  document.getElementById('documentManagementDiv').style.height = '428px';

    var myBaseString = "";
    this.userservice.GetImageData(rowEvent.data.id).then(result => {
      myBaseString = result;
      let objSelf = this;
      $(setTimeout(function () {
        objSelf.sendMsgEvent.emit(myBaseString);
      }, 100));
    });
    $('#buttonDiv').attr('style', 'display: none; ')

  }
  addChargeNew(file) {

    const reader = new FileReader();
    reader.readAsDataURL(file);

    let selfObj = this;
    let newdoc = new Document();

    $(setTimeout(function () {
      newdoc.file = file;
      newdoc.documentnamepermissionid = 0;
      newdoc.documentname = "Select";
      newdoc.permissionlevel = 0;
      newdoc.uploadedfilename = file.name;
      newdoc.Size = file.size;
      newdoc.documentdescription = "ban";
      newdoc.documenttype = file.type;
      newdoc.imagecontent = reader.result.toString();
      let extensionArr = file.name.split('.');
      newdoc.extension = extensionArr[extensionArr.length - 1];

      selfObj.documentlist.push(newdoc);
    }, 500));

  }
  addChargeExisting(file, charge) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    $(setTimeout(function () {
      charge.file = file;
      //charge.documentname = "";
      //charge.permissionlevel = "Select";
      charge.uploadedfilename = file.name;
      charge.size = file.size;
      charge.documenttype = file.type;
      charge.imagecontent = reader.result;
      let extensionArr = file.name.split('.');
      charge.extension = extensionArr[extensionArr.length - 1];
    }, 500));
  }

  addFiles(event) {

    if (!this.isDocumentManagement) {
      let files = event.srcElement.files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        this.addChargeNew(file);
      }
      $("#addFiles").val("");
    } else {
      this.addCharge();
    }

  }

  addFile(event, charge) {
    let files = event.srcElement.files;
    if (files != null && files.length > 0) {
      let file = files[0];
      this.addChargeExisting(file, charge);
    }
    $("#addFile").val("");



  }

  onFileAdded(file, index) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    let objSelf = this;
    $(setTimeout(function () {
      objSelf.GetImagedata(reader, index, file.name, file.size, file.type);
    }, 500));
  }
  SaveDocuments() {
    let test: number = this.accountId;
    this.finaldocumentlist = [];
    let deleteDocuments: Document[] = [];

    this.documentlist.forEach(document => {
      let isValidate: boolean = false;
      if (!this.isDocumentManagement) {
        if (document.documentnamepermissionid != undefined && document.documentnamepermissionid != null && document.documentnamepermissionid != 0)
          isValidate = true;
      } else {
        if (document.documentname != null && document.documentname.trim() != "" && document.permissionlevel != null && document.permissionlevel != 0)
          isValidate = true;
      }

      if (isValidate) {

        let newdoc = new Document();
        newdoc.id = document.id;
        newdoc.documentname = document.documentname;
        newdoc.uploadedby = this.currentuser.id;
        newdoc.uploadedfilename = document.uploadedfilename;
        newdoc.Size = document.Size;
        newdoc.documenttype = document.documenttype;
        newdoc.documentdescription = document.documentdescription;
        newdoc.permissionlevel = (this.selectedtype === "MySalesXCRM" || this.selectedtype === " MySalesXCRM " || this.selectedtype === "Accounts & Contacts") ? document.permissionlevel : document.documentnamepermissionid;
        newdoc.doctype = this.selectedtype;
        newdoc.imagecontent = document.imagecontent;
        newdoc.extension = document.extension;
        newdoc.accountid = this.accountId;
        newdoc.type = (this.selectedtype === null) ? 'Account Or Contact' : (this.selectedtype === "SalesXUsers") ? 'MySalesXCRM' : this.selectedtype;
        if (!this.isDocumentManagement) {

          newdoc.documentnamepermissionid = document.documentnamepermissionid;
        }

        this.finaldocumentlist.push(newdoc);

        deleteDocuments.push(document);
      }
    });

    if (this.finaldocumentlist.length > 0) {

      if (!this.isDocumentManagement) {
        this.userservice.addDocuments(this.finaldocumentlist).then(result => {
          this.toastr.success("Documents saved successfully.", "Documents Saved", { timeOut: 600 });
          this.loaddocuments(this.selectedtype, this.currentYear, result[0].tabId, this.currentuser.id, this.startDateVal, this.endDateVal, this.pageSize);
          if (deleteDocuments.length > 0) {
            deleteDocuments.forEach(document => {
              let deleteIndex = this.documentlist.indexOf(document);
              if (deleteIndex >= 0)
                this.documentlist.splice(deleteIndex, 1);
            });
          }
        });
      } else {
        this.userservice.addDocumentNamePermission(this.finaldocumentlist).then(result => {
          this.toastr.success("Document names with permissions saved successfully.", "Document", { timeOut: 600 });
          this.loaddocuments(this.selectedtype.trim(), this.currentYear, this.SelReportType, this.currentuser.id, this.startDateVal, this.endDateVal, this.pageSize);
          if (deleteDocuments.length > 0) {
            deleteDocuments.forEach(document => {
              let deleteIndex = this.documentlist.indexOf(document);
              if (deleteIndex >= 0)
                this.documentlist.splice(deleteIndex, 1);
            });
          }
        });
      }
    }
    else {

      if (!this.isDocumentManagement) {
        this.userservice.UpdateDocumentName(this.doc).then(result => {
          this.toastr.success("Documents Updated successfully.", "Documents Updated", { timeOut: 600 });
          this.loaddocuments(this.selectedtype, this.currentYear, result[0].tabId, this.currentuser.id, this.startDateVal, this.endDateVal, this.pageSize);
          if (deleteDocuments.length > 0) {
            deleteDocuments.forEach(document => {
              let deleteIndex = this.documentlist.indexOf(document);
              if (deleteIndex >= 0)
                this.documentlist.splice(deleteIndex, 1);
            });
          }
        });
      } else {
        //this.doc.uploadedby = this.currentuser.id;
        this.doc.lastediteduserId = this.currentuser.id;
        this.userservice.UpdatePermissionLevel(this.doc).then(result => {

          this.toastr.success("Document names with permissions Updated successfully.", "Document", { timeOut: 600 });
          this.loaddocuments(this.selectedtype.trim(), this.currentYear, this.SelReportType, this.currentuser.id, this.startDateVal, this.endDateVal, this.pageSize);
          if (deleteDocuments.length > 0) {
            deleteDocuments.forEach(document => {
              let deleteIndex = this.documentlist.indexOf(document);
              if (deleteIndex >= 0)
                this.documentlist.splice(deleteIndex, 1);
            });
          }
        });
      }

    }
    var url = window.location.href;
    if (url.indexOf('contactsdashboard') > -1) {

      //document.getElementById('documentsinfo').style.marginTop = "9% !important";
    }

    if (this.isEditDocument === true)
      this.isEditDocument = !this.isEditDocument;
  }
  transfered() {
    this.isselectedtransfered = true;
  }
  canceltransfered() {
    this.isselectedtransfered = false;
  }
  CancelDocuments() {
    this.documentlist = [];
    var url = window.location.href;
    if (url.indexOf('contactsdashboard') > -1) {
      //document.getElementById('documentsinfo').style.marginTop = "5%";
    }
    if (this.isEditDocument === true)
      this.isEditDocument = !this.isEditDocument;

  }
  editRowAg(rowdata) {
    if (this.isDocumentManagement) {
      this.isEditDocument = true;
      this.customerService.GetDocumentPermissionById(rowdata.id).then(result => {
        this.doc = result;
      });
    }
    else {


      if (this.selectedtype === 'SalesXUsers') {
        this.doc = rowdata;
        this.documentDialog = true;
        this.documentDialgCss();
      }
      else {
        if (Number(rowdata.permissionlevel) <= this.currentuser.permissionlevelid) {
          this.EditDiv = true;
          this.sendMsgEvent.emit("EditDocumentTrue");

        } else {
          this.DocViewAccessDeniedCss();
        }

      }

      this.customerService.GetSpecialityList("DocumentTab", this.currentuser.id).then((accounttype) => {
        accounttype.forEach(accounttype => {
        });
        this.docTabList = accounttype;
      });
      var selfObj = this;
      selfObj.customerService.GetContactById(selfObj.accountId).then(result => {
        var url = window.location.href;
        if (url.indexOf('contactsdashboard') > -1 || url.indexOf('contacts') > -1) {
          if (result) { selfObj.accountId = result.accountid; }
        }

        this.customerService.GetAccountContactList(selfObj.accountId).then((contacts) => {
          contacts.forEach(contacts => {
          });
          this.conList = contacts;
        });
      });
      if (this.selectedtype !== 'SalesXUsers') {
        this.customerService.GetDocumentById(rowdata.id).then(result => {
          this.doc = result;
          this.customerService.GetDocumentHistoryById(rowdata.id).then(result => {
            result.forEach(ele => {
              ele.EditedTime = this.datePipe.transform(ele.EditedDate, 'hh: mm a')
              ele.EditedDate = this.datePipe.transform(ele.EditedDate, "MM-dd-yyyy").toString();


            });
            this.docHistory = result;
          });
        });
      }




    }
  }

  documentDialgCss() {
    $(setTimeout(function () {
      $('#documentDialog .dialog').attr('style', 'width:50% !important;border: 4px solid rgb(49,189,240);border-radius:5%; height:64%;top:7%;left:0%;max-width:none;');
    }, 200));
  }
  deleteRowAg(rowdata) {
    let confirmText: string = "";
    this.deleteRowData = rowdata;
    this.contactStatus = this.deleteRowData.isdeleted
    this.DeleteConfirmationPopup = true;
    setTimeout(function () {
      $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 40);

  }
 
  ShowDeleteDocNameMatchCss() {
    setTimeout(function () {
      $('#ShowDeleteDocNameMatch .dialog').attr('style', 'width: 45%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 81%;overflow: auto;height: 81%;top: 11%;left: 53%;');
    }, 4);
  }

  ReceiveDeleteevent(msg) {
    if (msg === "Ok") {
      if (this.isDocumentManagement) {
        this.deleteRowData.isdeleted = this.contactStatus;
        this.userservice.UpdatePermissionLevel(this.deleteRowData).then(result => {
          this.toastr.success("Documents Restored successfully.", "Documents Restored", { timeOut: 600 });
          this.deleteRowData.isdeleted = result.isdeleted;
          let So = this;
          $(setTimeout(function () {
            So.getUserDocumentList(So.currentYear, So.currentPage, So.pageSize, So.currentuser.id, So.startDateVal, So.endDateVal);
          }, 100));
        });
      } else {
        this.customerService.DeactivateDocument(this.deleteRowData.id, (this.contactStatus === 1) ? 0 : 1, 0, this.currentuser.id).then((user) => {
          if (this.contactStatus === 1) {
            this.getUserDeletedDocumentList(this.currentYear, this.currentPage, this.pageSize === "" ? 25 : this.pageSize, this.selectedUserIds, this.startDateVal, this.endDateVal);
          } else {
            this.getUserDocumentList(this.currentYear, this.currentPage, this.pageSize === "" ? 25 : this.pageSize, this.selectedUserIds, this.startDateVal, this.endDateVal);
          }

        });
      }
    }
    this.DeleteConfirmationPopup = false;
  }
  EditDocument(documentId: number) {

    this.customerService.GetDocumentById(documentId).then(result => {
      this.doc = result;
    });
    this.customerService.GetSpecialityList("DocumentTab", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.docTabList = accounttype;
    });

  }
  DeleteDocument(documentId: number, documentName: string) {

    this.selecteddocumentName = documentName;
    this.isDocumentTransfer = true;
    this.isselectedtransfered = false
    this.fromdocumentid = documentId;
    let confirmText: string = "Are you sure you want to delete this Document?";
    if (this.isDocumentManagement)
      // confirmText = "Are you sure you want to delete this Document Name and Permission?"

      var c = confirm(confirmText);
    if (c === true) {
      if (!this.isDocumentManagement) {
        this.userservice.DeleteDocument(documentId.toString()).then(result => {
          if (result != null) {
            this.toastr.success("Document deleted successfully.", "Document Deleted", { timeOut: 600 });
          }
          else {
            this.toastr.error("Some error occurred while deleting document.", "Error!");
          }

          //this.loaddocuments(this.currentuser.id);

          //this.userdocumentlist = result;
          this.loaddocuments(this.selectedtype, this.currentYear, this.SelReportType, this.currentuser.id, this.startDateVal, this.endDateVal, this.pageSize);
        });
      } else {
        this.userservice.DeleteDocumentNamePermission(documentId.toString()).then(result => {
          if (result != null) {
            this.toastr.success("Document Name and Permission deleted successfully.", "Document", { timeOut: 600 });
          }
          else {
            this.toastr.error("There might be documents with this name. Please delete them before deleting this Document Name", "Documents with this name exists");
          }
          this.loaddocuments(this.selectedtype, this.currentYear, this.SelReportType, this.currentuser.id, this.startDateVal, this.endDateVal, this.pageSize);
        });
      }
    }
  }

  documenttransfered() {
    this.userservice.addtransfered(this.fromdocumentid, this.todocumentid).then(result => {
      this.loaddocuments(this.selectedtype, this.currentYear, this.SelReportType, this.currentuser.id, this.startDateVal, this.endDateVal, this.pageSize);
      this.isDocumentTransfer = false;
    });
  }
  SelectedDocumentType(id, Docname) {

    this.todocumentid = id;
    this.selectedtodocumentname = Docname;
    $('.closeButtonTr').removeAttr('style');

    $('#' + id).attr('style', 'background-color:#b7e4ff;')
  }
  ToDoDelete(taskId, index) {

    $('#' + index).remove();
  }
  onAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
    }, 400));

    var url = window.location.href;
    if (url.indexOf('contactsdashboard') > 1) {
      $('#buttonDiv').attr('style', 'margin-top: 0%;');
    }
    this.gridApi = event.api;
  }
  autoSizeAll() {
    var allColumnIds = [];
    if (this.gridOptions && this.gridOptions.columnApi) {
      var allCols = this.gridOptions.columnApi.getAllColumns();
      if (allCols != null) {
        allCols.forEach(function (column) {
          allColumnIds.push(column.getColId());
        });
      }
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
    }

  }
  autoSizeAllDocumentManagement() {
    var allColumnIds = [];
    if (this.gridOptionsDM && this.gridOptionsDM.columnApi) {
      var allCols = this.gridOptionsDM.columnApi.getAllColumns();
      if (allCols != null) {
        allCols.forEach(function (column) {
          allColumnIds.push(column.getColId());
        });
      }
      this.gridOptionsDM.columnApi.autoSizeColumns(allColumnIds);
    }

  }


  first() {

    this.currentPage = 1;
    this.getUserDocumentList(this.currentYear, this.currentPage, this.pageSize, this.currentuser.id, this.startDateVal, this.endDateVal)
  }
  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getUserDocumentList(this.currentYear, this.currentPage, this.pageSize, this.currentuser.id, this.startDateVal, this.endDateVal)
    }
  }
  next() {
    if (this.currentPage != this.totalnumberofPages) {
      this.currentPage++;
      this.getUserDocumentList(this.currentYear, this.currentPage, this.pageSize, this.currentuser.id, this.startDateVal, this.endDateVal)
    }

  }
  last() {
    this.currentPage = this.totalnumberofPages;
    this.getUserDocumentList(this.currentYear, this.currentPage, this.pageSize, this.currentuser.id, this.startDateVal, this.endDateVal)
  }
  fieldEdited(val) {

    var fieldeditd = "";
    this.FieldsEdited = this.FieldsEdited.filter(x => x != val);
    this.FieldsEdited.push(val);
  }

  UpdateDoc() {

    this.finaldocumentlist = [];
    this.finaldocumentlist.push(this.doc);
    this.userservice.addDocuments(this.finaldocumentlist).then(result => {

      if (result != null) {
        // this.cancel();
        this.toastr.success("Document Updated successfully.", "Document Saved", { timeOut: 600 });
        this.cancel();
        this.getUserDocumentList(this.currentYear, this.currentPage, this.pageSize, this.currentuser.id, this.startDateVal, this.endDateVal)
      }
      var DocHistory = [];
      this.finaldocumentlist.forEach(ele => {
        var doc = new DocumentHistory();
        doc.DocumentId = ele.id;
        doc.EditedBy = this.currentuser.id;
        doc.FieldEdited = this.FieldsEdited.join(',');
        DocHistory.push(doc);
      });
      this.userservice.DocumentsHistory(DocHistory).then(res => {
        this.FieldsEdited = [];
      });
    });
  }
  uploadFiles() {
    this.sendMsgEvent.emit("UploadDocument");
  }

  DoconSelectionChanged(event) {
    let selfobj = this;
    setTimeout(function () {
      var rowCount = event.api.getSelectedNodes().length;
      if (rowCount > 0) {
        if (event.api.getSelectedNodes()[0].data) {
          var rowdata = event.api.getSelectedNodes()[0];
          if (rowdata.data.permissionlevel <= selfobj.currentuser.permissionlevelid) {
            selfobj.documentindex = rowdata.rowIndex;
            selfobj.PreviewDocument = rowdata.data;
            if ((selfobj.Ids).indexOf(rowdata.data.id) > -1) {
              var index = (selfobj.Ids).indexOf(rowdata.data.id);

            } else {
              selfobj.Ids.push(rowdata.data.id)

            }
            selfobj.openfile(rowdata.data.id, rowdata.data.fileName, rowdata.data.documenttype)
            selfobj.sendMsgEvent.emit((selfobj.Ids).toString());
          } else {
            selfobj.DocViewAccessDeniedCss();

          }
        } else {

        }
      } else {

      }
    }, 200);
  }


  DocViewAccessDeniedCss() {
    this.DocViewAccessDenied = true;
    this.applicationComponent.salesxcrmDialog('#DocViewAccessDenied .dialog'); 
  }
  cancel() {
    this.EditDiv = false;
    this.sendMsgEvent.emit("EditDocumentFalse");
    this.documentDialog = false;
  }

  PDFClick(val) {
    this.userservice.GetProposalsDocumentContent(val).then(result => {
      let SO = this
      $(setTimeout(function () {
        SO.PDFDocumentOpen(result.PdfContent);
      }, 100));
    });
  }

  previousdocument() {
    this.documentindex--;
    if (this.userdocumentlist[this.documentindex].permissionlevel <= this.currentuser.permissionlevelid) {

      this.getImageData(this.userdocumentlist[this.documentindex]);
    } else {
      this.documentindex++;
      this.DocViewAccessDeniedCss();
    }
  }
  nextdocument() {
    this.documentindex++;
    if (this.userdocumentlist[this.documentindex].permissionlevel <= this.currentuser.permissionlevelid) {

      this.getImageData(this.userdocumentlist[this.documentindex]);
    } else {
      this.documentindex--;
      this.DocViewAccessDeniedCss();
    }
  }
  getImageData(documentid) {

    this.openfile(documentid.id, null, null);

  }
  onSelectionChanged(event) {

    var rowCount = event.api.getSelectedNodes();
    this.documentlist = [];
    rowCount.forEach(ele => {
      let newdoc = new Document();
      newdoc.id = ele.data.id;
      newdoc.documentname = ele.data.documentname;
      newdoc.permissionlevel = ele.data.permissionlevel;
      newdoc.uploadedfilename = ele.data.uploadedfilename;
      newdoc.Size = ele.data.Size;
      newdoc.documenttype = ele.data.documenttype;
      newdoc.imagecontent = ele.data.imagecontent;
      newdoc.extension = ele.data.extension;
      newdoc.documentdescription = '';
      newdoc.accountid = 0;
      newdoc.type = ele.data.type;
      this.documentlist.push(newdoc);

    });

  }

  Confirmation(val) {
   
    if (val === 'Ok') {
      if (this.deleteRowData.isdeleted === 0) {
        this.contactStatus = 1;
      } else {
        this.contactStatus = 0;
      }

      this.ReceiveDeleteevent('Ok');
      this.ShowDeleteDocNamePermission = false;
      this.ShowDeleteDocNameMatch = false;




    } else {
      this.ShowDeleteDocNamePermission = false;
      this.ShowDeleteDocNameMatch = false;
    }
  }
  ReplaceDocumentName() {
    this.finaldocumentlist.forEach(ele => {
      ele.documentnamepermissionid = this.ReplaceItem[0].id;
      ele.permissionlevel = this.ReplaceItem[0].id;
    });

    this.userservice.addDocuments(this.finaldocumentlist).then(result => {

      if (result != null) {
        this.Confirmation(true);
        this.toastr.success("Document Updated successfully.", "Document Saved", { timeOut: 600 });
        this.getUserDocumentList(this.currentYear, this.currentPage, this.pageSize, this.currentuser.id, this.startDateVal, this.endDateVal)
        this.ReplaceItem = [];
      }
    });
  }
  CloseDeleteDocNameMatch() {

  }

  ChangeDocumentType() {

    this.loaddocuments(this.selectedtype, this.currentYear, this.SelReportType, this.currentuser.id, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, this.pageSize);
  }
}
