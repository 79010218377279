import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { AdvancedsearchTable } from '../../models/advancedsearch-table';
import { RunQuery } from '../../models/run-query';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationComponent } from '../application.component';
import { ToastrService } from 'ngx-toastr';
import { AdvancedSearch } from '../../models/advancedsearch';
import { AdvancedsearchService } from 'src/app/services/advancedsearch.service';
import { CustomerService } from '../../services/customer.service';
import { ContactsdashboardComponent } from '../contacts/contactsdashboard/contactsdashboard.component';
import { AccountContactComponent } from '../account-contact/account-contact.component';
import { AccountComponent } from '../account-contact/account/account.component';

declare var $: any;
@Component({
  selector: 'search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnInit {
  currentuser: any;
  public SearchResultGridOptions: GridOptions;
  public columnDefs: any[];
  public queryId: number;
  public searchtype: string;
  selectedMenuRadio: string = "";
  tableData: AdvancedsearchTable[];
  isCustomer: boolean = false;
  QueryName: string;
  isFormerCustomer: boolean = false;
  showTabledata: boolean = true;
  CloseDialog: boolean = false;
  runquerylist: any = [];
  Newrunquerylist: any = [];
  Ids = [];
  slide = 0;
  query: RunQuery = new RunQuery();
  public sideBar;
  public TableColumns: any;
  isContactViewOpen: boolean = true;
  showContactDashBoard: boolean = false;
  @ViewChild(ContactsdashboardComponent) condashcmp: ContactsdashboardComponent;
  @ViewChild(AccountContactComponent) acccontactcomp;
  @ViewChild(AccountComponent) accountcomp;
  isShowAccountInfo: boolean = false;
  ShowGrid: boolean = true;
  contactid: number;
  accountid: number;
  AccConIds: string;
  private gridApi; public overlayNoRowsTemplate = "No record found";
  constructor(private route: ActivatedRoute, private opprtunityservice: OpprtunitiesService, private router: Router, private advsearch: AdvancedsearchService,
    private toastr: ToastrService, public applicationComponent: ApplicationComponent, public customerService: CustomerService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));


  }

  ngOnInit(): void {

    let isDel: boolean = false;
    this.route.params.subscribe(params => {
      this.queryId = params['queryId'];
      this.searchtype = params['searchtype'];
      this.RunQueryData(isDel);
      let qryCount = localStorage.getItem('selectedQueryCount');
      if (this.queryId != 0) {
        let advSearchTabName = localStorage.getItem('advSearchTabName');
        let advSearchQueryId = localStorage.getItem('advSearchQueryId');
        let advSearchQueryName = localStorage.getItem('advSearchQueryName');
        if (qryCount != null && parseInt(qryCount) == 0) {
          let obj = this;         
          setTimeout(function () { 
            if (advSearchTabName != null && advSearchQueryId != null) {
              obj.GetQueryInfo(parseInt(advSearchQueryId), advSearchQueryName, advSearchTabName, '');
            }
            else {
              obj.GetQueryInfo(obj.queryId, advSearchQueryName, 'QueryTab0', obj.searchtype);
            }
          }, 100);

        }
        else {
          this.GetQueryInfo(this.queryId, advSearchQueryName,'QueryTab0', this.searchtype);
        }
      }
      else {
        var query = localStorage.getItem('QuickSearchQuery');
        if (query != null) {
          this.firequery(query);
        }
      }

    });
   
    this.initializeAgGrid();
   
  }
  firequery(buildquery) {
    let advcontrol = new AdvancedSearch();
    advcontrol.SearchQry = buildquery;
    this.opprtunityservice.runQuicksearchQuery(advcontrol).then(result => {
      this.tableData = result;
      this.showTabledata = true;
    });
  }
  GetQueryInfo(queryId,queryName, tabname, searchtype) {
    this.showContactDashBoard = false;
    this.isShowAccountInfo = false;
    this.QueryName = queryName
    this.SearchResultGridOptions.rowSelection = 'multiple';
    this.searchtype = searchtype;
    localStorage.setItem('advSearchTabName', tabname);
    localStorage.setItem('advSearchQueryId', queryId);
    localStorage.setItem('advSearchQueryName', queryName);
    if (queryId != 0) {

      $('.blank').removeClass('green');
      $('#' + tabname).addClass('green');
      this.opprtunityservice.GetAdvancesSearchQueryByData(queryId).then((data) => {
        this.tableData = data;
        
        var res = this.runquerylist.filter(x => x.id == queryId)
        this.searchtype = res[0].searchtype;
        this.showTabledata = true;
       
      });

    }

  }
  RunQueryData(isDelete) {
    this.advsearch.GetAllRunQueries(this.currentuser.id).then((queries) => {
      this.runquerylist = [];
      this.Newrunquerylist = [];
      if (queries == null || queries.length <= 0) {
        this.applicationComponent.advancedSearch("");
        this.applicationComponent.searchoffon = false;
        let selfObj = this;
        setTimeout(function () {
          selfObj.tableData = [];
          selfObj.createColumnDefs("", selfObj.TableColumns);
        }, 100);
      }
      else {
        if (queries[0].queryOrder == null) {
          this.applicationComponent.searchoffon = false;
          this.showTabledata = false;
          this.tableData = [];
        }
        else {
          this.runquerylist = queries;
          this.applicationComponent.searchoffon = true;
        }

        var initValue = this.slide * 12;
        var finalVal = (initValue + 12);
        if (this.runquerylist.length < finalVal) {
          finalVal = this.runquerylist.length
        }
        for (var i = initValue; i < (finalVal); i++) {
          this.Newrunquerylist.push(this.runquerylist[i]);
        }
        let qryCount = localStorage.getItem('selectedQueryCount');
        if (qryCount != null && parseInt(qryCount) == 0) {

          let advSearchTabName = localStorage.getItem('advSearchTabName');
          let advSearchQueryId = localStorage.getItem('advSearchQueryId');
          if (advSearchTabName != null && advSearchQueryId != null) {
            let SO = this;
            setTimeout(function () {
              $('#' + advSearchTabName).addClass('green');
              $("#" + advSearchTabName).trigger("click");

            }, 100);
          }
          else {
            setTimeout(function () {
              $('#QueryTab0').addClass('green');
              $("#QueryTab0").trigger("click");
            }, 100);
          }
        }
        else {
          setTimeout(function () {
            $('#QueryTab0').addClass('green');
            $("#QueryTab0").trigger("click");
          }, 100);
        }
      }
    });
    var query = localStorage.getItem('QuickSearchQuery');
    if (query != null && query != "" && isDelete == false) {
      setTimeout(function () {
        $('#QueryTab0').addClass('green');
      }, 100);

    }
  }

  Previous() {
    this.Newrunquerylist = [];
    this.slide--;
    var initValue = this.slide * 5;
    var finalVal = (initValue + 5);
    if (this.runquerylist.length < finalVal) {
      finalVal = this.runquerylist.length
    }
    for (var i = initValue; i < (finalVal); i++) {
      this.Newrunquerylist.push(this.runquerylist[i]);
    }
  }
  next() {
    this.Newrunquerylist = [];
    this.slide++;
    var initValue = this.slide * 5;
    var finalVal = (initValue + 5);
    if (this.runquerylist.length < finalVal) {
      finalVal = this.runquerylist.length
    }
    for (var i = initValue; i < (finalVal); i++) {
      this.Newrunquerylist.push(this.runquerylist[i]);
    }
  }
  deleteQuery(queryid, val) {
    let isDel: boolean = false;
    if (queryid != 0) {
      isDel = true;
      this.query.runby = this.currentuser.id;
      this.query.delqueryId = queryid
      this.advsearch.saveRunQuery(this.query).then(result => {
        this.toastr.success("Query deleted successfully.", "Query", { timeOut: 600 });
        this.Newrunquerylist = [];
        this.RunQueryData(isDel);

      });
    }

  }
  initializeAgGrid() {
       this.SearchResultGridOptions = <GridOptions>{
         pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true,
      toolPanelSuppressPivotMode: true, rowSelection: (this.showContactDashBoard || this.isShowAccountInfo) ? 'single' : 'multiple', enableCellChangeFlash: true
    }; this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
   // this.createColumnDefs("", this.TableColumns);
    if (this.searchtype == 'Account') {
      //this.customerService.GetTableFields('Accounts', this.currentuser.id).then(result => {
      //  this.TableColumns = result;
        this.createColumnDefs("", this.TableColumns);
      //});
    } else if (this.searchtype == 'Contact') {
      this.customerService.GetTableFields('Contacts', this.currentuser.id).then(result => {
        this.TableColumns = result;
        this.createColumnDefs("", this.TableColumns);
      });
    }
    else {
      this.customerService.GetTableFields('Accounts & Contacts', this.currentuser.id).then(result => {
        this.TableColumns = result;
        this.createColumnDefs("", this.TableColumns);
      });
    }

    this.SearchResultGridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }

  }
  QuickSearchEventListener() {
    if (this.applicationComponent.QuickSearchEvenraised) {
      var query = localStorage.getItem('QuickSearchQuery');
      this.firequery(query);
      this.applicationComponent.QuickSearchEvenraised = false;
      this.advsearch.GetAllRunQueries(this.currentuser.id).then((queries) => {
        this.runquerylist = [];
        this.Newrunquerylist = [];
        if (queries[0].queryOrder == null) { this.applicationComponent.searchoffon = false; } else {
          this.runquerylist = queries;
          this.applicationComponent.searchoffon = true;
        }
        var initValue = this.slide * 5;
        var finalVal = (initValue + 5);
        if (this.runquerylist.length < finalVal) {
          finalVal = this.runquerylist.length
        }
        for (var i = initValue; i < (finalVal); i++) {
          this.Newrunquerylist.push(this.runquerylist[i]);
        }
        this.Newrunquerylist.splice(0, 0, { id: 0, queryOrder: "Quick Search-0", delqueryId: 0, runby: 0 });
      });
      setTimeout(function () {
        $('#QueryTab0').addClass('green');
      }, 100);
    }

  }
  createColumnDefs(columnSelections, TableColumns) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleArray[3]) });
        }
        else if (fieldHeaderVisibleArray[0] == "customertype") {
          this.columnDefs.push({
            field: 'customertype', headerName: 'Customer Type 1', hide: (!this.isCustomer && !this.isFormerCustomer),
            cellRenderer(params) {
              if (params.value == '0') { return "Non - Customer" } else if (params.value == '56') { return "Customer" }
              else if (params.value == '57') { return "Former Customer" }
            }, width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
        else if (fieldHeaderVisibleArray[0] == "accnumber") {
          this.columnDefs.push({ field: 'accnumber', headerName: 'Acct #', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer), width: parseInt(fieldHeaderVisibleArray[3]) });
        }
        else if (fieldHeaderVisibleArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, width: parseInt(fieldHeaderVisibleArray[3]) });
        } else {
          this.columnDefs.push({
            field: fieldHeaderVisibleArray[0],
            headerName: fieldHeaderVisibleArray[1],
            hide: fieldHeaderVisibleArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
      }
    } else {

      if (this.searchtype == 'Account') {
        this.columnDefs = [
          {
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            enableTooltip: true
          }];
        TableColumns.forEach(ele => {
          if (ele.OldOrNew == 'Old') {
            switch (ele.ColumnName) {
              case 'id': {
                selfObj.columnDefs.push({ field: 'id', headerName: 'Id', hide: true });
                break;
              }
              case 'customertypeid': {
                selfObj.columnDefs.push({
                  field: 'customertypeid', headerName: 'Customer Type 2', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer),
                  cellRenderer(params) {
                    if (params.value == '0') { return "Non - Customer" } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
                  }
                });
                break;
              }
              case 'accnumber': {
                selfObj.columnDefs.push({
                  field: 'accnumber', headerName: 'Act Number', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer), cellStyle: { color: 'red' }
                });
                break;
              }
              case 'IsContact': {
                selfObj.columnDefs.push({ field: 'IsContact', headerName: ' Is Contact?', hide: false });
                break;
              }
              case 'accountname': {
                selfObj.columnDefs.push({
                  field: 'accountname', headerName: 'Account Name', hide: false
                });
                break;
              }
              case 'speciality': {
                selfObj.columnDefs.push({
                  field: 'specialityName', headerName: 'Specialty', hide: false
                });
                break;
              }
              case 'address1': {
                selfObj.columnDefs.push({
                  field: 'address1', headerName: 'Address1', hide: false
                });
                break;
              }
              case 'address2': {
                selfObj.columnDefs.push({
                  field: 'address2', headerName: 'Address2', hide: false
                });
                break;
              }
              case 'city': {
                selfObj.columnDefs.push({
                  field: 'city', headerName: 'City', hide: false
                });
                break;
              }
              case 'state': {
                selfObj.columnDefs.push({
                  field: 'state', headerName: 'State', hide: false
                });
                break;
              }
              case 'zip': {
                selfObj.columnDefs.push({
                  field: 'zip', headerName: 'Zip', hide: false
                });
                break;
              }
              case 'phone': {
                selfObj.columnDefs.push({
                  field: 'phone', headerName: 'Phone', hide: true
                });
                break;
              }
              case 'mapit': {
                selfObj.columnDefs.push({
                  field: 'mapit', headerName: 'Map It', hide: false, width: 80, cellRenderer(params) {
                    var eDiv = document.createElement('div');
                    eDiv.innerHTML = '<img src="../../../assets/img/Map it_white.png" style="height:20px;width:50%"/>';
                    return eDiv;
                  }
                });
                break;
              }
              case 'ehr': {
                selfObj.columnDefs.push({
                  field: 'ehrName', headerName: 'EHR', hide: false
                });
                break;
              }
              //case 'pm': {
              //  selfObj.columnDefs.push({
              //    field: 'pmName', headerName: 'PM', hide: false
              //  });
              //  break;
              //}
              case 'rcm': {
                selfObj.columnDefs.push({
                  field: 'rcmName', headerName: 'RCM', hide: false
                });
                break;
              }
              case 'ownerid': {
                selfObj.columnDefs.push({
                  field: 'username', headerName: 'User Name', hide: false
                });
                break;
              }
              case 'accounttype': {
                selfObj.columnDefs.push({ field: 'accounttypeName', headerName: 'Account Type 22', hide: true });
                break;
              }
              case 'ownership': {
                selfObj.columnDefs.push({ field: 'ownershipName', headerName: 'Ownership', hide: true });
                break;
              }
              case 'accountStatus': {
                selfObj.columnDefs.push({
                  field: 'accountstatus', headerName: 'Account Status', hide: true, cellRenderer: function (params) {
                    if (params.value == '14') {
                      return 'Active';
                    } else {
                      return 'On-Hold';
                    }
                  }
                });
                break;
              }
              case 'country': {
                selfObj.columnDefs.push({ field: 'country', headerName: 'Country', hide: true });
                break;
              }
              case 'ProvidenceIsland': {
                selfObj.columnDefs.push({ field: 'ProvidenceIsland', headerName: 'Providence Island', hide: true });
                break;
              }
              case 'fax': {
                selfObj.columnDefs.push({ field: 'fax', headerName: 'Fax', hide: true });
                break;
              }
              case 'alterphone1': {
                selfObj.columnDefs.push({ field: 'alterphone1', headerName: 'alterphone1', hide: true });
                break;
              }
              case 'alterphone2': {
                selfObj.columnDefs.push({ field: 'alterphone2', headerName: 'alterphone2', hide: true });
                break;
              }
              case 'website': {
                selfObj.columnDefs.push({ field: 'website', headerName: 'website', hide: true });
                break;
              }
              case 'pmdatepurchased': {
                selfObj.columnDefs.push({ field: 'pmdatepurchased', headerName: 'PM Date Purchased', hide: true });
                break;
              }
              case 'ehrdatepurchased': {
                selfObj.columnDefs.push({ field: 'ehrdatepurchased', headerName: 'EHR Date Purchased', hide: true });
                break;
              }
              case 'rcmdatepurchased': {
                selfObj.columnDefs.push({ field: 'rcmdatepurchased', headerName: 'RCM Date Purchased', hide: true });
                break;
              }
              case 'created': {
                selfObj.columnDefs.push({ field: 'created', headerName: 'Created Date', hide: true });
                break;
              }
              case 'leadfromid': {
                selfObj.columnDefs.push({ field: 'leadfromid', headerName: 'Lead From', hide: true });
                break;
              }

            }


          }
          else {

            if (ele.DataType == "Pick List") {
              selfObj.columnDefs.push({
                field: ele.ColumnName, headerName: ele.DisplayAs, hide: false, width: 200, cellRenderer(params) {

                  if (params.value != null && params.value != "") {
                    return params.value.split(":")[1];
                  } else {
                    return params.value;
                  }


                }
              })
            }
            else {
              if (ele.ColumnName == "IsCustomer") {
                selfObj.columnDefs.push({ field: "isCustomer", headerName: ele.DisplayAs, hide: false, width: 200 })
              } else {
                selfObj.columnDefs.push({ field: ele.ColumnName, headerName: ele.DisplayAs, hide: false, width: 200 })
              }
            }
          }
        });
      }
      else if (this.searchtype == 'Contact') {
        this.columnDefs = [
          {
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            enableTooltip: true
          }];

        TableColumns.forEach(ele => {
          if (ele.OldOrNew == 'Old') {
            switch (ele.ColumnName) {
              case 'customertype': {
                selfObj.columnDefs.push({ field: 'customertype', headerName: 'Customer Type 3', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer) })
                break
              }
              case 'accnumber': {
                selfObj.columnDefs.push({ field: 'accnumber', headerName: 'Acct #', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer) })
                break
              }
              case 'lastname': {
                selfObj.columnDefs.push({ field: 'lastname', headerName: 'Last Name', hide: false })
                break
              }
              case 'firstname': {
                selfObj.columnDefs.push({ field: 'firstname', headerName: 'First Name', hide: false })
                break
              }
              case 'middlename': {
                selfObj.columnDefs.push({ field: 'middlename', headerName: 'Middle Name', hide: false })
                break
              }
              case 'title': {
                selfObj.columnDefs.push({ field: 'titleName', headerName: 'Title', hide: false })
                break
              }
              case 'suffix': {
                selfObj.columnDefs.push({ field: 'suffixName', headerName: 'Suffix', hide: false })
                break
              }
              case 'sex': {
                selfObj.columnDefs.push({ field: 'sexName', headerName: 'Sex', hide: false, })
                break
              }

              case 'email': {
                selfObj.columnDefs.push({ field: 'email', headerName: 'Email', hide: true })
                break
              }
              case 'address1': {
                selfObj.columnDefs.push({ field: 'address1', headerName: 'Address1', hide: false, })
                break
              }
              case 'address2': {
                selfObj.columnDefs.push({ field: 'address2', headerName: 'Address2', hide: true, })
                break
              }
              case 'city': {
                selfObj.columnDefs.push({ field: 'city', headerName: 'City', hide: false })
                break
              }
              case 'state': {
                selfObj.columnDefs.push({ field: 'state', headerName: 'State', hide: true })
                break
              }
              case 'speciality': {
                selfObj.columnDefs.push({ field: 'specialityName', headerName: 'Specialty', hide: false })
                break
              }
              case 'subspeciality': {
                selfObj.columnDefs.push({ field: 'subspecialityName', headerName: 'Subspeciality', hide: true })
                break
              }
              case 'DOB': {
                selfObj.columnDefs.push({ field: 'DOB', headerName: 'DOB', hide: false })
                break
              }
              case 'zip': {
                selfObj.columnDefs.push({ field: 'zip', headerName: 'ZIP', hide: false })
                break
              }
              case 'accountname': {
                selfObj.columnDefs.push({ field: 'accountname', headerName: 'Account', hide: false })
                break
              }
              case 'cell': {
                selfObj.columnDefs.push({ field: 'cell', headerName: 'Cell', hide: true, })
                break
              }
              case 'office': {
                selfObj.columnDefs.push({ field: 'office', headerName: 'Office', hide: true, })
                break
              }
              case 'homeaddress': {
                selfObj.columnDefs.push({ field: 'homeaddress', headerName: 'Home Address', hide: true, })
                break
              }
              case 'homecity': {
                selfObj.columnDefs.push({ field: 'homecity', headerName: 'Home City', hide: true })
                break
              }
              case 'homestate': {
                selfObj.columnDefs.push({ field: 'homestate', headerName: 'Home State', hide: true, })
                break
              }
              case 'homezip': {
                selfObj.columnDefs.push({ field: 'homezip', headerName: 'Home Zip', hide: true, })
                break
              }
              case 'home': {
                selfObj.columnDefs.push({ field: 'home', headerName: 'Home', hide: true, })
                break
              }
              case 'college': {
                selfObj.columnDefs.push({ field: 'collegeName', headerName: 'College', hide: true, })
                break
              }
              case 'collegegrandyear': {
                selfObj.columnDefs.push({ field: 'collegegrandyear', headerName: 'C.G.year', hide: true, })
                break
              }
              case 'medschool': {
                selfObj.columnDefs.push({ field: 'medschoolName', headerName: 'Medschool', hide: true, })
                break
              }
              case 'medschoolgrandyear': {
                selfObj.columnDefs.push({ field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true, })
                break
              }
              case 'residency': {
                selfObj.columnDefs.push({ field: 'residencyName', headerName: 'Residency', hide: true, })
                break
              }
              case 'residencygrandyear': {
                selfObj.columnDefs.push({ field: 'residencygrandyear', headerName: 'R.G.year', hide: true, })
                break
              }
              case 'fellowship': {
                selfObj.columnDefs.push({ field: 'fellowshipName', headerName: 'Fellowship', hide: true, })
                break
              }
              case 'fellowshipgrandyear': {
                selfObj.columnDefs.push({ field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true, })
                break
              }
              case 'ownerid': {
                selfObj.columnDefs.push({ field: 'owner', headerName: 'User Name', hide: false, suppressSorting: true, })
                break
              }
              case 'created': {
                selfObj.columnDefs.push({ field: 'created', headerName: 'Created123', hide: true, })
                break
              }
              case 'Status': {
                selfObj.columnDefs.push({ field: 'Status', headerName: 'Created', hide: true, })
                break
              } case 'contacttype': {
                selfObj.columnDefs.push({ field: 'contacttype', headerName: 'Created', hide: true, })
                break
              }
              case 'country': {
                selfObj.columnDefs.push({ field: 'country ', headerName: 'Created', hide: true, })
                break
              } case 'AlternateNo1': {
                selfObj.columnDefs.push({ field: 'AlternateNo1', headerName: 'Created', hide: true, })
                break
              }


            }
          } else {
            selfObj.columnDefs.push({ field: ele.ColumnName, headerName: ele.DisplayAs, hide: false, width: 200 })
          }

        });

      }
      else {
        this.columnDefs = [
          {
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            enableTooltip: true
          }];
        TableColumns.forEach(ele => {
          if (ele.OldOrNew == 'Old') {
            switch (ele.ColumnName) {
              case 'id': {
                selfObj.columnDefs.push({ field: 'id', headerName: 'Id', hide: true });
                break;
              }
              case 'customertypeid': {
                selfObj.columnDefs.push({
                  field: 'customertypeid', headerName: 'Customer Type 5', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer),
                  cellRenderer(params) {
                    if (params.value == '0') { return "Non - Customer" } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
                  }
                });
                break;
              }
              case 'accnumber': {
                selfObj.columnDefs.push({
                  field: 'accnumber', headerName: 'Act Number', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer), cellStyle: { color: 'red' }
                });
                break;
              }
              case 'IsContact': {
                selfObj.columnDefs.push({ field: 'IsContact', headerName: ' Is Contact?', hide: false });
                break;
              }
              case 'accountname': {
                selfObj.columnDefs.push({
                  field: 'accountname', headerName: 'Account Name', hide: false
                });
                break;
              }
              case 'lastname': {
                selfObj.columnDefs.push({ field: 'lastname', headerName: 'Last Name', hide: false })
                break
              }
              case 'firstname': {
                selfObj.columnDefs.push({ field: 'firstname', headerName: 'First Name', hide: false })
                break
              }
              case 'middlename': {
                selfObj.columnDefs.push({ field: 'middlename', headerName: 'Middle Name', hide: false })
                break
              }
              case 'contacttype': {
                selfObj.columnDefs.push({ field: 'contacttypeName', headerName: 'Contact Type', hide: false })
                break
              }
              case 'speciality': {
                selfObj.columnDefs.push({
                  field: 'specialityName', headerName: 'Specialty', hide: false
                });
                break;
              }
              case 'address1': {
                selfObj.columnDefs.push({
                  field: 'address1', headerName: 'Address1', hide: false
                });
                break;
              }
              case 'address2': {
                selfObj.columnDefs.push({
                  field: 'address2', headerName: 'Address2', hide: false
                });
                break;
              }
              case 'city': {
                selfObj.columnDefs.push({
                  field: 'city', headerName: 'City', hide: false
                });
                break;
              }
              case 'state': {
                selfObj.columnDefs.push({
                  field: 'state', headerName: 'State', hide: false
                });
                break;
              }
              case 'zip': {
                selfObj.columnDefs.push({
                  field: 'zip', headerName: 'Zip', hide: false
                });
                break;
              }
              case 'phone': {
                selfObj.columnDefs.push({
                  field: 'phone', headerName: 'Phone', hide: true
                });
                break;
              }
              case 'mapit': {
                selfObj.columnDefs.push({
                  field: 'mapit', headerName: 'Map It', hide: false, width: 80, cellRenderer(params) {
                    var eDiv = document.createElement('div');
                    eDiv.innerHTML = '<img src="../../../assets/img/Map it_white.png" style="height:20px;width:50%"/>';
                    return eDiv;
                  }
                });
                break;
              }
              case 'ehr': {
                selfObj.columnDefs.push({
                  field: 'ehrName', headerName: 'EHR', hide: false
                });
                break;
              }
              case 'pm': {
                selfObj.columnDefs.push({
                  field: 'pmName', headerName: 'PM', hide: false
                });
                break;
              }
              case 'rcm': {
                selfObj.columnDefs.push({
                  field: 'rcmName', headerName: 'RCM', hide: false
                });
                break;
              }
              case 'ownerid': {
                selfObj.columnDefs.push({
                  field: 'OWNER', headerName: 'Owner ', hide: false
                });
                break;
              }
              case 'accounttype': {
                selfObj.columnDefs.push({ field: 'accounttypeName', headerName: 'Account Type 11', hide: true });
                break;
              }
              case 'ownership': {
                selfObj.columnDefs.push({ field: 'ownershipName', headerName: 'Ownership', hide: true });
                break;
              }
              case 'accountStatus': {
                selfObj.columnDefs.push({
                  field: 'accountstatus', headerName: 'Account Status', hide: true,
                  cellRenderer: function (params) {
                    if (params.value == '14') {
                      return 'Active';
                    } else {
                      return 'On-Hold';
                    }
                  }
                });
                break;
              }
              case 'country': {
                selfObj.columnDefs.push({
                  field: 'country', headerName: 'Country', hide: true, cellRenderer: function (params) {
                    if (params.value == '881') {
                      return 'USA';
                    } else if (params.value == '882') {
                      return 'Bahamas';
                    } else {
                      return params.value;
                    }
                  }
                });
                break;
              }
              case 'ProvidenceIsland': {
                selfObj.columnDefs.push({ field: 'ProvidenceIsland', headerName: 'Providence Island', hide: true });
                break;
              }
              case 'fax': {
                selfObj.columnDefs.push({ field: 'fax', headerName: 'Fax', hide: true });
                break;
              }
              case 'alterphone1': {
                selfObj.columnDefs.push({ field: 'alterphone1', headerName: 'alterphone1', hide: true });
                break;
              }
              case 'alterphone2': {
                selfObj.columnDefs.push({ field: 'alterphone2', headerName: 'alterphone2', hide: true });
                break;
              }
              case 'website': {
                selfObj.columnDefs.push({ field: 'website', headerName: 'website', hide: true });
                break;
              }
              case 'pmdatepurchased': {
                selfObj.columnDefs.push({ field: 'pmdatepurchased', headerName: 'PM Date Purchased', hide: true });
                break;
              }
              case 'ehrdatepurchased': {
                selfObj.columnDefs.push({ field: 'ehrdatepurchased', headerName: 'EHR Date Purchased', hide: true });
                break;
              }
              case 'rcmdatepurchased': {
                selfObj.columnDefs.push({ field: 'rcmdatepurchased', headerName: 'RCM Date Purchased', hide: true });
                break;
              }
              case 'created': {
                selfObj.columnDefs.push({ field: 'created', headerName: 'Created Date', hide: true });
                break;
              }
              case 'leadfromid': {
                selfObj.columnDefs.push({ field: 'leadfromid', headerName: 'Lead From', hide: true });
                break;
              }
              case 'customertype': {
                selfObj.columnDefs.push({ field: 'customertype', headerName: 'Customer Type 6', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer) })
                break
              }
              case 'accnumber': {
                selfObj.columnDefs.push({ field: 'accnumber', headerName: 'Acct #', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer) })
                break
              }

              case 'title': {
                selfObj.columnDefs.push({ field: 'titleName', headerName: 'Title', hide: false })
                break
              }
              case 'suffix': {
                selfObj.columnDefs.push({ field: 'suffixName', headerName: 'Suffix', hide: false })
                break
              }
              case 'sex': {
                selfObj.columnDefs.push({ field: 'sexName', headerName: 'Sex', hide: false, })
                break
              }

              case 'email': {
                selfObj.columnDefs.push({ field: 'email', headerName: 'Email', hide: true })
                break
              }
              case 'address1': {
                selfObj.columnDefs.push({ field: 'address1', headerName: 'Address1', hide: false, })
                break
              }
              case 'address2': {
                selfObj.columnDefs.push({ field: 'address2', headerName: 'Address2', hide: true, })
                break
              }
              case 'city': {
                selfObj.columnDefs.push({ field: 'city', headerName: 'City', hide: false })
                break
              }
              case 'state': {
                selfObj.columnDefs.push({ field: 'state', headerName: 'State', hide: true })
                break
              }
              case 'speciality': {
                selfObj.columnDefs.push({ field: 'specialityName', headerName: 'Specialty', hide: false })
                break
              }
              case 'subspeciality': {
                selfObj.columnDefs.push({ field: 'subspecialityName', headerName: 'Subspeciality', hide: true })
                break
              }
              case 'DOB': {
                selfObj.columnDefs.push({ field: 'DOB', headerName: 'DOB', hide: false })
                break
              }
              case 'zip': {
                selfObj.columnDefs.push({ field: 'zip', headerName: 'ZIP', hide: false })
                break
              }
              case 'accountname': {
                selfObj.columnDefs.push({ field: 'accountname', headerName: 'Account', hide: false })
                break
              }
              case 'cell': {
                selfObj.columnDefs.push({ field: 'cell', headerName: 'Cell', hide: true, })
                break
              }
              case 'office': {
                selfObj.columnDefs.push({ field: 'office', headerName: 'Office', hide: true, })
                break
              }
              case 'homeaddress': {
                selfObj.columnDefs.push({ field: 'homeaddress', headerName: 'Home Address', hide: true, })
                break
              }
              case 'homecity': {
                selfObj.columnDefs.push({ field: 'homecity', headerName: 'Home City', hide: true })
                break
              }
              case 'homestate': {
                selfObj.columnDefs.push({ field: 'homestate', headerName: 'Home State', hide: true, })
                break
              }
              case 'homezip': {
                selfObj.columnDefs.push({ field: 'homezip', headerName: 'Home Zip', hide: true, })
                break
              }
              case 'home': {
                selfObj.columnDefs.push({ field: 'home', headerName: 'Home', hide: true, })
                break
              }
              case 'college': {
                selfObj.columnDefs.push({ field: 'collegeName', headerName: 'College', hide: true, })
                break
              }
              case 'collegegrandyear': {
                selfObj.columnDefs.push({ field: 'collegegrandyear', headerName: 'C.G.year', hide: true, })
                break
              }
              case 'medschool': {
                selfObj.columnDefs.push({ field: 'medschoolName', headerName: 'Medschool', hide: true, })
                break
              }
              case 'medschoolgrandyear': {
                selfObj.columnDefs.push({ field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true, })
                break
              }
              case 'residency': {
                selfObj.columnDefs.push({ field: 'residencyName', headerName: 'Residency', hide: true, })
                break
              }
              case 'residencygrandyear': {
                selfObj.columnDefs.push({ field: 'residencygrandyear', headerName: 'R.G.year', hide: true, })
                break
              }
              case 'fellowship': {
                selfObj.columnDefs.push({ field: 'fellowshipName', headerName: 'Fellowship', hide: true, })
                break
              }
              case 'fellowshipgrandyear': {
                selfObj.columnDefs.push({ field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true, })
                break
              }
              case 'ownerid': {
                selfObj.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true, })
                break
              }
              case 'created': {
                selfObj.columnDefs.push({ field: 'created', headerName: 'Created123', hide: true, })
                break
              }
              case 'Status': {
                selfObj.columnDefs.push({ field: 'Status', headerName: 'Contact Status', hide: true, })
                break
              } case 'contacttype': {
                selfObj.columnDefs.push({ field: 'contacttype', headerName: 'Created', hide: true, })
                break
              }
              case 'country': {
                selfObj.columnDefs.push({ field: 'country', headerName: 'Country', hide: true, })
                break
              } case 'AlternateNo1': {
                selfObj.columnDefs.push({ field: 'AlternateNo1', headerName: 'Created', hide: true, })
                break
              }
            }
          }
          else {
            if (ele.DataType == "Pick List") {
              if (ele.ColumnName == "rcm") {
                selfObj.columnDefs.push({
                  field: 'rcmName', headerName: 'RCM', hide: false
                });
              }
              else if (ele.ColumnName == "IsCustomer") {
                selfObj.columnDefs.push({
                  field: "isCustomer", headerName: ele.DisplayAs, hide: false, width: 200, cellRenderer(params) {
                    if (params.value == '875') {
                      return 'Yes';
                    } else if (params.value == '874') {
                      return 'No';
                    } {
                      return ' ';
                    }

                  }
                })
              } else if (ele.ColumnName == "STATUS") {
                selfObj.columnDefs.push({
                  field: 'constatus', headerName: ele.DisplayAs, hide: false
                });
              }
              else {

                selfObj.columnDefs.push({
                  field: (ele.ColumnName == 'accounttype') ? 'accounttypeName' : (ele.ColumnName == 'ownership') ? 'ownershipName' : (ele.ColumnName == 'customertypeid') ? 'customertypeid' :
                    (ele.ColumnName == 'accountStatus') ? 'accountStatus' : ele.ColumnName, headerName: ele.DisplayAs, hide: false, width: 200, cellRenderer(params) {

                      if (params.column.colId == "accountStatus") {
                        if (params.value == '14') {
                          return 'Active';
                        } else {
                          return 'On-Hold';
                        }
                      } else if (params.column.colId == "customertypeid") {
                        if (params.value == '0') { return "Non - Customer" } else if (params.value == '1') {
                          return "Current Customer"
                        } else if (params.value == '2') { return "Former Customer" }
                      }
                      else {
                        return params.value;
                      }
                      //}


                    }
                })
              }
            }
            else {
              if (ele.ColumnName == "IsCustomer") {
                selfObj.columnDefs.push({ field: "isCustomer", headerName: ele.DisplayAs, hide: false, width: 200 })
              } else {

                selfObj.columnDefs.push({ field: ele.ColumnName, headerName: ele.DisplayAs, hide: false, width: 200 })
              }

            }
          }

        });

      }



    }

  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }

  rowDoubleClickAgGrid(rowEvent) {
    //this.acccontactcomp.gridOptions.rowSelection = 'single';

    this.showContactDashBoard = true;
    this.applicationComponent.googlemapshow = false;
    if (this.searchtype == 'Account') {
     
      this.isShowAccountInfo = true;
      this.accountid = rowEvent.data.id;
    }
    this.contactid = rowEvent.data.id;
    this.accountid = rowEvent.data.id;
    this.SearchResultGridOptions.rowSelection = 'single';
    
  }
  GridClose() {
    this.showTabledata = false;''
    this.CloseDialog = true;
    this.dialogsize();
  }

  rowClickAgGrid(rowEvent) {
    if ((this.Ids).indexOf(rowEvent.data.id) > -1) {
      var index = (this.Ids).indexOf(rowEvent.data.id);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
      this.AccConIds = string;
     
      $('#emailIds').val(string);
      $('#tasklistselectedtyle').val('');
    } else {
      this.Ids.push(rowEvent.data.id)
      var string = (this.Ids).toString();
      this.AccConIds = string;
      $('#emailIds').val(this.gridApi.getSelectedRows().map(x => x.id).join(','));
      if (rowEvent.data.type == "Contact" || rowEvent.data.firstname != null) {
        $('#tasklistselectedtyle').val('Contact');
      }
      else if (rowEvent.data.type == "Account" || rowEvent.data.firstname == null) {
        $('#tasklistselectedtyle').val('Customer');
      }
    }
  
  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        if (this.searchtype == 'Account') {
          this.acccontactcomp.AccountData('show' + event.api.getSelectedNodes()[0].data.id);
          this.acccontactcomp.ShowContactView = false;
          this.SearchResultGridOptions.rowSelection = 'single';
        }
        else {
          this.contactid = event.api.getSelectedNodes()[0].data.contactid;
          this.condashcmp.GetContactInfo(event.api.getSelectedNodes()[0].data.contactid);
        }
      }
    }
  }

  dialogsize() {
    setTimeout(function () {
      $('.dialog').attr('style', ' width: 32%;padding - bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min - height: 23%;overflow: visible;height: 24%;');

    }, 4)
  }
  onContactMessageReceived(msg) {
    this.isShowAccountInfo = true;
  }

  expandContactView(collapse) {
    this.ShowGrid = collapse;
  }

  CloseContactDiv() {
    this.isShowAccountInfo = false;
    this.showContactDashBoard = false;
    this.ShowGrid = true;
    this.SearchResultGridOptions.rowSelection = 'multiple';
  }
  close() { this.applicationComponent.AdvancedSearchResult = false; }
}

