<div class="col-xs-12" style="text-align:center;margin-left: 0%;padding:0;overflow:auto;height:100%">
  <div class="col-xs-12" style="padding:0;height:100%" *ngIf="type =='Activities' || type =='FutureTask' ">
    <div class="col-xs-12" style="padding:0;height:5%">

      <div *ngIf="type=='Activities'" class="col-xs-2" style="text-align:left;height:100%">
        <!--<span style="margin: 0; font-size: 15px; font-weight: bold; ">ACTIVITIES</span>-->
      </div>
      <div *ngIf="type=='FutureTask'" class="col-xs-12" style="text-align: center; height: 100%; font-weight: bold; background-color: rgb(114, 205, 239) ">
        <h5 style="margin: 0; margin-top:5px">FUTURE SCHEDULED TASKS</h5>
      </div>
      <div class="col-xs-9" style="padding:0;height:100%;float:right" *ngIf="type =='Activities'">

        <div class="col-xs-4" style="padding:0 0 0 0;height:100%">

        </div>
        <div class="col-xs-4" style="height: 100%;">
          <select style="height:90%;width:100%;border-radius:5px;" (change)="tasklisttypes($event.target.value)">
            <option *ngFor="let act of ActivitiesDropdownList" value={{act.value}}>
              {{act.itemName}}
            </option>
          </select>
        </div>
        <div class="col-xs-4" style="height: 100%;">
          <select style="height:90%;width:100%;border-radius:5px;" (change)="StatusDropdownChange($event.target.value)">
            <option *ngFor="let stat of StatusDropdownList" value={{stat.value}}>
              {{stat.itemName}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-xs-12" style="padding:0;height:94%">
      <ag-grid-angular style="height:100%" class="ag-theme-balham"
                       [gridOptions]="gridOptions"
                       [columnDefs]="columnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="alltaskhistory"
                       [context]="context"
                       [pagination]="true"
                       [sideBar]="sideBar"
                       (selectionChanged)="selectionactivitiesChanged($event)">
      </ag-grid-angular>


    </div>
  </div>

  <div class="col-xs-12" style="padding:0;height:100%" *ngIf="type == 'Tickets'">

    <div class="col-xs-12" style="padding:0;height:7%">
      <div class="col-xs-12" style="text-align:left;">
        <div class="col-xs-9" style="padding: 0; height: 100%;">
          <img (click)="OpenSpecailNote()" src="../../../../assets/img/Notes.png" style="height:25px;" title="Notes" />
        </div>
        <div class="col-xs-3" style="height: 100%;">
          <select class="form-control decreasefieldSize" style="height:90%;width:100%;width:105%;border-radius:5px;" (change)="TicketChange($event.target.value)">
            <option *ngFor="let stat of StatusDropdownList" value={{stat.id}}>
              {{stat.itemName}}
            </option>
          </select>
        </div>
      </div>


    </div>

    <div class="col-xs-12" style="padding:0;height:93%">
      <div *ngIf="!openTaskhistory" class="col-xs-12" style="background-color:#ffffb4;height:100%">
        <div class="col-xs-12" style="font-size: 24px;"> SPECIAL NOTES FOR THIS ACCOUNT</div>
        <div class="col-xs-12">
          <textarea rows="22" cols="110" [(ngModel)]="specialnotes.specialNotes" style="border: 3px solid gray; background-color: rgb(255, 255, 180);">
            </textarea>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-12" style="padding: 0;margin-top:1%; ">
            <div class="col-xs-8">
            </div>
            <div class="col-xs-4" style="padding: 0;">
              <div class="col-xs-6" style="padding: 0;">
                <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="cancelSpecial()">Cancel</button>
              </div>
              <div class="col-xs-6" style="padding-right: 0;">
                <button type="submit" class="btn saveButton" style="width:100%;" (click)="SaveSpecialNote()">Save  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ag-grid-angular *ngIf="openTaskhistory" id="taskGrid"
                       style="width: 100%;height:100%;" class="ag-theme-balham"
                       [gridOptions]="ticketsgridOptions"
                       [columnDefs]="ticketscolumnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="ticketslist"
                       [sideBar]="sideBar"
                       [pagination]="true"
                       (rowSelected)="rowClickAgGrid($event)"
                       (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                       (selectionChanged)="selectionTicketChanged($event)">
      </ag-grid-angular>
    </div>

  </div>


  <div class="col-xs-12" style="padding:0;height:100%" *ngIf="type =='Opportunities'">
    <div class="col-xs-12" style="padding:0;height:5%">

      <div class="col-xs-6" style="text-align:left;height:100%">
      </div>

      <div class="col-xs-5" style="padding:0;height:100%;float:right">
        <div class="col-xs-4" style="padding:0.5% 0 0 0;height:100%">
        </div>
        <div class="col-xs-7" style="padding:0;height:100%;width:64.1%">
          <select style="height:90%;width:100%;border-radius:5px;" (change)="OpportunitiesTypeChange($event.target.value)">
            <option *ngFor="let act of ActivitiesDropdownList" value={{act.value}}>
              {{act.itemName}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-xs-12" style="padding:0;height:94%">
      <ag-grid-angular class="ag-theme-balham"
                       style="height:100%;width:99%"
                       [gridOptions]="OpportunityGridOptions"
                       [columnDefs]="OpportunitycolumnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="Allopportunities"
                       [sideBar]="sideBar"
                       [pagination]="true"
                       (selectionChanged)="OpportunitiesSelectionChanged($event)">
      </ag-grid-angular>

    </div>
    <app-dialog [(visible)]="showhistorypdf1" id="showhistorypdf1" *ngIf="showhistorypdf1" [CloseButtonColor]="'white'">
      <div id="historypdfDiv" class="col-xs-12" style="padding:0;">
      </div>
    </app-dialog>
  </div>

  <div class="col-xs-12" style="padding:0;height:100%" *ngIf="type =='Projects'">
    <div class="col-xs-12" style="padding:0;height:5%">
      <div class="col-xs-8" style="text-align:left;height:100%">
        <!--<span style="margin: 0; font-size: 15px; font-weight: bold;">PROJECTS</span>-->
      </div>
      <div class="col-xs-4" style="padding:0;height:100%;float:right">
        <div class="col-xs-3" style="padding:0.5% 0 0 0;height:100%">
        </div>
        <div class="col-xs-6" style="padding: 0; height: 100%; width: 72.3%">
          <select style="height:90%;width:100%;border-radius:5px;" (change)="ProjectsTypeChange($event.target.value)">
            <option *ngFor="let proj of ProjectsDropdownList" value={{proj.value}}>
              {{proj.itemName}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-xs-12" style="padding:0;height:94%">
      <ag-grid-angular class="ag-theme-balham"
                       style="height:100%;width:99%"
                       [gridOptions]="ProjectsgridOptions"
                       [columnDefs]="ProjectscolumnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="ProjectsList"
                       [sideBar]="sideBar"
                       [pagination]="true"
                       (rowDoubleClicked)="ProjectrowDoubleClickAgGrid($event)"
                       (selectionChanged)="ProjectsSelectionChanged($event)">
      </ag-grid-angular>
    </div>
  </div>
  <div class="col-xs-12" style="padding:0;height:100%" *ngIf="type =='sms'">
    <div class="col-xs-12" style="padding:0;height:5%">

    </div>

    <div class="col-xs-12" style="padding:0;height:94%">



    </div>
  </div>
  
</div>


<div *ngIf="showTicketsDetailsDialog" class="detailsDialog" id="showTicketsDetailsDialog"
     style="height: 86%; padding: 0; width: 35%;margin-top:-3.5%">

    <div class="col-xs-12" style="padding: 1% 0% 0% 0; height: 3%; background-color: #fb637e ">    
    </div>
    <div class="col-xs-12" style="padding: 1% 0% 0% 0; height: 4%;border-bottom:1px solid gray;">
      <div class="col-xs-9">
        <b>TICKET</b>
      </div>
      <div class="col-xs-1" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;padding:2px;float:right;z-index:1">
        <b> <img src="../../../assets/img/Black x.png" style="height:22px" (click)="closeDetailsDialog('Ticket')" /></b>

      </div>
    </div>

    <div class="col-xs-12 scroll-c" style="height:94%;padding-right:0;">
      <div class="col-xs-12" style="padding:0;height:92%;text-align:left">
        <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
          <div class="col-xs-3" style="padding: 0px">
            <label style="font-size:13px">Date Created:</label>
          </div>
          <div class="col-xs-9" style="padding: 0px">
            <b style="font-size: 13px;"> {{TicketDetails.createdDate| date:"MM-dd-yyyy"}}</b>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
          <div class="col-xs-3" style="padding: 0px">
            <label style="font-size: 13px">Time Created:</label>
          </div>
          <div class="col-xs-9" style="padding: 0px">
            <b style="font-size: 12px;"> {{TicketDetails.createdDate | date:"HH:MM a"}}</b>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
          <div class="col-xs-3" style="padding: 0px">
            <label style="font-size:11px">Contact:</label>
          </div>
          <div class="col-xs-9" style="padding: 0px">
            <b style="font-size: 13px;"> {{TicketDetails.contactname}}</b>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
          <div class="col-xs-3" style="padding: 0px">
            <label style="font-size: 13px">Account:</label>
          </div>
          <div class="col-xs-9" style="padding: 0px">
            <b style="font-size: 13px;"> {{TicketDetails.accountName}}</b>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
          <div class="col-xs-3" style="padding: 0px">
            <label style="font-size: 13px">Created By:</label>
          </div>
          <div class="col-xs-9" style="padding: 0px">
            <b style="font-size: 13px;"> {{TicketDetails.UserName}}</b>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
          <div class="col-xs-3" style="padding: 0px">
            <label style="font-size:11px">Ticket Number:</label>
          </div>
          <div class="col-xs-9" style="padding: 0px">
            <b style="font-size: 12px;"> {{TicketDetails.ticketId}}</b>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
          <div class="col-xs-3" style="padding: 0px">
            <label style="font-size: 13px">Subject:</label>
          </div>
          <div class="col-xs-9" style="padding: 0px">
            <b style="font-size: 13px;"> {{TicketDetails.subject}}</b>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
          <div class="col-xs-3" style="padding: 0px">
            <label style="font-size:11px">Assigned To:</label>
          </div>
          <div class="col-xs-9" style="padding: 0px">
            <b style="font-size: 12px;"> {{TicketDetails.userList}}</b>
          </div>
        </div>

        <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
          <div class="col-xs-3" style="padding: 0px">
            <label style="font-size: 13px">CC:</label>
          </div>
          <div class="col-xs-9" style="padding: 0px">
            <b style="font-size: 13px;"> {{TicketDetails.CCToName}}{{TicketDetails.CCTotaskName}}</b>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
          <div class="col-xs-3" style="padding: 0px">
            <label style="font-size: 13px">Status:</label>
          </div>
          <div class="col-xs-9" style="padding: 0px">
            <b style="font-size: 13px;"> {{TicketDetails.TicketStatus}}</b>
          </div>
        </div>
        <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
          <div class="col-xs-3" style="padding: 0px">
            <label style="font-size: 13px">Date Closed:</label>
          </div>
          <div class="col-xs-9" style="padding: 0px">

          </div>
        </div>
        <div class="col-xs-12" style="padding: 0;height:100%;">
          <label>Previous Notes:&nbsp;</label>
          <div class="col-xs-12    scroll-c" style="padding: 1% 1% 1% 2%; height:310px; overflow: auto; border: 2px solid #fb637e; display: flex">
            <div class="col-xs-10" style=" padding: 0;">
              <div class="col-xs-12" style="padding: 0; margin-top: 1%; font-size: 15px; margin-bottom: 5%" *ngFor="let Note of TicketDetails.ticketNotes">
                <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; font-weight: bold; height: 20px;"><b>{{Note.CreatedDate}} - {{Note.username}}</b></div>

                <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; height: 20px;">{{Note.Notes}}</div>
              </div>
            </div>

          </div>
          <!--<div class="col-xs-12    scroll-c" style="padding: 1% 1% 1% 2%; height: 100%; overflow: auto; border: 2px solid #777c; display: flex">
          <span *ngFor="let Note of TicketDetails.ticketNotes">{{Note.CreatedDate}}<br />{{Note.username}}<br />{{Note.Notes}}<br /><br /></span>

        </div>-->
        </div>

      </div>
      <div class="col-xs-12" style="padding:0;height:7%;text-align:center">
        <a *ngIf="taskhistory.status==='Open'" (click)="OpenTask()" style="color:black;text-decoration: underline;cursor: pointer;font-weight:600">OPEN TASK</a>
      </div>
    </div>
  
</div>

<div *ngIf="showActivityDetailsDialog" class="detailsDialog" id="showActivityDetailsDialog"
     style="height: 86%; padding: 0; width: 35%;margin-top: -3.3%">
  <div class="col-xs-12" style="padding: 0; height: 100%;">
    <div class="col-xs-12" style="padding:1% 0% 0% 0%; height:3%; background-color: #a8f1f1">
     
    </div>
    <div class="col-xs-12" style="height:4%;padding:0%;border-bottom:1px solid gray;">
      <div class="col-xs-9">
        <b>TASK | ACTIVITY</b>
      </div>
      <div class="col-xs-1" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;float:right;z-index:1">
        <b> <img src="../../../assets/img/Black x.png" style="height:20px" (click)="closeDetailsDialog('Activity')" /></b>

      </div>
    </div>

    <div class="col-xs-12" style="height:92%;background-color:white">
      <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Date Created:</label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <b style="font-size: 13px;"> {{taskhistory.datecreated | date:"MM-dd-yyyy"}}</b>
        </div>

      </div>
      <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Time Created:</label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <b style="font-size: 13px;"> {{taskhistory.datecreated | date:"HH:MM a"}}</b>
        </div>

      </div>
      <div class="col-xs-12" style="padding: 0;padding-top: 5px;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Contact:</label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <b style="font-size: 13px;"> {{taskhistory.lastName | uppercase}} , {{taskhistory.firstName | uppercase}}</b>
        </div>

      </div>
      <div class="col-xs-12" style="padding: 0;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Account:</label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <label style="font-size:13px"> {{taskhistory.accountname}}</label>
        </div>

      </div>
      <div class="col-xs-12" style="padding: 0;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Created By : </label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <label style="font-size: 13px;">{{taskhistory.owner}} </label>
        </div>
      </div>
      <div class="col-xs-12" style="padding: 0;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Status : </label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <label style="font-size: 13px;"> {{taskhistory.status}}</label>
        </div>
      </div>
      <div class="col-xs-12" style="padding: 0;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Date Closed : </label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <label style="font-size: 13px;"> {{taskhistory.estcompleteddate | date:"MM-dd-yyyy"}}</label>
        </div>
      </div>
      <div class="col-xs-12" style="padding: 0;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Task:</label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <label style="font-size: 13px;"> {{taskhistory.taskcategory}}</label>
        </div>

      </div>
      <div class="col-xs-12" style="padding: 0;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Type:</label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <label style="font-size: 13px;"> {{taskhistory.tasktype}}</label>
        </div>
      </div>

      <div class="col-xs-12" style="padding: 0;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Group : </label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <label style="font-size: 13px;"> {{(taskhistory.taskGroupID == 2)? 'Trainer' :  'Sales' }}</label>
        </div>

      </div>
      <div class="col-xs-12" style="padding: 0;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Date Scheduled : </label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <label style="font-size: 13px;"> {{taskhistory.estcompleteddate | date:"MM-dd-yyyy"}}</label>
        </div>

      </div>
      <div class="col-xs-12" style="padding: 0;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Time Scheduled : </label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <label style="font-size: 13px;"> {{taskhistory.estcompleteddate | date:"HH:MM a"}}</label>
        </div>

      </div>
      <div class="col-xs-12" style="padding: 0;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">Start Time: </label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <label style="font-size: 13px;"> {{taskhistory.starttime}}</label>
        </div>
      </div>
      <div class="col-xs-12" style="padding: 0;">
        <div class="col-xs-3" style="padding: 0px">
          <label style="font-size:13px">End Time: </label>
        </div>
        <div class="col-xs-9" style="padding: 0px">
          <label style="font-size: 13px;"> {{taskhistory.endtime}}</label>
        </div>
      </div>

      <div class="col-xs-12" style="padding: 0;height:40%;">
        <label>Previous Notes:&nbsp;</label>
        <div class="col-xs-12    scroll-c" style="padding: 1% 1% 1% 2%; height: 100%; overflow: auto; border: 2px solid #a8f1f1; display: flex">
          <div class="col-xs-10" style=" padding: 0;">
            <div class="col-xs-12" style="padding:0;margin-top:1%;font-size:15px;margin-bottom:5%" *ngFor="let note of taskhistorynotes">
              <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; font-weight: bold; height: 20px;"><b>{{note.created | date:"MM/dd/yy"}}</b></div>

              <div class="col-xs-12" style="padding: 0%; margin-top: 0.5%; height: 20px;">{{note.notedescription}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>

</div>
