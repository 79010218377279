<div class="col-xs-12" style="padding: 0;height: 100%;">
 
  <div class="col-xs-12" style="height:6%">
    <userdataheader [parentComp]="'PendedProposal'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
  </div>
 
  <div class="col-xs-12" style="padding: 0 0 0 1%; height: 81%">
    <ag-grid-angular style="height:100%;" class="ag-theme-balham"
                     [gridOptions]="gridOptions"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="AllPendedProposals"
                     [context]="context"
                     [sideBar]="sideBar"
                     [frameworkComponents]="frameworkComponents"
                     (cellDoubleClicked)="cellDoubleClicked($event)">
    </ag-grid-angular>

  </div>
</div>

<!--<app-dialog [(visible)]="isloader" *ngIf="isloader" id="isloader" [closable]="false">
  <ng4-loading-spinner [threshold]="2000" [timeout]="8000" [loadingText]="'Please wait...'" [zIndex]="9999"></ng4-loading-spinner>
</app-dialog>-->

<app-dialog [(visible)]="DeleteConfirmation" *ngIf="DeleteConfirmation" id="DeleteConfirmation" [closable]="false">
  <app-delete-popup [title]="'Pended Proposal'" [description]="(Proposal.isdeleted==1)?'Activate this Pended Proposal Selected?':'Delete this Pended Proposal Selected?'"
                    [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true"
                    (sendMsgEvent)="Confirmation($event)">
  </app-delete-popup>
</app-dialog>
