export class crmModel {
  public name: string;
  public title: string;
  public description: string;
  public issave: boolean;
  public iscancel: boolean;
  public isdiscard: boolean;
  public isok: boolean;
  public Idval: number;
  public idvalues: string;
}
