export class Viewaccount {
  public viewaccountname: string;
  public viewaddress1: string;
  public viewaddress2: string;
  public viewcity: string;
  public viewstate: string;
  public viewzip: string;
  public viewphone: string;
  public viewfax: string;
  public viewalterphone1: string;
  public viewalterphone2: string;
  public viewwebsite: string;
  public viewCountry: string;
  public Accountiddata: string;
  public accountId: number;
  public AccountHistoryNote: string;
  public ProvidenceIsland: string;
  public ownerName: string;
  //public viewownerid: number;
  //public viewmultiplelocations: number;
}
