import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GridOptions, RowDragEndEvent } from 'ag-grid';
import { OpportunityFields, OpportunityItems, OpportunityLeadTypes } from 'src/app/models/OpportunityLeadTypes';
import { UserService } from 'src/app/services/user.service';
import { ApplicationComponent } from '../application.component';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { SystemRowConfiguration } from '../../models/system-row-configuration';
import { LeadsService } from '../../services/leads.service';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';


declare var $: any;
@Component({
  selector: 'app-opportunities-configuration',
  templateUrl: './opportunities-configuration.component.html',
  styleUrls: ['./opportunities-configuration.component.css']
})
export class OpportunitiesConfigurationComponent implements OnInit {
  currentUser: any;
  OpportunityConfigDiv: boolean = true;
  FieldConfigDiv: boolean = true;
  FieldChoiceConfigDiv: boolean = false;
  OpportunityFieldsList: OpportunityFields[] = [];
  DuplicateOpportunityFieldsList: OpportunityFields[] = [];
  public gridOptionsView: GridOptions;
  public columnDefs;
  public sideBar;
  ShowOppFieldForm: boolean= false;
  OpportunityFieldform: FormGroup;
  OpportunityFields: OpportunityFields = new OpportunityFields();
  FiledDataList: OpportunityItems[] = [];
  DuplicateFiledDataList: OpportunityItems[] = [];
  ShowFieldItemTable: boolean = false;
  SelectedOppField: OpportunityFields;
  PickListIds = [];
  DeletePickListObj: OpportunityItems;
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration(); public ConfigFeildscolumnDefs;
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  subs = new Subscription(); public gridConfigFeildsView: GridOptions;
  private gridApi;
  constructor(private userService: UserService, private toastr: ToastrService, private OpportunityService: OpprtunitiesService, public applicationComponent: ApplicationComponent, private leadsService: LeadsService, private dragulaService: DragulaService) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this.subs.add(this.dragulaService.drop("Category-bag")
      .subscribe(({ name, el, target, source, sibling }) => {
        
        this.CategoryOrder();
        
       
      })
    );
  }

  ngOnInit() {
    this.ShowDiv('Fields Configuration');  
  }

  createdConfigFeildsColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.ConfigFeildscolumnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, rowDrag: true
      },
      { field: 'SortOrder', headerName: 'Sort Order', hide: false },

      { field: 'opportunityitem', headerName: 'Pick List Name', hide: false },

      {
        field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right', cellRenderer(params) {
          var eDiv = document.createElement('div');

          eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';

          eDiv.addEventListener('click', function () {
            selfObj.DeletePickList(params.data);
          });
          return eDiv;
        }
      }
    ];
  }
  ShowDiv(val) {
    if (val == 'Fields Configuration') {
      this.OpportunityConfigDiv = false;
      this.FieldConfigDiv = true;
      this.FieldChoiceConfigDiv = false;
      this.getOpportunityFields();
    } else if (val == 'Fields Choice Configuration') {
      this.OpportunityConfigDiv = false;
      this.FieldConfigDiv = false;
      this.FieldChoiceConfigDiv = true;
      this.getOpportunityFields();
    }
  }
  CloseaccessDiv() {
      this.OpportunityConfigDiv = true;
      this.FieldConfigDiv = false;
    this.FieldChoiceConfigDiv = false;
    this.OpportunityFields = new OpportunityFields();
    this.ShowOppFieldForm = false;
    this.ShowFieldItemTable = false;
  }

  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
    
  }

  getOpportunityFields() {
    this.initializeAgGrid('Fields');
    this.OpportunityService.GetOpportunityFields(this.currentUser.id).then(result => {
      if (this.FieldChoiceConfigDiv == true) {
        this.DuplicateOpportunityFieldsList = this.OpportunityFieldsList = result.filter(x => x.DataType == 'Pick List');
      } else {
        this.DuplicateOpportunityFieldsList = this.OpportunityFieldsList = result;
      }
      

    });
  }

  initializeAgGrid(Type) {
    this.gridOptionsView = <GridOptions>{
      enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    if (Type == 'Fields') {
      this.createColumnDefs("");
    } 

  }
  createColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        suppressCellSelection:false,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        rowDrag: true
      },
      { field: 'FieldName', headerName: 'Field Name', hide: false },
      { field: 'DisplayAs', headerName: 'Display As', hide: false },
      { field: 'RequiredField', headerName: 'Required Field', hide: false },
      { field: 'DataType', headerName: 'Data Type', hide: false },
      { field: 'Editable', headerName: 'Editable', hide: false },
      { field: 'SortOrder', headerName: 'Sort Order', hide: true },
      { field: 'UserId', headerName: 'User', hide: true },
      {
        field: 'action', headerName: 'Action', hide: true, width: 100, pinned: 'right', cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.data.isdeleted == 0 && params.data.TypeOfField == 'Custom') {
            eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
          }
          else if (params.data.isdeleted == 1 && params.data.TypeOfField == 'Custom') {
            eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size:14px"></i>';
          }

          eDiv.addEventListener('click', function () {
            params.data.isdeleted = 1;
            //selfObj.SaveProjField(params.data);

          });
          return eDiv;
        }
      }
    ];
  }

  onSelectionChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {

      if (rowEvent.api.getSelectedNodes()[0].data) {
        this.OpportunityFields = rowEvent.api.getSelectedNodes()[0].data;
        this.ShowOppFieldForm = true;
      }
    }
  }

  SaveOppField() {
    if (this.OpportunityFields.OppFieldId == 0) {
      this.OpportunityFields.ColumnName = this.OpportunityFields.ColumnName;
    }
    this.OpportunityService.SaveOpportunityFields(this.OpportunityFields).then(result => {
      this.getOpportunityFields();
      this.CloseaccessDiv();
    });
  }

  getPickListData(fieldId,fieldName) {
    this.OpportunityService.GetFieldItems(fieldId, fieldName, this.currentUser.id).then((accounttype) => {
      this.FiledDataList = accounttype;
    });
  }

  onSelectionChangedNew(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {

      if (rowEvent.api.getSelectedNodes()[0].data) {
        
        this.SelectedOppField = rowEvent.api.getSelectedNodes()[0].data;
        if (this.SelectedOppField.DataType == 'Pick List') {
          this.ShowFieldItemTable = true;
          this.getPickListData(this.SelectedOppField.OppFieldId, this.SelectedOppField.FieldName);
        } else {
          this.ShowFieldItemTable = true;
        }
       
      }
    }
  }

  AddNewFieldData(id, val) {
    if (val == 'Add') {
      var toDoEmpty = ({ id: 0, opportunityitem: '', Category: '', SortOrder: 0, isdeleted: 0, OppFieldID: 0 })
        this.FiledDataList.push(toDoEmpty);
        $(setTimeout(function () {
          $('#PickListSortOrder' + id).attr('contenteditable', "true")
          $('#PickListName' + id).attr('contenteditable', "true")
          $('#PickListRow' + id).attr('style', "background-color:#b7e4ff");
        }, 200));
      }
  }

  PickListRowClick(row) {
    $(setTimeout(function () {
      $('#PickListSortOrder' + row.id).attr('contenteditable', "true")
      $('#PickListName' + row.id).attr('contenteditable', "true")
      $('#PickListRow' + row.id).attr('style', "background-color:#b7e4ff");
    }, 200));
  }
  keyupPickList(fieldDataid) {
    if ((this.PickListIds.filter(f => f.Index == fieldDataid)).length == 0) {
      this.PickListIds.push({ Index: fieldDataid });
    }
  }
  DeletePickList(fieldData) {
    this.DeletePickListObj = fieldData;
    this.DeletePickListObj.isdeleted = 1;
    this.SaveData(this.DeletePickListObj);
  }

  SavePickListData() {
    if (this.PickListIds.length > 0) {
      var PickListArray = new OpportunityItems();
      
        this.PickListIds.forEach(id => {

          PickListArray.id = parseInt($('#PickListID~' + id.Index).html());
          PickListArray.SortOrder = parseInt($('#PickListSortOrder' + id.Index).html());
          PickListArray.opportunityitem = $('#PickListName' + id.Index).html();
          PickListArray.isdeleted = 0;
          PickListArray.OppFieldID = this.SelectedOppField.OppFieldId;
          PickListArray.Category = this.SelectedOppField.FieldName;
        });

      this.SaveData(PickListArray);

    }
  }
 
  SaveData(PickListArray) {
    this.OpportunityService.SaveOppPickList(PickListArray).then(result => {
      this.toastr.success("Saved Successfully.", "Pick List", { timeOut: 600 });
      this.getPickListData(this.SelectedOppField.OppFieldId, this.SelectedOppField.FieldName);
        this.PickListIds = [];
    });
  }

  PickListSortByType(value) {
    this.DuplicateFiledDataList = [];
    let selfObj = this;
    $(setTimeout(function () {
      if (value == 'GsortOrder') {
        selfObj.DuplicateFiledDataList = selfObj.FiledDataList.sort((a, b) => a.SortOrder - b.SortOrder);
      } else if (value == 'GOrderA-Z') {
        var data = selfObj.FiledDataList;
        data = data.sort((a, b) => a.opportunityitem.localeCompare(b.opportunityitem));
        selfObj.DuplicateFiledDataList = data;
      } else if (value == 'SSOrder') {
        selfObj.DuplicateFiledDataList = selfObj.FiledDataList.sort((a, b) => a.SortOrder - b.SortOrder);
      } else if (value == 'A-Z') {
        selfObj.DuplicateFiledDataList = selfObj.FiledDataList.sort((a, b) => a.opportunityitem.toLowerCase() > b.opportunityitem.toLowerCase() ? 1 : -1);

      }
    }, 100));

  }

  onAgGridReady(event) {
    this.gridOptionsView.api = event.api;
  }

  onRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("OpportunityFields");
  }
  onRowDragEndFieldChoice(event: RowDragEndEvent) {
    this.saverowconfiguration("OpportunityFieldChoice");
  }
 
  saverowconfiguration(type) {
    var sortoorder = [];
    if (type == "OpportunityFields") {
      this.gridOptionsView.api.forEachNode((node) => {
        sortoorder.push(node.data.OppFieldId);
      });
    } else if (type == "OpportunityFieldChoice") {
      this.gridOptionsView.api.forEachNode((node) => {
        sortoorder.push(node.data.OppFieldId);
      });
    }
    else {
      this.gridConfigFeildsView.api.forEachNode((node) => {
        sortoorder.push(node.data.id);
      });
    }
    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i == a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentUser.id;
    this.leadsService.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });
    });

  }

  CategoryOrder() {
    let TaskIdArr = [];
    $.each($("#" + this.SelectedOppField.ColumnName + " tr td:nth-child(2)"), function (k, v) {
      var TaskIds = $.trim($(this).attr('id'));
      TaskIdArr.push(TaskIds.split('~')[1]);

    });
    this.userService.SaveConfigFieldDataSortOrder(TaskIdArr.join(','), this.SelectedOppField.FieldName, this.SelectedOppField.OppFieldId, this.currentUser.id).then(result => {
      this.toastr.success("Questions Re-Ordered Successfully.", " Questions", { timeOut: 600 });
    });
  }
}

