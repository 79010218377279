import { Component, OnInit, ViewContainerRef, ElementRef,  Input, Output, EventEmitter, ViewChild, NgZone } from '@angular/core';
import { Contacts } from '../../models/contacts';
import { Customers } from '../../models/customers';
import { Notes } from '../../models/notes';
import { HttpClient } from '@angular/common/http';
import { AccountsService } from './../../services/accounts.service';
import { CustomerService } from './../../services/customer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Dropdowntype } from '../../models/dropdowntype';
import { TitleCasePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup,  FormBuilder} from '@angular/forms';
import { Location } from '@angular/common';
import { Tickets } from '../../models/tickets';
import { Emails } from '../../models/emails';
import { Document } from '../../models/document';
import { Project } from '../../models/project';
import { Accounts } from '../../models/accounts';
import { HeroService } from './../../services/hero.service';
import { Accountlocations } from '../../models/data-model';
import { LeadsService } from './../../services/leads.service';
import { TaskHistory } from '../../models/task-history';
import { GridOptions } from "ag-grid/main";
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';

declare var $: any;
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})

export class AddressComponent implements OnInit {
  currentuser: any;
  notedescription: string;
  userprevilages: any;
  accid: number;
  isOpenGoogleMap: boolean = false;
  customercontacttypelist: Dropdowntype[];
  Years: any[];
  MedSchoolYears: any[];
  ResidencyYears: any[];
  FellowShipYears: any[];
  addressform: FormGroup;
  account: Accounts = new Accounts();
  contacts: Contacts = new Contacts();
  customer: Customers = new Customers();
  accountlocations: Accountlocations = new Accountlocations();
  accountslist: Array<Accounts> = [];
  contactslist: Array<Contacts> = [];
  noteslist: Array<Notes> = [];
  public accountid: number = 0;
  public practiceid: number = 0;
  @Input()
  contactid: number;
  @Input()
  type: string;
  @Input()
  locationType: string;
  customertypeList: Dropdowntype[] = [];
  OwnerList: Dropdowntype[] = [];
  accounttypeList: Dropdowntype[] = [];
  ownershiplist: Dropdowntype[] = [];
  private providercontacts: Array<Contacts> = [];
  itemResource: any;
  ContactStaffitemResource: any;
  items = [];
  itemCount = 0;
  staffitems = [];
  staffitemCount = 0;
  locationId = 0;
  locId = 0;
  params: any;
  ContactStaffparams: any;
  showDialog = false;
  locationCount: number = 2;
  MonthList: Dropdowntype[] = [];
  DayList: Dropdowntype[] = [];
  public providerId: number = 0;
  activitieslist: Array<TaskHistory> = [];
  documentlist: Array<Document> = [];
  ticketslist: Array<Tickets> = [];
  emailslist: Array<Emails> = [];
  projectlist: Array<Project> = [];
  FeeAmountdiv: boolean = false;
  hidDiv: boolean = true;
  notesshowDialog = false;
  allnotes: Notes[];
  public staticsticsId: number = 1;
  public stateInfo = [];
  private data: Array<Notes> = [];
  public rows: Array<Notes> = [];
  public columns: Array<any> = [
    { title: 'Date', name: 'created' },
    { title: 'Type', name: 'type' },
    { title: 'Note Description', name: 'notedescription' }
  ];
  Ids = [];
  public page: number = 1;
  public itemsPerPage: number = 10;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;
  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered']
  };
  stateTextBox: boolean = true;
  stateDropDown: boolean = false;
  displayZipCode: boolean = true;
  displayCountryName: boolean = false;
  seletedcountry: any;
  countryId: number = 1;
  public elementRef;
  textareaLength: number = 15;
  addresses: Accountlocations[];
  public gridOptions: GridOptions;
  public columnDefs: any[];
  heroForm: FormGroup;
  nameChangeLog: string[] = [];
  allContactsproviders: Contacts[];
  allContactstaffs: Contacts[];
  public context;
  public frameworkComponents; public columnDefsnotes: any[];
  public gridOptionsnotes: GridOptions;
  today = Date.now();
  public AccountorCustomertype;
  @Input()
  address1;
  @Output()
  sendMsgEvent = new EventEmitter<Accounts>();
  titleCasePipe: TitleCasePipe = new TitleCasePipe();
  public searchControl: FormControl;
  public lattitude: number;
  public longitude: number;

  SelectedCountryId: number;
  public zoom: number;
  @ViewChild("search")
  public searchElementRef: ElementRef;
  public i = 1;
  latitude: number;
  address: string;
  private geoCoder;
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  @Input()
  Index: number;
  ShowImageDialog: boolean = false;
  ImageData: string = "";
  constructor(private ngZone: NgZone, private fb: FormBuilder, private http: HttpClient, private heroService: HeroService, private accountsService: AccountsService, private route: ActivatedRoute,
    private customerService: CustomerService, private toastr: ToastrService, private viewContainerRef: ViewContainerRef, private location: Location, private router: Router, myElement: ElementRef,
    private leadservice: LeadsService, private opprtunityservice: OpprtunitiesService, private mapsAPILoader: MapsAPILoader,
  ) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.elementRef = myElement;

  }
 
  ngOnInit() {

    this.searchControl = new FormControl();

    this.mapsAPILoader.load().then(() => {

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]

      });
      autocomplete.addListener("place_changed", () => {

        this.ngZone.run(() => {
          this.setCurrentLocation();
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
       
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.lattitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          var id = $('#addressID' + this.Index).val();
          var Name = $('#addressName' + this.Index).val();
          var type = $('#addressType' + this.Index).val();
          this.accountlocations = new Accountlocations();
          this.accountlocations.lattitude = place.geometry.location.lat().toString();
          this.accountlocations.longtitude = place.geometry.location.lng().toString();
          this.accountlocations.id = id;
          this.accountlocations.addressName = Name;
          this.accountlocations.addressType = type;
          var address_data = {
            'name': place.name,
            'formatted_address': place.formatted_address,
          };
          this.invokeEvent(place);
          var html = $.parseHTML(place.adr_address);
          for (var i = 0; i < html.length; i++) {
            if (html[i].nodeName == 'SPAN') {
              if (html[i].className == "street-address") {
                var add1 = html[i].innerHTML;
                this.accountlocations.address1 = add1;
              }
              else if (html[i].className == "extended-address") {
                var add2 = html[i].innerHTML;
                this.accountlocations.address2 = add2;
              }
              else if (html[i].className == "locality") {
                var city = html[i].innerHTML;
                if (this.SelectedCountryId == 2) {
                  this.accountlocations.state = city;
                  this.accountlocations.ProvidenceIsland = (city == "Nassau") ? "Nassau" : this.accountlocations.ProvidenceIsland

                } else {
                  this.accountlocations.city = city;
                  this.accountlocations.ProvidenceIsland = (city == "Nassau") ? "Nassau" : this.accountlocations.ProvidenceIsland

                }
              }
              else if (html[i].className == "region") {
                var state = html[i].innerHTML;
                this.accountlocations.state = state;
              }
              else if (html[i].className == "postal-code") {
                var zip = html[i].innerHTML;
                this.accountlocations.zip = zip;
              }
              else if (html[i].className == "country-name") {
                this.accountlocations.country = html[i].innerHTML;
              }
            }
          }

          this.loadaddress(this.accountlocations, this.Index);
          this.zoom = 12;
        });
      });
    });
  }


  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }


  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  sendBackAddress(location: Accountlocations) {
    this.account.isaddressadded = this.searchControl.valid;
    this.account.locationType = this.locationType;
    this.account.country = location.country;    
    this.sendMsgEvent.emit(this.account);
  }

  addressChange() {
    this.account.locationType = this.locationType;
    //  this.sendMsgEvent.emit(this.account);
  }
  invokeEvent(place: Object) {
    //this.setAddress.emit(place);
  }

  loadaddress(account: Accountlocations, i: number) {  
    if (account.country == "882" || account.country == "The Bahamas") {
      this.customerService.GetPickListData("Account (Primary) Country", 12, this.currentuser.id).then((accounttype) => {
        var county = accounttype.filter(x => x.itemName == "Bahamas")[0].id;
        $('#country').val(county);
        account.city = (account.city) == "Nassau" ? "" : account.city;
        $('#addressID' + i).val(account.id);
        $('#address1' + i).val(account.address1);
        $('#address2' + i).val(account.address2);
        $('#city' + i).val(account.city);
        $('#state' + i).val(account.state);
        $('#zip' + i).val(account.zip);

        $('#lattitude' + i).val(account.lattitude);
        $('#longtitude' + i).val(account.longtitude);
        $('#ProvidenceIsland' + i).val(account.ProvidenceIsland);
        $('#addressName' + i).val(account.addressName);
        $('#addressType' + i).val(account.addressType);
        $('#fax' + i).val(account.fax);
        $('#phone' + i).val(account.phone);
        $('#UploadImage' + i).val(account.LocationPicture);

        if (account.LocationPicture != null && account.LocationPicture != "") {
          $('#UploadImage' + i + "Icon").attr('style', 'float:right;height:26px;margin-top:4%;display:block');
        }

        if (i < 2) {
          this.accountlocations.address1 = account.address1;
          this.accountlocations.address2 = account.address2;
          this.accountlocations.city = account.city == "Nassau" ? "" : account.city;
          this.accountlocations.state = account.state;
          this.accountlocations.zip = account.zip;
          this.accountlocations.country = $('#country').val();
          this.accountlocations.lattitude = account.lattitude;
          this.accountlocations.longtitude = account.longtitude;
          this.accountlocations.fax = account.fax;
          this.accountlocations.phone = account.phone;
          this.accountlocations.LocationPicture = account.LocationPicture;
          this.accountlocations.ProvidenceIsland = account.city == "Nassau" ? "Nassau" : account.ProvidenceIsland;
          // this.getaddressFormValidation();
          if (account.LocationPicture != null && account.LocationPicture != "") {
            $('#UploadImage' + i + "Icon").attr('style', 'float:right;height:26px;margin-top:4%;display:block');
          }
          this.getaddressFormValidation(this.accountlocations);

        }
      });

    } else {
      this.customerService.GetPickListData("Account (Primary) Country", 12, this.currentuser.id).then((accounttype) => {
    
        if (account.country != null) {
          var county = accounttype.filter(x => x.itemName == "USA")[0].id;
          $('#country').val(county);
        } else {
          $('#country').val()
        }
        account.city = (account.city) == "Nassau" ? "" : account.city;
        $('#addressID' + i).val(account.id);
        $('#address1' + i).val(account.address1);
        $('#address2' + i).val(account.address2);
        $('#city' + i).val(account.city);
        $('#state' + i).val(account.state);
        $('#zip' + i).val(account.zip);

        $('#lattitude' + i).val(account.lattitude);
        $('#longtitude' + i).val(account.longtitude);
        $('#ProvidenceIsland' + i).val(account.ProvidenceIsland);
        $('#addressName' + i).val(account.addressName);
        $('#addressType' + i).val(account.addressType);
        $('#fax' + i).val(account.fax);
        $('#phone' + i).val(account.phone);
        $('#UploadImage' + i).val(account.LocationPicture);

        if (account.LocationPicture != null && account.LocationPicture != "") {
          $('#UploadImage' + i + "Icon").attr('style', 'float:right;height:26px;margin-top:4%;display:block');
        }

        if (i < 2) {
          this.accountlocations.address1 = account.address1;
          this.accountlocations.address2 = account.address2;
          this.accountlocations.city = account.city == "Nassau" ? "" : account.city;
          this.accountlocations.state = account.state;
          this.accountlocations.zip = account.zip;
          this.accountlocations.country = $('#country').val();
          this.accountlocations.lattitude = account.lattitude;
          this.accountlocations.longtitude = account.longtitude;
          this.accountlocations.fax = account.fax;
          this.accountlocations.phone = account.phone;
          this.accountlocations.LocationPicture = account.LocationPicture;
          this.accountlocations.ProvidenceIsland = account.city == "Nassau" ? "Nassau" : account.ProvidenceIsland;
          if (account.LocationPicture != null && account.LocationPicture != "") {
            $('#UploadImage' + i + "Icon").attr('style', 'float:right;height:26px;margin-top:4%;display:block');
          }
          this.getaddressFormValidation(this.accountlocations);

        }

      });

    }

  }
  loadMultipleLocations(locations: Accountlocations[]) {
    locations.forEach(function (value) {
      this.account = new Accounts();
      this.account.address1 = value.address1;
      this.account.address2 = value.address2;
    });

  }
  getaddressFormValidation(location: Accountlocations) {
    this.sendBackAddress(location);
  }

  clear(i) {

    $('#addressName' + i).val("");
    $('#address1' + i).val("");
    $('#address' + i).val("");
    $('#city' + i).val("");

    $('#state' + i).val("");
    $('#zip' + i).val("");
    $('#ProvidenceIsland' + i).val("");
    $('#phone' + i).val("");
    $('#fax' + i).val("");
    this.accountlocations.address1 = "";
    this.accountlocations.address2 = "";
    this.accountlocations.city = "";
    this.accountlocations.state = "";
    this.accountlocations.zip = "";
    this.accountlocations.country = "";
    this.accountlocations.ProvidenceIsland = "";  
    this.sendMsgEvent.emit(this.account);

  }

  Map() { this.isOpenGoogleMap = true; }

  accountNumber() {
    if ($('accnumber').val() == '') {
      if ($('#customertypeid').val() == 0) {
        this.account.accnumber = '0';
      } else {
        this.account.accnumber = null;
      }
    }
  }
  public transform(input: string): string {

    if (!input) {
      return '';
    }
    else {
      return input[0].toUpperCase() + input.substr(1);
    }
  }

  BanAddressChange(i) {

    this.accountlocations.address1 = $('#address1' + i).val();
    this.accountlocations.address2 = $('#address2' + i).val();
    this.accountlocations.city = $('#city' + i).val();
    this.accountlocations.state = $('#state' + i).val();
    this.accountlocations.zip = $('#zip' + i).val();
    this.accountlocations.country = $('#country' + i).val();
    this.accountlocations.lattitude = $('#lattitude' + i).val();
    this.accountlocations.longtitude = $('#longtitude' + i).val();
    this.accountlocations.ProvidenceIsland = $('#ProvidenceIsland' + i).val();


  }

  ShowImage(Index) {
    this.ImageData = $('#UploadImage' + Index).val();
    this.ShowImageDialog = true;
  }
  addFiles(event, index) {

    let files = event.srcElement.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      this.addChargeNew(file, index);

      const reader = new FileReader();
      reader.readAsDataURL(file);

    }
  }

  addChargeNew(file, index) {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.accountlocations.LocationPicture = reader.result.toString();
      $('#UploadImage' + index).val(selfObj.accountlocations.LocationPicture)
    }, 500));
  }
}
