<div class="col-xs-12" style="padding: 0; height: 100%;">
    <div class="col-xs-12" style="padding:0;height:100%;" *ngIf="ShowTaskGroupPage">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Task Group</span>
      </h4>

      <div class="col-xs-7" style="padding:0;height:93%">
        <div class="col-xs-12" style="padding:0;height:7%">
          <div class="col-xs-8" style="padding-left:0">
            <img src="../../../assets/img/Create New Document Name.png" (click)="ShowTaskGroupFieldform = true" style="height:30px" />
          </div>

          <div class="col-xs-4" style="padding:0">
            <select class="form-control">
              <option value="1"> Display All Task Groups </option>
          
            </select>


          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:93%">        

          <ag-grid-angular style="height:100%" class="ag-theme-balham"
                           [gridOptions]="gridOptions "
                           [columnDefs]="TaskGroupcolumnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="taskgrouplist"
                           [rowDragManaged]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           [context]="context"
                           (rowDragEnd)="onRowDragEndGroups($event)"
                           (dragStopped)="dragStopped($event)"
                           (selectionChanged)="onTGSelectionChanged($event)"
                           (gridReady)="onAgGridReady($event,'TaskGroups')">
          </ag-grid-angular>
        </div>
      </div>

      <div class="col-xs-5" style="padding:0 0 0 1%;height:93%">
        <div class="col-xs-12" style="padding:0;height:7%">


        </div>
        <div class="col-xs-12" style="padding:0;height:93%" *ngIf="ShowTaskGroupFieldform ">
          <form #TaskGroupFieldform="ngForm" style="height:100%">

            <div class="col-xs-12" style="padding:0;height:90%">


              <div class="col-xs-12" style="padding:0;height:9%">
                <div class="col-xs-4" style="padding:0;height:100%"><label>Task Group Name</label></div>
                <div class="col-xs-8" style="height:100%">
                  <input type="text" class="form-control" name="TaskGroupName" [(ngModel)]="this.taskGroupObj.TaskGroupName" required />
                </div>
              </div>

              <div class="col-xs-12" style="padding:0;height:9%">
                <div class="col-xs-4" style="padding:0;height:100%"><label>Sort Order</label></div>
                <div class="col-xs-8">
                  <input type="text" class="form-control" [(ngModel)]="this.taskGroupObj.Sortorder" name="SortOrder" required />
                </div>
              </div>
            </div>
            <div class="col-xs-12" style="height:10%;float:right;padding-right:15px">
              <div class="col-xs-6" style="padding: 0;">
                <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="canceltaskList()">Cancel</button>
              </div>
              <div class="col-xs-6" style="padding-right: 0;">
                <button type="submit" class="btn saveButton" style="width:100%;" [disabled]="(!TaskGroupFieldform.form.valid)" (click)="SaveGroup()">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xs-12" style="padding:0;height:100%;" *ngIf="ShowTaskGroupItemsPage">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Task Group Items</span>
      </h4>
      <div class="col-xs-7" style="padding:0;height:93%">
        <div class="col-xs-12" style="padding:0;height:7%">
          <div class="col-xs-2" style="padding-left:0">
            <img src="../../../assets/img/Create New Document Name.png" (click)="ShowTaskTypeFieldform = true" style="height:30px" />
          </div>
          <div class="col-xs-5" style="padding-left:0">
           <select class="form-control" (change)="DisplayByTaskGroups($event)" [(ngModel)]="selectedGroup">
              <option value="null"> Display All Task Groups </option>
              <option value="{{item.id}}" *ngFor="let item of taskgrouplist">Display {{item.TaskGroupName}} </option>
            </select>


          </div>
          <div class="col-xs-5" style="padding:0px">
           <select class="form-control" (change)="DisplayByCategory($event)" [(ngModel)]="SelectedCategory">
              <option value="null"> Display All Categories </option>
              <option value="To Do">Display To Do</option>
              <option value="Phone">Display Phone Call</option>
              <option value="Meeting">Display Meeting</option>
              <option value="Blocked Times">Display Blocked Times</option>
            </select>


          </div>
        </div>
        <div class="col-xs-12" style="padding:0;height:93%">
          <ag-grid-angular style="height:100%" class="ag-theme-balham"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="TaskListItems"
                           [rowDragManaged]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           [context]="context"
                           [frameworkComponents]="frameworkComponents"
                           (rowDragEnd)="onRowDragEnd($event)"
                           (selectionChanged)="onSelectionChanged($event)"
                           (gridReady)="onAgGridReady($event,'TaskGroupItems')">
          </ag-grid-angular>
        </div>
      </div>

      <div class="col-xs-5" style="padding:0 0 0 1%;height:93%">
        <div class="col-xs-12" style="padding:0;height:7%">
          <!--<div class="col-xs-1" style="height:100%;padding:0.5%;width:4%;float:left">
            <span> <img src="../../../assets/img/Black x.png" style="height:22px" (click)="closePage('TaskGroupsItems')" /></span>
          </div>-->
        </div>
        <div class="col-xs-12" style="padding:0;height:93%" *ngIf="ShowTaskTypeFieldform">
          <form #TaskTypeFieldform="ngForm" style="height:100%">

            <div class="col-xs-12" style="padding:0;height:90%">
              <div class="col-xs-12" style="padding:0;height:9%">
                <div class="col-xs-4" style="padding:0;height:100%"><label>Task Group</label></div>
                <div class="col-xs-8">
                  <select class="form-control" [(ngModel)]="this.taskTypeObj.taskgroupid" name="taskgroupid" required>
                    <option>Select Task Group</option>
                    <option *ngFor="let taskgroup of  taskgrouplist" value={{taskgroup.id}}>
                      {{taskgroup.TaskGroupName}}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-xs-12" style="padding:0;height:9%">
                <div class="col-xs-4" style="padding:0;height:100%"><label>Task Category</label></div>
                <div class="col-xs-8">
                  <select class="form-control" [(ngModel)]="taskTypeObj.category" name="{{taskTypeObj.category}}" required>
                    <option value="null" selected>Select Task</option>
                    <option value="To Do">To Do</option>
                    <option value="Phone">Phone Call</option>
                    <option value="Meeting">Meeting</option>
                    <option value="Blocked Times">Blocked Times</option>

                  </select>
                </div>
              </div>

              <div class="col-xs-12" style="padding:0;height:25%">
                <div class="col-xs-4" style="padding:0;height:100%"><label>Description</label></div>
                <div class="col-xs-8" style="height:100%">
                  <textarea style="width:100%;height:95%" class="form-control" [(ngModel)]="this.taskTypeObj.tasktype" name="tasktype" required></textarea>
                </div>
              </div>

              <div class="col-xs-12" style="padding:0;height:9%">
                <div class="col-xs-4" style="padding:0;height:100%"><label>Sort Order</label></div>
                <div class="col-xs-8">
                  <input type="text" class="form-control" [(ngModel)]="this.taskTypeObj.SortOrder" name="SortOrder" required />
                </div>
              </div>

              <div class="col-xs-12" style="padding:0;height:9%">
                <div class="col-xs-4" style="padding:0;height:100%"><label>Time Required (Minutes)</label></div>
                <div class="col-xs-8">

                  <select class="form-control" [(ngModel)]="this.taskTypeObj.TimeRequired" name="category" required>
                    <option value="null">Select Task</option>
                    <option value="{{item.value}}" *ngFor="let item of TimeSlots">{{item.itemName}}</option>

                  </select>
                </div>
              </div>
            </div>
            <div class="col-xs-12" style="height:10%;float:right;padding-right:15px">
              <div class="col-xs-6" style="padding: 0;">
                <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="canceltaskList()">Cancel</button>
              </div>
              <div class="col-xs-6" style="padding-right: 0;">
                <button type="submit" class="btn saveButton" style="width:100%;" [disabled]="(!TaskTypeFieldform.form.valid)" (click)="SaveTaskType()">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

</div>


<app-dialog [(visible)]="DeleteMessagePopup" *ngIf="DeleteMessagePopup" id="DeleteMessagePopup" [closable]="false">
  <app-delete-popup [title]="'Task Group Item'" [description]="(taskTypeObj.isdeleted == 1)?'Activate Task Group Item' : 'Delete Task Group Item'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteeventForMessage($event)">
  </app-delete-popup>
</app-dialog>


<app-dialog [(visible)]="DeleteTaskGroup" *ngIf="DeleteTaskGroup" id="DeleteTaskGroup" [closable]="false">
  <app-delete-popup [title]="'Task Group'" [description]="(taskTypeObj.isdeleted==1)?'Activate Task Item' : 'Delete Task  Item'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteTaskGroup($event)">
  </app-delete-popup>
</app-dialog>
