export class Dashboardconfiguration {
  public id: number;
  public resourceid: number;
  public columnsselections: string;
  public maxlimit: number;
  public userid: string;
  public configureduserid: number;
  public datecreated: string;
  public startdate: string;
  public enddate: string;
}
