export class AdvancedSearch {
  public Id: number;
  public QryName: string;
  public qryname: string;
  public SearchQry: string;
  public editdate: string;
  public createdate: string;
  public status: number;
  public searchtype: string;
  public filtercolumnsdata: string;
  public createdby: number;
  public defaultcolumns: string;
  public searchData: string;
  public RunStatus: string;
  public SearchOperator: string;
}
