import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Optional, ElementRef } from '@angular/core';
import { Accounts } from 'src/app/models/accounts';
import { Dropdowntype } from 'src/app/models/dropdowntype';
import { Statistics } from 'src/app/models/statistics';
import { CustomerService } from 'src/app/services/customer.service';
import { AccountDocuments } from 'src/app/models/account-documents';
import { Accountlocations } from 'src/app/models/data-model';
import { FormArray, FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { forbiddenNameValidator } from '../shared/forbidden-validator-directive.directive';
import { Contacts } from 'src/app/models/contacts';
import { AccountsService } from 'src/app/services/accounts.service';
import { AddressUpdate } from 'src/app/models/AddressUpdate';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AddressComponent } from '../address/address.component';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { OpprtunitiesService } from 'src/app/services/opprtunities.service';
import { AccountsHistory } from '../../models/AccountHistory';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Users } from '../../models/users';
import { Notes } from '../../models/notes';
declare var $: any;
@Component({
  selector: 'app-add-edit-account',
  templateUrl: './add-edit-account.component.html',
  styleUrls: ['./add-edit-account.component.css']
})
export class AddEditAccountComponent implements OnInit {
  showBtn: boolean = false;
  Show: boolean = true;
  EditAddressDiv: boolean = false;
  account: Accounts = new Accounts();
  Fields: any;
  CustomerDetailFields: any;
  accountHistory: AccountsHistory = new AccountsHistory();
  contacts: Contacts = new Contacts();
  @Input()
  accountID = 0;
  @Input()
  type: string;
  customertypeList: Dropdowntype[] = [];
  contactList: Dropdowntype[] = [];
  OwnerList: Dropdowntype[] = [];
  accounttypeList: Dropdowntype[] = [];
  ownershiplist: Dropdowntype[] = [];
  Statuslist: Dropdowntype[] = [];
  Accountstatisticlist: Array<Statistics> = [];
  SuffixList: Dropdowntype[] = [];
  SexList: Dropdowntype[] = [];
  leadfromlist: Dropdowntype[] = [];
  TitleList: Dropdowntype[] = [];
  contacttypelist: Dropdowntype[] = [];
  Specialitytypelist: Dropdowntype[] = [];
  Collegelist: Dropdowntype[] = [];
  MedicalSchoolList: Dropdowntype[] = [];
  StatesList: Dropdowntype[] = [];
  CitiesList: Dropdowntype[] = [];
  PractiseList: Dropdowntype[] = [];
  ehrpurchasedList: Dropdowntype[] = [];
  pmpurchasedList: Dropdowntype[] = [];
  rcmlist: Dropdowntype[] = [];
  salereplist: Users[] = [];
  currentuser: any;
  customercontacttypelist: Dropdowntype[];
  documentlist: Array<AccountDocuments> = [];
  AccountIdArr: Dropdowntype[] = [];
  contactIdArr: Dropdowntype[] = [];
  public stateInfo = [];
  MonthList: Dropdowntype[] = [];
  DayList: Dropdowntype[] = [];
  Years: any[];
  public gridOptionsnotes: GridOptions;
  accountlocations: Accountlocations = new Accountlocations();
  heroForm: FormGroup;
  accountform: FormGroup;
  showContatsGrid: boolean = false;
  showContinueButton: boolean = false;
  ShowContactstoSelect: boolean = false;
  selectedAddressOption: string = '';
  selectedcontact: string[] = [];
  enableUpdateAll: boolean = false;
  enableSelectContacts: boolean = false;
  enableDoNotUpdate: boolean = false;
  ismatchduplicate: string;
  SaveAccDialog: boolean = false;
  PhoneDuplicate = false;
  websiteduplicate = false;
  DuplicateAccountDialog: boolean = false;
  deleteduserName: string = "";
  showmapWarning: boolean = false;
  addressupdate: AddressUpdate = new AddressUpdate();
  addresses: Accountlocations[] = [];
  textareaLength: number = 15;
  displayCountryName: boolean = false;
  locstateDropDown;
  stateTextBox: boolean = true;
  displayZipCode: boolean = true;
  customeracces: number = 0;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  AddAccselectedMenuRadio: string = "AddAcctab4";
  multiplelocaddresses: any;
  AddressList = [];
  @ViewChild(AddressComponent) adddata;
  public gridOptions: GridOptions;
  public sideBar;
  public context;
  public frameworkComponents;
  public columnDefs: any[];
  allContactsproviders: Contacts[];
  allContacts: Contacts[];
  SortedContactType;
  SortedStatus;
  SelectedStatus;
  SelectedContactType;
  itemCount = 0;
  Contacttotal: Contacts[];
  LastTicketDate;
  LastContactDate;
  datePipe: DatePipe = new DatePipe("en-US");
  public zipmask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public dobmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public elementRef;
  collegeyearDrop: boolean = true;
  MedSchoolyearDrop: boolean = true;
  ResidencyyearDrop: boolean = true;
  FellowshipyearDrop: boolean = true;
  Mymodeldata: any;
  NewForm = new FormGroup({});
  LocationMappedContactsDiv: boolean = false;
  MappedContacts: Contacts[];
  deletedSecretLairsIndex = 0;
  ExpandMode: boolean = false;
  FormDataModified: boolean = false;
  DiscardFormDataConfirmation: boolean = false;
  NoAccess: boolean = false;
  salesrepName: string;
  AddNewLocation: boolean= false;
  ReplaceAddressSelected: boolean= false;
  public columnDefsnotes: any[];
  allnotes: Notes[];
  AllnotesOriginal: Notes[];
  timezonevalue: string;
  constructor(private customerService: CustomerService, private fb: FormBuilder, private accountsService: AccountsService,
    private toastr: ToastrService, private router: Router, private opprtunityservice: OpprtunitiesService, myElement: ElementRef) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser")); 
    this.elementRef = myElement;
    this.customeracces = this.currentuser.customertypeaccess;   
    this.createForm();
    this.account.accountStatus = 14;
  }
  
  ngOnInit(): void {
    this.account.id = this.accountID;
    this.AddAcctabChanged('AddAcctab4');
    if (this.accountID == 0) {
      this.AddAccselectedMenuRadio = 'AddAcctab4';    
    }
    this.primaryAdressdropdownvalues();
   
    this.customerService.GetFormConfiguration(this.currentuser.id, 'Mailing Address').then(res => {         
      if (res != null && res.length > 0) {
        let obj: string[] = [res[0].OrderFields, 'Mailing Address'];
        this.customerService.getSaveFormFields(obj).then(result => {
          if (this.customeracces == 0) {
            result = result.filter(x => x.ColumnName != 'customertypeid');
          }
          result.forEach(ele => {
            if (ele['ColumnName'] == 'ownerid') {
              ele['Data'] = this.OwnerList;
            } else {
              if (ele['DataType'] == 'Pick List'){
                this.customerService.GetPickListData(ele['FieldName'], ele['id'], this.currentuser.id).then((accounttype) => {
                  var data = this.PickListSortByType(ele['FieldDataOrderBy'], accounttype)
                  ele['Data'] = data;
                });
              }
            }
           
          });
          this.Fields = result;
          this.Fields.forEach(x => {            
            this.NewForm.addControl(x.ColumnName, new FormControl(''));
          });
        });
      }
    });
  }
  initializeAgGridNotes(val) {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',
      defaultToolPanel: 'none',
    };
    this.createColumnDefsnotes(val);

  }
  createColumnDefsnotes(val, @Optional() isShowDetails: boolean = false) { 
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false },
      {
        field: 'notedescription', hide: false, tooltipField: 'notedescription', width: 250, cellStyle: { 'text-align': 'left' }, cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.value && params.value.includes("Owner Changed")) {
            eDiv.innerHTML = "<label style='color:red'>" + params.value + "</label>";
          } else {
            eDiv.innerHTML = params.value;
          }

          return eDiv;
        }
      },
    ];

  }

  GetCustomerNotes(currentuserid, contactid, type, accidofallcontacts: string = "0", OppType: string) {

    var moment = require('moment-timezone');
    this.customerService.GetAllNotesListNew(currentuserid, contactid, type, accidofallcontacts, OppType).then((taskhistory) => {
      taskhistory.forEach(items => {
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh:mm a");

      });
      this.initializeAgGridNotes(type);

      this.allnotes = taskhistory;
  
      this.allnotes = this.allnotes.filter(x => x.isnewnote == null); 
      for (let note of this.allnotes) {
        if (note.notedescription) {
          note.notedescription = note.notedescription.replace(/<[^>]*>|&nbsp;/g, '');
        }
        if (note.isdocumentid != null && note.isdocumentid != "") {
          note.docList = note.isdocumentid.split(",");
        }
      }
    });
  }
  getAccountHistory() {
    this.customerService.LastTicketCreatedDateByAccID(this.account.id).then(result1 => {
      if (result1 != null && result1 != '0001-01-01T00:00:00') {
        this.LastTicketDate = this.datePipe.transform(result1, "dd-MM-yyyy")
      }
    });
    this.customerService.LastContactedDateByAccID(this.account.id).then(result2 => {
      if (result2 != null && result2 != '0001-01-01T00:00:00') {
        this.LastContactDate = this.datePipe.transform(result2, "MM/dd/yyyy");
      }
    });
    this.customerService.GetAccountHistoryById(this.account.id, this.currentuser.id).then(result3 => {
      if (result3 != null) {
        this.accountHistory = result3;    
        this.account.salesRep = this.accountHistory.Salesrep;
      }
      this.customerService.GetSalerepName(this.account.id).then(result3 => {
        this.salesrepName = result3;
      });
    });
   
  }
  primaryAdressdropdownvalues() {
    this.customerService.GetAllOwnerList(0).then((Owner) => {
      this.OwnerList = Owner;
    });
  }
  Accountspecificdropdowns() {
    this.getyears()
    {
      var range = [];
      var y = 1949;
      var year = new Date().getFullYear();
      for (var i = 0; y <= year; i++) {
        y = y + 1;
        range.push({
          label: y,
          value: y
        });
      }
      this.Years = range;
    }
  }
  AccountHistoryDropdowns() {
    this.opprtunityservice.GetSpecialityList("LeadFrom", this.currentuser.id).then((pc) => {
      this.leadfromlist = pc;
    });
  }
 
  getyears() {
    var range = [];
    var year = new Date().getFullYear();
    for (var i = 0; i < 7; i++) {
      range.push({
        label: year + i,
        value: parseInt(String(year + i).slice(2, 4))
      });
    }


    this.Years = range;
  }

  multipleLocation(id,type) {
    if (this.AddNewLocation == false) {
      this.AddNewLocation = type;

      this.showBtn = true;

      if (id == 1) {
        this.secretLairs.push(this.fb.group(new Accountlocations()));
        //$('#multipleLocation').val('0');
      } else {

      }
    }
    
  }

  locloadstate(ival, state, stateid, stateDDN, city) {

    $('.selectedState').removeClass('selectedState');
    var countryCode = ($('#country').val());
    if (countryCode == 1 && stateDDN == 'locstateDropDown') {
      for (var i = 0; i < 50; i++) {
        if (this.StatesList[i].itemName.trim().toString() == state) {
          $('#' + ival + stateDDN).val(state)
          $('#' + this.StatesList[i].id).addClass('selectedState');
          this.heroForm.value.secretLairs[ival].state = state;
          this.heroForm.value.secretLairs[ival].city = city;
        }
      }
    } else {
      $('#' + ival + stateid).val(state);
      this.heroForm.value.secretLairs[ival].state = state;
    }
  }
  

  locselectState(ival, id, name) {

    $('.selectedState').removeClass('selectedState');
    $('#' + id).addClass('selectedState');
    $('#' + ival + 'locstateDropDown').val(name);
    this.heroForm.value.secretLairs[ival].state = name;

  }

  removeloction(val) {
    this.deletedSecretLairsIndex = val;
    this.addresses = [];
    var deletedAddress = $('#addressID' + (val - 1)).val();
    if (deletedAddress != null && deletedAddress != undefined && deletedAddress != '') {
      if (deletedAddress == '0') {
        this.addresses.push(this.multiplelocaddresses[0]);

      } else {
        this.multiplelocaddresses.forEach(ele => {
          if (ele.id == Number(deletedAddress)) {
            ele.isdelete = 1;
            this.addresses.push(ele);
          }

        });
       
      }

      
      this.accountsService.getMappedContactsForLocation(this.addresses[0].id, this.addresses[0].accountid).then(result => {
        this.MappedContacts = result;

        if (result.length > 0) {
          this.AddressList = this.AddressList.filter(x => x.id != this.addresses[0].id)
          this.LocationMappedContactsDiv = true;
        } else {
          this.accountsService.addaccountLocations(this.addresses).then(result => {
            this.toastr.success("Location Deleted successfully.", "Location", { timeOut: 600 });
            $("#" + val).remove();
            this.secretLairs.removeAt(this.deletedSecretLairsIndex);
            this.cancelAccount();
          });
        }
      });
    }
    else {
      this.addresses = this.multiplelocaddresses;
      this.secretLairs.removeAt((val - 2));     
    }
    
    if (val == 0) {
      this.account.multiplelocations = 0;
    }
    

  }
  createForm() {
    this.heroForm = this.fb.group({
      name: '',
      secretLairs: this.fb.array([]),
      power: '',
      sidekick: ''
    });
  }


  get secretLairs(): FormArray {
    return this.heroForm.get('secretLairs') as FormArray;
  };

  setAddresses(addresses: Accountlocations[]) {

    const addressFGs = addresses.map(address => this.fb.group(address));
    const addressFormArray = this.fb.array(addressFGs);
    this.heroForm.setControl('secretLairs', addressFormArray);
  }
  prepareSaveHero(): Accountlocations[] {
    
    const formModel = this.heroForm.value;
    const secretLairsDeepCopy: Accountlocations[] = formModel.secretLairs.map(

      (address: Accountlocations) => Object.assign({}, address, address.accountid = this.account.id),
    );
    return secretLairsDeepCopy;
  }

  special_char(event) {

    var k = event.charCode;
    return (!(k === 124));
  }
  accountNumber() {
    if ($('accnumber').val() == '') {
      if ($('#customertypeid').val() == 0) {
        this.account.accnumber = '0';
      } else {
        this.account.accnumber = null;
      }
    }
  }
  titleSelected() {
    if ($('#title').hasClass('not-required')) {
      $('#title').removeClass('not-required')
      $('#title').addClass('required')

    }
  }
  collegeYear1() {
    $('#collegegrandyear').removeAttr('disabled');
  }
  medschoolYear1() {
    $('#medschoolgrandyear').removeAttr('disabled');
  }
  residencyYear1() {
    $('#residencygrandyear').removeAttr('disabled');
  }
  fellowshipYear1() {
    $('#fellowshipgrandyear').removeAttr('disabled');
  }
  handleClick(event) {
    var clickedComponent = event.target;   
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {
      $('#test').val('Account');
    }
  }

 

  ResetAccount() {
    this.account.id = 0;
    this.account.customertypeid = 0;
    this.account.country = null;
    this.account.accounttype = null;
    this.account.accountname = null;
    this.account.ownerid = null;
    this.account.state = null;
    this.account.city = null;
    this.account.practiceid = 0;
    this.account.address1 = null;
    this.account.address2 = null;
    this.account.zip = null;
    this.account.phone = null;
    this.account.fax = null;
    this.account.alterphone1 = null;
    this.account.ownership = null;
    this.account.alterphone2 = null;
    this.account.website = null;
    this.account.ownerid = null;
    this.account.multiplelocations = 0;    
    this.account.rcm = null;
    this.account.ehr = null;
    this.account.pm = null;
    this.account.ownerid = this.currentuser.id;
    this.accountlocations = new Accountlocations();
    this.account.speciality = null;
    this.account.accountStatus = 0;
    $('#test').val('');
  }

  resetAccountNumber() {
    if (this.account.id != 0) {

      if ($('#customertypeid').val() != 0) {
        $("#accnumber").prop("required", true);
        
        if (this.account.accnumber == '' || this.account.accnumber == '0') {
          $('#accnumber').val('');
          this.account.accnumber = null;

        } else {
          this.account.accnumber == '0';
          $('#accnumber').val('0');
        }
      } else {
        $("#accnumber").attr("required", true);
        if (this.account.accnumber == '' || this.account.accnumber == '0' || this.account.accnumber == null) {
          $('#accnumber').val('0');
          this.account.accnumber = '0';
        }
      }
    }
  }

  public onAccStatusClick() {
    if (this.currentuser.customerStatus == 0) {
      alert("You don't have access to Change Account Status. If you want to Access, please change it from User Configuration ")

    }
  }
  Nameval(event) {
    var regex = new RegExp("^[-a-zA-Z0-9!'_.&()-{}\|;:?/\" ]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  loadAdditionAddress() {
    this.adddata.pushAddress();
  }
  AddressReceived(msg: Accounts) { // Reverted Changes
    if (msg.locationType == "loc1") {
      let multipleAdd = new Accountlocations();
      multipleAdd.address1 = msg.address1;
      multipleAdd.address2 = msg.address2;
      multipleAdd.city = msg.city;
      multipleAdd.state = msg.state;
      multipleAdd.zip = msg.zip;
      multipleAdd.country = msg.country;
      multipleAdd.id = msg.id;
      multipleAdd.lattitude = msg.lattitude;
      multipleAdd.longtitude = msg.longtitude.toString();
      multipleAdd.phone = msg.phone;
      multipleAdd.fax = msg.fax;
      multipleAdd.ProvidenceIsland = msg.ProvidenceIsland;
      multipleAdd.accountid = this.account.id;
      multipleAdd.addressName = msg.addressName;
      this.addresses.push(multipleAdd);

    } else {
      if (this.type == "Contact") {
        this.contacts.address1 = msg.address1;
        this.contacts.address2 = msg.address2;
        this.contacts.city = msg.city;
        this.contacts.state = msg.state;
        this.contacts.zip = msg.zip;
        this.contacts.ProvidenceIsland = msg.ProvidenceIsland;
        this.contacts.lattitude = msg.lattitude.toString();
        this.contacts.longtitude = msg.longtitude.toString();
        this.contacts.country = msg.country;
        this.contacts.isaddressadded = msg.isaddressadded;
      } else {
        //if (this.type == "Customer") {
        this.account.address1 = msg.address1;
        this.account.address2 = msg.address2;
        this.account.city = msg.city;
        this.account.state = msg.state;
        this.account.zip = msg.zip;
        this.account.ProvidenceIsland = msg.ProvidenceIsland;
        this.account.lattitude = msg.lattitude;
        this.account.longtitude = msg.longtitude;
        this.account.country = msg.country;
        this.account.isaddressadded = msg.isaddressadded;
        this.NewForm.controls['address1'].setValue(this.account.address1);
        this.NewForm.controls['address2'].setValue(this.account.address2);
        this.NewForm.controls['city'].setValue(this.account.city);
        this.NewForm.controls['state'].setValue(this.account.state);
        this.NewForm.controls['zip'].setValue(this.account.zip);
        //this.NewForm.controls['country'].setValue(this.account.country);
        this.NewForm.controls['ProvidenceIsland'].setValue(this.account.ProvidenceIsland);
        this.NewForm.controls['lattitude'].setValue(this.account.lattitude);
        this.NewForm.controls['longtitude'].setValue(this.account.longtitude);
      
        this.NewForm.addControl('country', new FormControl(this.account.country));
        this.NewForm.addControl('lattitude', new FormControl(this.account.lattitude));
        if (this.account.country == "882") {
       
          setTimeout(function () {
            $('#city1').addClass('form-control decreasefieldSize ng-pristine');
            $('#city1').removeClass('ng-invalid');
            $('#city1').removeClass('required');

            new FormControl('city', Validators.nullValidator);
          }, 100);


          $('#state1').addClass('form-control decreasefieldSize ng-pristine');
          $('#state1').removeClass('ng-invalid');
          $('#state1').removeClass('required');
          new FormControl('state', Validators.nullValidator);


          $('#zip1').addClass('form-control decreasefieldSize ng-pristine');
          $('#zip1').removeClass('ng-invalid');
          $('#zip1').removeClass('required');
          new FormControl('zip', Validators.nullValidator);


        } else {
          $('#city1').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
          new FormControl('city', Validators.required);

          $('#state1').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
          new FormControl('state', Validators.required);

          $('#zip1').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
          new FormControl('zip', Validators.required);
        }
        
      }
    }

  }

  showStateList() {
    document.getElementById('stateList').style.display = 'block';

  }
  hideStateList() {
    document.getElementById('stateList').style.display = 'none';
  }
  locshowStateList(i) {
    document.getElementById(i + 'locstateList').style.display = 'block';

  }
  lochideStateList(i) {
    document.getElementById(i + 'locstateList').style.display = 'none';
  }
  contactsShowStateList() {

    document.getElementById('contactsStateList').style.display = 'block';
  }
  contactsHideStateList() {

    document.getElementById('contactsStateList').style.display = 'none';
  }
  locstatePopulate(ival, zipid, cityid, stateid, stateDDN) {

    var that = this;
    var zipCode = $('#' + ival + zipid).val();
    var newStr = zipCode.replace(/_/g, "");
    var newStrArray = newStr.split("-");
    if (newStrArray[0].length > 4) {
      var url = "http://ziptasticapi.com/" + newStrArray[0];
      $.ajax({
        url: url,
        dataType: "json",
        type: 'GET',
        success: function (data) {

          $('#' + ival + cityid).val(data.city);
          that.locloadstate(ival, data.state, stateid, stateDDN, data.city);

        }

      });
    }
  }
  cancelAccount() {
    this.FormDataModified = false;
    this.DiscardFormDataConfirmation = false;
    this.sendMsgEvent.emit("CloseAddAccountDialog~" + this.account.id);
  }

  showEditAddressDiv() { 
    this.account.zip = $('#zip1').val();
    this.account.address1 = $('#address11').val();
    this.account.address2 = $('#address21').val();
    this.account.city = $('#city1').val();
    this.account.state = $('#state1').val();
    this.account.country = $('#country').val();
    this.account.lattitude = $('#lattitude1').val();
    this.account.longtitude = $('#longtitude1').val();
    this.account.ProvidenceIsland = $('#ProvidenceIsland1').val();    
    this.NewForm.controls['ProvidenceIsland'].setValue(this.account.ProvidenceIsland);
    this.NewForm.controls['address1'].setValue(this.account.address1);
    this.NewForm.controls['address2'].setValue(this.account.address2);
    this.NewForm.controls['city'].setValue(this.account.city);
    this.NewForm.controls['state'].setValue(this.account.state);
    this.NewForm.controls['country'].setValue(this.account.country);
    this.NewForm.controls['zip'].setValue(this.account.zip);
    this.NewForm.controls['lattitude'].setValue(this.account.lattitude);
    this.NewForm.controls['longtitude'].setValue(this.account.longtitude);
   
    if (this.account.id == 0) {
      this.NewForm.addControl('userid', new FormControl(this.currentuser.id));
      var date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
      this.NewForm.addControl('created', new FormControl(date));
      this.NewForm.controls['created'].setValue(date);
    } else {
      var date = this.datePipe.transform(this.account.created, 'yyyy-MM-dd HH:mm:ss');
      this.NewForm.addControl('created', new FormControl(date));
      this.NewForm.controls['created'].setValue(date);
    }
    var date = this.datePipe.transform(this.account.created, 'yyyy-MM-dd HH:mm:ss');
    this.NewForm.addControl('created', new FormControl(date));
    this.NewForm.controls['created'].setValue(date);
      this.SaveAccount('DoNotUpdate');
  }
 
  SaveAccount(val) {
    if (this.account.isaddressadded) {
      if (this.account.id == 0) {
        this.accountsService.checkduplicateAccountName(this.account.accountname).then(result => {

          if (result == "No") {
            this.accountsService.checkduplicates(this.account).then(resultdata => {

              if (resultdata == "No" || resultdata == null) {
                this.SaveAccountInfo(val);
              }
              else {
                this.ismatchduplicate = result;
                if (resultdata == 'WebsiteDuplicate') { this.websiteduplicate = true; }
                this.SaveAccDialog = true;
              }
            });
          }
          else {
            this.DuplicateAccountDialog = true
            this.deleteduserName = result;
            $(setTimeout(function () {
              $('.dialog').attr('style', 'width:45% !important;border: 4px solid rgb(49,189,240);border-radius:5%; height:70%;top:16%;max-width:none;padding:0;');
            }, 200));

          }
        });


      } else {

        this.SaveAccountInfo(val);
      }

    }
    else {
      this.showmapWarning = true;
      this.showmapWarningDialogCss();
    }
  }
  showmapWarningDialogCss() {
    setTimeout(function () {
      $('#Banoo .dialog').attr('style', 'width:30%;padding-bottom: 0px;border:4px solid rgb(49,189,240);border-radius:5%;min-height: 40%;overflow: visible;height: 42%;top: 32%;left: 56%;');
    }, 4);
  }
  SaveAccountInfo(val) { 
    this.account.userid = this.currentuser.id;   
    this.account.fieldvalues = JSON.stringify(this.NewForm?.value);
        this.accountsService.addaccountNew(this.account).then(result => {
      this.account = result;
      this.SaveAccDialog = false;    
      this.toastr.success("Account saved successfully.", "Account Saved", { timeOut: 600 });
      this.EditAddressDiv = false;
      this.FormDataModified = false;
      $('#test').val('');
      if (this.account.zip != undefined || this.account.zip != null) {
        if (this.account.zip.toString().length >= 6) {
          this.account.zip = this.account.zip.toString().slice(0, 5) + "-" + this.account.zip.toString().slice(5);
        } else if (this.account.zip.toString().length === 5) {
          this.account.zip = this.account.zip.toString();
        } else {
          this.account.zip = this.account.zip;
        }
      }
      this.sendMsgEvent.emit("CloseAddAccountDialog~" + this.account.id);
    });

    $('#statisticHeader').attr('style', 'text-align: left;');
    this.addresses = null;
  }
  


  UpdatedAddressChecked(addressid) {
    this.ReplaceAddressSelected = true;
    if (addressid == 'AccountAdd') {
      this.addressupdate.id = 0;
      this.addressupdate.address1 = this.account.address1;
      this.addressupdate.address2 = this.account.address2;
      this.addressupdate.city = this.account.city;
      this.addressupdate.state = this.account.state;
      this.addressupdate.zip = this.account.zip;
      this.addressupdate.cell = this.account.phone;
      this.addressupdate.lattitude = this.account.lattitude;
      this.addressupdate.longtitude = this.account.longtitude.toString();      
    } else {
      this.addressupdate = this.multiplelocaddresses.filter(x => x.id == addressid)[0];
    }
    
  }
  
  updatecontactaddress() {
    this.addressupdate.ContactIds = this.MappedContacts.map(a => a.id).join(",");
    
    this.accountsService.updatecontactaddress(this.addressupdate).then(result => {
      this.addressupdate = new AddressUpdate();
      this.LocationMappedContactsDiv = false;
      this.accountsService.addaccountLocations(this.addresses).then(result => {
        this.toastr.success("Location Deleted successfully.", "Location", { timeOut: 600 });        
        $("#" + (this.deletedSecretLairsIndex)).remove();
        this.secretLairs.removeAt(this.deletedSecretLairsIndex);
        this.cancelAccount();
      });
    });

    var deletedAddress = $('#addressID' + (this.deletedSecretLairsIndex - 1)).val();
    if (deletedAddress == 0 || deletedAddress == '0') {
      this.accountsService.updateAccountAddress(this.addressupdate).then(result => { });
    }

  }
 
  closeduplicatediv() {
    this.SaveAccDialog = false;
  }
  SaveCustomeAddress() {
    this.account.isaddressadded = true;
    this.contacts.isaddressadded = true;
    this.showmapWarning = false;
  }

  public transform(input: string): string {
    if (!input) {
      return '';
    }
    else {
      return input[0].toUpperCase() + input.substr(1);
    }

  }
  cancelDialog() { this.DuplicateAccountDialog = false; }
  Continue() { this.SaveAccountInfo(this.selectedAddressOption); }
  AddAcctabChanged(val) {
    this.AddAccselectedMenuRadio = val;

    if (val == 'AddAcctab7') {
      this.LoadAdditionalLocations();
    }
    else if (val == 'AddAcctab0') {  
      this.account.CustomerSince = this.account.CustomerSince == "0001-01-01T00:00:00" ? null : this.datePipe.transform(this.account.CustomerSince, 'MM-dd-yyyy');
      this.customerService.GetAccountHistoryById(this.account.id, this.currentuser.id).then(result3 => {
        if (result3 != null) {
          this.accountHistory = result3;         
        }

      });
      this.AccountHistoryDropdowns();
    }
    else if (val == 'AddAcctab1') {
      this.customerService.GetFormConfiguration(this.currentuser.id, 'Account Specifics').then(res => {
        if (res != null && res.length > 0) {
          let obj: string[] = [res[0].OrderFields, 'Account Specifics'];
          this.customerService.getSaveFormFields(obj).then(result => {           
            result.forEach(ele => {
              if (ele['ColumnName'] == 'ownerid') {
                ele['Data'] = this.OwnerList;
              } else {
                if (ele['DataType'] == 'Pick List') {
                  this.customerService.GetPickListData(ele['FieldName'], ele['id'], this.currentuser.id).then((accounttype) => {
                    var data = this.PickListSortByType(ele['FieldDataOrderBy'], accounttype)
                    ele['Data'] = data;
                  });
                }
              }
            });
            this.Fields = result;         
            this.Fields.forEach(x => {
              this.NewForm.addControl(x.ColumnName, new FormControl(''));
            });           
          });
        }

      });
      this.Accountspecificdropdowns();
    }
    else if (val == 'AddAcctab4') {
      
      this.customerService.GetFormConfiguration(this.currentuser.id, 'Mailing Address').then(res => {
        if (res != null && res.length > 0) {
          let obj: string[] = [res[0].OrderFields, 'Mailing Address'];
          this.customerService.getSaveFormFields(obj).then(result => {
            if (this.customeracces == 0) {
              result = result.filter(x => x.ColumnName != 'customertypeid');
            }
            result.forEach(ele => {
              if (ele['ColumnName'] == 'ownerid') {
                ele['Data'] = this.OwnerList;
              } else {
                if (ele['DataType'] == 'Pick List') {
                  this.customerService.GetPickListData(ele['FieldName'], ele['id'], this.currentuser.id).then((accounttype) => {
                    var data = this.PickListSortByType(ele['FieldDataOrderBy'], accounttype)
                    ele['Data'] = data;
                  });
                }
              }
            });
            this.Fields = result;
            this.Fields.forEach(x => {
              this.NewForm.addControl(x.ColumnName, new FormControl(''));

            });
            this.customerService.GetByAccountIdNew(this.account.id).then(result => {
              if (result != null) {               
                this.account = JSON.parse(result);
                let so = this;
                setTimeout(function () {
                  $('#address11').val(so.account.address1);
                  so.NewForm.controls['address1'].setValue(so.account.address1);
                  $('#address21').val(so.account.address2);
                  so.NewForm.controls['address2'].setValue(so.account.address2);

                  $('#city1').val(so.account.city);
                  so.NewForm.controls['city'].setValue(so.account.city);
                  $('#state1').val(so.account.state);
                  so.NewForm.controls['state'].setValue(so.account.state);
                  $('#zip1').val(so.account.zip);
                  so.NewForm.controls['zip'].setValue(so.account.zip);
                  $('#country').val(so.account.country);
                  so.NewForm.controls['country'].setValue(so.account.country);
                  $('#ProvidenceIsland1').val(so.account.ProvidenceIsland);
                  so.NewForm.controls['ProvidenceIsland'].setValue(so.account.ProvidenceIsland);
                  so.NewForm.addControl('lattitude', new FormControl(so.account.lattitude));
                  so.NewForm.addControl('longtitude', new FormControl(so.account.longtitude));
                  $('#lattitude1').val(so.account.lattitude);
                  so.NewForm.controls['lattitude'].setValue(so.account.lattitude);
                  $('#longtitude1').val(so.account.lattitude);
                  so.NewForm.controls['longtitude'].setValue(so.account.longtitude);
                  so.adddata.loadaddress(so.account, 1);
                }, 400);
              }
            });
           

          });

        }

       // this.adddata.loadaddress(this.account, 1);
      });
    }
    else if (val == 'AddAcctab5') {     
      this.customerService.GetFormConfiguration(this.currentuser.id, 'Customer Details').then(res => {
        if (res != null && res.length > 0) {
       
          let obj: string[] = [res[0].OrderFields, 'Customer Details'];
          this.customerService.getSaveFormFields(obj).then(result => {           
         
            result.forEach(ele => {
                if (ele['DataType'] == 'Pick List') {
                  this.customerService.GetPickListData(ele['FieldName'], ele['id'], this.currentuser.id).then((accounttype) => {
                    var data = this.PickListSortByType(ele['FieldDataOrderBy'], accounttype)
                    ele['Data'] = data;
                  });
                }
            });
            this.Fields = result;
            
            this.Fields.forEach(x => {
              this.NewForm.addControl(x.ColumnName, new FormControl(''));             
            });
            this.Fields = [];
            if (this.account.isCustomer != 874) {
              this.account.isCustomer = 875;             
             this.Fields.push(result[0]);
            } else {
              this.Fields = result;
            
              this.account.HL7Interface = this.account.HL7Interface != null ? this.account.HL7Interface:897;
              this.account.CustomerRCM = this.account.CustomerRCM != null ? this.account.CustomerRCM : 901;
              this.account.OnDemand = this.account.OnDemand != null ? this.account.OnDemand : 904;
              this.account.ApptReminders = this.account.ApptReminders != null ? this.account.ApptReminders : 906;
              this.account.eLab = this.account.eLab != null ? this.account.eLab : 908;
              this.account.MIPS = this.account.MIPS != null ? this.account.MIPS : 910;         
              this.account.CustomerSince = this.account.CustomerSince == "0001-01-01T00:00:00" ? null : this.datePipe.transform(this.account.CustomerSince, 'yyyy-MM-dd');
            }
            
            let so = this;
            setTimeout(function () { 
              so.NewForm.controls['IsCustomer'].setValue(so.account.isCustomer);
             
              $('#CustomerSince').val(so.account.CustomerSince);
              if (so.currentuser.customerAccount) {
                $("#accnumber").prop("disabled", false);
              } else {
                $("#accnumber").prop("disabled", true);
              }

              if (so.currentuser.customerStatus) {
                $("#accountStatus").prop("disabled", false);
              } else {
                $("#accountStatus").prop("disabled", true);
              }

              if (so.currentuser.customerSince) {
                $("#CustomerSince").prop("disabled", false);
              } else {
                $("#CustomerSince").prop("disabled", true);
              }

              if (so.currentuser.isCustomer) {
                $("#IsCustomer").prop('disabled', false);
              } else {
                $("#IsCustomer").prop("disabled", true);
              }

              if (so.currentuser.SystemType) {
                $("#SystemType").prop('disabled', false);
              } else {
                $("#SystemType").prop("disabled", true);
              }

              if (so.currentuser.HL7interface) {
                $("#HL7Interface").prop('disabled', false);
              } else {
                $("#HL7Interface").prop("disabled", true);
              }

              if (so.currentuser.CustomerRCM) {
                $("#CustomerRCM").prop('disabled', false);
              } else {
                $("#CustomerRCM").prop("disabled", true);
              }

              if (so.currentuser.OnDemand) {
                $("#OnDemand").prop('disabled', false);
              } else {
                $("#OnDemand").prop("disabled", true);
              }

              if (so.currentuser.ApptReminders) {
                $("#ApptReminders").prop('disabled', false);
              } else {
                $("#ApptReminders").prop("disabled", true);
              }

              if (so.currentuser.eLab) {
                $("#eLab").prop('disabled', false);
              } else {
                $("#eLab").prop("disabled", true);
              }
              if (so.currentuser.MIPS) {
                $("#MIPS").prop('disabled', false);
              } else {
                $("#MIPS").prop("disabled", true);
              }
            }, 300);
          });
        }

      });

    }
    else if (val == 'AddAcctab6') {
      this.DisplayNote();      
    }
  }
  DisplayNote() {
    let allContactIds = [];
    this.customerService.GetAllContactproviderByAccount(this.accountID, this.currentuser.id).then((contacts) => {
      if (contacts.length > 0) {
        allContactIds = contacts.map(x => x.id);
        this.GetCustomerNotes(this.currentuser.id, allContactIds.join(","), 'AllContactsNotes', this.accountID.toString(), "null");
      } else {
        this.GetCustomerNotes(this.currentuser.id, "0", 'AllContactsNotes', this.accountID.toString(), "null");
      }

    });
  }
  LoadAdditionalLocations() {
    this.AddressList = [];
    this.multiplelocaddresses = [];
    this.customerService.GetByAccountIdNew(this.account.id).then(result => {   
      if (result != null) {
        this.account = JSON.parse(result);
        var Addloc = new Accountlocations();
        Addloc.id = 0;
        Addloc.address1 = this.account.address1;
        Addloc.address2 = this.account.address2;
        Addloc.city = this.account.city;
        Addloc.state = this.account.state;
        Addloc.zip = this.account.zip;
        Addloc.fax = this.account.fax;
        Addloc.phone = this.account.phone;
        Addloc.lattitude = this.account.lattitude;
        Addloc.longtitude = this.account.longtitude.toString();
        Addloc.ProvidenceIsland = this.account.ProvidenceIsland;
        Addloc.country = this.account.country;
        Addloc.accountid = this.account.id;
        Addloc.LocationPicture = this.account.LocationPicture;
        Addloc.addressName = 'Main Location';
        Addloc.addressType = "Main";

        this.multiplelocaddresses.push(Addloc);
        
        this.customerService.GetLocationByAccountId(this.account.id).then(result => {
          result.forEach(ele => {

            if (ele.address1 != null && ele.address1 != "") {

              this.multiplelocaddresses.push(ele);
            }
          })
          var index = 1;
          var LocationIndex = 2;
          this.multiplelocaddresses.forEach(ele => {
            if ((ele.address1 != undefined && ele.address1 != '') || (ele.address2 != undefined && ele.address2 != '')) {
              this.multipleLocation(1,false);
              let selfObj = this;
              $(setTimeout(function () {

                selfObj.AddressList.push({ "id": ele.id, "itemName": "Location (" + (LocationIndex) + ") :- " + ele.address1 + ", " + ele.address2 + ", " + ele.city + ", " + ele.state, "Category": 'Additional Loaction', "Location Picture": ele.LocationPicture });
             
                selfObj.adddata.loadaddress(ele, index);
                index++;
                LocationIndex++;

              }, 100));
            }
          });

        });
      }
    });

  
  }
  SaveAdditionalAddress() {
  
    this.addresses = [];
    let index = this.secretLairs.value.length;
   
    for (var i = 1; i <= index ; i++ ){
        let multipleAdd = new Accountlocations();
        multipleAdd.id = $('#addressID' + i).val();
      multipleAdd.addressName = $('#addressName' + i).val();
      multipleAdd.addressType = $('#addressType' + i).val();
        multipleAdd.address1 = $('#address1' + i).val();
        multipleAdd.address2 = $('#address2' + i).val();
        multipleAdd.city = $('#city' + i).val();
        multipleAdd.state = $('#state' + i).val();
        multipleAdd.zip = $('#zip' + i).val();
        multipleAdd.country = $('#country' + i).val();
      multipleAdd.lattitude = $('#lattitude' + i).val();
      multipleAdd.longtitude = $('#longtitude' + i).val();
      multipleAdd.phone = $('#phone' + i).val();
      multipleAdd.fax = $('#fax' + i).val();
      multipleAdd.LocationPicture = $('#UploadImage' + i).val();
      this.multiplelocaddresses.isdelete = 0;
        multipleAdd.ProvidenceIsland = $('#ProvidenceIsland' + i).val();
      multipleAdd.accountid = this.account.id;
      
      if (((multipleAdd.address1 != undefined && multipleAdd.address1 != '' && multipleAdd.address1 != null) || (multipleAdd.address2 != undefined && multipleAdd.address2 != '' && multipleAdd.address2 != null))) {
          this.addresses.push(multipleAdd);
      }
    }
    this.accountsService.addaccountLocations(this.addresses).then(result => {
      this.toastr.success("Location saved successfully.", "Location", { timeOut: 600 }); 
      this.sendMsgEvent.emit("Saved~" + this.account.id);
    });
  }
  
  SaveAccountHistory() {
    $("#test").val('');
    this.accountHistory.accountid = this.account.id;
    this.accountHistory.Salesrep = this.account.salesRep;
    this.accountsService.addaccountHistory(this.accountHistory).then(result => {
      this.toastr.success("Account History saved successfully.", "Account History", { timeOut: 600 }); 
    });
  }
  NOLicenseAccess() {
    this.NoAccess = true;
    setTimeout(function () {
      $('#NoAccess .dialog ').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%')

    }, 4)
  }
  Expand(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      this.ExpandMode = true;
    } else {
      this.ExpandMode = false;
    }
    this.sendMsgEvent.emit(msg);
  }

  PickListSortByType(value, data) {
    var DuplicateFiledDataList = [];
    if (value == 'SortOrder') {
      DuplicateFiledDataList = data.sort((a, b) => a.Sortorder - b.Sortorder);
      return DuplicateFiledDataList;
    } else if (value == 'FieldName') {
      DuplicateFiledDataList = data.sort((a, b) => a.itemName.localeCompare(b.itemName));
      return DuplicateFiledDataList;
    } else {
      DuplicateFiledDataList = data.sort((a, b) => a.Sortorder - b.Sortorder);
      return DuplicateFiledDataList;
    }
  }
  FormDataEntered(val) {
    this.FormDataModified = true;
  }
  IsCustomerTypeChange(value, fieldVal) {
    if (fieldVal == 'Customer') {
      if (value == 874) {
        $('#customertypeid').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
        new FormControl('customertypeid', Validators.required);
      }     
      else {
        $('#customertypeid').removeClass('ng-invalid');
        $('#customertypeid').removeClass('required');
        this.NewForm.controls['customertypeid'].setValue('');
        new FormControl('customertypeid', Validators.nullValidator);

      }
    } else if (fieldVal == 'country') {
      if (value == 882) {
      
        $('#city1').addClass('form-control decreasefieldSize ng-pristine');
        $('#city1').removeClass('ng-invalid');
        $('#city1').removeClass('required');
        new FormControl('city', Validators.nullValidator);

        $('#state1').addClass('form-control decreasefieldSize ng-pristine');
        $('#state1').removeClass('ng-invalid');
        $('#state1').removeClass('required');
        new FormControl('state', Validators.nullValidator);

        $('#zip1').addClass('form-control decreasefieldSize ng-pristine');
        $('#zip1').removeClass('ng-invalid');
        $('#zip1').removeClass('required');
        new FormControl('zip', Validators.nullValidator);

      } else {
        $('#city1').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
        new FormControl('city', Validators.required);

        $('#state1').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
        new FormControl('state', Validators.required);

        $('#zip1').addClass('form-control decreasefieldSize ng-pristine ng-invalid ng-star-inserted ng-touched');
        new FormControl('zip', Validators.required);
      }
    }  else if (fieldVal == 'customertypeid') {
      $('#customertypeid').removeClass("ng-pristine ng-valid ng-invalid");
      $('#customertypeid').addClass('ng-valid valid');
      $('#customertypeid').addClass('required')
    }
  }
  DiscardFormDataConfirmationCss() {
    this.DiscardFormDataConfirmation = true;
    setTimeout(function () {
      $('#DiscardFormDataConfirmation .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 4);
  }

}
