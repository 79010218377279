<div class="container" style="height:100%;padding:0 0 0 1%;">

  <div class="col-xs-12" style="padding: 0; height: 100%;">
    <div *ngIf="(type=='SalesQuota'||type=='TicketQuota')" style="height: 100%;">
      <div class="col-xs-12" style="padding: 0; background-color: rgb(40,62,74);">
        <div class="col-xs-2" style="padding:0;float: left;">
          <select id="salesyearselected" class="form-control" (change)="SelectedYear($event.target.value)" style=" height: 23px; padding: 0; margin: 1%; " required>
            <option *ngFor="let y of years" [selected]="yy === y ">{{y}}</option>
          </select>
        </div>
        <div class="col-xs-2" style="padding:0;float: right;color:white;text-align:center">
          <input type="checkbox" [(ngModel)]="isSalesQuota" id="SaleQuota" (change)="SaleQuota();"><label>&nbsp;Display % </label>
        </div>
      </div>
      <ag-grid-angular style="width: 100%;height: 89%; padding-top: 10px;overflow:auto;" class="ag-theme-balham"
                       [gridOptions]="SalesQuotagridOptions"
                       [columnDefs]="SalesQuotacolumnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       (cellValueChanged)="onCellValueChanged($event)"
                       [rowData]="SalesQuotadata"
                       [sideBar]="sideBar"
                       (rowClicked)="agGridRowSelected($event)"
                       (rowSelected)="agGridRowSelected($event)"
                       [enterMovesDownAfterEdit]="true"
                       [enterMovesDown]="true"
                       (gridReady)="onSalesQuotaGridReady($event)">
      </ag-grid-angular>
      
      <div class="col-xs-12" style="padding:0;height:8%">
        <div class="col-xs-6"  style="float: right; height: 9%; padding-top: 3%;">

          <div class="col-xs-6" style="padding:0;">
          <button class="btn noprovider" style="width: 100%; background: white; padding: 5px 7px;" ><span class="mainCheckMark"></span>Cancel</button></div>
          <div class="col-xs-6" style="padding:0;">
            <button id="saveSales" class="btn noprovider" style="width: 100%; background: #a2e477; padding: 5px 7px;" (click)="SaveSalesQuotaData();"><span class="mainCheckMark" disabled='disabled'></span>Save Sales Quota</button></div>
        </div>
      </div>

      <br />

    </div>

    <div *ngIf="type=='TaskQuota'" style="height: 100%;">

      <div class="col-xs-12" style="padding: 0; background-color: rgb(40,62,74);">
        <div class="col-xs-2" style="padding:0;float: left;">
          <select id="activityyearselected" class="form-control" (change)="ActivitySelectedYear($event.target.value)" style=" height: 23px; padding: 0; margin: 1%; " required>
            <option *ngFor="let y of years" [selected]="yy === y ">{{y}}</option>
          </select>
        </div>
        <div class="col-xs-2" style="padding:0;float: right;color:white;text-align:center">
          <input type="checkbox" [(ngModel)]="isActivityQuota" id="TaskQuota" value="TaskQuota" (change)="TaskQuota();"><label>&nbsp;Display % </label>
        </div>
      </div>
      <ag-grid-angular [style.max-height]="aggridheight" style="width: 100%;height: 86%; overflow:auto; padding-top: 10px;" class="ag-theme-balham"
                       [gridOptions]="ActivityQuotagridOptions"
                       [columnDefs]="ActivityQuotacolumnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       (cellValueChanged)="onCellValueChanged($event)"
                       [rowData]="ActivityQuotadata"
                       [sideBar]="sideBar"
                       (rowClicked)="agGridActivityRowSelected($event)"
                       (rowSelected)="agGridActivityRowSelected($event)"
                       [enterMovesDownAfterEdit]="true"
                       [enterMovesDown]="true"
                       (gridReady)="onActivityQuotaGridReady($event)">
      </ag-grid-angular>


      <!--<div class="col-xs-12" style="padding:0;">
        <div class="col-xs-5" style="float:right;padding:0;">
          <div class="col-xs-5">
            <button style="background:#d3d3d3;margin-top:5px;" class="btn btn-long provider saveButtonCSS">Cancel </button>
          </div>
          <div class="col-xs-7">
            <button (click)="SaveActivityQuotaData()" id="saveActivity" style="background:#d3d3d3;margin-top:5px;" class="btn btn-long provider saveButtonCSS" disabled='disabled'>Save Activity Quota</button>
          </div>
        </div>
      </div>-->
      <div class="col-xs-12" style="padding:0;height:8%">
        <div class="col-xs-6" style="float: right; height: 9%; padding-top: 3%;">

          <div class="col-xs-6" style="padding:0;">
            <button class="btn noprovider" style="width: 100%; background: white; padding: 5px 7px;"><span class="mainCheckMark"></span>Cancel</button>
          </div>
          <div class="col-xs-6" style="padding:0;">
            <button id="saveActivity" class="btn noprovider" style="width: 100%; background: #a2e477; padding: 5px 7px;" (click)="SaveActivityQuotaData();"><span class="mainCheckMark" disabled='disabled'></span>Save Activity Quota</button>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="closedUserQuotaEventListener()"></div>
  </div>

</div>
