export class AccountSummaryCheckBox {
  public isPhoneScheduled: boolean = false;
  public isPhoneCOmpleted: boolean = false;
  public isToDoScheduled: boolean = false;
  public isTodoCompleted: boolean = false;
  public isMeetingsScheduled: boolean = false;
  public isMeetingsCompleted: boolean = false;
  public isAccountsAdded: boolean = false;
  public isContactsAdded: boolean = false;
  public isLeadsConverted: boolean = false;
  public isOpportunitiesAdded: boolean = false;
}
