import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef, Optional, Input } from '@angular/core';
//import { CalendarComponent } from "ap-angular-fullcalendar";
import { Http } from '@angular/http';
import { Opportunities } from '../../models/opportunities';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from './../../services/customer.service';
import { DatePipe } from '@angular/common';
import { TaskHistory } from '../../models/task-history';
import { Notes } from '../../models/notes';
import { CalanderEvents } from '../../models/calanderEvent';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import { LeadsService } from './../../services/leads.service';
import { SalesProcessHistory } from '../../models/sales-process-history';
import { ToastrService } from 'ngx-toastr';;
import { Contacts } from '../../models/contacts';
import { Accounts } from '../../models/accounts';
import { Customers } from '../../models/customers';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { NotesrendererComponent } from '../notesrenderer/notesrenderer.component';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { Column } from 'ag-grid';
import * as moment from 'moment'
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../application.component';
import { ScheduletaskComponent } from "../scheduletask/scheduletask.component";
import { UserService } from '../../services/user.service';
import { NotesComponent } from '../notes/notes.component';
import { AccountsService } from 'src/app/services/accounts.service';
declare var require: any;
declare var $: any;
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, FullCalendarComponent } from '@fullcalendar/angular';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { TaskComponent } from '../task/task.component';
import timeGridPlugin from '@fullcalendar/timegrid';
import { AuditLogModel } from '../../models/auditlog';
import { Users } from '../../models/users';

@Component({
  selector: 'app-month-calender',
  templateUrl: './month-calender.component.html',
  styleUrls: ['./month-calender.component.css'],

})
export class MonthCalenderComponent implements OnInit {
  showDialog = false;
  isOn: boolean = true;
  check: boolean = true;
  dialogOpen: boolean = false;
  datepickerOpts: any;
  calenderMonthlist = ["August"];
  num: any;
  Ids = [];
  calenderHgt = screen.height * 0.61222;
  public sideBar;
  ids: any;
  title: any;
  TimeGridWeekOptions0: CalendarOptions = {};
  //ListViewOptions: CalendarOptions = {};
  events = [];
  FirstEvents = [];
  call: any;
  times: any;
  titlesave: Date;
  startsave: Date;
  startdate: Date;
  enddate: Date;
  endsave: Date;
  editRowId: any;
  itemCount = 0;
  dayitemCount = 0;
  params: any;
  dayparams: any;
  Detailsdiv: boolean = false;
  updatediv: boolean = true;
  mycalander1clicked: boolean = false;
  mycalander2clicked: boolean = false;
  MoveTaskDialog: boolean = false;
  hdrHtml0: boolean = false;
  hdrHtml1: boolean = false;
  hdrHtml2: boolean = false;
  taskDialog: boolean = false;
  BTClick: boolean = false;
  SearchDialog: boolean = false;
  finaldata = [];
  newEvents = [];
  globaleventid: number;
  globaldata = [];
  globalEvent = [];
  items = [];
  dayitems = [];
  titlesss: any;
  dropdownSettings = {};
  finalitems = "";
  myhidden: boolean = true;
  CalendarDialog: boolean = true;
  myvar: boolean = true;
  myremindersdiv: boolean = false;
  iscountdisplay: boolean = false;
  taskhistory: TaskHistory = new TaskHistory();
  user: Users = new Users();
  saleprocesshistory: SalesProcessHistory = new SalesProcessHistory();
  @ViewChild(ScheduletaskComponent) scheduleComp: ScheduletaskComponent;
  @ViewChild(TaskComponent) TaskComp: TaskComponent;
  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;
  @ViewChild(NotesComponent) notecomp;
  contact: Contacts = new Contacts();
  account: Accounts = new Accounts();
  taskhistorynotes: Array<Notes> = [];
  TaskStatuslist: Dropdowntype[] = [];
  Tasktypelist: Dropdowntype[] = [];
  taskhistoryList = new Array();
  notes: Notes = new Notes();
  calevent: CalanderEvents = new CalanderEvents();
  inbound: Array<any> = [];
  demo1Vals: any;
  displayKey: String = 'name';
  allSelected: Boolean = false;
  groupBy: string = 'type';
  statustype: string = 'All Tasks';
  groups: Array<any>;
  dropDownVisible: boolean = false;
  selectedItems: Array<any>;
  inboundSelector: Array<any>;
  filterVal: String;
  userprevilages: any;
  currentuser: any;
  calanderView: any;
  itemResource: any;
  dayitemResource: any;
  datePipe: DatePipe = new DatePipe("en-US");
  searchDiv: boolean = false;
  CustomeritemResource: any;
  ContactitemCount = 0;
  CustomeritemCount = 0;
  Contactitems = [];
  Customeritems = [];
  public gridOptions: GridOptions;
  public rowData: any[];
  public columnDefs: any[];
  pageSize = "10";
  tableDiv: boolean = true;
  labelDiv: boolean = true;
  dropdownList = [];
  dropdownSetting = {};
  alltaskhistory: TaskHistory[];
  globalalltaskhistory: TaskHistory[];
  aggridheight: string;
  timezonevalue: string;
  calenderHeight = (screen.height * 0.5888);
  public context;
  public gridOptionsnotes: GridOptions;
  public columnDefsnotes: any[];
  allnotes: Notes[];
  @ViewChild('closeBtn') closeBtn: ElementRef;
  public frameworkComponents;
  today = Date.now();
  DisplayrowsList: Dropdowntype[] = [];
  SelectedDate: any;
  showListView = "BlockView";
  showtaskDialog: boolean = false;
  selectedTaskId: number=0;
  @ViewChild(TaskComponent) taskcomp: TaskComponent;
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");

  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018,   today = Date.now();}
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  NotesDec = false;
  taskhistoryid: number;
  contactid: number;
  conid: number;
  customer: Customers = new Customers();
  selectedmonth = new Date();
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  claenderViewlist: Dropdowntype[];
  private gridApi;
  LoaderDialog: boolean = false;
  expandCalenderView: boolean = false; eventtype: string = ""; eventid: string = ""; eventdate: Date; startday: string; endday:string;
  changeCalendarView(view) {
    //this.myCalendar.fullCalendar('changeView', view);
  }
  close() {
    this.closeBtn.nativeElement.click();
  }
  onCalendarInit() {
  }
  ngAfterViewInit(): void {
  }

  handleDateClick(arg) {
    $('.tooltiptopicevent').remove(); 
    this.SelectedDate = arg.dateStr;
    $('#checkDiv').css('height', '89%');
    this.SearchDialog = !this.SearchDialog;
    this.SearchDialogCss();

  }

  handleDateSelect(arg) {
  }
  handleEvents(arg) {
  }
  @Input()
  id: number;
  @Input()
  type: string;
  @Input()
  taskid: number;
  view: string;
  CalenderViewType: string;
  calanderonoff: boolean = false;

  handleEventClick(clickInfo: EventClickArg) {
    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  }
  constructor(public applicationComponent: ApplicationComponent, private http: Http, private router: Router, private customerService: CustomerService, private opprtunityservice: OpprtunitiesService, private leadservice: LeadsService, private userservice: UserService, private toastr: ToastrService, private viewContainerRef: ViewContainerRef, private route: ActivatedRoute, private accountService: AccountsService) {
    this.selectedItems = [];
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {
      this.applicationComponent.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
      ];
      localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
      $("#userids").val(this.currentuser.id);
      this.applicationComponent.ExpandSelectedMenu(localStorage.getItem("Tab"));
    }
    this.calanderView = localStorage.getItem("calanderView");
    this.refresh();

  }

  ngOnInit() {
    this.user.calanderView = this.currentuser.calanderView;
    this.applicationComponent.pageViewed = 'calander';
    this.applicationComponent.selectedbuttonText = "Calendar";
    var obj: AuditLogModel = { id: 0, type: 'Calendar', action: 'Calendar Viewed', AccountOrContactId: 0, AccountOrContact: "", documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Calendar Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);

    this.route.params.subscribe(params => {
      this.view = params['subMenuId'];
      this.CalenderViewType = params['viewType'];

    });

    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }
    //this.initializeAgGrid();
    
    this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
      this.DisplayrowsList = Rows;
    });
    this.customerService.GetPrivilagesByuser(this.currentuser.id).then(result => {
      this.userprevilages = result;
    });

    this.customerService.GetSpecialityList("calenderView", this.currentuser.id).then((calender) => {
      calender.forEach(accounttype => {
      });
      this.claenderViewlist = calender;
    });

    var moment = require('moment-timezone');
    this.timezonevalue = moment.tz.guess();
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id, 1, 0, 0).then((Owner) => {
      Owner.forEach(Owner => {
      });

      this.dropdownList = Owner;

      if (this.applicationComponent.selectedusers != '' && this.applicationComponent.selectedusers != null && this.applicationComponent.selectedusers != undefined) {
        this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
          Owner.forEach(Owner => {
          });
          this.selectedItems = Owner;
        })
        this.TaskData(this.applicationComponent.calandarType, $("#userids").val());
      }
      else {
        this.selectedItems = [
          { "id": this.currentuser.id, "itemName": this.currentuser.username }
        ];
        this.finalitems += this.currentuser.id + ',';
        //$("#userids").val(this.currentuser.id);
        this.TaskData('All Tasks', $("#userids").val())
      }
    })

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3,
      lazyLoading: true
    };
    
    this.userservice.GetUserByuserId(this.currentuser.id).then(result => {
      this.user = result;
      this.loadcalanderview(this.user.calanderView, 0);

    });
    let SO = this;
    window.onchange = function (event) { 

    }

  }
  makeEventsDraggable(eventtype, eventid, date) {
    let that = this;
    $('.fc-draggable').each(function () {
      $(this).data('event', {
        title: $.trim($(this).text()), // use the element's text as the event title
        stick: true // maintain when user navigates (see docs on the renderEvent method)
      });
      $(this).draggable({
        zIndex: 999,
        revert: true,      // will cause the event to go back to its
        revertDuration: 0  //  original position after the drag
      });
      $("td").removeClass("fc-highlight");
    });
    $('.fc-rigid').attr('style', 'height:80px');
    $('.fc-day-grid-container').attr('style', 'height:auto');
    if (eventid != 0) {
      that.leadservice.GetByTaskHistoryId(eventid, "View", that.currentuser.timezone).then(result => {
        that.taskhistory = result;
        that.taskhistory.estcompleteddate = date;
        that.taskhistory.contactid = that.taskhistory.contactid;
        that.taskhistory.type = that.taskhistory.type;
        that.taskhistory.userid = that.currentuser.id;
        that.taskhistory.timezone = that.currentuser.timezone;
        that.taskhistory.tasktype = $("#sel1 option:selected").text();
        if (that.taskhistory.time != undefined || that.taskhistory.time != null) {
         
          that.taskhistory.starttime = moment(date).format("MM/DD/YYYY hh:mm a");
        }
        if (that.taskhistory.totime != null) {
          var difference = moment.duration(moment(that.taskhistory.totime).diff(moment(that.taskhistory.time)));
          var endTime = new Date(date.getTime() + difference.asMinutes() * 60000);
          that.taskhistory.endtime = moment(endTime).format("MM/DD/YYYY hh:mm a");
        }
        that.leadservice.addTaskHistory(that.taskhistory).then(result => {

          that.notes.accountid = result.id;
          that.notes.type = "TaskHistory";
          if (eventtype == "eventDrop" || eventtype == "eventReceive") {
            $(setTimeout(function () {
              //that.TaskData(that.statustype, $("#userids").val())

              that.checkvalues('All Tasks', $("#userids").val());
            }, 100));
          }


          that.notes.userid = that.currentuser.id;
          that.notes.notedescription = "Task Moved to " + moment(date).format("MM/DD/YYYY hh:mm a");
          that.opprtunityservice.addopprtunityNotes(that.notes).then(result => {
            that.notes = result;
            that.taskhistory.notedescription = '';
            this.toastr.success("Task saved successfully.", "Task Saved", { timeOut: 600 });
          });
        });

      });
    }

  }

  TaskData(val, userids) {
    this.applicationComponent.selectedusers = userids;
    userids = $("#userids").val();
    this.statustype = val;
    $('#taskstatus').val(val);
    this.applicationComponent.calandarType = val;
    if ($("#userids").val() != '') {
      this.checkvalues(val, userids);
    }
    else {
      this.items = [];
      this.itemCount = 0;

      this.TimeGridWeekOptions0.events = [];

     // this.ListViewOptions.events = [];
    }
  }

  refresh() {
    var that = this;
    var calEventStatus = [];
    that.taskhistoryList = [];   
    that.route.params.subscribe(params => {
      that.view = params['subMenuId'];
      that.CalenderViewType = params['viewType'];
    });


    that.TimeGridWeekOptions0 = {
      plugins: [timeGridPlugin],  
      views: {        
        TwoWeek: {
          text: '2 Weeks',
          type: 'timeGridWeek',
          duration: { weeks: 2 },
          dayHeaderContent: ({ date }) => {
            return moment(date).format('ddd MM/DD')           
          },         
        }
      },
      allDaySlot: false,
      slotDuration: '00:15:01',
      slotMinTime: '6:00',
      slotMaxTime: '24:00',
      nowIndicator: true,
      selectable: true,
      editable: true,
      droppable: true,
      slotLabelFormat:
      {
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false,
      },
      buttonText: {
        //today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        list: 'List',
        TwoWeek: '2 Weeks',
      },
      headerToolbar:
      {
        left: 'today,prev,next',
        center: 'title',
        right: 'timeGridDay,timeGridWeek,TwoWeek,dayGridMonth'     
      },
      
      //buttonText: {
      //  today: 'Today',
      //  month: 'Month',
      //  week: 'Week',
      //  day: 'Day',
      //  list: 'List',
      //  twoweek: '2 week',
      //},
      dateClick: this.handleDateClick.bind(this),
      eventDidMount: function (event) {
      },
      datesSet: function (event) {
        that.startday = event.startStr.toString();
        that.endday = event.endStr.toString();
        if (that.iscountdisplay) {

          that.loadtaskValues(that.startday, that.endday);
        }
      },
      eventDrop: function (event) {     
        that.eventtype = "eventDrop";
        that.eventid = event.event.id;
        that.eventdate = event.event.start;
        that.MoveTaskDialog = true;
        setTimeout(function () {
          $('#MoveTaskDialog .dialog').attr('style', 'width: 29%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height:22%;overflow: auto;height: 4%;top:0%');
        }, 4)

      },
      
      loading: function (isLoading) {

        if (isLoading) {
          that.LoaderDialog = true;
          that.LoaderDialogCss();
        }
        else {
          that.LoaderDialog = false;
        }

      },
      eventResize: function (event, ) {
        that.makeEventsDraggable('eventResize', event.event.id, event.event.start);
      },
      eventReceive: function (event) {
        that.makeEventsDraggable('eventReceive', that.globaleventid, event.event.start);
      },
      eventClick: function (event) {
        $(setTimeout(function () {

          that.leadservice.GetByTaskHistoryId(Number(event.event.id), "View", that.currentuser.timezone).then(result => {
            that.taskhistory = result;
            if (!that.taskhistory.status.startsWith("Complete")){
              that.id = that.taskhistory.contactid;
              that.taskid = that.taskhistory.id;
              that.type = that.taskhistory.type;
              if (that.taskhistory.contactid == null || that.taskhistory.contactid == 0 || that.taskhistory.contactid == undefined) {
                that.BTClick = true;
              }
              var url = window.location.href;
              if (url.indexOf('month-calender') > -1) {
                $('#checkDiv').css('height', '83%');
              }
              if (that.taskid != undefined) {
                that.taskDialog = !that.taskDialog;
                that.taskDialogCss();
                $(setTimeout(function () {
                  $('.tooltiptopicevent').remove(); 
                  that.TaskComp.loadcoantactname(that.id, that.type);
                  that.TaskComp.LoadSelectedTaskdata(that.taskid, true, "View");
                  that.TaskComp.loadtaskHistoryByselecteddate(that.taskhistory.estcompleteddate);

                }, 300));
              }
            }
          });
        }, 300));

      },
      eventMouseEnter: function (event) {
        var notesli = "";
        that.leadservice.GetAllTaskHistoryById(Number(event.event.id), 'TaskHistory').then((items) => {
          this.taskhistorynotes = items;
          this.taskhistorynotes.forEach((res) => {
            notesli += "<span> -" + res.notedescription + "</span><br />";
          });

        });


        that.leadservice.GetByTaskHistoryId(Number(event.event.id), "View", that.currentuser.timezone).then(taskdata => {
          that.taskhistory = taskdata;

          var moment = require('moment-timezone');
          let frm = (event.event.start == null) ? '(NTS)' : moment.tz(event.event.start.toLocaleString(), this.timezonevalue).format("h:mm A");
          let to = (event.event.end == null) ? '(NTS)' : moment.tz(event.event.end.toLocaleString(), this.timezonevalue).format("h:mm A");
          var contact = (that.taskhistory.contactid != 0 && that.taskhistory.contactid != null) ? "<b>Contact: </b>" + that.taskhistory.contactName + "<br/>" : "";
          var Account = (that.taskhistory.contactid != 0 && that.taskhistory.contactid != null) ? "<b>Account: </b>" + that.taskhistory.accountname + "<br/>" : "";
          let timelabel = frm + '-' + to;
          var tooltip = "<div class='tooltiptopicevent' style='width:auto;border:1px solid black;font-size:12px;height:auto;background:rgb(162, 228, 119);position:absolute;z-index:10001;padding:0.5% 0.5% 0.5% 0.5%;line-height:150%;top:"
            + (event.jsEvent.pageY) + "px;left:" + (event.jsEvent.pageX) + "px;'>"
            + "<b>Date: </b>" + moment(event.event.start).format("MM/DD/YYYY") + "<br/>"
            + "<b>Time: </b>" + timelabel + "<br/>"
            + contact
            + Account
            + "<b>Type: </b>" + event.event.title.split("[")[0] + "<br/>"
            + "<b>Notes: </b>" + notesli + "<br/>"
            + "</div>";
          $(this).mouseover(function (e) {
            $('.tooltiptopicevent').css('top', e.pageY + 10);
            $('.tooltiptopicevent').css('left', e.pageX + 20);
          });
          $(this).mousemove(function (e) {
            $('.tooltiptopicevent').css('top', e.pageY + 10);
            $('.tooltiptopicevent').css('left', e.pageX + 20);
          });
          $("body").append(tooltip);
        });
      },
      eventMouseLeave: function (data) {       
       $('.tooltiptopicevent').remove();        
      },
      eventDragStart: function (event) {
        that.globaleventid = 0;
        calEventStatus['calendar'] = '#calendar1';
        calEventStatus['event_id'] = event.event.id;
        that.globaleventid = Number(event.event.id);
      },
      
      eventDragStop: function (event) {
        

        calEventStatus = [];

      },

    };




    if (that.CalenderViewType !== undefined) {

      var curr = new Date(that.CalenderViewType); // get current date
      var first = curr.getDate(); // First day is the day of the month - the day of the week
      var last = first + 7; // last day is the first day + 6

      var firstday = this.datePipe.transform(curr.setDate(first), 'yyyy-MM-dd');/* new Date(curr.setDate(first)).toUTCString();*/
      var lastday = this.datePipe.transform(curr.setDate(last), 'yyyy-MM-dd');/* new Date(curr.setDate(last)).toUTCString();*/

      this.TimeGridWeekOptions0.validRange = function (nowDate) {
        return {
          start: that.CalenderViewType,
          end: that.CalenderViewType,
        };
      }
    }


    //that.ListViewOptions = {
    //  initialView: 'listWeek',
     
    //  headerToolbar:
    //  {
    //    left: 'today,prev,next',
    //    center: 'title',
    //    right: "listDay,listWeek,listTwoWeek,listMonth"

    //  },
    //  allDaySlot: true,
    //  views: {

    //    listTwoWeek: {
    //      text: 'Two Weeks',
    //      type: 'listWeek',
    //      duration: { weeks: 2 },
    //      dayHeaderContent: ({ date }) => {
    //        return moment(date).format('ddd MM/DD')
    //      },

    //    }, 
    //    today: { buttonText: 'Today'},
    //    listDay: { buttonText: 'Day' },
    //    listWeek: { buttonText: 'Week' },
    //    listMonth: { buttonText: 'Month' },
    //    listYear: { buttonText: 'Year' },
        
    //  },

    //  eventDidMount: function (event) {
    //  },
    //  eventClick: function (event) {
    //    $(setTimeout(function () {
    //      that.leadservice.GetByTaskHistoryId(parseInt(event.event.id), "View", that.currentuser.timezone).then(result => {
    //        that.taskhistory = result;
    //        that.id = that.taskhistory.contactid;
    //        that.taskid = that.taskhistory.id;
    //        that.type = that.taskhistory.type;

    //        that.showtaskDialog = !that.showtaskDialog;
    //        that.ShowtaskDialogBoxCss();
    //        $(setTimeout(function () {
    //          //that.scheduleComp.loadcoantactname(that.id, that.type);
    //          //that.scheduleComp.LoadSelectedTaskdata(that.taskid, true, "View");
    //          //that.scheduleComp.loadtaskHistoryByselecteddate(that.taskhistory.estcompleteddate);

    //          that.TaskComp.loadcoantactname(that.id, that.type);
    //          that.TaskComp.LoadSelectedTaskdata(that.taskid, true, "View");
    //          that.TaskComp.loadtaskHistoryByselecteddate(that.taskhistory.estcompleteddate);
    //        }, 300));;


    //      });
    //    }, 400));

    //  },

    //};
    $('#NewtimeGridWeekCalender1').hide();
    $('#NewtimeGridWeekCalender2').hide();
    $('#myCalendar1Div').hide();
    $('#myCalendar2Div').hide();


  }

  ShowtaskDialogBoxCss() {
    $(setTimeout(function () {

      $('#showtaskDialog .dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');

    }, 4));

  }

  hours_am_pm(time) {
    if (time != null && time != '') {
      let hour = (time.split(':'))[0]
      let min = (time.split(':'))[1]
      let part = hour > 12 ? 'pm' : 'am';
      min = (min + '').length == 1 ? `0${min}` : min;
      hour = hour > 12 ? hour - 12 : hour;
      hour = (hour + '').length == 1 ? `0${hour}` : hour;
      return `${hour}:${min} ${part}`
    }

  }
  checkvalues(val, userids) {
    this.selectedTaskId = Number(localStorage.getItem('TaskSelected'));
    this.alltaskhistory = [];
    this.newEvents = [];
    this.finaldata = [];
    if (userids) {
      this.leadservice.GetAllTaskHistoryForCalendar(val, userids, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, 'null', val, this.currentuser.timezone).then((taskhistory) => {
        this.globalalltaskhistory = taskhistory;
        var curr = new Date(); // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 7; // last day is the first day + 6

        var firstday = so.datePipe.transform(curr.setDate(first), 'yyyy-MM-dd');/* new Date(curr.setDate(first)).toUTCString();*/
        var lastday = so.datePipe.transform(curr.setDate(last), 'yyyy-MM-dd');
        this.loadtaskValues(firstday, lastday);
        taskhistory.forEach(taskhistory => {
          taskhistory.datecreated = this.datePipe.transform(taskhistory.datecreated, "yyyy-MM-dd");
          taskhistory.datecompleted = taskhistory.datecompleted == null ? "" : this.datePipe.transform(taskhistory.datecompleted, "yyyy-MM-dd");
          taskhistory.DateSched = taskhistory.estcompleteddate == null ? "" : this.datePipe.transform(taskhistory.estcompleteddate, "yyyy-MM-dd");
          taskhistory.time = taskhistory.starttime;
          taskhistory.totime = taskhistory.endtime;
         
          var moment = require('moment-timezone');
          taskhistory.estcompleteddate = moment.tz(taskhistory.estcompleteddate.toLocaleString(), this.timezonevalue).format("YYYY-MM-DD");

          if (taskhistory.status == 'Open') {

            if ((taskhistory.starttime != null && taskhistory.starttime != '0:00')) {
              (this.finaldata).push({

                id: taskhistory.id,
                title: taskhistory.tasktype + ((taskhistory.contactid == 0 || taskhistory.contactid == null) ? '' : "    - " + taskhistory.firstName + "," + taskhistory.lastName),
                start: (taskhistory.starttime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.starttime).toISOString() : '',
                end: (taskhistory.endtime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.endtime).toISOString() : '',
                color: (taskhistory.contactid == 0) ? '#0c3d6d' : '#3788d8',
                backgroundColor: (this.selectedTaskId == taskhistory.id) ? '#000000' : (taskhistory.taskcategory == "Phone") ? '#00aa55' : taskhistory.taskcategory == "To Do" ? '#f56042' : taskhistory.taskcategory == "Blocked Times" ? '#aa54aa' : '#3788d8',
                borderColor: (this.selectedTaskId == taskhistory.id) ?'black':'none'
              });
            }
            else {
              (this.finaldata).push({
                id: taskhistory.id,
                title: taskhistory.tasktype + ((taskhistory.contactid == 0 || taskhistory.contactid == null) ? '' : "    - " + taskhistory.firstName + "," + taskhistory.lastName),
                start: taskhistory.estcompleteddate,
                color: (taskhistory.contactid == 0) ? '#0c3d6d' : '#3788d8',
                backgroundColor: (this.selectedTaskId == taskhistory.id) ? '#000000' :(taskhistory.taskcategory == "Phone") ? '#00aa55' : taskhistory.taskcategory == "To Do" ? '#f56042' : taskhistory.taskcategory == "Blocked Times" ? '#aa54aa' : '#3788d8',
                borderColor: (this.selectedTaskId == taskhistory.id) ? 'black' : 'none',

              });
            }
          }
          else if (taskhistory.status == 'Completed' || taskhistory.status == 'Complete & Repeat' || taskhistory.status == 'Complete & Schedule New' || taskhistory.status == 'Complete & Return' || taskhistory.status =='Not Completed') {
            if ((taskhistory.starttime != null && taskhistory.starttime != '0:00')) {
              (this.finaldata).push({
                id: taskhistory.id,
                title: taskhistory.tasktype + ((taskhistory.contactid == 0 || taskhistory.contactid == null) ? '' : "    - " + taskhistory.firstName + "," + taskhistory.lastName),
                start: (taskhistory.starttime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.starttime).toISOString() : '',
                end: (taskhistory.endtime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.endtime).toISOString() : '',
                color: (taskhistory.contactid == 0) ? 'lightgrey' : 'lightgrey',
                textColor: 'black',
                backgroundColor: (this.selectedTaskId == taskhistory.id) ? '#000000' :'lightgrey',
                borderColor: (this.selectedTaskId == taskhistory.id) ? 'black' : 'none'
              });
            } else {
              (this.finaldata).push({
                id: taskhistory.id, color: (taskhistory.contactid == 0) ? 'lightgrey' : 'lightgrey', textColor: 'black',
                backgroundColor: (this.selectedTaskId == taskhistory.id) ? '#000000' :'lightgrey',
                Contact: taskhistory.contactName, title: taskhistory.tasktype + "[" + taskhistory.owner, start: new Date(taskhistory.estcompleteddate + " " + ((taskhistory.starttime) ? taskhistory.starttime : '')).toISOString(),
                borderColor: (this.selectedTaskId == taskhistory.id) ? 'black' : 'none'

              });
            }
          }
          else {
            if ((taskhistory.starttime != null && taskhistory.starttime != '0:00')) {
              (this.finaldata).push({
                id: taskhistory.id,
                title: taskhistory.tasktype + "[" + taskhistory.firstName + " " + taskhistory.lastName,
                start: (taskhistory.starttime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.starttime).toISOString() : '',
                end: (taskhistory.endtime) ? new Date(taskhistory.estcompleteddate + " " + taskhistory.endtime).toISOString() : '',
                color: (taskhistory.contactid == 0) ? '#0c3d6d' : '#3788d8',
                backgroundColor: (this.selectedTaskId == taskhistory.id) ? '#000000' : (taskhistory.taskcategory == "Phone") ? '#00aa55' : taskhistory.taskcategory == "To Do" ? '#f56042' : taskhistory.taskcategory == "Blocked Times" ? '#aa54aa' : '#3788d8',
                borderColor: (this.selectedTaskId == taskhistory.id) ? 'black' : 'none'
              });
            } else {
              (this.finaldata).push({
                id: taskhistory.id, color: (taskhistory.contactid == 0) ? '#0c3d6d' : '#3788d8',
                backgroundColor: (this.selectedTaskId == taskhistory.id) ? '#000000': (taskhistory.taskcategory == "Phone") ? '#00aa55' : taskhistory.taskcategory == "To Do" ? '#f56042' : taskhistory.taskcategory == "Blocked Times" ? '#aa54aa' : '#3788d8',
                Contact: taskhistory.contactName, title: taskhistory.tasktype + "    &nbsp;  " + taskhistory.firstName + "," + taskhistory.lastName, start: taskhistory.estcompleteddate, From: taskhistory.starttime, To: taskhistory.endtime,
               // borderColor: (this.selectedTaskId == taskhistory.id) ? 'black' : 'none'
              });
            }
          }
        });
        if (this.check == true) {
          $('.fc .fc-today-button').attr('disabled', false);
          this.newEvents = this.finaldata;
          this.TimeGridWeekOptions0.events = [];
          this.TimeGridWeekOptions0.events = this.newEvents;
          this.finaldata = [];
        }
        this.applicationComponent.searchCriteriadata = [];
      
      });
    } else {
      this.TimeGridWeekOptions0.events = []; 
      //this.ListViewOptions.events = [];
      this.newEvents = [];

    }
    let so = this;
    $(".fc-dayGridMonth-button").click(function () {
      var curr = new Date(); // get current date
      var first = new Date(curr.getFullYear(), curr.getMonth(), 1);
      var last = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);

      var firstday = so.datePipe.transform(first, 'yyyy-MM-dd');
      var lastday = so.datePipe.transform(last, 'yyyy-MM-dd');

      so.TimeGridWeekOptions0.validRange = function (nowDate) {
        return {
          start: firstday,
          end: lastday,
        };
      }
      so.loadtaskValues(firstday, lastday);
   
    });


    $(".fc-timeGridWeek-button").click(function () {
      so.iscountdisplay = true;
    });

   
    $(".fc-TwoWeek-button").click(function () {
      so.iscountdisplay = true;  

    });

    $(".fc-timeGridDay-button").click(function () {
      so.iscountdisplay = true;        
    });
    $(".fc-today-button").click(function () {
      so.iscountdisplay = true;     
    });

  }
  loadtaskValues(firstday: string, lastday: string) {
 
    this.calevent.ToDoCount = this.globalalltaskhistory.filter((val) => val.estcompleteddate >= firstday && val.estcompleteddate <= lastday && val.taskcategory.includes('To Do')).length;
    this.calevent.PhoneCount = this.globalalltaskhistory.filter((val) => val.estcompleteddate >= firstday && val.estcompleteddate <= lastday && val.taskcategory.includes('Phone')).length;

    this.calevent.MeetingCount = this.globalalltaskhistory.filter((val) => val.estcompleteddate >= firstday && val.estcompleteddate <= lastday && val.taskcategory.includes('Meeting')).length;

    this.calevent.BlockedCount = this.globalalltaskhistory.filter((val) => val.estcompleteddate >= firstday && val.estcompleteddate <= lastday && val.taskcategory.includes('Blocked Times')).length;

    this.calevent.OpenTaskCount = this.globalalltaskhistory.filter((val) => val.estcompleteddate >= firstday && val.estcompleteddate <= lastday && val.status.includes('Open')).length;

    //if (this.showListView == 'ListView') {
      this.alltaskhistory = this.globalalltaskhistory.filter((val) => val.estcompleteddate >= firstday && val.estcompleteddate <= lastday);
    //}
    }

  CustomeSort(ascending, field, status) {
    let that = this;
    return function (a, b) {
      if (new Date(a[field]) < new Date('yyyy-MM-dd')) return 0;
      else if (a[status] < b[status]) return -1;
      else return -1;
    };
  }

  loadcalanderview(selectedView, type) {
      this.expandCalenderView = false;
      $('#NewtimeGridWeekCalender0 .fc-button-group .fc-today-button').attr('style', 'width: 80px !important;');
      $('#NewtimeGridWeekCalender0 .fc-button-group .fc-prev-button').attr('style', 'width: 80px !important;padding-left: 25px;');
      $('#NewtimeGridWeekCalender0 .fc-button-group .fc-next-button').attr('style', 'width: 80px !important;padding-left: 25px;');
      $('#NewtimeGridWeekCalender0 .fc-button-group .fc-timeGridDay-button').attr('style', 'width: 80px !important;');
      $('#NewtimeGridWeekCalender0 .fc-button-group .fc-timeGridWeek-button').attr('style', 'width: 80px !important;');
      $('#NewtimeGridWeekCalender0 .fc-button-group .fc-dayGridMonth-button').attr('style', 'width: 80px !important;');
      $('#NewtimeGridWeekCalender0 .fc-button-group .fc-TwoWeek-button').attr('style', 'width: 80px !important;');
      $('#NewtimeGridWeekCalender0 div.fc-timegrid-body').attr('style', 'position: relative;z-index: 1;min-height: 100%;width: 102% !important;');
      $('#NewtimeGridWeekCalender0 div.fc-scroller').attr('style', ' height:50% !important;overflow: auto!important;');
   
  }

  onItemSelect(item: any) {
    var ids = "";
    ids += item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.TaskData(this.statustype, $("#userids").val())
  }
  OnItemDeSelect(item: any) {
    var ids = "";
    ids = item.id;
    var result = '';
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.TaskData(this.statustype, $("#userids").val())
    } else {
      this.TimeGridWeekOptions0.events = [];
      //this.calendarOptions0.events = [];
      //this.calendarOptions1.events = [];
      //this.calendarOptions2.events = [];
     // this.ListViewOptions.events = [];

      this.alltaskhistory = [];
      $("#userids").val('');
      this.itemCount = 0;
      this.statustype = this.statustype;
    }
  }
  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));

    this.TaskData(this.statustype, $("#userids").val())
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.applicationComponent.selectedusers = null;
    this.alltaskhistory = [];
    $("#userids").val('');
    this.TimeGridWeekOptions0.events = [];

    //this.ListViewOptions.events = [];

  }

  dateChange(event) {
    this.AssignStartAndEndDate(event);
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
    this.checkvalues($('#taskstatus').val(), $("#userids").val());
    this.saveConfiguration();
  }
  AssignStartAndEndDate(event) {

    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  TaskDataEventListener() {
    if (this.applicationComponent.isTaskDataLeadEventRaised) {
      this.pageSize
      this.TaskData(this.applicationComponent.TaskDataVal, this.applicationComponent.TaskDataUserids);
      this.applicationComponent.isTaskDataLeadEventRaised = false;
    }
    return false;
  }
  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single',
    };
    this.createColumnDefsnotes("");
    this.gridOptionsnotes.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  createColumnDefsnotes(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }
        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellStyle: { 'text-align': 'left' }, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];
  }
  //onAgGridReadynotes(event) {
  //  let selfObj = this;
  //  $(setTimeout(function () {
  //    selfObj.autoSizeAllnotes();
  //    $(setTimeout(function () {
  //      selfObj.autoSizeAllnotes();
  //    }, 400));
  //  }, 400));
  //}
  autoSizeAllnotes() {
    var allColumnIds = [];
    if (this.gridOptionsnotes && this.gridOptionsnotes.columnApi) {
      var allCols = this.gridOptionsnotes.columnApi.getAllColumns();
      if (allCols != null) {
        allCols.forEach(function (column) {
          allColumnIds.push(column.getColId());
        });
      }
    }

  }

  createColumnDefs() {
      this.columnDefs = [       
        {
          field: 'datecreated', headerName: 'Date', hide: false, width: 100,cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'time', headerName: 'Time', hide: false, cellRenderer: (params) => {
            return params.data.time + " - " + params.data.endtime;
          }
        },        
        {
          field: 'DAY', headerName: 'Day', width: 100,hide: false
        },
        {
          field: 'accountname', headerName: 'Account', hide: false
        },
        {
          field: 'firstName', headerName: 'First Name', hide: false
        },
        {
          field: 'lastName', headerName: 'Last Name', hide: false
        },
        {
          field: 'titleName', headerName: 'Title', width: 100, hide: false
        },
        {
          field: 'tasktype', headerName: 'Type', hide: false
        },
        {
          field: 'taskcategory', headerName: 'Task', hide: false
        },
        {
          field: 'status', headerName: 'Status', hide: false
        },
       
      ];
    
  }
  //onAgGridReady(event) {
  //  let selfObj = this;
  //  $(setTimeout(function () {
  //    selfObj.autoSizeAll();
  //    $(setTimeout(function () {
  //      selfObj.autoSizeAll();
  //    }, 100));
  //  }, 100));
  //}
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  autoSizeAll() {
    var allColumnIds = [];
    if (this.gridOptions && this.gridOptions.columnApi) {
      this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.getColId());
      });
    }
  }
  txtPageSizeChanged(pageSize) {

    //this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    this.gridOptions.api.paginationSetPageSize(Number(this.alltaskhistory.length));
    this.saveConfiguration();
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  saveConfiguration() {

    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 25;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";
    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 9;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;
    //this.opprtunityservice.saveDashboardconfiguration(config);

  }


  UpdateSearchColumns(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[] = columnSelections.split(',');

    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');
      if (fields[2] == 'true' && (fields[1] != '#' && fields[0] != 'notes')) {
        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }
    //this.applicationComponent.ShowSearchTabs('True');
    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];
    if (retrievedObject != null) {
      let obj: any[] = JSON.parse(retrievedObject);
      obj.forEach(element => {
        if (element.subtab == 'TaskCalander' && this.applicationComponent.Searchtabname == "TaskCalander") {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        } else if (element.subtab == 'ViewCalander' && this.applicationComponent.Searchtabname == "ViewCalander") {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        } else {
          this.applicationComponent.SearchSelectedItemsLengthChanged = false;
        }


      });
    }
    if (tempTableColumns && tempTableColumns.length > 0) {
      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })
    }
    else {
      this.accountService.GetDefaultColumns('TaskCalander', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.applicationComponent.SearchSelectedItems = [
            { "id": 'accountname', "itemName": 'Account Name' },
            { "id": 'firstName', "itemName": 'First Name' },
            { "id": 'lastName', "itemName": 'Last Name' },
          ];
        }
      });
    }
  }

  //rowClickAgGrid(rowEvent) {

  //  
  //  if ((this.Ids).indexOf(rowEvent.data.contactid) > -1) {
  //    var index = (this.Ids).indexOf(rowEvent.data.contactid);
  //    (this.Ids).splice(index, 1);
  //    var string = (this.Ids).toString();
  //    $('#emailIds').val(string);
  //    $('#tasklistselectedtyle').val('');

  //  } else {
  //    this.Ids.push(rowEvent.data.contactid)
  //    var string = (this.Ids).toString();
  //    $('#emailIds').val(string);
  //    if (rowEvent.data.type == "Contacts") {
  //      $('#tasklistselectedtyle').val('Contact');
  //    }
  //    else {
  //      $('#tasklistselectedtyle').val('Customer');
  //    }
  //  }



  //}

  onTaskListChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (rowEvent.api.getSelectedNodes()[0].data) {
        var data = rowEvent.api.getSelectedNodes()[0].data;
        var curr = new Date(data.estcompleteddate); // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 7; // last day is the first day + 6
        var firstday = this.datePipe.transform(curr.setDate(first), 'yyyy-MM-dd');/* new Date(curr.setDate(first)).toUTCString();*/
        var lastday = this.datePipe.transform(curr.setDate(last), 'yyyy-MM-dd');/* new Date(curr.setDate(last)).toUTCString();*/
        this.TimeGridWeekOptions0.validRange = function (nowDate) {
          return {
            start: firstday,
            end: lastday,
          };
        }
      }
    } else {
      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      var last = first + 7; // last day is the first day + 6
      var firstday = this.datePipe.transform(curr.setDate(first), 'yyyy-MM-dd');/* new Date(curr.setDate(first)).toUTCString();*/
      var lastday = this.datePipe.transform(curr.setDate(last), 'yyyy-MM-dd');/* new Date(curr.setDate(last)).toUTCString();*/
      this.TimeGridWeekOptions0.validRange = function (nowDate) {
        return {
          start: firstday,
          end: lastday,
        };
      }
      //this.TimeGridWeekOptions0.initialDate = new Date();
      $('#NewtimeGridWeekCalender0 .fc-today-button').click();
    }
  }

  rowDoubleClickAgGrid(rowEvent) {

    var message = rowEvent.data.contactid + '|' + rowEvent.data.id + '|' + rowEvent.data.type
    //var data = msg.split("|");
    this.id = rowEvent.data.contactid;
    this.taskid = rowEvent.data.id;
    this.type = rowEvent.data.type;

    this.leadservice.GetByTaskHistoryId(this.taskid, "View", this.currentuser.timezone).then(result => {
      this.taskhistory = result;
      this.id = this.taskhistory.contactid;
      this.taskid = this.taskhistory.id;
      this.type = this.taskhistory.type;
      this.taskDialog = !this.taskDialog;
      this.taskDialogCss();
    });
  }

  openDisplaynotes(params) {
    //  this.notes.notedescription = params.value;
    this.NotesDec = !this.NotesDec;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    var notesOffset = $("#divNotesContainer").offset();
    let notesWidth = $('#divNotesContainer').width();
    let notesHeight = $('#divNotesContainer').height();
    let windowWidth = $(window).width();
    let showDialogWidth = 350;
    $(setTimeout(function () {

      this.dialogsize();

      if ($('input[name=Notes]').is(':checked')) {
        $('#txtLongNoteDesc').css('height', '81%');
        $('#txtLongNoteDesc').css('padding-top', '0%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
        $('#txtUserFullName').css('height', '14%');
      } else {
        $('#txtLongNoteDesc').css('height', '96%');
        $('#txtLongNoteDesc').css('padding-top', '6%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
      }
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));
  }

  dialogsize() {
    setTimeout(function () {
      $('#showDetailsDialog').attr('style', ' width: 100%;padding - bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min - height: 63%;overflow: auto;height: 63%;');

    }, 4)
  }
  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();

  }
  saveEditedNote() {

    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.closeDetailsDialog();
        selfObj.getTaskHistoryNotes(selfObj.contactid, 'TaskHistory');
      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
    //this.Notes(this.selectedTabName);
  }

  getTaskHistoryNotes(id, type) {
    this.customerService.GetAllNotesList(this.currentuser.id, id, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.initializeAgGridNotes();
      this.allnotes = taskhistory;

      let windowHeight = $(window).height() - 330;

    });
  }

  onTaskSaved(msg) {
    this.taskDialog = !this.taskDialog;
    this.taskDialogCss();
    //this.TaskData(this.statustype, $("#userids").val())
    this.TaskData('All Tasks', $("#userids").val());
    this.SelectedDate = null;
  }
  openNotesDialogAg(rowdata) {
    //$('#checkDiv').css('height', '63%');
    //this.taskhistoryid = rowdata.id;
    //if (rowdata.type == 'Contacts') {
    //  this.opprtunityservice.GetContactById(rowdata.contactid).then(result => {
    //    this.opprtunityservice.GetByAccountId(result.accountid).then(result => {
    //      this.account = result;
    //    });
    //    var middleName = '';
    //    var title = '';
    //    if (result != null && result.middlename != null) {
    //      middleName = result.middlename;
    //    }
    //    if (result != null && result.title != null) {
    //      title = ',' + result.title;
    //    }
    //    if (result != null)
    //      this.contact.firstname = result.firstname + ' ' + middleName + ' ' + result.lastname + title;
    //  });
    //}
    //else if (rowdata.type == 'Customer') {
    //  this.customerService.GetByproviderId(rowdata.contactid).then(result => {
    //    this.customer = result;
    //    var middleName = '';
    //    var title = '';
    //    if (result != null && result.middle != null) {
    //      middleName = result.middle;
    //    }
    //    if (result != null && result.title != null) {
    //      title = ',' + result.title;
    //    }
    //    if (result != null)
    //      this.contact.firstname = result.first + ' ' + middleName + ' ' + result.last + title;
    //  });
    //}
    //this.contactid = rowdata.id;
    //this.conid = rowdata.contactid;
    //$('#accotIdNotes').val(rowdata.id);
    //$('#accotTypeNotes').val('TaskHistory');
    //this.getTaskHistoryNotes(rowdata.id, 'TaskHistory');
    //this.showDialog = !this.showDialog;
    //this.NotesDialogCss();
    //let selfobj = this;
    //$(setTimeout(function () {
    //  selfobj.notecomp.Notes('TaskHistory');
    //  $('#chkShowDetailsNotes').prop('checked', false); $('#closeDetailsDialog').click();
    //}, 100))


    //$('.dialog').width('1000px');


    this.conid = rowdata.contactid;
    this.showDialog = !this.showDialog;
    this.NotesDialogCss();

  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }
  onSearchstring(msg) {
    $('.tooltiptopicevent').remove(); 
    if (msg == 'BTClick') {
      this.taskDialog = true;
      this.SearchDialog = true;
      this.taskDialogCss();
      this.SearchDialog = !this.SearchDialog;
      this.BTClick = true;
      this.id = 0;
      this.taskid = 0;     
    } else {
      var data = msg.split("|");
      this.contactid = data[0];
      this.SearchDialog = !this.SearchDialog;
      this.SearchDialogCss();
      this.taskDialog = !this.taskDialog;
      this.taskDialogCss();
      this.id = data[0];
      this.taskid = 0;
      this.type = data[1];
      this.BTClick = false;
      let selfObj = this;      
      $(setTimeout(function () {
        selfObj.TaskComp.loadcoantactname(selfObj.id, selfObj.type);
        selfObj.TaskComp.LoadSelectedTaskdata(selfObj.taskid, true, "View");
        selfObj.TaskComp.loadtaskHistoryByselecteddate(selfObj.taskhistory.estcompleteddate);
      }, 300));
    }
  }
  calanderclick(val) {
    this.calanderonoff = val;
    this.checkvalues($('#taskstatus').val(), $("#userids").val());
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }

  }

  UserlistDataReceived(outProps: OutputPropsUserList) {
    this.calanderonoff = outProps.calanderonoff;   
    if (outProps.finalitems != "") {
      $("#userids").val(outProps.finalitems);
      if (!outProps.pageSizeChanged) {
        this.TaskData(outProps.secondDrpValue, $("#userids").val());
        this.loadcalanderview(outProps.thirdDrpValue, outProps.thirdDrpValue);
      }
    } else {
      this.alltaskhistory = [];
      $("#userids").val('');
    }
  }
  taskDialogCss() {
    $(setTimeout(function () {
      $('#taskDialog .dialog').attr('style', 'width: 45%;padding: 0;min-height: 63%;height: 99%;top: 0;left: 3%;max-width: 1393px !important;margin-right:1%;');
    }, 4));
  }
  SearchDialogCss() {
    $(setTimeout(function () {
      $('#SearchDialog .dialog').attr('style', 'width: 100%;padding: 0;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }
  ShowCalenderListView(msg) {
    this.showListView = msg;
    if (this.showListView == "ListView") {     
      $(setTimeout(function () {
        $('#NewtimeGridWeekCalender0 div.fc-view-harness').attr('style', 'display:none;');
      }, 4));
      this.iscountdisplay = true;
      this.initializeAgGrid();
      this.loadtaskValues(this.datePipe.transform(this.startday, 'yyyy-MM-dd'), this.datePipe.transform(this.endday, 'yyyy-MM-dd'));     
    } else {
    this.iscountdisplay = false;
      $(setTimeout(function () {
        $('#NewtimeGridWeekCalender0 div.fc-view-harness').attr('style', 'display:block;');
        $('#NewtimeGridWeekCalender0 div.fc-view-harness').attr('style', 'height:1120px;');
      }, 4));   
      
    }
    this.loadcalanderview(msg, msg);
  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: false, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true,
      toolPanelSuppressPivotMode: true, rowSelection: 'single', enableCellChangeFlash: true, suppressSorting: true, suppressMultiSort: true
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createColumnDefs();
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
  }
  onTaskclose(event) {
    this.showtaskDialog = !this.showtaskDialog;
  }
  expandCalender(val) {
    this.expandCalenderView = val;

    if (val == true) {
      $(setTimeout(function () {
        $('#NewtimeGridWeekCalender0 div.fc-timegrid-body').attr('style', 'position: relative;z-index: 1;min-height: 100%;width: 101.2% !important;');
        $('#NewtimeGridWeekCalender0 div.fc-scroller').attr('style', ' height:51% !important;overflow: auto!important;');
      }, 4));
    } else {
      $(setTimeout(function () {
        $('#NewtimeGridWeekCalender0 div.fc-timegrid-body').attr('style', 'position: relative;z-index: 1;min-height: 100%;width: 102% !important;');
        $('#NewtimeGridWeekCalender0 div.fc-scroller').attr('style', ' height:81% !important;overflow: auto!important;');
      }, 4));
    }
  }
  LoaderDialogCss() {
    setTimeout(function () {
      $('#LoaderDialog .dialog').attr('style', ' width: 50%;padding-bottom: 0px;border: none;min-height: 63%;overflow: auto;height: 50%;background: transparent;left: 48%;top: 50%;');
    }, 1)
  }

  MoveTask(val) {
    if (val == "OK") {
      this.makeEventsDraggable(this.eventtype, this.eventid, this.eventdate);
      this.MoveTaskDialog = false;
    } else {
      this.checkvalues('All Tasks', $("#userids").val());
    }
  }
  Close() {
    this.showListView = 'BlockView';
  }
}
