<div id="divNotesContainer" class="container" style="padding-left:0% !important;padding-right: 0;margin-top:0 !important;height:100%">
  <div class="col-xs-12" style="text-align:center;margin-left: 0%;padding:0;height:100%" *ngIf="notesMainDiv && !openFileView">

    <div [ngClass]="this.isModal ? 'col-xs-8' :'col-xs-12'" style="height:100%;padding:0;">
      <div class="col-xs-12 col-sm-12 col-md-12" style="background-color: #2A3740;padding-bottom: 0;height:5%;color:white;text-align:center;" *ngIf="this.isModal">
        <div class="col-xs-1">
          <img src="../../../assets/img/Expand View.png" style="height:85%;width:20%;float:left" />
        </div>
        <div class="col-xs-9"><label style="margin-top:0.5%">ACCOUNT DETAILS</label></div>
        <div class="col-xs-1"><img src="../../../assets/img/White x.png" style="height:20px;float:left" /></div>

      </div>

      <div class="col-xs-12" style="padding:0;height:7%" *ngIf="!noteidDiv && this.ViewType !== 'Block'">

        <div class="col-xs-12" style="padding:0;height:100%">
          <div class="col-xs-1" style="padding:0;height:100%;" *ngIf="typ != 'Project'">
            <button (click)="enterNotes()" style="background:none"> <img src="../../assets/img/Create New Document Name.png" style="height: 23px;" /></button>

          </div>
          <div [ngClass]="typ == 'Project' ? 'col-xs-9' : 'col-xs-4'" style="text-align:left;height:100%">
               </div>
          <div [ngClass]="typ == 'Project' ? 'col-xs-10' : 'col-xs-7'" style="padding:0px;height:95%" *ngIf="typ != 'Project'">
            <div class="col-xs-11" style="padding:0;height:100%;width:18%">
            </div>

            <div class="col-xs-1" style="padding: 0; height: 100%; width:10%;float:right;">

              <div class="col-xs-12">
                <button (click)="View('Block')" *ngIf="!NotesBlockView" style="background:none;"> <img src="../../assets/img/Expand View Off.png" style="height: 20px;" /></button>
                <button (click)="View('Off')" *ngIf="NotesBlockView" style="background:none;"> <img src="../../assets/img/Expand View ON.png" style="height: 20px;" /></button>
              </div>            
            </div>           
          </div>
          <div class="col-xs-1" style="padding: 0px; height: 95%; padding-left:60px" *ngIf="typ == 'Project'">
            <div class="col-xs-1" style="padding:0;height:100%;width:90%">
              <button (click)="View('Block')" *ngIf="!NotesBlockView" style="background:none;"> <img src="../../assets/img/Expand View Off.png" style="height: 20px;" /></button>
              <button (click)="View('Off')" *ngIf="NotesBlockView" style="background:none;"> <img src="../../assets/img/List View Off.png" style="height: 20px;" /></button>
            </div>         
          </div>
        </div>
      </div>

      <div class="col-xs-12" style="padding:0;" *ngIf="!noteidDiv" [style.height.%]="this.isModal ? '90' : '95'">
        <div class="tab-content" style="height:100%" *ngIf="!NotesBlockView">
          <div id="notesGrid" class="tab-pane fade in active" style="height:100%">
          <ag-grid-angular class="ag-theme-balham" id="notsHGTCont" style="height:99%"
                             [gridOptions]="gridOptionsnotes"
                             [columnDefs]="columnDefsnotes"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="allnotes"
                             [pagination]="true"
                             [sideBar]="sideBar"
                             (selectionChanged)="notesChanged($event)">
            </ag-grid-angular>
          </div>
        </div>
        <div *ngIf="NotesBlockView" style="overflow:auto;height:100%">
          <div *ngIf="typ != 'Project'" class="col-xs-12" style="border: 2px solid lightgray;padding:0px;font-weight: bold;">
            <div class="col-xs-12" style="background-color: #2A3740; color: white; text-align: center; padding: 0%; height: 5%;">
              ACCOUNT HISTORY
            </div>
            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%;  text-align: left;"><span> Is Customer</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;"><span *ngIf="account.isCustomer==874">Yes</span><span *ngIf="account.isCustomer==875">No</span></div>
            </div>

            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%;  text-align: left;"><span>Customer Since</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;">
                <span *ngIf="(account.customertypeid == 1 || account.customertypeid == 2)">
                  {{accountHistory.customerSince | date: 'MM-dd-yyyy'}}
                </span>
              </div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%; text-align: left;"><span>Lead From</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;">
                <span *ngIf="accountHistory.LeadFrom">{{accountHistory.LeadFrom}}</span>
              </div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%; text-align: left;">
                <span>Account Status</span>
              </div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;">
                {{(account.accountStatus == 14)? 'Active' :(account.accountStatus == 15)?'On Hold':'' }}
              </div>
            </div>

            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%; text-align: left;"><span>Sold By</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left; color: #0091ea">
                <span *ngIf="(account.customertypeid == 1 || account.customertypeid == 2)">{{(accountHistory.SoldBy == 0)?'Sales Team':'Reseller'}}</span>
              </div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%;  text-align: left;"><span>Account Owner</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;">{{account.OWNER}}</div>
            </div><br />
            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt; padding-top: 10px">
              <div class="col-xs-5" style="height: 100%;  text-align: left;"><span>Account Type</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;">{{account.accounttypeName}}</div>
            </div>


            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%;  text-align: left;"><span>Ownership</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;">{{account.ownershipName}}</div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%;  text-align: left;"><span>Active or Inactive</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left; color: #0091ea">
                <span>
                  {{(account.customertypeid == 1)? 'Active' : (account.customertypeid == 2)? 'Inactive' : ' ' }}
                </span>
              </div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%; text-align: left;"><span>EHR</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;">{{account.ehrName}}</div>
            </div>

            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%;  text-align: left;"><span>PM</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;">{{account.pmName}}</div>
            </div>

            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%; text-align: left;"><span>RCM</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;">{{account.rcmName}}</div>
            </div>
            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%;  text-align: left;"><span>Key Contact (#1)</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;">{{accountHistory.KeyContact1Name}}</div>
            </div>

            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%;  text-align: left;"><span>Key Contact (#2)</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;">{{accountHistory.KeyContact2Name}}</div>
            </div>

            <div class="col-xs-12" style="padding: 0; height: 4%; font-size: 9.5pt;">
              <div class="col-xs-5" style="height: 100%; text-align: left;"><span>Key Contact (#3)</span></div>
              <div class="col-xs-7" style="height: 100%; padding: 0; text-align: left;">{{accountHistory.KeyContact3Name}}</div>
            </div>

          </div>

          <div class="col-xs-12" style="height: 50%; margin-top: 2%; background-color: rgb(76, 87, 101);" *ngFor="let note of allnotes">
            <div class="col-xs-12" style="height: 9%; text-align: left;padding: 1%; color: white;">
              <label style="font-size: 16px; float: left"> Notes</label>
              <label style="margin-left: 4%; float: left; font-size: 16px;"> {{note.created}} </label>
            </div>
            <div class="col-xs-12" style="height:85%;background-color:white;padding:0;">
              <div class="col-xs-12" style="height:25%;text-align:center;padding:0;">
                <div class="col-xs-12" style="padding:1%;">
                  <label *ngIf="this.typ != 'Project'" style="color: black; float: left; font-size: 12px; padding-left: 1.5%;">Contact: {{note.contactname}}</label>
                  <label *ngIf="this.typ == 'Project'" style="color: black; float: left; font-size: 12px; padding-left: 1.5%;">Account: {{note.contactname}}</label>
                  <div class="col-xs-6" style="float:right;padding:0;">
                    <label style="color: black; float: left; font-size: 12px;">User: {{note.user}}</label>
                    <label style="float:right" (click)="openDisplaynotes(note)"><i class="fa fa-pencil"></i></label>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 scroll-c" style="height: 50%; text-align: left; font-weight: 700; color: black; overflow: auto; font-size: 12px;">
                {{note.notedescription}}
              </div>
              <div class="col-xs-12" *ngIf="isDelink && note.id == selectedNoteId" style="text-align: left;padding: 0;background: #bfbebe;color: white; background: #3c3b3b;opacity: 0.9;height: 50%; margin-top: -16%;text-align: center;">
                <div class="col-xs-12" style="text-align:center;margin-top:5%;font-size:24px;color:white">
                  Select Document to Delink
                </div>
              </div>


              <div class="col-xs-12" style="height:25%;text-align:left;padding:0 0 0 0.5%">
                <div class="col-xs-12" style="height:100%;text-align:left;border-top: 2px solid #d3d3d3;padding:0;display:flex">
                  <div class="col-xs-2" style="padding:0;">
                  
                    <span style="padding-left: 9%;">
                      <img src="../../../assets/img/Add Link.png" style="height:23px;" (click)="addlink(note)" />
                    </span>
                  </div>
                  <div class="col-xs-7" style="padding:0;">
                    <span style="font-size: 21px;margin: 2% 0 0 0;display:flex" *ngIf="note.isdocumentid">
                      <span *ngFor="let doc of note.docList;let i=index" style="font-size: 21px;margin-left: 2%;margin-bottom: 0;">
                        <span>
                          <img src="../../../assets/img/PDF_file_icon.svg.png" style="height:23px;display:block" id="{{doc}}Off" (click)="!isDelink ? openfile(doc, note,i) : delink(doc, note)" />
                        </span>
                        <span>
                          <img src="../../../assets/img/PDF Document to De-Link.png" style="height:23px; display:none" id="{{doc}}ON" (click)="!isDelink ? openfile(doc, note,i) : delink(doc, note)" />
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="col-xs-3" style="padding:0;">
                    <span style="float:right">
                      <button class="btn" (click)="delinkDocs(note.id)" id="{{note.id}}Off" style="display:block">De-Link</button>
                      <button class="btn" (click)="delinkOff(note.id)" id="{{note.id}}ON" style="color:red;display:none;font-weight: 600;">De-Link"ON"</button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12" style="padding: 0; height: 100%; background-color: #e7eff7;" *ngIf="allnotes== null || allnotes == undefined || allnotes.length <= 0">
            <label style="position: inherit; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%); font-size: 20px ">No Notes Added</label>
          </div>
        </div>
      </div>
      <div *ngIf="noteidDiv" class="col-xs-12" style="padding:0;height:100%;">
        <div class="col-xs-12" style="padding:0;text-align:center;color:black;height:6%">
          <label style="margin-left: 2%;float: left; margin-top: 0.5% ">CREATE NEW NOTE</label>
          <!--<div *ngIf="!isModal" class="col-xs-1" style="text-align:right;color: gray;font-size:12px; font-weight: normal;float:right;padding:2px;" (click)="CancelNote()">
            <b>
              <img src="../../../assets/img/Collapse View .png" style="height:17px" />
            </b>
          </div>-->
        </div>
        <div class="col-xs-12" style="padding: 0;height:90%;overflow:auto" id="noteidDiv">
         <div id="noteid" [froalaEditor]="editorOptions" [(froalaModel)]="notedescription"
              style="padding-right: 0%;height:97%"></div>
        </div>
        <div class="col-xs-4" id="saveNotesButtons" style="padding-left: 1%;padding:0; height:8%;float:right;margin-top:1%;" *ngIf="noteidDiv">
          <button class="btn cancel" (click)="CancelNote()" type="submit" style="padding: 0.75%;border: 1px solid;width:48%;font-weight:normal">Cancel</button>
          <button class="btn provider saveButton" (click)="SaveNote()" type="submit" style="margin-left: 2.2%;padding: 0.75%;width: 49%; font-weight:normal;border:1px solid black !important;">Save </button>
        </div>
      </div>
    </div>
  </div>

  <div style="padding:0;height:100%;" *ngIf="openFileView">
    <div class="col-xs-12" style="float:right;text-align:right;padding:0;">
      <div class="col-xs-12" style="padding:0;background-color:#2a3740;">
        <div class="col-xs-2">
          <i *ngIf="documentindex > 0" class="fa fa-arrow-left " aria-hidden="true" id="previous" style="color:white" (click)="previousdocument()"></i>
        </div>

        <div class="col-xs-5"> <label style="color:white"> Document {{documentindex+1}} of {{userdocumentcount}} </label></div>
        <div class="col-xs-2">
          <i *ngIf="documentindex < userdocumentcount-1" class="fa fa-arrow-right " aria-hidden="true" id="next" (click)="nextdocument()" style="color:white"></i>
        </div>
        <div class="col-xs-2"></div>
        <div class="col-xs-1" style="padding:0;float:right;margin-right:3%">
          <img src="../../../assets/img/Collapse View .png" style="height:17px" (click)="CloseopenFileView(null)" />
        </div>
      </div>

    </div>
    <div id="NotespdfDiv" class="col-xs-12">

    </div>


  </div>
  <div *ngIf="AttachDocument" style="height: 80%;">
    <div class="col-xs-11"><h3 style="text-align: center;"> Link Document to Note</h3></div>
    <div class="col-xs-1"><div class="col-xs-12" style="float:right;text-align:right"><img src="../../../assets/img/Collapse View .png" style="height:17px" (click)="CloseopenFileView('AttachDocument')" /></div></div>
    <documentmanagement *ngIf="typ != 'Project'" [accountId]="conid" [selectedtype]="typ" [isUploadDivVisible]="true" [documentview]="documentview" (sendMsgEvent)="onOpenUploadDocument($event)"></documentmanagement>
    <documentmanagement *ngIf="typ == 'Project'" [accountId]="projconid" [selectedtype]="projtyp" [isUploadDivVisible]="true" [documentview]="documentview" (sendMsgEvent)="onOpenUploadDocument($event)"></documentmanagement>
    <button class="btn saveButton" (click)="linkDocument()" style="width: 25%; border: 1px solid black !important; margin: 6px 3px 0px 0px; float: right">Link Document</button>
  </div>
  <div *ngIf="NotesDec" class="detailsDialog" id="showNotesDetailsDialog" style="width: 35%; height: 86%;
      margin-bottom: 25%; margin-top: -8.5%; left: 65%; overflow: auto; padding: 0;background-color: white ">
    <div class="col-xs-12" style="padding: 0; height: 2%; background-color: #ffffb4; padding:1%; font-size: 16px ">
      
    </div>
    <div class="col-xs-12" style="padding: 0; height: 4%;font-size: 16px;border-bottom:1px solid gray; ">
      <div class="col-xs-9">
        <b style="margin-top:0.5%">NOTES</b>
      </div>
      <div class="col-xs-1" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;margin-top:0%;padding:2px;float:right;z-index:1">
        <b><img src="../../../assets/img/Black x.png" style="height:20px" (click)="closeDetailsDialog()" /></b>
      </div>
    </div>
    <hr>
    <div class="col-xs-12" style="font-size: 13px;color:black;">
      <div class="col-xs-12" style="padding:0px;">
        <div class="col-xs-3" style="padding:0px;">
          <label>Date Created:</label>
        </div>
        <div class="col-xs-9" style="padding:0px;">
          <label> {{notesdata.created  | date: 'MM-dd-yyyy'}}</label>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0px;">
        <div class="col-xs-3" style="padding:0px;">
          <label>Time Created:</label>
        </div>
        <div class="col-xs-9" style="padding:0px;">
          <label> {{notesdata.created  | date: 'HH:MM a'}}</label>
        </div>
      </div>
      <div class="col-xs-12" *ngIf="typ != 'Project' && typ != 'Account'" style="padding:0px;">
        <div class="col-xs-3" style="padding:0px;">
          <label>Contact:</label>
        </div>

        <div class="col-xs-9" style="padding:0px;">
          <label> {{notesdata.contactname}}</label>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0px;">
        <div class="col-xs-3" style="padding:0px;">
          <label>Account:</label>
        </div>
        <div class="col-xs-9" style="padding:0px;">
          <label> {{notesdata.accountname}}</label>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0px;">

        <div class="col-xs-3" style="padding:0px;">
          <label>Created By:</label>
        </div>
        <div class="col-xs-9" style="padding:0px;">
          <label> {{notesdata.user}}</label>
        </div>
      </div>
      <div class="col-xs-12" style="padding:0px;">
        <div class="col-xs-3" style="padding:0px;">
          <label>Notes:</label>
        </div>
      </div>
      <div class="col-xs-12" style="font-size: 14px; border: 1px solid black; height: 530px; word-wrap: break-word">
        <label [innerHtml]="notesdata.notedescriptionhtml"></label>
      </div>
    </div>
    <ng-content></ng-content>

    <div class="col-xs-2 col-xs-offset-8" style="margin-left: 71%;">
      <span *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" class="editnote" style="margin-top: 20%;margin-right:-54%;">Edit</span>
      <span *ngIf="showEditNoteBtn" (click)="saveEditedNote()" class="save" style="margin-top: 20%;margin-right:-54%;">Save</span>
    </div>
  </div>
  <app-dialog [(visible)]="Upload" *ngIf="Upload" id="Upload">
    <upload-document [accountId]="conid" [selectedtype]="'Account'" [pageFrom]="'notes'" (sendMsgEvent)="SavedDocuments($event)"> </upload-document>
  </app-dialog>

</div>
<app-dialog [(visible)]="projectaddNote" *ngIf="projectaddNote" id="projectaddNote">
  <div class="col-xs-12" style="padding:0;text-align:center;color:black;height:6%">
    <label style="float: left; margin-left: 2%;margin-top: 0.5% ">CREATE NEW NOTE</label>
  </div>
  <div class="col-xs-12" style="padding: 0;height:93%;overflow:auto">
    <div [froalaEditor]="editorOptions" [(froalaModel)]="notedescription" style="padding-right: 0%;height:99%"></div>
  </div>
  <div class="col-xs-4" id="saveNotesButtons" style="padding-left: 1%;padding:0; height:8%;float:right;margin-right:2%;">
    <button class="btn cancel" (click)="ProjectCancelNote()" type="submit" style="padding: 0.75%;border: 1px solid;width:48%;font-weight:normal">Cancel</button>
    <button class="btn provider saveButton" (click)="SaveNote()" type="submit" style="margin-left: 2.2%;padding: 0.75%;width: 49%; font-weight:normal;border:1px solid black !important;">Save </button>
  </div>
</app-dialog>

<app-dialog [(visible)]="DocViewAccessDenied" *ngIf="DocViewAccessDenied" id="DocViewAccessDenied">

  <div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
    <b style="font-size:15px;">Access Denied</b><br />
    <div style="padding:0;margin-top:1%">
      <b style="font-size:15px;">Higher Level of Permission Required</b><br />
    </div>
    <button class="btn" (click)="DocViewAccessDenied = false" style="float:right;background-color: #0098f7 !important;color: white;margin-top:-5%">OK</button>
  </div>

</app-dialog>
<script>
  new FroalaEditor('#froala-editor', {
    toolbarButtons: [['bold', 'italic', 'underline'], ['embedly', 'html']]
  });

</script>
