import { Component, OnInit, ElementRef, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../../services/customer.service';
import { Contacts } from '../../../models/contacts';
import { Accounts } from '../../../models/accounts';
import { Statistics } from '../../../models/statistics';
import { Tickets } from '../../../models/tickets';
import { Notes } from '../../../models/notes';
import { Emails } from '../../../models/emails';
import { Project } from '../../../models/project';
import { Document } from '../../../models/document';
import { Dropdowntype, Dropdown } from '../../../models/dropdowntype';
import { ToastrService } from 'ngx-toastr';
import { ViewContainerRef } from '@angular/core';
import { AccountsService } from '../../../services/accounts.service';
import { LeadsService } from '../../../services/leads.service';
import { TaskHistory } from '../../../models/task-history';
import { DatePipe, TitleCasePipe} from '@angular/common';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { AccountContactComponent } from '../../../application/account-contact/account-contact.component';
import { TaskComponent } from '../../task/task.component';
import { ApplicationComponent } from '../../application.component';
import { OwnershipsharingComponent } from '../../ownershipsharing/ownershipsharing.component';
import { UploadDocumentComponent } from '../../../application/upload-document/upload-document.component';
import { NotesComponent } from '../../notes/notes.component';
import { AddressComponent } from '../../address/address.component';
import { HistoryComponent } from '../../history/history.component';
import { DocumentmanagementComponent } from '../../documentmanagement/documentmanagement.component';
import { UserService } from 'src/app/services/user.service';
import { Viewaccount } from '../../../models/viewaccount';
import * as moment from 'moment';
import { AccountDetailsComponent } from '../../account-details/account-details.component';
import { OpprtunitiesService } from '../../../services/opprtunities.service';
import { Opportunities } from '../../../models/opportunities';
import { Projects } from '../../../models/ProjectConfiguration';
import { Campaign } from '../../../models/Campaign';
import { CampaignsComponent } from '../../campaigns/campaigns.component';
import { ContactPhotoComponent } from '../contact-photo/contact-photo.component';
declare var require: any;
declare var $: any;
@Component({
  selector: 'contactsdashboard',
  templateUrl: './contactsdashboard.component.html',
  styleUrls: ['./contactsdashboard.component.css']
})
export class ContactsdashboardComponent implements OnInit {
  currentuser: any;
  userprevilages: any;
  Years: any[];
  MedSchoolYears: any[];
  ResidencyYears: any[];
  FellowShipYears: any[];
  public zipmask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public dobmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public contactid: number = 0;
  contactIdArr: any;
  public contactpermission: string;
  contact: Contacts = new Contacts();
  displaycontact: Contacts = new Contacts();
  expanded: boolean = false;
  public account: Accounts = new Accounts();
  statisticlist: Array<Statistics> = [];
  activitieslist: Array<TaskHistory> = [];
  Opportunitylist: Array<Opportunities> = [];
  Campignslist: Array<Campaign> = [];
  ProjectsList: Array<Projects> = [];
  userdocumentlist: any[] = [];
  Originaluserdocumentlist: any[] = [];
  POdocumentlist: any[] = [];
  noteslist: Array<Notes> = [];
  emailslist: Array<Emails> = [];
  projectlist: Array<Project> = [];
  documentlist: Array<Document> = [];
  ticketslist: Array<Tickets> = [];
  FeeAmountdiv: boolean = false;
  currentYear = new Date().getFullYear();
  isTicketsInfoDivVisible: boolean = false;
  AccountIdsDialog: boolean = false;
  activitiesinfo: boolean = false;
  showticketsinfo: boolean = false;
  Projectsinfo: boolean = false;
  showAddEditContact: boolean = false;
  conid: number;
  collegeyearDrop: boolean = true;
  MedSchoolyearDrop: boolean = true;
  ResidencyyearDrop: boolean = true;
  FellowshipyearDrop: boolean = true;
  documentview: boolean = false;
  ShowtaskDialog: boolean = false;
  viewPermission: boolean = true;
  documentindex: number = 0;
  hidDiv: boolean = true;
  Collegelist: Dropdowntype[] = [];
  contacttypelist: Dropdowntype[] = [];
  SexList: Dropdowntype[] = [];
  TitleList: Dropdowntype[] = [];
  SuffixList: Dropdowntype[] = [];
  Specialitytypelist: Dropdowntype[] = [];
  MonthList: Dropdowntype[] = [];
  DayList: Dropdowntype[] = [];
  public stateInfo = [];
  MedicalSchoolList: Dropdowntype[] = [];
  StatesList: Dropdowntype[] = [];
  CitiesList: Dropdowntype[] = [];
  OwnerList: Dropdowntype[] = [];
  public staticsticsId: number;
  stateTextBox: boolean = true;
  stateDropDown: boolean = false;
  displayZipCode: boolean = true;
  displayCountryName: boolean = false;
  edit: boolean = false;
  contactintialized: boolean = false;
  ScheduleTaskOpen: boolean = false;
  StatusDropdownList: Dropdowntype[] = [];
  uploadDocumentShow: boolean = false;
  id: number;
  taskid: number;
  addEditInsideContact: boolean = false;
  showmapWarning: boolean = false;
  seletedcountry: any;
  notesshowDialog = false;
  countryId: number = 1;
  public elementRef;
  datePipe: DatePipe = new DatePipe("en-US");
  allnotes: Notes[];
  public context;
  public columnDefsnotes: any[];
  public gridOptionsnotes: GridOptions;
  public getRowHeight;
  notes: Notes = new Notes();
  notedescription: string;
  private data: Array<Notes> = [];
  public length: number = 0;
  today = Date.now();
  campaignId: number;
  taskDialog = false;
  accDocCount: number = 0;
  conDocCount: number = 0; 
  nodeData:any;
  totalContactdocCount: number = 0;
  isShowDetailsChecked: boolean = false;
  @ViewChild(AccountContactComponent) accComp;
  @ViewChild(UploadDocumentComponent) uploadDoc;
  @ViewChild(AddressComponent) adddata;
  @ViewChild(OwnershipsharingComponent) ownrshipComp: OwnershipsharingComponent;
  @ViewChild(HistoryComponent) historyComp;
  @ViewChild(TaskComponent) taskComp;
  @ViewChild(DocumentmanagementComponent) docMgmtComp;
  @ViewChild(UploadDocumentComponent) uploadDocComp: UploadDocumentComponent;
  @ViewChild(AccountDetailsComponent) accdetailcmp;
  @ViewChild(NotesComponent) noteComp: NotesComponent;
  @ViewChild(CampaignsComponent) campaincmp;
  @ViewChild(ContactPhotoComponent) contactphotocmp;
  AccountIdArr: Dropdowntype[] = [];
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  @Input()
  contactids: any[];
  @Input()
  type: string;
  @Input()
  tab: string;
  @Input()
  SdivHeight: number;
  @Input()
  Source: string;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  public showContactInfo: boolean;
  public showAccountInfo: boolean;
  NotesDec = false;
  ContactDashBoardPage: boolean = true;
  @Input()
  ShowRightInfoDiv: boolean = true;
  titleCasePipe: TitleCasePipe = new TitleCasePipe();
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  ForDocumentInsideContactView: boolean = false;
  documentsinfoBesideContactInfo: boolean = false;
  ForPhotoInsideContactView: boolean = true;
  photoForConatctView: boolean = false;
  timezonevalue: string;
  urlImage = '';
  isModal: boolean = false;
  allowAccess: boolean = true;
  userdocumentcount: number = 0;
  contactemailcount:number=0
  fixHeight = 110;
  documentInsideContactViewHeight = (screen.height * 0.61)
  editContHGT = (screen.height * 0.39444); //0.3944
  divHeight = (screen.height * 0.155555);
  divHeight1 = (screen.height * 0.5);
  divHeight2 = (screen.height * 0.1667);
  divHeight3 = 452;
  RightDivHeight = screen.height * 0.691111;
  height = screen.height * 0.62888;
  isDocumentsInfoDivVisible: boolean = false;
  isTaskShow: boolean = false;
  statisticbox: string = 'Open';
  accountSharingid: number = 0;
  isSharing: boolean = false;
  docstatus: string = "New";
  documentcount: number = 0;
  userInfo: string;
  action: string;
  otherDetailsTable: boolean = true;
  notesinfo: boolean = false;
  documentsinfo: boolean = false;
  Proposalinfo: boolean = false;
  emailsinfo: boolean = false;
  public ShowPDF: boolean = false;
  public SixBoxExpand: boolean = false;
  viewaccount: Viewaccount = new Viewaccount();
  quickViewHistoryDialog: boolean = false;
  QuickViewActivitiesDialog: boolean = false;
  QuickViewNotesDialog: boolean = false;
  QuickViewEmailDialog: boolean = false;
  QuickViewDocumentsDialog: boolean = false;
  QuickViewProposalsDialog: boolean = false;
  QuickViewTicketsDialog: boolean = false;
  QuickViewOpportunityDialog: boolean = false;
  QuickViewProjectDialog: boolean = false;
  quickViewCampaignsDialog: boolean = false;
  quickViewSMSDialog: boolean = false;
  DocExpandView: boolean = false;
  DocumentsFilter;
  public EditDocument: boolean = true;
  futureTaskDialog = false;
  ViewPhotoDialog = false;
  crmDialog = false;
  url = ''; historyName = '';
  constructor(private route: ActivatedRoute, public applicationComponent: ApplicationComponent, private customerService: CustomerService, private userService: UserService, private accountsService: AccountsService, private toastr: ToastrService, private viewContainerRef: ViewContainerRef, private router: Router, myElement: ElementRef, private leadservice: LeadsService, private opprtunityservice: OpprtunitiesService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    var loggedInUserId = this.currentuser.id;
    this.customerService.GetPrivilagesByuser(loggedInUserId).then(result => {
      this.userprevilages = result;
    });
    this.elementRef = myElement;
  }

  AddDocumentsNew(val) {
    this.docstatus = val;
    if (this.uploadDocumentShow) {
      this.documentcount = 0;
      this.uploadDocComp.OpenFilemanual(this.docstatus);
    } else {
      this.docMgmtComp.uploadFiles();
    }
  }
 
  onOpenUploadDocument(msg) {
    var isnum = /^\d+$/.test(msg);
    if (isnum) {
      this.userdocumentcount = msg;
    }
    else if (msg == "UploadDocument") {
      this.uploadDocumentShow = true;
      let objSelf = this;
      $(setTimeout(function () {
        objSelf.uploadDocComp.OpenFilemanual(objSelf.docstatus);
        objSelf.documentcount = 0;
      }, 100));
    }
    else if (msg == "ShowPDF") {
      this.ShowPDF = true;
    } else if (msg == "Hide") {
      this.ShowPDF = false;
    }
    else if (msg == 'EditDocumentTrue') {
      this.EditDocument = false;
    } else if (msg == 'EditDocumentFalse') {
      this.EditDocument = true;
    }
  }


  GetContactInfo(contactid) {
    $('#emailIds').val(contactid);
    $('#tasklistselectedtyle').val('Contact');
    var url = window.location.href;
    if (url.indexOf('tasklistdashboard') > -1) {
      $('#ContactInformation1').attr('style','padding-right:0;')
      this.editContHGT = 240;
      this.allowAccess = false; 
    }
    else if (url.indexOf('quick-view') > -1) {
      $('#ContactInformation1').attr('style', 'overflow:hidden;padding-right:0;padding:0;height:100%');

      this.divHeight1 = screen.height * 0.46222;
      this.divHeight2 = screen.height * 0.15555;
      this.fixHeight = 100;
      this.divHeight3 = 420;
      this.allowAccess = false;
      this.editContHGT = screen.height * 0.36111;
    }
    else if (url.indexOf('opportunitiesdashboard') > -1) {
       
      $('#ContactInformation1').attr('style', 'overflow:hidden;padding-right:0;height:100%');
      $('#Contactinformation').attr('style', 'overflow:auto ;padding-right:0;height:100%' );
      this.editContHGT = screen.height * 0.264;
      this.allowAccess = false;
    }
    else if (url.indexOf('tickets') > -1) {
      $('#ContactInformation1').attr('style', 'overflow:hidden;padding-right:0;height:100%' );
      $('#Contactinformation').attr('style', 'overflow:auto ;padding-right:0;height:100%');      
    }

    else if (url.indexOf('campaigns') > -1) {
      this.allowAccess = false; 
    }

    else if (url.indexOf('month-calender') > -1) {
      this.allowAccess = false;
      document.getElementById('checkDiv').style.width = "1000px !important";
     
      this.divHeight3 = 350;
      this.divHeight1 = 350;
   
    }
    let selfObj = this;

    if (contactid != 0)
      $(setTimeout(function () {
        selfObj.Loadcontactdata(contactid);
      }, 500));
  }
  Stisticboxclose() {
    this.statisticbox = "Close";
  }
  StisticboxOpen() {
    this.statisticbox = "Open";    
  }
  Loadcontactdata(contactid) {
    var url = window.location.href;
    this.staticsticsId = 1;
    if (this.tab == 'QuickView') {      
      $('#emailIds').val(contactid);
      
    }
    $('#tasklistselectedtyle').val('Contact');
    this.contactids = contactid;
    this.customerService.GetContactById(contactid).then(result => {
      this.displaycontact = result;
     
      this.displaycontact.collegegrandyear = moment(this.displaycontact.collegegrandyear).format("YYYY");
      this.displaycontact.medschoolgrandyear = moment(this.displaycontact.medschoolgrandyear).format("YYYY");
      this.displaycontact.fellowshipgrandyear = moment(this.displaycontact.fellowshipgrandyear).format("YYYY");
      this.displaycontact.residencygrandyear = moment(this.displaycontact.residencygrandyear).format("YYYY");
      this.displaycontact.SassDate = this.displaycontact.SassDate != "0001-01-01T00:00:00" ? this.displaycontact.SassDate : "";
      this.displaycontact.SaaSRCM = this.displaycontact.SaaSRCM != "0001-01-01T00:00:00" ? this.displaycontact.SaaSRCM : "";
      this.displaycontact.SaaSRenewal = this.displaycontact.SaaSRenewal != "0001-01-01T00:00:00" ? this.displaycontact.SaaSRenewal : "";
      this.urlImage = "";
      this.customerService.Getcontactimage(contactid).then(res => {       
        this.urlImage = res.photo;
        this.displaycontact.photo = res.photo;
      });
      this.contactid = this.displaycontact.id;
      if (this.displaycontact.city != null) {
        var cityArray = this.displaycontact.city.split(' ');
          var newCity = '';
          for (var i = 0; i < cityArray.length; i++) {
            if (newCity == '') {
              newCity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
            }
            else {
              newCity = newCity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
            }
          }
        this.displaycontact.city = newCity;
      }
      
      this.displaycontact.state = this.displaycontact.state;
      
      this.customerService.loadaccountdata(this.displaycontact.accountid).then(result => {
        this.account = result;
        this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata, this.account.owner);

        if (url.indexOf('tasklistdashboard/Customer') > -1 || url.indexOf('opportunitiesdashboard') > -1 || url.indexOf('campaigns') > -1) {
         
        } else {
          if (this.account.customertypeid == 0) {
            this.statisticbox = 'Close';
            $(".coustomButton").removeClass("mainactiveButton");
            $("#contact_span").addClass("activeColor");
            $("#contact").addClass("mainactiveButton");
          }
          else {
            $(".coustomButton").removeClass("mainactiveButton");
            $("#customers_span").addClass("activeColor");
            $("#customers").addClass("mainactiveButton");
          }
        }
        if (this.account != null) {
          this.customerService.getAccountPermission(this.account.id, this.currentuser.id).then(perresult => {
            this.account.isAccountPermission = perresult;
          });
        }
        if (url.indexOf('opportunitiesdashboard') < 0  ) {
          if (url.indexOf('tickets') < -1) {
            if (this.account.customertypeid == 0) {
              $("#contact").addClass("mainactiveButton");
            } else {
              
              $("#customers").addClass("mainactiveButton");
            }   
          }
        }
        
        if (this.edit) {
          this.editcontact(parseInt(this.contactids.toString()));
        }
        
        if (this.account.website!=null && this.account.website.indexOf('www.') >= 0) {
          this.account.website = ((this.account.website).split('www.')[1])
        }
                if (this.account.accnumber != '0') {
          
          $(".coustomButton").removeClass("mainactiveButton");
          $("#customers").addClass("mainactiveButton");
        }
      });
      this.customerService.GetAllEmailsList(contactid, 'Contacts').then((emails) => {
        this.emailslist = [];        
        this.emailslist = emails;
      });
      this.leadservice.GetAllTaskHistoryByContact(contactid, 'Contacts', this.currentuser.timezone).then((activities) => {
        this.activitieslist = [];
        
        this.activitieslist = activities;
     
      });
      this.customerService.GetAllNotesList(this.currentuser.id, contactid.toString(), "Contact").then((notes) => {
        notes.forEach(notes => {
          var moment = require('moment-timezone');
          var timeDisplay = notes.user + '<br />' + moment.tz(notes.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(notes.created.toLocaleString(), this.timezonevalue).format("h:mm A");
          notes.userfullName = timeDisplay;
          notes.created = this.datePipe.transform(notes.created, "MM/dd/yyyy");
        });
        this.noteslist = notes.filter(x => x.isnewnote == 1 && x.type=='Contact');    
        this.allnotes = this.noteslist;
      
      });

      this.customerService.GetAllDocumentList(contactid, 'Contacts', this.currentYear, 'Contacts').then((document) => {
        this.Originaluserdocumentlist = document;
        this.userdocumentlist = document.filter(x => x.DocSavedFrom !== "Proposal");
        this.POdocumentlist = document.filter(x => x.DocSavedFrom === "Proposal");
      });
      this.customerService.GetTicketsListByContact(contactid, 'All').then((tickets) => {        
        this.ticketslist = tickets;
      });
      this.opprtunityservice.GetAllCampaigns(this.currentuser.id).then((Campaigns) => {
        this.Campignslist = Campaigns;
        this.Campignslist = this.Campignslist.filter(x => x.ContactsLinked == this.contactid.toString());
      });
      this.customerService.GetAllProjectsByAccountId((result.accountid).toString()).then(res => {
        this.ProjectsList = res;
      });

      this.opprtunityservice.GetAllOpportunitiesListByAccountorContact(contactid, 'Opt').then((customer) => {
        customer.forEach((customer) => {
          customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
          customer.actualclosedate = this.datePipe.transform(customer.actualclosedate, "MM-dd-yyyy");
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
        });
        this.Opportunitylist = customer;

      });
    });

    this.loaddropdowns();
    this.customerService.GetAllStatistics().then((statistics) => {
      this.statisticlist = [];
      statistics.forEach(statistics => {
        this.statisticlist.push(statistics)
      });

    });
   
    this.customerService.GetAllProjectList().then((project) => {
      project.forEach(project => {
      });
      this.projectlist = project;
    });
    this.contactintialized = true;
    
  }

  Display(accId, accountname, addr1, addr2, city, state, zip, country, phone, fax, website, Accountiddata,owner) {
    if (city != null) {
      var cityArray = city.split(' ');
      var newCity = '';
      for (var i = 0; i < cityArray.length; i++) {
        if (newCity == '') {
          newCity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
        else {
          newCity = newCity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
      }
    }
    if (website != null && website.indexOf('www.') >= 0) {
      website = ((website).split('www.')[1])
    } else if (website != null && website.indexOf('ww.') >= 0) {
      website = ((website).split('ww.')[1])
    }


    var properstate = (state != null) ? state.charAt(0).toUpperCase() + state.substr(1).toLowerCase() : "";
    this.viewaccount.accountId = accId;
    this.viewaccount.viewaccountname = accountname;
    this.viewaccount.viewaddress1 = addr1;
    this.viewaccount.viewaddress2 = addr2;
    this.viewaccount.viewcity = newCity == null ? "" : newCity;
    this.viewaccount.viewstate = properstate;
    this.viewaccount.viewzip = zip;
    this.viewaccount.viewCountry = country;
    this.viewaccount.viewphone = phone;
    this.viewaccount.viewfax = fax;
    this.viewaccount.viewwebsite = website;
    this.viewaccount.Accountiddata = Accountiddata;
    this.viewaccount.ownerName = owner;
    this.accdetailcmp.loadAccountId(accId);
  }
  handleClick(event) {
     
    var clickedComponent = event.target;
    this.nodeData = clickedComponent[0].text;
    if (this.nodeData == 'Select College') {
      this.collegeyearDrop = false;
    } else if (this.nodeData == 'Select Med School') {
      this.MedSchoolyearDrop = false;
    } else if (this.nodeData == 'Select Residency') {
      this.ResidencyyearDrop = false;
    } else if (this.nodeData == 'Select Fellowship') {
      this.FellowshipyearDrop = false;
    }
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {

      $('#test').val('Contact');
    } else {
      //alert("outside");
      //$('#test').val(1);
    }
  }
  addAccount() {
  }
  Resetcontacts() {
    this.contact.contacttype = null;
    this.contact.suffix = null;
    this.contact.sex = null;
    this.contact.title = null;
    this.contact.speciality = null;
    this.contact.subspeciality = null;
    this.contact.college = 0;
    this.contact.collegegrandyear = null;
    this.contact.medschool = 0;
    this.contact.medschoolgrandyear = null;
    this.contact.residency = 0;
    this.contact.residencygrandyear = null;
    this.contact.fellowship = 0;
    this.contact.fellowshipgrandyear = null;
    this.contact.ownerid = this.currentuser.id;
    this.contact.practiceid = 0;
  }

  CloseNotesDiv(val) {
     
      this.notesshowDialog = false;
      this.close();  
  }

  close() {
    if (this.tab === 'QuickView') {
      this.SixBoxExpand = false;
      this.sendMsgEvent.emit("Collapse");
    }
    this.otherDetailsTable = true;
    this.documentsinfo = false
    this.Proposalinfo = false
    this.notesinfo = false;
    this.activitiesinfo = false;
    this.emailsinfo = false;
    this.showticketsinfo = false;
    this.Projectsinfo = false;
   this.customerService.GetAllNotesList(this.currentuser.id,this.contactid.toString(), "Contact").then((notes) => {
      notes.forEach(notes => {
        var moment = require('moment-timezone');
        var timeDisplay = notes.user + '<br />' + moment.tz(notes.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(notes.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        notes.userfullName = timeDisplay;
        notes.created = this.datePipe.transform(notes.created, "MM-dd-yyyy");
        this.data.push(notes);
      });    
     this.noteslist = notes;

      this.length = this.data.length;
    });
    this.customerService.GetAllDocumentList(this.contactid.toString(), 'Contacts', this.currentYear,'Contacts').then((document) => {
      this.Originaluserdocumentlist = document;
      this.userdocumentlist = document.filter(x => x.DocSavedFrom !== "Proposal");
      this.POdocumentlist = document.filter(x => x.DocSavedFrom === "Proposal");
    });
    this.isDocumentsInfoDivVisible = false;
    this.ScheduleTaskOpen = false;
    this.onTaskSaved('Task Closed');
  }
  expand(val) {
    this.historyName = val;
    var url = window.location.href;
    if (url.indexOf('tickets') > -1) {     
      this.openAccountNotes()
    } else {
      if (this.tab === 'QuickView') {
        //this.sendMsgEvent.emit("Expand");
        //this.SixBoxExpand = true;
        this.quickViewHistoryDialog = true;
        this.QuickViewDialogCss(false);
        this.QuickViewActivitiesDialog = false
        this.QuickViewNotesDialog = true;
        this.QuickViewEmailDialog = false;
        this.QuickViewDocumentsDialog = false;
        this.QuickViewProposalsDialog = false;
        this.QuickViewTicketsDialog = false;
        this.QuickViewOpportunityDialog = false;
        this.QuickViewProjectDialog = false;
        this.isModal = false;
      } else {
        
        this.Stisticboxclose();
        this.otherDetailsTable = false;
        this.Projectsinfo = false;
        this.notesinfo = true;
      }
    
      let objSelf = this;
      $(setTimeout(function () {
        objSelf.noteComp.Notes('Contact Only');
      }, 500));
    }
  }
  loaddropdowns() {
     
    this.customerService.GetAllOwnerList(0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.OwnerList = Owner;

    });

     
    //this.customerService.GetSpecialitytypes(this.currentuser.id).then((accounttype) => {
    //  accounttype.forEach(accounttype => {
    //  });
    //  this.Specialitytypelist = accounttype;
    //});
    this.customerService.GetSpecialityList("Account Specialty", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Specialitytypelist = accounttype;
    });

    this.customerService.GetCollegeList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Collegelist = accounttype;
    });

    this.customerService.GetMedicalSchoolList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.MedicalSchoolList = accounttype;
    });

    this.customerService.GetAllStateList('all').then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.StatesList = accounttype;
      this.stateInfo = accounttype;
    });
    this.customerService.GetSpecialityList("Month", this.currentuser.id).then((appointment) => {
      appointment.forEach(appointment => {
      });
      this.MonthList = appointment;

    });

    this.customerService.GetSpecialityList("Day", this.currentuser.id).then((appointment) => {
      appointment.forEach(appointment => {
      });
      this.DayList = appointment;
    });
    this.customerService.Getcontacttypes(2).then((accounttype) => {
      this.contacttypelist = [];
      accounttype.forEach(accounttype => {
      });
      this.contacttypelist = accounttype;
    });
    this.customerService.GetCollegeList(this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Collegelist = accounttype;
    });
    this.customerService.GetSpecialityList("Suffix", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.SuffixList = accounttype;
    });
    this.customerService.GetSpecialityList("Sex", this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.SexList = accounttype;
    });

    this.getyears()
    {
      var range = [];
      var y = 1949;
      var year = new Date().getFullYear();
      for (var i = 0; y <= year; i++) {
        y = y + 1;
        range.push({
          label: y,
          value: y
        });
      }
      this.Years = range;
      this.MedSchoolYears = range;
      this.ResidencyYears = range;
      this.FellowShipYears = range;
    }
  }
  ReceivelinkNote(msg) {
    this.expanddocuments('WithOut Proposal','DOCUMENTS');
  }
  ngAfterViewInit() {
    
    
  }
  ngOnInit() {
    this.applicationComponent.totalglobalsearchcount = 0;
    var url = window.location.href;
    if (url.indexOf('opportunitiesdashboard') < 0 && url.indexOf('tasklistdashboard') < 0 && url.indexOf('campaigns') < 0 && url.indexOf('month-calender') < 0 && url.indexOf('contactsdashboard') < 0) {
      $(".mainCheckMark").removeClass("activeColor");
      $(".coustomButton").removeClass("mainactiveButton");
      
    } 
     
    this.route.params.subscribe(params => {
      this.contactid = params['contactid'];
      this.contactpermission = params['permission'];
      $('#emailIds').val(this.contactid);
      if (this.tab == 'QuickView' || this.tab == 'Search' ) {
        this.contactid = parseInt(this.contactids.toString().split(',')[0]);
        this.GetContactInfo(this.contactid);
      } 
        $('#accotIdNotes').val(this.contactid.toString());
      $('#accotTypeNotes').val('Contact');
      if (url.indexOf('opportunitiesdashboard') < 0 && url.indexOf('tasklistdashboard') < 0) {
        this.GetContactInfo(this.contactid);
        
      }
    });
    this.showContactInfo = true;
    this.customerService.GetDocumentsCount(this.contactid,'Contact').then(result => {
      this.userdocumentcount = result;
    });

   

    var url = window.location.href;
    if (url.indexOf('campaigns') > -1) {

      this.RightDivHeight = 410;
     }
    //document.getElementById('mergeHGT').style.height = '150px';
    $('#mergeHGT').css('height', '150px');
    //if (url.indexOf('campaigns') > -1) {
    //  //document.getElementById('otherDetails').style.height = "416px";
    //}

    if (url.indexOf('quick-view') > -1) {
      $(".mainCheckMark").removeClass("activeColor");
      $("#customers_span").addClass("activeColor");
      //document.getElementById('detailedView').style.height = '420px';
      
      $('#ContactInformation1').attr('style', 'overflow:hidden;padding-right:0;padding:0;;height:100%');
      this.divHeight1 = screen.height * 0.46222;
      this.divHeight2 = screen.height * 0.1555;
      this.fixHeight = 100;
      this.divHeight3 = 420;
    }
    //else if (url.indexOf('tasklistdashboard') > -1 || url.indexOf('opportunitiesdashboard') > -1) {
    //  this.applicationComponent.showContactsSubTabs = false;
    //}
    //else {
    //  this.applicationComponent.showContactsSubTabs = true;
    //}
    
    //this.applicationComponent.showDashboardSubTabs = false;
  }

  //collegeyear(val) {
  //  var range = [];
  //  var y = Number(val);
  //  var year = new Date().getFullYear();
  //  for (var i = 0; y <= year; i++) {
  //    y = y + 1;
  //    range.push({
  //      label: y,
  //      value: y
  //    });
  //  }
  //  this.MedSchoolYears = range;
  //}
  //medschoolyear(val) {
  //  var range = [];
  //  var y = Number(val);
  //  var year = new Date().getFullYear();
  //  for (var i = 0; y <= year; i++) {
  //    y = y + 1;
  //    range.push({
  //      label: y,
  //      value: y
  //    });
  //    this.ResidencyYears = range;
  //  }

  //  var range = [];
  //  var y = this.contact.collegegrandyear == null ? 1949 : this.contact.collegegrandyear;
  //  var year = this.contact.medschoolgrandyear;
  //  for (var i = 0; y < year - 1; i++) {
  //    y = y + 1;
  //    range.push({
  //      label: y,
  //      value: y
  //    });
     
  //  }
  //  if (this.contact.collegegrandyear == null) { this.Years = range;}
  //}
  //residencyyear(val) {
  //  var range = [];
  //  var y = Number(val);
  //  var year = new Date().getFullYear();
  //  for (var i = 0; y <= year; i++) {
  //    y = y + 1;
  //    range.push({
  //      label: y,
  //      value: y
  //    });
  //  }
  //  this.FellowShipYears = range;

  //  var range = [];
  //  var y = this.contact.medschoolgrandyear == null ? 1949 : this.contact.medschoolgrandyear;
  //  var year = this.contact.residencygrandyear;
  //  for (var i = 0; y < year - 1; i++) {
  //    y = y + 1;
  //    range.push({
  //      label: y,
  //      value: y
  //    });
  //  }
  //  if (this.contact.medschoolgrandyear == null) { this.MedSchoolYears = range; }
  //  if (this.contact.collegegrandyear == null) { this.Years = range; }  

  //}
  //fellowshipyear(val) {
  //  var range = [];
  //  var y = this.contact.residencygrandyear == null ? 1949 : this.contact.residencygrandyear;
  //  var year = this.contact.fellowshipgrandyear;
  //  for (var i = 0; y < year - 1; i++) {
  //    y = y + 1;
  //    range.push({
  //      label: y,
  //      value: y
  //    });
  //  }
  
  //  if (this.contact.residencygrandyear == null) { this.ResidencyYears = range; }
  //  if (this.contact.medschoolgrandyear == null) { this.MedSchoolYears = range; }
  //  if (this.contact.collegegrandyear == null) { this.Years = range; }
  //}

  nextStatistics() {
    var count = this.staticsticsId
    count++;
    if (count <= this.statisticlist.length) {
      document.getElementById('statisticsCount').innerHTML = '(' + count + ' of ' + this.statisticlist.length + ')';
      this.staticsticsId++;
      var id = this.staticsticsId;
      $('.statistic').attr('style', 'display:none');
      $('#' + id).attr('style', 'display:block');
      document.getElementById('previous').style.display = 'block';
      if (count == this.statisticlist.length) {
        document.getElementById('next').style.display = 'none';
      }
    }

  }
  statePopulate(zipid, cityid, stateid, stateDDN) {
    var that = this;
    var zipCode = $('#' + zipid).val();
    var newStr = zipCode.replace(/_/g, "");
    var newStrArray = newStr.split("-");
    if (newStrArray[0].length > 0) {
      var url = "http://ziptasticapi.com/" + newStrArray[0];
      $.ajax({
        url: url,
        dataType: "json",
        type: 'GET',
        success: function (data) {

          $('#' + cityid).val(data.city);
          that.loadstate(data.state, stateid, stateDDN);
          if (zipid == 'contactzip') {
            that.contact.city = data.city;
          }
          if (zipid == 'contacthomeZip') {
            that.contact.homecity = data.city;
          }
          if (zipid == 'zipCode') {
            that.account.city = data.city;

          }
        }

      });
    }
  }

  loadstate(state, stateid, stateDDN) {

    var countryCode = ($('#country').val());
    if (countryCode == 1) {
      for (var i = 0; i < 50; i++) {
        if (this.StatesList[i].itemName.trim().toString() == state) {
          $('#' + stateDDN).val(this.StatesList[i].id)
          this.account.state = state;
        }
      }
    } else {

      $('#' + stateid).val(state);

      if (stateid == 'contactstateTextBox') {
        this.contact.state = state;

      }
      if (stateid == 'contacthomestate') {
        this.contact.homestate = state;

      }
      if (stateid == 'stateTextBox') {
        this.account.state = state;
      }
    }
  }
  previousStatistics() {
    var count = this.staticsticsId
    count--;
    if (count > 0) {
      document.getElementById('statisticsCount').innerHTML = '(' + count + ' of ' + this.statisticlist.length + ')';
      this.staticsticsId--;
      var id = this.staticsticsId;
      $('.statistic').attr('style', 'display:none');
      $('#' + id).attr('style', 'display:block');
      document.getElementById('next').style.display = 'block';
      if (count == 1) {
        document.getElementById('previous').style.display = 'none';
      }
    }
  }
  Notes(val) {
    this.GetCustomerNotes(this.contact.id, val);
  }
  GetCustomerNotes(contactid, type) {
    //this.customerService.GetAllNotesList(this.currentuser.id,contactid, type).then((taskhistory) => {
    //  taskhistory.forEach(items => {
    //    var moment = require('moment-timezone');
    //    var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
    //    items.userfullName = timeDisplay;
    //    items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
    //  });
    //  this.initializeAgGridNotes();
    //  alert("a");
    //  this.allnotes = taskhistory;
    //});
    //this.noteComp.GetCustomerNotes(this.currentuser.id, contactid, type);
  }

  //initializeAgGridNotes() {
     
  //  this.gridOptionsnotes = <GridOptions>{
  //    pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
  //  };
  //  this.createColumnDefsnotes();

  //  this.context = this;
  //  var url = window.location.href;
  //  if (url.indexOf('campaigns') > -1) {
  //    this.divHeight1 = 210;
  //    document.getElementById('detailedView').style.overflow = "auto";
  //  }
  //  //if (url.indexOf('month-calender') > -1) {
  //  //  $(setTimeout(function () {
  //  //    document.getElementById('detailedView').style.fontSize = "11.5px";
  //  //  }, 400));
  //  //}
  //}
  //createColumnDefsnotes() {
  //  let selfObj = this;
  //  this.columnDefsnotes = [
  //    {

  //      field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
  //        if (params.data.isnewnote == 1) {
  //          return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
  //        }
  //        else if (params.value == 'Contact' || params.value == 'Account') {
  //          //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
  //          return '<i class="fa fa-laptop " aria-hidden="true"></i>';
  //        }
  //        else if (params.value != null && params.value.startsWith("Phone")) {
  //          //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
  //          return '<i class="fa fa-phone" aria-hidden="true"></i>';
  //        }
  //        else if (params.value != null && params.value.startsWith("To Do")) {
  //          return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
  //        }
  //        else if (params.value != null && params.value.startsWith("Meeting")) {
  //          //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
  //          return '<i class="fa fa-calendar " aria-hidden="true"></i>';
  //        } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
  //          //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
  //          return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
  //        }
  //        else {
  //          //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
  //          return '<i class="fa fa-usd" aria-hidden="true"></i>';
  //        }

  //      }
  //    },
  //    {
  //      field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', hide: false, cellRenderer(params) {
  //        var eDiv = document.createElement('div');
  //        eDiv.innerHTML = params.value;
  //        eDiv.addEventListener('click', function () {
  //          alert('B');
  //          selfObj.openDisplaynotes(params);
  //        });
  //        return eDiv;
  //      }
  //    },
  //    { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: true },
  //    { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
  //    {
  //      field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: false,
  //      cellRenderer(params) {
  //        if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
  //        else { return params.value; }

  //      }
  //    },
  //    { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
  //    { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
  //    { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

  //  ];
  //  //this.columnDefsnotes = [
  //  //  {
  //  //    field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
  //  //      if (params.value == 'Contact') {
  //  //        return '<i style="font-weight: 700;font-size: 18px;" class="fa fa-laptop" aria - hidden="true" > </i>';
  //  //      }
  //  //      else if (params.value != null && params.value.startsWith("Phone")) {
  //  //        return '<i style="font-weight: 700;font-size: 18px;" class="fa fa-phone" aria - hidden="true" > </i>';
  //  //      }
  //  //      else if (params.value != null && params.value.startsWith("To Do")) {
  //  //        return 'To-Do';
  //  //      }
  //  //      else if (params.value != null && params.value.startsWith("Meeting")) {
  //  //        return '<i style="font-weight: 700;font-size: 18px;" class="fa fa-calendar" aria - hidden="true" > </i>';
  //  //      } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
  //  //        return '<i style="font-weight: 700;font-size: 18px;" class="fa fa-server" aria - hidden="true" > </i>';
  //  //      }
  //  //      else {
  //  //        return '<h5 style="font-weight: 800;font-size: 15px;"> $$ </h5>'
  //  //      }
  //  //    }
  //  //  },
  //  //  {
  //  //    field: 'notedescription', headerName: 'Notes', hide: false, cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellRenderer(params) {
  //  //      var eDiv = document.createElement('div');
  //  //      eDiv.innerHTML = params.value;
  //  //      eDiv.addEventListener('click', function () {
  //  //        //selfObj.notes.notedescription = params.value;
  //  //        //selfObj.NotesDec = !selfObj.NotesDec;
  //  //        selfObj.openDisplaynotes(params);
  //  //      });
  //  //      return eDiv;
  //  //    }},
  //  //  { field: 'contactname', headerName: 'Contact', hide: false },
  //  //  { field: 'created', headerName: 'Date Added', hide: false },
  //  //  { field: 'status', headerName: 'Completed Y/N', hide: false, cellStyle: function (params) { if (params.value == 'Open') { return { color: 'red' }; } } },
  //  //  { field: 'user', headerName: 'User Added', hide: false },
  //  //  { field: 'type', headerName: 'Type', hide: false }

  //  //];
  //  this.getRowHeight = function (params) {
  //    return 18 * (Math.floor(params.data.notedescription.length / 45) + 1);
  //  };
  //}

  //onAgGridReadynotes(event) {
     
  //  let selfObj = this;
  //  $(setTimeout(function () {
  //    selfObj.autoSizeAllnotes();
  //    $(setTimeout(function () {
  //      selfObj.autoSizeAllnotes();
  //    }, 1000));
  //  }, 1000));
  //}
  //autoSizeAllnotes() {
  //  var allColumnIds = [];
  //  if (this.gridOptionsnotes && this.gridOptionsnotes.columnApi) {
  //    var allCols = this.gridOptionsnotes.columnApi.getAllColumns();
  //    if (allCols != null) {
  //      allCols.forEach(function (column) {
  //        allColumnIds.push(column.getColId());
  //      });
  //    }
  //    this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds);
  //    //this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds, "autosizeColumns");
  //  }
  //}

  enterNotes() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'block';
    document.getElementById('saveNotesButtons').style.display = 'block';
    document.getElementById('divTable').style.display = 'none';
  }
  SaveNote() {
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/img, "");
    this.notes.notedescription = this.notes.notedescription.replace(/<[^>]*>/g, '');
    this.notes.type = "Contact";
    this.notes.accountid = this.contact.id;

    this.customerService.SaveNotes(this.notes).then(result => {
      this.GetCustomerNotes(this.notes.accountid, 'Contact');
      $('#notesListTabsId .active').removeClass('active');
      $('#notesListTabsId #allNotesId').addClass('active');

      
      this.CancelNote();
    });
    //document.getElementById('ownerlabel').innerHTML = this.account.owner;
  }
  CancelNote() {
    $('.fr-element').empty();

    document.getElementById('noteid').style.display = 'none';
    document.getElementById('saveNotesButtons').style.display = 'none';
    document.getElementById('divTable').style.display = 'block';
    //document.getElementById('ownerlabel').innerHTML = this.account.owner;
  }
  editcontact(contactId) {
    if (this.contactpermission == "view" || this.account.isAccountPermission=="View") {
      this.applicationComponent.isAccessDenied = true;
    }
    else {

      var url = window.location.href;
      if (url.indexOf('tickets') > -1) {
        this.sendMsgEvent.emit('edit');
      }
      this.action = "EditContact";
      this.showAddEditContact = true;
      this.showAddEditContactCss();
      this.contactid = contactId;
   
    }
  
  }
  cancelContact() {
     
    //document.getElementById('EdittContactDetails').style.display = 'none';
    //document.getElementById('otherDetailsTable').style.display = 'block';
    this.otherDetailsTable = true;
    document.getElementById('saveContact').style.display = 'none';
   
    this.FellowshipyearDrop = true;
    this.MedSchoolyearDrop = true;
    this.ResidencyyearDrop = true;
    this.collegeyearDrop = true;
  }
 
  SaveContact() {
    if (this.contact.isaddressadded) {
      //document.getElementById('EdittContactDetails').style.display = 'none';
      //document.getElementById('otherDetailsTable').style.display = 'block';
      this.otherDetailsTable = true;
      document.getElementById('saveContact').style.display = 'none';
      if (this.tab != 'QuickView') {
        //$('#mainDivleft').attr('style', 'padding:0;width: 50%;');
      }
      $('#detailedView').attr('style', 'padding:0;padding-right: 0%;display: block;height:100% ')
      this.contact.accountid = this.account.id;
      this.contact.isshared = this.account.isshared;
       
      this.accountsService.addcontact(this.contact).then(result => {
        this.customerService.GetContactById(result.id).then(result1 => {
          if (document.getElementById('contactlabel').innerHTML == "EDIT CONTACT") {
            this.displaycontact = result1;    
            this.displaycontact.collegegrandyear = moment(this.displaycontact.collegegrandyear).format("YYYY");
            this.displaycontact.medschoolgrandyear = moment(this.displaycontact.medschoolgrandyear).format("YYYY");
            this.displaycontact.fellowshipgrandyear = moment(this.displaycontact.fellowshipgrandyear).format("YYYY");
            this.displaycontact.residencygrandyear = moment(this.displaycontact.residencygrandyear).format("YYYY");
          } else {
            this.contact = result1;
            this.contact.collegegrandyear = moment(this.contact.collegegrandyear).format("YYYY");
            this.contact.medschoolgrandyear = moment(this.contact.medschoolgrandyear).format("YYYY");
            this.contact.fellowshipgrandyear = moment(this.contact.fellowshipgrandyear).format("YYYY");
            this.contact.residencygrandyear = moment(this.contact.residencygrandyear).format("YYYY");
          }
          this.contactid = result1.id;
          
        });
        this.customerService.GetByAccountId(this.contact.accountid).then(result => {
          this.account = result;
          var city1 = this.account.city;
          var cityArray1 = city1.split(' ');
          var newcity1 = '';
          for (var i = 0; i < cityArray1.length; i++) {
            if (newcity1 == '') {
              newcity1 = cityArray1[i].charAt(0).toUpperCase() + cityArray1[i].substr(1).toLowerCase();
            } else {
              newcity1 = newcity1 + ' ' + cityArray1[i].charAt(0).toUpperCase() + cityArray1[i].substr(1).toLowerCase();
            }
          }
          this.account.city = newcity1;
           
          var state1 = this.account.state;
          var properstate1 = state1.charAt(0).toUpperCase() + state1.substr(1).toLowerCase();
          this.account.state = properstate1;

        });

        this.toastr.success("Contact Updated successfully.", "Contact Saved", { timeOut: 600 });

        this.FellowshipyearDrop = true;
        this.MedSchoolyearDrop = true;
        this.ResidencyyearDrop = true;
        this.collegeyearDrop = true;
      });
    }
    else {
      this.showmapWarning = true;
      this.showmapWarningDialogCss();
    }
  }
  showmapWarningDialogCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width:30%;padding-bottom: 0px;border:4px solid rgb(49,189,240);border-radius:5%;min-height: 30%;overflow: visible;height: 43%;top: 32%;left: 56%;');
    }, 4);
  }

  SaveCustomeAddress() {
    this.contact.isaddressadded = true;
    this.showmapWarning = false;
  }
  getyears() {
    var range = [];
    var y = 1949;
    var year = new Date().getFullYear();
    for (var i = 0; y <= year; i++) {
      y = y + 1;
      range.push({
        label: y,
        value: y
      });
    }
    this.Years = range;
  }


  loadcontacttitles(val) {
    this.customerService.GetTitleListbycontacttype(val).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.TitleList = accounttype;
      this.contact.title = null;
      if (val == 'Physician' || val == 'DPM' || val == 'NP' || val == 'PT' || val == 'PA') {
        $('#title').removeClass('required');
        $('#title').addClass('not-required');
        document.getElementById('titlespan').style.display = 'block';

      } else {
        $("#title").prop("required", false)
        $('#title').removeClass('not-required');
        $('#title').removeClass('required');
        document.getElementById('titlespan').style.display = 'none';

      }
    });

  }
  titleSelected() {
    if ($('#title').hasClass('not-required')) {
      $('#title').removeClass('not-required')
      $('#title').addClass('required')

    }
  }
  IsButtonAccess(btn, type) {
    if (this.contactpermission == "view") {
      this.applicationComponent.isAccessDenied = true;
    } else {
      if ((this.userprevilages == undefined || this.userprevilages.length == 0) && type == "add") {
        this.router.navigate(['/application/account-contact/' + this.contactpermission + '/' + this.account.id]);
      }
      if ((this.userprevilages == undefined || this.userprevilages.length == 0) && type == "edit") {

        this.router.navigate(['/application/account-contact/' + this.contactpermission + '/edit' + this.account.id]);
      }
      for (var j = 0; j < this.userprevilages.length; j++) {
        if (btn == this.userprevilages[j].resource) {
          if (type == "add") {
            if (this.userprevilages[j].canadd == false) {
              this.toastr.warning("You Do Not have Access to this Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
              return;
            }
            else {
              this.router.navigate(['/application/account-contact/' + this.contactpermission + '/show' + this.account.id]);
              //document.getElementById('AccountstatisticsBox').style.display = 'block';
              $('#AccountstatisticsBox').css('display', 'block');

            }

          }

          if (type == "edit") {
            if (this.userprevilages[j].canedit == false) {
              this.toastr.warning("You Do Not have Access to this Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
              return;
            }
            else {
              this.router.navigate(['/application/account-contact/' + this.contactpermission + '/edit' + this.account.id]);
              $('#AccountstatisticsBox').css('display', 'block');
              
            }
          }
        }

      }

      //document.getElementById('subTab').style.display = 'height';
    }  
  }
  contactsSelectState(id, name) {
    $('.selectedState').removeClass('selectedState');
    $('#cont_' + id).addClass('selectedState');
    $('#contactstateTextBox').val(name);
    this.contact.state = name;
  }
  contactsShowStateList() {

    document.getElementById('contactsStateList').style.display = 'block';
  }
  contactsHideStateList() {

    document.getElementById('contactsStateList').style.display = 'none';
  }
  changeCityStateZip(id) {

    var $stateDropdownDiv = $('#contactstateTextBox').parent();
    if (id == 1) {
      $('#citySateZipforContacts').html('City/State/Zip');
      this.getSelectedState('all');
      this.stateTextBox = true;
      this.stateDropDown = false;
      this.displayZipCode = true;
      this.displayCountryName = false;
      $stateDropdownDiv.removeClass();
      $stateDropdownDiv.addClass('col-xs-3 removeCompletePadding');
      $stateDropdownDiv.prop("style", "padding:0px");
      $('#contactstateTextBox').prop("placeholder", "Select State");
      $('#contactCityDiv').removeClass();
      $('#contactCityDiv').addClass('col-xs-6');

      this.countryId == 1;
    }
    else if (id == 2) {
      $('#citySateZipforContacts').html('Island/Country');
      this.getSelectedState('bahamas');
      this.stateTextBox = true;
      this.stateDropDown = false;
      this.displayZipCode = false;
      this.displayCountryName = true;
      $stateDropdownDiv.removeClass();
      $stateDropdownDiv.addClass('col-xs-6 removeCompletePadding');
      $stateDropdownDiv.prop("style", "padding:0px");
      $('#contactstateTextBox').prop("placeholder", "Select Island");
      $('#contactCityDiv').removeClass();
      $('#displayCountry').val('Bahamas');
      this.countryId == 2;
    }
  }
  getSelectedState(country) {
    this.customerService.GetAllStateList(country).then((state) => {
      state.forEach(accounttype => {
      });
      this.StatesList = state;
      this.stateInfo = state;

    });
  }
  QuickViewDialogCss(val) { 
    if (val == true) { //expand
      this.applicationComponent.StandardGreenpopupUi('.dialog');
    } else {
      if (this.Source == "Campaigns") {
        this.applicationComponent.quickViewDialogcss('#quickViewHistoryDialog .dialog');
        //$(setTimeout(function () {
        //  $('#quickViewHistoryDialog .dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(117, 218, 51);min-height: 63%;overflow: auto;height: 89%;top: 10%;left: 3%;margin-right:1%');
        //}, 4));
      } else {
        this.applicationComponent.quickViewDialogcss('.dialog');        
      }
    }
  }

  expandactivities(val) {
    this.historyName = val;
    if (this.contactpermission == "view" || this.account.isAccountPermission == 'View') {
      this.applicationComponent.isAccessDenied = true;
    } else {
      if (this.tab === 'QuickView') {
        //this.sendMsgEvent.emit("Expand");
        //this.SixBoxExpand = true;
        this.quickViewHistoryDialog = true;
        this.QuickViewDialogCss(false);
        this.QuickViewActivitiesDialog = true
        this.QuickViewNotesDialog = false;
        this.QuickViewEmailDialog = false;
        this.QuickViewDocumentsDialog = false;
        this.QuickViewProposalsDialog = false;
        this.QuickViewTicketsDialog = false;
        this.QuickViewOpportunityDialog = false;
        this.QuickViewProjectDialog = false;
      } else {
        this.otherDetailsTable = false;
        this.activitiesinfo = true;
        this.Projectsinfo = false
      }
      this.historyComp.getAllActivities(this.contactid, 'Contacts');
    }
  }
  AddTask() {
    if (this.contactpermission == "view") {
      this.applicationComponent.isAccessDenied = true;
    } else {
      this.ScheduleTaskOpen = true;
      document.getElementById('otherDetails').style.display = 'none';
      document.getElementById('taskDialog').style.display = 'block';
    }
    
    
  }
  onTaskSaved(msg) {
    if (msg != 'Header') {
      this.leadservice.GetAllTaskHistoryByContact(this.contactid, 'Contacts', this.currentuser.timezone).then((activities) => {

        this.activitieslist = activities;
      });
      document.getElementById('taskDialog').style.display = 'none';
      document.getElementById('otherDetails').style.display = 'block';
    }
    else {
      document.getElementById('taskDialog').style.display = 'none';
      document.getElementById('otherDetails').style.display = 'block';
    }
  }
 
  PreviousContact() {
    this.sendMsgEvent.emit("Previous");
  }
  NextContact() {
    this.sendMsgEvent.emit("Next");
  }
  LoadContact(msg) {
    this.showContactInfo = true;
    this.showAccountInfo = false;
    if (msg == 'Previous') { this.PreviousContact(); } else { this.NextContact(); }  
  }

  //openDisplaynotes(params) {
  //   
  //  this.notes.notedescription = params.value;
  //  this.NotesDec = !this.NotesDec;
  //  if (this.currentuser.id == params.data.userid) {
  //    this.showEditNoteBtn = true;
  //    this.editNoteId = params.data.id;
  //  } else
  //    this.showEditNoteBtn = false;

  //  var notesOffset = $("#divNotesContainer").offset();
  //  let notesWidth = $('#divNotesContainer').width();
  //  let notesHeight = $('#divNotesContainer').height();
  //  let windowWidth = $(window).width();
  //  let showDialogWidth = 350;
  //  $(setTimeout(function () {
  //     
  //    $('#showDetailsDialog').width(showDialogWidth);
  //    $('#showDetailsDialog').height('27%');
  //    $('#showDetailsDialog').css('top', '70%');
  //    $('#showDetailsDialog').css('left', '70%');
  //    $('#showDetailsDialog').css('border-radius', '4px');
  //    $('#showDetailsDialog').css('border', '3px solid');
  //    if ($('input[name=Notes]').is(':checked')) {
  //      $('#txtLongNoteDesc').css('height', '81%');
  //      $('#txtLongNoteDesc').css('padding-top', '0%');
  //      $('#txtUserFullName').css('height', '14%');
  //    } else {
  //      $('#txtLongNoteDesc').css('height', '96%');
  //      $('#txtLongNoteDesc').css('padding-top', '6%');
  //    }
  //    $('#showDetailsDialog').show();
  //  }, 1));
  //}

  openDisplaynotes(params) {
    $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 25%;left: 76%;background-color:#ffffb4;border: 1px solid;overflow:auto;padding:0;');
    $('#closeDetailsDialog').click();

    //this.notes.notedescription = params.value;
    this.NotesDec = true;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    var notesOffset = $("#divNotesContainer").offset();
    let notesWidth = $('#divNotesContainer').width();
    let notesHeight = $('#divNotesContainer').height();
    let windowWidth = $(window).width();
    let showDialogWidth = 350;

    let selfObj = this;
    $(setTimeout(function () {
       
      //$('#showDetailsDialog').width(showDialogWidth);
      //$('#showDetailsDialog').height(notesHeight - 140);
      //if ($('#typeClicked').val() == 'modal') {
      if (selfObj.isModal) {
        //$('#showDetailsDialog').css('top', '23%');
        //$('#showDetailsDialog').css('height', '61%');
        //$('#showDetailsDialog').css('left', notesWidth - showDialogWidth);
        //$('#showDetailsDialog').css('top', notesOffset.top + 125);
        //$('#showDetailsDialog').css('left', notesWidth - showDialogWidth);
        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 25%;left: 76%;background-color:#ffffb4;border: 1px solid;overflow:auto;padding:0;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '81%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '14%');
        } else {
          $('#txtLongNoteDesc').css('height', '96%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      } else {
        //var url = window.location.href;
        //if ((url).indexOf('account-contact') > -1) {
        //  $('#showDetailsDialog').css('top', '62%');
        //} else {
        //  $('#showDetailsDialog').css('top', '72%');
        //}
        var gridOffset = $("#notesGrid").offset();
        //$('#showDetailsDialog').css('top', gridOffset.top);
        //$('#showDetailsDialog').css('height', '38%');
        //$('#showDetailsDialog').css('left', '74%');

        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 25%;left: 76%;background-color:#ffffb4;border: 1px solid;overflow:auto;padding:0;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '53%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '33%');
        } else {
          $('#txtLongNoteDesc').css('height', '89%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('overflow', 'auto');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      }
      //$('#showDetailsDialog').css('border-radius', '4px');
      //$('#showDetailsDialog').css('border', '3px solid');
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));
  }

  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();   
  }
  expandcampaigns(val) {
    this.historyName = val;
    this.quickViewHistoryDialog = true;
    this.QuickViewDialogCss(false);
    this.QuickViewActivitiesDialog = false
    this.QuickViewNotesDialog = false;
    this.QuickViewEmailDialog = false;
    this.QuickViewDocumentsDialog = false;
    this.QuickViewProposalsDialog = false;
    this.QuickViewTicketsDialog = false;
    this.QuickViewOpportunityDialog = false;
    this.QuickViewProjectDialog = false;
    this.quickViewCampaignsDialog = true;
    this.customerService.GetSpecialityList("CampaignStatus", this.currentuser.id).then((Rows) => {
      this.StatusDropdownList = Rows;
    })
  }
  CampaignsDropdownChange(status) {
    this.campaincmp.LoadCampaigns(this.currentuser.id, status)
  }
  expanddocuments(val,headerName) {
    this.historyName = headerName;
    if (this.contactpermission == "view" || this.account.isAccountPermission == "View") {
      this.applicationComponent.isAccessDenied = true;
    } else {
      if (this.tab === 'QuickView') {
        
        this.quickViewHistoryDialog = true;
        this.quickViewSMSDialog = false;
        this.quickViewCampaignsDialog = false;

        this.QuickViewDialogCss(false);
        this.QuickViewActivitiesDialog = false
        this.QuickViewNotesDialog = false;
        this.QuickViewEmailDialog = false;
        this.QuickViewTicketsDialog = false;
        this.QuickViewOpportunityDialog = false;
        this.QuickViewProjectDialog = false;
        if (val == 'With Proposal') {
          this.QuickViewDocumentsDialog = false;
          this.QuickViewProposalsDialog = true;
        } else {
          this.QuickViewDocumentsDialog = true;
          this.QuickViewProposalsDialog = false;
        }

        this.loadDocFilterDropDown(val);
      } else {
        $('#setDocumentDetails').val(1);
        this.otherDetailsTable = false;
        this.activitiesinfo = false;
        this.emailsinfo = false;
        this.Projectsinfo = false;

        if (val == 'With Proposal') {
          this.documentsinfo = false;
          this.Proposalinfo = true;
        } else {
          this.documentsinfo = true;
          this.Proposalinfo = false;
        }
        
      }
      this.isDocumentsInfoDivVisible = true;
    }
  }


  loadDocFilterDropDown(val) {
    if (val == 'With Proposal') {

      this.DocumentsFilter = Array.from(new Set(this.POdocumentlist.map(s => s.documentname)))
        .map(contacttype => {
          return {
            Name: ((this.POdocumentlist.filter(x => x.documentname == contacttype)[0].documentname != null) ? this.POdocumentlist.filter(x => x.documentname == contacttype)[0].documentname : '') + " (" + this.POdocumentlist.filter(x => x.documentname == contacttype).length + ")",
            Value: contacttype
          }
        });

    } else {

      this.DocumentsFilter = Array.from(new Set(this.userdocumentlist.map(s => s.documentname)))
        .map(contacttype => {
          return {
            Name: ((this.userdocumentlist.filter(x => x.documentname == contacttype)[0].documentname != null) ? this.userdocumentlist.filter(x => x.documentname == contacttype)[0].documentname : '') + " (" + this.userdocumentlist.filter(x => x.documentname == contacttype).length + ")",
            Value: contacttype
          }
        });

    }


  }


  DocumentsFilterBy(event) {
    this.docMgmtComp.DocumentsFilterBy(event)
  }
  expandsms(val) {
    this.historyName = val;
    this.quickViewHistoryDialog = true;
    this.QuickViewDialogCss(false);
    this.QuickViewActivitiesDialog = false
    this.QuickViewNotesDialog = false;
    this.QuickViewEmailDialog = false;
    this.QuickViewDocumentsDialog = false;
    this.QuickViewProposalsDialog = false;
    this.QuickViewTicketsDialog = false;
    this.QuickViewOpportunityDialog = false;
    this.QuickViewProjectDialog = false;
    this.quickViewSMSDialog = true;
  }
  expandtickets(val) {
    this.historyName = val;
    if (this.account.isAccountPermission == 'View' || this.contactpermission == 'view') {
      this.applicationComponent.isAccessDenied = true;
    }
    else {
      if (this.tab === 'QuickView') {
        this.quickViewHistoryDialog = true;
        this.QuickViewDialogCss(false);
        this.QuickViewActivitiesDialog = false
        this.QuickViewNotesDialog = false;
        this.QuickViewEmailDialog = false;
        this.QuickViewDocumentsDialog = false;
        this.QuickViewProposalsDialog = false;
        this.QuickViewTicketsDialog = true;
        this.QuickViewOpportunityDialog = false;
        this.QuickViewProjectDialog = false;
      } else {
        this.otherDetailsTable = false;
        this.activitiesinfo = false;
        this.emailsinfo = false;
        this.documentsinfo = false;
        this.Proposalinfo = false;
        this.notesinfo = false;
        this.Projectsinfo = false;
        this.showticketsinfo = true;
      }
      this.isTicketsInfoDivVisible = true;

    }
    
  }
  expandemails(val) {
    this.historyName = val;
    if (this.contactpermission == "view" || this.account.isAccountPermission == "View") {
      this.applicationComponent.isAccessDenied = true;
    } else {
      if (this.tab === 'QuickView') {
        this.quickViewHistoryDialog = true;
        this.QuickViewDialogCss(false);
        this.QuickViewActivitiesDialog = false
        this.QuickViewNotesDialog = false;
        this.QuickViewEmailDialog = true;
        this.QuickViewDocumentsDialog = false;
        this.QuickViewProposalsDialog = false;
        this.QuickViewTicketsDialog = false;
        this.QuickViewOpportunityDialog = false;
        this.QuickViewProjectDialog = false;
      } else {
        this.otherDetailsTable = false;
        this.documentsinfo = false;
        this.Proposalinfo = false;
        this.Projectsinfo = false;
        this.emailsinfo = true;
      }
      
    }

  }

  expandOpportunities(val) {
    this.historyName = val;
    if (this.account.isAccountPermission == 'View' || this.contactpermission == 'view') {
      this.applicationComponent.isAccessDenied = true;
    } else {
      if (this.tab === 'QuickView') {
        this.quickViewHistoryDialog = true;
        this.QuickViewDialogCss(false);
        this.QuickViewActivitiesDialog = false
        this.QuickViewNotesDialog = false;
        this.QuickViewEmailDialog = false;
        this.QuickViewDocumentsDialog = false;
        this.QuickViewProposalsDialog = false;
        this.QuickViewTicketsDialog = false;
        this.QuickViewProjectDialog = false;
        this.QuickViewOpportunityDialog = true;
      } else {
        
        this.otherDetailsTable = false;
        this.activitiesinfo = false;
        this.emailsinfo = false;
        this.documentsinfo = false;
        this.Proposalinfo = false;
        this.notesinfo = false;
        this.Projectsinfo = false;
        this.showticketsinfo = true;
      }

      //this.historyComp.getAllActivities(this.contactid, 'Customer');
    }
  }
  expandProjects(val) {
    this.historyName = val;
    if (this.tab === 'QuickView') {
      this.quickViewHistoryDialog = true;
      this.QuickViewDialogCss(false);
      this.QuickViewActivitiesDialog = false
      this.QuickViewNotesDialog = false;
      this.QuickViewEmailDialog = false;
      this.QuickViewDocumentsDialog = false;
      this.QuickViewProposalsDialog = false;
      this.QuickViewTicketsDialog = false;
      this.QuickViewOpportunityDialog = false;
      this.QuickViewProjectDialog = true;
    } else {
      this.otherDetailsTable = false;
      this.activitiesinfo = false;
      this.emailsinfo = false;
      this.documentsinfo = false;
      this.Proposalinfo = false;
      this.notesinfo = false;
      this.showticketsinfo = false;
      this.Projectsinfo = true;
    }

  }


  closeDocumentInsideContact() {
     
    this.documentsinfoBesideContactInfo = false;
    this.photoForConatctView = false;
    this.addEditInsideContact = false;
    $('#contactDiv').removeClass('col-xs-7');
    $('#contactDiv').addClass('col-xs-8');
  }
  onDocumentOpen(myBaseString) {
     
    var viewtype = "ContactView";
    this.ForPhotoInsideContactView = false;
    $('#statisticsBox').attr('style', 'display:none');
    $('#otherDetails').attr('style', 'display:none');
    $('#buttonDiv').attr('style','display:none');
    document.getElementById('pdfDiv').style.display = 'block';


    this.documentsinfoBesideContactInfo = true;
    this.photoForConatctView = true;
    this.addEditInsideContact = true;
    $('#contactDiv').removeClass('col-xs-8');
    $('#contactDiv').addClass('col-xs-7');


    
    $('#pdfDiv').html('<embed src="' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
    $('#pdfDiv').attr('style', 'height: 571px;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;');
//
    setTimeout(function () {
      $('#documentDiv').removeClass('col-xs-4');
      $('#documentDiv').addClass('col-xs-5');
    }, 500);
  }
  saveEditedNote() {
    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.closeDetailsDialog();
        selfObj.GetCustomerNotes(selfObj.contactid, 'Contact');
      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
    //this.Notes(this.selectedTabName);
  }
  openAccountNotes() {
    //this.isModal = true;
    //$('#accotIdNotes').val(this.account.id);
    //$('#accotTypeNotes').val('Customer');
    //this.notesshowDialog = true;
    //this.notesshowDialogCss();
    //let selfobj = this;
    //$(setTimeout(function () {
    //  selfobj.noteComp.Notes('Contact Only');
    //  $('#chkShowDetailsNotes').prop('checked', false); $('#closeDetailsDialog').click();
    //}, 100));
    //$('#checkDiv').css('height', '63%');
    this.isModal = true;
    this.notesshowDialog = true;
    this.notesshowDialogCss();

  }
  notesshowDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }
  addFiles(event) {
    if (this.contactpermission == 'view') {
      this.applicationComponent.isAccessDenied = true;
    } else {
      let files = event.srcElement.files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        this.addChargeNew(file);
      }
    //  $("#addFiles").val("");
    }
  }
 
  addChargeNew(file) {
 
    let contact = new Contacts();
    const reader = new FileReader();
    reader.readAsDataURL(file);
   // this.urlImage = reader.result;
    //reader.onload = (event) => { // called once readAsDataURL is completed
      
    //}
    let selfObj = this;
    $(setTimeout(function () {
      contact.id = selfObj.contactid;
      contact.phototype = file.type;
      contact.photo = reader.result.toString();
      selfObj.urlImage = reader.result.toString();
     // $('#pdfDiv1').html('<button class="closeEmbed" onclick="closeEmbed()" style="float: right;background: white;border: none;" ><i class="fa fa-times-circle-o" aria-hidden="true"></i></button><embed src="' + reader.result + '" id = "pdfFileReader" style="width:100%;height:95%;" />')

     /// selfObj.urlImage = reader.result;
      selfObj.customerService.uploadPhoto(contact).then((user) => {
      }); 
    }, 500));
    //let selfObj = this;
    //$(setTimeout(function () {
    //  selfObj.customerService.uploadPhoto(this.contact.id, reader.result, file.type).then((user) => {
    //  });     
    //  });     
    //}, 500));
   
  }
  sharingAg() {
    this.isSharing = true;
    this.isSharingCss();
    this.accountSharingid = this.account.id;
    this.ownrshipComp.loadAccountInformation(this.account.id, this.contact.id);
  }


  isSharingCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width: 35%;padding-bottom: 0px;border: 1px solid rgb(49,189,240);min-height: 63%;overflow: auto;height: 82.5%;padding: 0;top: 10%;left: 63%;');
    }, 4)

  }
  previousdocument() {
    var viewtype = "ContactView";
    this.documentindex--;
    $('#pdfDiv').html('<embed src="' + this.Originaluserdocumentlist[this.documentindex].imagecontent + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
  }
  nextdocument() {
    var viewtype = "ContactView";
    this.documentindex++;
    $('#pdfDiv').html('<embed src="' + this.Originaluserdocumentlist[this.documentindex].imagecontent + '" id = "pdfFileReader" style="width:100%;height:95%;" />')
  }
  onDocumentSavedSaved(msg) {
    var isnum = /^\d+$/.test(msg);
    if (msg == 'UploadDocument') {
       
      this.uploadDocumentShow = true;
      let objSelf = this;
      $(setTimeout(function () {
        objSelf.uploadDoc.OpenFilemanual();
      }, 100));
    } else if (isnum) {
      this.customerService.GetDocumentsCount(msg, 'Contact').then(result => {
        this.userdocumentcount = result;

      });
    } else if (msg=="close") {
      this.close();
    } else if (msg == "ShowPDF") {
      this.ShowPDF = true;
    } else if (msg == "Hide") {
      this.ShowPDF = false;
    }
    else {
      this.documentview = true;
      this.onDocumentOpen(msg);
    }
  }
  SavedDocuments(msg) {
     
    this.uploadDocumentShow = false;
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.customerService.GetAllDocumentList(msg, 'Contacts', selfObj.currentYear, 'Contacts').then((document) => {
      
        //selfObj.userdocumentlist = document;
        let AccountDocuments: any = document.filter((doc) => (doc != null && doc.type.startsWith('Account')));

        selfObj.accDocCount = AccountDocuments.length;
        let ContactDocuments: any = document.filter((doc) => (doc != null && doc.type.startsWith('Contact')));
        selfObj.conDocCount = ContactDocuments.length;

      });
    }, 500));
    

  }
  public transform(input: string): string {
    //if (!input) {
    //  return '';
    //} else {
    //  return this.titleCasePipe.transform(input);;
    //}
    if (!input) {
      return '';
    }
    else {
      return input[0].toUpperCase() + input.substr(1);
    }
  }
  onCloseEventReceived(msg) {
    this.accountSharingid = 0;
    this.isSharing = false;
    this.GetContactInfo(this.contactid);
  }

  Expandimage() {
    if (document.getElementById('pdfDiv').className == 'col-xs-12') {
      document.getElementById('window').style.display = 'block';
      document.getElementById('pdfDiv').className = 'col-xs-6';
      $('#documentViewId').removeClass('col-xs-12');
      $('#documentViewId').addClass('col-xs-6');
    } else {
      document.getElementById('window').style.display = 'none';
      document.getElementById('pdfDiv').className = 'col-xs-12';
      $('#documentViewId').removeClass('col-xs-6');
      $('#documentViewId').addClass('col-xs-12');
    }
  }
  closeEmbed() {
    this.documentview = false;
    $('#pdfDiv').attr('style', 'display: none;');
    this.closeDocumentInsideContact();
    document.getElementById('window').style.display = 'block';
    document.getElementById('pdfDiv').className = 'col-xs-6';
  }
  OpenTask(contactId, taskid, type) {
    this.id = contactId;
    this.taskid = taskid;
    this.type = type;
    this.isTaskShow = true;
  }
  ReceiveTaskUpdate(msg) {  }
  Taskdialoghideshow(tdialog: boolean) {
    this.isTaskShow = tdialog;
  }
  AddressReceived(msg: Accounts) {   
    this.contact.address1 = msg.address1;
    this.contact.address2 = msg.address2;
    this.contact.city = msg.city;
    this.contact.state = msg.state;
    this.contact.zip = msg.zip;
    this.contact.ProvidenceIsland = msg.ProvidenceIsland;
    this.contact.lattitude = msg.lattitude;
    this.contact.longtitude = msg.longtitude.toString();    
  }
  openAccountIds() {
    this.AccountIdsDialog = true;
    this.AccountIdsDialogCss();
  }

  AccountIdsDialogCss() {
      setTimeout(function () {
        $('.dialog').attr('style', 'width: 20%;padding-bottom:0px;border:2px solid rgb(49,189,240);border-radius:5%;min-height:15%;overflow: auto;height: 18%;left: 0%;right:9%;top: 18%;padding: 0;');
      }, 4)

  }
  //expandDiv() {
  //  var ExpandVal = $('#expandid').html();
  //  if (ExpandVal == "X") {
  //    this.expanded = false;
  //    $('#expandid').html('<>')
  //    $('#window').removeClass('col-xs-12 col-sm-12 col-md-12');
  //    $('#window').addClass('col-xs-12 col-sm-12 col-md-6');
  //    $('#rightInfoDiv').attr('style', 'display:block;padding:0;height:95%');
  //  } else {
  //    this.expanded = true;
  //    $('#window').removeClass('col-xs-12 col-sm-12 col-md-6');
  //    $('#window').addClass('col-xs-12 col-sm-12 col-md-12');
  //    $('#rightInfoDiv').attr('style', 'display:none');
  //    $('#expandid').html('X');

  //  }

  //}

  onCloseDialogEventReceived(msg) {   
    if (msg == 'false') {
      this.notesshowDialog = false;
      this.isModal = false;
    }
  }
  onChange(msg) {
  
    this.contactphotocmp.checkNotes();
   
  }
  OnReceived(msg) {
  
    if (msg == "Open") {
      this.contactphotocmp.crmDialog = true;
      this.crmDialogCss(); }
    else {
      this.ViewPhotoDialog = false;  
    }
  }
  crmDialogCss() {
    $(setTimeout(function () {
      $('#crmDialog .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 42%;left: 24%;max-width: 1393px !important;');
    }, 4));
  }
  CRMSAVE() {
   
  }

  showAddEditContactCss() {
    setTimeout(function () {

      $('#addEditContact .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  ContactReceiveEvent(msg) {
    this.showAddEditContact = false;
    this.GetContactInfo(this.contactid);
    //this.toastr.success("Contact  saved successfully.", "Contact Saved", { timeOut: 600 });
  }
  ReceiveEvent(event) {

    if (event == "ExpandSoftwareLicense" || event == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(event);
    } else {
      this.showAddEditContact = false;
      if (this.contactid != 0) {
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.GetContactInfo(selfObj.contactid);
        }, 100));
      }
    } 
  }

  ExpandAddEditAccountDialog(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 100%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 86%;padding: 0;top: 10%;left: 10%;max-width: 88%;');
      }, 4)
    } else {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
      }, 4)
    }
  }


  AccountsharingAg(selectedval) {

    if (selectedval == 'share') {
      this.sharingAg();
    } else if (selectedval == 'Edit') {
      this.action = selectedval;
    } else {
      this.action = selectedval;
      this.showAddEditContact = true;
      this.showAddEditContactCss();
    }
    
  }
  AccountInfo() {
    var url = window.location.href;
    if (this.tab == 'QuickView') {
      if (url.indexOf('account/Accounts') > -1) {
        //let objSelf = this;
        //$(setTimeout(function () {
        //  objSelf.accComp.AccountData('show' + objSelf.account.id);
        //}, 100));
        this.sendMsgEvent.emit("AccountView");
      } else if (url.indexOf('contacts/contacts') > -1 || url.indexOf('customercontacts') > -1) {
        this.sendMsgEvent.emit("ContactView");
      } else {
        this.sendMsgEvent.emit("AccountView");
      }
    } else {
    
      if ((url.indexOf('tasklistdashboard') > -1 || url.indexOf('month-calender') > -1 || url.indexOf('opportunitiesdashboard') > -1 || url.indexOf('campaigns') > -1 || url.indexOf('quick-view') > -1) == false) {
        if (url.indexOf('tickets') > 1) {
          this.router.navigateByUrl(`/application/account-contact/all/show${this.account.id}`);
        } else if (this.tab == 'QuickView' || this.tab == 'Search') {

          this.showContactInfo = false;
          this.showAccountInfo = true; 
          let objSelf = this;
          $(setTimeout(function () {
            objSelf.accComp.AccountData('show' + objSelf.account.id);
          }, 100));
        } else {
          $(".subButtons").removeClass("activeButton");
          $('#allaccount_button').addClass("activeButton");
          this.router.navigateByUrl(`/application/account-contact/` + this.contactpermission + '/show' + this.account.id);
        }
      }
    }
  }
  btnClickReceived(event) {

    if (event.btnName == 'AccountInfo') {
      this.AccountInfo();
    }
    else if (event.btnName == 'addContact') {
      this.editcontact(0)
    } else if (event.btnName == 'StatusId') {
      this.sendMsgEvent.emit("StatusId");
    }
    else if (event.btnName == 'Accountsharing') {
      this.AccountsharingAg(event.val);
    }
    else if (event.btnName == 'openNotes') {

      this.openAccountNotes();
    } else if (event.btnName == 'FutureTask') {
      this.openFutureTask();
    }
  }
  openFutureTask() {
    this.futureTaskDialog = true;
  }
  ExpandPDF(val) {
    this.notesshowDialog = true;
    this.QuickViewDialogCss(val);
    this.DocExpandView = val;
  }
  openfile(myBaseString) {
    this.ViewPhotoDialog = true;
    this.ViewPhotoDialogCss();
  
  }
  ViewPhotoDialogCss() {
    setTimeout(function () {
      $('#ViewPhotoDialog .dialog').attr('style', 'width: 50%;padding-bottom: 0px;min-height: 25%;overflow: auto;height: 70%;padding: 0;left: 23%;top: 20%;');
    }, 4);
  }
  OpenSocialMedia(val, userName) {
    window.open(val + userName);
  }
  CloseTab(tabval) {
    this.sendMsgEvent.emit(tabval);
  }
  ToggleClick() {
    this.sendMsgEvent.emit("Toggle");
  }
}

