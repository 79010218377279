import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-delete',
  templateUrl: './link-delete.component.html',
  styleUrls: ['./link-delete.component.css']
})
export class LinkDeleteComponent implements OnInit {
  public params: any;
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: any): void {
    this.params = params;
  }

  public deleteRowAg() {
    this.params.context.deleteRowAg(this.params.node.data);
  }

  public linkRowAg() {
    this.params.context.linkRowAg(this.params.node.data);
  }

  public ActiateRowAg() {
    this.params.context.deleteRowAg(this.params.node.data);
  }

  refresh(): boolean {
    return false;
  }
}
