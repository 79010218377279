import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { type } from 'os';
import { SLCategories, SLTypes, SoftwareLicense } from '../../../models/SoftwareLicenseConfiguration';
import { TicketNotes } from '../../../models/TicketNotes';
import { CustomerService } from '../../../services/customer.service';
import { UserService } from '../../../services/user.service';
import { ApplicationComponent } from '../../application.component';
declare var $: any;
@Component({
  selector: 'app-software-licensing',
  templateUrl: './software-licensing.component.html',
  styleUrls: ['./software-licensing.component.css']
})
export class SoftwareLicensingComponent implements OnInit {
  @Input()
  accountid: number;
  all_SL_Types: SLTypes[];
  all_SL_TypesFilter: any[];
  all_SL_TypesOriginal: SLTypes[];
  all_SL_Category: SLCategories[];
  SoftwareLicenses: SoftwareLicense[];
  SoftwareLicense: SoftwareLicense = new SoftwareLicense();
  OriginalSoftwareLicenses: SoftwareLicense[];
  currentuser: any;
  Contacts = [{ id: 0, Name: '', type: '' }];
  LicenseLogins = []; SaasTerms = [];
  datePipe: DatePipe = new DatePipe("en-US");
  public dobmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  ExpandView: Boolean = false;
  @Input()
  Type: string;
  @Output()
  ExpandBox = new EventEmitter<string>();
  DisableSaveButton: boolean = false;
  ViewNotesDialog: boolean = false;
  LicenseNote: string = "";
  NotesIndex: number = 0;
  LicenseNotesDec: boolean = false;
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  TotalLogins = 0;
  public gridOptions: GridOptions;
  public sideBar;
  public context;
  public columnDefs: any[];
  public ContactsSortOrder: boolean = false;
  DeleteConfirmationPopup: boolean = false;
  LicenseNotes: TicketNotes = new TicketNotes();
  LicenseNotesData: TicketNotes[];
  selectedLicenseId = 0; Saasterms = [];
  SoftwarelicenseEdit: boolean = false;
  changeColor = [{ LicenseType: false, AccountContact: false, Date: false, status: false, Logins: false, LicNumber: false, SaaS: false, RDate:false }];
  constructor(private userService: UserService, private customerService: CustomerService, public applicationComponent: ApplicationComponent) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.LicenseLogins = Array(251).fill(1).map((x, i) => i);
  }

  ngOnInit(): void {
    this.getAllLicenseTypes();
    this.initializeAgGrid();

    this.getAllContacsAndAccounts();
    this.SoftwareLicenses = [];
  }
  getAllLicenseTypes() {
    this.userService.GetAll_SL_Categories(this.currentuser.id).then(result => {
      this.all_SL_Category = result.filter(x => x.isdeleted == 0);
      this.userService.GetAll_SL_Types(this.currentuser.id).then(result => {
        this.all_SL_Types = result.filter(x => x.isdeleted == 0);
        this.all_SL_TypesOriginal = result.filter(x => x.isdeleted == 0);

      });
    });
    this.customerService.GetSpecialityList("SaasTerm", this.currentuser.id).then((SaasTerm) => {    
      this.Saasterms = SaasTerm;

    });
  }

  getAllContacsAndAccounts() {
    this.Contacts = [];
    this.customerService.loadaccountdata(this.accountid).then(result => {
      this.Contacts.push({ id: result.id, Name: result.accountname, type: 'Account' });
      this.customerService.GetAllContactproviderByAccount(this.accountid, this.currentuser.id).then((contacts) => {
        contacts.forEach(result1 => {
          this.Contacts.push({ id: result1.id, Name: result1.firstname + " " + result1.lastname + ((result1.titleName)? (' - '+result1.titleName):''), type: 'Contact' });

        });
        this.initializeAgGrid();
        this.GetAllSoftwareLicense();

      });
    });



  }

  GetAllSoftwareLicense() {
    this.userService.GetAllSoftwareLicense(this.currentuser.id, this.Contacts.map(x => x.id).join(",")).then(result => {
      
      result.forEach(ele => {
        ele.Contacts_AccountName = this.Contacts.filter(x => x.id == ele.Contacts_AccountId)[0].Name;
        this.changeColor.push({ LicenseType: false, AccountContact: false, Date: false, status: false, Logins: false, LicNumber: false, SaaS: false, RDate: false })
      });
      this.SoftwareLicenses = result;
     
      this.OriginalSoftwareLicenses = result;
      this.TotalLogins = this.OriginalSoftwareLicenses.filter(x => x.Status == 1).reduce((sum, current) => sum + parseInt(current.Logins.toString()), 0);

      this.all_SL_TypesFilter = [];
      var rew = Array.from(new Set(this.SoftwareLicenses.map(s => s.LicenseTypeId)))
        .map(contacttype => {
          return {
            Name: ((this.SoftwareLicenses.filter(x => x.LicenseTypeId == contacttype)[0].LicenseTypeName != null) ? this.SoftwareLicenses.filter(x => x.LicenseTypeId == contacttype)[0].LicenseTypeName : ''),
            Value: contacttype
          }
        });

      this.all_SL_TypesFilter = rew;
      this.filter(1, 'Status')
    });
  }

  AddNewLicense() {

    this.SoftwareLicenses.push({
      SoftwarelicenseId: 0,
      LicenseTypeId: 0,
      Contacts_AccountId: 0,
      DateLicensed: '',
      Status: 1,
      Logins: 0,
      LicenseNumber: '',
      createdBy: this.currentuser.id,
      createdDate: '',
      isdeleted: 0,
      LicenseTypeName: "",
      Contacts_AccountName: "",
      createdByName: "",
      AccorConType: '',
      LicenseNote: '',
      SaaS: '',
      RDate:'',
      LicenseNotes: null,
      term:''
    });

  }
  SaveLicense() {
    this.SoftwareLicenses = this.SoftwareLicenses.filter(x => x.LicenseTypeId != 0);   
    this.SaveSoftwareLicense(this.SoftwareLicenses);
  }

  SaveSoftwareLicense(SoftwareLicenses) {
    this.userService.AddOrUpdateSoftwareLicense(SoftwareLicenses).then(result => {
      this.initializeAgGrid();
      this.GetAllSoftwareLicense();
      this.DisableSaveButton = false;
    });
  }

  EditLicense(licenseId) {
    $('#LicenseType' + licenseId).attr("Disabled", false);
    $('#AccOrCont' + licenseId).attr("Disabled", false);
    $('#LicenseDate' + licenseId).attr("Disabled", false);
    $('#LicenseStatus' + licenseId).attr("Disabled", false);
    $('#LicenseTerm' + licenseId).attr("Disabled", false);
    $('#LicenseLogins' + licenseId).attr("Disabled", false);
    $('#LicenseNo' + licenseId).attr("Disabled", false);
    $('#SaaS' + licenseId).attr("Disabled", false);
    $('#RDate' + licenseId).attr("Disabled", false);

    $('#LicenseType' + licenseId).attr("style", 'padding:0;height:100%;font-size:12px;background-color:#ffff91;color:black;');
    $('#AccOrCont' + licenseId).attr("style", 'padding:0;height:100%;font-size:12px;background-color:#ffff91;color:black;');
    $('#LicenseDate' + licenseId).attr("style", 'padding:0;font-size:11px;height:100%;background-color:#ffff91;color:black;');
    $('#LicenseStatus' + licenseId).attr("style", 'padding:0;height:100%;font-size:12px;background-color:#ffff91;color:black;');
    $('#LicenseTerm' + licenseId).attr("style", 'padding:0;height:100%;font-size:12px;background-color:#ffff91;color:black;');
    $('#LicenseLogins' + licenseId).attr("style", 'padding:0;height:100%;font-size:12px;background-color:#ffff91;color:black;');
    $('#LicenseNo' + licenseId).attr("style", 'padding:0;height:100%;font-size:12px;background-color:#ffff91;color:black;');
    $('#SaaS' + licenseId).attr("style", 'padding:0;height:100%;font-size:12px;background-color:#ffff91;color:black;');
    $('#RDate' + licenseId).attr("style", 'padding:0;height:100%;font-size:12px;background-color:#ffff91;color:black;');
  
  }
  DeleteLicense(licenseId) {
    var softwarelicense = this.SoftwareLicenses.filter(x => x.SoftwarelicenseId == licenseId);
    softwarelicense.forEach(ele => {
      ele.isdeleted = 1;
    });
    this.SaveSoftwareLicense(softwarelicense);

  }
  filter(value, type) {

    switch (type) {
      case 'LicenseType': {
        if (value != "0") {
          this.SoftwareLicenses = this.OriginalSoftwareLicenses.filter(x => x.LicenseTypeId == value);
        } else {
          this.SoftwareLicenses = this.OriginalSoftwareLicenses;
        }

        break;
      }
      case 'Account Or Contacts': {
        if (value != "0") {
          this.SoftwareLicenses = this.OriginalSoftwareLicenses.filter(x => x.Contacts_AccountId == value);
        } else {
          this.SoftwareLicenses = this.OriginalSoftwareLicenses;
        }
        break;
      }
      case 'Status': {
        if (value != "2") {
          this.SoftwareLicenses = this.OriginalSoftwareLicenses.filter(x => x.Status == value);
        }
        else {
          this.SoftwareLicenses = this.OriginalSoftwareLicenses;
        }
        break;
      }
      default: {
        this.SoftwareLicenses = this.OriginalSoftwareLicenses;
        break;
      }
    }
  }
  Expand(value) {
    this.ExpandView = value;
    this.initializeAgGrid();
    if (value == true) {

      this.ExpandBox.emit('ExpandSoftwareLicense');
    } else {
      this.ExpandBox.emit('CollapseSoftwareLicense');
    }

  }


  ChangeLogins(data) {
    if (data.LicenseTypeId != 0 && data.contact !== 0 && data.DateLicensed != "") {
      this.DisableSaveButton = true;
    }
  }
  changeEvent(event, data) {
    return this.Contacts.filter(x => x.id == event.target.value)[0].type;
  }
  ViewNotesdialog(index, license, type) {
    this.LicenseNotesData = [];
    if (type == 'Add/Edit') {
      this.SoftwareLicense = license;
      this.NotesIndex = index;
      //this.getNotes();
      this.LicenseNotesData = this.SoftwareLicense.LicenseNotes;
      if (this.LicenseNotesData && this.LicenseNotesData.length > 0) {
        this.LicenseNotesData.forEach(ele => {
          ele.Notes = ele.Notes.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/img, "");
          ele.Notes = ele.Notes.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/gi, "");

        });
      }
      this.ViewNotesDialog = true;
      this.ViewNotesDialogCss()

    } else if (type == 'View') {
      this.SoftwareLicense = license;
      this.LicenseNotesDec = true;

      let selfObj = this;
      $(setTimeout(function () {
        $('#showLicenseNotesDialog').attr('style', 'width: 22%;height: 57%;top: 35%;left: -13%;background-color: #ffffb4;border: 1px solid;overflow: auto;padding: 0;');
      }, 50));
    }

  }
  ViewNotesDialogCss() {
    $(setTimeout(function () {
      $('#ViewNotesDialog .dialog').attr('style', 'width: 39%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 36%;overflow: auto;height: 59%;padding: 0;top: 33%;left: 0;right: 31%;');
    }, 4));
  }

  SaveLicenseNotes(value) {
    
    if (value == 'Save' && this.SoftwareLicense.SoftwarelicenseId != 0) {
      if (this.LicenseNote != null && this.LicenseNote != '' && this.LicenseNote != undefined) {
        this.LicenseNotes.NoteId = 0;
        this.LicenseNotes.UserID = this.currentuser.id;
        this.LicenseNotes.TicketId = this.SoftwareLicense.SoftwarelicenseId;
        this.LicenseNotes.NotesType = 'License';
        this.LicenseNotes.Notes = this.LicenseNote;
        this.customerService.addticketNotes(this.LicenseNotes).then(result => {
          this.getNotes();
          this.LicenseNotes = new TicketNotes();
          this.LicenseNote = null;
          this.GetAllSoftwareLicense();
        })
      }
      else {
        alert("Please Enter Notes");
      }

    }
    else if (value == 'Save' && this.SoftwareLicense.SoftwarelicenseId == 0) {
      var myInput = $('#LicenseNote' + this.NotesIndex);
      myInput.val(this.LicenseNote);
      myInput.trigger('input');
      myInput.trigger('change');
      myInput[0].dispatchEvent(new Event("input", { bubbles: true }));
      this.LicenseNotesData = [];
      if (this.LicenseNote != null && this.LicenseNote != '') {
        this.LicenseNotesData.push({ NoteId: 0, Notes: this.LicenseNote, NotesType: 'License', TicketId: 0, CreatedDate: new Date().toString(), UserID: this.currentuser.id, username: '' })
        this.ChangeLogins(this.SoftwareLicense)
        this.LicenseNote = null;
        this.ViewNotesDialog = false;
      } else {
        alert("Please Enter Notes");
      }
      

     

    } else {
      this.ViewNotesDialog = false;
      this.LicenseNotesData = [];
    }
  
  
}
  getNotes() {

    if (this.SoftwareLicense.SoftwarelicenseId !== 0 && this.SoftwareLicense.SoftwarelicenseId !== null) {
      this.customerService.GetTicketNotesByTicketId(this.SoftwareLicense.SoftwarelicenseId, 'License').then(result => {
        this.LicenseNotesData = result;
        
      })
    }
    
  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, columnsMenuTab: false

    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',
    };

    this.context = this;
    this.createColumnDefs();
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: ''
      }
    }
  }

  createColumnDefs() {
    let selfobj = this;
    this.columnDefs = [

      {
        field: 'LicenseTypeName', headerName: 'License Type', hide: false, width: (selfobj.ExpandView) ? 250 : 150, suppressMenu: true, cellStyle: function (params) {
          if (params.data.Status != 1) { return { color: '#FF0000' }; }
        }
      },
      {
        field: 'Contacts_AccountName', headerName: 'Account | Contact', hide: false, width: (selfobj.ExpandView) ? 200 : 120, suppressMenu: true, cellStyle: function (params) {
          if (params.data.Status != 1) { return { color: '#FF0000' }; }
        } },
      {
        field: 'DateLicensed', headerName: 'Lic Date', hide: false, width: 80, suppressMenu: true, cellStyle: function (params) {
          if (params.data.Status != 1) { return { color: '#FF0000' }; }
        }},
      {
        field: 'Status', headerName: 'Status', hide: false, width: 75, suppressMenu: true,  cellStyle: function (params) {
          if (params.data.Status != 1) { return { color: '#FF0000' }; }
        },cellRenderer(params) {
          if (params.data.Status === 1) {
            return "Active";
          } else {
            return "InActive";
          }
        }
      },
      {
        field: 'Logins', headerName: 'Logins', width: 75, hide: false, suppressMenu: true, cellStyle: function (params) {
          if (params.data.Status != 1) { return { color: '#FF0000' }; }
        }
      },
      {
        field: 'SaaS', headerName: 'SaaS', width: 75, hide: false, suppressMenu: true
      },
      {
        field: 'RDate', headerName: 'RDate', width: 75, hide: false, suppressMenu: true
      },
      {
        field: 'LicenseNote', headerName: 'Notes', hide: false, width: 80, suppressMenu: true, cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.data.LicenseNotes == null ||  params.data.LicenseNotes.length <= 0 ) {
            eDiv.innerHTML = '<img src="../../../../assets/img/Notes Icon.png" style="height:22px;" />';
          }
          else if (params.data.LicenseNotes && params.data.LicenseNotes.length > 0) {
            eDiv.innerHTML = '<img src="../../../../assets/img/full-Yellow Note.png" style="height:22px;"/>';
          }

          eDiv.addEventListener('click', function () {
            selfobj.ViewNotesdialog(Event, params.data, 'View')
          });
          return eDiv;
        }
      },
      {
        field: 'LicenseNumber', headerName: 'License#', hide: false, width: 80, suppressMenu: true, cellStyle: function (params) {
          if (params.data.Status != 1) { return { color: '#FF0000' }; }
        } },
    ];
    
  }
  onAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
    }, 400));
  }
  autoSizeAll() {
    if (this.gridOptions && this.gridOptions.columnApi) {
      this.gridOptions.columnApi.autoSizeAllColumns();
    }
  }

  ColumnClick() {
    this.ContactsSortOrder = !this.ContactsSortOrder;
      let Selfobj = this;
    setTimeout(function () {
      if (Selfobj.ContactsSortOrder) {
        Selfobj.SoftwareLicenses = Selfobj.OriginalSoftwareLicenses.sort((a, b) => a.Contacts_AccountName > b.Contacts_AccountName ? 1 : -1);
      } else {
        Selfobj.SoftwareLicenses = Selfobj.OriginalSoftwareLicenses;
      }
      }, 200)
    
  }

  DeleteConfirmationPopupCss(value) {
    this.selectedLicenseId = value;
    this.DeleteConfirmationPopup = true;
    setTimeout(function () {
      $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4);
  }
}
