import { Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { Accounts } from '../../models/accounts';
import { Router, ActivatedRoute } from '@angular/router';
import { Accountsharing } from '../../models/Accountsharing';
import { CustomerService } from './../../services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ApplicationComponent } from '../application.component';
import { Contacts } from 'src/app/models/contacts';
import { Openitems } from 'src/app/models/openitems';
import { createDeflate } from 'zlib';
import { Dropdowntype } from '../../models/dropdowntype';
import { isNullOrUndefined } from 'util';
declare var $: any;
@Component({
  selector: 'ownershipsharing',
  templateUrl: './ownershipsharing.component.html',
  styleUrls: ['./ownershipsharing.component.css']
})
export class OwnershipsharingComponent implements OnInit {
  @Input()
  AccId: number;
  @Input()
  PageType: string;

  account: Accounts = new Accounts();
  accountSharing: Accountsharing = new Accountsharing();
  contact: Contacts = new Contacts();
  userdata: Openitems = new Openitems();  
  currentuser: any;
  selectedItems = [];
  permissionselectedItems = [];
  ViewgroupselectedItems = [];
  PermissionEditItems = [];
  PermissionViewItems = [];
  viewgroupselectedItems = [];
  groupselectedItems = [];
  dropdownSetting = {};
  perEditdropdownSetting = {};
  isChangeOwner: boolean = false;
  SaveButtonShow: boolean = false;
  ChangeOwnerButtonShow: boolean = true;
  SaveEditpermissionShow: boolean = false;
  SaveViewpermissionShow: boolean = false;
  SaveGroupPermission: boolean = false;
  SaveViewGroupPermission: boolean = false;
  finalitems = "";
  isPermissiontoEdit: boolean = false;
  WarningDialog: boolean = false;
  isPermissiontoView: boolean = false;
  isSaveOwner: boolean = false;
  
  
  SelectedOwner: any;
  contactid: number;
  public contactpermission: string;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  expanded: boolean = false;

  // New Changes Starts Here
  public dropdownList: Dropdowntype[] = [];
  accountgroupList = [];
  PermissionEditUserItems = [];
  PermissionEditGroupItems = [];
  PermissionViewUserItems = [];
  PermissionViewGroupItems = [];
  GroupDropdownSetting = {};
  UsersDropdownSetting = {};
  permissionEditaccount: any;
  permissionViewaccount: any;
  viewTaskGroup: any;
  permissionaccountGroups: any;
  accountSharingEditUserlist = [];
  accountSharingEditGrouplist = [];
  accountSharingViewUserlist = [];
  accountSharingViewGrouplist = [];

  constructor(private customerService: CustomerService, private toastr: ToastrService, private route: ActivatedRoute,
    private applicationComponent: ApplicationComponent, private router: Router)
  {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
    
    this.GroupDropdownSetting  = {
      singleSelection: false,
      text: "Select Group",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 0,
      searchPlaceholderText: 'Search Group',

    };
    this.UsersDropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 0,
      searchPlaceholderText: 'Search User',

    };

    this.route.params.subscribe(params => {
      this.contactpermission = params['permission'];      
    });
   
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1,0,0).then((Owner) => {
      Owner.forEach(Onr => {
        Onr.category = "Users";
      });
      this.dropdownList = Owner;
      
      this.customerService.GetAllAccountSharingGroups(this.currentuser.id).then((ShareGroups) => {
        
        var Groups = [];
        ShareGroups.forEach(ele => {
          Groups.push({ "id": ele.SharingGroupId, "itemName": ele.SharingGroupName, "category": 'SharingGroups' })
        });
        this.accountgroupList = Groups;
        this.loadAccountInformation(this.AccId, 0);
      });
    })

  }

  CancelPermissionEditUser() {
    //$('#userGropList').attr('style', 'display:block;height: 80%;overflow: auto;');
    this.isPermissiontoEdit = false;
    this.SaveEditpermissionShow = false;
    this.SaveGroupPermission = false;
  }
  CancelPermissionViewUser() {
    //$('#usrList').attr('style', 'display: block;height: 80%;overflow: auto;');
    this.isPermissiontoView = false;
    this.SaveViewGroupPermission = false;
    this.SaveViewpermissionShow = false;
  }

  editAccount(val) {
    if (this.contactpermission == 'view' || this.account.isAccountPermission == 'View') {
      this.applicationComponent.isAccessDenied = true;
    }
    else {

      if (this.contact != null) {
        this.sendMsgEvent.emit("ContactEdit");
      }
      else {
        this.sendMsgEvent.emit("AccountEdit");
      }
    }
    
  }

  editContact(val) {
  }
  loadAccountInformation(Accountid, contactId) {
    this.AccId = Accountid;
    this.PermissionEditItems = [];
    this.groupselectedItems = [];
    this.PermissionViewItems = [];
    this.viewgroupselectedItems = [];
    this.contact = null;
    if (contactId != 0) {
      this.customerService.GetContactById(contactId).then(Conresult => {
        this.contact = Conresult;
      });
    }
    this.customerService.GetByAccountId(Accountid).then(result => {
      this.account = result;
      if (this.account != null) {
        this.customerService.getAccountPermission(this.account.id, this.currentuser.id).then(perresult => {
          this.account.isAccountPermission = perresult;
          
        });
      }
      this.customerService.GetpermissionGroupByAccount(Accountid).then(permissionlist => {
        this.accountSharingEditUserlist = [];
        this.accountSharingViewUserlist = [];
        this.accountSharingEditGrouplist = [];
        this.accountSharingViewGrouplist = [];
        

        this.accountSharingEditUserlist = permissionlist.filter((cust) => (cust.Type != null && cust.Type.startsWith('EditUser') ));
        this.accountSharingViewUserlist = permissionlist.filter((cust) => (cust.Type != null && (cust.Type.startsWith('ViewUser'))));
        this.accountSharingEditGrouplist = permissionlist.filter((cust) => (cust.Type != null && (cust.Type.startsWith('EditGroup'))));
        this.accountSharingViewGrouplist = permissionlist.filter((cust) => (cust.Type != null && (cust.Type.startsWith('ViewGroup'))));

        this.accountSharingEditUserlist.forEach(ele => {
          this.PermissionEditUserItems.push(this.dropdownList.filter(x => x.id == ele.permissiontoeditaccount)[0])
        });

        this.accountSharingEditGrouplist.forEach(ele => {
          this.PermissionEditGroupItems.push(this.accountgroupList.filter(x => x.id == ele.permissiontoeditaccount)[0])
        });

        this.accountSharingViewUserlist.forEach(ele => {
          this.PermissionViewUserItems.push(this.dropdownList.filter(x => x.id == ele.permissiontoeditaccount)[0])
        });

        this.accountSharingViewGrouplist.forEach(ele => {
          this.PermissionViewGroupItems.push(this.accountgroupList.filter(x => x.id == ele.permissiontoeditaccount)[0])
        });

        
      });      
    });   
  }

  ChangeOwer() {
    if (this.contactpermission == 'view' || this.account.isAccountPermission == 'View') {
      this.applicationComponent.isAccessDenied = true;
    } else if (this.account.ownerid != this.currentuser.id) {
      alert("You are Not the Owner Of this Account!!")
      return false;
    }
    else {
      this.ChangeOwnerButtonShow = false;
      this.SaveButtonShow = false
      this.isChangeOwner = true;
    }
  }
  SaveOwner() {
    this.isSaveOwner = true;
    this.SaveButtonShow = false;

    this.ChangeOwnerButtonShow = false;
    this.SaveButtonShow = false;
    this.isSaveOwner = true;
   
   // document.getElementById('OwnerLabel').innerHTML = "dfdddddddddddddddd";
  }
  cancel() { this.isSaveOwner = false; }
  onItemSelect(item: any) {    
    this.SaveButtonShow = true;    
    this.account.ownerid = item.id;
    this.account.newowner = item.itemName;
    this.SelectedOwner = this.account.newowner;    
  }
  onPerViewItemSelect(item: any) { this.SaveEditpermissionShow = true; }
  onEditPermissionItemSelect(item: any) {
    this.SaveEditpermissionShow = true;
    this.accountSharingEditGrouplist = [];
    this.PermissionEditGroupItems.forEach(ele => {
      this.accountSharingEditGrouplist.push({ 'id': ele.id, "Type": 'Edit User', 'permissiontoeditaccount': ele.id, "permssionuser": ele.itemName})
    });
  }
  ongroupItemSelect(item: any) {
    this.SaveGroupPermission = true;    
  }
  onviewgroupItemSelect(item: any) {
    this.SaveViewGroupPermission = true;
  }
 
  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.SaveButtonShow = true;
    } else {
      this.SaveButtonShow = false;
    }
  }
  OnPerViewItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.SaveViewpermissionShow = true;
    } else {
      this.SaveViewpermissionShow = false;
    }
  }
  OnEditPermissionItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));

      this.loadOpenItems(item.id, this.account.id);

      this.SaveEditpermissionShow = true;
      
    } else {
      this.SaveEditpermissionShow = false;
    }
  }
  OngroupItemDeSelect(item: any) {
     
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));

      this.WarningDialog = true;
      this.SaveGroupPermission = true;
      
    } else {
      this.SaveGroupPermission = false;
    }

  }
  OnviewgroupItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.SaveViewGroupPermission = true;
    } else {
      this.SaveViewGroupPermission = false;
    }
  }

  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    this.SaveButtonShow = true;
    // $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    //this.Generate($("#userids").val());
  }
  onEditPermissionSelectAll(items: any) {
    var ids = "";
    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    this.SaveEditpermissionShow = true;
   
  }
  onPerViewSelectAll(items: any) {
    var ids = "";
    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    this.SaveViewpermissionShow = true; 
  }
  ongroupSelectAll(items: any) {
    var ids = "";
    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    this.SaveGroupPermission = true;
  }

  onviewgroupSelectAll(items: any) {
    var ids = "";
    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    this.SaveViewGroupPermission = true;
  }

  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.SaveButtonShow = false;

    $("#userids").val('');

  }
  onPerViewDeSelectAll(items: any) {
    this.finalitems = "";
    this.SaveViewpermissionShow = true;
    this.PermissionViewItems = [];
    //$("#userids").val('');

  }
  onEditPermissionDeSelectAll(items: any) {

    this.finalitems = "";
    this.PermissionEditItems = [];
    this.SaveEditpermissionShow = true;

    //$("#userids").val('');

  }
 
  ongroupDeSelectAll(items: any) {
    this.finalitems = "";
    this.SaveGroupPermission = false;

    $("#userids").val('');

  }

  onviewgroupDeSelectAll(items: any) {
    this.finalitems = "";
    this.SaveViewGroupPermission = false;

    $("#userids").val('');

  }
  loadOpenItems(userids,accId) {
    this.customerService.GetpopenItemsForUser(userids, accId).then(result => {    
      this.userdata = result;
      this.userdata.userId = userids;
      if (result.OpenTaskTotal != 0 || result.LeadConvertedTotal != 0 || result.OpportunitiesTotal != 0) {
        this.WarningDialog = true;
        this.dialogsize();
      }
    });
  }
  AddEditpermission() {
    if (this.contactpermission == 'view' || this.account.isAccountPermission == 'View') {
      this.applicationComponent.isAccessDenied = true;
    }
    else {


      //$('#userGropList').attr('style', 'display:none');
      this.isPermissiontoEdit = true;
      this.CancelPermissionViewUser();
      this.customerService.GetAccountSharingByAccountId(this.account.id).then(result => {
        this.accountSharing = result;
        
        let selfobj = this;
        $(setTimeout(function () {
          let permissionGroups = [];
          selfobj.customerService.Getselectedusers((selfobj.accountSharing.permissiontoeditaccount != "") ? selfobj.accountSharing.permissiontoeditaccount : null).then((users) => {
            users.forEach(Owner => {
              Owner.category = 'Users';
            });
            selfobj.PermissionEditItems = users;
            selfobj.customerService.GetDropdownNames((selfobj.accountSharing.permissionGroups != "") ? selfobj.accountSharing.permissionGroups : null).then((groups) => {
              groups.forEach(Owner => {
                Owner.category = "Groups";
              });
              selfobj.groupselectedItems = groups;
              selfobj.PermissionEditItems = [...selfobj.PermissionEditItems, ...groups];
              selfobj.accountSharing.permissionGroups = selfobj.groupselectedItems;
            });

            selfobj.accountSharing.permissiontoeditaccount = selfobj.PermissionEditItems;
          });

          

        }, 400));
      });
       
      $(setTimeout(function () {
        //$('.lazyContainer').attr('style', 'max-height:230px !important')
      }, 400));
      //if (this.accountSharing = null) {

      //}
      //else if (this.accountSharing != null) {
      //  let selfobj = this;
      //  $(setTimeout(function () {

      //    selfobj.customerService.Getselectedusers(selfobj.accountSharing.permissiontoeditaccount).then((User) => {
      //      User.forEach(Owner => {
      //      });
      //      selfobj.PermissionEditItems = User;
      //      selfobj.accountSharing.permissiontoeditaccount = selfobj.PermissionEditItems;
      //      selfobj.customerService.GetDropdownNames(selfobj.accountSharing.permissionGroups).then((Groups) => {
      //        Groups.forEach(Owner => {
      //        });
      //        selfobj.groupselectedItems = Groups;
      //        selfobj.accountSharing.permissionGroups = selfobj.groupselectedItems;
      //        selfobj.PermissionEditItems = [...selfobj.groupselectedItems, ...Groups];
      //      });
      //    });

         

      //  }, 400));
      //}
    }
  }
  permissionToViewAccount()
  {
    if (this.contactpermission == 'view' || this.account.isAccountPermission == 'View') {
      this.applicationComponent.isAccessDenied = true;
    }
    else {

      //$('#usrList').attr('style', 'display:none');
      this.isPermissiontoView = true;

      this.customerService.GetAccountSharingByAccountId(this.account.id).then(result => {
        this.accountSharing = result;
        
        let selfobj = this;
        $(setTimeout(function () {
          let permissionGroups = [];
          if (selfobj.accountSharing != null) {
            selfobj.customerService.Getselectedusers((selfobj.accountSharing.permissiontoviewaccount != "") ? selfobj.accountSharing.permissiontoviewaccount : null).then((Owner) => {
              Owner.forEach(Owner => {
                Owner.category = "Users";
              });
              selfobj.permissionselectedItems = Owner;
              selfobj.PermissionViewItems = selfobj.permissionselectedItems;
              if (selfobj.accountSharing.viewpermissionGroups != "") {
                selfobj.customerService.GetDropdownNames(selfobj.accountSharing.viewpermissionGroups).then((viewpergroups) => {
                  viewpergroups.forEach(Owner => {
                    Owner.category = "Groups";
                  });
                  selfobj.viewgroupselectedItems = viewpergroups;
                  selfobj.permissionselectedItems = [...selfobj.permissionselectedItems, ...viewpergroups];
                });
              }
             
            });

           
          }
        }, 400));
      });

      let selfobj = this;
      $(setTimeout(function () {
        
      }, 400));
      this.CancelPermissionEditUser();
    }
  }
  SavePermissionEditUser() {
    this.permissionEditaccount = [];
    this.permissionaccountGroups = [];
    this.permissionViewaccount = [];
    this.viewTaskGroup = [];
    if (this.PermissionEditGroupItems != undefined) {
      this.permissionaccountGroups = this.PermissionEditGroupItems.map(s => s.id);
    }

    if (this.PermissionEditUserItems != undefined) {
      this.permissionEditaccount = this.PermissionEditUserItems.map(s => s.id);
    }

    if (this.PermissionViewGroupItems != undefined) {
      this.viewTaskGroup = this.PermissionViewGroupItems.map(s => s.id);
    }

    if (this.PermissionViewUserItems != undefined) {
      this.permissionViewaccount = this.PermissionViewUserItems.map(s => s.id);
    }


    
    let accSharing: Accountsharing = new Accountsharing();
    accSharing.accountShareId = this.AccId;
    accSharing.permissiontoeditaccount = this.permissionEditaccount.toString();
    accSharing.permissionGroups = this.permissionaccountGroups.toString();
    accSharing.permissiontoviewaccount = this.permissionViewaccount.toString();
    accSharing.viewpermissionGroups = this.viewTaskGroup.toString();
    accSharing.id = (this.accountSharing != null) ? this.accountSharing.id : 0;
    accSharing.createdby = this.currentuser.id;

    this.customerService.addAccuntPermissionEdit(accSharing).then(result => {
      this.accountSharing = result;
      this.toastr.success("Edit Permission and Group Added .", "Share Permission", { timeOut: 600 });
      this.isPermissiontoEdit = false;
      //this.loadAccountInformation(this.AccId, 0);
      this.SaveEditpermissionShow = false;
      this.SaveGroupPermission = false;
      this.sendMsgEvent.emit("Save");
      
    });
  }
  dialogsize() {
    setTimeout(function () {
      $('.dialog').attr('style', ' width: 43% !important; padding - bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min - height: 63%;overflow: visible;height: 63%;');

    }, 4)
  }
  CancelWarning() {
    this.WarningDialog = false;
  }
  Continue(userId) {
    this.customerService.removesharingByAccid(this.AccId, userId).then(result => {
      this.sendMsgEvent.emit("Save");
    });
  }
  SavePermissiontoViewUser() {

    this.permissionViewaccount = [];
    this.viewTaskGroup = [];
    if (this.PermissionViewItems != undefined) {
      for (var i = 0; i < this.PermissionViewItems.length; i++) {
        if (this.PermissionViewItems[i].category == 'Users') {
          this.permissionViewaccount.push(this.PermissionViewItems[i].id);
        } else {
          this.viewTaskGroup.push(this.PermissionViewItems[i].id);
        }
        
      }
    }
    
    //if (this.viewgroupselectedItems != undefined) {
    //  for (var i = 0; i < this.viewgroupselectedItems.length; i++) {
       
    //  }
    //}
    //if (this.accountSharing == null) {
    let accSharing: Accountsharing = new Accountsharing();
    if (this.accountSharing != null) {
      accSharing.id = this.accountSharing.id;
    }
    else {
      accSharing.id = 0;
    }

    accSharing.permissiontoeditaccount = (this.accountSharing != null && this.accountSharing.permissiontoeditaccount != undefined) ? this.accountSharing.permissiontoeditaccount : "";
      accSharing.permissiontoviewaccount = this.permissionViewaccount.toString();
      accSharing.viewpermissionGroups = this.viewTaskGroup.toString();
      accSharing.accountShareId = this.AccId;
    accSharing.createdby = this.currentuser.id;
    //if ((accSharing.permissiontoeditaccount == null || accSharing.permissiontoeditaccount == "") && (accSharing.permissiontoviewaccount == null || accSharing.permissiontoviewaccount == "")) {
    //  this.customerService.removesharingByAccid(this.AccId).then(result => {
    //    this.sendMsgEvent.emit("Save");
    //  });
    //}
      this.customerService.addAccuntPermissionEdit(accSharing).then(result => {
        this.accountSharing = result;
        this.toastr.success("Permission to View.", "Share Permission", { timeOut: 600 });
        //this.loadAccountInformation(this.account.id, 0);
        this.isPermissiontoView = false;
        this.SaveViewpermissionShow = false;
        this.SaveViewGroupPermission = false;

        if ((accSharing.permissiontoeditaccount == null || accSharing.permissiontoeditaccount == "") && (accSharing.permissiontoviewaccount == null || accSharing.permissiontoviewaccount == "")) {
          this.customerService.removesharingByAccid(this.AccId,0).then(result => {
            this.sendMsgEvent.emit("Save");
          });
        }else {
          this.sendMsgEvent.emit("Save");

       }
      
      });
   
  
  }
  ModifyOwner() {
    this.customerService.Updateownerid(this.account.ownerid, this.account.id, this.currentuser.id).then((account) => {
      this.toastr.success("Owner Changed successfully.", "Updated Owner", { timeOut: 600 });
      this.sendMsgEvent.emit("Save");
      this.isSaveOwner = false;
      this.isChangeOwner = false;
      this.ChangeOwnerButtonShow = true;
      this.sendMsgEvent.emit("Save");
    });
  }
  close() {
    this.sendMsgEvent.emit("Close");
    $("#Accountinformation").show();
  }
  onEditGroupItemSelect(item: any) {
    this.SaveEditpermissionShow = true;
    this.accountSharingEditGrouplist = [];
    if (this.PermissionEditGroupItems.length > 0) {
      this.PermissionEditGroupItems.forEach(ele => {
        this.accountSharingEditGrouplist.push({ 'id': ele.id, "Type": 'Edit User', 'permissiontoeditaccount': ele.id, "permssionuser": ele.itemName })
      });
    } else {
      this.accountSharingEditGrouplist = [];
    }
    
  }

  onEditUsersItemSelect(item: any) {
    this.SaveEditpermissionShow = true;
    this.accountSharingEditUserlist = [];
    if (this.PermissionEditUserItems.length > 0) {
      this.PermissionEditUserItems.forEach(ele => {
        this.accountSharingEditUserlist.push({ 'id': ele.id, "Type": 'Edit User', 'permissiontoeditaccount': ele.id, "permssionuser": ele.itemName })
      });
    } else {
      this.accountSharingEditUserlist = [];
    }

  }

  onViewGroupItemSelect(item: any) {
    this.SaveEditpermissionShow = true;
    this.accountSharingViewGrouplist = [];
    if (this.PermissionViewGroupItems.length > 0) {
      this.PermissionViewGroupItems.forEach(ele => {
        this.accountSharingViewGrouplist.push({ 'id': ele.id, "Type": 'Edit User', 'permissiontoeditaccount': ele.id, "permssionuser": ele.itemName })
      });
    } else {
      this.accountSharingViewGrouplist = [];
    }

  }

  onViewUsersItemSelect(item: any) {
    this.SaveEditpermissionShow = true;
    this.accountSharingViewUserlist = [];
    if (this.PermissionViewUserItems.length > 0) {
      this.PermissionViewUserItems.forEach(ele => {
        this.accountSharingViewUserlist.push({ 'id': ele.id, "Type": 'Edit User', 'permissiontoeditaccount': ele.id, "permssionuser": ele.itemName })
      });
    } else {
      this.accountSharingViewUserlist = [];
    }

  }
 
}
