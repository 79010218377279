<div class="col-xs-12" style="padding: 0; height: 100%;">
    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="SLC_CategoriesPage">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Categories</span>
      </h4>
      <div class="col-xs-7" style="padding:0;height:93%">
        <div class="col-xs-12" style="height:7%;padding:0;">
          <div class="col-xs-6" style="text-align:left;padding:0.5% 0 0 0;height:100%">

            <img src="../../../assets/img/Create New Document Name.png" (click)="CreateNewCategory('Categories')" style="height:30px" />

          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:93%;border:1px solid grey">
          <ag-grid-angular style="height:100%" class="ag-theme-balham"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="all_SL_Category"
                           [rowDragManaged]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (rowDragEnd)="onRowDragEnd($event)"
                           (selectionChanged)="selectionChanged($event)"
                           (gridReady)="onAgGridReady($event,'Category')">
          </ag-grid-angular>
        </div>
      </div>

      <div class="col-xs-5" style="padding:0;height:93%">
        <form #AddSLCategoryForm="ngForm" style="height:100%">
          <div class="col-xs-12" style="height:6%;padding:0;">

          </div>
          <div class="col-xs-12" style="height:12%;margin: 2% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="height:100%;padding:0;"><label> Category</label></div>
            <div class="col-xs-9" style="height:100%;padding:0;"><textarea name="SLCategory.LicenseCategoryName" [(ngModel)]="SLCategory.LicenseCategoryName" [required]="createNewCategory" [disabled]="!createNewCategory" style="width:100%;height:100%;"></textarea></div>
          </div>
          <div class="col-xs-12" style="height:6%;margin:1% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="padding:0;height:100%"><label>Sort Order</label></div>
            <div class="col-xs-9" style="padding:0;height:100%"><input type="text" [(ngModel)]="SLCategory.SortOrder" name="SLCategory.SortOrder" [disabled]="!createNewCategory" [required]="createNewCategory" style="width:100%;height:100%" /></div>
          </div>
          <div class="col-xs-12" style="padding:0;height:60%">

          </div>
          <div class="col-xs-12" style="padding:0;margin-top:2%;height:6%">
            <div class="col-xs-7"></div>
            <div class="col-xs-2" style="display:block;padding:0">
              <button class="btn noprovider" style="width:100%;background: lightgray;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="ClearForm('Categories')">Cancel</button>
            </div>
            <div class="col-xs-2 pull-right" style="display:block;padding:0;margin-right:1%">
              <button class="btn noprovider" (click)="SaveSLCategory()" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" [disabled]="(!AddSLCategoryForm.form.valid)">Save</button>
            </div>
          </div>
        </form>

      </div>
    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="SLC_TypesPage">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">License Types</span>
      </h4>
      <div class="col-xs-7" style="padding:0;height:93%">
        <div class="col-xs-12" style="height:7%;padding:0;">
          <div class="col-xs-6" style="text-align:left;padding:0.5% 0 0;height:100%">

            <img src="../../../assets/img/Create New Document Name.png" (click)="CreateNewCategory('LicenseTypes')" style="height:30px" />
          </div>
          <div class="col-xs-6" style="padding:0;text-align:right">
            <div class="col-xs-12" style="height:6%;margin-top:1%;padding: 0;">
              <div class="col-xs-3"></div>
              <div class="col-xs-8" style="height:100%;">
                <select class="form-control" id="licenseCategoryId" (change)="loadSLTypes($event.target.value)">
                  <option value="0">All Categories</option>
                  <option value="{{cat.LicenseCategoryId}}" *ngFor="let cat of all_SL_Category">{{cat.LicenseCategoryName}}</option>
                </select>
              </div>


            </div>

          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:93%;border:1px solid grey">
          <ag-grid-angular style="height:100%" class="ag-theme-balham"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="all_SL_Types"
                           [rowDragManaged]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (rowDragEnd)="onRowDragEndLT($event)"
                           (selectionChanged)="selectionChanged($event)"
                           (gridReady)="onAgGridReady($event,'License Type')">
          </ag-grid-angular>

        </div>
      </div>

      <div class="col-xs-5" style="padding:0;height:93%">
        <form #AddSLTypeForm="ngForm" style="height:100%">
          <div class="col-xs-12" style="height:12%;margin-top:2%;padding: 0;">
            <div class="col-xs-2" style="text-align:right;height:100%;padding: 0;">
              License Type
            </div>
            <div class="col-xs-9" style="height:100%;width: 79%;padding: 0 0 0 3%;">
              <textarea class="form-control" style="width:100%;height:100%;" name="SLType.LicenseTypeName" [(ngModel)]="SLType.LicenseTypeName" [disabled]="!createNewLicenseType" [required]="createNewLicenseType"></textarea>
            </div>
          </div>
          <div class="col-xs-12" style="height:6%;margin-top:1%;padding: 0;">
            <div class="col-xs-2" style="padding:0;text-align:right;height:100%">Category</div>
            <div class="col-xs-9" style="height:100%;padding: 0 0 0 3%;width: 79%;">
              <select class="form-control" [required]="createNewLicenseType" name="SLType.LicenseCategoryId" [disabled]="!createNewLicenseType" [(ngModel)]="SLType.LicenseCategoryId">
                <option value="undefined" selected disabled>Please Select Category</option>
                <option value="{{cat.LicenseCategoryId}}" *ngFor="let cat of all_SL_Category">{{cat.LicenseCategoryName}}</option>
              </select>
            </div>
          </div>

          <div class="col-xs-12" style="height:6%;margin-top:1%;padding: 0;">
            <div class="col-xs-2" style="padding:0;text-align:right;height:100%">Sort Order</div>
            <div class="col-xs-9" style="height:100%;padding: 0 0 0 3%;width: 79%;">
              <input class="form-control" type="text" [required]="createNewLicenseType" [disabled]="!createNewLicenseType" name="SLType.SortOrder" [(ngModel)]="SLType.SortOrder" />
            </div>
          </div>


          <div class="col-xs-4" style="padding: 0; height: 10%; float: right; margin-right: 2%;">
            <div class="col-xs-12" style="height: 80%; margin-top: 6%; padding: 0; float: right;">

              <div class="col-xs-5" style="display:block;padding:0">
                <button class="btn noprovider" style="width:100%;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="ClearForm('LicenseTypes')">Cancel</button>
              </div>
              <div class="col-xs-5 pull-right" style="display:block;padding:0;margin-right:1%">
                <button class="btn noprovider" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" [disabled]="(!AddSLTypeForm.form.valid)" (click)="SaveSLType()">Save</button>
              </div>
            </div>
          </div>

        </form>
      </div>

    </div>

  </div>
