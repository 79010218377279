import { ElementRef, Component, OnInit, Input, NgZone, ViewChild, Output, EventEmitter } from '@angular/core';
import { AccountsService } from '../../services/accounts.service';
import { GoogleMap } from '../../models/google-map';
import { GoogleMapAdress } from '../../models/google-map-adress';
import { FormControl } from '@angular/forms';
declare var $: any;

import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Googlelocations } from '../../models/googlelocations';
@Component({
  selector: 'addressmap',
  templateUrl: './addressmap.component.html',
  styleUrls: ['./addressmap.component.css']
})
export class AddressmapComponent implements OnInit {
  public latitude: number;
  public longitude: number;
  public searchControl: FormControl;
  public zoom: number;
  public address: string;
  @Input()
  AccConIds: string;
  @Input()
  accesstype: string;
  @Output()
  outPropsEvent = new EventEmitter<string>();
  lat = 37.090240;
  lng = -95.712891;
  markers: GoogleMap[];
  Name: string;
  selectedPlaceId: string;
  markersAddress: GoogleMapAdress[] = []; 
  selectedMarker; 
  @ViewChild("search")
  public searchElementRef: ElementRef;
  private geoCoder;

  constructor(private accountService: AccountsService) { }

  ngOnInit() {
    this.outPropsEvent.emit(this.AccConIds);
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.GetLocations(selfObj.AccConIds, selfObj.accesstype);
    }, 100));
  
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 5;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }


  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

 
  getAddress(latitude, longitude) {
    
    this.geoCoder = new google.maps.Geocoder;
    this.geoCoder.geocode({ 'location': { lat: parseFloat(latitude), lng: parseFloat(longitude) } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          let maraddress = new GoogleMapAdress();
          maraddress.Name = results[0].formatted_address.split(',')[0];
          maraddress.placeid = results[0].place_id;
          maraddress.formatted_address = results[0].formatted_address;
          this.markersAddress.push(maraddress);
        } else {
          window.alert('No results found');
        }
      } else {
      }

    });
  }
  addMarker(lat: number, lng: number) {
    this.markers.push({ lat, lng, alpha: 0.4, formatted_address: '' });
  }

  max(coordType: 'lat' | 'lng'): number {
    return Math.max(...this.markers.map(marker => marker[coordType]));
  }

  min(coordType: 'lat' | 'lng'): number {
    return Math.min(...this.markers.map(marker => marker[coordType]));
  }

  selectMarker(event) {   
    this.lat = event.latitude;
    this.lng = event.longitude;   
    this.zoom = 6;
  }
 
  onMouseOver(infoWindow, event: MouseEvent, lat, lng) {
    this.geoCoder.geocode({ 'location': { lat: parseFloat(lat), lng: parseFloat(lng) } }, (results, status) => {
      this.Name = results[0].formatted_address.split(',')[0];
      this.selectedPlaceId = results[0].place_id;
    });
    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
  }
  GetLocations(AccConIds, Usertype) {   
    this.markers = [];
   
    var googleloc = new Googlelocations();
    googleloc.accountids = AccConIds;
    googleloc.Usertype = Usertype;
    this.accountService.GetAlllocations(googleloc).then((locations) => {
      this.markersAddress = [];
     
      this.markers = locations;
      $(setTimeout(function () {
        $("#map").trigger("click");
        this.lat = locations[0].lat;
        this.lng = locations[0].lng;
        this.zoom = 12;
      }, 500));
    });

  }
}
