<div class="col-xs-12" style="padding:0;height:100%" *ngIf="type=='Tickets'">
  <ag-grid-angular style="width: 100%; padding: 0;height:100%" id="TicketsGrid" class="ag-theme-balham"
                   [gridOptions]="ticketgridOptions"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="Ticketslist"
                   [context]="context"
                   [sideBar]="sideBar">
  </ag-grid-angular>
</div>
<div class="col-xs-12" style="padding:0;height:100%" *ngIf="type=='Customer'">
  <ag-grid-angular style="height:100%;" class="ag-theme-balham"
                   [gridOptions]="gridOptions"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="allCustomers"
                   (selectionChanged)="onSelectionChanged($event)"
                   (cellDoubleClicked)="cellDoubleClicked($event)"
                   [context]="context"
                   [sideBar]="sideBar">
  </ag-grid-angular>
</div>
<div class="col-xs-12" style="padding:0;height:100%" *ngIf="type=='CustomerAccounts'">
  <ag-grid-angular style="height:100%;" class="ag-theme-balham"
                   [gridOptions]="gridOptions"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="allCustomers"
                   (selectionChanged)="onSelectionChanged($event)"
                   (cellDoubleClicked)="cellDoubleClicked($event)"
                   [context]="context"
                   [sideBar]="sideBar">
  </ag-grid-angular>
</div>
<div class="col-xs-12" style="padding:0;height:100%" *ngIf="type=='CustomerOnHold'">
  <ag-grid-angular style="height:100%;" class="ag-theme-balham"
                   [gridOptions]="gridOptions"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="allOnHoldCustomers"
                   (selectionChanged)="onSelectionChanged($event)"
                   (cellDoubleClicked)="cellCustomerOnholdDoubleClicked($event)"
                   [context]="context"
                   [sideBar]="sideBar">
  </ag-grid-angular>
</div>


<div class="col-xs-12" style="padding:0;height:100%" *ngIf="type == 'ZendeskTicketsOpen'">
  <ag-grid-angular style="width: 100%; padding-top: 10px;padding: 0;height:97%" id="gridheight" class="ag-theme-balham"
                   [gridOptions]="ZenDeskTicketGridOptions"
                   [columnDefs]="ZendeskColumnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="OpenTicketsUserlist"
                   (cellDoubleClicked)="TicketcellDoubleClicked($event,'Open')"
                   [context]="context"
                   [sideBar]="sideBar">
  </ag-grid-angular>
</div>
<div class="col-xs-12" style="padding:0;height:100%" *ngIf="type == 'ZendeskTicketsClosed'">
  <ag-grid-angular style="width: 100%; padding-top: 10px;padding: 0;height:99%" id="gridheightClosed" class="ag-theme-balham"
                   [gridOptions]="ZenDeskTicketGridOptions"
                   [columnDefs]="ZendeskColumnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="ClosedTicketsUserlist"
                   (cellDoubleClicked)="TicketcellDoubleClicked($event,'Closed')"
                   [context]="context"
                   [sideBar]="sideBar">
  </ag-grid-angular>


</div>

<div class="col-xs-12" style="padding:0;height:100%" *ngIf="type == 'ZendeskTicketsSupport'">
  <ag-grid-angular style="width: 100%; padding-top: 10px;padding: 0;height:99%" id="gridheightClosed" class="ag-theme-balham"
                   [gridOptions]="ZenDeskTicketGridOptions"
                   [columnDefs]="ZendeskColumnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="ClosedTicketsUserlist"
                   [context]="context"
                   [sideBar]="sideBar">
  </ag-grid-angular>


</div>

<div class="col-xs-12" style="padding:0;height:100%" *ngIf="type == 'ZendeskTicketsTrainer'">
  <ag-grid-angular style="width: 100%; padding-top: 10px;padding: 0;height:99%" id="gridheightClosed" class="ag-theme-balham"
                   [gridOptions]="ZenDeskTicketGridOptions"
                   [columnDefs]="ZendeskColumnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="ClosedTicketsUserlist"
                   [context]="context"
                   [sideBar]="sideBar">
  </ag-grid-angular>
</div>
