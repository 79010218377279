import { Component, Input, OnInit } from '@angular/core';
import { Dropdowntype } from 'src/app/models/dropdowntype';
import { TicketConfiguration } from 'src/app/models/ticketConfiguration';
import { Users } from 'src/app/models/users';
import { UserService } from 'src/app/services/user.service';
import { AuditLogModel } from '../../models/auditlog';
import { ApplicationComponent } from '../application.component';
declare var $: any;

@Component({
  selector: 'app-ticket-configuration',
  templateUrl: './ticket-configuration.component.html',
  styleUrls: ['./ticket-configuration.component.css']
})
export class TicketConfigurationComponent implements OnInit {
  public salesxcrmbtn = 0;
  public userBtn = 0;
  public taskGroupBtn = 0;
  public zendeskBtn = 0;

  ticketConfigurationChange = false;
  ticketUserListChange = false;
  ticketTaskGroupChange = false;
  tasksSelected: any[] = [];
  usersSelected: any[] = [];
  currentuser: any;
  taskgrouplist: Dropdowntype[] = [];
  tickConfig: TicketConfiguration = new TicketConfiguration();
  tickConfigList: any[];

  userList: Array<Users> = [];

  user: Users = new Users();
  TicketConfigurationHomePage: boolean = true;
  TicketConfigurationPage: boolean = true;
  ZendeskActivationPage: boolean = false;
  @Input() SubMenuType: string = null;
  constructor(private userservice: UserService, private applicationComponent: ApplicationComponent) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.GetTicketConfigSettings();

    this.AccessDiv("SalesXCRM Ticket");
    
  }
  AccessDiv(val) {
    if (val == 'SalesXCRM Ticket') {
      this.TicketConfigurationHomePage = false;
      this.TicketConfigurationPage = true;
      this.ZendeskActivationPage = false
    }
    else if (val == 'Zendesk Integration') {
      this.TicketConfigurationHomePage = false;
      this.TicketConfigurationPage = false;
      this.ZendeskActivationPage = true
    }

    var obj: AuditLogModel = { id: 0, type: 'Ticket Configuration', action: 'Ticket Config - ' + val, AccountOrContactId: 0, AccountOrContact: val, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Ticket Config - ' + val, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }


  BtnClick(val) {
    switch (val) {
      case 'EnableSalesXcrm': {
        this.salesxcrmbtn = 1;
        this.getUser('460,461,462');
        this.getTaskGroups('0,1', null);
        this.GetTicketConfigSettings();
        break;
      };
      case 'DisableSalesXcrm': {
        this.salesxcrmbtn = 0;
        this.taskGroupBtn = 0;
        this.userBtn = 0;
        break
      };
      case 'ActiveUser': {
        if (this.salesxcrmbtn == 1) {
          this.userBtn = 1;
          this.getUser('460');
        }
        break;
      };
      case 'AllUser': {
        if (this.salesxcrmbtn == 1) {
          this.userBtn = 0;
          this.getUser('460,461,462');
        }
        break;
      };
      case 'ActiveTaskGroup': {
        if (this.salesxcrmbtn == 1) {
          this.taskGroupBtn = 1;
          this.getTaskGroups('0', null);
        }
        break;
      };
      case 'AllTaskGroup': {
        if (this.salesxcrmbtn == 1) {
          this.taskGroupBtn = 0;
          this.getTaskGroups('0,1', null);
        }
        break;
      }
    }

    this.ticketConfigurationChange = true;
  }


  getTaskGroups(status,val) {
     
    this.taskgrouplist = [];
    this.userservice.GetTaskgrouplist(status).then((taskgroups) => {
      this.taskgrouplist = taskgroups;
      this.GetTicketTaskGroups();
    });
  }

  getUser(UserStatusID) {
    this.userList = [];
    this.userservice.GetUsers(UserStatusID).then((user) => {
      this.userList = user;
      this.GetTicketUserLists();
    });
   
  }
  SaveTicket(val) {
    if (val == 'TicketSettings') {
      if (this.tickConfigList && this.tickConfigList[0].id > 0) {
        this.tickConfig.id = this.tickConfigList[0].id;
      }
      this.tickConfig.SalesXcrm = this.salesxcrmbtn;
      this.tickConfig.ActiveTask = this.taskGroupBtn;
      this.tickConfig.ActiveUser = this.userBtn;
      this.tickConfig.ActivateZendesk = this.zendeskBtn;
      this.tickConfig.UserId = this.currentuser.id;
      this.SaveTicketConfiguration();
    }
    else if (val == 'TaskGroupsSave') {
      //var tasksSelected = '';
      //this.tasksSelected.forEach(ele => {
      //  tasksSelected += ele +",";
      //});

      if (this.tickConfigList && this.tickConfigList[0].id > 0) {
        this.tickConfig.id = this.tickConfigList[0].id;
      }
      this.tickConfig.SalesXcrm = this.tickConfigList[0].SalesXcrm;
      this.tickConfig.ActiveTask = this.tickConfigList[0].ActiveTask;
      this.tickConfig.ActiveUser = this.tickConfigList[0].ActiveUser;
      this.tickConfig.ActivateZendesk = this.tickConfigList[0].ActivateZendesk;

      this.tickConfig.SelectedTaskGroupID = this.tasksSelected.join(",");
      if (this.tickConfigList) {
        this.tickConfig.SelectedUserID = this.tickConfigList[0].SelectedUserID;
      }
      this.tickConfig.UserId = this.currentuser.id;
      this.SaveTicketTaskGroups();
    }
    else if (val == 'UserSave') {

      //var usersSelected = '';
      //this.usersSelected.forEach(ele => {
      //  usersSelected += ele + ",";
      //});

      if (this.tickConfigList && this.tickConfigList[0].id > 0) {
        this.tickConfig.id = this.tickConfigList[0].id;
      }
      this.tickConfig.SalesXcrm = this.tickConfigList[0].SalesXcrm;
      this.tickConfig.ActiveTask = this.tickConfigList[0].ActiveTask;
      this.tickConfig.ActiveUser = this.tickConfigList[0].ActiveUser;
      this.tickConfig.ActivateZendesk = this.tickConfigList[0].ActivateZendesk;
      if (this.tickConfigList) {
        this.tickConfig.SelectedTaskGroupID = this.tickConfigList[0].SelectedTaskGroupID;
      }
      this.tickConfig.SelectedUserID = this.usersSelected.join(",");
      this.tickConfig.UserId = this.currentuser.id;
      this.SaveTicketUserList();
    }
    
    
  }

  SaveTicketConfiguration() {
    this.userservice.SaveTicketConfigSettings(this.tickConfig).then((taskgroup) => {
      this.tickConfig = taskgroup;
      this.GetTicketConfigSettings();
      this.ticketConfigurationChange = false;
    });
  }

  SaveTicketTaskGroups() {
    this.userservice.SaveTicketTaskGroups(this.tickConfig).then((taskgroup) => {
      this.tickConfig = taskgroup;
      this.GetTicketConfigSettings();
      this.ticketTaskGroupChange = false;
    });
  }
  SaveTicketUserList() {
    this.userservice.SaveTicketUserList(this.tickConfig).then((taskgroup) => {
      this.tickConfig = taskgroup;
      this.GetTicketConfigSettings();
      this.ticketUserListChange = false;
    });
  }

  GetTicketConfigSettings() {
    this.userservice.GetTicketConfigSettings(this.currentuser.id).then((taskgroup) => {
       
      this.tickConfigList = taskgroup;
      this.salesxcrmbtn = this.tickConfigList[0].SalesXcrm;
      this.taskGroupBtn = this.tickConfigList[0].ActiveTask;
      this.userBtn = this.tickConfigList[0].ActiveUser;
      this.zendeskBtn = this.tickConfigList[0].ActivateZendesk;
      (this.taskGroupBtn == 0) ? this.getTaskGroups('0,1', this.tickConfigList[0]) : this.getTaskGroups('0', this.tickConfigList[0]);
      (this.userBtn == 0) ? this.getUser('460,461,462') : this.getUser('460');
      if (this.tickConfigList[0].SelectedTaskGroupID != null) {
        var tskSelected = this.tickConfigList[0].SelectedTaskGroupID;
        this.tasksSelected = tskSelected.split(",");
        var self = this;
        $(setTimeout(function () {
          self.tasksSelected.forEach(id => {
            $("#TG" + id).prop('checked', true);
          });
          
        }, 100));
      }
      if (this.tickConfigList[0].SelectedUserID != null) {
        var usrSelected = this.tickConfigList[0].SelectedUserID;
        this.usersSelected = usrSelected.split(",");
        var sel = this;
        $(setTimeout(function () {
          sel.usersSelected.forEach(id => {
            $("#User" + id).prop('checked', true);
          });
        }, 200));
      }

      
      
    });
  }


  GetTicketUserLists() {
     
    this.userservice.GetTicketUserLists(this.currentuser.id).then((taskgroup) => {
      this.tickConfigList = taskgroup;
      if (this.tickConfigList && this.tickConfigList[0].SelectedUserID != null) {
        var usersSelected = this.tickConfigList[0].SelectedUserID;
        this.usersSelected = usersSelected.split(",");
        var selfObj = this;

        $(setTimeout(function () {
          selfObj.usersSelected.forEach(id => {
            $("#User" + id).prop('checked', true);
          });
        }, 200));
      }
    });
    
  }
  GetTicketTaskGroups() {
     
    this.userservice.GetTicketTaskGroups(this.currentuser.id).then((taskgroup) => {
      this.tickConfigList = taskgroup;

      if (this.tickConfigList[0].SelectedTaskGroupID != null) {
        var tasksSelected = this.tickConfigList[0].SelectedTaskGroupID;
        this.tasksSelected = tasksSelected.split(",");
        var selfObj = this;
        $(setTimeout(function () {
          selfObj.tasksSelected.forEach(id => {
            $("#TG" + id).prop('checked', true);
          });

        }, 100));
      }
    });
  }
  
  selectedTasks(val) {
     
    if ($('#TG' + val).is(":checked")) {
      this.tasksSelected.push(val)
    } else {
      this.tasksSelected = this.tasksSelected.filter(p => p != val);
    }
    this.ticketTaskGroupChange = true;
  }
  selectedUsers(val) {
     
    if ($('#User' + val).is(":checked")) {
      this.usersSelected.push(val)
    } else {
      this.usersSelected = this.usersSelected.filter(p => p != val);
    }
    this.ticketUserListChange = true;
  }

  Cancelticket(Cancelbtn) {
     
    if (Cancelbtn == 'TicketSettings') {
      this.GetTicketConfigSettings();
      this.ticketConfigurationChange = false;
    } else if (Cancelbtn == 'TaskGroupsCancel') {
      this.getTaskGroups('0,1', null);
      this.ticketTaskGroupChange = false;
    } else if (Cancelbtn == 'UserCancel') {
      this.getUser('460,461,462');
      this.ticketUserListChange = false;
    }

  }

  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
    

  }

}
