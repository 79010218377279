<div class="col-md-12 col-sm-6 col-xs-12" style="padding-right: 0px; height:100%;padding:0">
  <table class="table table-striped" style="margin-bottom:0;height:10%">
    <thead style="background: lightgray;">
      <tr>
        <th style="width:75%;color: green;font-size: 16px;">Roles</th>
        <th style="padding: 0 !important;width: 25%;vertical-align: middle;">
          <div class="col-xs-6" style="padding: 0px;  margin-top:-3px">
            <button class="btn btn-long btn-default" style="width: 100%;background-color:#808080; color:white" (click)="SortOrder()">Sort A-Z</button>
          </div>
          <div class="col-xs-2" style="padding: 0px; padding-left:3px !important; margin-top:-3px;width:20%;margin-left:20%">
            <button class="btn btn-long btn-default" id="AddNew" style="width: 100%;background-color:white; color:black;padding:0;font-size:23px;font-weight:900;" (click)="AddNewRole()">+</button>
          </div>
        </th>
      </tr>
    </thead>
  </table>
  <div class="col-xs-12" style="padding-left:1px;padding-right:1px; margin-top:2px;" [hidden]="RoleTextSave" [style.height.%]="AddRoleButtons==false? 15: 7">
    <input class="form-control placeholder-red text-red" id="RoleTextBox" placeholder="Role Name here..." (keyup)="KeyUp()" >
    <div class="col-xs-12 removeCompletePadding" style="padding: 0;" [hidden]="AddRoleButtons">
      <div class="col-xs-6" style="padding: 5px;">
        <button class="btn btn-long btn-default" (click)="CancelTaskGroup()" style="width: 100%;">Cancel</button>
      </div>
      <div class="col-xs-6" style="padding: 5px;">
        <button class="btn btn-long changeActive noprovider selectedType" (click)="SaveRole()" style="width: 100%;background-color: #92D14F;">Save</button>
      </div>
    </div>
  </div>

  <div style="width: 100%;overflow: auto; border: 1px solid #a6a4a4;" [style.height.%]="RoleTextSave != false? 92: AddRoleButtons == false ? 76: 84">
    <table id="RolesTable" class='table table-striped sortable ' [dragula]='"other-bag"' style="height:80%">

      <tbody [dragula]='"other-bag"'>
        <tr class="Tr" *ngFor="let role of rolesList; let i = index">
          <td id="{{role.id}}RoleID" style="border: 1px solid #A6A4A4;display:none;">{{role.id}}</td>
          <td id="{{role.id}}RoleType" class="disableEditable" style="width:85%;border: 1px solid #A6A4A4;" (keyup)="keyupTask(role.id,i,'Role')">
            {{role.itemName}}
          </td>
          <td *ngIf="(role.id>=currentuser.userrole)" id="{{role.id}}Roleorder" class="disableEditableButton" style="border: 1px solid #A6A4A4;width:15%;">
            <span *ngIf="role.itemName == ''">
            </span>
            <span *ngIf="role.itemName != ''">
              <span><button (click)="edit(role.id,'todoTasktype')" style="border: none" class="btn-xs btn-default"><i class="fa fa-pencil" aria-hidden="true"></i></button></span>
              <span *ngIf="role.isdeleted==0"><button (click)="RoleDelete(role.itemName,role.id,'1')" style="border: none" class="btn-xs btn-default"><i class="fa fa-trash" aria-hidden="true"></i></button></span>
              <span *ngIf="role.isdeleted!=0"> <button (click)="RoleDelete(role.itemName,role.id,'0')" style="border:none" class="btn-xs btn-default"><i class="fa fa-undo" aria-hidden="true"></i></button></span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
  <table class="table table-striped">
    <tr [hidden]="!Editing">
      <td style="padding-left:0;">  <button class="btn btn-long btn-default" (click)="cancelRole()" style="width: 100%;">Cancel</button></td>
      <td style="padding-right:0;"><button type="submit" class="btn btn-long changeActive noprovider selectedType" (click)="SaveRole()" style="width: 100%;background-color: #92D14F;">Save</button></td>
    </tr>
  </table>
</div>
