import { TicketNotes } from './TicketNotes';
import { DecimalPipe } from '@angular/common';

export class SLCategories {
  public LicenseCategoryId: number;
  public LicenseCategoryName: string;
  public SortOrder: number;
  public CreatedBy: number;
  public isdeleted: number;
}

export class SLTypes {
  public LicenseTypeId: number;
  public LicenseTypeName: string;
  public LicenseCategoryId: number;
  public SortOrder: number;
  public CreatedBy: number;
  public isdeleted: number;

  public LicenseCategoryName: string;
}

export class SoftwareLicense {
  public SoftwarelicenseId: number;
  public LicenseTypeId: number;
  public LicenseTypeName: string;
  public Contacts_AccountId: number;
  public Contacts_AccountName: string;
  public DateLicensed: string;
  public Status: number;
  public term: string;
  public Logins: number;
  public LicenseNumber: string;
  public createdBy: number;
  public createdByName: string;
  public createdDate: string;
  public isdeleted: number;
  public AccorConType: string;
  public LicenseNote: string;
  public SaaS: string;
  public RDate: string;
  public LicenseNotes: TicketNotes[];
}
