
  <div class="col-xs-12" style="overflow:auto; padding:0;border: 1px solid #ddd;height:100% !important">
    <div class="col-xs-12 scroll-c" style="padding:0;text-align: left;height:100%;overflow:auto">
      <div class="col-xs-12 col-sm-12 col-md-12" style="background-color: #2A3740;padding-bottom: 0;height:5%;color:white;text-align:center;" *ngIf="PageFrom !== 'Tickets'">
        <label style="margin-top:1%">ACCOUNT VIEW</label><a *ngIf="!isNotesDialog" (click)="AddTask()" style="color:#5aa251;float: right;margin-top: 0.5%">Task History </a>
      </div>     
    <div class="col-xs-12 scroll-c" style="padding-right:0;height:30%;overflow:auto">
      <account-details [viewaccount]="viewaccount" [account]="account" [tab]="''" [noteslist]="noteslist" (btnClickEvent)="btnClickReceived($event)" [pageFrom]="'Contacts'" [ViewData]="'Account'" [contact]="''"></account-details>

    </div>
      <div *ngIf="(Type =='Customer' || Type =='Account')" class="col-xs-12" id="providerDetails" style="display:block;height:65%;padding:0;">

        <div class="otherCss" style="margin-bottom:0.2em;padding:0;height:6%">

          <div class="col-xs-3" style="padding:0;height:100%">
            <select class="form-control" name="contacttype" (change)="ContactTypeFilter($event.target.value)" [(ngModel)]="SelectedContactType" style="height: 85%;margin-top: 2%;padding: 0;font-size: 12px;">
              <option value="All" [selected]="true">All Contacts ({{this.allContactsproviders?.length}})</option>
              <option *ngFor="let contacttype of SortedContactType" value={{contacttype.Value}}>
                {{contacttype.Name}}
              </option>
            </select>
          </div>

          <div class="col-xs-3" style="padding:0;height:100%">
            <select class="form-control" name="contactStatus" (change)="ContactStatusFilter($event.target.value)" [(ngModel)]="SelectedStatus" style="height: 85%;margin-top: 2%;padding: 0;font-size: 12px;">
              <option *ngFor="let Status of SortedStatus" value={{Status.id}}>
                {{Status.Value}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-xs-12" style="padding:0;height:93%">
          <ag-grid-angular style="width: 100%;height:100%" class="ag-theme-balham"
                           [gridOptions]="gridOptionsProvider"
                           [columnDefs]="columnDefsProvider"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="allContactsproviders"
                           [sideBar]="sideBar">
          </ag-grid-angular>
        </div>

      </div>
      <div *ngIf="(Type !=='Customer' && Type!=='Account')" class="col-xs-12 scroll-c" id="providerDetails" style="display:block;height:70%;padding:0;overflow:auto">
        <div class="col-xs-9 removeCompletePadding" style="padding:0">
          <div class="col-xs-12 removeCompletePadding" style="padding:0;">
            <div class="col-xs-12 removeCompletePadding">
              <div class="col-xs-12 " style="padding:0;">
                <b><label id="quickviewcontactid" style="font-size: 9pt;">CONTACT VIEW ({{displaycontact.TotalContactsCount}})</label></b>


              </div>

              <div class="col-xs-8 " style="padding:0 3%;text-align:center;" *ngIf="addEditInsideContact">
                <span [ngClass]="(this.contactpermission == 'view')? 'btn-xs btn-default pull-right disabled' : 'btn-xs btn-default pull-right'" [ngStyle]="{'backgroundColor':(this.contactpermission == 'view') ? 'white' : 'white','color':(this.contactpermission == 'view') ? 'lightgrey' : 'black'}" *ngIf="this.allowAccess === true" (click)="editcontact(0)" style="padding:0 0 2% 0"><i class="fa fa-user" aria-hidden="true"></i></span>

                <span [ngClass]="(this.contactpermission == 'view')? 'btn-xs btn-default pull-right disabled' : 'btn-xs btn-default pull-right'" [ngStyle]="{'backgroundColor':(this.contactpermission == 'view') ? 'white' : 'white','color':(this.contactpermission == 'view') ? 'lightgrey' : 'black'}" *ngIf="this.allowAccess === true" (click)="editcontact(displaycontact.id)"><i class="fa fa-pencil" aria-hidden="true"></i></span>

              </div>

            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b> Name:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                <b style=" color: #00b3f7d9; font-size: 9pt">{{displaycontact.firstname}} {{displaycontact.middlename}} {{displaycontact.lastname}}<span *ngIf="displaycontact.title != null">,</span>&nbsp;<span *ngIf="displaycontact.title != null">{{displaycontact.titleName}}</span></b>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" style="padding:0;font-weight:700" [style.width.%]="tab == 'QuickView' ? '45' :'37'">
                <b>Contact Type:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.contacttypeName}}
              </div>

            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b>Address1:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.address1}}
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b>Address2:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.address2}}
              </div>
            </div>

            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <span><b>City/State</b></span>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                <span>{{displaycontact.city}}<b *ngIf="displaycontact.state">,</b>&nbsp;{{displaycontact.state | uppercase}}.&nbsp;</span>

              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <span><b>Zip/Country:</b></span>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                <span><span *ngIf="displaycontact.zip && displaycontact.zip.length > 5">-</span>{{displaycontact.zip | slice:0:5 }}{{displaycontact.zip | slice:5:20 }},&nbsp;{{displaycontact.country}}</span>

              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <span><b>Island:</b></span>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                <span>{{displaycontact.ProvidenceIsland}}</span>

              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b>email:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.email}}
              </div>
            </div>

            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" style="padding:0" [style.width.%]="tab == 'QuickView' ? '45' :'37'">
                <b>Cell #</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.cell}}
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" style="padding:0" [style.width.%]="tab == 'QuickView' ? '45' :'37'">
                <b>Office #:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.office}}
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b>Specialty:</b>
              </div>
              <div *ngIf="displaycontact.speciality != 'null'" class="col-xs-7" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.specialityName}}
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b>Sub Specialty:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.subspecialityName}}
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b>Sex:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{(displaycontact.sex == 315)?'Male':'Female'}}
              </div>
            </div>

            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b>DOB:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.DOB}}
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b> College/Year:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.collegeName}}
                <span *ngIf="displaycontact.collegegrandyear !== null">
                  /{{displaycontact.collegegrandyear}}
                </span>
              </div>

            </div>
            <div *ngIf="displaycontact.medschool !== null" class="col-xs-12 removeCompletePadding ">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b>Med School/Year:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0 ;padding-left:0%" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.medschoolName}}
                <span *ngIf="displaycontact.medschoolgrandyear !== null">
                  / {{displaycontact.medschoolgrandyear}}
                </span>
              </div>

            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b>Residency/Year:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.residencyName}}
                <span *ngIf="(displaycontact.residencygrandyear!=null)"> /{{displaycontact.residencygrandyear}} </span>
              </div>

            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b>Fellowship/Year:</b>
              </div>
              <div *ngIf="displaycontact.fellowship !== null" [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.fellowshipName}}<span *ngIf="displaycontact.fellowshipgrandyear !== null">
                  /{{displaycontact.fellowshipgrandyear}}
                </span>
              </div>

            </div>

            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b>Home address:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.homeaddress}}
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" style="padding:0" [style.width.%]="tab == 'QuickView' ? '45' :'37'">
                <b>City/State/Zip:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-right:0;padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.homecity}}
                <span *ngIf="displaycontact.homestate !== null">/{{displaycontact.homestate | uppercase}}</span>
                <span *ngIf="displaycontact.homezip !== null">/{{displaycontact.homezip | slice:0:5}}<span *ngIf="displaycontact.homezip && displaycontact.homezip.length > 5">-{{displaycontact.homezip | slice:5:20}}</span></span>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b> Home #:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.home}}
              </div>
            </div>

            <div class="col-xs-12 removeCompletePadding">
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-5' :'col-xs-4'" [style.width.%]="tab == 'QuickView' ? '45' :'37'" style="padding:0;">
                <b>Owner:</b>
              </div>
              <div [ngClass]="tab == 'QuickView' ? 'col-xs-7' :'col-xs-8'" style="padding-left:0;" [style.width.%]="tab == 'QuickView' ? '55' :'63'">
                {{displaycontact.owner}}
              </div>
            </div>

          </div>

        </div>

        <div class="col-xs-2 removeCompletePadding" *ngIf="!photoForConatctView" style="float:right;padding:0;">         
          <div class="col-xs-12" style="padding:0;">

            <div style="height:175px;margin: 4% 0 0 0;">
              <img [src]="ConUrlImage " height="100" style="width:100%" *ngIf="ConUrlImage "> <br />
              <input id="addProfileImage" name="addProfileImage" value="addPhoto" type="file" accept=".jpg,.jpeg" style="display:contents" (change)="addFilesaccinfo($event)" />

              <label for="addProfileImage" style="font-size:100px;color:#ddd;width:100%;height:206px;margin-bottom:0;" *ngIf="!ConUrlImage ">
                <i class="fa fa-camera" aria-hidden="true" *ngIf="!ConUrlImage " for="addProfileImage" style="margin:0;"></i>
              </label>
              <input id="AddPhotoInput1" name="AddPhotoInput1" value="AddPhotoInput1" type="file" accept=".jpg,.jpeg" style="display:contents" (change)="addFilesaccinfo($event)"  />
              <label for="AddPhotoInput1" style="border:0.75px solid #ddd;border-radius:5px;width:100%;text-align:center;height: 24px;padding: 0;margin-bottom: 0;background-color: #ddd;font-size:0.8vw" *ngIf="ConUrlImage ">Replace Picture</label>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>

