import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Accounts } from '../../models/accounts';
import { Contacts } from '../../models/contacts';
import { CustomerService } from '../../services/customer.service';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { ApplicationComponent } from '../application.component';
declare var $: any;

@Component({
  selector: 'contactsof-customer',
  templateUrl: './contactsof-customer.component.html',
  styleUrls: ['./contactsof-customer.component.css']
})
export class ContactsofCustomerComponent implements OnInit {
  account: Accounts = new Accounts();
  allContactsproviders: Contacts[];
  itemResource: any;
  ContactsprovidersitemCount = 0; contactid: number;
  @Input()
  id: number;
  public gridOptionsProvider: GridOptions;
  public columnDefsProvider: any[];
  currentuser: any; public callhistorystatus: string;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  constructor(private customerService: CustomerService, public applicationComponent: ApplicationComponent, private opprtunityservice: OpprtunitiesService,)
  {
  this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.GetAccountInformation(this.id);
    this.initializeAgGrid();
  }
  GetAccountInformation(Accountid) {
    this.customerService.GetByAccountId(Accountid).then(result => {
      this.account = result;
    });
    this.customerService.GetAllContactproviderByAccount(Accountid, this.currentuser.id).then((contacts) => {
      contacts.forEach(contacts => {
      });
      this.allContactsproviders = contacts;
      this.ContactsprovidersitemCount = contacts.length;
      //this.itemResource = new DataTableResource(contacts);
      //this.itemResource.count().then(count => this.ContactsprovidersitemCount = count);
    });

  }
  onAgGridReadyProvider(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllProvider();
      $(setTimeout(function () {
        selfObj.autoSizeAllProvider();
      }, 400));
    }, 400));
  }
  autoSizeAllProvider() { }
  initializeAgGrid() {
   
    this.gridOptionsProvider = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'multiple'
    };

    let columnSelections: string;
   
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 36).then((config) => {

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefsProvider(columnSelections);
    });
    this.gridOptionsProvider.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
   
  }
  createColumnDefsProvider(columnSelections: string) {
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefsProvider = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefsProvider.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }  else {
          this.columnDefsProvider.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefsProvider = [     
        { field: 'lastname', headerName: 'Last Name', hide: false },
        { field: 'firstname', headerName: 'First Name', hide: false },
        { field: 'middlename', headerName: 'Middle Name', hide: false },
        { field: 'titleName', headerName: 'Title', hide: false },
        {
          field: 'callliststatus', headerName: 'Call List Hx', hide: false, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:50px; background:white">' + params.value + '</button>';
            var eButton = eDiv.querySelectorAll('.btn-simple')[0];
            eButton.addEventListener('click', function () {
              selfObj.callhistorystatus = params.data.callliststatus;
              selfObj.contactid = params.data.id;
              var obj = new Object();
              obj["id"] = params.data.id;
              obj["callliststatus"] = params.data.callliststatus;
              selfObj.sendMsgEvent.emit(JSON.stringify(obj));    
            });
            return eDiv;
          }
        },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        { field: 'DOB', headerName: 'DOB', hide: false },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        { field: 'zip', headerName: 'ZIP', hide: false },
        { field: 'accountname', headerName: 'Account Name', hide: false },
        { field: 'contacttype', headerName: 'Contact Type', hide: true },  //true starts here
        { field: 'suffix', headerName: 'Suffix', hide: true },
        { field: 'sex', headerName: 'Sex', hide: true },
        { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
        { field: 'email', headerName: 'Email', hide: true },
        { field: 'address1', headerName: 'Address1', hide: true },
        { field: 'address2', headerName: 'Address2', hide: true },
        { field: 'city', headerName: 'City', hide: true },
        { field: 'state', headerName: 'State', hide: true },
        { field: 'cell', headerName: 'Cell', hide: true },
        { field: 'office', headerName: 'Office', hide: true },
        { field: 'homeaddress', headerName: 'Home Address', hide: true },
        { field: 'homecity', headerName: 'Home City', hide: true },
        { field: 'homestate', headerName: 'Home State', hide: true },
        { field: 'homezip', headerName: 'Home Zip', hide: true },
        { field: 'home', headerName: 'Home', hide: true },
        { field: 'college', headerName: 'College', hide: true },
        { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
        { field: 'medschool', headerName: 'Medschool', hide: true },
        { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
        { field: 'residency', headerName: 'Residency', hide: true },
        { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
        { field: 'fellowship', headerName: 'Fellowship', hide: true },
        { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
        { field: 'created', headerName: 'Created', hide: true }
      ];
    }

  }
 
}

