import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Optional, Output, EventEmitter } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { OpprtunitiesService } from '../../../services/opprtunities.service';
import { ApplicationComponent } from '../../application.component';
import * as moment from 'moment'
import { Opportunities } from '../../../models/opportunities';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-revenue-dashboard',
  templateUrl: './revenue-dashboard.component.html',
  styleUrls: ['./revenue-dashboard.component.css']
})
export class RevenueDashboardComponent implements OnInit {
  @Input()
  opportunitytype: string;
  @Input()
  startDateVal;
  @Input()
  endDateVal;
  @Input()
  type;
  public gridOptions: GridOptions;
  public sideBar;
  currentuser: any;
  columnSelections;
  datePipe: DatePipe = new DatePipe("en-US");
  public context;
  public columnDefs: any[];
  today = Date.now();
  DateClosed: boolean = true;
  Allopportunities_Won: Opportunities[];
  Allopportunities_Lost: Opportunities[];
  Allopportunities_Open: Opportunities[];
  Allopportunities_Open_ECD: Opportunities[];
  @Output()
  outPropsEvent = new EventEmitter<string>();
  constructor(private opprtunityservice: OpprtunitiesService, public applicationComponent: ApplicationComponent, private router: Router) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    //alert(this.startDateVal);
    //alert(this.endDateVal);
    this.LoadRevenueData(this.opportunitytype, this.startDateVal, this.endDateVal);
    this.initializeAgGrid();
  }

  LoadRevenueData(type, startdatevalue, enddatevalue) {
    this.startDateVal = startdatevalue;
    this.endDateVal = enddatevalue;

    this.opportunitytype = type;
    if (this.type == 'Open_ECD') {
      this.OpportunityOpen_ECD(this.opportunitytype, $("#userids").val(), null);
    } else {

      if (this.opportunitytype == 'Won Opportunities') {
        this.OpportunityWon(this.opportunitytype, $("#userids").val(), null);
      } else if (this.opportunitytype == 'Lost Opportunities') {
        this.OpportunityLost(this.opportunitytype, $("#userids").val(), null);
      } else if (this.opportunitytype == 'Open Opportunities') {
        this.OpportunityOpen(this.opportunitytype, $("#userids").val(), null);
      }

    }

  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let DashconfigId = (this.type == 'Open_ECD') ? 60 : (this.type == 'Won') ? 61: 7;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, DashconfigId).then((config) => {
      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        //this.gridOptions.api.paginationSetPageSize(config.maxlimit);
      }
      this.columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";

      this.createColumnDefs(this.columnSelections);

    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;

  }
  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    var searcharray = [];
    if (this.applicationComponent.searchCriteriadata) {
      var searchaarraystring = this.applicationComponent.searchCriteriadata;
      if (searchaarraystring.includes(' /')) {
        searcharray = searchaarraystring.split(' / ');
      } else {
        searcharray.push(searchaarraystring);
      }
    }
    let selfobj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');       
          if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({
            field: 'owner', headerName: 'Owner', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, suppressSorting: true, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefs.push({
            field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "estimatedclosedate") {
          this.columnDefs.push({
            field: 'estimatedclosedate', headerName: 'ECD', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "datecreated") {
          this.columnDefs.push({
            field: 'datecreated', headerName: 'Date Created', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "practicename") {
          this.columnDefs.push({
            field: 'practicename', headerName: 'Account Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "lastName") {
          this.columnDefs.push({
            field: 'lastName', headerName: 'Last Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "firstName") {
          this.columnDefs.push({
            field: 'firstName', headerName: 'First Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "ProposalPDF") {
          this.columnDefs.push({
            field: 'ProposalPDF', headerName: 'Proposal', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            //cellRenderer(params) {
            //  if (params.data.ProposalPDF != null && params.data.ProposalPDF != 0) {
            //    var eDiv = document.createElement('div');
            //    eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:15%;margin-left:40%"/>';
            //    eDiv.addEventListener('click', function () {
            //      selfobj.PDFClick(params.data.ProposalPDF, 'Proposal');
            //    });
            //    return eDiv;
            //  }

            //}
          });
        }

        else if (fieldHeaderVisibleWidthArray[0] == "ProposalPOPDF") {
          this.columnDefs.push({
            field: 'ProposalPOPDF', headerName: 'PO', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            //cellRenderer(params) {
            //  if (params.data.ProposalPOPDF != null && params.data.ProposalPOPDF != 0) {
            //    var eDiv = document.createElement('div');
            //    eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:15%;margin-left:40%"/>';
            //    eDiv.addEventListener('click', function () {
            //      selfobj.PDFClick(params.data.ProposalPOPDF, 'Proposal');
            //    });
            //    return eDiv;
            //  }

            //}
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "datecompleted") {
          this.columnDefs.push({
            field: 'datecompleted', headerName: 'Date Completed', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              if (params.data.status == 'Open') {
                return "";
              } else {
                return moment(params.value).format('MM-DD-YYYY');
              }
            }
          });
        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      if (this.type == 'Open_ECD') {
      this.columnDefs = [
        {
          field: 'datecreated', headerName: 'Date Created', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'practicename', headerName: 'Account Name', hide: false,
          cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'estimatedclosedate', headerName: 'ECD', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'dollers', headerName: 'Price', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'RR', headerName: 'Monthly', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'itemName', headerName: 'Proposal Type', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'ProposalDescription', headerName: 'Proposal Description', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'firstName', headerName: 'First Name', hide: false,
          cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'lastName', headerName: 'Last Name', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'isPended', headerName: 'Pended', hide: false, cellStyle: function (params) {
            { return { color: '#FF0000' }; }
          }, cellRenderer: function (params) {
            if (params.data.isPended == '1' || params.data.isPended == 1) { return 'Pended'; }
          }
        },
        {
          field: 'PCname', headerName: 'Closing Percentage', hide: false
        },

        {
          field: 'datecompleted', headerName: 'Date Closed', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: (params) => {
            if (params.data.status == 'Open') {
              return "";
            } else {
              return moment(params.value).format('MM-DD-YYYY');
            }
          }
        },
        {
          field: 'status', headerName: 'Status', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },



        {
          field: 'ProposalPDF', headerName: 'Proposal', hide: true, width: 100,
          //cellRenderer(params) {

          //  if (params.data.ProposalPDF != null && params.data.ProposalPDF != 0) {
          //    var eDiv = document.createElement('div');
          //    eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
          //    eDiv.addEventListener('click', function () {
          //      selfobj.PDFClick(params.data.ProposalPDF, 'Proposal');
          //    });
          //    return eDiv;
          //  }

          //}

        },
        {
          field: 'ProposalPOPDF', headerName: 'PO', hide: true, width: 100,
          //cellRenderer(params) {
          //  if (params.data.ProposalPOPDF != null && params.data.ProposalPOPDF != 0) {
          //    var eDiv = document.createElement('div');
          //    eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
          //    eDiv.addEventListener('click', function () {
          //      selfobj.PDFClick(params.data.ProposalPOPDF, 'Proposal');
          //    });
          //    return eDiv;
          //  }
          //}

        },
        {
          field: 'LeadDescription', headerName: 'Lead Description', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },


        {
          field: 'PCname', headerName: 'Closing Percentage', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'drsName', headerName: '# DRS', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },  //true starts here
        {
          field: 'midlevel', headerName: '# Mid Levels', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'leadfrom', headerName: 'Lead From', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'owner', headerName: 'Owner', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'actualclosedate', headerName: 'Act.Close', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: true, pinned: 'right' },

        ];
      }
      else if (this.type == 'Won') {
        this.columnDefs = [

          {
            field: 'datecompleted', headerName: 'Date Closed', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              if (params.data.status == 'Open') {
                return "";
              } else {
                return moment(params.value).format('MM-DD-YYYY');
              }
            }
          },
         
          {
            field: 'practicename', headerName: 'Account Name', hide: false,
            cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },
          
          {
            field: 'dollers', headerName: 'Price', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'RR', headerName: 'Monthly', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
         
          {
            field: 'firstName', headerName: 'First Name', hide: false,
            cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'lastName', headerName: 'Last Name', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'itemName', headerName: 'Proposal Type', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'ProposalDescription', headerName: 'Proposal Description', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'status', headerName: 'Status', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },



          {
            field: 'ProposalPDF', headerName: 'Proposal', hide: true, width: 100,
            //cellRenderer(params) {

            //  if (params.data.ProposalPDF != null && params.data.ProposalPDF != 0) {
            //    var eDiv = document.createElement('div');
            //    eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
            //    eDiv.addEventListener('click', function () {
            //      selfobj.PDFClick(params.data.ProposalPDF, 'Proposal');
            //    });
            //    return eDiv;
            //  }

            //}

          },
          {
            field: 'ProposalPOPDF', headerName: 'PO', hide: true, width: 100,
            //cellRenderer(params) {
            //  if (params.data.ProposalPOPDF != null && params.data.ProposalPOPDF != 0) {
            //    var eDiv = document.createElement('div');
            //    eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
            //    eDiv.addEventListener('click', function () {
            //      selfobj.PDFClick(params.data.ProposalPOPDF, 'Proposal');
            //    });
            //    return eDiv;
            //  }
            //}

          },
          {
            field: 'LeadDescription', headerName: 'Lead Description', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },


          {
            field: 'PCname', headerName: 'Closing Percentage', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'drsName', headerName: '# DRS', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },  //true starts here
          {
            field: 'midlevel', headerName: '# Mid Levels', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'leadfrom', headerName: 'Lead From', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'owner', headerName: 'Owner', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'actualclosedate', headerName: 'Act.Close', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: true, pinned: 'right' },

        ];
      }
      else {
        this.columnDefs = [

          {
            field: 'datecompleted', headerName: 'Date Closed', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              if (params.data.status == 'Open') {
                return "";
              } else {
                return moment(params.value).format('MM-DD-YYYY');
              }
            }
          },
          {
            field: 'practicename', headerName: 'Account Name', hide: false,
            cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },

          {
            field: 'dollers', headerName: 'Price', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'RR', headerName: 'Monthly', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'firstName', headerName: 'First Name', hide: false,
            cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'lastName', headerName: 'Last Name', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'lastName', headerName: 'Title', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'itemName', headerName: 'Proposal Type', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'ProposalDescription', headerName: 'Proposal Description', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },

          {
            field: 'datecreated', headerName: 'Date Created', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          },
          {
            field: 'estimatedclosedate', headerName: 'ECD', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          },
          {
            field: 'status', headerName: 'Status', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },



          {
            field: 'ProposalPDF', headerName: 'Proposal', hide: true, width: 100,
            //cellRenderer(params) {

            //  if (params.data.ProposalPDF != null && params.data.ProposalPDF != 0) {
            //    var eDiv = document.createElement('div');
            //    eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
            //    eDiv.addEventListener('click', function () {
            //      selfobj.PDFClick(params.data.ProposalPDF, 'Proposal');
            //    });
            //    return eDiv;
            //  }

            //}

          },
          {
            field: 'ProposalPOPDF', headerName: 'PO', hide: true, width: 100,
            //cellRenderer(params) {
            //  if (params.data.ProposalPOPDF != null && params.data.ProposalPOPDF != 0) {
            //    var eDiv = document.createElement('div');
            //    eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
            //    eDiv.addEventListener('click', function () {
            //      selfobj.PDFClick(params.data.ProposalPOPDF, 'Proposal');
            //    });
            //    return eDiv;
            //  }
            //}

          },
          {
            field: 'LeadDescription', headerName: 'Lead Description', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },


          {
            field: 'PCname', headerName: 'Closing Percentage', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'drsName', headerName: '# DRS', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },  //true starts here
          {
            field: 'midlevel', headerName: '# Mid Levels', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'leadfrom', headerName: 'Lead From', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'owner', headerName: 'Owner', hide: false, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          {
            field: 'actualclosedate', headerName: 'Act.Close', hide: true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          },
          { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: true, pinned: 'right' },

        ];
      }

      var tooltipRenderer = function (params) {
        return '<span title="' + params.value + '">' + params.value + '</span>';
      };
    }
  }

  OpportunityWon(val, userids, searchval) {
    this.opprtunityservice.GetAllOpportunitiesList(val, userids, this.startDateVal, this.endDateVal, searchval, this.currentuser.id, true).then((customer) => {
      if (customer.length > 0) {

        this.Allopportunities_Won = customer;
        if (this.Allopportunities_Won.length > 0) {
          var won = this.Allopportunities_Won.reduce((sum, current) => sum + parseInt(current.dollers.replace('$', '')), 0);
          var rr = this.Allopportunities_Won.reduce((sum, current) => sum + parseInt(current.RR.replace('$', '')), 0);         
          this.outPropsEvent.emit(won + ":" + rr);
        }
        var openopportunityId = Number(localStorage.getItem('openopportunityId'));
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.gridOptions.api.forEachNode((node) => {
            if (node.data.id == openopportunityId) {
              selfObj.gridOptions.api.selectNode(node, true);
              selfObj.gridOptions.rowSelection = 'single';
            }
          });
        }, 300));
      }
      else {
        this.Allopportunities_Won = [];
        this.outPropsEvent.emit("0:0");
      }
    });

  }
  OpportunityLost(val, userids, searchval) {
    this.Allopportunities_Lost = [];
    this.opprtunityservice.GetAllOpportunitiesList(val, userids, this.startDateVal, this.endDateVal, searchval, this.currentuser.id, true).then((customer) => {
      if (customer.length > 0) {
        this.Allopportunities_Lost = customer;
      }
      else {
        this.Allopportunities_Lost = [];

      }
    });

  }
  OpportunityOpen(val, userids, searchval) {
    this.opprtunityservice.GetAllOpportunitiesList(val, userids, this.startDateVal, this.endDateVal, searchval, this.currentuser.id, true).then((customer) => {
      if (customer.length > 0) {
        this.Allopportunities_Open = customer;
        var openopportunityId = Number(localStorage.getItem('openopportunityId'));
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.gridOptions.api.forEachNode((node) => {
            if (node.data.id == openopportunityId) {
              selfObj.gridOptions.api.selectNode(node, true);
              selfObj.gridOptions.rowSelection = 'single';
            }
          });
        }, 300));
      }
      else {
        this.Allopportunities_Open = [];

      }
    });

  }

  rowClickAgGrid(rowEvent) {
    this.applicationComponent.CollapseSelectedMenu('opportunities');
    this.applicationComponent.ExpandSelectedMenu('opportunities');
    //if (this.type == 'Won') {
    //  localStorage.setItem('openopportunityId', rowEvent.data.id);
    //} else if (this.type =='Open_ECD'){
      localStorage.setItem('openopportunityId', rowEvent.data.id);
    //}

    this.router.navigateByUrl(`/application/opportunitiesdashboard/${rowEvent.data.accountorcontactid}/${rowEvent.data.type}/null/${rowEvent.data.id}`);

  }

  OpportunityOpen_ECD(val, userids, searchval) {
    this.opprtunityservice.GetAllOpportunitiesByECD(val, userids, this.startDateVal, this.endDateVal, searchval, this.currentuser.id).then((customer) => {
      if (customer.length > 0) {
        this.Allopportunities_Open_ECD = customer;
        var openopportunityId = Number(localStorage.getItem('openopportunityId'));
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.gridOptions.api.forEachNode((node) => {
            if (node.data.id == openopportunityId) {
              selfObj.gridOptions.api.selectNode(node, true);
              selfObj.gridOptions.rowSelection = 'single';
            }
          });
        }, 300));
      }
      else {
        this.Allopportunities_Open_ECD = [];

      }
    });

  }
}
