import { Component, OnInit, Input, Optional, EventEmitter, Output, ViewChild } from '@angular/core';
import { Accounts } from 'src/app/models/accounts';
import { CustomerService } from 'src/app/services/customer.service';
import { Contacts } from 'src/app/models/contacts';
import { GridOptions } from 'ag-grid';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { ApplicationComponent } from '../application.component';
import { Viewaccount } from '../../models/viewaccount';
import { Notes } from '../../models/notes';
import { DatePipe } from '@angular/common';
import { AccountDetailsComponent } from '../account-details/account-details.component';
declare var $: any;
@Component({
  selector: 'account-contact-info',
  templateUrl: './account-contact-info.component.html',
  styleUrls: ['./account-contact-info.component.css']
})
export class AccountContactInfoComponent implements OnInit {
  account: Accounts = new Accounts();
  displaycontact: Contacts = new Contacts();
  @Input()
  accountId: number;
  @Input()
  Type: string;
  @Input()
  PageFrom: string;
  @Input()
  isNotesDialog: boolean;
  @Input()
  tab: string;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  currentuser: any;
  ContactsprovidersitemCount = 0;
  public gridOptionsProvider: GridOptions;
  allContactsproviders: Contacts[];
  allContacts: Contacts[];
  public columnDefsProvider: any[];
  public columnDefs: any[];
  public sideBar;
  datePipe: DatePipe = new DatePipe("en-US");
  SortedContactType;
  itemCount = 0;
  SortedStatus;
  SelectedStatus;
  SelectedContactType;
  Contacttotal: Contacts[];
  viewaccount: Viewaccount = new Viewaccount();
  noteslist: Array<Notes> = [];
  photoForConatctView: boolean = false;
  @Output()
  btnClickEvent = new EventEmitter<any>();
  ConUrlImage = '';
  allowAccess: boolean = true;
  addEditInsideContact: boolean = false;
  public contactpermission: string;
  @ViewChild(AccountDetailsComponent) accdetailcmp;
  constructor(private customerService: CustomerService, public applicationComponent: ApplicationComponent, private opprtunityservice: OpprtunitiesService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {   
    this.initializeAgGrid();
    if (this.Type == "Customer" || this.Type=="Account") {
      this.GetAccountInfo();
    } else {
      this.GetContactInfo(this.accountId);
    }
  }
  initializeAgGrid() {
    this.gridOptionsProvider = <GridOptions>{
      pagination: false, enableColResize: true, paginationPageSize: 1000, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 36).then((config) => {

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefsProvider(columnSelections);
    });
    this.gridOptionsProvider.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }

  }
  createColumnDefsProvider(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let columnSelectionsArray: string[] = [];
    let selfObj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefsProvider = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefsProvider.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else {
          this.columnDefsProvider.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefsProvider = [       
        { field: 'lastname', headerName: 'Last Name', hide: false },
        { field: 'firstname', headerName: 'First Name', hide: false },
        { field: 'middlename', headerName: 'Middle Name', hide: false },
        { field: 'titleName', headerName: 'Title', hide: false },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        { field: 'DOB', headerName: 'DOB', hide: false },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        { field: 'zip', headerName: 'ZIP', hide: false },
        { field: 'accountname', headerName: 'Account Name', hide: false },
        { field: 'contacttype', headerName: 'Contact Type', hide: true },  //true starts here
        { field: 'suffix', headerName: 'Suffix', hide: true },
        { field: 'sex', headerName: 'Sex', hide: true },
        { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
        { field: 'email', headerName: 'Email', hide: true },
        { field: 'address1', headerName: 'Address1', hide: true },
        { field: 'address2', headerName: 'Address2', hide: true },
        { field: 'city', headerName: 'City', hide: true },
        { field: 'state', headerName: 'State', hide: true },
        { field: 'cell', headerName: 'Cell', hide: true },
        { field: 'office', headerName: 'Office', hide: true },
        { field: 'homeaddress', headerName: 'Home Address', hide: true },
        { field: 'homecity', headerName: 'Home City', hide: true },
        { field: 'homestate', headerName: 'Home State', hide: true },
        { field: 'homezip', headerName: 'Home Zip', hide: true },
        { field: 'home', headerName: 'Home', hide: true },
        { field: 'college', headerName: 'College', hide: true },
        { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
        { field: 'medschool', headerName: 'Medschool', hide: true },
        { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
        { field: 'residency', headerName: 'Residency', hide: true },
        { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
        { field: 'fellowship', headerName: 'Fellowship', hide: true },
        { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
        { field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true },
        { field: 'created', headerName: 'Created', hide: true }
      ];
    }

  }
  public GetAccountInfo() {
    this.customerService.GetByAccountId(this.accountId).then(result => {
      this.account = result;
      this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata);
      this.customerService.GetAllContactproviderByAccount(this.account.id, this.currentuser.id).then((contacts) => {
       
        this.allContactsproviders = contacts;
        
        this.allContacts = contacts;
        var rew = Array.from(new Set(this.allContactsproviders.map(s => s.contacttype)))
          .map(contacttype => {
            return {
              Name: ((this.allContactsproviders.filter(x => x.contacttype == contacttype)[0].contacttypeName != null) ? this.allContactsproviders.filter(x => x.contacttype == contacttype)[0].contacttypeName : '') + " (" + this.allContactsproviders.filter(x => x.contacttype == contacttype).length + ")",
              Value: contacttype
            }
          });
        this.SelectedContactType = "All";
        this.SortedStatus = [
          { id: 0, Value: 'Active & Inactive' + " (" + this.allContactsproviders.length + ")" },
          { id: 309, Value: 'Active' + " (" + this.allContactsproviders.filter(x => x.Status == 309).length + ")" },
          { id: 310, Value: 'Inactive' + " (" + this.allContactsproviders.filter(x => x.Status == 310).length + ")" }

        ];
        this.SelectedStatus = 309;
       
        this.SortedContactType = rew;

        this.itemCount = contacts.length;
       
      });
      });

   
  }
  public GetContactInfo(contactid) {
    this.customerService.GetContactById(contactid).then(result => {
      this.displaycontact = result;
      this.ConUrlImage  = "";
      this.customerService.Getcontactimage(contactid).then(res => {
        this.ConUrlImage  = res.photo;

      });
      this.customerService.GetByAccountId(this.displaycontact.accountid).then(result => {
        this.account = result;
        this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata);
        this.GetCustomerNotes(this.account.id.toString(), 'Customer');
      });

    });
  }

  ContactTypeFilter(val) {
    if (val == 'All') {
      this.Contacttotal = this.allContacts;
      if (this.SelectedStatus != 0) {
        this.allContactsproviders = this.allContacts.filter(x => x.Status == this.SelectedStatus);
      } else {
        this.allContactsproviders = this.allContacts;
      }
    } else {
      this.Contacttotal = this.allContacts.filter(x => x.contacttype == val);
      if (this.SelectedStatus != 0) {
        this.allContactsproviders = this.allContacts.filter(x => x.contacttype == val && x.Status == this.SelectedStatus);
      }
      else {
        this.allContactsproviders = this.allContacts.filter(x => x.contacttype == val);
      }

    }
    this.SortedStatus = [
      { id: 0, Value: 'Active & Inactive' + " (" + this.Contacttotal.length + ")" },
      { id: 309, Value: 'Active' + " (" + this.Contacttotal.filter(x => x.Status == 309).length + ")" },
      { id: 310, Value: 'Inactive' + " (" + this.Contacttotal.filter(x => x.Status == 310).length + ")" }

    ];

  }
  AddTask() {
    this.sendMsgEvent.emit("task");
  }
  ContactStatusFilter(val) {
    if (val == 0) {
      if (this.SelectedContactType == 'All')
        this.allContactsproviders = this.allContacts;
      else {
        this.allContactsproviders = this.allContacts.filter(x => x.contacttype == this.SelectedContactType);
      }
    } else {
      if (this.SelectedContactType != 'All') {
        this.allContactsproviders = this.allContacts.filter(x => x.Status == val && x.contacttype == this.SelectedContactType);
      }
      else {
        this.allContactsproviders = this.allContacts.filter(x => x.Status == val);
      }

    }
    //this.SelectedContactType = "All";
  }

  buttonClick(btnName, val) {
    this.btnClickEvent.emit({ btnName: btnName, val: val });

  }
  GetCustomerNotes(contactid, type) {
    this.customerService.GetAllNotesList(this.currentuser.id, contactid, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.noteslist = taskhistory;
    });

  }

  Display(accId, accountname, addr1, addr2, city, state, zip, country, phone, fax, website, Accountiddata) {

    //var properCity = city.charAt(0).toUpperCase() + city.substr(1).toLowerCase();
    if (city != null) {
      var cityArray = city.split(' ');
      var newCity = '';
      for (var i = 0; i < cityArray.length; i++) {
        if (newCity == '') {
          newCity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
        else {
          newCity = newCity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
      }
    }
    if (website != null && website.indexOf('www.') >= 0) {
      website = ((website).split('www.')[1])
    } else if (website != null && website.indexOf('ww.') >= 0) {
      website = ((website).split('ww.')[1])
    }


    var properstate = (state != null) ? state.charAt(0).toUpperCase() + state.substr(1).toLowerCase() : "";
    this.viewaccount.accountId = accId;
    this.viewaccount.viewaccountname = accountname;
    this.viewaccount.viewaddress1 = addr1;
    this.viewaccount.viewaddress2 = addr2;
    this.viewaccount.viewcity = newCity == null ? "" : newCity;
    this.viewaccount.viewstate = properstate;
    this.viewaccount.viewzip = zip;
    this.viewaccount.viewCountry = country;
    this.viewaccount.viewphone = phone;
    this.viewaccount.viewfax = fax;
    this.viewaccount.viewwebsite = website;
    this.viewaccount.Accountiddata = Accountiddata;
    this.accdetailcmp.loadAccountId(accId);
  }

  btnClickReceived(event) {

  }

  editcontact(contactId) {

    if (this.contactpermission == "view" || this.account.isAccountPermission == "View") {
      this.applicationComponent.isAccessDenied = true;
    }
    else {

      var url = window.location.href;
      if (url.indexOf('tickets') > -1) {
        this.sendMsgEvent.emit('edit');
      }
    }

  }
  addFilesaccinfo(event) {
    if (this.contactpermission == 'view') {
      this.applicationComponent.isAccessDenied = true;
    } else {
      let files = event.srcElement.files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        this.addCharge(file);
      }
    }
  }

  addCharge(file) {
    this.photoForConatctView = true;
    let contact = new Contacts();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    let selfObj = this;
    $(setTimeout(function () {
      contact.id = selfObj.accountId;
      contact.phototype = file.type;
      contact.photo = reader.result.toString();
      selfObj.GetContactInfo(selfObj.accountId);
      selfObj.customerService.uploadPhoto(contact).then((user) => {
        selfObj.customerService.Getcontactimage(selfObj.accountId).then(res => {
          selfObj.ConUrlImage = res.photo;
          selfObj.photoForConatctView = false;
        });

      });
    }, 500));
    
  }
}
