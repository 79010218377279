import { Component, OnInit, Input } from '@angular/core';
import { Accounts } from 'src/app/models/accounts';
import { Contacts } from 'src/app/models/contacts';
import { UserService } from 'src/app/services/user.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AccountsService } from 'src/app/services/accounts.service';
import { StatusIds } from 'src/app/models/StatusIds';
import { DatePipe } from '@angular/common';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { AccountsHistory } from '../../models/AccountHistory';
import { ApplicationComponent } from '../application.component';
declare var $: any;
@Component({
  selector: 'status-ids',
  templateUrl: './status-ids.component.html',
  styleUrls: ['./status-ids.component.css']
})
export class StatusIdsComponent implements OnInit {
  @Input()
  accOrContId: number;
  @Input()
  accesstype: string;
  StatusIds: StatusIds = new StatusIds();
  account: Accounts = new Accounts();
  contact: Contacts = new Contacts();
  AccountIdsDialog: boolean = false;
  statusIdExist: boolean = false;
  statusIdEditExist: boolean = false;
  statusIdcustomerEditExist: boolean = false;
  //AccountIdArr: any;
  StatusIDAHxDisplay: any;
  //StatusIDAHx: any;
  currentuser: any;
  LastTicketDate;
  LastContactDate;
  salesrepName: string;
  index = 0;
  datePipe: DatePipe = new DatePipe("en-US");
  public gridOptions: GridOptions; public gridCustomerOptions: GridOptions;
  CusProfiledropdownList = [];
  public gridApi;
  public gridCustomerApi;
  AccProfiledropdownList = [];
  dropdownSetting = {};
  AccountIdArr = [];
  StatusIDAHx = [];
  public sideBar;
  public frameworkComponents;
  public context;
  public columnDefs;

  accountHistory: AccountsHistory = new AccountsHistory();
  constructor(private userService: UserService,private customerService: CustomerService, private accountsService: AccountsService,private appcomponent:ApplicationComponent ) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.initializeAgGrid();
    this.GetAccountInfo(this.accOrContId);
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'multiple'
    };
    this.gridCustomerOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'multiple'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.context = this;
    this.frameworkComponents = {

    };
    this.createColumnDefs();
  }
  createColumnDefs() {
    this.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      
      { field: 'StatusIDName', headerName: 'StatusIDName', hide: false },
      //{ field: 'ProjectTypeName', headerName: 'Project Type', hide: false },
      //{ field: 'CreatedDate', headerName: 'Date Created', hide: false },
      //{ field: 'ProjectStatusName', headerName: 'Project Status', hide: false },
      //{ field: 'ProjectLeaderName', headerName: 'Project Leader', hide: false },
      //{ field: 'UserAccessName', headerName: 'User Access', hide: false },
      
    ];
  }
  EditStatusaccountIds() {
    this.statusIdEditExist = true;
  }
  PreviewStatusaccountIds() { this.statusIdEditExist = false; }
  LoadAccContactId(accContactId) {
    this.accOrContId = accContactId;
  }
  buttonClick(btnName, val) {
    //this.btnClickEvent.emit({ btnName: btnName, val: val });
  }
  doSomething() {

    this.statusIdEditExist = true;
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.gridOptions.api.forEachNode((node) => {
        if (node.data.Id == selfObj.StatusIds.StatusID1 || node.data.Id == selfObj.StatusIds.StatusID2 || node.data.Id == selfObj.StatusIds.StatusID3 || node.data.Id == selfObj.StatusIds.StatusID4 ||
          node.data.Id == selfObj.StatusIds.StatusID5 || node.data.Id == selfObj.StatusIds.StatusID6 || node.data.Id == selfObj.StatusIds.StatusID7 || node.data.Id == selfObj.StatusIds.StatusID8 ||
          node.data.Id == selfObj.StatusIds.StatusID9 || node.data.Id == selfObj.StatusIds.StatusID10 || node.data.Id == selfObj.StatusIds.StatusID11 || node.data.Id == selfObj.StatusIds.StatusID12 ||
          node.data.Id == selfObj.StatusIds.StatusID13 || node.data.Id == selfObj.StatusIds.StatusID14 || node.data.Id == selfObj.StatusIds.StatusID15 || node.data.Id == selfObj.StatusIds.StatusID16 ||
          node.data.Id == selfObj.StatusIds.StatusID17 || node.data.Id == selfObj.StatusIds.StatusID18 || node.data.Id == selfObj.StatusIds.StatusID19 || node.data.Id == selfObj.StatusIds.StatusID20) {
          selfObj.gridOptions.api.selectNode(node, true);
        }
      });
    }, 500));
  }
  Docustomerclick() {
    this.statusIdcustomerEditExist = true;
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.gridCustomerOptions.api.forEachNode((node) => {
        if (node.data.Id == selfObj.StatusIds.StatusID21 || node.data.Id == selfObj.StatusIds.StatusID22 || node.data.Id == selfObj.StatusIds.StatusID23 || node.data.Id == selfObj.StatusIds.StatusID24 ||
          node.data.Id == selfObj.StatusIds.StatusID25 || node.data.Id == selfObj.StatusIds.StatusID26 || node.data.Id == selfObj.StatusIds.StatusID27 || node.data.Id == selfObj.StatusIds.StatusID28 ||
          node.data.Id == selfObj.StatusIds.StatusID29 || node.data.Id == selfObj.StatusIds.StatusID30 || node.data.Id == selfObj.StatusIds.StatusID31 || node.data.Id == selfObj.StatusIds.StatusID32 ||
          node.data.Id == selfObj.StatusIds.StatusID33 || node.data.Id == selfObj.StatusIds.StatusID34 || node.data.Id == selfObj.StatusIds.StatusID35 || node.data.Id == selfObj.StatusIds.StatusID36 ||
          node.data.Id == selfObj.StatusIds.StatusID37 || node.data.Id == selfObj.StatusIds.StatusID38 || node.data.Id == selfObj.StatusIds.StatusID39 || node.data.Id == selfObj.StatusIds.StatusID40) {
          selfObj.gridCustomerOptions.api.selectNode(node, true);
        }
      });
    }, 500));
  }

  AccProfileonItemSelect(item: any) { }

  AccProfileOnItemDeSelect(item: any) { }


  CusProfileonItemSelect(item: any) { }

  CusProfileOnItemDeSelect(item: any) { }

  GetAccountInfo(accountId) { 
    this.customerService.GetByAccountId(accountId).then(result => {
      this.account = result;
      this.customerService.LastTicketCreatedDateByAccID(accountId).then(result1 => {
        if (result1 != null && result1 != '0001-01-01T00:00:00') {
          this.LastTicketDate = this.datePipe.transform(result1, "dd-MM-yyyy")
        }

      });
      this.customerService.LastContactedDateByAccID(accountId).then(result2 => {
        if (result2 != null && result2 != '0001-01-01T00:00:00') {
          this.LastContactDate = this.datePipe.transform(result2, "dd-MM-yyyy");
        }
      });
      this.customerService.GetSalerepName(accountId).then(result3 => {
        this.salesrepName = result3;
      });
      this.customerService.GetAccountHistoryById(accountId, this.currentuser.id).then(result => {      
          this.accountHistory = result;    
      })
      this.GetAccountStatusIds(this.account, this.accesstype);
     ;
    });
  }

  GetAccountStatusIds(Account, type) {
    
    var that = this;
    this.accountsService.GetAccountStatusIds(Account.id, type).then(result => {   
      this.CheckStatusIDExist(result);
      if (result != null) {
        this.StatusIds = result;
        this.userService.GetAllAddedStatusIds(this.currentuser.id, 0).then(result => {
          var AHx = result.filter(x => x.StatusIDGroup == 5001);
          var SID = result.filter(x => x.StatusIDGroup == 5000);   
          this.AccountIdArr = SID.sort((a, b) => a.SortOrder - b.SortOrder);
          this.StatusIDAHx = AHx.sort((a, b) => a.SortOrder - b.SortOrder);
          
          var current_elemnt1 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID1;
          });
          if (current_elemnt1.length > 0) {
            this.StatusIds.StatusIDName1 = current_elemnt1[0].StatusIDName;
          }

          var current_elemnt2 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID2;
          });
          if (current_elemnt2.length > 0) {
            this.StatusIds.StatusIDName2 = current_elemnt2[0].StatusIDName;
          }

          var current_elemnt3 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID3;
          });
          if (current_elemnt3.length > 0) {
            this.StatusIds.StatusIDName3 = current_elemnt3[0].StatusIDName;
          }

          var current_elemnt4 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID4;
          });
          if (current_elemnt4.length > 0) {
            this.StatusIds.StatusIDName4 = current_elemnt4[0].StatusIDName;
          }

          var current_elemnt5 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID5;
          });
          if (current_elemnt5.length > 0) {
            this.StatusIds.StatusIDName5 = current_elemnt5[0].StatusIDName;
          }

          var current_elemnt6 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID6;
          });
          if (current_elemnt6.length > 0) {
            this.StatusIds.StatusIDName6 = current_elemnt6[0].StatusIDName;
          }

          var current_elemnt7 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID7;
          });
          if (current_elemnt7.length > 0) {
            this.StatusIds.StatusIDName7 = current_elemnt7[0].StatusIDName;
          }

          var current_elemnt8 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID8;
          });
          if (current_elemnt8.length > 0) {
            this.StatusIds.StatusIDName8 = current_elemnt8[0].StatusIDName;
          }

          var current_elemnt9 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID9;
          });
          if (current_elemnt9.length > 0) {
            this.StatusIds.StatusIDName9 = current_elemnt9[0].StatusIDName;
          }

          var current_elemnt10 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID10;
          });
          if (current_elemnt10.length > 0) {
            this.StatusIds.StatusIDName10 = current_elemnt10[0].StatusIDName;
          }

          var current_elemnt11 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID11;
          });
          if (current_elemnt11.length > 0) {
            this.StatusIds.StatusIDName11 = current_elemnt11[0].StatusIDName;
          }

          var current_elemnt12 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID12;
          });
          if (current_elemnt12.length > 0) {
            this.StatusIds.StatusIDName12 = current_elemnt12[0].StatusIDName;
          }


          var current_elemnt13 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID13;
          });
          if (current_elemnt13.length > 0) {
            this.StatusIds.StatusIDName13 = current_elemnt13[0].StatusIDName;
          }

          var current_elemnt14 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID14;
          });
          if (current_elemnt14.length > 0) {
            this.StatusIds.StatusIDName14 = current_elemnt14[0].StatusIDName;
          }

          var current_elemnt15 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID15;
          });
          if (current_elemnt15.length > 0) {
            this.StatusIds.StatusIDName15 = current_elemnt15[0].StatusIDName;
          }

          var current_elemnt16 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID16;
          });
          if (current_elemnt16.length > 0) {
            this.StatusIds.StatusIDName16 = current_elemnt16[0].StatusIDName;
          }

          var current_elemnt17 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID17;
          });
          if (current_elemnt17.length > 0) {
            this.StatusIds.StatusIDName17 = current_elemnt17[0].StatusIDName;
          }

          var current_elemnt18 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID18;
          });
          if (current_elemnt18.length > 0) {
            this.StatusIds.StatusIDName18 = current_elemnt18[0].StatusIDName;
          }

          var current_elemnt19 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID19;
          });
          if (current_elemnt19.length > 0) {
            this.StatusIds.StatusIDName19 = current_elemnt19[0].StatusIDName;
          }

          var current_elemnt20 = this.AccountIdArr.filter(function (obj) {
            return obj.Id == that.StatusIds.StatusID20;
          });
          if (current_elemnt20.length > 0) {
            this.StatusIds.StatusIDName20 = current_elemnt20[0].StatusIDName;
          }

          var current_elemnt21 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID21;
            });
            if (current_elemnt21.length > 0) {
              this.StatusIds.StatusIDName21 = current_elemnt21[0].StatusIDName;
            }

            var current_elemnt22 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID22;
            });
            if (current_elemnt22.length > 0) {
              this.StatusIds.StatusIDName22 = current_elemnt22[0].StatusIDName;
            }

            var current_elemnt23 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID23;
            });
            if (current_elemnt23.length > 0) {
              this.StatusIds.StatusIDName23 = current_elemnt23[0].StatusIDName;
            }

            var current_elemnt24 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID24;
            });
            if (current_elemnt24.length > 0) {
              this.StatusIds.StatusIDName24 = current_elemnt24[0].StatusIDName;
            }

            var current_elemnt25 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID25;
            });
            if (current_elemnt25.length > 0) {
              this.StatusIds.StatusIDName25 = current_elemnt25[0].StatusIDName;
            }


            var current_elemnt26 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID26;
            });
            if (current_elemnt26.length > 0) {
              this.StatusIds.StatusIDName26 = current_elemnt26[0].StatusIDName;
            }

            var current_elemnt27 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID27;
            });
            if (current_elemnt27.length > 0) {
              this.StatusIds.StatusIDName27 = current_elemnt27[0].StatusIDName;
            }

            var current_elemnt28 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID27;
            });
            if (current_elemnt28.length > 0) {
              this.StatusIds.StatusIDName28 = current_elemnt28[0].StatusIDName;
            }

            var current_elemnt29 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID29;
            });
            if (current_elemnt29.length > 0) {
              this.StatusIds.StatusIDName29 = current_elemnt29[0].StatusIDName;
            }

            var current_elemnt30 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID30;
            });
            if (current_elemnt30.length > 0) {
              this.StatusIds.StatusIDName30 = current_elemnt30[0].StatusIDName;
            }

            var current_elemnt31 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID31;
            });
            if (current_elemnt31.length > 0) {
              this.StatusIds.StatusIDName31 = current_elemnt31[0].StatusIDName;
            }

            var current_elemnt32 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID32;
            });
            if (current_elemnt32.length > 0) {
              this.StatusIds.StatusIDName32 = current_elemnt32[0].StatusIDName;
            }

            var current_elemnt33 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID33;
            });
            if (current_elemnt33.length > 0) {
              this.StatusIds.StatusIDName33 = current_elemnt33[0].StatusIDName;
            }

            var current_elemnt34 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID34;
            });
            if (current_elemnt34.length > 0) {
              this.StatusIds.StatusIDName34 = current_elemnt34[0].StatusIDName;
            }

            var current_elemnt35 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID35;
            });
            if (current_elemnt35.length > 0) {
              this.StatusIds.StatusIDName35 = current_elemnt35[0].StatusIDName;
            }

            var current_elemnt36 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID36;
            });
            if (current_elemnt36.length > 0) {
              this.StatusIds.StatusIDName36 = current_elemnt36[0].StatusIDName;
            }

            var current_elemnt37 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID37;
            });
            if (current_elemnt37.length > 0) {
              this.StatusIds.StatusIDName37 = current_elemnt37[0].StatusIDName;
            }

            var current_elemnt38 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID38;
            });
            if (current_elemnt38.length > 0) {
              this.StatusIds.StatusIDName38 = current_elemnt38[0].StatusIDName;
            }

            var current_elemnt39 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID39;
            });
            if (current_elemnt39.length > 0) {
              this.StatusIds.StatusIDName39 = current_elemnt39[0].StatusIDName;
            }

            var current_elemnt40 = this.StatusIDAHx.filter(function (obj) {
              return obj.Id == that.StatusIds.StatusID40;
            });
            if (current_elemnt40.length > 0) {
              this.StatusIds.StatusIDName40 = current_elemnt40[0].StatusIDName;
            }
          
        });

        for (let i = 1; i <= 40; i++) {
          if (this.StatusIds["StatusID" + i] != null ) {
            this.statusIdExist = true;
          }
        }
      } else {
        this.userService.GetAllAddedStatusIds(this.currentuser.id, 0).then(result => {
          var AHx = result.filter(x => x.StatusIDGroup == 5001);
          var SID = result.filter(x => x.StatusIDGroup == 5000);
          this.AccountIdArr = SID.sort((a, b) => a.SortOrder - b.SortOrder);
          this.StatusIDAHx = AHx.sort((a, b) => a.SortOrder - b.SortOrder);
        });
        this.StatusIds = new StatusIds();
        this.statusIdExist = false;
        this.statusIdcustomerEditExist = false;
        this.StatusIds.AccountContactId = Account.id;
        this.StatusIds.AccountContactType = type;
      }
    });
  }
  CheckStatusIDExist(result) {
    if (result != undefined && (result.StatusID1 != null || result.StatusID2 != null || result.StatusID3 != null || result.StatusID4 != null || result.StatusID5 != null || result.StatusID6 != null ||
      result.StatusID7 != null || result.StatusID8 != null || result.StatusID9 != null || result.StatusID10 != null || result.StatusID11 != null || result.StatusID12 != null ||
      result.StatusID13 != null || result.StatusID14 != null || result.StatusID15 != null || result.StatusID16 != null || result.StatusID17 != null || result.StatusID18 != null ||
      result.StatusID19 != null || result.StatusID20 != null || result.StatusID21 != null || result.StatusID22 != null || result.StatusID23 != null || result.StatusID24 != null ||
      result.StatusID25 != null || result.StatusID26 != null || result.StatusID27 != null || result.StatusID28 != null || result.StatusID29 != null || result.StatusID30 != null ||
      result.StatusID31 != null || result.StatusID32 != null || result.StatusID33 != null || result.StatusID34 != null || result.StatusID35 != null || result.StatusID36 != null ||
      result.StatusID37 != null || result.StatusID38 != null || result.StatusID39 != null || result.StatusID40 != null)) {
      this.statusIdExist = true;
      return true;
    } else {
      this.statusIdExist = false;
      return false;
    }
  }
  openAccountIds() { 
    this.userService.GetAllAddedStatusIds(this.currentuser.id, 0).then(result => {
      var AHx = result.filter(x => x.StatusIDGroup == 5001);
      var SID = result.filter(x => x.StatusIDGroup == 5000);
      this.AccountIdArr = SID.sort((a, b) => a.SortOrder - b.SortOrder);   
      this.StatusIDAHx = AHx.sort((a, b) => a.SortOrder - b.SortOrder);
    });
   
    if (this.accesstype == 'Contact') {
      this.customerService.GetContactById(this.accOrContId).then(result => {
        this.contact = result;
        this.GetAccountInfo(this.contact.accountid);
      });
    }
    else {
      if (this.accOrContId > 0) {
        this.GetAccountInfo(this.accOrContId);
      }

    }
    this.StatusIds.AccountContactId = this.accOrContId;
    this.StatusIds.AccountContactType = this.accesstype;
    //this.AccountIdsDialog = true;
    //this.AccountIdsDialogCss();
  }
  AccountIdsDialogCss() {
    this.appcomponent.StandardGreenpopupUi('.dialog');
  
  }

  AccountIdPopupChange(val, sID) {
    if (val == null) {
      this.StatusIds = null;
    } else {
      for (var i = 1; (i <= 20) && (i != sID); i++) {
        if (this.StatusIds['StatusID' + i] == val) {
          alert("Please select Other Option already Selected Value Added .");
          this.StatusIds['StatusID' + sID] = "null";
        }
      }
    }
    
  }
  clearStatusIds(val) {
    if (val == "Account") {
      for (var i = 1; i <= 20; i++) {
        this.StatusIds['StatusID' + i] = null;
      }
    } else {
      for (var j = 21; j <= 40; j++) {
        this.StatusIds['StatusID' + j] = null;
      }
    }
  }

  Updatesaccounts(val) {  
    this.clearStatusIds(val);
    if (val == "Account") {
      const selectedData = this.gridApi.getSelectedRows();
      var i = 1;
      selectedData.forEach(col => {
        this.StatusIds['StatusID' + i] = col.Id;
        i++;
      });
    }
    if (val == "Customer") {
      const selectedCustomerData = this.gridCustomerApi.getSelectedRows();
      var j = 21;
      selectedCustomerData.forEach(col => {
        this.StatusIds['StatusID' + j] = col.Id;
        j++;

      });
    }

    this.StatusIds.userid = this.currentuser.id;
    this.accountsService.AddUpdateStatusIDS(this.StatusIds).then(result => {
      this.statusIdEditExist = false;
      this.statusIdcustomerEditExist = false;
      let SO = this;
      setTimeout(function () {
        SO.GetAccountStatusIds(SO.account, SO.accesstype);
      }, 200)
      
    });
  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  onAgCustomerGridReady(params) {
    this.gridCustomerApi = params.api;
  }
  cancelaccountIds() {
    this.statusIdEditExist = false;
  }
  cancelCustomeraccountIds() {
    this.statusIdcustomerEditExist = false;
  }
  CloseaccountIds() {  this.AccountIdsDialog = false;}
  DeleteStatusID(statusIDNumber) {
    this.StatusIds["StatusID" + statusIDNumber] = null;
    this.accountsService.AddUpdateStatusIDS(this.StatusIds).then(result => {
      let SO = this;
      setTimeout(function () {
        SO.GetAccountStatusIds(SO.account, SO.accesstype); 
      }, 4)
    });
  }

  SelectedStatusID(statusId) {

    $("#" + statusId).attr('style', 'font-weight:bold;');

   
  }
}
