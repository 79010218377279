<div class="col-xs-12 " id="Opptdetails" style="padding-left: 0% !important;padding-right:0; display:block;height:100%">
  <div class="col-xs-12" style="height: 2%; background-color: orangered"></div>
      <div class="col-xs-12" style="text-align: center;margin-top: 0.5%;padding:0;height:4%">
        <b> ACCOUNT SHARING</b>
        <img src="../../../assets/img/Black x.png" style="height:20px;float:right" (click)="close()" />
      </div>
    <div class="col-xs-12" *ngIf="!isChangeOwner" style="margin-top: 1%; padding: 0;"
         [style.height.%]="(PageType != 'QuickView')? '94':'94'" [ngStyle]="{'pointer-events':account.ownerid == currentuser.id ? 'auto' : 'none' }">

      <div class="col-xs-6" style="padding:0;height:90%">

        <div class="col-xs-12" style="padding:0;height:50%">
          <div class="col-xs-12" style="height: 10%; padding: 0; text-align: center; text-transform: uppercase"><b class="providerLabel">Permission to Edit Account</b></div>
          <div class="col-xs-12" style="height:13%;padding:0;text-align:center">
            <div class="col-xs-12" style="padding:0;height:100%">
              <div class="col-xs-12" style="padding:0 1% 0 4%;height:100%">

                <angular2-multiselect [data]="accountgroupList"
                                      [(ngModel)]="PermissionEditGroupItems"
                                      [settings]="GroupDropdownSetting" id="shareEditGroup"
                                      name="PermissionEditGroupItems"
                                      (onSelect)="onEditGroupItemSelect($event)"
                                      (onDeSelect)="onEditGroupItemSelect($event)"
                                      (onSelectAll)="onEditGroupItemSelect($event)"
                                      (onDeSelectAll)="onEditGroupItemSelect($event)">
                </angular2-multiselect>

              </div>

            </div>
          </div>
          <div class="col-xs-12" style="height:77%;padding:0 1% 0 4%">
            <div class="col-xs-12" style="padding:0;height:100%;border:1px solid black !important">
              <div class="col-xs-12 scroll-c" style="padding:0;height:100%;overflow:auto;" id="userGropList">
                <div *ngFor="let per of accountSharingEditGrouplist" style="padding-left:0;">
                  <label style="margin-left: 5px;">{{per.permssionuser}}</label>
                </div>
                <!--<div *ngFor="let per of PermissionEditGroupItems" style="padding-left:0;">
                <label style="margin-left: 5px;">{{per.permssionuser}}</label>
              </div>-->
              </div>

              /d

            </div>
            /d
          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:50%">

          <div class="col-xs-12" style="height:4%;padding:0;text-align:center"><b class="providerLabel"> </b></div>
          <div class="col-xs-12" style="height:13%;padding:0;text-align:center">
            <div class="col-xs-12" style="padding:0;height:100%">
              <div class="col-xs-12" style="padding:0 1% 0 4%;height:100%">
                <angular2-multiselect [data]="dropdownList" [(ngModel)]="PermissionEditUserItems" [settings]="UsersDropdownSetting" id="shareEditUser"
                                      name="PermissionEditUserItems"
                                      (onSelect)="onEditUsersItemSelect($event)"
                                      (onDeSelect)="onEditUsersItemSelect($event)"
                                      (onSelectAll)="onEditUsersItemSelect($event)"
                                      (onDeSelectAll)="onEditUsersItemSelect($event)">
                </angular2-multiselect>
              </div>

            </div>
          </div>
          <div class="col-xs-12" style="height:82%;padding:0 1% 0 4%">
            <div class="col-xs-12" style="padding: 0; height: 100%; border: 1px solid black !important">
              <div class="col-xs-12 scroll-c" style="padding:0;height:100%;overflow:auto;" id="userGropList">
                <div *ngFor="let per of accountSharingEditUserlist" style="padding-left:0;">
                  <label style="margin-left: 5px;">{{per.permssionuser}}</label>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>
      <div class="col-xs-6" style="padding:0;height:90%">
        <div class="col-xs-12" style="padding:0;height:50%">
          <div class="col-xs-12" style="height:10%;padding:0;text-align:center;text-transform:uppercase"><b class="providerLabel">Permission to View Account</b></div>
          <div class="col-xs-12" style="height:13%;padding:0;text-align:center">
            <div class="col-xs-12" style="padding:0;height:100%">
              <div class="col-xs-12" style="padding:0 4% 0 1%;height:100%">

                <angular2-multiselect id="ShareViewGroup"
                                      [data]="accountgroupList" [settings]="GroupDropdownSetting"
                                      name="permissionselectedGroupItems"
                                      [(ngModel)]="PermissionViewGroupItems"
                                      (onSelect)="onViewGroupItemSelect($event)"
                                      (onDeSelect)="onViewGroupItemSelect($event)"
                                      (onSelectAll)="onViewGroupItemSelect($event)"
                                      (onDeSelectAll)="onViewGroupItemSelect($event)">
                </angular2-multiselect>

              </div>

              <!--<div class="col-xs-12">

              <angular2-multiselect [data]="accountgroupList"
                                    name="viewgroupselectedItems"
                                    [(ngModel)]="viewgroupselectedItems"
                                    (onSelect)="onviewgroupItemSelect($event)"
                                    (onDeSelect)="OnviewgroupItemDeSelect($event)"
                                    (onSelectAll)="onviewgroupSelectAll($event)"
                                    (onDeSelectAll)="onviewgroupDeSelectAll($event)">
              </angular2-multiselect>


            </div>-->

            </div>
          </div>
          <div class="col-xs-12" style="height:77%;padding: 0 4% 0 1%">
            <div class="col-xs-12" style="padding: 0; height: 100%; border: 1px solid black !important">
              <div style="height:100%;padding:0;" class="col-xs-12 scroll-c" id="usrList">
                <div class="col-xs-12" *ngFor="let viewper of accountSharingViewGrouplist" style="padding-left:0;">
                  <!--<i [ngClass]="viewper.Type=='viewgroup' ? 'fa fa-users' :'fa fa-user'" aria-hidden="true"></i>--> <label style="margin-left: 5px;">{{viewper.permssionuser}}</label>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:50%">

          <div class="col-xs-12" style="height:4%;padding:0;text-align:center"><b class="providerLabel"></b></div>
          <div class="col-xs-12" style="height:13%;padding:0;text-align:center">
            <div class="col-xs-12" style="padding:0;height:100%">
              <div class="col-xs-12" style="padding:0 4% 0 1%;height:100%">

                <angular2-multiselect id="ShareViewUsers"
                                      [data]="dropdownList" [settings]="UsersDropdownSetting"
                                      name="permissionselectedItems"
                                      [(ngModel)]="PermissionViewUserItems"
                                      (onSelect)="onViewUsersItemSelect($event)"
                                      (onDeSelect)="onViewUsersItemSelect($event)"
                                      (onSelectAll)="onViewUsersItemSelect($event)"
                                      (onDeSelectAll)="onViewUsersItemSelect($event)">
                </angular2-multiselect>

              </div>

              <!--<div class="col-xs-12">

              <angular2-multiselect [data]="accountgroupList"
                                    name="viewgroupselectedItems"
                                    [(ngModel)]="viewgroupselectedItems"
                                    (onSelect)="onviewgroupItemSelect($event)"
                                    (onDeSelect)="OnviewgroupItemDeSelect($event)"
                                    (onSelectAll)="onviewgroupSelectAll($event)"
                                    (onDeSelectAll)="onviewgroupDeSelectAll($event)">
              </angular2-multiselect>


            </div>-->

            </div>
          </div>
          <div class="col-xs-12" style="height:82%;padding: 0 4% 0 1%">
            <div class="col-xs-12" style="padding: 0; height: 100%; border:1px solid black !important">
              <div style="height:100%;padding:0;" class="col-xs-12 scroll-c" id="usrList">
                <div class="col-xs-12" *ngFor="let viewper of accountSharingViewUserlist" style="padding-left:0;">
                  <!--<i [ngClass]="viewper.Type=='viewgroup' ? 'fa fa-users' :'fa fa-user'" aria-hidden="true"></i>--> <label style="margin-left: 5px;">{{viewper.permssionuser}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>




      </div>

      <div class="col-xs-12" style="height:10%">
        <div *ngIf="(SaveEditpermissionShow ||SaveViewpermissionShow)" class="col-xs-8" style="float:right">
          <button class="btn noprovider" (click)="SavePermissionEditUser()" style="height: 29px; margin-left:10%;width:42%;text-align:center;border: 1px solid #635f5f !important;float:right;">Save</button>
          <button class="btn btn-default" (click)="CancelPermissionEditUser()" style="height: 29px;width:40%; text-align:center;border: 1px solid #635f5f !important;background:#ddd;color:black">Cancel</button>

        </div>

        <!--<div *ngIf="(SaveViewpermissionShow || SaveViewGroupPermission)">
        <button class="btn noprovider" (click)="SavePermissiontoViewUser()" style="height: 29px; margin-left:10%;width:42%;text-align:center;border: 1px solid #635f5f !important;">Save</button>
        <button class="btn btn-default" (click)="CancelPermissionViewUser()" style="height: 29px;width:40%;float:right; text-align:center;border: 1px solid #635f5f !important;background:#ddd;color:black">Cancel</button>

      </div>-->
      </div>

    </div>

    <app-dialog [(visible)]="WarningDialog">

      <div class="col-xs-12" style="text-align:center;">
        <label style="color:red;font-size:17px;text-align:center">Warning - Open Items On User</label>
        <label>
          The Uers {{userdata.username}} has the following tems open that are associated with the following Account:<br />
          <label>{{account.accountname}}</label> <br /><br /><br />
        </label><br /><br />
        Converted Lead (Sales Proess is Not Completed)<span style="color:red">[{{userdata.LeadConvertedTotal}}]</span><br /> <br />
        Tasks (Open Task Not Completed) <span style="color:red">[{{userdata.OpenTaskTotal}}]</span><br /><br />
        Opportunities (Open Opportunities Not Completed)<span style="color:red">[{{userdata.OpportunitiesTotal}}]</span><br /><br /> <br />


        Select CONTINUE to remove  {{userdata.username}}  Edit permission from this Account. All Open Items for<br />
        {{userdata.username}}  Associated with this account will be transferred to you as owner of the Account.
        <br /> <br /><br />
        <div class="col-xs-12" style="margin-top: 0%;margin-bottom: 0%;height:25%">
          <div class="col-xs-6 pull-left" style="padding-right:0;height:100%">
            <button (click)="CancelWarning()" class="btn " style="width: 60%;height: 70%;border: 1px solid black !important;">Cancel</button>
          </div>

          <div class="col-xs-6 pull-right" style="height:100%">
            <button class="btn noprovider pull-right" style="width:60%;height: 70%;border: 1px solid black !important;background: #93d250;" (click)="Continue(this.userdata.userId)">Continue</button>

          </div>
        </div>

      </div>
    </app-dialog>

  </div>
