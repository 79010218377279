<div class="col-xs-12" style="padding: 0; height: 100%;">
    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="type=='900'">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="margin-left:1%">Status Type</span>
      </h4>
      <div class="col-xs-7" style="padding:0;height:93%">
       
        <div class="col-xs-12" style="padding:0;height:100%;border:1px solid grey">
          <ag-grid-angular style="height:100%" class="ag-theme-balham"
                           [gridOptions]="gridOptions "
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="statusTypes"
                           [rowDragManaged]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (rowDragEnd)="onRowDragEnd($event)"
                           (selectionChanged)="onSelectionChanged($event)"
                           (gridReady)="onAgGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>

      <div class="col-xs-5" style="padding:0;height:93%">
        <form #AddStatusTypeForm="ngForm" style="height:100%">

          <div class="col-xs-12" style="height:12%;margin: 2% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="height:100%;padding:0;"><label> Status Type</label></div>
            <div class="col-xs-9" style="height:100%;padding:0;"><textarea name="statustype.statustypeName" [(ngModel)]="statustype.statustypeName" [required]="createNewStatusType" [disabled]="!createNewStatusType" style="width:100%;height:100%;"></textarea></div>
          </div>
          <div class="col-xs-12" style="height:50%;margin:1% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="padding:0;height:100%"><label>Status Item</label></div>
            <div class="col-xs-9" style="padding:0;height:100%">
              <table class="c" border="1" style="width:100%;text-align:center">
                <tr style="font-weight:800;font-size:13px;"><td>Description</td><td>Sort Order</td><td>Create Note</td><td>Action</td></tr>
                <tr *ngFor="let StatueItem of StatusItems">
                  <td>{{StatueItem.StatusItemName}}</td>
                  <td>{{StatueItem.SortOrder}}</td>
                  <td>{{StatueItem.CreateNote}}</td>
                  <td><img src="../../../assets/img/Grey Trash.png" style="width:15%" /></td>
                </tr>

              </table>
            </div>
          </div>
          <div class="col-xs-12" style="padding:0;height:7%;margin:1% 2% 2% 5%;width:95%">
            <div class="col-xs-2" style="padding:0;height:100%"><label>Sort Order</label></div>
            <div class="col-xs-9" style="padding:0;height:100%"><input type="text" [(ngModel)]="statustype.SortOrder" name="MessageTypes.Sortorder" [disabled]="!createNewStatusType" [required]="createNewStatusType" style="width:100%;height:100%" /></div>
          </div>

        </form>

      </div>
    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="type=='901'">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span>Trigger Types</span>
      </h4>
      <div class="col-xs-7" style="padding:0;height:93%">
        <div class="col-xs-12" style="padding:0;height:100%;border:1px solid grey">
          <ag-grid-angular style="height:100%" class="ag-theme-balham"
                           [gridOptions]="triggergridOptions "
                           [columnDefs]="TriggerColumnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="triggerTypes"
                           [rowDragManaged]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (rowDragEnd)="onRowTriggerDragEnd($event)"
                           (selectionChanged)="onTriggerChanged($event)"
                           (gridReady)="onTriggerAgGridReady($event)">
          </ag-grid-angular>

        </div>
      </div>

      <div class="col-xs-5" style="padding:0;height:93%">
        <form #AddTriggerTypeForm="ngForm" style="height:100%">

          <div class="col-xs-12" style="height:12%;margin: 2% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="height:100%;padding:0;"><label> Trigger Type</label></div>
            <div class="col-xs-9" style="height:100%;padding:0;">
              <textarea [required]="createTriggerType" name="triggertype.TriggerType"
                        [(ngModel)]="triggertype.TriggerType" [disabled]="!createTriggerType" style="width:100%;height:100%;"></textarea>
            </div>
          </div>

          <div class="col-xs-12" style="height:12%;margin: 2% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="height:100%;padding:0;"><label> Description of Trigger</label></div>
            <div class="col-xs-9" style="height:100%;padding:0;"><textarea name="triggertype.TriggerTypeDescription" [(ngModel)]="triggertype.TriggerTypeDescription" [required]="createTriggerType" [disabled]="!createTriggerType" style="width:100%;height:100%;"></textarea></div>
          </div>

        </form>
      </div>
    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="type=='902'">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="margin-left:1%">Sales Stages</span>
      </h4>
      <div class="col-xs-7" style="padding:0;height:93%">
        <div class="col-xs-12" style="height:7%;padding:0;">
          <div class="col-xs-6" style="text-align:left;padding:1% 0 0 0;">

            <img src="../../../assets/img/Create New Document Name.png" (click)="CreateSalesStage()" style="height:30px" />
          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:86%;border:1px solid grey">
          <ag-grid-angular style="height:100%" class="ag-theme-balham"
                           [gridOptions]="SalesStageGridOptions "
                           [columnDefs]="SalesStageColumnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="allSalesStages"
                           [rowDragManaged]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (rowDragEnd)="onSaleStageRowDragEnd($event)"
                           (selectionChanged)="onSalesStageSelectionChanged($event)"
                           (gridReady)="onsalesstageAgGridReady($event)">
          </ag-grid-angular>
        </div>

      </div>

      <div class="col-xs-5" style="padding:0;height:93%">
        <form #SalesStagesForm="ngForm" style="height:100%">
          <div class="col-xs-12" style="height:6%;padding:0;">

          </div>
          <div class="col-xs-12" style="height:12%;margin: 2% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="height:100%;padding:0;"><label> Sales Stage</label></div>
            <div class="col-xs-9" style="height:100%;padding:0;"><textarea name="SalesStages.SaleStage" [(ngModel)]="SalesStages.SaleStage" [required]="createSalesStage" style="width:100%;height:100%;" [disabled]="!createSalesStage"></textarea></div>
          </div>
          <div class="col-xs-12" style="height:12%;margin: 2% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="height:100%;padding:0;"><label> Forecast Description</label></div>
            <div class="col-xs-9" style="height:100%;padding:0;"><textarea name="SalesStages.ForeCastDescription" [(ngModel)]="SalesStages.ForeCastDescription" [required]="createSalesStage" [disabled]="!createSalesStage" style="width:100%;height:100%;"></textarea></div>
          </div>
          <div class="col-xs-12" style="height:6%;margin:1% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="padding:0;height:100%"><label>Skip Stage</label></div>
            <div class="col-xs-9" style="padding:0;height:100%">
              <select class="form-control" id="MessageType" name="SalesStages.SkipStage" [(ngModel)]="SalesStages.SkipStage" (change)="completeForm();handleClick($event)" [disabled]="!createSalesStage" [required]="createSalesStage">
                <option value="1" [selected]="true">Yes</option>
                <option value="0" [selected]="true">No</option>
              </select>
            </div>
          </div>
          <div class="col-xs-12" style="height:6%;margin:1% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="padding:0;height:100%"><label>Sort Order</label></div>
            <div class="col-xs-9" style="padding:0;height:100%"><input type="text" name="SalesStages.SortOrder" [(ngModel)]="SalesStages.SortOrder" [required]="createSalesStage" [disabled]="!createSalesStage" style="width:100%;height:100%" /></div>
          </div>

          <div class="col-xs-12" style="padding:0;margin-top:2%;height:6%">
            <div class="col-xs-7"></div>
            <div class="col-xs-2" style="display:block;padding:0">
              <button class="btn noprovider" style="width:100%;background: lightgray;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="ClearSalesStageForm()">Cancel</button>
            </div>
            <div class="col-xs-2 pull-right" style="display:block;padding:0;margin-right:1%">
              <button class="btn noprovider" (click)="SaveSalesStage()" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" [disabled]="(!SalesStagesForm.form.valid)">Save</button>
            </div>
          </div>
        </form>

      </div>
    </div>
  
</div>
<app-dialog [(visible)]="DeleteConfirmationPopup" *ngIf="DeleteConfirmationPopup" id="DeleteConfirmationPopup" [closable]="false">
  <app-delete-popup [title]="(StatusTypeDiv)? 'Status Type' : ((TriggerTypesDiv)? 'Trigger Type' : 'Sales Stage')" [description]="(StatusTypeDiv)? 'Delete Status Type' : ((TriggerTypesDiv)? 'Delete Trigger Type' : 'Delete Sales Stage')" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteevent($event)">
  </app-delete-popup>
  <!--<app-delete-popup [status]="contactStatus" [MessageType]="(StatusTypeDiv)? 'Status Type' : ((TriggerTypesDiv)? 'Trigger Type' : 'Sales Stage')" (sendMsgEvent)="ReceiveDeleteevent($event)">
  </app-delete-popup>-->
</app-dialog>
