<div class="col-xs-12" style="padding: 0; height: 100%; " >
 
    <div class="col-xs-12" style="padding:0;height:100%;" *ngIf="CampaignConfigMainDiv">
      <div class="col-xs-12" style="padding:0;height:10%;">
        <h4 class="fontStyleCss" style="margin-top:0">
          <span style="text-transform: uppercase;">Campaign Configuration</span>
        </h4>
      </div>

      <div class="col-xs-12" style="height:90%;padding:0 1% 0 0">
        <div class="col-xs-12" style="padding:0;height:20%">
          <div class="col-xs-1" style="padding:0;height:100%;width:4%"><label style="font-weight:bold" (click)="AccessDiv('CampaignTypes')">
            <img src="../../../assets/img/System Config.png" style="height:30px" /></label></div>

          <div class="col-xs-11" style="padding:0;height:100%;">
            <div class="col-xs-12" style="padding:0;height:30%">
              <div class="col-xs-12" style="padding:0;height:100%">
              <label style="font-weight:bold;text-decoration:underline;">Campaign Types</label></div>

            </div>

            <div class="col-xs-12" style="padding:0;height:70%">
              <label style="font-weight:normal">You are able to add, edit or delete types of campaigns.</label>
            </div>

          </div>
        </div>

        <div class="col-xs-12" style="padding:0;height:20%">
          <div class="col-xs-1" style="padding:0;height:100%;width:4%"><label style="font-weight:bold" (click)="AccessDiv('CampaignExpenses')">
            <img src="../../../assets/img/System Config.png" style="height:30px" /></label></div>

          <div class="col-xs-11" style="padding:0;height:100%;">
            <div class="col-xs-12" style="padding:0;height:30%">
              <div class="col-xs-12" style="padding:0%;height:100%"><label style="font-weight:bold;text-decoration:underline;">Campaign Expenses</label></div>

            </div>

            <div class="col-xs-12" style="padding:0% ;height:70%">
              <label style="font-weight:normal">You are able to add, edit or delete campaign expenses.</label>
            </div>

          </div>
        </div>
      </div>

    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="type=='600'">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="margin-left:1%">Campaign Types</span>
      </h4>
      <div class="col-xs-7" style="padding:0;height:93%">
        <div class="col-xs-12" style="padding:0;height:6%">
          <div class="col-xs-4 pull-left" style="padding:0;margin-right:1%">

            <img src="../../../assets/img/Create New Document Name.png" (click)="ShowForm('CampaignTypes')" style="height:30px" />
          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:94%;border:1px solid grey">
         <ag-grid-angular style="height:100%" class="ag-theme-balham"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="AllCampaignTypes"
                           [rowDragManaged]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (selectionChanged)="onCampaignTypeSelectionChanged($event)"
                           (rowDragEnd)="onRowDragEnd($event)"
                           (gridReady)="onAgGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>

      <div class="col-xs-5" style="padding:0;height:93%">
        <form #CampaignTypeForm="ngForm" style="height:100%">
          <div class="col-xs-12" style="height:4%;padding:0;">

          </div>
          <div class="col-xs-12" style="height:12%;margin: 2% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="height:100%;padding:0;"><label> Type</label></div>
            <div class="col-xs-9" style="height:100%;padding:0;"><textarea name="CampaignType.CampaignTypeName" [(ngModel)]="CampaignType.CampaignTypeName" style="width:100%;height:100%;" [disabled]="CampaignTypeFormDisable"></textarea></div>
          </div>
          <div class="col-xs-12" style="height:12%;margin:1% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="padding:0;height:100%"><label>Sort Order</label></div>
            <div class="col-xs-9" style="padding:0;height:100%">
              <input type="text" name="CampaignType.SortOrder" [(ngModel)]="CampaignType.SortOrder" [disabled]="CampaignTypeFormDisable" />
            </div>
          </div>
          <div class="col-xs-12" style="padding:0;height:55%"></div>
          <div class="col-xs-12" style="padding:0;margin-top:2%;height:6%">
            <div class="col-xs-7"></div>
            <div class="col-xs-2" style="display:block;padding:0">
              <button class="btn noprovider" style="width:100%;background: lightgray;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="ClearForm('CampaignTypes')">Cancel</button>
            </div>
            <div class="col-xs-2 pull-right" style="display:block;padding:0;margin-right:1%">
              <button class="btn noprovider" (click)="SaveCampaignType()" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" [disabled]="(!CampaignTypeForm.form.valid)">Save</button>
            </div>
          </div>

        </form>

      </div>

    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="type=='601'">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="margin-left:1%">Campaign Expenses</span>
      </h4>

      <div class="col-xs-7" style="padding:0;height:93%">
        <div class="col-xs-12" style="padding:0;height:6%">
          <div class="col-xs-4 pull-left" style="padding:0;margin-right:1%">

            <img src="../../../assets/img/Create New Document Name.png" (click)="ShowForm('CampaignExpenses')" style="height:30px" />
          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:94%;border:1px solid grey">
          <ag-grid-angular style="height:100%" class="ag-theme-balham"
                           [gridOptions]="gridOptions "
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="AllCampaignExpsnses"
                           [rowDragManaged]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (selectionChanged)="onCampaignExpenseSelectionChanged($event)"
                           (rowDragEnd)="onRowDragEndExpense($event)"
                           (gridReady)="onAgGridReadyExpense($event)">
          </ag-grid-angular>
        </div>
      </div>

      <div class="col-xs-5" style="padding:0;height:93%">
        <form #CampaignExpenseForm="ngForm" style="height:100%">
          <div class="col-xs-12" style="height:4%;padding:0;">
          </div>
          <div class="col-xs-12" style="height:12%;margin: 2% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="height:100%;padding:0;"><label> Expense</label></div>
            <div class="col-xs-9" style="height:100%;padding:0;"><textarea name="CampaignExpense.CampaignExpenseName" [(ngModel)]="CampaignExpense.CampaignExpenseName" style="width:100%;height:100%;" [disabled]="CampaignExpenseFormDisable"></textarea></div>
          </div>
          <div class="col-xs-12" style="height:12%;margin:1% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="padding:0;height:100%"><label>Sort Order</label></div>
            <div class="col-xs-9" style="padding:0;height:100%">
              <input type="text" name="CampaignExpense.SortOrder" [(ngModel)]="CampaignExpense.SortOrder" [disabled]="CampaignExpenseFormDisable" />
            </div>
          </div>
          <div class="col-xs-12" style="padding:0;height:55%"></div>
          <div class="col-xs-12" style="padding:0;margin-top:2%;height:6%">
            <div class="col-xs-7"></div>
            <div class="col-xs-2" style="display:block;padding:0">
              <button class="btn noprovider" style="width:100%;background: lightgray;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="ClearForm('CampaignExpenses')">Cancel</button>
            </div>
            <div class="col-xs-2 pull-right" style="display:block;padding:0;margin-right:1%">
              <button class="btn noprovider" (click)="SaveCampaignExpense()" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" [disabled]="(!CampaignExpenseForm.form.valid)">Save</button>
            </div>
          </div>

        </form>

      </div>
    </div>

</div>
