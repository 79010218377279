export class Campaign {

  public CampaignID: number;
  public CampaignName: string;
  public CampaignTypeId: number;
  public CampaignTypeName: string;
  public ContactsLinked: string;
  public Expenses: string;
  public ExpenseNotes: string;
  public LinkedOpportunities: string;
  public TotalExpense: number;
  public createdby: number;
  public createdByName: string;
  public CreatedDate: string;
  public isDeleted: number;
  public SalesRevenue: string;
  public MonthlyRevenue: string;
  public salesPlusMonthlyPerYear: string;
  public ProfitLoss: string;
  public CampaignStatus: string;
  public contactsNames: string;
}
