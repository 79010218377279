<div class="wrapper">
  <header class="main-header"></header>
  <div class="content-wrapper">
    <div class="container">
      <form #resetpwdform="ngForm">
        <div class="card card-container" style="text-align:center;height:460px;">
          <img alt="Home" class="header__logo-mage" src="../../assets/img/SalesXcrm Logo.jpg" style="width: 100%">
          <h4 style="margin-top: 0;">The Leader in Sales,Support and Marketing Automation</h4>
          <p id="profile-name" class="profile-name-card"></p>
          <span id="reauth-email" class="reauth-email" style="color:red;white-space: normal;"></span>
          <div>
            <label>Enter your </label>   <label style="text-decoration: underline; margin-left: 2%">  New Password</label>
            <div class="col-xs-12" style="padding:0;">
              <div class="col-xs-11" style="padding:0;">
                <input type="password" class="form-control" id="password" (keyup)="CompareOldpassword()"
                       pattern="(?=.*\W+)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,15}"
                       [(ngModel)]="user.password" name="password"
                       #password="ngModel" style="margin-top:5%;width:100%" placeholder="New Password" required>
              </div>
              <div class="col-xs-1" style="padding:0;padding-top: 2%;">
                <span id="passwordEye" class="fa fa-fw fa-eye field-icon toggle-password pull-left" (click)="showhide()" style="padding-left: 12px;margin-top: 60%;"></span>
              </div>


            </div>



            <div style="color:red" [hidden]="!password.hasError('pattern')">Password include minimum of 8 characters to include at least one alpha character,Alpha character in UPPER Case, one number and one special character ( @, #, $, %,*,etc.)</div>
            <div class="col-xs-11" style="padding:0;">
              <input type="password" class="form-control" id="confirmPassword" (keyup)="Comparepwd()"
                     validateEqual="user.password"
                     [(ngModel)]="user.confirmpassword" name="confirmPassword"
                     #confirmPassword="ngModel" style="margin-top:5%" placeholder="Confirm password" required>
            </div>
            <div class="col-xs-1" style="padding:0;padding-top: 2%;">
              <span id="passwordEye1" class="fa fa-fw fa-eye field-icon toggle-password1 pull-left" (click)="showhide1()" style="padding-left: 12px;margin-top: 60%;"></span>
            </div>

            <span id="valid" style="margin-left:8px; font-size:12px; display:none">Match</span>
            <span id="userExist" style="color:red; margin-left:8px; font-size:12px; display:none">Please provide New password</span>
            <!--<span id="errmsgdiv" style="color:red; margin-left:8px; font-size:12px; display:none">Passwords didn't match</span>-->
            <div class="col-xs-11" style="padding:0;">
              <button class="btn btn-lg coustomButton btn-block btn-signin" [disabled]="!resetpwdform.form.valid" style="margin-top:5%;margin-bottom: 5%;" (click)="updatepassword()">CONTINUE</button>
            </div>

          </div>

          </div>

      </form>
    </div>
    <router-outlet></router-outlet>
  </div>
  <footer class="main-footer"></footer>
</div>
