import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { AccountsService } from './../../services/accounts.service';
import { ColumnNames } from './../../models/column-names';
import { AdvancedSearch } from '../../models/advancedsearch';
import { RunQuery } from '../../models/run-query';
import { DatePipe } from '@angular/common';
import { DialogComponent } from '../dialog/dialog.component';
import { AdvancedTableComponent } from '../advanced-table/advanced-table.component';
import { ApplicationComponent } from '../application.component';
import { ToastrService } from 'ngx-toastr';
import { Dropdowntype } from 'src/app/models/dropdowntype';
import { AdvancedsearchService } from 'src/app/services/advancedsearch.service';
import { CustomerService } from 'src/app/services/customer.service';
import { UserService } from 'src/app/services/user.service';
declare var $: any;
 
export enum Table {
  Contact,
  Account,
  Practice,
  Leads,
  Opportunity,
  Task
}
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { EditdeleteComponent } from '../editdelete/editdelete.component';
@Component({
  selector: 'advancedsearch',
  templateUrl: './advancedsearch.component.html',
  styleUrls: ['./advancedsearch.component.css']
})
export class AdvancedsearchComponent implements OnInit {
  
  fieldsSelector: boolean = false;
  quickSearch: boolean = false;
  accountShow: boolean = false;
  contactShow: boolean = false;
  showWarningDialog: boolean = false;
  DeleteConfirmationDialog: boolean = false;
  NoRowDialog: boolean = false;
  @Input()
  showAdvancedSearchDialog;
  @Input()
  searchOnoff: string;
  searchtype = "";
  tabType = [];
  selectedColumnList = [];
  LeadsShow: boolean = false;
  opportunitiesShow: boolean = false;
  TaskShow: boolean = false;
  valueSelector: boolean = false;
  emailFormArray: Array<any> = [];
  accountMap: Map<string, string>;
  contactColumns: Array<ColumnNames> = new Array<ColumnNames>();
  leadColumns: Array<ColumnNames> = new Array<ColumnNames>();
  OpportunityColumns: Array<ColumnNames> = new Array<ColumnNames>();
  TaskColumns: Array<ColumnNames> = new Array<ColumnNames>();
  accountColumns: Array<ColumnNames> = new Array<ColumnNames>();
  quickSearchColumns: Array<ColumnNames> = new Array<ColumnNames>();
  quickSearchTableDisplayName: string = "";
  quickSearchTableName: string = "";
  lhsSelectors: string[] = ['Equals', 'Is Not', 'Is Not Empty', 'Is Empty'];
  queryList: AdvancedSearch[] = [];
  queryName: string = "";
  editquerydate: string = "";
  fromadded: boolean = false;
  EditConfirmationDialog: boolean = false;
  editQryId: number = -1;
  RunStatus: string = "";
  fieldsSelectorSearchFilter = "";
  tablealias: string = "";
  accounttypeList: Dropdowntype[] = [];
  customertypeList: Dropdowntype[] = [];
  quickSearchFilter = "";
  datePipe: DatePipe = new DatePipe("en-US");
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  currentuser: any;
  @ViewChild(DialogComponent) dlgComp;
  query: RunQuery = new RunQuery();
  @ViewChild(AdvancedTableComponent) advcmp;
  selecteindex: number;
  selectstatus: number;
  selectedQueryCount: number = 0;
  public ContactTableColumns: any;
  public AccountTableColumns: any;
  andactive: boolean = true; andactiveval: string = "AND";
  public gridOptions: GridOptions;
  public sideBar;
  public frameworkComponents;
  public context;;
  public columnDefs: any[];
  public noRowsTemplate; public currentdatetime = new Date();
  closeOpenDialog: boolean = false;
  constructor(private advancedSearchService: AccountsService, private router: Router, private customerService: CustomerService, private userService: UserService,
    private advsearch: AdvancedsearchService, private toastr: ToastrService, public applicationComponent: ApplicationComponent) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.noRowsTemplate =
      `"<span">bbbbbbbb no rows to show</span>"`;
  }

  ngOnInit() {
    this.GetAdvancedsearchQueries();
  }
  GetAdvancedsearchQueries() {
    this.advancedSearchService.getAdvancedSearchQueries("qryname", this.currentuser.id).then((queries) => {
      queries.forEach(queries => {
        queries.createdate = this.datePipe.transform(queries.createdate, "MM/dd/yyyy");
      });
      this.queryList = queries;
      this.initializeAgGrid();
    });
  }
  onAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();    
    }, 400));
  }
  autoSizeAll() {
    if (this.gridOptions && this.gridOptions.columnApi) {
      this.gridOptions.columnApi.autoSizeAllColumns();
    }
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, paginationPageSize: 1000, enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true,
      rowSelection: 'multiple', enableCellChangeFlash: true, rowDragManaged: true, animateRows: true, suppressSorting: true, suppressMultiSort: true
      //pagination: true, enableColResize: true, paginationPageSize: 1000, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true,
      //toolPanelSuppressPivotMode: true, rowSelection: 'multiple', enableCellChangeFlash: true
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
   

    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeleteComponent
    };;
    this.createColumnDefs();
  }
  createColumnDefs() {
    let selfObj = this;
    selfObj.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },     
      { field: 'Id', headerName: 'Id', hide: true },      
      {
        field: 'QryName', headerName: 'Query Name', sortable: true, hide: false        
      },
      {
        field: 'RunStatus', headerName: 'Status', sortable: true, hide: false,
        cellRenderer(params) {
          if (params.value == null) { return "Closed" }
          else { return params.value }
        },
        cellStyle: function (params) {
          if (params.data.RunStatus == "Open") { return { color: '#FF0000' }; } else { return { color: '#000000' }; }
        }
      },
      {
        field: 'createdate', headerName: 'Create Date', sortable: true, width: 90, hide: false
        
      },
      {
        field: 'edit', headerName: 'Edit', hide: false, width: 70,cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<i class="fa fa-pencil" aria-hidden="true"></i>';
          eDiv.addEventListener('click', function () {
          selfObj.editQuery(params.data);
          });
          return eDiv;
        }
      },
       {
         field: 'delete', headerName: 'Delete', hide: false, width: 80,cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true"></i>';
           eDiv.addEventListener('click', function () {
             selfObj.deleteQuery(params.data.Id,1, params.data.RunStatus);
          });
          return eDiv;
        }
      }
    ];
  }
  changeQueryParam(param) {
    this.advancedSearchService.getAdvancedSearchQueries(param, this.currentuser.id).then((queries) => {
      queries.forEach(queries => {
        queries.createdate = this.datePipe.transform(queries.createdate, "MM/dd/yyyy");
      });
      this.queryList = queries;
    });
  };
  SelectionChanged(val) {
    if (val == "AND") {
      this.andactive = true;
      this.andactiveval = 'AND';
    }
    else {
      this.andactive = false;
      this.andactiveval = 'OR';
    }
  }
  Open() {
    localStorage.setItem('selectedQueryCount', this.selectedQueryCount.toString());
    this.sendMsgEvent.emit("DisplaySearchResult");
    this.applicationComponent.AdvancedSearchResult = true;
    this.applicationComponent.StandardpopupUi('#AdvancedSearchResult .dialog');
  }
  addNewQuery(event) {
    this.fieldsSelectorSearchFilter = "";
    this.searchtype = "AdvancedSearch";
    this.tabType = [];
    this.editQryId = -1;
    this.queryName = "";
    this.fromadded = false;
    this.accountShow = false;
    this.contactShow = false;
    this.LeadsShow = false;
    this.opportunitiesShow = false;
    this.TaskShow = false;
 
    this.accountColumns = [];
    if (this.accountColumns.length == 0)
      this.mapColumnNamesWithDisplayNames(this.accountColumns, Table.Account);
    else {
      this.accountColumns.forEach(col => {
        col.distinctValues = [];
        col.isSelected = false;
      });
    }

    if (this.contactColumns.length == 0)
      this.mapColumnNamesWithDisplayNames(this.contactColumns, Table.Contact);
    else {
      this.contactColumns.forEach(col => {
        col.distinctValues = [];
        col.isSelected = false;
      });
    }

    if (this.leadColumns.length == 0)
      this.mapColumnNamesWithDisplayNames(this.leadColumns, Table.Leads);
    else {
      this.leadColumns.forEach(col => {
        col.distinctValues = [];
        col.isSelected = false;
      });
    }

    if (this.OpportunityColumns.length == 0)
      this.mapColumnNamesWithDisplayNames(this.OpportunityColumns, Table.Opportunity);
    else {
      this.OpportunityColumns.forEach(col => {
        col.distinctValues = [];
        col.isSelected = false;
      });
    }

    if (this.TaskColumns.length == 0)
      this.mapColumnNamesWithDisplayNames(this.TaskColumns, Table.Task);
    else {
      this.TaskColumns.forEach(col => {
        col.distinctValues = [];
        col.isSelected = false;
      });
    }

   // this.queryLanding = false;
    this.quickSearch = false;
    this.valueSelector = false;
    this.fieldsSelector = true;
  }

  btnCancelFieldsSelectorClick($event) {
    $("input[name='foo']:checkbox").prop('checked', false);
    this.quickSearch = false;
    this.valueSelector = false;
    this.fieldsSelector = false;
   // this.queryLanding = true;
    this.accountShow = false;
    this.contactShow = false;

  }

  lblAccountHideShow(event) {
    this.accountShow = !this.accountShow;
    if (event.target.checked) {
      if (!this.tabType.includes('Account'))
        this.tabType.push('Account');
    } else {
      if (!this.selectedColumnList.find(x => x.type == "Account")) {
        this.tabType = this.tabType.filter(x => x != 'Account');
      }
    }

  }

  lblContactHideShow(event) {
    this.contactShow = !this.contactShow;
    if (event.target.checked) {
      if (!this.tabType.includes('Contact'))
        this.tabType.push('Contact');
    } else {
      if (!this.selectedColumnList.find(x => x.type == "Contact")) {
        this.tabType = this.tabType.filter(x => x != 'Contact');
      }
    }
    if (this.contactColumns.length == 0)
      this.mapColumnNamesWithDisplayNames(this.contactColumns, Table.Contact);
  }
  lblLeadHideShow(event) {
    this.LeadsShow = !this.LeadsShow;
    this.tabType.push('Leads');
  }

  lblOpportunitiesHideShow(event) {
    this.opportunitiesShow = !this.opportunitiesShow;
    this.tabType.push('Opportunity');
  }

  lblTaskHideShow(event) {
    this.TaskShow = !this.TaskShow;
    this.tabType.push('Task');
  }

  rblTblSelected(event) {
    $('#btnQuickSearchShow').removeAttr('disabled');
  }

  mapColumnNamesWithDisplayNames(columns: Array<ColumnNames>, table: Table) {
  
    if (table == Table.Contact) {
      this.customerService.GetTableFields('Contacts', this.currentuser.id).then(result => {
        this.ContactTableColumns = result;
        this.ContactTableColumns.forEach(ele => {
          if (ele.ColumnName != 'photo') {
            columns.push({ columnName: ele.ColumnName, displayName: ele.DisplayAs, distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: 'and' });
          }
        });
      });
    }
    else if (table == Table.Account) {
   
      this.customerService.GetTableFields('Accounts', this.currentuser.id).then(result => {
        this.AccountTableColumns = result;
      
        this.AccountTableColumns.forEach(ele => {
          columns.push({ columnName: ele.ColumnName, displayName: ele.DisplayAs, distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: 'and' });
        });

        columns.push({ columnName: 'accountid123', displayName: 'Status ID', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: 'and' });
      });
    }    
    columns.sort((a, b) => a.displayName.localeCompare(b.displayName));

  }


  mapColumnNamesWithDisplayNames2(columns: Array<ColumnNames>, table: Table) {

    if (table == Table.Contact) {
      columns.push({ columnName: 'contacttype', displayName: 'Contact Type', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'firstname', displayName: 'First Name', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'middlename', displayName: 'Middle Name', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'lastname', displayName: 'Last Name', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'suffix', displayName: 'Suffix', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'sex', displayName: 'Sex', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'title', displayName: 'Title', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'speciality', displayName: 'Specialty', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'subspeciality', displayName: 'Subspeciality', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'email', displayName: 'Email', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'address1', displayName: 'Address1', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'address2', displayName: 'Address2', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'city', displayName: 'City', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'state', displayName: 'State', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'zip', displayName: 'ZIP', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'cell', displayName: 'Cell #', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'office', displayName: 'Office #', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'DOB', displayName: 'DOB', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'homeaddress', displayName: 'Home Address', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'homecity', displayName: 'Home City', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'homestate', displayName: 'Home State', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'homezip', displayName: 'Home Zip', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });

      columns.push({ columnName: 'college', displayName: 'College', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'collegegrandyear', displayName: 'C.G.Year Completed', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'medschool', displayName: 'Medschool', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'medschoolgrandyear', displayName: 'M.G.year Completed', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'residency', displayName: 'Residency', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'residencygrandyear', displayName: 'R.G.year Completed', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'fellowship', displayName: 'Fellowship', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'fellowshipgrandyear', displayName: 'F.G.year Completed', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });

    } else if (table == Table.Account) {
   
      columns.push({ columnName: 'customertypeid', displayName: 'Customer Type', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'accnumber', displayName: 'Account Number', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'accounttype', displayName: 'Account Type', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'ownership', displayName: 'Owner Ship', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'accountname', displayName: 'Account Name', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'speciality', displayName: 'Specialty', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'address1', displayName: 'Address1', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'address2', displayName: 'Address2', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'city', displayName: 'City', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'state', displayName: 'State', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'zip', displayName: 'Zip', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'country', displayName: 'Country', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      //columns.push({ columnName: 'ProvidenceIsland', displayName: 'Providence/Island', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals" , andOrOperator:"and"});
      columns.push({ columnName: 'phone', displayName: 'Phone', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'fax', displayName: 'Fax', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'alterphone1', displayName: 'Alter Phone 1', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'alterphone2', displayName: 'Alter Phone 2', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'ehr', displayName: 'EHR', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'ehrdatepurchased', displayName: 'EHR Date Purchased', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'pm', displayName: 'PM', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'pmdatepurchased', displayName: 'PM Date Purchased', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'rcm', displayName: 'RCM', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'rcmdatepurchased', displayName: 'RCM Date Purchased', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'Website', displayName: 'Website', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'owner', displayName: 'Owner', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'MultipleLocations', displayName: 'Multiple Locations', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'accountid123', displayName: 'Status ID', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
    }
    else if (table == Table.Leads) {
      columns.push({ columnName: 'salesstage', displayName: 'Sales Stage', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'status', displayName: 'Status', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
      columns.push({ columnName: 'completeddate', displayName: 'Date Completed', distinctValues: [], isSelected: false, selectedVal: "", operator: "Equals", andOrOperator: "and" });
    }
  }

  btnQuickSearchShowContent(event) {

    this.searchtype = "QuickSearch";
    this.accountColumns = [];
    this.contactColumns = [];
    this.mapColumnNamesWithDisplayNames2(this.accountColumns, Table.Account);
    this.mapColumnNamesWithDisplayNames2(this.contactColumns, Table.Contact);

    this.quickSearchFilter = "";
    // this.searchOrShow("", false,"");  //show content

    //this.queryLanding = false;
    this.fieldsSelector = false;
    this.valueSelector = false;
    this.quickSearch = true;
  }

  btnQuickSearchCancelClick(event) {
    this.fieldsSelector = false;
    this.valueSelector = false;
    this.quickSearch = false;
   // this.queryLanding = true;
  }

  btnQuickSearchClick(event) {
    localStorage.setItem('selectedQueryCount', this.selectedQueryCount.toString());
    let buildSearchCriteria: string = "select * from";
    let wherequery: string = " ";
    this.quickSearchTableName = "";
    let columns: Array<ColumnNames>;
    let key: string;
    let selectedTbl = [];
    localStorage.removeItem("QuickSearchQuery");
    if ($('#rblAccount').prop("checked")) {
    
      this.quickSearchTableName += " tblaccounts as acc";
      for (let i = 0; i < this.accountColumns.length; i++) {
        var column = $('#AcctxtQuickSearchCol' + i).val().trim();
        if (column != "") {
          let colName: string = this.accountColumns[i].columnName;
          let searchCriteria: string = $('#AcctxtQuickSearchCol' + i).val();

          wherequery += " and ";

          if (searchCriteria != "%%")
            wherequery += "acc." + colName + " = '" + searchCriteria + "'";
          else
            wherequery += " " + colName + " '";
          if (!selectedTbl.includes("Accounts"))
            selectedTbl.push("Accounts");
        }
      }
    }
    if ($('#rblContact').prop("checked")) { //contact
    
      this.quickSearchTableName += " tblcontacts as con";

      for (let j = 0; j < this.contactColumns.length; j++) {
        var column = $('#ContxtQuickSearchCol' + j).val().trim();
        if (column != "") {
          let colName: string = this.contactColumns[j].columnName;
          let searchCriteria: string = $('#ContxtQuickSearchCol' + j).val();

          wherequery += " and ";

          if (searchCriteria != "%%")
            wherequery += "con." + colName + " ='" + searchCriteria + "'";
          else
            wherequery += " " + colName + " '";

          if (!selectedTbl.includes("Contacts"))
            selectedTbl.push("Contacts");
        }
      }
    }

    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (selectedTbl.length > 1) {
      buildSearchCriteria += "  tblcontacts as con  join tblaccounts as acc on con.accountid=acc.id  where con.userid = " + currentUser.id;
    }
    else if (selectedTbl.length == 1 && selectedTbl.includes("Accounts")) {
      buildSearchCriteria += "  tblaccounts as acc where acc.userid = " + currentUser.id;
    }
    else if (selectedTbl.length == 1 && selectedTbl.includes("Contacts")) {
      buildSearchCriteria += "  tblcontacts as con where con.userid = " + currentUser.id;
    }


    var qryName = "QuickSearch";
    buildSearchCriteria += wherequery;
    var url = window.location.href;
    if (url.indexOf('search-result') > -1) {
      this.sendMsgEvent.emit("changed");
    } else {
      this.router.navigateByUrl(`/application/search-result/0/QuickSearch`);
    }
    localStorage.setItem('QuickSearchQuery', buildSearchCriteria)
    $('.dialog__close-btn').click();

    this.sendMsgEvent.emit("Cancel")
  }

  closeAdvacedDialog() {
    this.applicationComponent.activateMainButtons('dashboards');
    this.applicationComponent.showAdvancedSearchDialog = false;
  }

  closeAdvaced() {  
    //if (this.queryLanding) {
    //  this.sendMsgEvent.emit("Cancel");
    //} else {
      if (this.searchtype == "AdvancedSearch") {
        this.showWarningDialog = true;
      }
      setTimeout(function () {
        $('#Ban .dialog').attr('style', 'width: 23%;padding-bottom: 0px;border: 4px solid red;min-height: 11%;overflow: auto;height: 23%;top: 39%;left: 73%;padding:0;');

      }, 100);
    //}
    $('input[name=foo]').prop("checked", false);
    this.applicationComponent.activateMainButtons(localStorage.getItem('Tab'));
    if (localStorage.getItem('SubTab') != null && localStorage.getItem('SubTab') != "") {
      if (localStorage.getItem('Tab') == "leads") {
        localStorage.getItem('SubTab') == "Open" ? this.applicationComponent.ClosedLeads('Open', this.currentuser.id, 'Open Leads') : localStorage.getItem('SubTab') == "Completed" ? this.applicationComponent.ClosedLeads('Completed', this.currentuser.id, 'Closed Leads') : localStorage.getItem('SubTab') == "All" ? this.applicationComponent.ClosedLeads('All', this.currentuser.id, 'All Leads') : this.applicationComponent.ClosedLeads('Forecasting', this.currentuser.id, 'Forecasting');
      }
      else if (localStorage.getItem('Tab') == "tasklist") {
        localStorage.getItem('SubTab') == "Open" ? this.applicationComponent.TaskData('Open', this.currentuser.id) : localStorage.getItem('SubTab') == "Completed" ? this.applicationComponent.TaskData('Completed', this.currentuser.id) : localStorage.getItem('SubTab') == "All" ? this.applicationComponent.TaskData('All', this.currentuser.id) :
          this.applicationComponent.TaskData('New', this.currentuser.id);
      }
     
      else if (localStorage.getItem('Tab') == "Calender") {
        localStorage.getItem('SubTab') == "TaskCalander" ? this.applicationComponent.SubmenuChange('Calander', 'TaskCalander', 'Sub') : this.applicationComponent.SubmenuChange('Calander', 'ViewCalander', 'Sub');
      }
      else if (localStorage.getItem('Tab') == "zendesk") {
        localStorage.getItem('SubTab') == "Open" ? this.applicationComponent.TicketData('Open', this.currentuser.id, 'OpenTickets') : localStorage.getItem('SubTab') == "Completed" ? this.applicationComponent.TicketData('Completed', this.currentuser.id, 'ClosedTickets') : localStorage.getItem('SubTab') == "All" ? this.applicationComponent.TicketData('All', this.currentuser.id, 'AllTickets') : this.applicationComponent.TicketData('New', this.currentuser.id, 'AddTickets');
      }
      else if (localStorage.getItem('Tab') == "ResponseWizard") {
        localStorage.getItem('SubTab') == "EditResponseWizard" ? this.applicationComponent.activateSubButtons('EditResponseWizard') : this.applicationComponent.activateSubButtons('ResponseWizard');
      }
      else if (localStorage.getItem('Tab') == "rhx") {
        localStorage.getItem('SubTab') == "Today" ? this.applicationComponent.SubmenuChange('Rhx', 'Today', 'Sub') : localStorage.getItem('SubTab') == "Yesterday" ? this.applicationComponent.SubmenuChange('Rhx', 'Yesterday', 'Sub') : localStorage.getItem('SubTab') == "Weekly" ? this.applicationComponent.SubmenuChange('Rhx', 'Weekly', 'Sub') : localStorage.getItem('SubTab') == "Year" ? this.applicationComponent.SubmenuChange('Rhx', 'Year', 'Sub') : this.applicationComponent.SubmenuChange('Rhx', 'Today', 'Sub');
      }
      else if (localStorage.getItem('Tab') == "saletool") {
        localStorage.getItem('SubTab') == "ResponseWizard" ? this.applicationComponent.SubmenuChange('SalesTool', 'ResponseWizard', 'Sub') : localStorage.getItem('SubTab') == "ImportDataWizard" ? this.applicationComponent.SubmenuChange('SalesTool', 'ImportDataWizard', 'Sub') : localStorage.getItem('SubTab') == "OpportunitiesWonApproval" ? this.applicationComponent.SubmenuChange('SalesTool', 'OpportunitiesWonApproval', 'Sub') : this.applicationComponent.SubmenuChange('SalesTool', 'ResponseWizard', 'Sub');
      }
      else if (localStorage.getItem('Tab') == "systemConfig") {
        localStorage.getItem('SubTab') == "451" ? this.applicationComponent.SubmenuChange('Config', '451', 'Sub') : localStorage.getItem('SubTab') == "426" ? this.applicationComponent.SubmenuChange('Config', '426', 'Sub') : this.applicationComponent.SubmenuChange('Config', '420', 'Sub');
      }
      else {
        this.applicationComponent.activateSubButtons(localStorage.getItem('SubTab'));
      }
    }
  }

  showWarningDialogCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width: 13.5%;padding-bottom: 0px;border:none; min-height:11%; overflow:auto; height:13%;left:30%;top:0.1%;text-align:center;background-color:#f4f4f4');

    }, 4);
  }
  showWarningDialogClose() {
    this.showWarningDialog = !this.showWarningDialog;
  }
  searchOrShow(searchValue: string, isSearch: boolean, searchtype: string) {

    if (document.getElementById('contact_span').className == 'mainCheckMark activeColor') {

      if (document.getElementById('a-z_button').className == 'subButtons activeButton') {
        if (isSearch)
          this.router.navigateByUrl(`/application/contacts/contacts/${searchValue}`);
        else {
          if (this.contactColumns.length == 0)
            this.mapColumnNamesWithDisplayNames(this.contactColumns, Table.Contact);
          this.quickSearchTableName = "tblcontacts con";
          this.quickSearchTableDisplayName = "Contacts";
          this.quickSearchColumns = this.contactColumns;
        }
      } else if (document.getElementById('allaccount_button').className == 'subButtons activeButton') {
        if (isSearch)
          this.router.navigateByUrl(`/application/account/Contact/${searchValue}`);
        else {
          if (this.accountColumns.length == 0)
            this.mapColumnNamesWithDisplayNames(this.accountColumns, Table.Account);
          this.quickSearchTableName = "tblaccounts acc";
          this.quickSearchTableDisplayName = "Accounts";
          this.quickSearchColumns = this.accountColumns;
        }
      }

    }
    else if (document.getElementById('customers_span').className == 'mainCheckMark activeColor') {
      if (searchValue != "") {
        if (document.getElementById('a-z_button').className == 'subButtons activeButton') {
          this.router.navigateByUrl(`/application/customercontacts/${searchValue}`);
        }

        else if (document.getElementById('allaccount_button').className == 'subButtons activeButton') {
          this.router.navigateByUrl(`/application/account/Customer/${searchValue}`);
        }
      } else {
        if (document.getElementById('a-z_button').className == 'subButtons activeButton') {
          if (isSearch)
            this.router.navigateByUrl(`/application/customer/customer`);
          else {
            if (this.accountColumns.length == 0)
              this.mapColumnNamesWithDisplayNames(this.accountColumns, Table.Account);
            this.quickSearchTableName = "tblaccounts acc";
            this.quickSearchTableDisplayName = "Accounts";
            this.quickSearchColumns = this.accountColumns;
          }
        } else if (document.getElementById('allaccount_button').className == 'subButtons activeButton') {
          if (isSearch)
            this.router.navigateByUrl(`/application/account/${searchValue}`);
          else {
            if (this.contactColumns.length == 0)
              this.mapColumnNamesWithDisplayNames(this.accountColumns, Table.Account);
            this.quickSearchTableName = "tblaccounts acc";
            this.quickSearchTableDisplayName = "Accounts";
            this.quickSearchColumns = this.accountColumns;
          }
        }
      }
    }
    else if (document.getElementById('tasklist_span').className == 'mainCheckMark activeColor') {
      if (isSearch)
        this.router.navigateByUrl(`/application/tasklistdashboard/null/0/null/${searchValue}`);
      else {
        if (this.leadColumns.length == 0)
          this.mapColumnNamesWithDisplayNames(this.TaskColumns, Table.Task);
        this.quickSearchTableName = "tbltaskhistory tas";

        this.quickSearchTableDisplayName = "Task";
        this.quickSearchColumns = this.TaskColumns;
      }
    }

    else if (document.getElementById('opportunities_span').className == 'mainCheckMark activeColor') {

      if (isSearch)
        this.router.navigateByUrl(`/application/opportunitiesdashboard/0/0/${searchValue}/null`);
      else {
        if (this.OpportunityColumns.length == 0)
          this.mapColumnNamesWithDisplayNames(this.OpportunityColumns, Table.Opportunity);
        this.quickSearchTableName = "tblopportunities op";
        this.quickSearchTableDisplayName = "Opportunities";
        this.quickSearchColumns = this.OpportunityColumns;
      }

    }
    else if (document.getElementById('leads_span').className == 'mainCheckMark activeColor') {
      if (isSearch)
        this.router.navigateByUrl(`/application/leadsdashboard/0/0/${searchValue}`);
      else {
        if (this.leadColumns.length == 0)
          this.mapColumnNamesWithDisplayNames(this.leadColumns, Table.Leads);
        this.quickSearchTableName = "tblsaleprocesshistory sale";

        this.quickSearchTableDisplayName = "Leads";
        this.quickSearchColumns = this.leadColumns;
      }

    }
    else if (searchtype == "Contact") {
      if (isSearch)
        this.router.navigateByUrl(`/application/contacts/contacts/${searchValue}`);
      else {
        if (this.contactColumns.length == 0)
          this.mapColumnNamesWithDisplayNames(this.contactColumns, Table.Contact);
        this.quickSearchTableName = "tblcontacts con";
        this.quickSearchTableDisplayName = "Contacts";
        this.quickSearchColumns = this.contactColumns;
      }
    }
    else if (searchtype == "Account") {
      if (isSearch)
        this.router.navigateByUrl(`/application/account/Contact/${searchValue}`);

    } else if (searchtype == "Leads") {
      if (isSearch)
        this.router.navigateByUrl(`/application/leadsdashboard/0/0/${searchValue}`);

    }
    else if (searchtype == "Opportunity") {
      if (isSearch)
        this.router.navigateByUrl(`/application/opportunitiesdashboard/0/0/${searchValue}/null`);

    }
    else if (searchtype == "Task") {
      if (isSearch)
        this.router.navigateByUrl(`/application/tasklistdashboard/null/0/null/${searchValue}`);

    }
    else {
      if (document.getElementById('subTab') != undefined && document.getElementById('subTab') != null)
        document.getElementById('subTab').style.display = 'none';
      this.router.navigateByUrl(`/application`);

      //this.router.navigateByUrl(`/applicaton`);
      if (isSearch)
        this.router.navigateByUrl(`/application`);
      else {
        if (this.leadColumns.length == 0)
          this.mapColumnNamesWithDisplayNames(this.leadColumns, Table.Leads);
        this.quickSearchTableName = "tblsaleprocesshistory sale";

        this.quickSearchTableDisplayName = "Leads";
        this.quickSearchColumns = this.leadColumns;
        // }
      }


    }
  }

  btnAddFieldsSelectorClick(event) {
    this.accountColumns.forEach(col => {
      if (col.isSelected) {
        if (col.columnName === 'speciality') {
          col.distinctValues = [];
          this.customerService.GetPickListData("Account Specialty", 21, this.currentuser.id).then((accounttype) => {
            accounttype.forEach(dist => {
              col.distinctValues.push({ "id": dist.id, "name": dist.itemName });
            });

          });
         
          if (col.selectedVal == "")
            col.selectedVal = col.distinctValues[0];
        }
        else if (col.columnName === 'SalesRep') {
          col.distinctValues = [];
          this.customerService.GetSalesRep(this.currentuser.id).then((accounttype) => {
            accounttype.forEach(dist => {
              col.distinctValues.push({ "id": dist.id, "name": dist.itemName });
            });

          });

          if (col.selectedVal == "")
            col.selectedVal = col.distinctValues[0];
        }
        else {
          var res = this.AccountTableColumns.filter(ele => ele.ColumnName == col.columnName);
          if ((res.length > 0 && res[0].OldOrNew == 'Old') || col.columnName == 'accountid123') {
            this.advancedSearchService.GetDistinctColumnValues('tblaccounts', col.columnName, this.currentuser.id).then((distinctValues) => {
              if (col.columnName == 'accounttype') {
                col.distinctValues = [];
               
                this.customerService.GetPickListData("Account Type", 3, this.currentuser.id).then((accounttype) => {                 
                  accounttype.forEach(dist => {
                    col.distinctValues.push({ "id": dist.id, "name": dist.itemName });                   
                  });

                });

              }
              else if (col.columnName == "customertypeid") {
                col.distinctValues = [];
                this.customerService.GetSpecialityList("Account Active or Inactive", this.currentuser.id).then((CustomerType) => {

                  distinctValues.forEach(dist => {
                    for (var i = 0; i < CustomerType.length; i++) {
                      if (CustomerType[i].id == Number(dist)) {
                        col.distinctValues.push({ "id": CustomerType[i].id, "name": CustomerType[i].itemName });
                      }
                    }
                  });
                });


              }
              else if (col.columnName == "ownership") {
                col.distinctValues = [];
                this.customerService.GetPickListData("Account Ownership", 4, this.currentuser.id).then((accounttype) => {
                  accounttype.forEach(dist => {
                    col.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                  });

                });
              
              } else if (col.columnName == "accountStatus") {
              col.distinctValues = [];
                this.customerService.GetPickListData("Account Customer Status", 5, this.currentuser.id).then((accounttype) => {
                  accounttype.forEach(dist => {
                    col.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                  });

                });}
              else if (col.columnName == "ehr" || col.columnName == "pm" || col.columnName == "rcm") {
                var type = "ehr";
                var fieldId = 0;
                switch (col.columnName) {
                  case "ehr": {
                    type = "Account EHR";
                    fieldId = 22;
                    break;
                  }
                  case "pm": {
                    type = "Account PM";
                    fieldId = 24;
                    break;
                  }
                  case "rcm": {
                    type = "Account RCM";
                    fieldId = 26;
                    break;
                  }

                }
                col.distinctValues = [];
                this.customerService.GetPickListData(type, fieldId, this.currentuser.id).then((accounttype) => {
                  accounttype.forEach(dist => {
                    col.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                  });

                });               
              }               
              else if (col.columnName == "ehrdatepurchased" || col.columnName == "pmdatepurchased" || col.columnName == "rcmdatepurchased" || col.columnName == "CustomerSince") {
                distinctValues.forEach(ele => {
                  if (distinctValues.length > 0 && ele != null && ele != "Invalid date") {
                    col.distinctValues.push({ "id": this.datePipe.transform(ele, 'yyyy-MM-dd') + ' 00:00:00', "name": this.datePipe.transform(ele, 'yyyy-MM-dd' + ' 00:00:00') })
                  }
                  else {
                    if (distinctValues.length == 0) {
                      col.distinctValues.push({ "id": null, "name": "No Records Found" })
                    }
                  }
                });
              }
              else if (col.columnName == 'ownerid') { 
                col.distinctValues = [];               
                this.customerService.GetAllaccesUserList(this.currentuser.id).then((Owner) => {                
                  Owner.forEach(ele => {                
                    if (ele != null) {
                      col.distinctValues.push({ "id": ele.id, "name": ele.itemName })
                    }
                    else {
                      if (distinctValues.length == 0) {
                        col.distinctValues.push({ "id": null, "name": "No Records Found" })
                      }
                    }

                  });
                });
              }
              else if (col.columnName == 'accountid123') {
                this.userService.GetAllStatusIds(this.currentuser.id, 0).then(result => {
                  let Doublesortres = this.DoubleSort(result, 'StatusIDGroupName', 'SortOrder');
                  let Groups = [];
                  Groups.push(Doublesortres.find(x => x.StatusIDGroup == '5000'));
                  Groups.push(Doublesortres.find(x => x.StatusIDGroup == '5001'));


                  distinctValues.forEach(ele => {


                    //for (var i = 0; i < Doublesortres.length; i++) {
                    //  col.distinctValues.push({ "id": Doublesortres[i].Id, "name": Doublesortres[i].StatusIDName, "Category": Doublesortres[i].StatusIDGroupName });

                    //}
                    for (var i = 0; i < Groups.length; i++) {
                      col.distinctValues.push({ "GroupNumber": Groups[i].StatusIDGroup, "GroupName": Groups[i].StatusIDGroupName, "GroupItems": Doublesortres.filter(x => x.StatusIDGroupName == Groups[i].StatusIDGroupName) });

                    }

                  });
                });
              }
              else {
                distinctValues.forEach(ele => {
                  if (ele != null) {
                    col.distinctValues.push({ "id": ele, "name": ele });
                  }
                  else {
                    if (distinctValues.length == 0) {
                      col.distinctValues.push({ "id": null, "name": "No Records Found" })
                    }
                  }
                });
              }


              if (col.selectedVal == "")
                col.selectedVal = distinctValues[0];
            });
          }
          else if ((col.columnName == "soldBy" || col.columnName == "leadfromid" || col.columnName == "keycontact1" || col.columnName == "keycontact2"
            || col.columnName == "keycontact3" || col.columnName == "LastTicketDate") && res[0].OldOrNew == 'New') {
            this.advancedSearchService.GetDistinctColumnValues('tblaccounthistory', col.columnName, this.currentuser.id).then((distinctValues) => {
              col.distinctValues = [];
              if (res[0].DataType == 'Pick List') {
                distinctValues.forEach(ele => {
                  if (ele != null) {
                    if (col.columnName == "leadfromid") {
                      col.distinctValues = [];
                      this.customerService.GetSpecialityList("LeadFrom", this.currentuser.id).then((lead) => {
                        col.distinctValues = [];
                        distinctValues.forEach(dist => {
                          for (var i = 0; i < lead.length; i++) {
                            if (lead[i].id == Number(dist)) {
                              col.distinctValues.push({ "id": lead[i].id, "name": lead[i].itemName });
                            }
                          }
                        });
                      });
                      //col.distinctValues.push({ "id": ele, "name": ele });
                    } else if (col.columnName == "soldBy") {
                      col.distinctValues.push({ "id": ele, "name": ele });
                    } else {
                      col.distinctValues.push({ "id": ele, "name": ele.split(":")[1] });
                    }
                  }
                  else {
                    if (distinctValues.length == 0) {
                      col.distinctValues.push({ "id": null, "name": "No Records Found" })
                    }
                  }
                });
              } else {
                distinctValues.forEach(ele => {
                  if (ele != null) {
                    col.distinctValues.push({ "id": ele, "name": ele });
                  }
                  else {
                    if (distinctValues.length == 0) {
                      col.distinctValues.push({ "id": null, "name": "No Records Found" })
                    }
                  }
                });
              }
              if (col.selectedVal == "")
                col.selectedVal = distinctValues[0];
            });
          }
          else {
            this.advancedSearchService.GetDistinctColumnValues('tblaccountadditionalfields', col.columnName, this.currentuser.id).then((distinctValues) => {
              if (res[0].DataType == 'Pick List') {           
                distinctValues.forEach(ele => {
                  if (ele != null) {
                    if (col.columnName == "IsCustomer") {
                      this.customerService.GetPickListData('IsCustomer', 111, this.currentuser.id).then((accounttype) => {
                        col.distinctValues.push({ "id": ele, "name": ele == '874' ? 'No' : 'Yes' });

                      });
                    }
                    else if (col.columnName == "SystemType") {
                      this.customerService.GetPickListData("SystemType", 122, this.currentuser.id).then((SystemTypes) => {                      
                        col.distinctValues = [];                                  
                          for (var i = 0; i < SystemTypes.length; i++) {                            
                              col.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });  
                          }                      
                      });
                    }
                    else if (col.columnName == "HL7Interface") {
                      this.customerService.GetPickListData("HL7Interface", 123, this.currentuser.id).then((SystemTypes) => {
                        col.distinctValues = [];
                        for (var i = 0; i < SystemTypes.length; i++) {
                          col.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                        }
                      });
                    }
                    else if (col.columnName == "CustomerRCM") {
                      this.customerService.GetPickListData("CustomerRCM", 124, this.currentuser.id).then((SystemTypes) => {
                        col.distinctValues = [];
                        for (var i = 0; i < SystemTypes.length; i++) {
                          col.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                        }
                      });
                    }
                    else if (col.columnName == "OnDemand") {
                      this.customerService.GetPickListData("OnDemand", 125, this.currentuser.id).then((SystemTypes) => {
                        col.distinctValues = [];
                        for (var i = 0; i < SystemTypes.length; i++) {
                          col.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                        }
                      });
                    }
                    else if (col.columnName == "ApptReminders") {
                      this.customerService.GetPickListData("ApptReminders", 126, this.currentuser.id).then((SystemTypes) => {
                        col.distinctValues = [];
                        for (var i = 0; i < SystemTypes.length; i++) {
                          col.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                        }
                      });
                    }
                    else if (col.columnName == "eLab") {
                      this.customerService.GetPickListData("eLab", 127, this.currentuser.id).then((SystemTypes) => {
                        col.distinctValues = [];
                        for (var i = 0; i < SystemTypes.length; i++) {
                          col.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                        }
                      });
                    } else if (col.columnName == "MIPS") {
                      this.customerService.GetPickListData("MIPS", 128, this.currentuser.id).then((SystemTypes) => {
                        col.distinctValues = [];
                        for (var i = 0; i < SystemTypes.length; i++) {
                          col.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                        }
                      });
                    }
                    else {
                      col.distinctValues.push({ "id": ele, "name": ele.split(":")[1] });
                    }
                  }
                  else {
                    if (distinctValues.length == 0) {
                      col.distinctValues.push({ "id": null, "name": "No Records Found" })
                    }
                  }
                });
              } else {
                distinctValues.forEach(ele => {
                  if (ele != null) {
                    col.distinctValues.push({ "id": ele, "name": ele });
                  }
                  else {
                    if (distinctValues.length == 0) {
                      col.distinctValues.push({ "id": null, "name": "No Records Found" })
                    }
                  }
                });
              }



              if (col.selectedVal == "")
                col.selectedVal = distinctValues[0];
            });
          }

        }
      }
      else
        col.distinctValues = [];

    });

    this.contactColumns.forEach(col => {
      if (col.isSelected) {
        var res = this.ContactTableColumns.filter(ele => ele.ColumnName == col.columnName);
        if (res.length > 0 && res[0].OldOrNew == 'Old') {

          this.advancedSearchService.GetDistinctColumnValues('tblcontacts', col.columnName, this.currentuser.id).then((distinctValues) => {
            if (col.columnName == 'contactid123') {
              col.distinctValues = [];
              this.customerService.GetSpecialityList("ContactIds", this.currentuser.id).then((ContactIds) => {
                ContactIds.forEach(conid => {
                  if (conid != null) {
                    col.distinctValues.push({ "id": conid.id, "name": conid.itemName })
                  }
                  else {
                    if (distinctValues.length == 0) {
                      col.distinctValues.push({ "id": null, "name": "No Records Found" })
                    }
                  }
                })
              });
            }
            else if (col.columnName == 'college' || col.columnName == 'contacttype' || col.columnName == 'fellowship' || col.columnName == 'medschool' || col.columnName == 'residency'
              || col.columnName == 'sex' || col.columnName == 'suffix' || col.columnName == 'speciality' || col.columnName == 'subspeciality' || col.columnName == 'title'
              || col.columnName == 'STATUS') {
              var listtype = "Contact College";
              var fieldId = 0;
              switch (col.columnName) {
                case "college": {
                  listtype = "Contact College"; fieldId = 99;
                  break;
                };
                case "contacttype": {
                  listtype = "Contact Type";
                  fieldId = 60;
                  break;
                };
                case "fellowship": {
                  listtype = "Contact Fellowship"; fieldId = 103;
                  break;
                };
                case "medschool": {
                  listtype = "Contact Medical School"; fieldId = 101;
                  break;
                };
                case "residency": {
                  listtype = "Contact Residency"; fieldId = 108;
                  break;
                };
                case "suffix": {
                  listtype = "Contact Suffix"; fieldId = 65;
                  break;
                }
                case "speciality": {
                  listtype = "Contact Speciality"; fieldId = 97;
                  break;
                }
                case "subspeciality": {
                  listtype = "Contact Sub-Specialty"; fieldId = 98;
                  break;
                }
                case "sex": {
                  listtype = "Contact Sex"; fieldId = 66;
                  break;
                };
                case "title": {
                  listtype = "Contact Title"; fieldId = 67;
                  break;
                };
                case "STATUS": {
                  listtype = "Contact Status";
                  fieldId = 61;
                  break;
                };


              }
              col.distinctValues = [];
              this.customerService.GetPickListData(listtype, fieldId, this.currentuser.id).then((accounttype) => {
                accounttype.forEach(dist => {
                  col.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                });
              });
              
            }
            else if (col.columnName == 'ownerid') {            
              col.distinctValues = [];       
              this.customerService.GetAllaccesUserList(this.currentuser.id).then((Owner) => {
                Owner.forEach(ele => {
                  if (ele != null) {
                    col.distinctValues.push({ "id":ele.id, "name": ele.itemName })
                  }
                  else {
                    if (distinctValues.length == 0) {
                      col.distinctValues.push({ "id": null, "name": "No Records Found" })
                    }
                  }

                });
              });
            }
            else {
              col.distinctValues = [];
              distinctValues.forEach(ele => {
                if (ele != null) {
                  col.distinctValues.push({ "id": ele, "name": ele })
                } else {
                  if (distinctValues.length == 0) {
                    col.distinctValues.push({ "id": null, "name": "No Records Found" })
                  }
                }
              });
            }

            if (col.selectedVal == "")
              col.selectedVal = distinctValues[0];
          });
        } else {
          col.distinctValues = [];
          this.advancedSearchService.GetDistinctColumnValues('tblcontactsadditionalfields', col.columnName, this.currentuser.id).then((distinctValues) => {
            if (res[0].DataType == 'Pick List') {
              distinctValues.forEach(ele => {
                if (ele != null) {
                  col.distinctValues.push({ "id": ele, "name": ele.split(":")[1] });
                } else {
                  if (distinctValues.length == 0) {
                    col.distinctValues.push({ "id": null, "name": "No Records Found" });
                  }
                }
              });
            }
            else {
              distinctValues.forEach(ele => {
                if (ele != null) {
                  col.distinctValues.push({ "id": ele, "name": ele });
                } else {
                  if (distinctValues.length == 0) {
                    col.distinctValues.push({ "id": null, "name": "No Records Found" });
                  }
                }
              });
            }



            if (col.selectedVal == "")
              col.selectedVal = distinctValues[0];
          });
        }


      } else
        col.distinctValues = [];
    });



    this.quickSearch = false;
   // this.queryLanding = false;
    this.fieldsSelector = false;
    this.valueSelector = true;


  }
  btnAddContactField(event, name, val) {
    this.contactColumns.splice(val + 1, 0, { columnName: name, displayName: 'duplicateCol', distinctValues: [], isSelected: true, selectedVal: "", operator: "Equals", andOrOperator: "and" });
    this.contactColumns.forEach(col => {

      if (col.isSelected) {
        col.distinctValues = [];
        this.advancedSearchService.GetDistinctColumnValues('tblcontacts', col.columnName, this.currentuser.id).then((distinctValues) => {
          if (col.columnName == 'contactid123') {
            this.customerService.GetSpecialityList("ContactIds", this.currentuser.id).then((ContactIds) => {
              ContactIds.forEach(conid => {
                if (conid != null) {
                  col.distinctValues.push(conid.itemName)
                }
              })
            });
          } else if (col.columnName == 'ownerid') {
            this.customerService.GetAllaccesUserList(this.currentuser.id).then((Owner) => {
              Owner.forEach(ele => {
                col.distinctValues.push({ "id": ele.id, "name": ele.itemName })

              });
            });
          }
          else if (col.columnName == 'college' || col.columnName == 'contacttype' || col.columnName == 'fellowship' || col.columnName == 'medschool' || col.columnName == 'residency'
            || col.columnName == 'sex' || col.columnName == 'suffix' || col.columnName == 'speciality' || col.columnName == 'subspeciality' || col.columnName == 'title'
            || col.columnName == 'STATUS') {
            var listtype = "Contact College";
            switch (col.columnName) {
              case "college": {
                listtype = "Contact College";
                break;
              };
              case "contacttype": {
                listtype = "Contact Type";
                break;
              };
              case "fellowship": {
                listtype = "Contact Medical School";
                break;
              };
              case "medschool": {
                listtype = "Contact Medical School";
                break;
              };
              case "residency": {
                listtype = "Contact Medical School";
                break;
              };
              case "suffix": {
                listtype = "Contact Suffix";
                break;
              }
              case "speciality": {
                listtype = "Account Specialty";
                break;
              }
              case "subspeciality": {
                listtype = "Account Specialty";
                break;
              }
              case "sex": {
                listtype = "Contact Sex";
                break;
              };
              case "title": {
                listtype = "Contact Title";
                break;
              };
              case "STATUS": {
                listtype = "Contact Status";
                break;
              };


            }
            col.distinctValues = [];
            this.customerService.GetSpecialityList(listtype, this.currentuser.id).then((College) => {

              distinctValues.forEach(dist => {
                for (var i = 0; i < College.length; i++) {
                  if (College[i].id == Number(dist)) {
                    col.distinctValues.push({ "id": College[i].id, "name": College[i].itemName });
                  }
                }
              });
            });

          }
          else {
            //col.distinctValues = distinctValues;
            distinctValues.forEach(ele => {
              if (ele != null) {
                col.distinctValues.push({ "id": ele, "name": ele })
              }
              else {
                if (distinctValues.length == 0) {
                  col.distinctValues.push({ "id": null, "name": "No Records Found" })
                }
              }
            });
          }
          if (col.selectedVal == "")
            col.selectedVal = distinctValues[0];
        });
      } else
        col.distinctValues = [];
    });
  }
  btnCancelValueSelectorClick(event) {
    this.valueSelector = false;
    this.quickSearch = false;
    //this.queryLanding = false;
    this.fieldsSelector = true;
  }

  btnSaveValueSelectorClick(event) {
    if ($('#txtQryName').val() != "") {
      let qryName: string = "'" + $('#txtQryName').val() + "'";
      let searchQry: string;
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      searchQry = "select * from ";

      if (this.tabType.includes("Contact") && this.contactColumns.length > 0) {
        if (!this.fromadded)
          this.fromadded = true;
        searchQry = "select distinct con.*,account.*,addField.*,PK.itemName as contacttypeName,PK1.itemName as suffixName,PK2.itemName as titleName,PK3.itemName as sexName,PK4.itemName as specialityName,PK5.itemName as collegeName,PK6.itemName as medschoolName,PK7.itemName as residencyName,PK8.itemName as felloshipName,PK9.itemName AS constatus, con.id as contactid from tblcontacts as con join tblaccounts as account on con.accountid = account.id Left Join tblcontactsadditionalfields as addField on con.id=addField.contactid LEFT JOIN `picklistfielddata` AS PK ON con.contacttype = PK.id  LEFT JOIN `picklistfielddata` AS PK1 ON con.suffix = PK1.id LEFT JOIN `picklistfielddata` AS PK2 ON con.title = PK2.id LEFT JOIN `picklistfielddata` AS PK3 ON con.sex = PK3.id LEFT JOIN `picklistfielddata` AS PK4 ON con.speciality = PK4.id LEFT JOIN `picklistfielddata` AS PK5 ON con.college = PK5.id LEFT JOIN `picklistfielddata` AS PK6 ON con.medschool = PK6.id LEFT JOIN `picklistfielddata` AS PK7 ON con.residency = PK7.id LEFT JOIN `picklistfielddata` AS PK8 ON con.fellowship = PK8.id LEFT JOIN `picklistfielddata` AS PK9 ON con.STATUS = PK9.id ";
      }
      else {
        searchQry += " ";
      }

      if (this.tabType.includes("Account") && this.accountColumns.length > 0) {
        if (!this.fromadded) {
          this.fromadded = true;
          searchQry = "select distinct acc.*,addField.*,PK.itemName as specialityName,PK1.itemName as ownershipName,PK2.itemName as accounttypeName,PK3.itemName as customertypeName,PK4.itemName as ehrName,PK5.itemName as pmName,PK6.itemName as rcmName,owr.Username AS OWNER  from tblaccounts as acc Left Join tblaccountadditionalfields as addField on acc.id=addField.accountid  LEFT JOIN `tblaccounthistory` AS hist ON acc.id =hist.accountid LEFT JOIN `tbluser` AS sauser ON sauser.id =hist.`Salesrep` LEFT JOIN `picklistfielddata` AS PK ON acc.speciality = PK.id LEFT JOIN `picklistfielddata` AS PK1 ON acc.ownership = PK1.id LEFT JOIN `picklistfielddata` AS PK2 ON acc.accounttype = PK2.id LEFT JOIN `picklistfielddata` AS PK3 ON acc.customertypeid = PK3.id LEFT JOIN `picklistfielddata` AS PK4 ON acc.ehr = PK4.id LEFT JOIN `picklistfielddata` AS PK5 ON acc.pm = PK5.id LEFT JOIN `picklistfielddata` AS PK6 ON acc.rcm = PK6.id JOIN tbluser AS owr ON owr.id=acc.ownerid ";
        } else {
          searchQry += " join tblaccounts as acc on con.accountid = acc.id "
        }
      }
      else {
        searchQry += " ";
      }

      if (this.tabType.includes("Account,Contact") && this.accountColumns.length > 0 && this.contactColumns.length > 0) {
        searchQry += " tblcontacts as con join tblaccounts as acc on con.accountid = acc.id "
      }
      else {
        searchQry += " ";
      }

      this.tabType.includes("Contact") && this.contactColumns.length > 0 ? this.tablealias = "con" : this.tabType.includes("Account") && this.accountColumns.length > 0 ? this.tablealias = "acc" : (this.tabType.includes("Account,Contact") && this.accountColumns.length > 0 && this.contactColumns.length > 0) ? this.tablealias = "acc" : "";
      searchQry += "where ";

        if (this.currentuser.systemadministrator == 1) {
          searchQry += this.tablealias + ".userid > 0 ";
        } else {
          searchQry += this.tablealias + ".userid = " + currentUser.id;
        }
      let searchData: any[] = [];
      let searchOperator: any[] = [];
      let banArray: any[] = [];
      let banArray2: any[] = [];
      let oprString: string = "";
      let whereQry1: string = "";
      let whereQry2: string = "";
      var searchval = "";
      for (let i = 0; i < this.contactColumns.length; i++) {
        if (this.contactColumns[i].distinctValues.length > 0) {
          let lhsVal: string = $('#selLHSContactSelector' + i).val();
          let rhsVal: string = $('#selRHSContactSelector' + i).val();
          let andOrOperator: string = $('#selRHSContactOperator' + i).val();

          if (lhsVal == "Is Not Empty" || lhsVal == "Is Empty")
            rhsVal = "";
          if (rhsVal != undefined) {
            switch (lhsVal) {
              case 'Equals': {
                oprString = " FIND_IN_SET(";
                break;
              }
              case 'Is Not': {
                oprString = " !FIND_IN_SET(";
                break;
              }
              case 'Is Not Empty': {
                oprString = " is not null";
                break;
              }
              case 'Is Empty': {
                oprString = " is null";
                break;
              }
            }
            if (banArray.length > 0) {
              banArray.forEach(x => {
                if ((x.column == this.contactColumns[i].columnName) && (x.Operator == oprString)) {
                  if (andOrOperator == "AND" && lhsVal == "Equals") {
                    x.SearchValue += "|''" + rhsVal + "''";
                  }
                  else if (andOrOperator == "AND" && lhsVal == "Is Not") {
                    var temp_search = x.SearchValue + "," + rhsVal + "";
                    var result = temp_search;
                    x.SearchValue = result;
                  }
                  else {
                    searchval = "'" + rhsVal + "'";
                  }

                }
                var existingopstring = banArray.find(y => y.Operator == oprString);
                if (!existingopstring && x.column == this.contactColumns[i].columnName && (x.Operator != oprString)) {
                  var searchval = "";

                  if (andOrOperator == "AND" && lhsVal == "Equals") { x.SearchValue += "|''" + rhsVal + "''"; }

                  else {
                    searchval = "" + rhsVal + "";
                  }

                  banArray.push({
                    'column': this.contactColumns[i].columnName,
                    'Operator': oprString,
                    'SearchValue': searchval,
                    'andOrOperator': $("input[name='" + this.contactColumns[i].columnName + "']:checked").val()
                  });
                }
              });
            }
            else {
              var searchval = "";
              if (rhsVal != "") {
                if (andOrOperator == "AND" && lhsVal == "Equals")
                { searchval += "|''" + rhsVal + "''"; } else {
                  searchval = rhsVal ;
                }
              }
                banArray.push({
                  'column': this.contactColumns[i].columnName,
                  'Operator': oprString,
                  'SearchValue': searchval,
                  'andOrOperator': andOrOperator
                });
            }

          }
        }
      }
      if (banArray.length > 0) {
        banArray.forEach(x => {
          var searchFields = x.column + " | " + x.Operator + " | " + x.SearchValue;
          searchData.push(searchFields);

          var Operator = x.column + " | " + x.andOrOperator + " | " + x.SearchValue;
          searchOperator.push(Operator);
          if (x.Operator == ' FIND_IN_SET(') {
            if (x.andOrOperator == 'OR') {
              whereQry1 += " AND " + x.Operator + "con." + x.column + ",''" + x.SearchValue + "'')";
            } else {
              if (x.SearchValue.includes('|')) {
                var andVales = [];
                 andVales = x.SearchValue.split('|');
                andVales.forEach(eledata => {
                  if (eledata != "") {
                    whereQry1 += " " + x.andOrOperator + "" + x.Operator + "con." + x.column + "," + eledata + ")";
                  }
                });
              } else {
                whereQry1 += " " + x.andOrOperator + " " + x.Operator + "con." + x.column + "," + x.SearchValue + ")";
              }
            }


          }
          else if (x.Operator == ' !FIND_IN_SET(') {
            if (x.column == 'address1' || x.column == 'address2' || x.column == 'homeaddress') {
              whereQry1 += " " + x.andOrOperator + " " + " con." + x.column + " IN(" + x.SearchValue + ")";
            } else {
              var res = this.ContactTableColumns.filter(ele => ele.ColumnName == x.column);
              if (res.length > 0 && res[0].OldOrNew == 'New') {
                whereQry1 += " " + x.andOrOperator + " " + x.Operator + "addField." + x.column + ",''" + x.SearchValue + "'')";
              }
              else {
                whereQry1 += " " + x.andOrOperator + " " + x.Operator + "con." + x.column + ",''" + x.SearchValue + "'')";
              }
            }

          }
          else if (x.Operator == ' is not null') {
            var res = this.ContactTableColumns.filter(ele => ele.ColumnName == x.column);
            if (res.length > 0 && res[0].OldOrNew == 'New') {
              whereQry1 += " " + x.andOrOperator + " " + "  addField." + x.column + x.Operator;
            }
            else {
              whereQry1 += " " + x.andOrOperator + " " + "  con." + x.column + x.Operator;
            }

          }
          else if (x.Operator == ' is null') {
            var res = this.ContactTableColumns.filter(ele => ele.ColumnName == x.column);
            if (res.length > 0 && res[0].OldOrNew == 'New') {
              whereQry1 += " " + x.andOrOperator + " " + "  addField." + x.column + x.Operator;
            }
            else {
              whereQry1 += " " + x.andOrOperator + " " + "  con." + x.column + x.Operator;
            }
          }

        });
      }
      searchQry += whereQry1;


      oprString = "";
      for (let i = 0; i < this.accountColumns.length; i++) {
        if (this.accountColumns[i].distinctValues.length > 0) {
          let lhsVal: string = $('#selLHSAccountSelector' + i).val();
          let rhsVal: string = $('#selRHSAccountSelector' + i).val();
          let andOrOperator: string = $('#selRHSAccountOperator' + i).val();
          if (lhsVal == "Is Not Empty" || lhsVal == "Is Empty")
            rhsVal = "";
          if (rhsVal != undefined) {
            switch (lhsVal) {
              case 'Equals': {
                oprString = " FIND_IN_SET(";
                break;
              }
              case 'Is Not': {
                oprString = " !FIND_IN_SET(";
                break;
              }
              case 'Is Not Empty': {
                oprString = " is not null";
                break;
              }
              case 'Is Empty': {
                oprString = " is null";
                break;
              }
            }
            if (banArray2.length > 0) {
              if (banArray2.find(x => x.column == this.accountColumns[i].columnName && x.column != 'accountid123')) {
                banArray2.forEach(x => {
                  if ((x.column == this.accountColumns[i].columnName) && (x.Operator == oprString)) {
                  
                    if (andOrOperator == "AND" && lhsVal == "Equals") {
                      x.SearchValue += "|''" + rhsVal + "''";
                    }
                    else if (andOrOperator == "AND" && lhsVal == "Is Not")
                    {
                      var temp_search = x.SearchValue + "," + rhsVal + "";
                      var result = temp_search;//.replace("',", ",");
                      x.SearchValue = result;
                    }
                    else if (andOrOperator == "OR") {
                      x.SearchValue += "," + rhsVal;
                    }
                    else {                      
                      searchval = "'" + rhsVal + "'";
                      }
                   
                  }
                  var existingopstring = banArray2.find(y => y.Operator == oprString);
                  if (!existingopstring && x.column == this.accountColumns[i].columnName && (x.Operator != oprString)) {
                    var searchval = "";
                   
                    if (andOrOperator == "AND" && lhsVal == "Equals")
                    { x.SearchValue += "|''" + rhsVal + "''"; }
                    
                      else {
                        searchval = "" + rhsVal + "";
                      }
                   
                    banArray2.push({
                      'column': this.accountColumns[i].columnName,
                      'Operator': oprString,
                      'SearchValue': searchval,
                      'andOrOperator': andOrOperator /*$("input[name='" + this.accountColumns[i].displayName + "']:checked").val()*/
                    });
                  }
                });
              }
              else {
                var searchval = "";
                if (andOrOperator == "AND" && lhsVal == "Equals") {
                  if (this.accountColumns[i].columnName == "accountid123") {
                    searchval = rhsVal;
                  } else {
                    searchval = "''" + rhsVal + "''";
                  }
                }
                else if (andOrOperator == "AND" && lhsVal == "Is Not") {
                  var temp_search = rhsVal;
                  var result = temp_search;//.replace("',", ",");
                  searchval = result;
                } else {
                  searchval = rhsVal;
                }
               
                banArray2.push({
                  'column': this.accountColumns[i].columnName,
                  'Operator': oprString,
                  'SearchValue': searchval,
                  'andOrOperator': andOrOperator/*$("input[name='" + this.accountColumns[i].displayName + "']:checked").val()*/
                });
              }

            }
            else {
              if (andOrOperator == "AND" && lhsVal == "Is Not")
              {
                searchval = rhsVal;
              }
              else if (andOrOperator == "OR") {
                searchval = rhsVal;
              }
              else {               
                  if (this.accountColumns[i].columnName == "accountname" || this.accountColumns[i].columnName == "address1" || this.accountColumns[i].columnName == "address2" || this.accountColumns[i].columnName == "city" || this.accountColumns[i].columnName == "state" || this.accountColumns[i].columnName == "zip" || this.accountColumns[i].columnName == "phone" || this.accountColumns[i].columnName == "fax" || this.accountColumns[i].columnName == "alterphone1" || this.accountColumns[i].columnName == "alterphone2" || this.accountColumns[i].columnName == "website" || this.accountColumns[i].columnName == "ehrdatepurchased" || this.accountColumns[i].columnName == "pmdatepurchased" || this.accountColumns[i].columnName == "rcmdatepurchased" || this.accountColumns[i].columnName == "country" || this.accountColumns[i].columnName == "CustomerSince") {
                  searchval = "''" + rhsVal + "''";}
                else {
                  searchval = rhsVal; //"''" + rhsVal + "''";
                }
              }
              banArray2.push({
                'column': this.accountColumns[i].columnName,
                'Operator': oprString,
                'SearchValue': searchval,
                'andOrOperator': andOrOperator
              });
            }



          }

        }
      }
      if (banArray2.length > 0) {
        banArray2.forEach(x => {
          var searchFields = x.column + " | " + x.Operator + " | " + x.SearchValue;
          searchData.push(searchFields);
          var Operator = x.column + " | " + x.andOrOperator + " | " + x.SearchValue;
          searchOperator.push(Operator);
          if (x.Operator == ' FIND_IN_SET(') {
            if (x.column == 'accountid123') {
              whereQry2 += " " + x.andOrOperator + " ";
              whereQry2 += "  acc.id IN(SELECT accountContactId FROM tblStatusIds AS stat WHERE ''" + x.SearchValue + "'' IN(stat.StatusID1, stat.StatusID2, stat.StatusID3, stat.StatusID4, stat.StatusID5, stat.StatusID6, stat.StatusID7," +
                "stat.StatusID8, stat.StatusID9, stat.StatusID10, stat.StatusID11, stat.StatusID12, stat.StatusID13, stat.StatusID14, stat.StatusID15,stat.StatusID16,stat.StatusID17,stat.StatusID18,stat.StatusID19,stat.StatusID20" +
                ",stat.StatusID21, stat.StatusID22, stat.StatusID23, stat.StatusID24, stat.StatusID25, stat.StatusID26, stat.StatusID27,stat.StatusID28, stat.StatusID29, stat.StatusID30, stat.StatusID31, stat.StatusID32, stat.StatusID33, stat.StatusID34, stat.StatusID35,stat.StatusID36,stat.StatusID37,stat.StatusID38,stat.StatusID39,stat.StatusID40)) ";
            }
            else if (x.column == 'accountname' || x.column == 'address1' || x.column == 'address2' || x.column == 'homeaddress' || x.column == 'accounttype' || x.column == 'customertypeid' || x.column == 'ownership' || x.column == 'accountStatus' || x.column == 'accnumber' || x.column == 'city' || x.column == 'state' || x.column == 'zip' || x.column == 'country' || x.column == 'phone' || x.column == 'fax' || x.column == 'alterphone1' || x.column == 'alterphone2' || x.column == 'website' || x.column == 'ownerid' || x.column == 'speciality' || x.column == 'ehr'
              || x.column == 'ehrdatepurchased' || x.column == 'pm' || x.column == 'pmdatepurchased' || x.column == 'rcm' || x.column == 'rcmdatepurchased' || x.column == 'created' || x.column == 'CustomerSince' || x.column == 'ehrdatepurchased' || x.column == 'country') {
              if (x.andOrOperator == 'OR') {
                whereQry2 +=  x.Operator + "acc." + x.column + ",''" + x.SearchValue + "'')";
              } else {
                if (x.SearchValue.includes('|')) {
                  var andVales = x.SearchValue.split('|');
                  andVales.forEach(eledata => {
                    if (eledata != "") {
                      whereQry2 += " AND " + x.Operator + "acc." + x.column + "," + eledata + ")";
                    }
                  });
                } else {
                  if (x.SearchValue != "") {
                    whereQry2 += " AND " + x.Operator + "acc." + x.column + "," + x.SearchValue + ")";
                  }
                }
              }
            } else if (x.column == 'SalesRep' || x.column == 'soldBy' || x.column == 'leadfromid' || x.column == "keycontact1" || x.column == "keycontact2"
              || x.column == "keycontact3") {
              whereQry2 += " AND " + x.Operator + "hist." + x.column + "," + x.SearchValue + ")";
            }
            else {
              var res = this.AccountTableColumns.filter(ele => ele.ColumnName == x.column);
              if (res.length > 0 && res[0].OldOrNew == 'New') {
                if (x.andOrOperator == 'OR') {
                  whereQry2 += " AND " + x.Operator + "addField." + x.column + ",''" + x.SearchValue + "'')";
                } else {
                  if (x.SearchValue.includes(',')) {
                    let andarray = x.SearchValue.split(",");
                    andarray.forEach(eledata => {
                      whereQry2 += " AND " + x.andOrOperator + "  addField." + x.column + "=" + eledata + " "
                    });
                  } else {
                    whereQry2 += " AND " + x.Operator + "addField." + x.column + ",''" + x.SearchValue + "'')";
                  }
                }
              }
              else {
                if (x.andOrOperator == 'OR') {
                  whereQry2 += " AND " + x.Operator + "acc." + x.column + ",''" + x.SearchValue + "'')";
                } else {
                  if (x.SearchValue.includes(',')) {
                    let andarray = x.SearchValue.split(",");
                    andarray.forEach(eledata => {
                      if (eledata!="")
                      whereQry2 += " " + x.andOrOperator + " " + "acc." + x.column + "=" + eledata + " "
                    });

                  } else {
                    if (x.column == 'ownerid') { whereQry2 += x.Operator + "acc." + x.column + ",''" + x.SearchValue + "'')"; }
                    else {
                      whereQry2 += " AND " + x.Operator + "acc." + x.column + ",''" + x.SearchValue + "'')";
                    }
                  }
                }
              }


            }

          }
          else if (x.Operator == ' !FIND_IN_SET(') {
            if (x.column == 'accountid123') {


              whereQry2 += " " + x.andOrOperator + " ";
      
              whereQry2 += " acc.id IN(SELECT accountContactId FROM tblStatusIds AS stat WHERE ''" + x.SearchValue + "'' NOT IN(stat.StatusID1, stat.StatusID2, stat.StatusID3, stat.StatusID4, stat.StatusID5, stat.StatusID6, stat.StatusID7, " +
                "stat.StatusID8, stat.StatusID9, stat.StatusID10, stat.StatusID11, stat.StatusID12, stat.StatusID13, stat.StatusID14, stat.StatusID15,stat.StatusID16,stat.StatusID17,stat.StatusID18,stat.StatusID19,stat.StatusID20" +
                ",stat.StatusID21, stat.StatusID22, stat.StatusID23, stat.StatusID24, stat.StatusID25, stat.StatusID26, stat.StatusID27,stat.StatusID28, stat.StatusID29, stat.StatusID30, stat.StatusID31, stat.StatusID32, stat.StatusID33, stat.StatusID34, stat.StatusID35,stat.StatusID36,stat.StatusID37,stat.StatusID38,stat.StatusID39,stat.StatusID40)) ";
            } else if (x.column == 'accountname' || x.column == 'address1' || x.column == 'address2' || x.column == 'homeaddress') {
              whereQry2 += " and " + x.Operator + "acc." + x.column + ",''" + x.SearchValue + "'')";
            }
            else if (x.column == 'SalesRep' || x.column == 'soldBy' || x.column == 'leadfromid' || x.column == "keycontact1" || x.column == "keycontact2"
              || x.column == "keycontact3") {
              whereQry2 += " AND " + x.Operator + "hist." + x.column + ",''" + x.SearchValue + "'')";
            }
            else {
              var res = this.AccountTableColumns.filter(ele => ele.ColumnName == x.column);
              if (res.length > 0 && res[0].OldOrNew == 'New') {
                whereQry2 += " AND " + x.Operator + "addField." + x.column + ",''" + x.SearchValue + "'')";
              }
              else {
                if (x.Operator.trim() == "!FIND_IN_SET(") {
                  whereQry2 += " AND " + x.Operator + "acc." + x.column + ",''" + x.SearchValue + "'')";
                } else {
                  whereQry2 += " AND " + x.Operator + "acc." + x.column + "," + x.SearchValue + ")";
                }
              }

            }
          }
          else if (x.Operator == ' is not null' || x.Operator == 'is not null') {
          
            var res = this.AccountTableColumns.filter(ele => ele.ColumnName == x.column);
            if (res.length > 0 && res[0].OldOrNew == 'New') {
              if (x.column == 'SalesRep' || x.column == 'soldBy' || x.column == 'leadfromid' || x.column == "keycontact1" || x.column == "keycontact2"
                || x.column == "keycontact3") {
                whereQry2 += " " + x.andOrOperator + " " + " hist." + x.column + " != 0";
              } else {
                whereQry2 += " " + x.andOrOperator + " " + " addField." + x.column + x.Operator;
              }
            }
           
            else {
              if (x.Operator == " is not null" || x.Operator == "is not null" ) {
                whereQry2 += " " + x.andOrOperator + " " + " acc." + x.column + x.Operator + " AND " + " acc." + x.column + "!='' '' && acc." + x.column + "!=0";
              } else {
                whereQry2 += " " + x.andOrOperator + " " + " acc." + x.column + x.Operator;
               
              }
            
            }

          }
          else if (x.Operator == ' is null') {

            var res = this.AccountTableColumns.filter(ele => ele.ColumnName == x.column);
            if (res.length > 0 && res[0].OldOrNew == 'New') {
              if (x.column == 'SalesRep' || x.column == 'soldBy' || x.column == 'leadfromid' || x.column == "keycontact1" || x.column == "keycontact2"
                || x.column == "keycontact3") {
                whereQry2 += " " + x.andOrOperator + " " + " hist." + x.column + " = 0";
              } else {
                whereQry2 += " " + x.andOrOperator + " " + " addField." + x.column + x.Operator;
              }
            } 
            else {
              whereQry2 += " " + x.andOrOperator + " " + " acc." + x.column + x.Operator;
            }

          }

        });
      }
      searchQry += whereQry2;   
      searchQry = "'" + searchQry + "'";
     
      this.advancedSearchService.saveAdvancedSearchQuery(qryName, searchQry.replace('where  and', 'where'), this.editQryId, this.tabType.filter(
        function (elem, index, self) {
          return index === self.indexOf(elem);
        }).join(","),
        this.currentuser.id, qryName, "", searchData.join("~").toString(), searchOperator.join("~").toString()).then((out) => {
          this.advancedSearchService.getAdvancedSearchQueries("qryname", this.currentuser.id).then((queries) => {
            queries.forEach(queries => {
              queries.createdate = this.datePipe.transform(queries.createdate, "MM/dd/yyyy");
            });
            this.queryList = queries;
            this.emailFormArray = [];

          });
          this.fromadded = false;
          this.query.runby = this.currentuser.id;
          this.query.queryOrder = out;
          this.accountColumns = [];
          this.contactColumns = [];
          this.tabType = [];
          this.fromadded = false;
          this.selectedColumnList = [];
        });
      this.quickSearch = false;
      this.fieldsSelector = false;
      this.valueSelector = false;
      //this.queryLanding = true;
    }
    else {
      alert("Please Enter Query Name");
    }
  }

  btnRemoveValueSelectorClick(event) {

    let id: string = event.target.id;
    let removeId: string = id.replace('btnRemove', 'divValueSelector');

    let count: number = parseInt(removeId[removeId.length - 1]);
    if (id.indexOf("Account") >= 0) {
      if (this.accountColumns[count]) {
        this.accountColumns[count].distinctValues = [];
        this.accountColumns[count].isSelected = false;
        this.accountColumns[count].selectedVal = "";
      }
    } else if (id.indexOf("Contact") >= 0) {
      if (this.contactColumns[count]) {
        this.contactColumns[count].distinctValues = [];
        this.contactColumns[count].isSelected = false;
        this.contactColumns[count].selectedVal = "";
      }
    }
    $('#' + removeId).remove();
  }

  fireSearchQuery(index) {
    var ids = this.emailFormArray.join(",").toString().split(',').toString();
   
    this.query.queryOrder = ids;
    this.query.runby = this.currentuser.id;
    this.advsearch.saveRunQuery(this.query).then(result => {
      this.applicationComponent.searchoffon = true;
      this.toastr.success("Query run successfully.", "Query", { timeOut: 600 });
    
      this.applicationComponent.AdvancedSearchResult = true;
      this.applicationComponent.StandardpopupUi('#AdvancedSearchResult .dialog');
    });


  }
  radioEditSelected(event) {
    $('#btnEditQry').removeAttr('disabled');
    $('#btnRunQry').removeAttr('disabled');
  }

  btnEditQueryClick(event) {

    let selectedIndex: number = parseInt($('input[name=rblEditQuery]:checked').val());

    this.editQryId = this.queryList[selectedIndex].Id;
    let qryName: string = this.queryList[selectedIndex].QryName;
    let searchQry: string = this.queryList[selectedIndex].SearchQry;
    
    this.queryName = qryName;
    this.editquerydate = this.queryList[selectedIndex].editdate;
    let splitString: string[] = searchQry.split(" where ");

    let andConditions: string[] = splitString[1].split(" and ");

    if (this.accountColumns.length == 0)
      this.mapColumnNamesWithDisplayNames(this.accountColumns, Table.Account);

    if (this.contactColumns.length == 0)
      this.mapColumnNamesWithDisplayNames(this.contactColumns, Table.Contact);

    this.accountShow = false;
    this.contactShow = false;

    let count: number = 0;
    andConditions.forEach(str => {
      if (count > 1) {
        let opr: string = "";
        let fieldAndValue: string[] = [""];
        if (str.indexOf("!=") >= 0) {
          fieldAndValue = str.split(" != ");
          opr = "Is Not";
        }
        else if (str.indexOf("=") >= 0) {
          fieldAndValue = str.split(" = ");
          opr = "Equals";
        }
        else if (str.indexOf("is not null") >= 0) {
          fieldAndValue = str.split(" is ");
          fieldAndValue[1] = "is not null";
          opr = "Is Not Empty";
        }

        let isTblContacts: boolean = fieldAndValue[0].split('.')[0] == "con";

        let fieldName: string = fieldAndValue[0].split('.')[1];
        let value: string = fieldAndValue[1];
        while (value.indexOf("'") >= 0) {
          value = value.replace("'", "");
        }

        //if (isTblContacts) {
        let column: ColumnNames = this.contactColumns.find(e => e.columnName == fieldName);
        column.isSelected = true;
        this.advancedSearchService.GetDistinctColumnValues('tblcontacts', column.columnName, this.currentuser.id).then((distinctValues) => {
          column.distinctValues = distinctValues;
          if (value != "is not null")
            column.selectedVal = value;
          else
            column.selectedVal = column.distinctValues[0];
        });
        column.operator = opr;

        this.contactShow = true;

        let actcolumn: ColumnNames = this.accountColumns.find(e => e.columnName == fieldName);
        actcolumn.isSelected = true;
        this.advancedSearchService.GetDistinctColumnValues('tblaccounts', actcolumn.columnName, this.currentuser.id).then((distinctValues) => {
 
          actcolumn.distinctValues = distinctValues;
          if (value != "is not null")
            actcolumn.selectedVal = value;
          else
            actcolumn.selectedVal = actcolumn.distinctValues[0];
        });
        actcolumn.operator = opr;

        this.accountShow = true;

      }

      count++;
    });

    this.quickSearch = false;
    //this.queryLanding = false;
    this.fieldsSelector = false;
    this.valueSelector = true;
  }

  isSelected() {
    if (this.accountColumns.find(col => col.distinctValues.length > 0) || this.contactColumns.find(col => col.distinctValues.length > 0)
      || this.leadColumns.find(col => col.distinctValues.length > 0) || this.TaskColumns.find(col => col.distinctValues.length > 0)
      || this.OpportunityColumns.find(col => col.distinctValues.length > 0))
      return true;
    else
      return false;
  }

  editQuery(Query) {
    if (Query.RunStatus != 'Open') {
      this.accountColumns = [];
      this.contactColumns = [];
      this.editQryId = Query.Id;
      let qryName: string = Query.QryName;
      let searchQry: string = Query.SearchQry;
      let searchType: string = Query.searchtype;

      this.queryName = qryName;
      let splitString: string[] = searchQry.split(" where ");

      if (searchType == "Account") {
        if (this.accountColumns.length == 0)
          this.mapColumnNamesWithDisplayNames(this.accountColumns, Table.Account);
        this.tabType.push('Account');
      }
      else if (searchType == "Account,Contact" || searchType == "Contact") {
        if (this.accountColumns.length == 0)
          this.mapColumnNamesWithDisplayNames(this.accountColumns, Table.Account);
        if (this.contactColumns.length == 0)
          this.mapColumnNamesWithDisplayNames(this.contactColumns, Table.Contact);
        this.tabType.push(searchType);
      }
      this.accountShow = false;
      this.contactShow = false;
      this.LeadsShow = false;
      this.opportunitiesShow = false;
      this.TaskShow = false;

      let count: number = 0;
      let totalcount = 0;
      let andConditions: string[] = splitString[1].split(" and ");
      let allConditions = [];
      let orConditions: string[] = splitString[1].split(" and ");;
      let str: string;
      this.andactiveval = "AND";
      orConditions.forEach(item => {
        str = item.toString();
        if (str.includes(" or ")) {
          str = str.substring(1, str.length - 1);
          orConditions = str.split(" or ");
          let st: string = orConditions[0].substring(0, orConditions[0].indexOf(",")) + orConditions[0].substring(orConditions[0].indexOf(","), orConditions[0].length);
          allConditions.push(st.toString());
        } else if (str.includes(" AND ") || str.includes(" and ")) {
          orConditions = str.split(" AND ");
          orConditions.forEach(substr => {
            let st: string = orConditions[0].substring(0, orConditions[0].indexOf(",")) + orConditions[0].substring(orConditions[0].indexOf(","), orConditions[0].length);
            allConditions.push(substr);
          });

        } else {
          allConditions.push(str);
        }
      })
      let valueArr: string[] = [];

      allConditions.forEach(str => {
        let andoOpr = "";
        let opr: string = "";
        let andOrOperator: string = "";
        let fieldAndValue: string[] = [""];
        if (str.indexOf("!FIND_IN_SET") >= 0) {
          fieldAndValue = str.substr(14).split(",");
          opr = "Is Not";
          andOrOperator = "or";

        }
        else if (str.indexOf("FIND_IN_SET") >= 0) {
          if (str.indexOf("ownerid") >= 0) {
            fieldAndValue = str.substr(12).split(",");
          } else {
            if (str.includes("and") || str.includes("AND")) {
              fieldAndValue = str.substr(12).split(",");
            } else {
              fieldAndValue = str.substr(12).split(",");
            }

          }
          opr = "Equals";
          andOrOperator = "and";
        }
        else if (str.indexOf("is not null") >= 0 || str.indexOf("!= 0") >= 0) {
          fieldAndValue = str.replace('acc.userid =' + this.currentuser.userid + '  AND ', "").replace("con.userid = " + this.currentuser.userid + " and   ", "").split(" is ");
          fieldAndValue[1] = "is not null";
          opr = "Is Not Empty";
        }
        else if (str.indexOf("is null") >= 0 || str.indexOf("= 0") >= 0) {
          fieldAndValue = str.replace('acc.userid = ' + this.currentuser.userid + ' AND ', "").replace("con.userid = " + this.currentuser.userid + " and   ", "").split(" is ");
          fieldAndValue[1] = "is null";
          opr = "Is Empty";
        }
        else {
          if (str.indexOf("tblStatusIds") >= 0) {
            if (str.indexOf("NOT IN(") >= 0) {
              var string1 = str.substr(105).trim();
              var ary = string1.split(" IN(").reverse()
              ary[0] = "acc.accountid123";
              fieldAndValue = ary;
              if (str.indexOf("StatusID1") >= 0) {
                opr = "Is Not";
              } else {
                opr = "Is Empty";
              }

            } else if (str.indexOf(" IN(") >= 0) {
              var string1 = str.substr(68).trim();
              var ary = string1.split(" IN(").reverse();
              ary[0] = "acc.accountid123";
              fieldAndValue = ary;
              if (str.indexOf("StatusID1") >= 0) {
                opr = "Equals";
              } else {
                opr = "Is Not Empty";
              }

            }

          }
          else {
            if (str.indexOf(" IN(") >= 0) {
              var ary = str.split(" IN(");
              ary[1] = ary[1].replace(')', '');
              fieldAndValue = ary;
              opr = "Equals";
            }
            if (str.indexOf(" NOT IN(") >= 0) {
              var ary = str.split(" NOT IN(");
              ary[1] = ary[1].replace(')', '');
              fieldAndValue = ary;
              opr = "Equals";
            }
          }

        }

        let isTblContacts: string = fieldAndValue[0].split('.')[0].replace('(', '');
        let fieldName: string = fieldAndValue[0].split('.')[1];
        fieldAndValue.splice(0, 1);
        let valueStr: string = fieldAndValue.join(",");
        let value: string = (valueStr == "is null") ? valueStr : valueStr.substring(0, valueStr.length - 1);
        let andoropr = andOrOperator;
        while (value != undefined && value.indexOf("'") >= 0) {

          value = value.replace("'", "");
        }
        if (value != "" && (value.includes("and") || value.includes("AND"))) {
          var multipleands = value.split("and");
          multipleands.forEach(str => {
            if (str.includes("FIND_IN_SET")) {

              let valstr: string = (fieldName == 'fax' || fieldName == 'phone' || fieldName == 'alterphone1' || fieldName == 'alterphone2') ? str.split(",")[1].trimRight() : str.split(",")[1].trimRight().replace(')', '');
              if (fieldName == 'fax' || fieldName == 'office' || fieldName == 'phone' || fieldName == 'alterphone1' || fieldName == 'alterphone2') {
                valstr = valstr.charAt(valstr.length - 1) == ')' ? valstr.substring(0, valstr.length - 1) : valstr;
              }
              valueArr.push(isTblContacts.trim() + "~" + fieldName + "~" + opr + "~" + valstr + "~" + andoropr);
            } else {
              valueArr.push(isTblContacts.trim() + "~" + fieldName + "~" + opr + "~" + str.substring(0, str.length - 2) + "~" + andoropr);
            }
          });

        } else {
          if (value != "") {
            if (value == 'is nul' || value == 'is null' || value == 'is not nul' || value == 'is not null') {
              valueArr.push(isTblContacts.trim() + "~" + fieldName + "~" + opr + "~" + value + "~" + "AND");
            }
            else {
              var orvalues = value.split(',');

              orvalues.forEach(str => {
                if (orvalues.length > 1)
                  valueArr.push(isTblContacts.trim() + "~" + fieldName + "~" + opr + "~" + str + "~" + "OR");
                else valueArr.push(isTblContacts.trim() + "~" + fieldName + "~" + opr + "~" + str + "~" + "AND");

              });
            }
            //valueArr.push(isTblContacts + "~" + fieldName + "~" + opr + "~" + value);
          }
        }
        //}
        count++;
      });
      let SelfObj = this;
      setTimeout(function () {
        for (var i = 0; i < valueArr.length; i++) {
          let values = valueArr[i].split("~");
          let arr1 = [];
          if ((values[1] != undefined) && values[1] == 'accountname' || values[1] == 'address1' || values[1] == 'address2' || values[1] == 'homeaddress') {
            arr1 = values[3].split("','");
            arr1[0] = arr1[0].replace("'", "");
          }
          else if ((values[1] != undefined) && values[1] == 'cell' || values[1] == 'phone' || values[1] == 'alterphone1' || values[1] == 'alterphone2' || values[1] == 'fax' || values[1] == 'office') {
            arr1 = values[3].split(",");
          }
          else if ((values[1] != undefined) && values[1] == "accountid123") {
            arr1 = values[3].replace(")", "").split("ND ");
          } else if ((values[1] != undefined)) {
            arr1 = values[3].replace(")", "").split(",");
          }
          let prevValues: string[] = [];
          prevValues = valueArr[i].split("~");
          var added_rows = [];
          arr1.forEach((val) => {
            if (values[0].trim() == 'con') {
              setTimeout(function () {
                let column: ColumnNames = SelfObj.contactColumns.find(e => e.columnName == values[1]);
                let str1: any = "";
                for (var i = 0; i < SelfObj.contactColumns.length; i++) {
                  if (SelfObj.contactColumns[i].columnName == column.columnName && added_rows.indexOf(i) == -1) {
                    SelfObj.contactColumns[i].operator = values[2];
                    SelfObj.contactColumns[i].andOrOperator = values[4];
                    added_rows.push(i);
                    str1 = i;
                    break;
                  }
                }

                let str: any = SelfObj.contactColumns.lastIndexOf(column);
                added_rows.push(str1);
                if (val != arr1[0] || (i > 0 && values[0].trim() == prevValues[0] && values[1] == prevValues[1])) {
                  SelfObj.contactColumns.splice(str + 1, 0, { columnName: values[1], displayName: (val == 'is null' || val == 'is not nul') ? values[1] : 'duplicateCol', distinctValues: [], isSelected: true, selectedVal: "", operator: values[2], andOrOperator: 'and' });
                  column = SelfObj.contactColumns[str + 1];
                  SelfObj.advancedSearchService.GetDistinctColumnValues('tblcontacts', column.columnName, SelfObj.currentuser.id).then((distinctValues) => {
                    if (column.columnName == 'contactid123') {
                      SelfObj.customerService.GetSpecialityList("ContactIds", SelfObj.currentuser.id).then((ContactIds) => {
                        ContactIds.forEach(conid => {
                          if (conid != null) {
                            column.distinctValues.push({ "id": conid.id, "name": conid.itemName })
                          }
                          else {
                            column.distinctValues.push({ "id": null, "name": "No Records Found" })
                          }
                        })
                      });
                    } if (column.columnName == 'STATUS') {
                      SelfObj.customerService.GetPickListData("Contact Status",61, SelfObj.currentuser.id).then((StatusIds) => {
                        StatusIds.forEach(conid => {
                          if (conid != null) {
                            column.distinctValues.push({ "id": conid.id, "name": conid.itemName })
                          }
                          else {
                            column.distinctValues.push({ "id": null, "name": "No Records Found" })
                          }
                        })
                      });
                    
                    } else if (column.columnName == 'ownerid') {
                      SelfObj.customerService.GetAllaccesUserList(SelfObj.currentuser.id).then((Owner) => {
                        distinctValues.forEach(ele => {
                          if (ele != null) {
                            column.distinctValues.push({ "id": Owner.filter(e => e.id == Number(ele))[0].id, "name": Owner.filter(e => e.id == Number(ele))[0].itemName })
                          }
                          else {
                            if (distinctValues.length == 0) {
                              column.distinctValues.push({ "id": null, "name": "No Records Found" })
                            }
                          }

                        });
                      });
                    }
                    else if (column.columnName == 'college' || column.columnName == 'contacttype' || column.columnName == 'fellowship' || column.columnName == 'medschool' || column.columnName == 'residency' || column.columnName == 'sex' || column.columnName == 'suffix' || column.columnName == 'speciality' || column.columnName == 'subspeciality' || column.columnName == 'title') {
                      var listtype = "Contact College";
                      switch (column.columnName) {
                        case "college": {
                          listtype = "Contact College";
                          break;
                        };
                        case "contacttype": {
                          listtype = "Contact Type";
                          break;
                        };
                        case "fellowship": {
                          listtype = "Contact Medical School";
                          break;
                        };
                        case "medschool": {
                          listtype = "Contact Medical School";
                          break;
                        };
                        case "residency": {
                          listtype = "Contact Medical School";
                          break;
                        };
                        case "suffix": {
                          listtype = "Contact Suffix";
                          break;
                        }
                        case "speciality": {
                          listtype = "Account Specialty";
                          break;
                        }
                        case "subspeciality": {
                          listtype = "Account Specialty";
                          break;
                        }
                        case "sex": {
                          listtype = "Contact Sex";
                          break;
                        };
                        case "title": {
                          listtype = "Contact Title";
                          break;
                        };


                      }
                      SelfObj.customerService.GetSpecialityList(listtype, SelfObj.currentuser.id).then((College) => {

                        distinctValues.forEach(dist => {
                          for (var i = 0; i < College.length; i++) {
                            if (College[i].id == Number(dist)) {
                              column.distinctValues.push({ "id": College[i].id, "name": College[i].itemName });
                            }
                          }
                        });
                      });
                     
                    }
                    else {
                      distinctValues.forEach(ele => {
                        if (ele != null) {
                          column.distinctValues.push({ "id": ele, "name": ele })
                        } else {
                          if (distinctValues.length == 0) {
                            column.distinctValues.push({ "id": null, "name": "No Records Found" })
                          }
                        }
                      });
                    }

                    if (column.selectedVal == "")
                      column.selectedVal = distinctValues[0];

                    if (val != "is not null")
                      column.selectedVal = val;
                    else
                      column.selectedVal = column.distinctValues[0];
                  });
                }
                else {
                  column = SelfObj.contactColumns[str];
                  column.isSelected = true;
                  SelfObj.advancedSearchService.GetDistinctColumnValues('tblcontacts', column.columnName, SelfObj.currentuser.id).then((distinctValues) => {
                    if (column.columnName == 'contactid123') {
                      SelfObj.customerService.GetSpecialityList("ContactIds", SelfObj.currentuser.id).then((ContactIds) => {
                        ContactIds.forEach(conid => {
                          if (conid != null) {
                            column.distinctValues.push({ "id": conid.id, "name": conid.itemName })
                          }
                          else {
                            if (ContactIds.length == 0) {
                              column.distinctValues.push({ "id": null, "name": "No Records Found" })
                            }
                          }
                        })
                      });
                    }
                    else if (column.columnName == 'college' || column.columnName == 'contacttype' || column.columnName == 'fellowship' || column.columnName == 'medschool' || column.columnName == 'residency' || column.columnName == 'sex' || column.columnName == 'suffix' || column.columnName == 'speciality' || column.columnName == 'subspeciality' || column.columnName == 'title') {
                      var listtype = "Contact College";
                      switch (column.columnName) {
                        case "college": {
                          listtype = "Contact College";
                          break;
                        };
                        case "contacttype": {
                          listtype = "Contact Type";
                          break;
                        };
                        case "fellowship": {
                          listtype = "Contact Medical School";
                          break;
                        };
                        case "medschool": {
                          listtype = "Contact Medical School";
                          break;
                        };
                        case "residency": {
                          listtype = "Contact Medical School";
                          break;
                        };
                        case "suffix": {
                          listtype = "Contact Suffix";
                          break;
                        }
                        case "speciality": {
                          listtype = "Account Specialty";
                          break;
                        }
                        case "subspeciality": {
                          listtype = "Account Specialty";
                          break;
                        }
                        case "sex": {
                          listtype = "Contact Sex";
                          break;
                        };
                        case "title": {
                          listtype = "Contact Title";
                          break;
                        };


                      }

                      SelfObj.customerService.GetSpecialityList(listtype, SelfObj.currentuser.id).then((College) => {

                        distinctValues.forEach(dist => {
                          for (var i = 0; i < College.length; i++) {
                            if (College[i].id == Number(dist)) {
                              column.distinctValues.push({ "id": College[i].id, "name": College[i].itemName });
                            }
                          }
                        });
                      });

                    }
                    else {
                      distinctValues.forEach(ele => {
                        if (ele != null) {
                          column.distinctValues.push({ "id": ele, "name": ele })
                        } else {
                          if (distinctValues.length == 0) {
                            column.distinctValues.push({ "id": null, "name": "No Records Found" })
                          }
                        }
                      });
                    }

                    if (val != "is not null")
                      column.selectedVal = val;
                    else
                      column.selectedVal = column.distinctValues[0];
                  });
                  column.operator = values[2];
                }
                SelfObj.contactShow = true;
              }, 200)
            }

            if (values[0].trim() == 'acc' || values[0].trim() == "addField" || values[0].trim() == "hist") {
              setTimeout(function () {
                var columnval = values[0].trim() == "hist" ? values[1] : values[1];
                let column: ColumnNames = SelfObj.accountColumns.find(e => e.columnName == columnval);
                let str1: any = "";
                for (var i = 0; i < SelfObj.accountColumns.length; i++) {
                  if (SelfObj.accountColumns[i].columnName == column.columnName && added_rows.indexOf(i) == -1) {
                    SelfObj.accountColumns[i].operator = values[2];
                    SelfObj.accountColumns[i].andOrOperator = values[4];
                   
                    added_rows.push(i);
                    str1 = i;
                    break;
                  }
                }
                if (val != arr1[0] || (i > 0 && values[0].trim() == prevValues[0] && values[1] == prevValues[1])) {
                  SelfObj.accountColumns.splice(str1 + 1, 0, {
                    columnName: values[1],
                    displayName: column.displayName, distinctValues: [], isSelected: true, selectedVal: val, operator: 
                  values[2], andOrOperator: values[4]
                  });

                  column = SelfObj.accountColumns[str1];
                  SelfObj.SelectionChanged(values[4].toString());
                  if (column.columnName === 'speciality') {

                    SelfObj.customerService.GetPickListData("Account Specialty", 21, SelfObj.currentuser.id).then((accounttype) => {
                      accounttype.forEach(dist => {
                        column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                      });
                    });
                    if (val != "is not null")
                      column.selectedVal = val;
                    else
                      column.selectedVal = column.distinctValues[0];
                  }
                  else if (column.columnName == "accountStatus") {
                    column.distinctValues = [];
                    SelfObj.customerService.GetPickListData("Account Customer Status", 5, SelfObj.currentuser.id).then((accounttype) => {
                      accounttype.forEach(dist => {
                        column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                      });
                    });
                    if (val != "is not null")
                      column.selectedVal = val;
                    else
                      column.selectedVal = column.distinctValues[0];
                  }
                  else if (column.columnName === 'SalesRep') {
                    column.distinctValues = [];
                    SelfObj.customerService.GetSalesRep(SelfObj.currentuser.id).then((accounttype) => {
                      accounttype.forEach(dist => {
                        column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                      });

                    });

                    if (val != "is not null")
                      column.selectedVal = val;
                    else
                      column.selectedVal = column.distinctValues[0];
                  }
                  else if (column.columnName === 'accountid123') {
                    SelfObj.userService.GetAllStatusIds(SelfObj.currentuser.id, 0).then(result => {
                      let Doublesortres = SelfObj.DoubleSort(result, 'StatusIDGroupName', 'SortOrder');
                      let Groups = [];
                      Groups.push(Doublesortres.find(x => x.StatusIDGroup == '5000'));
                      Groups.push(Doublesortres.find(x => x.StatusIDGroup == '5001'));

                      for (var i = 0; i < Groups.length; i++) {
                        column.distinctValues.push({ "id": Groups[i].Id, "GroupNumber": Groups[i].StatusIDGroup, "GroupName": Groups[i].StatusIDGroupName, "GroupItems": Doublesortres.filter(x => x.StatusIDGroupName == Groups[i].StatusIDGroupName) });
                        //column.selectedVal = Groups[i].Id;
                      }

                      if (val != "" && val != null) {
                        column.selectedVal = val.trim();
                      }

                    });
                  }
                  else if ((column.columnName == "soldBy" || column.columnName == "leadfromid" || column.columnName == "keycontact1" || column.columnName == "keycontact2"
                    || column.columnName == "keycontact3" || column.columnName == "LastTicketDate")) {
                    SelfObj.advancedSearchService.GetDistinctColumnValues('tblaccounthistory', column.columnName, SelfObj.currentuser.id).then((distinctValues) => {
                      column.distinctValues = [];
                      //if (res[0].DataType == 'Pick List') {
                      distinctValues.forEach(ele => {
                        if (ele != null) {
                          if (column.columnName == "leadfromid") {
                            column.distinctValues = [];
                            SelfObj.customerService.GetSpecialityList("LeadFrom", SelfObj.currentuser.id).then((lead) => {
                              column.distinctValues = [];
                              distinctValues.forEach(dist => {
                                for (var i = 0; i < lead.length; i++) {
                                  if (lead[i].id == Number(dist)) {
                                    column.distinctValues.push({ "id": lead[i].id, "name": lead[i].itemName });
                                  }
                                }
                              });
                            });
                            //col.distinctValues.push({ "id": ele, "name": ele });
                          } else if (column.columnName == "soldBy") {
                            column.distinctValues.push({ "id": ele, "name": ele });
                          } else {

                            column.distinctValues.push({ "id": ele, "name": ele.split(":")[1] });
                          }
                        }
                        else {
                          if (distinctValues.length == 0) {
                            column.distinctValues.push({ "id": null, "name": "No Records Found" })
                          }
                        }
                      });
                      if (val != "is not null")
                        column.selectedVal = val;
                      else
                        column.selectedVal = distinctValues[0];

                    });
                  }
                  else {

                    var table = (values[0].trim() == 'addField') ? 'tblaccountadditionalfields' : 'tblaccounts';
                    SelfObj.advancedSearchService.GetDistinctColumnValues(table, column.columnName, SelfObj.currentuser.id).then((distinctValues) => {

                      if (column.columnName == 'accounttype') {
                        SelfObj.customerService.GetPickListData("Account Type", 3, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });

                      }
                      else if (column.columnName == "ehr" || column.columnName == "pm" || column.columnName == "rcm") {
                        var type = "ehr";
                        var fieldId = 0;
                        switch (column.columnName) {
                          case "ehr": {
                            type = "Account EHR"; fieldId = 22;
                            break;
                          }
                          case "pm": {
                            type = "Account PM"; fieldId = 24; break;
                          }
                          case "rcm": {
                            type = "Account RCM"; fieldId = 26; break;
                          }

                        }

                        SelfObj.customerService.GetPickListData(type, fieldId, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });

                      }
                      else if (column.columnName == "customertypeid") {
                        SelfObj.customerService.GetSpecialityList("Account Active or Inactive", SelfObj.currentuser.id).then((CustomerType) => {
                          distinctValues.forEach(dist => {
                            for (var i = 0; i < CustomerType.length; i++) {
                              if (CustomerType[i].id == Number(dist)) {
                                //column.distinctValues.push(CustomerType[i].itemName)
                                column.distinctValues.push({ "id": CustomerType[i].id, "name": CustomerType[i].itemName });
                              }
                            }
                          });
                        });
                      }
                      else if (column.columnName == "ownership") {
                        SelfObj.customerService.GetPickListData("Account Ownership", 4, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });
                      }
                      else if (column.columnName == "SystemType") {
                        SelfObj.customerService.GetPickListData("SystemType", 122, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });
                      } else if (column.columnName == "HL7Interface") {
                        SelfObj.customerService.GetPickListData("HL7Interface", 123, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });
                      } else if (column.columnName == "CustomerRCM") {
                        SelfObj.customerService.GetPickListData("CustomerRCM", 124, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });
                      } else if (column.columnName == "OnDemand") {
                        SelfObj.customerService.GetPickListData("OnDemand", 125, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });
                      } else if (column.columnName == "ApptReminders") {
                        SelfObj.customerService.GetPickListData("OnDemand", 126, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });
                      } else if (column.columnName == "eLab") {
                        SelfObj.customerService.GetPickListData("eLab", 127, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });
                      } else if (column.columnName == "MIPS") {
                        SelfObj.customerService.GetPickListData("MIPS", 128, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });
                      }
                      else if (column.columnName == 'ownerid') {
                        SelfObj.customerService.GetAllaccesUserList(SelfObj.currentuser.id).then((Owner) => {
                          Owner.forEach(ele => {
                            if (ele != null) {
                              column.distinctValues.push({ "id": ele.id, "name": ele.itemName })
                            }

                          });
                        });
                      }
                     
                      else if (column.columnName == "ehrdatepurchased" || column.columnName == "pmdatepurchased" || column.columnName == "rcmdatepurchased") {
                        distinctValues.forEach(ele => {
                          if (distinctValues.length > 0 && ele != null && ele != "Invalid date") {
                            column.distinctValues.push({ "id": SelfObj.datePipe.transform(ele, 'yyyy-MM-dd') + ' 00:00:00', "name": SelfObj.datePipe.transform(ele, 'yyyy-MM-dd' + ' 00:00:00') })
                          }
                          else {
                            if (distinctValues.length == 0) {
                              column.distinctValues.push({ "id": null, "name": "No Records Found" })
                            }
                          }

                        });
                      }
                     
                      else {
                        let Selfobj = this;
                        distinctValues.forEach(ele => {
                          if (column.columnName == 'IsCustomer') {
                            column.distinctValues.push({ "id": ele, "name": ele == '874' ? 'No' : 'Yes' })
                          }
                          
                          else {
                            column.distinctValues.push({ "id": ele, "name": ele })
                          }
                        });
                      }
                      if (val != "is not null" && val != "") {

                        column.selectedVal = val;
                      }
                      else {
                        column.selectedVal = column.distinctValues[0];
                      }
                    });
                  }


                }
                else {

                  column = SelfObj.accountColumns[str1];
                  column.isSelected = true;

                  if (column.columnName === 'speciality') {
                    SelfObj.customerService.GetPickListData("Account Specialty", 21, SelfObj.currentuser.id).then((accounttype) => {
                      accounttype.forEach(dist => {
                        column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                      });

                    });

                    if (val != "is not null")
                      column.selectedVal = val;
                    else
                      column.selectedVal = column.distinctValues[0];
                  }
                  else if (column.columnName === 'SalesRep') {
                    column.distinctValues = [];
                    SelfObj.customerService.GetSalesRep(SelfObj.currentuser.id).then((accounttype) => {
                      accounttype.forEach(dist => {
                        column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                      });

                    });

                    if (column.selectedVal == "")
                      column.selectedVal = column.distinctValues[0];
                  }

                  else if (column.columnName === 'accountid123') {
                    SelfObj.userService.GetAllStatusIds(SelfObj.currentuser.id, 0).then(result => {
                      let Doublesortres = SelfObj.DoubleSort(result, 'StatusIDGroupName', 'SortOrder');
                      let Groups = [];
                      Groups.push(Doublesortres.find(x => x.StatusIDGroup == '5000'));
                      Groups.push(Doublesortres.find(x => x.StatusIDGroup == '5001'));


                      for (var i = 0; i < Groups.length; i++) {
                        column.distinctValues.push({ "id": Groups[i].Id, "GroupNumber": Groups[i].StatusIDGroup, "GroupName": Groups[i].StatusIDGroupName, "GroupItems": Doublesortres.filter(x => x.StatusIDGroupName == Groups[i].StatusIDGroupName) });
                        //column.selectedVal = Groups[i].Id;
                      }

                      if (val != "" && val != null) {
                        column.selectedVal = val.trim();
                      }

                      //else
                      //  column.selectedVal = column.distinctValues[0].id.trim();


                    });


                  }
                  else {
                  
                    var table = (values[0] == 'addField') ? 'tblaccountadditionalfields' : 'tblaccounts';
                    SelfObj.advancedSearchService.GetDistinctColumnValues(table, column.columnName, SelfObj.currentuser.id).then((distinctValues) => {                     
                      if (column.columnName == 'accounttype') {
                        SelfObj.customerService.GetPickListData("Account Type", 3, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });
                       
                      }
                      else if (column.columnName == "ehr" || column.columnName == "pm" || column.columnName == "rcm") {
                        var type = "ehr"; var fieldId = 0;
                        switch (column.columnName) {
                          case "ehr": {
                            type = "Account EHR"; fieldId = 22; break;
                          }
                          case "pm": {
                            type = "Account PM"; fieldId = 24; break;
                          }
                          case "rcm": {
                            type = "Account RCM"; fieldId = 26; break;
                          }

                        }

                        SelfObj.customerService.GetPickListData(type, fieldId, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });
                       

                      }
                      else if (column.columnName == "customertypeid") {
                        SelfObj.customerService.GetSpecialityList("Account Active or Inactive", SelfObj.currentuser.id).then((CustomerType) => {
                          distinctValues.forEach(dist => {
                            for (var i = 0; i < CustomerType.length; i++) {
                              if (CustomerType[i].id == Number(dist)) {
                                //column.distinctValues.push(CustomerType[i].itemName)
                                column.distinctValues.push({ "id": CustomerType[i].id, "name": CustomerType[i].itemName });
                              }
                            }
                          });
                        });
                      }
                      else if (column.columnName == "ownership") {
                        SelfObj.customerService.GetPickListData("Account Ownership", 4, SelfObj.currentuser.id).then((accounttype) => {
                          accounttype.forEach(dist => {
                            column.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                          });

                        });
                     
                      }
                      else if (column.columnName == "ehrdatepurchased" || column.columnName == "pmdatepurchased" || column.columnName == "rcmdatepurchased") {
                        distinctValues.forEach(ele => {
                          if (ele != null) {
                            column.distinctValues.push({ "id": SelfObj.datePipe.transform(ele, 'yyyy-MM-dd') + ' 00:00:00', "name": SelfObj.datePipe.transform(ele, 'yyyy-MM-dd' + ' 00:00:00') })
                          }
                        });
                      }
                      else if (column.columnName == 'ownerid') {
                        SelfObj.customerService.GetAllaccesUserList(SelfObj.currentuser.id).then((Owner) => {
                          Owner.forEach(ele => {
                            if (ele != null) {
                              column.distinctValues.push({ "id": ele.id, "name": ele.itemName })
                            }

                          });
                        });
                      }
                      else if (column.columnName == 'IsCustomer') {
                        SelfObj.customerService.GetPickListData('IsCustomer', 111, SelfObj.currentuser.id).then((accounttype) => {
                          distinctValues.forEach(ele => {
                            if (ele != null) {
                              column.distinctValues.push({ "id": ele, "name": ele == '874' ? 'No' : 'Yes' });
                            }
                          });
                        });
                      }
                      else if (column.columnName == "SystemType") {
                        SelfObj.customerService.GetPickListData("SystemType", 122, SelfObj.currentuser.id).then((SystemTypes) => {
                          column.distinctValues = [];
                          for (var i = 0; i < SystemTypes.length; i++) {
                            column.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                          }
                        });
                      }
                      else if (column.columnName == "HL7Interface") {
                        SelfObj.customerService.GetPickListData("HL7Interface", 123, SelfObj.currentuser.id).then((SystemTypes) => {
                          column.distinctValues = [];
                          for (var i = 0; i < SystemTypes.length; i++) {
                            column.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                          }
                        });
                      }
                      else if (column.columnName == "CustomerRCM") {
                        SelfObj.customerService.GetPickListData("CustomerRCM", 124, SelfObj.currentuser.id).then((SystemTypes) => {
                          column.distinctValues = [];
                          for (var i = 0; i < SystemTypes.length; i++) {
                            column.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                          }
                        });
                      }
                      else if (column.columnName == "OnDemand") {
                        SelfObj.customerService.GetPickListData("OnDemand", 125, SelfObj.currentuser.id).then((SystemTypes) => {
                          column.distinctValues = [];
                          for (var i = 0; i < SystemTypes.length; i++) {
                            column.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                          }
                        });
                      }
                      else if (column.columnName == "ApptReminders") {
                        SelfObj.customerService.GetPickListData("ApptReminders", 126, SelfObj.currentuser.id).then((SystemTypes) => {
                          column.distinctValues = [];
                          for (var i = 0; i < SystemTypes.length; i++) {
                            column.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                          }
                        });
                      }
                      else if (column.columnName == "eLab") {
                        SelfObj.customerService.GetPickListData("eLab", 127, SelfObj.currentuser.id).then((SystemTypes) => {
                          column.distinctValues = [];
                          for (var i = 0; i < SystemTypes.length; i++) {
                            column.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                          }
                        });
                      } else if (column.columnName == "MIPS") {
                        SelfObj.customerService.GetPickListData("MIPS", 128, SelfObj.currentuser.id).then((SystemTypes) => {
                          column.distinctValues = [];
                          for (var i = 0; i < SystemTypes.length; i++) {
                            column.distinctValues.push({ "id": SystemTypes[i].id, "name": SystemTypes[i].itemName });
                          }
                        });
                      }
                      else {
                        distinctValues.forEach(ele => {
                          if (ele != null) {
                            column.distinctValues.push({ "id": ele, "name": ele.split(":")[1] });
                          }
                        });
                      }
                      if (val != "is not null")
                        column.selectedVal = val;
                      else
                        column.selectedVal = column.distinctValues[0];
                    });
                  }
                  column.operator = values[2];
                  column.andOrOperator = "or";
                }
                SelfObj.accountShow = true;
              }, 200)



            }


          });
          // }
        }
      }, 300);

      this.quickSearch = false;
      //this.queryLanding = false;
      this.fieldsSelector = false;
      this.valueSelector = true;
    } else {
      this.EditConfirmationDialog = true;
      setTimeout(function () {
        $('#EditConfirmationDialog .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
      }, 4)
    }
  }
  notSelectedCss() {
    setTimeout(function () {
      $('#DeleteConfirmationDialog .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4)
  }
  ReceiveDeleteevent(msg) {
    if (msg == "Ok") {
      this.Confirmation('Yes');
    } else {
      this.DeleteConfirmationDialog = false;
    }
  }
  Confirmation(val) {
   
    if (val == 'Yes') {
      if (this.RunStatus != 'Open') {
        this.advancedSearchService.deleteAdvancedSearchQuery(this.editQryId, this.selectstatus, this.currentuser.id).then(() => {
          this.advancedSearchService.getAdvancedSearchQueries("qryname", this.currentuser.id).then((queries) => {
            queries.forEach(queries => {
              queries.createdate = this.datePipe.transform(queries.createdate, "MM/dd/yyyy");
            });
            this.queryList = queries;
            this.emailFormArray = [];
            this.toastr.success('Deleted successfully.', 'Delete!',
              { timeOut: 600 });
          });
        });
      } else {
        this.DeleteConfirmationDialog = false;
      }
    }
    this.DeleteConfirmationDialog = false;
  }
  deleteQuery(QueryId: number, status: number, runstatus: string) {
    this.editQryId = QueryId;
    this.selectstatus = status;
    this.RunStatus = runstatus;
    this.DeleteConfirmationDialog = true;
    this.applicationComponent.salesxcrmDialog('#DeleteConfirmationDialog .dialog');
  }

  btnRunQueryClick(event) {
    let selectedIndex: number = parseInt($('input[name=rblEditQuery]:checked').val());
    this.fireSearchQuery(selectedIndex);
    this.sendMsgEvent.emit("Cancel");
  }
  btnAddNew(event, name, val) {
    this.accountColumns.splice(val + 1, 0, { columnName: name, displayName: 'duplicateCol', distinctValues: [], isSelected: true, selectedVal: "", operator: "Equals", andOrOperator: "and" });
    this.accountColumns.forEach(col => { 
      col.distinctValues = [];
      if (col.isSelected) {
        if (col.columnName === 'speciality') {
          this.customerService.GetPickListData("Account Specialty", 21, this.currentuser.id).then((accounttype) => {
            accounttype.forEach(dist => {
              col.distinctValues.push({ "id": dist.id, "name": dist.itemName });
            });
            if (col.selectedVal == "")
            col.selectedVal = col.distinctValues[0];
          });
        
        }
        else if (col.columnName === 'SalesRep') {
          this.customerService.GetSalesRep(this.currentuser.id).then((accounttype) => {
            accounttype.forEach(dist => {
              col.distinctValues.push({ "id": dist.id, "name": dist.itemName });
            });
            if (col.selectedVal == "")
              col.selectedVal = col.distinctValues[0];
          });
        }
        else if (col.columnName == "accountStatus") {
          col.distinctValues = [];
          this.customerService.GetPickListData("Account Customer Status", 5, this.currentuser.id).then((accounttype) => {
            accounttype.forEach(dist => {
              col.distinctValues.push({ "id": dist.id, "name": dist.itemName });
            });
            if (col.selectedVal == "")
              col.selectedVal = col.distinctValues[0];
          });
        }
        else if (col.columnName === 'IsCustomer' || col.columnName === 'Industry') {

          this.advancedSearchService.GetDistinctColumnValues('tblaccountadditionalfields', col.columnName, this.currentuser.id).then((distinctValues) => {
            col.distinctValues = [];
            distinctValues.forEach(ele => {
              if (ele != null) {
                if (col.columnName == "IsCustomer") {
                  this.customerService.GetPickListData('IsCustomer', 111, this.currentuser.id).then((accounttype) => {
                   
                    col.distinctValues.push({ "id": ele, "name": ele == ('874' ||'872')? 'No' : 'Yes' });

                  });
                } else {
                  col.distinctValues.push({ "id": ele, "name": ele.split(":")[1] });
                }
              }
              else {
                if (distinctValues.length == 0) {
                  col.distinctValues.push({ "id": null, "name": "No Records Found" })
                }
              }
            });
            if (col.selectedVal == "")
              col.selectedVal = distinctValues[0];
          });
        }

        else if ((col.columnName == "soldBy" || col.columnName == "leadfromid" || col.columnName == "keycontact1" || col.columnName == "keycontact2"
          || col.columnName == "keycontact3" || col.columnName == "LastTicketDate")) {
          this.advancedSearchService.GetDistinctColumnValues('tblaccounthistory', col.columnName, this.currentuser.id).then((distinctValues) => {
            col.distinctValues = [];
            distinctValues.forEach(ele => {
              if (ele != null) {
                if (col.columnName == "leadfromid" || col.columnName == "soldBy" ) {
                  if (col.columnName == "leadfromid") {
                    this.customerService.GetSpecialityList("LeadFrom", this.currentuser.id).then((lead) => {
                      col.distinctValues = [];
                      distinctValues.forEach(dist => {
                        for (var i = 0; i < lead.length; i++) {
                          if (lead[i].id == Number(dist)) {
                            col.distinctValues.push({ "id": lead[i].id, "name": lead[i].itemName });
                          }
                        }
                      });
                    });
                  } else {
                    col.distinctValues.push({ "id": ele, "name": ele });
                  }
                } else {
                  col.distinctValues.push({ "id": ele, "name": ele.split(":")[1] });
                }
              }
              else {
                if (distinctValues.length == 0) {
                  col.distinctValues.push({ "id": null, "name": "No Records Found" })
                }
              }
            });
          });
        }
        else {
          this.advancedSearchService.GetDistinctColumnValues('tblaccounts', col.columnName, this.currentuser.id).then((distinctValues) => {
            col.distinctValues = [];
            if (col.columnName == 'accounttype') {
              this.customerService.GetPickListData("Account Type", 3, this.currentuser.id).then((accounttype) => {
                accounttype.forEach(dist => {
                  col.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                });

              });
             
            }
            else if (col.columnName == "ehr" || col.columnName == "pm" || col.columnName == "rcm") {
              var type = "ehr"; var fieldId = 0;
              switch (col.columnName) {
                case "ehr": {
                  type = "Account EHR"; fieldId = 22;break;
                }
                case "pm": {
                  type = "Account PM"; fieldId = 24; break;
                }
                case "rcm": {
                  type = "Account RCM"; fieldId = 26; break;
                }

              }
              this.customerService.GetPickListData(type, fieldId, this.currentuser.id).then((accounttype) => {
                accounttype.forEach(dist => {
                  col.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                });

              });
        
            }
            else if (col.columnName == "customertypeid") {
              this.customerService.GetSpecialityList("Account Active or Inactive", this.currentuser.id).then((CustomerType) => {
                distinctValues.forEach(dist => {
                  for (var i = 0; i < CustomerType.length; i++) {
                    if (CustomerType[i].id == Number(dist)) {
                      col.distinctValues.push({ "id": CustomerType[i].id, "name": CustomerType[i].itemName });
                    }
                  }
                });
              });


            }
            else if (col.columnName == "accountid123") {
              this.userService.GetAllStatusIds(this.currentuser.id, 0).then(result => {
                let Doublesortres = this.DoubleSort(result, 'StatusIDGroupName', 'SortOrder');
                let Groups = [];
                Groups.push(Doublesortres.find(x => x.StatusIDGroup == '5000'));
                Groups.push(Doublesortres.find(x => x.StatusIDGroup == '5001'));


                distinctValues.forEach(ele => {

                  for (var i = 0; i < Groups.length; i++) {
                    col.distinctValues.push({ "GroupNumber": Groups[i].StatusIDGroup, "GroupName": Groups[i].StatusIDGroupName, "GroupItems": Doublesortres.filter(x => x.StatusIDGroupName == Groups[i].StatusIDGroupName) });

                  }

                });
              });

            }
            else if (col.columnName == "ownership") {
              this.customerService.GetPickListData("Account Ownership", 4, this.currentuser.id).then((accounttype) => {
                accounttype.forEach(dist => {
                  col.distinctValues.push({ "id": dist.id, "name": dist.itemName });
                });

              });
            
            }
            else if (col.columnName == 'ownerid') {             
              col.distinctValues = [];         
              this.customerService.GetAllaccesUserList(this.currentuser.id).then((Owner) => {
                Owner.forEach(ele => {
                  if (ele != null) {
                    col.distinctValues.push({ "id": ele.id, "name": ele.itemName })
                  }
                  else {
                    if (distinctValues.length == 0) {
                      col.distinctValues.push({ "id": null, "name": "No Records Found" })
                    }
                  }

                });
              });
            }
            else {
              distinctValues.forEach(ele => {
                if (ele != null) {
                  col.distinctValues.push({ "id": ele, "name": ele })
                }
                else {
                  if (distinctValues.length == 0) {
                    col.distinctValues.push({ "id": null, "name": "No Records Found" })
                  }
                }

              });
            }
            if (col.selectedVal == "")
              col.selectedVal = distinctValues[0];
          });
        }
      } else
        col.distinctValues = [];

    });

  }
  btnAccRemove(val) {
    if (val == 0) {
      this.accountColumns.length = 0;
    }
    $("#" + (val + 2)).remove();
    this.accountColumns.splice(val, 1);
  }
  btnContactRemove(val) {
    if (val == 0) {
      this.contactColumns.length = 0;
    }
    $("#" + (val + 2)).remove();
    this.contactColumns.splice(val, 1);
  }
  btnAdvanvedRunQueryClick(event) {
    if (this.selectedQueryCount > 0) {
      localStorage.setItem('selectedQueryCount', this.selectedQueryCount.toString());
      this.fireSearchQuery(0);
      this.applicationComponent.selectedsubbutton = 'AdvancedSearchResult';   
    } else {
      this.NoRowDialog = true;
      setTimeout(function () {
        $('#NoRowDialog .dialog').attr('style', 'width: 25%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%;right: 20%;');
      }, 4)
    }
  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.selectedQueryCount += 1;
        this.emailFormArray.push(event.api.getSelectedNodes()[0].data.Id);
      }
    }
    localStorage.setItem('selectedQueryCount', this.selectedQueryCount.toString());
  }
  onChange(email: string, isChecked: boolean) {
    if (isChecked == true) {
      this.selectedQueryCount += 1;
      this.emailFormArray.push(email);
    } else if (isChecked == false) {
      this.selectedQueryCount -= 1;
      let index = this.emailFormArray.indexOf(email);
      this.emailFormArray.splice(index, 1);
    }
    localStorage.setItem('selectedQueryCount', this.selectedQueryCount.toString());
  }

  enableQuickSearchBtn() {

    let cnt: number = 0;
    for (let i = 0; i < this.quickSearchColumns.length; i++) {
      if ($('#txtQuickSearchCol' + i).val() == "")
        cnt++;
    }
    if (cnt == this.quickSearchColumns.length)
      return false;
    else
      return true;
  }
  selectedColList(type, columnName, index) {

    var id = "";
    if (type == 'Contact') {
      id = "chkContactCol" + index;
    } else if (type == 'Account') {
      id = "chkAccountCol" + index;
    }
    if ($('#' + id).prop("checked")) {
      this.selectedColumnList.push({ 'type': type, 'Name': columnName });
    }
    else {
      this.selectedColumnList = this.selectedColumnList.filter(x => x.Name != columnName);
    }
  }
  EditConfirmation() {
    this.EditConfirmationDialog = false;   
  }
  DoubleSort(arr, prop1, prop2) {
    let mysort = [...arr].sort((a, b) => {
      if (a[prop1] == b[prop1]) {
        if (a[prop2] == b[prop2]) {
          return 0;
        } else {
          return (a[prop2] < b[prop2]) ? -1 : 1;
        }
      } else {
        return (a[prop1] > b[prop1]) ? -1 : 1;
      }
    });
    return mysort;
  }

  openCloseQueries() {
    this.closeOpenDialog = true;
    this.crmDialogCss();
  }
  crmDialogCss() {
    $(setTimeout(function () {
      $('#closeOpenDialog .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 4));
  }
  closeopenqueries() {
   
    this.query.queryOrder = "";
    this.query.runby = this.currentuser.id;
    this.advsearch.closeRunQuery(this.query).then(result => {
      this.applicationComponent.searchoffon = false;
      this.GetAdvancedsearchQueries();
      this.toastr.success("Open Queries Closed successfully.", "Query", { timeOut: 600 });
      this.closeOpenDialog=false;
    });
  }
}
