
<div class="col-xs-12" style="padding:0;height:98%;margin-top:1%">

  <form #userform="ngForm" style="height:100%;display:flex">

    <div class="col-xs-2" style="height: 100%; width: 15%">
      <div class="col-xs-12" style="padding: 0; height: 100%; border-right: 5px solid rgb(186,219,152) ">
        <div class="col-xs-12" style="padding: 0; height: 5%; color: black; line-height: 28px;" (click)="AccessDiv('MessageTypes')" [style.background]="selectedtab == 'MessageTypes' ? 'rgb(186,219,152)': 'white'">
          <input style="display:none" type="radio" name="tabset" id="tab1" value="tab1" aria-controls="bano" checked />
          <label for="tab11" style="text-align:center;font-weight:normal" (click)="AccessDiv('MessageTypes')">Message Types</label>
        </div>
        <div class="col-xs-12" style="padding: 0; height: 5%; color: black; line-height: 28px;" (click)="AccessDiv('New Message')" [style.background]="selectedtab == 'New Message' ? 'rgb(186,219,152)': 'white'">
          <input type="radio" name="tabset" id="tab2" value="tab2" aria-controls="rauchbier" style="display:none">
          <label for="tab12" style="text-align: center; font-weight: normal" (click)="AccessDiv('New Message')">Create New Messages</label>
        </div>
        <div class="col-xs-12" style="padding: 0; height: 5%; color: black; line-height: 28px;" (click)="AccessDiv('Messages List')" [style.background]=" selectedtab == 'Messages List' ? 'rgb(186,219,152)': 'white'">
          <input style="display:none" type="radio" name="tabset" id="tab3" value="tab3" aria-controls="marzen">
          <label for="tab13" style="text-align: center; font-weight: normal" (click)="AccessDiv('Messages List')">View Previous Messages</label>
        </div>


      </div>
    </div>

    <div class="col-xs-10" style="padding:0;">

      <div class="col-xs-12" style="height:93%;padding:0%;">
        <div class="col-xs-12" style="padding:0;height:100% " *ngIf="MessageTypesDiv">
          <div class="col-xs-12" style="padding: 0; height:9%">
            <label style="font-weight: 600">Message Types represents the type of messaging being communicated to users in SalesXCRM such as Review Past Due Tasks, Sales Reports Due Today, Company Reminders, Company News, etc.. The system allows the users to add, edit or delete Message Types. A Message Type will need to be created before a user can Create and Send Messages. </label>
          </div>
          <div class="col-xs-7" style="padding:0;height:100%">
            <div class="col-xs-12" style="height:7%;padding:0;">
              <div class="col-xs-6" style="text-align:left;padding:0;">

                <img src="../../../assets/img/Create New Document Name.png" (click)="CreateNewMessageType()" style="height:30px" />

              </div>

            </div>
            <div class="col-xs-12" style="padding:0;height:87%;border:1px solid grey">

              <ag-grid-angular style="height:100%" class="ag-theme-balham"
                               [gridOptions]="gridOptions"
                               [columnDefs]="columnDefs"
                               [defaultColDef]="applicationComponent.defaultGridColDef"
                               [rowData]="allMessageTypes"
                               [rowDragManaged]="true"
                               [sideBar]="sideBar"
                               [animateRows]="true"
                               (selectionChanged)="onSelectionChanged($event)"
                               (rowDragEnd)="onRowMessageDragEnd($event)">
              </ag-grid-angular>
            </div>
          </div>

          <div class="col-xs-5" style="padding:0;height:100%">
            <form #AddMessageTypeForm="ngForm" style="height:100%">

              <div class="col-xs-12" style="height:12%;margin: 2% 2% 2% 5%;padding:0;width:95%">
                <div class="col-xs-2" style="height:100%;padding:0;"><label> Message Type</label></div>
                <div class="col-xs-9" style="height:100%;padding:0;"><textarea name="MessageTypes.MessageType" [(ngModel)]="MessageTypes.MessageType" [required]="createNewMessageType" [disabled]="!createNewMessageType" style="width:100%;height:100%;"></textarea></div>
              </div>
              <div class="col-xs-12" style="height:6%;margin:1% 2% 2% 5%;padding:0;width:95%">
                <div class="col-xs-2" style="padding:0;height:100%"><label>Sort Order</label></div>
                <div class="col-xs-9" style="padding:0;height:100%"><input type="text" [(ngModel)]="MessageTypes.Sortorder" name="MessageTypes.Sortorder" [disabled]="!createNewMessageType" [required]="createNewMessageType" style="width:100%;height:100%" /></div>
              </div>
              <div class="col-xs-12" style="padding:0;height:60%">

              </div>
              <div class="col-xs-12" style="padding:0;margin-top:2%;height:6%">
                <div class="col-xs-7"></div>
                <div class="col-xs-2" style="display:block;padding:0">
                  <button class="btn noprovider" style="width:100%;background: lightgray;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="ClearMessageType()">Cancel</button>
                </div>
                <div class="col-xs-2 pull-right" style="display:block;padding:0;margin-right:1%">
                  <button class="btn noprovider" (click)="SaveProposalCategory()" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" [disabled]="(!AddMessageTypeForm.form.valid)">Save</button>
                </div>
              </div>
            </form>

          </div>
        </div>

        <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="NewMessageDiv">

          <div class="col-xs-12" style="padding: 0%; height: 12%">
            <label style="font-weight: 600">
              Create New Messages will allow a user to create messages and send those messages to users that are selected to receive the message. Messages can be sent immediately or scheduled for a future date. Messages will display at the Dashboard when the user logs for the date of the message.
            </label>
          </div>
          <div class="col-xs-6" style="height:100%;padding:0 1% 0 0">
            <div class="col-xs-12" style="height:100%;border:1px solid lightgrey">
              <form #AddMessageForm="ngForm" style="height:100%">


                <div class="col-xs-12" style="padding:0;height:7%">
                  <div class="col-xs-4" style="height:100%;text-align:right"><label style="margin-top:3%">Subject</label> </div>
                  <div class="col-xs-8" style="height:100%;text-align:left">
                    <input class="form-control" type="text" [(ngModel)]="userMessages.MessageName" name="userMessages.MessageName" required style="width:100%;height:100%" />
                  </div>
                </div>

                <div class="col-xs-12" style="padding:0;height:7%;margin-top:1%">
                  <div class="col-xs-4" style="height:100%;text-align:right"><label style="margin-top:3%">Message Type</label> </div>
                  <div class="col-xs-8" style="height:100%;text-align:left">
                    <select class="form-control" id="MessageType" name="MessageType" [(ngModel)]="userMessages.MessageTypeId" required>
                      <option value="undefined" disabled [selected]="true">Please Select Message Type</option>
                      <option *ngFor="let type of allMessageTypes" value={{type.MessageTypeID}}>
                        {{type.MessageType}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-xs-12" style="padding:0;height:7%;margin-top:1%">
                  <div class="col-xs-4" style="height:100%;text-align:right">
                    <label style="margin-top:3%">Date To Display Message</label>
                  </div>
                  <div class="col-xs-8" style="height:100%;text-align:left">
                    <input class="form-control" type="date" #mycal name="DisplayDate" [(ngModel)]="userMessages.DisplayDate" placeholder="Select Date" id="date" required style="padding:0;" />
                  </div>
                </div>

                <div class="col-xs-12" style="padding: 0; margin-top: 1%; height: 60%;">
                  <div class="col-xs-4" style="text-align:right"><label style="margin-top:3%">Message Description</label> </div>

                  <div class="col-xs-8 edits" id="froala-editor" [froalaEditor]="editorOptions" required
                       [(froalaModel)]="MessageDescription" style="padding-right: 0%;height:90%"></div>
                </div>

                <div class="col-xs-12" style="padding:0;height:7%;margin-top:1%">
                  <div class="col-xs-4" style="height:100%;padding:0">

                  </div>
                  <div class="col-xs-8" style="height:100%;text-align:left" *ngIf="!EditMessageMode">                    
                    <div class="col-xs-5" style="padding:0">
                      <button class="btn noprovider" style="width:100%;background: lightgray;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;">Cancel</button>
                    </div>
                    <div class="col-xs-7" style="padding:0;">
                      <button class="btn noprovider" (click)="SaveMessage()" style="width: 100%; background: rgb(162, 228, 119); color: black; font-weight: 700; border: 1px solid #000; border-radius: 3px;" [disabled]="(!AddMessageForm.form.valid)">Save Message</button>
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>

          <div class="col-xs-6" style="padding-right: 0.3%;padding-left: 0;height:94%">

            <div class="col-xs-12" style="padding:0px !important;height:7%">

              <div class="col-xs-12 noprovider" style="text-align:center;margin-top:0%;padding:0 !important;height:100%" *ngIf="!EditMessageMode">
                <div class="tabset1 col-xs-12" style="padding:0;height:100%;">
                  <input type="radio" name="tabset1" id="tab6" value="tab6" aria-controls="Users" [(ngModel)]="UserTabSelection" checked />
                  <label for="tab6" style="text-align:center;width:50%" (click)="UserSetUp('Users')">Users</label>

                  <input type="radio" name="tabset1" id="tab8" value="tab8" aria-controls="SuspendedUsers" [(ngModel)]="UserTabSelection" checked />
                  <label for="tab8" style="text-align: center; width: 50%;width:auto; padding: 1%; margin-top: 0.5%" (click)="UserSetUp('Suspended')">Suspended / Inactive Users</label>

                </div>

              </div>

              <div class="col-xs-5 " style="border: 1px solid black;height: 75%;text-align: center;padding: 0;font-size:12px;" *ngIf="EditMessageMode">
                <label style="margin-top:2%">Total: {{userMessages.MessageToUserCount}}&nbsp;&nbsp; Viewed: {{userMessages.readuserCount}}&nbsp;&nbsp; Not Viewed: {{userMessages.RemainingCount}}</label>
              </div>

            </div>

            <div *ngIf="(selectedUserType=='Users'|| selectedUserType=='Suspended')" class="col-xs-12 noprovider" style="text-align:center;padding:0.75%;margin-top:0%;height:98%">

              <div class="row" style="margin-left: 0;margin-right: 0;margin-top:0%;padding-left: 0.1%; border-radius:3px;overflow:auto;height:5%">
                <label>List Of Users</label>
              </div>
              <div class="col-xs-12" style="padding:0;height:87%">
                <ag-grid-angular class="ag-theme-balham" *ngIf="selectedUserType=='Users'|| selectedUserType=='Suspended'"
                                 style="height:100%"
                                 [gridOptions]="gridOptionsusers"
                                 [columnDefs]="columnDefsusers"
                                 [defaultColDef]="applicationComponent.defaultGridColDef"
                                 [rowData]="allusers"
                                 [sideBar]="sideBar"
                                 [context]="context"
                                 (selectionChanged)="onUserSelection($event)">
                </ag-grid-angular>
              </div>

            </div>


          </div>
        </div>

        <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ViewMessageListDiv">
          <div class="col-xs-12" style="padding:0%;height:12%">
            <label>
              <span>
                View Previous Messages will allow a user to view a history of previous messages sent to users in the system
              </span>
            </label>
          </div>
          <div class="col-xs-12" style="padding:0 1% 0 0%;height:87%">
            <ag-grid-angular style="width: 100%;height:100%" id="gridheight" class="ag-theme-balham"
                             [gridOptions]="gridOptionsUserMessages"
                             [columnDefs]="columnDefsMessage"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [sideBar]="sideBar"
                             [rowData]="allMessages"
                             (columnVisible)="columnVisible($event)"
                             (dragStopped)="dragStopped($event)"
                             (columnResized)="columnResized($event)"
                             (selectionChanged)="onUserMessageSelection($event)">
            </ag-grid-angular>
          </div>

        </div>
      </div>
    </div>


  </form>

</div>
<app-dialog [(visible)]="DeleteMessageTypePopup" *ngIf="DeleteMessageTypePopup" id="DeleteMessageTypePopup" [closable]="false">
  <app-delete-popup [title]="'Message Type'" [description]="(contactStatus==1)?'Activate this Message Type Selected?':'Delete this Message Type Selected?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteeventForType($event)">
  </app-delete-popup>

</app-dialog>
<app-dialog [(visible)]="DeleteMessagePopup" *ngIf="DeleteMessagePopup" id="DeleteMessagePopup" [closable]="false">
  <app-delete-popup [title]="'Message'" [description]="(contactStatus1==1)?'Activate this Message Selected?':'Delete this Message Selected?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteeventForMessage($event)">
  </app-delete-popup>

</app-dialog>


  <script>


    $('#froala-editor').on('froalaEditor.contentChanged', function (e, editor) {
  
    });
    new FroalaEditor('.selector', {
      events: {
        'contentChanged': function () {
      
        }
      }
    });

</script>
