export class TicketConfiguration {
  public id: number;
  public SalesXcrm: number;
  public ActiveTask: number;
  public ActiveUser: number;
  public ActivateZendesk: number;
  public SelectedTaskGroupID: string;
  public SelectedUserID: string;
  public UserId: number;
 
}
