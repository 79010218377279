import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-editdelete',
  templateUrl: './editdelete.component.html',
  styleUrls: ['./editdelete.component.css']
})
export class EditdeleteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public params: any;

  agInit(params: any): void {
    this.params = params;
  }
  needDeletebtn() {
    return (document.getElementById('customers').className == 'mybtn coustomButton mainactiveButton') ? false : true;
  }
  needAuditbtn() {
    var url = window.location.href;
    if (url.indexOf('salestool') > -1) {
      return (document.getElementById('customers').className == 'mybtn coustomButton mainactiveButton') ? false : true;
    }
  }
  public ActiateRowAg() {
    this.params.context.deleteRowAg(this.params.node.data);
  }
  public editRowAg() {    
    this.params.context.editRowAg(this.params.node.data);
  }

  public deleteRowAg() {
    this.params.context.deleteRowAg(this.params.node.data);
  }
  public auditRowAg() {
    this.params.context.auditRowAg(this.params.node.data);

  }
  needEditbtn() {
    return (document.getElementById('viewcalllist_button').className == 'subButtons activeButton') ? false : true;
  }
  refresh(): boolean {    
    return false;
  }
}
