<input type="text" id="emailIds" style="display:none;" />
<input type="text" id="tasklistselectedtyle" style="display:none;" />
<input type="text" id="accids" style="display:none;" />
  <div class="col-xs-12" style="padding: 0%;height:100%">
    <div class="col-xs-12" style="padding: 0%;background:#fff;height:100%">
      
        <div class="col-xs-12" style="padding:0.3% 0 0.3% 0;height:10.5%" id="actiondivid">
          <userdataheader [parentComp]="'Reports'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
        </div>
        <div class="col-xs-12" id="UserActivityDetail" *ngIf="ShowUserActivityDetail" style="margin-top:0;padding:0%; height:89%" [style.border]="(applicationComponent.searchCriteriadata != '')?'3px solid #00B0F0':'none'">
          
          <div style="padding:0;height:100%" [ngClass]="(ShowActivityDetailsTask)? 'col-xs-4':'col-xs-12'">
            <div class="col-xs-12" style="padding:0;height:4%;" *ngIf="ShowActivityDetailsTask">
                <span (click)="ShowActivityDetailsTask = false;gridOptions.api.deselectAll();"><img src="../../../assets/img/Black x.png" style="height: 21px; float: LEFT; transform: rotate(180deg);" /></span>
                <b style="margin-left:3%"> User Activity (Details) ({{(alltaskhistory && alltaskhistory.length != undefined)? alltaskhistory.length : 0}})</b>
                
            </div>
            <div class="col-xs-12" style="padding:0;" [style.height.%]="(ShowActivityDetailsTask)? '89.5':'100'">
             <ag-grid-angular style="width: 100%;height:100%;  margin-top:0" class="ag-theme-balham"
                               [gridOptions]="gridOptions"
                               [columnDefs]="columnDefs"
                               [defaultColDef]="applicationComponent.defaultGridColDef"
                               [rowData]="alltaskhistory"
                               [context]="context"
                               [sideBar]="sideBar"
                               [pagination]="true"
                               [frameworkComponents]="frameworkComponents"
                               (selectionChanged)="TaskhistorySelectionChanged($event)"
                               (columnVisible)="columnVisible($event)"
                               (dragStopped)="dragStopped($event)"
                               (columnResized)="columnResized($event)"
                               (gridReady)="onAgGridReady($event)">
              </ag-grid-angular>
            </div>
          </div>

          <div class="col-xs-8" style="padding:0;height:100%" *ngIf="ShowActivityDetailsTask">
            <div class="col-xs-12" style="padding:0 0 0 1%;height:100%" >
              <scheduletask [id]="id" [taskid]="taskid" [type]="type" (sendMsgEvent)="onReceiveMessage($event)"></scheduletask>
            </div>
          </div>

        </div>


        <div class="col-xs-12" id="UserActivitySummary" *ngIf="ShowUserActivitySummary" style="border-radius: 3px;padding:0;height:92%">
          <div class="col-xs-12" style="padding:0;height:65%;" *ngIf="!UserActivitySummaryCheckedDiv">
            
              <div [ngClass]="(this.contactinformationDiv) ? 'col-xs-12 userlist' :'col-xs-3 userlist'" *ngFor="let summary of usersummaryList" id="{{summary.id}}" style="padding-left:0;padding-right:0.5%;height:100%;font-size:15px;">
                <div class="col-xs-12" style="background: rgb(186,219,152) !important;color: black;height:8%;padding:0 0 0 2%"><p style="padding:1%;margin:0;font-size:15px;font-weight:500">User&nbsp;&nbsp;{{summary.username}}<span class="pull-right" style="cursor:pointer" (click)="closeUserTable(summary.id)"><img src="../../../assets/img/Black x.png" style="height: 21px; float: LEFT; transform: rotate(180deg);" /></span></p></div>

                <div class="col-xs-12" style="padding:0;height:84%">
                  <table class="table table-striped" style="padding:0;margin:0;height:80%">
                    <tr class="closeButtonTr" style="height:10%" id="PhoneCallsScheduled">
                      <td style="border: 1px solid #A6A4A4;width:10%;text-align:center"><input type="checkbox" id="{{summary.id}}_phone_calls" [(ngModel)]="this.AccActChkBox.isPhoneScheduled" (change)="checkValue($event,'PhoneCallsScheduled','Phone|Scheduled',summary.id,'phone_calls',summary.username,summary.PhoneCallsScheduled)" /></td>
                      <td style="border: 1px solid #A6A4A4;padding:1.3%;width:70%"><b>Phone Calls </b>(Scheduled)</td>
                      <td style="border: 1px solid #A6A4A4;width:20%;text-align:center">{{summary.PhoneCallsScheduled}}</td>
                    </tr>
                    <tr class="closeButtonTr" style="height:10%" id="PhoneCallsCompleted">
                      <td style="border: 1px solid #A6A4A4;width:10%;text-align:center"><input type="checkbox" id="{{summary.id}}_phone_calls_Completed" [(ngModel)]="AccActChkBox.isPhoneCOmpleted" (change)="checkValue($event,'PhoneCallsCompleted','Phone|Completed',summary.id,'phone_calls_Completed',summary.username,summary.PhoneCallsCompleted)" /></td>
                      <td style="border: 1px solid #A6A4A4; padding:1.3%;width:70%"><b> Phone Calls </b>(Completed)</td>
                      <td style="border: 1px solid #A6A4A4;width:20%;text-align:center">{{summary.PhoneCallsCompleted}}</td>
                    </tr>
                    <tr class="closeButtonTr" style="height:10%" id="ToDoScheduled">

                      <td style="border: 1px solid #A6A4A4;width:10%;text-align:center"> <input type="checkbox" id="{{summary.id}}_to_do_sche" [(ngModel)]="AccActChkBox.isToDoScheduled" (change)="checkValue($event,'ToDoScheduled','To Do|Scheduled',summary.id,'to_do_sche',summary.username,summary.ToDoScheduled)" /></td>
                      <td style="border: 1px solid #A6A4A4;width:70%;padding:1.3%;"> <b>To Do's </b>(Scheduled)</td>
                      <td style="border: 1px solid #A6A4A4;width:20%;text-align:center">{{summary.ToDoScheduled}}</td>
                    </tr>
                    <tr class="closeButtonTr" style="height:10%" id="TodoCompleted">

                      <td style="border: 1px solid #A6A4A4;width:10%;text-align:center"> <input type="checkbox" id="{{summary.id}}_to_do_Completed" [(ngModel)]="AccActChkBox.isTodoCompleted" (change)="checkValue($event,'TodoCompleted','To Do|Completed',summary.id,'to_do_Completed',summary.username,summary.ToDoCompleted)" /></td>
                      <td style="border: 1px solid #A6A4A4;width:70%;padding:1.3%;"><b>To Do's</b> (Completed)</td>
                      <td style="border: 1px solid #A6A4A4;width:20%;text-align:center">{{summary.ToDoCompleted}}</td>
                    </tr>
                    <tr class="closeButtonTr" style="height:10%" id="MeetingsScheduled">
                      <td style="border: 1px solid #A6A4A4;width:10%;text-align:center"> <input type="checkbox" id="{{summary.id}}_meeting_sche" [(ngModel)]="AccActChkBox.isMeetingsScheduled" (change)="checkValue($event,'MeetingsScheduled','Meeting|Scheduled',summary.id,'meeting_sche',summary.username,summary.MeetingScheduled)" /></td>
                      <td style="border: 1px solid #A6A4A4;width:70%;padding:1.3%;"><b>Meetings </b>(Scheduled)</td>
                      <td style="border: 1px solid #A6A4A4;width:20%;text-align:center">{{summary.MeetingScheduled}}</td>
                    </tr>
                    <tr class="closeButtonTr" style="height:10%" id="MeetingsCompleted">
                      <td style="border: 1px solid #A6A4A4;width:10%;text-align:center"><input type="checkbox" id="{{summary.id}}_meeting_Completed" [(ngModel)]="AccActChkBox.isMeetingsCompleted" (change)="checkValue($event,'MeetingsCompleted','Meeting|Completed',summary.id,'meeting_Completed',summary.username,summary.MeetingCompleted)" /></td>
                      <td style="border: 1px solid #A6A4A4;width:70%;padding:1.3%;"><b>Meetings </b> (Completed)</td>
                      <td style="border: 1px solid #A6A4A4;width:20%;text-align:center">{{summary.MeetingCompleted}}</td>
                    </tr>
                    <tr class="closeButtonTr" style="height:10%" id="AccountsAdded">
                      <td style="border: 1px solid #A6A4A4;width:10%;text-align:center"><input type="checkbox" id="{{summary.id}}_new_add" [(ngModel)]="AccActChkBox.isAccountsAdded" (change)="checkValue($event,'AccountsAdded','Account',summary.id,'new_add',summary.username,summary.NewAccountsAdded)" /></td>
                      <td style="border: 1px solid #A6A4A4;width:70%;padding:1.3%;"><b>Accounts </b> (Added)</td>
                      <td style="border: 1px solid #A6A4A4;width:20%;text-align:center">{{summary.NewAccountsAdded}}</td>
                    </tr>
                    <tr class="closeButtonTr" style="height:10%" id="ContactsAdded">

                      <td style="border: 1px solid #A6A4A4;width:10%;text-align:center"> <input type="checkbox" id="{{summary.id}}_new_contact" [(ngModel)]="AccActChkBox.isContactsAdded" (change)="checkValue($event,'ContactsAdded','NewContact',summary.id,'new_contact',summary.username,summary.NewContactsAdded)" /></td>
                      <td style="border: 1px solid #A6A4A4;width:70%;padding:1.3%;"><b>Contacts</b> (Added)</td>
                      <td style="border: 1px solid #A6A4A4;width:20%;text-align:center">{{summary.NewContactsAdded}}</td>
                    </tr>
                    <tr class="closeButtonTr" style="height:10%" id="LeadsConverted">
                      <td style="border: 1px solid #A6A4A4;width:10%;text-align:center"> <input type="checkbox" id="{{summary.id}}_leads_converted" [(ngModel)]="AccActChkBox.isLeadsConverted" (change)="checkValue($event,'LeadsConverted','Lead',summary.id,'leads_converted',summary.id,summary.LeadConverted)" /></td>
                      <td style="border: 1px solid #A6A4A4;width:70%;padding:1.3%;"><b>Leads </b> (Converted)</td>
                      <td style="border: 1px solid #A6A4A4;width:20%;text-align:center">{{summary.LeadConverted}}</td>
                    </tr>
                    <tr class="closeButtonTr" style="height:10%" id="OpportunitiesAdded">

                      <td style="border: 1px solid #A6A4A4;width:10%;text-align:center"> <input type="checkbox" id="{{summary.id}}_new_oppo" [(ngModel)]="AccActChkBox.isOpportunitiesAdded" (change)="checkValue($event,'OpportunitiesAdded','Opportunity',summary.id,'new_oppo',summary.username,summary.NewOpportunitiesAdded)" /></td>
                      <td style="border: 1px solid #A6A4A4;width:70%;padding:1.3%;"><b>Opportunities </b> (Added)</td>
                      <td style="border: 1px solid #A6A4A4;width:20%;text-align:center">{{summary.NewOpportunitiesAdded}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            


          </div>

          <div class="col-xs-12" *ngIf="UserActivitySummaryCheckedDiv" style="padding:0;height:100%">
            <div [ngClass]="(TaskListInfo)? 'col-xs-4':'col-xs-12'" style="height:100%;padding:0;">
              <div style="height:4%">
                <span (click)="CloseInfoDiv()"><img src="../../../assets/img/Black x.png" style="height: 21px; float: LEFT; transform: rotate(180deg);" /></span>
                <b style="margin-left:3%"> {{headerlabel}}  ({{GridCount}})</b>
                <span><img src="../../../assets/img/Open Quickview Grid Panel.png" style="height: 23px; float: right; transform: rotate(180deg);" *ngIf="TaskListInfo" /></span>
              </div>
             A <ag-grid-angular style="width: 100%;height:95% " class="ag-theme-balham" *ngIf="showSelectedtaskHistory"
                               [gridOptions]="gridselectedOptions"
                               [columnDefs]="selectedcolumnDefs"
                               [defaultColDef]="applicationComponent.defaultGridColDef"
                               [context]="context"
                               [rowData]="alltaskhistory"
                               [sideBar]="sideBar"
                               [frameworkComponents]="frameworkComponents"
                               (selectionChanged)="TaskhistoryrowClickAgGrid($event)"
                               (columnVisible)="TaskhistorycolumnVisible($event)"
                               (dragStopped)="TaskhistorydragStopped($event)"
                               (columnResized)="TaskhistorycolumnResized($event)"
                               (gridReady)="selectedonAgGridReady($event)">
              </ag-grid-angular>

             B <ag-grid-angular style="width: 100%;height:100%" class="ag-theme-balham" *ngIf="showNewContactAddedTable"
                               [gridOptions]="NewContactgridOptions"
                               [columnDefs]="NewContactcolumnDefs"
                               [defaultColDef]="applicationComponent.defaultGridColDef"
                               [rowData]="allNewContact"
                               [context]="context"
                               [sideBar]="sideBar"
                               [frameworkComponents]="frameworkComponents"
                               (columnVisible)="NewContactcolumnVisible($event)"
                               (dragStopped)="NewContactdragStopped($event)"
                               (columnResized)="NewContactcolumnResized($event)"
                               (selectionChanged)="NewContactrowClickAgGrid($event)"
                               (gridReady)="NewContactonAgGridReady($event)">
              </ag-grid-angular>
             C <ag-grid-angular style="width: 100%;height:100%" class="ag-theme-balham" *ngIf="showNewAccountTable"
                               [gridOptions]="NewAccountgridOptions"
                               [columnDefs]="AccountcolumnDefs"
                               [defaultColDef]="applicationComponent.defaultGridColDef"
                               [rowData]="allCustomers"
                               [context]="context"
                               [sideBar]="sideBar"
                               [frameworkComponents]="frameworkComponents"
                               (selectionChanged)="AccountrowClickAgGrid($event)"
                               (columnVisible)="AccountcolumnVisible($event)"
                               (dragStopped)="AccountdragStopped($event)"
                               (columnResized)="AccountcolumnResized($event)"
                               (gridReady)="AccountonAgGridReady($event)">
              </ag-grid-angular>
             D <ag-grid-angular style="width: 100%;height:100%" class="ag-theme-balham" *ngIf="showSaleProcessHistoryTable"
                               [gridOptions]="LeadgridOptions"
                               [columnDefs]="LeadcolumnDefs"
                               [defaultColDef]="applicationComponent.defaultGridColDef"
                               [rowData]="allLeads"
                               [context]="context"
                               [sideBar]="sideBar"
                               [frameworkComponents]="frameworkComponents"
                               (selectionChanged)="LeadrowClickAgGrid($event)"
                               (columnVisible)="LeadcolumnVisible($event)"
                               (dragStopped)="LeaddragStopped($event)"
                               (columnResized)="LeadcolumnResized($event)"
                               (gridReady)="LeadonAgGridReady($event)">
              </ag-grid-angular>
            E  <ag-grid-angular style="width: 100%; height:100%" class="ag-theme-balham" *ngIf="showOpprtunitiesHistoryTable"
                               [gridOptions]="OpportunitiesgridOptions"
                               [columnDefs]="OpportunitiescolumnDefs"
                               [defaultColDef]="applicationComponent.defaultGridColDef"
                               [rowData]="Allopportunities"
                               [context]="context"
                               [sideBar]="sideBar"
                               [frameworkComponents]="frameworkComponents"
                               (selectionChanged)="OpportunitiesrowClickAgGrid($event)"
                               (columnVisible)="OpportunitiescolumnVisible($event)"
                               (dragStopped)="OpportunitiesdragStopped($event)"
                               (columnResized)="OpportunitiescolumnResized($event)"
                               (gridReady)="OpportunitiesonAgGridReady($event)">
              </ag-grid-angular>

            </div>
            <div class="col-xs-8" style="height:100%;padding:0;" *ngIf="TaskListInfo">
              <div class="col-xs-12" style="padding:0 0 0 1%;height:100%" *ngIf="showSelectedtaskHistory">
                <scheduletask [id]="id" [taskid]="taskid" [type]="type" ></scheduletask>
              </div>
              <div class="col-xs-12" style="padding:0 0 0 1%;height:100%" *ngIf="showNewAccountTable">
                <account-contact [type]="'Account'" [contactId]="'show'+accountids" [tab]="'QuickView'"></account-contact>

              </div>

              <div class="col-xs-12" style="padding:0 0 0 1%;height:100%" *ngIf="showNewContactAddedTable">
                <contactsdashboard [contactids]="contactids" [tab]="'QuickView'"></contactsdashboard>

              </div>

              <div class="col-xs-12" style="padding:0 0 0 1%;height:100%" *ngIf="showSaleProcessHistoryTable">
                <leads [contactid]="contactids" [id]="leadid"></leads>

              </div>

              <div class="col-xs-12" style="padding:0 0 0 1%;height:100%" *ngIf="showOpprtunitiesHistoryTable">
                <opportunities [typeSelected]="'Customer'" [contactid]="contactids" [OpportunityID]="opportunityids" [type]="typ">

                </opportunities>

              </div>

            </div>
          </div>
        </div>


        <div class="col-xs-12" style="padding:0;height:89.5%" *ngIf="OtherStandardReports">
          <app-other-standard-reports [SelectedReportType]="ReportType" [SelectedCategory]="Category" [StartDate]="startDate" [EndDate]="EndDate"></app-other-standard-reports>
        </div>

    </div>
  </div>



<app-dialog [(visible)]="CallHistoryDialog">
  <calllisthistory [id]="contactid" [type]="callhistorystatus"></calllisthistory>
</app-dialog>

<app-dialog [(visible)]="showDialog" *ngIf="showDialog" [CloseButtonColor]="'white'">
 
  <app-new-notes style="height:100%"
                 [selectedTabName]="'Tasks Only'"
                 [conid]="conid"
                 [typ]="'Contact'"
                 [isModal]="true">
  </app-new-notes>

</app-dialog>

<input type="text" id="accotIdNotes" style="display:none;" />
<input type="text" id="accotTypeNotes" style="display:none;" />
