import { Component, OnInit, Input, ViewChild, Optional } from '@angular/core';
import { CustomerService } from './../../services/customer.service';
import { Tickets } from '../../models/tickets';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { EditdeleteComponent } from '../editdelete/editdelete.component';
import { ContactsdashboardComponent } from '../../application/contacts/contactsdashboard/contactsdashboard.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationComponent } from '../application.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NotesrendererComponent } from '../notesrenderer/notesrenderer.component';
import { EditdeletecvComponent } from '../editdeletecv/editdeletecv.component';
import { EditactivatecvComponent } from '../editactivatecv/editactivatecv.component';
import { TicketNotes } from 'src/app/models/TicketNotes';
import { Dropdowntype } from 'src/app/models/dropdowntype';
import { Accounts } from 'src/app/models/accounts';
import { Viewaccount } from 'src/app/models/viewaccount';
import { Contacts } from 'src/app/models/contacts';
import { OpprtunitiesService } from 'src/app/services/opprtunities.service';
import { UserService } from 'src/app/services/user.service';
import { DatePipe, Location } from '@angular/common';
declare var $: any;

@Component({
  selector: 'tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {
  @Input()
  type: string;
  ticketslist: Array<Tickets> = [];
  Openticketslist: Array<Tickets> = [];
  Pendedticketslist: Array<Tickets> = [];
  Closedticketslist: Array<Tickets> = [];
  OnHoldticketslist: Array<Tickets> = [];
  Deletedticketslist: Array<Tickets> = [];
  duplicateticketslist: Array<Tickets> = [];
  datePipe: DatePipe = new DatePipe("en-US");
  tickets: Tickets = new Tickets();
  public ticketsgridOptions: GridOptions;
  public ticketscolumnDefs: any[];
  currentuser: any;
  source: string;
  public context;
  public frameworkComponents;
  public ticketView = true;
  contactid: number;
  ContOrAccType: string;
  tab: string = "Search";
  showAssignToDiv = false;
  showCCToDiv = false;
  ticketTaskAssignTo: any;
  ticketUserAssignTo: any;
  selectedUsersTasks = [];
  selectedTaskAssignTo = [];
  selectedUserAssignTo = [];
  selectedNames = '';
  selectedCCNames = '';
  selectedCCNamesArray = [];
  selectedCCTaskListArray = [];
  selectedCCUserListArray = [];
  ticketform: FormGroup;
  ticketNotes: TicketNotes = new TicketNotes();
  Filter: string = "All Tasks Groups and Users";
  sortOrder: string = "None";
  contactList: Dropdowntype[] = [];
  public contact: Contacts = new Contacts();
  public account: Accounts = new Accounts();
  viewaccount: Viewaccount = new Viewaccount();
  SortedContactType;
  SortedStatus;
  SelectedStatus;
  SelectedContactType;
  allContactsproviders: Contacts[];
  Contacttotal: Contacts[];
  allContacts: Contacts[];
  public gridOptions: GridOptions;
  itemCount = 0;
  public columnDefs: any[];
  showTicketsDetailsDialog: boolean = false;
  TicketDetails: Tickets = new Tickets();
  notesshowDialog: boolean = false;
  documentview: boolean = false;
  showDialog: boolean = false;
  openTicket: boolean = false;
  expanded: boolean = false;
  showAddEditAccountDialog: boolean = false;
  leadstatus;
  public sideBar;
  @ViewChild(ContactsdashboardComponent) contactComp;
  DeleteConfirmationPopup: boolean = false;
  CreateTicketDialog: boolean = false;
  CloseCreateNewWarning: boolean = false;
  deleteTcketid: any;
  contactStatus: any; specialNotes: string = "";
  constructor(private route: ActivatedRoute, private toastr: ToastrService, private customerService: CustomerService, private opprtunityservice: OpprtunitiesService, private location: Location, private router: Router,
    public applicationComponent: ApplicationComponent, private userservice: UserService)
  {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));    
  }

  ngOnInit() {
    this.initializeAgGrid();
    this.CreateTicketDialogCss();
    $('#showDetailsDialog').hide();
    this.source = this.type;
    this.applicationComponent.SearchSelectedItems = [];
    this.route.params.subscribe(params => {
      
      this.contactid = params['contactid'];
      localStorage.setItem('RhxContactVal', this.contactid.toString());
      this.ContOrAccType = params['ContOrAccType'];
      if (this.ContOrAccType == 'Contact') {
        this.LoadAccountandContacts(this.contactid);
      } else if (this.ContOrAccType == 'Account' || this.ContOrAccType == 'Customer') {
        this.tickets.contactid = null;
        this.LoadAccountandContactsByAccountID(this.contactid)
      }
      this.tickets.UserName = this.currentuser.username;
      this.tickets.priority = 0;
      this.loadtickets();
    });
    var url = window.location.href;
    if (url.indexOf('contactsdashboard') < 0 || url.indexOf('contactsdashboard') < 0) {
      this.applicationComponent.pageViewed = "CreateTicket";
      $(".subButtons").removeClass("activeButton");
      $("#AddTickets_button").addClass("activeButton");
    } 
    
    this.ticketform = new FormGroup({
      'AssignTicketTo': new FormControl(this.selectedNames, Validators.required),
      'Description': new FormControl(this.tickets.descriptions, Validators.required),
      'priority': new FormControl(this.tickets.priority, Validators.required),
      'subject': new FormControl(this.tickets.subject, Validators.required)
    });
    //this.applicationComponent.hideandShowSubtabs(false, true, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false);
  }
  initializeAgGrid() {
    this.ticketsgridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: false, paginationPageSize: 1000,toolPanelSuppressPivotMode: false, toolPanelSuppressValues: false, rowSelection: 'single'
    };
    let columnSelections: string;
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },

      ],
      position: 'right',

    };
    this.createticketscolumnDefs("");
    this.ticketsgridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {      
      editdelete: EditdeleteComponent
    };
  
  }
  closeDetailsDialog() {
    $('#showDetailsDialog').hide();
  }

  loadcontactdatatoticket(contactid) {
    this.customerService.GetContactById(contactid).then(result => {
      if (result && result.title == null) {
        result.title = "";
      }
      if (result && result.firstname == null) {
        result.firstname = "";
      }
      if (result && result.middlename == null) {
        result.middlename = "";
      }
      if (result && result.lastname == null) {
        result.lastname = "";
      }
   
      this.contactid = result.id;
      this.tickets.contactid = result.id;
      this.tickets.accountName = result.accountname;
      this.tickets.businessphone = result.office;
      this.tickets.cellphone = result.cell;
      this.tickets.contactname = result.firstname + ' ' + result.middlename + ' ' + result.lastname + ' ' + result.title ;
      this.tickets.email = result.email;
      this.tickets.UserID = result.ownerid;
      this.tickets.UserName = this.currentuser.username;
      if (this.ContOrAccType == 'contact') {
        this.contactComp.Loadcontactdata(contactid);
      }
      this.loadtickets();
    });
    
  }
  OpenTicketDetails() {
    this.openTicket = true;
    this.CreateTicketDialogCss();
  }
  HideTicket() { this.openTicket = false; this.CreateTicketDialogCss(); }
  LoadAccountandContacts(ContactId) {
    this.tickets.contactid = ContactId;
    this.customerService.GetContactById(ContactId).then(result => {
      if (result) {
        this.contact = result;
        this.tickets.email = result.email;
       
        this.tickets.businessphone = result.office;
        this.tickets.cellphone = result.cell;
        this.customerService.loadaccountdata(result.accountid).then(result => {
          this.account = result;
          this.specialNotes = this.account.specialNotes;
          this.tickets.accountName = this.account.accountname;
          this.Display(this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata);
          this.customerService.GetAccountContactList(this.account.id).then((conatct) => {
            this.contactList = conatct;
            
          });
        });
      }

    });
  }

  LoadAccountandContactsByAccountID(accountid) {
    this.initializeAgGridAccount();
    this.customerService.loadaccountdata(accountid).then(result => {
      this.account = result;
      this.specialNotes = this.account.specialNotes;
      this.tickets.accountName = this.account.accountname;
      this.Display(this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata);
      this.GetAllProviderAndContactInfo(this.account.id);
      this.customerService.GetAccountContactList(this.account.id).then((conatct) => {
        this.contactList = conatct;
        this.contactList.forEach(p => {
        });
      });
    });
  }
  Display(accountname, addr1, addr2, city, state, zip, country, phone, fax, website, Accountiddata) {

    //var properCity = city.charAt(0).toUpperCase() + city.substr(1).toLowerCase();
    if (city != null) {
      var cityArray = city.split(' ');
      var newCity = '';
      for (var i = 0; i < cityArray.length; i++) {
        if (newCity == '') {
          newCity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
        else {
          newCity = newCity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
      }
    }
    if (website != null && website.indexOf('www.') >= 0) {
      website = ((website).split('www.')[1])
    } else if (website != null && website.indexOf('ww.') >= 0) {
      website = ((website).split('ww.')[1])
    }


    var properstate = (state != null) ? state.charAt(0).toUpperCase() + state.substr(1).toLowerCase() : "";
    this.viewaccount.viewaccountname = accountname;
    this.viewaccount.viewaddress1 = addr1;
    this.viewaccount.viewaddress2 = addr2;
    this.viewaccount.viewcity = newCity == null ? "" : newCity;
    this.viewaccount.viewstate = properstate;
    this.viewaccount.viewzip = zip;
    this.viewaccount.viewCountry = country;
    this.viewaccount.viewphone = phone;
    this.viewaccount.viewfax = fax;
    this.viewaccount.viewwebsite = website;
    this.viewaccount.Accountiddata = Accountiddata;

  }

  GetAllProviderAndContactInfo(accountid) {
    this.initializeAgGrid();
    this.customerService.GetAllContactproviderByAccount(accountid, this.currentuser.id).then((contacts) => {
      contacts.forEach(contacts => {
        //this.getTicketsData(contacts.id);
      });
      this.allContactsproviders = contacts;
      this.allContacts = contacts;
      var rew = Array.from(new Set(this.allContactsproviders.map(s => s.contacttype)))
        .map(contacttype => {
          return {
            Name: contacttype + " (" + this.allContactsproviders.filter(x => x.contacttype == contacttype).length + ")",
            Value: contacttype
          }
        });
      this.SelectedContactType = "All";
      this.SortedStatus = [
        { id: 2, Value: 'Active & Inactive' + " (" + this.allContactsproviders.length + ")" },
        { id: 1, Value: 'Active' + " (" + this.allContactsproviders.filter(x => x.Status == 1).length + ")" },
        { id: 0, Value: 'Inactive' + " (" + this.allContactsproviders.filter(x => x.Status == 0).length + ")" }

      ];
      this.SelectedStatus = 1;
      //this.ContactStatusFilter(this.SelectedStatus)

      this.SortedContactType = rew;

      this.itemCount = contacts.length;
    });
    //if (this.account.id == 0) {
    //  this.GetCustomerNotes(accountid, 'Customer')
    //} else {
    //  this.GetCustomerNotes(accountid, 'Customer')
    //}

  }

  initializeAgGridAccount() {
    this.gridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'

    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
       
      ],
      position: 'right',

    };
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent,
      editdeletecv: EditdeletecvComponent,
      editactivatecv: EditactivatecvComponent
    };

    //this.createColumnDefs("");

    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 23).then((config) => {
      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        //this.gridOptions.api.paginationSetPageSize(config.maxlimit);
        //this.pageSize = config.maxlimit.toString();
      }

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      //this.colSelections = columnSelections;
      this.createColumnDefs(columnSelections);
    });

    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
  }
  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleArray[0] == "#") {

          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
          });
        }
        else if (fieldHeaderVisibleArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleArray[3]) });
        }
        else if (fieldHeaderVisibleArray[0] == "actions") {
          this.columnDefs.push({ field: 'actions', headerName: 'Actions', cellRenderer: isDeActive ? 'editactivatecv' : 'editdeletecv', hide: false, pinned: 'right', width: 80 });
        }
        else if (fieldHeaderVisibleArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, width: parseInt(fieldHeaderVisibleArray[3]) });
        } else if (fieldHeaderVisibleArray[0] == "image") {
          this.columnDefs.push({
            field: 'image', headerName: 'Image', hide: false, width: parseInt(fieldHeaderVisibleArray[3]), cellRenderer(params) {
              var eDiv = document.createElement('div');
              //eDiv.innerHTML = '<button class="btn-simple btn-sm" style="backround:white;box-shadow:none;border:none"><i class="fa fa-file-pdf-o" aria-hidden="true" style="color:blue"></i></button>';

              if (params.data.photo != null) {
                eDiv.innerHTML = '<img class="btn-simple" style="width:40px;height:30px" src="' + params.data.photo + '"/>';

                var eButton = eDiv.querySelectorAll('.btn-simple')[0];
                eButton.addEventListener('click', function () {
                  //selfObj.openfile(params.data.photo);
                });
              }


              return eDiv;
            }
          });
        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleArray[0],
            headerName: fieldHeaderVisibleArray[1],
            hide: fieldHeaderVisibleArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 32,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        { field: 'lastname', headerName: 'Last Name', hide: false, width: 120 },
        { field: 'firstname', headerName: 'First Name', hide: false, width: 120 },
        { field: 'middlename', headerName: 'Middle Name', hide: false, width: 120 },
        { field: 'title', headerName: 'Title', hide: false, width: 80 },
        {
          field: 'notes', headerName: 'Notes', cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.style.textAlign = 'center';
            eDiv.style.alignContent = 'center';
            eDiv.innerHTML = "Notes";
            eDiv.addEventListener('click', function () {
              //selfObj.openNotesDialogAg(params.data.id);
            });
            return eDiv;
          }, hide: false, width: 80
        },
        {
          field: 'image', headerName: 'Image', hide: false, width: 100, cellRenderer(params) {
            var eDiv = document.createElement('div');
            if (params.data.photo != null) {
              eDiv.innerHTML = '<img class="btn-simple" style="width:40px;height:30px" src="' + params.data.photo + '"/>';

              //eDiv.innerHTML = '<button class="btn-simple btn-sm" style="backround:white;box-shadow:none;border:none"><i class="fa fa-file-pdf-o" aria-hidden="true" style="color:blue"></i></button>';
              var eButton = eDiv.querySelectorAll('.btn-simple')[0];
              eButton.addEventListener('click', function () {
                //selfObj.openfile(params.data.photo);
              });
            }
            return eDiv;
          }
        },
        { field: 'speciality', headerName: 'Specialty', hide: false },
        { field: 'accountStatus', headerName: 'Account Status', hide: false },
        { field: 'DOB', headerName: 'DOB', hide: false, width: 80 },
        { field: 'zip', headerName: 'ZIP', hide: false, width: 80 },
        { field: 'accountname', headerName: 'Account Name', hide: false },
        { field: 'actions', headerName: 'Actions', cellRenderer: isDeActive ? 'editactivatecv' : 'editdeletecv', hide: false, pinned: 'right', width: 100 },
        { field: 'contacttype', headerName: 'Contact Type', hide: true },  //true starts here
        { field: 'suffix', headerName: 'Suffix', hide: true },
        { field: 'sex', headerName: 'Sex', hide: true },
        { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
        { field: 'email', headerName: 'Email', hide: true },
        { field: 'address1', headerName: 'Address1', hide: true },
        { field: 'address2', headerName: 'Address2', hide: true },
        { field: 'city', headerName: 'City', hide: true },
        { field: 'state', headerName: 'State', hide: true },
        { field: 'cell', headerName: 'Cell', hide: true },
        { field: 'office', headerName: 'Office', hide: true },
        { field: 'homeaddress', headerName: 'Home Address', hide: true },
        { field: 'homecity', headerName: 'Home City', hide: true },
        { field: 'homestate', headerName: 'Home State', hide: true },
        { field: 'homezip', headerName: 'Home Zip', hide: true },
        { field: 'home', headerName: 'Home', hide: true },
        { field: 'college', headerName: 'College', hide: true },
        { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
        { field: 'medschool', headerName: 'Medschool', hide: true },
        { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
        { field: 'residency', headerName: 'Residency', hide: true },
        { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
        { field: 'fellowship', headerName: 'Fellowship', hide: true },
        { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
        { field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true },
        { field: 'created', headerName: 'Created', hide: true }
      ];
    }
  }
  rowClickAgGridAccount(rowEvent) {
    if (rowEvent.node.selected && rowEvent.node.selected === true) {
      this.loadcontactdatatoticket(rowEvent.data.id);
    }
    
  }
  selectionTicketChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (rowEvent.api.getSelectedNodes()[0].data) {
        this.showTicketsDetailsDialog = true;
        this.TicketDetails = rowEvent.api.getSelectedNodes()[0].data;
        this.loadcontactdatatoticket(rowEvent.api.getSelectedNodes()[0].data.id)
      }
    } else {
      //this.showActivityDetailsDialog = false;
      //this.taskhistory = null;
    }

  }
  ContactTypeFilter(val) {
    if (val == 'All') {
      this.Contacttotal = this.allContacts;
      if (this.SelectedStatus != 2) {
        this.allContactsproviders = this.allContacts.filter(x => x.Status == this.SelectedStatus);
      } else {
        this.allContactsproviders = this.allContacts;
      }
    } else {
      this.Contacttotal = this.allContacts.filter(x => x.contacttype == val);
      if (this.SelectedStatus != 2) {
        this.allContactsproviders = this.allContacts.filter(x => x.contacttype == val && x.Status == this.SelectedStatus);
      }
      else {
        this.allContactsproviders = this.allContacts.filter(x => x.contacttype == val);
      }

    }
    this.SortedStatus = [
      { id: 2, Value: 'Active & Inactive' + " (" + this.Contacttotal.length + ")" },
      { id: 1, Value: 'Active' + " (" + this.Contacttotal.filter(x => x.Status == 1).length + ")" },
      { id: 0, Value: 'Inactive' + " (" + this.Contacttotal.filter(x => x.Status == 0).length + ")" }

    ];

  }
  ContactStatusFilter(val) {
    if (val == 2) {
      if (this.SelectedContactType == 'All')
        this.allContactsproviders = this.allContacts;
      else {
        this.allContactsproviders = this.allContacts.filter(x => x.contacttype == this.SelectedContactType);
      }
    } else {
      if (this.SelectedContactType != 'All') {
        this.allContactsproviders = this.allContacts.filter(x => x.Status == val && x.contacttype == this.SelectedContactType);
      }
      else {
        this.allContactsproviders = this.allContacts.filter(x => x.Status == val);
      }

    }
    //this.SelectedContactType = "All";
  }
  editRowAg(rowdata) {
  }
  deleteRowAg(rowdata) {
    this.customerService.DeleteTicket(rowdata.Id).then((user) => {
      this.loadtickets();
    });
  }
  delete(id) {
    this.DeleteConfirmationPopup=true;
    this.deleteTcketid = id;
    setTimeout(function () {
      $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4);
  }
  ReceiveDeleteevent(msg) {
    if (msg == "Ok") {
      this.customerService.DeleteTicket(this.deleteTcketid).then((user) => {
      this.loadtickets();
    });
    }
    this.DeleteConfirmationPopup = false;  
    this.showTicketsDetailsDialog = false;  
  }
  rowClickAgGrid(rowEvent) {
    $('#showDetailsDialog').show();
    var gridOffset = $("#taskGrid").offset();
    $('#showDetailsDialog').css('top', gridOffset.top);
    $('#showDetailsDialog').css('height', '44%');
    $('#showDetailsDialog').css('left', '74%');

    $('#showDetailsDialog').css('border-radius', '4px');
    $('#showDetailsDialog').css('border', '3px solid');
    $('#showDetailsDialog').show();
    $('#txtLongNoteDesc').empty();
    $('#txtLongNoteDesc').append(rowEvent.data.description);
  }
  createticketscolumnDefs(columnSelections: string) {
    let selfObj = this;    
    this.ticketscolumnDefs = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      { field: 'ticketId', headerName: 'Ticket', hide: true },
      { field: 'createdDate', headerName: 'Created Date', hide: false },
      { field: 'contactname', headerName: 'Contact name', hide: false },
      { field: 'email', headerName: 'Email', hide: false },
      { field: 'UserName', headerName: 'User Name', hide: false },
      { field: 'userList', headerName: 'Assign Ticket To', hide: false },
      { field: 'subject', headerName: 'Subject', hide: false },
      { field: 'descriptions', headerName: 'Description', hide: false },
      {
        field: 'TicketStatus', headerName: 'Status', hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          var text = '';
          if (params.value == 1) {
            text = 'Open';
          }
          else {
            text = 'Closed'
          }
          eDiv.innerHTML = text;
          return eDiv;
        }
      },
      {
        field: 'actions', width:50, headerName: 'Actions', cellRenderer(params) {

            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<i style="font-weight: 400;font-size: 14px;" class="fa fa-trash" aria - hidden="true" > </i>';
            eDiv.addEventListener('click', function () {
              selfObj.delete(params.data.ticketId);
            });
            return eDiv;

        },
        hide: false, pinned: 'right'
      }
    ];

  }
  savetickets() {
    this.tickets.UserID = this.currentuser.id;
    this.tickets.TicketStatus = 1;
    this.customerService.addticket(this.tickets).then(result => {
      if (result != null) {
        this.tickets = result;
        this.saveNotes(this.tickets);
        
      }
    });
  }

  saveNotes(tickets) {
    this.ticketNotes.NoteId = 0;
    this.ticketNotes.UserID = this.currentuser.id;
    this.ticketNotes.TicketId = tickets.ticketId;
    this.ticketNotes.Notes = this.tickets.descriptions;
    this.ticketNotes.NotesType = "Ticket";
    this.customerService.addticketNotes(this.ticketNotes).then(result => {
      let that = this;
      setTimeout(function () {
        that.applicationComponent.ticketcreateurl = '';      
        that.applicationComponent.activateMainButtons('tickets');
        that.applicationComponent.ExpandSelectedMenu('tickets');
        that.router.navigateByUrl(`/application/zendeskhome/0/Open Tickets`);
      }, 500)
      this.toastr.success("Ticket saved successfully.", "Ticket Saved", { timeOut: 600 });
    })
  }

  loadtickets() {
    this.ticketslist = [];
    this.duplicateticketslist = [];
    if (this.ContOrAccType == 'Contact') {
      this.customerService.GetTicketsListByContact(this.contactid, 'All').then((tickets) => {
        this.applicationComponent.OpenTicketCount = tickets.filter(p => p.TicketStatus == 1).length;
        this.Openticketslist = tickets.filter(p => p.TicketStatus == 1);
        this.applicationComponent.OpenTicketCountLoginUser = tickets.filter(p => p.TicketStatus && (p.assignTicketToUser != null && p.assignTicketToUser == this.currentuser.id)).length;
        this.applicationComponent.OnHoldTicketCount = tickets.filter(p => p.TicketStatus == 2).length;
        this.OnHoldticketslist = tickets.filter(p => p.TicketStatus == 2);
        this.applicationComponent.PendedTicketCount = tickets.filter(p => p.TicketStatus == 3).length;
        this.Pendedticketslist = tickets.filter(p => p.TicketStatus == 3);
        this.applicationComponent.ClosedTicketCount = tickets.filter(p => p.TicketStatus == 4).length + tickets.filter(p => p.TicketStatus == 5).length;;
        //this.applicationComponent.ClosedTicketCount += tickets.filter(p => p.TicketStatus == 5).length;
        this.applicationComponent.AllTicketCount = tickets.length;
        this.applicationComponent.UnReadOpenTicketCount = tickets.filter(p => p.readstatus == 0 && (p.assignTicketToUser != null && p.assignTicketToUser == this.currentuser.id)).length;

      });
    } else {
      this.customerService.GetTicketsListByAccount(this.contactid, 'All').then((tickets) => {
        this.ticketslist = tickets;
        this.applicationComponent.OpenTicketCount = tickets.filter(p => p.TicketStatus == 1).length;
        this.Openticketslist = tickets.filter(p => p.TicketStatus == 1);
        this.applicationComponent.OpenTicketCountLoginUser = tickets.filter(p => p.TicketStatus && (p.assignTicketToUser != null && p.assignTicketToUser == this.currentuser.id)).length;
        this.applicationComponent.OnHoldTicketCount = tickets.filter(p => p.TicketStatus == 2).length;
        this.OnHoldticketslist = tickets.filter(p => p.TicketStatus == 2);
        this.applicationComponent.PendedTicketCount = tickets.filter(p => p.TicketStatus == 3).length;
        this.Pendedticketslist = tickets.filter(p => p.TicketStatus == 3);
        this.applicationComponent.ClosedTicketCount = tickets.filter(p => p.TicketStatus == 4).length + tickets.filter(p => p.TicketStatus == 5).length;;
        this.applicationComponent.AllTicketCount = tickets.length;
        this.applicationComponent.UnReadOpenTicketCount = tickets.filter(p => p.readstatus == 0 && (p.assignTicketToUser != null && p.assignTicketToUser == this.currentuser.id)).length;

      });
    }
  }

  //GetAllTicketsForCountUpdate(filter, sortOrder) {     
  //  this.applicationComponent.ClosedTicketCount = 0;
  //  this.customerService.GetTicketsList('All Tickets', filter, this.currentuser.id, sortOrder, false, null).then((tickets) => {
  //    alert("2" + this.applicationComponent.OpenTicketCount);
  //    this.applicationComponent.OpenTicketCount = tickets.filter(p => p.TicketStatus == 1).length;
  //    this.applicationComponent.OpenTicketCountLoginUser = tickets.filter(p => p.TicketStatus && (p.assignTicketToUser != null && p.assignTicketToUser == this.currentuser.id)).length;
  //    this.applicationComponent.OnHoldTicketCount = tickets.filter(p => p.TicketStatus == 2).length;
  //    this.applicationComponent.PendedTicketCount = tickets.filter(p => p.TicketStatus == 3).length;
  //    this.applicationComponent.ClosedTicketCount += tickets.filter(p => p.TicketStatus == 4).length;
  //    this.applicationComponent.ClosedTicketCount += tickets.filter(p => p.TicketStatus == 5).length;
  //    this.applicationComponent.AllTicketCount = tickets.length;
  //    this.applicationComponent.UnReadOpenTicketCount = tickets.filter(p => p.readstatus == 0 && (p.assignTicketToUser != null && p.assignTicketToUser == this.currentuser.id)).length;
  //  });

  //}
  onEventReceived(msg) {
  
    this.ticketView = false;
    this.tab = 'tickets';
  }

  ShowAssignTo() {
    this.showAssignToDiv = true;
    this.showAssignToDivCss('AssignTo');
    this.GetTicketUserLists();
    this.getSelectedTaskGroups('0,1');
  }
  showAssignToDivCss(val) {
    setTimeout(function () {
      $('#showAssignToDiv .dialog').attr('style', 'width: 30%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: hidden;height: 85%;left: 68%;top: 10%;padding: 0;');
    }, 4)

    setTimeout(function () {
      $('#showCCToDiv .dialog').attr('style', 'width: 30%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: hidden;height: 85%;left: 68%;top: 10%;padding: 0;');
    }, 4)
  }
  ShowCCTo() {
    this.showCCToDiv = true;
    this.showAssignToDivCss('CCTo');
    this.GetTicketUserLists();
    this.getSelectedTaskGroups('0,1');
  }
  CloseAssignTo() {
    this.showAssignToDiv = false;
    this.showCCToDiv = false;
  }
  ClearAssignTo(val) {
    if (val == "To") {
      this.selectedNames = "";
      this.tickets.assignTicketToTask = null;
      this.tickets.assignTicketToUser = null;
      this.showAssignToDiv = false;
    } else {
      this.selectedCCNames = '';
      this.tickets.CCTo = null;
      this.tickets.CCToTask = null;
      this.showCCToDiv = false;
      this.selectedCCNamesArray = [];
    }
    
  }

  GetTicketUserLists() {
    this.userservice.GetTicketUserLists(this.currentuser.id).then((taskgroup) => {
      this.getSelectedUserList(taskgroup[0].SelectedUserID);
    });
  }
  getSelectedTaskGroups(status) {
    this.ticketTaskAssignTo = [];
    //this.userservice.GetSelectedTaskgroups(status).then((taskgroups) => {
    //  this.ticketTaskAssignTo = taskgroups;
    //}); //this is for showing selected tasks under ticket Config

    this.userservice.GetTaskgrouplist(status).then((taskgroups) => {
      this.ticketTaskAssignTo = taskgroups;
    });
  }
  getSelectedUserList(status) {
    this.ticketUserAssignTo = [];
    this.customerService.Getselectedusers(status).then((users) => {
      this.ticketUserAssignTo = users;
    });
  }
  selectedTasks(Value) {     
    this.selectedNames = '';
    this.tickets.assignTicketToTask = null;
    this.tickets.assignTicketToUser = null;
    if ($('#TG' + Value.id).is(":checked")) {
      this.selectedNames = Value.itemName;
      this.tickets.assignTicketToTask = Value.id;
    }
    else {
      this.selectedNames = '';
      this.tickets.assignTicketToTask = null;
    }
  }

  selectedUser(Value) {
    this.selectedNames = '';
    this.tickets.assignTicketToUser = null;
    this.tickets.assignTicketToTask = null;
    if ($('#UG' + Value.id).is(":checked")) {
      this.selectedNames = Value.itemName;
      this.tickets.assignTicketToUser = Value.id;
    }
    else {
      this.selectedNames = '';
      this.tickets.assignTicketToUser = null;
    }
  }

  selectedCCTasks(Value) {
    
    if ($('#TG' + Value.id).is(":checked")) {
      this.selectedCCTaskListArray.push(Value);
      this.selectedCCNamesArray.push(Value.itemName);
      
    }
    else {
      this.selectedCCTaskListArray = this.selectedCCTaskListArray.filter(x => x.id !== Value.id)
      this.selectedCCNamesArray = this.selectedCCNamesArray.reduce(Value.itemName);
     
    }
    this.selectedCCNames = this.selectedCCNamesArray.join(",").toString();
    this.tickets.CCToTask = this.selectedCCTaskListArray.map(x => x.id).join(",").toString();
  }

  selectedCCUser(Value) {
    
    if ($('#UG' + Value.id).is(":checked")) {
      this.selectedCCUserListArray.push(Value);
      this.selectedCCNamesArray.push(Value.itemName);
    }
    else {
      this.selectedCCUserListArray = this.selectedCCUserListArray.filter(x => x.id !== Value.id);
      this.selectedCCNamesArray = this.selectedCCNamesArray.reduce(Value.itemName);
    }

    this.selectedCCNames = this.selectedCCNamesArray.join(",").toString();
    this.tickets.CCTo = this.selectedCCUserListArray.map(x => x.id).join(",").toString();
  }


  ClearTicket() {
    $("#ticketsMainMenu").removeClass("activeButton");
    $("#contactMainMenu").addClass("activeButton");
    this.location.back();
   
  }

  EditAccount() {
    this.showAddEditAccountDialog = true;
    this.showAddEditAccountCss();
  }

  showAddEditAccountCss() {
    setTimeout(function () {

      $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
     
    }, 4)
  }

  ReceiveEvent(msg) {
    if (msg == "ExpandSoftwareLicense" || msg == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(msg);
    } else {
      this.showAddEditAccountDialog = false;
      this.LoadAccountandContactsByAccountID(this.contactid);
    }
  }

  ExpandAddEditAccountDialog(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 100%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 86%;padding: 0;top: 10%;left: 10%;max-width: 88%;');
      }, 4)
    } else {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
      }, 4)
    }
  }
  closeTicketDialog(val) {
    if (val == 'Ticket') {
      this.showTicketsDetailsDialog = false;
    }
  }

  getTicketStatus(val) {
    let status = '';
    switch (val) {
      case 1: {
        status = 'Open';
        break;
      }
      case 2: {
        status = 'On-Hold';
        break;
      }
      case 3: {
        status = 'Pended';
        break;
      }
      case 4: {
        status = 'Complete';
        break;
      }
      case 5: {
        status = 'Not-Completed';
        break;
      }
      case 6: {
        status = 'Deleted';
        break;
      }
    }

    return status;
  }
  CreateTicketDialogCss() {
    this.CreateTicketDialog = true;
    let selfObj = this;
    setTimeout(function () {
      if (selfObj.openTicket) {
        $('#CreateTicketDialog .dialog').attr('style', 'width: 93%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;height: 90%;left: 10%;padding: 0 0 0 0;');
      } else {
        $('#CreateTicketDialog .dialog').attr('style', 'width: 30%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;height: 90%;left: 68%;padding: 0 0 0 0;');
      }
    }, 4)
  }

  
  CloseCreateTicket() {

    if (this.tickets.ticketId == null || this.tickets.ticketId == 0 || this.tickets.ticketId == undefined) {
      this.CloseCreateNewWarning = true;
      setTimeout(function () {
        $('#CloseCreateNewWarning .dialog').attr('style', 'width: 29%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
      }, 4)
    }
    
  }
}
