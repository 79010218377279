import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Document } from '../../models/document';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import { CustomerService } from '../../services/customer.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { DatePipe } from '@angular/common';
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../application.component';
import * as moment from 'moment'
import { PageComponent } from '../page/page.component';
import { Router, ActivatedRoute } from '@angular/router';
import { EditdeletecvComponent } from '../editdeletecv/editdeletecv.component';
import { AgGridAngular } from 'ag-grid-angular';
import { SalesXCRMChartTabs } from '../../models/SalesXCRMChartTabs';
declare var $: any;

@Component({
  selector: 'upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.css']
})
export class UploadDocumentComponent implements OnInit {
  @Input()
  accountId: number = 0;

  @Input()
  isUploadDivVisible: boolean = false;

  @Input()
  selectedMenuRadio: number;

  @Input()
  selectedMenunumber: number; 
 
  @Input()
  isDocumentManagement: boolean = false;
  @Input() selectedtype: string;
  @Input() pageFrom: string;

  @Input()
  isDocumentsInfoDivVisible: boolean = false;

  @ViewChild('agGrid') agGrid: AgGridAngular;

  documentDivHeight: number = 100;
  fromdocumentid: number;
  todocumentid: number;
  selectedtodocumentname: string;
  uploadDivHeight: number = screen.height * 0.6111;
  gridhgtforAccView: number = screen.height * 0.35555;
  documentlist: Document[] = [];
  Originaldocumentlist: Document[] = [];
  finaldocumentlist: Document[] = [];
  doc: Document = new Document();
  permissionlevellist: Dropdowntype[] = [];
  currentuser: any;
  tablehgt = 145;
  @Input()
  accountstatisticBox: string = 'Open';
  selecteddocumentName: string;
  userdocumentlist: any[] = [];
  datePipe: DatePipe = new DatePipe("en-US");
  currentYear = new Date().getFullYear();
  selectedItems = [];
  dropdownList = [];
  isPdfOpen = false;
  isselectedtransfered = false;
  documentDialog = false;
  isDocumentTransfer = false;
  documentNamesList: any[] = [];
  @Output()
  sendMsgEvent = new EventEmitter<string>();

  documenttypeList: Dropdowntype[] = [];
  userpendingdocumentlist: any[] = [];
  currentPage: number = 1;
  startrow: number = 1;
  noofRows: number = 1;
  totalnumberofrows: number = 1;
  totalnumberofPages: number = 1;
  pageSize: number = 100;
  docuentlength: number = 0;
  public context;
  public frameworkComponents;
  docTabList: Dropdowntype[] = [];
  ChartTabs: SalesXCRMChartTabs[] = [];
  public gridOptions: GridOptions;
  public sideBar;
  public documentscolumnDefs: any[];
  private gridApi;
  private gridColumnApi;
  DisableSaveButton: boolean = true;
  constructor(private router: Router, public applicationComponent: ApplicationComponent, private customerService: CustomerService, private userservice: UserService, private toastr: ToastrService, private paginationcop: PageComponent) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    
  }
  checkEnable() {
    let isEnable: boolean = false;
    if (this.selectedtype == 'Users') {
      isEnable = this.documentlist.find(x => (x.documentnamepermissionid != undefined && x.documentnamepermissionid != null && x.documentnamepermissionid != 0) && (x.tabId != undefined && x.tabId != null && x.tabId != 0)) != null;
    } else {
      isEnable = this.documentlist.find(x => (x.documentnamepermissionid != undefined && x.documentnamepermissionid != null && x.documentnamepermissionid != 0)) != null;

    }
    
    $('table td#myid').find('input:first').focus()
    return isEnable;
  }

  OpenFilemanual(val) {
    if (val == 'New') {
      $("#addFiles").click();
      this.initializeAgGrid();
    }
 }
  openfile(myBaseString, documentid, fileName, documenttype) {
     
    var data = myBaseString.split(",")
    var binary_string = window.atob(data[1]);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    var arrayBufferView = new Uint8Array(bytes.buffer);
    if (documenttype == "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      var blob = new Blob([arrayBufferView], { type: documenttype });
    else
      var blob = new Blob([arrayBufferView], { type: 'image/png/html' });

    var url = window.URL.createObjectURL(blob);

    var a = $("<a>").attr("href", url).attr("target", "_blank").attr("download", fileName).appendTo("body");

    a[0].click();

    a.remove();
    this.sendMsgEvent.emit(myBaseString);

    $('.bodyContaint').css('height', '600px')
    $('#pdfDiv').css('');
  }

  openfilecharge(charge) {
    let myBaseString = charge.imagecontent;
    let fileName = charge.uploadedfilename;

    var data = myBaseString.split(",")
    var binary_string = window.atob(data[1]);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    var arrayBufferView = new Uint8Array(bytes.buffer);

    if (charge.documenttype == "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      var blob = new Blob([arrayBufferView], { type: charge.documenttype });
    else
      var blob = new Blob([arrayBufferView], { type: 'image/png/html' });

    var url = window.URL.createObjectURL(blob);

    var a = $("<a>").attr("href", url).attr("download", fileName).appendTo("body");

    a[0].click();

    a.remove();
   
  }

  openfilecharge1(charge) {
    var binary_string = window.atob(charge[1]);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    var arrayBufferView = new Uint8Array(bytes.buffer);

    if (charge.documenttype == "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      var blob = new Blob([arrayBufferView], { type: charge.documenttype });
    else
      var blob = new Blob([arrayBufferView], { type: 'image/png/html' });

    var url = window.URL.createObjectURL(blob);

    var a = $("<a>").attr("href", url).attr("download", 'ban.pdf').appendTo("body");

    a[0].click();

    a.remove();
  }
  deleteCharge(charge) {
    if (this.documentlist.length > 1) {
      let deleteIndex = this.documentlist.indexOf(charge);
      if (deleteIndex >= 0) {
        this.documentlist.splice(deleteIndex, 1);
        this.gridApi.setRowData(this.documentlist);
      }
    } else {
      let deleteIndex = this.documentlist.indexOf(charge);
      if (deleteIndex >= 0) {
        this.documentlist.splice(deleteIndex, 1);
        this.gridApi.setRowData(this.documentlist);
      }
      this.sendMsgEvent.emit("Cancel");
    }
    
  }

  loaddropdowns() {
    this.customerService.GetAllActiveDocumentNamePermissionList(this.selectedtype, 1, 100).then((document) => {
      document.forEach(document => {
        document.DateUploaded = this.datePipe.transform(document.DateUploaded, "MM-dd-yyyy");
      });
      this.documentNamesList = document;
    });
  
   
    this.customerService.GetMySalesXCRMChartTabs(this.currentuser.id).then((ChartTabs) => {
      this.ChartTabs = ChartTabs.filter(x => x.ChartTabDescription != '' && x.ChartTabDescription != null);
    });
    let selfObj = this;
    
    selfObj.customerService.GetAllPendingDocuments(selfObj.accountId, this.selectedtype, 2019).then((document) => {
      document.forEach(document => {
        let newdoc = new Document();
        newdoc.id = document.id;
        document.DateUploaded = this.datePipe.transform(document.DateUploaded, "MM-dd-yyyy");      
        newdoc.documentnamepermissionid = document.documentnamepermissionid;
        newdoc.tabId = document.tabId;
        newdoc.documentdescription = document.documentdescription;      
        newdoc.uploadedfilename = document.uploadedfilename;
        newdoc.Size = document.Size;
        newdoc.documenttype = document.type;
        newdoc.imagecontent = document.imagecontent;
        newdoc.extension = document.extension;
        selfObj.documentlist.push(newdoc);
        
      });
      selfObj.userpendingdocumentlist = document;
    });
     
  }

  bind(data) {
    this.Originaldocumentlist = data;
    this.initializeAgGrid();
  }

  ngOnInit() {
    this.loaddropdowns();
    if (this.selectedMenunumber) {
      this.doc.tabId = this.selectedMenunumber;
     
    }
    else {
      this.doc.tabId = 0;
      
    }
}
  TabChange(value) {
    this.doc.tabId = value;
  }
  GetImagedata(reader, index, filename, filesize, filetype) {
     
    $('#' + index + 'imagecontent').val(reader.result);
    $('#' + index + 'uploadedfilename').val(filename);
    $('#' + index + 'size').val(filesize);
    $('#' + index + 'documenttype').val(filetype);
    //  objSelf.showdetail();
  }
  onFileChanged(event, index) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      let objSelf = this;
      $(setTimeout(function () {
        objSelf.GetImagedata(reader, index, file.name, file.size, file.type);
      }, 500));
    }
  }
  addCharge() {
    let newdoc = new Document();
    newdoc.documentname = "";
    newdoc.permissionlevel = 0;
    newdoc.uploadedfilename = "";
    newdoc.Size = "";
    newdoc.documentdescription = "";
    newdoc.documenttype = "";
    newdoc.imagecontent = "";
    newdoc.extension = "";
    newdoc.accountid = 0;
    newdoc.type = "";
    this.documentlist.push(newdoc);
    this.initializeAgGrid();
    this.Originaldocumentlist = this.documentlist;
  }
  addChargeNew(file) {
    this.initializeAgGrid();
    const reader = new FileReader();
    reader.readAsDataURL(file);

    let selfObj = this;
    let newdoc = new Document();

    $(setTimeout(function () {
      
      newdoc.file = file;
      newdoc.documentnamepermissionid = 0;
      newdoc.tabId = 0;
      newdoc.id = 0;
      newdoc.documentname = "Select";
      newdoc.permissionlevel = 0;
      newdoc.uploadedfilename = file.name;
      newdoc.Size = file.size;
      newdoc.documentdescription = $('#documentdescription').val();

      newdoc.documenttype = file.type;
      newdoc.imagecontent = reader.result.toString();
      let extensionArr = file.name.split('.');
      newdoc.extension = extensionArr[extensionArr.length - 1];
      selfObj.documentlist.push(newdoc);

      selfObj.gridApi.setRowData(selfObj.documentlist);
      //selfObj.gridOptions.api.setRowData(selfObj.documentlist);
      return newdoc
    }, 200));

    
  }
  addChargeExisting(file, charge) {
    
    const reader = new FileReader();
    reader.readAsDataURL(file);

    $(setTimeout(function () {
      charge.file = file;
      //charge.documentname = "";
      //charge.permissionlevel = "Select";
      charge.uploadedfilename = file.name;
      charge.size = file.size;
      charge.documenttype = file.type;
      charge.imagecontent = reader.result;
      let extensionArr = file.name.split('.');
      charge.extension = extensionArr[extensionArr.length - 1];
    }, 500));
  }

  addFiles(event) {
    if (!this.isDocumentManagement) {
      let files = event.srcElement.files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file.type == "application/pdf") {
           this.addChargeNew(file);
        } else {
          alert("System Supports PDF Documents Only.");
        }   
      }
      $("#addFiles").val("");
      
    } else {
      
      this.addCharge();
    }
    
  }
  CancelDocument() {
    this.sendMsgEvent.emit("Cancel");
  }
  addFile(event, charge) {
    let files = event.srcElement.files;
    if (files != null && files.length > 0) {
      let file = files[0];
      this.addChargeExisting(file, charge);
    }
    $("#addFile").val("");
     


  }

  onFileAdded(file, index) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    let objSelf = this;
    $(setTimeout(function () {
      objSelf.GetImagedata(reader, index, file.name, file.size, file.type);
    }, 500));
  }

  validateDropdown() {
    let validstatus;
    let count = this.documentlist.length;
    this.documentlist.forEach(ele => {
      if (ele.documentnamepermissionid > 0) {
        validstatus = true;
      } else {
        return false;
      }
    });


    //for (var i = 0; i < count; i++) {
    //  var num = parseInt($('#optionBExtra' + i).val());
    //  if (num > 0) {
    //    validstatus=true;
    //  }
    //  else {
    //    return false;
    //  }
    //}
    return validstatus;
  }
  SaveDocuments(pendingstatus) {
    if (pendingstatus == 1) { this.sendMsgEvent.emit("Cancel"); }
    else {
      const allRowData = [];
      this.gridApi.forEachNode(node => allRowData.push(node.data));
      this.documentlist = allRowData;
      if (this.validateDropdown()) {
        let test: number = this.accountId;
        this.finaldocumentlist = [];
        let deleteDocuments: Document[] = [];
        var i = 0;
        this.documentlist.forEach(document => {
          let isValidate: boolean = false;
          if (!this.isDocumentManagement) {
            if (document.documentnamepermissionid != undefined && document.documentnamepermissionid != null && document.documentnamepermissionid != 0)
              isValidate = true;
          } else {
            if (document.documentname != null && document.documentname.trim() != "" && document.permissionlevel != null && document.permissionlevel != 0)
              isValidate = true;
          }


          if (isValidate) {

            let newdoc = new Document();
            //newdoc.documentdescription = $('#documentdescription' + i).val();
            newdoc.documentdescription = document.documentdescription;
            i++;
            newdoc.id = document.id;
            newdoc.documentname = document.documentname;
            newdoc.uploadedby = this.currentuser.id;
            newdoc.uploadedfilename = document.uploadedfilename;
            newdoc.Size = document.Size.toString();
            newdoc.documenttype = document.documenttype;
            newdoc.permissionlevel = (this.selectedtype == "MySalesXCRM" || this.selectedtype == " MySalesXCRM " || this.selectedtype == "Account" || this.selectedtype == "Contact") ? document.documentnamepermissionid : document.permissionlevel;
            newdoc.doctype = this.selectedtype;
            newdoc.tabId = document.tabId ? document.tabId : this.doc.tabId;
            newdoc.pendingstatus = pendingstatus;
            //newdoc.documentdescription = $('#documentdescription').val();      
            newdoc.imagecontent = document.imagecontent;
            newdoc.extension = document.extension;
            newdoc.accountid = this.accountId;
            newdoc.type = (this.selectedtype == null) ? 'Account Or Contact' : (this.selectedtype == "SalesXUsers") ? 'MySalesXCRM' : this.selectedtype;
            if (!this.isDocumentManagement) {

              newdoc.documentnamepermissionid = document.documentnamepermissionid;
            }

            this.finaldocumentlist.push(newdoc);

            deleteDocuments.push(document);
          }
        });

        if (this.finaldocumentlist.length > 0) {


          if (!this.isDocumentManagement) {
            this.userservice.addDocuments(this.finaldocumentlist).then(result => {
              if (pendingstatus == 0) {
                this.toastr.success("Documents saved successfully.", "Documents Saved", { timeOut: 600 });
                if (this.pageFrom == 'notes') {
                  this.sendMsgEvent.emit(result[0].id.toString());
                }
                else {
                  this.sendMsgEvent.emit(this.finaldocumentlist[0].accountid.toString());
                }

              } else {
                this.sendMsgEvent.emit("PendingDoc");
              }

              if (deleteDocuments.length > 0) {
                deleteDocuments.forEach(document => {
                  let deleteIndex = this.documentlist.indexOf(document);
                  if (deleteIndex >= 0)
                    this.documentlist.splice(deleteIndex, 1);
                });
              }
            });
          } else {
            this.userservice.addDocumentNamePermission(this.finaldocumentlist).then(result => {

              this.toastr.success("Document names with permissions saved successfully.","Document", { timeOut: 600 });
              this.sendMsgEvent.emit();
              if (deleteDocuments.length > 0) {
                deleteDocuments.forEach(document => {
                  let deleteIndex = this.documentlist.indexOf(document);
                  if (deleteIndex >= 0)
                    this.documentlist.splice(deleteIndex, 1);
                });
              }
            });
          }
        }
        else {
          this.sendMsgEvent.emit("Cancel");
        }
        var url = window.location.href;
        if (url.indexOf('contactsdashboard') > -1) {

          //document.getElementById('documentsinfo').style.marginTop = "9% !important";
        }
      }
      else {
        alert("Please select Document Name");
      }
    }
    //$(setTimeout(function () {
    //  $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(117, 218, 51);min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    //}, 4));
  }
  Cancel() { this.sendMsgEvent.emit("Cancel");}
  CancelDocuments() {
    //this.documentlist = [];
    var url = window.location.href;
    if (url.indexOf('contactsdashboard') > -1) {
      //document.getElementById('documentsinfo').style.marginTop = "5%";
    }
  }

  onCellValueChanged(event) {
    event.data.modified = true;
  }

  SelectedDocumentType(id, Docname) {
     
    this.todocumentid = id;
    this.selectedtodocumentname = Docname;
    $('.closeButtonTr').removeAttr('style');

    $('#' + id).attr('style', 'background-color:#b7e4ff;')
  }
  ToDoDelete(taskId, index) {
    $('#' + index).remove();
  }

  handleClick(event) {    
    this.sendMsgEvent.emit(event.target.value);
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: false, paginationPageSize: 1000,toolPanelSuppressPivotMode: false, toolPanelSuppressValues: false, rowSelection: 'Single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };

    this.createColumnDefs();
  }

  createColumnDefs() {
    let SO = this;
    const DocNameCellRenderer = function () { };
    DocNameCellRenderer.prototype = {
      init: function (params) {
        this.value = params.value;
        this.DocNameCellRenderer = document.createElement('div');
        var eSelect = document.createElement("select");
        eSelect.setAttribute('class', 'custom-select form-control');
        eSelect.setAttribute('style', 'padding:0px;font-size:12px;background-color: #ffff91;opacity: 1;border:none;border-left:3px solid red');
        eSelect.setAttribute('name', params.colDef.field);
        eSelect.setAttribute('id', 'optionBExtra' + params.rowIndex);
        eSelect.required = true;
        var eOption = document.createElement("option");
        eOption.text = "Select";
        eOption.value = "";
        eSelect.appendChild(eOption);
        if (params.colDef.field == "documentnamepermissionid") {
          if (SO.documentNamesList ) {
            SO.documentNamesList.forEach((customer) => {
              var eOption = document.createElement("option");
              eOption.text = customer.documentname;
              eOption.value = customer.id;
              eSelect.appendChild(eOption);
            });

          }
          this.DocNameCellRenderer.appendChild(eSelect);
          eSelect.addEventListener('change', function ($event) {
            if (this.checkValidity()) {
              $('#optionBExtra' + params.rowIndex).attr('style', 'padding:0px;font-size:12px;background-color: white;opacity: 1;border:none;border-left:3px solid lightgreen');

            } else {
              $('#optionBExtra' + params.rowIndex).attr('style', 'padding:0px;font-size:12px;background-color: #ffff91;opacity: 1;border:none;border-left:3px solid lightgreen');
            }

            $(setTimeout(function () {
              SO.checkValidatedate();
            }, 200));
            
            params.data.documentnamepermissionid = this.value
          });
        }
      },
      getGui: function () {
        return this.DocNameCellRenderer ;
      },
      getValue() {
        // this simple editor doubles any value entered into the input
        return this.value;
      },
      refresh: function () {
        return true;
      },
      destroy: function () {
        $(this.DocNameCellRenderer).remove();
      },
    }

    const DocTabCellRenderer = function () { };
    DocTabCellRenderer.prototype = {
      init: function (params) {
        this.value = params.value;
        this.DocTabCellRenderer = document.createElement('div');
        var eSelect = document.createElement("select");
        eSelect.setAttribute('class', 'custom-select form-control');
        eSelect.setAttribute('style', 'padding:0px;font-size:12px;background-color: #ffff91;opacity: 1;border:none;border-left:3px solid red');
        eSelect.setAttribute('name', params.colDef.field);
        eSelect.setAttribute('id', 'optionBExtra' + params.rowIndex);
        eSelect.required = true;
        var eOption = document.createElement("option");
        eOption.text = "Select";
        eOption.value = "";
        eSelect.appendChild(eOption);
        if (params.colDef.field == "tabId") {
          if (SO.ChartTabs) {
            SO.ChartTabs.forEach((customer) => {
              var eOption = document.createElement("option");
              eOption.text = customer.ChartTabDescription;
              eOption.value = customer.ChartTabId.toString();
              eSelect.appendChild(eOption);
            });

          }
          this.DocTabCellRenderer.appendChild(eSelect);
          eSelect.addEventListener('change', function ($event) {
            if (this.checkValidity()) {
              $('#optionBExtra' + params.rowIndex).attr('style', 'padding:0px;font-size:12px;background-color: white;opacity: 1;border:none;border-left:3px solid lightgreen');
            } else {
              $('#optionBExtra' + params.rowIndex).attr('style', 'padding:0px;font-size:12px;background-color: #ffff91;opacity: 1;border:none;border-left:3px solid lightgreen');
            }

            $(setTimeout(function () {
              SO.checkValidatedate();
            }, 200));
            params.data.tabId = this.value;
            
          });
        }
      },
      getGui: function () {
        return this.DocTabCellRenderer;
      },
      getValue() {
        // this simple editor doubles any value entered into the input
        return this.value;
      },
      refresh: function () {
        return true;
      },
      destroy: function () {
        $(this.DocTabCellRenderer).remove();
      },
    }

    const DocDescription= function () { };
    DocDescription.prototype = {
      init: function (params) {
        this.DocDescription = document.createElement('div');
        var input = document.createElement("input");
        input.setAttribute('class', 'form-control');
        input.setAttribute('style', 'padding:0px;font-size:12px;background-color: #ffff91;opacity: 1;border:none;border-left:3px solid red');
        input.setAttribute('name', params.colDef.field);
        input.setAttribute('type', 'text');
        input.setAttribute('placeholder', 'Enter Description');
        input.required = true;
        input.id = 'Description' + params.rowIndex;
        this.DocDescription.appendChild(input);
        this.value = params.value;
        params.data.documentdescription = this.value

        input.addEventListener('blur', function ($event) {
          //var res = sObj.SelectedSaleStage(params.data, $event);
         
          if (this.checkValidity()) {
            $('#Description' + params.rowIndex).attr('style', 'padding:0px;font-size:12px;background-color: white;opacity: 1;border:none;border-left:3px solid lightgreen');
            $(setTimeout(function () {
              SO.checkValidatedate();
            }, 200));
          } else {
            $('#Description' + params.rowIndex).attr('style', 'padding:0px;font-size:12px;background-color: #ffff91;opacity: 1;border:none;border-left:3px solid lightgreen');
          }
          params.data.documentdescription = this.value
        });
        input.addEventListener('keyup', function ($event) {
          if (this.checkValidity()) {
            $('#Description' + params.rowIndex).attr('style', 'padding:0px;font-size:12px;background-color: #ffff91;opacity: 1;border:none;border-left:3px solid lightgreen');
            $(setTimeout(function () {
              SO.checkValidatedate();
            }, 200));
          }
          params.data.documentdescription = this.value
        });
      },
      getGui: function () {
        return this.DocDescription;
      },
      getValue() {
        // this simple editor doubles any value entered into the input
        return this.value;
      },
      refresh: function () {
        return true;
      },
      destroy: function () {
        $(this.DocDescription).remove();
      },
    }


      this.documentscolumnDefs = [
    
        { field: 'documentnamepermissionid', headerName: 'Document Name', hide: false, cellRenderer: DocNameCellRenderer, },
        { field: 'tabId', headerName: 'Document Tab', hide: this.selectedtype !== 'MySalesXCRM', cellRenderer: DocTabCellRenderer, },
        { field: 'documentdescription', headerName: 'Document Description', hide: false, cellRenderer: DocDescription},
        { field: 'uploadedfilename', headerName: 'Upload File', hide: this.isDocumentManagement ? true : false },
        { field: 'Size', headerName: 'File Size', hide: this.isDocumentManagement ? true : false },
        {
          field: '', headerName: '', hide: false, cellRenderer(params) {

            var eDiv = document.createElement('div');
            
              eDiv.innerHTML = '<input type="button" title="Remove" value="X" class="pull-right" style="color:red;" />';
            
            eDiv.addEventListener('click', function ($event) {
              SO.deleteCharge(params.data);
            });
            return eDiv;

            //return '<input type="button" title="Remove" (click)="deleteCharge(charge)" value="X" class="pull-right" style="color:red;" />';
          }
        },
        

    ];
  }
   
  //onRemoveSelected(selectedData) {
  //  //var selectedData = this.gridApi.getRowData();
  //  var res = this.gridApi.applyTransaction({ remove: selectedData });
  //  //printResult(res);
  //}

  checkValidatedate() {
    const allRowData = [];
    this.gridApi.forEachNode(node => allRowData.push(node.data));
    allRowData.forEach(x => {
      if (x.documentdescription !== undefined && x.documentnamepermissionid !== 0) {
        this.DisableSaveButton = false;
      } 
    });
  }
}
