export class AutomatedEmails {
  public UserName: string;
  public Configureuserid: number;
  public accesstouserid: number;
  public leadAdded: number;
  public leadClosed: number;
  public oppAdded: number;
  public OppClosed: number;
  public weeklyRecap: number;
  public midMonthRecap: number;
  public endOfMonthRecap: number;
  public createdby: number;
  public createddate: string;
}
