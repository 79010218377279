<div class="col-xs-12" style="padding: 0; height: 100%; ">
    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="StatusIdHomePage">

      <div class="col-xs-12" style="padding:0;height:10%;">
        <h4 class="fontStyleCss" style="margin-top:0">
          <span style="text-transform: uppercase;">Status ID Configuration</span>
        </h4>

      </div>

      <div class="col-xs-12" style="height:90%;padding:0">
        <div class="col-xs-12" style="padding:0;height:20%">
          <div class="col-xs-1" style="padding:0;height:100%;width:4%"><label style="font-weight:bold" (click)="OpenStatusID('Group')"><img src="../../../assets/img/System Config.png" style="height:30px" /></label></div>

          <div class="col-xs-11" style="padding:0;height:100%;">
            <div class="col-xs-12" style="padding:0;height:30%">
              <div class="col-xs-12" style="padding:0;height:100%"><label style="font-weight:bold;text-decoration:underline;">Status ID Groups</label></div>

            </div>

            <div class="col-xs-12" style="padding:0;height:70%">
              <!--<label style="font-weight:normal">The system allows users to create status ID items to track specific data for accounts in the system. The items added will display under the Status ID Icon located next to the Account Name. Status ID Items can be selected under Advanced Search to builder queries and generate results. </label>-->
              <label style="font-weight:normal">Select to View Status ID Groups in the system</label>
            </div>

          </div>
        </div>


        <div class="col-xs-12" style="padding:0;height:20%">
          <div class="col-xs-1" style="padding:0;height:100%;width:4%"><label style="font-weight:bold" (click)="OpenStatusID('Items')"><img src="../../../assets/img/System Config.png" style="height:30px" /></label></div>

          <div class="col-xs-11" style="padding:0;height:100%;">
            <div class="col-xs-12" style="padding:0;height:30%">
              <div class="col-xs-12" style="padding:0;height:100%"><label style="font-weight:bold;text-decoration:underline;">Status ID Items</label></div>

            </div>

            <div class="col-xs-12" style="padding:0;height:70%">
              <!--<label style="font-weight:normal">The system allows users to create status ID items to track specific data for accounts in the system. The items added will display under the Status ID Icon located next to the Account Name. Status ID Items can be selected under Advanced Search to builder queries and generate results. </label>-->
              <label style="font-weight:normal">Select to View, Edit and Add Status ID Groups in the system</label>
            </div>

          </div>
        </div>
      </div>

    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="StatusIdGroupsPage">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Status ID Groups</span>
      </h4>
      <div class="col-xs-7" style="padding:0;height:93%">
        <div class="col-xs-12" style="height:7%;padding:0;">
          <div class="col-xs-8" style="text-align:left;padding:0.5%;height:100%">

          </div>
          <div class="col-xs-4" style="padding:0;text-align:right;height:100%">

            <select class="form-control" (change)="StatusIDOrder($event.target.value,'Groups')"
                    style="height: 92%;font-size: 12px;padding: 0;margin-top:0.5%">
              <option value=0 selected> Display Sort Order  </option>
              <option value=1> Display A-Z </option>
            </select>

          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:93%;border:1px solid grey">

          <ag-grid-angular style="height:100%" class="ag-theme-balham"
                           [gridOptions]="gridOptions"
                           [columnDefs]="GroupsColumnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="allStatusIdGroups"
                           [rowDragManaged]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (rowDragEnd)="onRowDragEndGroups($event)"
                           (selectionChanged)="onGroupSelectionChanged($event)"
                           (gridReady)="onAgGridReady($event,'StatusIDGroups')">
          </ag-grid-angular>
        </div>
      </div>

      <div class="col-xs-5" style="padding:0;height:93%">
        <form #AddGroupForm="ngForm" style="height:100%">
          <div class="col-xs-12" style="height:6%;padding:0;">
          </div>
          <div class="col-xs-12" style="height:12%;margin: 2% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="height:100%;padding:0;"><label> Task Group Name</label></div>
            <div class="col-xs-9" style="height:100%;padding:0;"><textarea name="StatusGroup.StatusGroupName" [(ngModel)]="StatusGroup.StatusGroupName" [required]="createNewStatusIdGroup" [disabled]="!createNewStatusIdGroup" style="width:100%;height:100%;"></textarea></div>
          </div>
          <div class="col-xs-12" style="height:6%;margin:1% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="padding:0;height:100%"><label>Sort Order</label></div>
            <div class="col-xs-9" style="padding:0;height:100%"><input type="text" [(ngModel)]="StatusGroup.StatusSortOrder" name="StatusGroup.StatusSortOrder" [disabled]="!createNewStatusIdGroup" [required]="createNewStatusIdGroup" style="width:100%;height:100%;" /></div>
          </div>

          <div class="col-xs-12" style="padding:0;height:51%">

          </div>
          <!--<div class="col-xs-12" style="padding:0;margin-top:2%;height:6%">
            <div class="col-xs-7"></div>
            <div class="col-xs-2" style="display:block;padding:0">
              <button class="btn noprovider" style="width:100%;background: lightgray;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="ClearStatusID()">Cancel</button>
            </div>
            <div class="col-xs-2 pull-right" style="display:block;padding:0;margin-right:1%">
              <button class="btn noprovider" (click)="SaveStatusID('Groups')" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" [disabled]="(!AddGroupForm.form.valid)">Save</button>
            </div>
          </div>-->
        </form>

      </div>

    </div>


    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="StatusIdItemsPage">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Status ID Items</span>
      </h4>
      <div class="col-xs-7" style="padding:0;height:93%" *ngIf="!deleteStatusId">
        <div class="col-xs-12" style="height:7%;padding:0;">

          <div class="col-xs-1" style="padding:0;">
            <img src="../../../assets/img/Create New Document Name.png" (click)="CreateNewStatusID('Items')" style="height:30px" />
          </div>

          <div class="col-xs-6" style="height:100%">

            <select class="form-control" [(ngModel)]="StatusIDGroup" (change)="StatusIDGroupChangeForGrid($event.target.value)">
              <option value="{{item.StatusGroupId}}" selected *ngFor="let item of allStatusIdGroups">{{item.StatusGroupName}}</option>

            </select>

          </div>
          <div class="col-xs-5" style="padding:0;text-align:right;height:100%">

            <select class="form-control" (change)="StatusIDOrder($event.target.value,'Items')">
              <option value=0 selected> Display Sort Order  </option>
              <option value=1>
                Display A-Z
              </option>
            </select>


          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:93%;border:1px solid grey">

          <ag-grid-angular style="height:100%" class="ag-theme-balham"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="allStatusIds"
                           [rowDragManaged]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (rowDragEnd)="onRowDragEnd($event)"
                           (selectionChanged)="onSelectionChanged($event)"
                           (gridReady)="onAgGridReady($event,'StatusIDItems')">
          </ag-grid-angular>
        </div>
      </div>

      <div [class]="(ExpandCategoryGrid)? 'col-xs-12' : 'col-xs-7'" style="padding:0;height:93%;background-color: lightgray;" *ngIf="deleteStatusId">
        <div class="col-xs-12" style="text-align:center;font-size:20px;height:15%">
          <label style="font-weight: bold;margin-top:1%">The Status ID Selected to DELETE is Linked to Accounts</label><br />
        </div>
        <div class="col-xs-12" style="text-align:center;font-size:18px;height:18%">
          <label>Please select another Status ID to replace the Status ID you have selected to  Delete</label><br /><br />
        </div>
        <div class="col-xs-12" style="height:8%;font-size:18px;">
          <div class="col-xs-5" style="height:100%;padding:0;"><label>Status ID Selected to Delete :</label></div>
          <div class="col-xs-7" style="height:100%;padding:0;"><span>Searching to Replace EHR</span></div>
        </div>
        <div class="col-xs-12" style="height:8%;font-size:18px;">
          <div class="col-xs-5" style="height:100%;padding:0;"><label>Update Accounts to Status ID:</label></div>
          <div class="col-xs-7" style="height:100%;padding:0;">
            <div class="col-xs-8" style="padding:0;height:100%">
              <angular2-multiselect [data]="dropdownList" id="AccountStatusID" [(ngModel)]="selectedItems" class="form-control"
                                    [settings]="dropdownSetting"
                                    (onSelect)="onItemSelect($event)"
                                    (onDeSelect)="OnItemDeSelect($event)"
                                    (onSelectAll)="onSelectAll($event)"
                                    (onDeSelectAll)="onDeSelectAll($event)">
              </angular2-multiselect>
            </div>
          </div>
        </div>
        <div class="col-xs-12" style="height:35%"></div>

        <div class="col-xs-12" style="padding:0;margin-top:2%;height:6%">
          <div class="col-xs-5"></div>
          <div class="col-xs-2" style="display:block;padding:0">
            <button class="btn noprovider" style="width:100%;background: lightgray;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="CancelSelectedAccount()">Cancel</button>
          </div>
          <div class="col-xs-2" style="display:block;padding:0;margin-right:1%;height:100%">
            <button class="btn noprovider" (click)="UpdateStatusId()" [disabled]="this.finalitems.length <= 0" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000 !important;border-radius: 3px;">Save</button>
          </div>
        </div>

      </div>

      <div class="col-xs-5" style="padding:0;height:93%">
        <form #AddCategoryForm="ngForm" style="height:100%">
          <div class="col-xs-12" style="height:6%;padding:0;">
            <!--<span style="margin-left:1%" > X </span>-->
          </div>
          <div class="col-xs-12" style="height:12%;margin: 2% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="height:100%;padding:0;"><label> Status ID</label></div>
            <div class="col-xs-9" style="height:100%;padding:0;"><textarea name="statusidCategory.StatusIDName" [(ngModel)]="statusidCategory.StatusIDName" [required]="createNewStatusId" [disabled]="!createNewStatusId" style="width:100%;height:100%;"></textarea></div>
          </div>
          <div class="col-xs-12" style="height:6%;margin:1% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="padding:0;height:100%"><label>Sort Order</label></div>
            <div class="col-xs-9" style="padding:0;height:100%"><input type="text" [(ngModel)]="statusidCategory.SortOrder" name="statusidCategory.SortOrder" [disabled]="!createNewStatusId" [required]="createNewStatusId" style="width:100%;height:100%" /></div>
          </div>
          <div class="col-xs-12" style="height:6%;margin:1% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="padding:0;height:100%"><label>Groups</label></div>
            <div class="col-xs-9" style="padding:0;height:100%">
              <select class="form-control" id="StatusIDGroup" name="statusidCategory.StatusIDGroup" [(ngModel)]="statusidCategory.StatusIDGroup" (change)="StatusIDGroupChange($event.target.value)" [disabled]="!createNewStatusId" [required]="createNewStatusId">
                <option value="{{item.StatusGroupId}}" selected *ngFor="let item of allStatusIdGroups">{{item.StatusGroupName}}</option>

              </select>
            </div>
          </div>
          <div class="col-xs-12" style="padding:0;height:51%">

          </div>
          <div class="col-xs-12" style="padding:0;margin-top:2%;height:6%">
            <div class="col-xs-7"></div>
            <div class="col-xs-2" style="display:block;padding:0">
              <button class="btn noprovider" style="width:100%;background: lightgray;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="ClearStatusID()">Cancel</button>
            </div>
            <div class="col-xs-2 pull-right" style="display:block;padding:0;margin-right:1%">
              <button class="btn noprovider" (click)="SaveStatusID('Items')" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" [disabled]="(!AddCategoryForm.form.valid)">Save</button>
            </div>
          </div>
        </form>

      </div>

    </div>

</div>

<app-dialog [(visible)]="DeleteConfirmationPopup" *ngIf="DeleteConfirmationPopup" id="DeleteConfirmationPopup" [closable]="false">
  <app-delete-popup [title]="'Status ID'" [description]="'Delete Status ID ?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveDeleteevent($event)">
  </app-delete-popup>
</app-dialog>

<app-dialog [(visible)]="DeleteGroupConfirmationPopup" *ngIf="DeleteGroupConfirmationPopup" id="DeleteGroupConfirmationPopup" [closable]="false">
  <app-delete-popup [title]="'Status ID Group'" [description]="'Delete Status ID Group ?'" [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true" (sendMsgEvent)="ReceiveGroupDeleteevent($event)">
  </app-delete-popup>

</app-dialog>
