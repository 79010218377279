import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { LeadsService } from '../../services/leads.service';
import { UserService } from '../../services/user.service';
import { Taskgroup } from "../../models/taskgroup";
import { WizardSubGroup } from '../../models/wizard-sub-group';
import { ToastrService } from 'ngx-toastr';
import { Dropdowntype } from '../../models/dropdowntype';
import { Tasklistconfiguration } from '../../models/tasklistconfiguration';
import { Lastresponseview } from '../../models/lastresponseview';
import { ApplicationComponent } from '../application.component';
import { QuestionResponses } from 'src/app/models/question-responses';
import { WizardQuestions } from 'src/app/models/wizard-questions';
import { WizardSubGroup2 } from 'src/app/models/WizardSubGroup2';
import { DragulaService } from 'ng2-dragula';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
declare var $: any;
import { Subscription } from 'rxjs';
import { count } from 'rxjs-compat/operator/count';
import { AuditLogModel } from '../../models/auditlog';
@Component({
  selector: 'responsewizard',
  templateUrl: './responsewizard.component.html',
  styleUrls: ['./responsewizard.component.css']
})
export class ResponsewizardComponent implements OnInit {
  LoggedInUser: any;
  taskgroup: Taskgroup = new Taskgroup();
  subtaskgroup: WizardSubGroup = new WizardSubGroup();
  subtaskgroup2: WizardSubGroup2 = new WizardSubGroup2();
  ques: Taskgroup = new Taskgroup();
  response: QuestionResponses = new QuestionResponses();
  Resoponsecategorylist: Dropdowntype[] = [];
  ResoponseSubcategorylist: Dropdowntype[] = [];
  ResoponseSubcategory2list: Dropdowntype[] = [];
  Questionslist = [];
  ResponseList = [];
  vampires;
  questions: WizardQuestions[] = [];
  totaskids = [];
  toDoNew: boolean = true;
  ButtonsHide: boolean = true;
  selectedCategoryId;
  selectedSubCategory1Id;
  selectedSubCategory2Id;
  SelectedResponseEditButtons: boolean = false;
  newtodoid = 0;
  toDobuttons: boolean = true;
  responseshowDialog = false;
  notedescription: string;
  selectedResponseId;
  lastresponseId: number = 0;
  prevlastresponseId: number = 0;
  HideCategorytextBox: boolean = true;
  HideCategory1TextBox: boolean = true;
  HideCategory2TextBox: boolean = true;
  HideCategoryButtons: boolean = true;
  category1ButtonSave: boolean = true;
  category2ButtonSave: boolean = true;
  salestoolPage: boolean;
  CategoryTextBox: string;
  subCategoryTextBox1: string;
  subCategoryTextBox2: string;
  tasklistconfiguration: Tasklistconfiguration = new Tasklistconfiguration();
  lastresponseview: Lastresponseview = new Lastresponseview();
  @Output()
  sendMsgEvent = new EventEmitter<string>();

  @Input()
  type: string = '';
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  subs = new Subscription();
  category = false;
  style:string = "";
  subcategory1 = false;
  subcategory2 = false;
  popcategory = false;
  popsubcategory1 = false;
  popsubcategory2 = false;
  DeleteConfirmationPopup: boolean = false;
  DeleteResponseConfirmationPopup: boolean = false;
  public deleteTicketId: number;
  public deleteResponseId: number;
  contactStatus: any;
  CategoryAdd: boolean = true;
  CategoryEdit: boolean = true;
  SubCategory1Add: boolean = true;
  SubCategory1Edit: boolean = true;
  SubCategory2Add: boolean = true;
  SubCategory2Edit: boolean = true;
  ResponsewizardHomePage: boolean = true;
  ResponsewizardPage: boolean = false;
  @Input()
  SubMenuType: string = null;
  constructor(private _location: Location, private route: ActivatedRoute,private customerService: CustomerService, private toastr: ToastrService, private userservice: UserService, private leadservice: LeadsService,
    private applicationComponent: ApplicationComponent, private dragulaService: DragulaService) {
    this.LoggedInUser = JSON.parse(localStorage.getItem("currentUser"));
    this.subs.add(this.dragulaService.drop("Responses_bag")
      .subscribe(({ name, el, target, source, sibling }) => {
        this.ToDoReordertaskItems('QuestionResponse');
      })
    );

    this.subs.add(this.dragulaService.drop("Questions-bag")
      .subscribe(({ name, el, target, source, sibling }) => {
        this.StatementsQuestionsOrder();
      })
    );

    this.subs.add(this.dragulaService.drop("Category-bag")
      .subscribe(({ name, el, target, source, sibling }) => {
        
        this.CategoryOrder('Category'); 
      })
    );
    this.subs.add(this.dragulaService.drop("SubCategory1-bag")
      .subscribe(({ name, el, target, source, sibling }) => {
        this.CategoryOrder('SubCategory1');
      })
    );
    this.subs.add(this.dragulaService.drop("SubCategory2-bag")
      .subscribe(({ name, el, target, source, sibling }) => {
        this.CategoryOrder('SubCategory2');
      })
    );

  }
 
  ShowHideDropdown(type) {
    if (type == 'Category') {
      if (this.category) {
        this.category = false;
      } else {
        this.category = true;
      }
    }
    else if (type == 'SubCategory1') {
      if (this.subcategory1) {
        this.subcategory1 = false;
      } else {
        this.subcategory1 = true;
      }
    }
    else if (type == 'SubCategory2') {
      if (this.subcategory2) {
        this.subcategory2 = false;
      } else {
        this.subcategory2 = true;
      }

      }
  }

  ShowHidePopDropdown(type) {
    if (type == 'Category') {
      if (this.popcategory) {
        this.popcategory = false;
      } else {
        this.popcategory = true;
      }
    }
    else if (type == 'SubCategory1') {
      if (this.popsubcategory1) {
        this.popsubcategory1 = false;
      } else {
        this.popsubcategory1 = true;
      }
    }
    else if (type == 'SubCategory2') {
      if (this.popsubcategory2) {
        this.popsubcategory2 = false;
      } else {
        this.popsubcategory2 = true;
      }

    }
  }
  CategoryOrder(type) {
    if (type == 'Category') {
      let TaskIdArr = [];
      $.each($("#CategoryDropdown div span[id$='Category']"), function (k, v) {
        var TaskIds = $.trim($(this).attr('id'));
        TaskIdArr.push(TaskIds.split('~')[0]);
      });
      this.userservice.SaveCategoriesOrder(TaskIdArr.join(','), 'Category', this.LoggedInUser.id).then(result => {
        this.toastr.success("Questions Re-Ordered Successfully.", " Questions", { timeOut: 600 });
      });
    } else if (type == 'SubCategory1') {

      let TaskIdArr = [];
      $.each($("#SubCategory1Dropdown div span[id$='SubCategory1']"), function (k, v) {
        var TaskIds = $.trim($(this).attr('id'));
        TaskIdArr.push(TaskIds.split('~')[0]);
      });
      this.userservice.SaveCategoriesOrder(TaskIdArr.join(','), 'SubCategory1', this.LoggedInUser.id).then(result => {
        this.toastr.success("Questions Re-Ordered Successfully.", " Questions", { timeOut: 600 });
      });
    }
    else if (type == 'SubCategory2') {
      let TaskIdArr = [];
      $.each($("#SubCategory2Dropdown div span[id$='SubCategory2']"), function (k, v) {
        var TaskIds = $.trim($(this).attr('id'));
        TaskIdArr.push(TaskIds.split('~')[0]);
      });
      this.userservice.SaveCategoriesOrder(TaskIdArr.join(','), 'SubCategory2', this.LoggedInUser.id).then(result => {
        this.toastr.success("Questions Re-Ordered Successfully.", " Questions", { timeOut: 600 });
      });
    }
    
  }
 // CategoryClick() { alert("clicked"); }
  GetLastResponse() {
     
    this.leadservice.GetLastResponseViewed(this.LoggedInUser.id).then((response) => {
       
      this.lastresponseview = response;
      var id = this.lastresponseview.categoryid;
      this.lastresponseId = response.id;
      this.selectedCategoryId = response.categoryid;
      
      this.selectedSubCategory1Id = response.SubTaskGroup1id;
      this.selectedSubCategory2Id = response.SubTaskGroup2id
      this.selectedResponseId = response.ResponseID;
      $('#todotaskgroup').val(response.categoryid);
      if (this.lastresponseview != null && this.lastresponseview.categoryid != 0 && this.lastresponseview.questionid != 0) {
        this.leadservice.GetAllSUbCategoryQuestionsList(id, 'SubCategory1', this.LoggedInUser.id).then((subCategory1List) => {
          this.ResoponseSubcategorylist = subCategory1List;
          if (this.type != "TaskList") {
            document.getElementById('selectedSubCat1Name').innerHTML = this.ResoponseSubcategorylist.filter(x => x.id == this.selectedSubCategory1Id)[0].itemName;
          } else {
            document.getElementById('selectedpopupSubCat1Name').innerHTML = this.ResoponseSubcategorylist.filter(x => x.id == this.selectedSubCategory1Id)[0].itemName;
          }
        });
        this.leadservice.GetAllSUbCategoryQuestionsList2(id, this.lastresponseview.SubTaskGroup1id, this.LoggedInUser.id).then((subCategory2List) => {
          this.ResoponseSubcategory2list = subCategory2List;
         
          if (this.type != "TaskList") {
            document.getElementById("subCat2dropdown").className = "activeButton";  
            document.getElementById('selectedSubCat2Name').innerHTML = this.ResoponseSubcategory2list.filter(x => x.id == this.selectedSubCategory2Id)[0].itemName;
          } else {
            document.getElementById('selectedpopupSubCat2Name').innerHTML = this.ResoponseSubcategory2list.filter(x => x.id == this.selectedSubCategory2Id)[0].itemName;
          }
          $('#todotaskgroup2').val(this.lastresponseview.SubTaskGroup2id);
        });

        
        this.GetAllQuesionsList(this.lastresponseview.SubTaskGroup2id, 0);
        
        //if (this.type != 'SalesTool') {
          this.SelectedQuestion(this.lastresponseview.questionid, "Click");
        //}

        var selfobj = this;
        $(setTimeout(function () {         
          $('#todotaskgroup').val(id);
          if (selfobj.type != "TaskList") {
            document.getElementById('selectedCatName').innerHTML = selfobj.Resoponsecategorylist.filter(x => x.id == selfobj.selectedCategoryId)[0].itemName;
          } else {
            document.getElementById('selectedpopupCatName').innerHTML = selfobj.Resoponsecategorylist.filter(x => x.id == selfobj.selectedCategoryId)[0].itemName;
          }
        }, 200));


      }
    });
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.type = params['type'];
      this.SubMenuType = params['SubMenuType'] ;
      $('.fr-counter').attr('style', 'display:none;')
      this.loadTaskGrouplist();
      if (this.SubMenuType !== null && this.SubMenuType !== undefined && this.SubMenuType !== '') {
        this.AccessDiv(this.SubMenuType);
      } else {

        var obj: AuditLogModel = { id: 0, type: 'Response Wizard', action: 'Response Wizard', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.LoggedInUser.id, dateofviewed: '', customertype: '', time: '', Title: 'Response Wizard', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
        this.applicationComponent.InsertAuditHistory(obj);
      }
    });
  }
  AccessDiv(value) {
    this.ResponsewizardHomePage = false;
    this.ResponsewizardPage = true;

    var obj: AuditLogModel = { id: 0, type: 'Response Wizard', action: 'Response Wizard', AccountOrContactId: 0, AccountOrContact: value, documentid: 0, userid: this.LoggedInUser.id, dateofviewed: '', customertype: '', time: '', Title: 'Response Wizard', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }

  canceltodotasktype(val) {
    this.GetAllQuesionsList(this.selectedSubCategory2Id, 0);
    this.ButtonsHide = true;
    this.ResponseList = null;
    $('#todoTasktype').attr('style', "background-color:#808080;color:white");
    $('#todoTasktypePopup').attr('style', "background-color:#808080;color:white");
  }
  //CancelCategory() {
  //   
  //  $('.taskGroupType').removeClass('btn-default')
  //  $('.taskGroupType').removeClass('selectedTaskGroupType')
  //  $('.taskGroupType').addClass('btn-default')

  //  $('#CategoryTextBox').val('');
  //  this.HideCategorytextBox = true;
  //  this.HideCategoryButtons = true;
  //}
  //subcat1Cancel() {

  //  $('#subresponsewizard').val('');
  //  this.HideCategory1TextBox = true;
  //  this.category1ButtonSave = true;
  //}
  addResponse() {
  
    this.responseshowDialog = true;
    $(setTimeout(function () {
      $('#responseshowDialog .dialog').attr('style', 'width: 32%;margin-top:8%;left:64%; height:75%;');
      $('.fr-wrapper').attr('style', 'border:3px solid #00B0F0 !important;height: 80%');
      $('.fr-element .fr-view').attr('style', 'border:3px solid #00B0F0 !important;height: 80%');
    }, 100));

  }
  ToDoReordertaskItems(tasktype) {
     
    var c = confirm("Are you sure you want to change the order of list?");
    if (c == true) {
      var TaskIdArr = [];
      if (tasktype == 'wizard') {
        $.each($("#todoTable tbody tr td[id$='todoTaskID']"), function (k, v) {
          var TaskIds = $.trim($(this).html());
          TaskIdArr.push(TaskIds);
        })
         
        this.tasklistconfiguration.taskgroupid = $('#todotaskgroup').val();
      }
      else if (tasktype == 'QuestionResponse') {
        $.each($("#responseTable tbody tr td[id$='responseID']"), function (k, v) {
          var TaskIds = $.trim($(this).html());
          TaskIdArr.push(TaskIds);
        })
        this.tasklistconfiguration.taskgroupid = this.ques.id;
      }
      this.tasklistconfiguration.configureduserid = this.LoggedInUser.id;
      this.tasklistconfiguration.tasklistconfigurtaionorder = TaskIdArr.toString(); 
      this.tasklistconfiguration.tasktype = tasktype;
      this.customerService.SaveTaskListConfiguration(this.tasklistconfiguration).then(result => {
        this.toastr.success("Response Re-Ordered Successfully.", " Responses", { timeOut: 600 });

      });
    }
  }

  StatementsQuestionsOrder() {
     
    var c = confirm("Are you sure you want to change the order ?");
    if (c == true) {
      var TaskIdArr = [];
      $.each($("#todoTable tbody tr td[id$='todoTaskID']"), function (k, v) {
        var TaskIds = $.trim($(this).html()) ;
        TaskIdArr.push(TaskIds);
       
      });
      this.tasklistconfiguration.taskgroupid = this.selectedSubCategory2Id
      this.tasklistconfiguration.configureduserid = this.LoggedInUser.id;
      this.tasklistconfiguration.tasklistconfigurtaionorder = TaskIdArr.toString();
      this.tasklistconfiguration.tasktype = 'WizardQuestions';
      this.customerService.SaveTaskListConfiguration(this.tasklistconfiguration).then(result => {
        this.toastr.success("Questions Re-Ordered Successfully.", " Questions", { timeOut: 600 });
      });

    }
  }

  SaveEditResponse() {
    var text = $('#' + this.selectedResponseId + 'EditContent').html();   
    this.response.id = this.selectedResponseId;
    this.response.response = text;  
    this.response.responseuserid = this.LoggedInUser.id;
    this.response.questionid = this.ques.id;
    this.response.isbolded = $('#' + this.selectedResponseId + 'boldContent').val();
    if (this.response.response != undefined && this.response.response != "") {
      this.customerService.SaveResponcesForQuestion(this.response).then(result => {
        this.notedescription = "";
        this.toastr.success("Response Updated successfully.", "Response updated", { timeOut: 600 });
        this.SelectedResponseEditButtons = false;
        $('.fr-element').empty();
        this.leadservice.GetAllResponseListByQuestionId(this.response.questionid, this.LoggedInUser.id).then((response) => {
          this.ResponseList = response;
        });
      });
    }
  }

  cancelEditResponse() {
    this.SelectedResponseEditButtons = false;
    $('.resTr').removeAttr('style');
    $('.PoPUpresTr').removeAttr('style');
  }

  SaveResponse() {
     
    this.response = new QuestionResponses();
    this.response.response = this.notedescription;
    //this.response.response = this.response.response.replace(/<[^>]*>/gi, '').trim();
    //this.response.response = this.response.response.replace(/&nbsp;/gi, '').trim();
    //this.response.response = this.response.response.replace(/^(&nbsp;|<br>)+/, '');
    this.response.responseuserid = this.LoggedInUser.id;
    this.response.questionid = this.ques.id;
    if (this.response.response != undefined && this.response.response != "") {
      if (this.response.questionid != 0 && this.response.questionid != null) {
        this.customerService.SaveResponcesForQuestion(this.response).then(result => {
          this.notedescription = "";
          this.toastr.success("Response saved successfully.", "Response Saved", { timeOut: 600 });
          this.responseshowDialog = !this.responseshowDialog;
          $('.fr-element').empty();
          this.leadservice.GetAllResponseListByQuestionId(this.response.questionid, this.LoggedInUser.id).then((response) => {
            this.ResponseList = response;
          });
        });
      } else {
        alert("Please Select One statment in the list");
      }
    }
  }
  AddCategory() {
    this.selectedCategoryId = 0;
    this.HideCategorytextBox = false;
    this.HideCategoryButtons = true;
    this.CategoryEdit = false;
    document.getElementById('selectedCatName').innerHTML = 'Select Category';
    this.category = true;
  }

  AddSubCategory1() {
    if (this.selectedCategoryId != 0) {  
      this.selectedSubCategory1Id = 0;
      this.HideCategory1TextBox = false;
      this.category1ButtonSave = true;
      this.SubCategory1Edit = false;
      //this.CancelButtons('SubCategory2');
      //this.CancelButtons('Category');
      document.getElementById('selectedSubCat1Name').innerHTML = 'Select Sub Category 1';
      this.subcategory1 = true;
    } else {
      alert("Please Select Category");
    }
  }

  AddsubCategory2() {
    if (this.selectedSubCategory1Id != 0) {

      this.selectedSubCategory2Id = 0;
      this.HideCategory2TextBox = false;
      this.category2ButtonSave = true;
      this.SubCategory2Edit = false;
      document.getElementById("subCat2dropdown").classList.remove("activeButton");
      document.getElementById("subCat2dropdown").className = "btn"; 
      document.getElementById('selectedSubCat2Name').innerHTML = 'Select Sub Category 2';
      this.subcategory2 = true

    } else {
      alert("Please select Sub Category 1");
    }
  }

  SaveSubCategory(SubCategoryType) {
     
    if (SubCategoryType == 'SubCategory2') {
      this.subtaskgroup.subTaskGroupName = $('#subCategoryTextBox2').val();
      this.subtaskgroup.id = this.selectedSubCategory2Id;
    } else {
      this.subtaskgroup.subTaskGroupName = $('#subCategoryTextBox1').val();
      this.subtaskgroup.id = this.selectedSubCategory1Id;
    }

    this.subtaskgroup.taskGroupId = this.selectedCategoryId;
    if (this.subtaskgroup.taskGroupId != 0 && this.subtaskgroup.taskGroupId != null ) {
      this.subtaskgroup.category = SubCategoryType;
      this.customerService.addSubtaskgroup(this.subtaskgroup).then(result => {
        this.toastr.success("New Sub Category Added Successfully.", "Category", { timeOut: 600 });
        //this.loadTaskGrouplist();
        this.GetAllSubCategories1List(this.subtaskgroup.taskGroupId);
        this.GetAllSubCategories2List(this.subtaskgroup.taskGroupId);
        document.getElementById('selectedSubCat1Name').innerHTML = 'Select Category';
        //this.myFunction('SubCategory1');
        if (SubCategoryType == 'SubCategory1') {
          this.CancelButtons('SubCategory1');
        } else if (SubCategoryType == 'SubCategory2') {
          this.CancelButtons('SubCategory2');
        }
        //this.CancelCategory();
        //this.subcat1Cancel();
        //this.myFunction('SubCategory1');
        $('#CategoryTextBox').val('');
        this.SubCategory1Add = true;
        this.SubCategory1Edit = true;

      });
    } else {
      alert("Please Select Category Before Saving Sub Category")
    }
  }
  SaveSubCategory2(SubCategoryType) {
     
    if (SubCategoryType == 'SubCategory2') {
      this.subtaskgroup2.subtaskgroup2Name = $('#subCategoryTextBox2').val();
      this.subtaskgroup2.id = this.selectedSubCategory2Id;
    }
    else {
      this.subtaskgroup2.subtaskgroup2Name = $('#subCategoryTextBox1').val();
      this.subtaskgroup2.id = this.selectedSubCategory1Id;
    }

    this.subtaskgroup2.taskgroupid = this.selectedCategoryId;
    this.subtaskgroup2.subtaskgroup1id = this.selectedSubCategory1Id;
    if (this.subtaskgroup2.subtaskgroup1id != 0 && this.subtaskgroup2.subtaskgroup1id != null) {
      this.customerService.addSubtaskgroup2(this.subtaskgroup2).then(result => {
         
        this.toastr.success("New Sub Category-2 Added Successfully.", "Category", { timeOut: 600 });
        //this.loadTaskGrouplist();
        //this.GetAllSubCategories1List(this.subtaskgroup.taskGroupId);
        this.GetAllSubCategories2List(this.subtaskgroup2.taskgroupid);
        document.getElementById("subCat2dropdown").classList.remove("activeButton");
        document.getElementById("subCat2dropdown").className = "btn";
        document.getElementById('selectedSubCat2Name').innerHTML = 'Select Category';
        //this.CancelButtons('SubCategory1');
        this.CancelButtons('SubCategory2');
        //this.CancelCategory();
        //this.subcat1Cancel();
        //this.myFunction('SubCategory2');
        $('#CategoryTextBox').val('');
        this.SubCategory2Add = true;
        this.SubCategory2Edit = true;
      });
    } else {
      alert("Please select Sub Category 1 while saving Sub Category 2");
    }
  }
  SavewizardCategory() {
     
    this.taskgroup.id = this.selectedCategoryId;
    this.taskgroup.addeduserid = this.LoggedInUser.id;
    this.taskgroup.tasktype = "Wizard";
    this.taskgroup.TaskGroupName = $('#CategoryTextBox').val();
     
    this.customerService.checkduplicateTaskGroupName(this.taskgroup).then(result => {
      if (result == null) {
        this.customerService.addtaskgroup(this.taskgroup).then(result => {
          this.toastr.success("New Category Added Successfully.", "Category", { timeOut: 600 });
          this.loadTaskGrouplist();
          //this.CancelCategory();
          this.selectedCategoryId = null;
          document.getElementById('selectedCatName').innerHTML = 'Select Category';
          this.CancelButtons('Category');
          this.ClearAllFields();
          //this.myFunction('Category');
          $('#CategoryTextBox').val('');
          this.CategoryAdd = true;
          this.CategoryEdit = true;
        });
      }
      else {
        alert(this.taskgroup.TaskGroupName + "  Already Exist")
      }
    });

  }
  SaveWizardQuestions() {
     
    var quest = new WizardQuestions();
    if (this.totaskids.length != 0) {
      this.totaskids.forEach(id => {
        quest.id = parseInt($('#' + id.Index + 'todoTaskID').html());
        quest.Question = $('#' + id.Index + 'todoTasktype').html();

        if (quest.Question != "" || quest.Question != null || quest.Question != undefined) {
          //quest.groupid = $('#todotaskgroup').val();
          quest.groupid = this.selectedSubCategory2Id;
          quest.createduserid = this.LoggedInUser.id;
          quest.Question = quest.Question.toString().replace(/<[^>]*>/gi, '').trim();
          quest.Question = quest.Question.toString().replace(/&nbsp;/gi, '').trim();
          if (quest.Question != '') {
            this.questions.push(quest);
          }
        }
      });
    }
    else {
      if ((this.questions.filter(f => { f.id == 0 && f.Question == $('#' + this.newtodoid + 'todoTasktype').html() }).length == 0)) {
        var quest = new WizardQuestions();
        //quest.Question = $('#' + this.newtodoid + 'todoTasktype').html().replace('To Do - ', '');
        quest.Question = $('#' + this.newtodoid + 'todoTasktype').html();
        quest.id = 0;
        quest.Question = quest.Question.toString().replace('&nbsp;', '').trim();
        quest.Question = quest.Question.toString().replace(/<[^>]*>/g, '').trim();
        if (quest.Question || quest.Question != '' || quest.Question != null || quest.Question != undefined) {
           
          //quest.groupid = $('#todotaskgroup').val();
          quest.groupid = this.selectedSubCategory2Id;
          quest.createduserid = this.LoggedInUser.id;
          this.questions.push(quest);
        }
      }

    }

    this.customerService.saveWizardQuestion(this.questions).then(result => {
      this.toastr.success(" Question Saved Successfully.", "Question", { timeOut: 600 });
      this.newtodoid = 0;
      this.totaskids = [];
      this.questions = [];
       
      this.GetAllQuesionsList(this.selectedSubCategory2Id, 0);
      this.ButtonsHide = true;
    });
  }
  GetAllQuesionsList(taskcategoryval, status) {
    this.Questionslist = [];
    this.leadservice.GetAllQuestionsList(this.LoggedInUser.id, taskcategoryval, status).then((accounttype) => {
      this.Questionslist = accounttype;
   
      if (localStorage.getItem('selectedQuestionId') != '' && parseInt(localStorage.getItem('selectedQuestionId')) > 0) {
        this.SelectedQuestion(localStorage.getItem('selectedQuestionId'), 'Click');
        $('#' + parseInt(localStorage.getItem('selectedQuestionId')) + 'todoTasktypePopup').attr('style', 'width:80%;background-color:#badb98');
      }

    });
    var url = window.location.href;
    if (url.indexOf('tasklistdashboard/Contacts/') > -1) {
      this.salestoolPage = false;
    }
    else if (url.indexOf('salestool') > -1) {
      this.salestoolPage = true;
    }
    
  }
  GetAllSubCategories1List(taskcategoryval) {
    this.ResoponseSubcategorylist = [];
    this.leadservice.GetAllSUbCategoryQuestionsList(taskcategoryval, 'SubCategory1', this.LoggedInUser.id).then((subCategory1List) => {
      this.ResoponseSubcategorylist = subCategory1List;    
      if (this.type == 'SalesTool') {
        document.getElementById('selectedSubCat1Name').innerHTML = 'Select Sub Category 1';
        document.getElementById("subCat2dropdown").classList.remove("activeButton");
        document.getElementById("subCat2dropdown").className = "btn";
        document.getElementById('selectedSubCat2Name').innerHTML = 'Select Sub Category 2';
      }
      else {
        document.getElementById('selectedpopupSubCat1Name').innerHTML = 'Select Sub Category 1';
        document.getElementById('selectedpopupSubCat2Name').innerHTML = 'Select Sub Category 2';
      }
      this.selectedSubCategory1Id = 0;
      this.selectedSubCategory2Id = 0;
      if (localStorage.getItem('selectedCategory1Id') != null) {
        this.loadtaskitems(localStorage.getItem('selectedCategory1Id'), 'SubCategory1')
        document.getElementById('selectedpopupSubCat1Name').innerHTML = localStorage.getItem('selectedCategory1Name');
      }

    });
    if (this.ResoponseSubcategorylist.length == 0) {
      $('#todotaskgroup1').val("0");
      $('#todotaskgroup2').val("0");
    }
  }
  GetAllSubCategories2List(taskcategoryval) {
    this.ResoponseSubcategory2list = [];
    this.leadservice.GetAllSUbCategoryQuestionsList2(taskcategoryval, this.selectedSubCategory1Id, this.LoggedInUser.id).then((subCategory2List) => {     
      this.ResoponseSubcategory2list = subCategory2List;
      if (this.type == 'SalesTool') {
        document.getElementById("subCat2dropdown").classList.remove("activeButton");
        document.getElementById("subCat2dropdown").className = "btn";
        document.getElementById('selectedSubCat2Name').innerHTML = 'Select Sub Category 2';
      } else {
        document.getElementById('selectedpopupSubCat2Name').innerHTML = 'Select Sub Category 2';
      }
      
      this.selectedSubCategory2Id = 0;
      if (localStorage.getItem('selectedCategory2Id') != null) {
        this.loadtaskitems(localStorage.getItem('selectedCategory2Id'), 'SubCategory2');
        document.getElementById('selectedpopupSubCat2Name').innerHTML = localStorage.getItem('selectedCategory2Name');
      }

    });
  }
  SelectedResonse(responseId) {
    this.selectedResponseId = responseId;
    this.SelectedResponseEditButtons = true;
    $('.Tr').removeAttr('style');
    $('.resTr').attr('style', 'width: 80%;border: none');
    $('.PoPUpresTr').attr('style', 'width: 80%;border: none');
    $(setTimeout(function () {
      $('#resTr' + responseId).attr('style', 'width:80%;background-color:#badb98');
      $('#PoPUpresTr' + responseId).attr('style', 'width:80%;background-color:#badb98');
    }, 100))
    this.lastresponseview.ResponseID = responseId;
    this.lastresponseview.questionid = this.ques.id;
    this.lastresponseview.userid = this.LoggedInUser.id;
    //localStorage.setItem('selectedCategoryId', this.selectedCategoryId);
    //localStorage.setItem('selectedSubCategory1Id', this.selectedSubCategory1Id);
    //localStorage.setItem('selectedSubCategory2Id', this.selectedSubCategory2Id);
    localStorage.setItem('selectedQuestionId', this.ques.id.toString());
    localStorage.setItem('selectedResponseId', this.selectedResponseId);
    this.customerService.SavelastViewedresponsewizard(this.lastresponseview).then((user) => {
    });
  }

  SelectedQuestion(QuestionId, val) {
    if (this.type == 'SalesTool') {
      $('.disableEditable').attr('contenteditable', "true");
    } else {
      $('.disableEditable').attr('contenteditable', "false");
    }
    $('.disableEditable').attr('style', 'width:80%;border: 2px solid ##A6A4A4 ');    
    $(setTimeout(function () {
      $('#' + QuestionId + 'todoTasktype').attr('style', 'width:80%;background-color:#badb98');
      $('#' + QuestionId + 'todoTasktypePopup').attr('style', 'width:80%;background-color:#badb98');
    }, 100));
    this.ques.id = QuestionId;
    this.leadservice.GetAllResponseListByQuestionId(QuestionId, this.LoggedInUser.id).then((response) => {
      this.ResponseList = response;
      this.ResponseList = this.ResponseList.filter(x => x.isdeleted != 1);
      if (parseInt(localStorage.getItem('selectedResponseId')) > 0) {
        $(setTimeout(function () {
          $('#resTr' + parseInt(localStorage.getItem('selectedResponseId'))).attr('style', 'width:80%;background-color:#badb98');
          $('#PoPUpresTr' + parseInt(localStorage.getItem('selectedResponseId'))).attr('style', 'width:80%;background-color:#badb98');
        }, 100))
      }

      this.leadservice.GetResponseByQuestionId(QuestionId, this.LoggedInUser.id).then((response) => {   
        if (response != null) {
          this.prevlastresponseId = response.ResponseID;
          let that = this;
          //$(setTimeout(function () {
          //  $('#resTr' + that.prevlastresponseId).attr('style', 'width:80%;background-color:#badb98');
          //  $('#PoPUpresTr' + that.prevlastresponseId).attr('style', 'width:80%;background-color:#badb98');
          //}, 200));
        }
        else {
          this.lastresponseview.id = this.lastresponseId;
          this.lastresponseview.userid = this.LoggedInUser.id;
          this.lastresponseview.questionid = QuestionId;
          this.lastresponseview.SubTaskGroup1id = this.selectedSubCategory1Id;
          this.lastresponseview.SubTaskGroup2id = this.selectedSubCategory2Id;
          this.lastresponseview.categoryid = this.selectedCategoryId;

          if (val == "Click") {
            this.customerService.SavelastViewedresponsewizard(this.lastresponseview).then((user) => {
            });
          }
        }
      });
      
    });
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  edit(jval, tasktype) {
    //this.Questionslist = [];
    if (jval == 0) {
      var toDoEmpty = ({ id: 0, itemName: '', category: null })
      this.Questionslist.push(toDoEmpty);
    }
    $(setTimeout(function () {

      //$('.disableEditable').attr('contenteditable', "false")
      if (this.type == 'SalesTool') {
        $('#' + jval + tasktype).attr('contenteditable', "true")
      }
      else {
        $('#' + jval + tasktype).attr('contenteditable', "false")
      }
      $('#' + tasktype).removeClass('btn-default');
      if (jval == 0) {
        $('#' + tasktype).attr('style', "background-color:#b7e4ff;")
      }

      //$('.Tr').removeAttr('style');
      $('#' + jval + tasktype).attr('style', "background-color:#b7e4ff;")
      $('#' + jval + tasktype + 'order').attr('style', "background-color:#b7e4ff;padding:2% 0 0 2%;")
      if (($('#' + jval + tasktype).html()).trim() == 'add item here') {
        $('#' + jval + tasktype).html('')
      }
    }, 200));
  }


  keyupTask(taskid, kval, tasktype) {
     
    if (taskid > 0) {
      if ((this.totaskids.filter(f => f.Index == taskid && f.Type == "todo")).length == 0) {
        this.totaskids.push({ Index: taskid, Type: tasktype });
      }
    }
    else {
      if (tasktype == "todo") { this.toDoNew = false; this.newtodoid = taskid; }

    }

    if (tasktype == "todo") {
      $("#" + taskid + "todoTasktype").css("color", "red");
      this.toDobuttons = false
    }
    this.ButtonsHide = false;


  }

  loadTaskGrouplist() {
    this.userservice.GetAllWizardTaskGroupList('Wizard', 0, this.LoggedInUser.id).then((taskgroups) => {
      this.Resoponsecategorylist = taskgroups;
      if ((localStorage.getItem('selectedCategoryId') != null)){
        this.Poploadtaskitems(localStorage.getItem('selectedCategoryId'), 'Category')
        document.getElementById('selectedpopupCatName').innerHTML = localStorage.getItem('selectedCategoryName');
      }
    });
  }
  deleterespose(responseid) {
    this.deleteResponseId = responseid;
    this.DeleteResponseConfirmationPopup = true;
    this.applicationComponent.salesxcrmDialog('#DeleteResponseConfirmationPopup .dialog');
 

  }
  editresponse(responseId) {
    this.responseshowDialog = true;
    this.customerService.ResponseById(responseId).then((user) => {
      this.response = user;
      this.notedescription = user.response;
    });
    $(setTimeout(function () {
       
      $('.fr-wrapper').attr('style', 'border:3px solid #00B0F0 !important;');
      // $('.fr-box fr-basic .fr-top .fr-wrapper').css('border', '2px solid #00B0F0');
    }, 100));

  }
  ToDoDelete(id, status) {
    
    this.deleteTicketId = id;
    if (status == 1) {
      this.contactStatus = 1;
    } else {
      this.contactStatus = 0;
    }
    
    this.DeleteConfirmationPopup = true;
    this.applicationComponent.salesxcrmDialog('#DeleteConfirmationPopup .dialog');
    //setTimeout(function () {
    //  $('#DeleteConfirmationPopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    //}, 4);
    //if (status == '1') {
    //  var c = confirm("Are you sure you want to do delete response item??");
    //  if (c == true) {
    //    this.customerService.activatedeactivateQuestion(id, status).then((user) => {
    //      this.toastr.success(" Response item deleted Successfully.", "Task List", { timeOut: 600 });
           
    //      this.GetAllQuesionsList($('#todotaskgroup2').val(), 0);
    //    });
    //  }
    //}
    //else {
    //  var c = confirm("Are you sure you want to do Activate Response item??");
    //  if (c == true) {
    //    this.customerService.activatedeactivateQuestion(id, status).then((user) => {
    //      this.toastr.success(" To Do item Activated Successfully.", "Task List", { timeOut: 600 });
           
    //      this.GetAllQuesionsList($('#todotaskgroup2').val(), 0);
    //    });
    //  }
    //}
    this.ResponseList = null;
  }
  ReceiveDeleteevent(msg) {
    if (msg == "Ok") {
      this.customerService.activatedeactivateQuestion(this.deleteTicketId, this.contactStatus).then((user) => {
        this.toastr.success(" To Do item Activated Successfully.", "Task List", { timeOut: 600 })
       
        this.GetAllQuesionsList(this.selectedSubCategory2Id,0);
        });
    }
      this.DeleteConfirmationPopup = false;
    
  }
  ReceiveresponseDeleteevent(msg) {
    if (msg == "Ok") {
      this.customerService.DeactivateResponse(this.deleteResponseId).then((user) => {
        this.toastr.success(" Response deleted Successfully.", "Response", { timeOut: 600 });
        this.leadservice.GetAllResponseListByQuestionId(this.ques.id, this.LoggedInUser.id).then((response) => {
          this.ResponseList = response;
        });
      });
    }
    this.DeleteResponseConfirmationPopup = false;

  }
  enableButtons(type) {
     
    //if (type == 'Category')
    //  this.HideCategoryButtons = false;
    //else if (type == 'SubCategory1')
    //  this.category1ButtonSave = false;
    //else if (type == 'SubCategory2')
    //  this.category2ButtonSave = false;
  }

  CancelResponse() {
    $('.fr-element').empty();
    this.response = new QuestionResponses();
  }
  CancelButtons(type) {
     
    $('#CategoryTextBox').val("");
    $('#subCategoryTextBox1').val("");
    $('#subCategoryTextBox2').val("");
    if (type == 'Category') {
      this.HideCategoryButtons = true;
      this.HideCategorytextBox = true;
      this.CategoryAdd = true;
      this.CategoryEdit = true;
      this.myFunction('Category');
    }
    else if (type == 'SubCategory1') {
      this.category1ButtonSave = true;
      this.HideCategory1TextBox = true;
      this.SubCategory1Add = true;
      this.SubCategory1Edit = true;
      this.myFunction('SubCategory1');
    }
    else if (type == 'SubCategory2') {
      this.category2ButtonSave = true;
      this.HideCategory2TextBox = true;
      this.SubCategory2Add = true;
      this.SubCategory2Edit = true;
      this.myFunction('SubCategory2');
    }
  }
  
  loadtaskitems(catval, type) {
     
    this.SelectedResponseEditButtons = false;
    if (type == 'Category') {
      this.selectedCategoryId = catval;
      this.ResoponseSubcategorylist = null;
      this.ResoponseSubcategory2list = null;
      this.GetAllSubCategories1List(catval)
      this.Questionslist = null;
    }
    else if (type == 'SubCategory1') {
      this.selectedSubCategory1Id = catval;
      this.ResoponseSubcategory2list = null;
      this.GetAllSubCategories2List(this.selectedCategoryId)
      this.Questionslist = null;
    }
    else if (type == 'SubCategory2') {
      this.selectedSubCategory2Id = catval;
      if ($('#isTodoSelected').is(":checked")) {
        this.GetAllQuesionsList(this.selectedSubCategory2Id, 1);
      }
      else {
        this.GetAllQuesionsList(this.selectedSubCategory2Id, 0);
      }
    }
    this.ResponseList = null;
  }

  Poploadtaskitems(catval, type) {
  
    //this.SelectedResponseEditButtons = false;
    if (type == 'Category') {
      this.selectedCategoryId = catval;    
      this.ResoponseSubcategorylist = null;
      this.ResoponseSubcategory2list = null;
      this.GetAllSubCategories1List(catval)
      this.Questionslist = null;
    }
    else if (type == 'SubCategory1') {
      this.selectedSubCategory1Id = catval;
      this.ResoponseSubcategory2list = null;
      this.GetAllSubCategories2List(this.selectedCategoryId)
      this.Questionslist = null;
    }
    else if (type == 'SubCategory2') {
      this.selectedSubCategory2Id = catval;
      if ($('#isTodoSelected').is(":checked")) {
        this.GetAllQuesionsList(this.selectedSubCategory2Id, 1);
      }
      else {
        this.GetAllQuesionsList(this.selectedSubCategory2Id, 0);
      }
    }
    this.ResponseList = null;
  }

  EditCategory(selectedCatId ,selectedtype) {
     
    //Old Code 
    //if (selectedtype == 'edit') {
    //   
    //  this.taskgroup.id = $('#todotaskgroup').val();
    //  $('.taskGroupType').removeClass('selectedTaskGroupType')

    //  $('#todoAddTaskGroup').removeClass('btn-default');
    //  $('#todoAddTaskGroup').addClass('selectedTaskGroupType');
    //  this.HideCategorytextBox = false;
    //  this.HideCategoryButtons = true;
    //   
    //  $('#CategoryTextBox').val($("#todotaskgroup option:selected").text());
    //} else if (selectedtype == 'delete') {
    //   
    //  this.customerService.deleteCategory($('#todotaskgroup').val()).then((taskgroup) => {
    //    this.loadTaskGrouplist();
    //  });
    //}

    // Old Code Ends


    if (selectedtype == 'Category') {
       
      this.selectedCategoryId = selectedCatId;
      if (this.selectedCategoryId != null && this.selectedCategoryId != 0) {
          this.Resoponsecategorylist.forEach(element => {
            if (element.id == this.selectedCategoryId) {
              $('#CategoryTextBox').val(element.itemName);
              this.CategoryTextBox = element.itemName;
              this.HideCategorytextBox = false;
              this.CategoryAdd = false;
            }
          });
          if (!this.category) {
            this.category = true;
          }
        }
        else {
          alert("Please Select categories")
        }
     
    }
    else if (selectedtype == 'SubCategory1') {
      this.selectedSubCategory1Id = selectedCatId;
      if (this.selectedSubCategory1Id != null && this.selectedSubCategory1Id != 0) {
        this.ResoponseSubcategorylist.forEach(element => {
          if (element.id == this.selectedSubCategory1Id) {
            $('#subCategoryTextBox1').val(element.itemName);
            this.subCategoryTextBox1 = element.itemName;
            this.HideCategory1TextBox = false;
            this.SubCategory1Add = false;
          }
        });
        //$('#SubCategory1Dropdown').val(localStorage.getItem('selectedSubCategory1Id'));
        if (!this.subcategory1) {
          this.subcategory1 = true;
        }
      }
      else {
        alert("please Select Sub Category 1")
      }
    }
    else if (selectedtype == 'SubCategory2') {
      this.selectedSubCategory2Id = selectedCatId;
      if (this.selectedSubCategory2Id != null && this.selectedSubCategory2Id != 0) {
        this.ResoponseSubcategory2list.forEach(element => {
          if (element.id == this.selectedSubCategory2Id) {
            $('#subCategoryTextBox2').val(element.itemName);
            this.subCategoryTextBox2 = element.itemName;
            this.HideCategory2TextBox = false;
            this.SubCategory2Add = false;
          }
        });
      //  $('#SubCategory2Dropdown').val(localStorage.getItem('selectedSubCategory2Id'));
        if (!this.subcategory2) {
          this.subcategory2 = true;
        }
      } else {
        alert("please Select Sub Category 2");
      }
    }


  }
  ClearAllFields() {
    //$('#todotaskgroup1').val("0");
    //$('#todotaskgroup2').val("0");
    //$('#todotaskgroup').val("0");
    this.Questionslist = null;
    this.ResponseList = null;
    this.selectedCategoryId = 0;
    this.selectedSubCategory1Id = 0;
    this.selectedSubCategory2Id = 0;
    document.getElementById('selectedCatName').innerHTML = 'Select Category';
    document.getElementById('selectedSubCat1Name').innerHTML = 'Select Sub Category 1';
    document.getElementById("subCat2dropdown").classList.remove("activeButton");
    document.getElementById("subCat2dropdown").className = "btn";
    document.getElementById('selectedSubCat2Name').innerHTML = 'Select Sub Category 2';
    this.ResoponseSubcategorylist = [];
    this.ResoponseSubcategory2list = [];
    //this.CancelButtons('Category')
    //this.CancelButtons('SubCategory1')
    //this.CancelButtons('SubCategory2')
  }

  clearCategory(val) {
    switch (val) {
      case 'clearCategory': {
        if (this.selectedSubCategory1Id == 0 && this.selectedSubCategory2Id == 0) {
          this.selectedCategoryId = 0;
          document.getElementById('selectedpopupCatName').innerHTML = 'Select Category';
          localStorage.removeItem('selectedCategoryId');
          localStorage.removeItem('selectedCategoryName');
          this.popcategory = false;
        } else {
          if (this.selectedSubCategory1Id != 0) {
            alert("Please Clear Sub Category1");
          } else if (this.selectedSubCategory2Id != 0){
            alert("Please Clear Sub Category2");
          }
        }
        this.loadTaskGrouplist();
      };
        break;

      case 'clearCategory1': {
        if (this.selectedSubCategory2Id != 0) {
          alert("Please Clear Sub Category2");
        } else {
          this.selectedSubCategory1Id = 0;
          document.getElementById('selectedpopupSubCat1Name').innerHTML = 'Select Sub Category 1';
          localStorage.removeItem('selectedCategory1Id');
          localStorage.removeItem('selectedCategory1Name');
          this.popsubcategory1 = false;
        }
        //if ($('#todotaskgroup2').val() != null ) {
        //  alert("Please Clear Sub Category2");
        //}else {
        //  $('#todotaskgroup1').val("0");
        //}
        
      };
        break;

      case 'clearCategory2': {
        this.selectedSubCategory2Id = 0;
        document.getElementById('selectedpopupSubCat2Name').innerHTML = 'Select Sub Category 2';
        localStorage.removeItem('selectedCategory2Id');
        localStorage.removeItem('selectedCategory2Name');
        this.Questionslist = null;
        this.ResponseList = null;
        this.popsubcategory2 = false;
        this.leadservice.GetAllResponseListByQuestionId(this.lastresponseview.questionid, this.LoggedInUser.id).then((response) => {
          this.ResponseList = response;       
          this.lastresponseview = new Lastresponseview();
          this.lastresponseview.id = this.lastresponseId;
          this.lastresponseview.userid = this.LoggedInUser.id;
          this.lastresponseview.questionid = this.lastresponseview.questionid;
          this.lastresponseview.SubTaskGroup1id = this.selectedSubCategory1Id;
          this.lastresponseview.SubTaskGroup2id = this.selectedSubCategory2Id;
          this.lastresponseview.categoryid = this.selectedCategoryId;
          
          this.customerService.SavelastViewedresponsewizard(this.lastresponseview).then((user) => {
            this.Questionslist = null;
            this.ResponseList = null;
          });
        });
        
       
      };
        break;
    }
  }
  divClick(event) {
    if (event.target.id == "QuestionsDiv") {
      this.canceltodotasktype('todo');
    } 
  }

  myFunction(type) {
    if (type == 'Category') {
      this.category = true;
     // document.getElementById("CategoryDropdown").classList.toggle("show");
    } else if (type == 'SubCategory1') {
      this.subcategory1 = true;
      //document.getElementById("SubCategory1Dropdown").classList.toggle("show");
    }
    else if (type == 'SubCategory2') {
      this.subcategory2 = true;
     // document.getElementById("SubCategory2Dropdown").classList.toggle("show");
    }
    
  }

  appendLabel(value, type) {
     
    if (type == 'Category') {
      document.getElementById('selectedCatName').innerHTML = value.itemName;
      this.selectedCategoryId = value.id;
      this.category = false;
    } else if (type == 'SubCategory1') {
      document.getElementById('selectedSubCat1Name').innerHTML = value.itemName;
      this.selectedSubCategory1Id = value.id;
      this.subcategory1 = false;
    }
    else if (type == 'SubCategory2') {
      document.getElementById("subCat2dropdown").classList.remove("btn");
      document.getElementById("subCat2dropdown").className = "activeButton";
      document.getElementById('selectedSubCat2Name').innerHTML = value.itemName;
      this.selectedSubCategory2Id = value.id;
      this.subcategory2 = false;
    }
    
  }
  popappendLabel(value, type) {
    if (type == 'Category') {
      document.getElementById('selectedpopupCatName').innerHTML = value.itemName;
      localStorage.setItem('selectedCategoryId', value.id);
      localStorage.setItem('selectedCategoryName', value.itemName);
      this.selectedCategoryId = value.id;
      this.popcategory = false;
    } else if (type == 'SubCategory1') {
      document.getElementById('selectedpopupSubCat1Name').innerHTML = value.itemName;
      localStorage.setItem('selectedCategory1Id', value.id);
      localStorage.setItem('selectedCategory1Name', value.itemName);
      this.selectedSubCategory1Id = value.id;
      this.popsubcategory1 = false;
    }
    else if (type == 'SubCategory2') {
      document.getElementById('selectedpopupSubCat2Name').innerHTML = value.itemName;
      localStorage.setItem('selectedCategory2Id', value.id);
      localStorage.setItem('selectedCategory2Name', value.itemName);
      this.selectedSubCategory2Id = value.id;
      this.popsubcategory2 = false;
    }

  }
  Delete(id, type) {
    if (type == 'Category') {
      this.leadservice.GetAllSUbCategoryQuestionsList(id, 'SubCategory1', this.LoggedInUser.id).then((subCategory1List) => {
        if (subCategory1List.length > 0) {
          alert("Sub Categories Exists for this Category");
        }
        else {
          this.customerService.DeactivateCategories(id, type).then((user) => {
            this.toastr.success(" Category Item deleted Successfully.", "Category", { timeOut: 600 });
            this.loadTaskGrouplist();
          });
        }

      });
    } else if (type == 'SubCategory1') {
      this.leadservice.GetAllSUbCategoryQuestionsList2(this.selectedCategoryId, id,this.LoggedInUser.id).then((subCategory2List) => {
        if (subCategory2List.length > 0) {
          alert("Sub Categories 2 Exists for this selected Sub Category 1");
        }
        else {
          this.customerService.DeactivateCategories(id, type).then((user) => {
            this.toastr.success(" Category Item deleted Successfully.", "Category", { timeOut: 600 });
            this.GetAllSubCategories1List(this.selectedCategoryId);
            
          });
        }
      });
    }
    else if (type == 'SubCategory2') {

      this.leadservice.GetAllQuestionsList(this.LoggedInUser.id, id, 0).then((QuestionsList) => {
        if (QuestionsList.length > 0) {
          alert("Statements Exists for this selected Sub Category 1");
        }
        else {
          this.customerService.DeactivateCategories(id, type).then((user) => {
            this.toastr.success(" Category Item deleted Successfully.", "Category", { timeOut: 600 });
            this.GetAllSubCategories2List(this.selectedCategoryId);
          });
        }
      });
      
    }
  }
  AddStyle(styleName, id, isbolded) {
    $('#' + id + 'boldContent').val(isbolded);
    if (isbolded) {
      $('#' + id +'EditContent').attr('style', 'font-weight:bold');
    }else {
      $('#' + id + 'EditContent').attr('style', 'font-weight:normal');
    }
  }
  close() { 
    this._location.back();
    //var url = window.location.href;
    //this.applicationComponent.displayheader(url);
    
  }
}
