<input type="text" id="selectedLastName" style="display:none;"/>
<input type="text" id="selectedId" style="display:none;" />
<input type="text" id="ccids" style="display:none;" />
<input type="text" id="Bccids" style="display:none;" />
<div class="container">
  <div id="CreateEmail">
    <div class="col-xs-12" style="padding: 0px;">
      <div class="col-xs-8" style="background:#f1f1f1;margin-top: 0.5%;padding-bottom: 0.5%;display: flex;padding: 0px;">
        <div class="col-xs-1" style="margin-top: 0.5%;padding:0px;">
          <button class="btn" style="width:70%;border:1px solid;background-color: #33CC33 !important;height: 180px; font-size: 19px;" (click)="SendEmail()">
            S<br />E<br />N<br />D<br />
          </button>
        </div>
        <div class="col-xs-2" style="margin-top: 0.5%;padding: 0px;">
          <div class="col-xs-12">
            <select class="form-control" id="sel1" style="padding-left:10%; border:0.5px solid #7b7878;background:#e9e9e9;">
              <option value="0">From</option>
            </select>
          </div>
          <div class="col-xs-12" style="margin-top: 3%;">
            <button style="width:100%;height: 33px;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;">To</button>
          </div>
          <div class="col-xs-12" style="margin-top: 4px;">
            <button style="width:100%;height: 30px;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;">Cc</button>
          </div>
          <div class="col-xs-12" style="margin-top: 4px;">
            <button style="width:100%;height: 29px;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;">Bcc</button>
          </div>
          <div class="col-xs-12" style="width:100%;height: 33px;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;text-align:center;margin-top:2.5%">
            Subject
          </div>
          <div class="col-xs-12" style="width:100%;height: 33px;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;text-align:center;margin-top:2.5%">
            Attachment
          </div>
        </div>
        <div class="col-xs-9" style="padding:0;margin-top:0.5%">
          <div class="col-xs-12" style="height: 34px;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;padding:0px">
            <label>{{currentuser.email}}</label>
          </div><br />
          <div class="col-xs-12" style="padding:0;margin-top: 0.7%;">
            <input type="text" id="To" placeholder="Enter To Email Ids" class="form-control" style="height:34px" />
          </div><br />
          <div class="col-xs-12" style="padding:0;margin-top:0.5%">
            <angular2-multiselect [data]="itemList" [(ngModel)]="selectedItems" [settings]="settings" (onSelect)="onItemSelect($event)"
                                  (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
              <c-search>
                <ng-template>
                  <input type="text" (keyup)="onSearch($event)" placeholder="Search  cc contacts" style="border: none;width: 100%; height: 100%;outline: none;" />
                </ng-template>
              </c-search>
              <c-item>
                <ng-template let-item="item">
                  <label style="color: #333;width: 150px;">{{item.name}}</label>
                </ng-template>
              </c-item>
            </angular2-multiselect>

          </div><br />
          <div class="col-xs-12" style="padding:0;margin-top:0.5%">
            <angular2-multiselect [data]="bccitemList" [(ngModel)]="BccselectedItems" [settings]="settings" (onSelect)="BcconItemSelect($event)"
                                  (onDeSelect)="BccOnItemDeSelect($event)" (onSelectAll)="BcconSelectAll($event)" (onDeSelectAll)="BcconDeSelectAll($event)">
              <c-search>
                <ng-template>
                  <input type="text" (keyup)="bcconSearch($event)" placeholder="Search bcc contacts" style="border: none;width: 100%; height: 100%;outline: none;" />
                </ng-template>
              </c-search>
              <c-item>
                <ng-template let-item="item">
                  <label style="color: #333;width: 150px;">{{item.name}}</label>
                </ng-template>
              </c-item>
            </angular2-multiselect>
          </div><br />
          <div class="col-xs-12" style="padding:0;margin-top:0.5%">
            <input id="Subject" type="text" class="form-control" placeholder="Add Subject" style="height:34px;" value="" />
          </div>
          <div class="col-xs-12" style="padding:0;margin-top:0.5%">
            <div class="col-xs-8" style="padding:0;">
              <input #fileUpload type="file" (change)="fileChangeEvent($event)" placeholder="Upload file..." multiple /><br />
              <!--<div *ngIf="filesToUpload?.length > 0"><br /><p>{{filesToUpload?.length}} files selected : </p></div>-->

              <ul>
                <li *ngFor="let fileName of selectedFileNames; let i = index">
                  {{fileName}}
                </li>
              </ul>

              <div [innerHtml]="uploadResult"></div>
            </div>
            <div class="col-xs-4">
              <button type="button" (click)="cancelUpload()">Clear Attachments</button>
            </div>


            <!--<div style="color:red">
              {{errorMessage}}
            </div>-->

          </div>
        </div>
      </div>

      <div class="col-xs-4" style="margin-top: 0.5%;border:1px solid #ddd;height:210px;padding:0px">
        <div class="col-xs-12" style="padding: 0 0 0 1%;margin-top: 0.5%;">
          <h4 style="font-weight: 600;">Account : {{accountName}}</h4>
          <label *ngIf="grayedContacts > 0" style="font-size: 15px" id="selectedLabel">{{selectedLabel}}: {{totalContacts}}&nbsp;(</label><label *ngIf="grayedContacts > 0" style="font-size: 15px; color: red">{{grayedContacts}} Not Associated</label><label *ngIf="grayedContacts > 0">)</label>
          <label style="font-size: 15px" id="selectedLabel" *ngIf="grayedContacts == 0">{{selectedLabel}}: {{totalContacts}}</label>
        </div>
        <div class="col-xs-12" style="padding: 0;margin-top: 0.5%;max-height: 12.25em;overflow: auto;padding-right: 0.5%;" id="selectedContacts">
        </div>
      </div>
    </div>

    <div class="col-xs-12" style="padding: 0;margin-top: 0.5%;height:362px;overflow:inherit">
      <div id="noteid" [froalaEditor]="editorOptions" [(froalaModel)]="messagedescription" style="">
        <p id="nameLast"></p>
        I Wanted to let you know that many off your colleagues have already added the MDLOGIC PM(practice management software) to the MD Logic EHR Software.this provides an all-in-one solution for your practice saving time and increasing efficiency.<br />I would like to schedule 15 minutes next week to simply review what benefits are available when choosing to add the MD Logic PM software. <br />Please let me know your best time and i will schedule a meeting.
        <br />
        Thanks again for being a valued customer.
      </div>
    </div>
  </div>

  <app-dialog [(visible)]="emailAlert">
    <label style="width:100%;text-align:center"><u>EMAIL ALERT</u></label>
    <br />
    <br />
    <label style="width:100%;text-align:center">The contacts you have selected are "NOT" all associated with the same account.</label>
    <br />
    <br />
    <label style="width:100%;text-align:center;margin:0 !important">To continue sending this email,</label>    
    <label style="width:100%;text-align:center;margin:0 !important">please select <a style="cursor:pointer" (click)="emailAlertResponse(true)">Continue</a></label>    
    <label style="width:100%;text-align:center;font-size:12px">(emails will be sent BCC)</label>
    <br />
    <br />
    <label style="width:100%;text-align:center;margin:0 !important">To remove contacts from the list</label>    
    <label style="width:100%;text-align:center">please select <a style="cursor:pointer" (click)="emailAlertResponse(false)">Return</a></label>
  </app-dialog>

  <ag-grid-angular  style="width: 100%; height:526px; padding-top: 10px;"
                   class="ag-theme-balham" *ngIf="SentEmailTable"
                   [gridOptions]="gridemailOptions"
                   [columnDefs]="emailcolumnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="sentemaillist"
                   [sideBar]="sideBar"
                   (gridReady)="onsentemailAgGridReady()">
  </ag-grid-angular>
</div>
