import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AddressUpdate } from 'src/app/models/AddressUpdate';
declare var $: any;

@Component({
  selector: 'app-imported',
  templateUrl: './imported.component.html',
  styleUrls: ['./imported.component.css']
})
export class ImportedComponent implements OnInit {
  source: any;
  
  currentPage: number = 1;
  startrow: number = 1;
  noofRows: number = 1;
  totalnumberofrows: number = 1;
  totalnumberofPages: number = 1;
  addressupdate: AddressUpdate = new AddressUpdate();
  constructor(private route: ActivatedRoute, private accountservice: AccountsService, private customerService: CustomerService) { }

  ngOnInit() {
     
    $(".subButtons").removeClass("activeButton");
    $("#imported_button").addClass("activeButton");
    this.route.params.subscribe(params => {
       
      this.source = params['sourcepage'];
      
    });

  }
  
  //UpdateContactAddress() {
  //  this.accountservice.GetAllAccounts().then(accounts => {     

  //    accounts.forEach(async ele => {
  //      this.addressupdate.address1 = ele.address1;
  //      this.addressupdate.address2 = ele.address2;
  //      this.addressupdate.city = ele.city;
  //      this.addressupdate.state = ele.state;
  //      this.addressupdate.zip = ele.zip;
  //      this.addressupdate.AccountId = ele.id;
  //      this.addressupdate.lattitude = ele.lattitude;
  //      this.addressupdate.longtitude = ele.longitude;
  //      this.addressupdate.mapit = ele.mapit;
  //      await  this.updatecontactaddress(this.addressupdate);
  //      //var response =await this.accountservice.updatecontactaddress(this.addressupdate).then(result => {
        
  //      //  return response;
  //      //});
     
  //    });
  //  });
  //}

  //updatecontactaddress(addressupdate) {
  //  this.accountservice.sp_updateAccAddressToContact(addressupdate).then(result => {
  //    this.addressupdate = new AddressUpdate();
  //  });
  //}

}
