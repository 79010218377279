export class Accountsharing {
  public id: number;
  public permissiontoeditaccount: any;
  public permissiontoviewaccount: any;
  public createdby: number;
  public createddate: string;
  public permissionGroups: any;
  public accountShareId: number;
  public permssionuser: string;
  public viewpermissionGroups: string;
  public Type: string; 
}

export class SharingGroups {
  public SharingGroupId: number;
  public SharingGroupName: string;
  public SortOrder: number;
  public isdeleted: number;
  public CreatedDate: string;
  public Createdby: number;
}
