import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'editdeletecvcheck',
  templateUrl: './editdeletecvcheck.component.html',
  styleUrls: ['./editdeletecvcheck.component.css']
})
export class EditdeletecvcheckComponent implements OnInit {
  currentuser: any;

  constructor() {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
  }

  public params: any;

  agInit(params: any): void {
     
    this.params = params;
    //alert()
  }
  
  public editRowAg() {
    this.params.context.editRowAg(this.params.node.data);
  }

  public deleteRowAg() {
    this.params.context.deleteRowAg(this.params.node.data);
  }

  public convertContactAg() {
    this.params.context.convertContactAg(this.params.node.data);
  }
  public callcheck() {
    this.params.context.callcheck(this.params.node.data);
  }
  public deletetoOpenRowAg() {
    this.params.context.deletetoOpenRowAg(this.params.node.data);

  }

  needSharing() {
    var url = window.location.href;
    if (url.indexOf('calllist') > -1) {
      return  true;
    }
   
  }
  refresh(): boolean {
    return false;
  }
  sharingAg() {
    this.params.context.sharingAg(this.params.node.data);
  }
}
