

    <div [ngClass]="statusIdEditExist ? 'col-xs-12' :'col-xs-6'" style="padding: 1%;" *ngIf="!statusIdcustomerEditExist">
      <div class="col-xs-12" style="padding: 0;">
        <label style="font-weight: 700; font-size: 15px;  margin-top: 2%">Status ID - Account Profile</label>
      </div>
      <div class="col-xs-12" style="height: 400px; padding: 0;">
        <div (click)="doSomething();" style="height: 7%;">
          <select class="form-control" style="height: 100%; padding: 0 2% 0 2%; font-size: 12px;">
            <option value="null" [selected]="true">Select Account Profile Fields</option>
          </select>
        </div>
        <div class="col-xs-12" style="padding:15px 0 0 0px">
          <div id="StatusIDName1" (click)="SelectedStatusID('StatusIDName1');"> {{StatusIds.StatusIDName1}}</div>
          <div id="StatusIDName2" (click)="SelectedStatusID('StatusIDName2');"> {{StatusIds.StatusIDName2}}</div>
          <div id="StatusIDName3" (click)="SelectedStatusID('StatusIDName3');"> {{StatusIds.StatusIDName3}}</div>
          <div id="StatusIDName4" (click)="SelectedStatusID('StatusIDName4');"> {{StatusIds.StatusIDName4}}</div>
          <div id="StatusIDName5" (click)="SelectedStatusID('StatusIDName5');"> {{StatusIds.StatusIDName5}}</div>
          <div id="StatusIDName6" (click)="SelectedStatusID('StatusIDName6');"> {{StatusIds.StatusIDName6}}</div>
          <div id="StatusIDName7" (click)="SelectedStatusID('StatusIDName7');"> {{StatusIds.StatusIDName7}}</div>
          <div id="StatusIDName8" (click)="SelectedStatusID('StatusIDName8');"> {{StatusIds.StatusIDName8}}</div>
          <div id="StatusIDName9" (click)="SelectedStatusID('StatusIDName9');"> {{StatusIds.StatusIDName9}}</div>
          <div id="StatusIDName10" (click)="SelectedStatusID('StatusIDName10');"> {{StatusIds.StatusIDName10}}</div>
          <div id="StatusIDName11" (click)="SelectedStatusID('StatusIDName11');"> {{StatusIds.StatusIDName11}}</div>
          <div id="StatusIDName12" (click)="SelectedStatusID('StatusIDName12');"> {{StatusIds.StatusIDName12}}</div>
          <div id="StatusIDName13" (click)="SelectedStatusID('StatusIDName13');"> {{StatusIds.StatusIDName13}}</div>
          <div id="StatusIDName14" (click)="SelectedStatusID('StatusIDName14');"> {{StatusIds.StatusIDName14}}</div>
          <div id="StatusIDName15" (click)="SelectedStatusID('StatusIDName15');"> {{StatusIds.StatusIDName15}}</div>
          <div id="StatusIDName16" (click)="SelectedStatusID('StatusIDName16');"> {{StatusIds.StatusIDName16}}</div>
          <div id="StatusIDName17" (click)="SelectedStatusID('StatusIDName17');"> {{StatusIds.StatusIDName17}}</div>
          <div id="StatusIDName18" (click)="SelectedStatusID('StatusIDName18');"> {{StatusIds.StatusIDName18}}</div>
          <div id="StatusIDName19" (click)="SelectedStatusID('StatusIDName19');"> {{StatusIds.StatusIDName19}}</div>
          <div id="StatusIDName20" (click)="SelectedStatusID('StatusIDName20');"> {{StatusIds.StatusIDName20}}</div>
        </div>

        <ag-grid-angular *ngIf="statusIdEditExist" style="width: 100%; height:100% !important;" id="gridheight" class="ag-theme-balham"
                         [headerHeight]="0"
                         [gridOptions]="gridOptions"
                         [columnDefs]="columnDefs"
                         [rowData]="AccountIdArr"
                         [context]="context"
                         [sideBar]="sideBar"
                         (gridReady)="onAgGridReady($event)">
        </ag-grid-angular>

      </div>
      <div class="col-xs-12" style="padding: 0; height: 20%; padding-top: 150px" *ngIf="statusIdEditExist">
        <div class="col-xs-6" style="padding: 0;padding-right:3px;height:100%">
          <button class="btn" (click)="cancelaccountIds()" style="width: 70%; border:1px solid;height:100%">Cancel</button>
        </div>
        <div class="col-xs-6" style="padding: 0;padding-left:3px;height:100%">
          <button class="btn saveButton" (click)="Updatesaccounts('Account')" style="color:black;width: 70%; border:1px solid;height:100%">Save</button>
        </div>

      </div>
    </div>

    <div [ngClass]="statusIdcustomerEditExist ? 'col-xs-12' :'col-xs-6'" style="padding: 1%;height:12%" *ngIf="!statusIdEditExist">
      <div class="col-xs-11" style="padding:0.1px;margin-top:2%" *ngIf="account.isCustomer == 874">
        <label style="font-size: 15px;font-weight: 700;">Status ID - Customer Profile</label>
      </div>
      <div class="col-xs-12" style="height: 400px; padding: 0;" *ngIf="account.isCustomer == 874">
        <div (click)="Docustomerclick();" style=" height: 7%;">
          <select class="form-control" style="height: 100%; padding: 0 2% 0 2%; font-size: 12px;">
            <option value="null" [selected]="true">Select Customer Profile Fields</option>
          </select>
        </div>

        <div class="col-xs-12" style="padding:15px 0 0 0px">
          <div id="StatusIDName21" (click)="SelectedStatusID('StatusIDName21');"> {{StatusIds.StatusIDName21}}</div>
          <div id="StatusIDName22" (click)="SelectedStatusID('StatusIDName22');"> {{StatusIds.StatusIDName22}}</div>
          <div id="StatusIDName23" (click)="SelectedStatusID('StatusIDName23');"> {{StatusIds.StatusIDName23}}</div>
          <div id="StatusIDName24" (click)="SelectedStatusID('StatusIDName24');"> {{StatusIds.StatusIDName24}}</div>
          <div id="StatusIDName25" (click)="SelectedStatusID('StatusIDName25');"> {{StatusIds.StatusIDName25}}</div>
          <div id="StatusIDName26" (click)="SelectedStatusID('StatusIDName26');"> {{StatusIds.StatusIDName26}}</div>
          <div id="StatusIDName27" (click)="SelectedStatusID('StatusIDName27');"> {{StatusIds.StatusIDName27}}</div>
          <div id="StatusIDName28" (click)="SelectedStatusID('StatusIDName28');"> {{StatusIds.StatusIDName28}}</div>
          <div id="StatusIDName29" (click)="SelectedStatusID('StatusIDName29');"> {{StatusIds.StatusIDName29}}</div>
          <div id="StatusIDName30" (click)="SelectedStatusID('StatusIDName30');"> {{StatusIds.StatusIDName30}}</div>
          <div id="StatusIDName31" (click)="SelectedStatusID('StatusIDName31');"> {{StatusIds.StatusIDName31}}</div>
          <div id="StatusIDName32" (click)="SelectedStatusID('StatusIDName32');"> {{StatusIds.StatusIDName32}}</div>
          <div id="StatusIDName33" (click)="SelectedStatusID('StatusIDName33');"> {{StatusIds.StatusIDName33}}</div>
          <div id="StatusIDName34" (click)="SelectedStatusID('StatusIDName34');"> {{StatusIds.StatusIDName34}}</div>
          <div id="StatusIDName35" (click)="SelectedStatusID('StatusIDName35');"> {{StatusIds.StatusIDName35}}</div>
          <div id="StatusIDName36" (click)="SelectedStatusID('StatusIDName36');"> {{StatusIds.StatusIDName36}}</div>
          <div id="StatusIDName37" (click)="SelectedStatusID('StatusIDName37');"> {{StatusIds.StatusIDName37}}</div>
          <div id="StatusIDName38" (click)="SelectedStatusID('StatusIDName38');"> {{StatusIds.StatusIDName38}}</div>
          <div id="StatusIDName39" (click)="SelectedStatusID('StatusIDName39');"> {{StatusIds.StatusIDName39}}</div>
          <div id="StatusIDName40" (click)="SelectedStatusID('StatusIDName40');"> {{StatusIds.StatusIDName40}}</div>
        </div>

        <ag-grid-angular *ngIf="statusIdcustomerEditExist" style="width: 100%; height:100% !important;" id="gridheight" class="ag-theme-balham"
                         [headerHeight]="0"
                         [gridOptions]="gridCustomerOptions"
                         [columnDefs]="columnDefs"
                         [rowData]="StatusIDAHx"
                         [context]="context"
                         [sideBar]="sideBar"
                         (gridReady)="onAgCustomerGridReady($event)">
        </ag-grid-angular>
      </div>
      <div class="col-xs-12" style="padding: 0; height: 20%; padding-top: 150px" *ngIf="statusIdcustomerEditExist">
        <div class="col-xs-6" style="padding: 0;padding-right:3px;height:100%">
          <button class="btn" (click)="cancelCustomeraccountIds()" style="width: 70%; border:1px solid;height:100%">Cancel</button>
        </div>
        <div class="col-xs-6" style="padding: 0;padding-left:3px;">
          <button class="btn saveButton" (click)="Updatesaccounts('Customer')" style="color:black;width: 70%; border:1px solid;height:100%">Save</button>
        </div>
      </div>
    </div>


