import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef, Optional, Input } from '@angular/core';
//import { CalendarComponent } from "ap-angular-fullcalendar";
import { Http } from '@angular/http';
import { Opportunities } from '../../models/opportunities';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from './../../services/customer.service';
import { DatePipe } from '@angular/common';
import { TaskHistory } from '../../models/task-history';
import { Notes } from '../../models/notes';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import { LeadsService } from './../../services/leads.service';
import { SalesProcessHistory } from '../../models/sales-process-history';
import { ToastrService } from 'ngx-toastr';;
import { Contacts } from '../../models/contacts';
import { Accounts } from '../../models/accounts';
import { Customers } from '../../models/customers';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { NotesrendererComponent } from '../notesrenderer/notesrenderer.component';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { Column } from 'ag-grid';
import * as moment from 'moment'
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../application.component';
import { ScheduletaskComponent } from "../scheduletask/scheduletask.component";
declare var require: any;
declare var $: any;
@Component({
  selector: 'tasklist',
  templateUrl: './tasklist.component.html',
  styleUrls: ['./tasklist.component.css']
})
export class TasklistComponent implements OnInit {
  showDialog = false;
  isOn: boolean = true;
  check: boolean = true;
  datepickerOpts: any;
  calenderMonthlist = ["August"];
  num: any;
  ids: any;
  title: any;
  calendarOptions: any;
  events = [];
  FirstEvents = [];
  call: any;
  times: any;
  titlesave: Date;
  startsave: Date;
  startdate: Date;
  enddate: Date;
  endsave: Date;
  editRowId: any;
  itemCount = 0;
  dayitemCount = 0;
  params: any;
  dayparams: any;
  Detailsdiv: boolean = false;
  updatediv: boolean = true;
  mycalander1clicked: boolean = false;
  mycalander2clicked: boolean = false;
  hdrHtml0: boolean = false;
  hdrHtml1: boolean = false;
  hdrHtml2: boolean = false;
  taskDialog: boolean = false;
  finaldata = [];
  newEvents = [];
  globaleventid: number;
  globaldata = [];
  globalEvent = [];
  items = [];
  dayitems = [];
  titlesss: any;
  dropdownSettings = {};
  finalitems = "";
  myhidden: boolean = true;
  myvar: boolean = true;
  myremindersdiv: boolean = false;
  taskhistory: TaskHistory = new TaskHistory();
  saleprocesshistory: SalesProcessHistory = new SalesProcessHistory();
  @ViewChild(ScheduletaskComponent) scheduleComp;
  contact: Contacts = new Contacts();
  account: Accounts = new Accounts();
  taskhistorynotes: Array<Notes> = [];
  TaskStatuslist: Dropdowntype[] = [];
  Tasktypelist: Dropdowntype[] = [];
  taskhistoryList = new Array();
  notes: Notes = new Notes();
  //@ViewChild(CalendarComponent) myCalendar: CalendarComponent;
  inbound: Array<any> = [];
  demo1Vals: any;
  displayKey: String = 'name';
  allSelected: Boolean = false;
  groupBy: string = 'type';
  statustype: string = 'Open';
  groups: Array<any>;
  dropDownVisible: boolean = false;
  selectedItems: Array<any>;
  inboundSelector: Array<any>;
  filterVal: String;
  userprevilages: any;
  currentuser: any;
  itemResource: any;
  dayitemResource: any;
  datePipe: DatePipe = new DatePipe("en-US");
  searchDiv: boolean = false;
  CustomeritemResource: any;
  ContactitemCount = 0;
  CustomeritemCount = 0;
  Contactitems = [];
  Customeritems = [];
  public gridOptions: GridOptions;
  public rowData: any[];
  public columnDefs: any[];
  pageSize: string = "";
  tableDiv: boolean = true;
  labelDiv: boolean = true;
  dropdownList = [];
  dropdownSetting = {};
  alltaskhistory: TaskHistory[];
  dayalltaskhistory: TaskHistory[];
  aggridheight: string;
  timezonevalue: string;
  public context;
  public gridOptionsnotes: GridOptions;
  public columnDefsnotes: any[];
  allnotes: Notes[];
  @ViewChild('closeBtn') closeBtn: ElementRef;
  public frameworkComponents;
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear()-1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear()-1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");

  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  NotesDec = false;
  taskhistoryid: number;
  contactid: number;
  conid: number;
  customer: Customers = new Customers();
  selectedmonth = new Date();
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  claenderViewlist: Dropdowntype[];
  changeCalendarView(view) {
   // this.myCalendar.fullCalendar('changeView', view);
  }
  close() {
    this.closeBtn.nativeElement.click();
  }
  onCalendarInit() {
  }
  ngAfterViewInit(): void {
    this.ResizeTable();
  }
  @Input()
  id: number;
  @Input()
  type: string;
  @Input()
  taskid: number;
  today = Date.now();
  constructor(public applicationComponent: ApplicationComponent, private http: Http, private router: Router, private customerService: CustomerService, private opprtunityservice: OpprtunitiesService, private leadservice: LeadsService, private toastr: ToastrService, private viewContainerRef: ViewContainerRef) {
    this.selectedItems = [];
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    }
    var loggedInUserId = this.currentuser.id;
    this.refresh();
  }

  HideAdnShow(id, type) {

    if (id == 0 || id == '' || id == null) {
      this.labelDiv = true;
      this.searchDiv = false;
      this.tableDiv = false;
    } else {
      this.labelDiv = false;
      this.searchDiv = true;
      this.tableDiv = true;
      if (type == "Contacts") {
        this.customerService.GetContactById(id).then(result => {
          this.contact = result;

        });
        document.getElementById('customerDiv').style.display = 'none';
        document.getElementById('ContactDiv').style.display = 'block';

      } else if (type == "Customer") {
        this.customerService.GetByAccountId(id).then(result => {
          this.account = result;

        });
        document.getElementById('customerDiv').style.display = 'block';
        document.getElementById('ContactDiv').style.display = 'none';
      }
    }

    this.leadservice.GetSalesStagesList().then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
    });
  }
  createColumnDefs(columnSelections: string) {
    let selfobj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false });
        } else if (fieldHeaderVisibleArray[0] == "owner") {
          this.columnDefs.push({
            field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, cellStyle: function (params) {
              if (params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) {
                return { color: '#FF0000' }
              }
            }});
        } else {
          this.columnDefs.push({
            field: fieldHeaderVisibleArray[0],
            headerName: fieldHeaderVisibleArray[1],
            hide: fieldHeaderVisibleArray[2] == "true" ? false : true
            , cellStyle: function (params) {
              if (params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) {
                return { color: '#FF0000' }
              }
            }
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false
        },

        {
          field: 'datecreated', headerName: 'Date Created', hide: false, cellStyle: function (params) {
            if (params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) {
              return { color: '#FF0000' }
            }
          }},
        {
          field: 'DateSched', headerName: 'Date Scheduled', hide: false, cellStyle: function (params) {
            if (params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) {
              return { color: '#FF0000' }
            }
          } },
        {
          field: 'status', headerName: 'Status', hide: false, cellStyle: function (params) {
            if (params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) {
              return { color: '#FF0000' }
            }
          } },
        {
          field: 'accountname', headerName: 'Account Name', hide: false, cellStyle: function (params) {
            if (params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) {
              return { color: '#FF0000' }
            }
          } },
        {
          field: 'tasktype', headerName: 'Type', hide: false, cellStyle: function (params) {
            if (params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) {
              return { color: '#FF0000' }
            }
          } },
        {
          field: 'contactName', headerName: 'Contact Name', hide: false, cellStyle: function (params) {
            if (params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) {
              return { color: '#FF0000' }
            }
          }},
        {
          field: 'time', headerName: 'Start Time', hide: false, cellStyle: function (params) {
            if (params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) {
              return { color: '#FF0000' }
            }
          } },
        {
          field: 'totime', headerName: 'End Time', hide: true, cellStyle: function (params) {
            if (params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) {
              return { color: '#FF0000' }
            }
          } },  //true starts here
        {
          field: 'owner', headerName: 'Owner', hide: false, cellStyle: function (params) {
            if (params.data.DateSched < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.DateSched, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) {
              return { color: '#FF0000' }
            }
          } },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false }
      ];
    }
  }
  onAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
      $(setTimeout(function () {
        selfObj.autoSizeAll();
      }, 100));
    }, 100));
  }
  autoSizeAll() {
    var allColumnIds = [];
    if (this.gridOptions && this.gridOptions.columnApi) {
      this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.getColId());
      });
    }
  }
  txtPageSizeChanged(pageSize) {

    this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    this.saveConfiguration();
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  saveConfiguration() {

    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";
    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 9;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

    //// this.toastr.setRootViewContainerRef(viewContainerRef);
    //this.toastr.success("Configuration Saved Successfully");
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    let columnSelections: string;
    //let selectedUserIds: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 9).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.gridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(columnSelections);
      this.context = this;

    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };

  }
  rowClickAgGrid(rowEvent) {

  }
  rowDoubleClickAgGrid(rowEvent) {
    this.router.navigateByUrl(`/application/tasklistdashboard/${rowEvent.data.type}/${rowEvent.data.contactid}/${rowEvent.data.id}`);

  }
  ngOnInit() {

    this.customerService.GetPrivilagesByuser(this.currentuser.id).then(result => {
      this.userprevilages = result;
    });
    this.customerService.GetSpecialityList("calenderView", this.currentuser.id).then((calender) => {
      calender.forEach(accounttype => {
      });
      this.claenderViewlist = calender;
    });
    var moment = require('moment-timezone');
    this.timezonevalue = moment.tz.guess();
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1,0,0).then((Owner) => {
      Owner.forEach(Owner => {
      });

      this.dropdownList = Owner;
       
      if (this.applicationComponent.selectedusers != '' || this.applicationComponent.selectedusers != null) {
        this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
          Owner.forEach(Owner => {
          });
          this.selectedItems = Owner;
        })
        this.TaskData('Open', $("#userids").val());
      }
      else {
        this.selectedItems = [
          { "id": this.currentuser.id, "itemName": this.currentuser.username }
        ];
        this.finalitems += this.currentuser.id + ',';
        //$("#userids").val(this.currentuser.id);

        this.TaskData('Open', $("#userids").val())
      }
    })
    var hdrHtml = `<div class="clear-fix"></div> 
                  <div class="row btn-group" style="margin-top:7px; float:left;">
	                  <button type="button" class="btn" style="background-color:green;color:white;height: 20px;font-size: 12px !important;font-weight: bold;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Open &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
	                  <button type="button" class="btn" style="background-color: blue;color: white;height: 20px;font-size: 12px !important;font-weight: bold;">&nbsp;&nbsp;&nbsp;&nbsp; Completed &nbsp;&nbsp;&nbsp;&nbsp;</button>
	                  <button type="button" class="btn" style="background-color: red;color: white;height: 20px;font-size: 12px !important;font-weight: bold;">Not-Completed</button>
                  </div>`;
    let selfobj = this;
    $(setTimeout(function () {
      $('#myCalendar0 .fc-center').after(hdrHtml);
      $('.fc-rigid').attr('style', 'height:80px');
      $('.fc-day-grid-container').attr('style', 'height:auto')
    }, 750));

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.initializeAgGrid();
  }
  reloadItems(params) {
    if (this.itemResource != undefined) {
      this.itemResource.query(params).then(items => this.items = items);
    }
  }
  dayreloadItems(dayparams) {
    if (this.dayitemResource != undefined) {
      this.dayitemResource.query(dayparams).then(dayitems => this.dayitems = dayitems);
      $('#notesTextArea').val('');
    }
  }
  getSelectedItems(): Array<any> {
    return this.selectedItems;
  }
  ActiveTask(id) {
    this.opprtunityservice.GetTaskStatus("TaskStatus", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.TaskStatuslist = pc;
    });
    $(".actionToDo").removeClass("activeColor");
    $(".changeActive").removeClass("activeButton");
    $(".changeActive").addClass("noprovider");
    $("#" + id).removeClass("noprovider");

    if (id == 'meeting') {
      $("#" + id + "_span").addClass("activeColor");
      $("#" + id).addClass("activeButton");
      $('#sel1 > option:first-child').text('Select Meeting List');
    } else if (id == 'phonecall') {
      $("#" + id + "_span").addClass("activeColor");
      $("#" + id).addClass("activeButton");
      $('#sel1 > option:first-child').text('Select Phone Call List');
    } else if (id == 'todo') {
      $("#" + id + "_span").addClass("activeColor");
      $("#" + id).addClass("activeButton");
      $('#sel1 > option:first-child').text('Select To Do List');
    }
    $('.spanStatus').removeClass('activeColor');
    $('.labelStatus').removeClass('redColor');
    var idStatus = 167;
    var status = 'Open'

    var spanId = idStatus + '_span';
    $('#' + idStatus).addClass('redColor');
    $('#' + spanId).addClass('activeColor');
    this.leadservice.GetAllTasksList(id, this.saleprocesshistory.salesstage, this.currentuser.TaskGroupid, 0, this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Tasktypelist = accounttype;
    });
  }
  selectedStatus(status) {
    if ($('#sel1').val() != null) {
      var id = status.id;
      this.saleprocesshistory.salesstage = status.itemName;
      this.taskhistory.status = status.itemName;
      var spanId = status.id + '_span';
      $('.spanStatus').removeClass('activeColor');
      $('.labelStatus').removeClass('redColor');
      $('#' + id).addClass('redColor');
      $('#' + spanId).addClass('activeColor');
      document.getElementById('selectStatus').innerHTML = status.itemName;
      document.getElementById('taskSave').style.display = 'block';
    }
  }
  completeForm() {

    if ((this.taskhistory.tasktype == "" || this.taskhistory.tasktype == undefined) && (($('#sel1').val() == "") || $('#sel1').val() == null)) {
      document.getElementById('taskSave').style.display = 'none';
    } else if (this.taskhistory.tasktype == undefined && ($('#sel1').val() == "")) {
      document.getElementById('taskSave').style.display = 'none';
    } else {
      document.getElementById('taskSave').style.display = 'block';
    }

  }
  SaveTaskHistory() {

    if (this.taskhistory.id == undefined) {//save
      if (this.userprevilages != undefined) {
        for (var j = 0; j < this.userprevilages.length; j++) {
          if ("Sched Task" == this.userprevilages[j].resource) {
            if (this.userprevilages[j].canadd == false) {
              this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
              return;
            }

          }
        }
      }
    }
    else {//edit
      if (this.userprevilages != undefined) {
        for (var j = 0; j < this.userprevilages.length; j++) {
          if ("Sched Task" == this.userprevilages[j].resource) {
            if (this.userprevilages[j].canedit == false) {
              this.toastr.warning("You Do Not have Access to update – Please check with CRM Administrator if Access is Required.", "Access Denied");
              return;
            }

          }
        }
      }
    }

    this.taskhistory.contactid = this.contact.id;
    this.taskhistory.type = this.type;
    this.taskhistory.userid = this.currentuser.id;
    this.taskhistory.timezone = this.currentuser.timezone;
    this.taskhistory.tasktype = $("#sel1 option:selected").text();
    if (this.taskhistory.time != null) {
      this.taskhistory.starttime = moment(this.taskhistory.estcompleteddate).format("MM/DD/YYYY") + ' ' + this.taskhistory.time;
    }
    if (this.taskhistory.totime != null) {
      this.taskhistory.endtime = moment(this.taskhistory.estcompleteddate).format("MM/DD/YYYY") + ' ' + this.taskhistory.totime;
    }
    this.leadservice.addTaskHistory(this.taskhistory).then(result => {
      this.notes.accountid = result.id;
      this.close();
      this.notes.type = "Lead";
      this.notes.userid = this.currentuser.id;
      this.opprtunityservice.addopprtunityNotes(this.notes).then(result => {
        this.notes = result;
        this.notes.notedescription = '';
      });
      this.checkvalues($('#taskstatus').val(), $("#userids").val());

    });

  }


  checkvalues(val, userids) {
    this.newEvents = [];

    this.leadservice.GetAllTaskHistory(val, userids, this.startDateVal, this.endDateVal,'tasklist').then((taskhistory) => {
      taskhistory.forEach(taskhistory => {
        taskhistory.datecreated = this.datePipe.transform(taskhistory.datecreated, "MM-dd-yyyy");
        taskhistory.DateSched = ((taskhistory.estcompleteddate == "0001-01-01") ? "" : this.datePipe.transform(taskhistory.estcompleteddate, "MM-dd-yyyy"));
        var moment = require('moment-timezone');
        taskhistory.estcompleteddate = moment.tz(taskhistory.estcompleteddate.toLocaleString(), this.timezonevalue).format("YYYY-MM-DD");
        if (taskhistory.status == 'Open') {
          (this.finaldata).push({ id: taskhistory.id, Contact: taskhistory.contactName, title: ((taskhistory.time != null && taskhistory.time != '0:00') ? taskhistory.time : '') + ' ' + taskhistory.tasktype, start: taskhistory.estcompleteddate, From: taskhistory.time, To: taskhistory.totime, backgroundColor: 'green' });

        }
        else if (taskhistory.status == 'Completed') {
          (this.finaldata).push({ id: taskhistory.id, Contact: taskhistory.contactName, title: ((taskhistory.time != null && taskhistory.time != '0:00') ? taskhistory.time : '') + ' ' + taskhistory.tasktype, start: taskhistory.estcompleteddate, From: taskhistory.time, To: taskhistory.totime, backgroundColor: 'blue' });
        }
        else {
          (this.finaldata).push({ id: taskhistory.id, Contact: taskhistory.contactName, title: ((taskhistory.time != null && taskhistory.time != '0:00') ? taskhistory.time : '') + ' ' + taskhistory.tasktype, start: taskhistory.estcompleteddate, From: taskhistory.time, To: taskhistory.totime, backgroundColor: 'red' });
        }

      });

      if (this.check == true) {
        this.newEvents = this.finaldata;

        $('#myCalendar').fullCalendar('removeEvents');
      
        this.calendarOptions.events = this.newEvents;

        $('#myCalendar').fullCalendar('renderEvents', this.newEvents, true);
     
    
        this.finaldata = [];
      }
      this.alltaskhistory = taskhistory;
    });

  }

  TaskData(val, userids) {
    userids = $("#userids").val();
    this.statustype = val;
    if ($("#userids").val() != '') {
      this.checkvalues(val, userids);
    }
    else {
      this.items = [];
      this.itemCount = 0;
      this.calendarOptions.events = [];
     // this.myCalendar.fullCalendar('refresh');
    }
    $(setTimeout(function () {
      $('.fc-rigid').attr('style', 'height:100px');
      $('.fc-day-grid-container').attr('style', 'height:auto')
    }, 500));
  }
  clientEvents(idOrFilter) {
  }
  refresh() {
    var that = this;
    that.taskhistoryList = [];
    this.calendarOptions = {

      height: 720,
      fixedWeekCount: false,
      header:
        {
          left: '',
          center: 'title',
          right: 'month,agendaWeek,agendaDay,listYear prev,next <br />'
        },
      defaultDate: that.selectedmonth,
      editable: true,     
      eventMouseover: function (data, event, view) {
        var notes = "";
        that.leadservice.GetAllTaskHistoryById(data.id, 'Lead').then((items) => {
          items.forEach(items => {
          });
          this.taskhistorynotes = items;

        });
        var notesli = "";
        this.taskhistorynotes.forEach((res) => {
          notesli += '<li>' + res.created.toString("mm-dd-yyyy").replace("T", " ") +  ' : ' + res.notedescription + '</li>';
        });

        var tooltip = '<div class="tooltiptopicevent" style="width:auto;height:auto;background:rgb(162, 228, 119);position:absolute;z-index:10001;padding:10px 10px 10px 10px ;  line-height: 200%;">' + 'Contact: ' + data.Contact + '</br>' + 'Type: ' + data.title + '</br>' +
          'Notes: ' + notesli + '<br/>'
          + 'From' + ': ' + (data.start).format("MM/DD/YYYY") + '</div>';
        var btnTrial = '<div style="width:auto;height:auto;background:#feb811;position:absolute;z-index:10001;padding:10px 10px 10px 10px ;  line-height: 200%;">Hey there, this is a test.</div>';
        $("body").append(tooltip);
        $(this).mouseover(function (e) {
          $(this).css('z-index', 10000);
          $('.tooltiptopicevent').fadeIn('500');
         // $('.tooltiptopicevent').fadeTo('10', 1.9);
        }).mousemove(function (e) {
          $('.tooltiptopicevent').css('top', e.pageY + 10);
          $('.tooltiptopicevent').css('left', e.pageX + 20);
        });


      },
      eventMouseout: function (data, event, view) {
        $(this).css('z-index', 8);

        $('.tooltiptopicevent').remove();

      },
      eventLimit: true,
      selectable: true,
      //events: [],
      droppable: true,
      dayClick: function (date, jsEvent, view, resourceObj) {
         
        that.taskDialog = !that.taskDialog;
        that.scheduleComp.LoadSelectedTaskdata(this.taskid, "", "", true);
      },
      eventClick: function (event, delta, revertFunc) {
        that.taskDialog = !that.taskDialog;
        that.leadservice.GetByTaskHistoryId(event.id, "View", this.currentuser.timezone).then(result => {
          that.taskhistory = result;
          that.id = that.taskhistory.contactid;
          that.taskid = that.taskhistory.id;
          that.type = that.taskhistory.type;

          that.scheduleComp.LoadSelectedTaskdata(that.taskid, that.taskhistory.status, "", true);
          that.scheduleComp.loadtaskHistoryByselecteddate(that.taskhistory.estcompleteddate);
        });
      },
      eventDrop: function (event, delta, revertFunc) {
        that.taskhistory.time = event.start.format("hh:mm");
        that.leadservice.GetByTaskHistoryId(event.id, "View", that.currentuser.timezone).then(result => {
          that.taskhistory = result;
          that.taskhistory.estcompleteddate = event.start;
          that.taskhistory.contactid = that.taskhistory.contactid;
          that.taskhistory.type = that.taskhistory.type;
          that.taskhistory.userid = that.currentuser.id;
          that.taskhistory.timezone = that.currentuser.timezone;
          this.taskhistory.tasktype = $("#sel1 option:selected").text();
          if (this.taskhistory.time != null) {
            this.taskhistory.starttime = moment(this.taskhistory.estcompleteddate).format("MM/DD/YYYY") + ' ' + this.taskhistory.time;
          }
          if (this.taskhistory.totime != null) {
            this.taskhistory.endtime = moment(this.taskhistory.estcompleteddate).format("MM/DD/YYYY") + ' ' + this.taskhistory.totime;
          }
          that.leadservice.addTaskHistory(that.taskhistory).then(result => {
            this.notes.accountid = result.id;
            this.notes.type = "TaskHistory"; 
          });
          this.checkvalues(result.status, $("#userids").val());
        });
      },
      eventResize: function (event, delta, revertFunc) {
        that.leadservice.GetByTaskHistoryId(event.id, "View", that.currentuser.timezone).then(result => {
          that.taskhistory = result;
          that.taskhistory.estcompleteddate = event.start;
          that.taskhistory.time = event.start.hastime();
          that.taskhistory.contactid = that.taskhistory.contactid;
          that.taskhistory.type = that.taskhistory.type;
          that.taskhistory.userid = that.currentuser.id;
          that.taskhistory.timezone = that.currentuser.timezone;
          this.taskhistory.tasktype = $("#sel1 option:selected").text();
          var moment = require('moment-timezone');
          if (this.taskhistory.time != null) {
            this.taskhistory.starttime = moment(this.taskhistory.estcompleteddate).format("MM/DD/YYYY") + ' ' + this.taskhistory.time;
          }
          if (this.taskhistory.totime != null) {
            this.taskhistory.endtime = moment(this.taskhistory.estcompleteddate).format("MM/DD/YYYY") + ' ' + this.taskhistory.totime;
          }
          that.leadservice.addTaskHistory(that.taskhistory).then(result => {
            this.notes.accountid = result.id;
            this.notes.type = "TaskHistory";
          });
          this.checkvalues(result.status, $("#userids").val());
        });
      },
    //  eventDragStart: function (event, jsEvent, ui, view) {
    //    // that.finaldata=[];
    //    // that.taskhistory.time = event.start.format("hh:mm");
    //    //that.leadservice.GetByTaskHistoryId(event.id).then(result => {
    //    //  that.taskhistory = result;
    //    //  that.taskhistory.estcompleteddate = event.start;
    //    //  that.taskhistory.contactid = that.taskhistory.contactid;
    //    //  that.taskhistory.type = that.taskhistory.type;
    //    //  that.taskhistory.userid = that.currentuser.id;
    //    //  that.finaldata.push({ id: that.taskhistory.id, Contact: that.taskhistory.contactName, title: ((that.taskhistory.time != null && that.taskhistory.time != '0:00') ? that.taskhistory.time : '') + ' ' + that.taskhistory.tasktype, start: that.taskhistory.estcompleteddate, From: that.taskhistory.time, To: that.taskhistory.totime, backgroundColor: 'blue' })
        
    //    //});
    //    that.globaleventid = event.id;
    //  //  $('#myCalendar0').fullCalendar('removeEvents', event._id);
    //  },
    //  eventDragStop: function (event, jsEvent, ui, view) {
    //     
    //    alert("drop");
      
    //    //$('.fc-draggable').each(function () {

    //    //  // store data so the calendar knows to render an event upon drop
    //    //  $(this).data('event', {
    //    //    title: $.trim($(this).text()), // use the element's text as the event title
    //    //    stick: true // maintain when user navigates (see docs on the renderEvent method)
    //    //  });


    //    //  // Dirty fix to remove highlighted blue background
    //    //  $("td").removeClass("fc-highlight");

    //    //});
    //    //that.leadservice.GetByTaskHistoryId(that.globaleventid).then(result => {
    //    //  that.taskhistory = result;
    //    //  that.taskhistory.estcompleteddate = event.start;
    //    //  that.taskhistory.contactid = that.taskhistory.contactid;
    //    //  that.taskhistory.type = that.taskhistory.type;
    //    //  that.taskhistory.userid = that.currentuser.id;
    //    //  //that.finaldata.push({ id: that.taskhistory.id, Contact: that.taskhistory.contactName, title: ((that.taskhistory.time != null && that.taskhistory.time != '0:00') ? that.taskhistory.time : '') + ' ' + that.taskhistory.tasktype, start: that.taskhistory.estcompleteddate, From: that.taskhistory.time, To: that.taskhistory.totime, backgroundColor: 'blue' })
    //    //  //$('#myCalendar1').fullCalendar('renderEvents', that.finaldata, true);
    //    //});

    //},
    };
  }


  ResizeTable() {
    this.aggridheight = ($("#gridheight").height(((window.innerHeight) - (($('#appComponentContent').height()) + ($('#actiondivid').height()))) - 40)) + 'px';
  }
  dateChange(event) {
    this.AssignStartAndEndDate(event);
    this.applicationComponent.startVal = this.startDateVal;
    this.applicationComponent.endVal = this.endDateVal;
    this.checkvalues($('#taskstatus').val(), $("#userids").val());
  }
  AssignStartAndEndDate(event) {

    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }

}
