<input type="text" id="emailIds" style="display:none;" />
<div class="container">
  <div class="row" style="display:flex;flex:auto;padding:0;margin:0.5% 0 0 0;" id="actiondivid">
    <div class="col-md-4 col-sm-6 col-xs-12" style="padding:  0;">
      <div class="col-md-2 col-sm-1 col-xs-3" style="padding:  0;">
        <label style="padding-top:5px">User List</label>
      </div>
      <div class="col-md-10 col-sm-11 col-xs-9" style="padding: 0%;padding-right: 1.5%;">
        <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                              [settings]="dropdownSetting"
                              (onSelect)="onItemSelect($event)"
                              (onDeSelect)="OnItemDeSelect($event)"
                              (onSelectAll)="onSelectAll($event)"
                              (onDeSelectAll)="onDeSelectAll($event)">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12 col-md-offset-4 col-sm-offset-6 col-xs-offset-0" style="padding: 0;">
      <div class="col-xs-12 col-md-3 col-sm-12 col-md-offset-3" style="padding: 0;">
        <my-date-picker id="startDate" name="startDate" [options]="startDatePickerOptions" [placeholder]="startDatePlaceHolder" (dateChanged)="dateChange($event)" style="border-left:none !important" required></my-date-picker>
      </div>
      <div class="col-xs-12 col-md-3 col-sm-12" style="padding: 0;    padding-left: 0.3%; padding-right: 0;">
        <my-date-picker name="endDate" [options]="endDatePickerOptions" [placeholder]="endDatePlaceHolder" (dateChanged)="dateChange($event)" style="border-left:none !important" required></my-date-picker>
      </div>

      <div class="col-md-3 col-sm-12 col-xs-12" style="padding: 0;padding-left: 3%;">
        <label class="col-sm-2 control-label" style="padding:0;padding-left:2%; margin-top: 5px;">Size</label>
        <div class="col-sm-10" style="padding-right: 0%;">
          <input class="form-control" type="text" style="height: 29px;" name="Page Size" [(ngModel)]="pageSize" (change)="txtPageSizeChanged($event.target.value)">
        </div>
      </div>
    </div>
  </div>
  <ag-grid-angular [style.height.px]="aggridheight" style="width: 100%;min-height:550px; padding-top: 10px;" id="gridheight" class="ag-theme-balham"
                   [gridOptions]="gridOptions"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="allCustomers"
                   [context]="context"
                   [frameworkComponents]="frameworkComponents"
                   (rowSelected)="rowClickAgGrid($event)"
                   (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                   (columnVisible)="columnVisible($event)"
                   (dragStopped)="dragStopped($event)"
                   (columnResized)="columnResized($event)"
                   (gridReady)="onAgGridReady($event)">
  </ag-grid-angular>

  <app-dialog [(visible)]="multiplelocationshowDialog">
    <div class="col-xs-12 provider" style="text-align:left;">
      <div class="col-xs-4" style="text-align:left">
        <div class="providerLabel">{{account.address1}}&nbsp;</div>
        <div>{{account.address2}}</div>
        <div>
          <span>{{account.city}} , {{account.state}},  {{account.zip | slice:0:5}}<span *ngIf="account.zip && account.zip.length > 5">-{{account.zip | slice:5:20}}</span>, {{account.country}}</span>
         
        </div>
      </div>
      <div class="col-xs-4">
        <div><b class="providerLabel" style="font-size:17px;">{{account.accountname}}</b></div>
      </div>
      <div class="col-xs-4" style="text-align:right">
        <div style="margin-top: 6%;"><button class="pull-right" (click)="NewLocation()">Add New Location</button></div>
      </div>
    </div>
    <div class="col-xs-12" style="width: 1000px;">

    </div>
    <div class="row" id="alllocations" style="margin-top: 2%;margin-bottom: 2%;padding-left: 1%;padding-right: 1%;padding-top:1%;max-height:500px;" [hidden]="alllocations">
      <div *ngIf="accountlocationlist == 0">
        <span class="search_no_results">
          No data found
        </span>
      </div>
      <div *ngIf="accountlocationlist !=0">
        <div id="location{{localtion.id}}" class="col-xs-4" *ngFor="let localtion of accountlocationlist" style="padding:0;">
          <div style="padding: 3%;">
            <div class="col-xs-12" style="background: darkkhaki;">
              <h5 class="pull-left">Location</h5><span class="pull-right fa fa-times" (click)="Close(localtion.id)" style="color:green;cursor: pointer;margin-top: 3%;"></span>
            </div>
            <div class="col-xs-12" style="border-left: 1px solid #ddd;border-right: 1px solid #ddd;">
              Address1:{{localtion.address1}}
            </div>
            <div class="col-xs-12" style="border-left: 1px solid #ddd;border-right: 1px solid #ddd;">
              Address2:{{localtion.address2}}
            </div>
            <div class="col-xs-12" style="border-left: 1px solid #ddd;border-right: 1px solid #ddd;">
              city/state/zip:{{localtion.city}},{{localtion.state}} {{localtion.zip}}
            </div>
            <div class="col-xs-12" style="border-left: 1px solid #ddd;border-right: 1px solid #ddd;">
              phone:{{localtion.phone}}
            </div>
            <div class="col-xs-12" style="border-left: 1px solid #ddd;border-right: 1px solid #ddd;border-bottom:1px solid #ddd; border-radius:3px">
              fax:{{localtion.fax}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="addnewlocation" [hidden]="addnewlocation">
      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
        <div class="col-xs-2">
          <label>Address1/Address2 </label>
        </div>
        <div class="col-xs-5">
          <input type="text" class="form-control" name="address10" [(ngModel)]="accountlocations.address1" placeholder="Enter Address" id=address1_0 />
        </div>
        <div class="col-xs-5">
          <input type="text" class="form-control" name="address20" [(ngModel)]="accountlocations.address2" placeholder="Enter Address" id=address2_0 />
        </div>
      </div>
      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
        <div class="col-xs-2">
          <label>City/State/Zip </label>
        </div>
        <div class="col-xs-10">
          <div class="col-xs-6 removeCompletePadding" style="padding:0;">
            <input type="text" class="form-control" name="city0" [(ngModel)]="accountlocations.city" id="city_0" placeholder="Enter City" />
          </div>
          <div class="col-xs-3" style="padding-right:0">
            <input type="text" class="form-control" name="state0" [(ngModel)]="accountlocations.state" id="state_0" placeholder="Enter State" />
          </div>
          <div class="col-xs-3" style="padding-right:0">
            <input type="text" class="form-control" name="zip0" [(ngModel)]="accountlocations.zip" id="zip_0" placeholder="Enter Zip" [textMask]="{mask: zipmask}" />
          </div>
        </div>
      </div>
      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
        <div class="col-xs-2">
          <label>Phone/Fax#</label>
        </div>
        <div class="col-xs-10">
          <div class="col-xs-6 removeCompletePadding" style="padding:0">
            <input type="text" class="form-control" name="phone0" [(ngModel)]="accountlocations.phone" placeholder="Enter Phone#" [textMask]="{mask: phonemask}" id="phone_0" />
          </div>
          <div class="col-xs-6" style="padding-right:0">
            <input type="text" class="form-control" name="fax0" [(ngModel)]="accountlocations.fax" placeholder="Enter Fax#" [textMask]="{mask: phonemask}" id="fax_0" />
          </div>
        </div>
      </div>
      <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;border-bottom:1px solid #000; padding-bottom:1%">
        <div class="col-xs-2">
          <label>Alter Phone#</label>
        </div>
        <div class="col-xs-10">
          <div class="col-xs-6 removeCompletePadding" style="padding:0">
            <input type="text" class="form-control" name="alterphone10" [(ngModel)]="accountlocations.alterphone1" placeholder="Enter Alternate 1 Phone#" [textMask]="{mask: phonemask}" id="alterPhone1_0" />
          </div>
          <div class="col-xs-6" style="padding-right:0">
            <input type="text" class="form-control" name="alterphone20" [(ngModel)]="accountlocations.alterphone2" placeholder="Enter Alternate 2 Phone#" [textMask]="{mask: phonemask}" id="alterPhone2_0" />
          </div>
        </div>
      </div>
      <div class="col-xs-12" id="saveLocation" style="margin-top:1%">
        <div class="col-xs-1" style="padding:0;">
          <button class="btn cancel" (click)="Cancel()" type="submit" style="padding: 9.75%;border: 1px solid;width: 100%;">Cancel</button>
        </div>
        <div class="col-xs-11" style="padding:0;">
          <button class="btn provider" (click)="SaveLocation()" type="submit" style="margin-left: 1%;padding: 0.75%;width: 100%;">Save</button>
        </div>

      </div>
    </div>
  </app-dialog>

  <input type="text" id="accotIdNotes" style="display:none;" />
  <input type="text" id="accotTypeNotes" style="display:none;" />
  <app-dialog [(visible)]="notesshowDialog">
    <app-notes [title]="'Customer Notes Info'"
               [source]="'Customer'"
               [account]="account"
               [gridOptionsnotes]="gridOptionsnotes"
               [allnotes]="allnotes"
               [context]="context"
               [columnDefsnotes]="columnDefsnotes"
               [contact]="account"
               [tabName]="'Contact'"
               [conid]="conid"
               [typ]="'Customer'">
    </app-notes>
  </app-dialog>
  <div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
    <textarea *ngIf="isShowDetailsChecked" id="txtUserFullName" readonly rows="3" name="userInfo" [(ngModel)]="userInfo" style="width:100%; border: none;"></textarea>

    <div class="col-xs-12" style="padding:0;">
      <button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog" style="float:left;position:unset; "><i class="fa fa-window-close-o"></i></button>
    </div>

    <div class="box" style="height:98%;border:none">
      <span *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" class="editnote">Edit</span>
      <span *ngIf="showEditNoteBtn" (click)="saveEditedNote()" class="save">Save</span>
      <div id="txtLongNoteDesc" class="text">
      </div>
    </div>
    <!--<div style="background-color: rgb(244,244,244); height: 100%; width: 100%;">
    <textarea *ngIf="isShowDetailsChecked" id="txtUserFullName" readonly rows="3" name="userInfo" [(ngModel)]="userInfo" style="width:100%; border: none;"></textarea>
    <div class="box" id="txtLongNoteDesc" style="width:100%; border: none;background-color: #ddd;"></div>
    <button *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" style="width: 100%; align-self: flex-end"><b>Edit Note</b></button>
  </div>-->
    <!--<button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog"><i class="fa fa-window-close-o"></i></button>-->
  </div>
  </div>


