export class AccountsHistory {
  public id: number;
  public accountid: number;
  public customerSince: string;
  public SoldBy: number;
  public LeadFromId: number;
  public KeyContact1: number;
  public KeyContact2: number;
  public KeyContact3: number;
  public LastTicketDate: string;
  public LastContactDate: string;
  public HistoryNotes: string;
  public KeyContact1Name: string;
  public KeyContact2Name: string;
  public KeyContact3Name: string;
  public LeadFrom: string;
  public StatisticsNotes: string;
  public customerStatisticnotes: string;
  public Salesrep: number;
  public HistoryViewed: string;
}
