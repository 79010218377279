export class OpportunityLeadTypes {
  public LeadTypeId: number;
  public LeadTypeName: string;
  public Sortorder: number;
  public UserID: number;
  public DeletedStatus: number;

}
export class OpportunityFields {
  public OppFieldId: number;
  public FieldName: string;
  public ColumnName: string;
  public DisplayAs: string;
  public RequiredField: string;
  public DataType: string;
  public Editable: string;
  public SortOrder: number;
  public UserId: number;
  public CreatedDate: string;
  public isdeleted: number;
}

export class OpportunityItems {
  public id: number;
  public opportunityitem: string;
  public Category: string;
  public OppFieldID: number;
  public SortOrder: number;
  public isdeleted: number;
}
