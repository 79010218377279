import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Optional } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { Accounts } from '../../../models/accounts';
import { Contacts } from '../../../models/contacts';
import { SalesProcessHistory } from '../../../models/sales-process-history';
import { LeadsService } from '../../../services/leads.service';
import { OpprtunitiesService } from '../../../services/opprtunities.service';
import { ApplicationComponent } from '../../application.component';
import { NotesrendererComponent } from '../../notesrenderer/notesrenderer.component';
declare var $: any;
import * as moment from 'moment'
import { Opportunities } from '../../../models/opportunities';
import { ToastrService } from 'ngx-toastr';
import { TaskHistory } from '../../../models/task-history';
import { EditdeletecvComponent } from '../../editdeletecv/editdeletecv.component';
import { CustomerService } from '../../../services/customer.service';
import { Dropdown } from '../../../models/dropdowntype';
import { Tickets } from '../../../models/tickets';
import { UserService } from '../../../services/user.service';
import { ProposalsModel } from '../../../models/Proposalsmodel';
import { Router } from '@angular/router';
import { Auditlog } from '../../../models/auditlog';

@Component({
  selector: 'app-other-standard-reports',
  templateUrl: './other-standard-reports.component.html',
  styleUrls: ['./other-standard-reports.component.css']
})
export class OtherStandardReportsComponent implements OnInit {
  @Input()
  SelectedReportType: string;
  @Input()
  SelectedCategory: string;
  @Input()
  StartDate: string;
  @Input()
  EndDate: string;
  currentuser: any;
  public sideBar;
  public context;
  public frameworkComponents;
  datePipe: DatePipe = new DatePipe("en-US");
  today = Date.now();

  public AccountframeworkComponents;
  public AccountGridOptions: GridOptions;
  public AccountcolumnDefs: any[];
  allCustomers: Accounts[];


  public ContactGridOptions: GridOptions;
  public ContactColumnDefs: any[];
  allNewContact: Contacts[];

  public LeadgridOptions: GridOptions;
  public LeadcolumnDefs: any[];
  allLeads: SalesProcessHistory[];

  public OpportunitiesgridOptions: GridOptions;
  public OpportunitiescolumnDefs: any[];
  Allopportunities: Opportunities[];

  public TaskGridOptions: GridOptions;
  public TaskcolumnDefs: any[];
  columnSelections: string;
  alltaskhistory: TaskHistory[];
  tasktype: string;

  public TicketGridOptions: GridOptions;
  public TicketColumnDefs: any[];
  ticketStatusList: Array<Dropdown> = [];
  Userlist: Array<Tickets> = [];
  ticketType: string = "Open Tickets";
  displayCC = false;
  assignedTo: string = null;
  Filter: string = "All Tasks Groups and Users";
  sortOrder: string = "None";

  public ProposalGridOptions: GridOptions;
  public ProposalColumnDefs: any[];
  AllProposals: ProposalsModel[];

  public AccessGridOptions: GridOptions;
  public AccessColumnDefs: any[];
  loginauditloglist: Array<Auditlog> = [];

  constructor(private router: Router,public applicationComponent: ApplicationComponent, private opprtunityservice: OpprtunitiesService, private leadservice: LeadsService, private toastr: ToastrService, private customerService: CustomerService, private userService: UserService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.SelectedReportType = "";
    //this.getOtherSelectedReportData(this.SelectedReportType, this.SelectedCategory, this.StartDate, this.EndDate);
  }

  getOtherSelectedReportData(SelectedReportType, SelectedCategory, StartDate, EndDate) {
    if (SelectedReportType == 'Accounts') {
      this.AccountIntializeAgGrid();
      this.loadallcustomers('All', 'All');
    }
    else if (SelectedReportType == 'Contacts') {
      this.NewContactIntializeAgGrid();
      let self = this;
      $(setTimeout(function () {
        self.loadallnewcontacts($("#userids").val(), StartDate, EndDate);
      }, 100));
    }
    else if (SelectedReportType == 'Leads') {
      this.LeadIntializeAgGrid();
     if (SelectedCategory == 'Leads Added') {
        this.LoadallLeadConverted($("#userids").val(), 'All Leads');
      } else if (SelectedCategory == 'Leads Lost' || SelectedCategory == 'Leads Won' || SelectedCategory == 'Leads Pended') {
        this.LoadallLeadConverted($("#userids").val(), 'Closed Leads');
      }

    }
    else if (SelectedReportType == 'Opportunities') {
      if (SelectedCategory == 'Added Opportunities') {
        this.OpprtunitiesIntializeAgGrid();
        this.ClosedOpportunity('Open Opportunities');
      } else {
        this.OpprtunitiesIntializeAgGrid();
        this.ClosedOpportunity(SelectedCategory);
      }

    }
    else if (SelectedReportType == 'Tasks') {
      if (SelectedCategory == 'Tasks Added') {
        this.initializeAgGrid();
        this.TaskData('Open Tasks', $("#userids").val(), null);
      }
      else {
        this.initializeAgGrid();
        this.TaskData('Completed Tasks', $("#userids").val(), null);
      }
    }
    else if (SelectedReportType == 'Tickets') {
    
      if (SelectedCategory == 'Tickets Added') {
        this.GetAllTicketStatus();
        this.assignedTo = null;
        this.ticketType = 'Open Tickets';
        this.initializeTicketsAgGrid();
        this.GetAllZendeskUers(this.Filter, this.sortOrder);
      } else if (SelectedCategory == 'Tickets Closed') {
        this.GetAllTicketStatus();
        this.assignedTo = null;
        this.ticketType = 'Closed Tickets';
        this.initializeTicketsAgGrid();
        this.GetAllZendeskUers(this.Filter, this.sortOrder);
      } else if (SelectedCategory == 'Tickets On-Hold') {
        this.GetAllTicketStatus();
        this.assignedTo = null;
        this.ticketType = 'On-Hold Tickets';
        this.initializeTicketsAgGrid();
        this.GetAllZendeskUers(this.Filter, this.sortOrder);
      } else if (SelectedCategory == 'Tickets Pended') {
        this.GetAllTicketStatus();
        this.assignedTo = null;
        this.ticketType = 'Pended Tickets';
        this.initializeTicketsAgGrid();
        this.GetAllZendeskUers(this.Filter, this.sortOrder);
      }
      else if (SelectedCategory == 'Tickets Open') {
        this.GetAllTicketStatus();
        this.assignedTo = null;
        this.ticketType = 'Open Tickets';
        this.initializeTicketsAgGrid();
        this.GetAllZendeskUers(this.Filter, this.sortOrder);
      }
    }
    else if (SelectedReportType == 'Proposals') {
      this.initializeProposalAgGrid();     
      if (this.SelectedCategory == 'Proposals Added') {
        this.GetAllProposal($("#userids").val())
      } else if (this.SelectedCategory == 'Proposals Pended') {
        this.getPendedProposals($("#userids").val());
      }
    } else if (SelectedReportType == 'Access(Login/Logout)' ) {
      this.OpportunitieshistoryinitializeAgGrid();
    }
  }
  getPendedProposals(userid) {
    this.userService.GetAllPendedProposals(userid, 1).then(result => {
      this.AllProposals = result.filter(x => (moment(x.CreatedDate).format('YYYY-MM-DD') > this.StartDate));;
    });
  }

  AccountIntializeAgGrid() {

    this.AccountGridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 41).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createAccountColumnDefs(columnSelections);

    });

    this.AccountGridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.AccountframeworkComponents = {
      notesrenderer: NotesrendererComponent
    };
  }
  loadallcustomers(type, term) {
    var userids = $("#userids").val();
    this.opprtunityservice.GetAllAccountsBySearch(this.currentuser.id, "AccountAdded", userids, "AccountAdded", this.StartDate, this.EndDate, 1, 100, '0,1,2', 0).then((account) => {
     
      this.allCustomers = account;
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.AccountGridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.allCustomers[0].id) {
            selfObj.AccountGridOptions.api.selectNode(node, true);
            selfObj.AccountGridOptions.api.paginationSetPageSize(Number(selfObj.allCustomers.length));
          }
        });
      }, 500));
     
    });
  }
  createAccountColumnDefs(columnSelections: string) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.AccountcolumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.AccountcolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "accnumber") {
          this.AccountcolumnDefs.push({ field: 'accnumber', headerName: 'Act Number', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: { color: 'red' } });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.AccountcolumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else {
          this.AccountcolumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.AccountcolumnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false, outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'accnumber', headerName: 'Act Number', hide: false, cellStyle: { color: 'red' } },
        { field: 'accountname', headerName: 'Account Name', hide: false },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        { field: 'address1', headerName: 'Address1', hide: false },
        { field: 'address2', headerName: 'Address2', hide: false },
        { field: 'city', headerName: 'City', hide: false },
        { field: 'state', headerName: 'State', hide: false },
        { field: 'zip', headerName: 'Zip', hide: false },
        { field: 'phone', headerName: 'Phone', hide: true }, //true starts here
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false }
      ];
    }
  }

  NewContactIntializeAgGrid() {
    this.ContactGridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 42).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createNewContactcolumnDefs(columnSelections);

    });

    this.ContactGridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };

  }
  createNewContactcolumnDefs(columnSelections: string) {

    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.ContactColumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.ContactColumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "callliststatus") {
          this.ContactColumnDefs.push({
            field: 'callliststatus', headerName: 'Call List Hx', cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:50px; background:white">' + params.value + '</button>';
              var eButton = eDiv.querySelectorAll('.btn-simple')[0];
              //eButton.addEventListener('click', function () {
              //  selfObj.callhistorystatus = params.data.callliststatus;
              //  selfObj.contactid = params.data.id;
              //  selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
              //  selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
              //});
              return eDiv;
            }, hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.ContactColumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else {
          this.ContactColumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.ContactColumnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        {
          field: 'lastname', headerName: 'Last Name', hide: false
        },
        { field: 'firstname', headerName: 'First Name', hide: false },
        { field: 'middlename', headerName: 'Middle Name', hide: false },
        { field: 'titleName', headerName: 'Title', hide: false },
        { field: 'specialityName', headerName: 'Specialty', hide: false },
        { field: 'DOB', headerName: 'DOB', hide: false },
        {
          field: 'callliststatus', headerName: 'Call List Hx', hide: false, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:50px; background:white">' + params.value + '</button>';
            var eButton = eDiv.querySelectorAll('.btn-simple')[0];
            eButton.addEventListener('click', function () {
              //selfObj.callhistorystatus = params.data.callliststatus;
              //selfObj.contactid = params.data.id;
              //selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
              //selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
            });
            return eDiv;
          }
        },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },
        { field: 'zip', headerName: 'ZIP', hide: false },
        { field: 'accountname', headerName: 'Account Name', hide: false },
        { field: 'contacttypeName', headerName: 'Contact Type', hide: true },  //true starts here
        { field: 'suffix', headerName: 'Suffix', hide: true },
        { field: 'sex', headerName: 'Sex', hide: true },
        { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
        { field: 'email', headerName: 'Email', hide: true },
        { field: 'address1', headerName: 'Address1', hide: true },
        { field: 'address2', headerName: 'Address2', hide: true },
        { field: 'city', headerName: 'City', hide: true },
        { field: 'state', headerName: 'State', hide: true },
        { field: 'cell', headerName: 'Cell', hide: true },
        { field: 'office', headerName: 'Office', hide: true },
        { field: 'homeaddress', headerName: 'Home Address', hide: true },
        { field: 'homecity', headerName: 'Home City', hide: true },
        { field: 'homestate', headerName: 'Home State', hide: true },
        { field: 'homezip', headerName: 'Home Zip', hide: true },
        { field: 'home', headerName: 'Home', hide: true },
        { field: 'college', headerName: 'College', hide: true },
        { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
        { field: 'medschool', headerName: 'Medschool', hide: true },
        { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
        { field: 'residency', headerName: 'Residency', hide: true },
        { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
        { field: 'fellowship', headerName: 'Fellowship', hide: true },
        { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
        { field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true },
        { field: 'created', headerName: 'Created', hide: true }
      ];
    }
  }
  public loadallnewcontacts(userlist, startdate, enddate) {
    
    this.opprtunityservice.GetAllContactsBySearch('null', userlist, 'contacts', 0, startdate, enddate).then((contact) => {
      contact.forEach(contact => {
        contact.created = this.datePipe.transform(contact.created, "MM-dd-yyyy");
        contact.speciality = ((contact.speciality == 'null') ? "" : contact.speciality);
      });
      this.allNewContact = contact;
      
      //this.contactorcustomerid = this.allNewContact[0].id;
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.ContactGridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.allNewContact[0].id) {
            selfObj.ContactGridOptions.api.selectNode(node, true);
            //selfObj.contactorcustomerid = selfObj.allNewContact[0].id;


          }
        });
      }, 500));

      //this.gridCount = this.allNewContact.length;
    });
  }


  LeadIntializeAgGrid() {
    this.LeadgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 43).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createLeadColumnDefs(columnSelections);
    });
    this.LeadgridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };

  }
  createLeadColumnDefs(columnSelections: string) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.LeadcolumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.LeadcolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "salesstage") {
          this.LeadcolumnDefs.push({
            field: 'salesstage', headerName: 'Sales Stage', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if (params.data.status == 'Open' || params.data.status == 'Pending') { return { color: 'red' }; }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "firstname") {
          this.LeadcolumnDefs.push({
            field: 'firstname', headerName: 'First Name', hide: false
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "lastname") {
          this.LeadcolumnDefs.push({
            field: 'lastname', headerName: 'Last Name', hide: false
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "account") {
          this.LeadcolumnDefs.push({
            field: 'account', headerName: 'Account Name', hide: false
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "nexttask") {
          this.LeadcolumnDefs.push({
            field: 'nexttask', headerName: 'Next Sales Step', hide: false, cellRenderer(params) {
              if (params.data.salesstage === 'IPC') { return 'Analysis' }
              else if (params.data.salesstage === 'Analysis') { return 'Prepare Analysis Report' }
              else if (params.data.salesstage === 'Prepare Analysis Report') { return 'Review Analysis' }
              else if (params.data.salesstage === 'Review Analysis') { return 'Generate Proposal' }
              else if (params.data.salesstage === 'Generate Proposal') { return 'Justification Meeting' }
              else if (params.data.salesstage === 'Justification Meeting') { return 'Reference Calls' }
              else if (params.data.salesstage === 'Reference Calls') { return 'Demonstration' }
              else if (params.data.salesstage === 'Demonstration') { return 'Won-Lost-Pending' }
            }
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.LeadcolumnDefs.push({
            field: 'status', headerName: 'Status', hide: false, cellStyle:
              function (params) {
                if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; }
              }, cellRenderer(params) {
                if (params.data.salesstage == 'MD Logic Demonstration' && params.data.status == 'Next Stage Pending') { return ' ' } else { return params.value; }
              }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.LeadcolumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.LeadcolumnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.LeadcolumnDefs.push({ field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellStyle: function (params) { if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; } } });
        }
        else {
          this.LeadcolumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {

      this.LeadcolumnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        { field: 'datecreated', headerName: 'Date Created', hide: false },
        {
          field: 'lastname', headerName: 'Last Name', hide: false
        },
        { field: 'middlename', headerName: 'Middle Name', hide: false },
        {
          field: 'firstname', headerName: 'First Name', hide: false
        },
        { field: 'titleName', headerName: 'Title', hide: false },
        {
          field: 'account', headerName: 'Account Name', hide: false
        },
        {
          field: 'salesstage', headerName: 'Sales Stage', hide: false, cellStyle: function (params) {
            if (params.data.status == 'Open' || params.data.status == 'Pending') { return { color: 'red' }; }
          }
        },
        {

          field: 'status', headerName: 'Status', hide: false, cellStyle:
            function (params) {
              if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; }
            }, cellRenderer(params) {
              if (params.data.salesstage == 'MD Logic Demonstration' && params.data.status == 'Next Stage Pending') { return ' ' } else { return params.value; }
            }

        },
        {
          field: 'nexttask', headerName: 'Next Sales Step', hide: false, cellRenderer(params) {
            if (params.data.salesstage == 'IPC') { return 'Analysis' }
            else if (params.data.salesstage == 'Analysis') { return 'Prepare Analysis Report' }
            else if (params.data.salesstage == 'Prepare Analysis Report') { return 'Review Analysis' }
            else if (params.data.salesstage == 'Review Analysis') { return 'Generate Proposal' }
            else if (params.data.salesstage == 'Generate Proposal') { return 'Justification Meeting' }
            else if (params.data.salesstage == 'Justification Meeting') { return 'Reference Calls' }
            else if (params.data.salesstage == 'Reference Calls') { return 'Demonstration' }
            else if (params.data.salesstage == 'Demonstration') { return 'Won-Lost-Pending' }
          }
        },
        { field: 'datescheduled', headerName: 'Date Schedule', hide: true },  //true starts here
        { field: 'owner', headerName: 'Owner', hide: false },
        { field: 'completeduser', headerName: 'Completed By', hide: false },
        { field: 'dealwonOrlost', headerName: 'Deal Won/Lost', hide: true },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, pinned: 'right' }

      ];

      var tooltipRenderer = function (params) {
        return '<span title="' + params.value + '">' + params.value + '</span>';
      };
    }
  }
  LoadallLeadConverted(userid,type) {   
    var userids = userid;
    this.leadservice.GetAllLeadsList(type, userids, this.StartDate, this.EndDate, 'null', 1, 10, this.currentuser.id).then((customer) => {
     
      if (this.SelectedCategory == 'Leads Lost') {
        this.allLeads = customer.filter(x => x.status == 'Lost');
      } else if (this.SelectedCategory == 'Leads Won') {
        this.allLeads = customer.filter(x => x.status == 'Won');
      } else if (this.SelectedCategory == 'Leads Pended') {
        this.allLeads = customer.filter(x => x.status == 'Pending');
      } else {
        this.allLeads = customer;
      }
    });


  }

  OpprtunitiesIntializeAgGrid() {
    this.OpportunitiesgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 44).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createOpportunitiesColumnDefs(columnSelections);

    });
    this.OpportunitiesgridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };

  }
  createOpportunitiesColumnDefs(columnSelections: string) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.OpportunitiescolumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.OpportunitiescolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.OpportunitiescolumnDefs.push({
            field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'MM-dd-yyyy') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "datecreated") {
          this.OpportunitiescolumnDefs.push({
            field: 'datecreated', headerName: 'Date Created', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "estimatedclosedate") {
          this.OpportunitiescolumnDefs.push({
            field: 'estimatedclosedate', headerName: 'Date Scheduled', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "callliststatus") {
          this.OpportunitiescolumnDefs.push({
            field: 'callliststatus', headerName: 'Call List Hx', cellRenderer(params) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:60px; background:white">' + params.value + '</button>';
              var eButton = eDiv.querySelectorAll('.btn-simple')[0];
              eButton.addEventListener('click', function () {
                //selfObj.callhistorystatus = params.data.callliststatus;
                //selfObj.contactid = params.data.accountorcontactid;
                //selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
                //selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
              });
              return eDiv;
            }, hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.OpportunitiescolumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else {
          this.OpportunitiescolumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.OpportunitiescolumnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false
        },
        {
          field: 'accountid', headerName: 'accountid ', hide: true
        },
        {
          field: 'datecreated', headerName: 'Date Created ', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'estimatedclosedate', headerName: 'Date Scheduled', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'status', headerName: 'Status', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'practicename', headerName: 'Account Name', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'contactName', headerName: 'Contact Name', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'itemName', headerName: 'Item', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'dollers', headerName: '$$$$', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'RR', headerName: 'RR', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'PCname', headerName: 'PC', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'drsName', headerName: 'Drs', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },  //true starts here
        {
          field: 'midlevel', headerName: 'Mid Levels', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: 'red' }; }
          }
        },
        {
          field: 'leadfrom', headerName: 'Lead From', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'owner', headerName: 'Owner', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'actualclosedate', headerName: 'Act.Close', hide: true, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfObj.datePipe.transform(selfObj.today, 'yyyy-MM-dd') || selfObj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfObj.datePipe.transform(selfObj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'callliststatus', headerName: 'Call List Hx', hide: false, cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<button class="btn-simple btn-sm" style="border:1px solid #ddd;width:60px; background:white">' + params.value + '</button>';
            var eButton = eDiv.querySelectorAll('.btn-simple')[0];
            eButton.addEventListener('click', function () {
              //selfObj.callhistorystatus = params.data.callliststatus;
              //selfObj.contactid = params.data.accountorcontactid;
              //selfObj.callhistorycmp.displayCallHistoryNotes(selfObj.contactid, params.data.callliststatus);
              //selfObj.CallHistoryDialog = !selfObj.CallHistoryDialog;
            });
            return eDiv;
          }
        },

        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, pinned: 'right' },
      ];
    }
  }
  ClosedOpportunity(val) {
    this.opprtunityservice.GetAllOpportunitiesList(val, $("#userids").val(), this.StartDate, this.EndDate, null, this.currentuser.id,true).then((customer) => {
      customer.forEach(customer => {
        customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
        if (customer.estimatedclosedate != '0001-01-01T00:00:00') {
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
        } else {
          customer.estimatedclosedate = '';
        }
      });
      //if (this.SelectedCategory == 'Lost') {
      //  this.Allopportunities = customer.filter(x => x.status == '110')
      //} else if (this.SelectedCategory == 'Won') {
      //  this.Allopportunities = customer.filter(x => x.status == '109')
      //} else if (this.SelectedCategory == 'Pended') {
      //  this.Allopportunities = customer.filter(x => x.status == '111')
      //} else {
        this.Allopportunities = customer;
      //}
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.OpportunitiesgridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.Allopportunities[0].id) {
            selfObj.OpportunitiesgridOptions.api.selectNode(node, true);
          }
        });
      }, 500));
    });
  };

  initializeAgGrid() {

    this.TaskGridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single' 
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 9).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        //this.TaskGridOptions.api.paginationSetPageSize(config.maxlimit);
        
      }
      this.columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(this.columnSelections);
      
    });
    this.TaskGridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };

    
  }
  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    let searchval = this.applicationComponent.searchstring;
    let selfobj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.TaskcolumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.TaskcolumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.TaskcolumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.TaskcolumnDefs.push({ field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) { if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; } } });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "datecompleted") {
          this.TaskcolumnDefs.push({
            field: 'datecompleted', headerName: 'Date Completed', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              if (params.data.status == 'Open' || params.value == "") { return ""; } else {
                return moment(params.value).format('MM-DD-YYYY');
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "datecreated") {
          this.TaskcolumnDefs.push({
            field: 'datecreated', headerName: 'Date Created', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "estcompleteddate" || fieldHeaderVisibleWidthArray[0] == "DateSched") {
          this.TaskcolumnDefs.push({
            field: 'estcompleteddate', headerName: 'Date Scheduled', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "accountname") {
          this.TaskcolumnDefs.push({
            field: 'accountname', headerName: 'Account Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            cellRenderer: function (params) {
              //if (searchval != null) {
              if (params.data.SearchVal != null) {
                let self = this;
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  //var search = searchval.replace(/["]/g, "")
                  var search = params.data.SearchVal
                  if (x[i] == search || (x[i].toLowerCase() == search.toLowerCase())) {
                    st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "firstName") {
          this.TaskcolumnDefs.push({
            field: 'firstName', headerName: 'First Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            cellRenderer: function (params) {
              if (searchval != null) {
                let self = this;
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = searchval.replace(/["]/g, "")
                  if (x[i] == search || (x[i].toLowerCase() == search.toLowerCase())) {
                    st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "lastName") {
          this.TaskcolumnDefs.push({
            field: 'lastName', headerName: 'Last Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            cellRenderer: function (params) {
              //if (searchval != null) {
              if (params.data.SearchVal != null) {
                let self = this;
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  //var search = searchval.replace(/["]/g, "")
                  var search = params.data.SearchVal;
                  if (x[i] == search || (x[i].toLowerCase() == search.toLowerCase())) {
                    st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "time") {
          this.TaskcolumnDefs.push({
            field: 'starttime', headerName: 'Start Time', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            cellRenderer: function (params) {
              if (params.value == '00:00:00' || params.value == null) { return "" } else {
                return params.value;
              }
            }
          });

        }
        else if (fieldHeaderVisibleWidthArray[0] == "totime") {
          this.TaskcolumnDefs.push({
            field: 'endtime', headerName: 'End Time', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
            },
            cellRenderer: function (params) {
              if (params.value == '00:00:00' || params.value == null) { return "" } else {
                return params.value;
              }
            }
          });

        }
        else {
          this.TaskcolumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            cellStyle: function (params) {
              if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
                return { color: '#FF0000' }
              }
            },
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.TaskcolumnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        {
          field: 'datecreated', headerName: 'Date Created', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'estcompleteddate', headerName: 'Date Scheduled', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'status', headerName: 'Status', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
          }
        },
        {
          field: 'datecompleted', headerName: 'Date Completed', hide: (this.tasktype == 'Open') ? true : false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') { return { color: '#FF0000' }; }
          }, cellRenderer: function (params) {
            if (params.data.status == 'Open' || params.value == "") { return ""; } else {
              return moment(params.value).format('MM-DD-YYYY');
            }
          }
        },
        {
          field: 'accountname', headerName: 'Account Name', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          },
          cellRenderer: function (params) {
            //if (searchval != null) {
            if (params.data.SearchVal != null) {
              let self = this;
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                //var search = searchval.replace(/["]/g, "")
                var search = params.data.SearchVal
                if (x[i] == search || (x[i].toLowerCase() == search.toLowerCase())) {
                  st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'taskcategory', headerName: 'Task', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },

        {
          field: 'tasktype', headerName: 'Type', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },
        //{
        //  field: 'contactName', headerName: 'Contact Name', hide: false, cellStyle: function (params) {
        //    if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'MM-dd-yyyy') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
        //      return { color: 'red' }
        //    }
        //  }
        //},
        {
          field: 'firstName', headerName: 'First Name', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          },
          cellRenderer: function (params) {
            //if (searchval != null) {
            if (params.data.SearchVal != null) {
              let self = this;
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                //var search = searchval.replace(/["]/g, "")
                var search = params.data.SearchVal;
                if (x[i] == search || (x[i].toLowerCase() == search.toLowerCase())) {
                  st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'lastName', headerName: 'Last Name', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == 'Open' || params.data.status == 'Pending')) { return { color: '#FF0000' }; }
          },
          cellRenderer: function (params) {
            //if (searchval != null) {
            if (params.data.SearchVal != null) {
              let self = this;
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                //var search = searchval.replace(/["]/g, "")
                var search = params.data.SearchVal
                if (x[i] == search || (x[i].toLowerCase() == search.toLowerCase())) {
                  st += "&nbsp;<label style='color:#FF0000'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'starttime', headerName: 'Start Time', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
          //}, cellRenderer: function (params) {
          //  return moment(params.value).format('MM-DD-YYYY');

          //}
        },
        {
          field: 'endtime', headerName: 'End Time', hide: false, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        }, //true starts here

        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, pinned: 'right' },
        {
          field: 'owner', headerName: 'User', hide: true, cellStyle: function (params) {
            if ((params.data.estcompleteddate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estcompleteddate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && params.data.status != 'Completed') {
              return { color: '#FF0000' }
            }
          }
        },

      ];

    }
  }
  TaskData(val, userids, searchval) {
    var submenuID = '';
    if (val == 'Open Tasks') {
      submenuID = "Open Tasks";
    }
    else if (val == 'Completed Tasks') {
      submenuID = "Completed Tasks";
    }
    else if (val == 'All') {
      submenuID = "All Tasks";
    }
    else if (val == 'New') {
      submenuID = "Add Tasks";
    } else {
      submenuID = "Open Tasks";
    }
    if (this.currentuser.UserPrivilegeList != undefined) {
      var button = document.getElementById(submenuID);
      var bname = (button == null) ? submenuID : (button.title);
      var thisTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == bname || p.resource.toLowerCase() == bname)[0];
      if (thisTab != undefined && thisTab.canview == false) {
        this.toastr.warning("You Do Not have Access to " + bname + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
        return false;
      }
    }

    userids = $("#userids").val();
    this.tasktype = val;
    this.leadservice.GetAllTaskHistory(val, userids, this.StartDate, this.EndDate, searchval, 'tasklist', this.currentuser.timezone).then((taskhistory) => {
      if (taskhistory.length > 0) {


        if ((taskhistory.length == 0 || taskhistory == null) && searchval != null) {
          this.applicationComponent.ShowInvalidSearchDialog = !this.applicationComponent.ShowInvalidSearchDialog;
          this.applicationComponent.dialogsizeInvalidSearch();
        }
        taskhistory.forEach(taskhistory => {
          taskhistory.datecreated = this.datePipe.transform(taskhistory.datecreated, "yyyy-MM-dd");
          taskhistory.datecompleted = taskhistory.datecompleted == null ? "" : this.datePipe.transform(taskhistory.datecompleted, "yyyy-MM-dd");
          taskhistory.estcompleteddate = ((taskhistory.estcompleteddate == "0001-01-01") ? "" : this.datePipe.transform(taskhistory.estcompleteddate, "yyyy-MM-dd"));
        });
        this.alltaskhistory = taskhistory;
        var data = [];
        var labelArr = [];
        if (this.alltaskhistory.length > 0 && this.alltaskhistory[0].islimitedSearch == true) {
          if (this.alltaskhistory[0].SearchVal) {
            this.applicationComponent.searchCriteria = this.alltaskhistory[0].SearchVal;
            //this.applicationComponent.Searchdiv = true;
            this.applicationComponent.searchstring = this.alltaskhistory[0].SearchVal.slice(0, -3);
            this.applicationComponent.searchCriteriadata = this.applicationComponent.searchstring.split(' / ');
          }
          this.applicationComponent.term = "True";//passing dummy data toshow delete button in search box
          this.applicationComponent.limitedSearch = true;
          $('#searchContact').val('');
        }
        else {

          //$('#searchContact').attr('style', 'height:30px;width:88%;border:none;border-radius:3px;')
          this.applicationComponent.term = "";
          $('#searchContact').val('');
          this.applicationComponent.searchstring = "";
          this.applicationComponent.limitedSearch = false;
          this.applicationComponent.searchCriteriadata = [];
        }
        if (this.TaskGridOptions.columnApi != undefined) {
          this.TaskGridOptions.columnApi.setColumnVisible('datecompleted', (val == 'Open') ? false : true);
          this.createColumnDefs(this.columnSelections);
        }

      } else {
        this.alltaskhistory = taskhistory;
        this.applicationComponent.searchstring = "No Match";
        if (this.applicationComponent.Searchtabname == 'OpenTaskHistory') {
          var retrievedObject = localStorage.getItem('OpenTaskSearchCriteria');
        } else if (this.applicationComponent.Searchtabname == 'ClosedTaskHistory') {
          var retrievedObject = localStorage.getItem('ClosedTasksSearchCriteria');
        } else if (this.applicationComponent.Searchtabname == 'AllTaskHistory') {
          var retrievedObject = localStorage.getItem('AllTasksSearchCriteria');
        }
        if (retrievedObject != null) {
          //this.applicationComponent.Searchdiv = true;
          var obj = retrievedObject.slice(0, -3);
          obj += '~';
          this.applicationComponent.searchCriteriadata = obj.split(' / ');
        }
      }
    });
    

  }

  initializeTicketsAgGrid() {
    this.TicketGridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 53).then((config) => {
      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        if (this.TicketGridOptions && this.TicketGridOptions.api)
          this.TicketGridOptions.api.paginationSetPageSize(config.maxlimit);
        
      }
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      
      this.TicketscreateColumnDefs(columnSelections);
    });

    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeletecvComponent
    };
  }
  TicketscreateColumnDefs(columnSelections: string) {
    var searcharray = [];
    if (this.applicationComponent.searchCriteriadata) {
      var searchaarraystring = this.applicationComponent.searchCriteriadata;
      if (searchaarraystring.includes(' /')) {
        searcharray = searchaarraystring.split(' / ');
      } else {
        searcharray.push(searchaarraystring);
      }
    }
    let columnSelectionsArray: string[] = [];
    let selfobj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      selfobj.TicketColumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          selfobj.TicketColumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "contactname") {
          selfobj.TicketColumnDefs.push({
            field: 'contactname', headerName: 'Contact Name', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "accountName") {
          selfobj.TicketColumnDefs.push({
            field: 'accountName', headerName: 'Account', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else {
          selfobj.TicketColumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {


      this.TicketColumnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'ticketId', headerName: 'Ticket', hide: true },
        {
          field: 'createdDate', headerName: 'Created Date', hide: false, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'accountName', headerName: 'Account', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'contactname', headerName: 'Contact name', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'email', headerName: 'Email', hide: false, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'UserName', headerName: 'User Name', hide: true, cellRenderer: function (params) {
            if (params.data.SearchVal != null && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                var search = params.data.SearchVal;
                if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }
              }
              return st

            } else {
              return params.value;
            }
          }
        },
        { field: 'userList', headerName: 'Assign Ticket To', hide: false },
        { field: 'subject', headerName: 'Subject', hide: false },
        { field: 'descriptions', headerName: 'Description', hide: false },
        { field: 'CCToName', headerName: 'CC', hide: true },
        {
          field: 'TicketStatus', headerName: 'Status', hide: false, cellRenderer(params) {
            var eDiv = document.createElement('div');
            var text = '';
            selfobj.ticketStatusList.forEach(ele => {
              if (ele.id == params.value) {
                text = ele.itemName;
              }
            })
            eDiv.innerHTML = text;
            return eDiv;
          }
        },
       

      ];
    }
    selfobj.TicketGridOptions.getRowStyle = function (params) {

      if (params.data.assignTicketToUser != null && params.data.assignTicketToUser == selfobj.currentuser.id && params.data.readstatus == 0) {
        return { backgroundColor: 'rgb(241, 242, 232)', 'font-weight': 'bold' };
      }
    }
  }
  GetAllTicketStatus() {
    this.customerService.GetAllTicketStatus().then((userslist) => {
      this.ticketStatusList = userslist;
    });
  }
  GetAllZendeskUers(filter, sortOrder) {  
    this.customerService.GetTicketsList(this.ticketType, 'My Tickets Only', this.currentuser.id, sortOrder, this.displayCC, this.assignedTo).then((tickets) => {
      this.Userlist = tickets.filter(x => (x.createdDate > this.StartDate));
    });

  }


  initializeProposalAgGrid() {
    this.ProposalGridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 54).then((config) => {
      columnSelections = config;
      
      this.createProposalColumnDefs(columnSelections);
      


    });



  }
  createProposalColumnDefs(columnSelections: string) {
    var searcharray = [];
    if (this.applicationComponent.searchCriteriadata) {
      var searchaarraystring = this.applicationComponent.searchCriteriadata;
      if (searchaarraystring.includes(' /')) {
        searcharray = searchaarraystring.split(' / ');
      } else {
        searcharray.push(searchaarraystring);
      }
    }
    let columnSelectionsArray: string[] = [];
    let selfobj = this;
    if (columnSelections != "" && columnSelections != null ) {
      columnSelectionsArray = columnSelections.split(',');

      selfobj.ProposalColumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          selfobj.ProposalColumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "ContactName") {
          selfobj.ProposalColumnDefs.push({
            field: 'ContactName', headerName: 'Contact Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "AccountName") {
          selfobj.ProposalColumnDefs.push({
            field: 'AccountName', headerName: 'Account', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else {
          selfobj.ProposalColumnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {


      selfobj.ProposalColumnDefs = [
        {
          headerName: '#',
          field: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false
        },
        {
          field: 'CreatedDate', headerName: 'Date', hide: false, width: 110, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'AccountName', headerName: 'Account', hide: false, width: 200, cellRenderer: function (params) {
            //if (searcharray.length > 0 && params.value != null) {
            //  let x = params.value.split(" ");
            //  var st = "";
            //  for (let i = 0; i < x.length; i++) {
            //    if (searcharray.toString().toLowerCase().includes(x[i].toLowerCase().trim())) {
            //      st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
            //    }
            //    else {
            //      st += x[i] + " ";
            //    }

            //  }
            //  return st
            //} else {
            //  return params.value;
            //}
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'AccountId', headerName: 'AccountId', hide: false, width: 200
        },
        {
          field: 'ContactName', headerName: 'Contact', hide: false, width: 180, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'itemName', headerName: 'Proposal Type', hide: false, width: 170, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'ProposalDescription', headerName: 'Proposal Description', hide: false, width: 170, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'ProposalPDF', headerName: 'Proposal', hide: false, width: 100, cellRenderer(params) {

            if (params.data.ProposalPDF != null && params.data.ProposalPDF != 0) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
              eDiv.addEventListener('click', function () {
                //selfobj.ContactDetails = false;
                //selfobj.PDFClick(params.data.ProposalPDF, 'Proposal');
              });
              return eDiv;
            }

          }
        },
        {
          field: 'ProposalPOPDF', headerName: 'PO', hide: false, width: 100, cellRenderer(params) {
            if (params.data.ProposalPOPDF != null && params.data.ProposalPOPDF != 0) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
              eDiv.addEventListener('click', function () {
                //selfobj.ContactDetails = false;
                //selfobj.PDFClick(params.data.ProposalPOPDF, 'Proposal');
              });
              return eDiv;
            }
          }

        },
        {
          field: 'leadfrom', headerName: 'Lead From', hide: false, width: 170, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'LeadDescription', headerName: 'Lead Description', hide: false, width: 170, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'TotalPrice', headerName: 'Total Price', hide: false, width: 100
        },
        {
          field: 'MonthlyTotalPrice', headerName: 'Monthly Total Price', hide: false, width: 100
        },
        {
          field: 'status', headerName: 'Status', hide: false, width: 100, cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                  st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        }

      ];

    }
  }
  GetAllProposal(userid) {
    this.userService.GetAllProposals(userid, 'All Proposals', 1, this.applicationComponent.startVal, this.applicationComponent.endVal).then(result => {

      this.AllProposals = result.filter(x => (moment(x.CreatedDate).format('YYYY-MM-DD') > this.StartDate));

      //if (this.AllProposals.length > 0) {
      //  this.applicationComponent.searchstring = "";
      //  this.applicationComponent.searchCriteriadata = [];
      //  if (this.AllProposals.length > 0 && this.AllProposals[0].islimitedSearch == true) {
      //    if (this.AllProposals[0].SearchVal) {
      //      this.applicationComponent.searchCriteria = this.AllProposals[0].SearchVal;
      //      this.applicationComponent.searchstring = this.AllProposals[0].SearchVal.slice(0, -3);
      //      this.applicationComponent.searchCriteriadata = this.applicationComponent.searchstring.split(' / ');
      //    }
      //    this.applicationComponent.term = "True";
      //    this.applicationComponent.limitedSearch = true;
      //    $('#searchContact').val('');
      //    //this.createColumnDefs(this.colSelections);
      //  }
      //  else {
      //    this.applicationComponent.term = "";
      //    $('#searchContact').val('');
      //    this.applicationComponent.searchstring = "";
      //    this.applicationComponent.limitedSearch = false;
      //    this.applicationComponent.searchCriteriadata = [];
      //    //this.createColumnDefs(this.colSelections);
      //  }
      //}
      //else {
      //  this.applicationComponent.searchstring = "No Match";
      //  //this.applicationComponent.searchCriteriadata.push("No Matched Results");
      //  var retrievedObject = localStorage.getItem('ProposalSearchCriteria');
      //  if (retrievedObject != null) {
      //    // this.applicationComponent.Searchdiv = true;
      //    var obj = retrievedObject.slice(0, -3);
      //    obj += '~';
      //    this.applicationComponent.searchCriteriadata = obj.split(' / ');
      //  }
      //}

    });
  }

  AccountDoubleClick(rowEvent) {
    this.applicationComponent.QuickSearchDiv = false;
    localStorage.setItem('RhxAccountVal', rowEvent.data.id);
    let SO = this;
    $(setTimeout(function () {
      SO.applicationComponent.activateSubButtons('empty');
      $(setTimeout(function () {
        SO.router.navigateByUrl(`/application/account/Accounts`);
        SO.applicationComponent.ExpandSelectedMenu('Accounts');
        $(".subButtons").removeClass("activeButton");
        SO.applicationComponent.selectedtabid = 'Customer';
      }, 100));
    }, 50));
  }
  ContactDoubleClick(rowEvent) {
    this.applicationComponent.QuickSearchDiv = false;
    localStorage.setItem('RhxContactVal', rowEvent.data.id);

    let SO = this;
    $(setTimeout(function () {
      SO.applicationComponent.activateSubButtons('empty');
      $(setTimeout(function () {
        SO.router.navigateByUrl(`/application/contacts/contacts/null`);
        SO.applicationComponent.ExpandSelectedMenu('contact');
        SO.applicationComponent.selectedtabid = 'contact';
      }, 100));
    }, 50));
  }
  LeadsDoubleClick(rowEvent) {
    this.applicationComponent.CollapseSelectedMenu('leads');
    this.applicationComponent.ExpandSelectedMenu('leads');
    this.router.navigateByUrl(`/application/leadsdashboard/${rowEvent.data.contactid}/${rowEvent.data.id}/null`);
  }
  OpportunitiesDoubleClick(rowEvent) {
    this.applicationComponent.CollapseSelectedMenu('opportunities');
    this.applicationComponent.ExpandSelectedMenu('opportunities');

    this.router.navigateByUrl(`/application/opportunitiesdashboard/${rowEvent.data.accountorcontactid}/${rowEvent.data.type}/null/${rowEvent.data.id}`);
  }
  ProposalDoubleClick(rowEvent) {
    this.applicationComponent.CollapseSelectedMenu('Proposals');
    this.applicationComponent.ExpandSelectedMenu('Proposals');

    this.router.navigateByUrl(`/application/Proposals/` + rowEvent.data.accountorcontactid + `/` + rowEvent.data.ProposalID + `/Open Proposal/Contact`);
  }
  TasksDoubleClick(rowEvent) {
    this.applicationComponent.CollapseSelectedMenu('task lists');
    this.applicationComponent.ExpandSelectedMenu('task lists');
    this.router.navigateByUrl(`/application/tasklistdashboard/Contacts/` + rowEvent.data.contactid + '/' + rowEvent.data.id +'/null');
  }
  TicketsDoubleClick(rowEvent) {
    this.applicationComponent.CollapseSelectedMenu('tickets');
    this.applicationComponent.ExpandSelectedMenu('tickets');
    var status = "Open Tickets";
    if (rowEvent.data.TicketStatus == 1) {
      status = "Open Tickets";
    } else if (rowEvent.data.TicketStatus == 2) {
      status = "On-Hold Tickets";
    } else if (rowEvent.data.TicketStatus == 3) {
      status = "Pended Tickets";
    } else if (rowEvent.data.TicketStatus == 4) {
      status = "Closed Tickets";
    } else if (rowEvent.data.TicketStatus == 5) {
      status = "Open Tickets";
    } else if (rowEvent.data.TicketStatus == 6) {
      status = "Deleted Tickets";
    }

    this.router.navigateByUrl(`/application/zendeskhome/`+rowEvent.data.contactid + '/' + rowEvent.data.ticketId +'/'+ status);
  }


  OpportunitieshistoryinitializeAgGrid() {
    this.AccessGridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',

    };
    this.opportunitiesscreateColumnDefs("");
    this.LoadLoginAuditLog('Login', $("#userids").val(), 1, 100, this.StartDate, this.EndDate, false);

    this.AccessGridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
  }
  opportunitiesscreateColumnDefs(columnSelections: string) {
    this.AccessColumnDefs = [
      { field: 'documentname', headerName: 'Audit Type', hide: false, width: 100 },
      { field: 'action', headerName: 'Action', hide: false, width: 200 },
      { field: 'username', headerName: ' User Name', hide: false, width: 200 },
      { field: 'LoggedIn', headerName: 'Login-Time', hide: false, width: 200 },
      {
        field: 'LoggedOut', headerName: 'Logout-Time', hide: false, width: 200, cellRenderer(params) {
          if (params.data.LoggedOut === null || params.data.LoggedOut === '') {
            return "BTC";
          } else {
            return params.data.LoggedOut;
          }
        }},
      { field: 'TimeSpent', headerName: 'Time Used', hide: false, width: 150 },
      { field: 'LogOutStatus', headerName: 'LogOut Status', hide: false, width: 200 },
    ];
    //this.accountcontactcolumnDefs = [
    //  { field: 'documentname', headerName: 'Audit Type', hide: false, width: 100 },
    //  { field: 'action', headerName: 'Action', hide: false, width: 300 },
    //  { field: 'username', headerName: ' User Name', hide: false, width: 200 },
    //  { field: 'dateofviewed', headerName: ' Created Date', hide: false, width: 250 },
    //];
  }

  LoadLoginAuditLog(type, UserIds, startrow, endrow, startdate, enddate, pageSizeChanged) {
    if (UserIds == "") {
      this.loginauditloglist = [];
    } else {
      if (!pageSizeChanged) {
        this.customerService.GetAllDocumentAuditlogList(0, type, UserIds, startrow, endrow, 'undefined', startdate, enddate).then((opportunityauditloglist) => {
          opportunityauditloglist.forEach(document => {
            document.dateofviewed = this.datePipe.transform(document.dateofviewed, "MM-dd-yyyy");
            document.LoggedIn = this.datePipe.transform(document.LoggedIn, "MM-dd-yyyy HH:mm");
            document.LoggedOut = document.LoggedOut != 'null' ? this.datePipe.transform(document.LoggedOut, "MM-dd-yyyy HH:mm") : document.LoggedOut;
          });
          this.loginauditloglist = opportunityauditloglist;
          this.AccessGridOptions.api.paginationSetPageSize(Number(this.loginauditloglist.length));
        });
      }
    }

  }

}
