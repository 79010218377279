import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Accounts } from '../../models/accounts';
import { AccountTransferModel } from '../../models/AccountTransferModel';
import { AuditLogModel } from '../../models/auditlog';
import { CustomerService } from '../../services/customer.service';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { ApplicationComponent } from '../application.component';
declare var $: any;
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinctUntilChanged";
import "rxjs/add/operator/mergeMap";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';

import { debounceTime } from 'rxjs/operators';
import { QuickSearchModel } from '../../models/QuickSearchmodel';
import { IMyDpOptions } from 'mydatepicker';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { Dropdowntype } from '../../models/dropdowntype';

@Component({
  selector: 'app-account-transfer-ownership',
  templateUrl: './account-transfer-ownership.component.html',
  styleUrls: ['./account-transfer-ownership.component.css']
})
export class AccountTransferOwnershipComponent implements OnInit {
  FromDropdownList = [];
  fromDropdownSettings = {};
  FromSelectedItems = [];
  ToDropdownList = [];
  ToDropdownSettings = {};
  ToSelectedItems = [];
  dropdownSetting = {};
  TransferedAccounts: AccountTransferModel[];
  public gridOptions: GridOptions;
  public sideBar;
  allCustomers: Accounts[];
  public columnDefs: any[];
  public context;
  Ids = [];
  currentuser: any;
  TransferConfirmationBox: boolean = false;
  AccountTransferPage: boolean = true;
  @Input() SubMenuType: string = null;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  LeaveTransferAccounts: boolean = false;
  customertype: any = '0,1,2';
  term: string;
  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  selectedval: string = 'Name';
  SearchType: string = "accountName";
  QuickSearchConfig = [];
  subject: Subject<any> = new Subject();
  QuickSearchModel: QuickSearchModel = new QuickSearchModel();
  UserIds: string;
  calanderonoff: boolean = true;
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 3) + '-01-01' : this.applicationComponent.startVal;
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");
  timezonevalue: string;
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");
  IncludeShared: string = "Shared";
  datePipe: DatePipe = new DatePipe("en-US");
  SearcValueList: Dropdowntype[] = [];
  SearchTypeList: Dropdowntype[] = [];
  SearcValueModel = [];
  SearchTypeModel = [];
  selectedUserIds = "";
  SearcValueDrpSetting = {};
  selectedItems = [];
  constructor(public applicationComponent: ApplicationComponent, private customerService: CustomerService, private opprtunityservice: OpprtunitiesService, private toastr: ToastrService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.fromDropdownSettings = {
      singleSelection: true,
      text: "Select User - Transfer From",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.ToDropdownSettings = {
      singleSelection: true,
      text: "Select User - Transfer To",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 10
    };
    this.SearcValueDrpSetting = {
      singleSelection: true,
      text: "Select Search"
    };
    this.loadSearchdropdowns();
    this.customerService.GetAllaccesusersListbyuser(0,1, 1, 1).then((Owner) => { // passing 0 to get all Users
      Owner.forEach(Owner => {
      });

      this.FromDropdownList = Owner;
      this.ToDropdownList = Owner;
      this.FromDropdownList.push({ id: 0, category: "None", itemName: "None", isdeleted: 0, name: null, Value: null });
      this.ToDropdownList.push({ id: 0, category: "None", itemName: "None", isdeleted: 0, name: null, Value: null });
    })
    this.allCustomers = [];
    this.initializeAgGrid();
    if (this.SubMenuType !== null && this.SubMenuType !== undefined && this.SubMenuType !== '') {
      this.ActionDiv(this.SubMenuType);
    } else {
      var obj: AuditLogModel = { id: 0, type: 'Account Transfer Configuration', action: 'Account Transfer', AccountOrContactId: 0, AccountOrContact: '', documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Account Transfer', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
      this.applicationComponent.InsertAuditHistory(obj);
    }

    this.subject
      .pipe(debounceTime(1000))
      .subscribe(() => {
        if (this.term != "") {
          this.GetContactSearchList(this.term, this.FromSelectedItems[0].id, 0);
        } else {
          this.allCustomers = [];
        }

      }
      );
  }
  CloseAccTransfer() {
    this.LeaveTransferAccounts = true;
    setTimeout(function () {
      $('#LeaveTransferAccounts .dialog').attr('style', 'width: 29%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top: 6%;');
    }, 4);
  }
  LeaveTransfer() {
  this.AccountTransferPage = false;
  }
  loadSearchdropdowns() {
    this.customerService.GetSpecialityList("Searchval", this.currentuser.id).then((Rows) => {
      this.SearcValueList = Rows;

      if (this.SearchType == "contactName") {
        this.SearcValueModel = this.SearcValueList.filter(x => x.itemName == 'Contact ' + this.selectedval);
      } else {
        this.SearcValueModel = this.SearcValueList.filter(x => x.itemName == 'Account ' + this.selectedval);
      }

    });
    this.customerService.GetSpecialityList("SearchTypeval", this.currentuser.id).then((Rows) => {
      this.SearchTypeList = Rows;

      var CustType = (this.customertype == '1') ? 'Customer' : (this.customertype == '2') ? 'Former Customer' : (this.customertype == '0') ? "Non-Customer" : 'Display All'
      this.SearchTypeModel = this.SearchTypeList.filter(x => x.itemName == CustType);

    });
    this.SearcValueModel = [{ "id": 702, "itemName": "Account Name" }];
    this.SearchTypeModel = [{ "id": 712, "itemName": "Display All" }];
  
  }
  ActionDiv(val) {
    this.AccountTransferPage = true;
     var obj: AuditLogModel = { id: 0, type: 'Account Transfer Configuration', action: 'Account Transfer', AccountOrContactId: 0, AccountOrContact: val, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Account Transfer', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);

  }

  FromOnItemSelect(event) {
    this.getAccounts();
  }
  getAccounts() {
    this.opprtunityservice.GetAllAccountByUserID(this.FromSelectedItems[0].id).then((account) => {
      if (account && account.length > 0) {
        if (this.term != '' && this.term != null && this.term != undefined) {
          this.allCustomers = account.filter(x => x.accountname.includes(this.term));
        } else {
          this.allCustomers = account;
        }
        this.initializeAgGrid();
      } else {
        this.allCustomers = [];
      }

    });
  }
  FromOnItemDeSelect(event) {    
  }
  ToOnItemSelect(event) {
  }
  ToOnItemDeSelect(event) {

  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, paginationPageSize: 1000,enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true,
      toolPanelSuppressPivotMode: true, rowSelection: 'multiple', enableCellChangeFlash: true
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
      this.createColumnDefs();
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    
  }
  createColumnDefs() {
    this.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      { field: 'id', headerName: 'Id', hide: true },
      { field: 'accountname', headerName: 'Account Name', hide: false },
      {
        field: 'customertypeid', headerName: 'Active Or Inactive', hide: false,
        cellRenderer(params) {
          if (params.value == '0') { return "Non - Customer" } else if (params.value == '1') { return "Customer" } else if (params.value == '2') { return "Former Customer" }
        }
      },
      { field: 'speciality', headerName: 'Specialty', hide: false, },
      {
        field: 'address1', headerName: 'Address1', hide: false,
      },
      {
        field: 'address2', headerName: 'Address2', hide: false,
      },
      {
        field: 'city', headerName: 'City', hide: false,
      },
      {
        field: 'state', headerName: 'State', hide: false,
      },
      {
        field: 'zip', headerName: 'Zip', hide: false
      },
      {
        field: 'phone', headerName: 'Phone', hide: false,
      },
      
      {
        field: 'username', headerName: 'User Name', hide: false,
      },
      {field: 'accnumber', headerName: 'Act Number', hide: true, cellStyle: { color: 'red' },},
      { field: 'IsContact', headerName: ' Is Contact?', hide: true },
   
    
    
      {
        field: 'mapit', headerName: 'Map It', hide: true, width: 80,
      },
      {
        field: 'ehr', headerName: 'EHR', hide: true,
      },
      {
        field: 'pm', headerName: 'PM', hide: true,
      },

      {
        field: 'rcm', headerName: 'RCM', hide: true,
      },
     
    ];
  }

  rowClickAgGrid(rowEvent) {
    if ((this.Ids).indexOf(rowEvent.data.id) > -1) {
      var index = (this.Ids).indexOf(rowEvent.data.id);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
    } else {
      this.Ids.push(rowEvent.data.id)
      var string = (this.Ids).toString();
    }
  }

  TransferAccounts() {
    if (this.ToSelectedItems && (this.ToSelectedItems.length > 0)) {
      let AccTransfr: AccountTransferModel = new AccountTransferModel();
      AccTransfr.AccountIds = this.Ids.join(',');
      AccTransfr.NewOwnerId = this.ToSelectedItems[0].id;
      AccTransfr.originalOwnerid = this.FromSelectedItems[0].id;
      AccTransfr.TransferedBy = this.currentuser.id
      this.customerService.transferAccounts(AccTransfr).then(result => {
        this.getAccounts();
        this.TransferConfirmationBox = false;
        this.Ids = [];
        this.toastr.success("Account Transfered successfully.", "Account Saved", { timeOut: 600 });
      });
    }
    else {
      alert("Please Select to User ,to which accounts transfer to");
    }
  }

  TransferConfirmationBoxCss() {
    setTimeout(function () {
      $('#TransferConfirmationBox .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4)
  }

  Search(event) {
    let selfObj = this;
    setTimeout(() => {
      var userIds = [];    
      if (selfObj.selectedval == null) {
        if (selfObj.SearchType == 'accountName') {
          if (selfObj.term != "") {
         
            selfObj.loadallaccounts(userIds, false, 1, 100, 0);
            
          } else {
            this.allCustomers = [];
          }
        }
        
      } else {
        selfObj.subject.next();

      }
    }, 200);


  }
  changeSearchTypeRadio(val) {
    this.initializeAgGrid();
  }

  changeRadio(val) {
    this.term = "";
    this.allCustomers = [];
    this.SaveQuickSearchConfigToLocal();
  }

  changeCustomerTypeRadio() {   
    this.SaveQuickSearchConfigToLocal()
  }

  SaveQuickSearchConfigToLocal() {
    this.QuickSearchConfig = [{ 'selectedval': this.selectedval, 'SearchType': this.SearchType, 'customertype': this.customertype, 'IncludeShared': this.IncludeShared }];
    localStorage.setItem('QuickSearchConfig', JSON.stringify(this.QuickSearchConfig[0]));
  }

  GetContactSearchList(term, userid, active_deactiveStatus?: number) {
    var userIds = [];
    this.FromSelectedItems.forEach(ele => {
      userIds.push(ele.id);

    });
    this.QuickSearchModel.searchterm = this.term;
    this.QuickSearchModel.userids = userIds.join();
    this.QuickSearchModel.startDate = (this.calanderonoff) ? this.startDateVal : '2018-01-01';
    this.QuickSearchModel.endDate = this.endDateVal;
    this.QuickSearchModel.Includetype = '457';
    this.QuickSearchModel.customertype = this.customertype;
    this.QuickSearchModel.selectedval = this.selectedval;
    this.QuickSearchModel.SearchType = this.SearchType;
    //this.QuickSearchModel.IncludeShared = this.IncludeShared;
    this.QuickSearchConfig = [{ 'selectedval': this.selectedval, 'SearchType': this.SearchType, 'customertype': this.customertype, 'IncludeShared': this.IncludeShared }];


    localStorage.setItem('QuickSearchConfig', JSON.stringify(this.QuickSearchConfig[0]));

    this.opprtunityservice.GetAllContactsAndCustomerByQuickSearch(this.QuickSearchModel).then((contact) => {
      this.allCustomers = [];
      if (contact.length > 0) {
        contact.forEach(contact => {
          contact.created = this.datePipe.transform(contact.created, "MM-dd-yyyy");
          contact.speciality = ((contact.speciality == 'null') ? "" : contact.speciality);
        });
        if (this.SearchType == 'accountName') {
          let selfobj = this;
          contact.forEach(con => {
            var account: Accounts = new Accounts();
            account.id = con.id;
            account.accountname = con.accountname;
            account.address1 = con.address1;
            account.address2 = con.address2;
            account.city = con.city;
            account.phone = con.cell;
            account.owner = con.owner;
            account.state = con.state;
            account.zip = con.zip;
            account.isshared = con.isshared;
            selfobj.allCustomers.push(account);

          });

         
        }

        this.initializeAgGrid();

      } else {
        this.allCustomers = [];
      }

    });
  }
  loadallaccounts(userids, isSearch: boolean, startrow, endrow, accstatus) {
  
    this.applicationComponent.selectedusers = userids;
    this.applicationComponent.searchstring = "";
    this.applicationComponent.searchCriteriadata = [];
    if (this.term == "") {
      this.term = null;
    }
    this.allCustomers = [];
    this.opprtunityservice.GetAllAccountsBySearch(this.FromSelectedItems[0].id, this.term, this.FromSelectedItems[0].id, 'Accounts', (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, startrow, endrow, this.customertype, accstatus).then((account) => {
      if (account.length > 0) {
        account.forEach(account => {
        });
        
          this.allCustomers = account;
        
      } else {
        this.allCustomers = [];
      }

    });

  }

  SearcValueItemSelect(item) {
    this.selectedval = item.itemName.split(' ')[1];
    if (item.value.startsWith('Account')) {
      this.SearchType = "accountName";
      
      if (this.term != "") {
        this.loadallaccounts(this.UserIds, false, 1, 10000, 0);

      } else {
        this.allCustomers = [];
      }
    }
    else {
      this.SearchType = "contactName";
      if (this.term == '') this.term = null;

    }
    this.SaveQuickSearchConfigToLocal();
  }
  SearchTypeItemSelect(item) {
    this.customertype = item.value == "Display All" ? '0,1,2,3' : item.value == "Shared Accounts/Contacts" ? '3' : item.value == "Customer" ? '1' : item.value == "Former Customer" ? '2' : '0';

    this.SaveQuickSearchConfigToLocal()
  }
  clearSearch() {
    this.term = null;
    $("#SearchTextbox").val(null);
    this.term = ""
    this.loadallaccounts(this.UserIds, false, 1, 10000, 0);
    this.initializeAgGrid();
  }
  close() {
    this.sendMsgEvent.emit("AccountTransfer");

  }
  IncludeSharedAccounts() {
    if ($('#IncludeShared').prop('checked') == true) {
      this.IncludeShared = "Shared";
    } else {
      this.IncludeShared = "NotShared";
    }
    var userIds = [];
    this.selectedItems.forEach(ele => {
      userIds.push(ele.id);

    });
    if (this.selectedval == null) {
      if (this.SearchType == 'accountName') {
        if (this.term == '') this.term = undefined;
        this.loadallaccounts(userIds.join(), false, 1, 10000, 0);
        
      } else if (this.SearchType == 'contactName') {
        if (this.term == '') this.term = null;
        //this.GetContactList(null, userIds, 0);
        //this.SearchResultTable = 'Contact';
      }
    }
    else {
      if (this.term == '' || this.term == null || this.term == undefined) {
        if (this.SearchType == 'accountName') {
          if (this.term == '') this.term = undefined;
          this.loadallaccounts(userIds.join(), false, 1, 10000, 0);
          //this.SearchResultTable = 'Account';
        } else if (this.SearchType == 'contactName') {
          if (this.term == '') this.term = null;
          //this.GetContactList(null, userIds, 0);
          //this.SearchResultTable = 'Contact';
        }
      } else {
        this.GetContactSearchList(this.term, userIds, 0);
        //this.SearchResultTable = 'Contact';
      }
    }

    this.SaveQuickSearchConfigToLocal();
  }
}
