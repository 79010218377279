export class StatusIds {
  public id: number;
  public AccountContactId: number;
  public AccountContactType: string;
  public StatusID1 : number;
  public StatusID2 : number;
  public StatusID3 : number;
  public StatusID4 : number;
  public StatusID5 : number;
  public StatusID6 : number;
  public StatusID7 : number;
  public StatusID8 : number;
  public StatusID9 : number;
  public StatusID10: number;
  public StatusID11: number;
  public StatusID12: number;
  public StatusID13: number;
  public StatusID14: number;
  public StatusID15: number;
  public StatusID16: number;
  public StatusID17: number;
  public StatusID18: number;
  public StatusID19: number;
  public StatusID20: number;
  public StatusID21: number;
  public StatusID22: number;
  public StatusID23: number;
  public StatusID24: number;
  public StatusID25: number;
  public StatusID26: number;
  public StatusID27: number;
  public StatusID28: number;
  public StatusID29: number;
  public StatusID30: number;
  public StatusID31: number;
  public StatusID32: number;
  public StatusID33: number;
  public StatusID34: number;
  public StatusID35: number;
  public StatusID36: number;
  public StatusID37: number;
  public StatusID38: number;
  public StatusID39: number;
  public StatusID40: number;
  public StatusIDName1: number;
  public StatusIDName2: number;
  public StatusIDName3: number;
  public StatusIDName4: number;
  public StatusIDName5: number;
  public StatusIDName6: number;
  public StatusIDName7: number;
  public StatusIDName8: number;
  public StatusIDName9: number;
  public StatusIDName10: number;
  public StatusIDName11: number;
  public StatusIDName12: number;
  public StatusIDName13: number;
  public StatusIDName14: number;
  public StatusIDName15: number;
  public StatusIDName16: number;
  public StatusIDName17: number;
  public StatusIDName18: number;
  public StatusIDName19: number;
  public StatusIDName20: number;
  public StatusIDName21: number;
  public StatusIDName22: number;
  public StatusIDName23: number;
  public StatusIDName24: number;
  public StatusIDName25: number;
  public StatusIDName26: number;
  public StatusIDName27: number;
  public StatusIDName28: number;
  public StatusIDName29: number;
  public StatusIDName30: number;
  public StatusIDName31: number;
  public StatusIDName32: number;
  public StatusIDName33: number;
  public StatusIDName34: number;
  public StatusIDName35: number;
  public StatusIDName36: number;
  public StatusIDName37: number;
  public StatusIDName38: number;
  public StatusIDName39: number;
  public StatusIDName40: number;
  public userid: number;
}
