<div class="container" style="height:99%;padding:0;">

  <input type="text" id="emailIds" style="display:none;" />
  <input type="text" id="tasklistselectedtyle" style="display:none;" />

  <div class="col-xs-4 pull-right" style="margin-top:1%;text-align:center;height:10%" id="RadioButtons">
    <form>
      <input type="radio" name="AccountContact" value="Accounts" id="Accounts" (click)="AccountClicked()" checked> Accounts
      <input type="radio" name="AccountContact" value="Contacts" id="Contacts" style="margin-left:4%" (click)="ContactClicked()"> Contacts<br>

    </form>

  </div>

  <div *ngIf="(salestooltype=='Opportunities - Won Approval')" class="col-xs-12" style="padding:0">
    <input type="text" id="accotIdNotes" style="display:none;" />
    <input type="text" id="accotTypeNotes" style="display:none;" />
    <app-dialog [(visible)]="showDialog">
      <app-notes [title]="'Opportunities Notes Info'"
                 [source]="'Opportunity'"
                 [account]="account"
                 [gridOptionsnotes]="gridOptionsnotes"
                 [allnotes]="allnotes"
                 [context]="context"
                 [contactid]="contactid"
                 [columnDefsnotes]="columnDefsnotes"
                 [tabName]="'Opportunity'"
                 [contact]="contact"
                 [conid]="conid"
                 [typ]="typ">
      </app-notes>
    </app-dialog>
    <div class="clearfix"></div>
    <br />
    <ag-grid-angular [style.height]="aggridheight" style="width: 100%; padding-top: 0px;" id="gridheight"
                     (window:resize)="ResizeTable()" class="ag-theme-balham"
                     [gridOptions]="gridOptions"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="Allopportunities"
                     [context]="context"
                     [sideBar]="sideBar"
                     [frameworkComponents]="frameworkComponents"
                     (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                     (rowSelected)="rowClickAgGrid($event)"
                     (columnVisible)="columnVisible($event)"
                     (dragStopped)="dragStopped($event)"
                     (columnResized)="columnResized($event)"
                     (gridReady)="onAgGridReady($event)">
    </ag-grid-angular>
  </div>

  <app-dialog [(visible)]="OpportunityHistoryDialog">
    <h4>Opportunity Audit Trial</h4>
    <ag-grid-angular [style.max-height]="aggridheight" style="width: 100%; height:300px; padding-top: 10px;"
                     class="ag-theme-balham"
                     [gridOptions]="gridopportunityOptions"
                     [columnDefs]="opportunitycolumnDefs"
                     [defaultColDef]="applicationComponent.defaultGridColDef"
                     [rowData]="opportunityauditloglist"
                     [sideBar]="sideBar"
                     (gridReady)="onopportunityauditlogAgGridReady($event)">
    </ag-grid-angular>
  </app-dialog>

  <div *ngIf="(salestooltype=='ResponseWizard')" class="col-xs-12" style="padding:0;height:98%">
    <responsewizard [type]="'SalesTool'"></responsewizard>
  </div>

  <div class="col-xs-12" id="importDataDiv" style="padding:0;">
    <div class="col-xs-6" style="padding-right:0;padding-left:0;">
      <form>
        <div id="Wizarddiv">
          <div class="col-xs-12" id="wizardHeader" style="text-align:center;background:#000;color:#9afd6c; border: 1px solid #000; border-radius:3px;margin-top: 0;height: 30px;">
            <label id="Wizardlabel" style="margin-top:1%">Import Wizard</label>
          </div>
          <div class="col-xs-12" id="WizardBody" style="border:1px solid #b5b1b1;height:466px;overflow:auto;">
            <div class="col-xs-12 removeCompletePadding" style="margin-top:1%">
              <div class="col-xs-5">
                <label>1.Select file to Import:</label>
              </div>
              <div class="col-xs-7">
                <!--<input type="text" name="importData" id="importData" class="form-control" placeholder="Select Import Data (excel file)" />-->
                <input type="file" class="form-control" accept=".xlsx" (change)="incomingfile($event)">
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top:1%">
              <div class="col-xs-5">
                <label>2.Rows Found in Table</label>
              </div>
              <div class="col-xs-7">
                <input type="text" name="importData" id="importData" class="form-control" placeholder="0" />
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top:1%">
              <div class="col-xs-5">
                <label>3.If Duplicates are Found:</label>
              </div>
              <div class="col-xs-7">
                <select class="form-control" style="text-align:center">
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                  <option value="2">Skip</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top:1%">
              <div class="col-xs-5">
                <label>4.Find Duplicates by Using:</label>
              </div>
              <div class="col-xs-7">
                <input type="checkbox" id="City" (change)="checkBoxChecked(this)"> City<br>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top:1%">
              <div class="col-xs-5">

              </div>
              <div class="col-xs-7">
                <input type="checkbox" id="State" (change)="checkBoxChecked(this)"> State<br>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top:1%">
              <div class="col-xs-5">

              </div>
              <div class="col-xs-7">
                <input type="checkbox" id="Zip" (change)="checkBoxChecked('Zip')"> Zip<br>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top:1%">
              <div class="col-xs-5">

              </div>
              <div class="col-xs-7">
                <input type="checkbox" id="Phone" (change)="checkBoxChecked('Phone')"> Phone<br>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top:1%">
              <div class="col-xs-5">

              </div>
              <div class="col-xs-7">
                <input type="checkbox" id="Fax" (change)="checkBoxChecked('Fax')"> Fax<br>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top:1%">
              <div class="col-xs-5">
                <label>5.Duplicates create Excel Table</label>
              </div>
              <div class="col-xs-7">
                <select class="form-control" style="text-align:center" (change)="DuplicateCreateExcel()" id="CreateExcel">
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top:1%;margin-bottom:1%">
              <div class="col-xs-5">
                <label>6.Import Data for User:</label>
              </div>
              <div class="col-xs-7">
                <select class="form-control" style="text-align:center">
                  <option value="0">Select User</option>
                  <option *ngFor="let owner of dropdownList;" value="{{owner.id}}">{{owner.itemName}}</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top:1%;margin-bottom:1%">
              <div class="col-xs-5">

              </div>
              <div class="col-xs-7">
                <button class="subButtons" *ngIf="this.file" style="border: none;color:black !important;padding:0;" (click)="RunFile()">Click to Review or Edit Excel</button>
              </div>
            </div>
          </div>

          <div class="col-xs-12" id="WizardBodyGrid" style="border:1px solid #b5b1b1;height:466px;overflow:auto;">
            <div><span class="pull-right" style="text-align:right;color: gray;font-size:12px; font-weight: normal;cursor: pointer;" (click)="CloseGrid()"><b><img src="../../../assets/img/CollapseView.png" /></b></span></div>
            <ag-grid-angular #agGrid of mygrid style="width: 100%; height: 430px; margin-top:4%"
                             class="ag-theme-balham"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="rowData"
                             [pagination]="true"
                             [columnDefs]="columnDefsExcel"
                             (gridReady)="onExcelGridReady($event)">
            </ag-grid-angular>

            <!--[enableSorting]="true"
            [enableFilter]="true-->"
          </div>
        </div>
      </form>
    </div>
    <div class="col-xs-6" style="padding:0;">
      <div class="col-xs-12" id="tableDiv">
        <div class="col-xs-12" id="tableDivHeader" style="text-align:center;background:#000;color:#9afd6c; border: 1px solid #000; border-radius:3px;margin-top: 0;height: 30px;">
          <label id="Wizardlabel" style="margin-top:1%">Match the SalesXcrm Fields to the Import Data Fields </label>
        </div>
        <div class="col-xs-12" id="tableDivBody" style="padding:0; border:1px solid #b5b1b1; margin-top:1%;height: 420px;overflow:auto">
          <table class="table" style="border:1px solid #b5b1b1;width: 100%; margin-bottom:0;overflow:auto">
            <tr style="border:1px solid #b5b1b1;height:25px;background-color:#d9d9d9;">
              <th style="border:1px solid #b5b1b1;width:10%">#</th>
              <th style="border:1px solid #b5b1b1;width:30%">Source Data</th>
              <th style="border:1px solid #b5b1b1;width:25%">Location of Data</th>
              <th style="border:1px solid #b5b1b1;width:35%;background-color:#9afd6c">Import Data To SalesXCRM</th>
            </tr>

            <tr style="border:1px solid #b5b1b1;" *ngFor="let tool of colValues; let i = index; ">
              <td style="border:1px solid #b5b1b1;">{{i+1}}</td>
              <td style="border:1px solid #b5b1b1;">{{tool}}</td>
              <td style="border:1px solid #b5b1b1;">Column {{alphabets[i]}}</td>
              <td style="border:1px solid #b5b1b1;padding:0;">

                <div style="width: 100%">
                  <select style="width:100%;height:36px;text-align:center">
                    <option value="0">Select</option>
                    <option *ngFor="let col of TblDropDown ;" value="{{col}}">{{col}}</option>
                  </select>
                </div>
              </td>
            </tr>


          </table>
        </div>


        <div class="col-xs-12" id="tableDivBottom" style="margin-top:5px;padding:0;">
          <div class="col-xs-6" style="padding:0;"><button class="btn displayButton" id="canbtn" style="width:99.5%;background-color:#d9d9d9;border:1px solid #b5b1b1;">Cancel</button></div>
          <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width:100%;color:#9afd6c; background-color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="getAllRows()">Import Data</button></div>
        </div>
      </div>
    </div>
  </div>
</div>


<div id="Excel">

</div>




