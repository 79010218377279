<div class="content" style="padding:0;height:100%">

  <div class="col-xs-12">
    <div class="col-xs-12" style="padding:0;">
      <label>Custom Query Results</label>
    </div>
    <div class="col-xs-12" style="padding:0;">

      <div style="padding:0;width:93%">
        <div class="col-xs-2 blank" style="border:1px solid #b6b7b9;border-radius:5px;height:6%;text-align:center;margin-left:0.5%;padding:0 1% 0 0; width:19.5%;" id="tab{{i}}" *ngFor="let qry of Newrunquerylist; let i = index" (click)="GetQueryInfo(qry.id,'tab'+i)">
          <label style="margin-top:4px;"  title="{{qry.queryOrder}}">  {{qry.queryOrder | slice:0:25}}</label>

          <span *ngIf="qry.queryOrder.length >= 25">&nbsp;<img src="../../assets/img/img_red_dots.png" style="height:7px; margin-left:5px;" /></span>
          <span><i (click)="deleteQuery(qry.id,'tab'+i)" class="fa fa-times " style="float:right;margin:3% 0 0 0"></i></span>
        </div>

      </div>
      <div class="col-xs-1" style="padding:0;text-align:right;width:7%;float:right">
        <div class="col-md-6 col-sm-6 col-xs-6" style="padding: 0">
          <button class="btn" style="background-color:#dddddd" (click)="Previous()"><i class="fa fa-chevron-circle-left" aria-hidden="true"></i></button>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6" style="padding: 0">
          <button class="btn" style="background-color:#dddddd" (click)="next()"><i class="fa fa-chevron-circle-right" aria-hidden="true"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12" style="height:90%">
    <div [ngClass]="(applicationComponent.googlemapshow)?'col-xs-5':'col-xs-12'" style="height:100%;padding:0;">
      <ag-grid-angular style="height:100%" class="ag-theme-balham" *ngIf="showTabledata"
                       [gridOptions]="globalGridOptions"
                       [columnDefs]="globalColumnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="tableData"
                       [pagination]="true"
                       [sideBar]="sideBar"
                       (rowSelected)="rowClickAgGrid($event)"
                       (rowDoubleClicked)="rowDoubleClickAgGrid($event)">
      </ag-grid-angular>
    </div>

    <div *ngIf="(applicationComponent.googlemapshow)" class="col-xs-7" style="padding:0px;height:100%">
      <addressmap [AccConIds]="this.Ids" [accesstype]="'Accounts'"></addressmap>

    </div>

  </div>

  <div>
    <div *ngIf="QuickSearchEventListener()"></div>

  </div>
  <app-dialog [(visible)]="CloseDialog" [closable]="false">
    <div style="height: 100%; width: 100%">
      <div class="col-xs-12" style="text-align:center; ">
        <div class="col-xs-12" style="font-size:14px;">
          <b>Advanced Search Result were Closed.</b>
        </div>
        <br /><br />
        <div class="col-xs-12" style="font-size:14px;margin-bottom: 5%;">
          <b>Select Advanced Option on Search Bar to Generate</b><br />
          Another  Advanced Search <br />
          -or-<br />
          Type Search Data in Search Bar and Select Enter
        </div>
      </div>

    </div>
  </app-dialog>
</div>

