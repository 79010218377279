import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../services/customer.service';
declare var $: any;
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  email: string;
  captchaRes: any;
  constructor(private customerService: CustomerService) { }

  ngOnInit() {

  }
  resolved(captchaResponse: string) {
    this.captchaRes = captchaResponse;
    if (this.captchaRes) {
      document.getElementById('pwdsent').style.display = 'block';
    } else {
      alert("please confirm you are human");
      return false;
    }
  }
  passwordsent() {
    if (this.captchaRes) {
      alert("“Thank You, a password reset link has been sent to your email on file. Please follow the link to reset your password. “");
      this.email = $("#email").val().toString();
       
      this.customerService.SaveEmail(this.email).then(result => {

      });
    }
    else {
      alert("please confirm you are human");
      return false;
    }
  }


}
