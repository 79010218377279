<div class="col-xs-12" style="padding:0;height:100%;">
 <ag-grid-angular style="width: 100%;height:100%" id="Won" class="ag-theme-balham" *ngIf="type=='Won'"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="Allopportunities_Won"
                           [context]="context"
                           [sideBar]="sideBar"
                           (cellDoubleClicked)="rowClickAgGrid($event)">
  </ag-grid-angular>

  <ag-grid-angular style="width: 100%;height:100%" id="Lost" class="ag-theme-balham" *ngIf="type=='Lost'"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="Allopportunities_Lost"
                           [context]="context"
                           [sideBar]="sideBar"
                           (cellDoubleClicked)="rowClickAgGrid($event)">
  </ag-grid-angular>

  <ag-grid-angular style="width: 100%;height:102%" id="Open" class="ag-theme-balham" *ngIf="type=='Open'"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="Allopportunities_Open"
                           [context]="context"
                           [sideBar]="sideBar"
                           (cellDoubleClicked)="rowClickAgGrid($event)">
  </ag-grid-angular>

   <ag-grid-angular style="width: 100%;height:100%" id="Open" class="ag-theme-balham"
                            *ngIf="type=='Open_ECD'"
                            [gridOptions]="gridOptions"
                            [columnDefs]="columnDefs"
                            [defaultColDef]="applicationComponent.defaultGridColDef"
                            [rowData]="Allopportunities_Open_ECD"
                            [context]="context"
                            [sideBar]="sideBar"
                            (cellDoubleClicked)="rowClickAgGrid($event)">
  </ag-grid-angular>
</div>
