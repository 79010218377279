export class ViewSalesinfo {
  public monthlyleadconvertedcount: number;
  public leadconvertedcount: number;
   public leadconvertedPercentage: string;

    public Monthlycallscompleted: number;
    public Monthlycallscount: number;
    public MonthlycallsPercentage: string;

    public MonthlycallsScheduled: number;
  public MonthlycallsScheduledcount: number;
  public MonthlycallsScheduledPercentage: string;

  public MonthlyMeetingCompletedgoal: number;
  public MonthlyMeetingCompletedCount: number;
  public MonthlyMeetingCompletedPercentage: string;

  public MonthlylastDueActivitygoal: number;
  public MonthlylastDueActivityCount: number;
  public MonthlylastDueActivityPercentage: string;

  public MonthlyToDogoal: number;
  public MonthlyToDoCount: number;
  public MonthlyToDoPercentage: string;
  

  public MonthlyAccountAdded: number;
  public MonthlyAccountsaddedcount: number;
  public MonthlyAccountsaddedPercentage: string;

  public MonthlySaleswongoal: string;
  public MonthlySalesWonCount: string;
  public MonthlySalesWonPercentage: string;

  public MonthlySalesRRgoal: string;
  public MonthlySalesRRCount: string;
  public MonthlySalesRRPercentage: string;


    public activityname: string;
    public Monthcount: number;
    public Goal: number
  public Percentage: string;


  public NewTicketsgoal: number;
  public NewTicketCount: number;
  public NewTicketPercentage: string;

  public ClosedTicketsgoal: number;
  public ClosedTicketCount: number;
  public ClosedTicketPercentage: string;

}
