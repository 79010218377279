export class userModel {
  public userId: number;
  public username: string;
 public customerAccount: number;
  public isCustomer: number;
  public customerSince: number;
  public customerStatus: number;
  public SystemType: number;
  public HL7interface: number;
  public CustomerRCM: number;
  public OnDemand: number;
  public ApptReminders: number;
  public eLab: number;
  public MIPS: number;
}
