
<div class="col-xs-12" style="padding: 0; height: 100%;" *ngIf="this.type == 'SalesTool'">
  <div class="col-xs-12" style="padding:0 1%;">

    <div class="col-xs-12" style="padding:0;height:100%;margin-top:1%">
      <div class="col-xs-4 " style="padding: 0 0.5% 0 0;margin-bottom:1%;margin-bottom:0;height:100%;width:30%">
        <div class="col-xs-12" style="height:1%;padding:0;background-color:orangered;color:orangered">a</div>
          <div class="col-xs-12" style="height:4%;padding:0;background-color:#2A3740;">
            <table class="table table-striped" style="margin-bottom:0;color:white">
              <thead>
                <tr>
                  <th style="width:65%;text-align: center;">Categories</th>
                </tr>
              </thead>
            </table>
          </div>

          <div class="col-xs-12" style="padding:0;height:93%;border: 1px solid #efefef;">
            <div class="col-xs-12" style="padding:0;height:14%">
              <div class="col-xs-12" style="padding: 0%">
                <div class="col-xs-1"></div>
                <div class="col-xs-10" style="padding:0%">
                  <b>Category </b>
                </div>
              </div>
              <div class="col-xs-12" style="padding: 0%">
                <div class="col-xs-1" style="padding:0;">
                  <img (click)="AddCategory()" src="../../assets/img/Add Campaign.png" style="height: 21px;" *ngIf="CategoryAdd" />
                </div>
                <div class="col-xs-11 dropdown" style="padding:0%">
                  <div class="btn dropdown-toggle account-dropdown" data-toggle="dropdown" (click)="ShowHideDropdown('Category')" style="height: 34px;width:100%;">
                    <span id="selectedCatName" class="pull-left">Select Category </span>
                    <span class="caret" style="float:right;margin-top: 3%;"></span>
                  </div>
                  <div *ngIf="category" id="CategoryDropdown" class="banContent" [dragula]='"Category-bag"' style="height:400px;">

                    <div *ngFor="let rescategory of Resoponsecategorylist" style="height:33px;border-bottom:0.5px solid lightgray;padding:1%">
                      <span style="font-weight:600;cursor:pointer" (click)="appendLabel(rescategory,'Category');loadtaskitems(rescategory.id,'Category')" contenteditable="true"
                            id="{{rescategory.id}}~Category" [style.color]="(rescategory.id == selectedCategoryId)?'red':'black'">{{rescategory.itemName}}</span>

                      <span style="float:right;margin-right:2%"><i class="fa fa-trash" aria-hidden="true" (click)="Delete(rescategory.id,'Category')"></i></span>
                      <span style="float:right;margin-right:2%"><i class="fa fa-pencil" aria-hidden="true" (click)="EditCategory(rescategory.id,'Category')"></i></span>
                    </div>

                    <div class="col-xs-12" [hidden]="HideCategorytextBox" style="padding-left:0.5%;bottom:0;position:absolute;">
                      <input class="form-control placeholder-red text-red" [(ngModel)]="CategoryTextBox" id="CategoryTextBox" placeholder="Type in New Category here..." (keyup)="enableButtons('Category')">
                      <div class="col-xs-8" style="padding-left:0.5%;float:right">
                        <div class="col-xs-6" style="padding: 5px;">
                          <button class="btn btn-long btn-default" (click)="CancelButtons('Category')" style="width: 100%;">Cancel</button>
                        </div>
                        <div class="col-xs-6" style="padding: 1% 0 0 0;">
                          <button class="btn btn-long changeActive noprovider selectedType" (click)="SavewizardCategory()" style="width: 100%;background: #93d250;">Save</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>


            <div class="col-xs-12" style="padding: 0%; height: 14%; margin-top: 3%;">
              <div class="col-xs-1">

              </div>
              <div class="col-xs-10" style="padding:0%">
                <b>Sub Category 1</b>
              </div>
              <div class="col-xs-12" style="padding:0%">
                <div class="col-xs-1" style="padding:0;">
                  <img (click)="AddSubCategory1()" src="../../assets/img/Add Campaign.png" style="height: 21px;" *ngIf="SubCategory1Add" />
                </div>
                <div class="col-xs-11 subCat1dropdown" style="padding:0%">
                  <div class="btn dropdown-toggle account-dropdown" (click)="ShowHideDropdown('SubCategory1')" data-toggle="subCat1dropdown" style="width:100%;height:34px">
                    <span id="selectedSubCat1Name" class="pull-left">Select Sub Category 1</span>
                    <span class="caret" style="float:right;margin-top: 3%;"></span>
                  </div>
                  <div *ngIf="subcategory1" id="SubCategory1Dropdown" class="banContent" [dragula]='"SubCategory1-bag"' style="height:350px;">
                    <div *ngFor="let sub of ResoponseSubcategorylist" style="height:33px;border-bottom:0.5px solid lightgray;padding:1%">

                      <span style="font-weight:600;cursor:pointer" (click)="appendLabel(sub,'SubCategory1');loadtaskitems(sub.id,'SubCategory1')"
                            id="{{sub.id}}~SubCategory1" [style.color]="(sub.id == selectedSubCategory1Id)?'red':'black'">{{sub.itemName}}</span>

                      <span style="float:right;margin-right:2%"><i class="fa fa-trash" aria-hidden="true" (click)="Delete(sub.id,'SubCategory1')"></i></span>
                      <span style="float:right;margin-right:2%"><i class="fa fa-pencil" aria-hidden="true" (click)="EditCategory(sub.id,'SubCategory1')"></i></span>
                    </div>
                    <div *ngIf="subcategory1 && (ResoponseSubcategorylist.length == 0)" class="banContent" id="SubCategory1Dropdown" [dragula]='"SubCategory1-bag"'>
                      <div style="height:25px;border-bottom:0.5px solid lightgray;padding-left:1%">
                        <span style="font-weight:600;cursor:pointer;color:red" id="0~SubCategory1">No Sub Category Available</span>
                      </div>

                    </div>
                    <div class="col-xs-12" [hidden]="HideCategory1TextBox" style="padding-left:0.5%;position:absolute;bottom:0;">
                      <input class="form-control placeholder-red text-red" [(ngModel)]="subCategoryTextBox1" id="subCategoryTextBox1" placeholder="Type in New Category here..." (keyup)="enableButtons('SubCategory1')">
                      <div class="col-xs-12" style="padding-left:0.5%;">
                        <div class="col-xs-6" style="padding: 5px;">
                          <button class="btn btn-long btn-default" (click)="CancelButtons('SubCategory1')" style="width: 100%;">Cancel</button>
                        </div>
                        <div class="col-xs-6" style="padding: 1% 0 0 0;">
                          <button class="btn btn-long changeActive noprovider selectedType" (click)="SaveSubCategory('SubCategory1')" style="width: 100%;background: #93d250;">Save</button>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

            </div>


            <div class="col-xs-12" style="padding: 0%; height: 14%; margin-top: 3%;">
              <div class="col-xs-12" style="padding: 0%;">
                <div class="col-xs-1">

                </div>
                <div class="col-xs-10" style="padding:0%">
                  <b>Sub Category 2 </b>
                </div>
              </div>
              <div class="col-xs-12" style="padding:0%">
                <div class="col-xs-1" style="padding:0;">
                  <img (click)="AddsubCategory2()" src="../../assets/img/Add Campaign.png" style="height: 21px;" *ngIf="SubCategory2Add" />
                </div>
                <div class="col-xs-11 subCat2dropdown" style="padding:0%">
                  <div class="btn dropdown-toggle account-dropdown" (click)="ShowHideDropdown('SubCategory2')" data-toggle="subCat2dropdown" id="subCat2dropdown" style="width:100%;height:34px">
                    <span id="selectedSubCat2Name" class="pull-left">Select Sub Category 2 </span>
                    <span class="caret" style="margin-left: 70%;"></span>
                  </div>

                  <div id="SubCategory2Dropdown" *ngIf="subcategory2" class=" banContent" [dragula]='"SubCategory2-bag"' style="height:300px;">
                    <div *ngFor="let sub of ResoponseSubcategory2list " style="height:33px;border-bottom:0.5px solid lightgray;padding:1%">
                      <span style="font-weight:600;cursor:pointer" (click)="appendLabel(sub,'SubCategory2');loadtaskitems(sub.id,'SubCategory2')"
                            id="{{sub.id}}~SubCategory2" [style.color]="(sub.id == selectedSubCategory2Id)?'red':'black'">{{sub.itemName}}</span>

                      <span style="float:right;margin-right:2%"><i class="fa fa-trash" aria-hidden="true" (click)="Delete(sub.id,'SubCategory2')"></i></span>
                      <span style="float:right;margin-right: 2%"><i class="fa fa-pencil" aria-hidden="true" (click)="EditCategory(sub.id,'SubCategory2')"></i></span>
                    </div>

                    <div *ngIf="(ResoponseSubcategory2list && ResoponseSubcategory2list.length == 0)" class="banContent" id="SubCategory2Dropdown" [dragula]='"SubCategory2-bag"'>
                      <div style="height:25px;border-bottom:0.5px solid lightgray;padding-left:1%">
                        <span style="font-weight:600;cursor:pointer;color:red"
                              id="0~SubCategory2">No Sub Category Available</span>

                      </div>

                    </div>
                    <div class="col-xs-12" [hidden]="HideCategory2TextBox" style="padding-left:0.5%;position:absolute;bottom:0;">
                      <input class="form-control placeholder-red text-red" [(ngModel)]="subCategoryTextBox2" id="subCategoryTextBox2" placeholder="Type in New Category here..." (keyup)="enableButtons('SubCategory2')">
                      <div class="col-xs-12" style="padding-left:0.5%;">
                        <div class="col-xs-6" style="padding: 5px;">
                          <button class="btn btn-long btn-default" (click)="CancelButtons('SubCategory2')" style="width: 100%;">Cancel</button>
                        </div>
                        <div class="col-xs-6" style="padding: 1% 0 0 0;">
                          <button class="btn btn-long changeActive noprovider selectedType" (click)="SaveSubCategory2('SubCategory2')" style="width: 100%;background: #93d250;">Save</button>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>

              </div>

            </div>

            <div class="col-xs-12" style="height:50%"></div>
            <div class="col-xs-12" style="height:7%;text-align:center;padding:3% 0 0 0"><label style="text-align: center;font-size: 15px;font-weight: 200;cursor:pointer;text-decoration:underline;" (click)="ClearAllFields()">CLEAR ALL CATEGORIES</label></div>
          </div>

        </div>

      <div class="col-xs-4" style="height:100%;padding:0 0.5% 0 0;width:35%" id="QuestionsDiv" (click)="divClick($event)">
        <div class="col-xs-12" style="height:1%;padding:0;background-color:orangered;color:orangered">a</div>
        <div class="col-xs-12" style="height:4%;padding:0;background-color:#2A3740;">
          <table class="table table-striped" style="margin-bottom:0;color:white">
            <thead>
              <tr>
                <th style="width:65%;text-align: center;">Statements</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="col-xs-12" style="padding:0;height:94%;border: 1px solid #efefef;">
          <div class="col-xs-12" style="height:4.5%;">
            <div class="col-xs-1" style="float:right;text-align:right;padding:0;" *ngIf="type=='SalesTool'">
              <img (click)="edit(0,'todoTasktype')" src="../../assets/img/Add Campaign.png" style="height: 21px;" />
            </div>
          </div>
          <div class="col-xs-12" style="height:85%">
            <table id="todoTable" class='table table-striped sortable ' [dragula]='"Questions-bag"' style="margin-bottom:0;">

              <tbody *ngFor="let tasktype of Questionslist; let i = index">
                <tr class="Tr" id="{{tasktype.id}}" style="height:35px !important;cursor:move">
                  <td id="{{tasktype.id}}todoTaskID" style="border: 1px solid #A6A4A4;display:none;">{{tasktype.id}}</td>
                  <td style="border: 1px solid #A6A4A4"><i class="fa fa-th" style="color: gray; padding-right: 2%;"></i></td>
                  <td (click)="SelectedQuestion(tasktype.id,'Click')" id="{{tasktype.id}}todoTasktype"
                      class="disableEditable" style="font-size: 13px;width: 90%; border: 1px solid #A6A4A4 "
                      (keyup)="keyupTask(tasktype.id,i,'todo')" innerHTML="{{tasktype.itemName}}">
                    {{tasktype.itemName}}{{tasktype.id}}
                  </td>
                  <td *ngIf="(type=='SalesTool')" id="{{tasktype.id}}todoTasktypeorder" style="border: 1px solid #A6A4A4;padding:2%;height:34px;width:10%">
                    <span *ngIf="tasktype.itemName == ''" [hidden]="toDoNew"></span>
                    <span *ngIf="tasktype.itemName != ''">
                      <span *ngIf="tasktype.isdeleted==0"><button (click)="ToDoDelete(tasktype.id,'1')" style="border: none" class="btn-xs btn-default"><i style="font-weight: 400;font-size: 14px;" class="fa fa-trash" aria-hidden="true"></i></button></span>

                      <span *ngIf="tasktype.isdeleted!=0"> <input type="button" (click)="ToDoDelete(tasktype.id,'0')" value="A" class="pull-right" style="color:green" /></span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-xs-8" style="padding:0;float:right;height:7%">
              <table class="table table-striped" style="margin-bottom:0;">
                <tr [hidden]="ButtonsHide">
                  <td style="padding-left:0; padding-top:0; "> <button class="btn btn-long btn-default" (click)="canceltodotasktype('todo')" style="width: 100%;">Cancel</button></td>
                  <td style="padding-right:0;padding-top:0; "><button type="submit" class="btn btn-long changeActive noprovider selectedType" (click)="SaveWizardQuestions()" style="width: 100%;background: #93d250;">Save</button></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-4" style="padding: 0px; height:100%;width:35%">
        <div class="col-xs-12" style="height:1%;padding:0;background-color:orangered;color:orangered">a</div>
        <div class="col-xs-12" style="height:4%;padding:0;background-color:#2A3740;">
          <table class="table table-striped" style="margin-bottom:0;color:white">
            <thead>
              <tr>
                <th style="width:65%;text-align: center;">Responses</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="col-xs-12" style="padding:0;height:94%;border: 1px solid #dddddd;">
          <div class="col-xs-12" style="height:4.5%;padding:0;">

            <div class="col-xs-2" style="padding:0 2% 0 0;height:100%;float:right;text-align:right">
              <img src="../../assets/img/Add Campaign.png" style="height: 21px;" (click)="addResponse()" />
            </div>
          </div>
          <div class="col-xs-12" style="height:86%;padding:0;">
            <table id="responseTable" class='table table-striped sortable ' dragula="Responses_bag">

              <tbody *ngFor="let responses of ResponseList; let i = index">
                <tr class="resTr" id="resTr{{responses.id}}" style="cursor:move">
                  <td id="{{responses.id}}responseID" style="border: 1px solid #A6A4A4;display:none;">{{responses.id}}</td>
                  <td *ngIf="responses.isdeleted==0">
                    <div class="col-xs-12" style="padding:0">
                      <div class="col-xs-3" style="padding:0">
                        <i class="fa fa-th" style="color: gray; padding-right: 5%;"></i>
                        <label style="text-decoration: underline;">
                          Response :
                        </label>
                      </div>
                      <div *ngIf="type =='SalesTool'" class="col-xs-1" style="padding:0%;width:5%">
                        <i class="fa fa-bold" aria-hidden="true" (click)="AddStyle('Bold',responses.id,1)"></i>
                      </div>
                      <div *ngIf="type =='SalesTool'" class="col-xs-1" style="padding:0%;width:5%">
                        <span aria-hidden="true" style="height:5%"
                              (click)="AddStyle('Bold',responses.id,0)">U</span>
                      </div>
                      <div class="col-xs-2" style="float:right">
                        <button *ngIf="(type=='SalesTool')" (click)="deleterespose(responses.id)" class="btn-xs btn-default"
                                style="border: none;float:right">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>

                    <div>
                      <p style="color:black;display:none" id="{{responses.id}}boldContent">{{responses.isbolded}}</p>
                      <p (click)="SelectedResonse(responses.id)" *ngIf="type =='SalesTool' "
                         contenteditable="true" [ngStyle]="{'font-weight': responses.isbolded ? 'bold' :'none'}" style="color:black;" id="{{responses.id}}EditContent"
                         innerHTML="{{responses.response}}"></p>
                      <p (click)="SelectedResonse(responses.id)"
                         *ngIf="type !='SalesTool'" contenteditable="false"
                         style="color:black" id="{{responses.id}}EditContent" innerHTML="{{responses.response}}"></p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-xs-8" style="padding:0;height:7%;float:right">
            <table class="table table-striped" style="margin-bottom:0;height:6%" *ngIf="SelectedResponseEditButtons">
              <tr>
                <td style="padding-left:0; padding-top:0; ">
                  <button class="btn btn-long btn-default" style="width: 100%;" (click)="cancelEditResponse()">Cancel</button>
                </td>
                <td style="padding-right:0;padding-top:0; ">
                  <button type="button" class="btn btn-long changeActive noprovider selectedType" style="width: 100%;background: #93d250;" (click)="SaveEditResponse()">Save</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <app-dialog [(visible)]="responseshowDialog" id="responseshowDialog">
          <div class="col-xs-12" style="padding: 0;height:95%" id="noteidDiv">
            <div id="noteid" [froalaEditor]="editorOptions" [(froalaModel)]="notedescription" style="padding-right: 0%; margin-top: 0.5%; height: 77%;"></div>
          </div>
          <div class="col-xs-12" id="saveNotesButtons">
            <div class="col-xs-8">

            </div>
            <div class="col-xs-4">
              <button class="btn cancel" (click)="CancelResponse()" type="submit" style="padding: 0.75%;border: 1px solid;width:50%;background-color:#dddddd; font-weight:normal">Cancel</button>
              <button class="btn provider saveButton" (click)="SaveResponse()" type="submit" style="margin-left: 0.2%;padding: 0.75%;width: 49%; font-weight:normal">Save</button>

            </div>
          </div>
        </app-dialog>
      </div>
      </div>


  </div>


</div>

<div class="col-xs-12" style="padding: 0; height: 100%;" *ngIf="type == 'TaskList'">
  <div class="col-xs-4" style="padding:0.4%;">
    <div class="col-xs-12" style="padding:0;">
      <div class="col-xs-12 mainactiveButton1" style="margin-bottom: 0%; padding: 0; text-align: center; background: #2A3740; color: white; height: 6%; padding-top: 1%; border: 1px solid rgb(186,219,152); width: 99.7%">
        <label style="padding-left: 14px;">Categories</label>
      </div>
    </div>

    <div class="col-xs-12" style="padding:0;margin-top:0.8%;height:7%">
      <div class="col-xs-3 removePadding"><label>Category </label></div>
      <div class="col-xs-9 removePadding">
        <div class="col-xs-11" style="padding:0;">

          <div class="dropdown" style="width:100%">
            <div class="btn dropdown-toggle account-dropdown" (click)="ShowHidePopDropdown('Category')" style="width:100%;height:34px">
              <span id="selectedpopupCatName" class="pull-left">Select Category</span>
              <span class="caret" style="float:right;margin-top: 3%;"></span>
            </div>
            <div *ngIf="popcategory" class="banContent" id="CategoryDropdown" [dragula]='"Category-bag"'>
              <div *ngFor="let rescategory of Resoponsecategorylist" style="height:25px;border-bottom:0.5px solid lightgray;padding-left:1%">
                <span style="font-weight:600;cursor:pointer" (click)="popappendLabel(rescategory,'Category');Poploadtaskitems(rescategory.id,'Category')"
                      id="{{rescategory.id}}~Category" [style.color]="(rescategory.id == selectedCategoryId)?'red':'black'">{{rescategory.itemName}}</span>

              </div>

            </div>
          </div>
        </div>
        <div class="col-xs-1" style="padding:0;margin-top:1%">
          <input class="pull-right closeButton" style="display: block;" type="button" value="X" id="clearCategory" (click)="clearCategory('clearCategory')">
        </div>
      </div>
    </div>

    <div class="col-xs-12" style="padding:0;margin-top:5%;height:7%">
      <div class="col-xs-3 removePadding"><label>Sub Category 1 </label></div>
      <div class="col-xs-9 removePadding">
        <div class="col-xs-11" style="padding:0;">

          <div class="subCat1dropdown" style="width:100%">
            <div class="btn dropdown-toggle account-dropdown" (click)="ShowHidePopDropdown('SubCategory1')" data-toggle="subCat1dropdown" style="width:100%;height:34px">
              <span id="selectedpopupSubCat1Name" class="pull-left">Select Sub Category 1</span>
              <span class="caret" style="float:right;margin-top: 3%;"></span>
            </div>
            <div *ngIf="popsubcategory1 && (ResoponseSubcategorylist && ResoponseSubcategorylist.length > 0)" class="subCat1dropdown-content" id="SubCategory1Dropdown" [dragula]='"SubCategory1-bag"'>
              <div *ngFor="let sub of ResoponseSubcategorylist" style="height:25px;border-bottom:0.5px solid lightgray;padding-left:1%">
                <span style="font-weight:600;cursor:pointer" (click)="popappendLabel(sub,'SubCategory1');loadtaskitems(sub.id,'SubCategory1')"
                      id="{{sub.id}}~SubCategory1" [style.color]="(sub.id == selectedSubCategory1Id)?'red':'black'">{{sub.itemName}}</span>
              </div>
            </div>
            <div *ngIf="popsubcategory1 && (ResoponseSubcategorylist && ResoponseSubcategorylist.length == 0)" class="subCat1dropdown-content" id="SubCategory1Dropdown" [dragula]='"SubCategory1-bag"'>
              <div style="height:25px;border-bottom:0.5px solid lightgray;padding-left:1%">
                <span style="font-weight:600;cursor:pointer;color:red" id="0~SubCategory1">No Sub Category Available</span>
              </div>

            </div>
          </div>

        </div>
        <div class="col-xs-1" style="padding:0;margin-top:1%">
          <input class="pull-right closeButton" style="display: block;" type="button" value="X" id="clearCategory1" (click)="clearCategory('clearCategory1')">
        </div>
      </div>
    </div>


    <div class="col-xs-12" style="padding:0;margin-top:5%;height:7%">
      <div class="col-xs-3 removePadding"><label>Sub Category 2 </label></div>
      <div class="col-xs-9 removePadding">
        <div class="col-xs-11" style="padding:0;">

          <div class="subCat2dropdown" style="width:100%">
            <div class="btn dropdown-toggle account-dropdown" (click)="ShowHidePopDropdown('SubCategory2')" data-toggle="subCat2dropdown" style="width:100%;height:34px">
              <span id="selectedpopupSubCat2Name" class="pull-left">Select Sub Category 2 </span>
              <span class="caret" style="margin-left: 70%;"></span>
            </div>
            <div *ngIf="popsubcategory2 && (ResoponseSubcategory2list && ResoponseSubcategory2list.length > 0)" class="subCat2dropdown-content" id="SubCategory2Dropdown" [dragula]='"SubCategory2-bag"'>
              <div *ngFor="let sub of ResoponseSubcategory2list" style="height:25px;border-bottom:0.5px solid lightgray;padding-left:1%">
                <span style="font-weight:600;cursor:pointer" (click)="popappendLabel(sub,'SubCategory2');loadtaskitems(sub.id,'SubCategory2')"
                      id="{{sub.id}}~SubCategory2" [style.color]="(sub.id == selectedSubCategory2Id)?'red':'black'">{{sub.itemName}}</span>

              </div>

            </div>

            <div *ngIf="popsubcategory2 && (ResoponseSubcategory2list && ResoponseSubcategory2list.length == 0)" class="subCat2dropdown-content" id="SubCategory2Dropdown" [dragula]='"SubCategory2-bag"'>
              <div style="height:25px;border-bottom:0.5px solid lightgray;padding-left:1%">
                <span style="font-weight:600;cursor:pointer;color:red"
                      id="0~SubCategory2">No Sub Category Available</span>

              </div>

            </div>
          </div>
        </div>
        <div class="col-xs-1" style="padding:0;margin-top:1%">
          <input class="pull-right closeButton" style="display: block;" type="button" value="X" id="clearCategory2" (click)="clearCategory('clearCategory2')">
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-4" style="padding:0.4%;">
    <div class="col-xs-12" style="padding:0;">
      <div class="col-xs-12 mainactiveButton1" style="margin-bottom: 0%; padding: 0; text-align: center; background: #2A3740; color: white; height: 6%; padding-top: 1%; border: 1px solid rgb(186,219,152); width: 99.7%">
        <label style="padding-left: 14px;">Statements</label>
      </div>
    </div>
    <div class="col-xs-12 scroll-c" style="padding:0.5%;height:100%">
      <table id="todoTable" class='table table-striped sortable ' [dragula]='"Questions-bag"' style="margin-bottom:0;">

        <tbody *ngFor="let tasktype of Questionslist; let i = index">
          <tr class="Tr" id="{{tasktype.id}}" style="height:35px !important;">
            <td id="{{tasktype.id}}todoTaskID" style="border: 1px solid #A6A4A4;display:none;">{{tasktype.id}}</td>
            <td (click)="SelectedQuestion(tasktype.id,'Click')" id="{{tasktype.id}}todoTasktypePopup" class="disableEditable"
                style="font-size: 13px; width: 80%; border: 1px solid #A6A4A4"
                (keyup)="keyupTask(tasktype.id,i,'todo')" innerHTML="{{tasktype.itemName}}">
              {{tasktype.itemName}}
            </td>

          </tr>
        </tbody>
      </table>
    </div>

  </div>
  <div class="col-xs-4" style="padding:0.4%;">
    <div class="col-xs-12" style="padding:0;">
      <div class="col-xs-12 mainactiveButton1" style="margin-bottom: 0%; padding: 0; text-align: center; background: #2A3740; color: white; height: 6%; padding-top: 1%; border: 1px solid rgb(186,219,152); width: 99.7%">
        <label style="padding-left: 14px;">Responses</label>
      </div>
    </div>
    <div class="col-xs-12 scroll-c" style="padding:0.5%;height:100%;">
      <table id="responseTable" class='table table-striped sortable ' dragula="Responses_bag">

        <tbody *ngFor="let responses of ResponseList; let i = index">
          <tr class="resTr" id="PoPUpresTr{{responses.id}}">
            <td id="{{responses.id}}responseID" style="border: 1px solid #A6A4A4;display:none;">{{responses.id}}</td>
            <td>
              <div style="font-size: 13px;">
                <p *ngIf="responses.isdeleted==1" style="color:red">{{responses.response}} </p>
                <p (click)="SelectedResonse(responses.id)" *ngIf="responses.isdeleted==0 " style="color:black" innerHTML="{{responses.response}}"></p>

              </div>
            </td>

          </tr>
        </tbody>
      </table>
    </div>

  </div>

</div>
<app-dialog [(visible)]="DeleteConfirmationPopup" *ngIf="DeleteConfirmationPopup" id="DeleteConfirmationPopup" [closable]="false">
  <app-delete-popup [status]="0" [title]="'RESPONSE WIZARD'" [description]="'Select OK to DELETE'"
                    [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true"
                    (sendMsgEvent)="ReceiveDeleteevent($event)">
  </app-delete-popup>
</app-dialog>
<app-dialog [(visible)]="DeleteResponseConfirmationPopup" *ngIf="DeleteResponseConfirmationPopup"
            id="DeleteResponseConfirmationPopup" [closable]="false">
  <app-delete-popup [status]="0" [title]="'RESPONSE WIZARD'" [description]="'Select OK to DELETE'"
                    [isok]="true" [issave]="false" [isdiscard]="false" [iscancel]="true"
                    (sendMsgEvent)="ReceiveresponseDeleteevent($event)">
  </app-delete-popup>
</app-dialog>
<script>
  new FroalaEditor('#froala-editor', {
    colorsText: [
      'white'
    ]
  });
</script>
