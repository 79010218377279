<div class="col-xs-12" style="padding: 0; height: 100%;">
    <div class="col-xs-12" style="padding:0;height:100% " *ngIf="accessCategoriesDiv">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Categories</span>
      </h4>
      <div class="col-xs-7" style="padding:0;height:93%">
        <div class="col-xs-12" style="height:7%;padding:0;">
          <div class="col-xs-6" style="text-align:left;padding:0.5% 0 0 0;height:100%">
            <img src="../../../assets/img/Create New Document Name.png" (click)="CreateNewCategory()" style="height:30px" />
          </div>

        </div>
        <div class="col-xs-12" style="padding:0;height:93%;border:1px solid grey">
          <ag-grid-angular style="height:100%" class="ag-theme-balham"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="allProposalCategory"
                           [rowDragManaged]="true"
                           [suppressMoveWhenRowDragging]="true"
                           [sideBar]="sideBar"
                           [animateRows]="true"
                           (rowDragEnd)="onRowDragEnd($event)"
                           (selectionChanged)="selectionChanged($event)"
                           (rowSelected)="rowClickAgGrid($event)"
                           (gridReady)="onAgGridReady($event,'Category')">
          </ag-grid-angular>
        </div>
      </div>

      <div class="col-xs-5" style="padding:0;height:93%">
        <form #AddCategoryForm="ngForm" style="height:100%">
          <div class="col-xs-12" style="height:6%;padding:0;">

          </div>
          <div class="col-xs-12" style="height:12%;margin: 2% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="height:100%;padding:0;"><label> Category</label></div>
            <div class="col-xs-9" style="height:100%;padding:0;"><textarea name="ProposalCategory.CategoryName" [(ngModel)]="ProposalCategory.CategoryName" [required]="createNewCategory" [disabled]="!createNewCategory" style="width:100%;height:100%;"></textarea></div>
          </div>
          <div class="col-xs-12" style="height:6%;margin:1% 2% 2% 5%;padding:0;width:95%">
            <div class="col-xs-2" style="padding:0;height:100%"><label>Sort Order</label></div>
            <div class="col-xs-9" style="padding:0;height:100%"><input type="text" [(ngModel)]="ProposalCategory.SortOrder" name="ProposalCategory.SortOrder" [disabled]="!createNewCategory" [required]="createNewCategory" style="width:100%;height:100%" /></div>
          </div>
          <div class="col-xs-12" style="padding:0;height:60%">

          </div>
          <div class="col-xs-12" style="padding:0;margin-top:2%;height:6%">
            <div class="col-xs-7"></div>
            <div class="col-xs-2" style="display:block;padding:0">
              <button class="btn noprovider" style="width:100%;background: lightgray;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="ClearCategory()">Cancel</button>
            </div>
            <div class="col-xs-2 pull-right" style="display:block;padding:0;margin-right:1%">
              <button class="btn noprovider" (click)="SaveProposalCategory()" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" [disabled]="(!AddCategoryForm.form.valid)">Save</button>
            </div>
          </div>
        </form>

      </div>
    </div>

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="accessItemsDiv">
      <h4 class="fontStyleCss" style="margin-top:0">
        <span style="text-transform: uppercase; margin-left: 1%">Items</span>
      </h4>
      <div class="col-xs-8" style="height:93%">
        <div class="col-xs-12" style="height:7%;padding:0;">
          <div class="col-xs-6" style="text-align:left;padding:0.5% 0 0;height:100%">

            <img src="../../../assets/img/Create New Document Name.png" (click)="CreateNewItem()" style="height:30px" />
          </div>
          <div class="col-xs-6" style="padding:0;text-align:right">
                <select class="form-control" id="ProposalCategoryId" (change)="loadproposals($event.target.value)">
                  <option value="0">All Categories</option>
                  <option value="{{cat.CategoryId}}" *ngFor="let cat of allProposalCategory">
                    {{cat.CategoryName}} 
                  </option>
                </select>
          </div>
        </div>
          <div class="col-xs-12" style="padding:0;height:93%">
            <ag-grid-angular style="height:100%" class="ag-theme-balham"
                             [gridOptions]="gridProposalOptions"
                             [columnDefs]="columnDefsForItems"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="allProposalItems"                           
                             [sideBar]="sideBar"
                             [animateRows]="true"
                             (selectionChanged)="onSelectionChanged($event)"
                             (gridReady)="onAgGridReady($event,'Item')">
            </ag-grid-angular>

          </div>
       
      </div>
      <div class="col-xs-4" style="padding:0;height:93%">
        <form #AddItemsForm="ngForm" style="height:100%">
          <div class="col-xs-12" style="height:12%;margin-top:2%;padding: 0;">
            <div class="col-xs-2" style="text-align:right;height:100%;padding: 0;">
              Items
            </div>
            <div class="col-xs-9" style="height:100%;width: 79%;padding: 0 0 0 3%;">
              <textarea class="form-control" style="width:100%;height:100%;" name="proposalItems.ProposalItemName" [(ngModel)]="proposalItems.ProposalItemName" [disabled]="!createNewitem" [required]="createNewitem"></textarea>
            </div>
          </div>
          <div class="col-xs-12" style="height:6%;margin-top:1%;padding: 0;">
            <div class="col-xs-2" style="padding:0;text-align:right;height:100%">Category</div>
            <div class="col-xs-9" style="height:100%;padding: 0 0 0 3%;width: 79%;">
              <select class="form-control" [required]="createNewitem" name="proposalItems.Categoryids" [disabled]="!createNewitem" [(ngModel)]="proposalItems.Categoryids">
                <option value="0" disabled>Please Select Category</option>
                <option value="{{cat.CategoryId}}" *ngFor="let cat of allProposalCategory">{{cat.CategoryName}}</option>
              </select>
            </div>
          </div>
          <div class="col-xs-12" style="height:47%;margin-top:2%;padding:0;">
            <div class="col-xs-2" style="text-align:right;height:100%;padding: 0;">Description</div>
            <div class="col-xs-9" style="height:100%;padding:0 0 0 3%;width:79%">
              <textarea class="form-control" style="width:100%;height:100%" id="ItemDescription" name="proposalItems.ItemDescription" [disabled]="!createNewitem" [(ngModel)]="proposalItems.ItemDescription" (keyup)="KeyUp('Description')"></textarea>
            </div>
          </div>

          <div class="col-xs-12" style="height:6%;margin-top:1%;padding: 0;">
            <div class="col-xs-2" style="padding:0;text-align:right;height:100%">Sort Order</div>
            <div class="col-xs-9" style="height:100%;padding: 0 0 0 3%;width: 79%;">
              <input class="form-control" type="text" [required]="createNewitem" [disabled]="!createNewitem" name="proposalItems.SortOrder" [(ngModel)]="proposalItems.SortOrder" />
            </div>
          </div>
          <div class="col-xs-12" style="height:6%;margin-top:1%;padding: 0;">
            <div class="col-xs-2" style="padding:0;text-align:right;height:100%">Price</div>
            <div class="col-xs-9" style="height:100%;padding:0 0 0 3%;width:79%;">
              <div class="col-xs-4" style="padding-left:0;height:100%">
                <input class="form-control" type="text" [required]="createNewitem" [disabled]="!createNewitem" name="proposalItems.Price12" [(ngModel)]="proposalItems.Price12" placeholder="12Months" />
              </div>
              <div class="col-xs-4" style="padding:0;height:100%">
                <input class="form-control" type="text" [required]="createNewitem" [disabled]="!createNewitem" name="proposalItems.Price24" [(ngModel)]="proposalItems.Price24" placeholder="24Months" />
              </div>
              <div class="col-xs-4" style="padding-right:0;height:100%">
                <input class="form-control" type="text" [required]="createNewitem" [disabled]="!createNewitem" name="proposalItems.Price36" [(ngModel)]="proposalItems.Price36" placeholder="36Months" />
              </div>

            </div>
          </div>

          <div class="col-xs-12" style="height:6%;margin-top:1%;padding: 0;">
            <div class="col-xs-2" style="padding:0;text-align:right;height:100%">Monthly</div>
            <div class="col-xs-9" style="height:100%;padding:0 0 0 3%;width:79%;">
              <div class="col-xs-4" style="padding-left:0;height:100%">
                <input class="form-control" type="text" [required]="createNewitem" id="Monthly12" name="proposalItems.Monthly12" [disabled]="!createNewitem" [(ngModel)]="proposalItems.Monthly12" (keyup)="KeyUp('Monthly12')" placeholder="12Months"  />
              </div>
              <div class="col-xs-4" style="padding:0;height:100%">
                <input class="form-control" type="text" id="Monthly24" name="proposalItems.Monthly24" [disabled]="!createNewitem" [(ngModel)]="proposalItems.Monthly24" (keyup)="KeyUp('Monthly24')" placeholder="24Months" [required]="createNewitem" />
              </div>
              <div class="col-xs-4" style="padding-right:0;height:100%">
                <input class="form-control" type="text" id="Monthly36" name="proposalItems.Monthly36" [disabled]="!createNewitem" [(ngModel)]="proposalItems.Monthly36" (keyup)="KeyUp('Monthly36')" placeholder="36Months" [required]="createNewitem" />
              </div>

            </div>
          </div>

          <div class="col-xs-4" style="padding: 0; height: 10%; float: right; margin-right: 2%;">
            <div class="col-xs-12" style="height: 80%; margin-top: 6%; padding: 0; float: right;">

              <div class="col-xs-5" style="display:block;padding:0">
                <button class="btn noprovider" style="width:100%;color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" (click)="clearItemFields()">Cancel</button>
              </div>
              <div class="col-xs-5 pull-right" style="display:block;padding:0;margin-right:1%">
                <button class="btn noprovider" style="width:100%;background: rgb(162, 228, 119);color: black;font-weight: 700;border: 1px solid #000;border-radius: 3px;" [disabled]="(!AddItemsForm.form.valid)" (click)="SaveProposalItem()">Save</button>
              </div>
            </div>
          </div>

        </form>
      </div>
    
  </div>

</div>
