<router-outlet></router-outlet>

  <app-dialog [(visible)]="TimeOutDialog" id="TimeOutDialog" *ngIf="TimeOutDialog" [closable]="false">
    <div class="col-md-12" *ngIf="TimeOutDialog" style="padding:0;margin-top:0%;">
      <div class="col-xs-12" style="font-size:18px;color:#949494">
        <div class="col-xs-12" style="padding:0; border-bottom:1px solid #949494">
          <div class="col-xs-7">
            <label style="font-size:30px;margin:5% 0 0 5%">Session Timeout 8310380991</label>
          </div>
          <label style="color:white;margin-top:10%;background-color:black;width:20%;text-align:center">{{count}}</label>
          <span style="float:right;font-size:20px;margin:5%" (click)="StaySignedIn()">
            <label><i class="fa fa-times" aria-hidden="true"></i></label>
          </span>

        </div>
        <div class="col-xs-12" style="padding:4%; border-bottom:1px solid #949494;font-size:19px;">
          <div style="color:#949494"><label>You're being timed out due to inactive with SalesXCRM.</label></div>
          <div style="color:#949494;margin-top:2%"><label>Please click below to stay signed into SalesXCRM.</label></div>

          <div style="color:#949494;margin-top:2%"><label>Otherwise, you will be logged off automatically. </label></div>
        </div>
        <br /><br /><br />

        <div class="col-xs-10 pull-right" style=" margin-top:4%;margin-bottom:2%">
          <button class="btn" style="width:27%;background-color:#fbfbfb;height: 20%;font-weight:600; border: 2px solid #cecccc !important;color:#949494;border-radius: 10px;margin-left: 20%;font-size:18px;" (click)="AgreeSignout()"> Log Off  </button>
          <button class="btn" style="width:48%;background-color:#92d050;height: 20%; font-size:18px;font-weight:600;color:#fbfbfb;margin-left:2%;border:1.3px solid black !important;border-radius: 10px;" (click)="StaySignedIn()">Stay Signed in</button>



        </div>

      </div>
    </div>
  </app-dialog>
