import { Component, OnInit, ViewContainerRef, ElementRef, Optional, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { CustomerService } from './../../services/customer.service';
import { LeadsService } from './../../services/leads.service';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { Contacts } from '../../models/contacts';
import { TaskHistory } from '../../models/task-history';
import { Opportunities } from '../../models/opportunities';
import { SalesProcessHistory } from '../../models/sales-process-history';
import { DatePipe, Location } from '@angular/common';
import { Dropdowntype, Dropdown } from '../../models/dropdowntype';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { Column } from 'ag-grid';
import { TaskComponent } from '../../application/task/task.component';
import { NotesrendererComponent } from '../../application/notesrenderer/notesrenderer.component';
import { Accounts } from '../../models/accounts';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
declare var require: any;
import { ApplicationComponent } from '../application.component';
import { NotesComponent } from '../notes/notes.component';
import { Notes } from 'src/app/models/notes';
import { SalesStages } from 'src/app/models/SalesStages';
import { UserService } from 'src/app/services/user.service';
import { AccountsService } from 'src/app/services/accounts.service';
import { Viewaccount } from '../../models/viewaccount';
import { OwnershipsharingComponent } from '../ownershipsharing/ownershipsharing.component';
import { StatusItems } from '../../models/StatusItems';
import { HistoryComponent } from '../history/history.component';
import { AccountDetailsComponent } from '../account-details/account-details.component';
import { DialogComponent } from '../dialog/dialog.component';
declare var $: any;

@Component({
  selector: 'leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.css']
})
export class LeadsComponent implements OnInit {
  @Input()
  contactid: number = 0;
  @Input()
  id: number = 0;
  saleprocessform: FormGroup;
  opprtunityform: FormGroup;
  AccountIdArr: Dropdowntype[] = [];
  taskhistoryform: FormGroup;
  public columnDefs: any[];
  //notehgtlead = screen.height * 0.178;
  AccountIdsDialog: boolean = false;
  showsalesDialog: boolean = false;
  notesshowDialog = false;
  SaveLeadDialog = false;
  taskhistorynotes: Array<Notes> = [];
  salestagenotes: Array<Notes> = [];
  salependingnotes: Array<Notes> = [];
  detailsTaskhistory = [];
  contact: Contacts = new Contacts();
  notes: Notes = new Notes();
  taskhistory: TaskHistory = new TaskHistory();
  saleprocesshistory: SalesProcessHistory = new SalesProcessHistory();
  opportunities: Opportunities = new Opportunities();
  Salesstageslist: Dropdowntype[] = [];
  Tasktypelist: Dropdowntype[] = [];
  TaskStatuslist: Dropdowntype[] = [];
  public gridOptionsnotes: GridOptions;
  public gridOptions: GridOptions;
  itemResource: any;
  showDialog = false;
  contactitemResource: any;
  items = [];
  itemCount = 0;
  params: any;
  SalesitemResource: any;
  userprevilages: any;
  Salesitems = [];
  SalesitemCount = 0;
  salesparams: any;
  itemList = [];
  stagecompletedList = [];
  selectedItems = [];
  Contactitems = [];
  settings = {};
  TaskStatussettings = {};
  stagecompletedsettings = {};
  TaskId = 0;
  selectedStagesDisable = new Array();
  ContactitemCount = 0;
  FeeAmountdiv: boolean = true;
  FeeAmountdiv1: boolean = true;
  datePipe: DatePipe = new DatePipe("en-US");
  typeOfLead = 0;
  nextId = 0;
  salesSelectedId = 0;
  scheduleTaskType: string;
  selectedTaskType: string;
  DealStatus: string;
  salesstageid: number = 0;
  currentuser: any;
  public elementRef;
  public context;
  allItems: any;
  public gridOptionsItems: GridOptions;
  public columnDefsItems: any[];
  allSalesItems: any;
  DuplicateSalesItems: any;
  public gridOptionsSalesItems: GridOptions;
  public columnDefsSalesItems: any[];
  OwnerList: Dropdowntype[] = [];
  opprtunityitemlist: Dropdowntype[] = [];
  Tasklist: Dropdowntype[] = [];
  pclist: Dropdowntype[] = [];
  drlist: Dropdowntype[] = [];
  midlevelslist: Dropdowntype[] = [];
  leadfromlist: Dropdowntype[] = [];
  @ViewChild(TaskComponent) shcComp;
  @ViewChild(NotesComponent) notecomp;
  @ViewChild(HistoryComponent) historyComp;

  taskDialog: boolean = false;
  hideSalesSaveBtn: boolean = true;
  opportunityDialog: boolean = false;
  PreviousNotesExpand: boolean = false;
  MdlogicDemoDialog: boolean = false;
  showSaveNotes: boolean = false;
  public columnDefsnotes: any[];
  public frameworkComponents;
  allnotes: Array<Notes> = [];
  leadid: number;
  conid: number;
  noteid: number;
  account: Accounts = new Accounts();
  notedescription: string;
  NotesDec = false;
  isoppadded: boolean = false;
  isModal: boolean = true;
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  Allopportunities: Opportunities[];
  SelectedOpportunities: Opportunities;
  SelectedOpportunity: string = "";
  SelectedOpportunityCount: boolean = true;
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  timezonevalue: string;
  opportunityId: number = 0;
  LeadStageNote: boolean = false;
  allSalesStages: SalesStages[];
  Showtrigger: boolean = false;
  NewProposalPage: boolean = false;
  SelectedStage = SalesStages;
  StatusSelection: boolean = false;
  DateSelection: boolean = false;
  userInfo: string;
  isShowDetailsChecked: boolean = false;
  public sideBar;
  viewaccount: Viewaccount = new Viewaccount();
  action: string;
  showAddEditAccount: boolean = false;
  showAddEditContact: boolean = false;
  isSharing: boolean = false;
  @ViewChild(OwnershipsharingComponent) ownrshipComp: OwnershipsharingComponent;
  rightMainDivHideShow: boolean = true;
  accountSharingid: number = 0;
  AddContacttableDiv: boolean = false;
  GridView: boolean = true;
  public StatusItems: StatusItems[];
  @ViewChild(AccountDetailsComponent) accdetailcmp;
  public AddLeadStagegridOptions: GridOptions;
  public documentscolumnDefs: any[];
  DocExpandView: boolean = false;
  @Output()
  outPropsEvent = new EventEmitter<any>();

  constructor(private route: ActivatedRoute, public applicationComponent: ApplicationComponent, private opprtunityservice: OpprtunitiesService, private accountsService: AccountsService,
    private customerService: CustomerService, private leadservice: LeadsService, private router: Router, private location: Location, private toastr: ToastrService,
    private viewContainerRef: ViewContainerRef, myElement: ElementRef, private userService: UserService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.route.params.subscribe(params => {
      let contactid = params['contactid'];
      this.taskhistory.tasktype = null;
    });
    this.elementRef = myElement;
  }
  cancelaccountIds() {
    this.AccountIdsDialog = false;
  }
  AccountIdsDialogCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width: 20%;padding-bottom:0px;border:2px solid rgb(49,189,240);border-radius:5%;min-height:15%;overflow: auto;height: 18%;left: 0%;right:9%;top: 18%;padding: 0;');
    }, 4)

  }
  NotesKeyUp() {
    if (this.notes.notedescription.length > 0) {
      this.showSaveNotes = true;
    }
    else {
      this.showSaveNotes = false;
    }
  }
  ExpandPrvNotesDetails(val) {
    if (val == 'Collapse') {
      this.PreviousNotesExpand = false;
    } else {
      this.PreviousNotesExpand = true;
    }
  }

  public HideAdnShow(contactid,id) {
    this.FeeAmountdiv = true;
   
    if (contactid == 0) {
      this.FeeAmountdiv = false;
     
    } else {
      this.FeeAmountdiv = true;
     
      this.customerService.LoadContactById(contactid).then(result => {
        this.contact = result;
       
        this.customerService.loadaccountdata(this.contact.accountid).then(result => {
          this.account = result;
          this.taskhistory.type = "Contacts";
          this.saleprocesshistory.ownerid = result.ownerid;
        
          this.GetAllSalesStage(contactid);
          this.SalesProcessHistoryForGrid(contactid);
         
        });
      });
    }

  }
  ngOnInit() {
    var thisTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == "Leads")[0];
    if (thisTab.canview == false) {
      this.toastr.warning("You Do Not have Access to " + thisTab.resource + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
      return false;
    }
    this.HideAdnShow(this.contactid, this.id);

    $(".mainCheckMark").removeClass("activeColor");
    $(".coustomButton").removeClass("mainactiveButton");
    $("#leads_span").addClass("activeColor");
    $("#leads").addClass("mainactiveButton");
    this.initializeAgGridSalesItems();
    this.route.params.subscribe(params => {
    
      if (params['contactid'] != "" && params['contactid'] != 0 && params['contactid'] != '0') {
        this.contactid = params['contactid'];
      
      }
      $('#emailIds').val(this.contactid);
      this.typeOfLead = params['type'];


    });
    if (document.getElementById('subTab') != undefined && document.getElementById('subTab') != null)
      //document.getElementById('subTab').style.display = 'none';
    this.saleprocessform = new FormGroup({
      'salesstage': new FormControl(this.saleprocesshistory.salesstage, Validators.required),
      'status': new FormControl(this.saleprocesshistory.status, Validators.required),
      'completeddate': new FormControl(this.saleprocesshistory.completeddate, Validators.required)
    });

    this.opprtunityform = new FormGroup({
      'item': new FormControl(this.opportunities.item, Validators.required),
      'dollers': new FormControl(this.opportunities.dollers, Validators.required),
      'RR': new FormControl(this.opportunities.RR, Validators.required),
      'estimatedclosedate': new FormControl(this.opportunities.estimatedclosedate, Validators.required),
      'PC': new FormControl(this.opportunities.PC, Validators.required),
      'drs': new FormControl(this.opportunities.drs, Validators.required),
      'midlevels': new FormControl(this.opportunities.midlevels, Validators.required),
      'leadfromid': new FormControl(this.opportunities.leadfromid, Validators.required),
      'ownerid': new FormControl(this.opportunities.ownerid, Validators.required)
    });
    this.taskhistoryform = new FormGroup({
      'tasktype': new FormControl(this.taskhistory.tasktype, Validators.required),
      'estcompleteddate': new FormControl(this.taskhistory.estcompleteddate, Validators.required),
      'stagenotedescription': new FormControl(this.notes.stagenotedescription, Validators.required)
    });
    this.TaskId = 0;
    
    this.customerService.LoadContactById(this.contactid).then(result => {
      this.contact = result;
      this.customerService.loadaccountdata(this.contact.accountid).then(result => {
        this.account = result;
        this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata);
      });
      this.customerService.GetSpecialityList("AccountIds", this.currentuser.id).then((Acids) => {
        this.AccountIdArr = Acids;
      });
    });
  }

  LoadSalesData(conid, salesId) {
    this.contactid = conid;
    this.id = salesId;
    var thisTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == "Leads")[0];
    if (thisTab.canview == false) {
      this.toastr.warning("You Do Not have Access to " + thisTab.resource + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
      return false;
    }

    this.HideAdnShow(this.contactid, this.id);

    $(".mainCheckMark").removeClass("activeColor");
    $(".coustomButton").removeClass("mainactiveButton");
    $("#leads_span").addClass("activeColor");
    $("#leads").addClass("mainactiveButton");
    this.initializeAgGridSalesItems();
   
    if (document.getElementById('subTab') != undefined && document.getElementById('subTab') != null)
      //document.getElementById('subTab').style.display = 'none';
      this.saleprocessform = new FormGroup({
        'salesstage': new FormControl(this.saleprocesshistory.salesstage, Validators.required),
        'status': new FormControl(this.saleprocesshistory.status, Validators.required),
        'completeddate': new FormControl(this.saleprocesshistory.completeddate, Validators.required)
      });

    this.opprtunityform = new FormGroup({
      'item': new FormControl(this.opportunities.item, Validators.required),
      'dollers': new FormControl(this.opportunities.dollers, Validators.required),
      'RR': new FormControl(this.opportunities.RR, Validators.required),
      'estimatedclosedate': new FormControl(this.opportunities.estimatedclosedate, Validators.required),
      'PC': new FormControl(this.opportunities.PC, Validators.required),
      'drs': new FormControl(this.opportunities.drs, Validators.required),
      'midlevels': new FormControl(this.opportunities.midlevels, Validators.required),
      'leadfromid': new FormControl(this.opportunities.leadfromid, Validators.required),
      'ownerid': new FormControl(this.opportunities.ownerid, Validators.required)
    });
    this.taskhistoryform = new FormGroup({
      'tasktype': new FormControl(this.taskhistory.tasktype, Validators.required),
      'estcompleteddate': new FormControl(this.taskhistory.estcompleteddate, Validators.required),
      'stagenotedescription': new FormControl(this.notes.stagenotedescription, Validators.required)
    });
    this.TaskId = 0;
    
    this.customerService.LoadContactById(this.contactid).then(result => {
      this.contact = result;
      this.customerService.loadaccountdata(this.contact.accountid).then(result => {
        this.account = result;
        this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata);
      });
      this.customerService.GetSpecialityList("AccountIds", this.currentuser.id).then((Acids) => {
        this.AccountIdArr = Acids;
      });
    });
  }
  Display(accId, accountname, addr1, addr2, city, state, zip, country, phone, fax, website, Accountiddata) {
    if (city != null) {
      var cityArray = city.split(' ');
      var newCity = '';
      for (var i = 0; i < cityArray.length; i++) {
        if (newCity == '') {
          newCity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
        else {
          newCity = newCity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
      }
    }
    if (website != null && website.indexOf('www.') >= 0) {
      website = ((website).split('www.')[1])
    } else if (website != null && website.indexOf('ww.') >= 0) {
      website = ((website).split('ww.')[1])
    }


    var properstate = (state != null) ? state.charAt(0).toUpperCase() + state.substr(1).toLowerCase() : "";
    this.viewaccount.accountId = accId;
    this.viewaccount.viewaccountname = accountname;
    this.viewaccount.viewaddress1 = addr1;
    this.viewaccount.viewaddress2 = addr2;
    this.viewaccount.viewcity = newCity == null ? "" : newCity;
    this.viewaccount.viewstate = properstate;
    this.viewaccount.viewzip = zip;
    this.viewaccount.viewCountry = country;
    this.viewaccount.viewphone = phone;
    this.viewaccount.viewfax = fax;
    this.viewaccount.viewwebsite = website;
    this.viewaccount.Accountiddata = Accountiddata;
    this.accdetailcmp.loadAccountId(accId);
  }
  GetCompletedStage(contactid): Promise<any> {

    const promise = new Promise<any>((resolve, reject) => {
      this.leadservice.getcompletedstages(contactid).then((taskhistory) => {
        if (taskhistory == null) {
          this.saleprocesshistory.salesstage = 'IPC';
         // document.getElementById('SaleStage').innerHTML = this.saleprocesshistory.salesstage;
        } else {
          this.salesstageid = taskhistory.id;
          this.GetBySalesProcessHistoryById(taskhistory.id).then((res) => {
            this.loadstage(taskhistory);
          });
        }
        //resolve();
      });

    });
    return promise;
  }

  onAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllItems();
      $(setTimeout(function () {
        selfObj.autoSizeAllItems();
      }, 400));
    }, 400));
  }
  autoSizeAllItems() {
    var allColumnIds = [];
    if (this.gridOptionsItems && this.gridOptionsItems.columnApi) {
      var allCols = this.gridOptionsItems.columnApi.getAllColumns();
      if (allCols != null) {
        allCols.forEach(function (column) {
          allColumnIds.push(column.getColId());
        });
      }
      this.gridOptionsItems.columnApi.autoSizeColumns(allColumnIds);
      //this.gridOptionsItems.columnApi.autoSizeColumns(allColumnIds, "autosizeColumns");
    }
  }
  rowClickAgGrid(rowEvent) {

    if (rowEvent.node.selected) {
      this.opportunityId = rowEvent.data.id;
    }
    else {
      this.opportunityId = 0;
    }
  }
  rowClickAgGridItems(rowEvent) {
    if (this.userprevilages != undefined) {
      for (var j = 0; j < this.userprevilages.length; j++) {
        if ("Leads" == this.userprevilages[j].resource) {
          if (this.userprevilages[j].canedit == false) {
            this.toastr.warning("You Do Not have Access to edit  – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }
        }
      }
    }
    $('.selected').removeClass('selected');
    this.taskhistory.id = rowEvent.data.id;
    document.getElementById('Sheduletask').className = "col-xs-7";
    document.getElementById('Sales').className = "col-xs-5 disableDiv";

    if (this.TaskId == this.taskhistory.id) {
      this.notes.notedescription = '';
      this.taskhistorynotes = [];
      this.Tasktypelist = [];
      this.taskhistory = new TaskHistory();
      this.detailsTaskhistory = [];
      this.TaskId = 0;
      $('.mainactiveButton').removeClass('mainactiveButton');
      $('.changeActive').addClass('noprovider');
      $('#leads').addClass('mainactiveButton');

      $('#sheduletask').addClass('noprovider');
      $('.actionToDo').removeClass('activeColor');
      $('#sheduletask_span').removeClass('activeColor')
      //document.getElementById('taskSave').style.display = 'none';
      //document.getElementById('sid').innerHTML = "Schedule Task";
      //document.getElementById('statusid').style.display = 'block';
      //document.getElementById('notesTextArea').style.color = 'black';
      var idStatus = 167;
      var rstatus = 'Open'
      var spanId = idStatus + '_span';
      $('.labelStatus').removeClass('redColor');
      $('.spanStatus').removeClass('activeColor');
      $('#' + idStatus).addClass('redColor');
      $('#' + spanId).addClass('activeColor');
      document.getElementById('selectStatus').innerHTML = rstatus;
      this.scheduleTaskType = "";
      this.selectedTaskType = "";
    }
    else { //first time click
      this.TaskId = this.taskhistory.id
      this.opprtunityservice.GetTaskStatus("TaskStatus", this.currentuser.id).then((pc) => {
        pc.forEach(pc => {
        });
        this.TaskStatuslist = pc;
      });
      this.TaskStatussettings = { singleSelection: true, text: "Select Task Type" };
      //document.getElementById('saveButton').style.display = 'none';
      document.getElementById('taskSave').style.display = 'none';
      document.getElementById('statusid').style.display = 'block';
      document.getElementById('notesTextArea').style.color = 'red';
      this.leadservice.GetByTaskHistoryId(rowEvent.data.id, "View", this.currentuser.timezone).then(result => {
        this.taskhistory = null;
        this.taskhistory = result;
        this.leadservice.GetAllTaskHistoryById(this.taskhistory.id, 'Lead').then((items) => {
          //items.forEach(items => {
          //});
          this.taskhistorynotes = items;
          document.getElementById('notesTextArea').style.color = 'red';
          document.getElementById('notesTextArea').style.border = 'none';

        });
        this.taskhistory.tasktype = rowEvent.data.tasktype;
        this.selectedTaskType = rowEvent.data.tasktype;
        this.taskhistory.estcompleteddate = ((this.taskhistory.estcompleteddate == "0001-01-01") ? "" : this.datePipe.transform(this.taskhistory.estcompleteddate, "yyyy-MM-dd"));
        (this.detailsTaskhistory).push(result.tasktype);
        (this.detailsTaskhistory).push(result.estcompleteddate);
        (this.detailsTaskhistory).push(result.time);
        var type = result.tasktype;
        var typeArray = type.split("-");

        if (typeArray[0].trim() == 'Phone') {
          this.scheduleTaskType = 'phonecall';
          this.ActiveTask('phonecall');

        } else if (typeArray[0].trim() == 'To Do') {
          this.scheduleTaskType = 'todo';
          this.ActiveTask('todo');
        } else {
          this.scheduleTaskType = 'meeting';
          this.ActiveTask('meeting')
        }
        var id = 0;
        var status = '';
        if (rowEvent.data.status == 'Open') {
          id = 167;
          status = 'Open'
        } else if (rowEvent.data.status == 'Completed') {
          id = 168;
          status = 'Completed'
        }
        else if (rowEvent.row.item.status == 'Not Completed') {
          id = 169;
          status = 'Not Completed'
        }
        $('.labelStatus').removeClass('redColor');
        $('.spanStatus').removeClass('activeColor');
        var spanId = id + '_span';
        $('#' + id).addClass('redColor');
        $('#' + spanId).addClass('activeColor');
        document.getElementById('selectStatus').innerHTML = status;
        // document.getElementById('saveButton').style.display = 'none'

        //this.taskhistory.tasktype = rowEvent.data.tasktype;

        this.taskhistory.status = status;
      });
      // document.getElementById('Leadsale').className = 'btn noprovider';

      document.getElementById('sheduletask').className = 'btn mainactiveButton';
      document.getElementById('sid').innerHTML = "Edit Task";
      document.getElementById('sheduletask_span').className = 'mainCheckMark activeColor';
    }
  }

  initializeAgGridSalesItems() {
    this.gridOptionsSalesItems = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 30).then((config) => {
      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.gridOptionsSalesItems.api.paginationSetPageSize(config.maxlimit);
      }

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefsSalesItems(columnSelections);

    });
    this.gridOptionsSalesItems.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };
  }
  createColumnDefsSalesItems(columnSelections: string) {
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefsSalesItems = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefsSalesItems.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.columnDefsSalesItems.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefsSalesItems.push({ field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) { if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; } } });
        }
        else {
          this.columnDefsSalesItems.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefsSalesItems = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'datecreated', headerName: 'Date Created', hide: false, width: 120 },
        { field: 'completeddate', headerName: 'Completed', hide: false, width: 120 },
        { field: 'status', headerName: 'Status', hide: false, width: 120, cellStyle: function (params) { if (params.value == 'Open' || params.value == 'Pending' || params.value == 'Skipped') { return { color: 'red' }; } } },
        { field: 'salesstage', headerName: 'Sales Stage', hide: false, width: 150 },
        { field: 'contactName', headerName: 'Contact Name', hide: false, width: 120 },
        { field: 'username', headerName: 'Owner', hide: false, width: 120 },
        { field: 'completeduser', headerName: 'Completed By', hide: false, width: 120 },
        { field: 'dealwonOrlost', headerName: 'Deal Won/Lost', hide: true, width: 120 },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: 120 }
      ];
    }
  }
  onAgGridReadySalesItems(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllSalesItems();
      $(setTimeout(function () {
        selfObj.autoSizeAllSalesItems();
      }, 400));
    }, 400));
  }
  autoSizeAllSalesItems() {

  }

  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptionsSalesItems.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    config.maxlimit = 25;
    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 30;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);

  }
  rowClickAgGridSalesItems(rowEvent) {
    this.hideSalesSaveBtn = true;
    $('.selected').removeClass('selected');
    this.saleprocesshistory = new SalesProcessHistory();
    this.taskhistory = new TaskHistory();
    this.detailsTaskhistory = [];
    this.notes.notedescription = '';
    this.notes.stagenotedescription = '';
    this.taskhistorynotes = [];
    this.salestagenotes = [];
    this.taskhistory = new TaskHistory();

    if (this.salesSelectedId == rowEvent.data.id) {
      this.salesSelectedId = 0;
      this.saleprocesshistory.status = null;
      this.GetCompletedStage(this.contactid);
    } else {
      this.salesSelectedId = rowEvent.data.id;
      this.GetBySalesProcessHistoryById(rowEvent.data.id);
    }

  }

  openNotesDialogAg(rowdata) {   
    this.conid = rowdata.contactid;
    this.showDialog = !this.showDialog;
    this.showDialogCss()

  }

  showDialogCss() {
    $(setTimeout(function () {
      $('#showDialog .dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }

  GetAllLeadNotes(id, type) {
    this.leadservice.GetAllSalesProcessHistoryByContact(id).then(SalesHistory => {
      var res = SalesHistory.map(x => x.id).join(',').toString();
      this.customerService.GetAllNotesList(this.currentuser.id, res, type).then((taskhistory) => {
        taskhistory.forEach(items => {
          var moment = require('moment-timezone');
          var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
          items.userfullName = timeDisplay;
          items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
        });
     
        this.allnotes = taskhistory;       
        let windowHeight = $(window).height() - 160;
      });
    });
    
  }
  initializeAgGridNotes() {
    this.gridOptionsnotes = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.createColumnDefsnotes("");
    this.gridOptionsnotes.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  createColumnDefsnotes(columnSelections: string) {
    let selfObj = this;
    this.columnDefsnotes = [
      {
        field: '#',
        headerName: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
      },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellStyle: { 'text-align': 'left' }, hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];
  }
  onAgGridReadynotes(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllnotes();
      $(setTimeout(function () {
        selfObj.autoSizeAllnotes();
      }, 400));
    }, 400));
  }
  autoSizeAllnotes() {
    var allColumnIds = [];
    if (this.gridOptionsnotes && this.gridOptionsnotes.columnApi) {
      var allCols = this.gridOptionsnotes.columnApi.getAllColumns();
      if (allCols != null) {
        allCols.forEach(function (column) {
          allColumnIds.push(column.getColId());
        });
      }
      this.gridOptionsnotes.columnApi.autoSizeColumns(allColumnIds);
    }

  }
  getNextStage(SalesStage) {

    var nextStage = Number(SalesStage.SalesStageID) + 1;
    var Result = this.allSalesStages.filter(e => e.SalesStageID == nextStage);
    if (Result.length != 0) {
      $('#TG' + Result[0].SalesStageID).prop('disabled', false);
      $('#Status' + Result[0].SalesStageID).prop('disabled', false);
      $('#CD' + Result[0].SalesStageID).prop('disabled', false);
      $('#Stage' + Result[0].SalesStageID).prop('style', 'color:black');
      $('#NoteWhite' + Result[0].SalesStageID).prop('disabled', false);

    }
    return Result;
  }
  OpenLeads() {

    this.showsalesDialog = true;
    this.SalesDialogCss();
    this.GetBySalesProcessHistoryById(this.saleprocesshistory.id).then((res) => {
      this.loadstage(this.saleprocesshistory);
      let SelfObj = this;
      setTimeout(function () {
        SelfObj.GetCompletedStage(this.saleprocesshistory.contactid);
      }, 200)
    });
   
  }

  GetBySalesProcessHistoryById(salesid): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.leadservice.GetBySalesProcessHistoryById(salesid).then(result => {      
        this.saleprocesshistory = result;
        this.SalesProcessHistory(this.saleprocesshistory.contactid);
        this.itemCount = 0;

        var CompletedStage = this.allSalesStages.filter(ele => ele.SaleStage == this.saleprocesshistory.salesstage);
        this.getNextStage(CompletedStage[0]);

        
        if (this.saleprocesshistory.status == 'Pending') {
          this.saleprocesshistory.id = 0;

        } else if (this.saleprocesshistory.status == 'Skipped') {

        }
      });
    });
    return promise;

  }
  Search() {
    var term = $('#term').val();

    this.opprtunityservice.GetAllContactsBySearch(term, this.currentuser.id, 'contacts').then((contact) => {
      contact.forEach(contact => {
      });
      this.ContactitemCount = contact.length
    });
  }
  CancelOpportunityDiv() {
    document.getElementById('OpportunityDiv').style.display = 'none';
    document.getElementById('Saleprocess').style.display = 'block';
    this.saleprocesshistory.id = 0;
    this.hideSalesSaveBtn = true;
  }

  loadstage(result) {
 
    if (result.status == 'Completed' || result.status == 'Skipped' || result.status == 'Pending') {
      if ((result.status == 'Completed') && result.salesstage == 'Generate Proposal') {
        //document.getElementById('Saleprocess').style.display = 'none';
        //document.getElementById('OpportunityDiv').style.display = 'block';
        this.hideSalesSaveBtn = false;

      } if ((result.status == 'Completed') && result.salesstage == 'MD Logic Demonstration') {
        $(setTimeout(function () {
          $('.dialog').width(((window.innerWidth * 30) / 100));
          $('.dialog').height(((window.innerHeight * 50) / 100));
          $('.dialog').height(((window.innerHeight * 50) / 100));
        }, 1));
        document.getElementById('saveButton').style.display = 'none';
      }

      else if ((result.status == 'Skipped') && result.salesstage != 'MD Logic Demonstration') {
        this.saleprocesshistory.id = 0;
      }
      var id = 0;
      var lable = '';
      if (result.salesstage == 'IPC') {
        id = 2;
        lable = 'Analysis'
      } else if (result.salesstage == 'Analysis') {
        id = 3;
        lable = 'Prepare Analysis Report'
      } else if (result.salesstage == 'Prepare Analysis Report') {
        id = 4;
        lable = 'Review Analysis'
      } else if (result.salesstage == 'Review Analysis') {
        id = 5;
        lable = 'Generate Proposal'
      } else if (result.salesstage == 'Generate Proposal') {
        id = 6;

        lable = 'Justification Meeting'
      } else if (result.salesstage == 'Justification Meeting') {
        id = 7;
        lable = 'Reference Calls'
      } else if (result.salesstage == 'Reference Calls') {
        id = 8;
        lable = 'MD Logic Demonstration';
      } else {
        lable = 'MD Logic Demonstration';

      }
      this.saleprocesshistory.salesstage = lable;

      if ((this.saleprocesshistory.dealwonOrlost == 'DEAL WON' || this.saleprocesshistory.dealwonOrlost == 'DEAL LOST') && this.saleprocesshistory.salesstage != 'MD Logic Demonstration') {
        //$('#Leadsale').addClass("mainactiveButton")
        //$('#Leadsale').removeClass("NextactiveButton")

      } else {
        //$('#Leadsale').removeClass("mainactiveButton")
        //$('#Leadsale').addClass("NextactiveButton")

      }

    }
    else {
      if (result.salesstage == 'IPC') {
        id = 1;
        lable = 'IPC'
      } else if (result.salesstage == 'Analysis') {
        id = 2;
        lable = 'Analysis'
      } else if (result.salesstage == 'Prepare Analysis Report') {
        id = 3;
        lable = 'Prepare Analysis Report'
      } else if (result.salesstage == 'Review Analysis') {
        id = 4;
        lable = 'Review Analysis'
      } else if (result.salesstage == 'Generate Proposal') {
        id = 5;
        lable = 'Generate Proposal'
      } else if (result.salesstage == 'Justification Meeting') {
        id = 6;
        lable = 'Justification Meeting'
      } else if (result.salesstage == 'Reference Calls') {
        id = 7;
        lable = 'Reference Calls';
      } else if (result.salesstage == 'MD Logic Demonstration') {
        id = 8;
        lable = 'MD Logic Demonstration';
      }
      this.saleprocesshistory.salesstage = lable;
      //$('#Leadsale').addClass("mainactiveButton")
      //$('#Leadsale').removeClass("NextactiveButton")
      //document.getElementById('Leadsale').innerHTML = 'Leads- Sales Process'
      //var that = this;
      //$(setTimeout(function () {
      //  document.getElementById('SaleStage').innerHTML = that.saleprocesshistory.salesstage;
      //}, 200));

    }

  }
  SaveSaleProcessHistory(val) {
    if (this.userprevilages != undefined) {
      for (var j = 0; j < this.userprevilages.length; j++) {
        if ("Leads" == this.userprevilages[j].resource) {
          if (this.userprevilages[j].canadd == false) {
            this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }

        }
      }
    }
    this.salesSelectedId = 0;
    this.salesstageid = 0;
    this.saleprocesshistory.contactid = this.contact.id;
    this.saleprocesshistory.userid = this.currentuser.id;
    if (this.saleprocesshistory.status == "Completed") {
      this.saleprocesshistory.completeduserid = this.currentuser.id;
    }
    if (this.saleprocesshistory.salesstage == 'Won-Lost-Pending') {
      this.saleprocesshistory.dealwonOrlost = this.saleprocesshistory.status;
    }

    this.saleprocesshistory.ownerid = this.currentuser.id;
    this.saleprocesshistory.completeduserid = this.currentuser.id;
    this.leadservice.addSaleProcessHistory(this.saleprocesshistory).then(result => {
   
      this.salesstageid = result.id;
      this.notes.accountid = result.id;
      this.notes.type = "Lead";
      var newNotes = new Notes();
      newNotes.accountid = result.id;
      newNotes.type = "Lead";
      this.notes.userid = this.currentuser.id;
      this.notes.isnewnote = 1;
      newNotes.notedescription = this.notes.stagenotedescription || this.notes.notedescription;
      this.SaveLeadDialog = false;
     
      this.opprtunityservice.addopprtunityNotes(newNotes).then(res => {
        this.notes = res;
        this.notes.stagenotedescription = '';
        this.notes.notedescription = '';
        this.clearNotes();
        
        if (result.id != null && result.id != undefined) {
        //  this.GetAllLeadNotes(result.id, 'Lead');
        }
      });
      if (result.salesstage == 'Demonstration' && result.status == 'Completed') {
        this.saleprocesshistory.id = result.id;
      }
      if (this.saleprocesshistory.salesstage == 'Won-Lost-Pending' && (this.saleprocesshistory.status == 'Won' || this.saleprocesshistory.status == 'Lost')) {
        this.leadservice.GetAllSalesProcessHistoryByContact(result.contactid).then(Leads => {
          var GeneralProposal = Leads.filter(e => e.salesstage == 'Generate Proposal')[0];

          var OpportunityStatus;
          if (this.saleprocesshistory.status == 'Won') {
            OpportunityStatus = 'Closed-Won';
          } else if (this.saleprocesshistory.status == 'Lost') {
            OpportunityStatus = 'Closed-Lost';
          }
          this.opprtunityservice.GetStatusChange(GeneralProposal.OpportunityID, OpportunityStatus).then(result => {
            //this.opprtunityservice.addopprtunity(this.opportunities).then(result => { });
          });


        });

      }
    
      let SelfObj = this;
      setTimeout(function () {
   /*     SelfObj.SalesProcessHistory(result.contactid);*/
        SelfObj.SalesProcessHistoryForGrid(result.contactid);
              
      },500);
     
    });
   
    //document.getElementById('leadnotes').style.display = 'none';
  }



  AddTask() {

    this.taskDialog = !this.taskDialog;
    //this.taskDialogCss();
    this.taskDialogBoxCss();

    let SelfObj = this;
    setTimeout(function () {
      SelfObj.historyComp.getAllActivities(SelfObj.contact.id, 'Contacts');
    }, 200)

    
    //var searchBoxOffset = $("#searchBox").offset();
    //let width = window.innerWidth;
    //let searchBoxHeight = $('#searchBox').height();
    //var leftmargin = ($(window).width()) / 1.7742;
    //var leftmargin = ($(window).width()) / 1.5;
    //let windowHeight = $(window).height() / 1.25;
    //$(setTimeout(function () {
    //$('.dialog').css('width', '40%');
    //$('.dialog').css('height', '96px;');
    //$('.dialog').css('left', '58%');
    //$('.dialog').css('top', '3%');
    //$('.dialog').css('border-radius', '3px');
    //$('.dialog').css('min-height', '95%');
    //$('.dialog').css('border', '4px solid #75da33');
    //$('.dialog').css('overflow', 'auto');
    //$('.dialog').css('background-color', '#F7F7F7')
    //$('.dialog').css('padding', '0')
    //$('#closeDialog').attr('style', 'color:white;')
    //this.taskDialogBoxCss();
    //}, 1));
  }

  taskDialogBoxCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width:40%;padding:0px;border:4px solid rgb(49,189,240);border-radius:5%;min-height:67%;overflow: auto;height: 96%;left: 58%;top: 3%;border-radius:3px;background-color:rgb(247, 247, 247);');
    }, 4));
  }
  LeadSaleProcess() {
    if ((this.Salesitems).length > 0) {
      // this.selectOpenSaleProcess(this.Salesitems[0].id)
    }
    this.Tasktypelist = [];
    $(".changeActive").removeClass("mainactiveButton");
    $(".actionToDo").removeClass("activeColor");
    $(".changeActive").addClass("noprovider");
    //$(".actionToDo").addClass("activeColor")
    //$('#Leadsale').removeClass("noprovider")
    //$('#Leadsale').addClass("mainactiveButton")

    document.getElementById('sheduletask').className = 'btn noprovider changeActive';
    $('#sheduletask_span').removeClass('activeColor')
    document.getElementById('sid').innerHTML = 'Schedule task';
    $('#Sales').removeClass('disableDiv');

  }
  ActiveTask(id) {
    var type = $('#sel1').val();
    if (type == null || type == undefined || type == "" || this.taskhistory.estcompleteddate == 'undefined')
      document.getElementById('taskSave').style.display = 'none'
    else
      document.getElementById('taskSave').style.display = 'block'
    document.getElementById('Sheduletask').className = "col-xs-7";
    document.getElementById('Sales').className = "col-xs-5 disableDiv";

    $(".actionToDo").removeClass("activeColor");
    $(".changeActive").removeClass("mainactiveButton");
    $(".changeActive").addClass("noprovider");
    $("#" + id).removeClass("noprovider");
    $("#" + id + "_span").addClass("activeColor");
    $("#" + id).addClass("mainactiveButton");
    $("#sheduletask_span").addClass("activeColor");
    $("#Leadsale_span").removeClass("activeColor");
    $("#sheduletask").removeClass("noprovider");
    $("#sheduletask").addClass("mainactiveButton");
    //   document.getElementById('Leadsale').className = 'btn noprovider';
    this.leadservice.GetAllTasksList(id, this.saleprocesshistory.salesstage, this.currentuser.TaskGroupid, 0, this.currentuser.id).then((accounttype) => {
      accounttype.forEach(accounttype => {
      });
      this.Tasktypelist = accounttype;
      if (this.scheduleTaskType == id && this.selectedTaskType != "")
        this.taskhistory.tasktype = this.selectedTaskType;
      else
        this.taskhistory.tasktype = accounttype[0].itemName;

    });
    var idStatus = 167;
    if (this.TaskId == 0) {
      var spanId = idStatus + '_span';
      $('.labelStatus').removeClass('redColor');
      $('.spanStatus').removeClass('activeColor');
      $('#' + idStatus).addClass('redColor');
      $('#' + spanId).addClass('activeColor');
      document.getElementById('selectStatus').innerHTML = 'Open';
    }


  }
  SalesProcessHistoryForGrid(contactid) {
   
    this.leadservice.GetAllSalesProcessHistoryByContact(contactid).then(SalesHistory => {
      SalesHistory.forEach(SalesHistory => {
        SalesHistory.datecreated = this.datePipe.transform(SalesHistory.datecreated, "MM-dd-yyyy");
        SalesHistory.completeddate = this.datePipe.transform(SalesHistory.completeddate, "MM-dd-yyyy");
      });
      this.allSalesItems = SalesHistory;
    
      if (this.allSalesItems.length == 0) {
        this.saleprocesshistory = new SalesProcessHistory();
        this.saleprocesshistory.contactname = this.contact.firstname + " " + this.contact.lastname + " , " + this.contact.titleName;
      } else {
        let selfObj = this;
        $(setTimeout(function () {         
          selfObj.gridOptionsSalesItems.api.forEachNode((node) => {
            if (node.data.id == selfObj.allSalesItems[0].id) {
              selfObj.gridOptionsSalesItems.api.selectNode(node, true);
              selfObj.gridOptionsSalesItems.rowSelection = 'single';
              selfObj.GetAllLeadNotes(contactid, 'Lead');
              selfObj.OpenLeads();

            }
          });          
        }, 500));
      }
    });
   
  }
  saveNotes() {
    this.notes.accountid = this.id;
    this.notes.type = 'Lead';
    this.notes.isnewnote = 1;
    this.notes.userid = this.currentuser.id;
    if (this.notes.notedescription != undefined && this.notes.notedescription != "") {
      this.customerService.SaveNotes(this.notes).then(result => {
       // this.toastr.success("Notes saved successfully.", "Notes Saved", { timeOut: 600 });
        this.clearNotes();
        this.GetAllLeadNotes(this.id, 'Lead');
        if (this.id != null && this.id != undefined) {
          this.GetAllLeadNotes(this.id, 'Lead');
        }
      });
    }
  }
  clearNotes() {
    this.notes.notedescription = "";
    this.showSaveNotes = false;
  }

  SalesDialogCss() {
    $(setTimeout(function () {
      $('#showsalesDialog .dialog').attr('style', 'width: 35%;padding-bottom: 0px;border: 1px solid rgb(49,189,240);min-height: 63%;overflow: auto;height:99.5%;padding: 0;top: 0%;left: 65%;');
    }, 100));
  }
  SalesProcessHistory(contactid) {
    this.leadservice.GetAllSalesProcessHistoryByContact(contactid).then(SalesHistory => {
      let dup = SalesHistory;
    
      if (dup.length != 0) {
        dup.sort((a, b) => a.id - b.id).forEach(SalesHistory => {       
          this.allSalesStages.forEach(element => {
            if (SalesHistory.salesstage == element.SaleStage) {
              if (SalesHistory.salesstage == "Won-Lost-Pending" || SalesHistory.status == 'Pending') {               
                element.status = SalesHistory.status;
                element.completeddate = ((SalesHistory.completeddate == "0001-01-01") ? "" : this.datePipe.transform(SalesHistory.completeddate, "yyyy-MM-dd"));
                element.notesDescription = SalesHistory.notesDescription;               
              }
              else {
                if (SalesHistory.status == 'Completed' || SalesHistory.status == 'Skipped') {
                 
                  element.status = SalesHistory.status;
                  element.completeddate = ((SalesHistory.completeddate == "0001-01-01") ? "" : this.datePipe.transform(SalesHistory.completeddate, "yyyy-MM-dd"));
                  element.notesDescription = SalesHistory.notesDescription;
                  element.id = SalesHistory.id;
                }
                else {
                  element.SalesStageID = SalesHistory.id;
                  element.status = SalesHistory.status;
                  element.completeddate = ((SalesHistory.completeddate == "0001-01-01") ? "" : this.datePipe.transform(SalesHistory.completeddate, "yyyy-MM-dd"));
                  element.notesDescription = SalesHistory.notesDescription;
                  element.id = SalesHistory.id;
                }
              }
              var conDate = new Date(this.saleprocesshistory.convertedtoLeaddate);
             
              this.saleprocesshistory.NoOfDays = "0";
              if (element.SaleStage == "IPC") {
                this.saleprocesshistory.IPCCompletedDate = element.completeddate;
                this.saleprocesshistory.IPCStatus = element.status;
                var ipcDate = new Date(this.saleprocesshistory.IPCCompletedDate);
                var ipcdays = Math.floor((Date.UTC(conDate.getFullYear(), conDate.getMonth(), conDate.getDate()) - Date.UTC(ipcDate.getFullYear(), ipcDate.getMonth(), ipcDate.getDate())) / (1000 * 60 * 60 * 24));
                this.saleprocesshistory.NoOfDays = ipcdays.toString().replace('-','');
              }
              if (element.SaleStage == "Analysis") {
                this.saleprocesshistory.AnalysisCompletedDate = element.completeddate;
                this.saleprocesshistory.AnalysisStatus = element.status;
                var analDate = new Date(this.saleprocesshistory.AnalysisCompletedDate);
                var analdays = Math.floor((Date.UTC(conDate.getFullYear(), conDate.getMonth(), conDate.getDate()) - Date.UTC(analDate.getFullYear(), analDate.getMonth(), analDate.getDate())) / (1000 * 60 * 60 * 24));
                this.saleprocesshistory.NoOfDays = analdays.toString().replace('-', '');;
           
              }
              if (element.SaleStage == "Prepare Analysis") {
                this.saleprocesshistory.PrepareAnalysisReportCompletedDate = element.completeddate;
                this.saleprocesshistory.PrepareAnalysisStatus = element.status;
                var prepAnalDate = new Date(this.saleprocesshistory.PrepareAnalysisReportCompletedDate);
                var prepAnaldays = Math.floor((Date.UTC(conDate.getFullYear(), conDate.getMonth(), conDate.getDate()) - Date.UTC(prepAnalDate.getFullYear(), prepAnalDate.getMonth(), prepAnalDate.getDate())) / (1000 * 60 * 60 * 24));
                this.saleprocesshistory.NoOfDays = prepAnaldays.toString().replace('-', '');;
              }
              if (element.SaleStage == "Review Analysis") {
                this.saleprocesshistory.ReviewAnalysisCompletedDate = element.completeddate;
                this.saleprocesshistory.ReviewAnalysisStatus = element.status;
                var revAnalDate = new Date(this.saleprocesshistory.PrepareAnalysisReportCompletedDate);
                var revAnaldays = Math.floor((Date.UTC(revAnalDate.getFullYear(), revAnalDate.getMonth(), revAnalDate.getDate()) - Date.UTC(conDate.getFullYear(), conDate.getMonth(), conDate.getDate())) / (1000 * 60 * 60 * 24));
                this.saleprocesshistory.NoOfDays = revAnaldays.toString().replace('-', '');;
              }
              if (element.SaleStage == "Generate Proposal") {
                this.saleprocesshistory.GeneralProposalCompletedDate = element.completeddate;
                this.saleprocesshistory.GenerateProposalStatus = element.status;
                var genproDate = new Date(this.saleprocesshistory.PrepareAnalysisReportCompletedDate);
                var genProdays = Math.floor((Date.UTC(conDate.getFullYear(), conDate.getMonth(), conDate.getDate()) - Date.UTC(genproDate.getFullYear(), genproDate.getMonth(), genproDate.getDate())) / (1000 * 60 * 60 * 24));
                this.saleprocesshistory.NoOfDays = genProdays.toString().replace('-', '');;
              }
              if (element.SaleStage == "Justification Meeting") {
                this.saleprocesshistory.justificationmettingCompletedDate = element.completeddate;
                this.saleprocesshistory.JustificationStatus = element.status;
                var justDate = new Date(this.saleprocesshistory.PrepareAnalysisReportCompletedDate);
                var jusdays = Math.floor((Date.UTC(conDate.getFullYear(), conDate.getMonth(), conDate.getDate()) - Date.UTC(justDate.getFullYear(), justDate.getMonth(), justDate.getDate())) / (1000 * 60 * 60 * 24));
                this.saleprocesshistory.NoOfDays = jusdays.toString().replace('-', '');;
              }
              if (element.SaleStage == "Reference Calls") {
                this.saleprocesshistory.ReferencecallCompletedDate = element.completeddate;
                this.saleprocesshistory.ReferenceCallsStatus = element.status;
                var refDate = new Date(this.saleprocesshistory.PrepareAnalysisReportCompletedDate);
                var refdays = Math.floor((Date.UTC(conDate.getFullYear(), conDate.getMonth(), conDate.getDate()) - Date.UTC(refDate.getFullYear(), refDate.getMonth(), refDate.getDate())) / (1000 * 60 * 60 * 24));
                this.saleprocesshistory.NoOfDays = refdays.toString().replace('-', '');;
              }
              if (element.SaleStage == "Demonstration") {
                this.saleprocesshistory.MdlogicdemonstrationCompletedDate = element.completeddate;
                this.saleprocesshistory.DemonstrationStatus = element.status;
                var demDate = new Date(this.saleprocesshistory.MdlogicdemonstrationCompletedDate);
                var demanstartiondays = Math.floor((Date.UTC(conDate.getFullYear(), conDate.getMonth(), conDate.getDate()) - Date.UTC(demDate.getFullYear(), demDate.getMonth(), demDate.getDate())) / (1000 * 60 * 60 * 24));
                this.saleprocesshistory.NoOfDays = demanstartiondays.toString().replace('-', '');
              }
              if (element.SaleStage == "Lead Status") {
                element.status = "Open";
                element.completeddate = "";
             
              }
            }
            else {
              if (dup.length == 1 && SalesHistory.status == null && element.SaleStage == "IPC") {
                element.id = SalesHistory.id;
                var IPC = this.allSalesStages.filter(ele => ele.SaleStage == "IPC")[0];
              }

            }
           

          });

        });
      }
      else {
        var IPC = this.allSalesStages.filter(ele => ele.SaleStage == "IPC")[0];
        
      }
   
      //this.initializeAddLeadStageAgGrid();
    });


  }

  AddNewOpportunity() {
    //this.Showtrigger = !this.Showtrigger;
    this.NewProposalPage = true;
    this.CreateProposalPageCss("CreateProposalPage");
    this.outPropsEvent.emit();

  }
  CloseProposalPage(msg) { }
  CreateProposalPageCss(val) { 
      setTimeout(function () {
        $('#CreateProposalPage .dialog').attr('style', 'width: 88%;padding: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 86%;top: 10%;right: 0;left: 10%;max-width: 100% !important;');
      }, 4)
  }
  Resetfields() {
    this.opportunities.item = null;
    this.opportunities.PC = null;
    this.opportunities.drs = null;
    this.opportunities.midlevels = null;
    this.opportunities.leadfromid = null;
    this.taskhistory.type = null;
    this.opportunities.ownerid = this.currentuser.id;
  }

  cancelLead() {
    //this.allSalesStages = null;
    this.notes.notedescription = "";
    this.saleprocesshistory.ownerid = this.currentuser.id;
    //this.showsalesDialog = false;
  }

  CancelOpportunity() {
    this.opportunityDialog = !this.opportunityDialog;
    document.getElementById('TaskButton').style.display = 'block';
    document.getElementById('LeadsDiv').style.display = 'block';
    document.getElementById('Saleprocess').style.display = 'block';
    document.getElementById('OpportunityDiv').style.display = 'none';
    this.hideSalesSaveBtn = true;

  }
  loadDropDowns() {
    this.opprtunityservice.GetAllItems().then((items) => {
      items.forEach(items => {
      });
      this.opprtunityitemlist = items;
    });

    this.opprtunityservice.GetSpecialityList("ProbabiltyClose", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.pclist = pc;
    });

    this.opprtunityservice.GetSpecialityList("Drs", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.drlist = pc;
    });

    this.opprtunityservice.GetSpecialityList("Midlevels", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.midlevelslist = pc;
    });

    this.opprtunityservice.GetSpecialityList("LeadFrom", this.currentuser.id).then((pc) => {
      pc.forEach(pc => {
      });
      this.leadfromlist = pc;
    });
    this.customerService.GetLoggedinUserList(this.currentuser.id, this.currentuser.systemadministrator).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.OwnerList = Owner;
    });
  }
  completeForm() {
  }
  completedDate() {
  }
  handleEnterKeyPress(e) {
  }
  optionSelected() {
  }
  disableTextBox(item) {
  }
  addNotes() {
  }
  delete(val, status) {
    this.DealStatus = status;
    $('#statusdiv .btn').removeClass("noprovider");
    if (status == "DEAL WON") {
      $("#Won").addClass("noprovider");

      //document.getElementById('Leadsale').className = 'btn changeActive mainactiveButton';

    } else if (status == "Pending") {
      $("#Pending").addClass("noprovider");

      // document.getElementById('Leadsale').className = 'btn changeActive mainactiveButton';
    }
    else {
      $("#Lost").addClass("noprovider");
    }

  }
  SaleStatus() {

    this.MdlogicDemoDialog = !this.MdlogicDemoDialog
    document.getElementById('SaleProcessForm').style.display = 'none';
    document.getElementById('DealwonorLastDiv').style.display = 'block';
    //document.getElementById('Leadsale').className = 'btn changeActive mainactiveButton';
    this.SaveSaleProcessHistory("");
  }

  //selectedStageCompleted(id) {
  //  if (this.salesSelectedId > 0) {
  //    if (id == 'yes') {
  //      if (this.saleprocesshistory.id == undefined || this.saleprocesshistory.id == 0) {
  //        //document.getElementById('DateComp').style.display = 'none';
  //      }
  //      else {
  //        //this.HideNShowSelectedStatus(id);
  //      }
  //    }
  //    else {
  //      //this.HideNShowSelectedStatus(id);
  //    }
  //  } else if (this.salesstageid > 0) {
  //    if (id == 'yes') {
  //      if (this.salesstageid == undefined || this.salesstageid == 0) {
  //        //document.getElementById('DateComp').style.display = 'none';
  //        //document.getElementById('leadnotes').style.display = 'none';
  //      }
  //      else
  //        //this.HideNShowSelectedStatus(id);
  //    }
  //    else
  //      //this.HideNShowSelectedStatus(id);
  //  }
  //  else {
  //    this.saleprocesshistory.status = null;
  //    document.getElementById('DateComp').style.display = 'none';
  //    // document.getElementById('leadnotes').style.display = 'none';
  //  }
  //}
  //HideNShowSelectedStatus(id) {
  //  if (id == 'yes') {
  //    this.saleprocesshistory.status = id;
  //    //document.getElementById('DateComp').style.display = 'block';
  //    //$('#saveButtonDiv').attr('style', ' border: none; width: 62%; background: gray;')
  //    //document.getElementById('leadnotes').style.display = 'none';

  //  } else if (id == 'no') {
  //    //document.getElementById('leadnotes').style.display = 'block';
  //    //document.getElementById('DateComp').style.display = 'none';
  //    //$('#saveButtonDiv').attr('style', 'border: none; width: 62%; background: gray;')
  //    this.saleprocesshistory.status = id;
  //  }
  //  else {
  //    document.getElementById('completeStage').style.display = 'block';
  //    //  document.getElementById('leadnotes').style.display = 'none';
  //    this.saleprocesshistory.status = null;
  //    this.saleprocesshistory.completeddate = null;
  //    //document.getElementById('DateComp').style.display = 'none';
  //    //$('#saveButtonDiv').attr('style', 'border: none; width: 62%; background: gray;')
  //  }
  //}
  //changestatus(id, status) {

    //$(".OpportunityactiveButton").addClass("c-button");
    //$(".OpportunityactiveButton").removeClass("OpportunityactiveButton");
    //this.DealStatus = status;
    //this.hideSalesSaveBtn = true;
    //if (status == "DEAL WON") {
      //$("#changeWon").removeClass("c-button");
      //$("#changeWon").addClass("OpportunityactiveButton");
      // document.getElementById('Leadsale').className = 'btn changeActive mainactiveButton';
    //} else if (status == "Pending") {
      //this.saleprocesshistory.dealwonOrlost = 'Pending';
      //$("#changePending").removeClass("c-button");
      //$("#changePending").addClass("OpportunityactiveButton");
      // document.getElementById('Leadsale').className = 'btn changeActive mainactiveButton';
    //}
    //else {
      //this.saleprocesshistory.dealwonOrlost = 'Completed';
      //$("#changeLost").removeClass("c-button");
      //$("#changeLost").addClass("OpportunityactiveButton");

    //}
    //this.hideSalesSaveBtn = true;
  //}
  //isSaveDisabled() {
  //  return $('#completeStage').css('display') != 'none';
  //}
  onTaskSaved(msg) {
    //if (msg != "Header") {
    this.taskDialog = !this.taskDialog;
    //}

  }

  openDisplaynotes(params) {
    $('#closeDetailsDialog').click();

    // this.notes.notedescription = params.value;
    this.NotesDec = true;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    var notesOffset = $("#divNotesContainer").offset();
    let notesWidth = $('#divNotesContainer').width();
    let notesHeight = $('#divNotesContainer').height();
    let windowWidth = $(window).width();
    let showDialogWidth = 350;

    let selfObj = this;
    $(setTimeout(function () {

      //$('#showDetailsDialog').width(showDialogWidth);
      //$('#showDetailsDialog').height(notesHeight - 140);
      //if ($('#typeClicked').val() == 'modal') {
      if (selfObj.isModal) {
        //$('#showDetailsDialog').css('top', '23%');
        //$('#showDetailsDialog').css('height', '49%');
        //$('#showDetailsDialog').css('left', notesWidth - showDialogWidth + 26);
        //$('#showDetailsDialog').css('top', notesOffset.top + 93);
        //$('#showDetailsDialog').css('left', notesWidth - showDialogWidth);

        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '81%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '14%');
        } else {
          $('#txtLongNoteDesc').css('height', '96%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtLongNoteDesc').css('overflow', 'auto');
        }
      } else {
        //var url = window.location.href;
        //if ((url).indexOf('account-contact') > -1) {
        //  $('#showDetailsDialog').css('top', '62%');
        //} else {
        //  $('#showDetailsDialog').css('top', '72%');
        //}
        var gridOffset = $("#notesGrid").offset();
        //$('#showDetailsDialog').css('top', gridOffset.top);
        //$('#showDetailsDialog').css('height', '30%');
        //$('#showDetailsDialog').css('left', '69%');

        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '53%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '33%');
        } else {
          $('#txtLongNoteDesc').css('height', '89%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      }
      //$('#showDetailsDialog').css('border-radius', '4px');
      //$('#showDetailsDialog').css('border', '3px solid');
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));
  }

  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
    //if ($("#btnEditNote").text() == "Edit Note") {
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();

  }
  saveEditedNote() {
    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.closeDetailsDialog();
        selfObj.GetAllLeadNotes(selfObj.conid, 'Lead');
        if (selfObj.conid != null && selfObj.conid != undefined) {
          selfObj.GetAllLeadNotes(selfObj.conid, 'Lead');
        }
      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
  }

  createColumnDefs(columnSelections: string) {
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefs.push({ field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) { if (params.value == 'Open') { return { color: 'red' }; } } });
        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        { field: 'estimatedclosedate', headerName: 'ECD', hide: false, width: 110 },
        { field: 'itemName', headerName: 'Proposal Type', hide: false, width: 200 },
        { field: 'dollers', headerName: 'Price', hide: false, width: 120 },
        { field: 'RR', headerName: 'Monthly', hide: false, width: 120 },
      ];

    }
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 31).then((config) => {

      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(columnSelections);

    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }

  }

  SaveOpportunity() {
    this.customerService.attachtoLead(this.opportunityId).then((user) => {
      this.applicationComponent.ClosedLeads('Forecasting', this.currentuser.id, 'Forecasting');
      this.opportunityId = 0;
    });
  }
  CancelselectedOpportunity() {
    this.Showtrigger = false;

  }
  AccountInfo() {
    // this.applicationComponent.ExpandSelectedMenu('Account');
    //$(".subButtons").removeClass("activeButton");
    //$('#allaccount_button').addClass("activeButton");
    //this.router.navigateByUrl(`/application/account-contact/all/show${this.account.id}`);
    
    //localStorage.setItem('RhxAccountVal', (this.account.id).toString());
    //let SO = this;
    //$(setTimeout(function () {
    //  SO.applicationComponent.activateSubButtons('empty');
    //  $(setTimeout(function () {
    //    SO.router.navigateByUrl(`/application/account/Accounts`);
    //    SO.applicationComponent.ExpandSelectedMenu('Accounts');
    //    $(".subButtons").removeClass("activeButton");
    //    SO.applicationComponent.selectedtabid = 'Customer';
    //  }, 100));
    //}, 50));

    localStorage.setItem('RhxContactVal', (this.contact.id).toString());

    let SO = this;
    $(setTimeout(function () {
      SO.applicationComponent.activateSubButtons('empty');
      $(setTimeout(function () {
        SO.router.navigateByUrl(`/application/contacts/contacts/null`);
        SO.applicationComponent.ExpandSelectedMenu('contact');
        SO.applicationComponent.selectedtabid = 'contact';
      }, 100));
    }, 50));


  }
  onCloseDialogEventReceived(val) {
    this.saleprocesshistory.notesDescription = null;
    if (val == 'false') {
      this.notesshowDialog = false;
      this.isModal = false;
    }
  }




  GetAllSalesStage(contactid) {
    this.leadservice.GetAllSalesStage(this.currentuser.id).then(result => {
      this.allSalesStages = result;
    
      let SO = this;
      setTimeout(function () {
        SO.SalesProcessHistory(contactid);
        SO.GetCompletedStage(contactid);
        SO.initializeAddLeadStageAgGrid();
                
      },500)

    });
  }

  SalesStageNote(SalesStage) {
    this.saleprocesshistory.notesDescription = SalesStage.notesDescription;
    this.SelectedStage = SalesStage;
    this.LeadStageNote = true;
    this.LeadStageNoteCss();
  }
  LeadStageNoteCss() {
    setTimeout(function () {
      $('#LeadStageNote .dialog').attr('style', 'width: 50%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min - height: 63 %;overflow: auto;height: 72%;padding: 0;');
    }, 4)

  }
  SelectedSaleStage(Obj, Event) {
    if (Obj.completeddate != null) {
      this.DateSelection = true;
    }
    this.StatusSelection = true;
    var status = Event.target.options[Event.target.selectedIndex].label;
    this.saleprocesshistory.status = status;
    this.saleprocesshistory.salesstage = Obj.SaleStage;
    this.saleprocesshistory.notesDescription = Obj.notesDescription;
    this.saleprocesshistory.id = Obj.id;

    if (Obj.TriggerType != null && status === "Completed") {
      this.Showtrigger = true;
      this.SelectedOpportunityCount = true;
      this.ShowtriggerDialogCss();
      this.GetOpportunity(this.salesstageid);
    }
    else if (status == 'Pending') {
      this.SalesStageNote(Obj)
    }
    return this.saleprocesshistory;
  }
  DateSelected(obj, event) {
    if (this.saleprocesshistory.status != null && this.saleprocesshistory.status != undefined) {
      this.StatusSelection = true;
    }
    this.DateSelection = true;
    //this.saleprocesshistory.status = obj.status;
    this.saleprocesshistory.completeddate = event.target.value;
    this.saleprocesshistory.salesstage = obj.SaleStage;
    this.saleprocesshistory.notesDescription = obj.notesDescription;
    this.saleprocesshistory.id = obj.id;
  }
  GetOpportunity(salesid) {
    this.leadservice.GetBySalesProcessHistoryById(salesid).then(result => {
      if (result != null) {
        this.opprtunityservice.GetAllOpportunitiesListByAccountorContact(result.contactid, 'Opt').then((customer) => {
          customer.forEach((customer) => {
            customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
            customer.actualclosedate = this.datePipe.transform(customer.actualclosedate, "MM-dd-yyyy");
            customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
          });
          this.Allopportunities = customer;
          this.itemCount = this.Allopportunities.length;

        });
        this.initializeAgGrid();
      }

    });

  }
  ShowtriggerDialogCss() {
    setTimeout(function () {
      $(setTimeout(function () {
        $('#Showtrigger .dialog').attr('style', 'width: 35%;padding-bottom: 0px;border: 1px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 82.5%;padding: 0;top: 10%;left: 63%;');
      }, 100));
      //$('#Showtrigger .dialog').attr('style', 'width: 42%;padding: 0;border: 4px solid rgb(117, 218, 51);min-height: 30%;overflow: auto;height: 35%;left: 54%;top: 60%;');
    }, 4)
  }
  onSelectionChanged(event) {
    var total = event.api.getSelectedNodes().length;
    if (total > 0) {
      this.SelectedOpportunityCount = false;
      if (event.api.getSelectedNodes()[0].data) {
        this.SelectedOpportunities = event.api.getSelectedNodes()[0].data;
        var text = this.SelectedOpportunities.estimatedclosedate + "    " + this.SelectedOpportunities.itemName + "   " + ("$" + (Number(this.SelectedOpportunities.dollers)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')) + "   " + ("$" + (Number(this.SelectedOpportunities.RR)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
        this.SelectedOpportunity = text;
      }
    }
  }
  SaveOpportunityForStage() {
    this.Showtrigger = !this.Showtrigger;
    this.saleprocesshistory.OpportunityID = this.SelectedOpportunities.id;
  }
  SaveNote(SelectedStage) {
    this.saleprocesshistory.notesDescription = this.saleprocesshistory.notesDescription;
    this.allSalesStages.forEach(ele => {
      if (ele.SalesStageID == SelectedStage.SalesStageID) {
        ele.notesDescription = this.saleprocesshistory.notesDescription;
        ele.completeddate = this.saleprocesshistory.completeddate;
        ele.status = this.saleprocesshistory.status;
      }
    });
    this.LeadStageNote = false;
    this.initializeAddLeadStageAgGrid();
  }
  CancelNote() {
    this.LeadStageNote = false;
  }
  EventEmit() {
    this.NewProposalPage = false;
    this.Showtrigger = !this.Showtrigger;
    this.SelectedOpportunityCount = true;
    this.ShowtriggerDialogCss();
    this.GetOpportunity(this.salesstageid);
  }

  addContact() {
    //if (this.practiceid == "view") {
    //  this.applicationComponent.isAccessDenied = true;
    //} else {
    if (this.userprevilages != undefined) {
      for (var j = 0; j < this.userprevilages.length; j++) {
        if ("Contacts" == this.userprevilages[j].resource) {
          if (this.userprevilages[j].canadd == false) {
            this.toastr.clear();
            this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }
        }
      }
    }
    this.showAddEditContact = true;
    this.showAddEditContactCss();
    //}
  }
  AccountsharingAg(selectedval) {
    //if (this.practiceid == "view") {
    //  this.applicationComponent.isAccessDenied = true;
    //} else {

    if (selectedval == "Edit") {
      this.isSharing = false;
      this.action = selectedval;
      this.showAddEditAccount = true;
      this.showAddEditAccountCss();
    }
    else if (selectedval == "View") {
      this.isSharing = false;
      this.action = selectedval;
      this.showAddEditAccount = true;
      this.showAddEditAccountCss();
    }
    else {
      this.isSharing = true;
      this.rightMainDivHideShow = false;
      this.accountSharingid = 1;
      this.AddContacttableDiv = false;
      this.ownrshipComp.loadAccountInformation(this.account.id, 0);
    }
    //}

  }
  showAddEditAccountCss() {
    setTimeout(function () {
      $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  showAddEditContactCss() {
    setTimeout(function () {
      $('#addEditContact .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  ContactReceiveEvent(msg) {
    this.showAddEditContact = false;
    //this.GetAllProviderAndContactInfo(this.account.id)
  }
  btnClickReceived(event) {
    
    if (event.btnName == 'addContact') {
      this.addContact();
    }
    else if (event.btnName == 'Accountsharing') {
      this.AccountsharingAg(event.val);
    }
    else if (event.btnName == 'openNotes') {
      //this.openAccountNotes();
    } else if (event.btnName == 'AccountInfo') {
      this.AccountInfo();
    }
  }
  ReceiveEvent(msg) {
    if (msg == "ExpandSoftwareLicense" || msg == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(msg);
    } else {

    }
  }



  ExpandAddEditAccountDialog(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 100%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 86%;padding: 0;top: 10%;left: 10%;max-width: 88%;');
      }, 4)
    } else {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
      }, 4)
    }
  }


  initializeAddLeadStageAgGrid() {
    this.AddLeadStagegridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressRowGroups: false, toolPanelSuppressPivotMode: false, toolPanelSuppressValues: false, rowSelection: 'Single'
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };

    this.createAddStageColumnDefs();
  }

  createAddStageColumnDefs() {
 
    let sObj = this;
    const DateCellRenderer = function () { };
    DateCellRenderer.prototype = {
      init: function (params) {
        this.DateactionsButton = document.createElement('div');
        var DateField = document.createElement("input");
        DateField.type = 'date';
        DateField.value = params.data.completeddate;
        DateField.id = 'CD' + params.data.SalesStageID;
        DateField.className = 'form-control';
        DateField.setAttribute('style', 'padding:0px;font-size:12px;background-color: white;opacity: 1;border:none');
        DateField.disabled = ((params.data.status !== 'Completed' && params.data.status !== 'Skipped' && params.data.status !== 'Pending' && params.data.status !== 'Won' && params.data.status !== 'Lost' && params.data.SaleStage !== 'IPC' ) ? true : false);
        this.DateactionsButton.appendChild(DateField);
        this.DateactionsButton.addEventListener('change', function ($event) {
          sObj.DateSelected(params.data, $event);
        });
      },
      getGui: function () {
        return this.DateactionsButton;
      },
      refresh: function () {
        return true;
      },
      destroy: function () {
        $(this.DateactionsButton).remove();
      },
    }

    const StatusCellRenderer = function () { };
    StatusCellRenderer.prototype = {
      init: function (params) {
        this.StatusactionsButton = document.createElement('div');
        var eSelect = document.createElement("select");
        eSelect.setAttribute('class', 'custom-select form-control');
        eSelect.setAttribute('style', 'padding:0px;font-size:12px;background-color: white;opacity: 1;border:none');
        eSelect.setAttribute('name', params.colDef.field);
        eSelect.setAttribute('id', 'Status' + params.data.SalesStageID);
        eSelect.disabled = ((params.data.status !== 'Completed' && params.data.status !== 'Skipped' && params.data.status !== '' && params.data.SaleStage !== 'IPC') ? true : false);
        var eOption = document.createElement("option");
        eOption.text = "Select";
        eOption.value = "";
        eSelect.appendChild(eOption);

        if (params.colDef.field == "status") {
          if (params && params.data && params.data.StatusItems && params.data.StatusItems.length > 0) {
            params.data.StatusItems.forEach((customer) => {
              var eOption = document.createElement("option");
              eOption.text = customer.StatusItemName;
              eOption.value = customer.StatusItemName;
              eOption.selected = (customer.StatusItemName == params.data.status) ? true : false;
              eSelect.appendChild(eOption);
            });
            
          }
          this.StatusactionsButton.appendChild(eSelect);
          eSelect.addEventListener('change', function ($event) {
            var res = sObj.SelectedSaleStage(params.data, $event);
          });
        }
      },
      getGui: function () {
        return this.StatusactionsButton;
      },
      refresh: function () {
        return true;
      },
      destroy: function () {
        $(this.StatusactionsButton).remove();
      },
    }

    this.documentscolumnDefs = [
      {
        field: '#', headerName: '#', cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.style.textAlign = 'center';
          eDiv.style.alignContent = 'center';
          if (params.data.status == 'Completed' || params.data.status == 'Skipped' || params.data.status == 'Won' || params.data.status == 'Lost' || params.data.status == 'Pending') {
            eDiv.innerHTML = '<i class="fa fa-check-circle" aria-hidden="true" style="color:#42b508;font-size: 18px;margin-top: 15%;"></i>'
          } else {
            eDiv.innerHTML = '<i class="fa fa-check-circle" aria-hidden="true" style="color:#badb98;font-size: 18px;margin-top: 15%;"></i>'
          }
          
          //var checkbox = document.createElement("input");
          //checkbox.type = 'checkbox';
          //checkbox.id = 'TG' + params.data.SalesStageID;
          //checkbox.checked = ((params.data.status == 'Completed' || params.data.status == 'Skipped' || params.data.status == 'Won' || params.data.status == 'Lost' || params.data.status == 'Pending' ) ? true : false);
          //checkbox.disabled = ((params.data.status !== 'Completed' && params.data.status !== 'Skipped' && params.data.status !== 'Won' && params.data.status !== 'Lost' && params.data.status !== 'Pending' ) ? true : false);
          //eDiv.appendChild(checkbox);
          return eDiv;

        }, hide: false, width: 35, suppressMenu: true,

      },
     
      {
        field: 'SaleStage', headerName: 'Sales Stage', hide: false, width: 120, suppressMenu: true, cellRenderer(params) {
          var label = document.createElement('span');
          if (params.data.status == 'Completed' || params.data.status == 'Skipped' || params.data.status == '' || params.data.status == 'Won' || params.data.status == 'Lost' || params.data.status == 'Pending' || params.data.SaleStage == 'IPC'){
            label.innerHTML = '<label style="color:black" id="Stage' + params.data.SalesStageID+'" >' + params.data.SaleStage + '</label>';
          } else {
            label.innerHTML = '<label style="color:lightgray" >' + params.data.SaleStage + '</label>';
          }
          

          return label;
        }
      },
      { field: 'status', headerName: 'Status', hide: false, cellRenderer: StatusCellRenderer, width: 100, suppressMenu: true, },
      { field: 'completeddate', headerName: 'Date', hide: false, cellRenderer: DateCellRenderer, width: 120, suppressMenu: true, suppressSizeToFit: false },
      //{
      //  field: 'notes', headerName: 'Notes', cellRenderer(params) {
      //    var eDiv = document.createElement('div');
      //    eDiv.style.textAlign = 'center';
      //    eDiv.style.alignContent = 'center';
      //    if (params.data.notesDescription == undefined || params.data.notesDescription == null || params.data.notesDescription == '') {
      //      eDiv.innerHTML = '<input type="image" src="../../../assets/img/Add Notes.jpg" style="height:20px;width:40%;margin-top:2%" />'
      //      eDiv.addEventListener('click', function () {
      //        selfObj.SalesStageNote(params.data);
      //      });
      //      return eDiv;
      //    } else if (params.data.notesDescription !== undefined || params.data.notesDescription !== null) {
      //      {
      //        eDiv.innerHTML = '<img src="../../../assets/img/Yellow Note.png" style="height:20px;width:40%;margin-top:2%"/>';
      //      }

      //      eDiv.addEventListener('click', function () {
      //        selfObj.SalesStageNote(params.data);
      //      });
      //      return eDiv;
      //    }
      //  }, hide: false, width: 70, suppressMenu: true,

      //}
    ];
  }

  SaveHistory() {
    this.SaveLeadDialog = true;
    this.applicationComponent.salesxcrmDialog('#SaveLeadDialog .dialog');
    //setTimeout(function () {
    //  $('#SaveLeadDialog .dialog').attr('style', 'width: 31%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%;right:68px;');
    //}, 4)
  }

  ExpandPDF(val) {
    this.notesshowDialog = true;
    this.QuickViewDialogCss(val);
    this.DocExpandView = val;
  }
  QuickViewDialogCss(val) {
    if (val == true) { //expand
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    } else {
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    }

  }

}
