import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { of } from 'rxjs';
//import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';
//import 'rxjs/add/operator/delay';
//import { Hero, heroes } from './../models/data-model';
@Injectable()
export class HeroService {

  constructor() { }

  delayMs = 500;

  // Fake server get; assume nothing can go wrong
  //getHeroes(): Observable<Hero[]> {
  //  return of(heroes).delay(this.delayMs); // simulate latency with delay
  //}

  // Fake server update; assume nothing can go wrong
  //updateHero(hero: Hero): Observable<Hero> {
  //  const oldHero = heroes.find(h => h.id === hero.id);
  //  const newHero = Object.assign(oldHero, hero); // Demo: mutate cached hero
  //  return of(newHero).delay(this.delayMs); // simulate latency with delay
  //}
}
