import { Injectable } from '@angular/core';
import { Messaging } from '../models/messaging';
import { Replymessages } from '../models/replymessages';
import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../app.settings';
import { Emails } from '../models/emails';
import { UserMessageTypes } from '../models/UserMessageTypes';
import { UserMessages } from '../models/UserMessages';
import { ChatMessage } from '../models/chatMessage';
@Injectable()
export class MessagingService {
  options: RequestOptions;
  authToken: any;
  reqHeader: HttpHeaders = new HttpHeaders();
  constructor(private http: HttpClient, private settings: AppSettings, private httpclient: HttpClient) {
    this.reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('key')
    });
    //this.authToken = localStorage.getItem('key');
    //this.headers = new Headers();
    //this.headers.append('Authorization', `Bearer ${this.authToken}`);
    //this.options = new RequestOptions({ headers: this.headers });
  }

  public GetAllSentMessagesList(currentuserid: number): Promise<Emails[]> {
    const promise = new Promise<Emails[]>((resolve, reject) => {
      this.httpclient.get<Emails[]>(this.settings.baseUrl + 'api/messaging/GetAllSentEmails/' + currentuserid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public Getallmessages(userid: string, MsgType: number, currentUserId: number, startdate: string, enddate: string, startrow: number, endrow: number): Promise<Messaging[]> {
    const promise = new Promise<Messaging[]>((resolve, reject) => {
      this.httpclient.get<Messaging[]>(this.settings.baseUrl + 'api/messaging/GetAllMessagesbyuser/' + userid + '/' + MsgType + '/' + currentUserId + '/' + startdate + '/' + enddate + '/' + startrow + '/' + endrow, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetallChats(userid: number): Promise<number> {
    const promise = new Promise<number>((resolve, reject) => {
      this.httpclient.get<number>(this.settings.baseUrl + 'api/chats/TotalUserCount/' + userid , { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  
  public GetallmessagesBymessage(userid: string, MsgId: number): Promise<Messaging[]> {
    const promise = new Promise<Messaging[]>((resolve, reject) => {
      this.httpclient.get<Messaging[]>(this.settings.baseUrl + 'api/messaging/GetAllReplyMessagesByMessage/' + userid + '/' + MsgId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public GetAllmessagesByUser(userid: string): Promise<Messaging> {
    const promise = new Promise<Messaging>((resolve, reject) => {
      this.httpclient.get<Messaging>(this.settings.baseUrl + 'api/messaging/GetAllMessagesByMessagecount/' + userid, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  
  GetMessageById(id: number): Promise<Messaging> {
    const promise = new Promise<Messaging>((resolve, reject) => {
      this.httpclient.get<Messaging>(this.settings.baseUrl + 'api/messaging/GetMessagedataById/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  GetReplyMessageById(id: number): Promise<Replymessages> {
    const promise = new Promise<Replymessages>((resolve, reject) => {
      this.httpclient.get<Replymessages>(this.settings.baseUrl + 'api/messaging/GetReplyMessagedataById/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  Updateisreadstatus(messageid: number, isreaduserids: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/messaging/GetUpdateisreadstatus/' + messageid + "/" + isreaduserids, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  GetInboxCount(id: number): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/messaging/GetInboxCountbyuser/' + id, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public SaveMessages(messaging: Messaging): Promise<Messaging> {

    const promise = new Promise<Messaging>((resolve, reject) => {
      this.httpclient.post<Messaging>(this.settings.baseUrl + "api/messaging/SaveMessages", messaging, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public replySaveMessages(replymessage: Replymessages): Promise<Replymessages> {

    const promise = new Promise<Replymessages>((resolve, reject) => {
      this.httpclient.post<Replymessages>(this.settings.baseUrl + "api/messaging/SaveReplyMessages", replymessage, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  
  DeleteMessage(messageid: number, deleteduserid: string, msgType: string, Msgval:string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.get<any>(this.settings.baseUrl + 'api/messaging/deletemessage/' + messageid + "/" + deleteduserid + "/" + msgType + "/" + Msgval, { headers: this.reqHeader } ).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }


  public AddorUpdateUserMessageTypes(ProposalCategory: UserMessageTypes): Promise<UserMessageTypes> {

    const promise = new Promise<UserMessageTypes>((resolve, reject) => {
      this.httpclient.post<UserMessageTypes>(this.settings.baseUrl + "api/messaging/AddorUpdateUserMessageTypes", ProposalCategory, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }


  public GetAllUserMessageTypes(userId: number): Promise<UserMessageTypes[]> {
    const promise = new Promise<UserMessageTypes[]>((resolve, reject) => {
      this.httpclient.get<UserMessageTypes[]>(this.settings.baseUrl + "api/messaging/GetAllUserMessageTypes/" + userId
        , { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public AddorUpdateUserMessages(usrmessage: UserMessages): Promise<UserMessages> {

    const promise = new Promise<UserMessages>((resolve, reject) => {
      this.httpclient.post<UserMessages>(this.settings.baseUrl + "api/messaging/AddorUpdateUserMessages", usrmessage, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  public GetAllUserMessages(userId: number): Promise<UserMessages[]> {
    const promise = new Promise<UserMessages[]>((resolve, reject) => {
      this.httpclient.get<UserMessages[]>(this.settings.baseUrl + "api/messaging/GetAllUserMessages/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }


  public GetUserMessagesByUser(userId: number): Promise<UserMessages[]> {
    const promise = new Promise<UserMessages[]>((resolve, reject) => {
      this.httpclient.get<UserMessages[]>(this.settings.baseUrl + "api/messaging/GetUserMessagesByUser/" + userId, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
}
