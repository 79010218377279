<div class="col-xs-12" style="padding: 0; height: 100%;">
  <div class="col-xs-12" style="height:90%;padding:0%;">

    <div class="col-xs-12" style="padding:0;height:100%" *ngIf="(type=='420'||type=='422' ||type=='423' ||type=='424') && show">

      <div class="col-xs-12" style="height: 100%; padding: 0; font-size: 15px" *ngIf="AccountOptionPage">
        <div class="col-xs-12" style="padding:0;height:10%;">
          <h4 class="fontStyleCss" style="margin-top:0">
            <span>ACCOUNT CONFIGURATION</span>
          </h4>
        </div>

      </div>

      <div class="col-xs-12" style="height:100%;padding:0" *ngIf="AddEditAccountField">
        <h4 class="fontStyleCss" style="margin-top:0">
          <span *ngIf="AddEditAccountField" style="margin-left:1%">Account Fields</span>

        </h4>
        <div class="col-xs-12" style="height:7%;display:flex;padding:0;">

          <div class="col-xs-7" style="padding:0;">
            <div class="col-xs-2" style="padding-left:0">
              <img src="../../../assets/img/Create New Document Name.png" (click)="ShowFieldForm('Accounts')" style="height:30px" />
            </div>
            <div class="col-xs-5" style="padding-left:0.5px">
              <select class="form-control" (change)="FilterByTypeOfField($event)">
                <option value="1"> Display All Types of Fields </option>
                <option value="2"> Display Standard Fields </option>
                <option value="3"> Display Custom Fields </option>
              </select>
            </div>
            <div class="col-xs-5" style="padding:0">
              <select class="form-control" (change)="SortByType($event)">
                <option value="1">System Display Sort Order</option>
                <option value="2">System Display A-Z</option>
              </select>
            </div>

          </div>
        </div>
        <div class="col-xs-12" style="height:87%;display:flex;padding:0;">

          <div class="col-xs-7" style="padding:0">
            <ag-grid-angular style="height:100%;" class="ag-theme-balham"
                             [gridOptions]="gridOptionsView"
                             [columnDefs]="columnDefs"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="DuplicateTableColumns"
                             [rowDragManaged]="true"
                             [suppressMoveWhenRowDragging]="true"
                             [sideBar]="sideBar"
                             [animateRows]="true"
                             (selectionChanged)="onSelectionChanged($event)"
                             (gridReady)="onAccountAgGridReady($event)"
                             (rowDragEnd)="onAccountRowDragEnd($event)">
            </ag-grid-angular>
          </div>

          <div class="col-xs-5" style="padding:0;height:100%">
            <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="ShowAccountFieldForm">
              <form #accountFieldform="ngForm" style="height:100%;padding:0;">
                <div class="col-xs-12" style="height:90%">
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Field Name</label>
                    </div>
                    <div class="col-xs-8">
                      <div class="col-xs-12" style="padding:0;">
                        <input class="form-control" required id="Fname" name="FieldName" [(ngModel)]="AccountFields.FieldName" [disabled]="DisableFields" />
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Type of Field</label>
                    </div>
                    <div class="col-xs-8">
                      <input class="form-control" id="TypeOfField" name="TypeOfField" [(ngModel)]="AccountFields.TypeOfField" [disabled]="DisableFields" />
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Display As</label>
                    </div>
                    <div class="col-xs-8">
                      <input class="form-control" id="DisplayAs" name="DisplayAs" [(ngModel)]="AccountFields.DisplayAs" required />

                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Required Field</label>
                    </div>
                    <div class="col-xs-8">
                      <select class="form-control" name="RequiredField" [(ngModel)]="AccountFields.RequiredField" required>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Data Type</label>
                    </div>
                    <div class="col-xs-8">
                      <select class="form-control" name="DataType" [(ngModel)]="AccountFields.DataType" required [disabled]="DisableFields">

                        <option value="null" disabled="disabled">Select Data Type</option>
                        <option value="Pick List">Pick List</option>
                        <option value="Text Box">Text Box</option>
                        <option value="Numeric">Numeric</option>
                        <option value="Date/Time">Date/Time</option>
                        <option value="Date">Date</option>
                        <option value="Date/YearOnly">Date/Year Only</option>
                        <option value="System Lookup">System Lookup</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Pick List Editable</label>
                    </div>
                    <div class="col-xs-8">
                      <select class="form-control" name="PickListEditable" [(ngModel)]="AccountFields.PickListEditable" required [disabled]="DisableFields">
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Data Format</label>
                    </div>
                    <div class="col-xs-8">
                      <select class="form-control" name="DataFormat" [(ngModel)]="AccountFields.DataFormat" required [disabled]="DisableFields">
                        <option value="0" disabled="disabled"> Select Data Format</option>
                        <option value="Alphanumeric"> Alphanumeric</option>
                        <option value="Alpha"> Alpha</option>
                        <option value="Numeric"> Numeric</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Max limit</label>
                    </div>
                    <div class="col-xs-8">
                      <input class="form-control" id="MaxLimit" name="MaxLimit" [(ngModel)]="AccountFields.MaxLimit" required [disabled]="DisableFields" />

                    </div>
                  </div>
                  <div class="col-xs-12" style="height:21%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Description</label>
                    </div>
                    <div class="col-xs-8">
                      <textarea class="form-control" rows="4" type="text" name="notedescription"
                                style="height:100%" [(ngModel)]="AccountFields.Description" required></textarea>
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Sort Order</label>
                    </div>
                    <div class="col-xs-8">
                      <input class="form-control" id="SortOrder" name="SortOrder" [(ngModel)]="AccountFields.SortOrder" required [disabled]="DisableFields" />

                    </div>
                  </div>
                </div>
                <div class="col-xs-12" style="height:10%;float:right;padding-right:15px">
                  <div class="col-xs-6" style="padding: 0;">
                    <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="CloseAccountField('Add/Edit')">Cancel</button>
                  </div>
                  <div class="col-xs-6" style="padding-right: 0;">
                    <button type="submit" class="btn saveButton" style="width:100%;" [disabled]="(!accountFieldform.form.valid)" (click)="SaveAccountField('Accounts')">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>

      <div class="col-xs-12" style="padding:0;height:100%" *ngIf="AccountFieldConfiguration">
        <h4 class="fontStyleCss" style="margin-top:0">
          <span style="margin-left:1%">Account Fields Configuration</span>

        </h4>
      
        <div class="col-xs-12" style="margin-top:0">
          <!--<div class="col-xs-12" style="padding:0;height:7%">-->
          <div class="col-xs-12" style="padding:0" *ngIf="ShowFieldItemTable">
            <div class="col-xs-2" style="padding-left:0">
              <img src="../../../assets/img/Create New Document Name.png" (click)="(SelectedField.PickListEditable == 'Yes')?AddNewFieldData(0,'Add','Accounts'):''" style="height:30px" />
            </div>

            <div class="col-xs-5" style="padding: 0;">
              <select class="form-control" (change)="PickListSortByType($event.target.value)" [(ngModel)]="SelectedField.FieldDataOrderBy">
                <option value="SortOrder">System Display Sort Order</option>
                <option value="FieldName">System Display A-Z</option>
              </select>
            </div>
            <div class="col-xs-5" style="float:right">
              <select class="form-control" (change)="PickListSortByType($event.target.value)" [(ngModel)]="SelectedField.FieldDataOrderBy">
                <option value="SortOrder">Grid By Sort Order</option>
                <option value="FieldName">Grid Order A-Z</option>
              </select>
            </div>


          </div>
          <!--</div>-->
        </div>
        <div class="col-xs-12" style="padding: 0.5% 0 0 0; height: 93%">
          <div class="col-xs-6" style="padding:0;height:100%;overflow:auto">

            <div class="multiselect">
              <table style="width:100%">
                <tr style="background-color:#d6dfe2;">
                  <th style="width:6%"></th>
                  <th style="padding-left:1%">Field Name </th>
                  <th style="text-align:center">Type Of Field</th>
                  <th style="text-align:center">Tab Type</th>
                  <th style="text-align:center">Data Type</th>
                  <th style="text-align:center">Pick List<br /> Editable</th>
                </tr>
                <tr *ngFor="let field of PickListColumns">
                  <td style="text-align:center"><input type="checkbox" class="FieldCheckBox" id="chkBox{{field.id}}" (change)="onCheckboxChange(field,$event)" /></td>
                  <td style="padding-left:1%">{{field.FieldName}}</td>
                  <td style="text-align:center">{{field.TypeOfField}}</td>
                  <td [ngStyle]="{'color':field.TableName=='Mailing Address' ? 'red':field.TableName=='Account Specifics' ? 'blue' : 'green' }"
                      style="text-align: center; width:24%;">
                    {{field.TableName}}
                  </td>
                  <td style="text-align:center">{{field.DataType}}</td>
                  <td style="text-align:center">{{field.PickListEditable}}</td>
                </tr>

              </table>


            </div>

          </div>
          <div class="col-xs-6" style="height:100%">

            <div class="col-xs-12" style="padding:0;height:92%;">
              <div class="col-xs-12" style="height:100%;padding-left:0;" *ngIf="ShowFieldItemTable">
                <div class="col-xs-12 scroll-c" style="height:90%;padding:0;">
                  <table style="width:100%">
                    <tr style="background-color:#d6dfe2;height: 38px;text-align: center;">
                      <th style="width:6%"></th>
                      <th style="display:none">Id</th>
                      <th style="text-align:center;width:15%">Sort Order</th>
                      <th style="text-align:center;width:60%">Pick List Name</th>
                      <th style="text-align:center;width:18%">Actions</th>
                    </tr>
                    <tbody [dragula]='"AccountFieldData-bag"' id="{{SelectedField.ColumnName}}">
                      <tr *ngFor="let fieldData of FiledDataList;let i = index" id="PickListRow{{fieldData.id}}" (click)="PickListRowClick(fieldData,'Accounts')">
                        <td style="text-align:center;width:6%" id="PickList{{fieldData.id}}"><input type="checkbox" /></td>
                        <td style="text-align: center; display: none;" id="PickListID{{fieldData.id}}">{{fieldData.id}}</td>
                        <td style="text-align:center;width:15%" id="PickListSortOrder{{fieldData.id}}" (keyup)="keyupPickList(fieldData.id)">{{fieldData.Sortorder}}</td>
                        <td style="text-align:left;padding-left:1%;width:60%;" id="PickListName{{fieldData.id}}" (keyup)="keyupPickList(fieldData.id)">{{fieldData.itemName}}</td>
                        <td style="text-align:center;width:18%"><i class="fa fa-trash" aria-hidden="true" *ngIf="fieldData.id !=0 && SelectedField.PickListEditable == 'Yes'" (click)="DeletePickList(fieldData,'Accounts')"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-xs-12" style="padding:2% 0 0 0;height:8%">
                  <div class="col-xs-6"></div>
                  <div class="col-xs-6">
                    <div class="col-xs-6" style="padding: 0;">
                      <button class="btn btn-default" style="width: 100%; border:1px solid">Cancel</button>
                    </div>
                    <div class="col-xs-6" style="padding-right: 0;">
                      <button type="submit" class="btn saveButton" style="width:100%;" (click)="SavePickListData('Accounts')">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12" style="padding:0;height:100%" *ngIf="AccountPageConfiguration">
        <h4 class="fontStyleCss" style="margin-top:0">
          <span style="margin-left:1%">{{SelectedConfigurationType}}</span>

        </h4>

        <div class="col-xs-6" style="padding:0;height:93%;border:1px solid lightgrey">
          <div class="col-xs-12" style="height:6%"><label style="font-size:18px;color:rgb(146, 208, 80);float:left" (click)="PreviewForm('Open')">Preview</label></div>
          <div class="col-xs-12" style="height:94%;padding:0;">
            <div class="col-xs-12" style="height:94%;padding:0 1% 0 1%;">
              <div class="col-xs-12 scroll-c" style="padding:0;height:90%">


                <div class="col-xs-12" style="padding:0;height:6%;margin-top:0.5%;" *ngFor="let item of DuplicateTableColumns;let i=index;">
                  <div class="col-xs-11" style="padding:0;height:100%;width:94%">
                    <div class="dropdownAcc">
                      <div class="selectBox" (click)="myFunction(i)">
                        <select style="height:100%;border-radius:5px;">
                          <option id="SelectOption{{i}}" value="null">Select Field</option>
                        </select>
                        <div class="overSelect"></div>
                      </div>
                      <div id="myDropdownAcc{{i}}" class="dropdownAcc-content scroll-c">
                        <table style="width:100%;border:none;">
                          <tr style="background-color:#d6dfe2;">
                            <th style="width:6%"></th>
                            <th style="text-align: center">Field Name</th>
                            <th style="text-align:center">Display As</th>
                          </tr>
                          <tbody>
                            <tr *ngFor="let item of DuplicateTableColumns">
                              <td style="text-align:center"><input type="checkbox" class="FieldSelectionCheckBox" id="FieldSelectionCheck{{i}}Id{{item.id}}" (change)="SelectedFieldChkChange(i,item,$event)" /></td>
                              <td style="padding-left:1%">{{item.FieldName}}</td>
                              <td style="padding-left: 1%">{{item.DisplayAs}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>


                  </div>
                  <div class="col-xs-1" style="padding:0;height:100%;width:6%;text-align:center">
                    <i class="fa fa-trash" aria-hidden="true" id="Delete{{i}}Id" (click)="DeletePageField(i,item,$event)"></i>
                  </div>
                </div>


              </div>
              <div class="col-xs-12" style="padding:0;height:10%">
                <div class="col-xs-4" id="SaveCancelOpp" style="float:right;height:9%">
                  <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width:100%; background:white" id="CancelOpp"><span class="mainCheckMark"></span>Cancel</button></div>
                  <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width:100%; background:#a2e477" (click)="SaveFields();" id="saveOpp"><span class="mainCheckMark"></span>Save</button></div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="col-xs-12" style="padding:0%;height:100%" *ngIf="(type=='500') && show">
      <div class="col-xs-12" style="height:100%;padding:0;font-size:15px" *ngIf="AccountSharingHomePage">
        <div class="col-xs-12" style="padding:0;height:10%;">
          <h4 class="fontStyleCss" style="margin-top:0">
            <span style="text-transform: uppercase;">Account Sharing Groups</span>

          </h4>

        </div>
      </div>

      <div class="col-xs-12" style="height:100%;padding:0" *ngIf="AccGroupNames">
        <h4 class="fontStyleCss" style="margin-top:0">
          <span *ngIf="AccGroupNames" style="margin-left:1%">Group Names</span>

        </h4>
        <div class="col-xs-12" style="height:7%;display:flex;padding:0;">

          <div class="col-xs-7" style="padding:0;">
            <div class="col-xs-1" style="padding-left:0">
              <img src="../../../assets/img/Create New Document Name.png" (click)="ShowFieldForm('AccountSharingGroup')" style="height:30px" />
            </div>


          </div>
        </div>
        <div class="col-xs-12" style="height:87%;display:flex;padding:0;">

          <div class="col-xs-7" style="padding:0">
            <ag-grid-angular style="height:100%;" class="ag-theme-balham"
                             [gridOptions]="ShareGroupsgridOptions"
                             [columnDefs]="columnDefs"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="SharingGroupsList"
                             [sideBar]="sideBar"
                             [rowDragManaged]="true"
                             [animateRows]="true"
                             (rowDragEnd)="onRowDragEnd($event)"
                             (sortChanged)="printSortStateToConsole($event)"
                             (selectionChanged)="onSharingGroupSelectionChanged($event)"
                             (gridReady)="onAgGridReady($event)">
            </ag-grid-angular>
          </div>

          <div class="col-xs-5" style="padding:0;height:100%">
            <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="ShowShareGroupFieldForm">
              <form #ShareGroupFieldform="ngForm" style="height:100%;padding:0;">
                <div class="col-xs-12" style="height:90%">
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Group Name</label>
                    </div>
                    <div class="col-xs-8">
                      <div class="col-xs-12" style="padding:0;">
                        <input class="form-control" required id="GroupName" name="GroupName" [(ngModel)]="SharingGroup.SharingGroupName" />

                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Sort Order</label>
                    </div>
                    <div class="col-xs-8">
                      <input class="form-control" id="ConSortOrder" name="SortOrder" [(ngModel)]="SharingGroup.SortOrder" required />

                    </div>
                  </div>
                </div>
                <div class="col-xs-12" style="height:10%;float:right;padding-right:15px">
                  <div class="col-xs-6" style="padding: 0;">
                    <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="CloseAccGroupNames('Close_Add/Edit')">Cancel</button>
                  </div>
                  <div class="col-xs-6" style="padding-right: 0;">
                    <button type="submit" class="btn saveButton" style="width:100%;" [disabled]="(!ShareGroupFieldform.form.valid)" (click)="SaveAccountShareGroup()">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


    </div>

    <div class="col-xs-12" style="padding:0%;height:100%" *ngIf="(type=='700'||type=='701'||type=='702') && show">
    
      <div class="col-xs-12" style="height:100%;padding:0" *ngIf="AddEditContactFields">
        <h4 class="fontStyleCss" style="margin-top:0">
          <span *ngIf="AddEditContactFields" style="margin-left:1%">Contact Fields</span>
        </h4>

        <div class="col-xs-12" style="height:7%;display:flex;padding:0;">

          <div class="col-xs-7" style="padding:0;">
            <div class="col-xs-2" style="padding-left:0">
              <img src="../../../assets/img/Create New Document Name.png" (click)="ShowFieldForm('Contacts')" style="height:30px" />
            </div>
            <div class="col-xs-5" style="padding-left:0.5px">
              <select class="form-control" (change)="FilterByTypeOfField($event)">

                <option value="1"> Display All Types of Fields </option>
                <option value="2"> Display Standard Fields </option>
                <option value="3"> Display Custom Fields </option>
              </select>


            </div>
            <div class="col-xs-5" style="padding:0">
              <select class="form-control" (change)="SortByType($event)">

                <option value="1">System Display Sort Order</option>
                <option value="2">System Display A-Z</option>

              </select>
            </div>

          </div>
        </div>
        <div class="col-xs-12" style="height:87%;display:flex;padding:0;">

          <div class="col-xs-7" style="padding:0">
            <ag-grid-angular style="height:100%;" class="ag-theme-balham"
                             [gridOptions]="gridOptionsView"
                             [columnDefs]="columnDefs"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [rowData]="DuplicateTableColumns"
                             [rowDragManaged]="true"
                             [suppressMoveWhenRowDragging]="true"
                             [sideBar]="sideBar"
                             [animateRows]="true"
                             (selectionChanged)="onSelectionChanged($event)"
                             (gridReady)="onContactAgGridReady($event)"
                             (rowDragEnd)="onContactRowDragEnd($event)">
            </ag-grid-angular>
          </div>

          <div class="col-xs-5" style="padding:0;height:100%">
            <div class="col-xs-12" style="height:100%;padding:0;" *ngIf="ShowContactFieldForm">
              <form #contactFieldform="ngForm" style="height:100%;padding:0;">
                <div class="col-xs-12" style="height:90%">
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Field Name</label>
                    </div>
                    <div class="col-xs-8">
                      <div class="col-xs-12" style="padding:0;">
                        <input class="form-control" required id="ConFname" name="FieldName" [(ngModel)]="ContactFields.FieldName" [disabled]="DisableFields" />

                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Type of Field</label>
                    </div>
                    <div class="col-xs-8">
                      <input class="form-control" id="ConTypeOfField" name="TypeOfField" [(ngModel)]="ContactFields.TypeOfField" [disabled]="DisableFields" />
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Display As</label>
                    </div>
                    <div class="col-xs-8">
                      <input class="form-control" id="ConDisplayAs" name="DisplayAs" [(ngModel)]="ContactFields.DisplayAs" required />

                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Required Field</label>
                    </div>
                    <div class="col-xs-8">
                      <select class="form-control" name="RequiredField" [(ngModel)]="ContactFields.RequiredField" required [disabled]="DisableFields">
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Data Type</label>
                    </div>
                    <div class="col-xs-8">
                      <select class="form-control" name="DataType" [(ngModel)]="ContactFields.DataType" required [disabled]="DisableFields">

                        <option value="null" disabled="disabled">Select Data Type</option>
                        <option value="Pick List">Pick List</option>
                        <option value="Text Box">Text Box</option>
                        <option value="Numeric">Numeric</option>
                        <option value="Date">Date</option>
                        <option value="Date/YearOnly">Date/Year Only</option>
                        <option value="Date/Time">Date/Time</option>
                        <option value="System Lookup">System Lookup</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Pick List Editable</label>
                    </div>
                    <div class="col-xs-8">
                      <select class="form-control" name="PickListEditable" [(ngModel)]="ContactFields.PickListEditable" required [disabled]="DisableFields">
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Data Format</label>
                    </div>
                    <div class="col-xs-8">
                      <select class="form-control" name="DataFormat" [(ngModel)]="ContactFields.DataFormat" required [disabled]="DisableFields">
                        <option value="0" disabled="disabled"> Select Data Format</option>
                        <option value="Alphanumeric"> Alphanumeric</option>
                        <option value="Alpha"> Alpha</option>
                        <option value="Numeric"> Numeric</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Max limit</label>
                    </div>
                    <div class="col-xs-8">
                      <input class="form-control" id="ConMaxLimit" name="MaxLimit" [(ngModel)]="ContactFields.MaxLimit" required [disabled]="DisableFields" />

                    </div>
                  </div>
                  <div class="col-xs-12" style="height:21%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Description</label>
                    </div>
                    <div class="col-xs-8">
                      <textarea class="form-control" rows="4" type="text" name="notedescription" style="height:100%" [(ngModel)]="ContactFields.Description" required></textarea>
                    </div>
                  </div>
                  <div class="col-xs-12" style="height:8%;padding:0">
                    <div class="col-xs-4" style="display:flex;height:100%;padding-top:1%">
                      <label>Sort Order</label>
                    </div>
                    <div class="col-xs-8">
                      <input class="form-control" id="ConSortOrder" name="SortOrder" [(ngModel)]="ContactFields.SortOrder" required [disabled]="DisableFields" />

                    </div>
                  </div>
                </div>
                <div class="col-xs-12" style="height:10%;float:right;padding-right:15px">
                  <div class="col-xs-6" style="padding: 0;">
                    <button class="btn btn-default" style="width: 100%; border:1px solid" (click)="ContactsField('Close_Add/Edit')">Cancel</button>
                  </div>
                  <div class="col-xs-6" style="padding-right: 0;">
                    <button type="submit" class="btn saveButton" style="width:100%;" [disabled]="(!contactFieldform.form.valid)" (click)="SaveAccountField('Contacts')">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ContactFieldsConfiguration">

        <div class="col-xs-12 fontStyleCss" style="margin-top:0">
          <span *ngIf="ContactFieldsConfiguration" >Contact Fields Configuration</span>
        </div>
        <div class="col-xs-12 fontStyleCss" style="margin-top:0">         
            <div class="col-xs-12" style="padding:0" *ngIf="ShowFieldItemTable">
              <div class="col-xs-2" style="padding-left:0">
                <img src="../../../assets/img/Create New Document Name.png" (click)="(SelectedField.PickListEditable == 'Yes')?AddNewFieldData(0,'Add','Contacts'):''" style="height:30px" />
              </div>
              <div class="col-xs-5" style="padding: 0;">
                <select class="form-control" (change)="PickListSortByType($event.target.value)" [(ngModel)]="SelectedField.FieldDataOrderBy">
                  <option value="SortOrder">System Display Sort Order</option>
                  <option value="FieldName">System Display A-Z</option>
                </select>
              </div>
              <div class="col-xs-5" style="float:right">
                <select class="form-control" (change)="PickListSortByType($event.target.value)" [(ngModel)]="SelectedField.FieldDataOrderBy">
                  <option value="SortOrder">Grid By Sort Order</option>
                  <option value="FieldName">Grid Order A-Z</option>
                </select>
              </div>
            </div>
        
        </div>

        <div class="col-xs-12" style="padding: 0.5% 0 0 0; height: 93%">
          <div class="col-xs-6" style="padding:0;height:100%">
            <div class="col-xs-12" style="padding:0;height:7%">
              <div class="col-xs-12" style="height: 100%; width: 98%; padding: 0;">
                <div class="multiselect">
                  <table style="width:100%">
                    <tr style="background-color:#d6dfe2;">
                      <th style="width:6%"></th>
                      <th style="padding-left:1%">Field Name </th>
                      <th style="text-align:center">Type Of Field</th>
                      <th style="text-align:center">Data Type</th>
                      <th style="text-align:center">Pick List<br /> Editable</th>
                    </tr>
                    <tr *ngFor="let field of PickListColumns">
                      <td style="text-align:center"><input type="checkbox" class="FieldCheckBox" id="chkBox{{field.id}}" (change)="onCheckboxChange(field,$event)" /></td>
                      <td style="padding-left:1%">{{field.FieldName}}</td>
                      <td style="text-align:center">{{field.TypeOfField}}</td>
                      <td style="text-align:center">{{field.DataType}}</td>
                      <td style="text-align:center">{{field.PickListEditable}}</td>
                    </tr>

                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-6" style="padding:0;height:100%">         
            <div class="col-xs-12" style="padding:0;height:92%;">
              <div class="col-xs-12" style="height:100%;padding-left:0;" *ngIf="ShowFieldItemTable">
                <div class="col-xs-12 scroll-c" style="height:90%;padding:0;">


                  <table style="width:100%">
                    <tr style="background-color:#d6dfe2;height: 38px;text-align: center;">
                      <th style="width:6%"></th>
                      <th style="display:none"></th>
                      <th style="text-align:center;width:15%">Sort Order</th>
                      <th style="text-align:center;width:60%">Pick List Name</th>
                      <th style="text-align:center;width:18%">Actions</th>
                    </tr>
                    <tbody [dragula]='"ContactFieldData-bag"' id="{{SelectedField.ColumnName}}">
                      <tr *ngFor="let fieldData of FiledDataList;let i = index" id="PickListRow{{fieldData.id}}" (click)="PickListRowClick(fieldData,'Contacts')">
                        <td style="text-align:center;width:6%" id="PickList{{fieldData.id}}"><input type="checkbox" /></td>
                        <td style="text-align:center;display:none" id="PickListID{{fieldData.id}}">{{fieldData.id}}</td>
                        <td style="text-align:center;width:15%" id="PickListSortOrder{{fieldData.id}}" (keyup)="keyupPickList(fieldData.id)">{{fieldData.Sortorder}}</td>
                        <td style="text-align:left;padding-left:1%;width:60%;" id="PickListName{{fieldData.id}}" (keyup)="keyupPickList(fieldData.id)">{{fieldData.itemName}}</td>
                        <td style="text-align:center;width:18%"><i class="fa fa-trash" aria-hidden="true" *ngIf="fieldData.id !=0 && SelectedField.PickListEditable == 'Yes'" (click)="DeletePickList(fieldData,'Contacts')"></i></td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ContactPageConfiguration">

          <h4 class="fontStyleCss" style="margin-top:0">
            <span style="margin-left:1%">Contact Page Configuration</span>

          </h4>
          <div class="col-xs-6" style="padding:0;height:93%;border:1px solid lightgrey">
            <div class="col-xs-12" style="height:6%"><label style="font-size:18px;color:rgb(146, 208, 80);float:left" (click)="PreviewForm('Open')">Preview</label></div>
            <div class="col-xs-12" style="height:94%;padding:0;">
              <div class="col-xs-12" style="height:94%;padding:0 1% 0 1%;">
                <div class="col-xs-12 scroll-c" style="padding:0;height:90%">


                  <div class="col-xs-12" style="padding:0;height:6%;margin-top:0.5%;" *ngFor="let item of DuplicateTableColumns;let i=index;">
                    <div class="col-xs-11" style="padding:0;height:100%;width:94%">
                      <div class="dropdownAcc">
                        <div class="selectBox" (click)="myFunction(i)">
                          <select style="height:100%;border-radius:5px;">
                            <option id="SelectOption{{i}}" value="null">Select Field </option>
                          </select>
                          <div class="overSelect"></div>
                        </div>
                        <div id="myDropdownAcc{{i}}" class="dropdownAcc-content scroll-c">
                          <table style="width:100%;border:none;">
                            <tr style="background-color:#d6dfe2;">
                              <th style="width:6%"></th>
                              <th style="text-align: center">Field Name </th>
                              <th style="text-align:center">Display As</th>
                            </tr>
                            <tbody>
                              <tr *ngFor="let item of DuplicateTableColumns">
                                <td style="text-align:center"><input type="checkbox" class="FieldSelectionCheckBox" id="FieldSelectionCheck{{i}}Id{{item.id}}" (change)="SelectedFieldChkChange(i,item,$event)" /></td>
                                <td style="padding-left:1%">{{item.FieldName}}</td>
                                <td style="padding-left: 1%">{{item.DisplayAs}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>


                    </div>
                    <div class="col-xs-1" style="padding:0;height:100%;width:6%;text-align:center">
                      <i class="fa fa-trash" aria-hidden="true" id="Delete{{i}}Id" (click)="DeletePageField(i,item,$event)"></i>
                    </div>
                  </div>


                </div>
                <div class="col-xs-12" style="padding:0;height:10%">
                  <div class="col-xs-4" id="SaveCancelOpp" style="float:right;height:9%">
                    <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width:100%; background:white" id="CancelOpp"><span class="mainCheckMark"></span>Cancel</button></div>
                    <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width:100%; background:#a2e477" (click)="SaveFields();" id="saveOpp"><span class="mainCheckMark"></span>Save</button></div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="col-xs-12" style="padding:0;height:100%" *ngIf="ContactPageConfiguration">
        <h4 class="fontStyleCss" style="margin-top:0">
          <span style="margin-left:1%">Contact Page Configuration</span>

        </h4>

        <div class="col-xs-6" style="padding:0;height:100%;border:1px solid lightgrey">
          <div class="col-xs-12" style="height:6%"><label style="font-size:18px;color:rgb(146, 208, 80);float:left" (click)="PreviewForm('Open')">Preview</label></div>
          <div class="col-xs-12" style="height:94%;padding:0;">
            <div class="col-xs-12" style="height:94%;padding:0 1% 0 1%;">
              <div class="col-xs-12 scroll-c" style="padding:0;height:90%">


                <div class="col-xs-12" style="padding:0;height:6%;margin-top:0.5%;" *ngFor="let item of DuplicateTableColumns;let i=index;">
                  <div class="col-xs-11" style="padding:0;height:100%;width:94%">
                    <div class="dropdownAcc">
                      <div class="selectBox" (click)="myFunction(i)">
                        <select style="height:100%;border-radius:5px;">
                          <option id="SelectOption{{i}}" value="null">Select Field </option>
                        </select>
                        <div class="overSelect"></div>
                      </div>
                      <div id="myDropdownAcc{{i}}" class="dropdownAcc-content scroll-c">
                        <table style="width:100%;border:none;">
                          <tr style="background-color:#d6dfe2;">
                            <th style="width:6%"></th>
                            <th style="text-align: center">Field Name</th>
                            <th style="text-align:center">Display As</th>
                          </tr>
                          <tbody>
                            <tr *ngFor="let item of DuplicateTableColumns">
                              <td style="text-align:center"><input type="checkbox" class="FieldSelectionCheckBox" id="FieldSelectionCheck{{i}}Id{{item.id}}" (change)="SelectedFieldChkChange(i,item,$event)" /></td>
                              <td style="padding-left:1%">{{item.FieldName}}</td>
                              <td style="padding-left: 1%">{{item.DisplayAs}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>


                  </div>
                  <div class="col-xs-1" style="padding:0;height:100%;width:6%;text-align:center">
                    <i class="fa fa-trash" aria-hidden="true" id="Delete{{i}}Id" (click)="DeletePageField(i,item,$event)"></i>
                  </div>
                </div>


              </div>
              <div class="col-xs-12" style="padding:0;height:10%">
                <div class="col-xs-4" id="SaveCancelOpp" style="float:right;height:9%">
                  <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width:100%; background:white" id="CancelOpp"><span class="mainCheckMark"></span>Cancel</button></div>
                  <div class="col-xs-6" style="padding:0;"><button class="btn noprovider" style="width:100%; background:#a2e477" (click)="SaveFields();" id="saveOpp"><span class="mainCheckMark"></span>Save</button></div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-dialog [(visible)]="DeleteMatchPopUp" *ngIf="DeleteMatchPopUp" id="DeleteMatchPopUp">
  <div class="col-xs-12" style="padding:0;height:100%">
    <div class="col-xs-12" style="height:10%">
      <label style="font-size: 27px;font-weight: 900;padding: 0 0 0 3%;">SalesXCRM Message</label>
    </div>
    <div class="col-xs-10" style="padding: 0 0 0 8%; height: 10%; margin-top: 1%;">
      <span style="font-size:18px;">You are Deleting the following {{DeletePickListObj.Category}} :&nbsp;</span><span style="font-size:18px;color:red;text-decoration:underline">{{DeletePickListObj.itemName}}:</span><br />
    </div>

    <div class="col-xs-12" style="padding: 0 0 0 8%; height: 20%; font-size: 18px;">
      <span>The system has found the {{DeletePickListObj.Category}} being used in the system. </span><br />
      <span>Before deleting you must select another {{DeletePickListObj.Category}} </span><br />
      <span>to replace the {{DeletePickListObj.Category}} you are Deleting.</span><br />
    </div>
    <div class="col-xs-12" style="padding:0 0 0 8%;height:58%">
      <div class="col-xs-12" style="padding:0;height:10%">
        <span style="font-size:18px;">Please select a {{DeletePickListObj.Category}} to replace this {{DeletePickListObj.Category}} </span>
      </div>
      <div class="col-xs-12" style="padding:0;height:88%">
        <div class="col-xs-7 scroll-c" style="padding:0;height:100%;overflow:auto;width:55%">

          <angular2-multiselect [data]="documenttypeList" [(ngModel)]="ReplaceItem"
                                [settings]="dropdownSetting">
          </angular2-multiselect>

        </div>
        <div class="col-xs-5" style="padding:0;height:100%">
          <div class="col-xs-12" style="height:80%"></div>
          <div class="col-xs-12" style="height:20%">
            <div class="col-xs-6"><button (click)="Confirmation(false)" class="btn noprovider" style="width: 100%; height: 36px; color: #008CBA; background-color: white; border: 1px solid lightgrey !important;">Cancel</button></div>

            <div class="col-xs-6"><button class="btn saveButton" style="width:100%;height: 36px;" (click)="ReplaceAccountType()">5Save</button></div>

          </div>
        </div>
      </div>
    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="PreviewAccountForm" *ngIf="PreviewAccountForm" id="PreviewAccountForm" [closable]="false">
  <div class="col-xs-12" style="padding:0;height:100%">
    <div class="col-xs-12" style="height:6%"><label style="font-size:18px;color:rgb(146, 208, 80);float:left" (click)="PreviewForm('Close')">Close Preview</label></div>
    <div class="col-xs-12" style="height:94%;padding:0;">
      <div class="col-xs-12" style="height:94%;padding:0 1% 0 1%;">
        <div class="col-xs-12 scroll-c" style="padding:0;height:90%">

          <table id="FieldTable" class='table table-striped sortable ' [dragula]='"Questions-bag"' style="margin-bottom:0;border:none">

            <tbody *ngFor="let item of AccountFieldsSelected;let i=index;" style="border:none;height:35px !important;">
              <tr class="Tr" id="{{item.id}}" style="height:35px !important;cursor:move">
                <td style="border: none;height:35px !important;padding: 1% 0 0 0;display:none;" id="{{item.id}}FieldID"><label>{{item.id}}</label></td>
                <td style="border: none;height:35px !important;padding: 1% 0 0 0"><label style="margin-top:1%">{{item.DisplayAs}}</label></td>
                <td style="border: none;height:35px !important;padding: 1% 0 0 0">
                  <div class="col-xs-12" style="padding:0;height:100%" *ngIf="item.DataType == 'Pick List'|| item.DataType == 'System Lookup' || item.DataType=='Date/YearOnly' || item.DataType == 'System Lookup'">
                    <select style=" height: 100%; width: 100%; ">
                      <option>Select</option>
                    </select>
                  </div>

                  <div class="col-xs-12" style="padding:0;height:100%" *ngIf="item.DataType =='Text Box' || item.DataType =='Numeric'">
                    <input type="text" style=" height: 100%; width: 100%; " />
                  </div>
                  <div class="col-xs-12" style="padding:0;height:100%" *ngIf="(item.DataType == 'Date' ||  item.DataType == 'Date/Time')">
                    <input type="date" style=" height: 100%; width: 100%;" />
                  </div>
                </td>

              </tr>
            </tbody>
          </table>

        </div>
        <div class="col-xs-12" style="padding:0;height:10%"></div>
      </div>
    </div>
  </div>
</app-dialog>

<app-dialog [(visible)]="showAccountContactDialog">
  <div>
    <div class="col-xs-12" style="text-align:center;font-size:15px;padding:0;">
      <br />
      The ID you have selected to DELETE is being used by an<br />
      Account / Contact. Please select an ID that will replace this ID.<br /> <br />

    </div>
    <div class="col-xs-12">
      <div class="col-xs-5" style="padding:0;">
        <label>
          Please select Other ID :
        </label>
      </div>
      <div class="col-xs-7" *ngIf="dropdownCategory == 'Accounts'" style="padding:0;">
        <select class="form-control" id="accountId" [(ngModel)]="replaceId">
          <option value="null" [selected]="true">Select ID 1</option>
          <option *ngFor="let acc of  AccountIdArr" value={{acc.id}}>
            {{acc.itemName}}
          </option>
        </select>
      </div>
      <div class="col-xs-7" *ngIf="dropdownCategory=='Contacts'" style="padding:0;">
        <select class="form-control" id="contactId" [(ngModel)]="replaceId">
          <option value="null" [selected]="true">Select ID 1</option>
          <option *ngFor="let con of  contactIdArr" value={{con.id}}>
            {{con.itemName}}
          </option>
        </select>
      </div>
    </div>


    <div class="col-xs-12" style="margin-top:7%">
      <div class="col-xs-6" style="padding-right: 0;">
        <button class="btn" style="width:100%;height: 35px;border:3px solid rgb(162, 228, 119) !important;border-radius:5px;" (click)="Replaceanddelete(this.dropdownid,this.dropItemName,replaceId)">Replace & Delete </button>
      </div>
      <div class="col-xs-6">
        <button class="btn" style="width:100%;height: 35px;border:3px solid lightgrey !important;border-radius:5px;">Cancel</button>
      </div>
    </div>
  </div>
</app-dialog>
