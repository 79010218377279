import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GridOptions } from 'ag-grid';
import { Role } from '../../models/Role';
import { UserService } from '../../services/user.service';
import { ApplicationComponent } from '../application.component';

@Component({
  selector: 'user-role-configuration',
  templateUrl: './user-role-configuration.component.html',
  styleUrls: ['./user-role-configuration.component.css']
})
export class UserRoleConfigurationComponent implements OnInit {
  @Input()
  selectedvalue: number;
  allRoles: Role[];
  public gridOptions: GridOptions;
  public columnDefs;
  public context; RoleForm = new FormGroup({});
  orole: Role = new Role();
  ShowRolesForm: boolean = false;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  constructor(public applicationComponent: ApplicationComponent, private userservice: UserService) { }

  ngOnInit(): void {
    this.initializeAgGrid();
    this.RoleForm = new FormGroup({
      'rolename': new FormControl(this.orole.rolename, Validators.nullValidator),
      'Salesrep': new FormControl(this.orole.Salesrep, Validators.nullValidator),
      'SortOrder': new FormControl(this.orole.SortOrder, Validators.nullValidator)

    });
  }
  onAgGridReady(event) { }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true//, rowSelection: 'single'
    };
    this.LoadRoles();
    this.createColumnDefs();
  }
  close() {
    var url = window.location.href;
    this.applicationComponent.displayheader(url);
  }
  CancelRole() {
    this.ShowRolesForm = false;
    this.orole = new Role();
  }
  SaveRole() {
    this.userservice.saveRole(this.orole).then(result => {
      this.LoadRoles();
      this.orole = new Role();
      this.ShowRolesForm = false;
    });
  }
  LoadRoles() {
    this.userservice.GetAllRoles().then((Roles) => {
      this.allRoles = Roles;
    });
  }
  createColumnDefs() {
    let selfObj = this;
    this.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 52,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      {
        headerName: "User Role",
        field: "rolename",
        hide: false

      },
      {
        headerName: "Sales Rep",
        field: "Salesrep",
        hide: false

      },
      {
        headerName: "Sort Order",
        field: "SortOrder",
        hide: false

      },
      {
        headerName: "Actions",
        field: "Actions", pinned:'right',width:100,
        hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:20%"/>';
          eDiv.addEventListener('click', function () {
            selfObj.DeleteRole(params.data);
          });
          return eDiv;
        }

      },
    ];
  }
  DeleteRole(data) {
    this.orole = data;
    this.orole.isdeleted = 1;
    this.SaveRole();
  }
  onRoleSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.ShowRolesForm = true;
        this.orole = event.api.getSelectedNodes()[0].data;
      }
    } else {
      this.orole = new Role();
    }

  }
}
