import { Component, Input, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { Projects } from '../../../models/ProjectConfiguration';
import { CustomerService } from '../../../services/customer.service';
import { ApplicationComponent } from '../../application.component';
import { EditdeletecvComponent } from '../../editdeletecv/editdeletecv.component';
declare var $: any;
import * as moment from 'moment'
import { Router } from '@angular/router';

@Component({
  selector: 'app-projects-dashboard',
  templateUrl: './projects-dashboard.component.html',
  styleUrls: ['./projects-dashboard.component.css']
})
export class ProjectsDashboardComponent implements OnInit {
  @Input() ProjectsType: string = 'Open Projects';
  @Input() Type: string = 'Open';
  public gridOptionsView: GridOptions;
  public PendedgridOptionsView: GridOptions;
  public CancelledgridOptionsView: GridOptions;
  public CompletedgridOptionsView: GridOptions;
  public columnDefs;
  public sideBar;
  public frameworkComponents;
  public context;
  currentuser: any;
  OpenProjectsList: Projects[] = [];
  ProjectsList: Projects[] = [];
  PendedProjectsList: Projects[] = [];
  CompletedProjectsList: Projects[] = [];
  CancelProjectsList: Projects[] = [];
  

  constructor(private router: Router,public applicationComponent: ApplicationComponent, private customerservice: CustomerService ) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = (JSON.parse(localStorage.getItem("SelectedUserIds"))) ? JSON.parse(localStorage.getItem("SelectedUserIds")) : [];
    if (this.applicationComponent.selectedItems != null && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {
      this.getSelectedUserList(this.currentuser.id);
    }
  }

  ngOnInit(): void {
    this.initializeAgGrid();
    switch (this.ProjectsType) {
      case 'Open Projects': {
        this.getOpenProjects();
        break;
      }
      case 'Pended Projects': {
        this.getPendedProjects();
        break;
      }
      case 'Completed Projects': {
        this.getCompletedProjects(null,null);
        break;
      }
      case 'Cancelled Projects': {
        this.getCancelledProjects(null, null);
        break;
      }
    }
    this.getAllProjects(null,null);
  }

  getSelectedUserList(val) {

    this.applicationComponent.selectedItems = [
      { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
    ];
    localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
    $("#userids").val(this.currentuser.id);

  }

  initializeAgGrid() {
    this.gridOptionsView = <GridOptions>{    
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.PendedgridOptionsView = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.CancelledgridOptionsView = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.CompletedgridOptionsView = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, paginationPageSize: 1000, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
   
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeletecvComponent,

    };
    this.createColumnDefs("");
  }
  createColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.columnDefs = [
      //{
      //  headerName: '#',
      //  field: '#',
      //  width: 52,
      //  checkboxSelection: true,
      //  suppressSorting: true,
      //  suppressMenu: true,
      //  pinned: true,
      //  hide: false, outerWidth: 8,
      //  headerCheckboxSelection: true,
      //  headerCheckboxSelectionFilteredOnly: true
      //},
      { field: 'AccountName', headerName: 'Account Name', hide: false },
      { field: 'ProjectStatusName', headerName: 'Project Status', hide: false },
      { field: 'ProjectTypeName', headerName: 'Project TypeId', hide: false },
      { field: 'ProjectLeaderName', headerName: 'Project LeaderId', hide: false },

      { field: 'TotalProjects', headerName: 'TotalProjects', hide: true },
      { field: 'OpenProjects', headerName: 'OpenProjects', hide: true },
      { field: 'PendedProjects', headerName: 'PendedProjects', hide: true },
      {
        field: 'CompletedProjects', headerName: 'CompletedProjects', hide: true, cellRenderer: function (params) {
          if (params.value != null) {

            return params.value;

          } else {
            return '0';
          }
        }
      },
      {
        field: 'CancelledProjects', headerName: 'CancelledProjects', hide: true, cellRenderer: function (params) {
          if (params.value != null) {

            return params.value;

          } else {
            return '0';
          }
        }
      },
      { field: 'TargetGoLiveDate', headerName: 'TargetGoLiveDate', hide: true },
      { field: 'NumberofTrainers', headerName: '# Trainers', hide: true },
      { field: 'OnsiteDateStatus1Name', headerName: 'OnsiteDateStatus1', hide: true },
      { field: 'OnsiteTrainingDate1From', headerName: 'OnsiteTrainingDate1', hide: true },
      { field: 'OnsiteTrainingDate1To', headerName: 'OnsiteTrainingDate1', hide: true },
      { field: 'OnsiteTrainingDate2From', headerName: 'OnsiteTrainingDate2', hide: true },
      { field: 'OnsiteTrainingDate2To', headerName: 'OnsiteTrainingDate2', hide: true },
      { field: 'OnsiteTrainingHours', headerName: 'OnsiteTrainingHours', hide: true },
      //{ field: 'PickListEditable', headerName: 'Pick List Editable', hide: false },
      
    
      { field: 'OnsiteTrainingName', headerName: 'OnsiteTraining', hide: true },
      { field: 'RemoteTrainingName', headerName: 'RemoteTraining', hide: true },
      { field: 'RemoteTrainingDate1From', headerName: 'RemoteTrainingDate1', hide: true },
      { field: 'RemoteTrainingDate1To', headerName: 'RemoteTrainingDate1', hide: true },
      { field: 'RemoteDateStatus1Name', headerName: 'RemoteDateStatus1', hide: true },
      { field: 'RemoteTrainingDate2From', headerName: 'RemoteTrainingDate2', hide: true },
      { field: 'RemoteTrainingDate2To', headerName: 'RemoteTrainingDate2', hide: true },
      { field: 'RemoteDateStatus2Name', headerName: 'RemoteDateStatus2', hide: true },
      { field: 'RemoteTrainingHours', headerName: 'RemoteTrainingHours', hide: true },
      { field: 'Trainer1Name', headerName: 'Trainer1', hide: true },
      { field: 'Trainer2Name', headerName: 'Trainer2', hide: true },
      { field: 'UserAccessName', headerName: 'UserAccess', hide: true },
      { field: 'Notes', headerName: 'Notes', hide: true },
      
    ];


  }
  getAllProjects(startDate, endDate) {
    this.customerservice.getAllProjects(this.currentuser.id).then(res => {
      res.forEach(ele => {
        ele.CreatedDate = moment(ele.CreatedDate).format("YYYY-MM-DD");
      });

      this.ProjectsList = res;
    

    });
  }
  cellDoubleClicked(event) {
    
    let SO = this;
    $(setTimeout(function () {
      SO.applicationComponent.activateMainButtons('empty');
      $(setTimeout(function () {     
        SO.applicationComponent.activateMainButtons('Projects');
        $(".subButtons").removeClass("activeButton");
        SO.applicationComponent.selectedtabid = 'Projects';
        if (SO.Type == 'Open') {
          localStorage.setItem('projecttype', 'Open');
          localStorage.setItem('projectid', event.data.ProjectID);
        }
        else if (SO.Type == 'Pended') {
          localStorage.setItem('projecttype', 'Pended');
          localStorage.setItem('Pendedprojectid', event.data.ProjectID);
        }
        else if (SO.Type == 'Completed') {
          localStorage.setItem('projecttype', 'Complete');
          localStorage.setItem('Completedprojectid', event.data.ProjectID);
        }
        else if (SO.Type == 'Cancelled') {
          localStorage.setItem('projecttype', 'Cancelled');
          localStorage.setItem('Cancelledprojectid', event.data.ProjectID);
        }
      }, 100));
    }, 50));

  }
  getOpenProjects() {
    this.customerservice.getAllProjects(this.currentuser.id).then(res => {
      res.forEach(ele => {
        ele.CreatedDate = moment(ele.CreatedDate).format("YYYY-MM-DD");
      });
      this.OpenProjectsList = res.filter(x => x.ProjectStatusName == 'Open')
      if (Number(localStorage.getItem("projectid")) > 0) {
        this.RowSelection(Number(localStorage.getItem("projectid")));
      }
    });
  }
  RowSelection(projectId) {
    let selfObj = this;
    $(setTimeout(function () {
      if (selfObj.gridOptionsView.api != null) {
        selfObj.gridOptionsView.api.forEachNode((node) => {
          if (node.data.ProjectID == projectId) {
            selfObj.gridOptionsView.api.selectNode(node, true);
            selfObj.gridOptionsView.rowSelection = 'single';
          }
        });
      }
    }, 300));
  }

  CancelledRowSelection(projectId) {
    let selfObj = this;
    $(setTimeout(function () {
      if (selfObj.CancelledgridOptionsView.api != null) {
        selfObj.CancelledgridOptionsView.api.forEachNode((node) => {
          if (node.data.ProjectID == projectId) {
            selfObj.CancelledgridOptionsView.api.selectNode(node, true);
            selfObj.CancelledgridOptionsView.rowSelection = 'single';
          }
        });
      }
    }, 300));
  }
  

  PendedRowSelection(projectId) {
    let selfObj = this;
    $(setTimeout(function () {
      if (selfObj.PendedgridOptionsView.api != null) {
        selfObj.PendedgridOptionsView.api.forEachNode((node) => {
          if (node.data.ProjectID == projectId) {
            selfObj.PendedgridOptionsView.api.selectNode(node, true);
            selfObj.PendedgridOptionsView.rowSelection = 'single';
          }
        });
      }
    }, 300));
  }
  getPendedProjects() {
    this.customerservice.getAllProjects(this.currentuser.id).then(res => {
      res.forEach(ele => {
        ele.CreatedDate = moment(ele.CreatedDate).format("YYYY-MM-DD");
      });
      this.PendedProjectsList = res.filter(x => x.ProjectStatusName == 'Pended')
      if (Number(localStorage.getItem("Pendedprojectid")) > 0) {
        this.PendedRowSelection(Number(localStorage.getItem("Pendedprojectid")));
      }
    });
  }
  getCompletedProjects(startDate, endDate) {
    this.CompletedProjectsList = [];
    this.customerservice.getAllProjects(this.currentuser.id).then(res => {
      res.forEach(ele => {
        ele.CreatedDate = moment(ele.CreatedDate).format("YYYY-MM-DD");
      });      
      if (startDate != null && endDate != null) {
        var data = this.CompletedProjectsList = res.filter(x => x.ProjectStatusName == 'Open')
        this.CompletedProjectsList = data;
        this.initializeAgGrid();
      } else {
        this.CompletedProjectsList = res.filter(x => x.ProjectStatusName == 'Complete')
      }
      if (Number(localStorage.getItem("Completedprojectid")) > 0) {
        this.RowSelection(Number(localStorage.getItem("Completedprojectid")));
      }
    });
  }
  getCancelledProjects(startDate, endDate) {

    this.customerservice.getAllProjects(this.currentuser.id).then(res => {
      res.forEach(ele => {
        ele.CreatedDate = moment(ele.CreatedDate).format("YYYY-MM-DD");
      });
      this.CancelProjectsList = [];
      if (startDate != null && endDate != null) {
        this.CancelProjectsList = res.filter(x => (x.CreatedDate > startDate) && (x.CreatedDate < endDate))
      }
      else {
        this.CancelProjectsList = res.filter(x => x.ProjectStatusName == 'Cancelled')
      }
      if (Number(localStorage.getItem("Cancelledprojectid")) > 0) {
        this.CancelledRowSelection(Number(localStorage.getItem("Cancelledprojectid")));
      }
    });
  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        let rowData = event.api.getSelectedNodes()[0].data;       
      }
    }

  }

  LoadCompletedProjects(type, startDate: string, endDate: string) {
   
    this.ProjectsType = type;
    switch (this.ProjectsType) {
      case 'Completed Projects': {
        this.Type = '';
        this.getCompletedProjects(startDate, endDate);
        //this.Type = 'Completed';
        break;
      }
      case 'Cancelled Projects': {
        this.getCancelledProjects(startDate, endDate);
        break;
      }
    }

  }
}
