<div class="container" style="padding:0">
  <input type="text" id="accotIdNotes" style="display:none;" />
  <input type="text" id="accotTypeNotes" style="display:none;" />
  <div *ngIf="PageFrom!='dashboard'" class="col-xs-12" style="padding:0.3% 0 0 0;">
    <userdataheader [parentComp]="'Lead'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>

  </div>
  <div *ngIf="leadtype!='Lead' && contactid!=undefined && !addLead && PageFrom!='dashboard'" class="col-xs-12" style="padding:0.3% 0 0.3% 0;" id="actiondivid">

    <div *ngIf="ForcastDiv" class="col-md-5 col-sm-6 col-xs-12" style="padding:  0;">
      <div class="col-xs-6" style="padding:0;">
        <div class="col-md-4 col-sm-1 col-xs-3" style="padding:  0;text-align:center">

          <label style="padding-top:5px">Lead Status</label>
        </div>
        <div class="col-md-8 col-sm-11 col-xs-9" style="padding: 0%; ">
          <select id="lead" class="form-control" required>
            <option *ngFor="let lead of  LeadstatusList" value={{lead.itemName}}>
              {{lead.itemName}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xs-6" style="padding:0;">
        <div class="col-xs-12" style="padding-right:0;">
          <button *ngIf="this.isDisplayCommissionEstimator" (click)="CommissionEstimator(false)" class="" style="padding:0;height:28px;background:white;">
            <img src="../../assets/img/range on.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
          </button>
          <button *ngIf="!this.isDisplayCommissionEstimator" (click)="CommissionEstimator(true)" class="" style="padding:0;height:28px;background:white;">
            <img src="../../assets/img/range off.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
          </button>
          <div class="col-xs-9" style="padding:0;float:right;margin:2% 4% 0 0"><span [ngStyle]="{'color':isDisplayCommissionEstimator ? 'black' : 'gray' }"> Display Commission Estimator</span></div>
        </div>
      </div>

    </div>

  </div>
 
  <div class="col-xs-12" style="padding:0;" [style.height.%]="(addLead)? '100' : '89.5' ">

    <div [ngClass]="(addLead)? 'col-xs-4':'col-xs-12'" id="LeadInformation" style="height:100%;padding:0;" *ngIf="!addLeadExpand && !OpenProppage" [style.border]="(applicationComponent.searchCriteriadata != '' && applicationComponent.searchCriteriadata != 'No Matched Results')?'3px solid #00B0F0':'none'">
      <div style="height:4%" *ngIf="addLead">
        <b style="font-size: 10pt; margin-left: 12px"><span (click)="CloseLeadsDiv()"><img src="../../../assets/img/Black x.png" style="height: 21px; float: left; transform: rotate(180deg);" /></span> LEADS  <!--( {{allLeads ? allLeads.length: '0'}} )--></b>
        <span (click)="expandLeadsView('Expand')"><img src="../../../assets/img/Open Quickview Grid Panel.png" style="height: 23px; float: right; transform: rotate(180deg);" /></span>
      </div>
      <div class="col-xs-12 input-icons" style="padding: 7px; margin-bottom: 0.3%; border: 1px solid lightgray; width:100%">
        <i class="fa fa-search icon"></i>
        <input type="text" class="searchform-control" id="filter-text-box" placeholder="Search" (input)="onFilterTextBoxChanged()" [(ngModel)]="value" />
      </div>
      <ag-grid-angular style="width: 100%;" id="gridheight" class="ag-theme-balham" [style.height.%]="(addLead)? '88.5' : '95'"
                       [gridOptions]="gridOptions"
                       [columnDefs]="columnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="allLeads"
                       [context]="context"
                       [sideBar]="sideBar"
                       [frameworkComponents]="frameworkComponents"
                       (rowSelected)="rowClickAgGrid($event)"
                       (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                       (selectionChanged)="OnselectionChanged($event)"
                       (columnVisible)="columnVisible($event)"
                       (dragStopped)="dragStopped($event)"
                       (columnResized)="columnResized($event)"
                       (gridReady)="onAgGridReady($event)">
      </ag-grid-angular>

    </div>
    <div class="col-xs-4" style="padding:0;height:100%;" *ngIf="addLeadExpand">
      <div class="col-xs-12" style="padding:0;height:4%">
        <span (click)="expandLeadsView('Collapse')"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
      </div>
      <div class="col-xs-12 scroll-c" style="padding:0;height:100%">
        <app-new-notes style="height:100%"
                       [selectedTabName]="'Display All'"
                       [conid]="contactid"
                       [typ]="'Contact'"
                       [ViewType]="'Block'"
                       [isModal]="false">
        </app-new-notes>
      </div>
    </div>

    <div id="addLead" [ngClass]="(OpenProppage)? 'col-xs-12':'col-xs-8'" *ngIf="addLead" style="padding:0% 0 0 1%;height:100%">
      <leads [contactid]="contactid" [id]="leadid" (outPropsEvent)="LeadMsgReceived($event)"></leads>
    </div>
  </div>
  <div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
    <textarea *ngIf="isShowDetailsChecked" id="txtUserFullName" readonly rows="3" name="userInfo" [(ngModel)]="userInfo" style="width:100%; border: none;"></textarea>
    <div class="col-xs-12" style="padding:0;">
      <button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog" style="float:left;position:unset; "><i class="fa fa-window-close-o"></i></button>
    </div>
    <div class="box" style="height:98%;border:none">
      <span *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" class="editnote">Edit</span>
      <span *ngIf="showEditNoteBtn" (click)="saveEditedNote()" class="save">Save</span>
      <div id="txtLongNoteDesc" class="text">
      </div>
    </div>

  </div>
</div>
<app-dialog [(visible)]="showDialog" *ngIf="showDialog" id="showDialog" [CloseButtonColor]="'white'">

  <app-new-notes style="height:100%"
                 [selectedTabName]="'Contact Only'"
                 [conid]="conid"
                 [typ]="'Contact'"
                 [isModal]="true">
  </app-new-notes>


</app-dialog>
