<span><button (click)="editRowAg()" class="btn-xs btn-default" style="border: none"><i class="fa fa-pencil" aria-hidden="true"></i></button></span>
<span *ngIf="params.data.type == 'Contact'"><button (click)="convertContactAg()" style="border: none" class="btn-xs btn-default"><b>CV</b></button></span>
<span *ngIf="params.data.type == 'Contact'"><button (click)="callcheck()" style="border: none" class="btn-xs btn-default"><i class="fa fa-check" aria-hidden="true"></i></button></span>

<span style="padding:0;"><button id="Sharing" *ngIf="needSharing() && params.data.ownerid==currentuser.id" (click)="sharingAg()" [ngStyle]="{'backgroundColor':(params.data.permission == 'view' ||params.data.isshared==1) ? 'white' : (params.data.permission == 'edit' || params.data.isshared==1 ? 'white' : 'white' ) ,'color':(params.data.permission == 'view' ||params.data.isshared==1) ? 'rgb(61,184,50)' : (params.data.permission == 'edit' ? 'rgb(61,184,50)' : 'black' ) }" class="btn-xs btn-default"><i style="height:13px;width:13px;" class="fa fa-share-alt" aria-hidden="true"></i></button></span>

<span style="padding:0;"><button id="Sharing" *ngIf="needSharing() && params.data.ownerid!=currentuser.id" (click)="sharingAg()" [ngStyle]="{'backgroundColor':(params.data.permission == 'view' ||params.data.isshared==1) ? 'white' : (params.data.permission == 'edit' || params.data.isshared==1 ? 'white' : 'white' ) ,'color':(params.data.permission == 'view' ||params.data.isshared==1) ? 'rgb(61,184,50)' : (params.data.permission == 'edit' ? 'rgb(61,184,50)' : 'black' ) }" class="btn-xs btn-default"><i style="height:13px;width:13px;" class="fa fa-share-alt" aria-hidden="true"></i></button></span>

<span *ngIf="params.data.calllistcontactstatus!='Deleted'"><button (click)="deleteRowAg() " style="border: none" class="btn-xs btn-default"><i class="fa fa-trash" aria-hidden="true"></i></button></span>
<span *ngIf="params.data.calllistcontactstatus=='Deleted'"><button (click)="deletetoOpenRowAg() " style="border: none" class="btn-xs btn-default"><i class="fa fa-undo" aria-hidden="true"></i></button></span>
