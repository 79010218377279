import { Component, OnInit, Input, Optional } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { Calllist } from '../../models/calllist';
import { Contacts } from '../../models/contacts';
import { AccountsService } from './../../services/accounts.service';
import { DatePipe } from '@angular/common';
import { CustomerService } from '../../services/customer.service';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { Column } from 'ag-grid';
import { Accounts } from 'src/app/models/accounts';
import { ApplicationComponent } from '../application.component';
declare var $: any;
@Component({
  selector: 'calllisthistory',
  templateUrl: './calllisthistory.component.html',
  styleUrls: ['./calllisthistory.component.css']
})

export class CalllisthistoryComponent implements OnInit, ICellRendererAngularComp {
  CallHistoryDialog = false;
  @Input()
  id: number;
  @Input()
  type: string;
  public gridOptions: GridOptions;
  public columnDefs: any[];
  rowData: Calllist[];
  datePipe: DatePipe = new DatePipe("en-US");
  contact: Contacts = new Contacts();
  contactid: number;
  account: Accounts = new Accounts();
  public context;
  itemResource: any;
  itemCount = 0;
  currentuser: any; selectedItems = [];
  public sideBar;
  constructor(private accountsService: AccountsService, public applicationComponent: ApplicationComponent, private customerService: CustomerService, private opprtunityservice: OpprtunitiesService) { this.currentuser = JSON.parse(localStorage.getItem("currentUser")); }

  ngOnInit() {
    this.initializeAgGrid();
  }
  GetAllCallHistory(type,contactid) {
    this.accountsService.GetAllCallHistorylist(type, contactid).then((calllist) => {
      calllist.forEach(calllist => {
        calllist.created = this.datePipe.transform(calllist.created, "MM-dd-yyyy HH:mm a");
        calllist.lastaccesed = this.datePipe.transform(calllist.lastaccesed, "MM-dd-yyyy HH:mm a");
      });
      this.rowData = calllist;
      this.itemCount = calllist.length;
    });
  }
  LoadAllCalllistcalllistHistory(type, contactid) {
    if (type == 'Customer' || type =='Account') {      
      this.contactid = contactid;
    } else {
      this.customerService.GetContactById(contactid).then(result => {
        this.contact = result;
        this.opprtunityservice.GetByAccountId(this.contact.accountid).then(result => {
          this.account = result;
        });
        if (type != "No") {
          document.getElementById('grid_call').style.display = 'block';
          document.getElementById('NoCallHistory').style.display = 'none';
          this.GetAllCallHistory(type, contactid);
         
        } else {
          this.itemCount = 0;
          document.getElementById('grid_call').style.display = 'none';
          document.getElementById('NoCallHistory').style.display = 'block';
        }
      });
    }
  }
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: false, enableColResize: true, paginationPageSize: 1000,enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'multiple'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 35).then((config) => {     
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefs(columnSelections);

    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;

  }
  columnVisible(event) {
    this.saveConfiguration();
     
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();
    config.maxlimit = 25;   
    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentuser.id;
    config.resourceid = 35;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    this.opprtunityservice.saveDashboardconfiguration(config); 
  }
  createColumnDefs(columnSelections: string) {
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefs.push({ field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, cellStyle: function (params) { if (params.value == 'Open' || params.value == 'Pending') { return { color: 'red' }; } } });
        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefs = [
        { field: 'created', headerName: 'Date Created', hide: false },
        { field: 'calllistname', headerName: 'Call List Name', hide: false },
        { field: 'LastUserAccessed', headerName: 'Created By', hide: false },
        { field: 'Assignedto', headerName: 'Assigned To', hide: false },
        { field: 'status', headerName: 'Status', hide: false, cellStyle: function (params) { if (params.value == 'Open') { return { color: 'red' }; } } },
        { field: 'lastaccesed', headerName: 'Date Completed', hide: false }
      ];
    }
  }
  onAgGridReady(event) {
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAll();
      $(setTimeout(function () {
        selfObj.autoSizeAll();
      }, 400));
    }, 400));
  }
  autoSizeAll() {
  
  }
  refresh(): boolean {
    return false;
  }
  onCallHistory(msg) {
    var data = JSON.parse(msg);
    this.type = 'CalllHistory';
    this.LoadAllCalllistcalllistHistory(data.callliststatus, data.id);
  }
  displayCallHistoryNotes(contactid, status) {
    this.LoadAllCalllistcalllistHistory(status, contactid);
  }
  Backtocontacts() {
    this.type = 'Customer';
  }
}
//export class CalllisthistoryComponent implements OnInit {

//  constructor() { }

//  ngOnInit() {
//  }

//}
