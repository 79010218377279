export class GroupChat {
  public groupId: number;
  public groupName: string;
  public createdBy: number;
  public createdat: string;
  public grpusers: string;

}


