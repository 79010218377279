import { Injectable } from '@angular/core';

import { Subject, Observable } from "rxjs";
import { Notification, NotificationType } from "./notification";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _subject = new Subject<Notification>();
  private _idx = 0;


  constructor() { }

  getObservable(): Observable<Notification> {
    return this._subject.asObservable();
  }

  Message(title: any, timeout = 0) {
    this._subject.next(new Notification(this._idx++, NotificationType.error, title, timeout));
  }


}
