export class Dropdowntype {
  public id: number;
  public itemName: string;
    public category: string;  
    public isdeleted: number;
  public taskgroupid: number;
}
export class Dropdown {
    public Text: string;
    public Value: string;
    public id: string;
    public itemName: string;
  public category: string;
  public taskgroupid: number;
}
export class ResourcePrivileges {
  public MainResourceId: number;
  public MainResourceName: string;
  public SubResourceId: number;
  public SubResourceName: string;
  public ResourceType: string;
  public IsMain: number;
  public IsChecked: boolean;
  public UserPrivilegeId: number;
  public CanView: boolean;
  public CanAdd: boolean;
  public CanEdit: boolean;
  public CanDelete: boolean;
}
export class SaveResourcePrivileges {
  public ResourcePrivileges: ResourcePrivileges[];
  public AssignedUserId: number;
  public UserId: number;
}

export class RevenueModel {
  public OpportunityWonTotalPrice: number;
  public OpportunityWonTotalRR: number;
  public OpportunityLostTotalPrice: number;
  public OpportunityLostTotalRR: number;
  public OpportunityOpenTotalPrice: number;
  public OpportunityOpenTotalRR: number;
  public fromDate: string
  public toDate: string;
  public UserIds: string;
}


export class ForecastDataModel {
  public ForeCastSalesMonth: number = 0;;
  public ForeCastSalesYear: number = 0;
  public ForeCastRRMonth: number = 0;
  public ForecastRRYear: number = 0;
  public SalesWonMonth: number = 0;
  public SalesWonYear: number = 0;
  public SalesLostMonth: number = 0;
  public SalesLostYear: number = 0;
  public RRWonMonth: number = 0;
  public RRWonYear: number = 0;
  public RRLostMonth: number = 0;
  public RRLostYear: number = 0;
  public fromDate: string;
  public toDate: string;
  public UserIds: string;
}
