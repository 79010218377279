import { Component, OnInit, Input } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { SassDateContacts } from '../../models/SassDateContacts';
import { Column } from 'ag-grid';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { ApplicationComponent } from '../application.component';
import { Router } from '@angular/router';

@Component({
  selector: 'sassdate-contacts',
  templateUrl: './sassdate-contacts.component.html',
  styleUrls: ['./sassdate-contacts.component.css']
})
export class SassdateContactsComponent implements OnInit {
  public frameworkComponents; public context; private gridApi; public sideBar;
  @Input() selectedreporttype = "Contacts(Saas by Date)";
  public gridOptions: GridOptions;
  public columnDefs: any[];
  currentuser: any;
  allContacts: SassDateContacts[];
  colSelections: string;
  public ReportType: string;
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };  
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal
  constructor(public applicationComponent: ApplicationComponent, private opprtunityservice: OpprtunitiesService, private router: Router) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.initializeAgGrid();
     
  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  initializeAgGrid() {
    this.context = this;
    this.frameworkComponents = {
    }
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    }; 
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, paginationPageSize: 1000, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true,
      toolPanelSuppressPivotMode: true, rowSelection: 'single', enableCellChangeFlash: true, suppressSorting: true, suppressMultiSort: true
    };
    let columnSelections: string;  
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, this.selectedreporttype == "Contacts(Saas by Date)"? 48:49).then((config) => {
        columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
        this.colSelections = columnSelections;
      this.GetsaasdateContactList(this.selectedreporttype,"Active Contacts");
      });
  }

  public GetsaasdateContactList(ReportType, statusval) {
    this.selectedreporttype = ReportType;
    let columnSelections: string;  
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, this.selectedreporttype == "Contacts(Saas by Date)" ? 48 : this.selectedreporttype == "Contact(SaaS Renewals)" ? 49 : 50).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = columnSelections;
      this.createColumnDefs(this.colSelections);
      this.opprtunityservice.GetAllsaasContacts(this.currentuser.id, ReportType).then((contact) => {
        this.allContacts = contact;
        this.allContacts = contact.filter(x => x.SaaSRCM != "01/01/0001");
   
        if (statusval == "Active Contacts") {
          this.allContacts = this.allContacts.filter(x => x.Status == "Active");
        } else if (statusval == "Inactive Contacts") {
          this.allContacts = this.allContacts.filter(x => x.Status == "In-Active");
        }
 
      });
    });    
  }

  cellDoubleClicked(rowEvent) {
    var url = window.location.href;
    if (url.indexOf('contacts') > -1) {
      let selfobj = this;
      $(setTimeout(function () {
        selfobj.router.navigateByUrl(`/application/contacts/contacts/null`);
      }, 50));
      selfobj.router.navigateByUrl(`/application`);
    }
    else {
      this.router.navigateByUrl(`/application/contacts/contacts/null`);
    }
    localStorage.setItem('RhxContactVal', rowEvent.data.contactid);

    $('#emailIds').val(rowEvent.data.contactid);
    $('#tasklistselectedtyle').val('Contact');
    let selfobj = this;
    setTimeout(function () {
      selfobj.applicationComponent.ExpandSelectedMenu('contact');
      selfobj.applicationComponent.selectedtabid = 'contact';
    }, 200)
  }

  createColumnDefs(columnSelections: string) {
    var searcharray = [];
    if (this.gridOptions.quickFilterText != null && this.gridOptions.quickFilterText != undefined && this.gridOptions.quickFilterText != '') {
      searcharray.push(this.gridOptions.quickFilterText);
    }
    let columnSelectionsArray: string[] = [];
 
    if (columnSelections != "") {

      columnSelectionsArray = columnSelections.split(',');
      this.columnDefs = [];
      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        this.columnDefs.push({
          field: fieldHeaderVisibleWidthArray[0],
          headerName: fieldHeaderVisibleWidthArray[1],
          hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
          width: parseInt(fieldHeaderVisibleWidthArray[3])
        });
      }
    } else {
      this.columnDefs = [
        { field: 'contactid', headerName: 'contactid', hide: true, width: 250 },
        { field: 'SaaSRenewal', headerName: 'SaaS Renewal', hide: this.selectedreporttype == "Contacts(Saas by Date)"? true : false, width: 250 },        
        { field: 'SassDate', headerName: 'Sass Date', width: 250 },
        { field: 'SaaStermName', headerName: 'SaaS Term', width: 250 },
        { field: 'SaaSRCM', headerName: 'SaaS RCM', hide: this.selectedreporttype == "Contact (SaaS RCM)" ? false : true, width: 250 },  
        { field: 'contactname', headerName: 'Contact', hide: false, width: 250, cellRenderer: function (params) { return params.value + " " + params.data.contacttitle } },
        { field: 'accountname', headerName: 'Account Name', hide: false, width: 250 },
        { field: 'address1', headerName: 'Address1', hide: false, width: 250 },
        { field: 'address2', headerName: 'Address2', hide: false, width: 250 },
        { field: 'city', headerName: 'City', hide: false, width: 250 },
        { field: 'state', headerName: 'State', hide: false, width: 250 },
        { field: 'Status', headerName: 'Status', hide: false, width: 250 },
        { field: 'zip', headerName: 'Zip', hide: false, width: 250 },
        { field: 'country', headerName: 'Country', hide: false, width: 250 },
        { field: 'ProvidenceIsland', headerName: 'Island', hide: false, width: 250 },
        { field: 'cell', headerName: 'Phone', hide: false, width: 250 },
        { field: 'Salsrep', headerName: 'Sales Rep', hide: false, width: 250 },
        { field: 'ownerName', headerName: 'Owner', hide: false, width: 250 }
      ];
    }
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();
   
      config.maxlimit = 100;
    
    config.columnsselections = "";
    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }
    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    config.configureduserid = this.currentuser.id;
    config.resourceid = this.selectedreporttype == "Contacts(Saas by Date)" ? 48 : this.selectedreporttype == "Contact(SaaS Renewals)"? 49:50;
    config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;
    config.userid = "";
    for (let i = 0; i < this.applicationComponent.selectedItems.length; i++) {
      config.userid += this.applicationComponent.selectedItems[i].id + ",";
    }
    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    this.opprtunityservice.saveDashboardconfiguration(config);

  }
}
