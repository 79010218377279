import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ApplicationComponent } from '../application.component';
import { AutomatedEmails } from 'src/app/models/automated-emails';
declare var $: any;
@Component({
  selector: 'automated-emails',
  templateUrl: './automated-emails.component.html',
  styleUrls: ['./automated-emails.component.css']
})
export class AutomatedEmailsComponent implements OnInit {
  @Input()
  userid: number;
  @Input()
  emailAlertUsername: any;
  @Input()
  emailAlertUsername2: any;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  public gridOptions: GridOptions;
  public columnDefs: any[];
  allAccessUser: AutomatedEmails[];
  finalAutomatedlist: AutomatedEmails[] = [];
  currentuser: any;
  public sideBar;
  constructor(private toastr: ToastrService, private userservice: UserService, public applicationComponent: ApplicationComponent) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
   
    this.initializeAgGrid();
    //this.loadUserAlertEmails(this.userid);
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.loadUserAlertEmails(selfObj.userid);
    }, 300));
   
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: false, headerHeight: 40, enableColResize: true, paginationPageSize: 1000, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',

    };

    this.createColumnDefs();
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }

  }
  onAgGridReady(event) { }
  loadUserAlertEmails(val)
  {
    this.userservice.GetAutomatedEmailsConfigByuser(val).then((config) => {
      
      if (config.length == 0) {
        this.userservice.GetAccessUserdata(val).then((userdata) => {

          var res = userdata.filter(x => x.accesstouserid == val);
          if (res.length > 0) {
            this.allAccessUser = userdata;
          } else {
            this.userservice.GetUserByuserId(val).then(result => {
              userdata.push({ accesstouserid: result.id, UserName: result.username, Configureuserid: 0, OppClosed: 0, createdby: 0, endOfMonthRecap: 0, leadAdded: 0, leadClosed: 0, midMonthRecap: 0, oppAdded: 0, weeklyRecap: 0, createddate: '' });
              this.allAccessUser = userdata;
            });
            
          }
          //this.allAccessUser = userdata;
          
        });
      } else {
        $(setTimeout(function () {
          config.forEach(config => {
            $("#" + config.accesstouserid + "midMonthRecapYes").prop('checked', config.midMonthRecap == 1 ? true : false);
            $("#" + config.accesstouserid + "endOfMonthRecapYes").prop('checked', config.endOfMonthRecap == 1 ? true : false);
            $("#" + config.accesstouserid + "leadAddedYes").prop('checked', config.leadAdded == 1 ? true : false);
           $("#" + config.accesstouserid + "leadclosedYes").prop('checked', config.leadClosed == 1 ? true : false);
            $("#" + config.accesstouserid + "OppAddedYes").prop('checked', config.oppAdded == 1 ? true : false);
            $("#" + config.accesstouserid + "OppClosedYes").prop('checked', config.OppClosed == 1 ? true : false);
            $("#" + config.accesstouserid + "WeeklyRecapYes").prop('checked', config.weeklyRecap == 1 ? true : false);
            
          });
        }, 1000)); 
        

        //this.DashBoardConfigList = config;
      }
    });
    
    this.userservice.GetAccessUserdata(val).then((userdata) => {
      var res = userdata.filter(x => x.accesstouserid == val);
      if (res.length > 0) {
        this.allAccessUser = userdata;
      } else {
        this.userservice.GetUserByuserId(val).then(result => {
          userdata.push({ accesstouserid: result.id, UserName: result.username, Configureuserid: 0, OppClosed: 0, createdby: 0, endOfMonthRecap: 0, leadAdded: 0, leadClosed: 0, midMonthRecap: 0, oppAdded: 0, weeklyRecap: 0, createddate: '' });
          this.allAccessUser = userdata;
        });

      }
      //this.allAccessUser = userdata;
        
      });
    
    
  }
  Change(chkId) {
    if (chkId.indexOf("Yes") >= 0) {
      let rplcId = chkId.replace("Yes", "No");
      $("#" + rplcId).prop('checked', false);
      
    }
    else if (chkId.indexOf("No") >= 0) {
      let rplcId = chkId.replace("No", "Yes");
      $("#" + rplcId).prop('checked', false);
    }
    this.applicationComponent.SelectedTab = 'tab5';
    this.sendMsgEvent.emit("Changed");
    $('#SaveUserAlerts').attr("style","background:#90d24e;margin-top:5px;height:100%");
  }
  createColumnDefs() {
    let selfObj = this;
    this.columnDefs = [
      { field: 'UserName', headerName: 'UserName', hide: false, width: 150, suppressSorting: true, headerClass:'Alertheaderclass' },
      { field: 'accesstouserid', headerName: 'Acces User', hide: true, suppressSorting: true, headerClass: 'Alertheaderclass' },
      {
        field: 'leadAdded', headerName: 'Lead \n Added', hide: false, headerClass: 'Alertheaderclass',suppressSorting: true,width:80, outerWidth: 2, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<input id="' + params.data.accesstouserid + 'leadAddedYes"' + ' type="checkbox" style="margin-left:40%;">';
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
            selfObj.Change(params.data.accesstouserid +"leadAddedYes");
            $('#saveDashboardConfig').css("background", "#93d250");
            $('#saveDashboardConfig').prop('disabled', false);

          });
          return eDiv;
        }
      },
     {
        field: 'leadclosed', headerName: 'Lead Closed', hide: false, suppressSorting: true, headerClass: 'Alertheaderclass', width: 80,outerWidth: 2, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<input id="' + params.data.accesstouserid + 'leadclosedYes"' + ' type="checkbox" style="margin-left:40%;">';
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
            selfObj.Change(params.data.accesstouserid +"leadclosedYes");
            $('#saveDashboardConfig').css("background", "#93d250");
            $('#saveDashboardConfig').prop('disabled', false);

          });
          return eDiv;
        }},
      {
        field: 'OppAdded', headerName: 'Opp Added', hide: false, suppressSorting: true, width: 85, headerClass: 'Alertheaderclass',outerWidth: 2, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<input id="' + params.data.accesstouserid + 'OppAddedYes"' + ' type="checkbox" style="margin-left:40%;">';
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
            selfObj.Change(params.data.accesstouserid +"OppAddedYes");
            $('#saveDashboardConfig').css("background", "#93d250");
            $('#saveDashboardConfig').prop('disabled', false);

          });
          return eDiv;
        } },
      {
        field: 'OppClosed', headerName: 'Opp Closed', hide: false, suppressSorting: true, width: 85, headerClass: 'Alertheaderclass', outerWidth: 2, cellRenderer(params) {
          var eDiv = document.createElement('div');
          
          eDiv.innerHTML = '<input id="' + params.data.accesstouserid + 'OppClosedYes"' + ' type="checkbox" style="margin-left:40%;">';
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
            selfObj.Change(params.data.accesstouserid +"OppClosedYes");
            $('#saveDashboardConfig').css("background", "#93d250");
            $('#saveDashboardConfig').prop('disabled', false);

          });
          return eDiv;
        } },
      {
        field: 'WeeklyRecap', headerName: 'Weekly Recap', hide: false, suppressSorting: true, width: 90, headerClass: 'Alertheaderclass', outerWidth: 2, cellRenderer(params) {
          var eDiv = document.createElement('div');
          
          eDiv.innerHTML = '<input id="' + params.data.accesstouserid + 'WeeklyRecapYes"' + ' type="checkbox" style="margin-left:40%;">';
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
            selfObj.Change(params.data.accesstouserid + "WeeklyRecapYes");
            $('#saveDashboardConfig').css("background", "#93d250");
            $('#saveDashboardConfig').prop('disabled', false);

          });
          return eDiv;
        } },
      {
        field: 'midMonthRecap', headerName: 'Mid Month Recap', hide: false, headerClass: 'Alertheaderclass', suppressSorting: true, width: 115, outerWidth: 2, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<input id="' + params.data.accesstouserid + 'midMonthRecapYes"' + ' type="checkbox" style="margin-left:40%;">';
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
            selfObj.Change(params.data.accesstouserid + "midMonthRecapYes");
            $('#saveDashboardConfig').css("background", "#93d250");
            $('#saveDashboardConfig').prop('disabled', false);

          });
          return eDiv;
        }
      },
      {
        field: 'endOfMonthRecap', headerName: 'End Of Month Recap', hide: false, headerClass: 'Alertheaderclass', suppressSorting: true, width: 120, outerWidth: 2, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<input id="' + params.data.accesstouserid + 'endOfMonthRecapYes"' + ' type="checkbox" style="margin-left:40%;">';
          eDiv.getElementsByTagName("input")[0].addEventListener('click', function () {
            selfObj.Change(params.data.accesstouserid + "endOfMonthRecapYes");
            $('#saveDashboardConfig').css("background", "#93d250");
            $('#saveDashboardConfig').prop('disabled', false);

          });
          return eDiv;
        }
      },
      ]
  }

  

  SaveAutomatedEmailsData() {
    this.finalAutomatedlist = [];
    let count = this.gridOptions.api.getDisplayedRowCount();
    for (var i = 0; i < count; i++) {
      var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(i);
      let autemail = new AutomatedEmails();  
      autemail.Configureuserid = this.userid;
      autemail.accesstouserid = rowNode.data.accesstouserid;
      autemail.leadAdded = $('#' + rowNode.data.accesstouserid + 'leadAddedYes').is(":checked") == true ? 1 : 0;
      autemail.leadClosed = $('#' + rowNode.data.accesstouserid + 'leadclosedYes').is(":checked") == true ? 1 : 0;
      autemail.oppAdded = $('#' + rowNode.data.accesstouserid + 'OppAddedYes').is(":checked") == true ? 1 : 0;
      autemail.OppClosed = $('#' + rowNode.data.accesstouserid + 'OppClosedYes').is(":checked") == true ? 1 : 0;
      autemail.weeklyRecap = $('#' + rowNode.data.accesstouserid + 'WeeklyRecapYes').is(":checked") == true ? 1 : 0;
      autemail.midMonthRecap = $('#' + rowNode.data.accesstouserid + 'midMonthRecapYes').is(":checked") == true ? 1 : 0;
      autemail.endOfMonthRecap = $('#' + rowNode.data.accesstouserid + 'endOfMonthRecapYes').is(":checked") == true ? 1 : 0;
      autemail.createdby = this.currentuser.id;
      if ((autemail.leadAdded == 1) || (autemail.leadClosed == 1) || (autemail.oppAdded == 1) || (autemail.OppClosed == 1) || (autemail.weeklyRecap == 1) || (autemail.midMonthRecap==1) || (autemail.endOfMonthRecap==1))
      this.finalAutomatedlist.push(autemail);
    }
    this.userservice.SaveAutomatedEmailConfig(this.finalAutomatedlist).then(result => {
      this.toastr.success("Automated Emails Configured successfully.", "Automated Config", { timeOut: 600 });
      this.sendMsgEvent.emit("Close");
      $('#SaveUserAlerts').attr("style", "background:#d3d3d3;margin-top:5px;height:100%");
    });
  }

  Cancel() {

  }
  closedAlertsEventListener() {
    var tab = localStorage.getItem('PrevUserConfigTab');
     
    if (this.applicationComponent.isClosedAlertsEventListener) {
      if (tab == "tab5") {
        this.SaveAutomatedEmailsData();
      }
      this.applicationComponent.isClosedAlertsEventListener = false;
      this.applicationComponent.isUserAlerts = false;

    }
    return false;
  }

  AlertCancel() {
    this.sendMsgEvent.emit("Alert Cancel");
  }
}
