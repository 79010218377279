import { Component, OnInit, ViewContainerRef, ViewEncapsulation, ElementRef, Renderer2, ViewChild, Optional, Inject, forwardRef } from '@angular/core';
import { CustomerService } from '../../../services/customer.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OpprtunitiesService } from '../../../services/opprtunities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Contacts } from '../../../models/contacts';
import { Customers } from '../../../models/customers';
import { Accounts } from '../../../models/accounts';
import { Notes } from '../../../models/notes';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';;
import { ParentComp, UserlistComponent } from "../../userlist/userlist.component";
import { Opportunities } from '../../../models/opportunities';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { NotesrendererComponent } from '../../notesrenderer/notesrenderer.component';
import { OpportunitiesComponent } from '../../opportunities/opportunities.component';
import { Dashboardconfiguration } from '../../../models/dashboardconfiguration';
import { Column } from 'ag-grid';
import { IMyDpOptions } from 'mydatepicker';
import { ApplicationComponent } from '../../application.component';
import * as moment from 'moment'
import { NotesComponent } from '../../notes/notes.component';
import { AccountsService } from 'src/app/services/accounts.service';
import { UserService } from 'src/app/services/user.service';
import { Dropdowntype } from 'src/app/models/dropdowntype';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { AuditLogModel } from '../../../models/auditlog';
declare var require: any;
declare var $: any;
@Component({
  selector: 'app-opportunitiesdashboard',
  templateUrl: './opportunitiesdashboard.component.html',
  styleUrls: ['./opportunitiesdashboard.component.css'],
 // encapsulation: ViewEncapsulation.None
})
export class OpportunitiesdashboardComponent implements OnInit {
  resizeheight = (window.innerHeight / 1.542) + 'px';
  items = [];
  columnSelections;
  userprevilages: any;
  notesitemResource: any;
  notesitems = [];
  notesitemCount = 0;
  notesparams: any;
  showDialog = false;
  dialogOpen: boolean = false;
  IsOpportunitydelete: boolean = false;
  datePipe: DatePipe = new DatePipe("en-US");
  itemCount = 0;
  itemResource: any;
  params: any;
  inputName: string = '';
  selectedopttype: string = null;
  Ids = [];
  opportunityids = [];
  FeeAmountdiv: boolean = false;
  noData: boolean = true;
  finalitems = "";
  currentuser: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSetting = {};
  notedescription: string;
  opportunitytype: string;
  userIds: string;
  contact: Contacts = new Contacts();
  account: Accounts = new Accounts();
  customer: Customers = new Customers();
  notes: Notes = new Notes();
  contactid: number;
  accountdiv: boolean = true;
  DateClosed: boolean = true;
  NotesDec = false;
  pageSize: string = "";
  thisComp: ParentComp = ParentComp.OpportunitiesDash;
  isViewInitialized: boolean = false;
  isPgClickRegistered: boolean = false;
  isPended: boolean = true;
  public gridOptions: GridOptions;
  public gridOptionsnotes: GridOptions;
  public rowData: any[];
  public columnDefs: any[];
  public columnDefsnotes: any[];
  Allopportunities: Opportunities[];
  filteredOpportunities: Opportunities[];
  nonFilteredOpportunities: Opportunities[];
  public context;
  public frameworkComponents;
  public Cust = Customers;
  aggridheight: string;
  @ViewChild(UserlistComponent) userListComp;
  @ViewChild(OpportunitiesComponent) optComp;
  @ViewChild(NotesComponent) notecomp;
  allnotes: Notes[];
  clickedContactid: number;
  conid: number;
  typ: string; term: string = "";
  Status: any;
  Showaddopportunity: boolean = false;
  ShowPdfDiv: boolean = false;
  ExpandPDFDiv: boolean = false;
  ExpandPreviewPDFDiv: boolean = false;
  totalPrice: number = 0;
  avgPrice: number = 0;
  totalMonthlyPrice: number = 0;
  avgMonthlyPrice: number = 0;
  Below30Days: number = 0;
  Betwn31_60Days: number = 0;
  Betwn61_90Days: number = 0;
  Betwn91_120Days: number = 0;
  //Betwn121_150Days: number = 0;
  Above120Days: number = 0;
  ExpandOppGrid: boolean = false;
  public sideBar;
  daysFilter: boolean = false;
  userInfo: string;
  isShowDetailsChecked: boolean = false;
  public customerpieChartData = [{ horizontalAlign: "left" }];
  public customerpieChartType: string = 'pie';
  public customerpieChartcolors = [{ backgroundColor: ["#826AA4", "#72cdef", "#4C7FC0", "#CA5A4E", "#9DB15A"] }]
  public pieColors = ['#826AA4', '#72cdef', '#4C7FC0', '#CA5A4E', '#9DB15A'];
  public customerpieChartLabels = [];
  public customerpieChartLabels1 = [];
  OpenOppTotal: string = "$ 0";
  Contact: string; ContactTaskHistory: string; ContactLead: string; AllContactsNotes: string; typeSelected: string;
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  opportunities: Opportunities = new Opportunities();
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear()) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear()).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");

  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");;
  timezonevalue: string;
  showEditNoteBtn: boolean = false;
  editNoteId: boolean = false;
  isDisplayCommissionEstimator: boolean = false;
  showpdf1: boolean = false;
  SelectOpportunityWarning : boolean = false;
  today = Date.now();
  calanderonoff: boolean = false;
  subTabName: string;
  DisplayrowsList: Dropdowntype[] = [];
  private gridApi;
  addopportunityExpand: boolean = false;
  notesshowDialog = false;
  DocExpandView: boolean = false;
  isModal: boolean = true;
  SalesCommission: number;
  RRCommission: number;
  EnableSearch: boolean = false;
  value = "";
  constructor(private accountService: AccountsService, public applicationComponent: ApplicationComponent, private elementRef: ElementRef, private userService: UserService, private renderer: Renderer2, private http: HttpClient, private opprtunityservice: OpprtunitiesService, private route: ActivatedRoute, private router: Router, private customerService: CustomerService, private toastr: ToastrService, private viewContainerRef: ViewContainerRef) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {
      this.applicationComponent.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
      ];
      localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
      $("#userids").val(this.currentuser.id);
      this.applicationComponent.ExpandSelectedMenu('opportunities');
    }
    var loggedInUserId = this.currentuser.id;
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1, 0, 0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.dropdownList = Owner;
    })

    this.customerService.GetPrivilagesByuser(loggedInUserId).then(result => {
      this.userprevilages = result;
    });

    
  }
  ngAfterViewInit(): void {
    var thisComponent = this;
    setTimeout(function () { thisComponent.ResizeTable(); }, 10);
  }

  loadallcustomers(loggedinuserid) {
    var labelArr = [];
    var labelArr1 = [];
    var data = [];
    this.opprtunityservice.GetAllCustomersSales(this.userIds, this.startDateVal, this.endDateVal).then((account) => {
      account.forEach(account => {
        var newArrayIs = { name: account.accountname, percentage: '$' + parseInt(account.percentage).toFixed(2) }
        labelArr1.push(newArrayIs);
        labelArr.push(account.accountname);
        data.push(parseInt(account.percentage));
      });
      this.customerpieChartLabels = labelArr;
      this.customerpieChartLabels1 = labelArr1;
      let selfobj = this;
      $(setTimeout(function () {
        this.customerpieChartLabels = labelArr;
        selfobj.customerpieChartData = data;
      }, 200));

    });

  }

  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) { 
    var searcharray = [];
    if (this.gridOptions.quickFilterText != null && this.gridOptions.quickFilterText != undefined && this.gridOptions.quickFilterText != '') {
      searcharray.push(this.gridOptions.quickFilterText);
    }
    let selfobj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "notes") {
          this.columnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: true, width: parseInt(fieldHeaderVisibleWidthArray[3]) });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({
            field: 'owner', headerName: 'Owner', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, getQuickFilterText: () => '', suppressSorting: true, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
       
        else if (fieldHeaderVisibleWidthArray[0] == "status") {
          this.columnDefs.push({
            field: 'status', headerName: 'Status', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, getQuickFilterText: () => '', width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellRenderer: (params) => {
              if (params.data.status == '108') {
                return 'Open';
              }
              else if (params.data.status == '109') {
                return 'Closed-Won';
              } else if (params.data.status == '110') {
                return 'Closed-Lost';
              } else if (params.data.status == '111') {
                return 'Closed-Pending';
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "estimatedclosedate") {
          this.columnDefs.push({
            field: 'estimatedclosedate', headerName: 'ECD', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, getQuickFilterText: () => '', width: parseInt(fieldHeaderVisibleWidthArray[3]), cellStyle: function (params) {
              if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111') ) { return { color: '#FF0000' }; }
            }, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "datecreated") {
          this.columnDefs.push({
            field: 'datecreated', headerName: 'Date Created ', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, getQuickFilterText: () => '', width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: (params) => {
             
                return moment(params.value).format('MM-DD-YYYY');
            
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "practicename") {
          this.columnDefs.push({
            field: 'practicename', headerName: 'Account Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),            
            cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st;
              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "accPhone") {
          this.columnDefs.push({
            field: 'accPhone', headerName: 'Phone', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "lastName") {
          this.columnDefs.push({
            field: 'lastName', headerName: 'Last Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, getQuickFilterText: () => '',width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "firstName") {
          this.columnDefs.push({
            field: 'firstName', headerName: 'First Name', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, getQuickFilterText: () => '', width: parseInt(fieldHeaderVisibleWidthArray[3])
            
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "ProposalPDF") {
          this.columnDefs.push({
            field: 'ProposalPDF', headerName: 'Proposal', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellRenderer(params) {          
                var eDiv = document.createElement('div');
                eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:15%;margin-left:40%"/>';
                eDiv.addEventListener('click', function () {
                  selfobj.PDFClick(params.data.ProposalPDF, 'Proposal');
                });
                return eDiv;             
            }
          });
        }

        else if (fieldHeaderVisibleWidthArray[0] == "ProposalPOPDF") {
          this.columnDefs.push({
            field: 'ProposalPOPDF', headerName: 'PO', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellRenderer(params) {
            
                var eDiv = document.createElement('div');
                eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:15%;margin-left:40%"/>';
                eDiv.addEventListener('click', function () {
                  selfobj.PDFClick(params.data.ProposalPOPDF, 'Proposal');
                });
                return eDiv;
            
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "datecompleted") {
          //this.columnDefs.push({
          //  field: 'datecompleted', headerName: 'Date Completed ', getQuickFilterText: () => '', hide: true, cellRenderer: (params) => {
          //    if (params.data.status == null) {
          //      return "";
          //    } else {
          //      return (params.value == "" || params.value == null) ? "" : moment(params.value).format('MM-DD-YYYY');
          //    }
          //  }
          //},);
        }
        else if (fieldHeaderVisibleWidthArray[0] == "actualclosedate") {
          this.columnDefs.push({
            field: 'actualclosedate', headerName: 'Date Closed', hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true, getQuickFilterText: () => '',width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: (params) => {
              if (params.value == null) {
                return "";
              } else {
                return moment(params.value).format('MM-DD-YYYY');
              }            
            }
          });
        } 
        else if (fieldHeaderVisibleWidthArray[0] == "isPended") {
          this.columnDefs.push({
            field: 'isPended', headerName: 'Pended', getQuickFilterText: () => '',hide: false, cellStyle: function (params) {
               { return { color: '#FF0000' }; }
            }, cellRenderer: function (params) {
              if (params.data.isPended == '1' || params.data.isPended == 1) { return 'Pended'; }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "action")
        {
          this.columnDefs.push({
            field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right'
            , cellRenderer(params) {              
                var eDiv = document.createElement('div');
                eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
                eDiv.addEventListener('click', function () {
                  selfobj.Deleteopportunity(params.data);
                });
                return eDiv;            
            }
          });
        }
        else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1], getQuickFilterText: () => '',
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    }
    else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        {
          field: 'id', headerName: 'ID', hide: true, getQuickFilterText: () => '',
        },
        {
          field: 'estimatedclosedate', headerName: 'ECD', getQuickFilterText: () => '', hide: false, cellStyle: function (params) {
            if ((params.data.estimatedclosedate < selfobj.datePipe.transform(selfobj.today, 'yyyy-MM-dd') || selfobj.datePipe.transform(params.data.estimatedclosedate, 'yyyy') < selfobj.datePipe.transform(selfobj.today, 'yyyy')) && (params.data.status == '108' || params.data.status == '111')) { return { color: '#FF0000' }; }
          }, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'practicename', headerName: 'Account Name', hide: false,
          cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        {
          field: 'status', headerName: 'Status', getQuickFilterText: () => '', hide: false, cellRenderer: (params) => {
            if (params.data.status == '108') {
              return 'Open';
            }
            else if (params.data.status == '109') {
              return 'Closed-Won';
            } else if (params.data.status == '110') {
              return 'Closed-Lost';
            } else if (params.data.status == '111') {
              return 'Closed-Pending';
            }
          }
        },
        {
          field: 'isPended', headerName: 'Pended', getQuickFilterText: () => '', hide: false,
          cellStyle: function (params) {
            { return { color: '#FF0000' }; }
          }, cellRenderer: function (params) {
            if (params.data.isPended == '1' || params.data.isPended == 1) { return 'Pended'; }
          }
        },
        {
          field: 'firstName', headerName: 'First Name', getQuickFilterText: () => '', hide: false
        },
        {
          field: 'lastName', headerName: 'Last Name', getQuickFilterText: () => '', hide: false
        },
        {
          field: 'title', headerName: 'Title', getQuickFilterText: () => '', hide: false
        },
        {
          field: 'itemName', headerName: 'Proposal Type', getQuickFilterText: () => '', hide: false
        },
        {
          field: 'dollers', headerName: 'Price', getQuickFilterText: () => '', hide: false
        },
        {
          field: 'RR', headerName: 'Monthly', getQuickFilterText: () => '', hide: false
        },
        {
          field: 'PCname', headerName: 'Closing Percentage', getQuickFilterText: () => '', hide: false
        },
        {
          field: 'leadfrom', headerName: 'Lead From', getQuickFilterText: () => '', hide: true
        },
        {
          field: 'LeadDescription', headerName: 'Lead Description', getQuickFilterText: () => '', hide: true
        },
        {
          field: 'ProposalDescription', headerName: 'Proposal Description', getQuickFilterText: () => '', hide: true
        },
        {
          field: 'createdUser', headerName: 'Created By', getQuickFilterText: () => '', hide: true
        },  //true starts here
        {
          field: 'actualclosedate', headerName: 'Date Closed', cellRenderer: (params) => {
            if (params.data.status == null) {
              return "";
            } else {
              return moment(params.value).format('MM-DD-YYYY');
            }
          }, getQuickFilterText: () => '', hide: ((this.opportunitytype !== "Open Opportunities" && this.opportunitytype !== "Pended Opportunities") && this.Showaddopportunity == false) ? false : false,
        },
        {
          field: 'owner', headerName: 'Owner', getQuickFilterText: () => '', hide: false
        },
        {
          field: 'datecreated', headerName: 'Date Created', getQuickFilterText: () => '',hide: false, cellRenderer: (params) => {
            return moment(params.value).format('MM-DD-YYYY');
          }
        },
        {
          field: 'source', headerName: 'Proposal/Opp Icon', hide: true
        },
      
        {
          field: 'datecompleted', headerName: 'Date Completed ', getQuickFilterText: () => '', hide: true,  cellRenderer: (params) => {
            if (params.data.status == null) {
              return "";
            } else {
              return (params.value == "" || params.value == null) ? "" : moment(params.value).format('MM-DD-YYYY');
            }
          }
        },
        {
          field: 'accPhone', headerName: 'Phone', hide: true,
          cellRenderer: function (params) {
            if (searcharray.length > 0 && params.value != null) {
              let x = params.value.split(" ");
              let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
              var st = "";
              for (let i = 0; i < x.length; i++) {
                if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                  st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                }
                else {
                  st += x[i] + " ";
                }

              }
              return st

            } else {
              return params.value;
            }
          }
        },
        
       
       
        {
          field: 'ProposalPDF', headerName: 'Proposal', hide: true, width: 100, cellRenderer(params) {
            //if (params.data.ProposalPDF != null && params.data.ProposalPDF != 0) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
              eDiv.addEventListener('click', function () {
                selfobj.PDFClick(params.data.ProposalPDF, 'Proposal');
              });
              return eDiv;
            //}
          }

        },
        {
          field: 'ProposalPOPDF', headerName: 'PO', hide: true, width: 100, cellRenderer(params) {
            //if (params.data.ProposalPOPDF != null && params.data.ProposalPOPDF != 0) {
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
              eDiv.addEventListener('click', function () {
                selfobj.PDFClick(params.data.ProposalPOPDF, 'Proposal');
              });
              return eDiv;
            //}
          }

        },      
      
        {
          field: 'PCname', headerName: 'Closing Percentage', getQuickFilterText: () => '',hide: false
        },
        
       
        {
          field: 'midlevel', headerName: '# Mid Levels', getQuickFilterText: () => '',hide: true
        },
        { field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: true, pinned: 'right' },
       
        {
          field: 'action', headerName: 'Action', hide: false , width: 100, pinned: 'right'
          , cellRenderer(params) {           
              var eDiv = document.createElement('div');
              eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
              eDiv.addEventListener('click', function () {
                selfobj.Deleteopportunity(params.data);
              });
              return eDiv;           
          }
        }

      ];

      var tooltipRenderer = function (params) {
        return '<span title="' + params.value + '">' + params.value + '</span>';
      };
    }
  }
  PDFClick(val, type) {
  
    this.showpdf1 = true;
    this.PdfDialogCss();
    this.userService.GetProposalsDocumentContent(val).then(result => {
      let SO = this
      $(setTimeout(function () {
        SO.onDocumentOpen(result.PdfContent);
      }, 100));
    });
  }
  onDocumentOpen(myBaseString) {
    $('#pdfDiv').html('<embed src="data:application/pdf;base64,' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:100%;" />')
    $('#pdfDiv').attr('style', 'width:100%;height: 100%;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;margin-left:0%');
  }

  Deleteopportunity(data) {   
    this.opportunities = data;
    if (this.opportunities.isPended != '1') {
      this.applicationComponent.loaddialog('Opportunitydelete', 'Delete Opportunity', 'Delete this Open Opportunity?', false, false, true, true, this.opportunities.id);
    } else {
      this.applicationComponent.loaddialog('Opportunitydelete', 'DELETE Pended Opportunity', 'Delete this Pended Opportunity?', false, false, true, true, this.opportunities.id);
    }
 
  }
  
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  autoSizeAll() {

  }
  txtPageSizeChanged(pageSize) {
    this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    this.saveConfiguration();
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',
    };
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 7).then((config) => {
      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.gridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }
      this.columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";

      this.createColumnDefs(this.columnSelections);   
      this.UpdateSearchColumns(this.columnSelections);

    });
    this.gridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      notesrenderer: NotesrendererComponent
    };

    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
  }

  updatevalues(opportunityids) {
    this.opprtunityservice.GetDollerRRvales(opportunityids).then(result => {
      this.opportunities = result;
      this.opportunities.SalesOpportunity = this.opportunities.dollers;
      this.opportunities.RROpportunity = this.opportunities.RR;
    });
  }
  onOppChanged(rowEvent) {
    /*if (this.Showaddopportunity) {*/
      var rowCount = rowEvent.api.getSelectedNodes().length;
      if (rowCount > 0) {
        if (rowEvent.api.getSelectedNodes()[0].data) {
          this.conid = rowEvent.api.getSelectedNodes()[0].data.accountorcontactid;
          if (this.optComp != undefined) {
            this.optComp.DisplayOpportunities(rowEvent.api.getSelectedNodes()[0].data);
            this.optComp.GetOpportunityDetais(rowEvent.api.getSelectedNodes()[0].data.id)
          }
        }
      //}
    }
  }
  rowClickAgGrid(rowEvent) {
    if (!this.Showaddopportunity) {
      if (this.opportunityids && (this.opportunityids).indexOf(rowEvent.data.id) > -1) {
        var index = (this.Ids).indexOf(rowEvent.data.id);
        (this.opportunityids).splice(index, 1);
        this.updatevalues((this.opportunityids).toString())

      } else {
        this.opportunityids = [];
        this.opportunityids.push(rowEvent.data.id);
        this.updatevalues((this.opportunityids).toString())
      }

      if ((this.Ids).indexOf(rowEvent.data.accountorcontactid) > -1) {
        var index = (this.Ids).indexOf(rowEvent.data.accountorcontactid);
        (this.Ids).splice(index, 1);
        var string = (this.Ids).toString();
        $('#emailIds').val(string);
        $('#tasklistselectedtyle').val('');
      } else {
        this.Ids.push(rowEvent.data.accountorcontactid)
        var string = (this.Ids).toString();
        $('#emailIds').val(string);
        if (rowEvent.data.type == "Contacts") {
          $('#tasklistselectedtyle').val('Contact');
        }
        else {
          $('#tasklistselectedtyle').val('Customer');
        }
      }
    }
  }
  rowDoubleClickAgGrid(rowEvent) {
    this.EnableSearch = false;
    this.Showaddopportunity = true;
    this.createColumnDefs(this.columnSelections);
    this.typeSelected = 'opportunity';
    this.opportunityids = rowEvent.data.id;
    this.updatevalues(this.opportunityids)

    this.contactid = this.conid  = rowEvent.data.accountorcontactid;
    this.typ = rowEvent.data.type;
    this.gridOptions.rowSelection = 'Single';
   
    var obj: AuditLogModel = { id: 0, type: 'Opportunity', action: 'Opportunity Viewed', AccountOrContactId: rowEvent.data.accountorcontactid, AccountOrContact: rowEvent.data.type, documentid: rowEvent.data.id, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Opportunity Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }
  expandOpportunityView(value) {
    if (value === 'Collapse') {
      this.addopportunityExpand = false;
      let selfObj = this;
      $(setTimeout(function () {
        selfObj.gridOptions.api.forEachNode((node) => {
          if (node.data.id == selfObj.opportunityids) {
            selfObj.gridOptions.api.selectNode(node, true);
            selfObj.gridOptions.rowSelection = 'single';
          }
        });
      }, 500));

    }
    else {
      this.addopportunityExpand = true;
      
    }
  }
  CloseOppDiv() {
    this.Showaddopportunity = false;
    this.addopportunityExpand = false;
    this.gridOptions.rowSelection = 'multiple';
  }
  openNotesDialogAg(rowdata) {
    

    this.conid = rowdata.accountorcontactid;

    if (rowdata.type == 'Customer' ) {
      this.typ = 'Account';
    } else if (rowdata.type == 'Contacts') {
      this.typ = 'Contact';
    }  else {
      this.typ = rowdata.type;
    }
    this.showDialog = !this.showDialog;
    this.NotesDialogCss();
    

  }
  NotesDialogCss() {
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    this.saveConfiguration();
  }

  UpdateSearchColumns(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[] = columnSelections.split(',');

    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');
      if (fields[2] == 'true' && (fields[1] != '#' && fields[0] != 'notes')) {
        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }
    //this.applicationComponent.ShowSearchTabs('True');
    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];
    if (retrievedObject != null) {
      let obj: any[] = JSON.parse(retrievedObject);
      obj.forEach(element => {
        
      });
    }
    if (tempTableColumns && tempTableColumns.length > 0) {
      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })
    }
    else {
      this.accountService.GetDefaultColumns('OpenOpportunity', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.applicationComponent.SearchSelectedItems = [
            { "id": 'firstName', "itemName": 'First Name' },
            { "id": 'lastName', "itemName": 'Last Name' },
            { "id": 'practicename', "itemName": 'Account Name' }
          ];
        }
      });
      //this.applicationComponent.showSearchTabsSubTabs = false;
    }
  }
  saveConfiguration() {

    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 25;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    this.UpdateSearchColumns(config.columnsselections);
    config.configureduserid = this.currentuser.id;
    config.resourceid = 7;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);
    config.startdate = this.startDateVal;
    config.enddate = this.endDateVal;
    this.opprtunityservice.saveDashboardconfiguration(config);

  }

  //initializeAgGridNotes() {
  //  this.gridOptionsnotes = <GridOptions>{
  //    pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
  //  };
  //  this.createColumnDefsnotes("");
  //  this.gridOptionsnotes.defaultColDef = {
  //    headerComponentParams: {
  //      menuIcon: 'fa-bars'
  //    }
  //  }
  //  this.context = this;
  //}
  //createColumnDefsnotes(columnSelections: string, @Optional() isDeActive: boolean = false) {
  //  let selfObj = this;

  //  this.columnDefsnotes = [
  //    {
  //      field: '#',
  //      headerName: '#',
  //      width: 52,
  //      checkboxSelection: true,
  //      suppressSorting: true,
  //      suppressMenu: true,
  //      pinned: true,
  //      hide: false,
  //      outerWidth: 8,
  //      headerCheckboxSelection: true,
  //      headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
  //    },
  //    {

  //      field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
  //        if (params.data.isnewnote == 1) {
  //          return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
  //        }
  //        else if (params.value == 'Contact' || params.value == 'Account') {
  //          //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
  //          return '<i class="fa fa-laptop " aria-hidden="true"></i>';
  //        }
  //        else if (params.value != null && params.value.startsWith("Phone")) {
  //          //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
  //          return '<i class="fa fa-phone" aria-hidden="true"></i>';
  //        }
  //        else if (params.value != null && params.value.startsWith("To Do")) {
  //          return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
  //        }
  //        else if (params.value != null && params.value.startsWith("Meeting")) {
  //          //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
  //          return '<i class="fa fa-calendar " aria-hidden="true"></i>';
  //        } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
  //          //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
  //          return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
  //        }
  //        else {
  //          //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
  //          return '<i class="fa fa-usd" aria-hidden="true"></i>';
  //        }

  //      }
  //    },
  //    {
  //      field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellStyle: { 'text-align': 'left' }, hide: false, cellRenderer(params) {
  //        var eDiv = document.createElement('div');
  //        eDiv.innerHTML = params.value;
  //        eDiv.addEventListener('click', function () {
  //          selfObj.openDisplaynotes(params);
  //        });
  //        return eDiv;
  //      }
  //    },
  //    { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: true },
  //    { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
  //    {
  //      field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: false,
  //      cellRenderer(params) {
  //        if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
  //        else { return params.value; }

  //      }
  //    },
  //    { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
  //    { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
  //    { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

  //  ];

  //}
  onAgGridReadynotes(event) {
    
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.autoSizeAllnotes();
      $(setTimeout(function () {
        selfObj.autoSizeAllnotes();
      }, 400));
    }, 400));
  }
  autoSizeAllnotes() {

  }
  ngOnInit() {
    this.initializeAgGrid();
    this.subTabName = "Open";
    this.applicationComponent.selectedbuttonText = "Opportunities";
    $(".coustomButton").removeClass("mainactiveButton");
    $("#opportunities").addClass("mainactiveButton");
    $("#opportunities_span").addClass("activeColor");
   
    var obj: AuditLogModel = { id: 0, type: 'Opportunity', action: 'Opportunity Viewed', AccountOrContactId: 0, AccountOrContact: "", documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Opportunity Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);

    this.route.params.subscribe(params => {
      this.typeSelected = params['type'];
      this.contactid = this.conid= params['contactid'];
      this.term = params['term'];
      this.opportunityids = params['id'];

      if (this.opportunitytype != null) {
        this.ClosedOpportunity(this.opportunitytype, $("#userids").val(), this.term);
        this.loadallcustomers($("#userids").val());
      }
    });
    this.Generate($("#userids").val());
    
    this.customerService.GetDisplayCommision(this.currentuser.id).then(result => {
      this.isDisplayCommissionEstimator = result;
    });

    
    this.isViewInitialized = true;
 
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 500));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 500));
    }


  }


  userListOutParamsBinding(outProps) {
    this.finalitems = outProps.finalitems;
    this.items = outProps.items;
    this.itemCount = outProps.itemsCount;
    this.selectedItems = outProps.selectedItems;
  }

  onItemSelect(item: any) {
    var ids = "";
    ids += item.id;
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());
  }

  OnItemDeSelect(item: any) {
    var ids = item.id;
    var result = "";
    this.finalitems = $("#userids").val();
    var dfi = this.finalitems.split(',');
    for (var i = 0; i < dfi.length; i++) {
      if (dfi[i] != ids && dfi[i] != "") {
        result += dfi[i] + ',';
      }
    }
    this.finalitems = result;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.Generate($("#userids").val());
    } else {
      this.Allopportunities = [];
      this.nonFilteredOpportunities = this.Allopportunities;
      $("#userids").val('');
    }
  }
  onSelectAll(items: any) {
    var ids = "";

    for (var i = 0; i < items.length; i++) {
      ids += items[i].id + ',';
    }
    this.finalitems = "";
    this.finalitems = ids;
    if (this.finalitems != "") {
      $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
      this.Generate($("#userids").val());
    } else {
      this.Allopportunities = [];
      this.nonFilteredOpportunities = this.Allopportunities;
      $("#userids").val('');
    }
  }
  onDeSelectAll(items: any) {
    this.finalitems = "";
    this.Allopportunities = [];
    this.nonFilteredOpportunities = this.Allopportunities;
    $("#userids").val('');
    this.applicationComponent.selectedusers = null;
    this.Generate($("#userids").val());
  }

  Generate(val) {
    if (this.contactid != 0) {
      this.Showaddopportunity = true
      this.applicationComponent.pageViewed = "CreateOpty";
    }
    else {
      this.Showaddopportunity = false
      if (this.applicationComponent.selectedusers != '' && this.applicationComponent.selectedusers != null && this.applicationComponent.selectedusers != undefined) {
        this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
          Owner.forEach(Owner => {
          });
          this.selectedItems = Owner;
        })
      }
      else {
        this.selectedItems = [
          { "id": this.currentuser.id, "itemName": this.currentuser.username }
        ];
        this.finalitems += this.currentuser.id + ',';
        //$("#userids").val(this.currentuser.id);

      }
      this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
        this.DisplayrowsList = Rows;
      });
    }

    let selobjj = this;
    $(setTimeout(function () {
    
      //selobjj.applicationComponent.showcampaignsTabs = false;
      selobjj.applicationComponent.selectedusers = val;
      selobjj.ClosedOpportunity('Open Opportunities', val, null);
      selobjj.loadallcustomers(val);
    }, 500));

  }

  ClosedOpportunity(val, userids, searchval) {   
    var subMenuId = '';
    if (val == 'Open') {
      subMenuId = 'OpenOpportunity';
    }
    this.totalPrice = 0;
    this.totalMonthlyPrice = 0;
    this.avgPrice = 0;
    this.avgMonthlyPrice = 0;
    this.userIds = userids;
   
    this.opprtunityservice.GetAllOpportunitiesList(val, userids, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, searchval, this.currentuser.id, this.isPended).then((customer) => {
      if (customer.length > 0) {
        if ((customer.length == 0 || customer == null) && searchval != null) {
          this.applicationComponent.ShowInvalidSearchDialog = !this.applicationComponent.ShowInvalidSearchDialog;
          this.applicationComponent.dialogsizeInvalidSearch();

        }
        customer.forEach(customer => {
          customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
          if (customer.estimatedclosedate != '0001-01-01T00:00:00') {
            customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "yyyy-MM-dd");
          } else {
            customer.estimatedclosedate = '';
          }
          if (customer.datecompleted != '0001-01-01T00:00:00' && customer.datecompleted !=null) {
            customer.datecompleted = this.datePipe.transform(customer.datecompleted, "yyyy-MM-dd");
          } else {
            customer.datecompleted = '';
          }
          if (customer.dollers != null && customer.dollers != undefined && customer.dollers != '') {
            this.totalPrice += Number(customer.dollers.split('$')[1]);
          }
          if (customer.RR != null && customer.RR != undefined && customer.RR != '') {
            this.totalMonthlyPrice += Number(customer.RR.split('$')[1]);
          }         

        });   
        this.Allopportunities = customer;
     
        if (this.Showaddopportunity && this.Allopportunities.length>0) {
          let selfObj = this;         
          var opportunityId = selfObj.opportunityids.length > 0 ? selfObj.opportunityids.toString() : selfObj.Allopportunities[0].id.toString();
          $(setTimeout(function () {
            selfObj.gridOptions.api.forEachNode((node) => {
              if (node.data.id == opportunityId) {
                    selfObj.gridOptions.api.selectNode(node, true);
                    selfObj.gridOptions.rowSelection = 'single';
                  }              
            });
          }, 300));
        } else {
          this.avgPrice = this.totalPrice / customer.length;
          this.avgMonthlyPrice = this.totalMonthlyPrice / customer.length;
          this.Below30Days = customer.filter((cust) => cust.NoOfDays <= 30).length;
          this.Betwn31_60Days = customer.filter((cust) => cust.NoOfDays > 30 && cust.NoOfDays <= 60).length;
          this.Betwn61_90Days = customer.filter((cust) => cust.NoOfDays > 60 && cust.NoOfDays <= 90).length;
          this.Betwn91_120Days = customer.filter((cust) => cust.NoOfDays > 90 && cust.NoOfDays <= 120).length;
          this.Above120Days = customer.filter((cust) => cust.NoOfDays > 120).length;
          this.gridOptions.columnApi.setColumnVisible('datecompleted', (val == 'Open') ? false : true)
          this.UpdateSearchColumns(this.columnSelections);
          this.loadallcustomers(val);
          this.nonFilteredOpportunities = this.Allopportunities;
        }
        
      }
      else {       
        this.Allopportunities = [];
        this.applicationComponent.searchstring = "No Match";
        if (this.applicationComponent.closedOpportunityVal == 'Open') {
          var retrievedObject = localStorage.getItem('OpenOppSearchCriteria');
        } else if (this.applicationComponent.closedOpportunityVal == 'Closed') {
          var retrievedObject = localStorage.getItem('ClosedOppSearchCriteria');
        } else if (this.applicationComponent.closedOpportunityVal == 'All') {
          var retrievedObject = localStorage.getItem('AllOppSearchCriteria');
        }
        if (retrievedObject != null) {
          var obj = retrievedObject.slice(0, -3);
          obj += '~';
          this.applicationComponent.searchCriteriadata = obj.split(' / ');
        }
        this.optComp.loadOpportunities(0);
      }
    });
    var strData = '<label>Opportunities Added</label><br /><br />';
    if (this.Allopportunities) {
      this.Allopportunities.forEach(ele => {
        strData += "<span>" + ele.practicename + "</span><br/>" +
          "<span>Price: " + ele.dollers + "</span><br />" +
          "<span>Monthly: " + ele.RR + "</span><br />" +
          "<span>ECD: " + ele.estimatedclosedate + "</span><br /><br />";
      });

    }
    this.opportunitytype = val;
    this.createColumnDefs(this.columnSelections);
  }

  FilterByDays(val) {
    this.totalPrice = 0;
    this.totalMonthlyPrice = 0;
    this.avgPrice = 0;
    this.avgMonthlyPrice = 0;
    //this.userIds = userids;
    $('.daysFilterCss').removeClass('daysFilterGreenCss');
    $('#' + val).addClass('daysFilterGreenCss');
   
    this.opprtunityservice.GetAllOpportunitiesList('Open Opportunities', $("#userids").val(), (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, null, this.currentuser.id, this.isPended).then((customers) => {
      switch (val) {
        case '30':
          this.daysFilter = true;
          customers = customers.filter((cust) => cust.NoOfDays <= 30);
          break;
        case '31-60':
          this.daysFilter = true;
          customers = customers.filter((cust) => cust.NoOfDays > 30 && cust.NoOfDays <= 60);
          break;
        case '61-90':
          this.daysFilter = true;
          customers = customers.filter((cust) => cust.NoOfDays > 60 && cust.NoOfDays <= 90);
          break;
        case '91-120':
          this.daysFilter = true;
          customers = customers.filter((cust) => cust.NoOfDays > 90 && cust.NoOfDays <= 120);
          break;
        //case '121-150':
        //  this.daysFilter = true;
        //  customers = customers.filter((cust) => cust.NoOfDays > 120 && cust.NoOfDays <= 150);
        //  break;
        case '120+':
          this.daysFilter = true;
          customers = customers.filter((cust) => cust.NoOfDays > 120);
          break;
        case 'DisplayAll':
          this.daysFilter = false;

          break;

      }

      customers.forEach(customer => {
        customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
        if (customer.estimatedclosedate != '0001-01-01T00:00:00') {
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "yyyy-MM-dd");
        } else {
          customer.estimatedclosedate = '';
        }
        if (customer.datecompleted != '0001-01-01T00:00:00' && customer.datecompleted!=null) {
          customer.datecompleted = this.datePipe.transform(customer.datecompleted, "yyyy-MM-dd");
        } else {
          customer.datecompleted = '';
        }

        this.totalPrice += Number(customer.dollers.split('$')[1]);
        this.totalMonthlyPrice += Number(customer.RR.split('$')[1]);

      });
      this.Allopportunities = customers;
      this.avgPrice = this.totalPrice / customers.length;
      this.avgMonthlyPrice = this.totalMonthlyPrice / customers.length;
      this.gridOptions.columnApi.setColumnVisible('datecompleted', (val == 'Open') ? false : true)
      this.UpdateSearchColumns(this.columnSelections);
      //this.loadallcustomers($("#userids").val());


    });
  }
  GetOpportunitiesnotes(id, type) {
    this.customerService.GetAllNotesList(this.currentuser.id, id, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.allnotes = taskhistory;
      this.notesitemCount = taskhistory.length;
    

    });
  }
  notesreloadItems(notesparams) {
    if (this.notesitemResource != undefined) {
      this.notesitemResource.query(notesparams).then(notesitems => this.notesitems = notesitems);
    }
  }

  AddOpportunity(conId) {
    if (this.userprevilages != undefined) {
      for (var j = 0; j < this.userprevilages.length; j++) {
        if ("Opportunities" == this.userprevilages[j].resource) {
          if (this.userprevilages[j].canadd == false) {
            this.toastr.warning("You Do Not have Access to this Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }
         
          this.applicationComponent.pageViewed = "CreateOpty";
          document.getElementById('addopportunity').style.display = 'block';
          this.Showaddopportunity = true

          //this.optComp.contactid = 0;
        }
      }
    }
  }
  enterNotes() {

    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'block';
    document.getElementById('saveNotesButtons').style.display = 'block';
    document.getElementById('divTable').style.display = 'none';
  }
  SaveNote() {
    this.notes.notedescription = this.notedescription.replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>|&nbsp;)/img, "");
    this.notes.notedescription = this.notes.notedescription.replace(/<[^>]*>/g, '');
    this.notes.type = "Opportunity";
    this.notes.accountid = this.contactid;

    this.customerService.SaveNotes(this.notes).then(result => {
      this.GetOpportunitiesnotes(this.notes.accountid, 'Opportunity');
      this.CancelNote();
    });
  }
  CancelNote() {
    $('.fr-element').empty();
    document.getElementById('noteid').style.display = 'none';
    document.getElementById('saveNotesButtons').style.display = 'none';
    document.getElementById('divTable').style.display = 'block';
  }
  ResizeTable() {
    this.aggridheight = (((window.innerHeight) - (($('#appComponentContent').height()) + ($('#actiondivid').height()))) - 45) + 'px';
  }

 
  AssignStartAndEndDate(event) {
    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")

      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }

  /** Converts and Returns date in this format 'YYYY-MM-DD'. Should be in Json format {year:2, month:2,year:1990 }  */
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }

 
  openDisplaynotes(params) {
    //this.notes.notedescription = params.value;
    this.NotesDec = !this.NotesDec;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;
    $(setTimeout(function () {

      $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

      if ($('input[name=Notes]').is(':checked')) {
        $('#txtLongNoteDesc').css('height', '81%');
        $('#txtLongNoteDesc').css('padding-top', '0%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
        $('#txtUserFullName').css('height', '14%');
      } else {
        $('#txtLongNoteDesc').css('height', '96%');
        $('#txtLongNoteDesc').css('padding-top', '6%');
        $('#txtLongNoteDesc').css('padding-left', '2%');
      }
      $('#showDetailsDialog').show();
      $('#txtLongNoteDesc').empty();
      $('#txtLongNoteDesc').append(params.value);
    }, 1));
  }
  closeDetailsDialog() {
    this.NotesDec = false;
  }
  editNote() {
    $('#btnEditNote').hide();
    $('.box').addClass('editable');
    $('.box').attr('contenteditable', 'true');
    $("#txtLongNoteDesc").css("background-color", "white");
    $('.save').show();

  }
  SaleCommissionOpprtunity(val) {
    this.opportunities.salesCommissionOpportunity = ((parseFloat(this.SalesCommission.toString()) * val) / 100).toString().replace('NaN', '');
  }
  RRCommissionOpprtunity(val) {
    this.opportunities.RRCommisssionOpportunityMonthly = (parseFloat(this.RRCommission.toString()) * (val / 100)).toString().replace('NaN', '');
   
    this.opportunities.RRCommisssionOpportunityYearly = parseFloat((102.60 * 12).toString()).toFixed(2);
  }
  onReceiveMessage(msg) {
    this.applicationComponent.isClosedOpportunityEventRaised = true;  
    this.applicationComponent.pageViewed = "CreateOpty";
    this.Showaddopportunity = true;
    this.Generate($("#userids").val());
    var data = msg.split("|");
    if (data[1] == 'Customer' || data[1] == 'Account') {
      this.typeSelected = 'Customer';
    }
    else {
     
    }

  }
  saveEditedNote() {

    let desc: string = $("#txtLongNoteDesc").html();
    let selfObj = this;
    this.customerService.SaveEditedNote(this.editNoteId.toString(), desc).then((success) => {
      if (success) {
        selfObj.toastr.success("Note updated successfully.", "Note Updated");
        selfObj.closeDetailsDialog();
        selfObj.GetOpportunitiesnotes(selfObj.contactid, 'Opportunity');
      } else {
        selfObj.toastr.success("There was some error saving note.", "Updated Failed");
      }
    });
    //this.Notes(this.selectedTabName);
  }

  StatusSelected() {
    var statusType = $('#statusOpp').val();
    this.Allopportunities = this.nonFilteredOpportunities
    var filteredOpportunities = [], status = '';
    if (statusType == "Closed Lost") {
      status = "Closed-Lost"
    } else if (statusType == "Closed Won") {
      status = "Closed-Won"
    } else {
      status = "Closed"
    }

    if (status != "Closed") {
      for (var i = 0; i < (this.Allopportunities).length; i++) {
        if (this.Allopportunities[i].status == status) {
          filteredOpportunities.push(this.Allopportunities[i])
        }
      }


      this.Allopportunities = filteredOpportunities;
    }
  }
  CommissionEstimator(val) {
    if (this.gridApi.getSelectedRows() != undefined && this.gridApi.getSelectedRows()?.length > 0) {
      this.SalesCommission = this.gridApi.getSelectedRows().reduce((sum, current) => sum +
        parseFloat(current.dollers.replace(',', '').replace('$', '')), 0);
      this.RRCommission = this.gridApi.getSelectedRows().reduce((sum, current) => sum +
        parseFloat(current.RR.replace(',', '').replace('$', '')), 0);
    }  
  
    if (this.opportunityids.length > 0) {
      this.isDisplayCommissionEstimator = val;
      this.isDisplayCommissionEstimatorCss();
    } else {
      this.SelectOpportunityWarning = true;
      this.SelectOpportunityWarningCss();
    }
  }

  
  isDisplayCommissionEstimatorCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width: 35%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);min-height: 63%;overflow: auto;height: 83%;left: 63%;padding: 0;');
    }, 4)
  }
  SelectOpportunityWarningCss() {
    setTimeout(function () {
      $('#SelectOpportunityWarning .dialog').attr('style', 'width: 27%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    }, 4);
  }
  PdfDialogCss() {
    setTimeout(function () {
      $('#showpdf1 .dialog').attr('style', 'width: 44%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);min-height: 63%;overflow: auto;height: 85%;top: 10%;left: 54%;padding:0;4');
    }, 4);
  }

  closeShowPdfDiv() {
    this.ShowPdfDiv = false;
    this.ExpandPDFDiv = false;
  }
  ExpandPDF1() {
    this.ExpandPDFDiv = !this.ExpandPDFDiv;
  }
  ExpandPDF(val) {
    this.notesshowDialog = true;
    this.QuickViewDialogCss(val);
    this.DocExpandView = val;
  }
  QuickViewDialogCss(val) {
    if (val == true) { //expand
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 100%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    } else {
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));
    }

  }

  onCloseDialogEventReceived(event) { 
    this.CommissionEstimator(false)
    if (event == 'false') {
      this.notesshowDialog = false;
      this.isModal = false;
    }
  }
  ExpandGrid(val) {
    this.ExpandOppGrid = val;
  }
  UserlistDataReceived(outProps: OutputPropsUserList) {
    if (outProps.enableSearch) { this.EnableSearch = true; }
    this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
    this.gridApi.paginationSetPageSize(Number(this.pageSize));     
    if (outProps.finalitems != "") {
      $("#userids").val(outProps.finalitems);
      if (!outProps.pageSizeChanged) {
        if (outProps.startDateVal != "" && outProps.startDateVal != null) {
          this.startDateVal = outProps.startDateVal;
        }
        if (outProps.endDateVal != "" && outProps.endDateVal != null) {
          this.endDateVal = outProps.endDateVal;
        }
         if (outProps.thirdDrpValue == '718')
        {
          this.isPended = false;
        }
        else { this.isPended = true; }
        this.ClosedOpportunity(outProps.secondDrpValue == undefined ? 'Open Opportunities' : outProps.secondDrpValue, $("#userids").val(), null);
      }
    } else {
      this.Allopportunities = [];
      $("#userids").val('');
    }
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
    this.gridOptions.quickFilterText = (document.getElementById('filter-text-box') as HTMLInputElement).value;
    this.createColumnDefs(this.columnSelections);
    this.gridApi.redrawRows(); 
  }
  ClearSearch() {
    this.gridApi.setQuickFilter('');
    this.value = "";
    this.applicationComponent.EnableSearch = false;
    this.gridOptions.quickFilterText = "";
    this.createColumnDefs(this.columnSelections);
    this.gridApi.redrawRows();
  }
}
