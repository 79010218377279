import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectFields, ProjectStages, ProjectSteps } from '../../../models/ProjectConfiguration';
import { ApplicationComponent } from '../../application.component';
import { GridOptions, ColumnUtils, ColumnApi, RowDragEndEvent } from "ag-grid/main";
import { CustomerService } from '../../../services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { debug } from 'console';
import { PickListModel, ProjectPickListModel } from '../../../models/PickListModel';
import { SystemRowConfiguration } from '../../../models/system-row-configuration';
import { LeadsComponent } from '../../leads/leads.component';
import { LeadsService } from '../../../services/leads.service';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/user.service';
declare var $: any;
@Component({
  selector: 'app-project-configuration',
  templateUrl: './project-configuration.component.html',
  styleUrls: ['./project-configuration.component.css']
})
export class ProjectConfigurationComponent implements OnInit {
  @Input()
  SubMenuType: string = null;
  ProjectConfigMainDiv: boolean = true;
  ProjectFieldsDiv: boolean = true;
  ProjectFieldsConfigDiv: boolean = false;
  ProjectStageDiv: boolean = false;
  ProjectStepsDiv: boolean = false;
  ProjectDivType: string = '';
  ProjectFields: ProjectFields = new ProjectFields();
  ShowProjectFieldForm: boolean = false;
  ShowProjectStageForm: boolean = false;
  ShowProjectStepsForm: boolean = false;
  ProjectFieldform: FormGroup;
  ProjectStageform: FormGroup;
  ProjectStepsform: FormGroup;
  DisableFields: boolean = false;
  currentuser: any;
  public gridOptionsView: GridOptions;
  public columnDefs;
  public sideBar;
  ProjectFieldsList: ProjectFields[] = [];
  DuplicateProjectFieldsList: ProjectFields[] = [];
  public gridConfigFeildsView: GridOptions;
  private gridApi;
  public PickListColumns: ProjectFields[] = [];
  public expanded: boolean = false;
  public ShowFieldItemTable: boolean = false;

  SelectedProjectFields: ProjectFields;
  FieldDataList: ProjectPickListModel[]= [];
  DuplicateFieldDataList: ProjectPickListModel[] = [];
  PickListIds = [];
  DeletePickListObj: ProjectPickListModel;
  documenttypeList = [];
  MappedAccounts = [];
  public DeleteMatchPopUp: boolean = false;
  PickListArray: ProjectPickListModel = new ProjectPickListModel();
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration();

  ProjectStage: ProjectStages = new ProjectStages();
  ProjectStagesList: ProjectStages[] = [];
  DuplicateProjectStagesList: ProjectStages[] = [];
  DisableStageFields: boolean = false;
  ProjectTypeList: ProjectPickListModel[] = [];

  ProjectSteps: ProjectSteps = new ProjectSteps();
  ProjectStepsList: ProjectSteps[] = [];
  OriginalProjectStepsList: ProjectSteps[] = [];
  StagesList: ProjectStages[] = [];

  selectedProjectType = 0;
  selectedProjectType1 = 0;
  selectedProjectStage = 0;
  subs = new Subscription();
  public ConfigFeildscolumnDefs;
  constructor(public applicationComponent: ApplicationComponent, private customerService: CustomerService, private toastr: ToastrService, private leadsService: LeadsService, private dragulaService: DragulaService, private userService: UserService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));

    this.subs.add(this.dragulaService.drop("ProposalFields-bag")
      .subscribe(({ name, el, target, source, sibling }) => {

        this.CategoryOrder();


      })
    );
  }

  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
  }
  ngOnInit(): void {
    this.AccessDiv("Fields");
  }
  initializeAgGridConfigFeild() {
    this.gridConfigFeildsView = <GridOptions>{
      enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    this.createdConfigFeildsColumnDefs("");
  }
  createdConfigFeildsColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.ConfigFeildscolumnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, rowDrag: true
      },
      { field: 'SortOrder', headerName: 'Sort Order', hide: false },

      { field: 'itemName', headerName: 'Pick List Name', hide: false },

      {
        field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right', cellRenderer(params) {
          var eDiv = document.createElement('div');

          eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';

          eDiv.addEventListener('click', function () {
            selfObj.DeletePickList(params.data, 'Accounts');
          });
          return eDiv;
        }
      }
    ];
  }

  onConfigFeildsRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration(this.SelectedProjectFields.ProjectFieldId);
  }
  AccessDiv(type) {

    if (type == "Fields") {
      this.ProjectConfigMainDiv = false;
      this.ProjectFieldsDiv = true;
      this.ProjectFieldsConfigDiv = false;
      this.ProjectStageDiv = false;
      this.ProjectStepsDiv = false;
      this.ProjectDivType = 'Projects Fields';
      this.getProjectFields();
    }
    else if (type == "Config") {
      this.ProjectConfigMainDiv = false;
      this.ProjectFieldsDiv = false;
      this.ProjectFieldsConfigDiv = true;
      this.ProjectStageDiv = false;
      this.ProjectStepsDiv = false;
      this.ProjectDivType = 'Projects Fields Configuration';
      this.getProjectFields();

    }
    else if (type == "Stages") {
      this.ProjectConfigMainDiv = false;
      this.ProjectFieldsDiv = false;
      this.ProjectFieldsConfigDiv = false;
      this.ProjectStageDiv = true;
      this.ProjectStepsDiv = false;
      this.ProjectDivType = 'Projects Stages';

      this.getProjectTypes();
      this.getProjectStages();
    }
    else if (type == "Steps") {
      this.ProjectConfigMainDiv = false;
      this.ProjectFieldsDiv = false;
      this.ProjectFieldsConfigDiv = false;
      this.ProjectStageDiv = false;
      this.ProjectStepsDiv = true;
      this.ProjectDivType = 'Projects Steps';

      this.getProjectTypes();
      this.getProjectStages();
      this.getProjectSteps();
    }
    else if (type == "Main") {
      this.ProjectConfigMainDiv = true;
      this.ProjectFieldsDiv = false;
      this.ProjectFieldsConfigDiv = false;
      this.ProjectStageDiv = false;
      this.ProjectStepsDiv = false;

      this.ShowProjectFieldForm = false;
      this.ShowFieldItemTable = false;
      this.ShowProjectStageForm = false;
      this.ShowProjectStepsForm = false;
      this.ProjectStepsList = this.OriginalProjectStepsList = [];
      this.selectedProjectStage = this.selectedProjectType = 0;
      
    }

  }

  ShowFieldForm(type) {
    if (type == 'Fields') {
      this.ShowProjectFieldForm = true;
    }
    else if (type == 'Stages') {
      this.ShowProjectStageForm = true;
    }
    else if (type == 'Steps') {
      this.ShowProjectStepsForm = true;
    }
  }
  FilterByTypeOfField(event) {
    this.DuplicateProjectFieldsList = [];
    if (event.target.value == 2) {
      this.DuplicateProjectFieldsList = this.ProjectFieldsList.filter(x => x.TypeOfField == 'Standard');
    } else if (event.target.value == 3) {
      this.DuplicateProjectFieldsList = this.ProjectFieldsList.filter(x => x.TypeOfField == 'Custom');
    } else {
      this.DuplicateProjectFieldsList = this.ProjectFieldsList;
    }
  }

  // Project Field Starts 
  SortByType(event) {
    this.DuplicateProjectFieldsList = [];
    let selfObj = this;
    $(setTimeout(function () {
      if (event.target.value == 1) {
        selfObj.DuplicateProjectFieldsList = selfObj.ProjectFieldsList.sort((a, b) => Number(a.SortOrder) - Number(b.SortOrder));
      } else if (event.target.value == 2) {
        var data = selfObj.ProjectFieldsList;
        data = data.sort((a, b) => a.FieldName.localeCompare(b.FieldName));
        selfObj.DuplicateProjectFieldsList = data;
      }
    }, 200))
  }
  getProjectFields() {
    this.initializeAgGrid('Fields');
    this.customerService.GetProjectFields(this.currentuser.id).then(result => {

      this.DuplicateProjectFieldsList = this.ProjectFieldsList = result;
    });
  }
  initializeAgGrid(Type) {
    this.gridOptionsView = <GridOptions>{
      enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, rowSelection: 'single',
      toolPanelSuppressValues: true, enableCellChangeFlash: true, enableMultiRowDragging: true, animateRows: true, rowDragMultiRow: true, suppressMoveWhenRowDragging: true
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    if (Type == 'Fields') {
      this.createColumnDefs("");
    } else if (Type == 'Stages') {
      this.createStageColumnDefs("");
    } else if (Type == 'Steps') {
      this.createStepsColumnDefs("");
    }
    
  }
  createColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, rowDrag: true
      },
      { field: 'FieldName', headerName: 'Field Name', hide: false },
      { field: 'TableName', headerName: 'Table Name', hide: true },
      { field: 'TypeOfField', headerName: 'Type Of Field', hide: false },
      { field: 'DisplayAs', headerName: 'Display As', hide: false },
      { field: 'RequiredField', headerName: 'Required Field', hide: false },
      { field: 'DataType', headerName: 'Data Type', hide: false },
      { field: 'PickListEditable', headerName: 'Pick List Editable', hide: false },
      { field: 'DataFormat', headerName: 'Data Format', hide: false },
      { field: 'MaxLimit', headerName: 'Max Limit', hide: false },
      { field: 'Description', headerName: 'Description', hide: false },
      { field: 'SortOrder', headerName: 'Sort Order', hide: false },
      //{ field: 'action', headerName: 'Action', hide: false },
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right', cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.data.isdeleted == 0 && params.data.TypeOfField == 'Custom') {
            eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
            eDiv.addEventListener('click', function () {
              params.data.isdeleted = 1;
              selfObj.SaveProjField(params.data);

            });
            return eDiv;
          }
          else if (params.data.isdeleted == 1 && params.data.TypeOfField == 'Custom') {
            eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size:14px"></i>';
            eDiv.addEventListener('click', function () {
              params.data.isdeleted = 0;
              selfObj.SaveProjField(params.data);

            });
            return eDiv;
          }

          
        }
      }
    ];
  }
  onSelectionChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {

      if (rowEvent.api.getSelectedNodes()[0].data) {
        this.ProjectFields = rowEvent.api.getSelectedNodes()[0].data;
        this.ProjectFields.OldFname = rowEvent.api.getSelectedNodes()[0].data.FieldName;
        this.ShowFieldForm('Fields');
      }
    }
  }

  SaveProjectField(type) {
    if (type == 'Fields') {
      this.ProjectFields.Userid = this.currentuser.id;
      this.ProjectFields.isdeleted = 0;
      this.SaveProjField(this.ProjectFields);
    } else if (type == 'Stages') {
      this.ProjectStage.UserId = this.currentuser.id;
      this.ProjectStage.isdeleted = 0;
      this.SaveProjectStage(this.ProjectStage);
    } else if (type == 'Steps') {
      this.ProjectSteps.UserId = this.currentuser.id;
      this.ProjectSteps.isdeleted = 0;
      this.SaveProjectSteps(this.ProjectSteps);
    }
    
  }

  SaveProjField(ProjectField) {
    this.customerService.SaveProjectField(ProjectField).then(result => {
      this.ProjectFields = new ProjectFields();
      this.toastr.success("Project Fields Saved Successfully.", "Project Fields", { timeOut: 600 });
      this.getProjectFields();
      this.ShowProjectFieldForm = false;
    });
  }
  // Project Field Ends

  // Project Field Configuration Starts

  showCheckboxes() {
    this.PickListColumns = [];
    
      var checkboxes = document.getElementById("checkboxes");
      if (!this.expanded) {
        checkboxes.style.display = "block";
        this.expanded = true;
        this.PickListColumns = this.ProjectFieldsList.filter(x => x.DataType == 'Pick List' && x.isdeleted == 0);
      } else {
        checkboxes.style.display = "none";
        this.expanded = false;
      }

  }
  onCheckboxChange(field,event) {
    if (event.target.checked == true) {
      $('.FieldCheckBox').prop('checked', false);
      $('#chkBox' + field.ProjectFieldId).prop('checked', true);
      this.SelectedProjectFields = field;
      this.customerService.GetProjectPickListData(this.SelectedProjectFields.ProjectFieldId, this.SelectedProjectFields.FieldName, this.currentuser.id).then((accounttype) => {
        this.FieldDataList = accounttype;
        this.DuplicateFieldDataList = accounttype;
        if (this.SelectedProjectFields.ProjectFieldId != 0) {
          this.ShowFieldItemTable = true;
          this.PickListSortByType(this.SelectedProjectFields.FieldDataOrderBy);
          this.initializeAgGridConfigFeild();
        } else {
          this.ShowFieldItemTable = false;
        }
      });


    }
  }
  PickListSortByType(value) {
    this.DuplicateFieldDataList = [];
    let selfObj = this;
    $(setTimeout(function () {
      if (value == 'SortOrder') {
        selfObj.DuplicateFieldDataList = selfObj.FieldDataList.sort((a, b) => a.SortOrder - b.SortOrder);
      } else if (value == 'FieldName') {
        var data = selfObj.FieldDataList;
        data = data.sort((a, b) => a.itemName.localeCompare(b.itemName));
        selfObj.DuplicateFieldDataList = data;
      }
    }, 100));
    this.SaveFieldDataOrderBy(value)
  }
  onConfigFeildsAgGridReady(event) {
    this.gridApi = event.api;
  }
  SaveFieldDataOrderBy(value) {
    this.customerService.SaveProjectFieldDataOrderBy(this.SelectedProjectFields.ProjectFieldId, value).then(mappedAccounts => {

    });
  }
  AddNewFieldData(id, val) {
      if (val == 'Add') {
        var toDoEmpty: ProjectPickListModel = ({ DataId: 0, ProjectFieldId: 0, isdeleted: 0, SortOrder: 0, itemName: '', Category: '' })
        this.FieldDataList.push(toDoEmpty);
        $(setTimeout(function () {
          $('#PickListSortOrder' + id).attr('contenteditable', "true")
          $('#PickListName' + id).attr('contenteditable', "true")
          $('#PickListRow' + id).attr('style', "background-color:#b7e4ff");
        }, 200));
      }
    

  }
  keyupPickList(val) {
    if ((this.PickListIds.filter(f => f.Index == val)).length == 0) {
      this.PickListIds.push({ Index: val });
    }

  }
  DeletePickList(val, Type) {
    this.DeletePickListObj = val;
    this.documenttypeList = [];
    if (this.PickListColumns.filter(x => x.ProjectFieldId == val.ProjectFieldId)[0].PickListEditable == 'Yes') {
      this.DeletePickListObj.isdeleted = 1;
      this.SaveData(this.DeletePickListObj);

    }
   
  }
  DeleteMatchPopUpCss() {
    setTimeout(function () {
      $('#DeleteMatchPopUp .dialog').attr('style', 'width: 45%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 81%;overflow: auto;height: 81%;top: 11%;left: 53%;');
    }, 4);
  }
  Confirmation(val) {
    this.DeleteMatchPopUp = false;
  }
  SaveData(PickListArray) {
    this.customerService.SaveProjectPickList(PickListArray).then(result => {
      this.toastr.success("Saved Successfully.", "Pick List", { timeOut: 600 });
      this.customerService.GetProjectPickListData(this.SelectedProjectFields.ProjectFieldId, this.SelectedProjectFields.FieldName,this.currentuser.id).then((accounttype) => {
        this.FieldDataList = accounttype;
        this.DuplicateFieldDataList = accounttype;
        this.PickListIds = [];
      });
    });
  }
  PickListRowClick(row, type) {
    
    if (this.PickListColumns.filter(x => x.ProjectFieldId == row.ProjectFieldId)[0].PickListEditable == 'Yes') {
        $(setTimeout(function () {
          $('#PickListSortOrder' + row.DataId).attr('contenteditable', "true")
          $('#PickListName' + row.DataId).attr('contenteditable', "true")
          $('#PickListRow' + row.DataId).attr('style', "background-color:#b7e4ff");
        }, 200));
      }
   

  }
  SavePickListData() {

    if (this.PickListIds.length > 0) {
      this.PickListArray = new ProjectPickListModel();
     
        this.PickListIds.forEach(id => {

          this.PickListArray.DataId = parseInt($('#PickListID' + id.Index).html());
          this.PickListArray.SortOrder = parseInt($('#PickListSortOrder' + id.Index).html());
          this.PickListArray.itemName = $('#PickListName' + id.Index).html();
          this.PickListArray.isdeleted = 0;
          this.PickListArray.ProjectFieldId = this.SelectedProjectFields.ProjectFieldId;
          this.PickListArray.Category = this.PickListColumns.filter(x => x.ProjectFieldId == this.SelectedProjectFields.ProjectFieldId)[0].FieldName;
        });
      this.SaveData(this.PickListArray);

    }

  }
  // Project Field Configuration Ends

  // Project Stage Starts
  getProjectTypes() {
    this.customerService.GetProjectPickListData(1, 'Project Type', this.currentuser.id).then((accounttype) => {
      this.ProjectTypeList = accounttype;
      
    });
  }

  ProjectTyperSelect(value) {
    this.ProjectStage.ProjectTypeID = value;
    if (value !== 0) {
      this.ProjectStage.ProjectTypeName = this.ProjectTypeList.find(x => x.DataId == value).itemName;

      this.DuplicateProjectStagesList = this.ProjectStagesList.filter(x => x.ProjectTypeID == value);
    } else {
      this.DuplicateProjectStagesList = this.ProjectStagesList;
    }
  }
  SaveProjectStage(ProjStage: ProjectStages) {
    this.customerService.SaveProjectStage(ProjStage).then(result => {
      this.ProjectStage = new ProjectStages();
      this.toastr.success("Project Stage Saved Successfully.", "Project Stage", { timeOut: 600 });
      this.getProjectStages();
      this.getProjectTypes();
      this.ShowProjectStageForm = false;
    });
  }

  getProjectStages() {
    this.initializeAgGrid('Stages');
    this.customerService.getProjectStages(this.currentuser.id).then(result => {
      this.ProjectStagesList = result;
      //this.DuplicateProjectStagesList = result;
      this.ProjectTyperSelect(this.selectedProjectType1)
    });
  }
  createStageColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, rowDrag: true
      },
      { field: 'ProjectStageName', headerName: 'Stage Name', hide: false },
      { field: 'ProjectTypeName', headerName: 'Projectr Type Name', hide: false },
      { field: 'SortOrder', headerName: 'Sort Order', hide: false },
      { field: 'createdDate', headerName: 'Created Date', hide: false },
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right', cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.data.isdeleted == 0) {
            eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
          }
          else if (params.data.isdeleted == 1) {
            eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size:14px"></i>';
          }

          eDiv.addEventListener('click', function () {
            params.data.isdeleted = 1;
            selfObj.SaveProjectStage(params.data);

          });
          return eDiv;
        }
      }
    ];
  }
  onStageSelectionChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {

      if (rowEvent.api.getSelectedNodes()[0].data) {
        this.ProjectStage = rowEvent.api.getSelectedNodes()[0].data;
        //this.ProjectStage.ProjectTypeName = this.ProjectTypeList.find(x => x.DataId == this.ProjectStage.ProjectTypeID).itemName;

        this.ShowFieldForm('Stages');
      }
    }
  }
  SaveProjectSteps(ProjectStep) {
    this.customerService.SaveProjectSteps(ProjectStep).then(result => {
      this.ProjectSteps = new ProjectSteps();
      this.toastr.success("Project Steps Saved Successfully.", "Project Steps", { timeOut: 600 });
      this.ProjectSteps.ProjectStageName = ProjectStep.ProjectStageName;
      this.ProjectSteps.ProjectStageId = ProjectStep.ProjectStageId;
      this.ShowProjectStepsForm = false;
      this.getProjectSteps();
    });
  }
  ProjectTypeSelect2(event) {
    this.ProjectSteps.ProjectTypeID = event.target.value;
    this.ProjectSteps.ProjectTypeName = this.ProjectTypeList.find(x => x.DataId == event.target.value).itemName;
    
    this.ProjectStepsList = this.OriginalProjectStepsList = [];
    this.GetProjStagesByProjTypeID(this.ProjectSteps.ProjectTypeID);
  }
  GetProjStagesByProjTypeID(typeId) {
    this.customerService.getProjStagesByTypeID(typeId).then(result => {
      this.StagesList = result;
    });
  }

  ProjectStageSelected(event) {
    this.customerService.getProjectSteps(this.currentuser.id).then(result => {
      this.OriginalProjectStepsList = this.ProjectStepsList = result;
      this.ProjectSteps.ProjectStageId = event.target.value;
      this.ProjectSteps.ProjectStageName = this.StagesList.find(x => x.ProjectStageID == event.target.value).ProjectStageName;
      this.ProjectStepsList = this.OriginalProjectStepsList.filter(x => x.ProjectStageId == event.target.value);
    });
    
  }


  getProjectSteps() {
    this.initializeAgGrid('Steps');
    this.customerService.getProjectSteps(this.currentuser.id).then(result => {
      this.OriginalProjectStepsList = this.ProjectStepsList = result;
      if (this.selectedProjectType != 0 && this.selectedProjectStage != 0) {
        this.ProjectStepsList = this.OriginalProjectStepsList.filter(x => (x.ProjectStageId == this.selectedProjectStage) && (x.ProjectTypeID == this.selectedProjectType));
      }
    });
  }

  createStepsColumnDefs(columnSelections: string) {
    let selfObj = this;

    this.columnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false, outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, rowDrag: true
      },
      { field: 'ProjectStepName', headerName: 'Step Name', hide: false },
      { field: 'ProjectTypeName', headerName: 'Type Name ', hide: false },
      { field: 'ProjectStageName', headerName: 'Stage Name ', hide: false },
      { field: 'Description', headerName: 'Description', hide: false },
      { field: 'SortOrder', headerName: 'Sort Order', hide: false },
      { field: 'Status1', headerName: 'Status 1', hide: false },
      { field: 'Status2', headerName: 'Status 2', hide: false },
      { field: 'Status3', headerName: 'Status 3', hide: false },
      { field: 'Status4', headerName: 'Status 4', hide: false },
      { field: 'Status5', headerName: 'Status 1', hide: false },
      { field: 'createdDate', headerName: 'Created Date', hide: false },
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right', cellRenderer(params) {
          var eDiv = document.createElement('div');
          if (params.data.isdeleted == 0) {
            eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
          }
          else if (params.data.isdeleted == 1) {
            eDiv.innerHTML = '<i class="fa fa-undo" aria-hidden="true" style="font-size:14px"></i>';
          }

          eDiv.addEventListener('click', function () {
            params.data.isdeleted = 1;
            selfObj.SaveProjectSteps(params.data);

          });
          return eDiv;
        }
      }
    ];
  }

  onStepsSelectionChanged(rowEvent) {
    var rowCount = rowEvent.api.getSelectedNodes().length;
    if (rowCount > 0) {

      if (rowEvent.api.getSelectedNodes()[0].data) {
        this.ProjectSteps = rowEvent.api.getSelectedNodes()[0].data;
        //this.ProjectStage.ProjectTypeName = this.ProjectTypeList.find(x => x.DataId == this.ProjectStage.ProjectTypeID).itemName;

        this.ShowFieldForm('Steps');
      }
    }
  }
  AddNewProjSteps() {
    this.ShowProjectStepsForm = true;
    if (this.selectedProjectType != null && this.selectedProjectType != undefined) {
      this.ProjectSteps.ProjectTypeID = this.selectedProjectType;
      this.ProjectSteps.ProjectTypeName = this.ProjectTypeList.find(x => x.DataId == this.ProjectSteps.ProjectTypeID).itemName;
      this.ProjectSteps.ProjectStageName = this.StagesList.find(x => x.ProjectStageID == this.ProjectSteps.ProjectStageId).ProjectStageName;
     
      this.GetProjStagesByProjTypeID(this.ProjectSteps.ProjectTypeID);
    }
  }

  onAgGridReady(event) {
      this.gridOptionsView.api = event.api;
  }

  onRowDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("ProjectFields");
  }
  onAgGridReadyStages(event) {
      this.gridOptionsView.api = event.api;
  }

  onRowDragEndStages(event: RowDragEndEvent) {
    this.saverowconfiguration("ProjectStages");
  }
  onAgGridReadySteps(event) {
    this.gridOptionsView.api = event.api;
  }

  onRowDragEndSteps(event: RowDragEndEvent) {
    this.saverowconfiguration("ProjectSteps");
  }

  saverowconfiguration(type) {
    var sortoorder = [];
    if (type == "ProjectFields") {
      this.gridOptionsView.api.forEachNode((node) => {
        sortoorder.push(node.data.ProjectFieldId);
      });
    } else if (type == "ProjectStages") {
      this.gridOptionsView.api.forEachNode((node) => {
        sortoorder.push(node.data.ProjectStageID);
      });
    } else if (type == "ProjectSteps") {
      this.gridOptionsView.api.forEachNode((node) => {
        sortoorder.push(node.data.ProjectStepID);
      });
    } else {
      this.gridConfigFeildsView.api.forEachNode((node) => {
        sortoorder.push(node.data.DataId);
      });
    }
    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i == a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentuser.id;
    this.leadsService.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("Configured successfully.", "Configuration", { timeOut: 600 });
    });

  }

  CategoryOrder() {
    let TaskIdArr = [];
    $.each($("#" + this.SelectedProjectFields.ColumnName + " tr td:nth-child(2)"), function (k, v) {
      var TaskIds = $.trim($(this).attr('id'));
      TaskIdArr.push(TaskIds.split('~')[1]);

    });
    this.userService.SaveConfigFieldDataSortOrder(TaskIdArr.join(','), this.SelectedProjectFields.FieldName, this.SelectedProjectFields.ProjectFieldId, this.currentuser.id).then(result => {
      this.toastr.success("Questions Re-Ordered Successfully.", " Questions", { timeOut: 600 });
    });
  }
}
