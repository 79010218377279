
<div class="col-xs-12" style="padding: 0%; height: 100%;" *ngIf="SearchTab=='Simple'">
  <div class="col-xs-12" style="padding:0%;">
    <div class="col-xs-12" style="background-color: rgb(186,220,153) !important; padding: 0%; color: rgb(186,220,153); height: 1% ">a</div>
    <span class="col-xs-11" style="padding: 1%; font-size: 20px;">Quick Search</span>
    <img class="col-xs-1" src="../../../assets/img/Black x.png" (click)="applicationComponent.pageViewed='';closeQuickSearch();"
         style="height: 22px; padding: 0%; width: 22px; margin-left: 12px;" />
  </div>

  <div class="col-xs-12" style="padding:0;padding-top:0.5%;">
    <div class="col-xs-12">
      <div class="col-xs-12" style="margin-bottom: 1%;padding: 0; height: 100%; width: 100%;font-weight: bold; border: 2px solid gray; color: #FF5F15 !important; font-size: 15px; ">
        <input *ngIf="selectedval  != '704'" type="text" (input)="onFilterTextBoxChanged()" class="form-control" [(ngModel)]="term" style="height: 34px; border: none !important" id="SearchTextbox"
               placeholder="Enter Search" />
        <input *ngIf="selectedval  == '704'" type="text" (input)="onFilterTextBoxChanged()" class="form-control" [(ngModel)]="term" style="height: 34px;border: none !important " id="SearchTextbox"
               [textMask]="{mask: phonemask}" placeholder="Enter Search Phone Number" />
      </div>
      <div class="col-xs-12" style="padding: 0%; font-size: 16px;margin-bottom:2%">
        <div class="col-xs-1" style="padding: 0%; width: 14.2%;">
          <input class="col-xs-2" type="radio" id="702" checked="checked" name="searchval" val='702' stye="height:1em;" (change)="SearcValueItemSelect(702)">
          <span class="col-xs-10">Accounts</span>
        </div>
        <div class="col-xs-1" style="padding: 0%; width: 14.2%;">
          <input class="col-xs-2" type="radio" id="703" name="searchval" val='703' (change)="SearcValueItemSelect(703)">
          <span class="col-xs-10">Contacts</span>
        </div>
        <div class="col-xs-1" style="padding: 0%; width: 14.2%;">
          <input class="col-xs-2" type="radio" id="704" name="searchval" val='704' (change)="SearcValueItemSelect(704)">
          <span class="col-xs-10">Phone</span>
        </div>
        <div class="col-xs-1" style="padding: 0%; width: 14.2%;">
          <input class="col-xs-2" type="radio" id="705" name="searchval" val='705' (change)="SearcValueItemSelect(705)">
          <span class="col-xs-10">Email</span>
        </div>
        <div class="col-xs-1" style="padding: 0%; width: 14.2%;">
          <input class="col-xs-2" type="radio" id="706" name="searchval" val='706' (change)="SearcValueItemSelect(706)">
          <span class="col-xs-10">City</span>
        </div>
        <div class="col-xs-1" style="padding: 0%; width: 14.2%;">
          <input class="col-xs-2" type="radio" id="723" name="searchval" val='723' (change)="SearcValueItemSelect(723)">
          <span class="col-xs-10">State</span>
        </div>
        <div class="col-xs-1" style="padding: 0%; width: 14.2%;">
          <input class="col-xs-2" type="radio" id="724" name="searchval" val='724' (change)="SearcValueItemSelect(724)">
          <span class="col-xs-10">Zip</span>
        </div>
      </div>
      <div class="col-xs-12" style="padding: 0%; font-size: 16px; margin-bottom: 2%">
        <div class="col-xs-4" style="padding: 0%">
          <input class="col-xs-1 checkbox-round" [checked]="isactivecustomer" id="ActiveCustomers" [(ngModel)]="isactivecustomer" type="checkbox" (click)="ActiveItemSelect($event)">
          <span class="col-xs-10">Active Customers</span>
        </div>
        <div class="col-xs-4" style="padding: 0%">
          <input class="col-xs-1 checkbox-round" [checked]="isinactivecustomer" id="InActiveCustomers" [(ngModel)]="isinactivecustomer" type="checkbox" (click)="InActiveItemSelect()">
          <span class="col-xs-10">Inactive Customers</span>
        </div>
        <div class="col-xs-4" style="padding: 0%">
          <input class="col-xs-1 checkbox-round" [checked]="issharedaccounts" id="SharedAccounts" [(ngModel)]="issharedaccounts" type="checkbox" (click)="SharedAccountsSelect()">
          <span class="col-xs-10">Shared Accounts</span>
        </div>


      </div>

      <div class="col-xs-12" style="font-size: 12px;padding: 0% 0 2% 0">
        <angular2-multiselect [data]="applicationComponent.dropdownList" [(ngModel)]="applicationComponent.selectedItems" id="QuickUserList"
                              [settings]="dropdownSetting"
                              (onSelect)="onItemSelect($event)"
                              (onDeSelect)="OnItemDeSelect($event)"
                              (onSelectAll)="onSelectAll($event)"
                              (onDeSelectAll)="onDeSelectAll($event)">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="col-xs-12" style=" padding: 0px;">
        <div class="col-xs-10">
          <b style="font-size:12px;"> SEARCH RESULTS</b>
        </div>
      </div>
      <div class="col-xs-12" style="padding: 0; height: 750px" *ngIf="SearchType == 'accountName'">
        <ag-grid-angular style="height:70%;" class="ag-theme-balham" id="AccountGrid"
                         [gridOptions]="gridOptions"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="applicationComponent.defaultGridColDef"
                         [rowData]="allCustomers"
                         [context]="context"
                         (cellDoubleClicked)="AccountcellDoubleClicked($event)"
                         (selectionChanged)="onSelectionChanged($event)"
                         [sideBar]="sideBar" (gridReady)="onAgGridReady($event)">
        </ag-grid-angular>

      </div>
      <div class="col-xs-12" style="padding: 0; height: 750px" *ngIf="SearchType == 'contactName'">
        <ag-grid-angular style="width: 100%; height:70% !important;" id="ContactGrid" class="ag-theme-balham"
                         [gridOptions]="gridOptions"
                         [columnDefs]="ContactcolumnDefs"
                         [defaultColDef]="applicationComponent.defaultGridColDef"
                         [rowData]="allContacts"
                         [context]="context"
                         [sideBar]="sideBar"
                         (cellDoubleClicked)="ContactcellDoubleClicked($event)"
                         (selectionChanged)="onContactsSelectionChanged($event)"
                         (gridReady)="onAgGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>
  <div class="col-xs-6" style="height:100%">
    <div class="col-xs-12" style="padding:0;padding-top:0.5%">
      <div class="col-xs-11" style="padding:0 0 0 2%;height:18%" *ngIf="showSearchTabGrid && AccountHistoryInfo">
        <div class="col-xs-11" style="padding:0;height:100%">
          <nav id="nav-menu-container">
            <ul class="footer-nav" style="padding:0px; width: 100%;margin:0;">
              <li class="subButtons" name="Schedule Task" id="schedTask_button" title="Schedule Task" (click)="IconClick('Create Task')" style="border: none !important; height: 28px;width:40px">
                <img src="../../assets/img/Create Task Icon V4.png" style="height: 26px; width: 34px;" />
              </li>
              <li class="subButtons" name="Tickets" id="tickets_button" title="Create Ticket" (click)="IconClick('Create Tickets')" style="border: none !important; height: 28px; width: 40px">
                <img src="../../assets/img/Create Support Ticket Icon.png" style="height: 26px; width: 34px;" />
              </li>
              <li class="subButtons" name="SMS" id="sms_button" title="Create SMS" style="border: none !important; height: 28px; width: 40px">
                <img src="../../assets/img/Create SMS Icon.png" style="height: 26px;width: 34px;" />
              </li>
              <li class="subButtons" name="Email" id="email_button" title="Create Email" style="border: none !important; height: 28px; width: 40px">
                <img src="../../assets/img/Create Email Icon.png" style="height: 26px;width: 34px;" />
              </li>
              <li class="subButtons" name="Create Proposal" id="proposal_button" title="Create Proposal" (click)="IconClick('Create Proposal')" style="border: none !important; height: 28px; width: 40px">
                <img src="../../assets/img/Create Proposal Icon.png" style="height: 26px; width: 34px;" />
              </li>
            </ul>
          </nav>
        </div>
      </div>

    </div>
    <div class="col-xs-12" style="padding: 1% 0 0 2%; height: 78%; margin-top: 2%; border: 1px solid lightgray; font-size: 11px;" *ngIf="showSearchTabGrid && AccountHistoryInfo">
      <div class="col-xs-12" style="padding: 0.5%; height: 17.5%; ">
        <label> Account Type : </label><span>
          {{account.accounttypeName}}
        </span>
      </div>
      <div class="col-xs-12" style="padding: 0.5%; height: 17.5%; ">
        <label> Ownership : </label><span>{{account.ownershipName}} </span>
      </div>
      <div class="col-xs-12" style="padding: 0.5%; height: 17.5%; ">
        <label> Account Status : </label><span>{{(account.accountStatus == 14)? 'Active': (account.accountStatus == 15)? 'On Hold':''}}</span>
      </div>
      <div class="col-xs-12" style="padding: 0.5%; height: 17.5%; ">
        <label> Account Owner : </label><span>{{account.owner}} </span>
      </div>
      <div class="col-xs-12" style="padding: 0.5%; height: 17.5%; ">
        <label> Customer : </label><span>{{account.customertype}} </span>
      </div>
      <div class="col-xs-12" style="padding: 0.5%; height: 17.5%;">
        <label> Last Contact Date : </label><span>{{LastContactDate}} </span>
      </div>
    </div>
  </div>

</div>

<app-dialog [(visible)]="SaveUserListConfirmation" *ngIf="SaveUserListConfirmation">
 <div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br /><br />
    <span style="font-size:15px;">User List</span><br />
    <div class="col-xs-8" style="padding:0;margin-top:1%">
      <span style="font-size:15px;">Save Changes to User List?</span><br />      
    </div>
    <div class="col-xs-4" id="MyButtonsDiv" style="padding:0;">
      <button class="btn" (click)="SaveConfirm('Yes')" style="background-color: #0098f7 !important;color: white;">OK</button>
      <button class="btn" (click)="SaveConfirm('No')" style="margin-left: 4%;">Cancel</button>
   </div>
</div>
</app-dialog>


