import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Column, GridOptions, RowDragEndEvent } from 'ag-grid';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { UserMessageTypes } from 'src/app/models/UserMessageTypes';
import { Dropdowntype } from 'src/app/models/dropdowntype';
import { UserMessages } from 'src/app/models/UserMessages';
import { MessagingService } from 'src/app/services/messaging.service';
import { UserService } from 'src/app/services/user.service';
import { OpprtunitiesService } from 'src/app/services/opprtunities.service';
import { LeadsService } from 'src/app/services/leads.service';
import { SystemRowConfiguration } from 'src/app/models/system-row-configuration';
import { ApplicationComponent } from '../application.component';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { AuditLogModel } from '../../models/auditlog';
declare var $: any;
@Component({
  selector: 'app-user-messaging',
  templateUrl: './user-messaging.component.html',
  styleUrls: ['./user-messaging.component.css']
})
export class UserMessagingComponent implements OnInit {
  public UserMessagingMainDiv: boolean = true;
  public MessageTypesDiv: boolean = true;
  public ExpandMessageTypesGrid: boolean = false;
  AddMessageTypeForm: FormGroup;
  createNewMessageType: boolean = false;
  MessageTypes: UserMessageTypes = new UserMessageTypes();
  allMessageTypes: UserMessageTypes[];
  public gridOptions: GridOptions;
  public columnDefs: any[];
  currentUser: any;
  selectedtab = 'MessageTypes';
  public NewMessageDiv: boolean = false;
  viewSuspendedUsers: boolean = false;
  selectedUserType = "Users";
  allusers: any;
  showAddUserDiv: boolean = false;
  public gridOptionsusers: GridOptions;
  save: boolean = false;
  public context;
  public columnDefsusers: any[];
  public columnDefsMessage: any[];
  rolesList: Dropdowntype[] = [];
  UserTabSelection: string = "tab6";
  userMessages: UserMessages = new UserMessages();
  AddMessageForm: FormGroup;
  selectedUserList = [];
  datePipe: DatePipe = new DatePipe("en-US");
  public ViewMessageListDiv: boolean = false;
  allMessages: UserMessages[];
  public gridOptionsUserMessages: GridOptions;
  EditMessageMode: boolean = false;
  public sideBar;
  rowconfig: SystemRowConfiguration = new SystemRowConfiguration();
  DeleteMessageTypePopup: boolean = false;
  deleteRowDataType: any;
  contactStatus: any;
  DeleteMessagePopup: boolean = false;
  deleteRowDataMessage: any;
  contactStatus1: any;
  @Input() OpenDivType: string = null;
  MessageDescription: string;
  public editorOptions: Object = {
    attribution: false,
    key: 'pe1G2wF1I1B2C3B5D6B4ogtzrkayiqC2A-7pmB-16D4E3D2F3C3I2D1B10C2B3==',
    events: {
    contentChanged: () => {
        this.Validatefield();
    }
  }
  };
  constructor(private messageService: MessagingService, private toastr: ToastrService, private userservice: UserService, private opprtunityservice: OpprtunitiesService,
    private leadsService: LeadsService, public applicationComponent: ApplicationComponent) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }
  Validatefield() {
    let Self = this;
    setTimeout(() => {                        // <<<---using ()=> syntax
    
      if (Self.selectedUserList.length > 0
        && ($('.fr-element').html().replace(/<[^>]*>|&nbsp;/g, '') != '' && $('.fr-element').html().replace(/<[^>]*>|&nbsp;/g, '') != undefined)) {
        Self.save = true;
      } else {
        Self.save = false;
      }
    },100);
  }
  ngOnInit() {
    this.initializeAgGrid();
    this.AccessDiv('MessageTypes');  
  }

  AccessDiv(val) {
    this.selectedtab = val;
    if (val == 'MessageTypes') {
      this.UserMessagingMainDiv = false;
      this.ViewMessageListDiv = false;
      this.MessageTypesDiv = true;
      this.NewMessageDiv = false;
      this.GetAllUserMessageTypes();
      this.MessageTypes = new UserMessageTypes();
    }
    else if (val == 'New Message') {
      
      this.UserMessagingMainDiv = false;
      this.MessageTypesDiv = false;
      this.NewMessageDiv = true;
      this.ViewMessageListDiv = false;
      this.LoadRequiredData();
    }
    else if (val == 'Messages List') {
      this.UserMessagingMainDiv = false;
      this.MessageTypesDiv = false;
      this.NewMessageDiv = false;
      this.EditMessageMode = false;
      this.ViewMessageListDiv = true;
      this.LoadViewMessageList();
    }
    var obj: AuditLogModel = { id: 0, type: 'PopUp Message', action: 'PopUp Message - ' + val, AccountOrContactId: 0, AccountOrContact: val, documentid: 0, userid: this.currentUser.id, dateofviewed: '', customertype: '', time: '', Title: 'PopUp Message - ' + val , AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }

  getAddMessageTypeForm(): FormGroup {
    return new FormGroup({
      'MessageType': new FormControl(this.MessageTypes.MessageType, Validators.required),
      'Sortorder': new FormControl(this.MessageTypes.Sortorder, Validators.required),
    });
  }

  CloseDiv(val) {
    if (val == 'Message Types') {
      this.MessageTypesDiv = false;
      
      this.UserMessagingMainDiv = true;
      this.createNewMessageType = false;
      this.NewMessageDiv = false;
    }
    else if (val == 'New Message') {
      this.MessageTypesDiv = false;
      this.UserMessagingMainDiv = true;
      this.NewMessageDiv = false;
      if (this.EditMessageMode == true) {
        this.AccessDiv('Messages List');
      }
      this.userMessages = new UserMessages();
      this.selectedUserList = null
    }
    else if (val == 'Messages List') {
      this.UserMessagingMainDiv = true;
      this.MessageTypesDiv = false;
      this.NewMessageDiv = false;
      this.ViewMessageListDiv = false;
      this.userMessages = new UserMessages();
    }
    
  }

  ClearMessageType() {
    this.MessageTypes = new UserMessageTypes();
  }

  CreateNewMessageType() {
    this.getAddMessageTypeForm();
    this.createNewMessageType = true;
    this.ExpandMessageTypesGrid = false;
    this.MessageTypes = new UserMessageTypes();
    var NextsortOrder = this.allMessageTypes.sort((a, b) => b.Sortorder - a.Sortorder)[0].Sortorder;
    this.MessageTypes.Sortorder = NextsortOrder + 1;

  }
  Expand(val) {
    if (val == 'Message Types') {
      this.ExpandMessageTypesGrid = !this.ExpandMessageTypesGrid;
    } else if (val == 'Item') {
      //this.ExpandItemGrid = !this.ExpandItemGrid;
    }
  }

  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      enableColResize: true, enableSorting: true, defaultToolPanel: '', enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, rowSelection: 'single',
      toolPanelSuppressValues: true, enableCellChangeFlash: true, enableMultiRowDragging: true,
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };

    this.createColumnDefs();
  }

  createColumnDefs() {
    let selfObj = this;
    this.columnDefs = [
      {
        field: '#',
        headerName: '#',
        width: 65,
        checkboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false,
        outerWidth: 8,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true, enableTooltip: true, rowDrag: true
      },
      { field: 'MessageTypeID', headerName: 'Id', hide: false, width: 120 },
      {
        field: 'MessageType', headerName: 'Message Type', hide: false, width: 400,
      },
      { field: 'Sortorder', headerName: 'Sort Order', hide: false, width: 120 },
      {
        field: 'action', headerName: 'Action', hide: false, width: 100, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:20%"/>';
          eDiv.addEventListener('click', function () {
            selfObj.deleteMessageTypes(params.data);
          });
          return eDiv;
        }
      }
    ];

   


  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.MessageTypes = event.api.getSelectedNodes()[0].data;
        this.createNewMessageType = true;
      }
    }
    else { 
      this.MessageTypes = null;
      this.ClearMessageType();
      this.createNewMessageType = false;
    }

  }

  onRowMessageDragEnd(event: RowDragEndEvent) {
    this.saverowconfiguration("MessageType");
  }
  saverowconfiguration(type) {
    var sortoorder = [];
   
      this.gridOptions.api.forEachNode((node) => {
        sortoorder.push(node.data.MessageTypeID);
      });
   
    this.rowconfig.SortOrder = sortoorder.filter((el, i, a) => i == a.indexOf(el)).join(",");
    this.rowconfig.Type = type;
    this.rowconfig.userid = this.currentUser.id;
    this.leadsService.SavestatusOrder(this.rowconfig).then(result => {
      this.toastr.success("MessageType Configured successfully.", "MessageType", { timeOut: 600 });
    });

  }
  SaveProposalCategory() {
    this.MessageTypes.UserID = this.currentUser.id;
    if (this.MessageTypes.MessageTypeID == null || this.MessageTypes.MessageTypeID == undefined || this.MessageTypes.MessageTypeID == 0) {
      this.MessageTypes.MessageTypeID = 0;
    }

    this.MessageTypes.deletedStatus = 0;
    this.AddorUpdateUserMessageTypes(this.MessageTypes);
  }
  AddorUpdateUserMessageTypes(MessageTypes) {
    this.messageService.AddorUpdateUserMessageTypes(MessageTypes).then(result => {
      this.GetAllUserMessageTypes();
      this.MessageTypes = new UserMessageTypes();
      this.createNewMessageType = false;
      this.toastr.success("Message Type saved successfully.", "Message Type Saved", { timeOut: 600 });
    });
  }

  GetAllUserMessageTypes() {
    this.messageService.GetAllUserMessageTypes(this.currentUser.id).then(result => {
      this.allMessageTypes = result;
    });
  }
  ReceiveDeleteeventForType(msg) {
    if (msg == "Ok") {
      this.MessageTypes = this.deleteRowDataType;
      this.MessageTypes.deletedStatus = 1;
      this.AddorUpdateUserMessageTypes(this.MessageTypes);
    }
    this.DeleteMessageTypePopup = false;

  }
  deleteMessageTypes(MessageType) {
    this.deleteRowDataType = MessageType;
    this.contactStatus = MessageType.deletedStatus;
    this.DeleteMessageTypePopup = true;
    this.applicationComponent.salesxcrmDialog('#DeleteMessageTypePopup .dialog');
    //setTimeout(function () {
    //  $('#DeleteMessageTypePopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    //}, 4);
  }


  UserSetUp(val) {
    if (val == "Users") {
      this.viewSuspendedUsers = false;
      this.viewSuspended(0);
    }
    else if (val == "Suspended") {
      this.viewSuspendedUsers = true;
      this.viewSuspended(1);
    }
    else {
      this.viewSuspendedUsers = false;
    }
    this.selectedUserType = val;

  }

  viewSuspended(val) {

    let messageId = (this.userMessages != null && this.userMessages.userMessageID) ? this.userMessages.userMessageID : 0;

    this.userservice.GetUserMessages(val, messageId).then((user) => {
      this.allusers = user;
      if (this.EditMessageMode == true && this.userMessages.MessageToUsers != null) {
        var selectedUsers = this.userMessages.MessageToUsers.split(',').map(Number);
        this.allusers =  this.allusers.filter(({ id }) => selectedUsers.includes(id));

        selectedUsers.forEach(ele => {
          let selfObj = this;
          $(setTimeout(function () {
            selfObj.gridOptionsusers.api.forEachNode((node) => {
              if (node.data.id == ele) {
                selfObj.gridOptionsusers.api.selectNode(node, true);
              }
            });
          }, 500));
        });
      }
        
      
    });
  }


  initializeAgGridusers() {
    this.gridOptionsusers = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'multiple'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentUser.id, 57).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefsusers(columnSelections);
    });
    this.gridOptionsusers.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
  }
  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptionsUserMessages.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();
    config.maxlimit = 10;
    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

    config.configureduserid = this.currentUser.id;
    config.resourceid = 57;

    config.userid = "";
   
    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config).then(result => {
    });

  }
  createColumnDefsusers(columnSelections: string) {
    let selfobj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefsusers = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefsusers.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "userrole") {
          this.columnDefsusers.push({
            field: 'userrole', headerName: 'User Role', hide: false,
            editable: true, cellEditor: "agSelectCellEditor", cellEditorParams: function (params) {
              return {
                values: Array.prototype.map.call(selfobj.rolesList, s => s.itemName),
                formatValue: function (value) {
                }
              };
            }
          })
        }
        else {
          this.columnDefsusers.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefsusers = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'firstname', headerName: 'First Name', hide: false,width:120 },
        { field: 'lastname', headerName: 'Last Name', hide: false, width: 120 },
        {
          field: 'userrole', headerName: 'User Role', hide: false, width: 120,
          editable: true, cellEditor: "agSelectCellEditor", cellEditorParams: function (params) {
            return {
              values: Array.prototype.map.call(selfobj.rolesList, s => s.itemName),
              formatValue: function (value) {
              }
            };
          }
        },
        { field: 'email', headerName: 'Email', hide: false, width: 180},
        { field: 'username', headerName: 'UserName', hide: false, width: 140 },
        { field: 'Viewed', headerName: 'Viewed', hide: selfobj.userMessages.userMessageID == 0 || selfobj.userMessages.userMessageID == null, width: 110 },
        { field: 'NotViewed', headerName: 'NotViewed', hide: selfobj.userMessages.userMessageID == 0 || selfobj.userMessages.userMessageID == null, width: 120 },
      ];
    }
  }


  LoadRequiredData() {
    this.userservice.GetAllRolesList(this.currentUser.id).then((roles) => {
      this.rolesList = roles;
    });

    this.initializeAgGridusers();
    this.UserSetUp('Users');
    this.getAddMessageForm();
    this.GetAllUserMessageTypes();
  }

  getAddMessageForm(): FormGroup {
    return new FormGroup({
      'MessageName': new FormControl(this.userMessages.MessageName, Validators.required),
      'MessageTypeId': new FormControl(this.userMessages.MessageTypeId, Validators.required),
      'DisplayDate': new FormControl(this.userMessages.DisplayDate, Validators.required),
      'MessageDescription': new FormControl(this.userMessages.MessageDescription, Validators.required),
    });
  }

  SaveMessage() {
    if (this.userMessages.userMessageID == 0 || this.userMessages.userMessageID == null) {
      this.userMessages.userMessageID = 0;
    }
    this.userMessages.CreatedBy = this.currentUser.id;
    this.userMessages.MessageDescription = $('.fr-element').html();
    if (this.selectedUserList != null && this.selectedUserList.length != 0) {
      this.userMessages.MessageToUsers = this.selectedUserList.join(',');
      this.AddorUpdateUserMessages(this.userMessages);
    } else {
      alert("You have not selected any user for this Message. Please Select ")
    }
    
    
  }

  AddorUpdateUserMessages(userMessages) {
    this.messageService.AddorUpdateUserMessages(userMessages).then((res) => {
      this.clearForm();
      this.viewSuspended(0)
      this.toastr.success("Message saved successfully.", "Message Saved", { timeOut: 600 });
    });
  }

  onUserSelection(event) {
    this.Validatefield();
    this.selectedUserList = [];
    event.api.getSelectedRows().forEach(ele => {
      this.selectedUserList.push(ele.id);
    });
  }

  clearForm() {
    this.userMessages = new UserMessages();
    this.selectedUserList = [];
  }

  GetAllUserMessages() {
    this.messageService.GetAllUserMessages(this.currentUser.id).then((res) => {
      this.allMessages = res;
      this.allMessages.forEach(ele => {
        ele.DisplayDate = this.datePipe.transform(ele.DisplayDate, "MM-dd-yyyy");
        if (ele.MessageToUsers != null) {
          ele.MessageToUserCount = ele.MessageToUsers.split(',').length;
        }
        if (ele.readusers != null) {
          ele.readuserCount = ele.readusers.split(',').length;
        }
        else {
          ele.readuserCount = 0;
        }
        ele.RemainingCount = ele.MessageToUserCount - ele.readuserCount;
      });

    });
  }

  initializeAgGridUserMessages() {
    this.gridOptionsUserMessages = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'multiple'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };

    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentUser.id, 57).then((config) => {
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.createColumnDefsMessages(columnSelections);
    });
    
  }

  createColumnDefsMessages(columnSelections: string) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefsMessage = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefsMessage.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "userrole") {
          this.columnDefsMessage.push({
            field: 'userrole', headerName: 'User Role', hide: false,
            editable: true, cellEditor: "agSelectCellEditor", cellEditorParams: function (params) {
              return {
                values: Array.prototype.map.call(selfObj.rolesList, s => s.itemName),
                formatValue: function (value) {
                }
              };
            }
          })
        }
        else {
          this.columnDefsMessage.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {
      this.columnDefsMessage = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { field: 'userMessageID', headerName: 'Message ID', hide: false ,width:120},
        { field: 'MessageName', headerName: 'Message Name', hide: false },
        { field: 'MessageType', headerName: 'Message Type', hide: false },
        { field: 'DisplayDate', headerName: 'Display Date', hide: false },
        { field: 'MessageDescription', headerName: 'Message Description', hide: false },
        { field: 'MessageToUserCount', headerName: 'Users', hide: false },
        { field: 'readuserCount', headerName: 'Viewed', hide: false },
        { field: 'RemainingCount', headerName: 'Not Viewed', hide: false },
        {
          field: 'action', headerName: 'Action', hide: false, width: 100, pinned: 'right', cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:20%"/>';
            eDiv.addEventListener('click', function () {
              selfObj.deleteUserMessage(params.data);
            });
            return eDiv;
          }
        }
      ];
    }
  }

  LoadViewMessageList() {
    this.initializeAgGridUserMessages();
    this.GetAllUserMessages();
  }
  onUserMessageSelection(event) {
    this.userMessages = event.api.getSelectedRows()[0];
    this.userMessages.DisplayDate = (this.datePipe.transform(this.userMessages.DisplayDate, "yyyy-MM-dd"));
    this.AccessDiv('New Message');
    this.EditMessageMode = true;
   
  }
  ReceiveDeleteeventForMessage(msg) {
    if (msg == "Ok") {
      this.userMessages = this.deleteRowDataMessage;
      this.userMessages.deletedStatus = 1;
      this.AddorUpdateUserMessages(this.userMessages);
    } 
    this.DeleteMessagePopup = false;
    
  }
  deleteUserMessage(UserMessages) {
    this.deleteRowDataMessage = UserMessages;
    this.contactStatus = UserMessages.deletedStatus;
    this.DeleteMessagePopup = true;
    this.applicationComponent.salesxcrmDialog('#DeleteMessagePopup .dialog');
    //setTimeout(function () {
    //  $('#DeleteMessagePopup .dialog').attr('style', 'width: 28%;padding-bottom: 0px;border: 4px solid rgb(102 181 49);min-height: 18%;overflow: auto;height: 4%;top:10%');
    //}, 4);
  }

  close() {
    this.applicationComponent.ShowConfigurationDialog = false;
    this.applicationComponent.activateMainButtons('dashboards');
    
  }
  
}
