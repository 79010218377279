import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
declare var $: any;
@Component({
  selector: 'app-notesrenderer',
  templateUrl: './notesrenderer.component.html',
  styleUrls: ['./notesrenderer.component.css']
})
export class NotesrendererComponent implements OnInit, ICellRendererAngularComp {

  constructor() { }

  ngOnInit() {
    //if (document.getElementById('coustomers').className == 'mybtn coustomButton mainactiveButton') {
    //  $('#location').prop("style", "height: 20px;");
    //} 
  }

  public params: any;

  agInit(params: any): void {
    this.params = params;
   
  }
  needLocationBtn() {
   // return (document.getElementById('coustomers').className == 'mybtn coustomButton mainactiveButton')? true : false
  }

  public openNotesDialogAg() {
     
    this.params.context.openNotesDialogAg(this.params.node.data);

  }
  public openMultiplelocationDialogAg() {
    this.params.context.openMultiplelocationDialogAg(this.params.node.data);
  }
  refresh(): boolean {
    return false;
  }

}
