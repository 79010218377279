

<div class="container" style="height:100%;padding:0;">
  <div style="height:100%">
    <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 0.3% 0 0.3% 0; height: 7.5%" id="actiondivid">
      <userdataheader [parentComp]="'Calendar'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)" (sendMsgEvent)="ShowCalenderListView($event)">
      </userdataheader>
    </div>
    <input type="text" id="accotIdNotes" style="display:none;" />
    <input type="text" id="accotTypeNotes" style="display:none;" />
    <input type="text" id="emailIds" style="display:none;" />
    <input type="text" id="tasklistselectedtyle" style="display:none;" />
    <app-dialog [(visible)]="showDialog" *ngIf="showDialog" [CloseButtonColor]="'white'">
      <app-new-notes style="height:100%"
                     [selectedTabName]="'Tasks Only'"
                     [conid]="conid"
                     [typ]="'Contact'"
                     [isModal]="true">
      </app-new-notes>
    </app-dialog>
    <div *ngIf="expandCalenderView" class="col-xs-1" style=" width:2%;padding:0;">
      <div class="col-xs-12" style="padding: 0;width:100%;height:5%">
        <div class="col-xs-12" style="padding:0;">
          <span (click)="expandCalender(false)"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
        </div>
      </div>
    </div>
    <div class="col-xs-12" style="padding: 0; height: 5%; float: right; border: 1px solid lightgray; margin-bottom: 4px;">
      <div class="col-xs-11" style="padding: 0px; background-color: lightgray; width: 98%; ">
        <div class="col-xs-2" style="width: 20%;padding:0.5%;">
          <div class="col-xs-12" style="background-color: white;">
            <div class="col-xs-1 todocircle"></div>
            <div class="col-xs-8">
              To Do
            </div>
            <div class="col-xs-1">
              {{calevent.ToDoCount}}
            </div>
          </div>
        </div>
        <div class="col-xs-2" style="width: 20%; padding: 0.5%;">
          <div class="col-xs-12" style="background-color: white;">
            <div class="col-xs-1 phonecircle"></div>
            <div class="col-xs-8">
              Phone
            </div>
            <div class="col-xs-1">
              {{calevent.PhoneCount}}
            </div>
          </div>
        </div>
        <div class="col-xs-2" style="width: 20%; padding: 0.5%;">
          <div class="col-xs-12" style="background-color: white;">
            <div class="col-xs-1 meetingcircle"></div>
            <div class="col-xs-8">
              Meeting
            </div>
            <div class="col-xs-1">
              {{calevent.MeetingCount}}
            </div>
          </div>
        </div>
        <div class="col-xs-2" style="width: 20%; padding: 0.5%;">
          <div class="col-xs-12" style="background-color: white;">
            <div class="col-xs-1 blockedcircle"></div>
            <div class="col-xs-8">
              Blocked
            </div>
            <div class="col-xs-1">
              {{calevent.BlockedCount}}
            </div>
          </div>
        </div>
        <div class="col-xs-2" style="width: 20%; padding: 0.5%;">
          <div class="col-xs-12" style="background-color: white;">
            <div class="col-xs-1 "></div>
            <div class="col-xs-8">
              Open Tasks
            </div>
            <div class="col-xs-1">
              {{calevent.OpenTaskCount}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-1" style="padding: 0.5% 1% 0% 0.5%; width: 2%;">
        <img src="../../../assets/img/Black x.png" (click)="Close()" style="height:20px"/>
      </div>
     
    </div>
    <div class="col-xs-12" style="padding: 0; overflow: hidden;float: right; border: 1px solid lightgray;"
         [style.width.%]="(this.expandCalenderView == false)? '100':'97'">
      <div class="col-xs-12" style="padding:0;">
        <full-calendar #NewtimeGridWeekCalender0 id="NewtimeGridWeekCalender0" [options]="TimeGridWeekOptions0"></full-calendar>       
      </div>

      <div class="col-xs-12" style="padding: 0;" *ngIf="showListView == 'ListView'">
        <ag-grid-angular  style="width: 100%;padding-top:5px;height: 550px;"  class="ag-theme-balham"
                         [gridOptions]="gridOptions"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="applicationComponent.defaultGridColDef"
                         [rowData]="alltaskhistory"
                         [context]="context"
                         [sideBar]="sideBar"
                         [frameworkComponents]="frameworkComponents"
                         (selectionChanged)="onTaskListChanged($event)"
                         (gridReady)="onAgGridReady($event)">
        </ag-grid-angular>
      </div>
      
      </div>

    </div>
</div>
<app-dialog [(visible)]="taskDialog" [CalendarDialog]="true" *ngIf="taskDialog" id="taskDialog" (visibleChange)="SelectedDate = null;BTClick= false" [closable]="false" [OutSideClick]="false" [CloseButtonColor]="'black'">
  <app-task [id]="id" [taskid]="taskid" [type]="type" [BTClick]="BTClick" [taskDialog]="true" [SearchDialog]="SearchDialog" [selectorTag]="true" [selectedDate]="SelectedDate" (sendMsgEvent)="onTaskSaved($event)"></app-task>

</app-dialog>
<app-dialog [(visible)]="SearchDialog" id="SearchDialog" [CalendarDialog]="true" style="width:542px" *ngIf="SearchDialog">
  <searchdata (sendMsgEvent)="onSearchstring($event)">
  </searchdata>
</app-dialog>

<app-dialog [(visible)]="MoveTaskDialog" id="MoveTaskDialog" *ngIf="MoveTaskDialog" [closable]="false">
  <div class="col-xs-12" style="margin-top:5%">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
    <div class="col-xs-12" style="padding: 0%; margin-top: 1%">
      <label style="font-size:14px;"> CALANDER </label><br />
      <label style="font-size:12px;">Select OK to confirm moving task?</label>

    </div>
    <div class="col-xs-4" style="padding: 0; float: right; margin-top: 4%;">
      <div class="col-xs-6" style="padding:0">
        <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%" (click)="MoveTask('OK')">OK</button>
      </div>
      <div class="col-xs-6" style="padding:0">
        <button class="btn" style="float:right;background-color: white !important;color: black;margin-top:1%" (click)="MoveTaskDialog=false;MoveTask('CANCEL')">Cancel</button>
      </div>
    </div>
  </div>
</app-dialog>

<div style='clear:both'></div>

<app-dialog [(visible)]="LoaderDialog" *ngIf="LoaderDialog" id="LoaderDialog"  [closable]="false">
  <div class="loader"></div>
</app-dialog>

