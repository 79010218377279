<div class="container" style="height:100%;padding:0;">
  <input type="text" id="emailIds" style="display:none;" />
  <input type="text" id="tasklistselectedtyle" style="display:none;" />
  <div class="col-xs-12" style="padding-left:0;padding-right: 0;height:100%">
    <div *ngIf="!isShowAccountInfo && PageFrom!='dashboard' && PageFrom!='Reports' " class="col-xs-12" style="padding:0.3% 0 0.3% 0;height:6%">
      <userdataheader [parentComp]="type" [selectedItems]="applicationComponent.selectedItems" (outPropsEvent)="UserlistDataReceived($event)"
                      *ngIf="!applicationComponent.googlemapshow">
      </userdataheader>
      <div class="col-xs-1" *ngIf="applicationComponent.googlemapshow" style="padding: 0%;float: right;">
        <span style="float:right;margin-top:7%" (click)="CloseAccountDiv();"> <img src="../../../assets/img/Black x.png" style="height:25px" /> </span>
      </div>
    </div>
    <div class="col-xs-12" style="padding:0px" [style.height.%]="(isShowAccountInfo)? '100':(PageFrom =='dashboard')? '100':'93'">

      <div [ngClass]="(isShowAccountInfo) ? 'col-xs-4':(applicationComponent.googlemapshow)?'col-xs-5':'col-xs-12'" style="padding-top: 10px; height: 100%; padding: 0px; margin-top: 0.5%;" [style.border]="(applicationComponent.searchCriteriadata != '')?'3px solid #00B0F0':'none'" *ngIf="isAccountViewOpen">

        <div style="height:4%" *ngIf="(isShowAccountInfo && isAccountViewOpen && PageFrom != 'dashboard' ) || applicationComponent.googlemapshow">
          <span (click)="CloseAccountDiv()"><img src="../../../assets/img/Black x.png" style="height: 21px; float: left; transform: rotate(180deg);" /></span>
          <b *ngIf="type!='Customer'" style=" font-size: 9pt; margin-left: 10px;"> ACCOUNTS  <!--( {{(allCustomers && allCustomers.length > 0)? allCustomers.length : 0 }} )--></b>
          <b *ngIf="type=='Customer'" style=" font-size: 9pt; margin-left: 10px;"> CUSTOMER ACCOUNTS </b>
          <span (click)="expandaccountView('Collapse')"><img src="../../../assets/img/Open Quickview Grid Panel.png" style="height: 23px; float: right; transform: rotate(180deg);" /></span>

        </div>      
    
      <div *ngIf="!isStatusidopen" class="col-xs-12 input-icons" style="padding: 7px; margin-bottom: 0.3%; border: 1px solid lightgray; width:100%">
        <i class="fa fa-search icon"></i>
        <input type="text" class="searchform-control" id="filter-text-box" placeholder="Search" (input)="onFilterTextBoxChanged()" [(ngModel)]="value" />
      </div>
        <div class="col-xs-12" style="padding: 0; height: 93%; border: 1px solid rgb(119,192,68);">
          <ag-grid-angular *ngIf="(isAccountViewOpen && !isStatusidopen)" style="height:100%;" class="ag-theme-balham"
                           [gridOptions]="gridOptions"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="applicationComponent.defaultGridColDef"
                           [rowData]="allCustomers"
                           [context]="context"
                           [sideBar]="sideBar"
                           [cacheQuickFilter]="true"
                           [frameworkComponents]="frameworkComponents"
                           (rowSelected)="rowClickAgGrid($event)"
                           (rowDoubleClicked)="cellDoubleClicked($event)"
                           (columnVisible)="columnVisible($event)"
                           (dragStopped)="dragStopped($event)"
                           (columnResized)="columnResized($event)"
                           (selectionChanged)="onSelectionChanged($event)"
                           (gridReady)="onAgGridReady($event)">
          </ag-grid-angular>
          <div *ngIf="isStatusidopen">
            <div class="col-xs-12" style="padding: 0px;">
              <div class="col-xs-12" style="height: 15px; margin-top: 0.3%; background-color: rgb(186,220,153)">
              </div>
              <div class="col-xs-12" style="height: 20px;margin-top:0.3%;padding: 1%">
                <img (click)="isStatusidopen=false;" src="../../../assets/img/Black x.png" style="float: right;height:20px" />
              </div>
            </div>
            <status-ids [accOrContId]="accountid" [accesstype]="'Account'" title="Status ID"></status-ids>
          </div>
        </div>
      </div>
      <div *ngIf="(applicationComponent.googlemapshow)" [ngClass]="(!isGooglemapExpandOpen)? 'col-xs-7' : 'col-xs-12'"  style="padding:0px;height:100%" [style.width.%]="isGooglemapExpandOpen?'100':isShowAccountInfo?'66.5':'58.3'">
        <div class="col-xs-12" *ngIf="isGooglemapExpandOpen" style="padding:0;height:4%">
          <span (click)="expandaccountView('Expand')"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
        </div>
        <addressmap [AccConIds]="AccConIds" [accesstype]="'Accounts'" (outPropsEvent)="AccountIdsReceived($event)"></addressmap>
      </div>

      <div *ngIf="!isAccountViewOpen && !isGooglemapExpandOpen" class="col-xs-4" style="padding:0;height:100%;">
        <div class="col-xs-12" style="padding:0;height:4%">
          <span (click)="expandaccountView('Expand')"> <img src="../../../assets/img/Open Quickview Grid Panel.png" style="float: left;height:25px" /></span>
        </div>
        <div class="col-xs-12 scroll-c" style="padding:0;height:100%">

          <app-new-notes style="height:100%"
                         [selectedTabName]="'1'"
                         [conid]="this.accountid"
                         [typ]="'Account'"
                         [ViewType]="'Block'"
                         [isModal]="false">
          </app-new-notes>

        </div>
      </div>

      <div *ngIf="isShowAccountInfo && !applicationComponent.googlemapshow && PageFrom!='dashboard'" class="col-xs-8" style="padding:0px 0px 0px 1%;height:100%;">
        <account-contact [type]="type" [contactId]="'show'+accountid" [tab]="'QuickView'" (sendMsgEvent)="EventReceived($event)"></account-contact>
      </div>
    </div>

  </div>
  <input type="text" id="accotIdNotes" style="display:none;" />
  <input type="text" id="accotTypeNotes" style="display:none;" />

  <div *ngIf="NotesDec" class="detailsDialog" id="showDetailsDialog">
    <textarea *ngIf="isShowDetailsChecked" id="txtUserFullName" readonly rows="3" name="userInfo" [(ngModel)]="userInfo" style="width:100%; border: none;"></textarea>
    <div class="col-xs-12" style="padding:0;">
      <button (click)="closeDetailsDialog()" aria-label="Close" class="dialog__close-btn" id="closeDetailsDialog" style="float:left;position:unset; "><i class="fa fa-window-close-o"></i></button>
    </div>

    <div class="box" style="height:98%;border:none">
      <span *ngIf="showEditNoteBtn" id="btnEditNote" (click)="editNote()" class="editnote">Edit</span>
      <span *ngIf="showEditNoteBtn" (click)="saveEditedNote()" class="save">Save</span>
      <div id="txtLongNoteDesc" class="text">
      </div>
    </div>

  </div>
</div>
<div *ngIf="MapItEventListner()"></div>

<app-dialog [(visible)]="IsEditAccount" *ngIf="IsEditAccount" id="addEditAccount" [closable]="false" [OutSideClick]="false">
  <app-add-edit-account [accountID]="this.accountid" [type]="'Account'" (sendMsgEvent)="ReceiveEvent($event)"></app-add-edit-account>
</app-dialog>

<app-dialog *ngIf="notesshowDialog && conid>0" [(visible)]="notesshowDialog" style="height:100%" [CloseButtonColor]="'black'">
  <div class="col-xs-12" style="height: 2%; padding: 0%; background-color: rgb(186,220,153)"></div>
    <div class="col-xs-12" style="height:5%;border-bottom:1px solid gray;">
      <label style="margin-top:0.5%">NOTES / ACCOUNT DETAILS</label>
    </div>
    <div class="col-xs-12" style="height:92%;padding:0;margin-top:0.5%">

      <app-new-notes style="height:100%"
                     [selectedTabName]="'Display All'"
                     [conid]="conid"
                     [typ]="'Account'"
                     [isModal]="false">
      </app-new-notes>

    </div>


</app-dialog>

<app-dialog [(visible)]="multiplelocationshowDialog" style="height:100%">
  <div class="col-xs-12 provider" style="text-align:left;">
    <div class="col-xs-4" style="text-align:left">
      <div class="providerLabel">{{account.address1}}&nbsp;</div>
      <div>{{account.address2}}</div>
      <div>
        <span>
          {{account.city}} , {{account.state}}. {{account.zip | slice:0:5}}<span *ngIf="account.zip && account.zip.length > 5">-{{account.zip | slice:5:20}}</span>
          }}
        </span>
      </div>
    </div>
    <div class="col-xs-4">
      <div><b class="providerLabel" style="font-size:17px;">{{account.accountname}}</b></div>
    </div>
    <div class="col-xs-4" style="text-align:right">

      <div style="margin-top: 6%;"><button class="pull-right" (click)="NewLocation()">Add New Location</button></div>
    </div>
  </div>
  <div class="col-xs-12" style="width: 1000px;">

  </div>
  <div class="row" id="alllocations" style="margin-top: 2%;margin-bottom: 2%;padding-left: 1%;padding-right: 1%;padding-top:1%;max-height:500px;" [hidden]="alllocations">
    <div *ngIf="accountlocationlist == 0">
      <span class="search_no_results">
        No data found
      </span>
    </div>
    <div *ngIf="accountlocationlist !=0">
      <div id="location{{localtion.id}}" class="col-xs-4" *ngFor="let localtion of accountlocationlist" style="padding:0;">
        <div style="padding: 3%;">
          <div class="col-xs-12" style="background: darkkhaki;">
            <h5 class="pull-left">Location</h5><span class="pull-right fa fa-times" (click)="Close(localtion.id)" style="color:green;cursor: pointer;margin-top: 3%;"></span>
          </div>
          <div class="col-xs-12" style="border-left: 1px solid #ddd;border-right: 1px solid #ddd;">
            Address1:{{localtion.address1}}
          </div>
          <div class="col-xs-12" style="border-left: 1px solid #ddd;border-right: 1px solid #ddd;">
            Address2:{{localtion.address2}}
          </div>
          <div class="col-xs-12" style="border-left: 1px solid #ddd;border-right: 1px solid #ddd;">
            city/state/zip:{{localtion.city}},{{localtion.state}} {{localtion.zip}}
          </div>
          <div class="col-xs-12" style="border-left: 1px solid #ddd;border-right: 1px solid #ddd;">
            phone:{{localtion.phone}}
          </div>
          <div class="col-xs-12" style="border-left: 1px solid #ddd;border-right: 1px solid #ddd;border-bottom:1px solid #ddd; border-radius:3px">
            fax:{{localtion.fax}}
          </div>
        </div>
      </div>
    </div>

  </div>

  <div id="addnewlocation" [hidden]="addnewlocation">
    <form [formGroup]="heroForm">
      <div formArrayName="secretLairs" style="height: 269px; float: left;width: 100%;overflow: auto">
        <div id="location" *ngFor="let address of secretLairs.controls; let i=index" [formGroupName]="i">
          <div id="{{i + 1+1}}" class="col-xs-12" style="text-align:center">
            <h4 style="color:darkred">Location #{{i + 1+1}} <button style="margin-left: 393px" (click)="removeloction(i)" type="button">Close</button></h4>

            <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
              <div class="col-xs-3">
                <label>Address1 </label>
              </div>
              <div class="col-xs-9">
                <input type="text" class="form-control" formControlName="address1" placeholder="Enter Address" />
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
              <div class="col-xs-3">
                <label>Address2</label>
              </div>
              <div class="col-xs-9">
                <input type="text" class="form-control" formControlName="address2" placeholder="Enter Address" />
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
              <div class="col-xs-3">
                <label>City/State/Zip *</label>
              </div>
              <div class="col-xs-9">
                <div class="col-xs-6 removeCompletePadding">
                  <input type="text" class="form-control" formControlName="city" placeholder="Enter City" />
                </div>
                <div class="col-xs-3" style="padding-right:0">
                  <input type="text" class="form-control" formControlName="state" placeholder="Enter State" />
                </div>
                <div class="col-xs-3" style="padding-right:0">
                  <input type="text" class="form-control" formControlName="zip" placeholder="Enter Zip" [textMask]="{mask: zipmask}" />
                </div>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;">
              <div class="col-xs-3">
                <label>Phone/Fax#</label>
              </div>
              <div class="col-xs-9">
                <div class="col-xs-6 removeCompletePadding">
                  <input type="text" class="form-control" formControlName="phone" placeholder="Enter Phone#" [textMask]="{mask: phonemask}" style="width:245px" />
                </div>
                <div class="col-xs-6" style="padding-right:0">
                  <input type="text" class="form-control" formControlName="fax" placeholder="Enter Fax#" [textMask]="{mask: phonemask}" />
                </div>
              </div>
            </div>
            <div class="col-xs-12 removeCompletePadding" style="margin-top: 1%;border-bottom:1px solid #000">
              <div class="col-xs-3">
                <label>Alter Phone#</label>
              </div>
              <div class="col-xs-9">
                <div class="col-xs-6 removeCompletePadding">
                  <input type="text" class="form-control" formControlName="alterphone1" placeholder="Enter Alternate 1 Phone#" [textMask]="{mask: phonemask}" />
                </div>
                <div class="col-xs-6" style="padding-right:0">
                  <input type="text" class="form-control" formControlName="alterphone2" placeholder="Enter Alternate 2 Phone#" [textMask]="{mask: phonemask}" />
                </div>
              </div>
            </div>
            <br>
            <!-- End of the repeated address template -->
          </div>
        </div>
        <button (click)="addLair()" type="button">Add Location</button>
      </div>

    </form>
    <div class="col-xs-12" style="padding: 0;margin-top: 1%;">
      <div class="col-xs-2" style="padding: 0;">
        <button class="btn btn-default" (click)="Cancel()" style="width: 100%;">Cancel</button>
      </div>
      <div class="col-xs-10" style="padding: 0;">
        <button type="submit" class="btn provider" (click)="SaveLocation()" style="width: 100%;">Save</button>
      </div>

    </div>
  </div>

</app-dialog>

<app-dialog [(visible)]="showDeleteDialog" *ngIf="showDeleteDialog" id="showDeleteDialog">
  <div class="col-xs-12" style="height: 100%; width: 100%;padding:0;">
    <div *ngIf="(isopenopr)" class="col-xs-12" style="font-size:18px;padding: 0%;background:white;height:100%">
      <div class="col-xs-5" style="padding:6% 2% 2% 2%;height:100%">
        <div class="col-xs-12" style="padding:0;height:71%;font-size:15px">
          <div class="col-xs-12 " style="padding: 0 ;">
            <b class="providerLabel" style=" font-family: Calibri; color: #00b3f7d9; font-size: 13pt">{{account.accountname}}</b>
          </div>
          <div class="col-xs-12" style="padding: 0;">{{account.address1}}</div>
          <div *ngIf="account.address2 !=null && account.address2 != ''" class="col-xs-12" style="padding: 0;">{{account.address2}},</div>
          <div class="col-xs-12" style="padding: 0;">
            <span *ngIf="account.city">{{account.city}}</span>
            <span *ngIf="account.state">,&nbsp;{{account.state | uppercase}}.</span>
            <span *ngIf="account.ProvidenceIsland">{{account.ProvidenceIsland}}</span>
            <span *ngIf="account.zip">&nbsp;{{account.zip | slice:0:5}}<span *ngIf="account.zip && account.zip.length > 5">-</span>{{account.zip | slice:5:20}}</span>
            <span *ngIf="account.country">,&nbsp;{{account.country}}</span>
          </div>
          <div class="col-xs-12" style="padding: 0;">
            <span *ngIf="account.phone!=null && account.phone!=''">Phone : {{account.phone}}</span>
            <span *ngIf="(account.phone!=null && account.phone!='') && (account.fax!=null && account.fax!='')">, </span>
            <span *ngIf="account.fax!=null && account.fax !=''">Fax: {{account.fax}}</span>
          </div>
          <div class="col-xs-12" style="padding: 0;">
            <div style="padding:0%">
              <span *ngIf="account.multiplelocations === 0 || account.multiplelocations === null ">Multiple Locations:&nbsp;<b>No </b></span>
              <span *ngIf="account.multiplelocations === 1">Multiple Locations:&nbsp;<b>Yes</b></span>
            </div>
            <div style="padding:0%">
              <span>

                <a href="http:\\{{account.website}}" target="_blank">{{account.website}}</a>
              </span>

            </div>
          </div>
          <div class="col-xs-11" style="padding: 0;">
            <label> Owner :  </label><label style="margin-left: 5px;">{{account.owner}}</label>
          </div>
        </div>
      </div>
      <div class="col-xs-7" style="padding:0;height:100%">
        <div class="col-xs-12" style="padding:1% 1% 1% 6%;height:15%;"><label style="color:red;font-size:25px;padding:0;">Account Alert</label></div>
        <div class="col-xs-12" style="padding: 0 8% 0 6%; height: 84%;">
          <label style="font-size:15px;">
            The system has detected one or more of the following items with an Open Status : <br />

          </label><br />
          <div class="col-xs-12" style="padding:0;font-size:15px;">
            <ul style="padding:0 0 0 5%">
              <li><label>Open Leads <span [style.color]="(AccountTasksCounts.TotalLeads > 0)? 'red':'black'">({{this.AccountTasksCounts.TotalLeads}})</span></label><br /></li>
              <li><label>Open Opportunities<span [style.color]="(AccountTasksCounts.TotalOpportunities > 0)? 'red':'black'">({{this.AccountTasksCounts.TotalOpportunities}})</span></label> <br /></li>
              <li><label>Open Task <span [style.color]="(AccountTasksCounts.TotalTasks > 0)? 'red':'black'">({{this.AccountTasksCounts.TotalTasks}})</span></label></li>
              <li><label>Open Tickets <span [style.color]="(AccountTasksCounts.TotalTickets > 0)? 'red':'black'">({{this.AccountTasksCounts.TotalTickets}})</span></label></li>
              <li><label>Pended Proposals<span [style.color]="(AccountTasksCounts.TotalPendProposals > 0)? 'red':'black'">({{this.AccountTasksCounts.TotalPendProposals}})</span></label></li>

            </ul>

          </div>
          <br /><br />
          <label style="font-size:15px;margin-top:1%">
            These Items Must be Completed/Closed before the Account can be Deleted.
          </label><br />

        </div>
      </div>
    </div>
    <div *ngIf="(!isopenopr)" class="col-xs-12" style="font-size:18px;background:white;height:100%;padding:1% 4% 0% 12%">
      <div class="col-xs-12" style="padding:5%">
        <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
        <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
          <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">{{title}}</div><br />
           <div class="col-xs-12" style="padding: 0; padding-top: 3%;">{{subtitle}}</div>
        </div>
        <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
          <div class="col-xs-5" style="padding: 0;">
          </div>
          <div class="col-xs-7" style="padding:0;">
            <div class="col-xs-6" style="padding:0">
              <button *ngIf="isdelete" class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                      (click)="DeactivateAccountstatus(selectedAccountid);">
                OK
              </button>
              <button *ngIf="!isdelete" class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%" (click)="deletedialogclose()">
                OK
              </button>
            </div>           
            <div class="col-xs-6" style="padding:0">
              <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite; padding-left: 5%; color: #2ed1d1 "
               (click)="deletedialogclose()">Cancel</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</app-dialog>



<app-dialog [(visible)]="isSharing" *ngIf="isSharing" id="isSharingDialog" [closable]="false">
  <ownershipsharing [AccId]="accountid" [PageType]="'QuickView'" (sendMsgEvent)="onCloseEventReceived($event)"></ownershipsharing>
</app-dialog>
