export class UserMessages {
  public userMessageID: number;
  public MessageName: string;
  public MessageTypeId: number;
  public DisplayDate: string;
  public MessageDescription: string;
  public MessageToUsers: string;
  public readusers: string;
  public CreatedDate: Date;
  public CreatedBy: number;
  public MessageType: string
  public deletedStatus: number;

  public MessageToUserCount: number;
  public readuserCount: number;
  public RemainingCount: number;
}
