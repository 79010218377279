<div class="container" style="padding: 0;height:100%">

  <div class="col-xs-12" style="padding:0;height:100%;">
    <div class="col-xs-12" style="padding:0.3% 0 0.3% 0;height:8.5%" id="actiondivid">
      <div class="col-xs-11" style="padding:0;">
        <userdataheader [parentComp]="'Forecasting'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
      </div>

    </div>

    <div class="col-xs-12" style="padding:0;margin-right: 0.25%;height:83.5% ">
      <div class="col-xs-12" (click)="CommissionEstimator(true)" style="padding: 0px; margin-bottom: 4px;">
        <img src="../../../assets/img/Comm Calculator Icon.png" style="height:20px;width:2.0%;float:right" />
      </div>
      <div class="col-xs-6" style="padding:0;height:99%;" *ngIf="!isexpand">

        <div class="col-xs-6" style="padding: 0% 1% 1% 0%; height: 17% ">
          <div class="col-xs-12 boxBorder boxBorderHighlight" id="FSMonth" (click)="BoxClick('FSMonth','Monthly Sales Forecast','FSSubMonth')">
            <div class="col-xs-12 subbackground FactiveButton" id="FSSubMonth"><label>Monthly Sales Forecast</label></div>
            <div class="col-xs-12" style="padding: 0 0 0 1%;height:50%">
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%"><label>{{(this.ForeCastBoxData.ForeCastSalesMonth)? (this.ForeCastBoxData.ForeCastSalesMonth | currency) : 0 | currency}}</label></div>
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%">

                <input type="month" style="font-size:12.2px;font-family:none; border:none !important;margin-left:0%;width:110%;text-align:right;float:right;" id="FSMonthCalendar" value="{{CurrentDate}}" (change)="BoxMonthChange($event.target.value,'Monthly Sales Forecast')" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-6" style="padding:0% 1% 1% 1%; height: 17% ">
          <div class="col-xs-12 boxBorder" id="FSYear" (click)="BoxClick('FSYear','Annual Sales Forecast','FSSubYear')">
            <div class="col-xs-12 subbackground" id="FSSubYear"><label>Annual Sales Forecast</label></div>
            <div class="col-xs-12" style="padding: 0 0 0 1%;height:50%">
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%"><label>{{(this.ForeCastBoxData.ForeCastSalesYear)? (this.ForeCastBoxData.ForeCastSalesYear | currency) : 0 | currency}}</label></div>
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%">
                <select class="form-control CustomSelectDropDownClass" [(ngModel)]="ForecastSalesECDYear" (change)="BoxMonthChange($event.target.value,'Annual Sales Forecast')" id="FSYearCalendar" style=" height: 23px; padding: 0; margin: 1%; ">
                  <option *ngFor="let y of years" [selected]="yy === y ">{{y}}</option>
                </select>
              </div>
            </div>
          </div>

        </div>

        <div class="col-xs-6" style="padding: 1% 1% 1% 0%; height: 17% ">
          <div class="col-xs-12 boxBorder" id="FRRMonth" (click)="BoxClick('FRRMonth','Monthly RR Forecast','FRRSubMonth')">
            <div class="col-xs-12 subbackground" id="FRRSubMonth"><label>Monthly RR Forecast</label></div>
            <div class="col-xs-12" style="padding: 0 0 0 1%;height:50%">
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%"><label>{{(this.ForeCastBoxData.ForeCastRRMonth)? (this.ForeCastBoxData.ForeCastRRMonth | currency) : 0 | currency}}</label></div>
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%">

                <input type="month" style="font-size:12.2px;font-family:none; border:none !important;margin-left:0%;width:110%;text-align:right;float:right;" id="FRRMonthCalendar" value="{{CurrentDate}}" (change)="BoxMonthChange($event.target.value,'Monthly RR Forecast')" />
              </div>
            </div>
          </div>

        </div>

        <div class="col-xs-6" style="padding: 1%; height: 17% ">
          <div class="col-xs-12 boxBorder" id="FRRYear" (click)="BoxClick('FRRYear','Annual RR Forecast','FRRSubYear')">
            <div class="col-xs-12 subbackground" id="FRRSubYear"><label>Annual RR Forecast </label></div>
            <div class="col-xs-12" style="padding: 0 0 0 1%;height:50%">
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%"><label>{{(this.ForeCastBoxData.ForecastRRYear)? (this.ForeCastBoxData.ForecastRRYear | currency) : 0 | currency}}</label></div>
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%">
                <select class="form-control CustomSelectDropDownClass" [(ngModel)]="ForecastRRECDYear" (change)="BoxMonthChange($event.target.value,'Annual RR Forecast')" id="FRRYearCalendar" style=" height: 23px; padding: 0; margin: 1%; ">
                  <option *ngFor="let y of years" [selected]="yy === y ">{{y}}</option>
                </select>

              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-6" style="padding: 1% 1% 1% 0%; height: 17% ">
          <div class="col-xs-12 boxBorder" id="SWMonth" (click)="BoxClick('SWMonth','Monthly Sales-Won','SWSubMonth')">
            <div class="col-xs-12 subbackground" id="SWSubMonth"><label>Monthly Sales-Won</label></div>
            <div class="col-xs-12" style="padding: 0 0 0 1%;height:50%">
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%"><label>{{(this.ForeCastBoxData.SalesWonMonth)? (this.ForeCastBoxData.SalesWonMonth | currency) : 0 | currency}}</label></div>
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%">

                <input type="month" style="font-size:12.2px;font-family:none; border:none !important;margin-left:0%;width:110%;text-align:right;float:right;" id="SWMonthCalendar" value="{{CurrentDate}}" (change)="BoxMonthChange($event.target.value,'Monthly Sales-Won')" />
              </div>
            </div>
          </div>

        </div>

        <div class="col-xs-6" style="padding: 1%; height: 17% ">
          <div class="col-xs-12 boxBorder" id="SWYear" (click)="BoxClick('SWYear','Annual Sales Won','SWSubYear')">
            <div class="col-xs-12 subbackground" id="SWSubYear"><label>Annual Sales Won</label></div>
            <div class="col-xs-12" style="padding: 0 0 0 1%;height:50%">
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%"><label>{{(this.ForeCastBoxData.SalesWonYear)? (this.ForeCastBoxData.SalesWonYear | currency) : 0  | currency}}</label></div>
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%">
                <select class="form-control CustomSelectDropDownClass" [(ngModel)]="SalesWonYear" (change)="BoxMonthChange($event.target.value,'Annual Sales Won')" style=" height: 23px; padding: 0; margin: 1%; ">
                  <option *ngFor="let y of years" [selected]="yy === y ">{{y}}</option>
                </select>

              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-6" style="padding: 1% 1% 1% 0%; height: 17% ">
          <div class="col-xs-12 boxBorder" id="RRWMonth" (click)="BoxClick('RRWMonth','Monthly RR-Won','RRWSubMonth')">
            <div class="col-xs-12 subbackground" id="RRWSubMonth"><label>Monthly RR-Won</label></div>
            <div class="col-xs-12" style="padding: 0 0 0 1%;height:50%">
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%"><label>{{(this.ForeCastBoxData.RRWonMonth)? (this.ForeCastBoxData.RRWonMonth | currency) : 0  | currency}}</label></div>
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%">

                <input type="month" style="font-size:12.2px;font-family:none; border:none !important;margin-left:0%;width:110%;text-align:right;float:right;" id="RRWMonthCalendar" value="{{CurrentDate}}" (change)="BoxMonthChange($event.target.value,'Monthly RR-Won')" />
              </div>
            </div>
          </div>

        </div>

        <div class="col-xs-6" style="padding: 1%; height: 17% ">
          <div class="col-xs-12 boxBorder" id="RRWYear" (click)="BoxClick('RRWYear','Annual RR Won','RRWSubYear')">
            <div class="col-xs-12 subbackground" id="RRWSubYear"><label>Annual RR Won</label></div>
            <div class="col-xs-12" style="padding: 0 0 0 1%;height:50%">
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%"><label>{{(this.ForeCastBoxData.RRWonYear)? (this.ForeCastBoxData.RRWonYear | currency) : 0 | currency}}</label></div>
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%">
                <select [(ngModel)]="RRWonYear" class="form-control CustomSelectDropDownClass" (change)="BoxMonthChange($event.target.value,'Annual RR Won')" style=" height: 23px; padding: 0; margin: 1%; ">
                  <option *ngFor="let y of years" [selected]="yy === y ">{{y}}</option>
                </select>

              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-6" style="padding: 1% 1% 1% 0%; height: 17% ">
          <div class="col-xs-12 boxBorder" id="SLMonth" (click)="BoxClick('SLMonth','Monthly Sales-Lost','SLSubMonth')">
            <div class="col-xs-12 subbackground" id="SLSubMonth"><label>Monthly Sales-Lost</label></div>
            <div class="col-xs-12" style="padding: 0 0 0 1%;height:50%">
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%"><label>{{(this.ForeCastBoxData.SalesLostMonth)? (this.ForeCastBoxData.SalesLostMonth | currency) : 0  | currency}}</label></div>
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%">

                <input type="month" style="font-size:12.2px;font-family:none; border:none !important;margin-left:0%;width:110%;text-align:right;float:right;" id="SLMonthCalendar" value="{{CurrentDate}}" (change)="BoxMonthChange($event.target.value,'Monthly Sales-Lost')" />
              </div>
            </div>
          </div>

        </div>

        <div class="col-xs-6" style="padding: 1%; height: 17% ">
          <div class="col-xs-12 boxBorder" id="SLYear" (click)="BoxClick('SLYear','Annual Sales Lost','SLSubYear')">
            <div class="col-xs-12 subbackground" id="SLSubYear"><label>Annual Sales Lost</label></div>
            <div class="col-xs-12" style="padding: 0 0 0 1%;height:50%">
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%"><label>{{(this.ForeCastBoxData.SalesLostYear)? (this.ForeCastBoxData.SalesLostYear | currency) : 0 | currency}}</label></div>
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%">
                <select [(ngModel)]="SalesLostYear" class="form-control CustomSelectDropDownClass" (change)="BoxMonthChange($event.target.value,'Annual Sales Lost')" style=" height: 23px; padding: 0; margin: 1%; ">
                  <option *ngFor="let y of years" [selected]="yy === y ">{{y}}</option>
                </select>

              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-6" style="padding: 1% 1% 1% 0%; height: 17% ">
          <div class="col-xs-12 boxBorder" id="RRLMonth" (click)="BoxClick('RRLMonth','Monthly RR-Lost','RRLSubMonth')">
            <div class="col-xs-12 subbackground" id="RRLSubMonth"><label>Monthly RR-Lost</label></div>
            <div class="col-xs-12" style="padding: 0 0 0 1%;height:50%">
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%"><label>{{(this.ForeCastBoxData.RRLostMonth)? (this.ForeCastBoxData.RRLostMonth | currency) : 0  | currency}}</label></div>
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%">

                <input type="month" style="font-size:12.2px;font-family:none; border:none !important;margin-left:0%;width:110%;text-align:right;float:right;" id="RRLMonthCalendar" value="{{CurrentDate}}" (change)="BoxMonthChange($event.target.value,'Monthly RR-Lost')" />
              </div>
            </div>
          </div>

        </div>

        <div class="col-xs-6" style="padding:1%;height:17%">
          <div class="col-xs-12 boxBorder" id="RRLYear" (click)="BoxClick('RRLYear','Annual RR Lost','RRLSubYear')">
            <div class="col-xs-12 subbackground" id="RRLSubYear"><label>Annual RR Lost</label></div>
            <div class="col-xs-12" style="padding: 0 0 0 1%;height:50%">
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%"><label>{{(this.ForeCastBoxData.RRLostYear)? (this.ForeCastBoxData.RRLostYear | currency) : 0 | currency}}</label></div>
              <div class="col-xs-6" style="padding:1% 0 0 0;height:100%">
                <select class="form-control CustomSelectDropDownClass" [(ngModel)]="RRLostYear" (change)="BoxMonthChange($event.target.value,'Annual RR Lost')" style=" height: 23px; padding: 0; margin: 1%; ">
                  <option *ngFor="let y of years" [selected]="yy === y ">{{y}}</option>
                </select>

              </div>
            </div>
          </div>
        </div>

      </div>
      <div [ngClass]="isexpand ? 'col-xs-12' :'col-xs-6'" style="padding: 0; height: 100%; border: 2px solid rgb(186,219,152) !important;">
        <div class="col-xs-11" style="height: 5%; text-transform: uppercase; background-color: rgb(186,219,152); color: #2A3740 ">
          <label style="padding-top: 0.5%;">RESULTS- {{SelectedBoxTitle}}</label>
        </div>
        <div class="col-xs-1" style="height: 5%; padding-top: 0.5%; text-transform: uppercase; background-color: rgb(186,219,152); color: #2A3740">
          <img src="../../../assets/img/Black Expand View.png" style="height: 60%; float: right" *ngIf="!isexpand" (click)="Expand(true);" />
          <img src="../../../assets/img/Black x.png" style="height: 80%;float: right" *ngIf="isexpand" (click)="Expand(false);" />
        </div>
        <ag-grid-angular style="width: 100%; height:90%" id="gridheight" class="ag-theme-balham"
                         [gridOptions]="gridOptions"
                         [columnDefs]="columnDefs"
                         [rowData]="Allopportunities"
                         [context]="context"
                         [sideBar]="sideBar"
                         (rowSelected)="rowClickAgGrid($event)"
                         (rowDoubleClicked)="rowDoubleClickAgGrid($event)"
                         (dragStopped)="dragStopped($event)"
                         (columnResized)="columnResized($event)"
                         (columnVisible)="columnVisible($event)"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
    <app-dialog [(visible)]="isDisplayCommissionEstimator" (visibleChange)="onCloseDialogEventReceived($event)" [CloseButtonColor]="'white'">
      <div class="col-xs-12" style="padding:0;height:100%">
        <div class="col-xs-12 noprovider" style="height: 7%; text-align: center; background: rgb(40,62,74) !important; color: white !important; font-size: 20px; padding-top: 1%">
          Commission Estimator
        </div>
        <div class="col-xs-12" style="height:93%;border:1px solid gray;padding:0;font-size:16px;">
          <div class="col-xs-12" style="padding:3%;height:100%">
            <div class="col-xs-12" style="padding:0;height:7%;"><label>SALES</label></div>
            <div class="col-xs-12" style="padding:0;height:23%">
              <div class="col-xs-12" style="padding:0;height:30%">
                <div class="col-xs-5" style="padding:0;height:100%">Sales Total :</div>
                <div class="col-xs-5" style="padding:0 5% 0 5%;height:100%;">
                  $ {{SalesCommission}}
                </div>
              </div>

              <div class="col-xs-12" style="padding:0;height:30%">
                <div class="col-xs-5" style="padding:0;height:100%">Sales Commission (%) : </div>
                <div class="col-xs-5" style="padding:0 5% 0 0;height:100%;text-align:right">
                  <div style=" width: 85%; border: 1px solid black !important; border: 1px solid #ddd !important; float: right; padding: 0; ">
                    <input type="number" style="width: 69%; border: none" (blur)="SaleCommissionOpprtunity($event.target.value)" /> %
                  </div>
                </div>
              </div>

              <div class="col-xs-12" style="padding:0;height:30%">
                <div class="col-xs-5" style="padding:0;height:100%">Total Sales Commission :</div>
                <div class="col-xs-5" style="padding:0 5% 0 5% ;height:100%;">{{opportunities.salesCommissionOpportunity | currency }}</div>
              </div>
            </div>

            <div class="col-xs-12" style="padding:0;height:7%;"></div>
            <div class="col-xs-12" style="padding:0;height:7%;"><label>RECURRING REVENUE</label></div>
            <div class="col-xs-12" style="padding:0;height:28%">
              <div class="col-xs-12" style="padding:0;height:25%">
                <div class="col-xs-5" style="padding:0;height:100%">RR/Monthly Total :</div>
                <div class="col-xs-5" style="padding:0 5% 0 5%;height:100%;">
                  $ {{RRCommission}}

                </div>
              </div>

              <div class="col-xs-12" style="padding:0;height:25%">
                <div class="col-xs-5" style="padding:0;height:100%">RR/Sales Commission % :</div>
                <div class="col-xs-5" style="padding:0 5% 0 0;height:100%;text-align:right">
                  <div style=" width: 85%; border: 1px solid black !important; border: 1px solid #ddd !important; float: right; padding: 0; ">
                    <input type="number" style="border:none;width: 69%" (blur)="RRCommissionOpprtunity($event.target.value)" /> %
                  </div>
                </div>
              </div>

              <div class="col-xs-12" style="padding:0;height:25%">
                <div class="col-xs-5" style="padding:0;height:100%">RR/Commission (Monthly) :</div>
                <div class="col-xs-5" style="padding:0 5% 0 5%;height:100%;">{{opportunities.RRCommisssionOpportunityMonthly | currency }}</div>
              </div>

              <div class="col-xs-12" style="padding:0;height:25%">
                <div class="col-xs-5" style="padding:0;height:100%">RR/Commission (Annually) :</div>
                <div class="col-xs-5" style="padding:0 5% 0 5%;height:100%;"> {{opportunities.RRCommisssionOpportunityYearly  }}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </app-dialog>
  </div>

  
</div>

