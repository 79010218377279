<div class="container" style="padding:0px;height:100%;margin:0;">
  <div class="col-xs-12" style="padding: 0;height:100%">
    <div class="col-xs-12" style="padding-left: 0px;padding-right: 0%;height:99%" *ngIf="(type=='Contacts' ||type=='Contact')">
      <form #contactform="ngForm" (keydown.enter)="$event.preventDefault()" style="height:100%">
        <div id="addContactDetais" class="disableDiv" style="height:100%">
          <div class="col-xs-12" id="addContactHeader" style="height:2%;background-color:orangered">

          </div>
            <div class="col-xs-12" id="addContactHeader" style="color:black;margin-top: 0;
            height:5%">
              <h4>
                <label *ngIf="contactid == 0 ">ADD NEW CONTACT</label>
                <label *ngIf="contactid != 0 ">EDIT CONTACT</label>
                <label style="float:right">
                  <img src="../../../assets/img/Black x.png" (click)="(FormDataModified)? (DiscardFormDataConfirmationCss()):cancelContact()" style="height:22px" />
                </label>
              </h4>
            </div>
            <div class="col-xs-12 removeCompletePadding scroll-c" id="addContactBody" style="border: 1px solid #ddd;padding:2% 0 2% 0; margin-top:3px; border-radius:3px;  overflow:auto ;height:93%">
              <form [formGroup]="NewForm">
                <div *ngFor="let field of Fields">
                  <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top:1%">
                    <div class="col-xs-3" style="padding-right:0;">
                      <label>{{field.DisplayAs | titlecase}}</label>
                    </div>
                    <div class="col-xs-9">
                      <div class="col-xs-12" style="padding:0;">
                        <div class="col-xs-12" style="padding:0;" *ngIf="field.OldOrNew == 'Old' && field.ColumnName != 'ownerid' && field.ColumnName != 'photo'">
                          <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="contacts[field.ColumnName]" *ngIf="(field.DataType == 'Pick List' || field.DataType == 'System Lookup') && field.ColumnName != 'address1'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)">
                            <option value="0">Select {{field.DisplayAs}} </option>
                            <option *ngFor="let customer of field.Data" value="{{customer.id}}" style="color:black">
                              {{customer.itemName}}
                            </option>
                          </select>


                        </div>
                        <div class="col-xs-12" style="padding:0;" *ngIf="field.OldOrNew == 'New'  && field.ColumnName != 'photo'">
                          <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="contacts[field.ColumnName]" *ngIf="(field.DataType == 'Pick List' || field.DataType == 'System Lookup') && field.ColumnName != 'address1'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)">
                            <option *ngFor="let customer of field.Data" value="{{customer.id}}:{{customer.itemName}}" style="color:black">
                              {{customer.itemName}}
                            </option>
                          </select>

                        </div>
                      </div>

                      <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" *ngIf="(field.DataType == 'Pick List' || field.DataType == 'System Lookup') && field.ColumnName == 'address1' && field.ColumnName !== 'photo' " [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="AddressSelected($event.target.value);FormDataEntered($event.target.value)" [(ngModel)]="AddressSelection">
                        <option *ngFor="let customer of field.Data" value={{customer.id}} style="color:black">
                          {{customer.itemName}}
                        </option>
                      </select>

                      <input value="{{contacts[field.ColumnName]}}" [(ngModel)]="contacts[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName != 'email' && field.ColumnName != 'cell' && field.ColumnName != 'office' && field.ColumnName != 'DOB'  && field.ColumnName != 'zip' && field.ColumnName != 'homezip' && field.ColumnName != 'home')" [required]="(field.RequiredField == 'Yes' && field.ColumnName !=='city')? 'true':'false'" (keyup)="FormDataEntered($event.target)" id="{{field.ColumnName}}" />

                      <input value="{{contacts[field.ColumnName]}}" [(ngModel)]="contacts[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'email')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" (keyup)="FormDataEntered($event.target)" id="{{field.ColumnName}}" />

                      <input value="{{contacts[field.ColumnName]}}" [(ngModel)]="contacts[field.ColumnName]" [formControlName]="field.ColumnName" class="form-control decreasefieldSize" type="text" placeholder="Enter {{field.DisplayAs}}" *ngIf="(field.DataType == 'Text Box' ||  field.DataType == 'Numeric') && (field.ColumnName == 'cell' || field.ColumnName == 'office' || field.ColumnName == 'DOB'  || field.ColumnName == 'zip' || field.ColumnName == 'homezip' || field.ColumnName == 'home')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" [textMask]="(field.ColumnName == 'DOB')? {mask: dobmask}:(field.ColumnName == 'zip' || field.ColumnName == 'homezip' )? {mask: zipmask}:{mask: phonemask}" (keyup)="FormDataEntered($event.target)" id="{{field.ColumnName}}" />  <!--zipmask-->


                      <input type="date" class="form-control decreasefieldSize" [formControlName]="field.ColumnName" [(ngModel)]="contacts[field.ColumnName]" *ngIf="(field.DataType == 'Date' ||  field.DataType == 'Date/Time')" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target)" id="{{field.ColumnName}}" />


                      <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" [formControlName]="field.ColumnName" [(ngModel)]="contacts[field.ColumnName]" *ngIf="field.DataType == 'Date/YearOnly'" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)">
                        <option *ngFor="let customer of Years" value="{{customer.value}}" style="color:black">
                          {{customer.label}}
                        </option>
                      </select>

                      <div class="col-xs-12" style="padding:0;" *ngIf="field.ColumnName == 'ownerid'">
                        <select class="form-control decreasefieldSize" name="{{field.ColumnName}}" id="{{field.ColumnName}}" disabled [formControlName]="field.ColumnName" [(ngModel)]="contacts[field.ColumnName]" [required]="(field.RequiredField == 'Yes')? 'true':'false'" (change)="FormDataEntered($event.target.value)">
                          <option value="(NULL)">Select {{field.DisplayAs}} </option>
                          <option *ngFor="let customer of field.Data" value="{{customer.id}}" style="color:black">
                            {{customer.itemName}}
                          </option>
                        </select>


                      </div>

                      <div class="col-xs-12" style="padding:0;" *ngIf="field.ColumnName == 'photo'">
                        {{contacts.fileName}}
                        <input id="addProfileFiles" name="addProfileFiles" class="form-control   decreasefieldSize" [formControlName]="field.ColumnName" type="file" (change)="addFiles($event)" accept=".jpg,.jpeg" style="display:block" />

                      </div>
                    </div>

                  </div>


                </div>

                <div class="col-xs-12">

                  <div class="col-xs-3">
                  </div>
                  <div class="col-xs-9">
                    <div class="col-xs-6" style="padding: 0;">
                      <button class="btn btn-default" (click)="(FormDataModified)? (DiscardFormDataConfirmationCss()):cancelContact()" style="width: 100%; border:1px solid">Cancel</button>
                    </div>
                    <div class="col-xs-6" style="padding-right: 0;">
                      <button class="btn saveButton" id="saveContact" (click)="SaveContact()" [disabled]="!NewForm.valid" style=" width: 100%;margin-top:2%">Save</button>
                    </div>
                  </div>

                </div>
              </form>


              <!--<div class="col-xs-12">

        <div class="col-xs-3">
        </div>
        <div class="col-xs-9">
          <div class="col-xs-6" style="padding: 0;">
            <button class="btn btn-default" (click)="cancelContact()" style="width: 100%; border:1px solid">Cancel</button>
          </div>
          <div class="col-xs-6" style="padding-right: 0;">
            <button class="btn saveButton" id="saveContact" (click)="SaveContact()" [disabled]="!contactform.form.valid" style=" width: 100%;margin-top:2%">Save</button>
          </div>
        </div>

      </div>-->


            </div>

          </div>
</form>
    </div>

    <app-dialog [(visible)]="showmapWarning">
      <div style=" font-size: 19px;font-family: initial;width:100%;">
        <div style="text-align:center;font-size:21px;">    <b>Adding Account Address</b></div>
        <div style="text-align:justify;padding: 0 3% 0 2%;">
          <br />
          <span>
            The Address for this account was not selected from
            google Address list.Please select one of the two
            options below: Select <b>"  Go to Google Address "</b> and
            select google address for the account -or- select
            <b>"Save"</b> and system will save the address you have
            added to account.
          </span>
          <br /><br />
        </div>
        <div class="col-xs-12" style="margin-top: 2%;">
          <div class="col-xs-6" style="padding: 0;">
            <button class="btn noprovider" (click)="showmapWarning= !showmapWarning" style="width:85%;height: 31px;background-color:dodgerblue;border-radius:5px;">Go to Google Address</button>

          </div>
          <div class="col-xs-6" style="padding-right:0;text-align:right;">
            <button class="btn noprovider" (click)="SaveCustomeAddress()" style="width:85%;height: 31px;background-color:#badb98;border-radius:5px;">Save</button>
          </div>
        </div>
      </div>


    </app-dialog>
  </div>
</div>



<app-dialog [(visible)]="DiscardFormDataConfirmation" *ngIf="DiscardFormDataConfirmation" id="DiscardFormDataConfirmation" [closable]="false">
  <div class="col-xs-12" style="padding:5%">
    <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
    <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
      <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">Contact</div><br />
      <div class="col-xs-12" style="padding: 0; padding-top: 3%;">
        <b style="font-size:15px;" *ngIf="contactid == 0 ">Discard Saving Contact</b><br />
        <b style="font-size:15px;" *ngIf="contactid != 0 ">Changes will Not be saved</b><br />
      </div>
    </div>
    <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
      <div class="col-xs-7" style="padding: 0;">

      </div>
      <div class="col-xs-5" style="padding:0;">
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                  (click)="cancelContact()">
            OK
          </button>
        </div>
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                  (click)="DiscardFormDataConfirmation = false">
            cancel
          </button>
        </div>

      </div>
    </div>
  </div>
  
</app-dialog>
