import { Opportunities } from "./opportunities";

export class Proposals {
  public ProposalID: number;
  public OpportunityID: number;
  public ProposalIds: string;
  public TotalPrice: number;
  public MonthlyTotalPrice: number;
  public TotalDiscountPercent: number;
  public MonthlyDiscountPercent: number;
  public ContactId: number;
  public AccountId: number;
  public CreatedBy: number;
  public ownerid: number;
  public CreatedDate: Date; 
  public CreateduserName: string;
  public ProposalPDF: string;
  public ProposalPOPDF: string;
  public ProposalPDFID: string;
  public ProposalPOID: string;
  public status: string;
  public type: string;
  public DefaultDiscount: boolean;
  public NumberOfMonths: number;
  public isPended: number;
  public isdeleted: number = 0;
  public owner: string;
  public estimatedclosedate: string;
  public LeadDescription: string;
  public leadfrom: string;
  public ProposalDescription: string;
  public itemName: string;
  public opportunities: Opportunities = new Opportunities();
  public AccountName: string;
  public statusName: string;
  public ContactName: string;
}
