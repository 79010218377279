import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
//import { EventEmitter } from 'stream';
import { SubScription } from '../../models/Subscriptionmodel';
import { UserService } from '../../services/user.service';
import { ApplicationComponent } from '../application.component';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  Subscriptionform: FormGroup;
  subscriptionmodel: SubScription = new SubScription();
  showSubscription: boolean = true;
  showBilling: boolean = false;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  selectedMenuRadio: string = "tab1";
  constructor(private applicationComponent: ApplicationComponent, private userservice: UserService) { }

  ngOnInit(): void {
    this.Subscriptionform = this.getSubscriptionForm();
    this.userservice.GetSubscriptionName().then(result => {
      this.subscriptionmodel = result;
    });
  }
  showContent(val) {
    if (val == 0) {
      this.showSubscription = true;
      this.showBilling = false;
    }
    else if (val == 1) {
      this.showSubscription = false;
      this.showBilling = true;
    }
  }
  getSubscriptionForm(): FormGroup {

    return new FormGroup({      
      'BusinessName': new FormControl(this.subscriptionmodel.BusinessName, Validators.required),

    });


  }
  close() {
    this.applicationComponent.activateMainButtons('dashboards');
    this.applicationComponent.ShowConfigurationDialog = false;
    
  }
  cancelsubscription() { this.close();  }
  Savesubscription() {
    this.userservice.SaveSubscription(this.subscriptionmodel).then(result => {
   
    });
  }
}
