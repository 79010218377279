import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions, ColumnUtils, ColumnApi } from "ag-grid/main";
import { OpprtunitiesService } from '../../services/opprtunities.service';
import { AdvancedsearchTable } from '../../models/advancedsearch-table';
import { RunQuery } from '../../models/run-query';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationComponent } from '../application.component';
import { ToastrService } from 'ngx-toastr';
import { AdvancedSearch } from '../../models/advancedsearch';
import { AdvancedsearchService } from 'src/app/services/advancedsearch.service';
declare var $: any;
@Component({
  selector: 'app-advanced-table',
  templateUrl: './advanced-table.component.html',
  styleUrls: ['./advanced-table.component.css']
})
export class AdvancedTableComponent implements OnInit {
  currentuser: any;
  public globalGridOptions: GridOptions;
  public globalColumnDefs: any[];
  public queryId: number;
  public searchtype: string;
  selectedMenuRadio: string = "";
  tableData: AdvancedsearchTable[];
  isCustomer: boolean = false;
  QueryName: string;
  isFormerCustomer: boolean = false;
  showTabledata: boolean = true;
  CloseDialog: boolean = false;
  runquerylist: any = [];
  Newrunquerylist: any = [];
  Ids = [];
  slide = 0;
  query: RunQuery = new RunQuery();
  public sideBar;
  constructor(private route: ActivatedRoute, private opprtunityservice: OpprtunitiesService, private router: Router, private advsearch: AdvancedsearchService,
    private toastr: ToastrService, public applicationComponent: ApplicationComponent) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    setTimeout(function () {
      $('#tab0').addClass('green');
      $("#tab0").trigger("click");
    }, 1000);

  }

  ngOnInit() {
    let isDel: boolean = false;
    this.route.params.subscribe(params => {
      this.queryId = params['queryId'];
      this.searchtype = params['searchtype'];
      this.RunQueryData(isDel);
      if (this.queryId != 0) {
        this.GetQueryInfo(this.queryId, 'tab0');
      }
      else {
        var query = localStorage.getItem('QuickSearchQuery');
        if (query != null) {
          this.firequery(query);
        }
      }
      //this.initializeAgGrid();
   
    });
    setTimeout(function () {
      $('#tab0').addClass('green');
    }, 100);

  }
  rowDoubleClickAgGrid(rowEvent) { 
    if (this.searchtype == "Contact" || rowEvent.data.firstname!=null)
      this.router.navigateByUrl(`/application/contactsdashboard/${rowEvent.data.id}/${rowEvent.data.permission}`);
    else if (this.searchtype == "ContactsAccount" || this.searchtype == "Account" || rowEvent.data.firstname == null)
      this.router.navigateByUrl(`/application/account-contact/${rowEvent.data.permission}/show${rowEvent.data.id}`);
  }
  QuickSearchEventListener() {
    if (this.applicationComponent.QuickSearchEvenraised) {
      var query = localStorage.getItem('QuickSearchQuery');
      this.firequery(query);
      this.applicationComponent.QuickSearchEvenraised = false;
      this.advsearch.GetAllRunQueries(this.currentuser.id).then((queries) => {
        this.runquerylist = [];
        this.Newrunquerylist = [];
        if (queries[0].queryOrder == null) { this.applicationComponent.searchoffon = false; } else {
          //var list = queries[0].queryOrder.split(",");

          this.runquerylist = queries;
          //if (query != null && query != "" && isDelete == false) {
          //  this.runquerylist.push("Quick Search-0");
          //}
          this.applicationComponent.searchoffon = true;
        }
        var initValue = this.slide * 5;
        var finalVal = (initValue + 5);
        if (this.runquerylist.length < finalVal) {
          finalVal = this.runquerylist.length
        }
        for (var i = initValue; i < (finalVal); i++) {
          this.Newrunquerylist.push(this.runquerylist[i]);
        }
        this.Newrunquerylist.splice(0, 0, { id: 0, queryOrder: "Quick Search-0", delqueryId: 0, runby: 0 });
      });
      setTimeout(function () {
        $('#tab0').addClass('green');
      }, 100);
    }
    
  }
  RunQueryData(isDelete) {
    this.advsearch.GetAllRunQueries(this.currentuser.id).then((queries) => {
      
      this.runquerylist = [];
      this.Newrunquerylist = [];
      if (queries == null || queries.length <= 0) {
        this.applicationComponent.searchoffon = false;
        let selfObj = this;
        setTimeout(function () {
          selfObj.tableData = [];
          selfObj.createColumnDefs("");  
        }, 100);
       }
      else {
        if (queries[0].queryOrder == null) {
          this.applicationComponent.searchoffon = false;
          this.showTabledata = false;
          this.tableData = [];
        }
        else {
          this.runquerylist = queries;
          this.applicationComponent.searchoffon = true;
        }

        var initValue = this.slide * 5;
        var finalVal = (initValue + 5);
        if (this.runquerylist.length < finalVal) {
          finalVal = this.runquerylist.length
        }
        for (var i = initValue; i < (finalVal); i++) {
          this.Newrunquerylist.push(this.runquerylist[i]);
        }
        setTimeout(function () {
          $('#tab0').addClass('green');
          $("#tab0").trigger("click");
        }, 100);

      }
    });
    var query = localStorage.getItem('QuickSearchQuery');
    if (query != null && query != "" && isDelete == false) {
      setTimeout(function () {
        $('#tab0').addClass('green');
      }, 100);

    }
  }

  Previous() {
    this.Newrunquerylist = [];
    this.slide--;
    var initValue = this.slide * 5;
    var finalVal = (initValue + 5);
    if (this.runquerylist.length < finalVal) {
      finalVal = this.runquerylist.length
    }
    for (var i = initValue; i < (finalVal); i++) {
      this.Newrunquerylist.push(this.runquerylist[i]);
    }

  }
  next() {
    this.Newrunquerylist = [];
    this.slide++;
    var initValue = this.slide * 5;
    var finalVal = (initValue + 5);
    if (this.runquerylist.length < finalVal) {
      finalVal = this.runquerylist.length
    }
    for (var i = initValue; i < (finalVal); i++) {
      this.Newrunquerylist.push(this.runquerylist[i]);
    }
  }
  deleteQuery(queryid,val)
  {
    let isDel: boolean = false;
    if (queryid != 0) {
      isDel = true;
      this.query.runby = this.currentuser.id;
      this.query.delqueryId = queryid
      this.advsearch.saveRunQuery(this.query).then(result => {
        this.toastr.success("Query deleted successfully.", "Query", { timeOut: 600 });
        this.Newrunquerylist = [];
        this.RunQueryData(isDel);

      });
    }
   
  }

  firequery(buildquery) {
    let advcontrol = new AdvancedSearch();
    advcontrol.SearchQry = buildquery;
    this.opprtunityservice.runQuicksearchQuery(advcontrol).then(result => {
      this.tableData = result;
      this.showTabledata = true;
    });
    this.initializeAgGrid();
  }
  GetQueryInfo(queryId,tabname) {
    if (queryId != 0) {
      this.opprtunityservice.GetAdvancesSearchQueryByData(queryId).then((data) => {
        this.tableData = data;
        var res = this.runquerylist.filter(x => x.id == queryId)
        this.searchtype = res[0].searchtype;
        this.showTabledata = true;
        this.initializeAgGrid();
        setTimeout(function () {
          $('.blank').removeClass('green');
          $('#' + tabname).addClass('green');
        }, 100);
      });
      
    }
    
  }
  initializeAgGrid() {
    this.globalGridOptions = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'multiple'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.createColumnDefs("");    
    this.globalGridOptions.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
   

  }
  createColumnDefs(columnSelections) {
    let selfObj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.globalColumnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleArray[0] == "#") {
          this.globalColumnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleArray[0] == "notes") {
          this.globalColumnDefs.push({ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false, width: parseInt(fieldHeaderVisibleArray[3]) });
        }
        else if (fieldHeaderVisibleArray[0] == "customertype") {
          this.globalColumnDefs.push({
            field: 'customertype', headerName: 'Customer Type', hide: (!this.isCustomer && !this.isFormerCustomer),
            cellRenderer(params) {
              if (params.value == '0') { return "Non - Customer" } else if (params.value == '56') { return "Customer" }
              else if (params.value == '57') { return "Former Customer" }
            }, width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
        else if (fieldHeaderVisibleArray[0] == "accnumber") {
          this.globalColumnDefs.push({ field: 'accnumber', headerName: 'Acct #', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer), width: parseInt(fieldHeaderVisibleArray[3]) });
        }
        else if (fieldHeaderVisibleArray[0] == "owner") {
          this.globalColumnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true, width: parseInt(fieldHeaderVisibleArray[3]) });
        } else {
          this.globalColumnDefs.push({
            field: fieldHeaderVisibleArray[0],
            headerName: fieldHeaderVisibleArray[1],
            hide: fieldHeaderVisibleArray[2] == "true" ? false : true, width: parseInt(fieldHeaderVisibleArray[3])
          });
        }
      }
    } else {
      if (this.searchtype == 'Account') {
        this.globalColumnDefs = [
          {
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            enableTooltip: true
          },
          // { field: 'type', headerName: 'Type', hide: false },
          {
            field: 'customertype', headerName: 'Customer Type', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer),
            cellRenderer(params) {
              if (params.value == '0') { return "Non - Customer" } else if (params.value == '56') { return "Customer" } else if (params.value == '57') { return "Former Customer" }
            }
          },
          { field: 'accountname', headerName: 'Account Name', hide: false },
          { field: 'accnumber', headerName: 'Act Number', hide: false },
          { field: 'speciality', headerName: 'Specialty', hide: false },
          { field: 'address1', headerName: 'Address1', hide: false},
          { field: 'address2', headerName: 'Address2', hide: false},
          { field: 'city', headerName: 'City', hide: false},
          { field: 'state', headerName: 'State', hide: false},
          { field: 'zip', headerName: 'ZIP', hide: false},
          { field: 'ehr', headerName: 'ehr', hide: false},
          { field: 'ehrdatepurchased', headerName: 'ehrdatepurchased', hide: false },
          { field: 'pm', headerName: 'pm', hide: false },
          { field: 'pmdatepurchased', headerName: 'pmdatepurchased', hide: false},

          //{ field: 'lastname', headerName: 'Last Name', hide: this.searchtype == 'Contact' },
          //{ field: 'firstname', headerName: 'First Name', hide: this.searchtype == 'Contact' },
          //{ field: 'middlename', headerName: 'Middle Name', hide: this.searchtype == 'Contact' },
          //{ field: 'title', headerName: 'Title', hide: this.searchtype == 'Contact' },
          //{ field: 'DOB', headerName: 'DOB', hide: this.searchtype == 'Contact' },
          //{ field: 'address1', headerName: 'Address1', hide: this.searchtype == 'Contact' },
          //{ field: 'address2', headerName: 'Address2', hide: this.searchtype == 'Contact' },
          //{ field: 'city', headerName: 'City', hide: this.searchtype == 'Contact' },
          //{ field: 'state', headerName: 'State', hide: this.searchtype == 'Contact' },
          //{ field: 'cell', headerName: 'Cell', hide: this.searchtype == 'Contact' },

          //{ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },



          { field: 'contacttype', headerName: 'Contact Type', hide: true },  //true starts here
          { field: 'suffix', headerName: 'Suffix', hide: true },
          { field: 'sex', headerName: 'Sex', hide: true },
          { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
          { field: 'email', headerName: 'Email', hide: true },

          { field: 'office', headerName: 'Office', hide: true },
          { field: 'homeaddress', headerName: 'Home Address', hide: true },
          { field: 'homecity', headerName: 'Home City', hide: true },
          { field: 'homestate', headerName: 'Home State', hide: true },
          { field: 'homezip', headerName: 'Home Zip', hide: true },
          { field: 'home', headerName: 'Home', hide: true },
          { field: 'college', headerName: 'College', hide: true },
          { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
          { field: 'medschool', headerName: 'Medschool', hide: true },
          { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
          { field: 'residency', headerName: 'Residency', hide: true },
          { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
          { field: 'fellowship', headerName: 'Fellowship', hide: true },
          { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
          //   { field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true },
          { field: 'created', headerName: 'Created', hide: true }

        ];
      } else if (this.searchtype == 'Contact') {
        this.globalColumnDefs = [
          {
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            enableTooltip: true
          },
          // { field: 'type', headerName: 'Type', hide: false },
          {
            field: 'customertype', headerName: 'Customer Type', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer),
            cellRenderer(params) {
              if (params.value == '0') { return "Non - Customer" } else if (params.value == '56') { return "Customer" } else if (params.value == '57') { return "Former Customer" }
            }
          },
          //{ field: 'accountname', headerName: 'Account Name', hide: false },
          //{ field: 'accnumber', headerName: 'Act Number', hide: this.searchtype == 'Account' },
          //{ field: 'speciality', headerName: 'Specialty', hide: this.searchtype == 'Account' },
          //{ field: 'address1', headerName: 'Address1', hide: this.searchtype == 'Account' },
          //{ field: 'address2', headerName: 'Address2', hide: this.searchtype == 'Account' },
          //{ field: 'city', headerName: 'City', hide: this.searchtype == 'Account' },
          //{ field: 'state', headerName: 'State', hide: this.searchtype == 'Account' },
          //{ field: 'zip', headerName: 'ZIP', hide: this.searchtype == 'Account' },
          //{ field: 'ehr', headerName: 'ehr', hide: this.searchtype == 'Account' },
          //{ field: 'ehrdatepurchased', headerName: 'ehrdatepurchased', hide: this.searchtype == 'Account' },
          //{ field: 'pm', headerName: 'pm', hide: this.searchtype == 'Account' },
          //{ field: 'pmdatepurchased', headerName: 'pmdatepurchased', hide: this.searchtype == 'Account' },

          { field: 'lastname', headerName: 'Last Name', hide: false },
          { field: 'firstname', headerName: 'First Name', hide: false },
          { field: 'middlename', headerName: 'Middle Name', hide: false },
          { field: 'speciality', headerName: 'Specialty', hide: false },
          { field: 'title', headerName: 'Title', hide: false },
          { field: 'DOB', headerName: 'DOB', hide: false },
          { field: 'address1', headerName: 'Address1', hide: false },
          { field: 'address2', headerName: 'Address2', hide: false },
          { field: 'city', headerName: 'City', hide: false },
          { field: 'state', headerName: 'State', hide: false },
          { field: 'cell', headerName: 'Cell', hide: false },

          //{ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },



          { field: 'contacttype', headerName: 'Contact Type', hide: true },  //true starts here
          { field: 'suffix', headerName: 'Suffix', hide: true },
          { field: 'sex', headerName: 'Sex', hide: true },
          { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
          { field: 'email', headerName: 'Email', hide: true },

          { field: 'office', headerName: 'Office', hide: true },
          { field: 'homeaddress', headerName: 'Home Address', hide: true },
          { field: 'homecity', headerName: 'Home City', hide: true },
          { field: 'homestate', headerName: 'Home State', hide: true },
          { field: 'homezip', headerName: 'Home Zip', hide: true },
          { field: 'home', headerName: 'Home', hide: true },
          { field: 'college', headerName: 'College', hide: true },
          { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
          { field: 'medschool', headerName: 'Medschool', hide: true },
          { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
          { field: 'residency', headerName: 'Residency', hide: true },
          { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
          { field: 'fellowship', headerName: 'Fellowship', hide: true },
          { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
          //   { field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true },
          { field: 'created', headerName: 'Created', hide: true }

        ];
      } else {
        this.globalColumnDefs = [
          {
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            enableTooltip: true
          },
          // { field: 'type', headerName: 'Type', hide: false },
          {
            field: 'customertype', headerName: 'Customer Type', hide: (!selfObj.isCustomer && !selfObj.isFormerCustomer),
            cellRenderer(params) {
              if (params.value == '0') { return "Non - Customer" } else if (params.value == '56') { return "Customer" } else if (params.value == '57') { return "Former Customer" }
            }
          },
          { field: 'accountname', headerName: 'Account Name', hide: false },
          { field: 'lastname', headerName: 'Last Name', hide: false },
          { field: 'firstname', headerName: 'First Name', hide: false },
          { field: 'middlename', headerName: 'Middle Name', hide: false },
          { field: 'accnumber', headerName: 'Act Number', hide: false },
          { field: 'speciality', headerName: 'Specialty', hide: false },
          { field: 'title', headerName: 'Title', hide: false },
          { field: 'DOB', headerName: 'DOB', hide: false },
          { field: 'address1', headerName: 'Address1', hide: false },
          { field: 'address2', headerName: 'Address2', hide: false },
          { field: 'city', headerName: 'City', hide: false },
          { field: 'state', headerName: 'State', hide: false},
          { field: 'zip', headerName: 'ZIP', hide: false},
          { field: 'ehr', headerName: 'ehr', hide: false },
          { field: 'ehrdatepurchased', headerName: 'ehrdatepurchased', hide: false},
          { field: 'pm', headerName: 'pm', hide: false},
          { field: 'pmdatepurchased', headerName: 'pmdatepurchased', hide: false},
          
          

          //{ field: 'notes', headerName: 'Notes', cellRenderer: 'notesrenderer', hide: false },



          { field: 'contacttype', headerName: 'Contact Type', hide: true },  //true starts here
          { field: 'suffix', headerName: 'Suffix', hide: true },
          { field: 'sex', headerName: 'Sex', hide: true },
          { field: 'subspeciality', headerName: 'Subspeciality', hide: true },
          { field: 'email', headerName: 'Email', hide: true },

          { field: 'office', headerName: 'Office', hide: true },
          { field: 'homeaddress', headerName: 'Home Address', hide: true },
          { field: 'homecity', headerName: 'Home City', hide: true },
          { field: 'homestate', headerName: 'Home State', hide: true },
          { field: 'homezip', headerName: 'Home Zip', hide: true },
          { field: 'home', headerName: 'Home', hide: true },
          { field: 'college', headerName: 'College', hide: true },
          { field: 'collegegrandyear', headerName: 'C.G.year', hide: true },
          { field: 'medschool', headerName: 'Medschool', hide: true },
          { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true },
          { field: 'residency', headerName: 'Residency', hide: true },
          { field: 'residencygrandyear', headerName: 'R.G.year', hide: true },
          { field: 'fellowship', headerName: 'Fellowship', hide: true },
          { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true },
          //   { field: 'owner', headerName: 'Owner', hide: false, suppressSorting: true },
          { field: 'created', headerName: 'Created', hide: true }

        ];
      }


      
    }

  }
  GridClose() {
    this.showTabledata = false;
    this.CloseDialog = true;
    this.dialogsize();
  }
 
  rowClickAgGrid(rowEvent) {
    if ((this.Ids).indexOf(rowEvent.data.id) > -1) {
      var index = (this.Ids).indexOf(rowEvent.data.id);
      (this.Ids).splice(index, 1);
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      $('#tasklistselectedtyle').val('');
    } else {
      this.Ids.push(rowEvent.data.id)
      var string = (this.Ids).toString();
      $('#emailIds').val(string);
      if (rowEvent.data.type == "Contact") {
        $('#tasklistselectedtyle').val('Contact');
      }
      else {
        $('#tasklistselectedtyle').val('Customer');
      }
    }
  }
  dialogsize() {
    setTimeout(function () {
      $('.dialog').attr('style', ' width: 32%;padding - bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min - height: 23%;overflow: visible;height: 24%;');

    }, 4)
  }
}
