import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { CustomerService } from './../../services/customer.service';
import { Zendeskuser } from '../../models/zendeskuser';
import { GridOptions, ColumnUtils } from "ag-grid/main";
import { EditdeletecvComponent } from '../editdeletecv/editdeletecv.component';
import { ApplicationComponent } from '../application.component';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { Column } from 'ag-grid';
import { Router, ActivatedRoute } from '@angular/router';
import { TicketsComponent } from '../tickets/tickets.component';
import { IMyDpOptions } from 'mydatepicker';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Tickets } from 'src/app/models/tickets';
import { Accounts } from 'src/app/models/accounts';
import { TicketNotes } from 'src/app/models/TicketNotes';
import { Dropdown, Dropdowntype } from 'src/app/models/dropdowntype';
import { UserService } from 'src/app/services/user.service';
import { AccountsService } from 'src/app/services/accounts.service';
import { NotesComponent } from '../notes/notes.component';
import { Contacts } from '../../models/contacts';
import { Notes } from '../../models/notes';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { Viewaccount } from '../../models/viewaccount';
import { OwnershipsharingComponent } from '../ownershipsharing/ownershipsharing.component';
import { AccountDetailsComponent } from '../account-details/account-details.component';
import { AccountContactInfoComponent } from '../account-contact-info/account-contact-info.component';
import { ContactsdashboardComponent } from '../contacts/contactsdashboard/contactsdashboard.component';
import { AuditLogModel } from '../../models/auditlog';
import { UserdataheaderComponent } from '../userdataheader/userdataheader.component';
declare var $: any;
@Component({
  selector: 'app-zendeskhome',
  templateUrl: './zendeskhome.component.html',
  styleUrls: ['./zendeskhome.component.css']
})
export class ZendeskhomeComponent implements OnInit {
  @ViewChild(TicketsComponent) ticketsComp;
  @ViewChild(NotesComponent) notecomp;
  //@ViewChild(ContactsdashboardComponent) contDashComp;
  url: SafeResourceUrl;

  datePipe: DatePipe = new DatePipe("en-US");
  Userlist: Array<Tickets> = [];
  OriginalUserList: Array<Tickets> = [];
  public columnDefs: any[];
  public gridOptions: GridOptions;
  public ticketgridOptions: GridOptions;
  aggridheight: number = screen.height * 0.6333;
  public context;
  public frameworkComponents;
  ozendeskuser: Zendeskuser = new Zendeskuser();
  account: Accounts = new Accounts();
  urlImage = '';
  ticketdata: Tickets = new Tickets();
  pageSize: string = "1000";
  isTicketsInfoDivVisible: boolean = false;
  ShowDialog: boolean = false;
  currentuser: any;
  selectedItems = [];
  showOpenTicketHistory = false;
  colSelections: string;
  accountorContactid;
  SaveTicketDialog: boolean = false;
  Filter: string = "My Tickets Only";
  sortOrder: string = "None";
  displayMyTicketsOn = false;
  showTicketDataDiv = true;
  ticketDataHide = false;
  ticketTaskAssignTo = [];
  ticketUserAssignTo = [];
  statusChange = false;
  showSaveNotes = false;
  ticketNotesData: any;
  ticketNotes: TicketNotes = new TicketNotes();
  userprevilages: any;
  TicketPrivilages: any;
  ticketStatusList: Array<Dropdown> = [];
  ticketStatusListForDropDown: Array<Dropdown> = [];
  displayCC = false;
  AssignTo = "";
  CCTo = "";
  expanded: boolean = false;
  isModal: boolean;
  notesshowDialog = false;
  conid: number;
  typ: string;
  contactid = 0;
  contacts: Contacts = new Contacts();
  allnotes: Array<Notes> = [];
  timezonevalue: string;
  public gridOptionsnotes: GridOptions;
  public columnDefsnotes: any[];
  public getRowHeight;
  showEditNoteBtn: boolean = false;
  TicketDeatilsExpand: boolean = false;
  PreviousNotesExpand: boolean = false;
  shwTicketDetails: boolean = false;
  editNoteId: boolean = false; EnableSearch: boolean = false; value = "";
  notes: Notes = new Notes();
  NotesDec = false;
  DisplayrowsList: Dropdowntype[] = [];
  @ViewChild(AccountContactInfoComponent) AccConInfoComp;
  public sideBar;
  ticketslist: Array<Tickets> = [];
  duplicateticketslist: Array<Tickets> = [];
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = this.applicationComponent.startVal == undefined ? (new Date().getFullYear() - 1) + '-01-01' : this.applicationComponent.startVal; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = this.applicationComponent.startVal == undefined ? 'Start: 01-01-' + (new Date().getFullYear() - 1).toString() : 'Start:' + moment(this.applicationComponent.startVal).format("MM-DD-YYYY");
  calanderonoff: boolean = false;
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = this.applicationComponent.endVal == undefined ? new Date().getFullYear() + '-12-31' : this.applicationComponent.endVal;;  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = this.applicationComponent.endVal == undefined ? 'End: 12-31-' + new Date().getFullYear().toString() : 'End:' + moment(this.applicationComponent.endVal).format("MM-DD-YYYY");
  @Input()
  PageFrom: string = "";
  @Input()
  dashticketType: string;
  @Input()
  assignedTo: string = "";
  ticketType: string = "Open Tickets";
  ticketId: string ;
  private gridApi;
  viewaccount: Viewaccount = new Viewaccount();
  action: string;
  showAddEditAccount: boolean = false;
  showAddEditContact: boolean = false;
  isSharing: boolean = false;
  @ViewChild(OwnershipsharingComponent) ownrshipComp: OwnershipsharingComponent;
  @ViewChild(UserdataheaderComponent) headerComp: UserdataheaderComponent;
  rightMainDivHideShow: boolean = true;
  accountSharingid: number = 0;
  AddContacttableDiv: boolean = false;
  @ViewChild(AccountDetailsComponent) accdetailcmp;
  public elementRef;
  public showTicketInfo: boolean = false;
  public ticketagingId: number = 0;
  @Input()
  startDateVal;
  @Input()
  endDateVal;
  public TotalTicketCount: number = 0;
  public Below30Days: number = 0;
  public Betwn31_60Days: number = 0;
  public Betwn61_90Days: number = 0;
  public Betwn91_120Days: number = 0;
  public Above120Days: number = 0;
  constructor(private route: ActivatedRoute, public applicationComponent: ApplicationComponent, private opprtunityservice: OpprtunitiesService, private router: Router,
    private userservice: UserService, private customerService: CustomerService, private toastr: ToastrService, sanitizer: DomSanitizer, private accountService: AccountsService,
    myElement: ElementRef  ) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.elementRef = myElement;
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    } else {
      this.applicationComponent.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username, "category": this.currentuser.userrole }
      ];
      localStorage.setItem('SelectedUserIds', JSON.stringify(this.applicationComponent.selectedItems));
      $("#userids").val(this.currentuser.id);
    /*  this.applicationComponent.ExpandSelectedMenu('tickets');*/
    }
  }
  ngOnInit() {
    this.initializeAgGrid();
    this.route.params.subscribe(params => {
      this.accountorContactid = params['accountorContactid'];
      this.ticketType = params['type'];
      this.ticketId = params['ticketId'];
      this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
        this.DisplayrowsList = Rows;
      });
      if (this.applicationComponent.selectedusers != '' && this.applicationComponent.selectedusers != null && this.applicationComponent.selectedusers != undefined) {
        this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
          Owner.forEach(Owner => {
          }); this.selectedItems = Owner;
        })
      }
      else {
        this.selectedItems = [{ "id": this.currentuser.id, "itemName": this.currentuser.username }];
    
      }
      this.GetAllTicketStatus();
      this.GetTicketUserLists();
      this.closeOpenTicketHistory();
      this.LoadTickets(this.startDateVal, this.endDateVal, this.dashticketType);
      if (this.ticketId != null && this.ticketId != undefined && this.ticketId != '') {
        this.showTicketInfo = true;
        var selfObj = this;
        $(setTimeout(function () {
          selfObj.ticketgridOptions.api.forEachNode((node) => {
            if (node.data.ticketId == selfObj.ticketId) {
              selfObj.ticketgridOptions.api.selectNode(node, true);
            }
          });
        }, 500));
      }

    });
    if (this.PageFrom != 'dashboard') {
        this.applicationComponent.selectedbuttonText = "Tickets";
    }
  }
  

  loadtickets(conId) {
    this.ticketslist = [];
    this.duplicateticketslist = [];
    this.customerService.GetTicketsListByContact(conId, 'All').then((tickets) => {
      var dateTime;
      tickets.forEach(ele => {
        this.customerService.GetTicketNotesByTicketId(ele.ticketId, 'Ticket').then(result => {

          var dateTime;
          ele.ticketNotes = result;
          ele.ticketNotes.forEach(note => {
            dateTime = '';
            dateTime = this.datePipe.transform(note.CreatedDate, "MM-dd-yyyy");
            dateTime += " (" + this.datePipe.transform(note.CreatedDate, 'hh: mm a') + ")";
            note.CreatedDate = dateTime;
          });
        })
        this.duplicateticketslist.push(ele);
        dateTime = '';
        dateTime = this.datePipe.transform(ele.createdDate, "MM-dd-yyyy");
        dateTime += " (" + this.datePipe.transform(ele.createdDate, 'HH: mm') + ")";
        ele.createdDate = dateTime;
      });
 
      this.ticketslist = this.duplicateticketslist;

    });
  }

  LoadTickets(startdate, enddate,tickettype) {
    
    this.startDateVal = startdate;
    this.endDateVal = enddate;
    this.dashticketType = tickettype;
    if (this.PageFrom == 'dashboard') {
      if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
        this.ticketType = this.dashticketType;
        this.GetAllZendeskUers(this.Filter, this.sortOrder);
      } else {
        this.currentuser.id = 0;
        this.applicationComponent.searchCriteriadata = [];
        this.GetAllZendeskUers(this.Filter, this.sortOrder);

      }

      
    }
    else {
      let str = localStorage.getItem('redirectfrom');
      let groupList = localStorage.getItem('assignedTo');
      let tktIndex = parseInt(localStorage.getItem('tktIndex'));
      let tktType = localStorage.getItem('tktType');
      if (str == 'dashboard') {
        if (tktType == 'Closed Tickets') {
          $(setTimeout(function () {
            $("#rdClosedTkt").attr("checked", true).click();
          }, 500));

        } else {
          this.assignedTo = null;
          this.ticketType = tktType;
          this.GetAllZendeskUers(this.Filter, this.sortOrder);

          let selfObj = this;
          $(setTimeout(function () {
            if (groupList != null && groupList != "null" && groupList != undefined && groupList != '') {
              let lst = selfObj.Userlist.filter(x => x.groupList == groupList);
              selfObj.showTicketDataDiv = true;
              selfObj.showOpenTicketHistory = false;
              selfObj.ticketDataHide = false;
              if (lst != null && lst != undefined && lst.length > 0) {
                if (selfObj.ticketId == null || selfObj.ticketId == undefined) {
                  selfObj.ticketdata = lst[tktIndex];
                  selfObj.getAccountDetails(lst[tktIndex].accID);
                  
                  selfObj.gridOptions.api.forEachNode((node) => {
                    if (node.data.ticketId == selfObj.ticketdata.ticketId) {
                      selfObj.gridOptions.api.selectNode(node, true);
                    }
                  });
                } else {
                  selfObj.gridOptions.api.forEachNode((node) => {
                    if (node.data.ticketId == selfObj.ticketId) {
                      selfObj.ticketdata = selfObj.Userlist.filter(x => x.ticketId == Number(selfObj.ticketId))[0];
                    
                      selfObj.gridOptions.api.selectNode(node, true);
                      //selfObj.contDashComp.ShowRightInfoDiv = false; 
                    }
                  });
                }
              }
              else {
                selfObj.ticketDataHide = true;
              }
            } else {
              selfObj.showTicketDataDiv = true;
              selfObj.showOpenTicketHistory = false;
              selfObj.ticketDataHide = false;
              if (selfObj.Userlist != null && selfObj.Userlist != undefined && selfObj.Userlist.length > 0) {
                if (selfObj.ticketId == null || selfObj.ticketId == undefined) {
                  selfObj.ticketdata = selfObj.Userlist[tktIndex];                
                  selfObj.getAccountDetails(selfObj.Userlist[tktIndex].accID);                 
                }
                else {
                  selfObj.gridOptions.api.forEachNode((node) => {
                    if (node.data.ticketId == selfObj.ticketId) {
                      selfObj.ticketdata = selfObj.Userlist.filter(x => x.ticketId == Number(selfObj.ticketId))[0];
                      selfObj.gridOptions.api.selectNode(node, true);
                    }
                  });
                }
                
              }
              else {
                selfObj.ticketDataHide = true;
              }
            }
          }, 500));
       
        }

      }
      else {
        this.assignedTo = null;
        this.displayCC = false;
        this.GetAllZendeskUers(this.Filter, 1);

        let selfObj = this;
        $(setTimeout(function () {
          selfObj.showTicketDataDiv = true;
          selfObj.showOpenTicketHistory = false;
          selfObj.ticketDataHide = false;
          if (selfObj.Userlist != null && selfObj.Userlist != undefined && selfObj.Userlist.length > 0) {
            if (selfObj.ticketId == null || selfObj.ticketId == undefined) {
              selfObj.ticketdata = selfObj.Userlist[0];
          
                selfObj.getAccountDetails(selfObj.Userlist[0].accID);
                
            }
            else {
              selfObj.gridOptions.api.forEachNode((node) => {
                if (node.data.ticketId == selfObj.ticketId) {
                  selfObj.ticketdata = selfObj.Userlist.filter(x => x.ticketId == Number(selfObj.ticketId))[0];
                
                  selfObj.gridOptions.api.selectNode(node, true);
                }
              });
            }
            var obj: AuditLogModel = { id: 0, type: 'Tickets', action: 'Tickets Viewed', AccountOrContactId: selfObj.ticketdata.contactid, AccountOrContact: "Contact", documentid: selfObj.ticketdata.ticketId, userid: selfObj.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Tickets Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
            selfObj.applicationComponent.InsertAuditHistory(obj);
          }
          else {
            selfObj.ticketDataHide = true;
          }
        }, 500));
      }
     

    }
  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  GetAllTicketStatus() {
    this.customerService.GetAllTicketStatus().then((userslist) => {
      this.ticketStatusListForDropDown = userslist.filter(p => p.id != '6');
      this.ticketStatusList = userslist;

    });
  }

  //loadContactTickets(conId) {
  //  let selfObj = this;
  //  $(setTimeout(function () {
  //    selfObj.isTicketsInfoDivVisible = true;
  //    selfObj.ticketsComp.loadcontactdatatoticket(conId);
  //  }, 200));


  //}

  calanderclick(val) {
    this.calanderonoff = val;
    if (this.calanderonoff == false) {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }
  }
  GetAllZendeskUers(filter, sortOrder) {

    if (this.ticketType == "") { this.Userlist = []; }
    else {
      if (this.ticketType == 'Open Tickets') {
        filter = 'My Tickets Only'
      }
      this.customerService.GetTicketsList(this.ticketType, filter, this.currentuser.id, sortOrder, this.displayCC, this.assignedTo).then((tickets) => {
      if (this.dashticketType == "Closed Tickets") {
          this.Userlist = tickets.filter(p => p.createdDate >= this.startDateVal && p.createdDate <= this.endDateVal);
        }       
        tickets.forEach(ele => {
          ele.createdDate = this.datePipe.transform(ele.createdDate, "MM-dd-yyyy")
          this.AssignTo = '';
          this.CCTo = '';
          if (ele.assignTicketToTask != null) {
            ele.assignTicketToTask = "TG-" + ele.assignTicketToTask;
            this.AssignTo = ele.assignTicketToTask;
          }
          if (ele.assignTicketToUser != null) {
            ele.assignTicketToUser = ele.assignTicketToUser; //"USR-" +ele.assignTicketToUser;
            this.AssignTo = ele.assignTicketToUser
          }

          if (ele.CCToTask != null) {
            ele.CCToTask = "TG-" + ele.CCToTask;
            this.CCTo = ele.CCToTask;
          }
          if (ele.CCTo != null) {
            ele.CCTo = "USR-" + ele.CCTo;
            this.CCTo = ele.CCTo
          }

        });
        this.Userlist = tickets;        
        this.OriginalUserList = tickets;
        this.applicationComponent.OpenTicketCountLoginUser = tickets.filter(p => p.TicketStatus == 1 && (p.assignTicketToUser != null && p.assignTicketToUser == this.currentuser.id) && p.readstatus==0).length;
        this.TotalTicketCount = this.Userlist.length;     
        this.Below30Days = this.Userlist.filter((cust) => cust.NoOfDays <= 30).length;
        this.Betwn31_60Days = this.Userlist.filter((cust) => cust.NoOfDays > 30 && cust.NoOfDays <= 60).length;
        this.Betwn61_90Days = this.Userlist.filter((cust) => cust.NoOfDays > 60 && cust.NoOfDays <= 90).length;
        this.Betwn91_120Days = this.Userlist.filter((cust) => cust.NoOfDays > 90 && cust.NoOfDays <= 120).length;
        this.Above120Days = this.Userlist.filter((cust) => cust.NoOfDays > 120).length;
        if (this.Userlist.length > 0) {
          if (this.showTicketInfo) {
            this.ticketId = localStorage.getItem('openTicketId');
            this.ticketId = Number(localStorage.getItem('openTicketId')) > 0 ? localStorage.getItem('openTicketId') : this.Userlist[0].ticketId.toString();
            let selfObj = this;
            $(setTimeout(function () {
              selfObj.ticketgridOptions.api.forEachNode((node) => {
                if (node.data.ticketId == selfObj.ticketId) {
                  selfObj.ticketgridOptions.api.selectNode(node, true);
                  selfObj.gridOptions.api.selectNode(node, true);
                }
              });
            }, 300));
          }
        }
        else {
          this.CloseTicketDiv();
         
          
        }
        if (this.ticketType == 'Pended Tickets') {
          $("#rdPendedTkt").attr("checked", true);
        }
        else if (this.ticketType == 'On-Hold Tickets') {
          $("#rdOnHoldTkt").attr("checked", true);
        }
        else if (this.ticketType == 'Closed Tickets') {
          $("#rdClosedTkt").attr("checked", true);
        }
        else if (this.ticketType == 'Deleted Tickets') {
          $("#rdDeletedTkt").attr("checked", true);
        }
        else {
          $("#rdOpenTkt").attr("checked", true);
        }
       
      });
    }

  }
  CloseTicketDiv() {
    this.showTicketInfo = false;
    this.ticketagingId = parseInt(localStorage.getItem('TicketAgingId'));
    if (this.ticketagingId > 0) {
      let that = this;
     
      setTimeout(function () {
        $('#' + that.ticketagingId).trigger("click");

      }, 200);  
     
    }
   // this.addopportunityExpand = false;
    //this.gridOptions.rowSelection = 'multiple';
  }
  //GetAllTicketsForCountUpdate(filter, sortOrder) {

  //  this.applicationComponent.ClosedTicketCount = 0;
  //  this.customerService.GetTicketsList('All Tickets', filter, this.currentuser.id, sortOrder, this.displayCC, this.assignedTo).then((tickets) => {
  //    this.applicationComponent.OpenTicketCount = tickets.filter(p => p.TicketStatus == 1).length;
  //    alert("c" + this.applicationComponent.OpenTicketCount);
  //    this.applicationComponent.OpenTicketCountLoginUser = tickets.filter(p => p.TicketStatus && (p.assignTicketToUser != null && p.assignTicketToUser == this.currentuser.id)).length;
  //    this.applicationComponent.OnHoldTicketCount = tickets.filter(p => p.TicketStatus == 2).length;
  //    this.applicationComponent.PendedTicketCount = tickets.filter(p => p.TicketStatus == 3).length;
  //    this.applicationComponent.ClosedTicketCount = tickets.filter(p => p.TicketStatus == 4).length + tickets.filter(p => p.TicketStatus == 5).length;;
  //    //this.applicationComponent.ClosedTicketCount += tickets.filter(p => p.TicketStatus == 5).length;
  //    this.applicationComponent.AllTicketCount = tickets.length;
  //    this.applicationComponent.UnReadOpenTicketCount = tickets.filter(p => p.readstatus == 0 && (p.assignTicketToUser != null && p.assignTicketToUser == this.currentuser.id)).length;
  //  });

  //}

  initializeAgGrid() {
    //this.gridOptions = <GridOptions>{
    //  pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    //};

    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.ticketgridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    let gridconfigId = (this.PageFrom == 'dashboard' && this.assignedTo == 'Support') ? 66 : 64;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, gridconfigId).then((config) => {
      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        if (this.gridOptions && this.gridOptions.api)
          this.gridOptions.api.paginationSetPageSize(config.maxlimit);
        this.pageSize = config.maxlimit.toString();
      }
      columnSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.colSelections = columnSelections;
      //this.GetAllZendeskUers(this.Filter, this.sortOrder);
      this.createColumnDefs(columnSelections);
      this.UpdateSearchColumns(columnSelections)
    });

    this.context = this;
    this.frameworkComponents = {
      editdeletecv: EditdeletecvComponent
    };
  }

  getAccountDetails(Accountid) {
    this.customerService.GetByAccountId(Accountid).then(result => {
      this.account = result;
      this.Display(this.account.id, this.account.accountname, this.account.address1, this.account.address2, this.account.city, this.account.state, this.account.zip, this.account.countryname, this.account.phone, this.account.fax, this.account.website, this.account.Accountiddata);
    });
  }

  Display(accId, accountname, addr1, addr2, city, state, zip, country, phone, fax, website, Accountiddata) {

    //var properCity = city.charAt(0).toUpperCase() + city.substr(1).toLowerCase();
    if (city != null) {
      var cityArray = city.split(' ');
      var newCity = '';
      for (var i = 0; i < cityArray.length; i++) {
        if (newCity == '') {
          newCity = cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
        else {
          newCity = newCity + ' ' + cityArray[i].charAt(0).toUpperCase() + cityArray[i].substr(1).toLowerCase();
        }
      }
    }
    if (website != null && website.indexOf('www.') >= 0) {
      website = ((website).split('www.')[1])
    } else if (website != null && website.indexOf('ww.') >= 0) {
      website = ((website).split('ww.')[1])
    }


    var properstate = (state != null) ? state.charAt(0).toUpperCase() + state.substr(1).toLowerCase() : "";
    this.viewaccount.accountId = accId;
    this.viewaccount.viewaccountname = accountname;
    this.viewaccount.viewaddress1 = addr1;
    this.viewaccount.viewaddress2 = addr2;
    this.viewaccount.viewcity = newCity == null ? "" : newCity;
    this.viewaccount.viewstate = properstate;
    this.viewaccount.viewzip = zip;
    this.viewaccount.viewCountry = country;
    this.viewaccount.viewphone = phone;
    this.viewaccount.viewfax = fax;
    this.viewaccount.viewwebsite = website;
    this.viewaccount.Accountiddata = Accountiddata;
    this.accdetailcmp.loadAccountId(accId);

  }
  createColumnDefs(columnSelections: string) {
    var searcharray = [];
    if (this.gridOptions.quickFilterText != null && this.gridOptions.quickFilterText != undefined && this.gridOptions.quickFilterText != '') {
      searcharray.push(this.gridOptions.quickFilterText);
    }
    let columnSelectionsArray: string[] = [];
    let selfobj = this;
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      selfobj.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          selfobj.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "contactname") {
          selfobj.columnDefs.push({
            field: 'contactname', headerName: 'Contact Name', getQuickFilterText: () => '',hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "accountName") {
          selfobj.columnDefs.push({
            field: 'accountName', headerName: 'Account', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]), cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "accPhone") {
          selfobj.columnDefs.push({
            field: 'accPhone', headerName: 'accPhone', hide: false, width: parseInt(fieldHeaderVisibleWidthArray[3]),
            cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          });
        }
        else if (fieldHeaderVisibleWidthArray[0] == "TicketStatus") {
          selfobj.columnDefs.push({
            field: 'TicketStatus', headerName: 'Status', getQuickFilterText: () => '',hide: false, cellRenderer(params) {
              var eDiv = document.createElement('div');
              var text = '';
              selfobj.ticketStatusList.forEach(ele => {
                if (ele.id == params.value) {
                  text = ele.itemName;
                }
              })
              eDiv.innerHTML = text;
              return eDiv;
            }
          });
        }

        else {
          selfobj.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1], getQuickFilterText: () => '',
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3])
          });
        }
      }
    } else {

      if (this.PageFrom == 'dashboard' && this.assignedTo =='null') {
        this.columnDefs = [
          
          {
            field: 'contactname', headerName: 'Contact name', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },

          {
            field: 'createdDate', headerName: 'Created Date', hide: false, width: 120, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          },
       
          {
            field: 'priority', headerName: 'Priority', hide: false, cellRenderer: function (params) {
              if (params.data.priority == 1) {
                return 'Yes';

              } else {
                return 'No';
              }
            }
          },

          {
            field: 'accountName', headerName: 'Account', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },
          { field: 'userList', headerName: 'Assign Ticket To', hide: false },

          { field: 'subject', headerName: 'Subject', hide: false },
          { field: 'descriptions', headerName: 'Description', hide: false },

          {
            field: 'email', headerName: 'Email', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },
          { field: 'CCToName', headerName: 'CC', hide: true },
       
          {
            field: 'TicketStatus', headerName: 'Status', hide: false, cellRenderer(params) {
              var eDiv = document.createElement('div');
              var text = '';
              selfobj.ticketStatusList.forEach(ele => {
                if (ele.id == params.value) {
                  text = ele.itemName;
                }
              })
              eDiv.innerHTML = text;
              return eDiv;
            }
          },
        

        ];
      } else if (this.PageFrom == 'dashboard') {
        this.columnDefs = [
          {
            field: 'accountName', headerName: 'Account', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'contactname', headerName: 'Contact name', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },

          {
            field: 'createdDate', headerName: 'Created Date', hide: false, width: 120, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          },
          { field: 'userList', headerName: 'Assign Ticket To', hide: false },

          {
            field: 'priority', headerName: 'Priority', hide: false, cellRenderer: function (params) {
              if (params.data.priority == 1) {
                return 'Yes';

              } else {
                return 'No';
              }
            }
          },


          { field: 'subject', headerName: 'Subject', hide: false },
          { field: 'descriptions', headerName: 'Description', hide: false },

          {
            field: 'email', headerName: 'Email', hide: false, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },
          { field: 'CCToName', headerName: 'CC', hide: true },
          { field: 'ticketId', headerName: 'Ticket', hide: true },
          {
            field: 'TicketStatus', headerName: 'Status', hide: false, cellRenderer(params) {
              var eDiv = document.createElement('div');
              var text = '';
              selfobj.ticketStatusList.forEach(ele => {
                if (ele.id == params.value) {
                  text = ele.itemName;
                }
              })
              eDiv.innerHTML = text;
              return eDiv;
            }
          },
          {
            field: 'UserName', headerName: 'User Name', hide: true, cellRenderer: function (params) {
              if (params.data.SearchVal != null && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  var search = params.data.SearchVal;
                  if (searcharray.toString().includes(x[i].trim().toLowerCase())) {
                    st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          }

        ];
      } else {
        this.columnDefs = [
          {
            headerName: '#',
            field: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: this.PageFrom == 'dashboard' ? true : false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          },

          {
            field: 'createdDate', headerName: 'Created Date', getQuickFilterText: () => '',hide: false, width: 120, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          },
          {
            field: 'ClosedDate', headerName: 'Closed Date', getQuickFilterText: () => '', hide: false, width: 120, cellRenderer: (params) => {
              return moment(params.value).format('MM-DD-YYYY');
            }
          },
          {
            field: 'accountName', headerName: 'Account', hide: false, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                var st = "";
                for (let i = 0; i < x.length; i++) {

                  if (searcharray.toString().startsWith(x[i].trim()) || searcharray.toString().toLowerCase().startsWith(x[i].toLowerCase()) || x[i].toString().toLowerCase().startsWith(searcharray.toString().toLowerCase())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }
                }
                return st

              } else {
                return params.value;
              }
            }
          },
          {
            field: 'contactname', headerName: 'Contact name', getQuickFilterText: () => '', hide: false
          },
          {
            field: 'priority', headerName: 'Priority', getQuickFilterText: () => '', hide: false, cellRenderer: function (params) {
              if (params.data.priority == 1) {
                return 'Yes';

              } else {
                return 'No';
              }
            }
          },

          { field: 'userList', headerName: 'Assign Ticket To', getQuickFilterText: () => '',hide: false },
         

          {
            field: 'accPhone', headerName: 'Phone', hide: false, cellRenderer: function (params) {
              if (searcharray.length > 0 && params.value != null) {
                let x = params.value.split(" ");
                let y = searcharray[0].includes(" ") >= 0 ? searcharray.toString().split(' ') : searcharray;
                var st = "";
                for (let i = 0; i < x.length; i++) {
                  if (i == 0 && searcharray.toString().startsWith(x[i]) || searcharray.toString().startsWith(x[i]) || x[i].toString().startsWith(searcharray.toString())) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else if (i == 1 && y.length > 1 && (y[1].toString().startsWith(x[i]) || y[1].toString().startsWith(x[i]) || x[i].toString().startsWith(y[1].toString()))) {
                    st += "&nbsp;<label style='color:#00B0F0'>" + x[i] + "</label>&nbsp;";
                  }
                  else {
                    st += x[i] + " ";
                  }

                }
                return st

              } else {
                return params.value;
              }
            }
          },
         

          
          { field: 'subject', headerName: 'Subject', getQuickFilterText: () => '',hide: false },
          { field: 'descriptions', headerName: 'Description', hide: false },

          {
            field: 'email', headerName: 'Email', getQuickFilterText: () => '',hide: false
          },
          { field: 'CCToName', headerName: 'CC', getQuickFilterText: () => '',hide: true },
          { field: 'ticketId', headerName: 'Ticket', getQuickFilterText: () => '',hide: true },
          {
            field: 'TicketStatus', headerName: 'Status', getQuickFilterText: () => '',hide: false, cellRenderer(params) {
              var eDiv = document.createElement('div');
              var text = '';
              selfobj.ticketStatusList.forEach(ele => {
                if (ele.id == params.value) {
                  text = ele.itemName;
                }
              })
              eDiv.innerHTML = text;
              return eDiv;
            }
          },
          {
            field: 'UserName', headerName: 'User Name', getQuickFilterText: () => '',hide: true
          },
          {
            field: 'action', headerName: 'Action', hide: true, cellRenderer(params) {
              if (params.data.TicketStatus != '6') {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = '<img src="../../../assets/img/Grey Trash.png" style="height:15px;width:12%"/>';
                eDiv.addEventListener('click', function () {
                  selfobj.deleteClosedTicket(params.data);
                });
                return eDiv;
              }
              else {
                return null;
              }


            }
          }

        ];
      }
    }
    selfobj.gridOptions.getRowStyle = function (params) {

      if (params.data.assignTicketToUser != null && params.data.assignTicketToUser == selfobj.currentuser.id && params.data.readstatus == 0) {
        return { /*'font-weight': 'bold'*/ };
      }
    }
  }
  editRowAg(rowdata) {
    this.customerService.savezendeskUser(this.ozendeskuser).then(result => { });
  }
  deleteRowAg(rowdata) {
    this.customerService.DeleteZendeskUser(rowdata.Id).then((user) => {
    });
    this.GetAllZendeskUers(this.Filter, this.sortOrder);
  }
  SaveZendeskuser() {
    this.customerService.savezendeskUser(this.ozendeskuser).then(result => { });
    this.GetAllZendeskUers(this.Filter, this.sortOrder);

  }

  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    if (event.finished == true)
      this.saveConfiguration();
  }
  UpdateSearchColumns(columnSelections) {
    this.applicationComponent.SearchColumnList = [];
    this.applicationComponent.SearchSelectedItems = [];
    let allCols: string[] = columnSelections.split(',');

    let columnSelectionsArray: any[] = [];
    for (let i = 0; i < allCols.length; i++) {
      let fields: string[] = allCols[i].split(':');
      if (fields[2] == 'true' && (fields[1] != '#' && fields[0] != 'notes' && fields[0] != 'actions' && fields[0] != 'callliststatus')) {
        columnSelectionsArray.push({ "id": fields[0], "itemName": fields[1] });
      }
    }

    this.applicationComponent.SearchColumnList = columnSelectionsArray;
    var retrievedObject = localStorage.getItem('TempTable');
    let tempTableColumns: any[];
    if (retrievedObject != null) {
      let obj: any[] = JSON.parse(retrievedObject);
      obj.forEach(element => {
        if (element.subtab == 'OpenTickets' && this.applicationComponent.Searchtabname == 'OpenTickets') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        } else if (element.subtab == 'ClosedTickets' && this.applicationComponent.Searchtabname == 'ClosedTickets') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        } else if (element.subtab == 'AllTickets' && this.applicationComponent.Searchtabname == 'AllTickets') {
          tempTableColumns = element.columns;
          this.applicationComponent.SearchSelectedItemsLengthChanged = element.SearchSelectedItemsLengthChanged;
        }

        //if (this.applicationComponent.SearchSelectedItemsLengthChanged == true) {
        //  this.applicationComponent.showSearchTabsSubTabs = false;
        //}
        //else {
        //  this.applicationComponent.showSearchTabsSubTabs = true;
        //}
      });
    }
    if (tempTableColumns && tempTableColumns.length > 0) {
      tempTableColumns.forEach(col => {
        this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

      })
    }
    else {
      this.accountService.GetDefaultColumns('OpenTickets', this.currentuser.id).then((config) => {
        if (config != null && config.defaultcolumns != null) {
          let tempTableColumns: any[];
          tempTableColumns = config.defaultcolumns.split(',');
          tempTableColumns.forEach(col => {
            this.applicationComponent.SearchSelectedItems.push({ "id": col.split('-')[0], "itemName": col.split('-')[1] })

          })
        } else {
          this.applicationComponent.SearchSelectedItems = [
            { "id": 'accountName', "itemName": 'Account' },
          ];
        }
      });
      //this.applicationComponent.showSearchTabsSubTabs = false;
    }
  }

  dateChange(event) { }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    if (this.pageSize == "")
      config.maxlimit = 1000;
    else
      config.maxlimit = Number(this.pageSize);

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }

    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    this.UpdateSearchColumns(config.columnsselections);
    config.configureduserid = this.currentuser.id;
    config.resourceid = 53;

    config.userid = "";
    for (let i = 0; i < this.selectedItems.length; i++) {
      config.userid += this.selectedItems[i].id + ",";
    }

    if (config.userid.length > 0)
      config.userid = config.userid.substr(0, config.userid.length - 1);

    this.opprtunityservice.saveDashboardconfiguration(config);
  }
  MyTicketsOn(val) {

    this.displayMyTicketsOn = val;
    if (this.displayMyTicketsOn == true) {
      this.sortOrder = "MyTicketsFirst";
    }
    else {
      this.sortOrder = "None";
    }
    this.GetAllZendeskUers(this.Filter, this.sortOrder);
  }

  //UserListChange(Val) {
  //  switch (Val) {
  //    case "1": {
  //      this.Filter = 'All Tasks Groups and Users';

  //      break;
  //    };
  //    case "2": {
  //      this.Filter = 'My Tickets Only';
  //      break;
  //    };
  //    case "3": {
  //      this.Filter = 'Task Groups Only';
  //      break;
  //    };
  //    case "4": {
  //      this.Filter = 'Users Only';
  //      break;
  //    }
  //  }
  //  this.GetAllZendeskUers(this.Filter, this.sortOrder);
  //}

  cellDoubleClicked(event) {
    if (this.PageFrom !== 'dashboard') {

      if (event.node.selected && event.node.selected === true) {
        this.ShowDialog = false;
        this.showTicketInfo = true;
        this.showTicketDataDiv = true;
        this.ticketDataHide = false;

        this.getAccountDetails(event.data.accID);
        //this.GetContactData(event.data.contactid);
        this.loadtickets(event.data.contactid);
        this.ticketdata = new Tickets()
        this.ticketdata = event.data;
        this.ticketId = event.data.ticketId;
        this.gridOptions.rowSelection = 'single';
       
        this.getNotes();
        if (event.data.assignTicketToUser == null) {
          this.AssignTo = event.data.assignTicketToTask;

        } else {
          this.AssignTo = event.data.assignTicketToUser;
        }

        if (event.data.CCTo == null) {
          
          this.CCTo = event.data.CCToTask;

        } else {
          this.CCTo = event.data.CCTo;
        }
        
        var self = this;
        $(setTimeout(function () {
          if (self.ticketdata.assignTicketToUser == self.currentuser.id && self.ticketdata.readstatus == 0) {
            self.ticketdata.readstatus = 1;
            self.savetickets();          
          }        
          var obj: AuditLogModel = { id: 0, type: 'Tickets', action: 'Tickets Viewed', AccountOrContactId: self.ticketdata.contactid, AccountOrContact: "Contact", documentid: self.ticketdata.ticketId, userid: self.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Tickets Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
          self.applicationComponent.InsertAuditHistory(obj);
        }, 100));


        var selfObj = this;
        $(setTimeout(function () {
          selfObj.ticketgridOptions.api.forEachNode((node) => {
            if (node.data.ticketId == selfObj.ticketdata.ticketId) {
              selfObj.gridOptions.api.selectNode(node, true);
            }
          });
        }, 500));
      }

    }

  }
  rowticketHistoryClickAgGrid(event) {
    this.ticketdata = new Tickets()
    this.ticketdata = event.data;
    this.getNotes();
  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {    
      this.ticketdata = event.api.getSelectedNodes()[0].data;
        this.getNotes();    
    }
    else {
      this.ticketdata = null;
      this.ticketNotesData = null;
    }

  }
  rowClickAgGrid(event) {   
      if (event.node.selected && event.node.selected === true) {
        if (this.showTicketInfo) {
          this.ShowDialog = false;
          this.showTicketInfo = true;
          this.showTicketDataDiv = true;
          this.ticketDataHide = false;
          this.getAccountDetails(event.data.accID);
          this.loadtickets(event.data.contactid);
          this.ticketdata = new Tickets()
          this.ticketdata = event.data;
          this.ticketId = event.data.ticketId;
          this.gridOptions.rowSelection = 'single';
          this.getNotes();
          if (event.data.assignTicketToUser == null) {
            this.AssignTo = event.data.assignTicketToTask;
          } else {
            this.AssignTo = event.data.assignTicketToUser;
          }

          if (event.data.CCTo == null) {
            this.CCTo = event.data.CCToTask;
          } else {
            this.CCTo = event.data.CCTo;
          }
          var self = this;
          $(setTimeout(function () {
            if (self.ticketdata.assignTicketToUser == self.currentuser.id && self.ticketdata.readstatus == 0) {
              self.ticketdata.readstatus = 1;
              self.SaveTicketdata();
            }
            var obj: AuditLogModel = { id: 0, type: 'Tickets', action: 'Tickets Viewed', AccountOrContactId: self.ticketdata.contactid, AccountOrContact: "Contact", documentid: self.ticketdata.ticketId, userid: self.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Tickets Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
            self.applicationComponent.InsertAuditHistory(obj);
          }, 100));
        }
       
       
        var selfObj = this;
        $(setTimeout(function () {
          selfObj.gridOptions.api.forEachNode((node) => {
            if (node.data.ticketId == selfObj.ticketdata.ticketId) {
              selfObj.gridOptions.api.selectNode(node, true);
            }
          });
        }, 300));
      }  
  }

  ticketDetailPopUpCss() {
    setTimeout(function () {
      $('#ShowDialog .dialog').attr('style', 'width: 30%;padding: 0;min-height: 63%;overflow: auto;height: 99.5%;top: 0%;left: 70%;');
    }, 4)
  }


  rowDoubleClickAgGrid(event) {
    if (this.PageFrom == 'dashboard') { 
      this.ticketId = event.data.ticketId;
      localStorage.setItem('openTicketId', event.data.ticketId);
      if (event.data.TicketStatus == 4) {
        this.router.navigateByUrl(`/application/zendeskhome/0/` + this.ticketId + '/' + 'Closed Tickets');
       
      } else {
      this.router.navigateByUrl(`/application/zendeskhome/0/` + this.ticketId + '/' + 'Open Tickets');
      }
     this.applicationComponent.ExpandSelectedMenu('tickets');
      var selfObj = this;
      $(setTimeout(function () {
        selfObj.ticketgridOptions.api.forEachNode((node) => {
          if (node.data.ticketId == selfObj.ticketId) {
            selfObj.ticketgridOptions.api.selectNode(node, true);
          }
        });
      }, 500));
    } else {
      this.ShowDialog = false;
      this.showTicketInfo = true;
      this.showTicketDataDiv = true;
      this.ticketDataHide = false;
      this.EnableSearch = false;
      this.getAccountDetails(event.data.accID);
      this.loadtickets(event.data.contactid);
      this.ticketdata = new Tickets()
      this.ticketdata = event.data;
      this.ticketId = event.data.ticketId;
      this.ticketgridOptions.rowSelection = 'single';

      this.getNotes();
      if (event.data.assignTicketToUser == null) {
        this.AssignTo = event.data.assignTicketToTask;

      } else {
        this.AssignTo = event.data.assignTicketToUser;
      }

      if (event.data.CCTo == null) {

        this.CCTo = event.data.CCToTask;

      } else {
        this.CCTo = event.data.CCTo;
      }
      var self = this;
      $(setTimeout(function () {
        if (self.ticketdata.assignTicketToUser == self.currentuser.id && self.ticketdata.readstatus == 0) {
          self.ticketdata.readstatus = 1;
          self.SaveTicketdata();
        }
        var obj: AuditLogModel = { id: 0, type: 'Tickets', action: 'Tickets Viewed', AccountOrContactId: self.ticketdata.contactid, AccountOrContact: "Contact", documentid: self.ticketdata.ticketId, userid: self.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Tickets Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
        self.applicationComponent.InsertAuditHistory(obj);
      }, 100));


      var selfObj = this;
      $(setTimeout(function () {
        selfObj.ticketgridOptions.api.forEachNode((node) => {
          if (node.data.ticketId == selfObj.ticketdata.ticketId) {
            selfObj.ticketgridOptions.api.selectNode(node, true);
          }
        });
      }, 500));

      //}

    }

  }

  //GetContactData(contactid) {
  //  this.customerService.Getcontactimage(contactid).then(res => {
  //    this.urlImage = res.photo;

  //  });
  //}



  GetTicketUserLists() {
    this.userservice.GetTicketUserLists(this.currentuser.id).then((taskgroup) => {
      if (taskgroup != null) {
        this.getSelectedUserList(taskgroup[0].SelectedUserID, taskgroup[0].SelectedTaskGroupID);
      }

    });
  }

  getSelectedTaskGroups(status) {
    this.ticketTaskAssignTo = [];
    this.userservice.GetSelectedTaskgroups(status).then((taskgroups) => {
      this.ticketTaskAssignTo = taskgroups;
    });


  }

  getSelectedUserList(status, groupid) {
    this.ticketUserAssignTo = [];
    this.customerService.Getselectedusers(status).then((users) => {
      users.forEach(elem => {
        elem.category = "USR-" + elem.id;
      });
      this.ticketUserAssignTo = users;

      let taskgroupsdata = [];
      this.userservice.GetTaskgrouplist('0,1').then((taskgroups) => {
        taskgroups.forEach(ele => {
          ele.category = "TG-" + ele.id;
          this.ticketUserAssignTo.push(ele);
        });
      });

    });



  }
  AssignToChange(val) {
    if (val.split('-')[0] == 'TG') {
      this.ticketdata.assignTicketToTask = val.split('-')[1];
      this.ticketdata.assignTicketToUser = null;
      this.AssignTo = val;
    }
    else {
      this.ticketdata.assignTicketToUser = val.split('-')[1];
      this.ticketdata.assignTicketToTask = null;
      this.AssignTo = val;
    }

    this.statusChange = true;
  }

  onStausChnage(val) {
    this.statusChange = true;
  }
  savetickets() {
    this.SaveTicketDialog = true;
    setTimeout(function () {
      $('#SaveTicketDialog .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');    
    }, 4)
  }
  SaveTicketdata() {
 
     if (this.ticketdata.assignTicketToTask != null && this.ticketdata.assignTicketToTask.startsWith("TG-")) {
      this.ticketdata.assignTicketToTask = this.ticketdata.assignTicketToTask.split('-')[1];
    }
    if (this.ticketdata.assignTicketToUser != null && this.ticketdata.assignTicketToUser.startsWith("USR-")) {
      this.ticketdata.assignTicketToUser = this.ticketdata.assignTicketToUser.split('-')[1];
    }
    this.customerService.addticket(this.ticketdata).then(result => {
      if (this.ticketNotes.Notes != null && this.ticketNotes.Notes != "") {
        this.saveNotes();
      }
      this.ticketdata = result;     
      this.loadtickets(this.ticketdata.contactid);
      this.GetAllZendeskUers(this.Filter, this.sortOrder);
      this.statusChange = false;
      this.SaveTicketDialog = false;
      this.ShowDialog = false;
      this.closeOpenTicketHistory();
      this.headerComp.LoadData();
    });
  }
  saveNotes() {
    this.ticketNotes.NoteId = 0;
    this.ticketNotes.UserID = this.currentuser.id;
    this.ticketNotes.TicketId = this.ticketdata.ticketId
    this.ticketNotes.NotesType = 'Ticket';
    this.customerService.addticketNotes(this.ticketNotes).then(result => {
      this.ticketNotes.Notes = '';
      this.getNotes();
      this.showSaveNotes = false;
    })
  }
  OpenTicket() {
    this.ShowDialog = true;
    if (this.ShowDialog == true) {
      this.ticketDetailPopUpCss();
    }
  }

  getNotes() {
    this.customerService.GetTicketNotesByTicketId(this.ticketdata.ticketId,'Ticket').then(result => {

      var dateTime;
      this.ticketNotesData = result;
      this.ticketNotesData.forEach(ele => {
        dateTime = '';
        dateTime = this.datePipe.transform(ele.CreatedDate, "MM-dd-yyyy");
        dateTime += " (" + this.datePipe.transform(ele.CreatedDate, 'hh: mm a') + ")";
        ele.CreatedDate = dateTime;
      });
    })
  }
  NotesKeyUp() {

    if (this.ticketNotes.Notes.length > 0) {
      this.showSaveNotes = true;
    }
    else {
      this.showSaveNotes = false;
    }
  }
  OpenTicketHistory() {
    this.showOpenTicketHistory = true;
    this.showOpenTicketHistoryCss();
  }
  showOpenTicketHistoryCss() {
    setTimeout(function () {
      $('#OpenTicketHistoryDialog .dialog').attr('style', 'width: 44%;padding: 0;border: 4px solid #00B0F0;min-height: 63%;overflow: auto;height: 84%;top: 11%;left: 54%;');
    }, 4)
  }
  CloseTicketHistory() {
    this.showOpenTicketHistory = false;
    let sObj = this;
    $(setTimeout(function () {
      if (sObj.ticketType == 'Pended Tickets') {
        $("#rdPendedTkt").attr("checked", true);
      }
      else if (sObj.ticketType == 'On-Hold Tickets') {
        $("#rdOnHoldTkt").attr("checked", true);
      }
      else if (sObj.ticketType == 'Closed Tickets') {
        $("#rdClosedTkt").attr("checked", true);
      }
      else if (sObj.ticketType == 'Deleted Tickets') {
        $("#rdDeletedTkt").attr("checked", true);
      }
      else {
        $("#rdOpenTkt").attr("checked", true);
      }
    }, 100));
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.ticketgridOptions.api.forEachNode((node) => {
        if (node.data.ticketId == selfObj.ticketdata.ticketId) {
          selfObj.ticketgridOptions.api.selectNode(node, true);
        }
      });
    }, 200));
  }
  closeOpenTicketHistory() {
    this.showTicketDataDiv = true;
    this.showOpenTicketHistory = false;
    this.ticketDataHide = false;
  }
  CarbonCopy(val) {
    if (val.split('-')[0] == 'TG') {
      this.ticketdata.CCToTask = val.split('-')[1];
      this.ticketdata.CCTo = null;
      this.CCTo = val;
    }
    else {
      this.ticketdata.CCTo = val.split('-')[1];
      this.ticketdata.CCToTask = null;
      this.CCTo = val;
    }

    this.ticketdata.CCTo = val.split("-")[1];
    this.statusChange = true;
  }
  
  ClearOpenTicket() {
    this.ticketdata.TicketStatus = 1;
  }
  clearNotes() {
    this.ticketNotes.Notes = "";
    this.showSaveNotes = false;
  }

  PriorityChnage(event) {
    this.ticketdata.priority = event.target.value;
    this.statusChange = true;

  }

  handleClick(event) {
    var clickedComponent = event.target;
    //}
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {
      $('#test').val('Ticket');


    } else {

    }
  }

  deleteClosedTicket(val) {
    this.ticketdata = val;
    this.ticketdata.TicketStatus = 6;
    this.savetickets();
  }
  txtPageSizeChanged(pageSize) {
    this.ticketgridOptions.api.paginationSetPageSize(Number(pageSize));
    this.saveConfiguration();
  }
  openDisplaynotes(params) {

    $('#closeDetailsDialog').click();

    this.notes.notedescription = params.value;
    this.NotesDec = true;
    if (this.currentuser.id == params.data.userid) {
      this.showEditNoteBtn = true;
      this.editNoteId = params.data.id;
    } else
      this.showEditNoteBtn = false;

    let selfObj = this;
    $(setTimeout(function () {
      var notesOffset = $("#divNotesContainer").offset();
      let notesWidth = $('#divNotesContainer').width();
      let notesHeight = $('#divNotesContainer').height();
      let windowWidth = $(window).width();
      let showDialogWidth = 350;


      //$('#showDetailsDialog').width(showDialogWidth);
      //$('#showDetailsDialog').height(notesHeight - 140);
      //if ($('#typeClicked').val() == 'modal') {
      if (selfObj.isModal) {
        //$('#showDetailsDialog').css('top', '23%');
        //$('#showDetailsDialog').css('height', '61%');
        //$('#showDetailsDialog').css('left', notesWidth - showDialogWidth);
        //$('#showDetailsDialog').css('top', notesOffset.top + 125);
        //$('#showDetailsDialog').css('left', notesWidth - showDialogWidth);

        $('#showDetailsDialog').attr('style', 'width: 22%;height: 71%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '81%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '14%');
        } else {
          $('#txtLongNoteDesc').css('height', '96%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      } else {
        //var url = window.location.href;
        //if ((url).indexOf('account-contact') > -1) {
        //  $('#showDetailsDialog').css('top', '62%');
        //} else {
        //  $('#showDetailsDialog').css('top', '72%');
        //}
        var gridOffset = $("#notesGrid").offset();
        //$('#showDetailsDialog').css('top', gridOffset.top);
        //$('#showDetailsDialog').css('height', '30%');
        //$('#showDetailsDialog').css('left', '69%');

        $('#showDetailsDialog').attr('style', 'width: 22%;height: 62%;top: 5.5%;left: 882px;border - radius: 4px;border: 1px solid;');

        if ($('input[name=Notes]').is(':checked')) {
          $('#txtLongNoteDesc').css('height', '53%');
          $('#txtLongNoteDesc').css('padding-top', '0%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
          $('#txtUserFullName').css('height', '33%');
        } else {
          $('#txtLongNoteDesc').css('height', '89%');
          $('#txtLongNoteDesc').css('padding-top', '6%');
          $('#txtLongNoteDesc').css('padding-left', '2%');
        }
      }
      //$('#showDetailsDialog').css('border-radius', '4px');
      //$('#showDetailsDialog').css('border', '3px solid');
      $('#showDetailsDialog').show();
    }, 1));
  }
  initializeAgGridNotes() {

    this.gridOptionsnotes = <GridOptions>{
      pagination: false, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressRowGroups: true, paginationPageSize: 1000,toolPanelSuppressPivotMode: true, toolPanelSuppressValues: true, rowSelection: 'single'
    };
    this.createColumnDefsnotes();

    this.context = this;
  }
  createColumnDefsnotes() {
    let selfObj = this;
    this.columnDefsnotes = [{
      field: '#',
      headerName: '#',
      width: 52,
      checkboxSelection: true,
      suppressSorting: true,
      suppressMenu: true,
      pinned: true,
      hide: false,
      outerWidth: 8,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
    },
      {

        field: 'type', headerName: '', hide: false, width: 60, cellRenderer(params) {
          if (params.data.isnewnote == 1) {
            return '<i class="fa fa-file-text-o " aria-hidden="true"></i>';
          }
          else if (params.value == 'Contact' || params.value == 'Account') {
            //  return '<img src="../../assets/img/Computer.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-laptop " aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("Phone")) {
            //return '<img src="../../assets/img/phone.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-phone" aria-hidden="true"></i>';
          }
          else if (params.value != null && params.value.startsWith("To Do")) {
            return '<img src="../../assets/img/todo.jpg" style="height: 100%;text-align:center"/>';
          }
          else if (params.value != null && params.value.startsWith("Meeting")) {
            //return '<img src="../../assets/img/calender.png" style="height: 88%;margin-top: -20%;margin-left: 13%;"/>';
            return '<i class="fa fa-calendar " aria-hidden="true"></i>';
          } else if (params.value != null && (params.value.startsWith("IPC") || params.value.startsWith("Analysis") || params.value.startsWith("Prepare") || params.value.startsWith("Review") || params.value.startsWith("Generate") || params.value.startsWith("Justification") || params.value.startsWith("Reference") || params.value.startsWith("MD Logic Demonstration"))) {
            //return '<img src="../../assets/img/glass.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-hourglass-o" aria-hidden="true"></i>';
          }
          else {
            //return '<img src="../../assets/img/dollar.jpg" style="height: 100%;text-align:center"/>';
            return '<i class="fa fa-usd" aria-hidden="true"></i>';
          }

        }
      },
      {
        field: 'notedescription', headerName: 'Notes', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", tooltipField: 'notedescription', cellStyle: { 'text-align': 'left' },hide: false, cellRenderer(params) {
          var eDiv = document.createElement('div');
          eDiv.innerHTML = params.value;
          eDiv.addEventListener('click', function () {
            selfObj.openDisplaynotes(params);
          });
          return eDiv;
        }
      },
      { field: 'contactname', headerName: 'Contact', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: true },
      { field: 'created', headerName: 'Date Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      {
        field: 'status', headerName: 'Completed Y/N', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: false,
        cellRenderer(params) {
          if (params.value == 'Completed') { return $('#chkShowDetailsNotes').prop('checked') == true ? '<i style="font-weight: 700;font-size: 18px;" class="activeColor" aria - hidden="true" > </i>' : params.value; }
          else { return params.value; }

        }
      },
      { field: 'user', headerName: 'User Added', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'type', headerName: 'Type', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' },hide: $('#chkShowDetailsNotes').prop('checked') == true ? true : false },
      { field: 'userfullName', headerName: 'User Info', cellClass: $('#chkShowDetailsNotes').prop('checked') == true ? "notesclass" : "notesclassRegular", cellStyle: { 'text-align': 'left' }, hide: $('#chkShowDetailsNotes').prop('checked') == true ? false : true, cellRenderer(params) { return params.value; } }

    ];

    this.getRowHeight = function (params) {
      return 18 * (Math.floor(params.data.notedescription.length / 45) + 1);
    };
  }
  GetCustomerNotes(contactid, type) {

    this.customerService.GetAllNotesList(this.currentuser.id, contactid, type).then((taskhistory) => {
      taskhistory.forEach(items => {
        var moment = require('moment-timezone');
        var timeDisplay = items.user + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("MM-DD-YYYY") + '<br />' + moment.tz(items.created.toLocaleString(), this.timezonevalue).format("h:mm A");
        items.userfullName = timeDisplay;
        items.created = this.datePipe.transform(items.created, "MM-dd-yyyy hh-mm-ss");
      });
      this.initializeAgGridNotes();
      this.allnotes = taskhistory;
    });

  }
  openNotesDialogAg(rowdata) {

    this.conid = rowdata.id;
    this.typ = rowdata.type;
    $('#accotIdNotes').val(rowdata.id);
    $('#accotTypeNotes').val('Contact');
    this.contactid = rowdata.id;
    this.customerService.GetContactById(rowdata.id).then(result => {
      this.contacts = result;
      this.customerService.GetByAccountId(this.contacts.accountid).then(result => {
        this.account = result;

      });
    });


    if ((document.getElementById('agCallListContacts').className == 'col-xs-6') || ((document.getElementById('agCallListContacts').className == 'removePadding col-xs-6'))) {
      //$(setTimeout(function () {
      //  $("#divNotesContainer").css('height', '400px');
      //}, 100));
      this.isModal = false;
      document.getElementById('notesinfo').style.display = 'block'
      $('#chkShowDetailsNotes').prop('checked', false);
      document.getElementById('providerDetails').style.display = 'none'
      document.getElementById('accountInformation').style.display = 'none'
    }
    if (document.getElementById('agCallListContacts').className == 'col-xs-12') {

      this.isModal = true;
      this.contactid = rowdata.id;
      $('#accotIdNotes').val(rowdata.id);
      $('#accotTypeNotes').val('Contact');

      this.contactid = this.contactid;
      this.notesshowDialog = !this.notesshowDialog;
      $(setTimeout(function () {
        $('.dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
      }, 4));

    }
    this.GetCustomerNotes(rowdata.id.toString(), "Contact");
  }
  openAccountNotes() {
    this.isModal = true;
    this.conid = this.account.id;
    $('#accotIdNotes').val(this.account.id);
    $('#accotTypeNotes').val('Customer');
    this.notesshowDialog = !this.notesshowDialog;
    $(setTimeout(function () {
      $('.dialog').attr('style', 'width: 43%;padding: 0;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 63%;overflow: auto;height: 81%;top: 10%;left: 3%;max-width: 1393px !important;margin-right:1%');
    }, 4));
  }

  UserlistDataReceived(outProps: OutputPropsUserList) {
    if (outProps.enableSearch) { this.EnableSearch = true; }
    this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    if (outProps.finalitems != "") {
      $("#userids").val(outProps.finalitems);
      this.sortOrder = outProps.secondDrpValue == undefined ? this.sortOrder : outProps.secondDrpValue;
      if (outProps.seventhDrpValue == "1") {
        this.ticketgridOptions.columnApi.setColumnVisible('CCToName', false);
        this.displayCC = false;
      }
      else if (outProps.seventhDrpValue == "0") {
        this.ticketgridOptions.columnApi.setColumnVisible('CCToName', true);
        this.displayCC = true;
      }

      else {
        this.ticketgridOptions.columnApi.setColumnVisible('CCToName', false);
        this.displayCC = false;
      }
      if (outProps.secondDrpValue != undefined) {
        var val = outProps.secondDrpValue == "1" ? "Open Tickets" : outProps.secondDrpValue == "2" ? "Pended Tickets" :
          outProps.secondDrpValue == "3" ? "Closed Tickets" : outProps.secondDrpValue == "4" ? "On-Hold Tickets" :
            outProps.secondDrpValue == "5" ? "Deleted Tickets" : "";
        this.onTicketTypeChange(val)
      }
      if (!outProps.pageSizeChanged) {
        this.GetAllZendeskUers(this.Filter, this.sortOrder);
      }
      if (outProps.eightDrpValue == '1') { this.shwTicketDetails = true; }
      else { this.shwTicketDetails = false; }
     
    } else {
      this.Userlist = [];
      $("#userids").val('');
    }

  }

  onTicketTypeChange(val) {
    this.ticketType = val;
    this.GetAllZendeskUers(this.Filter, this.sortOrder);
    let str = localStorage.getItem('redirectfrom');
    let tktIndex = parseInt(localStorage.getItem('tktIndex'));
    let tktType = localStorage.getItem('tktType');
    if (str == 'dashboard') {
      this.assignedTo = null;
      this.ticketType = tktType;
      this.GetAllZendeskUers(this.Filter, this.sortOrder);

      let selfObj = this;
      $(setTimeout(function () {
        selfObj.showTicketDataDiv = true;
        selfObj.showOpenTicketHistory = false;
        selfObj.ticketDataHide = false;
        if (selfObj.Userlist != null && selfObj.Userlist != undefined && selfObj.Userlist.length > 0) {
          selfObj.ticketdata = selfObj.Userlist[tktIndex];
          selfObj.getAccountDetails(selfObj.Userlist[tktIndex].accID);
          
        }
        else {
          selfObj.ticketDataHide = true;
        }
      }, 500));
      localStorage.setItem('tktType', '');
      localStorage.setItem('tktIndex', '');
      localStorage.setItem('redirectfrom', '');
      
    }
    
  }

  addContact() {
   
    if (this.userprevilages != undefined) {
      for (var j = 0; j < this.userprevilages.length; j++) {
        if ("Contacts" == this.userprevilages[j].resource) {
          if (this.userprevilages[j].canadd == false) {
            this.toastr.clear();
            this.toastr.warning("You Do Not have Access to add – Please check with CRM Administrator if Access is Required.", "Access Denied");
            return;
          }
        }
      }
    }
    this.showAddEditContact = true;
    this.showAddEditContactCss();
  }
  AccountsharingAg(selectedval) {
    //if (this.practiceid == "view") {
    //  this.applicationComponent.isAccessDenied = true;
    //} else {

    if (selectedval == "Edit") {
      this.isSharing = false;
      this.action = selectedval;
      this.showAddEditAccount = true;
      this.showAddEditAccountCss();
    }
    else if (selectedval == "View") {
      this.isSharing = false;
      this.action = selectedval;
      this.showAddEditAccount = true;
      this.showAddEditAccountCss();
    }
    else {
      this.isSharing = true;
      this.rightMainDivHideShow = false;
      this.accountSharingid = 1;
      this.AddContacttableDiv = false;
      this.ownrshipComp.loadAccountInformation(this.account.id, 0);
    }
    //}

  }
  showAddEditAccountCss() {
    setTimeout(function () {
      $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  showAddEditContactCss() {
    setTimeout(function () {
      $('#addEditContact .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
    }, 4)
  }
  ReceiveEvent(msg) {
    if (msg == "ExpandSoftwareLicense" || msg == "CollapseSoftwareLicense") {
      this.ExpandAddEditAccountDialog(msg);
    } else {
    }
  }

  ExpandAddEditAccountDialog(msg) {
    if (msg == 'ExpandSoftwareLicense') {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 100%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 86%;padding: 0;top: 10%;left: 10%;max-width: 88%;');
      }, 4)
    } else {
      setTimeout(function () {
        $('#addEditAccount .dialog').attr('style', 'width: 44%;padding-bottom: 0px;min-height: 63%;overflow: auto;height: 82%;padding: 0;top: 10%;left: 54%;');
      }, 4)
    }
  }

  ContactReceiveEvent(msg) {
    this.showAddEditContact = false;
    //this.GetAllProviderAndContactInfo(this.account.id)
  }
  btnClickReceived(event) {
    if (event.btnName == 'addContact') {
      this.addContact();
    }
    else if (event.btnName == 'Accountsharing') {
      this.AccountsharingAg(event.val);
    }
    else if (event.btnName == 'openNotes') {
      this.openAccountNotes();
    } else if (event.btnName == 'AccountInfo') {
      this.AccountInfo(event.val);
    }
  }

  AccountInfo(accountid) {
    this.router.navigateByUrl(`/application/account/Accounts`);
    this.applicationComponent.ExpandSelectedMenu('Accounts');
    $(".subButtons").removeClass("activeButton");
    localStorage.setItem('RhxAccountVal', accountid);
  }

  ExpandTicketDeatils(val) {
    if (val == 'Collapse') {
      this.TicketDeatilsExpand = false;
      this.CloseTicketHistory();

      var selfObj = this;
      $(setTimeout(function () {
        selfObj.ticketgridOptions.api.forEachNode((node) => {
          if (node.data.ticketId == selfObj.ticketdata.ticketId) {
            selfObj.ticketgridOptions.api.selectNode(node, true);
          }
        });
      }, 500));
    } else {
      this.TicketDeatilsExpand = true;
    }
    
    
  }
  ExpandPrvNotesDetails(val) {
    if (val == 'Collapse') {
      this.PreviousNotesExpand = false;      
    } else {
      this.PreviousNotesExpand = true;
    }


  }
  onAccountMessageReceived(msg) {
    if (msg == 'AccountView') {
      localStorage.setItem('RhxContactVal', (this.ticketdata.contactid).toString());

      let SO = this;
      $(setTimeout(function () {
        SO.applicationComponent.activateSubButtons('empty');
        $(setTimeout(function () {

          SO.router.navigateByUrl(`/application/contacts/contacts/null`);
          SO.applicationComponent.ExpandSelectedMenu('contact');
          SO.applicationComponent.selectedtabid = 'contact';
        }, 100));
      }, 50));
    }
  }
  FilterByDays(val) {
    localStorage.setItem('TicketAgingId', val);
    switch (val) {
      case '0':
        this.Userlist = this.OriginalUserList;
        break;
        case '30':
          this.Userlist = this.OriginalUserList.filter((cust) => cust.NoOfDays <= 30);
          break;
        case '31-60':
          this.Userlist = this.OriginalUserList.filter((cust) => cust.NoOfDays > 30 && cust.NoOfDays <= 60);
          break;
        case '61-90':
          this.Userlist = this.OriginalUserList.filter((cust) => cust.NoOfDays > 60 && cust.NoOfDays <= 90);
          break;
        case '91-120':
          this.Userlist = this.OriginalUserList.filter((cust) => cust.NoOfDays > 90 && cust.NoOfDays <= 120);
          break;
        case '120+':
          this.Userlist = this.OriginalUserList.filter((cust) => cust.NoOfDays > 120);
          break;
        case 'DisplayAll':
          this.Userlist = this.OriginalUserList;
          break;

      }
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
    this.gridOptions.quickFilterText = (document.getElementById('filter-text-box') as HTMLInputElement).value;
    this.createColumnDefs(this.colSelections);
    this.gridApi.redrawRows(); 
  }
  ClearSearch() {
    this.gridApi.setQuickFilter('');
    this.value = "";
    this.EnableSearch = false;
    this.gridOptions.quickFilterText = "";
    this.createColumnDefs(this.colSelections);
    this.gridApi.redrawRows(); 
  }
}
