<div class="wrapper" >
  <header class="main-header"></header>
  <script src="/js/jquery.balloon.js"></script>


  <div class="content-wrapper" style="margin-left:10% !important" >
    <div class="container">
      <form>
        <ng4-loading-spinner [threshold]="2000" [timeout]="8000"  [loadingText]="'Please wait...'" [zIndex]="9999"></ng4-loading-spinner>
        <div class="card card-container" style="text-align:center">
          <img alt="Home" class="header__logo-mage" src="../../assets/img/SalesXCRM Logo with New Slogan.png" style="width: 100%">
          <p id="profile-name" class="profile-name-card"></p>
          <span id="reauth-email" class="reauth-email" style="color:red;white-space: normal;"></span>
          <div *ngIf="tab=='Login'" style="margin-top:23%;width:90%;padding-left:14%;margin-bottom:17%">
            <input type="text" class="form-control" name="username" data-toggle="tooltip" [(ngModel)]="Formdata.username" placeholder="Email address" />
            <div (capsLock)="capsOn=$event">
              <input type="password" class="form-control" id="Passwords" [(ngModel)]="Formdata.password" name="password"
                     style="margin-top:5%" placeholder="Password"><span id="passwordEye" class="fa fa-fw fa-eye field-icon toggle-password pull-left" (click)="showhide($event)" style="float: right !important; margin-top: -22px"></span>

              <div *ngIf="capsOn" class="mdfont" style="color: red; margin-top: 3%;">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <span>Caps Lock is on</span>
              </div>
            </div>

            <button class="btn btn-lg coustomButton btn-block btn-signin" style="margin-top:5%;margin-bottom: 9%;" (click)="submitData()" (keydown.enter)="submitData()" (keyup.enter)="submitData()" id="submit" autofocus>Submit</button>
            <a name="forgotpwd" (click)="ForgotOrResetpassword('Forgot')" style="margin-top:5%;margin-bottom: 5%; color:#655d5d"><b style="color: rgb(186,219,152)"> Forgot Password  </b></a>
          </div>




          <div *ngIf="tab=='Forgot'">
            <label id="retry">
              Enter your email address and we will send <br />
              you a link to reset your password
            </label>
            <input type="text" class="form-control" name="username" [(ngModel)]="Formdata.username" placeholder="Email address" />
            <button class="btn btn-lg coustomButton btn-block btn-signin" style="margin-top:5%;margin-bottom: 5%;" (click)="CheckUserExist()">RESET PASSWORD</button>
            <a (click)="ForgotOrResetpassword('Login')" name="forgotpwd" style="margin-top:5%;margin-bottom: 5%; color:#655d5d"><b> Nevermind, </b><b style="text-decoration: underline;color:green"> take me back to login </b></a>
          </div>
          <div *ngIf="tab=='Sent'">
            <label>
              Your email was CONFIRMED
            </label>
            <label>
              A Link to reset your password has been send to your email
            </label>
            <button class="btn btn-lg coustomButton btn-block btn-signin" style="margin-top:5%;margin-bottom: 5%;" (click)="ForgotOrResetpassword('Login')">RETURN TO LOGIN</button>
          </div>
          <span style="color:gray;margin-top:2%">SALESXCRM.COM | © 2018- {{currentYear}} | All Rights Reserved</span>
        </div>
      </form>
    </div>
    <router-outlet></router-outlet>
  </div>
  <footer class="main-footer" style="margin-left: 40%;color:gray;margin-top:2%"></footer>
</div>

