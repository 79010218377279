<div class="col-xs-12" style="padding: 0; padding-top: 0.5%;">
  <div class="col-md-3 col-sm-6 col-xs-12" style="padding: 0;" id="backgroundOverley">
    <div [ngClass]="(DropDownListBox)? 'col-md-12' : 'col-md-12'" style="padding:0;">

      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 0%; " [ngStyle]="{'margin-top.%': 0,'width.%':100 }">
        <angular2-multiselect [data]="dropdownList" [(ngModel)]="applicationComponent.selectedItems" id="UserList"
                              [settings]="dropdownSetting"
                              (onSelect)="onItemSelect($event)"
                              (onDeSelect)="OnItemDeSelect($event)"
                              (onSelectAll)="onSelectAll($event)"
                              (onDeSelectAll)="onDeSelectAll($event)">
        </angular2-multiselect>
      </div>
    </div>
  </div>
  <div *ngIf="parentComp == 'Campaigns'" class="col-xs-2" style="width: 25%;padding: 0 0 0 1%; ">

    <angular2-multiselect [data]="secondDropdownList" id="SecondDrp" [(ngModel)]="SecondDrpModel"
                          [settings]="SingleSecondDrpSetting"
                          (onSelect)="onSecondDrpItemSelect($event)"
                          (onDeSelect)="OnSecondDrpItemDeSelect($event)"
                          (onSelectAll)="onSecondDrpItemSelectAll($event)"
                          (onDeSelectAll)="OnSecondDrpItemDeSelectAll($event)">
    </angular2-multiselect>
  </div>

  <div *ngIf="parentComp != 'Campaigns'" class="col-md-8 col-sm-8 col-xs-11"
       style="padding:  0;width:54%;height:100%"
       [style.width.%]="(parentComp=='Accounts' ||parentComp=='Lead' || parentComp=='Customer' || parentComp == 'Contact' || parentComp == 'CustContacts'|| parentComp == 'Tickets' || parentComp == 'Projects' || type == 'Customer'   )? '66':'52'">
    <div class="col-xs-12 removeCompletePadding" style="margin-top: 0%;padding: 0;height:100%">
      <div *ngIf="parentComp=='Accounts' || parentComp=='Customer' ||  parentComp == 'Projects' || parentComp == 'Contact' || (this.parentComp == 'CustContacts')  ||parentComp=='Forecasting'" class="col-xs-2" style="padding: 0 0 0 1%" [ngStyle]="{'width.%':(parentComp=='Forecasting') ? 32:25}">

        <div class="col-xs-12" style="padding: 0%;height:100%;">
          <angular2-multiselect [data]="thirdDropdownList" [(ngModel)]="ThirdDrpModel"
                                [settings]="SingleThirdDrpSetting"
                                (onSelect)="onThirdDrpItemSelect($event)"
                                (onDeSelect)="OnThirdDrpItemDeSelect($event)"
                                (onDeSelectAll)="OnThirdDrpDeSelectAll($event)">
          </angular2-multiselect>
        </div>
      </div>
      <div *ngIf="parentComp=='Accounts' || parentComp=='Customer' || parentComp == 'CustContacts' || parentComp == 'Contact' || (parentComp == 'Reports' && fourthDrpValue == 'User Activity(Details)') " class="col-xs-2" style="padding: 0 0 0 1%;width:23%;">

        <angular2-multiselect [data]="fifthDropdownList" [(ngModel)]="FifthDrpModel"
                              [settings]="SingleFifthDrpSetting"
                              (onSelect)="onFifthDrpItemSelect($event)"
                              (onDeSelect)="OnFifthDrpItemDeSelect($event)"
                              (onDeSelectAll)="OnFifthDrpItemDeSelectAll($event)">
        </angular2-multiselect>
      </div>
      <div *ngIf=" parentComp=='Accounts' || parentComp=='Customer' || parentComp == 'Contact' || (this.parentComp == 'CustContacts')" class="col-xs-2" style="padding: 0 0 0 1%;width:27%;">
        <angular2-multiselect [data]="fourthDropdownList" [(ngModel)]="FourthDrpModel" id="fourthDropdownList"
                              [settings]="SingleFourthDrpSetting"
                              (onSelect)="onFourthDrpItemSelect($event)"
                              (onDeSelect)="OnFourthDrpItemDeSelect($event)"
                              (onDeSelectAll)="OnFourthDrpDeSelectAll($event)">
        </angular2-multiselect>
      </div>
      <div *ngIf="parentComp == 'Reports' || parentComp == 'AdminReports'" class="col-xs-2" style="padding: 0 0 0 1%;width:27%;">
        <angular2-multiselect [data]="thirdDropdownList" [(ngModel)]="ThirdDrpModel"
                              [settings]="SingleThirdDrpSetting"
                              (onSelect)="onThirdDrpItemSelectReport($event)"
                              (onDeSelect)="OnThirdDrpItemDeSelectReport($event)"
                              (onDeSelectAll)="OnThirdDrpDeSelectAll($event)">
        </angular2-multiselect>
      </div>
      <div *ngIf="parentComp=='CustContacts' || parentComp=='Contact' || parentComp=='Customer' || parentComp=='Accounts' ||  parentComp == 'Lead' || parentComp == 'Proposal' || parentComp=='Opportunities' || parentComp == 'TaskList' || parentComp == 'Calendar' || parentComp=='Ticket' || parentComp == 'CallList' || parentComp == 'rhx' || parentComp == 'Profile' || parentComp=='ViewProject' || parentComp == 'Projects' || parentComp == 'Campaigns' ||  parentComp=='Tickets' " class="col-xs-2" [ngStyle]="{'width':parentComp == 'Calendar' ? '45%' : '25%' }" style="padding: 0 0 0 1%;">

        <angular2-multiselect [data]="secondDropdownList" id="SecondDrp" [(ngModel)]="SecondDrpModel"
                              [settings]="SingleSecondDrpSetting"
                              (onSelect)="onSecondDrpItemSelect($event)"
                              (onDeSelect)="OnSecondDrpItemDeSelect($event)"
                              (onSelectAll)="onSecondDrpItemSelectAll($event)"
                              (onDeSelectAll)="OnSecondDrpItemDeSelectAll($event)">
        </angular2-multiselect>
      </div>

      <div *ngIf=" parentComp=='Tickets'" class="col-xs-2" style="padding: 0 0 0 1%;width:25%;">
        <angular2-multiselect [data]="SeventhDropdownList" [(ngModel)]="SeventhDrpModel"
                              [settings]="SingleSeventhDrpSetting"
                              (onSelect)="onSeventhDrpItemSelect($event)"
                              (onDeSelect)="OnSeventhDrpItemDeSelect($event)"
                              (onSelectAll)="onSeventhDrpItemSelectAll($event)"
                              (onDeSelectAll)="OnSeventhDrpItemDeSelectAll($event)">
        </angular2-multiselect>

      </div>
      <div *ngIf=" parentComp=='Tickets'" class="col-xs-2" style="padding: 0 0 0 1%;width:25%;">
        <angular2-multiselect [data]="eightDropdownList" [(ngModel)]="eightDrpModel"
                              [settings]="eightDrpSetting"
                              (onSelect)="oneightDrpItemSelect($event)">
        </angular2-multiselect>
      </div>
      <div class="col-xs-1" style="padding: 0 0 0 4%;width:6%;" *ngIf="parentComp == 'Calendar'">
        <img src="../../../assets/img/Scheduler List View ON.png" *ngIf="!CalenderListView" (click)="ShowCalenderListView('ListView')" style=" height: 31px;" />
        <img src="../../../assets/img/Calendar Black.png" *ngIf="CalenderListView" (click)="ShowCalenderListView('BlockView')" style=" height: 31px;" />
      </div>

      <div *ngIf="parentComp=='Reports' || parentComp == 'AdminReports'" class="col-xs-2" style="padding: 0 0 0 1%;width:25%;">
        <div class="col-xs-12" style="padding: 0%;height:100%;">
          <angular2-multiselect [data]="fourthDropdownList" [(ngModel)]="FourthDrpModel" id="fourthDropdownList"
                                [settings]="SingleFourthDrpSetting"
                                (onSelect)="onFourthDrpItemSelect($event)"
                                (onDeSelect)="OnFourthDrpItemDeSelect($event)"
                                (onDeSelectAll)="OnFourthDrpDeSelectAll($event)">
          </angular2-multiselect>

        </div>
      </div>
      <div *ngIf="parentComp=='AdminReports' && (FourthDrpModel[0].itemName=='Contacts(SaaS by Date)' ||FourthDrpModel[0].itemName=='Contacts(SaaS Renewals)'
           ||FourthDrpModel[0].itemName=='Customer Contacts (LK)'
           ||FourthDrpModel[0].itemName=='Contacts(SaaS RCM)')"
           class="col-xs-2" style="padding: 0 0 0 1%;width:30%;">
        <angular2-multiselect [data]="fifthDropdownList" [(ngModel)]="FifthDrpModel"
                              [settings]="SingleFifthDrpSetting"
                              (onSelect)="onFifthDrpItemSelect($event)"
                              (onDeSelect)="OnFifthDrpItemDeSelect($event)"
                              (onDeSelectAll)="OnFifthDrpItemDeSelectAll($event)">
        </angular2-multiselect>
      </div>
    </div>
  </div>

  <div *ngIf="parentComp !=='Projects' && parentComp !== 'Profile' && parentComp !== 'PendedProposal'&& parentComp !== 'Customer' && parentComp !== 'Accounts' && parentComp !== 'Contact' && parentComp !== 'CustContacts' && parentComp !== 'Tickets'&& parentComp !== 'Lead' && parentComp !== 'Forecasting' && parentComp !== 'ViewProject' " class="col-md-3 col-sm-6 col-xs-12" style="padding: 0;width:23%;">
    <div class="col-xs-2" style="text-align:right;padding:0;padding-right:2%;width:18%">
      <button *ngIf="calanderonoff" (click)="calanderclick(false)" class="" style="padding:0;height:28px;background:white;">
        <img src="../../assets/img/range on.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
      </button>
      <button *ngIf="!calanderonoff" (click)="calanderclick(true)" class="" style="padding:0;height:28px;background:white;">
        <img src="../../assets/img/range off.png" style="height:45px;margin-top: -19%;padding-right: 8%;" />
      </button>
    </div>

    <div class="col-xs-3" style="padding: 0;width:40%">
      <my-date-picker id="startDate" name="startDate" [options]="startDatePickerOptions" [placeholder]="startDatePlaceHolder" (dateChanged)="dateChange($event)" style="border-left:none !important" required [(ngModel)]="startDateVal" formatWeekDay="{nameOfDay => nameOfDay.substr(0,1)}"></my-date-picker>
    </div>
    <div class="col-xs-3" style="padding: 0;    padding-left: 0.5%;width:40%">
      <my-date-picker name="endDate" [options]="endDatePickerOptions" [placeholder]="endDatePlaceHolder" (dateChanged)="dateChange($event)" style="border-left:none !important" required [(ngModel)]="endDateVal"></my-date-picker>
    </div>
    <!--<div *ngIf="(parentComp !=='crm' && parentComp !=='rhx' &&  parentComp !=='Campaigns'
         && parentComp !=='CallList' && parentComp !=='Reports' && parentComp !=='Calendar'&& parentComp !=='AdminReports')" class="col-xs-1" style="padding: 0;">-->
    <!--<img (click)="Search(true)" src="../../assets/img/Search Page Icon.png" style="height: 32px;"/>-->
    <!--</div>-->

  </div>
  <div *ngIf="(parentComp!='Campaigns' && (parentComp ==='CustContacts' ||type == 'Customer'|| type == 'CustContacts'))" class="col-md-1 col-sm-6 col-xs-12" style="padding: 0;">

    <div class="col-xs-4">
      <img *ngIf="isDisplayAccount" (click)="Displaydata('Accounts')" src="../../assets/img/Accounts Unslected.png" style="height: 32px;" />
      <img *ngIf="!isDisplayAccount" src="../../assets/img/Accounts Selected.png" style="height: 32px; " />
    </div>
    <div class="col-xs-4">
      <img *ngIf="isDisplayAccount" src="../../assets/img/Contacts Selected.png" style="height: 32px; " />
      <img *ngIf="!isDisplayAccount" (click)="Displaydata('Contacts')" src="../../assets/img/Contacts Unslected.png" style="height: 32px;" />
    </div>
    <div class="col-xs-4">
      <!--<img (click)="Search(true)" src="../../assets/img/Search Page Icon.png" style="height: 32px;" />-->
    </div>
  </div>

  <div *ngIf="(parentComp=='Accounts' ||parentComp=='Contact'||parentComp=='Lead'||parentComp=='Tickets' ||parentComp=='Projects')" class="col-md-1 col-sm-6 col-xs-12" style="padding: 0;">
    <div class="col-xs-6">
      <!--<img (click)="Search(true)" src="../../assets/img/Search Page Icon.png" style="height: 32px; padding-left: 82px" />-->
    </div>

  </div>
</div>

<app-dialog [(visible)]="SaveUserListConfirmation" *ngIf="SaveUserListConfirmation" id="SaveUserListConfirmation">
  <div class="col-xs-12" style="padding:5%">
    <div class="col-xs-12" style="padding: 0; font-weight: 900; font-size: 15px;">SalesXCRM Message</div>
    <div class="col-xs-12" style="padding: 0; padding-top: 6%; font-size: 15px;">
      <div class="col-xs-12" style="padding: 0; font-weight: bold; text-transform: uppercase ">User List</div><br />
       <div class="col-xs-12" style="padding: 0; padding-top: 3%;">Save Changes to User List</div>
    </div>
    <div class="col-xs-12" style="padding: 0; margin-top: 9%;">
      <div class="col-xs-7" style="padding: 0;">

      </div>
      <div class="col-xs-5" style="padding:0;">
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float:right;background-color: #0098f7 !important;color: white;margin-top:1%"
                  (click)="SaveConfirm('Yes')">
            OK
          </button>
        </div>
       
        <div class="col-xs-6" style="padding:0">
          <button class="btn" style="float: right; background-color: white !important; color: black; margin-top: 1%; box-shadow: 1px 1px 1px 1px antiquewhite;
        padding-left: 5%; color: #2ed1d1 " (click)="SaveConfirm('No')">Cancel</button>
        </div>
      </div>
    </div>
  </div>

</app-dialog>
