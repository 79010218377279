export class Accounts {
    public id: number;
    public customertypeid?: number;
    public country: string;
    public accounttype: string;
    public accountname: string;   
    public address1: string;
  public address2: string;
  public lattitude: string;
  public longtitude: String;
    public city: string;
    public state: string;
    public zip: string;
  public phone: string;
  public ProvidenceIsland: string;
    public fax: string;
    public alterphone1: string;
  public owner: string;
  public OWNER: string;// Both Required
  public newowner: string;
    public alterphone2: string;
    public website: string;
    public ownerid?: number;
    public multiplelocations?: number;
    public practiceid?: number;
  public accountStatus: number;
    public speciality: string;
  public specialityName: string;
    public practice: string;
    public email: string;
    public userid?: number;
    public ownership: string;
    public accnumber: string;
    public ehr: string;
    public ehrName: string;
  public ehrdatepurchased: string;
  public isCustomer: number;
  public CustomerSince: string;
    public pm: string;
    public pmName: string;
    public pmdatepurchased: string;
    public rcm: string;
    public rcmName: string;
    public rcmdatepurchased: string;
    public percentage: string;
  public OpenOppTotal: string;
  public isimported?: number;
  public totalcount: number;
  public CurrentPage: number;
  public PageSize: number;
  public TotalPages: number;
  public Total: number;
  public TaskTotal: number;
  public LeadOrOptTotal: number;
  public OpportunitiesTotal: number;
  public isshared: number;
  public isAccountPermission: string;
  public islimitedSearch: boolean;
  public isaddressadded: boolean;
  public SearchVal: string;
  public Accountiddata: string;
  public created: string;
  public locationType: string;
  public Salesrep: number;
  //public accountid3: string;
  public accountpopid1: string;
  public accountpopid2: string;
  public accountpopid3: string;
  public accountid123: string;
  public mapit: number;
  public customertype: string;
  public accounttypeName: string;
  public ownershipName: string;
  public callisttype: string;
  public source: string;
  public addressName: string;
  public addressID: number;
  public fieldvalues: string;
  public LocationPicture: string;
  accounlocation: Accountlocations[];
  public specialNotes: string;
  public salesRep: number;
  public SalesRepName: string;
  public countryname: string;
  public statusName: string;
  public SystemType: number;
  public HL7Interface: number;
  public CustomerRCM: number;
  public OnDemand: number;
  public ApptReminders: number;
  public eLab: number;
  public MIPS: number;
  public SystemTypeName: string;
  public HL7interfaceName: string;
  public CustomerRCMName: string;
  public onDemandName: string;
  public RemainderName: string;
  public eLabName: string;
  public MIPSName: string;
}
export class Accountlocations {
  public id: number;
  public accountid: number;
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public zip: string;
  public phone: string;
  public fax: string;
  public alterphone1: string;
  public alterphone2: string;
  public LocationPicture: string;
  public addressType: string;
}

export class AccountTasksCounts {
  public accountid: number;
  public TotalOpportunities: number;
  public TotalLeads: number;
  public TotalPendProposals: number;
  public TotalTasks: number;
  public TotalTickets: number;
  

}

