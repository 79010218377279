<div class="col-xs-12" style="padding:0;height:100%">
  <ag-grid-angular style="height:105%" class="ag-theme-balham"
                   [gridOptions]="gridProposalOptions"
                   [columnDefs]="columnDefsForItems"
                   [defaultColDef]="applicationComponent.defaultGridColDef"
                   [rowData]="allProposalItems"
                   [context]="context"
                   [sideBar]="sideBar"
                   [cacheQuickFilter]="true"
                   [frameworkComponents]="frameworkComponents"
                   (gridReady)="onAgGridReady($event)">

  </ag-grid-angular>
</div>
