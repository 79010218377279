<div class="container" style="padding-left:0% !important;padding-right: 0;height:100%;">
  <div *ngIf="this.type=='Customer'" style="height:100%">
    <contactsof-customer [id]="contactid" (sendMsgEvent)="onCallHistory($event)"></contactsof-customer>
  </div>
  <div  *ngIf="this.type!='Customer'" style="height:98%">
    <div class="col-xs-12 provider" style="text-align:center;margin-left: 0%;width: 98%;padding: 0;margin-left:1.5%;height:18%">
      <div class="col-xs-4" style="text-align:left;padding:0;">
        <div><b class="providerLabel" style="font-size:14px;color:red">{{account.accountname}}</b></div>
        <div class="providerLabel" style="font-size:12px;font-weight:normal">{{account.address1}}&nbsp;</div>
        <div style="font-size:12px;font-weight:normal">{{account.address2}}</div>
        <div style="font-size:12px;font-weight:normal">
          <span *ngIf="account.country=='1'">{{account.city}} , {{account.state}}.  {{account.zip | slice:0:5}}<span *ngIf="account.zip && account.zip.length > 5">-{{account.zip | slice:5:20}}</span>,&nbsp;{{account.country}}</span>
        </div>
      </div>
      <div class="col-xs-4" style="padding:0;">
        <h3>Call List History ({{itemCount}})</h3>
      </div>
      <div class="col-xs-4" style="text-align:right;padding:0;">
        <div style="text-align:center"><b>Contact:</b> {{contact.firstname}} {{contact.middlename}} {{contact.lastname}} {{(contact.titleName)? ', '+contact.titleName:'' }}</div>       
          <button *ngIf="type =='CalllHistory'" (click)="Backtocontacts()">Back to contacts</button>
      </div>

    </div>

    <div class="col-xs-12" style="height:80%">
      <ag-grid-angular id="grid_call" style="width: 99%;height:100%;padding-top:1%" class="ag-theme-balham"
                       [gridOptions]="gridOptions"
                       [columnDefs]="columnDefs"
                       [defaultColDef]="applicationComponent.defaultGridColDef"
                       [rowData]="rowData"
                       [context]="context"
                       [sideBar]="sideBar"
                       [suppressDragLeaveHidesColumns]="true"
                       (columnVisible)="columnVisible($event)"
                       (dragStopped)="dragStopped($event)"
                       (columnResized)="columnResized($event)"
                       (gridReady)="onAgGridReady($event)">
      </ag-grid-angular>

      <div class="col-xs-12" id="NoCallHistory" style="background-color:#f3f3f3;height:300px; "> 
        <h1 style="text-align: center;padding-top:10%"> No Call List History Available for this Contact</h1>
      </div>
    </div>
  </div>
</div>
