<div class="col-xs-12" style="padding: 0%; height: 99%; text-transform: uppercase;">
 
  <div class="col-xs-12" style="padding: 0 0 0 1%; height: 6%" [style.font-size.px]="!documentview ? '14' :'12'">
  <userdataheader [parentComp]="'crm'" [selectedItems]="selectedItems" (outPropsEvent)="UserlistDataReceived($event)"> </userdataheader>
 </div>

  <div class="col-xs-12" style="padding: 0 0 0 1%; height: 94%;">
    <div *ngIf="!documentview &&!uploadDocumentShow" [ngClass]="!documentview ? 'col-xs-4' :''" style="padding:0;height:100%;display:none !important">
      <div class="col-xs-12" style="margin-bottom: 0%;padding: 0;padding-left: 0.5%;text-align: center;background-color: #2A3740;color: white;padding-top: 1%;border: 1px solid #DCDCDC; width:99.7%">
        <label style="padding-left: 14pt;">My SalesXCRM - User privileges</label><br />
      </div>
      <div class="col-xs-12" id="Opptdetails" style="padding-left: 0% !important;padding-right:0;height:87%;border:1px solid #dddddd;margin-top:1%">
        <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top: 1%;">
          <div class="col-xs-5" style="padding-right:0;padding-left:0;font-weight:700">
            <b>User Name:</b>
          </div>
          <div class="col-xs-7" style="padding-left:0;">
            {{user.username}}
          </div>
        </div>

        <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top: 1%;">
          <div class="col-xs-5" style="padding-right:0;padding-left:0;font-weight:700">
            <b>My SalesXCRM (P-Level) :</b>
          </div>
          <div class="col-xs-7" style="padding-left:0;">
            E8
          </div>
        </div>

        <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top: 1%;">
          <div class="col-xs-5" style="padding-right:0;padding-left:0;font-weight:700">
            <b>User Status :</b>
          </div>
          <div class="col-xs-7" style="padding-left:0;">
            {{user.UserStatus}}
          </div>
        </div>

        <div class="col-xs-12 removeCompletePadding" style="padding:0;margin-top: 1%;">
          <div class="col-xs-5" style="padding-right:0;padding-left:0;font-weight:700">
            <b>User Access To:</b>
          </div>
          <div class="col-xs-7" style="padding-left:0;border: 2px solid; height: 100%;overflow-y: auto">
            <div *ngFor="let selecteduser of user.selectedItems">
              <label style="margin-left: 5%">{{selecteduser.itemName}} {{selecteduser.category}}</label>
            </div>

          </div>
        </div>

      </div>
    </div>
    <div id="window" class="col-xs-12" style="padding:0px;height:99%;margin-top:0.5%">
     

      <div class="col-xs-12" style="padding:0;height:26%">
        <div class="col-xs-3" style="padding:0;">
          <div class="tabset" style="width:100%;margin-left:0.7%;height:10%;float:right" [style.font-size.px]="!documentview ? '14' :'12'">
            <label class="TablabelClass" for="CRMtab{{Tab.ChartTabId}}" id="CRMtabLabel{{Tab.ChartTabId}}" style="text-align:center;color:black;width:50%;height:49px" (click)="ReportChanged(Tab.ChartTabId)" *ngFor="let Tab of ChartTabs">
              <input type="radio" name="tabset" id="CRMtab{{Tab.ChartTabId}}" value="CRMtab{{Tab.ChartTabId}}" [(ngModel)]="selectedMenuRadio" aria-controls="marzen" style="border: none !important; color: transparent; background: transparent;visibility:hidden" />
              {{Tab.ChartTabDescription}}
            </label>
          </div>
        </div>
        <div *ngIf="uploadDocumentShow" style="height:100%;padding:0;" class="col-xs-9">
          <upload-document [selectedtype]="'MySalesXCRM'" [selectedMenuRadio]="selectedMenuRadio" [selectedMenunumber]="selectedMenunumber" (sendMsgEvent)="SavedDocuments($event)"> </upload-document>
        </div>
        <div class="col-xs-9" *ngIf="!uploadDocumentShow" style="height: 100%;padding-right:0.2%;">
          <div [ngClass]="documentview ? 'col-xs-6' :'col-xs-12'" style="padding: 0; height: 6%;" [style.padding-bottom.%]="documentview ? '2.5':'2.5' ">

            <div class="col-xs-1" style="padding:0;width:3%" [style.width.%]="documentview ? '5':'3%' ">
              <div style="text-align:left">
                <span><img (click)="AddDocuments('New')" *ngIf="documentcount==0" src="../../assets/img/Add Campaign.png" style="height:22px;" /></span>
                <span><img (click)="AddDocuments('Open')" *ngIf="documentcount>0" src="../../assets/img/open document.png" style="height:22px;" /></span>
              </div>
            </div>
            <div [ngClass]="documentview ? 'col-xs-4' :'col-xs-4'" style="padding:0;" [style.width.%]="documentview ? '50':'33' ">
              <label style="font-size:14px">My Documents</label>
            </div>

          </div>
          <div *ngIf="documentview" class="col-xs-6">
            <div class="col-xs-4" style="padding:0%">
              <b> <img src="../../../assets/img/Black x.png" style="height:20px" (click)="closeEmbed()" /></b>
            </div>
            <div class="col-xs-1">
              <i *ngIf="documentindex > 0" class="fa fa-arrow-left " aria-hidden="true" id="previous" (click)="previousdocument()"></i>
            </div>
            <div class="col-xs-3"> <label style="color: black;font-size: 12px;">{{documentindex+1}} of {{userdocumentcount}} (Documents)</label></div>
            <div class="col-xs-1">
              <i *ngIf="(userdocumentcount > documentindex) && (userdocumentcount != (documentindex + 1) )" class="fa fa-arrow-right " aria-hidden="true" id="next" (click)="nextdocument()"></i>
            </div>
          
          </div>
          <div *ngIf="selectedReportType == 1016" style="height:97%;margin-top:1%">
            <ag-grid-angular style="width: 100%;height:100%" id="gridheight" class="ag-theme-balham"
                             [gridOptions]="gridOptionsUserMessages"
                             [columnDefs]="columnDefsMessage"
                             [defaultColDef]="applicationComponent.defaultGridColDef"
                             [sideBar]="sideBar"
                             [rowData]="allMessages"
                             (selectionChanged)="onUserMessageSelection($event)">
            </ag-grid-angular>
          </div>
          <div [ngClass]="documentview ? 'col-xs-6' :'col-xs-12'">
            <documentmanagement *ngIf="selectedReportType!=1016"
                                [selectedtype]="'SalesXUsers'" (sendMsgEvent)="onOpenUploadDocument($event)">
            </documentmanagement>
          </div>
          <div id="pdfDiv" class="col-xs-6">
          </div>
        </div>

      </div>
    
    </div>



    <div *ngIf="documentview" id="documentview" [ngClass]="documentview ? 'col-xs-6' :'col-xs-12'" style="padding-left:0%">
     
      <div *ngIf="selectedReportType==1016" style="background-color: gray; color: white; height: 680px; margin-left: 5px; padding-left: 25px; padding-top: 69px; ">
        <span [innerHtml]="userMessages.MessageDescription"></span>

      </div>
    </div>
    

  </div>

  <app-dialog [(visible)]="DeleteMessagePopup" *ngIf="DeleteMessagePopup" id="DeleteMessagePopup" [closable]="false">
    <app-delete-popup [status]="contactStatus" [MessageType]="'Message'" (sendMsgEvent)="ReceiveDeleteeventForMessage($event)">
    </app-delete-popup>
  </app-dialog>

</div>

<app-dialog [(visible)]="DocViewAccessDenied" *ngIf="DocViewAccessDenied" id="DocViewAccessDenied">

  <div class="col-xs-12">
    <label style="font-size:19px;font-weight:800">SalesXCRM Message</label> <br />
    <b style="font-size:15px;">Access Denied</b><br />
    <div style="padding:0;margin-top:1%">
      <b style="font-size:15px;">Higher Level of Permission Required</b><br />
    </div>
    <button class="btn" (click)="DocViewAccessDenied = false" style="float:right;background-color: #0098f7 !important;color: white;margin-top:-5%">OK</button>
  </div>

</app-dialog>
