import { Component, OnInit, ViewChild } from '@angular/core';
import { IMyDpOptions } from 'mydatepicker';
import {  GridOptions, Column } from 'ag-grid';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { Dropdowntype } from 'src/app/models/dropdowntype';
import { AuditLogComponent } from '../../audit-log/audit-log.component';
import { CustomerService } from 'src/app/services/customer.service';
import { ApplicationComponent } from '../../application.component';
import { Dashboardconfiguration } from 'src/app/models/dashboardconfiguration';
import { OpprtunitiesService } from 'src/app/services/opprtunities.service';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { AuditLogModel, Auditlog } from '../../../models/auditlog';
import { UserdataheaderComponent } from '../../userdataheader/userdataheader.component';
import { DatePipe } from '@angular/common';
import { SassdateContactsComponent } from '../../sassdate-contacts/sassdate-contacts.component';
import { CustomercontactsComponent } from '../../customercontacts/customercontacts.component';
declare var $: any;
@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.css']
})
export class AdminReportsComponent implements OnInit {
  dropdownList = [];
  selectedItems = [];
  dropdownSetting = {};
  currentuser: any;
  finalitems = "";
  calanderonoff: boolean = true;
  ReportTypeList: Dropdowntype[] = [];
  @ViewChild(AuditLogComponent) Auditlog;
  pageSize: string = "100";
  UserIds: string = "";
  public gridOptions: GridOptions;
  selectedtype: string = "";
  selectedreporttype: string = "Select Admin Reports";
  ExpandTodaysAccess: boolean = false;
  ExpandWeeklyAccess: boolean = false;
  ExpandMonthlyAccess: boolean = false;
  ExpandYearlyAccess: boolean = false;
  TodaysAccessDiv: boolean = true;
  WeeklyAccessDiv: boolean = true;
  MonthlyAccessDiv: boolean = true;
  YearlyAccessDiv: boolean = true;
  CustomDatesAccessDiv: boolean = false;
  ShowCustomDatesDialog: boolean = false;
  DisplayrowsList: Dropdowntype[] = [];
  public barChartOptions: any = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public TodaysColors = [{backgroundColor: 'darkviolet'}];
  public WeeklyColors = [{ backgroundColor: '#23B14D'}];
  public MonthlyColors = [{ backgroundColor: 'orange'}];
  public YearlyColors = [{ backgroundColor: '#4394db'}];
  public CustomColor = [{ backgroundColor: '#4394db'}];
  public barChartLabels = [];
  public barChartWeekLabels = [];
  public barChartMonthLabels = [];
  public barChartYearLabels = [];
  public barChartCustomDateLabels = [];
  public barChartType: string = 'horizontalBar';
  private gridApi; 
  public barChartLegend = true;
  public AuditType = 'Login';
  public columnDefs: any[];
  public barChartData: any[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Hours' }
  ];
  public barChartWeekData: any[];
  public barChartMonthData: any[];
  public barChartYearData: any[];
  public barChartCustomDateData: any[];
  public month = ((new Date().getMonth() + 1) < 10) ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = (new Date().getFullYear() - 1) + '-' + this.month + '-01'; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = 'Start:' + this.month + '-01-' + (new Date().getFullYear() - 1).toString();

  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = new Date().getFullYear() + '-' + this.month + '-' + (new Date().getDate()); //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = 'End:' + this.month + '-' + (new Date().getDate()) + '-' + new Date().getFullYear().toString();
  public pageSizeChanged: boolean = false;
  @ViewChild(UserdataheaderComponent) UDHComp;
  @ViewChild(SassdateContactsComponent) saasdatecmp;
  @ViewChild(CustomercontactsComponent) custcontactcmp;
  loggedinUserlist: Array<Auditlog> = [];
  datePipe: DatePipe = new DatePipe("en-US");
  constructor(private customerService: CustomerService, private applicationComponent: ApplicationComponent, private toastr: ToastrService,
    private route: ActivatedRoute, private opprtunityservice: OpprtunitiesService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationComponent.selectedItems = JSON.parse(localStorage.getItem("SelectedUserIds"));
    if (this.applicationComponent.selectedItems && this.applicationComponent.selectedItems.length > 0) {
      var id = [];
      this.applicationComponent.selectedItems.forEach(ele => {
        id.push(ele.id);
      });
      $("#userids").val(id.join(','));
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.selectedtype = params['type'];
      if (this.selectedtype == "rhx") {
        this.selectedreporttype = 'Audit Log(Admin)';
      } else {
        if (this.selectedtype == 'AuditLogAdmin' || this.selectedtype == 'Audit Log(Admin)') {
          this.selectedreporttype = 'Audit Log(Admin)';
        } else if (this.selectedtype == 'UserAccessAdmin' || this.selectedtype == 'User Access(Admin)') {
          this.selectedreporttype = 'User Access(Admin)';
        } else if (this.selectedtype == 'Users Logged In') {
          this.selectedreporttype = 'Users Logged In';
          this.loginUserListinitializeAgGrid();
          this.LogginUserList();
        }
      }
    });
    this.UserIds = this.currentuser.id;
    this.MultiSelectDropDown();
    this.customerService.GetSpecialityList("ReportType", this.currentuser.id).then((accounttype) => {     
      this.ReportTypeList = accounttype;     
    });
    this.customerService.GetSpecialityList("Rows", this.currentuser.id).then((Rows) => {
      this.DisplayrowsList = Rows;
    });
  }

  loginUserListinitializeAgGrid() {
   
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, paginationPageSize: 1000,toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };    
    this.CreatecolumnDefs();
  }

  LogginUserList() {
    this.customerService.GetAllUersloggedInList().then((userlist) => {     
      this.loggedinUserlist = userlist;
    });
  }
  CreatecolumnDefs() {   
    this.columnDefs = [
      { field: 'username', headerName: 'User', hide: false, width: 200 },      
      { field: 'Time', headerName: 'Logged In', hide: false, width: 200 },
      { field: 'Day', headerName: 'Day', hide: false, width: 200 },
      { field: 'action', headerName: 'Status', hide: false, width: 250 },
      ];
  }

  myFunction() {
    document.getElementById("myDropdown").classList.toggle("show");
  }
  MultiSelectDropDown() {
    this.dropdownSetting = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All Users',
      unSelectAllText: 'UnSelect All Users',
      enableSearchFilter: true,
      badgeShowLimit: 3
    }
    this.customerService.GetAllaccesusersListbyuser(this.currentuser.id,1, 0, 0).then((Owner) => {
      Owner.forEach(Owner => {
      });
      this.dropdownList = Owner;
    });
    if (this.applicationComponent.selectedusers != '' && this.applicationComponent.selectedusers != null && this.applicationComponent.selectedusers != undefined) {
      this.customerService.Getselectedusers(this.applicationComponent.selectedusers).then((Owner) => {
        Owner.forEach(Owner => {

        });
        this.selectedItems = Owner;
      });
      this.Generate($("#userids").val());
    }
    else {
      this.selectedItems = [
        { "id": this.currentuser.id, "itemName": this.currentuser.username }
      ];
      
      this.finalitems += this.currentuser.id + ',';
      this.Generate(this.currentuser.id);
    }
    this.customerService.GetSpecialityList("ReportType", this.currentuser.id).then((Rows) => {
      var value = Rows.filter(x => x.itemName == this.selectedreporttype)[0];
      this.UDHComp.FourthDrpModel = [{ "id": value.id, "itemName": value.itemName }];
    });

  }

  onItemSelect(item: any) {
    var ids = "";
    ids += item.id;
    var names = [];
   
    this.finalitems = $("#userids").val() + ",";
    this.finalitems += ids + ',';
    $("#userids").val(this.finalitems.substring(0, this.finalitems.length - 1).replace('undefined', ""));
    this.Generate($("#userids").val());

  }
  
  Generate(val) {
    val = $("#userids").val();
    this.barChartLabels = [];
    this.barChartWeekLabels = [];
    this.barChartMonthLabels = [];
    this.barChartYearLabels = [];
    this.applicationComponent.selectedusers = val;
    if (this.applicationComponent.selectedusers != '' || this.applicationComponent.selectedusers != null) {     
        this.customerService.GetUserAccessTime(this.applicationComponent.selectedusers, 'Today', this.startDateVal, this.endDateVal).then((Time) => {
          this.barChartLabels = []; var Timedata = [];
          if (Time != null) {
            Time.forEach(element => {
              Timedata.push(element.TimeSpent)
              this.barChartLabels.push(element.userName);
            });
            this.barChartData = [
                { data: Timedata, label: 'Hours' }
              ];
           
          }
         
        });

        this.customerService.GetUserAccessTime(this.applicationComponent.selectedusers, 'Week', this.startDateVal, this.endDateVal).then((weekTime) => {
          var time = [];
          this.barChartWeekLabels = [];
          this.barChartWeekData = [];
          if (weekTime != null) {
            weekTime.forEach(element => {
              this.barChartWeekLabels.push(element.userName)
              time.push(element.TimeSpent)
            });
              this.barChartWeekData = [
                { data: time, label: 'Hours' }
              ];
           
          }
         
        });

        this.customerService.GetUserAccessTime(this.applicationComponent.selectedusers, 'Month', this.startDateVal, this.endDateVal).then((Month) => {
          var timeSpent = []
          this.barChartMonthLabels = []; this.barChartMonthData = [];
          if (Month != null) {
            Month.forEach(element => {
              this.barChartMonthLabels.push(element.userName)
              timeSpent.push(element.TimeSpent)
            });
           
            let so = this;
            $(setTimeout(function () {
              so.barChartMonthData = [
                { data: timeSpent, label: 'Hours' }
              ];
            }, 100));

          }
         
        });

        this.customerService.GetUserAccessTime(this.applicationComponent.selectedusers, 'Year', this.startDateVal, this.endDateVal).then((Year) => {
          var YearTime = []
          this.barChartYearLabels = [];
          this.barChartYearData = [];
          if (Year != null) {
            Year.forEach(element => {
              YearTime.push(element.TimeSpent)
              this.barChartYearLabels.push(element.userName)
            });
           
            let so = this;
            $(setTimeout(function () {
              so.barChartYearData = [
                { data: YearTime, label: 'Hours' }
              ];
            }, 100));

          }         
        });
       // this.customDateData();
     // });

    }
    

  }
  calanderclick(val) {
    this.calanderonoff = val;      
    if (this.calanderonoff == false) {
      if (this.selectedreporttype == 'User Access(Admin)') {
        this.ShowCustomDatesDialog = false;
        this.hideShowDivs(true, true, true, true, false);
      }
      $(setTimeout(function () {
                 
        $('.mydp .selection').attr('style', 'background-color:#f4f4f4 !important;-webkit-text-fill-color:lightgrey');
        $('.mydp .btnpicker').attr('style', 'background-color:#f4f4f4 !important;color:#f4f4f4 !important;');
      }, 200));
    } else {
      if (this.selectedreporttype == 'User Access(Admin)') {
        this.ShowCustomDatesDialog = true;
        this.ShowCustomDatesDialogCss();
      }
      $(setTimeout(function () {
        $('.mydp .selection').attr('style', 'background-color:white !important;');
        $('.mydp .btnpicker').attr('style', 'background-color:white !important;color:black');
      }, 200));
    }
    //if (this.selectedreporttype == 'User Access(Admin)')
    //  this.hideShowDivs(true, true, true, true, false);

  }
  txtPageSizeChanged(pageSize, pageSizeChanged) {
 
      var val = this.Auditlog.audittype;
      if (val == 'Login') {
        this.Auditlog.LoadLoginAuditLog('Login', $("#userids").val(), 1, pageSize, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, pageSizeChanged);
      }
      else if (val == 'Account') {
        this.Auditlog.LoadLoginAuditLog('Account', $("#userids").val(), 1, this.pageSize, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, pageSizeChanged);
      }
      else if (val == 'Contact') {
        this.Auditlog.LoadLoginAuditLog('Contact', $("#userids").val(), 1, this.pageSize, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, pageSizeChanged);
      }
      else if (val == 'Leads') {
        this.Auditlog.LoadLoginAuditLog('Leads', $("#userids").val(), 1, this.pageSize, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, pageSizeChanged);
      }
      else if (val == 'Opportunity') {
        this.Auditlog.LoadLoginAuditLog('Opportunity', $("#userids").val(), 1, this.pageSize, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, pageSizeChanged);
      }
      else if (val == 'TaskList') {
        this.Auditlog.LoadLoginAuditLog('TaskList', $("#userids").val(), 1, this.pageSize, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, pageSizeChanged);
      }
      else if (val == 'InternalMessaging') {
        this.Auditlog.LoadLoginAuditLog('InternalMessaging', $("#userids").val(), 1, this.pageSize, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, pageSizeChanged);
      }
      else if (val == 'Campaigns') {
        //this.Auditlog.LoadLoginAuditLog('Campaigns', 1, pageSize);
        this.Auditlog.LoadLoginAuditLog('Campaigns', $("#userids").val(), 1, this.pageSize, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, pageSizeChanged);
      }
      else if (val == 'All') {
        //this.Auditlog.LoadLoginAuditLog('All', 1, pageSize);
        this.Auditlog.LoadLoginAuditLog('All', $("#userids").val(), 1, this.pageSize, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, pageSizeChanged);
      }

      //else {
      //  return false;
      //}

    this.saveConfiguration();
  }

saveConfiguration() {
  let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
  let config: Dashboardconfiguration = new Dashboardconfiguration();

  if (this.pageSize == "")
    config.maxlimit = 25;
  else
    config.maxlimit = Number(this.pageSize);

  config.columnsselections = "";

  for (let i = 0; i < allCols.length; i++) {
    config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
  }

  if (config.columnsselections.length > 0)
    config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);

  config.configureduserid = this.currentuser.id;
  config.resourceid = 8;

  config.userid = "";
  for (let i = 0; i < this.selectedItems.length; i++) {
    config.userid += this.selectedItems[i].id + ",";
  }

  if (config.userid.length > 0)
    config.userid = config.userid.substr(0, config.userid.length - 1);
  config.startdate = this.startDateVal;
  config.enddate = this.endDateVal;
  this.opprtunityservice.saveDashboardconfiguration(config);

}
  appendLabel(value) {
    if (this.currentuser.UserPrivilegeList != undefined) {
      var button = document.getElementById(value);
      var thisTab = this.currentuser.UserPrivilegeList.filter(p => p.resource == value || p.resource.toLowerCase() == value)[0];
      if (thisTab != undefined && thisTab.canview == false) {
        this.toastr.warning("You Do Not have Access to " + value + " Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
        return false;
      }
    }
    //document.getElementById('selectedName').innerHTML = value;
    this.selectedreporttype = value;
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
  dateChange(event) { 
    
    this.AssignStartAndEndDate(event);
    let userids = $("#userids").val();
    if (this.selectedreporttype == 'Audit Log(Admin)') {
      this.Auditlog.LoadLoginAuditLog('Login', this.UserIds, '1', this.pageSize, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal);
      this.saveConfiguration();
    } else if (this.selectedreporttype == 'User Access(Admin)') {
      this.customDateData();
    }
  }

  AssignStartAndEndDate(event) {
    let dateStr = event.formatted.split(":")[0];
    if (dateStr == "Start")
      this.startDateVal = this.getDateFormatYYYYHMMHDD(event.date);
    else
      this.endDateVal = this.getDateFormatYYYYHMMHDD(event.date);
  }
  getDateFormatYYYYHMMHDD(date): string {
    let year = date.year;
    let month = date.month;
    let day = date.day;

    return year + "-" + ((month < 10) ? '0' + parseInt(month) : month) + "-" + ((day < 10) ? '0' + parseInt(day) : day);
  }
  Expand(val, type) {
    switch (val) {
      case 'TodaysAccess':
        this.ExpandTodaysAccess = !this.ExpandTodaysAccess;
        if (type == 'Expand') {
          this.hideShowDivs(true, false, false, false, false);
        } else if (type == 'Collapse') {
          this.hideShowDivs(true, true, true, true, false);
        }
        break;
      case 'WeeklyAccess':
        this.ExpandWeeklyAccess = !this.ExpandWeeklyAccess;
        if (type == 'Expand') {
          this.hideShowDivs(false, true, false, false, false);
        } else if (type == 'Collapse') {
          this.hideShowDivs(true, true, true, true, false);
        }
        break;
      case 'MonthlyAccess':
        this.ExpandMonthlyAccess = !this.ExpandMonthlyAccess;
        if (type == 'Expand') {
          this.hideShowDivs(false, false, true, false, false);
        } else if (type == 'Collapse') {
          this.hideShowDivs(true, true, true, true, false);
        }
        break;
      case 'YearlyAccess':
        this.ExpandYearlyAccess = !this.ExpandYearlyAccess;
        if (type == 'Expand') {
          this.hideShowDivs(false, false, false, true, false);
        } else if (type == 'Collapse') {
          this.hideShowDivs(true, true, true, true, false);
        }
        break;
      case 'CustomDateAccess':
        if (type == 'Expand') {
          this.customDateData();
          let so = this;
          $(setTimeout(function () {
            so.hideShowDivs(false, false, false, false, true);
            so.ShowCustomDatesDialog = false;

          }, 100));

         

        } else if (type == 'Collapse') {
          this.hideShowDivs(true, true, true, true, false);
          //this.ShowCustomDatesDialog = false;
          this.calanderclick(false);
        }
        break;
      default:
        this.hideShowDivs(true, true, true, true, false);
        break
    }
    if (type == 'Expand') {
      $('#' + val).removeClass('col-xs-6 CollapseCss');
      $('#' + val).addClass('col-xs-12 ExpandCss');
    } else {
      $('#' + val).removeClass('col-xs-12 ExpandCss');
      $('#' + val).addClass('col-xs-6 CollapseCss');
    }
  }

  customDateData() {
  
    var timeSpent=[]
    this.barChartCustomDateLabels = [];
    this.customerService.GetUserAccessTime(this.applicationComponent.selectedusers, 'CustomDates', this.startDateVal, this.endDateVal).then((customdata) => {
      
      if (customdata != null) {
        customdata.forEach(element => {
          this.barChartCustomDateLabels.push(element.userName)
          timeSpent.push((element.TimeSpent == null) ? '0' : element.TimeSpent)
          
        });
        let so = this;
        $(setTimeout(function () {
          so.barChartCustomDateData = [
            { data: timeSpent, label: 'Hours' }
          ];
        }, 100));

      }
      else {
        this.barChartCustomDateLabels = [];
        this.barChartCustomDateData = [];
      }

    });
  }
  hideShowDivs(today: boolean, Weekly: boolean, Monthly: boolean, Yearly: boolean, customDates: boolean) {
    this.TodaysAccessDiv = today;
    this.WeeklyAccessDiv = Weekly;
    this.MonthlyAccessDiv = Monthly;
    this.YearlyAccessDiv = Yearly;
    this.CustomDatesAccessDiv = customDates;
  }
  CloseCustomDatesAccessDiv() {
    this.hideShowDivs(true, true, true, true, false);
  }
  ShowCustomDatesDialogCss() {
    setTimeout(function () {
      $('.dialog').attr('style', 'width: 30%;padding-bottom: 0px;border: 4px solid rgb(49,189,240);border-radius:5%;min-height: 12%;overflow: auto;height: 25%;top: 43%;left: 10%');
    }, 4)
  }
  onAgGridReady(params) {
    this.gridApi = params.api;
  }
  AudittypeReceived(Audittype) {
    this.AuditType = Audittype;
  }
  UserlistDataReceived(outProps: OutputPropsUserList) {
 
    this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
    this.selectedreporttype = outProps.fourthDrpValue == undefined ? this.selectedreporttype : outProps.fourthDrpValue;
    this.startDateVal = (this.calanderonoff) ? this.applicationComponent.SearchstartVal : '2018-01-01';
    this.endDateVal = (this.calanderonoff) ? this.applicationComponent.SearchendVal : this.endDateVal;
    this.pageSizeChanged = outProps.pageSizeChanged;    
    if (outProps.finalitems != "") {
      $("#userids").val(outProps.finalitems);
      if (outProps.fourthDrpValue == "Standard Reports") {
        this.applicationComponent.PopUpMessageType = 'audit';
        this.applicationComponent.SubmenuChange('Reports', 'StandardReports', 'Sub');
      } else if (outProps.fourthDrpValue == "Admin Reports") {
       
        var url = window.location.href;
        if (url.indexOf('admin-reports') > -1) {
          this.selectedreporttype = 'Audit Log(Admin)';
          let SelfObj = this;
          $(setTimeout(function () {
            SelfObj.Auditlog.LoadLoginAuditLog(this.AuditType, $("#userids").val(), '1', SelfObj.pageSize, (SelfObj.calanderonoff) ? SelfObj.startDateVal : '2018-01-01', SelfObj.endDateVal, SelfObj.pageSizeChanged);
          }, 500));

        } else {
          this.applicationComponent.SubmenuChange('Reports', 'AdminReports', 'Sub');
        }

      }
      else if (outProps.fourthDrpValue == "Users Logged In") {
        this.selectedreporttype = 'Users Logged In';
        this.loginUserListinitializeAgGrid();
        this.LogginUserList();
      }
      else if (outProps.fourthDrpValue == "Contacts(SaaS Renewals)" || outProps.fourthDrpValue == "Proposal Items" || outProps.fourthDrpValue == "Contacts(SaaS RCM)") {    
        if (this.saasdatecmp != undefined) {

          this.saasdatecmp.GetsaasdateContactList(outProps.fourthDrpValue, outProps.fifthDrpValue);
        }
      } else if (outProps.fourthDrpValue == "Customer Contacts (LK)") {
        if (this.custcontactcmp != undefined) {
          this.custcontactcmp.GetCUSContactListLK(outProps.fourthDrpValue, outProps.fifthDrpValue);
        }
      }
      else if (outProps.fourthDrpValue == "" || outProps.fourthDrpValue == undefined) { this.selectedreporttype = ''; }
      else {
        this.appendLabel(this.selectedreporttype);
        if (!outProps.pageSizeChanged) {
          this.Generate($("#userids").val());
          if (this.selectedreporttype == 'Audit Log(Admin)') {
            var obj: AuditLogModel = { id: 0, type: 'Admin Reports - ' + this.selectedreporttype, action: 'Admin Reports - ' + this.selectedreporttype, AccountOrContactId: 0, AccountOrContact: outProps.fourthDrpValue, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: outProps.fourthDrpValue, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
            this.applicationComponent.InsertAuditHistory(obj);

            this.Auditlog.LoadLoginAuditLog(this.AuditType, $("#userids").val(), '1', this.pageSize, (this.calanderonoff) ? this.startDateVal : '2018-01-01', this.endDateVal, this.pageSizeChanged);
          } else if (this.selectedreporttype == 'User Access(Admin)') {
            var obj: AuditLogModel = { id: 0, type: 'Admin Reports - ' + this.selectedreporttype, action: 'Admin Reports - ' + this.selectedreporttype, AccountOrContactId: 0, AccountOrContact: outProps.fourthDrpValue, documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: outProps.fourthDrpValue, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
            this.applicationComponent.InsertAuditHistory(obj);

            this.customDateData();
          }
          else if (outProps.fourthDrpValue == "Users Logged In") {
            this.selectedreporttype = 'Users Logged In';
            this.loginUserListinitializeAgGrid();
            this.LogginUserList();
          }
        }
      }
    }
    else {
      this.barChartLabels = [];
      this.barChartData = [];
      this.barChartMonthLabels = [];
      this.barChartMonthData = [];
      this.barChartWeekLabels = [];
      this.barChartWeekData = [];
      this.barChartYearLabels = [];
      this.barChartYearData = [];
      $("#userids").val('');
    }
    this.txtPageSizeChanged(this.pageSize, this.pageSizeChanged);
  }
}
