
<div class="col-xs-12" style="height:99%">
  <div class="col-xs-12" style="padding:0;height:30%"><label style="font-size:19px;font-weight:800">SalesXCRM Message</label></div>
  <div class="col-xs-12" style="padding:0;height:20%">
    <b style="font-size:15px;text-transform:uppercase">Convert Lead</b>
  </div>
  <div class="col-xs-12" style="padding:0;height:50%">
    <div style="padding:0;height:100%" [ngClass]="(type==1)? 'col-xs-9' : 'col-xs-7'">
      <b style="font-size:15px;" *ngIf="type==0">Convert this Contact to a Lead?<br /></b>
      <b style="font-size:15px;" *ngIf="type==1">Contact is an Open Lead  </b><br />

    </div>
    <div  style="padding:0;height:100%"  [ngClass]="(type==1)? 'col-xs-3' : 'col-xs-5'">
      <button class="btn" style="background-color: #0098f7 !important;color: white;margin-top:-5%;margin-left:15%" (click)="convertLead(id)">OK</button>

      <button class="btn" (click)="cancel()" style="float:right;background-color: white !important;color: #0098f7;margin-top:-5%;border: 1px solid lightgrey !important;" *ngIf="type==0">Cancel</button>
    </div>
  </div>
</div>


