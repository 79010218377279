import { Component, OnInit, ViewContainerRef, Optional, Pipe, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DecimalPipe } from "@angular/common";
import { CustomerService } from './../../services/customer.service';
import { OpprtunitiesService } from './../../services/opprtunities.service';
import { GridOptions, ColumnUtils, ColDef, ColumnApi } from "ag-grid/main";
import { Contacts } from '../../models/contacts';
import { Campaign } from '../../models/Campaign';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { IMyDpOptions } from 'mydatepicker';
import { Column, RowNode } from 'ag-grid';
import { Dashboardconfiguration } from '../../models/dashboardconfiguration';
import { ApplicationComponent } from '../application.component';
declare var $: any;
import * as moment from 'moment'
import { AccountsService } from 'src/app/services/accounts.service';
import { OutputPropsUserList } from 'src/app/models/output-props-user-list';
import { LeadsService } from '../../services/leads.service';
import { CampaignExpense, CampaignROI, CampaignType } from '../../models/CampaignConfiguration';
import { ContactsComponent } from '../contacts/contacts.component';
import { ContactsdashboardComponent } from '../contacts/contactsdashboard/contactsdashboard.component';
import { LinkDeleteComponent } from '../link-delete/link-delete.component';
import { Opportunities } from '../../models/opportunities';
import { ProposalsModel } from '../../models/Proposalsmodel';
import { UserService } from '../../services/user.service';
import { AuditLogModel } from '../../models/auditlog';
declare var require: any;
@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent implements OnInit {
  currentuser: any;
  selectedItems = [];
  ShowCampaignGrid: boolean = true;
  @Input()
  AccountcontactIds: string = "";
  public gridOptions: GridOptions;
  public ContactGridOptions: GridOptions;
  public sideBar;
  pageSize: string = "1000";
  colSelections: string;
  public columnDefs: any[];
  public ContactsColumnDefs: any[];
  ShowAddCampaign: boolean = false;
  campaigntypelist: CampaignType[];
  campaignExpenselist: CampaignExpense[];
  campaign: Campaign = new Campaign();
  ShowAddCampaignButtons: boolean = false;
  @Output()
  sendMsgEvent = new EventEmitter<string>();
  ShowContactsDiv: boolean = false;
  @ViewChild(ContactsComponent) contactcomponent;
  @ViewChild(ContactsdashboardComponent) condashcmp: ContactsdashboardComponent;
  conid = 0;
  contactids = [];
  LinkedOpportunityIds = [];
  OpportunityIds = "";
  public editorOptions: Object = { attribution: false, key: 'te1C2sD7C6D6F4D4G4C2kkxvE-11oeA-7mmC5aliyD2I2D1B2C7E2D1C5G1G2==' };
  public startDatePickerOptions: IMyDpOptions = { dateFormat: 'Start: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public startDateVal: any = (new Date().getFullYear() - 1) + '-01-01'; //{ date: { year: parseInt(new Date().getFullYear().toString()), month: 1, day: 1 } };
  public startDatePlaceHolder: string = 'Start: 01-01-' + (new Date().getFullYear() - 1).toString();
  public endDatePickerOptions: IMyDpOptions = { dateFormat: 'End: mm-dd-yyyy', selectionTxtFontSize: '12px', editableDateField: false, showClearDateBtn: false };
  public endDateVal: any = new Date().getFullYear() + '-12-31';  //{ date: { year: 2018, month: 10, day: 9 } }
  public endDatePlaceHolder: string = 'End: 12-31-' + new Date().getFullYear().toString();
  calanderonoff: boolean = true;
  allCampaigns: Campaign[];
  OriginalAllCampaigns: Campaign[];
  AllProposals: ProposalsModel[];

  public gridOptionsSelectedContacts: GridOptions;
  public columnDefsSelectedContacts: any[];

  public frameworkComponents;
  public context;
  LinkedContacts: Contacts[];
  ShowAddButton: boolean = false;
  ShowExpensesDialog: boolean = false;
  ShowROIDialog: boolean = false;
  ProposalsDialog: boolean = false;
  OpportunitiesDialog: boolean = false;
  OpportunityViewType: string="All Opportunities";
  EditCampaignName: boolean = false;
  ExpenseCountindex = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  TotalExpense = 0;
  AllExpenses = [{ ExpenseType: '', ExpenseCost: 0, ExpenseIndex: 0 }];
  CostEntered: boolean = false;
  DeleteExpenseConfirmation: boolean = false;
  selectedExpenseIndex = 0;
  LinkOpportunitiesDialog: boolean = false;
  Allopportunities: Opportunities[];
  Proposalopportunities: Opportunities[];
  SalesWonopportunities: Opportunities[];
  SalesLostopportunities: Opportunities[];
  datePipe: DatePipe = new DatePipe("en-US");
  ROI = new CampaignROI();
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });  
  public ProposalGridOptions: GridOptions;
  public ProposalColumnDefs: any[];
  public pdfview: boolean = false;
  ShowLeftSideDiv: boolean = true;
  DeleteContactConfirmation: boolean = false;
  selectedContectId = 0;

  public TableColumns: any;
  Contactvales: string = "0";
  allContacts: Contacts[];
  private gridApi;
  subtype: string = "contacts";
  Ids = [];
  active_deactiveStatus: number = 0;

  public currencymask = [/\B(?=(\d{3})+(?!\d))/g];
  DeleteCampaignConfirmation: boolean = false;
  CampaignsInstructions: boolean = false;

  @Input() CampaignId = 0;
  @Input() ShowViewCampaign: boolean = false;
  @Input() Source: string;
  @Input() type: string;
  public toggleVal: boolean = false;
  constructor(public applicationComponent: ApplicationComponent, private route: ActivatedRoute, public decimalpipe: DecimalPipe, private customerService: CustomerService, private leadsService: LeadsService,
    private opprtunityservice: OpprtunitiesService, private router: Router, private toastr: ToastrService, private userService: UserService, private viewContainerRef: ViewContainerRef, private accountsService: AccountsService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit()
  {
    if (localStorage.getItem('CompaignVal') != null && localStorage.getItem('CompaignVal') != undefined) {
     var campaignvalues:any [] = localStorage.getItem('CompaignVal').split('|');
      this.CampaignId = parseInt(campaignvalues[0]);
      this.type = campaignvalues[1];
    }    
    this.initializeAgGrid();

    var obj: AuditLogModel = { id: 0, type: 'Campaigns', action: 'Campaign Viewed' , AccountOrContactId: 0, AccountOrContact: "", documentid: 0, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Campaign Viewed', AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
  }

  UserlistDataReceived(outProps: OutputPropsUserList) {
    if (outProps.secondDrpValue != "0" && outProps.secondDrpValue != undefined &&
      outProps.secondDrpValue != null && outProps.secondDrpValue != "") {
      if (outProps.secondDrpValue == 'Open Campaign') {
        this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.CampaignStatus == 'Open');
      } else if (outProps.secondDrpValue == 'Closed Campaign') {
        this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.CampaignStatus == 'Closed');
      } else {
        this.allCampaigns = this.OriginalAllCampaigns;
      }

    }
    else {
      if (outProps.finalitems == "" || outProps.finalitems == null || outProps.finalitems == undefined) {
        this.allCampaigns = null;
      }
      else {
        this.LoadCampaigns(outProps.finalitems, "");
      }
    }
  }
  close() {
    if (this.Source == 'Dashboard') {
      this.sendMsgEvent.emit("CloseDialog");
    } else {
      this.applicationComponent.activateMainButtons('dashboards');
      this.applicationComponent.ShowConfigurationDialog = false;
    }
    
  }

  addNewCampaign(val) {
    this.GetCampaignTypes();
    this.campaign = new Campaign();
    this.ShowCampaignGrid = false;
    this.ShowAddCampaign = true;
   
    this.ShowViewCampaign = false;
    
  }

  GetCampaignTypes() {
    this.leadsService.GetCampaignTypes(this.currentuser.id).then(result => {
      this.campaigntypelist = result.filter(x => x.isdeleted !== 1 );
      
    });
  }

  back() {
    this.LoadCampaigns(this.currentuser.id,"");
    this.CampaignId = 0;
    this.ShowAddCampaign = false;
    this.ShowCampaignGrid = true;
    this.ShowAddCampaignButtons = false;
    
    this.ShowViewCampaign = false;
    this.conid = 0;
    this.EditCampaignName = false;
    this.ShowAddButton = false;
    this.ShowContactsDiv = false;
    this.TotalExpense = 0;
    this.AllExpenses = [];
    $('#emailIds').val('');
    this.Ids = [];
  }
  initializeAgGrid() {
    this.gridOptions = <GridOptions>{
      pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',

    };
    let columnSelections: string;
    this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 32).then((config) => {

      if (config != null && config.maxlimit != null && config.maxlimit > 0) {
        this.pageSize = config.maxlimit.toString();
      }

      this.colSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
      this.pageSize = (this.pageSize == "") ? '1000' : this.pageSize;
      this.createColumnDefs(this.colSelections);
      this.LoadCampaigns(this.currentuser.id, this.type);

    });

    this.context = this;
    this.frameworkComponents = {
      linkdelete: LinkDeleteComponent
    };

  }

  createColumnDefs(columnSelections: string, @Optional() isDeActive: boolean = false) {
    var searcharray = [];
    if (this.applicationComponent.searchCriteriadata) {
      var searchaarraystring = this.applicationComponent.searchCriteriadata;
      if (searchaarraystring.includes(' /')) {
        searcharray = searchaarraystring.split(' / ');
      } else {
        searcharray.push(searchaarraystring);
      }
    }
    let selfobj = this;
    let columnSelectionsArray: string[] = [];
    if (columnSelections != "") {
      columnSelectionsArray = columnSelections.split(',');

      this.columnDefs = [];

      for (let i = 0; i < columnSelectionsArray.length; i++) {
        let fieldHeaderVisibleWidthArray: string[] = columnSelectionsArray[i].split(':');
        if (fieldHeaderVisibleWidthArray[0] == "#") {
          this.columnDefs.push({
            field: '#',
            headerName: '#',
            width: 52,
            checkboxSelection: true,
            suppressSorting: true,
            suppressMenu: true,
            pinned: true,
            hide: false,
            outerWidth: 8,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          });
        } else if (fieldHeaderVisibleWidthArray[0] == "owner") {
          this.columnDefs.push({ field: 'owner', headerName: 'Owner', hide: true, suppressSorting: true });
        } else if (fieldHeaderVisibleWidthArray[0] == "actions") {
          this.columnDefs.push(
            {
              field: 'actions', headerName: 'Actions', width: 80, hide: false, pinned: 'right', cellRenderer(params) {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
                eDiv.addEventListener('click', function () {
                  selfobj.campaign = params.data;
                  selfobj.DeleteCampaignConfirmation = true;
                  selfobj.DeleteExpenseCss('DeleteCampaignConfirmation');
                });
                return eDiv;
              }
            }
          );
        } else {
          this.columnDefs.push({
            field: fieldHeaderVisibleWidthArray[0],
            headerName: fieldHeaderVisibleWidthArray[1],
            hide: fieldHeaderVisibleWidthArray[2] == "true" ? false : true,
            width: parseInt(fieldHeaderVisibleWidthArray[3]),
          });
        }
      }
    }
    else {
      this.columnDefs = [
        {
          field: '#',
          headerName: '#',
          width: 52,
          checkboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false,
          outerWidth: 8,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true, enableTooltip: true
        },
        { field: 'CampaignID', headerName: 'Campaign Id', hide: true },
        { field: 'CampaignName', headerName: 'Campaign Name', hide: false },
        { field: 'contactsNames', headerName: 'Name', hide: false },
        { field: 'CampaignTypeName', headerName: 'Campaign Type', hide: true },
        { field: 'TotalExpense', headerName: 'Total Expense', hide: true },
        { field: 'salesPlusMonthlyPerYear', headerName: 'Total SMR', hide: true },
        { field: 'ProfitLoss', headerName: 'Profit | Loss', hide: true },
        { field: 'CreatedDate', headerName: 'Date Created', hide: false },
        { field: 'CampaignStatus', headerName: 'Status', hide: false },
        { field: 'createdByName', headerName: 'Owner', hide: false },       
        {
          field: 'actions', headerName: 'Actions', width: 80, hide: (this.Source =="dashboard")?true: false, pinned: 'right',  cellRenderer(params) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<i class="fa fa-trash" aria-hidden="true" style="font-size:15px"></i>';
            eDiv.addEventListener('click', function () {
              selfobj.campaign = params.data;
              selfobj.DeleteCampaignConfirmation = true;
              selfobj.DeleteExpenseCss('DeleteCampaignConfirmation');
            });
            return eDiv;
          }        
        }

        
      ];

      var tooltipRenderer = function (params) {
        return '<span title="' + params.value + '">' + params.value + '</span>';
      };
    }
  }

  ReceiveDeleteCampaignevent(msg) {
    if (msg == "Ok") {
      this.campaign.isDeleted = 1;
      this.saveCampaignCosts(false);
      let that = this;
      setTimeout(function () {
        that.DeleteCampaignConfirmation = false;
        that.back();
      }, 300);

    } else {
      this.DeleteCampaignConfirmation = false;
    }
  }

  LoadCampaigns(userid, type) {
    this.opprtunityservice.GetAllCampaigns(userid).then((viewCampaigns) => {
     
      viewCampaigns.forEach(ele => {
        var salesPlusMonthlyPerYear = Number(ele.SalesRevenue) + (Number(ele.MonthlyRevenue) * 12);
        var ProfitLoss = salesPlusMonthlyPerYear - ele.TotalExpense;
        ele.salesPlusMonthlyPerYear = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(salesPlusMonthlyPerYear);
        ele.ProfitLoss = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(ProfitLoss);    
      });
      
      this.OriginalAllCampaigns = viewCampaigns;
      debugger;
      if ( this.type == "Open") {
        this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.CampaignStatus == 'Open');
      } else if (this.type == "Closed") {
        this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.CampaignStatus == 'Closed');
      } else {
        if (this.Source == 'AccountView') {
          this.allCampaigns = this.OriginalAllCampaigns;
          //if (type == "Open Campaign") {
          //  this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.ContactsLinked != null && this.AccountcontactIds.includes(x.ContactsLinked) && x.CampaignStatus == 'Open');
          //}
          //else if (type == "Closed Campaign") {
          //  this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.ContactsLinked != null && this.AccountcontactIds.includes(x.ContactsLinked) && x.CampaignStatus == 'Closed');
          //}
          //else {
          //  this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.ContactsLinked != null && this.AccountcontactIds.includes(x.ContactsLinked));
          //}
        }
        else if (this.Source == 'ContactView') {
          if (type == "Open Campaign") {
            this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.ContactsLinked == this.AccountcontactIds && x.CampaignStatus == 'Open');
          }
          else if (type == "Closed Campaign") {
            this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.ContactsLinked == this.AccountcontactIds && x.CampaignStatus == 'Closed');
          }
          else {
            this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.ContactsLinked == this.AccountcontactIds)
          }
        } else {
          this.allCampaigns = this.OriginalAllCampaigns.filter(x => x.CampaignStatus == 'Open');
        }
      }
      if (localStorage.getItem('CompaignselVal') != null && localStorage.getItem('CompaignselVal') != undefined) {
     this.CampaignId = Number(localStorage.getItem('CompaignselVal'));
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.gridOptions.api.forEachNode((node) => {
            if (node.data.CampaignID == selfObj.CampaignId) {
              selfObj.gridOptions.api.selectNode(node, true);

            }
          });
        }, 700));
      }
      });
  }
  LoadProposals(OppIDs) {
    this.opprtunityservice.GetAllProposalsByOppID(OppIDs).then((viewCampaigns) => {
      this.AllProposals = viewCampaigns;
      
    });
  }

  columnVisible(event) {
    this.saveConfiguration();
  }
  dragStopped(event) {
    this.saveConfiguration();
  }
  columnResized(event) {
    this.saveConfiguration();
  }
  saveConfiguration() {
    let allCols: Column[] = this.gridOptions.columnApi.getAllGridColumns();
    let config: Dashboardconfiguration = new Dashboardconfiguration();

    config.maxlimit = 25;

    config.columnsselections = "";

    for (let i = 0; i < allCols.length; i++) {
      config.columnsselections += allCols[i].getColId().split("_1")[0] + ":" + allCols[i].getColDef().headerName + ":" + allCols[i].isVisible() + ":" + allCols[i].getActualWidth() + ",";
    }
    if (config.columnsselections.length > 0)
      config.columnsselections = config.columnsselections.substr(0, config.columnsselections.length - 1);
    config.configureduserid = this.currentuser.id;
    config.resourceid = 32;

    config.userid = "";
    this.opprtunityservice.saveDashboardconfiguration(config);
  }
  initializeAgGridCampaigns() {
    debugger;
    this.gridOptionsSelectedContacts = <GridOptions>{
      pagination: true, editable: true, enableColResize: true, paginationPageSize: 1000, enableSorting: true, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single', rowMultiSelectWithClick: true
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'left',

    };

    this.createColumnDefsCampaigns();
    if (this.campaign.ContactsLinked != null && this.campaign.ContactsLinked != "" && this.campaign.ContactsLinked != undefined) {
      this.getContactsbyId(this.campaign.ContactsLinked);
    } else {
      this.LinkedContacts = [];
    }
    
    
    this.gridOptionsSelectedContacts.defaultColDef = {
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
    this.context = this;
    this.frameworkComponents = {
      linkdelete: LinkDeleteComponent
    };
    
    
  }

  createColumnDefsCampaigns() {
      this.columnDefsSelectedContacts = [
        {
          headerName: '#',
          field: '#',
          width: 40,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          suppressSorting: true,
          suppressMenu: true,
          pinned: true,
          hide: false
        },

        { field: 'lastname', headerName: 'Last Name', hide: false },
        { field: 'firstname', headerName: 'First Name', hide: false },
        { field: 'middlename', headerName: 'Middle Name', hide: false, },
        { field: 'titleName', headerName: 'Title', hide: false, },
        { field: 'specialityName', headerName: 'Specialty', hide: false},
        { field: 'DOB', headerName: 'DOB', hide: false, },
        { field: 'accountname', headerName: 'Account', hide: false },
        {
          field: 'actions', headerName: 'Actions', cellRenderer: 'linkdelete', cellRendererParams: { Delete: true, Link: true }, width: 80, hide: false, pinned: 'right'
        }
      ];
  }
  onAgGridReady(params) {
  }
  LinkContacts() {
    if (this.campaign.ContactsLinked != null) {
      var newcontacts = [];
      this.contactids = this.campaign.ContactsLinked.split(',');
      newcontacts = $('#emailIds').val().split(',');
      newcontacts.forEach(ele => {
        this.contactids = this.contactids.filter(x => x !== ele);
        this.contactids.push(ele); 
      });
      this.campaign.ContactsLinked = this.contactids.join(",");
      this.saveCampaignCosts(true);
    } else {
      this.campaign.ContactsLinked = $('#emailIds').val();
      this.saveCampaignCosts(true);
    }

    
    
  }

  getContactsbyId(ContactIds) {
    this.opprtunityservice.GetContactsByContactIds(ContactIds).then(res => {
      if (res) {
        this.LinkedContacts = res;
        let selfObj = this;
        $(setTimeout(function () {
          selfObj.gridOptionsSelectedContacts.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));

        }, 300));      
      }
      else {
        this.LinkedContacts = [];
      }
    });
  }

  saveCampaignCosts(LoadViewCampaign) {
    if (this.campaign.CampaignID == 0 || this.campaign.CampaignID == undefined) {
      this.campaign.createdby = this.currentuser.id;
      this.campaign.CampaignStatus = 'Open';
    }
    this.opprtunityservice.saveCampaign(this.campaign).then((viewCampaigns) => {
      if (viewCampaigns) {
        this.campaign = viewCampaigns;
        if (LoadViewCampaign == true) {
          this.LoadViewCampaignPage(this.campaign);
        }
        
        if (this.campaign.ContactsLinked == "" || this.campaign.ContactsLinked == null) {
          this.LinkedContacts = [];
        }
        
      }
    });
  }

  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data && this.DeleteCampaignConfirmation != true) {
        if (this.Source != 'dashboard') {
          this.LoadViewCampaignPage(event.api.getSelectedNodes()[0].data);
        }
      }
    }
  }

  LoadViewCampaignPage(campaign) {  
    this.campaign = campaign;
    if (this.applicationComponent.selectedbuttonText != 'My Campaigns') {
      this.applicationComponent.activateMainButtons('Campaigns');
      this.applicationComponent.selectedbuttonText = 'My Campaigns';
      localStorage.setItem('CompaignselVal', this.campaign.CampaignID.toString());
    }
    var obj: AuditLogModel = { id: 0, type: 'Campaigns', action: 'Campaign Viewed - ' + this.campaign.CampaignName, AccountOrContactId: 0, AccountOrContact: "", documentid: this.campaign.CampaignID, userid: this.currentuser.id, dateofviewed: '', customertype: '', time: '', Title: 'Campaign Viewed - ' + this.campaign.CampaignName, AccountName: '', contactname: '', documentname: '', username: '', LoggedIn: '', LoggedOut: '', TimeSpent: '', LogOutStatus: '', Total: 0, CurrentPage: 0 };
    this.applicationComponent.InsertAuditHistory(obj);
    
    this.GetCampaignTypes();
    if (this.campaign.LinkedOpportunities !== null && this.campaign.LinkedOpportunities !== '' && this.campaign.LinkedOpportunities !== undefined) {
      this.OpportunityIds = this.campaign.LinkedOpportunities;
     // this.CampaignId = this.campaign.CampaignID;
    
      this.LinkedOpportunityIds = this.campaign.LinkedOpportunities.split(",");
      this.GetOpportunitiesListByOppIds(this.campaign.LinkedOpportunities);

    } else {
      this.LinkedOpportunityIds = [];
      this.Allopportunities = [];
      this.Proposalopportunities = [];
      this.SalesWonopportunities = [];
      this.SalesLostopportunities = [];
    }
  
    let that = this;
    setTimeout(function () {
      
      that.ShowViewCampaign = true;
      that.ShowAddCampaign = false;
      that.ShowCampaignGrid = false;
      that.ShowAddCampaignButtons = false;   
      that.initializeAgGridCampaigns();      
    }, 500);

  }


  GetOpportunitiesListByOppIds(oppids) {
    this.opprtunityservice.GetOpportunitiesListByOppIds(oppids).then((customer) => {
      if (customer) {
        customer.forEach((customer) => {
          customer.datecreated = this.datePipe.transform(customer.datecreated, "MM-dd-yyyy");
          customer.actualclosedate = this.datePipe.transform(customer.actualclosedate, "MM-dd-yyyy");
          customer.estimatedclosedate = this.datePipe.transform(customer.estimatedclosedate, "MM-dd-yyyy");
        });
        this.Allopportunities = customer;
        this.Proposalopportunities = customer.filter(x => x.OpportunityFor == "Proposal");
        this.SalesWonopportunities = customer.filter(x => x.status == "Closed-Won");
        this.SalesLostopportunities = customer.filter(x => x.status == "Closed-Lost");
      }
      else {
        this.Allopportunities = [];
        this.Proposalopportunities = [];
        this.SalesWonopportunities = [];
        this.SalesLostopportunities = [];
      }

    });
  }

  onContactSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.conid = event.api.getSelectedNodes()[0].data.id;
        if (this.condashcmp != undefined) {
          this.condashcmp.GetContactInfo(event.api.getSelectedNodes()[0].data.id);
        }
      }
    }
    else {
      this.conid = 0;
    }
  }
  OpenDialog(val) {
    if (val == "Expenses") {
      this.GetCampaignExpenses();
      this.ShowExpensesDialog = true;
      this.DialogCss(val);
      this.AllExpenses = [];
      let that = this;
      $(setTimeout(function () {
        if (that.campaign.Expenses != null) {
          var expenses = [];
          that.TotalExpense = that.campaign.TotalExpense;
          expenses = that.campaign.Expenses.split(",");
          expenses.forEach(ele => {
            var expense = ele.split("~");
            $('#ExpenseType' + expense[2]).val(expense[0]);
            $('#ExpenseCost' + expense[2]).val(parseFloat(expense[1].replace(/,/g, "")).toFixed(2).toString().replace(/\B(?=(\d{6})+(?!\d))/g, ","));
            that.AllExpenses.push({ ExpenseType: expense[0], ExpenseCost: Number(parseFloat(expense[1].replace(/,/g, "")).toFixed(2).toString().replace(/\B(?=(\d{6})+(?!\d))/g, ",")), ExpenseIndex: Number(expense[2]) });
          });
        }
      }, 200));

    }
    else if (val == "ROI") {
      if (this.campaign.TotalExpense !== 0 && this.campaign.TotalExpense !== null && this.campaign.TotalExpense !== undefined) {
        this.ROI = new CampaignROI();
        if (this.SalesWonopportunities && this.SalesWonopportunities.length > 0) {

          this.SalesWonopportunities.forEach(ele => {
            this.ROI.SalesRevenue += Number(ele.dollers);
            this.ROI.MonthlyRevenue += Number(ele.RR);
          });
          this.ROI.MonthlyPerYear = this.ROI.MonthlyRevenue * 12;
          this.ROI.salesPlusMonthlyPerYear = this.ROI.SalesRevenue + (this.ROI.MonthlyRevenue * 12);
          this.ROI.Expenses = this.campaign.TotalExpense;
          this.ROI.ProfitLoss = this.ROI.salesPlusMonthlyPerYear - this.ROI.Expenses;
        } else {
          this.ROI.MonthlyPerYear = this.ROI.MonthlyRevenue * 12;
          this.ROI.salesPlusMonthlyPerYear = this.ROI.SalesRevenue + (this.ROI.MonthlyRevenue * 12);
          this.ROI.Expenses = this.campaign.TotalExpense;
          this.ROI.ProfitLoss = this.ROI.salesPlusMonthlyPerYear - this.ROI.Expenses;
        }
        this.ShowROIDialog = true;
        this.DialogCss(val);
      }
      else {
        alert("Please add Expenses");
      }
    }
    else if (val == 'Opportunities') {
      this.OpportunitiesDialog = true;
      this.OpportunityViewType = "All Opportunities";
      this.OpportunityIds = this.campaign.LinkedOpportunities;
    }
    else if (val == "Sales Won") {
      if (this.SalesWonopportunities && this.SalesWonopportunities.length > 0) {
        this.OpportunityIds = this.SalesWonopportunities.map(x => x.id).join(",");
      }
      else {
        this.OpportunityIds = null;
      }
      this.OpportunitiesDialog = true;
      this.OpportunityViewType = val;
    }
    else if (val == "Sales Lost") {
      if (this.SalesLostopportunities && this.SalesLostopportunities.length) {
        this.OpportunityIds = this.SalesLostopportunities.map(x => x.id).join(",");
      } else {
        this.OpportunityIds = null;
      }
      this.OpportunitiesDialog = true;
      this.OpportunityViewType = val;
    }
    else if (val == "Proposals") {
      this.initializeProposalAgGrid();
      this.ProposalsDialog = true;
    }

    this.DialogCss(val);
  }
  DialogCss(val) {
    if (val == "Expenses") {
      $(setTimeout(function () {
        $('#ExpensesDialog .dialog').attr('style', 'width: 50%;padding: 0;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 50%;max-width: 1393px !important;margin-right:1%');
      }, 10));
    } else if (val == "ROI") {
      $(setTimeout(function () {
        $('#ROIDialog .dialog').attr('style', 'width: 50%;padding: 0;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 50%;max-width: 1393px !important;margin-right:1%');
      }, 10));
    } else if (val == "LinkOpportunity") {
      $(setTimeout(function () {
        $('#LinkOpportunitiesDialog .dialog').attr('style', 'width: 50%;padding: 0;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 50%;max-width: 1393px !important;margin-right:1%');
      }, 10));
    } else if (val == "Proposals") {
      $(setTimeout(function () {
        $('#ProposalsDialog .dialog').attr('style', 'width: 50%;padding: 0;border: 3px solid #00B0F0;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 50%;max-width: 1393px !important;margin-right:1%');
      }, 10));
    } else if (val == "Opportunities" || val == "Sales Won" || val == "Sales Lost") {
      $(setTimeout(function () {
        $('#OpportunitiesDialog .dialog').attr('style', 'width: 50%;padding: 0;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 50%;max-width: 1393px !important;margin-right:1%');
      }, 10));
    } else if (val == "Instructions") {
      $(setTimeout(function () {
        $('#CampaignsInstructions .dialog').attr('style', 'width: 45%;min-height: 63%;overflow: auto;height: 83%;top: 10%;left: 50%;max-width: 1393px !important;margin-right:1%');
      }, 10));
    }
  }

  GetCampaignExpenses() {
    this.leadsService.GetCampaignExpenses(this.currentuser.id).then(result => {
      this.campaignExpenselist = result.filter(x => x.isdeleted != 1);
    });
  }
  leadingZeros(input) {
    var currency = $('#ExpenseCost'+ input).val();
    var result = parseFloat(currency.replace(/,/g, ""))
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{6})+(?!\d))/g, ",");
    $('#ExpenseCost' + input).val(result);
  }
  
  CalculateExpense(index) {
    var expenseType = $('#ExpenseType' + index).val();
    var expenseCost = Number($('#ExpenseCost' + index).val());

    if (expenseType !== null) {
      this.AllExpenses = this.AllExpenses.filter(x => x.ExpenseIndex !== 0);

      if (this.AllExpenses.length > 0) {
        this.AllExpenses = this.AllExpenses.filter(x => x.ExpenseIndex !== index);
        if (this.AllExpenses.filter(x => x.ExpenseType === expenseType).length > 0) {
          alert("Expense already Added");
          $('#ExpenseType' + index).val(0);
          $('#ExpenseCost' + index).val("");
        } else {
          this.AllExpenses.push({ ExpenseType: expenseType, ExpenseCost: expenseCost, ExpenseIndex: index });
        }


      } else {
        this.AllExpenses = [{ ExpenseType: expenseType, ExpenseCost: expenseCost, ExpenseIndex: index }];
      }

      this.TotalExpense = this.AllExpenses.map(x => x.ExpenseCost).reduce((a, b) => a + b, 0);

    }

    else {
      $('#ExpenseCost' + index).val("");
      alert("Please Select Expense Type");
    }   
  }

  saveExpenses() {
    var exp = [];
    this.AllExpenses.forEach(ele => {
      exp.push(ele.ExpenseType + "~" + ele.ExpenseCost + "~" + ele.ExpenseIndex);
    });

    this.campaign.Expenses = exp.join(",");
    this.campaign.TotalExpense = this.TotalExpense;
    this.saveCampaignCosts(true);
    this.ShowExpensesDialog = false;
  }
  DeleteExpense(index) {
    this.AllExpenses = this.AllExpenses.filter(x => x.ExpenseIndex !== index);
    this.TotalExpense = this.AllExpenses.map(x => x.ExpenseCost).reduce((a, b) => a + b, 0);
    $('#ExpenseType' + index).val(0);
    $('#ExpenseCost' + index).val("");
    this.saveExpenses();
    this.DeleteExpenseConfirmation = false;
  }
  DeleteExpenseCss(val) {
    
    setTimeout(function () {
      $('#' + val + ' .dialog').attr('style', 'width: 28%;padding: 0;border: 6px solid  rgb(49,189,240);border-radius:5%;min-height: 15%;top: 8%;left: 3%;max-width: 1393px !important;');
    }, 4)
  }

  deleteRowAg(rowdata) {
      this.DeleteContactConfirmation = true;
      this.selectedContectId = rowdata.id;
      this.DeleteExpenseCss('DeleteContactConfirmation');
    
  }
  ReceiveExpenseDeleteevent(msg) {
    if (msg == "Ok") {
      this.DeleteExpense(this.selectedExpenseIndex);
    }
    else {
      this.DeleteExpenseConfirmation = false;
    }
  }
  ReceiveDeleteevent(msg) {
    if (msg == "Ok") {
      this.DeleteContact();
    } else {
      this.DeleteContactConfirmation = false;
    }
  }
  DeleteContact() {
    this.contactids = this.campaign.ContactsLinked.split(",");
    this.contactids = this.contactids.filter(x => x !== this.selectedContectId.toString());
    this.campaign.ContactsLinked = this.contactids.join(",");
    this.conid = 0; //to clear Contact selection
    this.saveCampaignCosts(true);
    let that = this;
    setTimeout(function () {
      that.conid = 0;
      that.DeleteContactConfirmation = false;
    }, 300);
  }

  linkRowAg(rowdata) {
    this.conid = rowdata.id;
    this.LinkOpportunitiesDialog = true;

    this.DialogCss('LinkOpportunity');
    
  }
  ReceiveOpportunity(opportunityid) {
    if (this.campaign.LinkedOpportunities != "" && this.campaign.LinkedOpportunities != null && this.campaign.LinkedOpportunities != undefined) {
      this.LinkedOpportunityIds = this.campaign.LinkedOpportunities.split(",");
      var ExistingOpportunity = this.LinkedOpportunityIds.filter(x => x == opportunityid);
      
      if (ExistingOpportunity.length > 0) {
        alert("Opportunity Already Linked");
      }
      else {
        this.LinkedOpportunityIds.push(opportunityid);
        this.campaign.LinkedOpportunities = this.LinkedOpportunityIds.join(",");
        this.saveCampaignCosts(true);
      }
    } else {
      this.campaign.LinkedOpportunities = opportunityid;
      this.saveCampaignCosts(true);
    }

    this.LinkOpportunitiesDialog = false;
  }

  DeleteOpportunity(opportunityid) {
    if (this.campaign.LinkedOpportunities != "" && this.campaign.LinkedOpportunities != null && this.campaign.LinkedOpportunities != undefined) {
      this.LinkedOpportunityIds = this.campaign.LinkedOpportunities.split(",");
      var ExistingOpportunity = this.LinkedOpportunityIds.filter(x => x !== opportunityid);

      this.LinkedOpportunityIds = ExistingOpportunity;
        this.campaign.LinkedOpportunities = this.LinkedOpportunityIds.join(",");
      this.saveCampaignCosts(true);
      this.OpportunitiesDialog = false;
    } 

  }



initializeProposalAgGrid() {
  this.ProposalGridOptions = <GridOptions>{
    pagination: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'single'
  };
  this.sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
      }
    ],
    position: 'right',

  };
  let columnSelections: string;
  this.opprtunityservice.getDashboardConfigurationByUserId(this.currentuser.id, 54).then((config) => {

    if (config != null && config.maxlimit != null && config.maxlimit > 0) {
      this.pageSize = config.maxlimit.toString();

    }

    this.colSelections = config != null && config.columnsselections != null ? config.columnsselections : "";
    this.colSelections = config != null && config.columnsselections != null ? config.columnsselections : "";

    this.startDateVal = (config != null && config.startdate != null) ? config.startdate : (new Date().getFullYear() - 1) + '-01-01';
    this.startDatePlaceHolder = (config != null && config.startdate != null) ? 'Start:' + moment(config.startdate).format("MM-DD-YYYY") : 'Start: 01-01-' + (new Date().getFullYear() - 1).toString();
    this.endDateVal = (config != null && config.enddate != null) ? config.enddate : new Date().getFullYear() + '-12-31';
    this.endDatePlaceHolder = (config != null && config.enddate != null) ? 'End:' + moment(config.enddate).format("MM-DD-YYYY") : 'End: 12-31-' + (new Date().getFullYear() - 1).toString();

    this.pageSize = (this.pageSize == "") ? '1000' : this.pageSize;

    this.createProposalColumnDefs();
    if (this.Proposalopportunities && this.Proposalopportunities.length > 0) {
      this.LoadProposals(this.Proposalopportunities.map(x => x.id).join(","));
    } else {
      this.AllProposals=[]
    }
   
  });



}

  createProposalColumnDefs() {

    var searcharray = [];
    if (this.applicationComponent.searchCriteriadata != null) {
      var searchaarraystring = this.applicationComponent.searchCriteriadata;
      if (searchaarraystring.includes(' /')) {
        searcharray = searchaarraystring.split(' / ');
      } else {
        searcharray.push(searchaarraystring);
      }
    }
    let selfobj = this;
    let columnSelectionsArray: string[] = [];

    this.ProposalColumnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 40,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false
      },
      {
        field: 'CreatedDate', headerName: 'Date', hide: false, width: 110, cellRenderer: (params) => {
          return moment(params.value).format('MM-DD-YYYY');
        }
      },
      {
        field: 'AccountName', headerName: 'Account', hide: false, width: 200, cellRenderer: function (params) {
          if (searcharray.length > 0 && params.value != null) {
            let x = params.value.split(" ");
            var st = "";
            for (let i = 0; i < x.length; i++) {
              if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        }
      },
      {
        field: 'ContactName', headerName: 'Contact', hide: false, width: 180, cellRenderer: function (params) {
          if (searcharray.length > 0 && params.value != null) {
            let x = params.value.split(" ");
            var st = "";
            for (let i = 0; i < x.length; i++) {
              if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        }
      },
      {
        field: 'itemName', headerName: 'Proposal Type', hide: false, width: 170, cellRenderer: function (params) {
          if (searcharray.length > 0 && params.value != null) {
            let x = params.value.split(" ");
            var st = "";
            for (let i = 0; i < x.length; i++) {
              if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        }
      },
      {
        field: 'ProposalDescription', headerName: 'Proposal Description', hide: false, width: 170, cellRenderer: function (params) {
          if (searcharray.length > 0 && params.value != null) {
            let x = params.value.split(" ");
            var st = "";
            for (let i = 0; i < x.length; i++) {
              if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        }
      },
      {
        field: 'ProposalPDF', headerName: 'Proposal', hide: false, width: 100, cellRenderer(params) {

          if (params.data.ProposalPDF != null && params.data.ProposalPDF != 0) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
            eDiv.addEventListener('click', function () {
              selfobj.PDFClick(params.data.ProposalPDF, 'Proposal');
            });
            return eDiv;
          }

        }
      },
      {
        field: 'ProposalPOPDF', headerName: 'PO', hide: false, width: 100, cellRenderer(params) {
          if (params.data.ProposalPOPDF != null && params.data.ProposalPOPDF != 0) {
            var eDiv = document.createElement('div');
            eDiv.innerHTML = '<img src="../../../assets/img/PDF_file_icon.svg.png" style="height:20px;width:25%;margin-left:40%"/>';
            eDiv.addEventListener('click', function () {
              selfobj.PDFClick(params.data.ProposalPOPDF, 'Proposal');
            });
            return eDiv;
          }
        }

      },
      {
        field: 'leadfrom', headerName: 'Lead From', hide: false, width: 170, cellRenderer: function (params) {
          if (searcharray.length > 0 && params.value != null) {
            let x = params.value.split(" ");
            var st = "";
            for (let i = 0; i < x.length; i++) {
              if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        }
      },
      {
        field: 'LeadDescription', headerName: 'Lead Description', hide: false, width: 170, cellRenderer: function (params) {
          if (searcharray.length > 0 && params.value != null) {
            let x = params.value.split(" ");
            var st = "";
            for (let i = 0; i < x.length; i++) {
              if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        }
      },
      {
        field: 'TotalPrice', headerName: 'Total Price', hide: false, width: 100
      },
      {
        field: 'MonthlyTotalPrice', headerName: 'Monthly Total Price', hide: false, width: 100
      },
      {
        field: 'status', headerName: 'Status', hide: false, width: 100, cellRenderer: function (params) {
          if (searcharray.length > 0 && params.value != null) {
            let x = params.value.split(" ");
            var st = "";
            for (let i = 0; i < x.length; i++) {
              if (searcharray.toString().toLowerCase().includes(x[i].trim().toLowerCase())) {
                st += "&nbsp;<label style='color:red'>" + x[i] + "</label>&nbsp;";
              }
              else {
                st += x[i] + " ";
              }

            }
            return st

          } else {
            return params.value;
          }
        }
      }

    ];

  }

  PDFClick(val, type) {
    this.pdfview = true;
      this.userService.GetProposalsDocumentContent(val).then(result => {
        let SO = this
        $(setTimeout(function () {
          SO.onDocumentOpen(result.PdfContent);
          
        }, 100));
      });
    }



onDocumentOpen(myBaseString) {
  $('#pdfDiv').html('<embed src="data:application/pdf;base64,' + myBaseString + '" id = "pdfFileReader" style="width:100%;height:100%;" />')
  $('#pdfDiv').attr('style', 'width:100%;height: 100%;padding-left: 0;padding-right: 0%;border-top: 5px solid #f4f4f4;border-right: 5px solid #f4f4f4;border-bottom: 5px solid #f4f4f4;background-color: white;margin-left:0%');
  }

  onProposalSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 0) {
      if (event.api.getSelectedNodes()[0].data) {
        this.PDFClick(event.api.getSelectedNodes()[0].data.ProposalPDF,"");
        
      }
    }
  }



  showContactsdiv() {
    this.ContactsInitializeAgGrid("");
  }
  ContactsInitializeAgGrid(typeval) {

    this.ContactGridOptions = <GridOptions>{
      pagination: true, editable: true, enableColResize: true, enableSorting: true, paginationPageSize: 1000, enableFilter: true, toolPanelSuppressValues: true, toolPanelSuppressRowGroups: true, toolPanelSuppressPivotMode: true, rowSelection: 'multiple', rowMultiSelectWithClick: true
    };
    
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',
      defaultToolPanel: 'none',
    };

    this.ContactsCreateColumnDefs(this.colSelections, this.applicationComponent.searchstring, false);
    this.GetContactList('null', $("#userids").val(), this.active_deactiveStatus);
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      position: 'right',
      defaultToolPanel: 'none',
    };
    this.context = this;  
  }
  onContactMessageReceived(msg) {
    this.toggleVal = false;
  }
  ContactsCreateColumnDefs(columnSelections: string, searchstring, @Optional() isDeActive: boolean = false) {
    let selfObj = this;
    selfObj.ContactsColumnDefs = [
      {
        headerName: '#',
        field: '#',
        width: 40,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true,
        hide: false
      },
      { field: 'accnumber', headerName: 'Acct #', hide: true },
      { field: 'lastname', headerName: 'Last Name', hide: false, },
      { field: 'firstname', headerName: 'First Name', hide: false, },
      { field: 'middlename', headerName: 'Middle Name', hide: false, },
      { field: 'titleName', headerName: 'Title', hide: false, },
      { field: 'specialityName', headerName: 'Specialty', hide: false, },
      { field: 'DOB', headerName: 'DOB', hide: false, },
      { field: 'zip', headerName: 'ZIP', hide: false, },
      { field: 'accountname', headerName: 'Account', hide: false, },
      { field: 'suffixName', headerName: 'Suffix', hide: true },
      { field: 'sexName', headerName: 'Sex', hide: true, },
      { field: 'subspecialityName', headerName: 'Subspeciality', hide: true },
      { field: 'email', headerName: 'Email', hide: true },
      { field: 'address1', headerName: 'Address1', hide: true, },
      { field: 'address2', headerName: 'Address2', hide: true, },
      { field: 'city', headerName: 'City', hide: true },
      { field: 'state', headerName: 'State', hide: true },
      { field: 'cell', headerName: 'Cell', hide: true, },
      { field: 'office', headerName: 'Office', hide: true, },
      { field: 'homeaddress', headerName: 'Home Address', hide: true, },
      { field: 'homecity', headerName: 'Home City', hide: true },
      { field: 'homestate', headerName: 'Home State', hide: true, },
      { field: 'homezip', headerName: 'Home Zip', hide: true, },
      { field: 'home', headerName: 'Home', hide: true, },
      { field: 'collegeName', headerName: 'College', hide: true, },
      { field: 'collegegrandyear', headerName: 'C.G.year', hide: true, },
      { field: 'medschoolName', headerName: 'Medschool', hide: true, },
      { field: 'medschoolgrandyear', headerName: 'M.G.year', hide: true, },
      { field: 'residencyName', headerName: 'Residency', hide: true, },
      { field: 'residencygrandyear', headerName: 'R.G.year', hide: true, },
      { field: 'fellowshipName', headerName: 'Fellowship', hide: true, },
      { field: 'fellowshipgrandyear', headerName: 'F.G.year', hide: true, },
      { field: 'OWNER', headerName: 'User Name', hide: false, suppressSorting: true, },
      { field: 'created', headerName: 'Created123', hide: true, },
      { field: 'Status', headerName: 'Created', hide: true, },
      { field: 'contacttype', headerName: 'Created', hide: true, },
      { field: 'country', headerName: 'Created', hide: true, },
      { field: 'AlternateNo1', headerName: 'Created', hide: true, },
    ];
  }

  GetContactList(term, userid, active_deactiveStatus?: number) {
    this.opprtunityservice.GetAllContactsbySearchPaginationNew($("#userids").val(), term, userid, this.subtype, active_deactiveStatus,  '2018-01-01', this.endDateVal, '0,1').then((contact) => {

      contact.forEach(contact => {
        contact.created = this.datePipe.transform(contact.created, "MM-dd-yyyy");
        contact.speciality = ((contact.speciality == 'null') ? "" : contact.speciality);
      });
      this.allContacts = contact;
    });
  }
  ContactsUserlistDataReceived(outProps: OutputPropsUserList) {
    this.calanderonoff = outProps.calanderonoff;
    this.pageSize = outProps.pageSize == undefined ? this.pageSize : outProps.pageSize;
    this.Contactvales = outProps.secondDrpValue == undefined ? "0" : outProps.secondDrpValue;
    if (outProps.finalitems != "") {
      $("#userids").val(outProps.finalitems);
      this.gridApi.paginationSetPageSize(Number(this.pageSize));
      if (!outProps.pageSizeChanged) {
        this.GetContactList('null', $("#userids").val(), this.active_deactiveStatus);
      }
    } else {
      this.allContacts = [];
      $("#userids").val('');
    }
  }

  ContactsOnAgGridReady(params) {
    this.gridApi = params.api;
  }
  ContactsrowClickAgGrid(rowEvent) {
    if (this.active_deactiveStatus != 1)
      if ((this.Ids).indexOf(rowEvent.data.id) > -1) {
        var index = (this.Ids).indexOf(rowEvent.data.id);
        (this.Ids).splice(index, 1);
        var string = (this.Ids).toString();
        $('#emailIds').val(string);
        $('#tasklistselectedtyle').val(' ');
      } else {
        if (rowEvent.data.permission == "view") {
          this.applicationComponent.isAccessDenied = true;
        } else {
          this.applicationComponent.isAccessDenied = false;
        }
        this.Ids.push(rowEvent.data.id)
        var string = (this.Ids).toString();
        $('#emailIds').val(string);
        $('#tasklistselectedtyle').val('Contact');

      }

  }
  ShowGrid() {
    this.ShowLeftSideDiv = true;
    let selfObj = this;
    $(setTimeout(function () {
      selfObj.gridOptionsSelectedContacts.api.forEachNode((node) => {
        if (node.data.id == selfObj.conid) {
          selfObj.gridOptionsSelectedContacts.api.selectNode(node, true);
        }
      });
    }, 500));
  }

  ToggleClick() {
    if (this.toggleVal) {
      this.toggleVal = false;
    } else {
      this.toggleVal = true;
    }
  }
}
