import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions} from '@angular/http';
import { Accounts, AccountTasksCounts } from './../models/accounts';
import { Contacts } from './../models/contacts';
import { Viewcalllist } from './../models/viewcalllist';
import { Customers } from './../models/customers';
import { Notes } from './../models/notes';
import { Calllist } from './../models/calllist';
import { AppSettings } from '../app.settings';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Accountlocations, Hero } from './../models/data-model';
import { ColumnNames } from './../models/column-names';
import { AdvancedSearch } from './../models/advancedsearch';
import { Zendeskuser } from '../models/zendeskuser';
import { GoogleMap } from './../models/google-map';
//import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { StatusIds } from '../models/StatusIds';
import { AddressUpdate } from '../models/AddressUpdate';
import { AccountsHistory } from '../models/AccountHistory';
import { AccountSpecialNotes } from '../models/AccountSpecialNotes';
import { Googlelocations } from '../models/googlelocations';
declare var require: any;
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class AccountsService {
  options: RequestOptions;
  authToken: any;
  headers: any;
  addresses: Accountlocations[];
  viewcalllist: Viewcalllist; reqHeader: HttpHeaders = new HttpHeaders();
  constructor(private httpclient: HttpClient,private http: Http, private settings: AppSettings) {
   
    this.reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('key')
    });
  }
  updateaccountids(accounts: Accounts): Promise<Accounts> {
    const promise = new Promise<Accounts>((resolve, reject) => {
      this.httpclient.post<Accounts>(this.settings.baseUrl + 'api/accounts/UpdateStatusIds', accounts, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  AddUpdateStatusIDS(accounts: StatusIds): Promise<StatusIds> {
    const promise = new Promise<StatusIds>((resolve, reject) => {
      this.httpclient.post<StatusIds>(this.settings.baseUrl + 'api/accounts/AddUpdateStatusIDS', accounts, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  GetAccountStatusIds(id: number, type: string): Promise<StatusIds> {
    const promise = new Promise<StatusIds>((resolve, reject) => {
      if (id != undefined && id != null) {
        this.httpclient.get<StatusIds>(this.settings.baseUrl + 'api/accounts/GetAccountStatusIds/' + id + '/' + type, { headers: this.reqHeader }).
          subscribe(res => {
            resolve(res);
          });
      }
    });
    return promise;
  }

  addaccount(accounts: Accounts): Promise<Accounts> {
        const promise = new Promise<Accounts>((resolve, reject) => {
          this.httpclient.post<Accounts>(this.settings.baseUrl + 'api/accounts/SaveAccount', accounts, { headers: this.reqHeader }
             ).subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
  }
  addaccountNew(accounts: Accounts): Promise<Accounts> {
    const promise = new Promise<Accounts>((resolve, reject) => {
      this.httpclient.post<Accounts>(this.settings.baseUrl + 'api/accounts/SaveAccountNew', accounts,  { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  SavaccountSpecialNotes(accounts: AccountSpecialNotes): Promise<AccountSpecialNotes> {
    const promise = new Promise<AccountSpecialNotes>((resolve, reject) => {
      this.httpclient.post<AccountSpecialNotes>(this.settings.baseUrl + 'api/accounts/SaveSpecialNotes', accounts, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  addImportedAccount(accounts: Accounts[]): Promise<Accounts> {
        const promise = new Promise<Accounts>((resolve, reject) => {
          this.httpclient.post<Accounts>(this.settings.baseUrl + 'api/accounts/SaveImportedAccount', accounts, { headers: this.reqHeader }
             ).subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
  }
  checkduplicates(accounts: Accounts): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.post<string>(this.settings.baseUrl + 'api/accounts/Checkduplicate', accounts, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  checkduplicateAccountName(accountName: string): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.get<string>(this.settings.baseUrl + 'api/accounts/CheckAccountName/' + accountName, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });     
    });
    return promise;
  }
  checkLeadorOpportunityOpen(accid: number): Promise<AccountTasksCounts> {
    const promise = new Promise<AccountTasksCounts>((resolve, reject) => {
      this.httpclient.get<AccountTasksCounts>(this.settings.baseUrl + 'api/accounts/CheckIsLeadorOptOpen/' + accid, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  
  CheckImportedAccountDuplicates(array: string[]): Promise<Accounts> {
     
    const promise = new Promise<Accounts>((resolve, reject) => {
  
      this.httpclient.post<Accounts>(this.settings.baseUrl + 'api/accounts/CheckImportedAccountDuplicates', array, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  CheckImportedContactsDuplicates(array: string[]): Promise<Contacts> {
     
    const promise = new Promise<Contacts>((resolve, reject) => {
  
      this.httpclient.post<Contacts>(this.settings.baseUrl + 'api/accounts/CheckImportedAccountDuplicates', array, { headers: this.reqHeader }).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  addCalllist(calllist: Calllist): Promise<Calllist> {
    const promise = new Promise<Calllist>((resolve, reject) => {
      this.httpclient.post<Calllist>(this.settings.baseUrl + "api/accounts/SaveCalllist", calllist, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  getMappedContactsForLocation(Locationid: number, accountid: number): Promise<Contacts[]> {      
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/accounts/getMappedContactsForLocation/' + Locationid + '/' + accountid,
          { headers: this.reqHeader }).subscribe(res => {
            resolve(res);
          });
      });
      return promise;
  }

  addaccountLocations(addresses: Accountlocations[]): Promise<Accountlocations[]> {
    const promise = new Promise<Accountlocations[]>((resolve, reject) => {
      this.httpclient.post<Accountlocations[]>(this.settings.baseUrl + 'api/accounts/SaveAccountLocations', addresses, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }

  addaccountHistory(accountHstory: AccountsHistory): Promise<AccountsHistory> {
    const promise = new Promise<AccountsHistory>((resolve, reject) => {
      this.httpclient.post<AccountsHistory>(this.settings.baseUrl + 'api/accounts/SaveAccountHistory', accountHstory, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  sp_updateAccAddressToContact(addresses: AddressUpdate): Promise<AddressUpdate> {
    const promise = new Promise<AddressUpdate>((resolve, reject) => {
      this.httpclient.post<AddressUpdate>(this.settings.baseUrl + 'api/customers/updatecontactaddress', addresses, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  updatecontactaddress(addresses: AddressUpdate): Promise<AddressUpdate> {
    const promise = new Promise<AddressUpdate>((resolve, reject) => {
      this.httpclient.post<AddressUpdate>(this.settings.baseUrl + 'api/accounts/updatecontactaddress', addresses, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  updateAccountAddress(addresses: AddressUpdate): Promise<AddressUpdate> {
    const promise = new Promise<AddressUpdate>((resolve, reject) => {
      this.httpclient.post<AddressUpdate>(this.settings.baseUrl + 'api/accounts/updateAccountAddress', addresses, { headers: this.reqHeader }
      ).subscribe(res => {
        resolve(res);
      });
    });
    return promise;
  }
  GetAlllocations(googleloc: Googlelocations): Promise<GoogleMap[]> {
    const promise = new Promise<GoogleMap[]>((resolve, reject) => {
      this.httpclient.post<GoogleMap[]>(this.settings.baseUrl + 'api/accounts/GetAlllocationdetails' , googleloc, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  addcontact(contacts: Contacts): Promise<Contacts> {
     
        const promise = new Promise<Contacts>((resolve, reject) => {
          this.httpclient.post<Contacts>(this.settings.baseUrl + 'api/accounts/SaveContacts', contacts, { headers: this.reqHeader }
             ).subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
  }
  addcontactNew(contacts: Contacts): Promise<Contacts> {
     
        const promise = new Promise<Contacts>((resolve, reject) => {
          this.httpclient.post<Contacts>(this.settings.baseUrl + 'api/accounts/SaveContactsNew', contacts, { headers: this.reqHeader }
             ).subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
    }

    public GetAllAccounts(): Promise<Accounts[]> {
      const promise = new Promise<Accounts[]>((resolve, reject) => {
        this.httpclient.get<Accounts[]>(this.settings.baseUrl + 'api/accounts/GetAccountsList', { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
  }

  public GetAllCalllist(userid: number, startdate?: string, enddate?: string, selectedstatus?:string): Promise<Calllist[]> {
    const promise = new Promise<Calllist[]>((resolve, reject) => {
      this.httpclient.get<Calllist[]>(this.settings.baseUrl + 'api/accounts/GetAllCalllist/' + userid + '/' + startdate + '/' + enddate + '/' + selectedstatus, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllCallHistorylist(type: string, contactid: number): Promise<Calllist[]> {
    const promise = new Promise<Calllist[]>((resolve, reject) => {
      this.httpclient.get<Calllist[]>(this.settings.baseUrl + 'api/accounts/GetAllCalllHistoryist/' + type + '/' + contactid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
    public GetAllNotesList(): Promise<Notes[]> {
      const promise = new Promise<Notes[]>((resolve, reject) => {
        this.httpclient.get<Notes[]>(this.settings.baseUrl + 'api/customers/GetAllNotesList/', { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
    }
    public GetAllContacts(providerid: number): Promise<Contacts[]> {
      const promise = new Promise<Contacts[]>((resolve, reject) => {
        this.httpclient.get<Contacts[]>(this.settings.baseUrl + 'api/accounts/GetAllContactsList/' + providerid, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
  }

  public GetAllSelectedContactCalllist(viewcalllist): Promise<Contacts[]> {
    const promise = new Promise<Contacts[]>((resolve, reject) => {
      this.httpclient.post<Contacts[]>(this.settings.baseUrl + 'api/accounts/GetAllContactCalllist/', viewcalllist,{ headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetAllSelectedAccountCalllist(viewcalllist): Promise<Accounts[]> {
    const promise = new Promise<Accounts[]>((resolve, reject) => {
      this.httpclient.post<Accounts[]>(this.settings.baseUrl + 'api/accounts/GetAllAccountCalllist/', viewcalllist, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
    public GetAllSelectedContacts(contactids: string,type:string): Promise<Customers[]> {
        
        const promise = new Promise<Customers[]>((resolve, reject) => {
          this.httpclient.get<Customers[]>(this.settings.baseUrl + 'api/accounts/GetAllSelectedContactsList/' + contactids + '/' + type, { headers: this.reqHeader }).
                subscribe(res => {
                    resolve(res);
                });
        });
        return promise;
  }
  GetAllColumnNames(tblName: string): Promise<string[]> {
    const promise = new Promise<string[]>((resolve, reject) => {
      this.httpclient.get<string[]>(this.settings.baseUrl + 'api/accounts/GetAllColumns/' + tblName, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  GetDistinctColumnValues(tblName: string, columnName: string,currentuserid:number): Promise<string[]> {
    const promise = new Promise<string[]>((resolve, reject) => {
      this.httpclient.get<string[]>(this.settings.baseUrl + 'api/accounts/GetAllDistinctColumnValues/' + tblName + '/' + columnName + '/' + currentuserid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
           
        });
    });
    return promise;
  }

  public saveAdvancedSearchQuery(qryName: string, searchQry: string, editQryId: number, searchtype: string, userdId: number, SearchVal: string, filterSearchdata: string, searchdata: string, searchOperator: string): Promise<string> {
     
    let obj: string[] = [qryName, searchQry, editQryId.toString(), searchtype.toString(), userdId.toString(), SearchVal, filterSearchdata, searchdata, searchOperator];
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.post<string>(this.settings.baseUrl + 'api/accounts/SaveAdvancedSearchQuery', obj, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public SaveDefaultColumns(searchType: string, defaultColumns: string,loggedInuserId:number): Promise<string> {
     
    let obj: string[] = [searchType, defaultColumns, loggedInuserId.toString()];
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.post<string>(this.settings.baseUrl + 'api/accounts/SaveAdvancedDefaultColumns', obj, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public getAdvancedSearchQueries(param:string,userid:number): Promise<AdvancedSearch[]> {
    const promise = new Promise<AdvancedSearch[]>((resolve, reject) => {
      this.httpclient.get<AdvancedSearch[]>(this.settings.baseUrl + 'api/accounts/GetAdvancedSearchQueries/' + param + '/' + userid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public searchdata(tabname: string,loggedinuserid:number): Promise<AdvancedSearch> {
    const promise = new Promise<AdvancedSearch>((resolve, reject) => {
      this.httpclient.get<AdvancedSearch>(this.settings.baseUrl + 'api/accounts/GetFilterSearchData/' + tabname + '/' + loggedinuserid, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public GetDefaultColumns(searchType, loggedInuserId): Promise<AdvancedSearch> {
    const promise = new Promise<AdvancedSearch>((resolve, reject) => {
      this.httpclient.get<AdvancedSearch>(this.settings.baseUrl + 'api/accounts/GetAdvancedSearchColumns/' + searchType + '/' + loggedInuserId, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public deleteAdvancedSearchQuery(dltQryId: number, status: number, currentuserid: number): Promise<string> {
     
    let obj: AdvancedSearch = new AdvancedSearch();
    obj.Id = dltQryId;
    obj.status = status;
    obj.createdby = currentuserid;
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.post<string>(this.settings.baseUrl + 'api/accounts/DeleteAdvancedSearchQuery', obj, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  deleteSearchQuery(tabname:string,currentuserid:number): Promise<any> {
    let obj: AdvancedSearch = new AdvancedSearch();
    obj.searchtype = tabname;
    obj.createdby = currentuserid;
    const promise = new Promise<string>((resolve, reject) => {
      this.httpclient.post<string>(this.settings.baseUrl + 'api/accounts/DeleteSearchQuery', obj, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }
  public RemoveContactFromCallList(callListId: number, contactIds: string): Promise<any> {
     
    let arr: string[] = [callListId.toString(), contactIds];
    const promise = new Promise<any>((resolve, reject) => {
      this.httpclient.post<any>(this.settings.baseUrl + 'api/accounts/RemoveContactFromCallList', arr, { headers: this.reqHeader }).
        subscribe(res => {
          resolve(res);
        });
    });
    return promise;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void { 
     
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    var FileSaver = require('file-saver');
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName +new Date().getTime() + EXCEL_EXTENSION);
  }
  public downLoadFile(result, fileName) {
  //  const link = document.createElement('a');
  //  const file = new Blob([result], { type: 'application/pdf' });
  //  link.href = window.URL.createObjectURL(file);
  //  link.target ='_blank'
  //  link.download = fileName + '.pdf';
  //  window.open(link.href); 
  //  document.body.appendChild(link);
  //  link.click();
  //  //window.open(link.href, '_blank');
  //  document.body.removeChild(link);
    
    var file = new Blob([result], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    
  }
}
