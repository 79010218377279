<div class="container" style="padding: 0%;">
  <div  [ngClass]="(box1 && box2 && box3) ? 'col-xs-4': ((box1 && box2) || (box1 && box3) || (box2 && box3)) ? 'col-xs-6':'col-xs-12'" style="padding: 0.1%;
border: 2px solid #00B0F0;border-bottom:none" *ngIf="box3">
    <div class='col-xs-12' style="background-color: rgb(40,62,74); height: 7%">
      <div class="col-xs-11" style="color: white; padding: 3%; font-weight: 600;height:60px;">
        {{box3header}}
      </div>
      <div class="col-xs-1" style="padding: 3%">
        <img (click)="Close('box3')" src="../../../assets/img/White x.png" style="height:26px" />
      </div>
    </div>
    <div class='col-xs-12' style="height:650px">    
        <div class="col-xs-12" *ngFor="let chat of editloggedinUserlist" style="padding: 0%">
          <div class="col-xs-1">
            <input type="checkbox" [(ngModel)]="chat.userselected" (change)="editOpenChatbox(chat.username,chat.id,$event)">
          </div>
          <div class="col-xs-11">
            <span class="sublabel" id="{{chat.id}}_button" (click)="editOpenChatbox(chat.username,chat.id,null)">
              {{chat.username}}
            </span>
          </div>
        </div>
    
      <div class="col-xs-12" style=" height:5%;margin-top:35%;">
        <div *ngIf="selecteduserids.length>1" class="col-xs-12" style="padding:0%">
          <b>CHAT SESSION NAME</b>
          <input class="form-control" type="text" name="groupName" [(ngModel)]="groupName" placeholder="TYPE NAME OF CHAT SESSION" style="background-color: rgb(40,62,74); color: #00B0F0" />
        </div>
      </div>
      <div class="col-xs-12" style="height: 5%; margin-top: 5%;">
        <div class="col-xs-4" *ngIf="groupId>0" style="padding:0%;float:right;">
          <button type="button" (click)="Updategroup()" class="btn saveButton" style="width: 100%;">Save</button>
        </div>
        <div class="col-xs-4" *ngIf="groupId==0 && selecteduserids.length>0 " style="padding:0%;float:right;">
          <button type="button" (click)="StartChat()" class="btn saveButton" style="width: 100%;">Save </button>
        </div>
      </div>
    </div>

  </div>
  <div [ngClass]="(box1 && box2 && box3) ? 'col-xs-4': ((box1 && box2) || (box1 && box3) || (box2 && box3)) ? 'col-xs-6':'col-xs-12'"
       [ngStyle]="{'background-color':box3 ? '#edebe6' : 'none' }"
       style="padding: 0.1%; border: 2px solid #00B0F0;border-bottom:none;" *ngIf="box2">
    <div class='col-xs-12' style="background-color: rgb(40,62,74); color: white; font-weight: 600; padding: 3%; height: 60px; ">
        CHAT SESSIONS     
    </div>
    <div class='col-xs-12' style="height: 93%; padding: 0.5%">
      <div class='col-xs-12' style="height: 5%">
        <div class="col-xs-1" style="color: white; padding: 1%; font-weight: 600;">
          <img (click)="OpenContacts()" src="../../../assets/img/ChatContacts Selected.png" style="height:35px;" />
        </div>
        <div class="col-xs-10" style="padding:1%">
          <img (click)="EditChats()" src="../../../assets/img/Edit Contact.png" style="height: 35px;" />
        </div>
        <div class="col-xs-1" style="padding: 1%;float:right;">
          <img (click)="DeleteChat()" src="../../../assets/img/trash.png" style="height:30px" />
        </div>

      </div>
      <div class='col-xs-12' [ngStyle]="{'background-color':box3 ? '#edebe6' : 'none' }"
           style="border: 1px solid gray; padding: 0%; height: 745px">
        <div class="col-xs-12" *ngIf="showlabel" style="color: white; font-weight: 600;">
          <span *ngIf="message.groupName!=''" style="color: rgb(0,176,240)">{{message.groupName}}</span>
          <span *ngIf="message.groupName==''" style="color: rgb(0,176,240)">{{selectedchatusers}}</span>
        </div>
        <div class="col-xs-12" style="font-size:15px;background-color: white; height: 100%; color: black; overflow: auto; padding: 0%">
          <div class="col-xs-12" *ngFor="let fuserchat of FirstloggedinUserlist">      
              <span title="{{fuserchat.tousernames}}" class="sublabel" id="{{fuserchat.touserid}}_chatbutton">
                {{fuserchat.groupName}} <span *ngIf="fuserchat.unreadcount>0" style="color: rgb(0,176,240)">**</span>
              </span>          
          </div>
          <div class="col-xs-12" *ngFor="let chat of loggedinUserlist">         
              <span title="{{chat.tousernames}}" class="sublabel" id="{{chat.touserid}}_chatbutton"
                (click)="OpenChatbox(chat.touserid,chat.chatids,chat.groupId,chat.groupName,chat.groupuserids)">
                {{chat.groupName}} <span *ngIf="chat.unreadcount>0" style="color: rgb(0,176,240)">**</span>
              </span>
         
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="(box1 && box2 && box3) ? 'col-xs-4': ((box1 && box2) || (box1 && box3) || (box2 && box3)) ? 'col-xs-6':'col-xs-12'"
       [ngStyle]="{'pointer-events':box3 ? 'auto' : 'auto','background-color':box3 ? '#edebe6' : 'none' }"
       style="padding: 0.1%;" *ngIf="box1">
    <div class='col-xs-12' style="background-color: rgb(40,62,74); height: 60px;">
      <div class="col-xs-11" style="color: white; padding: 3%; font-weight: 600;">
        CHAT MESSAGES
      </div>
      <div class="col-xs-1" *ngIf="!box3" style="padding: 3%">
        <img (click)="Close('box1')" src="../../../assets/img/White x.png" style="height:26px" />
      </div>
    </div>

    <div  class='col-xs-12' style="height: 660px; font-weight: 600; overflow-y: scroll">
      <div *ngFor="let msg of previousmessages;let i = index">
        <div class="col-xs-3" style="padding:0%">
        </div>
        <div class="col-xs-9 activeSublabel" *ngIf="isDifferentDay(i) " style="padding:1%">
       {{ getMessageDate(i) }}
        </div>
        <div class="col-xs-12 incoming_msg" style="margin-top: 2%;">
          <div class="col-xs-3" style="padding:0%">
            <span>{{msg.sentuserfirstName.charAt(0).toUpperCase()}}    {{msg.sentuserlastName}}</span>
          </div>
          <div class="col-xs-9" style="padding:0%">
            <div class="received_withd_msg">
              <span class="time_date">
                {{msg.createdate | date: 'h:mm a'}}
              </span>
              <p>
                {{msg.message}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div  class='col-xs-12' *ngIf="isshowReply" style="height: 7%">  
      <div class="col-xs-11" style="padding:0%">
        <textarea class="form-control" rows="6" type="text" name="txtMessage"
         style="height: 100%; background-color: #00B0F0;color:white;" [(ngModel)]="txtMessage" placeholder="Write Reply"></textarea>
      </div>
        <div class="col-xs-1" (click)="sendMessage()" style="border: 2px solid #00B0F0;height:132px;">
          <br/> S<br/>
          E<br/>
          N<br/>
          D<br/>
        </div>
    </div>
  </div>
 
</div>
