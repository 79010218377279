import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/services/customer.service';
declare var $: any;
@Component({
  selector: 'app-editdeletecv',
  templateUrl: './editdeletecv.component.html',
  styleUrls: ['./editdeletecv.component.css']
})
export class EditdeletecvComponent implements OnInit, ICellRendererAngularComp {
  currentuser: any;
  userprevilages: any;
  constructor(private toastr: ToastrService, private customerService: CustomerService) {
    this.currentuser = JSON.parse(localStorage.getItem("currentUser"));

  }

  ngOnInit() {

  }

  public params: any;

  agInit(params: any): void {
    this.params = params;
    
  }
  needDeletebtn() {
    return (document.getElementById('customers').className == 'mybtn coustomButton mainactiveButton') ? true : true;
  }
  needCVbtn() {
    var url = window.location.href;
    if ((url.indexOf('quick-view') > -1) || (url.indexOf('account-contact/0/') > -1)) {
      return (document.getElementById('AccountsMainMenu').className == 'activeButton')? true : true;
    }
    else if (url.indexOf('contacts/contacts/') > -1 || url.indexOf('customercontacts') > -1) {
      // return (document.getElementById('a-z_button').className == 'subButtons activeButton') ? true : true;
      return true;
    } 
    else if (url.indexOf('calllist/0/') > -1) {
      return true;
    }
  }
  needSharing() {
    var url = window.location.href;
    if ((url.indexOf('account/Customer') > -1) || (url.indexOf('account/Contact') > -1)) {
      return (document.getElementById('AccountsMainMenu').className == 'activeButton') ? true : true;
    }
    else if ((url.indexOf('account-contact') > -1) || (url.indexOf('account/Accounts') > -1)) {
      return (document.getElementById('AccountsMainMenu').className == 'activeButton') ? true : true;
    }
    else if ((url.indexOf('contacts/contacts') > -1) || (url.indexOf('contacts') > -1) || (url.indexOf('customercontacts') > -1)) {
      return true;
    } else if (url.indexOf('calllist') > -1 || url.indexOf('quick-view') > -1) { return true; }
  }

  public editRowAg() {
    this.params.context.editRowAg(this.params.node.data);

  }

  public deleteRowAg() {

    this.params.context.deleteRowAg(this.params.node.data);

  }

  public ActiateRowAg() {
    this.params.context.deleteRowAg(this.params.node.data);
  }

  public convertContactAg() {
    this.customerService.GetPrivilagesByuser(this.currentuser.id).then(result => {
      this.userprevilages = result;

      var thisTab = this.userprevilages.filter(p => p.resource == ("Leads"))[0];
      if (thisTab.canview == false) {
        this.toastr.warning("You Do Not have Access to Leads Tab – Please check with CRM Administrator if Access is Required.", "Access Denied");
        return false;
      } else {
        this.params.context.convertContactAg(this.params.node.data);
      }
    });
  }
  public sharingAg() {
    this.params.context.sharingAg(this.params.node.data);
  }
  public callcheck() {
    this.params.context.callcheck(this.params.node.data);
  }
  refresh(): boolean {
    return false;
  }
}
