export class StatusIDCategory {
  public Id: number;
  public StatusIDName: string;
  public SortOrder: number;
  public StatusIDGroup: number;
  public StatusIDGroupName: string;
  public UserID: number;
  public deleteStatus: number;
}

export class StatusIDGroup{
  public  StatusGroupId: number;
  public StatusGroupName: string;
  public StatusSortOrder: number;
  public UserID: number;
  public DeleteStatus: number;
}
