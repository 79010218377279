export class SalesProcessHistory {
  public id: number;
  public contactid: number;
  public datecreated: string;
  public completeddate: string;
  public status: string;
  public ownerid: number;
  public completeduserid: number;
  public completeduser: string;
  public salesstage: string;
  public dealwonOrlost: string;
  public userid: number;
  public islimitedSearch: boolean;
  public SearchVal: string;
  public CurrentPage: number;
  public PageSize: number;
  public TotalPages: number;
  public Total: number;
  public notesDescription: string;
  public OpportunityID: number;
  public WLP: string;
  public NoOfDays: string;
  public IPC: string;
  public Analysis: string;
  public PrepareAnalysisReport: string;
  public ReviewAnalysis: string;
  public GeneralProposal: string;
  public justificationmetting: string;
  public Referencecall: string;
  public Mdlogicdemonstration: string;
  public IPCCompletedDate: string;
  public AnalysisCompletedDate: string;
  public PrepareAnalysisReportCompletedDate: string;
  public ReviewAnalysisCompletedDate: string;
  public GeneralProposalCompletedDate: string;
  public justificationmettingCompletedDate: string;
  public ReferencecallCompletedDate: string;
  public MdlogicdemonstrationCompletedDate: string;
  public convertedtoLeaddate: string;
  public contactname: string;
  public leadstatus: string;

  public IPCStatus: string;
  public AnalysisStatus: string;
  public PrepareAnalysisStatus: string;
  public ReviewAnalysisStatus: string;
  public GenerateProposalStatus: string;
  public JustificationStatus: string;
  public DemonstrationStatus: string;
  public ReferenceCallsStatus: string;

}
