import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Notes } from '../../../models/notes';
import { CustomerService } from '../../../services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, TitleCasePipe } from '@angular/common';
@Component({
  selector: 'contact-photo',
  templateUrl: './contact-photo.component.html',
  styleUrls: ['./contact-photo.component.css']
})
export class ContactPhotoComponent implements OnInit {
  notes: Notes = new Notes();
  @Input() contact;
  public crmDialog = false;
  SummaryNoteschanged: boolean = false;
  currentuser: any;
  @Output() Change: EventEmitter<string> = new EventEmitter<string>();
  datePipe: DatePipe = new DatePipe("en-US");
  constructor(private customerService: CustomerService, private toastr: ToastrService) { this.currentuser = JSON.parse(localStorage.getItem("currentUser")); }

  ngOnInit(): void {
    this.contact.SassDate = (this.contact.SassDate == "0001-01-01T00:00:00") ? null : this.datePipe.transform(this.contact.SassDate, 'yyyy-MM-dd');
    this.contact.SaaSRenewal = (this.contact.SaaSRenewal == "0001-01-01T00:00:00") ? null : this.datePipe.transform(this.contact.SaaSRenewal, 'yyyy-MM-dd');
    this.contact.SaaSRCM = (this.contact.SaaSRCM == "0001-01-01T00:00:00") ? null : this.datePipe.transform(this.contact.SaaSRCM, 'yyyy-MM-dd'); 
  } 
  close() { this.Change.emit("Close"); }
  SaveNote() { 
    this.notes.accountid = this.contact.id;
    this.notes.type = "ContactSummary";
    this.notes.isnewnote = 1;
    this.notes.userid = this.currentuser.id;
    this.notes.notedescription = this.contact.SummaryNotes;
    if (this.notes.notedescription != undefined && this.notes.notedescription != "") {
      this.customerService.SaveNotes(this.notes).then(result => {
        this.toastr.success("Notes saved successfully.", "Notes Saved", { timeOut: 600 });
        this.Change.emit("Close"); 
      });
    }
  }
  checkNotes() {
    if (this.SummaryNoteschanged) {
      this.Change.emit("Open")
    }
    else {
      this.Change.emit("Close")
    }   
  }
  valuechange(event) {
    this.SummaryNoteschanged = true;
  }
  CRMClose() {
    if (this.contact.SummaryNotes != null && this.contact.SummaryNotes != "") {
      this.crmDialog = true;
    }
  }
 
}
